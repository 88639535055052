import * as React from 'react';

const Boat = (props) => {
    const { style } = props;

    return (
        <svg width="4079" height="1481" viewBox="0 0 4079 1481" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', ...style }}>
            <g clipPath="url(#clip0_1_9114)">
                <path d="M3971.96 667.3C3870.04 645.41 3762.54 655.31 3659.92 637.36C3557.3 619.41 3558.62 596.28 3455.99 578.33C3353.37 560.38 3348.05 593.52 3245.43 575.57C3142.81 557.62 3148.13 524.48 3045.51 506.53C2942.89 488.58 2937.57 521.72 2834.95 503.77C2732.33 485.82 2737.66 452.68 2635.04 434.73C2532.42 416.78 2527.1 449.92 2424.48 431.97C2321.86 414.02 2327.18 380.88 2224.57 362.93C2121.95 344.98 2116.63 378.12 2014.01 360.17C1911.39 342.22 1916.71 309.08 1814.1 291.13C1711.48 273.18 1706.16 306.32 1603.54 288.37C1500.92 270.42 1506.24 237.28 1403.63 219.33C1301.01 201.38 1295.69 234.52 1193.07 216.57C1090.45 198.62 1095.78 165.48 993.16 147.53C890.54 129.58 885.22 162.72 782.61 144.77C679.99 126.82 685.31 93.68 582.7 75.73C480.08 57.78 474.76 90.92 372.14 72.97C288.54 58.36 274.35 7.28 216.84 0.71V0H0V1480.89H216.84V1479.81C272.78 1471.87 289.98 1422.28 372.17 1407.9C474.79 1389.95 480.11 1423.09 582.74 1405.14C685.36 1387.19 680.04 1354.05 782.65 1336.1C885.27 1318.15 890.59 1351.29 993.21 1333.34C1095.83 1315.39 1090.51 1282.25 1193.12 1264.3C1295.74 1246.35 1301.06 1279.49 1403.67 1261.54C1506.29 1243.59 1500.97 1210.45 1603.58 1192.5C1706.2 1174.55 1711.52 1207.69 1814.14 1189.74C1916.76 1171.79 1911.44 1138.65 2014.05 1120.7C2116.67 1102.75 2121.99 1135.89 2224.61 1117.94C2327.23 1099.99 2321.91 1066.85 2424.52 1048.9C2527.14 1030.95 2532.46 1064.09 2635.08 1046.14C2737.7 1028.19 2732.38 995.05 2834.99 977.1C2937.61 959.15 2942.93 992.29 3045.54 974.34C3148.16 956.39 3142.83 923.25 3245.45 905.3C3348.07 887.35 3353.39 920.49 3456.01 902.54C3558.63 884.59 3557.31 861.46 3659.93 843.51C3762.55 825.56 3870.05 839.46 3971.96 817.56V667.29V667.3Z" fill="#40EBDF"/>
                <g style={{ mixBlendMode: 'multiply' }}>
                    <path d="M4070.02 661.15L4078.69 628.95H3961.95L3936.58 651.83H3059.43V764.91H4042.08L4048.04 742.78H4078.69L4070.09 711.21C4065.63 694.83 4065.6 677.56 4070.02 661.16V661.15Z" fill="#14D0E1"/>
                    <path d="M3500.47 595.97H3381.07V609.66H3500.47V595.97Z" fill="#14D0E1"/>
                    <path d="M3500.47 595.97H3489.56V653.05H3500.47V595.97Z" fill="#14D0E1"/>
                    <path d="M3381.06 555.2H3361.79V654.64H3381.06V555.2Z" fill="#14D0E1"/>
                    <path d="M3381.07 555.2L3500.47 595.97H3488.19L3381.07 559.63V555.2Z" fill="#14D0E1"/>
                    <path d="M3758.54 595.97H3639.14V609.66H3758.54V595.97Z" fill="#14D0E1"/>
                    <path d="M3758.54 595.97H3747.63V653.05H3758.54V595.97Z" fill="#14D0E1"/>
                    <path d="M3639.14 555.2H3619.87V654.64H3639.14V555.2Z" fill="#14D0E1"/>
                    <path d="M3639.14 555.2L3758.54 595.97H3746.26L3639.14 559.63V555.2Z" fill="#14D0E1"/>
                    <path d="M3758.54 609.65H3877.94V595.96H3758.54V609.65Z" fill="#14D0E1"/>
                    <path d="M3758.54 653.05H3769.45V595.97H3758.54V653.05Z" fill="#14D0E1"/>
                    <path d="M3877.95 654.64H3897.22V555.2H3877.95V654.64Z" fill="#14D0E1"/>
                    <path d="M3877.94 555.2L3758.54 595.97H3770.82L3877.94 559.63V555.2Z" fill="#14D0E1"/>
                    <path d="M3248.97 569.93H3146.77V640.39H3248.97V569.93Z" fill="#14D0E1"/>
                    <path d="M3146.58 640.39H3107.88L3120.23 549.54H3146.58V640.39Z" fill="#14D0E1"/>
                    <path d="M3242.72 569.93H3160.93V549.54H3248.97L3242.72 569.93Z" fill="#14D0E1"/>
                    <path d="M3207.98 527.6H3191.98V549.54H3207.98V527.6Z" fill="#14D0E1"/>
                    <path d="M3146.58 549.54H3107.88V559.74H3146.58V549.54Z" fill="#14D0E1"/>
                    <path d="M3993.95 582.44H3977.26V641.87H3993.95V582.44Z" fill="#14D0E1"/>
                    <path d="M3273.2 651.82H3059.44V628.94H3242.62L3273.2 651.82Z" fill="#14D0E1"/>
                </g>
                <mask id="mask0_1_9114" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="3972" height="1481">
                    <path d="M3971.96 667.3C3870.04 645.41 3762.54 655.31 3659.92 637.36C3557.3 619.41 3558.62 596.28 3455.99 578.33C3353.37 560.38 3348.05 593.52 3245.43 575.57C3142.81 557.62 3148.13 524.48 3045.51 506.53C2942.89 488.58 2937.57 521.72 2834.95 503.77C2732.33 485.82 2737.66 452.68 2635.04 434.73C2532.42 416.78 2527.1 449.92 2424.48 431.97C2321.86 414.02 2327.18 380.88 2224.57 362.93C2121.95 344.98 2116.63 378.12 2014.01 360.17C1911.39 342.22 1916.71 309.08 1814.1 291.13C1711.48 273.18 1706.16 306.32 1603.54 288.37C1500.92 270.42 1506.24 237.28 1403.63 219.33C1301.01 201.38 1295.69 234.52 1193.07 216.57C1090.45 198.62 1095.78 165.48 993.16 147.53C890.54 129.58 885.22 162.72 782.61 144.77C679.99 126.82 685.31 93.68 582.7 75.73C480.08 57.78 474.76 90.92 372.14 72.97C288.54 58.36 274.35 7.28 216.84 0.71V0H0V1480.89H216.84V1479.81C272.78 1471.87 289.98 1422.28 372.17 1407.9C474.79 1389.95 480.11 1423.09 582.74 1405.14C685.36 1387.19 680.04 1354.05 782.65 1336.1C885.27 1318.15 890.59 1351.29 993.21 1333.34C1095.83 1315.39 1090.51 1282.25 1193.12 1264.3C1295.74 1246.35 1301.06 1279.49 1403.67 1261.54C1506.29 1243.59 1500.97 1210.45 1603.58 1192.5C1706.2 1174.55 1711.52 1207.69 1814.14 1189.74C1916.76 1171.79 1911.44 1138.65 2014.05 1120.7C2116.67 1102.75 2121.99 1135.89 2224.61 1117.94C2327.23 1099.99 2321.91 1066.85 2424.52 1048.9C2527.14 1030.95 2532.46 1064.09 2635.08 1046.14C2737.7 1028.19 2732.38 995.05 2834.99 977.1C2937.61 959.15 2942.93 992.29 3045.54 974.34C3148.16 956.39 3142.83 923.25 3245.45 905.3C3348.07 887.35 3353.39 920.49 3456.01 902.54C3558.63 884.59 3557.31 861.46 3659.93 843.51C3762.55 825.56 3870.05 839.46 3971.96 817.56V667.29V667.3Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_1_9114)">
                    <path d="M3736.05 883.71C3737.87 884.37 3738.01 885.86 3738.11 887.28C3738.15 887.8 3738.09 888.61 3738.21 888.87C3738.96 890.53 3736.8 890.5 3736.77 891.61C3735.69 891.41 3734.34 891.54 3733.62 890.93C3732.94 890.34 3732.14 889.54 3731.75 888.62C3731.37 887.75 3730.45 886.69 3732.18 886.07C3732.44 885.98 3732.49 885.38 3732.68 885.04C3733.51 883.51 3734.67 883.1 3736.06 883.73L3736.05 883.71Z" fill="white"/>
                    <path d="M3595.24 951.2C3595.72 950.17 3596.88 949.81 3597.25 949.98C3598.65 950.65 3599.81 949.62 3601.11 949.72C3602.03 949.79 3602.58 949.98 3602.84 950.83C3603.27 952.24 3603.11 952.89 3602.17 953.41C3601.76 953.64 3601.4 953.9 3601.03 954.18C3599.43 955.4 3595.48 954.39 3595.22 952.09C3595.17 951.68 3595.13 951.34 3595.25 951.19L3595.24 951.2Z" fill="white"/>
                    <path d="M3685.45 974.66C3686.4 974.34 3686.8 972.93 3688.11 973.4C3688.29 973.47 3688.47 973.63 3688.65 973.63C3689.39 973.6 3690.17 973.31 3690.74 974.04C3691.19 974.62 3690.99 975.32 3691.02 975.96C3691.07 977.23 3689.83 978.54 3688.44 978.75C3687.36 978.91 3686.58 978.52 3685.91 977.63C3685.22 976.71 3685.55 975.67 3685.44 974.66H3685.45Z" fill="white"/>
                    <path d="M3693.17 1113.09C3692.46 1114.65 3691.57 1115.1 3690.38 1114.73C3689.73 1114.52 3689.13 1114.15 3688.48 1113.91C3687.87 1113.68 3687.81 1113.34 3688.09 1112.79C3688.41 1112.18 3688.58 1111.48 3688.96 1110.92C3689.61 1109.97 3690.63 1109.6 3691.75 1109.59C3691.98 1109.59 3692.35 1109.8 3692.42 1109.99C3692.69 1110.72 3693.17 1111.28 3693.55 1111.92C3693.74 1112.23 3693.21 1112.97 3693.17 1113.09Z" fill="white"/>
                    <path d="M3568.62 1077.76C3568.88 1077.03 3569.22 1075.97 3570.46 1076.17C3571.04 1076.26 3572.14 1076.44 3572.05 1077.17C3571.95 1077.97 3572.5 1078.94 3571.66 1079.6C3571.08 1080.05 3570.31 1080.33 3569.88 1080.88C3569.48 1081.39 3569.24 1081.42 3568.71 1081.2C3567.95 1080.87 3567.74 1080.25 3567.84 1079.57C3567.93 1079.01 3568.29 1078.49 3568.62 1077.75V1077.76Z" fill="white"/>
                    <path d="M3711.95 1123.41C3712.25 1122.69 3713.08 1122.33 3713.8 1122.6C3714.04 1122.69 3715.7 1124.95 3715.7 1125.18C3715.7 1125.71 3714.18 1126.7 3714.17 1126.61C3713.97 1125.38 3712.85 1126.42 3712.44 1125.85C3712.05 1125.31 3711.75 1123.87 3711.95 1123.41Z" fill="white"/>
                    <path d="M3745.38 950.9C3745.01 950.72 3744.39 950.56 3744.55 950.2C3744.84 949.54 3744.86 948.67 3745.76 948.32C3746.26 948.12 3746.72 948.41 3747.13 948.25C3747.8 948 3748.17 948.07 3748.49 948.78C3748.83 949.55 3748.63 950.25 3748.06 950.56C3747.26 951 3746.34 951.62 3745.38 950.89V950.9Z" fill="white"/>
                    <path d="M3647.58 982.43C3647.29 982.98 3647.25 983.85 3646.19 983.32C3645.95 983.2 3645.48 983.58 3645.11 983.7C3644.69 983.83 3644.25 983.93 3643.82 984.05C3644.03 983.42 3644.06 982.59 3644.49 982.22C3645.04 981.73 3645.92 981.64 3646.64 981.32C3647.03 981.15 3647.43 981.06 3647.71 981.37C3648.02 981.71 3647.71 982.09 3647.57 982.44L3647.58 982.43Z" fill="white"/>
                    <path d="M3795.72 1037.69C3795.64 1037.87 3795.58 1038.05 3795.48 1038.22C3795.25 1038.58 3795.29 1039.27 3794.62 1039.14C3793.93 1039.01 3793.47 1038.57 3793.29 1037.83C3793.14 1037.2 3793.47 1037.01 3793.94 1036.79C3794.24 1036.64 3794.42 1036.24 3794.73 1036.08C3795.13 1035.88 3795.59 1035.54 3796.06 1035.91C3796.62 1036.35 3796.08 1036.75 3795.96 1037.17C3795.91 1037.35 3795.81 1037.52 3795.73 1037.7L3795.72 1037.69Z" fill="white"/>
                    <path d="M3702.53 1128.61C3703.07 1129.34 3703.85 1129.93 3704.02 1130.9C3704.06 1131.12 3703.92 1131.47 3703.56 1131.56C3703.11 1131.67 3702.62 1132.13 3702.22 1131.7C3701.73 1131.17 3701.34 1130.53 3700.98 1129.89C3700.55 1129.14 3701.55 1129.41 3701.67 1129.02C3701.79 1128.61 3702.06 1128.36 3702.54 1128.62L3702.53 1128.61Z" fill="white"/>
                    <path d="M3664.51 937.26C3664.37 937.78 3664.08 938.01 3663.49 937.71C3662.58 937.25 3662.28 936.52 3662.61 935.68C3662.91 934.93 3663.54 934.73 3664.19 934.8C3664.93 934.89 3665.07 936.08 3664.51 937.26Z" fill="white"/>
                    <path d="M3617.59 861.94C3617.47 862.21 3617.39 862.51 3617.22 862.74C3616.82 863.28 3616 863.15 3615.76 862.53C3615.62 862.16 3615.4 861.81 3615.36 861.43C3615.3 860.94 3615.51 860.39 3615.91 860.19C3616.37 859.96 3616.99 860.37 3617.51 860.04C3617.63 860.58 3618.17 861.07 3617.72 861.68C3617.66 861.76 3617.64 861.86 3617.6 861.95L3617.59 861.94Z" fill="white"/>
                    <path d="M3717.58 1075.89C3717.9 1075.77 3718.18 1075.68 3718.45 1075.55C3719.12 1075.24 3719.63 1076.21 3720.34 1075.74C3720.72 1075.49 3721.12 1075.68 3721.24 1076.11C3721.35 1076.52 3721.1 1076.8 3720.68 1076.95C3720.13 1077.14 3719.6 1077.4 3719.04 1077.56C3718.78 1077.64 3718.41 1078.13 3718.18 1077.47C3718 1076.94 3717.78 1076.41 3717.58 1075.89Z" fill="white"/>
                    <path d="M3611.65 1136.75C3611.12 1136.41 3610.99 1135.29 3611.4 1134.7C3611.57 1134.47 3613.37 1134.18 3613.63 1134.4C3614.3 1134.96 3613.63 1135.45 3613.44 1135.89C3613.06 1136.71 3612.16 1137.08 3611.65 1136.75Z" fill="white"/>
                    <path d="M3599.68 1055.4C3600.27 1055.56 3600.55 1055.83 3600.24 1056.44C3599.87 1057.18 3599.01 1057.69 3598.48 1057.45C3597.94 1057.21 3597.74 1056.21 3598.08 1055.46C3598.59 1054.34 3599.17 1055.37 3599.68 1055.41V1055.4Z" fill="white"/>
                    <path d="M3595.55 1049.48C3596 1049.17 3596.47 1048.81 3597.05 1049.03C3597.68 1049.26 3597.83 1050.61 3597.36 1051.26C3597.04 1051.69 3596.71 1051.5 3596.38 1051.31C3595.62 1050.88 3595.23 1050.01 3595.55 1049.48Z" fill="white"/>
                    <path d="M3681.83 1077.4C3681.67 1077.75 3681.57 1077.92 3681.52 1078.11C3681.31 1078.8 3680.62 1078.72 3680.15 1078.99C3680.04 1078.46 3679.52 1077.99 3679.89 1077.38C3679.99 1077.22 3680.06 1077.03 3680.13 1076.85C3680.35 1076.23 3680.59 1075.89 3681.37 1076.26C3682.12 1076.61 3682.16 1076.99 3681.83 1077.41V1077.4Z" fill="white"/>
                    <path d="M3534.71 991.94C3535.36 992.18 3535.3 992.66 3535.03 992.9C3534.48 993.39 3533.84 993.78 3533.21 994.16C3532.62 994.51 3532.69 993.89 3532.54 993.63C3531.9 992.49 3533.29 992.55 3533.58 991.94C3533.91 991.23 3534.4 991.95 3534.72 991.94H3534.71Z" fill="white"/>
                    <path d="M3646.29 1074.05C3646.48 1073.25 3647.13 1073.03 3647.78 1073.09C3648.27 1073.13 3648.81 1074.67 3648.49 1074.91C3647.86 1075.38 3647.19 1075.4 3646.48 1075C3645.98 1074.72 3646.14 1074.4 3646.29 1074.04V1074.05Z" fill="white"/>
                    <path d="M3793.88 970.61C3794.36 970.94 3794.47 971.49 3794.49 972.04C3794.5 972.37 3792.91 972.72 3792.34 972.52C3791.82 972.34 3791.93 971.98 3792.1 971.61C3792.45 970.88 3793.33 970.37 3793.88 970.61Z" fill="white"/>
                    <path d="M3668.83 1109.01C3668.6 1108.81 3668.43 1108.5 3668.7 1108.29C3669.35 1107.78 3669.88 1107.1 3670.72 1106.82C3671.07 1106.7 3671.38 1106.82 3671.51 1107C3671.64 1107.17 3671.62 1107.66 3671.48 1107.77C3670.91 1108.24 3670.28 1108.63 3669.66 1109.03C3669.4 1109.2 3669.11 1109.18 3668.83 1109V1109.01Z" fill="white"/>
                    <path d="M3668.95 1141.14C3669.05 1140.85 3669.17 1140.47 3669.68 1140.58C3670.12 1140.68 3670.16 1141.1 3670.35 1141.41C3670.73 1142.05 3670.3 1142.54 3670.03 1142.98C3669.86 1143.24 3669.18 1143.5 3669.04 1143.38C3668.74 1143.14 3668.52 1142.65 3668.49 1142.25C3668.47 1141.91 3668.77 1141.55 3668.95 1141.14Z" fill="white"/>
                    <path d="M3538.61 1045.01C3538.14 1044.88 3537.92 1044.58 3538.22 1044.01C3538.6 1043.28 3539.44 1042.79 3539.98 1043.02C3540.89 1043.4 3539.77 1044.25 3540.37 1044.77C3540.47 1044.86 3539.33 1045.27 3538.61 1045.01Z" fill="white"/>
                    <path d="M3721.7 1037.59C3721.86 1037.51 3722.03 1037.33 3722.17 1037.35C3722.93 1037.49 3723.72 1039.01 3723.36 1039.66C3723.24 1039.88 3722.99 1040.17 3722.8 1040.18C3722.59 1040.18 3722.3 1039.93 3722.18 1039.72C3721.65 1038.77 3721.51 1038.04 3721.7 1037.59Z" fill="white"/>
                    <path d="M3724.37 927.22C3724.1 927.1 3723.81 927.02 3723.58 926.86C3722.99 926.43 3723.53 926.02 3723.65 925.61C3723.81 925.08 3724.19 925.19 3724.56 925.36C3724.9 925.52 3725.27 925.6 3725.4 926.05C3725.54 926.5 3725.86 926.97 3725.42 927.37C3725.08 927.68 3724.71 927.35 3724.36 927.22H3724.37Z" fill="white"/>
                    <path d="M3668.54 1105.72C3668.19 1106.22 3667.83 1106 3667.51 1105.82C3666.74 1105.38 3666.36 1104.52 3666.71 1104C3667 1103.56 3667.37 1103.78 3667.69 1103.96C3668.46 1104.41 3668.82 1105.25 3668.54 1105.72Z" fill="white"/>
                    <path d="M3534.8 1052.41C3534.58 1051.76 3534.22 1051.21 3534.64 1050.49C3534.99 1049.9 3535.3 1050.02 3535.78 1050.22C3536.3 1050.44 3536.49 1050.65 3536.3 1051.27C3536.05 1052.09 3535.35 1052.1 3534.8 1052.41Z" fill="white"/>
                    <path d="M3537.14 1037.69C3537.29 1037.59 3537.47 1037.39 3537.59 1037.42C3537.96 1037.52 3538.44 1037.62 3538.62 1037.89C3538.9 1038.33 3539.3 1038.86 3538.95 1039.45C3538.68 1039.91 3538.3 1039.7 3537.98 1039.51C3537.2 1039.06 3536.85 1038.26 3537.15 1037.69H3537.14Z" fill="white"/>
                    <path d="M3764.22 932.21C3764.4 931.85 3764.59 931.46 3765.05 931.76C3765.65 932.16 3765.96 932.79 3765.58 933.42C3765.38 933.75 3764.79 933.85 3764.37 934.05C3764.17 933.44 3763.7 932.86 3764.21 932.21H3764.22Z" fill="white"/>
                    <path d="M3609.44 910.31C3610.07 910.64 3610.4 911.68 3610.13 912.1C3609.84 912.53 3609.5 912.29 3609.15 912.15C3608.45 911.88 3608.17 911.32 3608.34 910.69C3608.39 910.5 3609.09 910.15 3609.44 910.31Z" fill="white"/>
                    <path d="M3624.83 1074.09C3625.03 1073.64 3625.27 1073.18 3625.67 1073.52C3626.16 1073.95 3626.5 1074.56 3626.9 1075.09C3626.29 1075.37 3625.72 1075.59 3625.02 1075.17C3624.36 1074.78 3624.74 1074.4 3624.83 1074.08V1074.09Z" fill="white"/>
                    <path d="M3545.58 949.03C3545.83 948.26 3546.2 948.37 3546.74 948.56C3547.56 948.85 3547.41 949.29 3547.15 949.89C3546.88 950.51 3546.55 950.43 3546.02 950.23C3545.24 949.93 3545.21 949.51 3545.59 949.03H3545.58Z" fill="white"/>
                    <path d="M3631.75 1084.65C3632.18 1084.95 3633.11 1084.93 3632.57 1085.78C3632.28 1086.24 3631.68 1086.5 3631.09 1086.31C3630.12 1086 3630.82 1085.11 3630.76 1084.6C3630.68 1083.96 3631.39 1084.71 3631.74 1084.65H3631.75Z" fill="white"/>
                    <path d="M3677.99 1158.7C3678.11 1158.44 3678.19 1158.15 3678.35 1157.92C3678.64 1157.49 3679.01 1157.72 3679.34 1157.89C3679.58 1158.02 3679.94 1158.02 3679.97 1158.39C3680 1158.81 3679.81 1159.09 3679.39 1159.21C3679.21 1159.26 3679.03 1159.35 3678.85 1159.41C3678.5 1159.53 3677.99 1159.84 3677.84 1159.72C3677.49 1159.44 3677.88 1159.05 3677.99 1158.7Z" fill="white"/>
                    <path d="M3658.7 882.95C3658.17 882.49 3658.65 882.08 3658.81 881.69C3659.11 880.96 3659.58 881.43 3659.98 881.58C3660.35 881.72 3660.7 881.92 3660.42 882.41C3660.13 882.91 3659.13 883.22 3658.7 882.95Z" fill="white"/>
                    <path d="M3589.57 1022.92C3590.03 1023.15 3590.4 1023.32 3590.12 1023.82C3589.83 1024.32 3588.82 1024.65 3588.4 1024.35C3587.98 1024.05 3588.26 1023.71 3588.36 1023.35C3588.61 1022.48 3589.11 1022.66 3589.57 1022.92Z" fill="white"/>
                    <path d="M3649.23 1120.44C3649.08 1120.31 3648.7 1120.14 3648.7 1119.97C3648.69 1119.49 3649.23 1119.52 3649.5 1119.49C3649.9 1119.45 3650.44 1118.5 3650.77 1119.58C3650.88 1119.94 3651.11 1120.29 3650.82 1120.63C3650.24 1121.33 3649.83 1120.45 3649.23 1120.44Z" fill="white"/>
                    <path d="M3775.18 1034.24C3775.58 1034.01 3776.15 1033.38 3776.44 1033.9C3776.69 1034.35 3777.51 1034.41 3777.28 1035.08C3777.14 1035.48 3775.59 1035.43 3775.2 1035.02C3774.99 1034.81 3774.99 1034.52 3775.18 1034.24Z" fill="white"/>
                    <path d="M3563.12 954.59C3563.12 954.59 3563.04 954.07 3563.04 953.59C3563.04 953.38 3564.37 953.14 3564.59 953.3C3565.18 953.73 3564.65 954.13 3564.53 954.53C3564.38 955.02 3564.03 955 3563.12 954.58V954.59Z" fill="white"/>
                    <path d="M3581.63 925C3582.09 924.46 3582.47 924.97 3582.88 925.09C3583.39 925.25 3583.4 925.7 3583.12 925.96C3582.8 926.26 3582.32 926.43 3581.89 926.57C3581.73 926.62 3581.48 925.21 3581.64 925H3581.63Z" fill="white"/>
                    <path d="M3757.61 885.58C3758.12 885.7 3758.21 886 3758.09 886.25C3757.78 886.94 3757.17 887.11 3756.47 886.98C3756.11 886.91 3756.02 886.61 3756.13 886.34C3756.43 885.61 3757.06 885.52 3757.6 885.58H3757.61Z" fill="white"/>
                    <path d="M3734.18 1056.35C3733.65 1056.01 3733.39 1055.46 3733.59 1055C3733.69 1054.77 3734.34 1054.44 3734.86 1054.69C3735.56 1055.03 3735.11 1055.46 3734.97 1055.86C3734.84 1056.24 3734.64 1056.54 3734.18 1056.35Z" fill="white"/>
                    <path d="M3662.68 863.93C3662.95 864.01 3663.66 865.2 3663.5 865.27C3663.08 865.45 3662.62 865.64 3662.18 865.62C3661.77 865.6 3661.54 865.24 3661.8 864.8C3662.02 864.44 3661.96 863.79 3662.68 863.92V863.93Z" fill="white"/>
                    <path d="M3733.41 1062.35C3733.78 1061.94 3734.31 1061.59 3735.02 1061.93C3735.28 1062.05 3735.42 1062.34 3735.2 1062.63C3734.76 1063.2 3734.2 1063.47 3733.51 1063.17C3733.25 1063.06 3733.12 1062.77 3733.42 1062.35H3733.41Z" fill="white"/>
                    <path d="M3786.55 1101.85C3786.74 1101.34 3787.17 1101.32 3787.44 1101.6C3787.74 1101.92 3787.91 1102.4 3788.05 1102.83C3788.1 1102.99 3786.69 1103.24 3786.48 1103.08C3786.17 1102.86 3786.21 1102.38 3786.56 1101.85H3786.55Z" fill="white"/>
                    <path d="M3632.03 1079.79C3632.21 1079.42 3632.38 1079.05 3632.86 1079.31C3633.39 1079.6 3633.63 1080.17 3633.43 1080.63C3633.33 1080.86 3632.68 1081.19 3632.16 1080.94C3631.45 1080.6 3631.94 1080.17 3632.03 1079.79Z" fill="white"/>
                    <path d="M3704.18 1139.53C3704.62 1139.19 3705.05 1138.92 3705.63 1139.1C3705.97 1139.21 3706.09 1139.5 3705.94 1139.75C3705.69 1140.16 3705.8 1141.03 3704.89 1140.72C3704.34 1140.53 3704.47 1139.92 3704.18 1139.54V1139.53Z" fill="white"/>
                    <path d="M3644.71 1074.69C3644.86 1074.6 3645.02 1074.52 3645.17 1074.43C3645.49 1075.22 3645.69 1076.08 3646.44 1076.63C3646.47 1076.65 3646.33 1077.01 3646.2 1077.14C3646.13 1077.21 3645.79 1077.18 3645.78 1077.14C3645.58 1076.38 3644.61 1076.1 3644.59 1075.24C3644.59 1075.06 3644.67 1074.88 3644.71 1074.7V1074.69Z" fill="white"/>
                    <path d="M3649.23 1075.59C3649.75 1075.86 3649.15 1076.42 3648.92 1076.81C3648.48 1077.59 3647.96 1076.85 3647.5 1076.72C3647.36 1076.68 3647.01 1076.18 3647.51 1076.23C3648.27 1076.31 3647.98 1074.86 3649.22 1075.59H3649.23Z" fill="white"/>
                    <path d="M3714.63 904.03C3713.94 903.85 3714.03 903.15 3713.76 902.69C3714.1 902.55 3714.54 902.2 3714.76 902.31C3715.25 902.54 3715.39 903.08 3715.26 903.65C3715.18 903.99 3714.93 904.05 3714.63 904.03Z" fill="white"/>
                    <path d="M3565.75 960.96C3566.1 961.19 3566.74 961.2 3566.44 961.93C3566.22 962.46 3565.87 962.33 3565.51 962.15C3565.13 961.95 3564.51 961.92 3564.81 961.19C3565.03 960.66 3565.38 960.81 3565.75 960.96Z" fill="white"/>
                    <path d="M3785.98 937.51C3786.8 936.99 3787.42 937.07 3788.01 937.47C3788.15 937.56 3788.21 937.76 3788.31 937.92C3788.13 937.99 3787.93 938.03 3787.78 938.13C3787.19 938.53 3786.69 938.2 3786.2 937.93C3786.05 937.85 3786.01 937.57 3785.98 937.52V937.51Z" fill="white"/>
                    <path d="M3540.59 944.09C3540.64 943.95 3541.01 944.04 3541.37 944.23C3541.75 944.43 3542.39 944.45 3542.09 945.19C3541.88 945.71 3541.53 945.62 3541.17 945.43C3540.79 945.23 3540.16 945.19 3540.6 944.09H3540.59Z" fill="white"/>
                    <path d="M3579.14 993.68C3579.21 993.79 3579.4 993.95 3579.37 994.03C3579.16 994.66 3578.93 995.27 3578.13 995.42C3577.8 995.48 3577.61 995.47 3577.6 995.14C3577.6 994.61 3578.67 993.61 3579.14 993.68Z" fill="white"/>
                    <path d="M3668.01 920.73C3668.6 919.99 3669.02 920.83 3669.51 920.95C3669.73 921 3669.7 921.47 3669.51 921.7C3668.94 922.38 3668.48 921.66 3668.01 921.5C3667.79 921.42 3667.82 921 3668.01 920.74V920.73Z" fill="white"/>
                    <path d="M3635.64 1031.01C3636.23 1030.27 3636.65 1031.11 3637.14 1031.22C3637.36 1031.27 3637.33 1031.74 3637.14 1031.97C3636.57 1032.65 3636.11 1031.93 3635.64 1031.77C3635.42 1031.69 3635.45 1031.27 3635.64 1031.01Z" fill="white"/>
                    <path d="M3766.51 1009.93C3766.56 1009.79 3766.93 1009.88 3767.29 1010.07C3767.67 1010.27 3768.31 1010.29 3768.01 1011.03C3767.8 1011.55 3767.45 1011.46 3767.09 1011.27C3766.71 1011.07 3766.08 1011.03 3766.52 1009.93H3766.51Z" fill="white"/>
                    <path d="M3593.5 1096.71C3593.55 1097.32 3593.6 1097.96 3593.64 1098.6C3593.64 1098.63 3593.46 1098.71 3593.39 1098.69C3592.99 1098.6 3592.77 1098.35 3592.64 1097.94C3592.49 1097.49 3592.27 1097.05 3592.77 1096.7C3592.89 1096.62 3593.11 1096.7 3593.51 1096.7L3593.5 1096.71Z" fill="white"/>
                    <path d="M3647.88 1100.3C3647.51 1100.17 3647.1 1100.04 3647.26 1099.53C3647.46 1098.86 3647.89 1098.98 3648.38 1099.23C3648.72 1099.4 3649.15 1099.49 3648.99 1100.01C3648.79 1100.67 3648.35 1100.6 3647.89 1100.31L3647.88 1100.3Z" fill="white"/>
                    <path d="M3707.09 1090.47C3707.45 1090.06 3707.67 1089.89 3707.97 1090.11C3708.54 1090.55 3708.8 1091.1 3708.51 1091.8C3708.45 1091.94 3708.22 1092 3708.07 1092.1C3707.73 1091.53 3707.39 1090.97 3707.1 1090.47H3707.09Z" fill="white"/>
                    <path d="M3604.44 1032.56C3604.59 1032.05 3604.85 1031.73 3605.07 1031.84C3605.51 1032.08 3606.35 1031.95 3606.22 1032.82C3606.18 1033.12 3605.9 1033.43 3605.65 1033.33C3605.19 1033.13 3604.48 1033.13 3604.43 1032.55L3604.44 1032.56Z" fill="white"/>
                    <path d="M3766.22 990.28C3766.4 989.92 3766.56 989.57 3767.07 989.82C3767.78 990.16 3767.34 990.6 3767.19 991.01C3767.05 991.38 3766.87 991.71 3766.35 991.46C3765.63 991.12 3766.11 990.68 3766.22 990.28Z" fill="white"/>
                    <path d="M3684.38 953.53C3683.64 952.94 3684.48 952.52 3684.59 952.03C3684.64 951.81 3685.11 951.84 3685.34 952.03C3686.02 952.6 3685.3 953.06 3685.14 953.53C3685.06 953.75 3684.64 953.72 3684.38 953.53Z" fill="white"/>
                    <path d="M3704.74 1101.01C3704.37 1100.88 3703.96 1100.75 3704.12 1100.24C3704.32 1099.57 3704.75 1099.69 3705.24 1099.94C3705.58 1100.11 3706.01 1100.2 3705.85 1100.72C3705.65 1101.38 3705.21 1101.31 3704.75 1101.02L3704.74 1101.01Z" fill="white"/>
                    <path d="M3548.9 1046.96C3548.26 1046.74 3548.19 1046.49 3548.29 1046.19C3548.51 1045.53 3548.95 1045.66 3549.4 1045.92C3549.63 1046.05 3550.16 1046.22 3549.99 1046.39C3549.65 1046.73 3549.11 1046.86 3548.9 1046.96Z" fill="white"/>
                    <path d="M3767.28 952.94C3767.4 952.84 3767.6 952.66 3767.8 952.49C3767.97 953 3768.45 953.46 3768.09 954.05C3767.94 954.3 3767.7 954.4 3767.38 954.24C3766.68 953.9 3767.11 953.47 3767.28 952.94Z" fill="white"/>
                    <path d="M3710.04 1159.89C3710.3 1159.95 3710.39 1160.22 3710.31 1160.5C3710.2 1160.87 3709.97 1161.24 3709.5 1160.95C3709.17 1160.74 3708.41 1160.81 3708.67 1160.18C3708.76 1159.95 3709.43 1159.63 3710.05 1159.89H3710.04Z" fill="white"/>
                    <path d="M3540.9 1090.35C3541.1 1089.69 3541.79 1089.77 3542.26 1089.52C3542.25 1089.79 3542.3 1090.08 3542.2 1090.31C3542.03 1090.7 3541.99 1091.31 3541.26 1091.02C3540.93 1090.89 3540.87 1090.67 3540.89 1090.35H3540.9Z" fill="white"/>
                    <path d="M3720.52 1128.23C3720.92 1128.31 3720.53 1128.12 3720.61 1128.5C3720.65 1128.7 3720.47 1129.09 3720.29 1129.17C3719.8 1129.38 3719.3 1129.82 3718.73 1129.35C3718.67 1129.3 3718.6 1129.14 3718.63 1129.1C3719.13 1128.51 3719.92 1128.56 3720.52 1128.22V1128.23Z" fill="white"/>
                    <path d="M3706.51 1074.74C3707.03 1074.58 3707.56 1074.12 3708.08 1074.61C3708.21 1074.73 3708.17 1075.19 3708.04 1075.37C3707.63 1075.95 3707.22 1075.41 3706.81 1075.3C3706.53 1075.22 3706.35 1075.06 3706.51 1074.74Z" fill="white"/>
                    <path d="M3657.68 993.29C3657.76 993.11 3657.8 992.9 3657.93 992.77C3658.01 992.68 3658.29 992.65 3658.36 992.71C3658.58 992.94 3657.86 994.62 3657.51 994.67C3657.43 994.68 3657.21 994.43 3657.23 994.36C3657.35 993.99 3657.53 993.65 3657.69 993.29H3657.68Z" fill="white"/>
                    <path d="M3802.62 1062.96C3802.83 1062.39 3803.2 1062.45 3803.55 1062.66C3803.81 1062.81 3803.9 1063.04 3803.74 1063.38C3803.51 1063.88 3803.17 1063.83 3802.82 1063.61C3802.57 1063.46 3802.39 1063.22 3802.61 1062.96H3802.62Z" fill="white"/>
                    <path d="M3540.44 1071.02C3540.65 1070.78 3540.84 1070.67 3541.18 1070.82C3541.68 1071.05 3541.62 1071.39 3541.42 1071.74C3541.27 1072 3541.04 1072.09 3540.7 1071.93C3540.2 1071.7 3540.29 1071.38 3540.45 1071.02H3540.44Z" fill="white"/>
                    <path d="M3656.97 887.23C3657.18 886.99 3657.37 886.88 3657.71 887.03C3658.21 887.26 3658.15 887.6 3657.95 887.95C3657.8 888.21 3657.57 888.3 3657.23 888.14C3656.73 887.91 3656.82 887.59 3656.98 887.23H3656.97Z" fill="white"/>
                    <path d="M3764.04 922.2C3764.37 922.36 3764.66 922.54 3764.44 923.04C3764.29 923.38 3764.06 923.47 3763.78 923.37C3763.39 923.25 3763.12 923.03 3763.34 922.53C3763.49 922.2 3763.7 922.14 3764.04 922.2Z" fill="white"/>
                    <path d="M3542.31 966C3541.95 965.59 3541.71 965.15 3541.87 964.59C3541.91 964.44 3542.21 964.23 3542.22 964.24C3542.64 964.6 3542.87 965.06 3542.69 965.61C3542.64 965.76 3542.45 965.86 3542.31 966Z" fill="white"/>
                    <path d="M3618.38 892.19C3618.46 891.85 3618.74 891.78 3619.02 891.87C3619.41 891.99 3619.68 892.21 3619.46 892.71C3619.31 893.05 3619.08 893.13 3618.8 893.04C3618.41 892.92 3618.12 892.68 3618.38 892.18V892.19Z" fill="white"/>
                    <path d="M3594.77 1007.58C3594.98 1007.34 3595.17 1007.23 3595.51 1007.38C3596.01 1007.61 3595.95 1007.95 3595.75 1008.3C3595.6 1008.56 3595.37 1008.65 3595.03 1008.49C3594.53 1008.26 3594.62 1007.94 3594.77 1007.58Z" fill="white"/>
                    <path d="M3765.46 1117.02C3765.7 1116.74 3765.9 1116.63 3766.23 1116.78C3766.73 1117.01 3766.67 1117.35 3766.47 1117.7C3766.32 1117.96 3766.09 1118.04 3765.75 1117.89C3765.23 1117.66 3765.38 1117.33 3765.46 1117.02Z" fill="white"/>
                    <path d="M3741.82 1111.73C3742.35 1111.93 3742.29 1112.29 3742.08 1112.65C3741.93 1112.91 3741.7 1113 3741.36 1112.84C3740.86 1112.61 3740.92 1112.27 3741.12 1111.92C3741.27 1111.66 3741.52 1111.52 3741.81 1111.73H3741.82Z" fill="white"/>
                    <path d="M3530.97 926.44C3530.8 926.78 3530.63 927.14 3530.15 926.87C3530 926.79 3529.89 926.63 3529.77 926.51C3530.14 926.31 3530.51 926.11 3530.89 925.91C3530.92 926.09 3530.95 926.26 3530.98 926.45L3530.97 926.44Z" fill="white"/>
                    <path d="M3584.33 929.14C3584.5 928.8 3584.67 928.44 3585.15 928.71C3585.3 928.79 3585.41 928.95 3585.53 929.07C3585.16 929.27 3584.79 929.47 3584.41 929.67C3584.38 929.49 3584.35 929.32 3584.32 929.13L3584.33 929.14Z" fill="white"/>
                    <path d="M3561.84 1064.09C3562.21 1063.94 3562.56 1063.8 3562.91 1063.65C3562.9 1063.91 3562.97 1064.22 3562.86 1064.43C3562.61 1064.89 3562.22 1064.69 3561.91 1064.48C3561.83 1064.42 3561.85 1064.2 3561.84 1064.09Z" fill="white"/>
                    <path d="M3692.04 956.94C3692.2 957.1 3692.46 957.28 3692.43 957.34C3692.24 957.82 3691.69 957.72 3691.33 957.94C3691.3 957.76 3691.21 957.57 3691.26 957.41C3691.38 957.02 3691.61 956.75 3692.04 956.94Z" fill="white"/>
                    <path d="M3752.61 885.67C3752.74 885.59 3752.89 885.43 3752.99 885.45C3753.35 885.55 3753.76 885.7 3753.57 886.19C3753.49 886.41 3753.21 886.56 3753.02 886.74C3752.88 886.39 3752.75 886.03 3752.61 885.66V885.67Z" fill="white"/>
                    <path d="M3726.74 899.7C3726.24 899.5 3726.34 899.14 3726.54 898.8C3726.62 898.66 3726.83 898.6 3726.98 898.51C3727.08 898.92 3727.18 899.33 3727.27 899.74C3727.09 899.73 3726.92 899.71 3726.74 899.7Z" fill="white"/>
                    <path d="M3579.98 1048.24C3580.04 1048.2 3580.19 1048.02 3580.29 1048.04C3580.65 1048.13 3581.06 1048.29 3580.89 1048.77C3580.81 1048.99 3580.53 1049.15 3580.35 1049.33C3580.23 1048.97 3580.11 1048.62 3579.98 1048.23V1048.24Z" fill="white"/>
                    <path d="M3597.13 1013.93C3597.33 1013.43 3597.69 1013.53 3598.03 1013.73C3598.17 1013.81 3598.23 1014.02 3598.32 1014.17C3597.91 1014.27 3597.5 1014.37 3597.09 1014.46C3597.1 1014.28 3597.12 1014.11 3597.13 1013.93Z" fill="white"/>
                    <path d="M3687.83 870.5C3687.98 870.39 3688.13 870.29 3688.27 870.19C3688.39 870.59 3688.51 871 3688.62 871.4C3688.44 871.4 3688.26 871.43 3688.1 871.38C3687.6 871.22 3687.67 870.87 3687.83 870.5Z" fill="white"/>
                    <path d="M3743.12 1092.83C3742.62 1092.63 3742.72 1092.27 3742.92 1091.93C3743 1091.79 3743.21 1091.73 3743.36 1091.64C3743.46 1092.05 3743.56 1092.46 3743.65 1092.87C3743.47 1092.86 3743.3 1092.84 3743.12 1092.83Z" fill="white"/>
                    <path d="M3781.22 1107.28C3781.19 1107.03 3781.11 1106.84 3781.16 1106.69C3781.29 1106.3 3781.53 1106.03 3781.99 1106.29C3782.14 1106.38 3782.24 1106.54 3782.36 1106.67C3781.99 1106.87 3781.62 1107.07 3781.23 1107.29L3781.22 1107.28Z" fill="white"/>
                    <path d="M3562.63 1124.84C3562.95 1124.6 3563.19 1124.85 3563.43 1124.97C3563.5 1125.01 3563.54 1125.33 3563.47 1125.39C3563.19 1125.64 3562.93 1125.38 3562.7 1125.26C3562.62 1125.22 3562.65 1124.98 3562.63 1124.84Z" fill="white"/>
                    <path d="M3559.32 1099.17C3559.36 1098.85 3559.35 1098.57 3559.45 1098.33C3559.51 1098.19 3559.74 1098.13 3559.89 1098.03C3559.89 1098.29 3559.92 1098.57 3559.84 1098.82C3559.8 1098.95 3559.56 1099.02 3559.32 1099.17Z" fill="white"/>
                    <path d="M3802.45 1003.92C3802.77 1003.68 3803.01 1003.93 3803.25 1004.05C3803.32 1004.09 3803.36 1004.41 3803.29 1004.47C3803.01 1004.72 3802.75 1004.46 3802.52 1004.34C3802.44 1004.3 3802.47 1004.06 3802.45 1003.92Z" fill="white"/>
                    <path d="M3754.41 904.52C3754.73 904.56 3755.01 904.55 3755.25 904.65C3755.39 904.71 3755.45 904.94 3755.55 905.09C3755.29 905.09 3755.01 905.12 3754.76 905.04C3754.63 905 3754.56 904.76 3754.41 904.52Z" fill="white"/>
                    <path d="M3751.36 903.59C3751.5 903.66 3751.75 903.76 3751.94 903.92C3752.16 904.12 3752.08 904.31 3751.78 904.27C3751.53 904.23 3751.27 904.11 3751.07 903.95C3750.84 903.76 3750.92 903.6 3751.35 903.59H3751.36Z" fill="white"/>
                    <path d="M3816.03 992.2C3815.91 992.07 3815.67 991.91 3815.69 991.82C3815.78 991.48 3816.05 991.51 3816.29 991.67C3816.43 991.77 3816.66 991.95 3816.64 992.04C3816.55 992.38 3816.29 992.33 3816.04 992.19L3816.03 992.2Z" fill="white"/>
                    <path d="M3546.37 1067.94C3546.5 1067.82 3546.66 1067.58 3546.75 1067.6C3547.09 1067.69 3547.06 1067.96 3546.9 1068.2C3546.8 1068.34 3546.62 1068.57 3546.53 1068.55C3546.19 1068.46 3546.24 1068.2 3546.38 1067.95L3546.37 1067.94Z" fill="white"/>
                    <path d="M3679.78 877.51C3679.82 877.19 3679.81 876.91 3679.91 876.67C3679.97 876.53 3680.2 876.47 3680.35 876.37C3680.35 876.63 3680.38 876.91 3680.3 877.16C3680.26 877.29 3680.02 877.36 3679.78 877.51Z" fill="white"/>
                    <path d="M3689.68 1136.97C3689.79 1136.9 3689.94 1136.8 3690.09 1136.71C3690.09 1136.97 3690.12 1137.25 3690.04 1137.5C3690 1137.63 3689.76 1137.7 3689.61 1137.8C3689.47 1137.53 3689.46 1137.26 3689.68 1136.98V1136.97Z" fill="white"/>
                    <path d="M3637.45 861.11C3637.72 861.21 3637.93 861.37 3637.74 861.66C3637.69 861.74 3637.4 861.69 3637.23 861.66C3636.95 861.59 3636.74 861.41 3636.94 861.11C3636.99 861.03 3637.27 861.11 3637.45 861.11Z" fill="white"/>
                    <path d="M3707.11 1100.77C3707.38 1100.87 3707.59 1101.03 3707.4 1101.32C3707.35 1101.4 3707.06 1101.35 3706.89 1101.32C3706.61 1101.25 3706.4 1101.07 3706.6 1100.77C3706.65 1100.69 3706.93 1100.77 3707.11 1100.77Z" fill="white"/>
                    <path d="M3702.61 1089.02C3702.88 1089.12 3703.09 1089.28 3702.9 1089.57C3702.85 1089.65 3702.56 1089.6 3702.39 1089.57C3702.11 1089.5 3701.9 1089.32 3702.1 1089.03C3702.15 1088.95 3702.43 1089.03 3702.61 1089.03V1089.02Z" fill="white"/>
                    <path d="M3680.06 1048.5C3680.1 1048.18 3680.09 1047.9 3680.19 1047.66C3680.25 1047.52 3680.48 1047.46 3680.63 1047.36C3680.63 1047.62 3680.66 1047.9 3680.58 1048.15C3680.54 1048.28 3680.3 1048.35 3680.06 1048.5Z" fill="white"/>
                    <path d="M3572.27 1054.92C3572.44 1054.78 3572.65 1054.62 3572.86 1054.45C3572.89 1054.63 3573 1054.85 3572.93 1054.97C3572.8 1055.19 3572.57 1055.35 3572.38 1055.53C3572.35 1055.35 3572.32 1055.18 3572.28 1054.91L3572.27 1054.92Z" fill="white"/>
                    <path d="M3632.21 982.01C3632.32 981.94 3632.47 981.84 3632.62 981.75C3632.62 982.01 3632.65 982.29 3632.57 982.54C3632.53 982.67 3632.29 982.74 3632.14 982.84C3632 982.57 3631.99 982.3 3632.21 982.02V982.01Z" fill="white"/>
                    <path d="M3593.74 956.67C3593.78 956.35 3593.77 956.07 3593.87 955.83C3593.93 955.69 3594.16 955.63 3594.31 955.53C3594.31 955.79 3594.34 956.07 3594.26 956.32C3594.22 956.45 3593.98 956.52 3593.74 956.67Z" fill="white"/>
                    <path d="M3533.14 917.41C3533.46 917.17 3533.7 917.42 3533.94 917.54C3534.01 917.58 3534.05 917.9 3533.98 917.96C3533.7 918.21 3533.44 917.95 3533.21 917.83C3533.13 917.79 3533.16 917.55 3533.14 917.41Z" fill="white"/>
                    <path d="M3597.83 914.88C3598.1 914.98 3598.31 915.14 3598.12 915.43C3598.07 915.51 3597.78 915.46 3597.61 915.43C3597.33 915.36 3597.12 915.18 3597.32 914.89C3597.37 914.81 3597.65 914.89 3597.83 914.89V914.88Z" fill="white"/>
                    <path d="M3784.32 1007.92C3784.2 1007.79 3783.96 1007.63 3783.98 1007.54C3784.07 1007.2 3784.34 1007.23 3784.58 1007.39C3784.72 1007.49 3784.95 1007.67 3784.93 1007.76C3784.84 1008.1 3784.58 1008.05 3784.33 1007.91L3784.32 1007.92Z" fill="white"/>
                    <path d="M3690.84 983.05C3690.97 982.93 3691.13 982.69 3691.22 982.71C3691.56 982.8 3691.53 983.07 3691.37 983.31C3691.27 983.45 3691.09 983.68 3691 983.66C3690.66 983.57 3690.71 983.31 3690.85 983.06L3690.84 983.05Z" fill="white"/>
                    <path d="M3696.31 968.61C3696.31 968.46 3696.27 968.31 3696.31 968.17C3696.32 968.12 3696.47 968.1 3696.56 968.07C3696.56 968.22 3696.6 968.37 3696.56 968.51C3696.56 968.56 3696.4 968.58 3696.31 968.61Z" fill="white"/>
                    <path d="M3688.93 860.06C3688.93 859.91 3688.89 859.76 3688.93 859.62C3688.94 859.57 3689.09 859.55 3689.18 859.52C3689.18 859.67 3689.22 859.82 3689.18 859.96C3689.18 860.01 3689.02 860.03 3688.93 860.06Z" fill="white"/>
                    <path d="M3693.16 1081.32C3693.27 1081.23 3693.39 1081.15 3693.51 1081.06C3693.54 1081.15 3693.63 1081.27 3693.6 1081.31C3693.51 1081.42 3693.38 1081.5 3693.27 1081.6C3693.24 1081.51 3693.2 1081.43 3693.16 1081.32Z" fill="white"/>
                    <path d="M3728.06 1111.85C3727.96 1111.89 3727.87 1111.92 3727.78 1111.96C3727.78 1111.81 3727.74 1111.66 3727.78 1111.52C3727.78 1111.47 3727.94 1111.45 3728.03 1111.42C3728.04 1111.57 3728.05 1111.71 3728.07 1111.85H3728.06Z" fill="white"/>
                    <path d="M3648.65 1078.01C3648.74 1078.12 3648.82 1078.24 3648.91 1078.36C3648.82 1078.39 3648.7 1078.48 3648.66 1078.45C3648.55 1078.36 3648.47 1078.23 3648.37 1078.12C3648.46 1078.09 3648.54 1078.05 3648.65 1078.01Z" fill="white"/>
                    <path d="M3633.46 1034.42C3633.61 1034.42 3633.76 1034.38 3633.9 1034.42C3633.95 1034.43 3633.97 1034.58 3634 1034.67C3633.85 1034.67 3633.7 1034.71 3633.56 1034.67C3633.51 1034.67 3633.49 1034.51 3633.46 1034.42Z" fill="white"/>
                    <path d="M3591.42 1010.21C3591.46 1010.31 3591.49 1010.4 3591.53 1010.49C3591.38 1010.49 3591.23 1010.53 3591.09 1010.49C3591.04 1010.49 3591.02 1010.33 3590.99 1010.24C3591.14 1010.23 3591.28 1010.22 3591.43 1010.2L3591.42 1010.21Z" fill="white"/>
                    <path d="M3550.71 1054.07C3550.82 1053.98 3550.94 1053.9 3551.06 1053.81C3551.09 1053.9 3551.18 1054.02 3551.15 1054.06C3551.06 1054.17 3550.93 1054.25 3550.82 1054.35C3550.79 1054.26 3550.75 1054.18 3550.71 1054.07Z" fill="white"/>
                    <path d="M3665.64 1087.68C3665.74 1087.64 3665.83 1087.61 3665.92 1087.57C3665.92 1087.72 3665.96 1087.87 3665.92 1088.01C3665.92 1088.06 3665.76 1088.08 3665.67 1088.11C3665.66 1087.96 3665.65 1087.82 3665.63 1087.67L3665.64 1087.68Z" fill="white"/>
                    <path d="M3656.87 1013.78C3656.77 1013.82 3656.68 1013.85 3656.59 1013.89C3656.59 1013.74 3656.55 1013.59 3656.59 1013.45C3656.59 1013.4 3656.75 1013.38 3656.84 1013.35C3656.85 1013.5 3656.86 1013.64 3656.88 1013.79L3656.87 1013.78Z" fill="white"/>
                    <path d="M3530.88 944.69C3530.85 944.6 3530.77 944.46 3530.79 944.44C3530.91 944.35 3531.04 944.24 3531.18 944.23C3531.46 944.23 3531.52 944.38 3531.31 944.56C3531.21 944.65 3531.03 944.65 3530.89 944.68L3530.88 944.69Z" fill="white"/>
                    <path d="M3531.52 944.09C3531.66 944.05 3531.88 943.94 3531.94 944C3532.12 944.16 3532.08 944.34 3531.9 944.36C3531.74 944.38 3531.58 944.34 3531.41 944.33C3531.45 944.25 3531.48 944.17 3531.52 944.09Z" fill="white"/>
                    <path d="M3726.38 966.22C3726.49 966.13 3726.61 966.05 3726.73 965.96C3726.76 966.05 3726.85 966.17 3726.82 966.21C3726.73 966.32 3726.6 966.4 3726.49 966.5C3726.46 966.41 3726.42 966.33 3726.38 966.22Z" fill="white"/>
                    <path d="M3496.26 1023.25C3496.37 1023.16 3496.49 1023.08 3496.61 1022.99C3496.64 1023.08 3496.73 1023.2 3496.7 1023.24C3496.61 1023.35 3496.48 1023.43 3496.37 1023.53C3496.34 1023.44 3496.3 1023.36 3496.26 1023.25Z" fill="white"/>
                    <path d="M3527.38 937.57C3527.48 937.53 3527.57 937.5 3527.66 937.46C3527.66 937.61 3527.7 937.76 3527.66 937.9C3527.66 937.95 3527.5 937.97 3527.41 938C3527.4 937.85 3527.39 937.71 3527.37 937.56L3527.38 937.57Z" fill="white"/>
                    <path d="M3531.3 1049.11C3531.19 1049.2 3531.07 1049.28 3530.95 1049.37C3530.92 1049.28 3530.83 1049.16 3530.86 1049.12C3530.95 1049.01 3531.08 1048.93 3531.19 1048.83C3531.22 1048.92 3531.26 1049 3531.3 1049.11Z" fill="white"/>
                    <path d="M3631.38 943.28C3631.49 943.19 3631.61 943.11 3631.73 943.02C3631.76 943.11 3631.85 943.23 3631.82 943.27C3631.73 943.38 3631.6 943.46 3631.49 943.56C3631.46 943.47 3631.42 943.39 3631.38 943.28Z" fill="white"/>
                    <path d="M3617.3 1080.25C3617.34 1080.35 3617.37 1080.44 3617.41 1080.53C3617.26 1080.53 3617.11 1080.57 3616.97 1080.53C3616.92 1080.53 3616.9 1080.37 3616.87 1080.28C3617.02 1080.27 3617.16 1080.26 3617.31 1080.24L3617.3 1080.25Z" fill="white"/>
                    <path d="M3561.99 939.15C3561.99 939 3561.95 938.85 3561.99 938.71C3562 938.66 3562.15 938.64 3562.24 938.61C3562.24 938.76 3562.28 938.91 3562.24 939.05C3562.24 939.1 3562.08 939.12 3561.99 939.15Z" fill="white"/>
                    <path d="M3604.57 1077.48C3604.68 1077.39 3604.8 1077.31 3604.92 1077.22C3604.95 1077.31 3605.04 1077.43 3605.01 1077.47C3604.92 1077.58 3604.79 1077.66 3604.68 1077.76L3604.57 1077.48Z" fill="white"/>
                    <path d="M3681.15 989.68C3681.06 989.57 3680.98 989.45 3680.89 989.33C3680.98 989.3 3681.1 989.21 3681.14 989.24C3681.25 989.33 3681.33 989.46 3681.43 989.57C3681.34 989.6 3681.26 989.64 3681.15 989.68Z" fill="white"/>
                    <path d="M3819.39 983.96C3819.39 983.81 3819.35 983.66 3819.39 983.52C3819.4 983.47 3819.55 983.45 3819.64 983.42C3819.64 983.57 3819.68 983.72 3819.64 983.86C3819.64 983.91 3819.48 983.93 3819.39 983.96Z" fill="white"/>
                    <path d="M3476.24 1099.07C3474.72 1098.07 3474.92 1096.67 3475.14 1095.35C3475.22 1094.87 3475.45 1094.14 3475.4 1093.88C3475.08 1092.19 3477.05 1092.7 3477.32 1091.69C3478.26 1092.11 3479.53 1092.29 3480.05 1093.01C3480.55 1093.7 3481.09 1094.6 3481.25 1095.54C3481.4 1096.42 3482.01 1097.59 3480.3 1097.78C3480.05 1097.81 3479.86 1098.34 3479.62 1098.62C3478.53 1099.84 3477.37 1099.95 3476.24 1099.08V1099.07Z" fill="white"/>
                    <path d="M3619.82 1068.36C3619.15 1069.2 3618.01 1069.27 3617.72 1069.03C3616.59 1068.11 3615.3 1068.8 3614.14 1068.42C3613.32 1068.15 3612.86 1067.86 3612.8 1067.02C3612.71 1065.64 3613.01 1065.08 3613.98 1064.81C3614.4 1064.69 3614.79 1064.53 3615.19 1064.36C3616.92 1063.6 3620.31 1065.39 3620.04 1067.54C3619.99 1067.92 3619.96 1068.24 3619.82 1068.35V1068.36Z" fill="white"/>
                    <path d="M3542.51 1027.07C3541.57 1027.15 3540.89 1028.36 3539.8 1027.63C3539.65 1027.53 3539.52 1027.34 3539.36 1027.3C3538.68 1027.16 3537.9 1027.26 3537.54 1026.46C3537.26 1025.83 3537.59 1025.24 3537.71 1024.65C3537.94 1023.47 3539.37 1022.55 3540.68 1022.67C3541.7 1022.76 3542.33 1023.29 3542.75 1024.25C3543.18 1025.25 3542.65 1026.12 3542.52 1027.06L3542.51 1027.07Z" fill="white"/>
                    <path d="M3565.92 898.82C3566.91 897.55 3567.83 897.33 3568.83 897.94C3569.38 898.27 3569.85 898.74 3570.38 899.11C3570.89 899.46 3570.87 899.78 3570.49 900.22C3570.07 900.71 3569.76 901.31 3569.28 901.74C3568.48 902.47 3567.47 902.58 3566.44 902.34C3566.23 902.29 3565.94 902.02 3565.92 901.83C3565.84 901.11 3565.52 900.48 3565.31 899.82C3565.21 899.5 3565.85 898.94 3565.92 898.84V898.82Z" fill="white"/>
                    <path d="M3672.01 958.53C3671.61 959.14 3671.06 960.03 3669.98 959.58C3669.47 959.37 3668.51 958.97 3668.75 958.31C3669.02 957.6 3668.73 956.59 3669.64 956.18C3670.27 955.89 3671.03 955.81 3671.55 955.4C3672.03 955.02 3672.25 955.04 3672.69 955.37C3673.31 955.84 3673.37 956.45 3673.12 957.05C3672.91 957.55 3672.47 957.94 3672.01 958.54V958.53Z" fill="white"/>
                    <path d="M3551.03 885.26C3550.59 885.85 3549.76 886 3549.16 885.59C3548.96 885.45 3547.94 883.02 3547.99 882.81C3548.11 882.33 3549.71 881.75 3549.71 881.84C3549.63 883.01 3550.87 882.31 3551.12 882.91C3551.36 883.49 3551.31 884.87 3551.03 885.25V885.26Z" fill="white"/>
                    <path d="M3482.51 1035.6C3482.81 1035.84 3483.34 1036.12 3483.12 1036.42C3482.71 1036.96 3482.5 1037.75 3481.6 1037.87C3481.1 1037.94 3480.74 1037.58 3480.33 1037.63C3479.66 1037.71 3479.34 1037.57 3479.21 1036.84C3479.07 1036.06 3479.41 1035.47 3479.99 1035.31C3480.82 1035.09 3481.79 1034.72 3482.51 1035.6Z" fill="white"/>
                    <path d="M3578.84 1028.3C3579.23 1027.86 3579.45 1027.08 3580.3 1027.79C3580.49 1027.95 3581 1027.71 3581.37 1027.68C3581.79 1027.65 3582.21 1027.66 3582.62 1027.65C3582.29 1028.18 3582.08 1028.93 3581.6 1029.18C3580.99 1029.5 3580.16 1029.4 3579.44 1029.53C3579.04 1029.6 3578.66 1029.59 3578.47 1029.24C3578.26 1028.86 3578.62 1028.58 3578.83 1028.3H3578.84Z" fill="white"/>
                    <path d="M3455.59 945.18C3455.7 945.04 3455.8 944.88 3455.92 944.75C3456.21 944.47 3456.32 943.83 3456.91 944.1C3457.51 944.38 3457.84 944.87 3457.84 945.59C3457.84 946.2 3457.5 946.3 3457.02 946.4C3456.71 946.47 3456.46 946.8 3456.14 946.87C3455.73 946.97 3455.24 947.18 3454.89 946.74C3454.48 946.22 3455.06 945.96 3455.25 945.61C3455.34 945.45 3455.47 945.32 3455.58 945.18H3455.59Z" fill="white"/>
                    <path d="M3560.78 882.57C3560.45 881.79 3559.86 881.07 3559.93 880.15C3559.94 879.94 3560.15 879.66 3560.49 879.65C3560.93 879.65 3561.47 879.34 3561.75 879.82C3562.09 880.41 3562.3 881.09 3562.49 881.74C3562.72 882.52 3561.87 882.05 3561.67 882.39C3561.47 882.74 3561.17 882.91 3560.78 882.57Z" fill="white"/>
                    <path d="M3553.43 1065.86C3553.67 1065.41 3553.99 1065.27 3554.46 1065.67C3555.19 1066.29 3555.31 1067.02 3554.82 1067.72C3554.38 1068.34 3553.76 1068.38 3553.18 1068.17C3552.52 1067.93 3552.66 1066.8 3553.43 1065.85V1065.86Z" fill="white"/>
                    <path d="M3579.74 1145.04C3579.91 1144.82 3580.05 1144.56 3580.25 1144.39C3580.74 1143.98 3581.45 1144.28 3581.53 1144.9C3581.58 1145.27 3581.7 1145.64 3581.66 1146C3581.61 1146.46 3581.29 1146.92 3580.89 1147.01C3580.42 1147.12 3579.94 1146.6 3579.4 1146.8C3579.4 1146.28 3579.03 1145.72 3579.57 1145.25C3579.64 1145.19 3579.68 1145.11 3579.74 1145.03V1145.04Z" fill="white"/>
                    <path d="M3535.42 927.46C3535.1 927.5 3534.82 927.52 3534.55 927.58C3533.87 927.72 3533.62 926.71 3532.86 926.99C3532.46 927.13 3532.13 926.88 3532.12 926.45C3532.12 926.05 3532.4 925.85 3532.82 925.81C3533.37 925.76 3533.91 925.64 3534.45 925.62C3534.71 925.61 3535.15 925.23 3535.22 925.89C3535.27 926.42 3535.35 926.95 3535.42 927.47V927.46Z" fill="white"/>
                    <path d="M3645.65 895.14C3646.06 895.57 3645.94 896.62 3645.43 897.07C3645.23 897.25 3643.52 897.12 3643.32 896.85C3642.83 896.19 3643.54 895.89 3643.83 895.53C3644.35 894.87 3645.26 894.73 3645.66 895.14H3645.65Z" fill="white"/>
                    <path d="M3638.7 972.14C3638.2 971.86 3638 971.56 3638.41 971.07C3638.91 970.47 3639.81 970.2 3640.25 970.54C3640.69 970.88 3640.66 971.84 3640.18 972.45C3639.47 973.36 3639.16 972.29 3638.7 972.15V972.14Z" fill="white"/>
                    <path d="M3641.17 978.46C3640.69 978.64 3640.18 978.87 3639.7 978.54C3639.17 978.19 3639.33 976.93 3639.91 976.44C3640.29 976.12 3640.56 976.36 3640.82 976.61C3641.42 977.17 3641.59 978.05 3641.17 978.46Z" fill="white"/>
                    <path d="M3568.44 933.95C3568.66 933.66 3568.79 933.53 3568.88 933.37C3569.22 932.78 3569.84 933.01 3570.33 932.86C3570.32 933.37 3570.69 933.91 3570.21 934.39C3570.08 934.52 3569.98 934.67 3569.88 934.82C3569.55 935.34 3569.25 935.6 3568.61 935.09C3568 934.6 3568.05 934.25 3568.44 933.94V933.95Z" fill="white"/>
                    <path d="M3684.11 1044.45C3683.57 1044.09 3683.73 1043.67 3684.03 1043.5C3684.64 1043.17 3685.31 1042.96 3685.97 1042.75C3686.59 1042.56 3686.39 1043.11 3686.47 1043.38C3686.81 1044.56 3685.55 1044.2 3685.15 1044.7C3684.69 1045.27 3684.4 1044.51 3684.11 1044.45Z" fill="white"/>
                    <path d="M3600.19 944.83C3599.84 945.52 3599.2 945.57 3598.61 945.38C3598.17 945.23 3598.01 943.71 3598.37 943.56C3599.05 943.27 3599.66 943.4 3600.23 943.92C3600.63 944.28 3600.41 944.54 3600.19 944.84V944.83Z" fill="white"/>
                    <path d="M3442.51 1006.9C3442.14 1006.5 3442.16 1005.97 3442.27 1005.46C3442.33 1005.16 3443.86 1005.19 3444.34 1005.49C3444.78 1005.77 3444.6 1006.08 3444.36 1006.37C3443.88 1006.96 3442.97 1007.23 3442.51 1006.89V1006.9Z" fill="white"/>
                    <path d="M3587.28 907.91C3587.44 908.14 3587.54 908.46 3587.24 908.6C3586.53 908.92 3585.9 909.43 3585.07 909.5C3584.72 909.53 3584.47 909.36 3584.38 909.16C3584.3 908.98 3584.42 908.54 3584.58 908.46C3585.2 908.15 3585.87 907.94 3586.52 907.7C3586.8 907.6 3587.05 907.69 3587.27 907.91H3587.28Z" fill="white"/>
                    <path d="M3594.24 878.52C3594.08 878.77 3593.89 879.08 3593.45 878.87C3593.07 878.68 3593.12 878.29 3593.02 877.96C3592.81 877.29 3593.31 876.94 3593.66 876.6C3593.87 876.4 3594.55 876.31 3594.65 876.45C3594.87 876.74 3594.97 877.23 3594.9 877.61C3594.85 877.92 3594.49 878.19 3594.23 878.52H3594.24Z" fill="white"/>
                    <path d="M3692.23 995.08C3692.63 995.3 3692.77 995.62 3692.37 996.08C3691.86 996.66 3690.99 996.93 3690.54 996.6C3689.79 996.05 3691 995.52 3690.57 994.91C3690.5 994.81 3691.63 994.68 3692.23 995.07V995.08Z" fill="white"/>
                    <path d="M3523.23 961.57C3523.07 961.61 3522.87 961.74 3522.75 961.68C3522.09 961.38 3521.7 959.82 3522.17 959.31C3522.33 959.13 3522.62 958.92 3522.8 958.96C3522.99 959 3523.2 959.3 3523.27 959.52C3523.55 960.51 3523.51 961.2 3523.24 961.57H3523.23Z" fill="white"/>
                    <path d="M3496.5 1061.87C3496.72 1062.04 3496.97 1062.17 3497.14 1062.38C3497.59 1062.9 3497 1063.16 3496.8 1063.51C3496.53 1063.96 3496.21 1063.77 3495.91 1063.54C3495.64 1063.32 3495.32 1063.16 3495.29 1062.73C3495.26 1062.29 3495.08 1061.79 3495.56 1061.52C3495.94 1061.31 3496.21 1061.69 3496.49 1061.89L3496.5 1061.87Z" fill="white"/>
                    <path d="M3586.82 910.98C3587.25 910.6 3587.53 910.88 3587.78 911.12C3588.38 911.69 3588.54 912.56 3588.11 912.96C3587.75 913.3 3587.46 913.02 3587.2 912.78C3586.6 912.2 3586.45 911.36 3586.81 910.98H3586.82Z" fill="white"/>
                    <path d="M3697.34 989.14C3697.4 989.78 3697.61 990.36 3697.07 990.93C3696.62 991.39 3696.36 991.22 3695.96 990.93C3695.53 990.62 3695.41 990.38 3695.72 989.85C3696.13 989.16 3696.77 989.3 3697.34 989.14Z" fill="white"/>
                    <path d="M3691.97 1002.09C3691.81 1002.15 3691.6 1002.29 3691.5 1002.24C3691.19 1002.07 3690.77 1001.87 3690.67 1001.58C3690.51 1001.12 3690.26 1000.55 3690.71 1000.08C3691.06 999.72 3691.36 999.99 3691.61 1000.24C3692.22 1000.82 3692.37 1001.63 3691.97 1002.09Z" fill="white"/>
                    <path d="M3461.17 1048.54C3460.93 1048.83 3460.67 1049.15 3460.31 1048.77C3459.85 1048.28 3459.7 1047.63 3460.19 1047.14C3460.44 1046.88 3461.01 1046.93 3461.43 1046.83C3461.48 1047.43 3461.78 1048.06 3461.17 1048.55V1048.54Z" fill="white"/>
                    <path d="M3597.84 1102.62C3597.34 1102.18 3597.26 1101.15 3597.6 1100.83C3597.96 1100.5 3598.22 1100.8 3598.51 1101C3599.09 1101.4 3599.22 1101.98 3598.93 1102.51C3598.84 1102.67 3598.12 1102.83 3597.84 1102.61V1102.62Z" fill="white"/>
                    <path d="M3619.82 949.51C3619.54 949.88 3619.22 950.25 3618.93 949.84C3618.57 949.34 3618.4 948.71 3618.15 948.13C3618.77 948.01 3619.34 947.94 3619.88 948.47C3620.39 948.97 3619.97 949.24 3619.82 949.5V949.51Z" fill="white"/>
                    <path d="M3664.74 1081.28C3664.34 1081.93 3664.02 1081.75 3663.58 1081.46C3662.89 1081.01 3663.12 1080.65 3663.5 1080.15C3663.89 1079.64 3664.17 1079.78 3664.61 1080.09C3665.26 1080.54 3665.19 1080.93 3664.74 1081.28Z" fill="white"/>
                    <path d="M3615.81 938.34C3615.48 937.97 3614.63 937.79 3615.31 937.13C3615.68 936.77 3616.28 936.67 3616.77 936.97C3617.59 937.47 3616.75 938.12 3616.7 938.6C3616.63 939.2 3616.15 938.36 3615.81 938.34Z" fill="white"/>
                    <path d="M3589.84 860.47C3589.67 860.68 3589.54 860.93 3589.34 861.11C3588.98 861.43 3588.7 861.15 3588.43 860.92C3588.24 860.75 3587.91 860.67 3587.96 860.33C3588.02 859.94 3588.26 859.72 3588.67 859.7C3588.85 859.7 3589.03 859.65 3589.21 859.63C3589.55 859.6 3590.09 859.42 3590.2 859.57C3590.46 859.9 3590.01 860.18 3589.83 860.47H3589.84Z" fill="white"/>
                    <path d="M3546.78 1116.79C3547.16 1117.33 3546.64 1117.59 3546.4 1117.92C3545.97 1118.52 3545.64 1117.99 3545.3 1117.76C3544.99 1117.55 3544.71 1117.29 3545.08 1116.9C3545.45 1116.5 3546.43 1116.44 3546.77 1116.79H3546.78Z" fill="white"/>
                    <path d="M3640.79 1004.05C3640.42 1003.74 3640.12 1003.5 3640.49 1003.11C3640.86 1002.71 3641.86 1002.64 3642.18 1003C3642.5 1003.37 3642.16 1003.62 3641.99 1003.92C3641.57 1004.66 3641.15 1004.38 3640.79 1004.05Z" fill="white"/>
                    <path d="M3607.71 901.78C3607.82 901.93 3608.13 902.17 3608.09 902.33C3608 902.77 3607.51 902.63 3607.26 902.59C3606.88 902.54 3606.19 903.29 3606.12 902.23C3606.1 901.88 3605.97 901.51 3606.31 901.26C3606.99 900.75 3607.18 901.64 3607.72 901.78H3607.71Z" fill="white"/>
                    <path d="M3473.61 952.85C3473.2 952.98 3472.53 953.42 3472.38 952.88C3472.25 952.41 3471.51 952.17 3471.87 951.62C3472.08 951.29 3473.49 951.68 3473.76 952.13C3473.9 952.37 3473.84 952.63 3473.61 952.85Z" fill="white"/>
                    <path d="M3649.93 1072.33C3649.93 1072.33 3649.89 1072.83 3649.79 1073.26C3649.74 1073.45 3648.47 1073.38 3648.31 1073.18C3647.87 1072.65 3648.44 1072.41 3648.64 1072.07C3648.88 1071.66 3649.2 1071.75 3649.94 1072.34L3649.93 1072.33Z" fill="white"/>
                    <path d="M3626.5 1095.31C3625.96 1095.7 3625.72 1095.16 3625.38 1094.95C3624.95 1094.69 3625.04 1094.28 3625.35 1094.1C3625.71 1093.89 3626.18 1093.84 3626.61 1093.81C3626.76 1093.8 3626.68 1095.14 3626.5 1095.3V1095.31Z" fill="white"/>
                    <path d="M3456.95 1092.62C3456.51 1092.4 3456.49 1092.11 3456.66 1091.9C3457.1 1091.34 3457.69 1091.32 3458.3 1091.59C3458.62 1091.73 3458.63 1092.03 3458.47 1092.25C3458.03 1092.85 3457.44 1092.8 3456.95 1092.62Z" fill="white"/>
                    <path d="M3515.96 941.66C3516.37 942.09 3516.49 942.65 3516.2 943.03C3516.06 943.21 3515.39 943.37 3514.97 943.03C3514.4 942.57 3514.91 942.27 3515.13 941.93C3515.33 941.62 3515.59 941.38 3515.96 941.66Z" fill="white"/>
                    <path d="M3538.97 1133.3C3538.74 1133.17 3538.36 1131.92 3538.51 1131.89C3538.93 1131.81 3539.4 1131.75 3539.8 1131.86C3540.17 1131.96 3540.3 1132.35 3539.96 1132.69C3539.68 1132.97 3539.59 1133.58 3538.97 1133.3Z" fill="white"/>
                    <path d="M3517.97 936.35C3517.55 936.64 3516.98 936.84 3516.41 936.38C3516.2 936.21 3516.13 935.92 3516.41 935.7C3516.93 935.28 3517.51 935.15 3518.08 935.58C3518.29 935.74 3518.35 936.03 3517.98 936.35H3517.97Z" fill="white"/>
                    <path d="M3478.09 888.55C3477.8 888.97 3477.41 888.9 3477.22 888.58C3477.01 888.22 3476.96 887.75 3476.93 887.32C3476.92 887.17 3478.26 887.25 3478.42 887.43C3478.65 887.7 3478.52 888.13 3478.08 888.54L3478.09 888.55Z" fill="white"/>
                    <path d="M3614.49 942.72C3614.25 943.02 3614.01 943.32 3613.62 942.97C3613.2 942.59 3613.1 942.01 3613.39 941.63C3613.53 941.44 3614.2 941.29 3614.62 941.63C3615.19 942.09 3614.66 942.38 3614.48 942.71L3614.49 942.72Z" fill="white"/>
                    <path d="M3561.68 872.23C3561.21 872.45 3560.75 872.6 3560.26 872.31C3559.97 872.14 3559.93 871.84 3560.12 871.65C3560.44 871.33 3560.53 870.51 3561.29 871C3561.76 871.3 3561.5 871.82 3561.68 872.23Z" fill="white"/>
                    <path d="M3601.78 944.6C3601.62 944.65 3601.46 944.69 3601.3 944.74C3601.18 943.95 3601.19 943.12 3600.63 942.45C3600.6 942.42 3600.81 942.13 3600.96 942.04C3601.04 941.99 3601.34 942.09 3601.34 942.14C3601.36 942.88 3602.18 943.35 3602.01 944.14C3601.97 944.31 3601.86 944.45 3601.78 944.61V944.6Z" fill="white"/>
                    <path d="M3597.84 942.77C3597.43 942.41 3598.1 942.03 3598.39 941.72C3598.97 941.1 3599.27 941.9 3599.67 942.11C3599.79 942.17 3600 942.72 3599.55 942.55C3598.88 942.31 3598.82 943.7 3597.84 942.76V942.77Z" fill="white"/>
                    <path d="M3500.3 1085.21C3500.89 1085.53 3500.65 1086.15 3500.8 1086.62C3500.46 1086.67 3499.98 1086.89 3499.81 1086.75C3499.42 1086.43 3499.4 1085.9 3499.65 1085.42C3499.8 1085.13 3500.04 1085.13 3500.31 1085.21H3500.3Z" fill="white"/>
                    <path d="M3648.92 1065.93C3648.65 1065.65 3648.06 1065.5 3648.5 1064.89C3648.82 1064.46 3649.11 1064.65 3649.4 1064.89C3649.71 1065.16 3650.27 1065.33 3649.83 1065.93C3649.51 1066.37 3649.23 1066.15 3648.92 1065.93Z" fill="white"/>
                    <path d="M3442.44 1038.9C3441.57 1039.2 3441.02 1038.99 3440.57 1038.49C3440.47 1038.38 3440.45 1038.18 3440.4 1038.02C3440.58 1038 3440.77 1038.01 3440.93 1037.94C3441.56 1037.7 3441.94 1038.12 3442.33 1038.47C3442.45 1038.58 3442.43 1038.84 3442.44 1038.9Z" fill="white"/>
                    <path d="M3668.22 1086.89C3668.14 1087 3667.83 1086.85 3667.54 1086.59C3667.24 1086.32 3666.66 1086.16 3667.09 1085.56C3667.4 1085.13 3667.7 1085.29 3667.98 1085.54C3668.29 1085.81 3668.85 1085.98 3668.21 1086.89H3668.22Z" fill="white"/>
                    <path d="M3643.89 1033.08C3643.85 1032.96 3643.71 1032.78 3643.76 1032.71C3644.09 1032.18 3644.43 1031.68 3645.2 1031.71C3645.52 1031.73 3645.69 1031.78 3645.62 1032.08C3645.51 1032.56 3644.31 1033.24 3643.89 1033.08Z" fill="white"/>
                    <path d="M3546.59 1080.2C3545.89 1080.75 3545.68 1079.89 3545.27 1079.67C3545.08 1079.57 3545.21 1079.15 3545.44 1078.98C3546.11 1078.49 3546.38 1079.24 3546.77 1079.5C3546.95 1079.62 3546.84 1080 3546.6 1080.2H3546.59Z" fill="white"/>
                    <path d="M3600.45 986.51C3599.75 987.06 3599.54 986.2 3599.13 985.98C3598.94 985.88 3599.07 985.46 3599.3 985.29C3599.97 984.8 3600.24 985.55 3600.63 985.81C3600.81 985.93 3600.7 986.31 3600.46 986.51H3600.45Z" fill="white"/>
                    <path d="M3476.18 976.99C3476.1 977.1 3475.79 976.95 3475.5 976.69C3475.2 976.42 3474.62 976.26 3475.05 975.66C3475.36 975.23 3475.66 975.39 3475.94 975.64C3476.25 975.91 3476.81 976.08 3476.17 976.99H3476.18Z" fill="white"/>
                    <path d="M3653.44 935.73C3653.53 935.16 3653.62 934.57 3653.73 933.98C3653.73 933.95 3653.92 933.92 3653.98 933.95C3654.33 934.12 3654.47 934.4 3654.5 934.8C3654.53 935.25 3654.64 935.7 3654.1 935.9C3653.98 935.95 3653.78 935.83 3653.43 935.73H3653.44Z" fill="white"/>
                    <path d="M3604.51 920.48C3604.82 920.68 3605.16 920.89 3604.91 921.32C3604.58 921.88 3604.21 921.69 3603.82 921.35C3603.55 921.12 3603.18 920.94 3603.44 920.5C3603.77 919.94 3604.15 920.1 3604.51 920.47V920.48Z" fill="white"/>
                    <path d="M3548.22 916.43C3547.8 916.73 3547.56 916.83 3547.34 916.56C3546.92 916.04 3546.8 915.47 3547.22 914.89C3547.3 914.78 3547.53 914.77 3547.69 914.72C3547.87 915.31 3548.06 915.9 3548.22 916.43Z" fill="white"/>
                    <path d="M3629.32 991.97C3629.07 992.4 3628.76 992.64 3628.59 992.49C3628.24 992.18 3627.45 992.11 3627.75 991.34C3627.85 991.08 3628.18 990.85 3628.38 991C3628.76 991.28 3629.41 991.44 3629.32 991.98V991.97Z" fill="white"/>
                    <path d="M3472.12 995.02C3471.87 995.31 3471.66 995.59 3471.24 995.25C3470.67 994.78 3471.16 994.47 3471.39 994.14C3471.6 993.83 3471.84 993.57 3472.26 993.91C3472.84 994.38 3472.31 994.67 3472.12 995.02Z" fill="white"/>
                    <path d="M3538.85 1046.62C3539.4 1047.32 3538.54 1047.53 3538.32 1047.94C3538.22 1048.13 3537.8 1048 3537.63 1047.77C3537.14 1047.1 3537.89 1046.83 3538.15 1046.44C3538.27 1046.26 3538.65 1046.37 3538.85 1046.61V1046.62Z" fill="white"/>
                    <path d="M3552.69 907.32C3553 907.52 3553.34 907.73 3553.09 908.16C3552.76 908.72 3552.39 908.53 3552 908.19C3551.73 907.96 3551.36 907.78 3551.62 907.34C3551.95 906.78 3552.33 906.94 3552.69 907.31V907.32Z" fill="white"/>
                    <path d="M3683.25 991.02C3683.79 991.36 3683.8 991.61 3683.64 991.86C3683.29 992.42 3682.92 992.2 3682.56 991.86C3682.37 991.69 3681.93 991.42 3682.12 991.3C3682.5 991.06 3683.03 991.06 3683.24 991.01L3683.25 991.02Z" fill="white"/>
                    <path d="M3462.93 1028.91C3462.8 1028.98 3462.57 1029.09 3462.35 1029.2C3462.31 1028.7 3461.97 1028.17 3462.43 1027.71C3462.63 1027.51 3462.86 1027.48 3463.12 1027.7C3463.68 1028.16 3463.19 1028.46 3462.93 1028.91Z" fill="white"/>
                    <path d="M3560.8 852.33C3560.58 852.22 3560.55 851.95 3560.69 851.71C3560.88 851.39 3561.16 851.11 3561.53 851.48C3561.79 851.74 3562.5 851.85 3562.12 852.36C3561.98 852.55 3561.3 852.69 3560.8 852.33Z" fill="white"/>
                    <path d="M3700.12 953.12C3699.79 953.68 3699.18 953.46 3698.69 953.58C3698.76 953.34 3698.77 953.06 3698.91 952.87C3699.15 952.55 3699.32 952 3699.93 952.42C3700.2 952.61 3700.21 952.83 3700.12 953.11V953.12Z" fill="white"/>
                    <path d="M3544.25 878.97C3543.9 878.81 3544.21 879.06 3544.23 878.7C3544.23 878.5 3544.49 878.19 3544.67 878.16C3545.16 878.08 3545.72 877.78 3546.14 878.34C3546.19 878.4 3546.21 878.56 3546.18 878.59C3545.6 879.02 3544.88 878.8 3544.26 878.97H3544.25Z" fill="white"/>
                    <path d="M3545.29 930.95C3544.78 930.98 3544.2 931.29 3543.82 930.72C3543.73 930.58 3543.86 930.17 3544.02 930.03C3544.52 929.59 3544.78 930.18 3545.13 930.37C3545.37 930.5 3545.5 930.69 3545.28 930.95H3545.29Z" fill="white"/>
                    <path d="M3572 1016.15C3571.89 1016.29 3571.8 1016.48 3571.66 1016.57C3571.56 1016.63 3571.3 1016.6 3571.26 1016.53C3571.11 1016.27 3572.14 1014.9 3572.47 1014.93C3572.55 1014.93 3572.7 1015.22 3572.66 1015.27C3572.47 1015.58 3572.23 1015.86 3572.01 1016.15H3572Z" fill="white"/>
                    <path d="M3454.84 920.56C3454.52 921.03 3454.2 920.9 3453.92 920.63C3453.72 920.43 3453.69 920.2 3453.9 919.93C3454.22 919.53 3454.52 919.65 3454.79 919.92C3454.98 920.11 3455.1 920.38 3454.84 920.56Z" fill="white"/>
                    <path d="M3696.28 970.89C3696.04 971.07 3695.83 971.12 3695.56 970.91C3695.15 970.59 3695.28 970.3 3695.55 970.02C3695.74 969.82 3695.98 969.79 3696.25 970C3696.66 970.32 3696.5 970.59 3696.28 970.89Z" fill="white"/>
                    <path d="M3549.31 1113.25C3549.07 1113.43 3548.86 1113.48 3548.59 1113.27C3548.18 1112.95 3548.31 1112.66 3548.58 1112.38C3548.77 1112.18 3549.01 1112.15 3549.28 1112.36C3549.69 1112.68 3549.53 1112.95 3549.31 1113.25Z" fill="white"/>
                    <path d="M3459.13 1057.73C3458.86 1057.51 3458.64 1057.29 3458.95 1056.88C3459.16 1056.61 3459.39 1056.57 3459.63 1056.72C3459.96 1056.92 3460.16 1057.17 3459.84 1057.58C3459.63 1057.85 3459.42 1057.86 3459.13 1057.73Z" fill="white"/>
                    <path d="M3671.46 1066.48C3671.7 1066.93 3671.82 1067.39 3671.55 1067.86C3671.48 1067.98 3671.16 1068.12 3671.15 1068.11C3670.85 1067.69 3670.74 1067.22 3671.02 1066.75C3671.1 1066.63 3671.29 1066.57 3671.45 1066.48H3671.46Z" fill="white"/>
                    <path d="M3585.68 1117.22C3585.53 1117.52 3585.26 1117.51 3585.02 1117.37C3584.69 1117.17 3584.5 1116.92 3584.81 1116.51C3585.02 1116.23 3585.25 1116.21 3585.49 1116.35C3585.82 1116.55 3586.03 1116.83 3585.68 1117.23V1117.22Z" fill="white"/>
                    <path d="M3632.66 1016.93C3632.42 1017.11 3632.21 1017.16 3631.94 1016.95C3631.53 1016.63 3631.66 1016.34 3631.93 1016.06C3632.12 1015.86 3632.36 1015.83 3632.63 1016.04C3633.04 1016.36 3632.88 1016.63 3632.66 1016.93Z" fill="white"/>
                    <path d="M3500.7 879.33C3500.42 879.54 3500.21 879.59 3499.94 879.38C3499.53 879.06 3499.66 878.77 3499.93 878.49C3500.12 878.29 3500.35 878.26 3500.63 878.47C3501.05 878.79 3500.85 879.07 3500.7 879.33Z" fill="white"/>
                    <path d="M3521.15 889.36C3520.71 889.06 3520.85 888.74 3521.11 888.46C3521.31 888.26 3521.54 888.23 3521.81 888.44C3522.21 888.76 3522.09 889.06 3521.82 889.33C3521.63 889.53 3521.36 889.6 3521.14 889.35L3521.15 889.36Z" fill="white"/>
                    <path d="M3673.12 1105.15C3673.35 1104.87 3673.59 1104.58 3673.97 1104.94C3674.09 1105.05 3674.15 1105.21 3674.24 1105.35C3673.86 1105.45 3673.47 1105.55 3673.09 1105.66C3673.1 1105.49 3673.11 1105.32 3673.13 1105.15H3673.12Z" fill="white"/>
                    <path d="M3624.94 1090.93C3624.71 1091.21 3624.47 1091.5 3624.09 1091.14C3623.97 1091.03 3623.91 1090.87 3623.82 1090.73C3624.2 1090.63 3624.59 1090.53 3624.97 1090.42C3624.96 1090.59 3624.95 1090.76 3624.93 1090.93H3624.94Z" fill="white"/>
                    <path d="M3675.2 972.52C3674.83 972.58 3674.48 972.63 3674.13 972.68C3674.2 972.44 3674.2 972.14 3674.35 971.98C3674.68 971.61 3674.99 971.88 3675.23 972.14C3675.29 972.21 3675.22 972.41 3675.21 972.52H3675.2Z" fill="white"/>
                    <path d="M3532.59 1041.82C3532.48 1041.64 3532.28 1041.42 3532.32 1041.37C3532.6 1040.97 3533.08 1041.19 3533.46 1041.07C3533.45 1041.24 3533.49 1041.44 3533.41 1041.57C3533.22 1041.9 3532.94 1042.09 3532.59 1041.83V1041.82Z" fill="white"/>
                    <path d="M3461.54 1093.64C3461.41 1093.68 3461.23 1093.8 3461.15 1093.76C3460.84 1093.59 3460.5 1093.36 3460.78 1092.95C3460.91 1092.77 3461.19 1092.69 3461.41 1092.56C3461.46 1092.91 3461.5 1093.27 3461.55 1093.64H3461.54Z" fill="white"/>
                    <path d="M3488.28 1086.51C3488.69 1086.8 3488.52 1087.11 3488.27 1087.38C3488.17 1087.49 3487.96 1087.5 3487.8 1087.55C3487.8 1087.15 3487.8 1086.75 3487.8 1086.36C3487.96 1086.41 3488.11 1086.46 3488.28 1086.51Z" fill="white"/>
                    <path d="M3655.12 983.01C3655.05 983.04 3654.88 983.17 3654.8 983.12C3654.49 982.95 3654.15 982.72 3654.41 982.32C3654.53 982.13 3654.82 982.05 3655.03 981.93C3655.06 982.28 3655.09 982.63 3655.12 983.02V983.01Z" fill="white"/>
                    <path d="M3631.9 1010.6C3631.61 1011.01 3631.3 1010.84 3631.03 1010.59C3630.92 1010.49 3630.91 1010.28 3630.86 1010.12C3631.26 1010.12 3631.66 1010.12 3632.05 1010.12C3632 1010.28 3631.95 1010.43 3631.9 1010.6Z" fill="white"/>
                    <path d="M3517.42 1121.76C3517.26 1121.82 3517.1 1121.88 3516.95 1121.94C3516.93 1121.54 3516.91 1121.15 3516.89 1120.75C3517.05 1120.79 3517.23 1120.8 3517.36 1120.89C3517.79 1121.14 3517.64 1121.45 3517.41 1121.76H3517.42Z" fill="white"/>
                    <path d="M3515.8 906.35C3516.21 906.64 3516.04 906.95 3515.79 907.22C3515.69 907.33 3515.48 907.34 3515.32 907.39C3515.32 906.99 3515.32 906.59 3515.32 906.2C3515.48 906.25 3515.63 906.3 3515.8 906.35Z" fill="white"/>
                    <path d="M3484.16 884.76C3484.13 884.99 3484.16 885.19 3484.08 885.32C3483.88 885.65 3483.59 885.84 3483.24 885.5C3483.13 885.39 3483.07 885.22 3482.99 885.08C3483.37 884.98 3483.75 884.87 3484.16 884.77V884.76Z" fill="white"/>
                    <path d="M3687.85 916.81C3687.5 916.95 3687.34 916.68 3687.15 916.51C3687.09 916.46 3687.13 916.16 3687.2 916.12C3687.51 915.95 3687.69 916.25 3687.87 916.41C3687.93 916.46 3687.85 916.68 3687.84 916.81H3687.85Z" fill="white"/>
                    <path d="M3685.22 941C3685.11 941.29 3685.06 941.55 3684.92 941.74C3684.84 941.85 3684.61 941.86 3684.46 941.92C3684.53 941.68 3684.55 941.42 3684.68 941.21C3684.75 941.1 3684.98 941.09 3685.23 941H3685.22Z" fill="white"/>
                    <path d="M3442 974.57C3441.65 974.71 3441.49 974.44 3441.3 974.27C3441.24 974.22 3441.28 973.92 3441.35 973.88C3441.66 973.71 3441.84 974.01 3442.02 974.17C3442.08 974.22 3442 974.44 3441.99 974.57H3442Z" fill="white"/>
                    <path d="M3464.04 1076.01C3463.75 1075.9 3463.49 1075.85 3463.3 1075.71C3463.19 1075.63 3463.18 1075.4 3463.12 1075.25C3463.36 1075.32 3463.62 1075.34 3463.83 1075.47C3463.94 1075.54 3463.95 1075.77 3464.04 1076.02V1076.01Z" fill="white"/>
                    <path d="M3466.63 1077.53C3466.52 1077.43 3466.31 1077.29 3466.17 1077.1C3466.01 1076.87 3466.13 1076.71 3466.39 1076.82C3466.61 1076.91 3466.82 1077.08 3466.97 1077.26C3467.14 1077.48 3467.03 1077.61 3466.64 1077.53H3466.63Z" fill="white"/>
                    <path d="M3427.01 982.3C3427.09 982.44 3427.28 982.64 3427.24 982.72C3427.09 983.01 3426.84 982.92 3426.66 982.72C3426.55 982.6 3426.38 982.38 3426.43 982.3C3426.58 982.01 3426.81 982.11 3427.02 982.3H3427.01Z" fill="white"/>
                    <path d="M3690.19 972.4C3690.05 972.48 3689.85 972.67 3689.77 972.63C3689.48 972.48 3689.57 972.23 3689.77 972.05C3689.89 971.94 3690.11 971.77 3690.19 971.82C3690.48 971.97 3690.38 972.2 3690.19 972.41V972.4Z" fill="white"/>
                    <path d="M3526.32 1117.13C3526.21 1117.42 3526.16 1117.68 3526.02 1117.87C3525.94 1117.98 3525.71 1117.99 3525.56 1118.05C3525.63 1117.81 3525.65 1117.55 3525.78 1117.34C3525.85 1117.23 3526.08 1117.22 3526.33 1117.13H3526.32Z" fill="white"/>
                    <path d="M3574.37 877.76C3574.25 877.8 3574.09 877.85 3573.94 877.91C3574.01 877.67 3574.03 877.41 3574.16 877.2C3574.23 877.09 3574.46 877.08 3574.62 877.02C3574.69 877.3 3574.64 877.54 3574.37 877.75V877.76Z" fill="white"/>
                    <path d="M3561.41 1141.44C3561.18 1141.29 3561.03 1141.1 3561.27 1140.87C3561.34 1140.81 3561.59 1140.91 3561.73 1140.99C3561.97 1141.12 3562.12 1141.33 3561.87 1141.55C3561.8 1141.61 3561.56 1141.48 3561.4 1141.44H3561.41Z" fill="white"/>
                    <path d="M3550.47 907.02C3550.24 906.87 3550.09 906.68 3550.33 906.45C3550.4 906.39 3550.65 906.49 3550.79 906.57C3551.03 906.7 3551.18 906.91 3550.93 907.13C3550.86 907.19 3550.62 907.06 3550.46 907.02H3550.47Z" fill="white"/>
                    <path d="M3552 918.75C3551.77 918.6 3551.62 918.41 3551.86 918.18C3551.93 918.12 3552.18 918.22 3552.32 918.3C3552.56 918.43 3552.71 918.64 3552.46 918.86C3552.39 918.92 3552.15 918.79 3551.99 918.75H3552Z" fill="white"/>
                    <path d="M3563.7 960.75C3563.59 961.04 3563.54 961.3 3563.4 961.49C3563.32 961.6 3563.09 961.61 3562.94 961.67C3563.01 961.43 3563.03 961.17 3563.16 960.96C3563.23 960.85 3563.46 960.84 3563.71 960.75H3563.7Z" fill="white"/>
                    <path d="M3663.64 978.61C3663.45 978.7 3663.23 978.8 3663 978.91C3663.01 978.74 3662.96 978.51 3663.05 978.42C3663.22 978.25 3663.47 978.15 3663.68 978.03C3663.67 978.2 3663.66 978.37 3663.64 978.61Z" fill="white"/>
                    <path d="M3592.8 1032.07C3592.68 1032.11 3592.52 1032.16 3592.37 1032.22C3592.44 1031.98 3592.46 1031.72 3592.59 1031.51C3592.66 1031.4 3592.89 1031.39 3593.05 1031.33C3593.12 1031.61 3593.07 1031.85 3592.8 1032.06V1032.07Z" fill="white"/>
                    <path d="M3622.4 1063.7C3622.29 1063.99 3622.24 1064.25 3622.1 1064.44C3622.02 1064.55 3621.79 1064.56 3621.64 1064.62C3621.71 1064.38 3621.73 1064.12 3621.86 1063.91C3621.93 1063.8 3622.16 1063.79 3622.41 1063.7H3622.4Z" fill="white"/>
                    <path d="M3669.15 1112.92C3668.8 1113.06 3668.64 1112.78 3668.45 1112.62C3668.39 1112.57 3668.43 1112.27 3668.5 1112.23C3668.81 1112.06 3668.99 1112.36 3669.17 1112.52C3669.23 1112.57 3669.15 1112.79 3669.14 1112.92H3669.15Z" fill="white"/>
                    <path d="M3609.45 1101C3609.22 1100.85 3609.07 1100.66 3609.31 1100.43C3609.38 1100.37 3609.63 1100.47 3609.77 1100.55C3610.01 1100.68 3610.16 1100.89 3609.91 1101.11C3609.84 1101.17 3609.6 1101.04 3609.44 1101H3609.45Z" fill="white"/>
                    <path d="M3459.46 974.9C3459.54 975.04 3459.73 975.24 3459.69 975.32C3459.54 975.61 3459.29 975.52 3459.11 975.32C3459 975.2 3458.83 974.98 3458.88 974.9C3459.03 974.61 3459.26 974.71 3459.47 974.9H3459.46Z" fill="white"/>
                    <path d="M3539.44 1018.21C3539.3 1018.29 3539.1 1018.48 3539.02 1018.44C3538.73 1018.29 3538.82 1018.04 3539.02 1017.86C3539.14 1017.75 3539.36 1017.58 3539.44 1017.63C3539.73 1017.78 3539.63 1018.01 3539.44 1018.22V1018.21Z" fill="white"/>
                    <path d="M3531.26 1030.21C3531.23 1030.35 3531.23 1030.49 3531.17 1030.61C3531.15 1030.65 3531.01 1030.64 3530.92 1030.65C3530.95 1030.51 3530.95 1030.37 3531.01 1030.25C3531.03 1030.21 3531.17 1030.22 3531.26 1030.21Z" fill="white"/>
                    <path d="M3514.12 1131.06C3514.09 1131.2 3514.09 1131.34 3514.03 1131.46C3514.01 1131.5 3513.87 1131.49 3513.78 1131.5C3513.81 1131.36 3513.81 1131.22 3513.87 1131.1C3513.89 1131.06 3514.03 1131.07 3514.12 1131.06Z" fill="white"/>
                    <path d="M3558.94 927.87C3558.82 927.92 3558.69 927.98 3558.56 928.03C3558.55 927.94 3558.5 927.81 3558.53 927.78C3558.63 927.69 3558.77 927.65 3558.9 927.59C3558.91 927.68 3558.92 927.76 3558.94 927.87Z" fill="white"/>
                    <path d="M3533.76 892.28C3533.86 892.27 3533.95 892.26 3534.04 892.24C3534.01 892.38 3534.01 892.52 3533.95 892.64C3533.93 892.68 3533.79 892.67 3533.7 892.68C3533.72 892.54 3533.74 892.41 3533.76 892.27V892.28Z" fill="white"/>
                    <path d="M3598.9 940.69C3598.85 940.57 3598.79 940.44 3598.74 940.31C3598.83 940.3 3598.96 940.25 3598.99 940.28C3599.08 940.38 3599.12 940.52 3599.18 940.65C3599.09 940.66 3599.01 940.67 3598.9 940.69Z" fill="white"/>
                    <path d="M3603.2 983.88C3603.06 983.85 3602.92 983.85 3602.8 983.79C3602.76 983.77 3602.77 983.63 3602.76 983.54C3602.9 983.57 3603.04 983.57 3603.16 983.63C3603.2 983.65 3603.19 983.79 3603.2 983.88Z" fill="white"/>
                    <path d="M3636.3 1015.27C3636.29 1015.17 3636.28 1015.08 3636.26 1014.99C3636.4 1015.02 3636.54 1015.02 3636.66 1015.08C3636.7 1015.1 3636.69 1015.24 3636.7 1015.33C3636.56 1015.31 3636.43 1015.29 3636.29 1015.27H3636.3Z" fill="white"/>
                    <path d="M3683.17 984.13C3683.05 984.18 3682.92 984.24 3682.79 984.29C3682.78 984.2 3682.73 984.07 3682.76 984.04C3682.86 983.95 3683 983.91 3683.13 983.85C3683.14 983.94 3683.15 984.02 3683.17 984.13Z" fill="white"/>
                    <path d="M3585.5 928.11C3585.4 928.12 3585.31 928.13 3585.22 928.15C3585.25 928.01 3585.25 927.87 3585.31 927.75C3585.33 927.71 3585.47 927.72 3585.56 927.71C3585.54 927.85 3585.52 927.98 3585.5 928.12V928.11Z" fill="white"/>
                    <path d="M3577.25 997.59C3577.35 997.58 3577.44 997.57 3577.53 997.55C3577.5 997.69 3577.5 997.83 3577.44 997.95C3577.42 997.99 3577.28 997.98 3577.19 997.99C3577.21 997.85 3577.23 997.72 3577.25 997.58V997.59Z" fill="white"/>
                    <path d="M3677.22 1088.48C3677.23 1088.57 3677.27 1088.72 3677.25 1088.72C3677.12 1088.78 3676.97 1088.85 3676.85 1088.82C3676.6 1088.77 3676.57 1088.61 3676.81 1088.49C3676.92 1088.43 3677.08 1088.47 3677.22 1088.47V1088.48Z" fill="white"/>
                    <path d="M3676.5 1088.89C3676.36 1088.89 3676.14 1088.95 3676.1 1088.89C3675.97 1088.71 3676.04 1088.55 3676.22 1088.57C3676.37 1088.58 3676.51 1088.66 3676.66 1088.71C3676.61 1088.78 3676.56 1088.84 3676.51 1088.91L3676.5 1088.89Z" fill="white"/>
                    <path d="M3503.24 1025.77C3503.12 1025.82 3502.99 1025.88 3502.86 1025.93C3502.85 1025.84 3502.8 1025.71 3502.83 1025.68C3502.93 1025.59 3503.07 1025.55 3503.2 1025.49C3503.21 1025.58 3503.22 1025.66 3503.24 1025.77Z" fill="white"/>
                    <path d="M3726.16 1024.28C3726.04 1024.33 3725.91 1024.39 3725.78 1024.44C3725.77 1024.35 3725.72 1024.22 3725.75 1024.19C3725.85 1024.1 3725.99 1024.06 3726.12 1024C3726.13 1024.09 3726.14 1024.17 3726.16 1024.28Z" fill="white"/>
                    <path d="M3678.85 1095.76C3678.75 1095.77 3678.66 1095.78 3678.57 1095.8C3678.6 1095.66 3678.6 1095.52 3678.66 1095.4C3678.68 1095.36 3678.82 1095.37 3678.91 1095.36C3678.89 1095.5 3678.87 1095.63 3678.85 1095.77V1095.76Z" fill="white"/>
                    <path d="M3699.82 992.93C3699.94 992.88 3700.07 992.82 3700.2 992.77C3700.21 992.86 3700.26 992.99 3700.23 993.02C3700.13 993.11 3699.99 993.15 3699.86 993.21C3699.85 993.12 3699.84 993.04 3699.82 992.93Z" fill="white"/>
                    <path d="M3585.04 1067.65C3584.92 1067.7 3584.79 1067.76 3584.66 1067.81C3584.65 1067.72 3584.6 1067.59 3584.63 1067.56C3584.73 1067.47 3584.87 1067.43 3585 1067.37C3585.01 1067.46 3585.02 1067.54 3585.04 1067.65Z" fill="white"/>
                    <path d="M3628.05 945.54C3628.04 945.44 3628.03 945.35 3628.01 945.26C3628.15 945.29 3628.29 945.29 3628.41 945.35C3628.45 945.37 3628.44 945.51 3628.45 945.6C3628.31 945.58 3628.18 945.56 3628.04 945.54H3628.05Z" fill="white"/>
                    <path d="M3647.56 1086.7C3647.53 1086.84 3647.53 1086.98 3647.47 1087.1C3647.45 1087.14 3647.31 1087.13 3647.22 1087.14C3647.25 1087 3647.25 1086.86 3647.31 1086.74C3647.33 1086.7 3647.47 1086.71 3647.56 1086.7Z" fill="white"/>
                    <path d="M3639.08 950.87C3638.96 950.92 3638.83 950.98 3638.7 951.03C3638.69 950.94 3638.64 950.81 3638.67 950.78C3638.77 950.69 3638.91 950.65 3639.04 950.59L3639.08 950.87Z" fill="white"/>
                    <path d="M3549.76 1014.28C3549.81 1014.4 3549.87 1014.53 3549.92 1014.66C3549.83 1014.67 3549.7 1014.72 3549.67 1014.69C3549.58 1014.59 3549.54 1014.45 3549.48 1014.32C3549.57 1014.31 3549.65 1014.3 3549.76 1014.28Z" fill="white"/>
                    <path d="M3422.12 989.09C3422.09 989.23 3422.09 989.37 3422.03 989.49C3422.01 989.53 3421.87 989.52 3421.78 989.53C3421.81 989.39 3421.81 989.25 3421.87 989.13C3421.89 989.09 3422.03 989.1 3422.12 989.09Z" fill="white"/>
                    <path d="M3459.52 848.35C3461.47 847.76 3462.53 848.94 3463.51 850.08C3463.87 850.49 3464.33 851.22 3464.59 851.36C3466.27 852.29 3464.42 853.62 3465.09 854.58C3464.05 855.09 3462.99 856.05 3461.99 855.98C3461.04 855.91 3459.87 855.73 3458.95 855.2C3458.08 854.7 3456.64 854.38 3457.71 852.77C3457.87 852.53 3457.54 851.99 3457.48 851.58C3457.22 849.77 3457.95 848.69 3459.51 848.35H3459.52Z" fill="white"/>
                    <path d="M3382.69 993.91C3382.45 992.73 3383.21 991.7 3383.63 991.62C3385.24 991.31 3385.57 989.71 3386.73 988.98C3387.55 988.46 3388.13 988.28 3388.89 988.84C3390.14 989.76 3390.41 990.42 3389.94 991.45C3389.74 991.9 3389.59 992.34 3389.46 992.82C3388.87 994.86 3384.89 996.48 3383.23 994.7C3382.94 994.38 3382.68 994.13 3382.69 993.92V993.91Z" fill="white"/>
                    <path d="M3473.79 957.11C3474.39 956.24 3473.85 954.79 3475.25 954.37C3475.45 954.31 3475.7 954.34 3475.85 954.22C3476.46 953.74 3476.93 952.99 3477.88 953.26C3478.62 953.47 3478.89 954.18 3479.32 954.71C3480.16 955.76 3479.93 957.64 3478.89 958.69C3478.08 959.5 3477.17 959.66 3476.05 959.33C3474.88 958.98 3474.51 957.89 3473.79 957.11Z" fill="white"/>
                    <path d="M3567.25 1069.46C3567.63 1071.23 3567.16 1072.17 3565.92 1072.6C3565.24 1072.84 3564.5 1072.9 3563.8 1073.09C3563.14 1073.28 3562.87 1073.03 3562.77 1072.39C3562.66 1071.67 3562.36 1070.97 3562.33 1070.26C3562.28 1069.05 3562.91 1068.1 3563.86 1067.39C3564.05 1067.24 3564.49 1067.19 3564.68 1067.31C3565.36 1067.76 3566.13 1067.94 3566.85 1068.23C3567.2 1068.37 3567.22 1069.33 3567.26 1069.46H3567.25Z" fill="white"/>
                    <path d="M3439.62 1117.76C3439.38 1116.98 3439.01 1115.87 3440.18 1115.26C3440.73 1114.97 3441.77 1114.43 3442.15 1115.11C3442.57 1115.85 3443.64 1116.33 3443.35 1117.41C3443.15 1118.16 3442.67 1118.88 3442.65 1119.62C3442.63 1120.3 3442.45 1120.48 3441.86 1120.62C3441.01 1120.82 3440.44 1120.43 3440.11 1119.79C3439.83 1119.26 3439.81 1118.59 3439.63 1117.76H3439.62Z" fill="white"/>
                    <path d="M3589.62 1066.41C3589.43 1065.61 3589.9 1064.78 3590.68 1064.56C3590.94 1064.49 3593.77 1065.35 3593.91 1065.55C3594.24 1066 3593.58 1067.79 3593.51 1067.72C3592.57 1066.81 3592.27 1068.39 3591.57 1068.16C3590.9 1067.95 3589.75 1066.91 3589.62 1066.4V1066.41Z" fill="white"/>
                    <path d="M3509.59 899.37C3509.16 899.45 3508.54 899.71 3508.45 899.3C3508.28 898.56 3507.75 897.81 3508.3 896.95C3508.6 896.47 3509.17 896.42 3509.42 896.04C3509.83 895.41 3510.19 895.23 3510.91 895.64C3511.68 896.08 3511.95 896.79 3511.66 897.41C3511.26 898.28 3510.87 899.38 3509.6 899.37H3509.59Z" fill="white"/>
                    <path d="M3446.61 987.47C3446.71 988.12 3447.22 988.87 3446 989.09C3445.72 989.14 3445.56 989.75 3445.32 990.09C3445.05 990.47 3444.74 990.83 3444.45 991.19C3444.23 990.53 3443.74 989.81 3443.87 989.22C3444.03 988.46 3444.71 987.83 3445.12 987.11C3445.34 986.72 3445.63 986.4 3446.06 986.48C3446.54 986.57 3446.52 987.08 3446.61 987.47Z" fill="white"/>
                    <path d="M3606.71 941.24C3606.75 941.44 3606.82 941.64 3606.84 941.84C3606.88 942.29 3607.34 942.85 3606.69 943.16C3606.02 943.48 3605.36 943.4 3604.74 942.89C3604.21 942.45 3604.38 942.09 3604.64 941.6C3604.81 941.28 3604.7 940.83 3604.86 940.51C3605.07 940.09 3605.25 939.51 3605.87 939.53C3606.62 939.55 3606.42 940.23 3606.58 940.65C3606.65 940.84 3606.67 941.05 3606.72 941.24H3606.71Z" fill="white"/>
                    <path d="M3584.92 1076.72C3585.83 1077 3586.87 1077.01 3587.61 1077.72C3587.78 1077.88 3587.88 1078.26 3587.63 1078.56C3587.32 1078.94 3587.19 1079.63 3586.58 1079.52C3585.83 1079.38 3585.1 1079.08 3584.39 1078.77C3583.55 1078.4 3584.57 1078.01 3584.42 1077.6C3584.26 1077.17 3584.33 1076.8 3584.91 1076.71L3584.92 1076.72Z" fill="white"/>
                    <path d="M3432.58 938.61C3432.79 939.14 3432.68 939.52 3432 939.63C3430.94 939.81 3430.23 939.38 3429.98 938.46C3429.76 937.64 3430.17 937.07 3430.77 936.73C3431.45 936.34 3432.32 937.26 3432.59 938.61H3432.58Z" fill="white"/>
                    <path d="M3345.56 904.3C3345.63 904.6 3345.75 904.91 3345.75 905.21C3345.75 905.92 3344.98 906.32 3344.38 905.94C3344.03 905.72 3343.62 905.55 3343.35 905.26C3342.99 904.89 3342.82 904.29 3343.03 903.87C3343.27 903.39 3344.06 903.35 3344.28 902.74C3344.72 903.12 3345.48 903.2 3345.49 904C3345.49 904.1 3345.53 904.2 3345.55 904.3H3345.56Z" fill="white"/>
                    <path d="M3564.55 1022.64C3564.75 1022.33 3564.93 1022.08 3565.07 1021.81C3565.44 1021.13 3566.49 1021.63 3566.8 1020.79C3566.96 1020.34 3567.42 1020.24 3567.79 1020.54C3568.14 1020.82 3568.1 1021.22 3567.84 1021.6C3567.49 1022.11 3567.2 1022.66 3566.83 1023.14C3566.65 1023.37 3566.66 1024.02 3566.05 1023.61C3565.56 1023.27 3565.05 1022.97 3564.55 1022.65V1022.64Z" fill="white"/>
                    <path d="M3513.09 1140.68C3512.43 1140.73 3511.61 1139.86 3511.6 1139.1C3511.6 1138.8 3512.93 1137.42 3513.3 1137.44C3514.22 1137.49 3513.97 1138.32 3514.07 1138.82C3514.27 1139.75 3513.73 1140.63 3513.09 1140.67V1140.68Z" fill="white"/>
                    <path d="M3451.87 1079.33C3452.47 1079.1 3452.87 1079.15 3453 1079.85C3453.15 1080.71 3452.75 1081.68 3452.14 1081.81C3451.53 1081.94 3450.73 1081.22 3450.55 1080.37C3450.28 1079.1 3451.42 1079.61 3451.87 1079.32V1079.33Z" fill="white"/>
                    <path d="M3444.66 1076.91C3444.84 1076.37 3445.02 1075.77 3445.65 1075.58C3446.33 1075.38 3447.3 1076.42 3447.31 1077.27C3447.31 1077.83 3446.91 1077.88 3446.51 1077.93C3445.6 1078.04 3444.72 1077.56 3444.66 1076.9V1076.91Z" fill="white"/>
                    <path d="M3535.23 1046.36C3535.32 1046.76 3535.34 1046.96 3535.41 1047.15C3535.67 1047.87 3535.03 1048.24 3534.8 1048.76C3534.37 1048.38 3533.64 1048.31 3533.57 1047.56C3533.55 1047.36 3533.5 1047.16 3533.44 1046.96C3533.24 1046.3 3533.23 1045.86 3534.12 1045.68C3534.97 1045.51 3535.24 1045.8 3535.23 1046.36Z" fill="white"/>
                    <path d="M3357.03 1066.4C3357.73 1066.2 3357.98 1066.64 3357.9 1067.02C3357.75 1067.78 3357.45 1068.51 3357.15 1069.23C3356.87 1069.9 3356.54 1069.33 3356.25 1069.21C3354.99 1068.65 3356.2 1067.83 3356.06 1067.13C3355.9 1066.32 3356.76 1066.62 3357.03 1066.41V1066.4Z" fill="white"/>
                    <path d="M3503.04 1065.85C3502.7 1065.05 3503.11 1064.46 3503.7 1064.1C3504.14 1063.83 3505.56 1064.79 3505.44 1065.2C3505.21 1065.99 3504.65 1066.43 3503.8 1066.54C3503.2 1066.62 3503.13 1066.25 3503.04 1065.85Z" fill="white"/>
                    <path d="M3563.04 885.6C3563.65 885.57 3564.09 885.97 3564.45 886.43C3564.67 886.7 3563.54 887.99 3562.93 888.19C3562.38 888.37 3562.24 887.99 3562.16 887.57C3562 886.73 3562.42 885.75 3563.04 885.6Z" fill="white"/>
                    <path d="M3544.08 1081.29C3543.76 1081.26 3543.42 1081.12 3543.52 1080.76C3543.75 1079.92 3543.77 1079.01 3544.31 1078.25C3544.54 1077.93 3544.87 1077.83 3545.09 1077.9C3545.3 1077.96 3545.6 1078.39 3545.55 1078.58C3545.37 1079.33 3545.07 1080.06 3544.8 1080.79C3544.68 1081.1 3544.43 1081.26 3544.08 1081.29Z" fill="white"/>
                    <path d="M3564.35 1108.41C3564.25 1108.1 3564.12 1107.7 3564.62 1107.48C3565.06 1107.29 3565.35 1107.61 3565.71 1107.76C3566.43 1108.06 3566.38 1108.75 3566.42 1109.29C3566.44 1109.61 3566.03 1110.26 3565.83 1110.25C3565.42 1110.23 3564.93 1109.96 3564.65 1109.64C3564.42 1109.37 3564.45 1108.88 3564.34 1108.41H3564.35Z" fill="white"/>
                    <path d="M3393.65 1108.88C3393.17 1109.07 3392.8 1108.95 3392.69 1108.28C3392.55 1107.42 3392.96 1106.48 3393.56 1106.34C3394.57 1106.09 3394.16 1107.52 3394.99 1107.58C3395.13 1107.59 3394.42 1108.66 3393.66 1108.89L3393.65 1108.88Z" fill="white"/>
                    <path d="M3543.99 987.63C3544.07 987.46 3544.11 987.2 3544.24 987.14C3544.97 986.78 3546.59 987.57 3546.7 988.34C3546.74 988.6 3546.71 989.01 3546.55 989.13C3546.38 989.27 3545.97 989.24 3545.73 989.13C3544.68 988.66 3544.11 988.13 3543.99 987.63Z" fill="white"/>
                    <path d="M3476.94 892.52C3476.64 892.59 3476.34 892.71 3476.04 892.71C3475.27 892.71 3475.47 892.03 3475.32 891.61C3475.13 891.06 3475.51 890.92 3475.94 890.83C3476.32 890.75 3476.69 890.59 3477.08 890.88C3477.48 891.18 3478.05 891.38 3477.93 891.99C3477.84 892.47 3477.32 892.42 3476.94 892.52Z" fill="white"/>
                    <path d="M3541.76 1078.69C3541.77 1079.34 3541.34 1079.37 3540.95 1079.42C3540.02 1079.53 3539.16 1079.04 3539.13 1078.38C3539.1 1077.83 3539.55 1077.78 3539.94 1077.73C3540.87 1077.63 3541.7 1078.11 3541.76 1078.69Z" fill="white"/>
                    <path d="M3395.08 1117.54C3394.49 1117.13 3393.83 1116.89 3393.74 1116.02C3393.66 1115.3 3394 1115.2 3394.54 1115.07C3395.12 1114.93 3395.41 1114.99 3395.64 1115.64C3395.94 1116.49 3395.36 1116.93 3395.09 1117.55L3395.08 1117.54Z" fill="white"/>
                    <path d="M3387.81 1103.61C3387.88 1103.43 3387.9 1103.14 3388.02 1103.1C3388.39 1102.96 3388.87 1102.74 3389.18 1102.85C3389.7 1103.04 3390.37 1103.24 3390.44 1103.97C3390.5 1104.53 3390.05 1104.59 3389.65 1104.63C3388.71 1104.73 3387.91 1104.28 3387.8 1103.61H3387.81Z" fill="white"/>
                    <path d="M3513.81 871.72C3513.73 871.3 3513.65 870.85 3514.23 870.82C3514.98 870.78 3515.65 871.12 3515.72 871.89C3515.76 872.3 3515.32 872.75 3515.09 873.18C3514.54 872.79 3513.78 872.59 3513.8 871.72H3513.81Z" fill="white"/>
                    <path d="M3369.03 950.37C3369.77 950.26 3370.71 950.93 3370.73 951.45C3370.76 952 3370.31 952 3369.94 952.11C3369.18 952.32 3368.58 952.02 3368.33 951.38C3368.26 951.19 3368.63 950.45 3369.03 950.36V950.37Z" fill="white"/>
                    <path d="M3484.9 1079.36C3484.79 1078.85 3484.7 1078.31 3485.25 1078.35C3485.94 1078.4 3486.6 1078.71 3487.28 1078.91C3486.94 1079.54 3486.59 1080.07 3485.74 1080.16C3484.94 1080.24 3485.02 1079.68 3484.9 1079.36Z" fill="white"/>
                    <path d="M3339.28 1023.25C3339 1022.44 3339.39 1022.3 3339.96 1022.12C3340.84 1021.85 3340.99 1022.31 3341.14 1022.99C3341.3 1023.68 3340.97 1023.83 3340.4 1023.99C3339.55 1024.23 3339.26 1023.89 3339.28 1023.25Z" fill="white"/>
                    <path d="M3497.39 1083.95C3497.94 1083.93 3498.71 1083.33 3498.79 1084.39C3498.83 1084.96 3498.49 1085.55 3497.87 1085.76C3496.85 1086.11 3496.89 1084.91 3496.52 1084.52C3496.05 1084.03 3497.12 1084.22 3497.39 1083.95Z" fill="white"/>
                    <path d="M3583.04 1117.6C3582.98 1117.3 3582.86 1117.01 3582.85 1116.71C3582.83 1116.16 3583.28 1116.13 3583.67 1116.07C3583.95 1116.03 3584.26 1115.8 3584.52 1116.1C3584.81 1116.43 3584.83 1116.8 3584.55 1117.16C3584.43 1117.32 3584.34 1117.5 3584.22 1117.67C3584 1117.99 3583.77 1118.57 3583.55 1118.57C3583.08 1118.55 3583.16 1117.97 3583.04 1117.61V1117.6Z" fill="white"/>
                    <path d="M3393.55 896.27C3392.81 896.21 3392.96 895.57 3392.85 895.14C3392.64 894.33 3393.34 894.44 3393.77 894.31C3394.17 894.19 3394.59 894.14 3394.67 894.74C3394.74 895.35 3394.09 896.23 3393.55 896.28V896.27Z" fill="white"/>
                    <path d="M3422.92 1058.18C3423.45 1058.09 3423.87 1058 3423.94 1058.6C3424.01 1059.21 3423.36 1060.12 3422.82 1060.13C3422.27 1060.14 3422.3 1059.67 3422.16 1059.31C3421.83 1058.42 3422.37 1058.26 3422.91 1058.19L3422.92 1058.18Z" fill="white"/>
                    <path d="M3534.66 1103.27C3534.45 1103.26 3534.03 1103.35 3533.91 1103.2C3533.6 1102.8 3534.07 1102.49 3534.28 1102.29C3534.6 1102 3534.45 1100.86 3535.41 1101.57C3535.73 1101.8 3536.14 1101.96 3536.11 1102.43C3536.06 1103.38 3535.16 1102.9 3534.65 1103.26L3534.66 1103.27Z" fill="white"/>
                    <path d="M3587.16 951.22C3587.35 950.77 3587.44 949.88 3588.02 950.14C3588.51 950.36 3589.25 949.9 3589.47 950.61C3589.6 951.03 3588.26 951.96 3587.67 951.87C3587.36 951.82 3587.18 951.58 3587.16 951.22Z" fill="white"/>
                    <path d="M3357.63 1016.94C3357.63 1016.94 3357.23 1016.55 3356.94 1016.15C3356.81 1015.97 3357.78 1014.93 3358.07 1014.93C3358.84 1014.93 3358.64 1015.6 3358.79 1016.02C3358.97 1016.53 3358.66 1016.73 3357.63 1016.94Z" fill="white"/>
                    <path d="M3354.71 980.27C3354.76 979.53 3355.4 979.71 3355.82 979.57C3356.35 979.39 3356.64 979.76 3356.57 980.16C3356.49 980.62 3356.19 981.06 3355.91 981.45C3355.81 981.59 3354.71 980.55 3354.71 980.28V980.27Z" fill="white"/>
                    <path d="M3478.94 836.39C3479.44 836.17 3479.71 836.37 3479.77 836.66C3479.94 837.44 3479.53 837.96 3478.86 838.29C3478.51 838.46 3478.25 838.25 3478.17 837.96C3477.97 837.16 3478.44 836.68 3478.94 836.39Z" fill="white"/>
                    <path d="M3566.33 995.68C3565.67 995.73 3565.1 995.42 3564.98 994.9C3564.92 994.64 3565.26 993.96 3565.86 993.84C3566.67 993.68 3566.56 994.34 3566.69 994.76C3566.81 995.16 3566.84 995.55 3566.32 995.67L3566.33 995.68Z" fill="white"/>
                    <path d="M3384.97 877.68C3385.24 877.58 3386.6 878.14 3386.51 878.3C3386.27 878.72 3385.99 879.17 3385.61 879.43C3385.26 879.67 3384.83 879.51 3384.78 878.97C3384.74 878.53 3384.28 878.01 3384.97 877.68Z" fill="white"/>
                    <path d="M3569.45 1001.23C3569.5 1000.65 3569.74 1000.03 3570.55 999.87C3570.85 999.81 3571.15 999.97 3571.14 1000.35C3571.13 1001.1 3570.82 1001.69 3570.04 1001.87C3569.75 1001.94 3569.46 1001.78 3569.45 1001.23Z" fill="white"/>
                    <path d="M3639.24 1001.31C3639.08 1000.76 3639.43 1000.47 3639.83 1000.54C3640.29 1000.62 3640.73 1000.92 3641.12 1001.2C3641.26 1001.3 3640.22 1002.4 3639.95 1002.4C3639.56 1002.4 3639.28 1001.98 3639.24 1001.31Z" fill="white"/>
                    <path d="M3494.57 1079.66C3494.49 1079.23 3494.4 1078.81 3494.98 1078.73C3495.61 1078.64 3496.18 1078.98 3496.29 1079.5C3496.35 1079.76 3496.01 1080.44 3495.41 1080.56C3494.6 1080.72 3494.74 1080.05 3494.58 1079.66H3494.57Z" fill="white"/>
                    <path d="M3593.17 1084.93C3593.32 1084.37 3593.52 1083.86 3594.12 1083.65C3594.47 1083.53 3594.76 1083.71 3594.79 1084.01C3594.83 1084.51 3595.47 1085.18 3594.51 1085.49C3593.92 1085.68 3593.65 1085.08 3593.17 1084.93Z" fill="white"/>
                    <path d="M3502.1 1067.38C3502.18 1067.21 3502.25 1067.04 3502.33 1066.87C3503.1 1067.34 3503.8 1067.94 3504.78 1067.94C3504.82 1067.94 3504.92 1068.33 3504.89 1068.52C3504.87 1068.63 3504.57 1068.81 3504.53 1068.78C3503.88 1068.27 3502.89 1068.63 3502.33 1067.92C3502.21 1067.77 3502.17 1067.56 3502.09 1067.38H3502.1Z" fill="white"/>
                    <path d="M3506.5 1065.3C3507.11 1065.2 3506.95 1066.05 3507.01 1066.53C3507.12 1067.47 3506.22 1067.16 3505.75 1067.35C3505.61 1067.4 3504.99 1067.2 3505.46 1066.93C3506.15 1066.53 3505 1065.48 3506.51 1065.31L3506.5 1065.3Z" fill="white"/>
                    <path d="M3454.14 879C3453.44 879.28 3453.08 878.63 3452.56 878.41C3452.76 878.08 3452.91 877.51 3453.16 877.46C3453.72 877.35 3454.18 877.72 3454.42 878.28C3454.57 878.62 3454.39 878.82 3454.13 879H3454.14Z" fill="white"/>
                    <path d="M3363.85 1020.69C3364.29 1020.66 3364.84 1020.27 3365.04 1021.08C3365.19 1021.66 3364.81 1021.78 3364.4 1021.85C3363.95 1021.92 3363.4 1022.28 3363.2 1021.48C3363.05 1020.89 3363.44 1020.81 3363.85 1020.69Z" fill="white"/>
                    <path d="M3535.57 862.54C3535.94 861.58 3536.51 861.26 3537.26 861.23C3537.43 861.23 3537.61 861.35 3537.79 861.42C3537.68 861.59 3537.54 861.75 3537.47 861.93C3537.22 862.64 3536.59 862.67 3536.01 862.75C3535.83 862.78 3535.62 862.57 3535.57 862.54Z" fill="white"/>
                    <path d="M3331.96 1022.21C3331.92 1022.06 3332.28 1021.9 3332.71 1021.84C3333.16 1021.78 3333.71 1021.39 3333.92 1022.2C3334.07 1022.77 3333.71 1022.91 3333.29 1022.98C3332.84 1023.05 3332.28 1023.41 3331.96 1022.21Z" fill="white"/>
                    <path d="M3395.73 1039.98C3395.86 1040.03 3396.11 1040.05 3396.14 1040.14C3396.36 1040.81 3396.54 1041.46 3395.96 1042.09C3395.72 1042.35 3395.55 1042.47 3395.34 1042.18C3395.01 1041.74 3395.28 1040.21 3395.72 1039.97L3395.73 1039.98Z" fill="white"/>
                    <path d="M3425.16 922.42C3425.19 921.43 3426.08 921.87 3426.57 921.66C3426.79 921.57 3427.06 921.98 3427.04 922.3C3426.98 923.23 3426.14 922.92 3425.64 923.07C3425.41 923.14 3425.17 922.77 3425.16 922.42Z" fill="white"/>
                    <path d="M3467 1036.1C3467.03 1035.11 3467.92 1035.55 3468.41 1035.34C3468.63 1035.25 3468.9 1035.66 3468.88 1035.98C3468.82 1036.91 3467.98 1036.6 3467.48 1036.75C3467.25 1036.82 3467.01 1036.45 3467 1036.1Z" fill="white"/>
                    <path d="M3564.55 936.07C3564.51 935.92 3564.87 935.76 3565.3 935.7C3565.75 935.64 3566.3 935.25 3566.51 936.06C3566.66 936.63 3566.3 936.77 3565.88 936.84C3565.43 936.91 3564.87 937.27 3564.55 936.07Z" fill="white"/>
                    <path d="M3472.58 1118.18C3473.01 1118.67 3473.45 1119.17 3473.88 1119.69C3473.9 1119.71 3473.8 1119.9 3473.72 1119.93C3473.32 1120.11 3472.98 1120.03 3472.61 1119.77C3472.2 1119.48 3471.74 1119.24 3471.95 1118.64C3472 1118.5 3472.24 1118.42 3472.58 1118.18Z" fill="white"/>
                    <path d="M3520.87 1087.07C3520.47 1087.19 3520.05 1087.34 3519.86 1086.8C3519.61 1086.11 3520.05 1085.93 3520.61 1085.84C3521.01 1085.77 3521.42 1085.58 3521.61 1086.12C3521.85 1086.81 3521.44 1087.02 3520.86 1087.06L3520.87 1087.07Z" fill="white"/>
                    <path d="M3564.83 1041.57C3564.88 1041 3564.96 1040.71 3565.35 1040.72C3566.1 1040.73 3566.68 1041.04 3566.87 1041.82C3566.91 1041.97 3566.75 1042.17 3566.68 1042.35C3566.04 1042.08 3565.4 1041.82 3564.83 1041.58V1041.57Z" fill="white"/>
                    <path d="M3441.56 1057C3441.37 1056.48 3441.39 1056.03 3441.64 1056C3442.16 1055.93 3442.79 1055.29 3443.23 1056.11C3443.38 1056.39 3443.34 1056.83 3443.07 1056.9C3442.55 1057.03 3441.95 1057.46 3441.55 1057.01L3441.56 1057Z" fill="white"/>
                    <path d="M3551.96 919.62C3551.89 919.2 3551.8 918.81 3552.4 918.69C3553.22 918.53 3553.12 919.18 3553.25 919.62C3553.36 920.02 3553.41 920.41 3552.82 920.53C3552 920.69 3552.12 920.03 3551.97 919.61L3551.96 919.62Z" fill="white"/>
                    <path d="M3459.61 939.9C3458.62 939.87 3459.06 938.98 3458.85 938.49C3458.76 938.27 3459.17 938 3459.49 938.02C3460.42 938.08 3460.11 938.92 3460.26 939.42C3460.33 939.65 3459.96 939.89 3459.61 939.9Z" fill="white"/>
                    <path d="M3569.45 1051.97C3569.05 1052.09 3568.63 1052.24 3568.44 1051.7C3568.19 1051.01 3568.63 1050.83 3569.19 1050.74C3569.59 1050.67 3570 1050.48 3570.19 1051.02C3570.43 1051.71 3570.02 1051.92 3569.44 1051.96L3569.45 1051.97Z" fill="white"/>
                    <path d="M3403.59 1104.07C3402.91 1104.29 3402.69 1104.12 3402.59 1103.81C3402.36 1103.11 3402.81 1102.95 3403.36 1102.88C3403.64 1102.85 3404.19 1102.66 3404.15 1102.9C3404.08 1103.4 3403.7 1103.85 3403.59 1104.07Z" fill="white"/>
                    <path d="M3529.42 887.35C3529.46 887.19 3529.52 886.91 3529.58 886.64C3530.04 886.97 3530.74 887.05 3530.81 887.78C3530.84 888.09 3530.7 888.32 3530.32 888.39C3529.52 888.54 3529.61 887.91 3529.42 887.35Z" fill="white"/>
                    <path d="M3610.92 1098.48C3611.17 1098.37 3611.43 1098.54 3611.53 1098.83C3611.67 1099.22 3611.71 1099.67 3611.12 1099.72C3610.71 1099.75 3610.11 1100.29 3609.94 1099.59C3609.88 1099.34 3610.24 1098.65 3610.92 1098.48Z" fill="white"/>
                    <path d="M3424.06 1145.83C3423.82 1145.14 3424.45 1144.78 3424.69 1144.27C3424.85 1144.5 3425.08 1144.72 3425.14 1144.97C3425.24 1145.4 3425.59 1145.95 3424.79 1146.17C3424.43 1146.27 3424.24 1146.12 3424.06 1145.83Z" fill="white"/>
                    <path d="M3599.91 1065.1C3600.3 1064.92 3599.86 1065 3600.15 1065.28C3600.31 1065.43 3600.4 1065.87 3600.3 1066.05C3600.02 1066.53 3599.87 1067.22 3599.09 1067.18C3599 1067.18 3598.84 1067.08 3598.85 1067.04C3598.9 1066.23 3599.6 1065.77 3599.9 1065.11L3599.91 1065.1Z" fill="white"/>
                    <path d="M3554.45 1028.62C3554.8 1028.16 3554.94 1027.43 3555.7 1027.52C3555.88 1027.54 3556.14 1027.95 3556.15 1028.19C3556.17 1028.94 3555.48 1028.74 3555.06 1028.9C3554.77 1029.01 3554.52 1028.99 3554.45 1028.62Z" fill="white"/>
                    <path d="M3461.97 990.32C3461.93 990.12 3461.83 989.91 3461.86 989.72C3461.88 989.59 3462.09 989.39 3462.18 989.4C3462.52 989.46 3462.95 991.33 3462.69 991.59C3462.63 991.65 3462.28 991.58 3462.26 991.5C3462.13 991.11 3462.06 990.71 3461.98 990.31L3461.97 990.32Z" fill="white"/>
                    <path d="M3628.42 958.29C3628.24 957.67 3628.59 957.49 3629.02 957.45C3629.33 957.42 3629.55 957.56 3629.63 957.94C3629.75 958.5 3629.43 958.67 3629 958.71C3628.7 958.74 3628.39 958.65 3628.41 958.29H3628.42Z" fill="white"/>
                    <path d="M3411.53 1129.75C3411.55 1129.41 3411.65 1129.2 3412.03 1129.12C3412.59 1129 3412.76 1129.32 3412.81 1129.75C3412.84 1130.06 3412.7 1130.28 3412.32 1130.36C3411.75 1130.48 3411.63 1130.15 3411.53 1129.75Z" fill="white"/>
                    <path d="M3394.78 900.99C3394.8 900.65 3394.9 900.44 3395.28 900.36C3395.84 900.24 3396.01 900.56 3396.06 900.99C3396.09 901.3 3395.95 901.52 3395.57 901.6C3395 901.72 3394.88 901.39 3394.78 900.99Z" fill="white"/>
                    <path d="M3507.37 863.36C3507.75 863.28 3508.1 863.26 3508.23 863.82C3508.32 864.2 3508.18 864.42 3507.88 864.52C3507.47 864.66 3507.11 864.64 3506.98 864.08C3506.89 863.71 3507.04 863.52 3507.37 863.36Z" fill="white"/>
                    <path d="M3347.17 1039.67C3346.61 1039.55 3346.12 1039.33 3345.92 1038.75C3345.87 1038.6 3345.98 1038.23 3345.99 1038.23C3346.57 1038.27 3347.05 1038.51 3347.25 1039.1C3347.3 1039.25 3347.21 1039.46 3347.17 1039.67Z" fill="white"/>
                    <path d="M3365.21 929.42C3365.06 929.08 3365.26 928.85 3365.56 928.75C3365.97 928.61 3366.33 928.63 3366.46 929.19C3366.55 929.57 3366.41 929.79 3366.11 929.89C3365.7 930.03 3365.31 930.01 3365.22 929.43L3365.21 929.42Z" fill="white"/>
                    <path d="M3417.69 1041.93C3417.71 1041.59 3417.81 1041.38 3418.19 1041.3C3418.75 1041.18 3418.92 1041.5 3418.97 1041.93C3419 1042.24 3418.86 1042.46 3418.48 1042.54C3417.91 1042.66 3417.79 1042.33 3417.69 1041.93Z" fill="white"/>
                    <path d="M3630.91 1027.39C3630.94 1027 3631.03 1026.78 3631.41 1026.7C3631.97 1026.58 3632.14 1026.9 3632.19 1027.33C3632.22 1027.64 3632.08 1027.86 3631.71 1027.95C3631.13 1028.08 3631.04 1027.71 3630.92 1027.39H3630.91Z" fill="white"/>
                    <path d="M3607.58 1037.76C3608.15 1037.6 3608.33 1037.94 3608.38 1038.37C3608.41 1038.68 3608.27 1038.9 3607.89 1038.98C3607.33 1039.1 3607.16 1038.78 3607.11 1038.35C3607.08 1038.04 3607.2 1037.76 3607.57 1037.75L3607.58 1037.76Z" fill="white"/>
                    <path d="M3312.73 1013.3C3312.8 1013.7 3312.88 1014.11 3312.3 1014.18C3312.12 1014.2 3311.93 1014.14 3311.75 1014.12C3311.94 1013.72 3312.13 1013.31 3312.32 1012.91C3312.46 1013.04 3312.59 1013.17 3312.73 1013.31V1013.3Z" fill="white"/>
                    <path d="M3359.59 982.08C3359.52 981.68 3359.44 981.27 3360.02 981.2C3360.2 981.18 3360.39 981.24 3360.57 981.26C3360.38 981.66 3360.19 982.07 3360 982.47C3359.86 982.34 3359.73 982.21 3359.59 982.07V982.08Z" fill="white"/>
                    <path d="M3425.3 1110.45C3425.52 1110.09 3425.73 1109.75 3425.93 1109.41C3426.09 1109.64 3426.34 1109.85 3426.38 1110.1C3426.46 1110.64 3426 1110.72 3425.61 1110.74C3425.5 1110.74 3425.39 1110.54 3425.3 1110.45Z" fill="white"/>
                    <path d="M3468.23 937.98C3468.47 938.01 3468.8 938 3468.81 938.07C3468.95 938.6 3468.42 938.85 3468.25 939.27C3468.12 939.14 3467.92 939.03 3467.86 938.87C3467.72 938.47 3467.75 938.09 3468.22 937.98H3468.23Z" fill="white"/>
                    <path d="M3474.76 839.61C3474.82 839.47 3474.84 839.23 3474.94 839.19C3475.31 839.04 3475.75 838.92 3475.9 839.45C3475.97 839.69 3475.83 839.99 3475.78 840.27C3475.44 840.05 3475.1 839.84 3474.75 839.62L3474.76 839.61Z" fill="white"/>
                    <path d="M3461.67 867.73C3461.12 867.88 3460.98 867.5 3460.93 867.09C3460.91 866.92 3461.05 866.74 3461.12 866.56C3461.46 866.85 3461.8 867.13 3462.14 867.42C3461.98 867.52 3461.83 867.62 3461.66 867.72L3461.67 867.73Z" fill="white"/>
                    <path d="M3430.71 1085.63C3430.74 1085.55 3430.75 1085.31 3430.84 1085.27C3431.2 1085.12 3431.65 1084.99 3431.81 1085.51C3431.89 1085.75 3431.75 1086.05 3431.7 1086.33C3431.38 1086.1 3431.05 1085.88 3430.7 1085.63H3430.71Z" fill="white"/>
                    <path d="M3423.67 1045.83C3423.52 1045.28 3423.9 1045.14 3424.31 1045.09C3424.48 1045.07 3424.66 1045.21 3424.84 1045.28C3424.55 1045.62 3424.27 1045.96 3423.98 1046.3C3423.88 1046.14 3423.78 1045.99 3423.68 1045.82L3423.67 1045.83Z" fill="white"/>
                    <path d="M3410.4 867.44C3410.46 867.26 3410.52 867.08 3410.58 866.9C3410.93 867.17 3411.29 867.44 3411.64 867.7C3411.49 867.81 3411.35 867.95 3411.18 868.01C3410.65 868.19 3410.49 867.85 3410.4 867.43V867.44Z" fill="white"/>
                    <path d="M3596.81 1020.94C3596.26 1021.09 3596.12 1020.71 3596.07 1020.3C3596.05 1020.13 3596.19 1019.95 3596.26 1019.77C3596.6 1020.06 3596.94 1020.34 3597.28 1020.63C3597.12 1020.73 3596.97 1020.83 3596.8 1020.93L3596.81 1020.94Z" fill="white"/>
                    <path d="M3638.13 1009.25C3637.95 1009.06 3637.76 1008.95 3637.71 1008.79C3637.57 1008.38 3637.61 1007.99 3638.16 1007.94C3638.34 1007.92 3638.52 1008 3638.71 1008.03C3638.52 1008.43 3638.34 1008.83 3638.14 1009.26L3638.13 1009.25Z" fill="white"/>
                    <path d="M3464.11 1161.38C3464.24 1160.98 3464.59 1161.04 3464.87 1160.99C3464.96 1160.98 3465.19 1161.23 3465.17 1161.32C3465.09 1161.7 3464.71 1161.65 3464.43 1161.69C3464.34 1161.7 3464.21 1161.48 3464.11 1161.38Z" fill="white"/>
                    <path d="M3445.19 1141.72C3445.02 1141.42 3444.84 1141.19 3444.77 1140.93C3444.73 1140.78 3444.89 1140.58 3444.95 1140.4C3445.11 1140.63 3445.32 1140.84 3445.4 1141.1C3445.45 1141.24 3445.29 1141.45 3445.18 1141.72H3445.19Z" fill="white"/>
                    <path d="M3591.21 908.42C3591.34 908.02 3591.69 908.08 3591.97 908.03C3592.06 908.02 3592.29 908.27 3592.27 908.36C3592.19 908.74 3591.81 908.69 3591.53 908.73C3591.44 908.74 3591.31 908.52 3591.21 908.42Z" fill="white"/>
                    <path d="M3488.12 854.44C3488.42 854.27 3488.65 854.09 3488.91 854.02C3489.06 853.98 3489.26 854.14 3489.44 854.2C3489.21 854.36 3489 854.57 3488.74 854.65C3488.6 854.7 3488.39 854.54 3488.12 854.43V854.44Z" fill="white"/>
                    <path d="M3484.95 855.56C3485.11 855.54 3485.39 855.45 3485.65 855.47C3485.96 855.5 3486.01 855.72 3485.74 855.86C3485.51 855.98 3485.21 856.05 3484.94 856.04C3484.63 856.03 3484.6 855.84 3484.94 855.56H3484.95Z" fill="white"/>
                    <path d="M3595.34 889.97C3595.16 889.94 3594.85 889.96 3594.81 889.86C3594.67 889.52 3594.92 889.37 3595.22 889.36C3595.4 889.36 3595.71 889.36 3595.75 889.46C3595.89 889.8 3595.64 889.93 3595.34 889.97Z" fill="white"/>
                    <path d="M3414.62 1123.42C3414.65 1123.24 3414.63 1122.93 3414.73 1122.89C3415.07 1122.75 3415.22 1123 3415.23 1123.3C3415.23 1123.48 3415.23 1123.79 3415.13 1123.83C3414.79 1123.97 3414.66 1123.72 3414.62 1123.42Z" fill="white"/>
                    <path d="M3407.97 878.44C3407.8 878.14 3407.62 877.91 3407.55 877.65C3407.51 877.5 3407.67 877.3 3407.73 877.12C3407.89 877.35 3408.1 877.56 3408.18 877.82C3408.23 877.96 3408.07 878.17 3407.96 878.44H3407.97Z" fill="white"/>
                    <path d="M3579.29 1091.86C3579.34 1091.73 3579.41 1091.56 3579.47 1091.38C3579.63 1091.61 3579.84 1091.82 3579.92 1092.08C3579.97 1092.22 3579.81 1092.43 3579.74 1092.6C3579.45 1092.46 3579.28 1092.24 3579.28 1091.86H3579.29Z" fill="white"/>
                    <path d="M3361.84 891.13C3362.13 891.04 3362.41 891.05 3362.43 891.42C3362.43 891.52 3362.16 891.66 3361.99 891.73C3361.71 891.85 3361.42 891.82 3361.4 891.45C3361.4 891.35 3361.68 891.24 3361.83 891.13H3361.84Z" fill="white"/>
                    <path d="M3571.31 1050.28C3571.6 1050.19 3571.88 1050.2 3571.9 1050.57C3571.9 1050.67 3571.63 1050.81 3571.46 1050.88C3571.18 1051 3570.89 1050.97 3570.87 1050.6C3570.87 1050.5 3571.15 1050.39 3571.3 1050.28H3571.31Z" fill="white"/>
                    <path d="M3560.12 1043.15C3560.41 1043.06 3560.69 1043.07 3560.71 1043.44C3560.71 1043.54 3560.44 1043.68 3560.27 1043.75C3559.99 1043.87 3559.7 1043.84 3559.68 1043.47C3559.68 1043.37 3559.96 1043.26 3560.11 1043.15H3560.12Z" fill="white"/>
                    <path d="M3515.59 1023.01C3515.42 1022.71 3515.24 1022.48 3515.17 1022.22C3515.13 1022.07 3515.29 1021.87 3515.35 1021.69C3515.51 1021.92 3515.72 1022.13 3515.8 1022.39C3515.85 1022.53 3515.69 1022.74 3515.58 1023.01H3515.59Z" fill="white"/>
                    <path d="M3428.37 1096.13C3428.43 1095.9 3428.5 1095.63 3428.57 1095.37C3428.71 1095.5 3428.94 1095.62 3428.96 1095.77C3428.99 1096.04 3428.89 1096.32 3428.84 1096.59C3428.7 1096.46 3428.57 1096.33 3428.37 1096.13Z" fill="white"/>
                    <path d="M3433.33 996.77C3433.38 996.64 3433.45 996.47 3433.51 996.29C3433.67 996.52 3433.88 996.73 3433.96 996.99C3434.01 997.13 3433.85 997.34 3433.78 997.51C3433.49 997.37 3433.32 997.15 3433.32 996.77H3433.33Z" fill="white"/>
                    <path d="M3384.85 999.48C3384.68 999.18 3384.5 998.95 3384.43 998.69C3384.39 998.54 3384.55 998.34 3384.61 998.16C3384.77 998.39 3384.98 998.6 3385.06 998.86C3385.11 999 3384.95 999.21 3384.84 999.48H3384.85Z" fill="white"/>
                    <path d="M3308.9 1004.29C3309.03 1003.89 3309.38 1003.95 3309.66 1003.9C3309.75 1003.89 3309.98 1004.14 3309.96 1004.23C3309.88 1004.61 3309.5 1004.56 3309.22 1004.6C3309.13 1004.61 3309 1004.39 3308.9 1004.29Z" fill="white"/>
                    <path d="M3362.07 961.53C3362.36 961.44 3362.64 961.45 3362.66 961.82C3362.66 961.92 3362.39 962.06 3362.22 962.13C3361.94 962.25 3361.65 962.22 3361.63 961.85C3361.63 961.75 3361.91 961.64 3362.06 961.53H3362.07Z" fill="white"/>
                    <path d="M3578.37 923.2C3578.19 923.17 3577.88 923.19 3577.84 923.09C3577.7 922.75 3577.95 922.6 3578.25 922.59C3578.43 922.59 3578.74 922.59 3578.78 922.69C3578.92 923.03 3578.67 923.16 3578.37 923.2Z" fill="white"/>
                    <path d="M3483.61 960.84C3483.64 960.66 3483.62 960.35 3483.72 960.31C3484.06 960.17 3484.21 960.42 3484.22 960.72C3484.22 960.9 3484.22 961.21 3484.12 961.25C3483.78 961.39 3483.65 961.14 3483.61 960.84Z" fill="white"/>
                    <path d="M3479.18 945.18C3479.08 945.06 3478.96 944.95 3478.9 944.81C3478.88 944.76 3478.99 944.65 3479.05 944.57C3479.15 944.69 3479.27 944.8 3479.33 944.94C3479.35 944.99 3479.24 945.1 3479.18 945.18Z" fill="white"/>
                    <path d="M3404.77 857.92C3404.67 857.8 3404.55 857.69 3404.49 857.55C3404.47 857.5 3404.58 857.39 3404.64 857.31C3404.74 857.43 3404.86 857.54 3404.92 857.68C3404.94 857.73 3404.83 857.84 3404.77 857.92Z" fill="white"/>
                    <path d="M3547.29 1042.57C3547.33 1042.43 3547.38 1042.28 3547.42 1042.13C3547.5 1042.18 3547.66 1042.24 3547.66 1042.29C3547.66 1042.44 3547.6 1042.59 3547.56 1042.74C3547.48 1042.69 3547.39 1042.63 3547.29 1042.57Z" fill="white"/>
                    <path d="M3596.01 1046.5C3595.95 1046.6 3595.89 1046.68 3595.84 1046.77C3595.74 1046.65 3595.62 1046.54 3595.56 1046.4C3595.54 1046.35 3595.65 1046.24 3595.71 1046.16C3595.81 1046.28 3595.92 1046.39 3596.01 1046.51V1046.5Z" fill="white"/>
                    <path d="M3507.53 1067.71C3507.67 1067.75 3507.82 1067.8 3507.97 1067.84C3507.92 1067.92 3507.86 1068.08 3507.81 1068.08C3507.66 1068.08 3507.51 1068.02 3507.36 1067.98C3507.41 1067.9 3507.47 1067.81 3507.53 1067.71Z" fill="white"/>
                    <path d="M3467.29 1040.35C3467.41 1040.25 3467.52 1040.13 3467.66 1040.07C3467.71 1040.05 3467.82 1040.16 3467.9 1040.22C3467.78 1040.32 3467.67 1040.44 3467.53 1040.5C3467.48 1040.52 3467.37 1040.41 3467.29 1040.35Z" fill="white"/>
                    <path d="M3416.5 1046.26C3416.6 1046.32 3416.68 1046.38 3416.77 1046.43C3416.65 1046.53 3416.54 1046.65 3416.4 1046.71C3416.35 1046.73 3416.24 1046.62 3416.16 1046.56C3416.28 1046.46 3416.39 1046.35 3416.51 1046.26H3416.5Z" fill="white"/>
                    <path d="M3409.58 1108.95C3409.62 1108.81 3409.67 1108.66 3409.71 1108.51C3409.79 1108.56 3409.95 1108.62 3409.95 1108.67C3409.95 1108.82 3409.89 1108.97 3409.85 1109.12C3409.77 1109.07 3409.68 1109.01 3409.58 1108.95Z" fill="white"/>
                    <path d="M3527.98 1065.24C3528.04 1065.14 3528.1 1065.06 3528.15 1064.97C3528.25 1065.09 3528.37 1065.2 3528.43 1065.34C3528.45 1065.39 3528.34 1065.5 3528.28 1065.58C3528.18 1065.46 3528.07 1065.35 3527.98 1065.23V1065.24Z" fill="white"/>
                    <path d="M3474.16 1008.18C3474.1 1008.28 3474.04 1008.36 3473.99 1008.45C3473.89 1008.33 3473.77 1008.22 3473.71 1008.08C3473.69 1008.03 3473.8 1007.92 3473.86 1007.84C3473.96 1007.96 3474.07 1008.07 3474.16 1008.19V1008.18Z" fill="white"/>
                    <path d="M3324.11 1028.81C3324.02 1028.75 3323.87 1028.68 3323.88 1028.66C3323.92 1028.51 3323.97 1028.33 3324.08 1028.24C3324.31 1028.06 3324.46 1028.16 3324.39 1028.44C3324.36 1028.58 3324.21 1028.69 3324.11 1028.81Z" fill="white"/>
                    <path d="M3324.28 1027.9C3324.38 1027.78 3324.49 1027.55 3324.58 1027.56C3324.83 1027.58 3324.91 1027.76 3324.77 1027.89C3324.65 1028.01 3324.48 1028.08 3324.34 1028.17C3324.32 1028.08 3324.3 1027.99 3324.28 1027.9Z" fill="white"/>
                    <path d="M3503.14 924.27C3503.18 924.13 3503.23 923.98 3503.27 923.83C3503.35 923.88 3503.51 923.94 3503.51 923.99C3503.51 924.14 3503.45 924.29 3503.41 924.44C3503.33 924.39 3503.24 924.33 3503.14 924.27Z" fill="white"/>
                    <path d="M3344.13 1117.05C3344.17 1116.91 3344.22 1116.76 3344.26 1116.61C3344.34 1116.66 3344.5 1116.72 3344.5 1116.77C3344.5 1116.92 3344.44 1117.07 3344.4 1117.22C3344.32 1117.17 3344.23 1117.11 3344.13 1117.05Z" fill="white"/>
                    <path d="M3316.68 1024.97C3316.74 1024.87 3316.8 1024.79 3316.85 1024.7C3316.95 1024.82 3317.07 1024.93 3317.13 1025.07C3317.15 1025.12 3317.04 1025.23 3316.98 1025.31C3316.88 1025.19 3316.77 1025.08 3316.68 1024.96V1024.97Z" fill="white"/>
                    <path d="M3390.04 1116.94C3390 1117.08 3389.95 1117.23 3389.91 1117.38C3389.83 1117.33 3389.67 1117.27 3389.67 1117.22C3389.67 1117.07 3389.73 1116.92 3389.77 1116.77C3389.85 1116.82 3389.94 1116.88 3390.04 1116.94Z" fill="white"/>
                    <path d="M3408.31 964.5C3408.35 964.36 3408.4 964.21 3408.44 964.06C3408.52 964.11 3408.68 964.17 3408.68 964.22C3408.68 964.37 3408.62 964.52 3408.58 964.67C3408.5 964.62 3408.41 964.56 3408.31 964.5Z" fill="white"/>
                    <path d="M3482.4 1089.29C3482.5 1089.35 3482.58 1089.41 3482.67 1089.46C3482.55 1089.56 3482.44 1089.68 3482.3 1089.74C3482.25 1089.76 3482.14 1089.65 3482.06 1089.59C3482.18 1089.49 3482.29 1089.38 3482.41 1089.29H3482.4Z" fill="white"/>
                    <path d="M3346.97 1004.58C3346.87 1004.46 3346.75 1004.35 3346.69 1004.21C3346.67 1004.16 3346.78 1004.05 3346.84 1003.97C3346.94 1004.09 3347.06 1004.2 3347.12 1004.34C3347.14 1004.39 3347.03 1004.5 3346.97 1004.58Z" fill="white"/>
                    <path d="M3469.88 1094.95C3469.92 1094.81 3469.97 1094.66 3470.01 1094.51C3470.09 1094.56 3470.25 1094.62 3470.25 1094.67C3470.25 1094.82 3470.19 1094.97 3470.15 1095.12L3469.88 1094.95Z" fill="white"/>
                    <path d="M3479.57 972.54C3479.43 972.5 3479.28 972.45 3479.13 972.41C3479.18 972.33 3479.24 972.17 3479.29 972.17C3479.44 972.17 3479.59 972.23 3479.74 972.27C3479.69 972.35 3479.63 972.44 3479.57 972.54Z" fill="white"/>
                    <path d="M3593.02 880.88C3592.92 880.76 3592.8 880.65 3592.74 880.51C3592.72 880.46 3592.83 880.35 3592.89 880.27C3592.99 880.39 3593.11 880.5 3593.17 880.64C3593.19 880.69 3593.08 880.8 3593.02 880.88Z" fill="white"/>
                    <path d="M3282.03 890.47C3283.18 888.87 3284.68 889.15 3286.1 889.44C3286.62 889.55 3287.4 889.83 3287.68 889.79C3289.52 889.51 3288.88 891.62 3289.96 891.96C3289.47 892.96 3289.22 894.32 3288.42 894.86C3287.66 895.37 3286.65 895.92 3285.64 896.05C3284.68 896.18 3283.39 896.79 3283.26 894.93C3283.24 894.65 3282.67 894.43 3282.38 894.16C3281.11 892.93 3281.03 891.67 3282.03 890.49V890.47Z" fill="white"/>
                    <path d="M3309.13 1047.11C3308.25 1046.35 3308.22 1045.11 3308.49 1044.8C3309.54 1043.62 3308.85 1042.2 3309.31 1040.95C3309.63 1040.07 3309.97 1039.59 3310.88 1039.56C3312.38 1039.53 3312.97 1039.87 3313.22 1040.93C3313.33 1041.39 3313.48 1041.82 3313.66 1042.26C3314.41 1044.17 3312.33 1047.76 3310.01 1047.37C3309.6 1047.3 3309.25 1047.26 3309.14 1047.09L3309.13 1047.11Z" fill="white"/>
                    <path d="M3357.1 965.24C3357.05 964.22 3355.78 963.43 3356.6 962.28C3356.72 962.12 3356.93 961.99 3356.97 961.81C3357.15 961.08 3357.07 960.23 3357.96 959.87C3358.65 959.59 3359.28 959.98 3359.92 960.13C3361.18 960.43 3362.12 962.01 3361.93 963.43C3361.79 964.53 3361.19 965.19 3360.13 965.6C3359.03 966.02 3358.11 965.41 3357.09 965.24H3357.1Z" fill="white"/>
                    <path d="M3494.85 996.02C3496.18 997.15 3496.38 998.15 3495.68 999.21C3495.3 999.79 3494.77 1000.28 3494.35 1000.84C3493.95 1001.37 3493.61 1001.34 3493.15 1000.91C3492.64 1000.43 3492 1000.07 3491.56 999.54C3490.81 998.64 3490.73 997.54 3491.03 996.44C3491.09 996.21 3491.4 995.91 3491.61 995.9C3492.39 995.84 3493.09 995.53 3493.81 995.33C3494.16 995.23 3494.74 995.95 3494.85 996.03V996.02Z" fill="white"/>
                    <path d="M3425.73 1108.25C3425.08 1107.79 3424.15 1107.16 3424.68 1106.01C3424.93 1105.46 3425.41 1104.44 3426.1 1104.73C3426.85 1105.05 3427.96 1104.78 3428.37 1105.78C3428.66 1106.47 3428.71 1107.3 3429.13 1107.88C3429.52 1108.41 3429.49 1108.66 3429.12 1109.11C3428.59 1109.77 3427.92 1109.8 3427.29 1109.51C3426.76 1109.26 3426.35 1108.77 3425.73 1108.24V1108.25Z" fill="white"/>
                    <path d="M3510.16 980.49C3509.54 979.99 3509.42 979.08 3509.88 978.45C3510.04 978.24 3512.71 977.24 3512.94 977.3C3513.46 977.45 3514.01 979.21 3513.91 979.2C3512.65 979.06 3513.36 980.44 3512.69 980.68C3512.05 980.91 3510.56 980.8 3510.16 980.48V980.49Z" fill="white"/>
                    <path d="M3350.42 899.95C3350.14 900.26 3349.82 900.82 3349.51 900.57C3348.94 900.1 3348.1 899.84 3348 898.86C3347.95 898.32 3348.35 897.95 3348.32 897.5C3348.26 896.78 3348.43 896.43 3349.22 896.32C3350.07 896.2 3350.69 896.59 3350.84 897.23C3351.05 898.13 3351.4 899.2 3350.42 899.95Z" fill="white"/>
                    <path d="M3354.22 1004.48C3354.68 1004.92 3355.52 1005.19 3354.71 1006.08C3354.53 1006.28 3354.77 1006.84 3354.78 1007.24C3354.79 1007.69 3354.77 1008.14 3354.76 1008.6C3354.2 1008.22 3353.4 1007.96 3353.15 1007.43C3352.83 1006.76 3352.97 1005.87 3352.86 1005.08C3352.8 1004.65 3352.83 1004.24 3353.21 1004.04C3353.63 1003.83 3353.92 1004.23 3354.22 1004.47V1004.48Z" fill="white"/>
                    <path d="M3449.39 874.69C3449.54 874.82 3449.71 874.93 3449.84 875.07C3450.13 875.39 3450.82 875.54 3450.5 876.17C3450.18 876.81 3449.62 877.14 3448.85 877.11C3448.19 877.09 3448.1 876.71 3448.01 876.18C3447.95 875.84 3447.6 875.55 3447.54 875.21C3447.45 874.76 3447.25 874.22 3447.74 873.86C3448.32 873.43 3448.57 874.07 3448.94 874.3C3449.11 874.4 3449.24 874.55 3449.39 874.67V874.69Z" fill="white"/>
                    <path d="M3512.65 991.15C3513.51 990.82 3514.31 990.22 3515.3 990.33C3515.53 990.35 3515.83 990.59 3515.82 990.96C3515.81 991.43 3516.12 992.04 3515.58 992.31C3514.93 992.65 3514.19 992.85 3513.47 993.03C3512.61 993.24 3513.16 992.34 3512.8 992.12C3512.43 991.88 3512.26 991.56 3512.65 991.15Z" fill="white"/>
                    <path d="M3314.67 975.39C3315.15 975.67 3315.28 976.02 3314.83 976.51C3314.13 977.27 3313.33 977.37 3312.59 976.81C3311.94 976.31 3311.92 975.63 3312.17 975.02C3312.46 974.32 3313.67 974.51 3314.67 975.39Z" fill="white"/>
                    <path d="M3227.89 1000.49C3228.12 1000.68 3228.39 1000.84 3228.57 1001.07C3228.99 1001.61 3228.64 1002.38 3227.96 1002.44C3227.56 1002.48 3227.15 1002.59 3226.77 1002.53C3226.27 1002.45 3225.79 1002.1 3225.71 1001.65C3225.61 1001.14 3226.19 1000.64 3226 1000.04C3226.56 1000.07 3227.19 999.68 3227.67 1000.29C3227.73 1000.37 3227.82 1000.42 3227.9 1000.48L3227.89 1000.49Z" fill="white"/>
                    <path d="M3465.17 961.81C3465.14 961.46 3465.13 961.16 3465.08 960.87C3464.96 960.13 3466.06 959.9 3465.8 959.07C3465.66 958.63 3465.96 958.28 3466.41 958.29C3466.84 958.29 3467.05 958.62 3467.07 959.07C3467.1 959.66 3467.21 960.25 3467.21 960.85C3467.21 961.13 3467.6 961.63 3466.89 961.67C3466.32 961.7 3465.74 961.77 3465.17 961.82V961.81Z" fill="white"/>
                    <path d="M3495.44 1082.43C3494.96 1082.85 3493.82 1082.68 3493.37 1082.1C3493.19 1081.87 3493.4 1080.03 3493.69 1079.83C3494.42 1079.32 3494.72 1080.11 3495.09 1080.43C3495.79 1081.02 3495.9 1082.02 3495.44 1082.42V1082.43Z" fill="white"/>
                    <path d="M3412.43 1071.63C3412.75 1071.1 3413.09 1070.9 3413.6 1071.36C3414.22 1071.93 3414.49 1072.91 3414.1 1073.37C3413.72 1073.83 3412.68 1073.75 3412.04 1073.21C3411.09 1072.4 3412.25 1072.11 3412.43 1071.63Z" fill="white"/>
                    <path d="M3405.49 1074.04C3405.31 1073.52 3405.09 1072.95 3405.46 1072.44C3405.86 1071.88 3407.22 1072.11 3407.73 1072.76C3408.06 1073.19 3407.78 1073.46 3407.51 1073.74C3406.88 1074.37 3405.92 1074.51 3405.49 1074.04Z" fill="white"/>
                    <path d="M3456.74 997.25C3457.04 997.5 3457.18 997.64 3457.34 997.75C3457.96 998.15 3457.69 998.8 3457.83 999.34C3457.28 999.3 3456.68 999.68 3456.18 999.15C3456.05 999.01 3455.89 998.88 3455.73 998.77C3455.19 998.39 3454.92 998.05 3455.49 997.39C3456.04 996.75 3456.42 996.82 3456.74 997.25Z" fill="white"/>
                    <path d="M3332.27 1117.69C3332.69 1117.12 3333.13 1117.31 3333.3 1117.65C3333.63 1118.32 3333.83 1119.06 3334.03 1119.78C3334.21 1120.46 3333.63 1120.22 3333.33 1120.29C3332.03 1120.6 3332.48 1119.26 3331.96 1118.81C3331.36 1118.29 3332.2 1118.01 3332.28 1117.69H3332.27Z" fill="white"/>
                    <path d="M3443.61 1031.13C3442.88 1030.72 3442.85 1030.02 3443.08 1029.4C3443.26 1028.93 3444.91 1028.83 3445.06 1029.21C3445.35 1029.95 3445.18 1030.62 3444.6 1031.21C3444.19 1031.62 3443.92 1031.38 3443.61 1031.13Z" fill="white"/>
                    <path d="M3383.18 857.9C3383.63 857.52 3384.2 857.56 3384.75 857.7C3385.08 857.78 3384.98 859.43 3384.62 859.94C3384.3 860.4 3383.97 860.2 3383.67 859.92C3383.05 859.37 3382.8 858.38 3383.18 857.9Z" fill="white"/>
                    <path d="M3484.1 1018.74C3483.84 1018.91 3483.49 1019 3483.36 1018.67C3483.04 1017.89 3482.52 1017.19 3482.48 1016.29C3482.46 1015.91 3482.66 1015.64 3482.87 1015.56C3483.07 1015.48 3483.54 1015.63 3483.62 1015.8C3483.93 1016.48 3484.13 1017.21 3484.35 1017.93C3484.45 1018.24 3484.35 1018.51 3484.09 1018.73L3484.1 1018.74Z" fill="white"/>
                    <path d="M3515.61 1027.52C3515.35 1027.34 3515.01 1027.12 3515.27 1026.65C3515.49 1026.24 3515.91 1026.32 3516.27 1026.22C3517 1026.02 3517.37 1026.58 3517.72 1026.97C3517.93 1027.2 3517.99 1027.94 3517.84 1028.05C3517.52 1028.28 3516.98 1028.36 3516.58 1028.27C3516.24 1028.2 3515.97 1027.81 3515.62 1027.51L3515.61 1027.52Z" fill="white"/>
                    <path d="M3385.33 1128.57C3385.08 1129 3384.72 1129.13 3384.24 1128.68C3383.63 1128.11 3383.38 1127.15 3383.76 1126.68C3384.39 1125.89 3384.91 1127.23 3385.59 1126.78C3385.7 1126.71 3385.79 1127.94 3385.34 1128.57H3385.33Z" fill="white"/>
                    <path d="M3428.79 947.16C3428.75 946.98 3428.63 946.76 3428.69 946.64C3429.04 945.93 3430.74 945.58 3431.28 946.11C3431.46 946.29 3431.68 946.61 3431.63 946.81C3431.58 947.01 3431.25 947.23 3431.01 947.29C3429.93 947.55 3429.18 947.48 3428.79 947.17V947.16Z" fill="white"/>
                    <path d="M3321.41 913.98C3321.22 914.21 3321.06 914.47 3320.84 914.65C3320.25 915.11 3320 914.47 3319.64 914.23C3319.17 913.92 3319.38 913.58 3319.64 913.27C3319.89 912.98 3320.07 912.65 3320.55 912.64C3321.03 912.64 3321.58 912.45 3321.85 912.99C3322.06 913.41 3321.64 913.68 3321.41 913.98Z" fill="white"/>
                    <path d="M3480.8 1018.11C3481.19 1018.6 3480.88 1018.88 3480.61 1019.14C3479.96 1019.77 3479.02 1019.91 3478.6 1019.42C3478.25 1019.02 3478.56 1018.71 3478.84 1018.45C3479.49 1017.82 3480.41 1017.7 3480.8 1018.11Z" fill="white"/>
                    <path d="M3391.54 1134.35C3390.84 1134.39 3390.2 1134.59 3389.62 1133.98C3389.14 1133.48 3389.34 1133.2 3389.67 1132.78C3390.03 1132.33 3390.29 1132.21 3390.85 1132.56C3391.58 1133.03 3391.4 1133.72 3391.55 1134.34L3391.54 1134.35Z" fill="white"/>
                    <path d="M3377.76 1127.98C3377.71 1127.8 3377.55 1127.57 3377.62 1127.46C3377.82 1127.13 3378.05 1126.68 3378.37 1126.59C3378.88 1126.44 3379.51 1126.19 3379.99 1126.7C3380.36 1127.09 3380.05 1127.4 3379.78 1127.67C3379.12 1128.31 3378.24 1128.43 3377.77 1127.98H3377.76Z" fill="white"/>
                    <path d="M3337.33 876.32C3337.02 876.05 3336.7 875.75 3337.12 875.38C3337.67 874.9 3338.38 874.77 3338.89 875.32C3339.16 875.61 3339.09 876.21 3339.17 876.68C3338.52 876.71 3337.82 877.01 3337.32 876.33L3337.33 876.32Z" fill="white"/>
                    <path d="M3273 1021.87C3273.5 1021.35 3274.61 1021.31 3274.94 1021.69C3275.28 1022.09 3274.94 1022.36 3274.72 1022.66C3274.27 1023.27 3273.63 1023.39 3273.06 1023.05C3272.89 1022.95 3272.74 1022.16 3272.99 1021.86L3273 1021.87Z" fill="white"/>
                    <path d="M3437.71 1052.17C3437.32 1051.85 3436.94 1051.49 3437.39 1051.19C3437.95 1050.82 3438.64 1050.67 3439.27 1050.42C3439.38 1051.1 3439.43 1051.72 3438.83 1052.28C3438.26 1052.81 3438 1052.34 3437.71 1052.17Z" fill="white"/>
                    <path d="M3293.24 1095.15C3292.55 1094.69 3292.76 1094.35 3293.1 1093.89C3293.62 1093.16 3294 1093.43 3294.52 1093.86C3295.05 1094.3 3294.89 1094.6 3294.54 1095.06C3294.03 1095.74 3293.61 1095.65 3293.25 1095.15H3293.24Z" fill="white"/>
                    <path d="M3449.97 1048.31C3450.38 1047.97 3450.62 1047.06 3451.3 1047.82C3451.67 1048.23 3451.76 1048.89 3451.41 1049.41C3450.83 1050.28 3450.16 1049.34 3449.65 1049.26C3449 1049.16 3449.94 1048.68 3449.97 1048.31Z" fill="white"/>
                    <path d="M3535.32 1023.53C3535.1 1023.34 3534.84 1023.18 3534.65 1022.96C3534.31 1022.55 3534.63 1022.26 3534.9 1021.99C3535.09 1021.79 3535.19 1021.44 3535.56 1021.51C3535.98 1021.59 3536.2 1021.86 3536.21 1022.3C3536.21 1022.49 3536.25 1022.69 3536.26 1022.88C3536.28 1023.25 3536.45 1023.84 3536.28 1023.96C3535.91 1024.22 3535.63 1023.73 3535.32 1023.53Z" fill="white"/>
                    <path d="M3259.85 966.03C3259.25 966.42 3258.99 965.84 3258.65 965.58C3258.01 965.09 3258.61 964.75 3258.87 964.4C3259.11 964.07 3259.4 963.79 3259.81 964.2C3260.22 964.62 3260.25 965.68 3259.86 966.03H3259.85Z" fill="white"/>
                    <path d="M3377.82 1072.53C3378.17 1072.14 3378.44 1071.83 3378.85 1072.24C3379.26 1072.66 3379.3 1073.74 3378.9 1074.07C3378.49 1074.4 3378.23 1074.03 3377.91 1073.83C3377.13 1073.35 3377.45 1072.9 3377.82 1072.53Z" fill="white"/>
                    <path d="M3489.87 1041.1C3489.7 1041.22 3489.43 1041.54 3489.26 1041.49C3488.79 1041.37 3488.96 1040.85 3489.01 1040.58C3489.08 1040.17 3488.3 1039.39 3489.45 1039.36C3489.83 1039.35 3490.24 1039.23 3490.5 1039.6C3491.02 1040.36 3490.05 1040.52 3489.87 1041.1Z" fill="white"/>
                    <path d="M3440.32 893.85C3440.2 893.4 3439.75 892.66 3440.34 892.52C3440.85 892.4 3441.14 891.61 3441.73 892.02C3442.08 892.26 3441.6 893.77 3441.1 894.05C3440.84 894.19 3440.55 894.12 3440.33 893.85H3440.32Z" fill="white"/>
                    <path d="M3303.56 1079.51C3303.56 1079.51 3303.03 1079.45 3302.56 1079.31C3302.36 1079.25 3302.49 1077.88 3302.71 1077.71C3303.3 1077.25 3303.54 1077.88 3303.9 1078.11C3304.34 1078.39 3304.22 1078.73 3303.56 1079.5V1079.51Z" fill="white"/>
                    <path d="M3279.7 1053.19C3279.3 1052.59 3279.9 1052.35 3280.14 1051.99C3280.44 1051.54 3280.88 1051.65 3281.06 1051.99C3281.27 1052.39 3281.3 1052.9 3281.32 1053.36C3281.32 1053.53 3279.88 1053.38 3279.71 1053.17L3279.7 1053.19Z" fill="white"/>
                    <path d="M3289.83 869.87C3290.08 869.4 3290.4 869.4 3290.62 869.58C3291.21 870.08 3291.21 870.72 3290.89 871.37C3290.72 871.71 3290.4 871.7 3290.17 871.52C3289.54 871.02 3289.62 870.38 3289.83 869.86V869.87Z" fill="white"/>
                    <path d="M3450.63 940.14C3450.15 940.57 3449.54 940.67 3449.14 940.34C3448.95 940.18 3448.8 939.45 3449.19 939.01C3449.71 938.41 3450.02 938.98 3450.37 939.23C3450.7 939.46 3450.95 939.75 3450.63 940.14Z" fill="white"/>
                    <path d="M3242.32 956.87C3242.47 956.63 3243.84 956.26 3243.86 956.44C3243.93 956.9 3243.98 957.41 3243.84 957.83C3243.71 958.22 3243.29 958.35 3242.93 957.97C3242.64 957.66 3241.98 957.53 3242.32 956.87Z" fill="white"/>
                    <path d="M3456.29 942.55C3455.99 942.08 3455.8 941.46 3456.33 940.86C3456.52 940.64 3456.84 940.58 3457.07 940.88C3457.5 941.46 3457.62 942.09 3457.13 942.69C3456.95 942.91 3456.63 942.96 3456.3 942.55H3456.29Z" fill="white"/>
                    <path d="M3509.7 901.44C3509.26 901.11 3509.35 900.68 3509.7 900.5C3510.1 900.29 3510.61 900.26 3511.08 900.24C3511.25 900.24 3511.1 901.68 3510.89 901.85C3510.59 902.09 3510.13 901.92 3509.7 901.43V901.44Z" fill="white"/>
                    <path d="M3445.29 1046.69C3444.97 1046.41 3444.66 1046.14 3445.05 1045.74C3445.48 1045.3 3446.11 1045.22 3446.5 1045.55C3446.69 1045.71 3446.84 1046.44 3446.45 1046.88C3445.92 1047.48 3445.63 1046.88 3445.29 1046.69Z" fill="white"/>
                    <path d="M3523.79 992.56C3523.57 992.04 3523.43 991.54 3523.77 991.02C3523.97 990.72 3524.29 990.68 3524.49 990.9C3524.82 991.26 3525.7 991.39 3525.14 992.19C3524.8 992.68 3524.24 992.38 3523.79 992.55V992.56Z" fill="white"/>
                    <path d="M3443.8 1032.86C3443.76 1032.69 3443.71 1032.51 3443.67 1032.34C3444.53 1032.24 3445.43 1032.29 3446.17 1031.71C3446.2 1031.68 3446.51 1031.92 3446.6 1032.09C3446.65 1032.18 3446.52 1032.5 3446.48 1032.5C3445.68 1032.49 3445.14 1033.35 3444.29 1033.14C3444.11 1033.09 3443.96 1032.96 3443.79 1032.87L3443.8 1032.86Z" fill="white"/>
                    <path d="M3445.94 1028.68C3446.35 1028.25 3446.73 1028.99 3447.05 1029.32C3447.69 1029.97 3446.82 1030.27 3446.57 1030.69C3446.5 1030.81 3445.9 1031.02 3446.1 1030.54C3446.39 1029.82 3444.89 1029.7 3445.94 1028.68Z" fill="white"/>
                    <path d="M3296 917.09C3295.63 917.72 3294.97 917.43 3294.45 917.57C3294.41 917.2 3294.19 916.67 3294.35 916.49C3294.71 916.08 3295.28 916.08 3295.8 916.37C3296.11 916.54 3296.09 916.81 3296 917.09Z" fill="white"/>
                    <path d="M3310.53 1078.7C3310.85 1078.42 3311.04 1077.79 3311.67 1078.29C3312.13 1078.65 3311.91 1078.96 3311.63 1079.26C3311.33 1079.58 3311.12 1080.18 3310.49 1079.68C3310.03 1079.32 3310.28 1079.02 3310.53 1078.69V1078.7Z" fill="white"/>
                    <path d="M3348.56 856.47C3348.27 855.52 3348.53 854.93 3349.08 854.47C3349.21 854.36 3349.42 854.36 3349.6 854.3C3349.62 854.49 3349.6 854.7 3349.66 854.88C3349.89 855.57 3349.42 855.97 3349.03 856.37C3348.91 856.49 3348.62 856.46 3348.57 856.47H3348.56Z" fill="white"/>
                    <path d="M3287.03 1098.68C3286.91 1098.59 3287.09 1098.26 3287.38 1097.96C3287.68 1097.65 3287.88 1097.02 3288.52 1097.52C3288.97 1097.87 3288.78 1098.19 3288.5 1098.49C3288.2 1098.81 3287.98 1099.41 3287.03 1098.68Z" fill="white"/>
                    <path d="M3346.28 1074.65C3346.41 1074.61 3346.61 1074.47 3346.69 1074.53C3347.25 1074.91 3347.78 1075.3 3347.71 1076.13C3347.68 1076.47 3347.62 1076.66 3347.29 1076.57C3346.77 1076.43 3346.09 1075.1 3346.28 1074.66V1074.65Z" fill="white"/>
                    <path d="M3299.44 967.39C3298.88 966.61 3299.82 966.42 3300.07 965.98C3300.18 965.78 3300.63 965.94 3300.81 966.19C3301.31 966.94 3300.49 967.19 3300.19 967.61C3300.05 967.8 3299.65 967.66 3299.44 967.39Z" fill="white"/>
                    <path d="M3398.51 1029.65C3397.95 1028.87 3398.89 1028.68 3399.14 1028.24C3399.25 1028.04 3399.7 1028.2 3399.88 1028.45C3400.38 1029.2 3399.56 1029.45 3399.26 1029.87C3399.12 1030.06 3398.72 1029.92 3398.51 1029.65Z" fill="white"/>
                    <path d="M3414.1 895.6C3413.98 895.51 3414.16 895.18 3414.45 894.88C3414.75 894.57 3414.95 893.94 3415.59 894.44C3416.04 894.79 3415.85 895.11 3415.57 895.41C3415.27 895.73 3415.05 896.33 3414.1 895.6Z" fill="white"/>
                    <path d="M3451.19 1089.13C3451.8 1089.25 3452.44 1089.38 3453.07 1089.52C3453.1 1089.52 3453.13 1089.72 3453.09 1089.8C3452.89 1090.17 3452.58 1090.31 3452.15 1090.33C3451.66 1090.35 3451.17 1090.44 3450.97 1089.85C3450.92 1089.71 3451.06 1089.51 3451.18 1089.13H3451.19Z" fill="white"/>
                    <path d="M3469.77 1036.85C3469.54 1037.18 3469.3 1037.54 3468.84 1037.24C3468.24 1036.86 3468.47 1036.47 3468.85 1036.06C3469.11 1035.78 3469.32 1035.38 3469.78 1035.68C3470.37 1036.06 3470.18 1036.47 3469.76 1036.84L3469.77 1036.85Z" fill="white"/>
                    <path d="M3476.55 976.12C3476.25 975.65 3476.14 975.39 3476.44 975.16C3477.02 974.73 3477.64 974.62 3478.25 975.1C3478.37 975.19 3478.36 975.44 3478.42 975.61C3477.77 975.78 3477.12 975.96 3476.55 976.11V976.12Z" fill="white"/>
                    <path d="M3391.38 1060.64C3390.92 1060.36 3390.68 1060 3390.85 1059.82C3391.2 1059.46 3391.31 1058.6 3392.13 1058.96C3392.41 1059.08 3392.64 1059.45 3392.47 1059.66C3392.15 1060.06 3391.95 1060.75 3391.37 1060.64H3391.38Z" fill="white"/>
                    <path d="M3394.77 890.45C3394.46 890.17 3394.17 889.92 3394.56 889.49C3395.09 888.89 3395.4 889.44 3395.76 889.7C3396.08 889.94 3396.35 890.21 3395.97 890.65C3395.44 891.26 3395.14 890.67 3394.78 890.45H3394.77Z" fill="white"/>
                    <path d="M3336.1 960.43C3335.32 960.99 3335.13 960.05 3334.69 959.8C3334.49 959.69 3334.65 959.24 3334.9 959.06C3335.65 958.56 3335.9 959.38 3336.32 959.68C3336.51 959.82 3336.37 960.22 3336.1 960.43Z" fill="white"/>
                    <path d="M3486.21 981.35C3485.98 981.68 3485.74 982.04 3485.28 981.74C3484.68 981.36 3484.91 980.97 3485.29 980.56C3485.55 980.28 3485.76 979.88 3486.22 980.18C3486.81 980.56 3486.62 980.97 3486.2 981.34L3486.21 981.35Z" fill="white"/>
                    <path d="M3390.1 1119.03C3389.71 1119.6 3389.44 1119.6 3389.18 1119.42C3388.59 1119.02 3388.84 1118.63 3389.22 1118.26C3389.42 1118.07 3389.72 1117.6 3389.84 1117.81C3390.08 1118.23 3390.06 1118.8 3390.1 1119.04V1119.03Z" fill="white"/>
                    <path d="M3358.49 879.06C3358.42 878.91 3358.31 878.67 3358.2 878.42C3358.74 878.4 3359.33 878.05 3359.81 878.57C3360.02 878.79 3360.04 879.04 3359.8 879.32C3359.27 879.91 3358.98 879.37 3358.5 879.06H3358.49Z" fill="white"/>
                    <path d="M3545.36 992.46C3545.49 992.22 3545.78 992.21 3546.04 992.37C3546.37 992.58 3546.67 992.91 3546.25 993.29C3545.95 993.56 3545.81 994.32 3545.27 993.89C3545.07 993.73 3544.94 992.99 3545.36 992.46Z" fill="white"/>
                    <path d="M3430.39 1138.89C3429.8 1138.51 3430.07 1137.86 3429.95 1137.32C3430.21 1137.41 3430.51 1137.43 3430.71 1137.59C3431.04 1137.86 3431.63 1138.07 3431.15 1138.71C3430.93 1139 3430.7 1139 3430.39 1138.88V1138.89Z" fill="white"/>
                    <path d="M3517.25 973.42C3517.44 973.05 3517.15 973.38 3517.54 973.42C3517.75 973.44 3518.08 973.72 3518.11 973.92C3518.18 974.45 3518.47 975.07 3517.86 975.5C3517.79 975.55 3517.61 975.57 3517.59 975.53C3517.15 974.88 3517.42 974.12 3517.26 973.43L3517.25 973.42Z" fill="white"/>
                    <path d="M3460.97 972.33C3460.97 971.77 3460.65 971.13 3461.28 970.75C3461.43 970.66 3461.87 970.82 3462.02 971C3462.48 971.56 3461.83 971.82 3461.61 972.19C3461.46 972.44 3461.25 972.57 3460.98 972.33H3460.97Z" fill="white"/>
                    <path d="M3367.65 997.6C3367.5 997.47 3367.3 997.37 3367.21 997.21C3367.15 997.1 3367.19 996.82 3367.27 996.78C3367.56 996.63 3369 997.8 3368.95 998.16C3368.94 998.24 3368.63 998.39 3368.57 998.35C3368.24 998.13 3367.95 997.86 3367.65 997.61V997.6Z" fill="white"/>
                    <path d="M3476.05 874.92C3475.55 874.55 3475.71 874.21 3476.01 873.92C3476.23 873.71 3476.48 873.69 3476.77 873.92C3477.19 874.28 3477.05 874.6 3476.75 874.88C3476.54 875.08 3476.24 875.19 3476.05 874.91V874.92Z" fill="white"/>
                    <path d="M3411.33 1133.98C3411.15 1133.71 3411.1 1133.49 3411.33 1133.2C3411.69 1132.78 3412.01 1132.92 3412.29 1133.22C3412.5 1133.44 3412.52 1133.69 3412.29 1133.98C3411.93 1134.41 3411.64 1134.23 3411.33 1133.98Z" fill="white"/>
                    <path d="M3263.57 968.92C3263.39 968.65 3263.34 968.43 3263.57 968.14C3263.93 967.72 3264.25 967.86 3264.53 968.16C3264.74 968.38 3264.76 968.63 3264.53 968.92C3264.17 969.35 3263.88 969.17 3263.57 968.92Z" fill="white"/>
                    <path d="M3327.48 873.72C3327.73 873.44 3327.98 873.21 3328.41 873.56C3328.7 873.8 3328.72 874.05 3328.55 874.3C3328.32 874.65 3328.04 874.85 3327.61 874.49C3327.32 874.25 3327.33 874.03 3327.48 873.71V873.72Z" fill="white"/>
                    <path d="M3308.97 1103.06C3308.47 1103.3 3307.97 1103.41 3307.47 1103.1C3307.34 1103.02 3307.21 1102.67 3307.22 1102.66C3307.69 1102.35 3308.2 1102.25 3308.69 1102.58C3308.82 1102.67 3308.87 1102.88 3308.97 1103.06Z" fill="white"/>
                    <path d="M3257.73 1008.1C3257.41 1007.93 3257.43 1007.63 3257.6 1007.38C3257.83 1007.03 3258.11 1006.83 3258.54 1007.19C3258.83 1007.43 3258.85 1007.68 3258.68 1007.93C3258.45 1008.28 3258.14 1008.5 3257.72 1008.1H3257.73Z" fill="white"/>
                    <path d="M3364.23 1063.19C3364.05 1062.92 3364 1062.7 3364.23 1062.41C3364.59 1061.99 3364.91 1062.13 3365.19 1062.43C3365.4 1062.65 3365.42 1062.9 3365.19 1063.19C3364.83 1063.62 3364.54 1063.44 3364.23 1063.19Z" fill="white"/>
                    <path d="M3518.72 926.3C3518.51 925.98 3518.46 925.76 3518.7 925.48C3519.06 925.06 3519.38 925.2 3519.66 925.5C3519.87 925.72 3519.89 925.97 3519.66 926.26C3519.3 926.7 3519.01 926.47 3518.73 926.3H3518.72Z" fill="white"/>
                    <path d="M3506.99 947.99C3507.33 947.53 3507.67 947.69 3507.96 947.99C3508.17 948.21 3508.19 948.46 3507.96 948.75C3507.6 949.17 3507.28 949.03 3507 948.73C3506.79 948.51 3506.72 948.22 3507 948L3506.99 947.99Z" fill="white"/>
                    <path d="M3267.07 1103.21C3267.36 1103.47 3267.66 1103.74 3267.26 1104.14C3267.14 1104.26 3266.96 1104.33 3266.8 1104.42C3266.71 1104 3266.61 1103.58 3266.52 1103.16C3266.7 1103.18 3266.88 1103.2 3267.07 1103.22V1103.21Z" fill="white"/>
                    <path d="M3284.5 1051.69C3284.21 1051.43 3283.91 1051.16 3284.31 1050.76C3284.43 1050.64 3284.61 1050.57 3284.77 1050.48C3284.86 1050.9 3284.96 1051.32 3285.05 1051.74C3284.87 1051.72 3284.69 1051.7 3284.5 1051.68V1051.69Z" fill="white"/>
                    <path d="M3410.46 1111.1C3410.41 1110.69 3410.37 1110.31 3410.33 1109.93C3410.58 1110.01 3410.91 1110.03 3411.08 1110.2C3411.46 1110.57 3411.16 1110.89 3410.87 1111.14C3410.79 1111.21 3410.58 1111.12 3410.46 1111.1Z" fill="white"/>
                    <path d="M3341.56 953.88C3341.76 953.76 3342.01 953.56 3342.06 953.61C3342.48 953.93 3342.22 954.44 3342.34 954.85C3342.16 954.83 3341.94 954.86 3341.81 954.77C3341.46 954.55 3341.27 954.24 3341.56 953.88Z" fill="white"/>
                    <path d="M3288.53 874.8C3288.49 874.66 3288.37 874.46 3288.42 874.37C3288.61 874.04 3288.88 873.69 3289.31 874C3289.5 874.14 3289.57 874.46 3289.7 874.69C3289.31 874.72 3288.93 874.76 3288.53 874.8Z" fill="white"/>
                    <path d="M3295.11 904.03C3294.78 904.46 3294.45 904.26 3294.17 903.98C3294.06 903.86 3294.06 903.64 3294 903.47C3294.43 903.49 3294.86 903.51 3295.29 903.52C3295.23 903.69 3295.17 903.86 3295.1 904.03H3295.11Z" fill="white"/>
                    <path d="M3399.97 1088.94C3399.94 1088.86 3399.81 1088.67 3399.86 1088.59C3400.05 1088.26 3400.32 1087.9 3400.75 1088.2C3400.95 1088.34 3401.02 1088.65 3401.15 1088.89C3400.77 1088.91 3400.39 1088.93 3399.97 1088.95V1088.94Z" fill="white"/>
                    <path d="M3371.11 1062.64C3370.68 1062.31 3370.88 1061.98 3371.16 1061.7C3371.28 1061.59 3371.5 1061.59 3371.67 1061.53C3371.65 1061.96 3371.63 1062.39 3371.62 1062.82C3371.45 1062.76 3371.28 1062.7 3371.11 1062.63V1062.64Z" fill="white"/>
                    <path d="M3255.73 934.05C3255.67 933.87 3255.61 933.7 3255.55 933.53C3255.98 933.53 3256.41 933.53 3256.84 933.52C3256.79 933.69 3256.77 933.88 3256.67 934.03C3256.38 934.48 3256.05 934.31 3255.73 934.05Z" fill="white"/>
                    <path d="M3488.83 941.48C3488.5 941.91 3488.17 941.71 3487.89 941.43C3487.78 941.31 3487.78 941.09 3487.72 940.92C3488.15 940.94 3488.58 940.96 3489.01 940.97C3488.95 941.14 3488.89 941.31 3488.82 941.48H3488.83Z" fill="white"/>
                    <path d="M3513.54 908.17C3513.29 908.13 3513.08 908.16 3512.94 908.07C3512.59 907.84 3512.39 907.52 3512.78 907.15C3512.9 907.03 3513.09 906.98 3513.25 906.9C3513.34 907.32 3513.44 907.74 3513.54 908.18V908.17Z" fill="white"/>
                    <path d="M3470.19 1127.16C3470.05 1126.78 3470.36 1126.61 3470.54 1126.42C3470.6 1126.36 3470.92 1126.42 3470.96 1126.5C3471.13 1126.84 3470.8 1127.02 3470.61 1127.22C3470.55 1127.28 3470.32 1127.19 3470.18 1127.17L3470.19 1127.16Z" fill="white"/>
                    <path d="M3444.13 1123.29C3443.82 1123.16 3443.55 1123.09 3443.34 1122.93C3443.22 1122.84 3443.22 1122.59 3443.17 1122.42C3443.43 1122.5 3443.71 1122.54 3443.93 1122.69C3444.05 1122.77 3444.05 1123.02 3444.13 1123.29Z" fill="white"/>
                    <path d="M3418.18 858.73C3418.04 858.35 3418.35 858.18 3418.53 857.99C3418.59 857.93 3418.91 857.99 3418.95 858.07C3419.12 858.41 3418.79 858.59 3418.6 858.79C3418.54 858.85 3418.31 858.76 3418.17 858.74L3418.18 858.73Z" fill="white"/>
                    <path d="M3307.5 878.26C3307.63 877.95 3307.7 877.68 3307.86 877.47C3307.95 877.35 3308.2 877.35 3308.37 877.3C3308.29 877.56 3308.25 877.84 3308.1 878.06C3308.02 878.18 3307.77 878.18 3307.5 878.26Z" fill="white"/>
                    <path d="M3305.74 880.99C3305.85 880.88 3306.01 880.65 3306.23 880.51C3306.48 880.34 3306.65 880.48 3306.53 880.76C3306.42 880.99 3306.23 881.22 3306.02 881.36C3305.77 881.53 3305.64 881.41 3305.74 880.99Z" fill="white"/>
                    <path d="M3410.46 842.19C3410.3 842.27 3410.08 842.47 3409.99 842.42C3409.68 842.24 3409.79 841.98 3410.01 841.79C3410.14 841.68 3410.39 841.51 3410.47 841.55C3410.78 841.73 3410.66 841.97 3410.45 842.18L3410.46 842.19Z" fill="white"/>
                    <path d="M3409.95 1127.33C3409.87 1127.17 3409.67 1126.95 3409.72 1126.86C3409.9 1126.55 3410.16 1126.66 3410.35 1126.88C3410.46 1127.01 3410.63 1127.26 3410.59 1127.34C3410.41 1127.65 3410.17 1127.53 3409.96 1127.32L3409.95 1127.33Z" fill="white"/>
                    <path d="M3260.36 943.89C3260.05 943.76 3259.78 943.69 3259.57 943.53C3259.45 943.44 3259.45 943.19 3259.4 943.02C3259.66 943.1 3259.94 943.14 3260.16 943.29C3260.28 943.37 3260.28 943.62 3260.36 943.89Z" fill="white"/>
                    <path d="M3517.27 1006.06C3517.23 1005.93 3517.18 1005.76 3517.13 1005.58C3517.39 1005.66 3517.67 1005.7 3517.89 1005.85C3518.01 1005.93 3518.01 1006.18 3518.06 1006.36C3517.76 1006.42 3517.5 1006.36 3517.28 1006.06H3517.27Z" fill="white"/>
                    <path d="M3232.57 980.81C3232.74 980.57 3232.96 980.41 3233.19 980.68C3233.25 980.75 3233.13 981.02 3233.04 981.18C3232.89 981.43 3232.66 981.59 3232.43 981.31C3232.37 981.24 3232.52 980.98 3232.57 980.81Z" fill="white"/>
                    <path d="M3486.64 978.96C3486.81 978.72 3487.03 978.56 3487.26 978.83C3487.32 978.9 3487.2 979.17 3487.11 979.33C3486.96 979.58 3486.73 979.74 3486.5 979.46C3486.44 979.39 3486.59 979.13 3486.64 978.96Z" fill="white"/>
                    <path d="M3473.88 980.11C3474.05 979.87 3474.27 979.71 3474.5 979.98C3474.56 980.05 3474.44 980.32 3474.35 980.48C3474.2 980.73 3473.97 980.89 3473.74 980.61C3473.68 980.54 3473.83 980.28 3473.88 980.11Z" fill="white"/>
                    <path d="M3427.94 990.98C3427.63 990.85 3427.36 990.78 3427.15 990.62C3427.03 990.53 3427.03 990.28 3426.98 990.11C3427.24 990.19 3427.52 990.23 3427.74 990.38C3427.86 990.46 3427.86 990.71 3427.94 990.98Z" fill="white"/>
                    <path d="M3404.37 1098.34C3404.28 1098.13 3404.18 1097.88 3404.07 1097.64C3404.25 1097.66 3404.5 1097.61 3404.6 1097.72C3404.78 1097.91 3404.87 1098.18 3405 1098.42C3404.82 1098.4 3404.64 1098.38 3404.37 1098.35V1098.34Z" fill="white"/>
                    <path d="M3349.55 1019.43C3349.51 1019.3 3349.46 1019.13 3349.41 1018.95C3349.67 1019.03 3349.95 1019.07 3350.17 1019.22C3350.29 1019.3 3350.29 1019.55 3350.34 1019.73C3350.04 1019.79 3349.78 1019.73 3349.56 1019.43H3349.55Z" fill="white"/>
                    <path d="M3314.07 1050.1C3313.76 1049.97 3313.49 1049.9 3313.28 1049.74C3313.16 1049.65 3313.16 1049.4 3313.11 1049.23C3313.37 1049.31 3313.65 1049.35 3313.87 1049.5C3313.99 1049.58 3313.99 1049.83 3314.07 1050.1Z" fill="white"/>
                    <path d="M3258.83 1098.58C3258.69 1098.2 3259 1098.03 3259.18 1097.84C3259.24 1097.78 3259.56 1097.84 3259.6 1097.92C3259.77 1098.26 3259.44 1098.44 3259.25 1098.64C3259.19 1098.7 3258.96 1098.61 3258.82 1098.59L3258.83 1098.58Z" fill="white"/>
                    <path d="M3274.27 1034.51C3274.44 1034.27 3274.66 1034.11 3274.89 1034.38C3274.95 1034.45 3274.83 1034.72 3274.74 1034.88C3274.59 1035.13 3274.36 1035.29 3274.13 1035.01C3274.07 1034.94 3274.22 1034.68 3274.27 1034.51Z" fill="white"/>
                    <path d="M3417.07 877.61C3416.91 877.69 3416.69 877.89 3416.6 877.84C3416.29 877.66 3416.4 877.4 3416.62 877.21C3416.75 877.1 3417 876.93 3417.08 876.97C3417.39 877.15 3417.27 877.39 3417.06 877.6L3417.07 877.61Z" fill="white"/>
                    <path d="M3366.81 962.29C3366.73 962.13 3366.53 961.91 3366.58 961.82C3366.76 961.51 3367.02 961.62 3367.21 961.84C3367.32 961.97 3367.49 962.22 3367.45 962.3C3367.27 962.61 3367.03 962.49 3366.82 962.28L3366.81 962.29Z" fill="white"/>
                    <path d="M3354.19 952.92C3354.04 952.89 3353.89 952.87 3353.76 952.81C3353.71 952.79 3353.74 952.63 3353.73 952.54C3353.88 952.57 3354.03 952.59 3354.16 952.65C3354.21 952.67 3354.18 952.83 3354.19 952.92Z" fill="white"/>
                    <path d="M3245.81 930.09C3245.66 930.06 3245.51 930.04 3245.38 929.98C3245.33 929.96 3245.36 929.8 3245.35 929.71C3245.5 929.74 3245.65 929.76 3245.78 929.82C3245.83 929.84 3245.8 930 3245.81 930.09Z" fill="white"/>
                    <path d="M3463.72 987.23C3463.67 987.1 3463.61 986.96 3463.56 986.82C3463.65 986.82 3463.8 986.76 3463.83 986.8C3463.92 986.92 3463.96 987.07 3464.02 987.2C3463.93 987.2 3463.83 987.22 3463.72 987.23Z" fill="white"/>
                    <path d="M3503.3 961.5C3503.31 961.61 3503.32 961.71 3503.33 961.8C3503.18 961.77 3503.03 961.75 3502.9 961.69C3502.85 961.67 3502.88 961.51 3502.87 961.42C3503.02 961.45 3503.16 961.48 3503.31 961.5H3503.3Z" fill="white"/>
                    <path d="M3448.14 1029.91C3448.27 1029.86 3448.41 1029.8 3448.55 1029.75C3448.55 1029.84 3448.61 1029.99 3448.57 1030.02C3448.45 1030.11 3448.3 1030.15 3448.17 1030.21C3448.17 1030.12 3448.15 1030.02 3448.14 1029.91Z" fill="white"/>
                    <path d="M3401.24 1032.72C3401.27 1032.57 3401.29 1032.42 3401.35 1032.29C3401.37 1032.24 3401.53 1032.27 3401.62 1032.26C3401.59 1032.41 3401.57 1032.56 3401.51 1032.69C3401.49 1032.74 3401.33 1032.71 3401.24 1032.72Z" fill="white"/>
                    <path d="M3365.88 1067.2C3365.99 1067.19 3366.09 1067.18 3366.18 1067.17C3366.15 1067.32 3366.13 1067.47 3366.07 1067.6C3366.05 1067.65 3365.89 1067.62 3365.8 1067.63C3365.83 1067.48 3365.86 1067.34 3365.88 1067.19V1067.2Z" fill="white"/>
                    <path d="M3397.57 1119.23C3397.52 1119.1 3397.46 1118.96 3397.41 1118.82C3397.5 1118.82 3397.65 1118.76 3397.68 1118.8C3397.77 1118.92 3397.81 1119.07 3397.87 1119.2C3397.78 1119.2 3397.68 1119.22 3397.57 1119.23Z" fill="white"/>
                    <path d="M3462.32 1015.96C3462.31 1015.85 3462.3 1015.75 3462.29 1015.66C3462.44 1015.69 3462.59 1015.71 3462.72 1015.77C3462.77 1015.79 3462.74 1015.95 3462.75 1016.04C3462.6 1016.01 3462.46 1015.98 3462.31 1015.96H3462.32Z" fill="white"/>
                    <path d="M3387.51 1004.07C3387.52 1004.18 3387.53 1004.28 3387.54 1004.37C3387.39 1004.34 3387.24 1004.32 3387.11 1004.26C3387.06 1004.24 3387.09 1004.08 3387.08 1003.99C3387.23 1004.02 3387.37 1004.05 3387.52 1004.07H3387.51Z" fill="white"/>
                    <path d="M3284.92 1108.35C3284.82 1108.35 3284.66 1108.39 3284.65 1108.37C3284.6 1108.23 3284.52 1108.07 3284.55 1107.93C3284.62 1107.66 3284.79 1107.64 3284.91 1107.9C3284.97 1108.02 3284.91 1108.2 3284.91 1108.35H3284.92Z" fill="white"/>
                    <path d="M3284.52 1107.56C3284.52 1107.41 3284.47 1107.17 3284.55 1107.12C3284.76 1106.99 3284.92 1107.07 3284.9 1107.26C3284.88 1107.42 3284.79 1107.57 3284.74 1107.73C3284.67 1107.67 3284.6 1107.61 3284.53 1107.56H3284.52Z" fill="white"/>
                    <path d="M3360.17 922.8C3360.12 922.67 3360.06 922.53 3360.01 922.39C3360.1 922.39 3360.25 922.33 3360.28 922.37C3360.37 922.49 3360.41 922.64 3360.47 922.77C3360.38 922.77 3360.28 922.79 3360.17 922.8Z" fill="white"/>
                    <path d="M3352.29 1164.03C3352.24 1163.9 3352.18 1163.76 3352.13 1163.62C3352.22 1163.62 3352.37 1163.56 3352.4 1163.6C3352.49 1163.72 3352.53 1163.87 3352.59 1164C3352.5 1164 3352.4 1164.02 3352.29 1164.03Z" fill="white"/>
                    <path d="M3276.98 1109.8C3276.97 1109.69 3276.96 1109.59 3276.95 1109.5C3277.1 1109.53 3277.25 1109.55 3277.38 1109.61C3277.43 1109.63 3277.4 1109.79 3277.41 1109.88C3277.26 1109.85 3277.12 1109.82 3276.97 1109.8H3276.98Z" fill="white"/>
                    <path d="M3387.33 1136.87C3387.38 1137 3387.44 1137.14 3387.49 1137.28C3387.4 1137.28 3387.25 1137.34 3387.22 1137.3C3387.13 1137.18 3387.09 1137.03 3387.03 1136.9C3387.12 1136.9 3387.22 1136.88 3387.33 1136.87Z" fill="white"/>
                    <path d="M3311.38 1009.51C3311.33 1009.38 3311.27 1009.24 3311.22 1009.1C3311.31 1009.1 3311.46 1009.04 3311.49 1009.08C3311.58 1009.2 3311.62 1009.35 3311.68 1009.48C3311.59 1009.48 3311.49 1009.5 3311.38 1009.51Z" fill="white"/>
                    <path d="M3441.65 1061.24C3441.76 1061.23 3441.86 1061.22 3441.95 1061.21C3441.92 1061.36 3441.9 1061.51 3441.84 1061.64C3441.82 1061.69 3441.66 1061.66 3441.57 1061.67C3441.6 1061.52 3441.63 1061.38 3441.65 1061.23V1061.24Z" fill="white"/>
                    <path d="M3288.12 1076.34C3287.97 1076.31 3287.82 1076.29 3287.69 1076.23C3287.64 1076.21 3287.67 1076.05 3287.66 1075.96C3287.81 1075.99 3287.96 1076.01 3288.09 1076.07C3288.14 1076.09 3288.11 1076.25 3288.12 1076.34Z" fill="white"/>
                    <path d="M3435.42 1072.95C3435.37 1072.82 3435.31 1072.68 3435.26 1072.54C3435.35 1072.54 3435.5 1072.48 3435.53 1072.52C3435.62 1072.64 3435.66 1072.79 3435.72 1072.92L3435.42 1072.95Z" fill="white"/>
                    <path d="M3370.62 973.62C3370.49 973.67 3370.35 973.73 3370.21 973.78C3370.21 973.69 3370.15 973.54 3370.19 973.51C3370.31 973.42 3370.46 973.38 3370.59 973.32C3370.59 973.41 3370.61 973.51 3370.62 973.62Z" fill="white"/>
                    <path d="M3403.32 836.61C3403.17 836.58 3403.02 836.56 3402.89 836.5C3402.84 836.48 3402.87 836.32 3402.86 836.23C3403.01 836.26 3403.16 836.28 3403.29 836.34C3403.34 836.36 3403.31 836.52 3403.32 836.61Z" fill="white"/>
                    <path d="M3100.7 983.12C3100.59 981.15 3101.95 980.42 3103.24 979.76C3103.71 979.52 3104.5 979.25 3104.7 979.04C3105.96 977.67 3106.79 979.72 3107.85 979.3C3108.09 980.39 3108.75 981.62 3108.46 982.54C3108.18 983.42 3107.74 984.48 3107.03 985.22C3106.36 985.92 3105.73 987.21 3104.46 985.83C3104.27 985.63 3103.69 985.81 3103.29 985.77C3101.52 985.6 3100.67 984.67 3100.71 983.11L3100.7 983.12Z" fill="white"/>
                    <path d="M3220.21 1088.84C3219.04 1088.8 3218.24 1087.85 3218.26 1087.43C3218.34 1085.85 3216.91 1085.17 3216.48 1083.9C3216.18 1083.01 3216.14 1082.42 3216.84 1081.83C3217.99 1080.86 3218.68 1080.76 3219.54 1081.44C3219.91 1081.73 3220.3 1081.97 3220.72 1082.21C3222.5 1083.23 3223.13 1087.35 3221.07 1088.51C3220.7 1088.72 3220.41 1088.9 3220.21 1088.84Z" fill="white"/>
                    <path d="M3206.42 994.6C3205.74 993.83 3204.25 994.02 3204.18 992.59C3204.17 992.39 3204.25 992.16 3204.18 991.99C3203.86 991.31 3203.27 990.69 3203.74 989.86C3204.11 989.21 3204.84 989.12 3205.44 988.84C3206.62 988.29 3208.34 988.93 3209.09 990.16C3209.67 991.11 3209.61 992 3209.04 992.99C3208.45 994.01 3207.34 994.11 3206.43 994.61L3206.42 994.6Z" fill="white"/>
                    <path d="M3333.66 932.29C3335.41 932.34 3336.19 933 3336.31 934.26C3336.38 934.96 3336.27 935.67 3336.29 936.37C3336.31 937.04 3336.02 937.23 3335.4 937.18C3334.7 937.12 3333.97 937.24 3333.29 937.1C3332.14 936.87 3331.39 936.05 3330.93 935C3330.84 934.78 3330.89 934.36 3331.04 934.21C3331.62 933.67 3331.96 932.99 3332.41 932.38C3332.63 932.08 3333.53 932.28 3333.66 932.28V932.29Z" fill="white"/>
                    <path d="M3349.92 1063.58C3349.13 1063.63 3348 1063.72 3347.69 1062.48C3347.55 1061.89 3347.28 1060.79 3348 1060.59C3348.79 1060.37 3349.49 1059.46 3350.44 1059.99C3351.1 1060.35 3351.66 1060.97 3352.36 1061.16C3353 1061.33 3353.13 1061.54 3353.12 1062.13C3353.12 1062.98 3352.61 1063.43 3351.93 1063.59C3351.36 1063.73 3350.73 1063.59 3349.91 1063.58H3349.92Z" fill="white"/>
                    <path d="M3335.91 910.51C3335.11 910.51 3334.44 909.87 3334.41 909.09C3334.41 908.83 3335.87 906.37 3336.09 906.27C3336.59 906.06 3338.12 907.09 3338.04 907.15C3336.97 907.83 3338.38 908.47 3338.01 909.08C3337.66 909.66 3336.42 910.51 3335.91 910.51Z" fill="white"/>
                    <path d="M3160.23 947.64C3160.21 948.06 3160.31 948.7 3159.9 948.7C3159.16 948.7 3158.34 949.02 3157.65 948.31C3157.27 947.92 3157.35 947.37 3157.04 947.05C3156.54 946.52 3156.46 946.14 3157 945.56C3157.59 944.93 3158.32 944.85 3158.84 945.26C3159.57 945.84 3160.52 946.45 3160.22 947.65L3160.23 947.64Z" fill="white"/>
                    <path d="M3228.79 1027.15C3229.42 1027.2 3230.25 1026.9 3230.18 1028.1C3230.16 1028.37 3230.71 1028.66 3230.96 1028.97C3231.25 1029.32 3231.52 1029.69 3231.8 1030.05C3231.13 1030.1 3230.33 1030.4 3229.81 1030.15C3229.13 1029.82 3228.69 1029.04 3228.11 1028.49C3227.79 1028.19 3227.55 1027.85 3227.73 1027.46C3227.93 1027.03 3228.4 1027.17 3228.79 1027.17V1027.15Z" fill="white"/>
                    <path d="M3221.92 865.74C3222.12 865.74 3222.32 865.73 3222.51 865.76C3222.94 865.83 3223.58 865.52 3223.72 866.21C3223.87 866.92 3223.64 867.52 3223.02 867.99C3222.49 868.39 3222.18 868.15 3221.78 867.79C3221.52 867.56 3221.07 867.55 3220.8 867.33C3220.45 867.03 3219.95 866.74 3220.11 866.15C3220.3 865.45 3220.9 865.8 3221.33 865.74C3221.52 865.72 3221.72 865.74 3221.92 865.74Z" fill="white"/>
                    <path d="M3344.55 917.31C3345.02 916.51 3345.27 915.54 3346.11 915C3346.3 914.88 3346.68 914.87 3346.91 915.17C3347.2 915.55 3347.82 915.83 3347.57 916.38C3347.27 917.06 3346.82 917.67 3346.36 918.27C3345.82 918.97 3345.69 917.93 3345.26 917.97C3344.82 918.02 3344.49 917.87 3344.53 917.31H3344.55Z" fill="white"/>
                    <path d="M3179.55 1029.18C3180.1 1029.1 3180.43 1029.29 3180.38 1029.96C3180.31 1031 3179.74 1031.57 3178.82 1031.6C3177.99 1031.62 3177.55 1031.1 3177.37 1030.46C3177.16 1029.73 3178.23 1029.12 3179.56 1029.18H3179.55Z" fill="white"/>
                    <path d="M3127.3 1103.29C3127.6 1103.29 3127.91 1103.25 3128.2 1103.32C3128.87 1103.48 3129.07 1104.3 3128.58 1104.78C3128.29 1105.06 3128.04 1105.4 3127.7 1105.6C3127.27 1105.85 3126.66 1105.87 3126.32 1105.58C3125.92 1105.24 3126.06 1104.49 3125.54 1104.14C3126 1103.81 3126.25 1103.12 3127 1103.29C3127.1 1103.31 3127.2 1103.29 3127.3 1103.29Z" fill="white"/>
                    <path d="M3288.94 924.1C3288.69 923.84 3288.5 923.62 3288.28 923.42C3287.72 922.91 3288.44 922.05 3287.71 921.56C3287.33 921.3 3287.34 920.85 3287.71 920.57C3288.05 920.3 3288.42 920.43 3288.72 920.76C3289.12 921.2 3289.57 921.6 3289.94 922.06C3290.12 922.28 3290.73 922.43 3290.2 922.9C3289.77 923.29 3289.36 923.7 3288.95 924.1H3288.94Z" fill="white"/>
                    <path d="M3388.34 999.62C3388.23 1000.25 3387.23 1000.83 3386.51 1000.67C3386.23 1000.61 3385.24 999.03 3385.34 998.69C3385.6 997.83 3386.33 998.27 3386.82 998.28C3387.74 998.31 3388.45 999.02 3388.34 999.62Z" fill="white"/>
                    <path d="M3316.53 1043.23C3316.45 1042.61 3316.59 1042.24 3317.28 1042.29C3318.12 1042.34 3318.95 1042.95 3318.93 1043.55C3318.92 1044.15 3318.05 1044.74 3317.21 1044.72C3315.95 1044.68 3316.69 1043.73 3316.52 1043.23H3316.53Z" fill="white"/>
                    <path d="M3312.6 1049.47C3312.13 1049.17 3311.61 1048.87 3311.58 1048.24C3311.55 1047.55 3312.75 1046.88 3313.55 1047.07C3314.08 1047.2 3314.03 1047.59 3313.99 1047.97C3313.89 1048.86 3313.23 1049.57 3312.6 1049.47Z" fill="white"/>
                    <path d="M3304.57 957.16C3304.96 957.16 3305.16 957.19 3305.36 957.17C3306.09 957.09 3306.3 957.77 3306.74 958.11C3306.28 958.43 3306.05 959.1 3305.32 958.99C3305.13 958.96 3304.93 958.96 3304.73 958.98C3304.06 959.02 3303.64 958.93 3303.68 958.05C3303.71 957.21 3304.05 957.02 3304.57 957.16Z" fill="white"/>
                    <path d="M3282.62 1129.61C3282.59 1128.9 3283.06 1128.77 3283.4 1128.93C3284.08 1129.25 3284.7 1129.7 3285.31 1130.14C3285.88 1130.56 3285.27 1130.74 3285.08 1130.98C3284.26 1132.04 3283.77 1130.71 3283.07 1130.68C3282.27 1130.65 3282.75 1129.9 3282.62 1129.61Z" fill="white"/>
                    <path d="M3315.55 991.94C3314.72 992.08 3314.26 991.55 3314.05 990.92C3313.89 990.44 3315.13 989.32 3315.49 989.53C3316.18 989.93 3316.47 990.56 3316.38 991.38C3316.32 991.96 3315.95 991.94 3315.55 991.94Z" fill="white"/>
                    <path d="M3159.5 894.14C3159.62 893.56 3160.09 893.24 3160.6 893C3160.91 892.86 3161.87 894.22 3161.91 894.84C3161.95 895.4 3161.56 895.45 3161.15 895.43C3160.32 895.39 3159.5 894.77 3159.5 894.15V894.14Z" fill="white"/>
                    <path d="M3339.49 956.83C3339.39 957.12 3339.17 957.41 3338.86 957.24C3338.12 956.83 3337.27 956.6 3336.68 955.92C3336.43 955.63 3336.41 955.3 3336.53 955.1C3336.64 954.91 3337.1 954.73 3337.27 954.82C3337.94 955.16 3338.56 955.61 3339.18 956.03C3339.45 956.21 3339.54 956.49 3339.48 956.82L3339.49 956.83Z" fill="white"/>
                    <path d="M3369.69 943.94C3369.37 943.96 3368.97 944 3368.87 943.47C3368.79 943.01 3369.16 942.81 3369.39 942.51C3369.84 941.9 3370.47 942.1 3371 942.19C3371.31 942.24 3371.82 942.78 3371.77 942.97C3371.66 943.35 3371.29 943.75 3370.92 943.94C3370.61 944.1 3370.15 943.96 3369.69 943.94Z" fill="white"/>
                    <path d="M3331.02 1104.85C3331.09 1105.34 3330.89 1105.67 3330.23 1105.62C3329.39 1105.55 3328.6 1104.96 3328.6 1104.36C3328.6 1103.35 3329.84 1104.07 3330.1 1103.3C3330.14 1103.17 3330.98 1104.08 3331.03 1104.86L3331.02 1104.85Z" fill="white"/>
                    <path d="M3251.24 935.46C3251.1 935.35 3250.86 935.25 3250.83 935.11C3250.66 934.34 3251.78 933 3252.53 933.07C3252.79 933.1 3253.16 933.21 3253.24 933.39C3253.33 933.58 3253.21 933.96 3253.05 934.16C3252.37 935.04 3251.74 935.46 3251.24 935.45V935.46Z" fill="white"/>
                    <path d="M3146.3 976.83C3146.3 977.13 3146.34 977.44 3146.27 977.72C3146.1 978.45 3145.5 978.1 3145.06 978.15C3144.5 978.21 3144.45 977.81 3144.46 977.39C3144.47 977.01 3144.41 976.63 3144.77 976.32C3145.14 976.01 3145.46 975.52 3146.01 975.77C3146.44 975.96 3146.28 976.45 3146.29 976.82L3146.3 976.83Z" fill="white"/>
                    <path d="M3336.52 958.41C3337.13 958.55 3337.07 958.96 3337.02 959.34C3336.91 960.24 3336.26 960.94 3335.62 960.82C3335.09 960.72 3335.15 960.29 3335.2 959.91C3335.31 959.01 3335.96 958.34 3336.52 958.41Z" fill="white"/>
                    <path d="M3339.51 1105.49C3338.99 1105.96 3338.61 1106.52 3337.77 1106.41C3337.08 1106.32 3337.06 1105.97 3337.06 1105.44C3337.06 1104.86 3337.18 1104.6 3337.85 1104.53C3338.72 1104.44 3339 1105.09 3339.52 1105.49H3339.51Z" fill="white"/>
                    <path d="M3324.72 1109.14C3324.57 1109.04 3324.3 1108.95 3324.28 1108.82C3324.23 1108.43 3324.13 1107.94 3324.32 1107.67C3324.62 1107.23 3324.96 1106.64 3325.66 1106.74C3326.2 1106.81 3326.15 1107.25 3326.1 1107.63C3325.98 1108.54 3325.37 1109.19 3324.72 1109.14Z" fill="white"/>
                    <path d="M3135.15 937.33C3134.74 937.31 3134.3 937.28 3134.4 936.73C3134.54 936.01 3135.01 935.46 3135.75 935.57C3136.14 935.63 3136.47 936.15 3136.82 936.46C3136.33 936.89 3135.97 937.56 3135.15 937.34V937.33Z" fill="white"/>
                    <path d="M3176.07 1091.73C3176.13 1091.01 3176.98 1090.28 3177.48 1090.37C3178 1090.47 3177.9 1090.89 3177.92 1091.27C3177.95 1092.03 3177.52 1092.53 3176.87 1092.62C3176.67 1092.65 3176.07 1092.12 3176.07 1091.73Z" fill="white"/>
                    <path d="M3324.12 1012.13C3323.62 1012.12 3323.09 1012.08 3323.25 1011.57C3323.46 1010.94 3323.9 1010.38 3324.24 1009.79C3324.75 1010.25 3325.18 1010.71 3325.06 1011.53C3324.95 1012.3 3324.45 1012.1 3324.11 1012.14L3324.12 1012.13Z" fill="white"/>
                    <path d="M3237.91 1136.45C3237.08 1136.52 3237.04 1136.13 3237 1135.55C3236.95 1134.66 3237.42 1134.63 3238.09 1134.64C3238.78 1134.65 3238.84 1134.99 3238.86 1135.57C3238.89 1136.43 3238.5 1136.62 3237.9 1136.45H3237.91Z" fill="white"/>
                    <path d="M3331.3 1001.41C3331.41 1000.89 3331.02 1000.02 3332.03 1000.19C3332.58 1000.28 3333.06 1000.74 3333.11 1001.37C3333.2 1002.41 3332.09 1002.1 3331.64 1002.36C3331.07 1002.69 3331.5 1001.72 3331.3 1001.41Z" fill="white"/>
                    <path d="M3382.63 928.44C3382.34 928.44 3382.03 928.47 3381.75 928.41C3381.23 928.3 3381.3 927.87 3381.33 927.49C3381.36 927.21 3381.21 926.87 3381.55 926.7C3381.93 926.5 3382.27 926.57 3382.56 926.92C3382.68 927.07 3382.83 927.2 3382.96 927.35C3383.21 927.63 3383.71 927.98 3383.65 928.18C3383.53 928.62 3383 928.41 3382.63 928.44Z" fill="white"/>
                    <path d="M3130.73 1056.24C3130.5 1056.92 3129.93 1056.63 3129.5 1056.64C3128.69 1056.65 3128.95 1056.02 3128.93 1055.58C3128.91 1055.18 3128.96 1054.77 3129.54 1054.83C3130.13 1054.9 3130.81 1055.72 3130.73 1056.24Z" fill="white"/>
                    <path d="M3289.98 1065.66C3290.01 1065.14 3290.03 1064.72 3290.61 1064.79C3291.2 1064.86 3291.91 1065.69 3291.79 1066.19C3291.68 1066.71 3291.24 1066.57 3290.87 1066.62C3289.95 1066.73 3289.93 1066.18 3289.98 1065.66Z" fill="white"/>
                    <path d="M3358.05 970.73C3357.99 970.93 3357.98 971.35 3357.82 971.42C3357.38 971.62 3357.19 971.1 3357.05 970.86C3356.85 970.5 3355.75 970.37 3356.63 969.63C3356.92 969.38 3357.17 969.03 3357.6 969.16C3358.49 969.43 3357.83 970.16 3358.05 970.73Z" fill="white"/>
                    <path d="M3226.83 886.45C3226.45 886.17 3225.63 885.88 3226.01 885.4C3226.33 884.99 3226.07 884.19 3226.78 884.14C3227.21 884.11 3227.78 885.59 3227.55 886.13C3227.43 886.41 3227.16 886.53 3226.82 886.46L3226.83 886.45Z" fill="white"/>
                    <path d="M3236.17 1117.72C3236.17 1117.72 3235.71 1118.01 3235.26 1118.19C3235.06 1118.27 3234.31 1117.12 3234.37 1116.84C3234.55 1116.11 3235.13 1116.45 3235.56 1116.41C3236.08 1116.36 3236.2 1116.69 3236.16 1117.72H3236.17Z" fill="white"/>
                    <path d="M3200.96 1112.07C3200.27 1111.85 3200.59 1111.29 3200.55 1110.86C3200.5 1110.32 3200.92 1110.13 3201.28 1110.29C3201.69 1110.47 3202.04 1110.85 3202.34 1111.21C3202.45 1111.34 3201.22 1112.13 3200.96 1112.08V1112.07Z" fill="white"/>
                    <path d="M3093.88 962.09C3093.79 961.56 3094.03 961.36 3094.32 961.37C3095.09 961.39 3095.49 961.89 3095.65 962.61C3095.73 962.98 3095.48 963.18 3095.18 963.18C3094.38 963.18 3094.04 962.63 3093.88 962.09Z" fill="white"/>
                    <path d="M3263.95 916.25C3263.84 916.88 3263.42 917.35 3262.91 917.34C3262.66 917.34 3262.09 916.86 3262.12 916.26C3262.16 915.46 3262.75 915.72 3263.18 915.69C3263.59 915.67 3263.96 915.73 3263.95 916.24V916.25Z" fill="white"/>
                    <path d="M3111.25 1060.06C3111.22 1059.78 3112.06 1058.63 3112.18 1058.75C3112.52 1059.07 3112.88 1059.43 3113.04 1059.85C3113.19 1060.24 3112.93 1060.6 3112.42 1060.53C3112 1060.47 3111.4 1060.78 3111.25 1060.05V1060.06Z" fill="white"/>
                    <path d="M3269.89 914.59C3269.36 914.41 3268.82 914.04 3268.86 913.24C3268.87 912.95 3269.09 912.7 3269.45 912.8C3270.16 912.99 3270.64 913.41 3270.63 914.18C3270.63 914.47 3270.41 914.71 3269.89 914.59Z" fill="white"/>
                    <path d="M3285.96 848.87C3285.41 848.89 3285.21 848.5 3285.37 848.14C3285.55 847.73 3285.93 847.38 3286.29 847.08C3286.42 846.97 3287.21 848.2 3287.16 848.46C3287.08 848.83 3286.61 848.99 3285.97 848.88L3285.96 848.87Z" fill="white"/>
                    <path d="M3326.62 1003.08C3326.2 1003.06 3325.78 1003.05 3325.84 1002.49C3325.9 1001.88 3326.35 1001.42 3326.86 1001.43C3327.12 1001.43 3327.68 1001.91 3327.65 1002.51C3327.62 1003.31 3327.02 1003.03 3326.62 1003.09V1003.08Z" fill="white"/>
                    <path d="M3354.17 911.42C3353.67 911.15 3353.24 910.84 3353.19 910.23C3353.16 909.87 3353.39 909.64 3353.68 909.68C3354.16 909.75 3354.94 909.3 3355.01 910.28C3355.05 910.88 3354.42 911 3354.18 911.41L3354.17 911.42Z" fill="white"/>
                    <path d="M3316.78 993.18C3316.64 993.07 3316.49 992.96 3316.35 992.85C3316.96 992.23 3317.7 991.71 3317.92 990.78C3317.92 990.74 3318.32 990.73 3318.49 990.81C3318.59 990.85 3318.69 991.18 3318.66 991.21C3318.03 991.7 3318.14 992.72 3317.35 993.08C3317.18 993.16 3316.98 993.15 3316.79 993.18H3316.78Z" fill="white"/>
                    <path d="M3315.83 988.56C3315.88 987.97 3316.64 988.31 3317.1 988.36C3318.01 988.47 3317.51 989.25 3317.58 989.73C3317.6 989.87 3317.27 990.41 3317.12 989.91C3316.9 989.17 3315.65 990.01 3315.83 988.55V988.56Z" fill="white"/>
                    <path d="M3128.34 995.21C3128.44 995.94 3127.75 996.12 3127.43 996.56C3127.16 996.3 3126.66 996.02 3126.67 995.77C3126.7 995.22 3127.15 994.87 3127.73 994.77C3128.08 994.71 3128.23 994.93 3128.34 995.21Z" fill="white"/>
                    <path d="M3241.12 1112.71C3241.19 1112.29 3240.95 1111.68 3241.76 1111.68C3242.34 1111.68 3242.37 1112.06 3242.34 1112.46C3242.31 1112.9 3242.52 1113.5 3241.71 1113.51C3241.12 1113.51 3241.13 1113.13 3241.12 1112.71Z" fill="white"/>
                    <path d="M3131.49 914.74C3130.67 914.18 3130.5 913.56 3130.64 912.85C3130.67 912.68 3130.84 912.55 3130.94 912.39C3131.08 912.53 3131.19 912.7 3131.35 912.81C3131.96 913.21 3131.85 913.81 3131.79 914.37C3131.77 914.54 3131.53 914.69 3131.49 914.74Z" fill="white"/>
                    <path d="M3235.25 1143.11C3235.1 1143.11 3235.03 1142.74 3235.07 1142.32C3235.11 1141.89 3234.87 1141.27 3235.69 1141.26C3236.26 1141.26 3236.31 1141.62 3236.28 1142.03C3236.24 1142.47 3236.45 1143.08 3235.25 1143.1V1143.11Z" fill="white"/>
                    <path d="M3266.6 1087.11C3266.67 1087 3266.75 1086.76 3266.84 1086.76C3267.52 1086.71 3268.18 1086.68 3268.64 1087.37C3268.83 1087.66 3268.9 1087.84 3268.59 1087.98C3268.1 1088.19 3266.72 1087.58 3266.6 1087.11Z" fill="white"/>
                    <path d="M3162.6 1032.46C3161.67 1032.2 3162.29 1031.47 3162.21 1030.96C3162.17 1030.73 3162.63 1030.57 3162.92 1030.66C3163.79 1030.93 3163.29 1031.65 3163.33 1032.16C3163.34 1032.4 3162.94 1032.54 3162.61 1032.46H3162.6Z" fill="white"/>
                    <path d="M3279.27 1019.08C3278.34 1018.82 3278.96 1018.09 3278.88 1017.58C3278.84 1017.35 3279.3 1017.19 3279.59 1017.28C3280.46 1017.55 3279.96 1018.27 3280 1018.78C3280.01 1019.02 3279.61 1019.16 3279.28 1019.08H3279.27Z" fill="white"/>
                    <path d="M3207.39 904.28C3207.24 904.28 3207.17 903.91 3207.21 903.49C3207.25 903.06 3207.01 902.44 3207.83 902.43C3208.4 902.43 3208.45 902.79 3208.42 903.2C3208.38 903.64 3208.59 904.25 3207.39 904.27V904.28Z" fill="white"/>
                    <path d="M3357.87 1032.63C3358.43 1032.34 3359.01 1032.04 3359.59 1031.75C3359.62 1031.74 3359.76 1031.87 3359.78 1031.95C3359.86 1032.36 3359.7 1032.67 3359.38 1032.96C3359.01 1033.28 3358.68 1033.66 3358.16 1033.33C3358.04 1033.25 3358.02 1033.01 3357.87 1032.63Z" fill="white"/>
                    <path d="M3339.62 980.01C3339.64 980.41 3339.69 980.84 3339.14 980.9C3338.43 980.97 3338.37 980.53 3338.41 979.97C3338.44 979.58 3338.35 979.14 3338.91 979.09C3339.61 979.02 3339.72 979.46 3339.63 980.01H3339.62Z" fill="white"/>
                    <path d="M3306.83 928.18C3306.3 928 3306.05 927.86 3306.15 927.5C3306.34 926.79 3306.76 926.32 3307.53 926.32C3307.68 926.32 3307.83 926.51 3307.98 926.62C3307.58 927.16 3307.18 927.71 3306.83 928.19V928.18Z" fill="white"/>
                    <path d="M3293.13 1047.83C3292.59 1047.89 3292.18 1047.77 3292.21 1047.52C3292.26 1047.01 3291.8 1046.28 3292.68 1046.04C3292.98 1045.96 3293.38 1046.1 3293.38 1046.37C3293.38 1046.88 3293.66 1047.55 3293.13 1047.82V1047.83Z" fill="white"/>
                    <path d="M3189.01 912.37C3188.6 912.34 3188.21 912.33 3188.24 911.75C3188.28 910.94 3188.87 911.18 3189.31 911.17C3189.72 911.16 3190.1 911.2 3190.07 911.78C3190.03 912.59 3189.44 912.32 3189.01 912.38V912.37Z" fill="white"/>
                    <path d="M3186.96 1004.01C3186.7 1004.94 3185.97 1004.32 3185.46 1004.4C3185.23 1004.44 3185.07 1003.98 3185.16 1003.69C3185.43 1002.82 3186.15 1003.32 3186.66 1003.28C3186.9 1003.27 3187.04 1003.67 3186.96 1004V1004.01Z" fill="white"/>
                    <path d="M3317.69 926.21C3317.71 926.61 3317.76 927.04 3317.21 927.1C3316.5 927.17 3316.44 926.73 3316.48 926.17C3316.51 925.78 3316.42 925.34 3316.98 925.29C3317.68 925.22 3317.79 925.66 3317.7 926.21H3317.69Z" fill="white"/>
                    <path d="M3328.77 1094.38C3328.82 1095.07 3328.61 1095.24 3328.29 1095.26C3327.58 1095.32 3327.53 1094.85 3327.59 1094.32C3327.62 1094.05 3327.57 1093.49 3327.79 1093.58C3328.24 1093.76 3328.59 1094.22 3328.76 1094.38H3328.77Z" fill="white"/>
                    <path d="M3153.44 926.21C3153.29 926.14 3153.05 926.02 3152.81 925.89C3153.22 925.53 3153.46 924.89 3154.16 925C3154.46 925.04 3154.64 925.22 3154.62 925.6C3154.58 926.39 3154 926.15 3153.44 926.21Z" fill="white"/>
                    <path d="M3371 897.8C3370.95 897.53 3371.18 897.34 3371.47 897.31C3371.87 897.27 3372.3 897.33 3372.21 897.9C3372.15 898.29 3372.51 898.98 3371.82 898.99C3371.57 898.99 3371 898.49 3371 897.81V897.8Z" fill="white"/>
                    <path d="M3372.8 1084.66C3372.1 1084.73 3371.9 1084.06 3371.47 1083.71C3371.73 1083.62 3371.98 1083.45 3372.24 1083.45C3372.67 1083.45 3373.27 1083.25 3373.29 1084.05C3373.29 1084.41 3373.12 1084.56 3372.8 1084.66Z" fill="white"/>
                    <path d="M3337.03 900.53C3336.95 900.12 3336.92 900.56 3337.25 900.34C3337.43 900.22 3337.86 900.24 3338.01 900.37C3338.4 900.74 3339.01 901.04 3338.8 901.77C3338.78 901.85 3338.65 901.98 3338.61 901.97C3337.86 901.74 3337.59 900.97 3337.03 900.53Z" fill="white"/>
                    <path d="M3292.25 934.99C3291.9 934.56 3291.25 934.25 3291.51 933.56C3291.57 933.39 3292.02 933.24 3292.24 933.29C3292.95 933.44 3292.6 934.05 3292.66 934.48C3292.7 934.77 3292.62 935.01 3292.25 934.99Z" fill="white"/>
                    <path d="M3235 1013.33C3234.8 1013.33 3234.58 1013.37 3234.41 1013.3C3234.3 1013.26 3234.15 1013.01 3234.18 1012.92C3234.31 1012.62 3236.17 1012.63 3236.36 1012.94C3236.4 1013.01 3236.26 1013.32 3236.18 1013.33C3235.79 1013.36 3235.39 1013.33 3234.99 1013.33H3235Z" fill="white"/>
                    <path d="M3242.95 849.2C3242.33 849.23 3242.24 848.86 3242.3 848.44C3242.34 848.14 3242.52 847.96 3242.9 847.97C3243.46 847.99 3243.54 848.33 3243.49 848.74C3243.45 849.03 3243.29 849.3 3242.96 849.2H3242.95Z" fill="white"/>
                    <path d="M3354.78 1092.78C3354.47 1092.68 3354.29 1092.54 3354.3 1092.17C3354.32 1091.61 3354.66 1091.53 3355.07 1091.58C3355.37 1091.62 3355.55 1091.8 3355.54 1092.18C3355.52 1092.74 3355.18 1092.78 3354.78 1092.78Z" fill="white"/>
                    <path d="M3135.46 1056.17C3135.15 1056.07 3134.97 1055.93 3134.98 1055.56C3135 1055 3135.34 1054.92 3135.75 1054.97C3136.05 1055.01 3136.23 1055.19 3136.22 1055.57C3136.2 1056.13 3135.86 1056.17 3135.46 1056.17Z" fill="white"/>
                    <path d="M3125.8 941.48C3125.81 941.1 3125.87 940.76 3126.43 940.77C3126.8 940.77 3126.98 940.96 3127.01 941.26C3127.05 941.67 3126.95 942.01 3126.39 942C3126.02 942 3125.88 941.82 3125.8 941.47V941.48Z" fill="white"/>
                    <path d="M3255.19 1132.78C3254.95 1133.28 3254.63 1133.69 3254.04 1133.75C3253.88 1133.77 3253.56 1133.58 3253.57 1133.56C3253.74 1133.02 3254.08 1132.63 3254.68 1132.57C3254.84 1132.56 3255.01 1132.69 3255.2 1132.77L3255.19 1132.78Z" fill="white"/>
                    <path d="M3155.46 1090.53C3155.1 1090.59 3154.93 1090.35 3154.9 1090.05C3154.86 1089.64 3154.96 1089.3 3155.52 1089.3C3155.89 1089.3 3156.07 1089.49 3156.1 1089.79C3156.14 1090.21 3156.03 1090.57 3155.46 1090.52V1090.53Z" fill="white"/>
                    <path d="M3273.47 1066.87C3273.16 1066.77 3272.98 1066.63 3272.99 1066.26C3273.01 1065.7 3273.35 1065.62 3273.76 1065.67C3274.06 1065.71 3274.24 1065.89 3274.23 1066.27C3274.21 1066.83 3273.87 1066.87 3273.47 1066.87Z" fill="white"/>
                    <path d="M3308.61 862.68C3308.25 862.57 3308.07 862.43 3308.08 862.05C3308.1 861.49 3308.44 861.41 3308.85 861.46C3309.15 861.5 3309.32 861.68 3309.32 862.06C3309.32 862.64 3308.94 862.63 3308.61 862.68Z" fill="white"/>
                    <path d="M3313.03 887.04C3313.01 886.46 3313.37 886.37 3313.79 886.43C3314.09 886.47 3314.27 886.65 3314.26 887.03C3314.24 887.59 3313.9 887.67 3313.49 887.62C3313.19 887.58 3312.96 887.4 3313.03 887.05V887.04Z" fill="white"/>
                    <path d="M3222.45 1159.18C3222.84 1159.2 3223.25 1159.22 3223.18 1159.78C3223.16 1159.95 3223.06 1160.12 3222.99 1160.28C3222.65 1160.01 3222.31 1159.74 3221.98 1159.47C3222.13 1159.37 3222.29 1159.27 3222.45 1159.17V1159.18Z" fill="white"/>
                    <path d="M3203.78 1107.88C3203.39 1107.86 3202.98 1107.84 3203.05 1107.28C3203.07 1107.11 3203.17 1106.94 3203.24 1106.78C3203.58 1107.05 3203.92 1107.32 3204.25 1107.59C3204.1 1107.69 3203.94 1107.79 3203.78 1107.89V1107.88Z" fill="white"/>
                    <path d="M3339.75 1075.4C3339.46 1075.11 3339.19 1074.84 3338.91 1074.57C3339.16 1074.48 3339.42 1074.29 3339.66 1074.31C3340.19 1074.36 3340.15 1074.81 3340.08 1075.18C3340.06 1075.28 3339.84 1075.34 3339.74 1075.4H3339.75Z" fill="white"/>
                    <path d="M3187.12 995.44C3187.21 995.22 3187.27 994.91 3187.34 994.91C3187.87 994.91 3187.99 995.46 3188.34 995.71C3188.19 995.81 3188.04 995.97 3187.87 995.99C3187.46 996.03 3187.11 995.92 3187.11 995.44H3187.12Z" fill="white"/>
                    <path d="M3095.95 966.77C3095.83 966.68 3095.61 966.61 3095.59 966.5C3095.54 966.12 3095.52 965.67 3096.06 965.65C3096.3 965.65 3096.55 965.84 3096.8 965.95C3096.52 966.22 3096.24 966.49 3095.95 966.77Z" fill="white"/>
                    <path d="M3119.45 985.54C3119.46 986.09 3119.08 986.14 3118.68 986.09C3118.52 986.07 3118.38 985.9 3118.23 985.79C3118.58 985.53 3118.93 985.28 3119.28 985.02C3119.34 985.19 3119.4 985.36 3119.46 985.54H3119.45Z" fill="white"/>
                    <path d="M3317.62 1064.62C3317.55 1064.58 3317.33 1064.51 3317.31 1064.41C3317.26 1064.03 3317.24 1063.59 3317.76 1063.55C3318 1063.53 3318.26 1063.73 3318.5 1063.84C3318.21 1064.09 3317.93 1064.35 3317.61 1064.62H3317.62Z" fill="white"/>
                    <path d="M3278.51 1062.12C3277.96 1062.13 3277.91 1061.75 3277.96 1061.35C3277.98 1061.19 3278.15 1061.05 3278.26 1060.9C3278.52 1061.25 3278.77 1061.6 3279.03 1061.95C3278.86 1062.01 3278.69 1062.07 3278.51 1062.13V1062.12Z" fill="white"/>
                    <path d="M3107.43 1033.77C3107.27 1033.67 3107.12 1033.57 3106.96 1033.48C3107.29 1033.21 3107.63 1032.94 3107.96 1032.67C3108.03 1032.84 3108.13 1033 3108.15 1033.17C3108.2 1033.71 3107.84 1033.78 3107.43 1033.78V1033.77Z" fill="white"/>
                    <path d="M3294.72 893.33C3294.73 893.88 3294.35 893.93 3293.95 893.88C3293.79 893.86 3293.65 893.69 3293.5 893.58C3293.85 893.32 3294.2 893.07 3294.55 892.81C3294.61 892.98 3294.67 893.15 3294.73 893.33H3294.72Z" fill="white"/>
                    <path d="M3293.18 851.73C3292.96 851.86 3292.81 852.01 3292.65 852.02C3292.23 852.06 3291.88 851.93 3291.95 851.4C3291.97 851.23 3292.09 851.07 3292.16 850.91C3292.5 851.18 3292.83 851.45 3293.19 851.73H3293.18Z" fill="white"/>
                    <path d="M3396.62 1050.5C3396.27 1050.29 3396.41 1049.97 3396.43 1049.7C3396.43 1049.62 3396.72 1049.46 3396.81 1049.5C3397.15 1049.66 3397.01 1050.01 3396.99 1050.28C3396.99 1050.37 3396.75 1050.44 3396.63 1050.51L3396.62 1050.5Z" fill="white"/>
                    <path d="M3373.77 1063.82C3373.45 1063.91 3373.19 1064.03 3372.93 1064.03C3372.78 1064.03 3372.63 1063.84 3372.48 1063.74C3372.73 1063.64 3372.98 1063.5 3373.24 1063.47C3373.38 1063.46 3373.54 1063.66 3373.77 1063.82Z" fill="white"/>
                    <path d="M3187.45 872.83C3187.1 872.62 3187.24 872.3 3187.26 872.03C3187.26 871.95 3187.55 871.79 3187.64 871.83C3187.98 871.99 3187.84 872.34 3187.82 872.61C3187.82 872.7 3187.58 872.77 3187.46 872.84L3187.45 872.83Z" fill="white"/>
                    <path d="M3112.99 957.57C3112.9 957.25 3112.78 956.99 3112.78 956.73C3112.78 956.58 3112.97 956.43 3113.07 956.28C3113.17 956.53 3113.31 956.78 3113.34 957.04C3113.35 957.18 3113.15 957.34 3112.99 957.57Z" fill="white"/>
                    <path d="M3113.32 960.82C3113.34 960.66 3113.32 960.38 3113.4 960.14C3113.5 959.85 3113.72 959.85 3113.79 960.14C3113.85 960.39 3113.85 960.68 3113.77 960.93C3113.69 961.22 3113.5 961.2 3113.32 960.81V960.82Z" fill="white"/>
                    <path d="M3171.02 864.71C3170.95 864.87 3170.89 865.17 3170.8 865.18C3170.44 865.23 3170.37 864.96 3170.42 864.68C3170.45 864.51 3170.54 864.22 3170.63 864.21C3170.99 864.16 3171.05 864.42 3171.02 864.72V864.71Z" fill="white"/>
                    <path d="M3349.53 1088.43C3349.37 1088.36 3349.07 1088.3 3349.06 1088.21C3349.01 1087.85 3349.28 1087.78 3349.56 1087.83C3349.73 1087.86 3350.02 1087.95 3350.03 1088.04C3350.08 1088.4 3349.82 1088.46 3349.52 1088.43H3349.53Z" fill="white"/>
                    <path d="M3117.23 1038.57C3116.91 1038.66 3116.65 1038.78 3116.39 1038.78C3116.24 1038.78 3116.09 1038.59 3115.94 1038.49C3116.19 1038.39 3116.44 1038.25 3116.7 1038.22C3116.84 1038.21 3117 1038.41 3117.23 1038.57Z" fill="white"/>
                    <path d="M3357.52 926.08C3357.41 926 3357.26 925.9 3357.11 925.8C3357.36 925.7 3357.61 925.56 3357.87 925.53C3358.01 925.52 3358.17 925.72 3358.32 925.82C3358.12 926.06 3357.87 926.17 3357.52 926.08Z" fill="white"/>
                    <path d="M3118.62 1084.93C3118.6 1084.63 3118.67 1084.37 3119.03 1084.44C3119.12 1084.46 3119.2 1084.75 3119.22 1084.92C3119.26 1085.21 3119.18 1085.48 3118.82 1085.41C3118.73 1085.39 3118.68 1085.1 3118.62 1084.93Z" fill="white"/>
                    <path d="M3316.52 924.07C3316.5 923.77 3316.57 923.51 3316.93 923.58C3317.02 923.6 3317.1 923.89 3317.12 924.06C3317.16 924.35 3317.08 924.62 3316.72 924.55C3316.63 924.53 3316.58 924.24 3316.52 924.07Z" fill="white"/>
                    <path d="M3307.25 932.98C3307.23 932.68 3307.3 932.42 3307.66 932.49C3307.75 932.51 3307.83 932.8 3307.85 932.97C3307.89 933.26 3307.81 933.53 3307.45 933.46C3307.36 933.44 3307.31 933.15 3307.25 932.98Z" fill="white"/>
                    <path d="M3278.07 970.31C3277.75 970.4 3277.49 970.52 3277.23 970.52C3277.08 970.52 3276.93 970.33 3276.78 970.23C3277.03 970.13 3277.28 969.99 3277.54 969.96C3277.68 969.95 3277.84 970.15 3278.07 970.31Z" fill="white"/>
                    <path d="M3326.97 1069.22C3326.77 1069.11 3326.53 1068.98 3326.3 1068.85C3326.46 1068.75 3326.62 1068.56 3326.76 1068.58C3327.02 1068.61 3327.26 1068.77 3327.51 1068.88C3327.35 1068.98 3327.2 1069.08 3326.97 1069.22Z" fill="white"/>
                    <path d="M3234.51 1041.79C3234.4 1041.71 3234.25 1041.61 3234.1 1041.51C3234.35 1041.41 3234.6 1041.27 3234.86 1041.24C3235 1041.23 3235.16 1041.43 3235.31 1041.53C3235.11 1041.77 3234.86 1041.88 3234.51 1041.79Z" fill="white"/>
                    <path d="M3225.95 1088.08C3225.63 1088.17 3225.37 1088.29 3225.11 1088.29C3224.96 1088.29 3224.81 1088.1 3224.66 1088C3224.91 1087.9 3225.16 1087.76 3225.42 1087.73C3225.56 1087.72 3225.72 1087.92 3225.95 1088.08Z" fill="white"/>
                    <path d="M3213.09 1160.73C3212.74 1160.52 3212.88 1160.2 3212.9 1159.93C3212.9 1159.85 3213.19 1159.69 3213.28 1159.73C3213.62 1159.89 3213.48 1160.24 3213.46 1160.51C3213.46 1160.6 3213.22 1160.67 3213.1 1160.74L3213.09 1160.73Z" fill="white"/>
                    <path d="M3184.99 1100.84C3184.97 1100.54 3185.04 1100.28 3185.4 1100.35C3185.49 1100.37 3185.57 1100.66 3185.59 1100.83C3185.63 1101.12 3185.55 1101.39 3185.19 1101.32C3185.1 1101.3 3185.05 1101.01 3184.99 1100.84Z" fill="white"/>
                    <path d="M3198.43 888.31C3198.36 888.47 3198.3 888.77 3198.21 888.78C3197.85 888.83 3197.78 888.56 3197.83 888.28C3197.86 888.11 3197.95 887.82 3198.04 887.81C3198.4 887.76 3198.46 888.02 3198.43 888.32V888.31Z" fill="white"/>
                    <path d="M3212.18 986.19C3212.02 986.12 3211.72 986.06 3211.71 985.97C3211.66 985.61 3211.93 985.54 3212.21 985.59C3212.38 985.62 3212.67 985.71 3212.68 985.8C3212.73 986.16 3212.47 986.22 3212.17 986.19H3212.18Z" fill="white"/>
                    <path d="M3196.41 986.78C3196.27 986.84 3196.14 986.93 3196 986.96C3195.95 986.97 3195.87 986.84 3195.8 986.77C3195.94 986.71 3196.07 986.62 3196.21 986.59C3196.26 986.58 3196.34 986.71 3196.41 986.78Z" fill="white"/>
                    <path d="M3097.17 1036.89C3097.03 1036.95 3096.9 1037.04 3096.76 1037.07C3096.71 1037.08 3096.63 1036.95 3096.56 1036.88C3096.7 1036.82 3096.83 1036.73 3096.97 1036.7C3097.02 1036.69 3097.1 1036.82 3097.17 1036.89Z" fill="white"/>
                    <path d="M3303.76 944.93C3303.63 944.86 3303.5 944.78 3303.37 944.71C3303.44 944.64 3303.52 944.51 3303.57 944.52C3303.71 944.55 3303.84 944.65 3303.97 944.72C3303.9 944.79 3303.83 944.85 3303.75 944.93H3303.76Z" fill="white"/>
                    <path d="M3318.62 899.94C3318.7 900.02 3318.77 900.09 3318.83 900.16C3318.69 900.22 3318.56 900.31 3318.42 900.34C3318.37 900.35 3318.29 900.22 3318.22 900.15C3318.35 900.08 3318.49 900.01 3318.61 899.94H3318.62Z" fill="white"/>
                    <path d="M3318.33 988.15C3318.4 988.02 3318.48 987.89 3318.55 987.76C3318.62 987.83 3318.75 987.91 3318.74 987.96C3318.71 988.1 3318.61 988.23 3318.54 988.36C3318.47 988.29 3318.41 988.22 3318.33 988.14V988.15Z" fill="white"/>
                    <path d="M3283.34 1019.78C3283.28 1019.64 3283.19 1019.51 3283.16 1019.37C3283.15 1019.32 3283.28 1019.24 3283.35 1019.17C3283.41 1019.31 3283.5 1019.44 3283.53 1019.58C3283.54 1019.63 3283.41 1019.71 3283.34 1019.78Z" fill="white"/>
                    <path d="M3277.27 1068.98C3277.35 1068.9 3277.42 1068.83 3277.49 1068.77C3277.55 1068.91 3277.64 1069.04 3277.67 1069.18C3277.68 1069.23 3277.55 1069.31 3277.48 1069.38C3277.41 1069.25 3277.34 1069.11 3277.27 1068.99V1068.98Z" fill="white"/>
                    <path d="M3334.75 1089.86C3334.62 1089.79 3334.49 1089.71 3334.36 1089.64C3334.43 1089.57 3334.51 1089.44 3334.56 1089.45C3334.7 1089.48 3334.83 1089.58 3334.96 1089.65C3334.89 1089.72 3334.82 1089.78 3334.74 1089.86H3334.75Z" fill="white"/>
                    <path d="M3320.68 968.31C3320.6 968.23 3320.53 968.16 3320.47 968.09C3320.61 968.03 3320.74 967.94 3320.88 967.91C3320.93 967.9 3321.01 968.03 3321.08 968.1C3320.95 968.17 3320.81 968.24 3320.69 968.31H3320.68Z" fill="white"/>
                    <path d="M3254.61 1005.94C3254.69 1006.02 3254.76 1006.09 3254.82 1006.16C3254.68 1006.22 3254.55 1006.31 3254.41 1006.34C3254.36 1006.35 3254.28 1006.22 3254.21 1006.15C3254.34 1006.08 3254.48 1006.01 3254.6 1005.94H3254.61Z" fill="white"/>
                    <path d="M3239.67 1152.01C3239.6 1152.08 3239.5 1152.21 3239.47 1152.19C3239.34 1152.12 3239.18 1152.03 3239.12 1151.91C3239 1151.65 3239.12 1151.53 3239.38 1151.66C3239.5 1151.72 3239.57 1151.89 3239.66 1152.01H3239.67Z" fill="white"/>
                    <path d="M3238.85 1151.64C3238.76 1151.52 3238.57 1151.36 3238.6 1151.28C3238.68 1151.05 3238.86 1151.01 3238.96 1151.17C3239.04 1151.31 3239.07 1151.48 3239.12 1151.64C3239.03 1151.64 3238.94 1151.64 3238.85 1151.64Z" fill="white"/>
                    <path d="M3182.21 959.42C3182.08 959.35 3181.95 959.27 3181.82 959.2C3181.89 959.13 3181.97 959 3182.02 959.01C3182.16 959.04 3182.29 959.14 3182.42 959.21C3182.35 959.28 3182.28 959.34 3182.2 959.42H3182.21Z" fill="white"/>
                    <path d="M3327.38 1153.36C3327.25 1153.29 3327.12 1153.21 3326.99 1153.14C3327.06 1153.07 3327.14 1152.94 3327.19 1152.95C3327.33 1152.98 3327.46 1153.08 3327.59 1153.15C3327.52 1153.22 3327.45 1153.28 3327.37 1153.36H3327.38Z" fill="white"/>
                    <path d="M3234.35 1158.13C3234.27 1158.05 3234.2 1157.98 3234.14 1157.91C3234.28 1157.85 3234.41 1157.76 3234.55 1157.73C3234.6 1157.72 3234.68 1157.85 3234.75 1157.92C3234.62 1157.99 3234.48 1158.06 3234.36 1158.13H3234.35Z" fill="white"/>
                    <path d="M3337.79 1110.1C3337.92 1110.17 3338.05 1110.25 3338.18 1110.32C3338.11 1110.39 3338.03 1110.52 3337.98 1110.51C3337.84 1110.48 3337.71 1110.38 3337.58 1110.31C3337.65 1110.24 3337.72 1110.18 3337.8 1110.1H3337.79Z" fill="white"/>
                    <path d="M3198.38 1057.97C3198.25 1057.9 3198.12 1057.82 3197.99 1057.75C3198.06 1057.68 3198.14 1057.55 3198.19 1057.56C3198.33 1057.59 3198.46 1057.69 3198.59 1057.76C3198.52 1057.83 3198.45 1057.89 3198.37 1057.97H3198.38Z" fill="white"/>
                    <path d="M3332.9 1016.76C3332.98 1016.68 3333.05 1016.61 3333.12 1016.55C3333.18 1016.69 3333.27 1016.82 3333.3 1016.96C3333.31 1017.01 3333.18 1017.09 3333.11 1017.16C3333.04 1017.03 3332.97 1016.89 3332.9 1016.77V1016.76Z" fill="white"/>
                    <path d="M3222.08 1124.93C3221.94 1124.99 3221.81 1125.08 3221.67 1125.11C3221.62 1125.12 3221.54 1124.99 3221.47 1124.92C3221.61 1124.86 3221.74 1124.77 3221.88 1124.74C3221.93 1124.73 3222.01 1124.86 3222.08 1124.93Z" fill="white"/>
                    <path d="M3335.37 1029.85C3335.24 1029.78 3335.11 1029.7 3334.98 1029.63C3335.05 1029.56 3335.13 1029.43 3335.18 1029.44C3335.32 1029.47 3335.45 1029.57 3335.58 1029.64L3335.36 1029.85H3335.37Z" fill="white"/>
                    <path d="M3222.27 992.68C3222.2 992.81 3222.12 992.94 3222.05 993.07C3221.98 993 3221.85 992.92 3221.86 992.87C3221.89 992.73 3221.99 992.6 3222.06 992.47C3222.13 992.54 3222.19 992.61 3222.27 992.69V992.68Z" fill="white"/>
                    <path d="M3161.92 864.82C3161.78 864.88 3161.65 864.97 3161.51 865C3161.46 865.01 3161.38 864.88 3161.31 864.81C3161.45 864.75 3161.58 864.66 3161.72 864.63C3161.77 864.62 3161.85 864.75 3161.92 864.82Z" fill="white"/>
                    <path d="M3111.09 1172.5C3109.05 1172.81 3108.15 1171.47 3107.33 1170.19C3107.03 1169.72 3106.67 1168.93 3106.43 1168.74C3104.88 1167.56 3106.93 1166.49 3106.39 1165.43C3107.5 1165.07 3108.71 1164.26 3109.7 1164.47C3110.64 1164.67 3111.79 1165.03 3112.64 1165.69C3113.44 1166.32 3114.84 1166.85 3113.54 1168.31C3113.35 1168.53 3113.6 1169.12 3113.6 1169.54C3113.6 1171.4 3112.71 1172.38 3111.09 1172.49V1172.5Z" fill="white"/>
                    <path d="M3209.32 1037.43C3209.39 1038.65 3208.48 1039.58 3208.05 1039.6C3206.39 1039.68 3205.83 1041.24 3204.55 1041.8C3203.65 1042.2 3203.04 1042.3 3202.36 1041.63C3201.24 1040.53 3201.06 1039.82 3201.68 1038.86C3201.95 1038.44 3202.16 1038.01 3202.36 1037.56C3203.25 1035.6 3207.48 1034.54 3208.89 1036.57C3209.14 1036.93 3209.36 1037.22 3209.32 1037.43Z" fill="white"/>
                    <path d="M3112.5 1061.2C3111.77 1061.99 3112.11 1063.52 3110.63 1063.74C3110.42 1063.77 3110.17 1063.71 3110.01 1063.8C3109.33 1064.2 3108.74 1064.88 3107.83 1064.47C3107.11 1064.15 3106.95 1063.4 3106.6 1062.81C3105.9 1061.64 3106.41 1059.78 3107.61 1058.88C3108.54 1058.18 3109.48 1058.15 3110.56 1058.65C3111.68 1059.17 3111.89 1060.31 3112.51 1061.2H3112.5Z" fill="white"/>
                    <path d="M3034.9 934.84C3034.78 933.01 3035.39 932.13 3036.69 931.88C3037.41 931.74 3038.16 931.79 3038.89 931.69C3039.58 931.6 3039.81 931.88 3039.82 932.54C3039.83 933.28 3040.03 934.02 3039.95 934.74C3039.82 935.97 3039.05 936.83 3038 937.41C3037.78 937.53 3037.33 937.52 3037.17 937.37C3036.55 936.82 3035.81 936.53 3035.13 936.13C3034.8 935.93 3034.92 934.97 3034.9 934.84Z" fill="white"/>
                    <path d="M3170.07 904.8C3170.2 905.62 3170.41 906.79 3169.15 907.23C3168.55 907.44 3167.43 907.83 3167.15 907.09C3166.84 906.29 3165.83 905.65 3166.28 904.61C3166.59 903.89 3167.18 903.24 3167.3 902.5C3167.42 901.82 3167.62 901.66 3168.24 901.61C3169.12 901.53 3169.64 902.01 3169.88 902.7C3170.08 903.28 3170.01 903.95 3170.07 904.81V904.8Z" fill="white"/>
                    <path d="M3011.98 934.66C3012.06 935.49 3011.46 936.25 3010.64 936.36C3010.37 936.4 3007.66 935.12 3007.54 934.9C3007.27 934.4 3008.2 932.7 3008.26 932.78C3009.07 933.83 3009.6 932.29 3010.27 932.62C3010.91 932.93 3011.92 934.14 3011.97 934.67L3011.98 934.66Z" fill="white"/>
                    <path d="M3068.18 1114.01C3068.62 1113.99 3069.28 1113.82 3069.32 1114.24C3069.39 1115.01 3069.81 1115.83 3069.13 1116.63C3068.76 1117.07 3068.18 1117.03 3067.87 1117.39C3067.37 1117.96 3066.98 1118.09 3066.32 1117.58C3065.61 1117.03 3065.45 1116.27 3065.82 1115.69C3066.35 1114.87 3066.9 1113.82 3068.17 1114.02L3068.18 1114.01Z" fill="white"/>
                    <path d="M3144.19 1034.64C3144.19 1033.97 3143.78 1033.14 3145.04 1033.1C3145.32 1033.1 3145.57 1032.5 3145.86 1032.2C3146.19 1031.86 3146.55 1031.55 3146.9 1031.22C3147.02 1031.92 3147.41 1032.71 3147.2 1033.29C3146.93 1034.03 3146.15 1034.57 3145.64 1035.23C3145.36 1035.59 3145.03 1035.87 3144.6 1035.72C3144.13 1035.56 3144.23 1035.05 3144.19 1034.65V1034.64Z" fill="white"/>
                    <path d="M2976.7 1057.9C2976.68 1057.69 2976.65 1057.49 2976.66 1057.28C2976.69 1056.82 2976.3 1056.19 2977 1055.97C2977.72 1055.74 2978.37 1055.92 2978.92 1056.52C2979.38 1057.03 2979.17 1057.38 2978.84 1057.83C2978.63 1058.12 2978.67 1058.6 2978.46 1058.89C2978.19 1059.28 2977.93 1059.83 2977.3 1059.73C2976.55 1059.6 2976.85 1058.95 2976.75 1058.5C2976.71 1058.3 2976.71 1058.09 2976.7 1057.88V1057.9Z" fill="white"/>
                    <path d="M3018.2 924.98C3017.32 924.57 3016.28 924.41 3015.64 923.58C3015.49 923.39 3015.45 923 3015.74 922.73C3016.1 922.39 3016.33 921.72 3016.93 921.92C3017.66 922.17 3018.35 922.58 3019.02 922.99C3019.81 923.48 3018.73 923.73 3018.82 924.16C3018.91 924.61 3018.79 924.98 3018.2 924.98Z" fill="white"/>
                    <path d="M3151.21 1085.75C3151.07 1085.19 3151.24 1084.83 3151.94 1084.81C3153.03 1084.78 3153.69 1085.31 3153.8 1086.28C3153.9 1087.14 3153.4 1087.65 3152.76 1087.91C3152.02 1088.21 3151.28 1087.15 3151.21 1085.76V1085.75Z" fill="white"/>
                    <path d="M3233.64 1132.8C3233.62 1132.49 3233.54 1132.16 3233.58 1131.86C3233.68 1131.15 3234.52 1130.86 3235.06 1131.32C3235.38 1131.59 3235.76 1131.82 3236 1132.15C3236.31 1132.58 3236.39 1133.2 3236.12 1133.59C3235.81 1134.04 3235.01 1133.97 3234.7 1134.55C3234.31 1134.11 3233.56 1133.92 3233.67 1133.11C3233.68 1133.01 3233.66 1132.9 3233.65 1132.8H3233.64Z" fill="white"/>
                    <path d="M3030.83 982.25C3030.59 982.53 3030.37 982.76 3030.18 983.01C3029.71 983.64 3028.73 982.99 3028.3 983.79C3028.07 984.21 3027.59 984.25 3027.27 983.9C3026.96 983.57 3027.05 983.17 3027.37 982.83C3027.79 982.37 3028.16 981.86 3028.61 981.43C3028.82 981.22 3028.91 980.57 3029.46 981.07C3029.9 981.48 3030.37 981.86 3030.83 982.26V982.25Z" fill="white"/>
                    <path d="M3099.6 871.15C3100.27 871.2 3100.97 872.19 3100.87 872.95C3100.83 873.25 3099.29 874.44 3098.92 874.37C3098 874.19 3098.38 873.39 3098.35 872.87C3098.29 871.91 3098.96 871.1 3099.6 871.15Z" fill="white"/>
                    <path d="M3152.21 941.62C3151.58 941.76 3151.17 941.66 3151.15 940.93C3151.12 940.05 3151.67 939.13 3152.3 939.08C3152.93 939.03 3153.63 939.87 3153.69 940.75C3153.78 942.06 3152.71 941.39 3152.21 941.61V941.62Z" fill="white"/>
                    <path d="M3159.1 945.1C3158.84 945.62 3158.57 946.2 3157.92 946.29C3157.2 946.39 3156.38 945.21 3156.5 944.35C3156.58 943.79 3156.99 943.79 3157.4 943.8C3158.33 943.82 3159.14 944.43 3159.11 945.1H3159.1Z" fill="white"/>
                    <path d="M3063.71 962.67C3063.68 962.26 3063.69 962.05 3063.64 961.85C3063.49 961.09 3064.18 960.81 3064.48 960.32C3064.86 960.76 3065.58 960.94 3065.54 961.71C3065.53 961.91 3065.55 962.12 3065.59 962.33C3065.7 963.02 3065.64 963.47 3064.72 963.52C3063.84 963.57 3063.61 963.24 3063.7 962.68L3063.71 962.67Z" fill="white"/>
                    <path d="M3245.59 968.34C3244.85 968.44 3244.67 967.97 3244.8 967.59C3245.06 966.85 3245.47 966.16 3245.87 965.48C3246.25 964.85 3246.5 965.46 3246.77 965.63C3247.96 966.38 3246.62 967.03 3246.66 967.75C3246.7 968.59 3245.88 968.16 3245.59 968.33V968.34Z" fill="white"/>
                    <path d="M3098.86 947.76C3099.09 948.61 3098.58 949.14 3097.94 949.42C3097.46 949.63 3096.17 948.46 3096.35 948.06C3096.7 947.3 3097.32 946.94 3098.19 946.95C3098.8 946.95 3098.82 947.34 3098.85 947.75L3098.86 947.76Z" fill="white"/>
                    <path d="M3012.51 1120.1C3011.89 1120.04 3011.51 1119.58 3011.21 1119.07C3011.03 1118.77 3012.35 1117.63 3012.99 1117.52C3013.57 1117.42 3013.66 1117.82 3013.68 1118.25C3013.72 1119.12 3013.15 1120.04 3012.51 1120.1Z" fill="white"/>
                    <path d="M3059.89 926.31C3060.2 926.38 3060.53 926.58 3060.38 926.92C3060.03 927.73 3059.88 928.64 3059.23 929.33C3058.96 929.62 3058.61 929.67 3058.39 929.57C3058.19 929.47 3057.95 929.01 3058.03 928.82C3058.32 928.09 3058.72 927.4 3059.1 926.7C3059.26 926.4 3059.54 926.28 3059.9 926.31H3059.89Z" fill="white"/>
                    <path d="M3043.45 896.13C3043.5 896.46 3043.58 896.87 3043.04 897.03C3042.57 897.16 3042.33 896.79 3041.99 896.59C3041.31 896.18 3041.46 895.5 3041.5 894.95C3041.52 894.62 3042.03 894.03 3042.23 894.07C3042.64 894.15 3043.09 894.49 3043.32 894.86C3043.51 895.17 3043.41 895.66 3043.45 896.14V896.13Z" fill="white"/>
                    <path d="M3214.96 920.38C3215.47 920.26 3215.83 920.43 3215.84 921.13C3215.85 922.01 3215.31 922.9 3214.69 922.96C3213.64 923.06 3214.26 921.69 3213.43 921.51C3213.29 921.48 3214.16 920.51 3214.96 920.39V920.38Z" fill="white"/>
                    <path d="M3046.42 1020.39C3046.32 1020.55 3046.24 1020.8 3046.1 1020.85C3045.31 1021.1 3043.8 1020.08 3043.81 1019.29C3043.81 1019.02 3043.89 1018.62 3044.07 1018.51C3044.26 1018.4 3044.67 1018.49 3044.89 1018.63C3045.87 1019.26 3046.37 1019.87 3046.42 1020.39Z" fill="white"/>
                    <path d="M3099.99 1125.62C3100.3 1125.6 3100.62 1125.52 3100.92 1125.56C3101.7 1125.67 3101.4 1126.33 3101.49 1126.77C3101.61 1127.35 3101.19 1127.44 3100.76 1127.47C3100.36 1127.49 3099.97 1127.6 3099.62 1127.25C3099.26 1126.89 3098.72 1126.61 3098.93 1126.01C3099.09 1125.54 3099.61 1125.66 3100 1125.62H3099.99Z" fill="white"/>
                    <path d="M3061.83 929.25C3061.91 928.6 3062.35 928.62 3062.75 928.64C3063.7 928.67 3064.49 929.28 3064.43 929.95C3064.38 930.51 3063.92 930.5 3063.52 930.48C3062.57 930.45 3061.8 929.84 3061.83 929.25Z" fill="white"/>
                    <path d="M3214.78 911.47C3215.32 911.97 3215.94 912.3 3215.91 913.19C3215.88 913.92 3215.53 913.97 3214.97 914.03C3214.37 914.09 3214.09 913.99 3213.95 913.3C3213.77 912.4 3214.42 912.04 3214.78 911.47Z" fill="white"/>
                    <path d="M3220.06 926.51C3219.97 926.68 3219.9 926.97 3219.77 927C3219.37 927.09 3218.87 927.24 3218.57 927.08C3218.08 926.81 3217.43 926.52 3217.47 925.78C3217.49 925.21 3217.95 925.22 3218.36 925.23C3219.32 925.26 3220.06 925.83 3220.07 926.52L3220.06 926.51Z" fill="white"/>
                    <path d="M3059.95 1141.17C3059.97 1141.6 3059.98 1142.07 3059.4 1142.01C3058.64 1141.94 3058.02 1141.51 3058.05 1140.72C3058.07 1140.31 3058.58 1139.92 3058.87 1139.52C3059.37 1139.99 3060.1 1140.3 3059.95 1141.17Z" fill="white"/>
                    <path d="M3216.74 1083.14C3215.98 1083.14 3215.14 1082.34 3215.18 1081.81C3215.23 1081.26 3215.68 1081.32 3216.07 1081.26C3216.86 1081.15 3217.42 1081.55 3217.58 1082.22C3217.63 1082.42 3217.14 1083.11 3216.73 1083.14H3216.74Z" fill="white"/>
                    <path d="M3119.03 936.81C3119.07 937.34 3119.08 937.89 3118.53 937.77C3117.85 937.62 3117.22 937.21 3116.57 936.92C3117 936.34 3117.43 935.85 3118.3 935.89C3119.12 935.93 3118.95 936.47 3119.03 936.81Z" fill="white"/>
                    <path d="M3257.17 1014.25C3257.33 1015.1 3256.92 1015.19 3256.32 1015.28C3255.4 1015.42 3255.32 1014.94 3255.26 1014.23C3255.2 1013.51 3255.55 1013.41 3256.15 1013.33C3257.04 1013.21 3257.28 1013.6 3257.17 1014.24V1014.25Z" fill="white"/>
                    <path d="M3107.16 930.4C3106.6 930.34 3105.74 930.83 3105.81 929.76C3105.85 929.18 3106.28 928.64 3106.93 928.52C3108.01 928.32 3107.79 929.51 3108.11 929.96C3108.51 930.52 3107.46 930.17 3107.16 930.41V930.4Z" fill="white"/>
                    <path d="M3026.01 884.2C3026.03 884.51 3026.1 884.82 3026.07 885.12C3026.01 885.67 3025.56 885.64 3025.16 885.65C3024.87 885.65 3024.53 885.84 3024.31 885.5C3024.06 885.12 3024.1 884.76 3024.44 884.43C3024.59 884.29 3024.71 884.12 3024.84 883.97C3025.11 883.68 3025.43 883.13 3025.64 883.17C3026.11 883.25 3025.94 883.83 3026.01 884.21V884.2Z" fill="white"/>
                    <path d="M3184.28 1133.92C3185.02 1134.09 3184.77 1134.71 3184.82 1135.16C3184.91 1136 3184.22 1135.8 3183.77 1135.86C3183.35 1135.92 3182.92 1135.91 3182.93 1135.3C3182.94 1134.68 3183.73 1133.89 3184.28 1133.92Z" fill="white"/>
                    <path d="M3178.22 967.05C3177.67 967.07 3177.24 967.09 3177.25 966.48C3177.27 965.86 3178.05 965.04 3178.59 965.11C3179.14 965.18 3179.05 965.64 3179.13 966.03C3179.34 966.97 3178.77 967.06 3178.22 967.05Z" fill="white"/>
                    <path d="M3072.52 905.59C3072.73 905.63 3073.17 905.6 3073.26 905.76C3073.51 906.2 3072.99 906.45 3072.75 906.62C3072.39 906.87 3072.37 908.03 3071.51 907.18C3071.22 906.9 3070.83 906.68 3070.93 906.21C3071.12 905.26 3071.95 905.87 3072.52 905.58V905.59Z" fill="white"/>
                    <path d="M2997.79 1050.71C2997.53 1051.13 2997.31 1052.01 2996.77 1051.67C2996.31 1051.37 2995.5 1051.73 2995.38 1050.99C2995.31 1050.55 2996.79 1049.81 2997.37 1049.99C2997.67 1050.08 2997.82 1050.35 2997.79 1050.71Z" fill="white"/>
                    <path d="M3237.83 1017.92C3237.83 1017.92 3238.17 1018.37 3238.41 1018.82C3238.51 1019.02 3237.39 1019.92 3237.09 1019.88C3236.32 1019.77 3236.61 1019.13 3236.52 1018.69C3236.41 1018.15 3236.75 1017.99 3237.82 1017.93L3237.83 1017.92Z" fill="white"/>
                    <path d="M3235.45 1055.18C3235.29 1055.92 3234.67 1055.64 3234.23 1055.73C3233.67 1055.84 3233.43 1055.42 3233.56 1055.03C3233.71 1054.58 3234.07 1054.18 3234.41 1053.83C3234.53 1053.7 3235.48 1054.9 3235.45 1055.18Z" fill="white"/>
                    <path d="M3089.85 1181.69C3089.31 1181.84 3089.07 1181.6 3089.05 1181.3C3089 1180.49 3089.48 1180.02 3090.2 1179.79C3090.58 1179.67 3090.81 1179.92 3090.84 1180.22C3090.92 1181.06 3090.38 1181.47 3089.84 1181.69H3089.85Z" fill="white"/>
                    <path d="M3025.14 1009.07C3025.81 1009.12 3026.34 1009.51 3026.38 1010.04C3026.4 1010.31 3025.96 1010.94 3025.34 1010.97C3024.51 1011.01 3024.71 1010.37 3024.64 1009.92C3024.57 1009.5 3024.61 1009.11 3025.14 1009.06V1009.07Z" fill="white"/>
                    <path d="M3190.2 1153.83C3189.91 1153.89 3188.63 1153.13 3188.74 1152.99C3189.04 1152.6 3189.38 1152.19 3189.81 1151.99C3190.2 1151.8 3190.61 1152.03 3190.58 1152.57C3190.56 1153.02 3190.94 1153.6 3190.2 1153.84V1153.83Z" fill="white"/>
                    <path d="M3022.81 1003.04C3022.67 1003.61 3022.35 1004.21 3021.51 1004.25C3021.2 1004.26 3020.92 1004.07 3020.99 1003.68C3021.11 1002.92 3021.5 1002.38 3022.31 1002.31C3022.61 1002.28 3022.88 1002.48 3022.81 1003.04Z" fill="white"/>
                    <path d="M2952.73 992.86C2952.81 993.44 2952.42 993.68 2952.02 993.55C2951.57 993.4 2951.17 993.04 2950.82 992.7C2950.69 992.58 2951.89 991.63 2952.17 991.66C2952.57 991.71 2952.78 992.18 2952.72 992.86H2952.73Z" fill="white"/>
                    <path d="M3109.37 935.11C3109.39 935.55 3109.42 935.99 3108.83 935.98C3108.18 935.98 3107.66 935.56 3107.62 935.02C3107.6 934.75 3108.04 934.12 3108.66 934.08C3109.5 934.04 3109.26 934.69 3109.37 935.1V935.11Z" fill="white"/>
                    <path d="M3011.11 915.55C3010.87 916.09 3010.6 916.57 3009.97 916.69C3009.6 916.76 3009.34 916.54 3009.35 916.23C3009.38 915.72 3008.83 914.96 3009.85 914.79C3010.47 914.69 3010.65 915.33 3011.11 915.54V915.55Z" fill="white"/>
                    <path d="M3100.03 946.35C3099.93 946.51 3099.83 946.67 3099.73 946.83C3099.03 946.25 3098.41 945.54 3097.42 945.41C3097.38 945.41 3097.33 945 3097.39 944.81C3097.42 944.7 3097.76 944.56 3097.79 944.6C3098.37 945.21 3099.42 944.99 3099.87 945.78C3099.97 945.95 3099.98 946.16 3100.03 946.35Z" fill="white"/>
                    <path d="M3095.31 947.8C3094.69 947.8 3094.97 946.98 3094.98 946.5C3095 945.54 3095.86 945.98 3096.36 945.86C3096.51 945.83 3097.1 946.12 3096.6 946.33C3095.85 946.64 3096.86 947.86 3095.31 947.81V947.8Z" fill="white"/>
                    <path d="M3120.93 1142.49C3121.68 1142.31 3121.94 1143.01 3122.43 1143.31C3122.18 1143.61 3121.95 1144.16 3121.69 1144.18C3121.11 1144.21 3120.7 1143.77 3120.54 1143.18C3120.44 1142.82 3120.65 1142.64 3120.94 1142.5L3120.93 1142.49Z" fill="white"/>
                    <path d="M3232.12 1013.26C3231.67 1013.23 3231.07 1013.54 3230.98 1012.7C3230.92 1012.09 3231.31 1012.03 3231.74 1012.02C3232.2 1012.02 3232.8 1011.73 3232.89 1012.57C3232.95 1013.18 3232.55 1013.21 3232.12 1013.26Z" fill="white"/>
                    <path d="M3036.77 1147.23C3036.26 1148.14 3035.64 1148.38 3034.88 1148.3C3034.7 1148.28 3034.54 1148.12 3034.38 1148.03C3034.51 1147.88 3034.68 1147.74 3034.77 1147.56C3035.12 1146.88 3035.76 1146.94 3036.36 1146.94C3036.54 1146.94 3036.72 1147.18 3036.78 1147.22L3036.77 1147.23Z" fill="white"/>
                    <path d="M3264.37 1016.35C3264.39 1016.5 3264.01 1016.61 3263.57 1016.61C3263.11 1016.61 3262.5 1016.92 3262.41 1016.07C3262.35 1015.48 3262.72 1015.38 3263.15 1015.38C3263.61 1015.38 3264.22 1015.09 3264.37 1016.35Z" fill="white"/>
                    <path d="M3202.9 989.27C3202.77 989.2 3202.52 989.15 3202.51 989.05C3202.39 988.35 3202.29 987.66 3202.97 987.11C3203.25 986.88 3203.44 986.79 3203.61 987.11C3203.88 987.6 3203.38 989.09 3202.9 989.27Z" fill="white"/>
                    <path d="M3156.32 1103.08C3156.14 1104.07 3155.31 1103.5 3154.8 1103.64C3154.57 1103.7 3154.35 1103.25 3154.42 1102.93C3154.62 1102 3155.41 1102.44 3155.94 1102.36C3156.19 1102.32 3156.37 1102.73 3156.32 1103.08Z" fill="white"/>
                    <path d="M3130.75 982.85C3130.57 983.84 3129.74 983.27 3129.23 983.41C3129 983.47 3128.78 983.02 3128.85 982.7C3129.05 981.77 3129.84 982.21 3130.37 982.13C3130.62 982.09 3130.8 982.5 3130.75 982.85Z" fill="white"/>
                    <path d="M3018.3 1069.19C3018.32 1069.34 3017.94 1069.45 3017.5 1069.45C3017.04 1069.45 3016.43 1069.76 3016.34 1068.91C3016.28 1068.32 3016.65 1068.22 3017.08 1068.22C3017.54 1068.22 3018.15 1067.93 3018.3 1069.19Z" fill="white"/>
                    <path d="M3137.03 899.6C3136.67 899.05 3136.3 898.48 3135.94 897.89C3135.92 897.86 3136.05 897.7 3136.13 897.67C3136.55 897.55 3136.89 897.67 3137.22 897.99C3137.59 898.34 3138.02 898.64 3137.72 899.22C3137.65 899.36 3137.4 899.4 3137.02 899.59L3137.03 899.6Z" fill="white"/>
                    <path d="M3084.02 923.86C3084.44 923.8 3084.88 923.71 3085 924.27C3085.15 925 3084.69 925.11 3084.1 925.12C3083.69 925.12 3083.24 925.26 3083.13 924.69C3082.99 923.97 3083.43 923.81 3084.02 923.85V923.86Z" fill="white"/>
                    <path d="M3033.29 963.19C3033.16 963.76 3033.03 964.03 3032.65 963.97C3031.89 963.85 3031.36 963.46 3031.28 962.65C3031.26 962.49 3031.45 962.32 3031.54 962.15C3032.15 962.51 3032.75 962.87 3033.29 963.19Z" fill="white"/>
                    <path d="M3159.33 965.54C3159.45 966.09 3159.36 966.53 3159.1 966.53C3158.57 966.53 3157.85 967.08 3157.51 966.19C3157.4 965.89 3157.51 965.45 3157.79 965.42C3158.33 965.37 3159 965.02 3159.33 965.53V965.54Z" fill="white"/>
                    <path d="M3028.56 1087.53C3028.57 1087.96 3028.6 1088.37 3027.99 1088.4C3027.15 1088.44 3027.33 1087.8 3027.27 1087.34C3027.22 1086.92 3027.22 1086.52 3027.83 1086.49C3028.68 1086.45 3028.46 1087.1 3028.56 1087.53Z" fill="white"/>
                    <path d="M3124.25 1080.54C3125.24 1080.72 3124.67 1081.55 3124.81 1082.06C3124.87 1082.29 3124.42 1082.51 3124.1 1082.44C3123.17 1082.24 3123.61 1081.45 3123.53 1080.92C3123.49 1080.67 3123.9 1080.49 3124.25 1080.54Z" fill="white"/>
                    <path d="M3030.16 952.08C3030.58 952.02 3031.02 951.93 3031.14 952.49C3031.29 953.22 3030.83 953.33 3030.24 953.34C3029.83 953.34 3029.38 953.48 3029.27 952.91C3029.13 952.19 3029.57 952.03 3030.16 952.07V952.08Z" fill="white"/>
                    <path d="M3204.27 923.77C3204.99 923.65 3205.18 923.85 3205.24 924.18C3205.37 924.92 3204.89 925.01 3204.33 925C3204.04 925 3203.46 925.1 3203.54 924.86C3203.68 924.37 3204.13 923.97 3204.27 923.77Z" fill="white"/>
                    <path d="M3046.53 1123.21C3046.47 1123.37 3046.37 1123.63 3046.26 1123.9C3045.84 1123.51 3045.16 1123.32 3045.19 1122.58C3045.21 1122.26 3045.38 1122.06 3045.77 1122.04C3046.6 1122 3046.41 1122.63 3046.53 1123.21Z" fill="white"/>
                    <path d="M2995.24 899.36C2994.97 899.44 2994.74 899.22 2994.68 898.92C2994.6 898.51 2994.62 898.05 2995.22 898.09C2995.64 898.12 2996.32 897.66 2996.39 898.39C2996.42 898.65 2995.95 899.29 2995.25 899.37L2995.24 899.36Z" fill="white"/>
                    <path d="M3189.76 878.86C3189.9 879.59 3189.22 879.86 3188.9 880.34C3188.78 880.08 3188.57 879.83 3188.55 879.57C3188.51 879.12 3188.24 878.52 3189.07 878.42C3189.45 878.37 3189.62 878.55 3189.76 878.86Z" fill="white"/>
                    <path d="M3001.47 934.49C3001.05 934.62 3001.51 934.6 3001.25 934.28C3001.11 934.11 3001.08 933.65 3001.21 933.49C3001.56 933.05 3001.81 932.38 3002.59 932.53C3002.68 932.55 3002.82 932.67 3002.81 932.71C3002.64 933.52 3001.87 933.87 3001.47 934.5V934.49Z" fill="white"/>
                    <path d="M3041.84 977.71C3041.43 978.12 3041.17 978.83 3040.42 978.63C3040.24 978.58 3040.04 978.13 3040.07 977.89C3040.16 977.13 3040.83 977.43 3041.27 977.33C3041.57 977.26 3041.82 977.33 3041.84 977.7V977.71Z" fill="white"/>
                    <path d="M3129.18 1029.55C3129.2 1029.76 3129.26 1029.98 3129.21 1030.17C3129.17 1030.29 3128.93 1030.47 3128.84 1030.44C3128.51 1030.33 3128.34 1028.39 3128.65 1028.17C3128.72 1028.12 3129.06 1028.24 3129.07 1028.32C3129.14 1028.73 3129.15 1029.14 3129.18 1029.56V1029.55Z" fill="white"/>
                    <path d="M2957.37 1037.63C2957.46 1038.27 2957.08 1038.4 2956.64 1038.39C2956.32 1038.38 2956.12 1038.21 2956.09 1037.81C2956.05 1037.23 2956.4 1037.1 2956.83 1037.12C2957.14 1037.13 2957.44 1037.27 2957.36 1037.63H2957.37Z" fill="white"/>
                    <path d="M3200.02 896.82C3199.95 897.16 3199.82 897.36 3199.43 897.38C3198.85 897.42 3198.73 897.07 3198.74 896.64C3198.75 896.32 3198.92 896.12 3199.32 896.09C3199.91 896.05 3199.98 896.4 3200.02 896.82Z" fill="white"/>
                    <path d="M3183.73 1129C3183.66 1129.34 3183.53 1129.54 3183.14 1129.56C3182.56 1129.6 3182.44 1129.25 3182.45 1128.82C3182.46 1128.5 3182.63 1128.3 3183.03 1128.27C3183.62 1128.23 3183.69 1128.58 3183.73 1129Z" fill="white"/>
                    <path d="M3065.2 1150.5C3064.81 1150.52 3064.45 1150.5 3064.4 1149.91C3064.37 1149.52 3064.54 1149.32 3064.85 1149.26C3065.28 1149.18 3065.64 1149.25 3065.69 1149.83C3065.72 1150.22 3065.55 1150.38 3065.2 1150.5Z" fill="white"/>
                    <path d="M3251.62 996.61C3252.17 996.81 3252.62 997.11 3252.75 997.71C3252.78 997.87 3252.62 998.22 3252.6 998.22C3252.02 998.09 3251.58 997.78 3251.46 997.17C3251.43 997.01 3251.56 996.81 3251.62 996.61Z" fill="white"/>
                    <path d="M3217.54 1104.73C3217.64 1105.09 3217.41 1105.3 3217.09 1105.36C3216.66 1105.44 3216.3 1105.37 3216.25 1104.79C3216.22 1104.4 3216.39 1104.2 3216.7 1104.14C3217.13 1104.06 3217.52 1104.14 3217.53 1104.73H3217.54Z" fill="white"/>
                    <path d="M3181.12 984.13C3181.05 984.47 3180.92 984.67 3180.53 984.69C3179.95 984.73 3179.83 984.38 3179.84 983.95C3179.85 983.63 3180.02 983.43 3180.42 983.4C3181.01 983.36 3181.08 983.71 3181.12 984.13Z" fill="white"/>
                    <path d="M2964.87 967.87C2964.79 968.26 2964.66 968.46 2964.27 968.49C2963.69 968.53 2963.57 968.18 2963.58 967.75C2963.59 967.43 2963.77 967.23 2964.16 967.2C2964.76 967.15 2964.8 967.54 2964.87 967.87Z" fill="white"/>
                    <path d="M2989.8 960.84C2989.2 960.92 2989.07 960.55 2989.09 960.11C2989.1 959.79 2989.27 959.59 2989.67 959.56C2990.25 959.52 2990.37 959.87 2990.36 960.3C2990.35 960.62 2990.18 960.88 2989.81 960.83L2989.8 960.84Z" fill="white"/>
                    <path d="M3282.4 1028.08C3282.38 1027.67 3282.36 1027.25 3282.96 1027.26C3283.14 1027.26 3283.32 1027.35 3283.5 1027.4C3283.25 1027.78 3283 1028.16 3282.76 1028.54C3282.64 1028.39 3282.52 1028.24 3282.4 1028.08Z" fill="white"/>
                    <path d="M3230.81 1052.65C3230.83 1053.06 3230.85 1053.48 3230.25 1053.47C3230.07 1053.47 3229.89 1053.38 3229.71 1053.33C3229.96 1052.95 3230.21 1052.57 3230.45 1052.19C3230.57 1052.34 3230.69 1052.49 3230.81 1052.65Z" fill="white"/>
                    <path d="M3183.4 914.22C3183.13 914.55 3182.87 914.86 3182.61 915.17C3182.49 914.92 3182.26 914.66 3182.26 914.41C3182.26 913.85 3182.73 913.85 3183.13 913.88C3183.24 913.88 3183.33 914.12 3183.4 914.21V914.22Z" fill="white"/>
                    <path d="M3115.31 1081.22C3115.07 1081.15 3114.75 1081.11 3114.74 1081.04C3114.68 1080.49 3115.25 1080.31 3115.47 1079.92C3115.59 1080.07 3115.77 1080.21 3115.8 1080.38C3115.89 1080.81 3115.8 1081.18 3115.31 1081.22Z" fill="white"/>
                    <path d="M3094.52 1179.07C3094.44 1179.21 3094.38 1179.44 3094.27 1179.47C3093.88 1179.56 3093.42 1179.62 3093.34 1179.07C3093.31 1178.82 3093.49 1178.54 3093.58 1178.27C3093.89 1178.53 3094.2 1178.8 3094.52 1179.08V1179.07Z" fill="white"/>
                    <path d="M3111.74 1152.72C3112.31 1152.65 3112.4 1153.05 3112.39 1153.47C3112.39 1153.64 3112.22 1153.8 3112.12 1153.97C3111.82 1153.63 3111.52 1153.3 3111.22 1152.96C3111.39 1152.88 3111.56 1152.81 3111.74 1152.72Z" fill="white"/>
                    <path d="M3174.38 938.36C3174.34 938.44 3174.29 938.68 3174.19 938.7C3173.8 938.79 3173.34 938.86 3173.25 938.32C3173.21 938.07 3173.39 937.78 3173.47 937.52C3173.76 937.79 3174.06 938.07 3174.37 938.37L3174.38 938.36Z" fill="white"/>
                    <path d="M3175.68 979.35C3175.75 979.92 3175.35 980.01 3174.93 980C3174.76 980 3174.6 979.83 3174.43 979.73C3174.77 979.43 3175.1 979.13 3175.44 978.83C3175.52 979 3175.59 979.17 3175.68 979.35Z" fill="white"/>
                    <path d="M3163.19 1160.43C3163.1 1160.61 3163.02 1160.77 3162.93 1160.94C3162.61 1160.62 3162.3 1160.3 3161.98 1159.98C3162.15 1159.9 3162.31 1159.77 3162.48 1159.73C3163.03 1159.62 3163.15 1159.99 3163.18 1160.42L3163.19 1160.43Z" fill="white"/>
                    <path d="M2998.19 979.29C2998.76 979.22 2998.85 979.62 2998.84 980.04C2998.84 980.21 2998.67 980.37 2998.57 980.54C2998.27 980.2 2997.97 979.87 2997.67 979.53C2997.84 979.45 2998.01 979.38 2998.19 979.29Z" fill="white"/>
                    <path d="M2954.99 985.04C2955.14 985.26 2955.32 985.4 2955.35 985.57C2955.43 986 2955.34 986.39 2954.77 986.36C2954.59 986.36 2954.41 986.25 2954.23 986.19C2954.48 985.81 2954.72 985.44 2954.98 985.04H2954.99Z" fill="white"/>
                    <path d="M3151.79 857.45C3151.61 857.84 3151.25 857.72 3150.97 857.73C3150.88 857.73 3150.69 857.45 3150.72 857.36C3150.86 856.98 3151.23 857.1 3151.51 857.1C3151.6 857.1 3151.7 857.34 3151.79 857.46V857.45Z" fill="white"/>
                    <path d="M3167.94 879.93C3168.06 880.26 3168.22 880.52 3168.24 880.79C3168.26 880.95 3168.07 881.12 3167.98 881.29C3167.85 881.04 3167.68 880.79 3167.63 880.52C3167.6 880.37 3167.8 880.19 3167.94 879.93Z" fill="white"/>
                    <path d="M2987.52 1093.1C2987.34 1093.49 2986.98 1093.37 2986.7 1093.38C2986.61 1093.38 2986.42 1093.1 2986.45 1093.01C2986.59 1092.63 2986.96 1092.75 2987.24 1092.75C2987.33 1092.75 2987.43 1092.99 2987.52 1093.11V1093.1Z" fill="white"/>
                    <path d="M3083.24 1162.24C3082.91 1162.36 3082.65 1162.52 3082.38 1162.54C3082.22 1162.56 3082.05 1162.37 3081.88 1162.28C3082.13 1162.15 3082.38 1161.98 3082.65 1161.93C3082.8 1161.9 3082.98 1162.1 3083.24 1162.24Z" fill="white"/>
                    <path d="M3086.59 1161.57C3086.42 1161.57 3086.14 1161.61 3085.87 1161.55C3085.56 1161.48 3085.54 1161.25 3085.84 1161.14C3086.09 1161.05 3086.4 1161.03 3086.67 1161.08C3086.98 1161.14 3086.98 1161.33 3086.59 1161.56V1161.57Z" fill="white"/>
                    <path d="M2980.7 1111.03C2980.88 1111.09 2981.19 1111.11 2981.21 1111.21C2981.3 1111.58 2981.03 1111.68 2980.72 1111.65C2980.54 1111.63 2980.23 1111.57 2980.2 1111.47C2980.11 1111.1 2980.38 1111.02 2980.69 1111.02L2980.7 1111.03Z" fill="white"/>
                    <path d="M3196 902.73C3195.94 902.91 3195.92 903.22 3195.82 903.24C3195.45 903.33 3195.35 903.06 3195.38 902.75C3195.4 902.57 3195.46 902.26 3195.56 902.23C3195.93 902.14 3196.01 902.41 3196.01 902.72L3196 902.73Z" fill="white"/>
                    <path d="M3167.21 1149.74C3167.33 1150.07 3167.49 1150.33 3167.51 1150.6C3167.53 1150.76 3167.34 1150.93 3167.25 1151.1C3167.12 1150.85 3166.95 1150.6 3166.9 1150.33C3166.87 1150.18 3167.07 1150 3167.21 1149.74Z" fill="white"/>
                    <path d="M3026.05 910.59C3025.98 910.71 3025.89 910.88 3025.8 911.05C3025.67 910.8 3025.5 910.55 3025.45 910.28C3025.42 910.13 3025.62 909.95 3025.71 909.78C3025.98 909.96 3026.12 910.21 3026.06 910.59H3026.05Z" fill="white"/>
                    <path d="M3215.38 1143.67C3215.07 1143.72 3214.79 1143.67 3214.83 1143.3C3214.83 1143.2 3215.13 1143.09 3215.31 1143.05C3215.61 1142.97 3215.89 1143.05 3215.86 1143.42C3215.86 1143.52 3215.55 1143.59 3215.38 1143.68V1143.67Z" fill="white"/>
                    <path d="M3028.04 953.51C3027.73 953.56 3027.45 953.51 3027.49 953.14C3027.49 953.04 3027.79 952.93 3027.97 952.89C3028.27 952.81 3028.55 952.89 3028.52 953.26C3028.52 953.36 3028.21 953.43 3028.04 953.52V953.51Z" fill="white"/>
                    <path d="M3038.25 962.29C3037.94 962.34 3037.66 962.29 3037.7 961.92C3037.7 961.82 3038 961.71 3038.18 961.67C3038.48 961.59 3038.76 961.67 3038.73 962.04C3038.73 962.14 3038.42 962.21 3038.25 962.3V962.29Z" fill="white"/>
                    <path d="M3080.06 988.96C3080.18 989.29 3080.34 989.55 3080.36 989.82C3080.38 989.98 3080.19 990.15 3080.1 990.32C3079.97 990.07 3079.8 989.82 3079.75 989.55C3079.72 989.4 3079.92 989.22 3080.06 988.96Z" fill="white"/>
                    <path d="M3178.24 928.15C3178.15 928.37 3178.04 928.63 3177.93 928.89C3177.81 928.74 3177.59 928.58 3177.6 928.43C3177.6 928.16 3177.75 927.89 3177.84 927.62C3177.96 927.77 3178.08 927.92 3178.25 928.15H3178.24Z" fill="white"/>
                    <path d="M3158.88 1027.23C3158.81 1027.35 3158.72 1027.52 3158.63 1027.69C3158.5 1027.44 3158.33 1027.19 3158.28 1026.92C3158.25 1026.77 3158.45 1026.59 3158.54 1026.42C3158.81 1026.6 3158.95 1026.85 3158.89 1027.23H3158.88Z" fill="white"/>
                    <path d="M3207.96 1031.53C3208.08 1031.86 3208.24 1032.12 3208.26 1032.39C3208.28 1032.55 3208.09 1032.72 3208 1032.89C3207.87 1032.64 3207.7 1032.39 3207.65 1032.12C3207.62 1031.97 3207.82 1031.79 3207.96 1031.53Z" fill="white"/>
                    <path d="M3284.94 1037.68C3284.76 1038.07 3284.4 1037.95 3284.12 1037.96C3284.03 1037.96 3283.84 1037.68 3283.87 1037.59C3284.01 1037.21 3284.38 1037.33 3284.66 1037.33C3284.75 1037.33 3284.85 1037.57 3284.94 1037.69V1037.68Z" fill="white"/>
                    <path d="M3225.34 1072.94C3225.03 1072.99 3224.75 1072.94 3224.79 1072.57C3224.79 1072.47 3225.09 1072.36 3225.27 1072.32C3225.57 1072.24 3225.85 1072.32 3225.82 1072.69C3225.82 1072.79 3225.51 1072.86 3225.34 1072.95V1072.94Z" fill="white"/>
                    <path d="M3002.56 1080.12C3002.74 1080.18 3003.05 1080.2 3003.07 1080.3C3003.16 1080.67 3002.89 1080.77 3002.58 1080.74C3002.4 1080.72 3002.09 1080.66 3002.06 1080.56C3001.97 1080.19 3002.24 1080.11 3002.55 1080.11L3002.56 1080.12Z" fill="white"/>
                    <path d="M3103.17 1056.04C3103.11 1056.22 3103.09 1056.53 3102.99 1056.55C3102.62 1056.64 3102.52 1056.37 3102.55 1056.06C3102.57 1055.88 3102.63 1055.57 3102.73 1055.54C3103.1 1055.45 3103.18 1055.72 3103.18 1056.03L3103.17 1056.04Z" fill="white"/>
                    <path d="M3105.36 1072.4C3105.44 1072.54 3105.55 1072.66 3105.59 1072.81C3105.61 1072.86 3105.47 1072.96 3105.41 1073.03C3105.33 1072.89 3105.22 1072.77 3105.18 1072.62C3105.16 1072.57 3105.3 1072.47 3105.36 1072.4Z" fill="white"/>
                    <path d="M3167.46 1170.81C3167.54 1170.95 3167.65 1171.07 3167.69 1171.22C3167.71 1171.27 3167.57 1171.37 3167.51 1171.44C3167.43 1171.3 3167.32 1171.18 3167.28 1171.03C3167.26 1170.98 3167.4 1170.88 3167.46 1170.81Z" fill="white"/>
                    <path d="M3051.05 964.73C3050.99 964.87 3050.92 965.01 3050.86 965.15C3050.78 965.08 3050.64 965.01 3050.65 964.96C3050.67 964.81 3050.76 964.67 3050.82 964.52C3050.9 964.59 3050.97 964.65 3051.06 964.73H3051.05Z" fill="white"/>
                    <path d="M3002.69 953.73C3002.77 953.64 3002.83 953.56 3002.9 953.49C3002.98 953.63 3003.09 953.75 3003.13 953.9C3003.15 953.95 3003.01 954.05 3002.95 954.12C3002.86 953.99 3002.78 953.86 3002.69 953.73Z" fill="white"/>
                    <path d="M3094.63 945.23C3094.49 945.17 3094.35 945.1 3094.21 945.04C3094.28 944.96 3094.35 944.82 3094.4 944.83C3094.55 944.85 3094.69 944.94 3094.84 945C3094.77 945.08 3094.71 945.15 3094.63 945.24V945.23Z" fill="white"/>
                    <path d="M3131.07 978.54C3130.93 978.62 3130.81 978.73 3130.66 978.77C3130.61 978.79 3130.51 978.65 3130.44 978.59C3130.58 978.51 3130.7 978.4 3130.85 978.36C3130.9 978.34 3131 978.48 3131.07 978.54Z" fill="white"/>
                    <path d="M3182.94 979.96C3182.85 979.88 3182.77 979.82 3182.7 979.75C3182.84 979.67 3182.96 979.56 3183.11 979.52C3183.16 979.5 3183.26 979.64 3183.33 979.7C3183.2 979.79 3183.07 979.87 3182.94 979.96Z" fill="white"/>
                    <path d="M3198.97 917.99C3198.91 918.13 3198.84 918.27 3198.78 918.41C3198.7 918.34 3198.56 918.27 3198.57 918.22C3198.59 918.07 3198.68 917.93 3198.74 917.78C3198.82 917.85 3198.89 917.91 3198.98 917.99H3198.97Z" fill="white"/>
                    <path d="M3073.73 944.76C3073.65 944.85 3073.59 944.93 3073.52 945C3073.44 944.86 3073.33 944.74 3073.29 944.59C3073.27 944.54 3073.41 944.44 3073.47 944.37C3073.56 944.5 3073.64 944.63 3073.73 944.76Z" fill="white"/>
                    <path d="M3119.52 1009.85C3119.6 1009.76 3119.66 1009.68 3119.73 1009.61C3119.81 1009.75 3119.92 1009.87 3119.96 1010.02C3119.98 1010.07 3119.84 1010.17 3119.78 1010.24C3119.69 1010.11 3119.61 1009.98 3119.52 1009.85Z" fill="white"/>
                    <path d="M3273.21 1010.86C3273.29 1010.93 3273.43 1011.02 3273.42 1011.05C3273.36 1011.19 3273.28 1011.37 3273.16 1011.44C3272.91 1011.59 3272.76 1011.47 3272.87 1011.19C3272.92 1011.06 3273.09 1010.97 3273.2 1010.86H3273.21Z" fill="white"/>
                    <path d="M3272.9 1011.75C3272.78 1011.86 3272.64 1012.07 3272.55 1012.05C3272.3 1011.99 3272.24 1011.8 3272.4 1011.69C3272.54 1011.59 3272.71 1011.54 3272.87 1011.47C3272.87 1011.56 3272.88 1011.66 3272.89 1011.75H3272.9Z" fill="white"/>
                    <path d="M3078.27 1089.93C3078.21 1090.07 3078.14 1090.21 3078.08 1090.35C3078 1090.28 3077.86 1090.21 3077.87 1090.16C3077.89 1090.01 3077.98 1089.87 3078.04 1089.72C3078.12 1089.79 3078.19 1089.85 3078.28 1089.93H3078.27Z" fill="white"/>
                    <path d="M3265.87 919.33C3265.81 919.47 3265.74 919.61 3265.68 919.75C3265.6 919.68 3265.46 919.61 3265.47 919.56C3265.49 919.41 3265.58 919.27 3265.64 919.12C3265.72 919.19 3265.79 919.25 3265.88 919.33H3265.87Z" fill="white"/>
                    <path d="M3280.11 1015.79C3280.03 1015.88 3279.97 1015.96 3279.9 1016.03C3279.82 1015.89 3279.71 1015.77 3279.67 1015.62C3279.65 1015.57 3279.79 1015.47 3279.85 1015.4C3279.94 1015.53 3280.02 1015.66 3280.11 1015.79Z" fill="white"/>
                    <path d="M3219.75 912.8C3219.81 912.66 3219.88 912.52 3219.94 912.38C3220.02 912.45 3220.16 912.52 3220.15 912.57C3220.13 912.72 3220.04 912.86 3219.98 913.01C3219.9 912.94 3219.83 912.88 3219.74 912.8H3219.75Z" fill="white"/>
                    <path d="M3179.34 1063.26C3179.28 1063.4 3179.21 1063.54 3179.15 1063.68C3179.07 1063.61 3178.93 1063.54 3178.94 1063.49C3178.96 1063.34 3179.05 1063.2 3179.11 1063.05C3179.19 1063.11 3179.26 1063.18 3179.35 1063.26H3179.34Z" fill="white"/>
                    <path d="M3122.99 927.2C3122.9 927.12 3122.82 927.06 3122.75 926.99C3122.89 926.91 3123.01 926.8 3123.16 926.76C3123.21 926.74 3123.31 926.88 3123.38 926.94C3123.25 927.03 3123.12 927.11 3122.99 927.2Z" fill="white"/>
                    <path d="M3246.74 1031.88C3246.82 1032.02 3246.93 1032.14 3246.97 1032.29C3246.99 1032.34 3246.85 1032.44 3246.79 1032.51C3246.71 1032.37 3246.6 1032.25 3246.56 1032.1C3246.54 1032.05 3246.68 1031.95 3246.74 1031.88Z" fill="white"/>
                    <path d="M3136.38 923.33C3136.32 923.47 3136.25 923.61 3136.19 923.75C3136.11 923.68 3135.97 923.61 3135.98 923.56C3136 923.41 3136.09 923.27 3136.15 923.12L3136.39 923.33H3136.38Z" fill="white"/>
                    <path d="M3108.92 1044.87C3109.06 1044.93 3109.2 1045 3109.34 1045.06C3109.27 1045.14 3109.2 1045.28 3109.15 1045.27C3109 1045.25 3108.86 1045.16 3108.71 1045.1C3108.78 1045.02 3108.84 1044.95 3108.92 1044.86V1044.87Z" fill="white"/>
                    <path d="M2981.72 1120.5C2981.8 1120.64 2981.91 1120.76 2981.95 1120.91C2981.97 1120.96 2981.83 1121.06 2981.77 1121.13C2981.69 1120.99 2981.58 1120.87 2981.54 1120.72C2981.52 1120.67 2981.66 1120.57 2981.72 1120.5Z" fill="white"/>
                    <path d="M2969.17 1165.05C2967.32 1164.78 2966.89 1163.39 2966.52 1162.07C2966.38 1161.59 2966.28 1160.81 2966.12 1160.59C2965.08 1159.16 2967.14 1158.78 2966.95 1157.72C2968.01 1157.7 2969.26 1157.31 2970.07 1157.75C2970.83 1158.17 2971.74 1158.78 2972.29 1159.57C2972.82 1160.33 2973.9 1161.14 2972.39 1162.07C2972.17 1162.21 2972.23 1162.78 2972.12 1163.15C2971.64 1164.76 2970.61 1165.38 2969.18 1165.05H2969.17Z" fill="white"/>
                    <path d="M3089.34 1073.66C3089.08 1074.73 3088.05 1075.3 3087.68 1075.21C3086.22 1074.85 3085.33 1076.05 3084.08 1076.21C3083.2 1076.32 3082.64 1076.25 3082.23 1075.49C3081.55 1074.24 3081.58 1073.59 3082.36 1072.91C3082.7 1072.62 3082.99 1072.3 3083.29 1071.96C3084.57 1070.49 3088.5 1070.68 3089.2 1072.8C3089.32 1073.18 3089.44 1073.49 3089.35 1073.66H3089.34Z" fill="white"/>
                    <path d="M2999.34 1069.05C2998.5 1069.54 2998.4 1070.96 2997.06 1070.76C2996.87 1070.73 2996.67 1070.61 2996.5 1070.65C2995.81 1070.82 2995.12 1071.25 2994.44 1070.67C2993.9 1070.21 2993.95 1069.51 2993.8 1068.91C2993.5 1067.71 2994.42 1066.23 2995.7 1065.77C2996.69 1065.41 2997.51 1065.62 2998.31 1066.34C2999.15 1067.08 2999.03 1068.13 2999.33 1069.06L2999.34 1069.05Z" fill="white"/>
                    <path d="M2965.01 939.48C2965.38 937.87 2966.14 937.26 2967.33 937.38C2967.99 937.45 2968.63 937.68 2969.29 937.79C2969.91 937.89 2970.04 938.2 2969.88 938.77C2969.7 939.41 2969.67 940.11 2969.42 940.71C2968.99 941.74 2968.1 942.28 2967.04 942.51C2966.82 942.56 2966.43 942.43 2966.33 942.26C2965.94 941.62 2965.37 941.18 2964.88 940.65C2964.64 940.39 2965 939.59 2965.01 939.47V939.48Z" fill="white"/>
                    <path d="M3089.85 948.63C3089.75 949.37 3089.63 950.44 3088.42 950.49C3087.85 950.52 3086.78 950.56 3086.72 949.85C3086.66 949.08 3085.95 948.26 3086.61 947.48C3087.07 946.94 3087.74 946.52 3088.05 945.92C3088.33 945.36 3088.55 945.28 3089.1 945.39C3089.88 945.55 3090.21 946.1 3090.24 946.76C3090.26 947.31 3090.02 947.87 3089.86 948.63H3089.85Z" fill="white"/>
                    <path d="M2945.22 933.37C2945.07 934.11 2944.36 934.61 2943.62 934.5C2943.38 934.46 2941.36 932.65 2941.32 932.43C2941.22 931.93 2942.46 930.69 2942.49 930.78C2942.92 931.9 2943.78 930.7 2944.28 931.16C2944.76 931.6 2945.32 932.9 2945.22 933.38V933.37Z" fill="white"/>
                    <path d="M2947.24 1103.25C2947.63 1103.35 2948.24 1103.38 2948.16 1103.75C2948.02 1104.44 2948.16 1105.26 2947.38 1105.77C2946.94 1106.05 2946.46 1105.87 2946.1 1106.1C2945.51 1106.47 2945.15 1106.47 2944.71 1105.86C2944.24 1105.2 2944.29 1104.5 2944.77 1104.09C2945.44 1103.52 2946.19 1102.75 2947.24 1103.25Z" fill="white"/>
                    <path d="M3033.68 1054.31C3033.85 1053.73 3033.71 1052.91 3034.82 1053.2C3035.07 1053.27 3035.44 1052.82 3035.77 1052.63C3036.14 1052.42 3036.54 1052.24 3036.92 1052.05C3036.84 1052.69 3036.97 1053.48 3036.64 1053.92C3036.21 1054.49 3035.4 1054.75 3034.78 1055.19C3034.44 1055.43 3034.08 1055.59 3033.75 1055.35C3033.39 1055.09 3033.6 1054.67 3033.67 1054.31H3033.68Z" fill="white"/>
                    <path d="M2882.63 1030.88C2882.67 1030.7 2882.69 1030.51 2882.75 1030.34C2882.9 1029.95 2882.72 1029.31 2883.39 1029.3C2884.07 1029.3 2884.59 1029.61 2884.91 1030.28C2885.18 1030.85 2884.91 1031.09 2884.5 1031.4C2884.24 1031.6 2884.15 1032.02 2883.89 1032.22C2883.55 1032.49 2883.18 1032.9 2882.67 1032.64C2882.06 1032.34 2882.49 1031.84 2882.52 1031.43C2882.53 1031.25 2882.6 1031.07 2882.64 1030.88H2882.63Z" fill="white"/>
                    <path d="M2953.12 926.6C2952.47 926.02 2951.61 925.61 2951.27 924.72C2951.19 924.52 2951.26 924.16 2951.58 924.01C2951.98 923.81 2952.36 923.29 2952.82 923.62C2953.39 924.02 2953.88 924.56 2954.35 925.09C2954.9 925.72 2953.91 925.65 2953.87 926.05C2953.83 926.47 2953.63 926.75 2953.12 926.6Z" fill="white"/>
                    <path d="M3026.47 1100.38C3026.5 1099.86 3026.73 1099.59 3027.35 1099.75C3028.3 1100.01 3028.73 1100.64 3028.58 1101.5C3028.45 1102.27 3027.88 1102.58 3027.26 1102.64C3026.54 1102.7 3026.17 1101.6 3026.48 1100.37L3026.47 1100.38Z" fill="white"/>
                    <path d="M3085.59 1162.54C3085.65 1162.26 3085.67 1161.96 3085.78 1161.71C3086.05 1161.12 3086.85 1161.08 3087.2 1161.63C3087.41 1161.95 3087.68 1162.25 3087.8 1162.6C3087.95 1163.05 3087.86 1163.61 3087.52 1163.88C3087.13 1164.19 3086.46 1163.92 3086.04 1164.34C3085.82 1163.85 3085.22 1163.5 3085.52 1162.83C3085.56 1162.75 3085.56 1162.65 3085.58 1162.55L3085.59 1162.54Z" fill="white"/>
                    <path d="M2949.16 979.46C2948.88 979.64 2948.63 979.78 2948.4 979.95C2947.83 980.37 2947.15 979.55 2946.57 980.14C2946.26 980.45 2945.84 980.35 2945.65 979.97C2945.47 979.6 2945.65 979.29 2946.02 979.07C2946.51 978.78 2946.96 978.44 2947.46 978.18C2947.7 978.06 2947.95 977.51 2948.29 978.09C2948.57 978.56 2948.88 979.01 2949.17 979.47L2949.16 979.46Z" fill="white"/>
                    <path d="M3037.59 901.17C3038.16 901.39 3038.5 902.43 3038.22 903.06C3038.11 903.31 3036.46 903.94 3036.16 903.78C3035.41 903.38 3035.95 902.79 3036.05 902.33C3036.25 901.48 3037.03 900.96 3037.58 901.17H3037.59Z" fill="white"/>
                    <path d="M3064.81 975.86C3064.22 975.82 3063.9 975.62 3064.07 974.99C3064.28 974.22 3064.99 973.57 3065.54 973.69C3066.1 973.81 3066.49 974.72 3066.31 975.5C3066.04 976.66 3065.29 975.8 3064.8 975.86H3064.81Z" fill="white"/>
                    <path d="M3069.87 980.66C3069.51 981.04 3069.13 981.47 3068.54 981.38C3067.89 981.28 3067.49 980.04 3067.82 979.33C3068.03 978.86 3068.39 978.98 3068.74 979.09C3069.54 979.35 3070.08 980.09 3069.88 980.66H3069.87Z" fill="white"/>
                    <path d="M2982.72 971.06C2982.8 970.7 2982.86 970.52 2982.88 970.33C2982.94 969.64 2983.61 969.57 2984.01 969.22C2984.22 969.7 2984.8 970.04 2984.57 970.7C2984.51 970.87 2984.47 971.06 2984.45 971.25C2984.37 971.88 2984.2 972.25 2983.39 972.06C2982.61 971.87 2982.5 971.53 2982.73 971.07L2982.72 971.06Z" fill="white"/>
                    <path d="M3138.71 1023.27C3138.05 1023.17 3138.01 1022.71 3138.22 1022.42C3138.64 1021.85 3139.17 1021.35 3139.7 1020.87C3140.19 1020.42 3140.25 1021.02 3140.44 1021.24C3141.27 1022.2 3139.94 1022.41 3139.79 1023.05C3139.61 1023.79 3139.01 1023.2 3138.71 1023.27Z" fill="white"/>
                    <path d="M3017.03 967.3C3017.01 968.09 3016.43 968.43 3015.8 968.5C3015.33 968.56 3014.52 967.21 3014.78 966.91C3015.28 966.34 3015.92 966.19 3016.67 966.43C3017.2 966.59 3017.11 966.93 3017.04 967.3H3017.03Z" fill="white"/>
                    <path d="M2897.46 1094.04C2896.94 1093.83 2896.73 1093.33 2896.6 1092.81C2896.52 1092.5 2897.96 1091.86 2898.54 1091.93C2899.07 1092 2899.04 1092.36 2898.94 1092.74C2898.75 1093.5 2898.02 1094.15 2897.45 1094.04H2897.46Z" fill="white"/>
                    <path d="M2988.86 938.59C2989.11 938.73 2989.34 938.99 2989.13 939.25C2988.61 939.86 2988.25 940.61 2987.5 941.03C2987.19 941.21 2986.87 941.16 2986.71 941.02C2986.56 940.88 2986.48 940.42 2986.59 940.28C2987.03 939.72 2987.56 939.23 2988.07 938.73C2988.29 938.52 2988.56 938.48 2988.86 938.6V938.59Z" fill="white"/>
                    <path d="M2982.48 908.19C2982.44 908.49 2982.4 908.87 2981.89 908.86C2981.45 908.86 2981.33 908.47 2981.09 908.2C2980.6 907.67 2980.91 907.12 2981.09 906.65C2981.2 906.37 2981.79 906 2981.95 906.08C2982.28 906.25 2982.59 906.67 2982.69 907.04C2982.78 907.36 2982.57 907.75 2982.47 908.18L2982.48 908.19Z" fill="white"/>
                    <path d="M3124.66 973.78C3125.13 973.81 3125.4 974.05 3125.23 974.65C3125.01 975.41 3124.31 976.04 3123.76 975.93C3122.82 975.74 3123.72 974.72 3123.05 974.35C3122.94 974.29 3123.94 973.67 3124.67 973.78H3124.66Z" fill="white"/>
                    <path d="M2952.74 1016.54C2952.61 1016.65 2952.48 1016.85 2952.34 1016.85C2951.59 1016.87 2950.55 1015.58 2950.76 1014.9C2950.83 1014.67 2951.01 1014.34 2951.19 1014.3C2951.38 1014.25 2951.71 1014.44 2951.87 1014.62C2952.56 1015.42 2952.83 1016.08 2952.74 1016.54Z" fill="white"/>
                    <path d="M2971.75 1121.57C2972.02 1121.63 2972.32 1121.65 2972.57 1121.76C2973.21 1122.05 2972.78 1122.55 2972.74 1122.96C2972.69 1123.49 2972.31 1123.46 2971.93 1123.37C2971.58 1123.29 2971.21 1123.28 2971 1122.88C2970.78 1122.48 2970.39 1122.09 2970.72 1121.63C2970.98 1121.27 2971.4 1121.51 2971.75 1121.57Z" fill="white"/>
                    <path d="M2989.78 941.65C2990.02 941.11 2990.39 941.24 2990.73 941.36C2991.54 941.63 2992.07 942.36 2991.85 942.93C2991.66 943.4 2991.27 943.27 2990.93 943.16C2990.12 942.89 2989.61 942.16 2989.78 941.66V941.65Z" fill="white"/>
                    <path d="M3126.82 966.03C3127.16 966.6 3127.61 967.05 3127.35 967.81C3127.14 968.44 3126.82 968.39 3126.32 968.29C3125.78 968.19 3125.57 968.02 3125.62 967.39C3125.7 966.57 3126.36 966.42 3126.82 966.02V966.03Z" fill="white"/>
                    <path d="M3127.48 980.42C3127.36 980.54 3127.22 980.77 3127.11 980.77C3126.74 980.75 3126.26 980.75 3126.05 980.52C3125.7 980.16 3125.21 979.74 3125.43 979.1C3125.6 978.61 3125.99 978.74 3126.34 978.85C3127.16 979.13 3127.66 979.81 3127.48 980.41V980.42Z" fill="white"/>
                    <path d="M2933.04 1124.62C2932.94 1125 2932.84 1125.4 2932.34 1125.2C2931.7 1124.94 2931.28 1124.4 2931.51 1123.73C2931.63 1123.38 2932.18 1123.17 2932.53 1122.9C2932.84 1123.43 2933.39 1123.9 2933.04 1124.61V1124.62Z" fill="white"/>
                    <path d="M3083.87 1115.16C3083.21 1114.97 3082.69 1114.05 3082.87 1113.6C3083.06 1113.13 3083.43 1113.3 3083.79 1113.36C3084.5 1113.47 3084.88 1113.96 3084.85 1114.58C3084.84 1114.77 3084.24 1115.23 3083.88 1115.16H3083.87Z" fill="white"/>
                    <path d="M3037.34 963.07C3037.24 963.54 3037.1 964.02 3036.66 963.77C3036.11 963.46 3035.67 962.95 3035.19 962.52C3035.72 962.13 3036.21 961.82 3036.95 962.08C3037.65 962.33 3037.36 962.75 3037.34 963.07Z" fill="white"/>
                    <path d="M3136.79 1066.03C3136.71 1066.81 3136.33 1066.78 3135.79 1066.7C3134.95 1066.58 3135.01 1066.14 3135.14 1065.52C3135.28 1064.88 3135.6 1064.89 3136.15 1064.98C3136.95 1065.11 3137.06 1065.5 3136.79 1066.03Z" fill="white"/>
                    <path d="M3028.73 954.42C3028.26 954.22 3027.39 954.42 3027.73 953.51C3027.92 953.01 3028.43 952.66 3029.02 952.72C3030 952.83 3029.51 953.8 3029.67 954.27C3029.87 954.86 3029.05 954.29 3028.73 954.41V954.42Z" fill="white"/>
                    <path d="M2970.49 893.32C2970.43 893.59 2970.41 893.88 2970.3 894.13C2970.1 894.59 2969.72 894.45 2969.37 894.35C2969.12 894.28 2968.78 894.35 2968.68 894C2968.57 893.61 2968.69 893.3 2969.07 893.11C2969.23 893.02 2969.38 892.91 2969.54 892.81C2969.85 892.63 2970.27 892.23 2970.44 892.32C2970.82 892.51 2970.53 892.97 2970.49 893.32Z" fill="white"/>
                    <path d="M3042.57 1150.68C3043.16 1151.02 3042.79 1151.49 3042.71 1151.89C3042.57 1152.64 3042.03 1152.29 3041.62 1152.22C3041.24 1152.16 3040.87 1152.04 3041.04 1151.52C3041.21 1150.99 3042.1 1150.5 3042.56 1150.67L3042.57 1150.68Z" fill="white"/>
                    <path d="M3080.72 1004.64C3080.24 1004.51 3079.86 1004.42 3080.03 1003.9C3080.2 1003.37 3081.1 1002.86 3081.55 1003.06C3082.01 1003.26 3081.81 1003.64 3081.78 1004C3081.71 1004.87 3081.2 1004.8 3080.72 1004.64Z" fill="white"/>
                    <path d="M3005.19 923.94C3005.36 924.03 3005.75 924.12 3005.79 924.28C3005.89 924.73 3005.38 924.81 3005.13 924.89C3004.76 925.01 3004.44 926.01 3003.91 925.05C3003.73 924.73 3003.45 924.44 3003.66 924.06C3004.07 923.29 3004.63 924.04 3005.2 923.93L3005.19 923.94Z" fill="white"/>
                    <path d="M2902.75 1030.14C2902.42 1030.44 2902 1031.14 2901.62 1030.7C2901.3 1030.32 2900.5 1030.42 2900.59 1029.75C2900.64 1029.35 2902.12 1029.09 2902.58 1029.4C2902.82 1029.56 2902.88 1029.83 2902.75 1030.14Z" fill="white"/>
                    <path d="M3119.09 1064.18C3119.09 1064.18 3119.27 1064.66 3119.36 1065.11C3119.4 1065.31 3118.19 1065.8 3117.95 1065.68C3117.31 1065.38 3117.73 1064.9 3117.77 1064.5C3117.82 1064.01 3118.15 1063.96 3119.09 1064.18Z" fill="white"/>
                    <path d="M3107.34 1095.81C3107.01 1096.41 3106.55 1096.01 3106.14 1095.97C3105.63 1095.92 3105.53 1095.49 3105.74 1095.19C3105.98 1094.84 3106.4 1094.59 3106.79 1094.37C3106.93 1094.29 3107.44 1095.58 3107.34 1095.81Z" fill="white"/>
                    <path d="M2948.39 1167.48C2947.88 1167.48 2947.74 1167.2 2947.8 1166.94C2947.96 1166.23 2948.5 1165.95 2949.19 1165.93C2949.55 1165.93 2949.69 1166.2 2949.63 1166.47C2949.49 1167.22 2948.91 1167.43 2948.38 1167.48H2948.39Z" fill="white"/>
                    <path d="M2937.26 1001.2C2937.83 1001.42 2938.19 1001.89 2938.08 1002.37C2938.03 1002.6 2937.48 1003.04 2936.94 1002.91C2936.21 1002.73 2936.55 1002.23 2936.61 1001.82C2936.66 1001.44 2936.79 1001.11 2937.27 1001.2H2937.26Z" fill="white"/>
                    <path d="M3042.52 1169.46C3042.25 1169.44 3041.35 1168.45 3041.48 1168.35C3041.84 1168.09 3042.24 1167.83 3042.66 1167.76C3043.05 1167.7 3043.34 1168 3043.18 1168.46C3043.05 1168.84 3043.23 1169.45 3042.52 1169.46Z" fill="white"/>
                    <path d="M2936.81 995.38C2936.54 995.84 2936.11 996.27 2935.37 996.09C2935.1 996.02 2934.91 995.78 2935.07 995.46C2935.37 994.84 2935.85 994.47 2936.57 994.62C2936.84 994.68 2937.02 994.92 2936.82 995.38H2936.81Z" fill="white"/>
                    <path d="M2878.79 968.34C2878.71 968.86 2878.31 968.97 2878 968.75C2877.65 968.5 2877.4 968.09 2877.18 967.7C2877.1 967.56 2878.39 967.05 2878.62 967.15C2878.95 967.3 2879.01 967.76 2878.79 968.33V968.34Z" fill="white"/>
                    <path d="M3029.41 959.08C3029.32 959.47 3029.23 959.85 3028.71 959.7C3028.15 959.53 3027.81 959.03 3027.92 958.56C3027.97 958.32 3028.52 957.89 3029.07 958.02C3029.81 958.2 3029.43 958.7 3029.42 959.09L3029.41 959.08Z" fill="white"/>
                    <path d="M2949.43 916.59C2949.08 917 2948.72 917.34 2948.14 917.28C2947.8 917.25 2947.63 916.99 2947.72 916.73C2947.88 916.3 2947.6 915.49 2948.52 915.61C2949.08 915.68 2949.08 916.29 2949.42 916.59H2949.43Z" fill="white"/>
                    <path d="M3018.4 966.38C3018.27 966.49 3018.14 966.61 3018.01 966.72C3017.55 966.04 3017.2 965.26 3016.38 964.89C3016.34 964.87 3016.41 964.51 3016.51 964.36C3016.57 964.28 3016.89 964.24 3016.91 964.28C3017.25 964.96 3018.22 965.04 3018.4 965.84C3018.44 966.01 3018.4 966.2 3018.4 966.38Z" fill="white"/>
                    <path d="M3013.94 966.41C3013.4 966.26 3013.86 965.61 3013.99 965.19C3014.26 964.36 3014.89 964.97 3015.35 965C3015.49 965 3015.92 965.42 3015.43 965.46C3014.7 965.53 3015.25 966.85 3013.93 966.4L3013.94 966.41Z" fill="white"/>
                    <path d="M2985.49 1141.63C2986.18 1141.67 2986.23 1142.35 2986.57 1142.73C2986.28 1142.93 2985.93 1143.34 2985.7 1143.29C2985.19 1143.16 2984.95 1142.68 2984.96 1142.12C2984.96 1141.78 2985.2 1141.68 2985.48 1141.63H2985.49Z" fill="white"/>
                    <path d="M3115.36 1058.66C3114.98 1058.51 3114.37 1058.63 3114.52 1057.88C3114.63 1057.34 3114.98 1057.38 3115.35 1057.49C3115.75 1057.6 3116.35 1057.52 3116.2 1058.27C3116.1 1058.82 3115.74 1058.74 3115.35 1058.67L3115.36 1058.66Z" fill="white"/>
                    <path d="M2911.4 1123.84C2910.73 1124.5 2910.12 1124.54 2909.48 1124.28C2909.33 1124.22 2909.23 1124.04 2909.11 1123.92C2909.27 1123.82 2909.45 1123.75 2909.57 1123.62C2910.05 1123.12 2910.59 1123.34 2911.1 1123.5C2911.26 1123.55 2911.35 1123.8 2911.39 1123.85L2911.4 1123.84Z" fill="white"/>
                    <path d="M3142.48 1069.73C3142.46 1069.87 3142.1 1069.86 3141.72 1069.75C3141.32 1069.63 3140.71 1069.75 3140.85 1068.98C3140.95 1068.45 3141.3 1068.47 3141.68 1068.57C3142.08 1068.68 3142.68 1068.6 3142.48 1069.72V1069.73Z" fill="white"/>
                    <path d="M3096.3 1030.29C3096.21 1030.2 3096.01 1030.09 3096.02 1030C3096.1 1029.36 3096.19 1028.74 3096.92 1028.44C3097.22 1028.32 3097.41 1028.29 3097.47 1028.6C3097.58 1029.1 3096.76 1030.26 3096.3 1030.29Z" fill="white"/>
                    <path d="M3026.38 1116.71C3025.97 1117.52 3025.4 1116.81 3024.92 1116.8C3024.7 1116.8 3024.64 1116.34 3024.78 1116.09C3025.19 1115.34 3025.77 1115.92 3026.24 1115.99C3026.46 1116.02 3026.52 1116.42 3026.38 1116.71Z" fill="white"/>
                    <path d="M3035.51 1005.97C3035.1 1006.78 3034.53 1006.07 3034.05 1006.06C3033.83 1006.06 3033.77 1005.6 3033.91 1005.35C3034.32 1004.6 3034.9 1005.18 3035.37 1005.25C3035.59 1005.28 3035.65 1005.68 3035.51 1005.97Z" fill="white"/>
                    <path d="M2915.7 1051.48C2915.68 1051.62 2915.32 1051.61 2914.94 1051.5C2914.54 1051.38 2913.93 1051.5 2914.07 1050.73C2914.17 1050.2 2914.52 1050.22 2914.9 1050.32C2915.3 1050.43 2915.9 1050.35 2915.7 1051.47V1051.48Z" fill="white"/>
                    <path d="M3062.6 935.53C3062.43 934.96 3062.26 934.37 3062.1 933.77C3062.1 933.74 3062.25 933.63 3062.32 933.63C3062.72 933.63 3062.98 933.83 3063.18 934.19C3063.41 934.59 3063.7 934.96 3063.29 935.39C3063.19 935.49 3062.97 935.46 3062.59 935.53H3062.6Z" fill="white"/>
                    <path d="M3010.4 942.75C3010.78 942.8 3011.19 942.84 3011.14 943.36C3011.08 944.03 3010.65 944.01 3010.14 943.87C3009.79 943.77 3009.36 943.77 3009.41 943.25C3009.47 942.59 3009.9 942.57 3010.4 942.75Z" fill="white"/>
                    <path d="M2956.25 963.61C2955.99 964.07 2955.81 964.27 2955.49 964.12C2954.87 963.82 2954.51 963.34 2954.65 962.62C2954.68 962.48 2954.89 962.38 2955.01 962.25C2955.44 962.72 2955.87 963.19 2956.25 963.61Z" fill="white"/>
                    <path d="M3064.75 998.42C3064.71 998.93 3064.52 999.29 3064.29 999.22C3063.83 999.08 3063.06 999.37 3063 998.51C3062.98 998.22 3063.19 997.87 3063.44 997.92C3063.92 998.01 3064.59 997.88 3064.74 998.42H3064.75Z" fill="white"/>
                    <path d="M2919.82 1070.03C2919.72 1070.41 2919.64 1070.77 2919.1 1070.63C2918.36 1070.44 2918.69 1069.94 2918.76 1069.53C2918.82 1069.15 2918.93 1068.81 2919.47 1068.94C2920.21 1069.12 2919.85 1069.63 2919.83 1070.03H2919.82Z" fill="white"/>
                    <path d="M3004.48 1088.85C3005.29 1089.26 3004.58 1089.83 3004.57 1090.31C3004.57 1090.53 3004.11 1090.59 3003.86 1090.45C3003.11 1090.04 3003.69 1089.46 3003.76 1088.99C3003.79 1088.77 3004.19 1088.71 3004.48 1088.85Z" fill="white"/>
                    <path d="M2956.42 953.17C2956.8 953.22 2957.21 953.26 2957.16 953.78C2957.1 954.45 2956.67 954.43 2956.16 954.29C2955.81 954.19 2955.38 954.19 2955.43 953.67C2955.49 953.01 2955.92 952.99 2956.42 953.17Z" fill="white"/>
                    <path d="M3114.53 973.94C3115.18 974.02 3115.3 974.25 3115.26 974.55C3115.18 975.22 3114.74 975.18 3114.26 975.03C3114.01 974.95 3113.48 974.9 3113.61 974.7C3113.86 974.31 3114.35 974.08 3114.53 973.94Z" fill="white"/>
                    <path d="M2926.09 1105.59C2926 1105.71 2925.84 1105.91 2925.68 1106.12C2925.42 1105.67 2924.88 1105.33 2925.1 1104.7C2925.2 1104.43 2925.4 1104.3 2925.74 1104.38C2926.47 1104.56 2926.14 1105.05 2926.09 1105.59Z" fill="white"/>
                    <path d="M2939.91 898.45C2939.66 898.45 2939.51 898.2 2939.54 897.92C2939.57 897.54 2939.72 897.15 2940.22 897.34C2940.57 897.47 2941.28 897.26 2941.16 897.9C2941.11 898.13 2940.55 898.57 2939.92 898.45H2939.91Z" fill="white"/>
                    <path d="M3113.64 931.29C3113.58 931.96 3112.91 932.01 3112.51 932.35C3112.47 932.1 3112.36 931.83 3112.41 931.59C3112.49 931.19 3112.41 930.6 3113.16 930.73C3113.5 930.79 3113.6 930.98 3113.64 931.29Z" fill="white"/>
                    <path d="M2936.16 930.48C2935.77 930.48 2936.16 930.58 2936.03 930.24C2935.95 930.05 2936.05 929.65 2936.2 929.54C2936.62 929.25 2937.01 928.73 2937.64 929.06C2937.71 929.1 2937.81 929.24 2937.79 929.27C2937.44 929.92 2936.68 930.03 2936.17 930.47L2936.16 930.48Z" fill="white"/>
                    <path d="M2959.87 978.39C2959.4 978.64 2959 979.19 2958.4 978.82C2958.26 978.73 2958.2 978.29 2958.29 978.09C2958.56 977.46 2959.06 977.89 2959.47 977.92C2959.75 977.94 2959.95 978.06 2959.87 978.39Z" fill="white"/>
                    <path d="M3022.01 1046C3021.97 1046.18 3021.97 1046.39 3021.87 1046.54C3021.81 1046.64 3021.55 1046.73 3021.48 1046.68C3021.22 1046.5 3021.58 1044.78 3021.9 1044.66C3021.98 1044.63 3022.23 1044.83 3022.23 1044.9C3022.19 1045.27 3022.09 1045.63 3022.01 1046Z" fill="white"/>
                    <path d="M2871.16 1008.31C2871.07 1008.89 2870.71 1008.91 2870.34 1008.78C2870.07 1008.69 2869.94 1008.48 2870.02 1008.14C2870.14 1007.62 2870.47 1007.61 2870.84 1007.74C2871.1 1007.83 2871.33 1008.03 2871.17 1008.32L2871.16 1008.31Z" fill="white"/>
                    <path d="M3117.85 949.51C3117.7 949.78 3117.54 949.92 3117.19 949.84C3116.67 949.72 3116.66 949.39 3116.79 949.02C3116.88 948.75 3117.09 948.62 3117.43 948.7C3117.95 948.82 3117.92 949.14 3117.85 949.51Z" fill="white"/>
                    <path d="M3043.37 1146.28C3043.22 1146.55 3043.06 1146.69 3042.71 1146.61C3042.19 1146.49 3042.18 1146.16 3042.31 1145.79C3042.4 1145.52 3042.61 1145.39 3042.95 1145.47C3043.47 1145.59 3043.44 1145.91 3043.37 1146.28Z" fill="white"/>
                    <path d="M2935.17 1134.07C2934.82 1133.99 2934.52 1133.87 2934.63 1133.35C2934.7 1133 2934.91 1132.87 2935.19 1132.9C2935.58 1132.94 2935.88 1133.09 2935.77 1133.61C2935.7 1133.95 2935.5 1134.05 2935.17 1134.06V1134.07Z" fill="white"/>
                    <path d="M3136.58 1049.32C3137 1049.63 3137.32 1050.01 3137.27 1050.57C3137.26 1050.72 3137.02 1050.98 3137.01 1050.97C3136.54 1050.71 3136.24 1050.32 3136.3 1049.76C3136.32 1049.61 3136.48 1049.48 3136.58 1049.32Z" fill="white"/>
                    <path d="M3078.95 1134.06C3078.95 1134.4 3078.69 1134.52 3078.4 1134.49C3078.01 1134.45 3077.71 1134.3 3077.82 1133.78C3077.89 1133.43 3078.09 1133.3 3078.38 1133.33C3078.77 1133.37 3079.09 1133.54 3078.94 1134.06H3078.95Z" fill="white"/>
                    <path d="M3078.79 1020.18C3078.64 1020.45 3078.48 1020.59 3078.13 1020.51C3077.61 1020.39 3077.6 1020.06 3077.73 1019.69C3077.82 1019.42 3078.03 1019.29 3078.37 1019.37C3078.89 1019.49 3078.86 1019.81 3078.79 1020.18Z" fill="white"/>
                    <path d="M2895.8 949.87C2895.63 950.19 2895.46 950.33 2895.12 950.25C2894.6 950.13 2894.59 949.8 2894.72 949.43C2894.81 949.16 2895.02 949.03 2895.36 949.11C2895.89 949.22 2895.82 949.57 2895.8 949.88V949.87Z" fill="white"/>
                    <path d="M2919.21 950.26C2918.67 950.18 2918.66 949.82 2918.78 949.44C2918.87 949.17 2919.08 949.04 2919.42 949.12C2919.94 949.24 2919.95 949.57 2919.82 949.94C2919.73 950.21 2919.52 950.4 2919.2 950.26H2919.21Z" fill="white"/>
                    <path d="M3155.04 1084.57C3155.13 1084.21 3155.23 1083.84 3155.74 1084C3155.9 1084.05 3156.03 1084.18 3156.17 1084.27C3155.86 1084.53 3155.54 1084.8 3155.23 1085.06C3155.17 1084.9 3155.1 1084.74 3155.04 1084.57Z" fill="white"/>
                    <path d="M3103.98 1092.42C3103.89 1092.78 3103.79 1093.15 3103.28 1092.99C3103.12 1092.94 3102.99 1092.81 3102.85 1092.72C3103.16 1092.46 3103.48 1092.19 3103.79 1091.93C3103.85 1092.09 3103.92 1092.25 3103.98 1092.42Z" fill="white"/>
                    <path d="M3098.94 960.24C3098.62 960.46 3098.31 960.66 3098.01 960.86C3097.97 960.61 3097.84 960.33 3097.91 960.11C3098.05 959.63 3098.46 959.74 3098.8 959.88C3098.89 959.92 3098.91 960.14 3098.95 960.24H3098.94Z" fill="white"/>
                    <path d="M2996.56 1087.12C2996.37 1087 2996.1 1086.88 2996.11 1086.82C2996.2 1086.33 2996.74 1086.32 2997.04 1086.04C2997.1 1086.2 2997.22 1086.37 2997.21 1086.52C2997.17 1086.91 2997.01 1087.21 2996.56 1087.12Z" fill="white"/>
                    <path d="M2953.12 1166.43C2953.02 1166.53 2952.9 1166.72 2952.8 1166.71C2952.44 1166.69 2952.02 1166.62 2952.1 1166.12C2952.14 1165.89 2952.37 1165.7 2952.51 1165.49C2952.71 1165.8 2952.91 1166.11 2953.11 1166.43H2953.12Z" fill="white"/>
                    <path d="M2974.87 1148.09C2975.38 1148.18 2975.36 1148.55 2975.24 1148.91C2975.19 1149.06 2975 1149.15 2974.88 1149.27C2974.71 1148.9 2974.53 1148.53 2974.36 1148.16C2974.53 1148.14 2974.7 1148.12 2974.87 1148.09Z" fill="white"/>
                    <path d="M3084.85 978.79C3084.8 978.85 3084.69 979.04 3084.6 979.04C3084.24 979.02 3083.82 978.96 3083.88 978.46C3083.91 978.23 3084.14 978.03 3084.28 977.82C3084.46 978.13 3084.64 978.45 3084.84 978.79H3084.85Z" fill="white"/>
                    <path d="M3075.31 1014.63C3075.22 1015.14 3074.85 1015.12 3074.49 1015C3074.34 1014.95 3074.25 1014.76 3074.13 1014.64C3074.5 1014.47 3074.87 1014.29 3075.24 1014.12C3075.26 1014.29 3075.28 1014.46 3075.31 1014.63Z" fill="white"/>
                    <path d="M3017.41 1168.15C3017.29 1168.28 3017.17 1168.4 3017.05 1168.53C3016.86 1168.17 3016.67 1167.81 3016.48 1167.45C3016.65 1167.42 3016.82 1167.35 3016.98 1167.37C3017.49 1167.42 3017.49 1167.77 3017.41 1168.15Z" fill="white"/>
                    <path d="M2921.67 968.41C2922.18 968.5 2922.16 968.87 2922.04 969.23C2921.99 969.38 2921.8 969.47 2921.68 969.59C2921.51 969.22 2921.33 968.85 2921.16 968.48C2921.33 968.46 2921.5 968.44 2921.67 968.41Z" fill="white"/>
                    <path d="M2882.78 962.16C2882.86 962.39 2882.97 962.56 2882.95 962.71C2882.91 963.11 2882.73 963.41 2882.25 963.25C2882.1 963.2 2881.97 963.06 2881.83 962.97C2882.14 962.71 2882.45 962.45 2882.78 962.17V962.16Z" fill="white"/>
                    <path d="M3086.34 902.88C3086.08 903.17 3085.8 902.98 3085.56 902.91C3085.48 902.89 3085.39 902.59 3085.44 902.52C3085.66 902.23 3085.95 902.43 3086.2 902.5C3086.28 902.52 3086.3 902.76 3086.35 902.88H3086.34Z" fill="white"/>
                    <path d="M3094.48 926.54C3094.5 926.86 3094.57 927.12 3094.52 927.36C3094.49 927.5 3094.29 927.61 3094.16 927.73C3094.12 927.48 3094.03 927.22 3094.05 926.98C3094.06 926.85 3094.28 926.74 3094.47 926.55L3094.48 926.54Z" fill="white"/>
                    <path d="M2882.84 1064.18C2882.58 1064.47 2882.3 1064.28 2882.06 1064.21C2881.98 1064.19 2881.89 1063.89 2881.94 1063.82C2882.16 1063.53 2882.45 1063.73 2882.7 1063.8C2882.78 1063.82 2882.8 1064.06 2882.85 1064.18H2882.84Z" fill="white"/>
                    <path d="M2947.73 1148.92C2947.41 1148.94 2947.15 1149.01 2946.91 1148.96C2946.77 1148.93 2946.66 1148.73 2946.54 1148.6C2946.79 1148.56 2947.05 1148.47 2947.29 1148.49C2947.42 1148.5 2947.53 1148.72 2947.72 1148.91L2947.73 1148.92Z" fill="white"/>
                    <path d="M2950.8 1149.21C2950.66 1149.17 2950.39 1149.13 2950.18 1149.01C2949.93 1148.87 2949.97 1148.66 2950.26 1148.65C2950.5 1148.64 2950.78 1148.7 2950.99 1148.81C2951.24 1148.94 2951.2 1149.11 2950.8 1149.21Z" fill="white"/>
                    <path d="M2872.27 1077.92C2872.41 1078.02 2872.67 1078.12 2872.67 1078.21C2872.65 1078.55 2872.39 1078.57 2872.13 1078.47C2871.98 1078.41 2871.73 1078.28 2871.73 1078.18C2871.75 1077.84 2872 1077.83 2872.27 1077.92Z" fill="white"/>
                    <path d="M3112.84 953.58C3112.74 953.72 3112.64 953.98 3112.55 953.98C3112.21 953.96 3112.19 953.7 3112.29 953.44C3112.35 953.29 3112.48 953.04 3112.58 953.04C3112.92 953.06 3112.93 953.31 3112.84 953.58Z" fill="white"/>
                    <path d="M3023.68 1159.94C3023.7 1160.26 3023.77 1160.52 3023.72 1160.76C3023.69 1160.9 3023.49 1161.01 3023.36 1161.13C3023.32 1160.88 3023.23 1160.62 3023.25 1160.38C3023.26 1160.25 3023.48 1160.14 3023.67 1159.95L3023.68 1159.94Z" fill="white"/>
                    <path d="M2963.66 916.19C2963.57 916.28 2963.45 916.4 2963.32 916.52C2963.28 916.27 2963.19 916.01 2963.21 915.77C2963.22 915.64 2963.44 915.53 2963.56 915.4C2963.74 915.63 2963.8 915.88 2963.65 916.19H2963.66Z" fill="white"/>
                    <path d="M3066.96 1167.21C3066.68 1167.17 3066.45 1167.06 3066.58 1166.74C3066.61 1166.66 3066.9 1166.64 3067.06 1166.65C3067.34 1166.66 3067.57 1166.79 3067.44 1167.11C3067.41 1167.19 3067.12 1167.18 3066.96 1167.21Z" fill="white"/>
                    <path d="M2954.22 953.86C2953.94 953.82 2953.71 953.71 2953.84 953.39C2953.87 953.31 2954.16 953.29 2954.32 953.3C2954.6 953.31 2954.83 953.44 2954.7 953.76C2954.67 953.84 2954.38 953.83 2954.22 953.86Z" fill="white"/>
                    <path d="M2960.77 964.11C2960.49 964.07 2960.26 963.96 2960.39 963.64C2960.42 963.56 2960.71 963.54 2960.87 963.55C2961.15 963.56 2961.38 963.69 2961.25 964.01C2961.22 964.09 2960.93 964.08 2960.77 964.11Z" fill="white"/>
                    <path d="M2990.04 998.08C2990.06 998.4 2990.13 998.66 2990.08 998.9C2990.05 999.04 2989.85 999.15 2989.72 999.27C2989.68 999.02 2989.59 998.76 2989.61 998.52C2989.62 998.39 2989.84 998.28 2990.03 998.09L2990.04 998.08Z" fill="white"/>
                    <path d="M3090.85 970.97C3090.71 971.14 3090.55 971.33 3090.39 971.53C3090.33 971.37 3090.18 971.18 3090.22 971.05C3090.3 970.82 3090.49 970.62 3090.63 970.41C3090.69 970.57 3090.75 970.73 3090.85 970.97Z" fill="white"/>
                    <path d="M3048.32 1051.71C3048.23 1051.8 3048.11 1051.92 3047.98 1052.04C3047.94 1051.79 3047.85 1051.53 3047.87 1051.29C3047.88 1051.16 3048.1 1051.05 3048.22 1050.92C3048.4 1051.15 3048.46 1051.4 3048.31 1051.71H3048.32Z" fill="white"/>
                    <path d="M3089.7 1068.19C3089.72 1068.51 3089.79 1068.77 3089.74 1069.01C3089.71 1069.15 3089.51 1069.26 3089.38 1069.38C3089.34 1069.13 3089.25 1068.87 3089.27 1068.63C3089.28 1068.5 3089.5 1068.39 3089.69 1068.2L3089.7 1068.19Z" fill="white"/>
                    <path d="M3154.74 1093.54C3154.48 1093.83 3154.2 1093.64 3153.96 1093.57C3153.88 1093.55 3153.79 1093.25 3153.84 1093.18C3154.06 1092.89 3154.35 1093.09 3154.6 1093.16C3154.68 1093.18 3154.7 1093.42 3154.75 1093.54H3154.74Z" fill="white"/>
                    <path d="M3093.97 1108.56C3093.69 1108.52 3093.46 1108.41 3093.59 1108.09C3093.62 1108.01 3093.91 1107.99 3094.07 1108C3094.35 1108.01 3094.58 1108.14 3094.45 1108.46C3094.42 1108.54 3094.13 1108.53 3093.97 1108.56Z" fill="white"/>
                    <path d="M2899.23 1056.85C2899.37 1056.95 2899.63 1057.05 2899.63 1057.14C2899.61 1057.48 2899.35 1057.5 2899.09 1057.4C2898.94 1057.34 2898.69 1057.21 2898.69 1057.11C2898.71 1056.77 2898.96 1056.76 2899.23 1056.85Z" fill="white"/>
                    <path d="M2992.6 1062.16C2992.5 1062.3 2992.4 1062.56 2992.31 1062.56C2991.97 1062.54 2991.95 1062.28 2992.05 1062.02C2992.11 1061.87 2992.24 1061.62 2992.34 1061.62C2992.68 1061.64 2992.69 1061.89 2992.6 1062.16Z" fill="white"/>
                    <path d="M2990.24 1076.9C2990.27 1077.04 2990.33 1077.18 2990.33 1077.31C2990.33 1077.36 2990.19 1077.41 2990.11 1077.46C2990.08 1077.32 2990.02 1077.18 2990.02 1077.05C2990.02 1077 2990.16 1076.95 2990.24 1076.9Z" fill="white"/>
                    <path d="M3018.42 1178.24C3018.45 1178.38 3018.51 1178.52 3018.51 1178.65C3018.51 1178.7 3018.37 1178.75 3018.29 1178.8C3018.26 1178.66 3018.2 1178.52 3018.2 1178.39C3018.2 1178.34 3018.34 1178.29 3018.42 1178.24Z" fill="white"/>
                    <path d="M2971.22 969.56C2971.13 969.66 2971.04 969.77 2970.94 969.88C2970.89 969.8 2970.79 969.7 2970.81 969.66C2970.87 969.53 2970.98 969.43 2971.07 969.32C2971.12 969.4 2971.17 969.47 2971.23 969.56H2971.22Z" fill="white"/>
                    <path d="M2932.22 947.46C2932.31 947.4 2932.39 947.35 2932.46 947.3C2932.49 947.44 2932.55 947.58 2932.55 947.71C2932.55 947.76 2932.41 947.81 2932.33 947.86C2932.29 947.72 2932.25 947.59 2932.21 947.46H2932.22Z" fill="white"/>
                    <path d="M3014.02 964.01C3013.92 963.92 3013.81 963.83 3013.7 963.73C3013.78 963.68 3013.88 963.58 3013.92 963.6C3014.05 963.66 3014.15 963.77 3014.26 963.86C3014.18 963.91 3014.11 963.96 3014.02 964.02V964.01Z" fill="white"/>
                    <path d="M3036.91 1002.32C3036.77 1002.35 3036.63 1002.41 3036.5 1002.41C3036.45 1002.41 3036.4 1002.27 3036.35 1002.19C3036.49 1002.16 3036.63 1002.1 3036.76 1002.1C3036.81 1002.1 3036.86 1002.24 3036.91 1002.32Z" fill="white"/>
                    <path d="M3081.45 1017.04C3081.39 1016.95 3081.34 1016.87 3081.29 1016.8C3081.43 1016.77 3081.57 1016.71 3081.7 1016.71C3081.75 1016.71 3081.8 1016.85 3081.85 1016.93C3081.71 1016.97 3081.58 1017.01 3081.45 1017.05V1017.04Z" fill="white"/>
                    <path d="M3111.44 967.56C3111.35 967.66 3111.26 967.77 3111.16 967.88C3111.11 967.8 3111.01 967.7 3111.03 967.66C3111.09 967.53 3111.2 967.43 3111.29 967.32C3111.34 967.4 3111.39 967.47 3111.45 967.56H3111.44Z" fill="white"/>
                    <path d="M2996.05 958.17C2995.96 958.23 2995.88 958.28 2995.81 958.33C2995.78 958.19 2995.72 958.05 2995.72 957.92C2995.72 957.87 2995.86 957.82 2995.94 957.77C2995.98 957.91 2996.02 958.04 2996.06 958.17H2996.05Z" fill="white"/>
                    <path d="M3018.77 1026.43C3018.86 1026.37 3018.94 1026.32 3019.01 1026.27C3019.04 1026.41 3019.1 1026.55 3019.1 1026.68C3019.1 1026.73 3018.96 1026.78 3018.88 1026.83C3018.84 1026.69 3018.8 1026.56 3018.76 1026.43H3018.77Z" fill="white"/>
                    <path d="M3151.56 1067.27C3151.61 1067.35 3151.71 1067.47 3151.69 1067.49C3151.6 1067.6 3151.49 1067.73 3151.36 1067.76C3151.1 1067.82 3151.01 1067.68 3151.18 1067.47C3151.26 1067.37 3151.43 1067.33 3151.56 1067.27Z" fill="white"/>
                    <path d="M3151.06 1067.96C3150.93 1068.02 3150.75 1068.17 3150.68 1068.13C3150.48 1068.01 3150.48 1067.84 3150.65 1067.78C3150.8 1067.73 3150.96 1067.73 3151.12 1067.71C3151.1 1067.79 3151.08 1067.88 3151.07 1067.96H3151.06Z" fill="white"/>
                    <path d="M2962.23 1085.03C2962.14 1085.13 2962.05 1085.24 2961.95 1085.35C2961.9 1085.27 2961.8 1085.17 2961.82 1085.13C2961.88 1085 2961.99 1084.9 2962.08 1084.79C2962.13 1084.87 2962.18 1084.94 2962.24 1085.03H2962.23Z" fill="white"/>
                    <path d="M3169.01 986.12C3168.92 986.22 3168.83 986.33 3168.73 986.44C3168.68 986.36 3168.58 986.26 3168.6 986.22C3168.66 986.09 3168.77 985.99 3168.86 985.88C3168.91 985.96 3168.96 986.03 3169.02 986.12H3169.01Z" fill="white"/>
                    <path d="M3156.26 1073.33C3156.17 1073.39 3156.09 1073.44 3156.02 1073.49C3155.99 1073.35 3155.93 1073.21 3155.93 1073.08C3155.93 1073.03 3156.07 1072.98 3156.15 1072.93C3156.19 1073.07 3156.23 1073.2 3156.27 1073.33H3156.26Z" fill="white"/>
                    <path d="M3130.78 968.47C3130.87 968.37 3130.96 968.26 3131.06 968.15C3131.11 968.23 3131.21 968.33 3131.19 968.37C3131.13 968.5 3131.02 968.6 3130.93 968.71C3130.88 968.63 3130.83 968.56 3130.77 968.47H3130.78Z" fill="white"/>
                    <path d="M3056.66 1088.22C3056.57 1088.32 3056.48 1088.43 3056.38 1088.54C3056.33 1088.46 3056.23 1088.36 3056.25 1088.32C3056.31 1088.19 3056.42 1088.09 3056.51 1087.98C3056.56 1088.06 3056.61 1088.13 3056.67 1088.22H3056.66Z" fill="white"/>
                    <path d="M3043.27 955.77C3043.21 955.68 3043.16 955.6 3043.11 955.53C3043.25 955.5 3043.39 955.44 3043.52 955.44C3043.57 955.44 3043.62 955.58 3043.67 955.66C3043.53 955.7 3043.4 955.74 3043.27 955.78V955.77Z" fill="white"/>
                    <path d="M3123.18 1078.58C3123.21 1078.72 3123.27 1078.86 3123.27 1078.99C3123.27 1079.04 3123.13 1079.09 3123.05 1079.14C3123.02 1079 3122.96 1078.86 3122.96 1078.73C3122.96 1078.68 3123.1 1078.63 3123.18 1078.58Z" fill="white"/>
                    <path d="M3055.86 955.91C3055.77 956.01 3055.68 956.12 3055.58 956.23C3055.53 956.15 3055.43 956.05 3055.45 956.01C3055.51 955.88 3055.62 955.78 3055.71 955.67L3055.87 955.91H3055.86Z" fill="white"/>
                    <path d="M3000.49 1053.99C3000.59 1054.08 3000.7 1054.17 3000.81 1054.27C3000.73 1054.32 3000.63 1054.43 3000.59 1054.4C3000.46 1054.34 3000.36 1054.23 3000.25 1054.14C3000.33 1054.09 3000.4 1054.04 3000.49 1053.98V1053.99Z" fill="white"/>
                    <path d="M2870.69 1086.38C2870.72 1086.52 2870.78 1086.66 2870.78 1086.79C2870.78 1086.84 2870.64 1086.89 2870.56 1086.94C2870.53 1086.8 2870.47 1086.66 2870.47 1086.53C2870.47 1086.48 2870.61 1086.43 2870.69 1086.38Z" fill="white"/>
                    <path d="M2783.54 1121.8C2782.27 1120.45 2782.79 1119.11 2783.31 1117.85C2783.5 1117.39 2783.9 1116.72 2783.91 1116.45C2783.98 1114.7 2785.82 1115.66 2786.32 1114.72C2787.16 1115.35 2788.37 1115.83 2788.72 1116.66C2789.05 1117.46 2789.39 1118.49 2789.33 1119.45C2789.28 1120.36 2789.61 1121.66 2787.87 1121.45C2787.61 1121.42 2787.31 1121.91 2787 1122.12C2785.63 1123.07 2784.46 1122.92 2783.54 1121.79V1121.8Z" fill="white"/>
                    <path d="M2933.3 1124.66C2932.44 1125.34 2931.29 1125.15 2931.05 1124.84C2930.14 1123.66 2928.7 1124.05 2927.63 1123.4C2926.87 1122.94 2926.49 1122.55 2926.63 1121.7C2926.87 1120.31 2927.29 1119.82 2928.32 1119.78C2928.77 1119.76 2929.19 1119.69 2929.63 1119.61C2931.53 1119.26 2934.48 1121.82 2933.71 1123.9C2933.57 1124.27 2933.47 1124.58 2933.3 1124.66Z" fill="white"/>
                    <path d="M2866.1 1065.67C2865.14 1065.54 2864.19 1066.57 2863.28 1065.6C2863.15 1065.46 2863.07 1065.24 2862.91 1065.17C2862.27 1064.88 2861.47 1064.79 2861.3 1063.91C2861.16 1063.22 2861.63 1062.71 2861.89 1062.15C2862.39 1061.04 2864.02 1060.45 2865.3 1060.87C2866.29 1061.2 2866.8 1061.87 2866.98 1062.92C2867.18 1064.01 2866.44 1064.76 2866.1 1065.66V1065.67Z" fill="white"/>
                    <path d="M2919.16 943.72C2920.44 942.69 2921.4 942.69 2922.26 943.52C2922.73 943.98 2923.09 944.55 2923.53 945.04C2923.95 945.5 2923.86 945.82 2923.38 946.16C2922.84 946.55 2922.4 947.07 2921.83 947.39C2920.86 947.92 2919.83 947.8 2918.87 947.32C2918.67 947.22 2918.45 946.89 2918.47 946.69C2918.56 945.95 2918.39 945.26 2918.33 944.55C2918.3 944.2 2919.07 943.8 2919.16 943.71V943.72Z" fill="white"/>
                    <path d="M3010.66 1027.69C3010.12 1028.21 3009.37 1028.96 3008.4 1028.26C3007.94 1027.93 3007.08 1027.31 3007.47 1026.72C3007.9 1026.08 3007.85 1025.01 3008.85 1024.81C3009.54 1024.67 3010.32 1024.76 3010.93 1024.48C3011.49 1024.22 3011.71 1024.29 3012.07 1024.71C3012.58 1025.32 3012.49 1025.95 3012.11 1026.48C3011.79 1026.92 3011.26 1027.21 3010.66 1027.7V1027.69Z" fill="white"/>
                    <path d="M2907.52 926.78C2906.95 927.26 2906.08 927.22 2905.58 926.67C2905.41 926.49 2904.96 923.84 2905.06 923.64C2905.29 923.19 2907.02 922.99 2906.99 923.08C2906.64 924.22 2908.04 923.81 2908.15 924.48C2908.25 925.11 2907.88 926.47 2907.52 926.78Z" fill="white"/>
                    <path d="M2804.51 1060.21C2804.75 1060.52 2805.21 1060.92 2804.92 1061.17C2804.39 1061.62 2804 1062.35 2803.07 1062.26C2802.56 1062.21 2802.29 1061.77 2801.87 1061.73C2801.19 1061.65 2800.9 1061.44 2800.94 1060.69C2800.98 1059.89 2801.45 1059.37 2802.07 1059.35C2802.94 1059.32 2804 1059.18 2804.51 1060.22V1060.21Z" fill="white"/>
                    <path d="M2901.9 1075.34C2902.38 1074.99 2902.79 1074.27 2903.47 1075.17C2903.62 1075.37 2904.19 1075.25 2904.56 1075.31C2904.98 1075.38 2905.4 1075.48 2905.81 1075.57C2905.36 1076.02 2904.98 1076.71 2904.44 1076.85C2903.76 1077.03 2902.96 1076.73 2902.21 1076.7C2901.8 1076.68 2901.42 1076.58 2901.31 1076.19C2901.19 1075.76 2901.61 1075.57 2901.89 1075.34H2901.9Z" fill="white"/>
                    <path d="M2798.79 964.13C2798.93 964.01 2799.07 963.88 2799.22 963.78C2799.57 963.57 2799.83 962.96 2800.36 963.36C2800.9 963.77 2801.1 964.35 2800.94 965.06C2800.8 965.67 2800.44 965.68 2799.93 965.67C2799.61 965.67 2799.28 965.93 2798.95 965.94C2798.52 965.94 2797.98 966.03 2797.74 965.52C2797.45 964.91 2798.09 964.79 2798.36 964.49C2798.48 964.35 2798.64 964.25 2798.79 964.14V964.13Z" fill="white"/>
                    <path d="M2917.83 926.39C2917.68 925.53 2917.27 924.69 2917.54 923.79C2917.6 923.59 2917.87 923.35 2918.22 923.43C2918.65 923.53 2919.27 923.35 2919.43 923.89C2919.63 924.56 2919.68 925.27 2919.72 925.97C2919.77 926.8 2919.03 926.13 2918.75 926.42C2918.47 926.72 2918.13 926.82 2917.83 926.39Z" fill="white"/>
                    <path d="M2867.93 1106.75C2868.27 1106.36 2868.62 1106.3 2869 1106.8C2869.58 1107.59 2869.53 1108.34 2868.88 1108.92C2868.3 1109.44 2867.67 1109.33 2867.15 1108.99C2866.55 1108.6 2866.95 1107.51 2867.94 1106.74L2867.93 1106.75Z" fill="white"/>
                    <path d="M2875.66 1191.51C2875.88 1191.33 2876.08 1191.11 2876.32 1190.98C2876.9 1190.69 2877.54 1191.15 2877.48 1191.79C2877.44 1192.17 2877.48 1192.56 2877.35 1192.91C2877.19 1193.35 2876.77 1193.74 2876.35 1193.74C2875.86 1193.74 2875.5 1193.12 2874.92 1193.19C2875.05 1192.67 2874.8 1192.03 2875.45 1191.69C2875.53 1191.65 2875.6 1191.57 2875.67 1191.51H2875.66Z" fill="white"/>
                    <path d="M2882.21 965.08C2881.88 965.05 2881.6 965 2881.32 964.99C2880.61 964.97 2880.59 963.91 2879.78 964.01C2879.35 964.06 2879.08 963.73 2879.17 963.31C2879.25 962.91 2879.59 962.78 2880.01 962.83C2880.56 962.91 2881.13 962.91 2881.68 963.02C2881.94 963.07 2882.47 962.8 2882.38 963.46C2882.31 964 2882.27 964.54 2882.21 965.08Z" fill="white"/>
                    <path d="M2999.21 958.59C2999.52 959.11 2999.15 960.13 2998.54 960.45C2998.3 960.58 2996.63 960.05 2996.5 959.75C2996.16 958.98 2996.95 958.85 2997.31 958.56C2997.98 958.02 2998.92 958.1 2999.22 958.6L2999.21 958.59Z" fill="white"/>
                    <path d="M2974.41 1033.46C2973.98 1033.07 2973.85 1032.72 2974.37 1032.33C2975.01 1031.86 2975.96 1031.79 2976.32 1032.23C2976.68 1032.67 2976.42 1033.62 2975.8 1034.11C2974.88 1034.85 2974.82 1033.72 2974.41 1033.47V1033.46Z" fill="white"/>
                    <path d="M2975.4 1040.31C2974.89 1040.38 2974.32 1040.49 2973.92 1040.05C2973.48 1039.58 2973.92 1038.36 2974.62 1038.01C2975.07 1037.78 2975.28 1038.09 2975.48 1038.39C2975.95 1039.09 2975.91 1040 2975.4 1040.31Z" fill="white"/>
                    <path d="M2913.49 979.2C2913.78 978.97 2913.93 978.86 2914.06 978.73C2914.54 978.23 2915.1 978.59 2915.62 978.56C2915.49 979.07 2915.73 979.69 2915.15 980.05C2914.99 980.15 2914.85 980.27 2914.72 980.4C2914.27 980.84 2913.91 981.02 2913.4 980.37C2912.91 979.75 2913.04 979.41 2913.5 979.19L2913.49 979.2Z" fill="white"/>
                    <path d="M3002.72 1115.84C3002.27 1115.35 3002.52 1114.97 3002.86 1114.88C3003.54 1114.7 3004.26 1114.64 3004.96 1114.59C3005.62 1114.54 3005.29 1115.04 3005.31 1115.33C3005.37 1116.59 3004.2 1115.93 3003.69 1116.33C3003.1 1116.79 3002.99 1115.97 3002.71 1115.84H3002.72Z" fill="white"/>
                    <path d="M2942.5 997.39C2941.99 997.99 2941.34 997.9 2940.81 997.57C2940.41 997.32 2940.61 995.77 2940.99 995.7C2941.73 995.57 2942.31 995.84 2942.75 996.49C2943.06 996.94 2942.79 997.15 2942.5 997.39Z" fill="white"/>
                    <path d="M2771.44 1022.4C2771.17 1021.91 2771.31 1021.4 2771.53 1020.91C2771.66 1020.62 2773.17 1021.01 2773.58 1021.43C2773.95 1021.81 2773.7 1022.08 2773.4 1022.31C2772.78 1022.78 2771.82 1022.84 2771.44 1022.4Z" fill="white"/>
                    <path d="M2938.26 957.72C2938.37 957.99 2938.39 958.33 2938.06 958.39C2937.28 958.55 2936.54 958.9 2935.7 958.78C2935.35 958.73 2935.13 958.5 2935.1 958.29C2935.07 958.09 2935.29 957.68 2935.46 957.64C2936.15 957.48 2936.86 957.42 2937.56 957.34C2937.86 957.31 2938.09 957.45 2938.26 957.72Z" fill="white"/>
                    <path d="M2952.01 930.13C2951.79 930.34 2951.53 930.61 2951.14 930.29C2950.8 930.01 2950.95 929.64 2950.92 929.29C2950.87 928.58 2951.45 928.34 2951.87 928.08C2952.12 927.93 2952.82 928 2952.89 928.17C2953.04 928.51 2953.02 929.02 2952.87 929.38C2952.74 929.68 2952.33 929.86 2952 930.13H2952.01Z" fill="white"/>
                    <path d="M3022.26 1068.69C3022.61 1069 3022.67 1069.35 3022.16 1069.72C3021.52 1070.18 3020.59 1070.24 3020.23 1069.81C3019.62 1069.09 3020.94 1068.84 3020.65 1068.14C3020.6 1068.02 3021.76 1068.16 3022.26 1068.69Z" fill="white"/>
                    <path d="M2862.17 996.13C2862 996.13 2861.77 996.21 2861.67 996.13C2861.08 995.68 2861.06 994.04 2861.64 993.64C2861.84 993.5 2862.18 993.36 2862.35 993.44C2862.53 993.52 2862.67 993.87 2862.68 994.1C2862.73 995.14 2862.53 995.83 2862.17 996.13Z" fill="white"/>
                    <path d="M2812.31 1089.55C2812.49 1089.77 2812.71 1089.96 2812.83 1090.2C2813.15 1090.82 2812.51 1090.94 2812.23 1091.24C2811.86 1091.62 2811.59 1091.37 2811.34 1091.06C2811.12 1090.78 2810.84 1090.55 2810.92 1090.11C2811 1089.66 2810.92 1089.12 2811.47 1088.97C2811.89 1088.85 2812.07 1089.29 2812.31 1089.56V1089.55Z" fill="white"/>
                    <path d="M2937.09 960.66C2937.61 960.38 2937.82 960.72 2938.01 961.02C2938.48 961.73 2938.44 962.63 2937.91 962.93C2937.48 963.18 2937.25 962.84 2937.05 962.54C2936.58 961.83 2936.64 960.95 2937.08 960.67L2937.09 960.66Z" fill="white"/>
                    <path d="M3028.71 1063.98C3028.62 1064.63 3028.69 1065.26 3028.02 1065.69C3027.47 1066.05 3027.25 1065.81 3026.92 1065.43C3026.57 1065.02 3026.5 1064.76 3026.93 1064.3C3027.5 1063.71 3028.1 1064 3028.71 1063.97V1063.98Z" fill="white"/>
                    <path d="M3020.36 1075.59C3020.19 1075.61 3019.94 1075.71 3019.86 1075.63C3019.59 1075.39 3019.22 1075.09 3019.18 1074.78C3019.13 1074.28 3019.01 1073.66 3019.57 1073.3C3020 1073.02 3020.23 1073.37 3020.43 1073.67C3020.9 1074.39 3020.86 1075.23 3020.36 1075.59Z" fill="white"/>
                    <path d="M2780.31 1068.1C2780 1068.33 2779.67 1068.59 2779.41 1068.12C2779.07 1067.52 2779.07 1066.85 2779.67 1066.47C2779.98 1066.27 2780.53 1066.45 2780.97 1066.45C2780.88 1067.05 2781.03 1067.76 2780.31 1068.09V1068.1Z" fill="white"/>
                    <path d="M2903.5 1153.58C2903.11 1153.03 2903.27 1151.99 2903.68 1151.75C2904.11 1151.5 2904.3 1151.86 2904.54 1152.13C2905.02 1152.66 2905.02 1153.27 2904.61 1153.73C2904.49 1153.87 2903.73 1153.86 2903.5 1153.58Z" fill="white"/>
                    <path d="M2960.91 1006.6C2960.54 1006.9 2960.14 1007.19 2959.95 1006.72C2959.71 1006.14 2959.69 1005.47 2959.57 1004.84C2960.22 1004.86 2960.8 1004.92 2961.21 1005.58C2961.6 1006.2 2961.12 1006.36 2960.91 1006.59V1006.6Z" fill="white"/>
                    <path d="M2974.91 1147.93C2974.36 1148.49 2974.09 1148.23 2973.72 1147.84C2973.14 1147.23 2973.46 1146.93 2973.94 1146.52C2974.44 1146.11 2974.69 1146.31 2975.06 1146.72C2975.6 1147.31 2975.44 1147.68 2974.91 1147.93Z" fill="white"/>
                    <path d="M2959.53 994.57C2959.29 994.13 2958.49 993.75 2959.31 993.25C2959.76 992.98 2960.38 993.02 2960.8 993.44C2961.5 994.13 2960.51 994.58 2960.35 995.04C2960.14 995.62 2959.86 994.67 2959.53 994.57Z" fill="white"/>
                    <path d="M2951.83 911.18C2951.62 911.35 2951.42 911.57 2951.18 911.7C2950.74 911.94 2950.53 911.59 2950.32 911.3C2950.17 911.09 2949.86 910.93 2949.99 910.6C2950.14 910.23 2950.43 910.07 2950.84 910.14C2951.02 910.17 2951.21 910.17 2951.39 910.2C2951.74 910.25 2952.31 910.2 2952.39 910.37C2952.57 910.76 2952.06 910.93 2951.82 911.18H2951.83Z" fill="white"/>
                    <path d="M2849.49 1155.79C2849.74 1156.42 2849.16 1156.55 2848.85 1156.82C2848.28 1157.32 2848.08 1156.71 2847.8 1156.41C2847.54 1156.13 2847.33 1155.81 2847.78 1155.51C2848.24 1155.2 2849.23 1155.37 2849.49 1155.79Z" fill="white"/>
                    <path d="M2969.07 1065.65C2968.77 1065.26 2968.53 1064.95 2968.99 1064.65C2969.45 1064.34 2970.46 1064.5 2970.69 1064.93C2970.92 1065.37 2970.53 1065.54 2970.29 1065.8C2969.7 1066.44 2969.35 1066.06 2969.07 1065.65Z" fill="white"/>
                    <path d="M2959.98 956.37C2960.06 956.55 2960.31 956.85 2960.23 957.01C2960.03 957.42 2959.58 957.17 2959.34 957.08C2958.97 956.94 2958.11 957.52 2958.29 956.46C2958.35 956.11 2958.31 955.71 2958.7 955.54C2959.5 955.19 2959.47 956.12 2959.98 956.39V956.37Z" fill="white"/>
                    <path d="M2814.9 975.94C2814.46 975.97 2813.7 976.26 2813.67 975.68C2813.65 975.19 2812.97 974.78 2813.45 974.31C2813.74 974.03 2815.05 974.74 2815.21 975.26C2815.3 975.53 2815.18 975.78 2814.89 975.94H2814.9Z" fill="white"/>
                    <path d="M2962.28 1135.6C2962.28 1135.6 2962.13 1136.08 2961.92 1136.49C2961.83 1136.67 2960.59 1136.3 2960.47 1136.07C2960.15 1135.44 2960.78 1135.33 2961.06 1135.04C2961.4 1134.69 2961.69 1134.85 2962.29 1135.61L2962.28 1135.6Z" fill="white"/>
                    <path d="M2933.66 1152.98C2933.04 1153.24 2932.92 1152.65 2932.63 1152.36C2932.26 1152 2932.45 1151.62 2932.8 1151.51C2933.21 1151.39 2933.68 1151.45 2934.12 1151.51C2934.28 1151.53 2933.88 1152.85 2933.66 1152.97V1152.98Z" fill="white"/>
                    <path d="M2765.87 1110.9C2765.48 1110.58 2765.54 1110.28 2765.75 1110.11C2766.32 1109.66 2766.91 1109.77 2767.45 1110.18C2767.73 1110.39 2767.67 1110.69 2767.46 1110.87C2766.89 1111.36 2766.31 1111.17 2765.87 1110.89V1110.9Z" fill="white"/>
                    <path d="M2859.57 974.67C2859.88 975.19 2859.87 975.78 2859.49 976.08C2859.31 976.23 2858.61 976.23 2858.27 975.79C2857.81 975.2 2858.39 975.02 2858.68 974.74C2858.96 974.48 2859.26 974.3 2859.57 974.67Z" fill="white"/>
                    <path d="M2837.89 1170.38C2837.69 1170.2 2837.6 1168.87 2837.76 1168.88C2838.2 1168.9 2838.68 1168.94 2839.05 1169.15C2839.39 1169.34 2839.43 1169.75 2839.02 1170.01C2838.68 1170.23 2838.45 1170.81 2837.89 1170.38Z" fill="white"/>
                    <path d="M2862.8 969.86C2862.31 970.05 2861.7 970.12 2861.24 969.52C2861.07 969.3 2861.07 969 2861.39 968.84C2862.01 968.54 2862.61 968.55 2863.07 969.11C2863.24 969.32 2863.24 969.62 2862.79 969.85L2862.8 969.86Z" fill="white"/>
                    <path d="M2834.3 913.1C2833.92 913.45 2833.54 913.29 2833.43 912.93C2833.31 912.52 2833.37 912.05 2833.43 911.61C2833.45 911.45 2834.77 911.85 2834.89 912.07C2835.06 912.39 2834.82 912.78 2834.29 913.09L2834.3 913.1Z" fill="white"/>
                    <path d="M2957.2 998.62C2956.89 998.86 2956.58 999.11 2956.28 998.67C2955.95 998.19 2955.99 997.6 2956.37 997.29C2956.56 997.14 2957.25 997.14 2957.59 997.58C2958.05 998.17 2957.45 998.34 2957.2 998.62Z" fill="white"/>
                    <path d="M2921.12 916.32C2920.6 916.43 2920.11 916.47 2919.69 916.07C2919.44 915.83 2919.47 915.53 2919.71 915.38C2920.1 915.14 2920.38 914.35 2921.02 915C2921.41 915.4 2921.04 915.87 2921.12 916.32Z" fill="white"/>
                    <path d="M2944.14 997.52C2943.97 997.52 2943.8 997.54 2943.63 997.55C2943.69 996.74 2943.9 995.92 2943.49 995.12C2943.47 995.08 2943.75 994.84 2943.92 994.79C2944.01 994.76 2944.29 994.93 2944.28 994.97C2944.13 995.71 2944.83 996.37 2944.48 997.11C2944.41 997.27 2944.26 997.39 2944.14 997.52Z" fill="white"/>
                    <path d="M2940.65 994.8C2940.32 994.35 2941.08 994.13 2941.44 993.89C2942.16 993.41 2942.28 994.27 2942.62 994.58C2942.72 994.67 2942.81 995.26 2942.4 994.99C2941.79 994.59 2941.41 995.96 2940.65 994.8Z" fill="white"/>
                    <path d="M2810.65 1113.62C2811.16 1114.08 2810.78 1114.63 2810.82 1115.14C2810.47 1115.11 2809.94 1115.22 2809.8 1115.04C2809.48 1114.63 2809.59 1114.1 2809.95 1113.68C2810.16 1113.42 2810.41 1113.49 2810.65 1113.62Z" fill="white"/>
                    <path d="M2962.77 1129.01C2962.57 1128.66 2962.02 1128.38 2962.6 1127.88C2963.02 1127.52 2963.27 1127.78 2963.49 1128.09C2963.73 1128.43 2964.25 1128.72 2963.68 1129.22C2963.26 1129.58 2963.03 1129.3 2962.77 1129.01Z" fill="white"/>
                    <path d="M2763.95 1054.17C2763.02 1054.27 2762.52 1053.93 2762.19 1053.33C2762.11 1053.19 2762.15 1052.99 2762.13 1052.82C2762.31 1052.84 2762.5 1052.89 2762.68 1052.87C2763.36 1052.78 2763.64 1053.28 2763.95 1053.72C2764.04 1053.85 2763.95 1054.11 2763.95 1054.17Z" fill="white"/>
                    <path d="M2977.07 1154.31C2976.97 1154.4 2976.69 1154.18 2976.47 1153.85C2976.24 1153.51 2975.69 1153.22 2976.27 1152.72C2976.67 1152.37 2976.93 1152.6 2977.16 1152.91C2977.4 1153.25 2977.92 1153.55 2977.08 1154.3L2977.07 1154.31Z" fill="white"/>
                    <path d="M2965.41 1095.2C2965.39 1095.07 2965.31 1094.86 2965.37 1094.8C2965.82 1094.35 2966.28 1093.93 2967.03 1094.14C2967.34 1094.23 2967.5 1094.32 2967.36 1094.61C2967.14 1095.06 2965.78 1095.46 2965.41 1095.2Z" fill="white"/>
                    <path d="M2857.81 1119.41C2856.99 1119.79 2856.98 1118.89 2856.62 1118.58C2856.45 1118.44 2856.68 1118.05 2856.95 1117.94C2857.73 1117.61 2857.82 1118.42 2858.15 1118.76C2858.31 1118.92 2858.1 1119.27 2857.82 1119.41H2857.81Z" fill="white"/>
                    <path d="M2933.08 1038.86C2932.26 1039.24 2932.25 1038.34 2931.89 1038.03C2931.72 1037.89 2931.95 1037.5 2932.22 1037.39C2933 1037.06 2933.09 1037.87 2933.42 1038.21C2933.58 1038.37 2933.37 1038.72 2933.09 1038.86H2933.08Z" fill="white"/>
                    <path d="M2811.85 1000.51C2811.75 1000.6 2811.47 1000.38 2811.25 1000.05C2811.02 999.71 2810.47 999.42 2811.05 998.92C2811.45 998.57 2811.71 998.8 2811.94 999.11C2812.18 999.45 2812.7 999.75 2811.86 1000.5L2811.85 1000.51Z" fill="white"/>
                    <path d="M2997.51 1000.72C2997.73 1000.17 2997.96 999.61 2998.21 999.05C2998.22 999.02 2998.41 999.03 2998.47 999.08C2998.78 999.33 2998.85 999.64 2998.79 1000.04C2998.72 1000.49 2998.72 1000.96 2998.14 1001.04C2998 1001.06 2997.84 1000.89 2997.51 1000.72Z" fill="white"/>
                    <path d="M2952.46 974.21C2952.72 974.48 2953.02 974.77 2952.66 975.14C2952.2 975.62 2951.88 975.34 2951.57 974.92C2951.36 974.63 2951.03 974.36 2951.39 973.99C2951.85 973.51 2952.19 973.76 2952.46 974.21Z" fill="white"/>
                    <path d="M2897.48 957.1C2896.99 957.3 2896.73 957.35 2896.58 957.03C2896.28 956.41 2896.3 955.82 2896.85 955.35C2896.96 955.26 2897.18 955.3 2897.36 955.29C2897.41 955.92 2897.45 956.55 2897.49 957.11L2897.48 957.1Z" fill="white"/>
                    <path d="M2960.48 1050.98C2960.14 1051.35 2959.77 1051.52 2959.63 1051.32C2959.36 1050.93 2958.58 1050.68 2959.07 1049.98C2959.23 1049.74 2959.61 1049.6 2959.78 1049.79C2960.09 1050.16 2960.7 1050.47 2960.49 1050.98H2960.48Z" fill="white"/>
                    <path d="M2803.63 1017.48C2803.32 1017.71 2803.04 1017.94 2802.7 1017.51C2802.24 1016.91 2802.8 1016.72 2803.11 1016.44C2803.39 1016.18 2803.69 1015.98 2804.02 1016.41C2804.49 1017.01 2803.89 1017.18 2803.63 1017.48Z" fill="white"/>
                    <path d="M2857.91 1084.25C2858.29 1085.07 2857.39 1085.08 2857.08 1085.44C2856.94 1085.61 2856.55 1085.38 2856.44 1085.11C2856.11 1084.33 2856.92 1084.24 2857.26 1083.91C2857.42 1083.75 2857.77 1083.96 2857.91 1084.24V1084.25Z" fill="white"/>
                    <path d="M2904.04 949.09C2904.3 949.36 2904.6 949.65 2904.24 950.02C2903.78 950.5 2903.46 950.22 2903.15 949.8C2902.94 949.51 2902.61 949.24 2902.97 948.87C2903.43 948.39 2903.77 948.64 2904.04 949.09Z" fill="white"/>
                    <path d="M3014.28 1062.58C3014.74 1063.04 3014.69 1063.29 3014.48 1063.5C3014.01 1063.97 3013.69 1063.67 3013.41 1063.25C3013.27 1063.03 3012.89 1062.67 3013.1 1062.6C3013.53 1062.45 3014.06 1062.57 3014.28 1062.58Z" fill="white"/>
                    <path d="M2786.62 1049.01C2786.47 1049.05 2786.22 1049.11 2785.98 1049.17C2786.06 1048.66 2785.84 1048.06 2786.4 1047.71C2786.64 1047.56 2786.88 1047.58 2787.09 1047.86C2787.54 1048.45 2786.99 1048.63 2786.62 1049.02V1049.01Z" fill="white"/>
                    <path d="M2924.87 896.35C2924.67 896.19 2924.71 895.91 2924.91 895.71C2925.17 895.44 2925.52 895.22 2925.8 895.68C2925.99 896 2926.68 896.27 2926.18 896.7C2926 896.85 2925.29 896.84 2924.87 896.36V896.35Z" fill="white"/>
                    <path d="M3039.84 1028.84C3039.38 1029.32 3038.83 1028.96 3038.31 1028.97C3038.44 1028.75 3038.52 1028.47 3038.7 1028.32C3039.01 1028.06 3039.31 1027.55 3039.81 1028.11C3040.04 1028.36 3040 1028.58 3039.84 1028.84Z" fill="white"/>
                    <path d="M2902.25 918.96C2901.94 918.72 2902.19 919.04 2902.29 918.69C2902.35 918.5 2902.67 918.24 2902.85 918.25C2903.36 918.28 2903.98 918.12 2904.27 918.77C2904.3 918.84 2904.29 919.01 2904.25 919.03C2903.57 919.32 2902.91 918.94 2902.25 918.96Z" fill="white"/>
                    <path d="M2891.2 970.84C2890.68 970.75 2890.03 970.92 2889.8 970.27C2889.74 970.11 2889.97 969.74 2890.16 969.63C2890.76 969.31 2890.88 969.95 2891.18 970.23C2891.39 970.42 2891.47 970.63 2891.2 970.84Z" fill="white"/>
                    <path d="M2897.93 1061.69C2897.79 1061.81 2897.66 1061.97 2897.49 1062.03C2897.38 1062.07 2897.12 1061.98 2897.1 1061.9C2897.01 1061.6 2898.35 1060.48 2898.67 1060.59C2898.75 1060.62 2898.83 1060.93 2898.78 1060.98C2898.52 1061.24 2898.22 1061.46 2897.93 1061.7V1061.69Z" fill="white"/>
                    <path d="M2803.76 939.5C2803.33 939.9 2803.04 939.69 2802.83 939.36C2802.67 939.12 2802.7 938.88 2802.97 938.66C2803.38 938.33 2803.65 938.52 2803.86 938.85C2804.01 939.08 2804.06 939.37 2803.76 939.5Z" fill="white"/>
                    <path d="M3031.9 1045.61C3031.62 1045.73 3031.4 1045.74 3031.18 1045.46C3030.85 1045.05 3031.05 1044.78 3031.37 1044.57C3031.61 1044.41 3031.85 1044.44 3032.07 1044.71C3032.4 1045.12 3032.18 1045.36 3031.9 1045.6V1045.61Z" fill="white"/>
                    <path d="M2852.83 1152.87C2852.55 1152.99 2852.33 1153 2852.11 1152.72C2851.78 1152.31 2851.98 1152.04 2852.3 1151.83C2852.54 1151.67 2852.78 1151.7 2853 1151.97C2853.33 1152.38 2853.11 1152.62 2852.83 1152.86V1152.87Z" fill="white"/>
                    <path d="M2776.15 1076.76C2775.93 1076.48 2775.76 1076.21 2776.17 1075.87C2776.44 1075.65 2776.68 1075.67 2776.88 1075.87C2777.16 1076.14 2777.3 1076.44 2776.89 1076.78C2776.62 1077 2776.41 1076.96 2776.15 1076.76Z" fill="white"/>
                    <path d="M2985.03 1134.8C2985.17 1135.31 2985.18 1135.79 2984.8 1136.2C2984.7 1136.31 2984.35 1136.37 2984.35 1136.35C2984.15 1135.86 2984.15 1135.37 2984.54 1134.97C2984.64 1134.86 2984.85 1134.86 2985.03 1134.8Z" fill="white"/>
                    <path d="M2888.03 1165.26C2887.81 1165.52 2887.54 1165.45 2887.34 1165.26C2887.06 1164.99 2886.92 1164.69 2887.33 1164.35C2887.6 1164.12 2887.84 1164.15 2888.04 1164.35C2888.32 1164.62 2888.47 1164.95 2888.03 1165.27V1165.26Z" fill="white"/>
                    <path d="M2958 1076.56C2957.72 1076.68 2957.5 1076.69 2957.28 1076.41C2956.95 1076 2957.15 1075.73 2957.47 1075.52C2957.71 1075.36 2957.95 1075.39 2958.17 1075.66C2958.5 1076.07 2958.28 1076.31 2958 1076.55V1076.56Z" fill="white"/>
                    <path d="M2858.9 909.2C2858.57 909.34 2858.36 909.35 2858.14 909.07C2857.81 908.66 2858.01 908.39 2858.33 908.18C2858.57 908.02 2858.8 908.05 2859.03 908.32C2859.37 908.74 2859.11 908.96 2858.9 909.19V909.2Z" fill="white"/>
                    <path d="M2876.88 923.92C2876.51 923.52 2876.72 923.23 2877.05 923.02C2877.29 922.86 2877.53 922.89 2877.75 923.16C2878.08 923.57 2877.88 923.84 2877.56 924.05C2877.32 924.21 2877.04 924.22 2876.88 923.92Z" fill="white"/>
                    <path d="M2977.7 1173.59C2977.99 1173.37 2978.3 1173.14 2978.59 1173.58C2978.68 1173.72 2978.71 1173.89 2978.76 1174.05C2978.35 1174.06 2977.95 1174.07 2977.54 1174.09C2977.59 1173.93 2977.64 1173.76 2977.69 1173.59H2977.7Z" fill="white"/>
                    <path d="M2933.14 1148.27C2932.85 1148.49 2932.54 1148.72 2932.25 1148.28C2932.16 1148.14 2932.13 1147.97 2932.08 1147.81C2932.49 1147.8 2932.89 1147.79 2933.3 1147.77C2933.25 1147.93 2933.2 1148.1 2933.15 1148.27H2933.14Z" fill="white"/>
                    <path d="M3010.58 1042.32C3010.2 1042.29 3009.84 1042.26 3009.48 1042.23C3009.6 1042.01 3009.67 1041.71 3009.86 1041.58C3010.27 1041.29 3010.52 1041.63 3010.69 1041.95C3010.74 1042.03 3010.62 1042.22 3010.58 1042.32Z" fill="white"/>
                    <path d="M2852.82 1078.02C2852.75 1077.81 2852.61 1077.55 2852.66 1077.51C2853.03 1077.18 2853.46 1077.51 2853.86 1077.47C2853.81 1077.63 2853.8 1077.84 2853.69 1077.95C2853.42 1078.23 2853.11 1078.36 2852.82 1078.02Z" fill="white"/>
                    <path d="M2770.19 1112.99C2770.05 1113 2769.85 1113.08 2769.77 1113.01C2769.5 1112.77 2769.22 1112.46 2769.59 1112.12C2769.76 1111.97 2770.06 1111.96 2770.3 1111.88C2770.26 1112.24 2770.23 1112.6 2770.19 1112.98V1112.99Z" fill="white"/>
                    <path d="M2798.41 1112.11C2798.75 1112.49 2798.51 1112.76 2798.19 1112.97C2798.06 1113.05 2797.86 1113.01 2797.69 1113.03C2797.78 1112.64 2797.88 1112.24 2797.97 1111.85C2798.11 1111.94 2798.26 1112.02 2798.41 1112.11Z" fill="white"/>
                    <path d="M2988.2 1048.08C2988.13 1048.09 2987.92 1048.18 2987.85 1048.12C2987.58 1047.88 2987.29 1047.58 2987.65 1047.23C2987.81 1047.07 2988.12 1047.06 2988.36 1046.98C2988.31 1047.34 2988.26 1047.69 2988.2 1048.08Z" fill="white"/>
                    <path d="M2958.72 1070.09C2958.34 1070.43 2958.07 1070.19 2957.86 1069.87C2957.78 1069.74 2957.82 1069.54 2957.8 1069.37C2958.19 1069.46 2958.59 1069.56 2958.98 1069.65C2958.89 1069.79 2958.81 1069.94 2958.72 1070.09Z" fill="white"/>
                    <path d="M2819.16 1153.9C2818.99 1153.92 2818.82 1153.95 2818.65 1153.97C2818.72 1153.57 2818.8 1153.18 2818.87 1152.78C2819.02 1152.86 2819.19 1152.91 2819.31 1153.02C2819.67 1153.37 2819.46 1153.65 2819.16 1153.89V1153.9Z" fill="white"/>
                    <path d="M2867.62 939.56C2867.96 939.94 2867.72 940.21 2867.4 940.42C2867.27 940.5 2867.07 940.46 2866.9 940.48C2866.99 940.09 2867.09 939.69 2867.18 939.3C2867.32 939.39 2867.47 939.47 2867.62 939.56Z" fill="white"/>
                    <path d="M2841.21 910.75C2841.13 910.97 2841.11 911.18 2841.01 911.29C2840.73 911.57 2840.4 911.7 2840.13 911.27C2840.04 911.13 2840.03 910.95 2839.98 910.79C2840.38 910.78 2840.79 910.76 2841.21 910.75Z" fill="white"/>
                    <path d="M3036.09 989.93C3035.71 989.99 3035.61 989.68 3035.46 989.47C3035.41 989.41 3035.52 989.12 3035.6 989.1C3035.95 989.01 3036.06 989.34 3036.2 989.55C3036.25 989.62 3036.12 989.81 3036.08 989.94L3036.09 989.93Z" fill="white"/>
                    <path d="M3027.86 1013.35C3027.68 1013.61 3027.57 1013.85 3027.39 1014.01C3027.28 1014.1 3027.06 1014.06 3026.89 1014.08C3027.01 1013.86 3027.1 1013.6 3027.27 1013.43C3027.36 1013.34 3027.6 1013.38 3027.86 1013.35Z" fill="white"/>
                    <path d="M2778.46 990.17C2778.08 990.23 2777.98 989.92 2777.83 989.71C2777.78 989.65 2777.89 989.36 2777.97 989.34C2778.32 989.25 2778.43 989.58 2778.57 989.79C2778.62 989.86 2778.49 990.05 2778.45 990.18L2778.46 990.17Z" fill="white"/>
                    <path d="M2776.78 1096.05C2776.52 1095.87 2776.28 1095.76 2776.12 1095.58C2776.03 1095.47 2776.07 1095.25 2776.05 1095.08C2776.27 1095.2 2776.53 1095.29 2776.7 1095.46C2776.79 1095.55 2776.75 1095.79 2776.78 1096.05Z" fill="white"/>
                    <path d="M2778.99 1098.17C2778.91 1098.05 2778.72 1097.86 2778.63 1097.63C2778.52 1097.37 2778.68 1097.23 2778.91 1097.4C2779.11 1097.54 2779.28 1097.75 2779.38 1097.98C2779.5 1098.24 2779.36 1098.34 2778.98 1098.17H2778.99Z" fill="white"/>
                    <path d="M2761.77 994.36C2761.82 994.52 2761.96 994.76 2761.9 994.83C2761.68 995.09 2761.46 994.94 2761.32 994.7C2761.24 994.56 2761.13 994.3 2761.18 994.23C2761.4 993.98 2761.61 994.13 2761.76 994.36H2761.77Z" fill="white"/>
                    <path d="M3025.5 1045.69C3025.34 1045.74 3025.1 1045.88 3025.03 1045.82C3024.77 1045.6 3024.92 1045.38 3025.16 1045.24C3025.3 1045.16 3025.56 1045.05 3025.63 1045.1C3025.88 1045.32 3025.73 1045.53 3025.5 1045.68V1045.69Z" fill="white"/>
                    <path d="M2829.09 1151.37C2828.91 1151.63 2828.8 1151.87 2828.62 1152.03C2828.51 1152.12 2828.29 1152.08 2828.12 1152.1C2828.24 1151.88 2828.33 1151.62 2828.5 1151.45C2828.59 1151.36 2828.83 1151.4 2829.09 1151.37Z" fill="white"/>
                    <path d="M2932.44 924.77C2932.32 924.78 2932.14 924.8 2931.97 924.82C2932.09 924.6 2932.18 924.34 2932.35 924.17C2932.44 924.08 2932.68 924.12 2932.85 924.1C2932.85 924.39 2932.74 924.62 2932.44 924.77Z" fill="white"/>
                    <path d="M2858.29 1183.67C2858.1 1183.47 2857.99 1183.24 2858.29 1183.07C2858.37 1183.03 2858.6 1183.19 2858.72 1183.3C2858.93 1183.48 2859.03 1183.73 2858.73 1183.89C2858.65 1183.93 2858.44 1183.75 2858.29 1183.67Z" fill="white"/>
                    <path d="M2901.91 948.27C2901.72 948.07 2901.61 947.84 2901.91 947.67C2901.99 947.63 2902.22 947.79 2902.34 947.9C2902.55 948.08 2902.65 948.33 2902.35 948.49C2902.27 948.53 2902.06 948.35 2901.91 948.27Z" fill="white"/>
                    <path d="M2900.69 960.28C2900.5 960.08 2900.39 959.85 2900.69 959.68C2900.77 959.64 2901 959.8 2901.12 959.91C2901.33 960.09 2901.43 960.34 2901.13 960.5C2901.05 960.54 2900.84 960.36 2900.69 960.28Z" fill="white"/>
                    <path d="M2902.55 1004.72C2902.37 1004.98 2902.26 1005.22 2902.08 1005.38C2901.97 1005.47 2901.75 1005.43 2901.58 1005.45C2901.7 1005.23 2901.79 1004.97 2901.96 1004.8C2902.05 1004.71 2902.29 1004.75 2902.55 1004.72Z" fill="white"/>
                    <path d="M2997.68 1045.68C2997.47 1045.72 2997.22 1045.78 2996.98 1045.83C2997.03 1045.67 2997.03 1045.43 2997.15 1045.35C2997.36 1045.22 2997.62 1045.18 2997.87 1045.11C2997.82 1045.27 2997.77 1045.44 2997.69 1045.68H2997.68Z" fill="white"/>
                    <path d="M2914.89 1082.33C2914.77 1082.34 2914.59 1082.36 2914.42 1082.38C2914.54 1082.16 2914.63 1081.9 2914.8 1081.73C2914.89 1081.64 2915.13 1081.68 2915.3 1081.66C2915.3 1081.95 2915.19 1082.18 2914.89 1082.33Z" fill="white"/>
                    <path d="M2936.94 1120.62C2936.76 1120.88 2936.65 1121.12 2936.47 1121.28C2936.36 1121.37 2936.14 1121.33 2935.97 1121.35C2936.09 1121.13 2936.18 1120.87 2936.35 1120.7C2936.44 1120.61 2936.68 1120.65 2936.94 1120.62Z" fill="white"/>
                    <path d="M2971.94 1180.39C2971.56 1180.45 2971.46 1180.14 2971.31 1179.93C2971.26 1179.87 2971.37 1179.58 2971.45 1179.55C2971.8 1179.46 2971.91 1179.79 2972.05 1180C2972.1 1180.07 2971.97 1180.26 2971.93 1180.39H2971.94Z" fill="white"/>
                    <path d="M2915.41 1154.67C2915.22 1154.47 2915.11 1154.24 2915.41 1154.07C2915.49 1154.03 2915.72 1154.19 2915.84 1154.3C2916.05 1154.48 2916.15 1154.73 2915.85 1154.89C2915.77 1154.93 2915.56 1154.75 2915.41 1154.67Z" fill="white"/>
                    <path d="M2795.72 994.56C2795.77 994.72 2795.91 994.96 2795.85 995.03C2795.63 995.29 2795.41 995.14 2795.27 994.9C2795.19 994.76 2795.08 994.5 2795.13 994.43C2795.35 994.18 2795.56 994.33 2795.71 994.56H2795.72Z" fill="white"/>
                    <path d="M2865.1 1056.17C2864.94 1056.22 2864.7 1056.36 2864.63 1056.3C2864.37 1056.08 2864.52 1055.86 2864.76 1055.72C2864.9 1055.64 2865.16 1055.53 2865.23 1055.58C2865.48 1055.8 2865.33 1056.01 2865.1 1056.16V1056.17Z" fill="white"/>
                    <path d="M2854.19 1066.18C2854.13 1066.31 2854.09 1066.45 2854.01 1066.56C2853.98 1066.6 2853.84 1066.55 2853.75 1066.54C2853.81 1066.41 2853.85 1066.27 2853.93 1066.16C2853.96 1066.12 2854.1 1066.17 2854.19 1066.18Z" fill="white"/>
                    <path d="M2813.73 1162.38C2813.67 1162.51 2813.63 1162.65 2813.55 1162.76C2813.52 1162.8 2813.38 1162.75 2813.29 1162.74C2813.35 1162.61 2813.39 1162.47 2813.47 1162.36C2813.5 1162.32 2813.64 1162.37 2813.73 1162.38Z" fill="white"/>
                    <path d="M2905.47 970.95C2905.34 970.97 2905.2 971 2905.06 971.02C2905.06 970.93 2905.05 970.79 2905.09 970.76C2905.21 970.7 2905.36 970.69 2905.5 970.66C2905.5 970.75 2905.48 970.84 2905.47 970.94V970.95Z" fill="white"/>
                    <path d="M2888.73 929.74C2888.84 929.75 2888.93 929.76 2889.01 929.77C2888.95 929.9 2888.91 930.04 2888.83 930.15C2888.8 930.19 2888.66 930.14 2888.57 930.13C2888.62 930 2888.68 929.87 2888.73 929.74Z" fill="white"/>
                    <path d="M2942.18 992.98C2942.16 992.85 2942.13 992.71 2942.11 992.57C2942.2 992.57 2942.34 992.56 2942.37 992.6C2942.43 992.72 2942.44 992.87 2942.47 993.01C2942.38 993.01 2942.29 992.99 2942.19 992.98H2942.18Z" fill="white"/>
                    <path d="M2936.42 1036.88C2936.29 1036.82 2936.15 1036.78 2936.04 1036.7C2936 1036.67 2936.05 1036.53 2936.06 1036.44C2936.19 1036.5 2936.33 1036.54 2936.44 1036.62C2936.48 1036.65 2936.43 1036.79 2936.42 1036.88Z" fill="white"/>
                    <path d="M2962.01 1075.75C2962.02 1075.64 2962.03 1075.55 2962.04 1075.47C2962.17 1075.53 2962.31 1075.57 2962.42 1075.65C2962.46 1075.68 2962.41 1075.82 2962.4 1075.91C2962.27 1075.86 2962.14 1075.8 2962.01 1075.75Z" fill="white"/>
                    <path d="M3015.8 1055.71C3015.67 1055.73 3015.53 1055.76 3015.39 1055.78C3015.39 1055.69 3015.38 1055.55 3015.42 1055.52C3015.54 1055.46 3015.69 1055.45 3015.83 1055.42C3015.83 1055.51 3015.81 1055.6 3015.8 1055.7V1055.71Z" fill="white"/>
                    <path d="M2931.8 977.37C2931.69 977.36 2931.6 977.35 2931.52 977.34C2931.58 977.21 2931.62 977.07 2931.7 976.96C2931.73 976.92 2931.87 976.97 2931.96 976.98C2931.91 977.11 2931.85 977.24 2931.8 977.37Z" fill="white"/>
                    <path d="M2907.46 1044.47C2907.57 1044.48 2907.66 1044.49 2907.74 1044.5C2907.68 1044.63 2907.64 1044.77 2907.56 1044.88C2907.53 1044.92 2907.39 1044.87 2907.3 1044.86C2907.35 1044.73 2907.41 1044.6 2907.46 1044.47Z" fill="white"/>
                    <path d="M2985.64 1157.99C2985.64 1158.08 2985.64 1158.24 2985.61 1158.24C2985.47 1158.27 2985.31 1158.3 2985.19 1158.25C2984.95 1158.14 2984.97 1157.97 2985.22 1157.91C2985.35 1157.88 2985.5 1157.96 2985.64 1157.99Z" fill="white"/>
                    <path d="M2984.83 1158.22C2984.69 1158.19 2984.46 1158.19 2984.43 1158.12C2984.34 1157.91 2984.45 1157.77 2984.62 1157.83C2984.76 1157.88 2984.89 1157.99 2985.02 1158.07C2984.95 1158.12 2984.89 1158.18 2984.82 1158.23L2984.83 1158.22Z" fill="white"/>
                    <path d="M2827.39 1055.26C2827.26 1055.28 2827.12 1055.31 2826.98 1055.33C2826.98 1055.24 2826.97 1055.1 2827.01 1055.07C2827.13 1055.01 2827.28 1055 2827.42 1054.97C2827.42 1055.06 2827.4 1055.15 2827.39 1055.25V1055.26Z" fill="white"/>
                    <path d="M3049.17 1105.59C3049.04 1105.61 3048.9 1105.64 3048.76 1105.66C3048.76 1105.57 3048.75 1105.43 3048.79 1105.4C3048.91 1105.34 3049.06 1105.33 3049.2 1105.3C3049.2 1105.39 3049.18 1105.48 3049.17 1105.58V1105.59Z" fill="white"/>
                    <path d="M2985.56 1165.6C2985.45 1165.59 2985.36 1165.58 2985.28 1165.57C2985.34 1165.44 2985.38 1165.3 2985.46 1165.19C2985.49 1165.15 2985.63 1165.2 2985.72 1165.21C2985.67 1165.34 2985.61 1165.47 2985.56 1165.6Z" fill="white"/>
                    <path d="M3030.29 1068.33C3030.42 1068.31 3030.56 1068.28 3030.7 1068.26C3030.7 1068.35 3030.71 1068.49 3030.67 1068.52C3030.55 1068.58 3030.4 1068.59 3030.26 1068.62C3030.26 1068.53 3030.28 1068.44 3030.29 1068.34V1068.33Z" fill="white"/>
                    <path d="M2898.91 1115.87C2898.78 1115.89 2898.64 1115.92 2898.5 1115.94C2898.5 1115.85 2898.49 1115.71 2898.53 1115.68C2898.65 1115.62 2898.8 1115.61 2898.94 1115.58C2898.94 1115.67 2898.92 1115.76 2898.91 1115.86V1115.87Z" fill="white"/>
                    <path d="M2970.01 1004.57C2970.02 1004.46 2970.03 1004.37 2970.04 1004.29C2970.17 1004.35 2970.31 1004.39 2970.42 1004.47C2970.46 1004.5 2970.41 1004.64 2970.4 1004.73C2970.27 1004.68 2970.14 1004.62 2970.01 1004.57Z" fill="white"/>
                    <path d="M2956.59 1149.32C2956.53 1149.45 2956.49 1149.59 2956.41 1149.7C2956.38 1149.74 2956.24 1149.69 2956.15 1149.68C2956.21 1149.55 2956.25 1149.41 2956.33 1149.3C2956.36 1149.26 2956.5 1149.31 2956.59 1149.32Z" fill="white"/>
                    <path d="M2979.73 1012.43C2979.6 1012.45 2979.46 1012.48 2979.32 1012.5C2979.32 1012.41 2979.31 1012.27 2979.35 1012.24C2979.47 1012.18 2979.62 1012.17 2979.76 1012.14L2979.73 1012.42V1012.43Z" fill="white"/>
                    <path d="M2876.27 1054.66C2876.29 1054.79 2876.32 1054.93 2876.34 1055.07C2876.25 1055.07 2876.11 1055.08 2876.08 1055.04C2876.02 1054.92 2876.01 1054.77 2875.98 1054.63C2876.07 1054.63 2876.16 1054.65 2876.26 1054.66H2876.27Z" fill="white"/>
                    <path d="M2755.34 999.97C2755.28 1000.1 2755.24 1000.24 2755.16 1000.35C2755.13 1000.39 2754.99 1000.34 2754.9 1000.33C2754.96 1000.2 2755 1000.06 2755.08 999.95C2755.11 999.91 2755.25 999.96 2755.34 999.97Z" fill="white"/>
                    <path d="M2710.09 984.98C2710.69 983.11 2712.21 982.91 2713.64 982.75C2714.17 982.69 2714.99 982.72 2715.25 982.59C2716.91 981.76 2716.95 983.96 2718.08 983.95C2717.92 985.05 2718.1 986.42 2717.5 987.17C2716.93 987.89 2716.14 988.72 2715.22 989.16C2714.35 989.58 2713.31 990.55 2712.61 988.82C2712.51 988.56 2711.9 988.53 2711.54 988.35C2709.96 987.57 2709.5 986.4 2710.08 984.97L2710.09 984.98Z" fill="white"/>
                    <path d="M2783.9 1125.49C2782.83 1125.04 2782.42 1123.87 2782.59 1123.49C2783.22 1122.04 2782.13 1120.91 2782.18 1119.58C2782.22 1118.65 2782.39 1118.08 2783.24 1117.78C2784.65 1117.29 2785.32 1117.43 2785.89 1118.37C2786.13 1118.78 2786.41 1119.13 2786.71 1119.5C2788.01 1121.08 2787.13 1125.13 2784.81 1125.48C2784.4 1125.54 2784.06 1125.6 2783.9 1125.48V1125.49Z" fill="white"/>
                    <path d="M2804.36 1032.99C2804 1032.03 2802.55 1031.68 2802.98 1030.33C2803.04 1030.14 2803.2 1029.95 2803.19 1029.77C2803.14 1029.02 2802.8 1028.24 2803.53 1027.63C2804.1 1027.15 2804.82 1027.33 2805.47 1027.28C2806.76 1027.18 2808.14 1028.39 2808.4 1029.79C2808.6 1030.88 2808.24 1031.69 2807.36 1032.4C2806.45 1033.14 2805.38 1032.84 2804.36 1032.99Z" fill="white"/>
                    <path d="M2944.68 1019.98C2946.29 1020.64 2946.78 1021.53 2946.45 1022.75C2946.26 1023.42 2945.91 1024.04 2945.69 1024.71C2945.48 1025.34 2945.14 1025.42 2944.57 1025.15C2943.94 1024.85 2943.22 1024.7 2942.64 1024.34C2941.65 1023.72 2941.24 1022.7 2941.19 1021.56C2941.18 1021.33 2941.38 1020.95 2941.57 1020.87C2942.3 1020.57 2942.86 1020.06 2943.49 1019.65C2943.8 1019.45 2944.57 1019.95 2944.69 1020L2944.68 1019.98Z" fill="white"/>
                    <path d="M2913.44 1147.81C2912.69 1147.58 2911.6 1147.26 2911.76 1146C2911.83 1145.4 2911.97 1144.29 2912.72 1144.35C2913.53 1144.42 2914.5 1143.83 2915.2 1144.65C2915.68 1145.22 2915.99 1145.99 2916.57 1146.41C2917.1 1146.8 2917.15 1147.04 2916.93 1147.59C2916.63 1148.38 2916 1148.62 2915.31 1148.53C2914.73 1148.46 2914.19 1148.11 2913.44 1147.81Z" fill="white"/>
                    <path d="M2954.46 1000.52C2953.72 1000.24 2953.32 999.41 2953.57 998.67C2953.65 998.42 2955.89 996.65 2956.12 996.64C2956.66 996.62 2957.72 998.13 2957.63 998.14C2956.39 998.39 2957.48 999.49 2956.92 999.93C2956.38 1000.35 2954.93 1000.7 2954.46 1000.52Z" fill="white"/>
                    <path d="M2777.98 973.01C2777.81 973.39 2777.68 974.03 2777.3 973.88C2776.61 973.61 2775.74 973.62 2775.34 972.72C2775.12 972.22 2775.39 971.74 2775.22 971.33C2774.94 970.66 2775 970.28 2775.71 969.93C2776.48 969.56 2777.19 969.73 2777.53 970.3C2778 971.1 2778.67 972 2777.97 973.01H2777.98Z" fill="white"/>
                    <path d="M2813.66 1071.16C2814.23 1071.43 2815.11 1071.44 2814.62 1072.53C2814.51 1072.78 2814.91 1073.24 2815.04 1073.61C2815.19 1074.04 2815.31 1074.47 2815.44 1074.91C2814.79 1074.72 2813.95 1074.72 2813.55 1074.3C2813.04 1073.76 2812.9 1072.87 2812.56 1072.15C2812.37 1071.76 2812.27 1071.36 2812.58 1071.06C2812.92 1070.73 2813.31 1071.03 2813.67 1071.16H2813.66Z" fill="white"/>
                    <path d="M2864.27 918.64C2864.45 918.71 2864.64 918.77 2864.81 918.86C2865.19 919.08 2865.89 919.01 2865.78 919.7C2865.67 920.41 2865.24 920.89 2864.5 921.1C2863.87 921.28 2863.66 920.95 2863.42 920.48C2863.26 920.18 2862.84 920.01 2862.67 919.71C2862.45 919.31 2862.09 918.86 2862.44 918.37C2862.86 917.79 2863.3 918.32 2863.72 918.42C2863.91 918.47 2864.08 918.56 2864.27 918.64Z" fill="white"/>
                    <path d="M2960.1 1009.89C2960.82 1009.32 2961.39 1008.5 2962.37 1008.3C2962.59 1008.25 2962.95 1008.38 2963.05 1008.74C2963.18 1009.19 2963.66 1009.67 2963.24 1010.1C2962.72 1010.62 2962.08 1011.04 2961.45 1011.43C2960.7 1011.9 2960.95 1010.87 2960.54 1010.77C2960.12 1010.66 2959.86 1010.4 2960.1 1009.9V1009.89Z" fill="white"/>
                    <path d="M2767.15 1055.65C2767.69 1055.77 2767.93 1056.06 2767.65 1056.67C2767.21 1057.61 2766.49 1057.95 2765.62 1057.64C2764.85 1057.37 2764.62 1056.73 2764.67 1056.07C2764.73 1055.31 2765.94 1055.13 2767.16 1055.65H2767.15Z" fill="white"/>
                    <path d="M2692.4 1106.12C2692.68 1106.23 2692.99 1106.3 2693.23 1106.47C2693.8 1106.86 2693.69 1107.69 2693.07 1107.96C2692.7 1108.12 2692.35 1108.35 2691.96 1108.41C2691.47 1108.49 2690.9 1108.3 2690.68 1107.9C2690.43 1107.44 2690.83 1106.8 2690.46 1106.29C2691 1106.14 2691.48 1105.59 2692.12 1106.02C2692.2 1106.07 2692.3 1106.1 2692.4 1106.13V1106.12Z" fill="white"/>
                    <path d="M2905.98 996.57C2905.84 996.24 2905.74 995.96 2905.61 995.7C2905.27 995.03 2906.24 994.48 2905.74 993.77C2905.47 993.4 2905.65 992.98 2906.08 992.84C2906.49 992.71 2906.79 992.96 2906.95 993.38C2907.16 993.93 2907.44 994.46 2907.63 995.02C2907.72 995.29 2908.24 995.64 2907.57 995.89C2907.03 996.1 2906.51 996.34 2905.98 996.56V996.57Z" fill="white"/>
                    <path d="M2971.75 1101.9C2971.43 1102.45 2970.29 1102.63 2969.68 1102.23C2969.44 1102.07 2969.07 1100.25 2969.29 1099.97C2969.83 1099.26 2970.36 1099.92 2970.81 1100.11C2971.65 1100.46 2972.06 1101.37 2971.75 1101.9Z" fill="white"/>
                    <path d="M2889.57 1117.1C2889.71 1116.5 2889.97 1116.2 2890.6 1116.49C2891.37 1116.84 2891.92 1117.69 2891.69 1118.25C2891.47 1118.8 2890.45 1119.05 2889.68 1118.73C2888.52 1118.25 2889.55 1117.62 2889.57 1117.11V1117.1Z" fill="white"/>
                    <path d="M2883.71 1121.52C2883.38 1121.08 2883 1120.61 2883.19 1120.01C2883.4 1119.36 2884.76 1119.15 2885.44 1119.62C2885.89 1119.92 2885.71 1120.27 2885.53 1120.61C2885.12 1121.4 2884.26 1121.83 2883.71 1121.52Z" fill="white"/>
                    <path d="M2908.84 1032.83C2909.2 1032.98 2909.38 1033.07 2909.57 1033.12C2910.28 1033.31 2910.23 1034.01 2910.52 1034.48C2909.98 1034.62 2909.53 1035.16 2908.89 1034.8C2908.72 1034.71 2908.53 1034.64 2908.35 1034.58C2907.71 1034.38 2907.36 1034.15 2907.7 1033.34C2908.03 1032.57 2908.41 1032.52 2908.85 1032.83H2908.84Z" fill="white"/>
                    <path d="M2827.53 1185.45C2827.75 1184.78 2828.24 1184.82 2828.5 1185.09C2829.02 1185.63 2829.44 1186.26 2829.85 1186.89C2830.23 1187.48 2829.6 1187.43 2829.34 1187.59C2828.2 1188.29 2828.21 1186.87 2827.58 1186.6C2826.85 1186.29 2827.56 1185.76 2827.54 1185.44L2827.53 1185.45Z" fill="white"/>
                    <path d="M2906.77 1069.06C2905.95 1068.9 2905.71 1068.24 2905.74 1067.58C2905.76 1067.08 2907.3 1066.47 2907.57 1066.79C2908.07 1067.41 2908.11 1068.09 2907.74 1068.83C2907.48 1069.35 2907.15 1069.2 2906.78 1069.06H2906.77Z" fill="white"/>
                    <path d="M2796.2 923C2796.51 922.5 2797.07 922.37 2797.63 922.33C2797.96 922.31 2798.38 923.91 2798.2 924.5C2798.04 925.04 2797.66 924.94 2797.29 924.78C2796.53 924.45 2795.99 923.58 2796.2 923.01V923Z" fill="white"/>
                    <path d="M2941.44 1044.86C2941.25 1045.1 2940.94 1045.29 2940.71 1045.02C2940.17 1044.38 2939.46 1043.87 2939.15 1043.02C2939.02 1042.67 2939.12 1042.35 2939.3 1042.21C2939.47 1042.08 2939.96 1042.07 2940.09 1042.21C2940.59 1042.77 2941.01 1043.4 2941.44 1044.01C2941.62 1044.27 2941.61 1044.56 2941.44 1044.85V1044.86Z" fill="white"/>
                    <path d="M2974.07 1043.54C2973.77 1043.45 2973.38 1043.34 2973.48 1042.81C2973.56 1042.36 2973.99 1042.3 2974.3 1042.1C2974.94 1041.69 2975.45 1042.1 2975.91 1042.37C2976.18 1042.53 2976.47 1043.21 2976.35 1043.36C2976.11 1043.68 2975.63 1043.92 2975.22 1043.96C2974.88 1044 2974.5 1043.71 2974.07 1043.53V1043.54Z" fill="white"/>
                    <path d="M2881.29 1179.52C2881.18 1180 2880.88 1180.24 2880.28 1179.96C2879.52 1179.6 2879 1178.77 2879.21 1178.21C2879.57 1177.27 2880.47 1178.38 2880.98 1177.75C2881.07 1177.64 2881.53 1178.79 2881.29 1179.53V1179.52Z" fill="white"/>
                    <path d="M2866.92 993.82C2866.83 993.66 2866.64 993.49 2866.66 993.35C2866.77 992.57 2868.29 991.72 2868.96 992.05C2869.19 992.16 2869.5 992.4 2869.51 992.6C2869.52 992.81 2869.28 993.12 2869.06 993.25C2868.11 993.83 2867.38 993.99 2866.92 993.81V993.82Z" fill="white"/>
                    <path d="M2754.72 995.23C2754.61 995.5 2754.54 995.81 2754.38 996.05C2753.96 996.67 2753.53 996.13 2753.11 996.02C2752.56 995.87 2752.66 995.49 2752.82 995.1C2752.97 994.75 2753.04 994.37 2753.49 994.22C2753.95 994.06 2754.41 993.72 2754.83 994.15C2755.16 994.48 2754.84 994.87 2754.71 995.23H2754.72Z" fill="white"/>
                    <path d="M2938.11 1045.28C2938.63 1045.62 2938.43 1045.99 2938.25 1046.32C2937.83 1047.12 2936.97 1047.54 2936.43 1047.2C2935.98 1046.92 2936.18 1046.54 2936.36 1046.2C2936.78 1045.4 2937.62 1045 2938.12 1045.27L2938.11 1045.28Z" fill="white"/>
                    <path d="M2888.95 1183.1C2888.3 1183.35 2887.75 1183.74 2887.01 1183.34C2886.4 1183.01 2886.5 1182.68 2886.69 1182.19C2886.89 1181.65 2887.1 1181.45 2887.74 1181.62C2888.58 1181.84 2888.61 1182.55 2888.95 1183.1Z" fill="white"/>
                    <path d="M2873.92 1181.28C2873.82 1181.13 2873.6 1180.95 2873.63 1180.83C2873.72 1180.45 2873.8 1179.96 2874.07 1179.77C2874.51 1179.47 2875.03 1179.04 2875.65 1179.38C2876.13 1179.64 2875.93 1180.03 2875.75 1180.37C2875.32 1181.18 2874.52 1181.57 2873.93 1181.28H2873.92Z" fill="white"/>
                    <path d="M2758.3 954.57C2757.92 954.4 2757.52 954.22 2757.82 953.74C2758.2 953.12 2758.83 952.77 2759.49 953.14C2759.83 953.33 2759.95 953.93 2760.17 954.34C2759.56 954.57 2758.99 955.06 2758.31 954.57H2758.3Z" fill="white"/>
                    <path d="M2741.83 1112.59C2742.14 1111.94 2743.19 1111.56 2743.62 1111.83C2744.07 1112.11 2743.83 1112.46 2743.71 1112.82C2743.47 1113.54 2742.9 1113.85 2742.26 1113.7C2742.07 1113.66 2741.69 1112.95 2741.83 1112.59Z" fill="white"/>
                    <path d="M2907.62 1090.85C2907.16 1090.66 2906.68 1090.44 2907.01 1090.02C2907.43 1089.5 2908.03 1089.14 2908.56 1088.71C2908.87 1089.32 2909.11 1089.89 2908.71 1090.61C2908.33 1091.29 2907.94 1090.92 2907.61 1090.84L2907.62 1090.85Z" fill="white"/>
                    <path d="M2783.54 1176.02C2782.75 1175.8 2782.84 1175.41 2783.02 1174.86C2783.29 1174.01 2783.73 1174.15 2784.36 1174.4C2785 1174.65 2784.94 1174.99 2784.75 1175.54C2784.47 1176.35 2784.05 1176.39 2783.55 1176.02H2783.54Z" fill="white"/>
                    <path d="M2918.08 1083.43C2918.36 1082.98 2918.31 1082.04 2919.2 1082.55C2919.68 1082.83 2919.96 1083.42 2919.79 1084.03C2919.51 1085.03 2918.59 1084.35 2918.07 1084.43C2917.43 1084.54 2918.16 1083.79 2918.09 1083.43H2918.08Z" fill="white"/>
                    <path d="M2991.57 1033.7C2991.3 1033.59 2991 1033.52 2990.76 1033.36C2990.32 1033.08 2990.53 1032.7 2990.7 1032.36C2990.82 1032.11 2990.81 1031.75 2991.18 1031.7C2991.61 1031.65 2991.9 1031.84 2992.04 1032.26C2992.1 1032.44 2992.2 1032.62 2992.27 1032.8C2992.4 1033.15 2992.74 1033.65 2992.62 1033.82C2992.35 1034.18 2991.93 1033.8 2991.58 1033.7H2991.57Z" fill="white"/>
                    <path d="M2712.2 1063.58C2711.75 1064.14 2711.32 1063.66 2710.92 1063.52C2710.17 1063.25 2710.63 1062.75 2710.76 1062.33C2710.89 1061.95 2711.08 1061.58 2711.59 1061.85C2712.11 1062.12 2712.46 1063.13 2712.2 1063.58Z" fill="white"/>
                    <path d="M2856.96 1128.58C2857.18 1128.1 2857.34 1127.72 2857.85 1127.99C2858.37 1128.26 2858.74 1129.28 2858.46 1129.71C2858.17 1130.15 2857.81 1129.87 2857.45 1129.78C2856.56 1129.56 2856.73 1129.04 2856.97 1128.57L2856.96 1128.58Z" fill="white"/>
                    <path d="M2953.78 1064.34C2953.65 1064.5 2953.5 1064.89 2953.32 1064.9C2952.84 1064.93 2952.84 1064.38 2952.8 1064.11C2952.74 1063.7 2951.76 1063.2 2952.84 1062.81C2953.2 1062.68 2953.55 1062.44 2953.91 1062.72C2954.64 1063.28 2953.77 1063.73 2953.78 1064.34Z" fill="white"/>
                    <path d="M2861.53 939.63C2861.28 939.23 2860.62 938.68 2861.14 938.36C2861.59 938.09 2861.62 937.25 2862.3 937.46C2862.71 937.58 2862.71 939.16 2862.32 939.58C2862.11 939.8 2861.82 939.81 2861.53 939.63Z" fill="white"/>
                    <path d="M2788.54 1157.99C2788.54 1157.99 2788.01 1158.09 2787.53 1158.11C2787.32 1158.11 2787.02 1156.78 2787.18 1156.54C2787.6 1155.93 2788.02 1156.45 2788.44 1156.56C2788.94 1156.69 2788.94 1157.05 2788.54 1157.99Z" fill="white"/>
                    <path d="M2757.79 1140.3C2757.23 1139.86 2757.72 1139.44 2757.84 1139.03C2757.98 1138.51 2758.44 1138.48 2758.72 1138.76C2759.04 1139.07 2759.23 1139.55 2759.38 1139.99C2759.44 1140.15 2758.01 1140.45 2757.79 1140.31V1140.3Z" fill="white"/>
                    <path d="M2711.18 963.02C2711.28 962.5 2711.58 962.39 2711.85 962.5C2712.56 962.79 2712.76 963.4 2712.65 964.12C2712.59 964.49 2712.29 964.59 2712.01 964.49C2711.26 964.21 2711.14 963.58 2711.18 963.02Z" fill="white"/>
                    <path d="M2885.52 980.45C2885.2 981 2884.64 981.29 2884.17 981.1C2883.94 981.01 2883.58 980.36 2883.81 979.82C2884.12 979.09 2884.59 979.54 2885 979.67C2885.39 979.79 2885.71 979.98 2885.52 980.46V980.45Z" fill="white"/>
                    <path d="M2692.73 1060.25C2692.8 1059.98 2693.99 1059.21 2694.06 1059.36C2694.26 1059.78 2694.47 1060.24 2694.47 1060.69C2694.47 1061.1 2694.11 1061.35 2693.65 1061.1C2693.28 1060.89 2692.62 1060.97 2692.73 1060.24V1060.25Z" fill="white"/>
                    <path d="M2891.63 981C2891.2 980.64 2890.83 980.12 2891.15 979.38C2891.27 979.11 2891.55 978.96 2891.86 979.17C2892.45 979.59 2892.75 980.15 2892.47 980.87C2892.36 981.14 2892.08 981.28 2891.64 980.99L2891.63 981Z" fill="white"/>
                    <path d="M2929.78 925.56C2929.26 925.38 2929.22 924.95 2929.49 924.67C2929.81 924.35 2930.28 924.16 2930.72 924.01C2930.88 923.95 2931.18 925.38 2931.04 925.6C2930.83 925.92 2930.34 925.9 2929.78 925.57V925.56Z" fill="white"/>
                    <path d="M2913.13 1083.33C2912.74 1083.16 2912.36 1083.01 2912.61 1082.5C2912.88 1081.95 2913.46 1081.68 2913.93 1081.88C2914.17 1081.98 2914.52 1082.62 2914.29 1083.16C2913.98 1083.89 2913.52 1083.42 2913.12 1083.33H2913.13Z" fill="white"/>
                    <path d="M2971.12 1007.81C2970.76 1007.38 2970.46 1006.95 2970.63 1006.35C2970.73 1006 2971.02 1005.87 2971.28 1006.02C2971.7 1006.26 2972.58 1006.12 2972.3 1007.05C2972.13 1007.62 2971.5 1007.51 2971.13 1007.81H2971.12Z" fill="white"/>
                    <path d="M2907.48 1070.64C2907.39 1070.49 2907.29 1070.33 2907.2 1070.18C2907.99 1069.82 2908.86 1069.6 2909.39 1068.81C2909.41 1068.78 2909.78 1068.91 2909.91 1069.04C2909.99 1069.11 2909.96 1069.46 2909.92 1069.47C2909.16 1069.71 2908.9 1070.69 2908.04 1070.75C2907.86 1070.76 2907.67 1070.68 2907.48 1070.65V1070.64Z" fill="white"/>
                    <path d="M2908.23 1066.01C2908.48 1065.47 2909.07 1066.06 2909.48 1066.27C2910.29 1066.69 2909.55 1067.24 2909.44 1067.72C2909.41 1067.86 2908.91 1068.24 2908.94 1067.72C2909 1066.95 2907.53 1067.3 2908.22 1066L2908.23 1066.01Z" fill="white"/>
                    <path d="M2731.53 1005.98C2731.37 1006.69 2730.66 1006.62 2730.2 1006.91C2730.04 1006.57 2729.68 1006.14 2729.78 1005.91C2730 1005.41 2730.54 1005.24 2731.12 1005.35C2731.47 1005.42 2731.53 1005.67 2731.53 1005.97V1005.98Z" fill="white"/>
                    <path d="M2794.91 1155.08C2795.13 1154.71 2795.11 1154.06 2795.87 1154.34C2796.41 1154.54 2796.3 1154.91 2796.13 1155.27C2795.94 1155.67 2795.93 1156.3 2795.18 1156.02C2794.63 1155.82 2794.77 1155.46 2794.91 1155.07V1155.08Z" fill="white"/>
                    <path d="M2762.88 932.26C2762.32 931.45 2762.38 930.81 2762.76 930.2C2762.85 930.06 2763.05 929.99 2763.2 929.88C2763.28 930.06 2763.32 930.26 2763.43 930.41C2763.86 930.99 2763.54 931.52 2763.29 932.02C2763.21 932.17 2762.93 932.23 2762.88 932.26Z" fill="white"/>
                    <path d="M2778.72 1181.27C2778.58 1181.22 2778.65 1180.85 2778.83 1180.48C2779.02 1180.09 2779.02 1179.44 2779.78 1179.71C2780.31 1179.9 2780.23 1180.26 2780.06 1180.64C2779.87 1181.04 2779.85 1181.67 2778.72 1181.27Z" fill="white"/>
                    <path d="M2827.64 1140.27C2827.75 1140.19 2827.9 1140 2827.99 1140.03C2828.64 1140.22 2829.26 1140.43 2829.45 1141.24C2829.53 1141.57 2829.53 1141.77 2829.19 1141.79C2828.66 1141.81 2827.6 1140.76 2827.65 1140.28L2827.64 1140.27Z" fill="white"/>
                    <path d="M2750.23 1052.72C2749.46 1052.15 2750.29 1051.69 2750.39 1051.19C2750.43 1050.96 2750.91 1050.98 2751.15 1051.16C2751.86 1051.72 2751.15 1052.21 2751 1052.69C2750.93 1052.92 2750.5 1052.9 2750.22 1052.71L2750.23 1052.72Z" fill="white"/>
                    <path d="M2863.46 1081.48C2862.69 1080.91 2863.52 1080.45 2863.62 1079.95C2863.66 1079.72 2864.14 1079.74 2864.38 1079.92C2865.09 1080.48 2864.38 1080.97 2864.23 1081.45C2864.16 1081.68 2863.73 1081.66 2863.45 1081.47L2863.46 1081.48Z" fill="white"/>
                    <path d="M2837.15 949.34C2837.01 949.29 2837.08 948.92 2837.26 948.55C2837.45 948.16 2837.45 947.51 2838.21 947.78C2838.74 947.97 2838.66 948.33 2838.49 948.71C2838.3 949.11 2838.28 949.74 2837.15 949.34Z" fill="white"/>
                    <path d="M2931.76 1121.84C2932.38 1121.77 2933.03 1121.69 2933.67 1121.63C2933.7 1121.63 2933.79 1121.8 2933.78 1121.88C2933.71 1122.29 2933.45 1122.52 2933.05 1122.67C2932.59 1122.84 2932.16 1123.07 2931.79 1122.58C2931.7 1122.46 2931.77 1122.23 2931.77 1121.83L2931.76 1121.84Z" fill="white"/>
                    <path d="M2933.37 1066.46C2933.25 1066.84 2933.14 1067.26 2932.61 1067.12C2931.93 1066.94 2932.02 1066.5 2932.26 1066C2932.42 1065.65 2932.5 1065.21 2933.03 1065.36C2933.71 1065.54 2933.65 1065.99 2933.37 1066.47V1066.46Z" fill="white"/>
                    <path d="M2921.19 1006.68C2920.76 1006.33 2920.58 1006.11 2920.8 1005.8C2921.22 1005.21 2921.78 1004.92 2922.5 1005.19C2922.64 1005.24 2922.71 1005.48 2922.82 1005.63C2922.26 1005.99 2921.7 1006.36 2921.2 1006.68H2921.19Z" fill="white"/>
                    <path d="M2866.19 1113.11C2865.67 1112.98 2865.33 1112.72 2865.44 1112.5C2865.67 1112.05 2865.5 1111.2 2866.4 1111.29C2866.71 1111.32 2867.03 1111.6 2866.94 1111.85C2866.76 1112.33 2866.78 1113.05 2866.2 1113.11H2866.19Z" fill="white"/>
                    <path d="M2817.21 950.37C2816.83 950.2 2816.48 950.05 2816.71 949.52C2817.03 948.79 2817.5 949.21 2817.91 949.36C2818.29 949.49 2818.63 949.66 2818.4 950.2C2818.08 950.94 2817.62 950.48 2817.21 950.38V950.37Z" fill="white"/>
                    <path d="M2782.93 1034.87C2782.36 1035.64 2781.9 1034.81 2781.4 1034.71C2781.17 1034.67 2781.19 1034.19 2781.37 1033.95C2781.93 1033.24 2782.42 1033.95 2782.9 1034.1C2783.13 1034.17 2783.11 1034.6 2782.92 1034.88L2782.93 1034.87Z" fill="white"/>
                    <path d="M2931.97 1008.68C2931.85 1009.06 2931.74 1009.48 2931.21 1009.34C2930.53 1009.16 2930.62 1008.72 2930.86 1008.22C2931.02 1007.87 2931.1 1007.43 2931.63 1007.58C2932.31 1007.76 2932.25 1008.21 2931.97 1008.69V1008.68Z" fill="white"/>
                    <path d="M2882.89 1168.98C2882.69 1169.64 2882.44 1169.72 2882.13 1169.63C2881.45 1169.43 2881.57 1168.98 2881.81 1168.51C2881.94 1168.27 2882.09 1167.73 2882.26 1167.89C2882.62 1168.22 2882.77 1168.77 2882.88 1168.97L2882.89 1168.98Z" fill="white"/>
                    <path d="M2779.24 950.68C2779.13 950.56 2778.95 950.36 2778.77 950.16C2779.28 949.97 2779.73 949.47 2780.34 949.81C2780.6 949.96 2780.71 950.19 2780.56 950.53C2780.24 951.25 2779.79 950.83 2779.24 950.68Z" fill="white"/>
                    <path d="M2991.58 1001.1C2991.63 1000.84 2991.91 1000.73 2992.19 1000.81C2992.57 1000.91 2992.96 1001.13 2992.67 1001.62C2992.47 1001.96 2992.57 1002.74 2991.92 1002.49C2991.69 1002.4 2991.33 1001.74 2991.57 1001.1H2991.58Z" fill="white"/>
                    <path d="M2927.26 1175.5C2926.58 1175.32 2926.64 1174.62 2926.36 1174.15C2926.63 1174.15 2926.93 1174.09 2927.16 1174.18C2927.56 1174.33 2928.19 1174.36 2927.92 1175.11C2927.8 1175.45 2927.58 1175.52 2927.25 1175.51L2927.26 1175.5Z" fill="white"/>
                    <path d="M2959.03 991.63C2959.1 991.22 2958.92 991.62 2959.3 991.53C2959.51 991.48 2959.91 991.65 2959.99 991.83C2960.22 992.32 2960.69 992.81 2960.23 993.41C2960.18 993.48 2960.02 993.55 2959.98 993.52C2959.36 993.04 2959.38 992.23 2959.02 991.63H2959.03Z" fill="white"/>
                    <path d="M2905.22 1007.86C2905.04 1007.33 2904.55 1006.82 2905.03 1006.27C2905.15 1006.14 2905.61 1006.16 2905.81 1006.27C2906.42 1006.66 2905.88 1007.11 2905.78 1007.53C2905.71 1007.82 2905.56 1008 2905.22 1007.86Z" fill="white"/>
                    <path d="M2824.31 1060.5C2824.13 1060.43 2823.91 1060.39 2823.77 1060.27C2823.68 1060.19 2823.63 1059.91 2823.69 1059.84C2823.92 1059.6 2825.64 1060.28 2825.71 1060.63C2825.73 1060.71 2825.48 1060.95 2825.41 1060.93C2825.03 1060.82 2824.67 1060.65 2824.31 1060.51V1060.5Z" fill="white"/>
                    <path d="M2889.67 910.68C2889.08 910.48 2889.13 910.11 2889.33 909.74C2889.48 909.47 2889.71 909.37 2890.05 909.52C2890.56 909.73 2890.52 910.08 2890.32 910.44C2890.18 910.69 2889.94 910.89 2889.67 910.68Z" fill="white"/>
                    <path d="M2907.65 1176.68C2907.39 1176.48 2907.28 1176.28 2907.42 1175.94C2907.63 1175.43 2907.98 1175.47 2908.34 1175.67C2908.61 1175.81 2908.71 1176.05 2908.56 1176.39C2908.35 1176.91 2908.01 1176.82 2907.64 1176.68H2907.65Z" fill="white"/>
                    <path d="M2716.62 1065.18C2716.36 1064.98 2716.25 1064.78 2716.39 1064.44C2716.6 1063.93 2716.95 1063.97 2717.31 1064.17C2717.58 1064.31 2717.68 1064.55 2717.53 1064.89C2717.32 1065.41 2716.98 1065.32 2716.61 1065.18H2716.62Z" fill="white"/>
                    <path d="M2748.14 955.12C2748.29 954.78 2748.46 954.48 2748.98 954.68C2749.33 954.82 2749.43 955.05 2749.34 955.34C2749.23 955.74 2749.02 956.02 2748.5 955.81C2748.16 955.67 2748.09 955.46 2748.14 955.11V955.12Z" fill="white"/>
                    <path d="M2800.9 1178.71C2800.5 1179.09 2800.05 1179.36 2799.49 1179.21C2799.34 1179.17 2799.11 1178.88 2799.12 1178.87C2799.47 1178.43 2799.92 1178.18 2800.5 1178.34C2800.65 1178.38 2800.76 1178.57 2800.91 1178.71H2800.9Z" fill="white"/>
                    <path d="M2723.09 1104.2C2722.74 1104.13 2722.66 1103.85 2722.74 1103.56C2722.85 1103.16 2723.06 1102.88 2723.58 1103.09C2723.93 1103.23 2724.03 1103.46 2723.94 1103.75C2723.83 1104.15 2723.6 1104.45 2723.08 1104.21L2723.09 1104.2Z" fill="white"/>
                    <path d="M2841.18 1123.87C2840.92 1123.67 2840.81 1123.47 2840.95 1123.13C2841.16 1122.62 2841.51 1122.66 2841.87 1122.86C2842.14 1123 2842.24 1123.24 2842.09 1123.58C2841.88 1124.1 2841.54 1124.01 2841.17 1123.87H2841.18Z" fill="white"/>
                    <path d="M2945.97 946.41C2945.67 946.18 2945.55 945.98 2945.7 945.64C2945.91 945.13 2946.26 945.17 2946.62 945.37C2946.89 945.51 2946.98 945.75 2946.84 946.09C2946.63 946.62 2946.28 946.49 2945.97 946.42V946.41Z" fill="white"/>
                    <path d="M2941.48 970.61C2941.66 970.07 2942.04 970.11 2942.4 970.31C2942.67 970.46 2942.77 970.69 2942.62 971.03C2942.41 971.54 2942.06 971.5 2941.7 971.3C2941.43 971.16 2941.28 970.9 2941.48 970.6V970.61Z" fill="white"/>
                    <path d="M2761.14 1191.7C2761.5 1191.86 2761.87 1192.02 2761.61 1192.52C2761.53 1192.67 2761.38 1192.79 2761.26 1192.92C2761.04 1192.55 2760.82 1192.18 2760.6 1191.81C2760.78 1191.77 2760.96 1191.74 2761.14 1191.7Z" fill="white"/>
                    <path d="M2761.89 1137.4C2761.53 1137.24 2761.16 1137.08 2761.42 1136.58C2761.5 1136.43 2761.65 1136.31 2761.77 1136.18C2761.99 1136.55 2762.21 1136.92 2762.43 1137.29C2762.25 1137.33 2762.07 1137.36 2761.89 1137.4Z" fill="white"/>
                    <path d="M2899.81 1155.21C2899.64 1154.84 2899.48 1154.49 2899.33 1154.14C2899.6 1154.14 2899.91 1154.06 2900.12 1154.16C2900.6 1154.39 2900.4 1154.8 2900.21 1155.12C2900.16 1155.21 2899.93 1155.19 2899.81 1155.21Z" fill="white"/>
                    <path d="M2786.11 1026.96C2786.27 1026.79 2786.44 1026.52 2786.5 1026.55C2787 1026.73 2786.91 1027.29 2787.15 1027.65C2786.97 1027.68 2786.78 1027.78 2786.62 1027.74C2786.22 1027.63 2785.94 1027.41 2786.11 1026.97V1026.96Z" fill="white"/>
                    <path d="M2711.46 968.1C2711.38 967.97 2711.2 967.83 2711.22 967.72C2711.3 967.35 2711.45 966.93 2711.95 967.1C2712.18 967.18 2712.34 967.45 2712.53 967.64C2712.17 967.79 2711.82 967.94 2711.45 968.1H2711.46Z" fill="white"/>
                    <path d="M2726.67 993.85C2726.49 994.36 2726.11 994.27 2725.76 994.09C2725.62 994.01 2725.55 993.8 2725.45 993.65C2725.86 993.53 2726.28 993.42 2726.69 993.3C2726.69 993.48 2726.68 993.66 2726.67 993.84V993.85Z" fill="white"/>
                    <path d="M2883.03 1137.37C2882.98 1137.31 2882.8 1137.16 2882.82 1137.07C2882.9 1136.7 2883.04 1136.28 2883.54 1136.43C2883.77 1136.5 2883.94 1136.78 2884.13 1136.96C2883.77 1137.09 2883.42 1137.23 2883.03 1137.38V1137.37Z" fill="white"/>
                    <path d="M2847.54 1121.24C2847.03 1121.06 2847.12 1120.68 2847.3 1120.33C2847.38 1120.19 2847.59 1120.12 2847.74 1120.02C2847.86 1120.43 2847.97 1120.85 2848.09 1121.26C2847.91 1121.26 2847.73 1121.25 2847.55 1121.24H2847.54Z" fill="white"/>
                    <path d="M2698.47 1034.46C2698.36 1034.31 2698.25 1034.17 2698.14 1034.02C2698.55 1033.89 2698.95 1033.75 2699.36 1033.62C2699.36 1033.8 2699.4 1033.99 2699.36 1034.15C2699.22 1034.67 2698.86 1034.61 2698.47 1034.46Z" fill="white"/>
                    <path d="M2922.23 970C2922.05 970.51 2921.67 970.42 2921.32 970.24C2921.18 970.16 2921.11 969.95 2921.01 969.8C2921.42 969.68 2921.84 969.57 2922.25 969.45C2922.25 969.63 2922.24 969.81 2922.23 969.99V970Z" fill="white"/>
                    <path d="M2935.49 930.77C2935.24 930.81 2935.05 930.9 2934.89 930.85C2934.49 930.73 2934.2 930.49 2934.46 930.03C2934.54 929.88 2934.7 929.77 2934.83 929.64C2935.05 930.01 2935.27 930.38 2935.5 930.77H2935.49Z" fill="white"/>
                    <path d="M2961.48 1152.14C2961.23 1151.82 2961.48 1151.57 2961.59 1151.33C2961.63 1151.25 2961.95 1151.21 2962.01 1151.27C2962.27 1151.54 2962.01 1151.82 2961.9 1152.06C2961.86 1152.14 2961.62 1152.12 2961.48 1152.15V1152.14Z" fill="white"/>
                    <path d="M2935.53 1156.46C2935.2 1156.43 2934.92 1156.45 2934.67 1156.36C2934.53 1156.31 2934.46 1156.08 2934.35 1155.93C2934.62 1155.93 2934.9 1155.88 2935.15 1155.95C2935.29 1155.99 2935.36 1156.23 2935.52 1156.46H2935.53Z" fill="white"/>
                    <path d="M2829.71 913.06C2829.46 912.74 2829.71 912.49 2829.82 912.25C2829.86 912.17 2830.18 912.13 2830.24 912.19C2830.5 912.46 2830.24 912.74 2830.13 912.98C2830.09 913.06 2829.85 913.04 2829.71 913.07V913.06Z" fill="white"/>
                    <path d="M2730.54 965.56C2730.57 965.23 2730.55 964.95 2730.64 964.7C2730.69 964.56 2730.92 964.49 2731.07 964.38C2731.07 964.65 2731.12 964.93 2731.05 965.18C2731.01 965.32 2730.77 965.39 2730.54 965.55V965.56Z" fill="white"/>
                    <path d="M2729.71 968.69C2729.78 968.55 2729.86 968.28 2730.03 968.08C2730.22 967.85 2730.42 967.93 2730.39 968.22C2730.36 968.47 2730.25 968.75 2730.1 968.95C2729.92 969.19 2729.75 969.11 2729.72 968.68L2729.71 968.69Z" fill="white"/>
                    <path d="M2817.3 899.7C2817.18 899.83 2817.02 900.08 2816.93 900.06C2816.58 899.98 2816.6 899.71 2816.76 899.46C2816.85 899.31 2817.03 899.07 2817.13 899.09C2817.48 899.17 2817.44 899.43 2817.31 899.7H2817.3Z" fill="white"/>
                    <path d="M2904.3 1170.78C2904.17 1170.66 2903.92 1170.5 2903.94 1170.41C2904.02 1170.06 2904.29 1170.08 2904.54 1170.24C2904.69 1170.33 2904.93 1170.51 2904.91 1170.61C2904.83 1170.96 2904.57 1170.92 2904.3 1170.79V1170.78Z" fill="white"/>
                    <path d="M2705.89 1042.38C2705.56 1042.35 2705.27 1042.37 2705.03 1042.28C2704.89 1042.23 2704.82 1042 2704.71 1041.85C2704.98 1041.85 2705.26 1041.8 2705.51 1041.87C2705.65 1041.91 2705.72 1042.15 2705.88 1042.38H2705.89Z" fill="white"/>
                    <path d="M2969.06 1022.63C2968.99 1022.52 2968.88 1022.37 2968.78 1022.22C2969.05 1022.22 2969.33 1022.17 2969.58 1022.24C2969.72 1022.28 2969.79 1022.52 2969.9 1022.67C2969.63 1022.82 2969.36 1022.84 2969.06 1022.63Z" fill="white"/>
                    <path d="M2690.81 1085.99C2690.9 1085.71 2691.06 1085.49 2691.36 1085.68C2691.44 1085.73 2691.41 1086.03 2691.37 1086.2C2691.31 1086.49 2691.13 1086.7 2690.83 1086.51C2690.75 1086.46 2690.81 1086.17 2690.81 1085.99Z" fill="white"/>
                    <path d="M2931.65 1006.29C2931.74 1006.01 2931.9 1005.79 2932.2 1005.98C2932.28 1006.03 2932.25 1006.33 2932.21 1006.5C2932.15 1006.79 2931.97 1007 2931.67 1006.81C2931.59 1006.76 2931.65 1006.47 2931.65 1006.29Z" fill="white"/>
                    <path d="M2919.88 1011.29C2919.97 1011.01 2920.13 1010.79 2920.43 1010.98C2920.51 1011.03 2920.48 1011.33 2920.44 1011.5C2920.38 1011.79 2920.2 1012 2919.9 1011.81C2919.82 1011.76 2919.88 1011.47 2919.88 1011.29Z" fill="white"/>
                    <path d="M2879.56 1035.71C2879.23 1035.68 2878.95 1035.7 2878.7 1035.61C2878.56 1035.56 2878.49 1035.33 2878.38 1035.18C2878.65 1035.18 2878.93 1035.13 2879.18 1035.2C2879.32 1035.24 2879.39 1035.48 2879.55 1035.71H2879.56Z" fill="white"/>
                    <path d="M2890.1 1144.95C2889.95 1144.78 2889.78 1144.58 2889.6 1144.37C2889.78 1144.33 2890 1144.21 2890.13 1144.28C2890.36 1144.4 2890.53 1144.63 2890.72 1144.82C2890.54 1144.86 2890.36 1144.89 2890.1 1144.95Z" fill="white"/>
                    <path d="M2813.81 1086.79C2813.74 1086.68 2813.63 1086.53 2813.53 1086.38C2813.8 1086.38 2814.08 1086.33 2814.33 1086.4C2814.47 1086.44 2814.54 1086.68 2814.65 1086.83C2814.38 1086.98 2814.11 1087 2813.81 1086.79Z" fill="white"/>
                    <path d="M2789.5 1126.81C2789.17 1126.78 2788.88 1126.8 2788.64 1126.71C2788.5 1126.66 2788.43 1126.43 2788.32 1126.28C2788.59 1126.28 2788.87 1126.23 2789.12 1126.3C2789.26 1126.34 2789.33 1126.58 2789.49 1126.81H2789.5Z" fill="white"/>
                    <path d="M2751.89 1189.83C2751.64 1189.51 2751.89 1189.26 2752 1189.02C2752.04 1188.94 2752.36 1188.9 2752.42 1188.96C2752.68 1189.23 2752.42 1189.51 2752.31 1189.75C2752.27 1189.83 2752.03 1189.81 2751.89 1189.84V1189.83Z" fill="white"/>
                    <path d="M2746.9 1124.21C2746.99 1123.93 2747.15 1123.71 2747.45 1123.9C2747.53 1123.95 2747.5 1124.25 2747.46 1124.42C2747.4 1124.71 2747.22 1124.92 2746.92 1124.73C2746.84 1124.68 2746.9 1124.39 2746.9 1124.21Z" fill="white"/>
                    <path d="M2834.45 931.33C2834.33 931.46 2834.17 931.71 2834.08 931.69C2833.73 931.61 2833.75 931.34 2833.91 931.09C2834 930.94 2834.18 930.7 2834.28 930.72C2834.63 930.8 2834.59 931.06 2834.46 931.33H2834.45Z" fill="white"/>
                    <path d="M2812.68 1027.2C2812.55 1027.08 2812.3 1026.92 2812.32 1026.83C2812.4 1026.48 2812.67 1026.5 2812.92 1026.66C2813.07 1026.75 2813.31 1026.93 2813.29 1027.03C2813.21 1027.38 2812.95 1027.34 2812.68 1027.21V1027.2Z" fill="white"/>
                    <path d="M2797.81 1022.18C2797.66 1022.19 2797.51 1022.23 2797.36 1022.2C2797.31 1022.2 2797.28 1022.04 2797.25 1021.95C2797.4 1021.94 2797.55 1021.9 2797.7 1021.93C2797.75 1021.93 2797.78 1022.09 2797.81 1022.18Z" fill="white"/>
                    <path d="M2687.83 1033.74C2687.68 1033.75 2687.53 1033.79 2687.38 1033.76C2687.33 1033.76 2687.3 1033.6 2687.27 1033.51C2687.42 1033.5 2687.57 1033.46 2687.72 1033.49C2687.77 1033.49 2687.8 1033.65 2687.83 1033.74Z" fill="white"/>
                    <path d="M2912.41 1021.17C2912.32 1021.06 2912.22 1020.94 2912.13 1020.83C2912.22 1020.79 2912.34 1020.7 2912.38 1020.73C2912.5 1020.81 2912.59 1020.94 2912.68 1021.06C2912.59 1021.1 2912.51 1021.13 2912.4 1021.18L2912.41 1021.17Z" fill="white"/>
                    <path d="M2942.12 984.59C2942.16 984.69 2942.2 984.78 2942.24 984.87C2942.09 984.88 2941.94 984.92 2941.79 984.89C2941.74 984.89 2941.71 984.73 2941.68 984.64C2941.83 984.62 2941.98 984.6 2942.12 984.59Z" fill="white"/>
                    <path d="M2910.7 1066.51C2910.81 1066.42 2910.93 1066.32 2911.04 1066.23C2911.08 1066.32 2911.17 1066.44 2911.14 1066.48C2911.06 1066.6 2910.93 1066.69 2910.81 1066.78C2910.77 1066.69 2910.74 1066.61 2910.69 1066.5L2910.7 1066.51Z" fill="white"/>
                    <path d="M2866.99 1083.57C2866.98 1083.42 2866.94 1083.27 2866.97 1083.12C2866.97 1083.07 2867.13 1083.04 2867.22 1083.01C2867.23 1083.16 2867.27 1083.31 2867.24 1083.46C2867.24 1083.51 2867.08 1083.54 2866.99 1083.57Z" fill="white"/>
                    <path d="M2843.97 1127.18C2844.07 1127.14 2844.16 1127.1 2844.25 1127.06C2844.26 1127.21 2844.3 1127.36 2844.27 1127.51C2844.27 1127.56 2844.11 1127.59 2844.02 1127.62C2844 1127.47 2843.98 1127.32 2843.97 1127.18Z" fill="white"/>
                    <path d="M2890.04 1166.89C2889.95 1166.78 2889.85 1166.66 2889.76 1166.55C2889.85 1166.51 2889.97 1166.42 2890.01 1166.45C2890.13 1166.53 2890.22 1166.66 2890.31 1166.78C2890.22 1166.82 2890.14 1166.85 2890.03 1166.9L2890.04 1166.89Z" fill="white"/>
                    <path d="M2919.89 1048.9C2919.85 1048.8 2919.81 1048.71 2919.77 1048.62C2919.92 1048.61 2920.07 1048.57 2920.22 1048.6C2920.27 1048.6 2920.3 1048.76 2920.33 1048.85C2920.18 1048.87 2920.03 1048.89 2919.89 1048.9Z" fill="white"/>
                    <path d="M2845.16 1060.55C2845.2 1060.65 2845.24 1060.74 2845.28 1060.83C2845.13 1060.84 2844.98 1060.88 2844.83 1060.85C2844.78 1060.85 2844.75 1060.69 2844.72 1060.6C2844.87 1060.58 2845.02 1060.56 2845.16 1060.55Z" fill="white"/>
                    <path d="M2779.68 1191.11C2779.59 1191.15 2779.45 1191.23 2779.43 1191.21C2779.34 1191.1 2779.21 1190.96 2779.2 1190.83C2779.18 1190.55 2779.34 1190.48 2779.53 1190.69C2779.62 1190.79 2779.63 1190.97 2779.67 1191.11H2779.68Z" fill="white"/>
                    <path d="M2779.05 1190.48C2779.01 1190.33 2778.89 1190.12 2778.94 1190.06C2779.09 1189.87 2779.28 1189.9 2779.31 1190.09C2779.34 1190.25 2779.31 1190.42 2779.3 1190.59C2779.22 1190.56 2779.13 1190.52 2779.05 1190.49V1190.48Z" fill="white"/>
                    <path d="M2794.26 991.73C2794.17 991.62 2794.07 991.5 2793.98 991.39C2794.07 991.35 2794.19 991.26 2794.23 991.29C2794.35 991.37 2794.44 991.5 2794.53 991.62C2794.44 991.66 2794.36 991.69 2794.25 991.74L2794.26 991.73Z" fill="white"/>
                    <path d="M2860.77 1223.35C2860.68 1223.24 2860.58 1223.12 2860.49 1223.01C2860.58 1222.97 2860.7 1222.88 2860.74 1222.91C2860.86 1222.99 2860.95 1223.12 2861.04 1223.24C2860.95 1223.28 2860.87 1223.31 2860.76 1223.36L2860.77 1223.35Z" fill="white"/>
                    <path d="M2772.58 1194.93C2772.54 1194.83 2772.5 1194.74 2772.46 1194.65C2772.61 1194.64 2772.76 1194.6 2772.91 1194.63C2772.96 1194.63 2772.99 1194.79 2773.02 1194.88C2772.87 1194.9 2772.72 1194.92 2772.58 1194.93Z" fill="white"/>
                    <path d="M2885.73 1186.79C2885.82 1186.9 2885.92 1187.02 2886.01 1187.13C2885.92 1187.17 2885.8 1187.26 2885.76 1187.23C2885.64 1187.15 2885.55 1187.02 2885.46 1186.9C2885.55 1186.86 2885.63 1186.83 2885.74 1186.78L2885.73 1186.79Z" fill="white"/>
                    <path d="M2774.5 1089.08C2774.41 1088.97 2774.31 1088.85 2774.22 1088.74C2774.31 1088.7 2774.43 1088.61 2774.47 1088.64C2774.59 1088.72 2774.68 1088.85 2774.77 1088.97C2774.68 1089.01 2774.6 1089.04 2774.49 1089.09L2774.5 1089.08Z" fill="white"/>
                    <path d="M2914.15 1098.27C2914.25 1098.23 2914.34 1098.19 2914.43 1098.15C2914.44 1098.3 2914.48 1098.45 2914.45 1098.6C2914.45 1098.65 2914.29 1098.68 2914.2 1098.71C2914.18 1098.56 2914.16 1098.41 2914.15 1098.27Z" fill="white"/>
                    <path d="M2772.89 1159.71C2772.74 1159.72 2772.59 1159.76 2772.44 1159.73C2772.39 1159.73 2772.36 1159.57 2772.33 1159.48C2772.48 1159.47 2772.63 1159.43 2772.78 1159.46C2772.83 1159.46 2772.86 1159.62 2772.89 1159.71Z" fill="white"/>
                    <path d="M2911.81 1111.3C2911.72 1111.19 2911.62 1111.07 2911.53 1110.96C2911.62 1110.92 2911.74 1110.83 2911.78 1110.86C2911.9 1110.94 2911.99 1111.07 2912.08 1111.19L2911.8 1111.31L2911.81 1111.3Z" fill="white"/>
                    <path d="M2819.78 1036.8C2819.67 1036.89 2819.55 1036.99 2819.44 1037.08C2819.4 1036.99 2819.31 1036.87 2819.34 1036.83C2819.42 1036.71 2819.55 1036.62 2819.67 1036.53C2819.71 1036.62 2819.74 1036.7 2819.79 1036.81L2819.78 1036.8Z" fill="white"/>
                    <path d="M2808.8 896.59C2808.65 896.6 2808.5 896.64 2808.35 896.61C2808.3 896.61 2808.27 896.45 2808.24 896.36C2808.39 896.35 2808.54 896.31 2808.69 896.34C2808.74 896.34 2808.77 896.5 2808.8 896.59Z" fill="white"/>
                    <path d="M2780.09 1212.69C2778.11 1213.15 2777.13 1211.9 2776.23 1210.71C2775.9 1210.27 2775.49 1209.52 2775.24 1209.36C2773.63 1208.32 2775.56 1207.12 2774.95 1206.12C2776.01 1205.68 2777.14 1204.8 2778.13 1204.93C2779.07 1205.06 2780.23 1205.32 2781.1 1205.91C2781.93 1206.47 2783.35 1206.88 2782.18 1208.41C2782.01 1208.64 2782.3 1209.2 2782.33 1209.61C2782.47 1211.43 2781.67 1212.46 2780.09 1212.7V1212.69Z" fill="white"/>
                    <path d="M2866.16 1072.78C2866.32 1073.97 2865.5 1074.95 2865.08 1075C2863.46 1075.2 2863.02 1076.77 2861.82 1077.43C2860.97 1077.89 2860.38 1078.04 2859.66 1077.43C2858.48 1076.43 2858.25 1075.76 2858.78 1074.76C2859.01 1074.33 2859.18 1073.89 2859.35 1073.43C2860.07 1071.44 2864.14 1070.08 2865.68 1071.96C2865.95 1072.29 2866.19 1072.57 2866.17 1072.77L2866.16 1072.78Z" fill="white"/>
                    <path d="M2773.02 1103.44C2772.36 1104.27 2772.81 1105.75 2771.38 1106.07C2771.18 1106.12 2770.93 1106.07 2770.77 1106.18C2770.13 1106.62 2769.61 1107.33 2768.68 1107.01C2767.95 1106.75 2767.73 1106.03 2767.34 1105.47C2766.57 1104.37 2766.92 1102.51 2768.03 1101.54C2768.89 1100.78 2769.81 1100.68 2770.9 1101.09C2772.04 1101.51 2772.34 1102.62 2773 1103.45L2773.02 1103.44Z" fill="white"/>
                    <path d="M2687.32 985.42C2687.06 983.64 2687.59 982.73 2688.85 982.38C2689.55 982.19 2690.29 982.18 2691 982.03C2691.67 981.89 2691.92 982.15 2691.98 982.79C2692.05 983.51 2692.3 984.23 2692.28 984.94C2692.25 986.15 2691.56 987.05 2690.57 987.7C2690.37 987.83 2689.93 987.86 2689.75 987.73C2689.1 987.24 2688.35 987.01 2687.65 986.67C2687.31 986.5 2687.35 985.55 2687.32 985.42Z" fill="white"/>
                    <path d="M2817.6 945.7C2817.79 946.5 2818.08 947.62 2816.88 948.15C2816.31 948.4 2815.24 948.87 2814.91 948.17C2814.54 947.41 2813.5 946.86 2813.87 945.8C2814.12 945.07 2814.65 944.39 2814.71 943.65C2814.77 942.97 2814.96 942.81 2815.56 942.7C2816.42 942.55 2816.96 942.99 2817.25 943.64C2817.49 944.19 2817.47 944.86 2817.6 945.69V945.7Z" fill="white"/>
                    <path d="M2664.84 986.99C2664.98 987.8 2664.45 988.59 2663.66 988.76C2663.4 988.82 2660.64 987.77 2660.51 987.56C2660.21 987.09 2660.99 985.35 2661.05 985.43C2661.93 986.4 2662.33 984.85 2663.01 985.12C2663.66 985.38 2664.75 986.48 2664.84 987V986.99Z" fill="white"/>
                    <path d="M2733.57 1158.59C2734 1158.54 2734.64 1158.32 2734.7 1158.73C2734.82 1159.48 2735.3 1160.26 2734.7 1161.08C2734.37 1161.54 2733.8 1161.55 2733.53 1161.92C2733.08 1162.52 2732.71 1162.67 2732.02 1162.22C2731.28 1161.74 2731.06 1161 2731.39 1160.41C2731.85 1159.57 2732.3 1158.5 2733.57 1158.59Z" fill="white"/>
                    <path d="M2802.08 1074.99C2802.02 1074.34 2801.56 1073.55 2802.8 1073.41C2803.08 1073.38 2803.28 1072.78 2803.54 1072.46C2803.84 1072.1 2804.17 1071.77 2804.48 1071.42C2804.65 1072.09 2805.1 1072.84 2804.93 1073.42C2804.72 1074.16 2804 1074.75 2803.54 1075.44C2803.29 1075.82 2802.99 1076.12 2802.56 1076C2802.09 1075.88 2802.14 1075.37 2802.08 1074.98V1074.99Z" fill="white"/>
                    <path d="M2639.6 1110.51C2639.57 1110.31 2639.52 1110.11 2639.51 1109.91C2639.51 1109.46 2639.07 1108.87 2639.75 1108.6C2640.44 1108.32 2641.09 1108.45 2641.68 1109C2642.17 1109.47 2641.99 1109.82 2641.7 1110.29C2641.51 1110.59 2641.59 1111.05 2641.41 1111.36C2641.17 1111.77 2640.96 1112.33 2640.34 1112.27C2639.6 1112.2 2639.84 1111.54 2639.71 1111.1C2639.65 1110.91 2639.64 1110.7 2639.61 1110.5L2639.6 1110.51Z" fill="white"/>
                    <path d="M2670.2 977.02C2669.31 976.68 2668.28 976.6 2667.58 975.84C2667.42 975.67 2667.35 975.28 2667.61 975C2667.94 974.64 2668.12 973.96 2668.72 974.11C2669.46 974.3 2670.17 974.64 2670.85 975C2671.66 975.42 2670.62 975.74 2670.74 976.16C2670.87 976.6 2670.77 976.96 2670.2 977.01V977.02Z" fill="white"/>
                    <path d="M2812.84 1124.57C2812.66 1124.03 2812.8 1123.66 2813.49 1123.59C2814.56 1123.48 2815.24 1123.95 2815.43 1124.89C2815.6 1125.72 2815.15 1126.26 2814.53 1126.57C2813.82 1126.92 2813.02 1125.94 2812.84 1124.58V1124.57Z" fill="white"/>
                    <path d="M2897.24 1164.45C2897.19 1164.14 2897.09 1163.83 2897.11 1163.53C2897.16 1162.82 2897.95 1162.47 2898.52 1162.89C2898.85 1163.13 2899.25 1163.33 2899.5 1163.64C2899.83 1164.04 2899.96 1164.64 2899.72 1165.05C2899.45 1165.52 2898.66 1165.5 2898.4 1166.1C2897.98 1165.69 2897.24 1165.57 2897.28 1164.77C2897.28 1164.67 2897.25 1164.57 2897.24 1164.46V1164.45Z" fill="white"/>
                    <path d="M2686.93 1032.22C2686.71 1032.51 2686.52 1032.75 2686.35 1033.01C2685.94 1033.66 2684.93 1033.1 2684.56 1033.92C2684.37 1034.35 2683.9 1034.42 2683.55 1034.11C2683.22 1033.81 2683.28 1033.41 2683.57 1033.05C2683.95 1032.57 2684.27 1032.04 2684.68 1031.58C2684.87 1031.36 2684.91 1030.71 2685.49 1031.17C2685.96 1031.54 2686.45 1031.88 2686.93 1032.23V1032.22Z" fill="white"/>
                    <path d="M2745.94 918.05C2746.6 918.05 2747.36 918.97 2747.32 919.72C2747.31 920.02 2745.88 921.3 2745.52 921.26C2744.61 921.15 2744.92 920.34 2744.84 919.83C2744.71 918.89 2745.3 918.05 2745.93 918.05H2745.94Z" fill="white"/>
                    <path d="M2802.87 983.17C2802.26 983.36 2801.86 983.28 2801.78 982.57C2801.69 981.7 2802.15 980.76 2802.77 980.67C2803.38 980.58 2804.14 981.35 2804.26 982.2C2804.44 983.48 2803.34 982.9 2802.88 983.16L2802.87 983.17Z" fill="white"/>
                    <path d="M2809.89 986.05C2809.67 986.58 2809.46 987.16 2808.82 987.31C2808.13 987.46 2807.23 986.36 2807.28 985.51C2807.31 984.95 2807.72 984.93 2808.12 984.9C2809.04 984.85 2809.88 985.39 2809.89 986.04V986.05Z" fill="white"/>
                    <path d="M2717.69 1010.52C2717.63 1010.12 2717.62 1009.91 2717.56 1009.72C2717.35 1008.99 2718.01 1008.66 2718.27 1008.16C2718.67 1008.57 2719.4 1008.68 2719.42 1009.44C2719.42 1009.64 2719.46 1009.84 2719.51 1010.04C2719.67 1010.71 2719.65 1011.15 2718.75 1011.27C2717.89 1011.39 2717.64 1011.07 2717.69 1010.52Z" fill="white"/>
                    <path d="M2896.47 1002.28C2895.76 1002.44 2895.54 1001.98 2895.64 1001.61C2895.84 1000.87 2896.19 1000.15 2896.53 999.46C2896.85 998.81 2897.14 999.4 2897.42 999.54C2898.64 1000.18 2897.38 1000.92 2897.47 1001.63C2897.58 1002.45 2896.74 1002.09 2896.46 1002.28H2896.47Z" fill="white"/>
                    <path d="M2751.03 993.23C2751.32 994.04 2750.86 994.61 2750.26 994.93C2749.8 995.17 2748.45 994.12 2748.6 993.72C2748.88 992.95 2749.47 992.55 2750.32 992.49C2750.92 992.45 2750.96 992.82 2751.03 993.23Z" fill="white"/>
                    <path d="M2679.44 1168.79C2678.83 1168.78 2678.42 1168.35 2678.09 1167.87C2677.89 1167.59 2679.1 1166.37 2679.72 1166.22C2680.28 1166.08 2680.4 1166.46 2680.45 1166.88C2680.55 1167.73 2680.07 1168.67 2679.45 1168.78L2679.44 1168.79Z" fill="white"/>
                    <path d="M2711.18 975.16C2711.49 975.21 2711.83 975.38 2711.71 975.72C2711.42 976.54 2711.35 977.44 2710.76 978.17C2710.51 978.47 2710.18 978.55 2709.96 978.47C2709.75 978.39 2709.49 977.95 2709.55 977.77C2709.78 977.03 2710.12 976.32 2710.44 975.61C2710.58 975.31 2710.84 975.17 2711.19 975.16H2711.18Z" fill="white"/>
                    <path d="M2692.77 946.82C2692.85 947.14 2692.95 947.54 2692.44 947.73C2691.99 947.89 2691.72 947.55 2691.37 947.38C2690.67 947.03 2690.77 946.35 2690.76 945.81C2690.76 945.49 2691.21 944.87 2691.41 944.89C2691.82 944.93 2692.29 945.24 2692.54 945.58C2692.75 945.87 2692.69 946.35 2692.77 946.82Z" fill="white"/>
                    <path d="M2862.8 957.57C2863.29 957.41 2863.65 957.55 2863.72 958.24C2863.8 959.1 2863.34 960.01 2862.73 960.12C2861.71 960.3 2862.21 958.91 2861.39 958.79C2861.25 958.77 2862.03 957.75 2862.8 957.57Z" fill="white"/>
                    <path d="M2705.11 1068.44C2705.02 1068.6 2704.97 1068.86 2704.83 1068.91C2704.08 1069.22 2702.52 1068.32 2702.46 1067.55C2702.44 1067.28 2702.49 1066.89 2702.66 1066.77C2702.84 1066.65 2703.24 1066.7 2703.47 1066.82C2704.48 1067.36 2705.02 1067.92 2705.1 1068.43L2705.11 1068.44Z" fill="white"/>
                    <path d="M2765.64 1167.56C2765.94 1167.51 2766.25 1167.41 2766.55 1167.43C2767.32 1167.47 2767.08 1168.15 2767.2 1168.58C2767.36 1169.14 2766.96 1169.26 2766.54 1169.32C2766.15 1169.37 2765.78 1169.51 2765.4 1169.19C2765.02 1168.87 2764.47 1168.63 2764.63 1168.03C2764.75 1167.56 2765.27 1167.64 2765.65 1167.56H2765.64Z" fill="white"/>
                    <path d="M2713.31 977.9C2713.34 977.26 2713.77 977.25 2714.16 977.23C2715.09 977.18 2715.92 977.72 2715.91 978.38C2715.91 978.93 2715.46 978.95 2715.06 978.97C2714.13 979.01 2713.33 978.48 2713.31 977.9Z" fill="white"/>
                    <path d="M2861.94 948.85C2862.5 949.3 2863.14 949.58 2863.18 950.45C2863.21 951.17 2862.86 951.25 2862.32 951.35C2861.74 951.46 2861.45 951.37 2861.26 950.71C2861.01 949.85 2861.63 949.44 2861.94 948.85Z" fill="white"/>
                    <path d="M2868.26 963.2C2868.18 963.37 2868.14 963.66 2868.02 963.7C2867.64 963.82 2867.15 964.01 2866.85 963.87C2866.35 963.64 2865.69 963.41 2865.67 962.68C2865.65 962.12 2866.1 962.09 2866.5 962.07C2867.44 962.03 2868.21 962.53 2868.27 963.21L2868.26 963.2Z" fill="white"/>
                    <path d="M2727.55 1185.85C2727.6 1186.27 2727.65 1186.73 2727.07 1186.72C2726.32 1186.72 2725.68 1186.33 2725.65 1185.56C2725.64 1185.15 2726.11 1184.73 2726.36 1184.32C2726.88 1184.74 2727.63 1184.99 2727.55 1185.86V1185.85Z" fill="white"/>
                    <path d="M2876.9 1117.04C2876.16 1117.1 2875.27 1116.37 2875.27 1115.85C2875.27 1115.3 2875.72 1115.33 2876.1 1115.25C2876.87 1115.09 2877.44 1115.43 2877.65 1116.08C2877.71 1116.27 2877.29 1116.98 2876.89 1117.05L2876.9 1117.04Z" fill="white"/>
                    <path d="M2769.98 980.97C2770.06 981.48 2770.11 982.03 2769.56 981.95C2768.88 981.85 2768.24 981.5 2767.58 981.26C2767.96 980.66 2768.34 980.15 2769.2 980.12C2770.01 980.09 2769.88 980.64 2769.99 980.97H2769.98Z" fill="white"/>
                    <path d="M2911.31 1046.41C2911.53 1047.24 2911.14 1047.35 2910.56 1047.49C2909.66 1047.7 2909.55 1047.23 2909.44 1046.55C2909.33 1045.85 2909.66 1045.73 2910.25 1045.6C2911.11 1045.42 2911.37 1045.78 2911.32 1046.41H2911.31Z" fill="white"/>
                    <path d="M2757.84 975.58C2757.29 975.57 2756.48 976.11 2756.47 975.05C2756.47 974.47 2756.85 973.91 2757.48 973.74C2758.52 973.46 2758.4 974.65 2758.74 975.06C2759.17 975.58 2758.12 975.32 2757.84 975.57V975.58Z" fill="white"/>
                    <path d="M2674.76 936.44C2674.8 936.74 2674.9 937.04 2674.89 937.34C2674.87 937.88 2674.43 937.89 2674.03 937.93C2673.75 937.95 2673.43 938.16 2673.19 937.85C2672.92 937.5 2672.93 937.14 2673.23 936.79C2673.36 936.64 2673.47 936.46 2673.59 936.3C2673.83 936 2674.1 935.43 2674.31 935.45C2674.77 935.5 2674.66 936.07 2674.75 936.44H2674.76Z" fill="white"/>
                    <path d="M2848.92 1169.3C2849.65 1169.41 2849.46 1170.04 2849.54 1170.48C2849.7 1171.3 2849 1171.15 2848.57 1171.25C2848.16 1171.34 2847.74 1171.36 2847.71 1170.77C2847.68 1170.16 2848.39 1169.32 2848.92 1169.31V1169.3Z" fill="white"/>
                    <path d="M2830.31 1006.13C2829.77 1006.19 2829.35 1006.25 2829.32 1005.65C2829.29 1005.04 2830 1004.18 2830.53 1004.2C2831.08 1004.22 2831.02 1004.69 2831.13 1005.06C2831.4 1005.97 2830.86 1006.09 2830.31 1006.13Z" fill="white"/>
                    <path d="M2722 953.89C2722.21 953.92 2722.64 953.85 2722.74 954C2723.02 954.41 2722.53 954.7 2722.31 954.88C2721.98 955.15 2722.05 956.29 2721.13 955.53C2720.83 955.28 2720.43 955.09 2720.49 954.63C2720.61 953.68 2721.47 954.22 2722 953.89Z" fill="white"/>
                    <path d="M2659.73 1101.86C2659.51 1102.29 2659.36 1103.17 2658.81 1102.88C2658.33 1102.62 2657.57 1103.04 2657.39 1102.32C2657.29 1101.89 2658.69 1101.05 2659.27 1101.18C2659.57 1101.25 2659.74 1101.5 2659.73 1101.86Z" fill="white"/>
                    <path d="M2892.63 1051.49C2892.63 1051.49 2893 1051.9 2893.27 1052.32C2893.39 1052.5 2892.35 1053.48 2892.06 1053.46C2891.29 1053.41 2891.53 1052.76 2891.41 1052.33C2891.26 1051.81 2891.58 1051.63 2892.63 1051.49Z" fill="white"/>
                    <path d="M2893.12 1088.2C2893.02 1088.94 2892.39 1088.71 2891.96 1088.83C2891.42 1088.98 2891.16 1088.59 2891.25 1088.2C2891.36 1087.75 2891.69 1087.33 2891.99 1086.96C2892.1 1086.83 2893.12 1087.93 2893.11 1088.21L2893.12 1088.2Z" fill="white"/>
                    <path d="M2759.96 1223.32C2759.44 1223.51 2759.19 1223.29 2759.15 1223C2759.04 1222.21 2759.47 1221.72 2760.17 1221.43C2760.53 1221.28 2760.78 1221.51 2760.83 1221.8C2760.98 1222.61 2760.48 1223.06 2759.96 1223.31V1223.32Z" fill="white"/>
                    <path d="M2683.39 1058.95C2684.05 1058.95 2684.6 1059.29 2684.68 1059.81C2684.72 1060.07 2684.34 1060.73 2683.73 1060.81C2682.92 1060.91 2683.07 1060.27 2682.97 1059.84C2682.87 1059.43 2682.88 1059.05 2683.39 1058.96V1058.95Z" fill="white"/>
                    <path d="M2856.24 1188.38C2855.96 1188.46 2854.65 1187.81 2854.75 1187.66C2855.01 1187.26 2855.32 1186.83 2855.72 1186.59C2856.09 1186.37 2856.5 1186.56 2856.52 1187.1C2856.53 1187.54 2856.95 1188.09 2856.24 1188.37V1188.38Z" fill="white"/>
                    <path d="M2680.65 1053.22C2680.56 1053.79 2680.29 1054.4 2679.47 1054.5C2679.17 1054.54 2678.88 1054.37 2678.91 1053.98C2678.97 1053.23 2679.31 1052.67 2680.1 1052.54C2680.39 1052.49 2680.67 1052.67 2680.65 1053.22Z" fill="white"/>
                    <path d="M2611.16 1048.55C2611.28 1049.11 2610.91 1049.37 2610.52 1049.28C2610.07 1049.17 2609.65 1048.84 2609.28 1048.54C2609.15 1048.43 2610.25 1047.41 2610.53 1047.42C2610.92 1047.44 2611.17 1047.88 2611.16 1048.56V1048.55Z" fill="white"/>
                    <path d="M2760.37 980.03C2760.43 980.46 2760.49 980.89 2759.91 980.93C2759.28 980.98 2758.73 980.61 2758.65 980.08C2758.61 979.82 2758.99 979.16 2759.6 979.08C2760.42 978.97 2760.24 979.63 2760.37 980.03Z" fill="white"/>
                    <path d="M2662.53 968.31C2662.34 968.86 2662.11 969.35 2661.5 969.52C2661.14 969.62 2660.87 969.42 2660.86 969.12C2660.86 968.62 2660.26 967.91 2661.24 967.67C2661.84 967.52 2662.07 968.14 2662.54 968.31H2662.53Z" fill="white"/>
                    <path d="M2752.06 991.77C2751.97 991.93 2751.89 992.1 2751.8 992.26C2751.07 991.75 2750.41 991.1 2749.43 991.04C2749.39 991.04 2749.31 990.64 2749.35 990.45C2749.37 990.34 2749.69 990.18 2749.72 990.21C2750.33 990.77 2751.35 990.47 2751.85 991.21C2751.96 991.37 2751.99 991.58 2752.05 991.76L2752.06 991.77Z" fill="white"/>
                    <path d="M2747.55 993.55C2746.94 993.61 2747.15 992.77 2747.12 992.3C2747.07 991.35 2747.94 991.72 2748.43 991.57C2748.57 991.52 2749.18 991.77 2748.7 992.01C2747.99 992.37 2749.07 993.49 2747.55 993.56V993.55Z" fill="white"/>
                    <path d="M2787.45 1182.52C2788.17 1182.29 2788.48 1182.96 2788.98 1183.21C2788.76 1183.53 2788.57 1184.08 2788.32 1184.11C2787.76 1184.18 2787.32 1183.79 2787.12 1183.21C2787 1182.87 2787.19 1182.67 2787.46 1182.51L2787.45 1182.52Z" fill="white"/>
                    <path d="M2886.67 1047.35C2886.23 1047.35 2885.66 1047.71 2885.51 1046.89C2885.4 1046.3 2885.79 1046.21 2886.2 1046.16C2886.65 1046.12 2887.22 1045.79 2887.37 1046.61C2887.48 1047.2 2887.09 1047.27 2886.67 1047.35Z" fill="white"/>
                    <path d="M2705.28 1193.56C2704.85 1194.49 2704.26 1194.77 2703.51 1194.76C2703.34 1194.76 2703.17 1194.61 2702.99 1194.53C2703.11 1194.37 2703.26 1194.22 2703.34 1194.04C2703.63 1193.35 2704.27 1193.36 2704.85 1193.32C2705.03 1193.31 2705.22 1193.53 2705.28 1193.56Z" fill="white"/>
                    <path d="M2918.54 1047.93C2918.57 1048.08 2918.2 1048.22 2917.77 1048.25C2917.32 1048.28 2916.74 1048.64 2916.59 1047.81C2916.48 1047.23 2916.84 1047.11 2917.27 1047.07C2917.72 1047.03 2918.3 1046.71 2918.54 1047.93Z" fill="white"/>
                    <path d="M2856.2 1026.04C2856.07 1025.98 2855.82 1025.95 2855.8 1025.86C2855.63 1025.18 2855.48 1024.52 2856.11 1023.93C2856.37 1023.69 2856.54 1023.58 2856.74 1023.88C2857.04 1024.34 2856.67 1025.84 2856.21 1026.05L2856.2 1026.04Z" fill="white"/>
                    <path d="M2819.17 1141.18C2819.07 1142.17 2818.22 1141.67 2817.72 1141.84C2817.5 1141.92 2817.25 1141.49 2817.29 1141.17C2817.41 1140.24 2818.23 1140.62 2818.74 1140.49C2818.98 1140.43 2819.19 1140.82 2819.17 1141.17V1141.18Z" fill="white"/>
                    <path d="M2784.96 1025.22C2784.86 1026.21 2784.01 1025.71 2783.51 1025.88C2783.29 1025.96 2783.04 1025.53 2783.08 1025.21C2783.2 1024.28 2784.02 1024.66 2784.53 1024.53C2784.77 1024.47 2784.98 1024.86 2784.96 1025.21V1025.22Z" fill="white"/>
                    <path d="M2681.25 1118.43C2681.28 1118.58 2680.91 1118.72 2680.48 1118.75C2680.03 1118.78 2679.45 1119.14 2679.3 1118.31C2679.19 1117.73 2679.55 1117.61 2679.98 1117.57C2680.43 1117.53 2681.01 1117.21 2681.25 1118.43Z" fill="white"/>
                    <path d="M2784.8 943.12C2784.41 942.6 2784 942.07 2783.61 941.53C2783.59 941.51 2783.7 941.33 2783.78 941.3C2784.19 941.15 2784.53 941.25 2784.87 941.53C2785.26 941.85 2785.7 942.11 2785.46 942.7C2785.4 942.84 2785.16 942.9 2784.8 943.11V943.12Z" fill="white"/>
                    <path d="M2734.66 970.93C2735.06 970.84 2735.49 970.71 2735.65 971.26C2735.85 971.96 2735.41 972.11 2734.84 972.17C2734.44 972.21 2734.01 972.37 2733.86 971.82C2733.67 971.12 2734.09 970.94 2734.67 970.93H2734.66Z" fill="white"/>
                    <path d="M2687.9 1013.35C2687.81 1013.92 2687.71 1014.2 2687.33 1014.16C2686.58 1014.1 2686.03 1013.75 2685.89 1012.97C2685.86 1012.82 2686.03 1012.63 2686.11 1012.46C2686.73 1012.77 2687.35 1013.08 2687.9 1013.35Z" fill="white"/>
                    <path d="M2811.67 1006.08C2811.83 1006.61 2811.78 1007.05 2811.52 1007.07C2811 1007.11 2810.33 1007.7 2809.94 1006.86C2809.81 1006.57 2809.88 1006.14 2810.15 1006.09C2810.67 1006 2811.3 1005.6 2811.67 1006.09V1006.08Z" fill="white"/>
                    <path d="M2692.7 1135.64C2692.75 1136.06 2692.81 1136.46 2692.21 1136.53C2691.39 1136.63 2691.52 1135.99 2691.43 1135.55C2691.34 1135.14 2691.32 1134.75 2691.91 1134.67C2692.74 1134.56 2692.57 1135.22 2692.7 1135.64Z" fill="white"/>
                    <path d="M2786.01 1121.51C2787 1121.61 2786.5 1122.46 2786.67 1122.96C2786.75 1123.18 2786.32 1123.43 2786 1123.39C2785.07 1123.27 2785.45 1122.45 2785.32 1121.94C2785.26 1121.7 2785.65 1121.49 2786 1121.51H2786.01Z" fill="white"/>
                    <path d="M2683.98 1002.69C2684.38 1002.6 2684.81 1002.47 2684.97 1003.02C2685.17 1003.72 2684.73 1003.87 2684.16 1003.93C2683.76 1003.97 2683.33 1004.13 2683.18 1003.58C2682.99 1002.88 2683.41 1002.7 2683.99 1002.69H2683.98Z" fill="white"/>
                    <path d="M2852.57 961.7C2853.26 961.52 2853.47 961.71 2853.55 962.03C2853.73 962.74 2853.27 962.87 2852.72 962.9C2852.44 962.92 2851.88 963.07 2851.93 962.83C2852.03 962.34 2852.44 961.91 2852.57 961.7Z" fill="white"/>
                    <path d="M2713.03 1169.26C2712.98 1169.42 2712.9 1169.69 2712.82 1169.95C2712.38 1169.6 2711.69 1169.46 2711.67 1168.74C2711.67 1168.43 2711.81 1168.21 2712.19 1168.17C2713 1168.07 2712.86 1168.7 2713.02 1169.26H2713.03Z" fill="white"/>
                    <path d="M2645.74 953.65C2645.48 953.75 2645.24 953.55 2645.16 953.26C2645.05 952.87 2645.04 952.41 2645.62 952.4C2646.03 952.4 2646.67 951.9 2646.79 952.61C2646.84 952.86 2646.43 953.53 2645.74 953.65Z" fill="white"/>
                    <path d="M2834.93 918.77C2835.13 919.47 2834.48 919.79 2834.2 920.28C2834.06 920.04 2833.84 919.81 2833.8 919.55C2833.73 919.11 2833.42 918.54 2834.22 918.38C2834.59 918.31 2834.77 918.46 2834.93 918.76V918.77Z" fill="white"/>
                    <path d="M2654.52 987.62C2654.12 987.78 2654.57 987.72 2654.29 987.43C2654.14 987.27 2654.08 986.83 2654.19 986.66C2654.5 986.2 2654.7 985.52 2655.47 985.61C2655.56 985.61 2655.71 985.73 2655.7 985.77C2655.6 986.57 2654.87 986.98 2654.52 987.62Z" fill="white"/>
                    <path d="M2697.38 1026.93C2697.01 1027.37 2696.81 1028.08 2696.06 1027.94C2695.88 1027.91 2695.65 1027.48 2695.66 1027.24C2695.69 1026.49 2696.37 1026.74 2696.79 1026.6C2697.08 1026.51 2697.33 1026.55 2697.38 1026.92V1026.93Z" fill="white"/>
                    <path d="M2786.97 1071.14C2787 1071.34 2787.08 1071.55 2787.04 1071.74C2787.01 1071.86 2786.79 1072.06 2786.7 1072.04C2786.37 1071.96 2786.06 1070.07 2786.34 1069.82C2786.41 1069.76 2786.75 1069.86 2786.77 1069.93C2786.87 1070.32 2786.91 1070.73 2786.97 1071.14Z" fill="white"/>
                    <path d="M2619.1 1092.11C2619.24 1092.73 2618.88 1092.89 2618.45 1092.91C2618.14 1092.92 2617.93 1092.77 2617.87 1092.39C2617.79 1091.82 2618.12 1091.67 2618.55 1091.66C2618.85 1091.66 2619.16 1091.76 2619.11 1092.12L2619.1 1092.11Z" fill="white"/>
                    <path d="M2846.35 935.61C2846.31 935.94 2846.2 936.15 2845.81 936.21C2845.24 936.29 2845.1 935.96 2845.08 935.53C2845.07 935.22 2845.22 935.01 2845.6 934.95C2846.17 934.87 2846.27 935.21 2846.34 935.61H2846.35Z" fill="white"/>
                    <path d="M2848.01 1164.52C2847.97 1164.85 2847.86 1165.06 2847.47 1165.12C2846.9 1165.2 2846.76 1164.87 2846.74 1164.44C2846.73 1164.13 2846.88 1163.92 2847.26 1163.86C2847.83 1163.78 2847.93 1164.12 2848 1164.52H2848.01Z" fill="white"/>
                    <path d="M2733.42 1194.6C2733.04 1194.65 2732.68 1194.66 2732.59 1194.08C2732.53 1193.7 2732.68 1193.49 2732.99 1193.41C2733.4 1193.3 2733.76 1193.34 2733.85 1193.9C2733.91 1194.28 2733.75 1194.45 2733.42 1194.59V1194.6Z" fill="white"/>
                    <path d="M2904.53 1029.54C2905.08 1029.69 2905.55 1029.95 2905.72 1030.54C2905.76 1030.69 2905.63 1031.05 2905.61 1031.05C2905.03 1030.97 2904.57 1030.7 2904.41 1030.1C2904.37 1029.94 2904.48 1029.74 2904.52 1029.54H2904.53Z" fill="white"/>
                    <path d="M2879.32 1138.15C2879.45 1138.5 2879.23 1138.72 2878.93 1138.8C2878.52 1138.91 2878.16 1138.87 2878.06 1138.31C2878 1137.93 2878.15 1137.72 2878.46 1137.64C2878.88 1137.53 2879.26 1137.57 2879.32 1138.16V1138.15Z" fill="white"/>
                    <path d="M2834.46 1022.66C2834.42 1022.99 2834.31 1023.2 2833.92 1023.26C2833.35 1023.34 2833.21 1023.01 2833.19 1022.58C2833.18 1022.27 2833.33 1022.06 2833.71 1022C2834.28 1021.92 2834.38 1022.26 2834.45 1022.66H2834.46Z" fill="white"/>
                    <path d="M2621.16 1023.13C2621.11 1023.52 2621 1023.73 2620.62 1023.78C2620.05 1023.86 2619.91 1023.53 2619.89 1023.1C2619.88 1022.79 2620.03 1022.58 2620.41 1022.52C2621 1022.43 2621.06 1022.8 2621.16 1023.13Z" fill="white"/>
                    <path d="M2645.08 1014.34C2644.5 1014.47 2644.34 1014.11 2644.33 1013.68C2644.32 1013.37 2644.47 1013.16 2644.85 1013.1C2645.42 1013.02 2645.56 1013.35 2645.58 1013.78C2645.59 1014.09 2645.45 1014.36 2645.08 1014.35V1014.34Z" fill="white"/>
                    <path d="M2937.1 1058.07C2937.05 1057.67 2937 1057.25 2937.58 1057.22C2937.76 1057.21 2937.94 1057.28 2938.12 1057.32C2937.91 1057.71 2937.69 1058.1 2937.48 1058.49C2937.35 1058.35 2937.23 1058.21 2937.1 1058.07Z" fill="white"/>
                    <path d="M2888.38 1086.07C2888.43 1086.47 2888.48 1086.89 2887.9 1086.92C2887.72 1086.93 2887.54 1086.86 2887.36 1086.82C2887.57 1086.43 2887.79 1086.04 2888 1085.65C2888.13 1085.79 2888.25 1085.93 2888.38 1086.07Z" fill="white"/>
                    <path d="M2831.38 953.92C2831.14 954.27 2830.91 954.59 2830.68 954.92C2830.54 954.68 2830.3 954.45 2830.28 954.2C2830.24 953.65 2830.7 953.61 2831.09 953.62C2831.2 953.62 2831.3 953.84 2831.38 953.93V953.92Z" fill="white"/>
                    <path d="M2777.29 1122.86C2777.05 1122.81 2776.73 1122.8 2776.71 1122.73C2776.61 1122.19 2777.15 1121.98 2777.34 1121.57C2777.46 1121.71 2777.66 1121.83 2777.7 1121.99C2777.82 1122.4 2777.76 1122.77 2777.28 1122.85L2777.29 1122.86Z" fill="white"/>
                    <path d="M2764.33 1220.39C2764.26 1220.53 2764.22 1220.76 2764.12 1220.8C2763.75 1220.92 2763.29 1221.01 2763.18 1220.48C2763.13 1220.24 2763.29 1219.95 2763.35 1219.68C2763.67 1219.92 2763.99 1220.15 2764.33 1220.4V1220.39Z" fill="white"/>
                    <path d="M2779.22 1193.25C2779.77 1193.14 2779.89 1193.52 2779.91 1193.93C2779.91 1194.1 2779.77 1194.27 2779.69 1194.44C2779.37 1194.13 2779.05 1193.82 2778.73 1193.52C2778.89 1193.43 2779.05 1193.34 2779.22 1193.25Z" fill="white"/>
                    <path d="M2824.36 978.28C2824.33 978.36 2824.3 978.6 2824.2 978.63C2823.83 978.75 2823.38 978.85 2823.25 978.33C2823.19 978.09 2823.35 977.79 2823.41 977.52C2823.72 977.77 2824.03 978.01 2824.36 978.28Z" fill="white"/>
                    <path d="M2828.75 1018.38C2828.86 1018.93 2828.48 1019.05 2828.07 1019.07C2827.9 1019.07 2827.73 1018.93 2827.56 1018.85C2827.87 1018.53 2828.18 1018.21 2828.48 1017.89C2828.57 1018.05 2828.66 1018.21 2828.75 1018.38Z" fill="white"/>
                    <path d="M2830.26 1196.9C2830.19 1197.08 2830.12 1197.25 2830.04 1197.42C2829.71 1197.13 2829.37 1196.84 2829.04 1196.55C2829.2 1196.45 2829.34 1196.32 2829.52 1196.27C2830.05 1196.12 2830.2 1196.48 2830.26 1196.9Z" fill="white"/>
                    <path d="M2654.7 1031.8C2655.25 1031.69 2655.37 1032.07 2655.39 1032.48C2655.39 1032.65 2655.25 1032.82 2655.17 1032.99C2654.85 1032.68 2654.53 1032.37 2654.21 1032.07C2654.37 1031.98 2654.53 1031.89 2654.7 1031.8Z" fill="white"/>
                    <path d="M2612.78 1040.72C2612.95 1040.92 2613.13 1041.05 2613.17 1041.21C2613.28 1041.63 2613.22 1042.01 2612.67 1042.03C2612.49 1042.03 2612.31 1041.95 2612.13 1041.91C2612.34 1041.52 2612.55 1041.13 2612.78 1040.72Z" fill="white"/>
                    <path d="M2796.07 900.66C2795.92 901.05 2795.57 900.97 2795.29 901C2795.2 901 2794.99 900.74 2795.02 900.65C2795.12 900.27 2795.5 900.35 2795.78 900.33C2795.87 900.33 2795.98 900.55 2796.08 900.66H2796.07Z" fill="white"/>
                    <path d="M2813.62 921.47C2813.77 921.78 2813.94 922.02 2813.98 922.29C2814.01 922.44 2813.84 922.63 2813.76 922.8C2813.62 922.56 2813.42 922.33 2813.35 922.08C2813.31 921.94 2813.49 921.74 2813.61 921.48L2813.62 921.47Z" fill="white"/>
                    <path d="M2652.89 1144.21C2652.74 1144.6 2652.39 1144.52 2652.11 1144.55C2652.02 1144.55 2651.81 1144.29 2651.84 1144.2C2651.94 1143.82 2652.32 1143.9 2652.6 1143.88C2652.69 1143.88 2652.8 1144.1 2652.9 1144.21H2652.89Z" fill="white"/>
                    <path d="M2752 1204.74C2751.69 1204.89 2751.45 1205.06 2751.18 1205.1C2751.03 1205.13 2750.84 1204.96 2750.67 1204.88C2750.91 1204.74 2751.14 1204.54 2751.39 1204.47C2751.53 1204.43 2751.73 1204.61 2751.99 1204.73L2752 1204.74Z" fill="white"/>
                    <path d="M2755.23 1203.83C2755.07 1203.84 2754.79 1203.91 2754.52 1203.87C2754.21 1203.82 2754.17 1203.6 2754.46 1203.47C2754.7 1203.36 2755 1203.32 2755.26 1203.35C2755.57 1203.38 2755.59 1203.57 2755.22 1203.83H2755.23Z" fill="white"/>
                    <path d="M2647.56 1162.31C2647.74 1162.35 2648.05 1162.36 2648.08 1162.45C2648.2 1162.8 2647.94 1162.93 2647.64 1162.92C2647.46 1162.92 2647.15 1162.88 2647.12 1162.79C2647 1162.44 2647.26 1162.33 2647.57 1162.31H2647.56Z" fill="white"/>
                    <path d="M2842.87 941.7C2842.83 941.88 2842.82 942.19 2842.73 942.22C2842.38 942.34 2842.25 942.08 2842.26 941.78C2842.26 941.6 2842.3 941.29 2842.39 941.26C2842.74 941.14 2842.85 941.4 2842.87 941.71V941.7Z" fill="white"/>
                    <path d="M2833.39 1186.11C2833.54 1186.42 2833.71 1186.66 2833.75 1186.93C2833.78 1187.08 2833.61 1187.27 2833.53 1187.44C2833.39 1187.2 2833.19 1186.97 2833.12 1186.72C2833.08 1186.58 2833.26 1186.38 2833.38 1186.12L2833.39 1186.11Z" fill="white"/>
                    <path d="M2676.81 962.32C2676.75 962.44 2676.67 962.62 2676.59 962.79C2676.45 962.55 2676.25 962.32 2676.18 962.07C2676.14 961.93 2676.32 961.73 2676.4 961.56C2676.68 961.72 2676.83 961.95 2676.8 962.33L2676.81 962.32Z" fill="white"/>
                    <path d="M2880.16 1176.5C2879.86 1176.57 2879.58 1176.55 2879.59 1176.18C2879.59 1176.08 2879.87 1175.96 2880.04 1175.9C2880.33 1175.8 2880.61 1175.85 2880.61 1176.22C2880.61 1176.32 2880.32 1176.41 2880.16 1176.51V1176.5Z" fill="white"/>
                    <path d="M2682.02 1004.25C2681.72 1004.32 2681.44 1004.3 2681.45 1003.93C2681.45 1003.83 2681.73 1003.71 2681.9 1003.65C2682.19 1003.55 2682.47 1003.6 2682.47 1003.97C2682.47 1004.07 2682.18 1004.16 2682.02 1004.26V1004.25Z" fill="white"/>
                    <path d="M2692.69 1012.08C2692.39 1012.15 2692.11 1012.13 2692.12 1011.76C2692.12 1011.66 2692.4 1011.54 2692.57 1011.48C2692.86 1011.38 2693.14 1011.43 2693.14 1011.8C2693.14 1011.9 2692.85 1011.99 2692.69 1012.09V1012.08Z" fill="white"/>
                    <path d="M2735.72 1035.06C2735.87 1035.37 2736.04 1035.61 2736.08 1035.88C2736.11 1036.03 2735.94 1036.22 2735.86 1036.39C2735.72 1036.15 2735.52 1035.92 2735.45 1035.67C2735.41 1035.53 2735.59 1035.33 2735.71 1035.07L2735.72 1035.06Z" fill="white"/>
                    <path d="M2827.38 967.98C2827.31 968.2 2827.22 968.46 2827.13 968.73C2827 968.59 2826.78 968.45 2826.77 968.31C2826.76 968.04 2826.88 967.77 2826.94 967.5C2827.07 967.64 2827.19 967.78 2827.38 967.99V967.98Z" fill="white"/>
                    <path d="M2815.91 1066.6C2815.85 1066.72 2815.77 1066.9 2815.69 1067.07C2815.55 1066.83 2815.35 1066.6 2815.28 1066.35C2815.24 1066.21 2815.42 1066.01 2815.5 1065.84C2815.78 1066 2815.93 1066.23 2815.9 1066.61L2815.91 1066.6Z" fill="white"/>
                    <path d="M2864.37 1067.09C2864.52 1067.4 2864.69 1067.64 2864.73 1067.91C2864.76 1068.06 2864.59 1068.25 2864.51 1068.42C2864.37 1068.18 2864.17 1067.95 2864.1 1067.7C2864.06 1067.56 2864.24 1067.36 2864.36 1067.1L2864.37 1067.09Z" fill="white"/>
                    <path d="M2940.32 1067.29C2940.17 1067.68 2939.82 1067.6 2939.54 1067.63C2939.45 1067.63 2939.24 1067.37 2939.27 1067.28C2939.37 1066.9 2939.75 1066.98 2940.03 1066.96C2940.12 1066.96 2940.23 1067.18 2940.33 1067.29H2940.32Z" fill="white"/>
                    <path d="M2884.56 1106.38C2884.26 1106.45 2883.98 1106.43 2883.99 1106.06C2883.99 1105.96 2884.27 1105.84 2884.44 1105.78C2884.73 1105.68 2885.01 1105.73 2885.01 1106.1C2885.01 1106.2 2884.72 1106.29 2884.56 1106.39V1106.38Z" fill="white"/>
                    <path d="M2666.64 1130.34C2666.82 1130.38 2667.13 1130.39 2667.16 1130.48C2667.28 1130.83 2667.02 1130.96 2666.72 1130.95C2666.54 1130.95 2666.23 1130.91 2666.2 1130.82C2666.08 1130.47 2666.34 1130.36 2666.65 1130.34H2666.64Z" fill="white"/>
                    <path d="M2763.48 1099.09C2763.44 1099.27 2763.43 1099.58 2763.34 1099.61C2762.99 1099.73 2762.86 1099.47 2762.87 1099.17C2762.87 1098.99 2762.91 1098.68 2763 1098.65C2763.35 1098.53 2763.46 1098.79 2763.48 1099.1V1099.09Z" fill="white"/>
                    <path d="M2766.86 1114.97C2766.95 1115.1 2767.06 1115.21 2767.12 1115.35C2767.14 1115.4 2767.02 1115.5 2766.96 1115.58C2766.87 1115.45 2766.76 1115.34 2766.7 1115.2C2766.68 1115.15 2766.8 1115.05 2766.86 1114.97Z" fill="white"/>
                    <path d="M2835.24 1206.75C2835.33 1206.88 2835.44 1206.99 2835.5 1207.13C2835.52 1207.18 2835.4 1207.28 2835.34 1207.36C2835.25 1207.23 2835.14 1207.12 2835.08 1206.98C2835.06 1206.93 2835.18 1206.83 2835.24 1206.75Z" fill="white"/>
                    <path d="M2705.43 1013.51C2705.38 1013.65 2705.32 1013.8 2705.27 1013.94C2705.19 1013.88 2705.04 1013.82 2705.05 1013.77C2705.06 1013.62 2705.13 1013.47 2705.18 1013.33C2705.26 1013.39 2705.34 1013.45 2705.43 1013.52V1013.51Z" fill="white"/>
                    <path d="M2657.18 1006.39C2657.25 1006.29 2657.31 1006.22 2657.37 1006.14C2657.46 1006.27 2657.57 1006.38 2657.63 1006.52C2657.65 1006.57 2657.53 1006.67 2657.47 1006.75C2657.38 1006.63 2657.28 1006.5 2657.19 1006.39H2657.18Z" fill="white"/>
                    <path d="M2746.68 991.08C2746.54 991.03 2746.39 990.97 2746.25 990.92C2746.31 990.84 2746.37 990.69 2746.42 990.7C2746.57 990.71 2746.72 990.78 2746.86 990.83C2746.8 990.91 2746.74 990.99 2746.67 991.08H2746.68Z" fill="white"/>
                    <path d="M2784.95 1020.97C2784.82 1021.06 2784.71 1021.17 2784.57 1021.23C2784.52 1021.25 2784.42 1021.13 2784.34 1021.07C2784.47 1020.98 2784.58 1020.87 2784.72 1020.81C2784.77 1020.79 2784.87 1020.91 2784.95 1020.97Z" fill="white"/>
                    <path d="M2835.92 1018.42C2835.83 1018.35 2835.75 1018.29 2835.67 1018.23C2835.8 1018.14 2835.91 1018.03 2836.05 1017.97C2836.1 1017.95 2836.2 1018.07 2836.28 1018.13C2836.16 1018.22 2836.03 1018.32 2835.92 1018.41V1018.42Z" fill="white"/>
                    <path d="M2846.93 956.45C2846.88 956.59 2846.82 956.74 2846.77 956.88C2846.69 956.82 2846.54 956.76 2846.55 956.71C2846.56 956.56 2846.63 956.41 2846.68 956.27C2846.76 956.33 2846.84 956.39 2846.93 956.46V956.45Z" fill="white"/>
                    <path d="M2726.15 992.2C2726.08 992.3 2726.02 992.37 2725.96 992.45C2725.87 992.32 2725.76 992.21 2725.7 992.07C2725.68 992.02 2725.8 991.92 2725.86 991.84C2725.95 991.96 2726.05 992.09 2726.14 992.2H2726.15Z" fill="white"/>
                    <path d="M2776 1052.55C2776.07 1052.45 2776.13 1052.38 2776.19 1052.3C2776.28 1052.43 2776.39 1052.54 2776.45 1052.68C2776.47 1052.73 2776.35 1052.83 2776.29 1052.91C2776.2 1052.79 2776.1 1052.66 2776.01 1052.55H2776Z" fill="white"/>
                    <path d="M2926.78 1041.87C2926.86 1041.93 2927.01 1042.01 2927 1042.04C2926.95 1042.19 2926.89 1042.36 2926.78 1042.44C2926.54 1042.61 2926.39 1042.5 2926.48 1042.22C2926.52 1042.08 2926.68 1041.99 2926.78 1041.87Z" fill="white"/>
                    <path d="M2926.55 1042.77C2926.44 1042.88 2926.32 1043.1 2926.23 1043.09C2925.98 1043.05 2925.91 1042.87 2926.06 1042.74C2926.19 1042.63 2926.36 1042.57 2926.51 1042.49C2926.52 1042.58 2926.54 1042.67 2926.55 1042.77Z" fill="white"/>
                    <path d="M2741.63 1134.22C2741.58 1134.36 2741.52 1134.51 2741.47 1134.65C2741.39 1134.59 2741.24 1134.53 2741.25 1134.48C2741.26 1134.33 2741.33 1134.18 2741.38 1134.04C2741.46 1134.1 2741.54 1134.16 2741.63 1134.23V1134.22Z" fill="white"/>
                    <path d="M2912.64 952.68C2912.59 952.82 2912.53 952.97 2912.48 953.11C2912.4 953.05 2912.25 952.99 2912.26 952.94C2912.27 952.79 2912.34 952.64 2912.39 952.5C2912.47 952.56 2912.55 952.62 2912.64 952.69V952.68Z" fill="white"/>
                    <path d="M2933.93 1046.18C2933.86 1046.28 2933.8 1046.35 2933.74 1046.43C2933.65 1046.3 2933.54 1046.19 2933.48 1046.05C2933.46 1046 2933.58 1045.9 2933.64 1045.82C2933.73 1045.94 2933.83 1046.07 2933.92 1046.18H2933.93Z" fill="white"/>
                    <path d="M2866.92 949.77C2866.97 949.63 2867.03 949.48 2867.08 949.34C2867.16 949.4 2867.31 949.46 2867.3 949.51C2867.29 949.66 2867.22 949.81 2867.17 949.95C2867.09 949.89 2867.01 949.83 2866.92 949.76V949.77Z" fill="white"/>
                    <path d="M2838.71 1100.38C2838.66 1100.52 2838.6 1100.67 2838.55 1100.81C2838.47 1100.75 2838.32 1100.69 2838.33 1100.64C2838.34 1100.49 2838.41 1100.34 2838.46 1100.2C2838.54 1100.26 2838.62 1100.32 2838.71 1100.39V1100.38Z" fill="white"/>
                    <path d="M2773.13 971.24C2773.03 971.17 2772.96 971.11 2772.88 971.05C2773.01 970.96 2773.12 970.85 2773.26 970.79C2773.31 970.77 2773.41 970.89 2773.49 970.95C2773.37 971.04 2773.24 971.14 2773.13 971.23V971.24Z" fill="white"/>
                    <path d="M2902.43 1064.5C2902.52 1064.63 2902.63 1064.74 2902.69 1064.88C2902.71 1064.93 2902.59 1065.03 2902.53 1065.11C2902.44 1064.98 2902.33 1064.87 2902.27 1064.73C2902.25 1064.68 2902.37 1064.58 2902.43 1064.5Z" fill="white"/>
                    <path d="M2785.96 966.43C2785.91 966.57 2785.85 966.72 2785.8 966.86C2785.72 966.8 2785.57 966.74 2785.58 966.69C2785.59 966.54 2785.66 966.39 2785.71 966.25L2785.96 966.44V966.43Z" fill="white"/>
                    <path d="M2768.27 1087.7C2768.41 1087.75 2768.56 1087.81 2768.7 1087.86C2768.64 1087.94 2768.58 1088.09 2768.53 1088.08C2768.38 1088.07 2768.23 1088 2768.09 1087.95C2768.15 1087.87 2768.21 1087.79 2768.28 1087.7H2768.27Z" fill="white"/>
                    <path d="M2649.28 1171.52C2649.37 1171.65 2649.48 1171.76 2649.54 1171.9C2649.56 1171.95 2649.44 1172.05 2649.38 1172.13C2649.29 1172 2649.18 1171.89 2649.12 1171.75C2649.1 1171.7 2649.22 1171.6 2649.28 1171.52Z" fill="white"/>
                    <path d="M2673.52 1201.75C2671.69 1201.78 2671.06 1200.5 2670.49 1199.28C2670.28 1198.83 2670.06 1198.09 2669.87 1197.9C2668.64 1196.68 2670.57 1195.98 2670.23 1194.99C2671.25 1194.8 2672.41 1194.23 2673.25 1194.54C2674.05 1194.83 2675.03 1195.28 2675.69 1195.96C2676.32 1196.61 2677.49 1197.23 2676.17 1198.36C2675.97 1198.53 2676.13 1199.08 2676.08 1199.44C2675.86 1201.07 2674.96 1201.83 2673.53 1201.74L2673.52 1201.75Z" fill="white"/>
                    <path d="M2775.74 1094.56C2775.66 1095.64 2774.75 1096.35 2774.37 1096.32C2772.9 1096.19 2772.22 1097.5 2771.04 1097.85C2770.2 1098.1 2769.65 1098.11 2769.13 1097.45C2768.27 1096.35 2768.2 1095.71 2768.86 1094.93C2769.15 1094.59 2769.38 1094.24 2769.61 1093.87C2770.62 1092.25 2774.46 1091.82 2775.46 1093.77C2775.64 1094.12 2775.79 1094.4 2775.74 1094.58V1094.56Z" fill="white"/>
                    <path d="M2687.84 1104.08C2687.1 1104.69 2687.22 1106.07 2685.9 1106.09C2685.71 1106.09 2685.5 1106.01 2685.35 1106.07C2684.71 1106.34 2684.11 1106.87 2683.35 1106.41C2682.76 1106.05 2682.7 1105.36 2682.46 1104.8C2681.99 1103.69 2682.65 1102.11 2683.81 1101.46C2684.71 1100.96 2685.54 1101.04 2686.43 1101.6C2687.36 1102.19 2687.41 1103.22 2687.84 1104.07V1104.08Z" fill="white"/>
                    <path d="M2634.47 983.88C2634.58 982.26 2635.22 981.56 2636.39 981.49C2637.04 981.45 2637.7 981.58 2638.35 981.58C2638.97 981.58 2639.14 981.86 2639.07 982.44C2638.99 983.09 2639.07 983.77 2638.93 984.39C2638.67 985.45 2637.89 986.12 2636.9 986.5C2636.7 986.58 2636.3 986.52 2636.17 986.37C2635.69 985.82 2635.07 985.47 2634.52 985.04C2634.25 984.83 2634.47 984 2634.47 983.88Z" fill="white"/>
                    <path d="M2756.82 973.37C2756.84 974.11 2756.88 975.16 2755.72 975.4C2755.17 975.51 2754.14 975.73 2753.98 975.04C2753.8 974.3 2752.99 973.62 2753.51 972.76C2753.87 972.16 2754.46 971.66 2754.66 971.02C2754.84 970.43 2755.04 970.32 2755.59 970.35C2756.37 970.38 2756.77 970.87 2756.9 971.5C2757.01 972.03 2756.86 972.61 2756.82 973.37Z" fill="white"/>
                    <path d="M2614.35 981.04C2614.32 981.78 2613.71 982.38 2612.98 982.38C2612.74 982.38 2610.5 980.93 2610.43 980.73C2610.26 980.26 2611.27 978.87 2611.31 978.95C2611.9 979.97 2612.55 978.68 2613.1 979.04C2613.63 979.39 2614.37 980.57 2614.36 981.04H2614.35Z" fill="white"/>
                    <path d="M2642.67 1145.29C2643.06 1145.32 2643.66 1145.26 2643.64 1145.63C2643.61 1146.32 2643.88 1147.09 2643.2 1147.71C2642.82 1148.05 2642.32 1147.95 2642.01 1148.23C2641.5 1148.68 2641.15 1148.74 2640.63 1148.21C2640.07 1147.64 2640.02 1146.96 2640.41 1146.49C2640.97 1145.83 2641.58 1144.97 2642.68 1145.29H2642.67Z" fill="white"/>
                    <path d="M2718.81 1084.46C2718.88 1083.87 2718.63 1083.09 2719.74 1083.2C2719.99 1083.22 2720.28 1082.73 2720.57 1082.5C2720.9 1082.24 2721.25 1082.01 2721.6 1081.76C2721.63 1082.39 2721.87 1083.13 2721.62 1083.61C2721.29 1084.23 2720.55 1084.61 2720.02 1085.13C2719.73 1085.41 2719.41 1085.62 2719.05 1085.44C2718.66 1085.24 2718.8 1084.8 2718.81 1084.45V1084.46Z" fill="white"/>
                    <path d="M2568.85 1085.22C2568.86 1085.04 2568.85 1084.85 2568.89 1084.67C2568.97 1084.27 2568.7 1083.67 2569.34 1083.56C2570 1083.45 2570.55 1083.68 2570.96 1084.27C2571.31 1084.78 2571.08 1085.05 2570.73 1085.41C2570.51 1085.64 2570.49 1086.06 2570.27 1086.3C2569.98 1086.61 2569.69 1087.07 2569.15 1086.9C2568.51 1086.7 2568.85 1086.16 2568.81 1085.75C2568.79 1085.57 2568.83 1085.39 2568.84 1085.2L2568.85 1085.22Z" fill="white"/>
                    <path d="M2620.95 973.26C2620.23 972.8 2619.33 972.53 2618.86 971.73C2618.75 971.55 2618.76 971.19 2619.05 970.99C2619.41 970.74 2619.69 970.17 2620.19 970.42C2620.81 970.72 2621.36 971.16 2621.9 971.6C2622.53 972.12 2621.56 972.21 2621.59 972.61C2621.62 973.02 2621.47 973.32 2620.95 973.26Z" fill="white"/>
                    <path d="M2718.98 1130.21C2718.93 1129.7 2719.11 1129.4 2719.73 1129.47C2720.69 1129.57 2721.2 1130.12 2721.19 1130.98C2721.18 1131.75 2720.68 1132.14 2720.08 1132.29C2719.39 1132.46 2718.87 1131.45 2718.97 1130.21H2718.98Z" fill="white"/>
                    <path d="M2785.9 1181.25C2785.92 1180.97 2785.88 1180.68 2785.96 1180.42C2786.13 1179.8 2786.9 1179.65 2787.32 1180.12C2787.57 1180.4 2787.88 1180.64 2788.05 1180.96C2788.27 1181.37 2788.27 1181.93 2787.98 1182.24C2787.65 1182.6 2786.96 1182.44 2786.62 1182.91C2786.33 1182.47 2785.69 1182.22 2785.88 1181.52C2785.9 1181.43 2785.89 1181.33 2785.9 1181.24V1181.25Z" fill="white"/>
                    <path d="M2625.32 1025.08C2625.07 1025.3 2624.86 1025.47 2624.66 1025.67C2624.17 1026.17 2623.39 1025.48 2622.91 1026.13C2622.66 1026.48 2622.24 1026.45 2621.99 1026.1C2621.75 1025.77 2621.88 1025.44 2622.21 1025.17C2622.64 1024.82 2623.02 1024.41 2623.46 1024.08C2623.67 1023.92 2623.83 1023.36 2624.25 1023.87C2624.59 1024.28 2624.96 1024.67 2625.32 1025.07V1025.08Z" fill="white"/>
                    <path d="M2698.83 935.5C2699.41 935.62 2699.91 936.57 2699.74 937.23C2699.67 937.49 2698.17 938.35 2697.86 938.25C2697.07 937.98 2697.5 937.32 2697.53 936.86C2697.59 936.01 2698.27 935.38 2698.83 935.49V935.5Z" fill="white"/>
                    <path d="M2736.79 1003.63C2736.22 1003.68 2735.88 1003.54 2735.94 1002.9C2736.02 1002.12 2736.61 1001.38 2737.17 1001.41C2737.73 1001.44 2738.25 1002.26 2738.19 1003.04C2738.11 1004.2 2737.25 1003.49 2736.79 1003.63Z" fill="white"/>
                    <path d="M2742.44 1007.5C2742.15 1007.92 2741.85 1008.4 2741.26 1008.41C2740.62 1008.41 2740.03 1007.28 2740.24 1006.54C2740.38 1006.05 2740.74 1006.11 2741.09 1006.16C2741.91 1006.29 2742.55 1006.92 2742.44 1007.5Z" fill="white"/>
                    <path d="M2656.53 1011.73C2656.55 1011.37 2656.58 1011.18 2656.57 1011C2656.52 1010.32 2657.16 1010.15 2657.49 1009.75C2657.77 1010.18 2658.39 1010.42 2658.26 1011.09C2658.23 1011.27 2658.22 1011.45 2658.23 1011.64C2658.25 1012.26 2658.14 1012.65 2657.33 1012.59C2656.55 1012.53 2656.39 1012.21 2656.54 1011.73H2656.53Z" fill="white"/>
                    <path d="M2815.73 1038.09C2815.07 1038.09 2814.97 1037.65 2815.13 1037.34C2815.45 1036.72 2815.89 1036.16 2816.32 1035.61C2816.73 1035.1 2816.87 1035.67 2817.09 1035.85C2818.04 1036.65 2816.79 1037.06 2816.74 1037.7C2816.68 1038.44 2816.01 1037.97 2815.73 1038.08V1038.09Z" fill="white"/>
                    <path d="M2689.17 1002.76C2689.27 1003.53 2688.76 1003.94 2688.17 1004.11C2687.72 1004.24 2686.73 1003.06 2686.93 1002.73C2687.33 1002.1 2687.92 1001.86 2688.68 1001.97C2689.22 1002.05 2689.19 1002.38 2689.17 1002.76Z" fill="white"/>
                    <path d="M2593.02 1144.1C2592.48 1143.97 2592.2 1143.52 2592 1143.04C2591.88 1142.75 2593.17 1141.91 2593.75 1141.89C2594.27 1141.87 2594.3 1142.23 2594.27 1142.61C2594.2 1143.38 2593.6 1144.12 2593.03 1144.1H2593.02Z" fill="white"/>
                    <path d="M2657.43 979.32C2657.7 979.42 2657.96 979.63 2657.79 979.91C2657.38 980.58 2657.15 981.36 2656.49 981.89C2656.22 982.11 2655.9 982.11 2655.73 982C2655.56 981.89 2655.41 981.46 2655.5 981.3C2655.84 980.69 2656.28 980.13 2656.69 979.57C2656.87 979.33 2657.13 979.26 2657.44 979.32H2657.43Z" fill="white"/>
                    <path d="M2646.53 950.87C2646.53 951.17 2646.56 951.54 2646.07 951.61C2645.64 951.67 2645.47 951.31 2645.2 951.1C2644.65 950.66 2644.86 950.08 2644.96 949.6C2645.02 949.31 2645.54 948.86 2645.71 948.91C2646.06 949.02 2646.42 949.38 2646.58 949.73C2646.71 950.02 2646.57 950.44 2646.54 950.87H2646.53Z" fill="white"/>
                    <path d="M2794.45 992.33C2794.91 992.28 2795.21 992.47 2795.13 993.09C2795.04 993.86 2794.46 994.58 2793.9 994.56C2792.96 994.53 2793.67 993.4 2792.97 993.14C2792.85 993.1 2793.73 992.35 2794.45 992.33Z" fill="white"/>
                    <path d="M2634.54 1060.44C2634.43 1060.57 2634.33 1060.78 2634.2 1060.81C2633.48 1060.94 2632.27 1059.86 2632.37 1059.17C2632.4 1058.93 2632.52 1058.59 2632.69 1058.52C2632.87 1058.44 2633.22 1058.57 2633.4 1058.72C2634.19 1059.39 2634.56 1059.98 2634.54 1060.45V1060.44Z" fill="white"/>
                    <path d="M2669.26 1159.23C2669.53 1159.25 2669.82 1159.21 2670.08 1159.29C2670.75 1159.47 2670.41 1160.02 2670.43 1160.42C2670.46 1160.95 2670.09 1160.97 2669.71 1160.95C2669.36 1160.92 2669 1160.97 2668.73 1160.62C2668.46 1160.26 2668.02 1159.95 2668.27 1159.45C2668.47 1159.06 2668.91 1159.22 2669.26 1159.23Z" fill="white"/>
                    <path d="M2658.8 982.14C2658.95 981.58 2659.33 981.65 2659.68 981.71C2660.51 981.85 2661.13 982.47 2661 983.06C2660.89 983.54 2660.49 983.48 2660.14 983.42C2659.31 983.28 2658.71 982.66 2658.8 982.14Z" fill="white"/>
                    <path d="M2795.33 984.48C2795.74 984.98 2796.25 985.35 2796.12 986.13C2796.01 986.77 2795.69 986.77 2795.2 986.76C2794.66 986.74 2794.43 986.62 2794.39 986C2794.34 985.19 2794.95 984.95 2795.34 984.49L2795.33 984.48Z" fill="white"/>
                    <path d="M2798.2 998.32C2798.1 998.46 2798.01 998.7 2797.89 998.71C2797.53 998.74 2797.07 998.82 2796.82 998.64C2796.42 998.34 2795.89 998.01 2796 997.36C2796.09 996.86 2796.49 996.92 2796.84 996.98C2797.68 997.12 2798.26 997.71 2798.19 998.31L2798.2 998.32Z" fill="white"/>
                    <path d="M2632.24 1168.2C2632.21 1168.58 2632.16 1168.99 2631.65 1168.88C2630.99 1168.73 2630.5 1168.27 2630.62 1167.59C2630.69 1167.23 2631.18 1166.95 2631.48 1166.63C2631.86 1167.1 2632.47 1167.46 2632.24 1168.21V1168.2Z" fill="white"/>
                    <path d="M2776.88 1135.61C2776.21 1135.53 2775.56 1134.71 2775.67 1134.26C2775.78 1133.78 2776.17 1133.88 2776.52 1133.88C2777.23 1133.88 2777.67 1134.29 2777.73 1134.9C2777.75 1135.08 2777.24 1135.63 2776.88 1135.61Z" fill="white"/>
                    <path d="M2708.19 995.51C2708.16 995.98 2708.11 996.47 2707.64 996.29C2707.06 996.08 2706.56 995.65 2706.02 995.31C2706.47 994.85 2706.9 994.47 2707.66 994.61C2708.37 994.74 2708.17 995.2 2708.19 995.51Z" fill="white"/>
                    <path d="M2820.52 1079.81C2820.56 1080.58 2820.19 1080.61 2819.65 1080.62C2818.82 1080.64 2818.81 1080.2 2818.84 1079.58C2818.87 1078.94 2819.19 1078.89 2819.73 1078.9C2820.53 1078.9 2820.69 1079.27 2820.52 1079.82V1079.81Z" fill="white"/>
                    <path d="M2698.51 988.47C2698.03 988.35 2697.21 988.68 2697.4 987.75C2697.5 987.24 2697.95 986.82 2698.53 986.79C2699.5 986.74 2699.17 987.76 2699.4 988.19C2699.69 988.73 2698.8 988.3 2698.51 988.47Z" fill="white"/>
                    <path d="M2632.6 938.33C2632.58 938.6 2632.6 938.89 2632.54 939.15C2632.42 939.63 2632.03 939.55 2631.67 939.51C2631.42 939.48 2631.09 939.6 2630.94 939.28C2630.77 938.92 2630.85 938.6 2631.18 938.35C2631.33 938.24 2631.45 938.11 2631.59 937.99C2631.86 937.77 2632.2 937.32 2632.39 937.38C2632.79 937.51 2632.58 937.99 2632.6 938.34V938.33Z" fill="white"/>
                    <path d="M2742.38 1176.43C2743.01 1176.66 2742.72 1177.18 2742.71 1177.58C2742.69 1178.33 2742.11 1178.07 2741.71 1178.07C2741.33 1178.07 2740.96 1178.01 2741.04 1177.48C2741.13 1176.94 2741.91 1176.33 2742.38 1176.42V1176.43Z" fill="white"/>
                    <path d="M2756.67 1029.04C2756.18 1028.99 2755.8 1028.96 2755.89 1028.43C2755.98 1027.89 2756.77 1027.26 2757.23 1027.38C2757.71 1027.5 2757.57 1027.9 2757.6 1028.25C2757.67 1029.1 2757.16 1029.11 2756.68 1029.04H2756.67Z" fill="white"/>
                    <path d="M2670.98 962.6C2671.16 962.66 2671.55 962.69 2671.61 962.84C2671.78 963.26 2671.29 963.42 2671.06 963.53C2670.72 963.7 2670.56 964.72 2669.9 963.88C2669.68 963.6 2669.36 963.36 2669.5 962.96C2669.78 962.15 2670.44 962.79 2670.97 962.6H2670.98Z" fill="white"/>
                    <path d="M2588.23 1081.38C2587.96 1081.72 2587.66 1082.47 2587.22 1082.1C2586.85 1081.79 2586.1 1082 2586.08 1081.34C2586.07 1080.94 2587.46 1080.47 2587.95 1080.69C2588.2 1080.81 2588.3 1081.06 2588.23 1081.38Z" fill="white"/>
                    <path d="M2803.09 1080.77C2803.09 1080.77 2803.34 1081.21 2803.5 1081.63C2803.57 1081.81 2802.47 1082.48 2802.22 1082.41C2801.55 1082.22 2801.89 1081.69 2801.86 1081.29C2801.83 1080.81 2802.14 1080.7 2803.09 1080.77Z" fill="white"/>
                    <path d="M2796.61 1113.23C2796.39 1113.86 2795.87 1113.54 2795.47 1113.57C2794.97 1113.6 2794.81 1113.2 2794.97 1112.88C2795.15 1112.5 2795.52 1112.2 2795.86 1111.93C2795.98 1111.83 2796.68 1113 2796.61 1113.24V1113.23Z" fill="white"/>
                    <path d="M2653.77 1207.34C2653.28 1207.41 2653.1 1207.17 2653.12 1206.9C2653.17 1206.18 2653.64 1205.83 2654.31 1205.71C2654.65 1205.65 2654.83 1205.89 2654.82 1206.16C2654.8 1206.9 2654.27 1207.2 2653.77 1207.33V1207.34Z" fill="white"/>
                    <path d="M2617.17 1047.99C2617.75 1048.11 2618.17 1048.52 2618.15 1048.99C2618.14 1049.23 2617.67 1049.74 2617.12 1049.69C2616.38 1049.63 2616.64 1049.09 2616.63 1048.69C2616.63 1048.31 2616.7 1047.97 2617.17 1047.99Z" fill="white"/>
                    <path d="M2745.25 1194.64C2744.99 1194.66 2743.95 1193.84 2744.07 1193.73C2744.38 1193.43 2744.73 1193.1 2745.12 1192.97C2745.49 1192.85 2745.82 1193.09 2745.73 1193.57C2745.66 1193.96 2745.93 1194.52 2745.25 1194.64Z" fill="white"/>
                    <path d="M2615.83 1042.41C2615.64 1042.9 2615.29 1043.38 2614.54 1043.32C2614.27 1043.3 2614.05 1043.09 2614.15 1042.76C2614.35 1042.11 2614.75 1041.68 2615.47 1041.71C2615.74 1041.72 2615.95 1041.93 2615.83 1042.41Z" fill="white"/>
                    <path d="M2555.42 1025.23C2555.42 1025.75 2555.05 1025.91 2554.72 1025.75C2554.34 1025.57 2554.04 1025.2 2553.77 1024.86C2553.67 1024.74 2554.84 1024.04 2555.08 1024.11C2555.42 1024.2 2555.56 1024.64 2555.43 1025.23H2555.42Z" fill="white"/>
                    <path d="M2699.89 992.87C2699.86 993.26 2699.83 993.65 2699.31 993.57C2698.74 993.49 2698.33 993.06 2698.36 992.59C2698.38 992.35 2698.84 991.85 2699.39 991.89C2700.13 991.95 2699.85 992.5 2699.89 992.87Z" fill="white"/>
                    <path d="M2615.82 964.13C2615.55 964.58 2615.25 964.97 2614.68 965C2614.34 965.02 2614.14 964.8 2614.19 964.53C2614.28 964.09 2613.89 963.35 2614.8 963.32C2615.36 963.3 2615.44 963.89 2615.82 964.13Z" fill="white"/>
                    <path d="M2690.36 1001.66C2690.25 1001.79 2690.15 1001.92 2690.04 1002.05C2689.49 1001.46 2689.03 1000.76 2688.18 1000.53C2688.14 1000.52 2688.15 1000.16 2688.22 1000C2688.26 999.91 2688.57 999.83 2688.59 999.86C2689.03 1000.46 2689.98 1000.39 2690.28 1001.14C2690.34 1001.3 2690.33 1001.49 2690.35 1001.66H2690.36Z" fill="white"/>
                    <path d="M2686.05 1002.38C2685.5 1002.31 2685.84 1001.62 2685.91 1001.19C2686.04 1000.35 2686.75 1000.84 2687.2 1000.79C2687.33 1000.78 2687.82 1001.11 2687.35 1001.23C2686.65 1001.41 2687.4 1002.6 2686.05 1002.38Z" fill="white"/>
                    <path d="M2685.69 1176.53C2686.37 1176.46 2686.52 1177.11 2686.91 1177.42C2686.66 1177.66 2686.39 1178.11 2686.15 1178.09C2685.64 1178.05 2685.33 1177.62 2685.26 1177.07C2685.22 1176.74 2685.42 1176.61 2685.69 1176.52V1176.53Z" fill="white"/>
                    <path d="M2798.61 1076C2798.22 1075.92 2797.65 1076.12 2797.67 1075.37C2797.69 1074.83 2798.04 1074.82 2798.42 1074.86C2798.83 1074.91 2799.39 1074.73 2799.37 1075.48C2799.35 1076.02 2799 1076.01 2798.61 1076Z" fill="white"/>
                    <path d="M2611.15 1170.81C2610.6 1171.55 2610.02 1171.69 2609.36 1171.53C2609.21 1171.49 2609.08 1171.34 2608.95 1171.23C2609.09 1171.11 2609.25 1171.01 2609.35 1170.87C2609.74 1170.31 2610.3 1170.44 2610.82 1170.52C2610.98 1170.54 2611.11 1170.77 2611.15 1170.81Z" fill="white"/>
                    <path d="M2826.6 1082.5C2826.6 1082.64 2826.25 1082.69 2825.86 1082.64C2825.46 1082.59 2824.88 1082.79 2824.9 1082.03C2824.91 1081.5 2825.25 1081.46 2825.64 1081.51C2826.05 1081.56 2826.62 1081.39 2826.6 1082.5Z" fill="white"/>
                    <path d="M2775.75 1051.48C2775.65 1051.41 2775.43 1051.33 2775.43 1051.24C2775.41 1050.61 2775.4 1050 2776.06 1049.59C2776.33 1049.42 2776.51 1049.37 2776.62 1049.66C2776.8 1050.12 2776.19 1051.38 2775.75 1051.48Z" fill="white"/>
                    <path d="M2721.43 1146.05C2721.16 1146.9 2720.5 1146.3 2720.03 1146.36C2719.82 1146.39 2719.68 1145.96 2719.78 1145.69C2720.06 1144.9 2720.71 1145.38 2721.18 1145.37C2721.4 1145.37 2721.52 1145.75 2721.43 1146.05Z" fill="white"/>
                    <path d="M2713.08 1037.35C2712.81 1038.2 2712.15 1037.6 2711.68 1037.66C2711.47 1037.69 2711.33 1037.26 2711.43 1036.99C2711.71 1036.2 2712.36 1036.68 2712.83 1036.67C2713.05 1036.67 2713.17 1037.05 2713.08 1037.35Z" fill="white"/>
                    <path d="M2604.09 1100.03C2604.09 1100.17 2603.74 1100.22 2603.35 1100.17C2602.95 1100.12 2602.37 1100.32 2602.39 1099.56C2602.4 1099.03 2602.74 1098.99 2603.13 1099.04C2603.54 1099.09 2604.11 1098.92 2604.09 1100.03Z" fill="white"/>
                    <path d="M2728.39 964.92C2728.14 964.39 2727.88 963.85 2727.64 963.29C2727.63 963.26 2727.76 963.13 2727.83 963.12C2728.22 963.06 2728.5 963.22 2728.75 963.53C2729.03 963.88 2729.37 964.2 2729.05 964.67C2728.97 964.78 2728.75 964.79 2728.39 964.91V964.92Z" fill="white"/>
                    <path d="M2678.94 980.01C2679.31 980.01 2679.72 979.98 2679.75 980.49C2679.79 981.15 2679.38 981.19 2678.86 981.14C2678.5 981.1 2678.09 981.16 2678.06 980.65C2678.02 980 2678.43 979.91 2678.94 980.02V980.01Z" fill="white"/>
                    <path d="M2629.72 1008.62C2629.54 1009.11 2629.4 1009.33 2629.06 1009.23C2628.41 1009.03 2627.99 1008.63 2628.01 1007.91C2628.01 1007.77 2628.2 1007.64 2628.3 1007.5C2628.79 1007.89 2629.28 1008.28 2629.71 1008.62H2629.72Z" fill="white"/>
                    <path d="M2740.24 1025.49C2740.28 1025.99 2740.15 1026.37 2739.92 1026.34C2739.45 1026.28 2738.75 1026.68 2738.57 1025.86C2738.51 1025.58 2738.65 1025.21 2738.9 1025.22C2739.38 1025.24 2740.01 1025.01 2740.24 1025.5V1025.49Z" fill="white"/>
                    <path d="M2610.95 1117.36C2610.91 1117.74 2610.89 1118.1 2610.35 1118.05C2609.6 1117.99 2609.84 1117.45 2609.85 1117.04C2609.85 1116.66 2609.9 1116.31 2610.44 1116.36C2611.19 1116.42 2610.92 1116.97 2610.96 1117.36H2610.95Z" fill="white"/>
                    <path d="M2695.89 1122.46C2696.74 1122.73 2696.14 1123.39 2696.2 1123.86C2696.23 1124.07 2695.8 1124.21 2695.53 1124.11C2694.74 1123.83 2695.22 1123.18 2695.21 1122.71C2695.21 1122.49 2695.59 1122.37 2695.89 1122.46Z" fill="white"/>
                    <path d="M2628.27 998.49C2628.64 998.49 2629.05 998.46 2629.08 998.97C2629.12 999.63 2628.71 999.67 2628.19 999.62C2627.83 999.58 2627.42 999.64 2627.39 999.13C2627.35 998.48 2627.76 998.39 2628.27 998.5V998.49Z" fill="white"/>
                    <path d="M2784.65 994.06C2785.29 994.04 2785.44 994.24 2785.45 994.53C2785.48 995.19 2785.05 995.22 2784.56 995.15C2784.31 995.11 2783.79 995.14 2783.88 994.94C2784.06 994.53 2784.5 994.22 2784.65 994.06Z" fill="white"/>
                    <path d="M2622.55 1150.84C2622.48 1150.97 2622.36 1151.19 2622.24 1151.41C2621.92 1151.02 2621.34 1150.77 2621.46 1150.12C2621.51 1149.84 2621.68 1149.68 2622.03 1149.71C2622.76 1149.78 2622.52 1150.3 2622.56 1150.83L2622.55 1150.84Z" fill="white"/>
                    <path d="M2603.78 948.04C2603.53 948.08 2603.36 947.86 2603.34 947.58C2603.32 947.21 2603.39 946.81 2603.91 946.91C2604.27 946.98 2604.93 946.66 2604.9 947.31C2604.9 947.54 2604.41 948.05 2603.78 948.03V948.04Z" fill="white"/>
                    <path d="M2777.17 952.88C2777.21 953.54 2776.58 953.69 2776.24 954.08C2776.16 953.84 2776.01 953.6 2776.02 953.36C2776.04 952.96 2775.87 952.4 2776.61 952.41C2776.95 952.41 2777.08 952.59 2777.16 952.88H2777.17Z" fill="white"/>
                    <path d="M2605.12 979.65C2604.74 979.71 2605.14 979.75 2604.95 979.44C2604.85 979.27 2604.87 978.87 2605.01 978.74C2605.37 978.39 2605.67 977.83 2606.33 978.05C2606.4 978.07 2606.52 978.2 2606.5 978.23C2606.26 978.92 2605.54 979.14 2605.11 979.64L2605.12 979.65Z" fill="white"/>
                    <path d="M2635.53 1022.38C2635.12 1022.69 2634.81 1023.29 2634.18 1023.02C2634.03 1022.96 2633.91 1022.54 2633.96 1022.33C2634.12 1021.68 2634.68 1022.02 2635.08 1021.98C2635.35 1021.96 2635.57 1022.04 2635.54 1022.38H2635.53Z" fill="white"/>
                    <path d="M2706.21 1078.22C2706.2 1078.4 2706.23 1078.61 2706.16 1078.76C2706.11 1078.86 2705.88 1078.99 2705.8 1078.96C2705.52 1078.83 2705.6 1077.1 2705.9 1076.94C2705.97 1076.9 2706.25 1077.05 2706.25 1077.12C2706.27 1077.49 2706.23 1077.85 2706.2 1078.22H2706.21Z" fill="white"/>
                    <path d="M2554.23 1065.13C2554.23 1065.71 2553.89 1065.78 2553.5 1065.71C2553.22 1065.66 2553.06 1065.49 2553.09 1065.14C2553.12 1064.62 2553.44 1064.55 2553.82 1064.62C2554.09 1064.67 2554.34 1064.82 2554.22 1065.13H2554.23Z" fill="white"/>
                    <path d="M2784.08 969.87C2783.98 970.16 2783.84 970.32 2783.49 970.3C2782.97 970.27 2782.91 969.95 2782.97 969.57C2783.02 969.29 2783.19 969.13 2783.54 969.16C2784.06 969.19 2784.09 969.51 2784.07 969.88L2784.08 969.87Z" fill="white"/>
                    <path d="M2742.48 1172.05C2742.38 1172.34 2742.24 1172.5 2741.89 1172.48C2741.37 1172.45 2741.31 1172.13 2741.37 1171.75C2741.42 1171.47 2741.59 1171.31 2741.94 1171.34C2742.46 1171.37 2742.49 1171.69 2742.47 1172.06L2742.48 1172.05Z" fill="white"/>
                    <path d="M2635.76 1177.02C2635.41 1176.99 2635.1 1176.93 2635.12 1176.41C2635.14 1176.06 2635.31 1175.91 2635.6 1175.89C2635.99 1175.87 2636.3 1175.97 2636.27 1176.49C2636.25 1176.83 2636.08 1176.96 2635.76 1177.02Z" fill="white"/>
                    <path d="M2817.71 1063.65C2818.17 1063.89 2818.53 1064.21 2818.57 1064.75C2818.58 1064.9 2818.39 1065.19 2818.38 1065.18C2817.89 1065 2817.53 1064.67 2817.5 1064.12C2817.5 1063.97 2817.63 1063.82 2817.71 1063.65Z" fill="white"/>
                    <path d="M2775.05 1154.69C2775.1 1155.02 2774.87 1155.17 2774.58 1155.19C2774.19 1155.21 2773.88 1155.11 2773.91 1154.59C2773.93 1154.24 2774.1 1154.09 2774.39 1154.07C2774.78 1154.05 2775.11 1154.16 2775.05 1154.69Z" fill="white"/>
                    <path d="M2757.21 1044.4C2757.11 1044.69 2756.97 1044.85 2756.62 1044.83C2756.1 1044.8 2756.04 1044.48 2756.1 1044.1C2756.15 1043.82 2756.32 1043.66 2756.67 1043.69C2757.19 1043.72 2757.22 1044.04 2757.2 1044.41L2757.21 1044.4Z" fill="white"/>
                    <path d="M2569.03 1004.69C2568.91 1005.02 2568.78 1005.19 2568.43 1005.16C2567.91 1005.13 2567.85 1004.81 2567.91 1004.43C2567.96 1004.15 2568.13 1004 2568.48 1004.02C2569.01 1004.05 2569 1004.39 2569.03 1004.7V1004.69Z" fill="white"/>
                    <path d="M2591.77 1001.44C2591.23 1001.44 2591.16 1001.1 2591.23 1000.71C2591.28 1000.43 2591.45 1000.27 2591.8 1000.3C2592.32 1000.33 2592.38 1000.65 2592.32 1001.03C2592.27 1001.31 2592.1 1001.52 2591.77 1001.43V1001.44Z" fill="white"/>
                    <path d="M2841.07 1094.93C2841.1 1094.57 2841.14 1094.19 2841.66 1094.27C2841.82 1094.29 2841.97 1094.39 2842.12 1094.46C2841.86 1094.76 2841.6 1095.07 2841.33 1095.37C2841.24 1095.22 2841.16 1095.08 2841.07 1094.93Z" fill="white"/>
                    <path d="M2792.83 1110.47C2792.8 1110.83 2792.76 1111.21 2792.24 1111.13C2792.08 1111.11 2791.93 1111.01 2791.78 1110.94C2792.04 1110.64 2792.3 1110.33 2792.57 1110.03C2792.66 1110.18 2792.74 1110.32 2792.83 1110.47Z" fill="white"/>
                    <path d="M2767.43 983.21C2767.15 983.47 2766.89 983.71 2766.63 983.96C2766.55 983.72 2766.38 983.47 2766.41 983.25C2766.47 982.76 2766.89 982.81 2767.23 982.89C2767.32 982.91 2767.37 983.12 2767.43 983.21Z" fill="white"/>
                    <path d="M2687.95 1122.01C2687.75 1121.92 2687.47 1121.85 2687.47 1121.79C2687.48 1121.3 2688 1121.21 2688.25 1120.89C2688.33 1121.04 2688.48 1121.18 2688.49 1121.33C2688.52 1121.71 2688.4 1122.03 2687.96 1122.01H2687.95Z" fill="white"/>
                    <path d="M2658.18 1205.58C2658.09 1205.69 2658.02 1205.89 2657.92 1205.9C2657.57 1205.94 2657.15 1205.93 2657.15 1205.44C2657.15 1205.21 2657.35 1204.99 2657.45 1204.76C2657.69 1205.03 2657.93 1205.3 2658.18 1205.58Z" fill="white"/>
                    <path d="M2676.41 1184.44C2676.92 1184.44 2676.95 1184.81 2676.89 1185.17C2676.87 1185.32 2676.7 1185.44 2676.6 1185.58C2676.37 1185.25 2676.15 1184.92 2675.92 1184.58C2676.08 1184.53 2676.24 1184.48 2676.41 1184.43V1184.44Z" fill="white"/>
                    <path d="M2756.66 1003.37C2756.62 1003.43 2756.55 1003.64 2756.46 1003.65C2756.11 1003.69 2755.69 1003.69 2755.68 1003.2C2755.68 1002.98 2755.87 1002.75 2755.97 1002.52C2756.2 1002.8 2756.42 1003.07 2756.67 1003.37H2756.66Z" fill="white"/>
                    <path d="M2752.98 1039.56C2752.98 1040.07 2752.61 1040.1 2752.25 1040.04C2752.1 1040.02 2751.98 1039.85 2751.84 1039.75C2752.17 1039.52 2752.5 1039.3 2752.84 1039.07C2752.89 1039.23 2752.94 1039.39 2752.99 1039.56H2752.98Z" fill="white"/>
                    <path d="M2720.73 1197.26C2720.63 1197.4 2720.54 1197.54 2720.44 1197.68C2720.2 1197.36 2719.96 1197.04 2719.72 1196.72C2719.88 1196.67 2720.03 1196.57 2720.19 1196.56C2720.69 1196.53 2720.75 1196.87 2720.73 1197.25V1197.26Z" fill="white"/>
                    <path d="M2596.97 1018.64C2597.48 1018.64 2597.51 1019.01 2597.45 1019.37C2597.43 1019.52 2597.26 1019.64 2597.16 1019.78C2596.93 1019.45 2596.71 1019.12 2596.48 1018.78C2596.64 1018.73 2596.8 1018.68 2596.97 1018.63V1018.64Z" fill="white"/>
                    <path d="M2558.33 1018.62C2558.44 1018.83 2558.58 1018.97 2558.58 1019.13C2558.6 1019.52 2558.47 1019.84 2557.98 1019.76C2557.82 1019.73 2557.68 1019.62 2557.53 1019.55C2557.79 1019.25 2558.05 1018.95 2558.33 1018.62Z" fill="white"/>
                    <path d="M2746.31 929.59C2746.1 929.91 2745.81 929.77 2745.56 929.74C2745.48 929.74 2745.34 929.46 2745.38 929.38C2745.54 929.07 2745.86 929.21 2746.11 929.24C2746.19 929.25 2746.25 929.47 2746.31 929.59Z" fill="white"/>
                    <path d="M2757.87 951.25C2757.94 951.55 2758.04 951.8 2758.04 952.04C2758.04 952.18 2757.85 952.31 2757.75 952.45C2757.67 952.21 2757.54 951.98 2757.53 951.74C2757.53 951.61 2757.71 951.47 2757.87 951.26V951.25Z" fill="white"/>
                    <path d="M2574.23 1117.44C2574.02 1117.76 2573.73 1117.62 2573.48 1117.59C2573.4 1117.59 2573.26 1117.31 2573.3 1117.23C2573.46 1116.92 2573.78 1117.06 2574.03 1117.09C2574.11 1117.1 2574.17 1117.32 2574.23 1117.44Z" fill="white"/>
                    <path d="M2650.24 1189.46C2649.94 1189.53 2649.69 1189.63 2649.45 1189.63C2649.31 1189.63 2649.18 1189.44 2649.04 1189.34C2649.28 1189.26 2649.51 1189.13 2649.75 1189.12C2649.88 1189.12 2650.02 1189.3 2650.23 1189.46H2650.24Z" fill="white"/>
                    <path d="M2653.26 1189.26C2653.11 1189.24 2652.86 1189.25 2652.63 1189.16C2652.37 1189.06 2652.38 1188.86 2652.65 1188.8C2652.88 1188.75 2653.16 1188.77 2653.38 1188.84C2653.65 1188.93 2653.63 1189.1 2653.26 1189.26Z" fill="white"/>
                    <path d="M2566.12 1132.4C2566.27 1132.47 2566.54 1132.53 2566.55 1132.62C2566.59 1132.95 2566.33 1133.02 2566.07 1132.95C2565.91 1132.91 2565.65 1132.82 2565.64 1132.74C2565.6 1132.41 2565.85 1132.36 2566.12 1132.4Z" fill="white"/>
                    <path d="M2779.85 974.59C2779.78 974.74 2779.72 975.01 2779.63 975.02C2779.3 975.06 2779.23 974.8 2779.3 974.54C2779.34 974.38 2779.43 974.12 2779.51 974.11C2779.84 974.07 2779.89 974.32 2779.85 974.59Z" fill="white"/>
                    <path d="M2725.52 1188.34C2725.59 1188.64 2725.69 1188.89 2725.69 1189.13C2725.69 1189.27 2725.5 1189.4 2725.4 1189.54C2725.32 1189.3 2725.19 1189.07 2725.18 1188.83C2725.18 1188.7 2725.36 1188.56 2725.52 1188.35V1188.34Z" fill="white"/>
                    <path d="M2629.54 961.53C2629.47 961.63 2629.36 961.77 2629.26 961.9C2629.18 961.66 2629.05 961.43 2629.04 961.19C2629.04 961.06 2629.22 960.92 2629.33 960.78C2629.54 960.97 2629.64 961.21 2629.54 961.53Z" fill="white"/>
                    <path d="M2768.58 1188.66C2768.3 1188.66 2768.06 1188.59 2768.14 1188.27C2768.16 1188.18 2768.43 1188.13 2768.59 1188.11C2768.86 1188.08 2769.11 1188.17 2769.03 1188.5C2769.01 1188.59 2768.73 1188.62 2768.58 1188.67V1188.66Z" fill="white"/>
                    <path d="M2626.25 999.49C2625.97 999.49 2625.73 999.42 2625.81 999.1C2625.83 999.01 2626.1 998.96 2626.26 998.94C2626.53 998.91 2626.78 999 2626.7 999.33C2626.68 999.42 2626.4 999.45 2626.25 999.5V999.49Z" fill="white"/>
                    <path d="M2634.19 1008.41C2633.91 1008.41 2633.67 1008.34 2633.75 1008.02C2633.77 1007.93 2634.04 1007.88 2634.2 1007.86C2634.47 1007.83 2634.72 1007.92 2634.64 1008.25C2634.62 1008.34 2634.34 1008.37 2634.19 1008.42V1008.41Z" fill="white"/>
                    <path d="M2667.81 1036.77C2667.88 1037.07 2667.98 1037.32 2667.98 1037.56C2667.98 1037.7 2667.79 1037.83 2667.69 1037.97C2667.61 1037.73 2667.48 1037.5 2667.47 1037.26C2667.47 1037.13 2667.65 1036.99 2667.81 1036.78V1036.77Z" fill="white"/>
                    <path d="M2761.25 994.85C2761.14 995.03 2761.02 995.25 2760.89 995.46C2760.8 995.31 2760.63 995.15 2760.65 995.02C2760.69 994.78 2760.85 994.56 2760.95 994.34C2761.04 994.49 2761.12 994.63 2761.25 994.85Z" fill="white"/>
                    <path d="M2732.59 1079.67C2732.52 1079.77 2732.41 1079.91 2732.31 1080.04C2732.23 1079.8 2732.1 1079.57 2732.09 1079.33C2732.09 1079.2 2732.27 1079.06 2732.38 1078.92C2732.59 1079.11 2732.69 1079.35 2732.59 1079.67Z" fill="white"/>
                    <path d="M2775.23 1089.21C2775.3 1089.51 2775.4 1089.76 2775.4 1090C2775.4 1090.14 2775.21 1090.27 2775.11 1090.41C2775.03 1090.17 2774.9 1089.94 2774.89 1089.7C2774.89 1089.57 2775.07 1089.43 2775.23 1089.22V1089.21Z" fill="white"/>
                    <path d="M2842.17 1103.67C2841.96 1103.99 2841.67 1103.85 2841.42 1103.82C2841.34 1103.82 2841.2 1103.54 2841.24 1103.46C2841.4 1103.15 2841.72 1103.29 2841.97 1103.32C2842.05 1103.33 2842.11 1103.55 2842.17 1103.67Z" fill="white"/>
                    <path d="M2785.64 1127.66C2785.36 1127.66 2785.12 1127.59 2785.2 1127.27C2785.22 1127.18 2785.49 1127.13 2785.65 1127.11C2785.92 1127.08 2786.17 1127.17 2786.09 1127.5C2786.07 1127.59 2785.79 1127.62 2785.64 1127.67V1127.66Z" fill="white"/>
                    <path d="M2588.97 1107.79C2589.12 1107.86 2589.39 1107.92 2589.4 1108.01C2589.44 1108.34 2589.18 1108.41 2588.92 1108.34C2588.76 1108.3 2588.5 1108.21 2588.49 1108.13C2588.45 1107.8 2588.7 1107.75 2588.97 1107.79Z" fill="white"/>
                    <path d="M2680.24 1098.44C2680.17 1098.59 2680.11 1098.86 2680.02 1098.87C2679.69 1098.91 2679.62 1098.65 2679.69 1098.39C2679.73 1098.23 2679.82 1097.97 2679.9 1097.96C2680.23 1097.92 2680.28 1098.17 2680.24 1098.44Z" fill="white"/>
                    <path d="M2680.24 1113.09C2680.3 1113.22 2680.37 1113.34 2680.39 1113.48C2680.39 1113.53 2680.27 1113.6 2680.2 1113.65C2680.14 1113.52 2680.07 1113.4 2680.05 1113.26C2680.05 1113.21 2680.17 1113.14 2680.24 1113.09Z" fill="white"/>
                    <path d="M2723.27 1206.89C2723.33 1207.02 2723.4 1207.14 2723.42 1207.28C2723.42 1207.33 2723.3 1207.4 2723.23 1207.45C2723.17 1207.32 2723.1 1207.2 2723.08 1207.06C2723.08 1207.01 2723.2 1206.94 2723.27 1206.89Z" fill="white"/>
                    <path d="M2645.15 1012.06C2645.08 1012.17 2645 1012.29 2644.93 1012.41C2644.87 1012.34 2644.75 1012.26 2644.77 1012.22C2644.81 1012.09 2644.9 1011.97 2644.97 1011.85C2645.03 1011.92 2645.09 1011.98 2645.16 1012.06H2645.15Z" fill="white"/>
                    <path d="M2603.94 996.71C2604.02 996.64 2604.09 996.58 2604.15 996.52C2604.21 996.65 2604.28 996.77 2604.3 996.91C2604.3 996.96 2604.18 997.03 2604.11 997.08C2604.05 996.95 2603.99 996.83 2603.93 996.71H2603.94Z" fill="white"/>
                    <path d="M2685.75 1000.04C2685.64 999.97 2685.52 999.89 2685.4 999.82C2685.47 999.76 2685.55 999.64 2685.59 999.66C2685.72 999.7 2685.84 999.79 2685.96 999.86C2685.89 999.92 2685.83 999.98 2685.75 1000.05V1000.04Z" fill="white"/>
                    <path d="M2713.87 1033.6C2713.74 1033.66 2713.62 1033.73 2713.48 1033.75C2713.43 1033.75 2713.36 1033.63 2713.31 1033.56C2713.44 1033.5 2713.56 1033.43 2713.7 1033.41C2713.75 1033.41 2713.82 1033.53 2713.87 1033.6Z" fill="white"/>
                    <path d="M2759.3 1040.94C2759.23 1040.86 2759.17 1040.79 2759.11 1040.73C2759.24 1040.67 2759.36 1040.6 2759.5 1040.58C2759.55 1040.58 2759.62 1040.7 2759.67 1040.77C2759.54 1040.83 2759.42 1040.89 2759.3 1040.95V1040.94Z" fill="white"/>
                    <path d="M2780.67 988.36C2780.6 988.47 2780.52 988.59 2780.45 988.71C2780.39 988.64 2780.27 988.56 2780.29 988.52C2780.33 988.39 2780.42 988.27 2780.49 988.15C2780.55 988.22 2780.61 988.28 2780.68 988.36H2780.67Z" fill="white"/>
                    <path d="M2667.43 997.17C2667.35 997.24 2667.28 997.3 2667.22 997.36C2667.16 997.23 2667.09 997.11 2667.07 996.97C2667.07 996.92 2667.19 996.85 2667.26 996.8C2667.32 996.93 2667.38 997.05 2667.44 997.17H2667.43Z" fill="white"/>
                    <path d="M2700.04 1059.77C2700.12 1059.7 2700.19 1059.64 2700.25 1059.58C2700.31 1059.71 2700.38 1059.83 2700.4 1059.97C2700.4 1060.02 2700.28 1060.09 2700.21 1060.14C2700.15 1060.01 2700.09 1059.89 2700.03 1059.77H2700.04Z" fill="white"/>
                    <path d="M2835.01 1078.71C2835.07 1078.78 2835.19 1078.88 2835.17 1078.9C2835.1 1079.02 2835.01 1079.16 2834.9 1079.21C2834.66 1079.31 2834.55 1079.19 2834.68 1078.96C2834.74 1078.85 2834.9 1078.79 2835.01 1078.71Z" fill="white"/>
                    <path d="M2834.64 1079.46C2834.52 1079.54 2834.37 1079.71 2834.3 1079.68C2834.09 1079.6 2834.06 1079.43 2834.21 1079.34C2834.34 1079.27 2834.5 1079.25 2834.65 1079.2C2834.65 1079.28 2834.65 1079.37 2834.64 1079.45V1079.46Z" fill="white"/>
                    <path d="M2654.37 1125.31C2654.3 1125.42 2654.22 1125.54 2654.15 1125.66C2654.09 1125.59 2653.97 1125.51 2653.99 1125.47C2654.03 1125.34 2654.12 1125.22 2654.19 1125.1C2654.25 1125.17 2654.31 1125.23 2654.38 1125.31H2654.37Z" fill="white"/>
                    <path d="M2839.32 997.4C2839.25 997.51 2839.17 997.63 2839.1 997.75C2839.04 997.68 2838.92 997.6 2838.94 997.56C2838.98 997.43 2839.07 997.31 2839.14 997.19C2839.2 997.26 2839.26 997.32 2839.33 997.4H2839.32Z" fill="white"/>
                    <path d="M2840.51 1083.86C2840.43 1083.93 2840.36 1083.99 2840.3 1084.05C2840.24 1083.92 2840.17 1083.8 2840.15 1083.66C2840.15 1083.61 2840.27 1083.54 2840.34 1083.49C2840.4 1083.62 2840.46 1083.74 2840.52 1083.86H2840.51Z" fill="white"/>
                    <path d="M2799.55 986.23C2799.62 986.12 2799.7 986 2799.77 985.88C2799.83 985.95 2799.95 986.03 2799.93 986.07C2799.89 986.2 2799.8 986.32 2799.73 986.44C2799.67 986.37 2799.61 986.31 2799.54 986.23H2799.55Z" fill="white"/>
                    <path d="M2746.34 1113.74C2746.27 1113.85 2746.19 1113.97 2746.12 1114.09C2746.06 1114.02 2745.94 1113.94 2745.96 1113.9C2746 1113.77 2746.09 1113.65 2746.16 1113.53C2746.22 1113.6 2746.28 1113.66 2746.35 1113.74H2746.34Z" fill="white"/>
                    <path d="M2712.8 987.52C2712.73 987.44 2712.67 987.37 2712.61 987.31C2712.74 987.25 2712.86 987.18 2713 987.16C2713.05 987.16 2713.12 987.28 2713.17 987.35C2713.04 987.41 2712.92 987.47 2712.8 987.53V987.52Z" fill="white"/>
                    <path d="M2809.28 1094.08C2809.34 1094.21 2809.41 1094.33 2809.43 1094.47C2809.43 1094.52 2809.31 1094.59 2809.24 1094.64C2809.18 1094.51 2809.11 1094.39 2809.09 1094.25C2809.09 1094.2 2809.21 1094.13 2809.28 1094.08Z" fill="white"/>
                    <path d="M2725.02 985.69C2724.95 985.8 2724.87 985.92 2724.8 986.04C2724.74 985.97 2724.62 985.89 2724.64 985.85C2724.68 985.72 2724.77 985.6 2724.84 985.48L2725.03 985.69H2725.02Z" fill="white"/>
                    <path d="M2686.61 1089.3C2686.72 1089.37 2686.84 1089.45 2686.96 1089.52C2686.89 1089.58 2686.81 1089.7 2686.77 1089.68C2686.64 1089.64 2686.52 1089.55 2686.4 1089.48C2686.47 1089.42 2686.53 1089.36 2686.61 1089.29V1089.3Z" fill="white"/>
                    <path d="M2565.91 1140.84C2565.97 1140.97 2566.04 1141.09 2566.06 1141.23C2566.06 1141.28 2565.94 1141.35 2565.87 1141.4C2565.81 1141.27 2565.74 1141.15 2565.72 1141.01C2565.72 1140.96 2565.84 1140.89 2565.91 1140.84Z" fill="white"/>
                    <path d="M2734.1 962.62C2735.81 963.61 2735.68 965.15 2735.52 966.6C2735.46 967.13 2735.26 967.93 2735.33 968.22C2735.78 970.03 2733.61 969.6 2733.38 970.71C2732.33 970.31 2730.95 970.19 2730.33 969.44C2729.75 968.72 2729.1 967.77 2728.87 966.77C2728.65 965.82 2727.92 964.59 2729.77 964.28C2730.04 964.23 2730.21 963.64 2730.46 963.33C2731.57 961.94 2732.82 961.74 2734.1 962.63V962.62Z" fill="white"/>
                    <path d="M2579.91 1004.74C2580.59 1003.79 2581.82 1003.64 2582.16 1003.88C2583.44 1004.81 2584.8 1003.99 2586.09 1004.33C2587 1004.57 2587.52 1004.86 2587.63 1005.77C2587.81 1007.27 2587.52 1007.89 2586.48 1008.25C2586.03 1008.4 2585.62 1008.6 2585.19 1008.81C2583.35 1009.74 2579.56 1008 2579.72 1005.64C2579.75 1005.22 2579.76 1004.87 2579.91 1004.74Z" fill="white"/>
                    <path d="M2666.45 1044.92C2667.47 1044.77 2668.13 1043.42 2669.36 1044.14C2669.53 1044.24 2669.68 1044.44 2669.86 1044.47C2670.61 1044.58 2671.45 1044.42 2671.89 1045.27C2672.24 1045.93 2671.91 1046.6 2671.82 1047.25C2671.64 1048.54 2670.15 1049.63 2668.71 1049.58C2667.59 1049.54 2666.88 1049.01 2666.37 1047.99C2665.84 1046.93 2666.37 1045.95 2666.44 1044.92H2666.45Z" fill="white"/>
                    <path d="M2648.81 1185.74C2647.81 1187.18 2646.83 1187.47 2645.7 1186.88C2645.08 1186.55 2644.54 1186.07 2643.94 1185.7C2643.37 1185.35 2643.36 1185 2643.75 1184.5C2644.18 1183.94 2644.48 1183.27 2644.97 1182.77C2645.8 1181.93 2646.89 1181.75 2648.02 1181.95C2648.25 1181.99 2648.58 1182.27 2648.62 1182.48C2648.75 1183.26 2649.14 1183.92 2649.41 1184.63C2649.54 1184.98 2648.88 1185.62 2648.81 1185.74Z" fill="white"/>
                    <path d="M2529.86 1127.3C2530.25 1126.61 2530.8 1125.61 2532 1126.04C2532.57 1126.24 2533.64 1126.62 2533.41 1127.34C2533.16 1128.12 2533.53 1129.21 2532.58 1129.71C2531.91 1130.06 2531.09 1130.2 2530.55 1130.68C2530.05 1131.12 2529.81 1131.11 2529.31 1130.78C2528.6 1130.31 2528.5 1129.64 2528.74 1128.98C2528.94 1128.43 2529.39 1127.97 2529.86 1127.29V1127.3Z" fill="white"/>
                    <path d="M2665.82 1199.59C2666.26 1198.92 2667.16 1198.71 2667.84 1199.12C2668.06 1199.26 2669.32 1201.84 2669.28 1202.07C2669.18 1202.6 2667.47 1203.32 2667.47 1203.22C2667.49 1201.95 2666.18 1202.78 2665.87 1202.14C2665.58 1201.52 2665.54 1200.02 2665.82 1199.59Z" fill="white"/>
                    <path d="M2731.16 1031.99C2730.82 1031.74 2730.23 1031.47 2730.45 1031.14C2730.86 1030.52 2731.04 1029.66 2732.02 1029.47C2732.56 1029.37 2732.97 1029.73 2733.41 1029.66C2734.13 1029.53 2734.49 1029.66 2734.68 1030.45C2734.88 1031.29 2734.55 1031.95 2733.92 1032.16C2733.03 1032.45 2732 1032.91 2731.16 1032V1031.99Z" fill="white"/>
                    <path d="M2626.89 1045.79C2626.5 1046.29 2626.3 1047.15 2625.33 1046.43C2625.11 1046.27 2624.57 1046.56 2624.17 1046.61C2623.72 1046.67 2623.26 1046.69 2622.81 1046.72C2623.14 1046.13 2623.32 1045.3 2623.82 1045C2624.46 1044.61 2625.37 1044.67 2626.15 1044.49C2626.58 1044.39 2626.99 1044.38 2627.22 1044.74C2627.47 1045.14 2627.09 1045.47 2626.88 1045.79H2626.89Z" fill="white"/>
                    <path d="M2765.92 1128.64C2765.81 1128.8 2765.71 1128.98 2765.58 1129.13C2765.28 1129.46 2765.2 1130.16 2764.54 1129.9C2763.87 1129.64 2763.48 1129.11 2763.44 1128.34C2763.4 1127.68 2763.77 1127.55 2764.29 1127.41C2764.62 1127.32 2764.88 1126.94 2765.21 1126.84C2765.65 1126.71 2766.17 1126.45 2766.58 1126.91C2767.06 1127.45 2766.45 1127.76 2766.25 1128.16C2766.16 1128.34 2766.03 1128.49 2765.92 1128.65V1128.64Z" fill="white"/>
                    <path d="M2655.39 1203.1C2655.8 1203.93 2656.48 1204.67 2656.47 1205.68C2656.47 1205.91 2656.26 1206.23 2655.89 1206.26C2655.42 1206.29 2654.84 1206.66 2654.51 1206.15C2654.11 1205.53 2653.84 1204.81 2653.59 1204.1C2653.29 1203.26 2654.25 1203.73 2654.44 1203.35C2654.64 1202.96 2654.95 1202.76 2655.39 1203.11V1203.1Z" fill="white"/>
                    <path d="M2652.22 1003.41C2651.99 1003.91 2651.65 1004.08 2651.11 1003.68C2650.28 1003.05 2650.11 1002.26 2650.6 1001.47C2651.04 1000.77 2651.72 1000.68 2652.35 1000.88C2653.08 1001.1 2653 1002.34 2652.22 1003.42V1003.41Z" fill="white"/>
                    <path d="M2618.8 918.95C2618.63 919.2 2618.5 919.49 2618.28 919.69C2617.78 920.16 2616.98 919.88 2616.85 919.21C2616.78 918.81 2616.63 918.41 2616.65 918.02C2616.68 917.52 2616.99 917 2617.43 916.87C2617.93 916.72 2618.48 917.25 2619.06 917.01C2619.09 917.58 2619.53 918.16 2618.97 918.7C2618.9 918.77 2618.85 918.86 2618.8 918.95Z" fill="white"/>
                    <path d="M2680.22 1152.76C2680.57 1152.69 2680.87 1152.66 2681.16 1152.58C2681.89 1152.39 2682.23 1153.46 2683.03 1153.12C2683.46 1152.94 2683.83 1153.2 2683.87 1153.66C2683.9 1154.09 2683.6 1154.33 2683.15 1154.4C2682.56 1154.49 2681.98 1154.65 2681.39 1154.71C2681.11 1154.74 2680.65 1155.17 2680.54 1154.47C2680.45 1153.9 2680.33 1153.33 2680.22 1152.76Z" fill="white"/>
                    <path d="M2562.37 1194.6C2561.9 1194.16 2561.97 1193 2562.5 1192.49C2562.71 1192.29 2564.58 1192.32 2564.8 1192.6C2565.38 1193.29 2564.61 1193.66 2564.33 1194.06C2563.8 1194.81 2562.82 1195.02 2562.37 1194.6Z" fill="white"/>
                    <path d="M2565.24 1110.48C2565.8 1110.75 2566.03 1111.07 2565.62 1111.63C2565.11 1112.31 2564.15 1112.66 2563.66 1112.32C2563.16 1111.98 2563.14 1110.93 2563.62 1110.24C2564.34 1109.21 2564.74 1110.35 2565.24 1110.48Z" fill="white"/>
                    <path d="M2562.17 1103.76C2562.68 1103.53 2563.22 1103.26 2563.77 1103.58C2564.37 1103.93 2564.27 1105.31 2563.67 1105.88C2563.27 1106.25 2562.97 1106 2562.67 1105.75C2561.98 1105.18 2561.75 1104.23 2562.17 1103.76Z" fill="white"/>
                    <path d="M2643.94 1147.71C2643.72 1148.04 2643.59 1148.19 2643.5 1148.36C2643.16 1149.02 2642.48 1148.81 2641.95 1149C2641.93 1148.44 2641.5 1147.88 2641.99 1147.33C2642.12 1147.18 2642.23 1147.01 2642.33 1146.84C2642.66 1146.26 2642.97 1145.96 2643.69 1146.47C2644.38 1146.96 2644.35 1147.35 2643.94 1147.71Z" fill="white"/>
                    <path d="M2511.47 1034.65C2512.08 1035.01 2511.93 1035.48 2511.61 1035.68C2510.97 1036.07 2510.25 1036.35 2509.54 1036.61C2508.88 1036.86 2509.06 1036.25 2508.96 1035.96C2508.52 1034.69 2509.91 1035.01 2510.31 1034.44C2510.78 1033.79 2511.14 1034.6 2511.46 1034.65H2511.47Z" fill="white"/>
                    <path d="M2608.76 1137.82C2609.1 1137.05 2609.79 1136.95 2610.44 1137.13C2610.92 1137.26 2611.19 1138.91 2610.82 1139.1C2610.1 1139.46 2609.42 1139.35 2608.78 1138.83C2608.33 1138.46 2608.54 1138.17 2608.77 1137.83L2608.76 1137.82Z" fill="white"/>
                    <path d="M2776.39 1060.75C2776.82 1061.17 2776.83 1061.74 2776.74 1062.3C2776.69 1062.63 2775.03 1062.69 2774.48 1062.39C2773.99 1062.11 2774.16 1061.77 2774.4 1061.43C2774.89 1060.76 2775.86 1060.41 2776.38 1060.75H2776.39Z" fill="white"/>
                    <path d="M2625.04 1177.16C2624.85 1176.92 2624.73 1176.58 2625.04 1176.41C2625.79 1176.02 2626.44 1175.43 2627.34 1175.3C2627.72 1175.25 2628.01 1175.42 2628.11 1175.62C2628.21 1175.81 2628.11 1176.3 2627.94 1176.39C2627.28 1176.76 2626.57 1177.04 2625.88 1177.33C2625.58 1177.45 2625.3 1177.38 2625.05 1177.15L2625.04 1177.16Z" fill="white"/>
                    <path d="M2619.27 1209.54C2619.43 1209.26 2619.62 1208.91 2620.11 1209.12C2620.54 1209.3 2620.5 1209.73 2620.63 1210.08C2620.9 1210.79 2620.38 1211.21 2620.02 1211.6C2619.81 1211.83 2619.07 1211.96 2618.95 1211.82C2618.69 1211.52 2618.56 1210.99 2618.61 1210.58C2618.65 1210.24 2619.02 1209.93 2619.28 1209.55L2619.27 1209.54Z" fill="white"/>
                    <path d="M2505.65 1088.8C2505.2 1088.59 2505.03 1088.24 2505.44 1087.72C2505.96 1087.05 2506.89 1086.72 2507.39 1087.04C2508.24 1087.59 2506.95 1088.24 2507.46 1088.88C2507.55 1088.99 2506.32 1089.19 2505.65 1088.8Z" fill="white"/>
                    <path d="M2691.4 1114.94C2691.57 1114.88 2691.78 1114.74 2691.91 1114.79C2692.65 1115.07 2693.16 1116.74 2692.69 1117.33C2692.53 1117.53 2692.23 1117.78 2692.03 1117.75C2691.82 1117.72 2691.57 1117.41 2691.49 1117.17C2691.13 1116.11 2691.12 1115.36 2691.4 1114.94Z" fill="white"/>
                    <path d="M2714.35 1004.29C2714.1 1004.12 2713.82 1003.99 2713.62 1003.78C2713.1 1003.24 2713.72 1002.92 2713.92 1002.53C2714.18 1002.03 2714.54 1002.21 2714.88 1002.45C2715.19 1002.67 2715.55 1002.83 2715.6 1003.3C2715.65 1003.78 2715.89 1004.31 2715.38 1004.64C2714.98 1004.89 2714.67 1004.49 2714.34 1004.29H2714.35Z" fill="white"/>
                    <path d="M2625.35 1173.8C2624.9 1174.24 2624.59 1173.96 2624.3 1173.71C2623.61 1173.12 2623.38 1172.19 2623.83 1171.73C2624.2 1171.34 2624.53 1171.62 2624.83 1171.87C2625.52 1172.46 2625.73 1173.37 2625.36 1173.8H2625.35Z" fill="white"/>
                    <path d="M2500.46 1095.56C2500.36 1094.87 2500.09 1094.24 2500.65 1093.6C2501.11 1093.07 2501.4 1093.24 2501.85 1093.53C2502.34 1093.84 2502.48 1094.09 2502.18 1094.69C2501.78 1095.47 2501.07 1095.35 2500.46 1095.56Z" fill="white"/>
                    <path d="M2505.52 1081.17C2505.69 1081.1 2505.91 1080.92 2506.02 1080.98C2506.37 1081.15 2506.84 1081.34 2506.97 1081.64C2507.17 1082.13 2507.48 1082.74 2507.01 1083.27C2506.65 1083.68 2506.31 1083.4 2506.02 1083.15C2505.32 1082.55 2505.11 1081.68 2505.52 1081.16V1081.17Z" fill="white"/>
                    <path d="M2753.56 1016.63C2753.8 1016.3 2754.07 1015.94 2754.48 1016.33C2755.01 1016.84 2755.21 1017.53 2754.71 1018.1C2754.45 1018.4 2753.83 1018.38 2753.38 1018.51C2753.29 1017.86 2752.93 1017.19 2753.56 1016.63Z" fill="white"/>
                    <path d="M2601.72 966.16C2602.29 966.61 2602.44 967.72 2602.08 968.09C2601.71 968.47 2601.41 968.16 2601.09 967.96C2600.44 967.56 2600.25 966.94 2600.54 966.34C2600.62 966.16 2601.4 965.94 2601.72 966.16Z" fill="white"/>
                    <path d="M2587.14 1133.92C2587.43 1133.5 2587.75 1133.08 2588.09 1133.5C2588.51 1134.02 2588.73 1134.7 2589.04 1135.31C2588.37 1135.48 2587.76 1135.59 2587.13 1135.05C2586.54 1134.53 2586.99 1134.22 2587.13 1133.92H2587.14Z" fill="white"/>
                    <path d="M2530.29 993.42C2530.68 992.69 2531.04 992.87 2531.54 993.16C2532.32 993.61 2532.08 994.02 2531.71 994.58C2531.32 995.15 2531 995.02 2530.51 994.72C2529.78 994.27 2529.83 993.85 2530.29 993.43V993.42Z" fill="white"/>
                    <path d="M2592.18 1145.82C2592.56 1146.2 2593.49 1146.35 2592.8 1147.11C2592.42 1147.52 2591.77 1147.67 2591.22 1147.37C2590.3 1146.87 2591.17 1146.12 2591.2 1145.59C2591.24 1144.93 2591.81 1145.82 2592.18 1145.82Z" fill="white"/>
                    <path d="M2625.15 1228.89C2625.32 1228.65 2625.45 1228.37 2625.66 1228.17C2626.03 1227.8 2626.36 1228.08 2626.66 1228.32C2626.88 1228.49 2627.24 1228.56 2627.2 1228.94C2627.16 1229.37 2626.91 1229.62 2626.47 1229.66C2626.28 1229.68 2626.08 1229.74 2625.89 1229.76C2625.52 1229.82 2624.95 1230.04 2624.81 1229.88C2624.51 1229.54 2624.98 1229.21 2625.15 1228.88V1228.89Z" fill="white"/>
                    <path d="M2656.35 947.65C2655.9 947.08 2656.46 946.77 2656.69 946.4C2657.12 945.72 2657.52 946.28 2657.89 946.5C2658.24 946.71 2658.56 946.97 2658.18 947.42C2657.8 947.87 2656.74 948 2656.35 947.65Z" fill="white"/>
                    <path d="M2561.03 1075.92C2561.45 1076.24 2561.79 1076.48 2561.41 1076.92C2561.03 1077.37 2559.95 1077.51 2559.58 1077.14C2559.21 1076.76 2559.56 1076.47 2559.73 1076.13C2560.14 1075.3 2560.61 1075.58 2561.02 1075.92H2561.03Z" fill="white"/>
                    <path d="M2603.21 1185.08C2603.08 1184.92 2602.73 1184.68 2602.76 1184.51C2602.84 1184.03 2603.37 1184.15 2603.65 1184.17C2604.07 1184.2 2604.77 1183.35 2604.92 1184.49C2604.97 1184.87 2605.13 1185.27 2604.78 1185.56C2604.07 1186.15 2603.82 1185.2 2603.22 1185.08H2603.21Z" fill="white"/>
                    <path d="M2745.87 1121.39C2746.31 1121.23 2747.01 1120.7 2747.2 1121.28C2747.37 1121.78 2748.19 1122 2747.83 1122.62C2747.62 1122.99 2746.06 1122.66 2745.74 1122.18C2745.57 1121.93 2745.62 1121.64 2745.86 1121.39H2745.87Z" fill="white"/>
                    <path d="M2546.94 1002.25C2546.94 1002.25 2546.95 1001.71 2547.04 1001.23C2547.08 1001.02 2548.46 1001.02 2548.66 1001.23C2549.17 1001.78 2548.57 1002.08 2548.37 1002.46C2548.13 1002.93 2547.78 1002.84 2546.94 1002.25Z" fill="white"/>
                    <path d="M2571.01 975.85C2571.57 975.39 2571.87 975.97 2572.25 976.17C2572.73 976.42 2572.66 976.88 2572.33 977.09C2571.95 977.34 2571.44 977.42 2570.98 977.48C2570.81 977.5 2570.82 976.04 2571.01 975.85Z" fill="white"/>
                    <path d="M2755.47 968.46C2755.96 968.67 2756 968.99 2755.83 969.23C2755.39 969.87 2754.75 969.93 2754.06 969.67C2753.71 969.54 2753.68 969.21 2753.84 968.96C2754.28 968.28 2754.93 968.3 2755.47 968.46Z" fill="white"/>
                    <path d="M2700.52 1136.13C2700.05 1135.69 2699.88 1135.08 2700.18 1134.66C2700.32 1134.45 2701.04 1134.24 2701.52 1134.58C2702.17 1135.05 2701.63 1135.41 2701.41 1135.78C2701.21 1136.13 2700.95 1136.41 2700.52 1136.12V1136.13Z" fill="white"/>
                    <path d="M2663.84 929.23C2664.09 929.36 2664.59 930.69 2664.42 930.73C2663.97 930.84 2663.46 930.94 2663.02 930.84C2662.61 930.75 2662.45 930.34 2662.79 929.95C2663.07 929.63 2663.14 928.96 2663.83 929.23H2663.84Z" fill="white"/>
                    <path d="M2698.65 1142.03C2699.09 1141.69 2699.69 1141.43 2700.35 1141.91C2700.59 1142.08 2700.68 1142.4 2700.4 1142.65C2699.86 1143.14 2699.24 1143.31 2698.6 1142.88C2698.36 1142.72 2698.28 1142.41 2698.66 1142.03H2698.65Z" fill="white"/>
                    <path d="M2744.9 1191.57C2745.19 1191.09 2745.62 1191.15 2745.84 1191.48C2746.09 1191.86 2746.17 1192.37 2746.23 1192.83C2746.25 1193 2744.79 1192.99 2744.6 1192.8C2744.33 1192.53 2744.45 1192.05 2744.91 1191.57H2744.9Z" fill="white"/>
                    <path d="M2593.35 1140.98C2593.6 1140.64 2593.84 1140.29 2594.28 1140.65C2594.76 1141.04 2594.9 1141.66 2594.61 1142.09C2594.46 1142.3 2593.75 1142.51 2593.27 1142.17C2592.62 1141.7 2593.18 1141.35 2593.35 1140.99V1140.98Z" fill="white"/>
                    <path d="M2655.04 1214.39C2655.54 1214.12 2656.03 1213.93 2656.58 1214.22C2656.9 1214.39 2656.97 1214.71 2656.77 1214.93C2656.44 1215.29 2656.39 1216.19 2655.54 1215.71C2655.02 1215.42 2655.26 1214.83 2655.05 1214.39H2655.04Z" fill="white"/>
                    <path d="M2607.05 1138.17C2607.22 1138.11 2607.39 1138.05 2607.56 1137.99C2607.74 1138.84 2607.78 1139.74 2608.43 1140.44C2608.46 1140.47 2608.25 1140.8 2608.09 1140.91C2608 1140.97 2607.67 1140.87 2607.67 1140.83C2607.6 1140.03 2606.69 1139.57 2606.82 1138.7C2606.85 1138.52 2606.97 1138.35 2607.04 1138.17H2607.05Z" fill="white"/>
                    <path d="M2611.44 1139.91C2611.91 1140.28 2611.2 1140.73 2610.91 1141.08C2610.32 1141.79 2609.94 1140.94 2609.49 1140.73C2609.36 1140.67 2609.09 1140.09 2609.59 1140.24C2610.34 1140.46 2610.31 1138.95 2611.43 1139.91H2611.44Z" fill="white"/>
                    <path d="M2708.8 979.15C2708.14 978.84 2708.36 978.15 2708.17 977.64C2708.54 977.56 2709.04 977.29 2709.24 977.44C2709.69 977.76 2709.74 978.34 2709.5 978.88C2709.36 979.21 2709.09 979.22 2708.8 979.15Z" fill="white"/>
                    <path d="M2548.42 1009.15C2548.73 1009.44 2549.38 1009.57 2548.94 1010.25C2548.62 1010.74 2548.29 1010.55 2547.97 1010.3C2547.62 1010.03 2547 1009.88 2547.44 1009.2C2547.76 1008.71 2548.08 1008.92 2548.43 1009.14L2548.42 1009.15Z" fill="white"/>
                    <path d="M2774.51 1025.97C2775.43 1025.59 2776.04 1025.79 2776.56 1026.3C2776.68 1026.42 2776.71 1026.63 2776.78 1026.8C2776.59 1026.84 2776.38 1026.84 2776.21 1026.91C2775.54 1027.21 2775.1 1026.78 2774.66 1026.42C2774.52 1026.31 2774.53 1026.02 2774.52 1025.96L2774.51 1025.97Z" fill="white"/>
                    <path d="M2526.17 987.54C2526.25 987.41 2526.6 987.56 2526.93 987.82C2527.27 988.09 2527.92 988.23 2527.48 988.92C2527.17 989.4 2526.84 989.25 2526.51 988.99C2526.16 988.72 2525.54 988.56 2526.18 987.54H2526.17Z" fill="white"/>
                    <path d="M2555.89 1044.56C2555.95 1044.69 2556.1 1044.88 2556.05 1044.96C2555.72 1045.56 2555.38 1046.12 2554.55 1046.13C2554.21 1046.13 2554.01 1046.09 2554.07 1045.75C2554.16 1045.22 2555.43 1044.41 2555.89 1044.56Z" fill="white"/>
                    <path d="M2658.78 987.41C2659.51 986.77 2659.78 987.7 2660.25 987.9C2660.46 987.99 2660.35 988.46 2660.11 988.66C2659.41 989.24 2659.08 988.43 2658.63 988.18C2658.42 988.06 2658.53 987.64 2658.78 987.41Z" fill="white"/>
                    <path d="M2605.94 1092.53C2606.67 1091.89 2606.94 1092.82 2607.41 1093.02C2607.62 1093.11 2607.51 1093.58 2607.27 1093.78C2606.57 1094.36 2606.24 1093.55 2605.79 1093.3C2605.58 1093.18 2605.69 1092.76 2605.94 1092.53Z" fill="white"/>
                    <path d="M2741.6 1095.31C2741.68 1095.18 2742.03 1095.33 2742.36 1095.59C2742.7 1095.86 2743.35 1096 2742.91 1096.69C2742.6 1097.17 2742.27 1097.02 2741.94 1096.76C2741.59 1096.49 2740.97 1096.33 2741.61 1095.31H2741.6Z" fill="white"/>
                    <path d="M2551.44 1150.95C2551.38 1151.58 2551.31 1152.23 2551.23 1152.87C2551.23 1152.9 2551.03 1152.95 2550.96 1152.92C2550.57 1152.76 2550.4 1152.46 2550.34 1152.03C2550.28 1151.55 2550.14 1151.06 2550.7 1150.81C2550.83 1150.75 2551.05 1150.87 2551.44 1150.95Z" fill="white"/>
                    <path d="M2605.55 1164.55C2605.2 1164.35 2604.81 1164.15 2605.07 1163.66C2605.4 1163.03 2605.81 1163.22 2606.25 1163.56C2606.56 1163.8 2606.97 1163.97 2606.72 1164.46C2606.39 1165.09 2605.97 1164.93 2605.55 1164.56V1164.55Z" fill="white"/>
                    <path d="M2666.98 1165.52C2667.42 1165.17 2667.68 1165.04 2667.93 1165.32C2668.42 1165.86 2668.58 1166.47 2668.16 1167.12C2668.08 1167.25 2667.83 1167.27 2667.66 1167.34C2667.42 1166.71 2667.19 1166.08 2666.98 1165.52Z" fill="white"/>
                    <path d="M2574.23 1088.35C2574.47 1087.87 2574.8 1087.59 2575 1087.74C2575.4 1088.06 2576.27 1088.08 2575.98 1088.94C2575.88 1089.23 2575.54 1089.5 2575.31 1089.35C2574.88 1089.07 2574.17 1088.93 2574.23 1088.35Z" fill="white"/>
                    <path d="M2744.91 1075.47C2745.16 1075.14 2745.38 1074.82 2745.85 1075.16C2746.5 1075.64 2745.98 1076 2745.75 1076.38C2745.54 1076.73 2745.3 1077.02 2744.82 1076.68C2744.16 1076.2 2744.72 1075.85 2744.9 1075.47H2744.91Z" fill="white"/>
                    <path d="M2669.25 1023.44C2668.61 1022.71 2669.54 1022.44 2669.74 1021.97C2669.83 1021.76 2670.3 1021.87 2670.5 1022.11C2671.08 1022.81 2670.27 1023.14 2670.02 1023.59C2669.9 1023.8 2669.48 1023.69 2669.25 1023.44Z" fill="white"/>
                    <path d="M2662.67 1175.7C2662.32 1175.5 2661.93 1175.3 2662.19 1174.81C2662.52 1174.18 2662.93 1174.37 2663.37 1174.71C2663.68 1174.95 2664.09 1175.12 2663.84 1175.61C2663.51 1176.24 2663.09 1176.08 2662.67 1175.71V1175.7Z" fill="white"/>
                    <path d="M2515.66 1092.66C2515.05 1092.32 2515.03 1092.05 2515.18 1091.77C2515.52 1091.14 2515.94 1091.36 2516.35 1091.7C2516.56 1091.88 2517.06 1092.14 2516.86 1092.28C2516.46 1092.56 2515.89 1092.6 2515.66 1092.66Z" fill="white"/>
                    <path d="M2752.84 1038.07C2752.98 1037.99 2753.22 1037.85 2753.45 1037.72C2753.52 1038.26 2753.93 1038.82 2753.45 1039.34C2753.25 1039.57 2753 1039.62 2752.7 1039.4C2752.06 1038.93 2752.57 1038.58 2752.84 1038.07Z" fill="white"/>
                    <path d="M2657.21 1235.97C2657.46 1236.08 2657.5 1236.37 2657.36 1236.64C2657.18 1236.99 2656.88 1237.33 2656.46 1236.94C2656.16 1236.67 2655.38 1236.6 2655.76 1236.01C2655.9 1235.8 2656.63 1235.6 2657.2 1235.97H2657.21Z" fill="white"/>
                    <path d="M2499.63 1134.89C2499.96 1134.26 2500.63 1134.47 2501.16 1134.3C2501.1 1134.56 2501.1 1134.87 2500.96 1135.09C2500.72 1135.45 2500.56 1136.06 2499.88 1135.64C2499.57 1135.45 2499.55 1135.22 2499.63 1134.9V1134.89Z" fill="white"/>
                    <path d="M2673.57 1206.01C2673.96 1206.16 2673.6 1205.91 2673.61 1206.3C2673.61 1206.51 2673.36 1206.87 2673.17 1206.92C2672.64 1207.04 2672.05 1207.39 2671.57 1206.82C2671.52 1206.76 2671.48 1206.58 2671.51 1206.55C2672.12 1206.05 2672.91 1206.24 2673.58 1206.02L2673.57 1206.01Z" fill="white"/>
                    <path d="M2669.28 1149.57C2669.84 1149.51 2670.45 1149.13 2670.89 1149.73C2670.99 1149.87 2670.88 1150.33 2670.71 1150.49C2670.19 1151 2669.87 1150.38 2669.48 1150.19C2669.21 1150.06 2669.06 1149.87 2669.28 1149.57Z" fill="white"/>
                    <path d="M2635.06 1058.58C2635.17 1058.42 2635.25 1058.21 2635.41 1058.1C2635.51 1058.03 2635.8 1058.04 2635.85 1058.12C2636.03 1058.4 2635 1059.95 2634.63 1059.93C2634.54 1059.93 2634.37 1059.63 2634.4 1059.57C2634.59 1059.22 2634.83 1058.91 2635.06 1058.58Z" fill="white"/>
                    <path d="M2768.24 1155.35C2768.56 1154.82 2768.92 1154.94 2769.23 1155.22C2769.46 1155.42 2769.51 1155.67 2769.29 1155.98C2768.97 1156.44 2768.64 1156.32 2768.32 1156.05C2768.1 1155.86 2767.96 1155.57 2768.23 1155.36L2768.24 1155.35Z" fill="white"/>
                    <path d="M2502.72 1115.34C2502.97 1115.13 2503.19 1115.06 2503.5 1115.27C2503.96 1115.59 2503.84 1115.92 2503.57 1116.24C2503.37 1116.47 2503.12 1116.52 2502.81 1116.3C2502.35 1115.98 2502.5 1115.67 2502.72 1115.34Z" fill="white"/>
                    <path d="M2653.81 951.65C2654.06 951.44 2654.28 951.37 2654.59 951.58C2655.05 951.9 2654.93 952.23 2654.66 952.55C2654.46 952.78 2654.21 952.83 2653.9 952.61C2653.44 952.29 2653.59 951.98 2653.81 951.65Z" fill="white"/>
                    <path d="M2755.22 1006.51C2755.53 1006.73 2755.78 1006.96 2755.47 1007.43C2755.26 1007.74 2755.01 1007.79 2754.74 1007.64C2754.37 1007.44 2754.14 1007.18 2754.46 1006.71C2754.67 1006.4 2754.89 1006.38 2755.23 1006.5L2755.22 1006.51Z" fill="white"/>
                    <path d="M2523.88 1009.92C2523.59 1009.44 2523.43 1008.95 2523.7 1008.42C2523.77 1008.28 2524.11 1008.12 2524.12 1008.13C2524.48 1008.57 2524.62 1009.07 2524.34 1009.6C2524.27 1009.74 2524.06 1009.81 2523.89 1009.92H2523.88Z" fill="white"/>
                    <path d="M2614.04 949.55C2614.18 949.22 2614.48 949.21 2614.75 949.35C2615.12 949.55 2615.35 949.81 2615.04 950.28C2614.83 950.59 2614.58 950.64 2614.31 950.49C2613.94 950.29 2613.69 950.01 2614.05 949.55H2614.04Z" fill="white"/>
                    <path d="M2569.08 1061.42C2569.33 1061.21 2569.55 1061.14 2569.86 1061.35C2570.32 1061.67 2570.2 1062 2569.93 1062.32C2569.73 1062.55 2569.48 1062.6 2569.17 1062.38C2568.71 1062.06 2568.86 1061.75 2569.08 1061.42Z" fill="white"/>
                    <path d="M2720.89 1202.97C2721.19 1202.73 2721.4 1202.65 2721.71 1202.87C2722.17 1203.19 2722.05 1203.52 2721.78 1203.84C2721.58 1204.07 2721.33 1204.11 2721.02 1203.9C2720.54 1203.58 2720.75 1203.27 2720.89 1202.97Z" fill="white"/>
                    <path d="M2698.05 1193.31C2698.55 1193.61 2698.42 1193.96 2698.14 1194.28C2697.94 1194.51 2697.69 1194.56 2697.38 1194.34C2696.92 1194.02 2697.04 1193.69 2697.31 1193.37C2697.51 1193.14 2697.79 1193.04 2698.04 1193.3L2698.05 1193.31Z" fill="white"/>
                    <path d="M2519.73 967.99C2519.5 968.31 2519.26 968.63 2518.82 968.27C2518.69 968.16 2518.61 967.98 2518.5 967.84C2518.91 967.71 2519.32 967.57 2519.73 967.44C2519.73 967.62 2519.73 967.81 2519.73 968V967.99Z" fill="white"/>
                    <path d="M2572.96 980.51C2573.19 980.19 2573.43 979.87 2573.87 980.23C2574 980.34 2574.08 980.52 2574.19 980.66C2573.78 980.79 2573.37 980.93 2572.96 981.06C2572.96 980.88 2572.96 980.69 2572.96 980.5V980.51Z" fill="white"/>
                    <path d="M2525.54 1112.29C2525.94 1112.21 2526.32 1112.13 2526.7 1112.05C2526.64 1112.31 2526.66 1112.64 2526.51 1112.83C2526.18 1113.25 2525.82 1112.97 2525.55 1112.71C2525.48 1112.64 2525.55 1112.42 2525.55 1112.3L2525.54 1112.29Z" fill="white"/>
                    <path d="M2676.33 1028.28C2676.46 1028.47 2676.69 1028.7 2676.65 1028.76C2676.37 1029.21 2675.83 1029 2675.43 1029.16C2675.43 1028.98 2675.38 1028.76 2675.46 1028.62C2675.65 1028.25 2675.93 1028.02 2676.33 1028.29V1028.28Z" fill="white"/>
                    <path d="M2750.42 967.62C2750.56 967.57 2750.75 967.43 2750.84 967.47C2751.19 967.63 2751.57 967.87 2751.29 968.33C2751.16 968.54 2750.86 968.64 2750.63 968.79C2750.56 968.41 2750.49 968.03 2750.41 967.63L2750.42 967.62Z" fill="white"/>
                    <path d="M2721.79 977.01C2721.32 976.72 2721.49 976.37 2721.75 976.06C2721.86 975.94 2722.08 975.91 2722.25 975.85C2722.27 976.28 2722.3 976.71 2722.32 977.15C2722.15 977.11 2721.97 977.06 2721.79 977.01Z" fill="white"/>
                    <path d="M2546.73 1099.66C2546.8 1099.63 2546.98 1099.47 2547.07 1099.52C2547.42 1099.68 2547.8 1099.91 2547.54 1100.37C2547.42 1100.58 2547.11 1100.68 2546.89 1100.84C2546.84 1100.46 2546.78 1100.08 2546.72 1099.67L2546.73 1099.66Z" fill="white"/>
                    <path d="M2570.29 1068.25C2570.58 1067.78 2570.93 1067.95 2571.24 1068.21C2571.36 1068.32 2571.39 1068.54 2571.45 1068.71C2571.02 1068.73 2570.59 1068.76 2570.15 1068.78C2570.19 1068.61 2570.24 1068.43 2570.29 1068.25Z" fill="white"/>
                    <path d="M2687.97 940.46C2688.14 940.38 2688.31 940.31 2688.47 940.23C2688.51 940.66 2688.56 941.09 2688.6 941.52C2688.42 941.48 2688.23 941.48 2688.08 941.4C2687.6 941.15 2687.74 940.8 2687.97 940.46Z" fill="white"/>
                    <path d="M2702.83 1174.51C2702.36 1174.22 2702.53 1173.87 2702.79 1173.56C2702.9 1173.44 2703.12 1173.41 2703.29 1173.35C2703.31 1173.78 2703.34 1174.21 2703.36 1174.65C2703.19 1174.61 2703.01 1174.56 2702.83 1174.51Z" fill="white"/>
                    <path d="M2738.54 1196.06C2738.56 1195.81 2738.51 1195.6 2738.59 1195.45C2738.79 1195.08 2739.09 1194.85 2739.49 1195.2C2739.62 1195.31 2739.69 1195.5 2739.79 1195.65C2739.38 1195.78 2738.97 1195.92 2738.54 1196.06Z" fill="white"/>
                    <path d="M2515.19 1173.61C2515.56 1173.43 2515.75 1173.73 2515.97 1173.89C2516.04 1173.94 2516.01 1174.27 2515.94 1174.32C2515.61 1174.52 2515.4 1174.21 2515.19 1174.04C2515.12 1173.98 2515.19 1173.75 2515.19 1173.61Z" fill="white"/>
                    <path d="M2516.57 1147.15C2516.67 1146.83 2516.71 1146.55 2516.85 1146.33C2516.93 1146.2 2517.17 1146.18 2517.34 1146.11C2517.28 1146.37 2517.27 1146.66 2517.15 1146.89C2517.08 1147.01 2516.83 1147.04 2516.56 1147.15H2516.57Z" fill="white"/>
                    <path d="M2778.9 1095.87C2779.27 1095.69 2779.46 1095.99 2779.68 1096.15C2779.75 1096.2 2779.72 1096.53 2779.65 1096.58C2779.32 1096.78 2779.11 1096.47 2778.9 1096.3C2778.83 1096.24 2778.9 1096.01 2778.9 1095.87Z" fill="white"/>
                    <path d="M2748.77 986.95C2749.09 987.05 2749.37 987.09 2749.59 987.23C2749.72 987.31 2749.74 987.55 2749.81 987.72C2749.55 987.66 2749.26 987.65 2749.03 987.53C2748.91 987.46 2748.88 987.21 2748.77 986.94V986.95Z" fill="white"/>
                    <path d="M2745.87 985.44C2745.99 985.54 2746.24 985.68 2746.4 985.88C2746.59 986.12 2746.47 986.3 2746.18 986.2C2745.94 986.12 2745.69 985.95 2745.53 985.75C2745.33 985.52 2745.45 985.38 2745.88 985.43L2745.87 985.44Z" fill="white"/>
                    <path d="M2794.72 1086.55C2794.62 1086.4 2794.41 1086.19 2794.45 1086.11C2794.6 1085.78 2794.87 1085.86 2795.08 1086.07C2795.21 1086.19 2795.4 1086.42 2795.36 1086.51C2795.21 1086.84 2794.95 1086.74 2794.72 1086.55Z" fill="white"/>
                    <path d="M2509.26 1113.33C2509.41 1113.23 2509.62 1113.02 2509.7 1113.06C2510.03 1113.21 2509.95 1113.48 2509.74 1113.69C2509.62 1113.82 2509.39 1114.01 2509.3 1113.97C2508.97 1113.82 2509.07 1113.56 2509.26 1113.33Z" fill="white"/>
                    <path d="M2678.57 946.04C2678.67 945.72 2678.71 945.44 2678.85 945.22C2678.93 945.09 2679.17 945.07 2679.34 945C2679.28 945.26 2679.27 945.55 2679.15 945.78C2679.08 945.9 2678.83 945.93 2678.56 946.04H2678.57Z" fill="white"/>
                    <path d="M2640.91 1209.15C2641.03 1209.1 2641.2 1209.03 2641.37 1208.96C2641.31 1209.22 2641.3 1209.51 2641.18 1209.74C2641.11 1209.86 2640.86 1209.89 2640.69 1209.96C2640.6 1209.66 2640.64 1209.39 2640.91 1209.15Z" fill="white"/>
                    <path d="M2638.95 921.75C2639.21 921.9 2639.39 922.1 2639.14 922.36C2639.07 922.43 2638.79 922.33 2638.63 922.26C2638.36 922.14 2638.19 921.92 2638.44 921.66C2638.51 921.59 2638.78 921.72 2638.95 921.76V921.75Z" fill="white"/>
                    <path d="M2665.1 1175.9C2665.36 1176.05 2665.54 1176.25 2665.29 1176.51C2665.22 1176.58 2664.94 1176.48 2664.78 1176.41C2664.51 1176.29 2664.34 1176.07 2664.59 1175.81C2664.66 1175.74 2664.93 1175.87 2665.1 1175.91V1175.9Z" fill="white"/>
                    <path d="M2662.73 1163.24C2662.99 1163.39 2663.17 1163.59 2662.92 1163.85C2662.85 1163.92 2662.57 1163.82 2662.41 1163.75C2662.14 1163.63 2661.97 1163.41 2662.22 1163.15C2662.29 1163.08 2662.56 1163.21 2662.73 1163.25V1163.24Z" fill="white"/>
                    <path d="M2647.46 1118.29C2647.56 1117.97 2647.6 1117.69 2647.74 1117.47C2647.82 1117.34 2648.06 1117.32 2648.23 1117.25C2648.17 1117.51 2648.16 1117.8 2648.04 1118.03C2647.97 1118.15 2647.72 1118.18 2647.45 1118.29H2647.46Z" fill="white"/>
                    <path d="M2537.74 1104.97C2537.94 1104.86 2538.18 1104.73 2538.42 1104.61C2538.42 1104.79 2538.49 1105.04 2538.39 1105.15C2538.22 1105.35 2537.95 1105.46 2537.73 1105.61C2537.73 1105.43 2537.73 1105.24 2537.74 1104.97Z" fill="white"/>
                    <path d="M2611.48 1042.54C2611.6 1042.49 2611.77 1042.42 2611.94 1042.35C2611.88 1042.61 2611.87 1042.9 2611.75 1043.13C2611.68 1043.25 2611.43 1043.28 2611.26 1043.35C2611.17 1043.05 2611.21 1042.78 2611.48 1042.54Z" fill="white"/>
                    <path d="M2577.39 1009.96C2577.49 1009.64 2577.53 1009.36 2577.67 1009.14C2577.75 1009.01 2577.99 1008.99 2578.16 1008.92C2578.1 1009.18 2578.09 1009.47 2577.97 1009.7C2577.9 1009.82 2577.65 1009.85 2577.38 1009.96H2577.39Z" fill="white"/>
                    <path d="M2523.57 959.3C2523.94 959.12 2524.13 959.42 2524.35 959.58C2524.42 959.63 2524.39 959.96 2524.32 960.01C2523.99 960.21 2523.78 959.9 2523.57 959.73C2523.5 959.67 2523.57 959.44 2523.57 959.3Z" fill="white"/>
                    <path d="M2589.19 968.63C2589.45 968.78 2589.63 968.98 2589.38 969.24C2589.31 969.31 2589.03 969.21 2588.87 969.14C2588.6 969.02 2588.43 968.8 2588.68 968.54C2588.75 968.47 2589.02 968.6 2589.19 968.64V968.63Z" fill="white"/>
                    <path d="M2759.9 1096.57C2759.8 1096.42 2759.59 1096.21 2759.63 1096.13C2759.78 1095.8 2760.05 1095.88 2760.26 1096.09C2760.39 1096.21 2760.58 1096.44 2760.54 1096.53C2760.39 1096.86 2760.13 1096.76 2759.9 1096.57Z" fill="white"/>
                    <path d="M2670.33 1054.35C2670.48 1054.25 2670.69 1054.04 2670.77 1054.08C2671.1 1054.23 2671.02 1054.5 2670.81 1054.71C2670.69 1054.84 2670.46 1055.03 2670.37 1054.99C2670.04 1054.84 2670.14 1054.58 2670.33 1054.35Z" fill="white"/>
                    <path d="M2678.49 1040.82C2678.51 1040.67 2678.51 1040.51 2678.56 1040.38C2678.58 1040.33 2678.74 1040.34 2678.83 1040.32C2678.81 1040.47 2678.81 1040.63 2678.76 1040.76C2678.74 1040.81 2678.58 1040.8 2678.49 1040.82Z" fill="white"/>
                    <path d="M2690.98 930.15C2691 930 2691 929.84 2691.05 929.71C2691.07 929.66 2691.23 929.67 2691.32 929.65C2691.3 929.8 2691.3 929.96 2691.25 930.09C2691.23 930.14 2691.07 930.13 2690.98 930.15Z" fill="white"/>
                    <path d="M2654.63 1153.75C2654.76 1153.68 2654.89 1153.62 2655.03 1153.55C2655.05 1153.64 2655.11 1153.79 2655.07 1153.82C2654.96 1153.92 2654.81 1153.98 2654.68 1154.05C2654.66 1153.96 2654.64 1153.86 2654.62 1153.75H2654.63Z" fill="white"/>
                    <path d="M2684.17 1190.9C2684.06 1190.92 2683.96 1190.94 2683.87 1190.96C2683.89 1190.81 2683.89 1190.65 2683.94 1190.52C2683.96 1190.47 2684.12 1190.48 2684.21 1190.46C2684.2 1190.61 2684.18 1190.76 2684.17 1190.9Z" fill="white"/>
                    <path d="M2610.42 1142.24C2610.49 1142.37 2610.55 1142.5 2610.62 1142.64C2610.53 1142.66 2610.38 1142.72 2610.35 1142.68C2610.25 1142.57 2610.19 1142.42 2610.12 1142.29C2610.21 1142.27 2610.31 1142.25 2610.42 1142.23V1142.24Z" fill="white"/>
                    <path d="M2603.12 1095.55C2603.27 1095.57 2603.43 1095.57 2603.56 1095.62C2603.61 1095.64 2603.6 1095.8 2603.62 1095.89C2603.47 1095.87 2603.31 1095.87 2603.18 1095.82C2603.13 1095.8 2603.14 1095.64 2603.12 1095.55Z" fill="white"/>
                    <path d="M2565.22 1063.45C2565.24 1063.56 2565.26 1063.66 2565.28 1063.75C2565.13 1063.73 2564.97 1063.73 2564.84 1063.68C2564.79 1063.66 2564.8 1063.5 2564.78 1063.41C2564.93 1063.42 2565.08 1063.44 2565.22 1063.45Z" fill="white"/>
                    <path d="M2516.17 1100.15C2516.3 1100.08 2516.43 1100.02 2516.57 1099.95C2516.59 1100.04 2516.65 1100.19 2516.61 1100.22C2516.5 1100.32 2516.35 1100.38 2516.22 1100.45C2516.2 1100.36 2516.18 1100.26 2516.16 1100.15H2516.17Z" fill="white"/>
                    <path d="M2625.74 1155.1C2625.85 1155.08 2625.95 1155.06 2626.04 1155.04C2626.02 1155.19 2626.02 1155.35 2625.97 1155.48C2625.95 1155.53 2625.79 1155.52 2625.7 1155.54C2625.71 1155.39 2625.73 1155.24 2625.74 1155.1Z" fill="white"/>
                    <path d="M2630.49 1079.07C2630.38 1079.09 2630.28 1079.11 2630.19 1079.13C2630.21 1078.98 2630.21 1078.82 2630.26 1078.69C2630.28 1078.64 2630.44 1078.65 2630.53 1078.63C2630.52 1078.78 2630.5 1078.93 2630.49 1079.07Z" fill="white"/>
                    <path d="M2516.28 986.36C2516.26 986.26 2516.21 986.11 2516.23 986.1C2516.36 986.03 2516.52 985.94 2516.66 985.96C2516.94 986 2516.97 986.18 2516.73 986.32C2516.61 986.39 2516.43 986.35 2516.28 986.37V986.36Z" fill="white"/>
                    <path d="M2517.04 985.87C2517.19 985.87 2517.43 985.79 2517.48 985.85C2517.63 986.04 2517.56 986.22 2517.37 986.21C2517.21 986.21 2517.05 986.13 2516.89 986.09C2516.94 986.02 2516.99 985.94 2517.04 985.87Z" fill="white"/>
                    <path d="M2709.22 1043.93C2709.35 1043.86 2709.48 1043.8 2709.62 1043.73C2709.64 1043.82 2709.7 1043.97 2709.66 1044C2709.55 1044.1 2709.4 1044.16 2709.27 1044.23C2709.25 1044.14 2709.23 1044.04 2709.21 1043.93H2709.22Z" fill="white"/>
                    <path d="M2467 1059.12C2467.13 1059.05 2467.26 1058.99 2467.4 1058.92C2467.42 1059.01 2467.48 1059.16 2467.44 1059.19C2467.33 1059.29 2467.18 1059.35 2467.05 1059.42C2467.03 1059.33 2467.01 1059.23 2466.99 1059.12H2467Z" fill="white"/>
                    <path d="M2514.07 978.54C2514.18 978.52 2514.28 978.5 2514.37 978.48C2514.35 978.63 2514.35 978.79 2514.3 978.92C2514.28 978.97 2514.12 978.96 2514.03 978.98C2514.04 978.83 2514.06 978.68 2514.07 978.54Z" fill="white"/>
                    <path d="M2497.54 1091.59C2497.41 1091.66 2497.28 1091.72 2497.14 1091.79C2497.12 1091.7 2497.06 1091.55 2497.1 1091.52C2497.21 1091.42 2497.36 1091.36 2497.49 1091.29C2497.51 1091.38 2497.53 1091.48 2497.55 1091.59H2497.54Z" fill="white"/>
                    <path d="M2617.76 1003.39C2617.89 1003.32 2618.02 1003.26 2618.16 1003.19C2618.18 1003.28 2618.24 1003.43 2618.2 1003.46C2618.09 1003.56 2617.94 1003.62 2617.81 1003.69C2617.79 1003.6 2617.77 1003.5 2617.75 1003.39H2617.76Z" fill="white"/>
                    <path d="M2578.44 1138.74C2578.46 1138.85 2578.48 1138.95 2578.5 1139.04C2578.35 1139.02 2578.19 1139.02 2578.06 1138.97C2578.01 1138.95 2578.02 1138.79 2578 1138.7C2578.15 1138.71 2578.3 1138.73 2578.44 1138.74Z" fill="white"/>
                    <path d="M2548.64 986.49C2548.66 986.34 2548.66 986.18 2548.71 986.05C2548.73 986 2548.89 986.01 2548.98 985.99C2548.96 986.14 2548.96 986.3 2548.91 986.43C2548.89 986.48 2548.73 986.47 2548.64 986.49Z" fill="white"/>
                    <path d="M2566.12 1133.62C2566.25 1133.55 2566.38 1133.49 2566.52 1133.42C2566.54 1133.51 2566.6 1133.66 2566.56 1133.69C2566.45 1133.79 2566.3 1133.85 2566.17 1133.92L2566.11 1133.62H2566.12Z" fill="white"/>
                    <path d="M2659.35 1059.26C2659.28 1059.13 2659.22 1059 2659.15 1058.86C2659.24 1058.84 2659.39 1058.78 2659.42 1058.82C2659.52 1058.93 2659.58 1059.08 2659.65 1059.21C2659.56 1059.23 2659.46 1059.25 2659.35 1059.27V1059.26Z" fill="white"/>
                    <path d="M2799.63 1078.87C2799.65 1078.72 2799.65 1078.56 2799.7 1078.43C2799.72 1078.38 2799.88 1078.39 2799.97 1078.37C2799.95 1078.52 2799.95 1078.68 2799.9 1078.81C2799.88 1078.86 2799.72 1078.85 2799.63 1078.87Z" fill="white"/>
                    <path d="M2408.2 1162.26C2406.88 1161.2 2407.19 1159.92 2407.51 1158.71C2407.63 1158.27 2407.91 1157.62 2407.88 1157.37C2407.73 1155.78 2409.51 1156.43 2409.85 1155.51C2410.69 1155.98 2411.84 1156.26 2412.26 1156.97C2412.66 1157.65 2413.09 1158.54 2413.15 1159.42C2413.21 1160.25 2413.68 1161.39 2412.07 1161.41C2411.83 1161.41 2411.62 1161.89 2411.36 1162.13C2410.24 1163.16 2409.16 1163.17 2408.19 1162.26H2408.2Z" fill="white"/>
                    <path d="M2544.03 1146.53C2543.33 1147.25 2542.27 1147.22 2542.02 1146.97C2541.05 1146.01 2539.8 1146.54 2538.75 1146.08C2538.01 1145.76 2537.61 1145.45 2537.64 1144.67C2537.68 1143.38 2538.01 1142.89 2538.93 1142.73C2539.33 1142.66 2539.71 1142.55 2540.09 1142.42C2541.76 1141.87 2544.75 1143.83 2544.31 1145.8C2544.23 1146.15 2544.18 1146.44 2544.03 1146.53Z" fill="white"/>
                    <path d="M2476.03 1101.39C2475.15 1101.39 2474.41 1102.44 2473.47 1101.67C2473.34 1101.56 2473.24 1101.37 2473.09 1101.33C2472.47 1101.14 2471.74 1101.16 2471.48 1100.39C2471.27 1099.78 2471.64 1099.26 2471.8 1098.72C2472.12 1097.65 2473.52 1096.92 2474.73 1097.15C2475.67 1097.33 2476.2 1097.87 2476.5 1098.8C2476.81 1099.76 2476.24 1100.53 2476.04 1101.39H2476.03Z" fill="white"/>
                    <path d="M2509.1 984.57C2510.13 983.48 2511 983.36 2511.88 984.01C2512.36 984.37 2512.75 984.84 2513.22 985.23C2513.66 985.6 2513.62 985.89 2513.22 986.26C2512.78 986.68 2512.44 987.21 2511.97 987.56C2511.16 988.16 2510.21 988.18 2509.28 987.86C2509.09 987.8 2508.84 987.52 2508.84 987.34C2508.83 986.66 2508.59 986.05 2508.45 985.42C2508.38 985.11 2509.03 984.65 2509.1 984.56V984.57Z" fill="white"/>
                    <path d="M2602.15 1049.34C2601.73 1049.87 2601.14 1050.65 2600.18 1050.13C2599.72 1049.89 2598.87 1049.43 2599.15 1048.85C2599.46 1048.22 2599.29 1047.25 2600.16 1046.95C2600.77 1046.74 2601.48 1046.73 2602 1046.39C2602.48 1046.08 2602.68 1046.12 2603.06 1046.46C2603.6 1046.95 2603.6 1047.53 2603.31 1048.05C2603.07 1048.49 2602.63 1048.82 2602.15 1049.33V1049.34Z" fill="white"/>
                    <path d="M2496.5 970.67C2496.04 971.18 2495.25 971.24 2494.74 970.81C2494.57 970.67 2493.84 968.32 2493.9 968.13C2494.05 967.69 2495.59 967.3 2495.58 967.39C2495.4 968.46 2496.62 967.93 2496.8 968.51C2496.97 969.07 2496.8 970.35 2496.51 970.67H2496.5Z" fill="white"/>
                    <path d="M2419.64 1103.98C2419.9 1104.23 2420.36 1104.54 2420.13 1104.79C2419.7 1105.26 2419.44 1105.97 2418.59 1106C2418.12 1106.02 2417.82 1105.65 2417.44 1105.66C2416.82 1105.67 2416.53 1105.51 2416.47 1104.83C2416.41 1104.1 2416.77 1103.58 2417.33 1103.48C2418.12 1103.35 2419.05 1103.09 2419.64 1103.97V1103.98Z" fill="white"/>
                    <path d="M2509.6 1105.75C2510 1105.38 2510.27 1104.67 2511 1105.41C2511.16 1105.58 2511.66 1105.4 2512 1105.41C2512.39 1105.42 2512.78 1105.46 2513.16 1105.49C2512.81 1105.95 2512.54 1106.63 2512.08 1106.82C2511.49 1107.06 2510.73 1106.9 2510.05 1106.95C2509.68 1106.98 2509.32 1106.94 2509.18 1106.6C2509.02 1106.23 2509.38 1106 2509.6 1105.76V1105.75Z" fill="white"/>
                    <path d="M2402.71 1017.76C2402.83 1017.64 2402.93 1017.5 2403.06 1017.39C2403.35 1017.15 2403.51 1016.57 2404.04 1016.87C2404.58 1017.18 2404.83 1017.67 2404.77 1018.33C2404.72 1018.89 2404.39 1018.96 2403.93 1019.01C2403.64 1019.04 2403.37 1019.33 2403.08 1019.37C2402.69 1019.42 2402.21 1019.57 2401.93 1019.14C2401.59 1018.62 2402.15 1018.44 2402.37 1018.13C2402.47 1017.99 2402.6 1017.88 2402.71 1017.76Z" fill="white"/>
                    <path d="M2505.78 969.05C2505.54 968.29 2505.06 967.58 2505.2 966.73C2505.23 966.54 2505.45 966.29 2505.77 966.32C2506.17 966.35 2506.71 966.12 2506.92 966.59C2507.18 967.17 2507.31 967.81 2507.43 968.44C2507.57 969.18 2506.83 968.67 2506.61 968.97C2506.39 969.28 2506.1 969.4 2505.77 969.05H2505.78Z" fill="white"/>
                    <path d="M2482.71 1138.32C2482.97 1137.93 2483.28 1137.82 2483.68 1138.24C2484.3 1138.88 2484.34 1139.57 2483.83 1140.17C2483.37 1140.71 2482.79 1140.69 2482.27 1140.45C2481.68 1140.17 2481.91 1139.14 2482.71 1138.32Z" fill="white"/>
                    <path d="M2500.07 1214.06C2500.25 1213.87 2500.4 1213.64 2500.6 1213.5C2501.09 1213.16 2501.72 1213.5 2501.75 1214.09C2501.76 1214.43 2501.84 1214.79 2501.77 1215.12C2501.68 1215.54 2501.35 1215.94 2500.96 1215.99C2500.51 1216.05 2500.12 1215.53 2499.6 1215.66C2499.65 1215.18 2499.35 1214.62 2499.89 1214.24C2499.96 1214.19 2500.01 1214.12 2500.07 1214.05V1214.06Z" fill="white"/>
                    <path d="M2478.29 1008.41C2477.99 1008.42 2477.73 1008.41 2477.48 1008.44C2476.84 1008.51 2476.69 1007.55 2475.97 1007.74C2475.59 1007.84 2475.3 1007.57 2475.33 1007.18C2475.36 1006.81 2475.65 1006.65 2476.03 1006.65C2476.54 1006.65 2477.05 1006.59 2477.56 1006.62C2477.8 1006.63 2478.25 1006.32 2478.25 1006.94C2478.25 1007.44 2478.28 1007.93 2478.3 1008.42L2478.29 1008.41Z" fill="white"/>
                    <path d="M2583.35 988.23C2583.69 988.66 2583.48 989.63 2582.97 990C2582.77 990.14 2581.19 989.87 2581.04 989.61C2580.64 988.96 2581.33 988.74 2581.63 988.43C2582.17 987.86 2583.03 987.81 2583.36 988.23H2583.35Z" fill="white"/>
                    <path d="M2570.07 1058.99C2569.63 1058.69 2569.47 1058.39 2569.9 1057.97C2570.42 1057.46 2571.27 1057.28 2571.65 1057.64C2572.03 1057.99 2571.91 1058.88 2571.41 1059.4C2570.67 1060.18 2570.48 1059.16 2570.07 1058.99Z" fill="white"/>
                    <path d="M2571.8 1065.07C2571.34 1065.2 2570.85 1065.36 2570.43 1065.02C2569.97 1064.65 2570.23 1063.49 2570.81 1063.09C2571.19 1062.83 2571.42 1063.08 2571.64 1063.33C2572.15 1063.9 2572.22 1064.73 2571.8 1065.08V1065.07Z" fill="white"/>
                    <path d="M2508.32 1017.36C2508.55 1017.11 2508.68 1017 2508.78 1016.86C2509.15 1016.35 2509.7 1016.61 2510.17 1016.52C2510.11 1016.99 2510.41 1017.53 2509.92 1017.93C2509.79 1018.04 2509.68 1018.17 2509.57 1018.3C2509.21 1018.75 2508.91 1018.96 2508.37 1018.44C2507.85 1017.94 2507.93 1017.61 2508.31 1017.36H2508.32Z" fill="white"/>
                    <path d="M2605.76 1130.06C2605.29 1129.67 2605.48 1129.3 2605.77 1129.17C2606.36 1128.92 2607 1128.78 2607.64 1128.65C2608.23 1128.53 2608 1129.02 2608.05 1129.28C2608.26 1130.41 2607.12 1129.96 2606.71 1130.39C2606.23 1130.88 2606.03 1130.15 2605.76 1130.07V1130.06Z" fill="white"/>
                    <path d="M2536.79 1030.26C2536.41 1030.87 2535.8 1030.86 2535.28 1030.63C2534.89 1030.46 2534.88 1029.03 2535.21 1028.92C2535.86 1028.71 2536.42 1028.89 2536.9 1029.41C2537.24 1029.78 2537.01 1030 2536.78 1030.26H2536.79Z" fill="white"/>
                    <path d="M2385.1 1073.82C2384.79 1073.41 2384.86 1072.93 2385 1072.46C2385.08 1072.18 2386.5 1072.35 2386.92 1072.68C2387.3 1072.98 2387.11 1073.25 2386.86 1073.5C2386.36 1074 2385.49 1074.17 2385.1 1073.82Z" fill="white"/>
                    <path d="M2528.1 994.89C2528.23 995.12 2528.29 995.42 2528 995.52C2527.31 995.76 2526.69 996.17 2525.91 996.16C2525.58 996.16 2525.36 995.97 2525.3 995.79C2525.24 995.61 2525.39 995.21 2525.55 995.16C2526.15 994.93 2526.79 994.79 2527.42 994.63C2527.69 994.56 2527.92 994.67 2528.1 994.89Z" fill="white"/>
                    <path d="M2537.16 968.26C2536.99 968.47 2536.78 968.75 2536.39 968.51C2536.05 968.3 2536.14 967.95 2536.07 967.63C2535.94 966.99 2536.43 966.71 2536.79 966.42C2537 966.25 2537.64 966.23 2537.73 966.37C2537.91 966.66 2537.95 967.12 2537.86 967.46C2537.78 967.75 2537.43 967.96 2537.16 968.25V968.26Z" fill="white"/>
                    <path d="M2617.67 1085.01C2618.03 1085.25 2618.12 1085.56 2617.71 1085.95C2617.19 1086.45 2616.35 1086.61 2615.97 1086.27C2615.33 1085.69 2616.49 1085.31 2616.15 1084.71C2616.09 1084.61 2617.16 1084.59 2617.67 1085.01Z" fill="white"/>
                    <path d="M2463.96 1038.95C2463.81 1038.97 2463.61 1039.07 2463.51 1039.01C2462.92 1038.68 2462.7 1037.2 2463.18 1036.76C2463.34 1036.61 2463.63 1036.44 2463.79 1036.49C2463.96 1036.54 2464.14 1036.84 2464.17 1037.05C2464.34 1037.99 2464.25 1038.63 2463.96 1038.95Z" fill="white"/>
                    <path d="M2430.29 1129.57C2430.48 1129.74 2430.7 1129.89 2430.84 1130.1C2431.21 1130.63 2430.64 1130.81 2430.42 1131.11C2430.13 1131.5 2429.85 1131.3 2429.6 1131.06C2429.37 1130.83 2429.08 1130.66 2429.1 1130.25C2429.12 1129.84 2428.99 1129.36 2429.46 1129.15C2429.83 1128.99 2430.05 1129.37 2430.29 1129.58V1129.57Z" fill="white"/>
                    <path d="M2527.4 997.7C2527.84 997.39 2528.07 997.67 2528.28 997.91C2528.79 998.5 2528.86 999.31 2528.42 999.65C2528.06 999.93 2527.81 999.65 2527.6 999.4C2527.09 998.81 2527.03 998.01 2527.4 997.7Z" fill="white"/>
                    <path d="M2622.93 1079.96C2622.93 1080.56 2623.07 1081.12 2622.52 1081.59C2622.06 1081.98 2621.84 1081.79 2621.49 1081.49C2621.12 1081.16 2621.03 1080.93 2621.36 1080.47C2621.8 1079.86 2622.38 1080.06 2622.93 1079.95V1079.96Z" fill="white"/>
                    <path d="M2616.79 1091.49C2616.64 1091.53 2616.43 1091.65 2616.34 1091.59C2616.07 1091.4 2615.69 1091.18 2615.63 1090.91C2615.52 1090.47 2615.34 1089.92 2615.8 1089.52C2616.16 1089.22 2616.41 1089.5 2616.62 1089.75C2617.13 1090.35 2617.2 1091.11 2616.79 1091.49Z" fill="white"/>
                    <path d="M2398.71 1114.08C2398.46 1114.33 2398.19 1114.6 2397.9 1114.21C2397.52 1113.71 2397.44 1113.1 2397.93 1112.68C2398.19 1112.46 2398.71 1112.55 2399.11 1112.5C2399.11 1113.06 2399.32 1113.67 2398.72 1114.07L2398.71 1114.08Z" fill="white"/>
                    <path d="M2520.61 1176.34C2520.19 1175.89 2520.2 1174.93 2520.55 1174.66C2520.91 1174.38 2521.12 1174.69 2521.37 1174.89C2521.87 1175.31 2521.95 1175.86 2521.63 1176.33C2521.54 1176.47 2520.85 1176.56 2520.61 1176.33V1176.34Z" fill="white"/>
                    <path d="M2554.57 1036.35C2554.27 1036.67 2553.95 1036.98 2553.71 1036.58C2553.42 1036.09 2553.32 1035.48 2553.14 1034.93C2553.73 1034.87 2554.26 1034.86 2554.72 1035.4C2555.15 1035.91 2554.73 1036.12 2554.57 1036.35Z" fill="white"/>
                    <path d="M2584.53 1162.49C2584.1 1163.06 2583.82 1162.86 2583.44 1162.55C2582.84 1162.07 2583.09 1161.76 2583.48 1161.33C2583.88 1160.9 2584.13 1161.05 2584.51 1161.37C2585.07 1161.84 2584.97 1162.2 2584.53 1162.49Z" fill="white"/>
                    <path d="M2551.85 1025.63C2551.58 1025.26 2550.81 1025.01 2551.49 1024.46C2551.86 1024.16 2552.43 1024.12 2552.86 1024.45C2553.58 1024.99 2552.74 1025.52 2552.65 1025.96C2552.53 1026.51 2552.16 1025.69 2551.85 1025.64V1025.63Z" fill="white"/>
                    <path d="M2534.68 951.14C2534.51 951.32 2534.36 951.54 2534.16 951.69C2533.79 951.96 2533.56 951.67 2533.34 951.43C2533.18 951.26 2532.88 951.15 2532.96 950.84C2533.05 950.48 2533.29 950.3 2533.67 950.32C2533.84 950.32 2534.01 950.31 2534.17 950.31C2534.49 950.31 2535 950.2 2535.1 950.35C2535.31 950.68 2534.87 950.9 2534.68 951.15V951.14Z" fill="white"/>
                    <path d="M2472.03 1184.95C2472.34 1185.49 2471.82 1185.68 2471.58 1185.96C2471.13 1186.48 2470.87 1185.96 2470.58 1185.72C2470.31 1185.5 2470.08 1185.23 2470.45 1184.91C2470.83 1184.58 2471.75 1184.61 2472.03 1184.95Z" fill="white"/>
                    <path d="M2569.18 1088.77C2568.86 1088.45 2568.61 1088.2 2568.98 1087.87C2569.36 1087.54 2570.29 1087.56 2570.55 1087.92C2570.82 1088.29 2570.48 1088.49 2570.29 1088.76C2569.84 1089.41 2569.47 1089.11 2569.17 1088.77H2569.18Z" fill="white"/>
                    <path d="M2547.58 991.02C2547.67 991.17 2547.93 991.42 2547.89 991.56C2547.76 991.96 2547.32 991.78 2547.09 991.73C2546.74 991.65 2546.03 992.28 2546.06 991.29C2546.06 990.96 2545.99 990.61 2546.32 990.4C2547 989.99 2547.09 990.83 2547.58 991.01V991.02Z" fill="white"/>
                    <path d="M2418.73 1026.47C2418.33 1026.55 2417.68 1026.91 2417.59 1026.39C2417.51 1025.95 2416.85 1025.66 2417.23 1025.18C2417.46 1024.89 2418.73 1025.38 2418.94 1025.82C2419.05 1026.05 2418.97 1026.29 2418.73 1026.47Z" fill="white"/>
                    <path d="M2571.59 1152.88C2571.59 1152.88 2571.51 1153.34 2571.37 1153.73C2571.31 1153.9 2570.14 1153.73 2570 1153.52C2569.64 1152.99 2570.19 1152.81 2570.4 1152.52C2570.66 1152.16 2570.95 1152.27 2571.58 1152.88H2571.59Z" fill="white"/>
                    <path d="M2547.83 1172.1C2547.3 1172.42 2547.12 1171.89 2546.82 1171.67C2546.44 1171.39 2546.57 1171.02 2546.87 1170.88C2547.22 1170.72 2547.66 1170.72 2548.06 1170.73C2548.2 1170.73 2548.01 1171.97 2547.82 1172.11L2547.83 1172.1Z" fill="white"/>
                    <path d="M2390.89 1154.56C2390.5 1154.32 2390.51 1154.04 2390.68 1153.86C2391.14 1153.38 2391.68 1153.41 2392.23 1153.72C2392.51 1153.88 2392.49 1154.16 2392.33 1154.35C2391.87 1154.86 2391.33 1154.77 2390.89 1154.56Z" fill="white"/>
                    <path d="M2458.98 1019.86C2459.32 1020.29 2459.38 1020.83 2459.08 1021.15C2458.93 1021.31 2458.3 1021.4 2457.94 1021.04C2457.45 1020.56 2457.95 1020.33 2458.18 1020.04C2458.4 1019.77 2458.65 1019.57 2458.98 1019.86Z" fill="white"/>
                    <path d="M2463.32 1199.56C2463.12 1199.42 2462.87 1198.23 2463.02 1198.22C2463.42 1198.19 2463.86 1198.16 2464.22 1198.3C2464.55 1198.43 2464.64 1198.8 2464.3 1199.09C2464.02 1199.33 2463.88 1199.88 2463.32 1199.56Z" fill="white"/>
                    <path d="M2461.32 1015.11C2460.9 1015.34 2460.36 1015.48 2459.87 1015C2459.69 1014.82 2459.65 1014.55 2459.92 1014.37C2460.44 1014.02 2460.99 1013.96 2461.48 1014.41C2461.66 1014.58 2461.69 1014.85 2461.32 1015.12V1015.11Z" fill="white"/>
                    <path d="M2428.6 967.25C2428.3 967.62 2427.93 967.51 2427.79 967.21C2427.63 966.86 2427.63 966.42 2427.64 966.02C2427.64 965.88 2428.88 966.07 2429.02 966.26C2429.21 966.53 2429.05 966.91 2428.6 967.26V967.25Z" fill="white"/>
                    <path d="M2550.23 1029.58C2549.98 1029.84 2549.73 1030.1 2549.4 1029.74C2549.04 1029.35 2549 1028.8 2549.31 1028.48C2549.46 1028.32 2550.09 1028.23 2550.45 1028.59C2550.94 1029.07 2550.42 1029.29 2550.23 1029.58Z" fill="white"/>
                    <path d="M2507.53 959.55C2507.07 959.71 2506.63 959.81 2506.21 959.5C2505.96 959.31 2505.95 959.04 2506.14 958.88C2506.46 958.62 2506.62 957.86 2507.28 958.38C2507.69 958.7 2507.4 959.16 2507.53 959.56V959.55Z" fill="white"/>
                    <path d="M2538.28 1030.19C2538.13 1030.22 2537.98 1030.25 2537.82 1030.27C2537.78 1029.53 2537.86 1028.76 2537.4 1028.09C2537.38 1028.06 2537.6 1027.81 2537.74 1027.74C2537.82 1027.7 2538.09 1027.82 2538.09 1027.86C2538.04 1028.55 2538.76 1029.06 2538.53 1029.77C2538.48 1029.92 2538.36 1030.05 2538.27 1030.19H2538.28Z" fill="white"/>
                    <path d="M2534.8 1028.15C2534.45 1027.78 2535.1 1027.49 2535.4 1027.23C2535.99 1026.71 2536.2 1027.47 2536.55 1027.71C2536.65 1027.78 2536.8 1028.3 2536.4 1028.11C2535.8 1027.83 2535.62 1029.11 2534.8 1028.15Z" fill="white"/>
                    <path d="M2431.73 1151.54C2432.25 1151.89 2431.97 1152.44 2432.07 1152.9C2431.75 1152.92 2431.29 1153.08 2431.14 1152.93C2430.8 1152.6 2430.84 1152.11 2431.11 1151.68C2431.27 1151.42 2431.5 1151.45 2431.74 1151.54H2431.73Z" fill="white"/>
                    <path d="M2571.23 1146.85C2571 1146.56 2570.48 1146.37 2570.94 1145.85C2571.27 1145.48 2571.53 1145.68 2571.77 1145.93C2572.03 1146.2 2572.54 1146.41 2572.08 1146.93C2571.75 1147.31 2571.5 1147.08 2571.23 1146.85Z" fill="white"/>
                    <path d="M2382.21 1103.48C2381.38 1103.68 2380.89 1103.43 2380.51 1102.94C2380.42 1102.82 2380.43 1102.64 2380.39 1102.49C2380.56 1102.49 2380.73 1102.51 2380.89 1102.47C2381.49 1102.3 2381.81 1102.73 2382.14 1103.08C2382.24 1103.19 2382.2 1103.43 2382.2 1103.48H2382.21Z" fill="white"/>
                    <path d="M2587.25 1168C2587.17 1168.1 2586.89 1167.93 2586.65 1167.66C2586.4 1167.38 2585.87 1167.19 2586.33 1166.66C2586.65 1166.29 2586.91 1166.47 2587.16 1166.72C2587.42 1166.99 2587.93 1167.21 2587.25 1167.99V1168Z" fill="white"/>
                    <path d="M2569.48 1115.95C2569.45 1115.84 2569.34 1115.66 2569.39 1115.59C2569.74 1115.13 2570.11 1114.69 2570.81 1114.79C2571.1 1114.83 2571.26 1114.89 2571.17 1115.17C2571.02 1115.61 2569.85 1116.13 2569.48 1115.94V1115.95Z" fill="white"/>
                    <path d="M2475.1 1151.01C2474.4 1151.45 2474.29 1150.64 2473.92 1150.4C2473.75 1150.29 2473.91 1149.91 2474.14 1149.78C2474.81 1149.38 2474.98 1150.11 2475.33 1150.38C2475.49 1150.51 2475.35 1150.85 2475.11 1151.01H2475.1Z" fill="white"/>
                    <path d="M2533.34 1068.93C2532.64 1069.37 2532.53 1068.56 2532.16 1068.32C2531.99 1068.21 2532.15 1067.83 2532.38 1067.7C2533.05 1067.3 2533.22 1068.03 2533.57 1068.3C2533.73 1068.43 2533.59 1068.77 2533.35 1068.93H2533.34Z" fill="white"/>
                    <path d="M2418.97 1049.08C2418.89 1049.18 2418.61 1049.01 2418.37 1048.74C2418.12 1048.46 2417.59 1048.27 2418.05 1047.74C2418.37 1047.37 2418.63 1047.55 2418.88 1047.8C2419.14 1048.07 2419.65 1048.29 2418.97 1049.07V1049.08Z" fill="white"/>
                    <path d="M2586.96 1026.55C2587.1 1026.03 2587.23 1025.49 2587.39 1024.95C2587.39 1024.93 2587.57 1024.91 2587.63 1024.95C2587.94 1025.14 2588.05 1025.41 2588.04 1025.78C2588.04 1026.2 2588.09 1026.62 2587.58 1026.77C2587.46 1026.8 2587.29 1026.67 2586.97 1026.55H2586.96Z" fill="white"/>
                    <path d="M2542.96 1008.08C2543.23 1008.3 2543.53 1008.51 2543.25 1008.9C2542.89 1009.39 2542.57 1009.18 2542.24 1008.83C2542.01 1008.59 2541.68 1008.39 2541.96 1008.01C2542.32 1007.52 2542.66 1007.71 2542.96 1008.08Z" fill="white"/>
                    <path d="M2491.13 999.33C2490.71 999.57 2490.48 999.64 2490.3 999.38C2489.95 998.86 2489.9 998.32 2490.34 997.82C2490.43 997.72 2490.64 997.74 2490.79 997.7C2490.91 998.27 2491.03 998.83 2491.13 999.33Z" fill="white"/>
                    <path d="M2559.61 1076.55C2559.34 1076.93 2559.03 1077.12 2558.88 1076.96C2558.59 1076.64 2557.85 1076.51 2558.2 1075.82C2558.32 1075.58 2558.64 1075.41 2558.82 1075.56C2559.15 1075.85 2559.73 1076.06 2559.61 1076.55Z" fill="white"/>
                    <path d="M2413.61 1065.43C2413.36 1065.68 2413.13 1065.92 2412.77 1065.57C2412.28 1065.08 2412.77 1064.84 2413.01 1064.55C2413.23 1064.28 2413.48 1064.06 2413.83 1064.41C2414.33 1064.9 2413.81 1065.12 2413.6 1065.43H2413.61Z" fill="white"/>
                    <path d="M2470.89 1119.19C2471.33 1119.89 2470.52 1120 2470.28 1120.37C2470.17 1120.54 2469.79 1120.38 2469.66 1120.15C2469.26 1119.48 2469.99 1119.31 2470.26 1118.96C2470.39 1118.8 2470.73 1118.94 2470.89 1119.18V1119.19Z" fill="white"/>
                    <path d="M2496.09 991.28C2496.36 991.5 2496.66 991.71 2496.38 992.1C2496.02 992.59 2495.7 992.38 2495.37 992.03C2495.14 991.79 2494.81 991.59 2495.09 991.21C2495.45 990.72 2495.79 990.91 2496.09 991.28Z" fill="white"/>
                    <path d="M2609.7 1080.46C2610.17 1080.82 2610.16 1081.05 2609.99 1081.27C2609.62 1081.76 2609.29 1081.52 2608.99 1081.18C2608.83 1081 2608.45 1080.72 2608.63 1080.63C2609.01 1080.44 2609.49 1080.49 2609.7 1080.46Z" fill="white"/>
                    <path d="M2402.09 1096.04C2401.96 1096.09 2401.74 1096.18 2401.53 1096.26C2401.53 1095.79 2401.27 1095.27 2401.73 1094.89C2401.93 1094.72 2402.15 1094.71 2402.38 1094.94C2402.86 1095.42 2402.38 1095.65 2402.09 1096.05V1096.04Z" fill="white"/>
                    <path d="M2508.48 941.02C2508.28 940.9 2508.29 940.64 2508.44 940.44C2508.64 940.16 2508.93 939.92 2509.24 940.3C2509.46 940.57 2510.11 940.73 2509.71 941.17C2509.57 941.33 2508.92 941.41 2508.49 941.02H2508.48Z" fill="white"/>
                    <path d="M2628.7 1046.81C2628.34 1047.3 2627.8 1047.04 2627.33 1047.11C2627.42 1046.89 2627.45 1046.63 2627.6 1046.47C2627.85 1046.2 2628.06 1045.7 2628.58 1046.15C2628.82 1046.35 2628.81 1046.55 2628.69 1046.81H2628.7Z" fill="white"/>
                    <path d="M2490.78 964.24C2490.47 964.06 2490.74 964.32 2490.78 963.99C2490.81 963.81 2491.06 963.54 2491.23 963.53C2491.69 963.5 2492.23 963.28 2492.57 963.82C2492.61 963.88 2492.62 964.03 2492.58 964.05C2492 964.4 2491.36 964.13 2490.76 964.23L2490.78 964.24Z" fill="white"/>
                    <path d="M2487.13 1012.52C2486.65 1012.5 2486.09 1012.74 2485.79 1012.18C2485.72 1012.05 2485.88 1011.68 2486.04 1011.56C2486.54 1011.19 2486.73 1011.76 2487.04 1011.97C2487.25 1012.11 2487.35 1012.3 2487.13 1012.52Z" fill="white"/>
                    <path d="M2504.33 1093.88C2504.21 1094 2504.12 1094.17 2503.98 1094.24C2503.89 1094.29 2503.64 1094.24 2503.61 1094.17C2503.49 1093.91 2504.57 1092.73 2504.87 1092.79C2504.94 1092.8 2505.05 1093.07 2505.01 1093.13C2504.81 1093.4 2504.56 1093.64 2504.33 1093.88Z" fill="white"/>
                    <path d="M2404.19 994.87C2403.85 995.28 2403.56 995.13 2403.33 994.85C2403.16 994.65 2403.15 994.43 2403.37 994.2C2403.7 993.85 2403.97 993.99 2404.2 994.27C2404.36 994.46 2404.45 994.72 2404.19 994.87Z" fill="white"/>
                    <path d="M2623.56 1062.95C2623.32 1063.09 2623.13 1063.13 2622.89 1062.91C2622.54 1062.58 2622.69 1062.32 2622.96 1062.08C2623.16 1061.91 2623.38 1061.9 2623.61 1062.12C2623.96 1062.45 2623.79 1062.69 2623.56 1062.95Z" fill="white"/>
                    <path d="M2474.69 1181.89C2474.45 1182.03 2474.26 1182.07 2474.02 1181.85C2473.67 1181.52 2473.82 1181.26 2474.09 1181.02C2474.29 1180.85 2474.51 1180.84 2474.74 1181.06C2475.09 1181.39 2474.92 1181.63 2474.69 1181.89Z" fill="white"/>
                    <path d="M2396.01 1122.42C2395.78 1122.2 2395.59 1121.97 2395.92 1121.61C2396.14 1121.37 2396.35 1121.37 2396.56 1121.52C2396.85 1121.73 2397.01 1121.99 2396.68 1122.34C2396.46 1122.57 2396.27 1122.56 2396 1122.42H2396.01Z" fill="white"/>
                    <path d="M2592.08 1149.37C2592.26 1149.81 2592.34 1150.25 2592.04 1150.66C2591.96 1150.77 2591.66 1150.87 2591.65 1150.85C2591.41 1150.43 2591.35 1149.99 2591.65 1149.58C2591.73 1149.47 2591.92 1149.44 2592.08 1149.37Z" fill="white"/>
                    <path d="M2508.05 1188.79C2507.89 1189.05 2507.63 1189.03 2507.43 1188.87C2507.14 1188.66 2506.98 1188.4 2507.31 1188.05C2507.53 1187.81 2507.74 1187.81 2507.95 1187.96C2508.24 1188.17 2508.41 1188.45 2508.05 1188.79Z" fill="white"/>
                    <path d="M2560.5 1099.99C2560.26 1100.13 2560.07 1100.17 2559.83 1099.95C2559.48 1099.62 2559.63 1099.36 2559.9 1099.12C2560.1 1098.95 2560.32 1098.94 2560.55 1099.16C2560.9 1099.49 2560.73 1099.73 2560.5 1099.99Z" fill="white"/>
                    <path d="M2450.37 960.71C2450.09 960.88 2449.9 960.91 2449.66 960.69C2449.31 960.36 2449.46 960.1 2449.73 959.86C2449.93 959.69 2450.14 959.69 2450.38 959.9C2450.74 960.23 2450.53 960.47 2450.38 960.7L2450.37 960.71Z" fill="white"/>
                    <path d="M2468.44 971.83C2468.06 971.52 2468.21 971.23 2468.49 971C2468.69 970.83 2468.91 970.82 2469.14 971.04C2469.49 971.37 2469.34 971.63 2469.07 971.87C2468.87 972.04 2468.62 972.08 2468.44 971.83Z" fill="white"/>
                    <path d="M2590.18 1185.36C2590.42 1185.12 2590.66 1184.88 2590.98 1185.24C2591.08 1185.35 2591.12 1185.51 2591.19 1185.65C2590.82 1185.71 2590.46 1185.77 2590.09 1185.83C2590.12 1185.68 2590.14 1185.52 2590.17 1185.36H2590.18Z" fill="white"/>
                    <path d="M2546.78 1167.91C2546.54 1168.15 2546.3 1168.39 2545.98 1168.03C2545.88 1167.92 2545.84 1167.76 2545.77 1167.62C2546.14 1167.56 2546.5 1167.5 2546.87 1167.44C2546.84 1167.59 2546.82 1167.75 2546.79 1167.91H2546.78Z" fill="white"/>
                    <path d="M2603.87 1062.59C2603.52 1062.61 2603.19 1062.63 2602.86 1062.65C2602.94 1062.44 2602.97 1062.16 2603.12 1062.02C2603.45 1061.71 2603.72 1061.99 2603.92 1062.25C2603.97 1062.32 2603.89 1062.5 2603.87 1062.6V1062.59Z" fill="white"/>
                    <path d="M2465.52 1114.18C2465.43 1114 2465.27 1113.78 2465.31 1113.74C2465.61 1113.4 2466.03 1113.64 2466.39 1113.56C2466.36 1113.71 2466.39 1113.9 2466.3 1114.01C2466.09 1114.3 2465.82 1114.45 2465.52 1114.18Z" fill="white"/>
                    <path d="M2395.05 1155.92C2394.92 1155.95 2394.75 1156.04 2394.67 1155.99C2394.4 1155.81 2394.11 1155.56 2394.4 1155.21C2394.53 1155.05 2394.81 1155.01 2395.01 1154.91C2395.02 1155.24 2395.03 1155.57 2395.04 1155.92H2395.05Z" fill="white"/>
                    <path d="M2420.47 1151.68C2420.83 1151.99 2420.64 1152.26 2420.38 1152.48C2420.28 1152.57 2420.08 1152.56 2419.93 1152.6C2419.97 1152.23 2420 1151.86 2420.04 1151.49C2420.18 1151.55 2420.32 1151.61 2420.47 1151.67V1151.68Z" fill="white"/>
                    <path d="M2584.33 1070.54C2584.26 1070.56 2584.09 1070.66 2584.02 1070.62C2583.75 1070.44 2583.45 1070.2 2583.73 1069.84C2583.86 1069.68 2584.13 1069.63 2584.34 1069.53C2584.34 1069.86 2584.34 1070.19 2584.34 1070.54H2584.33Z" fill="white"/>
                    <path d="M2560.36 1094.05C2560.05 1094.41 2559.78 1094.22 2559.56 1093.96C2559.47 1093.86 2559.48 1093.66 2559.44 1093.51C2559.81 1093.55 2560.18 1093.58 2560.55 1093.62C2560.49 1093.76 2560.43 1093.9 2560.37 1094.05H2560.36Z" fill="white"/>
                    <path d="M2444.36 1186.95C2444.2 1186.99 2444.06 1187.04 2443.91 1187.08C2443.93 1186.71 2443.95 1186.34 2443.96 1185.98C2444.1 1186.03 2444.27 1186.06 2444.39 1186.15C2444.76 1186.42 2444.6 1186.7 2444.36 1186.96V1186.95Z" fill="white"/>
                    <path d="M2461.97 987.11C2462.33 987.42 2462.14 987.69 2461.88 987.91C2461.78 988 2461.58 987.99 2461.43 988.03C2461.47 987.66 2461.5 987.29 2461.54 986.92C2461.68 986.98 2461.82 987.04 2461.97 987.1V987.11Z" fill="white"/>
                    <path d="M2434.56 964.28C2434.51 964.49 2434.53 964.68 2434.44 964.79C2434.22 965.08 2433.94 965.23 2433.64 964.88C2433.54 964.77 2433.51 964.6 2433.44 964.46C2433.8 964.4 2434.17 964.34 2434.55 964.27L2434.56 964.28Z" fill="white"/>
                    <path d="M2620.54 1012.07C2620.21 1012.17 2620.08 1011.9 2619.92 1011.73C2619.87 1011.68 2619.93 1011.4 2620 1011.37C2620.3 1011.24 2620.44 1011.53 2620.6 1011.7C2620.65 1011.76 2620.56 1011.95 2620.54 1012.06V1012.07Z" fill="white"/>
                    <path d="M2615.96 1034.26C2615.83 1034.52 2615.76 1034.75 2615.61 1034.92C2615.52 1035.02 2615.32 1035 2615.16 1035.04C2615.24 1034.82 2615.29 1034.58 2615.42 1034.4C2615.49 1034.3 2615.71 1034.32 2615.95 1034.26H2615.96Z" fill="white"/>
                    <path d="M2387.51 1043.8C2387.18 1043.9 2387.05 1043.63 2386.89 1043.46C2386.84 1043.41 2386.9 1043.13 2386.97 1043.1C2387.27 1042.97 2387.41 1043.26 2387.57 1043.43C2387.62 1043.49 2387.53 1043.68 2387.51 1043.79V1043.8Z" fill="white"/>
                    <path d="M2398.94 1139.79C2398.68 1139.66 2398.45 1139.59 2398.28 1139.44C2398.18 1139.35 2398.2 1139.15 2398.16 1138.99C2398.38 1139.07 2398.62 1139.12 2398.8 1139.25C2398.9 1139.32 2398.88 1139.54 2398.94 1139.78V1139.79Z" fill="white"/>
                    <path d="M2401.2 1141.44C2401.11 1141.34 2400.92 1141.19 2400.81 1141C2400.68 1140.77 2400.81 1140.64 2401.04 1140.76C2401.23 1140.86 2401.42 1141.04 2401.54 1141.22C2401.68 1141.44 2401.56 1141.55 2401.21 1141.44H2401.2Z" fill="white"/>
                    <path d="M2372.92 1049.63C2372.98 1049.77 2373.14 1049.97 2373.09 1050.04C2372.92 1050.3 2372.7 1050.19 2372.55 1049.99C2372.46 1049.87 2372.32 1049.65 2372.37 1049.58C2372.54 1049.32 2372.74 1049.44 2372.91 1049.62L2372.92 1049.63Z" fill="white"/>
                    <path d="M2617.78 1063.81C2617.64 1063.87 2617.44 1064.03 2617.37 1063.98C2617.11 1063.81 2617.22 1063.59 2617.42 1063.44C2617.54 1063.35 2617.76 1063.21 2617.83 1063.26C2618.09 1063.43 2617.97 1063.63 2617.79 1063.8L2617.78 1063.81Z" fill="white"/>
                    <path d="M2453.03 1183.44C2452.9 1183.7 2452.83 1183.93 2452.68 1184.1C2452.59 1184.2 2452.39 1184.18 2452.23 1184.22C2452.31 1184 2452.36 1183.76 2452.49 1183.58C2452.56 1183.48 2452.78 1183.5 2453.02 1183.44H2453.03Z" fill="white"/>
                    <path d="M2518.8 965.8C2518.69 965.83 2518.54 965.86 2518.38 965.9C2518.46 965.68 2518.51 965.44 2518.64 965.26C2518.71 965.16 2518.93 965.18 2519.08 965.14C2519.12 965.4 2519.05 965.62 2518.79 965.8H2518.8Z" fill="white"/>
                    <path d="M2483.4 1209.09C2483.2 1208.93 2483.07 1208.74 2483.32 1208.55C2483.39 1208.5 2483.61 1208.62 2483.74 1208.7C2483.95 1208.84 2484.07 1209.05 2483.82 1209.23C2483.75 1209.28 2483.54 1209.14 2483.4 1209.08V1209.09Z" fill="white"/>
                    <path d="M2494.06 990.8C2493.86 990.64 2493.73 990.45 2493.98 990.26C2494.05 990.21 2494.27 990.33 2494.4 990.41C2494.61 990.55 2494.73 990.76 2494.48 990.94C2494.41 990.99 2494.2 990.85 2494.06 990.79V990.8Z" fill="white"/>
                    <path d="M2494.43 1001.81C2494.23 1001.65 2494.1 1001.46 2494.35 1001.27C2494.42 1001.22 2494.64 1001.34 2494.77 1001.42C2494.98 1001.56 2495.1 1001.77 2494.85 1001.95C2494.78 1002 2494.57 1001.86 2494.43 1001.8V1001.81Z" fill="white"/>
                    <path d="M2501.55 1041.79C2501.42 1042.05 2501.35 1042.28 2501.2 1042.45C2501.11 1042.55 2500.91 1042.53 2500.75 1042.57C2500.83 1042.35 2500.88 1042.11 2501.01 1041.93C2501.08 1041.83 2501.3 1041.85 2501.54 1041.79H2501.55Z" fill="white"/>
                    <path d="M2592.62 1067.21C2592.44 1067.28 2592.22 1067.35 2592 1067.43C2592.03 1067.27 2592 1067.06 2592.09 1066.98C2592.26 1066.83 2592.5 1066.77 2592.71 1066.67C2592.68 1066.83 2592.66 1066.98 2592.62 1067.21Z" fill="white"/>
                    <path d="M2522.2 1110.48C2522.09 1110.51 2521.94 1110.54 2521.78 1110.58C2521.86 1110.36 2521.91 1110.12 2522.04 1109.94C2522.11 1109.84 2522.33 1109.86 2522.48 1109.82C2522.52 1110.08 2522.45 1110.3 2522.19 1110.48H2522.2Z" fill="white"/>
                    <path d="M2546.83 1142.43C2546.7 1142.69 2546.63 1142.92 2546.48 1143.09C2546.39 1143.19 2546.19 1143.17 2546.03 1143.21C2546.11 1142.99 2546.16 1142.75 2546.29 1142.57C2546.36 1142.47 2546.58 1142.49 2546.82 1142.43H2546.83Z" fill="white"/>
                    <path d="M2585.81 1192.21C2585.48 1192.31 2585.35 1192.04 2585.19 1191.87C2585.14 1191.82 2585.2 1191.54 2585.27 1191.51C2585.57 1191.38 2585.71 1191.67 2585.87 1191.84C2585.92 1191.9 2585.83 1192.09 2585.81 1192.2V1192.21Z" fill="white"/>
                    <path d="M2531.53 1175.86C2531.33 1175.7 2531.2 1175.51 2531.45 1175.32C2531.52 1175.27 2531.74 1175.39 2531.87 1175.47C2532.08 1175.61 2532.2 1175.82 2531.95 1176C2531.88 1176.05 2531.67 1175.91 2531.53 1175.85V1175.86Z" fill="white"/>
                    <path d="M2403.66 1045.66C2403.72 1045.8 2403.88 1046 2403.83 1046.07C2403.66 1046.33 2403.44 1046.22 2403.29 1046.02C2403.2 1045.9 2403.06 1045.68 2403.11 1045.61C2403.28 1045.35 2403.48 1045.47 2403.65 1045.65L2403.66 1045.66Z" fill="white"/>
                    <path d="M2473.96 1092.91C2473.82 1092.97 2473.62 1093.13 2473.55 1093.08C2473.29 1092.91 2473.4 1092.69 2473.6 1092.54C2473.72 1092.45 2473.94 1092.31 2474.01 1092.36C2474.27 1092.53 2474.15 1092.73 2473.97 1092.9L2473.96 1092.91Z" fill="white"/>
                    <path d="M2465.31 1103.3C2465.27 1103.42 2465.25 1103.56 2465.19 1103.67C2465.17 1103.71 2465.04 1103.68 2464.95 1103.68C2464.99 1103.56 2465.01 1103.42 2465.07 1103.31C2465.09 1103.27 2465.22 1103.3 2465.31 1103.3Z" fill="white"/>
                    <path d="M2440.48 1195.28C2440.44 1195.4 2440.42 1195.54 2440.36 1195.65C2440.34 1195.69 2440.21 1195.66 2440.12 1195.66C2440.16 1195.54 2440.18 1195.4 2440.24 1195.29C2440.26 1195.25 2440.39 1195.28 2440.48 1195.28Z" fill="white"/>
                    <path d="M2500.06 1010.88C2499.94 1010.92 2499.82 1010.96 2499.7 1011C2499.7 1010.92 2499.66 1010.79 2499.7 1010.76C2499.8 1010.69 2499.94 1010.66 2500.06 1010.62C2500.06 1010.7 2500.06 1010.78 2500.06 1010.88Z" fill="white"/>
                    <path d="M2479.87 975.65C2479.97 975.65 2480.05 975.65 2480.13 975.65C2480.09 975.77 2480.07 975.91 2480.01 976.02C2479.99 976.06 2479.86 976.03 2479.77 976.03C2479.8 975.9 2479.83 975.78 2479.86 975.66L2479.87 975.65Z" fill="white"/>
                    <path d="M2535.96 1026.31C2535.92 1026.19 2535.88 1026.07 2535.84 1025.95C2535.92 1025.95 2536.05 1025.91 2536.08 1025.95C2536.15 1026.05 2536.18 1026.19 2536.22 1026.31C2536.14 1026.31 2536.06 1026.31 2535.96 1026.31Z" fill="white"/>
                    <path d="M2536.11 1066.74C2535.99 1066.7 2535.85 1066.68 2535.74 1066.62C2535.7 1066.6 2535.73 1066.47 2535.73 1066.38C2535.85 1066.42 2535.99 1066.44 2536.1 1066.5C2536.14 1066.52 2536.11 1066.65 2536.11 1066.74Z" fill="white"/>
                    <path d="M2564.02 1098.77C2564.02 1098.67 2564.02 1098.59 2564.02 1098.51C2564.14 1098.55 2564.28 1098.57 2564.39 1098.63C2564.43 1098.65 2564.4 1098.78 2564.4 1098.87C2564.27 1098.84 2564.15 1098.81 2564.03 1098.78L2564.02 1098.77Z" fill="white"/>
                    <path d="M2610.23 1074.06C2610.11 1074.1 2609.99 1074.14 2609.87 1074.18C2609.87 1074.1 2609.83 1073.97 2609.87 1073.94C2609.97 1073.87 2610.11 1073.84 2610.23 1073.8C2610.23 1073.88 2610.23 1073.96 2610.23 1074.06Z" fill="white"/>
                    <path d="M2524.66 1013.47C2524.56 1013.47 2524.48 1013.47 2524.4 1013.47C2524.44 1013.35 2524.46 1013.21 2524.52 1013.1C2524.54 1013.06 2524.67 1013.09 2524.76 1013.09C2524.73 1013.22 2524.7 1013.34 2524.67 1013.46L2524.66 1013.47Z" fill="white"/>
                    <path d="M2510.85 1077.14C2510.95 1077.14 2511.03 1077.14 2511.11 1077.14C2511.07 1077.26 2511.05 1077.4 2510.99 1077.51C2510.97 1077.55 2510.84 1077.52 2510.75 1077.52C2510.78 1077.39 2510.81 1077.27 2510.84 1077.15L2510.85 1077.14Z" fill="white"/>
                    <path d="M2595.46 1170.27C2595.46 1170.36 2595.49 1170.49 2595.46 1170.5C2595.34 1170.54 2595.19 1170.6 2595.08 1170.56C2594.85 1170.49 2594.84 1170.34 2595.07 1170.25C2595.18 1170.21 2595.33 1170.25 2595.45 1170.27H2595.46Z" fill="white"/>
                    <path d="M2594.76 1170.58C2594.63 1170.57 2594.42 1170.6 2594.39 1170.54C2594.29 1170.36 2594.37 1170.22 2594.53 1170.25C2594.67 1170.28 2594.79 1170.36 2594.92 1170.41C2594.87 1170.47 2594.81 1170.52 2594.76 1170.58Z" fill="white"/>
                    <path d="M2439.73 1096.7C2439.61 1096.74 2439.49 1096.78 2439.37 1096.82C2439.37 1096.74 2439.33 1096.61 2439.37 1096.58C2439.47 1096.51 2439.61 1096.48 2439.73 1096.44C2439.73 1096.52 2439.73 1096.6 2439.73 1096.7Z" fill="white"/>
                    <path d="M2646.53 1115.1C2646.41 1115.14 2646.29 1115.18 2646.17 1115.22C2646.17 1115.14 2646.13 1115.01 2646.17 1114.98C2646.27 1114.91 2646.41 1114.88 2646.53 1114.84C2646.53 1114.92 2646.53 1115 2646.53 1115.1Z" fill="white"/>
                    <path d="M2596.32 1177.17C2596.22 1177.17 2596.14 1177.17 2596.06 1177.17C2596.1 1177.05 2596.12 1176.91 2596.18 1176.8C2596.2 1176.76 2596.33 1176.79 2596.42 1176.79C2596.39 1176.92 2596.36 1177.04 2596.33 1177.16L2596.32 1177.17Z" fill="white"/>
                    <path d="M2624.89 1083.7C2625.01 1083.66 2625.13 1083.62 2625.25 1083.58C2625.25 1083.66 2625.29 1083.79 2625.25 1083.82C2625.15 1083.89 2625.01 1083.92 2624.89 1083.96C2624.89 1083.88 2624.89 1083.8 2624.89 1083.7Z" fill="white"/>
                    <path d="M2511.85 1142.79C2511.73 1142.83 2511.61 1142.87 2511.49 1142.91C2511.49 1142.83 2511.45 1142.7 2511.49 1142.67C2511.59 1142.6 2511.73 1142.57 2511.85 1142.53C2511.85 1142.61 2511.85 1142.69 2511.85 1142.79Z" fill="white"/>
                    <path d="M2562.56 1033.4C2562.56 1033.3 2562.56 1033.22 2562.56 1033.14C2562.68 1033.18 2562.82 1033.2 2562.93 1033.26C2562.97 1033.28 2562.94 1033.41 2562.94 1033.5C2562.81 1033.47 2562.69 1033.44 2562.57 1033.41L2562.56 1033.4Z" fill="white"/>
                    <path d="M2568.12 1165.99C2568.08 1166.11 2568.06 1166.25 2568 1166.36C2567.98 1166.4 2567.85 1166.37 2567.76 1166.37C2567.8 1166.25 2567.82 1166.11 2567.88 1166C2567.9 1165.96 2568.03 1165.99 2568.12 1165.99Z" fill="white"/>
                    <path d="M2572.31 1039.32C2572.19 1039.36 2572.07 1039.4 2571.95 1039.44C2571.95 1039.36 2571.91 1039.23 2571.95 1039.2C2572.05 1039.13 2572.19 1039.1 2572.31 1039.06V1039.32Z" fill="white"/>
                    <path d="M2483.88 1090.18C2483.92 1090.3 2483.96 1090.42 2484 1090.54C2483.92 1090.54 2483.79 1090.58 2483.76 1090.54C2483.69 1090.44 2483.66 1090.3 2483.62 1090.18C2483.7 1090.18 2483.78 1090.18 2483.88 1090.18Z" fill="white"/>
                    <path d="M2367.79 1055.5C2367.75 1055.62 2367.73 1055.76 2367.67 1055.87C2367.65 1055.91 2367.52 1055.88 2367.43 1055.88C2367.47 1055.76 2367.49 1055.62 2367.55 1055.51C2367.57 1055.47 2367.7 1055.5 2367.79 1055.5Z" fill="white"/>
                    <path d="M2490.26 1140.58C2489.93 1142.39 2488.54 1142.75 2487.23 1143.07C2486.75 1143.19 2485.98 1143.26 2485.75 1143.41C2484.31 1144.38 2484.01 1142.34 2482.96 1142.48C2482.98 1141.44 2482.65 1140.19 2483.11 1139.42C2483.55 1138.69 2484.19 1137.82 2484.99 1137.3C2485.75 1136.81 2486.6 1135.78 2487.45 1137.3C2487.58 1137.53 2488.15 1137.48 2488.5 1137.61C2490.06 1138.15 2490.63 1139.18 2490.26 1140.58Z" fill="white"/>
                    <path d="M2405.09 1018.84C2406.14 1019.13 2406.65 1020.17 2406.55 1020.54C2406.13 1021.96 2407.28 1022.88 2407.39 1024.12C2407.47 1024.99 2407.37 1025.54 2406.61 1025.91C2405.36 1026.53 2404.72 1026.48 2404.08 1025.68C2403.81 1025.33 2403.5 1025.03 2403.18 1024.73C2401.79 1023.42 2402.12 1019.55 2404.24 1018.95C2404.62 1018.84 2404.93 1018.75 2405.09 1018.84Z" fill="white"/>
                    <path d="M2397.04 1107.16C2397.49 1108 2398.88 1108.16 2398.63 1109.46C2398.6 1109.64 2398.47 1109.84 2398.5 1110C2398.64 1110.69 2399.04 1111.38 2398.44 1112.03C2397.97 1112.54 2397.28 1112.46 2396.68 1112.59C2395.49 1112.84 2394.07 1111.87 2393.67 1110.6C2393.35 1109.61 2393.6 1108.82 2394.33 1108.05C2395.09 1107.25 2396.12 1107.41 2397.04 1107.15V1107.16Z" fill="white"/>
                    <path d="M2268.29 1135.86C2266.72 1135.44 2266.15 1134.67 2266.32 1133.49C2266.41 1132.84 2266.67 1132.22 2266.8 1131.58C2266.92 1130.97 2267.23 1130.86 2267.79 1131.04C2268.41 1131.24 2269.1 1131.29 2269.68 1131.57C2270.67 1132.03 2271.18 1132.93 2271.36 1133.98C2271.4 1134.2 2271.26 1134.57 2271.09 1134.67C2270.45 1135.03 2269.99 1135.57 2269.45 1136.03C2269.19 1136.25 2268.41 1135.88 2268.29 1135.85V1135.86Z" fill="white"/>
                    <path d="M2282.16 1013.46C2282.89 1013.59 2283.93 1013.75 2283.94 1014.94C2283.94 1015.5 2283.94 1016.56 2283.24 1016.58C2282.48 1016.61 2281.64 1017.27 2280.9 1016.59C2280.38 1016.12 2280 1015.44 2279.42 1015.12C2278.88 1014.82 2278.81 1014.61 2278.94 1014.07C2279.13 1013.3 2279.68 1013.01 2280.33 1013.01C2280.87 1013.01 2281.42 1013.27 2282.16 1013.46Z" fill="white"/>
                    <path d="M2261.51 1155.08C2262.23 1155.25 2262.7 1155.98 2262.55 1156.7C2262.5 1156.94 2260.64 1158.85 2260.42 1158.88C2259.92 1158.96 2258.76 1157.69 2258.84 1157.66C2259.96 1157.28 2258.82 1156.39 2259.28 1155.92C2259.73 1155.47 2261.03 1154.97 2261.5 1155.08H2261.51Z" fill="white"/>
                    <path d="M2428.63 1159.73C2428.74 1159.35 2428.79 1158.75 2429.16 1158.84C2429.83 1159.01 2430.64 1158.89 2431.11 1159.69C2431.37 1160.13 2431.18 1160.6 2431.38 1160.97C2431.72 1161.56 2431.71 1161.92 2431.09 1162.33C2430.42 1162.77 2429.74 1162.69 2429.36 1162.2C2428.83 1161.52 2428.1 1160.75 2428.63 1159.74V1159.73Z" fill="white"/>
                    <path d="M2383.88 1072.82C2383.32 1072.63 2382.5 1072.73 2382.83 1071.66C2382.9 1071.42 2382.48 1071.04 2382.31 1070.7C2382.12 1070.32 2381.96 1069.93 2381.79 1069.54C2382.41 1069.64 2383.19 1069.54 2383.62 1069.89C2384.16 1070.33 2384.39 1071.14 2384.8 1071.77C2385.02 1072.11 2385.16 1072.47 2384.92 1072.79C2384.65 1073.14 2384.25 1072.91 2383.9 1072.83L2383.88 1072.82Z" fill="white"/>
                    <path d="M2354.95 1220.44C2354.77 1220.39 2354.59 1220.37 2354.42 1220.3C2354.04 1220.14 2353.4 1220.29 2353.42 1219.63C2353.44 1218.96 2353.78 1218.46 2354.44 1218.17C2355.01 1217.93 2355.23 1218.21 2355.52 1218.62C2355.71 1218.88 2356.11 1218.99 2356.3 1219.25C2356.55 1219.59 2356.94 1219.97 2356.67 1220.47C2356.35 1221.06 2355.88 1220.62 2355.48 1220.57C2355.3 1220.55 2355.12 1220.48 2354.95 1220.43V1220.44Z" fill="white"/>
                    <path d="M2255.17 1147.05C2254.57 1147.67 2254.13 1148.5 2253.25 1148.8C2253.05 1148.87 2252.7 1148.8 2252.56 1148.47C2252.38 1148.07 2251.88 1147.68 2252.22 1147.23C2252.64 1146.68 2253.18 1146.22 2253.72 1145.78C2254.36 1145.26 2254.25 1146.24 2254.65 1146.29C2255.06 1146.34 2255.33 1146.55 2255.16 1147.05H2255.17Z" fill="white"/>
                    <path d="M2428.9 1081.71C2428.39 1081.66 2428.13 1081.42 2428.32 1080.82C2428.61 1079.9 2429.25 1079.5 2430.09 1079.68C2430.84 1079.84 2431.13 1080.41 2431.16 1081.03C2431.19 1081.74 2430.09 1082.06 2428.9 1081.71Z" fill="white"/>
                    <path d="M2492.34 1026C2492.07 1025.93 2491.77 1025.9 2491.53 1025.78C2490.96 1025.49 2490.96 1024.7 2491.5 1024.38C2491.82 1024.19 2492.13 1023.93 2492.47 1023.83C2492.92 1023.7 2493.47 1023.81 2493.72 1024.15C2494.01 1024.54 2493.72 1025.19 2494.11 1025.62C2493.62 1025.82 2493.25 1026.39 2492.6 1026.07C2492.52 1026.03 2492.42 1026.02 2492.33 1026H2492.34Z" fill="white"/>
                    <path d="M2306.99 1153.01C2307.16 1153.3 2307.28 1153.54 2307.44 1153.77C2307.83 1154.35 2307 1154.98 2307.55 1155.58C2307.84 1155.9 2307.73 1156.31 2307.34 1156.48C2306.97 1156.65 2306.67 1156.45 2306.47 1156.08C2306.21 1155.59 2305.88 1155.13 2305.65 1154.63C2305.54 1154.39 2305.01 1154.13 2305.6 1153.81C2306.07 1153.55 2306.53 1153.27 2306.99 1153V1153.01Z" fill="white"/>
                    <path d="M2233.45 1062.99C2233.69 1062.44 2234.72 1062.14 2235.33 1062.44C2235.57 1062.56 2236.13 1064.2 2235.96 1064.49C2235.54 1065.21 2234.98 1064.66 2234.53 1064.54C2233.7 1064.31 2233.22 1063.52 2233.44 1062.99H2233.45Z" fill="white"/>
                    <path d="M2307.96 1039.15C2307.9 1039.72 2307.69 1040.03 2307.07 1039.84C2306.32 1039.61 2305.71 1038.88 2305.85 1038.34C2305.99 1037.8 2306.9 1037.45 2307.66 1037.66C2308.79 1037.97 2307.91 1038.67 2307.96 1039.15Z" fill="white"/>
                    <path d="M2312.88 1034.36C2313.24 1034.73 2313.65 1035.12 2313.54 1035.7C2313.42 1036.33 2312.18 1036.68 2311.5 1036.33C2311.05 1036.1 2311.17 1035.76 2311.3 1035.42C2311.59 1034.64 2312.34 1034.14 2312.89 1034.36H2312.88Z" fill="white"/>
                    <path d="M2300.04 1119.68C2299.69 1119.59 2299.51 1119.52 2299.33 1119.5C2298.65 1119.41 2298.61 1118.75 2298.29 1118.35C2298.77 1118.16 2299.13 1117.6 2299.76 1117.86C2299.93 1117.93 2300.11 1117.97 2300.29 1118C2300.9 1118.11 2301.26 1118.29 2301.04 1119.08C2300.83 1119.84 2300.48 1119.93 2300.04 1119.69V1119.68Z" fill="white"/>
                    <path d="M2357.47 968.34C2357.34 968.99 2356.89 969 2356.62 968.79C2356.08 968.35 2355.61 967.81 2355.16 967.28C2354.74 966.78 2355.33 966.75 2355.55 966.57C2356.52 965.79 2356.68 967.1 2357.3 967.28C2358.02 967.48 2357.42 968.05 2357.48 968.35L2357.47 968.34Z" fill="white"/>
                    <path d="M2297.68 1085.8C2298.46 1085.85 2298.76 1086.43 2298.81 1087.05C2298.85 1087.51 2297.49 1088.26 2297.21 1088C2296.67 1087.49 2296.55 1086.86 2296.81 1086.13C2296.99 1085.62 2297.32 1085.71 2297.68 1085.8Z" fill="white"/>
                    <path d="M2417.64 1208.32C2417.41 1208.82 2416.91 1209.01 2416.39 1209.11C2416.08 1209.17 2415.51 1207.73 2415.61 1207.16C2415.7 1206.64 2416.06 1206.69 2416.42 1206.79C2417.16 1207.01 2417.77 1207.75 2417.64 1208.31V1208.32Z" fill="white"/>
                    <path d="M2268.35 1112.37C2268.5 1112.13 2268.76 1111.91 2269.01 1112.13C2269.59 1112.66 2270.31 1113.05 2270.7 1113.8C2270.86 1114.11 2270.81 1114.42 2270.66 1114.57C2270.52 1114.71 2270.06 1114.78 2269.93 1114.66C2269.4 1114.2 2268.94 1113.67 2268.46 1113.15C2268.26 1112.93 2268.24 1112.66 2268.36 1112.37H2268.35Z" fill="white"/>
                    <path d="M2238.21 1117.46C2238.5 1117.51 2238.87 1117.57 2238.85 1118.06C2238.83 1118.49 2238.44 1118.59 2238.17 1118.82C2237.63 1119.28 2237.1 1118.95 2236.65 1118.76C2236.38 1118.65 2236.03 1118.05 2236.12 1117.89C2236.3 1117.57 2236.72 1117.29 2237.1 1117.2C2237.41 1117.13 2237.8 1117.35 2238.21 1117.46Z" fill="white"/>
                    <path d="M2308.26 980.21C2308.3 979.75 2308.55 979.49 2309.14 979.69C2309.88 979.93 2310.47 980.65 2310.34 981.19C2310.12 982.1 2309.15 981.19 2308.75 981.83C2308.68 981.94 2308.12 980.93 2308.25 980.22L2308.26 980.21Z" fill="white"/>
                    <path d="M2343.59 1150.94C2343.69 1151.07 2343.89 1151.21 2343.88 1151.34C2343.87 1152.08 2342.56 1153.05 2341.9 1152.82C2341.67 1152.74 2341.36 1152.56 2341.32 1152.37C2341.28 1152.18 2341.47 1151.86 2341.66 1151.71C2342.47 1151.06 2343.13 1150.82 2343.58 1150.93L2343.59 1150.94Z" fill="white"/>
                    <path d="M2447.61 1136.34C2447.68 1136.07 2447.71 1135.78 2447.83 1135.54C2448.14 1134.92 2448.61 1135.36 2449.01 1135.42C2449.53 1135.49 2449.49 1135.86 2449.38 1136.24C2449.29 1136.58 2449.26 1136.94 2448.86 1137.14C2448.46 1137.34 2448.06 1137.71 2447.62 1137.36C2447.28 1137.09 2447.52 1136.69 2447.6 1136.34H2447.61Z" fill="white"/>
                    <path d="M2271.39 1111.59C2270.87 1111.33 2271.01 1110.97 2271.14 1110.64C2271.44 1109.85 2272.18 1109.36 2272.73 1109.6C2273.18 1109.8 2273.04 1110.18 2272.92 1110.52C2272.62 1111.31 2271.89 1111.78 2271.4 1111.59H2271.39Z" fill="white"/>
                    <path d="M2300.71 977.79C2301.28 977.48 2301.75 977.06 2302.49 977.34C2303.1 977.57 2303.04 977.89 2302.93 978.37C2302.81 978.89 2302.64 979.1 2302.02 979.02C2301.21 978.91 2301.1 978.26 2300.72 977.79H2300.71Z" fill="white"/>
                    <path d="M2314.89 977.7C2315.01 977.83 2315.23 977.97 2315.22 978.08C2315.18 978.44 2315.16 978.91 2314.94 979.12C2314.57 979.45 2314.14 979.91 2313.52 979.67C2313.05 979.48 2313.19 979.1 2313.31 978.76C2313.61 977.96 2314.31 977.5 2314.89 977.7Z" fill="white"/>
                    <path d="M2449.1 1174.53C2449.47 1174.64 2449.86 1174.76 2449.65 1175.24C2449.37 1175.86 2448.83 1176.26 2448.18 1176C2447.84 1175.86 2447.66 1175.32 2447.4 1174.96C2447.94 1174.68 2448.41 1174.15 2449.1 1174.53Z" fill="white"/>
                    <path d="M2445.68 1025.84C2445.47 1026.48 2444.54 1026.96 2444.11 1026.76C2443.66 1026.56 2443.84 1026.2 2443.91 1025.85C2444.05 1025.15 2444.54 1024.8 2445.16 1024.86C2445.34 1024.88 2445.78 1025.48 2445.69 1025.84H2445.68Z" fill="white"/>
                    <path d="M2294.31 1065.66C2294.76 1065.78 2295.24 1065.93 2294.97 1066.36C2294.65 1066.89 2294.12 1067.3 2293.69 1067.76C2293.33 1067.23 2293.04 1066.73 2293.32 1066.01C2293.59 1065.33 2294 1065.63 2294.31 1065.66Z" fill="white"/>
                    <path d="M2399.44 971.89C2400.2 972 2400.16 972.37 2400.06 972.9C2399.91 973.72 2399.48 973.65 2398.87 973.49C2398.25 973.33 2398.27 973.01 2398.37 972.48C2398.53 971.7 2398.92 971.61 2399.43 971.89H2399.44Z" fill="white"/>
                    <path d="M2285.47 1073.79C2285.26 1074.24 2285.42 1075.11 2284.54 1074.74C2284.06 1074.54 2283.73 1074.02 2283.82 1073.44C2283.96 1072.48 2284.9 1073 2285.37 1072.87C2285.96 1072.7 2285.37 1073.48 2285.47 1073.8V1073.79Z" fill="white"/>
                    <path d="M2223.12 1128.67C2223.39 1128.74 2223.67 1128.77 2223.91 1128.89C2224.36 1129.1 2224.2 1129.47 2224.09 1129.81C2224.01 1130.05 2224.06 1130.39 2223.72 1130.48C2223.33 1130.58 2223.04 1130.44 2222.86 1130.06C2222.78 1129.9 2222.67 1129.75 2222.59 1129.59C2222.42 1129.28 2222.05 1128.85 2222.15 1128.69C2222.36 1128.32 2222.79 1128.63 2223.13 1128.68L2223.12 1128.67Z" fill="white"/>
                    <path d="M2478.99 1067.85C2479.34 1067.28 2479.8 1067.67 2480.19 1067.76C2480.92 1067.92 2480.55 1068.44 2480.48 1068.84C2480.41 1069.21 2480.27 1069.57 2479.77 1069.38C2479.25 1069.19 2478.81 1068.3 2479 1067.85H2478.99Z" fill="white"/>
                    <path d="M2336.88 1024.63C2336.74 1025.1 2336.63 1025.47 2336.12 1025.28C2335.6 1025.09 2335.14 1024.19 2335.35 1023.75C2335.57 1023.31 2335.93 1023.52 2336.28 1023.56C2337.13 1023.66 2337.04 1024.16 2336.87 1024.62L2336.88 1024.63Z" fill="white"/>
                    <path d="M2254.58 1095.74C2254.68 1095.57 2254.78 1095.2 2254.94 1095.17C2255.39 1095.09 2255.45 1095.6 2255.51 1095.84C2255.61 1096.21 2256.58 1096.57 2255.62 1097.05C2255.3 1097.21 2255 1097.48 2254.64 1097.26C2253.9 1096.82 2254.65 1096.3 2254.57 1095.74H2254.58Z" fill="white"/>
                    <path d="M2355.01 1200.62C2355.29 1200.96 2355.97 1201.4 2355.52 1201.75C2355.14 1202.05 2355.2 1202.84 2354.55 1202.72C2354.16 1202.65 2353.96 1201.19 2354.28 1200.75C2354.45 1200.52 2354.72 1200.48 2355.01 1200.61V1200.62Z" fill="white"/>
                    <path d="M2396.93 989.22C2396.93 989.22 2397.41 989.06 2397.85 988.99C2398.04 988.96 2398.48 990.17 2398.36 990.4C2398.04 991.02 2397.59 990.58 2397.19 990.53C2396.71 990.47 2396.67 990.14 2396.93 989.22Z" fill="white"/>
                    <path d="M2427.57 1002.01C2428.15 1002.35 2427.73 1002.8 2427.68 1003.2C2427.61 1003.7 2427.19 1003.78 2426.9 1003.56C2426.57 1003.31 2426.33 1002.89 2426.14 1002.5C2426.07 1002.36 2427.35 1001.91 2427.58 1002.02L2427.57 1002.01Z" fill="white"/>
                    <path d="M2491.84 1161.1C2491.81 1161.6 2491.54 1161.73 2491.28 1161.66C2490.58 1161.47 2490.33 1160.93 2490.34 1160.25C2490.34 1159.9 2490.62 1159.77 2490.89 1159.83C2491.62 1160 2491.81 1160.57 2491.83 1161.1H2491.84Z" fill="white"/>
                    <path d="M2327.9 1165.56C2328.13 1165.01 2328.62 1164.68 2329.08 1164.8C2329.31 1164.86 2329.72 1165.42 2329.56 1165.95C2329.35 1166.66 2328.87 1166.31 2328.48 1166.23C2328.11 1166.16 2327.79 1166.02 2327.9 1165.56Z" fill="white"/>
                    <path d="M2497.46 1068.63C2497.43 1068.89 2496.42 1069.75 2496.33 1069.61C2496.09 1069.25 2495.85 1068.84 2495.79 1068.42C2495.74 1068.04 2496.05 1067.76 2496.5 1067.94C2496.87 1068.09 2497.47 1067.94 2497.46 1068.63Z" fill="white"/>
                    <path d="M2322.16 1165.77C2322.6 1166.05 2323.01 1166.5 2322.8 1167.21C2322.72 1167.47 2322.48 1167.65 2322.17 1167.48C2321.57 1167.16 2321.22 1166.67 2321.4 1165.97C2321.47 1165.71 2321.71 1165.54 2322.16 1165.76V1165.77Z" fill="white"/>
                    <path d="M2293.3 1221.77C2293.81 1221.87 2293.9 1222.27 2293.67 1222.56C2293.42 1222.89 2293 1223.13 2292.61 1223.32C2292.47 1223.39 2292.02 1222.11 2292.13 1221.88C2292.29 1221.56 2292.74 1221.52 2293.3 1221.76V1221.77Z" fill="white"/>
                    <path d="M2290.08 1073.3C2290.46 1073.41 2290.83 1073.51 2290.66 1074.01C2290.47 1074.55 2289.97 1074.87 2289.5 1074.75C2289.27 1074.69 2288.86 1074.13 2289.01 1073.6C2289.22 1072.88 2289.7 1073.27 2290.07 1073.3H2290.08Z" fill="white"/>
                    <path d="M2245.18 1150.28C2245.57 1150.64 2245.89 1151 2245.81 1151.57C2245.76 1151.91 2245.5 1152.06 2245.25 1151.96C2244.83 1151.79 2244.03 1152.03 2244.18 1151.13C2244.27 1150.58 2244.87 1150.61 2245.18 1150.28Z" fill="white"/>
                    <path d="M2296.83 1084.41C2296.94 1084.54 2297.04 1084.67 2297.15 1084.8C2296.46 1085.22 2295.68 1085.54 2295.28 1086.33C2295.26 1086.37 2294.91 1086.28 2294.77 1086.18C2294.69 1086.12 2294.67 1085.8 2294.71 1085.78C2295.39 1085.47 2295.51 1084.52 2296.31 1084.37C2296.48 1084.34 2296.66 1084.39 2296.84 1084.4L2296.83 1084.41Z" fill="white"/>
                    <path d="M2296.68 1088.8C2296.51 1089.33 2295.89 1088.85 2295.49 1088.7C2294.69 1088.4 2295.31 1087.81 2295.35 1087.35C2295.36 1087.21 2295.78 1086.8 2295.81 1087.29C2295.85 1088.01 2297.17 1087.52 2296.68 1088.8Z" fill="white"/>
                    <path d="M2467.87 1123.61C2467.93 1122.93 2468.6 1122.91 2468.99 1122.59C2469.17 1122.89 2469.57 1123.24 2469.5 1123.47C2469.36 1123.96 2468.87 1124.18 2468.32 1124.15C2467.99 1124.13 2467.9 1123.9 2467.86 1123.62L2467.87 1123.61Z" fill="white"/>
                    <path d="M2391.36 992.67C2391.2 993.04 2391.29 993.64 2390.56 993.47C2390.03 993.35 2390.09 992.99 2390.21 992.63C2390.34 992.24 2390.27 991.65 2391.01 991.82C2391.54 991.94 2391.45 992.29 2391.37 992.67H2391.36Z" fill="white"/>
                    <path d="M2447.49 1195.78C2448.11 1196.47 2448.13 1197.07 2447.85 1197.68C2447.78 1197.82 2447.6 1197.91 2447.48 1198.03C2447.39 1197.87 2447.32 1197.69 2447.2 1197.56C2446.73 1197.07 2446.97 1196.54 2447.14 1196.05C2447.19 1195.9 2447.45 1195.81 2447.49 1195.78Z" fill="white"/>
                    <path d="M2403.29 966.44C2403.43 966.47 2403.41 966.82 2403.28 967.19C2403.15 967.57 2403.23 968.18 2402.49 968.01C2401.97 967.89 2402 967.55 2402.12 967.18C2402.25 966.79 2402.19 966.2 2403.29 966.43V966.44Z" fill="white"/>
                    <path d="M2362.72 1010.31C2362.63 1010.4 2362.51 1010.59 2362.42 1010.58C2361.8 1010.48 2361.19 1010.36 2360.92 1009.63C2360.81 1009.33 2360.79 1009.15 2361.1 1009.09C2361.59 1009 2362.7 1009.86 2362.71 1010.31H2362.72Z" fill="white"/>
                    <path d="M2444.97 1082.43C2445.75 1082.87 2445.03 1083.4 2445 1083.87C2444.99 1084.08 2444.54 1084.13 2444.29 1083.98C2443.57 1083.55 2444.17 1083 2444.25 1082.54C2444.29 1082.32 2444.69 1082.28 2444.97 1082.43Z" fill="white"/>
                    <path d="M2336.42 1069.13C2337.2 1069.57 2336.48 1070.1 2336.45 1070.57C2336.44 1070.78 2335.99 1070.83 2335.74 1070.68C2335.02 1070.25 2335.62 1069.7 2335.7 1069.24C2335.74 1069.02 2336.14 1068.98 2336.42 1069.13Z" fill="white"/>
                    <path d="M2376.49 1188.72C2376.63 1188.75 2376.61 1189.1 2376.48 1189.47C2376.35 1189.85 2376.43 1190.46 2375.69 1190.29C2375.17 1190.17 2375.2 1189.83 2375.32 1189.46C2375.45 1189.07 2375.39 1188.48 2376.49 1188.71V1188.72Z" fill="white"/>
                    <path d="M2268.22 1039.75C2267.65 1039.89 2267.06 1040.04 2266.47 1040.17C2266.44 1040.17 2266.34 1040.02 2266.34 1039.95C2266.36 1039.56 2266.57 1039.31 2266.92 1039.13C2267.32 1038.92 2267.7 1038.65 2268.1 1039.06C2268.19 1039.16 2268.16 1039.38 2268.21 1039.75H2268.22Z" fill="white"/>
                    <path d="M2273.28 1091.36C2273.35 1090.99 2273.4 1090.59 2273.91 1090.66C2274.57 1090.75 2274.53 1091.17 2274.37 1091.66C2274.26 1092 2274.24 1092.42 2273.73 1092.35C2273.08 1092.26 2273.08 1091.84 2273.28 1091.36Z" fill="white"/>
                    <path d="M2291.68 1145.42C2292.12 1145.7 2292.32 1145.88 2292.15 1146.19C2291.83 1146.79 2291.35 1147.12 2290.64 1146.96C2290.5 1146.93 2290.41 1146.72 2290.29 1146.59C2290.77 1146.19 2291.25 1145.78 2291.67 1145.42H2291.68Z" fill="white"/>
                    <path d="M2330.14 1040.08C2330.64 1040.14 2330.99 1040.34 2330.91 1040.56C2330.75 1041.01 2331.01 1041.77 2330.17 1041.8C2329.88 1041.8 2329.55 1041.59 2329.61 1041.35C2329.72 1040.88 2329.61 1040.22 2330.15 1040.09L2330.14 1040.08Z" fill="white"/>
                    <path d="M2394.9 1185.4C2395.27 1185.51 2395.62 1185.61 2395.46 1186.13C2395.25 1186.85 2394.77 1186.51 2394.36 1186.43C2393.99 1186.35 2393.65 1186.23 2393.81 1185.71C2394.02 1184.99 2394.5 1185.36 2394.9 1185.4Z" fill="white"/>
                    <path d="M2416.72 1102.88C2417.16 1102.1 2417.69 1102.82 2418.16 1102.85C2418.37 1102.86 2418.42 1103.31 2418.27 1103.56C2417.84 1104.28 2417.29 1103.68 2416.83 1103.6C2416.61 1103.56 2416.57 1103.16 2416.72 1102.88Z" fill="white"/>
                    <path d="M2281.42 1144.84C2281.49 1144.47 2281.54 1144.07 2282.05 1144.14C2282.71 1144.23 2282.67 1144.65 2282.51 1145.14C2282.4 1145.48 2282.38 1145.9 2281.87 1145.83C2281.22 1145.74 2281.22 1145.32 2281.42 1144.84Z" fill="white"/>
                    <path d="M2308.01 990.18C2308.11 989.54 2308.34 989.44 2308.64 989.48C2309.3 989.58 2309.24 990.01 2309.07 990.48C2308.98 990.72 2308.91 991.24 2308.72 991.11C2308.35 990.85 2308.14 990.36 2308.01 990.18Z" fill="white"/>
                    <path d="M2430.11 1180.62C2430.23 1180.72 2430.42 1180.88 2430.61 1181.04C2430.16 1181.28 2429.8 1181.8 2429.19 1181.56C2428.93 1181.46 2428.8 1181.25 2428.9 1180.92C2429.11 1180.21 2429.58 1180.55 2430.1 1180.62H2430.11Z" fill="white"/>
                    <path d="M2226.97 1158.94C2226.96 1159.19 2226.71 1159.32 2226.43 1159.28C2226.06 1159.23 2225.68 1159.08 2225.89 1158.59C2226.03 1158.25 2225.85 1157.54 2226.48 1157.69C2226.71 1157.74 2227.11 1158.32 2226.97 1158.94Z" fill="white"/>
                    <path d="M2266.04 989.39C2266.69 989.48 2266.72 990.13 2267.03 990.54C2266.78 990.57 2266.51 990.67 2266.28 990.61C2265.89 990.51 2265.31 990.57 2265.46 989.84C2265.53 989.51 2265.73 989.42 2266.03 989.39H2266.04Z" fill="white"/>
                    <path d="M2258.32 1163.88C2258.31 1164.27 2258.42 1163.88 2258.08 1164C2257.89 1164.07 2257.5 1163.96 2257.4 1163.81C2257.13 1163.39 2256.64 1162.98 2256.99 1162.37C2257.03 1162.3 2257.17 1162.21 2257.21 1162.24C2257.84 1162.61 2257.92 1163.36 2258.33 1163.88H2258.32Z" fill="white"/>
                    <path d="M2306.36 1142.43C2306.59 1142.9 2307.11 1143.32 2306.72 1143.89C2306.63 1144.03 2306.19 1144.07 2306 1143.97C2305.39 1143.68 2305.84 1143.2 2305.88 1142.8C2305.91 1142.53 2306.03 1142.33 2306.36 1142.43Z" fill="white"/>
                    <path d="M2375.26 1083.98C2375.44 1084.03 2375.64 1084.03 2375.79 1084.13C2375.88 1084.2 2375.96 1084.45 2375.91 1084.52C2375.73 1084.77 2374.04 1084.35 2373.94 1084.02C2373.92 1083.94 2374.12 1083.7 2374.19 1083.71C2374.55 1083.77 2374.91 1083.88 2375.26 1083.97V1083.98Z" fill="white"/>
                    <path d="M2332.31 1230.84C2332.88 1230.95 2332.88 1231.31 2332.74 1231.67C2332.64 1231.94 2332.43 1232.05 2332.1 1231.96C2331.6 1231.82 2331.59 1231.5 2331.74 1231.14C2331.84 1230.89 2332.04 1230.67 2332.32 1230.84H2332.31Z" fill="white"/>
                    <path d="M2284.12 985.96C2284.38 986.12 2284.51 986.28 2284.42 986.62C2284.28 987.12 2283.96 987.12 2283.6 986.98C2283.33 986.88 2283.22 986.67 2283.31 986.34C2283.45 985.83 2283.76 985.87 2284.13 985.96H2284.12Z" fill="white"/>
                    <path d="M2474.7 1066.88C2474.96 1067.04 2475.09 1067.2 2475 1067.54C2474.86 1068.04 2474.54 1068.04 2474.18 1067.9C2473.91 1067.8 2473.8 1067.59 2473.89 1067.26C2474.03 1066.75 2474.34 1066.79 2474.71 1066.88H2474.7Z" fill="white"/>
                    <path d="M2458.47 1172.81C2458.37 1173.15 2458.25 1173.44 2457.74 1173.31C2457.4 1173.22 2457.28 1173.02 2457.32 1172.74C2457.38 1172.36 2457.54 1172.07 2458.04 1172.2C2458.38 1172.29 2458.46 1172.48 2458.46 1172.81H2458.47Z" fill="white"/>
                    <path d="M2383 971.45C2383.33 971.05 2383.71 970.75 2384.25 970.82C2384.39 970.84 2384.64 971.08 2384.64 971.09C2384.36 971.54 2383.97 971.82 2383.42 971.74C2383.27 971.72 2383.15 971.56 2383 971.44V971.45Z" fill="white"/>
                    <path d="M2464.07 1031.42C2464.41 1031.44 2464.51 1031.7 2464.47 1031.98C2464.41 1032.36 2464.25 1032.65 2463.75 1032.52C2463.41 1032.43 2463.29 1032.23 2463.33 1031.95C2463.39 1031.56 2463.56 1031.26 2464.07 1031.42Z" fill="white"/>
                    <path d="M2352.09 1027.13C2352.35 1027.29 2352.48 1027.45 2352.39 1027.79C2352.25 1028.29 2351.93 1028.29 2351.57 1028.15C2351.3 1028.05 2351.19 1027.84 2351.28 1027.51C2351.42 1027 2351.73 1027.04 2352.1 1027.13H2352.09Z" fill="white"/>
                    <path d="M2275.8 1204.33C2276.1 1204.51 2276.24 1204.68 2276.15 1205.02C2276.01 1205.52 2275.69 1205.52 2275.33 1205.38C2275.06 1205.28 2274.95 1205.07 2275.04 1204.74C2275.17 1204.22 2275.51 1204.3 2275.81 1204.33H2275.8Z" fill="white"/>
                    <path d="M2277.1 1181.32C2277 1181.85 2276.64 1181.85 2276.28 1181.71C2276.01 1181.61 2275.9 1181.4 2275.99 1181.07C2276.13 1180.57 2276.45 1180.57 2276.81 1180.71C2277.07 1180.81 2277.25 1181.03 2277.1 1181.33V1181.32Z" fill="white"/>
                    <path d="M2418.38 954.67C2418.03 954.57 2417.67 954.46 2417.85 953.96C2417.91 953.81 2418.03 953.68 2418.13 953.55C2418.38 953.87 2418.62 954.19 2418.87 954.5C2418.71 954.56 2418.55 954.61 2418.38 954.67Z" fill="white"/>
                    <path d="M2424.11 1005.18C2424.46 1005.28 2424.82 1005.39 2424.64 1005.89C2424.58 1006.04 2424.46 1006.17 2424.36 1006.3C2424.11 1005.98 2423.87 1005.66 2423.62 1005.35C2423.78 1005.29 2423.94 1005.24 2424.11 1005.18Z" fill="white"/>
                    <path d="M2293.94 1004.97C2294.14 1005.3 2294.33 1005.6 2294.51 1005.91C2294.26 1005.94 2293.98 1006.06 2293.77 1005.98C2293.3 1005.82 2293.43 1005.42 2293.58 1005.1C2293.62 1005.01 2293.84 1005 2293.94 1004.97Z" fill="white"/>
                    <path d="M2414.7 1110.6C2414.57 1110.78 2414.45 1111.04 2414.38 1111.03C2413.9 1110.92 2413.91 1110.39 2413.65 1110.09C2413.81 1110.04 2413.98 1109.92 2414.13 1109.94C2414.51 1109.99 2414.8 1110.17 2414.7 1110.6Z" fill="white"/>
                    <path d="M2490.99 1156.42C2491.08 1156.53 2491.26 1156.64 2491.25 1156.74C2491.22 1157.09 2491.13 1157.5 2490.64 1157.4C2490.42 1157.36 2490.24 1157.12 2490.03 1156.97C2490.34 1156.79 2490.65 1156.61 2490.98 1156.42H2490.99Z" fill="white"/>
                    <path d="M2473.81 1134.31C2473.92 1133.81 2474.28 1133.85 2474.63 1133.98C2474.77 1134.03 2474.86 1134.22 2474.97 1134.35C2474.6 1134.51 2474.23 1134.66 2473.86 1134.82C2473.84 1134.65 2473.83 1134.49 2473.81 1134.31Z" fill="white"/>
                    <path d="M2311.63 1019.55C2311.68 1019.6 2311.87 1019.71 2311.86 1019.81C2311.83 1020.16 2311.75 1020.57 2311.26 1020.49C2311.04 1020.45 2310.85 1020.21 2310.65 1020.07C2310.97 1019.9 2311.28 1019.74 2311.62 1019.55H2311.63Z" fill="white"/>
                    <path d="M2346.49 1030.33C2346.99 1030.44 2346.95 1030.8 2346.82 1031.15C2346.77 1031.29 2346.58 1031.38 2346.45 1031.49C2346.29 1031.12 2346.14 1030.75 2345.98 1030.38C2346.15 1030.36 2346.31 1030.35 2346.49 1030.33Z" fill="white"/>
                    <path d="M2495.19 1093.26C2495.31 1093.39 2495.43 1093.51 2495.55 1093.63C2495.19 1093.8 2494.83 1093.98 2494.47 1094.15C2494.45 1093.98 2494.39 1093.81 2494.41 1093.65C2494.48 1093.15 2494.82 1093.16 2495.2 1093.26H2495.19Z" fill="white"/>
                    <path d="M2295.05 1179.61C2295.16 1179.11 2295.52 1179.15 2295.87 1179.28C2296.01 1179.33 2296.1 1179.52 2296.21 1179.65C2295.84 1179.81 2295.47 1179.96 2295.1 1180.12C2295.08 1179.95 2295.07 1179.79 2295.05 1179.61Z" fill="white"/>
                    <path d="M2287.38 1217.61C2287.61 1217.54 2287.78 1217.44 2287.93 1217.46C2288.32 1217.52 2288.61 1217.71 2288.43 1218.17C2288.37 1218.32 2288.23 1218.44 2288.13 1218.57C2287.88 1218.25 2287.64 1217.94 2287.38 1217.6V1217.61Z" fill="white"/>
                    <path d="M2237.04 1015.13C2237.31 1015.4 2237.12 1015.66 2237.04 1015.9C2237.02 1015.97 2236.72 1016.06 2236.66 1016C2236.38 1015.78 2236.59 1015.49 2236.66 1015.26C2236.69 1015.18 2236.92 1015.17 2237.04 1015.13Z" fill="white"/>
                    <path d="M2260.62 1008.05C2260.93 1008.05 2261.19 1007.99 2261.43 1008.05C2261.57 1008.08 2261.66 1008.29 2261.78 1008.41C2261.53 1008.44 2261.27 1008.52 2261.03 1008.49C2260.9 1008.47 2260.8 1008.25 2260.62 1008.06V1008.05Z" fill="white"/>
                    <path d="M2387.7 1221.53C2387.97 1221.8 2387.78 1222.06 2387.7 1222.3C2387.68 1222.37 2387.38 1222.46 2387.32 1222.4C2387.04 1222.18 2387.25 1221.89 2387.32 1221.66C2387.35 1221.58 2387.58 1221.57 2387.7 1221.53Z" fill="white"/>
                    <path d="M2473.57 1161.03C2473.57 1161.34 2473.63 1161.6 2473.57 1161.84C2473.54 1161.98 2473.33 1162.07 2473.21 1162.19C2473.18 1161.94 2473.1 1161.68 2473.13 1161.44C2473.15 1161.31 2473.36 1161.21 2473.56 1161.03H2473.57Z" fill="white"/>
                    <path d="M2473.97 1158.02C2473.92 1158.16 2473.88 1158.42 2473.75 1158.62C2473.6 1158.86 2473.4 1158.81 2473.4 1158.53C2473.4 1158.29 2473.47 1158.03 2473.59 1157.82C2473.73 1157.58 2473.89 1157.63 2473.98 1158.02H2473.97Z" fill="white"/>
                    <path d="M2400.81 1232.46C2400.91 1232.33 2401.02 1232.07 2401.11 1232.08C2401.44 1232.11 2401.46 1232.37 2401.34 1232.62C2401.27 1232.77 2401.13 1233.01 2401.04 1233C2400.71 1232.97 2400.71 1232.72 2400.8 1232.46H2400.81Z" fill="white"/>
                    <path d="M2287.93 991.05C2288.06 991.15 2288.32 991.26 2288.31 991.35C2288.28 991.68 2288.02 991.7 2287.77 991.58C2287.62 991.51 2287.38 991.37 2287.39 991.28C2287.42 990.95 2287.67 990.95 2287.93 991.04V991.05Z" fill="white"/>
                    <path d="M2487.37 1086.78C2487.68 1086.78 2487.94 1086.72 2488.18 1086.78C2488.32 1086.81 2488.41 1087.02 2488.53 1087.14C2488.28 1087.17 2488.02 1087.25 2487.78 1087.22C2487.65 1087.2 2487.55 1086.98 2487.37 1086.79V1086.78Z" fill="white"/>
                    <path d="M2245.33 1136.28C2245.41 1136.37 2245.53 1136.5 2245.64 1136.63C2245.39 1136.66 2245.13 1136.74 2244.89 1136.71C2244.76 1136.69 2244.66 1136.47 2244.54 1136.35C2244.77 1136.18 2245.02 1136.13 2245.32 1136.29L2245.33 1136.28Z" fill="white"/>
                    <path d="M2496.2 1044.51C2496.15 1044.78 2496.03 1045 2495.73 1044.87C2495.65 1044.83 2495.65 1044.55 2495.66 1044.39C2495.68 1044.12 2495.82 1043.9 2496.13 1044.03C2496.21 1044.07 2496.19 1044.34 2496.21 1044.51H2496.2Z" fill="white"/>
                    <path d="M2282.01 1147.03C2281.96 1147.3 2281.84 1147.52 2281.54 1147.39C2281.46 1147.35 2281.46 1147.07 2281.47 1146.91C2281.49 1146.64 2281.63 1146.42 2281.94 1146.55C2282.02 1146.59 2282 1146.86 2282.02 1147.03H2282.01Z" fill="white"/>
                    <path d="M2292.35 1140.99C2292.3 1141.26 2292.18 1141.48 2291.88 1141.35C2291.8 1141.31 2291.8 1141.03 2291.81 1140.87C2291.83 1140.6 2291.97 1140.38 2292.28 1140.51C2292.36 1140.55 2292.34 1140.82 2292.36 1140.99H2292.35Z" fill="white"/>
                    <path d="M2326.89 1113.54C2327.2 1113.54 2327.46 1113.48 2327.7 1113.54C2327.84 1113.57 2327.93 1113.78 2328.05 1113.9C2327.8 1113.93 2327.54 1114.01 2327.3 1113.98C2327.17 1113.96 2327.07 1113.74 2326.89 1113.55V1113.54Z" fill="white"/>
                    <path d="M2304.17 1013.35C2304.33 1013.49 2304.51 1013.66 2304.7 1013.83C2304.54 1013.88 2304.34 1014.02 2304.22 1013.98C2303.99 1013.89 2303.81 1013.7 2303.61 1013.55C2303.77 1013.5 2303.93 1013.44 2304.17 1013.36V1013.35Z" fill="white"/>
                    <path d="M2381.9 1058.32C2381.98 1058.41 2382.1 1058.54 2382.21 1058.67C2381.96 1058.7 2381.7 1058.78 2381.46 1058.75C2381.33 1058.73 2381.23 1058.51 2381.11 1058.39C2381.34 1058.22 2381.59 1058.17 2381.89 1058.33L2381.9 1058.32Z" fill="white"/>
                    <path d="M2399.72 1018.28C2400.03 1018.28 2400.29 1018.22 2400.53 1018.28C2400.67 1018.31 2400.76 1018.52 2400.88 1018.64C2400.63 1018.67 2400.37 1018.75 2400.13 1018.72C2400 1018.7 2399.9 1018.48 2399.72 1018.29V1018.28Z" fill="white"/>
                    <path d="M2427.19 955.31C2427.46 955.58 2427.27 955.84 2427.19 956.08C2427.17 956.15 2426.87 956.24 2426.81 956.18C2426.53 955.96 2426.74 955.67 2426.81 955.44C2426.84 955.36 2427.07 955.35 2427.19 955.31Z" fill="white"/>
                    <path d="M2439.59 1015.65C2439.54 1015.92 2439.42 1016.14 2439.12 1016.01C2439.04 1015.97 2439.04 1015.69 2439.05 1015.53C2439.07 1015.26 2439.21 1015.04 2439.52 1015.17C2439.6 1015.21 2439.58 1015.48 2439.6 1015.65H2439.59Z" fill="white"/>
                    <path d="M2381.13 1205.12C2381.23 1204.99 2381.34 1204.73 2381.43 1204.74C2381.76 1204.77 2381.78 1205.03 2381.66 1205.28C2381.59 1205.43 2381.45 1205.67 2381.36 1205.66C2381.03 1205.63 2381.03 1205.38 2381.12 1205.12H2381.13Z" fill="white"/>
                    <path d="M2390 1113.52C2390.13 1113.62 2390.39 1113.73 2390.38 1113.82C2390.35 1114.15 2390.09 1114.17 2389.84 1114.05C2389.69 1113.98 2389.45 1113.84 2389.46 1113.75C2389.49 1113.42 2389.74 1113.42 2390 1113.51V1113.52Z" fill="white"/>
                    <path d="M2404.4 1116.42C2404.54 1116.39 2404.68 1116.34 2404.81 1116.35C2404.86 1116.35 2404.9 1116.49 2404.94 1116.57C2404.8 1116.6 2404.66 1116.65 2404.53 1116.64C2404.48 1116.64 2404.44 1116.5 2404.4 1116.42Z" fill="white"/>
                    <path d="M2505.16 1092.67C2505.3 1092.64 2505.44 1092.59 2505.57 1092.6C2505.62 1092.6 2505.66 1092.74 2505.7 1092.82C2505.56 1092.85 2505.42 1092.9 2505.29 1092.89C2505.24 1092.89 2505.2 1092.75 2505.16 1092.67Z" fill="white"/>
                    <path d="M2298.11 1130.93C2298.21 1131.02 2298.31 1131.12 2298.41 1131.22C2298.33 1131.26 2298.23 1131.37 2298.19 1131.34C2298.07 1131.28 2297.97 1131.17 2297.87 1131.07C2297.95 1131.03 2298.02 1130.98 2298.12 1130.93H2298.11Z" fill="white"/>
                    <path d="M2274.86 1168.42C2274.81 1168.33 2274.76 1168.25 2274.72 1168.17C2274.86 1168.14 2275 1168.09 2275.13 1168.1C2275.18 1168.1 2275.22 1168.24 2275.26 1168.32C2275.12 1168.35 2274.99 1168.39 2274.86 1168.42Z" fill="white"/>
                    <path d="M2294.33 1088.63C2294.24 1088.73 2294.14 1088.83 2294.04 1088.93C2294 1088.85 2293.89 1088.75 2293.92 1088.71C2293.98 1088.59 2294.09 1088.49 2294.19 1088.39C2294.23 1088.47 2294.28 1088.54 2294.33 1088.64V1088.63Z" fill="white"/>
                    <path d="M2332.89 1067.61C2332.92 1067.75 2332.97 1067.89 2332.96 1068.02C2332.96 1068.07 2332.82 1068.11 2332.74 1068.15C2332.71 1068.01 2332.66 1067.87 2332.67 1067.74C2332.67 1067.69 2332.81 1067.65 2332.89 1067.61Z" fill="white"/>
                    <path d="M2349.1 1024.39C2349.01 1024.44 2348.93 1024.49 2348.85 1024.53C2348.82 1024.39 2348.77 1024.25 2348.78 1024.12C2348.78 1024.07 2348.92 1024.03 2349 1023.99C2349.03 1024.13 2349.07 1024.26 2349.1 1024.39Z" fill="white"/>
                    <path d="M2301.62 992.98C2301.72 993.07 2301.82 993.17 2301.92 993.27C2301.84 993.31 2301.74 993.42 2301.7 993.39C2301.58 993.33 2301.48 993.22 2301.38 993.12C2301.46 993.08 2301.53 993.03 2301.63 992.98H2301.62Z" fill="white"/>
                    <path d="M2287.88 1106.07C2287.93 1106.16 2287.98 1106.24 2288.02 1106.32C2287.88 1106.35 2287.74 1106.4 2287.61 1106.39C2287.56 1106.39 2287.52 1106.25 2287.48 1106.17C2287.62 1106.14 2287.75 1106.1 2287.88 1106.07Z" fill="white"/>
                    <path d="M2355.89 1086.4C2355.84 1086.31 2355.79 1086.23 2355.75 1086.15C2355.89 1086.12 2356.03 1086.07 2356.16 1086.08C2356.21 1086.08 2356.25 1086.22 2356.29 1086.3C2356.15 1086.33 2356.02 1086.37 2355.89 1086.4Z" fill="white"/>
                    <path d="M2401.23 957.42C2401.31 957.37 2401.43 957.28 2401.45 957.3C2401.55 957.4 2401.68 957.51 2401.7 957.63C2401.75 957.89 2401.61 957.97 2401.41 957.8C2401.31 957.72 2401.29 957.55 2401.23 957.42Z" fill="white"/>
                    <path d="M2401.89 957.93C2401.95 958.06 2402.08 958.24 2402.04 958.31C2401.92 958.5 2401.74 958.5 2401.69 958.33C2401.64 958.19 2401.66 958.02 2401.64 957.87C2401.72 957.89 2401.8 957.91 2401.88 957.93H2401.89Z" fill="white"/>
                    <path d="M2411.31 1144.28C2411.41 1144.37 2411.51 1144.47 2411.61 1144.57C2411.53 1144.61 2411.43 1144.72 2411.39 1144.69C2411.27 1144.63 2411.17 1144.52 2411.07 1144.42C2411.15 1144.38 2411.22 1144.33 2411.32 1144.28H2411.31Z" fill="white"/>
                    <path d="M2322.12 937.09C2322.22 937.18 2322.32 937.28 2322.42 937.38C2322.34 937.42 2322.24 937.53 2322.2 937.5C2322.08 937.44 2321.98 937.33 2321.88 937.23C2321.96 937.19 2322.03 937.14 2322.13 937.09H2322.12Z" fill="white"/>
                    <path d="M2407.38 953.03C2407.43 953.12 2407.48 953.2 2407.52 953.28C2407.38 953.31 2407.24 953.36 2407.11 953.35C2407.06 953.35 2407.02 953.21 2406.98 953.13C2407.12 953.1 2407.25 953.06 2407.38 953.03Z" fill="white"/>
                    <path d="M2303.27 973.99C2303.17 973.9 2303.07 973.8 2302.97 973.7C2303.05 973.66 2303.15 973.55 2303.19 973.58C2303.31 973.64 2303.41 973.75 2303.51 973.85C2303.43 973.89 2303.36 973.94 2303.26 973.99H2303.27Z" fill="white"/>
                    <path d="M2418.13 1051.54C2418.23 1051.63 2418.33 1051.73 2418.43 1051.83C2418.35 1051.87 2418.25 1051.98 2418.21 1051.95C2418.09 1051.89 2417.99 1051.78 2417.89 1051.68C2417.97 1051.64 2418.04 1051.59 2418.14 1051.54H2418.13Z" fill="white"/>
                    <path d="M2287.37 1059.55C2287.28 1059.6 2287.2 1059.65 2287.12 1059.69C2287.09 1059.55 2287.04 1059.41 2287.05 1059.28C2287.05 1059.23 2287.19 1059.19 2287.27 1059.15C2287.3 1059.29 2287.34 1059.42 2287.37 1059.55Z" fill="white"/>
                    <path d="M2411.25 985.76C2411.39 985.73 2411.53 985.68 2411.66 985.69C2411.71 985.69 2411.75 985.83 2411.79 985.91C2411.65 985.94 2411.51 985.99 2411.38 985.98C2411.33 985.98 2411.29 985.84 2411.25 985.76Z" fill="white"/>
                    <path d="M2287.99 1047.17C2288.09 1047.26 2288.19 1047.36 2288.29 1047.46C2288.21 1047.5 2288.11 1047.61 2288.07 1047.58C2287.95 1047.52 2287.85 1047.41 2287.75 1047.31L2288 1047.17H2287.99Z" fill="white"/>
                    <path d="M2382.27 1105.45C2382.36 1105.35 2382.46 1105.25 2382.56 1105.15C2382.6 1105.23 2382.71 1105.33 2382.68 1105.37C2382.62 1105.49 2382.51 1105.59 2382.41 1105.69C2382.37 1105.61 2382.32 1105.54 2382.27 1105.44V1105.45Z" fill="white"/>
                    <path d="M2409.06 1234.34C2409.2 1234.31 2409.34 1234.26 2409.47 1234.27C2409.52 1234.27 2409.56 1234.41 2409.6 1234.49C2409.46 1234.52 2409.32 1234.57 2409.19 1234.56C2409.14 1234.56 2409.1 1234.42 2409.06 1234.34Z" fill="white"/>
                    <path d="M2180.77 968.16C2182.27 966.75 2183.76 967.34 2185.16 967.92C2185.67 968.13 2186.41 968.58 2186.72 968.59C2188.66 968.67 2187.59 970.71 2188.63 971.28C2187.92 972.21 2187.39 973.56 2186.46 973.94C2185.57 974.31 2184.43 974.67 2183.36 974.6C2182.35 974.54 2180.9 974.91 2181.14 972.97C2181.18 972.68 2180.63 972.34 2180.4 972C2179.34 970.48 2179.52 969.17 2180.78 968.16H2180.77Z" fill="white"/>
                    <path d="M2177.02 1134.54C2176.27 1133.58 2176.49 1132.3 2176.83 1132.04C2178.14 1131.04 2177.72 1129.44 2178.44 1128.25C2178.95 1127.41 2179.39 1126.98 2180.33 1127.14C2181.87 1127.41 2182.42 1127.88 2182.46 1129.02C2182.48 1129.52 2182.55 1129.99 2182.64 1130.47C2183.03 1132.58 2180.16 1135.85 2177.86 1134.99C2177.45 1134.84 2177.11 1134.72 2177.02 1134.53V1134.54Z" fill="white"/>
                    <path d="M2242.81 1060.1C2242.96 1059.04 2241.81 1057.98 2242.9 1056.96C2243.05 1056.82 2243.3 1056.73 2243.38 1056.56C2243.71 1055.85 2243.81 1054.96 2244.78 1054.77C2245.55 1054.62 2246.11 1055.15 2246.74 1055.43C2247.98 1056 2248.62 1057.81 2248.15 1059.22C2247.78 1060.32 2247.04 1060.88 2245.87 1061.08C2244.66 1061.29 2243.83 1060.48 2242.83 1060.09L2242.81 1060.1Z" fill="white"/>
                    <path d="M2378.1 1119.51C2379.24 1120.94 2379.24 1122 2378.31 1122.95C2377.8 1123.47 2377.16 1123.86 2376.61 1124.36C2376.1 1124.83 2375.74 1124.73 2375.36 1124.19C2374.93 1123.59 2374.35 1123.09 2374 1122.46C2373.41 1121.38 2373.55 1120.24 2374.08 1119.17C2374.19 1118.95 2374.56 1118.7 2374.78 1118.73C2375.6 1118.83 2376.37 1118.64 2377.16 1118.58C2377.54 1118.55 2377.99 1119.41 2378.09 1119.51H2378.1Z" fill="white"/>
                    <path d="M2284.47 1220.86C2283.9 1220.26 2283.06 1219.42 2283.84 1218.35C2284.21 1217.84 2284.9 1216.88 2285.56 1217.33C2286.27 1217.81 2287.46 1217.76 2287.68 1218.87C2287.83 1219.64 2287.73 1220.5 2288.04 1221.18C2288.33 1221.81 2288.25 1222.05 2287.78 1222.44C2287.1 1223.01 2286.41 1222.91 2285.82 1222.48C2285.33 1222.12 2285.01 1221.53 2284.47 1220.86Z" fill="white"/>
                    <path d="M2396.97 1106.65C2396.43 1106.01 2396.49 1105.05 2397.1 1104.5C2397.3 1104.32 2400.25 1103.83 2400.47 1103.94C2400.97 1104.2 2401.18 1106.12 2401.09 1106.09C2399.83 1105.69 2400.27 1107.25 2399.53 1107.37C2398.83 1107.48 2397.32 1107.07 2396.97 1106.66V1106.65Z" fill="white"/>
                    <path d="M2249.12 991.69C2248.77 991.96 2248.32 992.47 2248.06 992.14C2247.57 991.55 2246.76 991.11 2246.86 990.08C2246.92 989.51 2247.41 989.21 2247.46 988.75C2247.55 987.99 2247.79 987.67 2248.62 987.72C2249.51 987.77 2250.08 988.3 2250.1 988.98C2250.13 989.95 2250.28 991.12 2249.12 991.69Z" fill="white"/>
                    <path d="M2231.93 1099.84C2232.31 1100.38 2233.12 1100.83 2232.11 1101.59C2231.88 1101.76 2232.02 1102.39 2231.95 1102.8C2231.87 1103.27 2231.76 1103.73 2231.66 1104.19C2231.16 1103.69 2230.39 1103.26 2230.24 1102.67C2230.04 1101.91 2230.37 1101.03 2230.42 1100.2C2230.45 1099.74 2230.56 1099.33 2230.99 1099.2C2231.47 1099.07 2231.68 1099.54 2231.94 1099.85L2231.93 1099.84Z" fill="white"/>
                    <path d="M2355.89 985.7C2356.02 985.86 2356.17 986.01 2356.27 986.18C2356.51 986.57 2357.18 986.86 2356.73 987.44C2356.27 988.03 2355.63 988.26 2354.84 988.08C2354.17 987.92 2354.15 987.52 2354.16 986.96C2354.16 986.6 2353.87 986.23 2353.87 985.87C2353.87 985.39 2353.77 984.79 2354.34 984.52C2355.02 984.2 2355.15 984.91 2355.49 985.22C2355.64 985.36 2355.75 985.54 2355.88 985.7H2355.89Z" fill="white"/>
                    <path d="M2397.37 1118.11C2398.32 1117.95 2399.26 1117.49 2400.26 1117.8C2400.49 1117.87 2400.75 1118.17 2400.66 1118.55C2400.55 1119.03 2400.75 1119.72 2400.14 1119.89C2399.4 1120.1 2398.6 1120.16 2397.82 1120.2C2396.9 1120.25 2397.64 1119.43 2397.32 1119.13C2396.99 1118.81 2396.88 1118.44 2397.36 1118.11H2397.37Z" fill="white"/>
                    <path d="M2197.17 1061.98C2197.6 1062.36 2197.67 1062.75 2197.11 1063.17C2196.23 1063.81 2195.39 1063.75 2194.75 1063.03C2194.18 1062.39 2194.3 1061.68 2194.68 1061.11C2195.12 1060.45 2196.33 1060.89 2197.17 1061.99V1061.98Z" fill="white"/>
                    <path d="M2102.96 1070.25C2103.16 1070.49 2103.41 1070.71 2103.54 1070.99C2103.86 1071.63 2103.34 1072.34 2102.64 1072.27C2102.22 1072.23 2101.78 1072.26 2101.4 1072.12C2100.91 1071.94 2100.48 1071.48 2100.49 1071C2100.49 1070.45 2101.19 1070.06 2101.11 1069.41C2101.68 1069.55 2102.4 1069.28 2102.77 1070C2102.82 1070.09 2102.9 1070.16 2102.97 1070.25H2102.96Z" fill="white"/>
                    <path d="M2354.52 1078.38C2354.56 1078.01 2354.61 1077.7 2354.62 1077.39C2354.65 1076.61 2355.82 1076.59 2355.72 1075.68C2355.67 1075.2 2356.04 1074.9 2356.5 1075C2356.94 1075.09 2357.09 1075.47 2357.02 1075.94C2356.93 1076.56 2356.92 1077.18 2356.8 1077.79C2356.74 1078.08 2357.04 1078.67 2356.3 1078.56C2355.7 1078.48 2355.1 1078.43 2354.5 1078.37L2354.52 1078.38Z" fill="white"/>
                    <path d="M2361.28 1208.41C2360.7 1208.75 2359.57 1208.34 2359.22 1207.66C2359.08 1207.39 2359.67 1205.54 2360.01 1205.39C2360.87 1205.02 2361.01 1205.89 2361.33 1206.29C2361.93 1207.04 2361.84 1208.08 2361.28 1208.41Z" fill="white"/>
                    <path d="M2278.19 1180.56C2278.63 1180.08 2279.02 1179.94 2279.45 1180.52C2279.97 1181.23 2280.05 1182.29 2279.56 1182.68C2279.07 1183.07 2278.02 1182.79 2277.47 1182.1C2276.65 1181.07 2277.91 1181.02 2278.19 1180.55V1180.56Z" fill="white"/>
                    <path d="M2270.57 1181.63C2270.49 1181.06 2270.38 1180.43 2270.86 1179.98C2271.39 1179.49 2272.74 1179.99 2273.12 1180.76C2273.38 1181.27 2273.03 1181.49 2272.7 1181.72C2271.93 1182.24 2270.91 1182.19 2270.57 1181.63Z" fill="white"/>
                    <path d="M2338.71 1113.09C2338.97 1113.41 2339.08 1113.58 2339.23 1113.73C2339.79 1114.26 2339.38 1114.88 2339.41 1115.46C2338.85 1115.31 2338.16 1115.58 2337.75 1114.93C2337.64 1114.76 2337.5 1114.6 2337.36 1114.45C2336.88 1113.95 2336.67 1113.55 2337.39 1112.98C2338.08 1112.44 2338.46 1112.58 2338.7 1113.09H2338.71Z" fill="white"/>
                    <path d="M2186.55 1211.7C2187.09 1211.2 2187.51 1211.48 2187.62 1211.86C2187.82 1212.61 2187.88 1213.41 2187.94 1214.2C2187.99 1214.93 2187.44 1214.57 2187.12 1214.58C2185.73 1214.64 2186.45 1213.35 2186.01 1212.78C2185.5 1212.12 2186.42 1212 2186.56 1211.69L2186.55 1211.7Z" fill="white"/>
                    <path d="M2318.39 1145.25C2317.72 1144.69 2317.83 1143.96 2318.2 1143.37C2318.47 1142.93 2320.2 1143.15 2320.27 1143.58C2320.42 1144.4 2320.11 1145.05 2319.39 1145.54C2318.89 1145.88 2318.66 1145.58 2318.39 1145.26V1145.25Z" fill="white"/>
                    <path d="M2291.25 955.1C2291.79 954.8 2292.37 954.96 2292.9 955.21C2293.22 955.36 2292.78 957.04 2292.32 957.49C2291.9 957.9 2291.6 957.62 2291.34 957.28C2290.82 956.59 2290.76 955.52 2291.25 955.1Z" fill="white"/>
                    <path d="M2362.48 1140.68C2362.18 1140.8 2361.8 1140.82 2361.73 1140.46C2361.56 1139.59 2361.17 1138.77 2361.31 1137.83C2361.37 1137.44 2361.63 1137.2 2361.86 1137.16C2362.08 1137.12 2362.54 1137.37 2362.58 1137.56C2362.76 1138.32 2362.82 1139.12 2362.9 1139.9C2362.94 1140.23 2362.77 1140.49 2362.47 1140.67L2362.48 1140.68Z" fill="white"/>
                    <path d="M2393.08 1156.06C2392.85 1155.82 2392.55 1155.52 2392.91 1155.09C2393.22 1154.72 2393.63 1154.88 2394.02 1154.85C2394.81 1154.8 2395.07 1155.44 2395.36 1155.91C2395.53 1156.19 2395.44 1156.96 2395.26 1157.05C2394.88 1157.22 2394.31 1157.19 2393.92 1157.03C2393.59 1156.89 2393.39 1156.43 2393.09 1156.06H2393.08Z" fill="white"/>
                    <path d="M2238.86 1233.59C2238.51 1233.98 2238.12 1234.04 2237.72 1233.48C2237.21 1232.77 2237.15 1231.73 2237.62 1231.33C2238.42 1230.65 2238.69 1232.12 2239.47 1231.81C2239.6 1231.76 2239.44 1233.04 2238.85 1233.6L2238.86 1233.59Z" fill="white"/>
                    <path d="M2320.1 1056C2320.1 1055.81 2320.01 1055.56 2320.1 1055.44C2320.6 1054.79 2322.42 1054.77 2322.87 1055.42C2323.02 1055.64 2323.18 1056.02 2323.09 1056.21C2323 1056.41 2322.61 1056.57 2322.35 1056.58C2321.19 1056.63 2320.43 1056.41 2320.1 1056.01V1056Z" fill="white"/>
                    <path d="M2216.48 1000.25C2216.24 1000.45 2216.02 1000.69 2215.75 1000.83C2215.06 1001.18 2214.92 1000.47 2214.6 1000.16C2214.17 999.75 2214.46 999.44 2214.8 999.18C2215.11 998.94 2215.37 998.63 2215.86 998.71C2216.36 998.8 2216.96 998.72 2217.13 999.33C2217.26 999.8 2216.77 1000 2216.48 1000.26V1000.25Z" fill="white"/>
                    <path d="M2359.22 1139.38C2359.52 1139.96 2359.15 1140.19 2358.82 1140.4C2358.03 1140.92 2357.03 1140.87 2356.7 1140.28C2356.42 1139.8 2356.8 1139.55 2357.14 1139.33C2357.93 1138.82 2358.91 1138.88 2359.22 1139.37V1139.38Z" fill="white"/>
                    <path d="M2244.07 1240.78C2243.35 1240.68 2242.65 1240.76 2242.17 1240.01C2241.77 1239.4 2242.04 1239.15 2242.46 1238.79C2242.92 1238.4 2243.21 1238.32 2243.71 1238.81C2244.37 1239.44 2244.04 1240.11 2244.07 1240.78Z" fill="white"/>
                    <path d="M2231.21 1231.46C2231.19 1231.27 2231.08 1231 2231.17 1230.9C2231.44 1230.6 2231.77 1230.19 2232.11 1230.15C2232.66 1230.09 2233.36 1229.96 2233.75 1230.59C2234.05 1231.07 2233.67 1231.32 2233.34 1231.54C2232.54 1232.06 2231.61 1232.01 2231.21 1231.45V1231.46Z" fill="white"/>
                    <path d="M2240.44 964.77C2240.18 964.43 2239.9 964.06 2240.42 963.77C2241.08 963.39 2241.84 963.4 2242.25 964.06C2242.47 964.41 2242.27 965.02 2242.26 965.51C2241.59 965.41 2240.81 965.57 2240.44 964.77Z" fill="white"/>
                    <path d="M2145 1101.32C2145.62 1100.89 2146.77 1101.07 2147.03 1101.53C2147.3 1102.01 2146.9 1102.22 2146.61 1102.48C2146.02 1103.02 2145.34 1103.01 2144.83 1102.55C2144.68 1102.41 2144.68 1101.58 2145 1101.32Z" fill="white"/>
                    <path d="M2308.08 1165.67C2307.75 1165.26 2307.42 1164.81 2307.95 1164.6C2308.59 1164.34 2309.34 1164.31 2310.04 1164.19C2310.01 1164.91 2309.94 1165.55 2309.21 1166.01C2308.52 1166.44 2308.34 1165.9 2308.09 1165.67H2308.08Z" fill="white"/>
                    <path d="M2151 1180.69C2150.38 1180.08 2150.67 1179.78 2151.11 1179.36C2151.79 1178.72 2152.13 1179.07 2152.57 1179.61C2153.03 1180.17 2152.8 1180.44 2152.35 1180.85C2151.69 1181.45 2151.28 1181.27 2151 1180.68V1180.69Z" fill="white"/>
                    <path d="M2321.45 1164.18C2321.94 1163.91 2322.37 1163.03 2322.92 1163.94C2323.22 1164.44 2323.17 1165.13 2322.71 1165.6C2321.94 1166.37 2321.44 1165.28 2320.93 1165.09C2320.28 1164.86 2321.34 1164.55 2321.45 1164.18Z" fill="white"/>
                    <path d="M2414.13 1155.94C2413.94 1155.7 2413.7 1155.48 2413.56 1155.22C2413.3 1154.73 2413.68 1154.5 2414.01 1154.27C2414.25 1154.1 2414.42 1153.76 2414.79 1153.91C2415.21 1154.08 2415.38 1154.4 2415.29 1154.86C2415.25 1155.06 2415.25 1155.27 2415.22 1155.47C2415.17 1155.86 2415.22 1156.49 2415.02 1156.58C2414.59 1156.78 2414.4 1156.21 2414.13 1155.94Z" fill="white"/>
                    <path d="M2142.75 1041.31C2142.05 1041.59 2141.9 1040.94 2141.6 1040.6C2141.05 1039.97 2141.73 1039.74 2142.06 1039.43C2142.37 1039.14 2142.73 1038.91 2143.06 1039.41C2143.4 1039.92 2143.21 1041.02 2142.74 1041.31H2142.75Z" fill="white"/>
                    <path d="M2242.45 1174.51C2242.89 1174.18 2243.23 1173.92 2243.56 1174.42C2243.9 1174.94 2243.72 1176.06 2243.24 1176.31C2242.75 1176.57 2242.56 1176.13 2242.28 1175.86C2241.58 1175.21 2241.99 1174.82 2242.45 1174.51Z" fill="white"/>
                    <path d="M2363.9 1164.82C2363.7 1164.9 2363.36 1165.18 2363.19 1165.1C2362.73 1164.88 2363.02 1164.38 2363.12 1164.11C2363.27 1163.7 2362.63 1162.74 2363.82 1162.94C2364.21 1163.01 2364.66 1162.96 2364.85 1163.4C2365.23 1164.29 2364.2 1164.25 2363.91 1164.81L2363.9 1164.82Z" fill="white"/>
                    <path d="M2342.7 1003.56C2342.67 1003.07 2342.35 1002.22 2342.99 1002.2C2343.54 1002.18 2344 1001.43 2344.51 1001.96C2344.82 1002.28 2344.02 1003.73 2343.45 1003.91C2343.15 1004 2342.87 1003.87 2342.7 1003.55V1003.56Z" fill="white"/>
                    <path d="M2164.75 1166.7C2164.75 1166.7 2164.21 1166.53 2163.76 1166.3C2163.56 1166.2 2163.97 1164.82 2164.24 1164.69C2164.94 1164.34 2165.06 1165.04 2165.38 1165.35C2165.77 1165.73 2165.59 1166.05 2164.75 1166.71V1166.7Z" fill="white"/>
                    <path d="M2145.55 1134.84C2145.26 1134.15 2145.92 1134.02 2146.24 1133.7C2146.64 1133.29 2147.07 1133.5 2147.18 1133.89C2147.31 1134.34 2147.24 1134.87 2147.17 1135.35C2147.14 1135.52 2145.68 1135.08 2145.55 1134.83V1134.84Z" fill="white"/>
                    <path d="M2192.94 948.57C2193.3 948.14 2193.62 948.2 2193.81 948.44C2194.31 949.07 2194.19 949.73 2193.72 950.33C2193.48 950.64 2193.15 950.57 2192.95 950.34C2192.41 949.7 2192.62 949.06 2192.94 948.57Z" fill="white"/>
                    <path d="M2343.95 1053.19C2343.37 1053.53 2342.72 1053.51 2342.38 1053.1C2342.21 1052.89 2342.21 1052.12 2342.7 1051.74C2343.36 1051.23 2343.56 1051.87 2343.87 1052.2C2344.16 1052.51 2344.36 1052.85 2343.95 1053.19Z" fill="white"/>
                    <path d="M2126.59 1028.36C2126.79 1028.14 2128.27 1028.04 2128.26 1028.22C2128.24 1028.7 2128.19 1029.24 2127.96 1029.65C2127.75 1030.03 2127.29 1030.07 2127 1029.61C2126.76 1029.23 2126.12 1028.97 2126.59 1028.35V1028.36Z" fill="white"/>
                    <path d="M2349.28 1056.8C2349.07 1056.25 2349 1055.58 2349.66 1055.07C2349.9 1054.88 2350.24 1054.89 2350.41 1055.24C2350.74 1055.93 2350.73 1056.6 2350.11 1057.11C2349.88 1057.3 2349.54 1057.29 2349.28 1056.8Z" fill="white"/>
                    <path d="M2412.44 1025.35C2412.05 1024.92 2412.23 1024.5 2412.63 1024.39C2413.08 1024.26 2413.61 1024.33 2414.09 1024.41C2414.26 1024.44 2413.82 1025.9 2413.57 1026.03C2413.22 1026.22 2412.78 1025.95 2412.43 1025.36L2412.44 1025.35Z" fill="white"/>
                    <path d="M2316.97 1161.57C2316.7 1161.22 2316.43 1160.88 2316.92 1160.55C2317.45 1160.19 2318.12 1160.23 2318.45 1160.65C2318.62 1160.86 2318.62 1161.63 2318.13 1162.01C2317.47 1162.52 2317.29 1161.85 2316.97 1161.57Z" fill="white"/>
                    <path d="M2408.54 1121.8C2408.42 1121.22 2408.38 1120.67 2408.83 1120.21C2409.1 1119.94 2409.43 1119.97 2409.59 1120.23C2409.85 1120.66 2410.74 1120.98 2410 1121.69C2409.55 1122.13 2409.04 1121.71 2408.54 1121.79V1121.8Z" fill="white"/>
                    <path d="M2318.23 1147.06C2318.23 1146.87 2318.21 1146.68 2318.2 1146.5C2319.1 1146.57 2320.02 1146.81 2320.9 1146.36C2320.94 1146.34 2321.2 1146.65 2321.27 1146.83C2321.3 1146.94 2321.11 1147.24 2321.06 1147.23C2320.24 1147.06 2319.51 1147.84 2318.68 1147.44C2318.51 1147.36 2318.37 1147.19 2318.22 1147.06H2318.23Z" fill="white"/>
                    <path d="M2321.27 1143.2C2321.78 1142.84 2322.02 1143.68 2322.28 1144.08C2322.81 1144.88 2321.85 1145.01 2321.51 1145.39C2321.41 1145.5 2320.75 1145.6 2321.05 1145.14C2321.49 1144.46 2319.97 1144.04 2321.26 1143.2H2321.27Z" fill="white"/>
                    <path d="M2189.75 998.32C2189.24 998.89 2188.63 998.47 2188.06 998.5C2188.09 998.11 2187.97 997.53 2188.18 997.37C2188.64 997.02 2189.22 997.14 2189.69 997.54C2189.97 997.78 2189.9 998.05 2189.75 998.32Z" fill="white"/>
                    <path d="M2172.08 1167.27C2172.46 1167.04 2172.78 1166.44 2173.33 1167.08C2173.73 1167.54 2173.44 1167.82 2173.1 1168.07C2172.73 1168.34 2172.39 1168.91 2171.84 1168.27C2171.44 1167.81 2171.75 1167.55 2172.08 1167.26V1167.27Z" fill="white"/>
                    <path d="M2255.98 946.65C2255.88 945.62 2256.26 945.07 2256.92 944.7C2257.07 944.61 2257.3 944.65 2257.49 944.63C2257.47 944.83 2257.41 945.04 2257.43 945.24C2257.53 946 2256.97 946.31 2256.48 946.64C2256.33 946.74 2256.05 946.64 2255.98 946.64V946.65Z" fill="white"/>
                    <path d="M2143.91 1183.05C2143.81 1182.94 2144.06 1182.63 2144.42 1182.38C2144.79 1182.12 2145.12 1181.52 2145.68 1182.16C2146.07 1182.61 2145.82 1182.9 2145.46 1183.15C2145.09 1183.42 2144.74 1183.99 2143.91 1183.05Z" fill="white"/>
                    <path d="M2209.63 1170.32C2209.77 1170.3 2210.01 1170.21 2210.08 1170.28C2210.58 1170.78 2211.04 1171.29 2210.8 1172.13C2210.7 1172.47 2210.6 1172.65 2210.28 1172.5C2209.78 1172.25 2209.34 1170.75 2209.63 1170.33V1170.32Z" fill="white"/>
                    <path d="M2183.14 1050.68C2182.72 1049.77 2183.72 1049.76 2184.07 1049.36C2184.23 1049.18 2184.66 1049.43 2184.78 1049.73C2185.15 1050.6 2184.25 1050.69 2183.86 1051.06C2183.68 1051.23 2183.29 1051 2183.14 1050.69V1050.68Z" fill="white"/>
                    <path d="M2272.35 1134.62C2271.93 1133.71 2272.93 1133.7 2273.28 1133.3C2273.44 1133.12 2273.87 1133.37 2273.99 1133.67C2274.36 1134.54 2273.46 1134.63 2273.07 1135C2272.89 1135.17 2272.5 1134.94 2272.35 1134.63V1134.62Z" fill="white"/>
                    <path d="M2315.41 1000.07C2315.31 999.96 2315.56 999.65 2315.92 999.4C2316.29 999.14 2316.62 998.54 2317.18 999.18C2317.57 999.63 2317.32 999.92 2316.96 1000.17C2316.59 1000.44 2316.24 1001.01 2315.41 1000.07Z" fill="white"/>
                    <path d="M2314.47 1206.35C2315.08 1206.6 2315.71 1206.86 2316.33 1207.13C2316.36 1207.14 2316.35 1207.35 2316.3 1207.42C2316.02 1207.76 2315.67 1207.85 2315.23 1207.77C2314.73 1207.69 2314.2 1207.69 2314.12 1207.04C2314.1 1206.89 2314.28 1206.71 2314.48 1206.34L2314.47 1206.35Z" fill="white"/>
                    <path d="M2344.11 1156.4C2343.8 1156.69 2343.49 1157.02 2343.08 1156.62C2342.55 1156.11 2342.86 1155.75 2343.33 1155.41C2343.66 1155.17 2343.95 1154.81 2344.37 1155.21C2344.9 1155.72 2344.62 1156.1 2344.12 1156.4H2344.11Z" fill="white"/>
                    <path d="M2363.32 1095.38C2363.1 1094.84 2363.05 1094.54 2363.4 1094.38C2364.09 1094.05 2364.75 1094.07 2365.27 1094.68C2365.37 1094.8 2365.32 1095.05 2365.34 1095.24C2364.64 1095.29 2363.94 1095.34 2363.32 1095.38Z" fill="white"/>
                    <path d="M2258.78 1165.02C2258.37 1164.64 2258.19 1164.22 2258.4 1164.08C2258.84 1163.78 2259.12 1162.92 2259.89 1163.46C2260.15 1163.64 2260.32 1164.06 2260.1 1164.25C2259.69 1164.6 2259.34 1165.27 2258.78 1165.03V1165.02Z" fill="white"/>
                    <path d="M2296.59 990.88C2296.33 990.53 2296.08 990.22 2296.57 989.85C2297.24 989.34 2297.45 989.97 2297.76 990.31C2298.04 990.62 2298.27 990.95 2297.79 991.33C2297.12 991.85 2296.93 991.19 2296.6 990.89L2296.59 990.88Z" fill="white"/>
                    <path d="M2222.21 1050.94C2221.3 1051.36 2221.29 1050.36 2220.89 1050.01C2220.71 1049.85 2220.96 1049.42 2221.26 1049.3C2222.13 1048.93 2222.22 1049.83 2222.59 1050.22C2222.76 1050.4 2222.53 1050.79 2222.22 1050.94H2222.21Z" fill="white"/>
                    <path d="M2372.19 1102.7C2371.88 1102.99 2371.57 1103.32 2371.16 1102.92C2370.63 1102.41 2370.94 1102.05 2371.41 1101.71C2371.74 1101.47 2372.03 1101.11 2372.45 1101.51C2372.98 1102.02 2372.7 1102.4 2372.2 1102.7H2372.19Z" fill="white"/>
                    <path d="M2245.69 1224.75C2245.17 1225.26 2244.9 1225.2 2244.66 1224.96C2244.14 1224.43 2244.47 1224.08 2244.94 1223.77C2245.18 1223.61 2245.59 1223.19 2245.67 1223.43C2245.83 1223.91 2245.7 1224.49 2245.69 1224.74V1224.75Z" fill="white"/>
                    <path d="M2261.62 971.86C2261.58 971.69 2261.51 971.42 2261.45 971.15C2262.01 971.24 2262.68 971 2263.07 971.62C2263.24 971.89 2263.21 972.15 2262.9 972.39C2262.24 972.89 2262.04 972.27 2261.62 971.86Z" fill="white"/>
                    <path d="M2430.72 1126.05C2430.9 1125.83 2431.21 1125.88 2431.43 1126.09C2431.73 1126.38 2431.97 1126.77 2431.46 1127.08C2431.1 1127.29 2430.8 1128.05 2430.33 1127.5C2430.16 1127.3 2430.18 1126.51 2430.71 1126.05H2430.72Z" fill="white"/>
                    <path d="M2283.07 1253.28C2282.54 1252.77 2282.94 1252.16 2282.93 1251.58C2283.18 1251.72 2283.48 1251.81 2283.65 1252.01C2283.94 1252.35 2284.5 1252.69 2283.88 1253.25C2283.6 1253.5 2283.36 1253.46 2283.07 1253.28Z" fill="white"/>
                    <path d="M2405.68 1100.82C2405.95 1100.48 2405.59 1100.76 2405.98 1100.87C2406.19 1100.93 2406.48 1101.29 2406.46 1101.49C2406.42 1102.05 2406.6 1102.74 2405.88 1103.06C2405.8 1103.1 2405.61 1103.08 2405.6 1103.04C2405.28 1102.29 2405.71 1101.56 2405.68 1100.82Z" fill="white"/>
                    <path d="M2348.08 1088.35C2348.18 1087.78 2347.99 1087.05 2348.72 1086.79C2348.89 1086.73 2349.31 1086.98 2349.43 1087.19C2349.79 1087.86 2349.07 1087.99 2348.76 1088.33C2348.55 1088.56 2348.31 1088.65 2348.08 1088.35Z" fill="white"/>
                    <path d="M2247.12 1095.48C2246.99 1095.32 2246.81 1095.18 2246.75 1094.99C2246.71 1094.87 2246.81 1094.58 2246.9 1094.56C2247.23 1094.46 2248.47 1095.95 2248.35 1096.31C2248.32 1096.39 2247.98 1096.48 2247.92 1096.43C2247.63 1096.14 2247.39 1095.8 2247.12 1095.48Z" fill="white"/>
                    <path d="M2383.23 991.32C2382.79 990.84 2383.03 990.52 2383.39 990.29C2383.66 990.12 2383.92 990.15 2384.16 990.45C2384.52 990.91 2384.31 991.2 2383.94 991.43C2383.68 991.59 2383.36 991.65 2383.22 991.32H2383.23Z" fill="white"/>
                    <path d="M2264.47 1244.39C2264.34 1244.07 2264.33 1243.84 2264.63 1243.59C2265.09 1243.23 2265.38 1243.44 2265.62 1243.81C2265.79 1244.08 2265.76 1244.34 2265.46 1244.58C2265 1244.95 2264.74 1244.7 2264.47 1244.38V1244.39Z" fill="white"/>
                    <path d="M2145.98 1045.02C2145.85 1044.7 2145.84 1044.47 2146.14 1044.22C2146.6 1043.86 2146.89 1044.07 2147.13 1044.44C2147.3 1044.71 2147.27 1044.97 2146.97 1045.21C2146.51 1045.58 2146.25 1045.33 2145.98 1045.01V1045.02Z" fill="white"/>
                    <path d="M2230.84 960.12C2231.15 959.88 2231.46 959.69 2231.83 960.15C2232.08 960.45 2232.05 960.71 2231.83 960.94C2231.52 961.25 2231.19 961.4 2230.82 960.94C2230.57 960.64 2230.62 960.41 2230.84 960.11V960.12Z" fill="white"/>
                    <path d="M2165.56 1191.98C2165 1192.13 2164.46 1192.14 2164.01 1191.72C2163.89 1191.61 2163.83 1191.22 2163.84 1191.22C2164.39 1191 2164.93 1191 2165.37 1191.44C2165.49 1191.56 2165.49 1191.79 2165.56 1191.99V1191.98Z" fill="white"/>
                    <path d="M2132.08 1084.09C2131.79 1083.85 2131.87 1083.55 2132.08 1083.32C2132.38 1083.01 2132.72 1082.86 2133.09 1083.32C2133.34 1083.62 2133.31 1083.88 2133.09 1084.11C2132.78 1084.42 2132.42 1084.58 2132.07 1084.09H2132.08Z" fill="white"/>
                    <path d="M2230.37 1162.17C2230.24 1161.85 2230.23 1161.62 2230.53 1161.37C2230.99 1161.01 2231.28 1161.22 2231.52 1161.59C2231.69 1161.86 2231.66 1162.12 2231.36 1162.36C2230.9 1162.73 2230.64 1162.48 2230.37 1162.16V1162.17Z" fill="white"/>
                    <path d="M2416.69 1052.7C2416.54 1052.33 2416.53 1052.1 2416.83 1051.85C2417.29 1051.49 2417.58 1051.7 2417.82 1052.07C2417.99 1052.34 2417.96 1052.6 2417.66 1052.85C2417.2 1053.23 2416.95 1052.93 2416.69 1052.7Z" fill="white"/>
                    <path d="M2400.27 1072.62C2400.71 1072.21 2401.03 1072.45 2401.27 1072.81C2401.44 1073.08 2401.41 1073.34 2401.11 1073.58C2400.65 1073.94 2400.36 1073.73 2400.12 1073.36C2399.95 1073.09 2399.94 1072.78 2400.27 1072.61V1072.62Z" fill="white"/>
                    <path d="M2122.49 1183.68C2122.74 1184.01 2122.99 1184.34 2122.5 1184.67C2122.35 1184.77 2122.15 1184.8 2121.97 1184.86C2121.96 1184.41 2121.95 1183.96 2121.93 1183.51C2122.11 1183.57 2122.29 1183.62 2122.48 1183.68H2122.49Z" fill="white"/>
                    <path d="M2150.79 1134.27C2150.54 1133.94 2150.29 1133.61 2150.78 1133.28C2150.93 1133.18 2151.13 1133.15 2151.31 1133.09C2151.32 1133.54 2151.33 1133.99 2151.35 1134.44C2151.17 1134.38 2150.99 1134.33 2150.8 1134.27H2150.79Z" fill="white"/>
                    <path d="M2268.2 1220.72C2268.24 1220.29 2268.27 1219.89 2268.3 1219.49C2268.54 1219.63 2268.87 1219.71 2269.02 1219.91C2269.34 1220.37 2268.96 1220.64 2268.61 1220.83C2268.52 1220.88 2268.32 1220.75 2268.2 1220.71V1220.72Z" fill="white"/>
                    <path d="M2229.14 1045.3C2229.37 1045.22 2229.66 1045.07 2229.71 1045.12C2230.07 1045.54 2229.71 1046.01 2229.75 1046.45C2229.57 1046.39 2229.34 1046.38 2229.22 1046.26C2228.91 1045.96 2228.77 1045.61 2229.15 1045.29L2229.14 1045.3Z" fill="white"/>
                    <path d="M2190.61 953.37C2190.6 953.21 2190.51 952.99 2190.59 952.9C2190.86 952.6 2191.2 952.29 2191.58 952.7C2191.75 952.89 2191.76 953.22 2191.84 953.49C2191.44 953.45 2191.03 953.41 2190.62 953.36L2190.61 953.37Z" fill="white"/>
                    <path d="M2191.47 984.72C2191.04 985.1 2190.74 984.83 2190.52 984.48C2190.43 984.34 2190.47 984.11 2190.45 983.92C2190.89 984.03 2191.33 984.13 2191.77 984.24C2191.67 984.4 2191.58 984.56 2191.48 984.73L2191.47 984.72Z" fill="white"/>
                    <path d="M2261.89 1195.83C2261.88 1195.75 2261.78 1195.52 2261.85 1195.44C2262.11 1195.14 2262.46 1194.83 2262.84 1195.22C2263.02 1195.4 2263.03 1195.74 2263.11 1196.01C2262.71 1195.95 2262.32 1195.89 2261.89 1195.83Z" fill="white"/>
                    <path d="M2237.55 1162.99C2237.17 1162.56 2237.44 1162.26 2237.79 1162.04C2237.93 1161.95 2238.16 1161.99 2238.35 1161.97C2238.24 1162.41 2238.14 1162.85 2238.03 1163.29C2237.87 1163.19 2237.71 1163.1 2237.54 1163L2237.55 1162.99Z" fill="white"/>
                    <path d="M2144.96 1007.62C2144.93 1007.43 2144.91 1007.24 2144.88 1007.05C2145.32 1007.13 2145.76 1007.22 2146.2 1007.3C2146.11 1007.46 2146.05 1007.66 2145.93 1007.79C2145.54 1008.19 2145.23 1007.96 2144.96 1007.62Z" fill="white"/>
                    <path d="M2382.93 1062.27C2382.5 1062.65 2382.2 1062.38 2381.98 1062.03C2381.89 1061.89 2381.93 1061.66 2381.91 1061.47C2382.35 1061.58 2382.79 1061.68 2383.23 1061.79C2383.13 1061.95 2383.04 1062.11 2382.94 1062.28L2382.93 1062.27Z" fill="white"/>
                    <path d="M2415.03 1033.04C2414.78 1032.95 2414.56 1032.93 2414.44 1032.81C2414.13 1032.5 2413.99 1032.13 2414.46 1031.83C2414.61 1031.73 2414.81 1031.72 2414.99 1031.66C2415 1032.11 2415.02 1032.56 2415.03 1033.03V1033.04Z" fill="white"/>
                    <path d="M2326.32 1249.26C2326.25 1248.84 2326.6 1248.73 2326.83 1248.57C2326.9 1248.52 2327.22 1248.64 2327.25 1248.73C2327.35 1249.11 2326.98 1249.23 2326.75 1249.4C2326.67 1249.45 2326.46 1249.31 2326.32 1249.26Z" fill="white"/>
                    <path d="M2300.33 1240.02C2300.04 1239.82 2299.77 1239.7 2299.59 1239.49C2299.49 1239.37 2299.54 1239.12 2299.52 1238.93C2299.77 1239.07 2300.05 1239.17 2300.24 1239.36C2300.34 1239.46 2300.29 1239.73 2300.32 1240.02H2300.33Z" fill="white"/>
                    <path d="M2327.04 963.02C2326.97 962.6 2327.32 962.49 2327.55 962.33C2327.62 962.28 2327.94 962.4 2327.97 962.49C2328.07 962.87 2327.7 962.99 2327.47 963.16C2327.39 963.21 2327.18 963.07 2327.04 963.02Z" fill="white"/>
                    <path d="M2209.4 960.75C2209.6 960.46 2209.72 960.19 2209.93 960.01C2210.05 959.91 2210.3 959.96 2210.49 959.94C2210.35 960.19 2210.25 960.47 2210.06 960.66C2209.96 960.76 2209.69 960.71 2209.4 960.74V960.75Z" fill="white"/>
                    <path d="M2207.04 963.2C2207.18 963.11 2207.39 962.91 2207.64 962.81C2207.93 962.69 2208.08 962.87 2207.9 963.13C2207.74 963.35 2207.5 963.54 2207.26 963.65C2206.97 963.78 2206.86 963.62 2207.05 963.21L2207.04 963.2Z" fill="white"/>
                    <path d="M2322.44 944.46C2322.26 944.51 2321.99 944.67 2321.92 944.6C2321.64 944.35 2321.8 944.11 2322.07 943.96C2322.23 943.87 2322.52 943.74 2322.59 943.81C2322.87 944.06 2322.7 944.28 2322.45 944.46H2322.44Z" fill="white"/>
                    <path d="M2264.41 1237.28C2264.36 1237.1 2264.2 1236.83 2264.27 1236.76C2264.52 1236.48 2264.76 1236.64 2264.91 1236.91C2265 1237.07 2265.13 1237.36 2265.06 1237.43C2264.81 1237.71 2264.59 1237.54 2264.41 1237.29V1237.28Z" fill="white"/>
                    <path d="M2147.73 1018.66C2147.44 1018.46 2147.17 1018.34 2146.99 1018.13C2146.89 1018.01 2146.94 1017.76 2146.92 1017.57C2147.17 1017.71 2147.45 1017.81 2147.64 1018C2147.74 1018.1 2147.69 1018.37 2147.72 1018.66H2147.73Z" fill="white"/>
                    <path d="M2399.11 1134.35C2399.1 1134.21 2399.08 1134.02 2399.06 1133.83C2399.31 1133.97 2399.59 1134.07 2399.78 1134.26C2399.88 1134.36 2399.83 1134.63 2399.85 1134.82C2399.53 1134.82 2399.27 1134.7 2399.1 1134.36L2399.11 1134.35Z" fill="white"/>
                    <path d="M2111.74 1050.98C2111.96 1050.77 2112.22 1050.65 2112.4 1050.98C2112.45 1051.07 2112.27 1051.32 2112.15 1051.46C2111.95 1051.69 2111.68 1051.8 2111.49 1051.47C2111.44 1051.38 2111.65 1051.15 2111.74 1050.99V1050.98Z" fill="white"/>
                    <path d="M2373.11 1100.33C2373.33 1100.12 2373.59 1100 2373.77 1100.33C2373.82 1100.42 2373.64 1100.67 2373.52 1100.81C2373.32 1101.04 2373.05 1101.15 2372.86 1100.82C2372.81 1100.73 2373.02 1100.5 2373.11 1100.34V1100.33Z" fill="white"/>
                    <path d="M2359.78 1098.94C2360 1098.73 2360.26 1098.61 2360.44 1098.94C2360.49 1099.03 2360.31 1099.28 2360.19 1099.42C2359.99 1099.65 2359.72 1099.76 2359.53 1099.43C2359.48 1099.34 2359.69 1099.11 2359.78 1098.95V1098.94Z" fill="white"/>
                    <path d="M2310.39 1100.84C2310.1 1100.64 2309.83 1100.52 2309.65 1100.31C2309.55 1100.19 2309.6 1099.94 2309.58 1099.75C2309.83 1099.89 2310.11 1099.99 2310.3 1100.18C2310.4 1100.28 2310.35 1100.55 2310.38 1100.84H2310.39Z" fill="white"/>
                    <path d="M2264.52 1206.37C2264.47 1206.14 2264.41 1205.86 2264.36 1205.59C2264.54 1205.65 2264.81 1205.65 2264.89 1205.78C2265.04 1206.01 2265.07 1206.31 2265.16 1206.58C2264.98 1206.52 2264.79 1206.46 2264.53 1206.38L2264.52 1206.37Z" fill="white"/>
                    <path d="M2224.12 1114.25C2224.11 1114.11 2224.09 1113.92 2224.07 1113.73C2224.32 1113.87 2224.6 1113.97 2224.79 1114.16C2224.89 1114.26 2224.84 1114.53 2224.86 1114.72C2224.54 1114.72 2224.28 1114.6 2224.11 1114.26L2224.12 1114.25Z" fill="white"/>
                    <path d="M2181.49 1138.6C2181.2 1138.4 2180.93 1138.28 2180.75 1138.07C2180.65 1137.95 2180.7 1137.7 2180.68 1137.51C2180.93 1137.65 2181.21 1137.75 2181.4 1137.94C2181.5 1138.04 2181.45 1138.31 2181.48 1138.6H2181.49Z" fill="white"/>
                    <path d="M2114.96 1177.26C2114.89 1176.84 2115.24 1176.73 2115.47 1176.57C2115.54 1176.52 2115.86 1176.64 2115.89 1176.73C2115.99 1177.11 2115.62 1177.23 2115.39 1177.4C2115.31 1177.45 2115.1 1177.31 2114.96 1177.26Z" fill="white"/>
                    <path d="M2143.75 1114.56C2143.97 1114.35 2144.23 1114.23 2144.41 1114.56C2144.46 1114.65 2144.28 1114.9 2144.16 1115.04C2143.96 1115.27 2143.69 1115.38 2143.5 1115.05C2143.45 1114.96 2143.66 1114.73 2143.75 1114.57V1114.56Z" fill="white"/>
                    <path d="M2322.09 982.18C2321.91 982.23 2321.64 982.39 2321.57 982.32C2321.29 982.07 2321.45 981.83 2321.72 981.68C2321.88 981.59 2322.17 981.46 2322.24 981.53C2322.52 981.78 2322.35 982 2322.1 982.18H2322.09Z" fill="white"/>
                    <path d="M2253.38 1059.03C2253.33 1058.85 2253.17 1058.58 2253.24 1058.51C2253.49 1058.23 2253.73 1058.39 2253.88 1058.66C2253.97 1058.82 2254.1 1059.11 2254.03 1059.18C2253.78 1059.46 2253.56 1059.29 2253.38 1059.04V1059.03Z" fill="white"/>
                    <path d="M2242.3 1046.87C2242.16 1046.81 2242 1046.76 2241.88 1046.66C2241.84 1046.63 2241.89 1046.47 2241.9 1046.37C2242.04 1046.43 2242.2 1046.48 2242.32 1046.58C2242.36 1046.61 2242.31 1046.77 2242.3 1046.87Z" fill="white"/>
                    <path d="M2135.57 1001.55C2135.43 1001.49 2135.27 1001.44 2135.15 1001.34C2135.11 1001.31 2135.16 1001.15 2135.17 1001.05C2135.31 1001.11 2135.47 1001.16 2135.59 1001.26C2135.63 1001.29 2135.58 1001.45 2135.57 1001.55Z" fill="white"/>
                    <path d="M2347.9 1104.21C2347.87 1104.06 2347.85 1103.91 2347.82 1103.75C2347.92 1103.76 2348.08 1103.74 2348.1 1103.79C2348.17 1103.93 2348.18 1104.09 2348.21 1104.24C2348.11 1104.23 2348.01 1104.22 2347.89 1104.21H2347.9Z" fill="white"/>
                    <path d="M2393.75 1085.76C2393.74 1085.88 2393.73 1085.98 2393.72 1086.08C2393.58 1086.02 2393.42 1085.97 2393.3 1085.87C2393.26 1085.84 2393.31 1085.68 2393.32 1085.58C2393.47 1085.64 2393.61 1085.7 2393.75 1085.75V1085.76Z" fill="white"/>
                    <path d="M2323.29 1144.91C2323.44 1144.88 2323.59 1144.86 2323.75 1144.83C2323.74 1144.93 2323.76 1145.09 2323.71 1145.11C2323.57 1145.18 2323.41 1145.19 2323.26 1145.22C2323.27 1145.12 2323.28 1145.02 2323.29 1144.9V1144.91Z" fill="white"/>
                    <path d="M2274.54 1138.34C2274.6 1138.2 2274.65 1138.04 2274.75 1137.92C2274.78 1137.88 2274.94 1137.93 2275.04 1137.94C2274.98 1138.08 2274.93 1138.24 2274.83 1138.36C2274.8 1138.4 2274.64 1138.35 2274.54 1138.34Z" fill="white"/>
                    <path d="M2231.25 1166.62C2231.37 1166.63 2231.47 1166.64 2231.57 1166.65C2231.51 1166.79 2231.46 1166.95 2231.36 1167.07C2231.33 1167.11 2231.17 1167.06 2231.07 1167.05C2231.13 1166.9 2231.19 1166.76 2231.24 1166.62H2231.25Z" fill="white"/>
                    <path d="M2253.32 1226.46C2253.29 1226.31 2253.27 1226.16 2253.24 1226C2253.34 1226.01 2253.5 1225.99 2253.52 1226.04C2253.59 1226.18 2253.6 1226.34 2253.63 1226.49C2253.53 1226.48 2253.43 1226.47 2253.31 1226.46H2253.32Z" fill="white"/>
                    <path d="M2340.67 1133.44C2340.68 1133.32 2340.69 1133.22 2340.7 1133.12C2340.84 1133.18 2341 1133.23 2341.12 1133.33C2341.16 1133.36 2341.11 1133.52 2341.1 1133.62C2340.95 1133.56 2340.81 1133.5 2340.67 1133.45V1133.44Z" fill="white"/>
                    <path d="M2266.22 1106.13C2266.21 1106.25 2266.2 1106.35 2266.19 1106.45C2266.05 1106.39 2265.89 1106.34 2265.77 1106.24C2265.73 1106.21 2265.78 1106.05 2265.79 1105.95C2265.94 1106.01 2266.08 1106.07 2266.22 1106.12V1106.13Z" fill="white"/>
                    <path d="M2139.79 1192.57C2139.69 1192.56 2139.52 1192.57 2139.51 1192.54C2139.48 1192.39 2139.44 1192.2 2139.5 1192.07C2139.62 1191.8 2139.81 1191.82 2139.87 1192.11C2139.9 1192.25 2139.82 1192.42 2139.78 1192.57H2139.79Z" fill="white"/>
                    <path d="M2139.54 1191.67C2139.58 1191.52 2139.57 1191.26 2139.65 1191.23C2139.89 1191.14 2140.04 1191.26 2139.98 1191.44C2139.93 1191.6 2139.81 1191.74 2139.72 1191.89C2139.66 1191.82 2139.6 1191.74 2139.54 1191.67Z" fill="white"/>
                    <path d="M2254.53 1017.13C2254.5 1016.98 2254.48 1016.83 2254.45 1016.67C2254.55 1016.68 2254.71 1016.66 2254.73 1016.71C2254.8 1016.85 2254.81 1017.01 2254.84 1017.16C2254.74 1017.15 2254.64 1017.14 2254.52 1017.13H2254.53Z" fill="white"/>
                    <path d="M2197.77 1263.35C2197.74 1263.2 2197.72 1263.05 2197.69 1262.89C2197.79 1262.9 2197.95 1262.88 2197.97 1262.93C2198.04 1263.07 2198.05 1263.23 2198.08 1263.38C2197.98 1263.37 2197.88 1263.36 2197.76 1263.35H2197.77Z" fill="white"/>
                    <path d="M2131.34 1192.45C2131.35 1192.33 2131.36 1192.23 2131.37 1192.13C2131.51 1192.19 2131.67 1192.24 2131.79 1192.34C2131.83 1192.37 2131.78 1192.53 2131.77 1192.63C2131.62 1192.57 2131.48 1192.51 2131.34 1192.46V1192.45Z" fill="white"/>
                    <path d="M2239.24 1242.52C2239.27 1242.67 2239.29 1242.82 2239.32 1242.98C2239.22 1242.97 2239.06 1242.99 2239.04 1242.94C2238.97 1242.8 2238.96 1242.64 2238.93 1242.49C2239.03 1242.5 2239.13 1242.51 2239.25 1242.52H2239.24Z" fill="white"/>
                    <path d="M2186.91 1096.36C2186.88 1096.21 2186.86 1096.06 2186.83 1095.9C2186.93 1095.91 2187.09 1095.89 2187.11 1095.94C2187.18 1096.08 2187.19 1096.24 2187.22 1096.39C2187.12 1096.38 2187.02 1096.37 2186.9 1096.36H2186.91Z" fill="white"/>
                    <path d="M2310.3 1175.79C2310.42 1175.8 2310.52 1175.81 2310.62 1175.82C2310.56 1175.96 2310.51 1176.12 2310.41 1176.24C2310.38 1176.28 2310.22 1176.23 2310.12 1176.22C2310.18 1176.07 2310.24 1175.93 2310.29 1175.79H2310.3Z" fill="white"/>
                    <path d="M2149.53 1160.32C2149.39 1160.26 2149.23 1160.21 2149.11 1160.11C2149.07 1160.08 2149.12 1159.92 2149.13 1159.82C2149.27 1159.88 2149.43 1159.93 2149.55 1160.03C2149.59 1160.06 2149.54 1160.22 2149.53 1160.32Z" fill="white"/>
                    <path d="M2301.54 1186.56C2301.51 1186.41 2301.49 1186.26 2301.46 1186.1C2301.56 1186.11 2301.72 1186.09 2301.74 1186.14C2301.81 1186.28 2301.82 1186.44 2301.85 1186.59L2301.53 1186.56H2301.54Z" fill="white"/>
                    <path d="M2255.01 1071.44C2254.86 1071.47 2254.71 1071.49 2254.55 1071.52C2254.56 1071.42 2254.54 1071.26 2254.59 1071.24C2254.73 1071.17 2254.89 1071.16 2255.04 1071.13C2255.03 1071.23 2255.02 1071.33 2255.01 1071.45V1071.44Z" fill="white"/>
                    <path d="M2316.23 937.29C2316.09 937.23 2315.93 937.18 2315.81 937.08C2315.77 937.05 2315.82 936.89 2315.83 936.79C2315.97 936.85 2316.13 936.9 2316.25 937C2316.29 937.03 2316.24 937.19 2316.23 937.29Z" fill="white"/>
                    <path d="M2276.24 1112.38C2276.52 1114.21 2275.32 1115.02 2274.17 1115.76C2273.75 1116.03 2273.04 1116.35 2272.87 1116.57C2271.81 1117.97 2270.85 1116.13 2269.9 1116.61C2269.57 1115.61 2268.84 1114.53 2269.03 1113.64C2269.21 1112.79 2269.53 1111.76 2270.12 1111C2270.69 1110.28 2271.15 1109.02 2272.47 1110.19C2272.66 1110.36 2273.2 1110.14 2273.57 1110.13C2275.24 1110.13 2276.12 1110.92 2276.23 1112.38H2276.24Z" fill="white"/>
                    <path d="M2154.72 1024.46C2155.82 1024.39 2156.65 1025.21 2156.67 1025.6C2156.74 1027.09 2158.14 1027.59 2158.66 1028.74C2159.02 1029.55 2159.11 1030.1 2158.51 1030.71C2157.52 1031.72 2156.89 1031.88 2156.02 1031.32C2155.64 1031.08 2155.26 1030.89 2154.85 1030.71C2153.09 1029.92 2152.13 1026.12 2153.95 1024.85C2154.27 1024.62 2154.54 1024.43 2154.72 1024.46Z" fill="white"/>
                    <path d="M2176.28 1111.36C2176.99 1112.02 2178.37 1111.71 2178.56 1113.03C2178.59 1113.22 2178.53 1113.44 2178.62 1113.59C2178.98 1114.2 2179.59 1114.72 2179.23 1115.55C2178.95 1116.19 2178.27 1116.35 2177.74 1116.66C2176.69 1117.29 2175.02 1116.84 2174.21 1115.76C2173.58 1114.92 2173.55 1114.08 2174 1113.11C2174.46 1112.1 2175.49 1111.91 2176.29 1111.36H2176.28Z" fill="white"/>
                    <path d="M2062.97 1181.35C2061.33 1181.47 2060.54 1180.92 2060.31 1179.75C2060.18 1179.1 2060.22 1178.43 2060.13 1177.77C2060.05 1177.15 2060.3 1176.94 2060.89 1176.93C2061.55 1176.92 2062.22 1176.74 2062.87 1176.81C2063.97 1176.92 2064.75 1177.61 2065.27 1178.56C2065.38 1178.75 2065.37 1179.16 2065.24 1179.31C2064.75 1179.87 2064.49 1180.53 2064.13 1181.15C2063.95 1181.45 2063.09 1181.35 2062.97 1181.36V1181.35Z" fill="white"/>
                    <path d="M2035.69 1060.02C2036.43 1059.9 2037.47 1059.71 2037.87 1060.84C2038.06 1061.38 2038.41 1062.38 2037.75 1062.64C2037.03 1062.92 2036.46 1063.83 2035.52 1063.43C2034.87 1063.15 2034.28 1062.63 2033.62 1062.51C2033.01 1062.41 2032.87 1062.22 2032.82 1061.67C2032.75 1060.88 2033.18 1060.41 2033.79 1060.19C2034.31 1060.01 2034.91 1060.07 2035.68 1060.01L2035.69 1060.02Z" fill="white"/>
                    <path d="M2062.87 1201.93C2063.62 1201.86 2064.3 1202.39 2064.4 1203.13C2064.43 1203.37 2063.29 1205.81 2063.09 1205.92C2062.64 1206.16 2061.11 1205.34 2061.18 1205.28C2062.12 1204.55 2060.74 1204.08 2061.03 1203.47C2061.31 1202.89 2062.39 1201.98 2062.87 1201.94V1201.93Z" fill="white"/>
                    <path d="M2223.81 1151.05C2223.79 1150.66 2223.64 1150.06 2224.02 1150.03C2224.71 1149.97 2225.45 1149.59 2226.16 1150.19C2226.55 1150.52 2226.52 1151.04 2226.84 1151.32C2227.36 1151.77 2227.47 1152.12 2227.01 1152.71C2226.52 1153.35 2225.84 1153.5 2225.31 1153.16C2224.57 1152.69 2223.63 1152.2 2223.8 1151.05H2223.81Z" fill="white"/>
                    <path d="M2152.36 1082.96C2151.76 1082.96 2151.02 1083.33 2150.97 1082.2C2150.97 1081.95 2150.43 1081.72 2150.16 1081.46C2149.85 1081.16 2149.57 1080.84 2149.28 1080.53C2149.91 1080.42 2150.62 1080.07 2151.14 1080.26C2151.8 1080.5 2152.29 1081.2 2152.88 1081.66C2153.21 1081.91 2153.46 1082.21 2153.33 1082.59C2153.18 1083.01 2152.73 1082.93 2152.37 1082.96H2152.36Z" fill="white"/>
                    <path d="M2173.62 1233.34C2173.44 1233.35 2173.25 1233.39 2173.07 1233.38C2172.66 1233.35 2172.1 1233.71 2171.9 1233.07C2171.7 1232.42 2171.85 1231.84 2172.39 1231.34C2172.85 1230.92 2173.16 1231.12 2173.57 1231.41C2173.83 1231.6 2174.26 1231.57 2174.53 1231.75C2174.88 1232 2175.38 1232.23 2175.29 1232.79C2175.18 1233.46 2174.59 1233.19 2174.19 1233.28C2174.01 1233.32 2173.82 1233.31 2173.64 1233.33L2173.62 1233.34Z" fill="white"/>
                    <path d="M2054.16 1196.36C2053.79 1197.15 2053.65 1198.08 2052.91 1198.66C2052.74 1198.79 2052.39 1198.83 2052.15 1198.57C2051.85 1198.24 2051.24 1198.04 2051.42 1197.5C2051.64 1196.84 2052.01 1196.22 2052.37 1195.62C2052.81 1194.91 2053.03 1195.88 2053.42 1195.8C2053.83 1195.71 2054.15 1195.83 2054.16 1196.35V1196.36Z" fill="white"/>
                    <path d="M2198.24 1076.54C2197.73 1076.66 2197.41 1076.52 2197.39 1075.89C2197.36 1074.91 2197.84 1074.32 2198.7 1074.22C2199.47 1074.13 2199.93 1074.57 2200.16 1075.15C2200.43 1075.82 2199.48 1076.48 2198.23 1076.55L2198.24 1076.54Z" fill="white"/>
                    <path d="M2240.31 1002.41C2240.03 1002.43 2239.74 1002.5 2239.46 1002.47C2238.82 1002.38 2238.56 1001.63 2238.97 1001.14C2239.21 1000.85 2239.42 1000.51 2239.72 1000.29C2240.1 1000.01 2240.67 999.94 2241.02 1000.18C2241.42 1000.46 2241.36 1001.18 2241.88 1001.46C2241.48 1001.81 2241.31 1002.48 2240.59 1002.39C2240.5 1002.38 2240.4 1002.4 2240.31 1002.41Z" fill="white"/>
                    <path d="M2105.56 1184.9C2105.81 1185.12 2106.02 1185.31 2106.24 1185.48C2106.81 1185.9 2106.22 1186.78 2106.94 1187.17C2107.32 1187.38 2107.35 1187.8 2107.04 1188.1C2106.74 1188.38 2106.39 1188.3 2106.08 1188.01C2105.67 1187.63 2105.21 1187.3 2104.82 1186.9C2104.63 1186.71 2104.05 1186.63 2104.5 1186.14C2104.87 1185.74 2105.21 1185.32 2105.56 1184.91V1184.9Z" fill="white"/>
                    <path d="M2005.63 1123.38C2005.67 1122.78 2006.56 1122.15 2007.24 1122.23C2007.51 1122.26 2008.58 1123.65 2008.52 1123.98C2008.36 1124.8 2007.64 1124.47 2007.17 1124.5C2006.31 1124.56 2005.58 1123.96 2005.62 1123.38H2005.63Z" fill="white"/>
                    <path d="M2068.8 1075.98C2068.93 1076.55 2068.83 1076.91 2068.18 1076.93C2067.39 1076.96 2066.56 1076.47 2066.52 1075.9C2066.48 1075.34 2067.23 1074.7 2068.02 1074.65C2069.2 1074.57 2068.6 1075.53 2068.8 1075.98Z" fill="white"/>
                    <path d="M2071.9 1069.78C2072.36 1070.02 2072.89 1070.25 2072.97 1070.84C2073.06 1071.48 2072 1072.22 2071.23 1072.12C2070.72 1072.05 2070.73 1071.68 2070.74 1071.32C2070.75 1070.48 2071.31 1069.75 2071.9 1069.78Z" fill="white"/>
                    <path d="M2087.9 1155.41C2087.53 1155.44 2087.34 1155.43 2087.16 1155.47C2086.48 1155.61 2086.23 1154.99 2085.78 1154.72C2086.18 1154.38 2086.33 1153.73 2087.02 1153.76C2087.2 1153.76 2087.39 1153.75 2087.57 1153.72C2088.19 1153.62 2088.59 1153.67 2088.64 1154.5C2088.69 1155.29 2088.39 1155.5 2087.88 1155.42L2087.9 1155.41Z" fill="white"/>
                    <path d="M2092.58 992.05C2092.67 992.71 2092.25 992.88 2091.91 992.76C2091.25 992.52 2090.62 992.16 2090.01 991.8C2089.44 991.46 2089.99 991.24 2090.15 990.99C2090.82 989.92 2091.4 991.12 2092.05 991.09C2092.8 991.05 2092.42 991.79 2092.57 992.05H2092.58Z" fill="white"/>
                    <path d="M2074.43 1123.87C2075.19 1123.66 2075.67 1124.11 2075.93 1124.69C2076.12 1125.12 2075.07 1126.28 2074.72 1126.12C2074.04 1125.81 2073.71 1125.25 2073.72 1124.47C2073.72 1123.92 2074.07 1123.91 2074.44 1123.87H2074.43Z" fill="white"/>
                    <path d="M2229.4 1201.03C2229.35 1201.59 2228.93 1201.93 2228.47 1202.2C2228.2 1202.36 2227.17 1201.18 2227.08 1200.6C2226.99 1200.08 2227.35 1200 2227.73 1199.98C2228.51 1199.94 2229.34 1200.45 2229.39 1201.02L2229.4 1201.03Z" fill="white"/>
                    <path d="M2055.26 1158.93C2055.32 1158.65 2055.5 1158.36 2055.81 1158.49C2056.54 1158.81 2057.35 1158.94 2057.97 1159.52C2058.23 1159.76 2058.28 1160.08 2058.18 1160.27C2058.09 1160.45 2057.68 1160.67 2057.51 1160.6C2056.85 1160.34 2056.23 1159.98 2055.61 1159.64C2055.34 1159.5 2055.23 1159.24 2055.26 1158.93Z" fill="white"/>
                    <path d="M2028.19 1173.76C2028.49 1173.71 2028.86 1173.64 2029 1174.13C2029.12 1174.55 2028.79 1174.77 2028.61 1175.08C2028.25 1175.7 2027.63 1175.56 2027.14 1175.53C2026.84 1175.51 2026.32 1175.05 2026.35 1174.87C2026.42 1174.5 2026.73 1174.09 2027.05 1173.89C2027.32 1173.72 2027.76 1173.8 2028.2 1173.77L2028.19 1173.76Z" fill="white"/>
                    <path d="M2049.57 1019.67C2049.46 1019.21 2049.61 1018.89 2050.24 1018.88C2051.03 1018.86 2051.83 1019.35 2051.88 1019.91C2051.97 1020.85 2050.74 1020.3 2050.58 1021.04C2050.55 1021.16 2049.68 1020.39 2049.57 1019.67Z" fill="white"/>
                    <path d="M2139.78 1170.81C2139.92 1170.9 2140.15 1170.97 2140.19 1171.1C2140.42 1171.81 2139.5 1173.17 2138.79 1173.16C2138.55 1173.16 2138.19 1173.09 2138.09 1172.92C2137.99 1172.75 2138.07 1172.38 2138.19 1172.18C2138.75 1171.29 2139.3 1170.85 2139.77 1170.8L2139.78 1170.81Z" fill="white"/>
                    <path d="M2234.16 1122.46C2234.14 1122.18 2234.07 1121.9 2234.1 1121.63C2234.19 1120.93 2234.79 1121.2 2235.19 1121.12C2235.71 1121.01 2235.79 1121.38 2235.82 1121.78C2235.84 1122.14 2235.94 1122.49 2235.62 1122.81C2235.3 1123.13 2235.05 1123.62 2234.51 1123.43C2234.09 1123.29 2234.2 1122.82 2234.15 1122.47L2234.16 1122.46Z" fill="white"/>
                    <path d="M2057.89 1157.17C2057.31 1157.1 2057.33 1156.7 2057.34 1156.35C2057.36 1155.5 2057.91 1154.79 2058.51 1154.84C2059.01 1154.88 2059 1155.29 2058.99 1155.65C2058.97 1156.5 2058.42 1157.19 2057.89 1157.17Z" fill="white"/>
                    <path d="M2041.58 1019.85C2042.03 1019.37 2042.32 1018.81 2043.12 1018.83C2043.78 1018.85 2043.82 1019.17 2043.88 1019.67C2043.94 1020.21 2043.84 1020.46 2043.23 1020.59C2042.43 1020.75 2042.1 1020.17 2041.58 1019.85Z" fill="white"/>
                    <path d="M2055.08 1015.07C2055.23 1015.15 2055.49 1015.21 2055.52 1015.33C2055.6 1015.69 2055.74 1016.14 2055.59 1016.41C2055.35 1016.85 2055.09 1017.43 2054.42 1017.4C2053.91 1017.38 2053.91 1016.97 2053.92 1016.6C2053.95 1015.74 2054.46 1015.07 2055.08 1015.06V1015.07Z" fill="white"/>
                    <path d="M2248.22 1158.39C2248.61 1158.37 2249.02 1158.36 2248.98 1158.89C2248.92 1159.58 2248.53 1160.13 2247.82 1160.1C2247.45 1160.08 2247.1 1159.63 2246.74 1159.37C2247.16 1158.92 2247.44 1158.26 2248.22 1158.39Z" fill="white"/>
                    <path d="M2195.75 1017.7C2195.75 1018.38 2195.03 1019.14 2194.56 1019.1C2194.06 1019.05 2194.12 1018.65 2194.07 1018.3C2193.97 1017.59 2194.32 1017.09 2194.93 1016.94C2195.11 1016.9 2195.73 1017.33 2195.76 1017.7H2195.75Z" fill="white"/>
                    <path d="M2064.55 1105.78C2065.02 1105.74 2065.52 1105.73 2065.41 1106.23C2065.28 1106.84 2064.91 1107.41 2064.65 1107.99C2064.13 1107.6 2063.69 1107.22 2063.72 1106.44C2063.75 1105.71 2064.24 1105.85 2064.55 1105.78Z" fill="white"/>
                    <path d="M2133.79 981.55C2134.56 981.4 2134.64 981.77 2134.72 982.31C2134.85 983.14 2134.42 983.21 2133.78 983.26C2133.13 983.31 2133.04 983 2132.97 982.46C2132.86 981.66 2133.21 981.45 2133.78 981.55H2133.79Z" fill="white"/>
                    <path d="M2058.82 1116.46C2058.77 1116.96 2059.21 1117.73 2058.24 1117.67C2057.72 1117.64 2057.23 1117.25 2057.12 1116.67C2056.94 1115.7 2058.01 1115.9 2058.41 1115.61C2058.91 1115.25 2058.6 1116.19 2058.82 1116.46Z" fill="white"/>
                    <path d="M2017.51 1189.45C2017.79 1189.43 2018.07 1189.37 2018.34 1189.4C2018.83 1189.45 2018.81 1189.86 2018.81 1190.22C2018.81 1190.48 2018.98 1190.79 2018.68 1190.98C2018.34 1191.2 2018.01 1191.17 2017.72 1190.87C2017.59 1190.74 2017.44 1190.63 2017.3 1190.51C2017.04 1190.27 2016.54 1189.98 2016.58 1189.79C2016.65 1189.37 2017.17 1189.52 2017.51 1189.45Z" fill="white"/>
                    <path d="M2241.43 1046.74C2241.58 1046.08 2242.14 1046.3 2242.54 1046.25C2243.29 1046.16 2243.11 1046.78 2243.17 1047.19C2243.22 1047.57 2243.22 1047.95 2242.67 1047.94C2242.11 1047.93 2241.4 1047.22 2241.43 1046.73V1046.74Z" fill="white"/>
                    <path d="M2091.58 1052.56C2091.6 1053.05 2091.62 1053.44 2091.07 1053.43C2090.51 1053.42 2089.77 1052.71 2089.83 1052.23C2089.89 1051.73 2090.31 1051.82 2090.66 1051.74C2091.51 1051.55 2091.58 1052.06 2091.58 1052.56Z" fill="white"/>
                    <path d="M2036.62 1147.62C2036.66 1147.43 2036.62 1147.04 2036.77 1146.95C2037.17 1146.72 2037.39 1147.19 2037.54 1147.4C2037.76 1147.72 2038.8 1147.74 2038.05 1148.51C2037.8 1148.77 2037.6 1149.12 2037.18 1149.03C2036.33 1148.86 2036.88 1148.11 2036.61 1147.61L2036.62 1147.62Z" fill="white"/>
                    <path d="M2167.12 1214.42C2167.5 1214.65 2168.29 1214.85 2167.98 1215.33C2167.71 1215.75 2168.04 1216.47 2167.37 1216.58C2166.97 1216.65 2166.3 1215.31 2166.46 1214.79C2166.54 1214.52 2166.78 1214.38 2167.11 1214.41L2167.12 1214.42Z" fill="white"/>
                    <path d="M2137.13 998.91C2137.13 998.91 2137.53 998.6 2137.93 998.38C2138.11 998.29 2138.92 999.3 2138.88 999.56C2138.78 1000.26 2138.2 999.99 2137.81 1000.07C2137.33 1000.17 2137.18 999.87 2137.12 998.9L2137.13 998.91Z" fill="white"/>
                    <path d="M2170.59 1000.96C2171.25 1001.1 2171.01 1001.66 2171.08 1002.06C2171.18 1002.56 2170.8 1002.78 2170.45 1002.66C2170.05 1002.53 2169.69 1002.2 2169.37 1001.9C2169.26 1001.79 2170.33 1000.93 2170.58 1000.96H2170.59Z" fill="white"/>
                    <path d="M2284.55 1131.43C2284.69 1131.91 2284.47 1132.13 2284.2 1132.15C2283.47 1132.2 2283.05 1131.77 2282.84 1131.12C2282.73 1130.78 2282.95 1130.57 2283.23 1130.54C2283.98 1130.46 2284.35 1130.95 2284.55 1131.44V1131.43Z" fill="white"/>
                    <path d="M2129.66 1189.94C2129.7 1189.34 2130.05 1188.86 2130.53 1188.82C2130.77 1188.8 2131.34 1189.2 2131.37 1189.75C2131.41 1190.5 2130.83 1190.32 2130.43 1190.38C2130.05 1190.44 2129.7 1190.41 2129.66 1189.93V1189.94Z" fill="white"/>
                    <path d="M2259.3 1041.37C2259.36 1041.63 2258.68 1042.78 2258.55 1042.68C2258.2 1042.41 2257.83 1042.1 2257.65 1041.73C2257.48 1041.38 2257.68 1041.02 2258.17 1041.04C2258.57 1041.06 2259.1 1040.71 2259.31 1041.38L2259.3 1041.37Z" fill="white"/>
                    <path d="M2124.25 1192.05C2124.76 1192.17 2125.3 1192.46 2125.34 1193.22C2125.35 1193.5 2125.18 1193.75 2124.83 1193.69C2124.15 1193.58 2123.66 1193.23 2123.6 1192.51C2123.58 1192.24 2123.76 1192 2124.25 1192.06V1192.05Z" fill="white"/>
                    <path d="M2115.26 1255.01C2115.78 1254.94 2115.99 1255.29 2115.88 1255.64C2115.75 1256.04 2115.42 1256.4 2115.12 1256.72C2115.01 1256.83 2114.15 1255.76 2114.18 1255.51C2114.22 1255.15 2114.64 1254.96 2115.26 1255.01Z" fill="white"/>
                    <path d="M2063.05 1114.47C2063.45 1114.45 2063.84 1114.42 2063.84 1114.95C2063.84 1115.53 2063.47 1116 2062.98 1116.04C2062.74 1116.06 2062.17 1115.66 2062.14 1115.11C2062.1 1114.36 2062.69 1114.57 2063.06 1114.47H2063.05Z" fill="white"/>
                    <path d="M2045.7 1202.76C2046.19 1202.97 2046.62 1203.21 2046.73 1203.78C2046.8 1204.12 2046.6 1204.35 2046.32 1204.33C2045.86 1204.3 2045.18 1204.8 2045.02 1203.89C2044.93 1203.33 2045.5 1203.17 2045.69 1202.75L2045.7 1202.76Z" fill="white"/>
                    <path d="M2073.17 1122.83C2073.31 1122.92 2073.46 1123.01 2073.6 1123.1C2073.08 1123.73 2072.44 1124.29 2072.33 1125.18C2072.33 1125.22 2071.96 1125.26 2071.79 1125.21C2071.69 1125.18 2071.57 1124.88 2071.6 1124.85C2072.15 1124.33 2071.94 1123.39 2072.66 1122.98C2072.81 1122.89 2073 1122.88 2073.18 1122.83H2073.17Z" fill="white"/>
                    <path d="M2074.48 1127.06C2074.48 1127.62 2073.74 1127.37 2073.31 1127.36C2072.45 1127.34 2072.84 1126.57 2072.73 1126.12C2072.7 1125.99 2072.96 1125.45 2073.15 1125.91C2073.43 1126.59 2074.52 1125.68 2074.48 1127.06Z" fill="white"/>
                    <path d="M2249.27 1103.61C2249.11 1102.94 2249.74 1102.7 2250 1102.26C2250.27 1102.48 2250.77 1102.69 2250.78 1102.93C2250.81 1103.45 2250.42 1103.82 2249.88 1103.97C2249.56 1104.06 2249.39 1103.87 2249.27 1103.62V1103.61Z" fill="white"/>
                    <path d="M2132.96 1004.05C2132.93 1004.45 2133.22 1005 2132.46 1005.08C2131.91 1005.14 2131.86 1004.78 2131.85 1004.4C2131.85 1003.98 2131.59 1003.44 2132.34 1003.36C2132.89 1003.3 2132.92 1003.66 2132.96 1004.05Z" fill="white"/>
                    <path d="M2253.72 1179.19C2254.54 1179.64 2254.75 1180.21 2254.69 1180.88C2254.68 1181.04 2254.53 1181.18 2254.45 1181.33C2254.31 1181.21 2254.19 1181.06 2254.03 1180.98C2253.42 1180.67 2253.47 1180.09 2253.47 1179.56C2253.47 1179.4 2253.69 1179.23 2253.72 1179.19Z" fill="white"/>
                    <path d="M2135.66 975.07C2135.8 975.05 2135.9 975.4 2135.9 975.79C2135.9 976.2 2136.18 976.75 2135.42 976.84C2134.89 976.9 2134.8 976.56 2134.8 976.17C2134.8 975.76 2134.54 975.21 2135.67 975.07H2135.66Z" fill="white"/>
                    <path d="M2111.48 1030.34C2111.42 1030.45 2111.37 1030.68 2111.29 1030.69C2110.66 1030.8 2110.04 1030.89 2109.55 1030.28C2109.35 1030.03 2109.26 1029.86 2109.55 1029.71C2109.99 1029.47 2111.33 1029.91 2111.49 1030.34H2111.48Z" fill="white"/>
                    <path d="M2213.8 1071.92C2214.69 1072.08 2214.18 1072.82 2214.31 1073.29C2214.37 1073.5 2213.96 1073.69 2213.67 1073.63C2212.84 1073.46 2213.23 1072.74 2213.15 1072.27C2213.11 1072.05 2213.48 1071.88 2213.8 1071.92Z" fill="white"/>
                    <path d="M2105.87 1095.15C2106.76 1095.31 2106.25 1096.05 2106.38 1096.52C2106.44 1096.73 2106.03 1096.92 2105.74 1096.86C2104.91 1096.69 2105.3 1095.97 2105.22 1095.5C2105.18 1095.28 2105.55 1095.11 2105.87 1095.15Z" fill="white"/>
                    <path d="M2183.67 1195.95C2183.81 1195.93 2183.91 1196.28 2183.91 1196.67C2183.91 1197.08 2184.19 1197.63 2183.43 1197.72C2182.9 1197.78 2182.81 1197.44 2182.81 1197.05C2182.81 1196.64 2182.55 1196.09 2183.68 1195.95H2183.67Z" fill="white"/>
                    <path d="M2031.1 1089.7C2030.6 1090.02 2030.09 1090.36 2029.57 1090.68C2029.55 1090.69 2029.4 1090.58 2029.37 1090.51C2029.26 1090.13 2029.37 1089.83 2029.66 1089.53C2029.98 1089.2 2030.25 1088.81 2030.77 1089.08C2030.89 1089.14 2030.93 1089.37 2031.1 1089.7Z" fill="white"/>
                    <path d="M2053 1137.25C2052.94 1136.87 2052.86 1136.48 2053.37 1136.37C2054.02 1136.24 2054.13 1136.65 2054.14 1137.17C2054.14 1137.54 2054.27 1137.94 2053.76 1138.04C2053.11 1138.17 2052.97 1137.77 2053 1137.24V1137.25Z" fill="white"/>
                    <path d="M2088.44 1182.73C2088.95 1182.85 2089.2 1182.96 2089.14 1183.31C2089.03 1183.99 2088.68 1184.47 2087.96 1184.54C2087.82 1184.55 2087.66 1184.39 2087.51 1184.3C2087.83 1183.76 2088.16 1183.21 2088.44 1182.73Z" fill="white"/>
                    <path d="M2090.26 1069.53C2090.76 1069.42 2091.15 1069.5 2091.15 1069.73C2091.15 1070.21 2091.65 1070.86 2090.85 1071.16C2090.58 1071.26 2090.19 1071.16 2090.16 1070.91C2090.11 1070.43 2089.79 1069.83 2090.26 1069.53Z" fill="white"/>
                    <path d="M2200.12 1186.7C2200.51 1186.69 2200.87 1186.66 2200.9 1187.21C2200.94 1187.97 2200.36 1187.8 2199.95 1187.85C2199.57 1187.9 2199.21 1187.9 2199.18 1187.35C2199.14 1186.59 2199.72 1186.79 2200.12 1186.7Z" fill="white"/>
                    <path d="M2193.62 1100.77C2193.78 1099.88 2194.52 1100.39 2194.99 1100.26C2195.2 1100.2 2195.39 1100.61 2195.33 1100.9C2195.16 1101.73 2194.44 1101.34 2193.97 1101.42C2193.75 1101.46 2193.58 1101.09 2193.62 1100.77Z" fill="white"/>
                    <path d="M2078.47 1185.57C2078.41 1185.19 2078.33 1184.8 2078.84 1184.69C2079.49 1184.56 2079.6 1184.97 2079.61 1185.49C2079.61 1185.86 2079.74 1186.26 2079.23 1186.36C2078.58 1186.49 2078.44 1186.09 2078.47 1185.56V1185.57Z" fill="white"/>
                    <path d="M2052.64 1029.26C2052.53 1028.62 2052.71 1028.44 2053.01 1028.39C2053.67 1028.27 2053.76 1028.7 2053.75 1029.2C2053.75 1029.46 2053.85 1029.98 2053.63 1029.91C2053.19 1029.78 2052.83 1029.39 2052.65 1029.25L2052.64 1029.26Z" fill="white"/>
                    <path d="M2232.13 1170.48C2232.27 1170.54 2232.51 1170.63 2232.75 1170.72C2232.4 1171.09 2232.23 1171.71 2231.57 1171.68C2231.29 1171.67 2231.1 1171.51 2231.08 1171.16C2231.05 1170.42 2231.6 1170.58 2232.13 1170.48Z" fill="white"/>
                    <path d="M2031.2 1217.04C2031.27 1217.28 2031.08 1217.49 2030.8 1217.55C2030.43 1217.63 2030.02 1217.6 2030.05 1217.07C2030.07 1216.69 2029.67 1216.08 2030.32 1216.02C2030.55 1216 2031.13 1216.41 2031.2 1217.05V1217.04Z" fill="white"/>
                    <path d="M2012.35 1042.39C2013 1042.26 2013.25 1042.87 2013.68 1043.16C2013.45 1043.27 2013.23 1043.45 2012.99 1043.48C2012.59 1043.52 2012.05 1043.76 2011.96 1043.01C2011.92 1042.67 2012.07 1042.52 2012.36 1042.39H2012.35Z" fill="white"/>
                    <path d="M2062.73 1211.37C2062.85 1211.74 2062.83 1211.33 2062.54 1211.57C2062.38 1211.7 2061.98 1211.72 2061.83 1211.61C2061.43 1211.29 2060.83 1211.07 2060.96 1210.38C2060.97 1210.3 2061.08 1210.17 2061.12 1210.18C2061.84 1210.33 2062.17 1211.02 2062.73 1211.38V1211.37Z" fill="white"/>
                    <path d="M2101.46 1175.02C2101.83 1175.39 2102.47 1175.62 2102.29 1176.29C2102.25 1176.45 2101.84 1176.63 2101.63 1176.61C2100.95 1176.53 2101.22 1175.93 2101.13 1175.53C2101.07 1175.26 2101.13 1175.03 2101.46 1175.02Z" fill="white"/>
                    <path d="M2147.82 1096.46C2148.01 1096.45 2148.2 1096.39 2148.37 1096.43C2148.48 1096.46 2148.64 1096.68 2148.62 1096.76C2148.53 1097.06 2146.78 1097.21 2146.58 1096.94C2146.53 1096.88 2146.64 1096.57 2146.71 1096.56C2147.07 1096.49 2147.45 1096.48 2147.82 1096.46Z" fill="white"/>
                    <path d="M2155.47 1250.75C2156.05 1250.67 2156.17 1251.01 2156.15 1251.4C2156.14 1251.69 2155.99 1251.87 2155.63 1251.89C2155.11 1251.93 2154.99 1251.61 2155.01 1251.22C2155.02 1250.95 2155.14 1250.67 2155.46 1250.74L2155.47 1250.75Z" fill="white"/>
                    <path d="M2028.46 1033.14C2028.76 1033.2 2028.94 1033.32 2028.97 1033.67C2029.01 1034.19 2028.69 1034.3 2028.3 1034.29C2028.01 1034.29 2027.83 1034.13 2027.81 1033.77C2027.77 1033.24 2028.09 1033.17 2028.46 1033.14Z" fill="white"/>
                    <path d="M2237.01 1047.24C2237.31 1047.3 2237.49 1047.42 2237.52 1047.77C2237.56 1048.29 2237.24 1048.4 2236.85 1048.39C2236.56 1048.39 2236.38 1048.23 2236.36 1047.87C2236.32 1047.34 2236.64 1047.27 2237.01 1047.24Z" fill="white"/>
                    <path d="M2256.59 1153.64C2256.61 1153.99 2256.59 1154.32 2256.06 1154.36C2255.71 1154.39 2255.53 1154.24 2255.47 1153.95C2255.4 1153.57 2255.46 1153.24 2255.98 1153.2C2256.33 1153.17 2256.48 1153.32 2256.58 1153.64H2256.59Z" fill="white"/>
                    <path d="M2117.96 986.57C2118.14 986.08 2118.4 985.67 2118.95 985.56C2119.09 985.53 2119.41 985.68 2119.41 985.69C2119.3 986.21 2119.02 986.61 2118.47 986.72C2118.32 986.75 2118.15 986.63 2117.97 986.58L2117.96 986.57Z" fill="white"/>
                    <path d="M2215.14 1016.93C2215.47 1016.84 2215.65 1017.05 2215.7 1017.33C2215.77 1017.71 2215.71 1018.04 2215.19 1018.08C2214.84 1018.11 2214.66 1017.96 2214.6 1017.67C2214.53 1017.28 2214.59 1016.93 2215.13 1016.92L2215.14 1016.93Z" fill="white"/>
                    <path d="M2106.91 1049.91C2107.21 1049.97 2107.39 1050.09 2107.42 1050.44C2107.46 1050.96 2107.14 1051.07 2106.75 1051.06C2106.46 1051.06 2106.28 1050.9 2106.26 1050.54C2106.22 1050.01 2106.54 1049.94 2106.91 1049.91Z" fill="white"/>
                    <path d="M2092.8 1244.17C2093.15 1244.24 2093.33 1244.36 2093.36 1244.71C2093.4 1245.23 2093.08 1245.34 2092.69 1245.33C2092.4 1245.33 2092.23 1245.16 2092.2 1244.81C2092.15 1244.27 2092.5 1244.24 2092.8 1244.17Z" fill="white"/>
                    <path d="M2086.42 1221.79C2086.5 1222.33 2086.16 1222.44 2085.76 1222.43C2085.47 1222.42 2085.29 1222.27 2085.27 1221.91C2085.23 1221.39 2085.55 1221.28 2085.94 1221.29C2086.23 1221.29 2086.46 1221.45 2086.42 1221.79Z" fill="white"/>
                    <path d="M2146.16 958.86C2145.79 958.88 2145.41 958.89 2145.42 958.36C2145.42 958.2 2145.5 958.03 2145.55 957.87C2145.89 958.09 2146.23 958.31 2146.57 958.53C2146.43 958.64 2146.3 958.74 2146.16 958.85V958.86Z" fill="white"/>
                    <path d="M2168.34 1005.13C2168.71 1005.11 2169.09 1005.1 2169.08 1005.63C2169.08 1005.79 2169 1005.96 2168.95 1006.12C2168.61 1005.9 2168.27 1005.68 2167.93 1005.46C2168.07 1005.35 2168.2 1005.25 2168.34 1005.14V1005.13Z" fill="white"/>
                    <path d="M2044.12 1048.02C2044.42 1048.26 2044.7 1048.49 2044.98 1048.72C2044.75 1048.83 2044.53 1049.03 2044.3 1049.03C2043.8 1049.03 2043.79 1048.61 2043.82 1048.25C2043.82 1048.15 2044.03 1048.07 2044.12 1048.01V1048.02Z" fill="white"/>
                    <path d="M2194.26 1108.8C2194.2 1109.01 2194.17 1109.31 2194.1 1109.31C2193.6 1109.37 2193.44 1108.86 2193.09 1108.65C2193.23 1108.55 2193.35 1108.38 2193.5 1108.35C2193.88 1108.27 2194.21 1108.35 2194.26 1108.79V1108.8Z" fill="white"/>
                    <path d="M2282.19 1127.25C2282.31 1127.32 2282.52 1127.37 2282.55 1127.47C2282.64 1127.82 2282.69 1128.24 2282.19 1128.3C2281.96 1128.33 2281.71 1128.17 2281.47 1128.09C2281.71 1127.81 2281.94 1127.54 2282.19 1127.25Z" fill="white"/>
                    <path d="M2258.48 1111.85C2258.42 1111.34 2258.77 1111.26 2259.15 1111.27C2259.3 1111.27 2259.45 1111.42 2259.6 1111.51C2259.3 1111.78 2259 1112.05 2258.69 1112.32C2258.62 1112.17 2258.55 1112.01 2258.48 1111.85Z" fill="white"/>
                    <path d="M2065.81 1056.07C2065.88 1056.1 2066.1 1056.15 2066.12 1056.24C2066.21 1056.59 2066.27 1057 2065.78 1057.09C2065.56 1057.13 2065.3 1056.96 2065.06 1056.89C2065.31 1056.63 2065.55 1056.36 2065.82 1056.08L2065.81 1056.07Z" fill="white"/>
                    <path d="M2102.63 1054.81C2103.14 1054.75 2103.22 1055.1 2103.21 1055.48C2103.21 1055.63 2103.06 1055.78 2102.97 1055.93C2102.7 1055.63 2102.43 1055.33 2102.16 1055.02C2102.31 1054.95 2102.47 1054.88 2102.63 1054.81Z" fill="white"/>
                    <path d="M2265.29 1065.62C2265.45 1065.7 2265.6 1065.78 2265.75 1065.85C2265.46 1066.13 2265.18 1066.42 2264.89 1066.7C2264.81 1066.55 2264.7 1066.41 2264.67 1066.25C2264.57 1065.75 2264.9 1065.65 2265.29 1065.62Z" fill="white"/>
                    <path d="M2102.97 1214.22C2102.91 1213.71 2103.26 1213.63 2103.64 1213.64C2103.79 1213.64 2103.94 1213.79 2104.09 1213.88C2103.79 1214.15 2103.49 1214.42 2103.18 1214.69C2103.11 1214.54 2103.04 1214.38 2102.97 1214.22Z" fill="white"/>
                    <path d="M2108.24 1253C2108.43 1252.86 2108.56 1252.7 2108.71 1252.68C2109.1 1252.61 2109.44 1252.69 2109.42 1253.19C2109.42 1253.35 2109.32 1253.51 2109.27 1253.67C2108.93 1253.45 2108.59 1253.23 2108.23 1253H2108.24Z" fill="white"/>
                    <path d="M1993.2 1076.54C1993.55 1076.7 1993.45 1077.02 1993.45 1077.27C1993.45 1077.35 1993.2 1077.52 1993.12 1077.5C1992.78 1077.38 1992.88 1077.04 1992.88 1076.79C1992.88 1076.71 1993.09 1076.62 1993.2 1076.54Z" fill="white"/>
                    <path d="M2013.35 1061.98C2013.64 1061.87 2013.88 1061.73 2014.12 1061.7C2014.26 1061.68 2014.42 1061.85 2014.57 1061.93C2014.34 1062.04 2014.12 1062.2 2013.88 1062.25C2013.75 1062.27 2013.58 1062.1 2013.35 1061.97V1061.98Z" fill="white"/>
                    <path d="M2205.22 1223.54C2205.57 1223.7 2205.47 1224.02 2205.47 1224.27C2205.47 1224.35 2205.22 1224.52 2205.14 1224.5C2204.8 1224.38 2204.9 1224.04 2204.9 1223.79C2204.9 1223.71 2205.11 1223.62 2205.22 1223.54Z" fill="white"/>
                    <path d="M2267.09 1137.41C2267.2 1137.7 2267.34 1137.94 2267.37 1138.18C2267.39 1138.32 2267.22 1138.48 2267.14 1138.63C2267.03 1138.4 2266.87 1138.18 2266.82 1137.94C2266.8 1137.81 2266.97 1137.64 2267.1 1137.41H2267.09Z" fill="white"/>
                    <path d="M2266.49 1134.41C2266.49 1134.56 2266.53 1134.82 2266.48 1135.06C2266.42 1135.34 2266.21 1135.36 2266.12 1135.09C2266.04 1134.86 2266.02 1134.59 2266.06 1134.35C2266.11 1134.07 2266.29 1134.07 2266.49 1134.42V1134.41Z" fill="white"/>
                    <path d="M2221.34 1229.62C2221.39 1229.46 2221.42 1229.18 2221.5 1229.16C2221.83 1229.08 2221.93 1229.32 2221.9 1229.6C2221.88 1229.76 2221.83 1230.04 2221.74 1230.06C2221.41 1230.14 2221.33 1229.9 2221.33 1229.62H2221.34Z" fill="white"/>
                    <path d="M2033.77 1036.73C2033.93 1036.78 2034.21 1036.81 2034.23 1036.89C2034.31 1037.22 2034.07 1037.32 2033.79 1037.29C2033.63 1037.27 2033.35 1037.22 2033.33 1037.13C2033.25 1036.8 2033.49 1036.72 2033.77 1036.72V1036.73Z" fill="white"/>
                    <path d="M2255.68 1062.03C2255.97 1061.92 2256.21 1061.78 2256.45 1061.75C2256.59 1061.73 2256.75 1061.9 2256.9 1061.98C2256.67 1062.09 2256.45 1062.25 2256.21 1062.3C2256.08 1062.32 2255.91 1062.15 2255.68 1062.02V1062.03Z" fill="white"/>
                    <path d="M2041.22 1189.35C2041.33 1189.41 2041.48 1189.49 2041.63 1189.58C2041.4 1189.69 2041.18 1189.85 2040.94 1189.9C2040.81 1189.92 2040.64 1189.75 2040.49 1189.67C2040.65 1189.43 2040.87 1189.3 2041.22 1189.35Z" fill="white"/>
                    <path d="M2250.12 1018.78C2250.17 1019.06 2250.12 1019.31 2249.79 1019.28C2249.7 1019.28 2249.61 1019.01 2249.56 1018.85C2249.49 1018.58 2249.56 1018.32 2249.89 1018.36C2249.98 1018.36 2250.05 1018.64 2250.12 1018.79V1018.78Z" fill="white"/>
                    <path d="M2079.75 1187.46C2079.8 1187.74 2079.75 1187.99 2079.42 1187.96C2079.33 1187.96 2079.24 1187.69 2079.19 1187.53C2079.12 1187.26 2079.19 1187 2079.52 1187.04C2079.61 1187.04 2079.68 1187.32 2079.75 1187.47V1187.46Z" fill="white"/>
                    <path d="M2087.61 1178.28C2087.66 1178.56 2087.61 1178.81 2087.28 1178.78C2087.19 1178.78 2087.1 1178.51 2087.05 1178.35C2086.98 1178.08 2087.05 1177.82 2087.38 1177.86C2087.47 1177.86 2087.54 1178.14 2087.61 1178.29V1178.28Z" fill="white"/>
                    <path d="M2111.48 1140.67C2111.77 1140.56 2112.01 1140.42 2112.25 1140.39C2112.39 1140.37 2112.55 1140.54 2112.7 1140.62C2112.47 1140.73 2112.25 1140.89 2112.01 1140.94C2111.88 1140.96 2111.71 1140.79 2111.48 1140.66V1140.67Z" fill="white"/>
                    <path d="M2056.64 1052.63C2056.84 1052.71 2057.07 1052.81 2057.3 1052.91C2057.16 1053.02 2057.02 1053.21 2056.89 1053.21C2056.65 1053.21 2056.4 1053.08 2056.16 1053C2056.3 1052.89 2056.43 1052.79 2056.63 1052.63H2056.64Z" fill="white"/>
                    <path d="M2145.67 1069.79C2145.78 1069.85 2145.93 1069.93 2146.08 1070.02C2145.85 1070.13 2145.63 1070.29 2145.39 1070.34C2145.26 1070.36 2145.09 1070.19 2144.94 1070.11C2145.1 1069.87 2145.32 1069.74 2145.67 1069.79Z" fill="white"/>
                    <path d="M2149.42 1025.7C2149.71 1025.59 2149.95 1025.45 2150.19 1025.42C2150.33 1025.41 2150.49 1025.57 2150.64 1025.65C2150.41 1025.76 2150.19 1025.92 2149.95 1025.97C2149.82 1025.99 2149.65 1025.82 2149.42 1025.69V1025.7Z" fill="white"/>
                    <path d="M2154.77 956.55C2155.12 956.71 2155.02 957.03 2155.02 957.28C2155.02 957.36 2154.77 957.53 2154.69 957.51C2154.35 957.39 2154.45 957.05 2154.45 956.8C2154.45 956.72 2154.66 956.63 2154.77 956.55Z" fill="white"/>
                    <path d="M2186.57 1010C2186.62 1010.28 2186.57 1010.53 2186.24 1010.5C2186.15 1010.5 2186.06 1010.23 2186.01 1010.07C2185.94 1009.8 2186.01 1009.54 2186.34 1009.58C2186.43 1009.58 2186.5 1009.86 2186.57 1010.01V1010Z" fill="white"/>
                    <path d="M2193.53 1210.06C2193.58 1209.9 2193.61 1209.62 2193.69 1209.6C2194.02 1209.52 2194.12 1209.76 2194.09 1210.04C2194.07 1210.2 2194.02 1210.48 2193.93 1210.5C2193.6 1210.58 2193.52 1210.34 2193.52 1210.06H2193.53Z" fill="white"/>
                    <path d="M2171.67 1119.76C2171.83 1119.81 2172.11 1119.84 2172.13 1119.92C2172.21 1120.25 2171.97 1120.35 2171.69 1120.32C2171.53 1120.3 2171.25 1120.25 2171.23 1120.16C2171.15 1119.83 2171.39 1119.75 2171.67 1119.75V1119.76Z" fill="white"/>
                    <path d="M2186.36 1117.76C2186.48 1117.69 2186.6 1117.59 2186.73 1117.55C2186.78 1117.54 2186.86 1117.65 2186.93 1117.71C2186.81 1117.78 2186.69 1117.88 2186.56 1117.92C2186.51 1117.93 2186.43 1117.82 2186.36 1117.76Z" fill="white"/>
                    <path d="M2274.6 1061.76C2274.72 1061.69 2274.84 1061.59 2274.97 1061.55C2275.02 1061.54 2275.1 1061.65 2275.17 1061.71C2275.05 1061.78 2274.93 1061.88 2274.8 1061.92C2274.75 1061.93 2274.67 1061.82 2274.6 1061.76Z" fill="white"/>
                    <path d="M2089.78 1166.77C2089.9 1166.83 2090.03 1166.89 2090.16 1166.94C2090.1 1167.01 2090.03 1167.14 2089.99 1167.13C2089.85 1167.11 2089.73 1167.04 2089.59 1166.98C2089.65 1166.91 2089.71 1166.84 2089.78 1166.76V1166.77Z" fill="white"/>
                    <path d="M2080.01 1210.23C2079.93 1210.16 2079.86 1210.1 2079.79 1210.04C2079.91 1209.97 2080.03 1209.87 2080.16 1209.83C2080.21 1209.82 2080.29 1209.93 2080.36 1209.99C2080.24 1210.07 2080.12 1210.15 2080.01 1210.22V1210.23Z" fill="white"/>
                    <path d="M2072.17 1127.68C2072.11 1127.8 2072.05 1127.93 2072 1128.06C2071.93 1128 2071.8 1127.93 2071.81 1127.89C2071.83 1127.75 2071.9 1127.63 2071.96 1127.49C2072.03 1127.55 2072.1 1127.61 2072.18 1127.68H2072.17Z" fill="white"/>
                    <path d="M2102 1094.87C2102.07 1094.99 2102.17 1095.11 2102.21 1095.24C2102.22 1095.29 2102.11 1095.37 2102.05 1095.44C2101.98 1095.32 2101.88 1095.2 2101.84 1095.07C2101.83 1095.02 2101.94 1094.94 2102 1094.87Z" fill="white"/>
                    <path d="M2103.16 1048.29C2103.09 1048.37 2103.03 1048.44 2102.97 1048.51C2102.9 1048.39 2102.8 1048.27 2102.76 1048.14C2102.75 1048.09 2102.86 1048.01 2102.92 1047.94C2103 1048.06 2103.08 1048.18 2103.15 1048.29H2103.16Z" fill="white"/>
                    <path d="M2047.47 1034.04C2047.59 1034.1 2047.72 1034.16 2047.85 1034.21C2047.79 1034.28 2047.72 1034.41 2047.68 1034.4C2047.54 1034.38 2047.42 1034.31 2047.28 1034.25C2047.34 1034.18 2047.4 1034.11 2047.47 1034.03V1034.04Z" fill="white"/>
                    <path d="M2071.8 1146.45C2071.88 1146.52 2071.95 1146.58 2072.02 1146.64C2071.9 1146.71 2071.78 1146.81 2071.65 1146.85C2071.6 1146.86 2071.52 1146.75 2071.45 1146.69C2071.57 1146.61 2071.69 1146.53 2071.8 1146.46V1146.45Z" fill="white"/>
                    <path d="M2130.15 1105.18C2130.07 1105.11 2130 1105.05 2129.93 1104.99C2130.05 1104.92 2130.17 1104.82 2130.3 1104.78C2130.35 1104.77 2130.43 1104.88 2130.5 1104.94C2130.38 1105.02 2130.26 1105.1 2130.15 1105.17V1105.18Z" fill="white"/>
                    <path d="M2130.71 967.15C2130.77 967.08 2130.85 966.95 2130.88 966.96C2131.01 967.02 2131.17 967.08 2131.23 967.19C2131.36 967.42 2131.26 967.54 2131.01 967.45C2130.89 967.4 2130.81 967.25 2130.71 967.15Z" fill="white"/>
                    <path d="M2131.51 967.42C2131.61 967.53 2131.8 967.65 2131.78 967.73C2131.73 967.96 2131.56 968.01 2131.45 967.86C2131.36 967.74 2131.32 967.58 2131.25 967.43C2131.33 967.43 2131.42 967.42 2131.5 967.41L2131.51 967.42Z" fill="white"/>
                    <path d="M2202.17 1142.05C2202.29 1142.11 2202.42 1142.17 2202.55 1142.22C2202.49 1142.29 2202.42 1142.42 2202.38 1142.41C2202.24 1142.39 2202.12 1142.32 2201.98 1142.26C2202.04 1142.19 2202.1 1142.12 2202.17 1142.04V1142.05Z" fill="white"/>
                    <path d="M2048.52 973.94C2048.64 974 2048.77 974.06 2048.9 974.11C2048.84 974.18 2048.77 974.31 2048.73 974.3C2048.59 974.28 2048.47 974.21 2048.33 974.15C2048.39 974.08 2048.45 974.01 2048.52 973.93V973.94Z" fill="white"/>
                    <path d="M2135.12 960.94C2135.2 961.01 2135.27 961.07 2135.34 961.13C2135.22 961.2 2135.1 961.3 2134.97 961.34C2134.92 961.35 2134.84 961.24 2134.77 961.18C2134.89 961.1 2135.01 961.02 2135.12 960.95V960.94Z" fill="white"/>
                    <path d="M2042.76 1015.38C2042.64 1015.32 2042.51 1015.26 2042.38 1015.21C2042.44 1015.14 2042.51 1015.01 2042.55 1015.02C2042.69 1015.04 2042.81 1015.11 2042.95 1015.17C2042.89 1015.24 2042.83 1015.31 2042.76 1015.39V1015.38Z" fill="white"/>
                    <path d="M2177.98 1051.34C2178.1 1051.4 2178.23 1051.46 2178.36 1051.51C2178.3 1051.58 2178.23 1051.71 2178.19 1051.7C2178.05 1051.68 2177.93 1051.61 2177.79 1051.55C2177.85 1051.48 2177.91 1051.41 2177.98 1051.33V1051.34Z" fill="white"/>
                    <path d="M2055.91 1102.25C2055.84 1102.33 2055.78 1102.4 2055.72 1102.47C2055.65 1102.35 2055.55 1102.23 2055.51 1102.1C2055.5 1102.05 2055.61 1101.97 2055.67 1101.9C2055.75 1102.02 2055.83 1102.14 2055.9 1102.25H2055.91Z" fill="white"/>
                    <path d="M2149.65 990.87C2149.77 990.8 2149.89 990.7 2150.02 990.66C2150.07 990.65 2150.15 990.76 2150.22 990.82C2150.1 990.89 2149.98 990.99 2149.85 991.03C2149.8 991.04 2149.72 990.93 2149.65 990.87Z" fill="white"/>
                    <path d="M2052.41 1090.23C2052.53 1090.29 2052.66 1090.35 2052.79 1090.4C2052.73 1090.47 2052.66 1090.6 2052.62 1090.59C2052.48 1090.57 2052.36 1090.5 2052.22 1090.44L2052.41 1090.22V1090.23Z" fill="white"/>
                    <path d="M2161.62 1114.62C2161.68 1114.5 2161.74 1114.37 2161.79 1114.24C2161.86 1114.3 2161.99 1114.37 2161.98 1114.41C2161.96 1114.55 2161.89 1114.67 2161.83 1114.81C2161.76 1114.75 2161.69 1114.69 2161.61 1114.62H2161.62Z" fill="white"/>
                    <path d="M2229.84 1228.69C2229.96 1228.62 2230.08 1228.52 2230.21 1228.48C2230.26 1228.47 2230.34 1228.58 2230.41 1228.64C2230.29 1228.71 2230.17 1228.81 2230.04 1228.85C2229.99 1228.86 2229.91 1228.75 2229.84 1228.69Z" fill="white"/>
                    <path d="M2170.49 1210.91C2169.44 1212.41 2168.03 1212.17 2166.7 1211.91C2166.21 1211.82 2165.49 1211.56 2165.22 1211.6C2163.51 1211.88 2164.08 1209.9 2163.07 1209.6C2163.52 1208.66 2163.74 1207.39 2164.48 1206.88C2165.19 1206.4 2166.12 1205.87 2167.07 1205.74C2167.97 1205.61 2169.16 1205.03 2169.3 1206.77C2169.32 1207.03 2169.86 1207.23 2170.13 1207.48C2171.33 1208.62 2171.41 1209.79 2170.5 1210.91H2170.49Z" fill="white"/>
                    <path d="M2143.64 1064.91C2144.47 1065.61 2144.51 1066.77 2144.26 1067.06C2143.29 1068.18 2143.95 1069.49 2143.54 1070.66C2143.25 1071.48 2142.94 1071.94 2142.09 1071.97C2140.69 1072.02 2140.13 1071.7 2139.89 1070.71C2139.78 1070.28 2139.64 1069.88 2139.47 1069.47C2138.75 1067.7 2140.66 1064.33 2142.83 1064.66C2143.22 1064.72 2143.54 1064.76 2143.65 1064.91H2143.64Z" fill="white"/>
                    <path d="M2099.66 1141.83C2099.72 1142.78 2100.91 1143.5 2100.15 1144.59C2100.04 1144.74 2099.85 1144.87 2099.81 1145.03C2099.65 1145.71 2099.73 1146.51 2098.91 1146.85C2098.27 1147.12 2097.68 1146.76 2097.08 1146.63C2095.9 1146.36 2095.01 1144.9 2095.17 1143.57C2095.29 1142.54 2095.84 1141.92 2096.83 1141.53C2097.85 1141.12 2098.72 1141.69 2099.67 1141.84L2099.66 1141.83Z" fill="white"/>
                    <path d="M1970.73 1114.44C1969.48 1113.4 1969.28 1112.47 1969.92 1111.47C1970.27 1110.92 1970.76 1110.46 1971.15 1109.93C1971.51 1109.43 1971.84 1109.45 1972.27 1109.85C1972.75 1110.29 1973.35 1110.62 1973.77 1111.12C1974.48 1111.95 1974.56 1112.98 1974.29 1114.01C1974.23 1114.22 1973.95 1114.51 1973.76 1114.52C1973.03 1114.58 1972.39 1114.88 1971.71 1115.08C1971.38 1115.17 1970.83 1114.51 1970.73 1114.44Z" fill="white"/>
                    <path d="M2034.17 1008.97C2034.78 1009.39 2035.66 1009.97 2035.17 1011.05C2034.94 1011.56 2034.51 1012.52 2033.85 1012.26C2033.14 1011.97 2032.11 1012.23 2031.72 1011.3C2031.45 1010.66 2031.38 1009.88 2030.99 1009.35C2030.62 1008.86 2030.65 1008.63 2030.99 1008.2C2031.48 1007.58 2032.11 1007.54 2032.7 1007.81C2033.19 1008.03 2033.58 1008.49 2034.17 1008.98V1008.97Z" fill="white"/>
                    <path d="M1956.59 1129.1C1957.17 1129.56 1957.3 1130.41 1956.87 1131C1956.73 1131.2 1954.24 1132.16 1954.03 1132.1C1953.55 1131.97 1953.01 1130.33 1953.1 1130.34C1954.28 1130.46 1953.6 1129.18 1954.23 1128.94C1954.82 1128.72 1956.22 1128.8 1956.59 1129.1Z" fill="white"/>
                    <path d="M2106.53 1202.73C2106.79 1202.43 2107.08 1201.91 2107.38 1202.14C2107.92 1202.57 2108.7 1202.8 2108.8 1203.72C2108.85 1204.23 2108.48 1204.58 2108.52 1204.99C2108.58 1205.67 2108.43 1205.99 2107.69 1206.1C2106.9 1206.22 2106.31 1205.86 2106.17 1205.26C2105.97 1204.42 2105.62 1203.42 2106.53 1202.72V1202.73Z" fill="white"/>
                    <path d="M2101.96 1105.16C2101.53 1104.76 2100.74 1104.51 2101.49 1103.67C2101.66 1103.48 2101.43 1102.96 2101.41 1102.59C2101.39 1102.17 2101.41 1101.74 2101.41 1101.32C2101.93 1101.67 2102.68 1101.9 2102.92 1102.39C2103.23 1103.02 2103.1 1103.85 2103.21 1104.58C2103.27 1104.98 2103.25 1105.37 2102.89 1105.55C2102.5 1105.75 2102.23 1105.38 2101.95 1105.16H2101.96Z" fill="white"/>
                    <path d="M2014.37 1227.29C2014.23 1227.17 2014.07 1227.07 2013.95 1226.94C2013.67 1226.64 2013.03 1226.51 2013.32 1225.92C2013.62 1225.32 2014.13 1225 2014.85 1225.02C2015.47 1225.04 2015.55 1225.39 2015.65 1225.88C2015.71 1226.2 2016.04 1226.46 2016.1 1226.78C2016.19 1227.2 2016.38 1227.7 2015.93 1228.04C2015.39 1228.44 2015.15 1227.85 2014.8 1227.64C2014.64 1227.55 2014.51 1227.41 2014.37 1227.3V1227.29Z" fill="white"/>
                    <path d="M1954.16 1119.16C1953.36 1119.47 1952.62 1120.04 1951.69 1119.95C1951.48 1119.93 1951.2 1119.71 1951.2 1119.37C1951.2 1118.93 1950.91 1118.37 1951.41 1118.1C1952.02 1117.78 1952.7 1117.58 1953.38 1117.41C1954.18 1117.2 1953.67 1118.05 1954.01 1118.26C1954.36 1118.48 1954.52 1118.78 1954.16 1119.16Z" fill="white"/>
                    <path d="M2139.18 1131.93C2138.73 1131.67 2138.6 1131.35 2139.02 1130.88C2139.67 1130.16 2140.41 1130.06 2141.1 1130.58C2141.72 1131.04 2141.74 1131.67 2141.51 1132.25C2141.25 1132.91 2140.11 1132.74 2139.17 1131.93H2139.18Z" fill="white"/>
                    <path d="M2219.97 1107.65C2219.75 1107.47 2219.5 1107.33 2219.33 1107.11C2218.93 1106.61 2219.26 1105.89 2219.89 1105.83C2220.26 1105.79 2220.64 1105.68 2221 1105.74C2221.46 1105.81 2221.92 1106.14 2222 1106.55C2222.1 1107.03 2221.56 1107.49 2221.74 1108.05C2221.21 1108.03 2220.63 1108.4 2220.18 1107.83C2220.12 1107.76 2220.04 1107.71 2219.97 1107.65Z" fill="white"/>
                    <path d="M1998.78 1146.1C1998.81 1146.43 1998.83 1146.71 1998.87 1146.98C1998.99 1147.67 1997.97 1147.9 1998.22 1148.67C1998.35 1149.08 1998.08 1149.41 1997.66 1149.4C1997.26 1149.4 1997.06 1149.1 1997.03 1148.68C1996.99 1148.13 1996.89 1147.58 1996.88 1147.02C1996.88 1146.76 1996.51 1146.3 1997.17 1146.25C1997.71 1146.21 1998.24 1146.15 1998.77 1146.09L1998.78 1146.1Z" fill="white"/>
                    <path d="M1969.33 1033.76C1969.77 1033.36 1970.84 1033.51 1971.27 1034.04C1971.44 1034.25 1971.27 1035.97 1970.99 1036.16C1970.31 1036.64 1970.02 1035.91 1969.67 1035.61C1969.01 1035.06 1968.9 1034.14 1969.33 1033.75V1033.76Z" fill="white"/>
                    <path d="M2046.94 1043.03C2046.64 1043.53 2046.33 1043.72 2045.85 1043.29C2045.26 1042.77 2045.01 1041.85 2045.36 1041.42C2045.71 1040.99 2046.69 1041.05 2047.29 1041.55C2048.19 1042.3 2047.1 1042.58 2046.94 1043.03Z" fill="white"/>
                    <path d="M2053.4 1040.72C2053.57 1041.21 2053.78 1041.73 2053.44 1042.21C2053.07 1042.73 2051.8 1042.54 2051.32 1041.94C2051.01 1041.54 2051.26 1041.28 2051.52 1041.03C2052.1 1040.44 2053 1040.3 2053.4 1040.73V1040.72Z" fill="white"/>
                    <path d="M2006.31 1112.92C2006.03 1112.69 2005.89 1112.56 2005.74 1112.46C2005.16 1112.1 2005.4 1111.48 2005.27 1110.98C2005.79 1111.01 2006.34 1110.65 2006.81 1111.15C2006.94 1111.28 2007.08 1111.4 2007.23 1111.5C2007.74 1111.85 2008 1112.16 2007.47 1112.79C2006.96 1113.39 2006.61 1113.33 2006.3 1112.93L2006.31 1112.92Z" fill="white"/>
                    <path d="M2121.35 999.24C2120.97 999.78 2120.55 999.6 2120.39 999.29C2120.08 998.67 2119.88 997.98 2119.69 997.31C2119.51 996.68 2120.06 996.9 2120.34 996.83C2121.55 996.52 2121.15 997.78 2121.64 998.2C2122.21 998.68 2121.43 998.95 2121.36 999.25L2121.35 999.24Z" fill="white"/>
                    <path d="M2018.23 1081.15C2018.92 1081.52 2018.95 1082.18 2018.74 1082.76C2018.58 1083.2 2017.04 1083.31 2016.89 1082.95C2016.61 1082.26 2016.77 1081.64 2017.3 1081.08C2017.68 1080.69 2017.93 1080.91 2018.23 1081.14V1081.15Z" fill="white"/>
                    <path d="M2076.36 1242.32C2075.94 1242.68 2075.41 1242.65 2074.9 1242.52C2074.59 1242.45 2074.67 1240.9 2075 1240.43C2075.29 1240 2075.6 1240.19 2075.89 1240.43C2076.47 1240.94 2076.72 1241.86 2076.37 1242.31L2076.36 1242.32Z" fill="white"/>
                    <path d="M1980.54 1093.12C1980.78 1092.96 1981.11 1092.87 1981.23 1093.18C1981.54 1093.91 1982.03 1094.56 1982.07 1095.4C1982.09 1095.75 1981.91 1096.01 1981.71 1096.08C1981.52 1096.15 1981.08 1096.02 1981.01 1095.86C1980.72 1095.22 1980.52 1094.54 1980.3 1093.88C1980.21 1093.59 1980.3 1093.34 1980.53 1093.13L1980.54 1093.12Z" fill="white"/>
                    <path d="M1951.04 1085.23C1951.28 1085.4 1951.6 1085.6 1951.37 1086.04C1951.17 1086.42 1950.78 1086.35 1950.44 1086.45C1949.76 1086.64 1949.41 1086.13 1949.08 1085.76C1948.88 1085.54 1948.82 1084.86 1948.96 1084.75C1949.26 1084.53 1949.76 1084.45 1950.14 1084.53C1950.46 1084.59 1950.71 1084.96 1951.04 1085.23Z" fill="white"/>
                    <path d="M2071.69 989.59C2071.92 989.19 2072.25 989.06 2072.71 989.48C2073.29 990.01 2073.52 990.9 2073.18 991.34C2072.6 992.08 2072.1 990.84 2071.47 991.26C2071.36 991.33 2071.27 990.18 2071.68 989.59H2071.69Z" fill="white"/>
                    <path d="M2032.9 1159.41C2032.94 1159.57 2033.06 1159.78 2033 1159.9C2032.68 1160.56 2031.09 1160.91 2030.59 1160.42C2030.42 1160.25 2030.21 1159.96 2030.25 1159.77C2030.3 1159.58 2030.6 1159.37 2030.83 1159.31C2031.84 1159.06 2032.54 1159.11 2032.9 1159.4V1159.41Z" fill="white"/>
                    <path d="M2133.49 1189.35C2133.66 1189.14 2133.81 1188.88 2134.02 1188.72C2134.56 1188.29 2134.81 1188.88 2135.15 1189.1C2135.6 1189.38 2135.4 1189.7 2135.15 1190C2134.92 1190.27 2134.75 1190.59 2134.31 1190.6C2133.86 1190.61 2133.35 1190.79 2133.09 1190.29C2132.89 1189.9 2133.28 1189.64 2133.49 1189.36V1189.35Z" fill="white"/>
                    <path d="M1983.63 1093.67C1983.26 1093.22 1983.55 1092.95 1983.8 1092.7C1984.4 1092.11 1985.28 1091.97 1985.67 1092.42C1986 1092.79 1985.71 1093.08 1985.46 1093.33C1984.86 1093.92 1984 1094.05 1983.63 1093.67Z" fill="white"/>
                    <path d="M2065.84 984.26C2066.49 984.22 2067.09 984.02 2067.64 984.59C2068.1 985.06 2067.91 985.32 2067.61 985.71C2067.28 986.14 2067.04 986.25 2066.51 985.92C2065.82 985.49 2065.99 984.85 2065.84 984.26Z" fill="white"/>
                    <path d="M2078.76 990.07C2078.81 990.23 2078.96 990.45 2078.9 990.55C2078.72 990.86 2078.5 991.28 2078.21 991.37C2077.74 991.52 2077.15 991.76 2076.69 991.28C2076.34 990.92 2076.62 990.62 2076.88 990.37C2077.49 989.77 2078.31 989.64 2078.76 990.06V990.07Z" fill="white"/>
                    <path d="M2118.99 1224.67C2119.28 1224.92 2119.59 1225.2 2119.19 1225.54C2118.68 1225.99 2118.02 1226.12 2117.54 1225.62C2117.29 1225.36 2117.35 1224.79 2117.27 1224.36C2117.87 1224.33 2118.53 1224.04 2119 1224.67H2118.99Z" fill="white"/>
                    <path d="M2177.63 1088.13C2177.17 1088.62 2176.13 1088.67 2175.82 1088.32C2175.49 1087.95 2175.81 1087.7 2176.01 1087.41C2176.43 1086.84 2177.02 1086.72 2177.55 1087.03C2177.71 1087.12 2177.85 1087.85 2177.62 1088.14L2177.63 1088.13Z" fill="white"/>
                    <path d="M2023.53 1061.45C2023.89 1061.75 2024.26 1062.08 2023.84 1062.36C2023.32 1062.71 2022.68 1062.86 2022.09 1063.1C2021.98 1062.47 2021.93 1061.89 2022.48 1061.36C2023.01 1060.86 2023.26 1061.3 2023.52 1061.46L2023.53 1061.45Z" fill="white"/>
                    <path d="M2158.01 1019.89C2158.66 1020.31 2158.46 1020.63 2158.16 1021.07C2157.69 1021.75 2157.33 1021.51 2156.84 1021.11C2156.34 1020.71 2156.49 1020.42 2156.81 1019.99C2157.28 1019.35 2157.67 1019.43 2158.02 1019.89H2158.01Z" fill="white"/>
                    <path d="M2012.13 1065.18C2011.75 1065.5 2011.54 1066.35 2010.89 1065.65C2010.54 1065.27 2010.45 1064.66 2010.78 1064.17C2011.31 1063.35 2011.94 1064.22 2012.43 1064.29C2013.04 1064.37 2012.17 1064.84 2012.14 1065.18H2012.13Z" fill="white"/>
                    <path d="M1932.67 1089.15C1932.88 1089.32 1933.13 1089.47 1933.3 1089.68C1933.62 1090.06 1933.32 1090.33 1933.08 1090.59C1932.9 1090.78 1932.81 1091.11 1932.47 1091.04C1932.08 1090.97 1931.87 1090.72 1931.86 1090.3C1931.86 1090.12 1931.82 1089.94 1931.81 1089.75C1931.79 1089.4 1931.63 1088.86 1931.78 1088.74C1932.12 1088.49 1932.39 1088.95 1932.68 1089.14L1932.67 1089.15Z" fill="white"/>
                    <path d="M2190.46 1140.13C2191.02 1139.76 2191.27 1140.3 2191.59 1140.54C2192.19 1140.99 2191.63 1141.31 2191.4 1141.64C2191.18 1141.95 2190.91 1142.22 2190.53 1141.84C2190.14 1141.45 2190.11 1140.46 2190.46 1140.13Z" fill="white"/>
                    <path d="M2079.26 1041.85C2078.94 1042.21 2078.69 1042.51 2078.3 1042.13C2077.91 1041.74 2077.86 1040.73 2078.24 1040.42C2078.62 1040.11 2078.86 1040.45 2079.16 1040.64C2079.89 1041.08 2079.6 1041.5 2079.26 1041.85Z" fill="white"/>
                    <path d="M1974.94 1072.3C1975.1 1072.19 1975.34 1071.89 1975.51 1071.93C1975.95 1072.04 1975.79 1072.53 1975.75 1072.78C1975.69 1073.16 1976.42 1073.89 1975.35 1073.93C1975 1073.94 1974.61 1074.06 1974.37 1073.71C1973.88 1073 1974.78 1072.85 1974.94 1072.3Z" fill="white"/>
                    <path d="M2022.65 1209.31C2022.77 1209.73 2023.2 1210.42 2022.65 1210.55C2022.18 1210.67 2021.91 1211.41 2021.36 1211.03C2021.03 1210.81 2021.46 1209.39 2021.93 1209.13C2022.18 1208.99 2022.44 1209.06 2022.65 1209.31Z" fill="white"/>
                    <path d="M2148.53 1034.6C2148.53 1034.6 2149.03 1034.65 2149.46 1034.77C2149.65 1034.82 2149.54 1036.1 2149.34 1036.27C2148.8 1036.7 2148.56 1036.12 2148.22 1035.9C2147.81 1035.64 2147.91 1035.33 2148.53 1034.6Z" fill="white"/>
                    <path d="M2171.07 1058.95C2171.45 1059.5 2170.89 1059.73 2170.67 1060.07C2170.4 1060.5 2169.98 1060.39 2169.81 1060.07C2169.61 1059.7 2169.58 1059.22 2169.56 1058.79C2169.56 1058.63 2170.91 1058.76 2171.07 1058.95Z" fill="white"/>
                    <path d="M2163.41 1230.22C2163.18 1230.66 2162.88 1230.67 2162.67 1230.5C2162.12 1230.04 2162.11 1229.44 2162.4 1228.83C2162.55 1228.51 2162.86 1228.51 2163.07 1228.68C2163.66 1229.14 2163.59 1229.74 2163.4 1230.22H2163.41Z" fill="white"/>
                    <path d="M2012.57 1166.19C2013.01 1165.79 2013.59 1165.68 2013.96 1165.99C2014.14 1166.14 2014.28 1166.81 2013.92 1167.23C2013.44 1167.79 2013.15 1167.27 2012.82 1167.04C2012.51 1166.82 2012.28 1166.56 2012.57 1166.19Z" fill="white"/>
                    <path d="M2206.91 1148.51C2206.77 1148.74 2205.5 1149.09 2205.48 1148.93C2205.41 1148.5 2205.36 1148.03 2205.48 1147.63C2205.6 1147.26 2205.99 1147.14 2206.33 1147.49C2206.61 1147.78 2207.22 1147.89 2206.91 1148.51Z" fill="white"/>
                    <path d="M2007.26 1163.99C2007.54 1164.43 2007.73 1165 2007.24 1165.57C2007.06 1165.78 2006.76 1165.84 2006.55 1165.56C2006.14 1165.02 2006.03 1164.43 2006.48 1163.87C2006.65 1163.66 2006.94 1163.61 2007.26 1163.99Z" fill="white"/>
                    <path d="M1957.79 1202.9C1958.21 1203.2 1958.12 1203.6 1957.79 1203.78C1957.42 1203.98 1956.94 1204.01 1956.51 1204.03C1956.35 1204.03 1956.48 1202.68 1956.67 1202.52C1956.94 1202.29 1957.38 1202.44 1957.78 1202.9H1957.79Z" fill="white"/>
                    <path d="M2016.51 1066.64C2016.81 1066.89 2017.11 1067.14 2016.74 1067.52C2016.34 1067.93 2015.75 1068.02 2015.38 1067.71C2015.2 1067.56 2015.06 1066.89 2015.42 1066.47C2015.9 1065.9 2016.18 1066.46 2016.51 1066.64Z" fill="white"/>
                    <path d="M1943.74 1117.95C1943.95 1118.43 1944.09 1118.9 1943.78 1119.39C1943.6 1119.68 1943.3 1119.71 1943.11 1119.51C1942.8 1119.18 1941.97 1119.06 1942.49 1118.31C1942.8 1117.85 1943.33 1118.12 1943.75 1117.96L1943.74 1117.95Z" fill="white"/>
                    <path d="M2018.04 1079.54C2018.08 1079.7 2018.12 1079.87 2018.17 1080.03C2017.37 1080.13 2016.53 1080.09 2015.84 1080.65C2015.81 1080.67 2015.52 1080.45 2015.43 1080.3C2015.38 1080.21 2015.5 1079.91 2015.54 1079.91C2016.29 1079.91 2016.79 1079.1 2017.58 1079.29C2017.75 1079.33 2017.89 1079.45 2018.05 1079.54H2018.04Z" fill="white"/>
                    <path d="M2016.08 1083.47C2015.7 1083.88 2015.34 1083.19 2015.04 1082.89C2014.43 1082.29 2015.24 1082 2015.48 1081.61C2015.55 1081.49 2016.1 1081.29 2015.92 1081.74C2015.66 1082.41 2017.06 1082.51 2016.08 1083.47Z" fill="white"/>
                    <path d="M2157.19 1186.19C2157.53 1185.6 2158.15 1185.86 2158.63 1185.73C2158.68 1186.08 2158.88 1186.56 2158.73 1186.74C2158.39 1187.13 2157.86 1187.13 2157.38 1186.87C2157.09 1186.71 2157.1 1186.47 2157.18 1186.2L2157.19 1186.19Z" fill="white"/>
                    <path d="M2142.03 1035.43C2141.73 1035.7 2141.57 1036.28 2140.97 1035.82C2140.54 1035.49 2140.74 1035.19 2141 1034.91C2141.28 1034.61 2141.46 1034.05 2142.06 1034.5C2142.49 1034.83 2142.27 1035.11 2142.03 1035.42V1035.43Z" fill="white"/>
                    <path d="M2108.7 1243.31C2108.98 1244.19 2108.74 1244.74 2108.23 1245.19C2108.11 1245.29 2107.91 1245.3 2107.75 1245.35C2107.73 1245.17 2107.75 1244.98 2107.69 1244.81C2107.47 1244.17 2107.9 1243.79 2108.27 1243.41C2108.38 1243.29 2108.65 1243.32 2108.7 1243.31Z" fill="white"/>
                    <path d="M2163.78 1016.54C2163.89 1016.62 2163.73 1016.94 2163.46 1017.22C2163.18 1017.51 2163 1018.1 2162.4 1017.64C2161.98 1017.32 2162.15 1017.02 2162.4 1016.74C2162.68 1016.44 2162.87 1015.87 2163.77 1016.55L2163.78 1016.54Z" fill="white"/>
                    <path d="M2108.68 1039.56C2108.56 1039.6 2108.37 1039.73 2108.3 1039.68C2107.77 1039.33 2107.27 1038.97 2107.33 1038.2C2107.35 1037.88 2107.41 1037.71 2107.72 1037.79C2108.2 1037.92 2108.86 1039.15 2108.68 1039.57V1039.56Z" fill="white"/>
                    <path d="M2153.48 1139.26C2154.01 1139.98 2153.14 1140.17 2152.91 1140.58C2152.81 1140.77 2152.38 1140.63 2152.22 1140.39C2151.74 1139.7 2152.51 1139.45 2152.78 1139.06C2152.91 1138.88 2153.28 1139.01 2153.48 1139.26Z" fill="white"/>
                    <path d="M2060.36 1082.1C2060.89 1082.82 2060.02 1083.01 2059.79 1083.42C2059.69 1083.61 2059.26 1083.47 2059.1 1083.23C2058.62 1082.54 2059.39 1082.29 2059.66 1081.9C2059.79 1081.72 2060.16 1081.85 2060.36 1082.1Z" fill="white"/>
                    <path d="M2047.12 1207.41C2047.23 1207.49 2047.07 1207.81 2046.8 1208.09C2046.52 1208.38 2046.34 1208.97 2045.74 1208.51C2045.32 1208.19 2045.49 1207.89 2045.74 1207.61C2046.02 1207.31 2046.21 1206.74 2047.11 1207.42L2047.12 1207.41Z" fill="white"/>
                    <path d="M2010.58 1027.07C2010 1026.96 2009.41 1026.85 2008.82 1026.72C2008.79 1026.72 2008.76 1026.53 2008.8 1026.46C2008.98 1026.11 2009.27 1025.97 2009.67 1025.96C2010.12 1025.94 2010.58 1025.85 2010.77 1026.39C2010.82 1026.52 2010.69 1026.71 2010.58 1027.06V1027.07Z" fill="white"/>
                    <path d="M1993.75 1076.07C1993.96 1075.76 1994.18 1075.42 1994.61 1075.69C1995.17 1076.04 1994.96 1076.41 1994.61 1076.79C1994.37 1077.06 1994.18 1077.43 1993.74 1077.15C1993.19 1076.8 1993.36 1076.42 1993.74 1076.06L1993.75 1076.07Z" fill="white"/>
                    <path d="M1988.02 1132.84C1988.31 1133.27 1988.41 1133.52 1988.13 1133.73C1987.59 1134.14 1987.01 1134.24 1986.44 1133.8C1986.33 1133.71 1986.33 1133.49 1986.28 1133.32C1986.88 1133.15 1987.49 1132.98 1988.02 1132.83V1132.84Z" fill="white"/>
                    <path d="M2066.71 1053.09C2067.14 1053.35 2067.37 1053.68 2067.21 1053.85C2066.88 1054.19 2066.8 1054.99 2066.02 1054.66C2065.76 1054.55 2065.54 1054.21 2065.7 1054.01C2066 1053.63 2066.18 1052.99 2066.71 1053.09Z" fill="white"/>
                    <path d="M2065.22 1212.04C2065.51 1212.3 2065.79 1212.53 2065.43 1212.94C2064.94 1213.51 2064.64 1213 2064.31 1212.75C2064 1212.53 2063.75 1212.28 2064.1 1211.87C2064.59 1211.3 2064.87 1211.84 2065.22 1212.04Z" fill="white"/>
                    <path d="M2119.31 1146.11C2120.03 1145.58 2120.22 1146.45 2120.63 1146.68C2120.82 1146.78 2120.68 1147.21 2120.44 1147.37C2119.75 1147.85 2119.5 1147.08 2119.11 1146.81C2118.93 1146.68 2119.06 1146.31 2119.31 1146.11Z" fill="white"/>
                    <path d="M1978.94 1128.06C1979.15 1127.75 1979.37 1127.41 1979.8 1127.68C1980.36 1128.03 1980.15 1128.4 1979.8 1128.78C1979.56 1129.05 1979.37 1129.42 1978.93 1129.14C1978.38 1128.79 1978.55 1128.41 1978.93 1128.05L1978.94 1128.06Z" fill="white"/>
                    <path d="M2067.33 998.55C2067.69 998.01 2067.94 998.01 2068.19 998.18C2068.74 998.55 2068.51 998.92 2068.16 999.27C2067.98 999.45 2067.7 999.89 2067.58 999.7C2067.35 999.31 2067.36 998.78 2067.32 998.56L2067.33 998.55Z" fill="white"/>
                    <path d="M2099.2 1222.31C2099.26 1222.45 2099.37 1222.67 2099.48 1222.9C2098.97 1222.93 2098.43 1223.25 2097.98 1222.78C2097.79 1222.58 2097.76 1222.34 2097.98 1222.08C2098.46 1221.52 2098.75 1222.03 2099.2 1222.31Z" fill="white"/>
                    <path d="M1923.6 1118.26C1923.48 1118.48 1923.21 1118.5 1922.97 1118.35C1922.66 1118.15 1922.37 1117.85 1922.76 1117.5C1923.03 1117.25 1923.16 1116.53 1923.67 1116.93C1923.85 1117.07 1923.98 1117.77 1923.6 1118.26Z" fill="white"/>
                    <path d="M2029.52 980.4C2030.08 980.75 2029.83 981.36 2029.95 981.86C2029.71 981.78 2029.42 981.76 2029.24 981.61C2028.93 981.36 2028.37 981.17 2028.82 980.57C2029.02 980.3 2029.24 980.29 2029.53 980.4H2029.52Z" fill="white"/>
                    <path d="M1950.04 1135.76C1949.87 1136.11 1950.13 1135.8 1949.77 1135.77C1949.57 1135.75 1949.26 1135.49 1949.24 1135.31C1949.17 1134.81 1948.89 1134.24 1949.46 1133.83C1949.52 1133.78 1949.69 1133.76 1949.71 1133.8C1950.13 1134.4 1949.89 1135.12 1950.04 1135.76Z" fill="white"/>
                    <path d="M2002.6 1136.23C2002.61 1136.75 2002.91 1137.35 2002.32 1137.71C2002.18 1137.8 2001.77 1137.65 2001.63 1137.49C2001.2 1136.97 2001.8 1136.72 2002.01 1136.38C2002.15 1136.14 2002.34 1136.02 2002.6 1136.24V1136.23Z" fill="white"/>
                    <path d="M2089.49 1111.71C2089.63 1111.83 2089.82 1111.92 2089.9 1112.07C2089.96 1112.17 2089.92 1112.43 2089.85 1112.48C2089.58 1112.63 2088.23 1111.55 2088.27 1111.21C2088.27 1111.13 2088.56 1110.99 2088.62 1111.03C2088.93 1111.23 2089.2 1111.48 2089.49 1111.72V1111.71Z" fill="white"/>
                    <path d="M1989.47 1227.34C1989.94 1227.68 1989.79 1228 1989.51 1228.27C1989.31 1228.47 1989.07 1228.49 1988.8 1228.27C1988.4 1227.94 1988.53 1227.64 1988.81 1227.37C1989.01 1227.18 1989.28 1227.07 1989.46 1227.34H1989.47Z" fill="white"/>
                    <path d="M2047.37 984.8C2047.54 985.05 2047.59 985.26 2047.37 985.53C2047.04 985.93 2046.74 985.79 2046.47 985.52C2046.27 985.32 2046.25 985.08 2046.47 984.81C2046.8 984.41 2047.08 984.57 2047.37 984.81V984.8Z" fill="white"/>
                    <path d="M2186.96 1137.47C2187.13 1137.72 2187.18 1137.93 2186.96 1138.2C2186.63 1138.6 2186.33 1138.46 2186.06 1138.19C2185.86 1137.99 2185.84 1137.75 2186.06 1137.48C2186.39 1137.08 2186.67 1137.24 2186.96 1137.48V1137.47Z" fill="white"/>
                    <path d="M2128.22 1226.99C2127.99 1227.26 2127.76 1227.47 2127.35 1227.15C2127.08 1226.93 2127.05 1226.7 2127.21 1226.46C2127.42 1226.14 2127.69 1225.94 2128.09 1226.27C2128.36 1226.49 2128.36 1226.7 2128.22 1227V1226.99Z" fill="white"/>
                    <path d="M2143.25 1012.66C2143.71 1012.43 2144.18 1012.32 2144.65 1012.61C2144.77 1012.69 2144.9 1013.01 2144.89 1013.02C2144.45 1013.31 2143.98 1013.41 2143.51 1013.11C2143.39 1013.03 2143.34 1012.83 2143.25 1012.66Z" fill="white"/>
                    <path d="M2192.02 1100.83C2192.32 1100.99 2192.3 1101.26 2192.15 1101.5C2191.94 1101.83 2191.68 1102.02 2191.27 1101.69C2191 1101.47 2190.97 1101.24 2191.13 1101C2191.34 1100.67 2191.63 1100.47 2192.02 1100.83Z" fill="white"/>
                    <path d="M2092.04 1050.44C2092.21 1050.69 2092.26 1050.9 2092.04 1051.17C2091.71 1051.57 2091.41 1051.43 2091.14 1051.16C2090.94 1050.96 2090.92 1050.72 2091.14 1050.45C2091.47 1050.05 2091.75 1050.21 2092.04 1050.45V1050.44Z" fill="white"/>
                    <path d="M1949.13 1179.78C1949.33 1180.07 1949.38 1180.28 1949.16 1180.55C1948.83 1180.95 1948.53 1180.81 1948.26 1180.54C1948.06 1180.34 1948.04 1180.1 1948.26 1179.83C1948.6 1179.41 1948.87 1179.63 1949.13 1179.78Z" fill="white"/>
                    <path d="M1959.87 1159.41C1959.56 1159.85 1959.24 1159.7 1958.96 1159.42C1958.76 1159.22 1958.74 1158.98 1958.96 1158.71C1959.29 1158.31 1959.59 1158.45 1959.86 1158.72C1960.06 1158.92 1960.12 1159.19 1959.86 1159.4L1959.87 1159.41Z" fill="white"/>
                    <path d="M2182.37 1012.11C2182.1 1011.87 2181.81 1011.62 2182.18 1011.25C2182.29 1011.13 2182.46 1011.07 2182.61 1010.99C2182.7 1011.38 2182.79 1011.77 2182.89 1012.16C2182.72 1012.14 2182.55 1012.13 2182.38 1012.11H2182.37Z" fill="white"/>
                    <path d="M2166.6 1060.39C2166.87 1060.63 2167.16 1060.88 2166.79 1061.25C2166.68 1061.36 2166.51 1061.43 2166.36 1061.51C2166.27 1061.12 2166.18 1060.73 2166.08 1060.34C2166.25 1060.36 2166.42 1060.37 2166.59 1060.39H2166.6Z" fill="white"/>
                    <path d="M2048.4 1006.15C2048.45 1006.53 2048.49 1006.88 2048.53 1007.24C2048.29 1007.16 2047.99 1007.15 2047.83 1007C2047.47 1006.66 2047.75 1006.35 2048.02 1006.12C2048.09 1006.06 2048.29 1006.14 2048.4 1006.15Z" fill="white"/>
                    <path d="M2114.28 1152.29C2114.09 1152.4 2113.87 1152.59 2113.81 1152.55C2113.42 1152.25 2113.65 1151.78 2113.54 1151.39C2113.71 1151.41 2113.91 1151.38 2114.04 1151.46C2114.37 1151.67 2114.55 1151.95 2114.28 1152.29Z" fill="white"/>
                    <path d="M2164.58 1225.61C2164.62 1225.74 2164.73 1225.93 2164.69 1226.01C2164.51 1226.32 2164.27 1226.65 2163.86 1226.36C2163.68 1226.23 2163.61 1225.94 2163.49 1225.72C2163.85 1225.68 2164.21 1225.65 2164.58 1225.61Z" fill="white"/>
                    <path d="M2158.15 1198.38C2158.46 1197.97 2158.77 1198.16 2159.03 1198.42C2159.14 1198.53 2159.14 1198.74 2159.19 1198.9C2158.79 1198.89 2158.39 1198.88 2157.98 1198.86C2158.04 1198.7 2158.09 1198.54 2158.15 1198.38Z" fill="white"/>
                    <path d="M2058.42 1026.75C2058.45 1026.82 2058.57 1027 2058.53 1027.08C2058.35 1027.39 2058.11 1027.73 2057.71 1027.45C2057.52 1027.32 2057.45 1027.03 2057.33 1026.81C2057.69 1026.79 2058.04 1026.77 2058.43 1026.75H2058.42Z" fill="white"/>
                    <path d="M2085.62 1051.02C2086.03 1051.33 2085.84 1051.64 2085.58 1051.9C2085.47 1052.01 2085.26 1052.01 2085.1 1052.06C2085.11 1051.66 2085.12 1051.26 2085.14 1050.85C2085.3 1050.91 2085.46 1050.96 2085.62 1051.02Z" fill="white"/>
                    <path d="M2194.62 1169.96C2194.68 1170.12 2194.74 1170.28 2194.79 1170.44C2194.39 1170.44 2193.99 1170.45 2193.59 1170.46C2193.64 1170.3 2193.65 1170.12 2193.74 1169.98C2194.01 1169.56 2194.32 1169.71 2194.62 1169.95V1169.96Z" fill="white"/>
                    <path d="M1976.88 1165.31C1977.19 1164.9 1977.5 1165.09 1977.76 1165.35C1977.87 1165.46 1977.87 1165.67 1977.92 1165.83C1977.52 1165.82 1977.12 1165.81 1976.71 1165.79C1976.77 1165.63 1976.82 1165.47 1976.88 1165.31Z" fill="white"/>
                    <path d="M1954.14 1196.66C1954.37 1196.69 1954.57 1196.66 1954.7 1196.75C1955.03 1196.96 1955.22 1197.26 1954.86 1197.61C1954.75 1197.72 1954.57 1197.77 1954.42 1197.85C1954.33 1197.46 1954.24 1197.07 1954.14 1196.66Z" fill="white"/>
                    <path d="M1992.47 991.75C1992.61 992.1 1992.32 992.26 1992.15 992.45C1992.1 992.51 1991.79 992.45 1991.76 992.38C1991.6 992.06 1991.9 991.89 1992.08 991.71C1992.14 991.65 1992.35 991.73 1992.48 991.75H1992.47Z" fill="white"/>
                    <path d="M2016.84 995.11C2017.13 995.23 2017.39 995.29 2017.58 995.44C2017.69 995.53 2017.69 995.75 2017.74 995.91C2017.5 995.84 2017.23 995.8 2017.03 995.67C2016.92 995.6 2016.92 995.36 2016.84 995.11Z" fill="white"/>
                    <path d="M2043.67 1241.88C2043.81 1242.24 2043.52 1242.39 2043.35 1242.58C2043.3 1242.64 2042.99 1242.58 2042.96 1242.51C2042.8 1242.19 2043.1 1242.02 2043.28 1241.84C2043.34 1241.78 2043.55 1241.86 2043.68 1241.88H2043.67Z" fill="white"/>
                    <path d="M2146.83 1222.56C2146.71 1222.85 2146.65 1223.11 2146.5 1223.3C2146.41 1223.41 2146.19 1223.41 2146.03 1223.46C2146.1 1223.22 2146.14 1222.95 2146.27 1222.75C2146.34 1222.64 2146.58 1222.64 2146.83 1222.56Z" fill="white"/>
                    <path d="M2148.45 1220C2148.35 1220.11 2148.2 1220.32 2148 1220.45C2147.76 1220.61 2147.61 1220.48 2147.72 1220.22C2147.82 1220 2147.99 1219.79 2148.19 1219.65C2148.42 1219.49 2148.54 1219.6 2148.46 1220H2148.45Z" fill="white"/>
                    <path d="M2051.04 1257.25C2051.19 1257.17 2051.39 1256.99 2051.47 1257.03C2051.76 1257.19 2051.66 1257.44 2051.46 1257.62C2051.34 1257.73 2051.11 1257.89 2051.03 1257.84C2050.74 1257.67 2050.85 1257.45 2051.03 1257.25H2051.04Z" fill="white"/>
                    <path d="M2048.71 991C2048.79 991.15 2048.97 991.35 2048.93 991.43C2048.76 991.72 2048.52 991.62 2048.34 991.42C2048.23 991.3 2048.07 991.07 2048.12 990.99C2048.29 990.7 2048.51 990.81 2048.71 990.99V991Z" fill="white"/>
                    <path d="M2190.2 1160.82C2190.49 1160.94 2190.75 1161 2190.94 1161.15C2191.05 1161.24 2191.05 1161.46 2191.1 1161.62C2190.86 1161.55 2190.59 1161.51 2190.39 1161.38C2190.28 1161.31 2190.28 1161.07 2190.2 1160.82Z" fill="white"/>
                    <path d="M1949.7 1105.29C1949.74 1105.41 1949.79 1105.57 1949.84 1105.73C1949.6 1105.66 1949.33 1105.62 1949.13 1105.49C1949.02 1105.42 1949.02 1105.18 1948.97 1105.02C1949.25 1104.96 1949.5 1105.02 1949.71 1105.29H1949.7Z" fill="white"/>
                    <path d="M2215.79 1126.07C2215.63 1126.3 2215.43 1126.45 2215.21 1126.2C2215.15 1126.13 2215.26 1125.88 2215.34 1125.74C2215.47 1125.5 2215.69 1125.36 2215.91 1125.61C2215.97 1125.68 2215.83 1125.92 2215.78 1126.08L2215.79 1126.07Z" fill="white"/>
                    <path d="M1978.56 1130.29C1978.4 1130.52 1978.2 1130.67 1977.98 1130.42C1977.92 1130.35 1978.03 1130.1 1978.11 1129.96C1978.24 1129.72 1978.46 1129.58 1978.68 1129.83C1978.74 1129.9 1978.6 1130.14 1978.55 1130.3L1978.56 1130.29Z" fill="white"/>
                    <path d="M1990.47 1129.09C1990.31 1129.32 1990.11 1129.47 1989.89 1129.22C1989.83 1129.15 1989.94 1128.9 1990.02 1128.76C1990.15 1128.52 1990.37 1128.38 1990.59 1128.63C1990.65 1128.7 1990.51 1128.94 1990.46 1129.1L1990.47 1129.09Z" fill="white"/>
                    <path d="M2033.26 1118.49C2033.55 1118.61 2033.81 1118.67 2034 1118.82C2034.11 1118.91 2034.11 1119.13 2034.16 1119.29C2033.92 1119.22 2033.65 1119.18 2033.45 1119.05C2033.34 1118.98 2033.34 1118.74 2033.26 1118.49Z" fill="white"/>
                    <path d="M2054.21 1018.01C2054.29 1018.2 2054.39 1018.43 2054.49 1018.66C2054.32 1018.64 2054.09 1018.69 2053.99 1018.59C2053.82 1018.42 2053.73 1018.16 2053.61 1017.94C2053.78 1017.96 2053.95 1017.97 2054.2 1018L2054.21 1018.01Z" fill="white"/>
                    <path d="M2106.18 1091.16C2106.22 1091.28 2106.27 1091.44 2106.32 1091.6C2106.08 1091.53 2105.81 1091.49 2105.61 1091.36C2105.5 1091.29 2105.5 1091.05 2105.45 1090.89C2105.73 1090.83 2105.98 1090.89 2106.19 1091.16H2106.18Z" fill="white"/>
                    <path d="M2139 1062.17C2139.29 1062.29 2139.55 1062.35 2139.74 1062.5C2139.85 1062.59 2139.85 1062.81 2139.9 1062.97C2139.66 1062.9 2139.39 1062.86 2139.19 1062.73C2139.08 1062.66 2139.08 1062.42 2139 1062.17Z" fill="white"/>
                    <path d="M2190.11 1016.36C2190.25 1016.72 2189.96 1016.87 2189.79 1017.06C2189.74 1017.12 2189.43 1017.06 2189.4 1016.99C2189.24 1016.67 2189.54 1016.5 2189.72 1016.32C2189.78 1016.26 2189.99 1016.34 2190.12 1016.36H2190.11Z" fill="white"/>
                    <path d="M2176.32 1076.34C2176.16 1076.57 2175.96 1076.72 2175.74 1076.47C2175.68 1076.4 2175.79 1076.15 2175.87 1076.01C2176 1075.77 2176.22 1075.63 2176.44 1075.88C2176.5 1075.95 2176.36 1076.19 2176.31 1076.35L2176.32 1076.34Z" fill="white"/>
                    <path d="M2044.52 1224.25C2044.67 1224.17 2044.87 1223.99 2044.95 1224.03C2045.24 1224.2 2045.14 1224.44 2044.94 1224.62C2044.82 1224.73 2044.59 1224.89 2044.51 1224.84C2044.22 1224.68 2044.33 1224.45 2044.51 1224.25H2044.52Z" fill="white"/>
                    <path d="M2090.62 1144.68C2090.7 1144.83 2090.88 1145.03 2090.84 1145.11C2090.68 1145.4 2090.43 1145.3 2090.25 1145.1C2090.14 1144.98 2089.98 1144.75 2090.03 1144.67C2090.2 1144.38 2090.42 1144.49 2090.62 1144.67V1144.68Z" fill="white"/>
                    <path d="M2102.5 1153.3C2102.64 1153.33 2102.78 1153.34 2102.9 1153.4C2102.94 1153.42 2102.92 1153.57 2102.93 1153.65C2102.79 1153.62 2102.65 1153.61 2102.53 1153.55C2102.49 1153.53 2102.51 1153.38 2102.5 1153.3Z" fill="white"/>
                    <path d="M2203.92 1173.56C2204.06 1173.59 2204.2 1173.6 2204.32 1173.66C2204.36 1173.68 2204.34 1173.83 2204.35 1173.91C2204.21 1173.88 2204.07 1173.87 2203.95 1173.81C2203.91 1173.79 2203.93 1173.64 2203.92 1173.56Z" fill="white"/>
                    <path d="M1999.88 1122.34C1999.93 1122.47 1999.98 1122.6 2000.03 1122.73C1999.94 1122.73 1999.8 1122.79 1999.77 1122.75C1999.69 1122.64 1999.65 1122.5 1999.59 1122.37C1999.68 1122.37 1999.77 1122.35 1999.87 1122.34H1999.88Z" fill="white"/>
                    <path d="M1963.18 1146.76C1963.17 1146.65 1963.16 1146.57 1963.15 1146.48C1963.29 1146.51 1963.43 1146.52 1963.55 1146.58C1963.59 1146.6 1963.57 1146.75 1963.58 1146.83C1963.44 1146.8 1963.3 1146.78 1963.17 1146.76H1963.18Z" fill="white"/>
                    <path d="M2014.01 1082.33C2013.88 1082.38 2013.75 1082.43 2013.62 1082.48C2013.62 1082.39 2013.56 1082.25 2013.6 1082.22C2013.71 1082.14 2013.85 1082.1 2013.98 1082.04C2013.98 1082.13 2014 1082.22 2014.01 1082.32V1082.33Z" fill="white"/>
                    <path d="M2057.78 1079.25C2057.75 1079.39 2057.74 1079.53 2057.68 1079.65C2057.66 1079.69 2057.51 1079.67 2057.43 1079.68C2057.46 1079.54 2057.47 1079.4 2057.53 1079.28C2057.55 1079.24 2057.7 1079.26 2057.78 1079.25Z" fill="white"/>
                    <path d="M2090.46 1046.71C2090.35 1046.72 2090.27 1046.73 2090.18 1046.74C2090.21 1046.6 2090.22 1046.46 2090.28 1046.34C2090.3 1046.3 2090.45 1046.32 2090.53 1046.31C2090.5 1046.45 2090.48 1046.59 2090.46 1046.72V1046.71Z" fill="white"/>
                    <path d="M2060.36 998.44C2060.41 998.57 2060.46 998.7 2060.51 998.83C2060.42 998.83 2060.28 998.89 2060.25 998.85C2060.17 998.74 2060.13 998.6 2060.07 998.47C2060.16 998.47 2060.25 998.45 2060.35 998.44H2060.36Z" fill="white"/>
                    <path d="M2000.91 1095.51C2000.92 1095.62 2000.93 1095.7 2000.94 1095.79C2000.8 1095.76 2000.66 1095.75 2000.54 1095.69C2000.5 1095.67 2000.52 1095.52 2000.51 1095.44C2000.65 1095.47 2000.79 1095.49 2000.92 1095.51H2000.91Z" fill="white"/>
                    <path d="M2070.88 1105.87C2070.87 1105.76 2070.86 1105.68 2070.85 1105.59C2070.99 1105.62 2071.13 1105.63 2071.25 1105.69C2071.29 1105.71 2071.27 1105.86 2071.28 1105.94C2071.14 1105.91 2071 1105.89 2070.87 1105.87H2070.88Z" fill="white"/>
                    <path d="M2165.65 1007.49C2165.74 1007.49 2165.89 1007.45 2165.9 1007.47C2165.95 1007.6 2166.02 1007.75 2165.99 1007.88C2165.93 1008.14 2165.77 1008.15 2165.66 1007.91C2165.61 1007.79 2165.66 1007.63 2165.66 1007.49H2165.65Z" fill="white"/>
                    <path d="M2166.04 1008.23C2166.04 1008.37 2166.09 1008.59 2166.02 1008.64C2165.83 1008.77 2165.67 1008.69 2165.7 1008.51C2165.72 1008.36 2165.8 1008.22 2165.85 1008.07C2165.91 1008.12 2165.98 1008.18 2166.04 1008.23Z" fill="white"/>
                    <path d="M2097.2 1181.49C2097.25 1181.62 2097.3 1181.75 2097.35 1181.88C2097.26 1181.88 2097.12 1181.94 2097.09 1181.9C2097.01 1181.79 2096.97 1181.65 2096.91 1181.52C2097 1181.52 2097.09 1181.5 2097.19 1181.49H2097.2Z" fill="white"/>
                    <path d="M2102.19 956.16C2102.24 956.29 2102.29 956.42 2102.34 956.55C2102.25 956.55 2102.11 956.61 2102.08 956.57C2102 956.46 2101.96 956.32 2101.9 956.19C2101.99 956.19 2102.08 956.17 2102.18 956.16H2102.19Z" fill="white"/>
                    <path d="M2173.05 1006.06C2173.06 1006.17 2173.07 1006.25 2173.08 1006.34C2172.94 1006.31 2172.8 1006.3 2172.68 1006.24C2172.64 1006.22 2172.66 1006.07 2172.65 1005.99C2172.79 1006.02 2172.93 1006.04 2173.06 1006.06H2173.05Z" fill="white"/>
                    <path d="M2069.74 981.87C2069.69 981.74 2069.64 981.61 2069.59 981.48C2069.68 981.48 2069.82 981.42 2069.85 981.46C2069.93 981.57 2069.97 981.71 2070.03 981.84C2069.94 981.84 2069.85 981.86 2069.75 981.87H2069.74Z" fill="white"/>
                    <path d="M2141.91 1100.04C2141.96 1100.17 2142.01 1100.3 2142.06 1100.43C2141.97 1100.43 2141.83 1100.49 2141.8 1100.45C2141.72 1100.34 2141.68 1100.2 2141.62 1100.07C2141.71 1100.07 2141.8 1100.05 2141.9 1100.04H2141.91Z" fill="white"/>
                    <path d="M2019.76 1053.02C2019.65 1053.03 2019.57 1053.04 2019.48 1053.05C2019.51 1052.91 2019.52 1052.77 2019.58 1052.65C2019.6 1052.61 2019.75 1052.63 2019.83 1052.62C2019.8 1052.76 2019.78 1052.9 2019.76 1053.03V1053.02Z" fill="white"/>
                    <path d="M2162.98 1037.41C2163.12 1037.44 2163.26 1037.45 2163.38 1037.51C2163.42 1037.53 2163.4 1037.68 2163.41 1037.76C2163.27 1037.73 2163.13 1037.72 2163.01 1037.66C2162.97 1037.64 2162.99 1037.49 2162.98 1037.41Z" fill="white"/>
                    <path d="M2025.47 1042.02C2025.52 1042.15 2025.57 1042.28 2025.62 1042.41C2025.53 1042.41 2025.39 1042.47 2025.36 1042.43C2025.28 1042.32 2025.24 1042.18 2025.18 1042.05L2025.46 1042.02H2025.47Z" fill="white"/>
                    <path d="M2086.95 1134.14C2087.08 1134.09 2087.21 1134.04 2087.34 1133.99C2087.34 1134.08 2087.4 1134.22 2087.36 1134.25C2087.25 1134.33 2087.11 1134.37 2086.98 1134.43C2086.98 1134.34 2086.96 1134.25 2086.95 1134.15V1134.14Z" fill="white"/>
                    <path d="M2057.77 1262.4C2057.91 1262.43 2058.05 1262.44 2058.17 1262.5C2058.21 1262.52 2058.19 1262.67 2058.2 1262.75C2058.06 1262.72 2057.92 1262.71 2057.8 1262.65C2057.76 1262.63 2057.78 1262.48 2057.77 1262.4Z" fill="white"/>
                    <path d="M2069.86 1215.58C2068.86 1216.96 2067.55 1216.72 2066.32 1216.46C2065.87 1216.37 2065.19 1216.12 2064.95 1216.15C2063.35 1216.38 2063.91 1214.55 2062.97 1214.25C2063.4 1213.38 2063.63 1212.2 2064.33 1211.74C2065 1211.3 2065.87 1210.83 2066.76 1210.72C2067.6 1210.61 2068.72 1210.09 2068.82 1211.71C2068.84 1211.95 2069.33 1212.14 2069.58 1212.39C2070.68 1213.47 2070.74 1214.56 2069.87 1215.59L2069.86 1215.58Z" fill="white"/>
                    <path d="M2047.05 1079.24C2047.81 1079.91 2047.83 1080.98 2047.59 1081.25C2046.67 1082.27 2047.27 1083.51 2046.86 1084.59C2046.57 1085.35 2046.28 1085.77 2045.49 1085.79C2044.19 1085.81 2043.67 1085.51 2043.46 1084.58C2043.37 1084.18 2043.24 1083.81 2043.09 1083.42C2042.45 1081.76 2044.27 1078.65 2046.29 1078.99C2046.65 1079.05 2046.95 1079.09 2047.05 1079.23V1079.24Z" fill="white"/>
                    <path d="M2004.94 1150.2C2004.98 1151.09 2006.08 1151.78 2005.36 1152.77C2005.26 1152.91 2005.07 1153.02 2005.03 1153.17C2004.87 1153.8 2004.93 1154.54 2004.16 1154.85C2003.56 1155.09 2003.01 1154.75 2002.46 1154.61C2001.36 1154.34 2000.56 1152.96 2000.72 1151.73C2000.85 1150.77 2001.37 1150.2 2002.29 1149.85C2003.25 1149.49 2004.05 1150.03 2004.93 1150.18L2004.94 1150.2Z" fill="white"/>
                    <path d="M1885.31 1122.76C1884.16 1121.77 1883.99 1120.9 1884.6 1119.98C1884.94 1119.47 1885.4 1119.06 1885.77 1118.57C1886.12 1118.11 1886.42 1118.14 1886.81 1118.51C1887.25 1118.93 1887.81 1119.25 1888.19 1119.71C1888.84 1120.5 1888.9 1121.45 1888.63 1122.41C1888.58 1122.61 1888.31 1122.87 1888.12 1122.88C1887.44 1122.93 1886.84 1123.2 1886.2 1123.37C1885.89 1123.45 1885.39 1122.83 1885.3 1122.76H1885.31Z" fill="white"/>
                    <path d="M1945.96 1025.51C1946.52 1025.91 1947.33 1026.46 1946.86 1027.46C1946.64 1027.93 1946.22 1028.82 1945.62 1028.56C1944.97 1028.28 1944 1028.5 1943.65 1027.64C1943.41 1027.04 1943.36 1026.31 1943 1025.81C1942.67 1025.34 1942.69 1025.13 1943.02 1024.74C1943.48 1024.17 1944.07 1024.15 1944.61 1024.4C1945.07 1024.62 1945.42 1025.05 1945.96 1025.51Z" fill="white"/>
                    <path d="M1871.92 1136.2C1872.46 1136.64 1872.56 1137.43 1872.15 1137.97C1872.01 1138.15 1869.68 1139.01 1869.49 1138.95C1869.04 1138.82 1868.57 1137.29 1868.65 1137.3C1869.74 1137.43 1869.14 1136.22 1869.72 1136.02C1870.28 1135.82 1871.57 1135.92 1871.92 1136.2Z" fill="white"/>
                    <path d="M2010.43 1207C2010.67 1206.73 2010.96 1206.24 2011.23 1206.46C2011.72 1206.87 2012.45 1207.1 2012.53 1207.95C2012.57 1208.42 2012.22 1208.74 2012.25 1209.13C2012.3 1209.76 2012.15 1210.06 2011.46 1210.15C2010.72 1210.25 2010.18 1209.91 2010.06 1209.35C2009.88 1208.56 2009.58 1207.63 2010.44 1206.99L2010.43 1207Z" fill="white"/>
                    <path d="M2007.64 1116.09C2007.24 1115.71 2006.52 1115.46 2007.22 1114.69C2007.38 1114.52 2007.17 1114.02 2007.16 1113.68C2007.16 1113.29 2007.17 1112.89 2007.18 1112.5C2007.66 1112.83 2008.36 1113.06 2008.57 1113.52C2008.85 1114.11 2008.72 1114.88 2008.81 1115.57C2008.86 1115.95 2008.83 1116.3 2008.5 1116.47C2008.13 1116.65 2007.89 1116.3 2007.63 1116.09H2007.64Z" fill="white"/>
                    <path d="M1924.25 1228.49C1924.12 1228.38 1923.97 1228.28 1923.86 1228.16C1923.61 1227.88 1923.01 1227.74 1923.29 1227.2C1923.57 1226.64 1924.06 1226.36 1924.73 1226.39C1925.3 1226.41 1925.38 1226.74 1925.46 1227.2C1925.51 1227.5 1925.81 1227.75 1925.87 1228.04C1925.94 1228.43 1926.12 1228.9 1925.69 1229.21C1925.18 1229.58 1924.97 1229.02 1924.65 1228.82C1924.51 1228.73 1924.39 1228.6 1924.26 1228.49H1924.25Z" fill="white"/>
                    <path d="M1869.81 1126.91C1869.06 1127.19 1868.36 1127.71 1867.5 1127.61C1867.3 1127.59 1867.04 1127.38 1867.05 1127.06C1867.06 1126.65 1866.8 1126.12 1867.26 1125.89C1867.83 1125.6 1868.47 1125.43 1869.1 1125.28C1869.84 1125.1 1869.36 1125.88 1869.68 1126.08C1870 1126.29 1870.14 1126.57 1869.81 1126.92V1126.91Z" fill="white"/>
                    <path d="M2041.89 1141.57C2041.48 1141.32 2041.36 1141.02 2041.76 1140.59C2042.38 1139.93 2043.07 1139.85 2043.71 1140.34C2044.28 1140.78 2044.29 1141.37 2044.07 1141.9C2043.81 1142.51 2042.76 1142.34 2041.9 1141.57H2041.89Z" fill="white"/>
                    <path d="M2117.47 1120.18C2117.27 1120.01 2117.03 1119.87 2116.88 1119.67C2116.52 1119.2 2116.83 1118.53 2117.42 1118.48C2117.77 1118.45 2118.12 1118.36 2118.46 1118.41C2118.89 1118.48 2119.31 1118.79 2119.38 1119.18C2119.46 1119.63 2118.96 1120.05 2119.12 1120.57C2118.63 1120.54 2118.09 1120.87 2117.67 1120.34C2117.62 1120.27 2117.54 1120.23 2117.47 1120.17V1120.18Z" fill="white"/>
                    <path d="M1910.95 1152.66C1910.98 1152.97 1910.98 1153.23 1911.02 1153.48C1911.12 1154.12 1910.17 1154.32 1910.39 1155.04C1910.51 1155.42 1910.25 1155.72 1909.86 1155.71C1909.48 1155.71 1909.31 1155.42 1909.29 1155.03C1909.26 1154.51 1909.18 1154 1909.18 1153.49C1909.18 1153.25 1908.85 1152.81 1909.46 1152.78C1909.96 1152.76 1910.46 1152.7 1910.96 1152.66H1910.95Z" fill="white"/>
                    <path d="M1885.22 1047.62C1885.64 1047.25 1886.63 1047.41 1887.02 1047.91C1887.18 1048.11 1886.98 1049.71 1886.73 1049.88C1886.09 1050.32 1885.84 1049.63 1885.51 1049.35C1884.91 1048.83 1884.82 1047.97 1885.22 1047.61V1047.62Z" fill="white"/>
                    <path d="M1957.34 1057.42C1957.06 1057.88 1956.76 1058.05 1956.32 1057.65C1955.78 1057.15 1955.56 1056.3 1955.89 1055.9C1956.23 1055.5 1957.13 1055.57 1957.68 1056.05C1958.51 1056.76 1957.49 1057 1957.33 1057.42H1957.34Z" fill="white"/>
                    <path d="M1963.39 1055.36C1963.54 1055.81 1963.73 1056.31 1963.41 1056.75C1963.06 1057.23 1961.88 1057.03 1961.44 1056.46C1961.15 1056.09 1961.4 1055.85 1961.64 1055.61C1962.19 1055.07 1963.02 1054.95 1963.4 1055.36H1963.39Z" fill="white"/>
                    <path d="M1918.46 1121.88C1918.2 1121.66 1918.08 1121.53 1917.94 1121.44C1917.4 1121.09 1917.64 1120.52 1917.53 1120.05C1918.01 1120.08 1918.53 1119.76 1918.97 1120.23C1919.08 1120.35 1919.22 1120.46 1919.36 1120.56C1919.83 1120.9 1920.06 1121.19 1919.56 1121.76C1919.08 1122.31 1918.75 1122.25 1918.47 1121.87L1918.46 1121.88Z" fill="white"/>
                    <path d="M2027.27 1017.76C2026.9 1018.25 2026.52 1018.09 2026.37 1017.79C2026.09 1017.21 2025.91 1016.56 2025.75 1015.93C2025.59 1015.34 2026.1 1015.55 2026.36 1015.49C2027.49 1015.22 2027.1 1016.39 2027.55 1016.79C2028.07 1017.25 2027.34 1017.49 2027.27 1017.76Z" fill="white"/>
                    <path d="M1930.03 1092.48C1930.66 1092.84 1930.69 1093.45 1930.48 1093.99C1930.32 1094.4 1928.88 1094.48 1928.76 1094.14C1928.51 1093.49 1928.66 1092.92 1929.17 1092.41C1929.53 1092.05 1929.76 1092.26 1930.03 1092.48Z" fill="white"/>
                    <path d="M1981.75 1243.41C1981.35 1243.74 1980.86 1243.7 1980.38 1243.58C1980.1 1243.51 1980.19 1242.07 1980.5 1241.63C1980.78 1241.23 1981.06 1241.41 1981.33 1241.65C1981.87 1242.13 1982.08 1243 1981.75 1243.41Z" fill="white"/>
                    <path d="M1894.77 1103.06C1894.99 1102.92 1895.3 1102.84 1895.41 1103.12C1895.68 1103.8 1896.13 1104.41 1896.16 1105.2C1896.17 1105.53 1896 1105.76 1895.82 1105.83C1895.64 1105.9 1895.23 1105.76 1895.17 1105.61C1894.91 1105.01 1894.73 1104.38 1894.54 1103.75C1894.46 1103.48 1894.55 1103.25 1894.77 1103.05V1103.06Z" fill="white"/>
                    <path d="M1867.41 1095.27C1867.63 1095.43 1867.93 1095.62 1867.7 1096.03C1867.51 1096.38 1867.14 1096.31 1866.83 1096.4C1866.19 1096.57 1865.88 1096.08 1865.57 1095.74C1865.39 1095.54 1865.34 1094.89 1865.48 1094.8C1865.76 1094.6 1866.23 1094.54 1866.58 1094.61C1866.87 1094.67 1867.1 1095.02 1867.41 1095.27Z" fill="white"/>
                    <path d="M1981.19 1008.03C1981.41 1007.66 1981.72 1007.55 1982.14 1007.94C1982.67 1008.44 1982.88 1009.28 1982.55 1009.68C1982 1010.36 1981.55 1009.2 1980.96 1009.58C1980.86 1009.64 1980.79 1008.57 1981.18 1008.03H1981.19Z" fill="white"/>
                    <path d="M1942.52 1165.57C1942.55 1165.72 1942.66 1165.92 1942.61 1166.03C1942.3 1166.64 1940.82 1166.94 1940.36 1166.48C1940.2 1166.32 1940.01 1166.04 1940.05 1165.87C1940.09 1165.69 1940.38 1165.5 1940.6 1165.45C1941.54 1165.23 1942.19 1165.29 1942.53 1165.57H1942.52Z" fill="white"/>
                    <path d="M2035.73 1194.95C2035.9 1194.75 2036.03 1194.52 2036.23 1194.37C2036.74 1193.97 2036.96 1194.53 2037.27 1194.74C2037.68 1195.01 2037.49 1195.3 2037.26 1195.58C2037.04 1195.83 2036.88 1196.12 2036.47 1196.13C2036.05 1196.13 2035.57 1196.29 2035.34 1195.82C2035.16 1195.46 2035.53 1195.22 2035.73 1194.96V1194.95Z" fill="white"/>
                    <path d="M1897.63 1103.62C1897.29 1103.2 1897.56 1102.95 1897.8 1102.72C1898.37 1102.18 1899.19 1102.06 1899.55 1102.49C1899.85 1102.84 1899.58 1103.1 1899.34 1103.33C1898.77 1103.87 1897.97 1103.98 1897.63 1103.62Z" fill="white"/>
                    <path d="M1975.82 1002.98C1976.43 1002.95 1976.98 1002.78 1977.49 1003.31C1977.91 1003.75 1977.73 1003.99 1977.44 1004.35C1977.13 1004.74 1976.9 1004.85 1976.42 1004.53C1975.79 1004.12 1975.95 1003.52 1975.82 1002.98Z" fill="white"/>
                    <path d="M1987.77 1008.58C1987.82 1008.73 1987.95 1008.94 1987.89 1009.03C1987.71 1009.32 1987.51 1009.7 1987.24 1009.79C1986.8 1009.92 1986.25 1010.14 1985.83 1009.69C1985.51 1009.35 1985.78 1009.08 1986.02 1008.85C1986.6 1008.3 1987.36 1008.19 1987.77 1008.59V1008.58Z" fill="white"/>
                    <path d="M2021.7 1227.62C2021.97 1227.86 2022.25 1228.12 2021.88 1228.44C2021.4 1228.85 2020.78 1228.96 2020.34 1228.49C2020.11 1228.24 2020.17 1227.71 2020.1 1227.31C2020.66 1227.29 2021.28 1227.03 2021.7 1227.63V1227.62Z" fill="white"/>
                    <path d="M2078.34 1101.37C2077.9 1101.82 2076.93 1101.85 2076.65 1101.52C2076.35 1101.17 2076.65 1100.94 2076.84 1100.68C2077.24 1100.15 2077.79 1100.05 2078.28 1100.35C2078.43 1100.44 2078.55 1101.12 2078.33 1101.38L2078.34 1101.37Z" fill="white"/>
                    <path d="M1935.27 1074.22C1935.6 1074.5 1935.94 1074.82 1935.55 1075.07C1935.07 1075.38 1934.46 1075.52 1933.91 1075.73C1933.82 1075.14 1933.78 1074.6 1934.3 1074.11C1934.8 1073.65 1935.02 1074.06 1935.27 1074.21V1074.22Z" fill="white"/>
                    <path d="M2061.1 1037.54C2061.7 1037.94 2061.51 1038.23 2061.22 1038.64C2060.77 1039.27 2060.44 1039.03 2059.99 1038.66C2059.53 1038.28 2059.67 1038.01 2059.98 1037.61C2060.43 1037.02 2060.79 1037.1 2061.11 1037.54H2061.1Z" fill="white"/>
                    <path d="M1924.59 1077.51C1924.23 1077.81 1924.02 1078.6 1923.43 1077.93C1923.11 1077.57 1923.04 1077 1923.35 1076.55C1923.86 1075.8 1924.43 1076.62 1924.88 1076.69C1925.44 1076.78 1924.63 1077.2 1924.59 1077.51Z" fill="white"/>
                    <path d="M1850.25 1098.65C1850.44 1098.82 1850.67 1098.96 1850.83 1099.15C1851.12 1099.51 1850.84 1099.76 1850.61 1099.99C1850.44 1100.16 1850.35 1100.47 1850.03 1100.4C1849.66 1100.33 1849.47 1100.09 1849.47 1099.71C1849.47 1099.54 1849.44 1099.37 1849.43 1099.2C1849.41 1098.88 1849.27 1098.37 1849.42 1098.26C1849.74 1098.03 1849.98 1098.46 1850.25 1098.64V1098.65Z" fill="white"/>
                    <path d="M2089.51 1149.98C2090.04 1149.64 2090.26 1150.15 2090.55 1150.38C2091.1 1150.81 2090.58 1151.1 2090.36 1151.4C2090.15 1151.68 2089.89 1151.93 2089.54 1151.57C2089.18 1151.2 2089.17 1150.28 2089.5 1149.97L2089.51 1149.98Z" fill="white"/>
                    <path d="M1987.45 1056.8C1987.14 1057.13 1986.91 1057.41 1986.55 1057.04C1986.19 1056.67 1986.17 1055.73 1986.52 1055.45C1986.88 1055.16 1987.1 1055.49 1987.38 1055.67C1988.06 1056.09 1987.78 1056.48 1987.45 1056.8Z" fill="white"/>
                    <path d="M1889.86 1083.58C1890.01 1083.48 1890.24 1083.2 1890.39 1083.24C1890.8 1083.35 1890.64 1083.8 1890.6 1084.04C1890.54 1084.39 1891.21 1085.08 1890.21 1085.1C1889.88 1085.1 1889.52 1085.21 1889.3 1084.88C1888.85 1084.22 1889.7 1084.08 1889.85 1083.58H1889.86Z" fill="white"/>
                    <path d="M1932.23 1211.87C1932.33 1212.27 1932.72 1212.91 1932.21 1213.03C1931.77 1213.13 1931.51 1213.82 1931 1213.46C1930.7 1213.25 1931.12 1211.94 1931.56 1211.7C1931.79 1211.58 1932.04 1211.64 1932.23 1211.87Z" fill="white"/>
                    <path d="M2052.06 1051.09C2052.06 1051.09 2052.52 1051.15 2052.93 1051.27C2053.11 1051.32 2052.99 1052.51 2052.79 1052.66C2052.28 1053.05 2052.07 1052.51 2051.76 1052.3C2051.38 1052.05 2051.48 1051.76 2052.07 1051.09H2052.06Z" fill="white"/>
                    <path d="M2072.68 1074.1C2073.03 1074.62 2072.5 1074.83 2072.29 1075.14C2072.03 1075.53 2071.65 1075.43 2071.49 1075.13C2071.31 1074.78 2071.28 1074.34 2071.27 1073.93C2071.27 1073.78 2072.53 1073.92 2072.67 1074.1H2072.68Z" fill="white"/>
                    <path d="M2062.97 1233.45C2062.75 1233.86 2062.47 1233.86 2062.28 1233.7C2061.77 1233.26 2061.77 1232.71 2062.06 1232.14C2062.21 1231.85 2062.49 1231.85 2062.69 1232.01C2063.23 1232.44 2063.16 1233 2062.98 1233.45H2062.97Z" fill="white"/>
                    <path d="M1923.49 1171.57C1923.91 1171.2 1924.44 1171.11 1924.78 1171.4C1924.95 1171.54 1925.07 1172.17 1924.73 1172.56C1924.27 1173.08 1924.01 1172.58 1923.71 1172.36C1923.42 1172.15 1923.21 1171.91 1923.49 1171.57Z" fill="white"/>
                    <path d="M2104.71 1158.03C2104.58 1158.24 2103.39 1158.55 2103.37 1158.4C2103.31 1158 2103.27 1157.56 2103.39 1157.19C2103.5 1156.85 2103.87 1156.74 2104.18 1157.07C2104.43 1157.34 2105 1157.46 2104.71 1158.03Z" fill="white"/>
                    <path d="M1918.58 1169.45C1918.84 1169.86 1919 1170.4 1918.54 1170.92C1918.37 1171.11 1918.09 1171.16 1917.9 1170.9C1917.52 1170.39 1917.43 1169.84 1917.86 1169.33C1918.02 1169.14 1918.29 1169.09 1918.58 1169.46V1169.45Z" fill="white"/>
                    <path d="M1871.93 1204.93C1872.32 1205.22 1872.23 1205.59 1871.93 1205.75C1871.58 1205.93 1871.14 1205.95 1870.73 1205.97C1870.58 1205.97 1870.72 1204.71 1870.9 1204.57C1871.16 1204.36 1871.56 1204.51 1871.93 1204.94V1204.93Z" fill="white"/>
                    <path d="M1928.66 1078.94C1928.93 1079.18 1929.21 1079.42 1928.86 1079.77C1928.48 1080.15 1927.93 1080.22 1927.59 1079.93C1927.42 1079.79 1927.3 1079.16 1927.64 1078.77C1928.1 1078.25 1928.35 1078.77 1928.65 1078.95L1928.66 1078.94Z" fill="white"/>
                    <path d="M1860.13 1125.63C1860.32 1126.08 1860.44 1126.52 1860.14 1126.97C1859.97 1127.23 1859.68 1127.26 1859.51 1127.07C1859.23 1126.76 1858.46 1126.64 1858.95 1125.94C1859.25 1125.51 1859.73 1125.78 1860.13 1125.63Z" fill="white"/>
                    <path d="M1929.88 1090.98C1929.92 1091.13 1929.95 1091.28 1929.99 1091.44C1929.24 1091.52 1928.46 1091.47 1927.81 1091.98C1927.78 1092 1927.52 1091.79 1927.44 1091.65C1927.4 1091.57 1927.51 1091.29 1927.55 1091.29C1928.25 1091.3 1928.72 1090.55 1929.45 1090.75C1929.6 1090.79 1929.74 1090.91 1929.88 1090.99V1090.98Z" fill="white"/>
                    <path d="M1928 1094.6C1927.64 1094.97 1927.32 1094.33 1927.04 1094.04C1926.48 1093.47 1927.24 1093.22 1927.47 1092.85C1927.54 1092.74 1928.05 1092.56 1927.88 1092.98C1927.62 1093.6 1928.93 1093.71 1928.01 1094.6H1928Z" fill="white"/>
                    <path d="M2057.84 1192.37C2058.17 1191.83 2058.73 1192.08 2059.19 1191.96C2059.23 1192.28 2059.41 1192.74 2059.27 1192.9C2058.95 1193.26 2058.46 1193.25 2058.01 1193C2057.74 1192.85 2057.76 1192.62 2057.84 1192.37Z" fill="white"/>
                    <path d="M2045.99 1051.76C2045.71 1052 2045.54 1052.55 2045 1052.11C2044.61 1051.79 2044.8 1051.52 2045.04 1051.27C2045.3 1050.99 2045.49 1050.47 2046.03 1050.91C2046.43 1051.23 2046.21 1051.48 2045.99 1051.77V1051.76Z" fill="white"/>
                    <path d="M2011.84 1244.82C2012.08 1245.65 2011.86 1246.16 2011.38 1246.56C2011.27 1246.65 2011.08 1246.66 2010.93 1246.7C2010.92 1246.53 2010.93 1246.35 2010.88 1246.2C2010.68 1245.6 2011.09 1245.25 2011.44 1244.91C2011.55 1244.8 2011.79 1244.83 2011.84 1244.83V1244.82Z" fill="white"/>
                    <path d="M2066.52 1034.51C2066.62 1034.59 2066.46 1034.88 2066.21 1035.14C2065.94 1035.41 2065.77 1035.95 2065.22 1035.52C2064.83 1035.21 2064.99 1034.94 2065.24 1034.68C2065.5 1034.4 2065.69 1033.88 2066.52 1034.52V1034.51Z" fill="white"/>
                    <path d="M2014.88 1055.11C2014.77 1055.15 2014.59 1055.26 2014.52 1055.22C2014.04 1054.89 2013.58 1054.54 2013.64 1053.82C2013.67 1053.52 2013.72 1053.36 2014.01 1053.44C2014.46 1053.57 2015.05 1054.73 2014.88 1055.11Z" fill="white"/>
                    <path d="M2055.09 1148.61C2055.57 1149.29 2054.76 1149.45 2054.54 1149.83C2054.44 1150 2054.05 1149.87 2053.9 1149.64C2053.47 1148.99 2054.19 1148.77 2054.44 1148.41C2054.56 1148.24 2054.91 1148.37 2055.09 1148.6V1148.61Z" fill="white"/>
                    <path d="M1969.25 1093.99C1969.73 1094.67 1968.92 1094.83 1968.7 1095.21C1968.6 1095.38 1968.21 1095.25 1968.06 1095.02C1967.62 1094.37 1968.35 1094.15 1968.6 1093.79C1968.72 1093.62 1969.07 1093.75 1969.25 1093.98V1093.99Z" fill="white"/>
                    <path d="M1955.04 1210.47C1955.14 1210.55 1954.98 1210.84 1954.73 1211.1C1954.46 1211.37 1954.29 1211.91 1953.74 1211.48C1953.35 1211.17 1953.51 1210.9 1953.76 1210.64C1954.02 1210.36 1954.21 1209.84 1955.04 1210.48V1210.47Z" fill="white"/>
                    <path d="M1923.73 1042.01C1923.2 1041.9 1922.64 1041.79 1922.09 1041.66C1922.06 1041.66 1922.04 1041.48 1922.07 1041.42C1922.24 1041.1 1922.52 1040.98 1922.89 1040.96C1923.31 1040.95 1923.74 1040.87 1923.91 1041.38C1923.95 1041.5 1923.83 1041.67 1923.72 1042L1923.73 1042.01Z" fill="white"/>
                    <path d="M1907.32 1087.38C1907.52 1087.1 1907.73 1086.78 1908.13 1087.04C1908.65 1087.38 1908.45 1087.71 1908.11 1088.07C1907.88 1088.31 1907.7 1088.66 1907.3 1088.39C1906.79 1088.05 1906.96 1087.7 1907.32 1087.38Z" fill="white"/>
                    <path d="M1901.13 1140.16C1901.39 1140.57 1901.48 1140.8 1901.22 1140.99C1900.71 1141.37 1900.17 1141.45 1899.65 1141.03C1899.55 1140.95 1899.55 1140.74 1899.51 1140.58C1900.07 1140.43 1900.64 1140.28 1901.14 1140.15L1901.13 1140.16Z" fill="white"/>
                    <path d="M1975.6 1067.08C1976 1067.33 1976.21 1067.64 1976.05 1067.79C1975.74 1068.1 1975.65 1068.85 1974.93 1068.53C1974.69 1068.42 1974.49 1068.11 1974.64 1067.92C1974.92 1067.57 1975.1 1066.97 1975.6 1067.08Z" fill="white"/>
                    <path d="M1971.82 1215.05C1972.08 1215.29 1972.34 1215.51 1972 1215.89C1971.53 1216.41 1971.27 1215.93 1970.96 1215.7C1970.68 1215.49 1970.45 1215.25 1970.78 1214.88C1971.25 1214.36 1971.5 1214.86 1971.82 1215.06V1215.05Z" fill="white"/>
                    <path d="M2023.18 1154.48C2023.86 1154 2024.02 1154.81 2024.4 1155.03C2024.57 1155.13 2024.44 1155.52 2024.21 1155.67C2023.56 1156.11 2023.34 1155.38 2022.98 1155.13C2022.81 1155.01 2022.94 1154.66 2023.17 1154.48H2023.18Z" fill="white"/>
                    <path d="M1892.75 1135.57C1892.95 1135.29 1893.16 1134.97 1893.56 1135.23C1894.08 1135.57 1893.88 1135.9 1893.54 1136.26C1893.31 1136.5 1893.13 1136.85 1892.73 1136.58C1892.22 1136.24 1892.39 1135.89 1892.75 1135.57Z" fill="white"/>
                    <path d="M1976.99 1016.31C1977.33 1015.82 1977.57 1015.82 1977.79 1015.98C1978.3 1016.33 1978.08 1016.67 1977.75 1016.99C1977.58 1017.16 1977.31 1017.56 1977.21 1017.38C1977 1017.01 1977.02 1016.52 1976.99 1016.31Z" fill="white"/>
                    <path d="M2003.31 1225.13C2003.37 1225.26 2003.46 1225.47 2003.56 1225.69C2003.09 1225.71 2002.58 1226 2002.16 1225.55C2001.98 1225.36 2001.96 1225.14 2002.18 1224.9C2002.64 1224.39 2002.9 1224.86 2003.31 1225.13Z" fill="white"/>
                    <path d="M1841.37 1125.61C1841.26 1125.81 1841 1125.83 1840.78 1125.68C1840.49 1125.49 1840.23 1125.21 1840.6 1124.88C1840.86 1124.65 1840.99 1123.98 1841.46 1124.36C1841.63 1124.5 1841.74 1125.14 1841.37 1125.6V1125.61Z" fill="white"/>
                    <path d="M1942.06 998.84C1942.57 999.18 1942.34 999.74 1942.43 1000.2C1942.21 1000.12 1941.94 1000.1 1941.77 999.96C1941.48 999.72 1940.97 999.53 1941.39 998.98C1941.58 998.73 1941.78 998.73 1942.05 998.83L1942.06 998.84Z" fill="white"/>
                    <path d="M1865.72 1142.3C1865.55 1142.62 1865.81 1142.34 1865.47 1142.3C1865.29 1142.28 1865 1142.04 1864.98 1141.87C1864.92 1141.41 1864.67 1140.87 1865.21 1140.5C1865.27 1140.46 1865.42 1140.44 1865.45 1140.47C1865.83 1141.04 1865.59 1141.7 1865.73 1142.3H1865.72Z" fill="white"/>
                    <path d="M1914.65 1143.52C1914.65 1144 1914.92 1144.56 1914.37 1144.89C1914.24 1144.97 1913.86 1144.83 1913.73 1144.67C1913.33 1144.18 1913.9 1143.96 1914.1 1143.64C1914.23 1143.42 1914.41 1143.31 1914.65 1143.52Z" fill="white"/>
                    <path d="M1995.92 1122C1996.05 1122.11 1996.22 1122.2 1996.3 1122.34C1996.35 1122.43 1996.32 1122.68 1996.25 1122.72C1996 1122.85 1994.75 1121.83 1994.79 1121.52C1994.79 1121.45 1995.07 1121.32 1995.12 1121.36C1995.4 1121.55 1995.65 1121.79 1995.92 1122.01V1122Z" fill="white"/>
                    <path d="M1901.06 1228.16C1901.49 1228.48 1901.35 1228.78 1901.09 1229.03C1900.9 1229.21 1900.68 1229.23 1900.43 1229.02C1900.06 1228.7 1900.19 1228.43 1900.46 1228.18C1900.65 1228.01 1900.9 1227.91 1901.07 1228.16H1901.06Z" fill="white"/>
                    <path d="M1958.61 1003.2C1958.77 1003.44 1958.81 1003.63 1958.61 1003.88C1958.29 1004.25 1958.02 1004.12 1957.77 1003.86C1957.59 1003.67 1957.57 1003.45 1957.78 1003.2C1958.1 1002.83 1958.35 1002.99 1958.62 1003.2H1958.61Z" fill="white"/>
                    <path d="M2086.29 1147.45C2086.45 1147.69 2086.49 1147.88 2086.28 1148.13C2085.96 1148.5 2085.69 1148.37 2085.44 1148.11C2085.26 1147.92 2085.24 1147.7 2085.45 1147.45C2085.77 1147.08 2086.02 1147.24 2086.29 1147.45Z" fill="white"/>
                    <path d="M2030.25 1229.92C2030.04 1230.17 2029.81 1230.36 2029.44 1230.05C2029.19 1229.84 2029.17 1229.62 2029.32 1229.4C2029.52 1229.1 2029.77 1228.92 2030.14 1229.24C2030.39 1229.45 2030.38 1229.64 2030.25 1229.92Z" fill="white"/>
                    <path d="M2047.47 1030.59C2047.91 1030.38 2048.35 1030.29 2048.77 1030.56C2048.88 1030.63 2049 1030.94 2048.99 1030.94C2048.58 1031.21 2048.14 1031.29 2047.71 1031C2047.6 1030.92 2047.56 1030.74 2047.47 1030.58V1030.59Z" fill="white"/>
                    <path d="M2091.56 1113.41C2091.83 1113.56 2091.82 1113.82 2091.67 1114.04C2091.47 1114.34 2091.22 1114.51 2090.85 1114.2C2090.6 1113.99 2090.58 1113.77 2090.73 1113.55C2090.93 1113.25 2091.2 1113.06 2091.56 1113.4V1113.41Z" fill="white"/>
                    <path d="M1999.22 1064.99C1999.38 1065.23 1999.42 1065.42 1999.22 1065.67C1998.9 1066.04 1998.63 1065.91 1998.38 1065.65C1998.2 1065.46 1998.18 1065.24 1998.39 1064.99C1998.71 1064.62 1998.96 1064.78 1999.23 1064.99H1999.22Z" fill="white"/>
                    <path d="M1864.22 1183.28C1864.4 1183.56 1864.45 1183.75 1864.24 1183.99C1863.92 1184.36 1863.65 1184.23 1863.4 1183.97C1863.22 1183.78 1863.2 1183.56 1863.41 1183.31C1863.73 1182.93 1863.98 1183.13 1864.22 1183.28Z" fill="white"/>
                    <path d="M1874.52 1164.47C1874.22 1164.87 1873.92 1164.73 1873.68 1164.47C1873.5 1164.28 1873.48 1164.06 1873.69 1163.81C1874.01 1163.44 1874.28 1163.57 1874.53 1163.83C1874.71 1164.02 1874.77 1164.27 1874.53 1164.47H1874.52Z" fill="white"/>
                    <path d="M2083.9 1030.66C2083.65 1030.43 2083.39 1030.2 2083.74 1029.85C2083.85 1029.74 2084.01 1029.69 2084.14 1029.61C2084.22 1029.98 2084.3 1030.34 2084.38 1030.71C2084.22 1030.69 2084.07 1030.67 2083.9 1030.66Z" fill="white"/>
                    <path d="M2068.5 1075.38C2068.75 1075.61 2069.01 1075.84 2068.66 1076.19C2068.55 1076.3 2068.39 1076.35 2068.26 1076.43C2068.18 1076.06 2068.1 1075.7 2068.02 1075.33C2068.18 1075.35 2068.33 1075.37 2068.5 1075.38Z" fill="white"/>
                    <path d="M1959.25 1023.1C1959.29 1023.45 1959.32 1023.79 1959.36 1024.12C1959.14 1024.05 1958.86 1024.03 1958.71 1023.89C1958.38 1023.57 1958.65 1023.29 1958.9 1023.07C1958.97 1023.01 1959.15 1023.09 1959.25 1023.1Z" fill="white"/>
                    <path d="M2018.4 1160.16C2018.22 1160.26 2018.01 1160.43 2017.96 1160.4C2017.6 1160.12 2017.82 1159.68 2017.72 1159.32C2017.88 1159.34 2018.06 1159.32 2018.18 1159.39C2018.48 1159.59 2018.65 1159.85 2018.39 1160.17L2018.4 1160.16Z" fill="white"/>
                    <path d="M2064.13 1229.18C2064.16 1229.31 2064.27 1229.48 2064.22 1229.56C2064.05 1229.84 2063.81 1230.15 2063.44 1229.87C2063.27 1229.74 2063.21 1229.47 2063.1 1229.27C2063.44 1229.24 2063.77 1229.21 2064.12 1229.18H2064.13Z" fill="white"/>
                    <path d="M2058.55 1203.73C2058.84 1203.35 2059.13 1203.53 2059.37 1203.78C2059.47 1203.88 2059.47 1204.08 2059.51 1204.23C2059.14 1204.21 2058.76 1204.19 2058.39 1204.18C2058.44 1204.03 2058.5 1203.89 2058.55 1203.73Z" fill="white"/>
                    <path d="M1968.27 1042.43C1968.29 1042.5 1968.41 1042.66 1968.36 1042.74C1968.19 1043.02 1967.96 1043.34 1967.59 1043.07C1967.42 1042.95 1967.36 1042.68 1967.24 1042.47C1967.57 1042.46 1967.9 1042.44 1968.26 1042.43H1968.27Z" fill="white"/>
                    <path d="M1993.24 1065.43C1993.62 1065.72 1993.44 1066.01 1993.19 1066.25C1993.09 1066.35 1992.89 1066.35 1992.74 1066.39C1992.76 1066.02 1992.78 1065.64 1992.79 1065.27C1992.94 1065.32 1993.08 1065.38 1993.24 1065.43Z" fill="white"/>
                    <path d="M2092.94 1177.81C2092.99 1177.96 2093.04 1178.11 2093.09 1178.26C2092.72 1178.26 2092.34 1178.26 2091.97 1178.26C2092.02 1178.11 2092.03 1177.94 2092.12 1177.82C2092.38 1177.43 2092.66 1177.58 2092.94 1177.81Z" fill="white"/>
                    <path d="M1890.28 1170.22C1890.57 1169.84 1890.86 1170.02 1891.1 1170.27C1891.2 1170.37 1891.2 1170.57 1891.24 1170.72C1890.87 1170.7 1890.49 1170.68 1890.12 1170.67C1890.17 1170.52 1890.23 1170.38 1890.28 1170.22Z" fill="white"/>
                    <path d="M1868.63 1199.07C1868.85 1199.1 1869.03 1199.08 1869.15 1199.16C1869.45 1199.37 1869.62 1199.64 1869.28 1199.96C1869.17 1200.06 1869.01 1200.11 1868.87 1200.18C1868.79 1199.82 1868.71 1199.45 1868.62 1199.07H1868.63Z" fill="white"/>
                    <path d="M1907.39 1008.85C1907.51 1009.18 1907.24 1009.33 1907.08 1009.5C1907.03 1009.55 1906.75 1009.5 1906.72 1009.43C1906.58 1009.13 1906.86 1008.98 1907.02 1008.81C1907.07 1008.75 1907.27 1008.84 1907.39 1008.85Z" fill="white"/>
                    <path d="M1930.03 1012.35C1930.3 1012.47 1930.54 1012.53 1930.71 1012.67C1930.81 1012.75 1930.81 1012.96 1930.86 1013.11C1930.64 1013.04 1930.39 1013 1930.2 1012.87C1930.1 1012.8 1930.1 1012.58 1930.03 1012.34V1012.35Z" fill="white"/>
                    <path d="M1951.31 1242.52C1951.43 1242.85 1951.16 1243 1951 1243.17C1950.95 1243.22 1950.67 1243.17 1950.64 1243.1C1950.5 1242.8 1950.78 1242.65 1950.94 1242.48C1950.99 1242.42 1951.19 1242.51 1951.31 1242.52Z" fill="white"/>
                    <path d="M2047.65 1226.08C2047.53 1226.35 2047.47 1226.59 2047.33 1226.76C2047.25 1226.86 2047.04 1226.86 2046.89 1226.91C2046.96 1226.69 2047 1226.44 2047.13 1226.25C2047.2 1226.15 2047.42 1226.15 2047.66 1226.08H2047.65Z" fill="white"/>
                    <path d="M2049.2 1223.71C2049.1 1223.81 2048.96 1224 2048.77 1224.13C2048.55 1224.27 2048.4 1224.15 2048.51 1223.91C2048.6 1223.71 2048.77 1223.51 2048.95 1223.39C2049.17 1223.24 2049.28 1223.35 2049.19 1223.72L2049.2 1223.71Z" fill="white"/>
                    <path d="M1957.95 1256.94C1958.09 1256.87 1958.28 1256.7 1958.36 1256.74C1958.63 1256.9 1958.53 1257.12 1958.34 1257.29C1958.22 1257.39 1958.01 1257.53 1957.94 1257.49C1957.67 1257.33 1957.77 1257.12 1957.96 1256.94H1957.95Z" fill="white"/>
                    <path d="M1959.77 1009C1959.84 1009.14 1960.01 1009.33 1959.97 1009.41C1959.81 1009.68 1959.59 1009.58 1959.42 1009.39C1959.32 1009.27 1959.18 1009.06 1959.22 1008.99C1959.38 1008.72 1959.59 1008.82 1959.77 1009.01V1009Z" fill="white"/>
                    <path d="M2088.96 1169.24C2089.23 1169.36 2089.47 1169.42 2089.64 1169.56C2089.74 1169.64 2089.74 1169.85 2089.79 1170C2089.57 1169.93 2089.32 1169.89 2089.13 1169.77C2089.03 1169.7 2089.03 1169.48 2088.96 1169.24Z" fill="white"/>
                    <path d="M1865.87 1113.93C1865.9 1114.04 1865.95 1114.19 1865.99 1114.35C1865.77 1114.28 1865.52 1114.24 1865.33 1114.11C1865.23 1114.04 1865.23 1113.82 1865.18 1113.67C1865.44 1113.62 1865.67 1113.67 1865.86 1113.93H1865.87Z" fill="white"/>
                    <path d="M2113.31 1137.27C2113.16 1137.48 2112.97 1137.62 2112.77 1137.38C2112.72 1137.32 2112.83 1137.08 2112.9 1136.95C2113.03 1136.73 2113.23 1136.6 2113.43 1136.84C2113.48 1136.9 2113.35 1137.12 2113.3 1137.27H2113.31Z" fill="white"/>
                    <path d="M1892.37 1137.64C1892.22 1137.85 1892.03 1137.99 1891.83 1137.75C1891.78 1137.69 1891.89 1137.45 1891.96 1137.32C1892.09 1137.1 1892.29 1136.97 1892.49 1137.21C1892.54 1137.27 1892.41 1137.49 1892.36 1137.64H1892.37Z" fill="white"/>
                    <path d="M1903.47 1136.7C1903.32 1136.91 1903.13 1137.05 1902.93 1136.81C1902.88 1136.75 1902.99 1136.51 1903.06 1136.38C1903.19 1136.16 1903.39 1136.03 1903.59 1136.27C1903.64 1136.33 1903.51 1136.55 1903.46 1136.7H1903.47Z" fill="white"/>
                    <path d="M1943.47 1127.47C1943.74 1127.59 1943.98 1127.65 1944.15 1127.79C1944.25 1127.87 1944.25 1128.08 1944.3 1128.23C1944.08 1128.16 1943.83 1128.12 1943.64 1128C1943.54 1127.93 1943.54 1127.71 1943.47 1127.47Z" fill="white"/>
                    <path d="M1964.48 1034.23C1964.56 1034.41 1964.64 1034.63 1964.73 1034.84C1964.57 1034.82 1964.35 1034.86 1964.27 1034.77C1964.11 1034.61 1964.04 1034.37 1963.93 1034.16C1964.09 1034.18 1964.25 1034.2 1964.48 1034.22V1034.23Z" fill="white"/>
                    <path d="M2011.77 1103.12C2011.8 1103.23 2011.85 1103.38 2011.89 1103.54C2011.67 1103.47 2011.42 1103.43 2011.23 1103.3C2011.13 1103.23 2011.13 1103.01 2011.08 1102.86C2011.34 1102.81 2011.57 1102.86 2011.76 1103.12H2011.77Z" fill="white"/>
                    <path d="M2042.77 1076.62C2043.04 1076.74 2043.28 1076.8 2043.45 1076.94C2043.55 1077.02 2043.55 1077.23 2043.6 1077.38C2043.38 1077.31 2043.13 1077.27 2042.94 1077.14C2042.84 1077.07 2042.84 1076.85 2042.77 1076.61V1076.62Z" fill="white"/>
                    <path d="M2091.04 1034.73C2091.16 1035.06 2090.89 1035.21 2090.73 1035.38C2090.68 1035.43 2090.4 1035.38 2090.37 1035.31C2090.23 1035.01 2090.51 1034.86 2090.67 1034.69C2090.72 1034.63 2090.92 1034.72 2091.04 1034.73Z" fill="white"/>
                    <path d="M2077.31 1090.37C2077.16 1090.58 2076.97 1090.72 2076.77 1090.48C2076.72 1090.42 2076.83 1090.18 2076.9 1090.05C2077.03 1089.83 2077.23 1089.7 2077.43 1089.94C2077.48 1090 2077.35 1090.22 2077.3 1090.37H2077.31Z" fill="white"/>
                    <path d="M1952.37 1226.11C1952.51 1226.04 1952.7 1225.87 1952.78 1225.91C1953.05 1226.07 1952.95 1226.29 1952.76 1226.46C1952.64 1226.56 1952.43 1226.7 1952.36 1226.66C1952.09 1226.5 1952.19 1226.29 1952.38 1226.11H1952.37Z" fill="white"/>
                    <path d="M1996.48 1152.72C1996.55 1152.86 1996.72 1153.05 1996.68 1153.13C1996.52 1153.4 1996.3 1153.3 1996.13 1153.11C1996.03 1152.99 1995.89 1152.78 1995.93 1152.71C1996.09 1152.44 1996.3 1152.54 1996.48 1152.73V1152.72Z" fill="white"/>
                    <path d="M2007.42 1160.92C2007.55 1160.95 2007.68 1160.96 2007.8 1161.02C2007.84 1161.04 2007.82 1161.17 2007.83 1161.26C2007.7 1161.23 2007.57 1161.22 2007.45 1161.16C2007.41 1161.14 2007.43 1161.01 2007.42 1160.92Z" fill="white"/>
                    <path d="M2101.55 1181.3C2101.68 1181.33 2101.81 1181.34 2101.93 1181.4C2101.97 1181.42 2101.95 1181.55 2101.96 1181.64C2101.83 1181.61 2101.7 1181.6 2101.58 1181.54C2101.54 1181.52 2101.56 1181.39 2101.55 1181.3Z" fill="white"/>
                    <path d="M1912.34 1130.56C1912.38 1130.68 1912.43 1130.8 1912.48 1130.92C1912.4 1130.92 1912.27 1130.97 1912.24 1130.93C1912.16 1130.83 1912.13 1130.7 1912.08 1130.58C1912.16 1130.58 1912.24 1130.57 1912.34 1130.56Z" fill="white"/>
                    <path d="M1877.8 1152.74C1877.8 1152.64 1877.78 1152.56 1877.78 1152.48C1877.91 1152.51 1878.04 1152.52 1878.16 1152.58C1878.2 1152.6 1878.18 1152.73 1878.19 1152.82C1878.06 1152.79 1877.93 1152.77 1877.81 1152.74H1877.8Z" fill="white"/>
                    <path d="M1926.09 1093.52C1925.97 1093.56 1925.85 1093.61 1925.73 1093.66C1925.73 1093.58 1925.68 1093.45 1925.72 1093.42C1925.82 1093.34 1925.95 1093.31 1926.07 1093.26C1926.07 1093.34 1926.08 1093.42 1926.09 1093.52Z" fill="white"/>
                    <path d="M1966.89 1091.3C1966.86 1091.43 1966.85 1091.56 1966.79 1091.68C1966.77 1091.72 1966.64 1091.7 1966.55 1091.71C1966.58 1091.58 1966.59 1091.45 1966.65 1091.33C1966.67 1091.29 1966.8 1091.31 1966.89 1091.3Z" fill="white"/>
                    <path d="M1997.81 1061.49C1997.71 1061.49 1997.63 1061.51 1997.55 1061.51C1997.58 1061.38 1997.59 1061.25 1997.65 1061.13C1997.67 1061.09 1997.8 1061.11 1997.89 1061.1C1997.86 1061.23 1997.84 1061.36 1997.81 1061.48V1061.49Z" fill="white"/>
                    <path d="M1970.5 1016.1C1970.54 1016.22 1970.59 1016.34 1970.64 1016.46C1970.56 1016.46 1970.43 1016.51 1970.4 1016.47C1970.32 1016.37 1970.29 1016.24 1970.24 1016.12C1970.32 1016.12 1970.4 1016.11 1970.5 1016.1Z" fill="white"/>
                    <path d="M1913.69 1105.58C1913.69 1105.68 1913.71 1105.76 1913.71 1105.84C1913.58 1105.81 1913.45 1105.8 1913.33 1105.74C1913.29 1105.72 1913.31 1105.59 1913.3 1105.5C1913.43 1105.53 1913.56 1105.55 1913.68 1105.58H1913.69Z" fill="white"/>
                    <path d="M1978.69 1116.28C1978.69 1116.18 1978.67 1116.1 1978.67 1116.02C1978.8 1116.05 1978.93 1116.06 1979.05 1116.12C1979.09 1116.14 1979.07 1116.27 1979.08 1116.36C1978.95 1116.33 1978.82 1116.31 1978.7 1116.28H1978.69Z" fill="white"/>
                    <path d="M2068.4 1026.1C2068.49 1026.1 2068.62 1026.06 2068.63 1026.08C2068.68 1026.2 2068.74 1026.35 2068.71 1026.46C2068.65 1026.7 2068.5 1026.71 2068.4 1026.49C2068.35 1026.38 2068.4 1026.23 2068.4 1026.1Z" fill="white"/>
                    <path d="M2068.75 1026.8C2068.75 1026.93 2068.79 1027.14 2068.73 1027.18C2068.55 1027.29 2068.41 1027.22 2068.43 1027.06C2068.45 1026.92 2068.52 1026.79 2068.58 1026.65C2068.64 1026.7 2068.7 1026.75 2068.76 1026.8H2068.75Z" fill="white"/>
                    <path d="M2002.06 1187.08C2002.1 1187.2 2002.15 1187.32 2002.2 1187.44C2002.12 1187.44 2001.99 1187.49 2001.96 1187.45C2001.88 1187.35 2001.85 1187.22 2001.8 1187.1C2001.88 1187.1 2001.96 1187.09 2002.06 1187.08Z" fill="white"/>
                    <path d="M2010.09 977.36C2010.13 977.48 2010.18 977.6 2010.23 977.72C2010.15 977.72 2010.02 977.77 2009.99 977.73C2009.91 977.63 2009.88 977.5 2009.83 977.38C2009.91 977.38 2009.99 977.37 2010.09 977.36Z" fill="white"/>
                    <path d="M2075.32 1024.88C2075.32 1024.98 2075.34 1025.06 2075.34 1025.14C2075.21 1025.11 2075.08 1025.1 2074.96 1025.04C2074.92 1025.02 2074.94 1024.89 2074.93 1024.8C2075.06 1024.83 2075.19 1024.85 2075.31 1024.88H2075.32Z" fill="white"/>
                    <path d="M1979.49 1000.81C1979.45 1000.69 1979.4 1000.57 1979.35 1000.45C1979.43 1000.45 1979.56 1000.4 1979.59 1000.44C1979.67 1000.54 1979.7 1000.67 1979.75 1000.79C1979.67 1000.79 1979.59 1000.8 1979.49 1000.81Z" fill="white"/>
                    <path d="M2044.91 1111.93C2044.95 1112.05 2045 1112.17 2045.05 1112.29C2044.97 1112.29 2044.84 1112.34 2044.81 1112.3C2044.73 1112.2 2044.7 1112.07 2044.65 1111.95C2044.73 1111.95 2044.81 1111.94 2044.91 1111.93Z" fill="white"/>
                    <path d="M1931.88 1066.31C1931.78 1066.31 1931.7 1066.33 1931.62 1066.33C1931.65 1066.2 1931.66 1066.07 1931.72 1065.95C1931.74 1065.91 1931.87 1065.93 1931.96 1065.92C1931.93 1066.05 1931.91 1066.18 1931.88 1066.3V1066.31Z" fill="white"/>
                    <path d="M2065.47 1053.92C2065.6 1053.95 2065.73 1053.96 2065.85 1054.02C2065.89 1054.04 2065.87 1054.17 2065.88 1054.26C2065.75 1054.23 2065.62 1054.22 2065.5 1054.16C2065.46 1054.14 2065.48 1054.01 2065.47 1053.92Z" fill="white"/>
                    <path d="M1937.36 1056.16C1937.4 1056.28 1937.45 1056.4 1937.5 1056.52C1937.42 1056.52 1937.29 1056.57 1937.26 1056.53C1937.18 1056.43 1937.15 1056.3 1937.1 1056.18L1937.36 1056.16Z" fill="white"/>
                    <path d="M1993.22 1142.85C1993.34 1142.81 1993.46 1142.76 1993.58 1142.71C1993.58 1142.79 1993.63 1142.92 1993.59 1142.95C1993.49 1143.03 1993.36 1143.06 1993.24 1143.11C1993.24 1143.03 1993.23 1142.95 1993.22 1142.85Z" fill="white"/>
                    <path d="M1964.13 1261.83C1964.26 1261.86 1964.39 1261.87 1964.51 1261.93C1964.55 1261.95 1964.53 1262.08 1964.54 1262.17C1964.41 1262.14 1964.28 1262.13 1964.16 1262.07C1964.12 1262.05 1964.14 1261.92 1964.13 1261.83Z" fill="white"/>
                    <path d="M1756.72 1025.48C1758 1023.97 1759.49 1024.37 1760.89 1024.77C1761.4 1024.92 1762.16 1025.26 1762.45 1025.24C1764.32 1025.11 1763.51 1027.17 1764.56 1027.6C1763.99 1028.56 1763.62 1029.91 1762.78 1030.38C1761.97 1030.83 1760.92 1031.3 1759.89 1031.35C1758.92 1031.4 1757.57 1031.91 1757.59 1030.03C1757.59 1029.75 1757.04 1029.48 1756.77 1029.18C1755.6 1027.84 1755.62 1026.57 1756.71 1025.47L1756.72 1025.48Z" fill="white"/>
                    <path d="M1771.2 1184.81C1770.38 1183.98 1770.45 1182.73 1770.75 1182.45C1771.9 1181.35 1771.32 1179.87 1771.88 1178.65C1772.28 1177.79 1772.65 1177.33 1773.56 1177.39C1775.06 1177.48 1775.63 1177.87 1775.8 1178.96C1775.87 1179.43 1775.99 1179.87 1776.13 1180.33C1776.73 1182.3 1774.35 1185.73 1772.05 1185.16C1771.64 1185.06 1771.3 1184.98 1771.2 1184.81Z" fill="white"/>
                    <path d="M1825.97 1106.57C1826 1105.54 1824.79 1104.65 1825.71 1103.56C1825.84 1103.41 1826.06 1103.29 1826.12 1103.12C1826.36 1102.4 1826.35 1101.54 1827.27 1101.26C1827.99 1101.04 1828.58 1101.48 1829.21 1101.68C1830.45 1102.09 1831.26 1103.74 1830.96 1105.15C1830.73 1106.24 1830.08 1106.85 1828.98 1107.18C1827.84 1107.52 1826.97 1106.82 1825.96 1106.57H1825.97Z" fill="white"/>
                    <path d="M1961.65 1148.63C1962.89 1149.87 1963.01 1150.89 1962.23 1151.89C1961.8 1152.44 1961.23 1152.89 1960.76 1153.42C1960.32 1153.92 1959.97 1153.86 1959.55 1153.4C1959.08 1152.88 1958.46 1152.46 1958.06 1151.89C1957.38 1150.93 1957.39 1149.82 1957.78 1148.74C1957.86 1148.52 1958.19 1148.24 1958.4 1148.24C1959.19 1148.24 1959.91 1147.98 1960.66 1147.84C1961.02 1147.77 1961.55 1148.54 1961.65 1148.62V1148.63Z" fill="white"/>
                    <path d="M1883.21 1255.6C1882.6 1255.09 1881.71 1254.38 1882.34 1253.27C1882.64 1252.74 1883.2 1251.75 1883.87 1252.11C1884.6 1252.49 1885.73 1252.31 1886.06 1253.35C1886.29 1254.07 1886.28 1254.91 1886.66 1255.52C1887.01 1256.09 1886.95 1256.33 1886.55 1256.76C1885.96 1257.37 1885.29 1257.36 1884.68 1257.01C1884.17 1256.72 1883.8 1256.19 1883.21 1255.61V1255.6Z" fill="white"/>
                    <path d="M1978.27 1134.3C1977.69 1133.75 1977.64 1132.83 1978.16 1132.23C1978.33 1132.03 1981.1 1131.24 1981.32 1131.33C1981.83 1131.52 1982.24 1133.33 1982.14 1133.31C1980.89 1133.07 1981.48 1134.51 1980.79 1134.7C1980.13 1134.88 1978.64 1134.65 1978.27 1134.3Z" fill="white"/>
                    <path d="M1824.57 1040.54C1824.27 1040.83 1823.89 1041.37 1823.6 1041.09C1823.06 1040.58 1822.24 1040.25 1822.23 1039.25C1822.23 1038.7 1822.66 1038.36 1822.66 1037.91C1822.66 1037.18 1822.86 1036.85 1823.66 1036.8C1824.52 1036.75 1825.12 1037.19 1825.21 1037.85C1825.34 1038.77 1825.61 1039.88 1824.57 1040.54Z" fill="white"/>
                    <path d="M1819.89 1145.71C1820.32 1146.18 1821.13 1146.53 1820.25 1147.36C1820.05 1147.55 1820.25 1148.13 1820.23 1148.53C1820.21 1148.98 1820.15 1149.44 1820.1 1149.89C1819.57 1149.46 1818.79 1149.14 1818.58 1148.59C1818.31 1147.89 1818.53 1147.01 1818.48 1146.21C1818.45 1145.77 1818.52 1145.36 1818.92 1145.2C1819.36 1145.02 1819.61 1145.45 1819.89 1145.71Z" fill="white"/>
                    <path d="M1925.9 1023.23C1926.04 1023.37 1926.2 1023.49 1926.32 1023.65C1926.59 1024 1927.26 1024.21 1926.9 1024.81C1926.52 1025.43 1925.94 1025.71 1925.17 1025.62C1924.51 1025.54 1924.45 1025.16 1924.4 1024.62C1924.37 1024.27 1924.04 1023.96 1924 1023.61C1923.95 1023.15 1923.79 1022.59 1924.31 1022.27C1924.93 1021.89 1925.13 1022.55 1925.48 1022.81C1925.64 1022.93 1925.76 1023.08 1925.91 1023.22L1925.9 1023.23Z" fill="white"/>
                    <path d="M1979.9 1145.2C1980.79 1144.94 1981.64 1144.4 1982.63 1144.59C1982.85 1144.63 1983.14 1144.89 1983.1 1145.27C1983.05 1145.74 1983.31 1146.37 1982.75 1146.61C1982.07 1146.9 1981.31 1147.04 1980.57 1147.16C1979.69 1147.31 1980.32 1146.45 1979.98 1146.19C1979.63 1145.92 1979.49 1145.58 1979.91 1145.21L1979.9 1145.2Z" fill="white"/>
                    <path d="M1782.58 1113.32C1783.03 1113.64 1783.14 1114 1782.65 1114.46C1781.88 1115.17 1781.07 1115.2 1780.38 1114.58C1779.76 1114.03 1779.8 1113.34 1780.1 1112.75C1780.45 1112.07 1781.65 1112.36 1782.58 1113.32Z" fill="white"/>
                    <path d="M1693.48 1131.45C1693.7 1131.66 1693.96 1131.84 1694.12 1132.09C1694.5 1132.67 1694.08 1133.41 1693.4 1133.41C1693 1133.41 1692.58 1133.49 1692.2 1133.41C1691.71 1133.29 1691.26 1132.9 1691.2 1132.44C1691.14 1131.92 1691.76 1131.47 1691.62 1130.85C1692.18 1130.92 1692.84 1130.59 1693.27 1131.24C1693.32 1131.32 1693.41 1131.38 1693.48 1131.45Z" fill="white"/>
                    <path d="M1934.65 1111.9C1934.65 1111.54 1934.66 1111.24 1934.64 1110.95C1934.58 1110.2 1935.7 1110.05 1935.5 1109.2C1935.4 1108.75 1935.72 1108.42 1936.18 1108.47C1936.61 1108.51 1936.79 1108.86 1936.78 1109.31C1936.76 1109.91 1936.82 1110.51 1936.78 1111.1C1936.76 1111.38 1937.11 1111.91 1936.39 1111.89C1935.81 1111.88 1935.23 1111.89 1934.65 1111.9Z" fill="white"/>
                    <path d="M1955.23 1235.37C1954.72 1235.76 1953.59 1235.49 1953.18 1234.88C1953.02 1234.64 1953.38 1232.81 1953.69 1232.63C1954.47 1232.18 1954.7 1233 1955.05 1233.35C1955.7 1234 1955.73 1235.01 1955.24 1235.38L1955.23 1235.37Z" fill="white"/>
                    <path d="M1872.84 1217.79C1873.21 1217.29 1873.56 1217.11 1874.04 1217.62C1874.62 1218.24 1874.8 1219.25 1874.38 1219.68C1873.96 1220.11 1872.92 1219.95 1872.32 1219.35C1871.43 1218.46 1872.62 1218.27 1872.84 1217.79Z" fill="white"/>
                    <path d="M1865.68 1219.64C1865.54 1219.1 1865.37 1218.52 1865.78 1218.04C1866.23 1217.52 1867.57 1217.85 1868.03 1218.54C1868.33 1219 1868.03 1219.25 1867.73 1219.5C1867.05 1220.08 1866.07 1220.14 1865.68 1219.64Z" fill="white"/>
                    <path d="M1923.32 1146.77C1923.6 1147.05 1923.73 1147.2 1923.89 1147.32C1924.48 1147.77 1924.16 1148.4 1924.25 1148.96C1923.7 1148.88 1923.07 1149.21 1922.61 1148.63C1922.49 1148.48 1922.34 1148.34 1922.19 1148.21C1921.67 1147.78 1921.43 1147.42 1922.06 1146.8C1922.66 1146.21 1923.04 1146.31 1923.33 1146.77H1923.32Z" fill="white"/>
                    <path d="M1788.69 1257.49C1789.16 1256.95 1789.59 1257.18 1789.73 1257.53C1790 1258.23 1790.15 1258.99 1790.29 1259.73C1790.42 1260.42 1789.85 1260.14 1789.54 1260.19C1788.21 1260.4 1788.77 1259.09 1788.28 1258.59C1787.72 1258.02 1788.58 1257.8 1788.69 1257.49Z" fill="white"/>
                    <path d="M1907.4 1179.7C1906.7 1179.23 1906.73 1178.53 1907.01 1177.92C1907.22 1177.47 1908.89 1177.49 1909.01 1177.89C1909.24 1178.66 1909.01 1179.31 1908.38 1179.85C1907.94 1180.23 1907.68 1179.97 1907.39 1179.69L1907.4 1179.7Z" fill="white"/>
                    <path d="M1860.83 1001.01C1861.32 1000.66 1861.88 1000.75 1862.42 1000.93C1862.74 1001.04 1862.51 1002.69 1862.11 1003.17C1861.75 1003.61 1861.44 1003.37 1861.15 1003.08C1860.57 1002.48 1860.4 1001.46 1860.82 1001.01H1860.83Z" fill="white"/>
                    <path d="M1949.03 1170.55C1948.76 1170.7 1948.4 1170.76 1948.29 1170.42C1948.03 1169.61 1947.57 1168.86 1947.6 1167.96C1947.61 1167.58 1947.83 1167.32 1948.05 1167.26C1948.26 1167.2 1948.72 1167.39 1948.78 1167.57C1949.03 1168.28 1949.18 1169.03 1949.34 1169.77C1949.41 1170.09 1949.28 1170.35 1949.01 1170.56L1949.03 1170.55Z" fill="white"/>
                    <path d="M1979.92 1181.92C1979.68 1181.72 1979.36 1181.47 1979.65 1181.02C1979.9 1180.63 1980.32 1180.74 1980.69 1180.67C1981.44 1180.53 1981.76 1181.12 1982.08 1181.54C1982.27 1181.79 1982.27 1182.54 1982.11 1182.64C1981.77 1182.84 1981.22 1182.88 1980.83 1182.76C1980.5 1182.66 1980.26 1182.25 1979.93 1181.92H1979.92Z" fill="white"/>
                    <path d="M1841.03 1272.72C1840.74 1273.13 1840.37 1273.23 1839.93 1272.74C1839.36 1272.12 1839.2 1271.13 1839.61 1270.7C1840.3 1269.96 1840.72 1271.34 1841.43 1270.95C1841.55 1270.88 1841.54 1272.13 1841.04 1272.72H1841.03Z" fill="white"/>
                    <path d="M1899.34 1094.26C1899.32 1094.08 1899.21 1093.85 1899.28 1093.73C1899.69 1093.05 1901.42 1092.84 1901.92 1093.41C1902.09 1093.61 1902.28 1093.95 1902.22 1094.14C1902.15 1094.34 1901.8 1094.53 1901.55 1094.57C1900.45 1094.74 1899.7 1094.61 1899.34 1094.27V1094.26Z" fill="white"/>
                    <path d="M1794.32 1052.26C1794.11 1052.47 1793.93 1052.73 1793.69 1052.89C1793.06 1053.3 1792.86 1052.64 1792.52 1052.37C1792.07 1052.02 1792.31 1051.7 1792.61 1051.41C1792.88 1051.14 1793.1 1050.82 1793.57 1050.85C1794.05 1050.88 1794.62 1050.74 1794.85 1051.3C1795.03 1051.74 1794.58 1051.98 1794.33 1052.26H1794.32Z" fill="white"/>
                    <path d="M1945.77 1169.66C1946.12 1170.18 1945.79 1170.44 1945.5 1170.68C1944.8 1171.26 1943.84 1171.32 1943.46 1170.8C1943.14 1170.37 1943.48 1170.09 1943.78 1169.84C1944.48 1169.26 1945.42 1169.21 1945.77 1169.66Z" fill="white"/>
                    <path d="M1846.79 1279.02C1846.09 1279 1845.43 1279.15 1844.89 1278.49C1844.45 1277.95 1844.67 1277.68 1845.04 1277.29C1845.43 1276.87 1845.7 1276.76 1846.24 1277.17C1846.94 1277.7 1846.69 1278.37 1846.8 1279.02H1846.79Z" fill="white"/>
                    <path d="M1833.49 1271.51C1833.45 1271.33 1833.32 1271.08 1833.39 1270.98C1833.62 1270.66 1833.89 1270.23 1834.21 1270.16C1834.73 1270.05 1835.38 1269.85 1835.83 1270.4C1836.17 1270.83 1835.83 1271.11 1835.54 1271.36C1834.83 1271.94 1833.93 1272 1833.5 1271.51H1833.49Z" fill="white"/>
                    <path d="M1813.35 1015.77C1813.06 1015.47 1812.76 1015.15 1813.22 1014.81C1813.81 1014.38 1814.53 1014.3 1815 1014.89C1815.25 1015.2 1815.13 1015.8 1815.17 1016.27C1814.52 1016.25 1813.79 1016.49 1813.35 1015.76V1015.77Z" fill="white"/>
                    <path d="M1737.01 1156.56C1737.55 1156.08 1738.67 1156.13 1738.97 1156.54C1739.28 1156.97 1738.92 1157.21 1738.67 1157.5C1738.17 1158.08 1737.52 1158.14 1736.98 1157.76C1736.82 1157.65 1736.73 1156.84 1737.01 1156.56Z" fill="white"/>
                    <path d="M1899.78 1200.32C1899.42 1199.97 1899.06 1199.57 1899.54 1199.31C1900.13 1198.99 1900.83 1198.89 1901.49 1198.69C1901.54 1199.38 1901.54 1200 1900.9 1200.52C1900.29 1201.01 1900.06 1200.52 1899.79 1200.32H1899.78Z" fill="white"/>
                    <path d="M1751.36 1231.72C1750.71 1231.21 1750.95 1230.88 1751.32 1230.44C1751.9 1229.75 1752.26 1230.05 1752.74 1230.52C1753.24 1231 1753.05 1231.29 1752.66 1231.73C1752.09 1232.37 1751.68 1232.25 1751.35 1231.72H1751.36Z" fill="white"/>
                    <path d="M1912.39 1197.45C1912.83 1197.14 1913.14 1196.25 1913.77 1197.06C1914.11 1197.51 1914.14 1198.17 1913.75 1198.66C1913.1 1199.48 1912.51 1198.49 1911.99 1198.37C1911.35 1198.22 1912.32 1197.81 1912.39 1197.44V1197.45Z" fill="white"/>
                    <path d="M2000.02 1179.51C1999.81 1179.3 1999.56 1179.12 1999.4 1178.88C1999.1 1178.44 1999.44 1178.18 1999.73 1177.92C1999.94 1177.74 2000.07 1177.39 2000.43 1177.49C2000.85 1177.61 2001.05 1177.9 2001.01 1178.34C2001 1178.54 2001.01 1178.73 2001.01 1178.93C2001.01 1179.31 2001.12 1179.91 2000.94 1180.01C2000.55 1180.24 2000.31 1179.73 2000.02 1179.5V1179.51Z" fill="white"/>
                    <path d="M1728.34 1099.48C1727.7 1099.82 1727.49 1099.22 1727.17 1098.93C1726.57 1098.38 1727.2 1098.1 1727.48 1097.77C1727.74 1097.46 1728.06 1097.2 1728.44 1097.64C1728.82 1098.09 1728.76 1099.16 1728.34 1099.48Z" fill="white"/>
                    <path d="M1838.04 1215.89C1838.42 1215.53 1838.72 1215.24 1839.1 1215.69C1839.48 1216.15 1839.43 1217.24 1839 1217.53C1838.56 1217.83 1838.33 1217.43 1838.03 1217.21C1837.29 1216.66 1837.64 1216.24 1838.05 1215.9L1838.04 1215.89Z" fill="white"/>
                    <path d="M1953 1193.46C1952.82 1193.56 1952.53 1193.86 1952.36 1193.8C1951.9 1193.64 1952.12 1193.13 1952.18 1192.86C1952.28 1192.46 1951.56 1191.61 1952.72 1191.67C1953.1 1191.69 1953.52 1191.6 1953.75 1192C1954.21 1192.81 1953.22 1192.89 1953 1193.45V1193.46Z" fill="white"/>
                    <path d="M1915.25 1041.72C1915.17 1041.25 1914.77 1040.48 1915.38 1040.39C1915.9 1040.31 1916.26 1039.54 1916.81 1040C1917.14 1040.27 1916.54 1041.75 1916.01 1041.98C1915.73 1042.1 1915.46 1042 1915.25 1041.72Z" fill="white"/>
                    <path d="M1762.98 1216.86C1762.98 1216.86 1762.45 1216.75 1761.99 1216.58C1761.79 1216.51 1762.03 1215.14 1762.27 1214.99C1762.9 1214.58 1763.09 1215.23 1763.43 1215.49C1763.85 1215.81 1763.7 1216.14 1762.97 1216.86H1762.98Z" fill="white"/>
                    <path d="M1741.18 1188.52C1740.83 1187.89 1741.45 1187.7 1741.72 1187.36C1742.06 1186.93 1742.49 1187.08 1742.64 1187.44C1742.82 1187.86 1742.81 1188.37 1742.78 1188.84C1742.78 1189.01 1741.33 1188.74 1741.18 1188.52Z" fill="white"/>
                    <path d="M1766.22 1005.45C1766.51 1005 1766.83 1005.02 1767.04 1005.23C1767.59 1005.78 1767.54 1006.42 1767.16 1007.05C1766.97 1007.37 1766.64 1007.35 1766.42 1007.14C1765.83 1006.59 1765.96 1005.95 1766.22 1005.45Z" fill="white"/>
                    <path d="M1921.82 1088.99C1921.31 1089.38 1920.68 1089.43 1920.31 1089.07C1920.13 1088.89 1920.04 1088.15 1920.47 1087.74C1921.04 1087.18 1921.3 1087.78 1921.64 1088.05C1921.95 1088.31 1922.18 1088.61 1921.82 1088.99Z" fill="white"/>
                    <path d="M1711.5 1088.87C1711.67 1088.64 1713.07 1088.39 1713.08 1088.56C1713.11 1089.03 1713.12 1089.54 1712.95 1089.96C1712.79 1090.34 1712.35 1090.44 1712.03 1090.03C1711.76 1089.69 1711.12 1089.51 1711.5 1088.88V1088.87Z" fill="white"/>
                    <path d="M1927.31 1091.86C1927.05 1091.36 1926.91 1090.73 1927.48 1090.17C1927.69 1089.97 1928.02 1089.93 1928.22 1090.25C1928.61 1090.87 1928.67 1091.51 1928.13 1092.07C1927.93 1092.28 1927.61 1092.3 1927.31 1091.86Z" fill="white"/>
                    <path d="M1984.23 1054.96C1983.81 1054.59 1983.94 1054.17 1984.31 1054.02C1984.73 1053.84 1985.24 1053.85 1985.71 1053.88C1985.88 1053.88 1985.61 1055.33 1985.39 1055.48C1985.07 1055.7 1984.62 1055.49 1984.23 1054.96Z" fill="white"/>
                    <path d="M1907.82 1195.44C1907.52 1195.14 1907.23 1194.84 1907.66 1194.47C1908.13 1194.06 1908.77 1194.03 1909.13 1194.4C1909.31 1194.58 1909.4 1195.32 1908.97 1195.73C1908.39 1196.29 1908.15 1195.67 1907.82 1195.44Z" fill="white"/>
                    <path d="M1990.97 1147.52C1990.8 1146.98 1990.69 1146.46 1991.07 1145.97C1991.29 1145.68 1991.62 1145.68 1991.8 1145.91C1992.1 1146.3 1992.98 1146.5 1992.35 1147.26C1991.97 1147.72 1991.43 1147.38 1990.96 1147.52H1990.97Z" fill="white"/>
                    <path d="M1907.45 1181.45C1907.42 1181.27 1907.39 1181.09 1907.36 1180.91C1908.23 1180.88 1909.13 1181 1909.92 1180.48C1909.96 1180.46 1910.24 1180.72 1910.32 1180.89C1910.36 1180.99 1910.21 1181.3 1910.17 1181.29C1909.37 1181.21 1908.75 1182.04 1907.92 1181.75C1907.75 1181.69 1907.6 1181.54 1907.44 1181.44L1907.45 1181.45Z" fill="white"/>
                    <path d="M1909.94 1177.43C1910.38 1177.03 1910.71 1177.81 1911 1178.16C1911.59 1178.87 1910.69 1179.09 1910.41 1179.49C1910.32 1179.61 1909.71 1179.77 1909.95 1179.3C1910.3 1178.6 1908.8 1178.36 1909.95 1177.42L1909.94 1177.43Z" fill="white"/>
                    <path d="M1768.58 1053.32C1768.16 1053.92 1767.52 1053.58 1766.98 1053.68C1766.97 1053.3 1766.79 1052.76 1766.97 1052.59C1767.37 1052.21 1767.94 1052.26 1768.43 1052.59C1768.73 1052.79 1768.69 1053.05 1768.57 1053.33L1768.58 1053.32Z" fill="white"/>
                    <path d="M1770.04 1216.62C1770.38 1216.36 1770.62 1215.75 1771.22 1216.31C1771.65 1216.71 1771.4 1217.01 1771.1 1217.28C1770.77 1217.58 1770.52 1218.16 1769.92 1217.61C1769.49 1217.21 1769.76 1216.93 1770.04 1216.62Z" fill="white"/>
                    <path d="M1826.23 996.77C1826.02 995.79 1826.32 995.23 1826.91 994.8C1827.05 994.7 1827.26 994.71 1827.44 994.68C1827.44 994.88 1827.41 995.08 1827.45 995.27C1827.63 995.98 1827.12 996.34 1826.7 996.71C1826.57 996.82 1826.29 996.77 1826.23 996.77Z" fill="white"/>
                    <path d="M1744.84 1234.75C1744.73 1234.65 1744.94 1234.33 1745.25 1234.05C1745.58 1233.76 1745.83 1233.15 1746.43 1233.7C1746.85 1234.09 1746.64 1234.39 1746.33 1234.67C1746 1234.97 1745.74 1235.55 1744.84 1234.74V1234.75Z" fill="white"/>
                    <path d="M1806.24 1215.46C1806.37 1215.43 1806.59 1215.31 1806.66 1215.37C1807.19 1215.8 1807.69 1216.23 1807.55 1217.06C1807.49 1217.4 1807.42 1217.58 1807.09 1217.47C1806.58 1217.29 1806 1215.9 1806.24 1215.47V1215.46Z" fill="white"/>
                    <path d="M1767.95 1104.05C1767.45 1103.22 1768.41 1103.11 1768.69 1102.69C1768.82 1102.5 1769.26 1102.69 1769.41 1102.96C1769.86 1103.75 1769 1103.94 1768.68 1104.33C1768.53 1104.51 1768.13 1104.33 1767.95 1104.05Z" fill="white"/>
                    <path d="M1862.28 1174.55C1861.78 1173.72 1862.74 1173.61 1863.02 1173.19C1863.15 1173 1863.59 1173.19 1863.74 1173.46C1864.19 1174.25 1863.33 1174.44 1863.01 1174.83C1862.86 1175.01 1862.46 1174.83 1862.28 1174.55Z" fill="white"/>
                    <path d="M1888.8 1041.35C1888.69 1041.25 1888.9 1040.93 1889.21 1040.65C1889.54 1040.36 1889.79 1039.75 1890.39 1040.3C1890.81 1040.69 1890.6 1040.99 1890.29 1041.27C1889.96 1041.57 1889.7 1042.15 1888.8 1041.34V1041.35Z" fill="white"/>
                    <path d="M1910.3 1238.49C1910.91 1238.66 1911.54 1238.84 1912.16 1239.03C1912.19 1239.03 1912.2 1239.24 1912.16 1239.31C1911.93 1239.66 1911.61 1239.78 1911.17 1239.77C1910.68 1239.75 1910.18 1239.8 1910.03 1239.2C1909.99 1239.06 1910.15 1238.87 1910.3 1238.49Z" fill="white"/>
                    <path d="M1933.18 1187.55C1932.92 1187.86 1932.66 1188.21 1932.22 1187.87C1931.65 1187.44 1931.91 1187.06 1932.33 1186.69C1932.62 1186.43 1932.86 1186.05 1933.3 1186.39C1933.86 1186.82 1933.64 1187.21 1933.19 1187.56L1933.18 1187.55Z" fill="white"/>
                    <path d="M1944.91 1127.19C1944.64 1126.7 1944.56 1126.42 1944.88 1126.22C1945.5 1125.83 1946.13 1125.78 1946.7 1126.31C1946.81 1126.41 1946.79 1126.66 1946.83 1126.84C1946.17 1126.96 1945.5 1127.08 1944.91 1127.19Z" fill="white"/>
                    <path d="M1852.61 1205.06C1852.18 1204.74 1851.96 1204.36 1852.15 1204.2C1852.53 1203.87 1852.71 1203.01 1853.51 1203.44C1853.78 1203.59 1853.98 1203.97 1853.79 1204.17C1853.44 1204.55 1853.18 1205.22 1852.61 1205.06Z" fill="white"/>
                    <path d="M1869.83 1034.61C1869.55 1034.31 1869.27 1034.03 1869.69 1033.63C1870.27 1033.07 1870.54 1033.65 1870.87 1033.94C1871.18 1034.21 1871.43 1034.5 1871 1034.91C1870.42 1035.48 1870.17 1034.87 1869.82 1034.62L1869.83 1034.61Z" fill="white"/>
                    <path d="M1805.29 1100.05C1804.46 1100.55 1804.35 1099.59 1803.93 1099.31C1803.74 1099.18 1803.93 1098.74 1804.2 1098.59C1804.99 1098.14 1805.18 1099 1805.57 1099.32C1805.75 1099.47 1805.57 1099.87 1805.29 1100.05Z" fill="white"/>
                    <path d="M1954.18 1133.21C1953.92 1133.52 1953.66 1133.87 1953.22 1133.53C1952.65 1133.1 1952.91 1132.72 1953.33 1132.35C1953.62 1132.09 1953.86 1131.71 1954.3 1132.05C1954.86 1132.48 1954.64 1132.87 1954.19 1133.22L1954.18 1133.21Z" fill="white"/>
                    <path d="M1846.59 1263.53C1846.15 1264.07 1845.88 1264.05 1845.63 1263.85C1845.07 1263.4 1845.35 1263.03 1845.77 1262.69C1845.98 1262.51 1846.33 1262.07 1846.43 1262.29C1846.64 1262.73 1846.57 1263.3 1846.59 1263.54V1263.53Z" fill="white"/>
                    <path d="M1834.36 1020.24C1834.3 1020.09 1834.21 1019.83 1834.12 1019.58C1834.67 1019.6 1835.28 1019.3 1835.72 1019.86C1835.91 1020.1 1835.92 1020.35 1835.65 1020.61C1835.08 1021.16 1834.82 1020.59 1834.37 1020.24H1834.36Z" fill="white"/>
                    <path d="M2012.61 1149.16C2012.76 1148.93 2013.06 1148.94 2013.3 1149.13C2013.62 1149.37 2013.89 1149.72 2013.44 1150.07C2013.12 1150.31 2012.92 1151.07 2012.41 1150.59C2012.23 1150.42 2012.15 1149.66 2012.62 1149.16H2012.61Z" fill="white"/>
                    <path d="M1885.39 1286.72C1884.83 1286.29 1885.15 1285.66 1885.08 1285.11C1885.33 1285.22 1885.63 1285.27 1885.82 1285.45C1886.13 1285.75 1886.71 1286.01 1886.17 1286.61C1885.93 1286.88 1885.69 1286.86 1885.4 1286.72H1885.39Z" fill="white"/>
                    <path d="M1985.96 1127.78C1986.18 1127.42 1985.86 1127.73 1986.25 1127.79C1986.46 1127.83 1986.77 1128.14 1986.78 1128.33C1986.8 1128.87 1987.05 1129.51 1986.4 1129.89C1986.33 1129.93 1986.15 1129.94 1986.13 1129.9C1985.74 1129.21 1986.07 1128.47 1985.97 1127.77L1985.96 1127.78Z" fill="white"/>
                    <path d="M1929.59 1122.12C1929.63 1121.56 1929.36 1120.89 1930.03 1120.56C1930.19 1120.48 1930.62 1120.68 1930.75 1120.87C1931.17 1121.47 1930.49 1121.68 1930.24 1122.03C1930.07 1122.27 1929.85 1122.38 1929.59 1122.12Z" fill="white"/>
                    <path d="M1833.93 1139.9C1833.79 1139.76 1833.6 1139.64 1833.52 1139.47C1833.47 1139.36 1833.52 1139.08 1833.61 1139.04C1833.91 1138.91 1835.26 1140.2 1835.19 1140.56C1835.17 1140.64 1834.85 1140.77 1834.79 1140.72C1834.48 1140.47 1834.21 1140.18 1833.93 1139.9Z" fill="white"/>
                    <path d="M1952.63 1025.62C1952.16 1025.21 1952.35 1024.88 1952.67 1024.62C1952.91 1024.43 1953.16 1024.42 1953.43 1024.69C1953.83 1025.09 1953.65 1025.4 1953.33 1025.65C1953.1 1025.83 1952.8 1025.92 1952.63 1025.62Z" fill="white"/>
                    <path d="M1866.67 1280.26C1866.51 1279.97 1866.47 1279.75 1866.74 1279.48C1867.14 1279.08 1867.44 1279.26 1867.71 1279.58C1867.9 1279.82 1867.91 1280.07 1867.64 1280.34C1867.24 1280.74 1866.96 1280.53 1866.67 1280.26Z" fill="white"/>
                    <path d="M1731.84 1102.68C1731.68 1102.39 1731.64 1102.17 1731.91 1101.9C1732.31 1101.5 1732.61 1101.68 1732.88 1102C1733.07 1102.24 1733.08 1102.49 1732.81 1102.76C1732.41 1103.16 1732.13 1102.95 1731.84 1102.68Z" fill="white"/>
                    <path d="M1803.68 1012.37C1803.95 1012.11 1804.22 1011.9 1804.63 1012.29C1804.9 1012.55 1804.9 1012.81 1804.71 1013.05C1804.45 1013.38 1804.15 1013.56 1803.75 1013.17C1803.48 1012.91 1803.5 1012.68 1803.68 1012.38V1012.37Z" fill="white"/>
                    <path d="M1766.49 1240.93C1765.97 1241.13 1765.45 1241.2 1764.98 1240.85C1764.85 1240.76 1764.75 1240.4 1764.76 1240.39C1765.26 1240.12 1765.77 1240.06 1766.25 1240.43C1766.37 1240.53 1766.4 1240.75 1766.49 1240.93Z" fill="white"/>
                    <path d="M1722.8 1141.51C1722.5 1141.31 1722.54 1141.02 1722.73 1140.78C1722.99 1140.45 1723.29 1140.27 1723.69 1140.66C1723.96 1140.92 1723.96 1141.17 1723.77 1141.42C1723.51 1141.75 1723.18 1141.95 1722.8 1141.52V1141.51Z" fill="white"/>
                    <path d="M1825.17 1205.42C1825.01 1205.13 1824.97 1204.91 1825.24 1204.64C1825.64 1204.24 1825.94 1204.42 1826.21 1204.74C1826.4 1204.98 1826.41 1205.23 1826.14 1205.5C1825.74 1205.9 1825.46 1205.69 1825.17 1205.42Z" fill="white"/>
                    <path d="M1991.25 1080.63C1991.06 1080.3 1991.03 1080.07 1991.29 1079.8C1991.69 1079.4 1991.99 1079.58 1992.26 1079.9C1992.45 1080.14 1992.45 1080.39 1992.19 1080.66C1991.79 1081.07 1991.52 1080.82 1991.25 1080.63Z" fill="white"/>
                    <path d="M1977.73 1101.44C1978.11 1101 1978.44 1101.19 1978.7 1101.52C1978.89 1101.76 1978.9 1102.01 1978.63 1102.28C1978.23 1102.68 1977.93 1102.5 1977.66 1102.18C1977.47 1101.94 1977.42 1101.65 1977.72 1101.45L1977.73 1101.44Z" fill="white"/>
                    <path d="M1724.45 1237.68C1724.72 1237.96 1725 1238.26 1724.57 1238.62C1724.44 1238.73 1724.25 1238.78 1724.09 1238.86C1724.03 1238.43 1723.97 1238 1723.91 1237.57C1724.09 1237.6 1724.27 1237.64 1724.46 1237.67L1724.45 1237.68Z" fill="white"/>
                    <path d="M1746.12 1187.41C1745.85 1187.13 1745.57 1186.83 1746 1186.47C1746.13 1186.36 1746.32 1186.31 1746.48 1186.23C1746.54 1186.66 1746.6 1187.09 1746.66 1187.52C1746.48 1187.49 1746.3 1187.45 1746.11 1187.42L1746.12 1187.41Z" fill="white"/>
                    <path d="M1867.66 1257.23C1867.65 1256.82 1867.64 1256.43 1867.62 1256.05C1867.87 1256.15 1868.19 1256.2 1868.35 1256.38C1868.7 1256.78 1868.37 1257.08 1868.06 1257.31C1867.98 1257.37 1867.77 1257.26 1867.66 1257.24V1257.23Z" fill="white"/>
                    <path d="M1811.3 1093.92C1811.51 1093.82 1811.77 1093.64 1811.82 1093.69C1812.21 1094.05 1811.92 1094.54 1812 1094.96C1811.82 1094.92 1811.6 1094.94 1811.47 1094.84C1811.14 1094.59 1810.97 1094.27 1811.29 1093.92H1811.3Z" fill="white"/>
                    <path d="M1764.52 1010.29C1764.49 1010.14 1764.38 1009.94 1764.45 1009.85C1764.67 1009.54 1764.97 1009.2 1765.37 1009.55C1765.55 1009.71 1765.6 1010.03 1765.7 1010.28C1765.31 1010.28 1764.92 1010.28 1764.52 1010.29Z" fill="white"/>
                    <path d="M1768.74 1040.14C1768.37 1040.55 1768.06 1040.32 1767.8 1040.01C1767.7 1039.88 1767.71 1039.66 1767.68 1039.48C1768.11 1039.53 1768.54 1039.59 1768.97 1039.64C1768.9 1039.8 1768.82 1039.97 1768.74 1040.14Z" fill="white"/>
                    <path d="M1858.93 1234.14C1858.91 1234.06 1858.79 1233.86 1858.85 1233.78C1859.07 1233.47 1859.36 1233.13 1859.77 1233.46C1859.96 1233.61 1860.01 1233.94 1860.12 1234.18C1859.74 1234.17 1859.35 1234.15 1858.93 1234.14Z" fill="white"/>
                    <path d="M1832.11 1205.42C1831.7 1205.05 1831.93 1204.74 1832.24 1204.48C1832.37 1204.38 1832.59 1204.39 1832.77 1204.36C1832.72 1204.79 1832.66 1205.22 1832.61 1205.65C1832.45 1205.58 1832.28 1205.5 1832.11 1205.42Z" fill="white"/>
                    <path d="M1726.8 1067.06C1726.75 1066.88 1726.71 1066.7 1726.66 1066.53C1727.09 1066.56 1727.52 1066.59 1727.95 1066.62C1727.88 1066.79 1727.85 1066.98 1727.74 1067.12C1727.41 1067.55 1727.09 1067.35 1726.79 1067.07L1726.8 1067.06Z" fill="white"/>
                    <path d="M1960.04 1093.43C1959.67 1093.84 1959.36 1093.61 1959.1 1093.3C1959 1093.17 1959.01 1092.95 1958.98 1092.77C1959.41 1092.82 1959.84 1092.88 1960.27 1092.93C1960.2 1093.09 1960.12 1093.26 1960.04 1093.43Z" fill="white"/>
                    <path d="M1987.54 1062.02C1987.29 1061.96 1987.08 1061.97 1986.95 1061.87C1986.62 1061.61 1986.45 1061.27 1986.86 1060.94C1986.99 1060.83 1987.19 1060.79 1987.35 1060.72C1987.41 1061.15 1987.47 1061.57 1987.54 1062.03V1062.02Z" fill="white"/>
                    <path d="M1926.27 1278.18C1926.16 1277.79 1926.48 1277.64 1926.68 1277.46C1926.74 1277.4 1927.06 1277.49 1927.09 1277.57C1927.23 1277.93 1926.89 1278.08 1926.69 1278.26C1926.62 1278.32 1926.4 1278.21 1926.27 1278.18Z" fill="white"/>
                    <path d="M1900.44 1272.18C1900.14 1272.02 1899.87 1271.93 1899.68 1271.76C1899.57 1271.66 1899.59 1271.41 1899.55 1271.23C1899.8 1271.33 1900.08 1271.4 1900.29 1271.56C1900.4 1271.65 1900.38 1271.9 1900.44 1272.18Z" fill="white"/>
                    <path d="M1895.88 1004.69C1895.77 1004.3 1896.09 1004.15 1896.29 1003.97C1896.35 1003.91 1896.67 1004 1896.71 1004.08C1896.85 1004.44 1896.51 1004.59 1896.31 1004.77C1896.24 1004.83 1896.02 1004.72 1895.89 1004.69H1895.88Z" fill="white"/>
                    <path d="M1783.27 1015.3C1783.43 1015 1783.52 1014.73 1783.69 1014.54C1783.79 1014.43 1784.04 1014.45 1784.22 1014.41C1784.12 1014.66 1784.05 1014.94 1783.89 1015.15C1783.8 1015.26 1783.55 1015.24 1783.27 1015.3Z" fill="white"/>
                    <path d="M1781.28 1017.89C1781.4 1017.79 1781.58 1017.57 1781.81 1017.45C1782.08 1017.3 1782.24 1017.45 1782.09 1017.72C1781.96 1017.94 1781.76 1018.16 1781.53 1018.29C1781.27 1018.44 1781.15 1018.31 1781.28 1017.89Z" fill="white"/>
                    <path d="M1889.48 987.47C1889.31 987.54 1889.07 987.72 1888.99 987.66C1888.69 987.45 1888.82 987.2 1889.06 987.03C1889.2 986.93 1889.46 986.78 1889.54 986.83C1889.84 987.04 1889.7 987.27 1889.47 987.46L1889.48 987.47Z" fill="white"/>
                    <path d="M1865.83 1273.46C1865.76 1273.29 1865.58 1273.05 1865.64 1272.97C1865.85 1272.67 1866.1 1272.8 1866.27 1273.04C1866.37 1273.18 1866.52 1273.44 1866.47 1273.52C1866.26 1273.82 1866.03 1273.68 1865.84 1273.45L1865.83 1273.46Z" fill="white"/>
                    <path d="M1730.65 1077.3C1730.35 1077.14 1730.08 1077.05 1729.89 1076.88C1729.78 1076.78 1729.8 1076.53 1729.76 1076.35C1730.01 1076.45 1730.29 1076.52 1730.5 1076.68C1730.61 1076.77 1730.59 1077.02 1730.65 1077.3Z" fill="white"/>
                    <path d="M1983.33 1160.52C1983.3 1160.39 1983.26 1160.21 1983.23 1160.03C1983.48 1160.13 1983.76 1160.2 1983.97 1160.36C1984.08 1160.45 1984.06 1160.7 1984.1 1160.88C1983.79 1160.92 1983.53 1160.83 1983.34 1160.52H1983.33Z" fill="white"/>
                    <path d="M1699.78 1112.09C1699.97 1111.86 1700.2 1111.72 1700.41 1112.01C1700.47 1112.09 1700.32 1112.35 1700.22 1112.5C1700.05 1112.74 1699.8 1112.88 1699.59 1112.58C1699.53 1112.5 1699.71 1112.26 1699.77 1112.09H1699.78Z" fill="white"/>
                    <path d="M1954.8 1130.85C1954.99 1130.62 1955.22 1130.48 1955.43 1130.77C1955.49 1130.85 1955.34 1131.11 1955.24 1131.26C1955.07 1131.5 1954.82 1131.64 1954.61 1131.34C1954.55 1131.26 1954.73 1131.02 1954.79 1130.85H1954.8Z" fill="white"/>
                    <path d="M1941.91 1130.97C1942.1 1130.74 1942.33 1130.6 1942.54 1130.89C1942.6 1130.97 1942.45 1131.23 1942.35 1131.38C1942.18 1131.62 1941.93 1131.76 1941.72 1131.46C1941.66 1131.38 1941.84 1131.14 1941.9 1130.97H1941.91Z" fill="white"/>
                    <path d="M1894.94 1138.14C1894.64 1137.98 1894.37 1137.89 1894.18 1137.72C1894.07 1137.62 1894.09 1137.37 1894.05 1137.19C1894.3 1137.29 1894.58 1137.36 1894.79 1137.52C1894.9 1137.61 1894.88 1137.86 1894.94 1138.14Z" fill="white"/>
                    <path d="M1862.58 1243.93C1862.51 1243.71 1862.42 1243.46 1862.34 1243.2C1862.52 1243.24 1862.78 1243.2 1862.87 1243.32C1863.04 1243.52 1863.1 1243.8 1863.21 1244.05C1863.03 1244.01 1862.85 1243.98 1862.58 1243.93Z" fill="white"/>
                    <path d="M1813.99 1160.32C1813.96 1160.19 1813.92 1160.01 1813.89 1159.83C1814.14 1159.93 1814.42 1160 1814.63 1160.16C1814.74 1160.25 1814.72 1160.5 1814.76 1160.68C1814.45 1160.72 1814.19 1160.63 1814 1160.32H1813.99Z" fill="white"/>
                    <path d="M1775.91 1188.21C1775.61 1188.05 1775.34 1187.96 1775.15 1187.79C1775.04 1187.69 1775.06 1187.44 1775.02 1187.26C1775.27 1187.36 1775.55 1187.43 1775.76 1187.59C1775.87 1187.68 1775.85 1187.93 1775.91 1188.21Z" fill="white"/>
                    <path d="M1716.56 1232.37C1716.45 1231.98 1716.77 1231.83 1716.97 1231.65C1717.03 1231.59 1717.35 1231.68 1717.39 1231.76C1717.53 1232.12 1717.19 1232.27 1716.99 1232.45C1716.92 1232.51 1716.7 1232.4 1716.57 1232.37H1716.56Z" fill="white"/>
                    <path d="M1737.25 1169.34C1737.44 1169.11 1737.67 1168.97 1737.88 1169.26C1737.94 1169.34 1737.79 1169.6 1737.69 1169.75C1737.52 1169.99 1737.27 1170.13 1737.06 1169.83C1737 1169.75 1737.18 1169.51 1737.24 1169.34H1737.25Z" fill="white"/>
                    <path d="M1893.24 1023.53C1893.07 1023.6 1892.83 1023.78 1892.75 1023.72C1892.45 1023.51 1892.58 1023.26 1892.82 1023.09C1892.96 1022.99 1893.22 1022.84 1893.3 1022.89C1893.6 1023.1 1893.46 1023.33 1893.23 1023.52L1893.24 1023.53Z" fill="white"/>
                    <path d="M1835.95 1104.4C1835.88 1104.23 1835.7 1103.99 1835.76 1103.91C1835.97 1103.61 1836.22 1103.74 1836.39 1103.98C1836.49 1104.12 1836.64 1104.38 1836.59 1104.46C1836.38 1104.76 1836.15 1104.62 1835.96 1104.39L1835.95 1104.4Z" fill="white"/>
                    <path d="M1824.04 1093.99C1823.9 1093.94 1823.74 1093.92 1823.62 1093.84C1823.58 1093.81 1823.62 1093.66 1823.62 1093.56C1823.76 1093.61 1823.92 1093.63 1824.04 1093.71C1824.08 1093.74 1824.04 1093.89 1824.04 1093.99Z" fill="white"/>
                    <path d="M1717.17 1062.29C1717.03 1062.24 1716.87 1062.22 1716.75 1062.14C1716.71 1062.11 1716.75 1061.96 1716.75 1061.86C1716.89 1061.91 1717.05 1061.93 1717.17 1062.01C1717.21 1062.04 1717.17 1062.19 1717.17 1062.29Z" fill="white"/>
                    <path d="M1931.14 1137.29C1931.1 1137.15 1931.06 1137.01 1931.01 1136.86C1931.11 1136.86 1931.26 1136.82 1931.29 1136.86C1931.37 1136.98 1931.4 1137.14 1931.45 1137.28C1931.35 1137.28 1931.26 1137.28 1931.14 1137.28V1137.29Z" fill="white"/>
                    <path d="M1972.93 1114.69C1972.93 1114.8 1972.93 1114.9 1972.93 1115C1972.79 1114.95 1972.63 1114.93 1972.51 1114.85C1972.47 1114.82 1972.51 1114.67 1972.51 1114.57C1972.66 1114.61 1972.8 1114.65 1972.94 1114.69H1972.93Z" fill="white"/>
                    <path d="M1912.05 1178.84C1912.19 1178.8 1912.33 1178.76 1912.48 1178.71C1912.48 1178.81 1912.52 1178.96 1912.48 1178.99C1912.36 1179.07 1912.2 1179.1 1912.06 1179.15C1912.06 1179.05 1912.06 1178.96 1912.06 1178.84H1912.05Z" fill="white"/>
                    <path d="M1864.76 1177.86C1864.81 1177.72 1864.83 1177.56 1864.91 1177.44C1864.94 1177.4 1865.09 1177.44 1865.19 1177.44C1865.14 1177.58 1865.12 1177.74 1865.04 1177.86C1865.01 1177.9 1864.86 1177.86 1864.76 1177.86Z" fill="white"/>
                    <path d="M1826.49 1209.57C1826.6 1209.57 1826.7 1209.57 1826.8 1209.57C1826.75 1209.71 1826.73 1209.87 1826.65 1209.99C1826.62 1210.03 1826.47 1209.99 1826.37 1209.99C1826.41 1209.84 1826.45 1209.7 1826.49 1209.56V1209.57Z" fill="white"/>
                    <path d="M1854.06 1264.34C1854.02 1264.2 1853.98 1264.06 1853.93 1263.91C1854.03 1263.91 1854.18 1263.87 1854.21 1263.91C1854.29 1264.03 1854.32 1264.19 1854.37 1264.33C1854.27 1264.33 1854.18 1264.33 1854.06 1264.33V1264.34Z" fill="white"/>
                    <path d="M1927.4 1166C1927.4 1165.89 1927.4 1165.79 1927.4 1165.69C1927.54 1165.74 1927.7 1165.76 1927.82 1165.84C1927.86 1165.87 1927.82 1166.02 1927.82 1166.12C1927.67 1166.08 1927.53 1166.04 1927.39 1166H1927.4Z" fill="white"/>
                    <path d="M1853.32 1148C1853.32 1148.11 1853.32 1148.21 1853.32 1148.31C1853.18 1148.26 1853.02 1148.24 1852.9 1148.16C1852.86 1148.13 1852.9 1147.98 1852.9 1147.88C1853.05 1147.92 1853.19 1147.96 1853.33 1148H1853.32Z" fill="white"/>
                    <path d="M1741.95 1244.29C1741.85 1244.29 1741.69 1244.31 1741.68 1244.29C1741.64 1244.15 1741.58 1243.97 1741.62 1243.84C1741.71 1243.57 1741.89 1243.57 1741.98 1243.84C1742.03 1243.97 1741.96 1244.14 1741.95 1244.29Z" fill="white"/>
                    <path d="M1741.6 1243.45C1741.62 1243.3 1741.59 1243.06 1741.66 1243.01C1741.88 1242.89 1742.04 1242.99 1742 1243.18C1741.96 1243.34 1741.87 1243.48 1741.8 1243.64C1741.74 1243.58 1741.67 1243.51 1741.61 1243.45H1741.6Z" fill="white"/>
                    <path d="M1832.49 1064.26C1832.45 1064.12 1832.41 1063.98 1832.36 1063.83C1832.46 1063.83 1832.61 1063.79 1832.64 1063.83C1832.72 1063.95 1832.75 1064.11 1832.8 1064.25C1832.7 1064.25 1832.61 1064.25 1832.49 1064.25V1064.26Z" fill="white"/>
                    <path d="M1805.01 1305.61C1804.97 1305.47 1804.93 1305.33 1804.88 1305.18C1804.98 1305.18 1805.13 1305.14 1805.16 1305.18C1805.24 1305.3 1805.27 1305.46 1805.32 1305.6C1805.22 1305.6 1805.13 1305.6 1805.01 1305.6V1305.61Z" fill="white"/>
                    <path d="M1733.86 1245.09C1733.86 1244.98 1733.86 1244.88 1733.86 1244.78C1734 1244.83 1734.16 1244.85 1734.28 1244.93C1734.32 1244.96 1734.28 1245.11 1734.28 1245.21C1734.13 1245.17 1733.99 1245.13 1733.85 1245.09H1733.86Z" fill="white"/>
                    <path d="M1842.36 1281.2C1842.4 1281.34 1842.44 1281.48 1842.49 1281.63C1842.39 1281.63 1842.24 1281.67 1842.21 1281.63C1842.13 1281.51 1842.1 1281.35 1842.05 1281.21C1842.15 1281.21 1842.24 1281.21 1842.36 1281.21V1281.2Z" fill="white"/>
                    <path d="M1776.51 1147.28C1776.47 1147.14 1776.43 1147 1776.38 1146.85C1776.48 1146.85 1776.63 1146.81 1776.66 1146.85C1776.74 1146.97 1776.77 1147.13 1776.82 1147.27C1776.72 1147.27 1776.63 1147.27 1776.51 1147.27V1147.28Z" fill="white"/>
                    <path d="M1903 1209.75C1903.11 1209.75 1903.21 1209.75 1903.31 1209.75C1903.26 1209.89 1903.24 1210.05 1903.16 1210.17C1903.13 1210.21 1902.98 1210.17 1902.88 1210.17C1902.92 1210.02 1902.96 1209.88 1903 1209.74V1209.75Z" fill="white"/>
                    <path d="M1747.75 1212.43C1747.61 1212.38 1747.45 1212.36 1747.33 1212.28C1747.29 1212.25 1747.33 1212.1 1747.33 1212C1747.47 1212.05 1747.63 1212.07 1747.75 1212.15C1747.79 1212.18 1747.75 1212.33 1747.75 1212.43Z" fill="white"/>
                    <path d="M1895.79 1220.98C1895.75 1220.84 1895.71 1220.7 1895.66 1220.55C1895.76 1220.55 1895.91 1220.51 1895.94 1220.55C1896.02 1220.67 1896.05 1220.83 1896.1 1220.97H1895.79V1220.98Z" fill="white"/>
                    <path d="M1838.85 1116.08C1838.71 1116.12 1838.57 1116.16 1838.42 1116.21C1838.42 1116.11 1838.38 1115.96 1838.42 1115.93C1838.54 1115.85 1838.7 1115.82 1838.84 1115.77C1838.84 1115.87 1838.84 1115.96 1838.84 1116.08H1838.85Z" fill="white"/>
                    <path d="M1882.77 981.29C1882.63 981.24 1882.47 981.22 1882.35 981.14C1882.31 981.11 1882.35 980.96 1882.35 980.86C1882.49 980.91 1882.65 980.93 1882.77 981.01C1882.81 981.04 1882.77 981.19 1882.77 981.29Z" fill="white"/>
                    <path d="M1869.91 1166.6C1870.13 1168.39 1868.94 1169.15 1867.81 1169.83C1867.39 1170.08 1866.69 1170.38 1866.53 1170.58C1865.46 1171.91 1864.58 1170.09 1863.64 1170.54C1863.35 1169.56 1862.67 1168.48 1862.88 1167.62C1863.08 1166.8 1863.41 1165.8 1864.02 1165.08C1864.59 1164.4 1865.08 1163.18 1866.33 1164.36C1866.51 1164.53 1867.04 1164.33 1867.4 1164.34C1869.02 1164.38 1869.86 1165.18 1869.93 1166.6H1869.91Z" fill="white"/>
                    <path d="M1754.04 1077.76C1755.11 1077.73 1755.9 1078.54 1755.91 1078.92C1755.94 1080.37 1757.29 1080.9 1757.76 1082.03C1758.09 1082.83 1758.16 1083.36 1757.56 1083.94C1756.57 1084.9 1755.95 1085.03 1755.12 1084.47C1754.76 1084.23 1754.39 1084.03 1754 1083.85C1752.31 1083.03 1751.48 1079.31 1753.28 1078.12C1753.6 1077.91 1753.86 1077.73 1754.04 1077.76Z" fill="white"/>
                    <path d="M1772.67 1162.9C1773.34 1163.56 1774.69 1163.29 1774.85 1164.59C1774.87 1164.77 1774.81 1164.99 1774.89 1165.14C1775.22 1165.74 1775.8 1166.27 1775.43 1167.06C1775.14 1167.68 1774.47 1167.81 1773.95 1168.1C1772.91 1168.68 1771.29 1168.2 1770.54 1167.13C1769.95 1166.3 1769.94 1165.48 1770.4 1164.55C1770.88 1163.58 1771.88 1163.42 1772.68 1162.9H1772.67Z" fill="white"/>
                    <path d="M1660.51 1227.93C1658.91 1228 1658.15 1227.45 1657.96 1226.3C1657.86 1225.67 1657.91 1225.01 1657.84 1224.37C1657.78 1223.76 1658.03 1223.57 1658.6 1223.57C1659.24 1223.57 1659.9 1223.42 1660.53 1223.5C1661.6 1223.64 1662.33 1224.33 1662.82 1225.26C1662.92 1225.45 1662.9 1225.84 1662.77 1225.99C1662.28 1226.52 1662.01 1227.16 1661.64 1227.75C1661.46 1228.04 1660.62 1227.91 1660.5 1227.93H1660.51Z" fill="white"/>
                    <path d="M1637.26 1109.13C1637.98 1109.03 1639.01 1108.88 1639.36 1109.99C1639.53 1110.52 1639.85 1111.5 1639.19 1111.74C1638.48 1111.99 1637.9 1112.86 1637 1112.45C1636.38 1112.16 1635.82 1111.64 1635.17 1111.51C1634.58 1111.39 1634.45 1111.21 1634.41 1110.67C1634.36 1109.9 1634.79 1109.45 1635.4 1109.26C1635.91 1109.1 1636.49 1109.18 1637.24 1109.14L1637.26 1109.13Z" fill="white"/>
                    <path d="M1659.85 1247.95C1660.58 1247.9 1661.23 1248.44 1661.31 1249.15C1661.34 1249.39 1660.16 1251.73 1659.96 1251.83C1659.52 1252.05 1658.05 1251.21 1658.12 1251.15C1659.06 1250.46 1657.72 1249.97 1658.02 1249.39C1658.31 1248.83 1659.38 1247.98 1659.85 1247.95Z" fill="white"/>
                    <path d="M1817.84 1202.81C1817.84 1202.43 1817.7 1201.84 1818.07 1201.82C1818.75 1201.78 1819.47 1201.43 1820.15 1202.04C1820.52 1202.38 1820.48 1202.88 1820.78 1203.15C1821.27 1203.6 1821.37 1203.94 1820.91 1204.51C1820.41 1205.12 1819.75 1205.25 1819.25 1204.9C1818.55 1204.42 1817.64 1203.92 1817.84 1202.81Z" fill="white"/>
                    <path d="M1750.16 1134.62C1749.58 1134.62 1748.84 1134.95 1748.83 1133.84C1748.83 1133.59 1748.31 1133.36 1748.06 1133.1C1747.77 1132.8 1747.5 1132.48 1747.23 1132.17C1747.84 1132.08 1748.55 1131.76 1749.04 1131.95C1749.68 1132.2 1750.13 1132.89 1750.7 1133.36C1751.01 1133.61 1751.25 1133.91 1751.11 1134.28C1750.96 1134.69 1750.51 1134.59 1750.16 1134.62Z" fill="white"/>
                    <path d="M1766.77 1281.52C1766.59 1281.52 1766.41 1281.56 1766.23 1281.54C1765.83 1281.5 1765.27 1281.83 1765.1 1281.21C1764.92 1280.57 1765.09 1280.01 1765.63 1279.54C1766.09 1279.15 1766.39 1279.34 1766.78 1279.64C1767.03 1279.83 1767.44 1279.81 1767.7 1280C1768.04 1280.25 1768.51 1280.49 1768.41 1281.03C1768.28 1281.68 1767.71 1281.4 1767.32 1281.48C1767.14 1281.51 1766.96 1281.5 1766.78 1281.51L1766.77 1281.52Z" fill="white"/>
                    <path d="M1651.53 1242.3C1651.15 1243.06 1650.99 1243.96 1650.25 1244.51C1650.08 1244.63 1649.73 1244.66 1649.51 1244.41C1649.22 1244.08 1648.64 1243.87 1648.83 1243.35C1649.06 1242.71 1649.43 1242.12 1649.81 1241.55C1650.25 1240.87 1650.45 1241.82 1650.83 1241.75C1651.23 1241.68 1651.54 1241.79 1651.53 1242.31V1242.3Z" fill="white"/>
                    <path d="M1794.98 1129.62C1794.49 1129.73 1794.17 1129.58 1794.17 1128.96C1794.17 1128.01 1794.65 1127.45 1795.49 1127.37C1796.24 1127.3 1796.68 1127.75 1796.89 1128.32C1797.13 1128.98 1796.19 1129.6 1794.97 1129.63L1794.98 1129.62Z" fill="white"/>
                    <path d="M1837.93 1058.62C1837.65 1058.63 1837.37 1058.7 1837.11 1058.65C1836.49 1058.55 1836.25 1057.81 1836.67 1057.35C1836.92 1057.08 1837.12 1056.75 1837.42 1056.55C1837.8 1056.29 1838.35 1056.23 1838.68 1056.48C1839.07 1056.76 1838.98 1057.46 1839.48 1057.74C1839.08 1058.07 1838.9 1058.72 1838.2 1058.61C1838.11 1058.6 1838.02 1058.61 1837.93 1058.62Z" fill="white"/>
                    <path d="M1701.86 1232.54C1702.1 1232.76 1702.29 1232.95 1702.51 1233.12C1703.05 1233.55 1702.46 1234.38 1703.15 1234.78C1703.52 1234.99 1703.53 1235.41 1703.22 1235.69C1702.92 1235.96 1702.58 1235.86 1702.29 1235.58C1701.9 1235.2 1701.46 1234.87 1701.09 1234.47C1700.91 1234.28 1700.34 1234.19 1700.8 1233.72C1701.17 1233.34 1701.51 1232.94 1701.87 1232.55L1701.86 1232.54Z" fill="white"/>
                    <path d="M1606.28 1169.97C1606.34 1169.39 1607.22 1168.8 1607.88 1168.9C1608.14 1168.94 1609.15 1170.32 1609.08 1170.63C1608.9 1171.43 1608.21 1171.08 1607.76 1171.1C1606.92 1171.13 1606.23 1170.53 1606.28 1169.97Z" fill="white"/>
                    <path d="M1669.04 1125.56C1669.15 1126.12 1669.04 1126.47 1668.41 1126.47C1667.64 1126.47 1666.85 1125.98 1666.82 1125.43C1666.79 1124.88 1667.54 1124.28 1668.31 1124.25C1669.46 1124.2 1668.85 1125.12 1669.03 1125.56H1669.04Z" fill="white"/>
                    <path d="M1672.23 1119.61C1672.67 1119.85 1673.18 1120.09 1673.24 1120.67C1673.31 1121.3 1672.26 1121.99 1671.51 1121.87C1671.02 1121.79 1671.04 1121.43 1671.05 1121.07C1671.09 1120.25 1671.64 1119.56 1672.22 1119.61H1672.23Z" fill="white"/>
                    <path d="M1685.47 1203.36C1685.11 1203.38 1684.93 1203.36 1684.75 1203.4C1684.09 1203.52 1683.86 1202.91 1683.43 1202.63C1683.83 1202.31 1683.99 1201.68 1684.67 1201.73C1684.85 1201.74 1685.03 1201.73 1685.21 1201.7C1685.82 1201.62 1686.21 1201.68 1686.23 1202.49C1686.25 1203.26 1685.96 1203.45 1685.47 1203.36Z" fill="white"/>
                    <path d="M1694.46 1044.53C1694.53 1045.17 1694.11 1045.32 1693.79 1045.2C1693.15 1044.95 1692.55 1044.58 1691.97 1044.21C1691.43 1043.87 1691.97 1043.66 1692.13 1043.43C1692.81 1042.41 1693.35 1043.59 1693.98 1043.58C1694.71 1043.56 1694.32 1044.27 1694.46 1044.53Z" fill="white"/>
                    <path d="M1673.22 1172.32C1673.97 1172.14 1674.42 1172.59 1674.65 1173.16C1674.82 1173.58 1673.77 1174.69 1673.43 1174.52C1672.77 1174.2 1672.47 1173.64 1672.5 1172.88C1672.52 1172.35 1672.85 1172.34 1673.22 1172.32Z" fill="white"/>
                    <path d="M1821.93 1251.6C1821.86 1252.14 1821.45 1252.46 1821 1252.71C1820.73 1252.86 1819.77 1251.68 1819.69 1251.12C1819.62 1250.61 1819.97 1250.54 1820.34 1250.54C1821.1 1250.52 1821.89 1251.04 1821.93 1251.6Z" fill="white"/>
                    <path d="M1653.61 1205.9C1653.68 1205.63 1653.86 1205.35 1654.15 1205.49C1654.85 1205.82 1655.64 1205.97 1656.23 1206.55C1656.48 1206.79 1656.51 1207.1 1656.42 1207.29C1656.33 1207.47 1655.92 1207.66 1655.76 1207.59C1655.13 1207.32 1654.53 1206.95 1653.94 1206.61C1653.68 1206.46 1653.59 1206.21 1653.61 1205.91V1205.9Z" fill="white"/>
                    <path d="M1626.87 1219.6C1627.16 1219.56 1627.52 1219.5 1627.64 1219.98C1627.74 1220.39 1627.41 1220.6 1627.23 1220.89C1626.86 1221.48 1626.27 1221.33 1625.78 1221.28C1625.49 1221.25 1624.99 1220.79 1625.03 1220.62C1625.1 1220.26 1625.42 1219.88 1625.74 1219.68C1626.01 1219.52 1626.44 1219.61 1626.86 1219.59L1626.87 1219.6Z" fill="white"/>
                    <path d="M1651.86 1070.24C1651.76 1069.79 1651.92 1069.48 1652.53 1069.49C1653.3 1069.49 1654.06 1069.99 1654.1 1070.54C1654.17 1071.46 1652.98 1070.89 1652.8 1071.61C1652.77 1071.73 1651.94 1070.95 1651.85 1070.25L1651.86 1070.24Z" fill="white"/>
                    <path d="M1735.54 1219.76C1735.68 1219.86 1735.9 1219.93 1735.93 1220.05C1736.13 1220.74 1735.2 1222.04 1734.51 1222.02C1734.27 1222.02 1733.93 1221.93 1733.84 1221.77C1733.75 1221.6 1733.84 1221.25 1733.96 1221.06C1734.53 1220.21 1735.08 1219.79 1735.53 1219.76H1735.54Z" fill="white"/>
                    <path d="M1828.69 1175.27C1828.68 1175 1828.62 1174.72 1828.66 1174.46C1828.77 1173.78 1829.34 1174.06 1829.73 1173.99C1830.24 1173.9 1830.31 1174.26 1830.32 1174.64C1830.33 1174.99 1830.42 1175.33 1830.1 1175.63C1829.78 1175.94 1829.52 1176.4 1829 1176.21C1828.6 1176.06 1828.71 1175.61 1828.68 1175.26L1828.69 1175.27Z" fill="white"/>
                    <path d="M1656.23 1204.27C1655.66 1204.19 1655.69 1203.8 1655.71 1203.45C1655.75 1202.62 1656.31 1201.94 1656.89 1202.01C1657.38 1202.06 1657.36 1202.46 1657.34 1202.81C1657.29 1203.64 1656.75 1204.3 1656.23 1204.26V1204.27Z" fill="white"/>
                    <path d="M1644.08 1070.2C1644.53 1069.74 1644.83 1069.21 1645.61 1069.25C1646.25 1069.29 1646.29 1069.6 1646.32 1070.09C1646.36 1070.62 1646.26 1070.86 1645.66 1070.97C1644.87 1071.11 1644.57 1070.53 1644.08 1070.2Z" fill="white"/>
                    <path d="M1657.34 1065.92C1657.49 1066.01 1657.74 1066.07 1657.76 1066.18C1657.83 1066.53 1657.95 1066.97 1657.8 1067.23C1657.55 1067.65 1657.28 1068.21 1656.64 1068.17C1656.14 1068.14 1656.16 1067.74 1656.18 1067.38C1656.23 1066.54 1656.74 1065.91 1657.35 1065.92H1657.34Z" fill="white"/>
                    <path d="M1841.4 1210.61C1841.78 1210.61 1842.18 1210.6 1842.12 1211.11C1842.04 1211.78 1841.65 1212.31 1840.96 1212.26C1840.6 1212.23 1840.27 1211.78 1839.93 1211.52C1840.35 1211.1 1840.64 1210.46 1841.4 1210.61Z" fill="white"/>
                    <path d="M1794.15 1072.29C1794.14 1072.95 1793.41 1073.67 1792.95 1073.62C1792.47 1073.56 1792.53 1073.17 1792.49 1072.83C1792.42 1072.14 1792.77 1071.66 1793.36 1071.53C1793.54 1071.49 1794.13 1071.93 1794.14 1072.29H1794.15Z" fill="white"/>
                    <path d="M1664.1 1154.44C1664.56 1154.42 1665.05 1154.42 1664.93 1154.9C1664.78 1155.49 1664.41 1156.03 1664.14 1156.59C1663.64 1156.2 1663.22 1155.81 1663.28 1155.06C1663.33 1154.35 1663.8 1154.5 1664.1 1154.44Z" fill="white"/>
                    <path d="M1734.84 1035.43C1735.59 1035.31 1735.66 1035.67 1735.72 1036.19C1735.82 1037 1735.4 1037.06 1734.78 1037.09C1734.15 1037.12 1734.07 1036.82 1734.02 1036.29C1733.94 1035.51 1734.28 1035.31 1734.84 1035.42V1035.43Z" fill="white"/>
                    <path d="M1658.23 1164.68C1658.17 1165.17 1658.57 1165.93 1657.64 1165.84C1657.13 1165.79 1656.67 1165.4 1656.58 1164.83C1656.43 1163.88 1657.46 1164.1 1657.86 1163.83C1658.36 1163.49 1658.03 1164.4 1658.23 1164.67V1164.68Z" fill="white"/>
                    <path d="M1616.06 1234.58C1616.33 1234.57 1616.6 1234.51 1616.87 1234.55C1617.35 1234.62 1617.32 1235.01 1617.31 1235.36C1617.31 1235.61 1617.46 1235.91 1617.16 1236.1C1616.82 1236.31 1616.51 1236.26 1616.23 1235.97C1616.11 1235.84 1615.96 1235.73 1615.83 1235.61C1615.58 1235.37 1615.11 1235.08 1615.15 1234.89C1615.23 1234.48 1615.73 1234.64 1616.07 1234.59L1616.06 1234.58Z" fill="white"/>
                    <path d="M1837.81 1101.79C1837.97 1101.15 1838.51 1101.38 1838.91 1101.35C1839.65 1101.29 1839.45 1101.88 1839.5 1102.28C1839.54 1102.65 1839.52 1103.03 1838.99 1103C1838.45 1102.97 1837.77 1102.27 1837.81 1101.79Z" fill="white"/>
                    <path d="M1691.84 1103.38C1691.84 1103.86 1691.86 1104.24 1691.32 1104.21C1690.78 1104.18 1690.08 1103.48 1690.15 1103.01C1690.22 1102.53 1690.63 1102.62 1690.97 1102.56C1691.8 1102.4 1691.86 1102.9 1691.84 1103.38Z" fill="white"/>
                    <path d="M1635.79 1194.4C1635.83 1194.22 1635.81 1193.83 1635.96 1193.76C1636.35 1193.55 1636.56 1194.01 1636.7 1194.22C1636.91 1194.54 1637.92 1194.58 1637.16 1195.32C1636.91 1195.56 1636.71 1195.9 1636.3 1195.81C1635.47 1195.62 1636.03 1194.91 1635.79 1194.41V1194.4Z" fill="white"/>
                    <path d="M1760.96 1262.93C1761.32 1263.16 1762.09 1263.38 1761.77 1263.84C1761.5 1264.24 1761.8 1264.95 1761.15 1265.04C1760.76 1265.09 1760.15 1263.78 1760.32 1263.28C1760.41 1263.02 1760.65 1262.89 1760.96 1262.93Z" fill="white"/>
                    <path d="M1737.62 1052.41C1737.62 1052.41 1738.02 1052.12 1738.42 1051.92C1738.59 1051.83 1739.36 1052.84 1739.32 1053.09C1739.21 1053.76 1738.65 1053.49 1738.26 1053.56C1737.79 1053.64 1737.66 1053.34 1737.62 1052.41Z" fill="white"/>
                    <path d="M1770.13 1055.32C1770.77 1055.47 1770.52 1056.01 1770.58 1056.4C1770.66 1056.89 1770.29 1057.09 1769.95 1056.97C1769.56 1056.83 1769.22 1056.51 1768.92 1056.2C1768.81 1056.09 1769.88 1055.29 1770.12 1055.32H1770.13Z" fill="white"/>
                    <path d="M1877.48 1185.37C1877.6 1185.84 1877.39 1186.05 1877.12 1186.06C1876.41 1186.09 1876.01 1185.66 1875.83 1185.02C1875.73 1184.69 1875.95 1184.49 1876.22 1184.47C1876.95 1184.41 1877.3 1184.9 1877.48 1185.38V1185.37Z" fill="white"/>
                    <path d="M1725.17 1238.1C1725.23 1237.51 1725.58 1237.06 1726.05 1237.04C1726.28 1237.03 1726.83 1237.43 1726.84 1237.97C1726.86 1238.7 1726.3 1238.51 1725.91 1238.56C1725.54 1238.61 1725.2 1238.56 1725.17 1238.1Z" fill="white"/>
                    <path d="M1855.35 1097.05C1855.4 1097.31 1854.7 1098.4 1854.58 1098.3C1854.25 1098.03 1853.9 1097.72 1853.73 1097.35C1853.57 1097 1853.78 1096.66 1854.25 1096.69C1854.64 1096.72 1855.16 1096.4 1855.35 1097.05Z" fill="white"/>
                    <path d="M1719.85 1240.01C1720.35 1240.14 1720.86 1240.44 1720.88 1241.17C1720.88 1241.44 1720.71 1241.68 1720.37 1241.61C1719.71 1241.49 1719.25 1241.13 1719.2 1240.43C1719.18 1240.17 1719.37 1239.93 1719.85 1240.01Z" fill="white"/>
                    <path d="M1709.4 1301.03C1709.91 1300.97 1710.11 1301.32 1709.98 1301.66C1709.84 1302.05 1709.52 1302.39 1709.21 1302.69C1709.1 1302.8 1708.3 1301.73 1708.33 1301.49C1708.38 1301.14 1708.79 1300.97 1709.39 1301.03H1709.4Z" fill="white"/>
                    <path d="M1662.4 1162.85C1662.79 1162.84 1663.17 1162.82 1663.15 1163.34C1663.13 1163.91 1662.76 1164.35 1662.29 1164.37C1662.06 1164.38 1661.51 1163.98 1661.5 1163.44C1661.48 1162.71 1662.05 1162.93 1662.41 1162.85H1662.4Z" fill="white"/>
                    <path d="M1643.13 1248.3C1643.6 1248.52 1644.01 1248.77 1644.1 1249.32C1644.15 1249.65 1643.96 1249.87 1643.69 1249.85C1643.25 1249.81 1642.57 1250.27 1642.44 1249.38C1642.36 1248.84 1642.93 1248.69 1643.13 1248.29V1248.3Z" fill="white"/>
                    <path d="M1672.02 1171.27C1672.16 1171.36 1672.3 1171.45 1672.43 1171.54C1671.91 1172.14 1671.27 1172.67 1671.13 1173.53C1671.13 1173.57 1670.77 1173.6 1670.61 1173.54C1670.52 1173.51 1670.4 1173.21 1670.43 1173.19C1670.98 1172.7 1670.81 1171.78 1671.51 1171.4C1671.66 1171.32 1671.85 1171.31 1672.02 1171.27Z" fill="white"/>
                    <path d="M1673.18 1175.42C1673.18 1175.97 1672.45 1175.7 1672.03 1175.68C1671.19 1175.64 1671.59 1174.9 1671.5 1174.46C1671.47 1174.33 1671.74 1173.82 1671.91 1174.26C1672.16 1174.92 1673.25 1174.07 1673.17 1175.42H1673.18Z" fill="white"/>
                    <path d="M1843.9 1157.34C1843.76 1156.68 1844.38 1156.47 1844.65 1156.05C1844.91 1156.27 1845.38 1156.49 1845.39 1156.72C1845.4 1157.22 1845.01 1157.58 1844.49 1157.7C1844.17 1157.78 1844.02 1157.59 1843.9 1157.34Z" fill="white"/>
                    <path d="M1733.42 1057.3C1733.38 1057.69 1733.64 1058.23 1732.9 1058.28C1732.37 1058.32 1732.32 1057.97 1732.32 1057.6C1732.32 1057.2 1732.09 1056.66 1732.83 1056.61C1733.37 1056.57 1733.38 1056.92 1733.42 1057.3Z" fill="white"/>
                    <path d="M1846.18 1231C1846.96 1231.46 1847.16 1232.02 1847.08 1232.68C1847.06 1232.83 1846.92 1232.97 1846.83 1233.12C1846.7 1233 1846.58 1232.85 1846.43 1232.77C1845.84 1232.45 1845.91 1231.89 1845.93 1231.37C1845.93 1231.21 1846.15 1231.06 1846.18 1231.01V1231Z" fill="white"/>
                    <path d="M1736.84 1029.18C1736.98 1029.16 1737.06 1029.5 1737.05 1029.89C1737.04 1030.29 1737.3 1030.83 1736.56 1030.89C1736.04 1030.93 1735.97 1030.6 1735.97 1030.22C1735.97 1029.82 1735.74 1029.28 1736.84 1029.17V1029.18Z" fill="white"/>
                    <path d="M1711.81 1082.31C1711.75 1082.42 1711.69 1082.64 1711.61 1082.65C1711 1082.74 1710.39 1082.81 1709.93 1082.2C1709.74 1081.95 1709.66 1081.79 1709.94 1081.64C1710.38 1081.41 1711.67 1081.88 1711.81 1082.3V1082.31Z" fill="white"/>
                    <path d="M1810.25 1125.54C1811.11 1125.72 1810.6 1126.43 1810.71 1126.88C1810.76 1127.09 1810.36 1127.26 1810.08 1127.2C1809.27 1127.01 1809.67 1126.32 1809.61 1125.86C1809.58 1125.64 1809.94 1125.49 1810.25 1125.54Z" fill="white"/>
                    <path d="M1704.6 1145.22C1705.46 1145.4 1704.95 1146.11 1705.06 1146.56C1705.11 1146.77 1704.71 1146.94 1704.43 1146.88C1703.62 1146.69 1704.02 1146 1703.96 1145.54C1703.93 1145.32 1704.29 1145.17 1704.6 1145.22Z" fill="white"/>
                    <path d="M1777.57 1245.41C1777.71 1245.39 1777.79 1245.73 1777.78 1246.12C1777.77 1246.52 1778.03 1247.06 1777.29 1247.12C1776.77 1247.16 1776.7 1246.83 1776.7 1246.45C1776.7 1246.05 1776.47 1245.51 1777.57 1245.4V1245.41Z" fill="white"/>
                    <path d="M1631.98 1137.89C1631.49 1138.19 1630.98 1138.5 1630.46 1138.8C1630.44 1138.81 1630.29 1138.7 1630.27 1138.63C1630.17 1138.26 1630.29 1137.97 1630.57 1137.69C1630.89 1137.38 1631.16 1137.01 1631.66 1137.28C1631.78 1137.34 1631.81 1137.57 1631.97 1137.9L1631.98 1137.89Z" fill="white"/>
                    <path d="M1652.01 1184.75C1651.96 1184.38 1651.89 1183.99 1652.39 1183.91C1653.03 1183.8 1653.12 1184.2 1653.12 1184.71C1653.12 1185.07 1653.23 1185.46 1652.72 1185.55C1652.08 1185.66 1651.96 1185.27 1652.01 1184.76V1184.75Z" fill="white"/>
                    <path d="M1685.26 1229.96C1685.75 1230.09 1685.99 1230.2 1685.93 1230.54C1685.81 1231.2 1685.45 1231.65 1684.75 1231.71C1684.61 1231.72 1684.46 1231.55 1684.32 1231.47C1684.65 1230.95 1684.98 1230.43 1685.27 1229.97L1685.26 1229.96Z" fill="white"/>
                    <path d="M1690.1 1119.86C1690.59 1119.77 1690.97 1119.86 1690.96 1120.08C1690.95 1120.55 1691.41 1121.19 1690.63 1121.46C1690.36 1121.55 1689.98 1121.45 1689.97 1121.2C1689.94 1120.73 1689.64 1120.14 1690.1 1119.86Z" fill="white"/>
                    <path d="M1793.83 1236.85C1794.21 1236.85 1794.56 1236.83 1794.57 1237.37C1794.59 1238.11 1794.03 1237.93 1793.63 1237.97C1793.26 1238.01 1792.91 1238 1792.9 1237.46C1792.88 1236.72 1793.44 1236.93 1793.83 1236.85Z" fill="white"/>
                    <path d="M1789.83 1153.07C1790.01 1152.21 1790.72 1152.72 1791.17 1152.61C1791.38 1152.56 1791.55 1152.96 1791.49 1153.24C1791.3 1154.05 1790.61 1153.65 1790.15 1153.71C1789.93 1153.74 1789.78 1153.38 1789.83 1153.07Z" fill="white"/>
                    <path d="M1675.48 1232.46C1675.43 1232.09 1675.36 1231.7 1675.86 1231.62C1676.5 1231.51 1676.59 1231.91 1676.59 1232.42C1676.59 1232.78 1676.7 1233.17 1676.19 1233.26C1675.55 1233.37 1675.43 1232.98 1675.48 1232.47V1232.46Z" fill="white"/>
                    <path d="M1654.59 1079.65C1654.5 1079.02 1654.68 1078.86 1654.97 1078.81C1655.62 1078.71 1655.69 1079.13 1655.67 1079.62C1655.66 1079.87 1655.74 1080.38 1655.53 1080.31C1655.1 1080.17 1654.76 1079.78 1654.59 1079.65Z" fill="white"/>
                    <path d="M1825.41 1221.94C1825.55 1222 1825.78 1222.09 1826 1222.19C1825.65 1222.54 1825.47 1223.14 1824.82 1223.09C1824.54 1223.07 1824.37 1222.92 1824.36 1222.57C1824.35 1221.85 1824.89 1222.02 1825.4 1221.94H1825.41Z" fill="white"/>
                    <path d="M1628.63 1261.8C1628.69 1262.04 1628.5 1262.23 1628.23 1262.28C1627.87 1262.34 1627.47 1262.31 1627.52 1261.79C1627.55 1261.43 1627.17 1260.82 1627.81 1260.77C1628.04 1260.75 1628.59 1261.17 1628.64 1261.79L1628.63 1261.8Z" fill="white"/>
                    <path d="M1615.02 1091.34C1615.66 1091.23 1615.88 1091.83 1616.29 1092.12C1616.06 1092.22 1615.84 1092.39 1615.61 1092.41C1615.22 1092.43 1614.69 1092.66 1614.62 1091.93C1614.59 1091.6 1614.74 1091.45 1615.02 1091.34Z" fill="white"/>
                    <path d="M1659.47 1257.14C1659.57 1257.51 1659.56 1257.11 1659.28 1257.33C1659.13 1257.45 1658.73 1257.46 1658.59 1257.35C1658.21 1257.03 1657.63 1256.8 1657.78 1256.13C1657.8 1256.05 1657.9 1255.93 1657.94 1255.94C1658.64 1256.1 1658.94 1256.78 1659.47 1257.15V1257.14Z" fill="white"/>
                    <path d="M1698.13 1222.82C1698.48 1223.19 1699.09 1223.43 1698.9 1224.08C1698.85 1224.23 1698.46 1224.4 1698.25 1224.37C1697.59 1224.28 1697.87 1223.7 1697.79 1223.31C1697.74 1223.04 1697.79 1222.83 1698.13 1222.82Z" fill="white"/>
                    <path d="M1745.38 1147.63C1745.56 1147.63 1745.76 1147.57 1745.92 1147.62C1746.03 1147.65 1746.18 1147.87 1746.15 1147.95C1746.05 1148.24 1744.35 1148.34 1744.16 1148.07C1744.12 1148.01 1744.23 1147.72 1744.3 1147.7C1744.66 1147.64 1745.02 1147.65 1745.38 1147.63Z" fill="white"/>
                    <path d="M1748.63 1297.97C1749.19 1297.91 1749.3 1298.24 1749.28 1298.62C1749.26 1298.9 1749.11 1299.07 1748.76 1299.08C1748.25 1299.1 1748.15 1298.79 1748.18 1298.41C1748.2 1298.14 1748.33 1297.88 1748.63 1297.96V1297.97Z" fill="white"/>
                    <path d="M1630.95 1082.78C1631.24 1082.85 1631.41 1082.97 1631.43 1083.31C1631.45 1083.82 1631.14 1083.92 1630.76 1083.9C1630.48 1083.88 1630.31 1083.73 1630.29 1083.38C1630.27 1082.87 1630.58 1082.81 1630.94 1082.78H1630.95Z" fill="white"/>
                    <path d="M1833.5 1102.15C1833.79 1102.22 1833.96 1102.34 1833.98 1102.68C1834 1103.19 1833.7 1103.29 1833.31 1103.27C1833.03 1103.25 1832.86 1103.1 1832.84 1102.75C1832.82 1102.24 1833.13 1102.18 1833.49 1102.15H1833.5Z" fill="white"/>
                    <path d="M1849.67 1206.22C1849.68 1206.56 1849.65 1206.88 1849.14 1206.91C1848.8 1206.93 1848.63 1206.77 1848.58 1206.5C1848.52 1206.12 1848.58 1205.81 1849.1 1205.78C1849.44 1205.76 1849.58 1205.91 1849.67 1206.22Z" fill="white"/>
                    <path d="M1719.3 1039.89C1719.49 1039.41 1719.76 1039.02 1720.29 1038.93C1720.43 1038.9 1720.74 1039.06 1720.73 1039.07C1720.61 1039.57 1720.32 1039.95 1719.78 1040.04C1719.64 1040.06 1719.47 1039.95 1719.29 1039.89H1719.3Z" fill="white"/>
                    <path d="M1813.04 1072.07C1813.36 1071.99 1813.53 1072.2 1813.58 1072.47C1813.64 1072.85 1813.58 1073.16 1813.06 1073.19C1812.72 1073.21 1812.55 1073.05 1812.5 1072.78C1812.44 1072.4 1812.51 1072.06 1813.04 1072.07Z" fill="white"/>
                    <path d="M1706.84 1101.22C1707.13 1101.29 1707.3 1101.41 1707.32 1101.75C1707.34 1102.26 1707.03 1102.36 1706.65 1102.34C1706.37 1102.32 1706.2 1102.17 1706.18 1101.82C1706.16 1101.31 1706.47 1101.25 1706.83 1101.22H1706.84Z" fill="white"/>
                    <path d="M1687.83 1289.86C1688.17 1289.94 1688.34 1290.06 1688.36 1290.4C1688.38 1290.91 1688.07 1291.01 1687.69 1290.99C1687.41 1290.97 1687.24 1290.82 1687.22 1290.47C1687.19 1289.94 1687.53 1289.92 1687.82 1289.86H1687.83Z" fill="white"/>
                    <path d="M1682.24 1267.92C1682.3 1268.44 1681.97 1268.55 1681.59 1268.53C1681.31 1268.51 1681.14 1268.36 1681.13 1268.01C1681.11 1267.5 1681.42 1267.4 1681.8 1267.42C1682.08 1267.44 1682.3 1267.59 1682.25 1267.92H1682.24Z" fill="white"/>
                    <path d="M1747.49 1013.69C1747.13 1013.69 1746.76 1013.7 1746.78 1013.18C1746.78 1013.02 1746.87 1012.87 1746.92 1012.71C1747.25 1012.93 1747.57 1013.16 1747.9 1013.38C1747.77 1013.48 1747.63 1013.58 1747.49 1013.68V1013.69Z" fill="white"/>
                    <path d="M1767.82 1059.32C1768.18 1059.32 1768.55 1059.31 1768.53 1059.83C1768.53 1059.99 1768.44 1060.14 1768.39 1060.3C1768.06 1060.08 1767.74 1059.85 1767.41 1059.63C1767.54 1059.53 1767.68 1059.43 1767.82 1059.33V1059.32Z" fill="white"/>
                    <path d="M1645.78 1097.68C1646.06 1097.93 1646.33 1098.16 1646.6 1098.39C1646.38 1098.49 1646.15 1098.68 1645.93 1098.68C1645.44 1098.67 1645.45 1098.26 1645.49 1097.91C1645.5 1097.82 1645.7 1097.74 1645.79 1097.68H1645.78Z" fill="white"/>
                    <path d="M1790.23 1160.89C1790.16 1161.09 1790.13 1161.38 1790.06 1161.39C1789.58 1161.43 1789.43 1160.93 1789.1 1160.73C1789.24 1160.63 1789.36 1160.48 1789.51 1160.45C1789.88 1160.38 1790.21 1160.47 1790.23 1160.9V1160.89Z" fill="white"/>
                    <path d="M1875.29 1181.23C1875.41 1181.3 1875.61 1181.36 1875.63 1181.45C1875.7 1181.79 1875.74 1182.2 1875.26 1182.25C1875.04 1182.27 1874.8 1182.11 1874.56 1182.02C1874.8 1181.76 1875.04 1181.49 1875.28 1181.22L1875.29 1181.23Z" fill="white"/>
                    <path d="M1852.64 1165.6C1852.59 1165.1 1852.94 1165.03 1853.31 1165.05C1853.46 1165.05 1853.6 1165.21 1853.74 1165.29C1853.44 1165.55 1853.14 1165.8 1852.84 1166.06C1852.78 1165.91 1852.71 1165.76 1852.65 1165.6H1852.64Z" fill="white"/>
                    <path d="M1666.68 1106.11C1666.74 1106.15 1666.96 1106.19 1666.97 1106.28C1667.04 1106.62 1667.09 1107.03 1666.61 1107.09C1666.39 1107.12 1666.15 1106.95 1665.91 1106.88C1666.16 1106.63 1666.4 1106.38 1666.67 1106.11H1666.68Z" fill="white"/>
                    <path d="M1702.54 1105.88C1703.04 1105.83 1703.11 1106.18 1703.09 1106.55C1703.09 1106.7 1702.93 1106.84 1702.85 1106.98C1702.59 1106.68 1702.34 1106.38 1702.08 1106.08C1702.23 1106.02 1702.38 1105.95 1702.54 1105.89V1105.88Z" fill="white"/>
                    <path d="M1860.52 1120.8C1860.67 1120.88 1860.82 1120.96 1860.96 1121.04C1860.67 1121.31 1860.39 1121.58 1860.1 1121.85C1860.03 1121.7 1859.92 1121.56 1859.9 1121.4C1859.82 1120.91 1860.14 1120.82 1860.52 1120.8Z" fill="white"/>
                    <path d="M1698.55 1261C1698.5 1260.5 1698.85 1260.43 1699.22 1260.45C1699.37 1260.45 1699.51 1260.61 1699.65 1260.69C1699.35 1260.95 1699.05 1261.2 1698.75 1261.46C1698.69 1261.31 1698.62 1261.16 1698.56 1261H1698.55Z" fill="white"/>
                    <path d="M1702.62 1298.88C1702.81 1298.75 1702.94 1298.6 1703.09 1298.58C1703.47 1298.52 1703.8 1298.61 1703.77 1299.1C1703.76 1299.26 1703.67 1299.41 1703.61 1299.56C1703.29 1299.34 1702.96 1299.11 1702.62 1298.88Z" fill="white"/>
                    <path d="M1595.46 1124.05C1595.79 1124.22 1595.69 1124.52 1595.69 1124.77C1595.69 1124.85 1595.44 1125.01 1595.36 1124.98C1595.04 1124.85 1595.14 1124.53 1595.15 1124.28C1595.15 1124.2 1595.36 1124.12 1595.47 1124.05H1595.46Z" fill="white"/>
                    <path d="M1615.47 1110.43C1615.76 1110.33 1615.99 1110.2 1616.22 1110.18C1616.36 1110.17 1616.51 1110.33 1616.65 1110.42C1616.43 1110.52 1616.21 1110.67 1615.97 1110.71C1615.84 1110.73 1615.68 1110.56 1615.46 1110.43H1615.47Z" fill="white"/>
                    <path d="M1797.78 1272.84C1798.11 1273.01 1798.01 1273.31 1798.01 1273.56C1798.01 1273.64 1797.76 1273.8 1797.68 1273.77C1797.36 1273.64 1797.46 1273.32 1797.47 1273.07C1797.47 1272.99 1797.68 1272.91 1797.79 1272.84H1797.78Z" fill="white"/>
                    <path d="M1860.33 1190.72C1860.43 1191.01 1860.56 1191.24 1860.58 1191.47C1860.59 1191.61 1860.43 1191.76 1860.34 1191.9C1860.24 1191.68 1860.09 1191.46 1860.05 1191.22C1860.03 1191.09 1860.2 1190.93 1860.33 1190.71V1190.72Z" fill="white"/>
                    <path d="M1859.82 1187.78C1859.82 1187.93 1859.85 1188.18 1859.79 1188.41C1859.72 1188.68 1859.52 1188.69 1859.43 1188.43C1859.36 1188.21 1859.34 1187.94 1859.39 1187.71C1859.45 1187.44 1859.62 1187.44 1859.81 1187.79L1859.82 1187.78Z" fill="white"/>
                    <path d="M1813.3 1279.2C1813.35 1279.04 1813.38 1278.77 1813.47 1278.76C1813.79 1278.69 1813.88 1278.93 1813.85 1279.2C1813.83 1279.36 1813.77 1279.63 1813.69 1279.65C1813.37 1279.72 1813.3 1279.48 1813.31 1279.21L1813.3 1279.2Z" fill="white"/>
                    <path d="M1636.02 1086.42C1636.18 1086.47 1636.45 1086.5 1636.46 1086.59C1636.53 1086.91 1636.29 1087 1636.02 1086.97C1635.86 1086.95 1635.59 1086.89 1635.57 1086.81C1635.5 1086.49 1635.74 1086.42 1636.01 1086.43L1636.02 1086.42Z" fill="white"/>
                    <path d="M1851.27 1117.05C1851.56 1116.95 1851.79 1116.82 1852.02 1116.8C1852.16 1116.79 1852.31 1116.95 1852.45 1117.04C1852.23 1117.14 1852.01 1117.29 1851.77 1117.33C1851.64 1117.35 1851.48 1117.18 1851.26 1117.05H1851.27Z" fill="white"/>
                    <path d="M1639.13 1235.12C1639.23 1235.18 1639.38 1235.27 1639.52 1235.35C1639.3 1235.45 1639.08 1235.6 1638.84 1235.64C1638.71 1235.66 1638.55 1235.49 1638.41 1235.4C1638.58 1235.17 1638.79 1235.05 1639.12 1235.11L1639.13 1235.12Z" fill="white"/>
                    <path d="M1847.03 1074.82C1847.07 1075.09 1847.02 1075.33 1846.69 1075.29C1846.6 1075.28 1846.52 1075.02 1846.48 1074.86C1846.42 1074.6 1846.48 1074.35 1846.81 1074.39C1846.9 1074.4 1846.95 1074.67 1847.02 1074.82H1847.03Z" fill="white"/>
                    <path d="M1676.68 1234.34C1676.72 1234.61 1676.67 1234.85 1676.34 1234.81C1676.25 1234.8 1676.17 1234.54 1676.13 1234.38C1676.07 1234.12 1676.13 1233.87 1676.46 1233.91C1676.55 1233.92 1676.6 1234.19 1676.67 1234.34H1676.68Z" fill="white"/>
                    <path d="M1684.58 1225.61C1684.62 1225.88 1684.57 1226.12 1684.24 1226.08C1684.15 1226.07 1684.07 1225.81 1684.03 1225.65C1683.97 1225.39 1684.03 1225.14 1684.36 1225.18C1684.45 1225.19 1684.5 1225.46 1684.57 1225.61H1684.58Z" fill="white"/>
                    <path d="M1708.81 1189.66C1709.1 1189.56 1709.33 1189.43 1709.56 1189.41C1709.7 1189.4 1709.85 1189.56 1709.99 1189.65C1709.77 1189.75 1709.55 1189.9 1709.31 1189.94C1709.18 1189.96 1709.02 1189.79 1708.8 1189.66H1708.81Z" fill="white"/>
                    <path d="M1657.85 1102.51C1658.04 1102.6 1658.26 1102.7 1658.49 1102.8C1658.35 1102.9 1658.21 1103.09 1658.08 1103.08C1657.84 1103.07 1657.61 1102.94 1657.38 1102.86C1657.52 1102.76 1657.65 1102.66 1657.85 1102.51Z" fill="white"/>
                    <path d="M1744.01 1121.62C1744.11 1121.68 1744.26 1121.77 1744.4 1121.85C1744.18 1121.95 1743.96 1122.1 1743.72 1122.14C1743.59 1122.16 1743.43 1121.99 1743.29 1121.9C1743.46 1121.67 1743.67 1121.55 1744 1121.61L1744.01 1121.62Z" fill="white"/>
                    <path d="M1748.85 1078.82C1749.14 1078.72 1749.37 1078.59 1749.6 1078.57C1749.74 1078.56 1749.89 1078.72 1750.03 1078.81C1749.81 1078.91 1749.59 1079.06 1749.35 1079.1C1749.22 1079.12 1749.06 1078.95 1748.84 1078.82H1748.85Z" fill="white"/>
                    <path d="M1755.94 1011.68C1756.27 1011.85 1756.17 1012.15 1756.17 1012.4C1756.17 1012.48 1755.92 1012.64 1755.84 1012.61C1755.52 1012.48 1755.62 1012.16 1755.63 1011.91C1755.63 1011.83 1755.84 1011.75 1755.95 1011.68H1755.94Z" fill="white"/>
                    <path d="M1785.43 1064.55C1785.47 1064.82 1785.42 1065.06 1785.09 1065.02C1785 1065.01 1784.92 1064.75 1784.88 1064.59C1784.82 1064.33 1784.88 1064.08 1785.21 1064.12C1785.3 1064.13 1785.35 1064.4 1785.42 1064.55H1785.43Z" fill="white"/>
                    <path d="M1786.77 1259.41C1786.82 1259.25 1786.85 1258.98 1786.94 1258.97C1787.26 1258.9 1787.35 1259.14 1787.32 1259.41C1787.3 1259.57 1787.24 1259.84 1787.16 1259.86C1786.84 1259.93 1786.77 1259.69 1786.78 1259.42L1786.77 1259.41Z" fill="white"/>
                    <path d="M1767.95 1170.94C1768.11 1170.99 1768.38 1171.02 1768.39 1171.11C1768.46 1171.43 1768.22 1171.52 1767.95 1171.49C1767.79 1171.47 1767.52 1171.41 1767.5 1171.33C1767.43 1171.01 1767.67 1170.94 1767.94 1170.95L1767.95 1170.94Z" fill="white"/>
                    <path d="M1782.3 1169.4C1782.42 1169.33 1782.53 1169.24 1782.66 1169.21C1782.71 1169.2 1782.79 1169.32 1782.85 1169.37C1782.73 1169.44 1782.62 1169.53 1782.49 1169.56C1782.44 1169.57 1782.36 1169.45 1782.3 1169.4Z" fill="white"/>
                    <path d="M1869.68 1117.3C1869.8 1117.23 1869.91 1117.14 1870.04 1117.11C1870.09 1117.1 1870.17 1117.22 1870.23 1117.27C1870.11 1117.34 1870 1117.43 1869.87 1117.46C1869.82 1117.47 1869.74 1117.35 1869.68 1117.3Z" fill="white"/>
                    <path d="M1687 1214.48C1687.12 1214.54 1687.24 1214.6 1687.37 1214.66C1687.31 1214.72 1687.24 1214.85 1687.2 1214.84C1687.07 1214.82 1686.95 1214.74 1686.82 1214.69C1686.88 1214.63 1686.94 1214.56 1687.01 1214.48H1687Z" fill="white"/>
                    <path d="M1676.31 1256.5C1676.23 1256.43 1676.17 1256.37 1676.1 1256.31C1676.22 1256.24 1676.33 1256.15 1676.46 1256.12C1676.51 1256.11 1676.59 1256.23 1676.65 1256.28C1676.53 1256.35 1676.42 1256.42 1676.3 1256.49L1676.31 1256.5Z" fill="white"/>
                    <path d="M1670.92 1175.96C1670.86 1176.08 1670.8 1176.2 1670.74 1176.33C1670.68 1176.27 1670.55 1176.2 1670.56 1176.16C1670.58 1176.03 1670.66 1175.91 1670.71 1175.78C1670.77 1175.84 1670.84 1175.9 1670.92 1175.97V1175.96Z" fill="white"/>
                    <path d="M1700.84 1144.84C1700.91 1144.96 1701 1145.07 1701.03 1145.2C1701.04 1145.25 1700.92 1145.33 1700.87 1145.39C1700.8 1145.27 1700.71 1145.16 1700.68 1145.03C1700.67 1144.98 1700.79 1144.9 1700.84 1144.84Z" fill="white"/>
                    <path d="M1703.23 1099.54C1703.16 1099.62 1703.1 1099.68 1703.04 1099.75C1702.97 1099.63 1702.88 1099.52 1702.85 1099.39C1702.84 1099.34 1702.96 1099.26 1703.01 1099.2C1703.08 1099.32 1703.15 1099.43 1703.22 1099.55L1703.23 1099.54Z" fill="white"/>
                    <path d="M1649.43 1084.17C1649.55 1084.23 1649.67 1084.29 1649.8 1084.35C1649.74 1084.41 1649.67 1084.54 1649.63 1084.53C1649.5 1084.51 1649.38 1084.43 1649.25 1084.38C1649.31 1084.32 1649.37 1084.25 1649.44 1084.17H1649.43Z" fill="white"/>
                    <path d="M1670.05 1194.21C1670.13 1194.28 1670.19 1194.34 1670.26 1194.4C1670.14 1194.47 1670.03 1194.56 1669.9 1194.59C1669.85 1194.6 1669.77 1194.48 1669.71 1194.43C1669.83 1194.36 1669.94 1194.29 1670.06 1194.22L1670.05 1194.21Z" fill="white"/>
                    <path d="M1727.95 1155.63C1727.87 1155.56 1727.81 1155.5 1727.74 1155.44C1727.86 1155.37 1727.97 1155.28 1728.1 1155.25C1728.15 1155.24 1728.23 1155.36 1728.29 1155.41C1728.17 1155.48 1728.06 1155.55 1727.94 1155.62L1727.95 1155.63Z" fill="white"/>
                    <path d="M1732.23 1021.34C1732.29 1021.27 1732.38 1021.15 1732.4 1021.16C1732.52 1021.22 1732.68 1021.29 1732.74 1021.39C1732.87 1021.62 1732.76 1021.74 1732.52 1021.63C1732.4 1021.58 1732.33 1021.43 1732.24 1021.33L1732.23 1021.34Z" fill="white"/>
                    <path d="M1733.01 1021.62C1733.1 1021.73 1733.28 1021.85 1733.26 1021.93C1733.2 1022.15 1733.04 1022.19 1732.94 1022.05C1732.85 1021.93 1732.82 1021.77 1732.76 1021.63C1732.84 1021.63 1732.92 1021.63 1733.01 1021.62Z" fill="white"/>
                    <path d="M1797.02 1193.46C1797.14 1193.52 1797.26 1193.58 1797.39 1193.64C1797.33 1193.7 1797.26 1193.83 1797.22 1193.82C1797.09 1193.8 1796.97 1193.72 1796.84 1193.67C1796.9 1193.61 1796.96 1193.54 1797.03 1193.46H1797.02Z" fill="white"/>
                    <path d="M1652.08 1025.72C1652.2 1025.78 1652.32 1025.84 1652.45 1025.9C1652.39 1025.96 1652.32 1026.09 1652.28 1026.08C1652.15 1026.06 1652.03 1025.98 1651.9 1025.93C1651.96 1025.87 1652.02 1025.8 1652.09 1025.72H1652.08Z" fill="white"/>
                    <path d="M1736.69 1015.41C1736.77 1015.48 1736.83 1015.54 1736.9 1015.6C1736.78 1015.67 1736.67 1015.76 1736.54 1015.79C1736.49 1015.8 1736.41 1015.68 1736.35 1015.63C1736.47 1015.56 1736.58 1015.49 1736.7 1015.42L1736.69 1015.41Z" fill="white"/>
                    <path d="M1645.35 1065.88C1645.23 1065.82 1645.11 1065.76 1644.98 1065.7C1645.04 1065.64 1645.11 1065.51 1645.15 1065.52C1645.28 1065.54 1645.4 1065.62 1645.53 1065.67C1645.47 1065.73 1645.41 1065.8 1645.34 1065.88H1645.35Z" fill="white"/>
                    <path d="M1775.95 1104.54C1776.07 1104.6 1776.19 1104.66 1776.32 1104.72C1776.26 1104.78 1776.19 1104.91 1776.15 1104.9C1776.02 1104.88 1775.9 1104.8 1775.77 1104.75C1775.83 1104.69 1775.89 1104.62 1775.96 1104.54H1775.95Z" fill="white"/>
                    <path d="M1655.79 1150.77C1655.72 1150.85 1655.66 1150.91 1655.6 1150.98C1655.53 1150.86 1655.44 1150.75 1655.41 1150.62C1655.4 1150.57 1655.52 1150.49 1655.57 1150.43C1655.64 1150.55 1655.71 1150.66 1655.78 1150.78L1655.79 1150.77Z" fill="white"/>
                    <path d="M1750.02 1044.94C1750.14 1044.87 1750.25 1044.78 1750.38 1044.75C1750.43 1044.74 1750.51 1044.86 1750.57 1044.91C1750.45 1044.98 1750.34 1045.07 1750.21 1045.1C1750.16 1045.11 1750.08 1044.99 1750.02 1044.94Z" fill="white"/>
                    <path d="M1652.7 1138.98C1652.82 1139.04 1652.94 1139.1 1653.07 1139.16C1653.01 1139.22 1652.94 1139.35 1652.9 1139.34C1652.77 1139.32 1652.65 1139.24 1652.52 1139.19L1652.71 1138.98H1652.7Z" fill="white"/>
                    <path d="M1758.32 1165.67C1758.38 1165.55 1758.44 1165.43 1758.5 1165.3C1758.56 1165.36 1758.69 1165.43 1758.68 1165.47C1758.66 1165.6 1758.58 1165.72 1758.53 1165.85C1758.47 1165.79 1758.4 1165.73 1758.32 1165.66V1165.67Z" fill="white"/>
                    <path d="M1821.6 1278.52C1821.72 1278.45 1821.83 1278.36 1821.96 1278.33C1822.01 1278.32 1822.09 1278.44 1822.15 1278.49C1822.03 1278.56 1821.92 1278.65 1821.79 1278.68C1821.74 1278.69 1821.66 1278.57 1821.6 1278.52Z" fill="white"/>
                    <path d="M1630.34 1298.84C1628.46 1298.62 1628 1297.22 1627.59 1295.89C1627.44 1295.4 1627.32 1294.62 1627.15 1294.4C1626.07 1292.98 1628.13 1292.54 1627.92 1291.48C1628.99 1291.43 1630.25 1291.01 1631.07 1291.44C1631.85 1291.85 1632.78 1292.44 1633.36 1293.23C1633.91 1293.98 1635.02 1294.78 1633.52 1295.75C1633.3 1295.89 1633.38 1296.48 1633.28 1296.85C1632.83 1298.49 1631.81 1299.14 1630.35 1298.85L1630.34 1298.84Z" fill="white"/>
                    <path d="M1749.57 1203.53C1749.34 1204.62 1748.31 1205.22 1747.93 1205.13C1746.45 1204.8 1745.57 1206.03 1744.31 1206.23C1743.42 1206.37 1742.86 1206.31 1742.42 1205.55C1741.7 1204.31 1741.71 1203.65 1742.49 1202.94C1742.83 1202.64 1743.12 1202.31 1743.41 1201.96C1744.67 1200.45 1748.65 1200.54 1749.4 1202.67C1749.53 1203.05 1749.65 1203.36 1749.57 1203.53Z" fill="white"/>
                    <path d="M1658.49 1201.07C1657.65 1201.59 1657.58 1203.02 1656.23 1202.86C1656.04 1202.84 1655.83 1202.72 1655.67 1202.76C1654.98 1202.95 1654.29 1203.4 1653.59 1202.83C1653.04 1202.38 1653.07 1201.67 1652.9 1201.06C1652.57 1199.86 1653.46 1198.34 1654.74 1197.84C1655.73 1197.45 1656.56 1197.65 1657.39 1198.35C1658.26 1199.08 1658.16 1200.14 1658.49 1201.07Z" fill="white"/>
                    <path d="M1620.62 1070.93C1620.95 1069.29 1621.7 1068.66 1622.92 1068.75C1623.59 1068.8 1624.24 1069.02 1624.91 1069.12C1625.54 1069.21 1625.68 1069.51 1625.53 1070.1C1625.36 1070.75 1625.35 1071.46 1625.11 1072.08C1624.7 1073.13 1623.81 1073.7 1622.75 1073.96C1622.53 1074.01 1622.14 1073.9 1622.02 1073.72C1621.61 1073.09 1621.02 1072.65 1620.52 1072.13C1620.27 1071.88 1620.61 1071.06 1620.63 1070.93H1620.62Z" fill="white"/>
                    <path d="M1747.03 1077.13C1746.95 1077.89 1746.85 1078.96 1745.63 1079.05C1745.05 1079.09 1743.97 1079.16 1743.9 1078.44C1743.82 1077.66 1743.08 1076.85 1743.73 1076.04C1744.18 1075.48 1744.85 1075.05 1745.14 1074.42C1745.41 1073.85 1745.63 1073.76 1746.18 1073.86C1746.98 1074 1747.32 1074.55 1747.36 1075.22C1747.4 1075.78 1747.17 1076.35 1747.02 1077.12L1747.03 1077.13Z" fill="white"/>
                    <path d="M1600.47 1065.24C1600.34 1065.99 1599.63 1066.52 1598.88 1066.42C1598.63 1066.39 1596.55 1064.6 1596.5 1064.38C1596.39 1063.88 1597.61 1062.6 1597.65 1062.68C1598.11 1063.8 1598.95 1062.57 1599.46 1063.02C1599.95 1063.45 1600.55 1064.76 1600.47 1065.24Z" fill="white"/>
                    <path d="M1606.66 1236.91C1607.05 1237 1607.68 1237.01 1607.61 1237.39C1607.48 1238.09 1607.65 1238.91 1606.87 1239.45C1606.44 1239.75 1605.94 1239.58 1605.58 1239.81C1605 1240.19 1604.63 1240.21 1604.17 1239.6C1603.68 1238.94 1603.72 1238.23 1604.19 1237.81C1604.86 1237.22 1605.59 1236.42 1606.67 1236.9L1606.66 1236.91Z" fill="white"/>
                    <path d="M1692.84 1185.32C1692.99 1184.73 1692.84 1183.9 1693.96 1184.17C1694.21 1184.23 1694.58 1183.77 1694.91 1183.57C1695.28 1183.35 1695.68 1183.16 1696.06 1182.96C1696 1183.6 1696.15 1184.4 1695.83 1184.85C1695.41 1185.43 1694.6 1185.72 1693.98 1186.18C1693.64 1186.43 1693.28 1186.6 1692.94 1186.37C1692.57 1186.11 1692.77 1185.69 1692.84 1185.32Z" fill="white"/>
                    <path d="M1539.58 1165.34C1539.62 1165.15 1539.63 1164.96 1539.69 1164.79C1539.83 1164.39 1539.64 1163.75 1540.31 1163.72C1541 1163.7 1541.53 1164.01 1541.87 1164.67C1542.16 1165.24 1541.88 1165.49 1541.48 1165.81C1541.22 1166.02 1541.14 1166.44 1540.89 1166.66C1540.55 1166.94 1540.19 1167.36 1539.66 1167.12C1539.03 1166.83 1539.45 1166.32 1539.47 1165.9C1539.47 1165.71 1539.54 1165.53 1539.57 1165.34H1539.58Z" fill="white"/>
                    <path d="M1608.29 1058.21C1607.62 1057.64 1606.74 1057.24 1606.37 1056.36C1606.29 1056.16 1606.34 1055.8 1606.66 1055.63C1607.06 1055.42 1607.43 1054.88 1607.91 1055.2C1608.5 1055.59 1609.01 1056.12 1609.49 1056.65C1610.07 1057.27 1609.05 1057.23 1609.03 1057.63C1609 1058.05 1608.81 1058.34 1608.29 1058.21Z" fill="white"/>
                    <path d="M1686.68 1232.07C1686.7 1231.54 1686.93 1231.26 1687.55 1231.42C1688.52 1231.66 1688.97 1232.29 1688.84 1233.16C1688.72 1233.94 1688.16 1234.27 1687.53 1234.34C1686.8 1234.42 1686.41 1233.31 1686.68 1232.07Z" fill="white"/>
                    <path d="M1747.96 1293.46C1748.02 1293.18 1748.02 1292.87 1748.14 1292.62C1748.4 1292.01 1749.21 1291.96 1749.57 1292.5C1749.79 1292.82 1750.07 1293.11 1750.2 1293.46C1750.37 1293.91 1750.29 1294.48 1749.95 1294.76C1749.56 1295.08 1748.88 1294.82 1748.46 1295.26C1748.22 1294.77 1747.61 1294.43 1747.9 1293.74C1747.94 1293.65 1747.94 1293.55 1747.96 1293.46Z" fill="white"/>
                    <path d="M1605.57 1111.74C1605.29 1111.93 1605.04 1112.08 1604.82 1112.25C1604.25 1112.69 1603.55 1111.88 1602.97 1112.48C1602.67 1112.8 1602.24 1112.71 1602.03 1112.33C1601.84 1111.96 1602.01 1111.64 1602.38 1111.41C1602.86 1111.11 1603.31 1110.75 1603.81 1110.47C1604.05 1110.34 1604.28 1109.78 1604.65 1110.36C1604.94 1110.83 1605.26 1111.28 1605.57 1111.74Z" fill="white"/>
                    <path d="M1693.05 1030.43C1693.63 1030.64 1694.01 1031.68 1693.74 1032.32C1693.63 1032.58 1691.98 1033.25 1691.68 1033.1C1690.91 1032.72 1691.44 1032.1 1691.54 1031.64C1691.72 1030.78 1692.5 1030.23 1693.06 1030.43H1693.05Z" fill="white"/>
                    <path d="M1722.38 1105.27C1721.79 1105.24 1721.46 1105.05 1721.61 1104.41C1721.8 1103.62 1722.5 1102.95 1723.07 1103.06C1723.63 1103.17 1724.05 1104.08 1723.89 1104.87C1723.65 1106.05 1722.87 1105.2 1722.38 1105.27Z" fill="white"/>
                    <path d="M1727.62 1109.99C1727.26 1110.38 1726.89 1110.83 1726.29 1110.75C1725.63 1110.67 1725.2 1109.42 1725.51 1108.7C1725.72 1108.22 1726.08 1108.33 1726.43 1108.43C1727.25 1108.67 1727.81 1109.41 1727.62 1109.99Z" fill="white"/>
                    <path d="M1639.29 1102.43C1639.36 1102.06 1639.42 1101.88 1639.43 1101.69C1639.48 1100.99 1640.15 1100.91 1640.54 1100.54C1640.77 1101.02 1641.36 1101.35 1641.14 1102.02C1641.08 1102.2 1641.05 1102.39 1641.03 1102.57C1640.96 1103.21 1640.8 1103.59 1639.98 1103.41C1639.19 1103.24 1639.07 1102.89 1639.29 1102.42V1102.43Z" fill="white"/>
                    <path d="M1798.24 1151.39C1797.57 1151.3 1797.52 1150.84 1797.73 1150.54C1798.14 1149.95 1798.67 1149.44 1799.19 1148.94C1799.67 1148.47 1799.75 1149.08 1799.95 1149.29C1800.81 1150.23 1799.48 1150.48 1799.34 1151.13C1799.18 1151.88 1798.55 1151.3 1798.25 1151.38L1798.24 1151.39Z" fill="white"/>
                    <path d="M1673.88 1097.78C1673.88 1098.58 1673.3 1098.93 1672.67 1099.02C1672.2 1099.09 1671.34 1097.74 1671.6 1097.44C1672.09 1096.85 1672.73 1096.69 1673.49 1096.91C1674.03 1097.06 1673.95 1097.4 1673.88 1097.78Z" fill="white"/>
                    <path d="M1556.11 1228.82C1555.58 1228.62 1555.35 1228.12 1555.21 1227.59C1555.13 1227.28 1556.56 1226.6 1557.15 1226.66C1557.69 1226.72 1557.67 1227.09 1557.58 1227.47C1557.41 1228.24 1556.68 1228.92 1556.1 1228.82H1556.11Z" fill="white"/>
                    <path d="M1644.71 1069.46C1644.97 1069.6 1645.21 1069.85 1644.99 1070.12C1644.48 1070.75 1644.13 1071.51 1643.39 1071.96C1643.08 1072.15 1642.76 1072.11 1642.6 1071.96C1642.44 1071.83 1642.35 1071.36 1642.46 1071.22C1642.89 1070.65 1643.41 1070.14 1643.91 1069.61C1644.12 1069.39 1644.4 1069.35 1644.71 1069.46Z" fill="white"/>
                    <path d="M1637.51 1038.88C1637.48 1039.18 1637.45 1039.57 1636.93 1039.57C1636.49 1039.57 1636.36 1039.19 1636.11 1038.93C1635.61 1038.41 1635.9 1037.84 1636.07 1037.36C1636.17 1037.08 1636.76 1036.68 1636.93 1036.76C1637.27 1036.92 1637.59 1037.34 1637.7 1037.72C1637.8 1038.04 1637.59 1038.44 1637.51 1038.88Z" fill="white"/>
                    <path d="M1782.84 1101.71C1783.32 1101.73 1783.59 1101.96 1783.43 1102.58C1783.23 1103.36 1782.54 1104.01 1781.97 1103.91C1781.02 1103.75 1781.9 1102.69 1781.21 1102.33C1781.09 1102.27 1782.09 1101.62 1782.83 1101.71H1782.84Z" fill="white"/>
                    <path d="M1610.1 1149.13C1609.97 1149.24 1609.84 1149.45 1609.71 1149.46C1608.96 1149.49 1607.87 1148.22 1608.07 1147.53C1608.14 1147.29 1608.3 1146.96 1608.49 1146.91C1608.69 1146.86 1609.02 1147.03 1609.18 1147.21C1609.9 1148 1610.19 1148.66 1610.1 1149.13Z" fill="white"/>
                    <path d="M1631.89 1254.83C1632.17 1254.88 1632.47 1254.89 1632.72 1255C1633.38 1255.28 1632.95 1255.79 1632.92 1256.21C1632.88 1256.75 1632.5 1256.73 1632.11 1256.65C1631.75 1256.58 1631.38 1256.57 1631.16 1256.18C1630.93 1255.78 1630.52 1255.4 1630.85 1254.92C1631.1 1254.55 1631.53 1254.78 1631.89 1254.83Z" fill="white"/>
                    <path d="M1645.71 1072.52C1645.94 1071.97 1646.32 1072.09 1646.67 1072.2C1647.5 1072.45 1648.05 1073.18 1647.84 1073.76C1647.66 1074.24 1647.26 1074.12 1646.91 1074.01C1646.08 1073.75 1645.55 1073.03 1645.71 1072.52Z" fill="white"/>
                    <path d="M1784.82 1093.81C1785.17 1094.38 1785.64 1094.82 1785.4 1095.6C1785.2 1096.24 1784.87 1096.2 1784.37 1096.11C1783.83 1096.02 1783.6 1095.86 1783.64 1095.22C1783.7 1094.39 1784.36 1094.23 1784.82 1093.8V1093.81Z" fill="white"/>
                    <path d="M1785.84 1108.35C1785.72 1108.48 1785.59 1108.71 1785.47 1108.71C1785.1 1108.69 1784.61 1108.71 1784.39 1108.49C1784.02 1108.13 1783.52 1107.72 1783.73 1107.07C1783.89 1106.57 1784.29 1106.69 1784.64 1106.8C1785.48 1107.06 1785.99 1107.74 1785.83 1108.35H1785.84Z" fill="white"/>
                    <path d="M1592.83 1258.86C1592.74 1259.24 1592.64 1259.66 1592.14 1259.47C1591.48 1259.22 1591.04 1258.69 1591.27 1258.01C1591.39 1257.65 1591.93 1257.43 1592.28 1257.15C1592.6 1257.68 1593.18 1258.13 1592.84 1258.87L1592.83 1258.86Z" fill="white"/>
                    <path d="M1745.06 1245.61C1744.39 1245.43 1743.84 1244.51 1744.01 1244.06C1744.19 1243.58 1744.57 1243.74 1744.93 1243.79C1745.65 1243.89 1746.05 1244.37 1746.03 1245C1746.03 1245.19 1745.43 1245.68 1745.06 1245.61Z" fill="white"/>
                    <path d="M1694.31 1093.01C1694.22 1093.48 1694.09 1093.98 1693.64 1093.73C1693.08 1093.43 1692.62 1092.92 1692.12 1092.5C1692.64 1092.09 1693.14 1091.77 1693.89 1092.01C1694.6 1092.24 1694.32 1092.68 1694.31 1093V1093.01Z" fill="white"/>
                    <path d="M1797.35 1194.65C1797.28 1195.44 1796.9 1195.42 1796.35 1195.36C1795.5 1195.26 1795.55 1194.82 1795.67 1194.18C1795.79 1193.53 1796.12 1193.53 1796.67 1193.61C1797.48 1193.72 1797.6 1194.12 1797.35 1194.66V1194.65Z" fill="white"/>
                    <path d="M1685.39 1084.48C1684.91 1084.29 1684.04 1084.51 1684.36 1083.59C1684.54 1083.09 1685.05 1082.71 1685.65 1082.76C1686.65 1082.85 1686.17 1083.84 1686.34 1084.31C1686.56 1084.9 1685.72 1084.34 1685.39 1084.48Z" fill="white"/>
                    <path d="M1625.03 1024.15C1624.97 1024.43 1624.96 1024.72 1624.86 1024.98C1624.67 1025.45 1624.28 1025.32 1623.93 1025.22C1623.67 1025.15 1623.33 1025.23 1623.22 1024.89C1623.1 1024.5 1623.22 1024.19 1623.59 1023.98C1623.75 1023.89 1623.9 1023.77 1624.06 1023.67C1624.36 1023.48 1624.78 1023.07 1624.96 1023.15C1625.35 1023.34 1625.07 1023.8 1625.04 1024.15H1625.03Z" fill="white"/>
                    <path d="M1704.18 1282.52C1704.79 1282.85 1704.42 1283.33 1704.35 1283.74C1704.23 1284.5 1703.67 1284.16 1703.26 1284.1C1702.88 1284.05 1702.5 1283.94 1702.66 1283.41C1702.82 1282.87 1703.71 1282.36 1704.18 1282.52Z" fill="white"/>
                    <path d="M1739.17 1133.97C1738.68 1133.85 1738.29 1133.77 1738.45 1133.24C1738.61 1132.7 1739.51 1132.16 1739.97 1132.35C1740.44 1132.54 1740.24 1132.93 1740.22 1133.29C1740.17 1134.17 1739.66 1134.11 1739.17 1133.97Z" fill="white"/>
                    <path d="M1660.85 1054.25C1661.03 1054.34 1661.42 1054.42 1661.46 1054.58C1661.58 1055.03 1661.06 1055.13 1660.81 1055.21C1660.43 1055.34 1660.14 1056.36 1659.58 1055.41C1659.4 1055.09 1659.1 1054.81 1659.3 1054.42C1659.7 1053.63 1660.28 1054.37 1660.85 1054.25Z" fill="white"/>
                    <path d="M1559.9 1164.1C1559.57 1164.41 1559.16 1165.13 1558.77 1164.7C1558.43 1164.33 1557.63 1164.45 1557.71 1163.77C1557.75 1163.36 1559.24 1163.07 1559.71 1163.37C1559.95 1163.53 1560.02 1163.8 1559.9 1164.11V1164.1Z" fill="white"/>
                    <path d="M1779.42 1193.22C1779.42 1193.22 1779.61 1193.7 1779.72 1194.15C1779.77 1194.35 1778.55 1194.87 1778.3 1194.77C1777.64 1194.49 1778.06 1193.99 1778.09 1193.58C1778.12 1193.08 1778.46 1193.02 1779.42 1193.22Z" fill="white"/>
                    <path d="M1768.31 1225.48C1767.99 1226.09 1767.51 1225.7 1767.1 1225.67C1766.58 1225.63 1766.47 1225.2 1766.68 1224.89C1766.92 1224.53 1767.33 1224.27 1767.72 1224.04C1767.86 1223.96 1768.41 1225.25 1768.31 1225.48Z" fill="white"/>
                    <path d="M1609.4 1301.81C1608.89 1301.81 1608.74 1301.55 1608.79 1301.27C1608.94 1300.54 1609.47 1300.25 1610.17 1300.22C1610.53 1300.2 1610.68 1300.48 1610.63 1300.76C1610.5 1301.52 1609.93 1301.75 1609.39 1301.81H1609.4Z" fill="white"/>
                    <path d="M1594.08 1134.01C1594.66 1134.21 1595.03 1134.69 1594.94 1135.17C1594.89 1135.41 1594.35 1135.87 1593.8 1135.74C1593.06 1135.58 1593.39 1135.06 1593.44 1134.65C1593.48 1134.26 1593.61 1133.93 1594.09 1134.01H1594.08Z" fill="white"/>
                    <path d="M1704.59 1301.51C1704.32 1301.5 1703.38 1300.51 1703.51 1300.41C1703.87 1300.14 1704.27 1299.86 1704.69 1299.78C1705.08 1299.71 1705.38 1300 1705.23 1300.48C1705.1 1300.87 1705.3 1301.48 1704.59 1301.51Z" fill="white"/>
                    <path d="M1593.48 1128.13C1593.22 1128.6 1592.79 1129.05 1592.04 1128.88C1591.77 1128.82 1591.57 1128.58 1591.72 1128.25C1592.01 1127.61 1592.49 1127.23 1593.21 1127.36C1593.48 1127.41 1593.67 1127.65 1593.48 1128.12V1128.13Z" fill="white"/>
                    <path d="M1534.17 1102.22C1534.1 1102.75 1533.7 1102.86 1533.38 1102.65C1533.02 1102.41 1532.76 1102 1532.53 1101.61C1532.45 1101.47 1533.74 1100.92 1533.97 1101.02C1534.31 1101.16 1534.38 1101.62 1534.17 1102.21V1102.22Z" fill="white"/>
                    <path d="M1686.2 1089.17C1686.11 1089.56 1686.03 1089.96 1685.51 1089.81C1684.94 1089.65 1684.58 1089.16 1684.68 1088.67C1684.73 1088.43 1685.27 1087.98 1685.82 1088.1C1686.57 1088.26 1686.2 1088.78 1686.2 1089.17Z" fill="white"/>
                    <path d="M1604.31 1048.18C1603.97 1048.6 1603.61 1048.96 1603.03 1048.91C1602.68 1048.88 1602.51 1048.63 1602.6 1048.36C1602.75 1047.92 1602.45 1047.11 1603.39 1047.21C1603.96 1047.27 1603.97 1047.88 1604.32 1048.18H1604.31Z" fill="white"/>
                    <path d="M1675.25 1096.83C1675.12 1096.95 1675 1097.06 1674.87 1097.18C1674.39 1096.5 1674.02 1095.72 1673.18 1095.37C1673.14 1095.35 1673.2 1094.99 1673.3 1094.83C1673.35 1094.74 1673.68 1094.7 1673.7 1094.74C1674.06 1095.42 1675.04 1095.48 1675.25 1096.28C1675.29 1096.45 1675.25 1096.64 1675.25 1096.83Z" fill="white"/>
                    <path d="M1670.74 1096.96C1670.19 1096.82 1670.64 1096.15 1670.76 1095.73C1671.01 1094.89 1671.66 1095.48 1672.13 1095.5C1672.27 1095.5 1672.72 1095.91 1672.22 1095.97C1671.48 1096.06 1672.08 1097.38 1670.73 1096.96H1670.74Z" fill="white"/>
                    <path d="M1646.27 1274.77C1646.97 1274.79 1647.03 1275.48 1647.39 1275.85C1647.1 1276.06 1646.76 1276.49 1646.53 1276.43C1646.02 1276.31 1645.76 1275.83 1645.76 1275.27C1645.76 1274.93 1645.99 1274.82 1646.27 1274.77Z" fill="white"/>
                    <path d="M1775.51 1187.73C1775.13 1187.59 1774.51 1187.73 1774.64 1186.96C1774.73 1186.41 1775.1 1186.45 1775.47 1186.54C1775.88 1186.64 1776.48 1186.54 1776.35 1187.3C1776.26 1187.85 1775.9 1187.78 1775.5 1187.73H1775.51Z" fill="white"/>
                    <path d="M1570.93 1258.6C1570.26 1259.28 1569.65 1259.34 1569 1259.09C1568.85 1259.03 1568.75 1258.85 1568.62 1258.73C1568.77 1258.63 1568.96 1258.55 1569.08 1258.41C1569.55 1257.9 1570.11 1258.11 1570.63 1258.25C1570.79 1258.29 1570.89 1258.55 1570.93 1258.59V1258.6Z" fill="white"/>
                    <path d="M1803.19 1198.25C1803.17 1198.39 1802.8 1198.39 1802.42 1198.29C1802.02 1198.18 1801.4 1198.31 1801.53 1197.54C1801.62 1197 1801.97 1197.01 1802.35 1197.11C1802.76 1197.22 1803.36 1197.11 1803.19 1198.26V1198.25Z" fill="white"/>
                    <path d="M1755.55 1159.52C1755.45 1159.43 1755.25 1159.32 1755.26 1159.23C1755.32 1158.58 1755.41 1157.96 1756.13 1157.63C1756.43 1157.5 1756.62 1157.46 1756.69 1157.78C1756.81 1158.28 1756.01 1159.47 1755.55 1159.51V1159.52Z" fill="white"/>
                    <path d="M1686.99 1248.58C1686.59 1249.41 1686 1248.71 1685.51 1248.71C1685.29 1248.71 1685.21 1248.25 1685.35 1247.99C1685.75 1247.22 1686.34 1247.8 1686.83 1247.85C1687.06 1247.88 1687.12 1248.28 1686.99 1248.58Z" fill="white"/>
                    <path d="M1693.51 1136.42C1693.11 1137.25 1692.52 1136.55 1692.03 1136.55C1691.81 1136.55 1691.73 1136.09 1691.87 1135.83C1692.27 1135.06 1692.86 1135.64 1693.35 1135.69C1693.58 1135.72 1693.64 1136.12 1693.51 1136.42Z" fill="white"/>
                    <path d="M1573.52 1185.35C1573.5 1185.49 1573.13 1185.49 1572.75 1185.39C1572.35 1185.28 1571.73 1185.4 1571.86 1184.64C1571.95 1184.1 1572.3 1184.11 1572.68 1184.21C1573.09 1184.32 1573.69 1184.21 1573.52 1185.36V1185.35Z" fill="white"/>
                    <path d="M1719.16 1064.56C1718.98 1063.99 1718.79 1063.39 1718.62 1062.79C1718.62 1062.76 1718.76 1062.65 1718.84 1062.64C1719.24 1062.64 1719.51 1062.83 1719.72 1063.19C1719.96 1063.59 1720.27 1063.96 1719.87 1064.4C1719.78 1064.5 1719.54 1064.48 1719.17 1064.56H1719.16Z" fill="white"/>
                    <path d="M1666.58 1073.13C1666.96 1073.17 1667.38 1073.2 1667.34 1073.73C1667.29 1074.41 1666.86 1074.4 1666.34 1074.27C1665.98 1074.18 1665.55 1074.19 1665.59 1073.66C1665.64 1072.99 1666.07 1072.96 1666.58 1073.13Z" fill="white"/>
                    <path d="M1612.35 1095.54C1612.1 1096.01 1611.92 1096.22 1611.59 1096.07C1610.95 1095.78 1610.58 1095.31 1610.7 1094.58C1610.72 1094.44 1610.93 1094.33 1611.05 1094.2C1611.5 1094.66 1611.94 1095.13 1612.34 1095.54H1612.35Z" fill="white"/>
                    <path d="M1722.88 1128.07C1722.85 1128.59 1722.67 1128.96 1722.44 1128.89C1721.97 1128.76 1721.2 1129.07 1721.12 1128.21C1721.09 1127.92 1721.29 1127.56 1721.55 1127.6C1722.04 1127.68 1722.71 1127.53 1722.88 1128.07Z" fill="white"/>
                    <path d="M1578.13 1204C1578.04 1204.39 1577.97 1204.75 1577.42 1204.63C1576.67 1204.46 1576.99 1203.94 1577.05 1203.53C1577.1 1203.15 1577.2 1202.79 1577.75 1202.91C1578.51 1203.08 1578.15 1203.6 1578.14 1204H1578.13Z" fill="white"/>
                    <path d="M1664.17 1220.96C1665 1221.36 1664.3 1221.95 1664.3 1222.44C1664.3 1222.66 1663.84 1222.74 1663.58 1222.6C1662.81 1222.2 1663.39 1221.61 1663.44 1221.12C1663.47 1220.89 1663.87 1220.83 1664.17 1220.96Z" fill="white"/>
                    <path d="M1612.27 1084.99C1612.65 1085.03 1613.07 1085.06 1613.03 1085.59C1612.98 1086.27 1612.55 1086.26 1612.03 1086.13C1611.67 1086.04 1611.24 1086.05 1611.28 1085.52C1611.33 1084.85 1611.76 1084.82 1612.27 1084.99Z" fill="white"/>
                    <path d="M1772.6 1102.11C1773.26 1102.17 1773.38 1102.4 1773.35 1102.71C1773.29 1103.39 1772.84 1103.36 1772.35 1103.21C1772.1 1103.13 1771.56 1103.09 1771.68 1102.9C1771.93 1102.5 1772.41 1102.25 1772.59 1102.11H1772.6Z" fill="white"/>
                    <path d="M1585.34 1239.79C1585.25 1239.92 1585.09 1240.12 1584.94 1240.33C1584.67 1239.88 1584.11 1239.55 1584.32 1238.91C1584.41 1238.63 1584.61 1238.49 1584.96 1238.57C1585.7 1238.74 1585.38 1239.24 1585.34 1239.79Z" fill="white"/>
                    <path d="M1594.24 1030.08C1593.98 1030.08 1593.83 1029.84 1593.85 1029.55C1593.88 1029.17 1594.01 1028.77 1594.52 1028.95C1594.88 1029.08 1595.59 1028.84 1595.48 1029.5C1595.44 1029.74 1594.88 1030.19 1594.24 1030.08Z" fill="white"/>
                    <path d="M1770.66 1059.02C1770.61 1059.7 1769.94 1059.77 1769.55 1060.11C1769.5 1059.85 1769.39 1059.59 1769.43 1059.35C1769.5 1058.95 1769.41 1058.35 1770.17 1058.46C1770.51 1058.51 1770.62 1058.71 1770.67 1059.02H1770.66Z" fill="white"/>
                    <path d="M1591.24 1062.55C1590.84 1062.56 1591.24 1062.65 1591.1 1062.31C1591.02 1062.12 1591.1 1061.71 1591.25 1061.6C1591.67 1061.3 1592.05 1060.76 1592.7 1061.08C1592.77 1061.12 1592.87 1061.26 1592.85 1061.29C1592.51 1061.96 1591.74 1062.09 1591.24 1062.54V1062.55Z" fill="white"/>
                    <path d="M1616.38 1110.4C1615.92 1110.66 1615.52 1111.23 1614.91 1110.87C1614.76 1110.78 1614.7 1110.34 1614.78 1110.14C1615.04 1109.49 1615.56 1109.92 1615.97 1109.94C1616.25 1109.95 1616.46 1110.07 1616.38 1110.41V1110.4Z" fill="white"/>
                    <path d="M1680.83 1177.21C1680.79 1177.4 1680.8 1177.61 1680.71 1177.76C1680.65 1177.86 1680.39 1177.96 1680.32 1177.91C1680.06 1177.74 1680.38 1175.99 1680.7 1175.86C1680.78 1175.83 1681.04 1176.02 1681.04 1176.09C1681.01 1176.47 1680.91 1176.84 1680.84 1177.21H1680.83Z" fill="white"/>
                    <path d="M1527.43 1142.8C1527.35 1143.39 1526.99 1143.41 1526.61 1143.29C1526.33 1143.2 1526.2 1143 1526.27 1142.65C1526.38 1142.13 1526.71 1142.1 1527.09 1142.22C1527.36 1142.31 1527.59 1142.5 1527.43 1142.79V1142.8Z" fill="white"/>
                    <path d="M1775.36 1077.33C1775.22 1077.61 1775.05 1077.76 1774.7 1077.68C1774.18 1077.57 1774.15 1077.24 1774.27 1076.86C1774.36 1076.58 1774.56 1076.45 1774.91 1076.52C1775.44 1076.63 1775.42 1076.96 1775.35 1077.33H1775.36Z" fill="white"/>
                    <path d="M1704.89 1278.05C1704.75 1278.33 1704.58 1278.48 1704.23 1278.4C1703.71 1278.29 1703.68 1277.96 1703.8 1277.58C1703.89 1277.3 1704.09 1277.17 1704.44 1277.24C1704.97 1277.35 1704.95 1277.68 1704.88 1278.05H1704.89Z" fill="white"/>
                    <path d="M1595.21 1268.36C1594.86 1268.28 1594.55 1268.18 1594.65 1267.65C1594.72 1267.3 1594.92 1267.16 1595.21 1267.19C1595.61 1267.22 1595.91 1267.37 1595.81 1267.89C1595.74 1268.24 1595.55 1268.34 1595.22 1268.36H1595.21Z" fill="white"/>
                    <path d="M1796.73 1177.76C1797.17 1178.07 1797.49 1178.44 1797.46 1179C1797.46 1179.15 1797.22 1179.42 1797.21 1179.41C1796.73 1179.16 1796.41 1178.78 1796.46 1178.21C1796.47 1178.06 1796.63 1177.92 1796.74 1177.76H1796.73Z" fill="white"/>
                    <path d="M1740.55 1264.83C1740.55 1265.18 1740.3 1265.3 1740.01 1265.27C1739.61 1265.24 1739.31 1265.09 1739.41 1264.57C1739.48 1264.22 1739.68 1264.08 1739.97 1264.11C1740.37 1264.14 1740.7 1264.3 1740.56 1264.83H1740.55Z" fill="white"/>
                    <path d="M1737.6 1149.73C1737.46 1150.01 1737.29 1150.16 1736.94 1150.08C1736.42 1149.97 1736.39 1149.64 1736.51 1149.26C1736.6 1148.98 1736.8 1148.85 1737.15 1148.92C1737.68 1149.03 1737.66 1149.36 1737.59 1149.73H1737.6Z" fill="white"/>
                    <path d="M1550.91 1083.13C1550.74 1083.45 1550.58 1083.6 1550.23 1083.53C1549.71 1083.42 1549.68 1083.09 1549.8 1082.71C1549.89 1082.43 1550.09 1082.3 1550.44 1082.37C1550.98 1082.47 1550.92 1082.82 1550.91 1083.14V1083.13Z" fill="white"/>
                    <path d="M1574.59 1082.95C1574.04 1082.88 1574.02 1082.52 1574.14 1082.13C1574.23 1081.85 1574.43 1081.72 1574.78 1081.79C1575.3 1081.9 1575.33 1082.23 1575.21 1082.61C1575.12 1082.89 1574.91 1083.08 1574.59 1082.95Z" fill="white"/>
                    <path d="M1816.25 1212.95C1816.33 1212.58 1816.42 1212.21 1816.94 1212.36C1817.1 1212.41 1817.24 1212.53 1817.38 1212.62C1817.07 1212.89 1816.76 1213.17 1816.45 1213.44C1816.38 1213.28 1816.32 1213.12 1816.25 1212.95Z" fill="white"/>
                    <path d="M1764.83 1222.13C1764.75 1222.5 1764.66 1222.87 1764.14 1222.72C1763.98 1222.67 1763.84 1222.55 1763.7 1222.46C1764.01 1222.19 1764.32 1221.91 1764.63 1221.64C1764.7 1221.8 1764.76 1221.96 1764.83 1222.13Z" fill="white"/>
                    <path d="M1756.51 1088.65C1756.19 1088.88 1755.89 1089.09 1755.59 1089.3C1755.54 1089.05 1755.41 1088.77 1755.47 1088.55C1755.6 1088.06 1756.02 1088.17 1756.36 1088.29C1756.45 1088.32 1756.47 1088.55 1756.52 1088.65H1756.51Z" fill="white"/>
                    <path d="M1656.12 1219.4C1655.93 1219.28 1655.65 1219.17 1655.66 1219.11C1655.74 1218.61 1656.28 1218.59 1656.58 1218.3C1656.65 1218.46 1656.77 1218.63 1656.76 1218.78C1656.73 1219.18 1656.57 1219.48 1656.12 1219.4Z" fill="white"/>
                    <path d="M1614.15 1300.63C1614.05 1300.73 1613.94 1300.92 1613.84 1300.92C1613.47 1300.91 1613.05 1300.85 1613.12 1300.34C1613.15 1300.11 1613.38 1299.91 1613.52 1299.69C1613.73 1300 1613.93 1300.31 1614.15 1300.63Z" fill="white"/>
                    <path d="M1635.69 1281.56C1636.21 1281.64 1636.19 1282.01 1636.08 1282.38C1636.03 1282.53 1635.85 1282.63 1635.72 1282.76C1635.54 1282.39 1635.35 1282.02 1635.17 1281.65C1635.34 1281.62 1635.51 1281.6 1635.69 1281.57V1281.56Z" fill="white"/>
                    <path d="M1742.72 1107.75C1742.67 1107.81 1742.57 1108.01 1742.47 1108.01C1742.11 1108 1741.68 1107.95 1741.73 1107.44C1741.75 1107.21 1741.99 1107 1742.12 1106.78C1742.31 1107.09 1742.5 1107.4 1742.71 1107.74L1742.72 1107.75Z" fill="white"/>
                    <path d="M1733.95 1144.2C1733.87 1144.72 1733.5 1144.7 1733.13 1144.59C1732.98 1144.54 1732.88 1144.36 1732.75 1144.23C1733.12 1144.05 1733.49 1143.86 1733.86 1143.68C1733.89 1143.85 1733.91 1144.02 1733.94 1144.2H1733.95Z" fill="white"/>
                    <path d="M1679.18 1300.8C1679.06 1300.93 1678.94 1301.06 1678.83 1301.19C1678.63 1300.83 1678.43 1300.47 1678.23 1300.11C1678.4 1300.08 1678.57 1300 1678.73 1300.02C1679.24 1300.06 1679.26 1300.41 1679.18 1300.8Z" fill="white"/>
                    <path d="M1577.52 1101.24C1578.04 1101.32 1578.02 1101.69 1577.91 1102.06C1577.86 1102.21 1577.68 1102.31 1577.55 1102.44C1577.37 1102.07 1577.18 1101.7 1577 1101.33C1577.17 1101.3 1577.34 1101.28 1577.52 1101.25V1101.24Z" fill="white"/>
                    <path d="M1538.05 1095.87C1538.13 1096.1 1538.25 1096.27 1538.24 1096.42C1538.2 1096.82 1538.03 1097.14 1537.54 1096.98C1537.38 1096.93 1537.25 1096.8 1537.11 1096.7C1537.42 1096.43 1537.72 1096.15 1538.05 1095.86V1095.87Z" fill="white"/>
                    <path d="M1742.36 1030.97C1742.1 1031.27 1741.82 1031.08 1741.57 1031.02C1741.49 1031 1741.39 1030.7 1741.44 1030.63C1741.65 1030.33 1741.95 1030.52 1742.2 1030.59C1742.28 1030.61 1742.31 1030.85 1742.36 1030.97Z" fill="white"/>
                    <path d="M1751.17 1054.69C1751.2 1055.01 1751.27 1055.27 1751.23 1055.52C1751.21 1055.66 1751 1055.77 1750.88 1055.9C1750.83 1055.65 1750.73 1055.39 1750.75 1055.14C1750.76 1055.01 1750.98 1054.89 1751.17 1054.69Z" fill="white"/>
                    <path d="M1540.61 1198.99C1540.35 1199.29 1540.07 1199.1 1539.82 1199.04C1539.74 1199.02 1539.64 1198.72 1539.69 1198.65C1539.9 1198.35 1540.2 1198.54 1540.45 1198.61C1540.53 1198.63 1540.56 1198.87 1540.61 1198.99Z" fill="white"/>
                    <path d="M1608.27 1283.07C1607.95 1283.1 1607.69 1283.17 1607.44 1283.13C1607.3 1283.11 1607.19 1282.9 1607.06 1282.78C1607.31 1282.73 1607.57 1282.63 1607.82 1282.65C1607.95 1282.66 1608.07 1282.88 1608.27 1283.07Z" fill="white"/>
                    <path d="M1611.38 1283.28C1611.23 1283.24 1610.97 1283.21 1610.75 1283.09C1610.49 1282.95 1610.53 1282.75 1610.82 1282.73C1611.07 1282.71 1611.34 1282.77 1611.56 1282.87C1611.82 1283 1611.77 1283.17 1611.38 1283.28Z" fill="white"/>
                    <path d="M1530.27 1213.14C1530.41 1213.23 1530.68 1213.33 1530.68 1213.43C1530.68 1213.77 1530.41 1213.8 1530.14 1213.7C1529.98 1213.64 1529.73 1213.51 1529.73 1213.42C1529.73 1213.08 1530 1213.06 1530.27 1213.14Z" fill="white"/>
                    <path d="M1770.39 1081.57C1770.3 1081.71 1770.2 1081.98 1770.1 1081.98C1769.76 1081.98 1769.73 1081.71 1769.83 1081.44C1769.89 1081.28 1770.02 1081.03 1770.11 1081.03C1770.45 1081.03 1770.47 1081.3 1770.39 1081.57Z" fill="white"/>
                    <path d="M1685.31 1292.35C1685.34 1292.67 1685.41 1292.93 1685.37 1293.18C1685.35 1293.32 1685.14 1293.43 1685.02 1293.56C1684.97 1293.31 1684.87 1293.05 1684.89 1292.8C1684.9 1292.67 1685.12 1292.55 1685.31 1292.35Z" fill="white"/>
                    <path d="M1618.68 1047.42C1618.59 1047.51 1618.47 1047.64 1618.34 1047.76C1618.29 1047.51 1618.19 1047.25 1618.21 1047C1618.22 1046.87 1618.44 1046.75 1618.56 1046.62C1618.75 1046.85 1618.82 1047.1 1618.67 1047.42H1618.68Z" fill="white"/>
                    <path d="M1729.24 1298.63C1728.96 1298.6 1728.72 1298.49 1728.84 1298.17C1728.87 1298.08 1729.16 1298.06 1729.33 1298.07C1729.61 1298.07 1729.85 1298.2 1729.73 1298.53C1729.7 1298.62 1729.41 1298.61 1729.24 1298.64V1298.63Z" fill="white"/>
                    <path d="M1610.07 1085.73C1609.79 1085.7 1609.55 1085.59 1609.67 1085.27C1609.7 1085.18 1609.99 1085.16 1610.16 1085.17C1610.44 1085.17 1610.68 1085.3 1610.56 1085.63C1610.53 1085.72 1610.24 1085.71 1610.07 1085.74V1085.73Z" fill="white"/>
                    <path d="M1616.94 1095.94C1616.66 1095.91 1616.42 1095.8 1616.54 1095.48C1616.57 1095.39 1616.86 1095.37 1617.03 1095.38C1617.31 1095.38 1617.55 1095.51 1617.43 1095.84C1617.4 1095.93 1617.11 1095.92 1616.94 1095.95V1095.94Z" fill="white"/>
                    <path d="M1647.35 1129.56C1647.38 1129.88 1647.45 1130.14 1647.41 1130.39C1647.39 1130.53 1647.18 1130.64 1647.06 1130.77C1647.01 1130.52 1646.91 1130.26 1646.93 1130.01C1646.94 1129.88 1647.16 1129.76 1647.35 1129.56Z" fill="white"/>
                    <path d="M1748.59 1099.69C1748.45 1099.86 1748.3 1100.06 1748.14 1100.26C1748.07 1100.1 1747.92 1099.91 1747.96 1099.78C1748.03 1099.54 1748.22 1099.34 1748.36 1099.12C1748.43 1099.28 1748.49 1099.44 1748.59 1099.68V1099.69Z" fill="white"/>
                    <path d="M1707.58 1182.34C1707.49 1182.43 1707.37 1182.56 1707.24 1182.68C1707.19 1182.43 1707.09 1182.17 1707.11 1181.92C1707.12 1181.79 1707.34 1181.67 1707.46 1181.54C1707.65 1181.77 1707.72 1182.02 1707.57 1182.34H1707.58Z" fill="white"/>
                    <path d="M1749.8 1197.99C1749.83 1198.31 1749.9 1198.57 1749.86 1198.82C1749.84 1198.96 1749.63 1199.07 1749.51 1199.2C1749.46 1198.95 1749.36 1198.69 1749.38 1198.44C1749.39 1198.31 1749.61 1198.19 1749.8 1197.99Z" fill="white"/>
                    <path d="M1816.17 1222.02C1815.91 1222.32 1815.63 1222.13 1815.38 1222.07C1815.3 1222.05 1815.2 1221.75 1815.25 1221.68C1815.46 1221.38 1815.76 1221.57 1816.01 1221.64C1816.09 1221.66 1816.12 1221.9 1816.17 1222.02Z" fill="white"/>
                    <path d="M1755.11 1238.69C1754.83 1238.66 1754.59 1238.55 1754.71 1238.23C1754.74 1238.14 1755.03 1238.12 1755.2 1238.13C1755.48 1238.13 1755.72 1238.26 1755.6 1238.59C1755.57 1238.68 1755.28 1238.67 1755.11 1238.7V1238.69Z" fill="white"/>
                    <path d="M1557 1191.18C1557.14 1191.27 1557.41 1191.37 1557.41 1191.47C1557.41 1191.81 1557.14 1191.84 1556.87 1191.74C1556.71 1191.68 1556.46 1191.55 1556.46 1191.46C1556.46 1191.12 1556.73 1191.1 1557 1191.18Z" fill="white"/>
                    <path d="M1651.51 1194.27C1651.42 1194.41 1651.32 1194.68 1651.22 1194.68C1650.88 1194.68 1650.85 1194.41 1650.95 1194.14C1651.01 1193.98 1651.14 1193.73 1651.23 1193.73C1651.57 1193.73 1651.59 1194 1651.51 1194.27Z" fill="white"/>
                    <path d="M1649.48 1209.22C1649.52 1209.36 1649.58 1209.5 1649.58 1209.64C1649.58 1209.69 1649.44 1209.74 1649.37 1209.79C1649.33 1209.65 1649.27 1209.51 1649.27 1209.37C1649.27 1209.32 1649.41 1209.27 1649.48 1209.22Z" fill="white"/>
                    <path d="M1680.44 1310.98C1680.48 1311.12 1680.54 1311.26 1680.54 1311.4C1680.54 1311.45 1680.4 1311.5 1680.33 1311.55C1680.29 1311.41 1680.23 1311.27 1680.23 1311.13C1680.23 1311.08 1680.37 1311.03 1680.44 1310.98Z" fill="white"/>
                    <path d="M1627.64 1101.19C1627.55 1101.3 1627.46 1101.41 1627.37 1101.52C1627.32 1101.44 1627.21 1101.34 1627.23 1101.3C1627.29 1101.17 1627.39 1101.07 1627.48 1100.95C1627.53 1101.03 1627.58 1101.1 1627.64 1101.19Z" fill="white"/>
                    <path d="M1587.67 1079.8C1587.76 1079.74 1587.84 1079.69 1587.91 1079.64C1587.95 1079.78 1588.01 1079.92 1588.01 1080.06C1588.01 1080.11 1587.87 1080.16 1587.8 1080.21C1587.76 1080.07 1587.71 1079.94 1587.67 1079.8Z" fill="white"/>
                    <path d="M1670.76 1094.53C1670.65 1094.44 1670.54 1094.35 1670.43 1094.26C1670.51 1094.21 1670.61 1094.1 1670.65 1094.12C1670.78 1094.18 1670.88 1094.28 1671 1094.37C1670.92 1094.42 1670.85 1094.47 1670.76 1094.53Z" fill="white"/>
                    <path d="M1694.83 1132.7C1694.69 1132.74 1694.55 1132.8 1694.41 1132.8C1694.36 1132.8 1694.31 1132.66 1694.26 1132.59C1694.4 1132.55 1694.54 1132.49 1694.68 1132.49C1694.73 1132.49 1694.78 1132.63 1694.83 1132.7Z" fill="white"/>
                    <path d="M1740.21 1146.49C1740.15 1146.4 1740.1 1146.32 1740.05 1146.25C1740.19 1146.21 1740.33 1146.15 1740.47 1146.15C1740.52 1146.15 1740.57 1146.29 1740.62 1146.36C1740.48 1146.4 1740.35 1146.45 1740.21 1146.49Z" fill="white"/>
                    <path d="M1769.31 1095.74C1769.22 1095.85 1769.13 1095.96 1769.04 1096.07C1768.99 1095.99 1768.88 1095.89 1768.9 1095.85C1768.96 1095.72 1769.06 1095.62 1769.15 1095.5C1769.2 1095.58 1769.25 1095.65 1769.31 1095.74Z" fill="white"/>
                    <path d="M1652.45 1089.07C1652.36 1089.13 1652.28 1089.18 1652.21 1089.23C1652.17 1089.09 1652.11 1088.95 1652.11 1088.81C1652.11 1088.76 1652.25 1088.71 1652.32 1088.66C1652.36 1088.8 1652.41 1088.93 1652.45 1089.07Z" fill="white"/>
                    <path d="M1677.08 1157.51C1677.17 1157.45 1677.25 1157.4 1677.32 1157.35C1677.36 1157.49 1677.42 1157.63 1677.42 1157.77C1677.42 1157.82 1677.28 1157.87 1677.21 1157.92C1677.17 1157.78 1677.12 1157.65 1677.08 1157.51Z" fill="white"/>
                    <path d="M1812.31 1195.54C1812.36 1195.62 1812.47 1195.74 1812.45 1195.75C1812.36 1195.86 1812.25 1196 1812.13 1196.03C1811.87 1196.1 1811.77 1195.96 1811.94 1195.74C1812.02 1195.63 1812.19 1195.6 1812.31 1195.53V1195.54Z" fill="white"/>
                    <path d="M1811.83 1196.25C1811.7 1196.32 1811.52 1196.47 1811.45 1196.43C1811.24 1196.32 1811.24 1196.14 1811.41 1196.07C1811.56 1196.01 1811.72 1196.02 1811.88 1195.99C1811.86 1196.07 1811.85 1196.16 1811.83 1196.24V1196.25Z" fill="white"/>
                    <path d="M1621.37 1218.13C1621.28 1218.24 1621.19 1218.35 1621.1 1218.46C1621.05 1218.38 1620.94 1218.28 1620.96 1218.24C1621.02 1218.11 1621.12 1218.01 1621.21 1217.89C1621.26 1217.97 1621.31 1218.04 1621.37 1218.13Z" fill="white"/>
                    <path d="M1827.96 1113.09C1827.87 1113.2 1827.78 1113.31 1827.69 1113.42C1827.64 1113.34 1827.53 1113.24 1827.55 1113.2C1827.61 1113.07 1827.71 1112.97 1827.8 1112.85C1827.85 1112.93 1827.9 1113 1827.96 1113.09Z" fill="white"/>
                    <path d="M1817.2 1201.56C1817.11 1201.62 1817.03 1201.67 1816.96 1201.72C1816.92 1201.58 1816.86 1201.44 1816.86 1201.3C1816.86 1201.25 1817 1201.2 1817.07 1201.15C1817.11 1201.29 1817.16 1201.42 1817.2 1201.56Z" fill="white"/>
                    <path d="M1788.89 1096.19C1788.98 1096.08 1789.07 1095.97 1789.16 1095.86C1789.21 1095.94 1789.32 1096.04 1789.3 1096.08C1789.24 1096.21 1789.14 1096.31 1789.05 1096.43C1789 1096.35 1788.95 1096.28 1788.89 1096.19Z" fill="white"/>
                    <path d="M1716.9 1219.04C1716.81 1219.15 1716.72 1219.26 1716.63 1219.37C1716.58 1219.29 1716.47 1219.19 1716.49 1219.15C1716.55 1219.02 1716.65 1218.92 1716.74 1218.8C1716.79 1218.88 1716.84 1218.95 1716.9 1219.04Z" fill="white"/>
                    <path d="M1700.12 1085.49C1700.06 1085.4 1700.01 1085.32 1699.96 1085.25C1700.1 1085.21 1700.24 1085.15 1700.38 1085.15C1700.43 1085.15 1700.48 1085.29 1700.53 1085.36C1700.39 1085.4 1700.26 1085.45 1700.12 1085.49Z" fill="white"/>
                    <path d="M1783.9 1207.68C1783.94 1207.82 1784 1207.96 1784 1208.1C1784 1208.15 1783.86 1208.2 1783.79 1208.25C1783.75 1208.11 1783.69 1207.97 1783.69 1207.83C1783.69 1207.78 1783.83 1207.73 1783.9 1207.68Z" fill="white"/>
                    <path d="M1712.85 1085.32C1712.76 1085.43 1712.67 1085.54 1712.58 1085.65C1712.53 1085.57 1712.42 1085.47 1712.44 1085.43C1712.5 1085.3 1712.6 1085.2 1712.69 1085.08L1712.85 1085.32Z" fill="white"/>
                    <path d="M1659.28 1185.81C1659.39 1185.9 1659.5 1185.99 1659.61 1186.08C1659.53 1186.13 1659.43 1186.24 1659.39 1186.22C1659.26 1186.16 1659.16 1186.06 1659.04 1185.97C1659.12 1185.92 1659.19 1185.87 1659.28 1185.81Z" fill="white"/>
                    <path d="M1528.88 1221.74C1528.92 1221.88 1528.98 1222.02 1528.98 1222.16C1528.98 1222.21 1528.84 1222.26 1528.77 1222.31C1528.73 1222.17 1528.67 1222.03 1528.67 1221.89C1528.67 1221.84 1528.81 1221.79 1528.88 1221.74Z" fill="white"/>
                    <path d="M1708.13 1135.7C1709.05 1137.34 1708.2 1138.53 1707.38 1139.64C1707.08 1140.05 1706.52 1140.6 1706.44 1140.87C1705.94 1142.57 1704.37 1141.17 1703.64 1141.97C1702.97 1141.14 1701.9 1140.37 1701.76 1139.46C1701.63 1138.59 1701.56 1137.5 1701.85 1136.57C1702.13 1135.69 1702.12 1134.32 1703.78 1134.96C1704.03 1135.05 1704.45 1134.65 1704.8 1134.51C1706.38 1133.91 1707.5 1134.35 1708.12 1135.69L1708.13 1135.7Z" fill="white"/>
                    <path d="M1561.61 1095.9C1562.62 1095.45 1563.71 1095.92 1563.86 1096.28C1564.46 1097.66 1565.97 1097.64 1566.86 1098.54C1567.49 1099.18 1567.77 1099.66 1567.43 1100.46C1566.85 1101.77 1566.32 1102.15 1565.29 1101.93C1564.85 1101.84 1564.41 1101.8 1563.96 1101.77C1562.01 1101.65 1559.74 1098.4 1561.01 1096.54C1561.23 1096.21 1561.41 1095.93 1561.6 1095.9H1561.61Z" fill="white"/>
                    <path d="M1613.1 1170.48C1614.01 1170.85 1615.2 1170.06 1615.86 1171.25C1615.95 1171.42 1615.98 1171.65 1616.11 1171.76C1616.67 1172.21 1617.43 1172.49 1617.39 1173.39C1617.35 1174.1 1616.76 1174.49 1616.37 1174.98C1615.6 1175.95 1613.85 1176.12 1612.7 1175.39C1611.81 1174.82 1611.48 1174.04 1611.55 1172.96C1611.63 1171.84 1612.53 1171.29 1613.09 1170.48H1613.1Z" fill="white"/>
                    <path d="M1530.82 1277.27C1529.31 1277.97 1528.36 1277.73 1527.73 1276.7C1527.38 1276.13 1527.17 1275.48 1526.86 1274.89C1526.56 1274.33 1526.72 1274.04 1527.28 1273.82C1527.9 1273.57 1528.47 1273.16 1529.11 1273C1530.19 1272.71 1531.17 1273.09 1532.01 1273.8C1532.18 1273.94 1532.32 1274.33 1532.25 1274.52C1531.98 1275.22 1531.98 1275.95 1531.86 1276.66C1531.8 1277.01 1530.95 1277.22 1530.84 1277.28L1530.82 1277.27Z" fill="white"/>
                    <path d="M1461.61 1172.12C1462.27 1171.74 1463.19 1171.19 1463.97 1172.12C1464.34 1172.56 1465.03 1173.38 1464.5 1173.87C1463.92 1174.39 1463.7 1175.46 1462.67 1175.41C1461.96 1175.38 1461.21 1175.09 1460.54 1175.22C1459.92 1175.34 1459.73 1175.22 1459.48 1174.71C1459.13 1173.99 1459.37 1173.39 1459.88 1172.96C1460.31 1172.6 1460.9 1172.45 1461.61 1172.12Z" fill="white"/>
                    <path d="M1538.08 1296.79C1538.76 1296.45 1539.6 1296.72 1539.96 1297.37C1540.08 1297.59 1539.87 1300.31 1539.72 1300.48C1539.38 1300.87 1537.64 1300.63 1537.69 1300.55C1538.32 1299.52 1536.84 1299.57 1536.9 1298.89C1536.96 1298.24 1537.65 1297 1538.09 1296.78L1538.08 1296.79Z" fill="white"/>
                    <path d="M1672.3 1191.07C1672.14 1190.7 1671.79 1190.19 1672.13 1190.03C1672.76 1189.72 1673.33 1189.1 1674.22 1189.42C1674.71 1189.59 1674.87 1190.09 1675.27 1190.24C1675.92 1190.48 1676.15 1190.77 1675.93 1191.5C1675.69 1192.28 1675.1 1192.67 1674.49 1192.53C1673.62 1192.34 1672.55 1192.22 1672.31 1191.07H1672.3Z" fill="white"/>
                    <path d="M1580.29 1152.13C1579.73 1152.35 1579.15 1152.96 1578.7 1151.9C1578.6 1151.66 1578.01 1151.64 1577.67 1151.49C1577.27 1151.32 1576.89 1151.12 1576.5 1150.93C1577.05 1150.6 1577.6 1150.01 1578.16 1150.01C1578.87 1150.01 1579.58 1150.49 1580.31 1150.71C1580.71 1150.83 1581.05 1151.02 1581.07 1151.43C1581.08 1151.88 1580.62 1151.96 1580.29 1152.13Z" fill="white"/>
                    <path d="M1654.2 1286.93C1654.03 1287.01 1653.87 1287.11 1653.69 1287.16C1653.29 1287.28 1652.88 1287.82 1652.47 1287.29C1652.05 1286.75 1651.98 1286.14 1652.32 1285.48C1652.61 1284.92 1652.97 1284.99 1653.46 1285.12C1653.78 1285.21 1654.17 1285.02 1654.49 1285.1C1654.91 1285.21 1655.47 1285.25 1655.58 1285.81C1655.71 1286.48 1655.06 1286.44 1654.71 1286.67C1654.56 1286.77 1654.37 1286.83 1654.2 1286.91V1286.93Z" fill="white"/>
                    <path d="M1527.85 1294.64C1527.78 1295.52 1527.98 1296.45 1527.49 1297.27C1527.38 1297.46 1527.05 1297.62 1526.74 1297.46C1526.34 1297.26 1525.69 1297.28 1525.66 1296.71C1525.63 1296.01 1525.76 1295.29 1525.89 1294.59C1526.05 1293.76 1526.61 1294.6 1526.95 1294.38C1527.3 1294.15 1527.65 1294.14 1527.85 1294.64Z" fill="white"/>
                    <path d="M1621.45 1129.65C1621.02 1129.95 1620.66 1129.93 1620.41 1129.33C1620.04 1128.41 1620.27 1127.68 1621.05 1127.27C1621.75 1126.91 1622.34 1127.16 1622.77 1127.63C1623.26 1128.16 1622.6 1129.14 1621.44 1129.64L1621.45 1129.65Z" fill="white"/>
                    <path d="M1634.78 1044.4C1634.52 1044.52 1634.27 1044.69 1634 1044.75C1633.36 1044.89 1632.84 1044.28 1633.06 1043.67C1633.19 1043.31 1633.26 1042.91 1633.46 1042.6C1633.72 1042.2 1634.23 1041.93 1634.65 1042.03C1635.13 1042.15 1635.33 1042.85 1635.92 1042.93C1635.67 1043.41 1635.75 1044.1 1635.03 1044.27C1634.94 1044.29 1634.86 1044.35 1634.77 1044.39L1634.78 1044.4Z" fill="white"/>
                    <path d="M1572.42 1265.4C1572.74 1265.51 1573 1265.63 1573.27 1265.7C1573.96 1265.9 1573.72 1266.94 1574.54 1267.05C1574.98 1267.11 1575.16 1267.5 1574.97 1267.89C1574.79 1268.26 1574.42 1268.31 1574.03 1268.15C1573.5 1267.94 1572.95 1267.79 1572.44 1267.55C1572.2 1267.44 1571.61 1267.57 1571.86 1266.94C1572.06 1266.43 1572.24 1265.91 1572.42 1265.39V1265.4Z" fill="white"/>
                    <path d="M1455.79 1242.88C1455.62 1242.3 1456.23 1241.38 1456.91 1241.22C1457.18 1241.15 1458.69 1242.08 1458.75 1242.42C1458.89 1243.26 1458.09 1243.2 1457.66 1243.39C1456.86 1243.75 1455.96 1243.45 1455.79 1242.88Z" fill="white"/>
                    <path d="M1498.67 1175.4C1499 1175.89 1499.04 1176.27 1498.43 1176.52C1497.69 1176.83 1496.73 1176.66 1496.49 1176.14C1496.25 1175.62 1496.73 1174.75 1497.46 1174.42C1498.55 1173.92 1498.32 1175.05 1498.67 1175.4Z" fill="white"/>
                    <path d="M1499.39 1168.42C1499.91 1168.48 1500.49 1168.51 1500.78 1169.04C1501.1 1169.61 1500.36 1170.7 1499.59 1170.87C1499.09 1170.98 1498.96 1170.63 1498.83 1170.29C1498.54 1169.49 1498.8 1168.6 1499.38 1168.42H1499.39Z" fill="white"/>
                    <path d="M1545.16 1243.79C1544.82 1243.95 1544.64 1244.01 1544.48 1244.11C1543.89 1244.49 1543.43 1243.99 1542.91 1243.89C1543.16 1243.43 1543.08 1242.75 1543.75 1242.54C1543.93 1242.48 1544.1 1242.4 1544.26 1242.3C1544.81 1241.98 1545.21 1241.89 1545.55 1242.65C1545.88 1243.38 1545.67 1243.68 1545.16 1243.79Z" fill="white"/>
                    <path d="M1491.18 1087.42C1491.5 1088.01 1491.16 1088.32 1490.8 1088.33C1490.09 1088.34 1489.37 1088.22 1488.66 1088.1C1488 1087.98 1488.44 1087.57 1488.5 1087.29C1488.75 1086.04 1489.74 1086.97 1490.34 1086.7C1491.04 1086.39 1490.94 1087.23 1491.18 1087.42Z" fill="white"/>
                    <path d="M1521.13 1218.74C1521.78 1218.27 1522.39 1218.53 1522.84 1218.98C1523.17 1219.32 1522.6 1220.8 1522.2 1220.77C1521.44 1220.72 1520.93 1220.3 1520.66 1219.56C1520.47 1219.04 1520.79 1218.9 1521.13 1218.74Z" fill="white"/>
                    <path d="M1695.47 1236.4C1695.62 1236.95 1695.35 1237.42 1695.01 1237.84C1694.81 1238.09 1693.42 1237.34 1693.12 1236.83C1692.85 1236.37 1693.16 1236.17 1693.52 1236.01C1694.24 1235.7 1695.21 1235.88 1695.47 1236.4Z" fill="white"/>
                    <path d="M1515.5 1258.79C1515.46 1258.5 1515.52 1258.16 1515.86 1258.18C1516.66 1258.22 1517.48 1258.05 1518.28 1258.38C1518.61 1258.52 1518.77 1258.8 1518.75 1259.01C1518.73 1259.22 1518.42 1259.56 1518.23 1259.56C1517.51 1259.55 1516.8 1259.43 1516.09 1259.33C1515.79 1259.29 1515.59 1259.09 1515.5 1258.78V1258.79Z" fill="white"/>
                    <path d="M1495.17 1282.51C1495.43 1282.36 1495.76 1282.16 1496.06 1282.57C1496.32 1282.92 1496.09 1283.25 1496.02 1283.61C1495.89 1284.32 1495.27 1284.41 1494.78 1284.56C1494.49 1284.65 1493.83 1284.4 1493.8 1284.22C1493.73 1283.85 1493.88 1283.35 1494.12 1283.04C1494.32 1282.78 1494.77 1282.7 1495.16 1282.52L1495.17 1282.51Z" fill="white"/>
                    <path d="M1460.33 1128.95C1460.06 1128.56 1460.09 1128.2 1460.68 1127.96C1461.42 1127.66 1462.35 1127.84 1462.6 1128.35C1463.03 1129.21 1461.66 1129.13 1461.77 1129.89C1461.79 1130.02 1460.69 1129.59 1460.32 1128.95H1460.33Z" fill="white"/>
                    <path d="M1599.79 1239.82C1599.96 1239.86 1600.2 1239.84 1600.28 1239.94C1600.75 1240.53 1600.36 1242.14 1599.69 1242.39C1599.46 1242.48 1599.09 1242.54 1598.94 1242.42C1598.78 1242.29 1598.73 1241.92 1598.78 1241.68C1598.99 1240.64 1599.35 1240.02 1599.78 1239.81L1599.79 1239.82Z" fill="white"/>
                    <path d="M1671.88 1160.29C1671.76 1160.03 1671.59 1159.79 1671.53 1159.52C1671.37 1158.83 1672.03 1158.87 1672.38 1158.65C1672.84 1158.36 1673.04 1158.68 1673.21 1159.05C1673.36 1159.38 1673.58 1159.68 1673.39 1160.09C1673.2 1160.51 1673.14 1161.06 1672.56 1161.08C1672.11 1161.09 1672.05 1160.62 1671.88 1160.3V1160.29Z" fill="white"/>
                    <path d="M1517.37 1256.19C1516.79 1256.33 1516.67 1255.95 1516.55 1255.61C1516.27 1254.8 1516.53 1253.93 1517.12 1253.76C1517.61 1253.62 1517.75 1254.01 1517.87 1254.36C1518.15 1255.18 1517.88 1256.02 1517.38 1256.19H1517.37Z" fill="white"/>
                    <path d="M1452.82 1131.98C1453.07 1131.36 1453.15 1130.73 1453.92 1130.46C1454.55 1130.24 1454.71 1130.53 1454.94 1130.99C1455.19 1131.48 1455.19 1131.76 1454.65 1132.09C1453.95 1132.53 1453.43 1132.09 1452.82 1131.98Z" fill="white"/>
                    <path d="M1463.9 1122.63C1464.07 1122.65 1464.34 1122.62 1464.4 1122.72C1464.6 1123.03 1464.9 1123.41 1464.86 1123.72C1464.79 1124.22 1464.75 1124.87 1464.11 1125.08C1463.62 1125.24 1463.48 1124.85 1463.36 1124.5C1463.08 1123.67 1463.32 1122.86 1463.9 1122.63Z" fill="white"/>
                    <path d="M1698.04 1189.28C1698.4 1189.13 1698.79 1188.96 1698.93 1189.48C1699.12 1190.15 1698.95 1190.82 1698.27 1191.04C1697.91 1191.16 1697.42 1190.85 1696.98 1190.74C1697.22 1190.17 1697.24 1189.44 1698.03 1189.29L1698.04 1189.28Z" fill="white"/>
                    <path d="M1598.05 1074.82C1598.3 1075.46 1597.89 1076.44 1597.42 1076.57C1596.93 1076.71 1596.84 1076.31 1596.67 1075.99C1596.32 1075.35 1596.48 1074.75 1597 1074.4C1597.15 1074.29 1597.9 1074.48 1598.05 1074.82Z" fill="white"/>
                    <path d="M1505.3 1205.14C1505.73 1204.94 1506.2 1204.75 1506.28 1205.26C1506.37 1205.89 1506.23 1206.55 1506.19 1207.2C1505.56 1207.02 1505 1206.81 1504.76 1206.07C1504.53 1205.36 1505.04 1205.33 1505.31 1205.15L1505.3 1205.14Z" fill="white"/>
                    <path d="M1526.45 1062.74C1527.12 1062.33 1527.33 1062.65 1527.6 1063.13C1528.02 1063.87 1527.63 1064.09 1527.05 1064.37C1526.46 1064.65 1526.26 1064.39 1526 1063.9C1525.61 1063.18 1525.86 1062.86 1526.44 1062.74H1526.45Z" fill="white"/>
                    <path d="M1503.69 1217.31C1503.82 1217.8 1504.51 1218.38 1503.58 1218.66C1503.07 1218.82 1502.47 1218.62 1502.16 1218.11C1501.64 1217.26 1502.72 1217.06 1503 1216.64C1503.35 1216.12 1503.39 1217.12 1503.69 1217.3V1217.31Z" fill="white"/>
                    <path d="M1490.67 1301.19C1490.92 1301.07 1491.17 1300.91 1491.43 1300.84C1491.92 1300.71 1492.04 1301.11 1492.17 1301.45C1492.26 1301.7 1492.53 1301.93 1492.32 1302.22C1492.08 1302.55 1491.76 1302.63 1491.37 1302.46C1491.2 1302.38 1491.02 1302.34 1490.84 1302.27C1490.51 1302.14 1489.94 1302.04 1489.9 1301.85C1489.82 1301.43 1490.36 1301.38 1490.66 1301.2L1490.67 1301.19Z" fill="white"/>
                    <path d="M1651.69 1085.98C1651.59 1085.3 1652.2 1085.31 1652.57 1085.12C1653.25 1084.77 1653.3 1085.42 1653.5 1085.78C1653.69 1086.12 1653.82 1086.49 1653.3 1086.67C1652.77 1086.86 1651.84 1086.45 1651.69 1085.97V1085.98Z" fill="white"/>
                    <path d="M1511.87 1145.07C1512.06 1145.53 1512.22 1145.89 1511.7 1146.08C1511.17 1146.27 1510.22 1145.86 1510.1 1145.38C1509.98 1144.89 1510.41 1144.82 1510.71 1144.62C1511.44 1144.14 1511.7 1144.59 1511.87 1145.07Z" fill="white"/>
                    <path d="M1493.81 1254.75C1493.78 1254.56 1493.61 1254.2 1493.72 1254.06C1494.01 1253.7 1494.4 1254.06 1494.61 1254.21C1494.93 1254.44 1495.93 1254.08 1495.49 1255.08C1495.35 1255.41 1495.28 1255.82 1494.86 1255.89C1493.99 1256.03 1494.24 1255.13 1493.81 1254.74V1254.75Z" fill="white"/>
                    <path d="M1641.28 1271.34C1641.72 1271.42 1642.54 1271.32 1642.42 1271.89C1642.32 1272.38 1642.88 1272.95 1642.29 1273.29C1641.94 1273.49 1640.83 1272.47 1640.79 1271.92C1640.77 1271.63 1640.95 1271.42 1641.27 1271.33L1641.28 1271.34Z" fill="white"/>
                    <path d="M1535.82 1077.98C1535.82 1077.98 1536.09 1077.54 1536.39 1077.2C1536.52 1077.05 1537.66 1077.71 1537.71 1077.98C1537.87 1078.67 1537.22 1078.63 1536.88 1078.85C1536.46 1079.12 1536.21 1078.88 1535.81 1077.99L1535.82 1077.98Z" fill="white"/>
                    <path d="M1568.24 1067.96C1568.92 1067.85 1568.88 1068.47 1569.1 1068.82C1569.37 1069.26 1569.1 1069.6 1568.72 1069.61C1568.29 1069.63 1567.84 1069.45 1567.43 1069.28C1567.28 1069.22 1568 1068.02 1568.24 1067.96Z" fill="white"/>
                    <path d="M1722.81 1150.77C1723.11 1151.18 1722.99 1151.46 1722.74 1151.57C1722.07 1151.88 1721.52 1151.62 1721.08 1151.08C1720.86 1150.8 1720.99 1150.52 1721.24 1150.39C1721.92 1150.05 1722.45 1150.37 1722.81 1150.77Z" fill="white"/>
                    <path d="M1597.05 1261.56C1596.87 1260.97 1597.03 1260.4 1597.48 1260.19C1597.7 1260.09 1598.38 1260.26 1598.61 1260.77C1598.91 1261.46 1598.3 1261.5 1597.95 1261.7C1597.61 1261.89 1597.27 1261.99 1597.06 1261.55L1597.05 1261.56Z" fill="white"/>
                    <path d="M1666.69 1074.51C1666.84 1074.74 1666.6 1076.07 1666.44 1076.02C1666.02 1075.89 1665.55 1075.73 1665.24 1075.44C1664.95 1075.17 1665.01 1074.75 1665.48 1074.6C1665.87 1074.47 1666.24 1073.96 1666.68 1074.51H1666.69Z" fill="white"/>
                    <path d="M1592.68 1265.49C1593.21 1265.42 1593.82 1265.5 1594.13 1266.2C1594.24 1266.46 1594.16 1266.76 1593.81 1266.83C1593.13 1266.97 1592.54 1266.81 1592.22 1266.15C1592.1 1265.9 1592.18 1265.61 1592.68 1265.49Z" fill="white"/>
                    <path d="M1606.68 1328.32C1607.14 1328.07 1607.48 1328.32 1607.49 1328.7C1607.51 1329.13 1607.33 1329.58 1607.16 1329.99C1607.1 1330.14 1605.9 1329.43 1605.84 1329.18C1605.75 1328.83 1606.08 1328.5 1606.68 1328.32Z" fill="white"/>
                    <path d="M1506.98 1213.9C1507.35 1213.74 1507.71 1213.57 1507.9 1214.08C1508.11 1214.63 1507.92 1215.21 1507.48 1215.41C1507.26 1215.51 1506.58 1215.34 1506.35 1214.83C1506.04 1214.13 1506.67 1214.12 1506.99 1213.9H1506.98Z" fill="white"/>
                    <path d="M1522.13 1303.72C1522.67 1303.74 1523.16 1303.82 1523.47 1304.32C1523.65 1304.61 1523.55 1304.91 1523.28 1304.99C1522.84 1305.13 1522.37 1305.84 1521.9 1305.03C1521.61 1304.54 1522.1 1304.17 1522.13 1303.71V1303.72Z" fill="white"/>
                    <path d="M1519.56 1218.21C1519.73 1218.24 1519.9 1218.28 1520.07 1218.31C1519.81 1219.09 1519.4 1219.85 1519.61 1220.73C1519.61 1220.77 1519.29 1220.94 1519.11 1220.95C1519.01 1220.95 1518.78 1220.72 1518.8 1220.68C1519.13 1219.99 1518.6 1219.17 1519.13 1218.53C1519.24 1218.39 1519.42 1218.31 1519.57 1218.21H1519.56Z" fill="white"/>
                    <path d="M1522.31 1221.75C1522.52 1222.28 1521.72 1222.31 1521.31 1222.45C1520.49 1222.74 1520.58 1221.87 1520.32 1221.48C1520.24 1221.36 1520.3 1220.77 1520.64 1221.13C1521.14 1221.67 1521.85 1220.42 1522.31 1221.75Z" fill="white"/>
                    <path d="M1679.45 1137.04C1679.06 1136.46 1679.57 1136.01 1679.66 1135.5C1680 1135.61 1680.54 1135.63 1680.64 1135.85C1680.85 1136.33 1680.61 1136.82 1680.16 1137.15C1679.89 1137.35 1679.66 1137.23 1679.45 1137.03V1137.04Z" fill="white"/>
                    <path d="M1533.71 1084.34C1533.82 1084.73 1534.29 1085.15 1533.6 1085.49C1533.1 1085.74 1532.92 1085.42 1532.78 1085.07C1532.62 1084.68 1532.19 1084.26 1532.87 1083.91C1533.37 1083.66 1533.53 1083.99 1533.71 1084.34Z" fill="white"/>
                    <path d="M1710.69 1207.02C1711.63 1207.16 1712.03 1207.61 1712.21 1208.28C1712.25 1208.43 1712.17 1208.62 1712.14 1208.8C1711.97 1208.74 1711.79 1208.64 1711.62 1208.62C1710.93 1208.54 1710.77 1207.97 1710.58 1207.47C1710.52 1207.32 1710.67 1207.08 1710.68 1207.03L1710.69 1207.02Z" fill="white"/>
                    <path d="M1525.9 1055.94C1526.02 1055.87 1526.24 1056.17 1526.38 1056.54C1526.53 1056.93 1526.99 1057.35 1526.3 1057.7C1525.82 1057.95 1525.62 1057.66 1525.47 1057.29C1525.31 1056.9 1524.88 1056.47 1525.9 1055.94Z" fill="white"/>
                    <path d="M1522.77 1116.92C1522.75 1117.05 1522.79 1117.28 1522.71 1117.32C1522.15 1117.65 1521.6 1117.95 1520.91 1117.55C1520.63 1117.39 1520.49 1117.26 1520.7 1117.01C1521.03 1116.62 1522.46 1116.56 1522.76 1116.91L1522.77 1116.92Z" fill="white"/>
                    <path d="M1634.53 1119.71C1635.43 1119.54 1635.21 1120.43 1635.5 1120.82C1635.63 1121 1635.31 1121.33 1635.02 1121.37C1634.17 1121.5 1634.28 1120.69 1634.04 1120.27C1633.93 1120.07 1634.22 1119.78 1634.53 1119.71Z" fill="white"/>
                    <path d="M1540.63 1180.3C1541.53 1180.13 1541.31 1181.02 1541.6 1181.41C1541.73 1181.59 1541.41 1181.92 1541.12 1181.96C1540.27 1182.09 1540.38 1181.28 1540.14 1180.86C1540.03 1180.66 1540.32 1180.37 1540.63 1180.3Z" fill="white"/>
                    <path d="M1650.35 1247.94C1650.47 1247.87 1650.69 1248.17 1650.83 1248.54C1650.98 1248.93 1651.44 1249.35 1650.75 1249.7C1650.27 1249.95 1650.07 1249.66 1649.92 1249.29C1649.76 1248.9 1649.33 1248.47 1650.35 1247.94Z" fill="white"/>
                    <path d="M1467.87 1201.88C1467.51 1202.36 1467.15 1202.86 1466.77 1203.35C1466.75 1203.37 1466.57 1203.32 1466.52 1203.26C1466.28 1202.94 1466.28 1202.61 1466.44 1202.23C1466.62 1201.8 1466.74 1201.34 1467.33 1201.4C1467.47 1201.41 1467.59 1201.62 1467.87 1201.87V1201.88Z" fill="white"/>
                    <path d="M1505.62 1239.07C1505.43 1238.74 1505.21 1238.39 1505.66 1238.11C1506.23 1237.75 1506.48 1238.11 1506.68 1238.6C1506.82 1238.94 1507.08 1239.28 1506.63 1239.56C1506.06 1239.91 1505.79 1239.59 1505.63 1239.08L1505.62 1239.07Z" fill="white"/>
                    <path d="M1555.44 1269.46C1555.97 1269.39 1556.24 1269.4 1556.31 1269.75C1556.45 1270.43 1556.29 1271.01 1555.63 1271.34C1555.5 1271.4 1555.29 1271.3 1555.12 1271.28C1555.23 1270.65 1555.34 1270.02 1555.44 1269.46Z" fill="white"/>
                    <path d="M1516.69 1161.62C1517.12 1161.34 1517.52 1161.27 1517.61 1161.49C1517.78 1161.94 1518.48 1162.38 1517.83 1162.95C1517.61 1163.14 1517.2 1163.19 1517.09 1162.96C1516.87 1162.52 1516.36 1162.07 1516.69 1161.62Z" fill="white"/>
                    <path d="M1662.62 1233.29C1662.98 1233.14 1663.32 1232.98 1663.54 1233.5C1663.85 1234.2 1663.24 1234.25 1662.87 1234.45C1662.53 1234.63 1662.19 1234.76 1661.96 1234.25C1661.65 1233.54 1662.27 1233.52 1662.61 1233.3L1662.62 1233.29Z" fill="white"/>
                    <path d="M1625.74 1154.24C1625.57 1153.34 1626.46 1153.56 1626.85 1153.27C1627.03 1153.14 1627.36 1153.46 1627.4 1153.75C1627.53 1154.6 1626.72 1154.49 1626.3 1154.73C1626.1 1154.84 1625.81 1154.55 1625.74 1154.24Z" fill="white"/>
                    <path d="M1547.01 1275.72C1546.82 1275.39 1546.6 1275.04 1547.05 1274.76C1547.62 1274.4 1547.87 1274.76 1548.07 1275.25C1548.21 1275.59 1548.47 1275.93 1548.02 1276.21C1547.45 1276.56 1547.18 1276.24 1547.02 1275.73L1547.01 1275.72Z" fill="white"/>
                    <path d="M1466.66 1136.93C1466.32 1136.36 1466.44 1136.13 1466.7 1135.97C1467.28 1135.62 1467.52 1136 1467.69 1136.48C1467.78 1136.73 1468.06 1137.18 1467.83 1137.2C1467.37 1137.24 1466.88 1136.99 1466.66 1136.93Z" fill="white"/>
                    <path d="M1687.12 1206.49C1687.28 1206.49 1687.53 1206.49 1687.79 1206.49C1687.59 1206.97 1687.65 1207.62 1687.01 1207.82C1686.74 1207.91 1686.51 1207.83 1686.36 1207.5C1686.06 1206.81 1686.65 1206.77 1687.11 1206.48L1687.12 1206.49Z" fill="white"/>
                    <path d="M1513.5 1322.43C1513.65 1322.64 1513.55 1322.9 1513.3 1323.05C1512.98 1323.25 1512.58 1323.38 1512.42 1322.86C1512.31 1322.5 1511.7 1322.06 1512.3 1321.77C1512.51 1321.66 1513.21 1321.85 1513.5 1322.43Z" fill="white"/>
                    <path d="M1433.2 1163.78C1433.77 1163.42 1434.22 1163.92 1434.73 1164.03C1434.55 1164.22 1434.41 1164.47 1434.19 1164.58C1433.82 1164.76 1433.4 1165.18 1433.04 1164.51C1432.88 1164.2 1432.97 1164 1433.2 1163.78Z" fill="white"/>
                    <path d="M1541.34 1305.78C1541.58 1306.09 1541.42 1305.71 1541.23 1306.03C1541.13 1306.21 1540.75 1306.38 1540.57 1306.32C1540.08 1306.16 1539.43 1306.17 1539.31 1305.46C1539.3 1305.38 1539.35 1305.22 1539.39 1305.21C1540.13 1305.09 1540.68 1305.63 1541.34 1305.77V1305.78Z" fill="white"/>
                    <path d="M1565.01 1257.51C1565.49 1257.73 1566.18 1257.72 1566.25 1258.42C1566.27 1258.59 1565.95 1258.9 1565.74 1258.96C1565.07 1259.13 1565.11 1258.47 1564.88 1258.12C1564.72 1257.89 1564.69 1257.65 1565.01 1257.51Z" fill="white"/>
                    <path d="M1580.82 1166.54C1580.99 1166.46 1581.16 1166.33 1581.33 1166.32C1581.45 1166.32 1581.68 1166.46 1581.68 1166.55C1581.7 1166.86 1580.1 1167.64 1579.81 1167.45C1579.74 1167.41 1579.74 1167.08 1579.8 1167.04C1580.12 1166.85 1580.47 1166.7 1580.82 1166.54Z" fill="white"/>
                    <path d="M1643.23 1309.91C1643.75 1309.63 1643.98 1309.91 1644.11 1310.28C1644.2 1310.56 1644.12 1310.78 1643.8 1310.93C1643.32 1311.15 1643.1 1310.9 1642.98 1310.52C1642.89 1310.26 1642.91 1309.95 1643.24 1309.91H1643.23Z" fill="white"/>
                    <path d="M1445.15 1149.26C1445.46 1149.21 1445.67 1149.26 1445.82 1149.58C1446.04 1150.06 1445.79 1150.28 1445.41 1150.41C1445.13 1150.5 1444.91 1150.42 1444.76 1150.1C1444.54 1149.61 1444.81 1149.43 1445.15 1149.27V1149.26Z" fill="white"/>
                    <path d="M1647.69 1088.04C1648 1087.99 1648.21 1088.04 1648.36 1088.36C1648.58 1088.84 1648.33 1089.06 1647.95 1089.19C1647.67 1089.28 1647.45 1089.2 1647.3 1088.88C1647.08 1088.39 1647.35 1088.21 1647.69 1088.05V1088.04Z" fill="white"/>
                    <path d="M1704.27 1181.8C1704.42 1182.13 1704.51 1182.44 1704.03 1182.67C1703.71 1182.82 1703.48 1182.74 1703.33 1182.49C1703.12 1182.15 1703.06 1181.82 1703.54 1181.59C1703.86 1181.44 1704.05 1181.53 1704.27 1181.79V1181.8Z" fill="white"/>
                    <path d="M1513.25 1073.15C1513.25 1072.62 1513.35 1072.14 1513.82 1071.84C1513.95 1071.76 1514.3 1071.79 1514.3 1071.8C1514.38 1072.33 1514.26 1072.81 1513.77 1073.11C1513.64 1073.19 1513.44 1073.14 1513.24 1073.16L1513.25 1073.15Z" fill="white"/>
                    <path d="M1616.14 1067.16C1616.42 1066.96 1616.67 1067.09 1616.82 1067.34C1617.03 1067.68 1617.09 1068.01 1616.61 1068.24C1616.29 1068.39 1616.06 1068.31 1615.91 1068.06C1615.7 1067.72 1615.64 1067.37 1616.15 1067.16H1616.14Z" fill="white"/>
                    <path d="M1525.44 1137.08C1525.75 1137.03 1525.96 1137.08 1526.11 1137.4C1526.33 1137.88 1526.08 1138.1 1525.7 1138.23C1525.42 1138.32 1525.2 1138.24 1525.05 1137.92C1524.83 1137.43 1525.1 1137.25 1525.44 1137.09V1137.08Z" fill="white"/>
                    <path d="M1581.53 1326.09C1581.89 1326.03 1582.1 1326.08 1582.25 1326.4C1582.47 1326.88 1582.22 1327.1 1581.84 1327.23C1581.56 1327.32 1581.34 1327.23 1581.19 1326.92C1580.95 1326.43 1581.27 1326.27 1581.53 1326.09Z" fill="white"/>
                    <path d="M1567.49 1307.18C1567.75 1307.66 1567.48 1307.89 1567.1 1308.02C1566.82 1308.11 1566.6 1308.03 1566.45 1307.71C1566.23 1307.23 1566.48 1307.01 1566.86 1306.88C1567.13 1306.79 1567.41 1306.84 1567.49 1307.18Z" fill="white"/>
                    <path d="M1530.05 1036.83C1529.71 1036.98 1529.35 1037.13 1529.17 1036.62C1529.11 1036.46 1529.13 1036.28 1529.12 1036.11C1529.52 1036.2 1529.92 1036.29 1530.33 1036.37C1530.24 1036.52 1530.15 1036.67 1530.06 1036.82L1530.05 1036.83Z" fill="white"/>
                    <path d="M1567.6 1072.72C1567.94 1072.57 1568.3 1072.42 1568.48 1072.93C1568.54 1073.09 1568.52 1073.27 1568.53 1073.44C1568.13 1073.35 1567.73 1073.26 1567.32 1073.18C1567.41 1073.03 1567.5 1072.88 1567.59 1072.73L1567.6 1072.72Z" fill="white"/>
                    <path d="M1465.3 1157.75C1465.67 1157.87 1466.02 1157.99 1466.36 1158.11C1466.18 1158.3 1466.04 1158.57 1465.83 1158.65C1465.36 1158.83 1465.2 1158.44 1465.1 1158.09C1465.07 1158 1465.24 1157.85 1465.3 1157.75Z" fill="white"/>
                    <path d="M1629.21 1161.62C1629.23 1161.84 1629.3 1162.13 1629.24 1162.16C1628.79 1162.39 1628.46 1161.97 1628.05 1161.9C1628.14 1161.75 1628.2 1161.55 1628.33 1161.47C1628.66 1161.26 1629.01 1161.21 1629.2 1161.62H1629.21Z" fill="white"/>
                    <path d="M1719.07 1147.65C1719.21 1147.67 1719.43 1147.65 1719.49 1147.73C1719.7 1148.03 1719.89 1148.41 1719.45 1148.65C1719.25 1148.76 1718.95 1148.69 1718.69 1148.71C1718.82 1148.36 1718.94 1148.02 1719.07 1147.65Z" fill="white"/>
                    <path d="M1691.12 1141.54C1690.88 1141.08 1691.18 1140.87 1691.55 1140.75C1691.7 1140.7 1691.89 1140.79 1692.06 1140.81C1691.87 1141.18 1691.68 1141.54 1691.49 1141.91C1691.37 1141.79 1691.25 1141.67 1691.12 1141.54Z" fill="white"/>
                    <path d="M1488.72 1157.62C1488.8 1157.62 1489.02 1157.59 1489.07 1157.67C1489.28 1157.97 1489.48 1158.34 1489.05 1158.59C1488.85 1158.71 1488.55 1158.64 1488.29 1158.66C1488.43 1158.32 1488.57 1157.99 1488.72 1157.62Z" fill="white"/>
                    <path d="M1523.14 1143.26C1523.6 1143.02 1523.81 1143.32 1523.93 1143.69C1523.98 1143.84 1523.89 1144.03 1523.87 1144.2C1523.5 1144.01 1523.14 1143.82 1522.77 1143.63C1522.89 1143.51 1523.01 1143.39 1523.14 1143.26Z" fill="white"/>
                    <path d="M1681.03 1095.33C1681.21 1095.35 1681.38 1095.37 1681.55 1095.38C1681.38 1095.75 1681.21 1096.12 1681.04 1096.5C1680.91 1096.39 1680.75 1096.29 1680.67 1096.15C1680.4 1095.71 1680.67 1095.5 1681.03 1095.33Z" fill="white"/>
                    <path d="M1580.46 1294.09C1580.22 1293.63 1580.53 1293.42 1580.89 1293.3C1581.04 1293.25 1581.23 1293.34 1581.4 1293.36C1581.21 1293.73 1581.02 1294.09 1580.83 1294.46C1580.71 1294.34 1580.59 1294.22 1580.46 1294.09Z" fill="white"/>
                    <path d="M1599.31 1328.93C1599.44 1328.73 1599.51 1328.54 1599.64 1328.45C1599.98 1328.24 1600.34 1328.2 1600.5 1328.68C1600.55 1328.84 1600.52 1329.02 1600.53 1329.19C1600.13 1329.1 1599.73 1329.02 1599.31 1328.92V1328.93Z" fill="white"/>
                    <path d="M1427.28 1202.96C1427.67 1202.99 1427.69 1203.33 1427.78 1203.57C1427.81 1203.64 1427.63 1203.9 1427.55 1203.9C1427.19 1203.9 1427.16 1203.55 1427.07 1203.31C1427.04 1203.23 1427.21 1203.07 1427.28 1202.96Z" fill="white"/>
                    <path d="M1441.16 1181.97C1441.4 1181.76 1441.57 1181.55 1441.79 1181.43C1441.92 1181.37 1442.13 1181.46 1442.3 1181.49C1442.12 1181.68 1441.98 1181.91 1441.76 1182.04C1441.65 1182.11 1441.42 1182.01 1441.16 1181.97Z" fill="white"/>
                    <path d="M1680.61 1266.35C1681 1266.38 1681.02 1266.72 1681.11 1266.96C1681.14 1267.03 1680.96 1267.29 1680.88 1267.29C1680.52 1267.29 1680.49 1266.94 1680.4 1266.7C1680.37 1266.62 1680.54 1266.46 1680.61 1266.35Z" fill="white"/>
                    <path d="M1708.42 1162.67C1708.63 1162.91 1708.84 1163.08 1708.96 1163.3C1709.02 1163.43 1708.93 1163.64 1708.9 1163.81C1708.71 1163.63 1708.48 1163.49 1708.35 1163.27C1708.28 1163.16 1708.38 1162.93 1708.42 1162.67Z" fill="white"/>
                    <path d="M1706.77 1160.05C1706.82 1160.19 1706.95 1160.42 1706.99 1160.67C1707.03 1160.96 1706.84 1161.05 1706.65 1160.83C1706.49 1160.64 1706.37 1160.39 1706.33 1160.15C1706.28 1159.87 1706.44 1159.8 1706.77 1160.06V1160.05Z" fill="white"/>
                    <path d="M1698.05 1266.35C1698.05 1266.18 1697.96 1265.91 1698.04 1265.85C1698.32 1265.65 1698.5 1265.85 1698.57 1266.12C1698.61 1266.28 1698.66 1266.56 1698.59 1266.61C1698.31 1266.81 1698.15 1266.61 1698.05 1266.34V1266.35Z" fill="white"/>
                    <path d="M1451.46 1150.76C1451.63 1150.76 1451.9 1150.67 1451.96 1150.75C1452.16 1151.03 1451.96 1151.21 1451.69 1151.28C1451.53 1151.32 1451.25 1151.37 1451.2 1151.3C1451 1151.02 1451.2 1150.86 1451.47 1150.76H1451.46Z" fill="white"/>
                    <path d="M1670.65 1095.37C1670.89 1095.16 1671.06 1094.95 1671.28 1094.83C1671.41 1094.77 1671.62 1094.86 1671.79 1094.89C1671.61 1095.08 1671.47 1095.31 1671.25 1095.44C1671.14 1095.51 1670.91 1095.41 1670.65 1095.37Z" fill="white"/>
                    <path d="M1513.08 1292.62C1513.2 1292.64 1513.38 1292.66 1513.55 1292.69C1513.37 1292.88 1513.23 1293.11 1513.01 1293.24C1512.9 1293.31 1512.67 1293.21 1512.5 1293.18C1512.57 1292.89 1512.73 1292.69 1513.07 1292.62H1513.08Z" fill="white"/>
                    <path d="M1649.92 1056.4C1650.06 1056.64 1650.11 1056.9 1649.78 1056.99C1649.69 1057.01 1649.51 1056.8 1649.41 1056.66C1649.25 1056.43 1649.21 1056.17 1649.54 1056.08C1649.63 1056.06 1649.79 1056.29 1649.91 1056.41L1649.92 1056.4Z" fill="white"/>
                    <path d="M1548.9 1277.05C1549.04 1277.29 1549.09 1277.55 1548.76 1277.64C1548.67 1277.66 1548.49 1277.45 1548.39 1277.31C1548.23 1277.08 1548.19 1276.82 1548.52 1276.73C1548.61 1276.71 1548.77 1276.94 1548.89 1277.06L1548.9 1277.05Z" fill="white"/>
                    <path d="M1553.06 1265.55C1553.2 1265.79 1553.25 1266.05 1552.92 1266.14C1552.83 1266.16 1552.65 1265.95 1552.55 1265.81C1552.39 1265.58 1552.35 1265.32 1552.68 1265.23C1552.77 1265.21 1552.93 1265.44 1553.05 1265.56L1553.06 1265.55Z" fill="white"/>
                    <path d="M1562.21 1221.4C1562.45 1221.19 1562.62 1220.98 1562.84 1220.86C1562.97 1220.8 1563.18 1220.89 1563.35 1220.92C1563.17 1221.11 1563.03 1221.34 1562.81 1221.47C1562.7 1221.54 1562.47 1221.44 1562.21 1221.4Z" fill="white"/>
                    <path d="M1478.81 1157.63C1479.03 1157.63 1479.28 1157.65 1479.54 1157.66C1479.45 1157.81 1479.39 1158.04 1479.26 1158.09C1479.03 1158.17 1478.75 1158.14 1478.5 1158.15C1478.59 1158 1478.68 1157.85 1478.82 1157.63H1478.81Z" fill="white"/>
                    <path d="M1569.25 1142.06C1569.37 1142.08 1569.55 1142.1 1569.72 1142.13C1569.54 1142.32 1569.4 1142.55 1569.18 1142.68C1569.07 1142.75 1568.84 1142.65 1568.67 1142.62C1568.74 1142.33 1568.9 1142.13 1569.24 1142.06H1569.25Z" fill="white"/>
                    <path d="M1557.03 1098.96C1557.27 1098.75 1557.44 1098.54 1557.66 1098.42C1557.79 1098.36 1558 1098.45 1558.17 1098.48C1557.99 1098.67 1557.85 1098.9 1557.63 1099.03C1557.52 1099.1 1557.29 1099 1557.03 1098.96Z" fill="white"/>
                    <path d="M1537.38 1031.57C1537.77 1031.6 1537.79 1031.94 1537.88 1032.18C1537.91 1032.25 1537.73 1032.51 1537.65 1032.51C1537.29 1032.51 1537.26 1032.16 1537.17 1031.92C1537.14 1031.84 1537.31 1031.68 1537.38 1031.57Z" fill="white"/>
                    <path d="M1586.6 1070.81C1586.74 1071.05 1586.79 1071.31 1586.46 1071.4C1586.37 1071.42 1586.19 1071.21 1586.09 1071.07C1585.93 1070.84 1585.89 1070.58 1586.22 1070.49C1586.31 1070.47 1586.47 1070.7 1586.59 1070.82L1586.6 1070.81Z" fill="white"/>
                    <path d="M1664.73 1257.78C1664.73 1257.61 1664.64 1257.34 1664.72 1257.28C1665 1257.08 1665.18 1257.28 1665.25 1257.55C1665.29 1257.71 1665.34 1257.99 1665.27 1258.04C1664.99 1258.24 1664.83 1258.04 1664.73 1257.77V1257.78Z" fill="white"/>
                    <path d="M1611.73 1180.07C1611.9 1180.07 1612.17 1179.98 1612.23 1180.06C1612.43 1180.34 1612.23 1180.52 1611.96 1180.59C1611.8 1180.63 1611.52 1180.68 1611.47 1180.61C1611.27 1180.33 1611.47 1180.17 1611.74 1180.07H1611.73Z" fill="white"/>
                    <path d="M1624.93 1172.93C1625.02 1172.82 1625.09 1172.69 1625.2 1172.6C1625.24 1172.57 1625.36 1172.65 1625.45 1172.68C1625.36 1172.79 1625.29 1172.92 1625.18 1173.01C1625.14 1173.04 1625.02 1172.96 1624.93 1172.93Z" fill="white"/>
                    <path d="M1688.47 1088.35C1688.56 1088.24 1688.63 1088.11 1688.74 1088.02C1688.78 1087.99 1688.9 1088.07 1688.99 1088.1C1688.9 1088.21 1688.83 1088.34 1688.72 1088.43C1688.68 1088.46 1688.56 1088.38 1688.47 1088.35Z" fill="white"/>
                    <path d="M1551 1253.88C1551.14 1253.88 1551.28 1253.9 1551.42 1253.91C1551.39 1254 1551.37 1254.14 1551.32 1254.15C1551.18 1254.18 1551.04 1254.15 1550.89 1254.15C1550.92 1254.06 1550.95 1253.98 1550.99 1253.88H1551Z" fill="white"/>
                    <path d="M1557.29 1298.52C1557.19 1298.48 1557.1 1298.45 1557.02 1298.42C1557.11 1298.31 1557.18 1298.18 1557.29 1298.09C1557.33 1298.06 1557.45 1298.14 1557.54 1298.17C1557.46 1298.29 1557.37 1298.4 1557.29 1298.51V1298.52Z" fill="white"/>
                    <path d="M1520.35 1223.16C1520.35 1223.3 1520.33 1223.44 1520.32 1223.58C1520.23 1223.55 1520.09 1223.53 1520.08 1223.48C1520.05 1223.34 1520.08 1223.2 1520.08 1223.05C1520.17 1223.08 1520.25 1223.11 1520.35 1223.15V1223.16Z" fill="white"/>
                    <path d="M1536.86 1181.42C1536.97 1181.51 1537.1 1181.58 1537.19 1181.69C1537.22 1181.73 1537.14 1181.85 1537.11 1181.94C1537 1181.85 1536.87 1181.78 1536.78 1181.67C1536.75 1181.63 1536.83 1181.51 1536.86 1181.42Z" fill="white"/>
                    <path d="M1521.3 1136.89C1521.26 1136.99 1521.23 1137.08 1521.2 1137.16C1521.09 1137.07 1520.96 1137 1520.87 1136.89C1520.84 1136.85 1520.92 1136.73 1520.95 1136.64C1521.07 1136.72 1521.18 1136.81 1521.29 1136.89H1521.3Z" fill="white"/>
                    <path d="M1463.47 1143.31C1463.61 1143.31 1463.75 1143.33 1463.89 1143.34C1463.86 1143.43 1463.84 1143.57 1463.79 1143.58C1463.65 1143.61 1463.51 1143.58 1463.36 1143.58C1463.39 1143.49 1463.42 1143.41 1463.46 1143.31H1463.47Z" fill="white"/>
                    <path d="M1526.7 1241.06C1526.8 1241.1 1526.89 1241.13 1526.97 1241.16C1526.88 1241.27 1526.81 1241.4 1526.7 1241.49C1526.66 1241.52 1526.54 1241.44 1526.45 1241.41C1526.53 1241.29 1526.62 1241.18 1526.7 1241.07V1241.06Z" fill="white"/>
                    <path d="M1567.21 1181.11C1567.11 1181.07 1567.02 1181.04 1566.94 1181.01C1567.03 1180.9 1567.1 1180.77 1567.21 1180.68C1567.25 1180.65 1567.37 1180.73 1567.46 1180.76C1567.38 1180.88 1567.29 1180.99 1567.21 1181.1V1181.11Z" fill="white"/>
                    <path d="M1518.38 1050.21C1518.41 1050.12 1518.45 1049.97 1518.47 1049.97C1518.61 1049.97 1518.79 1049.98 1518.89 1050.06C1519.1 1050.23 1519.04 1050.39 1518.77 1050.38C1518.64 1050.38 1518.51 1050.27 1518.38 1050.2V1050.21Z" fill="white"/>
                    <path d="M1519.24 1050.17C1519.37 1050.24 1519.6 1050.29 1519.61 1050.37C1519.64 1050.6 1519.5 1050.71 1519.35 1050.61C1519.22 1050.53 1519.12 1050.39 1519.01 1050.28C1519.09 1050.24 1519.17 1050.21 1519.24 1050.17Z" fill="white"/>
                    <path d="M1648.59 1190.28C1648.73 1190.28 1648.87 1190.3 1649.01 1190.31C1648.98 1190.4 1648.96 1190.54 1648.91 1190.55C1648.77 1190.58 1648.63 1190.55 1648.48 1190.55C1648.51 1190.46 1648.54 1190.38 1648.58 1190.28H1648.59Z" fill="white"/>
                    <path d="M1442.98 1086.03C1443.12 1086.03 1443.26 1086.05 1443.4 1086.06C1443.37 1086.15 1443.35 1086.29 1443.3 1086.3C1443.16 1086.33 1443.02 1086.3 1442.87 1086.3C1442.9 1086.21 1442.93 1086.13 1442.97 1086.03H1442.98Z" fill="white"/>
                    <path d="M1520.34 1042.74C1520.44 1042.78 1520.53 1042.81 1520.61 1042.84C1520.52 1042.95 1520.45 1043.08 1520.34 1043.17C1520.3 1043.2 1520.18 1043.12 1520.09 1043.09C1520.17 1042.97 1520.26 1042.86 1520.34 1042.75V1042.74Z" fill="white"/>
                    <path d="M1452.34 1127.32C1452.2 1127.32 1452.06 1127.3 1451.92 1127.29C1451.95 1127.2 1451.97 1127.06 1452.02 1127.05C1452.16 1127.02 1452.3 1127.05 1452.45 1127.05C1452.42 1127.14 1452.39 1127.22 1452.35 1127.32H1452.34Z" fill="white"/>
                    <path d="M1593.25 1113.03C1593.39 1113.03 1593.53 1113.05 1593.67 1113.06C1593.64 1113.15 1593.62 1113.29 1593.57 1113.3C1593.43 1113.33 1593.29 1113.3 1593.14 1113.3C1593.17 1113.21 1593.2 1113.13 1593.24 1113.03H1593.25Z" fill="white"/>
                    <path d="M1495.86 1204.89C1495.82 1204.99 1495.79 1205.08 1495.76 1205.16C1495.65 1205.07 1495.52 1205 1495.43 1204.89C1495.4 1204.85 1495.48 1204.73 1495.51 1204.64C1495.63 1204.72 1495.74 1204.81 1495.85 1204.89H1495.86Z" fill="white"/>
                    <path d="M1544.8 1065.9C1544.89 1065.79 1544.96 1065.66 1545.07 1065.57C1545.11 1065.54 1545.23 1065.62 1545.32 1065.65C1545.23 1065.76 1545.16 1065.89 1545.05 1065.98C1545.01 1066.01 1544.89 1065.93 1544.8 1065.9Z" fill="white"/>
                    <path d="M1488.24 1194.76C1488.38 1194.76 1488.52 1194.78 1488.66 1194.79C1488.63 1194.88 1488.61 1195.02 1488.56 1195.03C1488.42 1195.06 1488.28 1195.03 1488.13 1195.03L1488.23 1194.76H1488.24Z" fill="white"/>
                    <path d="M1600.39 1178.8C1600.39 1178.66 1600.41 1178.52 1600.42 1178.38C1600.51 1178.41 1600.65 1178.43 1600.66 1178.48C1600.69 1178.62 1600.66 1178.76 1600.66 1178.91C1600.57 1178.88 1600.49 1178.85 1600.39 1178.81V1178.8Z" fill="white"/>
                    <path d="M1705.77 1262.43C1705.86 1262.32 1705.93 1262.19 1706.04 1262.1C1706.08 1262.07 1706.2 1262.15 1706.29 1262.18C1706.2 1262.29 1706.13 1262.42 1706.02 1262.51C1705.98 1262.54 1705.86 1262.46 1705.77 1262.43Z" fill="white"/>
                    <path d="M1560.31 1254.11C1559.86 1256.02 1558.36 1256.34 1556.94 1256.61C1556.42 1256.71 1555.59 1256.74 1555.35 1256.89C1553.76 1257.84 1553.54 1255.65 1552.42 1255.75C1552.49 1254.64 1552.21 1253.29 1552.75 1252.49C1553.26 1251.73 1553.98 1250.84 1554.87 1250.33C1555.71 1249.85 1556.67 1248.79 1557.5 1250.47C1557.62 1250.72 1558.23 1250.7 1558.6 1250.85C1560.24 1251.51 1560.79 1252.64 1560.32 1254.11H1560.31Z" fill="white"/>
                    <path d="M1475.83 1119.58C1476.93 1119.95 1477.43 1121.08 1477.29 1121.47C1476.77 1122.96 1477.95 1124.01 1478 1125.34C1478.03 1126.28 1477.91 1126.85 1477.08 1127.22C1475.71 1127.82 1475.03 1127.73 1474.39 1126.84C1474.12 1126.45 1473.81 1126.12 1473.48 1125.78C1472.06 1124.3 1472.63 1120.19 1474.92 1119.67C1475.33 1119.58 1475.66 1119.49 1475.83 1119.59V1119.58Z" fill="white"/>
                    <path d="M1462.54 1213.47C1462.98 1214.4 1464.45 1214.64 1464.12 1216.02C1464.07 1216.21 1463.93 1216.41 1463.95 1216.59C1464.06 1217.33 1464.45 1218.09 1463.77 1218.75C1463.24 1219.27 1462.51 1219.15 1461.86 1219.25C1460.58 1219.45 1459.11 1218.35 1458.75 1216.97C1458.46 1215.9 1458.77 1215.06 1459.59 1214.28C1460.44 1213.47 1461.53 1213.69 1462.53 1213.47H1462.54Z" fill="white"/>
                    <path d="M1323.51 1237.26C1321.85 1236.72 1321.29 1235.87 1321.53 1234.63C1321.66 1233.95 1321.97 1233.3 1322.14 1232.62C1322.3 1231.98 1322.64 1231.87 1323.22 1232.1C1323.87 1232.35 1324.6 1232.44 1325.21 1232.76C1326.25 1233.31 1326.73 1234.29 1326.87 1235.43C1326.9 1235.66 1326.73 1236.06 1326.54 1236.15C1325.84 1236.5 1325.32 1237.06 1324.72 1237.52C1324.43 1237.74 1323.62 1237.3 1323.5 1237.27L1323.51 1237.26Z" fill="white"/>
                    <path d="M1344.84 1107.28C1345.61 1107.46 1346.72 1107.68 1346.66 1108.95C1346.63 1109.55 1346.58 1110.68 1345.83 1110.67C1345.01 1110.67 1344.09 1111.33 1343.33 1110.56C1342.8 1110.03 1342.43 1109.28 1341.83 1108.91C1341.27 1108.56 1341.21 1108.33 1341.38 1107.76C1341.62 1106.95 1342.23 1106.67 1342.92 1106.7C1343.5 1106.73 1344.07 1107.03 1344.85 1107.28H1344.84Z" fill="white"/>
                    <path d="M1315.25 1257.43C1316.01 1257.65 1316.47 1258.45 1316.28 1259.21C1316.22 1259.46 1314.12 1261.4 1313.89 1261.43C1313.36 1261.49 1312.18 1260.07 1312.27 1260.04C1313.49 1259.69 1312.31 1258.68 1312.84 1258.2C1313.34 1257.74 1314.76 1257.28 1315.25 1257.42V1257.43Z" fill="white"/>
                    <path d="M1493.48 1271.29C1493.62 1270.89 1493.71 1270.25 1494.09 1270.37C1494.8 1270.58 1495.67 1270.51 1496.13 1271.38C1496.39 1271.86 1496.15 1272.36 1496.35 1272.76C1496.68 1273.41 1496.65 1273.79 1495.97 1274.19C1495.23 1274.62 1494.5 1274.5 1494.13 1273.96C1493.6 1273.2 1492.86 1272.35 1493.48 1271.29Z" fill="white"/>
                    <path d="M1450.32 1176.09C1449.73 1175.86 1448.85 1175.92 1449.26 1174.79C1449.35 1174.54 1448.92 1174.11 1448.75 1173.74C1448.57 1173.33 1448.42 1172.9 1448.25 1172.48C1448.91 1172.62 1449.75 1172.56 1450.18 1172.95C1450.73 1173.45 1450.94 1174.33 1451.34 1175.02C1451.56 1175.4 1451.69 1175.79 1451.41 1176.11C1451.1 1176.47 1450.68 1176.2 1450.32 1176.09Z" fill="white"/>
                    <path d="M1411.56 1332.2C1411.37 1332.14 1411.18 1332.1 1411 1332.02C1410.6 1331.83 1409.91 1331.95 1409.97 1331.25C1410.03 1330.53 1410.41 1330.02 1411.14 1329.75C1411.76 1329.52 1411.99 1329.84 1412.27 1330.29C1412.45 1330.58 1412.88 1330.71 1413.07 1331C1413.32 1331.38 1413.72 1331.81 1413.4 1332.32C1413.02 1332.93 1412.55 1332.44 1412.12 1332.37C1411.93 1332.34 1411.75 1332.26 1411.56 1332.2Z" fill="white"/>
                    <path d="M1308.9 1248.51C1308.23 1249.14 1307.72 1250 1306.76 1250.28C1306.54 1250.34 1306.18 1250.24 1306.04 1249.89C1305.87 1249.45 1305.36 1249.01 1305.75 1248.55C1306.23 1247.99 1306.83 1247.52 1307.43 1247.08C1308.14 1246.56 1307.98 1247.6 1308.39 1247.67C1308.82 1247.75 1309.1 1247.99 1308.9 1248.51Z" fill="white"/>
                    <path d="M1497.92 1187.98C1497.37 1187.9 1497.11 1187.63 1497.35 1187C1497.71 1186.03 1498.41 1185.64 1499.3 1185.88C1500.09 1186.09 1500.37 1186.71 1500.37 1187.38C1500.37 1188.14 1499.17 1188.42 1497.92 1187.99V1187.98Z" fill="white"/>
                    <path d="M1568.64 1131.87C1568.35 1131.78 1568.04 1131.74 1567.79 1131.59C1567.19 1131.25 1567.23 1130.41 1567.84 1130.09C1568.19 1129.9 1568.53 1129.65 1568.91 1129.56C1569.4 1129.44 1569.98 1129.59 1570.23 1129.97C1570.52 1130.41 1570.17 1131.08 1570.57 1131.56C1570.04 1131.75 1569.61 1132.34 1568.93 1131.96C1568.84 1131.91 1568.74 1131.9 1568.65 1131.87H1568.64Z" fill="white"/>
                    <path d="M1363.93 1257.63C1364.09 1257.94 1364.22 1258.22 1364.37 1258.47C1364.76 1259.11 1363.84 1259.74 1364.39 1260.41C1364.68 1260.76 1364.54 1261.2 1364.12 1261.36C1363.72 1261.52 1363.4 1261.29 1363.21 1260.89C1362.95 1260.35 1362.63 1259.85 1362.41 1259.3C1362.3 1259.04 1361.76 1258.73 1362.4 1258.42C1362.92 1258.17 1363.42 1257.89 1363.93 1257.63Z" fill="white"/>
                    <path d="M1290.19 1157.59C1290.47 1157.02 1291.59 1156.75 1292.23 1157.1C1292.48 1157.24 1292.99 1159.03 1292.79 1159.32C1292.3 1160.07 1291.73 1159.45 1291.26 1159.3C1290.39 1159.01 1289.91 1158.14 1290.18 1157.59H1290.19Z" fill="white"/>
                    <path d="M1371.03 1136.09C1370.93 1136.7 1370.7 1137.02 1370.05 1136.78C1369.26 1136.49 1368.64 1135.68 1368.83 1135.11C1369.01 1134.54 1370 1134.22 1370.8 1134.48C1371.99 1134.87 1371.02 1135.58 1371.04 1136.09H1371.03Z" fill="white"/>
                    <path d="M1376.53 1131.24C1376.89 1131.66 1377.31 1132.09 1377.16 1132.71C1377 1133.38 1375.66 1133.68 1374.94 1133.28C1374.47 1133.01 1374.62 1132.65 1374.77 1132.3C1375.12 1131.48 1375.95 1130.99 1376.52 1131.25L1376.53 1131.24Z" fill="white"/>
                    <path d="M1358.28 1221.67C1357.91 1221.55 1357.72 1221.47 1357.53 1221.44C1356.81 1221.31 1356.8 1220.6 1356.48 1220.15C1357 1219.97 1357.42 1219.4 1358.08 1219.7C1358.26 1219.78 1358.45 1219.84 1358.64 1219.88C1359.29 1220.03 1359.67 1220.24 1359.38 1221.07C1359.11 1221.87 1358.74 1221.95 1358.28 1221.67Z" fill="white"/>
                    <path d="M1427.67 1063.1C1427.5 1063.79 1427.01 1063.78 1426.73 1063.53C1426.17 1063.03 1425.7 1062.43 1425.25 1061.83C1424.82 1061.27 1425.46 1061.27 1425.71 1061.09C1426.79 1060.31 1426.89 1061.72 1427.54 1061.94C1428.29 1062.2 1427.62 1062.77 1427.67 1063.09V1063.1Z" fill="white"/>
                    <path d="M1357.56 1185.36C1358.39 1185.46 1358.68 1186.09 1358.7 1186.76C1358.71 1187.26 1357.22 1187.98 1356.94 1187.68C1356.39 1187.1 1356.3 1186.42 1356.61 1185.66C1356.83 1185.12 1357.17 1185.24 1357.56 1185.36Z" fill="white"/>
                    <path d="M1479.15 1322.6C1478.88 1323.13 1478.33 1323.3 1477.78 1323.38C1477.45 1323.43 1476.91 1321.86 1477.05 1321.26C1477.17 1320.71 1477.55 1320.78 1477.94 1320.91C1478.72 1321.18 1479.33 1322.01 1479.16 1322.6H1479.15Z" fill="white"/>
                    <path d="M1324.83 1212.18C1325.01 1211.93 1325.29 1211.71 1325.54 1211.96C1326.13 1212.56 1326.88 1213.01 1327.25 1213.83C1327.41 1214.17 1327.33 1214.5 1327.16 1214.65C1327 1214.8 1326.51 1214.84 1326.37 1214.71C1325.83 1214.19 1325.36 1213.6 1324.88 1213.02C1324.68 1212.77 1324.67 1212.48 1324.82 1212.18H1324.83Z" fill="white"/>
                    <path d="M1292.37 1216.01C1292.68 1216.08 1293.07 1216.16 1293.02 1216.69C1292.97 1217.15 1292.55 1217.24 1292.26 1217.46C1291.66 1217.92 1291.11 1217.54 1290.64 1217.31C1290.36 1217.17 1290.02 1216.51 1290.12 1216.35C1290.33 1216.02 1290.8 1215.74 1291.2 1215.66C1291.54 1215.6 1291.94 1215.86 1292.38 1216L1292.37 1216.01Z" fill="white"/>
                    <path d="M1374.48 1073.17C1374.55 1072.68 1374.83 1072.42 1375.45 1072.66C1376.23 1072.96 1376.82 1073.75 1376.66 1074.32C1376.38 1075.28 1375.39 1074.25 1374.93 1074.92C1374.85 1075.03 1374.31 1073.92 1374.48 1073.17Z" fill="white"/>
                    <path d="M1403.12 1257.37C1403.22 1257.52 1403.42 1257.67 1403.41 1257.82C1403.36 1258.6 1401.91 1259.57 1401.22 1259.29C1400.98 1259.19 1400.66 1258.98 1400.63 1258.78C1400.6 1258.57 1400.82 1258.24 1401.02 1258.1C1401.92 1257.45 1402.64 1257.23 1403.12 1257.37Z" fill="white"/>
                    <path d="M1514.99 1247.32C1515.08 1247.04 1515.12 1246.73 1515.27 1246.48C1515.64 1245.83 1516.12 1246.33 1516.54 1246.41C1517.1 1246.51 1517.03 1246.91 1516.9 1247.3C1516.78 1247.66 1516.74 1248.04 1516.3 1248.23C1515.86 1248.42 1515.42 1248.8 1514.96 1248.4C1514.61 1248.09 1514.89 1247.68 1514.99 1247.31V1247.32Z" fill="white"/>
                    <path d="M1328.12 1211.5C1327.57 1211.2 1327.75 1210.82 1327.9 1210.47C1328.26 1209.64 1329.08 1209.16 1329.65 1209.45C1330.12 1209.69 1329.95 1210.09 1329.8 1210.44C1329.44 1211.27 1328.63 1211.73 1328.12 1211.5Z" fill="white"/>
                    <path d="M1366.56 1070.18C1367.19 1069.88 1367.7 1069.45 1368.48 1069.79C1369.12 1070.07 1369.04 1070.41 1368.89 1070.92C1368.73 1071.47 1368.54 1071.69 1367.88 1071.56C1367.02 1071.4 1366.94 1070.7 1366.56 1070.18Z" fill="white"/>
                    <path d="M1381.71 1070.84C1381.83 1070.98 1382.06 1071.14 1382.04 1071.26C1381.98 1071.64 1381.94 1072.14 1381.68 1072.35C1381.27 1072.69 1380.78 1073.15 1380.14 1072.86C1379.64 1072.64 1379.81 1072.24 1379.97 1071.88C1380.34 1071.04 1381.1 1070.59 1381.71 1070.83V1070.84Z" fill="white"/>
                    <path d="M1514.55 1288.18C1514.94 1288.32 1515.35 1288.47 1515.1 1288.97C1514.77 1289.62 1514.17 1290.01 1513.48 1289.7C1513.12 1289.54 1512.96 1288.95 1512.71 1288.55C1513.3 1288.28 1513.83 1287.74 1514.55 1288.18Z" fill="white"/>
                    <path d="M1518.81 1129.21C1518.55 1129.88 1517.53 1130.34 1517.08 1130.11C1516.61 1129.87 1516.82 1129.5 1516.91 1129.13C1517.1 1128.39 1517.64 1128.04 1518.29 1128.14C1518.48 1128.17 1518.92 1128.84 1518.8 1129.21H1518.81Z" fill="white"/>
                    <path d="M1355.04 1163.68C1355.52 1163.83 1356.01 1164.02 1355.71 1164.46C1355.33 1165.01 1354.75 1165.42 1354.26 1165.89C1353.9 1165.3 1353.62 1164.75 1353.96 1164C1354.28 1163.29 1354.71 1163.63 1355.04 1163.68Z" fill="white"/>
                    <path d="M1472.3 1069.13C1473.11 1069.29 1473.04 1069.68 1472.91 1070.24C1472.71 1071.11 1472.25 1071 1471.61 1070.81C1470.95 1070.61 1470.99 1070.27 1471.13 1069.71C1471.34 1068.88 1471.76 1068.81 1472.29 1069.13H1472.3Z" fill="white"/>
                    <path d="M1345.17 1171.89C1344.92 1172.36 1345.04 1173.29 1344.12 1172.85C1343.62 1172.61 1343.29 1172.04 1343.42 1171.42C1343.63 1170.4 1344.6 1171.01 1345.11 1170.89C1345.75 1170.73 1345.07 1171.54 1345.17 1171.89Z" fill="white"/>
                    <path d="M1275.66 1227.18C1275.94 1227.27 1276.24 1227.32 1276.49 1227.45C1276.95 1227.7 1276.77 1228.09 1276.63 1228.44C1276.53 1228.7 1276.57 1229.06 1276.2 1229.13C1275.78 1229.21 1275.47 1229.05 1275.3 1228.64C1275.22 1228.46 1275.12 1228.29 1275.03 1228.12C1274.87 1227.78 1274.49 1227.3 1274.6 1227.13C1274.84 1226.75 1275.29 1227.1 1275.65 1227.17L1275.66 1227.18Z" fill="white"/>
                    <path d="M1552.15 1175.84C1552.56 1175.25 1553.02 1175.69 1553.44 1175.8C1554.21 1176.01 1553.79 1176.55 1553.69 1176.97C1553.59 1177.36 1553.43 1177.74 1552.9 1177.51C1552.36 1177.28 1551.93 1176.3 1552.16 1175.83L1552.15 1175.84Z" fill="white"/>
                    <path d="M1402.69 1122.13C1402.51 1122.62 1402.38 1123.01 1401.85 1122.78C1401.31 1122.55 1400.86 1121.56 1401.11 1121.11C1401.36 1120.65 1401.74 1120.9 1402.11 1120.96C1403.02 1121.11 1402.89 1121.64 1402.69 1122.13Z" fill="white"/>
                    <path d="M1311.01 1193.69C1311.12 1193.52 1311.25 1193.12 1311.43 1193.1C1311.91 1193.03 1311.95 1193.58 1312.01 1193.85C1312.1 1194.25 1313.12 1194.68 1312.07 1195.15C1311.72 1195.3 1311.39 1195.57 1311.01 1195.32C1310.24 1194.81 1311.08 1194.3 1311.01 1193.7V1193.69Z" fill="white"/>
                    <path d="M1412.68 1311.04C1412.96 1311.41 1413.66 1311.92 1413.17 1312.28C1412.74 1312.58 1412.77 1313.42 1412.08 1313.27C1411.66 1313.18 1411.54 1311.6 1411.9 1311.16C1412.09 1310.93 1412.38 1310.89 1412.69 1311.05L1412.68 1311.04Z" fill="white"/>
                    <path d="M1468.7 1087.51C1468.7 1087.51 1469.22 1087.37 1469.7 1087.31C1469.91 1087.29 1470.31 1088.6 1470.17 1088.85C1469.8 1089.49 1469.33 1089.01 1468.91 1088.93C1468.4 1088.84 1468.38 1088.48 1468.7 1087.51Z" fill="white"/>
                    <path d="M1500.75 1102.8C1501.35 1103.2 1500.88 1103.65 1500.8 1104.07C1500.7 1104.6 1500.24 1104.66 1499.95 1104.41C1499.61 1104.12 1499.38 1103.66 1499.19 1103.24C1499.12 1103.09 1500.52 1102.67 1500.75 1102.8Z" fill="white"/>
                    <path d="M1560.91 1276.12C1560.85 1276.65 1560.56 1276.77 1560.28 1276.69C1559.55 1276.45 1559.3 1275.86 1559.36 1275.14C1559.39 1274.77 1559.69 1274.64 1559.97 1274.73C1560.74 1274.95 1560.91 1275.57 1560.91 1276.13V1276.12Z" fill="white"/>
                    <path d="M1385.59 1272.15C1385.87 1271.57 1386.4 1271.24 1386.89 1271.4C1387.13 1271.48 1387.54 1272.09 1387.35 1272.65C1387.09 1273.4 1386.6 1272.99 1386.18 1272.9C1385.79 1272.81 1385.45 1272.64 1385.6 1272.15H1385.59Z" fill="white"/>
                    <path d="M1571.83 1177.66C1571.78 1177.94 1570.66 1178.8 1570.57 1178.65C1570.33 1178.25 1570.09 1177.8 1570.06 1177.35C1570.03 1176.94 1570.37 1176.66 1570.84 1176.87C1571.23 1177.05 1571.88 1176.92 1571.83 1177.66Z" fill="white"/>
                    <path d="M1379.45 1272.07C1379.91 1272.39 1380.32 1272.89 1380.06 1273.65C1379.96 1273.93 1379.69 1274.1 1379.37 1273.91C1378.75 1273.53 1378.4 1273 1378.63 1272.26C1378.71 1271.98 1378.99 1271.82 1379.45 1272.07Z" fill="white"/>
                    <path d="M1345.65 1330.34C1346.19 1330.48 1346.26 1330.91 1346.01 1331.21C1345.72 1331.55 1345.26 1331.78 1344.84 1331.97C1344.69 1332.04 1344.27 1330.64 1344.4 1330.41C1344.58 1330.08 1345.07 1330.05 1345.66 1330.35L1345.65 1330.34Z" fill="white"/>
                    <path d="M1350.11 1171.61C1350.51 1171.75 1350.9 1171.87 1350.69 1172.4C1350.46 1172.97 1349.91 1173.28 1349.42 1173.13C1349.18 1173.05 1348.77 1172.44 1348.96 1171.88C1349.22 1171.13 1349.71 1171.56 1350.11 1171.62V1171.61Z" fill="white"/>
                    <path d="M1298.06 1251.43C1298.46 1251.83 1298.78 1252.24 1298.67 1252.84C1298.6 1253.2 1298.31 1253.35 1298.05 1253.22C1297.61 1253.01 1296.74 1253.22 1296.95 1252.27C1297.08 1251.69 1297.71 1251.75 1298.06 1251.42V1251.43Z" fill="white"/>
                    <path d="M1356.73 1183.83C1356.84 1183.98 1356.94 1184.12 1357.05 1184.27C1356.29 1184.69 1355.44 1184.98 1354.97 1185.8C1354.95 1185.84 1354.58 1185.73 1354.43 1185.61C1354.35 1185.54 1354.34 1185.2 1354.38 1185.18C1355.12 1184.89 1355.3 1183.88 1356.16 1183.76C1356.34 1183.73 1356.54 1183.8 1356.72 1183.82L1356.73 1183.83Z" fill="white"/>
                    <path d="M1356.34 1188.51C1356.13 1189.06 1355.49 1188.52 1355.07 1188.34C1354.23 1187.98 1354.92 1187.38 1355 1186.89C1355.02 1186.75 1355.49 1186.33 1355.49 1186.85C1355.49 1187.62 1356.93 1187.16 1356.34 1188.51Z" fill="white"/>
                    <path d="M1537.3 1234.81C1537.41 1234.09 1538.12 1234.1 1538.55 1233.78C1538.73 1234.11 1539.13 1234.51 1539.05 1234.74C1538.87 1235.26 1538.34 1235.47 1537.76 1235.4C1537.41 1235.36 1537.32 1235.11 1537.3 1234.81Z" fill="white"/>
                    <path d="M1462.56 1090.9C1462.37 1091.28 1462.44 1091.93 1461.66 1091.71C1461.1 1091.55 1461.18 1091.18 1461.33 1090.8C1461.49 1090.39 1461.45 1089.76 1462.22 1089.98C1462.78 1090.14 1462.67 1090.51 1462.56 1090.91V1090.9Z" fill="white"/>
                    <path d="M1511.69 1310.79C1512.31 1311.56 1512.3 1312.2 1511.97 1312.84C1511.89 1312.99 1511.7 1313.08 1511.55 1313.19C1511.46 1313.02 1511.4 1312.82 1511.28 1312.68C1510.8 1312.13 1511.09 1311.58 1511.3 1311.06C1511.36 1310.9 1511.64 1310.82 1511.69 1310.79Z" fill="white"/>
                    <path d="M1476.71 1063.52C1476.85 1063.56 1476.81 1063.94 1476.66 1064.32C1476.5 1064.72 1476.56 1065.37 1475.78 1065.16C1475.23 1065.01 1475.28 1064.64 1475.43 1064.26C1475.59 1063.85 1475.56 1063.21 1476.72 1063.52H1476.71Z" fill="white"/>
                    <path d="M1431.04 1108.21C1430.94 1108.3 1430.8 1108.5 1430.71 1108.48C1430.05 1108.34 1429.41 1108.18 1429.16 1107.39C1429.06 1107.06 1429.04 1106.87 1429.38 1106.83C1429.91 1106.77 1431.05 1107.73 1431.04 1108.22V1108.21Z" fill="white"/>
                    <path d="M1515.04 1189.61C1515.85 1190.12 1515.06 1190.65 1515 1191.15C1514.97 1191.38 1514.49 1191.4 1514.24 1191.23C1513.49 1190.73 1514.16 1190.18 1514.27 1189.69C1514.32 1189.46 1514.75 1189.44 1515.04 1189.61Z" fill="white"/>
                    <path d="M1399.83 1169.63C1400.64 1170.14 1399.85 1170.67 1399.79 1171.17C1399.76 1171.4 1399.28 1171.42 1399.03 1171.25C1398.28 1170.75 1398.95 1170.2 1399.06 1169.71C1399.11 1169.48 1399.54 1169.46 1399.83 1169.63Z" fill="white"/>
                    <path d="M1436.26 1299.47C1436.4 1299.51 1436.36 1299.89 1436.21 1300.27C1436.05 1300.67 1436.11 1301.32 1435.33 1301.11C1434.78 1300.96 1434.83 1300.59 1434.98 1300.21C1435.14 1299.8 1435.11 1299.16 1436.27 1299.47H1436.26Z" fill="white"/>
                    <path d="M1328.56 1134.62C1327.94 1134.74 1327.31 1134.87 1326.67 1134.97C1326.64 1134.97 1326.54 1134.8 1326.55 1134.72C1326.59 1134.3 1326.83 1134.05 1327.22 1133.87C1327.66 1133.67 1328.08 1133.4 1328.49 1133.87C1328.59 1133.98 1328.53 1134.22 1328.57 1134.62H1328.56Z" fill="white"/>
                    <path d="M1331.21 1190C1331.3 1189.61 1331.38 1189.18 1331.92 1189.28C1332.62 1189.41 1332.55 1189.86 1332.36 1190.37C1332.22 1190.73 1332.18 1191.18 1331.64 1191.07C1330.95 1190.94 1330.97 1190.49 1331.21 1189.99V1190Z" fill="white"/>
                    <path d="M1347.98 1248.71C1348.44 1249.03 1348.63 1249.23 1348.44 1249.55C1348.06 1250.17 1347.53 1250.5 1346.79 1250.29C1346.65 1250.25 1346.56 1250.02 1346.44 1249.88C1346.97 1249.47 1347.51 1249.07 1347.98 1248.71Z" fill="white"/>
                    <path d="M1394.67 1138.27C1395.2 1138.36 1395.56 1138.59 1395.47 1138.82C1395.28 1139.29 1395.51 1140.12 1394.61 1140.1C1394.3 1140.1 1393.95 1139.84 1394.03 1139.58C1394.17 1139.09 1394.1 1138.37 1394.67 1138.26V1138.27Z" fill="white"/>
                    <path d="M1456.08 1296.9C1456.47 1297.04 1456.83 1297.16 1456.64 1297.71C1456.38 1298.47 1455.88 1298.08 1455.45 1297.97C1455.06 1297.87 1454.71 1297.72 1454.9 1297.17C1455.16 1296.41 1455.66 1296.83 1456.08 1296.9Z" fill="white"/>
                    <path d="M1483.78 1209.94C1484.29 1209.13 1484.82 1209.92 1485.32 1209.98C1485.55 1210.01 1485.57 1210.49 1485.4 1210.74C1484.9 1211.49 1484.35 1210.82 1483.86 1210.71C1483.63 1210.66 1483.61 1210.23 1483.78 1209.94Z" fill="white"/>
                    <path d="M1337.06 1247.55C1337.15 1247.16 1337.23 1246.73 1337.77 1246.83C1338.47 1246.96 1338.4 1247.41 1338.21 1247.92C1338.07 1248.28 1338.03 1248.73 1337.49 1248.62C1336.8 1248.49 1336.82 1248.04 1337.06 1247.54V1247.55Z" fill="white"/>
                    <path d="M1373.69 1083.8C1373.84 1083.12 1374.08 1083.02 1374.39 1083.09C1375.09 1083.24 1375 1083.69 1374.79 1084.18C1374.68 1084.43 1374.58 1084.98 1374.39 1084.83C1374.01 1084.53 1373.81 1084 1373.68 1083.8H1373.69Z" fill="white"/>
                    <path d="M1493.94 1293.67C1494.06 1293.78 1494.26 1293.96 1494.45 1294.15C1493.96 1294.38 1493.54 1294.92 1492.91 1294.62C1492.64 1294.49 1492.51 1294.27 1492.64 1293.92C1492.9 1293.18 1493.38 1293.56 1493.94 1293.67Z" fill="white"/>
                    <path d="M1278.16 1259.71C1278.13 1259.98 1277.86 1260.1 1277.57 1260.05C1277.18 1259.98 1276.78 1259.79 1277.03 1259.28C1277.2 1258.92 1277.04 1258.16 1277.71 1258.35C1277.95 1258.42 1278.35 1259.06 1278.17 1259.71H1278.16Z" fill="white"/>
                    <path d="M1328.91 1080.72C1329.6 1080.85 1329.6 1081.55 1329.91 1082C1329.64 1082.02 1329.35 1082.11 1329.11 1082.03C1328.7 1081.91 1328.07 1081.93 1328.28 1081.16C1328.37 1080.81 1328.59 1080.72 1328.92 1080.71L1328.91 1080.72Z" fill="white"/>
                    <path d="M1311.37 1266.65C1311.34 1267.06 1311.48 1266.65 1311.11 1266.77C1310.91 1266.83 1310.49 1266.7 1310.4 1266.53C1310.14 1266.06 1309.63 1265.6 1310.04 1264.97C1310.09 1264.9 1310.24 1264.81 1310.28 1264.84C1310.93 1265.27 1310.98 1266.08 1311.38 1266.65H1311.37Z" fill="white"/>
                    <path d="M1363.82 1246.31C1364.04 1246.82 1364.57 1247.3 1364.13 1247.89C1364.02 1248.03 1363.56 1248.05 1363.36 1247.94C1362.72 1247.6 1363.22 1247.11 1363.29 1246.68C1363.34 1246.39 1363.48 1246.19 1363.82 1246.31Z" fill="white"/>
                    <path d="M1440.51 1187.55C1440.7 1187.61 1440.92 1187.63 1441.06 1187.74C1441.16 1187.81 1441.23 1188.09 1441.17 1188.16C1440.96 1188.41 1439.19 1187.88 1439.09 1187.53C1439.07 1187.45 1439.3 1187.19 1439.37 1187.21C1439.75 1187.29 1440.13 1187.43 1440.5 1187.55H1440.51Z" fill="white"/>
                    <path d="M1386.82 1342.1C1387.42 1342.25 1387.4 1342.63 1387.23 1343.01C1387.11 1343.29 1386.88 1343.4 1386.53 1343.29C1386 1343.12 1386.01 1342.77 1386.19 1342.39C1386.31 1342.13 1386.54 1341.91 1386.82 1342.1Z" fill="white"/>
                    <path d="M1348.4 1078.02C1348.67 1078.2 1348.8 1078.39 1348.69 1078.74C1348.52 1079.27 1348.17 1079.25 1347.79 1079.08C1347.51 1078.96 1347.4 1078.73 1347.51 1078.38C1347.68 1077.85 1348.02 1077.9 1348.4 1078.02Z" fill="white"/>
                    <path d="M1547.62 1174.58C1547.89 1174.76 1548.02 1174.95 1547.91 1175.3C1547.74 1175.83 1547.39 1175.81 1547.01 1175.64C1546.73 1175.52 1546.62 1175.29 1546.73 1174.94C1546.9 1174.41 1547.24 1174.46 1547.62 1174.58Z" fill="white"/>
                    <path d="M1524.64 1286.84C1524.52 1287.19 1524.37 1287.5 1523.84 1287.34C1523.48 1287.23 1523.37 1287.01 1523.43 1286.71C1523.51 1286.3 1523.7 1286.01 1524.23 1286.17C1524.58 1286.28 1524.67 1286.49 1524.65 1286.84H1524.64Z" fill="white"/>
                    <path d="M1454.77 1067.78C1455.14 1067.37 1455.56 1067.07 1456.14 1067.17C1456.29 1067.2 1456.55 1067.47 1456.54 1067.48C1456.22 1067.95 1455.79 1068.23 1455.21 1068.11C1455.06 1068.08 1454.93 1067.9 1454.77 1067.77V1067.78Z" fill="white"/>
                    <path d="M1538.16 1136.15C1538.52 1136.19 1538.61 1136.47 1538.56 1136.77C1538.48 1137.18 1538.29 1137.47 1537.76 1137.31C1537.4 1137.2 1537.29 1136.98 1537.35 1136.68C1537.43 1136.27 1537.63 1135.95 1538.17 1136.16L1538.16 1136.15Z" fill="white"/>
                    <path d="M1418.8 1125.61C1419.07 1125.79 1419.2 1125.98 1419.09 1126.33C1418.92 1126.86 1418.57 1126.84 1418.19 1126.67C1417.91 1126.55 1417.8 1126.32 1417.91 1125.97C1418.08 1125.44 1418.42 1125.49 1418.8 1125.61Z" fill="white"/>
                    <path d="M1327.89 1310.78C1328.2 1310.99 1328.34 1311.18 1328.22 1311.53C1328.05 1312.06 1327.7 1312.04 1327.32 1311.87C1327.04 1311.75 1326.93 1311.52 1327.04 1311.17C1327.21 1310.62 1327.56 1310.73 1327.89 1310.78Z" fill="white"/>
                    <path d="M1330.51 1286.27C1330.37 1286.83 1329.99 1286.81 1329.61 1286.64C1329.33 1286.52 1329.22 1286.29 1329.33 1285.94C1329.5 1285.41 1329.85 1285.43 1330.23 1285.6C1330.51 1285.72 1330.68 1285.96 1330.51 1286.28V1286.27Z" fill="white"/>
                    <path d="M1493.45 1051.75C1493.08 1051.62 1492.7 1051.49 1492.92 1050.97C1492.99 1050.81 1493.13 1050.68 1493.24 1050.54C1493.49 1050.89 1493.73 1051.25 1493.98 1051.6C1493.81 1051.65 1493.63 1051.7 1493.45 1051.75Z" fill="white"/>
                    <path d="M1496.87 1106C1497.24 1106.13 1497.62 1106.26 1497.4 1106.78C1497.33 1106.94 1497.19 1107.07 1497.08 1107.21C1496.83 1106.86 1496.59 1106.5 1496.34 1106.15C1496.51 1106.1 1496.69 1106.05 1496.87 1106Z" fill="white"/>
                    <path d="M1357.88 1098.85C1358.08 1099.21 1358.26 1099.54 1358.44 1099.88C1358.17 1099.9 1357.87 1100.01 1357.65 1099.92C1357.16 1099.72 1357.32 1099.31 1357.49 1098.97C1357.54 1098.88 1357.77 1098.88 1357.88 1098.85Z" fill="white"/>
                    <path d="M1481.22 1218.08C1481.08 1218.26 1480.93 1218.54 1480.86 1218.52C1480.35 1218.38 1480.4 1217.81 1480.13 1217.48C1480.3 1217.43 1480.49 1217.32 1480.65 1217.35C1481.06 1217.42 1481.35 1217.63 1481.22 1218.08Z" fill="white"/>
                    <path d="M1560.25 1271.07C1560.34 1271.19 1560.53 1271.32 1560.51 1271.43C1560.46 1271.81 1560.34 1272.24 1559.83 1272.11C1559.6 1272.05 1559.41 1271.79 1559.21 1271.62C1559.55 1271.44 1559.9 1271.26 1560.25 1271.08V1271.07Z" fill="white"/>
                    <path d="M1543.08 1246.54C1543.22 1246.01 1543.6 1246.07 1543.97 1246.23C1544.12 1246.29 1544.2 1246.5 1544.32 1246.64C1543.92 1246.79 1543.51 1246.93 1543.11 1247.08C1543.11 1246.9 1543.09 1246.72 1543.09 1246.54H1543.08Z" fill="white"/>
                    <path d="M1375.99 1115.36C1376.04 1115.42 1376.24 1115.55 1376.23 1115.65C1376.18 1116.03 1376.07 1116.46 1375.56 1116.35C1375.33 1116.3 1375.14 1116.03 1374.93 1115.87C1375.28 1115.71 1375.62 1115.55 1376 1115.37L1375.99 1115.36Z" fill="white"/>
                    <path d="M1412.65 1128.73C1413.18 1128.87 1413.12 1129.25 1412.96 1129.62C1412.9 1129.77 1412.69 1129.85 1412.55 1129.97C1412.4 1129.57 1412.26 1129.16 1412.11 1128.76C1412.29 1128.76 1412.47 1128.74 1412.65 1128.74V1128.73Z" fill="white"/>
                    <path d="M1568.1 1203.85C1568.22 1203.99 1568.34 1204.13 1568.46 1204.26C1568.07 1204.43 1567.67 1204.59 1567.28 1204.76C1567.26 1204.58 1567.21 1204.4 1567.24 1204.23C1567.34 1203.7 1567.71 1203.73 1568.1 1203.85Z" fill="white"/>
                    <path d="M1349.76 1285.4C1349.9 1284.87 1350.28 1284.93 1350.65 1285.09C1350.8 1285.15 1350.88 1285.36 1351 1285.5C1350.6 1285.65 1350.19 1285.79 1349.79 1285.94C1349.79 1285.76 1349.77 1285.58 1349.77 1285.4H1349.76Z" fill="white"/>
                    <path d="M1339.55 1325.57C1339.79 1325.51 1339.98 1325.41 1340.14 1325.44C1340.55 1325.52 1340.85 1325.74 1340.64 1326.23C1340.57 1326.39 1340.42 1326.51 1340.3 1326.64C1340.05 1326.29 1339.81 1325.94 1339.55 1325.57Z" fill="white"/>
                    <path d="M1296.57 1106.66C1296.85 1106.96 1296.62 1107.23 1296.53 1107.48C1296.5 1107.56 1296.18 1107.63 1296.11 1107.57C1295.83 1107.32 1296.06 1107.03 1296.16 1106.78C1296.19 1106.7 1296.44 1106.7 1296.57 1106.66Z" fill="white"/>
                    <path d="M1322.13 1100.36C1322.46 1100.36 1322.74 1100.32 1322.99 1100.39C1323.13 1100.43 1323.23 1100.66 1323.34 1100.8C1323.07 1100.82 1322.79 1100.89 1322.54 1100.84C1322.4 1100.81 1322.31 1100.58 1322.13 1100.36Z" fill="white"/>
                    <path d="M1446.47 1335.1C1446.75 1335.4 1446.52 1335.67 1446.43 1335.92C1446.4 1336 1446.08 1336.07 1446.01 1336.01C1445.73 1335.76 1445.96 1335.47 1446.06 1335.22C1446.09 1335.14 1446.34 1335.14 1446.47 1335.1Z" fill="white"/>
                    <path d="M1541.4 1275.07C1541.4 1275.4 1541.44 1275.68 1541.37 1275.93C1541.33 1276.07 1541.1 1276.17 1540.96 1276.28C1540.94 1276.01 1540.87 1275.73 1540.92 1275.48C1540.95 1275.34 1541.18 1275.25 1541.4 1275.07Z" fill="white"/>
                    <path d="M1541.99 1271.88C1541.93 1272.03 1541.87 1272.3 1541.72 1272.51C1541.55 1272.76 1541.34 1272.69 1541.35 1272.39C1541.36 1272.13 1541.45 1271.85 1541.59 1271.64C1541.75 1271.39 1541.92 1271.45 1541.99 1271.88Z" fill="white"/>
                    <path d="M1459.89 1347.47C1460 1347.33 1460.14 1347.07 1460.23 1347.08C1460.59 1347.13 1460.58 1347.41 1460.45 1347.67C1460.37 1347.83 1460.21 1348.08 1460.11 1348.06C1459.76 1348.01 1459.77 1347.74 1459.88 1347.47H1459.89Z" fill="white"/>
                    <path d="M1352.2 1083.66C1352.34 1083.77 1352.6 1083.91 1352.59 1084C1352.54 1084.36 1352.26 1084.35 1352 1084.22C1351.84 1084.14 1351.59 1083.98 1351.61 1083.88C1351.66 1083.53 1351.93 1083.54 1352.2 1083.65V1083.66Z" fill="white"/>
                    <path d="M1560.08 1196.51C1560.41 1196.51 1560.69 1196.47 1560.94 1196.54C1561.08 1196.58 1561.18 1196.81 1561.29 1196.95C1561.02 1196.97 1560.74 1197.04 1560.49 1196.99C1560.35 1196.96 1560.26 1196.73 1560.08 1196.51Z" fill="white"/>
                    <path d="M1298.98 1236.48C1299.06 1236.58 1299.18 1236.72 1299.29 1236.87C1299.02 1236.89 1298.74 1236.96 1298.49 1236.91C1298.35 1236.88 1298.26 1236.65 1298.14 1236.5C1298.4 1236.33 1298.66 1236.29 1298.98 1236.48Z" fill="white"/>
                    <path d="M1571.77 1151.84C1571.71 1152.13 1571.56 1152.36 1571.25 1152.19C1571.16 1152.15 1571.18 1151.85 1571.2 1151.67C1571.24 1151.38 1571.4 1151.15 1571.72 1151.32C1571.81 1151.36 1571.76 1151.66 1571.78 1151.84H1571.77Z" fill="white"/>
                    <path d="M1337.57 1249.92C1337.51 1250.21 1337.36 1250.44 1337.05 1250.27C1336.96 1250.23 1336.98 1249.93 1337 1249.75C1337.04 1249.46 1337.2 1249.23 1337.52 1249.4C1337.61 1249.44 1337.56 1249.74 1337.58 1249.92H1337.57Z" fill="white"/>
                    <path d="M1348.93 1244.02C1348.87 1244.31 1348.72 1244.54 1348.41 1244.37C1348.32 1244.33 1348.34 1244.03 1348.36 1243.85C1348.4 1243.56 1348.56 1243.33 1348.88 1243.5C1348.97 1243.54 1348.92 1243.84 1348.94 1244.02H1348.93Z" fill="white"/>
                    <path d="M1387.28 1216.54C1387.61 1216.54 1387.89 1216.5 1388.14 1216.57C1388.28 1216.61 1388.38 1216.84 1388.49 1216.98C1388.22 1217 1387.94 1217.07 1387.69 1217.02C1387.55 1216.99 1387.46 1216.76 1387.28 1216.54Z" fill="white"/>
                    <path d="M1368.35 1108.34C1368.51 1108.5 1368.7 1108.69 1368.89 1108.88C1368.71 1108.93 1368.5 1109.07 1368.37 1109.01C1368.13 1108.91 1367.95 1108.69 1367.74 1108.52C1367.92 1108.47 1368.09 1108.42 1368.35 1108.35V1108.34Z" fill="white"/>
                    <path d="M1448.97 1160.5C1449.05 1160.6 1449.17 1160.74 1449.28 1160.89C1449.01 1160.91 1448.73 1160.98 1448.48 1160.93C1448.34 1160.9 1448.25 1160.67 1448.13 1160.52C1448.39 1160.35 1448.65 1160.31 1448.97 1160.5Z" fill="white"/>
                    <path d="M1470.14 1118.69C1470.47 1118.69 1470.75 1118.65 1471 1118.72C1471.14 1118.76 1471.24 1118.99 1471.35 1119.13C1471.08 1119.15 1470.8 1119.22 1470.55 1119.17C1470.41 1119.14 1470.32 1118.91 1470.14 1118.69Z" fill="white"/>
                    <path d="M1502.82 1052.91C1503.1 1053.21 1502.87 1053.48 1502.78 1053.73C1502.75 1053.81 1502.43 1053.88 1502.36 1053.82C1502.08 1053.57 1502.31 1053.28 1502.41 1053.03C1502.44 1052.95 1502.69 1052.95 1502.82 1052.91Z" fill="white"/>
                    <path d="M1512.85 1118.01C1512.79 1118.3 1512.64 1118.53 1512.33 1118.36C1512.24 1118.32 1512.26 1118.02 1512.28 1117.84C1512.32 1117.55 1512.48 1117.32 1512.8 1117.49C1512.89 1117.53 1512.84 1117.83 1512.86 1118.01H1512.85Z" fill="white"/>
                    <path d="M1440.34 1317.24C1440.45 1317.1 1440.59 1316.84 1440.68 1316.85C1441.04 1316.9 1441.03 1317.18 1440.9 1317.44C1440.82 1317.6 1440.66 1317.85 1440.56 1317.83C1440.21 1317.78 1440.22 1317.51 1440.33 1317.24H1440.34Z" fill="white"/>
                    <path d="M1454.69 1219.88C1454.83 1219.99 1455.09 1220.13 1455.08 1220.22C1455.03 1220.58 1454.75 1220.57 1454.49 1220.44C1454.33 1220.36 1454.08 1220.2 1454.1 1220.1C1454.15 1219.75 1454.42 1219.76 1454.69 1219.87V1219.88Z" fill="white"/>
                    <path d="M1469.91 1223.75C1470.06 1223.73 1470.21 1223.68 1470.35 1223.69C1470.4 1223.69 1470.44 1223.85 1470.48 1223.93C1470.33 1223.95 1470.18 1224 1470.04 1223.99C1469.99 1223.99 1469.95 1223.83 1469.91 1223.75Z" fill="white"/>
                    <path d="M1578.77 1203.75C1578.92 1203.73 1579.07 1203.68 1579.21 1203.69C1579.26 1203.69 1579.3 1203.85 1579.34 1203.93C1579.19 1203.95 1579.04 1204 1578.9 1203.99C1578.85 1203.99 1578.81 1203.83 1578.77 1203.75Z" fill="white"/>
                    <path d="M1355.62 1233.58C1355.72 1233.68 1355.82 1233.79 1355.93 1233.9C1355.85 1233.94 1355.73 1234.05 1355.68 1234.02C1355.55 1233.95 1355.46 1233.82 1355.35 1233.72C1355.43 1233.68 1355.52 1233.63 1355.62 1233.58Z" fill="white"/>
                    <path d="M1328.79 1272.38C1328.74 1272.28 1328.69 1272.19 1328.65 1272.11C1328.8 1272.09 1328.95 1272.04 1329.09 1272.05C1329.14 1272.05 1329.18 1272.21 1329.22 1272.29C1329.07 1272.32 1328.93 1272.35 1328.78 1272.38H1328.79Z" fill="white"/>
                    <path d="M1353.84 1188.21C1353.74 1188.31 1353.63 1188.41 1353.52 1188.52C1353.48 1188.44 1353.37 1188.32 1353.4 1188.27C1353.47 1188.14 1353.6 1188.05 1353.7 1187.94C1353.74 1188.02 1353.79 1188.11 1353.84 1188.21Z" fill="white"/>
                    <path d="M1396.14 1167.82C1396.16 1167.97 1396.21 1168.12 1396.2 1168.26C1396.2 1168.31 1396.04 1168.35 1395.96 1168.39C1395.94 1168.24 1395.89 1168.09 1395.9 1167.95C1395.9 1167.9 1396.06 1167.86 1396.14 1167.82Z" fill="white"/>
                    <path d="M1415.75 1122.53C1415.65 1122.58 1415.56 1122.63 1415.48 1122.67C1415.46 1122.52 1415.41 1122.37 1415.42 1122.23C1415.42 1122.18 1415.58 1122.14 1415.66 1122.1C1415.69 1122.25 1415.72 1122.39 1415.75 1122.54V1122.53Z" fill="white"/>
                    <path d="M1366.72 1086.44C1366.82 1086.54 1366.92 1086.65 1367.03 1086.76C1366.95 1086.8 1366.83 1086.91 1366.78 1086.88C1366.65 1086.81 1366.56 1086.68 1366.45 1086.58C1366.53 1086.54 1366.62 1086.49 1366.72 1086.44Z" fill="white"/>
                    <path d="M1346.02 1206.49C1346.07 1206.59 1346.12 1206.68 1346.16 1206.76C1346.01 1206.78 1345.86 1206.83 1345.72 1206.82C1345.67 1206.82 1345.63 1206.66 1345.59 1206.58C1345.74 1206.55 1345.88 1206.52 1346.03 1206.49H1346.02Z" fill="white"/>
                    <path d="M1419.7 1189.1C1419.65 1189 1419.6 1188.91 1419.56 1188.83C1419.71 1188.81 1419.86 1188.76 1420 1188.77C1420.05 1188.77 1420.09 1188.93 1420.13 1189.01C1419.98 1189.04 1419.84 1189.07 1419.69 1189.1H1419.7Z" fill="white"/>
                    <path d="M1474.99 1053.77C1475.08 1053.72 1475.21 1053.63 1475.23 1053.65C1475.33 1053.76 1475.46 1053.88 1475.48 1054.02C1475.52 1054.3 1475.37 1054.38 1475.16 1054.19C1475.06 1054.1 1475.04 1053.92 1474.99 1053.78V1053.77Z" fill="white"/>
                    <path d="M1475.67 1054.35C1475.72 1054.49 1475.86 1054.69 1475.81 1054.76C1475.67 1054.96 1475.48 1054.94 1475.44 1054.76C1475.4 1054.6 1475.42 1054.43 1475.41 1054.26C1475.5 1054.29 1475.58 1054.31 1475.67 1054.34V1054.35Z" fill="white"/>
                    <path d="M1475.8 1253.87C1475.9 1253.97 1476 1254.08 1476.11 1254.19C1476.03 1254.23 1475.91 1254.34 1475.86 1254.31C1475.73 1254.24 1475.64 1254.11 1475.53 1254.01C1475.61 1253.97 1475.7 1253.92 1475.8 1253.87Z" fill="white"/>
                    <path d="M1391.58 1027.85C1391.68 1027.95 1391.78 1028.06 1391.89 1028.17C1391.81 1028.21 1391.69 1028.32 1391.64 1028.29C1391.51 1028.22 1391.42 1028.09 1391.31 1027.99C1391.39 1027.95 1391.48 1027.9 1391.58 1027.85Z" fill="white"/>
                    <path d="M1481.78 1049.42C1481.83 1049.52 1481.88 1049.61 1481.92 1049.69C1481.77 1049.71 1481.62 1049.76 1481.48 1049.75C1481.43 1049.75 1481.39 1049.59 1481.35 1049.51C1481.5 1049.48 1481.64 1049.45 1481.79 1049.42H1481.78Z" fill="white"/>
                    <path d="M1369.49 1066.25C1369.39 1066.15 1369.29 1066.04 1369.18 1065.93C1369.26 1065.89 1369.38 1065.78 1369.43 1065.81C1369.56 1065.88 1369.65 1066.01 1369.76 1066.11C1369.68 1066.15 1369.59 1066.2 1369.49 1066.25Z" fill="white"/>
                    <path d="M1488.02 1155.19C1488.12 1155.29 1488.22 1155.4 1488.33 1155.51C1488.25 1155.55 1488.13 1155.66 1488.08 1155.63C1487.95 1155.56 1487.86 1155.43 1487.75 1155.33C1487.83 1155.29 1487.92 1155.24 1488.02 1155.19Z" fill="white"/>
                    <path d="M1347.95 1156.78C1347.85 1156.83 1347.76 1156.88 1347.68 1156.92C1347.66 1156.77 1347.61 1156.62 1347.62 1156.48C1347.62 1156.43 1347.78 1156.39 1347.86 1156.35C1347.89 1156.5 1347.92 1156.64 1347.95 1156.79V1156.78Z" fill="white"/>
                    <path d="M1484.18 1084.58C1484.33 1084.56 1484.48 1084.51 1484.62 1084.52C1484.67 1084.52 1484.71 1084.68 1484.75 1084.76C1484.6 1084.78 1484.45 1084.83 1484.31 1084.82C1484.26 1084.82 1484.22 1084.66 1484.18 1084.58Z" fill="white"/>
                    <path d="M1349.28 1143.59C1349.38 1143.69 1349.48 1143.8 1349.59 1143.91C1349.51 1143.95 1349.39 1144.06 1349.34 1144.03C1349.21 1143.96 1349.12 1143.83 1349.01 1143.73L1349.28 1143.59Z" fill="white"/>
                    <path d="M1446.86 1210.85C1446.96 1210.75 1447.07 1210.65 1447.18 1210.54C1447.22 1210.62 1447.33 1210.74 1447.3 1210.79C1447.23 1210.92 1447.1 1211.01 1447 1211.12C1446.96 1211.04 1446.91 1210.95 1446.86 1210.85Z" fill="white"/>
                    <path d="M1468.61 1349.92C1468.76 1349.9 1468.91 1349.85 1469.05 1349.86C1469.1 1349.86 1469.14 1350.02 1469.18 1350.1C1469.03 1350.12 1468.88 1350.17 1468.74 1350.16C1468.69 1350.16 1468.65 1350 1468.61 1349.92Z" fill="white"/>
                    <path d="M1193.77 1260.26C1192.68 1258.85 1193.31 1257.62 1193.93 1256.47C1194.16 1256.05 1194.6 1255.45 1194.64 1255.19C1194.87 1253.53 1196.54 1254.62 1197.11 1253.77C1197.85 1254.45 1198.96 1255.02 1199.22 1255.86C1199.46 1256.65 1199.68 1257.67 1199.54 1258.58C1199.4 1259.45 1199.6 1260.72 1197.95 1260.35C1197.71 1260.3 1197.37 1260.73 1197.05 1260.91C1195.65 1261.69 1194.54 1261.44 1193.78 1260.27L1193.77 1260.26Z" fill="white"/>
                    <path d="M1336.51 1277.25C1335.62 1277.82 1334.54 1277.52 1334.35 1277.21C1333.6 1276 1332.19 1276.23 1331.22 1275.51C1330.54 1275 1330.21 1274.59 1330.42 1273.79C1330.78 1272.48 1331.23 1272.06 1332.22 1272.12C1332.65 1272.15 1333.06 1272.12 1333.48 1272.09C1335.33 1271.93 1337.9 1274.66 1336.97 1276.58C1336.8 1276.92 1336.68 1277.21 1336.51 1277.26V1277.25Z" fill="white"/>
                    <path d="M1277.95 1214.52C1277.05 1214.3 1276.04 1215.2 1275.26 1214.19C1275.15 1214.05 1275.09 1213.83 1274.95 1213.75C1274.37 1213.41 1273.61 1213.25 1273.53 1212.4C1273.47 1211.73 1273.96 1211.29 1274.26 1210.77C1274.85 1209.75 1276.46 1209.35 1277.64 1209.88C1278.56 1210.29 1278.97 1210.98 1279.05 1212C1279.13 1213.06 1278.36 1213.7 1277.95 1214.54V1214.52Z" fill="white"/>
                    <path d="M1340.23 1103.11C1341.55 1102.25 1342.47 1102.34 1343.21 1103.21C1343.62 1103.69 1343.9 1104.28 1344.28 1104.78C1344.64 1105.26 1344.52 1105.56 1344.03 1105.84C1343.48 1106.16 1343 1106.62 1342.43 1106.87C1341.46 1107.29 1340.48 1107.07 1339.61 1106.53C1339.43 1106.42 1339.25 1106.08 1339.29 1105.89C1339.44 1105.2 1339.35 1104.51 1339.36 1103.83C1339.36 1103.5 1340.14 1103.18 1340.23 1103.11Z" fill="white"/>
                    <path d="M1419.62 1192.01C1419.06 1192.45 1418.27 1193.1 1417.41 1192.34C1417 1191.98 1416.24 1191.3 1416.67 1190.78C1417.14 1190.21 1417.2 1189.18 1418.17 1189.08C1418.84 1189.01 1419.58 1189.18 1420.19 1188.96C1420.75 1188.76 1420.95 1188.85 1421.25 1189.29C1421.68 1189.92 1421.54 1190.51 1421.12 1190.98C1420.77 1191.37 1420.23 1191.6 1419.62 1192.01Z" fill="white"/>
                    <path d="M1330.72 1085.83C1330.13 1086.24 1329.31 1086.11 1328.88 1085.54C1328.74 1085.35 1328.56 1082.78 1328.68 1082.6C1328.94 1082.19 1330.61 1082.17 1330.58 1082.25C1330.13 1083.3 1331.51 1083.05 1331.55 1083.69C1331.59 1084.3 1331.11 1085.57 1330.73 1085.83H1330.72Z" fill="white"/>
                    <path d="M1219.66 1203.44C1219.86 1203.76 1220.26 1204.19 1219.96 1204.39C1219.41 1204.77 1218.97 1205.43 1218.09 1205.25C1217.6 1205.15 1217.39 1204.71 1216.99 1204.63C1216.35 1204.49 1216.09 1204.26 1216.2 1203.54C1216.31 1202.78 1216.82 1202.33 1217.41 1202.37C1218.25 1202.42 1219.27 1202.39 1219.66 1203.43V1203.44Z" fill="white"/>
                    <path d="M1311.22 1227.16C1311.72 1226.87 1312.17 1226.22 1312.74 1227.16C1312.87 1227.37 1313.42 1227.31 1313.77 1227.4C1314.17 1227.5 1314.55 1227.64 1314.94 1227.77C1314.47 1228.15 1314.03 1228.78 1313.51 1228.86C1312.84 1228.97 1312.11 1228.61 1311.4 1228.5C1311.01 1228.44 1310.66 1228.31 1310.59 1227.93C1310.51 1227.51 1310.94 1227.37 1311.22 1227.17V1227.16Z" fill="white"/>
                    <path d="M1223.33 1111.14C1223.48 1111.04 1223.62 1110.93 1223.78 1110.85C1224.14 1110.68 1224.44 1110.12 1224.91 1110.56C1225.38 1111.01 1225.53 1111.57 1225.3 1112.24C1225.11 1112.8 1224.76 1112.79 1224.28 1112.73C1223.97 1112.69 1223.63 1112.92 1223.32 1112.89C1222.91 1112.85 1222.39 1112.89 1222.2 1112.37C1221.98 1111.76 1222.6 1111.7 1222.89 1111.44C1223.02 1111.32 1223.18 1111.24 1223.33 1111.14Z" fill="white"/>
                    <path d="M1340.61 1086.43C1340.55 1085.6 1340.23 1084.75 1340.58 1083.92C1340.66 1083.73 1340.94 1083.53 1341.26 1083.64C1341.66 1083.77 1342.27 1083.66 1342.37 1084.19C1342.49 1084.84 1342.48 1085.54 1342.45 1086.21C1342.41 1087.01 1341.77 1086.3 1341.49 1086.55C1341.19 1086.81 1340.86 1086.87 1340.61 1086.43Z" fill="white"/>
                    <path d="M1275.79 1253.92C1276.16 1253.58 1276.5 1253.55 1276.8 1254.07C1277.28 1254.88 1277.16 1255.59 1276.48 1256.08C1275.88 1256.52 1275.28 1256.36 1274.82 1255.98C1274.28 1255.55 1274.77 1254.55 1275.79 1253.91V1253.92Z" fill="white"/>
                    <path d="M1275.11 1335.61C1275.34 1335.46 1275.54 1335.26 1275.79 1335.17C1276.37 1334.95 1276.94 1335.45 1276.82 1336.05C1276.75 1336.4 1276.74 1336.79 1276.59 1337.11C1276.4 1337.52 1275.96 1337.84 1275.55 1337.8C1275.08 1337.75 1274.8 1337.12 1274.23 1337.13C1274.4 1336.65 1274.23 1336.01 1274.88 1335.75C1274.96 1335.72 1275.03 1335.65 1275.11 1335.6V1335.61Z" fill="white"/>
                    <path d="M1302.91 1119.99C1302.6 1119.93 1302.34 1119.86 1302.07 1119.82C1301.4 1119.73 1301.48 1118.72 1300.69 1118.73C1300.27 1118.73 1300.05 1118.39 1300.17 1118C1300.29 1117.63 1300.62 1117.53 1301.02 1117.63C1301.54 1117.75 1302.08 1117.82 1302.59 1117.97C1302.83 1118.04 1303.36 1117.83 1303.21 1118.46C1303.09 1118.97 1303 1119.48 1302.9 1119.99H1302.91Z" fill="white"/>
                    <path d="M1415.26 1124.93C1415.5 1125.45 1415.05 1126.39 1414.44 1126.64C1414.2 1126.74 1412.65 1126.08 1412.56 1125.77C1412.31 1125 1413.07 1124.95 1413.45 1124.71C1414.15 1124.26 1415.03 1124.42 1415.27 1124.93H1415.26Z" fill="white"/>
                    <path d="M1384.45 1194.07C1384.08 1193.65 1383.99 1193.31 1384.52 1192.99C1385.17 1192.6 1386.09 1192.62 1386.39 1193.08C1386.69 1193.53 1386.35 1194.41 1385.72 1194.83C1384.77 1195.45 1384.82 1194.36 1384.45 1194.08V1194.07Z" fill="white"/>
                    <path d="M1384.74 1200.71C1384.24 1200.73 1383.7 1200.78 1383.35 1200.32C1382.97 1199.83 1383.52 1198.71 1384.21 1198.44C1384.67 1198.26 1384.83 1198.58 1385 1198.88C1385.38 1199.59 1385.26 1200.46 1384.74 1200.71Z" fill="white"/>
                    <path d="M1331.44 1136.45C1331.74 1136.25 1331.9 1136.17 1332.03 1136.06C1332.53 1135.63 1333.03 1136.03 1333.53 1136.05C1333.36 1136.52 1333.53 1137.14 1332.93 1137.43C1332.77 1137.51 1332.62 1137.61 1332.49 1137.72C1332.02 1138.09 1331.66 1138.24 1331.23 1137.57C1330.82 1136.93 1330.98 1136.62 1331.44 1136.45Z" fill="white"/>
                    <path d="M1403.65 1275.44C1403.26 1274.93 1403.54 1274.59 1403.88 1274.54C1404.55 1274.43 1405.24 1274.44 1405.92 1274.46C1406.55 1274.48 1406.2 1274.92 1406.18 1275.2C1406.12 1276.4 1405.07 1275.67 1404.54 1276.01C1403.93 1276.4 1403.91 1275.6 1403.65 1275.45V1275.44Z" fill="white"/>
                    <path d="M1357.41 1156.58C1356.87 1157.11 1356.25 1156.95 1355.78 1156.59C1355.42 1156.32 1355.76 1154.85 1356.13 1154.82C1356.85 1154.76 1357.38 1155.08 1357.74 1155.74C1357.99 1156.2 1357.71 1156.37 1357.42 1156.58H1357.41Z" fill="white"/>
                    <path d="M1191.68 1164.19C1191.47 1163.7 1191.65 1163.22 1191.91 1162.78C1192.06 1162.52 1193.47 1163.03 1193.82 1163.47C1194.14 1163.87 1193.87 1164.1 1193.56 1164.29C1192.93 1164.68 1192 1164.65 1191.68 1164.19Z" fill="white"/>
                    <path d="M1357.14 1118.29C1357.22 1118.56 1357.21 1118.88 1356.89 1118.91C1356.13 1118.99 1355.39 1119.25 1354.6 1119.05C1354.27 1118.97 1354.08 1118.73 1354.07 1118.52C1354.06 1118.33 1354.31 1117.95 1354.48 1117.93C1355.15 1117.84 1355.84 1117.85 1356.52 1117.84C1356.81 1117.84 1357.02 1118 1357.15 1118.27L1357.14 1118.29Z" fill="white"/>
                    <path d="M1372.89 1093.26C1372.66 1093.44 1372.39 1093.67 1372.04 1093.33C1371.75 1093.03 1371.92 1092.69 1371.93 1092.35C1371.95 1091.66 1372.52 1091.49 1372.96 1091.29C1373.22 1091.17 1373.87 1091.31 1373.93 1091.47C1374.05 1091.81 1373.98 1092.29 1373.8 1092.62C1373.65 1092.89 1373.24 1093.03 1372.89 1093.26Z" fill="white"/>
                    <path d="M1426.8 1232.27C1427.11 1232.6 1427.13 1232.94 1426.61 1233.24C1425.96 1233.62 1425.06 1233.59 1424.75 1233.14C1424.23 1232.39 1425.52 1232.28 1425.31 1231.58C1425.27 1231.46 1426.37 1231.71 1426.8 1232.26V1232.27Z" fill="white"/>
                    <path d="M1280.82 1147.73C1280.66 1147.72 1280.43 1147.77 1280.34 1147.68C1279.82 1147.2 1279.96 1145.63 1280.55 1145.3C1280.75 1145.19 1281.09 1145.08 1281.24 1145.17C1281.4 1145.27 1281.51 1145.61 1281.5 1145.84C1281.44 1146.84 1281.19 1147.47 1280.82 1147.73Z" fill="white"/>
                    <path d="M1224.31 1232.21C1224.46 1232.43 1224.65 1232.64 1224.75 1232.88C1225 1233.51 1224.37 1233.56 1224.08 1233.81C1223.69 1234.14 1223.45 1233.87 1223.25 1233.55C1223.07 1233.26 1222.82 1233.01 1222.94 1232.6C1223.06 1232.18 1223.04 1231.66 1223.58 1231.56C1224 1231.48 1224.13 1231.92 1224.33 1232.2L1224.31 1232.21Z" fill="white"/>
                    <path d="M1355.74 1120.99C1356.26 1120.78 1356.43 1121.12 1356.59 1121.42C1356.97 1122.14 1356.84 1123 1356.31 1123.23C1355.87 1123.43 1355.69 1123.08 1355.53 1122.77C1355.15 1122.05 1355.29 1121.21 1355.74 1120.98V1120.99Z" fill="white"/>
                    <path d="M1433.4 1228.38C1433.25 1228.99 1433.26 1229.6 1432.58 1229.95C1432.02 1230.24 1431.83 1229.99 1431.55 1229.6C1431.25 1229.17 1431.21 1228.92 1431.67 1228.53C1432.27 1228.02 1432.81 1228.36 1433.4 1228.38Z" fill="white"/>
                    <path d="M1424.33 1238.68C1424.16 1238.68 1423.92 1238.75 1423.85 1238.67C1423.62 1238.41 1423.29 1238.09 1423.29 1237.8C1423.29 1237.32 1423.23 1236.71 1423.8 1236.42C1424.24 1236.2 1424.43 1236.55 1424.59 1236.86C1424.97 1237.59 1424.85 1238.39 1424.34 1238.68H1424.33Z" fill="white"/>
                    <path d="M1195.79 1208.68C1195.47 1208.88 1195.13 1209.08 1194.92 1208.62C1194.65 1208.02 1194.72 1207.37 1195.32 1207.07C1195.64 1206.91 1196.15 1207.13 1196.57 1207.18C1196.43 1207.75 1196.5 1208.43 1195.79 1208.69V1208.68Z" fill="white"/>
                    <path d="M1305.3 1302.04C1304.98 1301.47 1305.23 1300.5 1305.65 1300.31C1306.09 1300.12 1306.23 1300.48 1306.44 1300.75C1306.85 1301.3 1306.79 1301.88 1306.35 1302.29C1306.22 1302.41 1305.5 1302.34 1305.3 1302.04Z" fill="white"/>
                    <path d="M1374.12 1167.13C1373.74 1167.38 1373.33 1167.62 1373.19 1167.16C1373.02 1166.58 1373.06 1165.94 1373.01 1165.33C1373.63 1165.41 1374.18 1165.53 1374.51 1166.19C1374.83 1166.82 1374.35 1166.93 1374.12 1167.13Z" fill="white"/>
                    <path d="M1374.04 1303.43C1373.47 1303.91 1373.23 1303.64 1372.91 1303.23C1372.41 1302.6 1372.74 1302.33 1373.25 1301.99C1373.77 1301.64 1373.99 1301.86 1374.3 1302.28C1374.76 1302.9 1374.57 1303.24 1374.04 1303.43Z" fill="white"/>
                    <path d="M1373.94 1155.51C1373.75 1155.07 1373.02 1154.62 1373.86 1154.23C1374.31 1154.01 1374.9 1154.11 1375.27 1154.55C1375.87 1155.28 1374.89 1155.61 1374.69 1156.04C1374.44 1156.58 1374.26 1155.64 1373.95 1155.51H1373.94Z" fill="white"/>
                    <path d="M1374.52 1075.14C1374.3 1075.29 1374.09 1075.47 1373.85 1075.57C1373.41 1075.76 1373.24 1075.41 1373.07 1075.11C1372.95 1074.89 1372.67 1074.72 1372.83 1074.41C1373.01 1074.07 1373.3 1073.94 1373.69 1074.05C1373.86 1074.1 1374.04 1074.12 1374.21 1074.16C1374.54 1074.24 1375.09 1074.25 1375.15 1074.42C1375.28 1074.81 1374.78 1074.92 1374.53 1075.14H1374.52Z" fill="white"/>
                    <path d="M1253.51 1299.01C1253.69 1299.63 1253.12 1299.71 1252.8 1299.94C1252.21 1300.36 1252.07 1299.76 1251.84 1299.45C1251.62 1299.16 1251.45 1298.83 1251.91 1298.59C1252.38 1298.34 1253.31 1298.59 1253.51 1299.02V1299.01Z" fill="white"/>
                    <path d="M1376.29 1224.3C1376.05 1223.89 1375.84 1223.58 1376.31 1223.33C1376.78 1223.08 1377.73 1223.33 1377.91 1223.76C1378.09 1224.2 1377.7 1224.33 1377.45 1224.55C1376.83 1225.1 1376.53 1224.71 1376.3 1224.29L1376.29 1224.3Z" fill="white"/>
                    <path d="M1378.01 1119.07C1378.07 1119.25 1378.27 1119.56 1378.19 1119.7C1377.96 1120.08 1377.56 1119.79 1377.33 1119.68C1376.99 1119.51 1376.11 1119.99 1376.38 1118.98C1376.47 1118.65 1376.47 1118.26 1376.86 1118.14C1377.65 1117.89 1377.54 1118.77 1378 1119.07H1378.01Z" fill="white"/>
                    <path d="M1237.6 1123.96C1237.18 1123.95 1236.42 1124.15 1236.45 1123.6C1236.48 1123.13 1235.87 1122.67 1236.37 1122.27C1236.67 1122.03 1237.85 1122.83 1237.96 1123.34C1238.02 1123.61 1237.88 1123.83 1237.59 1123.96H1237.6Z" fill="white"/>
                    <path d="M1363.15 1290.46C1363.15 1290.46 1362.96 1290.91 1362.72 1291.27C1362.62 1291.43 1361.46 1290.96 1361.37 1290.73C1361.13 1290.1 1361.74 1290.05 1362.03 1289.8C1362.39 1289.49 1362.65 1289.68 1363.15 1290.46Z" fill="white"/>
                    <path d="M1334.17 1304.33C1333.55 1304.52 1333.5 1303.94 1333.25 1303.64C1332.93 1303.26 1333.15 1302.91 1333.49 1302.84C1333.89 1302.76 1334.34 1302.87 1334.75 1302.97C1334.9 1303.01 1334.4 1304.23 1334.17 1304.32V1304.33Z" fill="white"/>
                    <path d="M1177.93 1248.18C1177.59 1247.84 1177.67 1247.56 1177.89 1247.42C1178.47 1247.04 1179.03 1247.2 1179.51 1247.65C1179.76 1247.88 1179.67 1248.16 1179.46 1248.31C1178.87 1248.73 1178.33 1248.49 1177.94 1248.18H1177.93Z" fill="white"/>
                    <path d="M1280.37 1126.99C1280.62 1127.51 1280.55 1128.08 1280.16 1128.33C1279.97 1128.46 1279.3 1128.39 1279.02 1127.94C1278.64 1127.33 1279.21 1127.21 1279.51 1126.97C1279.8 1126.74 1280.11 1126.6 1280.37 1126.98V1126.99Z" fill="white"/>
                    <path d="M1241.05 1311.83C1240.88 1311.64 1240.92 1310.36 1241.07 1310.38C1241.48 1310.44 1241.94 1310.53 1242.27 1310.76C1242.58 1310.97 1242.58 1311.37 1242.16 1311.58C1241.81 1311.75 1241.54 1312.29 1241.05 1311.83Z" fill="white"/>
                    <path d="M1283.92 1122.71C1283.43 1122.85 1282.85 1122.85 1282.47 1122.24C1282.33 1122.02 1282.36 1121.72 1282.68 1121.61C1283.3 1121.38 1283.87 1121.45 1284.26 1122.03C1284.41 1122.25 1284.37 1122.53 1283.92 1122.71Z" fill="white"/>
                    <path d="M1262.11 1065.8C1261.71 1066.1 1261.36 1065.91 1261.3 1065.56C1261.22 1065.16 1261.33 1064.71 1261.43 1064.3C1261.47 1064.15 1262.69 1064.65 1262.78 1064.88C1262.91 1065.2 1262.65 1065.55 1262.11 1065.8Z" fill="white"/>
                    <path d="M1371.33 1159.15C1371.01 1159.35 1370.69 1159.56 1370.45 1159.11C1370.18 1158.62 1370.27 1158.06 1370.66 1157.8C1370.85 1157.67 1371.52 1157.74 1371.8 1158.19C1372.18 1158.8 1371.6 1158.9 1371.33 1159.15Z" fill="white"/>
                    <path d="M1344.71 1077.13C1344.2 1077.18 1343.73 1077.18 1343.37 1076.75C1343.16 1076.5 1343.21 1076.21 1343.45 1076.1C1343.84 1075.91 1344.19 1075.18 1344.74 1075.87C1345.08 1076.29 1344.67 1076.7 1344.71 1077.14V1077.13Z" fill="white"/>
                    <path d="M1358.96 1156.87C1358.8 1156.87 1358.63 1156.85 1358.47 1156.85C1358.61 1156.08 1358.88 1155.31 1358.57 1154.52C1358.56 1154.48 1358.84 1154.28 1359.01 1154.24C1359.1 1154.22 1359.35 1154.41 1359.34 1154.45C1359.12 1155.14 1359.73 1155.84 1359.33 1156.51C1359.24 1156.65 1359.09 1156.75 1358.97 1156.87H1358.96Z" fill="white"/>
                    <path d="M1355.89 1153.93C1355.62 1153.46 1356.36 1153.33 1356.73 1153.13C1357.46 1152.74 1357.49 1153.57 1357.79 1153.9C1357.88 1154 1357.91 1154.57 1357.54 1154.27C1356.99 1153.83 1356.5 1155.1 1355.89 1153.93Z" fill="white"/>
                    <path d="M1220.44 1255.03C1220.89 1255.51 1220.47 1256.01 1220.46 1256.5C1220.13 1256.44 1219.62 1256.5 1219.5 1256.3C1219.24 1255.88 1219.39 1255.39 1219.77 1255.01C1220 1254.79 1220.22 1254.87 1220.45 1255.02L1220.44 1255.03Z" fill="white"/>
                    <path d="M1364.25 1284.21C1364.09 1283.86 1363.59 1283.54 1364.19 1283.12C1364.62 1282.82 1364.84 1283.09 1365.02 1283.4C1365.22 1283.74 1365.69 1284.08 1365.09 1284.5C1364.66 1284.8 1364.46 1284.52 1364.24 1284.21H1364.25Z" fill="white"/>
                    <path d="M1181.49 1193.82C1180.59 1193.82 1180.15 1193.45 1179.89 1192.85C1179.83 1192.71 1179.89 1192.52 1179.88 1192.36C1180.05 1192.39 1180.23 1192.46 1180.4 1192.46C1181.06 1192.44 1181.28 1192.95 1181.53 1193.39C1181.61 1193.53 1181.5 1193.76 1181.5 1193.82H1181.49Z" fill="white"/>
                    <path d="M1375.49 1309.73C1375.38 1309.81 1375.14 1309.57 1374.96 1309.24C1374.77 1308.9 1374.28 1308.57 1374.87 1308.14C1375.29 1307.84 1375.52 1308.08 1375.7 1308.41C1375.9 1308.75 1376.37 1309.1 1375.49 1309.73Z" fill="white"/>
                    <path d="M1369.98 1252.17C1369.98 1252.05 1369.91 1251.84 1369.98 1251.78C1370.46 1251.39 1370.93 1251.04 1371.63 1251.31C1371.92 1251.42 1372.06 1251.53 1371.9 1251.79C1371.64 1252.2 1370.31 1252.45 1369.98 1252.17Z" fill="white"/>
                    <path d="M1264.92 1265.05C1264.1 1265.33 1264.18 1264.47 1263.86 1264.14C1263.71 1263.99 1263.97 1263.64 1264.23 1263.56C1265.01 1263.32 1265.01 1264.1 1265.3 1264.46C1265.43 1264.63 1265.2 1264.94 1264.92 1265.05Z" fill="white"/>
                    <path d="M1344.47 1195.29C1343.65 1195.57 1343.73 1194.71 1343.41 1194.38C1343.26 1194.23 1343.52 1193.88 1343.78 1193.8C1344.56 1193.56 1344.56 1194.34 1344.85 1194.7C1344.98 1194.87 1344.75 1195.18 1344.47 1195.29Z" fill="white"/>
                    <path d="M1232.34 1147.13C1232.23 1147.21 1231.99 1146.97 1231.81 1146.64C1231.62 1146.3 1231.13 1145.97 1231.72 1145.54C1232.14 1145.24 1232.37 1145.48 1232.55 1145.81C1232.75 1146.15 1233.22 1146.5 1232.34 1147.13Z" fill="white"/>
                    <path d="M1409.63 1165C1409.9 1164.5 1410.17 1163.98 1410.45 1163.47C1410.46 1163.45 1410.64 1163.47 1410.69 1163.52C1410.96 1163.79 1411 1164.09 1410.91 1164.47C1410.8 1164.9 1410.75 1165.34 1410.19 1165.37C1410.06 1165.37 1409.92 1165.2 1409.62 1165H1409.63Z" fill="white"/>
                    <path d="M1369.12 1135.39C1369.34 1135.68 1369.6 1135.97 1369.22 1136.3C1368.73 1136.72 1368.46 1136.42 1368.2 1135.99C1368.02 1135.69 1367.73 1135.41 1368.11 1135.08C1368.59 1134.67 1368.9 1134.94 1369.11 1135.39H1369.12Z" fill="white"/>
                    <path d="M1318.25 1113.83C1317.77 1113.97 1317.51 1113.99 1317.39 1113.67C1317.16 1113.05 1317.23 1112.49 1317.81 1112.09C1317.92 1112.01 1318.13 1112.08 1318.3 1112.08C1318.28 1112.69 1318.27 1113.29 1318.25 1113.83Z" fill="white"/>
                    <path d="M1369.48 1209.47C1369.12 1209.79 1368.75 1209.91 1368.64 1209.72C1368.42 1209.32 1367.7 1209.01 1368.23 1208.39C1368.41 1208.18 1368.78 1208.07 1368.92 1208.28C1369.18 1208.66 1369.73 1209.01 1369.48 1209.48V1209.47Z" fill="white"/>
                    <path d="M1222.88 1162.55C1222.56 1162.74 1222.27 1162.93 1221.99 1162.49C1221.61 1161.87 1222.16 1161.75 1222.48 1161.51C1222.77 1161.29 1223.08 1161.13 1223.36 1161.57C1223.75 1162.19 1223.17 1162.29 1222.88 1162.55Z" fill="white"/>
                    <path d="M1268.37 1231.48C1268.65 1232.3 1267.79 1232.22 1267.46 1232.54C1267.31 1232.69 1266.96 1232.43 1266.88 1232.17C1266.64 1231.39 1267.42 1231.39 1267.78 1231.1C1267.95 1230.97 1268.26 1231.2 1268.37 1231.48Z" fill="white"/>
                    <path d="M1325.28 1106.8C1325.5 1107.09 1325.76 1107.38 1325.38 1107.71C1324.89 1108.13 1324.62 1107.83 1324.36 1107.4C1324.18 1107.1 1323.89 1106.82 1324.27 1106.49C1324.75 1106.08 1325.06 1106.35 1325.27 1106.8H1325.28Z" fill="white"/>
                    <path d="M1419.76 1225.67C1420.15 1226.16 1420.08 1226.39 1419.86 1226.57C1419.36 1226.98 1419.09 1226.66 1418.86 1226.23C1418.74 1226.01 1418.42 1225.62 1418.63 1225.58C1419.06 1225.48 1419.55 1225.65 1419.76 1225.67Z" fill="white"/>
                    <path d="M1203.64 1191.05C1203.49 1191.07 1203.25 1191.1 1203.01 1191.14C1203.13 1190.66 1202.98 1190.07 1203.55 1189.78C1203.79 1189.66 1204.02 1189.7 1204.2 1189.99C1204.57 1190.6 1204.03 1190.72 1203.64 1191.05Z" fill="white"/>
                    <path d="M1350.19 1058.41C1350.02 1058.24 1350.08 1057.98 1350.29 1057.8C1350.56 1057.57 1350.92 1057.39 1351.14 1057.85C1351.29 1058.18 1351.92 1058.5 1351.41 1058.86C1351.23 1058.99 1350.55 1058.91 1350.2 1058.41H1350.19Z" fill="white"/>
                    <path d="M1447.38 1195.89C1446.9 1196.3 1446.4 1195.9 1445.91 1195.87C1446.05 1195.67 1446.15 1195.41 1446.34 1195.28C1446.66 1195.06 1447 1194.61 1447.42 1195.19C1447.61 1195.45 1447.55 1195.66 1447.37 1195.89H1447.38Z" fill="white"/>
                    <path d="M1326.43 1077.86C1326.16 1077.6 1326.37 1077.93 1326.5 1077.61C1326.57 1077.43 1326.9 1077.22 1327.08 1077.25C1327.56 1077.33 1328.17 1077.23 1328.38 1077.88C1328.4 1077.95 1328.38 1078.11 1328.34 1078.12C1327.66 1078.33 1327.07 1077.91 1326.44 1077.87L1326.43 1077.86Z" fill="white"/>
                    <path d="M1310.95 1126.35C1310.47 1126.21 1309.83 1126.32 1309.66 1125.67C1309.62 1125.52 1309.87 1125.18 1310.07 1125.1C1310.67 1124.85 1310.73 1125.48 1310.99 1125.77C1311.17 1125.97 1311.23 1126.18 1310.95 1126.36V1126.35Z" fill="white"/>
                    <path d="M1308.73 1213.74C1308.58 1213.84 1308.44 1213.98 1308.28 1214.02C1308.17 1214.05 1307.94 1213.94 1307.92 1213.86C1307.86 1213.57 1309.25 1212.63 1309.55 1212.76C1309.62 1212.79 1309.67 1213.1 1309.62 1213.14C1309.35 1213.37 1309.04 1213.55 1308.74 1213.75L1308.73 1213.74Z" fill="white"/>
                    <path d="M1230.42 1088.1C1229.97 1088.44 1229.72 1088.21 1229.55 1087.87C1229.42 1087.62 1229.47 1087.4 1229.75 1087.22C1230.17 1086.95 1230.41 1087.15 1230.58 1087.49C1230.7 1087.73 1230.72 1088.01 1230.42 1088.1Z" fill="white"/>
                    <path d="M1438.2 1211.14C1437.92 1211.23 1437.71 1211.22 1437.53 1210.93C1437.26 1210.51 1437.47 1210.27 1437.8 1210.1C1438.04 1209.97 1438.27 1210.02 1438.45 1210.3C1438.73 1210.72 1438.49 1210.93 1438.2 1211.13V1211.14Z" fill="white"/>
                    <path d="M1256.98 1296.53C1256.7 1296.62 1256.49 1296.61 1256.31 1296.32C1256.04 1295.9 1256.25 1295.66 1256.58 1295.49C1256.82 1295.36 1257.05 1295.41 1257.23 1295.69C1257.51 1296.11 1257.27 1296.32 1256.98 1296.52V1296.53Z" fill="white"/>
                    <path d="M1191 1216.55C1190.82 1216.26 1190.68 1215.98 1191.1 1215.7C1191.38 1215.51 1191.6 1215.56 1191.78 1215.77C1192.02 1216.06 1192.12 1216.36 1191.7 1216.64C1191.42 1216.83 1191.23 1216.77 1190.99 1216.55H1191Z" fill="white"/>
                    <path d="M1384.95 1291.85C1385.03 1292.35 1385 1292.81 1384.6 1293.16C1384.49 1293.25 1384.16 1293.28 1384.15 1293.26C1384 1292.77 1384.05 1292.31 1384.46 1291.96C1384.57 1291.87 1384.77 1291.88 1384.95 1291.84V1291.85Z" fill="white"/>
                    <path d="M1289.42 1311.71C1289.19 1311.94 1288.94 1311.85 1288.76 1311.64C1288.52 1311.35 1288.42 1311.05 1288.84 1310.77C1289.12 1310.58 1289.34 1310.63 1289.52 1310.84C1289.76 1311.13 1289.87 1311.45 1289.42 1311.71Z" fill="white"/>
                    <path d="M1364.68 1233.66C1364.4 1233.75 1364.19 1233.74 1364.01 1233.45C1363.74 1233.03 1363.95 1232.79 1364.28 1232.62C1364.52 1232.49 1364.75 1232.54 1364.93 1232.82C1365.21 1233.24 1364.97 1233.45 1364.68 1233.65V1233.66Z" fill="white"/>
                    <path d="M1285.97 1064.41C1285.64 1064.51 1285.44 1064.5 1285.25 1064.22C1284.98 1063.8 1285.19 1063.56 1285.52 1063.39C1285.76 1063.26 1285.99 1063.31 1286.17 1063.59C1286.46 1064.02 1286.18 1064.21 1285.96 1064.41H1285.97Z" fill="white"/>
                    <path d="M1301.74 1080.18C1301.43 1079.77 1301.65 1079.51 1301.99 1079.34C1302.24 1079.21 1302.46 1079.26 1302.64 1079.54C1302.91 1079.96 1302.7 1080.2 1302.37 1080.37C1302.13 1080.5 1301.86 1080.48 1301.74 1080.18Z" fill="white"/>
                    <path d="M1374.25 1328.2C1374.55 1328.02 1374.86 1327.82 1375.1 1328.27C1375.17 1328.41 1375.18 1328.58 1375.22 1328.74C1374.83 1328.71 1374.44 1328.69 1374.05 1328.66C1374.11 1328.51 1374.18 1328.36 1374.24 1328.2H1374.25Z" fill="white"/>
                    <path d="M1334.11 1299.78C1333.81 1299.96 1333.5 1300.16 1333.26 1299.71C1333.19 1299.57 1333.18 1299.4 1333.14 1299.24C1333.53 1299.27 1333.92 1299.29 1334.31 1299.32C1334.25 1299.47 1334.18 1299.62 1334.12 1299.78H1334.11Z" fill="white"/>
                    <path d="M1418.15 1205.98C1417.79 1205.91 1417.44 1205.85 1417.1 1205.79C1417.24 1205.59 1417.33 1205.32 1417.52 1205.21C1417.94 1204.97 1418.14 1205.32 1418.28 1205.64C1418.32 1205.73 1418.18 1205.89 1418.14 1205.98H1418.15Z" fill="white"/>
                    <path d="M1264.09 1225.05C1264.04 1224.84 1263.93 1224.58 1263.98 1224.55C1264.37 1224.27 1264.74 1224.62 1265.13 1224.63C1265.06 1224.78 1265.04 1224.98 1264.93 1225.07C1264.65 1225.31 1264.33 1225.41 1264.09 1225.05Z" fill="white"/>
                    <path d="M1181.86 1250.58C1181.72 1250.58 1181.52 1250.63 1181.46 1250.56C1181.23 1250.31 1180.99 1249.98 1181.37 1249.69C1181.55 1249.56 1181.83 1249.58 1182.07 1249.53C1182 1249.87 1181.93 1250.22 1181.86 1250.57V1250.58Z" fill="white"/>
                    <path d="M1208.89 1252.43C1209.18 1252.83 1208.92 1253.06 1208.6 1253.23C1208.47 1253.3 1208.28 1253.24 1208.11 1253.24C1208.24 1252.87 1208.36 1252.5 1208.49 1252.14C1208.62 1252.24 1208.75 1252.33 1208.89 1252.43Z" fill="white"/>
                    <path d="M1396.23 1209.35C1396.16 1209.35 1395.96 1209.42 1395.89 1209.35C1395.66 1209.1 1395.41 1208.78 1395.78 1208.48C1395.95 1208.34 1396.24 1208.36 1396.48 1208.31C1396.4 1208.65 1396.31 1208.98 1396.22 1209.35H1396.23Z" fill="white"/>
                    <path d="M1365.98 1227.56C1365.58 1227.85 1365.35 1227.59 1365.18 1227.27C1365.11 1227.14 1365.17 1226.95 1365.17 1226.78C1365.54 1226.91 1365.91 1227.03 1366.27 1227.16C1366.17 1227.29 1366.08 1227.42 1365.98 1227.56Z" fill="white"/>
                    <path d="M1224.73 1294.32C1224.56 1294.32 1224.4 1294.33 1224.23 1294.34C1224.34 1293.97 1224.45 1293.6 1224.55 1293.22C1224.68 1293.31 1224.85 1293.38 1224.95 1293.5C1225.26 1293.87 1225.04 1294.11 1224.73 1294.32Z" fill="white"/>
                    <path d="M1291.41 1094.23C1291.7 1094.63 1291.44 1094.86 1291.12 1095.03C1290.99 1095.1 1290.8 1095.04 1290.63 1095.04C1290.76 1094.67 1290.88 1094.3 1291.01 1093.94C1291.14 1094.04 1291.27 1094.13 1291.41 1094.23Z" fill="white"/>
                    <path d="M1268.93 1064.21C1268.83 1064.42 1268.8 1064.61 1268.68 1064.7C1268.39 1064.94 1268.06 1065.03 1267.84 1064.6C1267.77 1064.46 1267.77 1064.29 1267.74 1064.13C1268.13 1064.16 1268.51 1064.18 1268.92 1064.21H1268.93Z" fill="white"/>
                    <path d="M1447.5 1158.37C1447.13 1158.39 1447.07 1158.08 1446.95 1157.87C1446.91 1157.8 1447.04 1157.54 1447.12 1157.53C1447.46 1157.47 1447.53 1157.8 1447.65 1158.01C1447.69 1158.08 1447.55 1158.25 1447.5 1158.37Z" fill="white"/>
                    <path d="M1437.41 1179.95C1437.22 1180.18 1437.09 1180.4 1436.9 1180.54C1436.79 1180.62 1436.58 1180.55 1436.41 1180.56C1436.55 1180.36 1436.66 1180.12 1436.84 1179.97C1436.94 1179.89 1437.16 1179.95 1437.41 1179.95Z" fill="white"/>
                    <path d="M1201.44 1134.08C1201.07 1134.1 1201.01 1133.79 1200.89 1133.58C1200.85 1133.51 1200.98 1133.25 1201.06 1133.24C1201.4 1133.18 1201.47 1133.51 1201.59 1133.72C1201.63 1133.79 1201.49 1133.96 1201.44 1134.08Z" fill="white"/>
                    <path d="M1189.76 1235.03C1189.53 1234.84 1189.31 1234.71 1189.17 1234.52C1189.09 1234.41 1189.16 1234.2 1189.15 1234.03C1189.35 1234.17 1189.59 1234.28 1189.74 1234.46C1189.82 1234.56 1189.76 1234.78 1189.76 1235.03Z" fill="white"/>
                    <path d="M1191.67 1237.26C1191.6 1237.13 1191.45 1236.94 1191.38 1236.71C1191.3 1236.45 1191.47 1236.34 1191.67 1236.52C1191.84 1236.67 1191.99 1236.89 1192.06 1237.11C1192.15 1237.37 1192 1237.45 1191.66 1237.26H1191.67Z" fill="white"/>
                    <path d="M1185.11 1136.49C1185.14 1136.65 1185.25 1136.89 1185.19 1136.95C1184.95 1137.17 1184.76 1137.01 1184.65 1136.77C1184.59 1136.62 1184.5 1136.37 1184.57 1136.31C1184.81 1136.09 1184.99 1136.25 1185.11 1136.49Z" fill="white"/>
                    <path d="M1432.08 1210.61C1431.92 1210.64 1431.68 1210.75 1431.62 1210.69C1431.4 1210.45 1431.56 1210.26 1431.8 1210.15C1431.95 1210.09 1432.2 1210 1432.26 1210.07C1432.48 1210.31 1432.32 1210.49 1432.08 1210.61Z" fill="white"/>
                    <path d="M1234.45 1292.84C1234.26 1293.07 1234.13 1293.29 1233.94 1293.43C1233.83 1293.51 1233.62 1293.44 1233.45 1293.45C1233.59 1293.25 1233.7 1293.01 1233.88 1292.86C1233.98 1292.78 1234.2 1292.84 1234.45 1292.84Z" fill="white"/>
                    <path d="M1354.72 1086.27C1354.6 1086.27 1354.44 1086.27 1354.27 1086.27C1354.41 1086.07 1354.52 1085.83 1354.7 1085.68C1354.8 1085.6 1355.02 1085.66 1355.18 1085.66C1355.15 1085.94 1355.03 1086.15 1354.72 1086.26V1086.27Z" fill="white"/>
                    <path d="M1259.27 1326.47C1259.1 1326.26 1259.02 1326.03 1259.32 1325.9C1259.4 1325.87 1259.6 1326.04 1259.71 1326.16C1259.89 1326.35 1259.96 1326.6 1259.67 1326.73C1259.59 1326.77 1259.41 1326.57 1259.27 1326.48V1326.47Z" fill="white"/>
                    <path d="M1323.32 1105.81C1323.15 1105.6 1323.07 1105.37 1323.37 1105.24C1323.45 1105.21 1323.65 1105.38 1323.76 1105.5C1323.94 1105.69 1324.01 1105.94 1323.72 1106.07C1323.64 1106.11 1323.46 1105.91 1323.32 1105.82V1105.81Z" fill="white"/>
                    <path d="M1321.02 1117.17C1320.85 1116.96 1320.77 1116.73 1321.07 1116.6C1321.15 1116.57 1321.35 1116.74 1321.46 1116.86C1321.64 1117.05 1321.71 1117.3 1321.42 1117.43C1321.34 1117.47 1321.16 1117.27 1321.02 1117.18V1117.17Z" fill="white"/>
                    <path d="M1318.57 1159.79C1318.38 1160.02 1318.25 1160.24 1318.06 1160.38C1317.95 1160.46 1317.74 1160.39 1317.57 1160.4C1317.71 1160.2 1317.82 1159.96 1318 1159.81C1318.1 1159.73 1318.32 1159.79 1318.57 1159.79Z" fill="white"/>
                    <path d="M1405.51 1207.96C1405.31 1207.98 1405.06 1208.01 1404.82 1208.04C1404.88 1207.89 1404.91 1207.66 1405.02 1207.6C1405.23 1207.49 1405.49 1207.49 1405.73 1207.44C1405.67 1207.59 1405.6 1207.74 1405.51 1207.97V1207.96Z" fill="white"/>
                    <path d="M1322.96 1235.07C1322.84 1235.07 1322.68 1235.07 1322.51 1235.07C1322.65 1234.87 1322.76 1234.63 1322.94 1234.48C1323.04 1234.4 1323.26 1234.46 1323.42 1234.46C1323.39 1234.74 1323.27 1234.95 1322.96 1235.06V1235.07Z" fill="white"/>
                    <path d="M1340.37 1273.74C1340.18 1273.97 1340.05 1274.19 1339.86 1274.33C1339.75 1274.41 1339.54 1274.34 1339.37 1274.35C1339.51 1274.15 1339.62 1273.91 1339.8 1273.76C1339.9 1273.68 1340.12 1273.74 1340.37 1273.74Z" fill="white"/>
                    <path d="M1368.11 1334.15C1367.74 1334.17 1367.68 1333.86 1367.56 1333.65C1367.52 1333.58 1367.65 1333.32 1367.73 1333.31C1368.07 1333.25 1368.14 1333.58 1368.26 1333.79C1368.3 1333.86 1368.16 1334.03 1368.11 1334.15Z" fill="white"/>
                    <path d="M1316.58 1304.21C1316.41 1304 1316.33 1303.77 1316.63 1303.64C1316.71 1303.61 1316.91 1303.78 1317.02 1303.9C1317.2 1304.09 1317.27 1304.34 1316.98 1304.47C1316.9 1304.51 1316.72 1304.31 1316.58 1304.22V1304.21Z" fill="white"/>
                    <path d="M1217.51 1139.91C1217.54 1140.07 1217.65 1140.31 1217.59 1140.37C1217.35 1140.59 1217.16 1140.43 1217.05 1140.19C1216.99 1140.04 1216.9 1139.79 1216.97 1139.73C1217.21 1139.51 1217.39 1139.67 1217.51 1139.91Z" fill="white"/>
                    <path d="M1277.91 1205.35C1277.75 1205.38 1277.51 1205.49 1277.45 1205.43C1277.23 1205.19 1277.39 1205 1277.63 1204.89C1277.78 1204.83 1278.03 1204.74 1278.09 1204.8C1278.31 1205.04 1278.15 1205.22 1277.91 1205.34V1205.35Z" fill="white"/>
                    <path d="M1266.53 1213.87C1266.46 1213.99 1266.41 1214.12 1266.32 1214.21C1266.29 1214.24 1266.16 1214.18 1266.08 1214.17C1266.15 1214.05 1266.2 1213.92 1266.29 1213.83C1266.32 1213.8 1266.45 1213.86 1266.53 1213.87Z" fill="white"/>
                    <path d="M1218.74 1301.89C1218.67 1302.01 1218.62 1302.14 1218.53 1302.23C1218.5 1302.26 1218.37 1302.2 1218.29 1302.19C1218.36 1302.07 1218.41 1301.94 1218.5 1301.85C1218.53 1301.82 1218.66 1301.88 1218.74 1301.89Z" fill="white"/>
                    <path d="M1324.57 1127.81C1324.44 1127.82 1324.3 1127.83 1324.17 1127.84C1324.19 1127.76 1324.18 1127.61 1324.23 1127.6C1324.35 1127.55 1324.5 1127.56 1324.63 1127.54C1324.61 1127.62 1324.6 1127.71 1324.58 1127.81H1324.57Z" fill="white"/>
                    <path d="M1312.5 1086.87C1312.6 1086.89 1312.69 1086.91 1312.77 1086.92C1312.7 1087.04 1312.65 1087.17 1312.56 1087.26C1312.53 1087.29 1312.4 1087.23 1312.32 1087.22C1312.38 1087.1 1312.45 1086.98 1312.51 1086.86L1312.5 1086.87Z" fill="white"/>
                    <path d="M1357.53 1152.34C1357.52 1152.21 1357.51 1152.07 1357.5 1151.94C1357.58 1151.96 1357.73 1151.95 1357.74 1152C1357.79 1152.12 1357.78 1152.27 1357.8 1152.4C1357.72 1152.38 1357.63 1152.37 1357.53 1152.35V1152.34Z" fill="white"/>
                    <path d="M1347.84 1193.72C1347.72 1193.65 1347.59 1193.6 1347.5 1193.51C1347.47 1193.48 1347.53 1193.35 1347.54 1193.27C1347.66 1193.34 1347.79 1193.39 1347.88 1193.48C1347.91 1193.51 1347.85 1193.64 1347.84 1193.72Z" fill="white"/>
                    <path d="M1368.58 1233.27C1368.6 1233.17 1368.62 1233.08 1368.63 1233C1368.75 1233.07 1368.88 1233.12 1368.97 1233.21C1369 1233.24 1368.94 1233.37 1368.93 1233.45C1368.81 1233.39 1368.69 1233.32 1368.57 1233.26L1368.58 1233.27Z" fill="white"/>
                    <path d="M1421.86 1219.25C1421.73 1219.26 1421.59 1219.27 1421.46 1219.28C1421.48 1219.2 1421.47 1219.05 1421.52 1219.04C1421.64 1218.99 1421.79 1219 1421.92 1218.98C1421.9 1219.06 1421.89 1219.15 1421.87 1219.25H1421.86Z" fill="white"/>
                    <path d="M1349.1 1136.45C1349 1136.43 1348.91 1136.41 1348.83 1136.4C1348.9 1136.28 1348.95 1136.15 1349.04 1136.06C1349.07 1136.03 1349.2 1136.09 1349.28 1136.1C1349.22 1136.22 1349.15 1136.34 1349.09 1136.46L1349.1 1136.45Z" fill="white"/>
                    <path d="M1319.47 1198.21C1319.57 1198.23 1319.66 1198.25 1319.74 1198.26C1319.67 1198.38 1319.62 1198.51 1319.53 1198.6C1319.5 1198.63 1319.37 1198.57 1319.29 1198.56C1319.35 1198.44 1319.42 1198.32 1319.48 1198.2L1319.47 1198.21Z" fill="white"/>
                    <path d="M1383.33 1314.06C1383.31 1314.15 1383.3 1314.29 1383.28 1314.3C1383.15 1314.31 1382.98 1314.33 1382.88 1314.27C1382.66 1314.14 1382.69 1313.99 1382.94 1313.95C1383.06 1313.93 1383.2 1314.02 1383.33 1314.06Z" fill="white"/>
                    <path d="M1382.53 1314.2C1382.4 1314.16 1382.18 1314.14 1382.16 1314.06C1382.1 1313.85 1382.22 1313.73 1382.37 1313.8C1382.5 1313.86 1382.61 1313.97 1382.73 1314.06C1382.66 1314.1 1382.59 1314.15 1382.53 1314.19V1314.2Z" fill="white"/>
                    <path d="M1241.98 1200.9C1241.85 1200.91 1241.71 1200.92 1241.58 1200.93C1241.6 1200.85 1241.59 1200.7 1241.64 1200.69C1241.76 1200.64 1241.91 1200.65 1242.04 1200.63C1242.02 1200.71 1242.01 1200.8 1241.99 1200.9H1241.98Z" fill="white"/>
                    <path d="M1448.98 1270.06C1448.85 1270.07 1448.71 1270.08 1448.58 1270.09C1448.6 1270.01 1448.59 1269.86 1448.64 1269.85C1448.76 1269.8 1448.91 1269.81 1449.04 1269.79C1449.02 1269.87 1449.01 1269.96 1448.99 1270.06H1448.98Z" fill="white"/>
                    <path d="M1382.53 1321.32C1382.43 1321.3 1382.34 1321.28 1382.26 1321.27C1382.33 1321.15 1382.38 1321.02 1382.47 1320.93C1382.5 1320.9 1382.63 1320.96 1382.71 1320.97C1382.65 1321.09 1382.58 1321.21 1382.52 1321.33L1382.53 1321.32Z" fill="white"/>
                    <path d="M1434.5 1232.68C1434.63 1232.67 1434.77 1232.66 1434.9 1232.65C1434.88 1232.73 1434.89 1232.88 1434.84 1232.89C1434.72 1232.94 1434.57 1232.93 1434.44 1232.95C1434.46 1232.87 1434.47 1232.78 1434.49 1232.68H1434.5Z" fill="white"/>
                    <path d="M1304.51 1265.59C1304.38 1265.6 1304.24 1265.61 1304.11 1265.62C1304.13 1265.54 1304.12 1265.39 1304.17 1265.38C1304.29 1265.33 1304.44 1265.34 1304.57 1265.32C1304.55 1265.4 1304.54 1265.49 1304.52 1265.59H1304.51Z" fill="white"/>
                    <path d="M1383 1166.06C1383.02 1165.96 1383.04 1165.87 1383.05 1165.79C1383.17 1165.86 1383.3 1165.91 1383.39 1166C1383.42 1166.03 1383.36 1166.16 1383.35 1166.24C1383.23 1166.18 1383.11 1166.11 1382.99 1166.05L1383 1166.06Z" fill="white"/>
                    <path d="M1356.41 1303.02C1356.34 1303.14 1356.29 1303.27 1356.2 1303.36C1356.17 1303.39 1356.04 1303.33 1355.96 1303.32C1356.03 1303.2 1356.08 1303.07 1356.17 1302.98C1356.2 1302.95 1356.33 1303.01 1356.41 1303.02Z" fill="white"/>
                    <path d="M1391.54 1174.49C1391.41 1174.5 1391.27 1174.51 1391.14 1174.52C1391.16 1174.44 1391.15 1174.29 1391.2 1174.28C1391.32 1174.23 1391.47 1174.24 1391.6 1174.22L1391.55 1174.49H1391.54Z" fill="white"/>
                    <path d="M1288.71 1204.97C1288.72 1205.1 1288.73 1205.24 1288.74 1205.37C1288.66 1205.35 1288.51 1205.36 1288.5 1205.31C1288.45 1205.19 1288.46 1205.04 1288.44 1204.91C1288.52 1204.93 1288.61 1204.94 1288.71 1204.96V1204.97Z" fill="white"/>
                    <path d="M1178.43 1141.24C1178.36 1141.36 1178.31 1141.49 1178.22 1141.58C1178.19 1141.61 1178.06 1141.55 1177.98 1141.54C1178.05 1141.42 1178.1 1141.29 1178.19 1141.2C1178.22 1141.17 1178.35 1141.23 1178.43 1141.24Z" fill="white"/>
                    <path d="M1302.26 1217.95C1302.52 1219.76 1301.32 1220.55 1300.18 1221.27C1299.76 1221.53 1299.06 1221.84 1298.89 1222.06C1297.83 1223.43 1296.9 1221.6 1295.96 1222.06C1295.65 1221.07 1294.94 1219.99 1295.14 1219.11C1295.33 1218.28 1295.65 1217.26 1296.25 1216.51C1296.82 1215.81 1297.29 1214.56 1298.58 1215.74C1298.77 1215.91 1299.3 1215.69 1299.67 1215.7C1301.32 1215.71 1302.18 1216.51 1302.27 1217.95H1302.26Z" fill="white"/>
                    <path d="M1183.21 1129.78C1184.29 1129.73 1185.11 1130.54 1185.12 1130.92C1185.18 1132.39 1186.55 1132.91 1187.05 1134.04C1187.4 1134.84 1187.48 1135.38 1186.88 1135.98C1185.89 1136.97 1185.27 1137.12 1184.42 1136.56C1184.05 1136.32 1183.67 1136.13 1183.27 1135.95C1181.54 1135.15 1180.63 1131.39 1182.44 1130.15C1182.76 1129.93 1183.02 1129.74 1183.21 1129.78Z" fill="white"/>
                    <path d="M1203.54 1215.84C1204.23 1216.5 1205.6 1216.21 1205.78 1217.52C1205.81 1217.7 1205.75 1217.93 1205.83 1218.07C1206.18 1218.68 1206.78 1219.2 1206.41 1220.01C1206.12 1220.64 1205.45 1220.79 1204.92 1221.09C1203.87 1221.7 1202.23 1221.24 1201.44 1220.16C1200.83 1219.33 1200.81 1218.5 1201.26 1217.54C1201.73 1216.55 1202.75 1216.37 1203.54 1215.83V1215.84Z" fill="white"/>
                    <path d="M1090.86 1283.7C1089.24 1283.8 1088.46 1283.25 1088.25 1282.09C1088.13 1281.45 1088.18 1280.78 1088.1 1280.13C1088.02 1279.52 1088.28 1279.31 1088.86 1279.31C1089.51 1279.31 1090.18 1279.14 1090.82 1279.21C1091.91 1279.33 1092.66 1280.02 1093.17 1280.96C1093.27 1281.15 1093.26 1281.55 1093.13 1281.7C1092.64 1282.24 1092.38 1282.9 1092.01 1283.5C1091.83 1283.79 1090.98 1283.68 1090.86 1283.7Z" fill="white"/>
                    <path d="M1065.26 1163.57C1065.99 1163.46 1067.03 1163.29 1067.41 1164.41C1067.59 1164.94 1067.93 1165.94 1067.27 1166.19C1066.56 1166.46 1065.98 1167.35 1065.06 1166.94C1064.42 1166.66 1063.85 1166.13 1063.19 1166.01C1062.59 1165.9 1062.45 1165.72 1062.41 1165.17C1062.35 1164.39 1062.78 1163.93 1063.39 1163.72C1063.9 1163.55 1064.5 1163.62 1065.26 1163.57Z" fill="white"/>
                    <path d="M1090.53 1304.03C1091.27 1303.97 1091.94 1304.5 1092.03 1305.23C1092.06 1305.47 1090.9 1307.87 1090.71 1307.97C1090.27 1308.2 1088.76 1307.37 1088.83 1307.32C1089.77 1306.61 1088.41 1306.13 1088.7 1305.53C1088.98 1304.96 1090.06 1304.08 1090.53 1304.04V1304.03Z" fill="white"/>
                    <path d="M1250.04 1255.56C1250.02 1255.17 1249.88 1254.58 1250.26 1254.55C1250.95 1254.5 1251.68 1254.13 1252.38 1254.73C1252.77 1255.06 1252.73 1255.57 1253.04 1255.85C1253.55 1256.3 1253.65 1256.64 1253.19 1257.23C1252.7 1257.86 1252.02 1258 1251.51 1257.66C1250.79 1257.18 1249.86 1256.69 1250.04 1255.56Z" fill="white"/>
                    <path d="M1180.23 1187.53C1179.64 1187.53 1178.9 1187.88 1178.87 1186.76C1178.87 1186.51 1178.34 1186.28 1178.07 1186.02C1177.77 1185.72 1177.5 1185.4 1177.21 1185.09C1177.83 1184.99 1178.54 1184.65 1179.05 1184.84C1179.7 1185.09 1180.17 1185.78 1180.76 1186.24C1181.08 1186.49 1181.33 1186.79 1181.19 1187.17C1181.04 1187.59 1180.59 1187.49 1180.23 1187.53Z" fill="white"/>
                    <path d="M1199.57 1336.27C1199.39 1336.28 1199.2 1336.32 1199.02 1336.3C1198.61 1336.27 1198.05 1336.61 1197.86 1335.99C1197.67 1335.35 1197.83 1334.77 1198.37 1334.29C1198.83 1333.88 1199.13 1334.08 1199.53 1334.37C1199.79 1334.56 1200.21 1334.53 1200.47 1334.72C1200.82 1334.97 1201.3 1335.2 1201.2 1335.76C1201.08 1336.42 1200.5 1336.15 1200.1 1336.24C1199.92 1336.28 1199.74 1336.27 1199.55 1336.28L1199.57 1336.27Z" fill="white"/>
                    <path d="M1081.99 1298.44C1081.62 1299.21 1081.47 1300.14 1080.73 1300.7C1080.56 1300.83 1080.21 1300.86 1079.98 1300.61C1079.69 1300.28 1079.09 1300.07 1079.27 1299.55C1079.49 1298.9 1079.86 1298.29 1080.23 1297.71C1080.67 1297.02 1080.88 1297.98 1081.27 1297.9C1081.67 1297.82 1081.99 1297.93 1081.99 1298.45V1298.44Z" fill="white"/>
                    <path d="M1225.62 1181.69C1225.12 1181.81 1224.8 1181.66 1224.79 1181.03C1224.77 1180.06 1225.25 1179.49 1226.11 1179.39C1226.87 1179.31 1227.32 1179.75 1227.55 1180.33C1227.81 1180.99 1226.86 1181.64 1225.63 1181.69H1225.62Z" fill="white"/>
                    <path d="M1267.99 1108.94C1267.71 1108.96 1267.42 1109.03 1267.15 1108.99C1266.52 1108.89 1266.27 1108.15 1266.68 1107.67C1266.93 1107.39 1267.13 1107.05 1267.43 1106.84C1267.81 1106.57 1268.37 1106.5 1268.71 1106.75C1269.11 1107.03 1269.03 1107.74 1269.55 1108.02C1269.15 1108.36 1268.98 1109.02 1268.27 1108.92C1268.18 1108.91 1268.08 1108.92 1267.99 1108.94Z" fill="white"/>
                    <path d="M1132.88 1287.68C1133.13 1287.9 1133.33 1288.09 1133.55 1288.26C1134.11 1288.68 1133.52 1289.54 1134.23 1289.93C1134.61 1290.14 1134.63 1290.56 1134.32 1290.85C1134.02 1291.12 1133.67 1291.04 1133.37 1290.75C1132.97 1290.37 1132.52 1290.04 1132.13 1289.64C1131.95 1289.45 1131.37 1289.37 1131.82 1288.88C1132.19 1288.49 1132.53 1288.07 1132.88 1287.67V1287.68Z" fill="white"/>
                    <path d="M1034.87 1225.82C1034.92 1225.23 1035.8 1224.61 1036.48 1224.71C1036.75 1224.75 1037.79 1226.13 1037.72 1226.45C1037.55 1227.26 1036.84 1226.92 1036.39 1226.95C1035.54 1227 1034.83 1226.4 1034.87 1225.83V1225.82Z" fill="white"/>
                    <path d="M1097.79 1179.7C1097.91 1180.26 1097.81 1180.62 1097.17 1180.63C1096.38 1180.65 1095.58 1180.16 1095.54 1179.6C1095.5 1179.04 1096.25 1178.43 1097.03 1178.38C1098.2 1178.31 1097.59 1179.26 1097.78 1179.7H1097.79Z" fill="white"/>
                    <path d="M1100.92 1173.61C1101.38 1173.85 1101.89 1174.08 1101.97 1174.67C1102.05 1175.31 1101 1176.03 1100.24 1175.91C1099.74 1175.84 1099.75 1175.47 1099.76 1175.11C1099.78 1174.28 1100.34 1173.57 1100.92 1173.61Z" fill="white"/>
                    <path d="M1115.77 1258.36C1115.41 1258.38 1115.22 1258.38 1115.04 1258.41C1114.37 1258.54 1114.13 1257.93 1113.69 1257.65C1114.09 1257.32 1114.25 1256.68 1114.93 1256.72C1115.11 1256.73 1115.3 1256.72 1115.48 1256.68C1116.09 1256.59 1116.49 1256.64 1116.53 1257.46C1116.57 1258.24 1116.27 1258.44 1115.77 1258.36Z" fill="white"/>
                    <path d="M1122.21 1097.08C1122.3 1097.73 1121.87 1097.89 1121.54 1097.77C1120.89 1097.53 1120.27 1097.16 1119.68 1096.8C1119.12 1096.46 1119.68 1096.24 1119.82 1096C1120.49 1094.95 1121.06 1096.15 1121.7 1096.12C1122.44 1096.09 1122.06 1096.81 1122.21 1097.08Z" fill="white"/>
                    <path d="M1102.82 1227.07C1103.57 1226.87 1104.05 1227.33 1104.29 1227.9C1104.47 1228.33 1103.42 1229.46 1103.07 1229.3C1102.4 1228.98 1102.08 1228.43 1102.1 1227.66C1102.1 1227.12 1102.45 1227.11 1102.82 1227.08V1227.07Z" fill="white"/>
                    <path d="M1255.02 1304.99C1254.96 1305.54 1254.55 1305.87 1254.09 1306.14C1253.82 1306.3 1252.82 1305.12 1252.73 1304.55C1252.65 1304.03 1253 1303.96 1253.38 1303.95C1254.15 1303.92 1254.96 1304.43 1255.01 1305L1255.02 1304.99Z" fill="white"/>
                    <path d="M1083.49 1261.47C1083.56 1261.19 1083.73 1260.91 1084.04 1261.04C1084.76 1261.36 1085.56 1261.5 1086.16 1262.08C1086.41 1262.32 1086.46 1262.63 1086.36 1262.82C1086.27 1263 1085.86 1263.2 1085.7 1263.14C1085.05 1262.87 1084.45 1262.52 1083.83 1262.17C1083.57 1262.02 1083.46 1261.77 1083.49 1261.46V1261.47Z" fill="white"/>
                    <path d="M1056.6 1275.82C1056.89 1275.78 1057.26 1275.71 1057.39 1276.19C1057.5 1276.61 1057.17 1276.82 1056.99 1277.12C1056.62 1277.72 1056.02 1277.58 1055.53 1277.54C1055.24 1277.52 1054.72 1277.06 1054.76 1276.88C1054.83 1276.52 1055.14 1276.12 1055.47 1275.92C1055.74 1275.75 1056.18 1275.84 1056.61 1275.81L1056.6 1275.82Z" fill="white"/>
                    <path d="M1079.42 1123.87C1079.32 1123.42 1079.47 1123.1 1080.09 1123.1C1080.87 1123.1 1081.65 1123.58 1081.7 1124.13C1081.78 1125.06 1080.57 1124.5 1080.4 1125.23C1080.37 1125.35 1079.52 1124.58 1079.42 1123.86V1123.87Z" fill="white"/>
                    <path d="M1166.84 1274.15C1166.98 1274.24 1167.21 1274.31 1167.24 1274.44C1167.46 1275.14 1166.54 1276.47 1165.84 1276.46C1165.6 1276.46 1165.25 1276.38 1165.15 1276.22C1165.05 1276.05 1165.13 1275.69 1165.26 1275.49C1165.82 1274.62 1166.37 1274.18 1166.83 1274.15H1166.84Z" fill="white"/>
                    <path d="M1260.59 1227.44C1260.57 1227.17 1260.51 1226.88 1260.54 1226.62C1260.64 1225.93 1261.22 1226.2 1261.62 1226.13C1262.14 1226.03 1262.21 1226.4 1262.23 1226.78C1262.25 1227.13 1262.34 1227.48 1262.03 1227.79C1261.71 1228.11 1261.46 1228.58 1260.93 1228.39C1260.52 1228.24 1260.63 1227.79 1260.59 1227.44Z" fill="white"/>
                    <path d="M1086.12 1259.77C1085.54 1259.69 1085.57 1259.3 1085.58 1258.95C1085.61 1258.11 1086.16 1257.41 1086.75 1257.47C1087.24 1257.52 1087.23 1257.92 1087.22 1258.28C1087.19 1259.12 1086.64 1259.8 1086.12 1259.77Z" fill="white"/>
                    <path d="M1071.53 1123.97C1071.98 1123.5 1072.28 1122.95 1073.07 1122.98C1073.72 1123.01 1073.76 1123.33 1073.81 1123.82C1073.86 1124.35 1073.76 1124.6 1073.16 1124.72C1072.36 1124.87 1072.05 1124.29 1071.54 1123.97H1071.53Z" fill="white"/>
                    <path d="M1084.91 1119.4C1085.06 1119.48 1085.31 1119.55 1085.34 1119.66C1085.42 1120.01 1085.55 1120.46 1085.4 1120.73C1085.16 1121.16 1084.89 1121.73 1084.24 1121.7C1083.73 1121.67 1083.74 1121.27 1083.76 1120.91C1083.8 1120.06 1084.31 1119.41 1084.92 1119.41L1084.91 1119.4Z" fill="white"/>
                    <path d="M1274.08 1263.08C1274.46 1263.07 1274.87 1263.06 1274.82 1263.58C1274.75 1264.26 1274.36 1264.8 1273.67 1264.76C1273.3 1264.74 1272.96 1264.28 1272.61 1264.03C1273.03 1263.59 1273.31 1262.94 1274.08 1263.08Z" fill="white"/>
                    <path d="M1223.81 1123.55C1223.81 1124.22 1223.08 1124.97 1222.62 1124.92C1222.13 1124.87 1222.19 1124.48 1222.14 1124.12C1222.05 1123.42 1222.4 1122.93 1223 1122.79C1223.18 1122.75 1223.78 1123.18 1223.81 1123.55Z" fill="white"/>
                    <path d="M1093.26 1209.09C1093.73 1209.06 1094.22 1209.05 1094.11 1209.54C1093.97 1210.14 1093.61 1210.7 1093.34 1211.27C1092.83 1210.88 1092.4 1210.5 1092.44 1209.73C1092.48 1209 1092.96 1209.15 1093.27 1209.09H1093.26Z" fill="white"/>
                    <path d="M1163.02 1087.16C1163.78 1087.02 1163.85 1087.39 1163.93 1087.92C1164.05 1088.74 1163.62 1088.81 1162.99 1088.85C1162.35 1088.9 1162.27 1088.59 1162.2 1088.05C1162.1 1087.26 1162.45 1087.05 1163.01 1087.16H1163.02Z" fill="white"/>
                    <path d="M1087.48 1219.57C1087.42 1220.06 1087.85 1220.83 1086.9 1220.76C1086.38 1220.72 1085.91 1220.33 1085.8 1219.76C1085.63 1218.8 1086.68 1219.01 1087.08 1218.73C1087.58 1218.38 1087.26 1219.31 1087.47 1219.58L1087.48 1219.57Z" fill="white"/>
                    <path d="M1045.87 1291.2C1046.14 1291.18 1046.42 1291.12 1046.69 1291.16C1047.18 1291.22 1047.15 1291.62 1047.15 1291.97C1047.15 1292.23 1047.31 1292.53 1047.01 1292.72C1046.67 1292.93 1046.35 1292.9 1046.06 1292.6C1045.93 1292.47 1045.78 1292.36 1045.65 1292.24C1045.4 1292 1044.91 1291.71 1044.94 1291.53C1045.02 1291.12 1045.52 1291.27 1045.86 1291.21L1045.87 1291.2Z" fill="white"/>
                    <path d="M1268.6 1152.74C1268.75 1152.09 1269.3 1152.31 1269.7 1152.27C1270.44 1152.19 1270.26 1152.8 1270.31 1153.2C1270.36 1153.57 1270.35 1153.96 1269.81 1153.94C1269.26 1153.92 1268.56 1153.22 1268.59 1152.74H1268.6Z" fill="white"/>
                    <path d="M1120.54 1156.82C1120.55 1157.31 1120.57 1157.69 1120.03 1157.67C1119.48 1157.65 1118.76 1156.95 1118.82 1156.47C1118.88 1155.98 1119.3 1156.07 1119.64 1156C1120.48 1155.82 1120.55 1156.33 1120.54 1156.82Z" fill="white"/>
                    <path d="M1065.21 1250.11C1065.25 1249.92 1065.22 1249.53 1065.37 1249.45C1065.76 1249.23 1065.98 1249.69 1066.13 1249.91C1066.35 1250.23 1067.38 1250.26 1066.62 1251.02C1066.37 1251.27 1066.17 1251.62 1065.76 1251.53C1064.92 1251.35 1065.47 1250.62 1065.22 1250.12L1065.21 1250.11Z" fill="white"/>
                    <path d="M1193.35 1317.52C1193.72 1317.75 1194.5 1317.95 1194.19 1318.43C1193.92 1318.84 1194.23 1319.56 1193.58 1319.66C1193.19 1319.72 1192.54 1318.4 1192.7 1317.89C1192.79 1317.62 1193.02 1317.49 1193.35 1317.53V1317.52Z" fill="white"/>
                    <path d="M1166.13 1104.34C1166.13 1104.34 1166.53 1104.04 1166.93 1103.83C1167.1 1103.74 1167.9 1104.75 1167.86 1105.01C1167.76 1105.7 1167.19 1105.43 1166.8 1105.5C1166.32 1105.59 1166.18 1105.29 1166.14 1104.34H1166.13Z" fill="white"/>
                    <path d="M1199.15 1106.74C1199.8 1106.88 1199.55 1107.43 1199.63 1107.83C1199.72 1108.33 1199.35 1108.53 1199 1108.42C1198.6 1108.29 1198.25 1107.96 1197.94 1107.66C1197.83 1107.55 1198.9 1106.72 1199.15 1106.75V1106.74Z" fill="white"/>
                    <path d="M1310.25 1236.86C1310.38 1237.34 1310.17 1237.55 1309.9 1237.56C1309.18 1237.6 1308.77 1237.17 1308.57 1236.52C1308.47 1236.19 1308.69 1235.98 1308.96 1235.95C1309.7 1235.88 1310.06 1236.36 1310.25 1236.85V1236.86Z" fill="white"/>
                    <path d="M1156.63 1292.93C1156.68 1292.33 1157.03 1291.87 1157.5 1291.84C1157.74 1291.82 1158.3 1292.22 1158.32 1292.77C1158.35 1293.51 1157.78 1293.32 1157.38 1293.38C1157.01 1293.43 1156.66 1293.4 1156.62 1292.93H1156.63Z" fill="white"/>
                    <path d="M1286.31 1147.64C1286.36 1147.9 1285.68 1149.02 1285.55 1148.92C1285.21 1148.65 1284.85 1148.34 1284.67 1147.97C1284.5 1147.62 1284.71 1147.26 1285.19 1147.29C1285.59 1147.31 1286.11 1146.98 1286.31 1147.64Z" fill="white"/>
                    <path d="M1151.26 1294.95C1151.77 1295.08 1152.29 1295.37 1152.32 1296.11C1152.33 1296.38 1152.15 1296.63 1151.81 1296.57C1151.14 1296.46 1150.66 1296.1 1150.61 1295.39C1150.59 1295.12 1150.77 1294.88 1151.26 1294.95Z" fill="white"/>
                    <path d="M1141.69 1357.03C1142.2 1356.97 1142.41 1357.31 1142.29 1357.66C1142.15 1358.06 1141.83 1358.41 1141.53 1358.72C1141.42 1358.83 1140.59 1357.76 1140.62 1357.51C1140.67 1357.16 1141.08 1356.97 1141.69 1357.03Z" fill="white"/>
                    <path d="M1091.68 1217.65C1092.07 1217.63 1092.46 1217.61 1092.45 1218.14C1092.45 1218.71 1092.07 1219.17 1091.59 1219.2C1091.35 1219.21 1090.79 1218.82 1090.77 1218.27C1090.74 1217.53 1091.32 1217.74 1091.68 1217.65Z" fill="white"/>
                    <path d="M1073.57 1304.66C1074.05 1304.87 1074.47 1305.12 1074.58 1305.68C1074.64 1306.01 1074.44 1306.24 1074.17 1306.22C1073.72 1306.19 1073.04 1306.67 1072.9 1305.77C1072.81 1305.22 1073.38 1305.06 1073.58 1304.65L1073.57 1304.66Z" fill="white"/>
                    <path d="M1101.58 1226.02C1101.72 1226.11 1101.86 1226.2 1102 1226.29C1101.48 1226.91 1100.85 1227.45 1100.72 1228.33C1100.72 1228.37 1100.36 1228.4 1100.19 1228.35C1100.1 1228.32 1099.98 1228.02 1100.01 1228C1100.56 1227.49 1100.37 1226.56 1101.07 1226.16C1101.22 1226.08 1101.41 1226.07 1101.58 1226.02Z" fill="white"/>
                    <path d="M1102.83 1230.22C1102.83 1230.77 1102.1 1230.52 1101.67 1230.51C1100.82 1230.48 1101.21 1229.72 1101.11 1229.28C1101.08 1229.15 1101.35 1228.62 1101.53 1229.08C1101.8 1229.75 1102.89 1228.86 1102.83 1230.23V1230.22Z" fill="white"/>
                    <path d="M1275.72 1208.99C1275.57 1208.33 1276.19 1208.1 1276.46 1207.67C1276.73 1207.89 1277.21 1208.11 1277.22 1208.34C1277.24 1208.85 1276.85 1209.21 1276.32 1209.35C1276 1209.43 1275.84 1209.25 1275.72 1208.99Z" fill="white"/>
                    <path d="M1161.95 1109.37C1161.92 1109.77 1162.19 1110.31 1161.44 1110.38C1160.9 1110.43 1160.85 1110.08 1160.84 1109.7C1160.84 1109.29 1160.59 1108.75 1161.34 1108.68C1161.88 1108.63 1161.91 1108.99 1161.95 1109.37Z" fill="white"/>
                    <path d="M1279.27 1283.69C1280.07 1284.15 1280.28 1284.7 1280.21 1285.37C1280.19 1285.53 1280.05 1285.67 1279.97 1285.82C1279.83 1285.7 1279.71 1285.55 1279.56 1285.47C1278.96 1285.15 1279.02 1284.58 1279.03 1284.06C1279.03 1283.9 1279.25 1283.74 1279.28 1283.69H1279.27Z" fill="white"/>
                    <path d="M1164.94 1080.79C1165.08 1080.77 1165.17 1081.12 1165.17 1081.5C1165.17 1081.91 1165.44 1082.45 1164.69 1082.53C1164.16 1082.58 1164.08 1082.25 1164.08 1081.86C1164.08 1081.45 1163.84 1080.91 1164.95 1080.79H1164.94Z" fill="white"/>
                    <path d="M1140.44 1135.11C1140.38 1135.22 1140.33 1135.44 1140.24 1135.46C1139.62 1135.56 1139.01 1135.64 1138.53 1135.04C1138.33 1134.79 1138.25 1134.62 1138.53 1134.47C1138.97 1134.23 1140.29 1134.69 1140.44 1135.11Z" fill="white"/>
                    <path d="M1241.04 1177.3C1241.92 1177.47 1241.41 1178.19 1241.53 1178.65C1241.58 1178.86 1241.18 1179.04 1240.9 1178.98C1240.08 1178.8 1240.47 1178.1 1240.4 1177.63C1240.37 1177.41 1240.73 1177.25 1241.04 1177.3Z" fill="white"/>
                    <path d="M1134.19 1199.05C1135.07 1199.22 1134.56 1199.94 1134.68 1200.4C1134.73 1200.61 1134.33 1200.79 1134.05 1200.73C1133.23 1200.55 1133.62 1199.85 1133.55 1199.38C1133.52 1199.16 1133.88 1199 1134.19 1199.05Z" fill="white"/>
                    <path d="M1209.91 1299.46C1210.05 1299.44 1210.14 1299.79 1210.14 1300.17C1210.14 1300.58 1210.41 1301.12 1209.66 1301.2C1209.13 1301.25 1209.05 1300.92 1209.05 1300.53C1209.05 1300.12 1208.81 1299.58 1209.92 1299.46H1209.91Z" fill="white"/>
                    <path d="M1060.4 1192.84C1059.91 1193.15 1059.4 1193.48 1058.88 1193.79C1058.86 1193.8 1058.71 1193.69 1058.69 1193.62C1058.59 1193.24 1058.7 1192.95 1058.98 1192.66C1059.3 1192.34 1059.57 1191.96 1060.08 1192.22C1060.2 1192.28 1060.24 1192.51 1060.4 1192.84Z" fill="white"/>
                    <path d="M1081.5 1240.04C1081.45 1239.67 1081.37 1239.27 1081.87 1239.18C1082.52 1239.06 1082.61 1239.46 1082.62 1239.98C1082.62 1240.34 1082.74 1240.74 1082.23 1240.84C1081.59 1240.96 1081.45 1240.56 1081.49 1240.05L1081.5 1240.04Z" fill="white"/>
                    <path d="M1116 1285.35C1116.5 1285.47 1116.75 1285.58 1116.69 1285.93C1116.58 1286.6 1116.22 1287.07 1115.51 1287.13C1115.37 1287.14 1115.21 1286.98 1115.07 1286.89C1115.4 1286.36 1115.72 1285.82 1116.01 1285.35H1116Z" fill="white"/>
                    <path d="M1119.06 1173.57C1119.55 1173.47 1119.94 1173.55 1119.94 1173.78C1119.94 1174.25 1120.42 1174.9 1119.63 1175.18C1119.36 1175.28 1118.97 1175.18 1118.95 1174.93C1118.91 1174.45 1118.6 1173.86 1119.06 1173.57Z" fill="white"/>
                    <path d="M1226.26 1290.5C1226.65 1290.5 1227 1290.47 1227.02 1291.01C1227.05 1291.76 1226.48 1291.59 1226.08 1291.64C1225.71 1291.69 1225.35 1291.68 1225.33 1291.14C1225.3 1290.39 1225.87 1290.59 1226.26 1290.5Z" fill="white"/>
                    <path d="M1220.79 1205.57C1220.96 1204.69 1221.68 1205.2 1222.14 1205.08C1222.35 1205.03 1222.53 1205.43 1222.47 1205.71C1222.29 1206.53 1221.59 1206.14 1221.12 1206.21C1220.9 1206.24 1220.74 1205.88 1220.79 1205.57Z" fill="white"/>
                    <path d="M1106.12 1288.04C1106.07 1287.67 1105.99 1287.27 1106.49 1287.18C1107.14 1287.06 1107.23 1287.46 1107.24 1287.98C1107.24 1288.34 1107.36 1288.74 1106.85 1288.84C1106.21 1288.96 1106.07 1288.56 1106.11 1288.05L1106.12 1288.04Z" fill="white"/>
                    <path d="M1082.35 1133.38C1082.25 1132.74 1082.43 1132.57 1082.72 1132.52C1083.37 1132.41 1083.46 1132.84 1083.44 1133.33C1083.44 1133.59 1083.53 1134.1 1083.31 1134.03C1082.88 1133.9 1082.52 1133.5 1082.35 1133.37V1133.38Z" fill="white"/>
                    <path d="M1258.04 1274.84C1258.18 1274.9 1258.41 1274.99 1258.65 1275.08C1258.3 1275.45 1258.12 1276.05 1257.47 1276.02C1257.19 1276 1257.01 1275.85 1257 1275.5C1256.98 1274.77 1257.52 1274.94 1258.05 1274.84H1258.04Z" fill="white"/>
                    <path d="M1059.09 1318.61C1059.16 1318.85 1058.96 1319.05 1058.69 1319.11C1058.33 1319.18 1057.92 1319.16 1057.96 1318.63C1057.99 1318.26 1057.59 1317.65 1058.24 1317.59C1058.47 1317.57 1059.04 1317.99 1059.1 1318.61H1059.09Z" fill="white"/>
                    <path d="M1042.41 1145.9C1043.06 1145.78 1043.29 1146.39 1043.71 1146.67C1043.48 1146.78 1043.26 1146.95 1043.02 1146.97C1042.62 1147 1042.09 1147.24 1042 1146.5C1041.96 1146.17 1042.12 1146.01 1042.4 1145.9H1042.41Z" fill="white"/>
                    <path d="M1090.29 1313.35C1090.4 1313.72 1090.39 1313.32 1090.1 1313.54C1089.95 1313.66 1089.54 1313.69 1089.4 1313.57C1089.01 1313.25 1088.42 1313.03 1088.56 1312.34C1088.58 1312.26 1088.68 1312.14 1088.72 1312.14C1089.43 1312.29 1089.75 1312.98 1090.29 1313.34V1313.35Z" fill="white"/>
                    <path d="M1128.94 1277.88C1129.3 1278.25 1129.93 1278.48 1129.74 1279.14C1129.7 1279.3 1129.3 1279.47 1129.08 1279.45C1128.41 1279.37 1128.68 1278.78 1128.6 1278.38C1128.54 1278.11 1128.6 1277.89 1128.94 1277.88Z" fill="white"/>
                    <path d="M1175.6 1200.81C1175.78 1200.8 1175.98 1200.74 1176.15 1200.79C1176.26 1200.82 1176.42 1201.04 1176.39 1201.12C1176.29 1201.41 1174.57 1201.55 1174.37 1201.27C1174.32 1201.21 1174.44 1200.91 1174.51 1200.9C1174.87 1200.84 1175.24 1200.83 1175.61 1200.81H1175.6Z" fill="white"/>
                    <path d="M1181.44 1353.27C1182.01 1353.2 1182.12 1353.53 1182.11 1353.92C1182.1 1354.2 1181.94 1354.38 1181.59 1354.4C1181.07 1354.43 1180.96 1354.12 1180.99 1353.74C1181.01 1353.47 1181.13 1353.2 1181.44 1353.28V1353.27Z" fill="white"/>
                    <path d="M1058.42 1136.95C1058.72 1137.02 1058.89 1137.13 1058.91 1137.48C1058.94 1138 1058.63 1138.1 1058.24 1138.08C1057.96 1138.07 1057.78 1137.91 1057.76 1137.56C1057.73 1137.04 1058.04 1136.97 1058.41 1136.94L1058.42 1136.95Z" fill="white"/>
                    <path d="M1264.24 1153.18C1264.54 1153.25 1264.71 1153.36 1264.73 1153.71C1264.76 1154.23 1264.45 1154.33 1264.06 1154.31C1263.78 1154.3 1263.6 1154.14 1263.58 1153.79C1263.55 1153.27 1263.86 1153.2 1264.23 1153.17L1264.24 1153.18Z" fill="white"/>
                    <path d="M1282.39 1258.49C1282.41 1258.84 1282.39 1259.16 1281.86 1259.2C1281.51 1259.23 1281.34 1259.07 1281.29 1258.79C1281.22 1258.41 1281.29 1258.09 1281.8 1258.05C1282.14 1258.03 1282.29 1258.18 1282.39 1258.49Z" fill="white"/>
                    <path d="M1147.33 1091.94C1147.51 1091.45 1147.78 1091.05 1148.32 1090.95C1148.46 1090.92 1148.77 1091.07 1148.77 1091.08C1148.65 1091.59 1148.37 1091.98 1147.83 1092.08C1147.69 1092.11 1147.52 1091.99 1147.33 1091.93V1091.94Z" fill="white"/>
                    <path d="M1242.97 1123.01C1243.29 1122.92 1243.47 1123.13 1243.52 1123.41C1243.59 1123.79 1243.52 1124.11 1243.01 1124.15C1242.66 1124.18 1242.49 1124.02 1242.44 1123.74C1242.37 1123.36 1242.44 1123.01 1242.97 1123.01Z" fill="white"/>
                    <path d="M1135.72 1154.38C1136.02 1154.45 1136.19 1154.56 1136.21 1154.91C1136.24 1155.43 1135.93 1155.53 1135.54 1155.51C1135.26 1155.5 1135.08 1155.34 1135.06 1154.99C1135.03 1154.47 1135.34 1154.4 1135.71 1154.37L1135.72 1154.38Z" fill="white"/>
                    <path d="M1119.62 1346.07C1119.97 1346.15 1120.14 1346.26 1120.16 1346.61C1120.19 1347.13 1119.88 1347.23 1119.49 1347.21C1119.21 1347.2 1119.03 1347.04 1119.01 1346.7C1118.97 1346.17 1119.32 1346.14 1119.61 1346.07H1119.62Z" fill="white"/>
                    <path d="M1113.57 1323.9C1113.64 1324.43 1113.3 1324.54 1112.92 1324.53C1112.64 1324.52 1112.46 1324.36 1112.44 1324.01C1112.41 1323.49 1112.72 1323.39 1113.11 1323.41C1113.39 1323.42 1113.62 1323.57 1113.58 1323.9H1113.57Z" fill="white"/>
                    <path d="M1175.48 1064.89C1175.12 1064.9 1174.74 1064.91 1174.75 1064.39C1174.75 1064.23 1174.84 1064.07 1174.88 1063.91C1175.21 1064.13 1175.55 1064.36 1175.88 1064.58C1175.75 1064.68 1175.61 1064.79 1175.47 1064.89H1175.48Z" fill="white"/>
                    <path d="M1196.88 1110.84C1197.24 1110.83 1197.62 1110.82 1197.61 1111.34C1197.61 1111.5 1197.52 1111.66 1197.48 1111.82C1197.15 1111.6 1196.81 1111.37 1196.48 1111.15C1196.61 1111.05 1196.75 1110.94 1196.89 1110.84H1196.88Z" fill="white"/>
                    <path d="M1073.72 1151.82C1074.01 1152.06 1074.29 1152.29 1074.56 1152.52C1074.33 1152.63 1074.11 1152.82 1073.88 1152.82C1073.39 1152.82 1073.38 1152.4 1073.42 1152.05C1073.42 1151.95 1073.63 1151.88 1073.72 1151.81V1151.82Z" fill="white"/>
                    <path d="M1221.33 1213.51C1221.27 1213.72 1221.23 1214.01 1221.17 1214.02C1220.68 1214.07 1220.53 1213.56 1220.18 1213.36C1220.32 1213.26 1220.44 1213.1 1220.59 1213.07C1220.97 1213 1221.3 1213.07 1221.33 1213.51Z" fill="white"/>
                    <path d="M1307.96 1232.7C1308.08 1232.77 1308.29 1232.83 1308.31 1232.92C1308.39 1233.27 1308.44 1233.68 1307.95 1233.74C1307.73 1233.77 1307.48 1233.6 1307.24 1233.52C1307.48 1233.25 1307.71 1232.98 1307.96 1232.7Z" fill="white"/>
                    <path d="M1284.72 1217.23C1284.66 1216.72 1285.02 1216.65 1285.39 1216.66C1285.54 1216.66 1285.68 1216.82 1285.83 1216.9C1285.53 1217.16 1285.23 1217.43 1284.93 1217.69C1284.86 1217.54 1284.8 1217.39 1284.72 1217.22V1217.23Z" fill="white"/>
                    <path d="M1095.06 1160.01C1095.13 1160.04 1095.34 1160.09 1095.36 1160.18C1095.44 1160.52 1095.5 1160.94 1095.01 1161.01C1094.79 1161.04 1094.54 1160.88 1094.3 1160.8C1094.55 1160.54 1094.79 1160.28 1095.06 1160V1160.01Z" fill="white"/>
                    <path d="M1131.43 1159.18C1131.94 1159.12 1132.01 1159.48 1132 1159.85C1132 1160 1131.84 1160.14 1131.76 1160.29C1131.5 1159.99 1131.23 1159.69 1130.97 1159.39C1131.12 1159.32 1131.27 1159.26 1131.44 1159.18H1131.43Z" fill="white"/>
                    <path d="M1291.96 1171.65C1292.12 1171.73 1292.26 1171.81 1292.41 1171.88C1292.12 1172.16 1291.84 1172.44 1291.55 1172.71C1291.48 1172.56 1291.36 1172.42 1291.34 1172.26C1291.25 1171.77 1291.58 1171.67 1291.96 1171.64V1171.65Z" fill="white"/>
                    <path d="M1130.01 1316.61C1129.95 1316.1 1130.31 1316.03 1130.68 1316.04C1130.83 1316.04 1130.97 1316.2 1131.12 1316.28C1130.82 1316.54 1130.52 1316.81 1130.22 1317.07C1130.15 1316.92 1130.09 1316.77 1130.01 1316.6V1316.61Z" fill="white"/>
                    <path d="M1134.77 1354.97C1134.96 1354.84 1135.09 1354.68 1135.24 1354.66C1135.63 1354.59 1135.96 1354.68 1135.94 1355.18C1135.94 1355.34 1135.84 1355.5 1135.79 1355.65C1135.46 1355.43 1135.13 1355.21 1134.77 1354.97Z" fill="white"/>
                    <path d="M1023.12 1179.42C1023.46 1179.59 1023.36 1179.9 1023.36 1180.15C1023.36 1180.23 1023.11 1180.4 1023.03 1180.37C1022.7 1180.25 1022.8 1179.92 1022.8 1179.66C1022.8 1179.58 1023.01 1179.49 1023.12 1179.42Z" fill="white"/>
                    <path d="M1043.18 1165.27C1043.47 1165.16 1043.7 1165.03 1043.94 1165.01C1044.08 1165 1044.23 1165.16 1044.38 1165.24C1044.15 1165.35 1043.94 1165.51 1043.7 1165.55C1043.57 1165.57 1043.41 1165.4 1043.18 1165.27Z" fill="white"/>
                    <path d="M1230.88 1326.95C1231.22 1327.12 1231.12 1327.43 1231.12 1327.68C1231.12 1327.76 1230.87 1327.93 1230.79 1327.9C1230.46 1327.78 1230.56 1327.45 1230.56 1327.19C1230.56 1327.11 1230.77 1327.02 1230.88 1326.95Z" fill="white"/>
                    <path d="M1292.95 1242.58C1293.06 1242.87 1293.19 1243.1 1293.21 1243.34C1293.22 1243.48 1293.06 1243.63 1292.98 1243.78C1292.87 1243.55 1292.71 1243.34 1292.67 1243.1C1292.65 1242.97 1292.82 1242.81 1292.95 1242.58Z" fill="white"/>
                    <path d="M1292.38 1239.6C1292.38 1239.75 1292.42 1240 1292.36 1240.24C1292.29 1240.51 1292.09 1240.53 1292 1240.26C1291.92 1240.03 1291.9 1239.76 1291.95 1239.53C1292.01 1239.25 1292.18 1239.25 1292.38 1239.6Z" fill="white"/>
                    <path d="M1246.73 1333.13C1246.78 1332.97 1246.81 1332.7 1246.9 1332.68C1247.23 1332.6 1247.32 1332.84 1247.29 1333.12C1247.27 1333.28 1247.22 1333.55 1247.13 1333.58C1246.81 1333.66 1246.73 1333.42 1246.73 1333.14V1333.13Z" fill="white"/>
                    <path d="M1063.63 1140.55C1063.79 1140.6 1064.06 1140.63 1064.08 1140.72C1064.16 1141.05 1063.92 1141.14 1063.64 1141.11C1063.48 1141.09 1063.21 1141.04 1063.18 1140.95C1063.1 1140.63 1063.34 1140.55 1063.62 1140.55H1063.63Z" fill="white"/>
                    <path d="M1282.51 1168C1282.8 1167.89 1283.03 1167.76 1283.27 1167.74C1283.41 1167.73 1283.56 1167.89 1283.71 1167.97C1283.48 1168.08 1283.27 1168.24 1283.03 1168.28C1282.9 1168.3 1282.74 1168.13 1282.51 1168Z" fill="white"/>
                    <path d="M1069.29 1291.36C1069.4 1291.42 1069.55 1291.51 1069.69 1291.59C1069.46 1291.7 1069.25 1291.86 1069.01 1291.9C1068.88 1291.92 1068.72 1291.75 1068.57 1291.67C1068.73 1291.43 1068.95 1291.31 1069.29 1291.37V1291.36Z" fill="white"/>
                    <path d="M1277.49 1125.22C1277.53 1125.49 1277.49 1125.74 1277.15 1125.71C1277.06 1125.71 1276.97 1125.44 1276.93 1125.28C1276.87 1125.01 1276.93 1124.76 1277.26 1124.8C1277.35 1124.8 1277.41 1125.08 1277.48 1125.23L1277.49 1125.22Z" fill="white"/>
                    <path d="M1107.37 1289.93C1107.41 1290.2 1107.37 1290.45 1107.03 1290.42C1106.94 1290.42 1106.85 1290.15 1106.81 1289.99C1106.75 1289.72 1106.81 1289.47 1107.14 1289.51C1107.23 1289.51 1107.29 1289.79 1107.36 1289.94L1107.37 1289.93Z" fill="white"/>
                    <path d="M1115.23 1280.95C1115.27 1281.22 1115.23 1281.47 1114.89 1281.44C1114.8 1281.44 1114.71 1281.17 1114.67 1281.01C1114.61 1280.74 1114.67 1280.49 1115 1280.53C1115.09 1280.53 1115.15 1280.81 1115.22 1280.96L1115.23 1280.95Z" fill="white"/>
                    <path d="M1139.22 1244.06C1139.51 1243.95 1139.74 1243.82 1139.98 1243.8C1140.12 1243.79 1140.27 1243.95 1140.42 1244.03C1140.19 1244.14 1139.98 1244.3 1139.74 1244.34C1139.61 1244.36 1139.45 1244.19 1139.22 1244.06Z" fill="white"/>
                    <path d="M1086.04 1156.51C1086.23 1156.59 1086.46 1156.69 1086.69 1156.79C1086.55 1156.89 1086.41 1157.09 1086.28 1157.08C1086.04 1157.07 1085.8 1156.94 1085.56 1156.86C1085.7 1156.76 1085.83 1156.65 1086.03 1156.5L1086.04 1156.51Z" fill="white"/>
                    <path d="M1173.77 1174.44C1173.88 1174.5 1174.03 1174.59 1174.17 1174.67C1173.94 1174.78 1173.73 1174.94 1173.49 1174.98C1173.36 1175 1173.2 1174.83 1173.05 1174.75C1173.21 1174.51 1173.43 1174.39 1173.77 1174.45V1174.44Z" fill="white"/>
                    <path d="M1177.96 1130.94C1178.25 1130.83 1178.48 1130.7 1178.72 1130.68C1178.86 1130.67 1179.01 1130.83 1179.16 1130.91C1178.93 1131.02 1178.72 1131.18 1178.48 1131.22C1178.35 1131.24 1178.19 1131.07 1177.96 1130.94Z" fill="white"/>
                    <path d="M1184.02 1062.71C1184.36 1062.88 1184.26 1063.19 1184.26 1063.44C1184.26 1063.52 1184.01 1063.69 1183.93 1063.66C1183.6 1063.54 1183.7 1063.21 1183.7 1062.95C1183.7 1062.87 1183.91 1062.78 1184.02 1062.71Z" fill="white"/>
                    <path d="M1214.83 1115.85C1214.87 1116.12 1214.83 1116.37 1214.49 1116.34C1214.4 1116.34 1214.31 1116.07 1214.27 1115.91C1214.21 1115.64 1214.27 1115.39 1214.6 1115.43C1214.69 1115.43 1214.75 1115.71 1214.82 1115.86L1214.83 1115.85Z" fill="white"/>
                    <path d="M1219.48 1313.51C1219.53 1313.35 1219.56 1313.08 1219.65 1313.06C1219.98 1312.98 1220.07 1313.22 1220.04 1313.5C1220.02 1313.66 1219.97 1313.93 1219.88 1313.96C1219.56 1314.04 1219.48 1313.8 1219.48 1313.52V1313.51Z" fill="white"/>
                    <path d="M1198.89 1224.08C1199.05 1224.13 1199.32 1224.16 1199.34 1224.25C1199.42 1224.58 1199.18 1224.67 1198.9 1224.64C1198.74 1224.62 1198.47 1224.57 1198.44 1224.48C1198.36 1224.16 1198.6 1224.08 1198.88 1224.08H1198.89Z" fill="white"/>
                    <path d="M1213.43 1222.27C1213.55 1222.2 1213.67 1222.11 1213.8 1222.07C1213.85 1222.06 1213.93 1222.18 1214 1222.23C1213.88 1222.3 1213.76 1222.39 1213.63 1222.43C1213.58 1222.44 1213.5 1222.32 1213.43 1222.27Z" fill="white"/>
                    <path d="M1301.2 1167.94C1301.32 1167.87 1301.44 1167.78 1301.57 1167.74C1301.62 1167.73 1301.7 1167.85 1301.77 1167.9C1301.65 1167.97 1301.53 1168.06 1301.4 1168.1C1301.35 1168.11 1301.27 1167.99 1301.2 1167.94Z" fill="white"/>
                    <path d="M1117.5 1269.61C1117.62 1269.67 1117.75 1269.73 1117.88 1269.79C1117.82 1269.86 1117.75 1269.99 1117.71 1269.98C1117.58 1269.96 1117.45 1269.88 1117.32 1269.83C1117.38 1269.76 1117.44 1269.7 1117.51 1269.62L1117.5 1269.61Z" fill="white"/>
                    <path d="M1107.37 1312.42C1107.29 1312.35 1107.22 1312.29 1107.16 1312.23C1107.28 1312.16 1107.4 1312.07 1107.53 1312.03C1107.58 1312.02 1107.66 1312.14 1107.73 1312.19C1107.61 1312.27 1107.49 1312.34 1107.38 1312.41L1107.37 1312.42Z" fill="white"/>
                    <path d="M1100.55 1230.8C1100.49 1230.92 1100.43 1231.05 1100.37 1231.18C1100.3 1231.12 1100.17 1231.05 1100.18 1231.01C1100.2 1230.88 1100.28 1230.75 1100.33 1230.62C1100.4 1230.68 1100.46 1230.74 1100.54 1230.81L1100.55 1230.8Z" fill="white"/>
                    <path d="M1130.37 1198.73C1130.44 1198.85 1130.53 1198.97 1130.57 1199.1C1130.58 1199.15 1130.46 1199.23 1130.41 1199.3C1130.34 1199.18 1130.25 1199.06 1130.21 1198.93C1130.2 1198.88 1130.32 1198.8 1130.37 1198.73Z" fill="white"/>
                    <path d="M1132.03 1152.73C1131.96 1152.81 1131.9 1152.88 1131.84 1152.94C1131.77 1152.82 1131.68 1152.7 1131.64 1152.57C1131.63 1152.52 1131.75 1152.44 1131.8 1152.37C1131.88 1152.49 1131.95 1152.61 1132.02 1152.72L1132.03 1152.73Z" fill="white"/>
                    <path d="M1077.19 1138.04C1077.31 1138.1 1077.44 1138.16 1077.56 1138.22C1077.5 1138.29 1077.43 1138.42 1077.39 1138.41C1077.26 1138.39 1077.13 1138.31 1077 1138.26C1077.06 1138.19 1077.12 1138.13 1077.19 1138.05V1138.04Z" fill="white"/>
                    <path d="M1099.96 1249.34C1100.04 1249.41 1100.11 1249.47 1100.17 1249.53C1100.05 1249.6 1099.93 1249.69 1099.8 1249.73C1099.75 1249.74 1099.67 1249.62 1099.6 1249.57C1099.72 1249.49 1099.84 1249.42 1099.95 1249.35L1099.96 1249.34Z" fill="white"/>
                    <path d="M1158.05 1209.22C1157.97 1209.15 1157.9 1209.09 1157.84 1209.03C1157.96 1208.96 1158.08 1208.87 1158.21 1208.83C1158.26 1208.82 1158.34 1208.94 1158.41 1208.99C1158.29 1209.07 1158.17 1209.14 1158.06 1209.21L1158.05 1209.22Z" fill="white"/>
                    <path d="M1160.13 1072.91C1160.19 1072.84 1160.27 1072.72 1160.3 1072.73C1160.43 1072.79 1160.58 1072.85 1160.65 1072.96C1160.78 1073.19 1160.67 1073.31 1160.43 1073.21C1160.31 1073.16 1160.23 1073.01 1160.14 1072.91H1160.13Z" fill="white"/>
                    <path d="M1160.92 1073.18C1161.01 1073.29 1161.2 1073.41 1161.18 1073.49C1161.13 1073.71 1160.96 1073.76 1160.86 1073.62C1160.77 1073.5 1160.73 1073.34 1160.67 1073.2C1160.75 1073.2 1160.84 1073.19 1160.92 1073.18Z" fill="white"/>
                    <path d="M1228.77 1246.43C1228.89 1246.49 1229.02 1246.55 1229.14 1246.61C1229.08 1246.68 1229.01 1246.81 1228.97 1246.8C1228.84 1246.78 1228.71 1246.7 1228.58 1246.65C1228.64 1246.58 1228.7 1246.52 1228.77 1246.44V1246.43Z" fill="white"/>
                    <path d="M1078.89 1078.71C1079.01 1078.77 1079.14 1078.83 1079.27 1078.89C1079.21 1078.96 1079.14 1079.09 1079.1 1079.08C1078.97 1079.06 1078.84 1078.98 1078.71 1078.93C1078.77 1078.86 1078.83 1078.8 1078.9 1078.72L1078.89 1078.71Z" fill="white"/>
                    <path d="M1164.56 1066.82C1164.64 1066.89 1164.71 1066.95 1164.77 1067.01C1164.65 1067.08 1164.53 1067.17 1164.4 1067.21C1164.35 1067.22 1164.27 1067.1 1164.2 1067.05C1164.32 1066.97 1164.44 1066.9 1164.55 1066.83L1164.56 1066.82Z" fill="white"/>
                    <path d="M1072.74 1119.56C1072.62 1119.5 1072.49 1119.44 1072.36 1119.38C1072.42 1119.31 1072.49 1119.18 1072.53 1119.19C1072.66 1119.21 1072.79 1119.29 1072.92 1119.34C1072.86 1119.41 1072.8 1119.47 1072.73 1119.55L1072.74 1119.56Z" fill="white"/>
                    <path d="M1205.89 1156.58C1206.01 1156.64 1206.14 1156.7 1206.27 1156.76C1206.21 1156.83 1206.14 1156.96 1206.1 1156.95C1205.97 1156.93 1205.84 1156.85 1205.71 1156.8C1205.77 1156.73 1205.83 1156.67 1205.9 1156.59L1205.89 1156.58Z" fill="white"/>
                    <path d="M1084.77 1205.5C1084.7 1205.58 1084.64 1205.65 1084.58 1205.71C1084.51 1205.59 1084.42 1205.47 1084.38 1205.34C1084.37 1205.29 1084.49 1205.21 1084.54 1205.14C1084.62 1205.26 1084.69 1205.38 1084.76 1205.49L1084.77 1205.5Z" fill="white"/>
                    <path d="M1178.58 1096.55C1178.7 1096.48 1178.82 1096.39 1178.95 1096.35C1179 1096.34 1179.08 1096.46 1179.15 1096.51C1179.03 1096.58 1178.91 1096.67 1178.78 1096.71C1178.73 1096.72 1178.65 1096.6 1178.58 1096.55Z" fill="white"/>
                    <path d="M1081.44 1193.6C1081.56 1193.66 1081.69 1193.72 1081.82 1193.78C1081.76 1193.85 1081.69 1193.98 1081.65 1193.97C1081.52 1193.95 1081.39 1193.87 1081.26 1193.82L1081.45 1193.61L1081.44 1193.6Z" fill="white"/>
                    <path d="M1189.03 1218.89C1189.09 1218.77 1189.15 1218.64 1189.21 1218.52C1189.28 1218.58 1189.41 1218.65 1189.4 1218.69C1189.38 1218.82 1189.3 1218.95 1189.25 1219.08C1189.18 1219.02 1189.12 1218.96 1189.04 1218.89H1189.03Z" fill="white"/>
                    <path d="M1255.14 1332.31C1255.26 1332.24 1255.38 1332.15 1255.51 1332.11C1255.56 1332.1 1255.64 1332.22 1255.71 1332.27C1255.59 1332.34 1255.47 1332.43 1255.34 1332.47C1255.29 1332.48 1255.21 1332.36 1255.14 1332.31Z" fill="white"/>
                    <path d="M1194.87 1183.31C1195.72 1185.05 1194.78 1186.24 1193.86 1187.33C1193.52 1187.73 1192.92 1188.28 1192.82 1188.54C1192.2 1190.27 1190.67 1188.73 1189.87 1189.51C1189.23 1188.61 1188.16 1187.76 1188.07 1186.81C1187.98 1185.91 1187.98 1184.78 1188.34 1183.83C1188.68 1182.93 1188.75 1181.53 1190.43 1182.29C1190.68 1182.4 1191.14 1182.01 1191.51 1181.89C1193.18 1181.36 1194.31 1181.89 1194.87 1183.3V1183.31Z" fill="white"/>
                    <path d="M1045.98 1133.56C1047.05 1133.15 1048.14 1133.7 1048.28 1134.08C1048.82 1135.54 1050.38 1135.61 1051.25 1136.59C1051.86 1137.28 1052.13 1137.8 1051.72 1138.6C1051.05 1139.92 1050.47 1140.27 1049.43 1139.99C1048.98 1139.87 1048.53 1139.8 1048.07 1139.75C1046.06 1139.51 1043.91 1136.02 1045.33 1134.18C1045.58 1133.85 1045.78 1133.57 1045.98 1133.55V1133.56Z" fill="white"/>
                    <path d="M1094.72 1213.6C1095.63 1214.03 1096.91 1213.29 1097.52 1214.55C1097.61 1214.73 1097.62 1214.97 1097.75 1215.09C1098.3 1215.59 1099.07 1215.92 1098.97 1216.85C1098.89 1217.58 1098.26 1217.95 1097.83 1218.43C1096.98 1219.39 1095.17 1219.46 1094.02 1218.64C1093.13 1218 1092.84 1217.17 1092.98 1216.06C1093.13 1214.91 1094.09 1214.39 1094.72 1213.59V1213.6Z" fill="white"/>
                    <path d="M1003.47 1318.96C1001.87 1319.59 1000.9 1319.29 1000.31 1318.19C999.98 1317.58 999.81 1316.9 999.52 1316.27C999.24 1315.68 999.43 1315.39 1000.02 1315.19C1000.68 1314.97 1001.29 1314.58 1001.96 1314.45C1003.09 1314.22 1004.09 1314.66 1004.9 1315.44C1005.07 1315.6 1005.19 1316.01 1005.1 1316.2C1004.78 1316.91 1004.73 1317.66 1004.57 1318.38C1004.49 1318.73 1003.59 1318.9 1003.48 1318.96H1003.47Z" fill="white"/>
                    <path d="M938.24 1206.33C938.94 1205.98 939.93 1205.46 940.68 1206.47C941.04 1206.95 941.7 1207.84 941.12 1208.3C940.49 1208.81 940.2 1209.89 939.14 1209.78C938.4 1209.7 937.65 1209.37 936.95 1209.46C936.31 1209.55 936.11 1209.41 935.88 1208.87C935.56 1208.1 935.84 1207.5 936.39 1207.09C936.85 1206.75 937.47 1206.62 938.22 1206.32L938.24 1206.33Z" fill="white"/>
                    <path d="M1009.81 1339.55C1010.53 1339.24 1011.38 1339.56 1011.71 1340.26C1011.82 1340.49 1011.44 1343.29 1011.28 1343.45C1010.91 1343.83 1009.12 1343.49 1009.18 1343.41C1009.89 1342.38 1008.36 1342.35 1008.46 1341.65C1008.56 1340.99 1009.35 1339.74 1009.81 1339.54V1339.55Z" fill="white"/>
                    <path d="M1154.61 1238.35C1154.47 1237.96 1154.13 1237.42 1154.5 1237.26C1155.17 1236.98 1155.79 1236.37 1156.69 1236.75C1157.19 1236.96 1157.32 1237.48 1157.72 1237.66C1158.38 1237.95 1158.6 1238.26 1158.33 1239C1158.04 1239.79 1157.4 1240.15 1156.78 1239.98C1155.9 1239.74 1154.8 1239.55 1154.61 1238.35Z" fill="white"/>
                    <path d="M1061.94 1192.72C1061.35 1192.92 1060.71 1193.51 1060.32 1192.39C1060.23 1192.14 1059.63 1192.08 1059.28 1191.91C1058.88 1191.71 1058.5 1191.48 1058.11 1191.26C1058.7 1190.95 1059.3 1190.38 1059.88 1190.41C1060.62 1190.44 1061.32 1190.99 1062.06 1191.26C1062.46 1191.41 1062.81 1191.63 1062.8 1192.05C1062.79 1192.52 1062.3 1192.57 1061.95 1192.72H1061.94Z" fill="white"/>
                    <path d="M1130.25 1336.24C1130.07 1336.31 1129.9 1336.41 1129.71 1336.45C1129.29 1336.55 1128.84 1337.08 1128.44 1336.51C1128.03 1335.93 1128.01 1335.29 1128.39 1334.63C1128.72 1334.07 1129.09 1334.16 1129.59 1334.33C1129.91 1334.44 1130.33 1334.27 1130.65 1334.37C1131.08 1334.5 1131.65 1334.58 1131.73 1335.17C1131.83 1335.87 1131.15 1335.79 1130.78 1336.01C1130.61 1336.11 1130.42 1336.16 1130.24 1336.23L1130.25 1336.24Z" fill="white"/>
                    <path d="M999.37 1336.72C999.25 1337.62 999.4 1338.6 998.84 1339.41C998.71 1339.59 998.37 1339.75 998.05 1339.56C997.65 1339.33 996.97 1339.31 996.98 1338.72C996.99 1337.99 997.16 1337.26 997.34 1336.55C997.55 1335.71 998.08 1336.6 998.45 1336.4C998.83 1336.19 999.19 1336.2 999.36 1336.72H999.37Z" fill="white"/>
                    <path d="M1105.75 1171.94C1105.28 1172.22 1104.91 1172.18 1104.7 1171.55C1104.37 1170.58 1104.66 1169.84 1105.49 1169.47C1106.23 1169.13 1106.83 1169.44 1107.24 1169.94C1107.72 1170.52 1106.98 1171.48 1105.75 1171.94Z" fill="white"/>
                    <path d="M1124.55 1084.74C1124.27 1084.85 1124.01 1085.01 1123.72 1085.06C1123.05 1085.17 1122.55 1084.51 1122.82 1083.89C1122.97 1083.53 1123.07 1083.12 1123.3 1082.81C1123.6 1082.41 1124.13 1082.16 1124.56 1082.3C1125.05 1082.45 1125.21 1083.19 1125.82 1083.3C1125.53 1083.78 1125.58 1084.5 1124.82 1084.63C1124.72 1084.65 1124.64 1084.7 1124.54 1084.74H1124.55Z" fill="white"/>
                    <path d="M1047.11 1309.17C1047.43 1309.31 1047.7 1309.44 1047.97 1309.53C1048.67 1309.77 1048.36 1310.83 1049.2 1311C1049.65 1311.09 1049.81 1311.5 1049.59 1311.9C1049.38 1312.27 1049 1312.3 1048.6 1312.11C1048.07 1311.86 1047.51 1311.67 1046.99 1311.4C1046.75 1311.27 1046.13 1311.37 1046.43 1310.74C1046.67 1310.23 1046.88 1309.7 1047.1 1309.17H1047.11Z" fill="white"/>
                    <path d="M928.05 1279.03C927.9 1278.42 928.59 1277.51 929.3 1277.38C929.58 1277.33 931.09 1278.38 931.12 1278.72C931.22 1279.59 930.39 1279.48 929.94 1279.66C929.1 1279.99 928.18 1279.62 928.04 1279.03H928.05Z" fill="white"/>
                    <path d="M976.3 1211.91C976.61 1212.44 976.63 1212.83 975.98 1213.05C975.19 1213.33 974.22 1213.1 974 1212.54C973.78 1211.99 974.33 1211.12 975.11 1210.82C976.26 1210.37 975.96 1211.52 976.3 1211.9V1211.91Z" fill="white"/>
                    <path d="M977.46 1204.75C978 1204.84 978.59 1204.91 978.86 1205.47C979.15 1206.08 978.32 1207.16 977.52 1207.29C976.99 1207.38 976.88 1207.01 976.78 1206.64C976.53 1205.8 976.85 1204.9 977.46 1204.74V1204.75Z" fill="white"/>
                    <path d="M1020.24 1285.25C1019.88 1285.39 1019.69 1285.45 1019.52 1285.54C1018.89 1285.89 1018.44 1285.35 1017.91 1285.22C1018.2 1284.76 1018.15 1284.06 1018.85 1283.87C1019.04 1283.82 1019.22 1283.74 1019.39 1283.65C1019.98 1283.36 1020.4 1283.28 1020.7 1284.09C1020.99 1284.87 1020.76 1285.16 1020.23 1285.24L1020.24 1285.25Z" fill="white"/>
                    <path d="M973.78 1120.64C974.08 1121.27 973.71 1121.57 973.33 1121.56C972.59 1121.53 971.86 1121.36 971.13 1121.2C970.46 1121.04 970.94 1120.64 971.01 1120.35C971.34 1119.08 972.3 1120.09 972.94 1119.85C973.68 1119.57 973.53 1120.43 973.76 1120.65L973.78 1120.64Z" fill="white"/>
                    <path d="M996.92 1257.98C997.62 1257.53 998.24 1257.83 998.67 1258.33C998.99 1258.7 998.31 1260.19 997.91 1260.14C997.13 1260.04 996.63 1259.58 996.39 1258.8C996.22 1258.25 996.56 1258.13 996.92 1257.98Z" fill="white"/>
                    <path d="M1175.85 1286.51C1175.97 1287.08 1175.67 1287.56 1175.29 1287.97C1175.07 1288.22 1173.68 1287.36 1173.4 1286.81C1173.15 1286.32 1173.48 1286.13 1173.86 1285.99C1174.63 1285.71 1175.61 1285.96 1175.85 1286.51Z" fill="white"/>
                    <path d="M988.74 1298.99C988.72 1298.69 988.8 1298.34 989.15 1298.38C989.98 1298.47 990.83 1298.34 991.63 1298.73C991.97 1298.89 992.11 1299.19 992.08 1299.41C992.05 1299.62 991.7 1299.96 991.52 1299.95C990.78 1299.9 990.05 1299.73 989.32 1299.59C989.01 1299.53 988.82 1299.31 988.74 1298.99Z" fill="white"/>
                    <path d="M966.36 1322.27C966.64 1322.13 966.99 1321.94 967.28 1322.38C967.53 1322.76 967.27 1323.09 967.18 1323.45C967.01 1324.18 966.36 1324.24 965.85 1324.36C965.55 1324.43 964.88 1324.14 964.85 1323.95C964.8 1323.56 964.98 1323.06 965.25 1322.75C965.47 1322.49 965.94 1322.44 966.36 1322.27Z" fill="white"/>
                    <path d="M939.47 1161.69C939.22 1161.27 939.27 1160.9 939.89 1160.69C940.67 1160.43 941.62 1160.66 941.85 1161.2C942.24 1162.11 940.84 1161.94 940.9 1162.74C940.91 1162.87 939.8 1162.37 939.46 1161.69H939.47Z" fill="white"/>
                    <path d="M1076.88 1284.39C1077.05 1284.44 1077.3 1284.43 1077.38 1284.55C1077.83 1285.18 1077.34 1286.83 1076.63 1287.05C1076.39 1287.13 1076.01 1287.17 1075.86 1287.03C1075.71 1286.89 1075.67 1286.5 1075.73 1286.26C1076.01 1285.2 1076.42 1284.58 1076.88 1284.39Z" fill="white"/>
                    <path d="M1156 1206.55C1155.89 1206.28 1155.73 1206.02 1155.68 1205.74C1155.55 1205.02 1156.23 1205.1 1156.61 1204.89C1157.1 1204.62 1157.29 1204.96 1157.44 1205.35C1157.57 1205.7 1157.78 1206.02 1157.57 1206.44C1157.35 1206.86 1157.25 1207.43 1156.66 1207.41C1156.2 1207.4 1156.16 1206.9 1156 1206.56V1206.55Z" fill="white"/>
                    <path d="M990.83 1296.41C990.22 1296.52 990.12 1296.12 990.02 1295.76C989.78 1294.9 990.1 1294.02 990.72 1293.88C991.23 1293.77 991.35 1294.18 991.45 1294.54C991.69 1295.4 991.37 1296.26 990.83 1296.4V1296.41Z" fill="white"/>
                    <path d="M931.54 1164.38C931.83 1163.76 931.96 1163.11 932.76 1162.88C933.42 1162.7 933.57 1163 933.78 1163.48C934.01 1164 933.99 1164.28 933.42 1164.6C932.67 1165.02 932.16 1164.53 931.54 1164.37V1164.38Z" fill="white"/>
                    <path d="M943.53 1155.38C943.71 1155.42 943.98 1155.39 944.05 1155.5C944.24 1155.83 944.52 1156.24 944.46 1156.56C944.36 1157.08 944.28 1157.74 943.6 1157.92C943.08 1158.06 942.96 1157.65 942.86 1157.28C942.62 1156.41 942.92 1155.59 943.53 1155.38Z" fill="white"/>
                    <path d="M1181.29 1238.03C1181.67 1237.89 1182.08 1237.75 1182.2 1238.29C1182.35 1238.99 1182.14 1239.67 1181.43 1239.86C1181.05 1239.96 1180.56 1239.61 1180.12 1239.47C1180.4 1238.89 1180.47 1238.14 1181.29 1238.03Z" fill="white"/>
                    <path d="M1084.84 1113.96C1085.06 1114.64 1084.57 1115.63 1084.09 1115.73C1083.58 1115.84 1083.51 1115.42 1083.35 1115.08C1083.03 1114.4 1083.22 1113.79 1083.78 1113.46C1083.95 1113.36 1084.7 1113.6 1084.84 1113.96Z" fill="white"/>
                    <path d="M981.39 1243C981.85 1242.82 982.34 1242.65 982.39 1243.18C982.45 1243.83 982.26 1244.51 982.18 1245.18C981.54 1244.96 980.98 1244.71 980.77 1243.92C980.57 1243.18 981.11 1243.17 981.39 1243Z" fill="white"/>
                    <path d="M1011.64 1097.25C1012.36 1096.86 1012.55 1097.21 1012.81 1097.72C1013.2 1098.51 1012.79 1098.72 1012.17 1098.97C1011.54 1099.23 1011.35 1098.94 1011.11 1098.43C1010.75 1097.67 1011.03 1097.35 1011.64 1097.26V1097.25Z" fill="white"/>
                    <path d="M979.01 1255.46C979.12 1255.98 979.79 1256.61 978.81 1256.85C978.28 1256.98 977.67 1256.75 977.38 1256.2C976.9 1255.29 978.02 1255.15 978.33 1254.74C978.72 1254.22 978.7 1255.26 979 1255.47L979.01 1255.46Z" fill="white"/>
                    <path d="M960.6 1341.28C960.87 1341.17 961.13 1341.02 961.41 1340.97C961.92 1340.87 962.03 1341.28 962.14 1341.64C962.22 1341.9 962.49 1342.15 962.25 1342.44C961.98 1342.77 961.64 1342.83 961.26 1342.63C961.09 1342.54 960.9 1342.48 960.73 1342.4C960.4 1342.24 959.81 1342.11 959.78 1341.91C959.72 1341.47 960.28 1341.45 960.6 1341.28Z" fill="white"/>
                    <path d="M1139.55 1128.66C1139.49 1127.95 1140.12 1128 1140.51 1127.83C1141.24 1127.51 1141.25 1128.18 1141.43 1128.57C1141.6 1128.93 1141.71 1129.32 1141.17 1129.48C1140.61 1129.64 1139.68 1129.16 1139.55 1128.67V1128.66Z" fill="white"/>
                    <path d="M991.72 1181.38C991.89 1181.87 992.04 1182.25 991.49 1182.41C990.93 1182.57 989.97 1182.1 989.88 1181.6C989.78 1181.09 990.23 1181.04 990.55 1180.86C991.34 1180.41 991.57 1180.89 991.72 1181.39V1181.38Z" fill="white"/>
                    <path d="M966.6 1293.54C966.58 1293.34 966.43 1292.96 966.55 1292.83C966.87 1292.48 967.25 1292.87 967.46 1293.04C967.78 1293.29 968.83 1292.98 968.32 1293.99C968.15 1294.33 968.06 1294.74 967.62 1294.79C966.71 1294.89 967.03 1293.97 966.61 1293.54H966.6Z" fill="white"/>
                    <path d="M1117.83 1319.38C1118.28 1319.49 1119.13 1319.44 1118.98 1320.02C1118.85 1320.52 1119.4 1321.14 1118.77 1321.46C1118.39 1321.65 1117.31 1320.53 1117.31 1319.96C1117.31 1319.66 1117.51 1319.45 1117.84 1319.38H1117.83Z" fill="white"/>
                    <path d="M1020.41 1113.54C1020.41 1113.54 1020.72 1113.11 1021.05 1112.76C1021.2 1112.61 1022.33 1113.37 1022.37 1113.64C1022.49 1114.36 1021.83 1114.28 1021.46 1114.49C1021.01 1114.74 1020.77 1114.49 1020.41 1113.54Z" fill="white"/>
                    <path d="M1054.47 1105.12C1055.18 1105.05 1055.1 1105.69 1055.31 1106.06C1055.57 1106.53 1055.26 1106.86 1054.87 1106.86C1054.43 1106.86 1053.97 1106.64 1053.55 1106.44C1053.4 1106.37 1054.21 1105.17 1054.47 1105.13V1105.12Z" fill="white"/>
                    <path d="M1209.13 1199.74C1209.42 1200.18 1209.27 1200.46 1209.01 1200.56C1208.3 1200.84 1207.75 1200.54 1207.33 1199.95C1207.12 1199.65 1207.27 1199.37 1207.53 1199.25C1208.25 1198.94 1208.78 1199.31 1209.13 1199.73V1199.74Z" fill="white"/>
                    <path d="M1072.76 1306.67C1072.61 1306.05 1072.81 1305.47 1073.28 1305.28C1073.51 1305.19 1074.21 1305.4 1074.41 1305.95C1074.68 1306.68 1074.05 1306.68 1073.67 1306.87C1073.31 1307.05 1072.95 1307.13 1072.76 1306.67Z" fill="white"/>
                    <path d="M1155.71 1117.71C1155.85 1117.95 1155.53 1119.31 1155.37 1119.25C1154.94 1119.09 1154.47 1118.9 1154.17 1118.58C1153.89 1118.28 1153.98 1117.86 1154.47 1117.72C1154.88 1117.61 1155.29 1117.1 1155.72 1117.7L1155.71 1117.71Z" fill="white"/>
                    <path d="M1068.02 1310.47C1068.57 1310.43 1069.2 1310.55 1069.47 1311.29C1069.57 1311.56 1069.47 1311.87 1069.11 1311.92C1068.4 1312.02 1067.8 1311.83 1067.51 1311.12C1067.4 1310.86 1067.51 1310.56 1068.02 1310.46V1310.47Z" fill="white"/>
                    <path d="M1078.76 1376.16C1079.25 1375.93 1079.58 1376.21 1079.58 1376.6C1079.58 1377.04 1079.36 1377.5 1079.16 1377.92C1079.09 1378.07 1077.89 1377.26 1077.85 1377C1077.78 1376.63 1078.14 1376.31 1078.77 1376.16H1078.76Z" fill="white"/>
                    <path d="M982.61 1252.15C983 1252 983.38 1251.85 983.55 1252.39C983.73 1252.97 983.5 1253.56 983.03 1253.74C982.8 1253.83 982.1 1253.62 981.9 1253.07C981.62 1252.33 982.28 1252.36 982.61 1252.15Z" fill="white"/>
                    <path d="M992.92 1345.75C993.47 1345.81 993.98 1345.92 994.27 1346.45C994.44 1346.76 994.32 1347.06 994.04 1347.13C993.58 1347.25 993.05 1347.95 992.61 1347.09C992.34 1346.57 992.86 1346.22 992.93 1345.74L992.92 1345.75Z" fill="white"/>
                    <path d="M995.33 1257.33C995.5 1257.37 995.67 1257.42 995.85 1257.46C995.53 1258.25 995.07 1259.01 995.23 1259.93C995.23 1259.97 994.89 1260.12 994.7 1260.13C994.6 1260.13 994.38 1259.87 994.4 1259.83C994.78 1259.14 994.29 1258.26 994.87 1257.63C994.99 1257.5 995.18 1257.42 995.34 1257.32L995.33 1257.33Z" fill="white"/>
                    <path d="M997.96 1261.15C998.15 1261.71 997.32 1261.69 996.88 1261.82C996.01 1262.07 996.16 1261.18 995.92 1260.76C995.85 1260.64 995.94 1260.02 996.27 1260.42C996.76 1261.01 997.56 1259.76 997.96 1261.15Z" fill="white"/>
                    <path d="M1165.19 1183C1164.82 1182.38 1165.37 1181.95 1165.5 1181.43C1165.84 1181.57 1166.4 1181.62 1166.49 1181.85C1166.68 1182.36 1166.41 1182.85 1165.92 1183.17C1165.63 1183.36 1165.4 1183.22 1165.2 1183.01L1165.19 1183Z" fill="white"/>
                    <path d="M1017.85 1119.98C1017.95 1120.39 1018.4 1120.85 1017.67 1121.16C1017.14 1121.39 1016.97 1121.05 1016.84 1120.67C1016.7 1120.26 1016.28 1119.8 1017.01 1119.48C1017.54 1119.25 1017.68 1119.6 1017.85 1119.97V1119.98Z" fill="white"/>
                    <path d="M1193.29 1257.09C1194.25 1257.29 1194.64 1257.78 1194.79 1258.48C1194.82 1258.64 1194.73 1258.83 1194.69 1259.01C1194.51 1258.93 1194.34 1258.82 1194.16 1258.79C1193.45 1258.67 1193.33 1258.07 1193.16 1257.54C1193.11 1257.38 1193.27 1257.15 1193.29 1257.09Z" fill="white"/>
                    <path d="M1011.48 1090.2C1011.61 1090.14 1011.82 1090.45 1011.94 1090.85C1012.07 1091.26 1012.52 1091.72 1011.79 1092.04C1011.28 1092.27 1011.09 1091.96 1010.96 1091.57C1010.82 1091.16 1010.4 1090.69 1011.48 1090.2Z" fill="white"/>
                    <path d="M1004.64 1152.97C1004.62 1153.1 1004.64 1153.34 1004.56 1153.38C1003.97 1153.69 1003.38 1153.97 1002.69 1153.52C1002.41 1153.33 1002.27 1153.19 1002.51 1152.95C1002.87 1152.57 1004.35 1152.59 1004.65 1152.97H1004.64Z" fill="white"/>
                    <path d="M1119.84 1162.45C1120.78 1162.33 1120.5 1163.23 1120.77 1163.65C1120.89 1163.84 1120.54 1164.16 1120.24 1164.19C1119.35 1164.28 1119.52 1163.44 1119.3 1162.99C1119.2 1162.78 1119.51 1162.5 1119.84 1162.44V1162.45Z" fill="white"/>
                    <path d="M1019.33 1219.45C1020.27 1219.33 1019.99 1220.23 1020.26 1220.65C1020.38 1220.84 1020.03 1221.16 1019.73 1221.19C1018.84 1221.28 1019.01 1220.44 1018.79 1219.99C1018.69 1219.78 1019 1219.5 1019.33 1219.44V1219.45Z" fill="white"/>
                    <path d="M1128.58 1295.76C1128.71 1295.7 1128.92 1296.01 1129.04 1296.41C1129.17 1296.82 1129.62 1297.28 1128.89 1297.6C1128.38 1297.83 1128.19 1297.52 1128.06 1297.13C1127.92 1296.72 1127.5 1296.25 1128.58 1295.76Z" fill="white"/>
                    <path d="M942.94 1237.42C942.54 1237.9 942.14 1238.39 941.72 1238.88C941.7 1238.9 941.51 1238.84 941.47 1238.77C941.24 1238.42 941.26 1238.09 941.45 1237.7C941.66 1237.27 941.81 1236.8 942.41 1236.9C942.55 1236.92 942.66 1237.14 942.94 1237.42Z" fill="white"/>
                    <path d="M979.71 1278.05C979.53 1277.69 979.33 1277.32 979.8 1277.06C980.41 1276.72 980.64 1277.1 980.82 1277.62C980.94 1277.98 981.19 1278.35 980.71 1278.61C980.1 1278.94 979.84 1278.59 979.71 1278.05Z" fill="white"/>
                    <path d="M1029.34 1312.37C1029.89 1312.33 1030.17 1312.36 1030.22 1312.73C1030.32 1313.44 1030.12 1314.03 1029.42 1314.33C1029.28 1314.39 1029.07 1314.27 1028.89 1314.24C1029.04 1313.59 1029.19 1312.95 1029.33 1312.38L1029.34 1312.37Z" fill="white"/>
                    <path d="M995.72 1198.75C996.18 1198.49 996.6 1198.44 996.68 1198.67C996.83 1199.15 997.53 1199.64 996.83 1200.19C996.59 1200.38 996.17 1200.41 996.06 1200.16C995.86 1199.69 995.36 1199.19 995.72 1198.75Z" fill="white"/>
                    <path d="M1142.11 1281.36C1142.5 1281.22 1142.85 1281.08 1143.05 1281.63C1143.32 1282.37 1142.7 1282.39 1142.31 1282.57C1141.95 1282.74 1141.59 1282.85 1141.39 1282.31C1141.11 1281.56 1141.76 1281.58 1142.12 1281.36H1142.11Z" fill="white"/>
                    <path d="M1108.72 1197.59C1108.6 1196.65 1109.5 1196.93 1109.92 1196.66C1110.11 1196.54 1110.43 1196.89 1110.46 1197.19C1110.55 1198.08 1109.71 1197.91 1109.26 1198.13C1109.05 1198.23 1108.77 1197.92 1108.71 1197.59H1108.72Z" fill="white"/>
                    <path d="M1020.27 1318.33C1020.09 1317.97 1019.89 1317.6 1020.36 1317.34C1020.97 1317 1021.2 1317.38 1021.38 1317.9C1021.5 1318.26 1021.75 1318.63 1021.27 1318.89C1020.66 1319.22 1020.4 1318.87 1020.27 1318.33Z" fill="white"/>
                    <path d="M945.54 1170.31C945.23 1169.7 945.36 1169.47 945.63 1169.33C946.25 1169 946.48 1169.41 946.62 1169.91C946.7 1170.17 946.96 1170.66 946.72 1170.66C946.24 1170.66 945.75 1170.39 945.53 1170.31H945.54Z" fill="white"/>
                    <path d="M1169 1255.15C1169.16 1255.16 1169.43 1255.18 1169.69 1255.19C1169.45 1255.67 1169.48 1256.34 1168.81 1256.52C1168.52 1256.6 1168.29 1256.5 1168.16 1256.16C1167.89 1255.43 1168.5 1255.42 1169 1255.15Z" fill="white"/>
                    <path d="M982.92 1364.56C983.07 1364.78 982.94 1365.05 982.68 1365.19C982.34 1365.38 981.92 1365.49 981.78 1364.95C981.69 1364.57 981.09 1364.09 981.72 1363.82C981.95 1363.72 982.65 1363.96 982.92 1364.57V1364.56Z" fill="white"/>
                    <path d="M909.41 1196.04C910.02 1195.7 910.46 1196.24 910.98 1196.39C910.78 1196.57 910.62 1196.82 910.39 1196.92C910 1197.08 909.54 1197.5 909.21 1196.78C909.06 1196.46 909.17 1196.25 909.41 1196.04Z" fill="white"/>
                    <path d="M1012.63 1349.02C1012.86 1349.36 1012.71 1348.95 1012.5 1349.28C1012.38 1349.46 1011.98 1349.61 1011.8 1349.54C1011.3 1349.35 1010.63 1349.32 1010.55 1348.58C1010.55 1348.5 1010.61 1348.33 1010.65 1348.33C1011.42 1348.25 1011.96 1348.84 1012.63 1349.02Z" fill="white"/>
                    <path d="M1039.92 1300.6C1040.41 1300.85 1041.11 1300.88 1041.15 1301.61C1041.15 1301.78 1040.81 1302.09 1040.59 1302.13C1039.89 1302.27 1039.97 1301.58 1039.75 1301.21C1039.6 1300.96 1039.59 1300.72 1039.92 1300.59V1300.6Z" fill="white"/>
                    <path d="M1061.63 1207.62C1061.81 1207.55 1061.99 1207.42 1062.17 1207.42C1062.29 1207.42 1062.52 1207.58 1062.52 1207.67C1062.52 1207.99 1060.83 1208.7 1060.54 1208.49C1060.47 1208.44 1060.49 1208.1 1060.55 1208.07C1060.89 1207.89 1061.27 1207.76 1061.63 1207.62Z" fill="white"/>
                    <path d="M1117.57 1359.31C1118.12 1359.05 1118.34 1359.35 1118.45 1359.75C1118.53 1360.04 1118.43 1360.27 1118.09 1360.4C1117.58 1360.6 1117.37 1360.32 1117.26 1359.93C1117.19 1359.65 1117.22 1359.34 1117.56 1359.31H1117.57Z" fill="white"/>
                    <path d="M922.6 1181.76C922.92 1181.73 923.14 1181.79 923.27 1182.13C923.47 1182.64 923.19 1182.85 922.8 1182.96C922.51 1183.04 922.28 1182.94 922.15 1182.6C921.95 1182.09 922.24 1181.92 922.6 1181.76Z" fill="white"/>
                    <path d="M1135.29 1130.54C1135.61 1130.51 1135.83 1130.57 1135.96 1130.91C1136.16 1131.42 1135.88 1131.63 1135.49 1131.74C1135.2 1131.82 1134.97 1131.72 1134.84 1131.38C1134.64 1130.87 1134.93 1130.7 1135.29 1130.54Z" fill="white"/>
                    <path d="M1188.16 1230.68C1188.29 1231.03 1188.37 1231.36 1187.86 1231.56C1187.52 1231.7 1187.29 1231.6 1187.15 1231.34C1186.96 1230.98 1186.91 1230.64 1187.42 1230.43C1187.76 1230.29 1187.95 1230.4 1188.16 1230.68Z" fill="white"/>
                    <path d="M997.4 1107.22C997.42 1106.67 997.56 1106.18 998.07 1105.9C998.2 1105.82 998.57 1105.87 998.57 1105.89C998.62 1106.44 998.47 1106.93 997.95 1107.21C997.81 1107.28 997.6 1107.22 997.4 1107.23V1107.22Z" fill="white"/>
                    <path d="M1103.96 1107.12C1104.26 1106.93 1104.51 1107.08 1104.65 1107.34C1104.84 1107.7 1104.89 1108.04 1104.38 1108.25C1104.04 1108.39 1103.81 1108.29 1103.67 1108.03C1103.47 1107.67 1103.43 1107.3 1103.97 1107.12H1103.96Z" fill="white"/>
                    <path d="M1006.2 1173.94C1006.52 1173.91 1006.74 1173.97 1006.87 1174.31C1007.07 1174.82 1006.79 1175.03 1006.4 1175.14C1006.11 1175.22 1005.88 1175.12 1005.75 1174.78C1005.55 1174.27 1005.84 1174.1 1006.2 1173.94Z" fill="white"/>
                    <path d="M1052.92 1372.36C1053.29 1372.32 1053.51 1372.38 1053.64 1372.72C1053.84 1373.23 1053.56 1373.44 1053.17 1373.55C1052.88 1373.63 1052.66 1373.53 1052.52 1373.19C1052.31 1372.67 1052.64 1372.52 1052.92 1372.36Z" fill="white"/>
                    <path d="M1039.55 1352.01C1039.79 1352.52 1039.49 1352.75 1039.1 1352.85C1038.81 1352.93 1038.58 1352.83 1038.45 1352.49C1038.25 1351.98 1038.53 1351.77 1038.92 1351.66C1039.21 1351.58 1039.49 1351.66 1039.56 1352L1039.55 1352.01Z" fill="white"/>
                    <path d="M1016.88 1070.72C1016.52 1070.85 1016.14 1070.99 1015.98 1070.45C1015.93 1070.29 1015.96 1070.1 1015.96 1069.92C1016.37 1070.03 1016.78 1070.15 1017.19 1070.26C1017.09 1070.41 1016.99 1070.56 1016.88 1070.71V1070.72Z" fill="white"/>
                    <path d="M1053.52 1109.99C1053.88 1109.86 1054.26 1109.72 1054.42 1110.26C1054.47 1110.42 1054.44 1110.61 1054.44 1110.79C1054.03 1110.68 1053.62 1110.56 1053.21 1110.45C1053.31 1110.3 1053.41 1110.15 1053.52 1110V1109.99Z" fill="white"/>
                    <path d="M942.9 1191.72C943.27 1191.87 943.63 1192.01 943.98 1192.15C943.79 1192.33 943.63 1192.61 943.4 1192.68C942.9 1192.84 942.76 1192.42 942.68 1192.05C942.66 1191.95 942.84 1191.81 942.9 1191.71V1191.72Z" fill="white"/>
                    <path d="M1111.87 1205.4C1111.87 1205.63 1111.94 1205.94 1111.87 1205.96C1111.39 1206.17 1111.07 1205.71 1110.66 1205.62C1110.76 1205.47 1110.84 1205.27 1110.98 1205.19C1111.34 1204.99 1111.69 1204.96 1111.87 1205.39V1205.4Z" fill="white"/>
                    <path d="M1205.46 1196.3C1205.6 1196.33 1205.83 1196.32 1205.89 1196.41C1206.08 1196.73 1206.27 1197.13 1205.79 1197.36C1205.57 1197.46 1205.27 1197.38 1205 1197.37C1205.15 1197.02 1205.3 1196.67 1205.46 1196.3Z" fill="white"/>
                    <path d="M1176.96 1188.34C1176.74 1187.85 1177.07 1187.66 1177.45 1187.55C1177.6 1187.51 1177.8 1187.61 1177.97 1187.65C1177.75 1188.02 1177.54 1188.38 1177.32 1188.75C1177.2 1188.62 1177.08 1188.49 1176.96 1188.35V1188.34Z" fill="white"/>
                    <path d="M967.09 1192.97C967.17 1192.98 967.4 1192.96 967.45 1193.04C967.64 1193.36 967.83 1193.76 967.37 1193.99C967.16 1194.1 966.85 1194.01 966.59 1194.02C966.75 1193.68 966.92 1193.34 967.09 1192.97Z" fill="white"/>
                    <path d="M1003.46 1180.18C1003.95 1179.96 1004.14 1180.29 1004.25 1180.67C1004.29 1180.82 1004.19 1181.02 1004.15 1181.19C1003.78 1180.97 1003.42 1180.76 1003.05 1180.54C1003.18 1180.42 1003.31 1180.3 1003.45 1180.18H1003.46Z" fill="white"/>
                    <path d="M1169.29 1140.04C1169.47 1140.07 1169.65 1140.1 1169.82 1140.13C1169.62 1140.5 1169.42 1140.88 1169.23 1141.25C1169.11 1141.12 1168.95 1141.02 1168.87 1140.87C1168.62 1140.4 1168.91 1140.2 1169.29 1140.04Z" fill="white"/>
                    <path d="M1053.71 1339.27C1053.49 1338.78 1053.82 1338.59 1054.2 1338.48C1054.35 1338.44 1054.55 1338.54 1054.72 1338.58C1054.5 1338.95 1054.29 1339.31 1054.07 1339.68C1053.95 1339.55 1053.83 1339.42 1053.71 1339.28V1339.27Z" fill="white"/>
                    <path d="M1071.11 1376.35C1071.26 1376.15 1071.34 1375.95 1071.48 1375.88C1071.85 1375.69 1072.21 1375.66 1072.35 1376.17C1072.39 1376.33 1072.35 1376.52 1072.35 1376.7C1071.94 1376.59 1071.54 1376.47 1071.1 1376.35H1071.11Z" fill="white"/>
                    <path d="M900.98 1236.14C901.38 1236.2 901.38 1236.54 901.46 1236.79C901.49 1236.87 901.29 1237.12 901.2 1237.12C900.83 1237.1 900.82 1236.74 900.74 1236.48C900.71 1236.4 900.9 1236.24 900.98 1236.13V1236.14Z" fill="white"/>
                    <path d="M916.54 1215.29C916.8 1215.09 916.99 1214.88 917.22 1214.77C917.36 1214.71 917.57 1214.83 917.74 1214.86C917.55 1215.04 917.38 1215.27 917.15 1215.39C917.03 1215.46 916.81 1215.34 916.53 1215.28L916.54 1215.29Z" fill="white"/>
                    <path d="M1158.74 1316.56C1159.14 1316.62 1159.14 1316.96 1159.22 1317.21C1159.25 1317.29 1159.05 1317.54 1158.96 1317.54C1158.59 1317.52 1158.58 1317.16 1158.5 1316.9C1158.47 1316.82 1158.66 1316.66 1158.74 1316.55V1316.56Z" fill="white"/>
                    <path d="M1193.57 1211.18C1193.77 1211.44 1193.98 1211.63 1194.09 1211.86C1194.15 1212 1194.04 1212.21 1194 1212.38C1193.82 1212.19 1193.59 1212.02 1193.47 1211.79C1193.4 1211.67 1193.52 1211.45 1193.58 1211.17L1193.57 1211.18Z" fill="white"/>
                    <path d="M1192.02 1208.37C1192.07 1208.52 1192.19 1208.77 1192.21 1209.02C1192.23 1209.32 1192.03 1209.4 1191.85 1209.16C1191.7 1208.96 1191.59 1208.69 1191.56 1208.44C1191.53 1208.14 1191.7 1208.09 1192.02 1208.37Z" fill="white"/>
                    <path d="M1176.74 1317.59C1176.74 1317.41 1176.68 1317.13 1176.76 1317.08C1177.06 1316.89 1177.24 1317.11 1177.3 1317.39C1177.34 1317.56 1177.37 1317.85 1177.29 1317.9C1176.99 1318.09 1176.83 1317.87 1176.75 1317.59H1176.74Z" fill="white"/>
                    <path d="M929.03 1183.68C929.21 1183.68 929.49 1183.62 929.54 1183.7C929.73 1184 929.51 1184.18 929.23 1184.24C929.06 1184.28 928.77 1184.31 928.72 1184.23C928.53 1183.93 928.75 1183.77 929.03 1183.69V1183.68Z" fill="white"/>
                    <path d="M1158.57 1139.47C1158.83 1139.27 1159.02 1139.06 1159.25 1138.95C1159.39 1138.89 1159.6 1139 1159.77 1139.04C1159.58 1139.22 1159.41 1139.45 1159.18 1139.57C1159.06 1139.64 1158.84 1139.52 1158.56 1139.46L1158.57 1139.47Z" fill="white"/>
                    <path d="M984.25 1333.76C984.38 1333.79 984.55 1333.82 984.73 1333.86C984.54 1334.04 984.37 1334.27 984.14 1334.39C984.02 1334.46 983.8 1334.34 983.62 1334.3C983.71 1334.01 983.89 1333.81 984.25 1333.76Z" fill="white"/>
                    <path d="M1139.47 1098.02C1139.6 1098.28 1139.64 1098.55 1139.29 1098.62C1139.2 1098.64 1139.02 1098.41 1138.93 1098.26C1138.78 1098.01 1138.76 1097.74 1139.1 1097.66C1139.19 1097.64 1139.34 1097.89 1139.47 1098.02Z" fill="white"/>
                    <path d="M1022.14 1319.82C1022.27 1320.08 1022.31 1320.35 1021.96 1320.42C1021.87 1320.44 1021.69 1320.21 1021.6 1320.06C1021.45 1319.81 1021.43 1319.54 1021.77 1319.46C1021.86 1319.44 1022.01 1319.69 1022.14 1319.82Z" fill="white"/>
                    <path d="M1027.12 1308.18C1027.25 1308.44 1027.29 1308.71 1026.94 1308.78C1026.85 1308.8 1026.67 1308.57 1026.58 1308.42C1026.43 1308.17 1026.41 1307.9 1026.75 1307.82C1026.84 1307.8 1026.99 1308.05 1027.12 1308.18Z" fill="white"/>
                    <path d="M1039.17 1263.15C1039.43 1262.95 1039.62 1262.74 1039.85 1262.63C1039.99 1262.57 1040.2 1262.68 1040.37 1262.72C1040.18 1262.9 1040.01 1263.13 1039.78 1263.25C1039.66 1263.32 1039.44 1263.2 1039.16 1263.14L1039.17 1263.15Z" fill="white"/>
                    <path d="M956.85 1192.4C957.07 1192.42 957.34 1192.45 957.6 1192.47C957.5 1192.62 957.42 1192.86 957.29 1192.9C957.04 1192.97 956.76 1192.92 956.5 1192.92C956.6 1192.77 956.71 1192.62 956.86 1192.4H956.85Z" fill="white"/>
                    <path d="M1051.13 1181.66C1051.26 1181.69 1051.43 1181.72 1051.61 1181.76C1051.42 1181.94 1051.25 1182.17 1051.02 1182.29C1050.9 1182.36 1050.68 1182.24 1050.5 1182.2C1050.59 1181.91 1050.77 1181.71 1051.13 1181.66Z" fill="white"/>
                    <path d="M1041.07 1136.46C1041.33 1136.26 1041.52 1136.05 1041.75 1135.94C1041.89 1135.88 1042.1 1135.99 1042.27 1136.03C1042.08 1136.21 1041.91 1136.44 1041.68 1136.56C1041.56 1136.63 1041.34 1136.51 1041.06 1136.45L1041.07 1136.46Z" fill="white"/>
                    <path d="M1024.77 1065.72C1025.17 1065.78 1025.17 1066.12 1025.25 1066.37C1025.28 1066.45 1025.08 1066.7 1024.99 1066.7C1024.62 1066.68 1024.61 1066.32 1024.53 1066.06C1024.5 1065.98 1024.69 1065.82 1024.77 1065.71V1065.72Z" fill="white"/>
                    <path d="M1073.25 1109.14C1073.38 1109.4 1073.42 1109.67 1073.07 1109.74C1072.98 1109.76 1072.8 1109.53 1072.71 1109.38C1072.56 1109.13 1072.54 1108.86 1072.88 1108.78C1072.97 1108.76 1073.12 1109.01 1073.25 1109.14Z" fill="white"/>
                    <path d="M1142.84 1306.76C1142.84 1306.58 1142.78 1306.3 1142.86 1306.25C1143.16 1306.06 1143.34 1306.28 1143.4 1306.56C1143.44 1306.73 1143.47 1307.02 1143.39 1307.07C1143.09 1307.26 1142.93 1307.04 1142.85 1306.76H1142.84Z" fill="white"/>
                    <path d="M1092.74 1223.42C1092.92 1223.42 1093.2 1223.36 1093.25 1223.44C1093.44 1223.74 1093.22 1223.92 1092.94 1223.98C1092.77 1224.02 1092.48 1224.05 1092.43 1223.97C1092.24 1223.67 1092.46 1223.51 1092.74 1223.43V1223.42Z" fill="white"/>
                    <path d="M1106.79 1216.82C1106.89 1216.71 1106.97 1216.58 1107.09 1216.5C1107.13 1216.47 1107.26 1216.56 1107.34 1216.6C1107.24 1216.71 1107.16 1216.84 1107.04 1216.92C1107 1216.95 1106.87 1216.86 1106.79 1216.82Z" fill="white"/>
                    <path d="M1177.37 1133.27C1177.47 1133.16 1177.55 1133.03 1177.67 1132.95C1177.71 1132.92 1177.84 1133.01 1177.92 1133.05C1177.82 1133.16 1177.74 1133.29 1177.62 1133.37C1177.58 1133.4 1177.45 1133.31 1177.37 1133.27Z" fill="white"/>
                    <path d="M1025.68 1296.01C1025.82 1296.03 1025.97 1296.05 1026.12 1296.06C1026.08 1296.15 1026.06 1296.3 1026.01 1296.31C1025.87 1296.33 1025.72 1296.3 1025.57 1296.29C1025.61 1296.2 1025.64 1296.12 1025.69 1296.01H1025.68Z" fill="white"/>
                    <path d="M1029.53 1342.47C1029.43 1342.43 1029.34 1342.39 1029.25 1342.35C1029.35 1342.24 1029.43 1342.11 1029.55 1342.03C1029.59 1342 1029.72 1342.09 1029.8 1342.13C1029.71 1342.24 1029.61 1342.36 1029.52 1342.47H1029.53Z" fill="white"/>
                    <path d="M995.86 1262.49C995.84 1262.63 995.82 1262.78 995.81 1262.93C995.72 1262.89 995.57 1262.87 995.56 1262.82C995.54 1262.68 995.57 1262.53 995.58 1262.38C995.67 1262.42 995.75 1262.45 995.86 1262.5V1262.49Z" fill="white"/>
                    <path d="M1015.37 1220.38C1015.48 1220.48 1015.61 1220.56 1015.69 1220.68C1015.72 1220.72 1015.63 1220.85 1015.59 1220.93C1015.48 1220.83 1015.35 1220.75 1015.27 1220.63C1015.24 1220.59 1015.33 1220.46 1015.37 1220.38Z" fill="white"/>
                    <path d="M1001.94 1173.5C1001.9 1173.6 1001.86 1173.69 1001.82 1173.78C1001.71 1173.68 1001.58 1173.6 1001.5 1173.48C1001.47 1173.44 1001.56 1173.31 1001.6 1173.23C1001.71 1173.32 1001.83 1173.42 1001.94 1173.51V1173.5Z" fill="white"/>
                    <path d="M941.87 1176.7C942.01 1176.72 942.16 1176.74 942.31 1176.75C942.27 1176.84 942.25 1176.99 942.2 1177C942.06 1177.02 941.91 1176.99 941.76 1176.98C941.8 1176.89 941.83 1176.81 941.88 1176.7H941.87Z" fill="white"/>
                    <path d="M1001.36 1281.35C1001.46 1281.39 1001.55 1281.43 1001.64 1281.47C1001.54 1281.58 1001.46 1281.71 1001.34 1281.79C1001.3 1281.82 1001.17 1281.73 1001.09 1281.69C1001.18 1281.58 1001.28 1281.46 1001.37 1281.35H1001.36Z" fill="white"/>
                    <path d="M1046.71 1221.86C1046.61 1221.82 1046.52 1221.78 1046.43 1221.74C1046.53 1221.63 1046.61 1221.5 1046.73 1221.42C1046.77 1221.39 1046.9 1221.48 1046.98 1221.52C1046.89 1221.63 1046.79 1221.75 1046.7 1221.86H1046.71Z" fill="white"/>
                    <path d="M1004.05 1083.84C1004.09 1083.75 1004.13 1083.6 1004.16 1083.6C1004.31 1083.62 1004.48 1083.63 1004.58 1083.72C1004.79 1083.91 1004.72 1084.07 1004.44 1084.05C1004.31 1084.05 1004.18 1083.92 1004.05 1083.84Z" fill="white"/>
                    <path d="M1004.94 1083.86C1005.07 1083.94 1005.3 1084 1005.31 1084.09C1005.33 1084.33 1005.18 1084.43 1005.03 1084.32C1004.9 1084.23 1004.81 1084.08 1004.7 1083.96C1004.78 1083.93 1004.86 1083.89 1004.95 1083.86H1004.94Z" fill="white"/>
                    <path d="M1130.18 1236.13C1130.32 1236.15 1130.47 1236.17 1130.62 1236.18C1130.58 1236.27 1130.56 1236.42 1130.51 1236.43C1130.37 1236.45 1130.22 1236.42 1130.07 1236.41C1130.11 1236.32 1130.14 1236.24 1130.19 1236.13H1130.18Z" fill="white"/>
                    <path d="M924.1 1116.36C924.24 1116.38 924.39 1116.4 924.54 1116.41C924.5 1116.5 924.48 1116.65 924.43 1116.66C924.29 1116.68 924.14 1116.65 923.99 1116.64C924.03 1116.55 924.06 1116.47 924.11 1116.36H924.1Z" fill="white"/>
                    <path d="M1006.51 1076.25C1006.61 1076.29 1006.7 1076.33 1006.79 1076.37C1006.69 1076.48 1006.61 1076.61 1006.49 1076.69C1006.45 1076.72 1006.32 1076.63 1006.24 1076.59C1006.33 1076.48 1006.43 1076.36 1006.52 1076.25H1006.51Z" fill="white"/>
                    <path d="M931.32 1159.54C931.18 1159.52 931.03 1159.5 930.88 1159.49C930.92 1159.4 930.94 1159.25 930.99 1159.24C931.13 1159.22 931.28 1159.25 931.43 1159.26C931.39 1159.35 931.36 1159.43 931.31 1159.54H931.32Z" fill="white"/>
                    <path d="M1077.62 1153.12C1077.76 1153.14 1077.91 1153.16 1078.06 1153.17C1078.02 1153.26 1078 1153.41 1077.95 1153.42C1077.81 1153.44 1077.66 1153.41 1077.51 1153.4C1077.55 1153.31 1077.58 1153.23 1077.63 1153.12H1077.62Z" fill="white"/>
                    <path d="M971.65 1242.18C971.61 1242.28 971.57 1242.37 971.53 1242.46C971.42 1242.36 971.29 1242.28 971.21 1242.16C971.18 1242.12 971.27 1241.99 971.31 1241.91C971.42 1242 971.54 1242.1 971.65 1242.19V1242.18Z" fill="white"/>
                    <path d="M1030.4 1101.6C1030.5 1101.49 1030.58 1101.36 1030.7 1101.28C1030.74 1101.25 1030.87 1101.34 1030.95 1101.38C1030.85 1101.49 1030.77 1101.62 1030.65 1101.7C1030.61 1101.73 1030.48 1101.64 1030.4 1101.6Z" fill="white"/>
                    <path d="M964.39 1231.28C964.53 1231.3 964.68 1231.32 964.83 1231.33C964.79 1231.42 964.77 1231.57 964.72 1231.58C964.58 1231.6 964.43 1231.57 964.28 1231.56L964.4 1231.28H964.39Z" fill="white"/>
                    <path d="M1081.1 1221.43C1081.12 1221.29 1081.14 1221.14 1081.15 1220.99C1081.24 1221.03 1081.39 1221.05 1081.4 1221.1C1081.42 1221.24 1081.39 1221.39 1081.38 1221.54C1081.29 1221.5 1081.21 1221.47 1081.1 1221.42V1221.43Z" fill="white"/>
                    <path d="M1184.93 1314C1185.03 1313.89 1185.11 1313.76 1185.23 1313.68C1185.27 1313.65 1185.4 1313.74 1185.48 1313.78C1185.38 1313.89 1185.3 1314.02 1185.18 1314.1C1185.14 1314.13 1185.01 1314.04 1184.93 1314Z" fill="white"/>
                    <path d="M1104.38 1233.48C1104.71 1235.33 1103.51 1236.17 1102.37 1236.94C1101.95 1237.22 1101.24 1237.56 1101.08 1237.79C1100.05 1239.23 1099.03 1237.39 1098.08 1237.9C1097.73 1236.9 1096.96 1235.82 1097.13 1234.92C1097.29 1234.06 1097.59 1233.01 1098.17 1232.23C1098.72 1231.49 1099.17 1230.2 1100.52 1231.36C1100.72 1231.53 1101.25 1231.29 1101.63 1231.28C1103.32 1231.24 1104.22 1232.02 1104.37 1233.49L1104.38 1233.48Z" fill="white"/>
                    <path d="M979.56 1147.58C980.66 1147.49 981.53 1148.29 981.56 1148.68C981.67 1150.18 983.1 1150.66 983.64 1151.8C984.02 1152.61 984.13 1153.16 983.54 1153.79C982.57 1154.83 981.93 1155.01 981.04 1154.47C980.65 1154.24 980.26 1154.06 979.84 1153.88C978.04 1153.12 976.98 1149.31 978.79 1147.98C979.11 1147.74 979.37 1147.54 979.56 1147.57V1147.58Z" fill="white"/>
                    <path d="M1003.4 1234.83C1004.13 1235.48 1005.51 1235.13 1005.75 1236.46C1005.78 1236.65 1005.73 1236.88 1005.82 1237.02C1006.2 1237.63 1006.83 1238.14 1006.48 1238.98C1006.21 1239.64 1005.53 1239.81 1005 1240.14C1003.95 1240.8 1002.25 1240.39 1001.41 1239.32C1000.76 1238.49 1000.7 1237.64 1001.13 1236.65C1001.57 1235.62 1002.61 1235.4 1003.4 1234.82V1234.83Z" fill="white"/>
                    <path d="M890.64 1308.19C888.99 1308.35 888.17 1307.81 887.91 1306.63C887.77 1305.98 887.79 1305.3 887.69 1304.64C887.59 1304.02 887.84 1303.8 888.44 1303.77C889.11 1303.74 889.78 1303.55 890.44 1303.6C891.55 1303.69 892.35 1304.37 892.9 1305.31C893.01 1305.5 893.02 1305.91 892.88 1306.07C892.4 1306.64 892.15 1307.32 891.8 1307.95C891.63 1308.26 890.75 1308.17 890.63 1308.19H890.64Z" fill="white"/>
                    <path d="M860.2 1186.31C860.94 1186.17 862 1185.96 862.43 1187.09C862.63 1187.63 863.01 1188.63 862.35 1188.91C861.63 1189.21 861.07 1190.14 860.12 1189.76C859.46 1189.49 858.85 1188.98 858.18 1188.88C857.56 1188.79 857.41 1188.61 857.35 1188.05C857.26 1187.25 857.68 1186.77 858.3 1186.54C858.82 1186.34 859.43 1186.4 860.2 1186.32V1186.31Z" fill="white"/>
                    <path d="M891.02 1328.98C891.77 1328.89 892.48 1329.41 892.59 1330.15C892.63 1330.4 891.53 1332.89 891.33 1333C890.88 1333.25 889.32 1332.46 889.39 1332.4C890.32 1331.64 888.91 1331.2 889.19 1330.58C889.46 1329.99 890.53 1329.05 891.01 1328.99L891.02 1328.98Z" fill="white"/>
                    <path d="M1052.34 1273.77C1052.31 1273.37 1052.15 1272.78 1052.52 1272.73C1053.22 1272.65 1053.95 1272.25 1054.69 1272.84C1055.1 1273.17 1055.08 1273.69 1055.41 1273.96C1055.94 1274.4 1056.06 1274.75 1055.62 1275.36C1055.14 1276.02 1054.45 1276.18 1053.92 1275.86C1053.17 1275.4 1052.2 1274.93 1052.35 1273.77H1052.34Z" fill="white"/>
                    <path d="M978.57 1206.71C977.97 1206.73 977.22 1207.12 977.15 1205.97C977.13 1205.71 976.59 1205.5 976.31 1205.24C975.99 1204.95 975.7 1204.63 975.4 1204.32C976.03 1204.19 976.74 1203.82 977.27 1204C977.94 1204.23 978.45 1204.92 979.06 1205.37C979.39 1205.62 979.66 1205.91 979.53 1206.3C979.39 1206.73 978.93 1206.65 978.57 1206.7V1206.71Z" fill="white"/>
                    <path d="M1003.62 1358.07C1003.43 1358.09 1003.25 1358.13 1003.06 1358.12C1002.64 1358.1 1002.08 1358.47 1001.87 1357.84C1001.65 1357.19 1001.79 1356.59 1002.33 1356.08C1002.78 1355.65 1003.1 1355.84 1003.52 1356.12C1003.79 1356.31 1004.22 1356.26 1004.49 1356.44C1004.85 1356.68 1005.36 1356.9 1005.28 1357.47C1005.18 1358.15 1004.58 1357.89 1004.18 1358C1004 1358.05 1003.81 1358.04 1003.62 1358.06V1358.07Z" fill="white"/>
                    <path d="M882.09 1323.57C881.74 1324.37 881.62 1325.32 880.88 1325.93C880.71 1326.07 880.35 1326.12 880.11 1325.86C879.8 1325.54 879.18 1325.34 879.35 1324.8C879.56 1324.13 879.91 1323.49 880.27 1322.88C880.69 1322.15 880.94 1323.13 881.34 1323.04C881.75 1322.94 882.08 1323.04 882.1 1323.58L882.09 1323.57Z" fill="white"/>
                    <path d="M1024.75 1199.14C1024.24 1199.28 1023.91 1199.14 1023.88 1198.5C1023.83 1197.51 1024.3 1196.91 1025.17 1196.78C1025.95 1196.67 1026.42 1197.11 1026.67 1197.69C1026.96 1198.36 1026.02 1199.05 1024.75 1199.15V1199.14Z" fill="white"/>
                    <path d="M1065.48 1123.27C1065.2 1123.3 1064.91 1123.38 1064.63 1123.35C1063.98 1123.27 1063.7 1122.52 1064.11 1122.02C1064.35 1121.72 1064.55 1121.37 1064.84 1121.15C1065.22 1120.86 1065.79 1120.77 1066.15 1121.01C1066.57 1121.28 1066.52 1122.01 1067.05 1122.28C1066.66 1122.65 1066.5 1123.33 1065.77 1123.25C1065.68 1123.25 1065.58 1123.27 1065.49 1123.28L1065.48 1123.27Z" fill="white"/>
                    <path d="M933.73 1310.77C933.99 1310.98 934.2 1311.18 934.43 1311.34C935.01 1311.75 934.44 1312.65 935.18 1313.03C935.57 1313.23 935.61 1313.66 935.3 1313.96C935.01 1314.25 934.65 1314.17 934.33 1313.89C933.9 1313.52 933.43 1313.19 933.03 1312.8C932.84 1312.61 932.24 1312.55 932.69 1312.04C933.05 1311.63 933.39 1311.19 933.73 1310.77Z" fill="white"/>
                    <path d="M831.35 1251.02C831.38 1250.41 832.26 1249.75 832.95 1249.82C833.23 1249.85 834.34 1251.22 834.28 1251.55C834.14 1252.39 833.4 1252.06 832.93 1252.11C832.06 1252.19 831.31 1251.6 831.34 1251.02H831.35Z" fill="white"/>
                    <path d="M894.02 1201.65C894.17 1202.22 894.08 1202.59 893.42 1202.63C892.62 1202.68 891.77 1202.2 891.72 1201.63C891.66 1201.06 892.41 1200.4 893.2 1200.33C894.39 1200.22 893.8 1201.21 894.02 1201.65Z" fill="white"/>
                    <path d="M897.01 1195.31C897.48 1195.54 898.02 1195.76 898.12 1196.36C898.23 1197.01 897.17 1197.78 896.39 1197.69C895.88 1197.63 895.87 1197.26 895.87 1196.89C895.87 1196.04 896.4 1195.29 897.01 1195.31Z" fill="white"/>
                    <path d="M915.19 1281.4C914.82 1281.44 914.63 1281.44 914.45 1281.48C913.77 1281.64 913.5 1281.02 913.04 1280.75C913.43 1280.4 913.57 1279.74 914.27 1279.75C914.46 1279.75 914.64 1279.73 914.83 1279.69C915.45 1279.57 915.86 1279.61 915.93 1280.45C916 1281.25 915.7 1281.46 915.19 1281.39V1281.4Z" fill="white"/>
                    <path d="M916.04 1116.32C916.15 1116.98 915.72 1117.16 915.38 1117.05C914.7 1116.83 914.07 1116.48 913.44 1116.13C912.86 1115.8 913.41 1115.56 913.56 1115.31C914.21 1114.22 914.83 1115.42 915.49 1115.36C916.25 1115.3 915.88 1116.06 916.04 1116.32Z" fill="white"/>
                    <path d="M900.84 1249.88C901.6 1249.65 902.1 1250.1 902.37 1250.67C902.57 1251.1 901.54 1252.3 901.18 1252.14C900.48 1251.84 900.14 1251.28 900.13 1250.49C900.13 1249.94 900.47 1249.91 900.84 1249.87V1249.88Z" fill="white"/>
                    <path d="M1059.18 1324.12C1059.14 1324.68 1058.73 1325.04 1058.27 1325.32C1058 1325.49 1056.94 1324.32 1056.83 1323.74C1056.73 1323.21 1057.09 1323.13 1057.48 1323.1C1058.27 1323.04 1059.11 1323.54 1059.18 1324.11V1324.12Z" fill="white"/>
                    <path d="M882.31 1285.73C882.37 1285.44 882.54 1285.14 882.85 1285.27C883.59 1285.57 884.42 1285.69 885.06 1286.26C885.33 1286.5 885.38 1286.81 885.29 1287.01C885.21 1287.2 884.79 1287.42 884.62 1287.36C883.95 1287.11 883.32 1286.77 882.68 1286.44C882.41 1286.3 882.29 1286.05 882.3 1285.73H882.31Z" fill="white"/>
                    <path d="M855.33 1301.36C855.63 1301.31 856 1301.22 856.15 1301.71C856.28 1302.13 855.95 1302.36 855.77 1302.67C855.42 1303.3 854.8 1303.18 854.29 1303.16C853.99 1303.15 853.45 1302.7 853.48 1302.52C853.54 1302.15 853.84 1301.73 854.17 1301.51C854.44 1301.33 854.89 1301.41 855.33 1301.36Z" fill="white"/>
                    <path d="M873.27 1145.23C873.15 1144.77 873.29 1144.44 873.93 1144.42C874.73 1144.39 875.54 1144.86 875.61 1145.42C875.73 1146.37 874.47 1145.84 874.32 1146.6C874.3 1146.73 873.4 1145.96 873.27 1145.24V1145.23Z" fill="white"/>
                    <path d="M967.95 1295.73C968.1 1295.82 968.33 1295.88 968.37 1296.01C968.62 1296.72 967.72 1298.11 967.01 1298.13C966.76 1298.13 966.4 1298.07 966.3 1297.91C966.19 1297.74 966.26 1297.37 966.39 1297.16C966.93 1296.25 967.48 1295.79 967.95 1295.73Z" fill="white"/>
                    <path d="M1062.12 1244.66C1062.09 1244.38 1062.01 1244.09 1062.04 1243.82C1062.12 1243.11 1062.72 1243.37 1063.13 1243.28C1063.66 1243.16 1063.74 1243.53 1063.78 1243.93C1063.81 1244.29 1063.92 1244.64 1063.61 1244.97C1063.29 1245.3 1063.05 1245.8 1062.51 1245.63C1062.08 1245.5 1062.18 1245.02 1062.13 1244.67L1062.12 1244.66Z" fill="white"/>
                    <path d="M884.94 1283.9C884.35 1283.84 884.36 1283.44 884.36 1283.08C884.36 1282.22 884.9 1281.49 885.51 1281.52C886.02 1281.55 886.02 1281.97 886.01 1282.33C886.01 1283.19 885.47 1283.9 884.94 1283.89V1283.9Z" fill="white"/>
                    <path d="M865.2 1145.61C865.64 1145.11 865.93 1144.54 866.74 1144.55C867.41 1144.55 867.46 1144.88 867.52 1145.38C867.59 1145.92 867.5 1146.18 866.88 1146.32C866.07 1146.51 865.73 1145.92 865.2 1145.61Z" fill="white"/>
                    <path d="M878.71 1140.47C878.87 1140.55 879.13 1140.6 879.16 1140.72C879.25 1141.08 879.4 1141.53 879.26 1141.81C879.03 1142.26 878.78 1142.85 878.1 1142.84C877.58 1142.84 877.58 1142.42 877.58 1142.05C877.58 1141.18 878.09 1140.49 878.71 1140.47Z" fill="white"/>
                    <path d="M1077.17 1280.6C1077.56 1280.57 1077.98 1280.55 1077.95 1281.08C1077.91 1281.77 1077.53 1282.34 1076.81 1282.33C1076.44 1282.33 1076.07 1281.87 1075.7 1281.62C1076.11 1281.16 1076.38 1280.48 1077.17 1280.6Z" fill="white"/>
                    <path d="M1020.84 1139.77C1020.86 1140.46 1020.15 1141.24 1019.67 1141.21C1019.17 1141.18 1019.21 1140.77 1019.15 1140.41C1019.04 1139.69 1019.38 1139.18 1019.99 1139.02C1020.17 1138.97 1020.81 1139.4 1020.84 1139.77Z" fill="white"/>
                    <path d="M890.44 1231.84C890.92 1231.79 891.42 1231.77 891.32 1232.27C891.2 1232.89 890.85 1233.47 890.59 1234.07C890.06 1233.69 889.6 1233.31 889.62 1232.53C889.63 1231.79 890.13 1231.92 890.44 1231.85V1231.84Z" fill="white"/>
                    <path d="M957.41 1104.74C958.18 1104.58 958.27 1104.95 958.37 1105.48C958.52 1106.31 958.08 1106.4 957.45 1106.47C956.8 1106.54 956.7 1106.23 956.62 1105.68C956.49 1104.88 956.84 1104.65 957.42 1104.74H957.41Z" fill="white"/>
                    <path d="M884.9 1242.77C884.86 1243.28 885.32 1244.05 884.35 1244.01C883.82 1243.99 883.32 1243.61 883.19 1243.02C882.98 1242.05 884.07 1242.22 884.47 1241.92C884.97 1241.54 884.68 1242.5 884.9 1242.77Z" fill="white"/>
                    <path d="M844.92 1317.45C845.2 1317.42 845.48 1317.35 845.75 1317.38C846.25 1317.42 846.24 1317.83 846.25 1318.2C846.25 1318.46 846.43 1318.77 846.14 1318.97C845.8 1319.2 845.47 1319.17 845.17 1318.88C845.04 1318.75 844.88 1318.65 844.74 1318.52C844.47 1318.28 843.96 1318.01 843.99 1317.81C844.05 1317.38 844.58 1317.52 844.92 1317.45Z" fill="white"/>
                    <path d="M1067.66 1168.01C1067.79 1167.34 1068.36 1167.55 1068.77 1167.49C1069.53 1167.38 1069.36 1168.02 1069.43 1168.42C1069.49 1168.8 1069.49 1169.19 1068.94 1169.19C1068.38 1169.19 1067.64 1168.5 1067.65 1168L1067.66 1168.01Z" fill="white"/>
                    <path d="M916.47 1177.45C916.5 1177.95 916.53 1178.34 915.98 1178.34C915.42 1178.34 914.65 1177.64 914.7 1177.15C914.75 1176.65 915.17 1176.72 915.52 1176.64C916.37 1176.43 916.46 1176.94 916.47 1177.45Z" fill="white"/>
                    <path d="M863.23 1274.76C863.26 1274.57 863.23 1274.17 863.37 1274.08C863.77 1273.84 864 1274.31 864.16 1274.52C864.39 1274.84 865.44 1274.83 864.7 1275.63C864.45 1275.9 864.26 1276.26 863.84 1276.18C862.97 1276.03 863.51 1275.26 863.23 1274.75V1274.76Z" fill="white"/>
                    <path d="M996.6 1339.11C996.99 1339.33 997.79 1339.51 997.49 1340.01C997.23 1340.44 997.58 1341.16 996.91 1341.29C996.51 1341.37 995.8 1340.04 995.95 1339.51C996.03 1339.23 996.27 1339.09 996.6 1339.11Z" fill="white"/>
                    <path d="M961.2 1122.19C961.2 1122.19 961.6 1121.87 962 1121.64C962.17 1121.54 963.02 1122.54 962.99 1122.81C962.91 1123.51 962.32 1123.26 961.92 1123.35C961.44 1123.46 961.28 1123.16 961.2 1122.19Z" fill="white"/>
                    <path d="M995.04 1123.47C995.71 1123.59 995.48 1124.16 995.56 1124.56C995.67 1125.07 995.3 1125.29 994.94 1125.18C994.53 1125.06 994.16 1124.74 993.83 1124.44C993.71 1124.33 994.78 1123.44 995.03 1123.47H995.04Z" fill="white"/>
                    <path d="M1113.22 1252.52C1113.37 1253 1113.16 1253.23 1112.89 1253.25C1112.16 1253.32 1111.72 1252.89 1111.49 1252.24C1111.37 1251.9 1111.59 1251.68 1111.87 1251.65C1112.63 1251.55 1113.01 1252.03 1113.22 1252.52Z" fill="white"/>
                    <path d="M958.19 1315.29C958.22 1314.68 958.56 1314.19 959.04 1314.14C959.28 1314.12 959.87 1314.5 959.91 1315.06C959.97 1315.81 959.38 1315.64 958.98 1315.72C958.6 1315.79 958.24 1315.77 958.19 1315.29Z" fill="white"/>
                    <path d="M1085.58 1162.17C1085.64 1162.43 1084.98 1163.61 1084.85 1163.51C1084.49 1163.25 1084.11 1162.95 1083.91 1162.57C1083.73 1162.22 1083.92 1161.85 1084.42 1161.86C1084.83 1161.86 1085.35 1161.51 1085.58 1162.18V1162.17Z" fill="white"/>
                    <path d="M952.78 1317.54C953.3 1317.65 953.85 1317.93 953.91 1318.69C953.93 1318.97 953.76 1319.23 953.4 1319.18C952.71 1319.09 952.21 1318.75 952.13 1318.02C952.1 1317.75 952.28 1317.5 952.78 1317.55V1317.54Z" fill="white"/>
                    <path d="M945.2 1381.34C945.72 1381.26 945.95 1381.61 945.84 1381.96C945.72 1382.37 945.4 1382.74 945.1 1383.07C944.99 1383.19 944.1 1382.12 944.13 1381.87C944.16 1381.51 944.58 1381.3 945.21 1381.34H945.2Z" fill="white"/>
                    <path d="M889.12 1240.65C889.52 1240.62 889.91 1240.58 889.93 1241.12C889.94 1241.71 889.58 1242.19 889.09 1242.24C888.85 1242.26 888.26 1241.88 888.22 1241.32C888.16 1240.56 888.76 1240.76 889.13 1240.66L889.12 1240.65Z" fill="white"/>
                    <path d="M873.7 1330.23C874.2 1330.43 874.64 1330.67 874.76 1331.24C874.83 1331.58 874.64 1331.82 874.36 1331.81C873.9 1331.8 873.22 1332.31 873.04 1331.39C872.93 1330.83 873.51 1330.65 873.69 1330.23H873.7Z" fill="white"/>
                    <path d="M899.54 1248.86C899.69 1248.95 899.84 1249.03 899.98 1249.12C899.47 1249.77 898.84 1250.35 898.74 1251.25C898.74 1251.29 898.37 1251.34 898.2 1251.29C898.1 1251.26 897.97 1250.96 898 1250.93C898.54 1250.39 898.31 1249.44 899.02 1249.01C899.17 1248.92 899.36 1248.9 899.54 1248.85V1248.86Z" fill="white"/>
                    <path d="M900.97 1253.1C900.99 1253.67 900.23 1253.43 899.79 1253.43C898.92 1253.43 899.3 1252.64 899.18 1252.19C899.14 1252.06 899.4 1251.51 899.6 1251.97C899.9 1252.65 900.98 1251.7 900.97 1253.1Z" fill="white"/>
                    <path d="M1076.93 1225.26C1076.75 1224.59 1077.38 1224.33 1077.64 1223.88C1077.92 1224.1 1078.42 1224.3 1078.44 1224.53C1078.48 1225.05 1078.09 1225.44 1077.56 1225.6C1077.24 1225.7 1077.07 1225.51 1076.93 1225.26Z" fill="white"/>
                    <path d="M957.1 1127.48C957.08 1127.89 957.38 1128.43 956.62 1128.53C956.07 1128.6 956 1128.24 955.98 1127.86C955.96 1127.44 955.69 1126.9 956.45 1126.8C957 1126.73 957.04 1127.09 957.1 1127.48Z" fill="white"/>
                    <path d="M1083.22 1301.48C1084.06 1301.92 1084.29 1302.48 1084.24 1303.17C1084.23 1303.33 1084.09 1303.48 1084.01 1303.63C1083.87 1303.51 1083.74 1303.36 1083.58 1303.28C1082.96 1302.98 1083 1302.39 1082.98 1301.86C1082.98 1301.69 1083.19 1301.52 1083.22 1301.48Z" fill="white"/>
                    <path d="M959.14 1098.15C959.28 1098.13 959.39 1098.47 959.4 1098.87C959.41 1099.28 959.71 1099.83 958.94 1099.94C958.4 1100.01 958.31 1099.68 958.29 1099.28C958.27 1098.86 958.01 1098.31 959.14 1098.15Z" fill="white"/>
                    <path d="M936.04 1154.55C935.98 1154.67 935.94 1154.89 935.85 1154.91C935.22 1155.04 934.6 1155.14 934.08 1154.54C933.87 1154.29 933.78 1154.13 934.06 1153.96C934.5 1153.7 935.86 1154.12 936.04 1154.55Z" fill="white"/>
                    <path d="M1040.36 1194.1C1041.27 1194.24 1040.77 1195 1040.9 1195.47C1040.96 1195.68 1040.55 1195.88 1040.27 1195.83C1039.42 1195.67 1039.8 1194.94 1039.72 1194.47C1039.68 1194.25 1040.05 1194.07 1040.37 1194.11L1040.36 1194.1Z" fill="white"/>
                    <path d="M931.91 1220.13C932.82 1220.27 932.32 1221.03 932.45 1221.5C932.51 1221.71 932.1 1221.91 931.82 1221.86C930.97 1221.7 931.35 1220.97 931.27 1220.5C931.23 1220.28 931.6 1220.1 931.92 1220.14L931.91 1220.13Z" fill="white"/>
                    <path d="M1012.88 1320.07C1013.02 1320.05 1013.13 1320.39 1013.14 1320.79C1013.15 1321.2 1013.45 1321.75 1012.68 1321.86C1012.14 1321.93 1012.05 1321.6 1012.03 1321.2C1012.01 1320.78 1011.75 1320.23 1012.88 1320.07Z" fill="white"/>
                    <path d="M856.27 1216.4C855.78 1216.74 855.27 1217.09 854.75 1217.42C854.73 1217.44 854.57 1217.33 854.55 1217.25C854.43 1216.87 854.54 1216.56 854.82 1216.26C855.13 1215.92 855.4 1215.52 855.93 1215.77C856.05 1215.83 856.1 1216.06 856.28 1216.39L856.27 1216.4Z" fill="white"/>
                    <path d="M879.52 1263.9C879.45 1263.52 879.36 1263.12 879.87 1263C880.53 1262.85 880.64 1263.26 880.67 1263.79C880.69 1264.16 880.82 1264.56 880.3 1264.68C879.65 1264.83 879.5 1264.43 879.52 1263.89V1263.9Z" fill="white"/>
                    <path d="M916.39 1308.98C916.91 1309.09 917.16 1309.19 917.11 1309.55C917.02 1310.24 916.67 1310.73 915.94 1310.82C915.8 1310.84 915.63 1310.67 915.48 1310.59C915.79 1310.03 916.11 1309.47 916.38 1308.98H916.39Z" fill="white"/>
                    <path d="M915.54 1194.62C916.04 1194.5 916.44 1194.57 916.45 1194.8C916.46 1195.28 916.98 1195.92 916.18 1196.25C915.91 1196.36 915.51 1196.27 915.48 1196.02C915.42 1195.53 915.08 1194.94 915.55 1194.62H915.54Z" fill="white"/>
                    <path d="M1029.27 1310.33C1029.66 1310.31 1030.03 1310.27 1030.07 1310.83C1030.13 1311.59 1029.54 1311.44 1029.13 1311.5C1028.75 1311.56 1028.39 1311.57 1028.34 1311.01C1028.28 1310.24 1028.88 1310.43 1029.27 1310.33Z" fill="white"/>
                    <path d="M1020.67 1223.71C1020.81 1222.8 1021.57 1223.3 1022.04 1223.17C1022.25 1223.11 1022.45 1223.52 1022.4 1223.8C1022.24 1224.65 1021.51 1224.27 1021.04 1224.35C1020.82 1224.39 1020.64 1224.02 1020.68 1223.7L1020.67 1223.71Z" fill="white"/>
                    <path d="M906.39 1312.09C906.32 1311.71 906.23 1311.31 906.74 1311.19C907.4 1311.04 907.51 1311.45 907.54 1311.98C907.56 1312.35 907.69 1312.75 907.17 1312.87C906.52 1313.02 906.37 1312.62 906.39 1312.08V1312.09Z" fill="white"/>
                    <path d="M876.59 1154.84C876.46 1154.19 876.64 1154.01 876.94 1153.95C877.6 1153.81 877.7 1154.25 877.71 1154.75C877.71 1155.01 877.82 1155.53 877.6 1155.47C877.15 1155.35 876.78 1154.96 876.59 1154.83V1154.84Z" fill="white"/>
                    <path d="M1061.21 1293.2C1061.36 1293.25 1061.6 1293.34 1061.84 1293.43C1061.49 1293.82 1061.34 1294.44 1060.67 1294.43C1060.38 1294.43 1060.19 1294.27 1060.17 1293.92C1060.12 1293.17 1060.69 1293.33 1061.22 1293.21L1061.21 1293.2Z" fill="white"/>
                    <path d="M859.4 1345C859.48 1345.24 859.29 1345.46 859.01 1345.52C858.64 1345.6 858.22 1345.59 858.24 1345.05C858.26 1344.67 857.83 1344.06 858.48 1343.98C858.72 1343.95 859.31 1344.36 859.39 1345H859.4Z" fill="white"/>
                    <path d="M836.21 1169.07C836.87 1168.92 837.13 1169.54 837.57 1169.81C837.34 1169.93 837.12 1170.12 836.88 1170.14C836.47 1170.19 835.93 1170.45 835.82 1169.69C835.77 1169.35 835.92 1169.19 836.21 1169.06V1169.07Z" fill="white"/>
                    <path d="M891.11 1338.52C891.24 1338.89 891.21 1338.48 890.92 1338.72C890.77 1338.85 890.35 1338.89 890.2 1338.78C889.79 1338.47 889.18 1338.26 889.29 1337.55C889.3 1337.47 889.41 1337.34 889.45 1337.34C890.18 1337.47 890.53 1338.16 891.1 1338.51L891.11 1338.52Z" fill="white"/>
                    <path d="M929.35 1300.89C929.73 1301.26 930.38 1301.47 930.22 1302.15C930.18 1302.31 929.78 1302.51 929.56 1302.49C928.87 1302.43 929.13 1301.82 929.03 1301.42C928.96 1301.15 929.01 1300.92 929.36 1300.89H929.35Z" fill="white"/>
                    <path d="M974.31 1220.45C974.5 1220.43 974.7 1220.37 974.87 1220.41C974.98 1220.44 975.15 1220.66 975.13 1220.74C975.04 1221.04 973.29 1221.24 973.07 1220.97C973.02 1220.91 973.12 1220.6 973.19 1220.58C973.56 1220.5 973.94 1220.49 974.31 1220.45Z" fill="white"/>
                    <path d="M985.7 1376.08C986.28 1375.98 986.41 1376.32 986.4 1376.72C986.4 1377.01 986.24 1377.2 985.89 1377.23C985.36 1377.28 985.24 1376.97 985.25 1376.57C985.25 1376.29 985.37 1376.02 985.7 1376.08Z" fill="white"/>
                    <path d="M852.26 1159.34C852.57 1159.4 852.75 1159.51 852.78 1159.86C852.83 1160.39 852.52 1160.51 852.12 1160.5C851.83 1160.5 851.64 1160.34 851.61 1159.99C851.56 1159.46 851.88 1159.38 852.25 1159.34H852.26Z" fill="white"/>
                    <path d="M1063.21 1168.62C1063.52 1168.68 1063.7 1168.79 1063.73 1169.14C1063.78 1169.67 1063.47 1169.79 1063.07 1169.78C1062.78 1169.78 1062.59 1169.62 1062.56 1169.27C1062.51 1168.74 1062.83 1168.66 1063.2 1168.62H1063.21Z" fill="white"/>
                    <path d="M1085.51 1275.61C1085.54 1275.97 1085.53 1276.29 1085 1276.35C1084.65 1276.39 1084.46 1276.24 1084.4 1275.95C1084.32 1275.56 1084.37 1275.23 1084.9 1275.18C1085.25 1275.14 1085.4 1275.29 1085.52 1275.61H1085.51Z" fill="white"/>
                    <path d="M941.54 1110.18C941.71 1109.68 941.97 1109.26 942.51 1109.13C942.65 1109.1 942.98 1109.24 942.98 1109.25C942.88 1109.78 942.61 1110.19 942.05 1110.31C941.9 1110.34 941.73 1110.23 941.54 1110.18Z" fill="white"/>
                    <path d="M1040.4 1138.54C1040.73 1138.44 1040.92 1138.65 1040.98 1138.93C1041.06 1139.32 1041.01 1139.65 1040.48 1139.7C1040.13 1139.74 1039.94 1139.59 1039.88 1139.3C1039.8 1138.91 1039.85 1138.56 1040.4 1138.53V1138.54Z" fill="white"/>
                    <path d="M931.89 1174.41C932.2 1174.47 932.38 1174.58 932.41 1174.93C932.46 1175.46 932.15 1175.58 931.75 1175.57C931.46 1175.57 931.27 1175.41 931.24 1175.06C931.19 1174.53 931.51 1174.45 931.88 1174.41H931.89Z" fill="white"/>
                    <path d="M922.25 1370.92C922.61 1370.99 922.79 1371.1 922.82 1371.45C922.87 1371.98 922.56 1372.1 922.16 1372.09C921.87 1372.09 921.69 1371.93 921.65 1371.58C921.59 1371.04 921.94 1370.99 922.24 1370.92H922.25Z" fill="white"/>
                    <path d="M915.28 1348.48C915.37 1349.02 915.03 1349.15 914.63 1349.14C914.34 1349.14 914.15 1348.98 914.12 1348.63C914.07 1348.1 914.38 1347.98 914.78 1347.99C915.07 1347.99 915.31 1348.14 915.28 1348.48Z" fill="white"/>
                    <path d="M969.36 1081.53C968.99 1081.56 968.6 1081.58 968.6 1081.04C968.6 1080.87 968.68 1080.71 968.72 1080.54C969.07 1080.76 969.42 1080.97 969.77 1081.19C969.64 1081.3 969.5 1081.41 969.36 1081.52V1081.53Z" fill="white"/>
                    <path d="M992.86 1127.74C993.23 1127.71 993.62 1127.69 993.62 1128.23C993.62 1128.39 993.54 1128.56 993.5 1128.73C993.15 1128.51 992.8 1128.3 992.45 1128.08C992.58 1127.97 992.72 1127.86 992.86 1127.75V1127.74Z" fill="white"/>
                    <path d="M868.43 1174C868.74 1174.24 869.03 1174.46 869.31 1174.69C869.08 1174.81 868.86 1175.02 868.63 1175.02C868.12 1175.03 868.11 1174.61 868.13 1174.25C868.13 1174.15 868.34 1174.07 868.43 1174Z" fill="white"/>
                    <path d="M1021.5 1231.81C1021.44 1232.03 1021.42 1232.32 1021.35 1232.33C1020.85 1232.4 1020.68 1231.89 1020.31 1231.69C1020.45 1231.58 1020.57 1231.41 1020.72 1231.38C1021.1 1231.29 1021.44 1231.36 1021.49 1231.81H1021.5Z" fill="white"/>
                    <path d="M1110.73 1248.35C1110.86 1248.42 1111.07 1248.47 1111.1 1248.56C1111.19 1248.91 1111.26 1249.33 1110.76 1249.41C1110.53 1249.45 1110.27 1249.29 1110.03 1249.21C1110.26 1248.92 1110.5 1248.64 1110.74 1248.34L1110.73 1248.35Z" fill="white"/>
                    <path d="M1086.43 1233.36C1086.36 1232.85 1086.71 1232.75 1087.09 1232.75C1087.24 1232.75 1087.4 1232.9 1087.55 1232.98C1087.25 1233.26 1086.95 1233.54 1086.65 1233.82C1086.58 1233.67 1086.5 1233.51 1086.42 1233.35L1086.43 1233.36Z" fill="white"/>
                    <path d="M890.53 1181.62C890.6 1181.65 890.82 1181.69 890.84 1181.78C890.93 1182.13 891.01 1182.55 890.51 1182.64C890.29 1182.68 890.02 1182.52 889.77 1182.46C890.01 1182.19 890.25 1181.92 890.52 1181.62H890.53Z" fill="white"/>
                    <path d="M927.68 1179.47C928.19 1179.4 928.29 1179.75 928.29 1180.13C928.29 1180.28 928.14 1180.44 928.06 1180.59C927.78 1180.29 927.5 1179.99 927.22 1179.69C927.37 1179.62 927.53 1179.54 927.69 1179.46L927.68 1179.47Z" fill="white"/>
                    <path d="M1092.2 1186.51C1092.36 1186.59 1092.52 1186.66 1092.67 1186.73C1092.39 1187.02 1092.1 1187.32 1091.82 1187.61C1091.74 1187.46 1091.62 1187.32 1091.59 1187.16C1091.48 1186.66 1091.81 1186.55 1092.2 1186.5V1186.51Z" fill="white"/>
                    <path d="M931.82 1340.44C931.75 1339.93 932.1 1339.83 932.48 1339.83C932.63 1339.83 932.79 1339.98 932.94 1340.06C932.64 1340.34 932.34 1340.62 932.04 1340.9C931.97 1340.75 931.89 1340.59 931.81 1340.43L931.82 1340.44Z" fill="white"/>
                    <path d="M938.05 1379.48C938.24 1379.34 938.37 1379.17 938.52 1379.14C938.91 1379.06 939.26 1379.14 939.25 1379.64C939.25 1379.8 939.16 1379.97 939.11 1380.13C938.76 1379.92 938.41 1379.7 938.05 1379.48Z" fill="white"/>
                    <path d="M817.69 1204.01C818.04 1204.17 817.95 1204.49 817.96 1204.75C817.96 1204.83 817.71 1205.01 817.63 1204.99C817.29 1204.88 817.38 1204.53 817.37 1204.28C817.37 1204.19 817.58 1204.1 817.69 1204.02V1204.01Z" fill="white"/>
                    <path d="M837.69 1188.83C837.98 1188.71 838.21 1188.57 838.46 1188.53C838.6 1188.51 838.77 1188.67 838.92 1188.75C838.69 1188.87 838.48 1189.04 838.23 1189.09C838.1 1189.12 837.93 1188.95 837.69 1188.82V1188.83Z" fill="white"/>
                    <path d="M1035.29 1347.42C1035.64 1347.58 1035.55 1347.9 1035.56 1348.16C1035.56 1348.24 1035.31 1348.42 1035.23 1348.4C1034.89 1348.29 1034.98 1347.94 1034.97 1347.69C1034.97 1347.6 1035.18 1347.51 1035.29 1347.43V1347.42Z" fill="white"/>
                    <path d="M1095.73 1258.98C1095.85 1259.27 1095.99 1259.5 1096.03 1259.75C1096.05 1259.89 1095.89 1260.06 1095.81 1260.21C1095.69 1259.98 1095.52 1259.77 1095.47 1259.52C1095.44 1259.39 1095.61 1259.22 1095.74 1258.98H1095.73Z" fill="white"/>
                    <path d="M1095.05 1255.96C1095.05 1256.11 1095.1 1256.37 1095.05 1256.61C1094.99 1256.89 1094.79 1256.92 1094.68 1256.65C1094.59 1256.42 1094.56 1256.14 1094.6 1255.9C1094.65 1255.62 1094.82 1255.61 1095.04 1255.96H1095.05Z" fill="white"/>
                    <path d="M1051.71 1353.18C1051.76 1353.02 1051.78 1352.73 1051.86 1352.71C1052.19 1352.62 1052.29 1352.86 1052.27 1353.14C1052.26 1353.31 1052.21 1353.59 1052.12 1353.61C1051.79 1353.7 1051.7 1353.46 1051.7 1353.18H1051.71Z" fill="white"/>
                    <path d="M857.71 1162.84C857.87 1162.89 858.16 1162.91 858.18 1162.99C858.27 1163.32 858.03 1163.42 857.75 1163.4C857.58 1163.39 857.3 1163.34 857.28 1163.25C857.19 1162.92 857.43 1162.83 857.71 1162.83V1162.84Z" fill="white"/>
                    <path d="M1082.42 1183.12C1082.71 1183 1082.94 1182.86 1083.19 1182.82C1083.33 1182.8 1083.5 1182.96 1083.65 1183.04C1083.42 1183.16 1083.21 1183.33 1082.96 1183.38C1082.83 1183.41 1082.66 1183.24 1082.42 1183.11V1183.12Z" fill="white"/>
                    <path d="M868.86 1316.79C868.97 1316.85 869.13 1316.93 869.28 1317.01C869.05 1317.13 868.84 1317.3 868.59 1317.35C868.46 1317.38 868.29 1317.21 868.13 1317.13C868.29 1316.88 868.51 1316.75 868.86 1316.79Z" fill="white"/>
                    <path d="M1075.77 1139.58C1075.82 1139.86 1075.79 1140.11 1075.44 1140.09C1075.35 1140.09 1075.25 1139.82 1075.2 1139.66C1075.12 1139.39 1075.18 1139.13 1075.52 1139.15C1075.61 1139.15 1075.68 1139.43 1075.76 1139.58H1075.77Z" fill="white"/>
                    <path d="M907.73 1313.97C907.78 1314.25 907.75 1314.5 907.4 1314.48C907.31 1314.48 907.21 1314.21 907.16 1314.05C907.08 1313.78 907.14 1313.52 907.48 1313.54C907.57 1313.54 907.64 1313.82 907.72 1313.97H907.73Z" fill="white"/>
                    <path d="M915.45 1304.51C915.5 1304.79 915.47 1305.04 915.12 1305.02C915.03 1305.02 914.93 1304.75 914.88 1304.59C914.8 1304.32 914.86 1304.06 915.2 1304.08C915.29 1304.08 915.36 1304.36 915.44 1304.51H915.45Z" fill="white"/>
                    <path d="M938.65 1265.96C938.94 1265.84 939.17 1265.7 939.42 1265.66C939.56 1265.64 939.73 1265.8 939.88 1265.88C939.65 1266 939.44 1266.17 939.19 1266.22C939.06 1266.25 938.89 1266.08 938.65 1265.95V1265.96Z" fill="white"/>
                    <path d="M881.19 1178.35C881.39 1178.43 881.63 1178.52 881.87 1178.62C881.73 1178.73 881.6 1178.93 881.46 1178.93C881.21 1178.93 880.97 1178.81 880.72 1178.74C880.85 1178.63 880.99 1178.52 881.19 1178.36V1178.35Z" fill="white"/>
                    <path d="M971.5 1193.57C971.61 1193.63 971.77 1193.71 971.92 1193.79C971.69 1193.91 971.48 1194.08 971.23 1194.13C971.1 1194.16 970.93 1193.99 970.77 1193.91C970.93 1193.66 971.15 1193.53 971.5 1193.57Z" fill="white"/>
                    <path d="M974.24 1148.96C974.53 1148.84 974.76 1148.7 975.01 1148.66C975.15 1148.64 975.32 1148.8 975.47 1148.88C975.24 1149 975.03 1149.17 974.78 1149.22C974.65 1149.25 974.48 1149.08 974.24 1148.95V1148.96Z" fill="white"/>
                    <path d="M978 1079C978.35 1079.16 978.26 1079.48 978.27 1079.74C978.27 1079.82 978.02 1080 977.94 1079.98C977.6 1079.87 977.69 1079.52 977.68 1079.27C977.68 1079.18 977.89 1079.09 978 1079.01V1079Z" fill="white"/>
                    <path d="M1011.38 1132.22C1011.43 1132.5 1011.4 1132.75 1011.05 1132.73C1010.96 1132.73 1010.86 1132.46 1010.81 1132.3C1010.73 1132.03 1010.79 1131.77 1011.13 1131.79C1011.22 1131.79 1011.29 1132.07 1011.37 1132.22H1011.38Z" fill="white"/>
                    <path d="M1023.17 1334.09C1023.22 1333.93 1023.24 1333.64 1023.32 1333.62C1023.65 1333.53 1023.75 1333.77 1023.73 1334.05C1023.72 1334.22 1023.67 1334.5 1023.58 1334.52C1023.25 1334.61 1023.16 1334.37 1023.16 1334.09H1023.17Z" fill="white"/>
                    <path d="M998.94 1243.41C999.1 1243.46 999.39 1243.48 999.41 1243.56C999.5 1243.89 999.26 1243.99 998.98 1243.97C998.81 1243.96 998.53 1243.91 998.51 1243.82C998.42 1243.49 998.66 1243.4 998.94 1243.4V1243.41Z" fill="white"/>
                    <path d="M1013.73 1241.04C1013.85 1240.96 1013.96 1240.87 1014.1 1240.82C1014.15 1240.8 1014.24 1240.92 1014.31 1240.98C1014.19 1241.06 1014.08 1241.15 1013.94 1241.2C1013.89 1241.22 1013.8 1241.1 1013.73 1241.04Z" fill="white"/>
                    <path d="M1101.51 1182.39C1101.63 1182.31 1101.74 1182.22 1101.88 1182.17C1101.93 1182.15 1102.02 1182.27 1102.09 1182.33C1101.97 1182.41 1101.86 1182.5 1101.72 1182.55C1101.67 1182.57 1101.58 1182.45 1101.51 1182.39Z" fill="white"/>
                    <path d="M917.37 1292.84C917.5 1292.89 917.63 1292.95 917.76 1293.01C917.7 1293.08 917.64 1293.21 917.59 1293.21C917.45 1293.19 917.32 1293.12 917.19 1293.07C917.25 1293 917.3 1292.93 917.37 1292.85V1292.84Z" fill="white"/>
                    <path d="M908.53 1336.96C908.45 1336.89 908.38 1336.83 908.31 1336.78C908.43 1336.7 908.54 1336.61 908.68 1336.56C908.73 1336.54 908.82 1336.66 908.89 1336.72C908.77 1336.8 908.66 1336.88 908.54 1336.96H908.53Z" fill="white"/>
                    <path d="M898.66 1253.78C898.61 1253.91 898.55 1254.04 898.49 1254.17C898.42 1254.11 898.29 1254.05 898.29 1254C898.31 1253.86 898.38 1253.73 898.43 1253.6C898.5 1253.66 898.57 1253.71 898.65 1253.78H898.66Z" fill="white"/>
                    <path d="M928 1219.94C928.08 1220.06 928.17 1220.17 928.22 1220.31C928.24 1220.36 928.12 1220.45 928.06 1220.52C927.98 1220.4 927.89 1220.29 927.84 1220.15C927.82 1220.1 927.94 1220.01 928 1219.94Z" fill="white"/>
                    <path d="M928.06 1172.86C927.99 1172.94 927.93 1173.01 927.88 1173.08C927.8 1172.96 927.71 1172.85 927.66 1172.71C927.64 1172.66 927.76 1172.57 927.82 1172.5C927.9 1172.62 927.98 1172.73 928.06 1172.85V1172.86Z" fill="white"/>
                    <path d="M871.48 1159.8C871.61 1159.85 871.74 1159.91 871.87 1159.97C871.81 1160.04 871.75 1160.17 871.7 1160.17C871.56 1160.15 871.43 1160.08 871.3 1160.03C871.36 1159.96 871.41 1159.89 871.48 1159.81V1159.8Z" fill="white"/>
                    <path d="M898.72 1272.74C898.8 1272.81 898.87 1272.87 898.94 1272.92C898.82 1273 898.71 1273.09 898.57 1273.14C898.52 1273.16 898.43 1273.04 898.36 1272.98C898.48 1272.9 898.59 1272.82 898.71 1272.74H898.72Z" fill="white"/>
                    <path d="M956.67 1229.68C956.59 1229.61 956.52 1229.55 956.45 1229.5C956.57 1229.42 956.68 1229.33 956.82 1229.28C956.87 1229.26 956.96 1229.38 957.03 1229.44C956.91 1229.52 956.8 1229.6 956.68 1229.68H956.67Z" fill="white"/>
                    <path d="M953.95 1090.27C954.01 1090.2 954.09 1090.07 954.11 1090.08C954.24 1090.13 954.4 1090.2 954.47 1090.31C954.61 1090.54 954.51 1090.67 954.25 1090.58C954.13 1090.54 954.04 1090.39 953.94 1090.28L953.95 1090.27Z" fill="white"/>
                    <path d="M954.77 1090.52C954.87 1090.62 955.07 1090.75 955.05 1090.83C955 1091.06 954.83 1091.11 954.72 1090.97C954.63 1090.85 954.58 1090.69 954.51 1090.55C954.6 1090.55 954.68 1090.53 954.77 1090.52Z" fill="white"/>
                    <path d="M1030.27 1265.2C1030.4 1265.25 1030.53 1265.31 1030.66 1265.37C1030.6 1265.44 1030.54 1265.57 1030.49 1265.57C1030.35 1265.55 1030.22 1265.48 1030.09 1265.43C1030.15 1265.36 1030.2 1265.29 1030.27 1265.21V1265.2Z" fill="white"/>
                    <path d="M871.12 1099.09C871.25 1099.14 871.38 1099.2 871.51 1099.26C871.45 1099.33 871.39 1099.46 871.34 1099.46C871.2 1099.44 871.07 1099.37 870.94 1099.32C871 1099.25 871.05 1099.18 871.12 1099.1V1099.09Z" fill="white"/>
                    <path d="M958.26 1083.89C958.34 1083.96 958.41 1084.02 958.48 1084.07C958.36 1084.15 958.25 1084.24 958.11 1084.29C958.06 1084.31 957.97 1084.19 957.9 1084.13C958.02 1084.05 958.13 1083.97 958.25 1083.89H958.26Z" fill="white"/>
                    <path d="M866.28 1141.06C866.15 1141.01 866.02 1140.95 865.89 1140.89C865.95 1140.82 866.01 1140.69 866.06 1140.69C866.2 1140.71 866.33 1140.78 866.46 1140.83C866.4 1140.9 866.35 1140.97 866.28 1141.05V1141.06Z" fill="white"/>
                    <path d="M1003.69 1174.17C1003.82 1174.22 1003.95 1174.28 1004.08 1174.34C1004.02 1174.41 1003.96 1174.54 1003.91 1174.54C1003.77 1174.52 1003.64 1174.45 1003.51 1174.4C1003.57 1174.33 1003.62 1174.26 1003.69 1174.18V1174.17Z" fill="white"/>
                    <path d="M881.63 1228.48C881.56 1228.56 881.5 1228.63 881.45 1228.7C881.37 1228.58 881.28 1228.47 881.23 1228.33C881.21 1228.28 881.33 1228.19 881.39 1228.12C881.47 1228.24 881.55 1228.35 881.63 1228.47V1228.48Z" fill="white"/>
                    <path d="M973.64 1113.78C973.76 1113.7 973.87 1113.61 974.01 1113.56C974.06 1113.54 974.15 1113.66 974.22 1113.72C974.1 1113.8 973.99 1113.89 973.85 1113.94C973.8 1113.96 973.71 1113.84 973.64 1113.78Z" fill="white"/>
                    <path d="M877.8 1216.43C877.93 1216.48 878.06 1216.54 878.19 1216.6C878.13 1216.67 878.07 1216.8 878.02 1216.8C877.88 1216.78 877.75 1216.71 877.62 1216.66L877.8 1216.44V1216.43Z" fill="white"/>
                    <path d="M988.68 1238.46C988.73 1238.33 988.79 1238.2 988.85 1238.07C988.92 1238.13 989.05 1238.19 989.05 1238.24C989.03 1238.38 988.96 1238.51 988.91 1238.64C988.84 1238.58 988.77 1238.53 988.69 1238.46H988.68Z" fill="white"/>
                    <path d="M1060.27 1352.03C1060.39 1351.95 1060.5 1351.86 1060.64 1351.81C1060.69 1351.79 1060.78 1351.91 1060.85 1351.97C1060.73 1352.05 1060.62 1352.14 1060.48 1352.19C1060.43 1352.21 1060.34 1352.09 1060.27 1352.03Z" fill="white"/>
                    <path d="M954.33 1317.95C953.83 1319.76 952.39 1320.02 951.02 1320.23C950.52 1320.31 949.73 1320.31 949.49 1320.44C947.93 1321.3 947.8 1319.19 946.72 1319.25C946.83 1318.19 946.6 1316.88 947.15 1316.14C947.67 1315.43 948.39 1314.6 949.25 1314.15C950.07 1313.72 951.03 1312.74 951.76 1314.37C951.87 1314.61 952.45 1314.62 952.8 1314.77C954.35 1315.46 954.84 1316.56 954.33 1317.95Z" fill="white"/>
                    <path d="M878.08 1186.33C879.12 1186.72 879.56 1187.82 879.42 1188.19C878.87 1189.6 879.96 1190.65 879.96 1191.92C879.96 1192.82 879.82 1193.36 879.01 1193.68C877.68 1194.21 877.03 1194.09 876.45 1193.22C876.2 1192.84 875.92 1192.51 875.62 1192.17C874.32 1190.71 874.99 1186.8 877.2 1186.37C877.59 1186.29 877.92 1186.22 878.07 1186.33H878.08Z" fill="white"/>
                    <path d="M862.16 1275.73C862.55 1276.63 863.95 1276.91 863.59 1278.22C863.54 1278.4 863.39 1278.59 863.41 1278.76C863.49 1279.47 863.84 1280.21 863.17 1280.82C862.64 1281.3 861.95 1281.16 861.33 1281.23C860.1 1281.38 858.73 1280.27 858.43 1278.94C858.19 1277.9 858.51 1277.12 859.32 1276.4C860.17 1275.66 861.2 1275.9 862.17 1275.72L862.16 1275.73Z" fill="white"/>
                    <path d="M728.3 1293.75C726.73 1293.18 726.22 1292.35 726.5 1291.17C726.65 1290.52 726.97 1289.91 727.15 1289.27C727.33 1288.66 727.65 1288.57 728.2 1288.81C728.82 1289.07 729.51 1289.18 730.08 1289.51C731.05 1290.07 731.49 1291.03 731.58 1292.12C731.6 1292.34 731.43 1292.72 731.24 1292.8C730.56 1293.11 730.04 1293.62 729.45 1294.05C729.16 1294.25 728.41 1293.8 728.29 1293.77L728.3 1293.75Z" fill="white"/>
                    <path d="M753.14 1170.1C753.87 1170.29 754.92 1170.55 754.82 1171.76C754.77 1172.33 754.69 1173.41 753.97 1173.38C753.19 1173.34 752.28 1173.95 751.58 1173.19C751.09 1172.66 750.77 1171.94 750.2 1171.56C749.68 1171.21 749.62 1170.98 749.8 1170.45C750.06 1169.69 750.65 1169.43 751.31 1169.49C751.86 1169.54 752.4 1169.85 753.13 1170.11L753.14 1170.1Z" fill="white"/>
                    <path d="M719.7 1312.77C720.42 1313.01 720.83 1313.79 720.62 1314.51C720.55 1314.75 718.48 1316.54 718.26 1316.55C717.75 1316.59 716.67 1315.19 716.76 1315.17C717.94 1314.88 716.85 1313.87 717.37 1313.43C717.87 1313.01 719.24 1312.61 719.7 1312.77Z" fill="white"/>
                    <path d="M889.79 1332.11C889.93 1331.74 890.04 1331.12 890.4 1331.25C891.07 1331.48 891.91 1331.43 892.32 1332.29C892.55 1332.76 892.31 1333.23 892.49 1333.61C892.78 1334.24 892.74 1334.61 892.07 1334.97C891.35 1335.36 890.66 1335.22 890.31 1334.69C889.82 1333.95 889.15 1333.1 889.78 1332.11H889.79Z" fill="white"/>
                    <path d="M851.73 1239.54C851.17 1239.3 850.33 1239.33 850.76 1238.26C850.86 1238.02 850.45 1237.59 850.31 1237.24C850.15 1236.84 850.02 1236.42 849.88 1236.02C850.51 1236.18 851.31 1236.14 851.71 1236.53C852.22 1237.03 852.39 1237.87 852.75 1238.55C852.95 1238.92 853.06 1239.3 852.78 1239.6C852.47 1239.93 852.08 1239.66 851.73 1239.55V1239.54Z" fill="white"/>
                    <path d="M809.32 1387.61C809.14 1387.55 808.96 1387.5 808.79 1387.42C808.42 1387.23 807.75 1387.32 807.83 1386.65C807.91 1385.97 808.3 1385.49 809 1385.25C809.6 1385.05 809.81 1385.36 810.06 1385.8C810.23 1386.08 810.63 1386.23 810.81 1386.51C811.04 1386.88 811.4 1387.3 811.08 1387.79C810.7 1388.36 810.26 1387.87 809.85 1387.79C809.67 1387.75 809.5 1387.67 809.32 1387.61Z" fill="white"/>
                    <path d="M713.94 1304.02C713.27 1304.6 712.76 1305.4 711.83 1305.64C711.62 1305.69 711.27 1305.58 711.16 1305.24C711.01 1304.81 710.54 1304.37 710.92 1303.95C711.4 1303.43 711.99 1303.01 712.58 1302.6C713.28 1302.12 713.08 1303.11 713.48 1303.2C713.89 1303.29 714.15 1303.53 713.94 1304.02Z" fill="white"/>
                    <path d="M896.88 1252.54C896.36 1252.45 896.12 1252.18 896.37 1251.58C896.75 1250.66 897.43 1250.31 898.28 1250.57C899.03 1250.8 899.28 1251.4 899.25 1252.04C899.22 1252.77 898.07 1253 896.88 1252.54Z" fill="white"/>
                    <path d="M966.47 1201.25C966.2 1201.15 965.9 1201.1 965.66 1200.95C965.1 1200.6 965.17 1199.8 965.76 1199.52C966.11 1199.36 966.44 1199.12 966.8 1199.04C967.27 1198.94 967.82 1199.11 968.05 1199.48C968.31 1199.91 967.95 1200.54 968.32 1201.02C967.81 1201.18 967.37 1201.73 966.74 1201.35C966.66 1201.3 966.56 1201.28 966.47 1201.25Z" fill="white"/>
                    <path d="M766.28 1314.63C766.43 1314.94 766.53 1315.2 766.67 1315.45C767.02 1316.07 766.12 1316.65 766.62 1317.3C766.89 1317.65 766.74 1318.06 766.33 1318.2C765.94 1318.34 765.65 1318.11 765.48 1317.72C765.25 1317.2 764.96 1316.7 764.77 1316.17C764.68 1315.92 764.16 1315.6 764.79 1315.33C765.3 1315.11 765.79 1314.86 766.28 1314.62V1314.63Z" fill="white"/>
                    <path d="M699.12 1216.37C699.41 1215.83 700.49 1215.61 701.09 1215.97C701.33 1216.11 701.75 1217.84 701.55 1218.12C701.06 1218.82 700.53 1218.21 700.09 1218.04C699.27 1217.74 698.84 1216.88 699.12 1216.37Z" fill="white"/>
                    <path d="M777.22 1198.56C777.11 1199.14 776.87 1199.44 776.26 1199.19C775.51 1198.89 774.95 1198.09 775.14 1197.55C775.33 1197.01 776.29 1196.73 777.05 1197.01C778.18 1197.42 777.22 1198.07 777.22 1198.56Z" fill="white"/>
                    <path d="M782.65 1194.1C782.98 1194.51 783.37 1194.94 783.2 1195.52C783.02 1196.15 781.73 1196.4 781.06 1195.99C780.62 1195.72 780.78 1195.38 780.93 1195.04C781.29 1194.27 782.1 1193.82 782.64 1194.1H782.65Z" fill="white"/>
                    <path d="M762.1 1280.02C761.75 1279.89 761.57 1279.81 761.39 1279.77C760.7 1279.62 760.72 1278.94 760.43 1278.5C760.94 1278.35 761.35 1277.81 761.98 1278.13C762.15 1278.21 762.33 1278.27 762.51 1278.32C763.13 1278.48 763.48 1278.7 763.18 1279.48C762.9 1280.23 762.53 1280.3 762.1 1280.02Z" fill="white"/>
                    <path d="M833.91 1130.64C833.72 1131.29 833.26 1131.27 833 1131.02C832.48 1130.53 832.05 1129.93 831.64 1129.35C831.25 1128.8 831.86 1128.82 832.1 1128.66C833.16 1127.95 833.21 1129.3 833.82 1129.54C834.53 1129.81 833.87 1130.34 833.91 1130.65V1130.64Z" fill="white"/>
                    <path d="M762.65 1245.25C763.44 1245.37 763.7 1245.99 763.7 1246.63C763.7 1247.11 762.25 1247.75 761.98 1247.45C761.47 1246.88 761.41 1246.23 761.73 1245.5C761.96 1244.99 762.28 1245.12 762.65 1245.24V1245.25Z" fill="white"/>
                    <path d="M874.33 1380.72C874.05 1381.21 873.53 1381.36 872.99 1381.42C872.67 1381.46 872.21 1379.94 872.36 1379.37C872.49 1378.85 872.86 1378.93 873.22 1379.07C873.96 1379.36 874.51 1380.17 874.33 1380.72Z" fill="white"/>
                    <path d="M730.41 1269.8C730.59 1269.57 730.87 1269.37 731.1 1269.62C731.65 1270.21 732.34 1270.67 732.68 1271.47C732.82 1271.8 732.73 1272.11 732.57 1272.25C732.41 1272.39 731.94 1272.41 731.81 1272.28C731.31 1271.77 730.88 1271.18 730.44 1270.61C730.25 1270.37 730.26 1270.09 730.41 1269.8Z" fill="white"/>
                    <path d="M699.22 1272.36C699.51 1272.44 699.89 1272.53 699.82 1273.03C699.76 1273.47 699.36 1273.54 699.06 1273.74C698.47 1274.16 697.96 1273.78 697.51 1273.55C697.24 1273.41 696.94 1272.77 697.05 1272.62C697.26 1272.31 697.72 1272.06 698.11 1272C698.44 1271.95 698.81 1272.21 699.22 1272.36Z" fill="white"/>
                    <path d="M782.67 1138.46C782.76 1137.99 783.03 1137.76 783.62 1138C784.36 1138.31 784.9 1139.09 784.72 1139.63C784.42 1140.54 783.51 1139.52 783.05 1140.14C782.97 1140.24 782.49 1139.16 782.68 1138.45L782.67 1138.46Z" fill="white"/>
                    <path d="M803.8 1315.71C803.89 1315.86 804.08 1316.01 804.06 1316.15C803.98 1316.9 802.57 1317.78 801.91 1317.48C801.68 1317.38 801.38 1317.16 801.36 1316.97C801.34 1316.77 801.56 1316.46 801.76 1316.33C802.64 1315.74 803.34 1315.55 803.79 1315.71H803.8Z" fill="white"/>
                    <path d="M911.19 1309.91C911.28 1309.64 911.34 1309.35 911.48 1309.12C911.85 1308.51 912.29 1309.01 912.7 1309.1C913.23 1309.22 913.15 1309.59 913.01 1309.97C912.88 1310.31 912.83 1310.67 912.41 1310.84C911.98 1311.01 911.55 1311.35 911.13 1310.96C910.8 1310.66 911.09 1310.27 911.2 1309.92L911.19 1309.91Z" fill="white"/>
                    <path d="M733.58 1269.26C733.07 1268.95 733.25 1268.6 733.41 1268.27C733.78 1267.49 734.58 1267.06 735.12 1267.35C735.57 1267.6 735.39 1267.97 735.23 1268.3C734.86 1269.08 734.07 1269.5 733.58 1269.26Z" fill="white"/>
                    <path d="M775.19 1135.33C775.8 1135.07 776.31 1134.67 777.04 1135.03C777.64 1135.32 777.55 1135.64 777.39 1136.12C777.22 1136.64 777.03 1136.84 776.41 1136.7C775.6 1136.52 775.54 1135.84 775.19 1135.33Z" fill="white"/>
                    <path d="M789.66 1136.48C789.77 1136.62 789.98 1136.78 789.96 1136.9C789.89 1137.26 789.83 1137.74 789.58 1137.93C789.17 1138.24 788.69 1138.67 788.09 1138.37C787.62 1138.14 787.8 1137.76 787.96 1137.43C788.34 1136.64 789.09 1136.24 789.66 1136.48Z" fill="white"/>
                    <path d="M909.38 1348.99C909.75 1349.13 910.14 1349.29 909.88 1349.76C909.54 1350.37 908.95 1350.72 908.31 1350.4C907.97 1350.23 907.84 1349.66 907.61 1349.28C908.18 1349.04 908.71 1348.54 909.38 1348.99Z" fill="white"/>
                    <path d="M918.88 1197.01C918.61 1197.64 917.62 1198.05 917.2 1197.81C916.76 1197.56 916.97 1197.21 917.07 1196.86C917.27 1196.16 917.81 1195.84 918.43 1195.96C918.61 1195.99 919.01 1196.65 918.88 1197V1197.01Z" fill="white"/>
                    <path d="M760.98 1224.41C761.43 1224.57 761.9 1224.77 761.59 1225.18C761.21 1225.69 760.64 1226.06 760.16 1226.5C759.84 1225.93 759.59 1225.39 759.94 1224.68C760.27 1224.01 760.67 1224.35 760.98 1224.41Z" fill="white"/>
                    <path d="M876.41 1137.93C877.18 1138.11 877.1 1138.49 876.96 1139.02C876.74 1139.84 876.31 1139.73 875.7 1139.52C875.08 1139.3 875.13 1138.98 875.28 1138.45C875.51 1137.67 875.92 1137.61 876.41 1137.94V1137.93Z" fill="white"/>
                    <path d="M751.25 1231.94C751 1232.38 751.08 1233.28 750.22 1232.82C749.75 1232.57 749.46 1232.01 749.6 1231.43C749.83 1230.46 750.74 1231.08 751.23 1230.98C751.84 1230.85 751.17 1231.6 751.25 1231.94Z" fill="white"/>
                    <path d="M682.85 1282.48C683.11 1282.58 683.4 1282.63 683.64 1282.77C684.08 1283.02 683.89 1283.39 683.74 1283.73C683.63 1283.97 683.66 1284.32 683.3 1284.38C682.89 1284.44 682.61 1284.28 682.46 1283.88C682.39 1283.71 682.3 1283.54 682.22 1283.37C682.08 1283.04 681.74 1282.57 681.85 1282.41C682.09 1282.05 682.51 1282.41 682.85 1282.48Z" fill="white"/>
                    <path d="M949.19 1242.77C949.6 1242.22 950.03 1242.66 950.42 1242.77C951.15 1243 950.73 1243.5 950.62 1243.9C950.51 1244.27 950.35 1244.63 949.85 1244.39C949.34 1244.15 948.97 1243.2 949.2 1242.76L949.19 1242.77Z" fill="white"/>
                    <path d="M807.99 1186.27C807.8 1186.73 807.66 1187.11 807.16 1186.87C806.65 1186.63 806.26 1185.67 806.51 1185.24C806.77 1184.81 807.12 1185.06 807.48 1185.13C808.34 1185.31 808.2 1185.81 807.99 1186.27Z" fill="white"/>
                    <path d="M717.82 1251.63C717.93 1251.47 718.07 1251.09 718.24 1251.08C718.7 1251.03 718.72 1251.56 718.77 1251.82C718.84 1252.21 719.8 1252.65 718.78 1253.06C718.44 1253.2 718.11 1253.44 717.76 1253.19C717.04 1252.68 717.86 1252.21 717.82 1251.64V1251.63Z" fill="white"/>
                    <path d="M811.11 1367.4C811.37 1367.77 812.02 1368.28 811.53 1368.6C811.11 1368.88 811.11 1369.68 810.45 1369.51C810.05 1369.41 809.99 1367.9 810.35 1367.48C810.54 1367.26 810.82 1367.24 811.11 1367.4Z" fill="white"/>
                    <path d="M872.34 1155.39C872.34 1155.39 872.84 1155.27 873.3 1155.24C873.5 1155.22 873.84 1156.5 873.7 1156.73C873.32 1157.33 872.9 1156.85 872.5 1156.76C872.01 1156.65 872 1156.31 872.35 1155.4L872.34 1155.39Z" fill="white"/>
                    <path d="M902.49 1171.12C903.05 1171.52 902.59 1171.94 902.49 1172.34C902.37 1172.85 901.94 1172.89 901.66 1172.64C901.34 1172.35 901.14 1171.9 900.97 1171.49C900.91 1171.34 902.26 1170.99 902.48 1171.12H902.49Z" fill="white"/>
                    <path d="M954.16 1339.03C954.09 1339.53 953.8 1339.64 953.54 1339.55C952.85 1339.3 952.63 1338.73 952.71 1338.03C952.75 1337.67 953.04 1337.57 953.31 1337.65C954.04 1337.89 954.18 1338.49 954.16 1339.02V1339.03Z" fill="white"/>
                    <path d="M786.52 1329.26C786.81 1328.72 787.33 1328.42 787.79 1328.59C788.02 1328.67 788.39 1329.27 788.18 1329.8C787.91 1330.51 787.45 1330.1 787.05 1330C786.68 1329.9 786.36 1329.73 786.52 1329.27V1329.26Z" fill="white"/>
                    <path d="M967.96 1245.18C967.91 1245.45 966.8 1246.23 966.72 1246.08C966.51 1245.69 966.29 1245.25 966.28 1244.82C966.26 1244.42 966.6 1244.17 967.05 1244.39C967.42 1244.57 968.04 1244.47 967.97 1245.18H967.96Z" fill="white"/>
                    <path d="M780.64 1328.97C781.07 1329.29 781.44 1329.79 781.17 1330.5C781.07 1330.76 780.8 1330.92 780.5 1330.72C779.92 1330.34 779.61 1329.81 779.85 1329.12C779.94 1328.86 780.21 1328.71 780.64 1328.97Z" fill="white"/>
                    <path d="M746.32 1383.58C746.83 1383.73 746.88 1384.14 746.63 1384.42C746.34 1384.74 745.89 1384.94 745.48 1385.1C745.33 1385.16 744.98 1383.81 745.11 1383.59C745.3 1383.28 745.76 1383.27 746.32 1383.57V1383.58Z" fill="white"/>
                    <path d="M755.99 1231.83C756.37 1231.97 756.74 1232.11 756.52 1232.6C756.28 1233.14 755.74 1233.42 755.28 1233.25C755.05 1233.17 754.68 1232.57 754.89 1232.04C755.16 1231.33 755.62 1231.76 756 1231.83H755.99Z" fill="white"/>
                    <path d="M703.46 1306.45C703.83 1306.85 704.12 1307.25 703.99 1307.82C703.91 1308.16 703.63 1308.29 703.38 1308.17C702.97 1307.96 702.13 1308.13 702.36 1307.23C702.5 1306.68 703.11 1306.76 703.45 1306.46L703.46 1306.45Z" fill="white"/>
                    <path d="M761.91 1243.76C762.01 1243.9 762.1 1244.05 762.2 1244.19C761.46 1244.56 760.64 1244.82 760.16 1245.59C760.14 1245.62 759.79 1245.51 759.65 1245.39C759.57 1245.32 759.58 1244.99 759.62 1244.98C760.34 1244.72 760.54 1243.77 761.37 1243.68C761.54 1243.66 761.73 1243.73 761.91 1243.76Z" fill="white"/>
                    <path d="M761.38 1248.22C761.16 1248.74 760.57 1248.2 760.17 1248.02C759.38 1247.65 760.06 1247.09 760.15 1246.63C760.18 1246.49 760.64 1246.11 760.63 1246.61C760.61 1247.35 762 1246.96 761.39 1248.23L761.38 1248.22Z" fill="white"/>
                    <path d="M932.97 1298.69C933.1 1298 933.78 1298.04 934.2 1297.74C934.36 1298.06 934.73 1298.46 934.65 1298.68C934.46 1299.17 933.95 1299.35 933.39 1299.27C933.06 1299.22 932.98 1298.98 932.97 1298.69Z" fill="white"/>
                    <path d="M866.35 1158.43C866.16 1158.79 866.2 1159.41 865.46 1159.17C864.93 1159 865.02 1158.64 865.17 1158.29C865.33 1157.9 865.32 1157.3 866.05 1157.53C866.58 1157.7 866.46 1158.05 866.34 1158.43H866.35Z" fill="white"/>
                    <path d="M905.88 1370.53C906.45 1371.29 906.42 1371.9 906.08 1372.5C906 1372.64 905.81 1372.72 905.67 1372.82C905.59 1372.65 905.54 1372.46 905.43 1372.32C904.99 1371.78 905.28 1371.26 905.5 1370.77C905.57 1370.62 905.83 1370.56 905.88 1370.53Z" fill="white"/>
                    <path d="M880.82 1132.71C880.96 1132.75 880.9 1133.11 880.74 1133.48C880.57 1133.86 880.6 1134.49 879.86 1134.25C879.34 1134.09 879.4 1133.74 879.56 1133.37C879.72 1132.98 879.72 1132.37 880.82 1132.71Z" fill="white"/>
                    <path d="M835.6 1173.92C835.5 1174 835.36 1174.19 835.27 1174.17C834.64 1174.01 834.04 1173.84 833.83 1173.07C833.74 1172.75 833.74 1172.57 834.06 1172.54C834.57 1172.5 835.63 1173.46 835.6 1173.92Z" fill="white"/>
                    <path d="M913.2 1254.68C913.96 1255.2 913.18 1255.67 913.11 1256.15C913.08 1256.37 912.62 1256.38 912.38 1256.2C911.68 1255.69 912.34 1255.19 912.46 1254.72C912.52 1254.5 912.93 1254.5 913.2 1254.67V1254.68Z" fill="white"/>
                    <path d="M803.64 1231.63C804.4 1232.15 803.62 1232.62 803.55 1233.1C803.52 1233.32 803.06 1233.33 802.82 1233.15C802.12 1232.64 802.78 1232.14 802.9 1231.67C802.96 1231.45 803.37 1231.45 803.64 1231.62V1231.63Z" fill="white"/>
                    <path d="M834.07 1357.13C834.21 1357.17 834.15 1357.53 833.99 1357.9C833.82 1358.28 833.85 1358.91 833.11 1358.67C832.59 1358.51 832.65 1358.16 832.81 1357.79C832.97 1357.4 832.97 1356.79 834.07 1357.13Z" fill="white"/>
                    <path d="M736.63 1195.7C736.04 1195.79 735.42 1195.89 734.81 1195.97C734.78 1195.97 734.69 1195.81 734.7 1195.73C734.76 1195.33 734.99 1195.1 735.37 1194.94C735.8 1194.76 736.21 1194.52 736.58 1194.98C736.67 1195.09 736.61 1195.31 736.63 1195.7Z" fill="white"/>
                    <path d="M737.28 1248.79C737.38 1248.42 737.47 1248.02 737.98 1248.13C738.64 1248.28 738.57 1248.7 738.36 1249.19C738.22 1249.53 738.16 1249.96 737.65 1249.84C736.99 1249.69 737.03 1249.26 737.28 1248.79Z" fill="white"/>
                    <path d="M751.32 1305.55C751.75 1305.87 751.93 1306.07 751.73 1306.37C751.35 1306.95 750.83 1307.25 750.13 1307.02C749.99 1306.98 749.91 1306.75 749.81 1306.61C750.33 1306.24 750.86 1305.87 751.32 1305.54V1305.55Z" fill="white"/>
                    <path d="M799.76 1201.45C800.26 1201.55 800.6 1201.79 800.5 1202.01C800.3 1202.45 800.5 1203.26 799.63 1203.21C799.34 1203.19 799.01 1202.94 799.09 1202.7C799.25 1202.23 799.2 1201.55 799.75 1201.46L799.76 1201.45Z" fill="white"/>
                    <path d="M853.13 1355.35C853.5 1355.5 853.84 1355.62 853.64 1356.14C853.36 1356.86 852.9 1356.46 852.5 1356.35C852.13 1356.24 851.8 1356.09 852 1355.57C852.28 1354.85 852.74 1355.27 853.14 1355.35H853.13Z" fill="white"/>
                    <path d="M882.6 1273.07C883.12 1272.31 883.59 1273.09 884.07 1273.16C884.29 1273.19 884.3 1273.65 884.12 1273.89C883.61 1274.59 883.11 1273.93 882.64 1273.81C882.42 1273.75 882.42 1273.34 882.59 1273.07H882.6Z" fill="white"/>
                    <path d="M740.91 1304.06C741.01 1303.69 741.1 1303.29 741.61 1303.4C742.27 1303.55 742.2 1303.97 741.99 1304.46C741.85 1304.8 741.79 1305.23 741.28 1305.11C740.62 1304.96 740.66 1304.53 740.91 1304.06Z" fill="white"/>
                    <path d="M781.55 1148.61C781.71 1147.97 781.95 1147.88 782.25 1147.95C782.91 1148.11 782.82 1148.55 782.6 1149.01C782.49 1149.25 782.37 1149.77 782.19 1149.62C781.83 1149.32 781.66 1148.8 781.55 1148.61Z" fill="white"/>
                    <path d="M889.47 1353.55C889.58 1353.66 889.76 1353.84 889.94 1354.03C889.46 1354.23 889.05 1354.73 888.45 1354.43C888.19 1354.3 888.08 1354.08 888.21 1353.75C888.49 1353.05 888.93 1353.43 889.47 1353.55Z" fill="white"/>
                    <path d="M684.13 1313.7C684.09 1313.95 683.83 1314.07 683.55 1314C683.18 1313.92 682.8 1313.73 683.05 1313.25C683.23 1312.91 683.1 1312.18 683.73 1312.39C683.96 1312.46 684.32 1313.09 684.12 1313.7H684.13Z" fill="white"/>
                    <path d="M738.8 1144.13C739.46 1144.28 739.43 1144.95 739.71 1145.39C739.45 1145.39 739.17 1145.47 738.94 1145.39C738.55 1145.26 737.95 1145.26 738.17 1144.53C738.27 1144.2 738.48 1144.12 738.79 1144.12L738.8 1144.13Z" fill="white"/>
                    <path d="M715.68 1321.46C715.63 1321.85 715.78 1321.46 715.42 1321.56C715.22 1321.61 714.83 1321.47 714.75 1321.3C714.51 1320.84 714.04 1320.39 714.45 1319.8C714.5 1319.73 714.65 1319.66 714.68 1319.68C715.29 1320.12 715.3 1320.89 715.67 1321.45L715.68 1321.46Z" fill="white"/>
                    <path d="M766.56 1303.79C766.75 1304.29 767.25 1304.76 766.8 1305.31C766.69 1305.44 766.25 1305.44 766.06 1305.33C765.46 1304.98 765.96 1304.53 766.04 1304.13C766.09 1303.85 766.24 1303.67 766.56 1303.79Z" fill="white"/>
                    <path d="M841.96 1250.17C842.14 1250.23 842.35 1250.26 842.48 1250.37C842.57 1250.44 842.63 1250.71 842.57 1250.78C842.36 1251.02 840.68 1250.44 840.6 1250.1C840.58 1250.02 840.81 1249.79 840.88 1249.8C841.25 1249.89 841.6 1250.04 841.95 1250.16L841.96 1250.17Z" fill="white"/>
                    <path d="M785.31 1396.24C785.88 1396.4 785.85 1396.76 785.67 1397.12C785.54 1397.38 785.32 1397.49 784.99 1397.36C784.49 1397.18 784.51 1396.84 784.69 1396.49C784.82 1396.24 785.04 1396.04 785.31 1396.24Z" fill="white"/>
                    <path d="M757.54 1142.22C757.79 1142.4 757.91 1142.58 757.79 1142.92C757.61 1143.42 757.27 1143.39 756.92 1143.22C756.66 1143.09 756.56 1142.87 756.68 1142.54C756.86 1142.04 757.18 1142.1 757.55 1142.22H757.54Z" fill="white"/>
                    <path d="M944.9 1241.41C945.15 1241.59 945.27 1241.77 945.15 1242.11C944.97 1242.61 944.63 1242.58 944.28 1242.41C944.02 1242.28 943.92 1242.06 944.04 1241.73C944.22 1241.23 944.54 1241.29 944.91 1241.41H944.9Z" fill="white"/>
                    <path d="M919.09 1348.06C918.96 1348.4 918.81 1348.69 918.31 1348.51C917.97 1348.39 917.87 1348.17 917.94 1347.89C918.03 1347.5 918.22 1347.23 918.72 1347.4C919.05 1347.52 919.13 1347.72 919.1 1348.06H919.09Z" fill="white"/>
                    <path d="M859.68 1136.04C860.05 1135.66 860.46 1135.39 861.01 1135.51C861.16 1135.54 861.39 1135.81 861.38 1135.82C861.06 1136.25 860.64 1136.51 860.08 1136.38C859.93 1136.35 859.82 1136.17 859.67 1136.04H859.68Z" fill="white"/>
                    <path d="M937.15 1204.31C937.49 1204.36 937.57 1204.63 937.51 1204.91C937.42 1205.3 937.23 1205.57 936.73 1205.4C936.39 1205.28 936.29 1205.07 936.35 1204.78C936.44 1204.39 936.65 1204.09 937.15 1204.31Z" fill="white"/>
                    <path d="M823.29 1190.15C823.54 1190.33 823.66 1190.51 823.54 1190.85C823.36 1191.35 823.02 1191.32 822.67 1191.15C822.41 1191.02 822.31 1190.8 822.43 1190.47C822.61 1189.97 822.93 1190.03 823.3 1190.15H823.29Z" fill="white"/>
                    <path d="M729.99 1364.26C730.28 1364.47 730.4 1364.65 730.28 1364.99C730.1 1365.49 729.76 1365.46 729.41 1365.29C729.15 1365.16 729.05 1364.95 729.17 1364.61C729.35 1364.09 729.69 1364.2 729.99 1364.26Z" fill="white"/>
                    <path d="M733.32 1340.9C733.17 1341.43 732.81 1341.4 732.45 1341.22C732.19 1341.09 732.08 1340.87 732.21 1340.54C732.39 1340.04 732.73 1340.07 733.08 1340.24C733.34 1340.37 733.5 1340.6 733.32 1340.9Z" fill="white"/>
                    <path d="M897.24 1122.02C896.89 1121.88 896.53 1121.74 896.76 1121.25C896.83 1121.1 896.97 1120.98 897.08 1120.85C897.3 1121.2 897.53 1121.54 897.75 1121.89C897.58 1121.93 897.41 1121.97 897.24 1122.02Z" fill="white"/>
                    <path d="M898.67 1174.05C899.02 1174.19 899.38 1174.33 899.15 1174.82C899.08 1174.97 898.94 1175.09 898.83 1175.22C898.61 1174.87 898.38 1174.53 898.16 1174.18C898.33 1174.14 898.5 1174.1 898.67 1174.05Z" fill="white"/>
                    <path d="M765.9 1162.47C766.08 1162.82 766.24 1163.15 766.4 1163.48C766.14 1163.48 765.85 1163.58 765.64 1163.49C765.18 1163.29 765.34 1162.89 765.52 1162.57C765.57 1162.48 765.79 1162.49 765.9 1162.47Z" fill="white"/>
                    <path d="M879.87 1280.77C879.73 1280.94 879.57 1281.2 879.51 1281.18C879.03 1281.03 879.09 1280.49 878.85 1280.16C879.02 1280.12 879.2 1280.02 879.35 1280.05C879.74 1280.14 880.01 1280.34 879.87 1280.77Z" fill="white"/>
                    <path d="M953.7 1334.18C953.78 1334.3 953.96 1334.43 953.94 1334.53C953.87 1334.89 953.75 1335.3 953.26 1335.15C953.04 1335.09 952.87 1334.83 952.68 1334.66C953.01 1334.5 953.35 1334.34 953.7 1334.18Z" fill="white"/>
                    <path d="M938.1 1310.12C938.25 1309.62 938.62 1309.69 938.96 1309.86C939.1 1309.93 939.17 1310.13 939.28 1310.27C938.89 1310.4 938.5 1310.52 938.1 1310.65C938.1 1310.48 938.1 1310.31 938.1 1310.13V1310.12Z" fill="white"/>
                    <path d="M782.67 1178.89C782.72 1178.95 782.9 1179.08 782.89 1179.17C782.83 1179.53 782.71 1179.94 782.22 1179.81C782 1179.75 781.83 1179.49 781.63 1179.33C781.97 1179.19 782.3 1179.04 782.67 1178.89Z" fill="white"/>
                    <path d="M817.3 1192.93C817.8 1193.08 817.73 1193.45 817.56 1193.79C817.49 1193.93 817.29 1194 817.15 1194.11C817.02 1193.72 816.9 1193.33 816.77 1192.93C816.94 1192.93 817.11 1192.93 817.29 1192.93H817.3Z" fill="white"/>
                    <path d="M963.5 1270.11C963.61 1270.25 963.72 1270.38 963.83 1270.52C963.45 1270.67 963.06 1270.81 962.68 1270.96C962.68 1270.79 962.62 1270.61 962.66 1270.45C962.77 1269.95 963.12 1269.99 963.5 1270.12V1270.11Z" fill="white"/>
                    <path d="M751.78 1340.72C751.93 1340.22 752.3 1340.29 752.64 1340.46C752.78 1340.53 752.85 1340.73 752.96 1340.87C752.57 1341 752.18 1341.12 751.78 1341.25C751.78 1341.08 751.78 1340.91 751.78 1340.73V1340.72Z" fill="white"/>
                    <path d="M740.64 1378.82C740.88 1378.77 741.06 1378.68 741.21 1378.72C741.6 1378.81 741.88 1379.03 741.66 1379.49C741.59 1379.64 741.44 1379.75 741.32 1379.87C741.1 1379.53 740.87 1379.18 740.64 1378.82Z" fill="white"/>
                    <path d="M706.96 1167.86C707.21 1168.16 706.99 1168.41 706.89 1168.64C706.86 1168.71 706.55 1168.77 706.49 1168.71C706.23 1168.46 706.46 1168.19 706.56 1167.95C706.59 1167.87 706.83 1167.88 706.96 1167.85V1167.86Z" fill="white"/>
                    <path d="M731.64 1162.7C731.96 1162.72 732.23 1162.69 732.47 1162.76C732.61 1162.8 732.69 1163.02 732.79 1163.16C732.53 1163.17 732.26 1163.23 732.02 1163.17C731.89 1163.14 731.8 1162.91 731.64 1162.69V1162.7Z" fill="white"/>
                    <path d="M842.64 1391.58C842.89 1391.88 842.67 1392.13 842.57 1392.36C842.54 1392.43 842.23 1392.49 842.17 1392.43C841.91 1392.18 842.14 1391.91 842.24 1391.67C842.27 1391.59 842.51 1391.6 842.64 1391.57V1391.58Z" fill="white"/>
                    <path d="M935.52 1337.36C935.5 1337.68 935.53 1337.95 935.46 1338.19C935.42 1338.33 935.2 1338.41 935.06 1338.51C935.05 1338.25 934.99 1337.98 935.05 1337.74C935.08 1337.61 935.31 1337.52 935.53 1337.36H935.52Z" fill="white"/>
                    <path d="M936.19 1334.33C936.13 1334.47 936.06 1334.72 935.91 1334.92C935.74 1335.15 935.54 1335.08 935.56 1334.8C935.58 1334.56 935.67 1334.29 935.81 1334.09C935.97 1333.85 936.13 1333.92 936.18 1334.33H936.19Z" fill="white"/>
                    <path d="M855.05 1403.87C855.16 1403.74 855.3 1403.49 855.39 1403.51C855.73 1403.57 855.72 1403.84 855.58 1404.08C855.5 1404.23 855.33 1404.46 855.24 1404.44C854.9 1404.38 854.93 1404.12 855.04 1403.87H855.05Z" fill="white"/>
                    <path d="M760.98 1147.74C761.11 1147.85 761.36 1147.99 761.34 1148.08C761.28 1148.42 761.01 1148.41 760.77 1148.27C760.62 1148.19 760.39 1148.02 760.41 1147.93C760.47 1147.59 760.73 1147.62 760.98 1147.73V1147.74Z" fill="white"/>
                    <path d="M956.08 1262.82C956.4 1262.84 956.67 1262.81 956.91 1262.88C957.05 1262.92 957.13 1263.14 957.23 1263.28C956.97 1263.29 956.7 1263.35 956.46 1263.29C956.33 1263.26 956.24 1263.03 956.08 1262.81V1262.82Z" fill="white"/>
                    <path d="M704.85 1292.18C704.93 1292.28 705.03 1292.42 705.14 1292.56C704.88 1292.57 704.61 1292.63 704.37 1292.57C704.24 1292.54 704.15 1292.31 704.05 1292.17C704.3 1292.01 704.56 1291.99 704.85 1292.17V1292.18Z" fill="white"/>
                    <path d="M968.79 1220.46C968.72 1220.73 968.57 1220.95 968.28 1220.78C968.2 1220.74 968.22 1220.45 968.25 1220.29C968.3 1220.01 968.46 1219.8 968.76 1219.97C968.84 1220.02 968.79 1220.29 968.8 1220.47L968.79 1220.46Z" fill="white"/>
                    <path d="M741.32 1306.35C741.25 1306.62 741.1 1306.84 740.81 1306.67C740.73 1306.63 740.75 1306.34 740.78 1306.18C740.83 1305.9 740.99 1305.69 741.29 1305.86C741.37 1305.91 741.32 1306.18 741.33 1306.36L741.32 1306.35Z" fill="white"/>
                    <path d="M752.39 1301.09C752.32 1301.36 752.17 1301.58 751.88 1301.41C751.8 1301.37 751.82 1301.08 751.85 1300.92C751.9 1300.64 752.06 1300.43 752.36 1300.6C752.44 1300.65 752.39 1300.92 752.4 1301.1L752.39 1301.09Z" fill="white"/>
                    <path d="M790.03 1276.1C790.35 1276.12 790.62 1276.09 790.86 1276.16C791 1276.2 791.08 1276.42 791.18 1276.56C790.92 1276.57 790.65 1276.63 790.41 1276.57C790.28 1276.54 790.19 1276.31 790.03 1276.09V1276.1Z" fill="white"/>
                    <path d="M775.6 1171.91C775.75 1172.07 775.92 1172.26 776.1 1172.44C775.93 1172.48 775.72 1172.61 775.6 1172.55C775.38 1172.44 775.21 1172.23 775.01 1172.06C775.18 1172.02 775.35 1171.98 775.6 1171.91Z" fill="white"/>
                    <path d="M850.97 1224.57C851.05 1224.67 851.15 1224.81 851.26 1224.95C851 1224.96 850.73 1225.02 850.49 1224.96C850.36 1224.93 850.27 1224.7 850.17 1224.56C850.42 1224.4 850.68 1224.38 850.97 1224.56V1224.57Z" fill="white"/>
                    <path d="M872.65 1185.28C872.97 1185.3 873.24 1185.27 873.48 1185.34C873.62 1185.38 873.7 1185.6 873.8 1185.74C873.54 1185.75 873.27 1185.81 873.03 1185.75C872.9 1185.72 872.81 1185.49 872.65 1185.27V1185.28Z" fill="white"/>
                    <path d="M906.17 1123.45C906.42 1123.75 906.2 1124 906.1 1124.23C906.07 1124.3 905.76 1124.36 905.7 1124.3C905.44 1124.05 905.67 1123.78 905.77 1123.54C905.8 1123.46 906.04 1123.47 906.17 1123.44V1123.45Z" fill="white"/>
                    <path d="M913.55 1186.08C913.48 1186.35 913.33 1186.57 913.04 1186.4C912.96 1186.36 912.98 1186.07 913.01 1185.91C913.06 1185.63 913.22 1185.42 913.52 1185.59C913.6 1185.64 913.55 1185.91 913.56 1186.09L913.55 1186.08Z" fill="white"/>
                    <path d="M837.37 1374.27C837.48 1374.14 837.62 1373.89 837.71 1373.91C838.05 1373.97 838.04 1374.24 837.9 1374.48C837.82 1374.63 837.65 1374.86 837.56 1374.84C837.22 1374.78 837.25 1374.52 837.36 1374.27H837.37Z" fill="white"/>
                    <path d="M854.42 1281.59C854.55 1281.7 854.8 1281.84 854.78 1281.93C854.72 1282.27 854.45 1282.26 854.21 1282.12C854.06 1282.04 853.83 1281.87 853.85 1281.78C853.91 1281.44 854.17 1281.47 854.42 1281.58V1281.59Z" fill="white"/>
                    <path d="M868.86 1285.81C869 1285.79 869.15 1285.75 869.29 1285.77C869.34 1285.77 869.37 1285.92 869.41 1286C869.27 1286.02 869.12 1286.06 868.98 1286.04C868.93 1286.04 868.9 1285.89 868.86 1285.81Z" fill="white"/>
                    <path d="M973.72 1270.38C973.86 1270.36 974.01 1270.32 974.15 1270.34C974.2 1270.34 974.23 1270.49 974.27 1270.57C974.13 1270.59 973.98 1270.63 973.84 1270.61C973.79 1270.61 973.76 1270.46 973.72 1270.38Z" fill="white"/>
                    <path d="M759.15 1291.32C759.24 1291.42 759.34 1291.53 759.43 1291.64C759.35 1291.68 759.23 1291.77 759.19 1291.75C759.07 1291.68 758.98 1291.55 758.89 1291.45C758.97 1291.41 759.05 1291.37 759.15 1291.33V1291.32Z" fill="white"/>
                    <path d="M732.16 1327.54C732.11 1327.44 732.07 1327.36 732.04 1327.28C732.18 1327.26 732.33 1327.22 732.47 1327.24C732.52 1327.24 732.55 1327.39 732.59 1327.47C732.45 1327.49 732.31 1327.52 732.17 1327.54H732.16Z" fill="white"/>
                    <path d="M758.99 1247.84C758.89 1247.93 758.78 1248.03 758.67 1248.12C758.63 1248.04 758.54 1247.92 758.56 1247.88C758.63 1247.76 758.76 1247.67 758.86 1247.58C758.9 1247.66 758.94 1247.74 758.98 1247.84H758.99Z" fill="white"/>
                    <path d="M800.17 1229.77C800.19 1229.91 800.23 1230.06 800.21 1230.2C800.21 1230.25 800.06 1230.28 799.98 1230.32C799.96 1230.18 799.92 1230.03 799.94 1229.89C799.94 1229.84 800.09 1229.81 800.17 1229.77Z" fill="white"/>
                    <path d="M820.48 1187.1C820.38 1187.15 820.3 1187.19 820.22 1187.22C820.2 1187.08 820.16 1186.93 820.18 1186.79C820.18 1186.74 820.33 1186.71 820.41 1186.67C820.43 1186.81 820.46 1186.95 820.48 1187.09V1187.1Z" fill="white"/>
                    <path d="M774.78 1150.9C774.87 1151 774.97 1151.11 775.06 1151.22C774.98 1151.26 774.86 1151.35 774.82 1151.33C774.7 1151.26 774.61 1151.13 774.52 1151.03C774.6 1150.99 774.68 1150.95 774.78 1150.91V1150.9Z" fill="white"/>
                    <path d="M750.89 1265.07C750.94 1265.17 750.98 1265.25 751.01 1265.33C750.87 1265.35 750.72 1265.39 750.58 1265.37C750.53 1265.37 750.5 1265.22 750.46 1265.14C750.6 1265.12 750.74 1265.09 750.88 1265.07H750.89Z" fill="white"/>
                    <path d="M821.98 1250.94C821.93 1250.84 821.89 1250.76 821.86 1250.68C822 1250.66 822.15 1250.62 822.29 1250.64C822.34 1250.64 822.37 1250.79 822.41 1250.87C822.27 1250.89 822.13 1250.92 821.99 1250.94H821.98Z" fill="white"/>
                    <path d="M879.51 1123.32C879.6 1123.28 879.73 1123.2 879.74 1123.21C879.84 1123.32 879.96 1123.44 879.97 1123.57C880 1123.84 879.85 1123.91 879.66 1123.72C879.57 1123.63 879.56 1123.45 879.51 1123.32Z" fill="white"/>
                    <path d="M880.14 1123.9C880.19 1124.04 880.31 1124.23 880.26 1124.3C880.12 1124.49 879.94 1124.46 879.9 1124.29C879.87 1124.14 879.9 1123.97 879.9 1123.81C879.98 1123.84 880.06 1123.87 880.14 1123.9Z" fill="white"/>
                    <path d="M873.46 1314.84C873.55 1314.94 873.65 1315.05 873.74 1315.16C873.66 1315.2 873.54 1315.29 873.5 1315.27C873.38 1315.2 873.29 1315.07 873.2 1314.97C873.28 1314.93 873.36 1314.89 873.46 1314.85V1314.84Z" fill="white"/>
                    <path d="M800.58 1095.67C800.67 1095.77 800.77 1095.88 800.86 1095.99C800.78 1096.03 800.66 1096.12 800.62 1096.1C800.5 1096.03 800.41 1095.9 800.32 1095.8C800.4 1095.76 800.48 1095.72 800.58 1095.68V1095.67Z" fill="white"/>
                    <path d="M886.16 1119.39C886.21 1119.49 886.25 1119.57 886.28 1119.65C886.14 1119.67 885.99 1119.71 885.85 1119.69C885.8 1119.69 885.77 1119.54 885.73 1119.46C885.87 1119.44 886.01 1119.41 886.15 1119.39H886.16Z" fill="white"/>
                    <path d="M778.13 1131.67C778.04 1131.57 777.94 1131.46 777.85 1131.35C777.93 1131.31 778.05 1131.22 778.09 1131.24C778.21 1131.31 778.3 1131.44 778.39 1131.54C778.31 1131.58 778.23 1131.62 778.13 1131.66V1131.67Z" fill="white"/>
                    <path d="M888.52 1220.83C888.61 1220.93 888.71 1221.04 888.8 1221.15C888.72 1221.19 888.6 1221.28 888.56 1221.26C888.44 1221.19 888.35 1221.06 888.26 1220.96C888.34 1220.92 888.42 1220.88 888.52 1220.84V1220.83Z" fill="white"/>
                    <path d="M754.43 1217.57C754.33 1217.62 754.25 1217.66 754.17 1217.69C754.15 1217.55 754.11 1217.4 754.13 1217.26C754.13 1217.21 754.28 1217.18 754.36 1217.14C754.38 1217.28 754.41 1217.42 754.43 1217.56V1217.57Z" fill="white"/>
                    <path d="M887.25 1153.12C887.39 1153.1 887.54 1153.06 887.68 1153.08C887.73 1153.08 887.76 1153.23 887.8 1153.31C887.66 1153.33 887.51 1153.37 887.37 1153.35C887.32 1153.35 887.29 1153.2 887.25 1153.12Z" fill="white"/>
                    <path d="M756.15 1204.99C756.24 1205.09 756.34 1205.2 756.43 1205.31C756.35 1205.35 756.23 1205.44 756.19 1205.42C756.07 1205.35 755.98 1205.22 755.89 1205.12L756.15 1205V1204.99Z" fill="white"/>
                    <path d="M847.24 1272.68C847.34 1272.59 847.45 1272.49 847.56 1272.4C847.6 1272.48 847.69 1272.6 847.67 1272.64C847.6 1272.76 847.47 1272.85 847.37 1272.94C847.33 1272.86 847.29 1272.78 847.25 1272.68H847.24Z" fill="white"/>
                    <path d="M863.31 1406.52C863.45 1406.5 863.6 1406.46 863.74 1406.48C863.79 1406.48 863.82 1406.63 863.86 1406.71C863.72 1406.73 863.57 1406.77 863.43 1406.75C863.38 1406.75 863.35 1406.6 863.31 1406.52Z" fill="white"/>
                    <path d="M735.18 1401.83C733.45 1401.97 732.77 1400.8 732.15 1399.68C731.92 1399.27 731.67 1398.58 731.47 1398.42C730.23 1397.34 732.01 1396.56 731.62 1395.64C732.58 1395.4 733.64 1394.78 734.46 1395.02C735.24 1395.24 736.19 1395.6 736.86 1396.21C737.5 1396.79 738.65 1397.3 737.47 1398.46C737.3 1398.63 737.47 1399.14 737.45 1399.49C737.35 1401.05 736.55 1401.83 735.18 1401.83Z" fill="white"/>
                    <path d="M825.21 1293.69C825.21 1294.72 824.39 1295.45 824.02 1295.44C822.62 1295.41 822.06 1296.69 820.96 1297.1C820.18 1297.39 819.66 1297.44 819.13 1296.84C818.25 1295.85 818.14 1295.25 818.71 1294.47C818.96 1294.13 819.16 1293.78 819.36 1293.41C820.21 1291.81 823.83 1291.15 824.9 1292.94C825.09 1293.26 825.26 1293.52 825.21 1293.69Z" fill="white"/>
                    <path d="M742.51 1308.33C741.85 1308.95 742.05 1310.26 740.8 1310.36C740.62 1310.37 740.42 1310.31 740.27 1310.38C739.68 1310.68 739.15 1311.22 738.4 1310.83C737.82 1310.52 737.72 1309.88 737.45 1309.36C736.93 1308.34 737.45 1306.8 738.52 1306.11C739.34 1305.58 740.13 1305.6 741.01 1306.08C741.93 1306.58 742.04 1307.55 742.51 1308.33Z" fill="white"/>
                    <path d="M684.23 1197.82C684.23 1196.28 684.79 1195.57 685.9 1195.43C686.51 1195.35 687.14 1195.43 687.76 1195.39C688.35 1195.35 688.53 1195.6 688.5 1196.16C688.47 1196.78 688.59 1197.42 688.49 1198.02C688.31 1199.04 687.62 1199.73 686.7 1200.15C686.51 1200.24 686.13 1200.21 686 1200.07C685.51 1199.58 684.9 1199.29 684.35 1198.91C684.08 1198.73 684.23 1197.92 684.23 1197.81V1197.82Z" fill="white"/>
                    <path d="M799.53 1180.02C799.59 1180.72 799.7 1181.71 798.62 1182.01C798.11 1182.15 797.14 1182.42 796.94 1181.78C796.72 1181.09 795.91 1180.5 796.34 1179.65C796.64 1179.06 797.17 1178.54 797.32 1177.93C797.46 1177.36 797.64 1177.25 798.16 1177.23C798.91 1177.21 799.32 1177.64 799.48 1178.24C799.62 1178.74 799.52 1179.3 799.52 1180.02H799.53Z" fill="white"/>
                    <path d="M664.98 1196.41C665 1197.11 664.46 1197.72 663.76 1197.77C663.53 1197.79 661.32 1196.56 661.23 1196.37C661.03 1195.94 661.9 1194.56 661.95 1194.63C662.58 1195.56 663.1 1194.29 663.65 1194.6C664.17 1194.9 664.96 1195.97 664.97 1196.42L664.98 1196.41Z" fill="white"/>
                    <path d="M702.33 1350.28C702.7 1350.28 703.27 1350.18 703.27 1350.54C703.28 1351.19 703.59 1351.91 702.98 1352.54C702.64 1352.89 702.16 1352.83 701.88 1353.11C701.42 1353.56 701.09 1353.65 700.57 1353.18C700 1352.68 699.91 1352.03 700.26 1351.56C700.75 1350.9 701.27 1350.05 702.33 1350.28Z" fill="white"/>
                    <path d="M770.61 1287.75C770.64 1287.19 770.35 1286.47 771.41 1286.5C771.65 1286.5 771.89 1286.02 772.15 1285.79C772.45 1285.52 772.77 1285.28 773.08 1285.02C773.14 1285.61 773.43 1286.3 773.22 1286.77C772.95 1287.37 772.27 1287.79 771.8 1288.31C771.54 1288.6 771.25 1288.82 770.9 1288.67C770.51 1288.51 770.62 1288.08 770.61 1287.74V1287.75Z" fill="white"/>
                    <path d="M628.52 1298.07C628.52 1297.9 628.5 1297.72 628.52 1297.55C628.57 1297.17 628.28 1296.62 628.88 1296.47C629.5 1296.32 630.04 1296.5 630.46 1297.04C630.82 1297.5 630.62 1297.78 630.32 1298.14C630.13 1298.37 630.13 1298.77 629.94 1299.01C629.69 1299.33 629.44 1299.78 628.92 1299.65C628.3 1299.5 628.59 1298.97 628.53 1298.59C628.5 1298.42 628.53 1298.24 628.53 1298.07H628.52Z" fill="white"/>
                    <path d="M670.74 1188.61C670.03 1188.22 669.16 1188.02 668.66 1187.29C668.55 1187.12 668.53 1186.79 668.79 1186.58C669.11 1186.32 669.35 1185.76 669.84 1185.96C670.44 1186.21 671 1186.59 671.54 1186.97C672.17 1187.42 671.25 1187.57 671.31 1187.94C671.36 1188.33 671.24 1188.62 670.75 1188.6L670.74 1188.61Z" fill="white"/>
                    <path d="M773.7 1331.1C773.62 1330.62 773.77 1330.32 774.37 1330.35C775.29 1330.39 775.81 1330.87 775.85 1331.69C775.89 1332.42 775.44 1332.82 774.89 1333C774.25 1333.21 773.68 1332.28 773.7 1331.1Z" fill="white"/>
                    <path d="M840.4 1375.21C840.4 1374.94 840.35 1374.67 840.4 1374.42C840.53 1373.82 841.24 1373.63 841.67 1374.05C841.92 1374.3 842.23 1374.51 842.41 1374.8C842.64 1375.18 842.68 1375.71 842.43 1376.02C842.14 1376.38 841.48 1376.27 841.18 1376.75C840.88 1376.35 840.26 1376.15 840.39 1375.48C840.41 1375.4 840.39 1375.3 840.39 1375.21H840.4Z" fill="white"/>
                    <path d="M678.19 1237.45C677.97 1237.67 677.78 1237.85 677.6 1238.05C677.17 1238.55 676.38 1237.95 675.98 1238.6C675.76 1238.94 675.36 1238.95 675.11 1238.63C674.87 1238.33 674.97 1238.01 675.25 1237.73C675.63 1237.37 675.97 1236.96 676.37 1236.62C676.56 1236.46 676.67 1235.91 677.11 1236.37C677.46 1236.74 677.84 1237.09 678.2 1237.44L678.19 1237.45Z" fill="white"/>
                    <path d="M742.14 1147.84C742.7 1147.92 743.23 1148.79 743.11 1149.42C743.06 1149.67 741.7 1150.59 741.39 1150.51C740.63 1150.31 740.99 1149.65 740.99 1149.22C740.99 1148.41 741.6 1147.77 742.14 1147.84Z" fill="white"/>
                    <path d="M782.48 1209.99C781.94 1210.08 781.61 1209.96 781.63 1209.35C781.66 1208.6 782.17 1207.86 782.7 1207.86C783.23 1207.86 783.78 1208.6 783.78 1209.34C783.78 1210.45 782.92 1209.82 782.49 1209.98L782.48 1209.99Z" fill="white"/>
                    <path d="M788.08 1213.29C787.83 1213.71 787.58 1214.18 787.02 1214.23C786.41 1214.28 785.79 1213.23 785.93 1212.52C786.03 1212.05 786.37 1212.08 786.71 1212.11C787.49 1212.18 788.14 1212.74 788.07 1213.3L788.08 1213.29Z" fill="white"/>
                    <path d="M706.92 1222.8C706.92 1222.45 706.93 1222.28 706.92 1222.11C706.83 1221.47 707.43 1221.27 707.71 1220.87C708 1221.26 708.6 1221.45 708.53 1222.09C708.51 1222.26 708.52 1222.44 708.53 1222.61C708.58 1223.2 708.51 1223.57 707.73 1223.56C706.98 1223.56 706.81 1223.26 706.92 1222.79V1222.8Z" fill="white"/>
                    <path d="M859.51 1237.61C858.89 1237.66 858.76 1237.24 858.89 1236.94C859.15 1236.33 859.53 1235.77 859.91 1235.22C860.26 1234.71 860.44 1235.24 860.66 1235.4C861.62 1236.09 860.45 1236.57 860.45 1237.18C860.45 1237.89 859.77 1237.48 859.52 1237.61H859.51Z" fill="white"/>
                    <path d="M737.29 1212.21C737.44 1212.94 736.98 1213.36 736.43 1213.56C736.01 1213.71 734.99 1212.65 735.17 1212.33C735.51 1211.71 736.05 1211.44 736.78 1211.5C737.29 1211.54 737.29 1211.86 737.29 1212.21Z" fill="white"/>
                    <path d="M655.19 1352.33C654.67 1352.24 654.38 1351.84 654.15 1351.39C654.02 1351.12 655.19 1350.24 655.73 1350.19C656.22 1350.14 656.27 1350.48 656.27 1350.84C656.26 1351.57 655.73 1352.31 655.19 1352.33Z" fill="white"/>
                    <path d="M705.71 1192.03C705.97 1192.11 706.23 1192.29 706.09 1192.57C705.75 1193.23 705.57 1193.99 704.99 1194.53C704.74 1194.76 704.45 1194.78 704.27 1194.68C704.1 1194.59 703.93 1194.18 704 1194.03C704.29 1193.43 704.66 1192.87 705.02 1192.31C705.17 1192.07 705.42 1191.98 705.71 1192.02V1192.03Z" fill="white"/>
                    <path d="M693.55 1165.75C693.58 1166.03 693.62 1166.38 693.16 1166.48C692.76 1166.56 692.57 1166.24 692.3 1166.05C691.75 1165.67 691.91 1165.11 691.98 1164.64C692.02 1164.36 692.48 1163.9 692.64 1163.94C692.98 1164.03 693.34 1164.34 693.51 1164.66C693.66 1164.93 693.55 1165.33 693.55 1165.74V1165.75Z" fill="white"/>
                    <path d="M836.41 1195.59C836.84 1195.52 837.14 1195.68 837.11 1196.27C837.07 1197.01 836.57 1197.73 836.04 1197.74C835.15 1197.77 835.75 1196.65 835.06 1196.45C834.94 1196.42 835.73 1195.65 836.41 1195.59Z" fill="white"/>
                    <path d="M689.2 1270.38C689.1 1270.51 689.03 1270.72 688.91 1270.75C688.23 1270.92 687.02 1269.97 687.07 1269.31C687.09 1269.08 687.18 1268.75 687.34 1268.67C687.51 1268.59 687.84 1268.68 688.02 1268.81C688.81 1269.39 689.2 1269.93 689.21 1270.38H689.2Z" fill="white"/>
                    <path d="M728.43 1361.8C728.69 1361.8 728.96 1361.75 729.21 1361.8C729.86 1361.93 729.57 1362.47 729.62 1362.85C729.69 1363.35 729.33 1363.4 728.97 1363.39C728.64 1363.39 728.3 1363.46 728.02 1363.14C727.74 1362.82 727.3 1362.55 727.51 1362.06C727.67 1361.68 728.1 1361.81 728.43 1361.79V1361.8Z" fill="white"/>
                    <path d="M707.18 1194.61C707.28 1194.07 707.65 1194.11 707.99 1194.14C708.79 1194.21 709.42 1194.77 709.33 1195.33C709.26 1195.8 708.88 1195.76 708.54 1195.73C707.74 1195.65 707.13 1195.1 707.18 1194.61Z" fill="white"/>
                    <path d="M836.75 1188.1C837.17 1188.55 837.68 1188.86 837.6 1189.61C837.54 1190.22 837.24 1190.25 836.77 1190.26C836.26 1190.28 836.03 1190.17 835.95 1189.59C835.85 1188.83 836.42 1188.56 836.75 1188.09V1188.1Z" fill="white"/>
                    <path d="M840.36 1201.03C840.27 1201.17 840.2 1201.41 840.09 1201.42C839.75 1201.47 839.32 1201.58 839.07 1201.42C838.67 1201.17 838.15 1200.89 838.21 1200.26C838.26 1199.78 838.65 1199.81 838.99 1199.85C839.8 1199.93 840.39 1200.45 840.35 1201.03H840.36Z" fill="white"/>
                    <path d="M693.91 1372.67C693.91 1373.03 693.89 1373.42 693.4 1373.35C692.76 1373.25 692.27 1372.85 692.34 1372.19C692.38 1371.84 692.83 1371.54 693.09 1371.22C693.48 1371.64 694.08 1371.94 693.91 1372.67Z" fill="white"/>
                    <path d="M828.93 1332.53C828.29 1332.49 827.63 1331.77 827.7 1331.32C827.77 1330.86 828.15 1330.93 828.48 1330.91C829.15 1330.87 829.6 1331.23 829.7 1331.8C829.73 1331.97 829.28 1332.52 828.94 1332.53H828.93Z" fill="white"/>
                    <path d="M754.86 1204.12C754.86 1204.56 754.84 1205.03 754.39 1204.9C753.82 1204.74 753.32 1204.36 752.79 1204.07C753.19 1203.61 753.57 1203.22 754.3 1203.3C754.99 1203.38 754.82 1203.83 754.86 1204.12Z" fill="white"/>
                    <path d="M866.72 1276.84C866.81 1277.57 866.46 1277.62 865.95 1277.66C865.16 1277.73 865.13 1277.32 865.12 1276.72C865.12 1276.11 865.41 1276.05 865.92 1276.02C866.67 1275.97 866.85 1276.31 866.72 1276.84Z" fill="white"/>
                    <path d="M745.23 1198.07C744.76 1197.99 744.01 1198.35 744.13 1197.45C744.2 1196.96 744.59 1196.53 745.14 1196.47C746.06 1196.36 745.81 1197.35 746.05 1197.74C746.36 1198.23 745.49 1197.88 745.22 1198.07H745.23Z" fill="white"/>
                    <path d="M679.55 1154.75C679.55 1155.01 679.59 1155.28 679.55 1155.53C679.47 1155.99 679.09 1155.94 678.75 1155.92C678.51 1155.9 678.21 1156.04 678.05 1155.75C677.86 1155.42 677.92 1155.11 678.21 1154.86C678.34 1154.75 678.45 1154.61 678.58 1154.49C678.82 1154.26 679.12 1153.82 679.3 1153.86C679.69 1153.96 679.52 1154.43 679.56 1154.75H679.55Z" fill="white"/>
                    <path d="M798.84 1373.43C799.45 1373.61 799.21 1374.12 799.22 1374.5C799.25 1375.21 798.68 1375 798.3 1375.03C797.94 1375.06 797.58 1375.03 797.63 1374.51C797.68 1373.99 798.38 1373.36 798.84 1373.42V1373.43Z" fill="white"/>
                    <path d="M802.95 1232.81C802.49 1232.79 802.12 1232.79 802.17 1232.28C802.22 1231.76 802.93 1231.11 803.38 1231.2C803.84 1231.29 803.73 1231.67 803.78 1232C803.9 1232.8 803.42 1232.84 802.95 1232.81Z" fill="white"/>
                    <path d="M717.48 1175.31C717.66 1175.36 718.02 1175.35 718.09 1175.5C718.28 1175.89 717.83 1176.07 717.62 1176.19C717.3 1176.38 717.22 1177.35 716.54 1176.6C716.31 1176.35 716 1176.14 716.1 1175.75C716.31 1174.96 716.98 1175.52 717.47 1175.31H717.48Z" fill="white"/>
                    <path d="M646.64 1293.19C646.4 1293.53 646.17 1294.26 645.73 1293.94C645.36 1293.67 644.66 1293.92 644.6 1293.29C644.56 1292.91 645.85 1292.37 646.33 1292.56C646.58 1292.66 646.69 1292.89 646.64 1293.19Z" fill="white"/>
                    <path d="M850.26 1278.86C850.26 1278.86 850.52 1279.26 850.7 1279.65C850.78 1279.82 849.78 1280.52 849.54 1280.47C848.9 1280.33 849.18 1279.81 849.13 1279.43C849.07 1278.97 849.36 1278.86 850.27 1278.86H850.26Z" fill="white"/>
                    <path d="M846.2 1310.05C846.03 1310.66 845.52 1310.39 845.14 1310.44C844.66 1310.5 844.49 1310.14 844.62 1309.82C844.77 1309.45 845.1 1309.14 845.4 1308.86C845.51 1308.76 846.24 1309.82 846.2 1310.05Z" fill="white"/>
                    <path d="M716.82 1408.38C716.36 1408.48 716.17 1408.26 716.17 1408.01C716.17 1407.33 716.6 1406.96 717.22 1406.8C717.54 1406.72 717.73 1406.94 717.74 1407.2C717.77 1407.91 717.29 1408.22 716.82 1408.38Z" fill="white"/>
                    <path d="M671.93 1259.69C672.49 1259.77 672.92 1260.12 672.92 1260.58C672.92 1260.8 672.52 1261.32 671.99 1261.31C671.29 1261.3 671.49 1260.77 671.46 1260.39C671.43 1260.03 671.48 1259.71 671.93 1259.69Z" fill="white"/>
                    <path d="M802.72 1390.5C802.47 1390.54 801.44 1389.83 801.54 1389.71C801.81 1389.4 802.12 1389.07 802.49 1388.92C802.83 1388.78 803.16 1388.99 803.11 1389.45C803.07 1389.83 803.36 1390.34 802.72 1390.5Z" fill="white"/>
                    <path d="M670.31 1254.49C670.16 1254.96 669.86 1255.45 669.15 1255.43C668.89 1255.43 668.67 1255.24 668.74 1254.92C668.88 1254.29 669.24 1253.86 669.93 1253.84C670.19 1253.84 670.4 1254.02 670.31 1254.48V1254.49Z" fill="white"/>
                    <path d="M611.96 1242.06C611.99 1242.55 611.65 1242.73 611.33 1242.6C610.96 1242.45 610.65 1242.12 610.37 1241.82C610.27 1241.71 611.33 1240.98 611.56 1241.02C611.89 1241.08 612.04 1241.49 611.96 1242.06Z" fill="white"/>
                    <path d="M746.82 1202.15C746.82 1202.52 746.82 1202.89 746.32 1202.85C745.78 1202.81 745.36 1202.43 745.36 1201.98C745.36 1201.75 745.76 1201.24 746.29 1201.25C747 1201.25 746.76 1201.8 746.83 1202.15H746.82Z" fill="white"/>
                    <path d="M665.3 1180.29C665.07 1180.73 664.82 1181.12 664.28 1181.19C663.96 1181.23 663.75 1181.03 663.79 1180.77C663.84 1180.34 663.43 1179.67 664.29 1179.59C664.82 1179.54 664.94 1180.09 665.31 1180.29H665.3Z" fill="white"/>
                    <path d="M738.35 1211.09C738.26 1211.22 738.16 1211.35 738.07 1211.48C737.51 1210.95 737.03 1210.32 736.21 1210.15C736.17 1210.15 736.16 1209.8 736.21 1209.64C736.24 1209.55 736.53 1209.45 736.56 1209.48C737.01 1210.03 737.91 1209.9 738.24 1210.59C738.31 1210.74 738.31 1210.92 738.34 1211.08L738.35 1211.09Z" fill="white"/>
                    <path d="M734.31 1212.05C733.78 1212.02 734.07 1211.34 734.1 1210.93C734.17 1210.12 734.87 1210.54 735.3 1210.47C735.43 1210.45 735.91 1210.73 735.47 1210.87C734.82 1211.09 735.6 1212.17 734.31 1212.04V1212.05Z" fill="white"/>
                    <path d="M745.11 1377.14C745.75 1377.03 745.93 1377.64 746.32 1377.91C746.1 1378.15 745.87 1378.6 745.65 1378.6C745.16 1378.6 744.84 1378.2 744.74 1377.69C744.68 1377.38 744.86 1377.24 745.11 1377.14Z" fill="white"/>
                    <path d="M845.71 1274.63C845.34 1274.58 844.81 1274.81 844.78 1274.09C844.76 1273.58 845.1 1273.54 845.46 1273.56C845.85 1273.58 846.37 1273.37 846.4 1274.09C846.42 1274.61 846.08 1274.61 845.71 1274.63Z" fill="white"/>
                    <path d="M674.09 1376.48C673.61 1377.22 673.07 1377.38 672.44 1377.28C672.29 1377.26 672.17 1377.11 672.03 1377.03C672.15 1376.91 672.3 1376.8 672.39 1376.66C672.72 1376.11 673.26 1376.2 673.76 1376.23C673.91 1376.24 674.05 1376.45 674.09 1376.49V1376.48Z" fill="white"/>
                    <path d="M872.66 1279.01C872.66 1279.14 872.34 1279.21 871.97 1279.19C871.59 1279.16 871.05 1279.39 871.02 1278.68C871 1278.18 871.32 1278.12 871.68 1278.14C872.07 1278.16 872.6 1277.96 872.65 1279.02L872.66 1279.01Z" fill="white"/>
                    <path d="M822.47 1252.85C822.37 1252.79 822.16 1252.73 822.15 1252.64C822.09 1252.04 822.05 1251.46 822.64 1251.04C822.89 1250.86 823.05 1250.8 823.18 1251.07C823.38 1251.5 822.88 1252.73 822.47 1252.85Z" fill="white"/>
                    <path d="M777.04 1345.96C776.84 1346.79 776.17 1346.26 775.73 1346.35C775.53 1346.39 775.38 1345.99 775.45 1345.73C775.67 1344.96 776.31 1345.37 776.76 1345.33C776.97 1345.31 777.1 1345.67 777.04 1345.96Z" fill="white"/>
                    <path d="M762.17 1243.47C761.97 1244.3 761.3 1243.77 760.86 1243.86C760.66 1243.9 760.51 1243.5 760.58 1243.24C760.8 1242.47 761.44 1242.88 761.89 1242.84C762.1 1242.82 762.23 1243.18 762.17 1243.47Z" fill="white"/>
                    <path d="M662.87 1309.85C662.87 1309.98 662.55 1310.05 662.18 1310.03C661.8 1310 661.26 1310.23 661.23 1309.52C661.21 1309.02 661.53 1308.96 661.89 1308.98C662.28 1309 662.81 1308.8 662.86 1309.86L662.87 1309.85Z" fill="white"/>
                    <path d="M772.04 1173.83C771.77 1173.34 771.49 1172.84 771.22 1172.34C771.21 1172.32 771.32 1172.18 771.39 1172.17C771.75 1172.09 772.03 1172.22 772.29 1172.5C772.58 1172.82 772.93 1173.09 772.65 1173.57C772.58 1173.68 772.37 1173.7 772.04 1173.84V1173.83Z" fill="white"/>
                    <path d="M726.14 1191.3C726.49 1191.27 726.87 1191.22 726.94 1191.7C727.02 1192.32 726.63 1192.39 726.14 1192.37C725.8 1192.35 725.41 1192.44 725.35 1191.96C725.27 1191.34 725.65 1191.24 726.14 1191.3Z" fill="white"/>
                    <path d="M681.32 1221.57C681.18 1222.04 681.06 1222.27 680.74 1222.19C680.11 1222.05 679.69 1221.69 679.66 1221C679.66 1220.87 679.82 1220.73 679.91 1220.59C680.4 1220.93 680.89 1221.26 681.32 1221.56V1221.57Z" fill="white"/>
                    <path d="M787.15 1230.49C787.22 1230.96 787.12 1231.33 786.9 1231.31C786.45 1231.28 785.82 1231.7 785.59 1230.94C785.51 1230.68 785.62 1230.32 785.87 1230.31C786.32 1230.3 786.91 1230.04 787.16 1230.49H787.15Z" fill="white"/>
                    <path d="M670.48 1325.84C670.47 1326.21 670.47 1326.55 669.95 1326.54C669.24 1326.53 669.43 1326 669.41 1325.61C669.39 1325.25 669.41 1324.92 669.93 1324.92C670.64 1324.93 670.42 1325.46 670.48 1325.84Z" fill="white"/>
                    <path d="M751.32 1325.24C752.15 1325.44 751.62 1326.11 751.71 1326.55C751.75 1326.75 751.35 1326.9 751.09 1326.83C750.32 1326.61 750.73 1325.97 750.69 1325.52C750.67 1325.31 751.03 1325.18 751.32 1325.24Z" fill="white"/>
                    <path d="M679.29 1212.06C679.64 1212.03 680.02 1211.98 680.09 1212.46C680.17 1213.08 679.78 1213.15 679.29 1213.13C678.95 1213.11 678.56 1213.2 678.5 1212.72C678.42 1212.1 678.8 1212 679.29 1212.06Z" fill="white"/>
                    <path d="M827.24 1197.85C827.85 1197.79 828 1197.97 828.03 1198.25C828.1 1198.88 827.69 1198.93 827.22 1198.89C826.98 1198.87 826.49 1198.93 826.56 1198.73C826.71 1198.33 827.1 1198.01 827.24 1197.85Z" fill="white"/>
                    <path d="M683.62 1356.83C683.56 1356.96 683.46 1357.18 683.36 1357.39C683.03 1357.04 682.46 1356.84 682.54 1356.22C682.57 1355.95 682.73 1355.79 683.05 1355.8C683.75 1355.82 683.56 1356.33 683.62 1356.83Z" fill="white"/>
                    <path d="M652.85 1165.81C652.62 1165.86 652.44 1165.67 652.4 1165.41C652.35 1165.06 652.4 1164.67 652.9 1164.74C653.25 1164.79 653.85 1164.44 653.87 1165.06C653.87 1165.28 653.45 1165.8 652.85 1165.82V1165.81Z" fill="white"/>
                    <path d="M817.52 1159.3C817.6 1159.92 817.01 1160.11 816.72 1160.49C816.63 1160.27 816.47 1160.05 816.47 1159.82C816.47 1159.44 816.27 1158.92 816.97 1158.88C817.29 1158.86 817.42 1159.02 817.52 1159.29V1159.3Z" fill="white"/>
                    <path d="M656.15 1195.68C655.79 1195.77 656.18 1195.77 655.98 1195.49C655.87 1195.34 655.87 1194.95 655.98 1194.82C656.3 1194.47 656.55 1193.92 657.19 1194.09C657.26 1194.11 657.38 1194.22 657.37 1194.25C657.19 1194.92 656.52 1195.18 656.15 1195.68Z" fill="white"/>
                    <path d="M687.7 1234.24C687.33 1234.56 687.08 1235.14 686.46 1234.93C686.31 1234.88 686.17 1234.49 686.2 1234.29C686.31 1233.66 686.86 1233.95 687.24 1233.89C687.5 1233.85 687.7 1233.91 687.7 1234.24Z" fill="white"/>
                    <path d="M758.27 1282.65C758.27 1282.82 758.32 1283.01 758.26 1283.17C758.22 1283.27 758.01 1283.41 757.93 1283.38C757.66 1283.27 757.62 1281.63 757.89 1281.46C757.95 1281.42 758.23 1281.54 758.24 1281.61C758.28 1281.96 758.26 1282.31 758.27 1282.66V1282.65Z" fill="white"/>
                    <path d="M613.38 1279.96C613.42 1280.51 613.1 1280.59 612.73 1280.56C612.46 1280.53 612.3 1280.38 612.3 1280.05C612.3 1279.56 612.6 1279.47 612.96 1279.51C613.22 1279.54 613.46 1279.67 613.38 1279.96Z" fill="white"/>
                    <path d="M825.15 1174.97C825.07 1175.25 824.95 1175.41 824.62 1175.41C824.13 1175.41 824.05 1175.11 824.08 1174.75C824.11 1174.48 824.26 1174.32 824.59 1174.32C825.09 1174.32 825.13 1174.62 825.14 1174.97H825.15Z" fill="white"/>
                    <path d="M798.65 1369.26C798.57 1369.54 798.45 1369.7 798.12 1369.7C797.63 1369.7 797.55 1369.4 797.58 1369.04C797.61 1368.77 797.76 1368.61 798.09 1368.61C798.59 1368.61 798.63 1368.91 798.64 1369.26H798.65Z" fill="white"/>
                    <path d="M697.81 1380.8C697.48 1380.8 697.18 1380.76 697.17 1380.26C697.17 1379.93 697.32 1379.77 697.59 1379.74C697.95 1379.7 698.26 1379.77 698.26 1380.27C698.26 1380.6 698.11 1380.73 697.81 1380.81V1380.8Z" fill="white"/>
                    <path d="M863.03 1261.7C863.48 1261.9 863.85 1262.17 863.92 1262.69C863.94 1262.83 863.78 1263.11 863.77 1263.11C863.29 1262.97 862.93 1262.68 862.87 1262.16C862.85 1262.02 862.97 1261.87 863.03 1261.7Z" fill="white"/>
                    <path d="M828.41 1350.72C828.47 1351.03 828.27 1351.19 828 1351.22C827.64 1351.26 827.34 1351.19 827.33 1350.7C827.33 1350.37 827.48 1350.21 827.75 1350.18C828.12 1350.13 828.44 1350.22 828.41 1350.73V1350.72Z" fill="white"/>
                    <path d="M804.45 1247.33C804.37 1247.61 804.25 1247.77 803.92 1247.77C803.43 1247.77 803.35 1247.47 803.38 1247.11C803.41 1246.84 803.56 1246.68 803.89 1246.68C804.39 1246.68 804.43 1246.98 804.44 1247.33H804.45Z" fill="white"/>
                    <path d="M623.54 1221.73C623.45 1222.05 623.33 1222.22 623 1222.22C622.51 1222.22 622.43 1221.92 622.46 1221.56C622.49 1221.29 622.64 1221.14 622.97 1221.13C623.48 1221.13 623.49 1221.45 623.53 1221.74L623.54 1221.73Z" fill="white"/>
                    <path d="M644.89 1217.19C644.38 1217.23 644.29 1216.9 644.33 1216.54C644.36 1216.27 644.51 1216.11 644.84 1216.11C645.33 1216.11 645.41 1216.41 645.38 1216.77C645.35 1217.04 645.2 1217.25 644.89 1217.19Z" fill="white"/>
                    <path d="M887.16 1289.86C887.16 1289.51 887.18 1289.16 887.67 1289.2C887.82 1289.21 887.97 1289.3 888.12 1289.35C887.89 1289.65 887.66 1289.96 887.43 1290.26C887.34 1290.13 887.25 1289.99 887.16 1289.86Z" fill="white"/>
                    <path d="M842.44 1307.67C842.44 1308.02 842.42 1308.37 841.93 1308.33C841.78 1308.32 841.63 1308.23 841.48 1308.18C841.71 1307.88 841.94 1307.57 842.17 1307.27C842.26 1307.4 842.35 1307.54 842.44 1307.67Z" fill="white"/>
                    <path d="M810.22 1188.67C809.97 1188.93 809.74 1189.18 809.51 1189.43C809.42 1189.21 809.25 1188.99 809.26 1188.77C809.29 1188.3 809.68 1188.32 810.02 1188.37C810.11 1188.38 810.17 1188.58 810.23 1188.67H810.22Z" fill="white"/>
                    <path d="M743.76 1325.32C743.57 1325.25 743.29 1325.2 743.29 1325.14C743.27 1324.67 743.76 1324.55 743.97 1324.24C744.06 1324.37 744.21 1324.5 744.22 1324.64C744.27 1325 744.18 1325.31 743.76 1325.32Z" fill="white"/>
                    <path d="M720.89 1406.44C720.82 1406.55 720.75 1406.74 720.66 1406.76C720.33 1406.82 719.93 1406.84 719.9 1406.37C719.89 1406.16 720.06 1405.93 720.14 1405.71C720.38 1405.95 720.63 1406.19 720.88 1406.44H720.89Z" fill="white"/>
                    <path d="M736.81 1385.23C737.29 1385.2 737.35 1385.54 737.31 1385.89C737.3 1386.03 737.15 1386.16 737.06 1386.3C736.83 1386 736.59 1385.7 736.36 1385.4C736.51 1385.34 736.66 1385.29 736.81 1385.23Z" fill="white"/>
                    <path d="M801.3 1208.47C801.26 1208.53 801.21 1208.73 801.12 1208.75C800.79 1208.81 800.39 1208.84 800.35 1208.38C800.33 1208.17 800.5 1207.94 800.58 1207.72C800.81 1207.97 801.04 1208.21 801.29 1208.48L801.3 1208.47Z" fill="white"/>
                    <path d="M800.13 1243.01C800.16 1243.49 799.82 1243.55 799.47 1243.51C799.33 1243.5 799.2 1243.35 799.06 1243.26C799.36 1243.03 799.66 1242.79 799.96 1242.56C800.02 1242.71 800.07 1242.86 800.13 1243.01Z" fill="white"/>
                    <path d="M779.65 1394.55C779.57 1394.69 779.48 1394.83 779.4 1394.97C779.15 1394.68 778.9 1394.4 778.66 1394.11C778.81 1394.05 778.95 1393.95 779.1 1393.93C779.57 1393.87 779.65 1394.19 779.65 1394.55Z" fill="white"/>
                    <path d="M650.92 1233.16C651.4 1233.13 651.46 1233.47 651.42 1233.82C651.41 1233.96 651.26 1234.09 651.17 1234.23C650.94 1233.93 650.7 1233.63 650.47 1233.33C650.62 1233.27 650.77 1233.22 650.92 1233.16Z" fill="white"/>
                    <path d="M614.29 1235.62C614.41 1235.81 614.55 1235.94 614.56 1236.08C614.6 1236.45 614.5 1236.76 614.03 1236.72C613.88 1236.7 613.74 1236.61 613.59 1236.55C613.82 1236.25 614.04 1235.94 614.29 1235.62Z" fill="white"/>
                    <path d="M786.77 1139.2C786.59 1139.51 786.3 1139.4 786.07 1139.39C786 1139.39 785.85 1139.14 785.88 1139.06C786.02 1138.75 786.32 1138.87 786.56 1138.88C786.64 1138.88 786.71 1139.09 786.77 1139.19V1139.2Z" fill="white"/>
                    <path d="M799.11 1158.99C799.2 1159.27 799.31 1159.5 799.32 1159.73C799.32 1159.86 799.16 1160 799.07 1160.14C798.98 1159.92 798.84 1159.7 798.81 1159.48C798.8 1159.36 798.97 1159.21 799.1 1159L799.11 1158.99Z" fill="white"/>
                    <path d="M635.68 1328.26C635.5 1328.57 635.21 1328.46 634.98 1328.45C634.91 1328.45 634.76 1328.2 634.79 1328.12C634.93 1327.81 635.23 1327.93 635.47 1327.94C635.55 1327.94 635.62 1328.15 635.68 1328.25V1328.26Z" fill="white"/>
                    <path d="M712.33 1391.67C712.05 1391.76 711.82 1391.87 711.59 1391.88C711.46 1391.88 711.32 1391.72 711.18 1391.63C711.4 1391.54 711.62 1391.4 711.84 1391.37C711.96 1391.36 712.11 1391.53 712.32 1391.66L712.33 1391.67Z" fill="white"/>
                    <path d="M715.18 1391.29C715.04 1391.29 714.8 1391.3 714.58 1391.24C714.32 1391.16 714.32 1390.97 714.58 1390.9C714.8 1390.84 715.06 1390.84 715.28 1390.9C715.54 1390.97 715.53 1391.13 715.19 1391.3L715.18 1391.29Z" fill="white"/>
                    <path d="M628.95 1342.96C629.1 1343.02 629.36 1343.06 629.37 1343.14C629.43 1343.45 629.19 1343.53 628.94 1343.49C628.79 1343.46 628.53 1343.4 628.52 1343.31C628.46 1343 628.7 1342.94 628.96 1342.96H628.95Z" fill="white"/>
                    <path d="M821.45 1179.71C821.39 1179.86 821.35 1180.12 821.27 1180.13C820.96 1180.19 820.88 1179.95 820.92 1179.7C820.95 1179.55 821.01 1179.29 821.1 1179.28C821.41 1179.22 821.47 1179.46 821.45 1179.72V1179.71Z" fill="white"/>
                    <path d="M783.62 1385.79C783.71 1386.07 783.82 1386.3 783.83 1386.53C783.83 1386.66 783.67 1386.8 783.58 1386.94C783.49 1386.72 783.35 1386.5 783.32 1386.28C783.31 1386.16 783.48 1386.01 783.61 1385.8L783.62 1385.79Z" fill="white"/>
                    <path d="M678.13 1176.95C678.07 1177.05 677.98 1177.18 677.89 1177.32C677.8 1177.1 677.66 1176.88 677.63 1176.66C677.62 1176.54 677.79 1176.39 677.88 1176.25C678.09 1176.42 678.2 1176.63 678.13 1176.95Z" fill="white"/>
                    <path d="M824.45 1383.34C824.19 1383.36 823.96 1383.31 824.01 1383C824.02 1382.92 824.28 1382.84 824.43 1382.82C824.68 1382.77 824.92 1382.84 824.87 1383.16C824.86 1383.24 824.6 1383.29 824.45 1383.35V1383.34Z" fill="white"/>
                    <path d="M677.44 1213.14C677.18 1213.16 676.95 1213.11 677 1212.8C677.01 1212.72 677.27 1212.64 677.42 1212.62C677.67 1212.57 677.91 1212.64 677.86 1212.96C677.85 1213.04 677.59 1213.09 677.44 1213.15V1213.14Z" fill="white"/>
                    <path d="M685.53 1221.08C685.27 1221.1 685.04 1221.05 685.09 1220.74C685.1 1220.66 685.36 1220.58 685.51 1220.56C685.76 1220.51 686 1220.58 685.95 1220.9C685.94 1220.98 685.68 1221.03 685.53 1221.09V1221.08Z" fill="white"/>
                    <path d="M719.22 1245.81C719.31 1246.09 719.42 1246.32 719.43 1246.55C719.43 1246.68 719.27 1246.82 719.18 1246.96C719.09 1246.74 718.95 1246.52 718.92 1246.3C718.91 1246.18 719.08 1246.03 719.21 1245.82L719.22 1245.81Z" fill="white"/>
                    <path d="M805.11 1200.1C805.02 1200.28 804.91 1200.49 804.81 1200.7C804.72 1200.57 804.54 1200.42 804.56 1200.3C804.58 1200.07 804.72 1199.85 804.8 1199.63C804.89 1199.76 804.98 1199.9 805.11 1200.1Z" fill="white"/>
                    <path d="M783.37 1282.33C783.31 1282.43 783.22 1282.56 783.13 1282.7C783.04 1282.48 782.9 1282.26 782.87 1282.04C782.86 1281.92 783.03 1281.77 783.12 1281.63C783.33 1281.8 783.44 1282.01 783.37 1282.33Z" fill="white"/>
                    <path d="M824.39 1288.65C824.48 1288.93 824.59 1289.16 824.6 1289.39C824.6 1289.52 824.44 1289.66 824.35 1289.8C824.26 1289.58 824.12 1289.36 824.09 1289.14C824.08 1289.02 824.25 1288.87 824.38 1288.66L824.39 1288.65Z" fill="white"/>
                    <path d="M888.77 1298.07C888.59 1298.38 888.3 1298.27 888.07 1298.26C888 1298.26 887.85 1298.01 887.88 1297.93C888.02 1297.62 888.32 1297.74 888.56 1297.75C888.64 1297.75 888.71 1297.96 888.77 1298.06V1298.07Z" fill="white"/>
                    <path d="M836.72 1324.42C836.46 1324.44 836.23 1324.39 836.28 1324.08C836.29 1324 836.55 1323.92 836.7 1323.9C836.95 1323.85 837.19 1323.92 837.14 1324.24C837.13 1324.32 836.87 1324.37 836.72 1324.43V1324.42Z" fill="white"/>
                    <path d="M649.03 1318.18C649.18 1318.24 649.44 1318.28 649.45 1318.36C649.51 1318.67 649.27 1318.75 649.02 1318.71C648.87 1318.68 648.61 1318.62 648.6 1318.53C648.54 1318.22 648.78 1318.16 649.04 1318.18H649.03Z" fill="white"/>
                    <path d="M734.95 1303.48C734.89 1303.63 734.85 1303.89 734.77 1303.9C734.46 1303.96 734.38 1303.72 734.42 1303.47C734.45 1303.32 734.51 1303.06 734.6 1303.05C734.91 1302.99 734.97 1303.23 734.95 1303.49V1303.48Z" fill="white"/>
                    <path d="M735.88 1317.36C735.94 1317.48 736.02 1317.59 736.05 1317.72C736.06 1317.76 735.94 1317.84 735.88 1317.9C735.82 1317.78 735.74 1317.67 735.71 1317.54C735.7 1317.5 735.82 1317.42 735.88 1317.36Z" fill="white"/>
                    <path d="M782.67 1403.51C782.73 1403.63 782.81 1403.74 782.84 1403.87C782.85 1403.91 782.73 1403.99 782.67 1404.05C782.61 1403.93 782.53 1403.82 782.5 1403.69C782.49 1403.65 782.61 1403.57 782.67 1403.51Z" fill="white"/>
                    <path d="M696.16 1223.84C696.1 1223.95 696.04 1224.07 695.97 1224.19C695.91 1224.13 695.79 1224.06 695.8 1224.02C695.83 1223.89 695.91 1223.78 695.96 1223.66C696.02 1223.72 696.08 1223.78 696.15 1223.85L696.16 1223.84Z" fill="white"/>
                    <path d="M656.11 1211.92C656.18 1211.85 656.24 1211.79 656.3 1211.73C656.36 1211.85 656.44 1211.96 656.47 1212.09C656.48 1212.13 656.36 1212.21 656.3 1212.27C656.23 1212.15 656.17 1212.04 656.11 1211.93V1211.92Z" fill="white"/>
                    <path d="M733.87 1209.85C733.76 1209.79 733.64 1209.73 733.52 1209.66C733.58 1209.6 733.65 1209.48 733.69 1209.49C733.82 1209.52 733.93 1209.6 734.05 1209.65C733.99 1209.71 733.93 1209.77 733.86 1209.84L733.87 1209.85Z" fill="white"/>
                    <path d="M762.68 1239.86C762.56 1239.92 762.45 1240 762.32 1240.03C762.28 1240.04 762.2 1239.92 762.14 1239.86C762.26 1239.8 762.37 1239.72 762.5 1239.69C762.54 1239.68 762.62 1239.8 762.68 1239.86Z" fill="white"/>
                    <path d="M806.21 1243.92C806.14 1243.85 806.08 1243.79 806.02 1243.73C806.14 1243.67 806.25 1243.59 806.38 1243.56C806.42 1243.55 806.5 1243.67 806.56 1243.73C806.44 1243.8 806.33 1243.86 806.22 1243.92H806.21Z" fill="white"/>
                    <path d="M823.09 1192.71C823.03 1192.82 822.97 1192.94 822.9 1193.06C822.84 1193 822.72 1192.93 822.73 1192.89C822.76 1192.76 822.84 1192.65 822.89 1192.53C822.95 1192.59 823.01 1192.65 823.08 1192.72L823.09 1192.71Z" fill="white"/>
                    <path d="M716.33 1208.3C716.26 1208.37 716.2 1208.43 716.14 1208.49C716.08 1208.37 716 1208.26 715.97 1208.13C715.96 1208.09 716.08 1208.01 716.14 1207.95C716.21 1208.07 716.27 1208.18 716.33 1208.29V1208.3Z" fill="white"/>
                    <path d="M751.24 1265.55C751.31 1265.48 751.37 1265.42 751.43 1265.36C751.49 1265.48 751.57 1265.59 751.6 1265.72C751.61 1265.76 751.49 1265.84 751.43 1265.9C751.36 1265.78 751.3 1265.67 751.24 1265.56V1265.55Z" fill="white"/>
                    <path d="M880.39 1274.88C880.45 1274.94 880.57 1275.03 880.56 1275.05C880.5 1275.17 880.43 1275.31 880.32 1275.36C880.1 1275.47 879.99 1275.36 880.09 1275.14C880.14 1275.03 880.29 1274.97 880.39 1274.88Z" fill="white"/>
                    <path d="M880.08 1275.61C879.98 1275.69 879.84 1275.86 879.77 1275.84C879.56 1275.78 879.53 1275.62 879.67 1275.53C879.79 1275.45 879.94 1275.42 880.08 1275.37C880.08 1275.45 880.08 1275.53 880.08 1275.61Z" fill="white"/>
                    <path d="M712.14 1330.6C712.08 1330.71 712.02 1330.83 711.95 1330.95C711.89 1330.89 711.77 1330.82 711.78 1330.78C711.81 1330.65 711.89 1330.54 711.94 1330.42C712 1330.48 712.06 1330.54 712.13 1330.61L712.14 1330.6Z" fill="white"/>
                    <path d="M879.27 1197.52C879.21 1197.63 879.15 1197.75 879.08 1197.87C879.02 1197.81 878.9 1197.74 878.91 1197.7C878.94 1197.57 879.02 1197.46 879.07 1197.34C879.13 1197.4 879.19 1197.46 879.26 1197.53L879.27 1197.52Z" fill="white"/>
                    <path d="M885.92 1279.4C885.85 1279.47 885.79 1279.53 885.73 1279.59C885.67 1279.47 885.59 1279.36 885.56 1279.23C885.55 1279.19 885.67 1279.11 885.73 1279.05C885.8 1279.17 885.86 1279.28 885.92 1279.39V1279.4Z" fill="white"/>
                    <path d="M840.86 1189.49C840.92 1189.38 840.98 1189.26 841.05 1189.14C841.11 1189.2 841.23 1189.27 841.22 1189.31C841.19 1189.44 841.11 1189.55 841.06 1189.67C841 1189.61 840.94 1189.55 840.87 1189.48L840.86 1189.49Z" fill="white"/>
                    <path d="M798.58 1313.75C798.52 1313.86 798.46 1313.98 798.39 1314.1C798.33 1314.04 798.21 1313.97 798.22 1313.93C798.25 1313.8 798.33 1313.69 798.38 1313.57C798.44 1313.63 798.5 1313.69 798.57 1313.76L798.58 1313.75Z" fill="white"/>
                    <path d="M758.71 1196.26C758.64 1196.19 758.58 1196.13 758.52 1196.07C758.64 1196.01 758.75 1195.93 758.88 1195.9C758.92 1195.89 759 1196.01 759.06 1196.07C758.94 1196.14 758.83 1196.2 758.72 1196.26H758.71Z" fill="white"/>
                    <path d="M856.98 1291.09C857.04 1291.21 857.12 1291.32 857.15 1291.45C857.16 1291.49 857.04 1291.57 856.98 1291.63C856.92 1291.51 856.84 1291.4 856.81 1291.27C856.8 1291.23 856.92 1291.15 856.98 1291.09Z" fill="white"/>
                    <path d="M770.18 1193.74C770.12 1193.85 770.06 1193.97 769.99 1194.09C769.93 1194.03 769.81 1193.96 769.82 1193.92C769.85 1193.79 769.93 1193.68 769.98 1193.56L770.17 1193.75L770.18 1193.74Z" fill="white"/>
                    <path d="M740.4 1294.41C740.51 1294.47 740.63 1294.53 740.75 1294.6C740.69 1294.66 740.62 1294.78 740.58 1294.77C740.45 1294.74 740.34 1294.66 740.22 1294.61C740.28 1294.55 740.34 1294.49 740.41 1294.42L740.4 1294.41Z" fill="white"/>
                    <path d="M629.29 1350.97C629.35 1351.09 629.43 1351.2 629.46 1351.33C629.47 1351.37 629.35 1351.45 629.29 1351.51C629.23 1351.39 629.15 1351.28 629.12 1351.15C629.11 1351.11 629.23 1351.03 629.29 1350.97Z" fill="white"/>
                    <path d="M683.77 1197.39C685.37 1197.96 685.5 1199.28 685.6 1200.52C685.64 1200.98 685.59 1201.69 685.69 1201.92C686.36 1203.38 684.46 1203.36 684.44 1204.33C683.49 1204.16 682.3 1204.28 681.67 1203.74C681.07 1203.23 680.37 1202.53 680.01 1201.71C679.68 1200.94 678.86 1200.02 680.37 1199.47C680.59 1199.39 680.64 1198.86 680.8 1198.56C681.52 1197.21 682.54 1196.85 683.76 1197.39H683.77Z" fill="white"/>
                    <path d="M560.25 1257.33C560.67 1256.42 561.69 1256.1 562.01 1256.25C563.24 1256.84 564.26 1255.92 565.4 1256.01C566.21 1256.07 566.69 1256.23 566.92 1256.98C567.31 1258.21 567.16 1258.79 566.34 1259.25C565.98 1259.45 565.67 1259.68 565.34 1259.93C563.94 1261.01 560.46 1260.14 560.22 1258.12C560.18 1257.76 560.13 1257.46 560.24 1257.33H560.25Z" fill="white"/>
                    <path d="M639.67 1277.57C640.51 1277.28 640.85 1276.04 642.01 1276.45C642.17 1276.51 642.33 1276.65 642.49 1276.65C643.14 1276.62 643.83 1276.36 644.33 1277.01C644.73 1277.52 644.56 1278.13 644.58 1278.7C644.63 1279.82 643.54 1280.97 642.33 1281.16C641.38 1281.3 640.69 1280.97 640.1 1280.19C639.49 1279.38 639.78 1278.47 639.68 1277.58L639.67 1277.57Z" fill="white"/>
                    <path d="M647.05 1399.26C646.43 1400.63 645.65 1401.04 644.6 1400.71C644.02 1400.53 643.5 1400.21 642.93 1399.99C642.39 1399.79 642.33 1399.49 642.58 1399.01C642.86 1398.47 643 1397.85 643.34 1397.36C643.91 1396.52 644.8 1396.19 645.79 1396.18C645.99 1396.18 646.32 1396.36 646.38 1396.53C646.62 1397.17 647.04 1397.67 647.38 1398.22C647.55 1398.49 647.09 1399.14 647.05 1399.25V1399.26Z" fill="white"/>
                    <path d="M537.38 1368.72C537.6 1368.07 537.9 1367.15 538.99 1367.31C539.5 1367.39 540.47 1367.54 540.39 1368.19C540.31 1368.89 540.79 1369.75 540.06 1370.33C539.55 1370.73 538.88 1370.98 538.5 1371.47C538.15 1371.92 537.94 1371.95 537.47 1371.75C536.8 1371.47 536.61 1370.92 536.7 1370.32C536.78 1369.82 537.09 1369.36 537.38 1368.72Z" fill="white"/>
                    <path d="M663.6 1408.25C663.86 1407.62 664.59 1407.3 665.23 1407.53C665.44 1407.61 666.91 1409.59 666.91 1409.79C666.91 1410.25 665.58 1411.13 665.57 1411.05C665.39 1409.97 664.41 1410.89 664.05 1410.39C663.7 1409.92 663.44 1408.65 663.61 1408.24L663.6 1408.25Z" fill="white"/>
                    <path d="M692.25 1256.42C691.92 1256.27 691.38 1256.13 691.52 1255.81C691.77 1255.22 691.78 1254.47 692.58 1254.15C693.02 1253.98 693.42 1254.22 693.78 1254.09C694.37 1253.87 694.69 1253.93 694.98 1254.55C695.28 1255.22 695.11 1255.84 694.61 1256.11C693.91 1256.5 693.11 1257.05 692.25 1256.41V1256.42Z" fill="white"/>
                    <path d="M606.4 1284.56C606.15 1285.05 606.12 1285.81 605.19 1285.35C604.98 1285.25 604.57 1285.58 604.24 1285.69C603.87 1285.81 603.49 1285.9 603.11 1286C603.29 1285.45 603.32 1284.72 603.69 1284.39C604.17 1283.96 604.95 1283.87 605.57 1283.59C605.91 1283.44 606.26 1283.36 606.52 1283.63C606.8 1283.93 606.52 1284.26 606.4 1284.57V1284.56Z" fill="white"/>
                    <path d="M736.89 1332.52C736.82 1332.68 736.77 1332.84 736.68 1332.98C736.48 1333.3 736.52 1333.91 735.93 1333.79C735.32 1333.67 734.91 1333.29 734.75 1332.64C734.61 1332.09 734.9 1331.92 735.32 1331.72C735.59 1331.59 735.74 1331.23 736.01 1331.1C736.36 1330.92 736.76 1330.62 737.18 1330.94C737.67 1331.32 737.2 1331.68 737.1 1332.04C737.06 1332.2 736.97 1332.35 736.9 1332.51L736.89 1332.52Z" fill="white"/>
                    <path d="M655.35 1412.86C655.83 1413.5 656.52 1414.02 656.67 1414.87C656.7 1415.06 656.58 1415.37 656.27 1415.45C655.88 1415.55 655.45 1415.95 655.09 1415.58C654.65 1415.12 654.31 1414.55 653.99 1414C653.61 1413.34 654.49 1413.58 654.59 1413.23C654.7 1412.87 654.93 1412.65 655.36 1412.87L655.35 1412.86Z" fill="white"/>
                    <path d="M621.09 1244.78C620.97 1245.24 620.71 1245.44 620.19 1245.18C619.39 1244.78 619.12 1244.14 619.41 1243.4C619.67 1242.74 620.23 1242.56 620.8 1242.62C621.45 1242.69 621.58 1243.75 621.09 1244.78Z" fill="white"/>
                    <path d="M579.52 1178.75C579.42 1178.99 579.35 1179.25 579.2 1179.46C578.85 1179.94 578.13 1179.83 577.92 1179.28C577.79 1178.96 577.6 1178.64 577.56 1178.31C577.5 1177.88 577.69 1177.4 578.04 1177.22C578.44 1177.01 578.99 1177.38 579.44 1177.08C579.55 1177.55 580.02 1177.98 579.63 1178.52C579.58 1178.59 579.56 1178.68 579.52 1178.76V1178.75Z" fill="white"/>
                    <path d="M668.35 1366.44C668.63 1366.33 668.88 1366.25 669.11 1366.14C669.7 1365.87 670.15 1366.72 670.78 1366.3C671.11 1366.08 671.47 1366.24 671.57 1366.62C671.67 1366.98 671.45 1367.23 671.08 1367.36C670.6 1367.53 670.13 1367.76 669.64 1367.9C669.41 1367.97 669.09 1368.41 668.89 1367.83C668.73 1367.36 668.53 1366.9 668.35 1366.44Z" fill="white"/>
                    <path d="M575.47 1420.41C575 1420.11 574.88 1419.13 575.24 1418.61C575.38 1418.41 576.97 1418.14 577.2 1418.34C577.8 1418.83 577.21 1419.26 577.04 1419.65C576.71 1420.37 575.92 1420.7 575.47 1420.41Z" fill="white"/>
                    <path d="M564.59 1348.93C565.11 1349.07 565.35 1349.3 565.09 1349.84C564.77 1350.49 564.02 1350.95 563.54 1350.74C563.07 1350.53 562.88 1349.65 563.18 1348.99C563.63 1348 564.14 1348.91 564.59 1348.94V1348.93Z" fill="white"/>
                    <path d="M560.94 1343.75C561.33 1343.48 561.75 1343.16 562.26 1343.35C562.82 1343.55 562.96 1344.73 562.54 1345.31C562.26 1345.69 561.97 1345.52 561.68 1345.36C561.01 1344.99 560.66 1344.23 560.94 1343.76V1343.75Z" fill="white"/>
                    <path d="M636.92 1367.92C636.78 1368.23 636.7 1368.38 636.65 1368.54C636.47 1369.15 635.86 1369.08 635.45 1369.32C635.35 1368.85 634.89 1368.45 635.22 1367.9C635.31 1367.76 635.37 1367.6 635.43 1367.44C635.62 1366.9 635.83 1366.59 636.52 1366.91C637.18 1367.22 637.21 1367.55 636.93 1367.92H636.92Z" fill="white"/>
                    <path d="M507.2 1293.41C507.77 1293.62 507.72 1294.04 507.48 1294.25C507 1294.68 506.44 1295.03 505.88 1295.37C505.36 1295.68 505.42 1295.14 505.29 1294.91C504.72 1293.91 505.94 1293.96 506.19 1293.42C506.48 1292.8 506.91 1293.42 507.19 1293.42L507.2 1293.41Z" fill="white"/>
                    <path d="M605.66 1365.13C605.82 1364.43 606.4 1364.23 606.97 1364.28C607.4 1364.31 607.88 1365.66 607.6 1365.88C607.05 1366.3 606.46 1366.31 605.83 1365.97C605.39 1365.73 605.53 1365.45 605.66 1365.13Z" fill="white"/>
                    <path d="M734.99 1273.55C735.42 1273.83 735.51 1274.32 735.53 1274.8C735.54 1275.09 734.15 1275.4 733.64 1275.23C733.18 1275.08 733.27 1274.75 733.42 1274.43C733.73 1273.79 734.49 1273.34 734.98 1273.54L734.99 1273.55Z" fill="white"/>
                    <path d="M625.63 1395.77C625.43 1395.6 625.27 1395.33 625.51 1395.14C626.08 1394.69 626.54 1394.09 627.28 1393.84C627.59 1393.74 627.86 1393.84 627.98 1393.99C628.09 1394.14 628.08 1394.57 627.96 1394.67C627.46 1395.09 626.91 1395.43 626.37 1395.79C626.14 1395.94 625.89 1395.92 625.64 1395.77H625.63Z" fill="white"/>
                    <path d="M625.87 1424.02C625.96 1423.76 626.06 1423.43 626.51 1423.53C626.9 1423.62 626.94 1423.98 627.1 1424.26C627.44 1424.82 627.06 1425.26 626.82 1425.64C626.68 1425.87 626.08 1426.1 625.95 1425.99C625.68 1425.78 625.49 1425.35 625.46 1425C625.44 1424.7 625.7 1424.39 625.86 1424.02H625.87Z" fill="white"/>
                    <path d="M510.85 1340.06C510.43 1339.95 510.24 1339.69 510.5 1339.18C510.83 1338.54 511.57 1338.1 512.04 1338.3C512.84 1338.63 511.86 1339.39 512.39 1339.84C512.48 1339.92 511.47 1340.28 510.85 1340.06Z" fill="white"/>
                    <path d="M671.81 1332.75C671.95 1332.68 672.1 1332.52 672.22 1332.54C672.89 1332.66 673.59 1333.99 673.28 1334.56C673.17 1334.76 672.96 1335.01 672.79 1335.02C672.61 1335.02 672.35 1334.81 672.24 1334.62C671.77 1333.79 671.64 1333.15 671.81 1332.75Z" fill="white"/>
                    <path d="M673.68 1235.69C673.45 1235.59 673.19 1235.52 672.98 1235.37C672.46 1234.99 672.93 1234.63 673.04 1234.27C673.18 1233.8 673.51 1233.9 673.84 1234.05C674.14 1234.19 674.46 1234.26 674.58 1234.65C674.7 1235.05 674.98 1235.46 674.6 1235.81C674.3 1236.09 673.97 1235.8 673.67 1235.68L673.68 1235.69Z" fill="white"/>
                    <path d="M625.36 1392.88C625.05 1393.32 624.74 1393.13 624.46 1392.97C623.78 1392.58 623.44 1391.83 623.75 1391.37C624 1390.98 624.33 1391.17 624.61 1391.33C625.29 1391.72 625.61 1392.46 625.36 1392.87V1392.88Z" fill="white"/>
                    <path d="M507.54 1346.58C507.34 1346.01 507.02 1345.53 507.39 1344.89C507.69 1344.37 507.97 1344.47 508.4 1344.64C508.86 1344.83 509.02 1345.01 508.86 1345.56C508.64 1346.28 508.03 1346.29 507.55 1346.57L507.54 1346.58Z" fill="white"/>
                    <path d="M509.53 1333.63C509.66 1333.54 509.82 1333.36 509.92 1333.39C510.24 1333.48 510.67 1333.56 510.82 1333.8C511.07 1334.19 511.43 1334.65 511.12 1335.17C510.88 1335.57 510.55 1335.39 510.26 1335.23C509.58 1334.84 509.26 1334.14 509.52 1333.63H509.53Z" fill="white"/>
                    <path d="M708.74 1239.91C708.89 1239.59 709.06 1239.25 709.47 1239.51C710 1239.86 710.28 1240.41 709.95 1240.97C709.78 1241.26 709.26 1241.35 708.89 1241.53C708.71 1241 708.3 1240.49 708.74 1239.91Z" fill="white"/>
                    <path d="M572.56 1221.31C573.11 1221.6 573.41 1222.51 573.17 1222.88C572.92 1223.26 572.62 1223.05 572.31 1222.93C571.69 1222.7 571.44 1222.2 571.59 1221.65C571.63 1221.49 572.25 1221.18 572.56 1221.31Z" fill="white"/>
                    <path d="M586.79 1365.26C586.97 1364.86 587.17 1364.46 587.52 1364.76C587.96 1365.13 588.25 1365.67 588.61 1366.14C588.07 1366.39 587.57 1366.58 586.96 1366.22C586.38 1365.88 586.71 1365.54 586.79 1365.27V1365.26Z" fill="white"/>
                    <path d="M516.57 1255.63C516.78 1254.95 517.12 1255.04 517.58 1255.21C518.31 1255.46 518.18 1255.85 517.94 1256.38C517.7 1256.93 517.41 1256.86 516.95 1256.69C516.26 1256.43 516.24 1256.06 516.56 1255.64L516.57 1255.63Z" fill="white"/>
                    <path d="M592.92 1374.51C593.3 1374.77 594.11 1374.75 593.65 1375.5C593.4 1375.91 592.87 1376.13 592.35 1375.97C591.49 1375.7 592.11 1374.92 592.05 1374.47C591.98 1373.91 592.6 1374.57 592.92 1374.51Z" fill="white"/>
                    <path d="M633.9 1439.43C634 1439.2 634.07 1438.94 634.21 1438.74C634.47 1438.36 634.79 1438.56 635.08 1438.71C635.29 1438.82 635.61 1438.82 635.64 1439.14C635.67 1439.51 635.5 1439.76 635.13 1439.87C634.97 1439.92 634.82 1439.99 634.66 1440.05C634.35 1440.16 633.91 1440.43 633.77 1440.32C633.46 1440.08 633.8 1439.73 633.9 1439.42V1439.43Z" fill="white"/>
                    <path d="M615.75 1197.05C615.28 1196.64 615.7 1196.29 615.84 1195.94C616.1 1195.29 616.52 1195.71 616.87 1195.84C617.2 1195.96 617.51 1196.13 617.26 1196.57C617.01 1197.01 616.13 1197.29 615.75 1197.05Z" fill="white"/>
                    <path d="M555.57 1320.42C555.98 1320.62 556.3 1320.77 556.05 1321.21C555.8 1321.65 554.91 1321.94 554.54 1321.69C554.17 1321.43 554.42 1321.13 554.5 1320.81C554.71 1320.05 555.16 1320.2 555.56 1320.42H555.57Z" fill="white"/>
                    <path d="M608.44 1405.91C608.3 1405.8 607.97 1405.65 607.97 1405.5C607.96 1405.08 608.43 1405.1 608.67 1405.07C609.03 1405.03 609.49 1404.2 609.79 1405.14C609.89 1405.45 610.09 1405.76 609.84 1406.07C609.33 1406.68 608.97 1405.92 608.45 1405.91H608.44Z" fill="white"/>
                    <path d="M718.81 1329.58C719.16 1329.37 719.66 1328.82 719.92 1329.28C720.14 1329.67 720.87 1329.73 720.67 1330.31C720.55 1330.66 719.19 1330.62 718.84 1330.27C718.66 1330.08 718.65 1329.83 718.82 1329.58H718.81Z" fill="white"/>
                    <path d="M532.02 1260.45C532.02 1260.45 531.95 1259.99 531.94 1259.57C531.94 1259.39 533.11 1259.17 533.3 1259.31C533.82 1259.69 533.36 1260.04 533.25 1260.4C533.12 1260.83 532.81 1260.82 532.01 1260.45H532.02Z" fill="white"/>
                    <path d="M548.17 1234.36C548.57 1233.89 548.91 1234.33 549.27 1234.44C549.72 1234.58 549.73 1234.97 549.48 1235.21C549.2 1235.48 548.78 1235.63 548.4 1235.75C548.26 1235.79 548.04 1234.56 548.17 1234.37V1234.36Z" fill="white"/>
                    <path d="M702.73 1198.94C703.18 1199.04 703.26 1199.3 703.16 1199.53C702.89 1200.14 702.36 1200.29 701.74 1200.18C701.42 1200.12 701.34 1199.85 701.44 1199.62C701.7 1198.98 702.25 1198.89 702.73 1198.94Z" fill="white"/>
                    <path d="M682.86 1349.19C682.39 1348.9 682.16 1348.41 682.34 1348C682.43 1347.8 683 1347.51 683.46 1347.73C684.08 1348.02 683.68 1348.41 683.56 1348.76C683.44 1349.09 683.27 1349.36 682.86 1349.19Z" fill="white"/>
                    <path d="M619.17 1180.31C619.41 1180.38 620.04 1181.42 619.9 1181.49C619.53 1181.65 619.12 1181.82 618.74 1181.81C618.38 1181.8 618.18 1181.48 618.4 1181.09C618.59 1180.77 618.54 1180.2 619.17 1180.32V1180.31Z" fill="white"/>
                    <path d="M682.21 1354.47C682.53 1354.11 683 1353.8 683.62 1354.1C683.85 1354.21 683.97 1354.46 683.78 1354.72C683.4 1355.22 682.9 1355.46 682.29 1355.2C682.06 1355.1 681.94 1354.85 682.21 1354.48V1354.47Z" fill="white"/>
                    <path d="M729.1 1388.97C729.27 1388.52 729.64 1388.5 729.88 1388.75C730.15 1389.03 730.3 1389.45 730.42 1389.83C730.46 1389.97 729.23 1390.19 729.04 1390.06C728.77 1389.87 728.8 1389.45 729.1 1388.97Z" fill="white"/>
                    <path d="M593.14 1370.24C593.29 1369.91 593.44 1369.58 593.87 1369.82C594.34 1370.07 594.56 1370.58 594.37 1370.98C594.28 1371.18 593.71 1371.47 593.25 1371.26C592.63 1370.97 593.05 1370.58 593.13 1370.25L593.14 1370.24Z" fill="white"/>
                    <path d="M656.84 1422.45C657.22 1422.15 657.6 1421.91 658.11 1422.06C658.41 1422.15 658.51 1422.41 658.38 1422.63C658.16 1422.99 658.26 1423.75 657.46 1423.48C656.97 1423.32 657.09 1422.78 656.83 1422.45H656.84Z" fill="white"/>
                    <path d="M604.27 1365.7C604.4 1365.62 604.54 1365.55 604.67 1365.47C604.96 1366.16 605.13 1366.92 605.79 1367.4C605.82 1367.42 605.69 1367.73 605.58 1367.85C605.52 1367.92 605.22 1367.89 605.21 1367.85C605.03 1367.18 604.18 1366.94 604.16 1366.19C604.16 1366.03 604.23 1365.87 604.26 1365.71L604.27 1365.7Z" fill="white"/>
                    <path d="M608.25 1366.47C608.71 1366.71 608.18 1367.2 607.99 1367.55C607.6 1368.24 607.15 1367.59 606.74 1367.48C606.62 1367.45 606.3 1367 606.74 1367.05C607.4 1367.12 607.14 1365.84 608.24 1366.48L608.25 1366.47Z" fill="white"/>
                    <path d="M665.02 1215.34C664.41 1215.18 664.49 1214.57 664.25 1214.17C664.55 1214.04 664.93 1213.74 665.12 1213.83C665.55 1214.03 665.68 1214.51 665.57 1215C665.5 1215.3 665.28 1215.35 665.02 1215.34Z" fill="white"/>
                    <path d="M534.36 1266.04C534.67 1266.24 535.23 1266.24 534.97 1266.89C534.78 1267.35 534.47 1267.25 534.16 1267.09C533.82 1266.92 533.28 1266.89 533.54 1266.25C533.73 1265.78 534.04 1265.92 534.37 1266.05L534.36 1266.04Z" fill="white"/>
                    <path d="M727.9 1244.48C728.62 1244.02 729.17 1244.09 729.69 1244.43C729.81 1244.51 729.87 1244.69 729.95 1244.82C729.79 1244.88 729.62 1244.91 729.48 1245.01C728.96 1245.37 728.52 1245.07 728.09 1244.84C727.96 1244.77 727.92 1244.53 727.9 1244.48Z" fill="white"/>
                    <path d="M512.16 1251.32C512.2 1251.2 512.53 1251.27 512.84 1251.44C513.17 1251.62 513.74 1251.63 513.48 1252.28C513.3 1252.74 512.99 1252.66 512.67 1252.49C512.33 1252.32 511.78 1252.28 512.16 1251.32Z" fill="white"/>
                    <path d="M546.27 1294.75C546.34 1294.85 546.5 1294.98 546.47 1295.06C546.29 1295.61 546.09 1296.15 545.39 1296.29C545.1 1296.35 544.93 1296.34 544.93 1296.05C544.93 1295.59 545.86 1294.7 546.28 1294.76L546.27 1294.75Z" fill="white"/>
                    <path d="M624.1 1230.23C624.61 1229.58 624.99 1230.31 625.42 1230.41C625.61 1230.45 625.59 1230.87 625.42 1231.07C624.92 1231.67 624.51 1231.04 624.1 1230.9C623.91 1230.83 623.93 1230.46 624.1 1230.23Z" fill="white"/>
                    <path d="M596.11 1327.34C596.62 1326.69 597 1327.42 597.43 1327.52C597.62 1327.56 597.6 1327.98 597.43 1328.18C596.93 1328.78 596.52 1328.15 596.11 1328.01C595.92 1327.94 595.94 1327.57 596.11 1327.34Z" fill="white"/>
                    <path d="M711.09 1308.23C711.13 1308.11 711.46 1308.18 711.77 1308.35C712.1 1308.53 712.67 1308.54 712.41 1309.19C712.23 1309.65 711.92 1309.57 711.6 1309.4C711.26 1309.23 710.71 1309.19 711.09 1308.23Z" fill="white"/>
                    <path d="M559.34 1385.28C559.38 1385.82 559.43 1386.38 559.47 1386.94C559.47 1386.97 559.32 1387.04 559.25 1387.02C558.9 1386.94 558.7 1386.72 558.59 1386.37C558.46 1385.97 558.26 1385.59 558.7 1385.28C558.8 1385.21 559 1385.28 559.35 1385.28H559.34Z" fill="white"/>
                    <path d="M607.17 1388.2C606.84 1388.08 606.48 1387.98 606.62 1387.52C606.8 1386.93 607.17 1387.03 607.6 1387.25C607.9 1387.4 608.28 1387.48 608.14 1387.94C607.96 1388.52 607.58 1388.46 607.17 1388.2Z" fill="white"/>
                    <path d="M659.19 1379.31C659.51 1378.95 659.7 1378.8 659.96 1378.99C660.46 1379.37 660.69 1379.86 660.44 1380.48C660.39 1380.6 660.19 1380.66 660.05 1380.74C659.75 1380.24 659.45 1379.75 659.19 1379.31Z" fill="white"/>
                    <path d="M568.68 1328.83C568.81 1328.38 569.04 1328.1 569.23 1328.2C569.62 1328.41 570.35 1328.29 570.25 1329.06C570.21 1329.32 569.97 1329.6 569.75 1329.51C569.34 1329.34 568.72 1329.34 568.68 1328.83Z" fill="white"/>
                    <path d="M710.75 1290.96C710.91 1290.64 711.04 1290.34 711.5 1290.55C712.13 1290.85 711.74 1291.24 711.61 1291.59C711.49 1291.92 711.33 1292.21 710.87 1291.99C710.24 1291.69 710.65 1291.31 710.75 1290.95V1290.96Z" fill="white"/>
                    <path d="M638.63 1258.99C637.98 1258.48 638.71 1258.1 638.81 1257.67C638.85 1257.48 639.27 1257.5 639.47 1257.67C640.07 1258.17 639.44 1258.58 639.3 1258.99C639.23 1259.18 638.86 1259.16 638.63 1258.99Z" fill="white"/>
                    <path d="M657.17 1388.58C656.84 1388.46 656.48 1388.36 656.62 1387.9C656.8 1387.31 657.17 1387.41 657.6 1387.63C657.9 1387.78 658.28 1387.86 658.14 1388.32C657.96 1388.9 657.58 1388.84 657.17 1388.58Z" fill="white"/>
                    <path d="M519.92 1341.73C519.35 1341.54 519.29 1341.32 519.38 1341.06C519.57 1340.48 519.96 1340.59 520.36 1340.82C520.57 1340.94 521.03 1341.08 520.88 1341.23C520.59 1341.53 520.11 1341.65 519.93 1341.74L519.92 1341.73Z" fill="white"/>
                    <path d="M711.52 1258.12C711.63 1258.03 711.8 1257.88 711.98 1257.72C712.13 1258.17 712.55 1258.57 712.24 1259.09C712.11 1259.31 711.9 1259.4 711.61 1259.26C711 1258.96 711.37 1258.59 711.52 1258.12Z" fill="white"/>
                    <path d="M662.09 1440.33C662.32 1440.38 662.4 1440.62 662.33 1440.87C662.23 1441.2 662.04 1441.52 661.62 1441.27C661.33 1441.09 660.66 1441.15 660.89 1440.6C660.97 1440.4 661.56 1440.11 662.1 1440.34L662.09 1440.33Z" fill="white"/>
                    <path d="M513.06 1379.92C513.24 1379.34 513.84 1379.4 514.25 1379.18C514.24 1379.41 514.29 1379.67 514.2 1379.87C514.06 1380.21 514.02 1380.75 513.38 1380.5C513.09 1380.39 513.03 1380.19 513.05 1379.91L513.06 1379.92Z" fill="white"/>
                    <path d="M671.16 1412.45C671.51 1412.52 671.17 1412.36 671.24 1412.69C671.27 1412.87 671.12 1413.21 670.96 1413.28C670.53 1413.46 670.09 1413.85 669.59 1413.45C669.53 1413.4 669.47 1413.26 669.5 1413.23C669.93 1412.71 670.63 1412.75 671.16 1412.45Z" fill="white"/>
                    <path d="M658.61 1365.48C659.07 1365.34 659.53 1364.93 659.99 1365.36C660.1 1365.46 660.07 1365.87 659.96 1366.03C659.6 1366.55 659.24 1366.07 658.88 1365.97C658.63 1365.9 658.48 1365.76 658.61 1365.48Z" fill="white"/>
                    <path d="M615.33 1294.07C615.4 1293.91 615.44 1293.73 615.55 1293.61C615.62 1293.53 615.87 1293.5 615.92 1293.56C616.12 1293.76 615.49 1295.24 615.18 1295.28C615.11 1295.29 614.91 1295.07 614.93 1295.01C615.03 1294.69 615.19 1294.38 615.33 1294.07Z" fill="white"/>
                    <path d="M743.07 1354.7C743.26 1354.2 743.58 1354.25 743.89 1354.43C744.12 1354.56 744.2 1354.77 744.06 1355.06C743.86 1355.5 743.56 1355.45 743.25 1355.27C743.03 1355.14 742.87 1354.93 743.06 1354.7H743.07Z" fill="white"/>
                    <path d="M512.58 1362.92C512.76 1362.7 512.93 1362.61 513.23 1362.74C513.67 1362.94 513.62 1363.24 513.44 1363.55C513.31 1363.78 513.1 1363.86 512.81 1363.72C512.37 1363.52 512.45 1363.24 512.58 1362.92Z" fill="white"/>
                    <path d="M614.25 1200.82C614.43 1200.6 614.6 1200.51 614.9 1200.64C615.34 1200.84 615.29 1201.14 615.11 1201.45C614.98 1201.68 614.77 1201.76 614.48 1201.62C614.04 1201.42 614.12 1201.14 614.25 1200.82Z" fill="white"/>
                    <path d="M708.54 1231.11C708.83 1231.25 709.09 1231.4 708.89 1231.85C708.76 1232.15 708.56 1232.23 708.31 1232.15C707.97 1232.04 707.73 1231.85 707.92 1231.41C708.05 1231.12 708.24 1231.06 708.54 1231.11Z" fill="white"/>
                    <path d="M513.77 1270.57C513.45 1270.21 513.24 1269.82 513.38 1269.33C513.42 1269.2 513.67 1269.01 513.68 1269.02C514.05 1269.34 514.25 1269.73 514.1 1270.23C514.06 1270.36 513.89 1270.45 513.77 1270.57Z" fill="white"/>
                    <path d="M580.33 1205.34C580.4 1205.04 580.65 1204.98 580.9 1205.06C581.24 1205.17 581.48 1205.36 581.29 1205.8C581.16 1206.1 580.96 1206.17 580.71 1206.1C580.37 1205.99 580.11 1205.79 580.34 1205.35L580.33 1205.34Z" fill="white"/>
                    <path d="M560.07 1306.91C560.25 1306.69 560.42 1306.6 560.72 1306.73C561.16 1306.93 561.11 1307.23 560.93 1307.54C560.8 1307.77 560.59 1307.85 560.3 1307.71C559.86 1307.51 559.94 1307.23 560.07 1306.91Z" fill="white"/>
                    <path d="M710.63 1402.4C710.84 1402.15 711.01 1402.05 711.31 1402.19C711.75 1402.39 711.7 1402.69 711.52 1403C711.39 1403.23 711.19 1403.3 710.89 1403.17C710.44 1402.97 710.56 1402.68 710.63 1402.4Z" fill="white"/>
                    <path d="M689.82 1397.85C690.29 1398.02 690.24 1398.34 690.05 1398.66C689.92 1398.89 689.71 1398.97 689.42 1398.83C688.98 1398.63 689.03 1398.33 689.21 1398.02C689.34 1397.79 689.56 1397.67 689.82 1397.85Z" fill="white"/>
                    <path d="M503.63 1235.83C503.48 1236.13 503.33 1236.45 502.91 1236.21C502.78 1236.14 502.68 1236 502.57 1235.9C502.9 1235.72 503.22 1235.54 503.55 1235.36C503.58 1235.51 503.6 1235.67 503.63 1235.83Z" fill="white"/>
                    <path d="M550.55 1237.98C550.7 1237.68 550.85 1237.36 551.27 1237.6C551.4 1237.67 551.5 1237.81 551.61 1237.91C551.28 1238.09 550.96 1238.27 550.63 1238.45C550.6 1238.3 550.58 1238.14 550.55 1237.98Z" fill="white"/>
                    <path d="M531.36 1356.74C531.69 1356.61 531.99 1356.48 532.3 1356.35C532.3 1356.58 532.36 1356.85 532.26 1357.04C532.05 1357.44 531.7 1357.27 531.43 1357.09C531.36 1357.04 531.38 1356.84 531.36 1356.74Z" fill="white"/>
                    <path d="M645.38 1261.96C645.52 1262.1 645.75 1262.26 645.73 1262.31C645.56 1262.73 645.08 1262.64 644.76 1262.84C644.74 1262.69 644.66 1262.51 644.7 1262.38C644.8 1262.04 645.01 1261.8 645.38 1261.96Z" fill="white"/>
                    <path d="M698.33 1199.03C698.44 1198.96 698.57 1198.81 698.66 1198.84C698.98 1198.92 699.34 1199.06 699.18 1199.49C699.11 1199.69 698.86 1199.82 698.7 1199.98C698.58 1199.67 698.46 1199.36 698.33 1199.03Z" fill="white"/>
                    <path d="M675.65 1211.48C675.21 1211.31 675.3 1210.99 675.47 1210.69C675.54 1210.57 675.72 1210.51 675.86 1210.43C675.95 1210.79 676.04 1211.15 676.12 1211.51C675.97 1211.5 675.81 1211.49 675.65 1211.48Z" fill="white"/>
                    <path d="M547.25 1342.72C547.31 1342.68 547.44 1342.52 547.52 1342.54C547.84 1342.62 548.2 1342.75 548.05 1343.18C547.98 1343.38 547.74 1343.51 547.58 1343.68C547.47 1343.37 547.37 1343.06 547.25 1342.72Z" fill="white"/>
                    <path d="M562.17 1312.48C562.34 1312.04 562.66 1312.13 562.96 1312.3C563.08 1312.37 563.14 1312.55 563.22 1312.69C562.86 1312.78 562.5 1312.87 562.14 1312.95C562.15 1312.8 562.16 1312.64 562.17 1312.48Z" fill="white"/>
                    <path d="M641.31 1185.98C641.44 1185.89 641.57 1185.8 641.7 1185.71C641.81 1186.06 641.91 1186.42 642.02 1186.77C641.87 1186.77 641.7 1186.8 641.56 1186.75C641.12 1186.61 641.18 1186.3 641.32 1185.97L641.31 1185.98Z" fill="white"/>
                    <path d="M690.88 1381.22C690.44 1381.05 690.53 1380.73 690.7 1380.43C690.77 1380.31 690.95 1380.25 691.09 1380.17C691.18 1380.53 691.27 1380.89 691.35 1381.25C691.2 1381.24 691.04 1381.23 690.88 1381.22Z" fill="white"/>
                    <path d="M724.44 1393.77C724.41 1393.55 724.34 1393.38 724.38 1393.25C724.49 1392.9 724.71 1392.66 725.1 1392.9C725.23 1392.97 725.32 1393.12 725.42 1393.23C725.1 1393.41 724.77 1393.59 724.43 1393.78L724.44 1393.77Z" fill="white"/>
                    <path d="M532.32 1410.15C532.6 1409.94 532.82 1410.15 533.02 1410.26C533.08 1410.29 533.12 1410.57 533.06 1410.63C532.82 1410.85 532.59 1410.63 532.38 1410.51C532.31 1410.47 532.33 1410.26 532.32 1410.14V1410.15Z" fill="white"/>
                    <path d="M529.3 1387.59C529.34 1387.3 529.33 1387.06 529.41 1386.85C529.46 1386.73 529.66 1386.67 529.79 1386.59C529.79 1386.82 529.82 1387.07 529.75 1387.28C529.71 1387.4 529.5 1387.46 529.3 1387.59Z" fill="white"/>
                    <path d="M742.66 1302.8C742.94 1302.59 743.16 1302.8 743.36 1302.91C743.42 1302.94 743.46 1303.22 743.4 1303.28C743.16 1303.5 742.93 1303.28 742.72 1303.16C742.65 1303.12 742.67 1302.91 742.66 1302.79V1302.8Z" fill="white"/>
                    <path d="M700 1215.61C700.29 1215.65 700.53 1215.64 700.74 1215.72C700.86 1215.77 700.92 1215.97 701 1216.1C700.77 1216.1 700.52 1216.13 700.31 1216.06C700.19 1216.02 700.13 1215.81 700 1215.61Z" fill="white"/>
                    <path d="M697.31 1214.8C697.43 1214.87 697.66 1214.94 697.83 1215.09C698.03 1215.26 697.95 1215.44 697.69 1215.4C697.47 1215.37 697.24 1215.26 697.07 1215.13C696.87 1214.97 696.94 1214.83 697.31 1214.81V1214.8Z" fill="white"/>
                    <path d="M754.55 1292.43C754.44 1292.32 754.23 1292.18 754.25 1292.1C754.33 1291.8 754.57 1291.83 754.78 1291.97C754.91 1292.05 755.11 1292.22 755.09 1292.3C755.01 1292.6 754.78 1292.56 754.56 1292.44L754.55 1292.43Z" fill="white"/>
                    <path d="M517.77 1360.19C517.88 1360.08 518.02 1359.87 518.1 1359.89C518.4 1359.97 518.37 1360.21 518.23 1360.42C518.15 1360.55 517.98 1360.75 517.9 1360.73C517.6 1360.65 517.64 1360.42 517.76 1360.2L517.77 1360.19Z" fill="white"/>
                    <path d="M634.26 1192.17C634.3 1191.88 634.29 1191.64 634.37 1191.43C634.42 1191.31 634.62 1191.25 634.75 1191.17C634.75 1191.4 634.78 1191.65 634.71 1191.86C634.67 1191.98 634.46 1192.04 634.26 1192.17Z" fill="white"/>
                    <path d="M644.08 1420.26C644.18 1420.2 644.31 1420.11 644.44 1420.03C644.44 1420.26 644.47 1420.51 644.4 1420.72C644.36 1420.84 644.15 1420.9 644.02 1420.98C643.9 1420.74 643.89 1420.51 644.08 1420.26Z" fill="white"/>
                    <path d="M596.97 1177.93C597.21 1178.01 597.4 1178.16 597.23 1178.41C597.18 1178.48 596.93 1178.44 596.78 1178.41C596.53 1178.35 596.35 1178.19 596.52 1177.93C596.57 1177.86 596.81 1177.93 596.97 1177.93Z" fill="white"/>
                    <path d="M659.25 1388.37C659.49 1388.45 659.68 1388.6 659.51 1388.85C659.46 1388.92 659.21 1388.88 659.06 1388.85C658.81 1388.79 658.63 1388.63 658.8 1388.37C658.85 1388.3 659.09 1388.37 659.25 1388.37Z" fill="white"/>
                    <path d="M655.25 1378.05C655.49 1378.13 655.68 1378.28 655.51 1378.53C655.46 1378.6 655.21 1378.56 655.06 1378.53C654.81 1378.47 654.63 1378.31 654.8 1378.05C654.85 1377.98 655.09 1378.05 655.25 1378.05Z" fill="white"/>
                    <path d="M635.24 1342.52C635.28 1342.23 635.27 1341.99 635.35 1341.78C635.4 1341.66 635.6 1341.6 635.73 1341.52C635.73 1341.75 635.76 1342 635.69 1342.21C635.65 1342.33 635.44 1342.39 635.24 1342.52Z" fill="white"/>
                    <path d="M540.5 1348.63C540.65 1348.51 540.83 1348.36 541.01 1348.22C541.04 1348.38 541.13 1348.57 541.07 1348.68C540.96 1348.87 540.75 1349.01 540.59 1349.18C540.56 1349.02 540.54 1348.87 540.5 1348.64V1348.63Z" fill="white"/>
                    <path d="M592.88 1284.26C592.98 1284.2 593.11 1284.11 593.24 1284.03C593.24 1284.26 593.27 1284.51 593.2 1284.72C593.16 1284.84 592.95 1284.9 592.82 1284.98C592.7 1284.74 592.69 1284.51 592.88 1284.26Z" fill="white"/>
                    <path d="M558.95 1262.15C558.99 1261.86 558.98 1261.62 559.06 1261.41C559.11 1261.29 559.31 1261.23 559.44 1261.15C559.44 1261.38 559.47 1261.63 559.4 1261.84C559.36 1261.96 559.15 1262.02 558.95 1262.15Z" fill="white"/>
                    <path d="M505.5 1227.89C505.78 1227.68 506 1227.89 506.2 1228C506.26 1228.03 506.3 1228.31 506.24 1228.37C506 1228.59 505.77 1228.37 505.56 1228.25C505.49 1228.21 505.51 1228 505.5 1227.88V1227.89Z" fill="white"/>
                    <path d="M562.37 1225.38C562.61 1225.46 562.8 1225.61 562.63 1225.86C562.58 1225.93 562.33 1225.89 562.18 1225.86C561.93 1225.8 561.75 1225.64 561.92 1225.38C561.97 1225.31 562.21 1225.38 562.37 1225.38Z" fill="white"/>
                    <path d="M726.74 1306.39C726.63 1306.28 726.42 1306.14 726.44 1306.06C726.52 1305.76 726.76 1305.79 726.97 1305.93C727.1 1306.01 727.3 1306.18 727.28 1306.26C727.2 1306.56 726.97 1306.52 726.75 1306.4L726.74 1306.39Z" fill="white"/>
                    <path d="M644.44 1284.92C644.55 1284.81 644.69 1284.6 644.77 1284.62C645.07 1284.7 645.04 1284.94 644.9 1285.15C644.82 1285.28 644.65 1285.48 644.57 1285.46C644.27 1285.38 644.31 1285.15 644.43 1284.93L644.44 1284.92Z" fill="white"/>
                    <path d="M649.19 1272.21C649.19 1272.08 649.16 1271.95 649.19 1271.82C649.19 1271.78 649.33 1271.76 649.41 1271.73C649.41 1271.86 649.44 1271.99 649.41 1272.12C649.41 1272.16 649.27 1272.18 649.19 1272.21Z" fill="white"/>
                    <path d="M642.23 1176.8C642.23 1176.67 642.2 1176.54 642.23 1176.41C642.23 1176.37 642.37 1176.35 642.45 1176.32C642.45 1176.45 642.48 1176.58 642.45 1176.71C642.45 1176.75 642.31 1176.77 642.23 1176.8Z" fill="white"/>
                    <path d="M646.9 1371.32C647 1371.24 647.1 1371.17 647.21 1371.09C647.24 1371.17 647.32 1371.28 647.29 1371.31C647.22 1371.41 647.1 1371.48 647 1371.56C646.97 1371.48 646.94 1371.41 646.91 1371.32H646.9Z" fill="white"/>
                    <path d="M677.72 1398.02C677.63 1398.06 677.55 1398.09 677.48 1398.11C677.48 1397.98 677.45 1397.85 677.48 1397.72C677.48 1397.68 677.62 1397.66 677.7 1397.63C677.71 1397.76 677.72 1397.89 677.73 1398.01L677.72 1398.02Z" fill="white"/>
                    <path d="M607.75 1368.6C607.83 1368.7 607.9 1368.8 607.98 1368.91C607.9 1368.94 607.79 1369.02 607.76 1368.99C607.66 1368.92 607.59 1368.8 607.51 1368.7C607.59 1368.67 607.66 1368.64 607.75 1368.61V1368.6Z" fill="white"/>
                    <path d="M594.21 1330.34C594.34 1330.34 594.47 1330.31 594.6 1330.34C594.64 1330.34 594.66 1330.48 594.69 1330.56C594.56 1330.56 594.43 1330.59 594.3 1330.56C594.26 1330.56 594.24 1330.42 594.21 1330.34Z" fill="white"/>
                    <path d="M557.14 1309.23C557.18 1309.32 557.21 1309.4 557.23 1309.47C557.1 1309.47 556.97 1309.5 556.84 1309.47C556.8 1309.47 556.78 1309.33 556.75 1309.25C556.88 1309.24 557.01 1309.23 557.13 1309.22L557.14 1309.23Z" fill="white"/>
                    <path d="M521.53 1347.97C521.63 1347.89 521.73 1347.82 521.84 1347.74C521.87 1347.82 521.95 1347.93 521.92 1347.96C521.85 1348.06 521.73 1348.13 521.63 1348.21C521.6 1348.13 521.57 1348.06 521.54 1347.97H521.53Z" fill="white"/>
                    <path d="M622.73 1377.03C622.82 1376.99 622.9 1376.96 622.97 1376.94C622.97 1377.07 623 1377.2 622.97 1377.33C622.97 1377.37 622.83 1377.39 622.75 1377.42C622.74 1377.29 622.73 1377.16 622.72 1377.04L622.73 1377.03Z" fill="white"/>
                    <path d="M614.71 1312.1C614.62 1312.14 614.54 1312.17 614.47 1312.19C614.47 1312.06 614.44 1311.93 614.47 1311.8C614.47 1311.76 614.61 1311.74 614.69 1311.71C614.7 1311.84 614.71 1311.97 614.72 1312.09L614.71 1312.1Z" fill="white"/>
                    <path d="M503.63 1251.88C503.6 1251.8 503.53 1251.68 503.55 1251.66C503.65 1251.58 503.77 1251.48 503.89 1251.48C504.13 1251.48 504.19 1251.61 504 1251.77C503.91 1251.85 503.75 1251.85 503.63 1251.88Z" fill="white"/>
                    <path d="M504.19 1251.35C504.32 1251.32 504.5 1251.22 504.56 1251.27C504.72 1251.41 504.69 1251.57 504.53 1251.59C504.39 1251.61 504.24 1251.58 504.1 1251.57C504.13 1251.5 504.16 1251.43 504.19 1251.36V1251.35Z" fill="white"/>
                    <path d="M675.62 1269.98C675.72 1269.9 675.82 1269.83 675.93 1269.75C675.96 1269.83 676.04 1269.94 676.01 1269.97C675.94 1270.07 675.82 1270.14 675.72 1270.22C675.69 1270.14 675.66 1270.07 675.63 1269.98H675.62Z" fill="white"/>
                    <path d="M473.52 1321.11C473.62 1321.03 473.72 1320.96 473.83 1320.88C473.86 1320.96 473.94 1321.07 473.91 1321.1C473.84 1321.2 473.72 1321.27 473.62 1321.35C473.59 1321.27 473.56 1321.2 473.53 1321.11H473.52Z" fill="white"/>
                    <path d="M500.52 1245.63C500.61 1245.59 500.69 1245.56 500.76 1245.54C500.76 1245.67 500.79 1245.8 500.76 1245.93C500.76 1245.97 500.62 1245.99 500.54 1246.02C500.53 1245.89 500.52 1245.76 500.51 1245.64L500.52 1245.63Z" fill="white"/>
                    <path d="M504.44 1343.7C504.34 1343.78 504.24 1343.85 504.13 1343.93C504.1 1343.85 504.02 1343.74 504.05 1343.71C504.12 1343.61 504.24 1343.54 504.34 1343.46C504.37 1343.54 504.4 1343.61 504.43 1343.7H504.44Z" fill="white"/>
                    <path d="M591.99 1250.21C592.09 1250.13 592.19 1250.06 592.3 1249.98C592.33 1250.06 592.41 1250.17 592.38 1250.2C592.31 1250.3 592.19 1250.37 592.09 1250.45C592.06 1250.37 592.03 1250.3 592 1250.21H591.99Z" fill="white"/>
                    <path d="M580.2 1370.7C580.24 1370.79 580.27 1370.87 580.29 1370.94C580.16 1370.94 580.03 1370.97 579.9 1370.94C579.86 1370.94 579.84 1370.8 579.81 1370.72C579.94 1370.71 580.07 1370.7 580.19 1370.69L580.2 1370.7Z" fill="white"/>
                    <path d="M530.96 1246.88C530.96 1246.75 530.93 1246.62 530.96 1246.49C530.96 1246.45 531.1 1246.43 531.18 1246.4C531.18 1246.53 531.21 1246.66 531.18 1246.79C531.18 1246.83 531.04 1246.85 530.96 1246.88Z" fill="white"/>
                    <path d="M568.99 1368.33C569.09 1368.25 569.19 1368.18 569.3 1368.1C569.33 1368.18 569.41 1368.29 569.38 1368.32C569.31 1368.42 569.19 1368.49 569.09 1368.57L569 1368.33H568.99Z" fill="white"/>
                    <path d="M635.95 1290.8C635.87 1290.7 635.8 1290.6 635.72 1290.49C635.8 1290.46 635.91 1290.38 635.94 1290.41C636.04 1290.48 636.11 1290.6 636.19 1290.7C636.11 1290.73 636.04 1290.76 635.95 1290.79V1290.8Z" fill="white"/>
                    <path d="M757.48 1285.17C757.48 1285.04 757.45 1284.91 757.48 1284.78C757.48 1284.74 757.62 1284.72 757.7 1284.69C757.7 1284.82 757.73 1284.95 757.7 1285.08C757.7 1285.12 757.56 1285.14 757.48 1285.17Z" fill="white"/>
                    <path d="M429.71 1204.65C431.16 1203.18 432.68 1203.71 434.1 1204.25C434.62 1204.45 435.38 1204.87 435.68 1204.87C437.63 1204.88 436.63 1206.97 437.69 1207.49C437.02 1208.45 436.53 1209.82 435.61 1210.24C434.73 1210.64 433.6 1211.05 432.52 1211.02C431.5 1210.99 430.06 1211.42 430.24 1209.46C430.27 1209.17 429.71 1208.85 429.46 1208.51C428.35 1207.02 428.47 1205.71 429.7 1204.64L429.71 1204.65Z" fill="white"/>
                    <path d="M432.04 1371.63C431.25 1370.7 431.42 1369.41 431.76 1369.13C433.04 1368.07 432.56 1366.49 433.24 1365.27C433.72 1364.41 434.15 1363.96 435.09 1364.09C436.65 1364.3 437.21 1364.76 437.29 1365.9C437.33 1366.4 437.42 1366.86 437.52 1367.35C437.98 1369.45 435.23 1372.83 432.89 1372.05C432.47 1371.91 432.12 1371.81 432.03 1371.62L432.04 1371.63Z" fill="white"/>
                    <path d="M495.29 1294.58C495.4 1293.51 494.21 1292.49 495.26 1291.43C495.41 1291.28 495.65 1291.18 495.72 1291.01C496.02 1290.28 496.09 1289.39 497.06 1289.16C497.82 1288.98 498.41 1289.49 499.05 1289.75C500.31 1290.27 501.02 1292.06 500.6 1293.5C500.27 1294.62 499.54 1295.2 498.38 1295.45C497.17 1295.71 496.31 1294.92 495.29 1294.57V1294.58Z" fill="white"/>
                    <path d="M633.13 1349.19C634.33 1350.58 634.36 1351.65 633.47 1352.63C632.98 1353.17 632.35 1353.59 631.82 1354.1C631.32 1354.59 630.96 1354.5 630.56 1353.98C630.11 1353.4 629.51 1352.92 629.13 1352.29C628.5 1351.23 628.6 1350.08 629.09 1348.99C629.19 1348.77 629.56 1348.5 629.78 1348.52C630.6 1348.59 631.37 1348.37 632.16 1348.29C632.54 1348.25 633.03 1349.09 633.12 1349.19H633.13Z" fill="white"/>
                    <path d="M542.95 1454.25C542.35 1453.67 541.49 1452.86 542.23 1451.75C542.58 1451.23 543.24 1450.24 543.92 1450.66C544.65 1451.11 545.84 1451.02 546.1 1452.12C546.28 1452.89 546.21 1453.76 546.55 1454.43C546.86 1455.05 546.79 1455.29 546.33 1455.71C545.67 1456.3 544.97 1456.23 544.36 1455.82C543.85 1455.48 543.51 1454.9 542.95 1454.25Z" fill="white"/>
                    <path d="M651.58 1335.6C651.02 1334.98 651.04 1334.02 651.63 1333.44C651.83 1333.25 654.76 1332.65 654.99 1332.75C655.5 1332.99 655.78 1334.91 655.69 1334.88C654.41 1334.53 654.91 1336.08 654.18 1336.22C653.48 1336.36 651.95 1336 651.59 1335.6H651.58Z" fill="white"/>
                    <path d="M499.11 1225.75C498.77 1226.03 498.34 1226.56 498.06 1226.24C497.54 1225.66 496.72 1225.25 496.78 1224.22C496.82 1223.65 497.29 1223.33 497.33 1222.86C497.39 1222.1 497.62 1221.77 498.46 1221.78C499.36 1221.79 499.94 1222.3 499.99 1222.99C500.06 1223.96 500.25 1225.13 499.11 1225.74V1225.75Z" fill="white"/>
                    <path d="M485.84 1334.82C486.25 1335.35 487.07 1335.77 486.08 1336.56C485.86 1336.74 486.01 1337.36 485.96 1337.78C485.9 1338.25 485.8 1338.72 485.72 1339.19C485.2 1338.71 484.42 1338.3 484.24 1337.72C484.02 1336.97 484.31 1336.07 484.33 1335.23C484.33 1334.77 484.44 1334.35 484.86 1334.21C485.33 1334.06 485.56 1334.53 485.83 1334.82H485.84Z" fill="white"/>
                    <path d="M605.95 1215.82C606.09 1215.98 606.24 1216.12 606.35 1216.29C606.6 1216.68 607.29 1216.94 606.86 1217.54C606.42 1218.15 605.79 1218.4 604.99 1218.25C604.31 1218.12 604.28 1217.71 604.27 1217.15C604.27 1216.79 603.95 1216.43 603.94 1216.07C603.92 1215.59 603.8 1214.99 604.37 1214.7C605.04 1214.35 605.2 1215.06 605.55 1215.36C605.71 1215.49 605.82 1215.67 605.96 1215.82H605.95Z" fill="white"/>
                    <path d="M652.4 1347.08C653.35 1346.88 654.28 1346.39 655.29 1346.66C655.52 1346.72 655.79 1347.01 655.72 1347.4C655.63 1347.89 655.85 1348.57 655.25 1348.76C654.52 1349 653.72 1349.09 652.94 1349.16C652.02 1349.24 652.73 1348.4 652.4 1348.1C652.05 1347.79 651.93 1347.43 652.4 1347.07V1347.08Z" fill="white"/>
                    <path d="M449.59 1298.13C450.04 1298.5 450.12 1298.89 449.57 1299.32C448.71 1299.99 447.87 1299.96 447.2 1299.26C446.6 1298.64 446.69 1297.93 447.06 1297.33C447.48 1296.65 448.7 1297.05 449.59 1298.12V1298.13Z" fill="white"/>
                    <path d="M355.42 1309.88C355.63 1310.12 355.88 1310.33 356.03 1310.6C356.38 1311.23 355.88 1311.97 355.17 1311.92C354.75 1311.89 354.31 1311.94 353.92 1311.82C353.42 1311.66 352.98 1311.21 352.96 1310.73C352.94 1310.18 353.62 1309.76 353.53 1309.11C354.11 1309.23 354.82 1308.93 355.22 1309.64C355.27 1309.73 355.36 1309.8 355.42 1309.88Z" fill="white"/>
                    <path d="M607.98 1308.81C608.01 1308.44 608.04 1308.13 608.04 1307.82C608.04 1307.03 609.21 1306.97 609.08 1306.07C609.01 1305.59 609.37 1305.28 609.84 1305.36C610.29 1305.44 610.45 1305.81 610.4 1306.28C610.34 1306.9 610.35 1307.53 610.25 1308.15C610.2 1308.44 610.52 1309.02 609.78 1308.94C609.18 1308.88 608.57 1308.85 607.97 1308.81H607.98Z" fill="white"/>
                    <path d="M619.52 1438.95C618.95 1439.31 617.8 1438.94 617.42 1438.27C617.27 1438 617.79 1436.13 618.13 1435.97C618.97 1435.57 619.15 1436.43 619.49 1436.83C620.12 1437.56 620.06 1438.61 619.52 1438.96V1438.95Z" fill="white"/>
                    <path d="M535.18 1414.07C535.6 1413.58 535.99 1413.42 536.44 1413.99C536.99 1414.68 537.1 1415.74 536.63 1416.16C536.16 1416.57 535.09 1416.32 534.52 1415.65C533.66 1414.65 534.92 1414.55 535.19 1414.07H535.18Z" fill="white"/>
                    <path d="M527.58 1415.43C527.48 1414.86 527.34 1414.24 527.81 1413.77C528.32 1413.26 529.69 1413.72 530.11 1414.47C530.38 1414.97 530.05 1415.21 529.72 1415.45C528.96 1415.99 527.95 1415.99 527.58 1415.43Z" fill="white"/>
                    <path d="M593.39 1344.19C593.66 1344.5 593.78 1344.67 593.93 1344.81C594.51 1345.32 594.12 1345.96 594.17 1346.54C593.6 1346.41 592.92 1346.71 592.49 1346.07C592.38 1345.9 592.23 1345.75 592.08 1345.6C591.58 1345.11 591.35 1344.72 592.06 1344.13C592.74 1343.56 593.12 1343.69 593.38 1344.2L593.39 1344.19Z" fill="white"/>
                    <path d="M444.43 1448.66C444.96 1448.14 445.39 1448.41 445.51 1448.79C445.74 1449.54 445.83 1450.34 445.92 1451.12C446 1451.85 445.43 1451.51 445.11 1451.54C443.71 1451.65 444.4 1450.33 443.93 1449.77C443.39 1449.13 444.31 1448.98 444.44 1448.66H444.43Z" fill="white"/>
                    <path d="M574.2 1377.19C573.51 1376.65 573.59 1375.92 573.94 1375.31C574.2 1374.85 575.93 1375.02 576.03 1375.44C576.21 1376.26 575.92 1376.92 575.22 1377.43C574.73 1377.79 574.49 1377.5 574.21 1377.18L574.2 1377.19Z" fill="white"/>
                    <path d="M540.01 1187.51C540.54 1187.19 541.12 1187.33 541.67 1187.56C542 1187.7 541.62 1189.4 541.17 1189.87C540.76 1190.3 540.46 1190.03 540.18 1189.7C539.63 1189.03 539.53 1187.95 540.01 1187.52V1187.51Z" fill="white"/>
                    <path d="M618.25 1371C617.96 1371.13 617.58 1371.17 617.49 1370.8C617.29 1369.94 616.86 1369.12 616.97 1368.18C617.02 1367.79 617.26 1367.54 617.5 1367.49C617.72 1367.44 618.19 1367.68 618.24 1367.87C618.44 1368.63 618.54 1369.42 618.65 1370.2C618.7 1370.53 618.55 1370.8 618.25 1370.99V1371Z" fill="white"/>
                    <path d="M649.49 1385.3C649.25 1385.07 648.94 1384.78 649.28 1384.34C649.58 1383.95 650 1384.1 650.39 1384.06C651.18 1383.98 651.47 1384.61 651.77 1385.08C651.95 1385.36 651.89 1386.13 651.71 1386.22C651.34 1386.4 650.77 1386.4 650.36 1386.24C650.02 1386.11 649.81 1385.66 649.49 1385.29V1385.3Z" fill="white"/>
                    <path d="M497.69 1468.69C497.36 1469.09 496.97 1469.17 496.54 1468.62C496 1467.93 495.9 1466.89 496.37 1466.47C497.15 1465.76 497.47 1467.23 498.25 1466.88C498.38 1466.82 498.27 1468.12 497.7 1468.69H497.69Z" fill="white"/>
                    <path d="M572.64 1287.63C572.63 1287.44 572.54 1287.19 572.62 1287.07C573.1 1286.4 574.92 1286.31 575.39 1286.95C575.55 1287.17 575.73 1287.54 575.64 1287.73C575.55 1287.93 575.18 1288.11 574.91 1288.13C573.75 1288.22 572.98 1288.03 572.63 1287.64L572.64 1287.63Z" fill="white"/>
                    <path d="M466.69 1235.52C466.46 1235.72 466.25 1235.98 465.98 1236.12C465.3 1236.5 465.14 1235.79 464.8 1235.49C464.36 1235.09 464.64 1234.78 464.97 1234.49C465.27 1234.23 465.52 1233.91 466.02 1233.98C466.52 1234.05 467.12 1233.95 467.31 1234.55C467.46 1235.02 466.98 1235.23 466.69 1235.51V1235.52Z" fill="white"/>
                    <path d="M614.92 1369.81C615.25 1370.38 614.88 1370.62 614.55 1370.85C613.77 1371.4 612.77 1371.38 612.42 1370.81C612.12 1370.33 612.5 1370.07 612.83 1369.84C613.61 1369.3 614.59 1369.32 614.92 1369.81Z" fill="white"/>
                    <path d="M503.17 1475.71C502.44 1475.63 501.74 1475.74 501.23 1475.01C500.81 1474.41 501.07 1474.15 501.48 1473.78C501.92 1473.37 502.21 1473.28 502.74 1473.75C503.42 1474.36 503.12 1475.04 503.17 1475.72V1475.71Z" fill="white"/>
                    <path d="M489.93 1466.83C489.9 1466.64 489.78 1466.37 489.87 1466.27C490.13 1465.96 490.45 1465.53 490.79 1465.49C491.34 1465.41 492.03 1465.26 492.46 1465.87C492.78 1466.34 492.41 1466.61 492.08 1466.84C491.29 1467.39 490.36 1467.38 489.94 1466.83H489.93Z" fill="white"/>
                    <path d="M489.42 1199.07C489.15 1198.74 488.86 1198.37 489.36 1198.06C490.01 1197.66 490.77 1197.64 491.21 1198.29C491.44 1198.63 491.27 1199.25 491.28 1199.74C490.6 1199.66 489.83 1199.86 489.42 1199.07Z" fill="white"/>
                    <path d="M398.71 1339.49C399.31 1339.03 400.48 1339.17 400.75 1339.62C401.04 1340.09 400.65 1340.31 400.36 1340.59C399.79 1341.15 399.11 1341.17 398.58 1340.73C398.42 1340.6 398.4 1339.76 398.71 1339.49Z" fill="white"/>
                    <path d="M564.61 1398.04C564.26 1397.64 563.92 1397.21 564.44 1396.97C565.08 1396.68 565.82 1396.63 566.52 1396.48C566.52 1397.2 566.47 1397.85 565.76 1398.34C565.08 1398.8 564.89 1398.26 564.62 1398.04H564.61Z" fill="white"/>
                    <path d="M407.64 1418.86C407 1418.27 407.28 1417.96 407.7 1417.53C408.35 1416.86 408.71 1417.2 409.18 1417.73C409.66 1418.27 409.44 1418.56 409 1418.98C408.36 1419.61 407.94 1419.44 407.64 1418.86Z" fill="white"/>
                    <path d="M577.96 1396.06C578.44 1395.77 578.84 1394.87 579.42 1395.77C579.74 1396.26 579.72 1396.95 579.27 1397.44C578.53 1398.24 577.99 1397.16 577.47 1397C576.81 1396.79 577.86 1396.44 577.96 1396.07V1396.06Z" fill="white"/>
                    <path d="M670.6 1384.4C670.4 1384.17 670.16 1383.96 670 1383.7C669.72 1383.22 670.1 1382.97 670.42 1382.73C670.65 1382.56 670.81 1382.2 671.19 1382.34C671.61 1382.5 671.8 1382.81 671.73 1383.27C671.7 1383.47 671.71 1383.68 671.68 1383.88C671.64 1384.27 671.71 1384.91 671.52 1385C671.1 1385.21 670.88 1384.66 670.6 1384.39V1384.4Z" fill="white"/>
                    <path d="M394.26 1279.39C393.57 1279.7 393.4 1279.05 393.09 1278.72C392.51 1278.11 393.19 1277.85 393.51 1277.53C393.81 1277.23 394.16 1276.98 394.51 1277.47C394.87 1277.97 394.72 1279.08 394.26 1279.38V1279.39Z" fill="white"/>
                    <path d="M499.12 1409.31C499.55 1408.97 499.88 1408.69 500.23 1409.18C500.59 1409.68 500.45 1410.81 499.98 1411.09C499.5 1411.37 499.3 1410.93 499 1410.68C498.27 1410.05 498.67 1409.64 499.12 1409.32V1409.31Z" fill="white"/>
                    <path d="M620.55 1395.15C620.36 1395.24 620.02 1395.53 619.85 1395.46C619.38 1395.26 619.65 1394.75 619.74 1394.47C619.88 1394.06 619.2 1393.12 620.4 1393.27C620.8 1393.32 621.24 1393.27 621.45 1393.7C621.86 1394.58 620.83 1394.58 620.55 1395.15Z" fill="white"/>
                    <path d="M593.39 1234.21C593.34 1233.72 592.99 1232.88 593.63 1232.83C594.18 1232.79 594.61 1232.02 595.15 1232.54C595.47 1232.85 594.73 1234.34 594.16 1234.54C593.86 1234.65 593.58 1234.52 593.39 1234.21Z" fill="white"/>
                    <path d="M420.92 1404.33C420.92 1404.33 420.38 1404.18 419.92 1403.96C419.72 1403.87 420.08 1402.47 420.34 1402.32C421.03 1401.94 421.18 1402.64 421.51 1402.94C421.92 1403.3 421.74 1403.63 420.92 1404.33Z" fill="white"/>
                    <path d="M400.5 1373.08C400.18 1372.4 400.85 1372.25 401.15 1371.91C401.53 1371.49 401.97 1371.68 402.1 1372.07C402.25 1372.52 402.2 1373.05 402.14 1373.54C402.12 1373.71 400.64 1373.32 400.5 1373.08Z" fill="white"/>
                    <path d="M441.19 1184.56C441.53 1184.12 441.86 1184.16 442.06 1184.39C442.59 1185 442.48 1185.67 442.04 1186.29C441.81 1186.61 441.48 1186.56 441.27 1186.33C440.7 1185.71 440.89 1185.06 441.19 1184.56Z" fill="white"/>
                    <path d="M596.45 1283.94C595.88 1284.3 595.23 1284.31 594.87 1283.9C594.7 1283.7 594.67 1282.92 595.14 1282.53C595.78 1282 596 1282.63 596.33 1282.95C596.63 1283.25 596.84 1283.58 596.45 1283.94Z" fill="white"/>
                    <path d="M377.58 1267C377.77 1266.78 379.25 1266.62 379.25 1266.8C379.25 1267.29 379.21 1267.82 379 1268.24C378.8 1268.63 378.34 1268.69 378.04 1268.24C377.79 1267.87 377.13 1267.63 377.58 1267Z" fill="white"/>
                    <path d="M601.93 1287.36C601.7 1286.82 601.6 1286.15 602.25 1285.61C602.49 1285.41 602.83 1285.4 603.01 1285.75C603.36 1286.43 603.38 1287.1 602.77 1287.64C602.54 1287.84 602.21 1287.84 601.93 1287.36Z" fill="white"/>
                    <path d="M664.12 1253.51C663.71 1253.1 663.88 1252.67 664.27 1252.54C664.72 1252.39 665.25 1252.44 665.74 1252.5C665.91 1252.52 665.52 1254 665.28 1254.14C664.93 1254.34 664.48 1254.09 664.12 1253.51Z" fill="white"/>
                    <path d="M573.37 1393.61C573.09 1393.27 572.8 1392.94 573.28 1392.59C573.8 1392.2 574.47 1392.23 574.82 1392.63C574.99 1392.83 575.02 1393.61 574.55 1394C573.91 1394.54 573.7 1393.87 573.37 1393.61Z" fill="white"/>
                    <path d="M663.73 1350.37C663.59 1349.79 663.52 1349.25 663.96 1348.77C664.22 1348.49 664.56 1348.51 664.73 1348.77C665.01 1349.19 665.91 1349.48 665.2 1350.22C664.77 1350.67 664.23 1350.27 663.74 1350.38L663.73 1350.37Z" fill="white"/>
                    <path d="M574.11 1379.01C574.09 1378.82 574.08 1378.63 574.06 1378.44C574.97 1378.48 575.89 1378.68 576.76 1378.2C576.8 1378.18 577.08 1378.48 577.14 1378.66C577.18 1378.76 576.99 1379.08 576.95 1379.07C576.12 1378.93 575.41 1379.73 574.58 1379.37C574.4 1379.29 574.26 1379.13 574.11 1379.01Z" fill="white"/>
                    <path d="M577.01 1375.03C577.5 1374.65 577.78 1375.48 578.06 1375.87C578.62 1376.65 577.67 1376.82 577.34 1377.21C577.24 1377.33 576.59 1377.45 576.87 1376.98C577.29 1376.28 575.75 1375.91 577.01 1375.03Z" fill="white"/>
                    <path d="M439.82 1234.56C439.33 1235.15 438.7 1234.75 438.13 1234.8C438.15 1234.41 438.01 1233.83 438.21 1233.66C438.65 1233.29 439.25 1233.39 439.73 1233.77C440.02 1234 439.96 1234.27 439.82 1234.55V1234.56Z" fill="white"/>
                    <path d="M428.28 1404.63C428.66 1404.39 428.96 1403.77 429.53 1404.4C429.94 1404.85 429.67 1405.14 429.33 1405.4C428.97 1405.68 428.65 1406.27 428.08 1405.65C427.66 1405.2 427.97 1404.93 428.28 1404.63Z" fill="white"/>
                    <path d="M504.34 1180.32C504.2 1179.29 504.56 1178.72 505.21 1178.33C505.36 1178.24 505.58 1178.27 505.78 1178.24C505.77 1178.44 505.71 1178.65 505.75 1178.85C505.88 1179.6 505.32 1179.94 504.85 1180.29C504.7 1180.4 504.42 1180.32 504.35 1180.32H504.34Z" fill="white"/>
                    <path d="M400.62 1421.49C400.51 1421.38 400.75 1421.06 401.11 1420.8C401.48 1420.53 401.78 1419.91 402.36 1420.53C402.77 1420.97 402.52 1421.26 402.18 1421.53C401.81 1421.81 401.49 1422.4 400.62 1421.49Z" fill="white"/>
                    <path d="M466.05 1406.32C466.19 1406.3 466.42 1406.19 466.5 1406.26C467.02 1406.75 467.5 1407.24 467.29 1408.09C467.2 1408.44 467.11 1408.62 466.78 1408.48C466.27 1408.25 465.77 1406.75 466.05 1406.33V1406.32Z" fill="white"/>
                    <path d="M435.11 1287.32C434.66 1286.42 435.66 1286.38 435.99 1285.96C436.14 1285.77 436.58 1286.01 436.72 1286.3C437.12 1287.16 436.22 1287.29 435.85 1287.67C435.68 1287.85 435.28 1287.63 435.11 1287.32Z" fill="white"/>
                    <path d="M527.64 1368.22C527.19 1367.32 528.19 1367.28 528.52 1366.86C528.67 1366.67 529.11 1366.91 529.25 1367.2C529.65 1368.06 528.75 1368.19 528.38 1368.57C528.21 1368.75 527.81 1368.53 527.64 1368.22Z" fill="white"/>
                    <path d="M565.89 1231.72C565.78 1231.61 566.02 1231.29 566.38 1231.03C566.75 1230.76 567.05 1230.14 567.63 1230.76C568.04 1231.2 567.79 1231.49 567.45 1231.76C567.08 1232.04 566.76 1232.63 565.89 1231.72Z" fill="white"/>
                    <path d="M572.51 1438.61C573.13 1438.84 573.77 1439.07 574.4 1439.32C574.43 1439.33 574.43 1439.54 574.38 1439.61C574.11 1439.96 573.77 1440.06 573.32 1440.01C572.81 1439.95 572.29 1439.96 572.18 1439.32C572.15 1439.17 572.33 1438.98 572.52 1438.61H572.51Z" fill="white"/>
                    <path d="M600.39 1387.42C600.09 1387.72 599.79 1388.06 599.36 1387.67C598.81 1387.17 599.11 1386.81 599.57 1386.45C599.89 1386.2 600.17 1385.82 600.6 1386.21C601.15 1386.7 600.88 1387.09 600.39 1387.41V1387.42Z" fill="white"/>
                    <path d="M617.42 1325.54C617.18 1325.01 617.12 1324.71 617.47 1324.53C618.15 1324.18 618.81 1324.17 619.36 1324.77C619.47 1324.89 619.42 1325.14 619.45 1325.33C618.75 1325.4 618.05 1325.48 617.43 1325.54H617.42Z" fill="white"/>
                    <path d="M515.14 1399.2C514.71 1398.83 514.52 1398.42 514.73 1398.27C515.16 1397.95 515.4 1397.08 516.2 1397.59C516.47 1397.76 516.65 1398.18 516.44 1398.37C516.04 1398.73 515.72 1399.42 515.14 1399.2Z" fill="white"/>
                    <path d="M546.68 1223.18C546.41 1222.84 546.14 1222.54 546.62 1222.14C547.27 1221.6 547.5 1222.23 547.83 1222.56C548.13 1222.86 548.36 1223.19 547.89 1223.58C547.24 1224.12 547.03 1223.47 546.68 1223.18Z" fill="white"/>
                    <path d="M474.29 1286.14C473.39 1286.59 473.35 1285.59 472.93 1285.26C472.74 1285.11 472.98 1284.67 473.27 1284.53C474.13 1284.13 474.26 1285.03 474.64 1285.4C474.82 1285.57 474.6 1285.97 474.29 1286.14Z" fill="white"/>
                    <path d="M626.59 1332.55C626.29 1332.85 625.99 1333.19 625.56 1332.8C625.01 1332.3 625.31 1331.94 625.77 1331.58C626.09 1331.33 626.37 1330.95 626.8 1331.34C627.35 1331.83 627.08 1332.22 626.59 1332.54V1332.55Z" fill="white"/>
                    <path d="M504.21 1459.57C503.71 1460.1 503.43 1460.05 503.19 1459.82C502.64 1459.31 502.97 1458.94 503.42 1458.62C503.66 1458.45 504.05 1458.02 504.14 1458.25C504.32 1458.73 504.21 1459.32 504.21 1459.56V1459.57Z" fill="white"/>
                    <path d="M510.92 1205.4C510.87 1205.24 510.8 1204.96 510.72 1204.69C511.29 1204.76 511.95 1204.5 512.36 1205.11C512.54 1205.37 512.52 1205.64 512.22 1205.89C511.58 1206.41 511.36 1205.8 510.91 1205.41L510.92 1205.4Z" fill="white"/>
                    <path d="M686.13 1353.82C686.3 1353.6 686.61 1353.63 686.85 1353.84C687.16 1354.12 687.42 1354.5 686.92 1354.83C686.57 1355.06 686.3 1355.83 685.8 1355.29C685.62 1355.1 685.61 1354.3 686.13 1353.82Z" fill="white"/>
                    <path d="M660.1 1329.44C660.36 1329.09 660 1329.38 660.4 1329.48C660.62 1329.53 660.91 1329.88 660.91 1330.09C660.89 1330.65 661.1 1331.34 660.39 1331.69C660.31 1331.73 660.12 1331.72 660.1 1331.68C659.75 1330.94 660.15 1330.19 660.1 1329.45V1329.44Z" fill="white"/>
                    <path d="M601.89 1319.04C601.97 1318.46 601.75 1317.74 602.47 1317.45C602.64 1317.38 603.07 1317.62 603.19 1317.83C603.57 1318.49 602.86 1318.65 602.56 1318.99C602.36 1319.23 602.12 1319.33 601.88 1319.03L601.89 1319.04Z" fill="white"/>
                    <path d="M500.91 1329.9C500.77 1329.74 500.59 1329.61 500.52 1329.42C500.47 1329.3 500.56 1329.01 500.65 1328.98C500.98 1328.87 502.28 1330.32 502.17 1330.68C502.14 1330.77 501.8 1330.87 501.74 1330.81C501.44 1330.53 501.18 1330.2 500.91 1329.89V1329.9Z" fill="white"/>
                    <path d="M633.58 1220.45C633.12 1219.99 633.35 1219.66 633.71 1219.41C633.97 1219.23 634.24 1219.25 634.49 1219.54C634.87 1219.98 634.67 1220.29 634.31 1220.54C634.06 1220.71 633.73 1220.78 633.58 1220.45Z" fill="white"/>
                    <path d="M523.76 1478.58C523.62 1478.27 523.6 1478.03 523.9 1477.78C524.34 1477.4 524.65 1477.61 524.9 1477.96C525.08 1478.22 525.06 1478.49 524.77 1478.74C524.32 1479.12 524.05 1478.89 523.77 1478.58H523.76Z" fill="white"/>
                    <path d="M397.64 1283C397.5 1282.69 397.48 1282.45 397.78 1282.2C398.22 1281.82 398.53 1282.03 398.78 1282.38C398.96 1282.64 398.94 1282.91 398.65 1283.16C398.2 1283.54 397.93 1283.31 397.65 1283H397.64Z" fill="white"/>
                    <path d="M479.62 1194.75C479.92 1194.5 480.22 1194.3 480.61 1194.75C480.87 1195.04 480.85 1195.31 480.64 1195.54C480.35 1195.86 480.02 1196.03 479.63 1195.59C479.37 1195.3 479.41 1195.06 479.63 1194.76L479.62 1194.75Z" fill="white"/>
                    <path d="M422.65 1429.65C422.09 1429.82 421.55 1429.85 421.09 1429.44C420.97 1429.33 420.89 1428.95 420.9 1428.94C421.44 1428.7 421.98 1428.68 422.44 1429.1C422.56 1429.21 422.58 1429.44 422.65 1429.64V1429.65Z" fill="white"/>
                    <path d="M385.13 1322.69C384.83 1322.46 384.9 1322.16 385.11 1321.92C385.4 1321.6 385.73 1321.44 386.12 1321.87C386.38 1322.16 386.36 1322.43 386.15 1322.66C385.85 1322.98 385.5 1323.16 385.13 1322.68V1322.69Z" fill="white"/>
                    <path d="M486.55 1397.38C486.41 1397.07 486.39 1396.83 486.69 1396.58C487.13 1396.2 487.44 1396.41 487.69 1396.76C487.87 1397.02 487.85 1397.29 487.56 1397.54C487.11 1397.92 486.84 1397.69 486.56 1397.38H486.55Z" fill="white"/>
                    <path d="M669.38 1280.78C669.21 1280.42 669.19 1280.18 669.49 1279.92C669.93 1279.54 670.24 1279.75 670.49 1280.1C670.67 1280.36 670.65 1280.62 670.36 1280.88C669.91 1281.28 669.65 1280.99 669.38 1280.77V1280.78Z" fill="white"/>
                    <path d="M653.64 1301.36C654.07 1300.94 654.4 1301.16 654.65 1301.52C654.83 1301.78 654.81 1302.05 654.52 1302.3C654.08 1302.68 653.77 1302.48 653.52 1302.12C653.34 1301.86 653.32 1301.55 653.64 1301.36Z" fill="white"/>
                    <path d="M379.16 1422.91C379.42 1423.23 379.69 1423.56 379.21 1423.9C379.06 1424.01 378.86 1424.04 378.69 1424.11C378.66 1423.66 378.63 1423.21 378.6 1422.76C378.78 1422.81 378.97 1422.86 379.16 1422.91Z" fill="white"/>
                    <path d="M405.72 1372.32C405.46 1372 405.19 1371.67 405.67 1371.33C405.82 1371.22 406.02 1371.19 406.19 1371.12C406.22 1371.57 406.25 1372.02 406.28 1372.47C406.1 1372.42 405.91 1372.37 405.72 1372.32Z" fill="white"/>
                    <path d="M526.64 1454.7C526.66 1454.27 526.68 1453.87 526.7 1453.47C526.95 1453.6 527.28 1453.67 527.43 1453.87C527.77 1454.31 527.39 1454.6 527.05 1454.81C526.96 1454.87 526.75 1454.74 526.64 1454.7Z" fill="white"/>
                    <path d="M481.04 1280.23C481.27 1280.14 481.56 1279.98 481.6 1280.03C481.98 1280.43 481.63 1280.92 481.69 1281.37C481.51 1281.32 481.28 1281.32 481.15 1281.2C480.83 1280.91 480.67 1280.56 481.04 1280.23Z" fill="white"/>
                    <path d="M439.03 1189.46C439.01 1189.3 438.92 1189.08 438.99 1188.99C439.25 1188.68 439.58 1188.36 439.97 1188.76C440.15 1188.94 440.17 1189.28 440.26 1189.54C439.85 1189.51 439.45 1189.49 439.03 1189.46Z" fill="white"/>
                    <path d="M441.04 1220.86C440.62 1221.26 440.32 1220.99 440.07 1220.65C439.97 1220.51 440.01 1220.28 439.98 1220.09C440.42 1220.18 440.87 1220.27 441.31 1220.36C441.22 1220.52 441.13 1220.69 441.03 1220.86H441.04Z" fill="white"/>
                    <path d="M519.39 1429.98C519.38 1429.9 519.27 1429.67 519.33 1429.59C519.58 1429.28 519.92 1428.95 520.31 1429.33C520.49 1429.5 520.52 1429.84 520.61 1430.11C520.21 1430.07 519.81 1430.02 519.38 1429.97L519.39 1429.98Z" fill="white"/>
                    <path d="M493.78 1397.94C493.38 1397.52 493.65 1397.22 493.99 1396.97C494.13 1396.87 494.36 1396.91 494.55 1396.88C494.46 1397.32 494.37 1397.77 494.28 1398.21C494.12 1398.12 493.95 1398.03 493.78 1397.93V1397.94Z" fill="white"/>
                    <path d="M395.24 1245.53C395.21 1245.34 395.17 1245.15 395.14 1244.96C395.59 1245.03 396.03 1245.09 396.48 1245.16C396.4 1245.33 396.35 1245.53 396.22 1245.66C395.84 1246.08 395.53 1245.85 395.24 1245.53Z" fill="white"/>
                    <path d="M635.87 1291.62C635.45 1292.02 635.15 1291.75 634.9 1291.41C634.8 1291.27 634.84 1291.04 634.81 1290.85C635.25 1290.94 635.7 1291.03 636.14 1291.12C636.05 1291.28 635.96 1291.45 635.86 1291.62H635.87Z" fill="white"/>
                    <path d="M667 1261.12C666.75 1261.04 666.52 1261.03 666.4 1260.91C666.08 1260.61 665.92 1260.25 666.38 1259.93C666.53 1259.83 666.73 1259.8 666.91 1259.74C666.94 1260.19 666.97 1260.64 667 1261.11V1261.12Z" fill="white"/>
                    <path d="M585.96 1481.19C585.88 1480.77 586.22 1480.65 586.45 1480.48C586.52 1480.43 586.85 1480.54 586.87 1480.63C586.99 1481.01 586.62 1481.15 586.39 1481.32C586.32 1481.38 586.1 1481.24 585.96 1481.2V1481.19Z" fill="white"/>
                    <path d="M559.56 1472.89C559.26 1472.7 558.99 1472.59 558.8 1472.39C558.69 1472.27 558.73 1472.02 558.71 1471.83C558.96 1471.96 559.25 1472.05 559.45 1472.23C559.56 1472.33 559.52 1472.59 559.56 1472.89Z" fill="white"/>
                    <path d="M576.19 1194.13C576.11 1193.71 576.45 1193.59 576.68 1193.42C576.75 1193.37 577.08 1193.48 577.1 1193.57C577.22 1193.95 576.85 1194.09 576.62 1194.26C576.55 1194.32 576.33 1194.18 576.19 1194.14V1194.13Z" fill="white"/>
                    <path d="M458.14 1196.17C458.33 1195.87 458.44 1195.6 458.64 1195.41C458.76 1195.3 459.01 1195.34 459.2 1195.32C459.07 1195.57 458.98 1195.86 458.8 1196.06C458.7 1196.17 458.44 1196.13 458.14 1196.17Z" fill="white"/>
                    <path d="M455.87 1198.71C456 1198.61 456.21 1198.4 456.46 1198.29C456.75 1198.16 456.9 1198.33 456.73 1198.6C456.58 1198.82 456.35 1199.03 456.11 1199.14C455.83 1199.28 455.71 1199.13 455.88 1198.71H455.87Z" fill="white"/>
                    <path d="M570.9 1175.69C570.72 1175.75 570.46 1175.92 570.38 1175.85C570.09 1175.61 570.24 1175.36 570.5 1175.2C570.66 1175.1 570.94 1174.97 571.02 1175.03C571.31 1175.27 571.15 1175.5 570.9 1175.68V1175.69Z" fill="white"/>
                    <path d="M523.44 1471.45C523.38 1471.27 523.21 1471.01 523.28 1470.93C523.52 1470.64 523.77 1470.79 523.93 1471.05C524.03 1471.21 524.16 1471.49 524.1 1471.57C523.86 1471.86 523.63 1471.7 523.45 1471.45H523.44Z" fill="white"/>
                    <path d="M398.43 1256.5C398.13 1256.31 397.86 1256.2 397.67 1256C397.56 1255.88 397.6 1255.63 397.58 1255.44C397.83 1255.57 398.12 1255.66 398.32 1255.84C398.43 1255.94 398.39 1256.2 398.43 1256.5Z" fill="white"/>
                    <path d="M654.74 1363.3C654.72 1363.16 654.7 1362.97 654.67 1362.78C654.92 1362.91 655.21 1363 655.41 1363.18C655.52 1363.28 655.48 1363.54 655.5 1363.74C655.18 1363.75 654.91 1363.64 654.74 1363.3Z" fill="white"/>
                    <path d="M363.52 1290.23C363.74 1290.01 363.99 1289.88 364.18 1290.2C364.23 1290.29 364.06 1290.55 363.94 1290.69C363.75 1290.93 363.48 1291.05 363.28 1290.73C363.23 1290.64 363.43 1290.4 363.51 1290.24L363.52 1290.23Z" fill="white"/>
                    <path d="M627.43 1330.14C627.65 1329.92 627.9 1329.79 628.09 1330.11C628.14 1330.2 627.97 1330.46 627.85 1330.6C627.66 1330.84 627.39 1330.96 627.19 1330.64C627.14 1330.55 627.34 1330.31 627.42 1330.15L627.43 1330.14Z" fill="white"/>
                    <path d="M614 1329.23C614.22 1329.01 614.47 1328.88 614.66 1329.2C614.71 1329.29 614.54 1329.55 614.42 1329.69C614.23 1329.93 613.96 1330.05 613.76 1329.73C613.71 1329.64 613.91 1329.4 613.99 1329.24L614 1329.23Z" fill="white"/>
                    <path d="M564.55 1332.95C564.25 1332.76 563.98 1332.65 563.79 1332.45C563.68 1332.33 563.72 1332.08 563.7 1331.89C563.95 1332.02 564.24 1332.11 564.44 1332.29C564.55 1332.39 564.51 1332.65 564.55 1332.95Z" fill="white"/>
                    <path d="M522.42 1440.46C522.36 1440.23 522.3 1439.95 522.23 1439.68C522.42 1439.73 522.68 1439.73 522.77 1439.85C522.93 1440.07 522.97 1440.37 523.07 1440.64C522.88 1440.59 522.7 1440.54 522.43 1440.46H522.42Z" fill="white"/>
                    <path d="M478.53 1349.56C478.51 1349.42 478.49 1349.23 478.46 1349.04C478.71 1349.17 479 1349.26 479.2 1349.44C479.31 1349.54 479.27 1349.8 479.29 1350C478.97 1350.01 478.7 1349.9 478.53 1349.56Z" fill="white"/>
                    <path d="M436.67 1375.54C436.37 1375.35 436.1 1375.24 435.91 1375.04C435.8 1374.92 435.84 1374.67 435.82 1374.48C436.07 1374.61 436.36 1374.7 436.56 1374.88C436.67 1374.98 436.63 1375.24 436.67 1375.54Z" fill="white"/>
                    <path d="M371.37 1416.75C371.29 1416.33 371.63 1416.21 371.86 1416.04C371.93 1415.99 372.26 1416.1 372.28 1416.19C372.4 1416.57 372.03 1416.71 371.8 1416.88C371.73 1416.94 371.51 1416.8 371.37 1416.76V1416.75Z" fill="white"/>
                    <path d="M397.94 1352.81C398.16 1352.59 398.41 1352.46 398.6 1352.78C398.65 1352.87 398.48 1353.13 398.36 1353.27C398.17 1353.51 397.9 1353.63 397.7 1353.31C397.65 1353.22 397.85 1352.98 397.93 1352.82L397.94 1352.81Z" fill="white"/>
                    <path d="M571.94 1213.53C571.76 1213.59 571.5 1213.76 571.42 1213.69C571.13 1213.45 571.28 1213.2 571.54 1213.04C571.7 1212.94 571.98 1212.81 572.06 1212.87C572.35 1213.11 572.19 1213.34 571.94 1213.52V1213.53Z" fill="white"/>
                    <path d="M505.85 1293.11C505.79 1292.93 505.62 1292.67 505.69 1292.59C505.93 1292.3 506.18 1292.45 506.34 1292.71C506.44 1292.87 506.57 1293.15 506.51 1293.23C506.27 1293.52 506.04 1293.36 505.86 1293.11H505.85Z" fill="white"/>
                    <path d="M494.29 1281.32C494.14 1281.26 493.98 1281.22 493.86 1281.13C493.82 1281.1 493.86 1280.94 493.87 1280.84C494.02 1280.9 494.18 1280.94 494.3 1281.03C494.34 1281.06 494.3 1281.22 494.29 1281.32Z" fill="white"/>
                    <path d="M385.6 1239.79C385.45 1239.73 385.29 1239.69 385.17 1239.6C385.13 1239.57 385.17 1239.41 385.18 1239.31C385.33 1239.37 385.49 1239.41 385.61 1239.5C385.65 1239.53 385.61 1239.69 385.6 1239.79Z" fill="white"/>
                    <path d="M602.29 1334.95C602.26 1334.8 602.22 1334.65 602.19 1334.49C602.29 1334.49 602.45 1334.47 602.48 1334.52C602.55 1334.65 602.57 1334.82 602.61 1334.97C602.51 1334.97 602.41 1334.96 602.29 1334.95Z" fill="white"/>
                    <path d="M647.59 1314.77C647.59 1314.89 647.58 1314.99 647.57 1315.09C647.42 1315.03 647.26 1314.99 647.14 1314.9C647.1 1314.87 647.14 1314.71 647.15 1314.61C647.3 1314.66 647.45 1314.72 647.59 1314.77Z" fill="white"/>
                    <path d="M579.1 1376.67C579.25 1376.64 579.4 1376.6 579.56 1376.57C579.56 1376.67 579.58 1376.83 579.53 1376.86C579.4 1376.93 579.23 1376.95 579.08 1376.99C579.08 1376.89 579.09 1376.79 579.1 1376.67Z" fill="white"/>
                    <path d="M529.97 1371.86C530.03 1371.71 530.07 1371.55 530.16 1371.43C530.19 1371.39 530.35 1371.43 530.45 1371.44C530.39 1371.59 530.35 1371.75 530.26 1371.87C530.23 1371.91 530.07 1371.87 529.97 1371.86Z" fill="white"/>
                    <path d="M487.6 1401.81C487.72 1401.81 487.82 1401.82 487.92 1401.83C487.86 1401.98 487.82 1402.14 487.73 1402.26C487.7 1402.3 487.54 1402.26 487.44 1402.25C487.49 1402.1 487.55 1401.95 487.6 1401.81Z" fill="white"/>
                    <path d="M511.92 1461.01C511.89 1460.86 511.85 1460.71 511.82 1460.55C511.92 1460.55 512.08 1460.53 512.11 1460.58C512.18 1460.71 512.2 1460.88 512.24 1461.03C512.14 1461.03 512.04 1461.02 511.92 1461.01Z" fill="white"/>
                    <path d="M596.11 1364.53C596.11 1364.41 596.12 1364.31 596.13 1364.21C596.28 1364.27 596.44 1364.31 596.56 1364.4C596.6 1364.43 596.56 1364.59 596.55 1364.69C596.4 1364.64 596.25 1364.58 596.11 1364.53Z" fill="white"/>
                    <path d="M520.45 1339.88C520.45 1340 520.44 1340.1 520.43 1340.2C520.28 1340.14 520.12 1340.1 520 1340.01C519.96 1339.98 520 1339.82 520.01 1339.72C520.16 1339.77 520.31 1339.83 520.45 1339.88Z" fill="white"/>
                    <path d="M396.84 1431.18C396.74 1431.18 396.57 1431.18 396.56 1431.16C396.53 1431.01 396.48 1430.82 396.53 1430.69C396.64 1430.42 396.83 1430.43 396.91 1430.72C396.95 1430.86 396.87 1431.03 396.84 1431.19V1431.18Z" fill="white"/>
                    <path d="M396.55 1430.29C396.58 1430.13 396.57 1429.88 396.65 1429.84C396.89 1429.74 397.04 1429.85 396.99 1430.04C396.94 1430.2 396.83 1430.35 396.74 1430.5C396.68 1430.43 396.62 1430.36 396.55 1430.29Z" fill="white"/>
                    <path d="M505.46 1251.06C505.43 1250.91 505.39 1250.76 505.36 1250.6C505.46 1250.6 505.62 1250.58 505.65 1250.63C505.72 1250.76 505.74 1250.93 505.78 1251.08C505.68 1251.08 505.58 1251.07 505.46 1251.06Z" fill="white"/>
                    <path d="M388.36 1431.38C388.36 1431.26 388.37 1431.16 388.38 1431.06C388.53 1431.12 388.69 1431.16 388.81 1431.25C388.85 1431.28 388.81 1431.44 388.8 1431.54C388.65 1431.49 388.5 1431.43 388.36 1431.38Z" fill="white"/>
                    <path d="M498.39 1477.63C498.42 1477.78 498.46 1477.93 498.49 1478.09C498.39 1478.09 498.23 1478.11 498.2 1478.06C498.13 1477.93 498.11 1477.76 498.07 1477.61C498.17 1477.61 498.27 1477.62 498.39 1477.63Z" fill="white"/>
                    <path d="M440.56 1332.98C440.53 1332.83 440.49 1332.68 440.46 1332.52C440.56 1332.52 440.72 1332.5 440.75 1332.55C440.82 1332.68 440.84 1332.85 440.88 1333C440.78 1333 440.68 1332.99 440.56 1332.98Z" fill="white"/>
                    <path d="M567.21 1408.11C567.33 1408.11 567.43 1408.12 567.53 1408.13C567.47 1408.28 567.43 1408.44 567.34 1408.56C567.31 1408.6 567.15 1408.56 567.05 1408.55C567.1 1408.4 567.16 1408.25 567.21 1408.11Z" fill="white"/>
                    <path d="M405.42 1398.49C405.27 1398.43 405.11 1398.39 404.99 1398.3C404.95 1398.27 404.99 1398.11 405 1398.01C405.15 1398.07 405.31 1398.11 405.43 1398.2C405.47 1398.23 405.43 1398.39 405.42 1398.49Z" fill="white"/>
                    <path d="M558.81 1419.23C558.78 1419.08 558.74 1418.93 558.71 1418.77C558.81 1418.77 558.97 1418.75 559 1418.8C559.07 1418.93 559.09 1419.1 559.13 1419.25L558.81 1419.23Z" fill="white"/>
                    <path d="M507.94 1305.5C507.79 1305.53 507.64 1305.57 507.48 1305.6C507.48 1305.5 507.46 1305.34 507.51 1305.31C507.64 1305.24 507.81 1305.22 507.96 1305.18C507.96 1305.28 507.95 1305.38 507.94 1305.5Z" fill="white"/>
                    <path d="M564.41 1168.73C564.26 1168.67 564.1 1168.63 563.98 1168.54C563.94 1168.51 563.98 1168.35 563.99 1168.25C564.14 1168.31 564.3 1168.35 564.42 1168.44C564.46 1168.47 564.42 1168.63 564.41 1168.73Z" fill="white"/>
                    <path d="M226.59 1374.27C226.06 1372.35 227.24 1371.34 228.37 1370.41C228.78 1370.07 229.5 1369.64 229.65 1369.38C230.6 1367.76 231.86 1369.6 232.81 1368.96C233.28 1369.98 234.2 1371.05 234.11 1372.02C234.02 1372.94 233.82 1374.09 233.27 1374.97C232.76 1375.81 232.42 1377.21 230.87 1376.13C230.64 1375.97 230.1 1376.28 229.7 1376.33C227.92 1376.54 226.88 1375.8 226.59 1374.27Z" fill="white"/>
                    <path d="M367.13 1452.82C365.97 1453.03 364.98 1452.26 364.91 1451.85C364.65 1450.27 363.09 1449.91 362.4 1448.75C361.91 1447.93 361.75 1447.36 362.31 1446.63C363.24 1445.43 363.89 1445.18 364.88 1445.66C365.31 1445.87 365.75 1446.02 366.2 1446.16C368.18 1446.79 369.68 1450.71 367.9 1452.29C367.58 1452.57 367.33 1452.81 367.13 1452.8V1452.82Z" fill="white"/>
                    <path d="M333.29 1362.87C332.45 1362.26 331.02 1362.76 330.65 1361.37C330.6 1361.17 330.63 1360.93 330.52 1360.78C330.06 1360.18 329.34 1359.69 329.63 1358.77C329.85 1358.05 330.56 1357.8 331.08 1357.4C332.12 1356.6 333.96 1356.87 334.96 1357.92C335.73 1358.73 335.87 1359.62 335.52 1360.72C335.15 1361.85 334.08 1362.19 333.3 1362.88L333.29 1362.87Z" fill="white"/>
                    <path d="M445.35 1274.11C447.09 1273.78 448 1274.26 448.39 1275.49C448.6 1276.16 448.65 1276.89 448.82 1277.58C448.99 1278.23 448.74 1278.49 448.11 1278.57C447.41 1278.67 446.71 1278.94 446.01 1278.95C444.82 1278.97 443.91 1278.33 443.23 1277.39C443.09 1277.2 443.05 1276.76 443.17 1276.59C443.62 1275.94 443.82 1275.19 444.12 1274.49C444.27 1274.15 445.2 1274.15 445.33 1274.12L445.35 1274.11Z" fill="white"/>
                    <path d="M489.59 1400.05C488.82 1400.27 487.73 1400.6 487.16 1399.45C486.89 1398.9 486.39 1397.88 487.06 1397.52C487.79 1397.13 488.29 1396.09 489.33 1396.4C490.06 1396.62 490.75 1397.1 491.47 1397.14C492.14 1397.17 492.31 1397.35 492.43 1397.94C492.61 1398.78 492.21 1399.33 491.57 1399.64C491.04 1399.9 490.39 1399.9 489.58 1400.06L489.59 1400.05Z" fill="white"/>
                    <path d="M442.89 1252.15C442.1 1252.32 441.31 1251.84 441.11 1251.07C441.04 1250.81 441.96 1248.07 442.16 1247.93C442.61 1247.62 444.34 1248.31 444.27 1248.38C443.36 1249.28 444.89 1249.61 444.66 1250.29C444.44 1250.94 443.4 1252.05 442.89 1252.15Z" fill="white"/>
                    <path d="M277.66 1326.5C277.73 1326.92 277.96 1327.53 277.57 1327.62C276.84 1327.77 276.1 1328.27 275.26 1327.71C274.8 1327.4 274.77 1326.85 274.39 1326.59C273.78 1326.17 273.62 1325.82 274.03 1325.13C274.47 1324.39 275.18 1324.14 275.78 1324.44C276.62 1324.85 277.69 1325.26 277.65 1326.5H277.66Z" fill="white"/>
                    <path d="M362.34 1390.16C362.98 1390.08 363.73 1389.6 363.91 1390.8C363.95 1391.07 364.55 1391.24 364.87 1391.49C365.23 1391.77 365.58 1392.08 365.93 1392.37C365.28 1392.57 364.56 1393.03 363.99 1392.89C363.25 1392.71 362.65 1392.03 361.96 1391.62C361.58 1391.39 361.27 1391.11 361.37 1390.68C361.47 1390.21 361.97 1390.25 362.35 1390.16H362.34Z" fill="white"/>
                    <path d="M320.89 1232.5C321.09 1232.46 321.28 1232.4 321.48 1232.39C321.92 1232.37 322.48 1231.92 322.77 1232.57C323.07 1233.23 322.98 1233.88 322.46 1234.47C322.02 1234.97 321.67 1234.81 321.19 1234.54C320.89 1234.37 320.44 1234.46 320.13 1234.3C319.72 1234.08 319.16 1233.9 319.19 1233.29C319.23 1232.56 319.89 1232.77 320.31 1232.62C320.5 1232.55 320.7 1232.54 320.89 1232.5Z" fill="white"/>
                    <path d="M452.87 1257C453.16 1256.11 453.2 1255.1 453.92 1254.39C454.08 1254.23 454.46 1254.14 454.75 1254.39C455.11 1254.7 455.79 1254.84 455.66 1255.44C455.51 1256.17 455.2 1256.88 454.88 1257.56C454.5 1258.37 454.14 1257.36 453.74 1257.5C453.32 1257.64 452.95 1257.56 452.88 1257H452.87Z" fill="white"/>
                    <path d="M314.23 1402.74C314.75 1402.54 315.12 1402.66 315.22 1403.33C315.37 1404.37 314.94 1405.06 314.03 1405.28C313.22 1405.48 312.67 1405.06 312.35 1404.47C311.98 1403.79 312.91 1402.96 314.23 1402.74Z" fill="white"/>
                    <path d="M399.5 1275.64C399.2 1275.44 398.96 1275.26 398.7 1275.11C398.04 1274.73 398.56 1273.72 397.74 1273.4C397.31 1273.23 397.22 1272.78 397.52 1272.42C397.8 1272.08 398.19 1272.13 398.56 1272.39C399.05 1272.74 399.58 1273.04 400.05 1273.41C400.27 1273.59 400.91 1273.6 400.49 1274.18C400.15 1274.65 399.84 1275.15 399.51 1275.63L399.5 1275.64Z" fill="white"/>
                    <path d="M513.73 1328.75C513.76 1329.4 512.89 1330.18 512.15 1330.17C511.86 1330.17 510.54 1328.83 510.57 1328.47C510.64 1327.57 511.45 1327.84 511.94 1327.75C512.85 1327.58 513.7 1328.13 513.73 1328.75Z" fill="white"/>
                    <path d="M452.3 1387.17C452.09 1386.58 452.15 1386.18 452.84 1386.08C453.68 1385.95 454.62 1386.37 454.74 1386.97C454.86 1387.57 454.13 1388.33 453.3 1388.49C452.05 1388.72 452.57 1387.62 452.3 1387.17Z" fill="white"/>
                    <path d="M449.76 1394.17C449.24 1393.98 448.65 1393.79 448.49 1393.17C448.31 1392.5 449.35 1391.57 450.18 1391.59C450.73 1391.6 450.77 1392 450.81 1392.38C450.9 1393.28 450.4 1394.12 449.76 1394.16V1394.17Z" fill="white"/>
                    <path d="M422.01 1304.88C422.4 1304.8 422.6 1304.79 422.79 1304.72C423.5 1304.49 423.84 1305.11 424.35 1305.35C423.97 1305.76 423.89 1306.48 423.14 1306.53C422.94 1306.54 422.75 1306.59 422.55 1306.64C421.9 1306.82 421.47 1306.82 421.31 1305.95C421.16 1305.11 421.46 1304.86 422 1304.88H422.01Z" fill="white"/>
                    <path d="M437.42 1479.62C437.24 1478.93 437.67 1478.7 438.04 1478.78C438.78 1478.95 439.49 1479.26 440.18 1479.57C440.83 1479.86 440.27 1480.17 440.13 1480.45C439.55 1481.67 438.78 1480.46 438.09 1480.58C437.29 1480.72 437.61 1479.88 437.41 1479.62H437.42Z" fill="white"/>
                    <path d="M440.31 1336.81C439.52 1337.13 438.95 1336.7 438.61 1336.12C438.35 1335.68 439.33 1334.31 439.73 1334.44C440.5 1334.69 440.91 1335.24 441 1336.08C441.06 1336.67 440.7 1336.72 440.31 1336.81Z" fill="white"/>
                    <path d="M265.45 1273.91C265.44 1273.31 265.84 1272.89 266.29 1272.55C266.56 1272.34 267.8 1273.48 267.97 1274.08C268.13 1274.63 267.76 1274.75 267.35 1274.82C266.52 1274.96 265.58 1274.52 265.45 1273.91Z" fill="white"/>
                    <path d="M456.38 1297.05C456.35 1297.36 456.19 1297.69 455.85 1297.59C455.03 1297.34 454.15 1297.3 453.42 1296.76C453.11 1296.53 453.02 1296.2 453.1 1295.99C453.17 1295.78 453.59 1295.51 453.77 1295.56C454.5 1295.76 455.21 1296.06 455.92 1296.34C456.22 1296.46 456.37 1296.72 456.39 1297.06L456.38 1297.05Z" fill="white"/>
                    <path d="M483.38 1277.85C483.07 1277.94 482.68 1278.06 482.48 1277.56C482.3 1277.13 482.63 1276.85 482.78 1276.5C483.09 1275.8 483.76 1275.87 484.29 1275.84C484.61 1275.82 485.23 1276.25 485.22 1276.44C485.19 1276.84 484.91 1277.31 484.59 1277.58C484.32 1277.8 483.84 1277.76 483.38 1277.85Z" fill="white"/>
                    <path d="M479.83 1444.81C480.01 1445.28 479.88 1445.65 479.22 1445.74C478.38 1445.85 477.47 1445.44 477.34 1444.85C477.12 1443.86 478.5 1444.29 478.59 1443.48C478.6 1443.34 479.63 1444.06 479.84 1444.82L479.83 1444.81Z" fill="white"/>
                    <path d="M364.78 1294.94C364.62 1294.86 364.36 1294.82 364.3 1294.69C363.97 1293.97 364.78 1292.4 365.53 1292.31C365.79 1292.28 366.18 1292.31 366.3 1292.47C366.43 1292.64 366.39 1293.04 366.28 1293.27C365.79 1294.28 365.26 1294.83 364.77 1294.94H364.78Z" fill="white"/>
                    <path d="M270.19 1358.27C270.25 1358.56 270.36 1358.86 270.35 1359.15C270.34 1359.9 269.67 1359.69 269.25 1359.83C268.71 1360.01 268.58 1359.62 268.5 1359.21C268.43 1358.83 268.29 1358.47 268.58 1358.09C268.88 1357.7 269.09 1357.16 269.68 1357.29C270.14 1357.39 270.09 1357.9 270.18 1358.27H270.19Z" fill="white"/>
                    <path d="M453.78 1299.25C454.41 1299.25 454.44 1299.68 454.47 1300.06C454.55 1300.97 454.06 1301.8 453.41 1301.82C452.87 1301.84 452.83 1301.4 452.8 1301.01C452.72 1300.1 453.21 1299.29 453.78 1299.25Z" fill="white"/>
                    <path d="M488.33 1443.61C487.92 1444.18 487.67 1444.81 486.81 1444.89C486.11 1444.95 486.02 1444.61 485.9 1444.09C485.77 1443.52 485.84 1443.24 486.48 1443.03C487.32 1442.75 487.74 1443.34 488.33 1443.62V1443.61Z" fill="white"/>
                    <path d="M474.54 1450.39C474.37 1450.32 474.08 1450.29 474.04 1450.17C473.91 1449.8 473.7 1449.33 473.83 1449.03C474.03 1448.53 474.24 1447.88 474.95 1447.82C475.5 1447.78 475.54 1448.22 475.58 1448.61C475.66 1449.53 475.2 1450.31 474.54 1450.39Z" fill="white"/>
                    <path d="M250.72 1321.73C250.31 1321.8 249.87 1321.87 249.85 1321.3C249.83 1320.56 250.17 1319.92 250.93 1319.86C251.33 1319.83 251.76 1320.27 252.18 1320.5C251.79 1321.03 251.58 1321.77 250.72 1321.73Z" fill="white"/>
                    <path d="M324.23 1465.16C324.14 1464.44 324.82 1463.53 325.33 1463.52C325.87 1463.51 325.86 1463.94 325.96 1464.31C326.15 1465.06 325.84 1465.63 325.21 1465.86C325.02 1465.93 324.31 1465.54 324.23 1465.15V1465.16Z" fill="white"/>
                    <path d="M453.1 1354.87C452.6 1354.97 452.07 1355.04 452.12 1354.5C452.19 1353.83 452.5 1353.18 452.71 1352.53C453.31 1352.88 453.83 1353.23 453.89 1354.07C453.95 1354.86 453.41 1354.76 453.09 1354.88L453.1 1354.87Z" fill="white"/>
                    <path d="M457.88 1342.76C457.88 1342.22 457.31 1341.45 458.34 1341.4C458.9 1341.37 459.47 1341.72 459.66 1342.33C459.97 1343.34 458.81 1343.27 458.42 1343.62C457.93 1344.07 458.14 1343.02 457.88 1342.76Z" fill="white"/>
                    <path d="M492.8 1259.8C492.51 1259.86 492.22 1259.96 491.92 1259.96C491.39 1259.96 491.36 1259.53 491.31 1259.15C491.28 1258.87 491.06 1258.57 491.35 1258.32C491.68 1258.04 492.04 1258.04 492.39 1258.32C492.54 1258.44 492.72 1258.54 492.88 1258.65C493.19 1258.87 493.75 1259.12 493.74 1259.32C493.71 1259.78 493.15 1259.69 492.79 1259.8H492.8Z" fill="white"/>
                    <path d="M271.91 1439.91C271.83 1440.63 271.21 1440.47 270.78 1440.57C269.99 1440.76 270.1 1440.07 269.99 1439.65C269.89 1439.26 269.85 1438.84 270.43 1438.78C271.02 1438.72 271.88 1439.38 271.91 1439.91Z" fill="white"/>
                    <path d="M430.94 1414.99C430.86 1414.47 430.79 1414.05 431.37 1414C431.96 1413.94 432.84 1414.6 432.84 1415.13C432.84 1415.67 432.38 1415.63 432.02 1415.75C431.14 1416.06 431 1415.52 430.94 1414.99Z" fill="white"/>
                    <path d="M477.66 1306.77C477.64 1306.98 477.72 1307.39 477.58 1307.5C477.19 1307.79 476.89 1307.32 476.7 1307.11C476.42 1306.79 475.31 1306.91 476.02 1305.98C476.25 1305.67 476.42 1305.27 476.88 1305.31C477.81 1305.39 477.32 1306.25 477.66 1306.76V1306.77Z" fill="white"/>
                    <path d="M330.18 1251.86C329.75 1251.66 328.88 1251.55 329.15 1251C329.38 1250.52 328.95 1249.79 329.64 1249.59C330.06 1249.47 330.94 1250.81 330.83 1251.38C330.77 1251.68 330.53 1251.85 330.18 1251.86Z" fill="white"/>
                    <path d="M389.07 1477.88C389.07 1477.88 388.68 1478.26 388.28 1478.54C388.11 1478.66 387.11 1477.69 387.12 1477.4C387.14 1476.65 387.79 1476.86 388.2 1476.72C388.7 1476.56 388.89 1476.86 389.08 1477.88H389.07Z" fill="white"/>
                    <path d="M353.14 1479.86C352.42 1479.8 352.61 1479.17 352.48 1478.75C352.31 1478.23 352.69 1477.95 353.07 1478.03C353.52 1478.12 353.94 1478.42 354.31 1478.71C354.45 1478.81 353.41 1479.86 353.14 1479.86Z" fill="white"/>
                    <path d="M215.35 1355C215.14 1354.5 215.35 1354.25 215.63 1354.19C216.4 1354.05 216.9 1354.45 217.21 1355.12C217.37 1355.47 217.16 1355.72 216.87 1355.78C216.08 1355.96 215.62 1355.49 215.35 1354.99V1355Z" fill="white"/>
                    <path d="M373.18 1273.27C373.21 1273.92 372.9 1274.47 372.39 1274.57C372.14 1274.62 371.48 1274.27 371.38 1273.68C371.25 1272.89 371.88 1273.01 372.3 1272.89C372.7 1272.78 373.07 1272.77 373.18 1273.27Z" fill="white"/>
                    <path d="M253.52 1447.86C253.43 1447.59 254.01 1446.28 254.16 1446.37C254.56 1446.61 255 1446.89 255.24 1447.27C255.47 1447.62 255.3 1448.04 254.77 1448.07C254.34 1448.1 253.82 1448.54 253.51 1447.85L253.52 1447.86Z" fill="white"/>
                    <path d="M378.68 1270.35C378.12 1270.28 377.51 1270.04 377.37 1269.24C377.32 1268.95 377.48 1268.66 377.86 1268.67C378.6 1268.7 379.16 1269.01 379.32 1269.78C379.38 1270.07 379.22 1270.35 378.68 1270.35Z" fill="white"/>
                    <path d="M380.4 1202.11C379.86 1202.25 379.58 1201.9 379.66 1201.51C379.75 1201.06 380.05 1200.64 380.34 1200.27C380.44 1200.13 381.49 1201.17 381.49 1201.44C381.49 1201.83 381.07 1202.08 380.4 1202.11Z" fill="white"/>
                    <path d="M453.62 1345.42C453.2 1345.49 452.79 1345.57 452.72 1345C452.65 1344.38 452.99 1343.83 453.5 1343.74C453.75 1343.69 454.41 1344.04 454.51 1344.63C454.65 1345.43 454 1345.28 453.61 1345.42H453.62Z" fill="white"/>
                    <path d="M461.09 1249.12C460.54 1248.96 460.05 1248.75 459.86 1248.16C459.75 1247.81 459.93 1247.54 460.23 1247.52C460.72 1247.49 461.39 1246.88 461.67 1247.83C461.84 1248.41 461.25 1248.66 461.09 1249.13V1249.12Z" fill="white"/>
                    <path d="M441.79 1337.77C441.63 1337.69 441.46 1337.61 441.3 1337.54C441.77 1336.8 442.38 1336.13 442.4 1335.17C442.4 1335.13 442.78 1335.04 442.97 1335.07C443.08 1335.09 443.25 1335.39 443.22 1335.43C442.7 1336.05 443.03 1337.03 442.33 1337.56C442.18 1337.67 441.98 1337.71 441.8 1337.78L441.79 1337.77Z" fill="white"/>
                    <path d="M439.86 1333.42C439.78 1332.82 440.61 1333 441.07 1332.95C441.99 1332.86 441.67 1333.73 441.84 1334.2C441.89 1334.34 441.68 1334.94 441.42 1334.48C441.04 1333.79 439.99 1334.9 439.86 1333.42Z" fill="white"/>
                    <path d="M256.44 1380.25C256.7 1380.94 256.05 1381.28 255.83 1381.78C255.51 1381.58 254.96 1381.42 254.92 1381.17C254.83 1380.62 255.2 1380.18 255.75 1379.95C256.08 1379.82 256.28 1380 256.45 1380.25H256.44Z" fill="white"/>
                    <path d="M392.88 1471.87C392.86 1471.44 392.49 1470.89 393.29 1470.71C393.87 1470.58 393.97 1470.95 394.03 1471.36C394.09 1471.8 394.43 1472.35 393.64 1472.53C393.06 1472.66 392.99 1472.28 392.89 1471.87H392.88Z" fill="white"/>
                    <path d="M242.26 1300.23C241.33 1299.85 241.03 1299.28 241.01 1298.54C241.01 1298.37 241.14 1298.2 241.21 1298.03C241.37 1298.14 241.52 1298.29 241.7 1298.35C242.39 1298.61 242.4 1299.23 242.47 1299.8C242.49 1299.97 242.28 1300.18 242.25 1300.23H242.26Z" fill="white"/>
                    <path d="M412.5 1441.15C412.55 1441.02 412.57 1440.78 412.66 1440.75C413.32 1440.55 413.96 1440.39 414.57 1440.97C414.82 1441.21 414.93 1441.38 414.65 1441.58C414.21 1441.9 412.72 1441.58 412.5 1441.15Z" fill="white"/>
                    <path d="M298.22 1409.62C297.25 1409.56 297.7 1408.71 297.51 1408.23C297.42 1408.01 297.84 1407.76 298.15 1407.79C299.06 1407.87 298.73 1408.68 298.87 1409.18C298.94 1409.41 298.56 1409.64 298.22 1409.63V1409.62Z" fill="white"/>
                    <path d="M410.38 1371.36C409.41 1371.3 409.86 1370.45 409.67 1369.97C409.58 1369.75 410 1369.5 410.31 1369.53C411.22 1369.61 410.89 1370.42 411.03 1370.92C411.1 1371.15 410.72 1371.38 410.38 1371.37V1371.36Z" fill="white"/>
                    <path d="M314.84 1273.61C314.69 1273.65 314.55 1273.29 314.5 1272.87C314.45 1272.43 314.08 1271.88 314.88 1271.7C315.44 1271.57 315.57 1271.92 315.63 1272.33C315.69 1272.77 316.03 1273.32 314.84 1273.61Z" fill="white"/>
                    <path d="M490.78 1367.83C491.27 1367.42 491.77 1367 492.29 1366.6C492.31 1366.58 492.49 1366.68 492.52 1366.76C492.68 1367.15 492.6 1367.49 492.34 1367.84C492.04 1368.23 491.8 1368.68 491.22 1368.46C491.08 1368.41 491.01 1368.17 490.79 1367.84L490.78 1367.83Z" fill="white"/>
                    <path d="M461.49 1319.87C461.6 1320.26 461.74 1320.68 461.21 1320.85C460.53 1321.07 460.37 1320.65 460.29 1320.09C460.24 1319.7 460.06 1319.29 460.59 1319.11C461.27 1318.89 461.46 1319.3 461.5 1319.86L461.49 1319.87Z" fill="white"/>
                    <path d="M418.02 1275.82C417.46 1275.76 417.18 1275.67 417.2 1275.29C417.23 1274.55 417.54 1274 418.31 1273.83C418.46 1273.8 418.65 1273.96 418.82 1274.03C418.54 1274.65 418.27 1275.27 418.02 1275.82Z" fill="white"/>
                    <path d="M430.22 1396.73C429.7 1396.91 429.27 1396.88 429.24 1396.62C429.18 1396.11 428.57 1395.48 429.38 1395.06C429.66 1394.92 430.09 1394.97 430.15 1395.24C430.26 1395.75 430.68 1396.35 430.22 1396.73Z" fill="white"/>
                    <path d="M298.46 1285.54C298.05 1285.6 297.66 1285.68 297.57 1285.09C297.44 1284.29 298.07 1284.39 298.5 1284.28C298.9 1284.18 299.28 1284.14 299.38 1284.72C299.52 1285.53 298.87 1285.39 298.46 1285.53V1285.54Z" fill="white"/>
                    <path d="M316.12 1376.33C316.06 1377.3 315.21 1376.85 314.73 1377.04C314.51 1377.13 314.26 1376.71 314.29 1376.4C314.37 1375.49 315.18 1375.82 315.68 1375.68C315.91 1375.61 316.14 1375.99 316.13 1376.33H316.12Z" fill="white"/>
                    <path d="M428.3 1271.54C428.41 1271.93 428.55 1272.35 428.02 1272.52C427.34 1272.74 427.18 1272.32 427.1 1271.76C427.05 1271.37 426.87 1270.96 427.4 1270.78C428.08 1270.56 428.27 1270.97 428.31 1271.53L428.3 1271.54Z" fill="white"/>
                    <path d="M475.36 1434.97C475.56 1435.64 475.39 1435.85 475.08 1435.94C474.39 1436.15 474.24 1435.7 474.19 1435.17C474.16 1434.89 473.99 1434.35 474.23 1434.39C474.72 1434.47 475.15 1434.85 475.36 1434.97Z" fill="white"/>
                    <path d="M266.37 1306.82C266.21 1306.78 265.95 1306.71 265.68 1306.64C266.01 1306.2 266.11 1305.52 266.82 1305.47C267.12 1305.45 267.34 1305.59 267.4 1305.96C267.53 1306.75 266.91 1306.64 266.36 1306.82H266.37Z" fill="white"/>
                    <path d="M474.76 1232.08C474.66 1231.83 474.83 1231.59 475.12 1231.49C475.5 1231.36 475.95 1231.33 475.98 1231.91C476 1232.31 476.51 1232.91 475.83 1233.07C475.58 1233.12 474.91 1232.75 474.77 1232.09L474.76 1232.08Z" fill="white"/>
                    <path d="M516.68 1415.93C516 1416.15 515.66 1415.53 515.17 1415.28C515.4 1415.13 515.62 1414.91 515.87 1414.86C516.29 1414.77 516.84 1414.44 517.03 1415.23C517.12 1415.59 516.97 1415.77 516.68 1415.94V1415.93Z" fill="white"/>
                    <path d="M441.85 1242.07C441.68 1241.69 441.75 1242.12 442.03 1241.84C442.18 1241.69 442.61 1241.6 442.78 1241.71C443.24 1242 443.91 1242.16 443.86 1242.92C443.86 1243 443.76 1243.16 443.71 1243.15C442.92 1243.08 442.49 1242.39 441.85 1242.07Z" fill="white"/>
                    <path d="M405.11 1285.66C404.67 1285.31 403.96 1285.15 404.07 1284.41C404.1 1284.23 404.5 1283.99 404.73 1283.99C405.46 1283.99 405.25 1284.66 405.4 1285.07C405.5 1285.35 405.47 1285.6 405.11 1285.66Z" fill="white"/>
                    <path d="M365.49 1375.21C365.29 1375.25 365.09 1375.34 364.9 1375.31C364.78 1375.29 364.58 1375.08 364.6 1374.99C364.66 1374.66 366.5 1374.28 366.76 1374.54C366.82 1374.6 366.74 1374.94 366.67 1374.96C366.29 1375.08 365.89 1375.13 365.5 1375.21H365.49Z" fill="white"/>
                    <path d="M338.07 1211.67C337.46 1211.83 337.3 1211.48 337.26 1211.06C337.24 1210.75 337.38 1210.54 337.75 1210.47C338.3 1210.37 338.46 1210.68 338.49 1211.1C338.51 1211.39 338.41 1211.7 338.07 1211.66V1211.67Z" fill="white"/>
                    <path d="M500.66 1427.8C500.33 1427.77 500.12 1427.67 500.05 1427.3C499.95 1426.75 500.26 1426.59 500.68 1426.56C500.99 1426.53 501.2 1426.68 501.27 1427.05C501.38 1427.61 501.05 1427.72 500.65 1427.81L500.66 1427.8Z" fill="white"/>
                    <path d="M276.55 1438.82C276.22 1438.79 276.01 1438.69 275.94 1438.32C275.84 1437.77 276.15 1437.61 276.57 1437.58C276.88 1437.55 277.09 1437.7 277.16 1438.07C277.27 1438.63 276.94 1438.74 276.54 1438.83L276.55 1438.82Z" fill="white"/>
                    <path d="M242.39 1327.83C242.32 1327.46 242.3 1327.11 242.86 1327C243.23 1326.92 243.44 1327.07 243.54 1327.36C243.67 1327.76 243.64 1328.12 243.09 1328.23C242.72 1328.3 242.55 1328.16 242.39 1327.84V1327.83Z" fill="white"/>
                    <path d="M303.66 1468.4C303.32 1468.54 303.1 1468.34 303.01 1468.05C302.88 1467.65 302.91 1467.3 303.46 1467.18C303.83 1467.1 304.04 1467.25 304.14 1467.54C304.27 1467.94 304.24 1468.32 303.67 1468.4H303.66Z" fill="white"/>
                    <path d="M414.92 1419.72C414.59 1419.69 414.38 1419.59 414.31 1419.22C414.21 1418.67 414.52 1418.51 414.94 1418.48C415.25 1418.45 415.46 1418.6 415.53 1418.97C415.64 1419.53 415.31 1419.64 414.91 1419.73L414.92 1419.72Z" fill="white"/>
                    <path d="M405.7 1210.86C405.32 1210.82 405.11 1210.73 405.04 1210.35C404.94 1209.8 405.25 1209.64 405.67 1209.61C405.98 1209.58 406.18 1209.73 406.26 1210.1C406.38 1210.67 406.01 1210.75 405.7 1210.86Z" fill="white"/>
                    <path d="M415.3 1233.92C415.15 1233.36 415.5 1233.19 415.92 1233.16C416.23 1233.14 416.44 1233.28 416.51 1233.65C416.61 1234.2 416.3 1234.36 415.88 1234.39C415.57 1234.42 415.3 1234.29 415.31 1233.92H415.3Z" fill="white"/>
                    <path d="M355.03 1475.13C354.64 1475.19 354.24 1475.26 354.18 1474.69C354.16 1474.52 354.23 1474.33 354.26 1474.15C354.65 1474.34 355.04 1474.54 355.43 1474.73C355.3 1474.86 355.17 1474.99 355.03 1475.12V1475.13Z" fill="white"/>
                    <path d="M482.1 1413.89C481.75 1413.67 481.42 1413.46 481.1 1413.25C481.33 1413.1 481.54 1412.86 481.79 1412.83C482.32 1412.76 482.38 1413.21 482.39 1413.6C482.39 1413.7 482.19 1413.81 482.1 1413.9V1413.89Z" fill="white"/>
                    <path d="M314.44 1367.85C314.48 1367.62 314.48 1367.3 314.54 1367.28C315.06 1367.16 315.3 1367.68 315.7 1367.85C315.57 1367.98 315.46 1368.17 315.3 1368.22C314.9 1368.35 314.54 1368.31 314.44 1367.84V1367.85Z" fill="white"/>
                    <path d="M218.4 1359.16C218.26 1359.1 218.03 1359.07 217.99 1358.97C217.86 1358.61 217.75 1358.17 218.27 1358.04C218.51 1357.98 218.8 1358.12 219.06 1358.18C218.84 1358.51 218.62 1358.83 218.4 1359.17V1359.16Z" fill="white"/>
                    <path d="M245.59 1372.63C245.72 1373.17 245.35 1373.3 244.95 1373.34C244.79 1373.35 244.61 1373.21 244.44 1373.14C244.73 1372.81 245.02 1372.49 245.31 1372.16C245.4 1372.32 245.5 1372.47 245.6 1372.63H245.59Z" fill="white"/>
                    <path d="M457.97 1408.02C457.89 1407.99 457.66 1407.97 457.62 1407.88C457.49 1407.52 457.37 1407.08 457.88 1406.93C458.11 1406.86 458.41 1407.01 458.68 1407.05C458.45 1407.36 458.22 1407.67 457.97 1408.01V1408.02Z" fill="white"/>
                    <path d="M418.87 1413.96C418.33 1414.09 418.2 1413.72 418.16 1413.32C418.15 1413.16 418.29 1412.98 418.36 1412.81C418.69 1413.1 419.01 1413.39 419.34 1413.68C419.18 1413.77 419.03 1413.87 418.87 1413.97V1413.96Z" fill="white"/>
                    <path d="M244.11 1422.76C243.93 1422.7 243.76 1422.63 243.59 1422.57C243.86 1422.23 244.13 1421.89 244.4 1421.55C244.5 1421.7 244.64 1421.84 244.69 1422C244.86 1422.52 244.52 1422.67 244.11 1422.75V1422.76Z" fill="white"/>
                    <path d="M398.6 1244.06C398.73 1244.6 398.36 1244.73 397.96 1244.77C397.8 1244.78 397.62 1244.64 397.45 1244.57C397.74 1244.24 398.03 1243.92 398.32 1243.59C398.41 1243.75 398.51 1243.9 398.61 1244.06H398.6Z" fill="white"/>
                    <path d="M388.14 1203.37C387.95 1203.54 387.83 1203.73 387.68 1203.77C387.27 1203.9 386.9 1203.85 386.85 1203.31C386.84 1203.14 386.91 1202.96 386.95 1202.78C387.34 1202.97 387.73 1203.16 388.14 1203.37Z" fill="white"/>
                    <path d="M532.82 1377.13C532.43 1377 532.5 1376.65 532.46 1376.38C532.45 1376.3 532.7 1376.08 532.79 1376.1C533.16 1376.19 533.1 1376.56 533.13 1376.83C533.14 1376.92 532.92 1377.04 532.82 1377.14V1377.13Z" fill="white"/>
                    <path d="M513.16 1395.17C512.86 1395.33 512.63 1395.5 512.38 1395.56C512.23 1395.59 512.04 1395.44 511.87 1395.37C512.1 1395.22 512.31 1395.02 512.56 1394.94C512.7 1394.9 512.9 1395.06 513.16 1395.17Z" fill="white"/>
                    <path d="M288.43 1246.9C288.04 1246.77 288.11 1246.42 288.07 1246.15C288.06 1246.07 288.31 1245.85 288.4 1245.87C288.77 1245.96 288.71 1246.33 288.74 1246.6C288.75 1246.69 288.53 1246.81 288.43 1246.91V1246.9Z" fill="white"/>
                    <path d="M233.22 1346.44C233.06 1346.14 232.89 1345.91 232.83 1345.66C232.8 1345.51 232.95 1345.32 233.02 1345.15C233.17 1345.38 233.37 1345.59 233.45 1345.84C233.49 1345.98 233.33 1346.18 233.22 1346.44Z" fill="white"/>
                    <path d="M234.24 1349.57C234.22 1349.41 234.15 1349.14 234.17 1348.88C234.2 1348.57 234.42 1348.53 234.55 1348.8C234.66 1349.03 234.72 1349.32 234.7 1349.58C234.68 1349.88 234.49 1349.91 234.23 1349.56L234.24 1349.57Z" fill="white"/>
                    <path d="M270.48 1242.42C270.44 1242.6 270.45 1242.9 270.36 1242.93C270.02 1243.06 269.89 1242.81 269.88 1242.51C269.88 1242.33 269.9 1242.03 269.99 1242C270.33 1241.87 270.45 1242.12 270.48 1242.42Z" fill="white"/>
                    <path d="M494.54 1424.64C494.36 1424.6 494.06 1424.61 494.03 1424.52C493.9 1424.18 494.15 1424.05 494.45 1424.04C494.63 1424.04 494.93 1424.06 494.96 1424.15C495.09 1424.49 494.84 1424.61 494.54 1424.64Z" fill="white"/>
                    <path d="M254.8 1425.38C254.5 1425.54 254.27 1425.71 254.02 1425.77C253.87 1425.8 253.68 1425.65 253.51 1425.58C253.74 1425.43 253.95 1425.23 254.2 1425.15C254.34 1425.11 254.54 1425.27 254.8 1425.38Z" fill="white"/>
                    <path d="M467.54 1262.86C467.42 1262.81 467.24 1262.74 467.07 1262.67C467.3 1262.52 467.51 1262.32 467.76 1262.24C467.9 1262.2 468.1 1262.36 468.27 1262.43C468.12 1262.71 467.91 1262.87 467.54 1262.86Z" fill="white"/>
                    <path d="M266.14 1470.8C266.06 1470.51 266.07 1470.24 266.43 1470.23C266.53 1470.23 266.66 1470.5 266.72 1470.66C266.83 1470.94 266.8 1471.22 266.43 1471.23C266.33 1471.23 266.23 1470.95 266.13 1470.8H266.14Z" fill="white"/>
                    <path d="M426.69 1269.69C426.61 1269.4 426.62 1269.13 426.98 1269.12C427.08 1269.12 427.21 1269.39 427.27 1269.55C427.38 1269.83 427.35 1270.11 426.98 1270.12C426.88 1270.12 426.78 1269.84 426.68 1269.69H426.69Z" fill="white"/>
                    <path d="M419.46 1280.46C419.38 1280.17 419.39 1279.9 419.75 1279.89C419.85 1279.89 419.98 1280.16 420.04 1280.32C420.15 1280.6 420.12 1280.88 419.75 1280.89C419.65 1280.89 419.55 1280.61 419.45 1280.46H419.46Z" fill="white"/>
                    <path d="M398.72 1323.54C398.42 1323.7 398.19 1323.87 397.94 1323.93C397.79 1323.96 397.6 1323.81 397.43 1323.74C397.66 1323.59 397.87 1323.39 398.12 1323.31C398.26 1323.27 398.46 1323.43 398.72 1323.54Z" fill="white"/>
                    <path d="M468.17 1410.55C467.95 1410.49 467.69 1410.41 467.43 1410.33C467.56 1410.2 467.69 1409.97 467.83 1409.96C468.09 1409.94 468.36 1410.04 468.63 1410.09C468.5 1410.22 468.37 1410.35 468.17 1410.54V1410.55Z" fill="white"/>
                    <path d="M371.12 1403.37C371 1403.32 370.82 1403.25 370.65 1403.18C370.88 1403.03 371.09 1402.83 371.34 1402.75C371.48 1402.71 371.68 1402.87 371.85 1402.94C371.7 1403.22 371.49 1403.38 371.12 1403.37Z" fill="white"/>
                    <path d="M372.63 1450.84C372.33 1451 372.1 1451.17 371.85 1451.23C371.7 1451.26 371.51 1451.11 371.34 1451.04C371.57 1450.89 371.78 1450.69 372.03 1450.61C372.17 1450.57 372.37 1450.73 372.63 1450.84Z" fill="white"/>
                    <path d="M334.98 1472.22C334.9 1471.93 334.91 1471.66 335.27 1471.65C335.37 1471.65 335.5 1471.92 335.56 1472.08C335.67 1472.36 335.64 1472.64 335.27 1472.65C335.17 1472.65 335.07 1472.37 334.97 1472.22H334.98Z" fill="white"/>
                    <path d="M302.57 1259.8C302.53 1259.98 302.54 1260.28 302.45 1260.31C302.11 1260.44 301.98 1260.19 301.97 1259.89C301.97 1259.71 301.99 1259.41 302.08 1259.38C302.42 1259.25 302.54 1259.5 302.57 1259.8Z" fill="white"/>
                    <path d="M337.16 1353.35C336.98 1353.31 336.68 1353.32 336.65 1353.23C336.52 1352.89 336.77 1352.76 337.07 1352.75C337.25 1352.75 337.55 1352.77 337.58 1352.86C337.71 1353.2 337.46 1353.32 337.16 1353.35Z" fill="white"/>
                    <path d="M321.74 1357.31C321.62 1357.4 321.51 1357.52 321.37 1357.58C321.32 1357.6 321.22 1357.48 321.14 1357.43C321.26 1357.34 321.37 1357.22 321.51 1357.16C321.56 1357.14 321.66 1357.26 321.74 1357.31Z" fill="white"/>
                    <path d="M234.67 1428.04C234.55 1428.13 234.44 1428.25 234.3 1428.31C234.25 1428.33 234.15 1428.21 234.07 1428.16C234.19 1428.07 234.3 1427.95 234.44 1427.89C234.49 1427.87 234.59 1427.99 234.67 1428.04Z" fill="white"/>
                    <path d="M418.59 1292.99C418.45 1292.95 418.31 1292.9 418.16 1292.85C418.21 1292.77 418.27 1292.62 418.32 1292.62C418.47 1292.62 418.61 1292.69 418.76 1292.73C418.71 1292.81 418.65 1292.89 418.59 1292.99Z" fill="white"/>
                    <path d="M423.58 1245.44C423.68 1245.5 423.76 1245.56 423.84 1245.61C423.72 1245.7 423.61 1245.82 423.47 1245.88C423.42 1245.9 423.32 1245.78 423.24 1245.73C423.36 1245.63 423.47 1245.53 423.58 1245.44Z" fill="white"/>
                    <path d="M442.24 1332.47C442.28 1332.33 442.33 1332.19 442.38 1332.04C442.46 1332.09 442.61 1332.15 442.61 1332.2C442.61 1332.35 442.54 1332.49 442.5 1332.64C442.42 1332.59 442.34 1332.53 442.24 1332.47Z" fill="white"/>
                    <path d="M414.54 1371.17C414.45 1371.05 414.33 1370.94 414.27 1370.8C414.25 1370.75 414.37 1370.65 414.42 1370.57C414.51 1370.69 414.63 1370.8 414.69 1370.94C414.71 1370.99 414.59 1371.09 414.54 1371.17Z" fill="white"/>
                    <path d="M419.13 1420.99C419.19 1420.89 419.25 1420.81 419.3 1420.73C419.39 1420.85 419.51 1420.96 419.57 1421.1C419.59 1421.15 419.47 1421.25 419.42 1421.33C419.32 1421.21 419.22 1421.1 419.13 1420.99Z" fill="white"/>
                    <path d="M480.27 1429.22C480.13 1429.18 479.99 1429.13 479.84 1429.08C479.89 1429 479.95 1428.85 480 1428.85C480.15 1428.85 480.29 1428.92 480.44 1428.96C480.39 1429.04 480.33 1429.12 480.27 1429.22Z" fill="white"/>
                    <path d="M440.3 1312.41C440.2 1312.35 440.12 1312.29 440.04 1312.24C440.16 1312.15 440.27 1312.03 440.41 1311.97C440.46 1311.95 440.56 1312.07 440.64 1312.12C440.52 1312.22 440.41 1312.32 440.3 1312.41Z" fill="white"/>
                    <path d="M383.24 1363.7C383.34 1363.76 383.42 1363.82 383.5 1363.87C383.38 1363.96 383.27 1364.08 383.13 1364.14C383.08 1364.16 382.98 1364.04 382.9 1363.99C383.02 1363.89 383.13 1363.79 383.24 1363.7Z" fill="white"/>
                    <path d="M301.86 1333.39C301.72 1333.35 301.58 1333.3 301.43 1333.25C301.48 1333.17 301.54 1333.02 301.59 1333.02C301.74 1333.02 301.88 1333.09 302.03 1333.13C301.98 1333.21 301.92 1333.29 301.86 1333.39Z" fill="white"/>
                    <path d="M487.63 1448.53C487.77 1448.57 487.91 1448.62 488.06 1448.67C488.01 1448.75 487.95 1448.9 487.9 1448.9C487.75 1448.9 487.61 1448.83 487.46 1448.79C487.51 1448.71 487.57 1448.63 487.63 1448.53Z" fill="white"/>
                    <path d="M338.98 1427.08C338.84 1427.04 338.7 1426.99 338.55 1426.94C338.6 1426.86 338.66 1426.71 338.71 1426.71C338.86 1426.71 339 1426.78 339.15 1426.82C339.1 1426.9 339.04 1426.98 338.98 1427.08Z" fill="white"/>
                    <path d="M462.76 1357.55C462.82 1357.45 462.88 1357.37 462.93 1357.29C463.02 1357.41 463.14 1357.52 463.2 1357.66C463.22 1357.71 463.1 1357.81 463.05 1357.89C462.95 1357.77 462.85 1357.66 462.76 1357.55Z" fill="white"/>
                    <path d="M467.99 1369.93C467.85 1369.89 467.71 1369.84 467.56 1369.79C467.61 1369.71 467.67 1369.56 467.72 1369.56C467.87 1369.56 468.01 1369.63 468.16 1369.67L467.99 1369.93Z" fill="white"/>
                    <path d="M348.51 1357.57C348.47 1357.71 348.42 1357.85 348.37 1358C348.29 1357.95 348.14 1357.89 348.14 1357.84C348.14 1357.69 348.21 1357.55 348.25 1357.4C348.33 1357.45 348.41 1357.51 348.51 1357.57Z" fill="white"/>
                    <path d="M261.54 1244.48C261.42 1244.57 261.31 1244.69 261.17 1244.75C261.12 1244.77 261.02 1244.65 260.94 1244.6C261.06 1244.51 261.17 1244.39 261.31 1244.33C261.36 1244.31 261.46 1244.43 261.54 1244.48Z" fill="white"/>
                    <path d="M147.33 1293.34C148.33 1291.9 149.67 1292.13 150.94 1292.37C151.4 1292.46 152.1 1292.7 152.35 1292.66C153.98 1292.39 153.44 1294.27 154.41 1294.56C153.99 1295.46 153.78 1296.67 153.07 1297.16C152.4 1297.62 151.51 1298.13 150.61 1298.26C149.76 1298.39 148.62 1298.95 148.48 1297.29C148.46 1297.04 147.95 1296.86 147.69 1296.61C146.54 1295.53 146.46 1294.41 147.33 1293.34Z" fill="white"/>
                    <path d="M173.54 1432.45C172.75 1431.78 172.7 1430.68 172.94 1430.41C173.86 1429.34 173.22 1428.09 173.61 1426.97C173.89 1426.18 174.18 1425.75 174.99 1425.72C176.32 1425.67 176.86 1425.97 177.09 1426.91C177.19 1427.32 177.34 1427.7 177.5 1428.09C178.19 1429.78 176.39 1433 174.32 1432.69C173.95 1432.64 173.64 1432.6 173.54 1432.46V1432.45Z" fill="white"/>
                    <path d="M215.17 1358.92C215.11 1358.01 213.97 1357.33 214.69 1356.29C214.79 1356.14 214.98 1356.03 215.01 1355.87C215.16 1355.22 215.08 1354.46 215.86 1354.13C216.47 1353.87 217.04 1354.21 217.6 1354.34C218.73 1354.59 219.58 1355.99 219.44 1357.25C219.33 1358.23 218.8 1358.83 217.87 1359.2C216.9 1359.59 216.07 1359.06 215.16 1358.92H215.17Z" fill="white"/>
                    <path d="M338.22 1384.5C339.42 1385.49 339.61 1386.37 339 1387.33C338.67 1387.85 338.2 1388.29 337.84 1388.8C337.49 1389.28 337.18 1389.26 336.77 1388.88C336.31 1388.46 335.73 1388.15 335.33 1387.68C334.65 1386.89 334.57 1385.91 334.82 1384.93C334.87 1384.73 335.14 1384.46 335.33 1384.44C336.03 1384.38 336.64 1384.09 337.28 1383.9C337.59 1383.81 338.12 1384.44 338.21 1384.51L338.22 1384.5Z" fill="white"/>
                    <path d="M351.65 1370.47C351.09 1370.04 350.97 1369.23 351.38 1368.66C351.52 1368.47 353.88 1367.54 354.09 1367.6C354.55 1367.73 355.07 1369.28 354.98 1369.28C353.86 1369.17 354.5 1370.39 353.91 1370.62C353.34 1370.84 352.01 1370.76 351.66 1370.48L351.65 1370.47Z" fill="white"/>
                    <path d="M208.36 1300.88C208.12 1301.16 207.83 1301.67 207.55 1301.45C207.03 1301.04 206.28 1300.82 206.19 1299.95C206.14 1299.47 206.49 1299.13 206.45 1298.74C206.39 1298.1 206.54 1297.79 207.24 1297.68C207.99 1297.56 208.56 1297.9 208.7 1298.47C208.9 1299.27 209.23 1300.22 208.37 1300.9L208.36 1300.88Z" fill="white"/>
                    <path d="M213.12 1393.9C213.53 1394.28 214.28 1394.52 213.58 1395.32C213.42 1395.5 213.64 1396 213.66 1396.35C213.68 1396.75 213.66 1397.16 213.66 1397.56C213.16 1397.23 212.44 1397.01 212.21 1396.54C211.91 1395.94 212.03 1395.15 211.92 1394.45C211.86 1394.07 211.88 1393.7 212.22 1393.52C212.59 1393.32 212.85 1393.68 213.12 1393.89V1393.9Z" fill="white"/>
                    <path d="M296.14 1277.07C296.28 1277.18 296.43 1277.28 296.54 1277.4C296.81 1277.69 297.42 1277.81 297.15 1278.37C296.87 1278.95 296.38 1279.25 295.69 1279.23C295.1 1279.22 295.02 1278.88 294.93 1278.42C294.87 1278.12 294.56 1277.87 294.5 1277.57C294.42 1277.17 294.23 1276.69 294.66 1276.37C295.17 1275.98 295.4 1276.55 295.74 1276.75C295.89 1276.84 296.01 1276.97 296.15 1277.08L296.14 1277.07Z" fill="white"/>
                    <path d="M354 1379.93C354.76 1379.63 355.47 1379.08 356.35 1379.16C356.55 1379.18 356.82 1379.38 356.82 1379.72C356.82 1380.14 357.1 1380.68 356.63 1380.93C356.05 1381.24 355.4 1381.43 354.76 1381.6C354 1381.8 354.48 1380.99 354.15 1380.79C353.82 1380.59 353.66 1380.3 354 1379.93Z" fill="white"/>
                    <path d="M177.52 1368.52C177.95 1368.76 178.07 1369.07 177.68 1369.52C177.06 1370.21 176.35 1370.3 175.69 1369.82C175.1 1369.39 175.07 1368.78 175.29 1368.23C175.54 1367.6 176.62 1367.76 177.52 1368.52Z" fill="white"/>
                    <path d="M100.58 1392.01C100.79 1392.18 101.03 1392.32 101.2 1392.52C101.58 1393 101.28 1393.68 100.67 1393.75C100.32 1393.79 99.9501 1393.89 99.6101 1393.84C99.1701 1393.78 98.7301 1393.47 98.6501 1393.07C98.5501 1392.61 99.0601 1392.17 98.8901 1391.64C99.3901 1391.66 99.9401 1391.31 100.38 1391.84C100.43 1391.91 100.52 1391.95 100.59 1392.01H100.58Z" fill="white"/>
                    <path d="M311.34 1354.43C311.31 1354.12 311.29 1353.85 311.25 1353.59C311.14 1352.93 312.11 1352.71 311.86 1351.98C311.73 1351.59 311.99 1351.28 312.39 1351.28C312.78 1351.28 312.96 1351.57 312.99 1351.97C313.03 1352.5 313.13 1353.02 313.14 1353.55C313.14 1353.8 313.5 1354.24 312.86 1354.28C312.35 1354.32 311.84 1354.38 311.33 1354.44L311.34 1354.43Z" fill="white"/>
                    <path d="M339.89 1461.43C339.47 1461.81 338.45 1461.67 338.04 1461.17C337.88 1460.97 338.04 1459.33 338.3 1459.14C338.95 1458.68 339.22 1459.38 339.56 1459.66C340.19 1460.18 340.3 1461.06 339.89 1461.43Z" fill="white"/>
                    <path d="M265.84 1452.91C266.12 1452.44 266.42 1452.25 266.88 1452.66C267.44 1453.16 267.69 1454.03 267.35 1454.44C267.01 1454.85 266.09 1454.8 265.51 1454.32C264.65 1453.61 265.69 1453.34 265.84 1452.91Z" fill="white"/>
                    <path d="M259.7 1455.15C259.54 1454.69 259.33 1454.19 259.65 1453.73C260 1453.23 261.21 1453.41 261.67 1453.98C261.97 1454.36 261.73 1454.61 261.49 1454.85C260.94 1455.42 260.09 1455.56 259.7 1455.15Z" fill="white"/>
                    <path d="M304.3 1386.1C304.57 1386.32 304.7 1386.45 304.84 1386.54C305.4 1386.88 305.17 1387.47 305.29 1387.95C304.8 1387.93 304.27 1388.27 303.82 1387.8C303.7 1387.67 303.56 1387.57 303.41 1387.47C302.92 1387.14 302.68 1386.84 303.18 1386.24C303.66 1385.67 304 1385.72 304.29 1386.1H304.3Z" fill="white"/>
                    <path d="M293.07 1416.44C292.41 1416.09 292.38 1415.47 292.58 1414.91C292.73 1414.49 294.2 1414.38 294.34 1414.72C294.61 1415.38 294.46 1415.97 293.95 1416.5C293.59 1416.87 293.35 1416.66 293.07 1416.44Z" fill="white"/>
                    <path d="M236.96 1263C237.36 1262.65 237.86 1262.69 238.35 1262.8C238.64 1262.87 238.57 1264.34 238.27 1264.8C237.99 1265.22 237.7 1265.04 237.42 1264.8C236.86 1264.32 236.62 1263.44 236.96 1263Z" fill="white"/>
                    <path d="M328.96 1404.87C328.73 1405.02 328.42 1405.11 328.3 1404.82C328.01 1404.13 327.53 1403.51 327.49 1402.71C327.47 1402.37 327.64 1402.13 327.83 1402.06C328.01 1401.99 328.43 1402.12 328.5 1402.27C328.78 1402.88 328.97 1403.52 329.18 1404.16C329.27 1404.43 329.18 1404.68 328.96 1404.88V1404.87Z" fill="white"/>
                    <path d="M357.13 1412.27C356.9 1412.11 356.59 1411.92 356.81 1411.5C357 1411.14 357.37 1411.2 357.69 1411.11C358.34 1410.93 358.67 1411.41 358.99 1411.76C359.18 1411.97 359.24 1412.62 359.11 1412.72C358.83 1412.93 358.35 1413.01 357.99 1412.94C357.69 1412.88 357.44 1412.54 357.12 1412.28L357.13 1412.27Z" fill="white"/>
                    <path d="M278.75 1341.87C278.71 1341.71 278.6 1341.52 278.65 1341.41C278.95 1340.78 280.46 1340.44 280.95 1340.9C281.12 1341.06 281.32 1341.34 281.28 1341.51C281.24 1341.69 280.95 1341.89 280.73 1341.95C279.77 1342.19 279.1 1342.14 278.75 1341.87Z" fill="white"/>
                    <path d="M182.71 1313.75C182.54 1313.95 182.41 1314.2 182.21 1314.36C181.69 1314.78 181.46 1314.21 181.13 1314C180.7 1313.73 180.89 1313.43 181.13 1313.14C181.35 1312.88 181.51 1312.58 181.93 1312.56C182.36 1312.55 182.85 1312.38 183.09 1312.85C183.28 1313.22 182.91 1313.47 182.71 1313.74V1313.75Z" fill="white"/>
                    <path d="M326.01 1404.36C326.36 1404.79 326.09 1405.04 325.86 1405.28C325.29 1405.85 324.46 1405.98 324.08 1405.56C323.76 1405.21 324.04 1404.93 324.28 1404.69C324.85 1404.12 325.67 1404 326.02 1404.36H326.01Z" fill="white"/>
                    <path d="M196.39 1280.01C196.11 1279.77 195.82 1279.51 196.19 1279.18C196.68 1278.75 197.3 1278.62 197.77 1279.1C198.01 1279.35 197.96 1279.89 198.04 1280.3C197.46 1280.33 196.84 1280.61 196.39 1280.01Z" fill="white"/>
                    <path d="M141.04 1410.46C141.48 1409.99 142.47 1409.94 142.77 1410.28C143.08 1410.63 142.79 1410.87 142.59 1411.15C142.19 1411.7 141.63 1411.82 141.12 1411.52C140.97 1411.43 140.83 1410.73 141.05 1410.46H141.04Z" fill="white"/>
                    <path d="M288.09 1435.25C287.74 1434.97 287.39 1434.65 287.79 1434.38C288.28 1434.05 288.89 1433.9 289.45 1433.67C289.56 1434.27 289.61 1434.82 289.08 1435.33C288.58 1435.81 288.34 1435.39 288.08 1435.24L288.09 1435.25Z" fill="white"/>
                    <path d="M160.03 1475.44C159.41 1475.04 159.6 1474.74 159.89 1474.32C160.34 1473.67 160.68 1473.9 161.15 1474.27C161.63 1474.65 161.49 1474.92 161.18 1475.34C160.73 1475.96 160.36 1475.88 160.03 1475.44Z" fill="white"/>
                    <path d="M298.96 1431.65C299.32 1431.34 299.52 1430.53 300.14 1431.19C300.48 1431.55 300.56 1432.13 300.26 1432.61C299.76 1433.39 299.15 1432.56 298.69 1432.5C298.11 1432.42 298.94 1431.98 298.97 1431.65H298.96Z" fill="white"/>
                    <path d="M374.62 1408.45C374.42 1408.28 374.18 1408.15 374.02 1407.95C373.72 1407.59 373.99 1407.33 374.23 1407.08C374.4 1406.9 374.48 1406.58 374.81 1406.64C375.19 1406.71 375.39 1406.94 375.4 1407.34C375.4 1407.51 375.44 1407.69 375.45 1407.86C375.47 1408.19 375.63 1408.71 375.48 1408.82C375.16 1409.06 374.9 1408.63 374.62 1408.45Z" fill="white"/>
                    <path d="M128.59 1360.92C128.06 1361.28 127.82 1360.76 127.51 1360.53C126.94 1360.1 127.46 1359.79 127.69 1359.48C127.9 1359.19 128.16 1358.93 128.52 1359.29C128.89 1359.66 128.93 1360.61 128.59 1360.92Z" fill="white"/>
                    <path d="M235.04 1454.18C235.35 1453.83 235.59 1453.55 235.95 1453.91C236.32 1454.28 236.37 1455.24 236.02 1455.54C235.66 1455.84 235.43 1455.51 235.14 1455.34C234.44 1454.92 234.72 1454.52 235.04 1454.18Z" fill="white"/>
                    <path d="M334.39 1424.71C334.24 1424.82 334.01 1425.1 333.85 1425.07C333.43 1424.97 333.58 1424.5 333.62 1424.26C333.68 1423.9 332.97 1423.21 333.99 1423.17C334.33 1423.16 334.69 1423.04 334.93 1423.37C335.4 1424.04 334.54 1424.2 334.39 1424.71Z" fill="white"/>
                    <path d="M288.32 1294.25C288.21 1293.85 287.79 1293.2 288.32 1293.07C288.77 1292.96 289.02 1292.25 289.55 1292.61C289.87 1292.82 289.46 1294.17 289.01 1294.42C288.78 1294.55 288.52 1294.49 288.32 1294.26V1294.25Z" fill="white"/>
                    <path d="M169.01 1461.37C169.01 1461.37 168.53 1461.32 168.12 1461.21C167.94 1461.16 168.04 1459.94 168.23 1459.78C168.75 1459.37 168.97 1459.92 169.3 1460.12C169.69 1460.37 169.6 1460.67 169.01 1461.36V1461.37Z" fill="white"/>
                    <path d="M147.41 1438.25C147.05 1437.72 147.58 1437.5 147.78 1437.18C148.04 1436.77 148.43 1436.87 148.6 1437.18C148.79 1437.53 148.83 1437.99 148.85 1438.4C148.85 1438.55 147.56 1438.44 147.41 1438.26V1438.25Z" fill="white"/>
                    <path d="M154.01 1274.9C154.23 1274.48 154.51 1274.47 154.71 1274.63C155.24 1275.07 155.25 1275.63 154.97 1276.22C154.83 1276.52 154.54 1276.52 154.33 1276.37C153.77 1275.94 153.83 1275.36 154.01 1274.9Z" fill="white"/>
                    <path d="M298.11 1335.33C297.69 1335.72 297.14 1335.82 296.79 1335.53C296.62 1335.39 296.48 1334.74 296.82 1334.34C297.28 1333.8 297.56 1334.3 297.87 1334.52C298.17 1334.72 298.39 1334.97 298.11 1335.33Z" fill="white"/>
                    <path d="M112.86 1352.99C112.99 1352.77 114.2 1352.43 114.23 1352.58C114.29 1352.99 114.35 1353.44 114.23 1353.82C114.12 1354.17 113.75 1354.29 113.42 1353.96C113.16 1353.69 112.57 1353.58 112.86 1352.99Z" fill="white"/>
                    <path d="M303.18 1337.4C302.91 1336.98 302.73 1336.44 303.19 1335.89C303.36 1335.69 303.64 1335.63 303.85 1335.89C304.24 1336.4 304.35 1336.96 303.93 1337.5C303.77 1337.7 303.49 1337.75 303.19 1337.39L303.18 1337.4Z" fill="white"/>
                    <path d="M350.2 1300.09C349.8 1299.8 349.88 1299.42 350.19 1299.26C350.54 1299.07 351 1299.03 351.41 1299.01C351.56 1299.01 351.45 1300.3 351.27 1300.45C351.01 1300.67 350.6 1300.53 350.21 1300.09H350.2Z" fill="white"/>
                    <path d="M294.76 1430.27C294.47 1430.03 294.19 1429.79 294.54 1429.43C294.92 1429.03 295.48 1428.95 295.83 1429.24C296.01 1429.38 296.14 1430.02 295.8 1430.43C295.34 1430.97 295.07 1430.45 294.76 1430.27Z" fill="white"/>
                    <path d="M363.95 1381.04C363.75 1380.58 363.62 1380.13 363.91 1379.67C364.08 1379.4 364.37 1379.36 364.55 1379.55C364.85 1379.86 365.63 1379.97 365.15 1380.69C364.85 1381.13 364.35 1380.87 363.95 1381.03V1381.04Z" fill="white"/>
                    <path d="M293.26 1417.98C293.22 1417.82 293.18 1417.67 293.14 1417.51C293.91 1417.41 294.7 1417.44 295.36 1416.91C295.39 1416.89 295.66 1417.09 295.75 1417.24C295.8 1417.32 295.69 1417.61 295.65 1417.61C294.94 1417.61 294.46 1418.39 293.71 1418.21C293.55 1418.17 293.41 1418.06 293.26 1417.98Z" fill="white"/>
                    <path d="M295.11 1414.23C295.47 1413.84 295.82 1414.5 296.11 1414.78C296.69 1415.35 295.92 1415.63 295.7 1416C295.64 1416.11 295.11 1416.31 295.28 1415.87C295.53 1415.23 294.19 1415.14 295.12 1414.22L295.11 1414.23Z" fill="white"/>
                    <path d="M160.12 1316.86C159.8 1317.42 159.21 1317.18 158.74 1317.31C158.7 1316.98 158.49 1316.52 158.64 1316.35C158.96 1315.98 159.47 1315.98 159.93 1316.22C160.21 1316.37 160.2 1316.6 160.12 1316.86Z" fill="white"/>
                    <path d="M175.2 1460.56C175.48 1460.3 175.64 1459.75 176.21 1460.18C176.62 1460.5 176.43 1460.78 176.19 1461.04C175.93 1461.33 175.75 1461.87 175.18 1461.43C174.77 1461.11 174.98 1460.85 175.2 1460.55V1460.56Z" fill="white"/>
                    <path d="M206.12 1262.18C205.85 1261.34 206.07 1260.81 206.56 1260.39C206.67 1260.29 206.86 1260.28 207.02 1260.24C207.04 1260.41 207.02 1260.6 207.08 1260.75C207.3 1261.36 206.88 1261.72 206.54 1262.09C206.43 1262.2 206.18 1262.17 206.13 1262.18H206.12Z" fill="white"/>
                    <path d="M154.55 1478.66C154.44 1478.58 154.6 1478.28 154.86 1478.01C155.13 1477.73 155.29 1477.17 155.87 1477.6C156.27 1477.9 156.11 1478.19 155.87 1478.46C155.61 1478.75 155.42 1479.29 154.56 1478.65L154.55 1478.66Z" fill="white"/>
                    <path d="M206.99 1456.48C207.1 1456.44 207.28 1456.32 207.35 1456.36C207.85 1456.69 208.33 1457.03 208.28 1457.77C208.26 1458.07 208.21 1458.24 207.91 1458.17C207.45 1458.05 206.82 1456.88 206.99 1456.48Z" fill="white"/>
                    <path d="M163.86 1361.6C163.35 1360.91 164.18 1360.73 164.4 1360.34C164.5 1360.16 164.9 1360.29 165.06 1360.52C165.52 1361.18 164.78 1361.42 164.53 1361.79C164.41 1361.96 164.05 1361.84 163.86 1361.61V1361.6Z" fill="white"/>
                    <path d="M252.89 1415.72C252.38 1415.03 253.21 1414.85 253.43 1414.46C253.53 1414.28 253.93 1414.41 254.09 1414.64C254.55 1415.3 253.81 1415.54 253.56 1415.91C253.44 1416.08 253.08 1415.96 252.89 1415.73V1415.72Z" fill="white"/>
                    <path d="M265 1296.16C264.89 1296.08 265.05 1295.78 265.31 1295.51C265.58 1295.23 265.74 1294.67 266.32 1295.1C266.72 1295.4 266.56 1295.69 266.32 1295.96C266.06 1296.25 265.87 1296.79 265.01 1296.15L265 1296.16Z" fill="white"/>
                    <path d="M300.58 1467.98C301.13 1468.08 301.7 1468.18 302.26 1468.3C302.29 1468.3 302.32 1468.48 302.28 1468.54C302.11 1468.87 301.83 1469 301.45 1469.02C301.02 1469.04 300.58 1469.13 300.4 1468.61C300.36 1468.49 300.48 1468.31 300.58 1467.97V1467.98Z" fill="white"/>
                    <path d="M316.43 1421.18C316.23 1421.48 316.02 1421.8 315.61 1421.54C315.07 1421.21 315.27 1420.86 315.61 1420.49C315.84 1420.23 316.02 1419.88 316.44 1420.14C316.97 1420.47 316.8 1420.84 316.44 1421.18H316.43Z" fill="white"/>
                    <path d="M321.66 1367.02C321.38 1366.61 321.29 1366.37 321.55 1366.17C322.06 1365.78 322.62 1365.67 323.16 1366.09C323.27 1366.17 323.27 1366.39 323.32 1366.55C322.75 1366.71 322.17 1366.88 321.66 1367.02Z" fill="white"/>
                    <path d="M246.95 1443.4C246.54 1443.15 246.32 1442.84 246.47 1442.68C246.78 1442.35 246.86 1441.59 247.6 1441.9C247.85 1442.01 248.06 1442.33 247.91 1442.52C247.63 1442.88 247.46 1443.5 246.95 1443.4Z" fill="white"/>
                    <path d="M247.72 1291.83C247.44 1291.59 247.18 1291.37 247.52 1290.97C247.99 1290.43 248.27 1290.91 248.59 1291.14C248.88 1291.35 249.13 1291.59 248.79 1291.98C248.32 1292.53 248.05 1292.01 247.72 1291.82V1291.83Z" fill="white"/>
                    <path d="M196.41 1354.92C195.72 1355.43 195.54 1354.6 195.15 1354.38C194.97 1354.28 195.1 1353.88 195.33 1353.72C195.99 1353.26 196.23 1354 196.6 1354.25C196.77 1354.37 196.65 1354.73 196.42 1354.92H196.41Z" fill="white"/>
                    <path d="M330.34 1371.55C330.14 1371.85 329.93 1372.17 329.52 1371.91C328.98 1371.58 329.18 1371.23 329.52 1370.86C329.75 1370.6 329.93 1370.25 330.35 1370.51C330.88 1370.84 330.71 1371.21 330.35 1371.55H330.34Z" fill="white"/>
                    <path d="M215.27 1282.17C215.21 1282.04 215.1 1281.82 215 1281.61C215.48 1281.58 216 1281.27 216.43 1281.72C216.62 1281.91 216.64 1282.14 216.43 1282.39C215.97 1282.92 215.7 1282.44 215.27 1282.17Z" fill="white"/>
                    <path d="M383.15 1380.66C383.26 1380.45 383.52 1380.43 383.75 1380.57C384.05 1380.76 384.32 1381.04 383.95 1381.38C383.69 1381.62 383.57 1382.31 383.08 1381.93C382.9 1381.79 382.78 1381.13 383.14 1380.66H383.15Z" fill="white"/>
                    <path d="M357.87 1364.08C358.03 1363.75 357.78 1364.04 358.13 1364.07C358.32 1364.08 358.62 1364.33 358.64 1364.5C358.71 1364.97 358.98 1365.52 358.43 1365.91C358.37 1365.95 358.21 1365.97 358.19 1365.94C357.79 1365.37 358.02 1364.69 357.87 1364.08Z" fill="white"/>
                    <path d="M307.74 1363.86C307.73 1363.36 307.44 1362.8 308 1362.45C308.13 1362.37 308.53 1362.5 308.66 1362.66C309.08 1363.15 308.5 1363.39 308.31 1363.72C308.18 1363.95 308 1364.07 307.75 1363.85L307.74 1363.86Z" fill="white"/>
                    <path d="M224.99 1387.6C224.85 1387.49 224.68 1387.4 224.59 1387.26C224.53 1387.17 224.57 1386.91 224.64 1386.87C224.9 1386.73 226.19 1387.75 226.16 1388.07C226.16 1388.14 225.88 1388.28 225.82 1388.24C225.53 1388.05 225.26 1387.81 224.99 1387.59V1387.6Z" fill="white"/>
                    <path d="M319.88 1276.92C319.43 1276.6 319.57 1276.29 319.83 1276.03C320.02 1275.84 320.25 1275.82 320.5 1276.03C320.88 1276.35 320.76 1276.63 320.5 1276.89C320.31 1277.07 320.05 1277.18 319.88 1276.92Z" fill="white"/>
                    <path d="M131.94 1363.44C131.77 1363.2 131.72 1363.01 131.94 1362.75C132.26 1362.37 132.54 1362.5 132.8 1362.75C132.99 1362.94 133.01 1363.17 132.8 1363.42C132.48 1363.81 132.22 1363.65 131.94 1363.43V1363.44Z" fill="white"/>
                    <path d="M187.58 1277.83C187.79 1277.57 188.02 1277.37 188.41 1277.68C188.67 1277.89 188.69 1278.11 188.55 1278.34C188.35 1278.65 188.1 1278.84 187.71 1278.53C187.45 1278.32 187.45 1278.12 187.58 1277.84V1277.83Z" fill="white"/>
                    <path d="M127.26 1398.4C126.98 1398.25 126.99 1397.99 127.13 1397.76C127.33 1397.45 127.58 1397.27 127.97 1397.57C128.23 1397.78 128.25 1398 128.11 1398.23C127.91 1398.54 127.64 1398.74 127.26 1398.4Z" fill="white"/>
                    <path d="M222.81 1446.04C222.64 1445.8 222.59 1445.61 222.81 1445.35C223.13 1444.97 223.41 1445.1 223.67 1445.35C223.86 1445.54 223.88 1445.77 223.67 1446.02C223.35 1446.41 223.09 1446.25 222.81 1446.03V1446.04Z" fill="white"/>
                    <path d="M358.55 1322.1C358.36 1321.82 358.31 1321.63 358.52 1321.37C358.84 1320.99 359.12 1321.12 359.38 1321.37C359.57 1321.56 359.59 1321.78 359.38 1322.04C359.06 1322.44 358.8 1322.24 358.55 1322.09V1322.1Z" fill="white"/>
                    <path d="M348.4 1341.57C348.7 1341.15 349 1341.29 349.26 1341.56C349.45 1341.75 349.47 1341.98 349.26 1342.23C348.94 1342.61 348.66 1342.48 348.4 1342.23C348.21 1342.04 348.15 1341.78 348.4 1341.58V1341.57Z" fill="white"/>
                    <path d="M151.67 1436.86C151.41 1436.63 151.14 1436.4 151.49 1436.04C151.6 1435.93 151.76 1435.87 151.89 1435.79C151.98 1436.16 152.07 1436.54 152.16 1436.91C152 1436.89 151.84 1436.88 151.67 1436.86Z" fill="white"/>
                    <path d="M201.18 1349.01C201.36 1348.9 201.57 1348.72 201.62 1348.76C202 1349.04 201.78 1349.5 201.89 1349.86C201.73 1349.84 201.54 1349.88 201.41 1349.8C201.1 1349.61 200.92 1349.34 201.18 1349.01Z" fill="white"/>
                    <path d="M152.91 1279.3C152.87 1279.17 152.76 1279 152.81 1278.92C152.98 1278.62 153.21 1278.31 153.6 1278.58C153.78 1278.71 153.84 1278.98 153.96 1279.19C153.62 1279.23 153.28 1279.26 152.92 1279.3H152.91Z" fill="white"/>
                    <path d="M159.16 1305.24C158.87 1305.63 158.57 1305.46 158.32 1305.21C158.22 1305.11 158.21 1304.91 158.17 1304.75C158.55 1304.76 158.94 1304.77 159.32 1304.78C159.27 1304.93 159.22 1305.08 159.16 1305.24Z" fill="white"/>
                    <path d="M254.97 1468.49C254.95 1468.42 254.82 1468.25 254.87 1468.18C255.04 1467.89 255.27 1467.56 255.65 1467.82C255.83 1467.94 255.9 1468.22 256.02 1468.43C255.68 1468.45 255.34 1468.47 254.97 1468.5V1468.49Z" fill="white"/>
                    <path d="M228.93 1445.46C228.54 1445.17 228.71 1444.87 228.96 1444.62C229.06 1444.52 229.26 1444.51 229.42 1444.47C229.41 1444.85 229.4 1445.24 229.39 1445.62C229.24 1445.57 229.09 1445.52 228.93 1445.46Z" fill="white"/>
                    <path d="M124.49 1332.49C124.43 1332.33 124.38 1332.18 124.32 1332.03C124.7 1332.03 125.08 1332.01 125.46 1332C125.41 1332.15 125.4 1332.32 125.32 1332.45C125.06 1332.85 124.77 1332.71 124.48 1332.48L124.49 1332.49Z" fill="white"/>
                    <path d="M332.14 1336.02C331.85 1336.41 331.55 1336.24 331.3 1335.99C331.2 1335.89 331.19 1335.69 331.15 1335.53C331.53 1335.54 331.92 1335.55 332.3 1335.56C332.25 1335.71 332.2 1335.86 332.14 1336.02Z" fill="white"/>
                    <path d="M353.7 1306.03C353.48 1306 353.29 1306.03 353.17 1305.94C352.86 1305.74 352.68 1305.46 353.02 1305.12C353.13 1305.01 353.29 1304.96 353.43 1304.89C353.52 1305.26 353.61 1305.63 353.7 1306.02V1306.03Z" fill="white"/>
                    <path d="M268.14 1263.28C268.01 1262.94 268.28 1262.79 268.44 1262.61C268.49 1262.56 268.78 1262.6 268.81 1262.67C268.96 1262.97 268.68 1263.14 268.51 1263.31C268.46 1263.37 268.25 1263.29 268.13 1263.27L268.14 1263.28Z" fill="white"/>
                    <path d="M169.85 1282.13C169.96 1281.86 170.02 1281.61 170.16 1281.42C170.24 1281.31 170.46 1281.31 170.61 1281.26C170.54 1281.49 170.51 1281.74 170.38 1281.94C170.31 1282.05 170.09 1282.05 169.84 1282.13H169.85Z" fill="white"/>
                    <path d="M168.32 1284.58C168.42 1284.48 168.56 1284.27 168.75 1284.15C168.97 1284 169.13 1284.12 169.02 1284.37C168.93 1284.58 168.76 1284.78 168.58 1284.92C168.36 1285.08 168.24 1284.97 168.33 1284.59L168.32 1284.58Z" fill="white"/>
                    <path d="M261.04 1248.65C260.9 1248.73 260.7 1248.9 260.63 1248.86C260.35 1248.7 260.44 1248.47 260.63 1248.3C260.75 1248.2 260.96 1248.04 261.04 1248.08C261.32 1248.24 261.22 1248.45 261.04 1248.64V1248.65Z" fill="white"/>
                    <path d="M128.75 1341.19C128.48 1341.08 128.23 1341.02 128.04 1340.88C127.93 1340.8 127.93 1340.58 127.88 1340.43C128.11 1340.5 128.36 1340.53 128.56 1340.66C128.67 1340.73 128.67 1340.95 128.75 1341.2V1341.19Z" fill="white"/>
                    <path d="M358.32 1393.14C358.28 1393.03 358.24 1392.87 358.19 1392.72C358.42 1392.79 358.67 1392.82 358.87 1392.95C358.98 1393.02 358.98 1393.24 359.03 1393.4C358.76 1393.46 358.53 1393.4 358.33 1393.15L358.32 1393.14Z" fill="white"/>
                    <path d="M104.49 1374.43C104.64 1374.21 104.83 1374.07 105.04 1374.31C105.1 1374.37 104.99 1374.62 104.91 1374.75C104.78 1374.98 104.58 1375.12 104.37 1374.88C104.31 1374.82 104.44 1374.59 104.49 1374.43Z" fill="white"/>
                    <path d="M330.69 1369.42C330.84 1369.2 331.03 1369.06 331.24 1369.3C331.3 1369.36 331.19 1369.61 331.11 1369.74C330.98 1369.97 330.78 1370.11 330.57 1369.87C330.51 1369.81 330.64 1369.58 330.69 1369.42Z" fill="white"/>
                    <path d="M319.34 1370.61C319.49 1370.39 319.68 1370.25 319.89 1370.49C319.95 1370.55 319.84 1370.8 319.76 1370.93C319.63 1371.16 319.43 1371.3 319.22 1371.06C319.16 1371 319.29 1370.77 319.34 1370.61Z" fill="white"/>
                    <path d="M278.58 1380.9C278.31 1380.79 278.06 1380.73 277.87 1380.59C277.76 1380.51 277.76 1380.29 277.71 1380.14C277.94 1380.21 278.19 1380.24 278.39 1380.37C278.5 1380.44 278.5 1380.66 278.58 1380.91V1380.9Z" fill="white"/>
                    <path d="M259.02 1476.8C258.94 1476.61 258.84 1476.4 258.75 1476.18C258.91 1476.2 259.13 1476.15 259.22 1476.24C259.38 1476.4 259.47 1476.65 259.58 1476.86C259.42 1476.84 259.26 1476.83 259.02 1476.81V1476.8Z" fill="white"/>
                    <path d="M209.16 1407.27C209.12 1407.16 209.08 1407 209.03 1406.85C209.26 1406.92 209.51 1406.95 209.71 1407.08C209.82 1407.15 209.82 1407.37 209.87 1407.53C209.6 1407.59 209.37 1407.53 209.17 1407.28L209.16 1407.27Z" fill="white"/>
                    <path d="M177.98 1435.04C177.71 1434.93 177.46 1434.87 177.27 1434.73C177.16 1434.65 177.16 1434.43 177.11 1434.28C177.34 1434.35 177.59 1434.38 177.79 1434.51C177.9 1434.58 177.9 1434.8 177.98 1435.05V1435.04Z" fill="white"/>
                    <path d="M129.43 1478.95C129.3 1478.61 129.57 1478.46 129.73 1478.28C129.78 1478.23 130.07 1478.27 130.1 1478.34C130.25 1478.64 129.97 1478.81 129.8 1478.98C129.75 1479.04 129.54 1478.96 129.42 1478.94L129.43 1478.95Z" fill="white"/>
                    <path d="M142.33 1421.69C142.48 1421.47 142.67 1421.33 142.88 1421.57C142.94 1421.63 142.83 1421.88 142.75 1422.01C142.62 1422.24 142.42 1422.38 142.21 1422.14C142.15 1422.08 142.28 1421.85 142.33 1421.69Z" fill="white"/>
                    <path d="M267.4 1280.1C267.26 1280.18 267.06 1280.35 266.99 1280.31C266.71 1280.15 266.8 1279.92 266.99 1279.75C267.11 1279.65 267.32 1279.49 267.4 1279.53C267.68 1279.69 267.58 1279.9 267.4 1280.09V1280.1Z" fill="white"/>
                    <path d="M223.77 1356.16C223.69 1356.02 223.52 1355.82 223.56 1355.75C223.72 1355.47 223.95 1355.56 224.12 1355.75C224.22 1355.87 224.38 1356.08 224.34 1356.16C224.18 1356.44 223.97 1356.34 223.78 1356.16H223.77Z" fill="white"/>
                    <path d="M212.41 1347.99C212.28 1347.96 212.14 1347.95 212.02 1347.89C211.98 1347.87 212 1347.73 211.99 1347.65C212.12 1347.68 212.26 1347.69 212.38 1347.75C212.42 1347.77 212.4 1347.91 212.41 1347.99Z" fill="white"/>
                    <path d="M115.61 1329.1C115.48 1329.07 115.34 1329.06 115.22 1329C115.18 1328.98 115.2 1328.84 115.19 1328.76C115.32 1328.79 115.46 1328.8 115.58 1328.86C115.62 1328.88 115.6 1329.02 115.61 1329.1Z" fill="white"/>
                    <path d="M310.39 1377.09C310.34 1376.97 310.29 1376.85 310.24 1376.72C310.32 1376.72 310.46 1376.67 310.48 1376.7C310.56 1376.8 310.6 1376.94 310.65 1377.06C310.57 1377.06 310.48 1377.08 310.38 1377.09H310.39Z" fill="white"/>
                    <path d="M345.29 1353.65C345.3 1353.75 345.31 1353.84 345.32 1353.92C345.19 1353.89 345.05 1353.88 344.93 1353.82C344.89 1353.8 344.91 1353.66 344.9 1353.58C345.03 1353.6 345.16 1353.63 345.29 1353.65Z" fill="white"/>
                    <path d="M297.09 1415.3C297.21 1415.25 297.33 1415.2 297.46 1415.15C297.46 1415.23 297.51 1415.37 297.48 1415.39C297.38 1415.47 297.24 1415.51 297.12 1415.56C297.12 1415.48 297.1 1415.39 297.09 1415.29V1415.3Z" fill="white"/>
                    <path d="M255.36 1418.42C255.39 1418.29 255.4 1418.15 255.46 1418.03C255.48 1417.99 255.62 1418.01 255.7 1418C255.67 1418.13 255.66 1418.27 255.6 1418.39C255.58 1418.43 255.44 1418.41 255.36 1418.42Z" fill="white"/>
                    <path d="M224.33 1449.59C224.43 1449.58 224.52 1449.57 224.6 1449.56C224.57 1449.69 224.56 1449.83 224.5 1449.95C224.48 1449.99 224.34 1449.97 224.26 1449.98C224.28 1449.85 224.31 1449.72 224.33 1449.59Z" fill="white"/>
                    <path d="M309.53 1402.68C309.52 1402.58 309.51 1402.49 309.5 1402.41C309.63 1402.44 309.77 1402.45 309.89 1402.51C309.93 1402.53 309.91 1402.67 309.92 1402.75C309.79 1402.73 309.66 1402.7 309.53 1402.68Z" fill="white"/>
                    <path d="M242.76 1393.09C242.77 1393.19 242.78 1393.28 242.79 1393.36C242.66 1393.33 242.52 1393.32 242.4 1393.26C242.36 1393.24 242.38 1393.1 242.37 1393.02C242.5 1393.04 242.63 1393.07 242.76 1393.09Z" fill="white"/>
                    <path d="M217.34 1321.09C217.29 1320.97 217.24 1320.85 217.19 1320.72C217.27 1320.72 217.41 1320.67 217.43 1320.7C217.51 1320.8 217.55 1320.94 217.6 1321.06C217.52 1321.06 217.43 1321.08 217.33 1321.09H217.34Z" fill="white"/>
                    <path d="M175.05 1398.94C175 1398.82 174.95 1398.7 174.9 1398.57C174.98 1398.57 175.12 1398.52 175.14 1398.55C175.22 1398.65 175.26 1398.79 175.31 1398.91C175.23 1398.91 175.14 1398.93 175.04 1398.94H175.05Z" fill="white"/>
                    <path d="M291.72 1443.28C291.82 1443.27 291.91 1443.26 291.99 1443.25C291.96 1443.38 291.95 1443.52 291.89 1443.64C291.87 1443.68 291.73 1443.66 291.65 1443.67C291.67 1443.54 291.7 1443.41 291.72 1443.28Z" fill="white"/>
                    <path d="M155.22 1458.76C155.09 1458.73 154.95 1458.72 154.83 1458.66C154.79 1458.64 154.81 1458.5 154.8 1458.42C154.93 1458.45 155.07 1458.46 155.19 1458.52C155.23 1458.54 155.21 1458.68 155.22 1458.76Z" fill="white"/>
                    <path d="M286.33 1453.78C286.28 1453.66 286.23 1453.54 286.18 1453.41C286.26 1453.41 286.4 1453.36 286.42 1453.39C286.5 1453.49 286.54 1453.63 286.59 1453.75L286.32 1453.78H286.33Z" fill="white"/>
                    <path d="M227.32 1366.2C227.2 1366.25 227.08 1366.3 226.95 1366.35C226.95 1366.27 226.9 1366.13 226.93 1366.11C227.03 1366.03 227.17 1365.99 227.29 1365.94C227.29 1366.02 227.31 1366.11 227.32 1366.21V1366.2Z" fill="white"/>
                    <path d="M254.61 1243.77C254.48 1243.74 254.34 1243.73 254.22 1243.67C254.18 1243.65 254.2 1243.51 254.19 1243.43C254.32 1243.46 254.46 1243.47 254.58 1243.53C254.62 1243.55 254.6 1243.69 254.61 1243.77Z" fill="white"/>
                    <path d="M131.13 1267.64C132.96 1267.82 133.44 1269.17 133.86 1270.45C134.02 1270.92 134.15 1271.68 134.32 1271.89C135.4 1273.25 133.4 1273.72 133.63 1274.75C132.59 1274.82 131.38 1275.25 130.57 1274.85C129.8 1274.47 128.89 1273.91 128.31 1273.16C127.76 1272.44 126.66 1271.69 128.11 1270.71C128.32 1270.57 128.24 1270 128.33 1269.64C128.73 1268.04 129.71 1267.39 131.13 1267.64Z" fill="white"/>
                    <path d="M17.18 1362.56C17.38 1361.49 18.37 1360.89 18.75 1360.97C20.19 1361.26 21.02 1360.04 22.24 1359.83C23.1 1359.68 23.65 1359.73 24.09 1360.45C24.82 1361.64 24.81 1362.29 24.07 1362.98C23.75 1363.28 23.47 1363.61 23.2 1363.95C22.01 1365.44 18.14 1365.44 17.36 1363.38C17.22 1363.02 17.1 1362.71 17.18 1362.55V1362.56Z" fill="white"/>
                    <path d="M105.7 1363.17C106.5 1362.65 106.54 1361.26 107.86 1361.39C108.05 1361.41 108.25 1361.52 108.41 1361.47C109.08 1361.27 109.73 1360.82 110.43 1361.36C110.98 1361.79 110.96 1362.48 111.13 1363.06C111.47 1364.22 110.64 1365.71 109.41 1366.22C108.45 1366.62 107.64 1366.44 106.82 1365.78C105.97 1365.09 106.03 1364.06 105.7 1363.16V1363.17Z" fill="white"/>
                    <path d="M155.35 1327.34C154.97 1327.26 154.36 1327.26 154.42 1326.89C154.53 1326.21 154.35 1325.41 155.1 1324.88C155.52 1324.58 156 1324.74 156.35 1324.5C156.91 1324.12 157.27 1324.09 157.73 1324.68C158.22 1325.31 158.2 1326 157.75 1326.42C157.12 1327.01 156.42 1327.8 155.36 1327.35L155.35 1327.34Z" fill="white"/>
                    <path d="M72.64 1379.13C72.5 1379.7 72.67 1380.51 71.57 1380.27C71.32 1380.22 70.98 1380.67 70.66 1380.87C70.3 1381.09 69.92 1381.28 69.55 1381.49C69.6 1380.86 69.43 1380.09 69.74 1379.65C70.13 1379.07 70.92 1378.78 71.51 1378.32C71.83 1378.07 72.18 1377.9 72.51 1378.12C72.88 1378.36 72.68 1378.78 72.63 1379.13H72.64Z" fill="white"/>
                    <path d="M221.91 1395.55C221.88 1395.73 221.87 1395.92 221.81 1396.09C221.68 1396.48 221.88 1397.1 221.23 1397.14C220.56 1397.18 220.04 1396.88 219.7 1396.24C219.41 1395.7 219.67 1395.45 220.06 1395.13C220.31 1394.92 220.38 1394.51 220.62 1394.3C220.94 1394.02 221.28 1393.6 221.8 1393.83C222.41 1394.1 222.01 1394.6 222 1395.01C222 1395.19 221.94 1395.37 221.91 1395.55Z" fill="white"/>
                    <path d="M77.72 1333.61C77.72 1334.12 77.5 1334.4 76.89 1334.26C75.95 1334.05 75.5 1333.45 75.61 1332.59C75.71 1331.83 76.25 1331.5 76.86 1331.41C77.56 1331.32 77.97 1332.39 77.73 1333.6L77.72 1333.61Z" fill="white"/>
                    <path d="M16.98 1275.17C16.93 1275.45 16.93 1275.74 16.83 1275.99C16.59 1276.58 15.81 1276.65 15.44 1276.13C15.22 1275.83 14.94 1275.55 14.81 1275.21C14.64 1274.77 14.71 1274.22 15.02 1273.94C15.39 1273.62 16.06 1273.86 16.45 1273.43C16.69 1273.9 17.29 1274.22 17.02 1274.89C16.99 1274.97 16.99 1275.07 16.97 1275.16L16.98 1275.17Z" fill="white"/>
                    <path d="M158.85 1448.91C159.12 1448.72 159.36 1448.57 159.57 1448.4C160.11 1447.96 160.81 1448.74 161.36 1448.14C161.65 1447.82 162.07 1447.9 162.27 1448.27C162.47 1448.62 162.3 1448.94 161.95 1449.17C161.49 1449.47 161.06 1449.83 160.58 1450.11C160.35 1450.24 160.13 1450.79 159.77 1450.23C159.48 1449.78 159.15 1449.35 158.85 1448.91Z" fill="white"/>
                    <path d="M45.51 1457.48C46.09 1457.49 46.41 1457.67 46.27 1458.3C46.1 1459.07 45.43 1459.74 44.88 1459.64C44.33 1459.54 43.91 1458.67 44.05 1457.9C44.26 1456.75 45.04 1457.56 45.51 1457.48Z" fill="white"/>
                    <path d="M40.3301 1452.99C40.6701 1452.6 41.0201 1452.16 41.6101 1452.22C42.2501 1452.29 42.7001 1453.49 42.4101 1454.2C42.2201 1454.67 41.8701 1454.57 41.5201 1454.48C40.7201 1454.26 40.1601 1453.56 40.3301 1452.99Z" fill="white"/>
                    <path d="M126.28 1458.61C126.22 1458.97 126.17 1459.15 126.16 1459.33C126.13 1460.01 125.47 1460.11 125.1 1460.46C124.87 1460 124.29 1459.69 124.49 1459.03C124.54 1458.86 124.57 1458.67 124.59 1458.49C124.65 1457.87 124.79 1457.5 125.6 1457.65C126.37 1457.8 126.49 1458.14 126.29 1458.6L126.28 1458.61Z" fill="white"/>
                    <path d="M92.77 1463.8C92.76 1463.02 93.31 1462.67 93.92 1462.57C94.38 1462.49 95.24 1463.78 94.99 1464.08C94.52 1464.66 93.91 1464.83 93.16 1464.64C92.63 1464.5 92.7 1464.17 92.76 1463.8H92.77Z" fill="white"/>
                    <path d="M204.61 1334.2C205.13 1334.39 205.36 1334.87 205.51 1335.37C205.6 1335.67 204.21 1336.36 203.64 1336.31C203.12 1336.27 203.13 1335.91 203.21 1335.53C203.36 1334.77 204.05 1334.11 204.62 1334.19L204.61 1334.2Z" fill="white"/>
                    <path d="M153.73 1412.67C153.85 1412.56 153.97 1412.35 154.11 1412.34C154.84 1412.29 155.92 1413.51 155.75 1414.18C155.69 1414.41 155.53 1414.74 155.35 1414.79C155.16 1414.84 154.83 1414.68 154.67 1414.51C153.96 1413.76 153.66 1413.12 153.74 1412.66L153.73 1412.67Z" fill="white"/>
                    <path d="M130.49 1310.42C130.22 1310.37 129.93 1310.37 129.68 1310.27C129.04 1310.01 129.44 1309.51 129.46 1309.1C129.49 1308.57 129.86 1308.59 130.24 1308.66C130.59 1308.73 130.95 1308.72 131.17 1309.1C131.4 1309.49 131.8 1309.84 131.5 1310.31C131.26 1310.68 130.84 1310.46 130.49 1310.42Z" fill="white"/>
                    <path d="M168.35 1305.74C168.43 1305.36 168.52 1304.96 169.01 1305.14C169.65 1305.37 170.09 1305.87 169.89 1306.54C169.78 1306.89 169.26 1307.12 168.93 1307.4C168.61 1306.89 168.04 1306.46 168.36 1305.74H168.35Z" fill="white"/>
                    <path d="M20.73 1321.6C21.38 1321.76 21.94 1322.64 21.78 1323.09C21.62 1323.56 21.24 1323.41 20.89 1323.37C20.19 1323.29 19.79 1322.83 19.8 1322.21C19.8 1322.03 20.37 1321.54 20.73 1321.6Z" fill="white"/>
                    <path d="M73.02 1468.84C73.1 1468.38 73.21 1467.9 73.66 1468.12C74.21 1468.4 74.66 1468.88 75.16 1469.28C74.66 1469.68 74.19 1470.01 73.45 1469.79C72.75 1469.58 73.01 1469.15 73.02 1468.83V1468.84Z" fill="white"/>
                    <path d="M81.8501 1476.94C82.3201 1477.11 83.1601 1476.88 82.8701 1477.79C82.7101 1478.28 82.2201 1478.66 81.6301 1478.62C80.6601 1478.56 81.1101 1477.58 80.9301 1477.13C80.7101 1476.56 81.5401 1477.09 81.8501 1476.95V1476.94Z" fill="white"/>
                    <path d="M59.7301 1284.94C59.1301 1284.64 59.4801 1284.15 59.5401 1283.75C59.6401 1283.01 60.1901 1283.33 60.5901 1283.38C60.9601 1283.42 61.3301 1283.52 61.1901 1284.04C61.0401 1284.57 60.1901 1285.08 59.7301 1284.94Z" fill="white"/>
                    <path d="M28.6401 1429.92C29.1201 1430.02 29.5001 1430.1 29.3501 1430.62C29.2001 1431.15 28.3401 1431.68 27.8901 1431.51C27.4301 1431.33 27.6101 1430.95 27.6301 1430.6C27.6601 1429.74 28.1601 1429.79 28.6401 1429.92Z" fill="white"/>
                    <path d="M202.19 1397.15C202.5 1396.84 202.88 1396.13 203.27 1396.55C203.6 1396.91 204.38 1396.77 204.32 1397.44C204.29 1397.84 202.84 1398.15 202.39 1397.87C202.15 1397.72 202.08 1397.46 202.19 1397.16V1397.15Z" fill="white"/>
                    <path d="M-1.45999 1341.6C-1.16999 1341 -0.689987 1341.37 -0.289987 1341.4C0.220013 1341.43 0.330013 1341.84 0.130013 1342.15C-0.099987 1342.5 -0.489987 1342.77 -0.859987 1343C-0.989987 1343.08 -1.54999 1341.84 -1.45999 1341.61V1341.6Z" fill="white"/>
                    <path d="M151.42 1264.35C151.92 1264.34 152.07 1264.59 152.02 1264.86C151.89 1265.57 151.38 1265.87 150.7 1265.91C150.35 1265.93 150.2 1265.67 150.24 1265.4C150.35 1264.66 150.9 1264.43 151.42 1264.35Z" fill="white"/>
                    <path d="M169.58 1427.05C169.01 1426.86 168.64 1426.41 168.72 1425.94C168.76 1425.71 169.28 1425.25 169.82 1425.36C170.55 1425.51 170.23 1426.01 170.19 1426.41C170.15 1426.79 170.04 1427.12 169.57 1427.04L169.58 1427.05Z" fill="white"/>
                    <path d="M58.96 1266.51C59.22 1266.51 60.16 1267.45 60.03 1267.55C59.69 1267.82 59.3 1268.1 58.89 1268.18C58.51 1268.26 58.21 1267.98 58.35 1267.51C58.47 1267.13 58.26 1266.54 58.95 1266.5L58.96 1266.51Z" fill="white"/>
                    <path d="M170.28 1432.75C170.52 1432.29 170.93 1431.84 171.66 1431.99C171.93 1432.04 172.12 1432.27 171.99 1432.6C171.72 1433.22 171.27 1433.61 170.56 1433.49C170.29 1433.45 170.11 1433.22 170.29 1432.75H170.28Z" fill="white"/>
                    <path d="M228.4 1456.76C228.46 1456.25 228.85 1456.12 229.16 1456.32C229.51 1456.55 229.78 1456.94 230.01 1457.31C230.09 1457.44 228.85 1458 228.62 1457.91C228.29 1457.78 228.21 1457.33 228.4 1456.75V1456.76Z" fill="white"/>
                    <path d="M80.98 1472.4C81.06 1472.02 81.13 1471.63 81.64 1471.77C82.2 1471.91 82.55 1472.39 82.47 1472.86C82.43 1473.09 81.91 1473.55 81.37 1473.44C80.64 1473.3 80.98 1472.78 80.98 1472.41V1472.4Z" fill="white"/>
                    <path d="M91.46 1464.76C91.58 1464.64 91.7 1464.53 91.82 1464.41C92.3 1465.06 92.68 1465.81 93.5 1466.14C93.54 1466.15 93.49 1466.51 93.4 1466.66C93.35 1466.74 93.03 1466.79 93.01 1466.76C92.65 1466.11 91.69 1466.07 91.47 1465.29C91.42 1465.13 91.46 1464.94 91.46 1464.76Z" fill="white"/>
                    <path d="M95.84 1464.54C96.38 1464.67 95.96 1465.32 95.84 1465.74C95.61 1466.56 94.9701 1466 94.5101 1465.99C94.3701 1465.99 93.9301 1465.6 94.4101 1465.54C95.1201 1465.44 94.52 1464.17 95.84 1464.55V1464.54Z" fill="white"/>
                    <path d="M116.13 1291.34C115.45 1291.34 115.37 1290.67 115.02 1290.31C115.3 1290.1 115.62 1289.68 115.85 1289.73C116.35 1289.83 116.61 1290.3 116.62 1290.84C116.62 1291.17 116.41 1291.28 116.13 1291.34Z" fill="white"/>
                    <path d="M189.63 1305.56C190.26 1304.89 190.86 1304.82 191.49 1305.05C191.64 1305.1 191.74 1305.27 191.87 1305.39C191.72 1305.49 191.55 1305.57 191.43 1305.71C190.98 1306.22 190.44 1306.02 189.93 1305.89C189.77 1305.85 189.67 1305.6 189.63 1305.56Z" fill="white"/>
                    <path d="M12.23 1405.43C12.32 1405.51 12.53 1405.62 12.52 1405.7C12.47 1406.33 12.4 1406.94 11.7 1407.27C11.41 1407.4 11.23 1407.44 11.15 1407.13C11.02 1406.65 11.78 1405.47 12.23 1405.42V1405.43Z" fill="white"/>
                    <path d="M77.09 1317.57C77.46 1316.75 78.05 1317.43 78.52 1317.42C78.73 1317.42 78.82 1317.86 78.69 1318.11C78.32 1318.87 77.73 1318.32 77.26 1318.27C77.04 1318.25 76.97 1317.86 77.09 1317.56V1317.57Z" fill="white"/>
                    <path d="M72.95 1426.65C73.32 1425.83 73.91 1426.51 74.38 1426.5C74.59 1426.5 74.68 1426.94 74.55 1427.19C74.18 1427.95 73.59 1427.4 73.12 1427.35C72.9 1427.33 72.83 1426.94 72.95 1426.64V1426.65Z" fill="white"/>
                    <path d="M188.55 1376.77C188.57 1376.63 188.92 1376.62 189.3 1376.72C189.69 1376.82 190.29 1376.69 190.18 1377.43C190.11 1377.95 189.76 1377.95 189.39 1377.86C188.99 1377.77 188.4 1377.87 188.55 1376.76V1376.77Z" fill="white"/>
                    <path d="M152.58 1464.77C152.82 1464.31 152.98 1464.1 153.3 1464.24C153.92 1464.51 154.3 1464.96 154.19 1465.67C154.17 1465.81 153.97 1465.92 153.85 1466.04C153.41 1465.6 152.96 1465.15 152.57 1464.76L152.58 1464.77Z" fill="white"/>
                    <path d="M44.5801 1435.34C44.6001 1434.84 44.7701 1434.48 44.9901 1434.53C45.4501 1434.65 46.1901 1434.33 46.2801 1435.17C46.3101 1435.46 46.1301 1435.81 45.8701 1435.77C45.4001 1435.7 44.7401 1435.86 44.5701 1435.34H44.5801Z" fill="white"/>
                    <path d="M183.71 1358.75C183.79 1358.37 183.86 1358.02 184.39 1358.13C185.12 1358.28 184.82 1358.79 184.78 1359.2C184.74 1359.57 184.64 1359.92 184.11 1359.81C183.37 1359.66 183.7 1359.15 183.71 1358.75Z" fill="white"/>
                    <path d="M99.8 1343.96C98.98 1343.59 99.66 1343 99.65 1342.53C99.65 1342.32 100.09 1342.23 100.34 1342.36C101.1 1342.73 100.55 1343.32 100.5 1343.79C100.48 1344.01 100.09 1344.08 99.79 1343.96H99.8Z" fill="white"/>
                    <path d="M152.87 1475.02C152.5 1474.98 152.09 1474.96 152.12 1474.45C152.15 1473.79 152.57 1473.79 153.08 1473.91C153.43 1473.99 153.85 1473.97 153.82 1474.49C153.79 1475.14 153.37 1475.18 152.87 1475.02Z" fill="white"/>
                    <path d="M176 1324.12C176.09 1324 176.23 1323.79 176.38 1323.59C176.65 1324.02 177.2 1324.33 177.01 1324.96C176.93 1325.23 176.74 1325.37 176.4 1325.3C175.68 1325.15 175.98 1324.66 176 1324.13V1324.12Z" fill="white"/>
                    <path d="M148.39 1450.42C148.84 1450.15 149.21 1449.6 149.81 1449.94C149.95 1450.02 150.03 1450.45 149.95 1450.65C149.71 1451.28 149.2 1450.88 148.8 1450.87C148.52 1450.87 148.32 1450.76 148.39 1450.42Z" fill="white"/>
                    <path d="M84.4601 1386.78C84.4901 1386.6 84.4801 1386.39 84.5701 1386.24C84.6301 1386.14 84.8801 1386.04 84.9501 1386.09C85.2101 1386.25 84.9301 1387.96 84.6201 1388.09C84.5501 1388.12 84.2801 1387.94 84.2901 1387.87C84.3201 1387.5 84.4001 1387.14 84.4601 1386.78Z" fill="white"/>
                    <path d="M234.15 1417.2C234.21 1416.63 234.57 1416.6 234.94 1416.71C235.21 1416.79 235.35 1416.98 235.29 1417.33C235.2 1417.84 234.87 1417.87 234.5 1417.76C234.24 1417.68 234.01 1417.5 234.16 1417.21L234.15 1417.2Z" fill="white"/>
                    <path d="M59.13 1289.29C59.26 1289.02 59.42 1288.87 59.76 1288.93C60.27 1289.02 60.3 1289.35 60.19 1289.72C60.11 1289.99 59.92 1290.13 59.58 1290.07C59.07 1289.98 59.08 1289.66 59.13 1289.29Z" fill="white"/>
                    <path d="M165.86 1296.57C166.2 1296.64 166.51 1296.73 166.42 1297.25C166.36 1297.59 166.17 1297.73 165.89 1297.71C165.5 1297.69 165.21 1297.55 165.29 1297.04C165.35 1296.7 165.53 1296.6 165.86 1296.57Z" fill="white"/>
                    <path d="M24.74 1302.84C24.74 1302.5 24.98 1302.38 25.26 1302.4C25.65 1302.42 25.94 1302.56 25.86 1303.07C25.8 1303.41 25.61 1303.55 25.33 1303.53C24.94 1303.51 24.62 1303.36 24.75 1302.84H24.74Z" fill="white"/>
                    <path d="M29.86 1414.59C29.99 1414.32 30.15 1414.17 30.49 1414.23C31 1414.32 31.03 1414.65 30.92 1415.02C30.84 1415.29 30.65 1415.43 30.31 1415.37C29.8 1415.28 29.81 1414.96 29.86 1414.59Z" fill="white"/>
                    <path d="M212.51 1475.63C212.67 1475.31 212.82 1475.17 213.16 1475.23C213.67 1475.32 213.7 1475.65 213.59 1476.02C213.51 1476.29 213.32 1476.42 212.98 1476.37C212.45 1476.28 212.51 1475.94 212.51 1475.63Z" fill="white"/>
                    <path d="M189.51 1476.26C190.04 1476.32 190.07 1476.67 189.96 1477.04C189.88 1477.31 189.69 1477.45 189.34 1477.39C188.83 1477.3 188.8 1476.97 188.91 1476.6C188.99 1476.33 189.19 1476.14 189.5 1476.26H189.51Z" fill="white"/>
                    <path d="M1.98999 1344.79C2.05999 1344.43 2.13999 1344.06 2.64999 1344.2C2.80999 1344.24 2.93999 1344.36 3.08999 1344.44C2.78999 1344.71 2.49999 1344.98 2.19999 1345.26C2.12999 1345.1 2.05999 1344.95 1.98999 1344.79Z" fill="white"/>
                    <path d="M12.6901 1474.29C13.0001 1474.06 13.2901 1473.85 13.5701 1473.64C13.6201 1473.88 13.7601 1474.15 13.7001 1474.37C13.5801 1474.85 13.1801 1474.75 12.8401 1474.64C12.7501 1474.61 12.7201 1474.39 12.6801 1474.29H12.6901Z" fill="white"/>
                    <path d="M107.64 1345.31C107.83 1345.42 108.1 1345.52 108.09 1345.59C108.02 1346.08 107.5 1346.11 107.22 1346.4C107.15 1346.24 107.03 1346.09 107.03 1345.93C107.05 1345.54 107.2 1345.24 107.64 1345.31Z" fill="white"/>
                    <path d="M146.83 1265.59C146.93 1265.49 147.03 1265.3 147.13 1265.3C147.49 1265.3 147.9 1265.35 147.84 1265.85C147.81 1266.07 147.59 1266.28 147.46 1266.49C147.25 1266.19 147.05 1265.9 146.83 1265.59Z" fill="white"/>
                    <path d="M126.27 1284.53C125.77 1284.46 125.77 1284.1 125.87 1283.75C125.91 1283.61 126.09 1283.5 126.21 1283.38C126.4 1283.74 126.58 1284.09 126.77 1284.45C126.61 1284.48 126.44 1284.51 126.27 1284.54V1284.53Z" fill="white"/>
                    <path d="M25.71 1455.47C25.76 1455.41 25.85 1455.22 25.95 1455.22C26.3 1455.22 26.72 1455.27 26.68 1455.76C26.66 1455.98 26.44 1456.19 26.31 1456.4C26.12 1456.1 25.93 1455.8 25.72 1455.47H25.71Z" fill="white"/>
                    <path d="M33.51 1419.89C33.58 1419.39 33.94 1419.39 34.29 1419.49C34.43 1419.53 34.54 1419.71 34.66 1419.83C34.3 1420.02 33.95 1420.2 33.59 1420.39C33.56 1420.23 33.53 1420.06 33.5 1419.89H33.51Z" fill="white"/>
                    <path d="M83.6501 1266.7C83.7601 1266.57 83.8801 1266.44 83.9901 1266.31C84.1901 1266.65 84.3901 1267 84.6001 1267.34C84.4401 1267.38 84.2701 1267.45 84.1101 1267.44C83.6101 1267.41 83.5901 1267.07 83.6501 1266.69V1266.7Z" fill="white"/>
                    <path d="M186.31 1458.55C185.81 1458.48 185.81 1458.12 185.91 1457.77C185.95 1457.63 186.13 1457.52 186.25 1457.4C186.44 1457.76 186.62 1458.11 186.81 1458.47C186.65 1458.5 186.48 1458.53 186.31 1458.56V1458.55Z" fill="white"/>
                    <path d="M224.75 1463C224.67 1462.78 224.55 1462.62 224.56 1462.47C224.59 1462.08 224.75 1461.77 225.23 1461.91C225.38 1461.96 225.51 1462.08 225.65 1462.17C225.36 1462.44 225.06 1462.71 224.75 1463Z" fill="white"/>
                    <path d="M220.25 1362.88C220.49 1362.59 220.77 1362.76 221.02 1362.82C221.1 1362.84 221.2 1363.12 221.15 1363.19C220.95 1363.48 220.65 1363.31 220.41 1363.24C220.33 1363.22 220.3 1362.99 220.25 1362.87V1362.88Z" fill="white"/>
                    <path d="M152.88 1282.53C153.19 1282.49 153.44 1282.42 153.68 1282.45C153.82 1282.47 153.93 1282.67 154.06 1282.78C153.81 1282.83 153.57 1282.93 153.32 1282.92C153.19 1282.92 153.07 1282.71 152.88 1282.52V1282.53Z" fill="white"/>
                    <path d="M149.85 1282.38C149.99 1282.42 150.25 1282.44 150.47 1282.55C150.72 1282.68 150.69 1282.88 150.41 1282.91C150.17 1282.93 149.9 1282.88 149.69 1282.78C149.44 1282.66 149.48 1282.5 149.86 1282.38H149.85Z" fill="white"/>
                    <path d="M230.02 1348.93C229.88 1348.84 229.62 1348.75 229.62 1348.66C229.62 1348.33 229.88 1348.29 230.14 1348.38C230.29 1348.44 230.55 1348.55 230.55 1348.64C230.55 1348.97 230.3 1348.99 230.03 1348.92L230.02 1348.93Z" fill="white"/>
                    <path d="M77.86 1275.03C77.82 1274.72 77.75 1274.47 77.78 1274.23C77.8 1274.09 78 1273.98 78.11 1273.85C78.16 1274.1 78.26 1274.34 78.25 1274.59C78.25 1274.72 78.04 1274.84 77.85 1275.03H77.86Z" fill="white"/>
                    <path d="M35.07 1269.78C35.34 1269.8 35.58 1269.91 35.46 1270.22C35.43 1270.3 35.15 1270.33 34.99 1270.33C34.72 1270.33 34.48 1270.21 34.6 1269.9C34.63 1269.82 34.91 1269.82 35.07 1269.78Z" fill="white"/>
                    <path d="M155 1474.25C155.27 1474.27 155.51 1474.38 155.39 1474.69C155.36 1474.77 155.08 1474.8 154.92 1474.8C154.65 1474.8 154.41 1474.68 154.53 1474.37C154.56 1474.29 154.84 1474.29 155 1474.25Z" fill="white"/>
                    <path d="M148.12 1464.48C148.39 1464.5 148.63 1464.61 148.51 1464.92C148.48 1465 148.2 1465.03 148.04 1465.03C147.77 1465.03 147.53 1464.91 147.65 1464.6C147.68 1464.52 147.96 1464.52 148.12 1464.48Z" fill="white"/>
                    <path d="M117.92 1432.42C117.88 1432.11 117.81 1431.86 117.84 1431.62C117.86 1431.48 118.06 1431.37 118.17 1431.24C118.22 1431.49 118.32 1431.73 118.31 1431.98C118.31 1432.11 118.1 1432.23 117.91 1432.42H117.92Z" fill="white"/>
                    <path d="M20.17 1463.41C20.3 1463.24 20.45 1463.04 20.6 1462.84C20.67 1463 20.82 1463.18 20.79 1463.3C20.72 1463.53 20.54 1463.73 20.41 1463.95C20.34 1463.79 20.27 1463.64 20.17 1463.41Z" fill="white"/>
                    <path d="M58.39 1382.32C58.47 1382.23 58.59 1382.11 58.71 1381.98C58.76 1382.23 58.86 1382.47 58.85 1382.72C58.85 1382.85 58.64 1382.97 58.52 1383.09C58.33 1382.87 58.26 1382.63 58.39 1382.32Z" fill="white"/>
                    <path d="M17.06 1367.95C17.02 1367.64 16.95 1367.39 16.98 1367.15C17 1367.01 17.2 1366.9 17.31 1366.77C17.36 1367.02 17.46 1367.26 17.45 1367.51C17.45 1367.64 17.24 1367.76 17.05 1367.95H17.06Z" fill="white"/>
                    <path d="M11.11 1328.51C11.38 1328.53 11.62 1328.64 11.5 1328.95C11.47 1329.03 11.19 1329.06 11.03 1329.06C10.76 1329.06 10.52 1328.94 10.64 1328.63C10.67 1328.55 10.95 1328.55 11.11 1328.51Z" fill="white"/>
                    <path d="M204.48 1370.79C204.34 1370.7 204.08 1370.61 204.08 1370.52C204.08 1370.19 204.34 1370.15 204.6 1370.24C204.75 1370.3 205.01 1370.41 205.01 1370.5C205.01 1370.83 204.76 1370.85 204.49 1370.78L204.48 1370.79Z" fill="white"/>
                    <path d="M112.61 1369.64C112.7 1369.5 112.79 1369.24 112.88 1369.24C113.21 1369.24 113.25 1369.5 113.16 1369.76C113.1 1369.91 112.99 1370.17 112.9 1370.17C112.57 1370.17 112.55 1369.92 112.62 1369.65L112.61 1369.64Z" fill="white"/>
                    <path d="M114.29 1355.07C114.25 1354.94 114.19 1354.8 114.18 1354.67C114.18 1354.62 114.31 1354.57 114.39 1354.52C114.43 1354.65 114.49 1354.79 114.5 1354.92C114.5 1354.97 114.37 1355.02 114.29 1355.07Z" fill="white"/>
                    <path d="M82.23 1256.83C82.19 1256.7 82.13 1256.56 82.12 1256.43C82.12 1256.38 82.25 1256.33 82.33 1256.28C82.37 1256.41 82.43 1256.55 82.44 1256.68C82.44 1256.73 82.31 1256.78 82.23 1256.83Z" fill="white"/>
                    <path d="M137.63 1459.59C137.71 1459.48 137.8 1459.38 137.89 1459.27C137.94 1459.34 138.05 1459.44 138.03 1459.48C137.98 1459.61 137.87 1459.71 137.79 1459.82C137.74 1459.75 137.69 1459.68 137.63 1459.59Z" fill="white"/>
                    <path d="M176.87 1479.58C176.78 1479.64 176.71 1479.69 176.64 1479.74C176.6 1479.61 176.54 1479.47 176.53 1479.34C176.53 1479.29 176.66 1479.24 176.74 1479.19C176.79 1479.32 176.83 1479.45 176.88 1479.58H176.87Z" fill="white"/>
                    <path d="M95.87 1466.89C95.98 1466.97 96.08 1467.06 96.19 1467.15C96.12 1467.2 96.02 1467.31 95.98 1467.29C95.85 1467.24 95.75 1467.13 95.64 1467.05C95.71 1467 95.78 1466.95 95.87 1466.89Z" fill="white"/>
                    <path d="M71.73 1430.29C71.86 1430.25 72 1430.19 72.13 1430.18C72.18 1430.18 72.23 1430.31 72.28 1430.39C72.15 1430.43 72.01 1430.49 71.88 1430.5C71.83 1430.5 71.78 1430.37 71.73 1430.29Z" fill="white"/>
                    <path d="M27.39 1417.79C27.45 1417.88 27.5 1417.95 27.55 1418.02C27.42 1418.06 27.28 1418.12 27.15 1418.13C27.1 1418.13 27.05 1418 27 1417.92C27.13 1417.87 27.26 1417.83 27.39 1417.78V1417.79Z" fill="white"/>
                    <path d="M0.109985 1467.65C0.189985 1467.54 0.279985 1467.44 0.369985 1467.33C0.419985 1467.4 0.529985 1467.5 0.509985 1467.54C0.459985 1467.67 0.349985 1467.77 0.269985 1467.88C0.219985 1467.81 0.169985 1467.74 0.109985 1467.65Z" fill="white"/>
                    <path d="M113.76 1471.85C113.85 1471.79 113.92 1471.74 113.99 1471.69C114.03 1471.82 114.09 1471.96 114.1 1472.09C114.1 1472.14 113.97 1472.19 113.89 1472.24C113.84 1472.11 113.8 1471.98 113.75 1471.85H113.76Z" fill="white"/>
                    <path d="M88.49 1405.85C88.4 1405.91 88.33 1405.96 88.26 1406.01C88.22 1405.88 88.16 1405.74 88.15 1405.61C88.15 1405.56 88.28 1405.51 88.36 1405.46C88.41 1405.59 88.45 1405.72 88.5 1405.85H88.49Z" fill="white"/>
                    <path d="M141.43 1345.87C141.51 1345.76 141.6 1345.66 141.69 1345.55C141.74 1345.62 141.85 1345.72 141.83 1345.76C141.78 1345.89 141.67 1345.99 141.59 1346.1C141.54 1346.03 141.49 1345.96 141.43 1345.87Z" fill="white"/>
                    <path d="M48.61 1346.85C48.69 1346.74 48.78 1346.64 48.87 1346.53C48.92 1346.6 49.03 1346.7 49.01 1346.74C48.96 1346.87 48.85 1346.97 48.77 1347.08C48.72 1347.01 48.67 1346.94 48.61 1346.85Z" fill="white"/>
                    <path d="M67.53 1476.25C67.59 1476.34 67.64 1476.41 67.69 1476.48C67.56 1476.52 67.42 1476.58 67.29 1476.59C67.24 1476.59 67.19 1476.46 67.14 1476.38C67.27 1476.33 67.4 1476.29 67.53 1476.24V1476.25Z" fill="white"/>
                    <path d="M55.16 1476.67C55.24 1476.56 55.33 1476.46 55.42 1476.35C55.47 1476.42 55.58 1476.52 55.56 1476.56C55.51 1476.69 55.4 1476.79 55.32 1476.9L55.16 1476.67Z" fill="white"/>
                    <path d="M105.23 1378C105.12 1377.92 105.02 1377.83 104.91 1377.74C104.98 1377.69 105.08 1377.58 105.12 1377.6C105.25 1377.65 105.35 1377.76 105.46 1377.84C105.39 1377.89 105.32 1377.94 105.23 1378Z" fill="white"/>
                    <path d="M231.2 1340.56C231.16 1340.43 231.1 1340.29 231.09 1340.16C231.09 1340.11 231.22 1340.06 231.3 1340.01C231.34 1340.14 231.4 1340.28 231.41 1340.41C231.41 1340.46 231.28 1340.51 231.2 1340.56Z" fill="white"/>
                    <path d="M6.9701 1264.48C8.7601 1264.22 9.5301 1265.4 10.2401 1266.52C10.5001 1266.93 10.8101 1267.62 11.0201 1267.79C12.3701 1268.83 10.5701 1269.75 11.0401 1270.68C10.0701 1270.99 9.0101 1271.69 8.1401 1271.5C7.3201 1271.32 6.3201 1271 5.5801 1270.42C4.8901 1269.86 3.6601 1269.4 4.8101 1268.13C4.9801 1267.94 4.7601 1267.42 4.7701 1267.06C4.7701 1265.44 5.5601 1264.59 6.9801 1264.49L6.9701 1264.48Z" fill="white"/>
                    <path d="M5.19006 1361.62C5.83006 1360.94 5.54006 1359.6 6.83006 1359.41C7.01006 1359.38 7.23006 1359.44 7.37006 1359.36C7.96006 1359.02 8.48006 1358.43 9.28006 1358.78C9.90006 1359.06 10.0501 1359.72 10.3501 1360.24C10.9501 1361.27 10.5001 1362.89 9.45006 1363.67C8.63006 1364.27 7.82006 1364.3 6.88006 1363.86C5.90006 1363.4 5.72006 1362.4 5.19006 1361.62Z" fill="white"/>
                    <path d="M72.3001 1472.29C72.4001 1473.89 71.8601 1474.65 70.7201 1474.86C70.0901 1474.98 69.4301 1474.93 68.8001 1475.01C68.2001 1475.09 67.9901 1474.84 67.9901 1474.26C67.9901 1473.62 67.8201 1472.96 67.8901 1472.34C68.0101 1471.27 68.6901 1470.52 69.6101 1470.02C69.8001 1469.92 70.1901 1469.93 70.3401 1470.06C70.8801 1470.54 71.5201 1470.8 72.1201 1471.15C72.4101 1471.32 72.3001 1472.16 72.3201 1472.28L72.3001 1472.29Z" fill="white"/>
                    <path d="M92.3 1472.56C92.24 1471.83 92.76 1471.17 93.47 1471.08C93.71 1471.05 96.07 1472.18 96.17 1472.37C96.4 1472.81 95.59 1474.29 95.53 1474.22C94.82 1473.3 94.36 1474.64 93.77 1474.35C93.21 1474.08 92.34 1473.02 92.29 1472.55L92.3 1472.56Z" fill="white"/>
                    <path d="M44.1301 1315.75C43.7501 1315.77 43.1701 1315.91 43.1401 1315.54C43.0901 1314.87 42.7201 1314.15 43.3101 1313.46C43.6401 1313.08 44.1401 1313.11 44.4101 1312.81C44.8501 1312.31 45.1901 1312.2 45.7601 1312.65C46.3801 1313.13 46.5201 1313.8 46.1901 1314.3C45.7201 1315.01 45.2401 1315.93 44.1301 1315.75Z" fill="white"/>
                    <path d="M123.7 1365.17C123.71 1365.35 123.75 1365.53 123.73 1365.71C123.7 1366.11 124.04 1366.66 123.42 1366.85C122.79 1367.04 122.22 1366.89 121.75 1366.36C121.35 1365.91 121.54 1365.61 121.83 1365.21C122.02 1364.96 121.99 1364.54 122.17 1364.28C122.41 1363.94 122.64 1363.46 123.19 1363.55C123.84 1363.66 123.58 1364.24 123.66 1364.63C123.7 1364.8 123.69 1364.99 123.7 1365.17Z" fill="white"/>
                    <path d="M76.0801 1430.93C76.2901 1430.69 76.4801 1430.49 76.6501 1430.27C77.0601 1429.72 77.9101 1430.3 78.3001 1429.6C78.5001 1429.23 78.9201 1429.2 79.2001 1429.51C79.4701 1429.8 79.3901 1430.14 79.1101 1430.44C78.7401 1430.84 78.4101 1431.28 78.0201 1431.66C77.8401 1431.84 77.7501 1432.41 77.2801 1431.97C76.8901 1431.61 76.4901 1431.27 76.0901 1430.93H76.0801Z" fill="white"/>
                    <path d="M47.2801 1447.86C47.3001 1448.22 47.3001 1448.4 47.3301 1448.58C47.4601 1449.24 46.8601 1449.48 46.5901 1449.91C46.2601 1449.52 45.6301 1449.37 45.6701 1448.69C45.6801 1448.51 45.6701 1448.33 45.6301 1448.15C45.5401 1447.55 45.5901 1447.15 46.3901 1447.11C47.1601 1447.07 47.3601 1447.36 47.2701 1447.85L47.2801 1447.86Z" fill="white"/>
                    <path d="M16.53 1460.7C16.33 1459.96 16.78 1459.49 17.34 1459.25C17.76 1459.07 18.88 1460.1 18.72 1460.44C18.41 1461.1 17.86 1461.41 17.1 1461.4C16.57 1461.4 16.55 1461.06 16.52 1460.69L16.53 1460.7Z" fill="white"/>
                    <path d="M92.75 1310.71C93.29 1310.77 93.62 1311.17 93.88 1311.62C94.03 1311.89 92.88 1312.87 92.32 1312.96C91.81 1313.04 91.74 1312.69 91.72 1312.32C91.69 1311.56 92.19 1310.76 92.75 1310.71Z" fill="white"/>
                    <path d="M50.4401 1479.61C50.1701 1479.55 49.8801 1479.37 50.0201 1479.07C50.3301 1478.36 50.4701 1477.57 51.0401 1476.98C51.2801 1476.73 51.5801 1476.69 51.7701 1476.78C51.9501 1476.86 52.1501 1477.27 52.0801 1477.43C51.8201 1478.07 51.4701 1478.67 51.1401 1479.27C51.0001 1479.53 50.7501 1479.63 50.4401 1479.61Z" fill="white"/>
                    <path d="M62.67 1397.56C62.76 1397.42 62.83 1397.2 62.95 1397.16C63.64 1396.94 64.95 1397.85 64.94 1398.54C64.94 1398.78 64.86 1399.12 64.71 1399.22C64.54 1399.32 64.19 1399.24 63.99 1399.11C63.13 1398.56 62.7 1398.02 62.66 1397.56H62.67Z" fill="white"/>
                    <path d="M16.4301 1305.45C16.1601 1305.47 15.8801 1305.54 15.6201 1305.5C14.9401 1305.4 15.2101 1304.83 15.1301 1304.44C15.0301 1303.93 15.3901 1303.86 15.7701 1303.84C16.1201 1303.82 16.4601 1303.73 16.7701 1304.04C17.0801 1304.35 17.5501 1304.6 17.3701 1305.12C17.2301 1305.53 16.7801 1305.42 16.4301 1305.46V1305.45Z" fill="white"/>
                    <path d="M48.7601 1477.03C48.6901 1477.6 48.3001 1477.57 47.9601 1477.56C47.1301 1477.53 46.4401 1476.99 46.5001 1476.41C46.5501 1475.92 46.9401 1475.94 47.2901 1475.95C48.1201 1475.98 48.7901 1476.51 48.7601 1477.03Z" fill="white"/>
                    <path d="M51.4601 1292.08C51.4501 1291.7 51.4301 1291.3 51.9501 1291.35C52.6201 1291.41 53.1501 1291.8 53.1201 1292.48C53.1001 1292.84 52.6501 1293.18 52.4001 1293.53C51.9701 1293.12 51.3301 1292.84 51.4601 1292.08Z" fill="white"/>
                    <path d="M-1.12994 1470.15C-1.15994 1469.69 -1.16994 1469.21 -0.68994 1469.32C-0.0999396 1469.46 0.45006 1469.81 1.01006 1470.07C0.63006 1470.57 0.25006 1471 -0.49994 1470.96C-1.20994 1470.92 -1.06994 1470.45 -1.12994 1470.15Z" fill="white"/>
                    <path d="M9.20002 1475.81C9.69002 1475.86 10.44 1475.45 10.37 1476.38C10.33 1476.89 9.95002 1477.36 9.38002 1477.46C8.44002 1477.63 8.64002 1476.59 8.36002 1476.2C8.01002 1475.71 8.93002 1476.02 9.19002 1475.81H9.20002Z" fill="white"/>
                    <path d="M105.26 1371.34C105.48 1370.97 105.68 1370.2 106.15 1370.51C106.55 1370.77 107.26 1370.46 107.36 1371.11C107.42 1371.5 106.12 1372.14 105.62 1371.98C105.36 1371.9 105.23 1371.66 105.26 1371.35V1371.34Z" fill="white"/>
                    <path d="M25.55 1256.56C26.02 1256.43 26.23 1256.64 26.24 1256.9C26.28 1257.61 25.86 1258.01 25.22 1258.21C24.89 1258.31 24.69 1258.1 24.66 1257.83C24.59 1257.1 25.06 1256.74 25.54 1256.55L25.55 1256.56Z" fill="white"/>
                    <path d="M81.1801 1407.55C80.5901 1407.5 80.1301 1407.16 80.1001 1406.69C80.0801 1406.46 80.4701 1405.9 81.0101 1405.88C81.7401 1405.85 81.5601 1406.41 81.6101 1406.8C81.6701 1407.17 81.6301 1407.51 81.1701 1407.55H81.1801Z" fill="white"/>
                    <path d="M83.1801 1412.83C83.3001 1412.33 83.5901 1411.81 84.3201 1411.78C84.5901 1411.77 84.8301 1411.94 84.7701 1412.28C84.6601 1412.94 84.3201 1413.41 83.6101 1413.47C83.3501 1413.49 83.1101 1413.31 83.1701 1412.83H83.1801Z" fill="white"/>
                    <path d="M144.3 1422.06C144.23 1421.56 144.58 1421.35 144.92 1421.46C145.31 1421.59 145.66 1421.91 145.96 1422.21C146.07 1422.32 145.02 1423.14 144.78 1423.11C144.43 1423.07 144.25 1422.66 144.3 1422.06Z" fill="white"/>
                    <path d="M7.30006 1471.69C7.28006 1471.31 7.26006 1470.92 7.78006 1470.93C8.34006 1470.93 8.80006 1471.3 8.83006 1471.77C8.84006 1472 8.46006 1472.56 7.92006 1472.58C7.19006 1472.61 7.40006 1472.04 7.31006 1471.69H7.30006Z" fill="white"/>
                    <path d="M15.51 1461.92C15.6 1461.78 15.69 1461.64 15.78 1461.5C16.39 1462.01 16.93 1462.63 17.79 1462.76C17.83 1462.76 17.86 1463.12 17.81 1463.28C17.78 1463.37 17.49 1463.49 17.46 1463.46C16.96 1462.92 16.04 1463.11 15.65 1462.42C15.57 1462.27 15.56 1462.09 15.51 1461.92Z" fill="white"/>
                    <path d="M19.63 1460.68C20.18 1460.68 19.93 1461.4 19.91 1461.82C19.89 1462.66 19.14 1462.27 18.7 1462.37C18.57 1462.4 18.05 1462.14 18.5 1461.96C19.16 1461.7 18.28 1460.63 19.63 1460.68Z" fill="white"/>
                    <path d="M71.7201 1286.9C72.1701 1286.11 72.7201 1285.9 73.3701 1285.97C73.5201 1285.99 73.6601 1286.12 73.8101 1286.21C73.6901 1286.34 73.5401 1286.46 73.4601 1286.62C73.1501 1287.21 72.5901 1287.15 72.0701 1287.15C71.9101 1287.15 71.7501 1286.94 71.7101 1286.91L71.7201 1286.9Z" fill="white"/>
                    <path d="M87.4501 1355.11C87.4301 1354.98 87.7701 1354.88 88.1501 1354.89C88.5501 1354.89 89.0901 1354.62 89.1601 1355.36C89.2101 1355.88 88.8901 1355.96 88.5101 1355.96C88.1101 1355.96 87.5701 1356.2 87.4501 1355.11Z" fill="white"/>
                    <path d="M29.3601 1481.63C28.9901 1481.68 28.6101 1481.76 28.5101 1481.26C28.3901 1480.62 28.7901 1480.53 29.3001 1480.52C29.6501 1480.52 30.0501 1480.4 30.1401 1480.9C30.2601 1481.53 29.8701 1481.66 29.3601 1481.63Z" fill="white"/>
                    <path d="M73.8401 1447.55C73.9601 1447.05 74.0701 1446.81 74.4101 1446.87C75.0701 1446.98 75.5301 1447.32 75.6001 1448.03C75.6101 1448.17 75.4501 1448.32 75.3701 1448.47C74.8401 1448.15 74.3201 1447.83 73.8501 1447.55H73.8401Z" fill="white"/>
                    <path d="M78.5901 1339.05C78.5801 1338.67 78.5601 1338.32 79.0901 1338.3C79.8301 1338.27 79.6601 1338.83 79.7101 1339.23C79.7601 1339.6 79.7501 1339.95 79.2201 1339.97C78.4801 1340 78.6801 1339.44 78.5901 1339.06V1339.05Z" fill="white"/>
                    <path d="M76.52 1457.26C76.15 1457.31 75.77 1457.39 75.67 1456.89C75.55 1456.25 75.95 1456.16 76.46 1456.15C76.81 1456.15 77.21 1456.03 77.3 1456.53C77.42 1457.16 77.03 1457.29 76.52 1457.26Z" fill="white"/>
                    <path d="M63.0801 1307.82C63.1301 1307.68 63.2301 1307.45 63.3201 1307.22C63.6801 1307.57 64.2801 1307.73 64.2401 1308.38C64.2201 1308.66 64.0801 1308.84 63.7301 1308.85C63.0101 1308.88 63.1801 1308.34 63.0801 1307.82Z" fill="white"/>
                    <path d="M101.47 1472.76C101.83 1472.65 101.44 1472.67 101.66 1472.95C101.78 1473.1 101.81 1473.5 101.69 1473.64C101.38 1474.02 101.16 1474.61 100.48 1474.47C100.4 1474.46 100.28 1474.35 100.29 1474.31C100.44 1473.61 101.11 1473.3 101.47 1472.76Z" fill="white"/>
                    <path d="M66.4501 1434.84C66.8101 1434.48 67.0401 1433.87 67.6901 1434.05C67.8501 1434.09 68.0201 1434.49 67.9901 1434.69C67.9101 1435.35 67.3301 1435.08 66.9401 1435.17C66.6801 1435.23 66.4601 1435.17 66.4401 1434.84H66.4501Z" fill="white"/>
                    <path d="M140.48 1382.96C140.41 1382.4 140.73 1382.29 141.12 1382.3C141.4 1382.31 141.57 1382.46 141.59 1382.81C141.62 1383.32 141.32 1383.43 140.94 1383.41C140.67 1383.4 140.41 1383.28 140.48 1382.97V1382.96Z" fill="white"/>
                    <path d="M46.92 1283.91C47.26 1283.89 47.58 1283.91 47.62 1284.43C47.65 1284.77 47.49 1284.95 47.22 1285C46.85 1285.07 46.53 1285 46.49 1284.5C46.46 1284.16 46.62 1284.02 46.92 1283.92V1283.91Z" fill="white"/>
                    <path d="M133.58 1443.81C133.65 1443.47 133.77 1443.29 134.11 1443.27C134.62 1443.24 134.72 1443.54 134.71 1443.92C134.7 1444.2 134.55 1444.37 134.2 1444.4C133.68 1444.44 133.65 1444.1 133.58 1443.81Z" fill="white"/>
                    <path d="M111.78 1449.83C112.3 1449.76 112.41 1450.09 112.4 1450.47C112.39 1450.75 112.24 1450.92 111.89 1450.94C111.38 1450.97 111.28 1450.67 111.29 1450.29C111.3 1450.01 111.45 1449.79 111.78 1449.83Z" fill="white"/>
                    <path d="M2.83001 1344.13C3.04001 1344.19 3.32001 1344.23 3.33001 1344.29C3.38001 1344.77 2.88001 1344.92 2.69001 1345.27C2.59001 1345.14 2.43001 1345.02 2.40001 1344.87C2.33001 1344.5 2.40001 1344.18 2.83001 1344.14V1344.13Z" fill="white"/>
                    <path d="M21.4701 1258.82C21.5401 1258.7 21.5901 1258.5 21.6901 1258.47C22.0301 1258.39 22.4401 1258.34 22.5001 1258.83C22.5301 1259.05 22.3701 1259.29 22.2901 1259.53C22.0201 1259.3 21.7601 1259.06 21.4801 1258.82H21.4701Z" fill="white"/>
                    <path d="M6.31001 1281.74C5.81001 1281.8 5.74001 1281.45 5.75001 1281.08C5.75001 1280.93 5.90001 1280.79 5.98001 1280.64C6.24001 1280.94 6.50001 1281.23 6.76001 1281.53C6.61001 1281.6 6.46001 1281.66 6.30001 1281.73L6.31001 1281.74Z" fill="white"/>
                    <path d="M104.56 1433.68C104.06 1433.74 103.99 1433.39 104 1433.02C104 1432.87 104.15 1432.73 104.23 1432.58C104.49 1432.88 104.75 1433.17 105.01 1433.47C104.86 1433.54 104.71 1433.6 104.55 1433.67L104.56 1433.68Z" fill="white"/>
                    <path d="M142.29 1428.87C142.16 1428.68 142.01 1428.56 141.98 1428.41C141.91 1428.03 142 1427.7 142.49 1427.72C142.65 1427.72 142.8 1427.82 142.96 1427.87C142.74 1428.2 142.53 1428.53 142.3 1428.87H142.29Z" fill="white"/>
                    <path d="M114.43 1334.39C114.59 1334.05 114.9 1334.15 115.15 1334.15C115.23 1334.15 115.4 1334.4 115.37 1334.48C115.25 1334.81 114.92 1334.71 114.68 1334.71C114.6 1334.71 114.52 1334.5 114.44 1334.4L114.43 1334.39Z" fill="white"/>
                    <path d="M31.2301 1273.57C31.5201 1273.46 31.7401 1273.33 31.9801 1273.31C32.1201 1273.3 32.2701 1273.46 32.4201 1273.54C32.2001 1273.65 31.9801 1273.8 31.7501 1273.85C31.6201 1273.87 31.4601 1273.7 31.2301 1273.58V1273.57Z" fill="white"/>
                    <path d="M28.3 1274.14C28.45 1274.14 28.7 1274.1 28.93 1274.16C29.2 1274.22 29.22 1274.42 28.95 1274.51C28.73 1274.59 28.46 1274.61 28.23 1274.56C27.96 1274.51 27.96 1274.34 28.3 1274.14Z" fill="white"/>
                    <path d="M120.47 1318.77C120.31 1318.72 120.04 1318.69 120.02 1318.61C119.94 1318.29 120.18 1318.2 120.45 1318.22C120.61 1318.24 120.88 1318.29 120.9 1318.38C120.98 1318.7 120.74 1318.78 120.47 1318.77Z" fill="white"/>
                    <path d="M78.3701 1456.03C78.6401 1455.99 78.8801 1456.03 78.8501 1456.36C78.8501 1456.45 78.5801 1456.54 78.4301 1456.58C78.1701 1456.64 77.9201 1456.58 77.9501 1456.26C77.9501 1456.17 78.2201 1456.11 78.3701 1456.04V1456.03Z" fill="white"/>
                    <path d="M69.5101 1448.32C69.7801 1448.28 70.0201 1448.32 69.9901 1448.65C69.9901 1448.74 69.7201 1448.83 69.5701 1448.87C69.3101 1448.93 69.0601 1448.87 69.0901 1448.55C69.0901 1448.46 69.3601 1448.4 69.5101 1448.33V1448.32Z" fill="white"/>
                    <path d="M33.1401 1424.83C33.0301 1424.54 32.9001 1424.32 32.8801 1424.08C32.8701 1423.94 33.0301 1423.79 33.1101 1423.64C33.2201 1423.86 33.3701 1424.08 33.4201 1424.31C33.4401 1424.44 33.2701 1424.6 33.1501 1424.83H33.1401Z" fill="white"/>
                    <path d="M101.24 1345.64C101.08 1345.59 100.81 1345.56 100.79 1345.48C100.71 1345.16 100.95 1345.07 101.22 1345.09C101.38 1345.11 101.65 1345.16 101.67 1345.25C101.75 1345.57 101.51 1345.65 101.24 1345.64Z" fill="white"/>
                    <path d="M13.3 1366.17C13.35 1366.01 13.38 1365.74 13.46 1365.72C13.78 1365.64 13.87 1365.88 13.85 1366.15C13.83 1366.31 13.78 1366.58 13.69 1366.6C13.37 1366.68 13.29 1366.44 13.3 1366.17Z" fill="white"/>
                    <path d="M11.4801 1351.87C11.4101 1351.75 11.3201 1351.64 11.2801 1351.51C11.2701 1351.47 11.3801 1351.38 11.4401 1351.32C11.5101 1351.44 11.6001 1351.55 11.6401 1351.68C11.6501 1351.72 11.5401 1351.81 11.4801 1351.87Z" fill="white"/>
                    <path d="M58.3401 1446.12C58.4001 1446 58.4501 1445.88 58.5101 1445.75C58.5801 1445.81 58.7001 1445.87 58.7001 1445.92C58.6801 1446.05 58.6101 1446.18 58.5501 1446.3C58.4801 1446.24 58.4201 1446.19 58.3401 1446.12Z" fill="white"/>
                    <path d="M100.5 1455.96C100.43 1456.04 100.37 1456.1 100.32 1456.17C100.25 1456.05 100.16 1455.94 100.12 1455.81C100.11 1455.77 100.22 1455.68 100.28 1455.62C100.35 1455.74 100.43 1455.85 100.5 1455.96Z" fill="white"/>
                    <path d="M20.22 1462.92C20.34 1462.98 20.46 1463.03 20.59 1463.09C20.53 1463.16 20.47 1463.28 20.42 1463.28C20.29 1463.26 20.16 1463.19 20.04 1463.13C20.1 1463.06 20.15 1463 20.22 1462.92Z" fill="white"/>
                    <path d="M38.4501 1463.44C38.5201 1463.36 38.5801 1463.3 38.6301 1463.23C38.7001 1463.35 38.7901 1463.46 38.8301 1463.59C38.8401 1463.63 38.7301 1463.72 38.6701 1463.78C38.6001 1463.66 38.5201 1463.55 38.4501 1463.44Z" fill="white"/>
                    <path d="M35.2101 1336.71C35.2701 1336.59 35.3201 1336.47 35.3801 1336.34C35.4501 1336.4 35.5701 1336.46 35.5701 1336.51C35.5501 1336.64 35.4801 1336.77 35.4201 1336.89C35.3501 1336.83 35.2901 1336.78 35.2101 1336.71Z" fill="white"/>
                    <path d="M8.23011 1375.89C8.11011 1375.83 7.99011 1375.78 7.86011 1375.72C7.92011 1375.65 7.98011 1375.53 8.03011 1375.53C8.16011 1375.55 8.29011 1375.62 8.41011 1375.68C8.35011 1375.75 8.30011 1375.81 8.23011 1375.89Z" fill="white"/>
                    <path d="M119.63 1310.51C119.56 1310.39 119.47 1310.28 119.43 1310.15C119.42 1310.11 119.53 1310.02 119.59 1309.96C119.66 1310.08 119.75 1310.19 119.79 1310.32C119.8 1310.36 119.69 1310.45 119.63 1310.51Z" fill="white"/>
                    <path d="M162.62 126.51C160.92 126.96 160.03 125.91 159.21 124.9C158.91 124.53 158.53 123.89 158.31 123.76C156.88 122.9 158.52 121.81 157.97 120.96C158.88 120.55 159.83 119.75 160.69 119.84C161.51 119.92 162.52 120.12 163.3 120.6C164.04 121.06 165.28 121.37 164.31 122.74C164.17 122.94 164.44 123.42 164.48 123.78C164.66 125.36 163.99 126.27 162.63 126.52L162.62 126.51Z" fill="white"/>
                    <path d="M233.23 2.64003C233.4 3.67003 232.72 4.54003 232.36 4.60003C230.96 4.82003 230.63 6.20003 229.6 6.80003C228.87 7.22003 228.36 7.37003 227.72 6.86003C226.66 6.03003 226.45 5.45003 226.88 4.57003C227.07 4.19003 227.2 3.81003 227.34 3.40003C227.91 1.65003 231.4 0.360034 232.79 1.94003C233.04 2.22003 233.25 2.45003 233.24 2.63003L233.23 2.64003Z" fill="white"/>
                    <path d="M153.32 31.94C152.77 32.68 153.21 33.95 151.98 34.27C151.81 34.32 151.59 34.28 151.46 34.38C150.92 34.78 150.49 35.41 149.67 35.16C149.03 34.96 148.82 34.33 148.47 33.86C147.77 32.93 148.02 31.3 148.96 30.43C149.69 29.75 150.48 29.63 151.44 29.96C152.44 30.29 152.73 31.25 153.33 31.94H153.32Z" fill="white"/>
                    <path d="M120.7 80.93C121.07 80.87 121.62 80.67 121.69 81.02C121.82 81.67 122.25 82.33 121.76 83.06C121.49 83.47 121 83.49 120.77 83.82C120.4 84.35 120.08 84.5 119.47 84.12C118.82 83.72 118.6 83.09 118.87 82.56C119.24 81.82 119.61 80.87 120.71 80.92L120.7 80.93Z" fill="white"/>
                    <path d="M177.71 6.42001C177.64 5.86001 177.22 5.19001 178.29 5.03001C178.53 4.99001 178.69 4.47001 178.9 4.19001C179.15 3.87001 179.42 3.57001 179.69 3.26001C179.86 3.84001 180.26 4.48001 180.14 4.99001C179.98 5.64001 179.37 6.17001 179 6.78001C178.79 7.11001 178.54 7.38001 178.17 7.30001C177.76 7.21001 177.79 6.76001 177.72 6.43001L177.71 6.42001Z" fill="white"/>
                    <path d="M37.7801 41.95C37.7501 41.78 37.6901 41.6 37.6901 41.43C37.6701 41.04 37.2801 40.54 37.8601 40.29C38.4501 40.03 39.0201 40.12 39.5401 40.58C39.9801 40.97 39.8301 41.28 39.5901 41.7C39.4401 41.97 39.5101 42.37 39.3701 42.64C39.1701 43 39.0101 43.49 38.4601 43.46C37.8201 43.42 38.0101 42.84 37.8801 42.47C37.8201 42.31 37.8101 42.13 37.7801 41.95Z" fill="white"/>
                    <path d="M188.48 49.12C188.31 48.66 188.42 48.33 189.01 48.25C189.93 48.12 190.54 48.51 190.73 49.32C190.9 50.04 190.52 50.52 190 50.8C189.4 51.12 188.67 50.3 188.48 49.12Z" fill="white"/>
                    <path d="M262.86 81.26C262.81 81 262.71 80.73 262.72 80.47C262.74 79.85 263.42 79.53 263.92 79.87C264.22 80.07 264.56 80.23 264.8 80.49C265.1 80.82 265.23 81.35 265.03 81.7C264.81 82.11 264.12 82.12 263.91 82.65C263.54 82.31 262.88 82.22 262.9 81.53C262.9 81.44 262.87 81.35 262.85 81.27L262.86 81.26Z" fill="white"/>
                    <path d="M74.0401 91.35C73.5101 91.35 73.14 91 72.84 90.6C72.66 90.36 73.6701 89.27 74.2101 89.12C74.6901 88.98 74.8001 89.31 74.8601 89.68C74.9801 90.41 74.5801 91.25 74.0401 91.36V91.35Z" fill="white"/>
                    <path d="M93.4001 3.55002C93.3301 3.69002 93.2901 3.92002 93.1701 3.97002C92.5301 4.26002 91.1501 3.53002 91.0801 2.86002C91.0601 2.63002 91.0801 2.28002 91.2301 2.18002C91.3801 2.07002 91.7301 2.10002 91.9401 2.20002C92.8301 2.64002 93.3101 3.11002 93.4001 3.55002Z" fill="white"/>
                    <path d="M148.78 87.78C149.04 87.73 149.3 87.64 149.56 87.64C150.23 87.66 150.04 88.25 150.15 88.62C150.3 89.1 149.96 89.22 149.6 89.28C149.27 89.34 148.94 89.46 148.61 89.2C148.27 88.93 147.79 88.74 147.91 88.22C148 87.81 148.45 87.86 148.78 87.78Z" fill="white"/>
                    <path d="M116.27 104.75C116.33 105.11 116.38 105.51 115.88 105.52C115.23 105.53 114.66 105.22 114.62 104.55C114.6 104.2 114.99 103.82 115.2 103.45C115.66 103.8 116.32 103.99 116.27 104.75Z" fill="white"/>
                    <path d="M243.83 40.72C243.19 40.8 242.39 40.19 242.38 39.74C242.38 39.26 242.76 39.27 243.09 39.19C243.75 39.03 244.26 39.31 244.46 39.86C244.52 40.03 244.17 40.65 243.83 40.72Z" fill="white"/>
                    <path d="M221.08 86.8701C221.72 86.9401 221.57 87.4901 221.65 87.8701C221.81 88.5701 221.2 88.4701 220.83 88.5601C220.48 88.6501 220.11 88.6801 220.07 88.1701C220.02 87.6401 220.62 86.8901 221.08 86.8701Z" fill="white"/>
                    <path d="M55.0001 33.86C54.8201 34.24 54.7201 35.01 54.2301 34.77C53.8101 34.56 53.1601 34.94 52.9801 34.33C52.8801 33.96 54.0701 33.19 54.5801 33.29C54.8501 33.34 55.0001 33.56 55.0001 33.87V33.86Z" fill="white"/>
                    <path d="M257.07 15.23C257.01 15.87 256.45 15.69 256.09 15.81C255.63 15.95 255.39 15.62 255.46 15.28C255.54 14.89 255.81 14.51 256.07 14.18C256.16 14.06 257.08 14.99 257.08 15.23H257.07Z" fill="white"/>
                    <path d="M145.47 136.32C145.03 136.5 144.8 136.32 144.76 136.07C144.64 135.39 145 134.95 145.6 134.68C145.91 134.54 146.13 134.73 146.19 134.99C146.34 135.69 145.92 136.09 145.48 136.33L145.47 136.32Z" fill="white"/>
                    <path d="M227.99 103.21C227.75 103.29 226.59 102.76 226.68 102.63C226.9 102.27 227.15 101.89 227.49 101.68C227.8 101.48 228.17 101.63 228.2 102.1C228.22 102.48 228.61 102.94 228 103.21H227.99Z" fill="white"/>
                    <path d="M168.14 100.12C168.76 99.9 169.05 100.47 169.49 100.67C169.31 100.95 169.16 101.44 168.94 101.47C168.45 101.55 168.06 101.22 167.87 100.73C167.75 100.43 167.91 100.26 168.14 100.12Z" fill="white"/>
                    <path d="M97.1801 112.09C96.8401 112.91 96.3301 113.17 95.6801 113.18C95.5301 113.18 95.3801 113.06 95.2301 113C95.3301 112.86 95.4601 112.72 95.5201 112.57C95.7501 111.96 96.3101 111.95 96.8101 111.9C96.9601 111.88 97.1401 112.07 97.1901 112.1L97.1801 112.09Z" fill="white"/>
                    <path d="M194.45 63.3501C194.39 64.2101 193.64 63.8001 193.21 63.9701C193.02 64.0501 192.79 63.6801 192.82 63.4001C192.9 62.5901 193.62 62.8901 194.05 62.7701C194.26 62.7101 194.45 63.0401 194.44 63.3501H194.45Z" fill="white"/>
                    <path d="M74.1401 47.6101C74.1701 47.7401 73.8601 47.8701 73.4801 47.9101C73.0901 47.9501 72.6001 48.2701 72.4401 47.5601C72.3301 47.0601 72.6401 46.9501 73.0101 46.9001C73.4001 46.8501 73.8901 46.5601 74.1401 47.6101Z" fill="white"/>
                    <path d="M84.5801 62.21C84.6301 62.58 84.7001 62.92 84.1801 63C83.4701 63.11 83.5701 62.55 83.4701 62.17C83.3801 61.82 83.3501 61.48 83.8601 61.39C84.5701 61.27 84.4501 61.85 84.5701 62.21H84.5801Z" fill="white"/>
                    <path d="M165.11 47.2401C165.97 47.3001 165.56 48.05 165.73 48.48C165.81 48.67 165.44 48.9 165.16 48.87C164.35 48.79 164.65 48.07 164.53 47.64C164.47 47.43 164.8 47.24 165.11 47.25V47.2401Z" fill="white"/>
                    <path d="M103.19 90.78C103.15 90.92 103.09 91.15 103.03 91.39C102.64 91.1 102.04 91 102 90.37C101.98 90.1 102.11 89.91 102.44 89.86C103.14 89.75 103.04 90.3 103.19 90.78Z" fill="white"/>
                    <path d="M164.48 3.52004C164.51 3.69004 164.59 3.88004 164.56 4.04004C164.54 4.15004 164.35 4.32004 164.27 4.31004C163.98 4.25004 163.65 2.62004 163.89 2.40004C163.95 2.35004 164.24 2.42004 164.26 2.49004C164.36 2.83004 164.41 3.18004 164.47 3.53004L164.48 3.52004Z" fill="white"/>
                    <path d="M19.4601 26.58C19.6001 27.12 19.2901 27.26 18.9201 27.29C18.6501 27.31 18.4601 27.18 18.4001 26.85C18.3101 26.36 18.6001 26.22 18.9701 26.2C19.2301 26.18 19.5001 26.27 19.4701 26.58H19.4601Z" fill="white"/>
                    <path d="M220.16 82.75C220.13 83.04 220.04 83.22 219.71 83.28C219.22 83.37 219.08 83.08 219.06 82.71C219.04 82.44 219.17 82.25 219.5 82.19C219.99 82.1 220.09 82.39 220.16 82.74V82.75Z" fill="white"/>
                    <path d="M121.61 112.17C121.28 112.23 120.97 112.24 120.87 111.75C120.81 111.42 120.93 111.23 121.19 111.15C121.55 111.04 121.86 111.06 121.95 111.55C122.01 111.88 121.88 112.03 121.6 112.16L121.61 112.17Z" fill="white"/>
                    <path d="M246.55 58.9701C246.67 59.2701 246.49 59.4601 246.23 59.5401C245.87 59.6501 245.56 59.6301 245.47 59.1401C245.41 58.8101 245.53 58.6201 245.79 58.5401C246.15 58.4301 246.49 58.4501 246.55 58.9701Z" fill="white"/>
                    <path d="M252.89 13.5301C252.94 13.8801 253 14.2301 252.49 14.2801C252.34 14.2901 252.17 14.2301 252.02 14.2101C252.19 13.8701 252.37 13.5201 252.54 13.1801C252.65 13.3001 252.77 13.4101 252.89 13.5301Z" fill="white"/>
                    <path d="M157.59 48.66C157.38 48.62 157.1 48.62 157.09 48.56C156.99 48.1 157.45 47.89 157.61 47.54C157.72 47.66 157.89 47.76 157.94 47.9C158.05 48.25 158.02 48.57 157.6 48.66H157.59Z" fill="white"/>
                    <path d="M149.18 133.65C149.13 133.77 149.1 133.98 149.01 134.01C148.69 134.13 148.3 134.22 148.19 133.76C148.14 133.55 148.27 133.29 148.32 133.06C148.61 133.26 148.89 133.45 149.19 133.66L149.18 133.65Z" fill="white"/>
                    <path d="M161.31 109.67C161.79 109.56 161.9 109.89 161.93 110.24C161.94 110.38 161.82 110.54 161.75 110.69C161.46 110.43 161.17 110.17 160.89 109.92C161.03 109.84 161.17 109.76 161.31 109.67Z" fill="white"/>
                    <path d="M205.69 111.36C205.63 111.52 205.57 111.67 205.52 111.82C205.22 111.58 204.92 111.34 204.62 111.09C204.75 111 204.88 110.88 205.02 110.83C205.48 110.69 205.61 110.99 205.68 111.35L205.69 111.36Z" fill="white"/>
                    <path d="M50.2901 70.8C50.1701 71.14 49.8601 71.08 49.6201 71.11C49.5501 71.11 49.3501 70.9 49.3701 70.82C49.4501 70.49 49.7801 70.55 50.0201 70.52C50.1001 70.52 50.2001 70.7 50.2901 70.8Z" fill="white"/>
                    <path d="M138.03 120.43C137.76 120.57 137.56 120.72 137.33 120.77C137.2 120.8 137.03 120.66 136.88 120.6C137.08 120.47 137.27 120.3 137.5 120.23C137.62 120.19 137.8 120.34 138.03 120.44V120.43Z" fill="white"/>
                    <path d="M140.8 119.55C140.66 119.56 140.42 119.63 140.19 119.6C139.92 119.57 139.88 119.38 140.13 119.26C140.34 119.16 140.6 119.11 140.82 119.13C141.09 119.15 141.11 119.31 140.8 119.55Z" fill="white"/>
                    <path d="M46.19 86.66C46.35 86.69 46.61 86.69 46.64 86.77C46.75 87.07 46.53 87.19 46.27 87.19C46.11 87.19 45.85 87.17 45.82 87.09C45.71 86.79 45.93 86.69 46.19 86.66Z" fill="white"/>
                    <path d="M208.09 101.91C208.23 102.18 208.38 102.38 208.43 102.61C208.46 102.74 208.32 102.91 208.26 103.06C208.13 102.86 207.96 102.67 207.89 102.44C207.85 102.32 208 102.14 208.1 101.91H208.09Z" fill="white"/>
                    <path d="M248.39 92.21C248.13 92.28 247.89 92.27 247.89 91.94C247.89 91.85 248.13 91.74 248.28 91.68C248.53 91.59 248.78 91.62 248.78 91.94C248.78 92.03 248.53 92.12 248.4 92.2L248.39 92.21Z" fill="white"/>
                    <path d="M250.17 31.25C249.91 31.32 249.67 31.31 249.67 30.99C249.67 30.9 249.91 30.79 250.06 30.73C250.31 30.64 250.56 30.67 250.56 30.99C250.56 31.08 250.31 31.17 250.18 31.25H250.17Z" fill="white"/>
                    <path d="M61.82 58.3701C61.98 58.4001 62.24 58.4001 62.27 58.4801C62.38 58.7801 62.16 58.9001 61.9 58.9001C61.74 58.9001 61.48 58.8801 61.45 58.8001C61.34 58.5001 61.56 58.4001 61.82 58.3701Z" fill="white"/>
                    <path d="M144.92 28.44C144.89 28.6 144.89 28.86 144.81 28.89C144.51 29 144.39 28.78 144.39 28.52C144.39 28.36 144.41 28.1 144.49 28.07C144.79 27.96 144.89 28.18 144.92 28.44Z" fill="white"/>
                    <path d="M148.31 42.1201C148.39 42.2301 148.49 42.3301 148.54 42.4501C148.56 42.4901 148.45 42.5901 148.41 42.6601C148.33 42.5501 148.23 42.4501 148.18 42.3301C148.16 42.2901 148.27 42.1901 148.31 42.1201Z" fill="white"/>
                    <path d="M210.3 119.76C210.38 119.87 210.48 119.97 210.53 120.09C210.55 120.13 210.44 120.23 210.4 120.3C210.32 120.19 210.22 120.09 210.17 119.97C210.15 119.93 210.26 119.83 210.3 119.76Z" fill="white"/>
                    <path d="M126.98 59.55C126.94 59.67 126.9 59.8 126.86 59.93C126.79 59.88 126.66 59.83 126.66 59.79C126.66 59.66 126.72 59.53 126.76 59.4C126.83 59.45 126.9 59.5 126.98 59.55Z" fill="white"/>
                    <path d="M210.22 27.38C210.18 27.5 210.14 27.63 210.1 27.76C210.03 27.71 209.9 27.66 209.9 27.62C209.9 27.49 209.96 27.36 210 27.23C210.07 27.28 210.14 27.33 210.23 27.38H210.22Z" fill="white"/>
                    <path d="M148.74 18.42C148.86 18.46 148.99 18.5 149.12 18.54C149.07 18.61 149.02 18.74 148.98 18.74C148.85 18.74 148.72 18.68 148.59 18.64C148.64 18.57 148.69 18.5 148.74 18.42Z" fill="white"/>
                    <path d="M47.95 94.6C48.03 94.71 48.13 94.81 48.18 94.93C48.2 94.97 48.09 95.07 48.05 95.14C47.97 95.03 47.87 94.93 47.82 94.81C47.8 94.77 47.91 94.67 47.95 94.6Z" fill="white"/>
                    <path d="M116.14 68.46C115.03 66.74 115.87 65.38 116.69 64.11C116.99 63.65 117.56 63 117.63 62.71C118.06 60.82 119.87 62.24 120.6 61.32C121.38 62.18 122.61 62.94 122.82 63.92C123.02 64.85 123.17 66.04 122.92 67.08C122.68 68.06 122.78 69.55 120.92 68.96C120.64 68.87 120.21 69.34 119.83 69.52C118.15 70.28 116.9 69.88 116.13 68.46H116.14Z" fill="white"/>
                    <path d="M278.55 101.92C277.48 102.48 276.26 102.04 276.07 101.66C275.32 100.19 273.68 100.32 272.64 99.4C271.91 98.75 271.57 98.24 271.89 97.35C272.43 95.88 272.99 95.44 274.12 95.6C274.61 95.67 275.08 95.68 275.58 95.68C277.72 95.68 280.41 99.07 279.15 101.18C278.93 101.55 278.75 101.87 278.55 101.92Z" fill="white"/>
                    <path d="M217.37 24.11C216.36 23.77 215.11 24.71 214.31 23.46C214.2 23.28 214.15 23.03 214 22.92C213.36 22.47 212.51 22.22 212.5 21.22C212.5 20.44 213.11 19.98 213.5 19.42C214.28 18.31 216.16 18 217.47 18.72C218.48 19.28 218.9 20.11 218.89 21.29C218.89 22.52 217.93 23.18 217.38 24.1L217.37 24.11Z" fill="white"/>
                    <path d="M382.4 12.04C381.71 12.49 380.74 13.16 379.83 12.2C379.4 11.75 378.58 10.89 379.13 10.33C379.73 9.71997 379.89 8.53997 381.02 8.52997C381.8 8.51997 382.63 8.77997 383.35 8.58997C384.01 8.41997 384.24 8.53997 384.54 9.06997C384.97 9.82997 384.75 10.5 384.23 11C383.79 11.42 383.15 11.63 382.4 12.04Z" fill="white"/>
                    <path d="M151.44 5.67999C151.64 6.06999 152.06 6.59999 151.7 6.79999C151.03 7.17999 150.46 7.88999 149.47 7.60999C148.92 7.44999 148.72 6.91999 148.27 6.77999C147.54 6.55999 147.27 6.25999 147.47 5.45999C147.68 4.58999 148.3 4.12999 148.97 4.22999C149.93 4.36999 151.1 4.43999 151.45 5.66999L151.44 5.67999Z" fill="white"/>
                    <path d="M254.37 41.88C254.97 41.6 255.56 40.89 256.11 42.02C256.24 42.27 256.87 42.26 257.27 42.4C257.71 42.56 258.14 42.76 258.58 42.94C258 43.34 257.44 44.01 256.83 44.06C256.05 44.12 255.25 43.64 254.44 43.44C254 43.33 253.61 43.15 253.57 42.7C253.52 42.21 254.03 42.09 254.37 41.89V41.88Z" fill="white"/>
                    <path d="M211.04 69.18C211.49 68.83 211.89 68.83 212.19 69.46C212.66 70.43 212.45 71.24 211.63 71.74C210.9 72.19 210.23 71.94 209.73 71.47C209.16 70.92 209.81 69.82 211.04 69.18Z" fill="white"/>
                    <path d="M202.28 163C202.55 162.85 202.81 162.65 203.11 162.56C203.8 162.36 204.4 162.99 204.2 163.68C204.08 164.08 204.03 164.52 203.83 164.87C203.57 165.32 203.04 165.65 202.57 165.57C202.03 165.47 201.78 164.72 201.12 164.68C201.36 164.14 201.23 163.39 202 163.16C202.1 163.13 202.18 163.06 202.28 163.01V163Z" fill="white"/>
                    <path d="M341.77 10.98C341.38 10.47 341.31 10.06 341.96 9.73996C342.75 9.34996 343.8 9.46996 344.1 10.02C344.4 10.57 343.93 11.55 343.16 11.96C342.01 12.58 342.18 11.33 341.77 10.98Z" fill="white"/>
                    <path d="M341.46 18.64C340.89 18.61 340.25 18.61 339.9 18.06C339.51 17.46 340.25 16.22 341.07 15.98C341.61 15.82 341.77 16.2 341.93 16.57C342.3 17.42 342.07 18.41 341.46 18.65V18.64Z" fill="white"/>
                    <path d="M355.9 106.38C355.51 105.76 355.86 105.4 356.25 105.36C357.03 105.3 357.82 105.38 358.6 105.47C359.33 105.55 358.87 106.03 358.83 106.35C358.64 107.73 357.5 106.78 356.87 107.12C356.13 107.5 356.18 106.58 355.9 106.39V106.38Z" fill="white"/>
                    <path d="M386.72 59.02C387.04 59.43 387.03 59.82 386.41 60.12C385.62 60.49 384.6 60.37 384.29 59.83C383.77 58.92 385.26 58.92 385.09 58.1C385.06 57.96 386.29 58.35 386.73 59.02H386.72Z" fill="white"/>
                    <path d="M153.98 39.2C154.13 39.47 154.33 39.73 154.41 40.01C154.63 40.75 153.91 40.76 153.54 41.02C153.06 41.36 152.81 41.02 152.61 40.64C152.43 40.29 152.17 39.98 152.34 39.52C152.52 39.05 152.55 38.45 153.17 38.39C153.66 38.34 153.76 38.86 153.97 39.2H153.98Z" fill="white"/>
                    <path d="M394.69 55.2C394.46 55.89 394.41 56.59 393.6 56.93C392.93 57.21 392.73 56.91 392.46 56.43C392.16 55.91 392.14 55.61 392.7 55.21C393.44 54.68 394.03 55.12 394.7 55.21L394.69 55.2Z" fill="white"/>
                    <path d="M383.26 66.15C383.07 66.14 382.78 66.19 382.7 66.09C382.46 65.77 382.11 65.37 382.14 65.03C382.18 64.48 382.18 63.77 382.87 63.5C383.4 63.29 383.58 63.71 383.73 64.08C384.1 64.96 383.88 65.86 383.26 66.15Z" fill="white"/>
                    <path d="M123.5 9.37C123.12 9.56 122.7 9.77 122.51 9.21C122.26 8.49 122.4 7.75 123.12 7.46C123.5 7.31 124.06 7.61 124.54 7.71C124.32 8.35 124.34 9.14 123.49 9.37H123.5Z" fill="white"/>
                    <path d="M240.26 127.36C239.94 126.68 240.33 125.58 240.83 125.41C241.35 125.23 241.48 125.66 241.69 125.99C242.11 126.66 241.98 127.33 241.44 127.75C241.28 127.88 240.46 127.72 240.26 127.36Z" fill="white"/>
                    <path d="M319.13 135.67C318.42 136.16 318.18 135.83 317.85 135.33C317.34 134.55 317.75 134.28 318.36 133.94C318.99 133.59 319.22 133.87 319.54 134.38C320.01 135.14 319.76 135.51 319.13 135.67Z" fill="white"/>
                    <path d="M181.03 118.83C181.18 119.56 180.52 119.59 180.13 119.83C179.41 120.26 179.31 119.55 179.07 119.17C178.84 118.82 178.68 118.42 179.23 118.18C179.8 117.94 180.84 118.32 181.03 118.83Z" fill="white"/>
                    <path d="M329.44 44.94C329.2 44.45 329 44.07 329.55 43.83C330.12 43.59 331.18 43.97 331.34 44.48C331.51 45.01 331.04 45.11 330.73 45.35C329.96 45.92 329.65 45.45 329.43 44.94H329.44Z" fill="white"/>
                    <path d="M307.88 119.69C307.88 119.69 307.61 120.18 307.31 120.58C307.18 120.75 305.9 120.11 305.81 119.82C305.59 119.08 306.3 119.08 306.66 118.82C307.1 118.5 307.38 118.74 307.88 119.68V119.69Z" fill="white"/>
                    <path d="M273.22 132.81C272.49 132.97 272.48 132.3 272.23 131.93C271.9 131.47 272.18 131.08 272.59 131.04C273.06 130.99 273.57 131.15 274.02 131.32C274.18 131.38 273.49 132.73 273.22 132.81Z" fill="white"/>
                    <path d="M99.1201 53.03C98.7601 52.6 98.8801 52.29 99.1401 52.15C99.8501 51.77 100.47 52.01 100.98 52.58C101.24 52.87 101.12 53.18 100.85 53.34C100.13 53.76 99.5401 53.44 99.1101 53.04L99.1201 53.03Z" fill="white"/>
                    <path d="M165.45 132.35C165.27 132.11 165.45 130.65 165.62 130.69C166.09 130.8 166.61 130.94 166.97 131.24C167.3 131.51 167.26 131.97 166.76 132.17C166.35 132.34 165.97 132.92 165.46 132.35H165.45Z" fill="white"/>
                    <path d="M147.31 65.0599C147.78 65.6599 147.25 66.19 147.18 66.75C146.8 66.65 146.21 66.66 146.09 66.43C145.83 65.92 146.05 65.37 146.52 64.98C146.8 64.74 147.06 64.8599 147.3 65.0599H147.31Z" fill="white"/>
                    <path d="M309.75 112.62C309.6 112.2 309.06 111.78 309.79 111.36C310.31 111.06 310.53 111.39 310.72 111.77C310.92 112.18 311.42 112.61 310.7 113.04C310.17 113.35 309.98 113 309.76 112.63L309.75 112.62Z" fill="white"/>
                    <path d="M320.18 143.05C320.05 143.13 319.79 142.83 319.61 142.43C319.42 142.02 318.89 141.59 319.61 141.16C320.12 140.86 320.36 141.16 320.54 141.55C320.74 141.96 321.24 142.4 320.17 143.05H320.18Z" fill="white"/>
                    <path d="M319.47 76.36C319.47 76.22 319.43 75.97 319.51 75.91C320.09 75.51 320.68 75.15 321.45 75.53C321.77 75.69 321.93 75.82 321.72 76.11C321.39 76.56 319.83 76.72 319.48 76.36H319.47Z" fill="white"/>
                    <path d="M197.45 80.91C196.48 81.16 196.66 80.17 196.32 79.76C196.17 79.57 196.49 79.19 196.81 79.13C197.73 78.93 197.66 79.83 197.95 80.27C198.09 80.48 197.79 80.81 197.45 80.91Z" fill="white"/>
                    <path d="M295.7 8.47996C294.73 8.72996 294.91 7.73996 294.57 7.32996C294.42 7.13996 294.74 6.75996 295.06 6.69996C295.98 6.49996 295.91 7.39996 296.2 7.83996C296.34 8.04996 296.04 8.37996 295.7 8.47996Z" fill="white"/>
                    <path d="M323.07 27.23C322.62 27.56 322.18 27.67 322.08 27.43C321.86 26.95 321.07 26.52 321.74 25.86C321.97 25.63 322.41 25.55 322.55 25.8C322.82 26.26 323.41 26.72 323.08 27.24L323.07 27.23Z" fill="white"/>
                    <path d="M204.69 42.66C204.94 43.63 203.95 43.45 203.54 43.79C203.35 43.94 202.97 43.62 202.91 43.3C202.71 42.38 203.61 42.45 204.05 42.16C204.26 42.02 204.59 42.32 204.69 42.66Z" fill="white"/>
                    <path d="M379.27 50.75C379.68 51.35 379.57 51.61 379.3 51.79C378.69 52.21 378.4 51.82 378.19 51.31C378.08 51.05 377.74 50.57 377.99 50.54C378.49 50.47 379.04 50.7 379.28 50.75H379.27Z" fill="white"/>
                    <path d="M413.93 19.21C413.33 19.64 412.81 19.13 412.24 19.04C412.42 18.82 412.56 18.54 412.79 18.41C413.18 18.19 413.61 17.7 414.04 18.41C414.24 18.73 414.15 18.96 413.92 19.21H413.93Z" fill="white"/>
                    <path d="M252.82 26.21C252.64 26.31 252.47 26.46 252.28 26.49C252.15 26.51 251.89 26.36 251.88 26.27C251.84 25.93 253.53 24.98 253.86 25.16C253.94 25.2 253.96 25.56 253.9 25.6C253.56 25.83 253.19 26.01 252.83 26.21H252.82Z" fill="white"/>
                    <path d="M401.89 35.85C401.56 35.92 401.32 35.89 401.14 35.55C400.87 35.04 401.14 34.79 401.53 34.62C401.82 34.5 402.08 34.57 402.26 34.92C402.54 35.43 402.25 35.65 401.89 35.85Z" fill="white"/>
                    <path d="M185.26 116.32C184.93 116.39 184.69 116.36 184.51 116.02C184.24 115.51 184.51 115.26 184.9 115.09C185.19 114.97 185.45 115.04 185.63 115.39C185.91 115.9 185.62 116.12 185.26 116.32Z" fill="white"/>
                    <path d="M117.22 17.95C117.04 17.6 116.91 17.27 117.42 16.98C117.76 16.79 118.01 16.86 118.2 17.12C118.45 17.47 118.54 17.83 118.03 18.11C117.69 18.3 117.47 18.21 117.22 17.94V17.95Z" fill="white"/>
                    <path d="M332.81 123.43C332.86 124.01 332.77 124.54 332.28 124.9C332.15 125 331.76 124.99 331.76 124.98C331.64 124.4 331.74 123.87 332.25 123.52C332.38 123.43 332.61 123.46 332.82 123.43H332.81Z" fill="white"/>
                    <path d="M221.06 136.94C220.77 137.18 220.49 137.05 220.31 136.79C220.06 136.44 219.97 136.08 220.48 135.8C220.82 135.61 221.07 135.68 221.26 135.94C221.51 136.3 221.61 136.68 221.06 136.93V136.94Z" fill="white"/>
                    <path d="M315.19 54.56C314.86 54.63 314.62 54.6 314.44 54.26C314.17 53.75 314.44 53.5 314.83 53.33C315.12 53.21 315.38 53.28 315.56 53.63C315.84 54.14 315.55 54.36 315.19 54.56Z" fill="white"/>
                    <path d="M316.97 164.16C317.33 163.98 317.71 163.79 317.94 164.33C318.01 164.5 318 164.7 318.03 164.88C317.59 164.81 317.14 164.74 316.7 164.67C316.79 164.5 316.88 164.33 316.97 164.16Z" fill="white"/>
                    <path d="M273.6 127.58C273.24 127.76 272.86 127.95 272.63 127.41C272.56 127.24 272.57 127.04 272.54 126.86C272.98 126.93 273.43 127 273.87 127.07C273.78 127.24 273.69 127.41 273.6 127.58Z" fill="white"/>
                    <path d="M379.35 27.96C378.94 27.85 378.55 27.74 378.17 27.64C378.35 27.42 378.49 27.12 378.71 27.01C379.21 26.78 379.41 27.2 379.54 27.58C379.58 27.68 379.41 27.85 379.35 27.96Z" fill="white"/>
                    <path d="M200.41 34.86C200.38 34.62 200.27 34.31 200.34 34.27C200.81 33.99 201.21 34.43 201.66 34.47C201.57 34.64 201.52 34.86 201.38 34.96C201.03 35.21 200.66 35.29 200.42 34.86H200.41Z" fill="white"/>
                    <path d="M103.4 56.17C103.24 56.15 103.01 56.2 102.94 56.11C102.7 55.8 102.45 55.4 102.92 55.11C103.13 54.98 103.46 55.03 103.74 55C103.63 55.39 103.51 55.77 103.4 56.18V56.17Z" fill="white"/>
                    <path d="M134.29 60.94C134.58 61.43 134.26 61.67 133.88 61.83C133.72 61.89 133.51 61.81 133.32 61.79C133.5 61.38 133.68 60.97 133.86 60.56C134 60.68 134.14 60.81 134.29 60.94Z" fill="white"/>
                    <path d="M353.82 29.69C353.74 29.69 353.5 29.74 353.43 29.66C353.19 29.35 352.94 28.96 353.39 28.65C353.6 28.51 353.93 28.56 354.21 28.52C354.08 28.9 353.95 29.27 353.82 29.69Z" fill="white"/>
                    <path d="M317.28 47.68C316.79 47.97 316.55 47.65 316.39 47.27C316.33 47.11 316.41 46.9 316.43 46.71C316.84 46.89 317.25 47.07 317.66 47.25C317.54 47.39 317.41 47.53 317.28 47.68Z" fill="white"/>
                    <path d="M148.41 110.63C148.21 110.63 148.03 110.61 147.84 110.61C148 110.19 148.16 109.78 148.32 109.36C148.47 109.48 148.65 109.57 148.75 109.71C149.07 110.17 148.79 110.42 148.41 110.63Z" fill="white"/>
                    <path d="M404.02 -0.0800115C403.77 0.169989 403.6 0.409989 403.37 0.549989C403.24 0.629989 403 0.539988 402.81 0.519988C402.99 0.299988 403.14 0.0399885 403.36 -0.110011C403.48 -0.190011 403.73 -0.100011 404.02 -0.0700115V-0.0800115Z" fill="white"/>
                    <path d="M114 39.07C113.75 38.82 113.51 38.65 113.37 38.42C113.29 38.29 113.38 38.05 113.4 37.86C113.62 38.04 113.88 38.19 114.03 38.41C114.11 38.53 114.02 38.78 113.99 39.07H114Z" fill="white"/>
                    <path d="M115.97 41.83C115.9 41.68 115.74 41.44 115.69 41.17C115.63 40.86 115.83 40.75 116.04 40.98C116.22 41.17 116.37 41.44 116.43 41.7C116.5 42.01 116.33 42.09 115.96 41.83H115.97Z" fill="white"/>
                    <path d="M394.9 34.64C394.71 34.66 394.42 34.76 394.36 34.69C394.13 34.4 394.33 34.19 394.62 34.09C394.79 34.03 395.1 33.96 395.16 34.04C395.39 34.33 395.18 34.52 394.9 34.65V34.64Z" fill="white"/>
                    <path d="M159.72 109.88C159.47 110.13 159.3 110.37 159.07 110.51C158.94 110.59 158.7 110.5 158.51 110.48C158.69 110.26 158.84 110 159.06 109.85C159.18 109.77 159.43 109.86 159.72 109.89V109.88Z" fill="white"/>
                    <path d="M184.97 150.95C184.8 150.69 184.73 150.42 185.08 150.3C185.17 150.27 185.39 150.49 185.5 150.63C185.69 150.87 185.75 151.15 185.39 151.28C185.3 151.31 185.11 151.07 184.96 150.95H184.97Z" fill="white"/>
                    <path d="M364.63 29C364.39 29 364.11 29.01 363.83 29.02C363.92 28.85 363.97 28.59 364.11 28.53C364.36 28.43 364.66 28.44 364.94 28.41C364.85 28.58 364.76 28.75 364.63 29Z" fill="white"/>
                    <path d="M267.1 52.12C266.96 52.11 266.77 52.09 266.58 52.08C266.76 51.86 266.91 51.6 267.13 51.45C267.25 51.37 267.5 51.46 267.69 51.48C267.63 51.8 267.47 52.03 267.1 52.13V52.12Z" fill="white"/>
                    <path d="M283.33 98.26C283.08 98.51 282.91 98.75 282.68 98.89C282.55 98.97 282.31 98.88 282.12 98.86C282.3 98.64 282.45 98.38 282.67 98.23C282.79 98.15 283.04 98.2399 283.33 98.2699V98.26Z" fill="white"/>
                    <path d="M309.33 170.4C308.91 170.4 308.86 170.03 308.74 169.77C308.7 169.69 308.88 169.4 308.97 169.39C309.36 169.36 309.42 169.74 309.53 170C309.57 170.08 309.39 170.27 309.32 170.4H309.33Z" fill="white"/>
                    <path d="M253.01 130.95C252.84 130.69 252.77 130.42 253.12 130.3C253.21 130.27 253.43 130.49 253.54 130.63C253.73 130.87 253.79 131.15 253.43 131.28C253.34 131.31 253.15 131.07 253 130.95H253.01Z" fill="white"/>
                    <path d="M218.21 13.55C218.02 13.57 217.73 13.67 217.67 13.6C217.44 13.31 217.64 13.1 217.93 13C218.1 12.94 218.41 12.87 218.47 12.95C218.7 13.24 218.5 13.43 218.21 13.56V13.55Z" fill="white"/>
                    <path d="M204.3 22.24C204.21 22.37 204.14 22.52 204.02 22.62C203.98 22.66 203.84 22.57 203.74 22.55C203.83 22.42 203.9 22.27 204.02 22.17C204.06 22.14 204.2 22.22 204.3 22.24Z" fill="white"/>
                    <path d="M140.78 118.75C140.69 118.88 140.62 119.03 140.5 119.13C140.46 119.17 140.32 119.08 140.22 119.06C140.31 118.93 140.38 118.78 140.5 118.68C140.54 118.64 140.68 118.73 140.78 118.75Z" fill="white"/>
                    <path d="M299.73 7.01001C299.6 6.92001 299.45 6.85001 299.35 6.73001C299.31 6.69001 299.4 6.55001 299.42 6.45001C299.55 6.54001 299.7 6.61001 299.8 6.73001C299.83 6.77001 299.75 6.91001 299.73 7.01001Z" fill="white"/>
                    <path d="M319.71 54.49C319.74 54.38 319.77 54.28 319.8 54.19C319.93 54.28 320.08 54.35 320.18 54.47C320.22 54.51 320.13 54.65 320.11 54.75C319.98 54.67 319.85 54.58 319.72 54.5L319.71 54.49Z" fill="white"/>
                    <path d="M382.31 43.58C382.16 43.58 382 43.58 381.85 43.58C381.88 43.48 381.89 43.32 381.94 43.31C382.09 43.27 382.25 43.29 382.41 43.28C382.38 43.37 382.35 43.47 382.32 43.58H382.31Z" fill="white"/>
                    <path d="M266.67 9.39996C266.78 9.42996 266.88 9.45996 266.97 9.48996C266.88 9.61996 266.81 9.76996 266.69 9.86996C266.65 9.89996 266.51 9.81996 266.41 9.79996C266.49 9.66996 266.58 9.53996 266.66 9.40996L266.67 9.39996Z" fill="white"/>
                    <path d="M328.77 148.79C328.74 148.89 328.71 149.06 328.69 149.06C328.53 149.06 328.35 149.06 328.23 148.99C327.99 148.82 328.04 148.64 328.34 148.63C328.48 148.63 328.63 148.74 328.78 148.8L328.77 148.79Z" fill="white"/>
                    <path d="M327.84 148.88C327.7 148.82 327.44 148.78 327.43 148.69C327.38 148.44 327.53 148.31 327.7 148.41C327.85 148.49 327.96 148.63 328.09 148.75C328.01 148.79 327.93 148.84 327.84 148.88Z" fill="white"/>
                    <path d="M177.34 4.93C177.19 4.93 177.03 4.93 176.88 4.93C176.91 4.84 176.92 4.67 176.97 4.66C177.12 4.62 177.28 4.64 177.44 4.63C177.41 4.72 177.38 4.82 177.35 4.93H177.34Z" fill="white"/>
                    <path d="M408.53 104.63C408.38 104.63 408.22 104.63 408.07 104.63C408.1 104.54 408.11 104.37 408.16 104.36C408.31 104.32 408.47 104.34 408.63 104.33C408.6 104.42 408.57 104.52 408.54 104.63H408.53Z" fill="white"/>
                    <path d="M327.15 157.06C327.04 157.03 326.94 157 326.85 156.97C326.94 156.84 327.01 156.69 327.13 156.59C327.17 156.55 327.31 156.64 327.41 156.66C327.33 156.79 327.24 156.92 327.16 157.05L327.15 157.06Z" fill="white"/>
                    <path d="M395.53 60.25C395.68 60.25 395.84 60.25 395.99 60.25C395.96 60.35 395.95 60.51 395.9 60.52C395.75 60.56 395.59 60.54 395.43 60.55C395.46 60.45 395.49 60.36 395.52 60.25H395.53Z" fill="white"/>
                    <path d="M242.9 85.39C242.75 85.39 242.59 85.39 242.44 85.39C242.47 85.29 242.48 85.13 242.53 85.12C242.68 85.08 242.84 85.1 243 85.09C242.97 85.19 242.94 85.28 242.91 85.39H242.9Z" fill="white"/>
                    <path d="M298.91 133.47C298.82 133.6 298.75 133.75 298.63 133.85C298.59 133.88 298.45 133.8 298.35 133.78C298.44 133.65 298.51 133.5 298.63 133.4C298.67 133.36 298.81 133.45 298.91 133.47Z" fill="white"/>
                    <path d="M230.66 14.17C230.66 14.32 230.66 14.48 230.66 14.63C230.57 14.6 230.4 14.59 230.39 14.54C230.35 14.39 230.37 14.23 230.36 14.07C230.46 14.1 230.55 14.13 230.66 14.16V14.17Z" fill="white"/>
                    <path d="M389.21 169.13C387.41 169.5 386.55 168.35 385.76 167.25C385.47 166.85 385.12 166.16 384.89 166.01C383.45 165.03 385.23 163.99 384.7 163.07C385.67 162.7 386.71 161.92 387.6 162.07C388.45 162.21 389.49 162.47 390.27 163.02C391.01 163.55 392.28 163.95 391.19 165.31C391.03 165.51 391.28 166.03 391.3 166.4C391.39 168.05 390.64 168.97 389.2 169.14L389.21 169.13Z" fill="white"/>
                    <path d="M470.4 44.38C470.52 45.46 469.75 46.33 469.37 46.37C467.9 46.52 467.47 47.93 466.36 48.49C465.58 48.89 465.04 49.01 464.4 48.44C463.35 47.51 463.16 46.89 463.67 46.01C463.89 45.62 464.06 45.23 464.22 44.82C464.92 43.03 468.63 41.9 469.98 43.64C470.22 43.95 470.43 44.2 470.4 44.38Z" fill="white"/>
                    <path d="M385.33 70.0101C384.71 70.7401 385.09 72.0901 383.79 72.3601C383.61 72.4001 383.38 72.3601 383.24 72.4401C382.65 72.8301 382.16 73.4601 381.33 73.1401C380.68 72.8901 380.49 72.2301 380.15 71.7201C379.48 70.7101 379.84 69.0301 380.87 68.1801C381.67 67.5201 382.5 67.4401 383.48 67.8401C384.5 68.2501 384.74 69.2601 385.33 70.0201V70.0101Z" fill="white"/>
                    <path d="M348.32 119.05C348.71 119.01 349.29 118.83 349.34 119.21C349.44 119.89 349.85 120.61 349.28 121.34C348.97 121.75 348.46 121.74 348.2 122.07C347.78 122.6 347.44 122.73 346.83 122.31C346.17 121.85 345.99 121.19 346.3 120.65C346.73 119.9 347.17 118.94 348.32 119.05Z" fill="white"/>
                    <path d="M412.3 44.91C412.26 44.32 411.87 43.6 412.99 43.5C413.24 43.48 413.44 42.94 413.68 42.66C413.96 42.34 414.26 42.0501 414.56 41.7401C414.7 42.3501 415.08 43.04 414.92 43.57C414.71 44.24 414.05 44.76 413.62 45.37C413.39 45.7 413.1 45.9701 412.72 45.8601C412.29 45.7401 412.36 45.28 412.3 44.92V44.91Z" fill="white"/>
                    <path d="M264.31 73.34C264.29 73.16 264.24 72.97 264.24 72.79C264.24 72.38 263.87 71.84 264.48 71.61C265.11 71.38 265.7 71.5 266.22 72.01C266.66 72.45 266.48 72.76 266.21 73.18C266.03 73.45 266.09 73.87 265.92 74.14C265.69 74.5 265.49 75.01 264.93 74.94C264.26 74.86 264.5 74.26 264.39 73.87C264.34 73.69 264.34 73.51 264.32 73.32L264.31 73.34Z" fill="white"/>
                    <path d="M420.91 90.0701C420.76 89.5801 420.89 89.25 421.52 89.2C422.49 89.13 423.1 89.57 423.24 90.42C423.37 91.18 422.95 91.66 422.39 91.92C421.74 92.22 421.03 91.3101 420.91 90.0801V90.0701Z" fill="white"/>
                    <path d="M496.44 128.13C496.4 127.85 496.32 127.57 496.34 127.29C496.4 126.65 497.13 126.35 497.63 126.74C497.93 126.97 498.28 127.15 498.5 127.44C498.79 127.81 498.89 128.36 498.67 128.72C498.41 129.14 497.7 129.11 497.45 129.64C497.08 129.26 496.41 129.13 496.47 128.41C496.47 128.32 496.45 128.22 496.44 128.13Z" fill="white"/>
                    <path d="M308.99 3.52003C308.79 3.78003 308.6 3.99003 308.45 4.22003C308.06 4.80003 307.16 4.26003 306.81 5.00003C306.63 5.39003 306.2 5.44003 305.9 5.14003C305.61 4.86003 305.67 4.50003 305.94 4.18003C306.3 3.75003 306.6 3.28003 306.98 2.87003C307.16 2.68003 307.21 2.09003 307.72 2.51003C308.13 2.85003 308.57 3.17003 309 3.50003L308.99 3.52003Z" fill="white"/>
                    <path d="M299.06 127.05C298.51 127.02 298.14 126.63 297.85 126.19C297.68 125.93 298.8 124.86 299.36 124.73C299.87 124.62 299.97 124.97 300 125.35C300.08 126.12 299.61 126.97 299.05 127.05H299.06Z" fill="white"/>
                    <path d="M324.62 36.74C324.53 36.89 324.48 37.12 324.36 37.16C323.67 37.42 322.28 36.58 322.25 35.88C322.24 35.64 322.29 35.28 322.45 35.18C322.61 35.07 322.98 35.13 323.19 35.25C324.09 35.76 324.57 36.28 324.63 36.75L324.62 36.74Z" fill="white"/>
                    <path d="M377.16 127.92C377.43 127.88 377.71 127.8 377.98 127.82C378.68 127.88 378.44 128.48 378.54 128.87C378.67 129.38 378.31 129.48 377.92 129.52C377.57 129.56 377.22 129.67 376.89 129.38C376.55 129.08 376.06 128.85 376.22 128.31C376.34 127.89 376.81 127.97 377.16 127.91V127.92Z" fill="white"/>
                    <path d="M342.24 143.61C342.28 143.99 342.31 144.41 341.79 144.39C341.11 144.36 340.54 144.01 340.53 143.3C340.53 142.93 340.96 142.56 341.2 142.19C341.66 142.58 342.33 142.83 342.24 143.61Z" fill="white"/>
                    <path d="M479.11 84.72C478.44 84.76 477.65 84.08 477.66 83.61C477.68 83.11 478.08 83.15 478.43 83.08C479.13 82.95 479.64 83.27 479.82 83.87C479.87 84.05 479.47 84.68 479.11 84.73V84.72Z" fill="white"/>
                    <path d="M511.91 21.5401C512.09 22.2901 511.73 22.3901 511.2 22.5001C510.38 22.6701 510.29 22.2401 510.21 21.6201C510.12 20.9801 510.43 20.8801 510.96 20.7801C511.75 20.6401 511.97 20.9701 511.91 21.5401Z" fill="white"/>
                    <path d="M452.56 131.41C453.22 131.52 453.03 132.09 453.1 132.49C453.22 133.23 452.6 133.08 452.2 133.16C451.83 133.23 451.44 133.24 451.43 132.7C451.41 132.15 452.08 131.4 452.56 131.41Z" fill="white"/>
                    <path d="M282.75 65.97C282.54 66.36 282.39 67.15 281.89 66.87C281.46 66.63 280.76 66.98 280.62 66.33C280.54 65.94 281.82 65.21 282.35 65.34C282.62 65.41 282.77 65.64 282.75 65.97Z" fill="white"/>
                    <path d="M494.86 25.7101C494.86 25.7101 495.19 26.0901 495.42 26.4801C495.52 26.6501 494.56 27.5101 494.3 27.4801C493.61 27.4201 493.84 26.8301 493.74 26.4401C493.62 25.9701 493.91 25.8101 494.86 25.7001V25.7101Z" fill="white"/>
                    <path d="M494.46 58.97C494.36 59.63 493.79 59.42 493.4 59.51C492.91 59.63 492.68 59.27 492.77 58.92C492.88 58.51 493.18 58.14 493.47 57.81C493.57 57.69 494.47 58.72 494.46 58.96V58.97Z" fill="white"/>
                    <path d="M370.73 178.29C370.26 178.45 370.04 178.25 370 177.98C369.91 177.26 370.32 176.83 370.96 176.58C371.29 176.46 371.51 176.66 371.55 176.93C371.66 177.67 371.2 178.06 370.73 178.28V178.29Z" fill="white"/>
                    <path d="M305.17 27.65C305.77 27.66 306.26 27.98 306.32 28.46C306.35 28.69 305.99 29.28 305.44 29.34C304.7 29.42 304.85 28.83 304.77 28.44C304.69 28.07 304.7 27.72 305.18 27.65H305.17Z" fill="white"/>
                    <path d="M458.75 148.86C458.49 148.93 457.32 148.31 457.42 148.18C457.67 147.82 457.96 147.44 458.32 147.24C458.66 147.05 459.03 147.24 459.03 147.72C459.03 148.12 459.4 148.62 458.75 148.87V148.86Z" fill="white"/>
                    <path d="M302.81 22.39C302.71 22.9 302.45 23.45 301.71 23.53C301.44 23.56 301.18 23.4 301.22 23.05C301.29 22.37 301.62 21.87 302.33 21.77C302.6 21.73 302.85 21.9 302.81 22.4V22.39Z" fill="white"/>
                    <path d="M239.98 16.56C240.08 17.07 239.74 17.3 239.38 17.2C238.97 17.09 238.6 16.79 238.27 16.5C238.15 16.4 239.18 15.5 239.42 15.51C239.78 15.53 239.99 15.94 239.97 16.55L239.98 16.56Z" fill="white"/>
                    <path d="M396.58 141.97C397.24 141.78 397.5 142.39 397.95 142.63C397.74 142.91 397.56 143.41 397.33 143.43C396.82 143.48 396.43 143.11 396.26 142.59C396.16 142.27 396.34 142.1 396.58 141.97Z" fill="white"/>
                    <path d="M489.57 21.8201C489.17 21.8101 488.64 22.1201 488.53 21.3701C488.45 20.8301 488.8 20.7601 489.17 20.7301C489.58 20.7001 490.1 20.4201 490.22 21.1701C490.3 21.7101 489.95 21.7601 489.57 21.8201Z" fill="white"/>
                    <path d="M321.89 150.08C321.48 150.91 320.93 151.15 320.26 151.12C320.1 151.12 319.95 150.98 319.8 150.9C319.91 150.76 320.06 150.63 320.13 150.47C320.41 149.85 320.99 149.88 321.51 149.85C321.67 149.85 321.84 150.05 321.89 150.08Z" fill="white"/>
                    <path d="M518.42 23.08C518.45 23.22 518.11 23.33 517.72 23.35C517.31 23.37 516.78 23.68 516.66 22.93C516.58 22.41 516.9 22.31 517.29 22.28C517.7 22.25 518.23 21.98 518.42 23.08Z" fill="white"/>
                    <path d="M462.45 1.82002C462.33 1.77002 462.11 1.73002 462.09 1.64002C461.95 1.02002 461.83 0.420017 462.41 -0.109983C462.65 -0.319983 462.81 -0.409983 462.98 -0.139983C463.24 0.290017 462.87 1.64002 462.45 1.81002V1.82002Z" fill="white"/>
                    <path d="M426.26 105.26C426.15 106.15 425.38 105.68 424.93 105.83C424.72 105.9 424.51 105.5 424.56 105.22C424.69 104.38 425.42 104.74 425.88 104.64C426.1 104.59 426.28 104.95 426.25 105.26H426.26Z" fill="white"/>
                    <path d="M397.95 -0.569958C397.84 0.320042 397.07 -0.149958 396.62 4.22001e-05C396.41 0.0700422 396.2 -0.329958 396.25 -0.609958C396.38 -1.44996 397.11 -1.08996 397.57 -1.18996C397.79 -1.23996 397.97 -0.879958 397.94 -0.569958H397.95Z" fill="white"/>
                    <path d="M301.86 81.4701C301.89 81.6101 301.55 81.7201 301.16 81.7401C300.75 81.7601 300.22 82.0701 300.1 81.3201C300.02 80.8001 300.34 80.7001 300.73 80.6701C301.14 80.6401 301.67 80.3701 301.86 81.4701Z" fill="white"/>
                    <path d="M311.83 97.32C311.86 97.71 311.91 98.06 311.36 98.12C310.61 98.19 310.75 97.62 310.67 97.21C310.6 96.84 310.59 96.48 311.13 96.43C311.88 96.35 311.71 96.94 311.82 97.32H311.83Z" fill="white"/>
                    <path d="M396.67 86.6801C397.56 86.7901 397.09 87.5601 397.24 88.0101C397.31 88.2201 396.91 88.4301 396.63 88.3801C395.79 88.2501 396.15 87.5201 396.05 87.0601C396 86.8401 396.36 86.6601 396.67 86.6901V86.6801Z" fill="white"/>
                    <path d="M329.47 128.25C329.42 128.39 329.34 128.63 329.26 128.87C328.87 128.54 328.25 128.4 328.25 127.74C328.25 127.46 328.39 127.27 328.74 127.23C329.48 127.16 329.34 127.72 329.47 128.24V128.25Z" fill="white"/>
                    <path d="M398.7 41.0701C398.72 41.2501 398.79 41.4501 398.75 41.6201C398.72 41.7301 398.52 41.9001 398.43 41.8801C398.13 41.8001 397.89 40.0801 398.15 39.8601C398.21 39.8101 398.52 39.9001 398.53 39.9701C398.61 40.3301 398.64 40.7001 398.69 41.0701H398.7Z" fill="white"/>
                    <path d="M246.17 56.2C246.28 56.77 245.95 56.9 245.56 56.91C245.27 56.91 245.09 56.77 245.05 56.42C244.99 55.9 245.29 55.78 245.68 55.77C245.95 55.77 246.23 55.88 246.17 56.2Z" fill="white"/>
                    <path d="M451.85 127.06C451.8 127.36 451.7 127.55 451.35 127.59C450.83 127.65 450.71 127.35 450.7 126.96C450.7 126.67 450.84 126.49 451.19 126.45C451.71 126.39 451.8 126.7 451.85 127.07V127.06Z" fill="white"/>
                    <path d="M347.35 151.67C347 151.71 346.68 151.7 346.61 151.18C346.56 150.83 346.71 150.65 346.98 150.58C347.36 150.49 347.68 150.53 347.75 151.05C347.8 151.39 347.65 151.55 347.34 151.67H347.35Z" fill="white"/>
                    <path d="M506.15 6.10004C506.65 6.25004 507.07 6.50004 507.2 7.03004C507.24 7.17004 507.11 7.49004 507.09 7.49004C506.57 7.40004 506.16 7.15004 506.03 6.61004C506 6.47004 506.1 6.29004 506.15 6.10004Z" fill="white"/>
                    <path d="M480.82 103.9C480.93 104.22 480.73 104.41 480.45 104.48C480.07 104.57 479.75 104.53 479.68 104.01C479.63 103.66 479.78 103.48 480.05 103.41C480.43 103.32 480.78 103.36 480.82 103.9Z" fill="white"/>
                    <path d="M535 32.6901C534.97 32.3301 534.93 31.9501 535.46 31.9301C535.62 31.9301 535.79 32.0001 535.95 32.0301C535.75 32.3801 535.54 32.7301 535.34 33.0801C535.23 32.9501 535.12 32.8201 535 32.6901Z" fill="white"/>
                    <path d="M490.22 56.94C490.25 57.3 490.29 57.6801 489.76 57.7001C489.6 57.7001 489.43 57.63 489.27 57.6C489.47 57.25 489.68 56.9 489.88 56.55C489.99 56.68 490.1 56.81 490.22 56.94Z" fill="white"/>
                    <path d="M388.75 87.7C388.54 87.65 388.24 87.63 388.23 87.57C388.15 87.08 388.65 86.9 388.83 86.54C388.94 86.67 389.11 86.78 389.15 86.93C389.25 87.31 389.19 87.64 388.75 87.7Z" fill="white"/>
                    <path d="M374.77 175.74C374.71 175.87 374.66 176.08 374.57 176.11C374.23 176.21 373.82 176.28 373.73 175.8C373.69 175.58 373.84 175.32 373.9 175.08C374.19 175.3 374.47 175.52 374.77 175.75V175.74Z" fill="white"/>
                    <path d="M388.87 151.5C389.37 151.41 389.47 151.76 389.48 152.14C389.48 152.29 389.34 152.45 389.27 152.6C388.99 152.31 388.7 152.03 388.42 151.74C388.57 151.66 388.72 151.59 388.87 151.51V151.5Z" fill="white"/>
                    <path d="M435.02 155.98C434.95 156.14 434.88 156.3 434.81 156.45C434.51 156.18 434.22 155.91 433.92 155.64C434.07 155.56 434.2 155.44 434.36 155.4C434.85 155.28 434.97 155.6 435.02 155.98Z" fill="white"/>
                    <path d="M279.8 2.39001C280.3 2.30001 280.4 2.65001 280.41 3.03001C280.41 3.18001 280.27 3.34001 280.2 3.49001C279.92 3.20001 279.63 2.92001 279.35 2.63001C279.5 2.55001 279.65 2.48001 279.8 2.40001V2.39001Z" fill="white"/>
                    <path d="M241.63 9.50006C241.78 9.69006 241.94 9.80006 241.97 9.95006C242.06 10.3301 241.99 10.6801 241.49 10.6801C241.33 10.6801 241.17 10.6001 241.01 10.5601C241.21 10.2101 241.41 9.87006 241.63 9.50006Z" fill="white"/>
                    <path d="M275.57 104.18C275.42 104.53 275.11 104.45 274.85 104.47C274.77 104.47 274.59 104.23 274.61 104.15C274.71 103.81 275.05 103.89 275.3 103.88C275.38 103.88 275.48 104.08 275.56 104.18H275.57Z" fill="white"/>
                    <path d="M363.95 161.29C363.66 161.42 363.44 161.56 363.2 161.6C363.06 161.62 362.9 161.47 362.74 161.39C362.96 161.27 363.17 161.1 363.41 161.04C363.54 161.01 363.71 161.17 363.95 161.29Z" fill="white"/>
                    <path d="M366.9 160.54C366.75 160.54 366.5 160.6 366.26 160.56C365.98 160.51 365.95 160.31 366.21 160.2C366.43 160.11 366.7 160.07 366.94 160.11C367.22 160.15 367.23 160.32 366.89 160.54H366.9Z" fill="white"/>
                    <path d="M270.33 120.45C270.49 120.49 270.77 120.5 270.8 120.59C270.9 120.91 270.66 121.02 270.39 121.01C270.23 121.01 269.95 120.96 269.92 120.88C269.82 120.56 270.06 120.47 270.33 120.46V120.45Z" fill="white"/>
                    <path d="M438.11 146.29C438.24 146.58 438.38 146.8 438.42 147.04C438.44 147.18 438.29 147.34 438.21 147.5C438.09 147.28 437.92 147.07 437.86 146.83C437.83 146.7 437.99 146.53 438.11 146.29Z" fill="white"/>
                    <path d="M480.69 138.65C480.42 138.71 480.17 138.68 480.18 138.34C480.18 138.25 480.44 138.14 480.6 138.1C480.86 138.02 481.12 138.06 481.11 138.4C481.11 138.49 480.84 138.57 480.69 138.65Z" fill="white"/>
                    <path d="M353.12 7.22003C353.25 7.51003 353.39 7.73003 353.43 7.97003C353.45 8.11003 353.3 8.27003 353.22 8.43003C353.1 8.21003 352.93 8.00003 352.87 7.76003C352.84 7.63003 353 7.46003 353.12 7.22003Z" fill="white"/>
                    <path d="M425.03 37.63C424.98 37.74 424.9 37.89 424.82 38.05C424.7 37.83 424.53 37.62 424.47 37.38C424.44 37.25 424.6 37.08 424.68 36.92C424.93 37.07 425.06 37.28 425.03 37.62V37.63Z" fill="white"/>
                    <path d="M468.91 39.1901C469.04 39.4801 469.18 39.7001 469.22 39.9401C469.24 40.0801 469.09 40.2401 469.01 40.4001C468.89 40.1801 468.72 39.9701 468.66 39.7301C468.63 39.6001 468.79 39.4301 468.91 39.1901Z" fill="white"/>
                    <path d="M537.7 41.11C537.55 41.46 537.24 41.38 536.98 41.4C536.9 41.4 536.72 41.16 536.74 41.08C536.84 40.74 537.18 40.82 537.43 40.81C537.51 40.81 537.61 41.01 537.69 41.11H537.7Z" fill="white"/>
                    <path d="M486.29 75.2401C486.02 75.3001 485.77 75.2701 485.78 74.9301C485.78 74.8401 486.04 74.7301 486.2 74.6901C486.46 74.6101 486.72 74.6501 486.71 74.9901C486.71 75.0801 486.44 75.1601 486.29 75.2401Z" fill="white"/>
                    <path d="M288.35 91.9301C288.51 91.9701 288.79 91.9801 288.82 92.0701C288.92 92.3901 288.68 92.5001 288.41 92.4901C288.25 92.4901 287.97 92.4401 287.94 92.3601C287.84 92.0401 288.08 91.9501 288.35 91.9401V91.9301Z" fill="white"/>
                    <path d="M376.78 65.85C376.74 66.01 376.73 66.29 376.64 66.32C376.32 66.42 376.21 66.18 376.22 65.91C376.22 65.75 376.27 65.47 376.35 65.44C376.67 65.34 376.76 65.58 376.77 65.85H376.78Z" fill="white"/>
                    <path d="M379.48 80.3101C379.56 80.4301 379.66 80.5401 379.7 80.6601C379.72 80.7001 379.6 80.8001 379.55 80.8701C379.47 80.7501 379.37 80.6401 379.33 80.5201C379.31 80.4801 379.43 80.3801 379.48 80.3101Z" fill="white"/>
                    <path d="M439.3 165.02C439.38 165.14 439.48 165.25 439.52 165.37C439.54 165.41 439.42 165.51 439.37 165.58C439.29 165.46 439.19 165.35 439.15 165.23C439.13 165.19 439.25 165.09 439.3 165.02Z" fill="white"/>
                    <path d="M389.2 23.99C389.27 23.91 389.32 23.84 389.37 23.77C389.45 23.89 389.55 24 389.59 24.12C389.61 24.16 389.49 24.26 389.44 24.33C389.36 24.22 389.27 24.1 389.19 23.99H389.2Z" fill="white"/>
                    <path d="M526.02 17.78C526.09 17.84 526.22 17.91 526.22 17.94C526.17 18.07 526.11 18.23 526.01 18.3C525.79 18.44 525.66 18.34 525.74 18.09C525.78 17.97 525.93 17.88 526.02 17.78Z" fill="white"/>
                    <path d="M525.79 18.59C525.69 18.69 525.57 18.89 525.49 18.87C525.26 18.83 525.21 18.66 525.34 18.55C525.46 18.45 525.61 18.4 525.75 18.33C525.76 18.41 525.77 18.5 525.78 18.58L525.79 18.59Z" fill="white"/>
                    <path d="M356.19 97.1601C356.14 97.2901 356.09 97.4201 356.04 97.5501C355.97 97.5001 355.84 97.4401 355.84 97.3901C355.85 97.2501 355.92 97.1201 355.97 96.9901C356.04 97.0401 356.11 97.1001 356.2 97.1601H356.19Z" fill="white"/>
                    <path d="M532.4 21.8501C532.33 21.9301 532.28 22.0001 532.23 22.0701C532.15 21.9501 532.05 21.8401 532.01 21.7201C531.99 21.6801 532.11 21.5801 532.16 21.5101C532.24 21.6201 532.33 21.7401 532.41 21.8501H532.4Z" fill="white"/>
                    <path d="M444.9 68.7501C444.85 68.8801 444.8 69.0101 444.75 69.1401C444.68 69.0901 444.55 69.0301 444.55 68.9801C444.56 68.8401 444.63 68.7101 444.68 68.5801C444.75 68.6301 444.82 68.6901 444.91 68.7501H444.9Z" fill="white"/>
                    <path d="M503.44 37.72C503.52 37.84 503.62 37.95 503.66 38.07C503.68 38.11 503.56 38.21 503.51 38.28C503.43 38.16 503.33 38.05 503.29 37.93C503.27 37.89 503.39 37.79 503.44 37.72Z" fill="white"/>
                    <path d="M381.39 55.6401C381.52 55.6901 381.65 55.7401 381.78 55.7901C381.73 55.8601 381.67 55.9901 381.62 55.9901C381.48 55.9801 381.35 55.9101 381.22 55.8601C381.27 55.7901 381.33 55.7201 381.39 55.6301V55.6401Z" fill="white"/>
                    <path d="M271.67 128.83C271.75 128.95 271.85 129.06 271.89 129.18C271.91 129.22 271.79 129.32 271.74 129.39C271.66 129.27 271.56 129.16 271.52 129.04C271.5 129 271.62 128.9 271.67 128.83Z" fill="white"/>
                    <path d="M467.23 113.89C466.32 113.45 466.01 112.41 466.17 112.09C466.78 110.86 465.88 109.82 465.99 108.67C466.06 107.86 466.24 107.37 467 107.15C468.25 106.78 468.83 106.94 469.28 107.78C469.48 108.14 469.7 108.47 469.95 108.8C471.01 110.23 470.07 113.72 468.03 113.93C467.67 113.97 467.37 114.01 467.23 113.89Z" fill="white"/>
                    <path d="M489.09 34.18C488.82 33.33 487.57 32.96 488 31.8C488.06 31.64 488.21 31.48 488.21 31.32C488.2 30.67 487.94 29.97 488.6 29.47C489.12 29.08 489.73 29.26 490.3 29.25C491.43 29.22 492.57 30.34 492.74 31.57C492.87 32.53 492.51 33.22 491.72 33.8C490.89 34.41 489.98 34.1 489.08 34.18H489.09Z" fill="white"/>
                    <path d="M611.93 28.96C613.3 29.61 613.7 30.41 613.35 31.45C613.16 32.03 612.82 32.55 612.6 33.12C612.39 33.66 612.09 33.71 611.61 33.45C611.07 33.16 610.45 33 609.96 32.66C609.12 32.07 608.81 31.17 608.82 30.17C608.82 29.97 609.01 29.64 609.18 29.58C609.83 29.35 610.34 28.93 610.9 28.6C611.18 28.44 611.83 28.91 611.93 28.95V28.96Z" fill="white"/>
                    <path d="M579.13 138.99C578.48 138.75 577.55 138.43 577.74 137.34C577.83 136.82 578 135.86 578.65 135.94C579.35 136.04 580.23 135.56 580.8 136.31C581.2 136.83 581.43 137.51 581.92 137.91C582.37 138.27 582.39 138.48 582.19 138.95C581.89 139.62 581.33 139.8 580.74 139.7C580.24 139.61 579.78 139.29 579.14 138.99H579.13Z" fill="white"/>
                    <path d="M621.3 12.44C620.67 12.16 620.36 11.42 620.61 10.79C620.69 10.58 622.72 9.13001 622.92 9.14001C623.39 9.14001 624.25 10.5 624.17 10.52C623.08 10.69 623.98 11.69 623.48 12.04C623 12.38 621.72 12.62 621.31 12.45L621.3 12.44Z" fill="white"/>
                    <path d="M495.53 67.85C496.01 68.11 496.78 68.16 496.3 69.09C496.19 69.3 496.52 69.72 496.62 70.05C496.73 70.43 496.82 70.81 496.91 71.2C496.36 71.01 495.62 70.97 495.29 70.58C494.87 70.09 494.79 69.3 494.52 68.67C494.37 68.32 494.3 67.97 494.58 67.72C494.89 67.45 495.22 67.72 495.52 67.86L495.53 67.85Z" fill="white"/>
                    <path d="M625.8 20.85C626.45 20.38 626.99 19.69 627.85 19.56C628.05 19.53 628.35 19.66 628.43 19.97C628.53 20.37 628.92 20.81 628.54 21.16C628.07 21.59 627.49 21.93 626.92 22.24C626.25 22.61 626.51 21.73 626.15 21.62C625.78 21.51 625.57 21.27 625.8 20.84V20.85Z" fill="white"/>
                    <path d="M455.69 52.31C456.15 52.44 456.35 52.7 456.08 53.22C455.66 54.02 455.01 54.28 454.27 53.98C453.61 53.71 453.44 53.14 453.51 52.57C453.59 51.91 454.66 51.8 455.7 52.32L455.69 52.31Z" fill="white"/>
                    <path d="M388.35 93.02C388.59 93.13 388.85 93.2 389.06 93.36C389.54 93.72 389.41 94.44 388.85 94.65C388.52 94.77 388.2 94.96 387.87 94.99C387.44 95.04 386.95 94.85 386.78 94.49C386.58 94.08 386.96 93.53 386.66 93.07C387.14 92.97 387.58 92.5 388.12 92.9C388.19 92.95 388.28 92.98 388.36 93.01L388.35 93.02Z" fill="white"/>
                    <path d="M579.23 6.88C579.12 6.59 579.05 6.34 578.94 6.11C578.67 5.51 579.54 5.07 579.14 4.43C578.92 4.09 579.09 3.73 579.48 3.64C579.85 3.55 580.09 3.78 580.21 4.15C580.37 4.64 580.59 5.11 580.73 5.61C580.79 5.85 581.23 6.18 580.64 6.37C580.16 6.53 579.7 6.71 579.23 6.89V6.88Z" fill="white"/>
                    <path d="M631.94 101.53C631.63 101.99 630.63 102.1 630.12 101.72C629.92 101.57 629.68 99.97 629.88 99.74C630.38 99.15 630.81 99.74 631.2 99.93C631.92 100.27 632.24 101.08 631.94 101.53Z" fill="white"/>
                    <path d="M559.67 111.19C559.82 110.67 560.06 110.43 560.6 110.7C561.25 111.04 561.69 111.8 561.47 112.28C561.25 112.75 560.36 112.92 559.7 112.61C558.71 112.14 559.63 111.64 559.67 111.19Z" fill="white"/>
                    <path d="M554.38 114.78C554.11 114.38 553.8 113.96 554 113.44C554.21 112.88 555.41 112.76 555.98 113.2C556.36 113.48 556.18 113.78 556.02 114.07C555.63 114.74 554.86 115.08 554.39 114.78H554.38Z" fill="white"/>
                    <path d="M580.14 38.6C580.45 38.74 580.6 38.83 580.76 38.88C581.37 39.07 581.29 39.69 581.53 40.11C581.06 40.21 580.64 40.66 580.1 40.32C579.96 40.23 579.8 40.16 579.64 40.1C579.1 39.9 578.79 39.68 579.13 38.99C579.45 38.33 579.78 38.3 580.15 38.59L580.14 38.6Z" fill="white"/>
                    <path d="M502.64 168.04C502.86 167.47 503.28 167.53 503.5 167.77C503.93 168.26 504.27 168.83 504.6 169.4C504.91 169.93 504.36 169.86 504.12 169.99C503.1 170.55 503.17 169.32 502.63 169.05C502.01 168.74 502.65 168.32 502.64 168.04Z" fill="white"/>
                    <path d="M576.76 70.08C576.05 69.9 575.87 69.32 575.93 68.74C575.97 68.31 577.34 67.84 577.55 68.14C577.96 68.7 577.97 69.3 577.61 69.92C577.36 70.36 577.08 70.22 576.76 70.08Z" fill="white"/>
                    <path d="M608.02 50.5C607.84 50.7 607.57 50.85 607.38 50.61C606.94 50.03 606.34 49.55 606.1 48.8C606 48.49 606.1 48.21 606.27 48.1C606.42 47.99 606.85 48.01 606.96 48.14C607.37 48.65 607.71 49.21 608.06 49.77C608.21 50.01 608.18 50.26 608.02 50.51V50.5Z" fill="white"/>
                    <path d="M636.51 50.78C636.25 50.68 635.92 50.57 636.03 50.12C636.12 49.73 636.49 49.7 636.77 49.54C637.34 49.21 637.77 49.59 638.16 49.85C638.39 50 638.61 50.61 638.5 50.73C638.28 51 637.85 51.18 637.49 51.2C637.19 51.22 636.88 50.95 636.51 50.78Z" fill="white"/>
                    <path d="M549.74 165.22C549.62 165.64 549.35 165.83 548.84 165.56C548.2 165.22 547.77 164.46 547.98 163.99C548.33 163.19 549.07 164.19 549.54 163.67C549.62 163.58 549.97 164.6 549.74 165.23V165.22Z" fill="white"/>
                    <path d="M545.32 2.78001C545.25 2.64001 545.09 2.48001 545.12 2.36001C545.25 1.69001 546.61 1.01001 547.18 1.33001C547.38 1.44001 547.63 1.66001 547.64 1.83001C547.64 2.01001 547.42 2.27001 547.22 2.38001C546.37 2.84001 545.72 2.95001 545.33 2.78001H545.32Z" fill="white"/>
                    <path d="M447.49 -0.879988C447.38 -0.649988 447.31 -0.389988 447.16 -0.179988C446.77 0.340012 446.41 -0.139988 446.05 -0.259988C445.58 -0.409988 445.68 -0.739988 445.84 -1.06999C445.98 -1.36999 446.06 -1.68999 446.46 -1.80999C446.86 -1.92999 447.28 -2.19999 447.63 -1.80999C447.9 -1.50999 447.61 -1.17999 447.48 -0.879988H447.49Z" fill="white"/>
                    <path d="M605.1 50.72C605.54 51.04 605.34 51.35 605.18 51.63C604.78 52.31 604.02 52.63 603.55 52.32C603.17 52.06 603.36 51.73 603.53 51.45C603.93 50.77 604.68 50.46 605.1 50.72Z" fill="white"/>
                    <path d="M556.26 168.68C555.68 168.87 555.19 169.18 554.56 168.8C554.04 168.49 554.15 168.21 554.33 167.78C554.53 167.32 554.72 167.16 555.27 167.33C555.99 167.56 555.99 168.18 556.26 168.67V168.68Z" fill="white"/>
                    <path d="M543.24 166.43C543.16 166.29 542.97 166.13 543 166.03C543.09 165.71 543.19 165.28 543.43 165.13C543.82 164.88 544.29 164.53 544.82 164.86C545.22 165.11 545.03 165.44 544.86 165.72C544.45 166.4 543.74 166.71 543.24 166.44V166.43Z" fill="white"/>
                    <path d="M431.13 100.82C431.43 100.27 432.36 99.98 432.72 100.23C433.1 100.49 432.88 100.79 432.76 101.1C432.52 101.72 432.01 101.96 431.46 101.81C431.3 101.76 430.99 101.14 431.14 100.83L431.13 100.82Z" fill="white"/>
                    <path d="M576.55 89.11C576.15 88.93 575.75 88.71 576.06 88.36C576.44 87.93 576.99 87.64 577.47 87.29C577.71 87.84 577.9 88.35 577.52 88.96C577.16 89.54 576.83 89.2 576.56 89.12L576.55 89.11Z" fill="white"/>
                    <path d="M464.71 157.9C464.03 157.67 464.13 157.34 464.3 156.87C464.57 156.14 464.95 156.28 465.48 156.52C466.03 156.77 465.96 157.06 465.77 157.53C465.5 158.22 465.12 158.24 464.7 157.9H464.71Z" fill="white"/>
                    <path d="M585.98 83.09C586.25 82.71 586.24 81.89 586.99 82.37C587.4 82.63 587.61 83.17 587.44 83.68C587.15 84.54 586.38 83.9 585.92 83.95C585.35 84.01 586.03 83.39 585.98 83.08V83.09Z" fill="white"/>
                    <path d="M652.19 42.96C651.96 42.85 651.7 42.78 651.5 42.63C651.13 42.36 651.33 42.05 651.49 41.76C651.61 41.55 651.61 41.23 651.94 41.21C652.31 41.18 652.56 41.36 652.66 41.73C652.71 41.89 652.78 42.05 652.83 42.21C652.93 42.52 653.2 42.97 653.09 43.11C652.84 43.41 652.49 43.06 652.19 42.96Z" fill="white"/>
                    <path d="M407.46 56.82C407.04 57.29 406.69 56.85 406.34 56.71C405.69 56.44 406.12 56.02 406.26 55.67C406.39 55.34 406.57 55.03 407 55.29C407.44 55.55 407.7 56.44 407.46 56.82Z" fill="white"/>
                    <path d="M530.76 119.77C530.97 119.36 531.13 119.04 531.56 119.3C532 119.56 532.28 120.46 532.01 120.83C531.74 121.2 531.44 120.94 531.13 120.85C530.36 120.62 530.53 120.18 530.76 119.78V119.77Z" fill="white"/>
                    <path d="M617.93 68.02C617.81 68.16 617.66 68.49 617.5 68.49C617.08 68.49 617.11 68.02 617.08 67.78C617.05 67.42 616.21 66.94 617.17 66.65C617.49 66.56 617.81 66.36 618.11 66.62C618.72 67.14 617.94 67.5 617.92 68.02H617.93Z" fill="white"/>
                    <path d="M469.86 142.41C469.86 142.41 469.4 142.48 468.98 142.47C468.8 142.47 468.6 141.28 468.74 141.09C469.13 140.57 469.48 141.05 469.83 141.16C470.26 141.3 470.24 141.61 469.86 142.41Z" fill="white"/>
                    <path d="M443.84 125.65C443.37 125.24 443.82 124.9 443.94 124.54C444.09 124.09 444.49 124.09 444.72 124.34C444.98 124.63 445.13 125.05 445.25 125.44C445.29 125.58 444.04 125.78 443.85 125.65H443.84Z" fill="white"/>
                    <path d="M390.64 53.07C390.71 52.83 391.78 52.22 391.84 52.36C392 52.73 392.16 53.15 392.14 53.54C392.12 53.9 391.79 54.1 391.41 53.86C391.09 53.66 390.51 53.71 390.64 53.07Z" fill="white"/>
                    <path d="M581.68 82.79C581.35 82.63 581.02 82.4701 581.27 82.0501C581.53 81.5801 582.05 81.37 582.45 81.56C582.65 81.65 582.93 82.2301 582.71 82.6901C582.4 83.3101 582.03 82.88 581.69 82.79H581.68Z" fill="white"/>
                    <path d="M635.5 19.52C635.2 19.13 634.97 18.74 635.13 18.23C635.23 17.93 635.49 17.83 635.71 17.97C636.07 18.2 636.84 18.11 636.55 18.91C636.38 19.4 635.84 19.28 635.5 19.52Z" fill="white"/>
                    <path d="M577.31 71.49C577.23 71.35 577.16 71.22 577.08 71.08C577.78 70.8 578.55 70.64 579.04 69.98C579.06 69.95 579.37 70.08 579.49 70.2C579.55 70.27 579.52 70.56 579.49 70.57C578.81 70.74 578.55 71.59 577.79 71.6C577.63 71.6 577.47 71.53 577.31 71.49Z" fill="white"/>
                    <path d="M578.16 67.49C578.41 67.03 578.89 67.57 579.24 67.78C579.93 68.18 579.26 68.63 579.14 69.04C579.11 69.16 578.65 69.47 578.71 69.02C578.79 68.35 577.5 68.59 578.16 67.49Z" fill="white"/>
                    <path d="M426.81 7.47004C426.64 8.08004 426.02 7.99004 425.61 8.22004C425.49 7.92004 425.19 7.53004 425.28 7.33004C425.49 6.90004 425.97 6.78004 426.47 6.90004C426.77 6.97004 426.82 7.20004 426.8 7.46004L426.81 7.47004Z" fill="white"/>
                    <path d="M475.54 140.16C475.75 139.85 475.76 139.28 476.41 139.56C476.88 139.76 476.76 140.07 476.6 140.38C476.42 140.72 476.38 141.27 475.74 140.99C475.27 140.79 475.41 140.48 475.55 140.15L475.54 140.16Z" fill="white"/>
                    <path d="M460.28 162.27C460.16 162.22 460.24 161.9 460.41 161.58C460.59 161.25 460.62 160.68 461.27 160.95C461.73 161.14 461.64 161.45 461.47 161.77C461.29 162.11 461.24 162.66 460.28 162.26V162.27Z" fill="white"/>
                    <path d="M504.71 128.67C504.81 128.6 504.95 128.45 505.03 128.47C505.59 128.66 506.12 128.87 506.25 129.59C506.3 129.88 506.29 130.06 506 130.05C505.53 130.05 504.66 129.08 504.72 128.67H504.71Z" fill="white"/>
                    <path d="M441.07 49.01C440.42 48.48 441.17 48.11 441.28 47.68C441.33 47.49 441.75 47.52 441.95 47.69C442.54 48.21 441.9 48.61 441.75 49.02C441.68 49.21 441.31 49.18 441.07 49.01Z" fill="white"/>
                    <path d="M538.47 79.01C537.82 78.48 538.57 78.11 538.68 77.68C538.73 77.49 539.15 77.52 539.35 77.69C539.94 78.21 539.3 78.61 539.15 79.02C539.08 79.21 538.71 79.18 538.47 79.01Z" fill="white"/>
                    <path d="M596.23 117.16C596.78 117.12 597.34 117.09 597.9 117.06C597.93 117.06 598 117.22 597.98 117.29C597.9 117.65 597.67 117.84 597.31 117.95C596.91 118.07 596.51 118.26 596.21 117.82C596.14 117.72 596.21 117.51 596.22 117.17L596.23 117.16Z" fill="white"/>
                    <path d="M600.05 68.97C599.93 69.3 599.81 69.66 599.36 69.51C598.77 69.32 598.88 68.94 599.1 68.52C599.26 68.22 599.34 67.84 599.8 67.99C600.38 68.18 600.31 68.56 600.05 68.97Z" fill="white"/>
                    <path d="M592.04 16.36C591.68 16.04 591.53 15.83 591.73 15.58C592.12 15.08 592.62 14.86 593.24 15.12C593.36 15.17 593.41 15.38 593.5 15.51C592.99 15.8 592.49 16.1 592.04 16.35V16.36Z" fill="white"/>
                    <path d="M539.48 106.7C539.03 106.56 538.75 106.32 538.85 106.13C539.07 105.75 538.96 105 539.74 105.12C540 105.16 540.28 105.41 540.18 105.63C540 106.04 539.99 106.67 539.48 106.7Z" fill="white"/>
                    <path d="M470.34 34.88C469.81 35.53 469.44 34.78 469.01 34.67C468.82 34.62 468.85 34.2 469.02 34C469.54 33.41 469.94 34.05 470.35 34.2C470.54 34.27 470.51 34.64 470.34 34.88Z" fill="white"/>
                    <path d="M601.35 18.57C601.23 18.9 601.11 19.26 600.66 19.11C600.07 18.92 600.18 18.54 600.4 18.12C600.56 17.82 600.64 17.44 601.1 17.59C601.68 17.78 601.61 18.16 601.35 18.57Z" fill="white"/>
                    <path d="M551.59 156.11C551.39 156.68 551.16 156.74 550.9 156.65C550.31 156.45 550.44 156.06 550.67 155.66C550.79 155.45 550.94 154.99 551.09 155.14C551.39 155.44 551.5 155.93 551.59 156.11Z" fill="white"/>
                    <path d="M653.63 14.56C653.68 14.33 653.93 14.25 654.18 14.33C654.51 14.43 654.83 14.64 654.57 15.05C654.38 15.34 654.43 16.02 653.88 15.78C653.68 15.69 653.4 15.1 653.64 14.56H653.63Z" fill="white"/>
                    <path d="M589.97 163.72C589.39 163.53 589.46 162.93 589.25 162.5C589.49 162.51 589.75 162.47 589.95 162.56C590.29 162.71 590.84 162.76 590.57 163.4C590.45 163.69 590.26 163.75 589.97 163.72Z" fill="white"/>
                    <path d="M625.67 4.89003C625.74 4.54003 625.58 4.88003 625.91 4.82003C626.09 4.79003 626.43 4.95003 626.5 5.11003C626.68 5.54003 627.06 5.99003 626.64 6.50003C626.59 6.56003 626.45 6.61003 626.42 6.59003C625.9 6.14003 625.95 5.44003 625.67 4.90003V4.89003Z" fill="white"/>
                    <path d="M578.08 16.69C577.95 16.22 577.54 15.75 577.99 15.29C578.1 15.18 578.5 15.22 578.67 15.33C579.18 15.7 578.7 16.06 578.59 16.42C578.52 16.67 578.37 16.82 578.09 16.68L578.08 16.69Z" fill="white"/>
                    <path d="M505.28 59.03C505.12 58.96 504.94 58.92 504.82 58.8C504.75 58.72 504.71 58.48 504.77 58.42C504.98 58.22 506.45 58.88 506.5 59.2C506.5 59.27 506.28 59.47 506.22 59.45C505.9 59.34 505.59 59.18 505.28 59.03Z" fill="white"/>
                    <path d="M572.83 163.9C572.62 163.71 572.52 163.54 572.66 163.25C572.87 162.81 573.17 162.86 573.48 163.05C573.71 163.19 573.78 163.39 573.64 163.69C573.43 164.13 573.15 164.04 572.83 163.91V163.9Z" fill="white"/>
                    <path d="M411.24 58.41C411.03 58.22 410.93 58.05 411.07 57.76C411.28 57.32 411.58 57.37 411.89 57.56C412.12 57.7 412.19 57.9 412.05 58.2C411.84 58.64 411.56 58.55 411.24 58.42V58.41Z" fill="white"/>
                    <path d="M479.73 161C479.36 161.32 478.96 161.53 478.48 161.37C478.35 161.33 478.16 161.07 478.17 161.06C478.5 160.69 478.9 160.5 479.39 160.66C479.52 160.7 479.61 160.87 479.73 161Z" fill="white"/>
                    <path d="M415.17 92.68C414.87 92.61 414.81 92.35 414.9 92.1C415.01 91.76 415.21 91.52 415.65 91.72C415.95 91.85 416.02 92.06 415.94 92.31C415.83 92.66 415.62 92.91 415.17 92.67V92.68Z" fill="white"/>
                    <path d="M517.21 114.98C517 114.79 516.9 114.62 517.04 114.33C517.25 113.89 517.55 113.94 517.86 114.13C518.09 114.27 518.16 114.47 518.02 114.77C517.81 115.21 517.53 115.12 517.21 114.99V114.98Z" fill="white"/>
                    <path d="M444.51 170.59C444.81 170.74 445.13 170.9 444.88 171.33C444.8 171.46 444.67 171.55 444.56 171.67C444.39 171.34 444.21 171.01 444.04 170.67C444.2 170.65 444.35 170.62 444.52 170.6L444.51 170.59Z" fill="white"/>
                    <path d="M447.54 123.31C447.24 123.16 446.92 123 447.17 122.57C447.25 122.44 447.38 122.35 447.49 122.24C447.66 122.57 447.84 122.9 448.01 123.24C447.85 123.26 447.7 123.29 447.53 123.31H447.54Z" fill="white"/>
                    <path d="M566.93 144.84C566.8 144.51 566.68 144.2 566.56 143.89C566.79 143.9 567.07 143.84 567.25 143.94C567.65 144.16 567.47 144.51 567.28 144.78C567.23 144.85 567.03 144.83 566.93 144.84Z" fill="white"/>
                    <path d="M473.46 28.13C473.61 27.99 473.76 27.76 473.82 27.79C474.24 27.97 474.14 28.45 474.34 28.77C474.18 28.79 474.01 28.87 473.87 28.83C473.53 28.72 473.29 28.51 473.46 28.13Z" fill="white"/>
                    <path d="M553.09 128.57C553.05 128.51 552.89 128.38 552.92 128.3C553.01 127.98 553.15 127.62 553.58 127.77C553.78 127.84 553.91 128.09 554.07 128.26C553.75 128.36 553.44 128.46 553.09 128.57Z" fill="white"/>
                    <path d="M522.88 112.96C522.44 112.78 522.53 112.46 522.71 112.16C522.78 112.04 522.97 111.99 523.1 111.9C523.18 112.27 523.26 112.63 523.35 113C523.19 112.99 523.04 112.97 522.88 112.96Z" fill="white"/>
                    <path d="M396.76 30.84C396.67 30.7 396.58 30.57 396.49 30.44C396.85 30.34 397.21 30.24 397.57 30.14C397.57 30.3 397.59 30.46 397.54 30.6C397.39 31.04 397.08 30.98 396.75 30.83L396.76 30.84Z" fill="white"/>
                    <path d="M620.81 144.86C620.6 144.57 620.83 144.36 620.94 144.15C620.97 144.09 621.26 144.06 621.31 144.12C621.53 144.37 621.29 144.6 621.18 144.8C621.14 144.87 620.93 144.84 620.81 144.86Z" fill="white"/>
                    <path d="M598.01 147.49C597.72 147.45 597.47 147.45 597.27 147.37C597.15 147.32 597.1 147.11 597.01 146.98C597.24 146.99 597.49 146.96 597.71 147.03C597.83 147.07 597.88 147.28 598.01 147.49Z" fill="white"/>
                    <path d="M570.17 158.61C570.06 158.5 569.85 158.35 569.87 158.27C569.95 157.97 570.19 158 570.4 158.15C570.53 158.24 570.72 158.4 570.7 158.48C570.62 158.78 570.39 158.74 570.16 158.61H570.17Z" fill="white"/>
                    <path d="M402.88 38.07C402.59 38.03 402.34 38.03 402.14 37.95C402.02 37.9 401.97 37.69 401.88 37.56C402.11 37.57 402.36 37.54 402.58 37.61C402.7 37.65 402.75 37.86 402.88 38.07Z" fill="white"/>
                    <path d="M633.06 32.34C633 32.24 632.92 32.11 632.83 31.97C633.06 31.98 633.31 31.95 633.53 32.02C633.65 32.06 633.7 32.27 633.79 32.41C633.55 32.53 633.31 32.53 633.06 32.34Z" fill="white"/>
                    <path d="M387.84 75.41C387.93 75.17 388.08 74.98 388.33 75.16C388.4 75.21 388.36 75.46 388.32 75.61C388.25 75.86 388.09 76.04 387.83 75.86C387.76 75.81 387.83 75.56 387.83 75.41H387.84Z" fill="white"/>
                    <path d="M601.17 16.47C601.26 16.23 601.41 16.04 601.66 16.22C601.73 16.27 601.69 16.52 601.65 16.67C601.58 16.92 601.42 17.1 601.16 16.92C601.09 16.87 601.16 16.62 601.16 16.47H601.17Z" fill="white"/>
                    <path d="M590.7 20.31C590.79 20.07 590.94 19.88 591.19 20.06C591.26 20.11 591.22 20.36 591.18 20.51C591.11 20.76 590.95 20.94 590.69 20.76C590.62 20.71 590.69 20.46 590.69 20.31H590.7Z" fill="white"/>
                    <path d="M554.5 39.83C554.21 39.79 553.96 39.79 553.76 39.71C553.64 39.66 553.59 39.45 553.5 39.32C553.73 39.33 553.98 39.3 554.2 39.37C554.32 39.41 554.37 39.62 554.5 39.83Z" fill="white"/>
                    <path d="M558.92 135.48C558.8 135.32 558.66 135.14 558.51 134.96C558.67 134.94 558.87 134.84 558.97 134.9C559.16 135.02 559.3 135.23 559.46 135.4C559.3 135.42 559.14 135.45 558.91 135.48H558.92Z" fill="white"/>
                    <path d="M494.98 81.48C494.92 81.38 494.84 81.25 494.75 81.11C494.98 81.12 495.23 81.09 495.45 81.16C495.57 81.2 495.62 81.41 495.71 81.55C495.47 81.67 495.23 81.67 494.98 81.48Z" fill="white"/>
                    <path d="M472.06 115.29C471.77 115.25 471.52 115.25 471.32 115.17C471.2 115.12 471.15 114.91 471.06 114.78C471.29 114.79 471.54 114.76 471.76 114.83C471.88 114.87 471.93 115.08 472.06 115.29Z" fill="white"/>
                    <path d="M436.53 168.56C436.32 168.27 436.55 168.06 436.66 167.85C436.69 167.79 436.98 167.76 437.03 167.82C437.25 168.07 437.01 168.3 436.9 168.5C436.86 168.57 436.65 168.54 436.53 168.56Z" fill="white"/>
                    <path d="M435.05 111.16C435.14 110.92 435.29 110.73 435.54 110.91C435.61 110.96 435.57 111.21 435.53 111.36C435.46 111.61 435.3 111.79 435.04 111.61C434.97 111.56 435.04 111.31 435.04 111.16H435.05Z" fill="white"/>
                    <path d="M496.59 29.5C496.48 29.39 496.27 29.24 496.29 29.16C496.37 28.86 496.61 28.89 496.82 29.04C496.95 29.13 497.14 29.29 497.12 29.37C497.04 29.67 496.81 29.63 496.58 29.5H496.59Z" fill="white"/>
                    <path d="M483.86 24.48C483.73 24.48 483.59 24.51 483.47 24.48C483.43 24.48 483.41 24.34 483.38 24.26C483.51 24.26 483.65 24.23 483.77 24.26C483.81 24.26 483.83 24.4 483.86 24.48Z" fill="white"/>
                    <path d="M387.52 29.75C387.39 29.75 387.25 29.78 387.13 29.75C387.09 29.75 387.07 29.61 387.04 29.53C387.17 29.53 387.31 29.5 387.43 29.53C387.47 29.53 387.49 29.67 387.52 29.75Z" fill="white"/>
                    <path d="M583.76 28.6C583.69 28.5 583.61 28.39 583.53 28.29C583.61 28.26 583.72 28.19 583.76 28.21C583.86 28.29 583.93 28.41 584.01 28.51C583.93 28.54 583.85 28.57 583.76 28.6Z" fill="white"/>
                    <path d="M580.3 68.03C580.4 67.96 580.51 67.88 580.61 67.8C580.64 67.88 580.71 67.99 580.69 68.03C580.61 68.13 580.49 68.2 580.39 68.28C580.36 68.2 580.33 68.12 580.3 68.03Z" fill="white"/>
                    <path d="M541.47 80.99C541.47 80.86 541.44 80.72 541.47 80.6C541.47 80.56 541.61 80.54 541.69 80.51C541.69 80.64 541.72 80.78 541.69 80.9C541.69 80.94 541.55 80.96 541.47 80.99Z" fill="white"/>
                    <path d="M519.51 117.98C519.6 117.95 519.68 117.92 519.76 117.89C519.76 118.02 519.79 118.16 519.76 118.28C519.76 118.32 519.62 118.34 519.54 118.37C519.54 118.24 519.52 118.11 519.51 117.98Z" fill="white"/>
                    <path d="M557.92 154.59C557.85 154.49 557.77 154.38 557.69 154.28C557.77 154.25 557.88 154.18 557.92 154.2C558.02 154.28 558.09 154.4 558.17 154.5C558.09 154.53 558.01 154.56 557.92 154.59Z" fill="white"/>
                    <path d="M589.07 53.08C589.04 52.99 589.01 52.91 588.98 52.83C589.11 52.83 589.25 52.8 589.37 52.83C589.41 52.83 589.43 52.97 589.46 53.05C589.33 53.05 589.2 53.07 589.07 53.08Z" fill="white"/>
                    <path d="M523.45 59.98C523.48 60.07 523.51 60.15 523.54 60.23C523.41 60.23 523.27 60.26 523.15 60.23C523.11 60.23 523.09 60.09 523.06 60.01C523.19 60.01 523.32 59.99 523.45 59.98Z" fill="white"/>
                    <path d="M460.7 170.89C460.62 170.92 460.49 170.99 460.48 170.97C460.4 170.87 460.3 170.74 460.3 170.62C460.3 170.38 460.44 170.32 460.59 170.51C460.67 170.6 460.66 170.76 460.69 170.89H460.7Z" fill="white"/>
                    <path d="M460.17 170.31C460.14 170.18 460.04 169.99 460.09 169.94C460.23 169.78 460.39 169.82 460.41 169.98C460.43 170.12 460.39 170.27 460.38 170.41C460.31 170.38 460.24 170.34 460.17 170.31Z" fill="white"/>
                    <path d="M529.95 202.51C529.88 202.41 529.8 202.3 529.72 202.2C529.8 202.17 529.91 202.1 529.95 202.12C530.05 202.2 530.12 202.32 530.2 202.42C530.12 202.45 530.04 202.48 529.95 202.51Z" fill="white"/>
                    <path d="M454.34 173.9C454.31 173.81 454.28 173.73 454.25 173.65C454.38 173.65 454.52 173.62 454.64 173.65C454.68 173.65 454.7 173.79 454.73 173.87C454.6 173.87 454.47 173.89 454.34 173.9Z" fill="white"/>
                    <path d="M553.29 171.75C553.36 171.85 553.44 171.96 553.52 172.06C553.44 172.09 553.33 172.16 553.29 172.14C553.19 172.06 553.12 171.94 553.04 171.84C553.12 171.81 553.2 171.78 553.29 171.75Z" fill="white"/>
                    <path d="M460.63 81.75C460.56 81.65 460.48 81.54 460.4 81.44C460.48 81.41 460.59 81.34 460.63 81.36C460.73 81.44 460.8 81.56 460.88 81.66C460.8 81.69 460.72 81.72 460.63 81.75Z" fill="white"/>
                    <path d="M581.91 95.85C582 95.82 582.08 95.79 582.16 95.76C582.16 95.89 582.19 96.03 582.16 96.15C582.16 96.19 582.02 96.21 581.94 96.24C581.94 96.11 581.92 95.98 581.91 95.85Z" fill="white"/>
                    <path d="M456.15 143.23C456.02 143.23 455.88 143.26 455.76 143.23C455.72 143.23 455.7 143.09 455.67 143.01C455.8 143.01 455.94 142.98 456.06 143.01C456.1 143.01 456.12 143.15 456.15 143.23Z" fill="white"/>
                    <path d="M579.31 107.11C579.24 107.01 579.16 106.9 579.08 106.8C579.16 106.77 579.27 106.7 579.31 106.72C579.41 106.8 579.48 106.92 579.56 107.02L579.31 107.11Z" fill="white"/>
                    <path d="M502.36 38.17C502.26 38.24 502.15 38.32 502.05 38.4C502.02 38.32 501.95 38.21 501.97 38.17C502.05 38.07 502.17 38 502.27 37.92C502.3 38 502.33 38.08 502.36 38.17Z" fill="white"/>
                    <path d="M505.73 61.56C505.54 60.48 506.25 59.56 506.63 59.5C508.09 59.25 508.43 57.81 509.5 57.17C510.26 56.72 510.79 56.56 511.46 57.08C512.57 57.94 512.81 58.54 512.36 59.47C512.17 59.87 512.03 60.27 511.89 60.7C511.31 62.54 507.67 63.93 506.2 62.28C505.94 61.99 505.71 61.75 505.73 61.56Z" fill="white"/>
                    <path d="M589.19 30.04C589.76 29.26 589.29 27.94 590.58 27.58C590.76 27.53 590.99 27.56 591.13 27.46C591.69 27.03 592.14 26.37 592.99 26.63C593.66 26.83 593.89 27.49 594.27 27.98C595.01 28.95 594.77 30.65 593.8 31.58C593.05 32.3 592.22 32.43 591.21 32.1C590.16 31.76 589.85 30.77 589.21 30.04H589.19Z" fill="white"/>
                    <path d="M671.71 133.96C672.02 135.57 671.57 136.42 670.43 136.78C669.81 136.98 669.13 137.02 668.49 137.19C667.89 137.34 667.65 137.12 667.57 136.53C667.48 135.88 667.23 135.24 667.22 134.59C667.2 133.49 667.8 132.64 668.67 132.01C668.85 131.88 669.25 131.84 669.41 131.95C670.02 132.37 670.71 132.55 671.36 132.83C671.68 132.97 671.68 133.84 671.71 133.95V133.96Z" fill="white"/>
                    <path d="M554.85 175.17C554.65 174.45 554.34 173.44 555.41 172.91C555.92 172.66 556.87 172.19 557.2 172.82C557.56 173.5 558.53 173.96 558.24 174.93C558.04 175.6 557.59 176.25 557.56 176.92C557.53 177.54 557.36 177.7 556.82 177.81C556.04 177.98 555.53 177.6 555.25 177.02C555.01 176.53 555 175.92 554.85 175.17Z" fill="white"/>
                    <path d="M692.08 131.65C691.92 130.92 692.37 130.18 693.08 130C693.32 129.94 695.86 130.79 695.99 130.97C696.28 131.38 695.64 133 695.58 132.93C694.74 132.08 694.44 133.51 693.81 133.29C693.21 133.08 692.18 132.12 692.08 131.65Z" fill="white"/>
                    <path d="M563.88 57.04C563.96 57.63 564.41 58.33 563.29 58.5C563.04 58.54 562.88 59.09 562.65 59.39C562.39 59.73 562.11 60.05 561.83 60.37C561.65 59.76 561.22 59.1 561.34 58.57C561.5 57.89 562.13 57.32 562.52 56.68C562.73 56.33 563 56.04 563.39 56.13C563.82 56.22 563.79 56.69 563.87 57.04H563.88Z" fill="white"/>
                    <path d="M710.18 18.38C710.22 18.56 710.27 18.74 710.28 18.92C710.3 19.33 710.72 19.85 710.12 20.12C709.5 20.4 708.9 20.31 708.35 19.83C707.88 19.42 708.04 19.09 708.28 18.65C708.44 18.37 708.35 17.95 708.51 17.66C708.71 17.28 708.88 16.76 709.45 16.79C710.13 16.82 709.93 17.44 710.07 17.82C710.13 17.99 710.15 18.18 710.18 18.36V18.38Z" fill="white"/>
                    <path d="M687.6 140.92C688.42 141.19 689.36 141.22 690.03 141.89C690.18 142.04 690.26 142.39 690.03 142.66C689.74 143 689.61 143.62 689.06 143.51C688.38 143.37 687.72 143.08 687.09 142.79C686.34 142.44 687.27 142.11 687.14 141.73C687.01 141.34 687.08 141 687.6 140.93V140.92Z" fill="white"/>
                    <path d="M552.16 12.39C552.34 12.88 552.23 13.21 551.61 13.31C550.65 13.45 550.01 13.05 549.8 12.21C549.62 11.46 550 10.95 550.55 10.65C551.18 10.31 551.95 11.17 552.16 12.4V12.39Z" fill="white"/>
                    <path d="M670.23 91.4C670.42 91.12 670.58 90.9 670.72 90.66C671.07 90.05 672.01 90.53 672.31 89.77C672.47 89.37 672.89 89.29 673.22 89.56C673.53 89.82 673.49 90.18 673.24 90.52C672.91 90.97 672.64 91.47 672.29 91.9C672.13 92.11 672.11 92.7 671.57 92.31C671.13 91.99 670.67 91.7 670.22 91.4H670.23Z" fill="white"/>
                    <path d="M621.07 197.49C620.47 197.52 619.74 196.71 619.75 196.03C619.75 195.76 621 194.54 621.33 194.56C622.17 194.62 621.92 195.38 622 195.83C622.16 196.68 621.65 197.47 621.08 197.49H621.07Z" fill="white"/>
                    <path d="M566.77 140.54C567.32 140.34 567.69 140.4 567.78 141.04C567.9 141.82 567.51 142.7 566.96 142.8C566.41 142.91 565.69 142.24 565.55 141.46C565.33 140.3 566.36 140.78 566.77 140.53V140.54Z" fill="white"/>
                    <path d="M560.27 138.19C560.45 137.7 560.62 137.16 561.19 137.01C561.81 136.84 562.67 137.81 562.66 138.58C562.66 139.09 562.28 139.13 561.92 139.16C561.09 139.24 560.3 138.78 560.26 138.19H560.27Z" fill="white"/>
                    <path d="M643.12 112.33C643.19 112.69 643.21 112.88 643.27 113.05C643.49 113.7 642.91 114.03 642.69 114.5C642.31 114.15 641.64 114.07 641.6 113.38C641.59 113.2 641.54 113.01 641.49 112.84C641.32 112.24 641.32 111.83 642.13 111.69C642.91 111.55 643.15 111.82 643.12 112.33Z" fill="white"/>
                    <path d="M480.93 126.84C481.57 126.67 481.79 127.07 481.71 127.42C481.55 128.1 481.27 128.76 480.98 129.41C480.71 130.01 480.42 129.49 480.16 129.37C479.03 128.83 480.15 128.11 480.03 127.47C479.9 126.73 480.68 127.02 480.92 126.84H480.93Z" fill="white"/>
                    <path d="M613.5 129.35C613.21 128.62 613.6 128.09 614.14 127.77C614.54 127.53 615.82 128.43 615.7 128.8C615.47 129.51 614.95 129.9 614.18 129.99C613.64 130.05 613.58 129.71 613.5 129.35Z" fill="white"/>
                    <path d="M650.43 144.22C650.14 144.19 649.83 144.05 649.93 143.73C650.16 142.97 650.19 142.15 650.7 141.47C650.91 141.18 651.21 141.1 651.42 141.17C651.61 141.23 651.87 141.62 651.82 141.79C651.64 142.47 651.36 143.13 651.1 143.78C650.99 144.06 650.75 144.2 650.43 144.21V144.22Z" fill="white"/>
                    <path d="M668.27 169.26C668.19 168.97 668.07 168.61 668.54 168.42C668.94 168.25 669.2 168.56 669.52 168.7C670.17 168.99 670.11 169.61 670.13 170.1C670.14 170.39 669.75 170.97 669.57 170.96C669.2 170.94 668.76 170.68 668.52 170.38C668.32 170.13 668.35 169.68 668.27 169.26Z" fill="white"/>
                    <path d="M513.3 166.16C512.86 166.32 512.52 166.21 512.44 165.59C512.33 164.81 512.72 163.96 513.27 163.84C514.19 163.64 513.79 164.92 514.54 165C514.67 165.01 514 165.97 513.3 166.16Z" fill="white"/>
                    <path d="M652.28 59.19C652.36 59.04 652.39 58.8 652.52 58.75C653.19 58.44 654.64 59.19 654.73 59.89C654.76 60.13 654.73 60.49 654.58 60.61C654.42 60.73 654.05 60.7 653.84 60.59C652.9 60.14 652.39 59.65 652.29 59.19H652.28Z" fill="white"/>
                    <path d="M648.38 141.81C648.38 142.4 647.99 142.42 647.63 142.45C646.78 142.53 646.02 142.07 646 141.47C645.98 140.97 646.39 140.93 646.75 140.9C647.6 140.82 648.34 141.28 648.39 141.81H648.38Z" fill="white"/>
                    <path d="M514.42 174.05C513.89 173.67 513.3 173.44 513.23 172.64C513.17 171.99 513.49 171.9 513.97 171.79C514.5 171.67 514.76 171.73 514.96 172.33C515.22 173.11 514.68 173.5 514.42 174.05Z" fill="white"/>
                    <path d="M508.11 161.25C508.17 161.09 508.2 160.83 508.31 160.79C508.65 160.67 509.09 160.48 509.37 160.59C509.83 160.78 510.44 160.97 510.49 161.63C510.53 162.14 510.12 162.18 509.76 162.21C508.9 162.29 508.18 161.86 508.11 161.25Z" fill="white"/>
                    <path d="M494.23 21.75C494.9 21.66 495.74 22.29 495.75 22.77C495.76 23.27 495.36 23.26 495.02 23.35C494.33 23.53 493.79 23.24 493.58 22.66C493.52 22.49 493.87 21.82 494.23 21.75Z" fill="white"/>
                    <path d="M596.75 141.24C596.66 140.78 596.59 140.28 597.09 140.33C597.71 140.39 598.31 140.68 598.92 140.88C598.6 141.44 598.27 141.92 597.5 141.98C596.77 142.03 596.86 141.53 596.75 141.24Z" fill="white"/>
                    <path d="M465.71 87.3C465.48 86.56 465.83 86.44 466.35 86.29C467.16 86.06 467.28 86.49 467.4 87.1C467.53 87.73 467.23 87.86 466.7 87.99C465.92 88.19 465.67 87.87 465.7 87.29L465.71 87.3Z" fill="white"/>
                    <path d="M607.99 145.67C608.49 145.67 609.2 145.14 609.26 146.1C609.29 146.62 608.96 147.15 608.4 147.33C607.46 147.62 607.53 146.54 607.2 146.18C606.78 145.73 607.75 145.92 608 145.68L607.99 145.67Z" fill="white"/>
                    <path d="M685.05 177.99C685 177.72 684.9 177.45 684.9 177.18C684.9 176.68 685.3 176.66 685.66 176.61C685.92 176.58 686.2 176.38 686.43 176.65C686.69 176.96 686.69 177.29 686.43 177.61C686.32 177.75 686.23 177.92 686.12 178.07C685.91 178.36 685.69 178.88 685.5 178.87C685.08 178.85 685.16 178.32 685.06 177.99H685.05Z" fill="white"/>
                    <path d="M540.92 120.74C541.41 120.66 541.79 120.6 541.84 121.14C541.89 121.69 541.28 122.51 540.79 122.51C540.29 122.51 540.33 122.08 540.21 121.75C539.93 120.94 540.42 120.8 540.91 120.75L540.92 120.74Z" fill="white"/>
                    <path d="M641.43 163.98C641.24 163.96 640.85 164.04 640.75 163.9C640.48 163.53 640.91 163.26 641.11 163.08C641.4 162.82 641.29 161.79 642.15 162.45C642.43 162.67 642.81 162.82 642.77 163.25C642.7 164.11 641.9 163.66 641.43 163.98Z" fill="white"/>
                    <path d="M692.22 27.03C692.4 26.63 692.5 25.82 693.02 26.07C693.46 26.28 694.14 25.88 694.33 26.52C694.44 26.91 693.2 27.72 692.67 27.63C692.39 27.58 692.23 27.36 692.22 27.03Z" fill="white"/>
                    <path d="M482.5 81.95C482.5 81.95 482.15 81.59 481.89 81.22C481.78 81.06 482.68 80.13 482.95 80.14C483.65 80.15 483.46 80.76 483.58 81.14C483.73 81.61 483.45 81.78 482.51 81.96L482.5 81.95Z" fill="white"/>
                    <path d="M480.61 48.6C480.67 47.93 481.25 48.11 481.64 47.98C482.13 47.82 482.38 48.17 482.31 48.53C482.23 48.94 481.95 49.34 481.68 49.69C481.58 49.82 480.61 48.85 480.61 48.6Z" fill="white"/>
                    <path d="M672.4 66.96C671.8 66.99 671.29 66.7 671.19 66.23C671.14 66 671.47 65.38 672.01 65.29C672.74 65.16 672.63 65.76 672.74 66.15C672.84 66.52 672.86 66.87 672.39 66.97L672.4 66.96Z" fill="white"/>
                    <path d="M675.12 72.07C675.18 71.55 675.4 70.98 676.15 70.86C676.42 70.81 676.69 70.96 676.68 71.31C676.65 71.99 676.36 72.52 675.65 72.67C675.38 72.72 675.12 72.58 675.12 72.08V72.07Z" fill="white"/>
                    <path d="M738.47 73.58C738.34 73.08 738.66 72.82 739.02 72.89C739.43 72.97 739.83 73.25 740.18 73.52C740.31 73.62 739.34 74.59 739.09 74.59C738.73 74.59 738.49 74.2 738.47 73.58Z" fill="white"/>
                    <path d="M605.52 141.72C605.45 141.33 605.38 140.95 605.91 140.89C606.48 140.82 606.99 141.14 607.08 141.61C607.13 141.85 606.8 142.46 606.26 142.55C605.52 142.68 605.66 142.07 605.52 141.72Z" fill="white"/>
                    <path d="M694.92 148.54C695.07 148.03 695.26 147.58 695.81 147.4C696.13 147.3 696.39 147.46 696.41 147.74C696.44 148.2 697.01 148.81 696.12 149.07C695.58 149.23 695.35 148.68 694.92 148.54Z" fill="white"/>
                    <path d="M612.61 130.73C612.68 130.58 612.75 130.42 612.83 130.27C613.52 130.71 614.14 131.27 615.03 131.29C615.07 131.29 615.15 131.65 615.12 131.82C615.1 131.92 614.82 132.08 614.79 132.05C614.21 131.57 613.3 131.88 612.81 131.22C612.71 131.08 612.67 130.89 612.6 130.73H612.61Z" fill="white"/>
                    <path d="M616.65 128.93C617.2 128.85 617.04 129.62 617.09 130.05C617.17 130.91 616.36 130.61 615.93 130.77C615.8 130.82 615.24 130.62 615.67 130.38C616.31 130.03 615.28 129.05 616.65 128.93Z" fill="white"/>
                    <path d="M488.07 85.48C488.47 85.46 488.98 85.12 489.14 85.86C489.26 86.39 488.92 86.49 488.54 86.55C488.13 86.61 487.63 86.92 487.46 86.19C487.34 85.65 487.69 85.58 488.07 85.49V85.48Z" fill="white"/>
                    <path d="M459.09 86.2C459.05 86.07 459.39 85.93 459.78 85.88C460.19 85.83 460.7 85.49 460.87 86.23C460.99 86.75 460.67 86.87 460.28 86.93C459.87 86.99 459.36 87.3 459.09 86.2Z" fill="white"/>
                    <path d="M516.61 103.65C516.73 103.7 516.96 103.72 516.98 103.8C517.16 104.41 517.32 105.01 516.78 105.57C516.56 105.8 516.4 105.9 516.21 105.64C515.92 105.23 516.21 103.85 516.6 103.65H516.61Z" fill="white"/>
                    <path d="M581.4 101.61C581.45 100.71 582.25 101.13 582.69 100.95C582.89 100.87 583.13 101.25 583.1 101.54C583.03 102.39 582.27 102.08 581.81 102.21C581.6 102.27 581.39 101.93 581.39 101.61H581.4Z" fill="white"/>
                    <path d="M672.01 12.82C671.97 12.69 672.31 12.55 672.7 12.5C673.11 12.45 673.62 12.11 673.79 12.85C673.91 13.37 673.59 13.49 673.2 13.55C672.79 13.61 672.28 13.92 672.01 12.82Z" fill="white"/>
                    <path d="M584.76 176.23C585.14 176.68 585.53 177.15 585.91 177.63C585.93 177.65 585.83 177.81 585.76 177.84C585.4 177.99 585.08 177.91 584.76 177.67C584.39 177.4 583.98 177.17 584.18 176.63C584.23 176.5 584.45 176.44 584.76 176.23Z" fill="white"/>
                    <path d="M629.25 148.99C628.89 149.09 628.5 149.22 628.34 148.73C628.13 148.1 628.53 147.95 629.04 147.87C629.4 147.82 629.78 147.65 629.95 148.15C630.15 148.78 629.78 148.96 629.25 148.99Z" fill="white"/>
                    <path d="M670.09 108.59C670.15 108.07 670.23 107.81 670.58 107.83C671.26 107.86 671.78 108.15 671.94 108.86C671.97 109 671.82 109.18 671.76 109.33C671.18 109.07 670.61 108.82 670.1 108.59H670.09Z" fill="white"/>
                    <path d="M557.87 120.05C557.7 119.57 557.73 119.17 557.97 119.14C558.44 119.08 559.03 118.52 559.42 119.27C559.55 119.53 559.51 119.93 559.26 119.98C558.79 120.08 558.23 120.47 557.88 120.05H557.87Z" fill="white"/>
                    <path d="M576.67 14.12C575.77 14.07 576.19 13.27 576.01 12.83C575.93 12.63 576.31 12.39 576.6 12.42C577.45 12.49 577.14 13.25 577.27 13.71C577.33 13.92 576.99 14.13 576.67 14.13V14.12Z" fill="white"/>
                    <path d="M674.07 118.13C673.71 118.23 673.32 118.36 673.16 117.87C672.95 117.24 673.35 117.09 673.86 117.01C674.22 116.96 674.6 116.79 674.77 117.29C674.97 117.92 674.6 118.1 674.07 118.13Z" fill="white"/>
                    <path d="M522.43 162C521.81 162.19 521.61 162.03 521.53 161.74C521.34 161.1 521.75 160.96 522.25 160.91C522.51 160.88 523.01 160.72 522.97 160.95C522.89 161.4 522.54 161.81 522.44 162H522.43Z" fill="white"/>
                    <path d="M710.75 161.21C710.98 161.11 711.21 161.28 711.3 161.54C711.42 161.89 711.45 162.31 710.91 162.34C710.54 162.36 709.98 162.84 709.84 162.2C709.79 161.97 710.13 161.35 710.75 161.21Z" fill="white"/>
                    <path d="M540.15 200.33C539.94 199.7 540.52 199.39 540.75 198.93C540.89 199.14 541.09 199.34 541.14 199.58C541.22 199.97 541.53 200.48 540.8 200.66C540.47 200.74 540.3 200.61 540.14 200.34L540.15 200.33Z" fill="white"/>
                    <path d="M701.44 130.67C701.8 130.51 701.39 130.58 701.66 130.83C701.8 130.97 701.88 131.37 701.78 131.53C701.51 131.96 701.37 132.58 700.66 132.53C700.58 132.53 700.44 132.43 700.44 132.39C700.5 131.66 701.15 131.26 701.44 130.66V130.67Z" fill="white"/>
                    <path d="M660.94 96.62C661.26 96.21 661.41 95.55 662.1 95.65C662.26 95.67 662.49 96.05 662.49 96.27C662.49 96.95 661.87 96.76 661.49 96.89C661.23 96.98 661 96.96 660.94 96.62Z" fill="white"/>
                    <path d="M577.77 59.95C577.73 59.77 577.65 59.58 577.68 59.41C577.7 59.3 577.89 59.11 577.98 59.13C578.28 59.19 578.64 60.89 578.4 61.13C578.34 61.18 578.03 61.11 578.01 61.04C577.9 60.69 577.85 60.32 577.78 59.95H577.77Z" fill="white"/>
                    <path d="M729.54 34.3C729.39 33.74 729.71 33.58 730.1 33.55C730.39 33.53 730.58 33.66 730.65 34C730.75 34.51 730.45 34.66 730.06 34.69C729.79 34.71 729.5 34.62 729.54 34.3Z" fill="white"/>
                    <path d="M529.11 185.47C529.14 185.16 529.23 184.97 529.57 184.91C530.08 184.81 530.23 185.11 530.26 185.5C530.28 185.79 530.15 185.98 529.81 186.05C529.29 186.15 529.19 185.84 529.11 185.48V185.47Z" fill="white"/>
                    <path d="M472.54 102.37C472.03 102.25 471.6 102.04 471.42 101.51C471.37 101.37 471.48 101.04 471.5 101.04C472.03 101.09 472.46 101.32 472.63 101.85C472.67 101.99 472.58 102.18 472.55 102.36L472.54 102.37Z" fill="white"/>
                    <path d="M491.2 2.65997C491.07 2.34997 491.26 2.13997 491.53 2.05997C491.9 1.93997 492.23 1.95997 492.34 2.47997C492.41 2.81997 492.28 3.01997 492.01 3.10997C491.64 3.22997 491.28 3.20997 491.21 2.66997L491.2 2.65997Z" fill="white"/>
                    <path d="M536.51 105.88C536.54 105.57 536.63 105.38 536.97 105.32C537.48 105.22 537.63 105.52 537.66 105.91C537.68 106.2 537.55 106.39 537.21 106.46C536.69 106.56 536.59 106.25 536.51 105.89V105.88Z" fill="white"/>
                    <path d="M730.37 97.08C730.4 96.72 730.49 96.53 730.84 96.47C731.35 96.37 731.5 96.67 731.53 97.06C731.55 97.35 731.42 97.54 731.08 97.61C730.55 97.72 730.48 97.38 730.37 97.09V97.08Z" fill="white"/>
                    <path d="M708.97 106.01C709.49 105.87 709.65 106.19 709.68 106.58C709.7 106.87 709.57 107.06 709.23 107.13C708.72 107.23 708.57 106.93 708.54 106.54C708.52 106.26 708.64 106.01 708.97 106.01Z" fill="white"/>
                    <path d="M441.81 77.72C441.87 78.08 441.93 78.46 441.4 78.51C441.24 78.53 441.07 78.47 440.9 78.44C441.08 78.08 441.26 77.71 441.44 77.35C441.56 77.47 441.68 77.59 441.81 77.72Z" fill="white"/>
                    <path d="M485.01 50.35C484.95 49.99 484.89 49.61 485.42 49.56C485.58 49.54 485.75 49.6 485.92 49.63C485.74 49.99 485.56 50.36 485.38 50.72C485.26 50.6 485.14 50.48 485.01 50.35Z" fill="white"/>
                    <path d="M542 168.24C542.21 167.92 542.4 167.61 542.6 167.31C542.74 167.52 542.96 167.72 542.99 167.95C543.05 168.44 542.64 168.5 542.28 168.51C542.18 168.51 542.08 168.32 542.01 168.24H542Z" fill="white"/>
                    <path d="M584.54 12.55C584.76 12.59 585.05 12.58 585.07 12.65C585.18 13.13 584.7 13.35 584.54 13.73C584.42 13.61 584.24 13.5 584.19 13.36C584.07 12.99 584.1 12.65 584.54 12.56V12.55Z" fill="white"/>
                    <path d="M547.42 145.82C547.45 145.75 547.46 145.53 547.55 145.5C547.88 145.37 548.29 145.27 548.43 145.74C548.49 145.96 548.36 146.23 548.32 146.48C548.03 146.27 547.74 146.06 547.43 145.83L547.42 145.82Z" fill="white"/>
                    <path d="M541.86 109.54C541.74 109.04 542.08 108.92 542.45 108.88C542.6 108.87 542.77 109 542.92 109.06C542.65 109.36 542.39 109.67 542.12 109.97C542.03 109.83 541.95 109.68 541.85 109.53L541.86 109.54Z" fill="white"/>
                    <path d="M699.54 90.52C699.04 90.64 698.92 90.3 698.88 89.93C698.87 89.78 699 89.61 699.06 89.46C699.36 89.73 699.67 89.99 699.97 90.26C699.83 90.35 699.68 90.43 699.53 90.53L699.54 90.52Z" fill="white"/>
                    <path d="M737.3 80.77C737.14 80.59 736.97 80.49 736.92 80.34C736.8 79.96 736.85 79.61 737.35 79.57C737.51 79.56 737.68 79.63 737.84 79.66C737.66 80.02 737.48 80.38 737.3 80.77Z" fill="white"/>
                    <path d="M576.18 215.27C576.3 214.91 576.63 214.97 576.88 214.93C576.96 214.92 577.16 215.15 577.14 215.23C577.06 215.58 576.71 215.52 576.46 215.55C576.38 215.56 576.27 215.36 576.18 215.26V215.27Z" fill="white"/>
                    <path d="M559.41 197.04C559.26 196.76 559.1 196.55 559.05 196.31C559.02 196.17 559.16 196 559.23 195.84C559.37 196.05 559.55 196.25 559.63 196.48C559.67 196.61 559.52 196.79 559.42 197.04H559.41Z" fill="white"/>
                    <path d="M532.04 179.79C532.07 179.62 532.06 179.35 532.15 179.31C532.47 179.19 532.59 179.42 532.6 179.7C532.6 179.86 532.58 180.14 532.5 180.18C532.19 180.3 532.08 180.07 532.05 179.79H532.04Z" fill="white"/>
                    <path d="M682.17 154.55C682.22 154.44 682.28 154.28 682.35 154.12C682.49 154.33 682.67 154.53 682.75 154.76C682.79 154.89 682.64 155.07 682.57 155.23C682.31 155.1 682.16 154.89 682.17 154.55Z" fill="white"/>
                    <path d="M675.79 116.63C676.06 116.55 676.31 116.57 676.32 116.9C676.32 116.99 676.07 117.11 675.92 117.17C675.66 117.27 675.4 117.24 675.39 116.9C675.39 116.81 675.65 116.71 675.79 116.62V116.63Z" fill="white"/>
                    <path d="M665.78 109.93C666.05 109.85 666.3 109.87 666.31 110.2C666.31 110.29 666.06 110.41 665.91 110.47C665.65 110.57 665.39 110.54 665.38 110.2C665.38 110.11 665.64 110.01 665.78 109.92V109.93Z" fill="white"/>
                    <path d="M625.77 90.73C625.62 90.45 625.46 90.24 625.41 90C625.38 89.86 625.52 89.69 625.59 89.53C625.73 89.74 625.91 89.94 625.99 90.17C626.03 90.3 625.88 90.48 625.78 90.73H625.77Z" fill="white"/>
                    <path d="M545.09 155.3C545.15 155.09 545.22 154.85 545.29 154.61C545.41 154.73 545.62 154.85 545.63 154.98C545.65 155.22 545.56 155.48 545.51 155.72C545.39 155.6 545.27 155.48 545.09 155.3Z" fill="white"/>
                    <path d="M551.64 65.2C551.69 65.09 551.75 64.93 551.82 64.77C551.96 64.98 552.14 65.18 552.22 65.41C552.26 65.54 552.11 65.72 552.04 65.88C551.78 65.75 551.63 65.54 551.64 65.2Z" fill="white"/>
                    <path d="M507.57 66.66C507.42 66.38 507.26 66.17 507.21 65.93C507.18 65.79 507.32 65.62 507.39 65.46C507.53 65.67 507.71 65.87 507.79 66.1C507.83 66.23 507.68 66.41 507.58 66.66H507.57Z" fill="white"/>
                    <path d="M438.52 69.46C438.64 69.1 438.97 69.16 439.22 69.12C439.3 69.11 439.5 69.34 439.48 69.42C439.4 69.77 439.05 69.71 438.8 69.74C438.72 69.75 438.61 69.55 438.52 69.45V69.46Z" fill="white"/>
                    <path d="M487.68 31.74C487.95 31.66 488.2 31.68 488.21 32.01C488.21 32.1 487.96 32.22 487.81 32.28C487.55 32.38 487.29 32.35 487.28 32.01C487.28 31.92 487.54 31.82 487.68 31.73V31.74Z" fill="white"/>
                    <path d="M684.82 1.40998C684.65 1.37998 684.38 1.38998 684.34 1.29998C684.22 0.979976 684.45 0.859976 684.73 0.849976C684.89 0.849976 685.17 0.869976 685.21 0.949976C685.33 1.25998 685.1 1.36998 684.82 1.39998V1.40998Z" fill="white"/>
                    <path d="M598.03 33.62C598.06 33.45 598.05 33.18 598.14 33.14C598.46 33.02 598.58 33.25 598.59 33.53C598.59 33.69 598.57 33.97 598.49 34.01C598.18 34.13 598.07 33.9 598.04 33.62H598.03Z" fill="white"/>
                    <path d="M594.33 19.32C594.24 19.21 594.14 19.11 594.08 18.98C594.06 18.94 594.17 18.84 594.22 18.76C594.31 18.87 594.41 18.97 594.47 19.1C594.49 19.14 594.38 19.24 594.33 19.32Z" fill="white"/>
                    <path d="M654.14 109.14C654.18 109.01 654.22 108.88 654.27 108.74C654.34 108.79 654.48 108.84 654.48 108.89C654.48 109.03 654.42 109.16 654.38 109.3C654.31 109.25 654.23 109.2 654.14 109.14Z" fill="white"/>
                    <path d="M698.29 113.71C698.23 113.8 698.18 113.87 698.13 113.95C698.04 113.84 697.94 113.74 697.88 113.61C697.86 113.57 697.97 113.47 698.02 113.39C698.11 113.5 698.2 113.61 698.29 113.71Z" fill="white"/>
                    <path d="M617.53 131.14C617.66 131.18 617.79 131.22 617.93 131.27C617.88 131.34 617.83 131.48 617.78 131.48C617.64 131.48 617.51 131.42 617.37 131.38C617.42 131.31 617.47 131.23 617.53 131.14Z" fill="white"/>
                    <path d="M581.57 105.47C581.68 105.38 581.78 105.28 581.91 105.22C581.95 105.2 582.05 105.31 582.13 105.36C582.02 105.45 581.92 105.55 581.79 105.61C581.75 105.63 581.65 105.52 581.57 105.47Z" fill="white"/>
                    <path d="M535.34 109.78C535.43 109.84 535.5 109.89 535.58 109.94C535.47 110.03 535.37 110.13 535.24 110.19C535.2 110.21 535.1 110.1 535.02 110.05C535.13 109.96 535.24 109.87 535.34 109.78Z" fill="white"/>
                    <path d="M527.76 166.55C527.8 166.42 527.84 166.29 527.89 166.15C527.96 166.2 528.1 166.25 528.1 166.3C528.1 166.44 528.04 166.57 528 166.71C527.93 166.66 527.85 166.61 527.76 166.55Z" fill="white"/>
                    <path d="M636.15 129.31C636.21 129.22 636.26 129.15 636.31 129.07C636.4 129.18 636.5 129.28 636.56 129.41C636.58 129.45 636.47 129.55 636.42 129.63C636.33 129.52 636.24 129.41 636.15 129.31Z" fill="white"/>
                    <path d="M588.47 76.41C588.41 76.5 588.36 76.57 588.31 76.65C588.22 76.54 588.12 76.44 588.06 76.31C588.04 76.27 588.15 76.17 588.2 76.09C588.29 76.2 588.38 76.31 588.47 76.41Z" fill="white"/>
                    <path d="M451.83 92.03C451.75 91.98 451.62 91.91 451.62 91.89C451.66 91.76 451.71 91.59 451.81 91.51C452.02 91.35 452.16 91.44 452.09 91.7C452.06 91.82 451.92 91.92 451.83 92.03Z" fill="white"/>
                    <path d="M452 91.21C452.09 91.1 452.2 90.9 452.28 90.91C452.51 90.93 452.58 91.1 452.45 91.22C452.34 91.32 452.19 91.39 452.05 91.47C452.03 91.39 452.02 91.3 452 91.22V91.21Z" fill="white"/>
                    <path d="M616.51 0.829993C616.55 0.699993 616.59 0.569993 616.64 0.429993C616.71 0.479993 616.85 0.529993 616.85 0.579993C616.85 0.719993 616.79 0.849993 616.75 0.989993C616.68 0.939993 616.6 0.889993 616.51 0.829993Z" fill="white"/>
                    <path d="M468.18 172.56C468.22 172.43 468.26 172.3 468.31 172.16C468.38 172.21 468.52 172.26 468.52 172.31C468.52 172.45 468.46 172.58 468.42 172.72C468.35 172.67 468.27 172.62 468.18 172.56Z" fill="white"/>
                    <path d="M445.16 88.4C445.22 88.31 445.27 88.24 445.32 88.16C445.41 88.27 445.51 88.37 445.57 88.5C445.59 88.54 445.48 88.64 445.43 88.72C445.34 88.61 445.25 88.5 445.16 88.4Z" fill="white"/>
                    <path d="M509.85 173.4C509.81 173.53 509.77 173.66 509.72 173.8C509.65 173.75 509.51 173.7 509.51 173.65C509.51 173.51 509.57 173.38 509.61 173.24C509.68 173.29 509.76 173.34 509.85 173.4Z" fill="white"/>
                    <path d="M529.59 35.39C529.63 35.26 529.67 35.13 529.72 34.99C529.79 35.04 529.93 35.09 529.93 35.14C529.93 35.28 529.87 35.41 529.83 35.55C529.76 35.5 529.68 35.45 529.59 35.39Z" fill="white"/>
                    <path d="M594.27 150.21C594.36 150.27 594.43 150.32 594.51 150.37C594.4 150.46 594.3 150.56 594.17 150.62C594.13 150.64 594.03 150.53 593.95 150.48C594.06 150.39 594.17 150.3 594.27 150.21Z" fill="white"/>
                    <path d="M473.08 70.51C472.99 70.4 472.89 70.3 472.83 70.17C472.81 70.13 472.92 70.03 472.97 69.95C473.06 70.06 473.16 70.16 473.22 70.29C473.24 70.33 473.13 70.43 473.08 70.51Z" fill="white"/>
                    <path d="M582.79 155.09C582.83 154.96 582.87 154.83 582.92 154.69C582.99 154.74 583.13 154.79 583.13 154.84C583.13 154.98 583.07 155.11 583.03 155.25L582.79 155.09Z" fill="white"/>
                    <path d="M594.12 44.16C593.99 44.12 593.86 44.08 593.72 44.03C593.77 43.96 593.82 43.82 593.87 43.82C594.01 43.82 594.14 43.88 594.28 43.92C594.23 43.99 594.18 44.07 594.12 44.16Z" fill="white"/>
                    <path d="M565.21 79.22C564.91 78.04 565.63 76.95 566.05 76.85C567.66 76.47 567.93 74.83 569.08 74.03C569.89 73.47 570.47 73.26 571.26 73.79C572.57 74.66 572.87 75.32 572.44 76.38C572.25 76.84 572.13 77.3 572.01 77.79C571.5 79.88 567.55 81.71 565.78 79.98C565.47 79.67 565.2 79.42 565.2 79.22H565.21Z" fill="white"/>
                    <path d="M655.87 37.83C656.44 36.92 655.82 35.48 657.23 34.99C657.43 34.92 657.69 34.94 657.83 34.81C658.42 34.29 658.87 33.52 659.85 33.74C660.61 33.92 660.92 34.63 661.37 35.14C662.27 36.16 662.12 38.08 661.11 39.19C660.32 40.05 659.41 40.25 658.26 39.97C657.06 39.67 656.64 38.58 655.87 37.82V37.83Z" fill="white"/>
                    <path d="M755.64 147.45C756.1 149.22 755.67 150.2 754.43 150.69C753.75 150.96 753 151.06 752.3 151.29C751.64 151.51 751.36 151.27 751.22 150.63C751.07 149.91 750.74 149.21 750.68 148.49C750.58 147.26 751.17 146.27 752.1 145.51C752.29 145.35 752.73 145.28 752.93 145.39C753.64 145.81 754.42 145.96 755.17 146.23C755.53 146.36 755.6 147.33 755.64 147.46V147.45Z" fill="white"/>
                    <path d="M628.49 202.16C628.21 201.38 627.79 200.27 628.94 199.6C629.49 199.28 630.51 198.69 630.93 199.36C631.39 200.09 632.5 200.53 632.25 201.63C632.08 202.4 631.63 203.15 631.64 203.9C631.65 204.59 631.48 204.78 630.88 204.95C630.03 205.19 629.43 204.82 629.07 204.19C628.77 203.66 628.71 202.99 628.49 202.16Z" fill="white"/>
                    <path d="M778.18 143.35C777.95 142.55 778.39 141.69 779.17 141.43C779.43 141.34 782.33 142.1 782.49 142.29C782.84 142.73 782.26 144.57 782.18 144.51C781.18 143.63 780.96 145.24 780.23 145.05C779.54 144.86 778.32 143.87 778.18 143.36V143.35Z" fill="white"/>
                    <path d="M629.69 69.82C629.82 70.47 630.37 71.21 629.14 71.49C628.86 71.55 628.73 72.18 628.5 72.53C628.24 72.93 627.94 73.3 627.66 73.69C627.41 73.03 626.88 72.32 626.98 71.72C627.11 70.95 627.77 70.27 628.15 69.53C628.36 69.12 628.63 68.78 629.08 68.85C629.57 68.92 629.57 69.44 629.68 69.83L629.69 69.82Z" fill="white"/>
                    <path d="M789.85 15.75C789.9 15.95 789.98 16.15 790.01 16.35C790.07 16.81 790.57 17.35 789.92 17.7C789.25 18.06 788.58 18.01 787.93 17.51C787.38 17.09 787.53 16.71 787.77 16.21C787.92 15.88 787.8 15.43 787.95 15.09C788.15 14.65 788.3 14.06 788.93 14.05C789.69 14.04 789.52 14.74 789.7 15.16C789.78 15.35 789.81 15.56 789.87 15.76L789.85 15.75Z" fill="white"/>
                    <path d="M773.88 154.02C774.82 154.26 775.87 154.23 776.66 154.92C776.84 155.08 776.96 155.46 776.72 155.77C776.43 156.17 776.32 156.88 775.7 156.79C774.93 156.68 774.18 156.41 773.45 156.13C772.59 155.8 773.6 155.35 773.43 154.94C773.25 154.51 773.31 154.13 773.88 154.02Z" fill="white"/>
                    <path d="M613.27 20.94C613.51 21.47 613.42 21.85 612.73 22C611.67 22.23 610.92 21.83 610.63 20.91C610.37 20.09 610.76 19.49 611.35 19.12C612.02 18.69 612.95 19.59 613.28 20.95L613.27 20.94Z" fill="white"/>
                    <path d="M750.8 100.13C750.99 99.81 751.16 99.55 751.29 99.27C751.64 98.56 752.72 99.02 752.99 98.15C753.14 97.69 753.6 97.57 753.98 97.85C754.35 98.12 754.33 98.52 754.08 98.92C753.75 99.45 753.48 100.02 753.13 100.53C752.96 100.77 752.99 101.43 752.36 101.04C751.85 100.72 751.31 100.43 750.79 100.13H750.8Z" fill="white"/>
                    <path d="M703.97 222.06C703.3 222.14 702.43 221.29 702.38 220.53C702.36 220.23 703.66 218.77 704.03 218.77C704.97 218.77 704.74 219.64 704.87 220.14C705.11 221.07 704.6 221.99 703.96 222.06H703.97Z" fill="white"/>
                    <path d="M639.17 162.66C639.77 162.4 640.18 162.43 640.34 163.14C640.53 164 640.17 165.01 639.56 165.17C638.95 165.33 638.11 164.64 637.88 163.79C637.55 162.52 638.73 162.98 639.17 162.67V162.66Z" fill="white"/>
                    <path d="M631.76 160.54C631.92 159.98 632.07 159.37 632.7 159.15C633.38 158.92 634.41 159.93 634.45 160.79C634.48 161.36 634.07 161.43 633.67 161.5C632.75 161.66 631.84 161.2 631.75 160.54H631.76Z" fill="white"/>
                    <path d="M722.15 125.49C722.26 125.89 722.29 126.1 722.37 126.28C722.66 126.99 722.04 127.4 721.83 127.94C721.38 127.58 720.63 127.54 720.53 126.78C720.5 126.58 720.44 126.38 720.37 126.18C720.13 125.52 720.1 125.07 721 124.85C721.86 124.64 722.14 124.92 722.16 125.49H722.15Z" fill="white"/>
                    <path d="M542.48 153.84C543.18 153.6 543.45 154.04 543.39 154.43C543.27 155.2 543 155.96 542.73 156.7C542.48 157.39 542.12 156.83 541.82 156.72C540.52 156.21 541.71 155.32 541.54 154.62C541.34 153.81 542.23 154.07 542.49 153.85L542.48 153.84Z" fill="white"/>
                    <path d="M690.42 146.69C690.04 145.9 690.43 145.28 691.01 144.89C691.44 144.59 692.93 145.5 692.83 145.93C692.63 146.74 692.08 147.21 691.23 147.37C690.63 147.48 690.54 147.11 690.43 146.7L690.42 146.69Z" fill="white"/>
                    <path d="M732.69 160.49C732.37 160.48 732.01 160.34 732.1 159.98C732.3 159.12 732.27 158.2 732.79 157.4C733 157.07 733.34 156.95 733.57 157.01C733.79 157.07 734.11 157.48 734.07 157.67C733.92 158.44 733.66 159.19 733.41 159.95C733.31 160.27 733.05 160.44 732.7 160.48L732.69 160.49Z" fill="white"/>
                    <path d="M754.46 187.06C754.35 186.75 754.19 186.35 754.69 186.1C755.12 185.88 755.44 186.2 755.81 186.34C756.56 186.61 756.54 187.31 756.6 187.86C756.64 188.19 756.25 188.86 756.05 188.86C755.64 188.86 755.12 188.61 754.83 188.29C754.58 188.03 754.59 187.53 754.46 187.06Z" fill="white"/>
                    <path d="M581.51 195.23C581.03 195.44 580.65 195.34 580.51 194.66C580.33 193.8 580.7 192.83 581.3 192.65C582.31 192.35 581.96 193.81 582.81 193.84C582.95 193.84 582.28 194.96 581.52 195.23H581.51Z" fill="white"/>
                    <path d="M728.38 65.58C728.45 65.4 728.48 65.14 728.61 65.07C729.33 64.67 731.01 65.4 731.15 66.18C731.2 66.45 731.19 66.85 731.04 66.99C730.87 67.14 730.46 67.12 730.21 67.03C729.13 66.6 728.52 66.09 728.38 65.59V65.58Z" fill="white"/>
                    <path d="M730.23 157.96C730.27 158.61 729.84 158.67 729.44 158.73C728.5 158.88 727.61 158.43 727.54 157.76C727.48 157.2 727.93 157.13 728.33 157.07C729.27 156.92 730.13 157.37 730.22 157.96H730.23Z" fill="white"/>
                    <path d="M583.35 203.94C582.73 203.55 582.06 203.34 581.92 202.46C581.81 201.74 582.15 201.62 582.68 201.46C583.26 201.29 583.56 201.34 583.82 201.98C584.17 202.83 583.59 203.3 583.34 203.94H583.35Z" fill="white"/>
                    <path d="M575.36 190.15C575.42 189.97 575.43 189.67 575.55 189.62C575.92 189.46 576.39 189.21 576.72 189.32C577.25 189.49 577.94 189.66 578.05 190.39C578.13 190.95 577.68 191.03 577.28 191.1C576.33 191.25 575.5 190.83 575.36 190.15Z" fill="white"/>
                    <path d="M549.41 35.72C550.15 35.57 551.14 36.21 551.19 36.74C551.24 37.29 550.79 37.32 550.41 37.44C549.65 37.69 549.03 37.41 548.75 36.78C548.67 36.59 549.01 35.83 549.41 35.72Z" fill="white"/>
                    <path d="M672.65 161.2C672.51 160.69 672.4 160.15 672.96 160.17C673.66 160.19 674.35 160.47 675.04 160.64C674.72 161.29 674.39 161.85 673.54 161.97C672.73 162.09 672.79 161.52 672.65 161.2Z" fill="white"/>
                    <path d="M522.55 110.91C522.23 110.1 522.62 109.94 523.19 109.74C524.07 109.43 524.24 109.89 524.43 110.57C524.62 111.27 524.3 111.43 523.72 111.62C522.87 111.9 522.56 111.57 522.55 110.92V110.91Z" fill="white"/>
                    <path d="M685.51 165.29C686.07 165.24 686.82 164.61 686.95 165.67C687.02 166.25 686.7 166.86 686.08 167.1C685.06 167.5 685.05 166.29 684.65 165.91C684.15 165.44 685.25 165.58 685.5 165.29H685.51Z" fill="white"/>
                    <path d="M773.82 195.53C773.74 195.23 773.61 194.94 773.59 194.64C773.55 194.09 774 194.03 774.39 193.95C774.67 193.89 774.97 193.65 775.25 193.94C775.56 194.27 775.59 194.63 775.33 195.01C775.21 195.18 775.13 195.37 775.02 195.54C774.81 195.87 774.6 196.48 774.39 196.48C773.92 196.48 773.97 195.89 773.83 195.53H773.82Z" fill="white"/>
                    <path d="M608.88 142.54C609.42 142.42 609.84 142.31 609.94 142.92C610.04 143.53 609.42 144.48 608.88 144.52C608.33 144.56 608.33 144.08 608.18 143.72C607.8 142.83 608.34 142.64 608.89 142.55L608.88 142.54Z" fill="white"/>
                    <path d="M724.15 183.19C723.93 183.19 723.51 183.3 723.39 183.16C723.06 182.77 723.52 182.43 723.73 182.22C724.04 181.91 723.84 180.77 724.85 181.43C725.18 181.65 725.61 181.79 725.6 182.27C725.59 183.24 724.66 182.79 724.16 183.18L724.15 183.19Z" fill="white"/>
                    <path d="M770.48 26.74C770.65 26.28 770.71 25.3701 771.3 25.6101C771.81 25.8101 772.54 25.3101 772.79 26.0201C772.94 26.4401 771.62 27.45 771.02 27.38C770.71 27.34 770.51 27.11 770.48 26.75V26.74Z" fill="white"/>
                    <path d="M540.86 103.7C540.86 103.7 540.44 103.32 540.12 102.93C539.98 102.76 540.92 101.66 541.21 101.64C541.99 101.6 541.82 102.29 541.99 102.71C542.2 103.22 541.89 103.44 540.85 103.7H540.86Z" fill="white"/>
                    <path d="M536.25 66.67C536.27 65.92 536.93 66.07 537.35 65.91C537.88 65.7 538.19 66.07 538.14 66.47C538.08 66.94 537.8 67.4 537.53 67.81C537.43 67.96 536.28 66.96 536.26 66.68L536.25 66.67Z" fill="white"/>
                    <path d="M751.38 72.73C750.71 72.81 750.12 72.52 749.98 72C749.91 71.74 750.22 71.03 750.83 70.89C751.64 70.69 751.56 71.36 751.71 71.79C751.85 72.19 751.89 72.58 751.38 72.73Z" fill="white"/>
                    <path d="M754.79 78.22C754.82 77.63 755.03 76.99 755.84 76.79C756.14 76.72 756.45 76.86 756.46 77.25C756.48 78.01 756.2 78.62 755.42 78.84C755.13 78.92 754.83 78.77 754.79 78.22Z" fill="white"/>
                    <path d="M825.51 75.15C825.33 74.6 825.67 74.29 826.08 74.34C826.55 74.4 827.01 74.68 827.42 74.95C827.57 75.05 826.57 76.2 826.29 76.22C825.89 76.25 825.6 75.83 825.52 75.14L825.51 75.15Z" fill="white"/>
                    <path d="M682.46 161.07C682.35 160.64 682.25 160.22 682.83 160.11C683.47 159.99 684.06 160.3 684.19 160.83C684.26 161.09 683.94 161.8 683.34 161.94C682.53 162.14 682.63 161.45 682.46 161.07Z" fill="white"/>
                    <path d="M782.6 161.96C782.73 161.38 782.91 160.86 783.51 160.62C783.86 160.48 784.16 160.65 784.2 160.95C784.27 161.46 784.94 162.1 783.98 162.46C783.39 162.68 783.09 162.08 782.6 161.96Z" fill="white"/>
                    <path d="M689.54 148.29C689.61 148.11 689.68 147.94 689.75 147.76C690.55 148.2 691.29 148.78 692.28 148.73C692.32 148.73 692.44 149.12 692.42 149.31C692.41 149.42 692.11 149.62 692.07 149.59C691.39 149.1 690.4 149.51 689.81 148.82C689.68 148.67 689.63 148.47 689.54 148.29Z" fill="white"/>
                    <path d="M693.9 145.99C694.51 145.86 694.39 146.73 694.47 147.21C694.63 148.16 693.7 147.89 693.23 148.1C693.09 148.16 692.45 147.98 692.91 147.69C693.59 147.25 692.37 146.24 693.9 146V145.99Z" fill="white"/>
                    <path d="M547.34 107.21C547.78 107.16 548.32 106.74 548.56 107.55C548.73 108.14 548.36 108.27 547.94 108.36C547.49 108.46 546.95 108.84 546.71 108.04C546.53 107.45 546.92 107.35 547.34 107.21Z" fill="white"/>
                    <path d="M515.08 110.19C515.03 110.04 515.39 109.86 515.82 109.78C516.27 109.7 516.81 109.28 517.06 110.09C517.23 110.66 516.88 110.82 516.46 110.91C516.01 111 515.46 111.39 515.08 110.19Z" fill="white"/>
                    <path d="M580.51 125.32C580.65 125.36 580.9 125.37 580.94 125.46C581.19 126.13 581.41 126.78 580.85 127.45C580.62 127.72 580.45 127.85 580.22 127.57C579.86 127.14 580.08 125.58 580.51 125.32Z" fill="white"/>
                    <path d="M605.02 4.87003C605.02 3.86003 605.93 4.27003 606.41 4.04003C606.63 3.93003 606.92 4.34003 606.92 4.67003C606.9 5.62003 606.04 5.34003 605.54 5.52003C605.31 5.60003 605.05 5.24003 605.03 4.88003L605.02 4.87003Z" fill="white"/>
                    <path d="M652.56 118.18C652.56 117.17 653.47 117.58 653.95 117.35C654.17 117.24 654.46 117.65 654.46 117.98C654.44 118.93 653.58 118.65 653.08 118.83C652.85 118.91 652.59 118.55 652.57 118.19L652.56 118.18Z" fill="white"/>
                    <path d="M746.89 12.41C746.84 12.26 747.2 12.08 747.63 12C748.08 11.92 748.62 11.5001 748.87 12.3101C749.04 12.8801 748.69 13.04 748.27 13.13C747.82 13.22 747.27 13.61 746.89 12.41Z" fill="white"/>
                    <path d="M661.91 201.1C662.36 201.58 662.84 202.07 663.29 202.57C663.31 202.59 663.22 202.78 663.14 202.82C662.75 203.02 662.39 202.95 662.01 202.71C661.58 202.43 661.1 202.22 661.29 201.59C661.33 201.44 661.57 201.35 661.91 201.1Z" fill="white"/>
                    <path d="M709.44 167.39C709.04 167.53 708.62 167.7 708.4 167.17C708.12 166.48 708.55 166.28 709.12 166.16C709.52 166.08 709.93 165.86 710.15 166.4C710.42 167.08 710.02 167.32 709.43 167.39H709.44Z" fill="white"/>
                    <path d="M751.93 119.3C751.95 118.72 752.02 118.42 752.42 118.41C753.18 118.39 753.78 118.67 754.01 119.45C754.05 119.6 753.9 119.81 753.84 119.99C753.18 119.75 752.52 119.51 751.93 119.29V119.3Z" fill="white"/>
                    <path d="M627.72 140.5C627.5 139.98 627.5 139.53 627.76 139.48C628.28 139.38 628.89 138.71 629.38 139.52C629.55 139.8 629.52 140.24 629.25 140.32C628.73 140.47 628.14 140.94 627.72 140.5Z" fill="white"/>
                    <path d="M640.72 21.03C639.71 21.03 640.12 20.12 639.89 19.64C639.78 19.42 640.19 19.13 640.52 19.13C641.47 19.15 641.19 20.01 641.37 20.51C641.45 20.74 641.09 21 640.73 21.02L640.72 21.03Z" fill="white"/>
                    <path d="M757.08 129.63C756.68 129.77 756.26 129.94 756.04 129.41C755.76 128.72 756.19 128.52 756.76 128.4C757.16 128.32 757.57 128.1 757.79 128.64C758.06 129.32 757.66 129.56 757.07 129.63H757.08Z" fill="white"/>
                    <path d="M591.37 189.91C590.69 190.16 590.46 190 590.34 189.69C590.07 188.99 590.53 188.81 591.08 188.71C591.37 188.66 591.91 188.45 591.89 188.7C591.84 189.21 591.48 189.69 591.37 189.91Z" fill="white"/>
                    <path d="M801.2 174.89C801.45 174.76 801.72 174.93 801.83 175.22C801.99 175.61 802.05 176.06 801.46 176.14C801.04 176.19 800.46 176.76 800.25 176.06C800.18 175.81 800.51 175.09 801.19 174.89H801.2Z" fill="white"/>
                    <path d="M613.99 231.3C613.71 230.61 614.33 230.22 614.56 229.69C614.73 229.92 614.97 230.13 615.05 230.38C615.17 230.81 615.55 231.35 614.75 231.61C614.39 231.72 614.19 231.59 613.99 231.3Z" fill="white"/>
                    <path d="M788.54 141.56C788.92 141.36 788.48 141.46 788.79 141.73C788.96 141.87 789.07 142.32 788.98 142.5C788.72 143 788.6 143.71 787.81 143.7C787.72 143.7 787.56 143.61 787.56 143.56C787.57 142.74 788.26 142.24 788.54 141.56Z" fill="white"/>
                    <path d="M740.84 106.65C741.17 106.17 741.29 105.43 742.06 105.48C742.24 105.49 742.52 105.9 742.54 106.14C742.6 106.9 741.88 106.73 741.47 106.91C741.19 107.03 740.93 107.02 740.84 106.65Z" fill="white"/>
                    <path d="M645.39 72.02C645.34 71.82 645.23 71.61 645.25 71.42C645.26 71.29 645.47 71.07 645.56 71.08C645.9 71.12 646.43 73 646.17 73.28C646.11 73.35 645.76 73.28 645.73 73.21C645.58 72.82 645.5 72.42 645.39 72.02Z" fill="white"/>
                    <path d="M812.62 32.04C812.41 31.42 812.76 31.23 813.19 31.16C813.51 31.11 813.74 31.24 813.83 31.62C813.98 32.19 813.66 32.37 813.23 32.43C812.93 32.47 812.61 32.39 812.62 32.03V32.04Z" fill="white"/>
                    <path d="M600.57 215.57C600.57 215.23 600.67 215.01 601.04 214.91C601.61 214.76 601.79 215.08 601.85 215.51C601.9 215.83 601.77 216.05 601.39 216.15C600.82 216.3 600.68 215.97 600.56 215.57H600.57Z" fill="white"/>
                    <path d="M531.29 127.2C530.71 127.11 530.21 126.9 529.98 126.33C529.92 126.18 530.01 125.8 530.03 125.8C530.62 125.82 531.12 126.04 531.35 126.62C531.41 126.77 531.32 126.99 531.3 127.2H531.29Z" fill="white"/>
                    <path d="M544.6 14.66C544.43 14.32 544.62 14.08 544.92 13.96C545.33 13.8 545.69 13.8 545.85 14.36C545.96 14.74 545.82 14.97 545.53 15.08C545.12 15.24 544.73 15.24 544.6 14.65V14.66Z" fill="white"/>
                    <path d="M602.85 126.31C602.85 125.97 602.95 125.75 603.32 125.65C603.89 125.5 604.07 125.82 604.13 126.25C604.18 126.57 604.05 126.79 603.67 126.89C603.1 127.04 602.96 126.71 602.84 126.31H602.85Z" fill="white"/>
                    <path d="M818.25 101.95C818.25 101.55 818.35 101.33 818.73 101.23C819.3 101.08 819.48 101.4 819.54 101.83C819.59 102.15 819.45 102.37 819.08 102.48C818.5 102.64 818.39 102.27 818.25 101.95Z" fill="white"/>
                    <path d="M795.08 113.51C795.65 113.32 795.85 113.66 795.92 114.1C795.97 114.42 795.84 114.65 795.46 114.74C794.89 114.89 794.71 114.57 794.65 114.14C794.6 113.83 794.72 113.54 795.09 113.51H795.08Z" fill="white"/>
                    <path d="M495.19 102.03C495.28 102.43 495.38 102.84 494.8 102.94C494.62 102.97 494.43 102.92 494.24 102.9C494.41 102.48 494.59 102.06 494.76 101.65C494.9 101.78 495.05 101.9 495.2 102.03H495.19Z" fill="white"/>
                    <path d="M541.28 68.28C541.19 67.88 541.09 67.47 541.67 67.37C541.85 67.34 542.04 67.39 542.23 67.41C542.06 67.83 541.88 68.25 541.71 68.66C541.57 68.53 541.42 68.41 541.27 68.28H541.28Z" fill="white"/>
                    <path d="M613.65 195.39C613.86 195.01 614.05 194.66 614.24 194.31C614.41 194.54 614.68 194.74 614.72 194.99C614.83 195.54 614.37 195.63 613.97 195.67C613.86 195.68 613.73 195.47 613.64 195.39H613.65Z" fill="white"/>
                    <path d="M649.37 18.69C649.61 18.71 649.94 18.69 649.97 18.76C650.13 19.29 649.61 19.57 649.46 20C649.32 19.87 649.11 19.77 649.05 19.61C648.89 19.21 648.9 18.83 649.38 18.69H649.37Z" fill="white"/>
                    <path d="M618.01 170.01C618.03 169.93 618.04 169.68 618.13 169.64C618.49 169.48 618.94 169.32 619.13 169.84C619.22 170.08 619.09 170.39 619.06 170.67C618.72 170.46 618.38 170.24 618.01 170.01Z" fill="white"/>
                    <path d="M609.09 129.99C608.92 129.44 609.29 129.28 609.7 129.21C609.87 129.18 610.06 129.32 610.24 129.38C609.96 129.74 609.69 130.1 609.41 130.46C609.3 130.31 609.19 130.15 609.08 129.99H609.09Z" fill="white"/>
                    <path d="M783.41 96.95C782.86 97.12 782.7 96.75 782.63 96.34C782.6 96.17 782.74 95.98 782.8 95.8C783.16 96.08 783.52 96.35 783.88 96.63C783.73 96.74 783.57 96.85 783.41 96.96V96.95Z" fill="white"/>
                    <path d="M824.75 83.25C824.56 83.07 824.36 82.96 824.3 82.8C824.14 82.39 824.16 82 824.72 81.91C824.9 81.88 825.09 81.95 825.28 81.97C825.11 82.39 824.94 82.8 824.76 83.24L824.75 83.25Z" fill="white"/>
                    <path d="M655.28 245.25C655.39 244.84 655.76 244.88 656.03 244.82C656.12 244.8 656.36 245.04 656.35 245.14C656.29 245.53 655.9 245.49 655.62 245.55C655.53 245.57 655.39 245.35 655.28 245.25Z" fill="white"/>
                    <path d="M635.22 226.19C635.04 225.89 634.84 225.67 634.76 225.41C634.71 225.26 634.86 225.05 634.92 224.87C635.09 225.1 635.31 225.3 635.41 225.56C635.46 225.7 635.31 225.92 635.22 226.2V226.19Z" fill="white"/>
                    <path d="M603.41 209.02C603.43 208.83 603.41 208.52 603.49 208.48C603.83 208.32 603.99 208.57 604.02 208.88C604.04 209.06 604.04 209.38 603.94 209.42C603.6 209.58 603.46 209.33 603.41 209.02Z" fill="white"/>
                    <path d="M768.85 169.61C768.89 169.48 768.95 169.3 769.02 169.11C769.19 169.34 769.41 169.54 769.51 169.8C769.56 169.94 769.41 170.16 769.35 170.34C769.05 170.21 768.87 169.99 768.86 169.61H768.85Z" fill="white"/>
                    <path d="M758.89 127.84C759.18 127.73 759.47 127.73 759.5 128.1C759.5 128.2 759.24 128.36 759.07 128.44C758.79 128.57 758.5 128.56 758.46 128.18C758.46 128.08 758.73 127.95 758.88 127.84H758.89Z" fill="white"/>
                    <path d="M747.24 121.12C747.53 121.01 747.82 121.01 747.85 121.38C747.85 121.48 747.59 121.64 747.42 121.72C747.14 121.85 746.85 121.84 746.81 121.46C746.81 121.36 747.08 121.23 747.23 121.12H747.24Z" fill="white"/>
                    <path d="M701.2 102.72C701.02 102.42 700.82 102.2 700.74 101.94C700.69 101.79 700.84 101.58 700.9 101.4C701.07 101.63 701.29 101.83 701.39 102.09C701.44 102.23 701.29 102.45 701.2 102.73V102.72Z" fill="white"/>
                    <path d="M616.12 180.75C616.17 180.52 616.23 180.24 616.29 179.97C616.43 180.1 616.68 180.21 616.7 180.36C616.74 180.63 616.65 180.92 616.62 181.2C616.48 181.07 616.33 180.95 616.12 180.76V180.75Z" fill="white"/>
                    <path d="M616.66 79.84C616.7 79.71 616.76 79.53 616.83 79.34C617 79.57 617.22 79.77 617.32 80.03C617.37 80.17 617.22 80.39 617.16 80.57C616.86 80.44 616.68 80.22 616.67 79.84H616.66Z" fill="white"/>
                    <path d="M567.65 84.77C567.47 84.47 567.27 84.25 567.19 83.99C567.14 83.84 567.29 83.63 567.35 83.45C567.52 83.68 567.74 83.88 567.84 84.14C567.89 84.28 567.74 84.5 567.65 84.78V84.77Z" fill="white"/>
                    <path d="M490.91 93.08C491.02 92.67 491.39 92.71 491.66 92.65C491.75 92.63 491.99 92.87 491.98 92.97C491.92 93.36 491.53 93.32 491.25 93.38C491.16 93.4 491.02 93.18 490.91 93.08Z" fill="white"/>
                    <path d="M542.87 47.34C543.16 47.23 543.45 47.23 543.48 47.6C543.48 47.7 543.22 47.86 543.05 47.94C542.77 48.07 542.48 48.06 542.44 47.68C542.44 47.58 542.71 47.45 542.86 47.34H542.87Z" fill="white"/>
                    <path d="M665.99 41.16C666.01 40.97 665.99 40.66 666.07 40.62C666.41 40.46 666.57 40.71 666.6 41.02C666.62 41.2 666.62 41.52 666.52 41.56C666.18 41.72 666.04 41.47 665.99 41.16Z" fill="white"/>
                    <path d="M660.79 25.49C660.69 25.37 660.56 25.27 660.49 25.13C660.47 25.08 660.58 24.96 660.63 24.88C660.73 25 660.86 25.1 660.93 25.24C660.95 25.29 660.84 25.41 660.79 25.49Z" fill="white"/>
                    <path d="M734.2 121.11C734.24 120.96 734.27 120.81 734.31 120.66C734.4 120.71 734.55 120.76 734.56 120.81C734.56 120.96 734.51 121.12 734.48 121.27C734.39 121.22 734.31 121.17 734.2 121.11Z" fill="white"/>
                    <path d="M783.75 122.89C783.69 122.99 783.64 123.08 783.59 123.17C783.49 123.05 783.36 122.95 783.29 122.81C783.27 122.76 783.38 122.64 783.43 122.56C783.54 122.67 783.65 122.79 783.75 122.9V122.89Z" fill="white"/>
                    <path d="M695.05 148.38C695.2 148.42 695.35 148.45 695.5 148.49C695.45 148.58 695.4 148.73 695.35 148.74C695.2 148.74 695.04 148.69 694.89 148.66C694.94 148.57 694.99 148.49 695.05 148.38Z" fill="white"/>
                    <path d="M653.04 122.47C653.16 122.37 653.26 122.24 653.4 122.17C653.45 122.15 653.57 122.26 653.65 122.31C653.53 122.41 653.43 122.54 653.29 122.61C653.24 122.63 653.12 122.52 653.04 122.47Z" fill="white"/>
                    <path d="M601.84 130.75C601.94 130.81 602.03 130.86 602.12 130.91C602 131.01 601.9 131.14 601.76 131.21C601.71 131.23 601.59 131.12 601.51 131.07C601.62 130.96 601.74 130.85 601.85 130.75H601.84Z" fill="white"/>
                    <path d="M597.66 194.59C597.7 194.44 597.73 194.29 597.77 194.14C597.86 194.19 598.01 194.24 598.02 194.29C598.02 194.44 597.97 194.6 597.94 194.75C597.85 194.7 597.77 194.65 597.66 194.59Z" fill="white"/>
                    <path d="M715.66 144.95C715.72 144.85 715.77 144.76 715.82 144.67C715.92 144.79 716.05 144.89 716.12 145.03C716.14 145.08 716.03 145.2 715.98 145.28C715.87 145.17 715.76 145.05 715.66 144.94V144.95Z" fill="white"/>
                    <path d="M658.55 89.56C658.49 89.66 658.44 89.75 658.39 89.84C658.29 89.72 658.16 89.62 658.09 89.48C658.07 89.43 658.18 89.31 658.23 89.23C658.34 89.34 658.45 89.46 658.55 89.57V89.56Z" fill="white"/>
                    <path d="M507.43 117.23C507.34 117.18 507.18 117.11 507.19 117.09C507.23 116.94 507.26 116.75 507.37 116.66C507.59 116.47 507.75 116.56 507.7 116.85C507.67 116.99 507.53 117.11 507.43 117.24V117.23Z" fill="white"/>
                    <path d="M507.56 116.3C507.66 116.17 507.76 115.94 507.85 115.94C508.11 115.95 508.2 116.12 508.06 116.27C507.94 116.39 507.78 116.47 507.64 116.57C507.62 116.48 507.59 116.39 507.57 116.3H507.56Z" fill="white"/>
                    <path d="M684.13 3.22002C684.17 3.07002 684.2 2.92002 684.24 2.77002C684.33 2.82002 684.48 2.87002 684.49 2.92002C684.49 3.07002 684.44 3.23002 684.41 3.38002C684.32 3.33002 684.24 3.28002 684.13 3.22002Z" fill="white"/>
                    <path d="M531.7 205.75C531.74 205.6 531.77 205.45 531.81 205.3C531.9 205.35 532.05 205.4 532.06 205.45C532.06 205.6 532.01 205.76 531.98 205.91C531.89 205.86 531.81 205.81 531.7 205.75Z" fill="white"/>
                    <path d="M499.73 113.68C499.79 113.58 499.84 113.49 499.89 113.4C499.99 113.52 500.12 113.62 500.19 113.76C500.21 113.81 500.1 113.93 500.05 114.01C499.94 113.9 499.83 113.78 499.73 113.67V113.68Z" fill="white"/>
                    <path d="M578.21 203.57C578.17 203.72 578.14 203.87 578.1 204.02C578.01 203.97 577.86 203.92 577.85 203.87C577.85 203.72 577.9 203.56 577.93 203.41C578.02 203.46 578.1 203.51 578.21 203.57Z" fill="white"/>
                    <path d="M589.85 48.27C589.89 48.12 589.92 47.97 589.96 47.82C590.05 47.87 590.2 47.92 590.21 47.97C590.21 48.12 590.16 48.28 590.13 48.43C590.04 48.38 589.96 48.33 589.85 48.27Z" fill="white"/>
                    <path d="M670.55 171.38C670.65 171.44 670.74 171.49 670.83 171.54C670.71 171.64 670.61 171.77 670.47 171.84C670.42 171.86 670.3 171.75 670.22 171.7C670.33 171.59 670.45 171.48 670.56 171.38H670.55Z" fill="white"/>
                    <path d="M529.5 91.65C529.4 91.53 529.27 91.43 529.2 91.29C529.18 91.24 529.29 91.12 529.34 91.04C529.44 91.16 529.57 91.26 529.64 91.4C529.66 91.45 529.55 91.57 529.5 91.65Z" fill="white"/>
                    <path d="M658.13 177.68C658.17 177.53 658.2 177.38 658.24 177.23C658.33 177.28 658.48 177.33 658.49 177.38C658.49 177.53 658.44 177.69 658.41 177.84L658.13 177.68Z" fill="white"/>
                    <path d="M662.43 53.2C662.28 53.16 662.13 53.13 661.98 53.09C662.03 53 662.08 52.85 662.13 52.84C662.28 52.84 662.44 52.89 662.59 52.92C662.54 53.01 662.49 53.09 662.43 53.2Z" fill="white"/>
                    <path d="M862.57 139.2C862.33 141 860.98 141.43 859.71 141.8C859.24 141.94 858.49 142.05 858.27 142.2C856.9 143.22 856.5 141.23 855.47 141.43C855.44 140.4 855.05 139.19 855.47 138.41C855.87 137.67 856.45 136.78 857.22 136.23C857.95 135.71 858.73 134.65 859.64 136.11C859.78 136.33 860.34 136.26 860.69 136.36C862.26 136.81 862.87 137.8 862.57 139.2Z" fill="white"/>
                    <path d="M772.66 23.4801C773.71 23.7201 774.27 24.7101 774.18 25.0801C773.84 26.5001 775.02 27.3501 775.18 28.5701C775.3 29.4301 775.23 29.9701 774.5 30.3801C773.3 31.0601 772.66 31.0301 772 30.2801C771.71 29.9501 771.4 29.6701 771.07 29.3901C769.63 28.1601 769.77 24.3401 771.83 23.6501C772.19 23.5301 772.5 23.4101 772.66 23.5001V23.4801Z" fill="white"/>
                    <path d="M769.09 110.88C769.57 111.69 770.95 111.78 770.77 113.07C770.75 113.25 770.63 113.45 770.67 113.61C770.84 114.28 771.27 114.94 770.71 115.61C770.27 116.14 769.59 116.09 769.01 116.25C767.85 116.55 766.41 115.67 765.94 114.44C765.58 113.48 765.78 112.69 766.46 111.9C767.17 111.08 768.19 111.18 769.09 110.88Z" fill="white"/>
                    <path d="M643.67 145.5C642.1 145.16 641.51 144.43 641.61 143.27C641.67 142.63 641.89 142.01 641.99 141.37C642.08 140.77 642.38 140.64 642.94 140.79C643.56 140.96 644.24 140.98 644.83 141.22C645.83 141.63 646.37 142.49 646.6 143.51C646.65 143.72 646.53 144.1 646.36 144.2C645.75 144.59 645.32 145.14 644.82 145.62C644.57 145.85 643.79 145.52 643.67 145.5Z" fill="white"/>
                    <path d="M651.29 24.24C652.01 24.33 653.05 24.44 653.11 25.61C653.14 26.16 653.2 27.2 652.51 27.26C651.76 27.33 650.97 28.02 650.21 27.39C649.68 26.95 649.27 26.3 648.68 26.01C648.13 25.74 648.05 25.53 648.16 25C648.31 24.24 648.84 23.92 649.48 23.88C650.02 23.85 650.56 24.08 651.3 24.23L651.29 24.24Z" fill="white"/>
                    <path d="M637.93 164.77C638.65 164.91 639.15 165.59 639.04 166.31C639 166.55 637.26 168.52 637.05 168.57C636.56 168.67 635.35 167.48 635.44 167.45C636.52 167.02 635.35 166.2 635.79 165.71C636.21 165.24 637.47 164.69 637.93 164.77Z" fill="white"/>
                    <path d="M802.8 161.11C802.89 160.73 802.91 160.14 803.27 160.21C803.94 160.34 804.73 160.19 805.24 160.95C805.52 161.37 805.35 161.85 805.57 162.19C805.93 162.75 805.94 163.11 805.35 163.54C804.71 164 804.03 163.96 803.64 163.5C803.08 162.85 802.33 162.14 802.8 161.11Z" fill="white"/>
                    <path d="M754.43 77.6901C753.87 77.5301 753.07 77.6701 753.34 76.6001C753.4 76.3601 752.96 76.0001 752.78 75.6801C752.57 75.3201 752.4 74.9401 752.21 74.5701C752.83 74.6401 753.59 74.5001 754.02 74.8201C754.57 75.2301 754.84 76.0201 755.27 76.6101C755.51 76.9401 755.66 77.2801 755.43 77.6101C755.18 77.9701 754.77 77.7601 754.42 77.6901H754.43Z" fill="white"/>
                    <path d="M733.21 224.54C733.03 224.5 732.85 224.49 732.68 224.42C732.3 224.28 731.68 224.46 731.66 223.81C731.65 223.15 731.95 222.64 732.6 222.33C733.15 222.06 733.38 222.33 733.69 222.72C733.89 222.97 734.29 223.05 734.49 223.3C734.75 223.63 735.16 223.98 734.91 224.48C734.62 225.08 734.14 224.67 733.74 224.64C733.56 224.63 733.39 224.57 733.21 224.53V224.54Z" fill="white"/>
                    <path d="M631.29 157.17C630.73 157.81 630.34 158.65 629.49 158.99C629.3 159.07 628.95 159.01 628.8 158.7C628.61 158.31 628.09 157.95 628.41 157.5C628.8 156.94 629.31 156.46 629.82 156C630.42 155.46 630.37 156.43 630.76 156.46C631.16 156.49 631.44 156.68 631.3 157.18L631.29 157.17Z" fill="white"/>
                    <path d="M799.22 84.23C798.71 84.21 798.45 83.98 798.6 83.38C798.84 82.46 799.45 82.03 800.29 82.17C801.04 82.29 801.35 82.84 801.41 83.44C801.48 84.14 800.41 84.51 799.22 84.22V84.23Z" fill="white"/>
                    <path d="M858.97 26.22C858.7 26.16 858.41 26.15 858.16 26.04C857.58 25.78 857.54 25.01 858.06 24.66C858.37 24.46 858.65 24.19 858.99 24.07C859.43 23.92 859.97 24 860.24 24.32C860.54 24.69 860.29 25.35 860.7 25.75C860.23 25.97 859.89 26.56 859.24 26.27C859.16 26.23 859.06 26.23 858.97 26.21V26.22Z" fill="white"/>
                    <path d="M682.64 160.48C682.82 160.75 682.96 160.99 683.12 161.21C683.54 161.76 682.75 162.42 683.32 162.99C683.62 163.29 683.53 163.7 683.16 163.89C682.8 164.07 682.5 163.89 682.28 163.54C682 163.07 681.66 162.64 681.4 162.16C681.28 161.93 680.75 161.7 681.31 161.36C681.76 161.09 682.2 160.78 682.64 160.49V160.48Z" fill="white"/>
                    <path d="M605.75 75.4301C605.96 74.8801 606.96 74.5301 607.58 74.8001C607.83 74.9101 608.45 76.5001 608.3 76.7901C607.92 77.5201 607.34 77.0101 606.9 76.9101C606.08 76.7301 605.56 75.9701 605.75 75.4401V75.4301Z" fill="white"/>
                    <path d="M677.98 48.27C677.95 48.84 677.76 49.15 677.14 48.99C676.39 48.8 675.75 48.11 675.86 47.57C675.97 47.03 676.85 46.64 677.61 46.81C678.74 47.06 677.91 47.8 677.98 48.27Z" fill="white"/>
                    <path d="M682.58 43.3101C682.95 43.6601 683.38 44.0201 683.29 44.6001C683.2 45.2301 682 45.6301 681.31 45.3201C680.85 45.1201 680.96 44.7701 681.07 44.4301C681.31 43.6501 682.03 43.1201 682.58 43.3101Z" fill="white"/>
                    <path d="M674.15 127.99C673.8 127.92 673.62 127.86 673.44 127.85C672.77 127.8 672.7 127.15 672.36 126.77C672.83 126.56 673.15 125.99 673.79 126.21C673.96 126.27 674.14 126.3 674.32 126.32C674.93 126.4 675.29 126.55 675.11 127.34C674.94 128.1 674.6 128.2 674.15 127.99Z" fill="white"/>
                    <path d="M670.15 94.73C670.92 94.74 671.25 95.3 671.33 95.91C671.39 96.37 670.09 97.17 669.8 96.92C669.24 96.44 669.09 95.83 669.31 95.09C669.46 94.58 669.79 94.66 670.15 94.73Z" fill="white"/>
                    <path d="M794.37 209.52C794.17 210.03 793.69 210.24 793.18 210.36C792.88 210.44 792.24 209.05 792.31 208.48C792.37 207.97 792.73 207.99 793.09 208.08C793.83 208.26 794.47 208.96 794.36 209.51L794.37 209.52Z" fill="white"/>
                    <path d="M642.57 122.36C642.71 122.11 642.95 121.89 643.2 122.1C643.8 122.6 644.52 122.94 644.95 123.66C645.13 123.96 645.08 124.27 644.95 124.42C644.82 124.57 644.37 124.65 644.23 124.55C643.69 124.13 643.2 123.62 642.71 123.13C642.5 122.92 642.47 122.66 642.57 122.36Z" fill="white"/>
                    <path d="M613.12 128.86C613.41 128.9 613.78 128.93 613.78 129.42C613.78 129.85 613.4 129.96 613.15 130.2C612.64 130.68 612.1 130.38 611.65 130.22C611.38 130.12 611.01 129.55 611.09 129.39C611.25 129.06 611.66 128.77 612.02 128.66C612.33 128.57 612.71 128.77 613.13 128.86H613.12Z" fill="white"/>
                    <path d="M718.59 156.64C718.7 156.77 718.9 156.89 718.9 157.02C718.92 157.74 717.69 158.77 717.02 158.57C716.79 158.5 716.48 158.34 716.43 158.16C716.38 157.97 716.56 157.65 716.73 157.5C717.5 156.82 718.14 156.55 718.59 156.64Z" fill="white"/>
                    <path d="M820.34 137.13C820.4 136.86 820.41 136.58 820.52 136.33C820.8 135.7 821.28 136.12 821.68 136.15C822.2 136.19 822.17 136.56 822.09 136.94C822.01 137.28 822.01 137.64 821.63 137.85C821.24 138.07 820.87 138.45 820.42 138.13C820.07 137.88 820.29 137.47 820.35 137.13H820.34Z" fill="white"/>
                    <path d="M645.52 121.43C644.99 121.2 645.12 120.84 645.23 120.51C645.48 119.72 646.19 119.2 646.74 119.41C647.2 119.59 647.08 119.97 646.97 120.3C646.71 121.09 646.02 121.59 645.52 121.43Z" fill="white"/>
                    <path d="M823.69 174.67C824.06 174.76 824.45 174.86 824.26 175.34C824.02 175.97 823.5 176.38 822.84 176.16C822.5 176.04 822.29 175.52 822.02 175.18C822.54 174.88 822.98 174.33 823.68 174.67H823.69Z" fill="white"/>
                    <path d="M812.99 28.3701C812.81 29.0101 811.92 29.5301 811.49 29.3601C811.04 29.1801 811.19 28.8201 811.25 28.4701C811.35 27.7801 811.82 27.4001 812.43 27.4301C812.61 27.4301 813.07 28.0101 813 28.3701H812.99Z" fill="white"/>
                    <path d="M665.84 75.06C666.29 75.16 666.76 75.28 666.53 75.72C666.24 76.26 665.74 76.69 665.33 77.16C664.95 76.65 664.64 76.17 664.88 75.45C665.11 74.77 665.53 75.04 665.84 75.06Z" fill="white"/>
                    <path d="M657.53 83.51C657.35 83.97 657.54 84.81 656.66 84.49C656.18 84.31 655.83 83.82 655.88 83.24C655.97 82.28 656.92 82.76 657.38 82.6C657.95 82.4 657.4 83.2 657.53 83.51Z" fill="white"/>
                    <path d="M598.81 140.65C599.07 140.71 599.35 140.72 599.6 140.83C600.05 141.02 599.92 141.39 599.82 141.73C599.75 141.97 599.82 142.31 599.49 142.41C599.11 142.52 598.81 142.41 598.62 142.04C598.53 141.88 598.42 141.74 598.33 141.59C598.15 141.29 597.76 140.89 597.85 140.72C598.03 140.35 598.48 140.62 598.82 140.66L598.81 140.65Z" fill="white"/>
                    <path d="M847.88 68.1C848.2 67.52 848.66 67.88 849.06 67.95C849.79 68.07 849.45 68.61 849.39 69C849.34 69.37 849.22 69.73 848.72 69.57C848.2 69.41 847.72 68.55 847.88 68.1Z" fill="white"/>
                    <path d="M705.75 32.5401C705.63 33.0101 705.55 33.3801 705.04 33.2201C704.52 33.0601 704.02 32.1901 704.21 31.7501C704.4 31.3001 704.77 31.5001 705.12 31.5201C705.96 31.5801 705.9 32.0701 705.76 32.5401H705.75Z" fill="white"/>
                    <path d="M628.18 106.66C628.27 106.49 628.35 106.12 628.51 106.08C628.94 105.97 629.03 106.47 629.11 106.72C629.23 107.08 630.2 107.38 629.28 107.9C628.98 108.07 628.69 108.35 628.32 108.15C627.57 107.76 628.29 107.21 628.18 106.66Z" fill="white"/>
                    <path d="M732.29 205.02C732.58 205.34 733.27 205.74 732.85 206.11C732.49 206.43 732.59 207.2 731.94 207.12C731.55 207.07 731.29 205.64 731.58 205.2C731.73 204.97 732 204.91 732.29 205.03V205.02Z" fill="white"/>
                    <path d="M793.98 5.78004C794.56 6.09004 794.18 6.55004 794.14 6.94004C794.09 7.44004 793.68 7.54004 793.39 7.33004C793.05 7.10004 792.8 6.69004 792.59 6.32004C792.51 6.19004 793.76 5.68004 793.98 5.77004V5.78004Z" fill="white"/>
                    <path d="M865.14 159.34C865.14 159.83 864.88 159.97 864.62 159.92C863.93 159.77 863.65 159.25 863.63 158.58C863.62 158.23 863.88 158.1 864.15 158.14C864.88 158.27 865.09 158.83 865.14 159.34Z" fill="white"/>
                    <path d="M703.86 171.81C704.06 171.26 704.52 170.9 704.98 171C705.21 171.05 705.64 171.58 705.51 172.11C705.34 172.82 704.85 172.49 704.46 172.44C704.09 172.39 703.77 172.27 703.86 171.81Z" fill="white"/>
                    <path d="M866.11 67.96C866.09 68.22 865.14 69.11 865.04 68.98C864.79 68.64 864.52 68.24 864.45 67.84C864.38 67.46 864.67 67.18 865.13 67.33C865.5 67.46 866.09 67.28 866.11 67.96Z" fill="white"/>
                    <path d="M698.21 172.31C698.66 172.56 699.08 172.98 698.91 173.7C698.85 173.96 698.61 174.15 698.3 174C697.69 173.71 697.33 173.25 697.47 172.55C697.52 172.29 697.76 172.11 698.21 172.3V172.31Z" fill="white"/>
                    <path d="M672.55 228.9C673.05 228.97 673.16 229.36 672.96 229.66C672.73 230 672.32 230.25 671.95 230.46C671.82 230.54 671.31 229.29 671.4 229.07C671.54 228.75 671.98 228.68 672.55 228.9Z" fill="white"/>
                    <path d="M662.05 82.79C662.43 82.88 662.8 82.96 662.65 83.46C662.49 84 662.01 84.34 661.55 84.24C661.32 84.19 660.89 83.66 661.02 83.13C661.19 82.41 661.68 82.77 662.05 82.78V82.79Z" fill="white"/>
                    <path d="M621.61 160.85C622.01 161.18 622.35 161.53 622.3 162.09C622.27 162.42 622.02 162.59 621.77 162.5C621.35 162.35 620.57 162.63 620.68 161.73C620.74 161.18 621.33 161.18 621.62 160.85H621.61Z" fill="white"/>
                    <path d="M669.25 93.41C669.36 93.53 669.47 93.66 669.58 93.78C668.92 94.23 668.17 94.58 667.82 95.38C667.8 95.42 667.45 95.35 667.31 95.26C667.23 95.21 667.19 94.89 667.23 94.87C667.89 94.53 667.95 93.59 668.73 93.4C668.89 93.36 669.08 93.4 669.25 93.4V93.41Z" fill="white"/>
                    <path d="M669.32 97.7401C669.18 98.2701 668.54 97.8301 668.14 97.7001C667.33 97.4501 667.92 96.8301 667.94 96.3801C667.94 96.2501 668.34 95.8201 668.39 96.3001C668.46 97.0101 669.74 96.4601 669.32 97.7401Z" fill="white"/>
                    <path d="M839.67 123.59C839.7 122.92 840.36 122.87 840.72 122.53C840.92 122.81 841.32 123.15 841.27 123.37C841.15 123.86 840.69 124.1 840.14 124.1C839.81 124.1 839.71 123.87 839.66 123.6L839.67 123.59Z" fill="white"/>
                    <path d="M823.15 195.69C823.79 196.34 823.84 196.93 823.6 197.54C823.54 197.68 823.37 197.78 823.25 197.9C823.15 197.75 823.08 197.57 822.95 197.45C822.46 196.99 822.67 196.46 822.82 195.96C822.86 195.81 823.11 195.71 823.16 195.68L823.15 195.69Z" fill="white"/>
                    <path d="M730.5 17.15C730.41 17.24 730.31 17.44 730.22 17.43C729.6 17.36 729 17.27 728.7 16.57C728.58 16.28 728.55 16.1 728.85 16.03C729.33 15.92 730.47 16.71 730.5 17.15Z" fill="white"/>
                    <path d="M815.08 84.15C815.87 84.54 815.19 85.1 815.18 85.57C815.18 85.78 814.74 85.85 814.49 85.72C813.76 85.33 814.32 84.76 814.38 84.3C814.41 84.08 814.8 84.03 815.08 84.15Z" fill="white"/>
                    <path d="M707.5 76.4C708.29 76.79 707.61 77.35 707.6 77.82C707.6 78.03 707.16 78.1 706.91 77.97C706.18 77.58 706.74 77.01 706.8 76.55C706.83 76.33 707.22 76.28 707.5 76.4Z" fill="white"/>
                    <path d="M752.87 192.23C753.01 192.25 753 192.6 752.9 192.97C752.79 193.35 752.9 193.95 752.16 193.82C751.64 193.73 751.66 193.39 751.76 193.02C751.87 192.63 751.78 192.05 752.87 192.23Z" fill="white"/>
                    <path d="M638.86 50.8201C638.31 50.9901 637.73 51.1601 637.16 51.3201C637.13 51.3201 637.02 51.1801 637.02 51.1101C637.02 50.7201 637.21 50.4701 637.56 50.2701C637.95 50.0501 638.31 49.7601 638.72 50.1501C638.82 50.2401 638.79 50.4601 638.86 50.8301V50.8201Z" fill="white"/>
                    <path d="M646.39 101.41C646.44 101.04 646.47 100.65 646.98 100.69C647.63 100.74 647.61 101.16 647.48 101.65C647.39 101.99 647.39 102.41 646.88 102.36C646.24 102.3 646.21 101.89 646.39 101.41Z" fill="white"/>
                    <path d="M667.18 153.76C667.63 154.01 667.83 154.18 667.68 154.49C667.39 155.1 666.93 155.45 666.23 155.32C666.09 155.29 665.99 155.09 665.87 154.97C666.32 154.55 666.77 154.13 667.17 153.75L667.18 153.76Z" fill="white"/>
                    <path d="M699.87 48.1C700.36 48.13 700.72 48.31 700.65 48.54C700.52 48.99 700.81 49.73 699.98 49.79C699.7 49.81 699.36 49.62 699.4 49.37C699.49 48.91 699.35 48.26 699.87 48.1Z" fill="white"/>
                    <path d="M770.83 188.06C771.2 188.15 771.55 188.23 771.42 188.75C771.25 189.47 770.76 189.16 770.35 189.1C769.98 189.04 769.64 188.94 769.77 188.42C769.94 187.7 770.43 188.04 770.83 188.06Z" fill="white"/>
                    <path d="M788.26 105.69C788.65 104.9 789.21 105.58 789.68 105.59C789.89 105.59 789.96 106.03 789.83 106.28C789.44 107.01 788.87 106.45 788.41 106.39C788.19 106.36 788.14 105.97 788.26 105.69Z" fill="white"/>
                    <path d="M657.05 153.7C657.1 153.33 657.13 152.94 657.64 152.98C658.29 153.03 658.27 153.45 658.14 153.94C658.05 154.28 658.05 154.7 657.54 154.65C656.9 154.59 656.87 154.18 657.05 153.7Z" fill="white"/>
                    <path d="M675.61 0.0300397C675.68 -0.59996 675.9 -0.71996 676.19 -0.68996C676.84 -0.61996 676.81 -0.18996 676.66 0.27004C676.58 0.51004 676.54 1.02004 676.35 0.91004C675.97 0.67004 675.74 0.20004 675.61 0.0300397Z" fill="white"/>
                    <path d="M805.29 181.61C805.41 181.7 805.61 181.85 805.8 182C805.37 182.25 805.04 182.79 804.43 182.58C804.17 182.49 804.03 182.3 804.12 181.96C804.29 181.25 804.77 181.56 805.29 181.61Z" fill="white"/>
                    <path d="M604.1 170.28C604.1 170.53 603.86 170.67 603.59 170.65C603.23 170.62 602.84 170.49 603.02 170C603.15 169.65 602.93 168.97 603.56 169.09C603.79 169.13 604.22 169.68 604.1 170.29V170.28Z" fill="white"/>
                    <path d="M634.23 1.32007C634.88 1.38007 634.94 2.02007 635.27 2.40007C635.02 2.44007 634.76 2.55007 634.53 2.51007C634.14 2.43007 633.57 2.51007 633.69 1.79007C633.74 1.46007 633.93 1.36007 634.23 1.32007Z" fill="white"/>
                    <path d="M635.22 173.59C635.22 173.97 635.32 173.59 634.99 173.72C634.81 173.8 634.42 173.72 634.31 173.56C634.02 173.16 633.52 172.78 633.83 172.17C633.87 172.1 634 172.01 634.04 172.03C634.68 172.37 634.79 173.1 635.22 173.59Z" fill="white"/>
                    <path d="M681.49 150.1C681.74 150.55 682.27 150.94 681.92 151.52C681.84 151.66 681.41 151.72 681.21 151.64C680.59 151.38 681.01 150.89 681.03 150.49C681.05 150.22 681.16 150.02 681.48 150.1H681.49Z" fill="white"/>
                    <path d="M746.48 89.1101C746.66 89.1501 746.86 89.1501 747.01 89.2401C747.11 89.3001 747.2 89.5501 747.15 89.6201C746.98 89.8701 745.3 89.5401 745.19 89.2301C745.16 89.1601 745.35 88.9001 745.42 88.9101C745.78 88.9501 746.13 89.0401 746.49 89.1201L746.48 89.1101Z" fill="white"/>
                    <path d="M711.42 235.9C711.98 235.98 712 236.33 711.88 236.7C711.79 236.97 711.6 237.09 711.26 237.02C710.76 236.91 710.74 236.59 710.86 236.23C710.95 235.98 711.14 235.75 711.42 235.91V235.9Z" fill="white"/>
                    <path d="M843.6 67.36C843.86 67.5 844 67.66 843.93 67.99C843.82 68.49 843.5 68.51 843.14 68.39C842.87 68.3 842.75 68.11 842.82 67.77C842.93 67.27 843.25 67.29 843.6 67.35V67.36Z" fill="white"/>
                    <path d="M832.84 172.52C832.76 172.86 832.66 173.15 832.15 173.05C831.81 172.98 831.68 172.79 831.71 172.51C831.75 172.13 831.89 171.84 832.39 171.94C832.72 172.01 832.82 172.19 832.83 172.52H832.84Z" fill="white"/>
                    <path d="M831.39 32.95C831.72 32.95 831.84 33.2 831.81 33.48C831.77 33.86 831.63 34.15 831.13 34.05C830.79 33.98 830.67 33.79 830.69 33.51C830.73 33.13 830.88 32.82 831.39 32.96V32.95Z" fill="white"/>
                    <path d="M720.86 34.25C721.12 34.39 721.26 34.55 721.19 34.88C721.08 35.38 720.76 35.4 720.4 35.28C720.13 35.19 720.01 35 720.08 34.66C720.19 34.16 720.51 34.1801 720.86 34.2401V34.25Z" fill="white"/>
                    <path d="M654.44 212.58C654.75 212.75 654.89 212.9 654.81 213.24C654.7 213.74 654.38 213.76 654.02 213.64C653.75 213.55 653.63 213.36 653.7 213.02C653.8 212.5 654.14 212.57 654.44 212.58Z" fill="white"/>
                    <path d="M654.59 189.84C654.51 190.36 654.17 190.38 653.8 190.26C653.53 190.17 653.41 189.98 653.48 189.64C653.59 189.14 653.91 189.12 654.27 189.24C654.54 189.33 654.72 189.53 654.59 189.84Z" fill="white"/>
                    <path d="M790.72 9.08002C791.07 9.17002 791.43 9.25002 791.28 9.75002C791.23 9.90002 791.11 10.03 791.03 10.17C790.77 9.87002 790.51 9.57002 790.25 9.27002C790.41 9.21002 790.56 9.15002 790.72 9.08002Z" fill="white"/>
                    <path d="M662.48 15.3C662.69 15.61 662.89 15.9 663.09 16.19C662.85 16.23 662.58 16.36 662.36 16.3C661.89 16.17 662 15.77 662.13 15.44C662.16 15.35 662.38 15.33 662.48 15.3Z" fill="white"/>
                    <path d="M786.65 113.39C786.53 113.57 786.42 113.84 786.36 113.83C785.88 113.75 785.87 113.23 785.59 112.94C785.75 112.88 785.91 112.76 786.06 112.77C786.44 112.8 786.73 112.96 786.65 113.39Z" fill="white"/>
                    <path d="M864.07 154.77C864.17 154.87 864.35 154.98 864.35 155.07C864.33 155.42 864.27 155.83 863.79 155.75C863.57 155.72 863.38 155.49 863.17 155.35C863.47 155.16 863.77 154.96 864.08 154.76L864.07 154.77Z" fill="white"/>
                    <path d="M846.05 133.83C846.13 133.33 846.49 133.35 846.84 133.47C846.98 133.52 847.08 133.7 847.2 133.82C846.84 133.99 846.48 134.16 846.13 134.34C846.11 134.18 846.08 134.01 846.06 133.84L846.05 133.83Z" fill="white"/>
                    <path d="M680.63 28.79C680.68 28.84 680.88 28.94 680.87 29.03C680.86 29.38 680.8 29.79 680.32 29.73C680.1 29.7 679.9 29.48 679.7 29.34C680 29.16 680.3 28.98 680.63 28.78V28.79Z" fill="white"/>
                    <path d="M715.5 37.6801C716 37.7601 715.98 38.1201 715.86 38.4701C715.81 38.6101 715.63 38.7101 715.51 38.8301C715.34 38.4701 715.17 38.1101 714.99 37.7601C715.15 37.7401 715.32 37.7101 715.49 37.6901L715.5 37.6801Z" fill="white"/>
                    <path d="M865.09 92.34C865.22 92.46 865.34 92.57 865.46 92.68C865.11 92.87 864.77 93.06 864.42 93.24C864.39 93.08 864.32 92.91 864.34 92.76C864.38 92.27 864.72 92.26 865.1 92.34H865.09Z" fill="white"/>
                    <path d="M672.19 187.28C672.27 186.78 672.63 186.8 672.98 186.92C673.12 186.97 673.22 187.15 673.34 187.27C672.98 187.44 672.62 187.61 672.27 187.79C672.25 187.63 672.22 187.46 672.2 187.29L672.19 187.28Z" fill="white"/>
                    <path d="M666.51 225.09C666.73 225.01 666.89 224.9 667.04 224.92C667.43 224.96 667.72 225.13 667.57 225.6C667.52 225.75 667.39 225.87 667.3 226.01C667.04 225.71 666.78 225.41 666.51 225.1V225.09Z" fill="white"/>
                    <path d="M606.92 28.1C607.2 28.35 607.02 28.62 606.96 28.86C606.94 28.93 606.65 29.03 606.59 28.98C606.31 28.77 606.49 28.49 606.56 28.25C606.58 28.17 606.81 28.15 606.93 28.1H606.92Z" fill="white"/>
                    <path d="M629.81 19.9701C630.12 19.9401 630.37 19.8801 630.61 19.9201C630.75 19.9401 630.85 20.1401 630.97 20.2601C630.73 20.3001 630.48 20.3901 630.24 20.3701C630.11 20.3601 630 20.1501 629.82 19.9601L629.81 19.9701Z" fill="white"/>
                    <path d="M765.53 224C765.81 224.25 765.63 224.52 765.57 224.76C765.55 224.83 765.26 224.93 765.2 224.88C764.92 224.67 765.1 224.39 765.17 224.15C765.19 224.07 765.42 224.05 765.54 224H765.53Z" fill="white"/>
                    <path d="M847.13 160.17C847.16 160.48 847.22 160.73 847.18 160.97C847.16 161.11 846.96 161.21 846.84 161.33C846.8 161.09 846.71 160.84 846.73 160.6C846.74 160.47 846.95 160.36 847.14 160.18L847.13 160.17Z" fill="white"/>
                    <path d="M847.38 157.19C847.34 157.33 847.31 157.58 847.19 157.79C847.05 158.03 846.86 157.99 846.84 157.72C846.83 157.48 846.88 157.22 846.99 157.01C847.12 156.76 847.28 156.81 847.38 157.19Z" fill="white"/>
                    <path d="M778.98 234.12C779.07 233.98 779.17 233.73 779.26 233.73C779.59 233.74 779.62 234 779.52 234.25C779.46 234.4 779.34 234.64 779.25 234.64C778.92 234.63 778.91 234.38 778.99 234.12H778.98Z" fill="white"/>
                    <path d="M655.87 1.87006C656.01 1.96006 656.26 2.06006 656.26 2.15006C656.25 2.48006 655.99 2.51006 655.74 2.41006C655.59 2.35006 655.35 2.23006 655.35 2.14006C655.36 1.81006 655.61 1.80006 655.87 1.88006V1.87006Z" fill="white"/>
                    <path d="M857.06 86.3501C857.37 86.3201 857.62 86.2601 857.86 86.3001C858 86.3201 858.1 86.5201 858.22 86.6401C857.98 86.6801 857.73 86.7701 857.49 86.7501C857.36 86.7401 857.25 86.5301 857.07 86.3401L857.06 86.3501Z" fill="white"/>
                    <path d="M621.07 147.04C621.16 147.13 621.27 147.25 621.39 147.37C621.15 147.41 620.9 147.5 620.66 147.48C620.53 147.47 620.42 147.26 620.3 147.14C620.52 146.96 620.76 146.9 621.07 147.04Z" fill="white"/>
                    <path d="M863.68 44.26C863.65 44.53 863.54 44.75 863.23 44.63C863.15 44.6 863.13 44.32 863.14 44.16C863.14 43.89 863.27 43.66 863.58 43.79C863.66 43.82 863.65 44.1 863.68 44.26Z" fill="white"/>
                    <path d="M657.73 155.83C657.7 156.1 657.59 156.32 657.28 156.2C657.2 156.17 657.18 155.89 657.19 155.73C657.19 155.46 657.32 155.23 657.63 155.36C657.71 155.39 657.7 155.67 657.73 155.83Z" fill="white"/>
                    <path d="M667.62 149.36C667.59 149.63 667.48 149.85 667.17 149.73C667.09 149.7 667.07 149.42 667.08 149.26C667.08 148.99 667.21 148.76 667.52 148.89C667.6 148.92 667.59 149.2 667.62 149.36Z" fill="white"/>
                    <path d="M700.29 120.62C700.6 120.59 700.85 120.53 701.09 120.57C701.23 120.59 701.33 120.79 701.45 120.91C701.21 120.95 700.96 121.04 700.72 121.02C700.59 121.01 700.48 120.8 700.3 120.61L700.29 120.62Z" fill="white"/>
                    <path d="M672.97 23.04C673.13 23.17 673.32 23.33 673.52 23.48C673.36 23.54 673.18 23.69 673.06 23.65C672.83 23.58 672.64 23.39 672.43 23.25C672.59 23.19 672.74 23.13 672.97 23.03V23.04Z" fill="white"/>
                    <path d="M751.76 63.51C751.85 63.6 751.96 63.72 752.08 63.84C751.84 63.88 751.59 63.97 751.35 63.95C751.22 63.94 751.11 63.73 750.99 63.61C751.21 63.43 751.45 63.37 751.76 63.51Z" fill="white"/>
                    <path d="M767.35 23.19C767.66 23.16 767.91 23.1 768.15 23.14C768.29 23.16 768.39 23.36 768.51 23.48C768.27 23.52 768.02 23.61 767.78 23.59C767.65 23.58 767.54 23.37 767.36 23.18L767.35 23.19Z" fill="white"/>
                    <path d="M806.49 18.63C806.46 18.9 806.35 19.12 806.04 19C805.96 18.97 805.94 18.69 805.95 18.53C805.95 18.26 806.08 18.03 806.39 18.16C806.47 18.19 806.46 18.47 806.49 18.63Z" fill="white"/>
                    <path d="M758.25 208.17C758.34 208.03 758.44 207.78 758.53 207.78C758.86 207.79 758.89 208.05 758.79 208.3C758.73 208.45 758.61 208.69 758.52 208.69C758.19 208.68 758.18 208.43 758.26 208.17H758.25Z" fill="white"/>
                    <path d="M762.47 117.49C762.61 117.58 762.86 117.68 762.86 117.77C762.85 118.1 762.59 118.13 762.34 118.03C762.19 117.97 761.95 117.85 761.95 117.76C761.96 117.43 762.21 117.42 762.47 117.5V117.49Z" fill="white"/>
                    <path d="M776.8 119.63C776.93 119.59 777.07 119.54 777.2 119.54C777.25 119.54 777.3 119.67 777.34 119.75C777.21 119.79 777.07 119.84 776.94 119.84C776.89 119.84 776.84 119.71 776.8 119.63Z" fill="white"/>
                    <path d="M874.88 91.2701C875.01 91.2301 875.15 91.1801 875.28 91.1801C875.33 91.1801 875.38 91.3101 875.42 91.3901C875.29 91.4301 875.15 91.4801 875.02 91.4801C874.97 91.4801 874.92 91.3501 874.88 91.2701Z" fill="white"/>
                    <path d="M672.8 139.17C672.9 139.26 673.01 139.35 673.11 139.44C673.04 139.49 672.94 139.59 672.9 139.57C672.78 139.51 672.68 139.41 672.57 139.32C672.64 139.27 672.72 139.22 672.8 139.17Z" fill="white"/>
                    <path d="M651.74 177.25C651.68 177.16 651.63 177.09 651.59 177.02C651.72 176.98 651.86 176.93 651.99 176.93C652.04 176.93 652.09 177.06 652.13 177.14C652 177.18 651.87 177.22 651.74 177.26V177.25Z" fill="white"/>
                    <path d="M666.99 97.69C666.9 97.79 666.81 97.9 666.72 98C666.67 97.93 666.57 97.83 666.59 97.79C666.65 97.67 666.75 97.57 666.84 97.46C666.89 97.53 666.94 97.61 666.99 97.69Z" fill="white"/>
                    <path d="M703.94 75.08C703.98 75.21 704.03 75.35 704.03 75.48C704.03 75.53 703.9 75.58 703.82 75.62C703.78 75.49 703.73 75.35 703.73 75.22C703.73 75.17 703.86 75.12 703.94 75.08Z" fill="white"/>
                    <path d="M717.78 31.7101C717.69 31.7701 717.62 31.8201 717.55 31.8601C717.51 31.7301 717.46 31.5901 717.46 31.4601C717.46 31.4101 717.59 31.3601 717.67 31.3201C717.71 31.4501 717.75 31.5801 717.79 31.7101H717.78Z" fill="white"/>
                    <path d="M669.46 3.10004C669.56 3.19004 669.67 3.28004 669.77 3.37004C669.7 3.42004 669.6 3.52004 669.56 3.50004C669.44 3.44004 669.34 3.34004 669.23 3.25004C669.3 3.20004 669.38 3.15004 669.46 3.10004Z" fill="white"/>
                    <path d="M661.49 115.18C661.55 115.27 661.6 115.34 661.64 115.41C661.51 115.45 661.37 115.5 661.24 115.5C661.19 115.5 661.14 115.37 661.1 115.29C661.23 115.25 661.36 115.21 661.49 115.17V115.18Z" fill="white"/>
                    <path d="M727.52 92.45C727.46 92.36 727.41 92.29 727.37 92.22C727.5 92.18 727.64 92.13 727.77 92.13C727.82 92.13 727.87 92.26 727.91 92.34C727.78 92.38 727.65 92.42 727.52 92.46V92.45Z" fill="white"/>
                    <path d="M784.97 146.74C785.07 146.83 785.18 146.92 785.28 147.01C785.21 147.06 785.11 147.16 785.07 147.14C784.95 147.08 784.85 146.98 784.74 146.89C784.81 146.84 784.89 146.79 784.97 146.74Z" fill="white"/>
                    <path d="M787.12 55.05C787.22 55.14 787.33 55.23 787.43 55.32C787.36 55.37 787.26 55.4701 787.22 55.4501C787.1 55.3901 787 55.2901 786.89 55.2001C786.96 55.1501 787.04 55.1 787.12 55.05Z" fill="white"/>
                    <path d="M658.7 69.3801C658.61 69.4401 658.54 69.4901 658.47 69.5301C658.43 69.4001 658.38 69.2601 658.38 69.1301C658.38 69.0801 658.51 69.0301 658.59 68.9901C658.63 69.1201 658.67 69.2501 658.71 69.3801H658.7Z" fill="white"/>
                    <path d="M658.7 57.15C658.8 57.24 658.91 57.33 659.01 57.42C658.94 57.47 658.84 57.57 658.8 57.55C658.68 57.49 658.58 57.39 658.47 57.3L658.7 57.15Z" fill="white"/>
                    <path d="M754.46 109.92C754.55 109.82 754.64 109.71 754.73 109.61C754.78 109.68 754.88 109.78 754.86 109.82C754.8 109.94 754.7 110.04 754.61 110.15C754.56 110.08 754.51 110 754.46 109.92Z" fill="white"/>
                    <path d="M787.2 235.57C787.33 235.53 787.47 235.48 787.6 235.48C787.65 235.48 787.7 235.61 787.74 235.69C787.61 235.73 787.47 235.78 787.34 235.78C787.29 235.78 787.24 235.65 787.2 235.57Z" fill="white"/>
                    <path d="M833.35 249.46C831.59 249.87 830.72 248.76 829.92 247.7C829.63 247.31 829.26 246.65 829.04 246.5C827.61 245.58 829.32 244.51 828.78 243.62C829.72 243.23 830.72 242.44 831.6 242.56C832.43 242.67 833.46 242.9 834.24 243.43C834.98 243.93 836.24 244.29 835.2 245.65C835.05 245.85 835.31 246.35 835.33 246.71C835.46 248.33 834.75 249.24 833.35 249.45V249.46Z" fill="white"/>
                    <path d="M909.65 125.13C909.79 126.19 909.07 127.06 908.69 127.1C907.25 127.28 906.87 128.68 905.79 129.26C905.03 129.67 904.51 129.8 903.87 129.26C902.82 128.38 902.62 127.78 903.09 126.89C903.3 126.51 903.45 126.12 903.59 125.71C904.23 123.94 907.84 122.73 909.21 124.4C909.45 124.7 909.66 124.94 909.64 125.12L909.65 125.13Z" fill="white"/>
                    <path d="M826.97 152.44C826.39 153.18 826.79 154.49 825.52 154.78C825.34 154.82 825.12 154.78 824.98 154.88C824.42 155.27 823.95 155.9 823.13 155.62C822.49 155.39 822.29 154.75 821.94 154.26C821.25 153.29 821.57 151.63 822.55 150.77C823.32 150.1 824.13 150.01 825.1 150.37C826.11 150.74 826.38 151.73 826.97 152.46V152.44Z" fill="white"/>
                    <path d="M750.75 47.72C750.52 46.14 750.99 45.33 752.11 45.02C752.73 44.85 753.39 44.84 754.02 44.7C754.62 44.57 754.84 44.8 754.89 45.38C754.95 46.02 755.17 46.66 755.16 47.29C755.13 48.37 754.52 49.17 753.64 49.74C753.46 49.86 753.07 49.88 752.91 49.76C752.33 49.33 751.67 49.12 751.04 48.82C750.74 48.67 750.77 47.83 750.75 47.71V47.72Z" fill="white"/>
                    <path d="M866.39 12.32C866.56 13.03 866.82 14.03 865.75 14.5C865.24 14.72 864.3 15.14 864 14.52C863.67 13.85 862.75 13.36 863.07 12.42C863.29 11.77 863.76 11.16 863.82 10.51C863.88 9.90998 864.05 9.75998 864.58 9.66998C865.34 9.53998 865.83 9.91998 866.08 10.51C866.29 11 866.28 11.59 866.39 12.33V12.32Z" fill="white"/>
                    <path d="M730.78 49.14C730.91 49.86 730.44 50.56 729.73 50.71C729.5 50.76 727.05 49.83 726.93 49.65C726.67 49.23 727.35 47.69 727.41 47.75C728.19 48.61 728.54 47.23 729.15 47.47C729.73 47.7 730.69 48.68 730.77 49.14H730.78Z" fill="white"/>
                    <path d="M791.99 201.46C792.37 201.41 792.94 201.22 793 201.59C793.11 202.26 793.53 202.95 793 203.68C792.71 204.09 792.2 204.1 791.96 204.43C791.56 204.96 791.23 205.1 790.62 204.7C789.96 204.27 789.77 203.62 790.06 203.09C790.47 202.34 790.87 201.39 791.99 201.47V201.46Z" fill="white"/>
                    <path d="M852.75 127.15C852.7 126.57 852.29 125.87 853.39 125.75C853.64 125.72 853.81 125.19 854.05 124.91C854.31 124.59 854.61 124.29 854.89 123.99C855.04 124.59 855.44 125.25 855.29 125.77C855.1 126.43 854.46 126.95 854.06 127.56C853.84 127.89 853.57 128.16 853.19 128.06C852.77 127.95 852.82 127.5 852.76 127.15H852.75Z" fill="white"/>
                    <path d="M708.49 158.86C708.46 158.68 708.41 158.5 708.41 158.33C708.41 157.93 708.02 157.41 708.62 157.17C709.23 156.92 709.81 157.03 710.33 157.52C710.77 157.94 710.6 158.25 710.35 158.67C710.19 158.94 710.25 159.35 710.09 159.62C709.88 159.98 709.69 160.48 709.14 160.43C708.48 160.37 708.7 159.78 708.58 159.4C708.53 159.23 708.52 159.05 708.49 158.87V158.86Z" fill="white"/>
                    <path d="M735.53 40.28C734.74 39.98 733.82 39.91 733.2 39.24C733.06 39.09 732.99 38.74 733.23 38.49C733.52 38.17 733.68 37.57 734.21 37.7C734.87 37.86 735.49 38.17 736.1 38.49C736.82 38.86 735.9 39.15 736 39.52C736.11 39.91 736.03 40.23 735.52 40.28H735.53Z" fill="white"/>
                    <path d="M862.35 171.17C862.19 170.69 862.31 170.36 862.92 170.3C863.87 170.2 864.47 170.62 864.64 171.45C864.79 172.19 864.39 172.67 863.85 172.94C863.22 173.25 862.51 172.38 862.35 171.17Z" fill="white"/>
                    <path d="M937.35 206.5C937.31 206.23 937.22 205.95 937.23 205.68C937.27 205.05 937.98 204.74 938.48 205.11C938.78 205.33 939.13 205.5 939.36 205.77C939.66 206.12 939.77 206.66 939.56 207.02C939.32 207.43 938.62 207.42 938.39 207.95C938.02 207.59 937.36 207.48 937.39 206.77C937.39 206.68 937.36 206.59 937.35 206.5Z" fill="white"/>
                    <path d="M750.44 89.29C750.25 89.55 750.07 89.76 749.93 89.99C749.56 90.57 748.67 90.07 748.35 90.8C748.18 91.19 747.77 91.25 747.46 90.97C747.17 90.7 747.22 90.35 747.48 90.03C747.82 89.6 748.1 89.13 748.46 88.72C748.63 88.53 748.66 87.95 749.18 88.35C749.6 88.68 750.03 88.98 750.46 89.29H750.44Z" fill="white"/>
                    <path d="M853.36 45.61C852.82 45.78 852.46 45.71 852.39 45.08C852.31 44.31 852.72 43.48 853.26 43.39C853.8 43.31 854.47 43.99 854.58 44.75C854.75 45.89 853.77 45.38 853.35 45.6L853.36 45.61Z" fill="white"/>
                    <path d="M859.6 48.16C859.41 48.63 859.22 49.15 858.65 49.28C858.03 49.42 857.24 48.44 857.28 47.69C857.31 47.19 857.67 47.17 858.02 47.15C858.83 47.1 859.58 47.58 859.59 48.16H859.6Z" fill="white"/>
                    <path d="M777.74 69.98C777.69 69.62 777.68 69.44 777.63 69.27C777.44 68.62 778.03 68.33 778.26 67.88C778.62 68.24 779.26 68.34 779.28 69.02C779.28 69.2 779.32 69.38 779.36 69.55C779.5 70.14 779.48 70.54 778.69 70.65C777.92 70.75 777.71 70.48 777.75 69.98H777.74Z" fill="white"/>
                    <path d="M936.5 62.48C935.87 62.62 935.67 62.22 935.77 61.88C935.95 61.22 936.26 60.59 936.56 59.97C936.84 59.39 937.11 59.91 937.35 60.04C938.43 60.61 937.31 61.27 937.4 61.89C937.5 62.62 936.75 62.3 936.5 62.47V62.48Z" fill="white"/>
                    <path d="M807.32 54.6C807.58 55.32 807.17 55.82 806.63 56.11C806.23 56.33 805.02 55.39 805.15 55.04C805.4 54.35 805.92 54 806.68 53.95C807.21 53.91 807.25 54.24 807.31 54.61L807.32 54.6Z" fill="white"/>
                    <path d="M743.93 210.57C743.39 210.56 743.02 210.18 742.73 209.76C742.55 209.51 743.62 208.43 744.18 208.29C744.68 208.17 744.78 208.51 744.83 208.88C744.92 209.63 744.49 210.47 743.94 210.57H743.93Z" fill="white"/>
                    <path d="M771.92 38.59C772.2 38.63 772.49 38.78 772.39 39.09C772.14 39.82 772.07 40.62 771.55 41.26C771.33 41.53 771.03 41.6 770.84 41.52C770.66 41.45 770.42 41.06 770.47 40.9C770.67 40.24 770.98 39.62 771.26 38.99C771.38 38.72 771.62 38.6 771.93 38.59H771.92Z" fill="white"/>
                    <path d="M755.54 13.44C755.61 13.72 755.7 14.08 755.25 14.25C754.85 14.4 754.61 14.09 754.3 13.94C753.68 13.63 753.76 13.03 753.76 12.54C753.76 12.25 754.16 11.71 754.34 11.72C754.7 11.76 755.12 12.03 755.34 12.33C755.53 12.58 755.48 13.02 755.54 13.43V13.44Z" fill="white"/>
                    <path d="M906.55 22.81C906.98 22.67 907.31 22.79 907.37 23.4C907.44 24.16 907.03 24.97 906.49 25.07C905.58 25.23 906.03 24 905.3 23.89C905.18 23.87 905.87 22.97 906.56 22.81H906.55Z" fill="white"/>
                    <path d="M766.63 121.43C766.55 121.58 766.5 121.8 766.38 121.85C765.71 122.13 764.33 121.33 764.28 120.65C764.26 120.41 764.31 120.06 764.46 119.96C764.62 119.85 764.98 119.9 765.18 120.01C766.08 120.49 766.56 120.99 766.63 121.44V121.43Z" fill="white"/>
                    <path d="M820.48 209.39C820.75 209.35 821.02 209.26 821.28 209.27C821.96 209.31 821.75 209.91 821.86 210.29C822 210.79 821.65 210.89 821.27 210.95C820.93 211 820.59 211.12 820.26 210.84C819.92 210.55 819.43 210.35 819.57 209.81C819.68 209.39 820.14 209.46 820.48 209.39Z" fill="white"/>
                    <path d="M773.81 41.02C773.84 40.45 774.22 40.44 774.57 40.42C775.4 40.38 776.13 40.86 776.12 41.44C776.12 41.93 775.72 41.95 775.37 41.97C774.54 42.01 773.83 41.53 773.81 41.02Z" fill="white"/>
                    <path d="M905.78 15.07C906.28 15.47 906.85 15.72 906.88 16.49C906.91 17.13 906.6 17.2 906.12 17.29C905.6 17.38 905.35 17.31 905.18 16.73C904.96 15.96 905.51 15.6 905.78 15.08V15.07Z" fill="white"/>
                    <path d="M911.41 27.81C911.34 27.96 911.3 28.22 911.19 28.25C910.85 28.36 910.42 28.52 910.15 28.4C909.71 28.2 909.12 27.99 909.1 27.34C909.08 26.84 909.48 26.82 909.83 26.8C910.67 26.76 911.35 27.21 911.4 27.81H911.41Z" fill="white"/>
                    <path d="M786.67 225.68C786.72 226.05 786.76 226.46 786.24 226.45C785.57 226.45 785 226.11 784.98 225.42C784.97 225.06 785.39 224.68 785.61 224.32C786.07 224.69 786.74 224.91 786.67 225.68Z" fill="white"/>
                    <path d="M919.23 164.42C918.57 164.48 917.78 163.83 917.78 163.37C917.78 162.88 918.18 162.91 918.52 162.83C919.2 162.68 919.71 162.99 919.9 163.56C919.96 163.73 919.58 164.36 919.23 164.42Z" fill="white"/>
                    <path d="M824.14 43.69C824.21 44.15 824.26 44.63 823.77 44.56C823.17 44.47 822.59 44.16 822.01 43.95C822.35 43.42 822.69 42.96 823.44 42.94C824.15 42.92 824.05 43.4 824.14 43.7V43.69Z" fill="white"/>
                    <path d="M949.72 101.66C949.92 102.39 949.57 102.5 949.05 102.62C948.25 102.81 948.15 102.39 948.06 101.78C947.96 101.16 948.26 101.05 948.78 100.94C949.55 100.78 949.78 101.1 949.73 101.66H949.72Z" fill="white"/>
                    <path d="M813.36 38.91C812.87 38.9 812.15 39.38 812.14 38.44C812.14 37.93 812.47 37.43 813.03 37.28C813.95 37.03 813.85 38.08 814.15 38.45C814.54 38.91 813.6 38.68 813.35 38.91H813.36Z" fill="white"/>
                    <path d="M739.54 4.23997C739.58 4.50997 739.66 4.76997 739.65 5.03997C739.63 5.51997 739.24 5.52997 738.89 5.55997C738.64 5.57997 738.35 5.75997 738.14 5.48997C737.9 5.17997 737.91 4.85997 738.18 4.54997C738.3 4.40997 738.39 4.25997 738.5 4.11997C738.71 3.84997 738.95 3.34997 739.14 3.36997C739.55 3.40997 739.45 3.91997 739.54 4.24997V4.23997Z" fill="white"/>
                    <path d="M894.44 210.85C895.09 210.95 894.92 211.5 894.99 211.89C895.13 212.61 894.51 212.48 894.13 212.57C893.77 212.65 893.39 212.67 893.36 212.14C893.33 211.6 893.96 210.85 894.44 210.84V210.85Z" fill="white"/>
                    <path d="M877.75 65.97C877.27 66.02 876.9 66.08 876.87 65.54C876.84 65 877.47 64.23 877.95 64.25C878.44 64.27 878.38 64.68 878.48 65.01C878.72 65.82 878.24 65.93 877.75 65.96V65.97Z" fill="white"/>
                    <path d="M781.51 19.68C781.7 19.7 782.08 19.64 782.17 19.78C782.42 20.15 781.98 20.4 781.79 20.56C781.49 20.8 781.56 21.81 780.75 21.14C780.48 20.92 780.13 20.75 780.18 20.34C780.28 19.5 781.05 19.98 781.52 19.69L781.51 19.68Z" fill="white"/>
                    <path d="M726.36 151.16C726.17 151.55 726.03 152.33 725.54 152.06C725.12 151.83 724.44 152.2 724.28 151.57C724.19 151.19 725.43 150.44 725.95 150.56C726.22 150.62 726.37 150.84 726.36 151.16Z" fill="white"/>
                    <path d="M933.13 106.19C933.13 106.19 933.46 106.56 933.7 106.93C933.8 107.09 932.88 107.96 932.63 107.94C931.95 107.9 932.16 107.32 932.05 106.94C931.92 106.48 932.2 106.32 933.13 106.19Z" fill="white"/>
                    <path d="M933.61 138.79C933.52 139.44 932.96 139.24 932.58 139.35C932.1 139.48 931.87 139.13 931.95 138.79C932.05 138.39 932.34 138.02 932.61 137.69C932.71 137.57 933.62 138.55 933.61 138.8V138.79Z" fill="white"/>
                    <path d="M815.49 258.92C815.03 259.09 814.81 258.9 814.77 258.64C814.67 257.94 815.05 257.5 815.67 257.25C815.99 257.12 816.21 257.32 816.26 257.58C816.39 258.3 815.95 258.7 815.49 258.93V258.92Z" fill="white"/>
                    <path d="M747.33 113.03C747.92 113.03 748.41 113.33 748.48 113.79C748.52 114.02 748.18 114.61 747.64 114.68C746.92 114.77 747.05 114.2 746.96 113.82C746.87 113.46 746.88 113.12 747.34 113.04L747.33 113.03Z" fill="white"/>
                    <path d="M900.96 227.79C900.71 227.87 899.55 227.29 899.63 227.16C899.86 226.8 900.14 226.42 900.49 226.21C900.82 226.02 901.19 226.19 901.2 226.66C901.21 227.05 901.59 227.54 900.96 227.79Z" fill="white"/>
                    <path d="M744.89 107.94C744.81 108.45 744.57 108.99 743.84 109.08C743.57 109.11 743.32 108.96 743.35 108.62C743.41 107.95 743.71 107.45 744.41 107.34C744.67 107.3 744.92 107.45 744.9 107.94H744.89Z" fill="white"/>
                    <path d="M683.17 103.87C683.28 104.37 682.95 104.6 682.6 104.52C682.2 104.42 681.83 104.13 681.5 103.86C681.38 103.76 682.36 102.85 682.61 102.86C682.96 102.87 683.18 103.27 683.17 103.87Z" fill="white"/>
                    <path d="M815.61 42.87C815.66 43.25 815.72 43.63 815.2 43.67C814.64 43.71 814.15 43.38 814.08 42.92C814.05 42.69 814.39 42.1 814.92 42.03C815.65 41.93 815.49 42.52 815.6 42.87H815.61Z" fill="white"/>
                    <path d="M728.71 32.55C728.54 33.04 728.34 33.47 727.79 33.62C727.47 33.71 727.23 33.53 727.22 33.27C727.22 32.82 726.68 32.2 727.55 31.98C728.08 31.85 728.29 32.39 728.7 32.55H728.71Z" fill="white"/>
                    <path d="M808.24 53.3C808.16 53.45 808.09 53.59 808.01 53.74C807.36 53.28 806.77 52.71 805.9 52.66C805.86 52.66 805.8 52.31 805.83 52.14C805.85 52.04 806.13 51.9 806.16 51.93C806.7 52.42 807.6 52.16 808.05 52.82C808.14 52.96 808.17 53.14 808.23 53.31L808.24 53.3Z" fill="white"/>
                    <path d="M804.24 54.88C803.7 54.93 803.88 54.19 803.86 53.77C803.81 52.93 804.59 53.26 805.02 53.12C805.15 53.08 805.68 53.3 805.26 53.51C804.63 53.83 805.59 54.82 804.24 54.88Z" fill="white"/>
                    <path d="M839.87 222.66C840.51 222.45 840.79 223.05 841.23 223.27C841.04 223.55 840.87 224.05 840.64 224.07C840.14 224.13 839.75 223.78 839.57 223.28C839.46 222.97 839.63 222.8 839.87 222.66Z" fill="white"/>
                    <path d="M927.84 102.52C927.45 102.52 926.94 102.84 926.81 102.11C926.72 101.58 927.05 101.5 927.42 101.47C927.82 101.43 928.33 101.14 928.46 101.87C928.56 102.4 928.21 102.45 927.84 102.53V102.52Z" fill="white"/>
                    <path d="M766.9 232.54C766.52 233.37 765.99 233.62 765.33 233.6C765.18 233.6 765.02 233.47 764.87 233.4C764.98 233.26 765.11 233.13 765.18 232.97C765.44 232.35 766 232.36 766.52 232.33C766.68 232.32 766.85 232.51 766.9 232.54Z" fill="white"/>
                    <path d="M956.14 103C956.17 103.13 955.84 103.25 955.46 103.28C955.06 103.31 954.55 103.62 954.41 102.89C954.31 102.38 954.63 102.27 955.01 102.24C955.41 102.2 955.92 101.92 956.14 103Z" fill="white"/>
                    <path d="M900.76 83.63C900.64 83.58 900.42 83.55 900.4 83.47C900.25 82.87 900.12 82.28 900.67 81.75C900.9 81.53 901.05 81.44 901.23 81.7C901.5 82.11 901.17 83.44 900.76 83.63Z" fill="white"/>
                    <path d="M867.99 185.92C867.9 186.8 867.14 186.36 866.7 186.51C866.5 186.58 866.28 186.2 866.32 185.92C866.43 185.1 867.15 185.43 867.6 185.32C867.81 185.27 868 185.61 867.98 185.92H867.99Z" fill="white"/>
                    <path d="M837.49 82.97C837.4 83.85 836.64 83.41 836.2 83.56C836 83.63 835.78 83.25 835.82 82.97C835.93 82.15 836.65 82.48 837.1 82.37C837.31 82.32 837.5 82.66 837.48 82.97H837.49Z" fill="white"/>
                    <path d="M745.48 165.85C745.51 165.98 745.18 166.1 744.8 166.13C744.4 166.16 743.89 166.47 743.75 165.74C743.65 165.23 743.97 165.12 744.35 165.09C744.75 165.05 745.26 164.77 745.48 165.85Z" fill="white"/>
                    <path d="M837.27 10.06C836.92 9.60003 836.56 9.13003 836.21 8.65003C836.19 8.63003 836.29 8.47003 836.36 8.45003C836.72 8.32003 837.02 8.40003 837.33 8.65003C837.68 8.93003 838.07 9.17003 837.85 9.69003C837.8 9.81003 837.58 9.87003 837.27 10.06Z" fill="white"/>
                    <path d="M792.77 34.81C793.13 34.73 793.51 34.62 793.65 35.1C793.83 35.73 793.43 35.86 792.93 35.91C792.58 35.95 792.19 36.09 792.06 35.6C791.89 34.98 792.26 34.82 792.78 34.81H792.77Z" fill="white"/>
                    <path d="M751.29 72.53C751.21 73.04 751.13 73.28 750.78 73.25C750.11 73.19 749.62 72.89 749.5 72.19C749.48 72.05 749.63 71.89 749.7 71.73C750.25 72 750.8 72.28 751.29 72.52V72.53Z" fill="white"/>
                    <path d="M861.19 65.95C861.33 66.42 861.29 66.81 861.06 66.83C860.6 66.86 860.01 67.39 859.66 66.64C859.54 66.38 859.6 66 859.85 65.95C860.31 65.87 860.87 65.52 861.2 65.95H861.19Z" fill="white"/>
                    <path d="M755.67 181.12C755.71 181.5 755.76 181.85 755.23 181.91C754.5 182 754.62 181.43 754.54 181.04C754.46 180.68 754.44 180.33 754.97 180.26C755.7 180.17 755.56 180.75 755.67 181.12Z" fill="white"/>
                    <path d="M838.52 168.48C839.4 168.57 838.96 169.33 839.11 169.77C839.18 169.97 838.8 170.19 838.52 170.15C837.7 170.04 838.03 169.32 837.92 168.87C837.87 168.66 838.21 168.47 838.52 168.49V168.48Z" fill="white"/>
                    <path d="M747.8 63.07C748.16 62.99 748.54 62.88 748.68 63.36C748.86 63.99 748.46 64.12 747.96 64.17C747.61 64.21 747.22 64.35 747.09 63.86C746.92 63.24 747.29 63.08 747.81 63.07H747.8Z" fill="white"/>
                    <path d="M897.47 26.49C898.09 26.33 898.27 26.49 898.34 26.78C898.5 27.41 898.09 27.53 897.61 27.56C897.36 27.57 896.86 27.71 896.91 27.49C897 27.05 897.36 26.67 897.47 26.49Z" fill="white"/>
                    <path d="M773.76 210.96C773.72 211.1 773.65 211.34 773.58 211.58C773.19 211.27 772.58 211.15 772.56 210.5C772.56 210.22 772.69 210.03 773.03 209.99C773.75 209.9 773.63 210.46 773.77 210.96H773.76Z" fill="white"/>
                    <path d="M713.79 19.55C713.56 19.63 713.35 19.47 713.27 19.2C713.17 18.85 713.16 18.45 713.68 18.44C714.05 18.44 714.61 17.99 714.72 18.62C714.76 18.85 714.4 19.44 713.79 19.55Z" fill="white"/>
                    <path d="M721.61 49.71C721.26 49.85 721.65 49.8 721.4 49.54C721.27 49.4 721.21 49 721.31 48.85C721.59 48.44 721.76 47.84 722.44 47.92C722.52 47.92 722.65 48.02 722.65 48.06C722.56 48.77 721.91 49.14 721.61 49.71Z" fill="white"/>
                    <path d="M759.72 84.58C759.39 84.97 759.22 85.6 758.55 85.48C758.39 85.45 758.19 85.07 758.19 84.86C758.21 84.2 758.82 84.41 759.2 84.29C759.46 84.21 759.68 84.24 759.72 84.58Z" fill="white"/>
                    <path d="M839.32 123.75C839.35 123.93 839.42 124.12 839.39 124.28C839.37 124.39 839.17 124.56 839.09 124.54C838.8 124.47 838.52 122.79 838.77 122.57C838.83 122.52 839.13 122.6 839.15 122.67C839.24 123.02 839.28 123.38 839.33 123.74L839.32 123.75Z" fill="white"/>
                    <path d="M690.27 142.54C690.39 143.09 690.07 143.23 689.69 143.25C689.41 143.26 689.22 143.12 689.17 142.79C689.1 142.28 689.39 142.15 689.77 142.14C690.04 142.14 690.31 142.23 690.26 142.54H690.27Z" fill="white"/>
                    <path d="M891.92 3.33C891.88 3.63 891.78 3.81 891.44 3.86C890.93 3.93 890.81 3.64 890.79 3.26C890.78 2.98 890.92 2.79 891.25 2.74C891.76 2.66 891.85 2.97 891.91 3.33H891.92Z" fill="white"/>
                    <path d="M893.63 206.61C893.59 206.91 893.49 207.09 893.15 207.14C892.64 207.21 892.52 206.92 892.5 206.54C892.49 206.26 892.63 206.07 892.96 206.02C893.47 205.94 893.56 206.25 893.62 206.61H893.63Z" fill="white"/>
                    <path d="M791.89 233.44C791.55 233.49 791.23 233.49 791.15 232.98C791.1 232.64 791.23 232.45 791.5 232.38C791.87 232.28 792.19 232.31 792.27 232.82C792.32 233.16 792.19 233.31 791.89 233.44Z" fill="white"/>
                    <path d="M943.69 86.69C944.18 86.83 944.6 87.05 944.75 87.57C944.79 87.71 944.67 88.03 944.66 88.02C944.15 87.95 943.74 87.71 943.6 87.18C943.56 87.04 943.66 86.86 943.7 86.68L943.69 86.69Z" fill="white"/>
                    <path d="M921.4 183.16C921.51 183.47 921.32 183.66 921.05 183.74C920.68 183.84 920.36 183.81 920.28 183.3C920.22 182.96 920.36 182.78 920.63 182.7C921 182.6 921.34 182.64 921.39 183.16H921.4Z" fill="white"/>
                    <path d="M881.45 80.64C881.41 80.94 881.31 81.12 880.97 81.17C880.46 81.24 880.34 80.95 880.32 80.57C880.31 80.29 880.45 80.1 880.78 80.05C881.29 79.97 881.38 80.28 881.44 80.64H881.45Z" fill="white"/>
                    <path d="M692.03 81.28C691.98 81.63 691.89 81.81 691.55 81.86C691.04 81.93 690.92 81.64 690.9 81.26C690.89 80.98 691.03 80.8 691.36 80.74C691.88 80.66 691.94 80.99 692.03 81.28Z" fill="white"/>
                    <path d="M713.26 73.45C712.74 73.56 712.61 73.24 712.59 72.86C712.58 72.58 712.72 72.39 713.05 72.34C713.56 72.27 713.68 72.56 713.7 72.94C713.71 73.22 713.58 73.46 713.25 73.44L713.26 73.45Z" fill="white"/>
                    <path d="M972.64 111.99C972.6 111.63 972.55 111.27 973.07 111.24C973.23 111.24 973.39 111.3 973.55 111.33C973.36 111.68 973.17 112.02 972.98 112.37C972.87 112.25 972.75 112.12 972.64 112V111.99Z" fill="white"/>
                    <path d="M929.4 136.91C929.44 137.27 929.49 137.63 928.97 137.66C928.81 137.66 928.65 137.6 928.49 137.57C928.68 137.22 928.87 136.88 929.06 136.53C929.17 136.65 929.29 136.78 929.4 136.9V136.91Z" fill="white"/>
                    <path d="M878.65 19.61C878.44 19.92 878.23 20.21 878.03 20.49C877.91 20.28 877.69 20.07 877.67 19.85C877.63 19.37 878.04 19.33 878.39 19.33C878.48 19.33 878.58 19.52 878.65 19.6V19.61Z" fill="white"/>
                    <path d="M830.78 169.69C830.57 169.65 830.28 169.64 830.27 169.57C830.18 169.09 830.66 168.9 830.83 168.54C830.94 168.66 831.11 168.77 831.15 168.92C831.25 169.29 831.21 169.61 830.78 169.69Z" fill="white"/>
                    <path d="M819.37 256.31C819.31 256.43 819.28 256.64 819.18 256.67C818.85 256.78 818.45 256.86 818.34 256.38C818.29 256.16 818.44 255.91 818.49 255.67C818.78 255.88 819.06 256.09 819.36 256.31H819.37Z" fill="white"/>
                    <path d="M832.56 232.19C833.05 232.09 833.16 232.43 833.18 232.8C833.18 232.95 833.05 233.1 832.98 233.25C832.69 232.98 832.41 232.7 832.12 232.43C832.26 232.35 832.41 232.27 832.56 232.19Z" fill="white"/>
                    <path d="M872.44 41.24C872.41 41.31 872.39 41.52 872.3 41.55C871.97 41.66 871.57 41.75 871.45 41.28C871.4 41.07 871.54 40.8 871.59 40.56C871.86 40.78 872.14 41 872.44 41.24Z" fill="white"/>
                    <path d="M876.37 76.85C876.47 77.34 876.13 77.45 875.76 77.47C875.61 77.47 875.46 77.34 875.31 77.27C875.58 76.98 875.86 76.7 876.13 76.41C876.21 76.55 876.29 76.7 876.37 76.85Z" fill="white"/>
                    <path d="M877.89 235.39C877.82 235.55 877.76 235.7 877.7 235.86C877.4 235.6 877.11 235.35 876.81 235.09C876.95 235 877.08 234.88 877.23 234.84C877.7 234.71 877.83 235.02 877.89 235.4V235.39Z" fill="white"/>
                    <path d="M721.82 88.94C722.31 88.84 722.42 89.18 722.44 89.55C722.44 89.7 722.31 89.85 722.24 90C721.95 89.73 721.67 89.45 721.38 89.18C721.52 89.1 721.67 89.02 721.82 88.94Z" fill="white"/>
                    <path d="M684.6 96.9C684.75 97.08 684.91 97.19 684.95 97.34C685.05 97.71 684.99 98.05 684.5 98.07C684.34 98.07 684.18 98 684.02 97.96C684.21 97.62 684.4 97.27 684.6 96.9Z" fill="white"/>
                    <path d="M720.32 188.78C720.19 189.13 719.87 189.06 719.63 189.08C719.55 189.08 719.36 188.85 719.39 188.77C719.48 188.43 719.82 188.51 720.06 188.49C720.14 188.49 720.24 188.68 720.32 188.78Z" fill="white"/>
                    <path d="M808.4 242.43C808.12 242.56 807.91 242.71 807.68 242.75C807.54 242.77 807.38 242.63 807.23 242.56C807.44 242.43 807.64 242.26 807.87 242.2C807.99 242.17 808.17 242.32 808.41 242.43H808.4Z" fill="white"/>
                    <path d="M811.27 241.62C811.12 241.63 810.88 241.69 810.64 241.66C810.36 241.62 810.33 241.42 810.59 241.31C810.8 241.21 811.07 241.17 811.3 241.2C811.57 241.23 811.59 241.4 811.27 241.63V241.62Z" fill="white"/>
                    <path d="M715.61 204.86C715.77 204.9 716.04 204.9 716.07 204.98C716.17 205.29 715.94 205.41 715.68 205.4C715.52 205.4 715.25 205.37 715.22 205.28C715.12 204.97 715.34 204.87 715.61 204.85V204.86Z" fill="white"/>
                    <path d="M888.83 8.74002C888.79 8.90002 888.79 9.17002 888.71 9.20002C888.4 9.30002 888.28 9.07002 888.29 8.81002C888.29 8.65002 888.32 8.38002 888.41 8.35002C888.72 8.25002 888.82 8.47002 888.84 8.74002H888.83Z" fill="white"/>
                    <path d="M880.66 225.8C880.79 226.08 880.94 226.29 880.98 226.52C881 226.66 880.86 226.82 880.79 226.97C880.66 226.76 880.49 226.56 880.43 226.33C880.4 226.21 880.55 226.03 880.66 225.79V225.8Z" fill="white"/>
                    <path d="M741.38 27.22C741.33 27.33 741.26 27.48 741.19 27.64C741.06 27.43 740.89 27.23 740.83 27C740.8 26.88 740.95 26.7 741.02 26.55C741.27 26.69 741.41 26.9 741.38 27.23V27.22Z" fill="white"/>
                    <path d="M922.19 217.22C921.93 217.28 921.68 217.26 921.68 216.93C921.68 216.84 921.93 216.73 922.08 216.68C922.33 216.59 922.59 216.63 922.58 216.96C922.58 217.05 922.32 217.13 922.18 217.22H922.19Z" fill="white"/>
                    <path d="M746.05 64.45C745.79 64.51 745.54 64.49 745.54 64.16C745.54 64.07 745.79 63.96 745.94 63.91C746.19 63.82 746.45 63.86 746.44 64.19C746.44 64.28 746.18 64.36 746.04 64.45H746.05Z" fill="white"/>
                    <path d="M755.54 71.4C755.28 71.46 755.03 71.44 755.03 71.11C755.03 71.02 755.28 70.91 755.43 70.86C755.68 70.77 755.94 70.81 755.93 71.14C755.93 71.23 755.67 71.31 755.53 71.4H755.54Z" fill="white"/>
                    <path d="M793.77 91.76C793.9 92.04 794.05 92.25 794.09 92.48C794.11 92.62 793.97 92.78 793.9 92.93C793.77 92.72 793.6 92.52 793.54 92.29C793.51 92.17 793.66 91.99 793.77 91.75V91.76Z" fill="white"/>
                    <path d="M875.1 32.1C875.03 32.3 874.96 32.53 874.88 32.76C874.77 32.64 874.57 32.52 874.56 32.39C874.55 32.15 874.65 31.91 874.71 31.67C874.82 31.79 874.94 31.92 875.1 32.1Z" fill="white"/>
                    <path d="M865.02 119.69C864.97 119.8 864.9 119.95 864.83 120.11C864.7 119.9 864.53 119.7 864.47 119.47C864.44 119.35 864.59 119.17 864.66 119.02C864.91 119.16 865.05 119.37 865.02 119.7V119.69Z" fill="white"/>
                    <path d="M908.06 120.08C908.19 120.36 908.34 120.57 908.38 120.8C908.4 120.94 908.26 121.1 908.19 121.25C908.06 121.04 907.89 120.84 907.83 120.61C907.8 120.49 907.95 120.31 908.06 120.07V120.08Z" fill="white"/>
                    <path d="M975.51 120.17C975.38 120.52 975.06 120.45 974.82 120.47C974.74 120.47 974.55 120.24 974.58 120.16C974.67 119.82 975.01 119.9 975.25 119.88C975.33 119.88 975.43 120.07 975.51 120.17Z" fill="white"/>
                    <path d="M926.02 154.95C925.76 155.01 925.51 154.99 925.51 154.66C925.51 154.57 925.76 154.46 925.91 154.41C926.16 154.32 926.42 154.36 926.41 154.69C926.41 154.78 926.15 154.86 926.01 154.95H926.02Z" fill="white"/>
                    <path d="M732.53 176.45C732.69 176.49 732.96 176.49 732.99 176.57C733.09 176.88 732.86 177 732.6 176.99C732.44 176.99 732.17 176.96 732.14 176.87C732.04 176.56 732.26 176.46 732.53 176.44V176.45Z" fill="white"/>
                    <path d="M818.49 148.59C818.45 148.75 818.45 149.02 818.37 149.05C818.06 149.15 817.94 148.92 817.95 148.66C817.95 148.5 817.98 148.23 818.07 148.2C818.38 148.1 818.48 148.32 818.5 148.59H818.49Z" fill="white"/>
                    <path d="M821.51 162.69C821.59 162.8 821.69 162.91 821.74 163.03C821.76 163.07 821.65 163.17 821.6 163.24C821.52 163.13 821.42 163.02 821.37 162.9C821.35 162.86 821.46 162.76 821.51 162.69Z" fill="white"/>
                    <path d="M882.32 244.13C882.4 244.24 882.5 244.35 882.55 244.47C882.57 244.51 882.46 244.61 882.41 244.68C882.33 244.57 882.23 244.46 882.18 244.34C882.16 244.3 882.27 244.2 882.32 244.13Z" fill="white"/>
                    <path d="M766.86 72.65C766.81 72.77 766.77 72.9 766.72 73.03C766.65 72.98 766.52 72.92 766.52 72.88C766.52 72.75 766.59 72.62 766.63 72.49C766.7 72.54 766.77 72.59 766.85 72.65H766.86Z" fill="white"/>
                    <path d="M723.99 66.38C724.05 66.3 724.1 66.23 724.15 66.16C724.23 66.27 724.33 66.38 724.38 66.5C724.4 66.54 724.29 66.64 724.24 66.71C724.16 66.6 724.07 66.49 723.99 66.39V66.38Z" fill="white"/>
                    <path d="M803.47 52.69C803.35 52.64 803.22 52.6 803.09 52.55C803.14 52.48 803.2 52.35 803.24 52.35C803.37 52.35 803.5 52.42 803.63 52.46C803.58 52.53 803.53 52.6 803.47 52.68V52.69Z" fill="white"/>
                    <path d="M837.48 79.2C837.37 79.28 837.26 79.38 837.14 79.43C837.1 79.45 837 79.34 836.93 79.29C837.04 79.21 837.15 79.11 837.27 79.06C837.31 79.04 837.41 79.15 837.48 79.2Z" fill="white"/>
                    <path d="M882.74 76.88C882.66 76.82 882.59 76.77 882.52 76.72C882.63 76.64 882.74 76.54 882.86 76.49C882.9 76.47 883 76.58 883.07 76.63C882.96 76.71 882.85 76.8 882.75 76.88H882.74Z" fill="white"/>
                    <path d="M892.46 21.83C892.41 21.95 892.37 22.08 892.32 22.21C892.25 22.16 892.12 22.1 892.12 22.06C892.12 21.93 892.19 21.8 892.23 21.67C892.3 21.72 892.37 21.77 892.45 21.83H892.46Z" fill="white"/>
                    <path d="M785.24 53.71C785.18 53.79 785.13 53.86 785.08 53.93C785 53.82 784.9 53.71 784.85 53.59C784.83 53.55 784.94 53.45 784.99 53.38C785.07 53.49 785.16 53.6 785.24 53.7V53.71Z" fill="white"/>
                    <path d="M829.56 107.25C829.62 107.17 829.67 107.1 829.72 107.03C829.8 107.14 829.9 107.25 829.95 107.37C829.97 107.41 829.86 107.51 829.81 107.58C829.73 107.47 829.64 107.36 829.56 107.26V107.25Z" fill="white"/>
                    <path d="M963.46 97.62C963.53 97.67 963.66 97.75 963.66 97.77C963.61 97.9 963.56 98.06 963.46 98.13C963.25 98.28 963.12 98.18 963.19 97.94C963.23 97.82 963.37 97.73 963.46 97.63V97.62Z" fill="white"/>
                    <path d="M963.25 98.41C963.15 98.51 963.05 98.71 962.97 98.69C962.75 98.66 962.69 98.5 962.82 98.38C962.93 98.28 963.08 98.23 963.22 98.16C963.23 98.24 963.25 98.32 963.26 98.4L963.25 98.41Z" fill="white"/>
                    <path d="M799.12 179.81C799.07 179.93 799.03 180.06 798.98 180.19C798.91 180.14 798.78 180.08 798.78 180.04C798.78 179.91 798.85 179.78 798.89 179.65C798.96 179.7 799.03 179.75 799.11 179.81H799.12Z" fill="white"/>
                    <path d="M950.81 18.42C950.76 18.54 950.72 18.67 950.67 18.8C950.6 18.75 950.47 18.69 950.47 18.65C950.47 18.52 950.54 18.39 950.58 18.26C950.65 18.31 950.72 18.36 950.8 18.42H950.81Z" fill="white"/>
                    <path d="M969.81 101.44C969.75 101.52 969.7 101.59 969.65 101.66C969.57 101.55 969.47 101.44 969.42 101.32C969.4 101.28 969.51 101.18 969.56 101.11C969.64 101.22 969.73 101.33 969.81 101.43V101.44Z" fill="white"/>
                    <path d="M910.2 15.89C910.25 15.77 910.29 15.64 910.34 15.51C910.41 15.56 910.54 15.62 910.54 15.66C910.54 15.79 910.47 15.92 910.43 16.05C910.36 16 910.29 15.95 910.21 15.89H910.2Z" fill="white"/>
                    <path d="M885.3 149.66C885.25 149.78 885.21 149.91 885.16 150.04C885.09 149.99 884.96 149.93 884.96 149.89C884.96 149.76 885.03 149.63 885.07 149.5C885.14 149.55 885.21 149.6 885.29 149.66H885.3Z" fill="white"/>
                    <path d="M826.93 35.05C826.85 34.99 826.78 34.94 826.71 34.89C826.82 34.81 826.93 34.71 827.05 34.66C827.09 34.64 827.19 34.75 827.26 34.8C827.15 34.88 827.04 34.97 826.94 35.05H826.93Z" fill="white"/>
                    <path d="M941.85 117.73C941.93 117.84 942.03 117.95 942.08 118.07C942.1 118.11 941.99 118.21 941.94 118.28C941.86 118.17 941.76 118.06 941.71 117.94C941.69 117.9 941.8 117.8 941.85 117.73Z" fill="white"/>
                    <path d="M838.32 30.76C838.27 30.88 838.23 31.01 838.18 31.14C838.11 31.09 837.98 31.03 837.98 30.99C837.98 30.86 838.05 30.73 838.09 30.6L838.31 30.76H838.32Z" fill="white"/>
                    <path d="M822.73 138.47C822.85 138.52 822.98 138.56 823.11 138.61C823.06 138.68 823 138.81 822.96 138.81C822.83 138.81 822.7 138.74 822.57 138.7C822.62 138.63 822.67 138.56 822.73 138.48V138.47Z" fill="white"/>
                    <path d="M717.14 213.03C717.22 213.14 717.32 213.25 717.37 213.37C717.39 213.41 717.28 213.51 717.23 213.58C717.15 213.47 717.05 213.36 717 213.24C716.98 213.2 717.09 213.1 717.14 213.03Z" fill="white"/>
                    <path d="M1035.85 58.8C1037.16 60.34 1036.51 61.78 1035.87 63.12C1035.63 63.61 1035.16 64.32 1035.13 64.61C1034.95 66.51 1033 65.35 1032.4 66.35C1031.52 65.61 1030.23 65.02 1029.89 64.09C1029.57 63.2 1029.27 62.06 1029.39 61.01C1029.5 60.02 1029.21 58.58 1031.1 58.91C1031.38 58.96 1031.74 58.44 1032.09 58.23C1033.64 57.27 1034.91 57.5 1035.85 58.79V58.8Z" fill="white"/>
                    <path d="M872.68 46.83C873.66 46.14 874.9 46.42 875.14 46.77C876.06 48.11 877.65 47.78 878.78 48.54C879.58 49.08 879.98 49.54 879.77 50.45C879.43 51.95 878.94 52.46 877.81 52.45C877.32 52.45 876.86 52.49 876.37 52.55C874.28 52.82 871.21 49.85 872.17 47.63C872.34 47.24 872.47 46.9 872.66 46.83H872.68Z" fill="white"/>
                    <path d="M942.48 115.13C943.51 115.33 944.61 114.26 945.56 115.37C945.69 115.53 945.77 115.77 945.93 115.86C946.61 116.22 947.48 116.36 947.62 117.33C947.73 118.09 947.18 118.62 946.87 119.22C946.25 120.4 944.44 120.95 943.08 120.41C942.02 119.99 941.51 119.23 941.36 118.08C941.21 116.88 942.06 116.11 942.48 115.14V115.13Z" fill="white"/>
                    <path d="M877.41 245.01C875.95 246.06 874.9 246.01 874.02 245.05C873.53 244.52 873.18 243.87 872.72 243.31C872.29 242.78 872.4 242.44 872.95 242.09C873.56 241.7 874.08 241.15 874.72 240.84C875.81 240.31 876.92 240.51 877.95 241.09C878.16 241.21 878.39 241.59 878.35 241.8C878.21 242.6 878.35 243.37 878.37 244.14C878.37 244.52 877.52 244.92 877.41 245V245.01Z" fill="white"/>
                    <path d="M782.56 148.02C783.18 147.49 784.04 146.71 785.06 147.53C785.54 147.92 786.45 148.65 785.98 149.27C785.48 149.94 785.46 151.11 784.37 151.28C783.61 151.39 782.76 151.24 782.08 151.52C781.45 151.77 781.22 151.68 780.85 151.2C780.33 150.51 780.46 149.83 780.91 149.27C781.29 148.8 781.88 148.52 782.56 148.02Z" fill="white"/>
                    <path d="M889.1 264.17C889.75 263.67 890.69 263.78 891.21 264.4C891.38 264.61 891.72 267.53 891.59 267.74C891.31 268.22 889.42 268.33 889.45 268.24C889.9 267.02 888.35 267.38 888.27 266.65C888.2 265.95 888.68 264.49 889.1 264.17Z" fill="white"/>
                    <path d="M1009.33 124.74C1009.09 124.39 1008.61 123.92 1008.94 123.67C1009.55 123.22 1010.02 122.44 1011.02 122.59C1011.58 122.67 1011.84 123.17 1012.3 123.24C1013.04 123.36 1013.34 123.62 1013.26 124.43C1013.17 125.3 1012.62 125.83 1011.95 125.82C1011 125.8 1009.84 125.89 1009.34 124.73L1009.33 124.74Z" fill="white"/>
                    <path d="M904 102.47C903.45 102.82 902.97 103.59 902.28 102.56C902.13 102.33 901.5 102.43 901.1 102.34C900.65 102.24 900.2 102.1 899.75 101.98C900.27 101.52 900.73 100.78 901.31 100.66C902.06 100.51 902.91 100.87 903.73 100.96C904.18 101.01 904.58 101.14 904.68 101.57C904.79 102.05 904.31 102.23 904 102.47Z" fill="white"/>
                    <path d="M1009.9 229.87C1009.74 229.99 1009.58 230.13 1009.41 230.22C1009.01 230.43 1008.69 231.08 1008.15 230.61C1007.59 230.13 1007.4 229.49 1007.62 228.72C1007.81 228.07 1008.21 228.07 1008.75 228.11C1009.1 228.14 1009.48 227.86 1009.83 227.88C1010.3 227.9 1010.89 227.84 1011.13 228.41C1011.41 229.1 1010.71 229.19 1010.39 229.5C1010.25 229.64 1010.07 229.74 1009.9 229.86V229.87Z" fill="white"/>
                    <path d="M877.83 264C877.94 264.94 878.34 265.89 877.99 266.85C877.91 267.07 877.6 267.31 877.23 267.21C876.76 267.08 876.08 267.24 875.94 266.64C875.77 265.9 875.75 265.12 875.75 264.35C875.75 263.44 876.51 264.21 876.83 263.91C877.16 263.6 877.53 263.51 877.83 264Z" fill="white"/>
                    <path d="M942.91 70.23C942.51 70.63 942.13 70.68 941.75 70.11C941.17 69.22 941.27 68.4 942.01 67.8C942.67 67.27 943.35 67.42 943.9 67.82C944.53 68.28 944.03 69.45 942.91 70.22V70.23Z" fill="white"/>
                    <path d="M918.97 223.9C919.33 223.96 919.63 224.02 919.93 224.05C920.7 224.12 920.66 225.27 921.55 225.21C922.02 225.18 922.3 225.56 922.18 226.01C922.07 226.44 921.69 226.57 921.24 226.48C920.64 226.36 920.02 226.32 919.43 226.18C919.15 226.11 918.56 226.37 918.69 225.66C918.8 225.08 918.88 224.49 918.97 223.91V223.9Z" fill="white"/>
                    <path d="M790.97 224.06C790.67 223.48 791.13 222.38 791.81 222.07C792.08 221.95 793.87 222.62 794 222.96C794.32 223.82 793.46 223.92 793.05 224.21C792.28 224.76 791.27 224.62 790.97 224.06Z" fill="white"/>
                    <path d="M822.44 143.87C822.89 144.32 823.01 144.71 822.41 145.11C821.69 145.59 820.64 145.61 820.28 145.11C819.92 144.61 820.25 143.59 820.95 143.09C822 142.34 821.99 143.58 822.43 143.87H822.44Z" fill="white"/>
                    <path d="M821.77 136.34C822.34 136.29 822.95 136.21 823.37 136.71C823.82 137.25 823.26 138.55 822.49 138.89C821.98 139.12 821.77 138.77 821.57 138.43C821.1 137.64 821.2 136.65 821.77 136.34Z" fill="white"/>
                    <path d="M885.68 206.65C885.35 206.89 885.18 206.99 885.03 207.13C884.48 207.65 883.89 207.22 883.32 207.22C883.49 206.68 883.26 205.98 883.93 205.62C884.11 205.52 884.27 205.4 884.42 205.26C884.94 204.81 885.34 204.63 885.86 205.37C886.36 206.08 886.2 206.44 885.68 206.65Z" fill="white"/>
                    <path d="M796.43 52.34C796.9 52.9 796.59 53.3 796.22 53.38C795.47 53.54 794.68 53.56 793.91 53.58C793.19 53.59 793.57 53.07 793.57 52.75C793.57 51.38 794.81 52.16 795.39 51.75C796.06 51.28 796.13 52.19 796.43 52.34Z" fill="white"/>
                    <path d="M855.11 185.1C855.7 184.47 856.4 184.61 856.97 185C857.39 185.29 857.09 186.97 856.66 187.03C855.85 187.13 855.22 186.8 854.78 186.07C854.47 185.56 854.78 185.35 855.11 185.1Z" fill="white"/>
                    <path d="M1043.17 167.92C1043.44 168.47 1043.25 169.02 1042.98 169.54C1042.82 169.85 1041.19 169.33 1040.77 168.86C1040.39 168.43 1040.68 168.15 1041.02 167.91C1041.72 167.43 1042.78 167.42 1043.16 167.93L1043.17 167.92Z" fill="white"/>
                    <path d="M857.4 228.62C857.3 228.32 857.3 227.95 857.66 227.9C858.52 227.78 859.35 227.43 860.26 227.62C860.64 227.7 860.86 227.96 860.89 228.19C860.92 228.41 860.65 228.84 860.46 228.88C859.7 229.01 858.92 229.04 858.15 229.08C857.82 229.1 857.57 228.93 857.41 228.62H857.4Z" fill="white"/>
                    <path d="M840.77 257.9C841.02 257.69 841.32 257.41 841.73 257.78C842.08 258.1 841.9 258.5 841.91 258.88C841.92 259.66 841.28 259.89 840.8 260.14C840.51 260.29 839.76 260.17 839.69 259.99C839.54 259.61 839.6 259.05 839.78 258.68C839.94 258.36 840.39 258.19 840.78 257.91L840.77 257.9Z" fill="white"/>
                    <path d="M772.33 102.61C771.97 102.25 771.92 101.86 772.5 101.49C773.22 101.02 774.24 101.02 774.62 101.5C775.25 102.32 773.79 102.51 774.06 103.3C774.11 103.43 772.85 103.21 772.34 102.61H772.33Z" fill="white"/>
                    <path d="M942.66 191.22C942.85 191.22 943.1 191.16 943.21 191.25C943.83 191.77 943.75 193.56 943.09 193.97C942.86 194.11 942.49 194.25 942.31 194.15C942.12 194.05 941.98 193.66 941.98 193.41C941.99 192.27 942.25 191.54 942.66 191.23V191.22Z" fill="white"/>
                    <path d="M1002.56 92.27C1002.38 92.02 1002.15 91.8 1002.03 91.53C1001.72 90.83 1002.42 90.74 1002.75 90.43C1003.18 90.03 1003.46 90.33 1003.71 90.68C1003.93 91 1004.23 91.27 1004.12 91.74C1004.01 92.22 1004.05 92.82 1003.45 92.95C1002.98 93.06 1002.81 92.56 1002.57 92.26L1002.56 92.27Z" fill="white"/>
                    <path d="M858.84 225.48C858.26 225.75 858.05 225.37 857.85 225.03C857.38 224.23 857.48 223.25 858.07 222.95C858.56 222.7 858.79 223.09 858.98 223.43C859.45 224.23 859.34 225.19 858.83 225.47L858.84 225.48Z" fill="white"/>
                    <path d="M765.02 107.37C765.16 106.67 765.11 105.98 765.87 105.54C766.49 105.18 766.72 105.45 767.05 105.89C767.41 106.36 767.47 106.65 766.97 107.12C766.32 107.73 765.68 107.38 765.01 107.37H765.02Z" fill="white"/>
                    <path d="M774.81 95.2C775 95.2 775.27 95.1 775.36 95.19C775.64 95.47 776.03 95.82 776.05 96.15C776.08 96.7 776.17 97.38 775.54 97.74C775.05 98.01 774.82 97.63 774.62 97.29C774.15 96.47 774.24 95.56 774.81 95.2Z" fill="white"/>
                    <path d="M1036.2 117.56C1036.55 117.32 1036.93 117.07 1037.19 117.59C1037.53 118.26 1037.48 119 1036.81 119.38C1036.46 119.58 1035.87 119.35 1035.39 119.32C1035.52 118.67 1035.4 117.89 1036.2 117.57V117.56Z" fill="white"/>
                    <path d="M906.89 17.04C907.29 17.67 907.05 18.79 906.58 19.02C906.09 19.26 905.91 18.86 905.66 18.56C905.16 17.96 905.2 17.29 905.68 16.81C905.82 16.67 906.64 16.72 906.88 17.04H906.89Z" fill="white"/>
                    <path d="M835.58 173.96C836 173.66 836.45 173.36 836.64 173.88C836.87 174.53 836.85 175.26 836.94 175.95C836.23 175.89 835.61 175.79 835.19 175.04C834.8 174.34 835.34 174.19 835.58 173.95V173.96Z" fill="white"/>
                    <path d="M828.66 18.98C829.29 18.41 829.57 18.7 829.96 19.15C830.56 19.85 830.19 20.16 829.64 20.57C829.07 20.99 828.81 20.75 828.43 20.29C827.87 19.61 828.07 19.22 828.66 18.97V18.98Z" fill="white"/>
                    <path d="M836.38 187.16C836.62 187.65 837.47 188.12 836.54 188.61C836.03 188.88 835.36 188.8 834.92 188.32C834.2 187.53 835.3 187.09 835.51 186.6C835.77 185.98 836.02 187.03 836.38 187.16Z" fill="white"/>
                    <path d="M839.85 278.58C840.09 278.4 840.32 278.18 840.59 278.06C841.08 277.83 841.29 278.22 841.5 278.55C841.65 278.79 841.98 278.98 841.81 279.33C841.62 279.73 841.3 279.89 840.85 279.78C840.65 279.73 840.45 279.72 840.25 279.68C839.87 279.61 839.25 279.63 839.17 279.43C839 279 839.56 278.84 839.84 278.58H839.85Z" fill="white"/>
                    <path d="M965.92 17.82C965.68 17.12 966.33 17.01 966.67 16.73C967.32 16.22 967.51 16.9 967.79 17.24C968.06 17.56 968.27 17.92 967.76 18.23C967.24 18.54 966.17 18.29 965.91 17.82H965.92Z" fill="white"/>
                    <path d="M830.17 109.08C830.47 109.53 830.71 109.88 830.2 110.18C829.68 110.49 828.59 110.25 828.36 109.77C828.13 109.28 828.57 109.11 828.85 108.84C829.53 108.18 829.89 108.61 830.17 109.07V109.08Z" fill="white"/>
                    <path d="M833.63 228.81C833.56 228.61 833.3 228.26 833.39 228.1C833.63 227.66 834.1 227.96 834.36 228.08C834.75 228.25 835.73 227.67 835.47 228.82C835.39 229.2 835.4 229.64 834.97 229.8C834.08 230.13 834.16 229.12 833.63 228.8V228.81Z" fill="white"/>
                    <path d="M993.02 216.03C993.5 216.03 994.35 215.75 994.34 216.38C994.34 216.92 995.05 217.41 994.5 217.89C994.17 218.18 992.78 217.32 992.63 216.75C992.55 216.45 992.7 216.19 993.02 216.03Z" fill="white"/>
                    <path d="M841.71 33.18C841.71 33.18 841.91 32.66 842.15 32.23C842.26 32.04 843.59 32.51 843.71 32.78C844.02 33.48 843.33 33.57 843.01 33.87C842.62 34.24 842.31 34.04 841.71 33.18Z" fill="white"/>
                    <path d="M873.95 15.92C874.64 15.67 874.73 16.33 875.04 16.65C875.42 17.06 875.19 17.47 874.8 17.57C874.35 17.68 873.83 17.58 873.36 17.48C873.19 17.44 873.7 16.03 873.95 15.92Z" fill="white"/>
                    <path d="M1054.48 71.73C1054.88 72.1 1054.81 72.42 1054.57 72.59C1053.93 73.05 1053.29 72.89 1052.72 72.41C1052.43 72.16 1052.51 71.84 1052.75 71.65C1053.4 71.15 1054.02 71.39 1054.49 71.73H1054.48Z" fill="white"/>
                    <path d="M944.23 214.78C943.92 214.2 943.97 213.55 944.4 213.24C944.61 213.09 945.37 213.12 945.72 213.62C946.18 214.29 945.55 214.45 945.21 214.74C944.89 215.01 944.55 215.19 944.23 214.77V214.78Z" fill="white"/>
                    <path d="M979.44 2.60001C979.64 2.81001 979.67 4.27001 979.49 4.25001C979.02 4.20001 978.49 4.13001 978.1 3.88001C977.74 3.65001 977.72 3.20001 978.18 2.94001C978.56 2.73001 978.85 2.10001 979.43 2.60001H979.44Z" fill="white"/>
                    <path d="M940.42 219.83C940.97 219.65 941.63 219.61 942.1 220.29C942.27 220.54 942.25 220.87 941.89 221.02C941.2 221.31 940.54 221.26 940.07 220.63C939.89 220.39 939.92 220.06 940.42 219.83Z" fill="white"/>
                    <path d="M968.15 283.42C968.59 283.06 968.99 283.26 969.09 283.65C969.2 284.1 969.1 284.62 969 285.09C968.96 285.26 967.55 284.75 967.44 284.5C967.27 284.14 967.55 283.73 968.15 283.42Z" fill="white"/>
                    <path d="M839.16 182.89C839.51 182.64 839.86 182.39 840.17 182.89C840.5 183.43 840.42 184.08 840 184.39C839.79 184.54 839.03 184.51 838.68 184.01C838.21 183.34 838.88 183.19 839.16 182.9V182.89Z" fill="white"/>
                    <path d="M873.64 274.78C874.21 274.69 874.75 274.68 875.18 275.14C875.43 275.41 875.38 275.74 875.12 275.89C874.68 276.13 874.33 276.98 873.66 276.22C873.25 275.76 873.69 275.27 873.63 274.78H873.64Z" fill="white"/>
                    <path d="M853.34 184.85C853.53 184.85 853.71 184.85 853.9 184.85C853.78 185.73 853.51 186.62 853.9 187.51C853.92 187.55 853.6 187.79 853.42 187.85C853.31 187.88 853.03 187.67 853.04 187.63C853.25 186.83 852.52 186.07 852.95 185.29C853.04 185.12 853.21 185 853.34 184.86V184.85Z" fill="white"/>
                    <path d="M856.98 188.03C857.31 188.55 856.47 188.74 856.07 188.98C855.26 189.46 855.18 188.52 854.82 188.16C854.71 188.05 854.65 187.41 855.09 187.72C855.73 188.19 856.23 186.72 856.98 188.03Z" fill="white"/>
                    <path d="M1005.79 66.11C1005.26 65.58 1005.7 65 1005.7 64.44C1006.08 64.49 1006.66 64.4 1006.8 64.61C1007.12 65.07 1006.97 65.64 1006.56 66.09C1006.31 66.36 1006.05 66.28 1005.79 66.11Z" fill="white"/>
                    <path d="M840.78 40.34C840.98 40.73 841.56 41.07 840.9 41.58C840.43 41.95 840.17 41.65 839.94 41.3C839.69 40.92 839.15 40.56 839.8 40.06C840.28 39.69 840.51 40.01 840.78 40.34Z" fill="white"/>
                    <path d="M1053.22 133.72C1054.24 133.67 1054.76 134.07 1055.09 134.74C1055.17 134.89 1055.12 135.11 1055.13 135.3C1054.93 135.27 1054.73 135.2 1054.54 135.21C1053.79 135.27 1053.51 134.7 1053.21 134.21C1053.12 134.06 1053.22 133.78 1053.23 133.72H1053.22Z" fill="white"/>
                    <path d="M826.69 11.9C826.81 11.8 827.1 12.07 827.32 12.43C827.56 12.81 828.13 13.16 827.47 13.67C827.01 14.03 826.74 13.77 826.51 13.41C826.27 13.03 825.72 12.66 826.68 11.89L826.69 11.9Z" fill="white"/>
                    <path d="M835.91 77.06C835.92 77.2 836 77.44 835.93 77.5C835.41 77.96 834.89 78.4 834.08 78.12C833.75 78.01 833.57 77.9 833.75 77.59C834.02 77.11 835.52 76.76 835.91 77.06Z" fill="white"/>
                    <path d="M954.7 57.02C955.62 56.66 955.57 57.64 955.95 58C956.12 58.16 955.85 58.57 955.56 58.68C954.69 59 954.64 58.11 954.3 57.71C954.14 57.53 954.39 57.16 954.7 57.02Z" fill="white"/>
                    <path d="M867.84 140.43C868.76 140.07 868.71 141.05 869.09 141.41C869.26 141.57 868.99 141.98 868.7 142.09C867.83 142.41 867.78 141.52 867.44 141.12C867.28 140.94 867.53 140.57 867.84 140.43Z" fill="white"/>
                    <path d="M997.81 189.41C997.93 189.31 998.22 189.58 998.44 189.94C998.68 190.32 999.25 190.67 998.59 191.18C998.13 191.54 997.86 191.28 997.63 190.92C997.39 190.54 996.84 190.17 997.8 189.4L997.81 189.41Z" fill="white"/>
                    <path d="M795.31 178.21C795.03 178.79 794.75 179.4 794.45 180C794.44 180.03 794.23 180 794.17 179.95C793.85 179.66 793.78 179.31 793.87 178.88C793.97 178.39 794 177.88 794.64 177.82C794.79 177.81 794.95 178 795.31 178.21Z" fill="white"/>
                    <path d="M842.89 209.79C842.62 209.48 842.32 209.15 842.73 208.76C843.26 208.26 843.59 208.59 843.9 209.06C844.12 209.39 844.46 209.7 844.04 210.09C843.51 210.58 843.15 210.29 842.88 209.78L842.89 209.79Z" fill="white"/>
                    <path d="M901.84 231.7C902.38 231.51 902.67 231.48 902.82 231.83C903.11 232.52 903.06 233.17 902.43 233.65C902.31 233.74 902.06 233.68 901.88 233.69C901.87 233 901.86 232.31 901.84 231.7Z" fill="white"/>
                    <path d="M838.67 125.58C839.07 125.2 839.48 125.04 839.62 125.26C839.89 125.7 840.73 126.02 840.16 126.76C839.97 127.01 839.55 127.15 839.38 126.93C839.06 126.51 838.42 126.14 838.68 125.59L838.67 125.58Z" fill="white"/>
                    <path d="M1007.77 171.39C1008.12 171.15 1008.45 170.92 1008.79 171.42C1009.26 172.1 1008.63 172.27 1008.28 172.56C1007.96 172.82 1007.62 173.03 1007.28 172.53C1006.81 171.85 1007.46 171.7 1007.77 171.39Z" fill="white"/>
                    <path d="M952.51 95.36C952.15 94.44 953.13 94.49 953.49 94.11C953.65 93.94 954.06 94.21 954.17 94.5C954.49 95.37 953.6 95.42 953.2 95.76C953.02 95.92 952.65 95.67 952.51 95.36Z" fill="white"/>
                    <path d="M894.21 240.05C893.94 239.74 893.64 239.41 894.05 239.02C894.58 238.52 894.91 238.85 895.22 239.32C895.44 239.65 895.78 239.96 895.36 240.35C894.83 240.84 894.47 240.55 894.2 240.04L894.21 240.05Z" fill="white"/>
                    <path d="M780.68 109.76C780.21 109.23 780.28 108.96 780.52 108.74C781.07 108.25 781.39 108.6 781.67 109.07C781.81 109.31 782.2 109.74 781.97 109.8C781.49 109.93 780.92 109.77 780.68 109.76Z" fill="white"/>
                    <path d="M1028.19 138C1028.35 137.97 1028.63 137.92 1028.9 137.87C1028.79 138.42 1028.98 139.09 1028.35 139.44C1028.08 139.59 1027.82 139.56 1027.6 139.24C1027.14 138.57 1027.76 138.41 1028.18 138.01L1028.19 138Z" fill="white"/>
                    <path d="M868.37 296.35C868.57 296.54 868.51 296.84 868.29 297.05C867.99 297.33 867.6 297.55 867.32 297.03C867.13 296.67 866.4 296.34 866.96 295.9C867.16 295.74 867.94 295.8 868.36 296.35H868.37Z" fill="white"/>
                    <path d="M750.8 145.04C751.33 144.54 751.91 144.97 752.47 144.99C752.32 145.22 752.22 145.52 752.01 145.68C751.66 145.94 751.3 146.48 750.78 145.84C750.55 145.55 750.6 145.32 750.8 145.04Z" fill="white"/>
                    <path d="M894.39 273.02C894.71 273.3 894.46 272.93 894.33 273.31C894.26 273.52 893.89 273.78 893.69 273.75C893.14 273.68 892.45 273.82 892.18 273.1C892.15 273.02 892.18 272.84 892.22 272.82C892.98 272.54 893.67 273 894.4 273.01L894.39 273.02Z" fill="white"/>
                    <path d="M909.5 217.09C910.06 217.22 910.78 217.07 911 217.8C911.05 217.97 910.78 218.37 910.57 218.47C909.9 218.79 909.8 218.08 909.49 217.76C909.28 217.54 909.2 217.31 909.51 217.09H909.5Z" fill="white"/>
                    <path d="M907.53 117.6C907.69 117.48 907.84 117.31 908.03 117.26C908.15 117.23 908.43 117.34 908.45 117.43C908.53 117.76 907 118.9 906.66 118.77C906.58 118.74 906.51 118.39 906.57 118.34C906.87 118.07 907.21 117.85 907.54 117.61L907.53 117.6Z" fill="white"/>
                    <path d="M1003.02 256.43C1003.51 256.02 1003.81 256.27 1004.02 256.64C1004.17 256.91 1004.14 257.17 1003.82 257.39C1003.35 257.72 1003.07 257.5 1002.86 257.13C1002.71 256.87 1002.67 256.55 1003.01 256.43H1003.02Z" fill="white"/>
                    <path d="M760.45 127.22C760.77 127.11 761 127.11 761.22 127.42C761.55 127.89 761.33 128.16 760.96 128.38C760.69 128.54 760.43 128.49 760.21 128.18C759.87 127.71 760.13 127.47 760.45 127.22Z" fill="white"/>
                    <path d="M962.11 20.81C962.43 20.7 962.66 20.7 962.88 21.01C963.21 21.48 962.99 21.75 962.62 21.97C962.35 22.13 962.09 22.08 961.87 21.77C961.53 21.3 961.79 21.06 962.11 20.81Z" fill="white"/>
                    <path d="M1041.25 108.36C1041.47 108.68 1041.64 108.99 1041.17 109.33C1040.86 109.56 1040.6 109.52 1040.39 109.29C1040.1 108.97 1039.97 108.64 1040.43 108.3C1040.74 108.07 1040.96 108.13 1041.24 108.36H1041.25Z" fill="white"/>
                    <path d="M816.84 32.71C816.72 32.15 816.74 31.62 817.18 31.2C817.3 31.09 817.68 31.04 817.68 31.06C817.87 31.61 817.84 32.14 817.39 32.55C817.27 32.66 817.04 32.65 816.84 32.71Z" fill="white"/>
                    <path d="M924.44 5.22C924.69 4.95 924.98 5.04 925.19 5.27C925.48 5.58 925.61 5.92 925.15 6.26C924.84 6.49 924.59 6.45 924.37 6.22C924.08 5.9 923.94 5.54 924.44 5.22Z" fill="white"/>
                    <path d="M842.88 97.83C843.2 97.72 843.43 97.72 843.65 98.03C843.98 98.5 843.76 98.77 843.39 98.99C843.12 99.15 842.86 99.1 842.64 98.79C842.3 98.32 842.56 98.08 842.88 97.83Z" fill="white"/>
                    <path d="M941.08 286.23C941.45 286.1 941.68 286.1 941.91 286.41C942.24 286.88 942.02 287.15 941.65 287.37C941.38 287.53 941.12 287.48 940.9 287.17C940.55 286.7 940.85 286.46 941.09 286.23H941.08Z" fill="white"/>
                    <path d="M922.34 269.11C922.72 269.57 922.47 269.87 922.1 270.08C921.83 270.23 921.57 270.2 921.35 269.88C921.02 269.41 921.24 269.13 921.61 268.92C921.88 268.77 922.18 268.77 922.34 269.1V269.11Z" fill="white"/>
                    <path d="M874.24 21.08C874.57 20.85 874.92 20.62 875.21 21.12C875.3 21.27 875.32 21.47 875.37 21.65C874.93 21.64 874.48 21.63 874.04 21.62C874.1 21.44 874.17 21.27 874.24 21.09V21.08Z" fill="white"/>
                    <path d="M783.51 132.06C783.93 132.12 784.32 132.17 784.71 132.22C784.56 132.45 784.47 132.77 784.26 132.9C783.8 133.19 783.55 132.8 783.37 132.45C783.32 132.36 783.47 132.16 783.51 132.05V132.06Z" fill="white"/>
                    <path d="M957.7 102.45C957.76 102.68 957.9 102.97 957.85 103.02C957.42 103.36 956.98 102.98 956.54 102.99C956.61 102.82 956.63 102.59 956.75 102.48C957.06 102.19 957.41 102.07 957.7 102.46V102.45Z" fill="white"/>
                    <path d="M1049.88 69.19C1050.03 69.19 1050.26 69.11 1050.34 69.19C1050.62 69.47 1050.91 69.82 1050.48 70.17C1050.29 70.33 1049.96 70.32 1049.69 70.39C1049.75 70 1049.81 69.6 1049.88 69.19Z" fill="white"/>
                    <path d="M1019.06 68.48C1018.71 68.04 1018.99 67.76 1019.35 67.55C1019.49 67.47 1019.72 67.52 1019.9 67.51C1019.77 67.94 1019.65 68.36 1019.52 68.79C1019.37 68.69 1019.21 68.58 1019.05 68.48H1019.06Z" fill="white"/>
                    <path d="M808.27 127.1C808.35 127.1 808.58 127.01 808.65 127.08C808.93 127.35 809.22 127.71 808.82 128.06C808.63 128.22 808.3 128.22 808.03 128.29C808.11 127.9 808.18 127.52 808.27 127.1Z" fill="white"/>
                    <path d="M841.72 104.84C842.16 104.49 842.44 104.77 842.65 105.13C842.73 105.27 842.68 105.5 842.69 105.68C842.26 105.55 841.84 105.43 841.41 105.3C841.51 105.15 841.62 104.99 841.72 104.83V104.84Z" fill="white"/>
                    <path d="M998.89 21.67C999.08 21.65 999.27 21.64 999.45 21.62C999.35 22.05 999.24 22.48 999.14 22.91C998.98 22.81 998.8 22.75 998.67 22.62C998.29 22.21 998.54 21.93 998.88 21.68L998.89 21.67Z" fill="white"/>
                    <path d="M933.37 252.6C933.02 252.16 933.3 251.88 933.66 251.67C933.8 251.59 934.03 251.64 934.21 251.63C934.08 252.06 933.96 252.48 933.83 252.91C933.68 252.81 933.52 252.7 933.36 252.6H933.37Z" fill="white"/>
                    <path d="M960.48 285.58C960.58 285.34 960.61 285.12 960.73 285.01C961.05 284.72 961.42 284.6 961.69 285.08C961.78 285.23 961.78 285.43 961.83 285.61C961.39 285.61 960.95 285.59 960.48 285.58Z" fill="white"/>
                    <path d="M752.6 187.71C753.02 187.66 753.11 188.01 753.25 188.25C753.3 188.32 753.16 188.63 753.07 188.65C752.69 188.73 752.59 188.36 752.44 188.13C752.39 188.05 752.54 187.85 752.59 187.72L752.6 187.71Z" fill="white"/>
                    <path d="M762.96 162.65C763.17 162.38 763.3 162.12 763.52 161.95C763.64 161.86 763.88 161.92 764.07 161.91C763.92 162.15 763.81 162.42 763.62 162.6C763.51 162.7 763.26 162.63 762.97 162.65H762.96Z" fill="white"/>
                    <path d="M1033.61 202.67C1034.03 202.62 1034.12 202.97 1034.26 203.21C1034.31 203.28 1034.17 203.59 1034.08 203.61C1033.7 203.69 1033.6 203.32 1033.45 203.09C1033.4 203.01 1033.55 202.81 1033.6 202.68L1033.61 202.67Z" fill="white"/>
                    <path d="M1041.7 87.28C1041.97 87.49 1042.23 87.62 1042.4 87.84C1042.49 87.96 1042.43 88.2 1042.44 88.39C1042.2 88.24 1041.93 88.13 1041.75 87.94C1041.65 87.83 1041.72 87.58 1041.7 87.29V87.28Z" fill="white"/>
                    <path d="M1039.41 84.84C1039.5 84.98 1039.68 85.2 1039.77 85.45C1039.87 85.75 1039.69 85.88 1039.45 85.68C1039.24 85.52 1039.07 85.27 1038.97 85.02C1038.86 84.73 1039.02 84.63 1039.41 84.83V84.84Z" fill="white"/>
                    <path d="M1052.06 199.08C1052.02 198.9 1051.88 198.63 1051.95 198.56C1052.21 198.29 1052.44 198.47 1052.57 198.74C1052.65 198.9 1052.76 199.19 1052.69 199.26C1052.43 199.52 1052.22 199.35 1052.06 199.09V199.08Z" fill="white"/>
                    <path d="M767.44 127.51C767.62 127.47 767.89 127.33 767.96 127.4C768.23 127.66 768.05 127.89 767.78 128.02C767.62 128.1 767.33 128.21 767.26 128.14C767 127.88 767.17 127.67 767.43 127.51H767.44Z" fill="white"/>
                    <path d="M987.91 23.85C988.12 23.58 988.25 23.32 988.47 23.15C988.59 23.06 988.83 23.12 989.02 23.11C988.87 23.35 988.76 23.62 988.57 23.8C988.46 23.9 988.21 23.83 987.92 23.85H987.91Z" fill="white"/>
                    <path d="M861.8 264.9C861.94 264.9 862.12 264.88 862.31 264.88C862.16 265.12 862.05 265.39 861.86 265.57C861.75 265.67 861.5 265.6 861.31 265.61C861.32 265.29 861.46 265.05 861.8 264.9Z" fill="white"/>
                    <path d="M896.49 241.07C896.69 241.3 896.79 241.56 896.46 241.72C896.37 241.76 896.13 241.57 896 241.45C895.78 241.24 895.69 240.97 896.02 240.8C896.11 240.76 896.33 240.97 896.48 241.07H896.49Z" fill="white"/>
                    <path d="M898.52 228.04C898.72 228.27 898.82 228.53 898.49 228.69C898.4 228.73 898.16 228.54 898.03 228.42C897.81 228.21 897.72 227.94 898.05 227.77C898.14 227.73 898.36 227.94 898.51 228.04H898.52Z" fill="white"/>
                    <path d="M899.12 179.46C899.33 179.19 899.46 178.93 899.68 178.76C899.8 178.67 900.04 178.73 900.23 178.72C900.08 178.96 899.97 179.23 899.78 179.41C899.67 179.51 899.42 179.44 899.13 179.46H899.12Z" fill="white"/>
                    <path d="M797.78 129.16C798.01 129.12 798.28 129.08 798.56 129.04C798.49 129.22 798.48 129.48 798.35 129.55C798.12 129.68 797.82 129.7 797.55 129.77C797.62 129.59 797.68 129.42 797.78 129.16Z" fill="white"/>
                    <path d="M890.25 94.08C890.39 94.08 890.57 94.06 890.76 94.06C890.61 94.3 890.5 94.57 890.31 94.75C890.2 94.85 889.95 94.78 889.76 94.79C889.77 94.47 889.91 94.23 890.25 94.08Z" fill="white"/>
                    <path d="M868.46 51.01C868.67 50.74 868.8 50.48 869.02 50.31C869.14 50.22 869.38 50.28 869.57 50.27C869.42 50.51 869.31 50.78 869.12 50.96C869.01 51.06 868.76 50.99 868.47 51.01H868.46Z" fill="white"/>
                    <path d="M893.95 15.15C894.15 15.38 894.25 15.64 893.92 15.8C893.83 15.84 893.59 15.65 893.46 15.53C893.24 15.32 893.15 15.05 893.48 14.88C893.57 14.84 893.79 15.05 893.94 15.15H893.95Z" fill="white"/>
                    <path d="M1015.04 196.86C1015 196.68 1014.86 196.41 1014.93 196.34C1015.19 196.07 1015.42 196.25 1015.55 196.52C1015.63 196.68 1015.74 196.97 1015.67 197.04C1015.41 197.3 1015.2 197.13 1015.04 196.87V196.86Z" fill="white"/>
                    <path d="M943.01 125.56C943.19 125.52 943.46 125.38 943.53 125.45C943.8 125.71 943.62 125.94 943.35 126.07C943.19 126.15 942.9 126.26 942.83 126.19C942.57 125.93 942.74 125.72 943 125.56H943.01Z" fill="white"/>
                    <path d="M955.5 115.29C955.57 115.15 955.62 115 955.72 114.89C955.75 114.85 955.9 114.91 956 114.93C955.93 115.07 955.88 115.22 955.78 115.33C955.75 115.37 955.6 115.31 955.5 115.29Z" fill="white"/>
                    <path d="M1005.32 12.75C1005.39 12.61 1005.44 12.46 1005.54 12.35C1005.57 12.31 1005.72 12.37 1005.82 12.39C1005.75 12.53 1005.7 12.68 1005.6 12.79C1005.57 12.83 1005.42 12.77 1005.32 12.75Z" fill="white"/>
                    <path d="M893.94 216.12C894.09 216.1 894.24 216.08 894.39 216.06C894.37 216.16 894.39 216.32 894.34 216.34C894.2 216.4 894.04 216.4 893.89 216.43C893.91 216.33 893.92 216.24 893.94 216.12Z" fill="white"/>
                    <path d="M909.77 262.06C909.65 262.04 909.56 262.03 909.46 262.01C909.53 261.87 909.58 261.72 909.68 261.61C909.71 261.57 909.86 261.63 909.96 261.65C909.89 261.79 909.83 261.93 909.77 262.06Z" fill="white"/>
                    <path d="M855.2 189.93C855.22 190.08 855.24 190.23 855.26 190.38C855.16 190.36 855 190.38 854.98 190.33C854.92 190.19 854.92 190.03 854.89 189.88C854.99 189.9 855.08 189.91 855.2 189.93Z" fill="white"/>
                    <path d="M864.09 142.38C864.23 142.45 864.38 142.5 864.49 142.6C864.53 142.63 864.47 142.78 864.45 142.88C864.31 142.81 864.16 142.76 864.05 142.66C864.01 142.63 864.07 142.48 864.09 142.38Z" fill="white"/>
                    <path d="M838.47 98.48C838.45 98.6 838.44 98.69 838.42 98.79C838.28 98.72 838.13 98.67 838.02 98.57C837.98 98.54 838.04 98.39 838.06 98.29C838.2 98.36 838.34 98.42 838.47 98.48Z" fill="white"/>
                    <path d="M778.62 117.16C778.77 117.14 778.92 117.12 779.07 117.1C779.05 117.2 779.07 117.36 779.02 117.38C778.88 117.44 778.72 117.44 778.57 117.47C778.59 117.37 778.6 117.28 778.62 117.16Z" fill="white"/>
                    <path d="M865.6 207.57C865.72 207.59 865.81 207.6 865.91 207.62C865.84 207.76 865.79 207.91 865.69 208.02C865.66 208.06 865.51 208 865.41 207.98C865.48 207.84 865.54 207.7 865.6 207.57Z" fill="white"/>
                    <path d="M896.12 135.82C896 135.8 895.91 135.79 895.81 135.77C895.88 135.63 895.93 135.48 896.03 135.37C896.06 135.33 896.21 135.39 896.31 135.41C896.24 135.55 896.18 135.69 896.12 135.82Z" fill="white"/>
                    <path d="M817.55 7.37998C817.57 7.27998 817.57 7.10998 817.6 7.10998C817.75 7.08998 817.94 7.05998 818.06 7.11998C818.32 7.24998 818.29 7.42998 818 7.48998C817.86 7.51998 817.7 7.41998 817.55 7.37998Z" fill="white"/>
                    <path d="M818.45 7.17999C818.6 7.21999 818.85 7.22999 818.88 7.30999C818.96 7.54999 818.83 7.68999 818.65 7.61999C818.5 7.55999 818.36 7.42999 818.22 7.33999C818.29 7.28999 818.37 7.22999 818.44 7.17999H818.45Z" fill="white"/>
                    <path d="M984.09 128.78C984.24 128.76 984.39 128.74 984.54 128.72C984.52 128.82 984.54 128.98 984.49 129C984.35 129.06 984.19 129.06 984.04 129.09C984.06 128.99 984.07 128.9 984.09 128.78Z" fill="white"/>
                    <path d="M745.16 60.78C745.31 60.76 745.46 60.74 745.61 60.72C745.59 60.82 745.61 60.98 745.56 61C745.42 61.06 745.26 61.06 745.11 61.09C745.13 60.99 745.14 60.9 745.16 60.78Z" fill="white"/>
                    <path d="M763.55 102.54C763.4 102.56 763.25 102.58 763.1 102.6C763.12 102.5 763.1 102.34 763.15 102.32C763.29 102.26 763.45 102.26 763.6 102.23C763.58 102.33 763.57 102.42 763.55 102.54Z" fill="white"/>
                    <path d="M909.66 58.44C909.81 58.42 909.96 58.4 910.11 58.38C910.09 58.48 910.11 58.64 910.06 58.66C909.92 58.72 909.76 58.72 909.61 58.75C909.63 58.65 909.64 58.56 909.66 58.44Z" fill="white"/>
                    <path d="M825.53 175.64C825.51 175.76 825.5 175.85 825.48 175.95C825.34 175.88 825.19 175.83 825.08 175.73C825.04 175.7 825.1 175.55 825.12 175.45C825.26 175.52 825.4 175.58 825.53 175.64Z" fill="white"/>
                    <path d="M848.72 18.55C848.79 18.41 848.84 18.26 848.94 18.15C848.97 18.11 849.12 18.17 849.22 18.19C849.15 18.33 849.1 18.48 849 18.59C848.97 18.63 848.82 18.57 848.72 18.55Z" fill="white"/>
                    <path d="M815.39 166.49C815.54 166.47 815.69 166.45 815.84 166.43C815.82 166.53 815.84 166.69 815.79 166.71C815.65 166.77 815.49 166.77 815.34 166.8L815.39 166.49Z" fill="white"/>
                    <path d="M930.74 126.55C930.72 126.4 930.7 126.25 930.68 126.1C930.78 126.12 930.94 126.1 930.96 126.15C931.02 126.29 931.02 126.45 931.05 126.6C930.95 126.58 930.86 126.57 930.74 126.55Z" fill="white"/>
                    <path d="M1059.41 193.34C1059.48 193.2 1059.53 193.05 1059.63 192.94C1059.66 192.9 1059.81 192.96 1059.91 192.98C1059.84 193.12 1059.79 193.27 1059.69 193.38C1059.66 193.42 1059.51 193.36 1059.41 193.34Z" fill="white"/>
                    <path d="M1026.52 299.3C1024.7 299.53 1023.93 298.33 1023.23 297.17C1022.97 296.75 1022.67 296.04 1022.46 295.87C1021.1 294.79 1022.95 293.89 1022.49 292.93C1023.49 292.63 1024.58 291.94 1025.45 292.15C1026.28 292.35 1027.3 292.69 1028.03 293.3C1028.73 293.88 1029.96 294.37 1028.77 295.64C1028.59 295.83 1028.81 296.36 1028.8 296.73C1028.76 298.38 1027.95 299.23 1026.51 299.3H1026.52Z" fill="white"/>
                    <path d="M1116.4 181.31C1116.44 182.39 1115.61 183.2 1115.23 183.21C1113.76 183.25 1113.22 184.62 1112.08 185.1C1111.27 185.44 1110.73 185.52 1110.14 184.91C1109.17 183.91 1109.02 183.28 1109.59 182.44C1109.84 182.07 1110.03 181.7 1110.22 181.3C1111.05 179.58 1114.82 178.72 1116.04 180.55C1116.26 180.87 1116.44 181.14 1116.4 181.32V181.31Z" fill="white"/>
                    <path d="M1029.96 200.5C1029.29 201.18 1029.57 202.55 1028.25 202.72C1028.06 202.74 1027.84 202.68 1027.7 202.76C1027.09 203.1 1026.55 203.69 1025.75 203.31C1025.12 203.02 1024.99 202.34 1024.69 201.81C1024.1 200.76 1024.58 199.11 1025.66 198.34C1026.5 197.74 1027.33 197.73 1028.28 198.19C1029.27 198.67 1029.43 199.69 1029.96 200.5Z" fill="white"/>
                    <path d="M963.55 86.77C963.48 85.14 964.04 84.37 965.2 84.18C965.84 84.07 966.51 84.13 967.16 84.06C967.78 83.99 967.98 84.25 967.97 84.83C967.97 85.48 968.12 86.15 968.04 86.79C967.9 87.88 967.2 88.63 966.25 89.12C966.06 89.22 965.66 89.2 965.51 89.07C964.97 88.57 964.32 88.3 963.72 87.93C963.43 87.75 963.55 86.9 963.54 86.78L963.55 86.77Z" fill="white"/>
                    <path d="M1084.17 62.77C1084.27 63.5 1084.43 64.54 1083.3 64.91C1082.77 65.08 1081.76 65.41 1081.52 64.75C1081.26 64.03 1080.37 63.44 1080.8 62.53C1081.09 61.9 1081.62 61.33 1081.75 60.67C1081.87 60.07 1082.05 59.93 1082.6 59.9C1083.38 59.85 1083.84 60.28 1084.04 60.9C1084.21 61.42 1084.13 62.01 1084.17 62.77Z" fill="white"/>
                    <path d="M943.21 86.17C943.26 86.91 942.72 87.57 941.99 87.65C941.75 87.68 939.36 86.49 939.27 86.29C939.04 85.84 939.9 84.35 939.95 84.42C940.65 85.37 941.15 84.01 941.74 84.31C942.31 84.6 943.18 85.69 943.21 86.16V86.17Z" fill="white"/>
                    <path d="M989.57 246.52C989.96 246.52 990.55 246.38 990.58 246.75C990.62 247.44 990.98 248.18 990.37 248.87C990.03 249.25 989.52 249.21 989.24 249.52C988.78 250.02 988.44 250.12 987.86 249.66C987.24 249.16 987.11 248.48 987.45 247.97C987.94 247.25 988.44 246.33 989.57 246.53V246.52Z" fill="white"/>
                    <path d="M1058.63 177.55C1058.63 176.96 1058.29 176.21 1059.42 176.2C1059.67 176.2 1059.91 175.67 1060.17 175.41C1060.47 175.11 1060.8 174.84 1061.11 174.56C1061.2 175.18 1061.53 175.9 1061.34 176.4C1061.08 177.05 1060.38 177.51 1059.91 178.09C1059.65 178.41 1059.35 178.65 1058.98 178.51C1058.57 178.36 1058.66 177.9 1058.64 177.55H1058.63Z" fill="white"/>
                    <path d="M909.45 194.89C909.45 194.71 909.41 194.52 909.43 194.34C909.47 193.93 909.13 193.37 909.76 193.19C910.4 193 910.98 193.17 911.46 193.72C911.86 194.19 911.66 194.49 911.36 194.88C911.17 195.14 911.19 195.56 911 195.82C910.75 196.16 910.51 196.65 909.95 196.54C909.29 196.41 909.57 195.84 909.49 195.44C909.45 195.26 909.47 195.07 909.46 194.89H909.45Z" fill="white"/>
                    <path d="M948.92 77.69C948.15 77.31 947.23 77.14 946.67 76.4C946.54 76.23 946.51 75.88 946.77 75.65C947.1 75.36 947.32 74.76 947.84 74.95C948.49 75.18 949.09 75.56 949.67 75.94C950.36 76.39 949.4 76.59 949.47 76.98C949.54 77.38 949.43 77.7 948.91 77.7L948.92 77.69Z" fill="white"/>
                    <path d="M1063.85 223.06C1063.74 222.56 1063.89 222.24 1064.52 222.24C1065.49 222.24 1066.06 222.72 1066.14 223.58C1066.21 224.34 1065.76 224.79 1065.18 225.01C1064.51 225.26 1063.88 224.31 1063.84 223.07L1063.85 223.06Z" fill="white"/>
                    <path d="M1136.11 266.47C1136.1 266.19 1136.03 265.9 1136.08 265.63C1136.18 265 1136.93 264.76 1137.4 265.18C1137.68 265.43 1138.01 265.64 1138.22 265.94C1138.48 266.33 1138.54 266.88 1138.29 267.22C1138 267.61 1137.3 267.53 1137.01 268.04C1136.67 267.64 1136.01 267.46 1136.12 266.74C1136.13 266.65 1136.12 266.55 1136.11 266.46V266.47Z" fill="white"/>
                    <path d="M959 128.79C958.78 129.04 958.58 129.23 958.41 129.45C957.98 130 957.13 129.4 956.72 130.1C956.51 130.47 956.08 130.49 955.8 130.18C955.53 129.88 955.62 129.53 955.91 129.23C956.29 128.83 956.63 128.38 957.04 128.01C957.23 127.83 957.33 127.25 957.8 127.71C958.19 128.08 958.6 128.43 958.99 128.79H959Z" fill="white"/>
                    <path d="M1022.26 31.5C1022.85 31.56 1023.45 32.45 1023.35 33.12C1023.31 33.39 1021.91 34.41 1021.59 34.34C1020.78 34.16 1021.13 33.46 1021.11 33C1021.07 32.15 1021.68 31.44 1022.25 31.5H1022.26Z" fill="white"/>
                    <path d="M1067.57 95.11C1067 95.23 1066.65 95.12 1066.64 94.48C1066.64 93.69 1067.14 92.89 1067.7 92.86C1068.26 92.83 1068.87 93.59 1068.9 94.37C1068.95 95.54 1068.01 94.92 1067.57 95.11Z" fill="white"/>
                    <path d="M1073.63 98.33C1073.39 98.78 1073.14 99.29 1072.56 99.36C1071.92 99.44 1071.22 98.37 1071.34 97.61C1071.42 97.11 1071.79 97.13 1072.15 97.14C1072.98 97.17 1073.69 97.74 1073.64 98.33H1073.63Z" fill="white"/>
                    <path d="M988.58 112.05C988.56 111.68 988.58 111.5 988.54 111.32C988.42 110.65 989.04 110.41 989.32 109.98C989.65 110.38 990.29 110.55 990.24 111.23C990.23 111.41 990.24 111.6 990.27 111.78C990.35 112.4 990.29 112.79 989.48 112.82C988.69 112.85 988.5 112.55 988.59 112.05H988.58Z" fill="white"/>
                    <path d="M1149.97 120.68C1149.32 120.76 1149.16 120.33 1149.29 120C1149.54 119.35 1149.91 118.74 1150.29 118.15C1150.64 117.6 1150.85 118.15 1151.09 118.31C1152.13 119 1150.93 119.55 1150.95 120.19C1150.97 120.93 1150.25 120.54 1149.99 120.68H1149.97Z" fill="white"/>
                    <path d="M1020.09 99.51C1020.28 100.27 1019.82 100.73 1019.24 100.97C1018.81 101.15 1017.69 100.08 1017.85 99.73C1018.17 99.06 1018.74 98.76 1019.51 98.78C1020.05 98.8 1020.06 99.14 1020.08 99.51H1020.09Z" fill="white"/>
                    <path d="M940.02 250.83C939.47 250.76 939.14 250.35 938.89 249.89C938.74 249.62 939.93 248.63 940.5 248.55C941.02 248.48 941.09 248.83 941.1 249.21C941.12 249.98 940.6 250.79 940.03 250.83H940.02Z" fill="white"/>
                    <path d="M985.9 79.69C986.18 79.76 986.46 79.94 986.32 80.24C985.99 80.95 985.84 81.76 985.25 82.35C985 82.6 984.69 82.64 984.5 82.54C984.32 82.45 984.12 82.03 984.19 81.87C984.46 81.23 984.83 80.62 985.18 80.01C985.33 79.75 985.58 79.65 985.89 79.68L985.9 79.69Z" fill="white"/>
                    <path d="M971.9 52.57C971.94 52.86 972 53.23 971.52 53.36C971.1 53.47 970.89 53.13 970.59 52.95C969.99 52.57 970.14 51.97 970.18 51.48C970.21 51.19 970.67 50.68 970.85 50.71C971.21 50.78 971.61 51.1 971.8 51.43C971.97 51.71 971.87 52.14 971.89 52.57H971.9Z" fill="white"/>
                    <path d="M1123.72 77.48C1124.17 77.38 1124.49 77.54 1124.49 78.16C1124.49 78.94 1123.99 79.72 1123.43 79.76C1122.49 79.83 1123.07 78.63 1122.34 78.45C1122.22 78.42 1123.01 77.58 1123.72 77.48Z" fill="white"/>
                    <path d="M972.09 162.96C971.99 163.1 971.92 163.32 971.8 163.36C971.1 163.57 969.78 162.63 969.79 161.93C969.79 161.69 969.88 161.34 970.04 161.25C970.21 161.15 970.57 161.25 970.77 161.37C971.63 161.94 972.06 162.5 972.09 162.96Z" fill="white"/>
                    <path d="M1017.58 257.45C1017.86 257.43 1018.14 257.37 1018.4 257.41C1019.09 257.52 1018.81 258.1 1018.88 258.5C1018.97 259.02 1018.6 259.09 1018.22 259.1C1017.87 259.11 1017.52 259.2 1017.21 258.88C1016.9 258.56 1016.43 258.3 1016.62 257.77C1016.77 257.36 1017.23 257.47 1017.58 257.44V257.45Z" fill="white"/>
                    <path d="M987.57 82.35C987.65 81.77 988.04 81.8 988.4 81.82C989.24 81.86 989.93 82.42 989.87 83.01C989.82 83.5 989.41 83.48 989.05 83.47C988.21 83.43 987.54 82.87 987.57 82.35Z" fill="white"/>
                    <path d="M1123.73 69.57C1124.2 70.02 1124.74 70.33 1124.7 71.12C1124.66 71.77 1124.34 71.81 1123.85 71.85C1123.31 71.89 1123.07 71.79 1122.96 71.18C1122.82 70.38 1123.4 70.07 1123.74 69.57H1123.73Z" fill="white"/>
                    <path d="M1128.12 83.03C1128.03 83.18 1127.97 83.43 1127.86 83.46C1127.51 83.53 1127.05 83.66 1126.79 83.51C1126.36 83.26 1125.79 82.99 1125.84 82.33C1125.87 81.82 1126.28 81.84 1126.64 81.86C1127.49 81.91 1128.14 82.43 1128.13 83.04L1128.12 83.03Z" fill="white"/>
                    <path d="M981.72 270.47C981.72 270.85 981.73 271.27 981.21 271.21C980.53 271.13 979.99 270.74 980.04 270.04C980.06 269.67 980.53 269.34 980.79 268.99C981.22 269.42 981.87 269.71 981.72 270.48V270.47Z" fill="white"/>
                    <path d="M1122.08 222.04C1121.41 222.04 1120.67 221.29 1120.73 220.83C1120.79 220.34 1121.18 220.4 1121.53 220.36C1122.24 220.28 1122.72 220.64 1122.85 221.24C1122.89 221.42 1122.44 222.02 1122.08 222.04Z" fill="white"/>
                    <path d="M1038.22 90.19C1038.24 90.66 1038.25 91.15 1037.76 91.03C1037.16 90.88 1036.61 90.51 1036.04 90.23C1036.44 89.72 1036.83 89.3 1037.59 89.35C1038.32 89.4 1038.16 89.88 1038.22 90.19Z" fill="white"/>
                    <path d="M1159.34 161.67C1159.46 162.43 1159.1 162.5 1158.57 162.57C1157.75 162.68 1157.69 162.25 1157.65 161.62C1157.61 160.98 1157.92 160.9 1158.46 160.84C1159.25 160.75 1159.45 161.1 1159.34 161.67Z" fill="white"/>
                    <path d="M1027.8 84.25C1027.31 84.19 1026.53 84.6 1026.62 83.65C1026.67 83.13 1027.06 82.66 1027.64 82.57C1028.6 82.41 1028.38 83.47 1028.66 83.87C1029 84.37 1028.08 84.05 1027.81 84.25H1027.8Z" fill="white"/>
                    <path d="M956.65 41.63C956.66 41.9 956.72 42.18 956.68 42.45C956.62 42.94 956.21 42.91 955.86 42.9C955.6 42.9 955.3 43.05 955.11 42.75C954.9 42.41 954.94 42.09 955.24 41.8C955.37 41.68 955.48 41.53 955.61 41.4C955.85 41.15 956.15 40.66 956.34 40.7C956.75 40.78 956.6 41.29 956.65 41.63Z" fill="white"/>
                    <path d="M1092.26 266.49C1092.91 266.65 1092.68 267.2 1092.71 267.6C1092.78 268.35 1092.17 268.15 1091.77 268.2C1091.4 268.24 1091.01 268.23 1091.04 267.69C1091.06 267.14 1091.78 266.45 1092.26 266.49Z" fill="white"/>
                    <path d="M1090.17 118.2C1089.68 118.2 1089.3 118.22 1089.32 117.68C1089.35 117.13 1090.06 116.42 1090.54 116.49C1091.03 116.56 1090.93 116.97 1091 117.32C1091.16 118.16 1090.66 118.22 1090.17 118.21V118.2Z" fill="white"/>
                    <path d="M997.53 61.55C997.72 61.59 998.11 61.57 998.19 61.72C998.41 62.12 997.94 62.33 997.72 62.47C997.4 62.68 997.36 63.71 996.61 62.95C996.36 62.7 996.02 62.49 996.11 62.08C996.3 61.24 997.02 61.8 997.53 61.55Z" fill="white"/>
                    <path d="M928.31 188.93C928.08 189.3 927.86 190.08 927.39 189.76C926.99 189.49 926.26 189.79 926.17 189.13C926.11 188.74 927.45 188.11 927.96 188.28C928.23 188.37 928.35 188.61 928.32 188.93H928.31Z" fill="white"/>
                    <path d="M1142.1 164.55C1142.1 164.55 1142.4 164.96 1142.6 165.36C1142.69 165.54 1141.67 166.32 1141.41 166.27C1140.73 166.16 1141 165.59 1140.93 165.2C1140.84 164.72 1141.15 164.59 1142.1 164.55Z" fill="white"/>
                    <path d="M1139.25 197.58C1139.1 198.23 1138.55 197.97 1138.15 198.04C1137.65 198.12 1137.45 197.75 1137.57 197.41C1137.71 197.02 1138.04 196.67 1138.35 196.36C1138.46 196.25 1139.28 197.33 1139.25 197.58Z" fill="white"/>
                    <path d="M1007.48 307.05C1007 307.17 1006.79 306.96 1006.78 306.69C1006.75 305.97 1007.18 305.57 1007.83 305.37C1008.17 305.27 1008.37 305.49 1008.39 305.77C1008.45 306.51 1007.96 306.87 1007.47 307.05H1007.48Z" fill="white"/>
                    <path d="M953.42 152.49C954.02 152.55 954.48 152.9 954.5 153.38C954.51 153.62 954.11 154.17 953.56 154.19C952.82 154.21 953.01 153.64 952.96 153.25C952.91 152.88 952.95 152.53 953.42 152.5V152.49Z" fill="white"/>
                    <path d="M1097.13 284.29C1096.87 284.34 1095.75 283.64 1095.85 283.51C1096.12 283.17 1096.44 282.81 1096.82 282.64C1097.17 282.48 1097.53 282.69 1097.49 283.17C1097.46 283.57 1097.79 284.1 1097.13 284.29Z" fill="white"/>
                    <path d="M951.47 147.09C951.34 147.59 951.04 148.12 950.29 148.14C950.02 148.14 949.77 147.97 949.84 147.62C949.96 146.95 950.32 146.48 951.04 146.43C951.31 146.41 951.54 146.6 951.47 147.09Z" fill="white"/>
                    <path d="M889.45 136.67C889.51 137.18 889.16 137.39 888.81 137.27C888.42 137.13 888.07 136.8 887.76 136.49C887.65 136.38 888.73 135.56 888.98 135.59C889.33 135.64 889.51 136.06 889.45 136.67Z" fill="white"/>
                    <path d="M1029.66 88.48C1029.67 88.87 1029.69 89.26 1029.16 89.25C1028.59 89.23 1028.13 88.85 1028.11 88.37C1028.1 88.13 1028.5 87.58 1029.05 87.56C1029.79 87.54 1029.57 88.11 1029.66 88.48Z" fill="white"/>
                    <path d="M942.8 69.18C942.58 69.66 942.33 70.08 941.76 70.17C941.43 70.23 941.2 70.03 941.22 69.75C941.26 69.3 940.79 68.61 941.69 68.48C942.24 68.4 942.39 68.97 942.8 69.17V69.18Z" fill="white"/>
                    <path d="M1021.15 98.28C1021.06 98.42 1020.97 98.56 1020.87 98.7C1020.26 98.17 1019.72 97.53 1018.85 97.39C1018.81 97.39 1018.78 97.02 1018.83 96.86C1018.86 96.77 1019.16 96.65 1019.19 96.68C1019.69 97.23 1020.63 97.06 1021.01 97.77C1021.09 97.92 1021.1 98.11 1021.14 98.28H1021.15Z" fill="white"/>
                    <path d="M1016.93 99.47C1016.38 99.47 1016.64 98.73 1016.66 98.3C1016.7 97.45 1017.45 97.86 1017.9 97.76C1018.03 97.73 1018.55 98.01 1018.1 98.18C1017.43 98.44 1018.3 99.54 1016.93 99.47Z" fill="white"/>
                    <path d="M1035.84 272.85C1036.51 272.71 1036.73 273.33 1037.15 273.61C1036.92 273.88 1036.7 274.36 1036.47 274.36C1035.96 274.37 1035.6 273.98 1035.47 273.45C1035.39 273.13 1035.58 272.97 1035.84 272.85Z" fill="white"/>
                    <path d="M1137.12 160.3C1136.72 160.26 1136.18 160.53 1136.12 159.78C1136.08 159.24 1136.43 159.19 1136.81 159.19C1137.22 159.19 1137.76 158.95 1137.82 159.7C1137.86 160.24 1137.51 160.26 1137.12 160.3Z" fill="white"/>
                    <path d="M961.02 275.4C960.55 276.2 959.99 276.4 959.32 276.32C959.16 276.3 959.02 276.16 958.88 276.07C959 275.94 959.15 275.82 959.24 275.66C959.56 275.06 960.14 275.13 960.66 275.15C960.82 275.15 960.98 275.37 961.02 275.4Z" fill="white"/>
                    <path d="M1165.7 163.68C1165.72 163.82 1165.37 163.91 1164.98 163.9C1164.57 163.9 1164.02 164.15 1163.96 163.4C1163.91 162.87 1164.25 162.8 1164.64 162.8C1165.05 162.8 1165.6 162.57 1165.7 163.68Z" fill="white"/>
                    <path d="M1111.64 138.42C1111.53 138.36 1111.31 138.3 1111.29 138.22C1111.2 137.6 1111.13 136.98 1111.74 136.51C1111.99 136.31 1112.16 136.24 1112.31 136.51C1112.54 136.95 1112.07 138.27 1111.64 138.41V138.42Z" fill="white"/>
                    <path d="M1068.05 238.56C1067.87 239.44 1067.15 238.91 1066.69 239.03C1066.48 239.08 1066.3 238.67 1066.37 238.39C1066.56 237.57 1067.26 237.98 1067.73 237.91C1067.95 237.88 1068.11 238.25 1068.06 238.56H1068.05Z" fill="white"/>
                    <path d="M1047.71 131.29C1047.53 132.17 1046.81 131.64 1046.35 131.76C1046.14 131.81 1045.96 131.4 1046.03 131.12C1046.22 130.3 1046.92 130.71 1047.39 130.64C1047.61 130.61 1047.77 130.98 1047.72 131.29H1047.71Z" fill="white"/>
                    <path d="M946.16 205.74C946.18 205.88 945.83 205.97 945.44 205.96C945.03 205.96 944.48 206.21 944.42 205.46C944.37 204.93 944.71 204.86 945.1 204.86C945.51 204.86 946.06 204.63 946.16 205.74Z" fill="white"/>
                    <path d="M1054.93 57.5C1054.62 57 1054.3 56.49 1054 55.96C1053.99 55.94 1054.1 55.79 1054.17 55.77C1054.55 55.67 1054.85 55.79 1055.13 56.08C1055.45 56.4 1055.83 56.68 1055.55 57.19C1055.49 57.31 1055.26 57.34 1054.92 57.5H1054.93Z" fill="white"/>
                    <path d="M1007.38 78C1007.75 77.95 1008.15 77.88 1008.24 78.39C1008.36 79.04 1007.94 79.13 1007.43 79.13C1007.07 79.13 1006.67 79.24 1006.58 78.73C1006.47 78.08 1006.87 77.96 1007.39 78H1007.38Z" fill="white"/>
                    <path d="M961.56 111.92C961.43 112.42 961.32 112.66 960.97 112.6C960.3 112.48 959.84 112.12 959.78 111.4C959.77 111.26 959.94 111.11 960.02 110.96C960.55 111.29 961.08 111.63 961.55 111.92H961.56Z" fill="white"/>
                    <path d="M1073.43 116.49C1073.53 116.98 1073.44 117.37 1073.21 117.37C1072.74 117.36 1072.09 117.83 1071.81 117.04C1071.72 116.77 1071.81 116.38 1072.07 116.36C1072.55 116.32 1073.15 116.02 1073.43 116.49Z" fill="white"/>
                    <path d="M954.91 222.23C954.91 222.62 954.93 222.97 954.39 222.99C953.64 223.01 953.82 222.45 953.78 222.04C953.74 221.66 953.75 221.31 954.29 221.29C955.04 221.27 954.83 221.84 954.91 222.23Z" fill="white"/>
                    <path d="M1040.01 217.91C1040.89 218.09 1040.36 218.81 1040.48 219.27C1040.53 219.48 1040.12 219.66 1039.84 219.59C1039.02 219.4 1039.43 218.7 1039.36 218.23C1039.33 218.01 1039.7 217.85 1040.01 217.9V217.91Z" fill="white"/>
                    <path d="M959 101.99C959.37 101.94 959.77 101.87 959.86 102.38C959.98 103.03 959.56 103.12 959.05 103.12C958.69 103.12 958.29 103.23 958.2 102.72C958.09 102.07 958.49 101.95 959.01 101.99H959Z" fill="white"/>
                    <path d="M1114.16 80.28C1114.8 80.18 1114.97 80.37 1115.01 80.66C1115.11 81.32 1114.68 81.39 1114.19 81.37C1113.93 81.36 1113.42 81.45 1113.49 81.23C1113.63 80.8 1114.03 80.45 1114.16 80.27V80.28Z" fill="white"/>
                    <path d="M970.16 254.27C970.1 254.41 970.01 254.64 969.91 254.88C969.55 254.52 968.94 254.34 968.99 253.69C969.01 253.41 969.17 253.23 969.51 253.22C970.25 253.21 970.07 253.75 970.16 254.28V254.27Z" fill="white"/>
                    <path d="M929.04 54.49C928.8 54.55 928.6 54.36 928.55 54.08C928.48 53.72 928.51 53.31 929.04 53.35C929.41 53.38 930.02 52.99 930.07 53.64C930.09 53.87 929.66 54.43 929.03 54.48L929.04 54.49Z" fill="white"/>
                    <path d="M1102.16 40.12C1102.27 40.77 1101.66 41 1101.37 41.41C1101.27 41.18 1101.09 40.95 1101.08 40.72C1101.05 40.32 1100.83 39.78 1101.57 39.71C1101.91 39.68 1102.05 39.83 1102.17 40.12H1102.16Z" fill="white"/>
                    <path d="M933.87 85.8C933.5 85.91 933.9 85.9 933.68 85.61C933.56 85.45 933.54 85.05 933.66 84.91C933.98 84.53 934.22 83.93 934.9 84.08C934.98 84.1 935.1 84.2 935.09 84.24C934.93 84.95 934.24 85.26 933.87 85.8Z" fill="white"/>
                    <path d="M968.86 124.97C968.48 125.33 968.25 125.95 967.58 125.76C967.42 125.71 967.25 125.31 967.28 125.1C967.37 124.43 967.96 124.71 968.36 124.63C968.63 124.58 968.85 124.63 968.86 124.97Z" fill="white"/>
                    <path d="M1045.39 172.73C1045.39 172.91 1045.45 173.11 1045.4 173.28C1045.37 173.39 1045.15 173.54 1045.07 173.51C1044.78 173.41 1044.67 171.68 1044.94 171.49C1045 171.44 1045.3 171.56 1045.31 171.63C1045.37 171.99 1045.37 172.36 1045.39 172.73Z" fill="white"/>
                    <path d="M892.68 176.52C892.75 177.09 892.41 177.2 892.02 177.18C891.74 177.16 891.56 177.01 891.55 176.66C891.53 176.14 891.84 176.04 892.22 176.06C892.49 176.08 892.76 176.21 892.68 176.52Z" fill="white"/>
                    <path d="M1110.91 56.28C1110.84 56.58 1110.72 56.75 1110.37 56.77C1109.85 56.79 1109.75 56.48 1109.77 56.1C1109.79 55.82 1109.94 55.64 1110.29 55.63C1110.81 55.61 1110.87 55.92 1110.9 56.29L1110.91 56.28Z" fill="white"/>
                    <path d="M1091.87 262.11C1091.8 262.41 1091.68 262.58 1091.33 262.6C1090.81 262.62 1090.71 262.31 1090.73 261.93C1090.75 261.65 1090.9 261.47 1091.25 261.46C1091.77 261.44 1091.83 261.75 1091.86 262.12L1091.87 262.11Z" fill="white"/>
                    <path d="M986.2 278.86C985.85 278.87 985.53 278.84 985.5 278.32C985.48 277.97 985.64 277.8 985.92 277.75C986.3 277.69 986.62 277.75 986.65 278.27C986.67 278.62 986.51 278.76 986.2 278.85V278.86Z" fill="white"/>
                    <path d="M1154.76 145.9C1155.24 146.09 1155.64 146.36 1155.74 146.9C1155.77 147.04 1155.61 147.35 1155.6 147.35C1155.09 147.22 1154.7 146.94 1154.61 146.39C1154.59 146.25 1154.7 146.08 1154.76 145.9Z" fill="white"/>
                    <path d="M1122.37 241.23C1122.45 241.56 1122.24 241.73 1121.96 241.78C1121.58 241.84 1121.26 241.78 1121.23 241.26C1121.21 240.91 1121.37 240.74 1121.65 240.69C1122.03 240.63 1122.38 240.69 1122.37 241.23Z" fill="white"/>
                    <path d="M1092.41 133.43C1092.34 133.73 1092.22 133.9 1091.87 133.92C1091.35 133.94 1091.25 133.63 1091.27 133.25C1091.29 132.97 1091.44 132.79 1091.79 132.78C1092.31 132.76 1092.37 133.07 1092.4 133.44L1092.41 133.43Z" fill="white"/>
                    <path d="M900.72 114.72C900.64 115.06 900.52 115.24 900.17 115.26C899.65 115.28 899.55 114.97 899.57 114.59C899.59 114.31 899.75 114.14 900.09 114.12C900.62 114.09 900.65 114.43 900.71 114.73L900.72 114.72Z" fill="white"/>
                    <path d="M922.99 108.97C922.46 109.03 922.35 108.7 922.37 108.31C922.39 108.03 922.54 107.85 922.89 107.84C923.41 107.82 923.51 108.13 923.49 108.51C923.47 108.79 923.32 109.02 922.99 108.97Z" fill="white"/>
                    <path d="M1181.47 174.45C1181.47 174.09 1181.46 173.71 1181.98 173.73C1182.14 173.73 1182.3 173.82 1182.46 173.87C1182.23 174.2 1182.01 174.53 1181.78 174.86C1181.68 174.72 1181.58 174.59 1181.47 174.45Z" fill="white"/>
                    <path d="M1135.18 195.25C1135.18 195.61 1135.19 195.99 1134.67 195.97C1134.51 195.97 1134.35 195.88 1134.19 195.83C1134.42 195.5 1134.64 195.17 1134.87 194.84C1134.97 194.98 1135.07 195.11 1135.18 195.25Z" fill="white"/>
                    <path d="M1095.81 71.39C1095.56 71.68 1095.33 71.95 1095.09 72.22C1094.99 71.99 1094.79 71.76 1094.8 71.54C1094.8 71.05 1095.23 71.05 1095.58 71.09C1095.68 71.1 1095.75 71.3 1095.81 71.39Z" fill="white"/>
                    <path d="M1032.06 218.34C1031.85 218.27 1031.56 218.24 1031.55 218.17C1031.51 217.68 1032.01 217.53 1032.22 217.19C1032.32 217.33 1032.48 217.45 1032.51 217.6C1032.58 217.98 1032.5 218.31 1032.06 218.34Z" fill="white"/>
                    <path d="M1011.67 304.81C1011.6 304.93 1011.54 305.14 1011.44 305.16C1011.09 305.24 1010.68 305.28 1010.62 304.79C1010.59 304.57 1010.77 304.32 1010.85 304.08C1011.12 304.32 1011.39 304.56 1011.67 304.81Z" fill="white"/>
                    <path d="M1027.48 281.75C1027.99 281.7 1028.06 282.05 1028.04 282.43C1028.04 282.58 1027.88 282.72 1027.79 282.87C1027.53 282.56 1027.27 282.26 1027.01 281.95C1027.16 281.88 1027.32 281.82 1027.48 281.75Z" fill="white"/>
                    <path d="M1087.32 92.65C1087.28 92.72 1087.24 92.93 1087.15 92.95C1086.8 93.03 1086.39 93.08 1086.32 92.59C1086.29 92.37 1086.46 92.12 1086.54 91.88C1086.79 92.13 1087.05 92.38 1087.33 92.65H1087.32Z" fill="white"/>
                    <path d="M1087.67 129.07C1087.72 129.58 1087.37 129.65 1086.99 129.63C1086.84 129.63 1086.7 129.47 1086.55 129.38C1086.86 129.12 1087.16 128.86 1087.47 128.6C1087.54 128.75 1087.6 128.91 1087.67 129.07Z" fill="white"/>
                    <path d="M1073.01 289.61C1072.93 289.76 1072.85 289.91 1072.77 290.06C1072.5 289.77 1072.22 289.48 1071.95 289.19C1072.1 289.12 1072.24 289.01 1072.4 288.98C1072.89 288.9 1072.99 289.23 1073.01 289.61Z" fill="white"/>
                    <path d="M930.07 125.52C930.58 125.47 930.65 125.82 930.63 126.2C930.63 126.35 930.47 126.49 930.38 126.64C930.12 126.33 929.86 126.03 929.6 125.72C929.75 125.65 929.91 125.59 930.07 125.52Z" fill="white"/>
                    <path d="M891.61 129.77C891.74 129.96 891.89 130.09 891.92 130.25C891.98 130.64 891.89 130.97 891.39 130.94C891.23 130.94 891.08 130.84 890.92 130.78C891.15 130.45 891.37 130.12 891.61 129.77Z" fill="white"/>
                    <path d="M1068.87 20.36C1068.7 20.7 1068.39 20.59 1068.14 20.59C1068.06 20.59 1067.89 20.33 1067.92 20.26C1068.05 19.93 1068.38 20.04 1068.63 20.04C1068.71 20.04 1068.79 20.26 1068.87 20.36Z" fill="white"/>
                    <path d="M1082.77 40.64C1082.87 40.93 1083 41.17 1083.02 41.41C1083.03 41.55 1082.87 41.7 1082.78 41.85C1082.67 41.62 1082.52 41.4 1082.48 41.16C1082.46 41.03 1082.63 40.87 1082.77 40.64Z" fill="white"/>
                    <path d="M918.36 226.37C918.19 226.71 917.88 226.6 917.63 226.6C917.55 226.6 917.38 226.34 917.41 226.27C917.54 225.94 917.87 226.05 918.12 226.05C918.2 226.05 918.28 226.27 918.36 226.37Z" fill="white"/>
                    <path d="M1001.99 289.64C1001.7 289.74 1001.46 289.87 1001.22 289.89C1001.08 289.9 1000.93 289.74 1000.78 289.65C1001.01 289.54 1001.23 289.39 1001.47 289.35C1001.6 289.33 1001.76 289.5 1001.99 289.64Z" fill="white"/>
                    <path d="M1004.97 289.11C1004.82 289.11 1004.57 289.14 1004.33 289.08C1004.06 289.01 1004.04 288.81 1004.31 288.72C1004.54 288.65 1004.81 288.63 1005.04 288.68C1005.31 288.74 1005.31 288.91 1004.96 289.11H1004.97Z" fill="white"/>
                    <path d="M911.95 242.15C912.11 242.21 912.38 242.23 912.4 242.32C912.47 242.65 912.23 242.74 911.96 242.7C911.8 242.68 911.53 242.62 911.51 242.53C911.44 242.2 911.68 242.13 911.95 242.14V242.15Z" fill="white"/>
                    <path d="M1107.23 61.44C1107.17 61.6 1107.15 61.87 1107.06 61.89C1106.73 61.96 1106.64 61.72 1106.68 61.45C1106.7 61.29 1106.76 61.02 1106.85 61C1107.18 60.93 1107.25 61.17 1107.24 61.44H1107.23Z" fill="white"/>
                    <path d="M1076.79 280.2C1076.89 280.49 1077.02 280.73 1077.04 280.97C1077.05 281.11 1076.89 281.26 1076.8 281.41C1076.69 281.18 1076.54 280.96 1076.5 280.72C1076.48 280.59 1076.65 280.43 1076.79 280.2Z" fill="white"/>
                    <path d="M956.17 65.07C956.11 65.18 956.02 65.32 955.93 65.47C955.82 65.24 955.67 65.02 955.63 64.78C955.61 64.65 955.78 64.49 955.87 64.34C956.1 64.51 956.23 64.73 956.16 65.06L956.17 65.07Z" fill="white"/>
                    <path d="M1119.69 275.76C1119.42 275.8 1119.17 275.75 1119.21 275.42C1119.22 275.33 1119.48 275.24 1119.64 275.21C1119.91 275.15 1120.16 275.21 1120.12 275.55C1120.11 275.64 1119.84 275.7 1119.69 275.77V275.76Z" fill="white"/>
                    <path d="M957.09 103.22C956.82 103.26 956.57 103.21 956.61 102.88C956.62 102.79 956.88 102.7 957.04 102.67C957.31 102.61 957.56 102.67 957.52 103.01C957.51 103.1 957.24 103.16 957.09 103.23V103.22Z" fill="white"/>
                    <path d="M965.98 111.21C965.71 111.25 965.46 111.2 965.5 110.87C965.51 110.78 965.77 110.69 965.93 110.66C966.2 110.6 966.45 110.66 966.41 111C966.4 111.09 966.13 111.15 965.98 111.22V111.21Z" fill="white"/>
                    <path d="M1002.58 135.72C1002.68 136.01 1002.81 136.25 1002.83 136.49C1002.84 136.63 1002.68 136.78 1002.59 136.93C1002.48 136.7 1002.33 136.48 1002.29 136.24C1002.27 136.11 1002.44 135.95 1002.58 135.72Z" fill="white"/>
                    <path d="M1090.96 83.66C1090.87 83.85 1090.77 84.08 1090.67 84.31C1090.57 84.17 1090.38 84.03 1090.39 83.9C1090.4 83.66 1090.53 83.42 1090.62 83.19C1090.72 83.33 1090.82 83.46 1090.97 83.67L1090.96 83.66Z" fill="white"/>
                    <path d="M1071.81 171.25C1071.75 171.36 1071.66 171.5 1071.57 171.65C1071.46 171.42 1071.31 171.2 1071.27 170.96C1071.25 170.83 1071.42 170.67 1071.51 170.52C1071.74 170.69 1071.87 170.91 1071.8 171.24L1071.81 171.25Z" fill="white"/>
                    <path d="M1115.31 176.04C1115.41 176.33 1115.54 176.57 1115.56 176.81C1115.57 176.95 1115.41 177.1 1115.32 177.25C1115.21 177.02 1115.06 176.8 1115.02 176.56C1115 176.43 1115.17 176.27 1115.31 176.04Z" fill="white"/>
                    <path d="M1183.54 183.02C1183.37 183.36 1183.06 183.25 1182.81 183.25C1182.73 183.25 1182.56 182.99 1182.59 182.92C1182.72 182.59 1183.05 182.7 1183.3 182.7C1183.38 182.7 1183.46 182.92 1183.54 183.02Z" fill="white"/>
                    <path d="M1129.92 213.15C1129.65 213.19 1129.4 213.14 1129.44 212.81C1129.45 212.72 1129.71 212.63 1129.87 212.6C1130.14 212.54 1130.39 212.6 1130.35 212.94C1130.34 213.03 1130.07 213.09 1129.92 213.16V213.15Z" fill="white"/>
                    <path d="M931.97 215.14C932.13 215.2 932.4 215.22 932.42 215.31C932.49 215.64 932.25 215.73 931.98 215.69C931.82 215.67 931.55 215.61 931.53 215.52C931.46 215.19 931.7 215.12 931.97 215.13V215.14Z" fill="white"/>
                    <path d="M1021.78 195.74C1021.72 195.9 1021.7 196.17 1021.61 196.19C1021.28 196.26 1021.19 196.02 1021.23 195.75C1021.25 195.59 1021.31 195.32 1021.4 195.3C1021.73 195.23 1021.8 195.47 1021.79 195.74H1021.78Z" fill="white"/>
                    <path d="M1023.4 210.31C1023.47 210.43 1023.56 210.55 1023.6 210.68C1023.61 210.73 1023.49 210.81 1023.43 210.87C1023.36 210.75 1023.27 210.63 1023.23 210.5C1023.22 210.45 1023.34 210.37 1023.4 210.31Z" fill="white"/>
                    <path d="M1076.6 298.91C1076.67 299.03 1076.76 299.15 1076.8 299.28C1076.81 299.33 1076.69 299.41 1076.63 299.47C1076.56 299.35 1076.47 299.23 1076.43 299.1C1076.42 299.05 1076.54 298.97 1076.6 298.91Z" fill="white"/>
                    <path d="M977.3 113.63C977.24 113.75 977.18 113.88 977.12 114C977.05 113.94 976.93 113.87 976.93 113.82C976.95 113.69 977.03 113.56 977.09 113.43C977.16 113.49 977.22 113.55 977.3 113.62V113.63Z" fill="white"/>
                    <path d="M934.58 102.91C934.65 102.83 934.71 102.77 934.77 102.7C934.84 102.82 934.93 102.94 934.97 103.07C934.98 103.12 934.86 103.2 934.8 103.26C934.73 103.14 934.65 103.02 934.58 102.91Z" fill="white"/>
                    <path d="M1016.38 97.18C1016.26 97.12 1016.13 97.06 1016.01 97C1016.07 96.93 1016.14 96.81 1016.19 96.81C1016.32 96.83 1016.45 96.91 1016.58 96.97C1016.52 97.04 1016.46 97.1 1016.39 97.18H1016.38Z" fill="white"/>
                    <path d="M1048.08 127.47C1047.96 127.54 1047.84 127.63 1047.71 127.67C1047.66 127.68 1047.58 127.56 1047.52 127.5C1047.64 127.43 1047.76 127.34 1047.89 127.3C1047.94 127.29 1048.02 127.41 1048.08 127.47Z" fill="white"/>
                    <path d="M1094.11 129.76C1094.03 129.69 1093.97 129.63 1093.9 129.57C1094.02 129.5 1094.14 129.41 1094.27 129.37C1094.32 129.36 1094.4 129.48 1094.46 129.54C1094.34 129.61 1094.22 129.69 1094.11 129.76Z" fill="white"/>
                    <path d="M1109.56 75.05C1109.5 75.17 1109.44 75.3 1109.38 75.42C1109.31 75.36 1109.19 75.29 1109.19 75.24C1109.21 75.11 1109.29 74.98 1109.35 74.85C1109.42 74.91 1109.48 74.97 1109.56 75.04V75.05Z" fill="white"/>
                    <path d="M997.83 96.35C997.76 96.43 997.7 96.49 997.64 96.56C997.57 96.44 997.48 96.32 997.44 96.19C997.43 96.14 997.55 96.06 997.61 96C997.68 96.12 997.76 96.24 997.83 96.35Z" fill="white"/>
                    <path d="M1037.21 155.05C1037.28 154.97 1037.34 154.91 1037.4 154.84C1037.47 154.96 1037.56 155.08 1037.6 155.21C1037.61 155.26 1037.49 155.34 1037.43 155.4C1037.36 155.28 1037.28 155.16 1037.21 155.05Z" fill="white"/>
                    <path d="M1173.65 158.97C1173.72 159.03 1173.84 159.12 1173.83 159.14C1173.77 159.27 1173.7 159.42 1173.59 159.48C1173.36 159.61 1173.24 159.5 1173.34 159.26C1173.39 159.14 1173.54 159.07 1173.64 158.97H1173.65Z" fill="white"/>
                    <path d="M1173.36 159.76C1173.25 159.85 1173.12 160.04 1173.04 160.02C1172.82 159.96 1172.77 159.8 1172.92 159.69C1173.04 159.6 1173.2 159.57 1173.34 159.51C1173.34 159.59 1173.34 159.68 1173.35 159.76H1173.36Z" fill="white"/>
                    <path d="M999 225.34C998.94 225.46 998.88 225.59 998.82 225.71C998.75 225.65 998.63 225.58 998.63 225.53C998.65 225.4 998.73 225.27 998.79 225.14C998.86 225.2 998.92 225.26 999 225.33V225.34Z" fill="white"/>
                    <path d="M1168.94 77.56C1168.88 77.68 1168.82 77.81 1168.76 77.93C1168.69 77.87 1168.57 77.8 1168.57 77.75C1168.59 77.62 1168.67 77.49 1168.73 77.36C1168.8 77.42 1168.86 77.48 1168.94 77.55V77.56Z" fill="white"/>
                    <path d="M1179.68 163.49C1179.61 163.57 1179.55 163.63 1179.49 163.7C1179.42 163.58 1179.33 163.46 1179.29 163.33C1179.28 163.28 1179.4 163.2 1179.46 163.14C1179.53 163.26 1179.61 163.38 1179.68 163.49Z" fill="white"/>
                    <path d="M1128.12 70.85C1128.18 70.73 1128.24 70.6 1128.3 70.48C1128.37 70.54 1128.49 70.61 1128.49 70.66C1128.47 70.79 1128.39 70.92 1128.33 71.05C1128.26 70.99 1128.2 70.93 1128.12 70.86V70.85Z" fill="white"/>
                    <path d="M1089.27 203.65C1089.21 203.77 1089.15 203.9 1089.09 204.02C1089.02 203.96 1088.9 203.89 1088.9 203.84C1088.92 203.71 1089 203.58 1089.06 203.45C1089.13 203.51 1089.19 203.57 1089.27 203.64V203.65Z" fill="white"/>
                    <path d="M1041.92 81.73C1041.84 81.66 1041.78 81.6 1041.71 81.54C1041.83 81.47 1041.95 81.38 1042.08 81.34C1042.13 81.33 1042.21 81.45 1042.27 81.51C1042.15 81.58 1042.03 81.66 1041.92 81.73Z" fill="white"/>
                    <path d="M1149.73 177.12C1149.8 177.24 1149.89 177.36 1149.93 177.49C1149.94 177.54 1149.82 177.62 1149.76 177.68C1149.69 177.56 1149.6 177.44 1149.56 177.31C1149.55 177.26 1149.67 177.18 1149.73 177.12Z" fill="white"/>
                    <path d="M1053.88 78.56C1053.82 78.68 1053.76 78.81 1053.7 78.93C1053.63 78.87 1053.51 78.8 1053.51 78.75C1053.53 78.62 1053.61 78.49 1053.67 78.36L1053.88 78.55V78.56Z" fill="white"/>
                    <path d="M1027.11 185.93C1027.23 185.99 1027.36 186.05 1027.48 186.11C1027.42 186.18 1027.35 186.3 1027.3 186.3C1027.17 186.28 1027.04 186.2 1026.91 186.14C1026.97 186.07 1027.03 186.01 1027.1 185.93H1027.11Z" fill="white"/>
                    <path d="M912.67 250.58C912.74 250.7 912.83 250.82 912.87 250.95C912.88 251 912.76 251.08 912.7 251.14C912.63 251.02 912.54 250.9 912.5 250.77C912.49 250.72 912.61 250.64 912.67 250.58Z" fill="white"/>
                    <path d="M1040.81 171.86C1040.83 169.95 1042.19 169.33 1043.48 168.77C1043.95 168.57 1044.73 168.36 1044.93 168.17C1046.24 166.93 1046.91 168.96 1047.96 168.63C1048.12 169.7 1048.68 170.93 1048.34 171.8C1048.01 172.63 1047.52 173.63 1046.78 174.3C1046.08 174.94 1045.4 176.14 1044.26 174.73C1044.09 174.52 1043.51 174.66 1043.13 174.6C1041.43 174.32 1040.67 173.36 1040.81 171.87V171.86Z" fill="white"/>
                    <path d="M1149.56 281.78C1148.43 281.66 1147.72 280.69 1147.77 280.29C1147.95 278.76 1146.61 278.01 1146.28 276.76C1146.05 275.88 1146.05 275.3 1146.76 274.78C1147.94 273.92 1148.6 273.86 1149.39 274.57C1149.73 274.88 1150.09 275.13 1150.48 275.39C1152.14 276.49 1152.47 280.52 1150.41 281.5C1150.04 281.68 1149.74 281.83 1149.56 281.76V281.78Z" fill="white"/>
                    <path d="M1142.3 189.76C1141.69 188.97 1140.24 189.05 1140.26 187.68C1140.26 187.49 1140.36 187.26 1140.3 187.1C1140.04 186.42 1139.5 185.78 1140.01 185.01C1140.41 184.4 1141.12 184.36 1141.72 184.13C1142.9 183.67 1144.52 184.41 1145.16 185.64C1145.66 186.6 1145.55 187.45 1144.93 188.37C1144.29 189.32 1143.21 189.34 1142.31 189.77L1142.3 189.76Z" fill="white"/>
                    <path d="M1269.34 137.71C1271.03 137.87 1271.74 138.56 1271.78 139.79C1271.8 140.47 1271.65 141.15 1271.62 141.83C1271.6 142.47 1271.31 142.65 1270.7 142.56C1270.03 142.46 1269.31 142.53 1268.67 142.35C1267.57 142.05 1266.9 141.22 1266.53 140.17C1266.45 139.96 1266.53 139.55 1266.69 139.41C1267.28 138.93 1267.66 138.29 1268.13 137.73C1268.36 137.46 1269.22 137.71 1269.35 137.71H1269.34Z" fill="white"/>
                    <path d="M1276.61 265.71C1275.84 265.71 1274.74 265.72 1274.53 264.51C1274.43 263.93 1274.24 262.85 1274.96 262.7C1275.74 262.53 1276.47 261.71 1277.36 262.27C1277.97 262.66 1278.48 263.29 1279.14 263.52C1279.75 263.73 1279.86 263.94 1279.81 264.51C1279.75 265.33 1279.23 265.73 1278.57 265.85C1278.01 265.95 1277.41 265.78 1276.62 265.71H1276.61Z" fill="white"/>
                    <path d="M1272.92 116.79C1272.15 116.74 1271.54 116.08 1271.56 115.32C1271.56 115.07 1273.14 112.78 1273.36 112.71C1273.86 112.54 1275.27 113.64 1275.19 113.68C1274.11 114.27 1275.44 114.98 1275.04 115.54C1274.66 116.08 1273.41 116.82 1272.91 116.79H1272.92Z" fill="white"/>
                    <path d="M1100.66 141.38C1100.61 141.78 1100.66 142.41 1100.28 142.38C1099.56 142.33 1098.75 142.59 1098.13 141.85C1097.78 141.44 1097.9 140.93 1097.63 140.59C1097.18 140.04 1097.12 139.67 1097.69 139.15C1098.3 138.58 1099.02 138.55 1099.49 138.98C1100.16 139.59 1101.04 140.24 1100.67 141.38H1100.66Z" fill="white"/>
                    <path d="M1161.83 222.68C1162.44 222.77 1163.26 222.53 1163.11 223.69C1163.08 223.95 1163.58 224.27 1163.81 224.58C1164.07 224.93 1164.3 225.31 1164.55 225.67C1163.89 225.67 1163.11 225.91 1162.62 225.64C1161.99 225.28 1161.61 224.49 1161.08 223.92C1160.79 223.61 1160.58 223.26 1160.78 222.9C1161 222.5 1161.45 222.66 1161.82 222.68H1161.83Z" fill="white"/>
                    <path d="M1165.58 66.16C1165.77 66.18 1165.97 66.17 1166.15 66.21C1166.57 66.31 1167.2 66.04 1167.29 66.72C1167.39 67.41 1167.13 67.98 1166.5 68.39C1165.96 68.74 1165.68 68.49 1165.31 68.12C1165.07 67.88 1164.64 67.85 1164.39 67.61C1164.07 67.3 1163.61 66.98 1163.8 66.42C1164.03 65.76 1164.58 66.13 1165.01 66.1C1165.2 66.09 1165.39 66.13 1165.58 66.15V66.16Z" fill="white"/>
                    <path d="M1280.84 123.92C1281.35 123.18 1281.65 122.25 1282.5 121.79C1282.69 121.68 1283.06 121.7 1283.26 122.01C1283.51 122.39 1284.1 122.7 1283.82 123.22C1283.49 123.85 1283.01 124.42 1282.53 124.97C1281.97 125.62 1281.9 124.59 1281.49 124.61C1281.06 124.63 1280.75 124.46 1280.83 123.92H1280.84Z" fill="white"/>
                    <path d="M1114.09 221.47C1114.62 221.43 1114.93 221.63 1114.84 222.28C1114.7 223.28 1114.12 223.8 1113.22 223.76C1112.42 223.73 1112.03 223.19 1111.89 222.57C1111.73 221.85 1112.81 221.33 1114.09 221.47Z" fill="white"/>
                    <path d="M1058.79 289.76C1059.08 289.79 1059.39 289.76 1059.66 289.85C1060.3 290.05 1060.44 290.86 1059.93 291.28C1059.63 291.53 1059.37 291.85 1059.03 292.01C1058.59 292.23 1058.01 292.21 1057.69 291.9C1057.33 291.55 1057.51 290.83 1057.03 290.46C1057.49 290.17 1057.78 289.51 1058.5 289.73C1058.59 289.76 1058.69 289.75 1058.79 289.76Z" fill="white"/>
                    <path d="M1226.63 126.91C1226.41 126.65 1226.23 126.41 1226.03 126.21C1225.52 125.69 1226.27 124.89 1225.6 124.37C1225.25 124.1 1225.29 123.66 1225.65 123.41C1226 123.17 1226.35 123.32 1226.61 123.66C1226.97 124.11 1227.38 124.53 1227.71 125C1227.87 125.22 1228.45 125.4 1227.91 125.83C1227.47 126.18 1227.05 126.55 1226.62 126.91H1226.63Z" fill="white"/>
                    <path d="M1317.88 206.34C1317.73 206.94 1316.73 207.44 1316.04 207.23C1315.77 207.15 1314.91 205.56 1315.03 205.24C1315.33 204.43 1316.01 204.89 1316.49 204.94C1317.38 205.03 1318.01 205.76 1317.88 206.34Z" fill="white"/>
                    <path d="M1245.63 243.88C1245.59 243.28 1245.75 242.93 1246.42 243.02C1247.23 243.13 1247.99 243.76 1247.94 244.34C1247.89 244.92 1247.02 245.44 1246.2 245.36C1244.99 245.24 1245.76 244.37 1245.63 243.88Z" fill="white"/>
                    <path d="M1241.43 249.67C1241 249.35 1240.51 249.03 1240.52 248.41C1240.53 247.74 1241.74 247.17 1242.5 247.4C1243 247.56 1242.93 247.93 1242.87 248.3C1242.71 249.15 1242.03 249.8 1241.43 249.67Z" fill="white"/>
                    <path d="M1239.61 159.88C1239.99 159.91 1240.18 159.95 1240.37 159.94C1241.08 159.91 1241.24 160.58 1241.64 160.94C1241.18 161.22 1240.91 161.86 1240.21 161.7C1240.02 161.66 1239.83 161.65 1239.64 161.65C1238.99 161.65 1238.59 161.53 1238.68 160.68C1238.77 159.87 1239.11 159.71 1239.6 159.88H1239.61Z" fill="white"/>
                    <path d="M1207.28 325.23C1207.3 324.54 1207.76 324.45 1208.08 324.62C1208.71 324.97 1209.29 325.45 1209.85 325.92C1210.37 326.36 1209.77 326.5 1209.57 326.72C1208.71 327.69 1208.32 326.37 1207.65 326.3C1206.88 326.22 1207.39 325.53 1207.28 325.23Z" fill="white"/>
                    <path d="M1247.99 194.22C1247.18 194.3 1246.77 193.76 1246.61 193.13C1246.49 192.66 1247.75 191.65 1248.09 191.88C1248.73 192.31 1248.97 192.94 1248.83 193.73C1248.73 194.29 1248.38 194.24 1248 194.22H1247.99Z" fill="white"/>
                    <path d="M1103.4 89.6C1103.55 89.04 1104.03 88.76 1104.54 88.57C1104.84 88.45 1105.68 89.83 1105.69 90.43C1105.69 90.98 1105.31 90.99 1104.92 90.95C1104.12 90.86 1103.37 90.2 1103.4 89.61V89.6Z" fill="white"/>
                    <path d="M1273.4 161.81C1273.29 162.09 1273.06 162.35 1272.77 162.17C1272.08 161.73 1271.28 161.45 1270.74 160.76C1270.52 160.47 1270.52 160.14 1270.65 159.96C1270.77 159.79 1271.23 159.64 1271.39 159.74C1272.01 160.12 1272.58 160.58 1273.16 161.03C1273.41 161.22 1273.47 161.5 1273.4 161.82V161.81Z" fill="white"/>
                    <path d="M1303.43 151.29C1303.12 151.29 1302.73 151.29 1302.67 150.78C1302.62 150.33 1303 150.16 1303.23 149.88C1303.7 149.32 1304.3 149.56 1304.8 149.67C1305.1 149.74 1305.56 150.29 1305.49 150.47C1305.36 150.83 1304.98 151.19 1304.61 151.35C1304.3 151.48 1303.87 151.32 1303.42 151.28L1303.43 151.29Z" fill="white"/>
                    <path d="M1255.68 304.4C1255.72 304.88 1255.51 305.19 1254.87 305.09C1254.06 304.97 1253.33 304.34 1253.37 303.77C1253.43 302.79 1254.59 303.57 1254.88 302.84C1254.93 302.72 1255.68 303.65 1255.68 304.4Z" fill="white"/>
                    <path d="M1189.45 135.46C1189.32 135.34 1189.1 135.23 1189.08 135.1C1188.97 134.34 1190.13 133.12 1190.85 133.24C1191.1 133.28 1191.45 133.42 1191.52 133.6C1191.59 133.79 1191.45 134.15 1191.29 134.33C1190.57 135.14 1189.94 135.5 1189.45 135.46Z" fill="white"/>
                    <path d="M1085.3 168.71C1085.28 169 1085.3 169.3 1085.22 169.57C1085.01 170.26 1084.45 169.89 1084.03 169.91C1083.48 169.93 1083.46 169.54 1083.5 169.14C1083.54 168.77 1083.5 168.4 1083.87 168.13C1084.25 167.85 1084.59 167.4 1085.1 167.68C1085.5 167.9 1085.32 168.35 1085.3 168.72V168.71Z" fill="white"/>
                    <path d="M1270.42 163.15C1271 163.32 1270.91 163.72 1270.84 164.08C1270.67 164.94 1270 165.58 1269.39 165.42C1268.89 165.29 1268.97 164.88 1269.04 164.51C1269.21 163.65 1269.88 163.04 1270.41 163.15H1270.42Z" fill="white"/>
                    <path d="M1263.85 305.56C1263.32 305.98 1262.92 306.49 1262.11 306.33C1261.44 306.2 1261.45 305.86 1261.48 305.35C1261.51 304.79 1261.65 304.55 1262.3 304.52C1263.15 304.49 1263.38 305.14 1263.85 305.55V305.56Z" fill="white"/>
                    <path d="M1249.31 308.14C1249.17 308.03 1248.92 307.93 1248.91 307.81C1248.89 307.43 1248.82 306.95 1249.02 306.7C1249.34 306.29 1249.7 305.75 1250.38 305.89C1250.9 306 1250.82 306.41 1250.75 306.78C1250.58 307.65 1249.95 308.24 1249.32 308.15L1249.31 308.14Z" fill="white"/>
                    <path d="M1077.07 129.8C1076.67 129.75 1076.25 129.7 1076.38 129.17C1076.56 128.48 1077.05 127.98 1077.76 128.13C1078.13 128.21 1078.41 128.73 1078.74 129.06C1078.24 129.44 1077.84 130.07 1077.07 129.8Z" fill="white"/>
                    <path d="M1106.69 281.73C1106.8 281.04 1107.66 280.38 1108.14 280.51C1108.64 280.64 1108.52 281.04 1108.51 281.41C1108.49 282.15 1108.05 282.6 1107.41 282.65C1107.22 282.66 1106.66 282.12 1106.7 281.74L1106.69 281.73Z" fill="white"/>
                    <path d="M1254.98 214.29C1254.49 214.25 1253.98 214.18 1254.18 213.69C1254.42 213.09 1254.88 212.58 1255.25 212.03C1255.71 212.51 1256.1 212.98 1255.93 213.76C1255.77 214.5 1255.3 214.27 1254.98 214.29Z" fill="white"/>
                    <path d="M1163.61 328.96C1162.81 328.98 1162.79 328.59 1162.79 328.03C1162.79 327.16 1163.25 327.16 1163.9 327.22C1164.57 327.27 1164.6 327.61 1164.59 328.17C1164.56 329 1164.18 329.16 1163.61 328.96Z" fill="white"/>
                    <path d="M1262.62 204.4C1262.76 203.9 1262.44 203.04 1263.41 203.26C1263.94 203.38 1264.37 203.86 1264.38 204.47C1264.4 205.48 1263.35 205.11 1262.89 205.33C1262.32 205.61 1262.79 204.7 1262.62 204.39V204.4Z" fill="white"/>
                    <path d="M1316.94 137.14C1316.66 137.11 1316.36 137.14 1316.09 137.06C1315.6 136.92 1315.69 136.51 1315.75 136.15C1315.79 135.89 1315.67 135.55 1316.01 135.4C1316.39 135.23 1316.72 135.32 1316.97 135.67C1317.08 135.82 1317.22 135.96 1317.33 136.11C1317.55 136.4 1318.01 136.77 1317.95 136.96C1317.8 137.37 1317.3 137.14 1316.95 137.15L1316.94 137.14Z" fill="white"/>
                    <path d="M1065.14 244.49C1064.88 245.14 1064.35 244.82 1063.93 244.8C1063.15 244.76 1063.44 244.16 1063.45 243.74C1063.45 243.35 1063.53 242.95 1064.08 243.06C1064.64 243.17 1065.25 244 1065.14 244.5V244.49Z" fill="white"/>
                    <path d="M1218.51 263.86C1218.58 263.36 1218.62 262.95 1219.17 263.06C1219.73 263.17 1220.37 264.01 1220.23 264.49C1220.09 264.98 1219.67 264.83 1219.31 264.85C1218.42 264.9 1218.43 264.37 1218.51 263.86Z" fill="white"/>
                    <path d="M1290.45 176.45C1290.38 176.64 1290.34 177.04 1290.18 177.1C1289.74 177.27 1289.59 176.75 1289.47 176.51C1289.3 176.15 1288.24 175.95 1289.14 175.29C1289.44 175.07 1289.7 174.75 1290.11 174.9C1290.95 175.22 1290.26 175.88 1290.45 176.44V176.45Z" fill="white"/>
                    <path d="M1169 86.5C1168.65 86.2 1167.88 85.87 1168.28 85.43C1168.62 85.05 1168.41 84.26 1169.11 84.26C1169.53 84.26 1169.98 85.73 1169.73 86.23C1169.6 86.49 1169.33 86.59 1169.01 86.5H1169Z" fill="white"/>
                    <path d="M1163.13 310.74C1163.13 310.74 1162.67 310.99 1162.22 311.14C1162.03 311.21 1161.37 310.04 1161.45 309.78C1161.67 309.09 1162.21 309.46 1162.63 309.44C1163.14 309.42 1163.23 309.75 1163.13 310.74Z" fill="white"/>
                    <path d="M1129.45 303C1128.8 302.75 1129.15 302.22 1129.13 301.8C1129.11 301.27 1129.53 301.12 1129.87 301.29C1130.26 301.49 1130.57 301.88 1130.84 302.25C1130.94 302.38 1129.7 303.07 1129.45 303Z" fill="white"/>
                    <path d="M1035.57 151.08C1035.51 150.57 1035.76 150.38 1036.04 150.41C1036.79 150.48 1037.14 150.99 1037.25 151.69C1037.3 152.05 1037.04 152.23 1036.76 152.21C1035.98 152.16 1035.69 151.61 1035.57 151.08Z" fill="white"/>
                    <path d="M1202.97 117.71C1202.83 118.32 1202.39 118.74 1201.89 118.7C1201.64 118.68 1201.13 118.18 1201.19 117.61C1201.28 116.84 1201.83 117.12 1202.25 117.13C1202.64 117.13 1203 117.22 1202.96 117.72L1202.97 117.71Z" fill="white"/>
                    <path d="M1046.06 246.93C1046.05 246.66 1046.93 245.6 1047.05 245.73C1047.36 246.06 1047.68 246.44 1047.81 246.85C1047.93 247.24 1047.66 247.57 1047.17 247.47C1046.76 247.38 1046.17 247.65 1046.07 246.93H1046.06Z" fill="white"/>
                    <path d="M1208.83 116.48C1208.33 116.27 1207.83 115.88 1207.92 115.11C1207.95 114.83 1208.17 114.6 1208.52 114.72C1209.19 114.94 1209.63 115.38 1209.57 116.13C1209.55 116.41 1209.32 116.63 1208.83 116.48Z" fill="white"/>
                    <path d="M1228.59 53.97C1228.05 53.96 1227.89 53.56 1228.07 53.22C1228.27 52.83 1228.66 52.52 1229.03 52.25C1229.16 52.15 1229.85 53.39 1229.78 53.64C1229.68 54 1229.22 54.12 1228.6 53.97H1228.59Z" fill="white"/>
                    <path d="M1257.98 205.71C1257.57 205.67 1257.17 205.63 1257.26 205.08C1257.36 204.49 1257.82 204.07 1258.32 204.12C1258.57 204.14 1259.08 204.64 1259.02 205.21C1258.93 205.98 1258.37 205.67 1257.98 205.7V205.71Z" fill="white"/>
                    <path d="M1290.52 118.85C1290.06 118.55 1289.66 118.23 1289.65 117.63C1289.64 117.28 1289.88 117.07 1290.16 117.13C1290.62 117.23 1291.4 116.85 1291.4 117.8C1291.4 118.38 1290.79 118.45 1290.52 118.84V118.85Z" fill="white"/>
                    <path d="M1249.1 195.5C1248.97 195.38 1248.84 195.27 1248.71 195.15C1249.34 194.59 1250.09 194.13 1250.36 193.25C1250.37 193.21 1250.75 193.23 1250.91 193.31C1251 193.36 1251.08 193.68 1251.04 193.71C1250.4 194.15 1250.44 195.14 1249.65 195.44C1249.48 195.5 1249.29 195.48 1249.1 195.5Z" fill="white"/>
                    <path d="M1248.48 190.97C1248.57 190.4 1249.28 190.78 1249.72 190.86C1250.6 191.02 1250.06 191.74 1250.1 192.22C1250.1 192.36 1249.75 192.86 1249.64 192.37C1249.47 191.64 1248.21 192.37 1248.48 190.97Z" fill="white"/>
                    <path d="M1066.76 185.33C1066.81 186.04 1066.13 186.17 1065.79 186.58C1065.55 186.31 1065.08 186.01 1065.11 185.77C1065.17 185.24 1065.63 184.93 1066.2 184.87C1066.54 184.83 1066.68 185.05 1066.76 185.33Z" fill="white"/>
                    <path d="M1168.25 306.21C1168.35 305.81 1168.15 305.2 1168.93 305.25C1169.49 305.28 1169.49 305.66 1169.44 306.05C1169.38 306.47 1169.55 307.07 1168.77 307.02C1168.2 306.99 1168.23 306.61 1168.25 306.21Z" fill="white"/>
                    <path d="M1074.98 107.71C1074.22 107.11 1074.1 106.5 1074.28 105.82C1074.32 105.66 1074.49 105.54 1074.6 105.4C1074.72 105.54 1074.82 105.72 1074.97 105.83C1075.54 106.25 1075.39 106.83 1075.3 107.37C1075.27 107.54 1075.03 107.67 1074.98 107.71Z" fill="white"/>
                    <path d="M1160.61 335.23C1160.47 335.23 1160.42 334.86 1160.49 334.46C1160.56 334.04 1160.37 333.44 1161.15 333.48C1161.7 333.51 1161.73 333.87 1161.67 334.26C1161.61 334.68 1161.77 335.28 1160.6 335.23H1160.61Z" fill="white"/>
                    <path d="M1194.53 283.09C1194.61 282.98 1194.7 282.76 1194.79 282.76C1195.45 282.76 1196.09 282.77 1196.49 283.47C1196.66 283.76 1196.71 283.94 1196.4 284.05C1195.91 284.22 1194.62 283.54 1194.53 283.08V283.09Z" fill="white"/>
                    <path d="M1097.48 223.55C1096.6 223.24 1097.24 222.57 1097.2 222.08C1097.18 221.86 1097.63 221.73 1097.91 221.84C1098.73 222.16 1098.21 222.82 1098.21 223.31C1098.21 223.54 1097.81 223.65 1097.49 223.55H1097.48Z" fill="white"/>
                    <path d="M1211.17 218.13C1210.29 217.82 1210.93 217.15 1210.89 216.66C1210.87 216.44 1211.32 216.31 1211.6 216.42C1212.42 216.74 1211.9 217.4 1211.9 217.89C1211.9 218.12 1211.5 218.23 1211.18 218.13H1211.17Z" fill="white"/>
                    <path d="M1149.05 102.49C1148.91 102.49 1148.86 102.12 1148.93 101.72C1149 101.3 1148.81 100.7 1149.59 100.74C1150.14 100.77 1150.17 101.13 1150.11 101.52C1150.05 101.94 1150.21 102.54 1149.04 102.49H1149.05Z" fill="white"/>
                    <path d="M1286.29 236.29C1286.85 236.05 1287.43 235.79 1288.01 235.55C1288.04 235.54 1288.17 235.68 1288.18 235.76C1288.23 236.16 1288.06 236.45 1287.72 236.71C1287.34 236.99 1287 237.34 1286.52 236.99C1286.41 236.91 1286.41 236.67 1286.29 236.3V236.29Z" fill="white"/>
                    <path d="M1272.04 184.23C1272.04 184.62 1272.05 185.04 1271.52 185.06C1270.83 185.09 1270.8 184.65 1270.87 184.11C1270.92 183.74 1270.87 183.31 1271.41 183.29C1272.09 183.27 1272.17 183.7 1272.04 184.23Z" fill="white"/>
                    <path d="M1243.67 132.01C1243.17 131.81 1242.94 131.65 1243.05 131.31C1243.27 130.64 1243.71 130.21 1244.46 130.26C1244.61 130.26 1244.74 130.47 1244.88 130.58C1244.46 131.08 1244.04 131.58 1243.67 132.02V132.01Z" fill="white"/>
                    <path d="M1222.72 246.82C1222.2 246.85 1221.81 246.7 1221.85 246.46C1221.93 245.97 1221.54 245.23 1222.4 245.06C1222.69 245 1223.08 245.16 1223.06 245.43C1223.03 245.93 1223.25 246.59 1222.73 246.82H1222.72Z" fill="white"/>
                    <path d="M1130.76 109.13C1130.36 109.08 1129.99 109.04 1130.05 108.48C1130.14 107.7 1130.69 107.97 1131.12 107.98C1131.51 107.99 1131.88 108.06 1131.82 108.62C1131.73 109.4 1131.17 109.1 1130.76 109.13Z" fill="white"/>
                    <path d="M1122.87 197.61C1122.56 198.49 1121.89 197.85 1121.4 197.89C1121.18 197.91 1121.05 197.46 1121.16 197.18C1121.48 196.36 1122.14 196.88 1122.63 196.88C1122.86 196.88 1122.97 197.28 1122.87 197.6V197.61Z" fill="white"/>
                    <path d="M1254.29 130.8C1254.29 131.19 1254.3 131.61 1253.77 131.63C1253.08 131.66 1253.05 131.22 1253.12 130.68C1253.17 130.31 1253.12 129.88 1253.66 129.86C1254.34 129.84 1254.42 130.27 1254.29 130.8Z" fill="white"/>
                    <path d="M1254.18 294.13C1254.18 294.8 1253.97 294.95 1253.66 294.95C1252.97 294.95 1252.95 294.51 1253.05 294C1253.1 293.74 1253.08 293.19 1253.29 293.29C1253.72 293.49 1254.02 293.96 1254.18 294.12V294.13Z" fill="white"/>
                    <path d="M1095.48 120.22C1095.34 120.14 1095.12 120.01 1094.89 119.87C1095.31 119.55 1095.59 118.95 1096.25 119.09C1096.54 119.15 1096.7 119.34 1096.66 119.7C1096.57 120.46 1096.03 120.2 1095.48 120.22Z" fill="white"/>
                    <path d="M1307.67 106.76C1307.64 106.5 1307.87 106.32 1308.16 106.31C1308.55 106.3 1308.96 106.39 1308.84 106.92C1308.75 107.3 1309.06 107.99 1308.39 107.95C1308.15 107.93 1307.63 107.41 1307.67 106.76Z" fill="white"/>
                    <path d="M1297.38 287.57C1296.69 287.59 1296.55 286.93 1296.16 286.56C1296.41 286.49 1296.67 286.34 1296.92 286.36C1297.34 286.39 1297.93 286.23 1297.89 287.01C1297.88 287.36 1297.69 287.49 1297.38 287.57Z" fill="white"/>
                    <path d="M1274.65 107.21C1274.59 106.81 1274.55 107.23 1274.88 107.04C1275.06 106.94 1275.48 106.98 1275.61 107.12C1275.96 107.51 1276.54 107.84 1276.29 108.52C1276.26 108.6 1276.13 108.71 1276.09 108.7C1275.38 108.43 1275.17 107.67 1274.66 107.21H1274.65Z" fill="white"/>
                    <path d="M1229.13 137.65C1228.81 137.21 1228.21 136.87 1228.5 136.22C1228.57 136.06 1229.01 135.95 1229.23 136.01C1229.91 136.2 1229.53 136.77 1229.56 137.19C1229.58 137.48 1229.49 137.7 1229.13 137.66V137.65Z" fill="white"/>
                    <path d="M1168.72 209.72C1168.53 209.7 1168.32 209.73 1168.15 209.65C1168.04 209.6 1167.92 209.35 1167.96 209.27C1168.11 208.99 1169.9 209.12 1170.07 209.43C1170.11 209.5 1169.94 209.79 1169.87 209.79C1169.49 209.79 1169.1 209.74 1168.72 209.71V209.72Z" fill="white"/>
                    <path d="M1186.98 51.52C1186.38 51.5 1186.32 51.14 1186.4 50.74C1186.46 50.45 1186.65 50.29 1187.01 50.33C1187.55 50.38 1187.61 50.72 1187.53 51.11C1187.47 51.39 1187.3 51.64 1186.99 51.52H1186.98Z" fill="white"/>
                    <path d="M1279.43 294.26C1279.13 294.14 1278.97 294 1279 293.64C1279.05 293.1 1279.39 293.04 1279.78 293.12C1280.07 293.18 1280.23 293.37 1280.19 293.73C1280.14 294.27 1279.81 294.29 1279.42 294.26H1279.43Z" fill="white"/>
                    <path d="M1069.71 244.73C1069.41 244.61 1069.25 244.47 1069.28 244.11C1069.33 243.57 1069.67 243.51 1070.06 243.59C1070.35 243.65 1070.51 243.84 1070.47 244.2C1070.42 244.74 1070.09 244.76 1069.7 244.73H1069.71Z" fill="white"/>
                    <path d="M1067.76 133.21C1067.8 132.85 1067.87 132.52 1068.42 132.57C1068.78 132.6 1068.94 132.79 1068.95 133.08C1068.95 133.48 1068.84 133.8 1068.3 133.76C1067.94 133.73 1067.82 133.55 1067.76 133.21Z" fill="white"/>
                    <path d="M1180.55 326.52C1180.29 326.99 1179.95 327.36 1179.37 327.39C1179.22 327.39 1178.92 327.19 1178.93 327.18C1179.13 326.67 1179.48 326.31 1180.06 326.3C1180.21 326.3 1180.37 326.44 1180.55 326.53V326.52Z" fill="white"/>
                    <path d="M1086.84 279.24C1086.49 279.28 1086.34 279.03 1086.33 278.74C1086.33 278.34 1086.43 278.02 1086.98 278.06C1087.34 278.09 1087.5 278.28 1087.51 278.57C1087.52 278.98 1087.4 279.32 1086.84 279.24Z" fill="white"/>
                    <path d="M1202.47 263.96C1202.17 263.84 1202.01 263.7 1202.04 263.34C1202.09 262.8 1202.43 262.74 1202.82 262.82C1203.11 262.88 1203.27 263.07 1203.23 263.43C1203.18 263.97 1202.85 263.99 1202.46 263.96H1202.47Z" fill="white"/>
                    <path d="M1249.61 68.79C1249.27 68.66 1249.1 68.51 1249.14 68.15C1249.19 67.61 1249.53 67.55 1249.92 67.63C1250.21 67.69 1250.36 67.88 1250.33 68.24C1250.29 68.8 1249.93 68.77 1249.61 68.79Z" fill="white"/>
                    <path d="M1252.32 92.62C1252.33 92.06 1252.7 92 1253.09 92.08C1253.38 92.14 1253.54 92.33 1253.5 92.69C1253.45 93.23 1253.11 93.29 1252.72 93.21C1252.43 93.15 1252.22 92.96 1252.31 92.62H1252.32Z" fill="white"/>
                    <path d="M1147.2 349.94C1147.58 349.99 1147.97 350.03 1147.87 350.57C1147.84 350.74 1147.73 350.89 1147.66 351.04C1147.35 350.76 1147.04 350.47 1146.73 350.19C1146.89 350.11 1147.04 350.02 1147.2 349.93V349.94Z" fill="white"/>
                    <path d="M1132.45 299.14C1132.07 299.09 1131.68 299.05 1131.78 298.51C1131.81 298.34 1131.92 298.19 1131.99 298.04C1132.3 298.32 1132.61 298.61 1132.92 298.89C1132.76 298.97 1132.61 299.06 1132.45 299.15V299.14Z" fill="white"/>
                    <path d="M1266.02 276.48C1265.76 276.18 1265.51 275.9 1265.27 275.62C1265.52 275.55 1265.78 275.38 1266.02 275.41C1266.53 275.49 1266.47 275.92 1266.37 276.28C1266.34 276.38 1266.12 276.42 1266.03 276.48H1266.02Z" fill="white"/>
                    <path d="M1123.58 189.34C1123.68 189.13 1123.76 188.84 1123.83 188.84C1124.34 188.87 1124.42 189.41 1124.75 189.68C1124.59 189.76 1124.44 189.91 1124.28 189.92C1123.88 189.94 1123.55 189.8 1123.58 189.34Z" fill="white"/>
                    <path d="M1037.27 155.74C1037.16 155.65 1036.95 155.56 1036.94 155.46C1036.91 155.09 1036.93 154.66 1037.45 154.67C1037.69 154.67 1037.91 154.89 1038.15 155.01C1037.86 155.25 1037.57 155.49 1037.27 155.75V155.74Z" fill="white"/>
                    <path d="M1058.78 175.4C1058.76 175.93 1058.38 175.95 1058 175.88C1057.85 175.85 1057.72 175.67 1057.58 175.56C1057.93 175.33 1058.29 175.11 1058.64 174.88C1058.69 175.05 1058.73 175.22 1058.78 175.39V175.4Z" fill="white"/>
                    <path d="M1245.31 264.63C1245.25 264.58 1245.03 264.5 1245.03 264.41C1245 264.04 1245.01 263.61 1245.52 263.61C1245.75 263.61 1245.99 263.82 1246.22 263.94C1245.93 264.17 1245.63 264.39 1245.31 264.64V264.63Z" fill="white"/>
                    <path d="M1207.66 259.7C1207.13 259.68 1207.11 259.3 1207.18 258.92C1207.21 258.77 1207.39 258.64 1207.5 258.5C1207.73 258.85 1207.95 259.21 1208.18 259.56C1208.01 259.61 1207.84 259.65 1207.67 259.7H1207.66Z" fill="white"/>
                    <path d="M1044.06 221.27C1043.91 221.16 1043.77 221.06 1043.63 220.96C1043.97 220.72 1044.31 220.48 1044.65 220.24C1044.7 220.41 1044.79 220.57 1044.8 220.74C1044.82 221.26 1044.46 221.31 1044.06 221.28V221.27Z" fill="white"/>
                    <path d="M1234.2 97.53C1234.18 98.06 1233.8 98.08 1233.42 98.01C1233.27 97.98 1233.14 97.8 1233 97.69C1233.35 97.46 1233.71 97.24 1234.06 97.01C1234.11 97.18 1234.15 97.35 1234.2 97.52V97.53Z" fill="white"/>
                    <path d="M1235.39 57.2C1235.17 57.31 1235.01 57.45 1234.85 57.45C1234.44 57.45 1234.11 57.32 1234.21 56.81C1234.24 56.65 1234.36 56.5 1234.44 56.35C1234.75 56.63 1235.06 56.91 1235.38 57.21L1235.39 57.2Z" fill="white"/>
                    <path d="M1322.61 256.07C1322.29 255.84 1322.44 255.54 1322.48 255.28C1322.49 255.2 1322.78 255.06 1322.86 255.11C1323.18 255.29 1323.02 255.62 1322.98 255.87C1322.97 255.96 1322.73 256.01 1322.61 256.07Z" fill="white"/>
                    <path d="M1299.66 267.47C1299.34 267.53 1299.08 267.64 1298.83 267.62C1298.68 267.62 1298.55 267.42 1298.41 267.31C1298.66 267.23 1298.91 267.11 1299.16 267.1C1299.3 267.1 1299.44 267.3 1299.65 267.47H1299.66Z" fill="white"/>
                    <path d="M1131.8 70.8C1131.47 70.57 1131.63 70.27 1131.67 70.01C1131.68 69.93 1131.97 69.79 1132.05 69.84C1132.37 70.02 1132.21 70.35 1132.17 70.6C1132.16 70.69 1131.92 70.74 1131.8 70.8Z" fill="white"/>
                    <path d="M1054.33 147.94C1054.27 147.62 1054.16 147.36 1054.18 147.11C1054.18 146.96 1054.38 146.83 1054.49 146.69C1054.57 146.94 1054.69 147.19 1054.7 147.44C1054.7 147.58 1054.5 147.72 1054.33 147.93V147.94Z" fill="white"/>
                    <path d="M1054.45 151.1C1054.48 150.95 1054.48 150.68 1054.57 150.45C1054.68 150.18 1054.89 150.19 1054.95 150.48C1054.99 150.73 1054.97 151.01 1054.88 151.24C1054.78 151.52 1054.6 151.49 1054.45 151.1Z" fill="white"/>
                    <path d="M1116.43 61.89C1116.35 62.04 1116.28 62.32 1116.19 62.33C1115.84 62.36 1115.78 62.09 1115.86 61.82C1115.9 61.66 1116 61.38 1116.1 61.38C1116.45 61.35 1116.49 61.61 1116.44 61.89H1116.43Z" fill="white"/>
                    <path d="M1274.63 289.71C1274.48 289.63 1274.2 289.56 1274.19 289.47C1274.16 289.12 1274.43 289.06 1274.7 289.14C1274.86 289.18 1275.14 289.28 1275.14 289.38C1275.17 289.73 1274.91 289.77 1274.63 289.72V289.71Z" fill="white"/>
                    <path d="M1053.22 226.54C1052.9 226.6 1052.64 226.71 1052.39 226.69C1052.24 226.69 1052.11 226.49 1051.97 226.38C1052.22 226.3 1052.47 226.18 1052.72 226.17C1052.86 226.17 1053 226.37 1053.21 226.54H1053.22Z" fill="white"/>
                    <path d="M1292.82 133.24C1292.72 133.16 1292.58 133.05 1292.44 132.94C1292.69 132.86 1292.94 132.74 1293.19 132.73C1293.33 132.73 1293.47 132.93 1293.61 133.04C1293.4 133.26 1293.16 133.35 1292.82 133.24Z" fill="white"/>
                    <path d="M1051.58 271.46C1051.58 271.17 1051.67 270.92 1052 271.01C1052.09 271.03 1052.14 271.32 1052.16 271.49C1052.18 271.77 1052.08 272.03 1051.74 271.94C1051.65 271.92 1051.63 271.63 1051.58 271.46Z" fill="white"/>
                    <path d="M1253.3 128.66C1253.3 128.37 1253.39 128.12 1253.72 128.21C1253.81 128.23 1253.86 128.52 1253.88 128.69C1253.9 128.97 1253.8 129.23 1253.46 129.14C1253.37 129.12 1253.35 128.83 1253.3 128.66Z" fill="white"/>
                    <path d="M1243.76 136.67C1243.76 136.38 1243.85 136.13 1244.18 136.22C1244.27 136.24 1244.32 136.53 1244.34 136.7C1244.36 136.98 1244.26 137.24 1243.92 137.15C1243.83 137.13 1243.81 136.84 1243.76 136.67Z" fill="white"/>
                    <path d="M1213.15 170.9C1212.83 170.96 1212.57 171.07 1212.32 171.05C1212.17 171.05 1212.04 170.85 1211.9 170.74C1212.15 170.66 1212.4 170.54 1212.65 170.53C1212.79 170.53 1212.93 170.73 1213.14 170.9H1213.15Z" fill="white"/>
                    <path d="M1254.06 269.68C1253.87 269.56 1253.65 269.42 1253.43 269.28C1253.59 269.2 1253.76 269.02 1253.9 269.05C1254.15 269.1 1254.37 269.27 1254.6 269.39C1254.44 269.47 1254.29 269.56 1254.06 269.68Z" fill="white"/>
                    <path d="M1166.42 237.21C1166.32 237.13 1166.18 237.02 1166.04 236.91C1166.29 236.83 1166.54 236.71 1166.79 236.7C1166.93 236.7 1167.07 236.9 1167.21 237.01C1167 237.23 1166.76 237.32 1166.42 237.21Z" fill="white"/>
                    <path d="M1155.16 281.41C1154.84 281.47 1154.58 281.58 1154.33 281.56C1154.18 281.56 1154.05 281.36 1153.91 281.25C1154.16 281.17 1154.41 281.05 1154.66 281.04C1154.8 281.04 1154.94 281.24 1155.15 281.41H1155.16Z" fill="white"/>
                    <path d="M1138.05 350.83C1137.72 350.6 1137.88 350.3 1137.92 350.04C1137.93 349.96 1138.22 349.82 1138.3 349.87C1138.62 350.05 1138.46 350.38 1138.42 350.63C1138.41 350.72 1138.17 350.77 1138.05 350.83Z" fill="white"/>
                    <path d="M1114.73 291.11C1114.73 290.82 1114.82 290.57 1115.15 290.66C1115.24 290.68 1115.29 290.97 1115.31 291.14C1115.33 291.42 1115.23 291.68 1114.89 291.59C1114.8 291.57 1114.78 291.28 1114.73 291.11Z" fill="white"/>
                    <path d="M1141.41 86.47C1141.33 86.62 1141.26 86.9 1141.17 86.91C1140.82 86.94 1140.76 86.67 1140.84 86.4C1140.88 86.24 1140.98 85.96 1141.08 85.96C1141.43 85.93 1141.47 86.19 1141.42 86.47H1141.41Z" fill="white"/>
                    <path d="M1148.4 182.01C1148.25 181.93 1147.97 181.86 1147.96 181.77C1147.93 181.42 1148.2 181.36 1148.47 181.44C1148.63 181.48 1148.91 181.58 1148.91 181.68C1148.94 182.03 1148.68 182.07 1148.4 182.02V182.01Z" fill="white"/>
                    <path d="M1133.12 181.56C1132.98 181.61 1132.85 181.69 1132.71 181.71C1132.66 181.71 1132.59 181.58 1132.53 181.51C1132.67 181.46 1132.8 181.38 1132.94 181.36C1132.99 181.36 1133.06 181.49 1133.12 181.56Z" fill="white"/>
                    <path d="M1033.94 223.62C1033.8 223.67 1033.67 223.75 1033.53 223.77C1033.48 223.77 1033.41 223.64 1033.35 223.57C1033.49 223.52 1033.62 223.44 1033.76 223.42C1033.81 223.42 1033.88 223.55 1033.94 223.62Z" fill="white"/>
                    <path d="M1239.62 148.01C1239.5 147.93 1239.38 147.85 1239.26 147.77C1239.33 147.71 1239.42 147.59 1239.47 147.6C1239.61 147.64 1239.72 147.74 1239.85 147.82C1239.78 147.88 1239.71 147.94 1239.62 148.01Z" fill="white"/>
                    <path d="M1256.89 105.46C1256.96 105.54 1257.02 105.62 1257.08 105.69C1256.94 105.74 1256.81 105.82 1256.67 105.84C1256.62 105.84 1256.55 105.71 1256.49 105.64C1256.62 105.58 1256.76 105.52 1256.89 105.46Z" fill="white"/>
                    <path d="M1250.93 190.73C1251.01 190.61 1251.09 190.49 1251.17 190.37C1251.23 190.44 1251.35 190.53 1251.34 190.58C1251.3 190.72 1251.2 190.83 1251.12 190.96C1251.06 190.89 1251 190.82 1250.93 190.73Z" fill="white"/>
                    <path d="M1215.06 219.07C1215.01 218.93 1214.93 218.8 1214.91 218.66C1214.91 218.61 1215.04 218.54 1215.11 218.48C1215.16 218.62 1215.24 218.75 1215.26 218.89C1215.26 218.94 1215.13 219.01 1215.06 219.07Z" fill="white"/>
                    <path d="M1206.02 266.25C1206.1 266.18 1206.18 266.12 1206.25 266.06C1206.3 266.2 1206.38 266.33 1206.4 266.47C1206.4 266.52 1206.27 266.59 1206.2 266.65C1206.14 266.52 1206.08 266.38 1206.02 266.25Z" fill="white"/>
                    <path d="M1260.25 290.14C1260.13 290.06 1260.01 289.98 1259.89 289.9C1259.96 289.84 1260.05 289.72 1260.1 289.73C1260.24 289.77 1260.35 289.87 1260.48 289.95C1260.41 290.01 1260.34 290.07 1260.25 290.14Z" fill="white"/>
                    <path d="M1254.48 171.71C1254.41 171.63 1254.35 171.55 1254.29 171.48C1254.43 171.43 1254.56 171.35 1254.7 171.33C1254.75 171.33 1254.82 171.46 1254.88 171.53C1254.75 171.59 1254.61 171.65 1254.48 171.71Z" fill="white"/>
                    <path d="M1188.17 203.84C1188.24 203.92 1188.3 204 1188.36 204.07C1188.22 204.12 1188.09 204.2 1187.95 204.22C1187.9 204.22 1187.83 204.09 1187.77 204.02C1187.9 203.96 1188.04 203.9 1188.17 203.84Z" fill="white"/>
                    <path d="M1164.31 344.12C1164.24 344.18 1164.13 344.3 1164.11 344.28C1163.99 344.2 1163.84 344.11 1163.79 343.99C1163.69 343.73 1163.82 343.62 1164.06 343.77C1164.17 343.84 1164.23 344 1164.31 344.13V344.12Z" fill="white"/>
                    <path d="M1163.54 343.71C1163.46 343.59 1163.29 343.42 1163.32 343.35C1163.41 343.13 1163.59 343.11 1163.67 343.27C1163.74 343.41 1163.76 343.58 1163.8 343.74C1163.71 343.74 1163.63 343.72 1163.54 343.72V343.71Z" fill="white"/>
                    <path d="M1121.15 154.19C1121.03 154.11 1120.91 154.03 1120.79 153.95C1120.86 153.89 1120.95 153.77 1121 153.78C1121.14 153.82 1121.25 153.92 1121.38 154C1121.31 154.06 1121.24 154.12 1121.15 154.19Z" fill="white"/>
                    <path d="M1249.04 351.07C1248.92 350.99 1248.8 350.91 1248.68 350.83C1248.75 350.77 1248.84 350.65 1248.89 350.66C1249.03 350.7 1249.14 350.8 1249.27 350.88C1249.2 350.94 1249.13 351 1249.04 351.07Z" fill="white"/>
                    <path d="M1158.77 349.69C1158.7 349.61 1158.64 349.53 1158.58 349.46C1158.72 349.41 1158.85 349.33 1158.99 349.31C1159.04 349.31 1159.11 349.44 1159.17 349.51C1159.04 349.57 1158.9 349.63 1158.77 349.69Z" fill="white"/>
                    <path d="M1261.89 309.91C1262.01 309.99 1262.13 310.07 1262.25 310.15C1262.18 310.21 1262.09 310.33 1262.04 310.32C1261.9 310.28 1261.79 310.18 1261.66 310.1C1261.73 310.04 1261.8 309.98 1261.89 309.91Z" fill="white"/>
                    <path d="M1130.44 250.52C1130.32 250.44 1130.2 250.36 1130.08 250.28C1130.15 250.22 1130.24 250.1 1130.29 250.11C1130.43 250.15 1130.54 250.25 1130.67 250.33C1130.6 250.39 1130.53 250.45 1130.44 250.52Z" fill="white"/>
                    <path d="M1263.17 219.34C1263.25 219.27 1263.33 219.21 1263.4 219.15C1263.45 219.29 1263.53 219.42 1263.55 219.56C1263.55 219.61 1263.42 219.68 1263.35 219.74C1263.29 219.61 1263.23 219.47 1263.17 219.34Z" fill="white"/>
                    <path d="M1149.05 316.79C1148.91 316.84 1148.78 316.92 1148.64 316.94C1148.59 316.94 1148.52 316.81 1148.46 316.74C1148.6 316.69 1148.73 316.61 1148.87 316.59C1148.92 316.59 1148.99 316.72 1149.05 316.79Z" fill="white"/>
                    <path d="M1264.71 232.15C1264.59 232.07 1264.47 231.99 1264.35 231.91C1264.42 231.85 1264.51 231.73 1264.56 231.74C1264.7 231.78 1264.81 231.88 1264.94 231.96L1264.71 232.15Z" fill="white"/>
                    <path d="M1157.75 188.93C1157.67 189.05 1157.59 189.17 1157.51 189.29C1157.45 189.22 1157.33 189.13 1157.34 189.08C1157.38 188.94 1157.48 188.83 1157.56 188.7C1157.62 188.77 1157.68 188.84 1157.75 188.93Z" fill="white"/>
                    <path d="M1107.63 61.41C1107.49 61.46 1107.36 61.54 1107.22 61.56C1107.17 61.56 1107.1 61.43 1107.04 61.36C1107.18 61.31 1107.31 61.23 1107.45 61.21C1107.5 61.21 1107.57 61.34 1107.63 61.41Z" fill="white"/>
                    <path d="M1295.82 87.61C1297.72 87.5 1298.43 88.81 1299.07 90.05C1299.3 90.51 1299.56 91.26 1299.77 91.45C1301.1 92.67 1299.12 93.47 1299.52 94.49C1298.47 94.73 1297.29 95.36 1296.4 95.08C1295.55 94.81 1294.53 94.39 1293.81 93.7C1293.13 93.05 1291.89 92.45 1293.21 91.22C1293.41 91.04 1293.23 90.47 1293.26 90.09C1293.42 88.38 1294.32 87.56 1295.82 87.59V87.61Z" fill="white"/>
                    <path d="M1194.11 203.19C1194.15 202.06 1195.06 201.29 1195.46 201.31C1196.99 201.38 1197.64 200 1198.86 199.59C1199.72 199.3 1200.29 199.26 1200.86 199.93C1201.8 201.04 1201.9 201.7 1201.25 202.53C1200.97 202.89 1200.74 203.27 1200.51 203.67C1199.53 205.39 1195.56 206 1194.43 204.02C1194.23 203.67 1194.06 203.38 1194.11 203.2V203.19Z" fill="white"/>
                    <path d="M1285.01 189.66C1285.75 189 1285.57 187.56 1286.94 187.49C1287.13 187.48 1287.36 187.56 1287.52 187.49C1288.18 187.18 1288.77 186.61 1289.58 187.06C1290.21 187.41 1290.3 188.12 1290.57 188.69C1291.11 189.83 1290.49 191.49 1289.31 192.21C1288.39 192.77 1287.53 192.72 1286.58 192.17C1285.59 191.6 1285.5 190.53 1285.01 189.66Z" fill="white"/>
                    <path d="M1345.44 312.28C1345.4 313.97 1344.76 314.73 1343.54 314.84C1342.87 314.9 1342.18 314.8 1341.5 314.83C1340.86 314.85 1340.67 314.57 1340.71 313.97C1340.76 313.29 1340.65 312.59 1340.78 311.94C1341 310.83 1341.78 310.1 1342.8 309.66C1343.01 309.57 1343.42 309.62 1343.56 309.77C1344.08 310.33 1344.74 310.66 1345.33 311.08C1345.62 311.29 1345.43 312.16 1345.43 312.29L1345.44 312.28Z" fill="white"/>
                    <path d="M1218.79 328.29C1218.74 327.53 1218.65 326.44 1219.84 326.14C1220.41 326 1221.47 325.74 1221.67 326.44C1221.89 327.2 1222.76 327.88 1222.26 328.79C1221.91 329.43 1221.32 329.97 1221.14 330.64C1220.97 331.26 1220.77 331.38 1220.2 331.38C1219.38 331.38 1218.95 330.89 1218.79 330.24C1218.66 329.69 1218.79 329.08 1218.79 328.29Z" fill="white"/>
                    <path d="M1366.46 314.4C1366.46 313.63 1367.07 312.98 1367.83 312.95C1368.08 312.94 1370.46 314.35 1370.55 314.56C1370.75 315.04 1369.76 316.52 1369.71 316.45C1369.05 315.42 1368.44 316.79 1367.85 316.43C1367.29 316.09 1366.46 314.9 1366.46 314.4Z" fill="white"/>
                    <path d="M1330.21 144.98C1329.81 144.96 1329.18 145.06 1329.19 144.67C1329.19 143.96 1328.88 143.16 1329.57 142.5C1329.95 142.13 1330.47 142.21 1330.79 141.91C1331.3 141.43 1331.67 141.34 1332.23 141.87C1332.83 142.44 1332.92 143.15 1332.53 143.65C1331.97 144.36 1331.38 145.27 1330.23 144.99L1330.21 144.98Z" fill="white"/>
                    <path d="M1253.65 211.32C1253.6 211.93 1253.9 212.73 1252.74 212.66C1252.48 212.64 1252.2 213.17 1251.91 213.42C1251.58 213.7 1251.22 213.96 1250.87 214.23C1250.82 213.58 1250.53 212.82 1250.77 212.31C1251.08 211.66 1251.84 211.23 1252.37 210.67C1252.66 210.36 1252.99 210.13 1253.37 210.3C1253.79 210.49 1253.65 210.95 1253.66 211.32H1253.65Z" fill="white"/>
                    <path d="M1409.38 204.3C1409.38 204.49 1409.39 204.68 1409.37 204.87C1409.3 205.29 1409.61 205.9 1408.94 206.04C1408.26 206.19 1407.67 205.97 1407.22 205.37C1406.84 204.86 1407.07 204.56 1407.41 204.17C1407.63 203.92 1407.64 203.48 1407.85 203.23C1408.14 202.89 1408.42 202.41 1408.99 202.56C1409.66 202.74 1409.33 203.32 1409.39 203.74C1409.41 203.93 1409.39 204.12 1409.39 204.31L1409.38 204.3Z" fill="white"/>
                    <path d="M1359.92 322.76C1360.69 323.21 1361.63 323.45 1362.15 324.26C1362.27 324.45 1362.27 324.81 1361.99 325.03C1361.63 325.31 1361.36 325.91 1360.83 325.67C1360.18 325.38 1359.58 324.95 1359 324.51C1358.32 323.99 1359.33 323.86 1359.29 323.45C1359.24 323.03 1359.39 322.7 1359.93 322.75L1359.92 322.76Z" fill="white"/>
                    <path d="M1251.58 163.81C1251.66 164.34 1251.48 164.66 1250.83 164.61C1249.83 164.54 1249.27 164 1249.25 163.1C1249.23 162.3 1249.73 161.88 1250.34 161.69C1251.05 161.48 1251.64 162.51 1251.58 163.8V163.81Z" fill="white"/>
                    <path d="M1179.94 113.58C1179.94 113.87 1179.98 114.17 1179.91 114.45C1179.76 115.1 1178.96 115.29 1178.51 114.82C1178.24 114.54 1177.91 114.3 1177.72 113.97C1177.48 113.55 1177.45 112.97 1177.74 112.63C1178.07 112.24 1178.79 112.38 1179.13 111.87C1179.45 112.31 1180.12 112.55 1179.95 113.28C1179.93 113.37 1179.95 113.47 1179.95 113.57L1179.94 113.58Z" fill="white"/>
                    <path d="M1353.23 269.11C1353.48 268.87 1353.7 268.68 1353.89 268.47C1354.38 267.93 1355.22 268.62 1355.68 267.92C1355.93 267.55 1356.37 267.56 1356.64 267.91C1356.9 268.24 1356.78 268.59 1356.45 268.88C1356.02 269.26 1355.64 269.7 1355.19 270.06C1354.98 270.23 1354.84 270.82 1354.38 270.31C1354.01 269.9 1353.61 269.5 1353.22 269.1L1353.23 269.11Z" fill="white"/>
                    <path d="M1280.6 365.21C1279.99 365.11 1279.43 364.14 1279.59 363.45C1279.65 363.18 1281.17 362.22 1281.5 362.31C1282.33 362.56 1281.91 363.26 1281.9 363.74C1281.87 364.63 1281.19 365.31 1280.61 365.21H1280.6Z" fill="white"/>
                    <path d="M1238.34 296.02C1238.93 295.94 1239.29 296.08 1239.25 296.74C1239.2 297.55 1238.62 298.35 1238.04 298.34C1237.46 298.33 1236.89 297.5 1236.91 296.69C1236.94 295.48 1237.87 296.19 1238.34 296.02Z" fill="white"/>
                    <path d="M1232.31 292.25C1232.6 291.8 1232.89 291.29 1233.5 291.26C1234.17 291.23 1234.82 292.39 1234.64 293.16C1234.52 293.67 1234.14 293.63 1233.77 293.59C1232.91 293.49 1232.22 292.86 1232.31 292.25Z" fill="white"/>
                    <path d="M1321.37 284.27C1321.37 284.65 1321.34 284.84 1321.36 285.03C1321.44 285.74 1320.78 285.94 1320.46 286.36C1320.15 285.92 1319.5 285.7 1319.6 285C1319.63 284.81 1319.62 284.62 1319.61 284.43C1319.57 283.79 1319.66 283.38 1320.51 283.41C1321.32 283.44 1321.5 283.77 1321.37 284.27Z" fill="white"/>
                    <path d="M1154.91 263.51C1155.59 263.48 1155.72 263.93 1155.57 264.26C1155.26 264.91 1154.83 265.52 1154.4 266.11C1154 266.66 1153.82 266.07 1153.59 265.89C1152.57 265.1 1153.85 264.62 1153.88 263.95C1153.91 263.18 1154.63 263.64 1154.92 263.51H1154.91Z" fill="white"/>
                    <path d="M1287.83 294.95C1287.69 294.15 1288.2 293.7 1288.81 293.5C1289.27 293.35 1290.35 294.53 1290.15 294.88C1289.76 295.55 1289.16 295.82 1288.36 295.74C1287.8 295.68 1287.82 295.33 1287.82 294.94L1287.83 294.95Z" fill="white"/>
                    <path d="M1381.83 144.15C1382.39 144.26 1382.7 144.72 1382.94 145.21C1383.08 145.5 1381.77 146.43 1381.17 146.48C1380.63 146.52 1380.58 146.15 1380.6 145.75C1380.64 144.95 1381.24 144.15 1381.83 144.15Z" fill="white"/>
                    <path d="M1321.77 317.97C1321.49 317.88 1321.21 317.67 1321.37 317.37C1321.76 316.66 1321.98 315.84 1322.64 315.26C1322.92 315.02 1323.24 315 1323.43 315.11C1323.61 315.22 1323.78 315.66 1323.7 315.83C1323.37 316.48 1322.94 317.07 1322.54 317.68C1322.37 317.94 1322.1 318.02 1321.78 317.97H1321.77Z" fill="white"/>
                    <path d="M1334.28 347.08C1334.26 346.77 1334.22 346.39 1334.73 346.29C1335.17 346.21 1335.37 346.57 1335.66 346.79C1336.25 347.22 1336.06 347.83 1335.97 348.34C1335.92 348.64 1335.4 349.13 1335.22 349.08C1334.85 348.98 1334.46 348.62 1334.29 348.26C1334.14 347.96 1334.27 347.52 1334.28 347.07V347.08Z" fill="white"/>
                    <path d="M1178.92 310.16C1178.44 310.23 1178.13 310.04 1178.18 309.4C1178.24 308.59 1178.82 307.82 1179.39 307.82C1180.36 307.82 1179.68 309.02 1180.42 309.26C1180.54 309.3 1179.67 310.12 1178.92 310.16Z" fill="white"/>
                    <path d="M1342.18 232.77C1342.29 232.63 1342.38 232.41 1342.51 232.38C1343.25 232.21 1344.55 233.29 1344.48 234.01C1344.46 234.26 1344.35 234.62 1344.17 234.7C1343.99 234.79 1343.62 234.67 1343.43 234.52C1342.58 233.86 1342.18 233.26 1342.18 232.77Z" fill="white"/>
                    <path d="M1302.01 131.6C1301.72 131.6 1301.43 131.64 1301.15 131.58C1300.45 131.42 1300.78 130.84 1300.73 130.42C1300.67 129.88 1301.06 129.83 1301.46 129.84C1301.83 129.85 1302.2 129.79 1302.49 130.14C1302.79 130.5 1303.26 130.8 1303.02 131.33C1302.83 131.74 1302.37 131.59 1302 131.6H1302.01Z" fill="white"/>
                    <path d="M1320.24 315.11C1320.11 315.7 1319.71 315.64 1319.35 315.6C1318.48 315.49 1317.81 314.87 1317.92 314.26C1318.01 313.75 1318.43 313.8 1318.8 313.85C1319.67 313.96 1320.32 314.58 1320.25 315.12L1320.24 315.11Z" fill="white"/>
                    <path d="M1178.33 318.35C1177.88 317.85 1177.34 317.49 1177.44 316.67C1177.53 316 1177.86 315.98 1178.37 315.98C1178.93 315.98 1179.18 316.1 1179.25 316.74C1179.34 317.58 1178.71 317.85 1178.33 318.35Z" fill="white"/>
                    <path d="M1174.77 304.09C1174.87 303.94 1174.96 303.68 1175.07 303.67C1175.44 303.62 1175.92 303.52 1176.18 303.7C1176.61 303.99 1177.17 304.31 1177.08 304.99C1177.01 305.51 1176.59 305.47 1176.22 305.42C1175.34 305.31 1174.71 304.72 1174.76 304.09H1174.77Z" fill="white"/>
                    <path d="M1340.09 120.75C1340.11 120.35 1340.14 119.93 1340.67 120.02C1341.36 120.15 1341.89 120.6 1341.79 121.32C1341.74 121.7 1341.24 122.01 1340.94 122.35C1340.53 121.88 1339.87 121.53 1340.09 120.74V120.75Z" fill="white"/>
                    <path d="M1191.21 160.6C1191.91 160.66 1192.61 161.48 1192.52 161.96C1192.43 162.46 1192.02 162.37 1191.65 162.39C1190.91 162.42 1190.44 162.01 1190.35 161.38C1190.32 161.19 1190.83 160.6 1191.21 160.61V160.6Z" fill="white"/>
                    <path d="M1268.38 303.28C1268.38 302.79 1268.42 302.28 1268.92 302.44C1269.53 302.64 1270.07 303.06 1270.64 303.39C1270.19 303.88 1269.76 304.3 1268.97 304.19C1268.22 304.09 1268.42 303.6 1268.38 303.28Z" fill="white"/>
                    <path d="M1148.21 220.38C1148.14 219.58 1148.52 219.54 1149.08 219.5C1149.94 219.45 1149.97 219.9 1149.96 220.55C1149.96 221.22 1149.62 221.28 1149.06 221.3C1148.23 221.33 1148.05 220.96 1148.21 220.38Z" fill="white"/>
                    <path d="M1278.73 310.18C1279.24 310.28 1280.07 309.91 1279.91 310.89C1279.82 311.42 1279.38 311.88 1278.77 311.94C1277.76 312.03 1278.07 310.96 1277.81 310.52C1277.49 309.97 1278.42 310.38 1278.72 310.19L1278.73 310.18Z" fill="white"/>
                    <path d="M1349.27 359.53C1349.27 359.25 1349.24 358.95 1349.3 358.68C1349.4 358.18 1349.82 358.24 1350.18 358.28C1350.45 358.3 1350.77 358.16 1350.94 358.49C1351.13 358.86 1351.07 359.19 1350.73 359.46C1350.58 359.58 1350.46 359.73 1350.32 359.85C1350.05 360.09 1349.71 360.57 1349.52 360.52C1349.1 360.4 1349.3 359.89 1349.27 359.54V359.53Z" fill="white"/>
                    <path d="M1225.35 116.77C1224.69 116.55 1224.97 116 1224.96 115.59C1224.95 114.81 1225.56 115.06 1225.98 115.04C1226.37 115.02 1226.77 115.07 1226.7 115.62C1226.63 116.19 1225.84 116.85 1225.35 116.77Z" fill="white"/>
                    <path d="M1216.64 270.46C1217.15 270.49 1217.55 270.5 1217.48 271.06C1217.41 271.63 1216.62 272.31 1216.13 272.21C1215.63 272.1 1215.76 271.68 1215.71 271.32C1215.6 270.44 1216.13 270.41 1216.64 270.46Z" fill="white"/>
                    <path d="M1308.4 335.91C1308.21 335.85 1307.81 335.85 1307.73 335.69C1307.54 335.26 1308.03 335.08 1308.27 334.95C1308.62 334.75 1308.74 333.69 1309.46 334.54C1309.7 334.82 1310.04 335.06 1309.91 335.48C1309.65 336.33 1308.94 335.7 1308.4 335.92V335.91Z" fill="white"/>
                    <path d="M1389.41 209.1C1389.68 208.73 1389.96 207.94 1390.42 208.31C1390.82 208.62 1391.59 208.36 1391.64 209.05C1391.67 209.46 1390.24 210.02 1389.73 209.8C1389.46 209.69 1389.35 209.43 1389.41 209.1Z" fill="white"/>
                    <path d="M1166.27 218.66C1166.27 218.66 1165.99 218.22 1165.81 217.79C1165.73 217.6 1166.84 216.87 1167.11 216.93C1167.81 217.1 1167.49 217.66 1167.53 218.07C1167.58 218.57 1167.26 218.69 1166.27 218.66Z" fill="white"/>
                    <path d="M1171.64 184.67C1171.85 184.01 1172.39 184.31 1172.81 184.27C1173.33 184.22 1173.51 184.62 1173.36 184.97C1173.19 185.37 1172.82 185.7 1172.48 186C1172.36 186.11 1171.59 184.92 1171.64 184.67Z" fill="white"/>
                    <path d="M1316.11 80.99C1316.62 80.9 1316.82 81.14 1316.81 81.41C1316.79 82.16 1316.31 82.54 1315.62 82.7C1315.27 82.78 1315.07 82.53 1315.07 82.25C1315.07 81.48 1315.6 81.14 1316.11 80.99Z" fill="white"/>
                    <path d="M1360.74 244.98C1360.13 244.88 1359.68 244.48 1359.68 243.98C1359.68 243.74 1360.14 243.19 1360.72 243.21C1361.49 243.24 1361.25 243.81 1361.27 244.23C1361.29 244.62 1361.23 244.98 1360.74 244.98Z" fill="white"/>
                    <path d="M1221.61 97.98C1221.88 97.95 1222.99 98.76 1222.87 98.88C1222.56 99.21 1222.21 99.56 1221.81 99.71C1221.44 99.85 1221.08 99.61 1221.15 99.11C1221.21 98.7 1220.91 98.13 1221.61 97.98Z" fill="white"/>
                    <path d="M1362.37 250.71C1362.54 250.2 1362.89 249.68 1363.67 249.71C1363.95 249.72 1364.19 249.93 1364.1 250.28C1363.92 250.96 1363.52 251.43 1362.77 251.42C1362.49 251.42 1362.26 251.21 1362.37 250.71Z" fill="white"/>
                    <path d="M1425.82 266.05C1425.8 265.51 1426.18 265.33 1426.53 265.48C1426.93 265.65 1427.26 266.02 1427.56 266.36C1427.67 266.48 1426.48 267.25 1426.23 267.2C1425.87 267.12 1425.71 266.67 1425.82 266.05Z" fill="white"/>
                    <path d="M1277.11 305.66C1277.13 305.25 1277.14 304.85 1277.68 304.9C1278.27 304.96 1278.72 305.39 1278.71 305.88C1278.71 306.13 1278.25 306.67 1277.67 306.65C1276.9 306.62 1277.17 306.04 1277.11 305.65V305.66Z" fill="white"/>
                    <path d="M1365.63 332.02C1365.89 331.54 1366.18 331.13 1366.78 331.07C1367.13 331.04 1367.35 331.26 1367.31 331.54C1367.24 332 1367.68 332.75 1366.73 332.82C1366.15 332.86 1366.04 332.26 1365.63 332.02Z" fill="white"/>
                    <path d="M1286.64 296.14C1286.75 296 1286.85 295.86 1286.96 295.72C1287.56 296.31 1288.06 297.02 1288.96 297.23C1289 297.23 1289 297.61 1288.94 297.78C1288.9 297.88 1288.58 297.97 1288.56 297.94C1288.08 297.33 1287.1 297.44 1286.75 296.68C1286.68 296.52 1286.68 296.32 1286.65 296.14H1286.64Z" fill="white"/>
                    <path d="M1291.1 295.22C1291.67 295.27 1291.34 296 1291.29 296.45C1291.19 297.33 1290.44 296.85 1289.97 296.92C1289.83 296.94 1289.31 296.62 1289.79 296.47C1290.51 296.25 1289.69 295.05 1291.1 295.22Z" fill="white"/>
                    <path d="M1284.23 114.32C1283.53 114.42 1283.35 113.75 1282.93 113.44C1283.18 113.18 1283.45 112.7 1283.69 112.71C1284.22 112.73 1284.56 113.17 1284.66 113.73C1284.72 114.07 1284.51 114.22 1284.24 114.32H1284.23Z" fill="white"/>
                    <path d="M1171.12 223.43C1171.53 223.5 1172.11 223.26 1172.12 224.04C1172.12 224.6 1171.76 224.63 1171.37 224.6C1170.94 224.57 1170.37 224.78 1170.36 224C1170.36 223.43 1170.73 223.44 1171.13 223.43H1171.12Z" fill="white"/>
                    <path d="M1361.89 117.16C1362.43 116.37 1363.03 116.2 1363.72 116.34C1363.88 116.37 1364.01 116.53 1364.16 116.63C1364.02 116.76 1363.86 116.87 1363.76 117.03C1363.38 117.62 1362.79 117.51 1362.25 117.46C1362.08 117.44 1361.94 117.21 1361.89 117.17V117.16Z" fill="white"/>
                    <path d="M1141.77 217.83C1141.77 217.69 1142.13 217.62 1142.53 217.66C1142.95 217.7 1143.54 217.47 1143.55 218.25C1143.55 218.8 1143.21 218.85 1142.81 218.82C1142.39 218.79 1141.8 218.99 1141.77 217.83Z" fill="white"/>
                    <path d="M1195.89 247.95C1196 248.02 1196.23 248.09 1196.23 248.18C1196.28 248.83 1196.31 249.47 1195.64 249.92C1195.36 250.1 1195.19 250.17 1195.05 249.87C1194.84 249.4 1195.43 248.07 1195.88 247.95H1195.89Z" fill="white"/>
                    <path d="M1248.37 147.46C1248.62 146.56 1249.33 147.16 1249.81 147.08C1250.03 147.04 1250.19 147.48 1250.1 147.76C1249.84 148.6 1249.15 148.12 1248.66 148.16C1248.43 148.17 1248.29 147.78 1248.37 147.46Z" fill="white"/>
                    <path d="M1261.56 260.02C1261.81 259.12 1262.52 259.72 1263 259.64C1263.22 259.6 1263.38 260.04 1263.29 260.32C1263.03 261.16 1262.34 260.68 1261.85 260.72C1261.62 260.73 1261.48 260.34 1261.56 260.02Z" fill="white"/>
                    <path d="M1372.16 190.38C1372.16 190.24 1372.52 190.17 1372.92 190.21C1373.34 190.25 1373.93 190.02 1373.94 190.8C1373.94 191.35 1373.6 191.4 1373.2 191.37C1372.78 191.34 1372.19 191.54 1372.16 190.38Z" fill="white"/>
                    <path d="M1248.68 335.89C1248.96 336.43 1249.25 336.98 1249.53 337.55C1249.54 337.58 1249.41 337.72 1249.34 337.73C1248.94 337.81 1248.64 337.66 1248.37 337.34C1248.06 336.98 1247.69 336.67 1248.01 336.16C1248.09 336.04 1248.32 336.02 1248.68 335.88V335.89Z" fill="white"/>
                    <path d="M1299.41 318.16C1299.02 318.18 1298.61 318.23 1298.55 317.7C1298.48 317.02 1298.91 316.95 1299.45 316.99C1299.82 317.02 1300.25 316.93 1300.3 317.47C1300.37 318.15 1299.95 318.25 1299.41 318.16Z" fill="white"/>
                    <path d="M1349.34 286.39C1349.51 285.88 1349.65 285.64 1350 285.73C1350.68 285.91 1351.14 286.31 1351.14 287.06C1351.14 287.21 1350.95 287.35 1350.85 287.5C1350.32 287.12 1349.8 286.73 1349.34 286.39Z" fill="white"/>
                    <path d="M1233.85 273.46C1233.79 272.94 1233.9 272.54 1234.15 272.57C1234.64 272.62 1235.35 272.17 1235.58 273.02C1235.66 273.31 1235.52 273.7 1235.26 273.7C1234.76 273.7 1234.12 273.97 1233.86 273.47L1233.85 273.46Z" fill="white"/>
                    <path d="M1364.32 172.66C1364.34 172.26 1364.35 171.89 1364.92 171.91C1365.7 171.95 1365.47 172.52 1365.49 172.94C1365.5 173.33 1365.47 173.7 1364.9 173.67C1364.12 173.64 1364.38 173.06 1364.32 172.65V172.66Z" fill="white"/>
                    <path d="M1275.88 170.9C1274.98 170.65 1275.58 169.94 1275.5 169.46C1275.46 169.24 1275.9 169.08 1276.18 169.17C1277.02 169.43 1276.54 170.12 1276.58 170.61C1276.59 170.84 1276.2 170.98 1275.88 170.9Z" fill="white"/>
                    <path d="M1351.27 296.86C1350.88 296.88 1350.47 296.93 1350.41 296.4C1350.34 295.72 1350.77 295.65 1351.31 295.69C1351.68 295.72 1352.11 295.63 1352.16 296.17C1352.23 296.85 1351.81 296.95 1351.27 296.86Z" fill="white"/>
                    <path d="M1189.02 307.96C1188.35 308.01 1188.19 307.81 1188.17 307.5C1188.11 306.81 1188.56 306.76 1189.07 306.82C1189.33 306.85 1189.87 306.8 1189.79 307.01C1189.62 307.45 1189.18 307.78 1189.02 307.95V307.96Z" fill="white"/>
                    <path d="M1350.88 138.38C1350.95 138.24 1351.07 138.01 1351.18 137.77C1351.53 138.17 1352.14 138.4 1352.05 139.07C1352.01 139.36 1351.83 139.53 1351.47 139.52C1350.71 139.48 1350.93 138.93 1350.88 138.38Z" fill="white"/>
                    <path d="M1378.81 348.24C1379.07 348.19 1379.26 348.41 1379.29 348.7C1379.33 349.08 1379.27 349.5 1378.73 349.42C1378.35 349.36 1377.68 349.72 1377.68 349.04C1377.68 348.8 1378.16 348.25 1378.81 348.24Z" fill="white"/>
                    <path d="M1198.5 350.42C1198.43 349.74 1199.08 349.55 1199.41 349.14C1199.5 349.39 1199.67 349.63 1199.66 349.88C1199.66 350.29 1199.85 350.87 1199.08 350.89C1198.73 350.89 1198.59 350.73 1198.49 350.42H1198.5Z" fill="white"/>
                    <path d="M1376.1 315.46C1376.49 315.38 1376.07 315.36 1376.28 315.67C1376.39 315.84 1376.38 316.26 1376.25 316.4C1375.89 316.77 1375.6 317.37 1374.9 317.17C1374.82 317.15 1374.7 317.03 1374.71 316.99C1374.93 316.26 1375.67 316 1376.09 315.46H1376.1Z" fill="white"/>
                    <path d="M1342.74 272.34C1343.15 272 1343.45 271.37 1344.12 271.62C1344.28 271.68 1344.43 272.11 1344.38 272.33C1344.24 273.02 1343.65 272.68 1343.23 272.74C1342.95 272.78 1342.72 272.7 1342.74 272.35V272.34Z" fill="white"/>
                    <path d="M1267 217.28C1267 217.09 1266.96 216.88 1267.03 216.71C1267.07 216.6 1267.31 216.46 1267.39 216.49C1267.68 216.61 1267.67 218.41 1267.37 218.59C1267.3 218.63 1267 218.49 1267 218.42C1266.97 218.04 1267 217.66 1267 217.27V217.28Z" fill="white"/>
                    <path d="M1425.4 224.56C1425.37 223.96 1425.73 223.87 1426.13 223.93C1426.42 223.97 1426.59 224.14 1426.58 224.51C1426.57 225.05 1426.24 225.13 1425.84 225.08C1425.56 225.04 1425.3 224.89 1425.4 224.57V224.56Z" fill="white"/>
                    <path d="M1190.62 333.06C1190.71 332.76 1190.85 332.58 1191.21 332.59C1191.75 332.6 1191.83 332.93 1191.78 333.33C1191.74 333.62 1191.57 333.79 1191.2 333.78C1190.66 333.77 1190.62 333.44 1190.62 333.05V333.06Z" fill="white"/>
                    <path d="M1225.43 121.33C1225.52 121.03 1225.66 120.85 1226.02 120.86C1226.56 120.87 1226.64 121.2 1226.59 121.6C1226.55 121.89 1226.38 122.06 1226.01 122.05C1225.47 122.04 1225.43 121.71 1225.43 121.32V121.33Z" fill="white"/>
                    <path d="M1336.07 111.73C1336.43 111.74 1336.76 111.8 1336.76 112.34C1336.76 112.7 1336.58 112.87 1336.29 112.9C1335.89 112.94 1335.56 112.85 1335.57 112.31C1335.57 111.95 1335.75 111.82 1336.08 111.74L1336.07 111.73Z" fill="white"/>
                    <path d="M1151.79 237.05C1151.3 236.82 1150.91 236.51 1150.85 235.94C1150.83 235.79 1151.02 235.48 1151.03 235.48C1151.55 235.65 1151.93 235.97 1151.99 236.54C1152 236.69 1151.87 236.86 1151.8 237.04L1151.79 237.05Z" fill="white"/>
                    <path d="M1192.32 140.72C1192.26 140.38 1192.49 140.21 1192.78 140.18C1193.18 140.14 1193.51 140.23 1193.5 140.77C1193.5 141.13 1193.32 141.3 1193.03 141.33C1192.63 141.37 1192.28 141.27 1192.32 140.71V140.72Z" fill="white"/>
                    <path d="M1215.43 254.53C1215.52 254.23 1215.66 254.05 1216.02 254.06C1216.56 254.07 1216.64 254.4 1216.59 254.8C1216.55 255.09 1216.38 255.26 1216.01 255.25C1215.47 255.24 1215.43 254.91 1215.43 254.52V254.53Z" fill="white"/>
                    <path d="M1412.54 287.95C1412.65 287.6 1412.78 287.42 1413.15 287.43C1413.69 287.44 1413.77 287.77 1413.72 288.17C1413.68 288.46 1413.51 288.63 1413.14 288.62C1412.58 288.62 1412.58 288.25 1412.54 287.94V287.95Z" fill="white"/>
                    <path d="M1389.06 292.28C1389.62 292.26 1389.7 292.61 1389.65 293.01C1389.61 293.3 1389.44 293.47 1389.07 293.46C1388.53 293.45 1388.45 293.12 1388.5 292.72C1388.54 292.43 1388.71 292.2 1389.05 292.28H1389.06Z" fill="white"/>
                    <path d="M1126.23 205.53C1126.21 205.91 1126.19 206.3 1125.65 206.24C1125.48 206.22 1125.33 206.12 1125.16 206.06C1125.42 205.73 1125.68 205.41 1125.94 205.08C1126.04 205.23 1126.13 205.38 1126.23 205.53Z" fill="white"/>
                    <path d="M1175.69 187.39C1175.71 187.01 1175.73 186.62 1176.27 186.68C1176.44 186.7 1176.59 186.8 1176.76 186.86C1176.5 187.19 1176.24 187.51 1175.98 187.84C1175.88 187.69 1175.79 187.54 1175.69 187.39Z" fill="white"/>
                    <path d="M1207.36 318.51C1207.64 318.23 1207.9 317.97 1208.16 317.7C1208.25 317.94 1208.44 318.19 1208.41 318.43C1208.36 318.94 1207.93 318.91 1207.57 318.84C1207.47 318.82 1207.41 318.61 1207.35 318.51H1207.36Z" fill="white"/>
                    <path d="M1284.15 171.04C1284.36 171.12 1284.66 171.19 1284.66 171.25C1284.66 171.76 1284.13 171.88 1283.89 172.22C1283.8 172.07 1283.64 171.93 1283.62 171.77C1283.58 171.37 1283.69 171.04 1284.14 171.04H1284.15Z" fill="white"/>
                    <path d="M1311.6 83C1311.68 82.88 1311.76 82.67 1311.86 82.66C1312.23 82.61 1312.66 82.59 1312.68 83.11C1312.68 83.34 1312.5 83.59 1312.39 83.83C1312.13 83.56 1311.87 83.29 1311.6 83.01V83Z" fill="white"/>
                    <path d="M1293.54 105.71C1293.01 105.72 1292.96 105.35 1293.01 104.97C1293.03 104.81 1293.2 104.68 1293.3 104.53C1293.55 104.87 1293.8 105.2 1294.04 105.54C1293.88 105.6 1293.71 105.65 1293.54 105.71Z" fill="white"/>
                    <path d="M1217.71 297.13C1217.75 297.06 1217.82 296.85 1217.91 296.83C1218.27 296.78 1218.71 296.76 1218.74 297.26C1218.76 297.49 1218.56 297.74 1218.47 297.98C1218.22 297.7 1217.98 297.43 1217.71 297.13Z" fill="white"/>
                    <path d="M1220.02 259.38C1220.01 258.85 1220.38 258.8 1220.76 258.85C1220.92 258.87 1221.05 259.04 1221.2 259.14C1220.86 259.39 1220.53 259.64 1220.19 259.88C1220.13 259.72 1220.08 259.55 1220.02 259.38Z" fill="white"/>
                    <path d="M1246.97 94.24C1247.07 94.09 1247.16 93.94 1247.25 93.79C1247.51 94.11 1247.78 94.43 1248.04 94.75C1247.88 94.81 1247.72 94.92 1247.56 94.93C1247.04 94.98 1246.97 94.63 1246.97 94.23V94.24Z" fill="white"/>
                    <path d="M1382.94 274.62C1382.41 274.63 1382.36 274.26 1382.41 273.88C1382.43 273.72 1382.6 273.59 1382.7 273.44C1382.95 273.78 1383.2 274.11 1383.44 274.45C1383.28 274.51 1383.11 274.56 1382.94 274.62Z" fill="white"/>
                    <path d="M1423.08 273.04C1422.96 272.83 1422.81 272.68 1422.8 272.52C1422.76 272.11 1422.88 271.77 1423.4 271.84C1423.56 271.86 1423.72 271.97 1423.88 272.04C1423.62 272.36 1423.36 272.69 1423.09 273.03L1423.08 273.04Z" fill="white"/>
                    <path d="M1231.52 373.32C1231.72 372.98 1232.04 373.11 1232.29 373.13C1232.37 373.13 1232.53 373.41 1232.49 373.49C1232.33 373.82 1232 373.69 1231.74 373.66C1231.65 373.66 1231.59 373.43 1231.52 373.31V373.32Z" fill="white"/>
                    <path d="M1218.62 351.31C1218.53 351 1218.42 350.75 1218.41 350.5C1218.41 350.35 1218.59 350.21 1218.69 350.06C1218.78 350.3 1218.93 350.54 1218.95 350.79C1218.96 350.93 1218.77 351.08 1218.62 351.31Z" fill="white"/>
                    <path d="M1402.46 171.06C1402.66 170.72 1402.98 170.85 1403.23 170.87C1403.31 170.87 1403.47 171.15 1403.43 171.23C1403.27 171.56 1402.94 171.43 1402.68 171.4C1402.59 171.4 1402.53 171.17 1402.46 171.05V171.06Z" fill="white"/>
                    <path d="M1320.51 99.41C1320.82 99.32 1321.07 99.21 1321.32 99.2C1321.47 99.2 1321.61 99.38 1321.76 99.48C1321.52 99.57 1321.28 99.72 1321.03 99.74C1320.89 99.75 1320.74 99.56 1320.51 99.41Z" fill="white"/>
                    <path d="M1317.38 99.74C1317.53 99.76 1317.8 99.74 1318.04 99.82C1318.32 99.91 1318.32 100.12 1318.04 100.2C1317.8 100.26 1317.52 100.26 1317.28 100.19C1317 100.11 1317.01 99.93 1317.39 99.75L1317.38 99.74Z" fill="white"/>
                    <path d="M1410.25 155.19C1410.09 155.12 1409.81 155.07 1409.79 154.98C1409.74 154.64 1410 154.56 1410.28 154.61C1410.45 154.64 1410.72 154.72 1410.74 154.81C1410.79 155.15 1410.54 155.21 1410.25 155.18V155.19Z" fill="white"/>
                    <path d="M1194.81 327.98C1194.88 327.82 1194.93 327.54 1195.02 327.52C1195.36 327.47 1195.44 327.73 1195.39 328.01C1195.36 328.18 1195.28 328.45 1195.19 328.47C1194.85 328.52 1194.79 328.27 1194.82 327.98H1194.81Z" fill="white"/>
                    <path d="M1242.37 103.7C1242.28 103.39 1242.17 103.14 1242.16 102.89C1242.16 102.74 1242.34 102.6 1242.44 102.45C1242.53 102.69 1242.68 102.93 1242.7 103.18C1242.71 103.32 1242.52 103.47 1242.37 103.7Z" fill="white"/>
                    <path d="M1351.49 335.29C1351.56 335.18 1351.66 335.04 1351.76 334.89C1351.85 335.13 1352 335.37 1352.02 335.62C1352.03 335.76 1351.84 335.91 1351.74 336.06C1351.51 335.87 1351.4 335.63 1351.49 335.29Z" fill="white"/>
                    <path d="M1197.63 105.16C1197.92 105.14 1198.17 105.21 1198.1 105.55C1198.08 105.64 1197.8 105.71 1197.64 105.74C1197.36 105.78 1197.1 105.7 1197.17 105.36C1197.19 105.27 1197.47 105.23 1197.63 105.16Z" fill="white"/>
                    <path d="M1353.33 295.73C1353.62 295.71 1353.87 295.78 1353.8 296.12C1353.78 296.21 1353.5 296.28 1353.34 296.31C1353.06 296.35 1352.8 296.27 1352.87 295.93C1352.89 295.84 1353.17 295.8 1353.33 295.73Z" fill="white"/>
                    <path d="M1344.71 286.8C1345 286.78 1345.25 286.85 1345.18 287.19C1345.16 287.28 1344.88 287.35 1344.72 287.38C1344.44 287.42 1344.18 287.34 1344.25 287C1344.27 286.91 1344.55 286.87 1344.71 286.8Z" fill="white"/>
                    <path d="M1308.61 258.74C1308.52 258.43 1308.41 258.18 1308.4 257.93C1308.4 257.79 1308.58 257.64 1308.68 257.49C1308.77 257.73 1308.92 257.97 1308.94 258.22C1308.95 258.36 1308.76 258.51 1308.61 258.74Z" fill="white"/>
                    <path d="M1213.29 306.16C1213.39 305.97 1213.52 305.74 1213.64 305.51C1213.73 305.66 1213.92 305.82 1213.9 305.96C1213.87 306.21 1213.72 306.44 1213.61 306.68C1213.51 306.53 1213.42 306.38 1213.28 306.16H1213.29Z" fill="white"/>
                    <path d="M1239.54 216.87C1239.61 216.76 1239.71 216.62 1239.81 216.47C1239.9 216.71 1240.05 216.95 1240.07 217.2C1240.08 217.34 1239.89 217.49 1239.79 217.64C1239.56 217.45 1239.45 217.21 1239.54 216.87Z" fill="white"/>
                    <path d="M1194.85 208.73C1194.76 208.42 1194.65 208.17 1194.64 207.92C1194.64 207.77 1194.82 207.63 1194.92 207.48C1195.01 207.72 1195.16 207.96 1195.18 208.21C1195.19 208.35 1195 208.5 1194.85 208.73Z" fill="white"/>
                    <path d="M1124.72 196.5C1124.92 196.16 1125.24 196.29 1125.49 196.31C1125.57 196.31 1125.73 196.59 1125.69 196.67C1125.53 197 1125.2 196.87 1124.94 196.84C1124.85 196.84 1124.79 196.61 1124.72 196.49V196.5Z" fill="white"/>
                    <path d="M1182.44 169.23C1182.73 169.21 1182.98 169.28 1182.91 169.62C1182.89 169.71 1182.61 169.78 1182.45 169.81C1182.17 169.85 1181.91 169.77 1181.98 169.43C1182 169.34 1182.28 169.3 1182.44 169.23Z" fill="white"/>
                    <path d="M1387.55 181.69C1387.39 181.62 1387.11 181.57 1387.09 181.48C1387.04 181.14 1387.3 181.06 1387.58 181.11C1387.75 181.14 1388.02 181.22 1388.04 181.31C1388.09 181.65 1387.84 181.71 1387.55 181.68V181.69Z" fill="white"/>
                    <path d="M1293.13 195.19C1293.2 195.03 1293.25 194.75 1293.34 194.73C1293.68 194.68 1293.76 194.94 1293.71 195.22C1293.68 195.39 1293.6 195.66 1293.51 195.68C1293.17 195.73 1293.11 195.48 1293.14 195.19H1293.13Z" fill="white"/>
                    <path d="M1292.53 179.98C1292.47 179.85 1292.38 179.72 1292.35 179.58C1292.35 179.53 1292.47 179.45 1292.54 179.39C1292.6 179.52 1292.69 179.65 1292.72 179.79C1292.72 179.84 1292.6 179.92 1292.53 179.98Z" fill="white"/>
                    <path d="M1243.94 84.34C1243.88 84.21 1243.79 84.08 1243.76 83.94C1243.76 83.89 1243.88 83.81 1243.95 83.75C1244.01 83.88 1244.1 84.01 1244.13 84.15C1244.13 84.2 1244.01 84.28 1243.94 84.34Z" fill="white"/>
                    <path d="M1333.17 283.46C1333.24 283.34 1333.31 283.21 1333.38 283.09C1333.44 283.16 1333.57 283.24 1333.56 283.29C1333.53 283.43 1333.44 283.55 1333.37 283.68C1333.31 283.61 1333.24 283.55 1333.17 283.47V283.46Z" fill="white"/>
                    <path d="M1376.62 297.7C1376.54 297.78 1376.47 297.84 1376.41 297.9C1376.35 297.77 1376.26 297.64 1376.23 297.5C1376.23 297.45 1376.35 297.37 1376.42 297.31C1376.49 297.44 1376.56 297.57 1376.62 297.69V297.7Z" fill="white"/>
                    <path d="M1291.5 297.63C1291.62 297.7 1291.75 297.77 1291.87 297.84C1291.8 297.9 1291.72 298.03 1291.67 298.02C1291.53 297.99 1291.41 297.9 1291.28 297.83C1291.35 297.77 1291.41 297.7 1291.49 297.63H1291.5Z" fill="white"/>
                    <path d="M1260.9 263.95C1261.03 263.89 1261.16 263.8 1261.3 263.77C1261.35 263.77 1261.43 263.89 1261.49 263.96C1261.36 264.02 1261.23 264.11 1261.09 264.14C1261.04 264.14 1260.96 264.02 1260.9 263.95Z" fill="white"/>
                    <path d="M1213.41 258.21C1213.49 258.29 1213.55 258.36 1213.61 258.42C1213.48 258.48 1213.35 258.57 1213.21 258.6C1213.16 258.6 1213.08 258.48 1213.02 258.41C1213.15 258.34 1213.28 258.27 1213.4 258.21H1213.41Z" fill="white"/>
                    <path d="M1193.4 313.71C1193.47 313.59 1193.54 313.46 1193.61 313.34C1193.67 313.41 1193.8 313.49 1193.79 313.54C1193.76 313.68 1193.67 313.8 1193.6 313.93C1193.54 313.86 1193.47 313.8 1193.4 313.72V313.71Z" fill="white"/>
                    <path d="M1310.64 299.85C1310.72 299.77 1310.79 299.71 1310.85 299.65C1310.91 299.78 1311 299.91 1311.03 300.05C1311.03 300.1 1310.91 300.18 1310.84 300.24C1310.77 300.11 1310.7 299.98 1310.64 299.86V299.85Z" fill="white"/>
                    <path d="M1274.18 236.19C1274.1 236.27 1274.03 236.33 1273.97 236.39C1273.91 236.26 1273.82 236.13 1273.79 235.99C1273.79 235.94 1273.91 235.86 1273.98 235.8C1274.05 235.93 1274.12 236.06 1274.18 236.18V236.19Z" fill="white"/>
                    <path d="M1133.19 222.12C1133.12 222.05 1133 221.95 1133.01 221.93C1133.08 221.8 1133.16 221.65 1133.28 221.59C1133.53 221.47 1133.65 221.59 1133.52 221.84C1133.46 221.96 1133.3 222.03 1133.18 222.12H1133.19Z" fill="white"/>
                    <path d="M1133.55 221.33C1133.67 221.24 1133.82 221.06 1133.9 221.08C1134.12 221.15 1134.16 221.33 1134 221.43C1133.87 221.51 1133.7 221.54 1133.55 221.59C1133.55 221.5 1133.55 221.42 1133.55 221.33Z" fill="white"/>
                    <path d="M1318.89 166.21C1318.96 166.09 1319.03 165.96 1319.1 165.84C1319.16 165.91 1319.29 165.99 1319.28 166.04C1319.25 166.18 1319.16 166.3 1319.09 166.43C1319.03 166.36 1318.96 166.3 1318.89 166.22V166.21Z" fill="white"/>
                    <path d="M1132.1 306.76C1132.17 306.64 1132.24 306.51 1132.31 306.39C1132.37 306.46 1132.5 306.54 1132.49 306.59C1132.46 306.73 1132.37 306.85 1132.3 306.98C1132.24 306.91 1132.17 306.85 1132.1 306.77V306.76Z" fill="white"/>
                    <path d="M1127.28 217.01C1127.36 216.93 1127.43 216.87 1127.49 216.81C1127.55 216.94 1127.64 217.07 1127.67 217.21C1127.67 217.26 1127.55 217.34 1127.48 217.4C1127.41 217.27 1127.34 217.14 1127.28 217.02V217.01Z" fill="white"/>
                    <path d="M1173.87 316.7C1173.8 316.82 1173.73 316.95 1173.66 317.07C1173.6 317 1173.47 316.92 1173.48 316.87C1173.51 316.73 1173.6 316.61 1173.67 316.48C1173.73 316.55 1173.8 316.61 1173.87 316.69V316.7Z" fill="white"/>
                    <path d="M1223.84 182.05C1223.91 181.93 1223.98 181.8 1224.05 181.68C1224.11 181.75 1224.24 181.83 1224.23 181.88C1224.2 182.02 1224.11 182.14 1224.04 182.27C1223.98 182.2 1223.91 182.14 1223.84 182.06V182.05Z" fill="white"/>
                    <path d="M1263.93 311.76C1264.01 311.84 1264.07 311.91 1264.13 311.97C1264 312.03 1263.87 312.12 1263.73 312.15C1263.68 312.15 1263.6 312.03 1263.54 311.96C1263.67 311.89 1263.8 311.82 1263.92 311.76H1263.93Z" fill="white"/>
                    <path d="M1159.29 205.09C1159.23 204.96 1159.14 204.83 1159.11 204.69C1159.11 204.64 1159.23 204.56 1159.3 204.5C1159.36 204.63 1159.45 204.76 1159.48 204.9C1159.48 204.95 1159.36 205.03 1159.29 205.09Z" fill="white"/>
                    <path d="M1251.3 314.17C1251.37 314.05 1251.44 313.92 1251.51 313.8C1251.57 313.87 1251.7 313.95 1251.69 314C1251.66 314.14 1251.57 314.26 1251.5 314.39L1251.3 314.18V314.17Z" fill="white"/>
                    <path d="M1286.9 204.95C1286.78 204.88 1286.65 204.81 1286.53 204.74C1286.6 204.68 1286.68 204.55 1286.73 204.56C1286.87 204.59 1286.99 204.68 1287.12 204.75C1287.05 204.81 1286.99 204.88 1286.91 204.95H1286.9Z" fill="white"/>
                    <path d="M1410.13 146.41C1410.07 146.28 1409.98 146.15 1409.95 146.01C1409.95 145.96 1410.07 145.88 1410.14 145.82C1410.2 145.95 1410.29 146.08 1410.32 146.22C1410.32 146.27 1410.2 146.35 1410.13 146.41Z" fill="white"/>
                    <path d="M1342.04 115.71C1343.78 115.06 1344.81 116.07 1345.77 117.04C1346.12 117.4 1346.58 118.02 1346.83 118.14C1348.41 118.89 1346.81 120.2 1347.48 121.03C1346.57 121.55 1345.66 122.49 1344.75 122.49C1343.89 122.49 1342.81 122.39 1341.94 121.96C1341.12 121.55 1339.79 121.36 1340.67 119.83C1340.8 119.6 1340.47 119.13 1340.39 118.76C1340.04 117.13 1340.64 116.1 1342.04 115.7V115.71Z" fill="white"/>
                    <path d="M1280.98 252.48C1280.69 251.42 1281.32 250.44 1281.69 250.34C1283.13 249.96 1283.34 248.49 1284.35 247.76C1285.07 247.24 1285.58 247.04 1286.3 247.5C1287.49 248.26 1287.77 248.84 1287.41 249.8C1287.25 250.22 1287.15 250.63 1287.05 251.07C1286.64 252.95 1283.12 254.67 1281.51 253.15C1281.22 252.88 1280.98 252.66 1280.97 252.48H1280.98Z" fill="white"/>
                    <path d="M1361.51 213.63C1362.01 212.8 1361.42 211.52 1362.68 211.06C1362.86 210.99 1363.09 211 1363.22 210.89C1363.74 210.41 1364.13 209.71 1365.01 209.89C1365.7 210.03 1365.98 210.67 1366.4 211.12C1367.23 212.02 1367.13 213.75 1366.25 214.76C1365.56 215.55 1364.75 215.75 1363.71 215.51C1362.63 215.27 1362.23 214.3 1361.53 213.63H1361.51Z" fill="white"/>
                    <path d="M1453.09 310.06C1453.54 311.64 1453.17 312.53 1452.07 312.99C1451.46 313.24 1450.8 313.35 1450.17 313.57C1449.58 313.78 1449.32 313.57 1449.19 313C1449.04 312.36 1448.73 311.74 1448.66 311.09C1448.54 309.99 1449.06 309.09 1449.88 308.39C1450.05 308.25 1450.44 308.17 1450.62 308.27C1451.26 308.64 1451.97 308.75 1452.64 308.98C1452.97 309.09 1453.04 309.96 1453.09 310.07V310.06Z" fill="white"/>
                    <path d="M1340.08 361.55C1339.81 360.85 1339.41 359.87 1340.44 359.25C1340.92 358.96 1341.83 358.4 1342.22 359C1342.64 359.64 1343.65 360.02 1343.45 361.01C1343.31 361.7 1342.92 362.38 1342.94 363.06C1342.96 363.68 1342.81 363.85 1342.28 364.02C1341.52 364.25 1340.98 363.93 1340.64 363.37C1340.36 362.9 1340.3 362.3 1340.08 361.56V361.55Z" fill="white"/>
                    <path d="M1473.23 305.95C1473.01 305.24 1473.39 304.46 1474.09 304.21C1474.32 304.13 1476.94 304.75 1477.08 304.92C1477.41 305.31 1476.91 306.97 1476.84 306.91C1475.93 306.14 1475.76 307.59 1475.1 307.43C1474.48 307.28 1473.37 306.41 1473.23 305.95Z" fill="white"/>
                    <path d="M1390.57 159.05C1390.19 159.15 1389.64 159.42 1389.53 159.05C1389.33 158.39 1388.81 157.74 1389.25 156.92C1389.5 156.47 1390 156.39 1390.21 156.02C1390.55 155.42 1390.86 155.24 1391.53 155.57C1392.26 155.92 1392.54 156.56 1392.32 157.13C1392.01 157.95 1391.72 158.97 1390.57 159.04V159.05Z" fill="white"/>
                    <path d="M1338.64 242.82C1338.77 243.4 1339.28 244.06 1338.18 244.33C1337.93 244.39 1337.82 244.96 1337.62 245.28C1337.39 245.64 1337.14 245.98 1336.89 246.33C1336.65 245.74 1336.16 245.12 1336.24 244.57C1336.34 243.87 1336.92 243.25 1337.25 242.58C1337.43 242.21 1337.67 241.9 1338.07 241.95C1338.51 242.01 1338.52 242.47 1338.63 242.82H1338.64Z" fill="white"/>
                    <path d="M1481.27 191.27C1481.32 191.45 1481.39 191.62 1481.42 191.8C1481.48 192.21 1481.94 192.69 1481.36 193.01C1480.77 193.34 1480.16 193.31 1479.57 192.88C1479.07 192.52 1479.19 192.17 1479.4 191.71C1479.53 191.41 1479.41 191.01 1479.54 190.71C1479.71 190.31 1479.83 189.78 1480.4 189.76C1481.08 189.73 1480.94 190.36 1481.11 190.74C1481.19 190.91 1481.22 191.09 1481.27 191.27Z" fill="white"/>
                    <path d="M1469.57 315.6C1470.42 315.8 1471.36 315.75 1472.08 316.35C1472.24 316.49 1472.36 316.83 1472.15 317.11C1471.89 317.47 1471.82 318.11 1471.25 318.04C1470.56 317.96 1469.88 317.73 1469.22 317.49C1468.44 317.21 1469.34 316.79 1469.18 316.42C1469.01 316.04 1469.05 315.7 1469.57 315.58V315.6Z" fill="white"/>
                    <path d="M1322.98 199.29C1323.2 199.76 1323.13 200.11 1322.51 200.25C1321.56 200.48 1320.89 200.13 1320.6 199.31C1320.35 198.58 1320.69 198.03 1321.21 197.69C1321.81 197.29 1322.65 198.08 1322.97 199.29H1322.98Z" fill="white"/>
                    <path d="M1241.91 173.33C1241.99 173.6 1242.12 173.87 1242.14 174.14C1242.18 174.79 1241.51 175.2 1240.94 174.89C1240.61 174.71 1240.23 174.58 1239.96 174.33C1239.61 174.01 1239.42 173.48 1239.59 173.08C1239.78 172.63 1240.49 172.54 1240.66 171.98C1241.09 172.3 1241.78 172.32 1241.83 173.05C1241.83 173.14 1241.88 173.23 1241.91 173.32V173.33Z" fill="white"/>
                    <path d="M1447.85 267.7C1448.01 267.41 1448.16 267.17 1448.28 266.92C1448.58 266.28 1449.55 266.67 1449.79 265.89C1449.91 265.47 1450.32 265.36 1450.68 265.6C1451.02 265.83 1451.01 266.2 1450.79 266.56C1450.5 267.04 1450.28 267.56 1449.97 268.02C1449.82 268.24 1449.86 268.83 1449.29 268.49C1448.82 268.21 1448.34 267.96 1447.87 267.7H1447.85Z" fill="white"/>
                    <path d="M1408.17 377.97C1407.57 378.05 1406.78 377.31 1406.72 376.63C1406.7 376.36 1407.83 375.03 1408.17 375.02C1409.01 375.01 1408.83 375.78 1408.95 376.23C1409.18 377.06 1408.75 377.89 1408.17 377.97Z" fill="white"/>
                    <path d="M1348.91 325.92C1349.44 325.68 1349.81 325.7 1349.97 326.33C1350.16 327.1 1349.85 328.01 1349.31 328.16C1348.77 328.32 1347.99 327.71 1347.78 326.95C1347.46 325.81 1348.52 326.2 1348.92 325.92H1348.91Z" fill="white"/>
                    <path d="M1342.22 324.15C1342.36 323.65 1342.48 323.09 1343.04 322.89C1343.65 322.67 1344.59 323.55 1344.65 324.33C1344.69 324.84 1344.32 324.91 1343.97 324.98C1343.15 325.14 1342.32 324.75 1342.23 324.16L1342.22 324.15Z" fill="white"/>
                    <path d="M1422.64 291C1422.74 291.35 1422.78 291.54 1422.85 291.71C1423.13 292.34 1422.57 292.72 1422.39 293.21C1421.98 292.89 1421.31 292.87 1421.2 292.19C1421.17 292.01 1421.11 291.83 1421.05 291.66C1420.83 291.07 1420.79 290.67 1421.59 290.45C1422.36 290.24 1422.62 290.49 1422.64 291Z" fill="white"/>
                    <path d="M1262.01 319.84C1262.64 319.61 1262.89 320 1262.84 320.35C1262.74 321.05 1262.52 321.73 1262.29 322.4C1262.08 323.03 1261.74 322.53 1261.47 322.43C1260.29 321.99 1261.34 321.18 1261.18 320.55C1260.99 319.82 1261.79 320.05 1262.01 319.84Z" fill="white"/>
                    <path d="M1394.57 310.61C1394.21 309.91 1394.55 309.34 1395.07 308.98C1395.45 308.71 1396.8 309.49 1396.72 309.87C1396.56 310.6 1396.07 311.03 1395.31 311.19C1394.77 311.3 1394.69 310.97 1394.58 310.61H1394.57Z" fill="white"/>
                    <path d="M1438.28 143.35C1438.84 143.29 1439.26 143.62 1439.61 144.02C1439.82 144.25 1438.88 145.49 1438.33 145.71C1437.84 145.9 1437.69 145.57 1437.59 145.2C1437.39 144.45 1437.72 143.53 1438.27 143.36L1438.28 143.35Z" fill="white"/>
                    <path d="M1432.76 322.18C1432.47 322.18 1432.15 322.06 1432.22 321.74C1432.38 320.96 1432.34 320.14 1432.79 319.42C1432.98 319.12 1433.27 319.01 1433.48 319.06C1433.68 319.11 1433.97 319.47 1433.94 319.65C1433.82 320.35 1433.6 321.02 1433.39 321.7C1433.3 321.99 1433.08 322.15 1432.76 322.19V322.18Z" fill="white"/>
                    <path d="M1452.79 345.6C1452.68 345.32 1452.54 344.97 1452.98 344.74C1453.37 344.54 1453.65 344.82 1453.99 344.93C1454.67 345.16 1454.66 345.79 1454.73 346.28C1454.77 346.57 1454.43 347.18 1454.25 347.19C1453.88 347.19 1453.42 346.98 1453.15 346.7C1452.92 346.47 1452.92 346.02 1452.8 345.6H1452.79Z" fill="white"/>
                    <path d="M1297.8 356.23C1297.38 356.43 1297.03 356.35 1296.89 355.74C1296.71 354.97 1297.02 354.09 1297.56 353.92C1298.46 353.63 1298.17 354.95 1298.93 354.96C1299.06 354.96 1298.48 355.97 1297.8 356.23Z" fill="white"/>
                    <path d="M1427.08 237.14C1427.14 236.98 1427.16 236.74 1427.28 236.68C1427.92 236.31 1429.44 236.93 1429.58 237.62C1429.63 237.86 1429.63 238.22 1429.49 238.35C1429.34 238.48 1428.97 238.48 1428.75 238.4C1427.77 238.03 1427.22 237.59 1427.08 237.14Z" fill="white"/>
                    <path d="M1360.51 154.78C1360.24 154.86 1359.98 154.99 1359.71 155.01C1359.01 155.06 1359.15 154.43 1358.99 154.05C1358.78 153.56 1359.12 153.41 1359.5 153.3C1359.84 153.21 1360.17 153.04 1360.54 153.28C1360.92 153.53 1361.45 153.67 1361.38 154.24C1361.33 154.68 1360.85 154.67 1360.51 154.78Z" fill="white"/>
                    <path d="M1430.5 319.96C1430.55 320.55 1430.16 320.61 1429.81 320.67C1428.97 320.82 1428.16 320.43 1428.09 319.84C1428.03 319.34 1428.43 319.27 1428.79 319.2C1429.63 319.05 1430.41 319.44 1430.5 319.96Z" fill="white"/>
                    <path d="M1299.62 364.01C1299.06 363.67 1298.45 363.5 1298.31 362.71C1298.2 362.06 1298.5 361.95 1298.98 361.8C1299.5 361.63 1299.76 361.67 1300.01 362.25C1300.34 363 1299.83 363.44 1299.62 364.01Z" fill="white"/>
                    <path d="M1292.19 351.79C1292.24 351.62 1292.25 351.36 1292.35 351.31C1292.68 351.16 1293.1 350.93 1293.39 351.02C1293.87 351.17 1294.49 351.3 1294.6 351.96C1294.69 352.47 1294.28 352.54 1293.92 352.61C1293.07 352.76 1292.32 352.4 1292.18 351.8L1292.19 351.79Z" fill="white"/>
                    <path d="M1392.74 133.69C1392.64 133.31 1392.54 132.91 1393.07 132.85C1393.75 132.77 1394.38 133.04 1394.49 133.73C1394.55 134.1 1394.18 134.53 1394 134.94C1393.48 134.62 1392.77 134.49 1392.74 133.69Z" fill="white"/>
                    <path d="M1265.97 213.76C1266.63 213.61 1267.53 214.17 1267.58 214.64C1267.64 215.14 1267.24 215.17 1266.9 215.29C1266.22 215.53 1265.66 215.29 1265.4 214.73C1265.32 214.56 1265.62 213.87 1265.97 213.77V213.76Z" fill="white"/>
                    <path d="M1378.91 323.97C1378.78 323.52 1378.66 323.03 1379.17 323.04C1379.8 323.04 1380.42 323.29 1381.05 323.42C1380.78 324.01 1380.49 324.52 1379.73 324.64C1379.01 324.76 1379.05 324.25 1378.92 323.96L1378.91 323.97Z" fill="white"/>
                    <path d="M1243.31 281.72C1243.01 281 1243.36 280.85 1243.86 280.66C1244.64 280.36 1244.8 280.77 1244.98 281.38C1245.17 282 1244.88 282.15 1244.36 282.33C1243.6 282.6 1243.32 282.3 1243.3 281.72H1243.31Z" fill="white"/>
                    <path d="M1390.52 327.39C1391.02 327.34 1391.69 326.75 1391.82 327.7C1391.89 328.22 1391.62 328.77 1391.07 329C1390.16 329.37 1390.13 328.29 1389.77 327.96C1389.32 327.54 1390.3 327.65 1390.52 327.39Z" fill="white"/>
                    <path d="M1470.31 352.83C1470.23 352.56 1470.11 352.3 1470.09 352.03C1470.04 351.54 1470.44 351.47 1470.79 351.4C1471.04 351.35 1471.31 351.12 1471.56 351.37C1471.85 351.66 1471.88 351.98 1471.65 352.33C1471.55 352.48 1471.48 352.65 1471.38 352.81C1471.2 353.11 1471.02 353.66 1470.83 353.66C1470.41 353.67 1470.44 353.14 1470.31 352.82V352.83Z" fill="white"/>
                    <path d="M1285.01 163.17C1284.33 163.17 1284.44 162.57 1284.31 162.18C1284.07 161.46 1284.71 161.51 1285.1 161.37C1285.46 161.24 1285.84 161.17 1285.94 161.7C1286.04 162.25 1285.5 163.09 1285.02 163.16L1285.01 163.17Z" fill="white"/>
                    <path d="M1321.35 308.45C1321.83 308.33 1322.2 308.23 1322.31 308.77C1322.41 309.32 1321.87 310.18 1321.39 310.23C1320.89 310.27 1320.89 309.85 1320.75 309.52C1320.39 308.73 1320.87 308.55 1321.36 308.46L1321.35 308.45Z" fill="white"/>
                    <path d="M1425.52 342.71C1425.33 342.71 1424.95 342.82 1424.84 342.7C1424.54 342.36 1424.95 342.04 1425.12 341.85C1425.39 341.57 1425.19 340.55 1426.11 341.12C1426.41 341.31 1426.8 341.43 1426.8 341.86C1426.8 342.73 1425.97 342.34 1425.52 342.71Z" fill="white"/>
                    <path d="M1464.1 201.49C1464.25 201.07 1464.28 200.26 1464.81 200.46C1465.27 200.63 1465.91 200.17 1466.16 200.8C1466.31 201.18 1465.14 202.1 1464.6 202.05C1464.32 202.02 1464.14 201.82 1464.1 201.49Z" fill="white"/>
                    <path d="M1259.6 274.9C1259.6 274.9 1259.22 274.57 1258.92 274.22C1258.79 274.07 1259.61 273.06 1259.88 273.05C1260.58 273 1260.44 273.62 1260.6 273.99C1260.79 274.44 1260.53 274.64 1259.6 274.9Z" fill="white"/>
                    <path d="M1254.76 241.77C1254.76 241.09 1255.36 241.22 1255.73 241.06C1256.2 240.86 1256.49 241.18 1256.45 241.55C1256.4 241.97 1256.16 242.39 1255.93 242.76C1255.85 242.89 1254.79 242.02 1254.77 241.77H1254.76Z" fill="white"/>
                    <path d="M1358.97 103.69C1359.41 103.46 1359.67 103.62 1359.74 103.88C1359.94 104.58 1359.6 105.08 1359.01 105.42C1358.7 105.6 1358.45 105.42 1358.37 105.16C1358.14 104.44 1358.54 103.98 1358.98 103.69H1358.97Z" fill="white"/>
                    <path d="M1447.85 243.12C1447.25 243.2 1446.72 242.96 1446.58 242.5C1446.51 242.27 1446.78 241.63 1447.32 241.49C1448.04 241.3 1447.98 241.9 1448.13 242.28C1448.27 242.64 1448.31 242.99 1447.85 243.13V243.12Z" fill="white"/>
                    <path d="M1276.11 146.8C1276.35 146.69 1277.61 147.12 1277.54 147.27C1277.35 147.66 1277.12 148.09 1276.79 148.35C1276.48 148.59 1276.09 148.47 1276.01 147.99C1275.94 147.59 1275.5 147.15 1276.11 146.81V146.8Z" fill="white"/>
                    <path d="M1451.01 247.97C1451.02 247.44 1451.2 246.86 1451.93 246.67C1452.2 246.6 1452.48 246.72 1452.49 247.07C1452.52 247.76 1452.28 248.31 1451.59 248.51C1451.33 248.59 1451.06 248.46 1451.01 247.96V247.97Z" fill="white"/>
                    <path d="M1514.39 243.87C1514.21 243.38 1514.51 243.09 1514.88 243.14C1515.3 243.19 1515.72 243.43 1516.09 243.66C1516.22 243.74 1515.35 244.8 1515.1 244.82C1514.74 244.85 1514.47 244.48 1514.39 243.87Z" fill="white"/>
                    <path d="M1387.71 323.67C1387.61 323.29 1387.5 322.91 1388.02 322.8C1388.59 322.68 1389.12 322.95 1389.26 323.42C1389.33 323.65 1389.06 324.29 1388.52 324.43C1387.79 324.62 1387.88 324.01 1387.71 323.67Z" fill="white"/>
                    <path d="M1477.55 322.56C1477.66 322.04 1477.81 321.57 1478.34 321.34C1478.65 321.21 1478.92 321.35 1478.97 321.62C1479.04 322.07 1479.66 322.64 1478.8 322.98C1478.28 323.19 1477.99 322.66 1477.55 322.55V322.56Z" fill="white"/>
                    <path d="M1393.81 312.06C1393.87 311.9 1393.93 311.74 1393.99 311.58C1394.71 311.96 1395.39 312.47 1396.28 312.4C1396.32 312.4 1396.43 312.74 1396.42 312.92C1396.42 313.02 1396.14 313.2 1396.11 313.18C1395.49 312.75 1394.61 313.14 1394.06 312.53C1393.94 312.4 1393.89 312.22 1393.81 312.06Z" fill="white"/>
                    <path d="M1397.68 309.92C1398.23 309.8 1398.13 310.58 1398.21 311C1398.37 311.85 1397.53 311.62 1397.11 311.82C1396.99 311.88 1396.41 311.73 1396.82 311.45C1397.42 311.04 1396.31 310.16 1397.67 309.91L1397.68 309.92Z" fill="white"/>
                    <path d="M1338.99 143.87C1338.37 144.17 1338.01 143.6 1337.52 143.43C1337.68 143.12 1337.79 142.59 1338.01 142.53C1338.51 142.4 1338.95 142.7 1339.21 143.2C1339.36 143.5 1339.21 143.7 1338.99 143.87Z" fill="white"/>
                    <path d="M1265.47 277.93C1265.87 277.88 1266.34 277.49 1266.57 278.21C1266.74 278.73 1266.4 278.86 1266.03 278.95C1265.63 279.04 1265.15 279.4 1264.92 278.68C1264.75 278.16 1265.1 278.05 1265.47 277.93Z" fill="white"/>
                    <path d="M1411.95 124.05C1412.22 123.16 1412.73 122.83 1413.41 122.76C1413.57 122.74 1413.74 122.85 1413.9 122.9C1413.81 123.06 1413.69 123.21 1413.64 123.38C1413.46 124.04 1412.88 124.11 1412.36 124.21C1412.2 124.24 1412 124.07 1411.94 124.04L1411.95 124.05Z" fill="white"/>
                    <path d="M1236.6 281.21C1236.55 281.08 1236.87 280.91 1237.26 280.83C1237.66 280.75 1238.14 280.36 1238.38 281.08C1238.55 281.59 1238.24 281.74 1237.86 281.83C1237.46 281.92 1236.97 282.28 1236.61 281.21H1236.6Z" fill="white"/>
                    <path d="M1295.57 293.54C1295.69 293.57 1295.92 293.58 1295.96 293.66C1296.2 294.25 1296.41 294.84 1295.92 295.45C1295.72 295.7 1295.57 295.82 1295.36 295.57C1295.03 295.19 1295.19 293.79 1295.58 293.54H1295.57Z" fill="white"/>
                    <path d="M1315.27 185.02C1315.24 184.12 1316.07 184.46 1316.5 184.25C1316.69 184.15 1316.96 184.51 1316.97 184.8C1316.97 185.65 1316.19 185.41 1315.74 185.58C1315.53 185.66 1315.29 185.33 1315.27 185.02Z" fill="white"/>
                    <path d="M1360.07 285.76C1360.04 284.86 1360.87 285.2 1361.3 284.99C1361.49 284.89 1361.76 285.25 1361.77 285.54C1361.77 286.39 1360.99 286.15 1360.54 286.32C1360.33 286.4 1360.09 286.07 1360.07 285.76Z" fill="white"/>
                    <path d="M1442.67 189.09C1442.62 188.96 1442.94 188.79 1443.33 188.71C1443.73 188.63 1444.21 188.24 1444.45 188.96C1444.62 189.47 1444.31 189.62 1443.93 189.71C1443.53 189.8 1443.04 190.16 1442.68 189.09H1442.67Z" fill="white"/>
                    <path d="M1370.04 359.96C1370.46 360.38 1370.89 360.81 1371.31 361.25C1371.33 361.27 1371.25 361.44 1371.18 361.48C1370.83 361.67 1370.51 361.61 1370.17 361.4C1369.78 361.16 1369.35 360.97 1369.5 360.41C1369.54 360.28 1369.75 360.19 1370.04 359.96Z" fill="white"/>
                    <path d="M1412.03 328.82C1411.68 328.95 1411.3 329.12 1411.1 328.64C1410.84 328.03 1411.22 327.84 1411.73 327.72C1412.09 327.64 1412.45 327.44 1412.66 327.92C1412.92 328.53 1412.56 328.75 1412.04 328.82H1412.03Z" fill="white"/>
                    <path d="M1449.23 284.88C1449.23 284.36 1449.3 284.09 1449.65 284.08C1450.34 284.05 1450.87 284.29 1451.1 284.98C1451.14 285.11 1451.01 285.31 1450.96 285.47C1450.36 285.27 1449.76 285.06 1449.23 284.88Z" fill="white"/>
                    <path d="M1338.21 306.26C1338 305.8 1337.99 305.39 1338.23 305.35C1338.7 305.25 1339.23 304.63 1339.68 305.35C1339.84 305.59 1339.82 306 1339.58 306.07C1339.12 306.21 1338.6 306.65 1338.21 306.26Z" fill="white"/>
                    <path d="M1430.26 174.91C1430.17 174.53 1430.07 174.18 1430.6 174.04C1431.33 173.85 1431.29 174.44 1431.42 174.83C1431.55 175.19 1431.62 175.54 1431.09 175.68C1430.35 175.87 1430.43 175.26 1430.26 174.9V174.91Z" fill="white"/>
                    <path d="M1347.6 198.84C1346.7 198.87 1347.04 198.04 1346.83 197.61C1346.73 197.42 1347.09 197.15 1347.38 197.14C1348.23 197.14 1347.99 197.92 1348.16 198.37C1348.24 198.58 1347.91 198.82 1347.6 198.84Z" fill="white"/>
                    <path d="M1454.05 294.04C1453.7 294.17 1453.32 294.34 1453.12 293.86C1452.86 293.25 1453.24 293.06 1453.75 292.94C1454.11 292.86 1454.47 292.66 1454.68 293.14C1454.94 293.75 1454.58 293.97 1454.06 294.04H1454.05Z" fill="white"/>
                    <path d="M1306.55 351.27C1305.94 351.51 1305.74 351.37 1305.62 351.09C1305.37 350.47 1305.77 350.29 1306.26 350.2C1306.51 350.15 1307 349.95 1306.98 350.17C1306.94 350.63 1306.63 351.06 1306.54 351.27H1306.55Z" fill="white"/>
                    <path d="M1407.86 146.94C1407.88 146.79 1407.93 146.54 1407.97 146.29C1408.41 146.56 1409.05 146.6 1409.15 147.25C1409.2 147.53 1409.08 147.74 1408.74 147.83C1408.02 148.01 1408.07 147.43 1407.86 146.94Z" fill="white"/>
                    <path d="M1494.48 333.8C1494.7 333.68 1494.94 333.83 1495.06 334.08C1495.21 334.42 1495.27 334.83 1494.74 334.91C1494.37 334.96 1493.85 335.49 1493.66 334.86C1493.59 334.64 1493.88 333.99 1494.48 333.79V333.8Z" fill="white"/>
                    <path d="M1327.63 387.97C1327.37 387.36 1327.92 386.99 1328.11 386.51C1328.27 386.71 1328.49 386.89 1328.56 387.12C1328.68 387.51 1329.03 387.98 1328.32 388.23C1328 388.34 1327.81 388.22 1327.64 387.97H1327.63Z" fill="white"/>
                    <path d="M1482.49 304.14C1482.83 303.95 1482.44 304.05 1482.72 304.29C1482.88 304.42 1482.99 304.81 1482.9 304.98C1482.67 305.43 1482.58 306.07 1481.87 306.08C1481.79 306.08 1481.64 306 1481.64 305.96C1481.64 305.22 1482.24 304.76 1482.48 304.14H1482.49Z" fill="white"/>
                    <path d="M1439.03 273.73C1439.32 273.29 1439.41 272.62 1440.1 272.66C1440.27 272.66 1440.53 273.03 1440.55 273.24C1440.61 273.92 1439.97 273.78 1439.6 273.95C1439.35 274.07 1439.12 274.06 1439.03 273.73Z" fill="white"/>
                    <path d="M1352.76 244.49C1352.71 244.31 1352.61 244.13 1352.62 243.96C1352.62 243.85 1352.81 243.64 1352.89 243.65C1353.2 243.68 1353.71 245.35 1353.48 245.61C1353.43 245.67 1353.11 245.62 1353.08 245.56C1352.94 245.22 1352.85 244.85 1352.75 244.5L1352.76 244.49Z" fill="white"/>
                    <path d="M1502 205.45C1501.8 204.9 1502.11 204.72 1502.5 204.65C1502.78 204.6 1502.99 204.72 1503.09 205.05C1503.23 205.56 1502.95 205.73 1502.57 205.79C1502.3 205.83 1502.01 205.77 1502.01 205.44L1502 205.45Z" fill="white"/>
                    <path d="M1315.29 374.12C1315.29 373.81 1315.36 373.61 1315.7 373.52C1316.21 373.38 1316.37 373.66 1316.44 374.04C1316.49 374.32 1316.37 374.53 1316.04 374.63C1315.53 374.77 1315.4 374.48 1315.29 374.12Z" fill="white"/>
                    <path d="M1286.4 167.38C1286.4 167.07 1286.47 166.87 1286.81 166.78C1287.32 166.64 1287.48 166.92 1287.55 167.3C1287.6 167.58 1287.48 167.79 1287.15 167.89C1286.64 168.03 1286.51 167.74 1286.4 167.38Z" fill="white"/>
                    <path d="M1386.4 126.48C1386.74 126.39 1387.06 126.34 1387.21 126.85C1387.31 127.19 1387.2 127.4 1386.93 127.51C1386.57 127.66 1386.24 127.67 1386.09 127.17C1385.99 126.84 1386.11 126.66 1386.4 126.49V126.48Z" fill="white"/>
                    <path d="M1251.46 296.16C1250.94 296.09 1250.49 295.91 1250.27 295.4C1250.21 295.27 1250.29 294.92 1250.3 294.92C1250.83 294.92 1251.28 295.12 1251.5 295.63C1251.56 295.77 1251.48 295.96 1251.46 296.15V296.16Z" fill="white"/>
                    <path d="M1261.26 194.96C1261.1 194.66 1261.27 194.44 1261.53 194.33C1261.89 194.18 1262.22 194.17 1262.37 194.67C1262.47 195.01 1262.36 195.22 1262.09 195.33C1261.73 195.48 1261.37 195.49 1261.25 194.96H1261.26Z" fill="white"/>
                    <path d="M1315.63 294C1315.63 293.69 1315.7 293.49 1316.04 293.4C1316.55 293.26 1316.71 293.54 1316.78 293.92C1316.83 294.2 1316.71 294.41 1316.38 294.51C1315.87 294.65 1315.74 294.36 1315.63 294Z" fill="white"/>
                    <path d="M1508.39 268.05C1508.39 267.69 1508.47 267.49 1508.8 267.4C1509.31 267.26 1509.47 267.54 1509.54 267.92C1509.59 268.2 1509.47 268.41 1509.14 268.51C1508.62 268.67 1508.52 268.33 1508.39 268.05Z" fill="white"/>
                    <path d="M1487.82 278.86C1488.33 278.68 1488.51 278.98 1488.58 279.37C1488.63 279.65 1488.51 279.86 1488.18 279.96C1487.67 280.1 1487.51 279.82 1487.44 279.44C1487.39 279.16 1487.49 278.9 1487.83 278.87L1487.82 278.86Z" fill="white"/>
                    <path d="M1218.6 274.27C1218.69 274.63 1218.79 275 1218.26 275.1C1218.1 275.13 1217.92 275.09 1217.76 275.07C1217.91 274.69 1218.05 274.31 1218.2 273.94C1218.33 274.05 1218.46 274.16 1218.6 274.28V274.27Z" fill="white"/>
                    <path d="M1259.3 243.12C1259.21 242.76 1259.11 242.39 1259.64 242.29C1259.8 242.26 1259.98 242.3 1260.14 242.32C1259.99 242.7 1259.85 243.08 1259.7 243.45C1259.57 243.34 1259.44 243.23 1259.3 243.11V243.12Z" fill="white"/>
                    <path d="M1326.64 355.76C1326.82 355.42 1326.98 355.1 1327.15 354.78C1327.31 354.98 1327.55 355.16 1327.6 355.38C1327.7 355.87 1327.3 355.96 1326.94 356C1326.84 356.01 1326.72 355.83 1326.64 355.76Z" fill="white"/>
                    <path d="M1355.32 196.58C1355.54 196.6 1355.84 196.57 1355.86 196.63C1356.02 197.1 1355.55 197.36 1355.43 197.75C1355.3 197.64 1355.11 197.55 1355.05 197.41C1354.89 197.05 1354.9 196.71 1355.33 196.58H1355.32Z" fill="white"/>
                    <path d="M1355.36 106.86C1355.4 106.73 1355.41 106.51 1355.5 106.47C1355.82 106.31 1356.22 106.18 1356.39 106.65C1356.47 106.86 1356.36 107.15 1356.33 107.4C1356.01 107.22 1355.69 107.05 1355.36 106.87V106.86Z" fill="white"/>
                    <path d="M1345.16 133.18C1344.67 133.35 1344.52 133.02 1344.45 132.64C1344.42 132.49 1344.54 132.31 1344.59 132.15C1344.92 132.39 1345.24 132.63 1345.57 132.87C1345.43 132.97 1345.3 133.07 1345.16 133.17V133.18Z" fill="white"/>
                    <path d="M1330.07 332.91C1330.09 332.84 1330.09 332.62 1330.17 332.58C1330.49 332.43 1330.89 332.28 1331.07 332.74C1331.15 332.95 1331.04 333.24 1331.02 333.49C1330.71 333.3 1330.4 333.12 1330.07 332.92V332.91Z" fill="white"/>
                    <path d="M1321.3 297.18C1321.13 296.69 1321.46 296.54 1321.84 296.47C1321.99 296.44 1322.17 296.56 1322.33 296.61C1322.09 296.94 1321.85 297.26 1321.61 297.59C1321.51 297.45 1321.41 297.32 1321.31 297.18H1321.3Z" fill="white"/>
                    <path d="M1298.58 135.99C1298.63 135.82 1298.67 135.65 1298.71 135.49C1299.05 135.71 1299.38 135.93 1299.72 136.16C1299.59 136.27 1299.47 136.41 1299.32 136.47C1298.85 136.67 1298.68 136.36 1298.57 135.99H1298.58Z" fill="white"/>
                    <path d="M1477.04 264.23C1476.55 264.4 1476.4 264.07 1476.33 263.69C1476.3 263.54 1476.42 263.36 1476.47 263.2C1476.8 263.44 1477.12 263.68 1477.45 263.92C1477.31 264.02 1477.18 264.12 1477.04 264.22V264.23Z" fill="white"/>
                    <path d="M1513.86 251.15C1513.69 250.99 1513.51 250.9 1513.45 250.75C1513.3 250.38 1513.31 250.03 1513.81 249.95C1513.97 249.92 1514.14 249.98 1514.31 250C1514.16 250.38 1514.02 250.75 1513.87 251.15H1513.86Z" fill="white"/>
                    <path d="M1364.93 399.69C1365.02 399.32 1365.35 399.35 1365.6 399.29C1365.68 399.27 1365.9 399.49 1365.89 399.57C1365.84 399.92 1365.49 399.9 1365.24 399.95C1365.16 399.97 1365.03 399.78 1364.93 399.69Z" fill="white"/>
                    <path d="M1346.57 382.98C1346.4 382.72 1346.22 382.52 1346.14 382.29C1346.1 382.16 1346.22 381.97 1346.28 381.8C1346.44 382 1346.64 382.18 1346.73 382.41C1346.78 382.53 1346.65 382.73 1346.57 382.99V382.98Z" fill="white"/>
                    <path d="M1465.22 162.39C1465.31 162.02 1465.64 162.05 1465.89 161.99C1465.97 161.97 1466.19 162.19 1466.18 162.27C1466.13 162.62 1465.78 162.6 1465.53 162.65C1465.45 162.67 1465.32 162.48 1465.22 162.39Z" fill="white"/>
                    <path d="M1368.39 119.53C1368.65 119.36 1368.85 119.18 1369.08 119.1C1369.21 119.06 1369.4 119.18 1369.57 119.24C1369.37 119.4 1369.19 119.6 1368.96 119.69C1368.84 119.74 1368.64 119.61 1368.38 119.53H1368.39Z" fill="white"/>
                    <path d="M1365.58 120.74C1365.73 120.71 1365.97 120.62 1366.21 120.62C1366.5 120.62 1366.55 120.82 1366.31 120.97C1366.1 121.1 1365.84 121.17 1365.6 121.18C1365.32 121.18 1365.28 121.02 1365.58 120.74Z" fill="white"/>
                    <path d="M1467.87 145.39C1467.7 145.37 1467.43 145.41 1467.39 145.33C1467.24 145.03 1467.46 144.88 1467.74 144.85C1467.9 144.83 1468.18 144.83 1468.22 144.91C1468.37 145.21 1468.15 145.34 1467.87 145.4V145.39Z" fill="white"/>
                    <path d="M1317.72 368.19C1317.74 368.02 1317.7 367.75 1317.78 367.71C1318.08 367.56 1318.23 367.78 1318.26 368.06C1318.28 368.22 1318.28 368.5 1318.2 368.54C1317.9 368.69 1317.77 368.47 1317.71 368.19H1317.72Z" fill="white"/>
                    <path d="M1297.04 146.11C1296.87 145.85 1296.69 145.65 1296.61 145.42C1296.57 145.29 1296.69 145.1 1296.75 144.93C1296.91 145.13 1297.11 145.31 1297.2 145.54C1297.25 145.66 1297.12 145.86 1297.04 146.12V146.11Z" fill="white"/>
                    <path d="M1465.36 329.68C1465.4 329.56 1465.45 329.4 1465.5 329.23C1465.66 329.43 1465.86 329.61 1465.95 329.84C1466 329.96 1465.87 330.16 1465.82 330.33C1465.55 330.22 1465.38 330.03 1465.36 329.69V329.68Z" fill="white"/>
                    <path d="M1255.91 160.4C1256.17 160.3 1256.42 160.29 1256.46 160.63C1256.47 160.72 1256.23 160.87 1256.08 160.94C1255.83 161.06 1255.57 161.06 1255.53 160.72C1255.52 160.63 1255.77 160.51 1255.9 160.4H1255.91Z" fill="white"/>
                    <path d="M1455.63 292.4C1455.89 292.3 1456.14 292.29 1456.18 292.63C1456.19 292.72 1455.95 292.87 1455.8 292.94C1455.55 293.06 1455.29 293.06 1455.25 292.72C1455.24 292.63 1455.49 292.51 1455.62 292.4H1455.63Z" fill="white"/>
                    <path d="M1445.05 286.6C1445.31 286.5 1445.56 286.49 1445.6 286.83C1445.61 286.92 1445.37 287.07 1445.22 287.14C1444.97 287.26 1444.71 287.26 1444.67 286.92C1444.66 286.83 1444.91 286.71 1445.04 286.6H1445.05Z" fill="white"/>
                    <path d="M1403.4 270.97C1403.23 270.71 1403.05 270.51 1402.97 270.28C1402.93 270.15 1403.05 269.96 1403.11 269.79C1403.27 269.99 1403.47 270.17 1403.56 270.4C1403.61 270.52 1403.48 270.72 1403.4 270.98V270.97Z" fill="white"/>
                    <path d="M1328.57 342.58C1328.61 342.37 1328.66 342.12 1328.71 341.87C1328.84 341.98 1329.06 342.08 1329.08 342.21C1329.12 342.45 1329.05 342.71 1329.02 342.96C1328.89 342.85 1328.76 342.74 1328.56 342.57L1328.57 342.58Z" fill="white"/>
                    <path d="M1327.13 252.05C1327.17 251.93 1327.22 251.77 1327.27 251.6C1327.43 251.8 1327.63 251.98 1327.72 252.21C1327.77 252.33 1327.64 252.53 1327.59 252.7C1327.32 252.59 1327.15 252.4 1327.13 252.06V252.05Z" fill="white"/>
                    <path d="M1283.27 257.41C1283.1 257.15 1282.92 256.95 1282.84 256.72C1282.8 256.59 1282.92 256.4 1282.98 256.23C1283.14 256.43 1283.34 256.61 1283.43 256.84C1283.48 256.96 1283.35 257.16 1283.27 257.42V257.41Z" fill="white"/>
                    <path d="M1214.59 266.32C1214.68 265.95 1215.01 265.98 1215.26 265.92C1215.34 265.9 1215.56 266.12 1215.55 266.2C1215.5 266.55 1215.15 266.53 1214.9 266.58C1214.82 266.6 1214.69 266.41 1214.59 266.32Z" fill="white"/>
                    <path d="M1260.32 224.31C1260.58 224.21 1260.83 224.2 1260.87 224.54C1260.88 224.63 1260.64 224.78 1260.49 224.85C1260.24 224.97 1259.98 224.97 1259.94 224.63C1259.93 224.54 1260.18 224.42 1260.31 224.31H1260.32Z" fill="white"/>
                    <path d="M1454.45 176.57C1454.28 176.55 1454.01 176.59 1453.97 176.51C1453.82 176.21 1454.04 176.06 1454.32 176.03C1454.48 176.01 1454.76 176.01 1454.8 176.09C1454.95 176.39 1454.73 176.52 1454.45 176.58V176.57Z" fill="white"/>
                    <path d="M1370.65 216.42C1370.67 216.25 1370.63 215.98 1370.71 215.94C1371.01 215.79 1371.16 216.01 1371.19 216.29C1371.21 216.45 1371.21 216.73 1371.13 216.77C1370.83 216.92 1370.7 216.7 1370.64 216.42H1370.65Z" fill="white"/>
                    <path d="M1365.69 202.46C1365.59 202.36 1365.48 202.26 1365.41 202.14C1365.39 202.1 1365.49 201.99 1365.53 201.91C1365.63 202.01 1365.74 202.11 1365.81 202.23C1365.83 202.27 1365.73 202.38 1365.69 202.46Z" fill="white"/>
                    <path d="M1292.9 127.68C1292.8 127.58 1292.69 127.48 1292.62 127.36C1292.6 127.32 1292.7 127.21 1292.74 127.13C1292.84 127.23 1292.95 127.33 1293.02 127.45C1293.04 127.49 1292.94 127.6 1292.9 127.68Z" fill="white"/>
                    <path d="M1433.36 286.83C1433.39 286.7 1433.42 286.56 1433.45 286.42C1433.53 286.46 1433.67 286.5 1433.67 286.55C1433.67 286.69 1433.63 286.83 1433.61 286.97C1433.53 286.93 1433.45 286.88 1433.36 286.83Z" fill="white"/>
                    <path d="M1477.84 287.49C1477.79 287.58 1477.75 287.66 1477.7 287.74C1477.6 287.64 1477.49 287.54 1477.42 287.42C1477.4 287.38 1477.5 287.27 1477.54 287.19C1477.64 287.29 1477.74 287.39 1477.84 287.49Z" fill="white"/>
                    <path d="M1398.76 312.04C1398.89 312.07 1399.03 312.1 1399.17 312.13C1399.13 312.21 1399.09 312.35 1399.04 312.35C1398.9 312.35 1398.76 312.31 1398.62 312.29C1398.66 312.21 1398.71 312.13 1398.76 312.04Z" fill="white"/>
                    <path d="M1360.58 289.6C1360.68 289.5 1360.78 289.39 1360.9 289.32C1360.94 289.3 1361.05 289.4 1361.13 289.44C1361.03 289.54 1360.93 289.65 1360.81 289.72C1360.77 289.74 1360.66 289.64 1360.58 289.6Z" fill="white"/>
                    <path d="M1314.81 298C1314.9 298.05 1314.98 298.09 1315.06 298.14C1314.96 298.24 1314.86 298.35 1314.74 298.42C1314.7 298.44 1314.59 298.34 1314.51 298.3C1314.61 298.2 1314.71 298.1 1314.81 298Z" fill="white"/>
                    <path d="M1312.28 355.35C1312.31 355.22 1312.34 355.08 1312.37 354.94C1312.45 354.98 1312.59 355.02 1312.59 355.07C1312.59 355.21 1312.55 355.35 1312.53 355.49C1312.45 355.45 1312.37 355.4 1312.28 355.35Z" fill="white"/>
                    <path d="M1417.18 308.57C1417.23 308.48 1417.27 308.4 1417.32 308.32C1417.42 308.42 1417.53 308.52 1417.6 308.64C1417.62 308.68 1417.52 308.79 1417.48 308.87C1417.38 308.77 1417.28 308.67 1417.18 308.57Z" fill="white"/>
                    <path d="M1364.9 259.98C1364.85 260.07 1364.81 260.15 1364.76 260.23C1364.66 260.13 1364.55 260.03 1364.48 259.91C1364.46 259.87 1364.56 259.76 1364.6 259.68C1364.7 259.78 1364.8 259.88 1364.9 259.98Z" fill="white"/>
                    <path d="M1229.87 287.68C1229.79 287.64 1229.65 287.58 1229.65 287.56C1229.68 287.42 1229.71 287.25 1229.8 287.17C1230 286.99 1230.14 287.07 1230.1 287.33C1230.08 287.46 1229.95 287.57 1229.87 287.68Z" fill="white"/>
                    <path d="M1229.97 286.84C1230.05 286.72 1230.14 286.51 1230.22 286.51C1230.45 286.51 1230.53 286.67 1230.42 286.8C1230.32 286.91 1230.17 286.99 1230.04 287.08C1230.02 287 1229.99 286.92 1229.97 286.84Z" fill="white"/>
                    <path d="M1386.2 182.05C1386.23 181.92 1386.26 181.78 1386.29 181.64C1386.37 181.68 1386.51 181.72 1386.51 181.77C1386.51 181.91 1386.47 182.05 1386.45 182.19C1386.37 182.15 1386.29 182.1 1386.2 182.05Z" fill="white"/>
                    <path d="M1253.33 366.61C1253.36 366.48 1253.39 366.34 1253.42 366.2C1253.5 366.24 1253.64 366.28 1253.64 366.33C1253.64 366.47 1253.6 366.61 1253.58 366.75C1253.5 366.71 1253.42 366.66 1253.33 366.61Z" fill="white"/>
                    <path d="M1222.89 284.63C1222.94 284.54 1222.98 284.46 1223.03 284.38C1223.13 284.48 1223.24 284.58 1223.31 284.7C1223.33 284.74 1223.23 284.85 1223.19 284.93C1223.09 284.83 1222.99 284.73 1222.89 284.63Z" fill="white"/>
                    <path d="M1295 363.77C1294.97 363.9 1294.94 364.04 1294.91 364.18C1294.83 364.14 1294.69 364.1 1294.69 364.05C1294.69 363.91 1294.73 363.77 1294.75 363.63C1294.83 363.67 1294.91 363.72 1295 363.77Z" fill="white"/>
                    <path d="M1302.49 224.25C1302.52 224.12 1302.55 223.98 1302.58 223.84C1302.66 223.88 1302.8 223.92 1302.8 223.97C1302.8 224.11 1302.76 224.25 1302.74 224.39C1302.66 224.35 1302.58 224.3 1302.49 224.25Z" fill="white"/>
                    <path d="M1377.22 333.14C1377.31 333.19 1377.39 333.23 1377.47 333.28C1377.37 333.38 1377.27 333.49 1377.15 333.56C1377.11 333.58 1377 333.48 1376.92 333.44C1377.02 333.34 1377.12 333.24 1377.22 333.14Z" fill="white"/>
                    <path d="M1249.18 264.31C1249.08 264.21 1248.97 264.11 1248.9 263.99C1248.88 263.95 1248.98 263.84 1249.02 263.76C1249.12 263.86 1249.23 263.96 1249.3 264.08C1249.32 264.12 1249.22 264.23 1249.18 264.31Z" fill="white"/>
                    <path d="M1366.2 339.02C1366.23 338.89 1366.26 338.75 1366.29 338.61C1366.37 338.65 1366.51 338.69 1366.51 338.74C1366.51 338.88 1366.47 339.02 1366.45 339.16L1366.2 339.02Z" fill="white"/>
                    <path d="M1367.68 227.28C1367.55 227.25 1367.41 227.22 1367.27 227.19C1367.31 227.11 1367.35 226.97 1367.4 226.97C1367.54 226.97 1367.68 227.01 1367.82 227.03C1367.78 227.11 1367.73 227.19 1367.68 227.28Z" fill="white"/>
                    <path d="M1465.22 137.27C1465.12 137.17 1465.01 137.07 1464.94 136.95C1464.92 136.91 1465.02 136.8 1465.06 136.72C1465.16 136.82 1465.27 136.92 1465.34 137.04C1465.36 137.08 1465.26 137.19 1465.22 137.27Z" fill="white"/>
                    <path d="M1467.93 122.83C1469.97 122.93 1470.57 124.4 1471.11 125.8C1471.31 126.31 1471.5 127.15 1471.7 127.37C1472.97 128.81 1470.78 129.45 1471.09 130.58C1469.94 130.71 1468.62 131.26 1467.7 130.86C1466.83 130.48 1465.78 129.91 1465.1 129.1C1464.45 128.33 1463.19 127.55 1464.74 126.4C1464.97 126.23 1464.84 125.61 1464.92 125.2C1465.28 123.4 1466.33 122.63 1467.92 122.83H1467.93Z" fill="white"/>
                    <path d="M1346.64 234.34C1346.81 233.15 1347.87 232.43 1348.29 232.49C1349.91 232.74 1350.76 231.34 1352.1 231.04C1353.05 230.83 1353.66 230.85 1354.19 231.63C1355.06 232.92 1355.09 233.63 1354.31 234.44C1353.97 234.79 1353.68 235.17 1353.4 235.57C1352.16 237.29 1347.86 237.49 1346.89 235.26C1346.72 234.86 1346.56 234.54 1346.64 234.35V234.34Z" fill="white"/>
                    <path d="M1444.9 230.21C1445.76 229.59 1445.73 228.04 1447.2 228.12C1447.41 228.13 1447.64 228.24 1447.81 228.18C1448.54 227.93 1449.24 227.38 1450.05 227.95C1450.68 228.39 1450.7 229.16 1450.92 229.8C1451.36 231.07 1450.51 232.77 1449.18 233.41C1448.14 233.9 1447.23 233.75 1446.28 233.06C1445.29 232.34 1445.31 231.19 1444.89 230.21H1444.9Z" fill="white"/>
                    <path d="M1495.37 367.52C1495.13 369.31 1494.37 370.05 1493.06 370.03C1492.34 370.03 1491.62 369.83 1490.89 369.78C1490.2 369.73 1490.03 369.41 1490.15 368.77C1490.28 368.06 1490.24 367.3 1490.45 366.61C1490.81 365.45 1491.73 364.77 1492.86 364.41C1493.09 364.34 1493.53 364.43 1493.66 364.61C1494.15 365.26 1494.81 365.69 1495.4 366.21C1495.68 366.46 1495.38 367.37 1495.37 367.51V367.52Z" fill="white"/>
                    <path d="M1358.78 370.26C1358.81 369.44 1358.84 368.27 1360.14 368.09C1360.76 368 1361.92 367.84 1362.05 368.61C1362.2 369.44 1363.05 370.26 1362.41 371.18C1361.97 371.82 1361.28 372.33 1361.01 373.03C1360.76 373.67 1360.53 373.77 1359.93 373.71C1359.06 373.62 1358.65 373.05 1358.56 372.34C1358.48 371.74 1358.68 371.11 1358.79 370.27L1358.78 370.26Z" fill="white"/>
                    <path d="M1517.5 372.15C1517.59 371.33 1518.31 370.71 1519.12 370.76C1519.39 370.78 1521.76 372.54 1521.83 372.78C1521.99 373.31 1520.77 374.78 1520.72 374.69C1520.14 373.52 1519.33 374.9 1518.74 374.46C1518.18 374.03 1517.44 372.67 1517.49 372.15H1517.5Z" fill="white"/>
                    <path d="M1498.05 187.76C1497.62 187.69 1496.95 187.73 1497 187.31C1497.09 186.55 1496.84 185.67 1497.65 185.04C1498.1 184.69 1498.64 184.83 1499.01 184.55C1499.61 184.09 1500.01 184.05 1500.55 184.67C1501.13 185.34 1501.14 186.11 1500.66 186.59C1499.99 187.28 1499.26 188.19 1498.06 187.75L1498.05 187.76Z" fill="white"/>
                    <path d="M1409.08 249.72C1408.96 250.36 1409.19 251.25 1407.96 251.05C1407.68 251 1407.33 251.53 1406.99 251.76C1406.6 252.03 1406.2 252.26 1405.8 252.51C1405.82 251.81 1405.6 250.97 1405.91 250.45C1406.32 249.79 1407.17 249.42 1407.8 248.88C1408.14 248.59 1408.52 248.38 1408.9 248.6C1409.32 248.85 1409.13 249.32 1409.09 249.72H1409.08Z" fill="white"/>
                    <path d="M1575.61 259.83C1575.58 260.03 1575.58 260.24 1575.53 260.43C1575.41 260.87 1575.67 261.55 1574.94 261.63C1574.2 261.71 1573.6 261.41 1573.19 260.72C1572.84 260.13 1573.12 259.84 1573.52 259.47C1573.78 259.23 1573.84 258.76 1574.1 258.52C1574.44 258.19 1574.8 257.71 1575.39 257.94C1576.09 258.21 1575.67 258.79 1575.68 259.24C1575.68 259.44 1575.63 259.64 1575.61 259.85V259.83Z" fill="white"/>
                    <path d="M1509.6 380.3C1510.37 380.87 1511.34 381.23 1511.8 382.15C1511.91 382.36 1511.87 382.75 1511.54 382.95C1511.12 383.2 1510.77 383.81 1510.23 383.5C1509.57 383.12 1508.98 382.59 1508.42 382.06C1507.75 381.43 1508.85 381.41 1508.84 380.97C1508.84 380.51 1509.03 380.19 1509.6 380.3Z" fill="white"/>
                    <path d="M1412.24 198.93C1412.26 199.5 1412.03 199.82 1411.35 199.7C1410.29 199.51 1409.76 198.87 1409.84 197.92C1409.91 197.07 1410.49 196.67 1411.16 196.55C1411.94 196.41 1412.45 197.57 1412.24 198.93Z" fill="white"/>
                    <path d="M1341.68 137.37C1341.64 137.68 1341.66 138.01 1341.55 138.29C1341.31 138.96 1340.45 139.08 1340.01 138.52C1339.75 138.19 1339.43 137.9 1339.26 137.53C1339.05 137.06 1339.09 136.44 1339.43 136.11C1339.82 135.74 1340.58 135.96 1340.99 135.46C1341.28 135.97 1341.97 136.29 1341.71 137.05C1341.68 137.15 1341.68 137.25 1341.67 137.36L1341.68 137.37Z" fill="white"/>
                    <path d="M1508.53 322.46C1508.82 322.23 1509.07 322.06 1509.3 321.85C1509.88 321.33 1510.7 322.16 1511.27 321.46C1511.57 321.09 1512.04 321.15 1512.29 321.56C1512.53 321.94 1512.36 322.3 1511.98 322.58C1511.48 322.94 1511.03 323.36 1510.51 323.7C1510.27 323.86 1510.05 324.47 1509.62 323.88C1509.27 323.4 1508.89 322.94 1508.52 322.47L1508.53 322.46Z" fill="white"/>
                    <path d="M1420.39 416.53C1419.75 416.35 1419.27 415.26 1419.51 414.54C1419.61 414.25 1421.33 413.41 1421.67 413.55C1422.52 413.91 1422 414.61 1421.93 415.11C1421.8 416.05 1421 416.7 1420.39 416.53Z" fill="white"/>
                    <path d="M1383.23 338.13C1383.87 338.11 1384.24 338.3 1384.12 339C1383.97 339.86 1383.27 340.64 1382.65 340.56C1382.03 340.48 1381.52 339.53 1381.63 338.67C1381.8 337.38 1382.71 338.24 1383.23 338.12V338.13Z" fill="white"/>
                    <path d="M1377.24 333.43C1377.6 332.98 1377.96 332.47 1378.62 332.51C1379.33 332.55 1379.9 333.86 1379.61 334.66C1379.42 335.19 1379.03 335.1 1378.64 335.02C1377.74 334.82 1377.08 334.07 1377.24 333.43Z" fill="white"/>
                    <path d="M1472.92 335C1472.87 335.4 1472.82 335.61 1472.82 335.81C1472.82 336.57 1472.1 336.71 1471.71 337.13C1471.43 336.63 1470.76 336.32 1470.95 335.58C1471 335.38 1471.02 335.18 1471.03 334.97C1471.06 334.28 1471.2 333.86 1472.1 333.99C1472.96 334.11 1473.12 334.48 1472.92 335Z" fill="white"/>
                    <path d="M1298.11 294.11C1298.84 294.15 1298.93 294.65 1298.72 294.98C1298.32 295.64 1297.79 296.23 1297.27 296.81C1296.78 297.35 1296.66 296.7 1296.43 296.49C1295.43 295.54 1296.85 295.17 1296.95 294.46C1297.07 293.64 1297.78 294.22 1298.1 294.11H1298.11Z" fill="white"/>
                    <path d="M1436.02 342.58C1435.96 341.72 1436.56 341.3 1437.23 341.15C1437.74 341.04 1438.76 342.42 1438.5 342.77C1438.01 343.44 1437.34 343.66 1436.5 343.49C1435.91 343.37 1435.97 342.99 1436.01 342.58H1436.02Z" fill="white"/>
                    <path d="M1553.08 192.7C1553.67 192.88 1553.95 193.4 1554.14 193.95C1554.25 194.28 1552.76 195.12 1552.12 195.1C1551.54 195.08 1551.53 194.68 1551.6 194.26C1551.73 193.41 1552.46 192.63 1553.09 192.7H1553.08Z" fill="white"/>
                    <path d="M1469.54 370.91C1469.25 370.78 1468.98 370.52 1469.19 370.22C1469.69 369.5 1470.01 368.66 1470.77 368.12C1471.09 367.89 1471.44 367.91 1471.63 368.05C1471.81 368.18 1471.95 368.68 1471.84 368.84C1471.41 369.49 1470.89 370.08 1470.39 370.68C1470.18 370.94 1469.88 371 1469.54 370.9V370.91Z" fill="white"/>
                    <path d="M1479.57 403.29C1479.58 402.96 1479.59 402.54 1480.14 402.5C1480.62 402.46 1480.78 402.87 1481.07 403.13C1481.65 403.66 1481.37 404.29 1481.23 404.81C1481.14 405.12 1480.54 405.59 1480.35 405.52C1479.97 405.37 1479.6 404.94 1479.45 404.55C1479.32 404.22 1479.51 403.76 1479.57 403.29Z" fill="white"/>
                    <path d="M1318.4 346.46C1317.88 346.48 1317.57 346.24 1317.7 345.57C1317.86 344.72 1318.55 343.96 1319.17 344.03C1320.21 344.14 1319.34 345.34 1320.1 345.68C1320.23 345.74 1319.2 346.51 1318.4 346.47V346.46Z" fill="white"/>
                    <path d="M1500.88 282.54C1501.01 282.41 1501.13 282.17 1501.28 282.16C1502.09 282.07 1503.35 283.36 1503.19 284.12C1503.14 284.38 1502.98 284.75 1502.78 284.82C1502.57 284.89 1502.2 284.73 1502.01 284.55C1501.18 283.75 1500.82 283.06 1500.87 282.55L1500.88 282.54Z" fill="white"/>
                    <path d="M1469.55 170.34C1469.25 170.3 1468.92 170.32 1468.64 170.22C1467.91 169.97 1468.33 169.39 1468.33 168.94C1468.33 168.35 1468.75 168.35 1469.17 168.41C1469.56 168.46 1469.96 168.44 1470.23 168.85C1470.51 169.27 1470.97 169.64 1470.66 170.18C1470.41 170.6 1469.94 170.38 1469.55 170.35V170.34Z" fill="white"/>
                    <path d="M1468.23 367.68C1468.03 368.29 1467.61 368.18 1467.22 368.1C1466.31 367.89 1465.66 367.14 1465.85 366.51C1466.01 365.98 1466.44 366.08 1466.83 366.17C1467.74 366.38 1468.37 367.12 1468.23 367.69V367.68Z" fill="white"/>
                    <path d="M1316.85 355.11C1316.43 354.52 1315.89 354.08 1316.09 353.22C1316.26 352.52 1316.61 352.54 1317.16 352.59C1317.75 352.65 1318.01 352.8 1318.01 353.49C1318.01 354.39 1317.31 354.61 1316.85 355.1V355.11Z" fill="white"/>
                    <path d="M1314.67 339.53C1314.79 339.38 1314.91 339.12 1315.04 339.12C1315.44 339.11 1315.96 339.06 1316.22 339.28C1316.64 339.64 1317.21 340.04 1317.03 340.76C1316.9 341.31 1316.45 341.21 1316.06 341.12C1315.14 340.9 1314.53 340.21 1314.66 339.54L1314.67 339.53Z" fill="white"/>
                    <path d="M1511.3 163.09C1511.37 162.67 1511.44 162.22 1512 162.38C1512.73 162.6 1513.24 163.14 1513.05 163.89C1512.95 164.29 1512.38 164.56 1512.03 164.89C1511.64 164.34 1510.99 163.9 1511.3 163.08V163.09Z" fill="white"/>
                    <path d="M1348.36 188.69C1349.09 188.83 1349.76 189.78 1349.6 190.28C1349.44 190.81 1349.02 190.66 1348.63 190.64C1347.84 190.59 1347.38 190.1 1347.36 189.42C1347.36 189.22 1347.96 188.65 1348.36 188.7V188.69Z" fill="white"/>
                    <path d="M1414.38 349.24C1414.44 348.72 1414.54 348.18 1415.05 348.41C1415.68 348.69 1416.21 349.2 1416.78 349.62C1416.25 350.1 1415.74 350.49 1414.91 350.28C1414.13 350.08 1414.39 349.59 1414.38 349.24Z" fill="white"/>
                    <path d="M1295.85 247.45C1295.86 246.59 1296.28 246.59 1296.87 246.62C1297.79 246.66 1297.77 247.15 1297.69 247.84C1297.61 248.55 1297.25 248.58 1296.65 248.53C1295.77 248.47 1295.61 248.05 1295.84 247.45H1295.85Z" fill="white"/>
                    <path d="M1424.62 357.75C1425.15 357.91 1426.08 357.61 1425.8 358.63C1425.65 359.19 1425.13 359.63 1424.47 359.62C1423.39 359.6 1423.83 358.5 1423.61 358C1423.33 357.38 1424.28 357.92 1424.62 357.75Z" fill="white"/>
                    <path d="M1494.11 418.23C1494.15 417.93 1494.14 417.61 1494.23 417.33C1494.4 416.81 1494.83 416.92 1495.22 417C1495.5 417.06 1495.87 416.94 1496.01 417.31C1496.17 417.72 1496.06 418.07 1495.68 418.32C1495.51 418.43 1495.36 418.57 1495.2 418.69C1494.89 418.92 1494.47 419.39 1494.27 419.31C1493.83 419.14 1494.1 418.62 1494.11 418.23Z" fill="white"/>
                    <path d="M1389.64 145.9C1388.96 145.59 1389.32 145.04 1389.36 144.6C1389.43 143.77 1390.06 144.1 1390.51 144.13C1390.93 144.15 1391.34 144.25 1391.21 144.83C1391.08 145.43 1390.16 146.04 1389.64 145.9Z" fill="white"/>
                    <path d="M1363.02 308.47C1363.55 308.56 1363.98 308.62 1363.85 309.21C1363.71 309.81 1362.79 310.45 1362.28 310.28C1361.76 310.11 1361.94 309.67 1361.94 309.28C1361.92 308.33 1362.49 308.36 1363.02 308.47Z" fill="white"/>
                    <path d="M1453.29 388.48C1453.09 388.4 1452.66 388.34 1452.61 388.17C1452.45 387.69 1453 387.55 1453.27 387.44C1453.66 387.27 1453.91 386.15 1454.58 387.14C1454.8 387.47 1455.14 387.75 1454.95 388.19C1454.58 389.07 1453.9 388.32 1453.29 388.49V388.48Z" fill="white"/>
                    <path d="M1553.81 262.68C1554.14 262.32 1554.52 261.51 1554.98 261.95C1555.37 262.33 1556.22 262.14 1556.19 262.88C1556.17 263.32 1554.59 263.75 1554.07 263.46C1553.8 263.31 1553.7 263.02 1553.81 262.68Z" fill="white"/>
                    <path d="M1315.27 247.66C1315.27 247.66 1315.03 247.16 1314.88 246.68C1314.82 246.47 1316.08 245.81 1316.36 245.91C1317.09 246.17 1316.68 246.73 1316.68 247.18C1316.68 247.72 1316.32 247.81 1315.27 247.66Z" fill="white"/>
                    <path d="M1324.83 212.1C1325.12 211.42 1325.67 211.81 1326.12 211.81C1326.68 211.81 1326.83 212.26 1326.63 212.62C1326.4 213.02 1325.97 213.34 1325.58 213.61C1325.44 213.71 1324.75 212.36 1324.84 212.1H1324.83Z" fill="white"/>
                    <path d="M1490.27 118.07C1490.82 118.03 1491 118.3 1490.96 118.6C1490.86 119.39 1490.3 119.75 1489.55 119.84C1489.16 119.88 1488.98 119.6 1489.01 119.3C1489.09 118.48 1489.69 118.18 1490.26 118.08L1490.27 118.07Z" fill="white"/>
                    <path d="M1519.25 297.63C1518.61 297.45 1518.17 296.97 1518.24 296.45C1518.27 296.19 1518.82 295.66 1519.43 295.75C1520.24 295.87 1519.92 296.45 1519.9 296.9C1519.88 297.32 1519.77 297.69 1519.25 297.64V297.63Z" fill="white"/>
                    <path d="M1387.79 125.49C1388.08 125.49 1389.17 126.47 1389.03 126.59C1388.67 126.9 1388.25 127.24 1387.8 127.35C1387.39 127.46 1387.04 127.16 1387.17 126.64C1387.28 126.21 1387.02 125.57 1387.79 125.49Z" fill="white"/>
                    <path d="M1520.33 303.91C1520.57 303.38 1521 302.87 1521.82 302.99C1522.12 303.04 1522.35 303.28 1522.21 303.64C1521.94 304.35 1521.46 304.8 1520.67 304.71C1520.37 304.68 1520.15 304.43 1520.33 303.91Z" fill="white"/>
                    <path d="M1586.13 327.4C1586.17 326.83 1586.59 326.67 1586.95 326.87C1587.35 327.1 1587.67 327.53 1587.94 327.93C1588.04 328.07 1586.69 328.76 1586.43 328.67C1586.05 328.55 1585.94 328.05 1586.13 327.4Z" fill="white"/>
                    <path d="M1423.4 352.76C1423.46 352.33 1423.52 351.9 1424.09 352.02C1424.71 352.15 1425.14 352.65 1425.07 353.18C1425.04 353.44 1424.49 353.97 1423.88 353.88C1423.06 353.76 1423.42 353.17 1423.4 352.76Z" fill="white"/>
                    <path d="M1514.62 390.81C1514.95 390.33 1515.31 389.92 1515.95 389.93C1516.33 389.93 1516.53 390.2 1516.46 390.49C1516.33 390.98 1516.71 391.82 1515.7 391.79C1515.08 391.77 1515.03 391.11 1514.62 390.82V390.81Z" fill="white"/>
                    <path d="M1434.62 343.71C1434.75 343.57 1434.88 343.44 1435.01 343.3C1435.58 344 1436.04 344.8 1436.97 345.13C1437.01 345.14 1436.97 345.54 1436.88 345.72C1436.83 345.82 1436.48 345.89 1436.45 345.85C1436.01 345.15 1434.95 345.16 1434.67 344.3C1434.61 344.12 1434.64 343.91 1434.63 343.71H1434.62Z" fill="white"/>
                    <path d="M1439.46 343.22C1440.07 343.33 1439.63 344.08 1439.53 344.55C1439.32 345.48 1438.58 344.88 1438.07 344.9C1437.92 344.9 1437.4 344.5 1437.93 344.4C1438.72 344.25 1437.98 342.87 1439.46 343.22Z" fill="white"/>
                    <path d="M1452.58 149.94C1451.82 149.97 1451.7 149.24 1451.29 148.86C1451.59 148.61 1451.92 148.13 1452.18 148.17C1452.74 148.26 1453.06 148.76 1453.1 149.36C1453.12 149.73 1452.89 149.86 1452.58 149.94Z" fill="white"/>
                    <path d="M1319.89 253.28C1320.31 253.4 1320.97 253.21 1320.88 254.05C1320.82 254.65 1320.43 254.64 1320.01 254.56C1319.56 254.48 1318.92 254.63 1319 253.8C1319.06 253.2 1319.45 253.24 1319.88 253.28H1319.89Z" fill="white"/>
                    <path d="M1534.9 161.73C1535.57 160.95 1536.22 160.84 1536.94 161.06C1537.11 161.11 1537.23 161.3 1537.38 161.42C1537.22 161.54 1537.03 161.64 1536.91 161.8C1536.44 162.39 1535.83 162.2 1535.26 162.09C1535.08 162.05 1534.96 161.79 1534.91 161.74L1534.9 161.73Z" fill="white"/>
                    <path d="M1289.29 244.02C1289.29 243.87 1289.69 243.84 1290.12 243.92C1290.56 244.01 1291.22 243.83 1291.14 244.66C1291.09 245.25 1290.71 245.26 1290.28 245.19C1289.83 245.11 1289.19 245.26 1289.29 244.02Z" fill="white"/>
                    <path d="M1343.48 282.17C1343.59 282.26 1343.82 282.36 1343.82 282.46C1343.8 283.16 1343.76 283.84 1343 284.24C1342.69 284.41 1342.49 284.46 1342.38 284.12C1342.21 283.59 1342.99 282.25 1343.48 282.17Z" fill="white"/>
                    <path d="M1410.68 181.16C1411.05 180.23 1411.73 180.95 1412.26 180.91C1412.5 180.89 1412.61 181.38 1412.49 181.67C1412.12 182.53 1411.44 181.95 1410.91 181.93C1410.66 181.92 1410.56 181.49 1410.68 181.16Z" fill="white"/>
                    <path d="M1412.01 302.43C1412.38 301.5 1413.06 302.22 1413.59 302.18C1413.83 302.16 1413.94 302.65 1413.82 302.94C1413.45 303.8 1412.77 303.22 1412.24 303.2C1411.99 303.19 1411.89 302.76 1412.01 302.43Z" fill="white"/>
                    <path d="M1537.57 240.81C1537.57 240.66 1537.97 240.63 1538.4 240.71C1538.84 240.8 1539.5 240.62 1539.42 241.45C1539.37 242.04 1538.99 242.05 1538.56 241.98C1538.11 241.9 1537.47 242.05 1537.57 240.81Z" fill="white"/>
                    <path d="M1389.73 381.72C1389.97 382.33 1390.22 382.95 1390.45 383.58C1390.46 383.61 1390.31 383.75 1390.22 383.75C1389.79 383.78 1389.49 383.59 1389.23 383.23C1388.94 382.82 1388.59 382.44 1388.98 381.94C1389.07 381.82 1389.33 381.83 1389.73 381.72Z" fill="white"/>
                    <path d="M1445.72 368.58C1445.3 368.56 1444.86 368.56 1444.86 367.99C1444.86 367.26 1445.33 367.24 1445.89 367.34C1446.28 367.41 1446.75 367.37 1446.74 367.94C1446.74 368.67 1446.28 368.73 1445.72 368.58Z" fill="white"/>
                    <path d="M1502.44 340.41C1502.68 339.88 1502.85 339.64 1503.21 339.78C1503.92 340.05 1504.35 340.53 1504.28 341.32C1504.26 341.47 1504.05 341.61 1503.93 341.75C1503.41 341.28 1502.9 340.82 1502.44 340.4V340.41Z" fill="white"/>
                    <path d="M1381 313.61C1381 313.05 1381.16 312.64 1381.41 312.69C1381.92 312.8 1382.73 312.4 1382.88 313.33C1382.93 313.64 1382.74 314.05 1382.46 314.02C1381.93 313.97 1381.22 314.18 1380.99 313.61H1381Z" fill="white"/>
                    <path d="M1531.22 221.07C1531.29 220.65 1531.34 220.25 1531.94 220.34C1532.76 220.47 1532.46 221.05 1532.43 221.5C1532.4 221.92 1532.32 222.3 1531.72 222.22C1530.89 222.1 1531.23 221.51 1531.22 221.07Z" fill="white"/>
                    <path d="M1437.31 209.21C1436.38 208.84 1437.1 208.16 1437.06 207.63C1437.04 207.39 1437.53 207.28 1437.82 207.4C1438.68 207.77 1438.1 208.45 1438.08 208.98C1438.07 209.23 1437.64 209.33 1437.31 209.21Z" fill="white"/>
                    <path d="M1503.31 351.76C1502.89 351.74 1502.45 351.74 1502.45 351.17C1502.45 350.44 1502.92 350.42 1503.48 350.52C1503.87 350.59 1504.34 350.55 1504.33 351.12C1504.33 351.85 1503.87 351.91 1503.31 351.76Z" fill="white"/>
                    <path d="M1329.4 345.26C1328.68 345.24 1328.54 345 1328.54 344.67C1328.56 343.93 1329.04 343.93 1329.58 344.05C1329.86 344.11 1330.44 344.12 1330.32 344.34C1330.09 344.79 1329.58 345.09 1329.4 345.26Z" fill="white"/>
                    <path d="M1520.79 183.07C1520.88 182.93 1521.03 182.69 1521.18 182.46C1521.51 182.92 1522.13 183.24 1521.95 183.94C1521.87 184.24 1521.67 184.41 1521.29 184.35C1520.48 184.22 1520.79 183.66 1520.79 183.07Z" fill="white"/>
                    <path d="M1526.82 409.55C1527.1 409.53 1527.28 409.78 1527.28 410.09C1527.28 410.5 1527.17 410.94 1526.6 410.79C1526.2 410.68 1525.45 410.99 1525.53 410.27C1525.56 410.01 1526.13 409.48 1526.83 409.55H1526.82Z" fill="white"/>
                    <path d="M1334.69 391.52C1334.69 390.79 1335.41 390.66 1335.81 390.26C1335.88 390.53 1336.03 390.81 1336 391.07C1335.95 391.51 1336.1 392.15 1335.27 392.08C1334.9 392.05 1334.76 391.85 1334.69 391.52Z" fill="white"/>
                    <path d="M1527.63 374.37C1528.06 374.33 1527.61 374.26 1527.8 374.62C1527.9 374.81 1527.84 375.26 1527.69 375.39C1527.26 375.75 1526.89 376.35 1526.17 376.05C1526.09 376.02 1525.97 375.87 1525.99 375.83C1526.31 375.08 1527.12 374.89 1527.63 374.36V374.37Z" fill="white"/>
                    <path d="M1497 324.71C1497.48 324.39 1497.86 323.76 1498.55 324.1C1498.71 324.18 1498.82 324.65 1498.75 324.88C1498.52 325.59 1497.93 325.17 1497.48 325.18C1497.17 325.18 1496.94 325.08 1497 324.71Z" fill="white"/>
                    <path d="M1422.62 257.56C1422.65 257.36 1422.62 257.13 1422.71 256.96C1422.77 256.85 1423.04 256.72 1423.12 256.77C1423.42 256.94 1423.2 258.85 1422.86 259.01C1422.78 259.05 1422.48 258.86 1422.48 258.78C1422.48 258.37 1422.56 257.97 1422.61 257.56H1422.62Z" fill="white"/>
                    <path d="M1590.36 283.19C1590.4 282.55 1590.79 282.5 1591.21 282.6C1591.52 282.67 1591.68 282.88 1591.63 283.26C1591.55 283.83 1591.19 283.88 1590.78 283.78C1590.49 283.71 1590.22 283.52 1590.37 283.19H1590.36Z" fill="white"/>
                    <path d="M1328.27 372.15C1328.4 371.84 1328.57 371.67 1328.95 371.72C1329.52 371.8 1329.57 372.16 1329.47 372.57C1329.4 372.88 1329.19 373.04 1328.81 372.99C1328.23 372.91 1328.23 372.56 1328.27 372.15Z" fill="white"/>
                    <path d="M1389.21 150.76C1389.34 150.45 1389.51 150.28 1389.89 150.33C1390.46 150.41 1390.51 150.77 1390.41 151.18C1390.34 151.49 1390.13 151.65 1389.75 151.6C1389.17 151.52 1389.17 151.17 1389.21 150.76Z" fill="white"/>
                    <path d="M1508.04 153.04C1508.42 153.09 1508.77 153.19 1508.7 153.76C1508.65 154.14 1508.45 154.31 1508.13 154.3C1507.7 154.3 1507.36 154.16 1507.43 153.59C1507.48 153.21 1507.67 153.08 1508.04 153.04Z" fill="white"/>
                    <path d="M1297.78 265.59C1297.29 265.29 1296.91 264.91 1296.9 264.3C1296.9 264.14 1297.13 263.83 1297.14 263.83C1297.67 264.07 1298.04 264.45 1298.04 265.07C1298.04 265.23 1297.88 265.39 1297.78 265.58V265.59Z" fill="white"/>
                    <path d="M1351.79 167.66C1351.76 167.29 1352.03 167.14 1352.34 167.14C1352.77 167.14 1353.11 167.28 1353.04 167.85C1353 168.23 1352.79 168.39 1352.47 168.39C1352.04 168.39 1351.67 168.24 1351.78 167.65L1351.79 167.66Z" fill="white"/>
                    <path d="M1363.54 291.38C1363.67 291.07 1363.84 290.9 1364.22 290.95C1364.79 291.03 1364.84 291.39 1364.74 291.8C1364.67 292.11 1364.46 292.27 1364.08 292.22C1363.5 292.14 1363.5 291.79 1363.54 291.38Z" fill="white"/>
                    <path d="M1569.53 349.2C1569.69 348.84 1569.85 348.67 1570.23 348.72C1570.8 348.8 1570.85 349.16 1570.75 349.57C1570.68 349.88 1570.47 350.03 1570.09 349.99C1569.5 349.92 1569.54 349.54 1569.53 349.2Z" fill="white"/>
                    <path d="M1544.04 351.15C1544.63 351.19 1544.69 351.58 1544.59 352C1544.52 352.31 1544.31 352.47 1543.93 352.42C1543.36 352.34 1543.31 351.98 1543.41 351.57C1543.48 351.26 1543.7 351.04 1544.05 351.16L1544.04 351.15Z" fill="white"/>
                    <path d="M1274.14 229.16C1274.08 229.56 1274.01 229.97 1273.44 229.85C1273.26 229.81 1273.11 229.69 1272.94 229.6C1273.25 229.28 1273.57 228.96 1273.88 228.65C1273.96 228.82 1274.05 228.99 1274.14 229.16Z" fill="white"/>
                    <path d="M1328.82 215.44C1328.88 215.04 1328.95 214.63 1329.52 214.75C1329.7 214.79 1329.85 214.91 1330.02 215C1329.71 215.32 1329.39 215.64 1329.08 215.95C1329 215.78 1328.91 215.61 1328.82 215.44Z" fill="white"/>
                    <path d="M1347.73 358.56C1348.06 358.29 1348.37 358.04 1348.68 357.79C1348.75 358.06 1348.92 358.35 1348.87 358.59C1348.76 359.13 1348.31 359.05 1347.93 358.93C1347.83 358.9 1347.79 358.66 1347.74 358.56H1347.73Z" fill="white"/>
                    <path d="M1446.09 210.29C1446.3 210.4 1446.62 210.5 1446.61 210.57C1446.56 211.12 1445.98 211.18 1445.68 211.51C1445.6 211.34 1445.45 211.17 1445.45 211C1445.45 210.57 1445.6 210.23 1446.09 210.28V210.29Z" fill="white"/>
                    <path d="M1485.24 119.7C1485.34 119.58 1485.45 119.37 1485.56 119.36C1485.95 119.34 1486.41 119.38 1486.38 119.93C1486.36 120.18 1486.13 120.42 1485.99 120.66C1485.74 120.34 1485.5 120.03 1485.24 119.7Z" fill="white"/>
                    <path d="M1463.46 141.83C1462.9 141.78 1462.89 141.38 1462.98 140.98C1463.02 140.82 1463.21 140.69 1463.33 140.55C1463.56 140.94 1463.78 141.32 1464.01 141.71C1463.83 141.75 1463.65 141.79 1463.46 141.84V141.83Z" fill="white"/>
                    <path d="M1361.15 336.97C1361.2 336.9 1361.3 336.68 1361.4 336.68C1361.79 336.66 1362.26 336.69 1362.24 337.23C1362.24 337.48 1362 337.72 1361.87 337.96C1361.64 337.64 1361.41 337.32 1361.16 336.97H1361.15Z" fill="white"/>
                    <path d="M1367.88 297.07C1367.93 296.51 1368.33 296.5 1368.73 296.59C1368.89 296.63 1369.02 296.82 1369.16 296.94C1368.77 297.17 1368.39 297.39 1368 297.62C1367.96 297.44 1367.92 297.26 1367.87 297.07H1367.88Z" fill="white"/>
                    <path d="M1415.2 124.36C1415.32 124.21 1415.44 124.06 1415.55 123.91C1415.79 124.28 1416.04 124.65 1416.28 125.02C1416.1 125.07 1415.92 125.16 1415.74 125.16C1415.18 125.16 1415.14 124.78 1415.19 124.35L1415.2 124.36Z" fill="white"/>
                    <path d="M1539.53 331.68C1538.97 331.63 1538.96 331.23 1539.05 330.83C1539.09 330.67 1539.28 330.54 1539.4 330.4C1539.63 330.79 1539.85 331.17 1540.08 331.56C1539.9 331.6 1539.72 331.64 1539.53 331.69V331.68Z" fill="white"/>
                    <path d="M1582.42 334.52C1582.31 334.28 1582.17 334.11 1582.18 333.94C1582.18 333.5 1582.35 333.15 1582.89 333.28C1583.06 333.32 1583.21 333.46 1583.37 333.55C1583.06 333.87 1582.75 334.18 1582.42 334.52Z" fill="white"/>
                    <path d="M1367.25 419.62C1367.5 419.28 1367.82 419.46 1368.1 419.51C1368.19 419.53 1368.32 419.84 1368.27 419.92C1368.07 420.26 1367.73 420.07 1367.45 420.02C1367.36 420 1367.31 419.75 1367.25 419.62Z" fill="white"/>
                    <path d="M1356 394.74C1355.94 394.4 1355.85 394.12 1355.87 393.85C1355.88 393.7 1356.1 393.56 1356.22 393.42C1356.29 393.69 1356.42 393.96 1356.41 394.23C1356.41 394.38 1356.19 394.52 1356 394.74Z" fill="white"/>
                    <path d="M1571.99 223.67C1572.24 223.33 1572.56 223.51 1572.84 223.56C1572.92 223.58 1573.06 223.89 1573.01 223.97C1572.81 224.31 1572.47 224.12 1572.19 224.07C1572.1 224.05 1572.05 223.8 1571.99 223.67Z" fill="white"/>
                    <path d="M1492.87 138.17C1493.21 138.11 1493.49 138.02 1493.76 138.04C1493.91 138.05 1494.05 138.27 1494.19 138.39C1493.92 138.46 1493.65 138.59 1493.38 138.58C1493.23 138.58 1493.09 138.36 1492.87 138.17Z" fill="white"/>
                    <path d="M1489.51 138.17C1489.67 138.2 1489.96 138.22 1490.2 138.33C1490.49 138.46 1490.46 138.68 1490.15 138.73C1489.89 138.77 1489.59 138.73 1489.34 138.63C1489.05 138.51 1489.09 138.33 1489.51 138.18V138.17Z" fill="white"/>
                    <path d="M1582.08 207.66C1581.92 207.57 1581.62 207.48 1581.62 207.38C1581.6 207.01 1581.89 206.96 1582.18 207.05C1582.35 207.1 1582.64 207.22 1582.64 207.32C1582.66 207.69 1582.38 207.73 1582.08 207.66Z" fill="white"/>
                    <path d="M1333.3 367.22C1333.39 367.06 1333.48 366.76 1333.58 366.76C1333.95 366.74 1334 367.03 1333.91 367.32C1333.86 367.49 1333.74 367.78 1333.64 367.78C1333.27 367.8 1333.23 367.52 1333.3 367.22Z" fill="white"/>
                    <path d="M1409.23 133.91C1409.17 133.57 1409.08 133.29 1409.1 133.02C1409.11 132.87 1409.33 132.73 1409.45 132.59C1409.52 132.86 1409.65 133.13 1409.64 133.4C1409.64 133.55 1409.42 133.69 1409.23 133.91Z" fill="white"/>
                    <path d="M1499.2 392.69C1499.29 392.59 1499.41 392.44 1499.54 392.3C1499.61 392.57 1499.74 392.84 1499.73 393.11C1499.73 393.26 1499.51 393.4 1499.38 393.54C1499.16 393.31 1499.07 393.05 1499.2 392.69Z" fill="white"/>
                    <path d="M1361.46 130.41C1361.77 130.42 1362.03 130.52 1361.92 130.88C1361.89 130.98 1361.59 131.02 1361.4 131.03C1361.1 131.04 1360.83 130.93 1360.94 130.57C1360.97 130.47 1361.28 130.46 1361.46 130.41Z" fill="white"/>
                    <path d="M1505.63 350.8C1505.94 350.81 1506.2 350.91 1506.09 351.27C1506.06 351.37 1505.76 351.41 1505.57 351.42C1505.27 351.43 1505 351.32 1505.11 350.96C1505.14 350.86 1505.45 350.85 1505.63 350.8Z" fill="white"/>
                    <path d="M1497.47 340.32C1497.78 340.33 1498.04 340.43 1497.93 340.79C1497.9 340.89 1497.6 340.93 1497.41 340.94C1497.11 340.95 1496.84 340.84 1496.95 340.48C1496.98 340.38 1497.29 340.37 1497.47 340.32Z" fill="white"/>
                    <path d="M1462.22 306.39C1462.16 306.05 1462.07 305.77 1462.09 305.5C1462.1 305.35 1462.32 305.21 1462.44 305.07C1462.51 305.34 1462.64 305.61 1462.63 305.88C1462.63 306.03 1462.41 306.17 1462.22 306.39Z" fill="white"/>
                    <path d="M1355.43 346.09C1355.56 345.9 1355.72 345.67 1355.88 345.44C1355.96 345.61 1356.14 345.8 1356.11 345.94C1356.05 346.2 1355.86 346.43 1355.72 346.68C1355.64 346.51 1355.55 346.34 1355.43 346.09Z" fill="white"/>
                    <path d="M1393.44 254.03C1393.53 253.93 1393.65 253.78 1393.78 253.64C1393.85 253.91 1393.98 254.18 1393.97 254.45C1393.97 254.6 1393.75 254.74 1393.62 254.88C1393.4 254.65 1393.31 254.39 1393.44 254.03Z" fill="white"/>
                    <path d="M1346.81 240.32C1346.75 239.98 1346.66 239.7 1346.68 239.43C1346.69 239.28 1346.91 239.14 1347.03 239C1347.1 239.27 1347.23 239.54 1347.22 239.81C1347.22 239.96 1347 240.1 1346.81 240.32Z" fill="white"/>
                    <path d="M1273.55 219.38C1273.8 219.04 1274.12 219.22 1274.4 219.27C1274.48 219.29 1274.62 219.6 1274.57 219.68C1274.37 220.02 1274.03 219.83 1273.75 219.78C1273.66 219.76 1273.61 219.51 1273.55 219.38Z" fill="white"/>
                    <path d="M1338.06 196.89C1338.37 196.9 1338.63 197 1338.52 197.36C1338.49 197.46 1338.19 197.5 1338 197.51C1337.7 197.52 1337.43 197.41 1337.54 197.05C1337.57 196.95 1337.88 196.94 1338.06 196.89Z" fill="white"/>
                    <path d="M1554.92 233.3C1554.76 233.21 1554.46 233.12 1554.46 233.02C1554.44 232.65 1554.73 232.6 1555.02 232.69C1555.19 232.74 1555.48 232.86 1555.48 232.96C1555.5 233.33 1555.22 233.37 1554.92 233.3Z" fill="white"/>
                    <path d="M1452.92 237.01C1453.01 236.85 1453.1 236.55 1453.2 236.55C1453.57 236.53 1453.62 236.82 1453.53 237.11C1453.48 237.28 1453.36 237.57 1453.26 237.57C1452.89 237.59 1452.85 237.31 1452.92 237.01Z" fill="white"/>
                    <path d="M1454 220.76C1453.95 220.61 1453.87 220.47 1453.86 220.32C1453.86 220.27 1454 220.2 1454.08 220.14C1454.13 220.29 1454.21 220.43 1454.22 220.58C1454.22 220.63 1454.08 220.7 1454 220.76Z" fill="white"/>
                    <path d="M1413.08 113.49C1413.03 113.34 1412.95 113.2 1412.94 113.05C1412.94 113 1413.08 112.93 1413.16 112.87C1413.21 113.02 1413.29 113.16 1413.3 113.31C1413.3 113.36 1413.16 113.43 1413.08 113.49Z" fill="white"/>
                    <path d="M1485.56 335.47C1485.65 335.35 1485.74 335.22 1485.83 335.1C1485.89 335.18 1486.02 335.28 1486 335.33C1485.95 335.47 1485.84 335.59 1485.75 335.72C1485.69 335.64 1485.63 335.56 1485.56 335.47Z" fill="white"/>
                    <path d="M1530.19 355.52C1530.1 355.59 1530.02 355.65 1529.94 355.71C1529.89 355.56 1529.81 355.42 1529.8 355.27C1529.8 355.22 1529.94 355.15 1530.02 355.09C1530.08 355.23 1530.14 355.38 1530.19 355.52Z" fill="white"/>
                    <path d="M1439.62 345.84C1439.74 345.93 1439.87 346.02 1439.99 346.11C1439.91 346.17 1439.81 346.3 1439.76 346.28C1439.62 346.23 1439.5 346.12 1439.37 346.03C1439.45 345.97 1439.53 345.91 1439.62 345.84Z" fill="white"/>
                    <path d="M1410.85 306.54C1411 306.49 1411.14 306.41 1411.29 306.4C1411.34 306.4 1411.41 306.54 1411.47 306.62C1411.32 306.67 1411.18 306.75 1411.03 306.76C1410.98 306.76 1410.91 306.62 1410.85 306.54Z" fill="white"/>
                    <path d="M1360.97 295.07C1361.04 295.16 1361.1 295.24 1361.16 295.32C1361.01 295.37 1360.87 295.45 1360.72 295.46C1360.67 295.46 1360.6 295.32 1360.54 295.24C1360.68 295.18 1360.83 295.12 1360.97 295.07Z" fill="white"/>
                    <path d="M1333.41 351.88C1333.5 351.76 1333.59 351.63 1333.68 351.51C1333.74 351.59 1333.87 351.69 1333.85 351.74C1333.8 351.88 1333.69 352 1333.6 352.13C1333.54 352.05 1333.48 351.97 1333.41 351.88Z" fill="white"/>
                    <path d="M1459.74 350.37C1459.83 350.3 1459.91 350.24 1459.99 350.18C1460.04 350.33 1460.12 350.47 1460.13 350.62C1460.13 350.67 1459.99 350.74 1459.91 350.8C1459.85 350.66 1459.79 350.51 1459.74 350.37Z" fill="white"/>
                    <path d="M1428.12 278.5C1428.03 278.57 1427.95 278.63 1427.87 278.69C1427.82 278.54 1427.74 278.4 1427.73 278.25C1427.73 278.2 1427.87 278.13 1427.95 278.07C1428.01 278.21 1428.07 278.36 1428.12 278.5Z" fill="white"/>
                    <path d="M1279.68 247.61C1279.62 247.53 1279.5 247.41 1279.51 247.39C1279.6 247.26 1279.7 247.11 1279.84 247.06C1280.12 246.96 1280.23 247.11 1280.07 247.35C1279.99 247.47 1279.81 247.52 1279.68 247.6V247.61Z" fill="white"/>
                    <path d="M1280.14 246.81C1280.27 246.73 1280.45 246.55 1280.54 246.59C1280.77 246.69 1280.79 246.89 1280.61 246.97C1280.46 247.04 1280.28 247.05 1280.11 247.09C1280.12 247 1280.13 246.91 1280.14 246.81Z" fill="white"/>
                    <path d="M1483.61 209.07C1483.7 208.95 1483.79 208.82 1483.88 208.7C1483.94 208.78 1484.07 208.88 1484.05 208.93C1484 209.07 1483.89 209.19 1483.8 209.32C1483.74 209.24 1483.68 209.16 1483.61 209.07Z" fill="white"/>
                    <path d="M1268.96 337.56C1269.05 337.44 1269.14 337.31 1269.23 337.19C1269.29 337.27 1269.42 337.37 1269.4 337.42C1269.35 337.56 1269.24 337.68 1269.15 337.81C1269.09 337.73 1269.03 337.65 1268.96 337.56Z" fill="white"/>
                    <path d="M1273.96 241.5C1274.05 241.43 1274.13 241.37 1274.21 241.31C1274.26 241.46 1274.34 241.6 1274.35 241.75C1274.35 241.8 1274.21 241.87 1274.13 241.93C1274.07 241.79 1274.01 241.64 1273.96 241.5Z" fill="white"/>
                    <path d="M1312.29 352.86C1312.2 352.98 1312.11 353.11 1312.02 353.23C1311.96 353.15 1311.83 353.05 1311.85 353C1311.9 352.86 1312.01 352.74 1312.1 352.61C1312.16 352.69 1312.22 352.77 1312.29 352.86Z" fill="white"/>
                    <path d="M1380.67 215.2C1380.76 215.08 1380.85 214.95 1380.94 214.83C1381 214.91 1381.13 215.01 1381.11 215.06C1381.06 215.2 1380.95 215.32 1380.86 215.45C1380.8 215.37 1380.74 215.29 1380.67 215.2Z" fill="white"/>
                    <path d="M1408.68 357.76C1408.75 357.85 1408.81 357.93 1408.87 358.01C1408.72 358.06 1408.58 358.14 1408.43 358.15C1408.38 358.15 1408.31 358.01 1408.25 357.93C1408.39 357.87 1408.54 357.81 1408.68 357.76Z" fill="white"/>
                    <path d="M1309.37 232.43C1309.32 232.28 1309.24 232.14 1309.23 231.99C1309.23 231.94 1309.37 231.87 1309.45 231.81C1309.5 231.96 1309.58 232.1 1309.59 232.25C1309.59 232.3 1309.45 232.37 1309.37 232.43Z" fill="white"/>
                    <path d="M1394.98 358.9C1395.07 358.78 1395.16 358.65 1395.25 358.53C1395.31 358.61 1395.44 358.71 1395.42 358.76C1395.37 358.9 1395.26 359.02 1395.17 359.15L1394.98 358.9Z" fill="white"/>
                    <path d="M1445.19 246.69C1445.07 246.6 1444.94 246.51 1444.82 246.42C1444.9 246.36 1445 246.23 1445.05 246.25C1445.19 246.3 1445.31 246.41 1445.44 246.5C1445.36 246.56 1445.28 246.62 1445.19 246.69Z" fill="white"/>
                    <path d="M1582.94 198.3C1582.89 198.15 1582.81 198.01 1582.8 197.86C1582.8 197.81 1582.94 197.74 1583.02 197.68C1583.07 197.83 1583.15 197.97 1583.16 198.12C1583.16 198.17 1583.02 198.24 1582.94 198.3Z" fill="white"/>
                    <path d="M1547.13 449.92C1545.13 450.27 1544.22 448.98 1543.39 447.75C1543.08 447.3 1542.72 446.53 1542.47 446.36C1540.92 445.24 1542.9 444.15 1542.35 443.12C1543.43 442.74 1544.6 441.92 1545.57 442.1C1546.5 442.28 1547.64 442.59 1548.48 443.23C1549.28 443.83 1550.67 444.31 1549.42 445.77C1549.24 445.99 1549.5 446.56 1549.51 446.97C1549.56 448.79 1548.71 449.77 1547.12 449.93L1547.13 449.92Z" fill="white"/>
                    <path d="M1640.09 315.08C1640.19 316.27 1639.32 317.21 1638.9 317.24C1637.28 317.36 1636.76 318.9 1635.52 319.49C1634.65 319.9 1634.05 320.02 1633.37 319.38C1632.24 318.33 1632.05 317.64 1632.64 316.68C1632.89 316.26 1633.09 315.84 1633.28 315.39C1634.1 313.45 1638.22 312.3 1639.65 314.26C1639.9 314.61 1640.13 314.89 1640.09 315.09V315.08Z" fill="white"/>
                    <path d="M1545.76 340.77C1545.06 341.56 1545.43 343.05 1543.99 343.3C1543.79 343.34 1543.54 343.28 1543.38 343.38C1542.72 343.79 1542.17 344.47 1541.26 344.09C1540.55 343.8 1540.37 343.06 1540.01 342.49C1539.3 341.36 1539.75 339.52 1540.9 338.61C1541.8 337.9 1542.71 337.85 1543.78 338.31C1544.89 338.79 1545.13 339.91 1545.75 340.77H1545.76Z" fill="white"/>
                    <path d="M1466.55 218.82C1466.38 217.03 1466.96 216.15 1468.23 215.87C1468.93 215.72 1469.67 215.74 1470.38 215.63C1471.05 215.52 1471.29 215.8 1471.32 216.44C1471.35 217.16 1471.56 217.89 1471.5 218.6C1471.4 219.8 1470.67 220.67 1469.65 221.26C1469.44 221.38 1469 221.38 1468.83 221.24C1468.21 220.72 1467.47 220.45 1466.79 220.07C1466.46 219.89 1466.55 218.94 1466.53 218.81L1466.55 218.82Z" fill="white"/>
                    <path d="M1598.32 186.03C1598.47 186.83 1598.7 187.97 1597.48 188.43C1596.9 188.65 1595.81 189.06 1595.51 188.34C1595.18 187.56 1594.18 186.96 1594.59 185.93C1594.88 185.21 1595.44 184.56 1595.54 183.83C1595.64 183.16 1595.84 183 1596.44 182.93C1597.3 182.83 1597.82 183.29 1598.08 183.96C1598.29 184.52 1598.23 185.18 1598.32 186.02V186.03Z" fill="white"/>
                    <path d="M1444.08 219.22C1444.18 220.03 1443.61 220.79 1442.81 220.92C1442.54 220.96 1439.85 219.77 1439.73 219.56C1439.46 219.08 1440.32 217.39 1440.38 217.46C1441.2 218.47 1441.68 216.95 1442.35 217.25C1442.99 217.54 1444.01 218.69 1444.07 219.22H1444.08Z" fill="white"/>
                    <path d="M1503.62 393.64C1504.05 393.61 1504.69 393.43 1504.74 393.84C1504.83 394.6 1505.26 395.39 1504.62 396.18C1504.27 396.62 1503.7 396.6 1503.41 396.95C1502.93 397.53 1502.56 397.66 1501.9 397.17C1501.19 396.65 1501.01 395.91 1501.36 395.33C1501.86 394.51 1502.37 393.47 1503.63 393.63L1503.62 393.64Z" fill="white"/>
                    <path d="M1576.17 313.96C1576.15 313.31 1575.73 312.5 1576.97 312.43C1577.25 312.41 1577.48 311.82 1577.76 311.52C1578.08 311.18 1578.42 310.86 1578.75 310.53C1578.89 311.21 1579.29 311.98 1579.1 312.55C1578.85 313.28 1578.1 313.83 1577.62 314.49C1577.35 314.85 1577.03 315.14 1576.61 315C1576.15 314.85 1576.23 314.35 1576.18 313.96H1576.17Z" fill="white"/>
                    <path d="M1412.55 340.9C1412.53 340.7 1412.49 340.5 1412.49 340.3C1412.51 339.85 1412.11 339.24 1412.79 339.01C1413.49 338.77 1414.14 338.93 1414.69 339.51C1415.16 340 1414.95 340.35 1414.64 340.8C1414.44 341.09 1414.49 341.55 1414.29 341.85C1414.03 342.24 1413.79 342.79 1413.17 342.7C1412.44 342.59 1412.71 341.94 1412.6 341.51C1412.55 341.32 1412.55 341.11 1412.53 340.91L1412.55 340.9Z" fill="white"/>
                    <path d="M1449.94 209.57C1449.07 209.19 1448.05 209.06 1447.39 208.26C1447.24 208.08 1447.19 207.69 1447.47 207.42C1447.82 207.08 1448.03 206.41 1448.62 206.59C1449.35 206.81 1450.03 207.2 1450.69 207.58C1451.47 208.04 1450.42 208.31 1450.52 208.73C1450.62 209.17 1450.52 209.53 1449.94 209.55V209.57Z" fill="white"/>
                    <path d="M1584.32 363.88C1584.17 363.33 1584.32 362.97 1585.01 362.94C1586.08 362.89 1586.73 363.39 1586.87 364.33C1586.99 365.17 1586.52 365.68 1585.89 365.95C1585.17 366.26 1584.42 365.24 1584.32 363.88Z" fill="white"/>
                    <path d="M1666.29 407.97C1666.26 407.66 1666.17 407.35 1666.21 407.05C1666.29 406.35 1667.1 406.04 1667.65 406.48C1667.97 406.74 1668.35 406.95 1668.59 407.27C1668.9 407.68 1669 408.29 1668.74 408.68C1668.44 409.13 1667.66 409.08 1667.37 409.65C1666.98 409.22 1666.24 409.06 1666.32 408.27C1666.33 408.17 1666.3 408.07 1666.29 407.96V407.97Z" fill="white"/>
                    <path d="M1463.74 265.4C1463.51 265.68 1463.3 265.91 1463.12 266.16C1462.67 266.79 1461.7 266.17 1461.29 266.97C1461.08 267.39 1460.61 267.44 1460.28 267.1C1459.97 266.78 1460.05 266.39 1460.35 266.05C1460.75 265.59 1461.1 265.08 1461.53 264.64C1461.73 264.43 1461.81 263.79 1462.36 264.27C1462.81 264.66 1463.28 265.02 1463.73 265.4H1463.74Z" fill="white"/>
                    <path d="M1528.41 154.78C1529.07 154.81 1529.78 155.77 1529.7 156.51C1529.67 156.81 1528.18 158.01 1527.83 157.95C1526.93 157.79 1527.28 157 1527.23 156.49C1527.15 155.55 1527.78 154.74 1528.41 154.77V154.78Z" fill="white"/>
                    <path d="M1581.72 222.57C1581.1 222.73 1580.71 222.63 1580.67 221.92C1580.62 221.05 1581.13 220.14 1581.75 220.08C1582.36 220.02 1583.07 220.82 1583.15 221.69C1583.27 222.98 1582.2 222.34 1581.72 222.57Z" fill="white"/>
                    <path d="M1588.57 225.8C1588.32 226.31 1588.08 226.89 1587.44 227C1586.74 227.12 1585.9 225.98 1586 225.13C1586.06 224.58 1586.47 224.57 1586.86 224.57C1587.78 224.57 1588.58 225.15 1588.56 225.8H1588.57Z" fill="white"/>
                    <path d="M1495.49 245.39C1495.45 244.99 1495.45 244.78 1495.41 244.59C1495.24 243.85 1495.91 243.56 1496.2 243.07C1496.58 243.49 1497.3 243.65 1497.28 244.4C1497.28 244.6 1497.3 244.81 1497.34 245C1497.46 245.67 1497.42 246.12 1496.52 246.19C1495.66 246.26 1495.43 245.94 1495.5 245.39H1495.49Z" fill="white"/>
                    <path d="M1673.93 246.45C1673.21 246.57 1673.02 246.11 1673.14 245.74C1673.38 245.01 1673.76 244.32 1674.14 243.64C1674.49 243.01 1674.76 243.61 1675.03 243.77C1676.21 244.47 1674.91 245.14 1674.97 245.85C1675.03 246.67 1674.22 246.27 1673.93 246.45Z" fill="white"/>
                    <path d="M1529.58 229.9C1529.82 230.73 1529.34 231.26 1528.72 231.55C1528.25 231.77 1526.96 230.65 1527.13 230.26C1527.45 229.51 1528.06 229.14 1528.91 229.13C1529.51 229.13 1529.53 229.49 1529.58 229.9Z" fill="white"/>
                    <path d="M1449.2 400.99C1448.59 400.94 1448.21 400.5 1447.9 400.01C1447.72 399.72 1448.98 398.57 1449.61 398.45C1450.18 398.34 1450.27 398.73 1450.3 399.15C1450.36 400 1449.83 400.92 1449.2 400.99Z" fill="white"/>
                    <path d="M1490.84 209.84C1491.15 209.9 1491.47 210.09 1491.34 210.43C1491.01 211.23 1490.89 212.13 1490.27 212.82C1490.01 213.11 1489.67 213.17 1489.46 213.07C1489.26 212.98 1489.02 212.53 1489.08 212.35C1489.35 211.63 1489.72 210.94 1490.08 210.25C1490.23 209.96 1490.5 209.83 1490.85 209.85L1490.84 209.84Z" fill="white"/>
                    <path d="M1473.97 180.67C1474.03 180.99 1474.12 181.4 1473.59 181.56C1473.14 181.7 1472.88 181.34 1472.55 181.15C1471.87 180.77 1472 180.1 1472.03 179.55C1472.04 179.23 1472.53 178.64 1472.73 178.67C1473.13 178.73 1473.59 179.06 1473.82 179.41C1474.02 179.71 1473.93 180.19 1473.98 180.66L1473.97 180.67Z" fill="white"/>
                    <path d="M1642.72 200.18C1643.21 200.05 1643.57 200.21 1643.6 200.89C1643.64 201.75 1643.13 202.64 1642.52 202.71C1641.49 202.84 1642.07 201.48 1641.25 201.32C1641.11 201.29 1641.94 200.32 1642.72 200.18Z" fill="white"/>
                    <path d="M1479.96 302.41C1479.86 302.57 1479.8 302.82 1479.66 302.87C1478.9 303.14 1477.39 302.17 1477.37 301.39C1477.37 301.12 1477.43 300.73 1477.61 300.62C1477.79 300.51 1478.2 300.58 1478.42 300.72C1479.4 301.31 1479.9 301.9 1479.96 302.41Z" fill="white"/>
                    <path d="M1535.09 404.24C1535.39 404.21 1535.7 404.13 1536 404.16C1536.76 404.24 1536.49 404.9 1536.58 405.34C1536.71 405.91 1536.31 406 1535.88 406.04C1535.49 406.07 1535.11 406.19 1534.76 405.85C1534.4 405.51 1533.86 405.25 1534.05 404.66C1534.2 404.2 1534.71 404.3 1535.09 404.25V404.24Z" fill="white"/>
                    <path d="M1492.82 212.68C1492.88 212.04 1493.31 212.05 1493.7 212.05C1494.63 212.05 1495.42 212.63 1495.38 213.29C1495.35 213.84 1494.9 213.84 1494.5 213.84C1493.57 213.84 1492.8 213.26 1492.81 212.68H1492.82Z" fill="white"/>
                    <path d="M1642.32 191.46C1642.86 191.93 1643.48 192.25 1643.47 193.12C1643.47 193.84 1643.11 193.9 1642.57 193.97C1641.98 194.05 1641.7 193.95 1641.55 193.28C1641.35 192.41 1641.98 192.04 1642.32 191.46Z" fill="white"/>
                    <path d="M1647.86 206.07C1647.77 206.24 1647.71 206.52 1647.59 206.55C1647.2 206.65 1646.71 206.81 1646.41 206.66C1645.92 206.41 1645.28 206.14 1645.3 205.41C1645.3 204.85 1645.76 204.85 1646.16 204.85C1647.1 204.85 1647.84 205.4 1647.86 206.07Z" fill="white"/>
                    <path d="M1496.22 420.48C1496.25 420.9 1496.28 421.36 1495.7 421.32C1494.95 421.27 1494.33 420.86 1494.35 420.09C1494.35 419.69 1494.85 419.29 1495.12 418.89C1495.62 419.34 1496.35 419.62 1496.22 420.48Z" fill="white"/>
                    <path d="M1648.49 359.7C1647.75 359.72 1646.9 358.95 1646.93 358.43C1646.97 357.89 1647.41 357.93 1647.79 357.87C1648.56 357.75 1649.12 358.12 1649.29 358.77C1649.34 358.97 1648.88 359.65 1648.48 359.69L1648.49 359.7Z" fill="white"/>
                    <path d="M1549.08 218.67C1549.13 219.19 1549.16 219.73 1548.61 219.62C1547.94 219.49 1547.31 219.11 1546.67 218.83C1547.08 218.25 1547.49 217.76 1548.34 217.78C1549.14 217.8 1548.99 218.34 1549.08 218.67Z" fill="white"/>
                    <path d="M1686.42 291.17C1686.6 292 1686.2 292.1 1685.61 292.2C1684.71 292.36 1684.62 291.89 1684.54 291.2C1684.47 290.49 1684.8 290.39 1685.39 290.3C1686.26 290.16 1686.5 290.53 1686.41 291.17H1686.42Z" fill="white"/>
                    <path d="M1537.28 212.68C1536.73 212.64 1535.9 213.13 1535.94 212.08C1535.96 211.51 1536.37 210.97 1537.01 210.83C1538.06 210.61 1537.88 211.78 1538.2 212.21C1538.61 212.75 1537.57 212.43 1537.28 212.68Z" fill="white"/>
                    <path d="M1456.58 169.4C1456.61 169.7 1456.68 170.01 1456.66 170.3C1456.61 170.84 1456.17 170.83 1455.78 170.84C1455.5 170.84 1455.17 171.04 1454.95 170.72C1454.7 170.35 1454.73 170 1455.05 169.67C1455.19 169.53 1455.3 169.36 1455.44 169.2C1455.69 168.91 1455.99 168.36 1456.2 168.39C1456.66 168.46 1456.51 169.03 1456.59 169.4H1456.58Z" fill="white"/>
                    <path d="M1617.93 410.29C1618.66 410.44 1618.43 411.05 1618.49 411.49C1618.6 412.31 1617.92 412.13 1617.48 412.2C1617.07 412.27 1616.65 412.27 1616.65 411.67C1616.65 411.06 1617.4 410.26 1617.93 410.28V410.29Z" fill="white"/>
                    <path d="M1607.86 246.85C1607.32 246.88 1606.9 246.92 1606.9 246.32C1606.9 245.71 1607.65 244.89 1608.18 244.94C1608.72 244.99 1608.64 245.45 1608.73 245.83C1608.96 246.75 1608.4 246.85 1607.86 246.85Z" fill="white"/>
                    <path d="M1502.71 189.22C1502.92 189.26 1503.35 189.22 1503.44 189.37C1503.7 189.8 1503.19 190.06 1502.97 190.22C1502.62 190.47 1502.63 191.61 1501.77 190.8C1501.48 190.53 1501.09 190.33 1501.18 189.87C1501.35 188.93 1502.17 189.52 1502.72 189.22H1502.71Z" fill="white"/>
                    <path d="M1433.04 333.33C1432.8 333.75 1432.6 334.62 1432.07 334.29C1431.61 334.01 1430.83 334.38 1430.69 333.66C1430.61 333.23 1432.05 332.46 1432.62 332.63C1432.92 332.71 1433.07 332.97 1433.05 333.33H1433.04Z" fill="white"/>
                    <path d="M1667.55 295.25C1667.55 295.25 1667.9 295.68 1668.14 296.11C1668.25 296.3 1667.16 297.21 1666.88 297.18C1666.12 297.09 1666.39 296.45 1666.29 296.02C1666.17 295.5 1666.5 295.33 1667.55 295.24V295.25Z" fill="white"/>
                    <path d="M1666.14 331.83C1666.01 332.56 1665.39 332.3 1664.96 332.4C1664.41 332.52 1664.17 332.12 1664.29 331.73C1664.42 331.29 1664.77 330.89 1665.09 330.53C1665.21 330.4 1666.17 331.56 1666.14 331.83Z" fill="white"/>
                    <path d="M1526.54 459.46C1526.02 459.62 1525.78 459.39 1525.75 459.1C1525.68 458.31 1526.14 457.84 1526.84 457.59C1527.2 457.46 1527.44 457.7 1527.48 458C1527.58 458.82 1527.06 459.23 1526.54 459.46Z" fill="white"/>
                    <path d="M1458.82 291.83C1459.48 291.86 1460.01 292.23 1460.06 292.75C1460.09 293.01 1459.67 293.65 1459.06 293.69C1458.25 293.75 1458.43 293.12 1458.35 292.68C1458.28 292.27 1458.3 291.88 1458.82 291.82V291.83Z" fill="white"/>
                    <path d="M1624.23 429.67C1623.95 429.74 1622.68 429.02 1622.78 428.88C1623.06 428.49 1623.39 428.08 1623.8 427.87C1624.18 427.67 1624.58 427.88 1624.57 428.42C1624.57 428.86 1624.95 429.42 1624.23 429.67Z" fill="white"/>
                    <path d="M1456.39 285.98C1456.27 286.54 1455.97 287.14 1455.14 287.2C1454.84 287.22 1454.56 287.04 1454.61 286.65C1454.71 285.91 1455.08 285.36 1455.87 285.28C1456.17 285.25 1456.43 285.44 1456.38 285.98H1456.39Z" fill="white"/>
                    <path d="M1387.44 277.73C1387.53 278.29 1387.15 278.54 1386.76 278.42C1386.32 278.29 1385.92 277.94 1385.56 277.62C1385.43 277.5 1386.59 276.54 1386.86 276.57C1387.25 276.61 1387.47 277.06 1387.43 277.73H1387.44Z" fill="white"/>
                    <path d="M1539.56 217.24C1539.59 217.67 1539.63 218.1 1539.05 218.11C1538.42 218.12 1537.89 217.73 1537.84 217.2C1537.82 216.94 1538.23 216.3 1538.84 216.26C1539.66 216.2 1539.45 216.84 1539.56 217.24Z" fill="white"/>
                    <path d="M1442.75 200.5C1442.53 201.04 1442.28 201.51 1441.66 201.65C1441.3 201.73 1441.03 201.52 1441.04 201.22C1441.06 200.72 1440.5 199.99 1441.49 199.79C1442.09 199.67 1442.29 200.3 1442.75 200.5Z" fill="white"/>
                    <path d="M1530.69 228.5C1530.6 228.66 1530.5 228.82 1530.41 228.98C1529.71 228.43 1529.08 227.75 1528.11 227.64C1528.07 227.64 1528.01 227.24 1528.06 227.05C1528.09 226.94 1528.41 226.8 1528.44 226.83C1529.02 227.41 1530.05 227.17 1530.51 227.94C1530.61 228.1 1530.63 228.31 1530.68 228.5H1530.69Z" fill="white"/>
                    <path d="M1526.1 230.03C1525.49 230.05 1525.74 229.23 1525.74 228.76C1525.74 227.82 1526.59 228.23 1527.08 228.1C1527.23 228.06 1527.81 228.34 1527.32 228.55C1526.59 228.87 1527.61 230.04 1526.1 230.03Z" fill="white"/>
                    <path d="M1556.04 420.26C1556.77 420.07 1557.04 420.75 1557.53 421.02C1557.3 421.33 1557.08 421.87 1556.82 421.89C1556.26 421.93 1555.84 421.52 1555.67 420.94C1555.57 420.59 1555.77 420.41 1556.04 420.26Z" fill="white"/>
                    <path d="M1661.84 290.82C1661.4 290.8 1660.81 291.12 1660.71 290.3C1660.63 289.71 1661.02 289.63 1661.44 289.61C1661.89 289.59 1662.48 289.3 1662.58 290.12C1662.66 290.72 1662.26 290.76 1661.84 290.82Z" fill="white"/>
                    <path d="M1473.64 427C1473.17 427.9 1472.56 428.15 1471.81 428.1C1471.64 428.09 1471.48 427.94 1471.31 427.85C1471.44 427.69 1471.6 427.56 1471.68 427.38C1472.01 426.7 1472.64 426.75 1473.22 426.74C1473.4 426.74 1473.58 426.97 1473.63 427H1473.64Z" fill="white"/>
                    <path d="M1693.53 293.05C1693.55 293.2 1693.18 293.32 1692.75 293.33C1692.3 293.34 1691.71 293.66 1691.6 292.83C1691.52 292.25 1691.89 292.15 1692.31 292.13C1692.76 292.11 1693.35 291.82 1693.53 293.05Z" fill="white"/>
                    <path d="M1632.6 268.03C1632.47 267.97 1632.23 267.92 1632.21 267.83C1632.07 267.15 1631.96 266.48 1632.61 265.92C1632.88 265.69 1633.06 265.6 1633.24 265.9C1633.52 266.38 1633.06 267.85 1632.6 268.03Z" fill="white"/>
                    <path d="M1589.76 380.75C1589.61 381.73 1588.78 381.19 1588.28 381.34C1588.05 381.41 1587.83 380.96 1587.89 380.65C1588.06 379.74 1588.85 380.15 1589.36 380.05C1589.6 380.01 1589.79 380.4 1589.75 380.75H1589.76Z" fill="white"/>
                    <path d="M1561.71 263.51C1561.56 264.49 1560.73 263.95 1560.23 264.1C1560 264.17 1559.78 263.72 1559.84 263.41C1560.01 262.5 1560.8 262.91 1561.31 262.81C1561.55 262.77 1561.74 263.16 1561.7 263.51H1561.71Z" fill="white"/>
                    <path d="M1453.61 350.94C1453.63 351.09 1453.26 351.21 1452.83 351.22C1452.38 351.23 1451.79 351.55 1451.68 350.72C1451.6 350.14 1451.97 350.04 1452.39 350.02C1452.84 350 1453.43 349.71 1453.61 350.94Z" fill="white"/>
                    <path d="M1565.8 181.75C1565.44 181.22 1565.06 180.67 1564.69 180.1C1564.67 180.07 1564.79 179.91 1564.87 179.88C1565.28 179.75 1565.62 179.87 1565.94 180.17C1566.31 180.51 1566.74 180.79 1566.46 181.37C1566.4 181.5 1566.15 181.55 1565.79 181.75H1565.8Z" fill="white"/>
                    <path d="M1514.44 206.84C1514.85 206.77 1515.28 206.67 1515.41 207.22C1515.57 207.93 1515.12 208.05 1514.55 208.08C1514.15 208.1 1513.71 208.24 1513.59 207.68C1513.43 206.97 1513.86 206.81 1514.44 206.84Z" fill="white"/>
                    <path d="M1465.68 246.66C1465.57 247.22 1465.45 247.49 1465.07 247.44C1464.33 247.34 1463.8 246.97 1463.7 246.18C1463.68 246.03 1463.86 245.85 1463.95 245.68C1464.55 246.02 1465.16 246.36 1465.69 246.66H1465.68Z" fill="white"/>
                    <path d="M1589.3 245.84C1589.43 246.38 1589.36 246.81 1589.1 246.82C1588.58 246.83 1587.88 247.39 1587.54 246.53C1587.42 246.24 1587.51 245.81 1587.79 245.77C1588.31 245.71 1588.96 245.34 1589.3 245.84Z" fill="white"/>
                    <path d="M1464.12 368.67C1464.14 369.1 1464.18 369.49 1463.58 369.53C1462.75 369.59 1462.92 368.96 1462.85 368.51C1462.79 368.1 1462.78 367.71 1463.38 367.66C1464.21 367.6 1464.01 368.24 1464.12 368.66V368.67Z" fill="white"/>
                    <path d="M1557.76 359.45C1558.74 359.6 1558.2 360.43 1558.35 360.93C1558.42 361.16 1557.97 361.38 1557.66 361.32C1556.75 361.15 1557.16 360.36 1557.06 359.85C1557.02 359.61 1557.41 359.42 1557.76 359.46V359.45Z" fill="white"/>
                    <path d="M1462.33 235.85C1462.74 235.78 1463.17 235.68 1463.3 236.23C1463.46 236.94 1463.01 237.06 1462.44 237.09C1462.04 237.11 1461.6 237.25 1461.48 236.69C1461.32 235.98 1461.75 235.82 1462.33 235.85Z" fill="white"/>
                    <path d="M1632.32 203.77C1633.02 203.63 1633.21 203.83 1633.28 204.15C1633.43 204.87 1632.96 204.98 1632.41 204.98C1632.13 204.98 1631.56 205.1 1631.63 204.86C1631.76 204.38 1632.18 203.97 1632.32 203.77Z" fill="white"/>
                    <path d="M1482.62 403.21C1482.56 403.37 1482.47 403.63 1482.38 403.89C1481.96 403.51 1481.28 403.35 1481.3 402.62C1481.3 402.31 1481.47 402.1 1481.85 402.08C1482.66 402.02 1482.49 402.64 1482.62 403.21Z" fill="white"/>
                    <path d="M1426.8 185.03C1426.54 185.11 1426.31 184.91 1426.24 184.61C1426.15 184.21 1426.16 183.76 1426.74 183.78C1427.15 183.8 1427.81 183.34 1427.9 184.05C1427.93 184.31 1427.49 184.95 1426.8 185.03Z" fill="white"/>
                    <path d="M1616.99 160.11C1617.15 160.82 1616.49 161.1 1616.18 161.58C1616.05 161.33 1615.85 161.09 1615.82 160.83C1615.77 160.39 1615.49 159.81 1616.3 159.69C1616.67 159.64 1616.84 159.8 1616.98 160.11H1616.99Z" fill="white"/>
                    <path d="M1433.77 219.31C1433.37 219.45 1433.81 219.41 1433.55 219.11C1433.41 218.94 1433.37 218.5 1433.49 218.34C1433.82 217.9 1434.05 217.23 1434.82 217.36C1434.91 217.37 1435.05 217.49 1435.04 217.53C1434.9 218.32 1434.15 218.69 1433.77 219.31Z" fill="white"/>
                    <path d="M1474.42 260.68C1474.02 261.09 1473.79 261.79 1473.05 261.62C1472.87 261.58 1472.67 261.14 1472.69 260.91C1472.76 260.17 1473.42 260.44 1473.85 260.33C1474.14 260.26 1474.39 260.31 1474.42 260.68Z" fill="white"/>
                    <path d="M1561.33 309.34C1561.35 309.54 1561.42 309.76 1561.37 309.94C1561.34 310.06 1561.1 310.24 1561.01 310.22C1560.68 310.13 1560.47 308.23 1560.76 307.99C1560.83 307.94 1561.16 308.05 1561.18 308.13C1561.26 308.53 1561.28 308.94 1561.32 309.34H1561.33Z" fill="white"/>
                    <path d="M1393.09 321.52C1393.19 322.15 1392.83 322.29 1392.4 322.28C1392.09 322.28 1391.88 322.11 1391.85 321.73C1391.8 321.16 1392.14 321.03 1392.56 321.04C1392.86 321.04 1393.16 321.17 1393.09 321.52Z" fill="white"/>
                    <path d="M1627.48 177.47C1627.42 177.8 1627.3 178 1626.92 178.04C1626.35 178.09 1626.22 177.76 1626.23 177.33C1626.23 177.02 1626.4 176.82 1626.78 176.78C1627.35 176.73 1627.44 177.07 1627.49 177.48L1627.48 177.47Z" fill="white"/>
                    <path d="M1617.27 405.48C1617.21 405.81 1617.09 406.01 1616.71 406.05C1616.14 406.1 1616.01 405.77 1616.02 405.34C1616.02 405.03 1616.19 404.83 1616.57 404.79C1617.14 404.74 1617.23 405.08 1617.28 405.49L1617.27 405.48Z" fill="white"/>
                    <path d="M1501.6 429.49C1501.22 429.52 1500.86 429.51 1500.8 428.93C1500.76 428.55 1500.92 428.35 1501.23 428.28C1501.65 428.19 1502 428.25 1502.07 428.82C1502.11 429.2 1501.95 429.36 1501.6 429.49Z" fill="white"/>
                    <path d="M1680.54 274.01C1681.08 274.19 1681.54 274.47 1681.67 275.06C1681.71 275.22 1681.55 275.57 1681.54 275.56C1680.97 275.45 1680.53 275.15 1680.4 274.56C1680.37 274.4 1680.49 274.21 1680.54 274.01Z" fill="white"/>
                    <path d="M1649.81 380.85C1649.92 381.2 1649.69 381.41 1649.39 381.48C1648.97 381.57 1648.62 381.51 1648.55 380.94C1648.51 380.56 1648.67 380.36 1648.98 380.29C1649.4 380.2 1649.78 380.26 1649.81 380.85Z" fill="white"/>
                    <path d="M1611.12 263.53C1611.06 263.86 1610.94 264.06 1610.56 264.1C1609.99 264.15 1609.86 263.82 1609.87 263.39C1609.87 263.08 1610.04 262.88 1610.42 262.84C1610.99 262.79 1611.08 263.13 1611.13 263.54L1611.12 263.53Z" fill="white"/>
                    <path d="M1398.72 252.94C1398.65 253.32 1398.53 253.53 1398.14 253.56C1397.57 253.61 1397.44 253.28 1397.45 252.85C1397.45 252.54 1397.62 252.34 1398 252.3C1398.59 252.24 1398.63 252.62 1398.72 252.94Z" fill="white"/>
                    <path d="M1422.99 245.43C1422.41 245.53 1422.27 245.16 1422.27 244.73C1422.27 244.42 1422.44 244.21 1422.82 244.18C1423.39 244.13 1423.52 244.46 1423.51 244.89C1423.51 245.2 1423.35 245.46 1422.98 245.43H1422.99Z" fill="white"/>
                    <path d="M1711.49 304.11C1711.46 303.71 1711.43 303.29 1712.02 303.29C1712.2 303.29 1712.38 303.37 1712.56 303.42C1712.33 303.8 1712.09 304.17 1711.86 304.55C1711.74 304.41 1711.62 304.26 1711.5 304.11H1711.49Z" fill="white"/>
                    <path d="M1661.53 329.47C1661.56 329.87 1661.59 330.29 1661 330.29C1660.82 330.29 1660.64 330.21 1660.46 330.16C1660.69 329.78 1660.93 329.41 1661.16 329.03C1661.28 329.17 1661.4 329.32 1661.52 329.47H1661.53Z" fill="white"/>
                    <path d="M1611.63 194.93C1611.37 195.26 1611.13 195.57 1610.88 195.88C1610.75 195.63 1610.53 195.39 1610.52 195.14C1610.5 194.59 1610.96 194.57 1611.36 194.6C1611.47 194.6 1611.56 194.83 1611.63 194.92V194.93Z" fill="white"/>
                    <path d="M1549.01 360.34C1548.78 360.28 1548.45 360.25 1548.44 360.18C1548.37 359.64 1548.92 359.45 1549.13 359.06C1549.25 359.21 1549.43 359.34 1549.47 359.5C1549.57 359.92 1549.49 360.28 1549.01 360.34Z" fill="white"/>
                    <path d="M1531.05 456.78C1530.98 456.92 1530.92 457.15 1530.82 457.18C1530.44 457.28 1529.99 457.35 1529.9 456.81C1529.86 456.56 1530.04 456.28 1530.11 456.02C1530.42 456.27 1530.73 456.52 1531.05 456.79V456.78Z" fill="white"/>
                    <path d="M1547.28 430.52C1547.84 430.44 1547.94 430.82 1547.93 431.24C1547.93 431.41 1547.77 431.57 1547.68 431.74C1547.38 431.42 1547.07 431.09 1546.77 430.77C1546.94 430.69 1547.1 430.61 1547.28 430.53V430.52Z" fill="white"/>
                    <path d="M1603.38 218.82C1603.34 218.89 1603.3 219.13 1603.2 219.16C1602.82 219.26 1602.37 219.34 1602.27 218.81C1602.22 218.57 1602.4 218.28 1602.47 218.02C1602.76 218.28 1603.06 218.54 1603.38 218.83V218.82Z" fill="white"/>
                    <path d="M1605.66 258.97C1605.74 259.53 1605.36 259.63 1604.94 259.62C1604.77 259.62 1604.61 259.46 1604.44 259.37C1604.76 259.07 1605.09 258.76 1605.41 258.46C1605.49 258.63 1605.57 258.79 1605.65 258.97H1605.66Z" fill="white"/>
                    <path d="M1597.91 436.8C1597.83 436.97 1597.75 437.14 1597.67 437.31C1597.35 437 1597.03 436.7 1596.72 436.39C1596.88 436.3 1597.04 436.17 1597.21 436.14C1597.75 436.02 1597.87 436.38 1597.92 436.8H1597.91Z" fill="white"/>
                    <path d="M1431.66 263.31C1432.22 263.23 1432.32 263.61 1432.31 264.03C1432.31 264.2 1432.15 264.36 1432.06 264.53C1431.76 264.21 1431.45 263.88 1431.15 263.56C1431.32 263.48 1431.48 263.4 1431.66 263.32V263.31Z" fill="white"/>
                    <path d="M1389.46 270.02C1389.62 270.23 1389.79 270.36 1389.83 270.53C1389.92 270.95 1389.83 271.33 1389.29 271.32C1389.11 271.32 1388.94 271.22 1388.76 271.17C1388.99 270.79 1389.22 270.42 1389.47 270.02H1389.46Z" fill="white"/>
                    <path d="M1579.23 140.06C1579.06 140.44 1578.71 140.34 1578.43 140.35C1578.34 140.35 1578.15 140.08 1578.18 139.99C1578.3 139.62 1578.67 139.72 1578.95 139.71C1579.04 139.71 1579.14 139.94 1579.23 140.05V140.06Z" fill="white"/>
                    <path d="M1595.62 161.69C1595.75 162.01 1595.91 162.26 1595.94 162.52C1595.96 162.67 1595.78 162.85 1595.7 163.02C1595.57 162.77 1595.39 162.54 1595.33 162.28C1595.3 162.14 1595.48 161.95 1595.62 161.69Z" fill="white"/>
                    <path d="M1424.03 375.15C1423.86 375.53 1423.51 375.43 1423.23 375.44C1423.14 375.44 1422.95 375.17 1422.98 375.08C1423.1 374.71 1423.47 374.81 1423.75 374.8C1423.84 374.8 1423.94 375.03 1424.03 375.14V375.15Z" fill="white"/>
                    <path d="M1519.58 440.56C1519.26 440.69 1519.01 440.85 1518.75 440.88C1518.6 440.9 1518.42 440.72 1518.25 440.64C1518.5 440.51 1518.73 440.33 1518.99 440.27C1519.13 440.24 1519.32 440.42 1519.58 440.56Z" fill="white"/>
                    <path d="M1522.84 439.82C1522.68 439.82 1522.4 439.87 1522.13 439.82C1521.82 439.76 1521.8 439.53 1522.09 439.42C1522.34 439.33 1522.64 439.3 1522.9 439.34C1523.21 439.39 1523.21 439.58 1522.84 439.82Z" fill="white"/>
                    <path d="M1417.79 392.9C1417.97 392.95 1418.27 392.97 1418.3 393.07C1418.4 393.43 1418.13 393.54 1417.83 393.52C1417.65 393.51 1417.35 393.46 1417.32 393.36C1417.22 393 1417.48 392.91 1417.79 392.91V392.9Z" fill="white"/>
                    <path d="M1623.69 183.36C1623.64 183.54 1623.62 183.84 1623.52 183.87C1623.16 183.97 1623.05 183.7 1623.07 183.4C1623.08 183.22 1623.13 182.92 1623.23 182.89C1623.59 182.79 1623.68 183.05 1623.68 183.36H1623.69Z" fill="white"/>
                    <path d="M1601.59 426.23C1601.72 426.55 1601.88 426.8 1601.91 427.06C1601.93 427.21 1601.75 427.39 1601.67 427.56C1601.54 427.31 1601.36 427.08 1601.3 426.82C1601.27 426.68 1601.45 426.49 1601.59 426.23Z" fill="white"/>
                    <path d="M1457.28 195.27C1457.22 195.39 1457.13 195.56 1457.04 195.72C1456.91 195.47 1456.73 195.24 1456.67 194.98C1456.64 194.84 1456.82 194.65 1456.91 194.48C1457.18 194.65 1457.32 194.89 1457.27 195.26L1457.28 195.27Z" fill="white"/>
                    <path d="M1648.66 419.08C1648.36 419.14 1648.08 419.1 1648.11 418.73C1648.11 418.63 1648.4 418.52 1648.58 418.47C1648.87 418.39 1649.15 418.45 1649.13 418.82C1649.13 418.92 1648.83 419 1648.67 419.08H1648.66Z" fill="white"/>
                    <path d="M1460.29 237.3C1459.99 237.36 1459.71 237.32 1459.74 236.95C1459.74 236.85 1460.03 236.74 1460.21 236.69C1460.5 236.61 1460.78 236.67 1460.76 237.04C1460.76 237.14 1460.46 237.22 1460.3 237.3H1460.29Z" fill="white"/>
                    <path d="M1470.51 245.65C1470.21 245.71 1469.93 245.67 1469.96 245.3C1469.96 245.2 1470.25 245.09 1470.43 245.04C1470.72 244.96 1471 245.02 1470.98 245.39C1470.98 245.49 1470.68 245.57 1470.52 245.65H1470.51Z" fill="white"/>
                    <path d="M1512.17 270.76C1512.3 271.08 1512.46 271.33 1512.49 271.59C1512.51 271.74 1512.33 271.92 1512.25 272.09C1512.12 271.84 1511.94 271.61 1511.88 271.35C1511.85 271.21 1512.03 271.02 1512.17 270.76Z" fill="white"/>
                    <path d="M1606.91 208.72C1606.82 208.94 1606.72 209.19 1606.62 209.45C1606.5 209.3 1606.28 209.16 1606.28 209.01C1606.28 208.74 1606.41 208.48 1606.49 208.21C1606.61 208.36 1606.73 208.5 1606.9 208.72H1606.91Z" fill="white"/>
                    <path d="M1590.38 306.32C1590.32 306.44 1590.23 306.61 1590.14 306.77C1590.01 306.52 1589.83 306.29 1589.77 306.03C1589.74 305.89 1589.92 305.7 1590.01 305.53C1590.28 305.7 1590.42 305.94 1590.37 306.31L1590.38 306.32Z" fill="white"/>
                    <path d="M1638.61 309.32C1638.74 309.64 1638.9 309.89 1638.93 310.15C1638.95 310.3 1638.77 310.48 1638.69 310.65C1638.56 310.4 1638.38 310.17 1638.32 309.91C1638.29 309.77 1638.47 309.58 1638.61 309.32Z" fill="white"/>
                    <path d="M1714.22 313.45C1714.05 313.83 1713.7 313.73 1713.42 313.74C1713.33 313.74 1713.14 313.47 1713.17 313.38C1713.29 313.01 1713.66 313.11 1713.94 313.1C1714.03 313.1 1714.13 313.33 1714.22 313.44V313.45Z" fill="white"/>
                    <path d="M1656.67 349.49C1656.37 349.55 1656.09 349.51 1656.12 349.14C1656.12 349.04 1656.41 348.93 1656.59 348.88C1656.88 348.8 1657.16 348.86 1657.14 349.23C1657.14 349.33 1656.84 349.41 1656.68 349.49H1656.67Z" fill="white"/>
                    <path d="M1438.45 362.05C1438.63 362.1 1438.93 362.12 1438.96 362.22C1439.06 362.58 1438.79 362.69 1438.49 362.67C1438.31 362.66 1438.01 362.61 1437.98 362.51C1437.88 362.15 1438.14 362.06 1438.45 362.06V362.05Z" fill="white"/>
                    <path d="M1536.49 335.95C1536.44 336.13 1536.42 336.43 1536.32 336.46C1535.96 336.56 1535.85 336.29 1535.87 335.99C1535.88 335.81 1535.93 335.51 1536.03 335.48C1536.39 335.38 1536.48 335.64 1536.48 335.95H1536.49Z" fill="white"/>
                    <path d="M1539.03 351.94C1539.11 352.07 1539.22 352.19 1539.27 352.34C1539.29 352.39 1539.16 352.49 1539.1 352.56C1539.02 352.43 1538.91 352.31 1538.86 352.16C1538.84 352.11 1538.97 352.01 1539.03 351.94Z" fill="white"/>
                    <path d="M1602.36 446.87C1602.44 447 1602.55 447.12 1602.6 447.27C1602.62 447.32 1602.49 447.42 1602.43 447.49C1602.35 447.36 1602.24 447.24 1602.19 447.09C1602.17 447.04 1602.3 446.94 1602.36 446.87Z" fill="white"/>
                    <path d="M1483.13 247.73C1483.07 247.87 1483.01 248.01 1482.95 248.15C1482.87 248.09 1482.73 248.02 1482.74 247.97C1482.76 247.82 1482.84 247.68 1482.89 247.53C1482.97 247.59 1483.04 247.65 1483.13 247.73Z" fill="white"/>
                    <path d="M1435.45 238.14C1435.52 238.05 1435.59 237.97 1435.65 237.9C1435.73 238.03 1435.84 238.15 1435.89 238.3C1435.91 238.35 1435.78 238.45 1435.72 238.52C1435.63 238.39 1435.54 238.27 1435.46 238.14H1435.45Z" fill="white"/>
                    <path d="M1525.36 227.54C1525.22 227.48 1525.08 227.42 1524.94 227.36C1525 227.28 1525.07 227.14 1525.12 227.15C1525.27 227.17 1525.41 227.25 1525.56 227.3C1525.5 227.38 1525.44 227.45 1525.36 227.54Z" fill="white"/>
                    <path d="M1561.92 259.28C1561.79 259.36 1561.67 259.47 1561.52 259.52C1561.47 259.54 1561.37 259.41 1561.3 259.35C1561.43 259.27 1561.55 259.16 1561.7 259.11C1561.75 259.09 1561.85 259.22 1561.92 259.28Z" fill="white"/>
                    <path d="M1612.8 259.39C1612.71 259.32 1612.63 259.25 1612.56 259.19C1612.69 259.11 1612.81 259 1612.96 258.95C1613.01 258.93 1613.11 259.06 1613.18 259.12C1613.05 259.21 1612.93 259.3 1612.8 259.38V259.39Z" fill="white"/>
                    <path d="M1626.98 198.25C1626.92 198.39 1626.86 198.53 1626.8 198.67C1626.72 198.61 1626.58 198.54 1626.59 198.49C1626.61 198.34 1626.69 198.2 1626.74 198.05C1626.82 198.11 1626.89 198.17 1626.98 198.25Z" fill="white"/>
                    <path d="M1504.87 227.59C1504.8 227.68 1504.73 227.76 1504.67 227.83C1504.59 227.7 1504.48 227.58 1504.43 227.43C1504.41 227.38 1504.54 227.28 1504.6 227.21C1504.69 227.34 1504.78 227.46 1504.86 227.59H1504.87Z" fill="white"/>
                    <path d="M1551.37 290.27C1551.44 290.18 1551.51 290.1 1551.57 290.03C1551.65 290.16 1551.76 290.28 1551.81 290.43C1551.83 290.48 1551.7 290.58 1551.64 290.65C1551.55 290.52 1551.46 290.4 1551.38 290.27H1551.37Z" fill="white"/>
                    <path d="M1702.06 287.45C1702.14 287.51 1702.28 287.6 1702.27 287.63C1702.21 287.77 1702.15 287.95 1702.03 288.02C1701.78 288.17 1701.64 288.06 1701.74 287.79C1701.79 287.66 1701.95 287.57 1702.06 287.46V287.45Z" fill="white"/>
                    <path d="M1701.78 288.33C1701.67 288.44 1701.53 288.65 1701.45 288.63C1701.2 288.58 1701.14 288.4 1701.3 288.28C1701.43 288.18 1701.6 288.13 1701.76 288.05C1701.76 288.14 1701.78 288.23 1701.79 288.33H1701.78Z" fill="white"/>
                    <path d="M1512.91 369.8C1512.85 369.94 1512.79 370.08 1512.73 370.22C1512.65 370.16 1512.51 370.09 1512.52 370.04C1512.54 369.89 1512.62 369.75 1512.67 369.6C1512.75 369.66 1512.82 369.72 1512.91 369.8Z" fill="white"/>
                    <path d="M1692.6 197.9C1692.54 198.04 1692.48 198.18 1692.42 198.32C1692.34 198.26 1692.2 198.19 1692.21 198.14C1692.23 197.99 1692.31 197.85 1692.36 197.7C1692.44 197.76 1692.51 197.82 1692.6 197.9Z" fill="white"/>
                    <path d="M1708.95 292.11C1708.88 292.2 1708.81 292.28 1708.75 292.35C1708.67 292.22 1708.56 292.1 1708.51 291.95C1708.49 291.9 1708.62 291.8 1708.68 291.73C1708.77 291.86 1708.86 291.98 1708.94 292.11H1708.95Z" fill="white"/>
                    <path d="M1647.23 192.64C1647.29 192.5 1647.35 192.36 1647.41 192.22C1647.49 192.28 1647.63 192.35 1647.62 192.4C1647.6 192.55 1647.52 192.69 1647.47 192.84C1647.39 192.78 1647.32 192.72 1647.23 192.64Z" fill="white"/>
                    <path d="M1611.33 341.14C1611.27 341.28 1611.21 341.42 1611.15 341.56C1611.07 341.5 1610.93 341.43 1610.94 341.38C1610.96 341.23 1611.04 341.09 1611.09 340.94C1611.17 341 1611.24 341.06 1611.33 341.14Z" fill="white"/>
                    <path d="M1552.72 209.15C1552.63 209.08 1552.55 209.01 1552.48 208.95C1552.61 208.87 1552.73 208.76 1552.88 208.71C1552.93 208.69 1553.03 208.82 1553.1 208.88C1552.97 208.97 1552.85 209.06 1552.72 209.14V209.15Z" fill="white"/>
                    <path d="M1676.63 308.71C1676.71 308.84 1676.82 308.96 1676.87 309.11C1676.89 309.16 1676.76 309.26 1676.7 309.33C1676.62 309.2 1676.51 309.08 1676.46 308.93C1676.44 308.88 1676.57 308.78 1676.63 308.71Z" fill="white"/>
                    <path d="M1565.75 205.03C1565.69 205.17 1565.63 205.31 1565.57 205.45C1565.49 205.39 1565.35 205.32 1565.36 205.27C1565.38 205.12 1565.46 204.98 1565.51 204.83L1565.75 205.03Z" fill="white"/>
                    <path d="M1541.85 324.86C1541.99 324.92 1542.13 324.98 1542.27 325.04C1542.21 325.12 1542.14 325.26 1542.09 325.25C1541.94 325.23 1541.8 325.15 1541.65 325.1C1541.71 325.02 1541.77 324.95 1541.85 324.86Z" fill="white"/>
                    <path d="M1419.02 402.15C1419.1 402.28 1419.21 402.4 1419.26 402.55C1419.28 402.6 1419.15 402.7 1419.09 402.77C1419.01 402.64 1418.9 402.52 1418.85 402.37C1418.83 402.32 1418.96 402.22 1419.02 402.15Z" fill="white"/>
                    <path d="M1512.26 313.28C1512.04 311.6 1513.15 310.89 1514.21 310.24C1514.6 310 1515.25 309.72 1515.41 309.53C1516.4 308.28 1517.24 309.97 1518.12 309.55C1518.4 310.47 1519.04 311.47 1518.85 312.28C1518.67 313.05 1518.36 313.99 1517.8 314.67C1517.27 315.31 1516.82 316.46 1515.64 315.36C1515.47 315.2 1514.98 315.4 1514.63 315.39C1513.11 315.36 1512.32 314.62 1512.25 313.29L1512.26 313.28Z" fill="white"/>
                    <path d="M1621.36 395.8C1620.36 395.84 1619.61 395.08 1619.6 394.73C1619.56 393.37 1618.29 392.88 1617.85 391.83C1617.54 391.09 1617.46 390.58 1618.02 390.04C1618.94 389.14 1619.52 389.01 1620.3 389.53C1620.64 389.76 1620.99 389.93 1621.36 390.11C1622.95 390.87 1623.75 394.35 1622.07 395.47C1621.77 395.67 1621.53 395.84 1621.36 395.81V395.8Z" fill="white"/>
                    <path d="M1603.39 316.15C1602.76 315.54 1601.5 315.79 1601.34 314.58C1601.32 314.41 1601.38 314.2 1601.3 314.07C1600.98 313.51 1600.44 313.02 1600.78 312.27C1601.05 311.69 1601.67 311.56 1602.16 311.28C1603.13 310.73 1604.65 311.17 1605.36 312.17C1605.92 312.95 1605.93 313.71 1605.5 314.59C1605.06 315.5 1604.12 315.66 1603.38 316.15H1603.39Z" fill="white"/>
                    <path d="M1708.06 254.54C1709.56 254.47 1710.27 254.98 1710.46 256.05C1710.56 256.64 1710.51 257.26 1710.58 257.86C1710.64 258.43 1710.41 258.61 1709.87 258.61C1709.27 258.61 1708.65 258.76 1708.06 258.68C1707.06 258.56 1706.36 257.91 1705.91 257.04C1705.82 256.86 1705.83 256.49 1705.95 256.36C1706.41 255.86 1706.66 255.26 1707 254.7C1707.17 254.43 1707.95 254.54 1708.06 254.53V254.54Z" fill="white"/>
                    <path d="M1730.58 365.7C1729.91 365.79 1728.95 365.95 1728.6 364.91C1728.44 364.42 1728.13 363.5 1728.74 363.27C1729.4 363.03 1729.94 362.21 1730.78 362.59C1731.37 362.86 1731.89 363.35 1732.5 363.46C1733.06 363.57 1733.18 363.74 1733.22 364.24C1733.27 364.96 1732.87 365.38 1732.3 365.57C1731.82 365.73 1731.27 365.65 1730.57 365.69L1730.58 365.7Z" fill="white"/>
                    <path d="M1708.56 235.78C1707.88 235.83 1707.26 235.33 1707.19 234.66C1707.16 234.44 1708.26 232.24 1708.44 232.14C1708.85 231.93 1710.23 232.71 1710.17 232.76C1709.3 233.41 1710.55 233.86 1710.27 234.41C1710.01 234.93 1709 235.74 1708.57 235.77L1708.56 235.78Z" fill="white"/>
                    <path d="M1560.82 279.04C1560.83 279.4 1560.96 279.94 1560.61 279.97C1559.98 280.01 1559.3 280.34 1558.66 279.78C1558.31 279.47 1558.34 279 1558.06 278.74C1557.6 278.32 1557.5 278 1557.93 277.47C1558.39 276.9 1559.02 276.77 1559.48 277.09C1560.14 277.54 1560.99 278 1560.82 279.04Z" fill="white"/>
                    <path d="M1624.64 342.51C1625.18 342.51 1625.87 342.2 1625.89 343.23C1625.89 343.46 1626.38 343.68 1626.62 343.92C1626.89 344.2 1627.15 344.5 1627.41 344.78C1626.84 344.87 1626.18 345.18 1625.71 344.99C1625.11 344.76 1624.68 344.11 1624.15 343.68C1623.86 343.44 1623.63 343.17 1623.76 342.82C1623.9 342.44 1624.32 342.53 1624.65 342.5L1624.64 342.51Z" fill="white"/>
                    <path d="M1608.18 204.99C1608.35 204.99 1608.52 204.95 1608.69 204.97C1609.07 205 1609.58 204.69 1609.75 205.27C1609.92 205.86 1609.77 206.4 1609.27 206.83C1608.84 207.2 1608.56 207.02 1608.2 206.74C1607.96 206.56 1607.58 206.59 1607.33 206.41C1607.01 206.18 1606.56 205.96 1606.66 205.45C1606.78 204.84 1607.31 205.1 1607.68 205.02C1607.84 204.99 1608.02 205 1608.19 204.99H1608.18Z" fill="white"/>
                    <path d="M1716.39 241.02C1716.74 240.31 1716.89 239.46 1717.57 238.94C1717.73 238.82 1718.05 238.79 1718.26 239.03C1718.53 239.33 1719.08 239.53 1718.91 240.02C1718.7 240.62 1718.35 241.18 1718 241.71C1717.59 242.35 1717.4 241.46 1717.04 241.53C1716.67 241.6 1716.37 241.49 1716.38 241.01L1716.39 241.02Z" fill="white"/>
                    <path d="M1582.68 347.47C1583.14 347.37 1583.44 347.51 1583.44 348.08C1583.44 348.97 1583 349.5 1582.21 349.58C1581.51 349.65 1581.09 349.24 1580.89 348.7C1580.66 348.09 1581.54 347.5 1582.68 347.46V347.47Z" fill="white"/>
                    <path d="M1542.88 414.25C1543.14 414.24 1543.4 414.18 1543.65 414.22C1544.23 414.31 1544.46 415 1544.07 415.44C1543.84 415.7 1543.65 416.01 1543.37 416.2C1543.01 416.44 1542.5 416.5 1542.19 416.27C1541.83 416.01 1541.9 415.36 1541.43 415.09C1541.8 414.78 1541.97 414.17 1542.62 414.27C1542.7 414.28 1542.79 414.27 1542.88 414.26V414.25Z" fill="white"/>
                    <path d="M1669.3 250.48C1669.07 250.28 1668.89 250.1 1668.69 249.94C1668.18 249.54 1668.73 248.75 1668.08 248.39C1667.74 248.2 1667.72 247.8 1668.01 247.54C1668.29 247.29 1668.61 247.37 1668.89 247.64C1669.26 247.99 1669.67 248.3 1670.02 248.67C1670.19 248.85 1670.72 248.93 1670.3 249.37C1669.96 249.73 1669.64 250.11 1669.31 250.48H1669.3Z" fill="white"/>
                    <path d="M1759.22 308.51C1759.17 309.06 1758.35 309.61 1757.73 309.52C1757.48 309.48 1756.53 308.2 1756.6 307.9C1756.76 307.15 1757.41 307.47 1757.84 307.45C1758.63 307.41 1759.28 307.97 1759.23 308.5L1759.22 308.51Z" fill="white"/>
                    <path d="M1700.7 350.5C1700.59 349.98 1700.69 349.65 1701.28 349.64C1702.01 349.64 1702.75 350.09 1702.78 350.61C1702.81 351.12 1702.11 351.69 1701.39 351.72C1700.31 351.77 1700.88 350.9 1700.71 350.5H1700.7Z" fill="white"/>
                    <path d="M1697.75 356.1C1697.33 355.88 1696.86 355.65 1696.79 355.11C1696.72 354.52 1697.7 353.87 1698.4 353.98C1698.86 354.05 1698.85 354.39 1698.83 354.72C1698.8 355.48 1698.28 356.14 1697.74 356.1H1697.75Z" fill="white"/>
                    <path d="M1684.83 277.71C1685.17 277.69 1685.34 277.7 1685.5 277.67C1686.12 277.56 1686.34 278.12 1686.74 278.38C1686.37 278.68 1686.22 279.27 1685.59 279.23C1685.42 279.22 1685.25 279.23 1685.08 279.26C1684.51 279.34 1684.15 279.29 1684.12 278.53C1684.09 277.81 1684.37 277.63 1684.83 277.71Z" fill="white"/>
                    <path d="M1677.38 426.57C1677.31 425.97 1677.7 425.82 1678 425.94C1678.6 426.17 1679.16 426.51 1679.71 426.85C1680.22 427.17 1679.71 427.36 1679.57 427.58C1678.94 428.54 1678.43 427.43 1677.84 427.45C1677.15 427.47 1677.52 426.81 1677.38 426.56V426.57Z" fill="white"/>
                    <path d="M1696.5 306.72C1695.8 306.89 1695.37 306.47 1695.15 305.94C1694.99 305.54 1695.96 304.5 1696.29 304.66C1696.91 304.96 1697.19 305.48 1697.17 306.19C1697.16 306.69 1696.84 306.7 1696.5 306.72Z" fill="white"/>
                    <path d="M1556.69 233.36C1556.75 232.85 1557.13 232.55 1557.56 232.31C1557.81 232.17 1558.72 233.27 1558.8 233.79C1558.87 234.27 1558.54 234.33 1558.19 234.34C1557.48 234.36 1556.73 233.88 1556.69 233.36Z" fill="white"/>
                    <path d="M1714.66 275.14C1714.6 275.4 1714.43 275.66 1714.15 275.53C1713.49 275.23 1712.75 275.09 1712.2 274.55C1711.97 274.32 1711.93 274.04 1712.02 273.86C1712.1 273.69 1712.49 273.51 1712.64 273.57C1713.23 273.82 1713.79 274.16 1714.36 274.48C1714.6 274.62 1714.69 274.85 1714.67 275.14H1714.66Z" fill="white"/>
                    <path d="M1739.63 262.14C1739.36 262.18 1739.02 262.24 1738.9 261.79C1738.8 261.41 1739.11 261.21 1739.28 260.94C1739.62 260.39 1740.18 260.52 1740.63 260.56C1740.9 260.58 1741.37 261.01 1741.34 261.17C1741.27 261.5 1740.98 261.87 1740.68 262.05C1740.43 262.2 1740.03 262.12 1739.63 262.14Z" fill="white"/>
                    <path d="M1717.13 402.23C1717.22 402.65 1717.08 402.94 1716.5 402.94C1715.78 402.94 1715.06 402.48 1715.02 401.97C1714.95 401.11 1716.06 401.64 1716.23 400.96C1716.26 400.85 1717.04 401.57 1717.12 402.23H1717.13Z" fill="white"/>
                    <path d="M1637.82 262.66C1637.69 262.57 1637.48 262.51 1637.45 262.39C1637.25 261.74 1638.12 260.52 1638.77 260.54C1638.99 260.54 1639.32 260.62 1639.4 260.77C1639.49 260.93 1639.4 261.26 1639.29 261.44C1638.76 262.24 1638.25 262.63 1637.82 262.67V262.66Z" fill="white"/>
                    <path d="M1550.82 304.91C1550.83 305.16 1550.89 305.42 1550.85 305.67C1550.75 306.3 1550.21 306.05 1549.85 306.11C1549.37 306.2 1549.31 305.86 1549.29 305.5C1549.28 305.17 1549.2 304.85 1549.49 304.57C1549.79 304.28 1550.03 303.84 1550.51 304.02C1550.89 304.16 1550.78 304.58 1550.82 304.9V304.91Z" fill="white"/>
                    <path d="M1712.22 276.68C1712.75 276.76 1712.72 277.12 1712.71 277.44C1712.67 278.22 1712.16 278.86 1711.61 278.8C1711.15 278.75 1711.17 278.38 1711.19 278.05C1711.23 277.27 1711.74 276.65 1712.22 276.68Z" fill="white"/>
                    <path d="M1724.43 402.21C1724.01 402.64 1723.73 403.15 1723 403.11C1722.4 403.08 1722.36 402.78 1722.33 402.33C1722.29 401.84 1722.38 401.61 1722.94 401.5C1723.68 401.37 1723.96 401.91 1724.43 402.21Z" fill="white"/>
                    <path d="M1712.03 406.3C1711.89 406.22 1711.66 406.16 1711.64 406.06C1711.57 405.73 1711.45 405.32 1711.59 405.07C1711.82 404.67 1712.07 404.15 1712.67 404.19C1713.14 404.22 1713.12 404.59 1713.11 404.93C1713.07 405.72 1712.59 406.31 1712.03 406.31V406.3Z" fill="white"/>
                    <path d="M1538.7 271.88C1538.35 271.88 1537.97 271.89 1538.02 271.41C1538.09 270.79 1538.45 270.29 1539.1 270.33C1539.44 270.35 1539.75 270.78 1540.07 271.02C1539.68 271.42 1539.41 272.02 1538.7 271.88Z" fill="white"/>
                    <path d="M1583.81 401.17C1583.81 400.55 1584.49 399.87 1584.93 399.92C1585.38 399.97 1585.33 400.33 1585.36 400.66C1585.43 401.31 1585.1 401.76 1584.55 401.88C1584.39 401.92 1583.83 401.51 1583.81 401.17Z" fill="white"/>
                    <path d="M1705.15 323.41C1704.72 323.43 1704.26 323.44 1704.37 322.99C1704.5 322.43 1704.85 321.93 1705.1 321.4C1705.57 321.76 1705.96 322.12 1705.92 322.83C1705.88 323.5 1705.43 323.36 1705.15 323.41Z" fill="white"/>
                    <path d="M1639.6 435.34C1638.9 435.46 1638.83 435.12 1638.77 434.63C1638.67 433.87 1639.07 433.81 1639.64 433.78C1640.23 433.74 1640.31 434.03 1640.36 434.52C1640.44 435.25 1640.12 435.44 1639.6 435.34Z" fill="white"/>
                    <path d="M1710.59 313.79C1710.65 313.33 1710.26 312.62 1711.14 312.7C1711.62 312.74 1712.05 313.1 1712.14 313.64C1712.29 314.53 1711.32 314.33 1710.94 314.58C1710.48 314.9 1710.78 314.04 1710.59 313.79Z" fill="white"/>
                    <path d="M1749.67 248.04C1749.42 248.05 1749.16 248.11 1748.91 248.07C1748.46 248.01 1748.49 247.64 1748.49 247.31C1748.49 247.07 1748.35 246.79 1748.62 246.62C1748.93 246.42 1749.23 246.46 1749.49 246.74C1749.61 246.86 1749.74 246.96 1749.86 247.08C1750.09 247.3 1750.54 247.57 1750.51 247.75C1750.43 248.13 1749.97 247.99 1749.65 248.04H1749.67Z" fill="white"/>
                    <path d="M1542.73 373.81C1542.58 374.41 1542.08 374.2 1541.71 374.23C1541.02 374.29 1541.2 373.73 1541.15 373.36C1541.11 373.02 1541.12 372.66 1541.62 372.68C1542.13 372.7 1542.77 373.36 1542.73 373.8V373.81Z" fill="white"/>
                    <path d="M1679.47 371.42C1679.47 370.97 1679.45 370.61 1679.95 370.64C1680.46 370.66 1681.12 371.32 1681.05 371.76C1680.99 372.21 1680.61 372.12 1680.29 372.19C1679.52 372.34 1679.45 371.88 1679.47 371.43V371.42Z" fill="white"/>
                    <path d="M1731.43 285.8C1731.39 285.97 1731.41 286.33 1731.28 286.41C1730.91 286.61 1730.72 286.18 1730.59 285.98C1730.39 285.68 1729.44 285.65 1730.15 284.95C1730.38 284.72 1730.57 284.4 1730.95 284.49C1731.72 284.66 1731.21 285.33 1731.44 285.8H1731.43Z" fill="white"/>
                    <path d="M1613.74 222.37C1613.4 222.15 1612.68 221.96 1612.97 221.52C1613.22 221.15 1612.94 220.48 1613.55 220.39C1613.91 220.34 1614.5 221.57 1614.34 222.04C1614.26 222.29 1614.04 222.4 1613.74 222.37Z" fill="white"/>
                    <path d="M1636.89 419.45C1636.89 419.45 1636.52 419.72 1636.15 419.91C1635.99 419.99 1635.26 419.06 1635.3 418.82C1635.4 418.19 1635.93 418.44 1636.29 418.37C1636.73 418.29 1636.86 418.57 1636.89 419.45Z" fill="white"/>
                    <path d="M1606.42 416.92C1605.82 416.78 1606.05 416.28 1605.99 415.91C1605.91 415.45 1606.26 415.26 1606.57 415.37C1606.93 415.5 1607.26 415.8 1607.54 416.08C1607.64 416.18 1606.64 416.94 1606.42 416.91V416.92Z" fill="white"/>
                    <path d="M1505.06 295.74C1504.94 295.3 1505.14 295.11 1505.39 295.09C1506.05 295.06 1506.43 295.46 1506.61 296.06C1506.7 296.37 1506.5 296.56 1506.25 296.58C1505.56 296.64 1505.24 296.19 1505.06 295.74Z" fill="white"/>
                    <path d="M1647.42 245.41C1647.37 245.96 1647.04 246.39 1646.6 246.41C1646.38 246.42 1645.87 246.05 1645.85 245.54C1645.83 244.86 1646.35 245.03 1646.72 244.98C1647.07 244.93 1647.39 244.97 1647.42 245.4V245.41Z" fill="white"/>
                    <path d="M1526.33 378.35C1526.28 378.11 1526.93 377.08 1527.04 377.17C1527.35 377.42 1527.68 377.71 1527.85 378.06C1528 378.38 1527.81 378.71 1527.36 378.68C1526.99 378.66 1526.51 378.96 1526.33 378.35Z" fill="white"/>
                    <path d="M1652.4 243.59C1651.93 243.47 1651.45 243.19 1651.43 242.51C1651.43 242.26 1651.59 242.03 1651.91 242.09C1652.53 242.2 1652.97 242.53 1653.01 243.19C1653.03 243.44 1652.86 243.66 1652.4 243.59Z" fill="white"/>
                    <path d="M1661.81 186.36C1661.34 186.41 1661.15 186.09 1661.26 185.77C1661.39 185.41 1661.69 185.08 1661.97 184.8C1662.07 184.7 1662.83 185.7 1662.8 185.92C1662.75 186.24 1662.37 186.41 1661.81 186.36Z" fill="white"/>
                    <path d="M1706.69 315.52C1706.33 315.53 1705.97 315.55 1705.98 315.06C1705.99 314.53 1706.34 314.11 1706.78 314.09C1707 314.08 1707.51 314.45 1707.53 314.96C1707.55 315.65 1707.02 315.44 1706.68 315.52H1706.69Z" fill="white"/>
                    <path d="M1724.23 235.36C1723.79 235.16 1723.4 234.93 1723.31 234.41C1723.26 234.1 1723.44 233.89 1723.69 233.91C1724.11 233.94 1724.74 233.51 1724.86 234.34C1724.93 234.85 1724.41 234.99 1724.22 235.36H1724.23Z" fill="white"/>
                    <path d="M1697.63 307.7C1697.5 307.62 1697.37 307.53 1697.24 307.45C1697.72 306.88 1698.32 306.39 1698.44 305.58C1698.44 305.54 1698.78 305.51 1698.93 305.56C1699.02 305.59 1699.13 305.86 1699.1 305.89C1698.59 306.35 1698.76 307.22 1698.1 307.58C1697.96 307.66 1697.79 307.66 1697.63 307.7Z" fill="white"/>
                    <path d="M1696.51 303.82C1696.51 303.31 1697.19 303.55 1697.58 303.57C1698.37 303.6 1697.99 304.3 1698.08 304.71C1698.11 304.83 1697.86 305.31 1697.69 304.9C1697.45 304.28 1696.44 305.09 1696.5 303.82H1696.51Z" fill="white"/>
                    <path d="M1536.68 321.8C1536.82 322.41 1536.24 322.62 1535.99 323.01C1535.74 322.8 1535.3 322.6 1535.29 322.39C1535.28 321.92 1535.64 321.59 1536.13 321.46C1536.43 321.39 1536.57 321.56 1536.68 321.8Z" fill="white"/>
                    <path d="M1640.8 414.84C1640.84 414.48 1640.59 413.97 1641.28 413.92C1641.78 413.88 1641.83 414.2 1641.83 414.55C1641.83 414.93 1642.05 415.43 1641.36 415.48C1640.86 415.52 1640.84 415.19 1640.8 414.84Z" fill="white"/>
                    <path d="M1534.09 252.8C1533.35 252.37 1533.17 251.85 1533.24 251.24C1533.26 251.1 1533.39 250.97 1533.47 250.83C1533.59 250.94 1533.7 251.08 1533.85 251.16C1534.4 251.46 1534.34 251.98 1534.33 252.47C1534.33 252.62 1534.13 252.76 1534.1 252.81L1534.09 252.8Z" fill="white"/>
                    <path d="M1637.77 441.21C1637.64 441.23 1637.56 440.91 1637.57 440.55C1637.57 440.18 1637.33 439.67 1638.03 439.61C1638.52 439.57 1638.59 439.87 1638.59 440.23C1638.59 440.61 1638.81 441.11 1637.78 441.21H1637.77Z" fill="white"/>
                    <path d="M1660.89 391.28C1660.95 391.18 1661 390.97 1661.07 390.96C1661.65 390.87 1662.21 390.81 1662.65 391.37C1662.83 391.6 1662.9 391.76 1662.65 391.89C1662.24 392.1 1661.03 391.67 1660.89 391.28Z" fill="white"/>
                    <path d="M1568.41 351.39C1567.6 351.23 1568.08 350.56 1567.97 350.14C1567.92 349.95 1568.3 349.78 1568.56 349.84C1569.32 350.01 1568.95 350.66 1569.01 351.09C1569.04 351.29 1568.7 351.44 1568.41 351.39Z" fill="white"/>
                    <path d="M1667.27 332.3C1666.46 332.14 1666.94 331.47 1666.83 331.05C1666.78 330.86 1667.16 330.69 1667.42 330.75C1668.18 330.92 1667.81 331.57 1667.87 332C1667.9 332.2 1667.56 332.35 1667.27 332.3Z" fill="white"/>
                    <path d="M1598.29 238.88C1598.16 238.9 1598.08 238.58 1598.09 238.22C1598.09 237.85 1597.85 237.34 1598.55 237.28C1599.04 237.24 1599.11 237.54 1599.11 237.9C1599.11 238.28 1599.33 238.78 1598.3 238.88H1598.29Z" fill="white"/>
                    <path d="M1735.34 338.73C1735.8 338.45 1736.27 338.15 1736.75 337.87C1736.77 337.86 1736.91 337.96 1736.93 338.03C1737.02 338.38 1736.91 338.65 1736.65 338.92C1736.36 339.22 1736.1 339.56 1735.63 339.31C1735.52 339.25 1735.49 339.04 1735.34 338.73Z" fill="white"/>
                    <path d="M1716.29 294.94C1716.34 295.28 1716.4 295.65 1715.94 295.73C1715.34 295.84 1715.25 295.46 1715.25 294.98C1715.25 294.65 1715.15 294.28 1715.62 294.19C1716.21 294.08 1716.33 294.45 1716.29 294.93V294.94Z" fill="white"/>
                    <path d="M1684.86 252.79C1684.4 252.67 1684.17 252.57 1684.23 252.25C1684.34 251.63 1684.67 251.2 1685.33 251.15C1685.46 251.14 1685.6 251.3 1685.74 251.37C1685.43 251.86 1685.13 252.35 1684.86 252.78V252.79Z" fill="white"/>
                    <path d="M1681 355.97C1680.55 356.06 1680.18 355.98 1680.19 355.77C1680.19 355.33 1679.76 354.73 1680.49 354.48C1680.74 354.39 1681.1 354.48 1681.11 354.72C1681.14 355.16 1681.42 355.71 1680.99 355.98L1681 355.97Z" fill="white"/>
                    <path d="M1583.11 247C1582.75 247 1582.42 247.02 1582.41 246.52C1582.39 245.83 1582.91 245.99 1583.29 245.95C1583.64 245.91 1583.96 245.92 1583.98 246.42C1584 247.11 1583.47 246.92 1583.11 247Z" fill="white"/>
                    <path d="M1587.37 325.47C1587.21 326.28 1586.54 325.8 1586.12 325.91C1585.93 325.96 1585.76 325.58 1585.82 325.32C1585.99 324.56 1586.64 324.93 1587.07 324.87C1587.27 324.84 1587.42 325.18 1587.37 325.47Z" fill="white"/>
                    <path d="M1694.01 250.39C1694.06 250.73 1694.12 251.1 1693.66 251.18C1693.06 251.29 1692.97 250.91 1692.97 250.43C1692.97 250.1 1692.87 249.73 1693.34 249.64C1693.93 249.53 1694.05 249.9 1694.01 250.38V250.39Z" fill="white"/>
                    <path d="M1714.52 393.42C1714.61 394.01 1714.44 394.16 1714.17 394.21C1713.57 394.31 1713.49 393.91 1713.51 393.45C1713.51 393.21 1713.44 392.74 1713.63 392.8C1714.03 392.93 1714.35 393.29 1714.51 393.42H1714.52Z" fill="white"/>
                    <path d="M1553.61 261.16C1553.48 261.11 1553.27 261.02 1553.05 260.93C1553.38 260.6 1553.54 260.04 1554.15 260.08C1554.41 260.1 1554.58 260.24 1554.58 260.56C1554.6 261.24 1554.09 261.07 1553.61 261.16Z" fill="white"/>
                    <path d="M1737.72 222.61C1737.66 222.39 1737.84 222.2 1738.09 222.16C1738.43 222.1 1738.8 222.13 1738.76 222.61C1738.73 222.95 1739.09 223.52 1738.5 223.56C1738.29 223.58 1737.77 223.19 1737.72 222.61Z" fill="white"/>
                    <path d="M1751.52 382.23C1750.92 382.34 1750.71 381.77 1750.32 381.5C1750.53 381.4 1750.74 381.24 1750.96 381.23C1751.33 381.2 1751.82 380.99 1751.89 381.67C1751.92 381.98 1751.78 382.12 1751.52 382.22V382.23Z" fill="white"/>
                    <path d="M1708.86 227.17C1708.76 226.83 1708.77 227.2 1709.04 226.99C1709.18 226.88 1709.56 226.86 1709.69 226.97C1710.05 227.27 1710.59 227.48 1710.46 228.11C1710.45 228.18 1710.35 228.3 1710.31 228.29C1709.65 228.14 1709.37 227.51 1708.87 227.17H1708.86Z" fill="white"/>
                    <path d="M1672.85 259.56C1672.52 259.21 1671.94 259 1672.12 258.38C1672.16 258.23 1672.53 258.08 1672.73 258.1C1673.35 258.18 1673.09 258.73 1673.17 259.09C1673.22 259.34 1673.17 259.54 1672.85 259.55V259.56Z" fill="white"/>
                    <path d="M1629.04 330.29C1628.87 330.29 1628.69 330.35 1628.54 330.3C1628.44 330.27 1628.3 330.07 1628.32 329.99C1628.41 329.72 1630 329.61 1630.19 329.87C1630.23 329.93 1630.13 330.2 1630.06 330.21C1629.73 330.26 1629.38 330.26 1629.04 330.28V330.29Z" fill="white"/>
                    <path d="M1625.07 189.46C1624.54 189.52 1624.44 189.21 1624.46 188.85C1624.48 188.59 1624.62 188.43 1624.94 188.41C1625.42 188.39 1625.51 188.67 1625.49 189.03C1625.47 189.28 1625.36 189.53 1625.07 189.45V189.46Z" fill="white"/>
                    <path d="M1736.65 390.35C1736.38 390.29 1736.21 390.18 1736.2 389.86C1736.18 389.38 1736.46 389.29 1736.82 389.31C1737.08 389.32 1737.24 389.47 1737.26 389.79C1737.28 390.27 1736.99 390.33 1736.65 390.35Z" fill="white"/>
                    <path d="M1546.76 373.44C1546.49 373.38 1546.32 373.27 1546.31 372.95C1546.29 372.47 1546.57 372.38 1546.93 372.4C1547.19 372.41 1547.35 372.56 1547.37 372.88C1547.39 373.36 1547.1 373.42 1546.76 373.44Z" fill="white"/>
                    <path d="M1530.98 276.04C1530.97 275.72 1530.99 275.42 1531.47 275.39C1531.79 275.37 1531.95 275.51 1532 275.77C1532.06 276.12 1532 276.42 1531.52 276.45C1531.2 276.47 1531.07 276.33 1530.98 276.04Z" fill="white"/>
                    <path d="M1654.13 431.07C1653.96 431.52 1653.71 431.88 1653.21 431.98C1653.08 432 1652.79 431.86 1652.79 431.85C1652.9 431.38 1653.17 431.02 1653.67 430.93C1653.8 430.91 1653.96 431.01 1654.13 431.07Z" fill="white"/>
                    <path d="M1566.12 401.49C1565.82 401.57 1565.66 401.37 1565.61 401.12C1565.55 400.77 1565.61 400.47 1566.09 400.44C1566.41 400.42 1566.57 400.56 1566.62 400.82C1566.68 401.17 1566.62 401.49 1566.12 401.49Z" fill="white"/>
                    <path d="M1665.44 373.53C1665.17 373.47 1665 373.36 1664.99 373.04C1664.97 372.56 1665.25 372.47 1665.61 372.49C1665.87 372.5 1666.03 372.65 1666.05 372.97C1666.07 373.45 1665.78 373.51 1665.44 373.53Z" fill="white"/>
                    <path d="M1682.09 196.69C1681.77 196.62 1681.61 196.51 1681.59 196.19C1681.57 195.71 1681.85 195.62 1682.21 195.64C1682.47 195.65 1682.63 195.8 1682.65 196.12C1682.68 196.61 1682.36 196.64 1682.09 196.69Z" fill="white"/>
                    <path d="M1687.47 217.21C1687.41 216.72 1687.72 216.62 1688.08 216.64C1688.34 216.66 1688.5 216.8 1688.52 217.12C1688.54 217.6 1688.26 217.69 1687.9 217.67C1687.64 217.66 1687.43 217.52 1687.47 217.21Z" fill="white"/>
                    <path d="M1627.89 455.79C1628.23 455.79 1628.57 455.77 1628.56 456.26C1628.56 456.41 1628.48 456.56 1628.43 456.7C1628.12 456.49 1627.82 456.28 1627.51 456.07C1627.64 455.98 1627.76 455.88 1627.89 455.78V455.79Z" fill="white"/>
                    <path d="M1608.56 413.16C1608.22 413.16 1607.88 413.18 1607.89 412.69C1607.89 412.54 1607.97 412.4 1608.02 412.25C1608.33 412.46 1608.63 412.67 1608.94 412.88C1608.81 412.97 1608.69 413.07 1608.56 413.17V413.16Z" fill="white"/>
                    <path d="M1722.66 376.47C1722.39 376.24 1722.14 376.03 1721.89 375.81C1722.1 375.71 1722.31 375.53 1722.52 375.54C1722.98 375.54 1722.98 375.93 1722.94 376.26C1722.94 376.35 1722.74 376.42 1722.66 376.47Z" fill="white"/>
                    <path d="M1586.94 318.14C1587 317.95 1587.04 317.68 1587.09 317.67C1587.54 317.63 1587.68 318.1 1588 318.29C1587.87 318.38 1587.76 318.53 1587.62 318.56C1587.27 318.62 1586.97 318.55 1586.94 318.14Z" fill="white"/>
                    <path d="M1507.13 299.61C1507.02 299.54 1506.83 299.49 1506.81 299.4C1506.74 299.08 1506.7 298.7 1507.15 298.65C1507.36 298.63 1507.58 298.78 1507.8 298.86C1507.58 299.11 1507.36 299.36 1507.13 299.61Z" fill="white"/>
                    <path d="M1528.44 314.11C1528.49 314.58 1528.16 314.65 1527.82 314.63C1527.68 314.63 1527.55 314.48 1527.41 314.4C1527.69 314.16 1527.97 313.92 1528.25 313.67C1528.31 313.81 1528.37 313.95 1528.43 314.1L1528.44 314.11Z" fill="white"/>
                    <path d="M1703.03 368.71C1702.97 368.68 1702.77 368.63 1702.75 368.55C1702.68 368.23 1702.63 367.85 1703.08 367.78C1703.28 367.75 1703.52 367.91 1703.74 367.98C1703.51 368.22 1703.28 368.45 1703.03 368.71Z" fill="white"/>
                    <path d="M1669.44 369.14C1668.97 369.19 1668.9 368.86 1668.92 368.52C1668.92 368.38 1669.07 368.25 1669.15 368.11C1669.39 368.39 1669.63 368.67 1669.88 368.95C1669.74 369.01 1669.6 369.07 1669.45 369.13L1669.44 369.14Z" fill="white"/>
                    <path d="M1521.34 356.13C1521.2 356.05 1521.06 355.98 1520.92 355.91C1521.19 355.66 1521.45 355.4 1521.72 355.15C1521.79 355.29 1521.89 355.42 1521.91 355.57C1521.99 356.03 1521.69 356.11 1521.33 356.14L1521.34 356.13Z" fill="white"/>
                    <path d="M1672.23 223.79C1672.28 224.26 1671.95 224.33 1671.61 224.31C1671.47 224.31 1671.34 224.16 1671.2 224.08C1671.48 223.84 1671.76 223.6 1672.04 223.35C1672.1 223.49 1672.16 223.63 1672.22 223.78L1672.23 223.79Z" fill="white"/>
                    <path d="M1668.18 188.33C1668 188.45 1667.88 188.59 1667.74 188.62C1667.38 188.68 1667.07 188.6 1667.1 188.14C1667.1 187.99 1667.19 187.85 1667.25 187.7C1667.55 187.91 1667.86 188.11 1668.18 188.33Z" fill="white"/>
                    <path d="M1769.64 351.46C1769.33 351.3 1769.42 351.02 1769.42 350.79C1769.42 350.72 1769.66 350.56 1769.73 350.59C1770.04 350.71 1769.94 351.01 1769.93 351.24C1769.93 351.32 1769.73 351.39 1769.63 351.46H1769.64Z" fill="white"/>
                    <path d="M1750.98 364.34C1750.71 364.44 1750.5 364.56 1750.27 364.58C1750.14 364.59 1750 364.44 1749.86 364.36C1750.07 364.26 1750.27 364.12 1750.49 364.08C1750.61 364.06 1750.76 364.22 1750.97 364.34H1750.98Z" fill="white"/>
                    <path d="M1579.18 213.31C1578.87 213.15 1578.96 212.87 1578.96 212.64C1578.96 212.57 1579.2 212.41 1579.27 212.44C1579.58 212.56 1579.48 212.86 1579.47 213.09C1579.47 213.17 1579.27 213.24 1579.17 213.31H1579.18Z" fill="white"/>
                    <path d="M1521.09 290.63C1520.99 290.36 1520.87 290.15 1520.85 289.92C1520.84 289.79 1520.99 289.65 1521.07 289.51C1521.17 289.72 1521.31 289.92 1521.35 290.14C1521.37 290.26 1521.21 290.41 1521.09 290.62V290.63Z" fill="white"/>
                    <path d="M1521.58 293.38C1521.58 293.24 1521.55 293.01 1521.6 292.79C1521.66 292.54 1521.85 292.53 1521.93 292.77C1522 292.98 1522.01 293.23 1521.97 293.45C1521.92 293.7 1521.76 293.7 1521.57 293.38H1521.58Z" fill="white"/>
                    <path d="M1564.6 207.45C1564.55 207.6 1564.52 207.85 1564.44 207.87C1564.14 207.94 1564.05 207.71 1564.08 207.46C1564.1 207.31 1564.15 207.06 1564.23 207.04C1564.53 206.97 1564.6 207.19 1564.59 207.45H1564.6Z" fill="white"/>
                    <path d="M1731.87 386.97C1731.72 386.92 1731.47 386.89 1731.45 386.81C1731.38 386.51 1731.61 386.42 1731.86 386.45C1732.01 386.47 1732.26 386.52 1732.28 386.6C1732.35 386.9 1732.13 386.97 1731.87 386.96V386.97Z" fill="white"/>
                    <path d="M1530.03 359.59C1529.76 359.69 1529.55 359.81 1529.32 359.83C1529.19 359.84 1529.05 359.69 1528.91 359.61C1529.12 359.51 1529.32 359.37 1529.54 359.33C1529.66 359.31 1529.81 359.47 1530.02 359.59H1530.03Z" fill="white"/>
                    <path d="M1728.05 247.67C1727.95 247.61 1727.82 247.53 1727.68 247.45C1727.89 247.35 1728.09 247.21 1728.31 247.17C1728.43 247.15 1728.58 247.31 1728.72 247.39C1728.57 247.61 1728.36 247.72 1728.05 247.66V247.67Z" fill="white"/>
                    <path d="M1534.26 399.13C1534.22 398.88 1534.26 398.65 1534.57 398.68C1534.65 398.68 1534.73 398.93 1534.77 399.08C1534.83 399.33 1534.77 399.56 1534.46 399.52C1534.38 399.52 1534.32 399.26 1534.26 399.12V399.13Z" fill="white"/>
                    <path d="M1692.88 248.64C1692.84 248.39 1692.88 248.16 1693.19 248.19C1693.27 248.19 1693.35 248.44 1693.39 248.59C1693.45 248.84 1693.39 249.07 1693.08 249.03C1693 249.03 1692.94 248.77 1692.88 248.63V248.64Z" fill="white"/>
                    <path d="M1685.53 256.86C1685.49 256.61 1685.53 256.38 1685.84 256.41C1685.92 256.41 1686 256.66 1686.04 256.81C1686.1 257.06 1686.04 257.29 1685.73 257.25C1685.65 257.25 1685.59 256.99 1685.53 256.85V256.86Z" fill="white"/>
                    <path d="M1663.04 290.69C1662.77 290.79 1662.56 290.91 1662.33 290.93C1662.2 290.94 1662.06 290.79 1661.92 290.71C1662.13 290.61 1662.33 290.47 1662.55 290.43C1662.67 290.41 1662.82 290.57 1663.03 290.69H1663.04Z" fill="white"/>
                    <path d="M1711.33 372.03C1711.15 371.95 1710.94 371.86 1710.73 371.76C1710.86 371.67 1710.99 371.49 1711.11 371.5C1711.33 371.51 1711.55 371.63 1711.77 371.71C1711.64 371.8 1711.52 371.9 1711.33 372.04V372.03Z" fill="white"/>
                    <path d="M1630.49 354.65C1630.39 354.59 1630.26 354.51 1630.12 354.43C1630.33 354.33 1630.53 354.19 1630.75 354.15C1630.87 354.13 1631.02 354.29 1631.16 354.37C1631.01 354.59 1630.8 354.7 1630.49 354.64V354.65Z" fill="white"/>
                    <path d="M1626.21 394.78C1625.94 394.88 1625.73 395 1625.5 395.02C1625.37 395.03 1625.23 394.88 1625.09 394.8C1625.3 394.7 1625.5 394.56 1625.72 394.52C1625.84 394.5 1625.99 394.66 1626.2 394.78H1626.21Z" fill="white"/>
                    <path d="M1619.99 457.73C1619.68 457.57 1619.77 457.29 1619.77 457.06C1619.77 456.99 1620.01 456.83 1620.08 456.86C1620.39 456.98 1620.29 457.28 1620.28 457.51C1620.28 457.59 1620.08 457.66 1619.98 457.73H1619.99Z" fill="white"/>
                    <path d="M1592.03 408.37C1591.99 408.12 1592.03 407.89 1592.34 407.92C1592.42 407.92 1592.5 408.17 1592.54 408.32C1592.6 408.57 1592.54 408.8 1592.23 408.76C1592.15 408.76 1592.09 408.5 1592.03 408.36V408.37Z" fill="white"/>
                    <path d="M1589.58 225.82C1589.53 225.97 1589.5 226.22 1589.42 226.24C1589.12 226.31 1589.03 226.08 1589.06 225.83C1589.08 225.68 1589.13 225.43 1589.21 225.41C1589.51 225.34 1589.58 225.56 1589.57 225.82H1589.58Z" fill="white"/>
                    <path d="M1607.76 308.59C1607.61 308.54 1607.36 308.51 1607.34 308.43C1607.27 308.13 1607.5 308.04 1607.75 308.07C1607.9 308.09 1608.15 308.14 1608.17 308.22C1608.24 308.52 1608.02 308.59 1607.76 308.58V308.59Z" fill="white"/>
                    <path d="M1594.32 310.12C1594.21 310.18 1594.1 310.27 1593.98 310.3C1593.94 310.31 1593.86 310.2 1593.8 310.15C1593.91 310.09 1594.02 310 1594.14 309.97C1594.18 309.96 1594.26 310.07 1594.32 310.12Z" fill="white"/>
                    <path d="M1512.77 359.47C1512.66 359.53 1512.55 359.62 1512.43 359.65C1512.39 359.66 1512.31 359.55 1512.25 359.5C1512.36 359.44 1512.47 359.35 1512.59 359.32C1512.63 359.31 1512.71 359.42 1512.77 359.47Z" fill="white"/>
                    <path d="M1683.33 267.31C1683.22 267.26 1683.1 267.2 1682.99 267.14C1683.04 267.08 1683.11 266.96 1683.15 266.97C1683.27 266.99 1683.39 267.06 1683.51 267.11C1683.46 267.17 1683.4 267.23 1683.34 267.3L1683.33 267.31Z" fill="white"/>
                    <path d="M1693.09 227.88C1693.16 227.95 1693.22 228 1693.28 228.05C1693.17 228.11 1693.06 228.2 1692.94 228.23C1692.9 228.24 1692.82 228.13 1692.76 228.08C1692.87 228.01 1692.98 227.94 1693.08 227.88H1693.09Z" fill="white"/>
                    <path d="M1698.63 303.3C1698.68 303.19 1698.74 303.07 1698.8 302.96C1698.86 303.01 1698.98 303.08 1698.97 303.12C1698.95 303.24 1698.88 303.36 1698.83 303.48C1698.77 303.43 1698.71 303.37 1698.64 303.31L1698.63 303.3Z" fill="white"/>
                    <path d="M1670.79 332.63C1670.73 332.52 1670.64 332.41 1670.61 332.29C1670.6 332.25 1670.71 332.17 1670.76 332.11C1670.82 332.22 1670.91 332.33 1670.94 332.45C1670.95 332.49 1670.84 332.57 1670.79 332.63Z" fill="white"/>
                    <path d="M1668.83 375.09C1668.9 375.02 1668.95 374.96 1669 374.9C1669.06 375.01 1669.15 375.12 1669.18 375.24C1669.19 375.28 1669.08 375.36 1669.03 375.42C1668.96 375.31 1668.89 375.2 1668.83 375.1V375.09Z" fill="white"/>
                    <path d="M1719.33 389.16C1719.22 389.11 1719.1 389.05 1718.99 388.99C1719.04 388.93 1719.11 388.81 1719.15 388.82C1719.27 388.84 1719.39 388.91 1719.51 388.96C1719.46 389.02 1719.4 389.08 1719.34 389.15L1719.33 389.16Z" fill="white"/>
                    <path d="M1699.34 286.19C1699.27 286.12 1699.21 286.07 1699.15 286.02C1699.26 285.96 1699.37 285.87 1699.49 285.84C1699.53 285.83 1699.61 285.94 1699.67 285.99C1699.56 286.06 1699.45 286.13 1699.35 286.19H1699.34Z" fill="white"/>
                    <path d="M1645.33 322.69C1645.4 322.76 1645.46 322.81 1645.52 322.86C1645.41 322.92 1645.3 323.01 1645.18 323.04C1645.14 323.05 1645.06 322.94 1645 322.89C1645.11 322.82 1645.22 322.75 1645.32 322.69H1645.33Z" fill="white"/>
                    <path d="M1642.13 448.53C1642.07 448.59 1641.99 448.71 1641.97 448.7C1641.85 448.65 1641.71 448.58 1641.65 448.48C1641.53 448.27 1641.63 448.16 1641.86 448.25C1641.97 448.3 1642.04 448.43 1642.13 448.53Z" fill="white"/>
                    <path d="M1641.41 448.27C1641.32 448.17 1641.15 448.05 1641.17 447.98C1641.22 447.78 1641.38 447.73 1641.47 447.87C1641.55 447.98 1641.59 448.13 1641.64 448.26C1641.56 448.26 1641.49 448.26 1641.41 448.27Z" fill="white"/>
                    <path d="M1580.38 287.67C1580.27 287.62 1580.15 287.56 1580.04 287.5C1580.09 287.44 1580.16 287.32 1580.2 287.33C1580.32 287.35 1580.44 287.42 1580.56 287.47C1580.51 287.53 1580.45 287.59 1580.39 287.66L1580.38 287.67Z" fill="white"/>
                    <path d="M1717.2 443.93C1717.09 443.88 1716.97 443.82 1716.86 443.76C1716.91 443.7 1716.98 443.58 1717.02 443.59C1717.14 443.61 1717.26 443.68 1717.38 443.73C1717.33 443.79 1717.27 443.85 1717.21 443.93H1717.2Z" fill="white"/>
                    <path d="M1637.99 454.11C1637.92 454.04 1637.86 453.99 1637.8 453.94C1637.91 453.88 1638.02 453.79 1638.14 453.76C1638.18 453.75 1638.26 453.86 1638.32 453.91C1638.21 453.98 1638.1 454.05 1638 454.11H1637.99Z" fill="white"/>
                    <path d="M1723.26 406.27C1723.37 406.32 1723.49 406.38 1723.6 406.44C1723.55 406.5 1723.48 406.62 1723.44 406.61C1723.32 406.59 1723.2 406.52 1723.08 406.47C1723.13 406.41 1723.19 406.35 1723.25 406.28L1723.26 406.27Z" fill="white"/>
                    <path d="M1600.67 370.85C1600.56 370.8 1600.44 370.74 1600.33 370.68C1600.38 370.62 1600.45 370.5 1600.49 370.51C1600.61 370.53 1600.73 370.6 1600.85 370.65C1600.8 370.71 1600.74 370.77 1600.68 370.84L1600.67 370.85Z" fill="white"/>
                    <path d="M1712.96 326.8C1713.03 326.73 1713.08 326.67 1713.13 326.61C1713.19 326.72 1713.28 326.83 1713.31 326.95C1713.32 326.99 1713.21 327.07 1713.16 327.13C1713.09 327.02 1713.02 326.91 1712.96 326.81V326.8Z" fill="white"/>
                    <path d="M1625.32 426.53C1625.21 426.59 1625.1 426.68 1624.98 426.71C1624.94 426.72 1624.86 426.61 1624.8 426.56C1624.91 426.5 1625.02 426.41 1625.14 426.38C1625.18 426.37 1625.26 426.48 1625.32 426.53Z" fill="white"/>
                    <path d="M1715.92 337.83C1715.81 337.78 1715.69 337.72 1715.58 337.66C1715.63 337.6 1715.7 337.48 1715.74 337.49C1715.86 337.51 1715.98 337.58 1716.1 337.63L1715.93 337.83H1715.92Z" fill="white"/>
                    <path d="M1616.81 313.47C1616.76 313.58 1616.7 313.7 1616.64 313.81C1616.58 313.76 1616.46 313.69 1616.47 313.65C1616.49 313.53 1616.56 313.41 1616.61 313.29C1616.67 313.34 1616.73 313.4 1616.8 313.46L1616.81 313.47Z" fill="white"/>
                    <path d="M1556.84 208.13C1556.73 208.19 1556.62 208.28 1556.5 208.31C1556.46 208.32 1556.38 208.21 1556.32 208.16C1556.43 208.1 1556.54 208.01 1556.66 207.98C1556.7 207.97 1556.78 208.08 1556.84 208.13Z" fill="white"/>
                    <path d="M1620.65 361.25C1620.11 359.41 1621.23 358.41 1622.3 357.49C1622.69 357.15 1623.38 356.73 1623.52 356.48C1624.41 354.9 1625.65 356.66 1626.56 356.03C1627.03 357.01 1627.93 358.02 1627.86 358.96C1627.79 359.85 1627.61 360.96 1627.1 361.82C1626.62 362.64 1626.31 364 1624.8 362.98C1624.58 362.83 1624.06 363.14 1623.68 363.19C1621.97 363.43 1620.95 362.73 1620.64 361.25H1620.65Z" fill="white"/>
                    <path d="M1757.56 434.66C1756.44 434.88 1755.47 434.16 1755.4 433.76C1755.12 432.24 1753.61 431.92 1752.93 430.81C1752.45 430.03 1752.28 429.48 1752.81 428.77C1753.68 427.6 1754.31 427.35 1755.28 427.79C1755.7 427.98 1756.12 428.12 1756.57 428.25C1758.49 428.82 1760 432.58 1758.31 434.14C1758.01 434.42 1757.77 434.65 1757.57 434.64L1757.56 434.66Z" fill="white"/>
                    <path d="M1723.4 348.45C1722.58 347.87 1721.21 348.38 1720.82 347.05C1720.77 346.86 1720.79 346.62 1720.68 346.48C1720.23 345.9 1719.53 345.45 1719.78 344.56C1719.98 343.86 1720.66 343.61 1721.16 343.21C1722.15 342.42 1723.93 342.65 1724.91 343.64C1725.67 344.41 1725.82 345.27 1725.5 346.33C1725.17 347.43 1724.14 347.77 1723.39 348.45H1723.4Z" fill="white"/>
                    <path d="M1830.01 260.93C1831.68 260.58 1832.57 261.03 1832.96 262.21C1833.18 262.86 1833.23 263.56 1833.41 264.22C1833.58 264.85 1833.35 265.1 1832.74 265.19C1832.06 265.29 1831.4 265.57 1830.73 265.59C1829.58 265.63 1828.69 265.03 1828.03 264.13C1827.89 263.95 1827.84 263.53 1827.96 263.36C1828.39 262.72 1828.56 262 1828.84 261.32C1828.98 260.99 1829.88 260.98 1830 260.94L1830.01 260.93Z" fill="white"/>
                    <path d="M1874.81 381.7C1874.07 381.92 1873.02 382.26 1872.45 381.16C1872.18 380.64 1871.68 379.66 1872.32 379.3C1873.02 378.91 1873.48 377.9 1874.5 378.18C1875.2 378.38 1875.88 378.83 1876.58 378.86C1877.22 378.88 1877.39 379.05 1877.52 379.61C1877.71 380.41 1877.33 380.95 1876.72 381.26C1876.21 381.52 1875.58 381.53 1874.8 381.7H1874.81Z" fill="white"/>
                    <path d="M1827.27 239.79C1826.51 239.97 1825.74 239.51 1825.53 238.78C1825.46 238.53 1826.3 235.87 1826.49 235.74C1826.92 235.43 1828.6 236.07 1828.54 236.13C1827.68 237.01 1829.16 237.3 1828.95 237.97C1828.74 238.6 1827.76 239.68 1827.28 239.8L1827.27 239.79Z" fill="white"/>
                    <path d="M1669.12 314.3C1669.19 314.7 1669.43 315.29 1669.05 315.38C1668.35 315.54 1667.64 316.03 1666.83 315.51C1666.38 315.22 1666.34 314.69 1665.97 314.45C1665.38 314.06 1665.21 313.72 1665.6 313.05C1666.02 312.33 1666.7 312.08 1667.28 312.35C1668.1 312.73 1669.14 313.1 1669.12 314.31V314.3Z" fill="white"/>
                    <path d="M1751.88 374.29C1752.49 374.2 1753.21 373.72 1753.41 374.88C1753.45 375.14 1754.03 375.3 1754.35 375.53C1754.71 375.79 1755.04 376.08 1755.38 376.36C1754.75 376.56 1754.07 377.02 1753.51 376.9C1752.8 376.74 1752.2 376.1 1751.53 375.7C1751.16 375.49 1750.86 375.22 1750.94 374.81C1751.03 374.36 1751.51 374.38 1751.88 374.3V374.29Z" fill="white"/>
                    <path d="M1709.24 222.89C1709.43 222.85 1709.61 222.79 1709.8 222.77C1710.23 222.74 1710.75 222.3 1711.05 222.92C1711.35 223.56 1711.27 224.18 1710.78 224.76C1710.37 225.25 1710.02 225.1 1709.56 224.85C1709.26 224.69 1708.83 224.79 1708.53 224.63C1708.13 224.43 1707.59 224.26 1707.61 223.67C1707.63 222.97 1708.28 223.16 1708.67 223.01C1708.85 222.94 1709.04 222.93 1709.23 222.88L1709.24 222.89Z" fill="white"/>
                    <path d="M1836.98 244.3C1837.25 243.44 1837.27 242.46 1837.94 241.76C1838.09 241.6 1838.45 241.51 1838.74 241.74C1839.1 242.03 1839.75 242.16 1839.64 242.74C1839.51 243.45 1839.22 244.14 1838.92 244.8C1838.57 245.59 1838.21 244.62 1837.81 244.76C1837.41 244.9 1837.05 244.84 1836.97 244.3H1836.98Z" fill="white"/>
                    <path d="M1705.68 387.24C1706.18 387.04 1706.54 387.15 1706.64 387.79C1706.8 388.79 1706.4 389.46 1705.52 389.69C1704.74 389.89 1704.21 389.5 1703.89 388.94C1703.52 388.29 1704.4 387.48 1705.68 387.24Z" fill="white"/>
                    <path d="M1672.76 469.15C1673.05 469.09 1673.33 468.97 1673.62 468.98C1674.29 468.98 1674.66 469.72 1674.31 470.27C1674.1 470.6 1673.94 470.98 1673.66 471.24C1673.3 471.58 1672.74 471.73 1672.34 471.53C1671.89 471.3 1671.85 470.56 1671.28 470.34C1671.64 469.92 1671.72 469.21 1672.48 469.21C1672.58 469.21 1672.67 469.17 1672.77 469.15H1672.76Z" fill="white"/>
                    <path d="M1785.8 263.19C1785.51 263 1785.27 262.83 1785.02 262.69C1784.38 262.34 1784.86 261.35 1784.06 261.05C1783.64 260.89 1783.55 260.46 1783.83 260.11C1784.1 259.78 1784.47 259.82 1784.83 260.07C1785.31 260.4 1785.82 260.68 1786.28 261.03C1786.5 261.2 1787.11 261.2 1786.72 261.77C1786.4 262.23 1786.11 262.71 1785.8 263.18V263.19Z" fill="white"/>
                    <path d="M1896.9 312.49C1896.94 313.11 1896.12 313.88 1895.4 313.89C1895.12 313.89 1893.83 312.62 1893.85 312.27C1893.9 311.4 1894.69 311.65 1895.16 311.55C1896.04 311.37 1896.87 311.88 1896.9 312.48V312.49Z" fill="white"/>
                    <path d="M1838.62 369.89C1838.41 369.32 1838.46 368.94 1839.12 368.83C1839.93 368.69 1840.85 369.08 1840.97 369.65C1841.09 370.22 1840.41 370.98 1839.6 371.14C1838.4 371.39 1838.88 370.31 1838.62 369.89Z" fill="white"/>
                    <path d="M1836.29 376.69C1835.78 376.51 1835.21 376.34 1835.04 375.75C1834.86 375.1 1835.84 374.19 1836.65 374.2C1837.18 374.2 1837.22 374.58 1837.27 374.96C1837.37 375.82 1836.91 376.65 1836.29 376.7V376.69Z" fill="white"/>
                    <path d="M1808.01 291.01C1808.38 290.93 1808.58 290.91 1808.76 290.85C1809.44 290.61 1809.78 291.21 1810.28 291.43C1809.92 291.83 1809.85 292.52 1809.14 292.58C1808.95 292.6 1808.76 292.64 1808.58 292.7C1807.96 292.89 1807.54 292.89 1807.37 292.05C1807.21 291.24 1807.49 290.99 1808.02 291.01H1808.01Z" fill="white"/>
                    <path d="M1825.82 459.33C1825.63 458.67 1826.05 458.44 1826.41 458.51C1827.12 458.66 1827.81 458.95 1828.49 459.23C1829.12 459.5 1828.58 459.81 1828.46 460.08C1827.92 461.27 1827.15 460.12 1826.49 460.24C1825.72 460.39 1826.02 459.57 1825.82 459.32V459.33Z" fill="white"/>
                    <path d="M1826.2 321.51C1825.45 321.83 1824.89 321.43 1824.55 320.88C1824.3 320.46 1825.21 319.13 1825.6 319.24C1826.35 319.47 1826.75 320 1826.86 320.8C1826.93 321.36 1826.58 321.43 1826.2 321.51Z" fill="white"/>
                    <path d="M1656.45 263.77C1656.43 263.19 1656.8 262.78 1657.24 262.44C1657.5 262.24 1658.71 263.31 1658.89 263.89C1659.05 264.41 1658.7 264.54 1658.3 264.62C1657.51 264.77 1656.59 264.36 1656.45 263.78V263.77Z" fill="white"/>
                    <path d="M1841.03 282.88C1841 283.18 1840.86 283.5 1840.53 283.41C1839.74 283.19 1838.88 283.16 1838.17 282.65C1837.87 282.43 1837.78 282.12 1837.85 281.91C1837.91 281.71 1838.31 281.43 1838.49 281.48C1839.2 281.66 1839.89 281.94 1840.57 282.2C1840.86 282.31 1841.01 282.56 1841.03 282.88Z" fill="white"/>
                    <path d="M1866.76 263.91C1866.46 264 1866.09 264.12 1865.88 263.65C1865.7 263.24 1866.01 262.96 1866.15 262.62C1866.44 261.94 1867.09 261.99 1867.6 261.96C1867.91 261.94 1868.51 262.34 1868.5 262.52C1868.48 262.91 1868.22 263.37 1867.91 263.63C1867.65 263.85 1867.19 263.82 1866.75 263.91H1866.76Z" fill="white"/>
                    <path d="M1866.14 425.03C1866.32 425.48 1866.21 425.84 1865.56 425.93C1864.75 426.05 1863.86 425.67 1863.73 425.1C1863.5 424.14 1864.84 424.54 1864.91 423.76C1864.92 423.63 1865.92 424.3 1866.14 425.03Z" fill="white"/>
                    <path d="M1752.63 282.38C1752.47 282.3 1752.23 282.27 1752.17 282.14C1751.84 281.45 1752.59 279.92 1753.32 279.83C1753.57 279.8 1753.95 279.83 1754.07 279.98C1754.2 280.14 1754.17 280.53 1754.07 280.75C1753.62 281.74 1753.12 282.27 1752.64 282.38H1752.63Z" fill="white"/>
                    <path d="M1662.45 345.08C1662.51 345.36 1662.62 345.64 1662.62 345.93C1662.62 346.66 1661.97 346.47 1661.57 346.6C1661.05 346.78 1660.92 346.41 1660.84 346.01C1660.77 345.65 1660.62 345.3 1660.9 344.93C1661.18 344.55 1661.37 344.02 1661.95 344.14C1662.4 344.23 1662.36 344.72 1662.45 345.08Z" fill="white"/>
                    <path d="M1838.57 285.04C1839.18 285.04 1839.21 285.44 1839.25 285.81C1839.35 286.69 1838.88 287.49 1838.26 287.53C1837.74 287.56 1837.69 287.13 1837.65 286.76C1837.56 285.88 1838.02 285.1 1838.57 285.04Z" fill="white"/>
                    <path d="M1874.32 423.74C1873.93 424.3 1873.7 424.91 1872.88 425C1872.2 425.07 1872.11 424.75 1871.99 424.24C1871.86 423.69 1871.92 423.42 1872.53 423.21C1873.33 422.93 1873.75 423.49 1874.33 423.74H1874.32Z" fill="white"/>
                    <path d="M1861.13 430.51C1860.96 430.45 1860.69 430.42 1860.65 430.31C1860.52 429.96 1860.31 429.51 1860.43 429.21C1860.62 428.72 1860.8 428.09 1861.49 428.03C1862.02 427.98 1862.07 428.4 1862.11 428.78C1862.2 429.67 1861.77 430.42 1861.14 430.52L1861.13 430.51Z" fill="white"/>
                    <path d="M1643.04 310.15C1642.64 310.22 1642.22 310.3 1642.19 309.75C1642.16 309.04 1642.48 308.41 1643.21 308.35C1643.59 308.32 1644.02 308.74 1644.42 308.95C1644.05 309.47 1643.86 310.18 1643.04 310.16V310.15Z" fill="white"/>
                    <path d="M1716.38 447.29C1716.28 446.59 1716.92 445.71 1717.41 445.69C1717.93 445.67 1717.93 446.09 1718.03 446.44C1718.23 447.16 1717.94 447.72 1717.34 447.95C1717.16 448.02 1716.47 447.66 1716.39 447.29H1716.38Z" fill="white"/>
                    <path d="M1838.85 338.72C1838.37 338.82 1837.86 338.91 1837.9 338.38C1837.95 337.73 1838.25 337.1 1838.44 336.47C1839.03 336.8 1839.53 337.13 1839.61 337.93C1839.68 338.69 1839.16 338.61 1838.85 338.72Z" fill="white"/>
                    <path d="M1784.98 475.82C1784.21 476.08 1784.08 475.71 1783.92 475.17C1783.67 474.34 1784.11 474.2 1784.75 474.06C1785.41 473.91 1785.54 474.22 1785.69 474.77C1785.91 475.57 1785.59 475.84 1784.98 475.82Z" fill="white"/>
                    <path d="M1843.25 326.96C1843.23 326.44 1842.68 325.71 1843.67 325.64C1844.21 325.6 1844.77 325.93 1844.96 326.52C1845.28 327.49 1844.16 327.44 1843.78 327.79C1843.32 328.23 1843.5 327.22 1843.24 326.97L1843.25 326.96Z" fill="white"/>
                    <path d="M1875.55 246.33C1875.27 246.39 1874.99 246.49 1874.71 246.5C1874.19 246.51 1874.16 246.09 1874.11 245.72C1874.07 245.45 1873.86 245.16 1874.14 244.92C1874.46 244.65 1874.8 244.64 1875.14 244.9C1875.29 245.02 1875.46 245.11 1875.62 245.21C1875.92 245.42 1876.47 245.64 1876.46 245.84C1876.44 246.28 1875.89 246.2 1875.55 246.31V246.33Z" fill="white"/>
                    <path d="M1665.47 423.81C1665.41 424.51 1664.8 424.36 1664.39 424.46C1663.63 424.65 1663.73 423.99 1663.61 423.58C1663.5 423.2 1663.46 422.8 1664.02 422.74C1664.59 422.67 1665.42 423.3 1665.46 423.8L1665.47 423.81Z" fill="white"/>
                    <path d="M1818.48 397.09C1818.39 396.59 1818.32 396.19 1818.88 396.13C1819.45 396.07 1820.31 396.69 1820.32 397.19C1820.32 397.71 1819.88 397.68 1819.54 397.8C1818.7 398.11 1818.55 397.6 1818.48 397.09Z" fill="white"/>
                    <path d="M1861.73 291.9C1861.72 292.1 1861.8 292.5 1861.66 292.61C1861.28 292.9 1860.99 292.45 1860.81 292.25C1860.54 291.95 1859.47 292.08 1860.13 291.18C1860.35 290.88 1860.5 290.49 1860.95 290.52C1861.85 290.58 1861.39 291.42 1861.73 291.9Z" fill="white"/>
                    <path d="M1718.53 241.41C1718.11 241.23 1717.27 241.14 1717.52 240.59C1717.73 240.13 1717.3 239.43 1717.97 239.22C1718.37 239.1 1719.24 240.37 1719.15 240.93C1719.1 241.22 1718.87 241.39 1718.53 241.41Z" fill="white"/>
                    <path d="M1779.15 458.46C1779.15 458.46 1778.78 458.83 1778.4 459.11C1778.23 459.23 1777.26 458.31 1777.26 458.03C1777.26 457.3 1777.89 457.49 1778.29 457.36C1778.77 457.19 1778.96 457.48 1779.16 458.46H1779.15Z" fill="white"/>
                    <path d="M1744.52 460.98C1743.82 460.93 1744 460.32 1743.86 459.92C1743.69 459.42 1744.04 459.15 1744.42 459.22C1744.85 459.3 1745.27 459.58 1745.63 459.85C1745.76 459.95 1744.78 460.97 1744.52 460.98Z" fill="white"/>
                    <path d="M1609.49 342.84C1609.28 342.36 1609.47 342.11 1609.75 342.06C1610.49 341.91 1610.98 342.29 1611.29 342.93C1611.45 343.26 1611.25 343.51 1610.97 343.58C1610.21 343.76 1609.76 343.32 1609.49 342.84Z" fill="white"/>
                    <path d="M1760.37 261.34C1760.41 261.96 1760.12 262.5 1759.63 262.61C1759.39 262.66 1758.74 262.34 1758.64 261.77C1758.5 261.01 1759.11 261.11 1759.52 260.99C1759.9 260.88 1760.26 260.86 1760.38 261.34H1760.37Z" fill="white"/>
                    <path d="M1647.87 431.79C1647.77 431.53 1648.32 430.26 1648.46 430.34C1648.85 430.57 1649.28 430.83 1649.52 431.19C1649.75 431.53 1649.59 431.93 1649.08 431.97C1648.66 432.01 1648.17 432.44 1647.86 431.78L1647.87 431.79Z" fill="white"/>
                    <path d="M1765.63 258.43C1765.09 258.38 1764.5 258.15 1764.35 257.38C1764.3 257.1 1764.44 256.82 1764.81 256.83C1765.52 256.85 1766.07 257.14 1766.24 257.87C1766.3 258.14 1766.15 258.42 1765.63 258.43Z" fill="white"/>
                    <path d="M1766.14 192.56C1765.62 192.7 1765.35 192.37 1765.42 192C1765.5 191.57 1765.78 191.15 1766.05 190.79C1766.15 190.66 1767.17 191.64 1767.18 191.9C1767.18 192.27 1766.78 192.53 1766.14 192.56Z" fill="white"/>
                    <path d="M1839.19 329.59C1838.79 329.67 1838.39 329.75 1838.32 329.2C1838.24 328.6 1838.56 328.07 1839.05 327.97C1839.29 327.92 1839.94 328.24 1840.04 328.81C1840.19 329.58 1839.55 329.44 1839.19 329.59Z" fill="white"/>
                    <path d="M1844.77 236.57C1844.24 236.42 1843.76 236.23 1843.57 235.66C1843.46 235.32 1843.63 235.06 1843.91 235.03C1844.38 234.99 1845.02 234.39 1845.3 235.3C1845.47 235.86 1844.91 236.11 1844.77 236.56V236.57Z" fill="white"/>
                    <path d="M1827.65 322.41C1827.49 322.34 1827.33 322.27 1827.17 322.19C1827.61 321.47 1828.19 320.81 1828.19 319.88C1828.19 319.84 1828.56 319.75 1828.74 319.78C1828.84 319.8 1829.01 320.09 1828.99 320.12C1828.5 320.73 1828.84 321.67 1828.16 322.19C1828.02 322.3 1827.82 322.34 1827.65 322.41Z" fill="white"/>
                    <path d="M1825.71 318.25C1825.62 317.68 1826.42 317.83 1826.87 317.78C1827.76 317.68 1827.46 318.53 1827.63 318.97C1827.68 319.1 1827.48 319.69 1827.23 319.25C1826.85 318.6 1825.86 319.68 1825.71 318.25Z" fill="white"/>
                    <path d="M1649.55 366.52C1649.81 367.18 1649.2 367.52 1648.99 368C1648.68 367.81 1648.14 367.66 1648.1 367.42C1648 366.89 1648.35 366.46 1648.88 366.23C1649.2 366.1 1649.39 366.27 1649.56 366.51L1649.55 366.52Z" fill="white"/>
                    <path d="M1782.72 452.61C1782.7 452.19 1782.33 451.67 1783.09 451.49C1783.64 451.36 1783.75 451.71 1783.81 452.1C1783.88 452.52 1784.21 453.04 1783.45 453.23C1782.9 453.37 1782.81 453 1782.71 452.61H1782.72Z" fill="white"/>
                    <path d="M1634.52 289.56C1633.62 289.21 1633.32 288.66 1633.29 287.95C1633.29 287.79 1633.41 287.62 1633.47 287.45C1633.63 287.55 1633.78 287.69 1633.95 287.75C1634.62 287.99 1634.64 288.59 1634.72 289.13C1634.74 289.3 1634.54 289.5 1634.52 289.55V289.56Z" fill="white"/>
                    <path d="M1783.96 482.72C1783.82 482.76 1783.67 482.42 1783.62 482.01C1783.56 481.59 1783.2 481.06 1783.97 480.87C1784.51 480.74 1784.64 481.07 1784.7 481.47C1784.77 481.89 1785.1 482.42 1783.96 482.72Z" fill="white"/>
                    <path d="M1801.13 422.64C1801.18 422.51 1801.19 422.28 1801.28 422.25C1801.91 422.05 1802.53 421.88 1803.12 422.43C1803.36 422.66 1803.47 422.82 1803.2 423.02C1802.78 423.33 1801.34 423.06 1801.12 422.64H1801.13Z" fill="white"/>
                    <path d="M1690.35 394.14C1689.41 394.1 1689.83 393.27 1689.64 392.81C1689.55 392.6 1689.95 392.35 1690.25 392.37C1691.13 392.43 1690.83 393.22 1690.97 393.7C1691.04 393.92 1690.68 394.15 1690.35 394.14Z" fill="white"/>
                    <path d="M1797.91 355.34C1796.97 355.3 1797.39 354.47 1797.2 354.01C1797.11 353.8 1797.51 353.55 1797.81 353.57C1798.69 353.63 1798.39 354.42 1798.53 354.9C1798.6 355.12 1798.24 355.35 1797.91 355.34Z" fill="white"/>
                    <path d="M1704.1 262.66C1703.96 262.7 1703.81 262.36 1703.76 261.95C1703.7 261.53 1703.34 261 1704.11 260.81C1704.65 260.68 1704.78 261.01 1704.84 261.41C1704.91 261.83 1705.24 262.36 1704.1 262.66Z" fill="white"/>
                    <path d="M1875.41 350.59C1875.88 350.19 1876.35 349.77 1876.84 349.37C1876.86 349.35 1877.03 349.45 1877.07 349.52C1877.24 349.89 1877.16 350.22 1876.91 350.56C1876.63 350.94 1876.41 351.38 1875.84 351.18C1875.71 351.13 1875.63 350.91 1875.41 350.58V350.59Z" fill="white"/>
                    <path d="M1846.35 304.81C1846.46 305.19 1846.6 305.59 1846.09 305.76C1845.44 305.99 1845.27 305.58 1845.19 305.04C1845.13 304.67 1844.95 304.27 1845.46 304.09C1846.11 303.87 1846.31 304.26 1846.35 304.8V304.81Z" fill="white"/>
                    <path d="M1803.68 263.04C1803.14 262.99 1802.87 262.91 1802.88 262.55C1802.9 261.84 1803.19 261.3 1803.92 261.12C1804.06 261.09 1804.25 261.24 1804.42 261.3C1804.16 261.9 1803.91 262.51 1803.68 263.04Z" fill="white"/>
                    <path d="M1817.48 379.49C1816.99 379.67 1816.57 379.65 1816.54 379.4C1816.47 378.91 1815.87 378.31 1816.65 377.9C1816.91 377.76 1817.33 377.8 1817.39 378.06C1817.51 378.55 1817.92 379.12 1817.48 379.49Z" fill="white"/>
                    <path d="M1688.49 274.43C1688.09 274.5 1687.72 274.58 1687.62 274.01C1687.48 273.24 1688.09 273.33 1688.5 273.22C1688.88 273.12 1689.25 273.07 1689.36 273.63C1689.51 274.41 1688.88 274.28 1688.49 274.43Z" fill="white"/>
                    <path d="M1707.06 361.73C1707.02 362.67 1706.19 362.25 1705.73 362.44C1705.52 362.53 1705.27 362.13 1705.29 361.83C1705.35 360.95 1706.14 361.25 1706.62 361.11C1706.84 361.04 1707.07 361.4 1707.06 361.73Z" fill="white"/>
                    <path d="M1813.52 258.75C1813.63 259.13 1813.77 259.53 1813.26 259.7C1812.61 259.93 1812.44 259.52 1812.36 258.98C1812.3 258.61 1812.12 258.21 1812.63 258.03C1813.28 257.81 1813.48 258.2 1813.52 258.74V258.75Z" fill="white"/>
                    <path d="M1861.67 415.62C1861.87 416.27 1861.71 416.47 1861.41 416.56C1860.75 416.77 1860.6 416.34 1860.54 415.83C1860.51 415.56 1860.33 415.05 1860.57 415.08C1861.04 415.15 1861.47 415.51 1861.67 415.62Z" fill="white"/>
                    <path d="M1657.89 295.51C1657.74 295.47 1657.48 295.41 1657.22 295.35C1657.53 294.92 1657.62 294.26 1658.3 294.2C1658.59 294.17 1658.81 294.3 1658.87 294.66C1659.01 295.42 1658.41 295.32 1657.89 295.51Z" fill="white"/>
                    <path d="M1857.68 219.9C1857.58 219.66 1857.74 219.42 1858.02 219.33C1858.39 219.2 1858.82 219.17 1858.85 219.72C1858.88 220.11 1859.38 220.68 1858.72 220.84C1858.48 220.9 1857.83 220.55 1857.68 219.91V219.9Z" fill="white"/>
                    <path d="M1901.21 396.55C1900.56 396.78 1900.22 396.18 1899.74 395.95C1899.96 395.8 1900.17 395.59 1900.41 395.53C1900.82 395.44 1901.34 395.11 1901.54 395.87C1901.63 396.21 1901.49 396.39 1901.22 396.56L1901.21 396.55Z" fill="white"/>
                    <path d="M1826.1 230.08C1825.93 229.71 1826.01 230.13 1826.27 229.85C1826.41 229.7 1826.83 229.61 1826.99 229.71C1827.44 229.98 1828.09 230.12 1828.05 230.86C1828.05 230.94 1827.95 231.09 1827.91 231.09C1827.15 231.04 1826.72 230.37 1826.09 230.08H1826.1Z" fill="white"/>
                    <path d="M1791.38 272.76C1790.95 272.43 1790.26 272.29 1790.35 271.57C1790.37 271.4 1790.76 271.16 1790.98 271.15C1791.69 271.13 1791.5 271.79 1791.64 272.18C1791.74 272.45 1791.72 272.69 1791.37 272.75L1791.38 272.76Z" fill="white"/>
                    <path d="M1754.67 359.81C1754.48 359.85 1754.29 359.94 1754.11 359.91C1753.99 359.89 1753.8 359.69 1753.81 359.6C1753.87 359.28 1755.63 358.88 1755.88 359.13C1755.94 359.19 1755.87 359.51 1755.8 359.54C1755.44 359.66 1755.05 359.72 1754.67 359.8V359.81Z" fill="white"/>
                    <path d="M1725.46 202.5C1724.88 202.66 1724.71 202.33 1724.67 201.93C1724.64 201.63 1724.77 201.43 1725.13 201.35C1725.66 201.24 1725.82 201.55 1725.86 201.95C1725.89 202.23 1725.8 202.53 1725.46 202.5Z" fill="white"/>
                    <path d="M1885.95 408.28C1885.63 408.26 1885.43 408.16 1885.36 407.81C1885.25 407.28 1885.55 407.12 1885.96 407.08C1886.26 407.05 1886.46 407.18 1886.54 407.54C1886.65 408.08 1886.34 408.19 1885.96 408.28H1885.95Z" fill="white"/>
                    <path d="M1669.94 422.68C1669.62 422.66 1669.42 422.56 1669.35 422.21C1669.24 421.68 1669.54 421.52 1669.95 421.48C1670.25 421.45 1670.45 421.58 1670.53 421.94C1670.64 422.48 1670.33 422.59 1669.95 422.68H1669.94Z" fill="white"/>
                    <path d="M1635.11 316.17C1635.04 315.81 1635.02 315.48 1635.55 315.36C1635.91 315.28 1636.11 315.41 1636.21 315.7C1636.34 316.08 1636.32 316.43 1635.79 316.54C1635.44 316.62 1635.26 316.48 1635.11 316.17Z" fill="white"/>
                    <path d="M1800.53 468.47C1800.42 469 1800.2 469.46 1799.66 469.65C1799.52 469.7 1799.17 469.59 1799.17 469.58C1799.21 469.03 1799.45 468.58 1800 468.4C1800.15 468.35 1800.34 468.44 1800.54 468.48L1800.53 468.47Z" fill="white"/>
                    <path d="M1696.58 450.76C1696.26 450.9 1696.04 450.71 1695.95 450.43C1695.82 450.05 1695.84 449.7 1696.37 449.59C1696.73 449.51 1696.93 449.64 1697.03 449.93C1697.16 450.32 1697.14 450.69 1696.59 450.77L1696.58 450.76Z" fill="white"/>
                    <path d="M1803.1 401.93C1802.78 401.91 1802.58 401.81 1802.51 401.46C1802.4 400.93 1802.7 400.77 1803.11 400.73C1803.41 400.7 1803.61 400.83 1803.69 401.19C1803.8 401.73 1803.49 401.84 1803.11 401.93H1803.1Z" fill="white"/>
                    <path d="M1790.7 200.59C1790.33 200.56 1790.13 200.47 1790.05 200.11C1789.94 199.58 1790.24 199.42 1790.65 199.38C1790.95 199.35 1791.15 199.49 1791.23 199.84C1791.35 200.39 1791 200.47 1790.7 200.58V200.59Z" fill="white"/>
                    <path d="M1800.34 222.67C1800.19 222.13 1800.52 221.96 1800.92 221.92C1801.22 221.89 1801.42 222.02 1801.5 222.38C1801.61 222.91 1801.3 223.07 1800.9 223.11C1800.61 223.14 1800.34 223.02 1800.34 222.67Z" fill="white"/>
                    <path d="M1775.43 500.82C1775.81 500.75 1776.19 500.68 1776.26 501.23C1776.28 501.4 1776.22 501.58 1776.2 501.75C1775.82 501.57 1775.44 501.39 1775.06 501.21C1775.18 501.08 1775.31 500.96 1775.44 500.82H1775.43Z" fill="white"/>
                    <path d="M1746.26 456.39C1745.88 456.46 1745.5 456.53 1745.43 455.98C1745.41 455.81 1745.47 455.63 1745.49 455.46C1745.87 455.64 1746.25 455.82 1746.63 456C1746.51 456.13 1746.38 456.25 1746.25 456.39H1746.26Z" fill="white"/>
                    <path d="M1867.82 395.17C1867.48 394.96 1867.16 394.76 1866.84 394.57C1867.06 394.42 1867.26 394.19 1867.5 394.15C1868.01 394.08 1868.08 394.51 1868.1 394.88C1868.1 394.98 1867.91 395.09 1867.83 395.17H1867.82Z" fill="white"/>
                    <path d="M1705.3 353.58C1705.33 353.35 1705.33 353.05 1705.39 353.03C1705.89 352.9 1706.13 353.4 1706.52 353.56C1706.39 353.69 1706.29 353.87 1706.14 353.92C1705.76 354.05 1705.41 354.02 1705.3 353.57V353.58Z" fill="white"/>
                    <path d="M1612.49 346.81C1612.35 346.75 1612.13 346.73 1612.09 346.63C1611.95 346.28 1611.84 345.86 1612.34 345.72C1612.57 345.66 1612.85 345.79 1613.11 345.84C1612.9 346.16 1612.7 346.47 1612.49 346.8V346.81Z" fill="white"/>
                    <path d="M1638.96 359.34C1639.09 359.86 1638.74 359.99 1638.35 360.03C1638.19 360.05 1638.02 359.91 1637.85 359.85C1638.12 359.53 1638.4 359.21 1638.67 358.89C1638.76 359.04 1638.86 359.19 1638.95 359.34H1638.96Z" fill="white"/>
                    <path d="M1844.43 389.91C1844.36 389.88 1844.13 389.87 1844.09 389.78C1843.95 389.44 1843.83 389.02 1844.32 388.86C1844.54 388.79 1844.83 388.92 1845.09 388.97C1844.87 389.27 1844.66 389.58 1844.42 389.91H1844.43Z" fill="white"/>
                    <path d="M1806.82 396.3C1806.3 396.43 1806.17 396.08 1806.13 395.69C1806.11 395.53 1806.25 395.36 1806.31 395.19C1806.63 395.46 1806.95 395.74 1807.27 396.01C1807.12 396.1 1806.97 396.2 1806.82 396.29V396.3Z" fill="white"/>
                    <path d="M1638.37 407.73C1638.2 407.67 1638.03 407.61 1637.86 407.56C1638.12 407.23 1638.37 406.9 1638.63 406.56C1638.73 406.7 1638.87 406.83 1638.92 406.99C1639.09 407.49 1638.76 407.64 1638.37 407.73Z" fill="white"/>
                    <path d="M1784.4 232.73C1784.53 233.25 1784.18 233.38 1783.79 233.42C1783.63 233.44 1783.46 233.3 1783.29 233.24C1783.56 232.92 1783.84 232.6 1784.11 232.28C1784.2 232.43 1784.3 232.58 1784.39 232.73H1784.4Z" fill="white"/>
                    <path d="M1773.63 193.66C1773.45 193.83 1773.34 194.01 1773.19 194.06C1772.8 194.19 1772.44 194.15 1772.39 193.63C1772.37 193.46 1772.45 193.29 1772.48 193.12C1772.86 193.3 1773.24 193.48 1773.64 193.67L1773.63 193.66Z" fill="white"/>
                    <path d="M1916.13 358.85C1915.75 358.73 1915.81 358.39 1915.77 358.13C1915.76 358.05 1915.99 357.83 1916.08 357.85C1916.44 357.93 1916.39 358.29 1916.42 358.55C1916.43 358.64 1916.22 358.75 1916.13 358.85Z" fill="white"/>
                    <path d="M1897.46 376.58C1897.17 376.74 1896.96 376.91 1896.71 376.97C1896.57 377 1896.38 376.86 1896.21 376.79C1896.43 376.64 1896.63 376.45 1896.87 376.37C1897 376.33 1897.2 376.48 1897.45 376.58H1897.46Z" fill="white"/>
                    <path d="M1678.16 237.32C1677.78 237.2 1677.84 236.86 1677.8 236.6C1677.79 236.52 1678.02 236.3 1678.11 236.32C1678.47 236.4 1678.42 236.76 1678.45 237.02C1678.46 237.11 1678.25 237.22 1678.16 237.32Z" fill="white"/>
                    <path d="M1626.58 334.29C1626.42 334 1626.25 333.79 1626.19 333.54C1626.16 333.4 1626.3 333.21 1626.37 333.04C1626.52 333.26 1626.71 333.46 1626.79 333.7C1626.83 333.83 1626.68 334.03 1626.58 334.28V334.29Z" fill="white"/>
                    <path d="M1627.62 337.29C1627.6 337.14 1627.52 336.88 1627.54 336.63C1627.57 336.33 1627.78 336.29 1627.91 336.55C1628.02 336.77 1628.08 337.05 1628.07 337.3C1628.05 337.59 1627.88 337.62 1627.61 337.3L1627.62 337.29Z" fill="white"/>
                    <path d="M1660.78 233.31C1660.75 233.48 1660.76 233.77 1660.68 233.81C1660.35 233.94 1660.22 233.7 1660.21 233.42C1660.21 233.25 1660.21 232.96 1660.31 232.92C1660.64 232.79 1660.75 233.03 1660.79 233.31H1660.78Z" fill="white"/>
                    <path d="M1880 405.33C1879.83 405.3 1879.54 405.31 1879.5 405.23C1879.37 404.9 1879.61 404.77 1879.89 404.76C1880.06 404.76 1880.35 404.76 1880.39 404.86C1880.52 405.19 1880.28 405.3 1880 405.34V405.33Z" fill="white"/>
                    <path d="M1648.73 410.08C1648.44 410.24 1648.23 410.41 1647.98 410.47C1647.84 410.5 1647.65 410.36 1647.48 410.29C1647.7 410.14 1647.9 409.95 1648.14 409.87C1648.27 409.83 1648.47 409.98 1648.72 410.08H1648.73Z" fill="white"/>
                    <path d="M1851.23 249.71C1851.11 249.66 1850.94 249.6 1850.78 249.53C1851 249.38 1851.2 249.19 1851.44 249.11C1851.57 249.07 1851.77 249.22 1851.93 249.29C1851.79 249.56 1851.59 249.72 1851.23 249.71Z" fill="white"/>
                    <path d="M1660.43 453.7C1660.35 453.42 1660.35 453.16 1660.7 453.14C1660.79 453.14 1660.93 453.39 1660.99 453.55C1661.1 453.82 1661.07 454.09 1660.72 454.1C1660.63 454.1 1660.52 453.83 1660.42 453.69L1660.43 453.7Z" fill="white"/>
                    <path d="M1811.94 256.98C1811.86 256.7 1811.86 256.44 1812.21 256.42C1812.3 256.42 1812.44 256.67 1812.5 256.83C1812.61 257.1 1812.58 257.37 1812.23 257.38C1812.14 257.38 1812.03 257.11 1811.93 256.97L1811.94 256.98Z" fill="white"/>
                    <path d="M1805.14 267.5C1805.06 267.22 1805.06 266.96 1805.41 266.94C1805.5 266.94 1805.64 267.19 1805.7 267.35C1805.81 267.62 1805.78 267.89 1805.43 267.9C1805.34 267.9 1805.23 267.63 1805.13 267.49L1805.14 267.5Z" fill="white"/>
                    <path d="M1785.86 309.41C1785.57 309.57 1785.36 309.74 1785.11 309.8C1784.97 309.83 1784.78 309.69 1784.61 309.62C1784.83 309.47 1785.03 309.28 1785.27 309.2C1785.4 309.16 1785.6 309.31 1785.85 309.41H1785.86Z" fill="white"/>
                    <path d="M1854.33 392.18C1854.12 392.12 1853.86 392.05 1853.61 391.98C1853.74 391.85 1853.85 391.63 1853.99 391.62C1854.24 391.59 1854.51 391.69 1854.77 391.73C1854.64 391.86 1854.52 391.99 1854.34 392.17L1854.33 392.18Z" fill="white"/>
                    <path d="M1760.57 386.89C1760.45 386.84 1760.28 386.78 1760.12 386.71C1760.34 386.56 1760.54 386.37 1760.78 386.29C1760.91 386.25 1761.11 386.4 1761.27 386.47C1761.13 386.74 1760.93 386.9 1760.57 386.89Z" fill="white"/>
                    <path d="M1762.83 432.66C1762.54 432.82 1762.33 432.99 1762.08 433.05C1761.94 433.08 1761.75 432.94 1761.58 432.87C1761.8 432.72 1762 432.53 1762.24 432.45C1762.37 432.41 1762.57 432.56 1762.82 432.66H1762.83Z" fill="white"/>
                    <path d="M1766.91 504.38C1766.53 504.26 1766.59 503.92 1766.55 503.66C1766.54 503.58 1766.77 503.36 1766.86 503.38C1767.22 503.46 1767.17 503.82 1767.2 504.08C1767.21 504.17 1767 504.28 1766.91 504.38Z" fill="white"/>
                    <path d="M1726.87 453.92C1726.79 453.64 1726.79 453.38 1727.14 453.36C1727.23 453.36 1727.37 453.61 1727.43 453.77C1727.54 454.04 1727.51 454.31 1727.16 454.32C1727.07 454.32 1726.96 454.05 1726.86 453.91L1726.87 453.92Z" fill="white"/>
                    <path d="M1692.03 249.53C1692 249.7 1692.01 249.99 1691.93 250.03C1691.6 250.16 1691.47 249.92 1691.46 249.64C1691.46 249.47 1691.46 249.18 1691.56 249.14C1691.89 249.01 1692 249.25 1692.04 249.53H1692.03Z" fill="white"/>
                    <path d="M1726.97 339.2C1726.8 339.17 1726.51 339.18 1726.47 339.1C1726.34 338.77 1726.58 338.64 1726.86 338.63C1727.03 338.63 1727.32 338.63 1727.36 338.73C1727.49 339.06 1727.25 339.17 1726.97 339.21V339.2Z" fill="white"/>
                    <path d="M1712.16 343.29C1712.05 343.38 1711.94 343.49 1711.81 343.55C1711.76 343.57 1711.66 343.46 1711.58 343.41C1711.69 343.32 1711.8 343.21 1711.93 343.15C1711.98 343.13 1712.08 343.24 1712.16 343.29Z" fill="white"/>
                    <path d="M1629.35 412.98C1629.24 413.07 1629.13 413.18 1629 413.24C1628.95 413.26 1628.85 413.15 1628.77 413.1C1628.88 413.01 1628.99 412.9 1629.12 412.84C1629.17 412.82 1629.27 412.93 1629.35 412.98Z" fill="white"/>
                    <path d="M1804.51 279.61C1804.37 279.57 1804.23 279.53 1804.09 279.48C1804.14 279.4 1804.19 279.26 1804.24 279.26C1804.38 279.26 1804.52 279.32 1804.67 279.36C1804.62 279.44 1804.57 279.52 1804.51 279.61Z" fill="white"/>
                    <path d="M1808.52 233.65C1808.61 233.71 1808.69 233.76 1808.77 233.81C1808.66 233.9 1808.55 234.01 1808.42 234.07C1808.37 234.09 1808.27 233.98 1808.19 233.93C1808.3 233.83 1808.41 233.74 1808.52 233.64V233.65Z" fill="white"/>
                    <path d="M1827.99 317.29C1828.03 317.15 1828.07 317.01 1828.12 316.87C1828.2 316.92 1828.34 316.97 1828.34 317.02C1828.34 317.16 1828.28 317.3 1828.24 317.45C1828.16 317.4 1828.08 317.35 1827.99 317.29Z" fill="white"/>
                    <path d="M1801.92 355.1C1801.83 354.99 1801.72 354.88 1801.66 354.75C1801.64 354.7 1801.75 354.6 1801.8 354.52C1801.89 354.63 1802 354.74 1802.06 354.87C1802.08 354.92 1801.97 355.02 1801.92 355.1Z" fill="white"/>
                    <path d="M1807.18 403.07C1807.24 402.98 1807.29 402.9 1807.34 402.82C1807.43 402.93 1807.54 403.04 1807.6 403.17C1807.62 403.22 1807.51 403.32 1807.46 403.4C1807.36 403.29 1807.27 403.18 1807.17 403.07H1807.18Z" fill="white"/>
                    <path d="M1866.31 409.99C1866.17 409.95 1866.03 409.91 1865.89 409.86C1865.94 409.78 1865.99 409.64 1866.04 409.64C1866.18 409.64 1866.32 409.7 1866.47 409.74C1866.42 409.82 1866.37 409.9 1866.31 409.99Z" fill="white"/>
                    <path d="M1825.78 297.97C1825.69 297.91 1825.61 297.86 1825.53 297.81C1825.64 297.72 1825.75 297.61 1825.88 297.55C1825.93 297.53 1826.03 297.64 1826.11 297.69C1826 297.79 1825.89 297.88 1825.78 297.98V297.97Z" fill="white"/>
                    <path d="M1771.6 348.42C1771.69 348.48 1771.77 348.53 1771.85 348.58C1771.74 348.67 1771.63 348.78 1771.5 348.84C1771.45 348.86 1771.35 348.75 1771.27 348.7C1771.38 348.6 1771.49 348.51 1771.6 348.41V348.42Z" fill="white"/>
                    <path d="M1790.14 490.17C1790.09 490.25 1790.02 490.39 1790 490.39C1789.86 490.35 1789.69 490.31 1789.61 490.2C1789.44 489.98 1789.53 489.84 1789.8 489.9C1789.93 489.93 1790.03 490.07 1790.15 490.17H1790.14Z" fill="white"/>
                    <path d="M1789.28 490C1789.17 489.91 1788.95 489.8 1788.96 489.72C1788.98 489.48 1789.15 489.41 1789.28 489.54C1789.39 489.65 1789.46 489.81 1789.54 489.95C1789.45 489.97 1789.37 489.99 1789.28 490.01V490Z" fill="white"/>
                    <path d="M1692.58 320.55C1692.44 320.51 1692.3 320.47 1692.16 320.42C1692.21 320.34 1692.26 320.2 1692.31 320.2C1692.45 320.2 1692.59 320.26 1692.74 320.3C1692.69 320.38 1692.64 320.46 1692.58 320.55Z" fill="white"/>
                    <path d="M1873.55 471.82C1873.41 471.78 1873.27 471.74 1873.13 471.69C1873.18 471.61 1873.23 471.47 1873.28 471.47C1873.42 471.47 1873.56 471.53 1873.71 471.57C1873.66 471.65 1873.61 471.73 1873.55 471.82Z" fill="white"/>
                    <path d="M1786.47 497.16C1786.38 497.1 1786.3 497.05 1786.22 497C1786.33 496.91 1786.44 496.8 1786.57 496.74C1786.62 496.72 1786.72 496.83 1786.8 496.88C1786.69 496.98 1786.58 497.07 1786.47 497.17V497.16Z" fill="white"/>
                    <path d="M1873.73 428.49C1873.87 428.53 1874.01 428.57 1874.15 428.62C1874.1 428.7 1874.05 428.84 1874 428.84C1873.86 428.84 1873.72 428.78 1873.57 428.74C1873.62 428.66 1873.67 428.58 1873.73 428.49Z" fill="white"/>
                    <path d="M1729.96 410.3C1729.82 410.26 1729.68 410.22 1729.54 410.17C1729.59 410.09 1729.64 409.95 1729.69 409.95C1729.83 409.95 1729.97 410.01 1730.12 410.05C1730.07 410.13 1730.02 410.21 1729.96 410.3Z" fill="white"/>
                    <path d="M1848.21 341.15C1848.27 341.06 1848.32 340.98 1848.37 340.9C1848.46 341.01 1848.57 341.12 1848.63 341.25C1848.65 341.3 1848.54 341.4 1848.49 341.48C1848.39 341.37 1848.3 341.26 1848.2 341.15H1848.21Z" fill="white"/>
                    <path d="M1767.41 468.44C1767.3 468.53 1767.19 468.64 1767.06 468.7C1767.01 468.72 1766.91 468.61 1766.83 468.56C1766.94 468.47 1767.05 468.36 1767.18 468.3C1767.23 468.28 1767.33 468.39 1767.41 468.44Z" fill="white"/>
                    <path d="M1853.47 352.99C1853.33 352.95 1853.19 352.91 1853.05 352.86C1853.1 352.78 1853.15 352.64 1853.2 352.64C1853.34 352.64 1853.48 352.7 1853.63 352.74L1853.47 352.99Z" fill="white"/>
                    <path d="M1737.99 343.09C1737.95 343.23 1737.91 343.37 1737.86 343.51C1737.78 343.46 1737.64 343.41 1737.64 343.36C1737.64 343.22 1737.7 343.08 1737.74 342.93C1737.82 342.98 1737.9 343.03 1737.99 343.09Z" fill="white"/>
                    <path d="M1652.18 235.45C1652.07 235.54 1651.96 235.65 1651.83 235.71C1651.78 235.73 1651.68 235.62 1651.6 235.57C1651.71 235.48 1651.82 235.37 1651.95 235.31C1652 235.29 1652.1 235.4 1652.18 235.45Z" fill="white"/>
                    <path d="M1840.92 486.23C1839.26 485.71 1839.08 484.36 1838.93 483.09C1838.88 482.63 1838.9 481.89 1838.79 481.67C1838.05 480.2 1840 480.16 1839.98 479.16C1840.96 479.3 1842.17 479.13 1842.84 479.66C1843.48 480.16 1844.22 480.86 1844.61 481.67C1844.98 482.44 1845.85 483.36 1844.32 483.98C1844.09 484.07 1844.07 484.61 1843.91 484.93C1843.22 486.34 1842.19 486.75 1840.92 486.23Z" fill="white"/>
                    <path d="M1965.08 420.46C1964.68 421.41 1963.65 421.77 1963.32 421.63C1962.04 421.08 1961.03 422.04 1959.86 422C1959.03 421.97 1958.53 421.82 1958.27 421.06C1957.83 419.81 1957.96 419.22 1958.78 418.72C1959.14 418.5 1959.45 418.26 1959.78 417.99C1961.18 416.84 1964.76 417.6 1965.08 419.65C1965.14 420.01 1965.19 420.32 1965.08 420.46Z" fill="white"/>
                    <path d="M1883.13 402.63C1882.29 402.95 1881.98 404.24 1880.78 403.86C1880.61 403.81 1880.45 403.66 1880.29 403.68C1879.63 403.73 1878.93 404.03 1878.39 403.38C1877.97 402.88 1878.12 402.24 1878.07 401.67C1877.98 400.53 1879.05 399.31 1880.29 399.07C1881.25 398.89 1881.97 399.21 1882.6 399.98C1883.26 400.79 1882.99 401.73 1883.13 402.63Z" fill="white"/>
                    <path d="M1871.19 278.45C1871.77 277.02 1872.56 276.58 1873.64 276.88C1874.23 277.04 1874.79 277.35 1875.37 277.55C1875.93 277.74 1876 278.04 1875.76 278.54C1875.5 279.1 1875.37 279.74 1875.04 280.25C1874.49 281.13 1873.59 281.49 1872.58 281.54C1872.37 281.54 1872.04 281.38 1871.96 281.2C1871.7 280.56 1871.24 280.06 1870.87 279.51C1870.69 279.24 1871.14 278.56 1871.17 278.45H1871.19Z" fill="white"/>
                    <path d="M1984.44 305.72C1984.24 306.39 1983.96 307.35 1982.85 307.22C1982.32 307.16 1981.33 307.04 1981.39 306.38C1981.45 305.66 1980.92 304.8 1981.65 304.18C1982.15 303.75 1982.84 303.47 1983.21 302.96C1983.55 302.49 1983.76 302.45 1984.25 302.63C1984.95 302.9 1985.16 303.45 1985.09 304.06C1985.03 304.57 1984.72 305.05 1984.46 305.72H1984.44Z" fill="white"/>
                    <path d="M1853.93 269.85C1853.68 270.51 1852.95 270.86 1852.29 270.64C1852.07 270.57 1850.5 268.6 1850.49 268.39C1850.47 267.92 1851.8 266.97 1851.82 267.05C1852.05 268.14 1853.01 267.17 1853.4 267.67C1853.77 268.14 1854.09 269.43 1853.93 269.85Z" fill="white"/>
                    <path d="M1830.11 426.16C1830.45 426.31 1831.01 426.43 1830.88 426.76C1830.65 427.37 1830.66 428.14 1829.86 428.49C1829.42 428.68 1829 428.45 1828.63 428.6C1828.04 428.85 1827.7 428.8 1827.39 428.17C1827.06 427.49 1827.21 426.86 1827.71 426.56C1828.41 426.14 1829.21 425.55 1830.11 426.16Z" fill="white"/>
                    <path d="M1916.89 394.28C1917.13 393.77 1917.13 393 1918.1 393.43C1918.32 393.53 1918.73 393.17 1919.06 393.05C1919.44 392.91 1919.82 392.81 1920.21 392.69C1920.04 393.26 1920.05 394.01 1919.67 394.36C1919.19 394.82 1918.41 394.94 1917.77 395.25C1917.42 395.42 1917.07 395.51 1916.8 395.24C1916.51 394.94 1916.77 394.59 1916.89 394.28Z" fill="white"/>
                    <path d="M1781.72 349.94C1781.78 349.78 1781.83 349.61 1781.92 349.46C1782.11 349.12 1782.05 348.51 1782.66 348.6C1783.29 348.7 1783.72 349.07 1783.91 349.73C1784.07 350.29 1783.78 350.47 1783.36 350.69C1783.09 350.83 1782.95 351.2 1782.68 351.35C1782.33 351.55 1781.93 351.87 1781.49 351.55C1780.97 351.18 1781.44 350.79 1781.53 350.42C1781.57 350.25 1781.65 350.1 1781.72 349.94Z" fill="white"/>
                    <path d="M1862.21 264.83C1861.7 264.2 1860.97 263.69 1860.79 262.83C1860.75 262.63 1860.86 262.32 1861.18 262.22C1861.58 262.1 1862 261.67 1862.38 262.05C1862.84 262.51 1863.21 263.07 1863.56 263.63C1863.97 264.29 1863.07 264.08 1862.97 264.44C1862.87 264.82 1862.65 265.05 1862.2 264.83H1862.21Z" fill="white"/>
                    <path d="M1903.31 435.49C1903.41 435.01 1903.67 434.8 1904.21 435.05C1905.04 435.43 1905.34 436.08 1905.07 436.85C1904.83 437.54 1904.26 437.74 1903.68 437.7C1903.01 437.65 1902.84 436.58 1903.3 435.5L1903.31 435.49Z" fill="white"/>
                    <path d="M1948.21 501.52C1948.31 501.27 1948.37 501 1948.51 500.79C1948.85 500.29 1949.59 500.38 1949.83 500.93C1949.97 501.25 1950.18 501.57 1950.23 501.91C1950.3 502.35 1950.13 502.85 1949.78 503.04C1949.38 503.26 1948.8 502.91 1948.35 503.24C1948.22 502.76 1947.72 502.34 1948.1 501.77C1948.15 501.7 1948.17 501.61 1948.2 501.53L1948.21 501.52Z" fill="white"/>
                    <path d="M1850.58 312.78C1850.29 312.9 1850.04 312.99 1849.81 313.11C1849.22 313.41 1848.73 312.56 1848.1 313C1847.77 313.24 1847.4 313.08 1847.28 312.7C1847.17 312.33 1847.38 312.07 1847.75 311.93C1848.24 311.74 1848.71 311.49 1849.2 311.33C1849.44 311.25 1849.75 310.79 1849.97 311.38C1850.15 311.85 1850.37 312.32 1850.57 312.78H1850.58Z" fill="white"/>
                    <path d="M1943.63 254.24C1944.12 254.52 1944.28 255.53 1943.93 256.07C1943.79 256.28 1942.18 256.61 1941.93 256.42C1941.3 255.94 1941.89 255.48 1942.05 255.07C1942.36 254.32 1943.16 253.96 1943.63 254.23V254.24Z" fill="white"/>
                    <path d="M1957.33 326.94C1956.8 326.81 1956.53 326.58 1956.79 326.03C1957.1 325.35 1957.85 324.86 1958.34 325.06C1958.83 325.26 1959.05 326.15 1958.77 326.84C1958.35 327.86 1957.79 326.96 1957.33 326.95V326.94Z" fill="white"/>
                    <path d="M1961.26 332.11C1960.87 332.4 1960.46 332.74 1959.93 332.57C1959.35 332.38 1959.17 331.18 1959.58 330.58C1959.85 330.18 1960.16 330.34 1960.46 330.5C1961.16 330.86 1961.54 331.62 1961.27 332.11H1961.26Z" fill="white"/>
                    <path d="M1882.68 310.13C1882.81 309.81 1882.89 309.65 1882.93 309.48C1883.09 308.85 1883.72 308.89 1884.13 308.64C1884.25 309.11 1884.73 309.51 1884.42 310.08C1884.34 310.23 1884.28 310.4 1884.23 310.56C1884.06 311.12 1883.85 311.44 1883.13 311.14C1882.44 310.85 1882.4 310.52 1882.67 310.13H1882.68Z" fill="white"/>
                    <path d="M2018.03 381.65C2017.44 381.46 2017.47 381.03 2017.71 380.8C2018.18 380.34 2018.75 379.97 2019.3 379.6C2019.82 379.26 2019.78 379.82 2019.92 380.05C2020.54 381.05 2019.29 381.05 2019.05 381.61C2018.77 382.26 2018.31 381.63 2018.02 381.65H2018.03Z" fill="white"/>
                    <path d="M1914.75 311.86C1914.61 312.59 1914.03 312.8 1913.44 312.78C1913 312.76 1912.46 311.4 1912.74 311.17C1913.29 310.72 1913.89 310.68 1914.54 311.01C1915 311.24 1914.87 311.53 1914.74 311.86H1914.75Z" fill="white"/>
                    <path d="M1785.79 410.18C1785.34 409.9 1785.23 409.41 1785.19 408.92C1785.17 408.62 1786.58 408.25 1787.11 408.41C1787.59 408.55 1787.5 408.88 1787.36 409.22C1787.07 409.89 1786.3 410.38 1785.79 410.19V410.18Z" fill="white"/>
                    <path d="M1893.22 281.24C1893.43 281.41 1893.6 281.68 1893.36 281.88C1892.79 282.36 1892.34 282.99 1891.6 283.27C1891.29 283.39 1891.01 283.3 1890.88 283.14C1890.76 282.99 1890.76 282.55 1890.88 282.44C1891.37 282 1891.93 281.62 1892.47 281.24C1892.7 281.08 1892.96 281.09 1893.22 281.24Z" fill="white"/>
                    <path d="M1891.95 252.36C1891.87 252.63 1891.77 252.97 1891.31 252.89C1890.91 252.82 1890.86 252.44 1890.68 252.17C1890.31 251.61 1890.68 251.15 1890.91 250.75C1891.05 250.51 1891.65 250.26 1891.79 250.36C1892.07 250.57 1892.29 251 1892.32 251.36C1892.35 251.66 1892.1 252 1891.95 252.38V252.36Z" fill="white"/>
                    <path d="M2012.61 334.08C2013.04 334.18 2013.25 334.44 2013 334.97C2012.69 335.64 2011.95 336.11 2011.46 335.93C2010.63 335.62 2011.6 334.81 2011.05 334.37C2010.96 334.3 2011.97 333.88 2012.62 334.09L2012.61 334.08Z" fill="white"/>
                    <path d="M1848.27 347.36C1848.13 347.44 1847.98 347.61 1847.86 347.59C1847.17 347.49 1846.41 346.16 1846.7 345.56C1846.8 345.36 1847.01 345.08 1847.19 345.07C1847.38 345.06 1847.65 345.27 1847.77 345.46C1848.28 346.3 1848.43 346.94 1848.27 347.36Z" fill="white"/>
                    <path d="M1849.86 446.69C1850.1 446.79 1850.37 446.85 1850.58 446.99C1851.13 447.36 1850.66 447.75 1850.56 448.12C1850.43 448.6 1850.09 448.52 1849.75 448.38C1849.44 448.25 1849.11 448.19 1848.97 447.79C1848.83 447.39 1848.53 446.98 1848.9 446.6C1849.19 446.31 1849.54 446.59 1849.86 446.7V446.69Z" fill="white"/>
                    <path d="M1893.6 284.19C1893.9 283.73 1894.22 283.91 1894.52 284.06C1895.23 284.43 1895.6 285.19 1895.31 285.67C1895.07 286.07 1894.73 285.9 1894.43 285.74C1893.72 285.37 1893.37 284.62 1893.6 284.19Z" fill="white"/>
                    <path d="M2015.76 327.28C2015.98 327.85 2016.33 328.34 2015.98 329C2015.69 329.54 2015.4 329.45 2014.96 329.29C2014.48 329.11 2014.31 328.93 2014.46 328.36C2014.65 327.62 2015.28 327.58 2015.77 327.28H2015.76Z" fill="white"/>
                    <path d="M2014.19 340.6C2014.06 340.69 2013.9 340.89 2013.8 340.86C2013.47 340.78 2013.03 340.71 2012.86 340.47C2012.59 340.08 2012.21 339.63 2012.51 339.08C2012.74 338.66 2013.08 338.83 2013.38 338.99C2014.09 339.37 2014.44 340.07 2014.19 340.6Z" fill="white"/>
                    <path d="M1813.85 443.64C1813.7 443.97 1813.54 444.33 1813.12 444.07C1812.57 443.74 1812.26 443.18 1812.58 442.6C1812.75 442.3 1813.28 442.19 1813.64 441.99C1813.84 442.53 1814.28 443.03 1813.85 443.64Z" fill="white"/>
                    <path d="M1953.79 457.74C1953.22 457.47 1952.87 456.54 1953.11 456.16C1953.35 455.76 1953.67 455.97 1953.99 456.08C1954.63 456.29 1954.9 456.79 1954.78 457.36C1954.74 457.53 1954.12 457.87 1953.8 457.74H1953.79Z" fill="white"/>
                    <path d="M1934.04 311.04C1933.87 311.45 1933.68 311.88 1933.31 311.58C1932.85 311.21 1932.53 310.68 1932.15 310.21C1932.69 309.93 1933.2 309.72 1933.84 310.07C1934.44 310.4 1934.12 310.75 1934.05 311.04H1934.04Z" fill="white"/>
                    <path d="M2009.81 420.62C2009.61 421.32 2009.27 421.24 2008.79 421.09C2008.04 420.86 2008.16 420.46 2008.38 419.91C2008.6 419.34 2008.9 419.4 2009.39 419.56C2010.1 419.8 2010.14 420.18 2009.82 420.62H2009.81Z" fill="white"/>
                    <path d="M1927.44 301.8C1927.04 301.55 1926.21 301.6 1926.66 300.82C1926.91 300.39 1927.43 300.14 1927.97 300.29C1928.86 300.54 1928.25 301.36 1928.33 301.81C1928.43 302.38 1927.76 301.73 1927.44 301.8Z" fill="white"/>
                    <path d="M1883.19 236.89C1883.09 237.13 1883.03 237.39 1882.89 237.61C1882.64 238 1882.31 237.82 1882 237.67C1881.78 237.56 1881.46 237.58 1881.42 237.25C1881.37 236.87 1881.54 236.61 1881.91 236.49C1882.07 236.44 1882.23 236.35 1882.39 236.29C1882.7 236.17 1883.14 235.87 1883.29 235.98C1883.61 236.22 1883.28 236.59 1883.19 236.9V236.89Z" fill="white"/>
                    <path d="M1910.49 484.12C1910.98 484.52 1910.57 484.9 1910.44 485.26C1910.2 485.93 1909.76 485.52 1909.39 485.4C1909.05 485.29 1908.73 485.12 1908.96 484.67C1909.2 484.21 1910.09 483.9 1910.49 484.12Z" fill="white"/>
                    <path d="M1967.59 355.78C1967.17 355.59 1966.83 355.45 1967.07 354.99C1967.31 354.53 1968.21 354.2 1968.59 354.45C1968.98 354.7 1968.74 355.02 1968.66 355.35C1968.47 356.14 1968.01 355.99 1967.59 355.78Z" fill="white"/>
                    <path d="M1910.43 270.26C1910.57 270.37 1910.92 270.51 1910.93 270.67C1910.96 271.1 1910.47 271.09 1910.23 271.13C1909.87 271.18 1909.42 272.05 1909.08 271.1C1908.97 270.78 1908.75 270.47 1909 270.15C1909.5 269.5 1909.9 270.27 1910.43 270.26Z" fill="white"/>
                    <path d="M1800.31 352.3C1799.96 352.53 1799.47 353.11 1799.19 352.65C1798.95 352.26 1798.21 352.23 1798.39 351.62C1798.5 351.26 1799.9 351.25 1800.27 351.6C1800.46 351.78 1800.48 352.04 1800.32 352.3H1800.31Z" fill="white"/>
                    <path d="M1993.84 416.25C1993.84 416.25 1993.93 416.72 1993.95 417.14C1993.95 417.33 1992.77 417.59 1992.56 417.45C1992.02 417.08 1992.48 416.7 1992.57 416.34C1992.69 415.89 1993 415.9 1993.83 416.25H1993.84Z" fill="white"/>
                    <path d="M1978.27 443.52C1977.88 444.02 1977.51 443.58 1977.14 443.48C1976.68 443.35 1976.65 442.95 1976.89 442.7C1977.17 442.42 1977.59 442.25 1977.98 442.11C1978.12 442.06 1978.39 443.32 1978.27 443.52Z" fill="white"/>
                    <path d="M1821.47 485.32C1821.01 485.23 1820.92 484.97 1821.01 484.73C1821.27 484.1 1821.8 483.92 1822.44 484.02C1822.77 484.07 1822.86 484.34 1822.77 484.58C1822.53 485.24 1821.96 485.35 1821.47 485.32Z" fill="white"/>
                    <path d="M1836.37 330.94C1836.86 331.22 1837.12 331.71 1836.95 332.13C1836.87 332.34 1836.3 332.66 1835.82 332.45C1835.18 332.17 1835.57 331.76 1835.68 331.4C1835.79 331.06 1835.96 330.77 1836.38 330.93L1836.37 330.94Z" fill="white"/>
                    <path d="M1907.61 501.36C1907.37 501.3 1906.68 500.25 1906.82 500.18C1907.19 500 1907.6 499.81 1908 499.81C1908.37 499.81 1908.59 500.13 1908.37 500.53C1908.19 500.86 1908.26 501.45 1907.61 501.35V501.36Z" fill="white"/>
                    <path d="M1836.84 325.53C1836.53 325.91 1836.06 326.24 1835.41 325.96C1835.17 325.86 1835.03 325.61 1835.23 325.34C1835.6 324.81 1836.1 324.55 1836.73 324.79C1836.97 324.88 1837.1 325.13 1836.84 325.53Z" fill="white"/>
                    <path d="M1787.65 291.93C1787.5 292.39 1787.11 292.43 1786.86 292.19C1786.58 291.91 1786.41 291.49 1786.27 291.1C1786.22 290.96 1787.48 290.69 1787.68 290.81C1787.96 290.99 1787.95 291.43 1787.65 291.92V291.93Z" fill="white"/>
                    <path d="M1927.36 306.18C1927.21 306.52 1927.08 306.86 1926.63 306.64C1926.14 306.4 1925.9 305.89 1926.07 305.47C1926.15 305.26 1926.72 304.94 1927.2 305.15C1927.85 305.43 1927.43 305.83 1927.36 306.18Z" fill="white"/>
                    <path d="M1860.34 255.08C1859.96 255.4 1859.58 255.66 1859.05 255.52C1858.74 255.44 1858.63 255.17 1858.75 254.95C1858.96 254.58 1858.83 253.8 1859.66 254.04C1860.17 254.19 1860.07 254.74 1860.34 255.08Z" fill="white"/>
                    <path d="M1916.15 311.23C1916.01 311.31 1915.88 311.4 1915.74 311.48C1915.42 310.78 1915.22 310.02 1914.52 309.55C1914.49 309.53 1914.61 309.21 1914.72 309.09C1914.78 309.02 1915.09 309.04 1915.1 309.08C1915.31 309.76 1916.19 309.98 1916.24 310.74C1916.25 310.9 1916.18 311.07 1916.15 311.23Z" fill="white"/>
                    <path d="M1912.05 310.58C1911.57 310.36 1912.09 309.83 1912.28 309.47C1912.65 308.75 1913.14 309.4 1913.56 309.5C1913.69 309.53 1914.02 309.97 1913.56 309.94C1912.88 309.89 1913.19 311.19 1912.04 310.58H1912.05Z" fill="white"/>
                    <path d="M1859.45 467.18C1860.08 467.32 1860.02 467.95 1860.28 468.35C1859.98 468.49 1859.6 468.82 1859.4 468.73C1858.96 468.54 1858.8 468.06 1858.9 467.55C1858.96 467.24 1859.19 467.18 1859.45 467.18Z" fill="white"/>
                    <path d="M1991.24 410.62C1990.92 410.43 1990.34 410.44 1990.59 409.77C1990.77 409.29 1991.09 409.39 1991.42 409.54C1991.77 409.7 1992.33 409.71 1992.09 410.38C1991.91 410.87 1991.6 410.74 1991.25 410.62H1991.24Z" fill="white"/>
                    <path d="M1794.09 439.66C1793.37 440.16 1792.81 440.11 1792.26 439.77C1792.13 439.69 1792.07 439.51 1791.98 439.38C1792.14 439.31 1792.32 439.27 1792.45 439.17C1792.97 438.79 1793.43 439.07 1793.88 439.29C1794.02 439.36 1794.07 439.61 1794.09 439.65V439.66Z" fill="white"/>
                    <path d="M2014.48 424.88C2014.44 425 2014.11 424.94 2013.78 424.78C2013.44 424.61 2012.86 424.62 2013.1 423.95C2013.27 423.48 2013.59 423.55 2013.92 423.7C2014.27 423.87 2014.84 423.88 2014.48 424.88Z" fill="white"/>
                    <path d="M1978.03 381.69C1977.96 381.59 1977.79 381.46 1977.81 381.38C1977.98 380.81 1978.16 380.25 1978.87 380.09C1979.16 380.02 1979.34 380.02 1979.35 380.32C1979.37 380.79 1978.44 381.73 1978.02 381.69H1978.03Z" fill="white"/>
                    <path d="M1900.76 450.48C1900.26 451.16 1899.84 450.43 1899.4 450.34C1899.2 450.3 1899.21 449.88 1899.38 449.66C1899.87 449.03 1900.31 449.66 1900.74 449.79C1900.94 449.85 1900.93 450.23 1900.76 450.48Z" fill="white"/>
                    <path d="M1925.88 350.16C1925.38 350.84 1924.96 350.11 1924.52 350.02C1924.32 349.98 1924.33 349.56 1924.5 349.34C1924.99 348.71 1925.43 349.34 1925.86 349.47C1926.06 349.53 1926.05 349.91 1925.88 350.16Z" fill="white"/>
                    <path d="M1808.98 373.85C1808.94 373.97 1808.61 373.91 1808.28 373.75C1807.94 373.58 1807.36 373.59 1807.6 372.92C1807.77 372.45 1808.09 372.52 1808.42 372.67C1808.77 372.84 1809.34 372.85 1808.98 373.85Z" fill="white"/>
                    <path d="M1961.4 289.58C1961.33 289.03 1961.26 288.46 1961.21 287.89C1961.21 287.86 1961.36 287.78 1961.44 287.8C1961.8 287.86 1962.01 288.09 1962.14 288.44C1962.29 288.84 1962.5 289.23 1962.06 289.56C1961.96 289.64 1961.75 289.58 1961.4 289.58Z" fill="white"/>
                    <path d="M1912.37 288.31C1912.71 288.42 1913.08 288.51 1912.96 288.98C1912.8 289.59 1912.41 289.5 1911.97 289.29C1911.66 289.15 1911.27 289.08 1911.4 288.61C1911.56 288.01 1911.95 288.06 1912.38 288.3L1912.37 288.31Z" fill="white"/>
                    <path d="M1859.49 299.29C1859.18 299.67 1858.99 299.83 1858.71 299.64C1858.18 299.27 1857.93 298.78 1858.16 298.14C1858.21 298.02 1858.41 297.95 1858.55 297.86C1858.87 298.36 1859.2 298.85 1859.49 299.29Z" fill="white"/>
                    <path d="M1953.87 347.65C1953.76 348.11 1953.53 348.41 1953.33 348.32C1952.93 348.12 1952.18 348.27 1952.26 347.48C1952.29 347.21 1952.53 346.92 1952.75 347C1953.17 347.16 1953.81 347.14 1953.87 347.65Z" fill="white"/>
                    <path d="M1809.95 391.51C1809.8 391.84 1809.67 392.16 1809.2 391.95C1808.55 391.67 1808.93 391.26 1809.05 390.89C1809.16 390.55 1809.32 390.25 1809.79 390.45C1810.45 390.73 1810.04 391.14 1809.95 391.51Z" fill="white"/>
                    <path d="M1884.86 421.59C1885.54 422.09 1884.81 422.51 1884.72 422.95C1884.68 423.15 1884.26 423.14 1884.04 422.97C1883.41 422.48 1884.04 422.04 1884.17 421.61C1884.23 421.41 1884.61 421.42 1884.86 421.59Z" fill="white"/>
                    <path d="M1861.23 289.73C1861.57 289.84 1861.94 289.93 1861.82 290.4C1861.66 291.01 1861.27 290.92 1860.83 290.71C1860.52 290.57 1860.13 290.5 1860.26 290.03C1860.42 289.43 1860.81 289.48 1861.24 289.72L1861.23 289.73Z" fill="white"/>
                    <path d="M2003.28 332.69C2003.87 332.86 2003.94 333.09 2003.86 333.36C2003.69 333.96 2003.29 333.86 2002.87 333.65C2002.65 333.54 2002.18 333.41 2002.32 333.25C2002.61 332.93 2003.09 332.79 2003.28 332.69Z" fill="white"/>
                    <path d="M1810.34 425.11C1810.23 425.21 1810.06 425.37 1809.88 425.53C1809.71 425.08 1809.26 424.68 1809.56 424.14C1809.69 423.91 1809.89 423.81 1810.2 423.95C1810.84 424.23 1810.47 424.63 1810.34 425.12V425.11Z" fill="white"/>
                    <path d="M1854.33 236.98C1854.1 236.94 1854 236.69 1854.07 236.44C1854.16 236.1 1854.35 235.76 1854.78 236.01C1855.09 236.19 1855.77 236.09 1855.55 236.67C1855.47 236.88 1854.88 237.19 1854.32 236.98H1854.33Z" fill="white"/>
                    <path d="M2008.91 293.39C2008.75 293.99 2008.13 293.95 2007.71 294.19C2007.71 293.95 2007.65 293.69 2007.73 293.48C2007.87 293.13 2007.88 292.57 2008.55 292.8C2008.85 292.91 2008.92 293.1 2008.9 293.39H2008.91Z" fill="white"/>
                    <path d="M1846.05 265.83C1845.69 265.77 1846.05 265.93 1845.96 265.59C1845.92 265.41 1846.06 265.05 1846.22 264.98C1846.65 264.78 1847.09 264.36 1847.62 264.76C1847.68 264.8 1847.75 264.95 1847.72 264.98C1847.3 265.53 1846.58 265.51 1846.05 265.84V265.83Z" fill="white"/>
                    <path d="M1860.58 313.41C1860.11 313.57 1859.66 314.01 1859.17 313.58C1859.05 313.48 1859.07 313.06 1859.17 312.89C1859.51 312.35 1859.91 312.82 1860.28 312.91C1860.53 312.97 1860.7 313.11 1860.57 313.4L1860.58 313.41Z" fill="white"/>
                    <path d="M1907.43 384.88C1907.37 385.04 1907.33 385.23 1907.22 385.36C1907.15 385.44 1906.9 385.49 1906.84 385.43C1906.63 385.23 1907.22 383.7 1907.53 383.64C1907.6 383.63 1907.81 383.84 1907.79 383.91C1907.7 384.24 1907.55 384.56 1907.42 384.89L1907.43 384.88Z" fill="white"/>
                    <path d="M1774.59 327.48C1774.42 328 1774.09 327.96 1773.76 327.78C1773.52 327.65 1773.43 327.45 1773.56 327.14C1773.75 326.68 1774.06 326.72 1774.38 326.9C1774.61 327.02 1774.78 327.24 1774.59 327.48Z" fill="white"/>
                    <path d="M2010.02 310.76C2009.84 310.99 2009.67 311.09 2009.37 310.97C2008.91 310.78 2008.95 310.48 2009.12 310.15C2009.25 309.91 2009.45 309.83 2009.76 309.95C2010.22 310.14 2010.15 310.43 2010.02 310.76Z" fill="white"/>
                    <path d="M1911.9 480.2C1911.72 480.43 1911.55 480.53 1911.25 480.41C1910.79 480.22 1910.83 479.92 1911 479.59C1911.13 479.35 1911.33 479.27 1911.64 479.39C1912.1 479.58 1912.03 479.87 1911.9 480.2Z" fill="white"/>
                    <path d="M1814.37 452.64C1814.06 452.51 1813.8 452.36 1813.98 451.9C1814.1 451.59 1814.3 451.5 1814.56 451.58C1814.91 451.68 1815.16 451.86 1814.98 452.32C1814.86 452.62 1814.67 452.68 1814.36 452.64H1814.37Z" fill="white"/>
                    <path d="M2012.14 405.24C2012.48 405.59 2012.71 405.99 2012.58 406.49C2012.55 406.62 2012.29 406.83 2012.28 406.82C2011.89 406.51 2011.67 406.11 2011.81 405.6C2011.85 405.47 2012.01 405.37 2012.14 405.24Z" fill="white"/>
                    <path d="M1946.42 474.36C1946.36 474.67 1946.11 474.74 1945.85 474.67C1945.5 474.57 1945.25 474.39 1945.43 473.93C1945.55 473.62 1945.75 473.53 1946.01 473.61C1946.37 473.71 1946.63 473.91 1946.42 474.37V474.36Z" fill="white"/>
                    <path d="M1963.47 369.76C1963.29 369.99 1963.12 370.09 1962.82 369.97C1962.36 369.78 1962.4 369.48 1962.57 369.15C1962.7 368.91 1962.9 368.83 1963.21 368.95C1963.67 369.14 1963.6 369.43 1963.47 369.76Z" fill="white"/>
                    <path d="M1806.05 277.53C1805.84 277.79 1805.67 277.9 1805.37 277.77C1804.91 277.58 1804.95 277.28 1805.12 276.95C1805.25 276.71 1805.45 276.63 1805.76 276.75C1806.23 276.93 1806.12 277.24 1806.05 277.52V277.53Z" fill="white"/>
                    <path d="M1827.5 281.43C1827.02 281.27 1827.06 280.94 1827.23 280.61C1827.36 280.37 1827.56 280.28 1827.87 280.41C1828.33 280.6 1828.29 280.9 1828.12 281.23C1827.99 281.47 1827.77 281.6 1827.5 281.43Z" fill="white"/>
                    <path d="M2023.76 440.41C2023.9 440.09 2024.04 439.77 2024.49 439.99C2024.63 440.06 2024.73 440.19 2024.85 440.3C2024.52 440.49 2024.2 440.69 2023.87 440.88C2023.84 440.72 2023.8 440.57 2023.77 440.4L2023.76 440.41Z" fill="white"/>
                    <path d="M1975.69 439.9C1975.55 440.22 1975.41 440.54 1974.96 440.32C1974.82 440.25 1974.72 440.12 1974.6 440.01C1974.93 439.82 1975.25 439.62 1975.58 439.43C1975.61 439.59 1975.65 439.74 1975.68 439.91L1975.69 439.9Z" fill="white"/>
                    <path d="M1991.04 317.76C1990.71 317.91 1990.4 318.05 1990.09 318.19C1990.09 317.95 1990.01 317.68 1990.11 317.49C1990.31 317.07 1990.67 317.24 1990.96 317.41C1991.04 317.46 1991.02 317.66 1991.04 317.76Z" fill="white"/>
                    <path d="M1877.85 418.8C1877.7 418.66 1877.46 418.51 1877.48 418.45C1877.64 418.01 1878.13 418.09 1878.45 417.87C1878.48 418.03 1878.57 418.2 1878.53 418.34C1878.44 418.69 1878.24 418.94 1877.85 418.79V418.8Z" fill="white"/>
                    <path d="M1825.97 485.07C1825.86 485.14 1825.73 485.3 1825.64 485.28C1825.31 485.21 1824.94 485.08 1825.09 484.63C1825.16 484.43 1825.4 484.28 1825.57 484.11C1825.7 484.42 1825.84 484.74 1825.98 485.06L1825.97 485.07Z" fill="white"/>
                    <path d="M1848.72 471.52C1849.18 471.68 1849.1 472.01 1848.93 472.32C1848.86 472.45 1848.68 472.51 1848.54 472.6C1848.44 472.23 1848.33 471.87 1848.23 471.5C1848.39 471.5 1848.55 471.5 1848.71 471.52H1848.72Z" fill="white"/>
                    <path d="M1975.29 332.67C1975.23 332.71 1975.11 332.88 1975.02 332.86C1974.69 332.79 1974.32 332.67 1974.45 332.22C1974.51 332.02 1974.76 331.87 1974.92 331.69C1975.04 332.01 1975.16 332.32 1975.29 332.66V332.67Z" fill="white"/>
                    <path d="M1961.12 364.13C1960.96 364.59 1960.63 364.51 1960.32 364.34C1960.19 364.27 1960.13 364.09 1960.04 363.95C1960.41 363.85 1960.77 363.74 1961.14 363.64C1961.14 363.8 1961.14 363.96 1961.12 364.12V364.13Z" fill="white"/>
                    <path d="M1884.75 496.36C1884.62 496.46 1884.49 496.56 1884.36 496.65C1884.24 496.29 1884.12 495.93 1884 495.57C1884.16 495.57 1884.33 495.53 1884.47 495.57C1884.93 495.69 1884.88 496.02 1884.75 496.35V496.36Z" fill="white"/>
                    <path d="M1827.01 298.47C1827.47 298.63 1827.39 298.96 1827.22 299.27C1827.15 299.4 1826.97 299.46 1826.83 299.55C1826.73 299.18 1826.62 298.82 1826.52 298.45C1826.68 298.45 1826.84 298.45 1827 298.47H1827.01Z" fill="white"/>
                    <path d="M1792.24 286.85C1792.28 287.07 1792.36 287.24 1792.32 287.38C1792.22 287.74 1792.01 287.99 1791.59 287.77C1791.46 287.7 1791.36 287.56 1791.25 287.45C1791.57 287.26 1791.9 287.06 1792.24 286.86V286.85Z" fill="white"/>
                    <path d="M1988.13 263.17C1987.85 263.39 1987.62 263.17 1987.41 263.08C1987.34 263.05 1987.3 262.76 1987.36 262.71C1987.6 262.48 1987.84 262.71 1988.06 262.8C1988.13 262.83 1988.12 263.05 1988.14 263.17H1988.13Z" fill="white"/>
                    <path d="M1992.03 286.13C1992 286.42 1992.03 286.67 1991.94 286.89C1991.89 287.01 1991.69 287.08 1991.56 287.17C1991.56 286.93 1991.51 286.68 1991.58 286.46C1991.61 286.34 1991.83 286.27 1992.03 286.13Z" fill="white"/>
                    <path d="M1776.88 380.55C1776.6 380.77 1776.37 380.55 1776.16 380.46C1776.09 380.43 1776.05 380.14 1776.11 380.09C1776.35 379.86 1776.59 380.09 1776.81 380.18C1776.88 380.21 1776.87 380.43 1776.89 380.55H1776.88Z" fill="white"/>
                    <path d="M1823.67 468.18C1823.38 468.15 1823.13 468.18 1822.91 468.09C1822.79 468.04 1822.72 467.84 1822.63 467.71C1822.87 467.71 1823.12 467.66 1823.34 467.73C1823.46 467.76 1823.53 467.98 1823.67 468.18Z" fill="white"/>
                    <path d="M1826.44 468.91C1826.31 468.85 1826.08 468.78 1825.9 468.63C1825.69 468.46 1825.76 468.28 1826.03 468.31C1826.25 468.34 1826.5 468.43 1826.68 468.57C1826.89 468.73 1826.82 468.88 1826.44 468.91Z" fill="white"/>
                    <path d="M1765.1 391.58C1765.21 391.69 1765.44 391.82 1765.42 391.91C1765.35 392.22 1765.11 392.2 1764.89 392.07C1764.76 391.99 1764.55 391.83 1764.57 391.75C1764.64 391.44 1764.87 391.47 1765.11 391.59L1765.1 391.58Z" fill="white"/>
                    <path d="M2004.81 313.74C2004.7 313.85 2004.57 314.08 2004.48 314.06C2004.17 313.99 2004.19 313.75 2004.32 313.53C2004.4 313.4 2004.56 313.19 2004.64 313.21C2004.95 313.28 2004.92 313.51 2004.8 313.75L2004.81 313.74Z" fill="white"/>
                    <path d="M1891.74 489.77C1891.71 490.06 1891.74 490.31 1891.65 490.53C1891.6 490.65 1891.4 490.72 1891.27 490.81C1891.27 490.57 1891.22 490.32 1891.29 490.1C1891.32 489.98 1891.54 489.91 1891.74 489.77Z" fill="white"/>
                    <path d="M1873.46 256.86C1873.36 256.93 1873.23 257.02 1873.1 257.11C1873.1 256.87 1873.05 256.62 1873.12 256.4C1873.15 256.28 1873.37 256.21 1873.5 256.12C1873.63 256.36 1873.65 256.6 1873.47 256.86H1873.46Z" fill="white"/>
                    <path d="M1930.39 502.99C1930.14 502.91 1929.95 502.77 1930.11 502.5C1930.15 502.43 1930.42 502.46 1930.57 502.49C1930.82 502.54 1931.02 502.7 1930.85 502.97C1930.81 503.04 1930.55 502.99 1930.39 502.99Z" fill="white"/>
                    <path d="M1859.1 290.03C1858.85 289.95 1858.66 289.81 1858.82 289.54C1858.86 289.47 1859.13 289.5 1859.28 289.53C1859.53 289.58 1859.73 289.74 1859.56 290.01C1859.52 290.08 1859.26 290.03 1859.1 290.03Z" fill="white"/>
                    <path d="M1863.57 300.43C1863.32 300.35 1863.13 300.21 1863.29 299.94C1863.33 299.87 1863.6 299.9 1863.75 299.93C1864 299.98 1864.2 300.14 1864.03 300.41C1863.99 300.48 1863.73 300.43 1863.57 300.43Z" fill="white"/>
                    <path d="M1885.31 336.05C1885.28 336.34 1885.31 336.59 1885.22 336.81C1885.17 336.93 1884.97 337 1884.84 337.09C1884.84 336.85 1884.79 336.6 1884.86 336.38C1884.89 336.26 1885.11 336.19 1885.31 336.05Z" fill="white"/>
                    <path d="M1981.99 326.38C1981.84 326.51 1981.66 326.67 1981.48 326.82C1981.45 326.66 1981.34 326.46 1981.4 326.35C1981.51 326.15 1981.71 326 1981.88 325.82C1981.91 325.98 1981.95 326.14 1981.99 326.37V326.38Z" fill="white"/>
                    <path d="M1930.73 394.1C1930.63 394.17 1930.5 394.26 1930.37 394.35C1930.37 394.11 1930.32 393.86 1930.39 393.64C1930.42 393.52 1930.64 393.45 1930.77 393.36C1930.9 393.6 1930.92 393.84 1930.74 394.1H1930.73Z" fill="white"/>
                    <path d="M1966.24 415.49C1966.21 415.78 1966.24 416.03 1966.15 416.25C1966.1 416.37 1965.9 416.44 1965.77 416.53C1965.77 416.29 1965.72 416.04 1965.79 415.82C1965.82 415.7 1966.04 415.63 1966.24 415.49Z" fill="white"/>
                    <path d="M2022.14 448.6C2021.86 448.82 2021.63 448.6 2021.42 448.51C2021.35 448.48 2021.31 448.19 2021.37 448.14C2021.61 447.91 2021.85 448.14 2022.07 448.23C2022.14 448.26 2022.13 448.48 2022.15 448.6H2022.14Z" fill="white"/>
                    <path d="M1964.06 453.21C1963.81 453.13 1963.62 452.99 1963.78 452.72C1963.82 452.65 1964.09 452.68 1964.24 452.71C1964.49 452.76 1964.69 452.92 1964.52 453.19C1964.48 453.26 1964.22 453.21 1964.06 453.21Z" fill="white"/>
                    <path d="M1793.04 376.3C1793.15 376.41 1793.38 376.54 1793.36 376.63C1793.29 376.94 1793.05 376.92 1792.83 376.79C1792.7 376.71 1792.49 376.55 1792.51 376.47C1792.58 376.16 1792.81 376.19 1793.05 376.31L1793.04 376.3Z" fill="white"/>
                    <path d="M1877.98 395.28C1877.87 395.39 1877.74 395.62 1877.65 395.6C1877.34 395.53 1877.36 395.29 1877.49 395.07C1877.57 394.94 1877.73 394.73 1877.81 394.75C1878.12 394.82 1878.09 395.05 1877.97 395.29L1877.98 395.28Z" fill="white"/>
                    <path d="M1873.59 408.46C1873.6 408.59 1873.63 408.73 1873.61 408.85C1873.61 408.89 1873.47 408.92 1873.39 408.95C1873.38 408.82 1873.35 408.68 1873.37 408.56C1873.37 408.52 1873.51 408.49 1873.59 408.46Z" fill="white"/>
                    <path d="M1884.15 505.79C1884.16 505.92 1884.19 506.06 1884.17 506.18C1884.17 506.22 1884.03 506.25 1883.95 506.28C1883.94 506.15 1883.91 506.01 1883.93 505.89C1883.93 505.85 1884.07 505.82 1884.15 505.79Z" fill="white"/>
                    <path d="M1872.34 307.01C1872.24 307.09 1872.14 307.17 1872.04 307.26C1872.01 307.18 1871.92 307.07 1871.95 307.04C1872.02 306.93 1872.14 306.86 1872.24 306.77C1872.27 306.85 1872.31 306.92 1872.35 307.02L1872.34 307.01Z" fill="white"/>
                    <path d="M1839.86 280.82C1839.95 280.78 1840.03 280.75 1840.11 280.71C1840.12 280.84 1840.15 280.98 1840.13 281.1C1840.13 281.14 1839.99 281.17 1839.91 281.2C1839.89 281.07 1839.88 280.94 1839.86 280.81V280.82Z" fill="white"/>
                    <path d="M1912.48 308.39C1912.4 308.29 1912.32 308.19 1912.23 308.09C1912.31 308.06 1912.42 307.97 1912.45 308C1912.56 308.07 1912.63 308.19 1912.72 308.29C1912.64 308.32 1912.57 308.36 1912.47 308.4L1912.48 308.39Z" fill="white"/>
                    <path d="M1927.72 347.03C1927.59 347.04 1927.45 347.07 1927.33 347.05C1927.29 347.05 1927.26 346.91 1927.23 346.83C1927.36 346.82 1927.5 346.79 1927.62 346.81C1927.66 346.81 1927.69 346.95 1927.72 347.03Z" fill="white"/>
                    <path d="M1966.39 367.27C1966.35 367.18 1966.32 367.1 1966.28 367.02C1966.41 367.01 1966.55 366.98 1966.67 367C1966.71 367 1966.74 367.14 1966.77 367.22C1966.64 367.24 1966.51 367.25 1966.38 367.27H1966.39Z" fill="white"/>
                    <path d="M2001.4 326.37C2001.3 326.45 2001.2 326.53 2001.1 326.62C2001.07 326.54 2000.98 326.43 2001.01 326.4C2001.08 326.29 2001.2 326.22 2001.3 326.13C2001.33 326.21 2001.37 326.28 2001.41 326.38L2001.4 326.37Z" fill="white"/>
                    <path d="M1896.86 300.3C1896.77 300.34 1896.69 300.37 1896.61 300.41C1896.6 300.28 1896.57 300.14 1896.59 300.02C1896.59 299.98 1896.73 299.95 1896.81 299.92C1896.83 300.05 1896.84 300.18 1896.86 300.31V300.3Z" fill="white"/>
                    <path d="M1907.41 366.42C1907.5 366.38 1907.58 366.35 1907.66 366.31C1907.67 366.44 1907.7 366.58 1907.68 366.7C1907.68 366.74 1907.54 366.77 1907.46 366.8C1907.44 366.67 1907.43 366.54 1907.41 366.41V366.42Z" fill="white"/>
                    <path d="M2023.18 423.99C2023.21 424.07 2023.29 424.19 2023.27 424.21C2023.17 424.29 2023.05 424.4 2022.93 424.41C2022.68 424.43 2022.62 424.29 2022.8 424.12C2022.89 424.04 2023.05 424.03 2023.18 423.99Z" fill="white"/>
                    <path d="M2022.63 424.55C2022.5 424.59 2022.32 424.7 2022.26 424.65C2022.09 424.51 2022.12 424.35 2022.28 424.32C2022.42 424.29 2022.57 424.32 2022.72 424.32C2022.69 424.39 2022.66 424.47 2022.63 424.54V424.55Z" fill="white"/>
                    <path d="M1846.63 411.7C1846.53 411.78 1846.43 411.86 1846.33 411.95C1846.3 411.87 1846.21 411.76 1846.24 411.73C1846.31 411.62 1846.43 411.55 1846.53 411.46C1846.56 411.54 1846.6 411.61 1846.64 411.71L1846.63 411.7Z" fill="white"/>
                    <path d="M2051.47 352.11C2051.37 352.19 2051.27 352.27 2051.17 352.36C2051.14 352.28 2051.05 352.17 2051.08 352.14C2051.15 352.03 2051.27 351.96 2051.37 351.87C2051.4 351.95 2051.44 352.02 2051.48 352.12L2051.47 352.11Z" fill="white"/>
                    <path d="M2026.58 430.27C2026.49 430.31 2026.41 430.34 2026.33 430.38C2026.32 430.25 2026.29 430.11 2026.31 429.99C2026.31 429.95 2026.45 429.92 2026.53 429.89C2026.55 430.02 2026.56 430.15 2026.58 430.28V430.27Z" fill="white"/>
                    <path d="M2019.04 330.12C2019.14 330.04 2019.24 329.96 2019.34 329.87C2019.37 329.95 2019.46 330.06 2019.43 330.09C2019.36 330.2 2019.24 330.27 2019.14 330.36C2019.11 330.28 2019.07 330.21 2019.03 330.11L2019.04 330.12Z" fill="white"/>
                    <path d="M1932.87 428.89C1932.77 428.97 1932.67 429.05 1932.57 429.14C1932.54 429.06 1932.45 428.95 1932.48 428.92C1932.55 428.81 1932.67 428.74 1932.77 428.65C1932.8 428.73 1932.84 428.8 1932.88 428.9L1932.87 428.89Z" fill="white"/>
                    <path d="M1940.58 305.24C1940.54 305.15 1940.51 305.07 1940.47 304.99C1940.6 304.98 1940.74 304.95 1940.86 304.97C1940.9 304.97 1940.93 305.11 1940.96 305.19C1940.83 305.21 1940.7 305.22 1940.57 305.24H1940.58Z" fill="white"/>
                    <path d="M1995.41 430.1C1995.42 430.23 1995.45 430.37 1995.43 430.49C1995.43 430.53 1995.29 430.56 1995.21 430.59C1995.2 430.46 1995.17 430.32 1995.19 430.2C1995.19 430.16 1995.33 430.13 1995.41 430.1Z" fill="white"/>
                    <path d="M1952.13 307.27C1952.03 307.35 1951.93 307.43 1951.83 307.52C1951.8 307.44 1951.71 307.33 1951.74 307.3C1951.81 307.19 1951.93 307.12 1952.03 307.03L1952.14 307.28L1952.13 307.27Z" fill="white"/>
                    <path d="M1886.46 388.97C1886.54 389.07 1886.62 389.17 1886.71 389.27C1886.63 389.3 1886.52 389.39 1886.49 389.36C1886.38 389.29 1886.31 389.17 1886.22 389.07C1886.3 389.04 1886.37 389 1886.47 388.96L1886.46 388.97Z" fill="white"/>
                    <path d="M1762.37 399.11C1762.38 399.24 1762.41 399.38 1762.39 399.5C1762.39 399.54 1762.25 399.57 1762.17 399.6C1762.16 399.47 1762.13 399.33 1762.15 399.21C1762.15 399.17 1762.29 399.14 1762.37 399.11Z" fill="white"/>
                    <path d="M2047.72 250.17C2049.61 249.93 2050.4 251.18 2051.13 252.38C2051.4 252.82 2051.71 253.56 2051.93 253.73C2053.34 254.85 2051.42 255.79 2051.89 256.78C2050.86 257.09 2049.73 257.81 2048.82 257.59C2047.96 257.38 2046.9 257.03 2046.14 256.4C2045.42 255.8 2044.14 255.29 2045.37 253.97C2045.55 253.77 2045.33 253.22 2045.34 252.84C2045.38 251.13 2046.22 250.25 2047.72 250.17Z" fill="white"/>
                    <path d="M1954.35 372.44C1954.31 371.32 1955.17 370.48 1955.57 370.47C1957.1 370.43 1957.65 369.01 1958.84 368.52C1959.68 368.17 1960.24 368.09 1960.85 368.72C1961.86 369.76 1962.01 370.41 1961.41 371.29C1961.15 371.67 1960.95 372.06 1960.75 372.47C1959.89 374.26 1955.97 375.14 1954.72 373.24C1954.5 372.9 1954.3 372.63 1954.34 372.44H1954.35Z" fill="white"/>
                    <path d="M2044.02 352.64C2044.71 351.93 2044.43 350.51 2045.79 350.34C2045.98 350.32 2046.21 350.38 2046.37 350.3C2047 349.95 2047.56 349.34 2048.39 349.73C2049.04 350.04 2049.18 350.74 2049.49 351.29C2050.11 352.38 2049.6 354.09 2048.48 354.89C2047.6 355.51 2046.74 355.52 2045.76 355.04C2044.74 354.54 2044.57 353.48 2044.02 352.65V352.64Z" fill="white"/>
                    <path d="M2112.77 470.67C2112.85 472.36 2112.26 473.15 2111.05 473.36C2110.38 473.47 2109.69 473.41 2109.02 473.49C2108.38 473.56 2108.17 473.29 2108.18 472.69C2108.18 472.01 2108.02 471.32 2108.11 470.66C2108.25 469.53 2108.98 468.76 2109.96 468.25C2110.16 468.15 2110.58 468.16 2110.73 468.3C2111.29 468.82 2111.97 469.1 2112.59 469.49C2112.89 469.68 2112.76 470.56 2112.78 470.69L2112.77 470.67Z" fill="white"/>
                    <path d="M1987.64 495.42C1987.54 494.66 1987.37 493.58 1988.54 493.2C1989.09 493.02 1990.13 492.68 1990.38 493.37C1990.65 494.11 1991.57 494.73 1991.13 495.67C1990.83 496.33 1990.27 496.92 1990.14 497.6C1990.02 498.23 1989.82 498.36 1989.26 498.4C1988.45 498.45 1987.98 498 1987.77 497.36C1987.6 496.82 1987.68 496.21 1987.64 495.42Z" fill="white"/>
                    <path d="M2133.87 471.32C2133.82 470.55 2134.38 469.87 2135.14 469.78C2135.39 469.75 2137.86 470.99 2137.96 471.2C2138.19 471.67 2137.31 473.21 2137.25 473.14C2136.52 472.16 2136.01 473.56 2135.39 473.25C2134.8 472.95 2133.9 471.82 2133.87 471.33V471.32Z" fill="white"/>
                    <path d="M2085.98 304.96C2085.58 304.96 2084.96 305.11 2084.94 304.72C2084.89 304.01 2084.53 303.24 2085.16 302.53C2085.51 302.13 2086.04 302.18 2086.33 301.86C2086.81 301.34 2087.16 301.24 2087.76 301.72C2088.4 302.24 2088.54 302.94 2088.18 303.47C2087.67 304.21 2087.15 305.17 2085.98 304.96Z" fill="white"/>
                    <path d="M2014.27 376.41C2014.27 377.02 2014.62 377.8 2013.45 377.81C2013.19 377.81 2012.95 378.35 2012.67 378.62C2012.36 378.93 2012.02 379.21 2011.69 379.5C2011.59 378.86 2011.25 378.11 2011.46 377.59C2011.73 376.92 2012.45 376.44 2012.94 375.84C2013.21 375.51 2013.52 375.26 2013.91 375.41C2014.34 375.57 2014.24 376.04 2014.27 376.41Z" fill="white"/>
                    <path d="M2169.01 358.6C2169.01 358.79 2169.05 358.98 2169.03 359.17C2168.99 359.59 2169.34 360.18 2168.69 360.37C2168.02 360.56 2167.42 360.39 2166.93 359.82C2166.51 359.34 2166.72 359.02 2167.03 358.61C2167.23 358.34 2167.21 357.91 2167.41 357.64C2167.67 357.28 2167.92 356.78 2168.5 356.89C2169.18 357.02 2168.9 357.62 2168.98 358.04C2169.02 358.22 2169 358.42 2169.01 358.61V358.6Z" fill="white"/>
                    <path d="M2127.93 480.1C2128.73 480.5 2129.68 480.67 2130.26 481.44C2130.39 481.62 2130.42 481.98 2130.15 482.22C2129.81 482.52 2129.58 483.14 2129.03 482.94C2128.36 482.7 2127.73 482.31 2127.13 481.91C2126.42 481.44 2127.42 481.24 2127.34 480.84C2127.26 480.42 2127.39 480.09 2127.93 480.1Z" fill="white"/>
                    <path d="M2008.9 329.19C2009.01 329.71 2008.85 330.04 2008.21 330.04C2007.21 330.04 2006.61 329.54 2006.53 328.65C2006.45 327.86 2006.92 327.4 2007.52 327.17C2008.21 326.91 2008.87 327.9 2008.9 329.19Z" fill="white"/>
                    <path d="M1934.01 284.09C1934.02 284.38 1934.09 284.68 1934.04 284.96C1933.93 285.62 1933.16 285.87 1932.67 285.43C1932.38 285.17 1932.03 284.95 1931.82 284.64C1931.55 284.24 1931.49 283.66 1931.74 283.31C1932.04 282.9 1932.77 282.99 1933.07 282.46C1933.42 282.88 1934.1 283.07 1933.99 283.81C1933.98 283.9 1933.99 284 1934 284.1L1934.01 284.09Z" fill="white"/>
                    <path d="M2117.54 427.1C2117.77 426.85 2117.97 426.64 2118.15 426.42C2118.6 425.85 2119.48 426.47 2119.9 425.74C2120.12 425.35 2120.56 425.33 2120.85 425.66C2121.13 425.97 2121.03 426.33 2120.73 426.64C2120.33 427.05 2119.98 427.52 2119.56 427.91C2119.36 428.09 2119.26 428.7 2118.77 428.22C2118.37 427.83 2117.94 427.47 2117.53 427.1H2117.54Z" fill="white"/>
                    <path d="M2051.81 527.93C2051.19 527.87 2050.57 526.94 2050.68 526.24C2050.72 525.96 2052.17 524.9 2052.5 524.98C2053.34 525.17 2052.98 525.9 2053 526.37C2053.04 527.26 2052.4 527.98 2051.81 527.93Z" fill="white"/>
                    <path d="M2004.89 461.9C2005.48 461.78 2005.84 461.89 2005.85 462.56C2005.85 463.38 2005.33 464.21 2004.75 464.24C2004.17 464.27 2003.54 463.48 2003.51 462.67C2003.46 461.46 2004.43 462.1 2004.89 461.91V461.9Z" fill="white"/>
                    <path d="M1998.62 458.55C1998.87 458.08 1999.13 457.55 1999.74 457.48C2000.4 457.4 2001.13 458.51 2001 459.3C2000.92 459.82 2000.54 459.8 2000.16 459.79C1999.3 459.75 1998.57 459.17 1998.62 458.56V458.55Z" fill="white"/>
                    <path d="M2086.83 444.42C2086.85 444.8 2086.83 444.99 2086.87 445.18C2087 445.88 2086.35 446.12 2086.06 446.57C2085.72 446.15 2085.06 445.98 2085.11 445.27C2085.12 445.08 2085.11 444.89 2085.08 444.7C2084.99 444.06 2085.06 443.65 2085.91 443.62C2086.72 443.59 2086.92 443.91 2086.83 444.42Z" fill="white"/>
                    <path d="M1919.47 435.28C1920.15 435.2 1920.31 435.64 1920.18 435.98C1919.92 436.65 1919.53 437.28 1919.15 437.9C1918.79 438.47 1918.57 437.9 1918.32 437.74C1917.24 437.03 1918.49 436.46 1918.47 435.79C1918.45 435.02 1919.2 435.43 1919.47 435.28Z" fill="white"/>
                    <path d="M2054.15 457.39C2053.96 456.6 2054.43 456.12 2055.03 455.88C2055.48 455.7 2056.64 456.8 2056.47 457.16C2056.13 457.85 2055.55 458.17 2054.75 458.14C2054.19 458.12 2054.18 457.77 2054.16 457.38L2054.15 457.39Z" fill="white"/>
                    <path d="M2137.37 300.55C2137.94 300.62 2138.28 301.05 2138.55 301.53C2138.71 301.81 2137.47 302.83 2136.88 302.92C2136.34 303 2136.27 302.63 2136.26 302.23C2136.24 301.43 2136.78 300.6 2137.37 300.55Z" fill="white"/>
                    <path d="M2089.58 477.98C2089.29 477.91 2089 477.72 2089.14 477.41C2089.48 476.67 2089.64 475.84 2090.26 475.22C2090.52 474.96 2090.84 474.92 2091.04 475.02C2091.23 475.11 2091.43 475.55 2091.36 475.71C2091.07 476.38 2090.69 477 2090.33 477.63C2090.18 477.9 2089.91 478 2089.59 477.97L2089.58 477.98Z" fill="white"/>
                    <path d="M2104.07 506.13C2104.03 505.83 2103.97 505.44 2104.47 505.31C2104.9 505.2 2105.12 505.55 2105.43 505.74C2106.05 506.13 2105.9 506.75 2105.85 507.26C2105.82 507.56 2105.34 508.09 2105.16 508.05C2104.79 507.97 2104.38 507.64 2104.17 507.3C2104 507.01 2104.1 506.57 2104.08 506.12L2104.07 506.13Z" fill="white"/>
                    <path d="M1946.64 480.11C1946.17 480.21 1945.84 480.05 1945.85 479.4C1945.85 478.59 1946.37 477.78 1946.95 477.74C1947.92 477.67 1947.32 478.92 1948.08 479.1C1948.21 479.13 1947.39 480 1946.65 480.1L1946.64 480.11Z" fill="white"/>
                    <path d="M2104.01 391.64C2104.11 391.5 2104.18 391.26 2104.31 391.22C2105.04 391 2106.41 391.98 2106.39 392.71C2106.39 392.96 2106.3 393.33 2106.13 393.42C2105.95 393.52 2105.58 393.42 2105.38 393.29C2104.49 392.69 2104.04 392.12 2104.01 391.63V391.64Z" fill="white"/>
                    <path d="M2056.94 293.58C2056.65 293.6 2056.36 293.66 2056.08 293.62C2055.37 293.51 2055.66 292.9 2055.59 292.49C2055.5 291.95 2055.88 291.88 2056.28 291.87C2056.65 291.86 2057.01 291.77 2057.33 292.1C2057.65 292.44 2058.14 292.71 2057.94 293.25C2057.78 293.67 2057.31 293.56 2056.94 293.59V293.58Z" fill="white"/>
                    <path d="M2087.85 475.23C2087.76 475.83 2087.36 475.8 2086.99 475.78C2086.12 475.73 2085.4 475.16 2085.47 474.54C2085.53 474.03 2085.95 474.05 2086.32 474.07C2087.19 474.12 2087.89 474.69 2087.85 475.24V475.23Z" fill="white"/>
                    <path d="M1946.62 488.31C1946.14 487.84 1945.57 487.52 1945.62 486.7C1945.66 486.03 1945.99 485.99 1946.5 485.95C1947.06 485.91 1947.31 486.01 1947.43 486.64C1947.58 487.47 1946.97 487.79 1946.62 488.31Z" fill="white"/>
                    <path d="M1942.08 474.35C1942.17 474.2 1942.24 473.93 1942.35 473.91C1942.72 473.84 1943.19 473.7 1943.46 473.86C1943.9 474.12 1944.49 474.4 1944.45 475.08C1944.42 475.6 1943.99 475.59 1943.62 475.57C1942.74 475.52 1942.07 474.98 1942.08 474.34V474.35Z" fill="white"/>
                    <path d="M2094.15 280.12C2094.15 279.72 2094.14 279.29 2094.68 279.36C2095.38 279.44 2095.94 279.85 2095.89 280.58C2095.86 280.96 2095.39 281.31 2095.11 281.67C2094.66 281.23 2093.99 280.93 2094.15 280.13V280.12Z" fill="white"/>
                    <path d="M1948.51 330.19C1949.21 330.2 1949.97 330.96 1949.91 331.45C1949.85 331.96 1949.44 331.89 1949.08 331.93C1948.35 332.01 1947.84 331.64 1947.71 331.01C1947.67 330.82 1948.13 330.2 1948.51 330.18V330.19Z" fill="white"/>
                    <path d="M2035.33 467.04C2035.31 466.55 2035.3 466.04 2035.81 466.17C2036.43 466.32 2037 466.71 2037.59 467C2037.18 467.52 2036.77 467.97 2035.98 467.91C2035.23 467.86 2035.39 467.36 2035.33 467.04Z" fill="white"/>
                    <path d="M1909.79 392.76C1909.66 391.97 1910.04 391.9 1910.59 391.83C1911.44 391.72 1911.51 392.17 1911.54 392.82C1911.58 393.49 1911.26 393.57 1910.7 393.63C1909.88 393.72 1909.67 393.36 1909.78 392.77L1909.79 392.76Z" fill="white"/>
                    <path d="M2046.13 473.2C2046.64 473.27 2047.45 472.84 2047.36 473.82C2047.31 474.36 2046.9 474.85 2046.3 474.94C2045.3 475.1 2045.53 474.01 2045.25 473.59C2044.89 473.07 2045.85 473.41 2046.14 473.2H2046.13Z" fill="white"/>
                    <path d="M2119.87 517.49C2119.86 517.21 2119.8 516.92 2119.84 516.64C2119.91 516.13 2120.32 516.17 2120.69 516.17C2120.96 516.17 2121.27 516.01 2121.47 516.32C2121.69 516.67 2121.64 517.01 2121.33 517.3C2121.19 517.43 2121.08 517.58 2120.95 517.72C2120.7 517.98 2120.39 518.48 2120.2 518.44C2119.77 518.35 2119.94 517.83 2119.88 517.48L2119.87 517.49Z" fill="white"/>
                    <path d="M1979.49 284.12C1978.82 283.95 1979.06 283.38 1979.02 282.97C1978.95 282.2 1979.58 282.4 1980 282.35C1980.39 282.3 1980.79 282.32 1980.76 282.88C1980.73 283.45 1979.99 284.17 1979.49 284.12Z" fill="white"/>
                    <path d="M1981.48 437.92C1981.99 437.92 1982.39 437.9 1982.36 438.46C1982.33 439.03 1981.59 439.77 1981.09 439.7C1980.58 439.63 1980.68 439.2 1980.61 438.84C1980.44 437.97 1980.96 437.9 1981.47 437.92H1981.48Z" fill="white"/>
                    <path d="M2077.5 496.79C2077.31 496.75 2076.9 496.77 2076.82 496.61C2076.6 496.2 2077.08 495.98 2077.3 495.83C2077.64 495.61 2077.68 494.54 2078.46 495.34C2078.72 495.6 2079.07 495.81 2078.98 496.24C2078.78 497.11 2078.03 496.53 2077.5 496.78V496.79Z" fill="white"/>
                    <path d="M2149.44 364.76C2149.68 364.38 2149.91 363.57 2150.4 363.9C2150.82 364.18 2151.57 363.87 2151.67 364.55C2151.73 364.96 2150.34 365.61 2149.81 365.43C2149.53 365.34 2149.4 365.09 2149.44 364.75V364.76Z" fill="white"/>
                    <path d="M1927.68 389.79C1927.68 389.79 1927.37 389.37 1927.16 388.95C1927.07 388.77 1928.13 387.96 1928.39 388C1929.1 388.12 1928.81 388.7 1928.89 389.11C1928.98 389.61 1928.66 389.75 1927.68 389.78V389.79Z" fill="white"/>
                    <path d="M1930.68 355.54C1930.84 354.86 1931.41 355.13 1931.82 355.06C1932.34 354.97 1932.55 355.36 1932.42 355.72C1932.27 356.13 1931.93 356.49 1931.61 356.81C1931.49 356.92 1930.65 355.8 1930.68 355.54Z" fill="white"/>
                    <path d="M2067.48 242.16C2067.98 242.03 2068.19 242.26 2068.21 242.54C2068.24 243.29 2067.79 243.71 2067.11 243.91C2066.76 244.01 2066.55 243.78 2066.53 243.5C2066.47 242.73 2066.98 242.36 2067.48 242.17V242.16Z" fill="white"/>
                    <path d="M2123.35 402.52C2122.73 402.46 2122.25 402.09 2122.23 401.6C2122.22 401.36 2122.64 400.78 2123.21 400.76C2123.98 400.74 2123.78 401.33 2123.83 401.74C2123.88 402.13 2123.84 402.49 2123.35 402.52Z" fill="white"/>
                    <path d="M1974.47 265.66C1974.74 265.61 1975.9 266.34 1975.79 266.47C1975.51 266.82 1975.18 267.19 1974.79 267.37C1974.43 267.54 1974.06 267.32 1974.09 266.82C1974.12 266.41 1973.78 265.86 1974.47 265.66Z" fill="white"/>
                    <path d="M2125.37 408.12C2125.51 407.6 2125.82 407.05 2126.59 407.03C2126.87 407.03 2127.13 407.21 2127.06 407.57C2126.93 408.26 2126.56 408.76 2125.81 408.8C2125.53 408.82 2125.29 408.63 2125.36 408.12H2125.37Z" fill="white"/>
                    <path d="M2189.68 419.01C2189.62 418.48 2189.99 418.26 2190.35 418.39C2190.76 418.54 2191.12 418.88 2191.44 419.2C2191.55 419.32 2190.43 420.16 2190.17 420.13C2189.8 420.08 2189.62 419.64 2189.69 419.01H2189.68Z" fill="white"/>
                    <path d="M2044.2 468.82C2044.19 468.41 2044.17 468.01 2044.72 468.03C2045.32 468.05 2045.79 468.44 2045.81 468.94C2045.82 469.19 2045.4 469.76 2044.83 469.78C2044.06 469.8 2044.29 469.21 2044.2 468.82Z" fill="white"/>
                    <path d="M2134.26 488.94C2134.49 488.44 2134.75 488.01 2135.34 487.91C2135.69 487.85 2135.92 488.06 2135.9 488.34C2135.86 488.81 2136.35 489.52 2135.41 489.66C2134.84 489.74 2134.68 489.15 2134.26 488.94Z" fill="white"/>
                    <path d="M2053.04 458.66C2053.14 458.51 2053.23 458.37 2053.33 458.22C2053.97 458.77 2054.52 459.44 2055.43 459.58C2055.47 459.58 2055.5 459.96 2055.45 460.13C2055.42 460.23 2055.11 460.35 2055.08 460.32C2054.56 459.74 2053.59 459.93 2053.19 459.19C2053.11 459.03 2053.1 458.84 2053.05 458.66H2053.04Z" fill="white"/>
                    <path d="M2057.42 457.43C2058 457.43 2057.72 458.19 2057.7 458.64C2057.66 459.53 2056.88 459.1 2056.41 459.2C2056.27 459.23 2055.73 458.94 2056.2 458.77C2056.9 458.5 2056 457.36 2057.42 457.44V457.43Z" fill="white"/>
                    <path d="M2038.01 277.59C2037.32 277.74 2037.09 277.09 2036.65 276.8C2036.88 276.52 2037.11 276.02 2037.35 276.02C2037.88 276.01 2038.25 276.42 2038.39 276.97C2038.47 277.3 2038.27 277.47 2038.01 277.59Z" fill="white"/>
                    <path d="M1932.84 394.2C1933.25 394.24 1933.82 393.96 1933.88 394.74C1933.92 395.3 1933.56 395.35 1933.17 395.35C1932.74 395.35 1932.18 395.59 1932.12 394.82C1932.07 394.26 1932.45 394.24 1932.85 394.2H1932.84Z" fill="white"/>
                    <path d="M2115.62 275.03C2116.11 274.2 2116.69 274 2117.38 274.08C2117.54 274.1 2117.69 274.25 2117.84 274.34C2117.71 274.48 2117.56 274.6 2117.47 274.76C2117.13 275.38 2116.54 275.31 2116 275.29C2115.83 275.29 2115.67 275.06 2115.62 275.03Z" fill="white"/>
                    <path d="M1903.2 390.66C1903.18 390.52 1903.54 390.43 1903.95 390.44C1904.37 390.45 1904.94 390.18 1905.01 390.96C1905.06 391.51 1904.71 391.59 1904.31 391.58C1903.88 391.58 1903.32 391.82 1903.21 390.66H1903.2Z" fill="white"/>
                    <path d="M1959.23 416.92C1959.35 416.98 1959.57 417.04 1959.59 417.13C1959.69 417.78 1959.76 418.41 1959.12 418.9C1958.86 419.1 1958.68 419.18 1958.53 418.9C1958.29 418.44 1958.78 417.08 1959.23 416.93V416.92Z" fill="white"/>
                    <path d="M2004.57 313.12C2004.76 312.21 2005.5 312.75 2005.98 312.64C2006.2 312.59 2006.38 313.01 2006.31 313.3C2006.11 314.15 2005.39 313.73 2004.9 313.79C2004.67 313.82 2004.51 313.44 2004.56 313.12H2004.57Z" fill="white"/>
                    <path d="M2025.53 424.39C2025.72 423.48 2026.46 424.02 2026.94 423.91C2027.16 423.86 2027.34 424.28 2027.27 424.57C2027.07 425.42 2026.35 425 2025.86 425.06C2025.63 425.09 2025.47 424.71 2025.52 424.39H2025.53Z" fill="white"/>
                    <path d="M2130.95 347.3C2130.93 347.16 2131.29 347.07 2131.7 347.08C2132.12 347.09 2132.69 346.82 2132.76 347.6C2132.81 348.15 2132.46 348.23 2132.06 348.22C2131.63 348.22 2131.07 348.46 2130.96 347.3H2130.95Z" fill="white"/>
                    <path d="M2017.96 500.92C2018.28 501.44 2018.61 501.97 2018.92 502.51C2018.93 502.53 2018.82 502.69 2018.74 502.71C2018.35 502.81 2018.04 502.69 2017.75 502.39C2017.42 502.06 2017.03 501.77 2017.31 501.24C2017.38 501.12 2017.61 501.08 2017.96 500.92Z" fill="white"/>
                    <path d="M2067.3 479.72C2066.91 479.77 2066.5 479.84 2066.41 479.32C2066.29 478.65 2066.72 478.55 2067.25 478.55C2067.62 478.55 2068.04 478.44 2068.13 478.97C2068.25 479.64 2067.83 479.77 2067.29 479.72H2067.3Z" fill="white"/>
                    <path d="M2114.86 444.59C2114.99 444.07 2115.11 443.82 2115.47 443.88C2116.16 444.01 2116.64 444.38 2116.7 445.13C2116.71 445.27 2116.54 445.43 2116.45 445.59C2115.9 445.24 2115.35 444.9 2114.86 444.59Z" fill="white"/>
                    <path d="M1998.85 439.72C1998.75 439.21 1998.84 438.8 1999.08 438.81C1999.57 438.82 2000.25 438.33 2000.53 439.16C2000.63 439.44 2000.53 439.84 2000.26 439.86C1999.77 439.9 1999.14 440.21 1998.85 439.72Z" fill="white"/>
                    <path d="M2121.89 330.19C2121.89 329.79 2121.87 329.42 2122.43 329.4C2123.21 329.38 2123.02 329.97 2123.07 330.39C2123.11 330.78 2123.1 331.15 2122.54 331.16C2121.76 331.18 2121.98 330.59 2121.89 330.18V330.19Z" fill="white"/>
                    <path d="M2033.62 334.57C2032.71 334.38 2033.25 333.64 2033.14 333.16C2033.09 332.94 2033.51 332.76 2033.8 332.83C2034.65 333.03 2034.23 333.75 2034.29 334.24C2034.32 334.47 2033.94 334.63 2033.62 334.58V334.57Z" fill="white"/>
                    <path d="M2117.51 454.89C2117.12 454.94 2116.71 455.01 2116.62 454.49C2116.5 453.82 2116.93 453.72 2117.46 453.72C2117.83 453.72 2118.25 453.61 2118.34 454.14C2118.46 454.81 2118.04 454.94 2117.5 454.89H2117.51Z" fill="white"/>
                    <path d="M1956.56 477.22C1955.9 477.32 1955.72 477.13 1955.68 476.82C1955.58 476.14 1956.02 476.06 1956.53 476.08C1956.8 476.09 1957.33 476 1957.26 476.22C1957.12 476.67 1956.7 477.03 1956.56 477.21V477.22Z" fill="white"/>
                    <path d="M2106.12 296.94C2106.18 296.79 2106.28 296.55 2106.38 296.31C2106.75 296.68 2107.38 296.87 2107.33 297.55C2107.31 297.84 2107.15 298.03 2106.79 298.03C2106.03 298.04 2106.21 297.48 2106.12 296.93V296.94Z" fill="white"/>
                    <path d="M2148.53 504.19C2148.78 504.13 2148.99 504.33 2149.04 504.61C2149.11 504.99 2149.08 505.41 2148.53 505.36C2148.15 505.33 2147.51 505.73 2147.46 505.06C2147.44 504.82 2147.88 504.24 2148.54 504.19H2148.53Z" fill="white"/>
                    <path d="M1968.95 518.88C1968.83 518.21 1969.47 517.97 1969.77 517.54C1969.88 517.78 1970.06 518.01 1970.07 518.26C1970.1 518.67 1970.33 519.23 1969.56 519.31C1969.21 519.34 1969.06 519.18 1968.94 518.89L1968.95 518.88Z" fill="white"/>
                    <path d="M2143.55 471.71C2143.94 471.6 2143.52 471.61 2143.75 471.91C2143.88 472.07 2143.89 472.49 2143.77 472.64C2143.44 473.04 2143.19 473.65 2142.48 473.5C2142.4 473.48 2142.27 473.37 2142.28 473.33C2142.45 472.59 2143.17 472.28 2143.55 471.71Z" fill="white"/>
                    <path d="M2107.3 431.04C2107.69 430.67 2107.94 430.02 2108.63 430.22C2108.79 430.27 2108.97 430.69 2108.94 430.91C2108.84 431.6 2108.23 431.31 2107.82 431.4C2107.54 431.46 2107.31 431.4 2107.3 431.04Z" fill="white"/>
                    <path d="M2027.99 381.42C2027.99 381.23 2027.92 381.02 2027.98 380.85C2028.02 380.74 2028.24 380.58 2028.33 380.61C2028.63 380.71 2028.75 382.51 2028.46 382.71C2028.39 382.76 2028.09 382.64 2028.07 382.56C2028.01 382.18 2028.01 381.8 2027.99 381.42Z" fill="white"/>
                    <path d="M2186.38 377.67C2186.31 377.08 2186.66 376.96 2187.06 376.99C2187.35 377.01 2187.54 377.17 2187.55 377.53C2187.57 378.07 2187.25 378.18 2186.85 378.15C2186.57 378.13 2186.29 378 2186.37 377.67H2186.38Z" fill="white"/>
                    <path d="M1959.9 502.12C1959.97 501.81 1960.1 501.63 1960.46 501.61C1961 501.59 1961.1 501.91 1961.08 502.31C1961.06 502.6 1960.9 502.78 1960.54 502.8C1960 502.82 1959.94 502.5 1959.91 502.11L1959.9 502.12Z" fill="white"/>
                    <path d="M1979.89 288.66C1979.96 288.35 1980.09 288.17 1980.45 288.15C1980.99 288.13 1981.09 288.45 1981.07 288.85C1981.05 289.14 1980.89 289.32 1980.53 289.34C1979.99 289.36 1979.93 289.04 1979.9 288.65L1979.89 288.66Z" fill="white"/>
                    <path d="M2089.5 271.42C2089.86 271.41 2090.19 271.44 2090.23 271.98C2090.25 272.34 2090.09 272.52 2089.8 272.57C2089.4 272.63 2089.07 272.57 2089.04 272.03C2089.02 271.67 2089.18 271.53 2089.51 271.42H2089.5Z" fill="white"/>
                    <path d="M1914.52 409.12C1914.02 408.92 1913.61 408.64 1913.51 408.08C1913.48 407.93 1913.64 407.61 1913.66 407.61C1914.19 407.74 1914.59 408.04 1914.69 408.6C1914.71 408.75 1914.59 408.93 1914.53 409.11L1914.52 409.12Z" fill="white"/>
                    <path d="M1948.24 310.29C1948.15 309.95 1948.37 309.77 1948.66 309.72C1949.06 309.66 1949.39 309.72 1949.42 310.26C1949.44 310.62 1949.28 310.8 1948.99 310.85C1948.59 310.92 1948.23 310.85 1948.24 310.28V310.29Z" fill="white"/>
                    <path d="M1979.17 422.13C1979.24 421.82 1979.37 421.64 1979.73 421.62C1980.27 421.6 1980.37 421.92 1980.35 422.32C1980.33 422.61 1980.17 422.79 1979.81 422.81C1979.27 422.83 1979.21 422.51 1979.18 422.12L1979.17 422.13Z" fill="white"/>
                    <path d="M2177.97 441.75C2178.06 441.39 2178.18 441.21 2178.54 441.19C2179.08 441.17 2179.18 441.49 2179.16 441.89C2179.14 442.18 2178.98 442.36 2178.62 442.38C2178.07 442.41 2178.04 442.05 2177.98 441.74L2177.97 441.75Z" fill="white"/>
                    <path d="M2154.86 447.7C2155.41 447.64 2155.52 447.99 2155.5 448.39C2155.48 448.68 2155.32 448.87 2154.96 448.88C2154.42 448.9 2154.32 448.58 2154.34 448.18C2154.36 447.89 2154.52 447.65 2154.86 447.7Z" fill="white"/>
                    <path d="M1886.86 379.47C1886.86 379.85 1886.87 380.24 1886.33 380.22C1886.16 380.22 1886 380.13 1885.83 380.08C1886.07 379.74 1886.3 379.39 1886.54 379.05C1886.65 379.19 1886.75 379.33 1886.86 379.48V379.47Z" fill="white"/>
                    <path d="M1934.9 357.96C1934.9 357.58 1934.89 357.19 1935.43 357.21C1935.6 357.21 1935.76 357.3 1935.93 357.35C1935.69 357.69 1935.46 358.04 1935.22 358.38C1935.11 358.24 1935.01 358.1 1934.9 357.95V357.96Z" fill="white"/>
                    <path d="M1975.57 486.46C1975.83 486.16 1976.07 485.88 1976.31 485.6C1976.42 485.84 1976.62 486.07 1976.61 486.31C1976.6 486.82 1976.17 486.82 1975.8 486.78C1975.7 486.77 1975.63 486.56 1975.56 486.47L1975.57 486.46Z" fill="white"/>
                    <path d="M2041.87 334.13C2042.09 334.2 2042.39 334.24 2042.39 334.31C2042.43 334.82 2041.91 334.97 2041.69 335.33C2041.59 335.19 2041.42 335.06 2041.39 334.9C2041.32 334.51 2041.41 334.17 2041.86 334.14L2041.87 334.13Z" fill="white"/>
                    <path d="M2063.12 244.47C2063.2 244.35 2063.25 244.13 2063.36 244.11C2063.72 244.03 2064.15 243.99 2064.21 244.5C2064.24 244.73 2064.06 244.99 2063.97 245.23C2063.69 244.98 2063.41 244.73 2063.12 244.47Z" fill="white"/>
                    <path d="M2046.7 268.37C2046.17 268.42 2046.1 268.05 2046.12 267.67C2046.12 267.51 2046.29 267.37 2046.38 267.21C2046.65 267.53 2046.92 267.84 2047.19 268.16C2047.03 268.23 2046.87 268.29 2046.7 268.37Z" fill="white"/>
                    <path d="M1984.4 464.43C1984.44 464.36 1984.49 464.14 1984.58 464.12C1984.94 464.04 1985.37 463.99 1985.44 464.49C1985.47 464.72 1985.3 464.98 1985.22 465.23C1984.96 464.97 1984.69 464.71 1984.41 464.43H1984.4Z" fill="white"/>
                    <path d="M1984.09 426.65C1984.04 426.12 1984.41 426.05 1984.79 426.07C1984.95 426.07 1985.09 426.24 1985.25 426.33C1984.93 426.6 1984.62 426.87 1984.3 427.14C1984.23 426.98 1984.17 426.82 1984.09 426.65Z" fill="white"/>
                    <path d="M1999.48 260.16C1999.57 260 1999.65 259.85 1999.73 259.69C2000.01 259.99 2000.3 260.29 2000.58 260.6C2000.42 260.67 2000.27 260.79 2000.11 260.82C1999.6 260.91 1999.5 260.56 1999.48 260.17V260.16Z" fill="white"/>
                    <path d="M2147.54 430.53C2147.01 430.58 2146.94 430.21 2146.96 429.83C2146.96 429.67 2147.13 429.53 2147.22 429.37C2147.49 429.69 2147.76 430 2148.03 430.32C2147.87 430.39 2147.71 430.45 2147.54 430.53Z" fill="white"/>
                    <path d="M2187.44 426.16C2187.3 425.96 2187.15 425.82 2187.12 425.67C2187.06 425.27 2187.15 424.92 2187.67 424.95C2187.84 424.96 2188 425.06 2188.16 425.12C2187.93 425.46 2187.69 425.8 2187.44 426.17V426.16Z" fill="white"/>
                    <path d="M2003.46 539.42C2003.64 539.07 2003.96 539.18 2004.22 539.18C2004.3 539.18 2004.47 539.45 2004.44 539.53C2004.31 539.87 2003.96 539.76 2003.7 539.76C2003.61 539.76 2003.53 539.54 2003.45 539.43L2003.46 539.42Z" fill="white"/>
                    <path d="M1989.07 518.37C1988.96 518.07 1988.83 517.83 1988.81 517.58C1988.8 517.44 1988.97 517.28 1989.06 517.12C1989.17 517.36 1989.33 517.58 1989.37 517.83C1989.39 517.96 1989.21 518.13 1989.07 518.37Z" fill="white"/>
                    <path d="M2159.8 325.94C2159.98 325.59 2160.3 325.7 2160.56 325.7C2160.64 325.7 2160.81 325.97 2160.78 326.05C2160.65 326.39 2160.3 326.28 2160.04 326.28C2159.95 326.28 2159.87 326.06 2159.79 325.95L2159.8 325.94Z" fill="white"/>
                    <path d="M2073.14 260.21C2073.44 260.1 2073.68 259.97 2073.93 259.95C2074.07 259.94 2074.23 260.11 2074.39 260.2C2074.15 260.31 2073.93 260.47 2073.68 260.51C2073.55 260.53 2073.38 260.35 2073.14 260.21Z" fill="white"/>
                    <path d="M2070.05 260.76C2070.2 260.76 2070.47 260.73 2070.71 260.79C2070.99 260.86 2071.01 261.07 2070.73 261.16C2070.49 261.24 2070.21 261.25 2069.97 261.2C2069.69 261.14 2069.69 260.96 2070.05 260.76Z" fill="white"/>
                    <path d="M2166.47 309.58C2166.31 309.52 2166.02 309.49 2166 309.4C2165.92 309.06 2166.18 308.97 2166.46 309C2166.63 309.02 2166.91 309.08 2166.93 309.17C2167.01 309.51 2166.76 309.59 2166.47 309.58Z" fill="white"/>
                    <path d="M1963.72 496.77C1963.78 496.61 1963.81 496.32 1963.9 496.3C1964.24 496.22 1964.33 496.48 1964.3 496.76C1964.28 496.93 1964.22 497.21 1964.13 497.23C1963.79 497.31 1963.71 497.06 1963.72 496.77Z" fill="white"/>
                    <path d="M1995.55 269.92C1995.44 269.62 1995.31 269.38 1995.29 269.13C1995.28 268.99 1995.45 268.83 1995.54 268.67C1995.65 268.91 1995.81 269.13 1995.85 269.38C1995.87 269.51 1995.69 269.68 1995.55 269.92Z" fill="white"/>
                    <path d="M2120.4 493.19C2120.46 493.08 2120.55 492.93 2120.64 492.77C2120.75 493.01 2120.91 493.23 2120.95 493.48C2120.97 493.61 2120.79 493.78 2120.7 493.93C2120.46 493.76 2120.33 493.53 2120.4 493.18V493.19Z" fill="white"/>
                    <path d="M1951.06 274.47C1951.34 274.43 1951.6 274.48 1951.56 274.83C1951.55 274.92 1951.28 275.01 1951.11 275.05C1950.83 275.11 1950.57 275.05 1950.61 274.7C1950.62 274.61 1950.9 274.55 1951.06 274.47Z" fill="white"/>
                    <path d="M2119.49 453.62C2119.77 453.58 2120.03 453.63 2119.99 453.98C2119.98 454.07 2119.71 454.16 2119.54 454.2C2119.26 454.26 2119 454.2 2119.04 453.85C2119.05 453.76 2119.33 453.7 2119.49 453.62Z" fill="white"/>
                    <path d="M2110.28 445.32C2110.56 445.28 2110.82 445.33 2110.78 445.68C2110.77 445.77 2110.5 445.86 2110.33 445.9C2110.05 445.96 2109.79 445.9 2109.83 445.55C2109.84 445.46 2110.12 445.4 2110.28 445.32Z" fill="white"/>
                    <path d="M2072.35 419.86C2072.24 419.56 2072.11 419.32 2072.09 419.07C2072.08 418.93 2072.25 418.77 2072.34 418.61C2072.45 418.85 2072.61 419.07 2072.65 419.32C2072.67 419.45 2072.49 419.62 2072.35 419.86Z" fill="white"/>
                    <path d="M1980.63 473.74C1980.72 473.54 1980.83 473.3 1980.93 473.07C1981.03 473.21 1981.23 473.36 1981.22 473.5C1981.21 473.75 1981.07 473.99 1980.99 474.24C1980.88 474.1 1980.78 473.96 1980.63 473.75V473.74Z" fill="white"/>
                    <path d="M2000.59 382.92C2000.65 382.81 2000.74 382.66 2000.83 382.5C2000.94 382.74 2001.1 382.96 2001.14 383.21C2001.16 383.34 2000.98 383.51 2000.89 383.66C2000.65 383.49 2000.52 383.26 2000.59 382.91V382.92Z" fill="white"/>
                    <path d="M1955.48 377.91C1955.37 377.61 1955.24 377.37 1955.22 377.12C1955.21 376.98 1955.38 376.82 1955.47 376.66C1955.58 376.9 1955.74 377.12 1955.78 377.37C1955.8 377.5 1955.62 377.67 1955.48 377.91Z" fill="white"/>
                    <path d="M1884.72 370.58C1884.9 370.23 1885.22 370.34 1885.48 370.34C1885.56 370.34 1885.73 370.61 1885.7 370.69C1885.57 371.03 1885.22 370.92 1884.96 370.92C1884.87 370.92 1884.79 370.7 1884.71 370.59L1884.72 370.58Z" fill="white"/>
                    <path d="M1940.37 339.4C1940.65 339.36 1940.91 339.41 1940.87 339.76C1940.86 339.85 1940.59 339.94 1940.42 339.98C1940.14 340.04 1939.88 339.98 1939.92 339.63C1939.93 339.54 1940.21 339.48 1940.37 339.4Z" fill="white"/>
                    <path d="M2145.68 337.57C2145.52 337.51 2145.23 337.48 2145.21 337.39C2145.13 337.05 2145.39 336.96 2145.67 336.99C2145.84 337.01 2146.12 337.07 2146.14 337.16C2146.22 337.5 2145.97 337.58 2145.68 337.57Z" fill="white"/>
                    <path d="M2052.51 357.59C2052.57 357.43 2052.6 357.14 2052.69 357.12C2053.03 357.04 2053.12 357.3 2053.09 357.58C2053.07 357.75 2053.01 358.03 2052.92 358.05C2052.58 358.13 2052.5 357.88 2052.51 357.59Z" fill="white"/>
                    <path d="M2050.85 342.47C2050.78 342.34 2050.68 342.22 2050.65 342.09C2050.64 342.04 2050.76 341.96 2050.82 341.89C2050.89 342.02 2050.99 342.14 2051.02 342.27C2051.03 342.32 2050.91 342.4 2050.85 342.47Z" fill="white"/>
                    <path d="M1995.77 250.51C1995.7 250.38 1995.6 250.26 1995.57 250.13C1995.56 250.08 1995.68 250 1995.74 249.93C1995.81 250.06 1995.91 250.18 1995.94 250.31C1995.95 250.36 1995.83 250.44 1995.77 250.51Z" fill="white"/>
                    <path d="M2098.54 442.79C2098.6 442.66 2098.66 442.53 2098.73 442.4C2098.8 442.46 2098.93 442.53 2098.92 442.58C2098.9 442.72 2098.82 442.85 2098.76 442.98C2098.69 442.92 2098.62 442.86 2098.54 442.78V442.79Z" fill="white"/>
                    <path d="M2142.84 453.96C2142.77 454.04 2142.7 454.11 2142.64 454.18C2142.57 454.05 2142.47 453.93 2142.44 453.8C2142.43 453.75 2142.55 453.67 2142.61 453.6C2142.69 453.72 2142.76 453.85 2142.84 453.97V453.96Z" fill="white"/>
                    <path d="M2057.99 459.81C2058.12 459.87 2058.25 459.93 2058.38 460C2058.32 460.07 2058.25 460.2 2058.2 460.19C2058.06 460.17 2057.93 460.09 2057.8 460.03C2057.86 459.96 2057.92 459.89 2058 459.81H2057.99Z" fill="white"/>
                    <path d="M2025.14 428.36C2025.27 428.29 2025.39 428.2 2025.52 428.16C2025.57 428.15 2025.65 428.27 2025.72 428.33C2025.59 428.4 2025.47 428.5 2025.34 428.53C2025.29 428.54 2025.21 428.42 2025.14 428.36Z" fill="white"/>
                    <path d="M1977.41 425.93C1977.49 426 1977.56 426.07 1977.63 426.13C1977.5 426.2 1977.38 426.3 1977.25 426.33C1977.2 426.34 1977.12 426.22 1977.05 426.16C1977.17 426.08 1977.3 426.01 1977.42 425.93H1977.41Z" fill="white"/>
                    <path d="M1961.32 482.65C1961.38 482.52 1961.44 482.39 1961.51 482.26C1961.58 482.32 1961.71 482.39 1961.7 482.44C1961.68 482.58 1961.6 482.71 1961.54 482.84C1961.47 482.78 1961.4 482.72 1961.32 482.64V482.65Z" fill="white"/>
                    <path d="M2077.23 460.69C2077.3 460.61 2077.37 460.54 2077.43 460.47C2077.5 460.6 2077.6 460.72 2077.63 460.85C2077.64 460.9 2077.52 460.98 2077.46 461.05C2077.38 460.93 2077.31 460.8 2077.23 460.68V460.69Z" fill="white"/>
                    <path d="M2036.46 399.77C2036.39 399.85 2036.32 399.92 2036.26 399.99C2036.19 399.86 2036.09 399.74 2036.06 399.61C2036.05 399.56 2036.17 399.48 2036.23 399.41C2036.31 399.53 2036.38 399.66 2036.46 399.78V399.77Z" fill="white"/>
                    <path d="M1894.95 395.53C1894.88 395.47 1894.75 395.38 1894.76 395.35C1894.82 395.22 1894.89 395.06 1895.01 394.99C1895.25 394.86 1895.37 394.97 1895.27 395.22C1895.22 395.34 1895.06 395.42 1894.95 395.52V395.53Z" fill="white"/>
                    <path d="M1895.25 394.72C1895.36 394.62 1895.5 394.43 1895.58 394.45C1895.81 394.51 1895.86 394.68 1895.71 394.79C1895.58 394.88 1895.42 394.92 1895.27 394.98C1895.27 394.89 1895.27 394.81 1895.26 394.72H1895.25Z" fill="white"/>
                    <path d="M2076.17 326.91C2076.23 326.78 2076.29 326.65 2076.36 326.52C2076.43 326.58 2076.56 326.65 2076.55 326.7C2076.53 326.84 2076.45 326.97 2076.39 327.1C2076.32 327.04 2076.25 326.98 2076.17 326.9V326.91Z" fill="white"/>
                    <path d="M1899.74 479.98C1899.8 479.85 1899.86 479.72 1899.93 479.59C1900 479.65 1900.13 479.72 1900.12 479.77C1900.1 479.91 1900.02 480.04 1899.96 480.17C1899.89 480.11 1899.82 480.05 1899.74 479.97V479.98Z" fill="white"/>
                    <path d="M1888.7 390.84C1888.77 390.76 1888.84 390.69 1888.9 390.62C1888.97 390.75 1889.07 390.87 1889.1 391C1889.11 391.05 1888.99 391.13 1888.93 391.2C1888.85 391.08 1888.78 390.95 1888.7 390.83V390.84Z" fill="white"/>
                    <path d="M1942.06 486.99C1942 487.12 1941.94 487.25 1941.87 487.38C1941.8 487.32 1941.67 487.25 1941.68 487.2C1941.7 487.06 1941.78 486.93 1941.84 486.8C1941.91 486.86 1941.98 486.92 1942.06 487V486.99Z" fill="white"/>
                    <path d="M1982.52 349.3C1982.58 349.17 1982.64 349.04 1982.71 348.91C1982.78 348.97 1982.91 349.04 1982.9 349.09C1982.88 349.23 1982.8 349.36 1982.74 349.49C1982.67 349.43 1982.6 349.37 1982.52 349.29V349.3Z" fill="white"/>
                    <path d="M2031.48 475.81C2031.56 475.88 2031.63 475.95 2031.7 476.01C2031.57 476.08 2031.45 476.18 2031.32 476.21C2031.27 476.22 2031.19 476.1 2031.12 476.04C2031.24 475.96 2031.37 475.89 2031.49 475.81H2031.48Z" fill="white"/>
                    <path d="M1919.78 376.74C1919.71 376.61 1919.61 376.49 1919.58 376.36C1919.57 376.31 1919.69 376.23 1919.75 376.16C1919.82 376.29 1919.92 376.41 1919.95 376.54C1919.96 376.59 1919.84 376.67 1919.78 376.74Z" fill="white"/>
                    <path d="M2019.07 479.08C2019.13 478.95 2019.19 478.82 2019.26 478.69C2019.33 478.75 2019.46 478.82 2019.45 478.87C2019.43 479.01 2019.35 479.14 2019.29 479.27L2019.07 479.07V479.08Z" fill="white"/>
                    <path d="M2046.97 367.75C2046.84 367.69 2046.71 367.63 2046.58 367.56C2046.64 367.49 2046.71 367.36 2046.76 367.37C2046.9 367.39 2047.03 367.47 2047.16 367.53C2047.1 367.6 2047.04 367.67 2046.96 367.75H2046.97Z" fill="white"/>
                    <path d="M2165.74 300.84C2165.67 300.71 2165.57 300.59 2165.54 300.46C2165.53 300.41 2165.65 300.33 2165.71 300.26C2165.78 300.39 2165.88 300.51 2165.91 300.64C2165.92 300.69 2165.8 300.77 2165.74 300.84Z" fill="white"/>
                    <path d="M2176.4 535.82C2174.7 536.67 2173.53 535.76 2172.45 534.87C2172.05 534.55 2171.52 533.95 2171.25 533.86C2169.55 533.27 2171.05 531.75 2170.27 530.98C2171.14 530.35 2171.97 529.29 2172.89 529.2C2173.77 529.11 2174.88 529.09 2175.81 529.43C2176.69 529.75 2178.07 529.81 2177.34 531.46C2177.23 531.7 2177.62 532.15 2177.74 532.52C2178.27 534.15 2177.77 535.26 2176.39 535.82H2176.4Z" fill="white"/>
                    <path d="M2223.73 389.61C2224.14 390.66 2223.61 391.73 2223.24 391.87C2221.81 392.41 2221.76 393.94 2220.81 394.8C2220.14 395.41 2219.63 395.67 2218.85 395.28C2217.55 394.64 2217.2 394.07 2217.47 393.05C2217.59 392.61 2217.64 392.17 2217.69 391.72C2217.91 389.75 2221.3 387.62 2223.12 388.99C2223.44 389.23 2223.72 389.43 2223.74 389.62L2223.73 389.61Z" fill="white"/>
                    <path d="M2145.84 438.06C2145.42 438.96 2146.16 440.2 2144.93 440.81C2144.76 440.9 2144.52 440.91 2144.4 441.04C2143.92 441.58 2143.6 442.34 2142.69 442.25C2141.97 442.18 2141.61 441.56 2141.13 441.15C2140.19 440.32 2140.1 438.55 2140.89 437.42C2141.51 436.54 2142.31 436.25 2143.4 436.37C2144.53 436.5 2145.04 437.44 2145.83 438.05L2145.84 438.06Z" fill="white"/>
                    <path d="M2041.85 349.71C2041.22 348.15 2041.5 347.2 2042.57 346.61C2043.16 346.28 2043.83 346.11 2044.44 345.81C2045.02 345.53 2045.3 345.71 2045.5 346.29C2045.72 346.93 2046.11 347.53 2046.25 348.18C2046.49 349.29 2046.06 350.26 2045.3 351.07C2045.15 351.24 2044.75 351.36 2044.56 351.27C2043.86 350.97 2043.13 350.93 2042.42 350.77C2042.07 350.69 2041.9 349.82 2041.85 349.7V349.71Z" fill="white"/>
                    <path d="M2151.5 284.81C2151.85 285.49 2152.36 286.45 2151.39 287.2C2150.93 287.55 2150.06 288.22 2149.6 287.65C2149.1 287.04 2148.03 286.77 2148.13 285.73C2148.2 285.01 2148.52 284.27 2148.42 283.59C2148.33 282.96 2148.47 282.77 2148.99 282.54C2149.74 282.22 2150.33 282.49 2150.74 283.02C2151.08 283.47 2151.21 284.08 2151.51 284.81H2151.5Z" fill="white"/>
                    <path d="M2021.75 356.11C2022.06 356.81 2021.75 357.65 2021.07 357.98C2020.84 358.09 2018.1 357.74 2017.94 357.58C2017.57 357.22 2017.89 355.47 2017.96 355.52C2018.97 356.21 2018.99 354.71 2019.67 354.8C2020.32 354.89 2021.55 355.65 2021.74 356.11H2021.75Z" fill="white"/>
                    <path d="M2122.16 496.92C2122.54 496.78 2123.07 496.44 2123.22 496.8C2123.5 497.46 2124.1 498.06 2123.74 498.94C2123.54 499.43 2123.03 499.56 2122.86 499.96C2122.58 500.61 2122.28 500.83 2121.56 500.57C2120.78 500.29 2120.42 499.68 2120.58 499.06C2120.81 498.19 2120.99 497.12 2122.16 496.92Z" fill="white"/>
                    <path d="M2165.97 405.78C2165.77 405.2 2165.18 404.59 2166.27 404.19C2166.52 404.1 2166.56 403.51 2166.73 403.16C2166.92 402.77 2167.15 402.39 2167.36 402.01C2167.67 402.58 2168.23 403.17 2168.21 403.73C2168.18 404.45 2167.66 405.15 2167.4 405.87C2167.26 406.27 2167.05 406.61 2166.63 406.6C2166.17 406.6 2166.11 406.12 2165.96 405.78H2165.97Z" fill="white"/>
                    <path d="M2026.11 473.98C2026.04 473.8 2025.94 473.63 2025.9 473.45C2025.79 473.04 2025.28 472.6 2025.83 472.21C2026.4 471.81 2027.02 471.77 2027.67 472.15C2028.22 472.47 2028.13 472.83 2027.97 473.32C2027.87 473.64 2028.04 474.04 2027.94 474.36C2027.81 474.78 2027.74 475.34 2027.16 475.42C2026.47 475.52 2026.54 474.86 2026.33 474.5C2026.23 474.34 2026.18 474.15 2026.11 473.98Z" fill="white"/>
                    <path d="M2024.43 345.86C2023.55 345.75 2022.59 345.91 2021.79 345.37C2021.61 345.25 2021.46 344.91 2021.63 344.6C2021.85 344.2 2021.86 343.54 2022.44 343.55C2023.15 343.55 2023.87 343.72 2024.57 343.89C2025.4 344.09 2024.52 344.62 2024.73 344.97C2024.94 345.34 2024.94 345.69 2024.43 345.87V345.86Z" fill="white"/>
                    <path d="M2186.71 448.48C2186.43 448.03 2186.47 447.66 2187.08 447.45C2188.02 447.12 2188.75 447.39 2189.13 448.2C2189.47 448.92 2189.18 449.51 2188.68 449.92C2188.12 450.39 2187.17 449.68 2186.71 448.48Z" fill="white"/>
                    <path d="M2272.25 466.08C2272.14 465.81 2271.98 465.55 2271.93 465.27C2271.81 464.62 2272.46 464.12 2273.07 464.37C2273.43 464.52 2273.83 464.61 2274.13 464.83C2274.52 465.12 2274.77 465.64 2274.64 466.06C2274.49 466.54 2273.78 466.71 2273.67 467.3C2273.2 467.02 2272.49 467.07 2272.36 466.34C2272.34 466.25 2272.29 466.16 2272.25 466.07V466.08Z" fill="white"/>
                    <path d="M2051.83 392.34C2051.7 392.66 2051.57 392.92 2051.48 393.19C2051.25 393.88 2050.21 393.58 2050.06 394.41C2049.98 394.85 2049.57 395.01 2049.19 394.8C2048.82 394.6 2048.79 394.23 2048.97 393.83C2049.21 393.31 2049.39 392.76 2049.65 392.25C2049.78 392.01 2049.67 391.41 2050.29 391.7C2050.8 391.93 2051.32 392.13 2051.83 392.35V392.34Z" fill="white"/>
                    <path d="M2080.25 275.51C2080.85 275.36 2081.74 276.03 2081.88 276.72C2081.93 277 2080.92 278.48 2080.58 278.52C2079.72 278.62 2079.83 277.81 2079.65 277.37C2079.32 276.55 2079.68 275.65 2080.25 275.51Z" fill="white"/>
                    <path d="M2146.4 322.13C2145.89 322.44 2145.5 322.46 2145.28 321.83C2145 321.06 2145.22 320.11 2145.76 319.89C2146.3 319.67 2147.15 320.2 2147.45 320.96C2147.9 322.08 2146.77 321.8 2146.4 322.14V322.13Z" fill="white"/>
                    <path d="M2153.42 323.2C2153.34 323.73 2153.27 324.31 2152.72 324.58C2152.12 324.87 2151.06 324.07 2150.92 323.29C2150.83 322.77 2151.19 322.66 2151.55 322.55C2152.37 322.3 2153.26 322.61 2153.41 323.2H2153.42Z" fill="white"/>
                    <path d="M2075 365.82C2074.86 365.47 2074.8 365.28 2074.71 365.12C2074.36 364.5 2074.88 364.06 2075.01 363.54C2075.47 363.82 2076.15 363.76 2076.34 364.45C2076.39 364.63 2076.47 364.81 2076.56 364.98C2076.85 365.55 2076.93 365.96 2076.14 366.27C2075.38 366.57 2075.09 366.34 2075.01 365.82H2075Z" fill="white"/>
                    <path d="M2235.71 318.82C2235.1 319.12 2234.8 318.76 2234.81 318.39C2234.83 317.67 2234.99 316.95 2235.15 316.24C2235.3 315.58 2235.69 316.05 2235.98 316.12C2237.23 316.43 2236.24 317.38 2236.49 318.01C2236.77 318.73 2235.92 318.59 2235.72 318.82H2235.71Z" fill="white"/>
                    <path d="M2101.49 342.74C2101.93 343.42 2101.64 344.03 2101.16 344.46C2100.8 344.78 2099.34 344.13 2099.38 343.73C2099.47 342.96 2099.91 342.47 2100.68 342.23C2101.22 342.06 2101.34 342.39 2101.49 342.74Z" fill="white"/>
                    <path d="M2075.2 518.16C2074.64 518.28 2074.17 517.99 2073.77 517.63C2073.53 517.41 2074.36 516.05 2074.89 515.77C2075.37 515.52 2075.56 515.84 2075.7 516.21C2075.98 516.96 2075.75 517.92 2075.21 518.16H2075.2Z" fill="white"/>
                    <path d="M2061.27 335.11C2061.57 335.08 2061.91 335.16 2061.87 335.5C2061.79 336.31 2061.92 337.15 2061.55 337.93C2061.39 338.26 2061.1 338.4 2060.89 338.38C2060.68 338.35 2060.35 338.01 2060.36 337.83C2060.41 337.11 2060.56 336.39 2060.69 335.67C2060.75 335.36 2060.96 335.18 2061.27 335.1V335.11Z" fill="white"/>
                    <path d="M2038.27 313.41C2038.41 313.68 2038.6 314.02 2038.17 314.31C2037.8 314.56 2037.48 314.31 2037.12 314.22C2036.41 314.06 2036.34 313.42 2036.22 312.93C2036.15 312.64 2036.43 311.98 2036.61 311.95C2036.99 311.9 2037.48 312.07 2037.79 312.33C2038.05 312.54 2038.1 313 2038.27 313.41Z" fill="white"/>
                    <path d="M2195.22 285.61C2195.63 285.36 2195.99 285.4 2196.2 286.01C2196.46 286.77 2196.24 287.71 2195.72 287.94C2194.83 288.33 2194.98 286.95 2194.2 287.03C2194.07 287.04 2194.55 285.95 2195.22 285.61Z" fill="white"/>
                    <path d="M2076.37 421.25C2076.32 421.42 2076.33 421.66 2076.22 421.74C2075.6 422.19 2073.99 421.72 2073.77 421.03C2073.69 420.79 2073.65 420.42 2073.77 420.28C2073.91 420.13 2074.29 420.09 2074.52 420.15C2075.56 420.42 2076.17 420.81 2076.36 421.25H2076.37Z" fill="white"/>
                    <path d="M2153.29 497.99C2153.55 497.88 2153.81 497.72 2154.08 497.67C2154.79 497.54 2154.72 498.2 2154.92 498.57C2155.19 499.05 2154.85 499.24 2154.48 499.39C2154.14 499.52 2153.83 499.73 2153.42 499.52C2153 499.31 2152.45 499.22 2152.46 498.64C2152.46 498.19 2152.95 498.14 2153.28 497.99H2153.29Z" fill="white"/>
                    <path d="M2063.81 337.13C2063.69 336.54 2064.09 336.43 2064.44 336.33C2065.28 336.08 2066.14 336.39 2066.29 336.99C2066.41 337.49 2066 337.61 2065.65 337.72C2064.81 337.97 2063.97 337.65 2063.82 337.13H2063.81Z" fill="white"/>
                    <path d="M2192.51 277.88C2193.12 278.16 2193.76 278.28 2193.99 279.06C2194.18 279.71 2193.88 279.85 2193.41 280.06C2192.9 280.29 2192.62 280.27 2192.31 279.71C2191.9 278.98 2192.36 278.47 2192.51 277.87V277.88Z" fill="white"/>
                    <path d="M2201.43 289.52C2201.4 289.69 2201.43 289.97 2201.32 290.03C2201 290.22 2200.6 290.5 2200.29 290.44C2199.78 290.34 2199.14 290.27 2198.95 289.62C2198.81 289.11 2199.21 288.99 2199.57 288.89C2200.42 288.64 2201.23 288.93 2201.43 289.53V289.52Z" fill="white"/>
                    <path d="M2122.71 523.04C2122.85 523.41 2123 523.81 2122.47 523.93C2121.78 524.09 2121.12 523.88 2120.92 523.19C2120.82 522.82 2121.15 522.34 2121.29 521.9C2121.86 522.17 2122.59 522.23 2122.71 523.03V523.04Z" fill="white"/>
                    <path d="M2243.28 427.47C2242.62 427.69 2241.65 427.23 2241.54 426.75C2241.43 426.25 2241.83 426.18 2242.16 426.02C2242.82 425.7 2243.42 425.89 2243.75 426.43C2243.85 426.59 2243.62 427.33 2243.27 427.48L2243.28 427.47Z" fill="white"/>
                    <path d="M2116.01 327.4C2116.19 327.85 2116.37 328.33 2115.85 328.38C2115.21 328.44 2114.55 328.27 2113.89 328.19C2114.1 327.56 2114.34 327.01 2115.11 326.8C2115.84 326.6 2115.85 327.12 2116.01 327.4Z" fill="white"/>
                    <path d="M2258.96 355.66C2259.34 356.36 2259.01 356.55 2258.51 356.81C2257.74 357.2 2257.53 356.8 2257.28 356.2C2257.02 355.59 2257.3 355.4 2257.8 355.16C2258.54 354.8 2258.86 355.07 2258.95 355.66H2258.96Z" fill="white"/>
                    <path d="M2103.78 325.18C2103.28 325.29 2102.66 325.96 2102.42 325C2102.29 324.48 2102.51 323.88 2103.05 323.59C2103.94 323.11 2104.08 324.21 2104.49 324.51C2105 324.89 2103.98 324.88 2103.79 325.18H2103.78Z" fill="white"/>
                    <path d="M2019.6 307.96C2019.71 308.22 2019.86 308.48 2019.91 308.75C2020.01 309.25 2019.61 309.36 2019.26 309.47C2019.01 309.55 2018.76 309.81 2018.48 309.58C2018.16 309.32 2018.09 308.99 2018.28 308.61C2018.37 308.44 2018.42 308.26 2018.5 308.09C2018.65 307.76 2018.77 307.19 2018.97 307.16C2019.4 307.1 2019.42 307.65 2019.59 307.96H2019.6Z" fill="white"/>
                    <path d="M2229.4 481.17C2230.09 481.11 2230.05 481.72 2230.23 482.1C2230.55 482.81 2229.89 482.83 2229.51 483.01C2229.16 483.18 2228.78 483.3 2228.62 482.76C2228.46 482.21 2228.92 481.29 2229.4 481.17Z" fill="white"/>
                    <path d="M2176.42 336.94C2175.94 337.11 2175.57 337.26 2175.41 336.72C2175.25 336.17 2175.7 335.23 2176.19 335.13C2176.69 335.03 2176.74 335.47 2176.93 335.78C2177.38 336.55 2176.91 336.78 2176.42 336.93V336.94Z" fill="white"/>
                    <path d="M2066.4 313.38C2066.6 313.36 2066.97 313.2 2067.1 313.32C2067.45 313.63 2067.06 314 2066.9 314.21C2066.66 314.53 2066.97 315.55 2065.97 315.06C2065.64 314.9 2065.23 314.82 2065.18 314.38C2065.08 313.49 2065.98 313.79 2066.39 313.38H2066.4Z" fill="white"/>
                    <path d="M2042.5 461.67C2042.4 462.11 2042.46 462.95 2041.88 462.8C2041.39 462.67 2040.79 463.22 2040.47 462.61C2040.28 462.24 2041.37 461.17 2041.93 461.16C2042.22 461.16 2042.43 461.35 2042.5 461.67Z" fill="white"/>
                    <path d="M2243.09 364.4C2243.09 364.4 2243.52 364.7 2243.86 365.02C2244.01 365.16 2243.28 366.27 2243.01 366.32C2242.3 366.44 2242.38 365.8 2242.17 365.44C2241.92 365 2242.17 364.77 2243.09 364.4Z" fill="white"/>
                    <path d="M2251.65 397.67C2251.72 398.36 2251.1 398.29 2250.74 398.5C2250.28 398.75 2249.96 398.46 2249.96 398.08C2249.96 397.65 2250.16 397.19 2250.36 396.79C2250.43 396.64 2251.61 397.42 2251.66 397.68L2251.65 397.67Z" fill="white"/>
                    <path d="M2160.45 549.94C2160.02 550.23 2159.75 550.09 2159.64 549.83C2159.36 549.14 2159.64 548.59 2160.22 548.18C2160.51 547.97 2160.79 548.11 2160.91 548.37C2161.22 549.08 2160.87 549.59 2160.45 549.94Z" fill="white"/>
                    <path d="M2054.52 417.43C2055.12 417.28 2055.7 417.47 2055.88 417.93C2055.97 418.16 2055.77 418.84 2055.24 419.05C2054.52 419.32 2054.52 418.7 2054.33 418.33C2054.15 417.98 2054.07 417.63 2054.52 417.43Z" fill="white"/>
                    <path d="M2240.27 496.89C2240.03 497.03 2238.7 496.73 2238.76 496.57C2238.91 496.15 2239.09 495.69 2239.4 495.39C2239.69 495.11 2240.11 495.19 2240.24 495.68C2240.35 496.08 2240.85 496.48 2240.27 496.9V496.89Z" fill="white"/>
                    <path d="M2050.76 412.82C2050.8 413.36 2050.69 413.97 2049.97 414.25C2049.71 414.35 2049.41 414.25 2049.35 413.9C2049.24 413.2 2049.43 412.62 2050.11 412.33C2050.37 412.22 2050.66 412.32 2050.76 412.82Z" fill="white"/>
                    <path d="M1986.56 423.94C1986.79 424.42 1986.52 424.74 1986.14 424.74C1985.71 424.74 1985.25 424.54 1984.85 424.34C1984.7 424.27 1985.48 423.09 1985.73 423.05C1986.09 422.98 1986.41 423.33 1986.56 423.94Z" fill="white"/>
                    <path d="M2107.06 328.67C2107.21 329.05 2107.36 329.42 2106.84 329.59C2106.27 329.77 2105.7 329.56 2105.51 329.1C2105.42 328.87 2105.62 328.19 2106.15 327.98C2106.87 327.7 2106.85 328.34 2107.06 328.67Z" fill="white"/>
                    <path d="M2015.53 339.64C2015.48 340.18 2015.38 340.68 2014.86 340.97C2014.55 341.14 2014.26 341.02 2014.19 340.75C2014.07 340.3 2013.37 339.78 2014.21 339.35C2014.72 339.08 2015.07 339.59 2015.53 339.65V339.64Z" fill="white"/>
                    <path d="M2102.11 341.17C2102.07 341.34 2102.03 341.51 2101.98 341.68C2101.2 341.37 2100.45 340.93 2099.55 341.09C2099.51 341.09 2099.36 340.76 2099.35 340.57C2099.35 340.47 2099.6 340.25 2099.64 340.27C2100.32 340.64 2101.17 340.14 2101.8 340.71C2101.93 340.83 2102.01 341.01 2102.11 341.17Z" fill="white"/>
                    <path d="M2098.39 343.79C2097.85 343.98 2097.85 343.17 2097.73 342.74C2097.47 341.89 2098.35 342.03 2098.76 341.79C2098.88 341.72 2099.48 341.81 2099.1 342.13C2098.53 342.61 2099.76 343.39 2098.4 343.79H2098.39Z" fill="white"/>
                    <path d="M2176.44 506.77C2177.04 506.4 2177.47 506.94 2177.98 507.06C2177.85 507.4 2177.8 507.94 2177.58 508.03C2177.08 508.22 2176.6 507.96 2176.29 507.48C2176.1 507.19 2176.23 506.97 2176.44 506.77Z" fill="white"/>
                    <path d="M2236.77 361.96C2236.37 362.06 2235.93 362.51 2235.61 361.79C2235.38 361.28 2235.71 361.11 2236.08 360.98C2236.48 360.84 2236.93 360.42 2237.24 361.13C2237.47 361.65 2237.13 361.79 2236.76 361.96H2236.77Z" fill="white"/>
                    <path d="M2104.17 534.96C2103.99 535.9 2103.51 536.29 2102.82 536.44C2102.66 536.48 2102.48 536.38 2102.3 536.35C2102.37 536.18 2102.48 536.01 2102.51 535.83C2102.62 535.14 2103.2 535.01 2103.72 534.84C2103.88 534.79 2104.11 534.95 2104.16 534.96H2104.17Z" fill="white"/>
                    <path d="M2265.86 355.44C2265.92 355.57 2265.62 355.77 2265.23 355.9C2264.83 356.03 2264.38 356.48 2264.06 355.76C2263.83 355.26 2264.13 355.07 2264.51 354.94C2264.91 354.8 2265.37 354.38 2265.85 355.44H2265.86Z" fill="white"/>
                    <path d="M2204.35 349.32C2204.22 349.3 2203.99 349.32 2203.94 349.24C2203.63 348.66 2203.36 348.09 2203.79 347.41C2203.97 347.13 2204.11 347 2204.35 347.22C2204.73 347.57 2204.72 349.02 2204.35 349.31V349.32Z" fill="white"/>
                    <path d="M2196.13 462.18C2196.26 463.1 2195.37 462.84 2194.96 463.1C2194.77 463.22 2194.46 462.88 2194.42 462.59C2194.33 461.72 2195.15 461.88 2195.58 461.66C2195.78 461.56 2196.07 461.86 2196.12 462.18H2196.13Z" fill="white"/>
                    <path d="M2139.41 364.32C2139.54 365.24 2138.65 364.98 2138.24 365.24C2138.05 365.36 2137.74 365.02 2137.7 364.73C2137.61 363.86 2138.43 364.02 2138.86 363.8C2139.06 363.7 2139.35 364 2139.4 364.32H2139.41Z" fill="white"/>
                    <path d="M2065.72 471.97C2065.78 472.1 2065.48 472.3 2065.09 472.43C2064.69 472.56 2064.24 473.01 2063.92 472.29C2063.69 471.79 2063.99 471.6 2064.37 471.47C2064.77 471.33 2065.23 470.91 2065.71 471.97H2065.72Z" fill="white"/>
                    <path d="M2121.12 289.71C2120.65 289.33 2120.16 288.93 2119.68 288.53C2119.66 288.51 2119.72 288.33 2119.79 288.28C2120.13 288.05 2120.46 288.07 2120.83 288.25C2121.25 288.45 2121.72 288.59 2121.62 289.19C2121.6 289.33 2121.39 289.44 2121.12 289.71Z" fill="white"/>
                    <path d="M2081.68 326.07C2082.03 325.89 2082.39 325.69 2082.65 326.15C2082.99 326.75 2082.62 326.98 2082.11 327.15C2081.76 327.27 2081.4 327.52 2081.14 327.05C2080.81 326.46 2081.15 326.2 2081.68 326.06V326.07Z" fill="white"/>
                    <path d="M2048.55 374.97C2048.6 375.51 2048.57 375.78 2048.21 375.84C2047.51 375.95 2046.94 375.76 2046.64 375.08C2046.58 374.95 2046.69 374.74 2046.73 374.57C2047.36 374.71 2048 374.86 2048.56 374.98L2048.55 374.97Z" fill="white"/>
                    <path d="M2159.46 341.01C2159.72 341.46 2159.77 341.87 2159.54 341.94C2159.07 342.09 2158.6 342.78 2158.06 342.1C2157.87 341.87 2157.84 341.46 2158.08 341.35C2158.53 341.15 2159.02 340.65 2159.46 341.01Z" fill="white"/>
                    <path d="M2079.93 485.09C2080.07 485.47 2080.21 485.81 2079.68 486.01C2078.95 486.28 2078.94 485.67 2078.75 485.29C2078.58 484.94 2078.47 484.59 2079 484.38C2079.73 484.1 2079.72 484.73 2079.93 485.08V485.09Z" fill="white"/>
                    <path d="M2161.64 451.63C2162.56 451.5 2162.3 452.39 2162.56 452.8C2162.68 452.99 2162.34 453.3 2162.05 453.34C2161.18 453.43 2161.34 452.61 2161.12 452.18C2161.02 451.98 2161.32 451.69 2161.64 451.64V451.63Z" fill="white"/>
                    <path d="M2042.63 366.15C2042.98 365.97 2043.34 365.77 2043.6 366.23C2043.94 366.83 2043.57 367.06 2043.06 367.23C2042.71 367.35 2042.35 367.6 2042.09 367.13C2041.76 366.54 2042.1 366.28 2042.63 366.14V366.15Z" fill="white"/>
                    <path d="M2186.84 291.63C2187.43 291.32 2187.66 291.44 2187.8 291.71C2188.12 292.32 2187.73 292.54 2187.24 292.69C2186.99 292.77 2186.51 293.03 2186.51 292.8C2186.5 292.33 2186.77 291.85 2186.84 291.63Z" fill="white"/>
                    <path d="M2105.84 511.16C2105.84 511.32 2105.82 511.58 2105.8 511.84C2105.32 511.61 2104.67 511.65 2104.49 510.99C2104.41 510.71 2104.5 510.48 2104.84 510.35C2105.55 510.08 2105.57 510.68 2105.84 511.16Z" fill="white"/>
                    <path d="M1997.02 330.01C1996.8 330.15 1996.54 330.03 1996.4 329.78C1996.21 329.45 1996.1 329.03 1996.63 328.9C1997 328.8 1997.47 328.21 1997.74 328.83C1997.84 329.05 1997.62 329.75 1997.02 330.01Z" fill="white"/>
                    <path d="M2161.32 256.5C2161.65 257.09 2161.13 257.53 2160.99 258.04C2160.81 257.85 2160.56 257.69 2160.46 257.46C2160.3 257.08 2159.89 256.63 2160.59 256.3C2160.91 256.15 2161.11 256.26 2161.32 256.49V256.5Z" fill="white"/>
                    <path d="M2012.51 358.96C2012.18 359.19 2012.58 359.04 2012.26 358.84C2012.09 358.73 2011.93 358.34 2012 358.16C2012.18 357.67 2012.21 357.01 2012.93 356.92C2013.01 356.91 2013.17 356.97 2013.18 357.01C2013.26 357.76 2012.69 358.3 2012.52 358.96H2012.51Z" fill="white"/>
                    <path d="M2060.17 385.23C2059.93 385.71 2059.91 386.4 2059.19 386.44C2059.02 386.44 2058.72 386.11 2058.67 385.9C2058.53 385.21 2059.2 385.29 2059.56 385.07C2059.8 384.92 2060.04 384.9 2060.17 385.23Z" fill="white"/>
                    <path d="M2151.38 405.62C2151.45 405.8 2151.57 405.97 2151.58 406.15C2151.58 406.27 2151.42 406.49 2151.33 406.49C2151.01 406.49 2150.31 404.84 2150.51 404.56C2150.56 404.49 2150.89 404.5 2150.92 404.56C2151.1 404.89 2151.23 405.26 2151.37 405.61L2151.38 405.62Z" fill="white"/>
                    <path d="M2003.4 461.78C2003.66 462.32 2003.37 462.54 2002.98 462.65C2002.7 462.73 2002.47 462.64 2002.34 462.3C2002.14 461.8 2002.41 461.59 2002.79 461.49C2003.06 461.42 2003.36 461.45 2003.4 461.78Z" fill="white"/>
                    <path d="M2175.42 269.28C2175.45 269.59 2175.4 269.81 2175.06 269.94C2174.56 270.14 2174.36 269.87 2174.25 269.49C2174.17 269.21 2174.26 268.98 2174.6 268.85C2175.1 268.65 2175.27 268.93 2175.42 269.29V269.28Z" fill="white"/>
                    <path d="M2227.51 477.02C2227.54 477.33 2227.49 477.55 2227.15 477.68C2226.65 477.88 2226.45 477.61 2226.34 477.23C2226.26 476.95 2226.35 476.72 2226.69 476.59C2227.19 476.39 2227.36 476.67 2227.51 477.03V477.02Z" fill="white"/>
                    <path d="M2129.98 529.69C2129.64 529.82 2129.32 529.9 2129.11 529.4C2128.97 529.07 2129.06 528.84 2129.32 528.7C2129.67 528.51 2130.01 528.46 2130.22 528.96C2130.36 529.29 2130.25 529.48 2129.98 529.69Z" fill="white"/>
                    <path d="M2249.07 341.82C2249.61 341.84 2250.09 341.97 2250.37 342.46C2250.44 342.59 2250.4 342.95 2250.39 342.95C2249.85 343 2249.37 342.86 2249.09 342.35C2249.02 342.22 2249.07 342.01 2249.07 341.81V341.82Z" fill="white"/>
                    <path d="M2250.15 446.13C2250.34 446.42 2250.2 446.66 2249.94 446.81C2249.59 447 2249.25 447.05 2249.04 446.55C2248.9 446.22 2248.99 445.99 2249.25 445.85C2249.6 445.65 2249.97 445.61 2250.15 446.13Z" fill="white"/>
                    <path d="M2183.84 351.05C2183.87 351.36 2183.82 351.58 2183.48 351.71C2182.98 351.91 2182.78 351.64 2182.67 351.26C2182.59 350.98 2182.68 350.75 2183.02 350.62C2183.52 350.42 2183.69 350.7 2183.84 351.06V351.05Z" fill="white"/>
                    <path d="M1990.03 398.62C1990.07 398.98 1990.01 399.2 1989.68 399.33C1989.18 399.53 1988.98 399.26 1988.87 398.88C1988.79 398.6 1988.89 398.38 1989.22 398.24C1989.73 398.02 1989.87 398.35 1990.04 398.63L1990.03 398.62Z" fill="white"/>
                    <path d="M2009.83 385.34C2009.33 385.58 2009.11 385.29 2009 384.9C2008.92 384.62 2009.01 384.39 2009.35 384.26C2009.85 384.06 2010.05 384.33 2010.16 384.71C2010.24 384.99 2010.16 385.27 2009.83 385.34Z" fill="white"/>
                    <path d="M2284.98 360.56C2284.85 360.21 2284.71 359.84 2285.23 359.68C2285.39 359.63 2285.57 359.66 2285.75 359.65C2285.64 360.05 2285.53 360.45 2285.42 360.86C2285.27 360.76 2285.13 360.66 2284.98 360.56Z" fill="white"/>
                    <path d="M2246.87 396.79C2247 397.14 2247.14 397.51 2246.62 397.67C2246.46 397.72 2246.28 397.69 2246.1 397.7C2246.21 397.3 2246.32 396.9 2246.43 396.49C2246.58 396.59 2246.72 396.69 2246.87 396.79Z" fill="white"/>
                    <path d="M2165.85 289.24C2165.71 289.61 2165.57 289.95 2165.44 290.3C2165.26 290.11 2164.99 289.96 2164.92 289.74C2164.76 289.25 2165.17 289.11 2165.53 289.03C2165.63 289.01 2165.77 289.18 2165.86 289.25L2165.85 289.24Z" fill="white"/>
                    <path d="M2154.01 454.78C2153.78 454.78 2153.49 454.85 2153.46 454.78C2153.25 454.31 2153.69 454 2153.78 453.59C2153.92 453.69 2154.12 453.76 2154.2 453.9C2154.4 454.25 2154.43 454.59 2154.01 454.78Z" fill="white"/>
                    <path d="M2163.79 546.31C2163.76 546.45 2163.78 546.67 2163.69 546.73C2163.38 546.92 2162.99 547.11 2162.76 546.64C2162.66 546.43 2162.74 546.13 2162.74 545.87C2163.09 546.01 2163.43 546.16 2163.79 546.31Z" fill="white"/>
                    <path d="M2171.32 518.34C2171.8 518.12 2171.99 518.44 2172.1 518.81C2172.14 518.96 2172.04 519.15 2172.01 519.32C2171.65 519.11 2171.29 518.9 2170.93 518.69C2171.06 518.57 2171.19 518.46 2171.32 518.33V518.34Z" fill="white"/>
                    <path d="M2164.86 312.93C2164.85 313.01 2164.88 313.23 2164.79 313.28C2164.48 313.47 2164.09 313.66 2163.86 313.21C2163.75 313 2163.83 312.7 2163.83 312.44C2164.16 312.6 2164.5 312.75 2164.86 312.92V312.93Z" fill="white"/>
                    <path d="M2177.71 348.42C2177.93 348.9 2177.61 349.09 2177.24 349.2C2177.09 349.24 2176.9 349.14 2176.73 349.11C2176.94 348.75 2177.15 348.39 2177.36 348.03C2177.48 348.16 2177.59 348.29 2177.72 348.42H2177.71Z" fill="white"/>
                    <path d="M2218.53 510.38C2218.5 510.56 2218.48 510.73 2218.45 510.9C2218.08 510.71 2217.71 510.52 2217.35 510.33C2217.47 510.21 2217.58 510.05 2217.72 509.97C2218.17 509.72 2218.38 510.01 2218.53 510.38Z" fill="white"/>
                    <path d="M2022.44 399.08C2022.92 398.86 2023.11 399.18 2023.22 399.55C2023.26 399.7 2023.16 399.89 2023.13 400.06C2022.77 399.85 2022.41 399.64 2022.05 399.43C2022.18 399.31 2022.31 399.2 2022.44 399.07V399.08Z" fill="white"/>
                    <path d="M1986.3 416.45C1986.5 416.6 1986.69 416.67 1986.76 416.81C1986.95 417.17 1986.98 417.53 1986.48 417.67C1986.32 417.72 1986.14 417.67 1985.96 417.67C1986.07 417.27 1986.17 416.87 1986.29 416.45H1986.3Z" fill="white"/>
                    <path d="M2121.98 248.62C2121.93 249.01 2121.59 249.01 2121.34 249.1C2121.26 249.13 2121.01 248.93 2121.01 248.85C2121.02 248.48 2121.38 248.47 2121.63 248.39C2121.71 248.36 2121.86 248.54 2121.97 248.62H2121.98Z" fill="white"/>
                    <path d="M2142.53 263.66C2142.73 263.91 2142.94 264.09 2143.04 264.32C2143.1 264.45 2142.99 264.66 2142.96 264.83C2142.78 264.64 2142.55 264.48 2142.43 264.26C2142.36 264.14 2142.48 263.92 2142.53 263.65V263.66Z" fill="white"/>
                    <path d="M2045.63 501.68C2045.58 502.07 2045.24 502.07 2044.99 502.16C2044.91 502.19 2044.66 501.99 2044.66 501.91C2044.67 501.54 2045.03 501.53 2045.28 501.45C2045.36 501.42 2045.51 501.6 2045.62 501.68H2045.63Z" fill="white"/>
                    <path d="M2149.11 534.81C2148.86 535.01 2148.68 535.22 2148.45 535.32C2148.32 535.38 2148.11 535.27 2147.94 535.24C2148.13 535.06 2148.29 534.83 2148.51 534.71C2148.63 534.64 2148.85 534.76 2149.12 534.81H2149.11Z" fill="white"/>
                    <path d="M2151.85 533.27C2151.7 533.32 2151.46 533.44 2151.22 533.46C2150.93 533.49 2150.85 533.29 2151.08 533.11C2151.28 532.96 2151.54 532.85 2151.78 532.82C2152.07 532.78 2152.13 532.95 2151.85 533.27Z" fill="white"/>
                    <path d="M2044.79 519.32C2044.96 519.32 2045.24 519.25 2045.29 519.33C2045.47 519.62 2045.27 519.8 2044.99 519.86C2044.82 519.9 2044.54 519.93 2044.49 519.86C2044.31 519.57 2044.52 519.41 2044.79 519.33V519.32Z" fill="white"/>
                    <path d="M2173.59 275.59C2173.59 275.76 2173.66 276.04 2173.58 276.09C2173.29 276.27 2173.11 276.07 2173.05 275.79C2173.01 275.62 2172.98 275.34 2173.05 275.29C2173.34 275.11 2173.5 275.32 2173.58 275.59H2173.59Z" fill="white"/>
                    <path d="M2218.99 499.89C2219.19 500.14 2219.4 500.32 2219.5 500.55C2219.56 500.68 2219.45 500.89 2219.42 501.06C2219.24 500.87 2219.01 500.71 2218.89 500.49C2218.82 500.37 2218.94 500.15 2218.99 499.88V499.89Z" fill="white"/>
                    <path d="M2027.18 331.03C2027.16 331.16 2027.12 331.33 2027.09 331.5C2026.91 331.31 2026.68 331.15 2026.56 330.93C2026.49 330.81 2026.61 330.59 2026.64 330.42C2026.93 330.5 2027.12 330.68 2027.18 331.03Z" fill="white"/>
                    <path d="M2259.39 480.81C2259.14 480.94 2258.88 480.98 2258.8 480.64C2258.78 480.55 2259.01 480.37 2259.15 480.28C2259.39 480.13 2259.66 480.1 2259.73 480.44C2259.75 480.53 2259.51 480.68 2259.38 480.8L2259.39 480.81Z" fill="white"/>
                    <path d="M2041.19 368C2040.94 368.13 2040.68 368.17 2040.6 367.83C2040.58 367.74 2040.81 367.56 2040.95 367.47C2041.19 367.32 2041.46 367.29 2041.53 367.63C2041.55 367.72 2041.31 367.87 2041.18 367.99L2041.19 368Z" fill="white"/>
                    <path d="M2052.62 372.76C2052.37 372.89 2052.11 372.93 2052.03 372.59C2052.01 372.5 2052.24 372.32 2052.38 372.23C2052.62 372.08 2052.89 372.05 2052.96 372.39C2052.98 372.48 2052.74 372.63 2052.61 372.75L2052.62 372.76Z" fill="white"/>
                    <path d="M2096.82 384.15C2097.02 384.4 2097.23 384.58 2097.33 384.81C2097.39 384.94 2097.28 385.15 2097.25 385.32C2097.07 385.13 2096.84 384.97 2096.72 384.75C2096.65 384.63 2096.77 384.41 2096.82 384.14V384.15Z" fill="white"/>
                    <path d="M2165.32 302.91C2165.3 303.13 2165.28 303.39 2165.26 303.65C2165.11 303.55 2164.88 303.48 2164.84 303.35C2164.77 303.11 2164.82 302.83 2164.81 302.57C2164.96 302.67 2165.1 302.77 2165.32 302.91Z" fill="white"/>
                    <path d="M2176.69 395.1C2176.67 395.23 2176.63 395.4 2176.6 395.57C2176.42 395.38 2176.19 395.22 2176.07 395C2176 394.88 2176.12 394.66 2176.15 394.49C2176.44 394.57 2176.63 394.75 2176.69 395.1Z" fill="white"/>
                    <path d="M2220.85 384.84C2221.05 385.09 2221.26 385.27 2221.36 385.5C2221.42 385.63 2221.31 385.84 2221.28 386.01C2221.1 385.82 2220.87 385.66 2220.75 385.44C2220.68 385.32 2220.8 385.1 2220.85 384.83V384.84Z" fill="white"/>
                    <path d="M2289.95 368.23C2289.9 368.62 2289.56 368.62 2289.31 368.71C2289.23 368.74 2288.98 368.54 2288.98 368.46C2288.99 368.09 2289.35 368.08 2289.6 368C2289.68 367.97 2289.83 368.15 2289.94 368.23H2289.95Z" fill="white"/>
                    <path d="M2247.89 416.09C2247.64 416.22 2247.38 416.26 2247.3 415.92C2247.28 415.83 2247.51 415.65 2247.65 415.56C2247.89 415.41 2248.16 415.38 2248.23 415.72C2248.25 415.81 2248.01 415.96 2247.88 416.08L2247.89 416.09Z" fill="white"/>
                    <path d="M2055.08 486.03C2055.25 486.03 2055.53 485.96 2055.58 486.04C2055.76 486.33 2055.56 486.51 2055.28 486.57C2055.11 486.61 2054.83 486.64 2054.78 486.57C2054.6 486.28 2054.81 486.12 2055.08 486.04V486.03Z" fill="white"/>
                    <path d="M2136.2 436.22C2136.2 436.39 2136.27 436.67 2136.19 436.72C2135.9 436.9 2135.72 436.7 2135.66 436.42C2135.62 436.25 2135.59 435.97 2135.66 435.92C2135.95 435.74 2136.11 435.95 2136.19 436.22H2136.2Z" fill="white"/>
                    <path d="M2142.79 449.91C2142.9 450.01 2143.03 450.09 2143.11 450.2C2143.14 450.24 2143.05 450.36 2143.01 450.45C2142.9 450.35 2142.77 450.27 2142.69 450.16C2142.66 450.12 2142.75 450 2142.79 449.91Z" fill="white"/>
                    <path d="M2225.23 518.24C2225.34 518.34 2225.47 518.42 2225.55 518.53C2225.58 518.57 2225.49 518.69 2225.45 518.78C2225.34 518.68 2225.21 518.6 2225.13 518.49C2225.1 518.45 2225.19 518.33 2225.23 518.24Z" fill="white"/>
                    <path d="M2064.52 371.24C2064.5 371.38 2064.49 371.52 2064.47 371.67C2064.39 371.63 2064.24 371.61 2064.23 371.56C2064.21 371.42 2064.23 371.27 2064.25 371.13C2064.33 371.17 2064.42 371.2 2064.52 371.24Z" fill="white"/>
                    <path d="M2019.08 375.44C2019.12 375.34 2019.16 375.25 2019.19 375.17C2019.3 375.27 2019.43 375.35 2019.51 375.46C2019.54 375.5 2019.45 375.62 2019.41 375.71C2019.3 375.62 2019.18 375.53 2019.07 375.44H2019.08Z" fill="white"/>
                    <path d="M2097.06 341.74C2096.92 341.72 2096.78 341.71 2096.63 341.69C2096.67 341.61 2096.69 341.46 2096.74 341.45C2096.88 341.43 2097.03 341.45 2097.17 341.47C2097.13 341.55 2097.1 341.64 2097.06 341.74Z" fill="white"/>
                    <path d="M2138.46 360.45C2138.36 360.56 2138.28 360.69 2138.17 360.77C2138.13 360.8 2138.01 360.71 2137.92 360.67C2138.02 360.56 2138.1 360.43 2138.21 360.35C2138.25 360.32 2138.37 360.41 2138.46 360.45Z" fill="white"/>
                    <path d="M2184.24 346.88C2184.14 346.84 2184.05 346.8 2183.97 346.77C2184.07 346.66 2184.15 346.53 2184.26 346.45C2184.3 346.42 2184.42 346.51 2184.51 346.55C2184.42 346.66 2184.33 346.78 2184.24 346.89V346.88Z" fill="white"/>
                    <path d="M2180.55 288.1C2180.53 288.24 2180.52 288.38 2180.5 288.53C2180.42 288.49 2180.27 288.47 2180.26 288.42C2180.24 288.28 2180.26 288.13 2180.28 287.99C2180.36 288.03 2180.45 288.06 2180.55 288.1Z" fill="white"/>
                    <path d="M2078.65 347.3C2078.61 347.4 2078.57 347.49 2078.54 347.57C2078.43 347.47 2078.3 347.39 2078.22 347.28C2078.19 347.24 2078.28 347.12 2078.32 347.03C2078.43 347.12 2078.55 347.21 2078.66 347.3H2078.65Z" fill="white"/>
                    <path d="M2137.3 391.15C2137.34 391.05 2137.38 390.96 2137.41 390.88C2137.52 390.98 2137.65 391.06 2137.73 391.17C2137.76 391.21 2137.67 391.33 2137.63 391.42C2137.52 391.33 2137.4 391.24 2137.29 391.15H2137.3Z" fill="white"/>
                    <path d="M2272.02 348.12C2272.11 348.16 2272.26 348.2 2272.26 348.22C2272.24 348.36 2272.23 348.54 2272.15 348.64C2271.97 348.84 2271.81 348.78 2271.83 348.51C2271.83 348.38 2271.96 348.25 2272.03 348.12H2272.02Z" fill="white"/>
                    <path d="M2272.01 348.98C2271.94 349.11 2271.87 349.33 2271.79 349.34C2271.55 349.36 2271.45 349.21 2271.56 349.06C2271.65 348.93 2271.79 348.84 2271.91 348.73C2271.94 348.81 2271.98 348.89 2272.01 348.97V348.98Z" fill="white"/>
                    <path d="M2124.1 472.98C2124.08 473.12 2124.07 473.26 2124.05 473.41C2123.97 473.37 2123.82 473.35 2123.81 473.3C2123.79 473.16 2123.81 473.01 2123.83 472.87C2123.91 472.91 2124 472.94 2124.1 472.98Z" fill="white"/>
                    <path d="M2239.45 270.15C2239.43 270.29 2239.42 270.43 2239.4 270.58C2239.32 270.54 2239.17 270.52 2239.16 270.47C2239.14 270.33 2239.16 270.18 2239.18 270.04C2239.26 270.08 2239.35 270.11 2239.45 270.15Z" fill="white"/>
                    <path d="M2279.47 350.45C2279.43 350.55 2279.39 350.64 2279.36 350.72C2279.25 350.62 2279.12 350.54 2279.04 350.43C2279.01 350.39 2279.1 350.27 2279.14 350.18C2279.25 350.27 2279.37 350.36 2279.48 350.45H2279.47Z" fill="white"/>
                    <path d="M2197.25 277.61C2197.27 277.47 2197.28 277.33 2197.3 277.18C2197.38 277.22 2197.53 277.24 2197.54 277.29C2197.56 277.43 2197.54 277.58 2197.52 277.72C2197.44 277.68 2197.35 277.65 2197.25 277.61Z" fill="white"/>
                    <path d="M2204.88 420.77C2204.86 420.91 2204.85 421.05 2204.83 421.2C2204.75 421.16 2204.6 421.14 2204.59 421.09C2204.57 420.95 2204.59 420.8 2204.61 420.66C2204.69 420.7 2204.78 420.73 2204.88 420.77Z" fill="white"/>
                    <path d="M2116.72 317.86C2116.62 317.82 2116.53 317.78 2116.45 317.75C2116.55 317.64 2116.63 317.51 2116.74 317.43C2116.78 317.4 2116.9 317.49 2116.99 317.53C2116.9 317.64 2116.81 317.76 2116.72 317.87V317.86Z" fill="white"/>
                    <path d="M2254.88 374.07C2254.99 374.17 2255.12 374.25 2255.2 374.36C2255.23 374.4 2255.14 374.52 2255.1 374.61C2254.99 374.51 2254.86 374.43 2254.78 374.32C2254.75 374.28 2254.84 374.16 2254.88 374.07Z" fill="white"/>
                    <path d="M2127.33 310.65C2127.31 310.79 2127.3 310.93 2127.28 311.08C2127.2 311.04 2127.05 311.02 2127.04 310.97C2127.02 310.83 2127.04 310.68 2127.06 310.54L2127.33 310.65Z" fill="white"/>
                    <path d="M2138.04 424.81C2138.18 424.83 2138.32 424.84 2138.47 424.86C2138.43 424.94 2138.41 425.09 2138.36 425.1C2138.22 425.12 2138.07 425.1 2137.93 425.08C2137.97 425 2138 424.91 2138.04 424.81Z" fill="white"/>
                    <path d="M2048.38 527.31C2048.49 527.41 2048.62 527.49 2048.7 527.6C2048.73 527.64 2048.64 527.76 2048.6 527.85C2048.49 527.75 2048.36 527.67 2048.28 527.56C2048.25 527.52 2048.34 527.4 2048.38 527.31Z" fill="white"/>
                    <path d="M2415.69 418.28C2415.87 420.15 2414.6 420.9 2413.4 421.58C2412.96 421.83 2412.22 422.11 2412.04 422.32C2410.89 423.67 2410.02 421.75 2409.03 422.19C2408.76 421.16 2408.08 420.02 2408.33 419.13C2408.56 418.28 2408.94 417.26 2409.59 416.52C2410.2 415.83 2410.75 414.58 2412.01 415.84C2412.2 416.03 2412.75 415.83 2413.13 415.84C2414.82 415.93 2415.66 416.79 2415.69 418.27V418.28Z" fill="white"/>
                    <path d="M2297.93 322.18C2299.04 322.18 2299.84 323.05 2299.84 323.44C2299.83 324.95 2301.21 325.54 2301.66 326.73C2301.98 327.57 2302.04 328.13 2301.4 328.71C2300.34 329.67 2299.69 329.8 2298.85 329.19C2298.48 328.92 2298.1 328.71 2297.7 328.5C2295.96 327.59 2295.22 323.7 2297.13 322.52C2297.47 322.31 2297.75 322.13 2297.93 322.17V322.18Z" fill="white"/>
                    <path d="M2314.63 411.36C2315.31 412.07 2316.72 411.83 2316.84 413.18C2316.86 413.37 2316.79 413.6 2316.86 413.75C2317.19 414.39 2317.77 414.96 2317.36 415.77C2317.04 416.4 2316.34 416.52 2315.78 416.81C2314.68 417.38 2313.01 416.83 2312.26 415.69C2311.67 414.81 2311.69 413.96 2312.2 413C2312.73 412.01 2313.78 411.87 2314.62 411.36H2314.63Z" fill="white"/>
                    <path d="M2195.88 475.48C2194.21 475.5 2193.44 474.9 2193.28 473.7C2193.19 473.04 2193.27 472.36 2193.22 471.69C2193.17 471.06 2193.44 470.86 2194.04 470.88C2194.71 470.91 2195.4 470.77 2196.05 470.88C2197.16 471.06 2197.9 471.8 2198.37 472.79C2198.47 472.99 2198.44 473.4 2198.29 473.55C2197.76 474.08 2197.46 474.74 2197.06 475.35C2196.87 475.64 2196 475.49 2195.87 475.5L2195.88 475.48Z" fill="white"/>
                    <path d="M2175.43 351.13C2176.18 351.05 2177.25 350.92 2177.59 352.09C2177.75 352.64 2178.05 353.68 2177.36 353.9C2176.62 354.14 2175.98 355.03 2175.06 354.57C2174.42 354.25 2173.86 353.69 2173.19 353.53C2172.58 353.39 2172.45 353.19 2172.43 352.63C2172.4 351.82 2172.86 351.38 2173.5 351.19C2174.03 351.04 2174.64 351.14 2175.42 351.12L2175.43 351.13Z" fill="white"/>
                    <path d="M2194.56 496.3C2195.32 496.27 2195.98 496.85 2196.04 497.6C2196.06 497.85 2194.76 500.25 2194.55 500.34C2194.08 500.56 2192.58 499.63 2192.66 499.58C2193.66 498.89 2192.28 498.34 2192.61 497.74C2192.93 497.17 2194.07 496.32 2194.56 496.3Z" fill="white"/>
                    <path d="M2360.37 454.31C2360.37 453.91 2360.26 453.3 2360.64 453.29C2361.34 453.27 2362.11 452.93 2362.8 453.58C2363.18 453.94 2363.12 454.46 2363.42 454.76C2363.92 455.25 2364.01 455.6 2363.51 456.18C2362.97 456.8 2362.28 456.91 2361.77 456.54C2361.05 456.02 2360.13 455.47 2360.37 454.32V454.31Z" fill="white"/>
                    <path d="M2292.1 381.22C2291.5 381.19 2290.72 381.52 2290.74 380.37C2290.74 380.11 2290.22 379.85 2289.96 379.57C2289.67 379.25 2289.4 378.91 2289.12 378.58C2289.76 378.5 2290.5 378.19 2291.01 378.41C2291.67 378.69 2292.12 379.43 2292.69 379.93C2293 380.2 2293.24 380.52 2293.09 380.9C2292.92 381.32 2292.46 381.2 2292.09 381.22H2292.1Z" fill="white"/>
                    <path d="M2304.75 534.59C2304.56 534.59 2304.37 534.62 2304.19 534.59C2303.77 534.54 2303.19 534.86 2303.02 534.21C2302.85 533.54 2303.04 532.96 2303.62 532.49C2304.11 532.09 2304.41 532.31 2304.81 532.63C2305.07 532.84 2305.5 532.83 2305.76 533.03C2306.1 533.3 2306.59 533.56 2306.46 534.13C2306.31 534.8 2305.72 534.5 2305.31 534.57C2305.13 534.6 2304.94 534.58 2304.75 534.59Z" fill="white"/>
                    <path d="M2186.08 490.15C2185.66 490.93 2185.46 491.86 2184.68 492.41C2184.5 492.53 2184.14 492.55 2183.91 492.28C2183.62 491.93 2183.02 491.69 2183.23 491.16C2183.49 490.51 2183.9 489.9 2184.31 489.32C2184.79 488.63 2184.96 489.62 2185.36 489.56C2185.78 489.5 2186.1 489.63 2186.08 490.16V490.15Z" fill="white"/>
                    <path d="M2338.89 377.43C2338.37 377.53 2338.05 377.36 2338.07 376.72C2338.1 375.73 2338.62 375.16 2339.5 375.1C2340.28 375.05 2340.73 375.53 2340.93 376.13C2341.16 376.82 2340.16 377.44 2338.89 377.43Z" fill="white"/>
                    <path d="M2385.82 304.91C2385.53 304.91 2385.24 304.97 2384.96 304.91C2384.32 304.78 2384.09 304.01 2384.54 303.54C2384.8 303.26 2385.03 302.93 2385.35 302.73C2385.75 302.47 2386.33 302.43 2386.67 302.7C2387.06 303.01 2386.96 303.73 2387.47 304.04C2387.05 304.37 2386.84 305.04 2386.11 304.9C2386.02 304.88 2385.92 304.9 2385.82 304.9V304.91Z" fill="white"/>
                    <path d="M2238.75 481.58C2238.99 481.81 2239.19 482.02 2239.41 482.21C2239.96 482.67 2239.31 483.52 2240.02 483.96C2240.39 484.19 2240.4 484.62 2240.07 484.9C2239.75 485.17 2239.4 485.06 2239.1 484.75C2238.7 484.34 2238.26 483.98 2237.89 483.56C2237.71 483.36 2237.12 483.24 2237.61 482.77C2238 482.39 2238.38 481.98 2238.76 481.58H2238.75Z" fill="white"/>
                    <path d="M2141.28 413.46C2141.36 412.85 2142.29 412.27 2142.98 412.39C2143.25 412.44 2144.25 413.9 2144.17 414.23C2143.96 415.05 2143.25 414.67 2142.78 414.68C2141.9 414.68 2141.2 414.04 2141.28 413.46Z" fill="white"/>
                    <path d="M2207.98 369.23C2208.08 369.81 2207.96 370.17 2207.3 370.16C2206.49 370.14 2205.69 369.6 2205.68 369.02C2205.67 368.45 2206.47 367.85 2207.27 367.84C2208.47 367.83 2207.8 368.77 2207.98 369.23Z" fill="white"/>
                    <path d="M2211.49 363.14C2211.95 363.41 2212.46 363.67 2212.51 364.28C2212.57 364.94 2211.45 365.62 2210.67 365.47C2210.16 365.37 2210.19 365 2210.22 364.63C2210.28 363.78 2210.88 363.08 2211.49 363.15V363.14Z" fill="white"/>
                    <path d="M2222.62 450.71C2222.25 450.71 2222.05 450.71 2221.87 450.73C2221.18 450.83 2220.96 450.19 2220.52 449.89C2220.94 449.57 2221.14 448.92 2221.83 448.99C2222.02 449.01 2222.21 448.99 2222.39 448.99C2223.02 448.93 2223.43 448.99 2223.43 449.84C2223.43 450.64 2223.12 450.83 2222.61 450.72L2222.62 450.71Z" fill="white"/>
                    <path d="M2236.99 285.73C2237.05 286.4 2236.6 286.55 2236.27 286.41C2235.61 286.13 2235 285.73 2234.41 285.33C2233.85 284.96 2234.43 284.76 2234.6 284.52C2235.34 283.48 2235.86 284.73 2236.52 284.73C2237.28 284.73 2236.85 285.46 2236.99 285.74V285.73Z" fill="white"/>
                    <path d="M2210.86 418.02C2211.64 417.86 2212.1 418.34 2212.32 418.94C2212.49 419.39 2211.36 420.5 2211.01 420.32C2210.34 419.96 2210.04 419.38 2210.09 418.59C2210.13 418.03 2210.47 418.04 2210.85 418.03L2210.86 418.02Z" fill="white"/>
                    <path d="M2363.09 505.2C2363 505.76 2362.56 506.08 2362.08 506.33C2361.79 506.48 2360.83 505.22 2360.77 504.63C2360.71 504.1 2361.08 504.04 2361.47 504.04C2362.26 504.04 2363.07 504.61 2363.09 505.19V505.2Z" fill="white"/>
                    <path d="M2189.39 452.35C2189.47 452.07 2189.67 451.78 2189.97 451.94C2190.69 452.3 2191.51 452.48 2192.1 453.11C2192.35 453.37 2192.38 453.69 2192.27 453.88C2192.17 454.06 2191.74 454.25 2191.57 454.17C2190.92 453.87 2190.31 453.47 2189.7 453.09C2189.44 452.93 2189.34 452.67 2189.38 452.35H2189.39Z" fill="white"/>
                    <path d="M2161.14 465.76C2161.44 465.73 2161.82 465.68 2161.93 466.18C2162.03 466.61 2161.68 466.82 2161.48 467.11C2161.07 467.71 2160.46 467.54 2159.96 467.48C2159.66 467.44 2159.16 466.95 2159.2 466.77C2159.29 466.4 2159.63 466.01 2159.97 465.82C2160.26 465.66 2160.7 465.77 2161.14 465.77V465.76Z" fill="white"/>
                    <path d="M2191.85 311.13C2191.76 310.66 2191.94 310.34 2192.57 310.37C2193.37 310.4 2194.15 310.94 2194.17 311.51C2194.21 312.47 2193 311.84 2192.79 312.58C2192.75 312.7 2191.92 311.87 2191.85 311.13Z" fill="white"/>
                    <path d="M2274.2 469.35C2274.34 469.45 2274.57 469.53 2274.6 469.66C2274.79 470.39 2273.78 471.71 2273.06 471.67C2272.81 471.66 2272.46 471.56 2272.37 471.39C2272.28 471.21 2272.37 470.85 2272.52 470.65C2273.14 469.79 2273.72 469.37 2274.2 469.35Z" fill="white"/>
                    <path d="M2372.53 425.99C2372.53 425.71 2372.47 425.42 2372.53 425.14C2372.67 424.44 2373.25 424.75 2373.66 424.69C2374.2 424.61 2374.25 424.99 2374.26 425.39C2374.26 425.75 2374.34 426.11 2374 426.42C2373.66 426.73 2373.38 427.2 2372.84 426.99C2372.43 426.82 2372.56 426.36 2372.54 426L2372.53 425.99Z" fill="white"/>
                    <path d="M2192.17 450.73C2191.58 450.62 2191.63 450.23 2191.66 449.87C2191.73 449.01 2192.33 448.32 2192.93 448.41C2193.43 448.48 2193.4 448.9 2193.37 449.26C2193.3 450.12 2192.7 450.79 2192.17 450.74V450.73Z" fill="white"/>
                    <path d="M2183.75 310.85C2184.23 310.39 2184.56 309.84 2185.37 309.91C2186.03 309.97 2186.06 310.3 2186.09 310.8C2186.12 311.35 2186.01 311.6 2185.38 311.69C2184.56 311.81 2184.26 311.2 2183.76 310.84L2183.75 310.85Z" fill="white"/>
                    <path d="M2197.69 306.81C2197.84 306.9 2198.1 306.98 2198.12 307.09C2198.18 307.45 2198.3 307.92 2198.12 308.19C2197.85 308.62 2197.55 309.19 2196.88 309.13C2196.36 309.08 2196.39 308.66 2196.43 308.3C2196.51 307.43 2197.06 306.78 2197.69 306.81Z" fill="white"/>
                    <path d="M2384.64 463.17C2385.03 463.17 2385.46 463.18 2385.38 463.72C2385.28 464.41 2384.85 464.95 2384.14 464.88C2383.77 464.84 2383.44 464.36 2383.09 464.08C2383.54 463.65 2383.86 463 2384.64 463.18V463.17Z" fill="white"/>
                    <path d="M2339.84 317.76C2339.81 318.45 2339.03 319.18 2338.55 319.11C2338.05 319.04 2338.13 318.63 2338.1 318.27C2338.04 317.55 2338.43 317.06 2339.05 316.95C2339.23 316.92 2339.83 317.39 2339.84 317.77V317.76Z" fill="white"/>
                    <path d="M2201.93 399.13C2202.41 399.13 2202.92 399.13 2202.78 399.63C2202.61 400.24 2202.21 400.79 2201.91 401.37C2201.41 400.95 2200.98 400.53 2201.06 399.75C2201.14 399.01 2201.62 399.19 2201.94 399.13H2201.93Z" fill="white"/>
                    <path d="M2279.29 277.53C2280.07 277.43 2280.13 277.81 2280.19 278.35C2280.27 279.2 2279.83 279.24 2279.19 279.26C2278.53 279.28 2278.46 278.96 2278.42 278.4C2278.36 277.59 2278.72 277.39 2279.3 277.52L2279.29 277.53Z" fill="white"/>
                    <path d="M2195.5 409.6C2195.42 410.1 2195.82 410.91 2194.85 410.79C2194.32 410.72 2193.85 410.3 2193.78 409.71C2193.65 408.72 2194.72 408.98 2195.15 408.71C2195.68 408.37 2195.31 409.31 2195.51 409.6H2195.5Z" fill="white"/>
                    <path d="M2149.41 481C2149.69 481 2149.98 480.95 2150.25 481C2150.75 481.08 2150.7 481.49 2150.68 481.86C2150.67 482.12 2150.82 482.44 2150.5 482.62C2150.14 482.82 2149.81 482.77 2149.53 482.45C2149.41 482.31 2149.26 482.19 2149.13 482.06C2148.88 481.8 2148.4 481.48 2148.44 481.29C2148.54 480.87 2149.05 481.05 2149.4 481H2149.41Z" fill="white"/>
                    <path d="M2384.34 349.82C2384.53 349.16 2385.08 349.42 2385.49 349.39C2386.26 349.35 2386.04 349.96 2386.07 350.38C2386.1 350.76 2386.07 351.16 2385.52 351.11C2384.96 351.07 2384.28 350.31 2384.33 349.82H2384.34Z" fill="white"/>
                    <path d="M2232.41 346.88C2232.4 347.38 2232.4 347.78 2231.85 347.73C2231.29 347.68 2230.58 346.93 2230.67 346.44C2230.76 345.94 2231.18 346.05 2231.53 346C2232.4 345.86 2232.44 346.38 2232.41 346.88Z" fill="white"/>
                    <path d="M2171.21 439.81C2171.26 439.62 2171.25 439.22 2171.41 439.14C2171.82 438.93 2172.03 439.42 2172.16 439.64C2172.37 439.98 2173.42 440.05 2172.61 440.8C2172.34 441.05 2172.12 441.39 2171.7 441.28C2170.85 441.06 2171.45 440.33 2171.21 439.81Z" fill="white"/>
                    <path d="M2299.29 515.07C2299.66 515.32 2300.45 515.57 2300.11 516.04C2299.82 516.45 2300.11 517.2 2299.42 517.27C2299.01 517.31 2298.42 515.92 2298.61 515.41C2298.71 515.14 2298.96 515.02 2299.29 515.07Z" fill="white"/>
                    <path d="M2281.65 295.29C2281.65 295.29 2282.08 295 2282.49 294.81C2282.67 294.72 2283.44 295.8 2283.39 296.06C2283.25 296.76 2282.68 296.46 2282.28 296.51C2281.79 296.58 2281.66 296.27 2281.66 295.29H2281.65Z" fill="white"/>
                    <path d="M2315.38 299.34C2316.04 299.52 2315.76 300.07 2315.81 300.48C2315.88 300.99 2315.49 301.19 2315.14 301.05C2314.74 300.89 2314.4 300.54 2314.09 300.22C2313.98 300.1 2315.12 299.3 2315.37 299.34H2315.38Z" fill="white"/>
                    <path d="M2422.97 438.04C2423.08 438.54 2422.85 438.74 2422.58 438.74C2421.84 438.74 2421.44 438.29 2421.27 437.61C2421.18 437.26 2421.41 437.06 2421.69 437.05C2422.45 437.02 2422.8 437.53 2422.97 438.03V438.04Z" fill="white"/>
                    <path d="M2262.83 488.11C2262.91 487.5 2263.29 487.04 2263.78 487.03C2264.02 487.03 2264.58 487.46 2264.58 488.02C2264.58 488.78 2264 488.56 2263.59 488.6C2263.2 488.64 2262.85 488.59 2262.83 488.1V488.11Z" fill="white"/>
                    <path d="M2402.74 345.45C2402.78 345.72 2402.02 346.84 2401.9 346.73C2401.56 346.44 2401.21 346.11 2401.04 345.71C2400.89 345.35 2401.11 344.99 2401.61 345.04C2402.02 345.08 2402.57 344.76 2402.74 345.45Z" fill="white"/>
                    <path d="M2257.24 489.92C2257.75 490.07 2258.28 490.4 2258.27 491.16C2258.27 491.44 2258.07 491.68 2257.72 491.61C2257.04 491.46 2256.56 491.08 2256.54 490.34C2256.54 490.06 2256.73 489.83 2257.23 489.92H2257.24Z" fill="white"/>
                    <path d="M2244.44 553.08C2244.97 553.04 2245.17 553.41 2245.03 553.76C2244.87 554.16 2244.52 554.5 2244.2 554.81C2244.08 554.92 2243.28 553.78 2243.32 553.53C2243.38 553.17 2243.82 553 2244.44 553.09V553.08Z" fill="white"/>
                    <path d="M2199.9 407.83C2200.3 407.83 2200.7 407.83 2200.67 408.37C2200.63 408.96 2200.23 409.41 2199.74 409.42C2199.5 409.42 2198.94 408.99 2198.94 408.43C2198.94 407.67 2199.52 407.91 2199.9 407.84V407.83Z" fill="white"/>
                    <path d="M2177.15 496.12C2177.63 496.36 2178.05 496.63 2178.13 497.22C2178.18 497.56 2177.96 497.79 2177.68 497.76C2177.22 497.71 2176.5 498.16 2176.4 497.24C2176.34 496.67 2176.93 496.54 2177.15 496.13V496.12Z" fill="white"/>
                    <path d="M2209.64 416.88C2209.78 416.98 2209.92 417.08 2210.06 417.18C2209.5 417.79 2208.82 418.32 2208.65 419.21C2208.65 419.25 2208.27 419.27 2208.1 419.21C2208 419.18 2207.9 418.86 2207.93 418.84C2208.51 418.35 2208.37 417.38 2209.11 417.01C2209.27 416.93 2209.46 416.93 2209.64 416.89V416.88Z" fill="white"/>
                    <path d="M2210.72 421.24C2210.7 421.81 2209.96 421.51 2209.52 421.48C2208.65 421.41 2209.09 420.65 2209.01 420.19C2208.99 420.05 2209.28 419.53 2209.45 420C2209.69 420.7 2210.85 419.84 2210.73 421.24H2210.72Z" fill="white"/>
                    <path d="M2388.93 407.82C2388.8 407.13 2389.46 406.93 2389.75 406.5C2390.01 406.74 2390.5 406.98 2390.5 407.22C2390.5 407.74 2390.08 408.1 2389.53 408.21C2389.2 408.28 2389.04 408.08 2388.93 407.82Z" fill="white"/>
                    <path d="M2277.13 300.24C2277.08 300.64 2277.33 301.21 2276.56 301.25C2276 301.27 2275.97 300.91 2275.98 300.53C2275.99 300.11 2275.77 299.55 2276.54 299.51C2277.1 299.48 2277.11 299.85 2277.13 300.25V300.24Z" fill="white"/>
                    <path d="M2388.97 484.54C2389.77 485.05 2389.96 485.63 2389.85 486.31C2389.83 486.47 2389.67 486.61 2389.58 486.76C2389.45 486.63 2389.33 486.47 2389.17 486.38C2388.57 486.03 2388.66 485.44 2388.69 484.91C2388.69 484.74 2388.93 484.59 2388.96 484.55L2388.97 484.54Z" fill="white"/>
                    <path d="M2281.57 271.09C2281.71 271.08 2281.79 271.43 2281.77 271.83C2281.75 272.24 2282 272.82 2281.22 272.86C2280.68 272.89 2280.61 272.54 2280.63 272.15C2280.65 271.73 2280.43 271.16 2281.57 271.09Z" fill="white"/>
                    <path d="M2253.85 325.58C2253.78 325.69 2253.72 325.92 2253.63 325.93C2252.99 326 2252.36 326.06 2251.89 325.41C2251.7 325.15 2251.62 324.97 2251.92 324.83C2252.38 324.61 2253.71 325.14 2253.85 325.58Z" fill="white"/>
                    <path d="M2354.91 373.66C2355.8 373.87 2355.24 374.59 2355.34 375.07C2355.39 375.29 2354.96 375.46 2354.68 375.38C2353.85 375.16 2354.29 374.45 2354.24 373.97C2354.22 373.74 2354.6 373.6 2354.92 373.66H2354.91Z" fill="white"/>
                    <path d="M2244.36 390.81C2245.25 391.02 2244.69 391.74 2244.79 392.22C2244.84 392.44 2244.41 392.61 2244.13 392.53C2243.3 392.31 2243.74 391.6 2243.69 391.12C2243.67 390.89 2244.05 390.75 2244.37 390.81H2244.36Z" fill="white"/>
                    <path d="M2317.12 497.37C2317.26 497.36 2317.34 497.71 2317.32 498.11C2317.3 498.52 2317.55 499.1 2316.77 499.14C2316.23 499.17 2316.16 498.82 2316.18 498.43C2316.2 498.01 2315.98 497.44 2317.12 497.37Z" fill="white"/>
                    <path d="M2169.03 380.89C2168.51 381.19 2167.97 381.5 2167.42 381.79C2167.39 381.8 2167.25 381.68 2167.23 381.61C2167.14 381.22 2167.28 380.92 2167.58 380.64C2167.92 380.32 2168.22 379.95 2168.73 380.25C2168.85 380.32 2168.88 380.55 2169.03 380.9V380.89Z" fill="white"/>
                    <path d="M2188.39 430.29C2188.35 429.91 2188.29 429.5 2188.81 429.42C2189.48 429.32 2189.56 429.75 2189.54 430.28C2189.53 430.65 2189.63 431.06 2189.1 431.14C2188.44 431.23 2188.32 430.82 2188.38 430.29H2188.39Z" fill="white"/>
                    <path d="M2221.56 478.38C2222.07 478.53 2222.31 478.65 2222.24 479C2222.09 479.68 2221.71 480.14 2220.97 480.18C2220.83 480.18 2220.67 480.01 2220.53 479.91C2220.89 479.38 2221.25 478.85 2221.56 478.38Z" fill="white"/>
                    <path d="M2230.08 363.97C2230.59 363.89 2230.99 363.99 2230.97 364.23C2230.94 364.71 2231.41 365.4 2230.58 365.65C2230.3 365.74 2229.91 365.62 2229.9 365.36C2229.88 364.87 2229.59 364.24 2230.08 363.97Z" fill="white"/>
                    <path d="M2334.31 488.97C2334.71 488.97 2335.07 488.97 2335.07 489.53C2335.07 490.3 2334.49 490.1 2334.07 490.13C2333.68 490.16 2333.32 490.13 2333.32 489.58C2333.32 488.81 2333.9 489.04 2334.3 488.97H2334.31Z" fill="white"/>
                    <path d="M2332.8 401.66C2333.01 400.77 2333.73 401.33 2334.21 401.23C2334.43 401.18 2334.6 401.61 2334.52 401.89C2334.3 402.72 2333.59 402.28 2333.11 402.33C2332.88 402.35 2332.74 401.97 2332.8 401.65V401.66Z" fill="white"/>
                    <path d="M2211.31 480.67C2211.27 480.29 2211.21 479.88 2211.73 479.8C2212.4 479.7 2212.48 480.13 2212.46 480.66C2212.45 481.03 2212.55 481.44 2212.02 481.52C2211.36 481.61 2211.24 481.2 2211.3 480.67H2211.31Z" fill="white"/>
                    <path d="M2194.39 321.01C2194.31 320.35 2194.51 320.19 2194.81 320.15C2195.49 320.07 2195.55 320.51 2195.51 321.02C2195.49 321.28 2195.56 321.81 2195.34 321.73C2194.9 321.57 2194.56 321.15 2194.38 321.01H2194.39Z" fill="white"/>
                    <path d="M2367.64 474.45C2367.78 474.51 2368.02 474.62 2368.25 474.73C2367.87 475.09 2367.66 475.7 2367 475.63C2366.71 475.6 2366.53 475.43 2366.54 475.08C2366.54 474.33 2367.1 474.53 2367.64 474.45Z" fill="white"/>
                    <path d="M2161.64 509.72C2161.7 509.97 2161.49 510.17 2161.21 510.21C2160.83 510.26 2160.42 510.21 2160.48 509.68C2160.53 509.3 2160.15 508.66 2160.81 508.63C2161.05 508.62 2161.61 509.07 2161.64 509.72Z" fill="white"/>
                    <path d="M2152.85 331.93C2153.52 331.84 2153.73 332.47 2154.15 332.79C2153.91 332.89 2153.67 333.06 2153.43 333.07C2153.02 333.08 2152.46 333.3 2152.41 332.54C2152.39 332.2 2152.55 332.05 2152.85 331.94V331.93Z" fill="white"/>
                    <path d="M2193.87 505.84C2193.97 506.22 2193.97 505.81 2193.67 506.03C2193.51 506.15 2193.09 506.15 2192.95 506.03C2192.57 505.69 2191.97 505.43 2192.14 504.73C2192.16 504.65 2192.27 504.53 2192.32 504.54C2193.04 504.73 2193.33 505.45 2193.88 505.85L2193.87 505.84Z" fill="white"/>
                    <path d="M2235.18 471.35C2235.53 471.75 2236.16 472.01 2235.94 472.68C2235.89 472.84 2235.47 473 2235.25 472.96C2234.57 472.84 2234.87 472.25 2234.81 471.84C2234.76 471.56 2234.83 471.34 2235.18 471.34V471.35Z" fill="white"/>
                    <path d="M2286.72 394.61C2286.91 394.61 2287.11 394.56 2287.28 394.61C2287.39 394.65 2287.54 394.88 2287.51 394.96C2287.4 395.25 2285.63 395.31 2285.43 395.02C2285.39 394.95 2285.51 394.65 2285.59 394.64C2285.96 394.59 2286.34 394.61 2286.72 394.6V394.61Z" fill="white"/>
                    <path d="M2285.36 551.14C2285.95 551.09 2286.05 551.44 2286.01 551.84C2285.98 552.13 2285.82 552.3 2285.46 552.31C2284.93 552.31 2284.83 551.99 2284.87 551.6C2284.9 551.32 2285.04 551.06 2285.36 551.15V551.14Z" fill="white"/>
                    <path d="M2169.69 323.52C2169.99 323.6 2170.17 323.73 2170.17 324.09C2170.17 324.62 2169.85 324.71 2169.46 324.68C2169.17 324.65 2169 324.49 2168.99 324.13C2168.99 323.59 2169.31 323.54 2169.69 323.53V323.52Z" fill="white"/>
                    <path d="M2379.84 350.07C2380.14 350.15 2380.32 350.28 2380.32 350.64C2380.32 351.17 2380 351.26 2379.61 351.23C2379.32 351.2 2379.15 351.04 2379.14 350.68C2379.14 350.14 2379.46 350.09 2379.84 350.08V350.07Z" fill="white"/>
                    <path d="M2393.38 458.86C2393.38 459.22 2393.34 459.54 2392.81 459.56C2392.45 459.56 2392.28 459.4 2392.24 459.11C2392.19 458.72 2392.27 458.39 2392.8 458.38C2393.15 458.38 2393.29 458.54 2393.38 458.86Z" fill="white"/>
                    <path d="M2262.98 281.68C2263.19 281.19 2263.48 280.79 2264.04 280.71C2264.19 280.69 2264.5 280.86 2264.5 280.87C2264.35 281.39 2264.05 281.78 2263.49 281.85C2263.34 281.87 2263.17 281.74 2262.99 281.68H2262.98Z" fill="white"/>
                    <path d="M2359.5 318.12C2359.84 318.05 2360.01 318.27 2360.05 318.56C2360.1 318.95 2360.02 319.28 2359.49 319.29C2359.13 319.3 2358.96 319.13 2358.92 318.84C2358.87 318.44 2358.95 318.09 2359.5 318.12Z" fill="white"/>
                    <path d="M2248.08 345.11C2248.38 345.19 2248.56 345.32 2248.56 345.68C2248.56 346.21 2248.24 346.3 2247.85 346.27C2247.56 346.24 2247.39 346.08 2247.38 345.72C2247.38 345.18 2247.7 345.13 2248.08 345.12V345.11Z" fill="white"/>
                    <path d="M2222.35 540.79C2222.7 540.89 2222.88 541.01 2222.88 541.37C2222.88 541.9 2222.56 541.99 2222.17 541.96C2221.88 541.93 2221.71 541.76 2221.7 541.41C2221.69 540.86 2222.04 540.85 2222.35 540.79Z" fill="white"/>
                    <path d="M2217.22 517.77C2217.26 518.32 2216.92 518.42 2216.52 518.38C2216.23 518.35 2216.06 518.19 2216.05 517.83C2216.05 517.3 2216.37 517.21 2216.76 517.24C2217.05 517.27 2217.28 517.43 2217.22 517.77Z" fill="white"/>
                    <path d="M2293.14 255.31C2292.77 255.31 2292.38 255.3 2292.42 254.76C2292.43 254.59 2292.52 254.44 2292.58 254.27C2292.91 254.51 2293.24 254.76 2293.57 255C2293.43 255.1 2293.28 255.2 2293.14 255.3V255.31Z" fill="white"/>
                    <path d="M2312.85 303.43C2313.22 303.43 2313.61 303.44 2313.57 303.98C2313.56 304.15 2313.47 304.3 2313.41 304.47C2313.08 304.23 2312.75 303.98 2312.42 303.74C2312.56 303.64 2312.71 303.54 2312.85 303.44V303.43Z" fill="white"/>
                    <path d="M2184.66 339.5C2184.95 339.76 2185.22 340.01 2185.49 340.26C2185.25 340.36 2185.01 340.55 2184.78 340.54C2184.27 340.51 2184.29 340.09 2184.34 339.73C2184.35 339.63 2184.57 339.56 2184.66 339.5Z" fill="white"/>
                    <path d="M2332.96 409.82C2332.89 410.03 2332.84 410.33 2332.77 410.33C2332.27 410.36 2332.13 409.83 2331.79 409.61C2331.93 409.51 2332.07 409.35 2332.22 409.33C2332.61 409.27 2332.94 409.37 2332.96 409.82Z" fill="white"/>
                    <path d="M2420.83 433.67C2420.95 433.75 2421.16 433.81 2421.18 433.91C2421.25 434.27 2421.27 434.69 2420.77 434.73C2420.54 434.75 2420.29 434.57 2420.05 434.47C2420.31 434.2 2420.56 433.94 2420.83 433.66V433.67Z" fill="white"/>
                    <path d="M2397.76 416.69C2397.73 416.17 2398.09 416.11 2398.47 416.14C2398.62 416.15 2398.76 416.31 2398.91 416.41C2398.59 416.67 2398.27 416.92 2397.94 417.18C2397.88 417.02 2397.82 416.86 2397.75 416.69H2397.76Z" fill="white"/>
                    <path d="M2206.14 348.92C2206.21 348.96 2206.42 349.01 2206.44 349.11C2206.51 349.47 2206.54 349.89 2206.04 349.95C2205.81 349.97 2205.56 349.79 2205.32 349.71C2205.58 349.46 2205.85 349.21 2206.14 348.93V348.92Z" fill="white"/>
                    <path d="M2243.45 349.81C2243.97 349.78 2244.03 350.14 2244 350.52C2243.99 350.67 2243.83 350.81 2243.73 350.96C2243.47 350.64 2243.22 350.32 2242.96 349.99C2243.12 349.93 2243.28 349.87 2243.45 349.8V349.81Z" fill="white"/>
                    <path d="M2407.37 370.33C2407.53 370.42 2407.67 370.51 2407.82 370.59C2407.51 370.86 2407.21 371.13 2406.9 371.4C2406.83 371.24 2406.72 371.09 2406.7 370.93C2406.63 370.42 2406.97 370.33 2407.37 370.33Z" fill="white"/>
                    <path d="M2234.41 511.09C2234.38 510.57 2234.74 510.51 2235.12 510.54C2235.27 510.55 2235.41 510.71 2235.56 510.81C2235.24 511.07 2234.92 511.32 2234.59 511.58C2234.53 511.42 2234.47 511.26 2234.4 511.09H2234.41Z" fill="white"/>
                    <path d="M2237.45 550.63C2237.65 550.5 2237.79 550.35 2237.95 550.33C2238.35 550.28 2238.69 550.38 2238.64 550.89C2238.62 551.05 2238.52 551.21 2238.46 551.37C2238.13 551.13 2237.8 550.89 2237.45 550.63Z" fill="white"/>
                    <path d="M2131.47 365.35C2131.81 365.54 2131.69 365.85 2131.68 366.11C2131.68 366.19 2131.41 366.35 2131.33 366.32C2131 366.18 2131.12 365.84 2131.13 365.59C2131.13 365.5 2131.36 365.43 2131.47 365.36V365.35Z" fill="white"/>
                    <path d="M2152.72 351.81C2153.02 351.71 2153.27 351.59 2153.51 351.58C2153.65 351.58 2153.8 351.75 2153.95 351.84C2153.71 351.94 2153.48 352.09 2153.24 352.12C2153.11 352.14 2152.95 351.95 2152.72 351.81Z" fill="white"/>
                    <path d="M2337.29 526.54C2337.63 526.73 2337.51 527.04 2337.5 527.3C2337.5 527.38 2337.23 527.54 2337.15 527.51C2336.82 527.37 2336.94 527.03 2336.95 526.78C2336.95 526.69 2337.18 526.62 2337.29 526.55V526.54Z" fill="white"/>
                    <path d="M2404.96 443.06C2405.06 443.36 2405.18 443.61 2405.19 443.85C2405.19 443.99 2405.02 444.14 2404.93 444.29C2404.83 444.05 2404.68 443.82 2404.65 443.58C2404.63 443.45 2404.82 443.29 2404.96 443.06Z" fill="white"/>
                    <path d="M2404.52 439.99C2404.52 440.14 2404.54 440.41 2404.47 440.64C2404.39 440.92 2404.18 440.92 2404.1 440.64C2404.03 440.41 2404.03 440.12 2404.09 439.89C2404.16 439.61 2404.33 439.62 2404.53 439.98L2404.52 439.99Z" fill="white"/>
                    <path d="M2353.24 533.65C2353.3 533.49 2353.34 533.21 2353.43 533.19C2353.77 533.13 2353.85 533.38 2353.81 533.66C2353.78 533.83 2353.71 534.1 2353.62 534.12C2353.28 534.18 2353.22 533.93 2353.23 533.65H2353.24Z" fill="white"/>
                    <path d="M2174.86 327.47C2175.02 327.53 2175.3 327.57 2175.32 327.66C2175.38 328 2175.13 328.08 2174.85 328.04C2174.68 328.01 2174.41 327.94 2174.39 327.85C2174.33 327.51 2174.58 327.45 2174.86 327.46V327.47Z" fill="white"/>
                    <path d="M2397.86 366.13C2398.16 366.03 2398.41 365.91 2398.65 365.9C2398.79 365.9 2398.94 366.07 2399.09 366.16C2398.85 366.26 2398.62 366.41 2398.38 366.44C2398.25 366.46 2398.09 366.27 2397.86 366.13Z" fill="white"/>
                    <path d="M2173.4 482.29C2173.51 482.36 2173.65 482.45 2173.8 482.54C2173.56 482.64 2173.33 482.79 2173.09 482.82C2172.96 482.84 2172.8 482.65 2172.65 482.56C2172.83 482.33 2173.06 482.21 2173.4 482.28V482.29Z" fill="white"/>
                    <path d="M2394.78 322.05C2394.81 322.33 2394.75 322.58 2394.41 322.53C2394.32 322.52 2394.24 322.24 2394.21 322.08C2394.16 321.81 2394.23 321.55 2394.57 321.6C2394.66 321.61 2394.71 321.89 2394.78 322.05Z" fill="white"/>
                    <path d="M2212.5 482.66C2212.53 482.94 2212.47 483.19 2212.13 483.14C2212.04 483.13 2211.96 482.85 2211.93 482.69C2211.88 482.42 2211.95 482.16 2212.29 482.21C2212.38 482.22 2212.43 482.5 2212.5 482.66Z" fill="white"/>
                    <path d="M2220.99 473.83C2221.02 474.11 2220.96 474.36 2220.62 474.31C2220.53 474.3 2220.45 474.02 2220.42 473.86C2220.37 473.59 2220.44 473.33 2220.78 473.38C2220.87 473.39 2220.92 473.67 2220.99 473.83Z" fill="white"/>
                    <path d="M2247.34 437.18C2247.64 437.08 2247.89 436.96 2248.13 436.95C2248.27 436.95 2248.42 437.12 2248.57 437.21C2248.33 437.31 2248.1 437.46 2247.86 437.49C2247.73 437.51 2247.57 437.32 2247.34 437.18Z" fill="white"/>
                    <path d="M2197.06 344.89C2197.25 344.99 2197.48 345.1 2197.71 345.21C2197.57 345.31 2197.41 345.5 2197.28 345.49C2197.03 345.47 2196.8 345.33 2196.56 345.23C2196.7 345.13 2196.85 345.03 2197.06 344.89Z" fill="white"/>
                    <path d="M2286.11 367.5C2286.22 367.57 2286.36 367.66 2286.51 367.75C2286.27 367.85 2286.04 368 2285.8 368.03C2285.67 368.05 2285.51 367.86 2285.36 367.77C2285.54 367.54 2285.77 367.42 2286.11 367.49V367.5Z" fill="white"/>
                    <path d="M2292.5 323.12C2292.8 323.02 2293.05 322.9 2293.29 322.89C2293.43 322.89 2293.58 323.06 2293.73 323.15C2293.49 323.25 2293.26 323.4 2293.02 323.43C2292.89 323.45 2292.73 323.26 2292.5 323.12Z" fill="white"/>
                    <path d="M2301.99 253.48C2302.33 253.67 2302.21 253.98 2302.2 254.24C2302.2 254.32 2301.93 254.48 2301.85 254.45C2301.52 254.31 2301.64 253.97 2301.65 253.72C2301.65 253.63 2301.88 253.56 2301.99 253.49V253.48Z" fill="white"/>
                    <path d="M2331.01 309.42C2331.04 309.7 2330.98 309.95 2330.64 309.9C2330.55 309.89 2330.47 309.61 2330.44 309.45C2330.39 309.18 2330.46 308.92 2330.8 308.97C2330.89 308.98 2330.94 309.26 2331.01 309.42Z" fill="white"/>
                    <path d="M2326.26 512.22C2326.32 512.06 2326.36 511.78 2326.45 511.76C2326.79 511.7 2326.87 511.95 2326.83 512.23C2326.8 512.4 2326.73 512.67 2326.64 512.69C2326.3 512.75 2326.24 512.5 2326.25 512.22H2326.26Z" fill="white"/>
                    <path d="M2309.47 419.58C2309.63 419.64 2309.91 419.68 2309.93 419.77C2309.99 420.11 2309.74 420.19 2309.46 420.15C2309.29 420.12 2309.02 420.05 2309 419.96C2308.94 419.62 2309.19 419.56 2309.47 419.57V419.58Z" fill="white"/>
                    <path d="M2324.45 418.42C2324.58 418.35 2324.7 418.26 2324.83 418.23C2324.88 418.22 2324.96 418.34 2325.02 418.41C2324.89 418.48 2324.77 418.57 2324.64 418.6C2324.59 418.61 2324.51 418.49 2324.45 418.42Z" fill="white"/>
                    <path d="M2417.01 366.97C2417.14 366.9 2417.26 366.81 2417.39 366.78C2417.44 366.77 2417.52 366.89 2417.58 366.96C2417.45 367.03 2417.33 367.12 2417.2 367.15C2417.15 367.16 2417.07 367.04 2417.01 366.97Z" fill="white"/>
                    <path d="M2223.86 462.32C2223.98 462.38 2224.11 462.45 2224.24 462.52C2224.18 462.59 2224.1 462.71 2224.05 462.7C2223.91 462.67 2223.79 462.59 2223.66 462.53C2223.72 462.46 2223.79 462.4 2223.86 462.32Z" fill="white"/>
                    <path d="M2211.42 505.7C2211.34 505.62 2211.28 505.56 2211.21 505.5C2211.34 505.43 2211.46 505.34 2211.59 505.31C2211.64 505.3 2211.72 505.42 2211.78 505.49C2211.66 505.56 2211.53 505.63 2211.41 505.7H2211.42Z" fill="white"/>
                    <path d="M2208.35 421.74C2208.29 421.86 2208.22 421.99 2208.15 422.12C2208.08 422.06 2207.96 421.98 2207.97 421.93C2208 421.79 2208.08 421.67 2208.14 421.54C2208.21 421.6 2208.27 421.67 2208.35 421.74Z" fill="white"/>
                    <path d="M2240.46 390.3C2240.53 390.43 2240.62 390.55 2240.65 390.68C2240.66 390.73 2240.54 390.81 2240.47 390.87C2240.4 390.74 2240.31 390.62 2240.28 390.49C2240.27 390.44 2240.39 390.36 2240.46 390.3Z" fill="white"/>
                    <path d="M2244.37 343.24C2244.29 343.32 2244.23 343.38 2244.17 343.45C2244.1 343.32 2244.01 343.2 2243.98 343.07C2243.97 343.02 2244.09 342.94 2244.16 342.88C2244.23 343 2244.3 343.13 2244.37 343.25V343.24Z" fill="white"/>
                    <path d="M2188.88 325.55C2189 325.61 2189.13 325.68 2189.26 325.75C2189.2 325.82 2189.12 325.94 2189.07 325.93C2188.93 325.9 2188.81 325.82 2188.68 325.76C2188.74 325.69 2188.81 325.63 2188.88 325.55Z" fill="white"/>
                    <path d="M2206.86 440.7C2206.94 440.78 2207 440.84 2207.07 440.9C2206.94 440.97 2206.82 441.06 2206.69 441.09C2206.64 441.1 2206.56 440.98 2206.5 440.91C2206.62 440.84 2206.75 440.77 2206.87 440.7H2206.86Z" fill="white"/>
                    <path d="M2268.33 402.39C2268.25 402.31 2268.19 402.25 2268.12 402.19C2268.25 402.12 2268.37 402.03 2268.5 402C2268.55 401.99 2268.63 402.11 2268.69 402.18C2268.57 402.25 2268.44 402.32 2268.32 402.39H2268.33Z" fill="white"/>
                    <path d="M2277.02 262.78C2277.09 262.71 2277.18 262.59 2277.2 262.6C2277.33 262.67 2277.48 262.74 2277.54 262.85C2277.66 263.09 2277.54 263.21 2277.3 263.1C2277.18 263.05 2277.11 262.89 2277.02 262.78Z" fill="white"/>
                    <path d="M2277.82 263.11C2277.91 263.22 2278.1 263.36 2278.07 263.44C2278 263.66 2277.83 263.71 2277.73 263.56C2277.65 263.43 2277.61 263.27 2277.56 263.12C2277.65 263.12 2277.73 263.12 2277.82 263.12V263.11Z" fill="white"/>
                    <path d="M2339.01 443.92C2339.13 443.98 2339.26 444.05 2339.39 444.12C2339.33 444.19 2339.25 444.31 2339.2 444.3C2339.06 444.27 2338.94 444.19 2338.81 444.13C2338.87 444.06 2338.94 444 2339.01 443.92Z" fill="white"/>
                    <path d="M2193.48 264.82C2193.6 264.88 2193.73 264.95 2193.86 265.02C2193.8 265.09 2193.72 265.21 2193.67 265.2C2193.53 265.17 2193.41 265.09 2193.28 265.03C2193.34 264.96 2193.41 264.9 2193.48 264.82Z" fill="white"/>
                    <path d="M2281.85 256.76C2281.93 256.84 2281.99 256.9 2282.06 256.96C2281.93 257.03 2281.81 257.12 2281.68 257.15C2281.63 257.16 2281.55 257.04 2281.49 256.97C2281.61 256.9 2281.74 256.83 2281.86 256.76H2281.85Z" fill="white"/>
                    <path d="M2185.21 306.39C2185.09 306.33 2184.96 306.26 2184.83 306.19C2184.89 306.12 2184.97 306 2185.02 306.01C2185.16 306.04 2185.28 306.12 2185.41 306.18C2185.35 306.25 2185.28 306.31 2185.21 306.39Z" fill="white"/>
                    <path d="M2319.88 350.74C2320 350.8 2320.13 350.87 2320.26 350.94C2320.2 351.01 2320.12 351.13 2320.07 351.12C2319.93 351.09 2319.81 351.01 2319.68 350.95C2319.74 350.88 2319.81 350.82 2319.88 350.74Z" fill="white"/>
                    <path d="M2193.4 395.05C2193.32 395.13 2193.26 395.19 2193.2 395.26C2193.13 395.13 2193.04 395.01 2193.01 394.88C2193 394.83 2193.12 394.75 2193.19 394.69C2193.26 394.81 2193.33 394.94 2193.4 395.06V395.05Z" fill="white"/>
                    <path d="M2294.78 287.9C2294.91 287.83 2295.03 287.74 2295.16 287.71C2295.21 287.7 2295.29 287.82 2295.35 287.89C2295.22 287.96 2295.1 288.05 2294.97 288.08C2294.92 288.09 2294.84 287.97 2294.78 287.9Z" fill="white"/>
                    <path d="M2190.56 382.69C2190.68 382.75 2190.81 382.82 2190.94 382.89C2190.88 382.96 2190.8 383.08 2190.75 383.07C2190.61 383.04 2190.49 382.96 2190.36 382.9L2190.56 382.69Z" fill="white"/>
                    <path d="M2299.61 413.79C2299.67 413.67 2299.74 413.54 2299.81 413.41C2299.88 413.47 2300 413.55 2299.99 413.6C2299.96 413.74 2299.88 413.86 2299.82 413.99C2299.75 413.93 2299.69 413.86 2299.61 413.79Z" fill="white"/>
                    <path d="M2361.89 533.2C2362.02 533.13 2362.14 533.04 2362.27 533.01C2362.32 533 2362.4 533.12 2362.46 533.19C2362.33 533.26 2362.21 533.35 2362.08 533.38C2362.03 533.39 2361.95 533.27 2361.89 533.2Z" fill="white"/>
                    <path d="M2257.21 362.88C2257.39 360.86 2258.87 360.31 2260.28 359.83C2260.8 359.65 2261.63 359.5 2261.87 359.31C2263.35 358.1 2263.9 360.3 2265.03 360.04C2265.12 361.19 2265.61 362.52 2265.18 363.42C2264.77 364.27 2264.17 365.29 2263.34 365.94C2262.55 366.56 2261.73 367.78 2260.64 366.19C2260.48 365.96 2259.86 366.06 2259.46 365.96C2257.69 365.53 2256.96 364.46 2257.22 362.89L2257.21 362.88Z" fill="white"/>
                    <path d="M2363.39 487.68C2362.21 487.47 2361.54 486.38 2361.62 485.97C2361.93 484.37 2360.57 483.47 2360.32 482.13C2360.15 481.18 2360.19 480.57 2360.98 480.08C2362.29 479.26 2363 479.26 2363.78 480.08C2364.12 480.43 2364.48 480.73 2364.86 481.03C2366.52 482.33 2366.56 486.6 2364.3 487.48C2363.9 487.64 2363.57 487.78 2363.38 487.69L2363.39 487.68Z" fill="white"/>
                    <path d="M2363.03 389.87C2362.45 388.99 2360.91 388.96 2361.04 387.51C2361.06 387.3 2361.18 387.08 2361.13 386.9C2360.91 386.16 2360.39 385.44 2360.99 384.67C2361.46 384.06 2362.22 384.07 2362.86 383.88C2364.14 383.49 2365.8 384.4 2366.38 385.75C2366.83 386.8 2366.65 387.7 2365.92 388.62C2365.17 389.57 2364.03 389.51 2363.04 389.89L2363.03 389.87Z" fill="white"/>
                    <path d="M2501.41 344.97C2503.18 345.27 2503.88 346.06 2503.82 347.36C2503.79 348.08 2503.57 348.79 2503.49 349.5C2503.42 350.18 2503.09 350.34 2502.46 350.2C2501.76 350.04 2501 350.06 2500.33 349.82C2499.19 349.42 2498.55 348.48 2498.24 347.34C2498.18 347.11 2498.29 346.68 2498.47 346.55C2499.14 346.09 2499.58 345.44 2500.12 344.89C2500.38 344.62 2501.27 344.95 2501.41 344.97Z" fill="white"/>
                    <path d="M2498.92 480.8C2498.11 480.74 2496.95 480.66 2496.82 479.36C2496.76 478.74 2496.64 477.59 2497.41 477.48C2498.24 477.37 2499.09 476.55 2499.98 477.22C2500.6 477.68 2501.08 478.39 2501.76 478.68C2502.38 478.95 2502.48 479.18 2502.39 479.78C2502.26 480.64 2501.69 481.02 2500.97 481.09C2500.37 481.15 2499.75 480.92 2498.92 480.79V480.8Z" fill="white"/>
                    <path d="M2506.86 323.16C2506.05 323.04 2505.46 322.3 2505.55 321.49C2505.58 321.22 2507.42 318.93 2507.66 318.87C2508.2 318.73 2509.61 320 2509.52 320.04C2508.33 320.57 2509.68 321.43 2509.21 322C2508.77 322.54 2507.38 323.23 2506.86 323.15V323.16Z" fill="white"/>
                    <path d="M2322.88 335.43C2322.8 335.85 2322.8 336.52 2322.4 336.46C2321.65 336.35 2320.77 336.56 2320.17 335.73C2319.84 335.27 2320 334.74 2319.74 334.36C2319.31 333.75 2319.28 333.35 2319.92 332.84C2320.61 332.29 2321.37 332.31 2321.84 332.8C2322.5 333.49 2323.37 334.26 2322.89 335.43H2322.88Z" fill="white"/>
                    <path d="M2381.05 426.21C2381.69 426.36 2382.57 426.16 2382.32 427.38C2382.26 427.65 2382.77 428.03 2382.99 428.37C2383.24 428.76 2383.45 429.18 2383.69 429.58C2383 429.54 2382.15 429.72 2381.65 429.39C2381.01 428.96 2380.67 428.1 2380.16 427.45C2379.88 427.1 2379.69 426.72 2379.93 426.35C2380.19 425.94 2380.66 426.15 2381.05 426.21Z" fill="white"/>
                    <path d="M2397.46 261.12C2397.66 261.15 2397.87 261.16 2398.06 261.22C2398.49 261.35 2399.18 261.13 2399.23 261.85C2399.28 262.59 2398.96 263.17 2398.26 263.55C2397.66 263.87 2397.38 263.59 2397.03 263.17C2396.8 262.9 2396.34 262.83 2396.1 262.56C2395.79 262.21 2395.32 261.83 2395.57 261.26C2395.86 260.58 2396.42 261.01 2396.87 261.02C2397.07 261.02 2397.27 261.08 2397.47 261.11L2397.46 261.12Z" fill="white"/>
                    <path d="M2514.66 331.32C2515.25 330.58 2515.65 329.62 2516.58 329.2C2516.79 329.1 2517.18 329.15 2517.37 329.49C2517.61 329.91 2518.2 330.29 2517.87 330.81C2517.47 331.45 2516.92 332.02 2516.37 332.56C2515.72 333.2 2515.74 332.11 2515.3 332.1C2514.85 332.09 2514.53 331.88 2514.66 331.32Z" fill="white"/>
                    <path d="M2330.7 421.13C2331.27 421.13 2331.58 421.37 2331.43 422.04C2331.2 423.08 2330.55 423.59 2329.6 423.48C2328.76 423.38 2328.38 422.79 2328.29 422.11C2328.18 421.33 2329.36 420.87 2330.7 421.13Z" fill="white"/>
                    <path d="M2266.84 488.9C2267.15 488.95 2267.47 488.95 2267.75 489.06C2268.41 489.32 2268.49 490.18 2267.92 490.59C2267.59 490.83 2267.28 491.14 2266.91 491.29C2266.43 491.48 2265.82 491.42 2265.51 491.07C2265.15 490.67 2265.41 489.93 2264.93 489.49C2265.44 489.22 2265.8 488.55 2266.54 488.84C2266.63 488.88 2266.74 488.88 2266.84 488.89V488.9Z" fill="white"/>
                    <path d="M2457.14 330.16C2456.93 329.86 2456.76 329.6 2456.57 329.37C2456.08 328.78 2456.93 328 2456.26 327.4C2455.91 327.08 2455.99 326.62 2456.39 326.39C2456.78 326.17 2457.13 326.35 2457.39 326.73C2457.73 327.24 2458.13 327.71 2458.44 328.23C2458.59 328.48 2459.19 328.72 2458.58 329.12C2458.09 329.45 2457.61 329.81 2457.13 330.16H2457.14Z" fill="white"/>
                    <path d="M2547.24 421.35C2547.04 421.98 2545.93 422.42 2545.23 422.15C2544.95 422.04 2544.17 420.3 2544.33 419.97C2544.72 419.14 2545.39 419.68 2545.89 419.77C2546.82 419.93 2547.44 420.76 2547.24 421.36V421.35Z" fill="white"/>
                    <path d="M2467.92 455.28C2467.92 454.64 2468.12 454.28 2468.82 454.43C2469.67 454.61 2470.42 455.34 2470.32 455.95C2470.22 456.56 2469.26 457.04 2468.4 456.89C2467.13 456.67 2468.02 455.8 2467.92 455.28Z" fill="white"/>
                    <path d="M2463.02 461.05C2462.59 460.68 2462.1 460.3 2462.16 459.65C2462.23 458.94 2463.55 458.43 2464.34 458.74C2464.86 458.95 2464.76 459.34 2464.66 459.72C2464.43 460.61 2463.65 461.24 2463.03 461.05H2463.02Z" fill="white"/>
                    <path d="M2468.24 366.04C2468.64 366.11 2468.84 366.16 2469.04 366.17C2469.8 366.2 2469.9 366.92 2470.31 367.33C2469.8 367.59 2469.47 368.24 2468.74 368.02C2468.55 367.96 2468.34 367.94 2468.14 367.92C2467.46 367.87 2467.04 367.71 2467.2 366.82C2467.36 365.97 2467.73 365.83 2468.24 366.04Z" fill="white"/>
                    <path d="M2420.92 538.18C2420.99 537.46 2421.49 537.39 2421.81 537.6C2422.45 538.02 2423.02 538.57 2423.58 539.11C2424.1 539.61 2423.45 539.71 2423.23 539.93C2422.24 540.89 2421.93 539.46 2421.23 539.33C2420.42 539.18 2421.02 538.49 2420.92 538.17V538.18Z" fill="white"/>
                    <path d="M2474.36 402.99C2473.5 403.01 2473.1 402.41 2472.99 401.73C2472.9 401.22 2474.32 400.26 2474.65 400.53C2475.3 401.04 2475.49 401.72 2475.28 402.54C2475.13 403.12 2474.77 403.05 2474.36 402.99Z" fill="white"/>
                    <path d="M2329.89 280.94C2330.09 280.36 2330.62 280.11 2331.18 279.94C2331.51 279.84 2332.29 281.36 2332.24 282C2332.2 282.58 2331.8 282.57 2331.39 282.49C2330.55 282.33 2329.81 281.58 2329.9 280.95L2329.89 280.94Z" fill="white"/>
                    <path d="M2503.79 370.76C2503.65 371.04 2503.39 371.31 2503.09 371.09C2502.4 370.57 2501.57 370.21 2501.06 369.43C2500.85 369.1 2500.88 368.76 2501.03 368.58C2501.17 368.41 2501.67 368.29 2501.82 368.4C2502.45 368.85 2503.01 369.39 2503.59 369.91C2503.84 370.13 2503.89 370.43 2503.78 370.76H2503.79Z" fill="white"/>
                    <path d="M2536.36 362.04C2536.03 362.01 2535.62 361.99 2535.6 361.44C2535.58 360.96 2535.99 360.81 2536.26 360.54C2536.81 359.98 2537.42 360.28 2537.94 360.45C2538.25 360.55 2538.69 361.17 2538.61 361.35C2538.44 361.72 2538.01 362.07 2537.61 362.21C2537.27 362.32 2536.83 362.12 2536.36 362.04Z" fill="white"/>
                    <path d="M2473.72 520.02C2473.72 520.53 2473.47 520.84 2472.81 520.69C2471.97 520.5 2471.25 519.78 2471.33 519.17C2471.47 518.14 2472.64 519.05 2473 518.31C2473.06 518.18 2473.79 519.24 2473.72 520.03V520.02Z" fill="white"/>
                    <path d="M2417.17 336.24C2417.04 336.1 2416.82 335.97 2416.81 335.83C2416.75 335.02 2418.08 333.82 2418.83 334C2419.09 334.06 2419.45 334.23 2419.51 334.43C2419.57 334.64 2419.39 335.01 2419.21 335.19C2418.39 335.98 2417.69 336.32 2417.18 336.24H2417.17Z" fill="white"/>
                    <path d="M2304.48 363.09C2304.43 363.39 2304.43 363.71 2304.32 363.99C2304.04 364.71 2303.48 364.27 2303.03 364.25C2302.45 364.23 2302.46 363.81 2302.54 363.39C2302.61 363.01 2302.6 362.61 2303.01 362.35C2303.43 362.09 2303.82 361.64 2304.35 361.97C2304.76 362.23 2304.53 362.7 2304.48 363.08V363.09Z" fill="white"/>
                    <path d="M2500.53 371.94C2501.13 372.17 2501.01 372.58 2500.91 372.96C2500.67 373.86 2499.9 374.47 2499.28 374.26C2498.76 374.09 2498.88 373.65 2498.98 373.27C2499.23 372.37 2499.98 371.78 2500.54 371.94H2500.53Z" fill="white"/>
                    <path d="M2482.26 521.9C2481.66 522.3 2481.2 522.81 2480.36 522.58C2479.67 522.39 2479.7 522.03 2479.77 521.49C2479.85 520.9 2480.01 520.66 2480.7 520.68C2481.6 520.71 2481.79 521.42 2482.26 521.9Z" fill="white"/>
                    <path d="M2466.7 523.47C2466.56 523.34 2466.3 523.21 2466.3 523.09C2466.3 522.69 2466.28 522.17 2466.5 521.92C2466.87 521.52 2467.3 520.97 2468 521.17C2468.54 521.32 2468.43 521.76 2468.32 522.14C2468.07 523.05 2467.35 523.62 2466.7 523.47Z" fill="white"/>
                    <path d="M2298.87 321.32C2298.45 321.24 2298.01 321.15 2298.19 320.6C2298.43 319.89 2298.99 319.4 2299.73 319.61C2300.12 319.72 2300.37 320.3 2300.69 320.67C2300.13 321.03 2299.66 321.67 2298.87 321.32Z" fill="white"/>
                    <path d="M2318.09 484.22C2318.26 483.5 2319.23 482.88 2319.72 483.04C2320.24 483.22 2320.08 483.63 2320.04 484.02C2319.96 484.8 2319.46 485.24 2318.78 485.24C2318.58 485.24 2318.03 484.62 2318.1 484.22H2318.09Z" fill="white"/>
                    <path d="M2480.15 424.75C2479.64 424.67 2479.11 424.55 2479.35 424.05C2479.65 423.43 2480.18 422.93 2480.62 422.38C2481.07 422.93 2481.44 423.45 2481.2 424.26C2480.98 425.03 2480.49 424.75 2480.15 424.74V424.75Z" fill="white"/>
                    <path d="M2374.48 538.65C2373.63 538.61 2373.64 538.19 2373.69 537.6C2373.77 536.69 2374.25 536.72 2374.93 536.83C2375.63 536.94 2375.64 537.29 2375.58 537.89C2375.49 538.76 2375.07 538.9 2374.48 538.65Z" fill="white"/>
                    <path d="M2489 414.9C2489.18 414.38 2488.92 413.45 2489.92 413.76C2490.47 413.93 2490.88 414.47 2490.85 415.12C2490.79 416.19 2489.71 415.71 2489.21 415.91C2488.58 416.16 2489.16 415.24 2489 414.9Z" fill="white"/>
                    <path d="M2551.76 348.16C2551.46 348.11 2551.15 348.1 2550.87 348C2550.36 347.82 2550.49 347.39 2550.58 347.01C2550.65 346.73 2550.55 346.37 2550.92 346.24C2551.34 346.09 2551.68 346.21 2551.91 346.61C2552.01 346.78 2552.15 346.94 2552.26 347.1C2552.47 347.42 2552.93 347.85 2552.84 348.05C2552.65 348.48 2552.14 348.19 2551.76 348.17V348.16Z" fill="white"/>
                    <path d="M2277.14 441.56C2276.81 442.22 2276.27 441.84 2275.83 441.79C2275.01 441.69 2275.36 441.08 2275.4 440.63C2275.44 440.22 2275.55 439.81 2276.13 439.96C2276.72 440.12 2277.29 441.05 2277.14 441.56Z" fill="white"/>
                    <path d="M2437.67 474.23C2437.78 473.7 2437.86 473.28 2438.43 473.44C2439.02 473.6 2439.62 474.54 2439.43 475.04C2439.24 475.55 2438.82 475.35 2438.43 475.34C2437.48 475.32 2437.53 474.76 2437.67 474.23Z" fill="white"/>
                    <path d="M2520.64 387.59C2520.55 387.78 2520.48 388.21 2520.3 388.26C2519.82 388.4 2519.7 387.85 2519.6 387.58C2519.45 387.18 2518.35 386.9 2519.35 386.26C2519.68 386.05 2519.98 385.73 2520.41 385.93C2521.27 386.33 2520.49 386.98 2520.64 387.59Z" fill="white"/>
                    <path d="M2399.46 282.88C2399.12 282.54 2398.33 282.13 2398.78 281.69C2399.17 281.32 2399.01 280.46 2399.75 280.52C2400.19 280.55 2400.55 282.14 2400.25 282.65C2400.09 282.92 2399.8 283 2399.46 282.88Z" fill="white"/>
                    <path d="M2375.43 519.36C2375.43 519.36 2374.92 519.58 2374.44 519.71C2374.23 519.77 2373.63 518.48 2373.73 518.21C2374.01 517.5 2374.56 517.93 2375 517.94C2375.54 517.96 2375.61 518.32 2375.43 519.36Z" fill="white"/>
                    <path d="M2340.45 508.5C2339.78 508.18 2340.19 507.65 2340.21 507.21C2340.24 506.65 2340.69 506.52 2341.03 506.73C2341.42 506.97 2341.72 507.41 2341.98 507.82C2342.07 507.97 2340.71 508.6 2340.45 508.5Z" fill="white"/>
                    <path d="M2253.33 340.51C2253.31 339.96 2253.59 339.79 2253.88 339.84C2254.66 339.97 2255 340.54 2255.05 341.29C2255.08 341.68 2254.79 341.84 2254.49 341.8C2253.67 341.69 2253.41 341.08 2253.32 340.51H2253.33Z" fill="white"/>
                    <path d="M2432.87 318.56C2432.67 319.19 2432.18 319.6 2431.66 319.52C2431.4 319.48 2430.89 318.91 2431.01 318.31C2431.16 317.51 2431.73 317.85 2432.17 317.88C2432.59 317.91 2432.95 318.04 2432.88 318.56H2432.87Z" fill="white"/>
                    <path d="M2256.79 442.63C2256.79 442.34 2257.82 441.3 2257.93 441.44C2258.23 441.81 2258.54 442.24 2258.64 442.69C2258.73 443.11 2258.42 443.44 2257.91 443.29C2257.49 443.17 2256.84 443.4 2256.79 442.63Z" fill="white"/>
                    <path d="M2439.16 317.73C2438.65 317.47 2438.15 317.02 2438.31 316.21C2438.37 315.91 2438.62 315.69 2438.97 315.84C2439.66 316.13 2440.09 316.63 2439.97 317.41C2439.92 317.7 2439.67 317.91 2439.16 317.72V317.73Z" fill="white"/>
                    <path d="M2465.01 253.24C2464.44 253.18 2464.3 252.75 2464.52 252.41C2464.77 252.02 2465.2 251.72 2465.61 251.46C2465.76 251.37 2466.39 252.73 2466.29 252.99C2466.15 253.36 2465.66 253.45 2465.02 253.24H2465.01Z" fill="white"/>
                    <path d="M2484 415.92C2483.57 415.84 2483.15 415.77 2483.29 415.2C2483.44 414.58 2483.96 414.18 2484.48 414.27C2484.74 414.31 2485.24 414.88 2485.13 415.48C2484.98 416.29 2484.41 415.91 2483.99 415.92H2484Z" fill="white"/>
                    <path d="M2525.29 326.72C2524.83 326.37 2524.43 326 2524.46 325.37C2524.48 325 2524.75 324.8 2525.04 324.89C2525.52 325.04 2526.37 324.69 2526.3 325.7C2526.26 326.31 2525.6 326.34 2525.29 326.73V326.72Z" fill="white"/>
                    <path d="M2475.43 404.43C2475.3 404.3 2475.17 404.16 2475.04 404.03C2475.75 403.49 2476.57 403.07 2476.93 402.16C2476.95 402.12 2477.34 402.17 2477.51 402.27C2477.6 402.32 2477.66 402.68 2477.62 402.7C2476.9 403.11 2476.87 404.16 2476.01 404.41C2475.83 404.46 2475.62 404.43 2475.43 404.43Z" fill="white"/>
                    <path d="M2475.13 399.59C2475.27 398.99 2475.99 399.45 2476.45 399.57C2477.36 399.81 2476.74 400.53 2476.74 401.04C2476.74 401.19 2476.32 401.69 2476.24 401.16C2476.12 400.37 2474.73 401.05 2475.13 399.59Z" fill="white"/>
                    <path d="M2283.56 379.18C2283.56 379.93 2282.83 380.02 2282.44 380.42C2282.21 380.11 2281.74 379.76 2281.79 379.51C2281.9 378.96 2282.41 378.66 2283.01 378.64C2283.37 378.64 2283.5 378.87 2283.57 379.18H2283.56Z" fill="white"/>
                    <path d="M2381.19 514.98C2381.33 514.56 2381.16 513.91 2381.99 514.02C2382.58 514.1 2382.55 514.49 2382.46 514.9C2382.36 515.34 2382.49 515.98 2381.67 515.87C2381.07 515.79 2381.14 515.4 2381.19 514.97V514.98Z" fill="white"/>
                    <path d="M2298.43 297.82C2297.68 297.13 2297.6 296.47 2297.84 295.77C2297.9 295.61 2298.09 295.49 2298.21 295.35C2298.33 295.51 2298.42 295.71 2298.57 295.83C2299.14 296.32 2298.93 296.92 2298.79 297.48C2298.75 297.65 2298.48 297.77 2298.43 297.81V297.82Z" fill="white"/>
                    <path d="M2370.81 545.03C2370.66 545.01 2370.64 544.62 2370.75 544.2C2370.86 543.76 2370.7 543.11 2371.53 543.22C2372.11 543.3 2372.11 543.67 2372.02 544.09C2371.92 544.53 2372.04 545.18 2370.82 545.03H2370.81Z" fill="white"/>
                    <path d="M2410.8 492.64C2410.89 492.53 2411 492.31 2411.1 492.31C2411.8 492.36 2412.47 492.42 2412.84 493.2C2412.99 493.52 2413.04 493.72 2412.7 493.81C2412.17 493.95 2410.86 493.13 2410.8 492.64Z" fill="white"/>
                    <path d="M2312.99 422.01C2312.08 421.61 2312.82 420.95 2312.81 420.43C2312.81 420.19 2313.29 420.1 2313.57 420.23C2314.41 420.63 2313.81 421.29 2313.77 421.81C2313.75 422.05 2313.32 422.14 2312.99 422.01Z" fill="white"/>
                    <path d="M2433.55 425.32C2432.64 424.92 2433.38 424.26 2433.37 423.74C2433.37 423.5 2433.85 423.41 2434.13 423.54C2434.97 423.94 2434.37 424.6 2434.33 425.12C2434.31 425.36 2433.88 425.45 2433.55 425.32Z" fill="white"/>
                    <path d="M2377.11 298.19C2376.96 298.17 2376.94 297.78 2377.05 297.36C2377.16 296.92 2377 296.27 2377.83 296.38C2378.41 296.46 2378.41 296.83 2378.32 297.25C2378.22 297.69 2378.34 298.34 2377.12 298.19H2377.11Z" fill="white"/>
                    <path d="M2511.49 450.49C2512.1 450.28 2512.73 450.05 2513.37 449.85C2513.4 449.85 2513.53 450 2513.53 450.08C2513.55 450.51 2513.35 450.8 2512.97 451.04C2512.55 451.31 2512.16 451.65 2511.68 451.24C2511.57 451.14 2511.58 450.89 2511.49 450.49Z" fill="white"/>
                    <path d="M2500.56 394.35C2500.53 394.76 2500.51 395.21 2499.94 395.19C2499.21 395.16 2499.21 394.7 2499.33 394.14C2499.42 393.75 2499.39 393.29 2499.96 393.32C2500.68 393.35 2500.73 393.81 2500.56 394.36V394.35Z" fill="white"/>
                    <path d="M2474.74 336.9C2474.23 336.64 2473.99 336.46 2474.14 336.11C2474.43 335.42 2474.93 335 2475.71 335.11C2475.86 335.13 2475.99 335.35 2476.13 335.48C2475.65 335.97 2475.16 336.47 2474.73 336.91L2474.74 336.9Z" fill="white"/>
                    <path d="M2443.47 456.56C2442.92 456.55 2442.51 456.36 2442.57 456.11C2442.69 455.6 2442.34 454.79 2443.26 454.68C2443.57 454.64 2443.97 454.84 2443.93 455.12C2443.86 455.64 2444.04 456.36 2443.47 456.56Z" fill="white"/>
                    <path d="M2357.25 303.75C2356.83 303.66 2356.44 303.6 2356.56 303.01C2356.72 302.2 2357.28 302.52 2357.73 302.57C2358.14 302.61 2358.53 302.71 2358.41 303.3C2358.25 304.12 2357.69 303.76 2357.25 303.75Z" fill="white"/>
                    <path d="M2341.88 396.62C2341.48 397.53 2340.82 396.79 2340.3 396.8C2340.06 396.8 2339.97 396.32 2340.1 396.04C2340.5 395.2 2341.16 395.8 2341.68 395.84C2341.92 395.86 2342.01 396.29 2341.88 396.62Z" fill="white"/>
                    <path d="M2486.06 336.47C2486.03 336.88 2486.01 337.33 2485.44 337.31C2484.71 337.28 2484.71 336.82 2484.83 336.26C2484.92 335.87 2484.89 335.41 2485.46 335.44C2486.18 335.47 2486.23 335.93 2486.06 336.48V336.47Z" fill="white"/>
                    <path d="M2472.95 509.05C2472.9 509.76 2472.66 509.9 2472.34 509.88C2471.61 509.83 2471.63 509.36 2471.77 508.83C2471.84 508.56 2471.87 507.98 2472.08 508.1C2472.51 508.35 2472.8 508.87 2472.95 509.05Z" fill="white"/>
                    <path d="M2319.09 312.66C2318.95 312.56 2318.72 312.41 2318.5 312.25C2318.97 311.94 2319.31 311.33 2320 311.54C2320.3 311.63 2320.46 311.84 2320.38 312.21C2320.22 313.01 2319.67 312.68 2319.09 312.66Z" fill="white"/>
                    <path d="M2544.38 315.32C2544.37 315.04 2544.63 314.87 2544.93 314.89C2545.34 314.91 2545.77 315.03 2545.6 315.59C2545.48 315.98 2545.75 316.74 2545.04 316.64C2544.79 316.6 2544.28 316.01 2544.37 315.32H2544.38Z" fill="white"/>
                    <path d="M2519.12 505.55C2518.39 505.52 2518.29 504.81 2517.91 504.39C2518.18 504.33 2518.47 504.2 2518.73 504.24C2519.17 504.31 2519.8 504.19 2519.71 505C2519.67 505.37 2519.46 505.49 2519.13 505.55H2519.12Z" fill="white"/>
                    <path d="M2509.45 313.17C2509.42 312.74 2509.34 313.18 2509.7 313.01C2509.9 312.92 2510.34 312.99 2510.46 313.15C2510.8 313.59 2511.38 313.98 2511.06 314.68C2511.02 314.76 2510.88 314.87 2510.84 314.85C2510.11 314.51 2509.95 313.69 2509.44 313.16L2509.45 313.17Z" fill="white"/>
                    <path d="M2458.93 341.71C2458.63 341.22 2458.02 340.82 2458.38 340.15C2458.47 339.99 2458.94 339.9 2459.16 339.98C2459.86 340.24 2459.42 340.81 2459.41 341.25C2459.41 341.55 2459.29 341.78 2458.92 341.71H2458.93Z" fill="white"/>
                    <path d="M2389.37 413.06C2389.17 413.03 2388.94 413.04 2388.78 412.95C2388.67 412.89 2388.56 412.61 2388.61 412.53C2388.79 412.24 2390.68 412.53 2390.82 412.87C2390.85 412.95 2390.66 413.24 2390.58 413.24C2390.18 413.22 2389.78 413.13 2389.37 413.06Z" fill="white"/>
                    <path d="M2421.24 247.35C2420.61 247.29 2420.57 246.9 2420.69 246.48C2420.78 246.18 2420.99 246.03 2421.37 246.09C2421.94 246.19 2421.97 246.55 2421.85 246.96C2421.77 247.25 2421.57 247.5 2421.25 247.35H2421.24Z" fill="white"/>
                    <path d="M2499.63 511.19C2499.33 511.04 2499.16 510.88 2499.23 510.5C2499.33 509.93 2499.69 509.9 2500.1 510.01C2500.4 510.09 2500.55 510.31 2500.49 510.69C2500.39 511.26 2500.04 511.25 2499.63 511.19Z" fill="white"/>
                    <path d="M2281.96 442.18C2281.66 442.03 2281.49 441.87 2281.56 441.49C2281.66 440.92 2282.02 440.89 2282.43 441C2282.73 441.08 2282.88 441.3 2282.82 441.68C2282.72 442.25 2282.37 442.24 2281.96 442.18Z" fill="white"/>
                    <path d="M2288.76 324.18C2288.83 323.8 2288.93 323.46 2289.5 323.55C2289.88 323.61 2290.03 323.82 2290.02 324.13C2290 324.56 2289.85 324.89 2289.28 324.8C2288.9 324.74 2288.79 324.54 2288.76 324.18Z" fill="white"/>
                    <path d="M2392.57 537.42C2392.25 537.9 2391.86 538.26 2391.26 538.24C2391.1 538.24 2390.8 538 2390.81 537.98C2391.07 537.46 2391.46 537.11 2392.08 537.14C2392.24 537.14 2392.4 537.31 2392.58 537.42H2392.57Z" fill="white"/>
                    <path d="M2297.32 480.01C2296.95 480.02 2296.81 479.75 2296.82 479.44C2296.84 479.01 2296.99 478.68 2297.56 478.77C2297.94 478.83 2298.09 479.04 2298.08 479.35C2298.06 479.78 2297.9 480.14 2297.32 480V480.01Z" fill="white"/>
                    <path d="M2420.71 473.06C2420.41 472.91 2420.24 472.75 2420.31 472.37C2420.41 471.8 2420.77 471.77 2421.18 471.88C2421.48 471.96 2421.63 472.18 2421.57 472.56C2421.47 473.13 2421.12 473.12 2420.71 473.06Z" fill="white"/>
                    <path d="M2486.04 270.57C2485.69 270.4 2485.52 270.23 2485.59 269.85C2485.69 269.28 2486.05 269.25 2486.46 269.36C2486.76 269.44 2486.91 269.66 2486.85 270.04C2486.76 270.62 2486.38 270.57 2486.04 270.57Z" fill="white"/>
                    <path d="M2487.01 295.97C2487.07 295.38 2487.46 295.34 2487.87 295.46C2488.17 295.55 2488.32 295.76 2488.26 296.14C2488.16 296.71 2487.8 296.74 2487.39 296.62C2487.09 296.54 2486.88 296.32 2487.01 295.97Z" fill="white"/>
                    <path d="M2355.47 559.52C2355.86 559.6 2356.27 559.68 2356.13 560.24C2356.08 560.41 2355.96 560.56 2355.87 560.72C2355.57 560.4 2355.26 560.07 2354.96 559.75C2355.13 559.67 2355.3 559.6 2355.48 559.52H2355.47Z" fill="white"/>
                    <path d="M2343.93 504.66C2343.54 504.58 2343.13 504.5 2343.27 503.94C2343.32 503.77 2343.44 503.62 2343.53 503.46C2343.83 503.78 2344.14 504.11 2344.44 504.43C2344.27 504.51 2344.1 504.58 2343.92 504.66H2343.93Z" fill="white"/>
                    <path d="M2486.87 491.34C2486.62 491 2486.38 490.69 2486.14 490.37C2486.41 490.31 2486.7 490.15 2486.94 490.21C2487.47 490.34 2487.37 490.79 2487.24 491.16C2487.21 491.26 2486.97 491.29 2486.86 491.34H2486.87Z" fill="white"/>
                    <path d="M2343.29 387.93C2343.41 387.72 2343.52 387.42 2343.59 387.42C2344.13 387.49 2344.17 388.07 2344.49 388.38C2344.32 388.46 2344.14 388.6 2343.98 388.59C2343.55 388.57 2343.22 388.41 2343.29 387.93Z" fill="white"/>
                    <path d="M2254.76 345.56C2254.65 345.45 2254.44 345.34 2254.44 345.23C2254.44 344.84 2254.49 344.38 2255.04 344.44C2255.29 344.47 2255.51 344.71 2255.75 344.85C2255.43 345.08 2255.1 345.32 2254.77 345.56H2254.76Z" fill="white"/>
                    <path d="M2275.92 368.05C2275.85 368.61 2275.46 368.6 2275.06 368.5C2274.9 368.46 2274.78 368.26 2274.64 368.13C2275.03 367.92 2275.42 367.71 2275.81 367.5C2275.84 367.68 2275.88 367.86 2275.91 368.05H2275.92Z" fill="white"/>
                    <path d="M2465.93 477.17C2465.87 477.12 2465.65 477.01 2465.65 476.91C2465.65 476.52 2465.69 476.06 2466.23 476.1C2466.48 476.12 2466.71 476.36 2466.95 476.5C2466.62 476.72 2466.29 476.93 2465.93 477.17Z" fill="white"/>
                    <path d="M2426.53 468.97C2425.97 468.9 2425.98 468.51 2426.08 468.11C2426.12 467.95 2426.32 467.83 2426.45 467.69C2426.66 468.08 2426.87 468.47 2427.08 468.86C2426.9 468.89 2426.72 468.93 2426.53 468.96V468.97Z" fill="white"/>
                    <path d="M2256.71 415.34C2256.56 415.21 2256.42 415.09 2256.28 414.97C2256.66 414.74 2257.04 414.51 2257.42 414.29C2257.46 414.47 2257.55 414.65 2257.54 414.83C2257.51 415.38 2257.14 415.41 2256.72 415.34H2256.71Z" fill="white"/>
                    <path d="M2467.48 299.72C2467.41 300.28 2467.02 300.27 2466.62 300.17C2466.46 300.13 2466.34 299.93 2466.2 299.8C2466.59 299.59 2466.98 299.38 2467.37 299.17C2467.4 299.35 2467.44 299.53 2467.47 299.72H2467.48Z" fill="white"/>
                    <path d="M2471.95 257.2C2471.71 257.3 2471.53 257.43 2471.36 257.42C2470.93 257.4 2470.59 257.22 2470.74 256.69C2470.79 256.52 2470.93 256.37 2471.02 256.22C2471.32 256.54 2471.63 256.86 2471.95 257.2Z" fill="white"/>
                    <path d="M2548.29 474.27C2547.96 474.01 2548.15 473.7 2548.21 473.43C2548.23 473.35 2548.54 473.23 2548.62 473.28C2548.95 473.5 2548.75 473.83 2548.69 474.1C2548.67 474.19 2548.42 474.23 2548.29 474.28V474.27Z" fill="white"/>
                    <path d="M2523.13 484.5C2522.79 484.54 2522.51 484.63 2522.24 484.59C2522.09 484.57 2521.96 484.35 2521.82 484.23C2522.09 484.17 2522.36 484.05 2522.63 484.07C2522.78 484.07 2522.91 484.3 2523.12 484.5H2523.13Z" fill="white"/>
                    <path d="M2361.39 263.33C2361.06 263.07 2361.25 262.76 2361.31 262.49C2361.33 262.41 2361.64 262.29 2361.72 262.34C2362.05 262.56 2361.85 262.89 2361.79 263.16C2361.77 263.25 2361.52 263.29 2361.39 263.34V263.33Z" fill="white"/>
                    <path d="M2273.41 338.69C2273.37 338.35 2273.28 338.07 2273.32 337.8C2273.34 337.65 2273.56 337.52 2273.68 337.38C2273.74 337.65 2273.86 337.92 2273.84 338.19C2273.84 338.34 2273.61 338.47 2273.41 338.68V338.69Z" fill="white"/>
                    <path d="M2273.28 342.03C2273.32 341.87 2273.34 341.59 2273.46 341.35C2273.6 341.07 2273.82 341.11 2273.85 341.41C2273.88 341.67 2273.83 341.97 2273.72 342.21C2273.59 342.49 2273.41 342.45 2273.28 342.03Z" fill="white"/>
                    <path d="M2345.87 252.69C2345.77 252.85 2345.68 253.14 2345.58 253.14C2345.21 253.14 2345.17 252.86 2345.27 252.57C2345.33 252.4 2345.46 252.12 2345.56 252.12C2345.93 252.12 2345.95 252.4 2345.88 252.69H2345.87Z" fill="white"/>
                    <path d="M2494.92 506.01C2494.76 505.91 2494.47 505.82 2494.47 505.72C2494.47 505.35 2494.75 505.31 2495.04 505.41C2495.21 505.47 2495.49 505.6 2495.49 505.7C2495.49 506.07 2495.21 506.09 2494.92 506.02V506.01Z" fill="white"/>
                    <path d="M2265.98 421.64C2265.64 421.68 2265.36 421.77 2265.09 421.73C2264.94 421.71 2264.81 421.49 2264.67 421.37C2264.94 421.31 2265.21 421.19 2265.48 421.21C2265.63 421.21 2265.76 421.44 2265.97 421.64H2265.98Z" fill="white"/>
                    <path d="M2526.57 342.12C2526.47 342.03 2526.33 341.9 2526.19 341.77C2526.46 341.71 2526.73 341.59 2527 341.61C2527.14 341.61 2527.28 341.84 2527.42 341.97C2527.18 342.18 2526.92 342.26 2526.57 342.12Z" fill="white"/>
                    <path d="M2260.68 468.98C2260.7 468.68 2260.81 468.42 2261.16 468.54C2261.25 468.57 2261.29 468.88 2261.29 469.06C2261.29 469.36 2261.16 469.62 2260.81 469.5C2260.72 469.47 2260.72 469.16 2260.68 468.98Z" fill="white"/>
                    <path d="M2485.19 334.13C2485.21 333.83 2485.32 333.57 2485.67 333.69C2485.76 333.72 2485.8 334.03 2485.8 334.21C2485.8 334.51 2485.67 334.77 2485.32 334.65C2485.23 334.62 2485.23 334.31 2485.19 334.13Z" fill="white"/>
                    <path d="M2474.47 341.84C2474.49 341.54 2474.6 341.28 2474.95 341.4C2475.04 341.43 2475.08 341.74 2475.08 341.92C2475.08 342.22 2474.95 342.48 2474.6 342.36C2474.51 342.33 2474.51 342.02 2474.47 341.84Z" fill="white"/>
                    <path d="M2439.39 375.57C2439.05 375.61 2438.77 375.7 2438.5 375.66C2438.35 375.64 2438.22 375.42 2438.08 375.3C2438.35 375.24 2438.62 375.12 2438.89 375.14C2439.04 375.14 2439.17 375.37 2439.38 375.57H2439.39Z" fill="white"/>
                    <path d="M2474.77 483.21C2474.58 483.07 2474.36 482.91 2474.14 482.74C2474.31 482.66 2474.51 482.49 2474.65 482.53C2474.91 482.6 2475.13 482.8 2475.37 482.94C2475.2 483.02 2475.03 483.09 2474.77 483.21Z" fill="white"/>
                    <path d="M2384.74 441.92C2384.64 441.83 2384.5 441.7 2384.36 441.57C2384.63 441.51 2384.9 441.39 2385.17 441.41C2385.31 441.41 2385.45 441.64 2385.59 441.77C2385.35 441.98 2385.09 442.06 2384.74 441.92Z" fill="white"/>
                    <path d="M2369.34 487.74C2369 487.78 2368.72 487.87 2368.45 487.83C2368.3 487.81 2368.17 487.59 2368.03 487.47C2368.3 487.41 2368.57 487.29 2368.84 487.31C2368.99 487.31 2369.12 487.54 2369.33 487.74H2369.34Z" fill="white"/>
                    <path d="M2345.73 559.73C2345.4 559.47 2345.59 559.16 2345.65 558.89C2345.67 558.81 2345.98 558.69 2346.06 558.74C2346.39 558.96 2346.19 559.29 2346.13 559.56C2346.11 559.65 2345.86 559.69 2345.73 559.74V559.73Z" fill="white"/>
                    <path d="M2325.84 494.77C2325.86 494.47 2325.97 494.21 2326.32 494.33C2326.41 494.36 2326.45 494.67 2326.45 494.85C2326.45 495.15 2326.32 495.41 2325.97 495.29C2325.88 495.26 2325.88 494.95 2325.84 494.77Z" fill="white"/>
                    <path d="M2370.31 280.66C2370.21 280.82 2370.12 281.11 2370.02 281.11C2369.65 281.11 2369.61 280.83 2369.71 280.54C2369.77 280.37 2369.9 280.09 2370 280.09C2370.37 280.09 2370.39 280.37 2370.32 280.66H2370.31Z" fill="white"/>
                    <path d="M2370.1 382.16C2369.94 382.06 2369.65 381.97 2369.65 381.87C2369.65 381.5 2369.93 381.46 2370.22 381.56C2370.39 381.62 2370.67 381.75 2370.67 381.85C2370.67 382.22 2370.39 382.24 2370.1 382.17V382.16Z" fill="white"/>
                    <path d="M2353.99 380.47C2353.84 380.52 2353.7 380.59 2353.55 380.59C2353.5 380.59 2353.44 380.44 2353.38 380.36C2353.53 380.31 2353.67 380.24 2353.82 380.24C2353.87 380.24 2353.93 380.39 2353.99 380.47Z" fill="white"/>
                    <path d="M2245.83 417.03C2245.68 417.08 2245.54 417.15 2245.39 417.15C2245.34 417.15 2245.28 417 2245.22 416.92C2245.37 416.87 2245.51 416.8 2245.66 416.8C2245.71 416.8 2245.77 416.95 2245.83 417.03Z" fill="white"/>
                    <path d="M2469.18 353.49C2469.06 353.4 2468.94 353.3 2468.82 353.21C2468.9 353.15 2469 353.03 2469.05 353.05C2469.19 353.11 2469.31 353.22 2469.43 353.31C2469.35 353.37 2469.27 353.42 2469.18 353.49Z" fill="white"/>
                    <path d="M2490.82 309.9C2490.89 310 2490.95 310.07 2491 310.15C2490.85 310.2 2490.71 310.27 2490.56 310.27C2490.51 310.27 2490.45 310.12 2490.39 310.04C2490.54 309.99 2490.68 309.94 2490.82 309.89V309.9Z" fill="white"/>
                    <path d="M2477.74 399.53C2477.83 399.41 2477.93 399.29 2478.02 399.17C2478.08 399.25 2478.2 399.35 2478.18 399.4C2478.12 399.54 2478.01 399.66 2477.92 399.78C2477.86 399.7 2477.81 399.62 2477.74 399.53Z" fill="white"/>
                    <path d="M2437.58 426.62C2437.53 426.47 2437.46 426.33 2437.46 426.18C2437.46 426.13 2437.61 426.07 2437.69 426.01C2437.74 426.16 2437.81 426.3 2437.81 426.45C2437.81 426.5 2437.66 426.56 2437.58 426.62Z" fill="white"/>
                    <path d="M2424.28 475.76C2424.38 475.69 2424.45 475.63 2424.53 475.58C2424.58 475.73 2424.65 475.87 2424.65 476.02C2424.65 476.07 2424.5 476.13 2424.42 476.19C2424.37 476.04 2424.32 475.9 2424.27 475.76H2424.28Z" fill="white"/>
                    <path d="M2479.68 505.32C2479.56 505.23 2479.44 505.13 2479.32 505.04C2479.4 504.98 2479.5 504.86 2479.55 504.88C2479.69 504.94 2479.81 505.05 2479.93 505.14C2479.85 505.2 2479.77 505.25 2479.68 505.32Z" fill="white"/>
                    <path d="M2483 379.71C2482.93 379.61 2482.87 379.54 2482.82 379.46C2482.97 379.41 2483.11 379.34 2483.26 379.34C2483.31 379.34 2483.37 379.49 2483.43 379.57C2483.28 379.62 2483.14 379.67 2483 379.72V379.71Z" fill="white"/>
                    <path d="M2410.38 408.39C2410.45 408.49 2410.51 408.56 2410.56 408.64C2410.41 408.69 2410.27 408.76 2410.12 408.76C2410.07 408.76 2410.01 408.61 2409.95 408.53C2410.1 408.48 2410.24 408.43 2410.38 408.38V408.39Z" fill="white"/>
                    <path d="M2374.01 554.72C2373.93 554.78 2373.81 554.89 2373.78 554.88C2373.66 554.79 2373.51 554.68 2373.47 554.54C2373.39 554.26 2373.53 554.16 2373.77 554.32C2373.89 554.4 2373.93 554.58 2374.01 554.72Z" fill="white"/>
                    <path d="M2373.23 554.23C2373.16 554.09 2372.98 553.91 2373.02 553.83C2373.13 553.6 2373.33 553.6 2373.4 553.77C2373.46 553.92 2373.47 554.1 2373.5 554.27C2373.41 554.26 2373.32 554.24 2373.23 554.23Z" fill="white"/>
                    <path d="M2343.51 350.6C2343.39 350.51 2343.27 350.41 2343.15 350.32C2343.23 350.26 2343.33 350.14 2343.38 350.16C2343.52 350.22 2343.64 350.33 2343.76 350.42C2343.68 350.48 2343.6 350.53 2343.51 350.6Z" fill="white"/>
                    <path d="M2462.99 568.81C2462.87 568.72 2462.75 568.62 2462.63 568.53C2462.71 568.47 2462.81 568.35 2462.86 568.37C2463 568.43 2463.12 568.54 2463.24 568.63C2463.16 568.69 2463.08 568.74 2462.99 568.81Z" fill="white"/>
                    <path d="M2367.72 560.17C2367.65 560.07 2367.59 560 2367.54 559.92C2367.69 559.87 2367.83 559.8 2367.98 559.8C2368.03 559.8 2368.09 559.95 2368.15 560.03C2368 560.08 2367.86 560.13 2367.72 560.18V560.17Z" fill="white"/>
                    <path d="M2479.85 526.34C2479.97 526.43 2480.09 526.53 2480.21 526.62C2480.13 526.68 2480.03 526.8 2479.98 526.78C2479.84 526.72 2479.72 526.61 2479.6 526.52C2479.68 526.46 2479.76 526.41 2479.85 526.34Z" fill="white"/>
                    <path d="M2345.67 453.13C2345.55 453.04 2345.43 452.94 2345.31 452.85C2345.39 452.79 2345.49 452.67 2345.54 452.69C2345.68 452.75 2345.8 452.86 2345.92 452.95C2345.84 453.01 2345.76 453.06 2345.67 453.13Z" fill="white"/>
                    <path d="M2488.4 430.74C2488.5 430.67 2488.57 430.61 2488.65 430.56C2488.7 430.71 2488.77 430.85 2488.77 431C2488.77 431.05 2488.62 431.11 2488.54 431.17C2488.49 431.02 2488.44 430.88 2488.39 430.74H2488.4Z" fill="white"/>
                    <path d="M2360.06 524.64C2359.91 524.69 2359.77 524.76 2359.62 524.76C2359.57 524.76 2359.51 524.61 2359.45 524.53C2359.6 524.48 2359.74 524.41 2359.89 524.41C2359.94 524.41 2360 524.56 2360.06 524.64Z" fill="white"/>
                    <path d="M2489.01 444.4C2488.89 444.31 2488.77 444.21 2488.65 444.12C2488.73 444.06 2488.83 443.94 2488.88 443.96C2489.02 444.02 2489.14 444.13 2489.26 444.22L2489.01 444.4Z" fill="white"/>
                    <path d="M2379.43 390.21C2379.34 390.33 2379.24 390.45 2379.15 390.57C2379.09 390.49 2378.97 390.39 2378.99 390.34C2379.05 390.2 2379.16 390.08 2379.25 389.96C2379.31 390.04 2379.36 390.12 2379.43 390.21Z" fill="white"/>
                    <path d="M2336.6 251.48C2336.45 251.53 2336.31 251.6 2336.16 251.6C2336.11 251.6 2336.05 251.45 2335.99 251.37C2336.14 251.32 2336.28 251.25 2336.43 251.25C2336.48 251.25 2336.54 251.4 2336.6 251.48Z" fill="white"/>
                    <path d="M2628.42 401.1C2628.83 402.8 2627.76 403.66 2626.74 404.44C2626.37 404.73 2625.72 405.09 2625.58 405.3C2624.69 406.7 2623.65 405.04 2622.79 405.57C2622.4 404.66 2621.63 403.69 2621.74 402.84C2621.84 402.03 2622.06 401.03 2622.56 400.27C2623.04 399.55 2623.38 398.32 2624.71 399.32C2624.91 399.47 2625.39 399.21 2625.74 399.18C2627.31 399.05 2628.2 399.73 2628.42 401.09V401.1Z" fill="white"/>
                    <path d="M2507.22 327.86C2508.25 327.71 2509.1 328.41 2509.14 328.78C2509.32 330.18 2510.68 330.54 2511.25 331.58C2511.65 332.31 2511.78 332.82 2511.27 333.44C2510.42 334.47 2509.84 334.67 2508.98 334.21C2508.61 334.01 2508.23 333.87 2507.83 333.73C2506.11 333.12 2504.91 329.63 2506.52 328.29C2506.81 328.05 2507.04 327.85 2507.21 327.87L2507.22 327.86Z" fill="white"/>
                    <path d="M2534.28 407.94C2535 408.5 2536.27 408.1 2536.56 409.33C2536.6 409.5 2536.56 409.72 2536.66 409.85C2537.05 410.4 2537.66 410.84 2537.39 411.64C2537.17 412.27 2536.55 412.46 2536.07 412.8C2535.13 413.47 2533.52 413.18 2532.68 412.23C2532.02 411.49 2531.93 410.71 2532.27 409.76C2532.63 408.77 2533.58 408.51 2534.29 407.93L2534.28 407.94Z" fill="white"/>
                    <path d="M2433.13 482.63C2431.59 482.87 2430.81 482.41 2430.5 481.33C2430.33 480.73 2430.31 480.09 2430.18 479.48C2430.05 478.9 2430.28 478.69 2430.83 478.63C2431.45 478.57 2432.07 478.35 2432.68 478.36C2433.72 478.38 2434.51 478.97 2435.07 479.82C2435.19 479.99 2435.21 480.37 2435.1 480.53C2434.68 481.09 2434.49 481.74 2434.2 482.35C2434.06 482.65 2433.24 482.61 2433.12 482.64L2433.13 482.63Z" fill="white"/>
                    <path d="M2397.99 370.59C2398.67 370.42 2399.65 370.16 2400.11 371.19C2400.33 371.68 2400.74 372.6 2400.14 372.89C2399.49 373.21 2399.02 374.11 2398.11 373.8C2397.48 373.59 2396.88 373.14 2396.25 373.09C2395.67 373.04 2395.52 372.88 2395.43 372.36C2395.3 371.62 2395.67 371.15 2396.23 370.9C2396.7 370.69 2397.28 370.7 2398 370.59H2397.99Z" fill="white"/>
                    <path d="M2434.63 502C2435.33 501.87 2436.01 502.32 2436.16 503.01C2436.21 503.24 2435.32 505.62 2435.15 505.74C2434.75 506 2433.24 505.34 2433.31 505.29C2434.14 504.53 2432.8 504.19 2433.03 503.6C2433.25 503.03 2434.19 502.09 2434.64 502.01L2434.63 502Z" fill="white"/>
                    <path d="M2582.1 441.58C2582.05 441.21 2581.86 440.66 2582.22 440.6C2582.87 440.49 2583.53 440.07 2584.25 440.59C2584.65 440.87 2584.66 441.36 2584.98 441.6C2585.5 441.98 2585.63 442.3 2585.25 442.9C2584.84 443.54 2584.21 443.73 2583.69 443.46C2582.96 443.07 2582.03 442.69 2582.11 441.59L2582.1 441.58Z" fill="white"/>
                    <path d="M2509.56 383.08C2509 383.13 2508.32 383.54 2508.2 382.47C2508.17 382.23 2507.65 382.06 2507.38 381.84C2507.07 381.59 2506.78 381.3 2506.48 381.03C2507.06 380.88 2507.7 380.49 2508.2 380.63C2508.84 380.81 2509.35 381.42 2509.95 381.81C2510.28 382.02 2510.54 382.28 2510.44 382.65C2510.34 383.06 2509.9 383.01 2509.56 383.07V383.08Z" fill="white"/>
                    <path d="M2541.3 522.92C2541.13 522.95 2540.96 523 2540.78 523C2540.39 523 2539.89 523.38 2539.65 522.8C2539.41 522.21 2539.51 521.64 2539.98 521.14C2540.38 520.71 2540.69 520.87 2541.1 521.11C2541.36 521.27 2541.76 521.2 2542.03 521.36C2542.38 521.56 2542.87 521.74 2542.82 522.28C2542.77 522.92 2542.19 522.71 2541.82 522.83C2541.65 522.88 2541.48 522.89 2541.3 522.92Z" fill="white"/>
                    <path d="M2426 497.45C2425.72 498.22 2425.65 499.11 2425 499.71C2424.85 499.85 2424.52 499.91 2424.27 499.69C2423.96 499.41 2423.37 499.26 2423.5 498.74C2423.66 498.1 2423.95 497.49 2424.25 496.9C2424.61 496.2 2424.89 497.09 2425.25 496.99C2425.63 496.88 2425.94 496.96 2425.99 497.45H2426Z" fill="white"/>
                    <path d="M2552.23 373.47C2551.76 373.63 2551.45 373.51 2551.38 372.92C2551.28 372 2551.68 371.41 2552.49 371.24C2553.21 371.09 2553.68 371.48 2553.94 372C2554.24 372.61 2553.41 373.31 2552.23 373.47Z" fill="white"/>
                    <path d="M2586.04 300.43C2585.78 300.47 2585.51 300.56 2585.25 300.55C2584.64 300.52 2584.33 299.83 2584.69 299.34C2584.9 299.05 2585.06 298.71 2585.33 298.49C2585.67 298.2 2586.19 298.09 2586.54 298.29C2586.94 298.52 2586.94 299.2 2587.45 299.42C2587.1 299.78 2587 300.43 2586.31 300.39C2586.22 300.39 2586.13 300.42 2586.05 300.43H2586.04Z" fill="white"/>
                    <path d="M2473.48 482.65C2473.74 482.83 2473.94 483 2474.17 483.14C2474.74 483.49 2474.25 484.36 2474.96 484.67C2475.34 484.83 2475.4 485.23 2475.13 485.54C2474.87 485.83 2474.53 485.78 2474.22 485.54C2473.8 485.22 2473.34 484.94 2472.94 484.6C2472.75 484.44 2472.19 484.41 2472.58 483.91C2472.89 483.5 2473.18 483.08 2473.48 482.67V482.65Z" fill="white"/>
                    <path d="M2374.65 432.56C2374.65 431.99 2375.43 431.33 2376.08 431.35C2376.34 431.36 2377.45 432.58 2377.42 432.89C2377.33 433.68 2376.63 433.42 2376.19 433.48C2375.38 433.6 2374.65 433.1 2374.65 432.55V432.56Z" fill="white"/>
                    <path d="M2430.39 383.03C2430.56 383.55 2430.5 383.9 2429.88 383.97C2429.13 384.06 2428.32 383.66 2428.24 383.13C2428.16 382.6 2428.81 381.95 2429.55 381.84C2430.65 381.67 2430.16 382.63 2430.39 383.03Z" fill="white"/>
                    <path d="M2432.83 376.95C2433.28 377.14 2433.79 377.31 2433.92 377.86C2434.06 378.46 2433.12 379.24 2432.38 379.2C2431.9 379.17 2431.87 378.83 2431.85 378.48C2431.8 377.69 2432.26 376.96 2432.82 376.94L2432.83 376.95Z" fill="white"/>
                    <path d="M2454.56 456.28C2454.22 456.34 2454.04 456.35 2453.87 456.39C2453.24 456.58 2452.96 456.01 2452.52 455.79C2452.87 455.44 2452.96 454.81 2453.62 454.79C2453.79 454.79 2453.97 454.75 2454.14 454.71C2454.72 454.57 2455.1 454.58 2455.21 455.36C2455.32 456.1 2455.05 456.32 2454.57 456.28H2454.56Z" fill="white"/>
                    <path d="M2446.23 302.2C2446.37 302.81 2445.98 303 2445.66 302.92C2445.02 302.75 2444.4 302.46 2443.8 302.16C2443.24 301.89 2443.74 301.63 2443.87 301.39C2444.42 300.33 2445.06 301.42 2445.67 301.33C2446.37 301.23 2446.07 301.96 2446.24 302.2H2446.23Z" fill="white"/>
                    <path d="M2439.43 427.66C2440.13 427.41 2440.62 427.79 2440.9 428.32C2441.11 428.71 2440.22 429.89 2439.87 429.76C2439.2 429.52 2438.85 429.02 2438.8 428.28C2438.76 427.76 2439.08 427.72 2439.43 427.66Z" fill="white"/>
                    <path d="M2591.26 488.17C2591.26 488.7 2590.89 489.05 2590.48 489.34C2590.24 489.51 2589.18 488.48 2589.05 487.95C2588.93 487.47 2589.26 487.36 2589.62 487.32C2590.35 487.22 2591.17 487.64 2591.27 488.17H2591.26Z" fill="white"/>
                    <path d="M2424.12 462.13C2424.16 461.86 2424.3 461.57 2424.6 461.67C2425.31 461.91 2426.09 461.97 2426.72 462.47C2426.98 462.68 2427.05 462.97 2426.98 463.16C2426.91 463.34 2426.54 463.57 2426.38 463.52C2425.74 463.33 2425.13 463.04 2424.52 462.77C2424.26 462.66 2424.14 462.43 2424.13 462.13H2424.12Z" fill="white"/>
                    <path d="M2399.81 478.2C2400.08 478.13 2400.43 478.04 2400.6 478.48C2400.74 478.87 2400.45 479.1 2400.3 479.4C2400 480.01 2399.42 479.93 2398.95 479.94C2398.67 479.94 2398.14 479.56 2398.15 479.39C2398.18 479.04 2398.44 478.63 2398.74 478.41C2398.98 478.22 2399.41 478.27 2399.81 478.21V478.2Z" fill="white"/>
                    <path d="M2407.91 331.54C2407.77 331.12 2407.89 330.8 2408.48 330.74C2409.22 330.66 2410.01 331.06 2410.11 331.58C2410.27 332.46 2409.07 332.03 2408.97 332.75C2408.95 332.87 2408.07 332.21 2407.91 331.54Z" fill="white"/>
                    <path d="M2504.58 466.72C2504.72 466.8 2504.94 466.84 2504.99 466.96C2505.26 467.61 2504.5 468.96 2503.84 469.01C2503.61 469.03 2503.27 468.99 2503.17 468.84C2503.06 468.69 2503.11 468.34 2503.21 468.14C2503.67 467.26 2504.15 466.8 2504.59 466.72H2504.58Z" fill="white"/>
                    <path d="M2589.61 413.87C2589.57 413.61 2589.48 413.35 2589.49 413.09C2589.52 412.43 2590.1 412.63 2590.47 412.52C2590.95 412.38 2591.06 412.72 2591.11 413.09C2591.16 413.42 2591.28 413.75 2591.01 414.07C2590.73 414.4 2590.54 414.87 2590.02 414.74C2589.62 414.64 2589.68 414.19 2589.61 413.86V413.87Z" fill="white"/>
                    <path d="M2426.46 460.28C2425.9 460.26 2425.89 459.89 2425.87 459.55C2425.82 458.75 2426.28 458.03 2426.85 458.03C2427.32 458.03 2427.35 458.42 2427.37 458.76C2427.41 459.56 2426.96 460.26 2426.46 460.28Z" fill="white"/>
                    <path d="M2400.41 332.34C2400.79 331.85 2401.03 331.3 2401.78 331.26C2402.4 331.23 2402.47 331.53 2402.56 331.99C2402.66 332.49 2402.59 332.74 2402.02 332.91C2401.28 333.13 2400.92 332.6 2400.41 332.34Z" fill="white"/>
                    <path d="M2412.73 326.79C2412.88 326.86 2413.13 326.89 2413.16 327C2413.26 327.33 2413.43 327.75 2413.31 328.01C2413.12 328.44 2412.92 329.01 2412.29 329.04C2411.81 329.06 2411.78 328.67 2411.76 328.33C2411.72 327.52 2412.15 326.85 2412.73 326.8V326.79Z" fill="white"/>
                    <path d="M2605.64 446.58C2606 446.53 2606.39 446.49 2606.39 446.99C2606.39 447.64 2606.06 448.19 2605.4 448.22C2605.05 448.23 2604.68 447.83 2604.32 447.62C2604.68 447.17 2604.89 446.52 2605.64 446.59V446.58Z" fill="white"/>
                    <path d="M2545.3 318.29C2545.36 318.93 2544.74 319.7 2544.29 319.7C2543.82 319.7 2543.84 319.32 2543.76 318.98C2543.61 318.32 2543.9 317.82 2544.46 317.64C2544.63 317.59 2545.24 317.95 2545.3 318.29Z" fill="white"/>
                    <path d="M2428.72 411.4C2429.16 411.33 2429.63 411.28 2429.57 411.75C2429.49 412.34 2429.19 412.89 2428.99 413.47C2428.47 413.15 2428.02 412.82 2428 412.09C2427.97 411.4 2428.45 411.5 2428.73 411.41L2428.72 411.4Z" fill="white"/>
                    <path d="M2484.18 289.11C2484.89 288.91 2484.99 289.25 2485.11 289.75C2485.3 290.52 2484.89 290.62 2484.3 290.72C2483.7 290.82 2483.59 290.53 2483.48 290.03C2483.32 289.29 2483.62 289.06 2484.17 289.11H2484.18Z" fill="white"/>
                    <path d="M2424.16 421.9C2424.16 422.37 2424.62 423.07 2423.71 423.08C2423.21 423.08 2422.73 422.76 2422.58 422.22C2422.33 421.32 2423.35 421.42 2423.71 421.12C2424.15 420.74 2423.94 421.65 2424.16 421.89V421.9Z" fill="white"/>
                    <path d="M2390.98 493.79C2391.24 493.75 2391.5 493.67 2391.75 493.67C2392.22 493.68 2392.23 494.07 2392.26 494.4C2392.28 494.64 2392.46 494.92 2392.2 495.13C2391.9 495.36 2391.59 495.36 2391.29 495.1C2391.16 494.99 2391.01 494.9 2390.87 494.79C2390.61 494.59 2390.12 494.36 2390.13 494.17C2390.17 493.77 2390.66 493.87 2390.98 493.78V493.79Z" fill="white"/>
                    <path d="M2590.55 342.06C2590.64 341.43 2591.18 341.59 2591.56 341.52C2592.26 341.38 2592.14 341.98 2592.23 342.35C2592.31 342.7 2592.33 343.07 2591.82 343.1C2591.3 343.13 2590.57 342.53 2590.56 342.06H2590.55Z" fill="white"/>
                    <path d="M2450 359.21C2450.05 359.67 2450.11 360.04 2449.59 360.07C2449.07 360.1 2448.32 359.49 2448.33 359.03C2448.35 358.56 2448.75 358.61 2449.07 358.51C2449.85 358.27 2449.96 358.74 2450 359.21Z" fill="white"/>
                    <path d="M2405.7 452.95C2405.72 452.77 2405.66 452.4 2405.79 452.31C2406.15 452.06 2406.39 452.49 2406.55 452.68C2406.78 452.97 2407.77 452.9 2407.12 453.69C2406.9 453.95 2406.75 454.3 2406.34 454.25C2405.52 454.16 2405.98 453.41 2405.7 452.95Z" fill="white"/>
                    <path d="M2533.7 505.62C2534.08 505.81 2534.84 505.93 2534.58 506.41C2534.36 506.82 2534.72 507.48 2534.11 507.64C2533.74 507.73 2533.01 506.53 2533.12 506.03C2533.18 505.77 2533.39 505.62 2533.7 505.63V505.62Z" fill="white"/>
                    <path d="M2488.68 305.18C2488.68 305.18 2489.04 304.86 2489.39 304.62C2489.55 304.52 2490.39 305.41 2490.38 305.66C2490.34 306.32 2489.78 306.12 2489.41 306.23C2488.96 306.36 2488.8 306.09 2488.67 305.19L2488.68 305.18Z" fill="white"/>
                    <path d="M2520.33 304.51C2520.96 304.59 2520.78 305.13 2520.88 305.5C2521.01 305.97 2520.68 306.19 2520.34 306.11C2519.95 306.02 2519.59 305.74 2519.27 305.48C2519.15 305.39 2520.1 304.5 2520.34 304.5L2520.33 304.51Z" fill="white"/>
                    <path d="M2637.72 418.39C2637.89 418.83 2637.7 419.05 2637.45 419.09C2636.77 419.19 2636.34 418.82 2636.09 418.22C2635.96 417.91 2636.15 417.69 2636.41 417.65C2637.11 417.52 2637.5 417.95 2637.72 418.39Z" fill="white"/>
                    <path d="M2496.54 485.52C2496.54 484.95 2496.82 484.47 2497.27 484.4C2497.49 484.36 2498.06 484.69 2498.14 485.21C2498.24 485.91 2497.68 485.79 2497.3 485.88C2496.95 485.97 2496.62 485.97 2496.54 485.52Z" fill="white"/>
                    <path d="M2606.94 335.61C2607.01 335.85 2606.46 336.98 2606.33 336.9C2605.98 336.68 2605.61 336.41 2605.4 336.07C2605.21 335.75 2605.37 335.39 2605.83 335.38C2606.21 335.36 2606.68 335 2606.93 335.61H2606.94Z" fill="white"/>
                    <path d="M2491.62 487.92C2492.11 487.99 2492.64 488.23 2492.74 488.93C2492.77 489.19 2492.63 489.44 2492.3 489.41C2491.65 489.36 2491.16 489.07 2491.05 488.39C2491.01 488.14 2491.16 487.89 2491.63 487.91L2491.62 487.92Z" fill="white"/>
                    <path d="M2488.07 547.86C2488.55 547.75 2488.78 548.07 2488.7 548.41C2488.61 548.8 2488.33 549.16 2488.07 549.48C2487.98 549.6 2487.09 548.65 2487.09 548.41C2487.1 548.07 2487.48 547.86 2488.07 547.86Z" fill="white"/>
                    <path d="M2427.98 419.69C2428.35 419.64 2428.72 419.58 2428.76 420.08C2428.8 420.63 2428.49 421.1 2428.04 421.17C2427.82 421.2 2427.25 420.88 2427.17 420.36C2427.07 419.66 2427.64 419.81 2427.98 419.69Z" fill="white"/>
                    <path d="M2418.54 504.12C2419.02 504.28 2419.44 504.48 2419.59 505C2419.68 505.31 2419.51 505.55 2419.25 505.55C2418.82 505.56 2418.21 506.08 2418 505.23C2417.87 504.71 2418.4 504.51 2418.54 504.11V504.12Z" fill="white"/>
                    <path d="M2438.16 426.77C2438.3 426.84 2438.45 426.92 2438.59 426.99C2438.15 427.63 2437.6 428.2 2437.55 429.04C2437.55 429.08 2437.21 429.14 2437.05 429.11C2436.96 429.09 2436.82 428.82 2436.84 428.79C2437.31 428.26 2437.05 427.39 2437.69 426.95C2437.82 426.86 2438 426.83 2438.16 426.77Z" fill="white"/>
                    <path d="M2439.72 430.65C2439.77 431.18 2439.05 431 2438.64 431.03C2437.83 431.08 2438.14 430.32 2438 429.91C2437.96 429.79 2438.17 429.26 2438.38 429.68C2438.69 430.29 2439.65 429.35 2439.72 430.66V430.65Z" fill="white"/>
                    <path d="M2602.36 394.96C2602.15 394.34 2602.73 394.07 2602.94 393.64C2603.22 393.83 2603.7 393.99 2603.72 394.21C2603.78 394.69 2603.45 395.08 2602.96 395.25C2602.66 395.36 2602.49 395.19 2602.36 394.96Z" fill="white"/>
                    <path d="M2485.16 310.34C2485.16 310.72 2485.47 311.21 2484.77 311.34C2484.26 311.44 2484.18 311.11 2484.14 310.75C2484.1 310.36 2483.82 309.87 2484.52 309.74C2485.03 309.64 2485.09 309.98 2485.16 310.34Z" fill="white"/>
                    <path d="M2612.44 465.72C2613.25 466.08 2613.49 466.6 2613.48 467.24C2613.48 467.39 2613.36 467.54 2613.29 467.69C2613.15 467.59 2613.02 467.46 2612.87 467.39C2612.27 467.14 2612.28 466.59 2612.24 466.09C2612.23 465.94 2612.42 465.77 2612.44 465.72Z" fill="white"/>
                    <path d="M2485.44 282.87C2485.57 282.84 2485.69 283.16 2485.72 283.53C2485.75 283.92 2486.06 284.41 2485.35 284.55C2484.85 284.65 2484.75 284.34 2484.71 283.97C2484.67 283.58 2484.39 283.08 2485.44 282.87Z" fill="white"/>
                    <path d="M2466.99 336.76C2466.94 336.87 2466.91 337.09 2466.83 337.11C2466.25 337.26 2465.67 337.39 2465.16 336.86C2464.95 336.64 2464.86 336.49 2465.11 336.32C2465.51 336.06 2466.8 336.37 2466.99 336.76Z" fill="white"/>
                    <path d="M2566.51 367.9C2567.36 367.98 2566.94 368.72 2567.09 369.15C2567.16 369.34 2566.79 369.56 2566.52 369.52C2565.72 369.42 2566.04 368.72 2565.93 368.28C2565.88 368.07 2566.21 367.89 2566.51 367.9Z" fill="white"/>
                    <path d="M2466.77 398.18C2467.62 398.26 2467.2 399 2467.35 399.43C2467.42 399.62 2467.05 399.84 2466.78 399.8C2465.98 399.7 2466.3 399 2466.19 398.56C2466.14 398.35 2466.47 398.17 2466.77 398.18Z" fill="white"/>
                    <path d="M2547.84 486.96C2547.97 486.93 2548.09 487.25 2548.12 487.62C2548.15 488.01 2548.46 488.5 2547.75 488.64C2547.25 488.74 2547.15 488.43 2547.11 488.06C2547.07 487.67 2546.79 487.17 2547.84 486.96Z" fill="white"/>
                    <path d="M2395.99 398.88C2395.55 399.22 2395.09 399.58 2394.63 399.92C2394.61 399.94 2394.46 399.84 2394.43 399.77C2394.3 399.42 2394.38 399.13 2394.62 398.83C2394.89 398.49 2395.12 398.11 2395.63 398.32C2395.75 398.37 2395.8 398.58 2395.99 398.88Z" fill="white"/>
                    <path d="M2420.31 441.92C2420.23 441.57 2420.12 441.2 2420.59 441.07C2421.2 440.89 2421.32 441.27 2421.38 441.77C2421.42 442.11 2421.56 442.48 2421.09 442.62C2420.49 442.79 2420.33 442.43 2420.32 441.93L2420.31 441.92Z" fill="white"/>
                    <path d="M2457.2 481.94C2457.69 482.01 2457.93 482.09 2457.91 482.43C2457.86 483.08 2457.57 483.55 2456.89 483.68C2456.76 483.7 2456.6 483.56 2456.45 483.49C2456.71 482.95 2456.97 482.41 2457.2 481.94Z" fill="white"/>
                    <path d="M2450.09 375.28C2450.55 375.14 2450.93 375.18 2450.95 375.4C2450.99 375.85 2451.5 376.42 2450.78 376.76C2450.53 376.88 2450.16 376.82 2450.11 376.58C2450.03 376.13 2449.68 375.59 2450.09 375.27V375.28Z" fill="white"/>
                    <path d="M2562.59 476.97C2562.96 476.93 2563.29 476.87 2563.36 477.39C2563.45 478.1 2562.9 477.99 2562.52 478.07C2562.17 478.15 2561.83 478.17 2561.76 477.66C2561.66 476.95 2562.23 477.09 2562.59 476.97Z" fill="white"/>
                    <path d="M2549.78 396.62C2549.86 395.77 2550.6 396.19 2551.03 396.04C2551.22 395.97 2551.44 396.34 2551.4 396.61C2551.3 397.41 2550.6 397.09 2550.16 397.2C2549.95 397.25 2549.77 396.92 2549.78 396.62Z" fill="white"/>
                    <path d="M2448.04 485.39C2447.96 485.04 2447.85 484.67 2448.32 484.54C2448.93 484.36 2449.05 484.74 2449.11 485.24C2449.15 485.58 2449.29 485.95 2448.82 486.09C2448.22 486.26 2448.06 485.9 2448.05 485.4L2448.04 485.39Z" fill="white"/>
                    <path d="M2411.55 340.33C2411.39 339.73 2411.55 339.55 2411.83 339.48C2412.44 339.32 2412.56 339.71 2412.59 340.19C2412.61 340.43 2412.74 340.91 2412.53 340.87C2412.11 340.78 2411.73 340.44 2411.55 340.33Z" fill="white"/>
                    <path d="M2591.44 459.21C2591.58 459.25 2591.81 459.32 2592.04 459.39C2591.74 459.77 2591.63 460.36 2591 460.39C2590.73 460.39 2590.54 460.27 2590.5 459.94C2590.41 459.24 2590.95 459.36 2591.44 459.22V459.21Z" fill="white"/>
                    <path d="M2406.02 518.69C2406.1 518.91 2405.94 519.12 2405.69 519.2C2405.35 519.3 2404.96 519.31 2404.95 518.81C2404.95 518.45 2404.51 517.91 2405.12 517.8C2405.34 517.76 2405.92 518.1 2406.03 518.7L2406.02 518.69Z" fill="white"/>
                    <path d="M2374.66 355.83C2375.26 355.66 2375.54 356.21 2375.97 356.45C2375.76 356.57 2375.57 356.76 2375.34 356.8C2374.96 356.87 2374.47 357.14 2374.33 356.44C2374.26 356.12 2374.4 355.97 2374.66 355.83Z" fill="white"/>
                    <path d="M2435.24 510.9C2435.38 511.24 2435.33 510.86 2435.08 511.1C2434.94 511.23 2434.56 511.29 2434.41 511.19C2434.01 510.92 2433.43 510.76 2433.5 510.1C2433.5 510.03 2433.6 509.89 2433.64 509.9C2434.33 509.98 2434.69 510.61 2435.25 510.9H2435.24Z" fill="white"/>
                    <path d="M2468.84 473.67C2469.22 473.99 2469.83 474.15 2469.72 474.8C2469.69 474.95 2469.33 475.16 2469.12 475.15C2468.47 475.13 2468.68 474.54 2468.56 474.18C2468.48 473.93 2468.51 473.71 2468.83 473.67H2468.84Z" fill="white"/>
                    <path d="M2506.35 396.14C2506.52 396.11 2506.71 396.04 2506.87 396.07C2506.98 396.09 2507.15 396.28 2507.13 396.36C2507.06 396.65 2505.44 396.93 2505.22 396.69C2505.17 396.63 2505.25 396.34 2505.32 396.32C2505.66 396.23 2506.01 396.19 2506.36 396.14H2506.35Z" fill="white"/>
                    <path d="M2525.57 540.72C2526.11 540.6 2526.24 540.91 2526.26 541.28C2526.27 541.55 2526.14 541.73 2525.81 541.78C2525.32 541.85 2525.19 541.57 2525.18 541.2C2525.18 540.94 2525.26 540.68 2525.57 540.72Z" fill="white"/>
                    <path d="M2389.09 345.87C2389.38 345.91 2389.56 346 2389.61 346.33C2389.68 346.82 2389.4 346.95 2389.03 346.97C2388.76 346.98 2388.58 346.85 2388.53 346.52C2388.45 346.03 2388.75 345.94 2389.09 345.87Z" fill="white"/>
                    <path d="M2586.43 342.87C2586.72 342.91 2586.9 343 2586.95 343.33C2587.02 343.82 2586.74 343.95 2586.37 343.97C2586.1 343.98 2585.92 343.85 2585.87 343.52C2585.79 343.03 2586.09 342.94 2586.43 342.87Z" fill="white"/>
                    <path d="M2613.15 441.46C2613.2 441.79 2613.2 442.1 2612.71 442.18C2612.38 442.24 2612.2 442.1 2612.13 441.84C2612.03 441.48 2612.06 441.17 2612.55 441.09C2612.88 441.04 2613.03 441.17 2613.15 441.46Z" fill="white"/>
                    <path d="M2469.68 295.07C2469.81 294.59 2470.03 294.19 2470.53 294.04C2470.66 294 2470.97 294.11 2470.97 294.13C2470.9 294.63 2470.67 295.03 2470.16 295.17C2470.02 295.21 2469.85 295.11 2469.68 295.08V295.07Z" fill="white"/>
                    <path d="M2563.49 316.06C2563.79 315.95 2563.98 316.13 2564.05 316.39C2564.15 316.75 2564.12 317.06 2563.63 317.14C2563.3 317.2 2563.12 317.06 2563.05 316.8C2562.95 316.44 2562.98 316.11 2563.49 316.06Z" fill="white"/>
                    <path d="M2464.22 355.53C2464.51 355.57 2464.69 355.66 2464.74 355.99C2464.81 356.48 2464.53 356.61 2464.16 356.63C2463.89 356.64 2463.71 356.51 2463.66 356.18C2463.58 355.69 2463.88 355.6 2464.22 355.53Z" fill="white"/>
                    <path d="M2466.09 539.41C2466.43 539.45 2466.61 539.55 2466.66 539.87C2466.73 540.36 2466.45 540.49 2466.08 540.51C2465.81 540.52 2465.63 540.39 2465.58 540.06C2465.49 539.56 2465.82 539.5 2466.1 539.41H2466.09Z" fill="white"/>
                    <path d="M2458.35 518.85C2458.46 519.35 2458.16 519.49 2457.78 519.5C2457.51 519.51 2457.33 519.38 2457.28 519.05C2457.21 518.56 2457.49 518.43 2457.86 518.41C2458.13 518.4 2458.36 518.52 2458.35 518.84V518.85Z" fill="white"/>
                    <path d="M2494.05 266.79C2493.71 266.83 2493.35 266.88 2493.31 266.38C2493.3 266.23 2493.36 266.07 2493.39 265.91C2493.73 266.09 2494.07 266.27 2494.4 266.46C2494.28 266.57 2494.16 266.68 2494.04 266.79H2494.05Z" fill="white"/>
                    <path d="M2518.53 308.61C2518.87 308.57 2519.23 308.52 2519.27 309.02C2519.28 309.17 2519.22 309.33 2519.19 309.49C2518.85 309.31 2518.51 309.13 2518.18 308.94C2518.3 308.83 2518.42 308.72 2518.54 308.61H2518.53Z" fill="white"/>
                    <path d="M2404.99 358.65C2405.29 358.86 2405.57 359.05 2405.85 359.25C2405.64 359.37 2405.45 359.58 2405.23 359.6C2404.76 359.64 2404.72 359.25 2404.72 358.91C2404.72 358.82 2404.91 358.73 2404.98 358.66L2404.99 358.65Z" fill="white"/>
                    <path d="M2551 404.13C2550.96 404.33 2550.95 404.61 2550.89 404.63C2550.43 404.72 2550.24 404.25 2549.89 404.09C2550.01 403.98 2550.11 403.82 2550.25 403.78C2550.6 403.68 2550.92 403.72 2551 404.14V404.13Z" fill="white"/>
                    <path d="M2635.17 414.63C2635.29 414.69 2635.49 414.72 2635.52 414.81C2635.63 415.13 2635.71 415.52 2635.25 415.62C2635.04 415.67 2634.79 415.53 2634.56 415.48C2634.76 415.2 2634.96 414.92 2635.17 414.63Z" fill="white"/>
                    <path d="M2611.67 401.99C2611.57 401.51 2611.9 401.41 2612.25 401.39C2612.39 401.39 2612.54 401.51 2612.69 401.58C2612.43 401.86 2612.16 402.14 2611.9 402.42C2611.82 402.28 2611.74 402.14 2611.66 402L2611.67 401.99Z" fill="white"/>
                    <path d="M2426.03 364.53C2426.1 364.56 2426.3 364.58 2426.33 364.66C2426.44 364.98 2426.53 365.37 2426.07 365.48C2425.86 365.53 2425.61 365.4 2425.37 365.35C2425.58 365.08 2425.79 364.82 2426.02 364.52L2426.03 364.53Z" fill="white"/>
                    <path d="M2460.58 360.48C2461.06 360.38 2461.16 360.71 2461.18 361.06C2461.18 361.2 2461.06 361.35 2460.99 361.5C2460.71 361.24 2460.43 360.97 2460.15 360.71C2460.29 360.63 2460.43 360.55 2460.57 360.47L2460.58 360.48Z" fill="white"/>
                    <path d="M2614.47 357.96C2614.62 358.02 2614.77 358.08 2614.92 358.14C2614.67 358.43 2614.42 358.72 2614.18 359.01C2614.1 358.87 2613.98 358.75 2613.94 358.6C2613.81 358.14 2614.11 358.02 2614.47 357.95V357.96Z" fill="white"/>
                    <path d="M2473.33 510.44C2473.23 509.96 2473.56 509.86 2473.91 509.84C2474.05 509.84 2474.2 509.96 2474.35 510.03C2474.09 510.31 2473.82 510.59 2473.56 510.87C2473.48 510.73 2473.4 510.59 2473.32 510.45L2473.33 510.44Z" fill="white"/>
                    <path d="M2481.31 546.52C2481.48 546.38 2481.59 546.22 2481.73 546.18C2482.09 546.08 2482.42 546.13 2482.44 546.61C2482.44 546.76 2482.37 546.92 2482.34 547.07C2482 546.89 2481.67 546.71 2481.31 546.52Z" fill="white"/>
                    <path d="M2359.31 389.45C2359.65 389.58 2359.58 389.88 2359.6 390.12C2359.6 390.19 2359.38 390.38 2359.3 390.36C2358.97 390.27 2359.04 389.95 2359.02 389.71C2359.02 389.63 2359.21 389.53 2359.3 389.45H2359.31Z" fill="white"/>
                    <path d="M2377.13 374.18C2377.4 374.05 2377.6 373.9 2377.83 373.86C2377.96 373.83 2378.12 373.98 2378.27 374.04C2378.06 374.16 2377.87 374.33 2377.65 374.39C2377.53 374.42 2377.36 374.27 2377.13 374.17V374.18Z" fill="white"/>
                    <path d="M2570.26 511.23C2570.6 511.36 2570.53 511.66 2570.55 511.9C2570.55 511.97 2570.33 512.16 2570.25 512.14C2569.92 512.05 2569.99 511.73 2569.97 511.49C2569.97 511.41 2570.16 511.31 2570.25 511.23H2570.26Z" fill="white"/>
                    <path d="M2621.77 425.37C2621.9 425.64 2622.05 425.84 2622.09 426.07C2622.12 426.2 2621.97 426.36 2621.91 426.51C2621.79 426.3 2621.62 426.11 2621.56 425.89C2621.53 425.77 2621.68 425.6 2621.78 425.37H2621.77Z" fill="white"/>
                    <path d="M2620.96 422.6C2620.97 422.74 2621.03 422.98 2621 423.21C2620.96 423.48 2620.77 423.51 2620.66 423.26C2620.57 423.05 2620.52 422.79 2620.55 422.57C2620.58 422.3 2620.74 422.29 2620.96 422.6Z" fill="white"/>
                    <path d="M2585.9 515.7C2585.94 515.54 2585.94 515.28 2586.02 515.25C2586.32 515.15 2586.43 515.37 2586.43 515.63C2586.43 515.79 2586.4 516.05 2586.32 516.08C2586.02 516.18 2585.92 515.96 2585.9 515.7Z" fill="white"/>
                    <path d="M2394.38 348.83C2394.54 348.87 2394.8 348.87 2394.83 348.95C2394.93 349.25 2394.71 349.36 2394.45 349.36C2394.29 349.36 2394.03 349.33 2394 349.25C2393.9 348.95 2394.12 348.85 2394.38 348.83Z" fill="white"/>
                    <path d="M2605.15 355.33C2605.42 355.2 2605.62 355.05 2605.85 355.01C2605.98 354.98 2606.14 355.13 2606.29 355.19C2606.08 355.31 2605.89 355.48 2605.67 355.54C2605.55 355.57 2605.38 355.42 2605.15 355.32V355.33Z" fill="white"/>
                    <path d="M2413.28 491.85C2413.39 491.9 2413.54 491.97 2413.68 492.03C2413.47 492.15 2413.28 492.32 2413.06 492.38C2412.94 492.41 2412.77 492.26 2412.62 492.2C2412.76 491.96 2412.95 491.82 2413.28 491.85Z" fill="white"/>
                    <path d="M2596.54 315.07C2596.6 315.33 2596.58 315.57 2596.26 315.56C2596.17 315.56 2596.06 315.32 2596.01 315.17C2595.92 314.92 2595.96 314.68 2596.28 314.68C2596.37 314.68 2596.45 314.93 2596.53 315.07H2596.54Z" fill="white"/>
                    <path d="M2449.39 487.07C2449.45 487.33 2449.43 487.57 2449.11 487.56C2449.02 487.56 2448.91 487.32 2448.86 487.17C2448.77 486.92 2448.81 486.68 2449.13 486.68C2449.22 486.68 2449.3 486.93 2449.38 487.07H2449.39Z" fill="white"/>
                    <path d="M2456.07 477.82C2456.13 478.08 2456.11 478.32 2455.79 478.31C2455.7 478.31 2455.59 478.07 2455.54 477.92C2455.45 477.67 2455.49 477.43 2455.81 477.43C2455.9 477.43 2455.98 477.68 2456.06 477.82H2456.07Z" fill="white"/>
                    <path d="M2475.59 440.57C2475.86 440.44 2476.06 440.29 2476.29 440.25C2476.42 440.22 2476.58 440.37 2476.73 440.43C2476.52 440.55 2476.33 440.72 2476.11 440.78C2475.99 440.81 2475.82 440.66 2475.59 440.56V440.57Z" fill="white"/>
                    <path d="M2417.13 362.01C2417.32 362.07 2417.55 362.15 2417.78 362.22C2417.66 362.33 2417.54 362.53 2417.42 362.53C2417.19 362.54 2416.95 362.44 2416.72 362.39C2416.84 362.28 2416.96 362.17 2417.14 362.01H2417.13Z" fill="white"/>
                    <path d="M2502.24 371.21C2502.35 371.26 2502.5 371.33 2502.64 371.39C2502.43 371.51 2502.24 371.68 2502.02 371.74C2501.9 371.77 2501.73 371.62 2501.58 371.56C2501.72 371.32 2501.91 371.18 2502.24 371.21Z" fill="white"/>
                    <path d="M2502.33 329.44C2502.6 329.31 2502.8 329.16 2503.03 329.12C2503.16 329.09 2503.32 329.24 2503.47 329.3C2503.26 329.42 2503.07 329.59 2502.85 329.65C2502.73 329.68 2502.56 329.53 2502.33 329.43V329.44Z" fill="white"/>
                    <path d="M2501.98 263.95C2502.32 264.08 2502.25 264.38 2502.27 264.62C2502.27 264.69 2502.05 264.88 2501.97 264.86C2501.64 264.77 2501.71 264.45 2501.69 264.21C2501.69 264.13 2501.88 264.03 2501.97 263.95H2501.98Z" fill="white"/>
                    <path d="M2536.06 311.76C2536.12 312.02 2536.1 312.26 2535.78 312.25C2535.69 312.25 2535.58 312.01 2535.53 311.86C2535.44 311.61 2535.48 311.37 2535.8 311.37C2535.89 311.37 2535.97 311.62 2536.05 311.76H2536.06Z" fill="white"/>
                    <path d="M2558.21 499.47C2558.25 499.31 2558.25 499.05 2558.33 499.02C2558.63 498.92 2558.74 499.14 2558.74 499.4C2558.74 499.56 2558.71 499.82 2558.63 499.85C2558.33 499.95 2558.23 499.73 2558.21 499.47Z" fill="white"/>
                    <path d="M2530.6 416.2C2530.76 416.24 2531.02 416.24 2531.05 416.32C2531.15 416.62 2530.93 416.73 2530.67 416.73C2530.51 416.73 2530.25 416.7 2530.22 416.62C2530.12 416.32 2530.34 416.22 2530.6 416.2Z" fill="white"/>
                    <path d="M2544.27 413.17C2544.38 413.09 2544.48 412.99 2544.6 412.95C2544.64 412.93 2544.73 413.04 2544.8 413.09C2544.69 413.17 2544.59 413.27 2544.47 413.31C2544.43 413.33 2544.34 413.22 2544.27 413.17Z" fill="white"/>
                    <path d="M2622.93 353.6C2623.04 353.52 2623.14 353.42 2623.26 353.38C2623.3 353.36 2623.39 353.47 2623.46 353.52C2623.35 353.6 2623.25 353.7 2623.13 353.74C2623.09 353.76 2623 353.65 2622.93 353.6Z" fill="white"/>
                    <path d="M2457.22 466.82C2457.34 466.86 2457.47 466.91 2457.59 466.95C2457.54 467.02 2457.49 467.15 2457.44 467.14C2457.31 467.14 2457.18 467.07 2457.06 467.03C2457.11 466.96 2457.16 466.89 2457.22 466.81V466.82Z" fill="white"/>
                    <path d="M2451.41 508.48C2451.33 508.42 2451.26 508.37 2451.19 508.32C2451.3 508.24 2451.4 508.14 2451.52 508.1C2451.56 508.08 2451.65 508.19 2451.72 508.24C2451.61 508.32 2451.51 508.4 2451.41 508.48Z" fill="white"/>
                    <path d="M2437.6 431.42C2437.56 431.54 2437.51 431.67 2437.47 431.79C2437.4 431.74 2437.27 431.69 2437.28 431.64C2437.28 431.51 2437.35 431.38 2437.39 431.26C2437.46 431.31 2437.53 431.36 2437.61 431.42H2437.6Z" fill="white"/>
                    <path d="M2463.11 398.22C2463.19 398.33 2463.29 398.43 2463.33 398.55C2463.35 398.59 2463.24 398.68 2463.19 398.75C2463.11 398.64 2463.01 398.54 2462.97 398.42C2462.95 398.38 2463.06 398.29 2463.11 398.22Z" fill="white"/>
                    <path d="M2460.56 354.29C2460.5 354.37 2460.45 354.44 2460.4 354.51C2460.32 354.4 2460.22 354.3 2460.18 354.18C2460.16 354.14 2460.27 354.05 2460.32 353.98C2460.4 354.09 2460.48 354.19 2460.56 354.29Z" fill="white"/>
                    <path d="M2407.06 345.23C2407.18 345.27 2407.31 345.32 2407.43 345.36C2407.38 345.43 2407.33 345.56 2407.28 345.55C2407.15 345.55 2407.02 345.48 2406.9 345.44C2406.95 345.37 2407 345.3 2407.06 345.22V345.23Z" fill="white"/>
                    <path d="M2438.71 449.11C2438.79 449.17 2438.86 449.22 2438.93 449.27C2438.82 449.35 2438.72 449.45 2438.6 449.49C2438.56 449.51 2438.47 449.4 2438.4 449.35C2438.51 449.27 2438.61 449.19 2438.71 449.11Z" fill="white"/>
                    <path d="M2490.4 405.72C2490.32 405.66 2490.25 405.61 2490.18 405.56C2490.29 405.48 2490.39 405.38 2490.51 405.34C2490.55 405.32 2490.64 405.43 2490.71 405.48C2490.6 405.56 2490.5 405.64 2490.4 405.72Z" fill="white"/>
                    <path d="M2480.16 275.8C2480.21 275.73 2480.28 275.6 2480.3 275.61C2480.43 275.65 2480.58 275.7 2480.65 275.8C2480.79 276 2480.7 276.13 2480.46 276.06C2480.34 276.02 2480.26 275.89 2480.16 275.8Z" fill="white"/>
                    <path d="M2480.93 275.99C2481.03 276.08 2481.22 276.19 2481.21 276.26C2481.18 276.48 2481.02 276.54 2480.91 276.41C2480.81 276.3 2480.76 276.16 2480.69 276.03C2480.77 276.02 2480.85 276 2480.93 275.99Z" fill="white"/>
                    <path d="M2561.03 434.79C2561.15 434.83 2561.28 434.88 2561.4 434.92C2561.35 434.99 2561.3 435.12 2561.25 435.11C2561.12 435.11 2560.99 435.04 2560.87 435C2560.92 434.93 2560.97 434.86 2561.03 434.78V434.79Z" fill="white"/>
                    <path d="M2403.36 288.6C2403.48 288.64 2403.61 288.69 2403.73 288.73C2403.68 288.8 2403.63 288.93 2403.58 288.92C2403.45 288.92 2403.32 288.85 2403.2 288.81C2403.25 288.74 2403.3 288.67 2403.36 288.59V288.6Z" fill="white"/>
                    <path d="M2483.83 269.61C2483.91 269.67 2483.98 269.72 2484.05 269.77C2483.94 269.85 2483.84 269.95 2483.72 269.99C2483.68 270.01 2483.59 269.9 2483.52 269.85C2483.63 269.77 2483.73 269.69 2483.83 269.61Z" fill="white"/>
                    <path d="M2401.17 328.04C2401.05 328 2400.92 327.95 2400.8 327.91C2400.85 327.84 2400.9 327.71 2400.95 327.72C2401.08 327.72 2401.21 327.79 2401.33 327.83C2401.28 327.9 2401.23 327.97 2401.17 328.05V328.04Z" fill="white"/>
                    <path d="M2531.2 351.33C2531.32 351.37 2531.45 351.42 2531.57 351.46C2531.52 351.53 2531.47 351.66 2531.42 351.65C2531.29 351.65 2531.16 351.58 2531.04 351.54C2531.09 351.47 2531.14 351.4 2531.2 351.32V351.33Z" fill="white"/>
                    <path d="M2420.32 408.75C2420.26 408.83 2420.21 408.9 2420.16 408.97C2420.08 408.86 2419.98 408.76 2419.94 408.64C2419.92 408.6 2420.03 408.51 2420.08 408.44C2420.16 408.55 2420.24 408.65 2420.32 408.75Z" fill="white"/>
                    <path d="M2499.83 296.65C2499.94 296.57 2500.04 296.47 2500.16 296.43C2500.2 296.41 2500.29 296.52 2500.36 296.57C2500.25 296.65 2500.15 296.75 2500.03 296.79C2499.99 296.81 2499.9 296.7 2499.83 296.65Z" fill="white"/>
                    <path d="M2416.08 397.72C2416.2 397.76 2416.33 397.81 2416.45 397.85C2416.4 397.92 2416.35 398.05 2416.3 398.04C2416.17 398.04 2416.04 397.97 2415.92 397.93L2416.08 397.71V397.72Z" fill="white"/>
                    <path d="M2520.75 412.15C2520.79 412.03 2520.84 411.9 2520.88 411.78C2520.95 411.83 2521.08 411.88 2521.07 411.93C2521.07 412.06 2521 412.19 2520.96 412.31C2520.89 412.26 2520.82 412.21 2520.74 412.15H2520.75Z" fill="white"/>
                    <path d="M2593.82 514.16C2593.93 514.08 2594.03 513.98 2594.15 513.94C2594.19 513.92 2594.28 514.03 2594.35 514.08C2594.24 514.16 2594.14 514.26 2594.02 514.3C2593.98 514.32 2593.89 514.21 2593.82 514.16Z" fill="white"/>
                    <path d="M2473.68 505.74C2472.12 504.54 2472.44 503.04 2472.77 501.64C2472.89 501.12 2473.2 500.36 2473.16 500.07C2472.94 498.22 2475.03 498.92 2475.4 497.85C2476.39 498.37 2477.74 498.67 2478.25 499.48C2478.74 500.26 2479.26 501.28 2479.36 502.3C2479.46 503.27 2480.03 504.57 2478.16 504.65C2477.88 504.66 2477.65 505.23 2477.36 505.5C2476.09 506.73 2474.83 506.77 2473.67 505.74H2473.68Z" fill="white"/>
                    <path d="M2631.26 483.47C2630.47 484.33 2629.23 484.32 2628.93 484.04C2627.78 482.96 2626.34 483.6 2625.1 483.1C2624.23 482.75 2623.75 482.4 2623.76 481.49C2623.77 479.99 2624.14 479.41 2625.21 479.19C2625.68 479.1 2626.11 478.95 2626.55 478.79C2628.48 478.1 2632.01 480.29 2631.55 482.6C2631.47 483.01 2631.41 483.35 2631.25 483.46L2631.26 483.47Z" fill="white"/>
                    <path d="M2550.82 432.93C2549.8 432.95 2548.97 434.2 2547.85 433.33C2547.69 433.21 2547.57 432.99 2547.4 432.94C2546.68 432.74 2545.82 432.79 2545.49 431.89C2545.23 431.19 2545.64 430.57 2545.81 429.94C2546.15 428.69 2547.76 427.8 2549.17 428.03C2550.27 428.21 2550.91 428.82 2551.28 429.89C2551.67 431 2551.03 431.91 2550.82 432.92V432.93Z" fill="white"/>
                    <path d="M2585.89 296.04C2587.06 294.74 2588.07 294.58 2589.1 295.31C2589.67 295.71 2590.14 296.26 2590.69 296.69C2591.21 297.1 2591.17 297.45 2590.73 297.89C2590.23 298.39 2589.85 299.01 2589.31 299.44C2588.39 300.16 2587.28 300.21 2586.19 299.87C2585.97 299.8 2585.67 299.49 2585.66 299.27C2585.63 298.48 2585.33 297.78 2585.15 297.05C2585.06 296.69 2585.8 296.14 2585.88 296.03L2585.89 296.04Z" fill="white"/>
                    <path d="M2696.05 368.68C2695.57 369.31 2694.91 370.23 2693.78 369.66C2693.24 369.39 2692.23 368.88 2692.55 368.2C2692.89 367.46 2692.66 366.34 2693.67 365.96C2694.37 365.7 2695.2 365.66 2695.8 365.26C2696.34 364.89 2696.59 364.93 2697.03 365.31C2697.67 365.86 2697.68 366.53 2697.37 367.16C2697.11 367.68 2696.6 368.07 2696.05 368.69V368.68Z" fill="white"/>
                    <path d="M2570.83 280.24C2570.31 280.84 2569.4 280.94 2568.78 280.45C2568.58 280.29 2567.65 277.58 2567.73 277.36C2567.9 276.85 2569.67 276.35 2569.66 276.45C2569.48 277.7 2570.88 277.04 2571.11 277.72C2571.32 278.37 2571.17 279.86 2570.84 280.24H2570.83Z" fill="white"/>
                    <path d="M2485.29 437.59C2485.6 437.88 2486.15 438.22 2485.88 438.52C2485.4 439.08 2485.11 439.91 2484.12 439.97C2483.57 440.01 2483.22 439.59 2482.77 439.61C2482.04 439.65 2481.7 439.47 2481.61 438.67C2481.52 437.82 2481.93 437.2 2482.57 437.08C2483.48 436.9 2484.56 436.58 2485.28 437.58L2485.29 437.59Z" fill="white"/>
                    <path d="M2590.01 437.03C2590.46 436.58 2590.76 435.76 2591.63 436.59C2591.82 436.78 2592.4 436.56 2592.8 436.56C2593.25 436.56 2593.71 436.6 2594.16 436.62C2593.76 437.17 2593.48 437.96 2592.94 438.2C2592.26 438.5 2591.37 438.33 2590.58 438.41C2590.15 438.46 2589.73 438.41 2589.55 438.03C2589.35 437.6 2589.76 437.33 2590.01 437.04V437.03Z" fill="white"/>
                    <path d="M2463.07 337.77C2463.2 337.62 2463.32 337.46 2463.46 337.33C2463.79 337.05 2463.96 336.36 2464.58 336.7C2465.21 337.04 2465.53 337.61 2465.47 338.38C2465.43 339.04 2465.05 339.12 2464.52 339.19C2464.18 339.24 2463.88 339.58 2463.54 339.63C2463.09 339.7 2462.54 339.89 2462.2 339.39C2461.79 338.8 2462.44 338.56 2462.68 338.2C2462.79 338.04 2462.94 337.9 2463.07 337.76V337.77Z" fill="white"/>
                    <path d="M2581.58 278.08C2581.28 277.21 2580.7 276.39 2580.84 275.4C2580.87 275.17 2581.11 274.88 2581.49 274.9C2581.96 274.93 2582.58 274.64 2582.84 275.18C2583.16 275.85 2583.33 276.59 2583.49 277.32C2583.68 278.18 2582.79 277.61 2582.55 277.96C2582.3 278.32 2581.97 278.48 2581.58 278.08Z" fill="white"/>
                    <path d="M2559.67 475.71C2559.96 475.24 2560.32 475.12 2560.8 475.59C2561.54 476.32 2561.61 477.12 2561.03 477.84C2560.51 478.48 2559.83 478.48 2559.23 478.21C2558.53 477.9 2558.77 476.69 2559.68 475.72L2559.67 475.71Z" fill="white"/>
                    <path d="M2582.09 563.32C2582.29 563.1 2582.46 562.83 2582.7 562.66C2583.26 562.26 2584.01 562.63 2584.05 563.31C2584.07 563.71 2584.17 564.12 2584.1 564.51C2584.01 565 2583.64 565.47 2583.19 565.55C2582.67 565.63 2582.2 565.04 2581.59 565.21C2581.64 564.65 2581.27 564.01 2581.89 563.55C2581.97 563.49 2582.02 563.4 2582.09 563.33V563.32Z" fill="white"/>
                    <path d="M2550.74 324.68C2550.39 324.7 2550.09 324.7 2549.79 324.74C2549.05 324.84 2548.85 323.73 2548.01 323.97C2547.57 324.09 2547.23 323.79 2547.25 323.33C2547.27 322.9 2547.6 322.7 2548.05 322.69C2548.64 322.68 2549.24 322.59 2549.83 322.61C2550.11 322.61 2550.62 322.25 2550.64 322.96C2550.65 323.54 2550.7 324.11 2550.74 324.69V324.68Z" fill="white"/>
                    <path d="M2672.39 298.13C2672.8 298.62 2672.59 299.76 2672 300.19C2671.77 300.36 2669.93 300.09 2669.74 299.8C2669.26 299.05 2670.06 298.78 2670.39 298.41C2671.01 297.73 2672 297.65 2672.4 298.13H2672.39Z" fill="white"/>
                    <path d="M2659 380.86C2658.48 380.52 2658.29 380.18 2658.77 379.68C2659.36 379.07 2660.35 378.84 2660.8 379.24C2661.25 379.64 2661.14 380.68 2660.58 381.3C2659.74 382.23 2659.49 381.05 2659.01 380.86H2659Z" fill="white"/>
                    <path d="M2661.19 387.88C2660.66 388.04 2660.09 388.25 2659.59 387.86C2659.05 387.44 2659.31 386.09 2659.98 385.6C2660.42 385.28 2660.69 385.57 2660.95 385.85C2661.56 386.5 2661.67 387.46 2661.19 387.88Z" fill="white"/>
                    <path d="M2585.94 334.22C2586.2 333.93 2586.35 333.79 2586.46 333.63C2586.87 333.02 2587.52 333.31 2588.07 333.19C2588.02 333.74 2588.38 334.35 2587.82 334.84C2587.67 334.97 2587.55 335.12 2587.43 335.28C2587.03 335.81 2586.69 336.07 2586.04 335.48C2585.42 334.91 2585.5 334.53 2585.94 334.22Z" fill="white"/>
                    <path d="M2702.6 462.5C2702.04 462.06 2702.25 461.62 2702.59 461.47C2703.27 461.16 2704.02 460.98 2704.75 460.81C2705.43 460.65 2705.18 461.23 2705.24 461.53C2705.51 462.84 2704.18 462.35 2703.71 462.86C2703.17 463.45 2702.91 462.6 2702.6 462.51V462.5Z" fill="white"/>
                    <path d="M2619.44 348.4C2619.01 349.12 2618.31 349.13 2617.69 348.87C2617.23 348.68 2617.17 347.02 2617.56 346.88C2618.31 346.61 2618.97 346.81 2619.54 347.41C2619.94 347.83 2619.69 348.1 2619.43 348.4H2619.44Z" fill="white"/>
                    <path d="M2444.22 403.51C2443.85 403.04 2443.91 402.48 2444.07 401.93C2444.16 401.61 2445.81 401.76 2446.31 402.13C2446.76 402.46 2446.55 402.79 2446.26 403.08C2445.69 403.68 2444.69 403.9 2444.22 403.51Z" fill="white"/>
                    <path d="M2608.29 307.5C2608.45 307.76 2608.53 308.12 2608.2 308.24C2607.41 308.54 2606.69 309.03 2605.79 309.04C2605.41 309.04 2605.15 308.84 2605.07 308.62C2605 308.42 2605.16 307.95 2605.34 307.88C2606.03 307.6 2606.77 307.41 2607.5 307.21C2607.81 307.12 2608.08 307.24 2608.3 307.49L2608.29 307.5Z" fill="white"/>
                    <path d="M2618.06 276.24C2617.87 276.49 2617.64 276.82 2617.18 276.55C2616.78 276.32 2616.87 275.9 2616.79 275.54C2616.62 274.8 2617.18 274.45 2617.59 274.11C2617.83 273.91 2618.57 273.87 2618.68 274.03C2618.9 274.36 2618.96 274.9 2618.86 275.3C2618.78 275.63 2618.38 275.89 2618.07 276.24H2618.06Z" fill="white"/>
                    <path d="M2715.14 409.74C2715.56 410.01 2715.68 410.37 2715.21 410.84C2714.62 411.43 2713.65 411.65 2713.2 411.26C2712.43 410.61 2713.78 410.13 2713.36 409.44C2713.29 409.32 2714.53 409.28 2715.14 409.74Z" fill="white"/>
                    <path d="M2534.96 360.64C2534.78 360.67 2534.56 360.79 2534.43 360.73C2533.73 360.36 2533.44 358.64 2533.98 358.12C2534.17 357.94 2534.49 357.73 2534.69 357.79C2534.89 357.85 2535.1 358.18 2535.15 358.43C2535.37 359.52 2535.28 360.27 2534.96 360.64Z" fill="white"/>
                    <path d="M2498.42 467.06C2498.64 467.26 2498.9 467.42 2499.08 467.66C2499.52 468.26 2498.87 468.49 2498.62 468.85C2498.3 469.32 2497.97 469.09 2497.66 468.81C2497.38 468.55 2497.05 468.36 2497.05 467.88C2497.05 467.4 2496.89 466.84 2497.44 466.59C2497.86 466.39 2498.13 466.82 2498.42 467.06Z" fill="white"/>
                    <path d="M2607.57 310.78C2608.07 310.4 2608.34 310.72 2608.6 311C2609.21 311.67 2609.32 312.61 2608.82 313.02C2608.41 313.36 2608.11 313.04 2607.85 312.75C2607.24 312.08 2607.15 311.15 2607.57 310.78Z" fill="white"/>
                    <path d="M2721.12 403.71C2721.13 404.41 2721.32 405.06 2720.69 405.62C2720.17 406.09 2719.9 405.88 2719.49 405.53C2719.05 405.16 2718.93 404.9 2719.31 404.35C2719.8 403.63 2720.48 403.84 2721.12 403.7V403.71Z" fill="white"/>
                    <path d="M2714.32 417.3C2714.14 417.35 2713.9 417.49 2713.8 417.43C2713.48 417.22 2713.03 416.97 2712.95 416.66C2712.81 416.15 2712.58 415.51 2713.11 415.04C2713.52 414.68 2713.82 415 2714.07 415.28C2714.69 415.96 2714.79 416.84 2714.32 417.3Z" fill="white"/>
                    <path d="M2461.23 449.95C2460.95 450.25 2460.64 450.57 2460.29 450.13C2459.83 449.56 2459.72 448.85 2460.29 448.35C2460.58 448.09 2461.19 448.18 2461.65 448.11C2461.65 448.76 2461.93 449.47 2461.24 449.95H2461.23Z" fill="white"/>
                    <path d="M2604.89 518.84C2604.38 518.33 2604.38 517.21 2604.77 516.89C2605.18 516.56 2605.44 516.9 2605.74 517.14C2606.34 517.61 2606.44 518.25 2606.08 518.81C2605.97 518.98 2605.18 519.1 2604.89 518.84Z" fill="white"/>
                    <path d="M2640.31 354.96C2639.98 355.34 2639.6 355.71 2639.32 355.25C2638.97 354.68 2638.84 353.99 2638.61 353.34C2639.29 353.25 2639.91 353.22 2640.46 353.84C2640.98 354.42 2640.49 354.67 2640.31 354.95V354.96Z" fill="white"/>
                    <path d="M2678.85 500.86C2678.37 501.54 2678.04 501.31 2677.58 500.96C2676.87 500.42 2677.15 500.05 2677.59 499.54C2678.04 499.02 2678.34 499.2 2678.79 499.56C2679.46 500.09 2679.35 500.51 2678.84 500.86H2678.85Z" fill="white"/>
                    <path d="M2636.83 342.57C2636.5 342.15 2635.6 341.88 2636.38 341.22C2636.81 340.86 2637.46 340.8 2637.98 341.16C2638.83 341.77 2637.87 342.4 2637.78 342.92C2637.66 343.56 2637.2 342.62 2636.84 342.57H2636.83Z" fill="white"/>
                    <path d="M2614.67 256.39C2614.47 256.61 2614.31 256.86 2614.08 257.04C2613.66 257.36 2613.38 257.04 2613.11 256.76C2612.92 256.56 2612.57 256.45 2612.65 256.08C2612.75 255.66 2613.02 255.45 2613.47 255.46C2613.67 255.46 2613.86 255.43 2614.06 255.43C2614.43 255.43 2615.02 255.27 2615.14 255.44C2615.39 255.81 2614.89 256.08 2614.68 256.38L2614.67 256.39Z" fill="white"/>
                    <path d="M2548.61 530.27C2548.98 530.88 2548.39 531.13 2548.12 531.46C2547.61 532.08 2547.29 531.47 2546.95 531.21C2546.63 530.96 2546.35 530.66 2546.78 530.27C2547.21 529.87 2548.28 529.88 2548.62 530.27H2548.61Z" fill="white"/>
                    <path d="M2658.83 415.52C2658.45 415.16 2658.15 414.88 2658.58 414.48C2659.01 414.08 2660.1 414.08 2660.41 414.48C2660.73 414.9 2660.35 415.15 2660.14 415.46C2659.63 416.23 2659.2 415.89 2658.84 415.51L2658.83 415.52Z" fill="white"/>
                    <path d="M2630.85 302.42C2630.96 302.59 2631.27 302.87 2631.22 303.04C2631.08 303.51 2630.57 303.32 2630.3 303.26C2629.89 303.18 2629.09 303.93 2629.09 302.78C2629.09 302.4 2628.98 301.99 2629.37 301.74C2630.15 301.24 2630.28 302.22 2630.85 302.41V302.42Z" fill="white"/>
                    <path d="M2481.97 347.44C2481.51 347.55 2480.76 347.98 2480.64 347.38C2480.54 346.87 2479.76 346.55 2480.18 345.98C2480.44 345.64 2481.93 346.16 2482.19 346.68C2482.33 346.95 2482.24 347.23 2481.97 347.45V347.44Z" fill="white"/>
                    <path d="M2663.51 490.05C2663.51 490.05 2663.43 490.58 2663.28 491.04C2663.22 491.24 2661.85 491.07 2661.68 490.84C2661.24 490.24 2661.88 490.01 2662.12 489.66C2662.42 489.23 2662.75 489.36 2663.5 490.05H2663.51Z" fill="white"/>
                    <path d="M2636.43 513.11C2635.82 513.49 2635.6 512.88 2635.25 512.64C2634.8 512.33 2634.93 511.89 2635.29 511.72C2635.7 511.52 2636.21 511.51 2636.67 511.51C2636.84 511.51 2636.65 512.96 2636.43 513.12V513.11Z" fill="white"/>
                    <path d="M2453.31 497.29C2452.85 497.02 2452.85 496.7 2453.05 496.49C2453.57 495.92 2454.21 495.94 2454.85 496.28C2455.18 496.46 2455.17 496.78 2454.98 497.01C2454.46 497.62 2453.82 497.52 2453.31 497.3V497.29Z" fill="white"/>
                    <path d="M2528.61 338.56C2529.02 339.05 2529.11 339.67 2528.77 340.06C2528.6 340.25 2527.87 340.37 2527.44 339.97C2526.86 339.43 2527.44 339.14 2527.69 338.8C2527.93 338.48 2528.22 338.24 2528.61 338.57V338.56Z" fill="white"/>
                    <path d="M2538.9 547.53C2538.66 547.37 2538.34 545.99 2538.51 545.97C2538.97 545.92 2539.48 545.88 2539.9 546.03C2540.29 546.17 2540.4 546.6 2540.01 546.94C2539.69 547.22 2539.54 547.87 2538.89 547.52L2538.9 547.53Z" fill="white"/>
                    <path d="M2531.19 332.97C2530.71 333.26 2530.08 333.43 2529.5 332.88C2529.29 332.68 2529.24 332.36 2529.54 332.14C2530.14 331.72 2530.77 331.63 2531.35 332.14C2531.57 332.33 2531.61 332.65 2531.18 332.97H2531.19Z" fill="white"/>
                    <path d="M2491.71 278.25C2491.37 278.69 2490.94 278.58 2490.77 278.22C2490.57 277.81 2490.56 277.3 2490.56 276.84C2490.56 276.67 2492.01 276.86 2492.17 277.08C2492.4 277.38 2492.22 277.84 2491.71 278.26V278.25Z" fill="white"/>
                    <path d="M2635.07 347.21C2634.78 347.52 2634.5 347.83 2634.11 347.42C2633.68 346.98 2633.62 346.34 2633.97 345.96C2634.14 345.77 2634.87 345.65 2635.3 346.05C2635.88 346.59 2635.28 346.87 2635.07 347.21Z" fill="white"/>
                    <path d="M2583.33 266.97C2582.8 267.17 2582.29 267.3 2581.79 266.95C2581.49 266.74 2581.47 266.42 2581.69 266.23C2582.06 265.91 2582.22 265.03 2583.01 265.61C2583.49 265.97 2583.17 266.52 2583.33 266.97Z" fill="white"/>
                    <path d="M2621.18 348.27C2621 348.31 2620.83 348.34 2620.65 348.38C2620.58 347.52 2620.65 346.62 2620.09 345.85C2620.07 345.82 2620.31 345.52 2620.48 345.43C2620.57 345.38 2620.89 345.52 2620.89 345.56C2620.85 346.36 2621.7 346.93 2621.46 347.77C2621.41 347.95 2621.27 348.1 2621.17 348.26L2621.18 348.27Z" fill="white"/>
                    <path d="M2617.06 346C2616.64 345.58 2617.4 345.22 2617.73 344.91C2618.4 344.29 2618.67 345.17 2619.08 345.43C2619.2 345.51 2619.39 346.11 2618.92 345.9C2618.21 345.59 2618.05 347.09 2617.06 346Z" fill="white"/>
                    <path d="M2500.75 492.58C2501.37 492.97 2501.06 493.62 2501.18 494.15C2500.81 494.18 2500.28 494.38 2500.09 494.21C2499.69 493.83 2499.71 493.26 2500.01 492.76C2500.19 492.46 2500.46 492.48 2500.74 492.58H2500.75Z" fill="white"/>
                    <path d="M2662.92 483.05C2662.65 482.72 2662.03 482.51 2662.55 481.9C2662.93 481.45 2663.23 481.68 2663.52 481.97C2663.83 482.28 2664.42 482.51 2663.91 483.12C2663.53 483.57 2663.24 483.31 2662.92 483.05Z" fill="white"/>
                    <path d="M2441.71 438.1C2440.75 438.36 2440.17 438.08 2439.72 437.52C2439.62 437.39 2439.62 437.17 2439.57 437C2439.76 436.99 2439.97 437.01 2440.15 436.96C2440.85 436.75 2441.23 437.23 2441.62 437.64C2441.74 437.76 2441.7 438.05 2441.71 438.11V438.1Z" fill="white"/>
                    <path d="M2682.18 507.18C2682.09 507.3 2681.76 507.1 2681.47 506.8C2681.17 506.49 2680.55 506.27 2681.07 505.65C2681.43 505.21 2681.74 505.41 2682.04 505.7C2682.35 506.01 2682.95 506.25 2682.19 507.18H2682.18Z" fill="white"/>
                    <path d="M2659.98 447.14C2659.94 447.01 2659.81 446.8 2659.87 446.73C2660.27 446.18 2660.68 445.66 2661.5 445.76C2661.84 445.8 2662.03 445.87 2661.93 446.19C2661.77 446.7 2660.42 447.35 2659.98 447.14Z" fill="white"/>
                    <path d="M2551.19 490.69C2550.39 491.23 2550.24 490.28 2549.8 490.02C2549.6 489.9 2549.77 489.45 2550.03 489.29C2550.8 488.81 2551.02 489.65 2551.43 489.95C2551.62 490.09 2551.46 490.49 2551.19 490.69Z" fill="white"/>
                    <path d="M2616.56 393.49C2615.76 394.03 2615.61 393.08 2615.17 392.82C2614.97 392.7 2615.14 392.25 2615.4 392.09C2616.17 391.61 2616.39 392.45 2616.8 392.75C2616.99 392.89 2616.83 393.29 2616.56 393.49Z" fill="white"/>
                    <path d="M2482.91 373.73C2482.82 373.85 2482.49 373.65 2482.2 373.35C2481.9 373.04 2481.28 372.82 2481.8 372.2C2482.16 371.76 2482.47 371.96 2482.77 372.25C2483.08 372.56 2483.68 372.8 2482.92 373.73H2482.91Z" fill="white"/>
                    <path d="M2677.71 342.62C2677.85 342.01 2678 341.37 2678.16 340.75C2678.16 340.72 2678.37 340.7 2678.44 340.74C2678.8 340.95 2678.94 341.27 2678.94 341.7C2678.94 342.19 2679.02 342.68 2678.43 342.86C2678.29 342.9 2678.09 342.76 2677.72 342.63L2677.71 342.62Z" fill="white"/>
                    <path d="M2625.97 322.4C2626.29 322.64 2626.65 322.89 2626.33 323.34C2625.93 323.92 2625.55 323.68 2625.15 323.29C2624.87 323.02 2624.49 322.8 2624.8 322.34C2625.2 321.76 2625.6 321.97 2625.96 322.39L2625.97 322.4Z" fill="white"/>
                    <path d="M2565.41 313.74C2564.93 314.03 2564.66 314.12 2564.45 313.82C2564.03 313.22 2563.95 312.6 2564.45 312.01C2564.55 311.9 2564.79 311.91 2564.97 311.86C2565.12 312.51 2565.28 313.17 2565.41 313.75V313.74Z" fill="white"/>
                    <path d="M2647.35 401.59C2647.05 402.04 2646.69 402.27 2646.52 402.09C2646.17 401.72 2645.31 401.6 2645.7 400.78C2645.83 400.5 2646.2 400.29 2646.41 400.46C2646.8 400.79 2647.49 401.02 2647.35 401.59Z" fill="white"/>
                    <path d="M2477.15 392.91C2476.86 393.21 2476.6 393.49 2476.18 393.09C2475.59 392.54 2476.16 392.24 2476.43 391.9C2476.68 391.58 2476.96 391.32 2477.38 391.72C2477.97 392.27 2477.38 392.55 2477.15 392.91Z" fill="white"/>
                    <path d="M2545.36 453.79C2545.9 454.59 2544.95 454.74 2544.69 455.18C2544.57 455.38 2544.12 455.21 2543.96 454.95C2543.48 454.18 2544.32 453.96 2544.62 453.55C2544.76 453.36 2545.16 453.52 2545.36 453.79Z" fill="white"/>
                    <path d="M2570.94 304.23C2571.26 304.47 2571.62 304.72 2571.3 305.17C2570.9 305.75 2570.52 305.51 2570.12 305.12C2569.84 304.85 2569.46 304.63 2569.77 304.17C2570.17 303.59 2570.57 303.8 2570.93 304.22L2570.94 304.23Z" fill="white"/>
                    <path d="M2705.74 404.67C2706.3 405.08 2706.29 405.35 2706.1 405.6C2705.68 406.18 2705.3 405.91 2704.94 405.52C2704.75 405.32 2704.3 405 2704.51 404.89C2704.94 404.66 2705.51 404.7 2705.74 404.67Z" fill="white"/>
                    <path d="M2464.63 428.86C2464.48 428.92 2464.23 429.03 2463.98 429.13C2463.98 428.58 2463.65 427.99 2464.18 427.52C2464.41 427.32 2464.66 427.3 2464.93 427.56C2465.5 428.1 2464.95 428.39 2464.63 428.85V428.86Z" fill="white"/>
                    <path d="M2583.9 245.38C2583.67 245.24 2583.66 244.95 2583.83 244.7C2584.05 244.37 2584.39 244.08 2584.75 244.52C2585.01 244.82 2585.77 244.99 2585.32 245.52C2585.16 245.71 2584.41 245.82 2583.89 245.38H2583.9Z" fill="white"/>
                    <path d="M2726.86 364.97C2726.46 365.55 2725.82 365.26 2725.28 365.36C2725.37 365.11 2725.41 364.8 2725.58 364.61C2725.86 364.29 2726.09 363.7 2726.71 364.2C2726.99 364.43 2726.99 364.66 2726.86 364.96V364.97Z" fill="white"/>
                    <path d="M2563.97 272.93C2563.61 272.73 2563.92 273.03 2563.97 272.64C2564 272.43 2564.29 272.11 2564.48 272.09C2565.02 272.04 2565.64 271.76 2566.05 272.39C2566.1 272.46 2566.11 272.64 2566.07 272.66C2565.41 273.08 2564.65 272.79 2563.96 272.93H2563.97Z" fill="white"/>
                    <path d="M2561.14 329.21C2560.58 329.21 2559.93 329.5 2559.57 328.85C2559.48 328.7 2559.66 328.26 2559.84 328.12C2560.42 327.68 2560.65 328.34 2561.02 328.57C2561.27 328.73 2561.39 328.94 2561.14 329.21Z" fill="white"/>
                    <path d="M2583.54 423.37C2583.41 423.52 2583.3 423.71 2583.14 423.8C2583.03 423.86 2582.75 423.8 2582.71 423.73C2582.56 423.44 2583.78 422.03 2584.14 422.09C2584.22 422.1 2584.36 422.42 2584.32 422.48C2584.09 422.8 2583.81 423.08 2583.55 423.38L2583.54 423.37Z" fill="white"/>
                    <path d="M2464.13 311.1C2463.75 311.59 2463.41 311.42 2463.13 311.1C2462.93 310.87 2462.91 310.62 2463.16 310.34C2463.53 309.93 2463.85 310.08 2464.12 310.39C2464.31 310.61 2464.42 310.91 2464.12 311.09L2464.13 311.1Z" fill="white"/>
                    <path d="M2721.36 383.9C2721.08 384.07 2720.86 384.12 2720.58 383.87C2720.17 383.5 2720.32 383.18 2720.63 382.91C2720.86 382.71 2721.11 382.69 2721.39 382.94C2721.81 383.32 2721.62 383.6 2721.36 383.9Z" fill="white"/>
                    <path d="M2551.61 526.64C2551.33 526.81 2551.11 526.86 2550.83 526.61C2550.42 526.24 2550.57 525.92 2550.88 525.65C2551.11 525.45 2551.36 525.43 2551.64 525.68C2552.06 526.06 2551.87 526.34 2551.61 526.64Z" fill="white"/>
                    <path d="M2458.33 459.74C2458.05 459.49 2457.83 459.22 2458.2 458.8C2458.45 458.52 2458.7 458.5 2458.95 458.68C2459.29 458.92 2459.48 459.21 2459.12 459.63C2458.88 459.91 2458.65 459.9 2458.34 459.74H2458.33Z" fill="white"/>
                    <path d="M2687.25 485.37C2687.48 485.88 2687.57 486.39 2687.25 486.87C2687.16 487 2686.81 487.12 2686.8 487.11C2686.5 486.63 2686.42 486.12 2686.77 485.63C2686.86 485.5 2687.08 485.46 2687.26 485.37H2687.25Z" fill="white"/>
                    <path d="M2590.63 533.7C2590.45 534.01 2590.15 533.99 2589.91 533.81C2589.57 533.57 2589.38 533.28 2589.74 532.86C2589.99 532.58 2590.24 532.56 2590.49 532.74C2590.83 532.98 2591.04 533.3 2590.63 533.7Z" fill="white"/>
                    <path d="M2649.06 428.83C2648.78 429 2648.56 429.05 2648.28 428.8C2647.87 428.43 2648.02 428.11 2648.33 427.84C2648.56 427.64 2648.81 427.62 2649.09 427.87C2649.51 428.25 2649.32 428.53 2649.06 428.83Z" fill="white"/>
                    <path d="M2516.86 270C2516.54 270.2 2516.32 270.25 2516.04 270C2515.63 269.63 2515.78 269.31 2516.09 269.04C2516.32 268.84 2516.57 268.83 2516.85 269.07C2517.28 269.45 2517.04 269.73 2516.86 270.01V270Z" fill="white"/>
                    <path d="M2538.2 282.4C2537.75 282.04 2537.92 281.71 2538.23 281.43C2538.46 281.23 2538.71 281.21 2538.99 281.46C2539.4 281.83 2539.25 282.15 2538.94 282.42C2538.71 282.62 2538.43 282.68 2538.21 282.4H2538.2Z" fill="white"/>
                    <path d="M2686.1 527.3C2686.37 527.02 2686.65 526.72 2687.03 527.14C2687.15 527.27 2687.21 527.45 2687.29 527.61C2686.87 527.69 2686.44 527.77 2686.02 527.85C2686.05 527.67 2686.07 527.49 2686.1 527.3Z" fill="white"/>
                    <path d="M2635.08 508.26C2634.81 508.54 2634.53 508.84 2634.15 508.42C2634.03 508.29 2633.97 508.11 2633.89 507.95C2634.31 507.87 2634.74 507.79 2635.16 507.71C2635.13 507.89 2635.11 508.07 2635.08 508.26Z" fill="white"/>
                    <path d="M2698.44 384.05C2698.03 384.08 2697.65 384.11 2697.27 384.14C2697.36 383.89 2697.39 383.57 2697.56 383.4C2697.94 383.03 2698.26 383.35 2698.5 383.64C2698.56 383.72 2698.47 383.93 2698.45 384.04L2698.44 384.05Z" fill="white"/>
                    <path d="M2538.97 448.12C2538.86 447.91 2538.67 447.66 2538.71 447.61C2539.05 447.2 2539.55 447.47 2539.96 447.37C2539.93 447.55 2539.96 447.77 2539.87 447.9C2539.64 448.24 2539.33 448.43 2538.97 448.12Z" fill="white"/>
                    <path d="M2458.2 498.75C2458.05 498.79 2457.85 498.9 2457.76 498.84C2457.44 498.64 2457.09 498.36 2457.42 497.94C2457.57 497.75 2457.89 497.69 2458.13 497.57C2458.15 497.96 2458.18 498.34 2458.2 498.75Z" fill="white"/>
                    <path d="M2487.65 493.07C2488.07 493.41 2487.86 493.74 2487.57 494.01C2487.45 494.12 2487.23 494.11 2487.05 494.16C2487.08 493.73 2487.11 493.3 2487.14 492.87C2487.31 492.94 2487.47 493 2487.65 493.07Z" fill="white"/>
                    <path d="M2675.93 393.87C2675.85 393.89 2675.66 394.02 2675.57 393.97C2675.25 393.77 2674.9 393.49 2675.21 393.07C2675.35 392.88 2675.67 392.81 2675.91 392.69C2675.91 393.07 2675.92 393.45 2675.93 393.87Z" fill="white"/>
                    <path d="M2648.73 421.93C2648.38 422.35 2648.06 422.14 2647.79 421.85C2647.68 421.73 2647.69 421.51 2647.64 421.33C2648.07 421.36 2648.5 421.39 2648.93 421.42C2648.86 421.59 2648.8 421.75 2648.73 421.93Z" fill="white"/>
                    <path d="M2516.47 533.41C2516.29 533.47 2516.12 533.52 2515.95 533.57C2515.95 533.14 2515.97 532.71 2515.98 532.28C2516.15 532.34 2516.34 532.36 2516.48 532.46C2516.92 532.77 2516.74 533.09 2516.48 533.4L2516.47 533.41Z" fill="white"/>
                    <path d="M2531.13 300.37C2531.55 300.71 2531.34 301.04 2531.05 301.31C2530.93 301.42 2530.71 301.41 2530.53 301.46C2530.56 301.03 2530.59 300.6 2530.62 300.17C2530.79 300.24 2530.95 300.3 2531.13 300.37Z" fill="white"/>
                    <path d="M2498.56 274.61C2498.51 274.86 2498.53 275.07 2498.44 275.21C2498.2 275.55 2497.87 275.74 2497.52 275.34C2497.41 275.21 2497.36 275.02 2497.28 274.86C2497.7 274.78 2498.12 274.7 2498.57 274.61H2498.56Z" fill="white"/>
                    <path d="M2716.36 324.78C2715.97 324.91 2715.82 324.6 2715.63 324.4C2715.57 324.34 2715.63 324.02 2715.72 323.98C2716.07 323.82 2716.24 324.16 2716.43 324.35C2716.49 324.41 2716.39 324.64 2716.37 324.78H2716.36Z" fill="white"/>
                    <path d="M2711.67 350.74C2711.53 351.04 2711.45 351.32 2711.29 351.52C2711.19 351.63 2710.95 351.63 2710.77 351.67C2710.86 351.42 2710.91 351.13 2711.06 350.92C2711.14 350.8 2711.4 350.81 2711.67 350.74Z" fill="white"/>
                    <path d="M2446.14 368.51C2445.75 368.64 2445.6 368.33 2445.41 368.13C2445.35 368.07 2445.41 367.75 2445.5 367.71C2445.85 367.55 2446.02 367.89 2446.21 368.08C2446.27 368.14 2446.17 368.37 2446.15 368.51H2446.14Z" fill="white"/>
                    <path d="M2462.25 479.87C2461.95 479.73 2461.67 479.65 2461.47 479.49C2461.36 479.39 2461.36 479.15 2461.32 478.97C2461.57 479.06 2461.86 479.11 2462.07 479.26C2462.19 479.34 2462.18 479.6 2462.25 479.87Z" fill="white"/>
                    <path d="M2464.92 481.72C2464.81 481.61 2464.59 481.44 2464.45 481.22C2464.29 480.96 2464.44 480.8 2464.71 480.93C2464.94 481.04 2465.16 481.24 2465.3 481.45C2465.47 481.7 2465.33 481.83 2464.92 481.72Z" fill="white"/>
                    <path d="M2429.34 375.73C2429.42 375.89 2429.61 376.12 2429.56 376.2C2429.37 376.51 2429.12 376.39 2428.93 376.16C2428.82 376.02 2428.66 375.77 2428.71 375.69C2428.9 375.39 2429.14 375.51 2429.34 375.73Z" fill="white"/>
                    <path d="M2714.66 385.06C2714.5 385.14 2714.27 385.33 2714.19 385.28C2713.88 385.09 2714 384.84 2714.23 384.65C2714.37 384.54 2714.62 384.38 2714.7 384.43C2715 384.62 2714.88 384.86 2714.66 385.06Z" fill="white"/>
                    <path d="M2526.46 529.08C2526.32 529.38 2526.24 529.66 2526.08 529.86C2525.98 529.97 2525.74 529.97 2525.56 530.01C2525.65 529.76 2525.7 529.47 2525.85 529.26C2525.93 529.14 2526.19 529.15 2526.46 529.08Z" fill="white"/>
                    <path d="M2596.63 273.92C2596.5 273.95 2596.33 274 2596.15 274.05C2596.24 273.8 2596.29 273.51 2596.44 273.3C2596.52 273.19 2596.78 273.19 2596.95 273.15C2597 273.45 2596.93 273.71 2596.63 273.93V273.92Z" fill="white"/>
                    <path d="M2562.54 558.03C2562.3 557.85 2562.15 557.63 2562.43 557.41C2562.5 557.35 2562.77 557.48 2562.92 557.57C2563.17 557.72 2563.31 557.96 2563.03 558.19C2562.95 558.25 2562.7 558.09 2562.54 558.03Z" fill="white"/>
                    <path d="M2568.57 303.73C2568.33 303.55 2568.18 303.33 2568.46 303.11C2568.53 303.05 2568.8 303.18 2568.95 303.27C2569.2 303.42 2569.34 303.66 2569.06 303.89C2568.98 303.95 2568.73 303.79 2568.57 303.73Z" fill="white"/>
                    <path d="M2569.32 316.53C2569.08 316.35 2568.93 316.13 2569.21 315.91C2569.28 315.85 2569.55 315.98 2569.7 316.07C2569.95 316.22 2570.09 316.46 2569.81 316.69C2569.73 316.75 2569.48 316.59 2569.32 316.53Z" fill="white"/>
                    <path d="M2578.77 362.84C2578.63 363.14 2578.55 363.42 2578.39 363.62C2578.29 363.73 2578.05 363.73 2577.87 363.77C2577.96 363.52 2578.01 363.23 2578.16 363.02C2578.24 362.9 2578.5 362.91 2578.77 362.84Z" fill="white"/>
                    <path d="M2685.48 389.75C2685.27 389.83 2685.02 389.93 2684.77 390.02C2684.8 389.84 2684.76 389.59 2684.86 389.49C2685.05 389.32 2685.33 389.23 2685.57 389.11C2685.54 389.29 2685.52 389.47 2685.48 389.74V389.75Z" fill="white"/>
                    <path d="M2604.82 442.16C2604.69 442.19 2604.52 442.24 2604.34 442.29C2604.43 442.04 2604.48 441.75 2604.63 441.54C2604.71 441.43 2604.97 441.43 2605.14 441.39C2605.19 441.69 2605.12 441.95 2604.82 442.17V442.16Z" fill="white"/>
                    <path d="M2634.4 478.62C2634.26 478.92 2634.18 479.2 2634.02 479.4C2633.92 479.51 2633.68 479.51 2633.5 479.55C2633.59 479.3 2633.64 479.01 2633.79 478.8C2633.87 478.68 2634.13 478.69 2634.4 478.62Z" fill="white"/>
                    <path d="M2681.21 535.4C2680.82 535.53 2680.67 535.22 2680.48 535.02C2680.42 534.96 2680.48 534.64 2680.57 534.6C2680.92 534.44 2681.09 534.78 2681.28 534.97C2681.34 535.03 2681.24 535.26 2681.22 535.4H2681.21Z" fill="white"/>
                    <path d="M2617.57 517.96C2617.33 517.78 2617.18 517.56 2617.46 517.34C2617.53 517.28 2617.8 517.41 2617.95 517.5C2618.2 517.65 2618.34 517.89 2618.06 518.12C2617.98 518.18 2617.73 518.02 2617.57 517.96Z" fill="white"/>
                    <path d="M2464.99 370.2C2465.07 370.36 2465.26 370.59 2465.21 370.67C2465.02 370.98 2464.77 370.86 2464.58 370.63C2464.47 370.49 2464.31 370.24 2464.36 370.16C2464.55 369.86 2464.79 369.98 2464.99 370.2Z" fill="white"/>
                    <path d="M2548.17 423.12C2548.01 423.2 2547.78 423.39 2547.7 423.34C2547.39 423.15 2547.51 422.9 2547.74 422.71C2547.88 422.6 2548.13 422.44 2548.21 422.49C2548.51 422.68 2548.39 422.92 2548.17 423.12Z" fill="white"/>
                    <path d="M2538.41 435.47C2538.37 435.62 2538.35 435.77 2538.28 435.9C2538.26 435.94 2538.1 435.92 2538.01 435.92C2538.05 435.77 2538.07 435.62 2538.14 435.49C2538.16 435.45 2538.32 435.47 2538.41 435.47Z" fill="white"/>
                    <path d="M2512.2 543.21C2512.16 543.36 2512.14 543.51 2512.07 543.64C2512.05 543.68 2511.89 543.66 2511.8 543.66C2511.84 543.51 2511.86 543.36 2511.93 543.23C2511.95 543.19 2512.11 543.21 2512.2 543.21Z" fill="white"/>
                    <path d="M2576.13 326.92C2575.99 326.97 2575.85 327.02 2575.71 327.07C2575.71 326.98 2575.66 326.83 2575.71 326.8C2575.83 326.71 2575.98 326.68 2576.12 326.62C2576.12 326.71 2576.13 326.81 2576.14 326.92H2576.13Z" fill="white"/>
                    <path d="M2551.61 286.52C2551.72 286.52 2551.82 286.51 2551.91 286.5C2551.87 286.65 2551.85 286.8 2551.78 286.93C2551.76 286.97 2551.6 286.95 2551.51 286.95C2551.54 286.8 2551.58 286.66 2551.61 286.52Z" fill="white"/>
                    <path d="M2618.36 343.83C2618.31 343.69 2618.26 343.55 2618.21 343.41C2618.3 343.41 2618.45 343.36 2618.48 343.41C2618.57 343.53 2618.6 343.68 2618.66 343.82C2618.57 343.82 2618.47 343.83 2618.36 343.84V343.83Z" fill="white"/>
                    <path d="M2619.72 390.85C2619.57 390.81 2619.42 390.79 2619.29 390.72C2619.25 390.7 2619.27 390.54 2619.27 390.45C2619.42 390.49 2619.57 390.51 2619.7 390.58C2619.74 390.6 2619.72 390.76 2619.72 390.85Z" fill="white"/>
                    <path d="M2653.13 427.31C2653.13 427.2 2653.12 427.1 2653.11 427.01C2653.26 427.05 2653.41 427.07 2653.54 427.14C2653.58 427.16 2653.56 427.32 2653.56 427.41C2653.41 427.38 2653.27 427.34 2653.13 427.31Z" fill="white"/>
                    <path d="M2706.17 397.21C2706.03 397.26 2705.89 397.31 2705.75 397.36C2705.75 397.27 2705.7 397.12 2705.75 397.09C2705.87 397 2706.02 396.97 2706.16 396.91C2706.16 397 2706.17 397.1 2706.18 397.21H2706.17Z" fill="white"/>
                    <path d="M2604.84 329.21C2604.73 329.21 2604.63 329.22 2604.54 329.23C2604.58 329.08 2604.6 328.93 2604.67 328.8C2604.69 328.76 2604.85 328.78 2604.94 328.78C2604.91 328.93 2604.87 329.07 2604.84 329.21Z" fill="white"/>
                    <path d="M2590.62 403.7C2590.73 403.7 2590.83 403.69 2590.92 403.68C2590.88 403.83 2590.86 403.98 2590.79 404.11C2590.77 404.15 2590.61 404.13 2590.52 404.13C2590.55 403.98 2590.59 403.84 2590.62 403.7Z" fill="white"/>
                    <path d="M2691.8 509.59C2691.8 509.69 2691.84 509.85 2691.81 509.86C2691.67 509.91 2691.5 509.98 2691.37 509.94C2691.1 509.86 2691.09 509.69 2691.35 509.58C2691.48 509.53 2691.65 509.58 2691.8 509.58V509.59Z" fill="white"/>
                    <path d="M2690.99 509.97C2690.84 509.97 2690.6 510 2690.55 509.93C2690.42 509.72 2690.52 509.56 2690.7 509.59C2690.86 509.62 2691.01 509.71 2691.16 509.77C2691.1 509.84 2691.04 509.9 2690.98 509.97H2690.99Z" fill="white"/>
                    <path d="M2508.45 428.54C2508.31 428.59 2508.17 428.64 2508.03 428.69C2508.03 428.6 2507.98 428.45 2508.03 428.42C2508.15 428.33 2508.3 428.3 2508.44 428.24C2508.44 428.33 2508.45 428.43 2508.46 428.54H2508.45Z" fill="white"/>
                    <path d="M2749.6 443.9C2749.46 443.95 2749.32 444 2749.18 444.05C2749.18 443.96 2749.13 443.81 2749.18 443.78C2749.3 443.69 2749.45 443.66 2749.59 443.6C2749.59 443.69 2749.6 443.79 2749.61 443.9H2749.6Z" fill="white"/>
                    <path d="M2693 517.59C2692.89 517.59 2692.79 517.6 2692.7 517.61C2692.74 517.46 2692.76 517.31 2692.83 517.18C2692.85 517.14 2693.01 517.16 2693.1 517.16C2693.07 517.31 2693.03 517.45 2693 517.59Z" fill="white"/>
                    <path d="M2723.51 408C2723.65 407.95 2723.79 407.9 2723.93 407.85C2723.93 407.94 2723.98 408.09 2723.93 408.12C2723.81 408.21 2723.66 408.24 2723.52 408.3C2723.52 408.21 2723.51 408.11 2723.5 408H2723.51Z" fill="white"/>
                    <path d="M2593.71 480.05C2593.57 480.1 2593.43 480.15 2593.29 480.2C2593.29 480.11 2593.24 479.96 2593.29 479.93C2593.41 479.84 2593.56 479.81 2593.7 479.75C2593.7 479.84 2593.71 479.94 2593.72 480.05H2593.71Z" fill="white"/>
                    <path d="M2649.51 351.29C2649.51 351.18 2649.5 351.08 2649.49 350.99C2649.64 351.03 2649.79 351.05 2649.92 351.12C2649.96 351.14 2649.94 351.3 2649.94 351.39C2649.79 351.36 2649.65 351.32 2649.51 351.29Z" fill="white"/>
                    <path d="M2659.86 505.4C2659.82 505.55 2659.8 505.7 2659.73 505.83C2659.71 505.87 2659.55 505.85 2659.46 505.85C2659.5 505.7 2659.52 505.55 2659.59 505.42C2659.61 505.38 2659.77 505.4 2659.86 505.4Z" fill="white"/>
                    <path d="M2661.04 357.9C2660.9 357.95 2660.76 358 2660.62 358.05C2660.62 357.96 2660.57 357.81 2660.62 357.78C2660.74 357.69 2660.89 357.66 2661.03 357.6L2661.05 357.9H2661.04Z" fill="white"/>
                    <path d="M2559.63 419.66C2559.68 419.8 2559.73 419.94 2559.78 420.08C2559.69 420.08 2559.54 420.13 2559.51 420.08C2559.42 419.96 2559.39 419.81 2559.33 419.67C2559.42 419.67 2559.52 419.66 2559.63 419.65V419.66Z" fill="white"/>
                    <path d="M2423.53 382.7C2423.49 382.85 2423.47 383 2423.4 383.13C2423.38 383.17 2423.22 383.15 2423.13 383.15C2423.17 383 2423.19 382.85 2423.26 382.72C2423.28 382.68 2423.44 382.7 2423.53 382.7Z" fill="white"/>
                    <path d="M2558.33 361.79C2558.74 360.01 2560.13 359.7 2561.45 359.44C2561.93 359.34 2562.7 359.31 2562.93 359.17C2564.41 358.27 2564.62 360.31 2565.67 360.21C2565.61 361.24 2565.88 362.5 2565.39 363.25C2564.92 363.96 2564.25 364.79 2563.43 365.27C2562.65 365.72 2561.77 366.71 2560.99 365.16C2560.87 364.93 2560.31 364.95 2559.96 364.81C2558.43 364.21 2557.91 363.16 2558.34 361.79H2558.33Z" fill="white"/>
                    <path d="M2637.85 486.36C2636.82 486.02 2636.35 484.97 2636.47 484.61C2636.94 483.22 2635.84 482.25 2635.79 481.02C2635.75 480.15 2635.87 479.61 2636.64 479.27C2637.91 478.7 2638.55 478.79 2639.14 479.61C2639.4 479.97 2639.68 480.28 2639.99 480.59C2641.32 481.95 2640.82 485.78 2638.7 486.29C2638.32 486.38 2638.01 486.46 2637.85 486.37V486.36Z" fill="white"/>
                    <path d="M2649.56 398.92C2649.15 398.06 2647.78 397.85 2648.07 396.56C2648.11 396.38 2648.25 396.19 2648.22 396.03C2648.11 395.34 2647.74 394.64 2648.37 394.02C2648.86 393.53 2649.54 393.64 2650.14 393.54C2651.33 393.35 2652.7 394.36 2653.05 395.64C2653.32 396.63 2653.05 397.41 2652.29 398.14C2651.5 398.9 2650.49 398.7 2649.56 398.92Z" fill="white"/>
                    <path d="M2778.74 375.83C2780.29 376.32 2780.81 377.11 2780.6 378.26C2780.48 378.9 2780.2 379.5 2780.04 380.14C2779.89 380.74 2779.58 380.84 2779.04 380.63C2778.43 380.4 2777.75 380.32 2777.18 380.03C2776.21 379.53 2775.75 378.61 2775.61 377.56C2775.58 377.34 2775.74 376.98 2775.91 376.89C2776.56 376.56 2777.04 376.04 2777.59 375.61C2777.86 375.4 2778.61 375.81 2778.73 375.84L2778.74 375.83Z" fill="white"/>
                    <path d="M2759.79 496.89C2759.07 496.73 2758.04 496.53 2758.09 495.35C2758.11 494.79 2758.15 493.74 2758.85 493.74C2759.61 493.74 2760.46 493.12 2761.17 493.82C2761.66 494.31 2762.01 495 2762.58 495.35C2763.1 495.67 2763.16 495.89 2763.01 496.41C2762.79 497.16 2762.23 497.43 2761.58 497.41C2761.04 497.39 2760.51 497.11 2759.78 496.89H2759.79Z" fill="white"/>
                    <path d="M2786.29 357.01C2785.58 356.81 2785.15 356.07 2785.32 355.36C2785.38 355.12 2787.31 353.3 2787.53 353.28C2788.03 353.22 2789.13 354.53 2789.04 354.56C2787.91 354.89 2789.01 355.82 2788.52 356.27C2788.06 356.7 2786.74 357.14 2786.28 357.01H2786.29Z" fill="white"/>
                    <path d="M2620.38 345.34C2620.26 345.71 2620.18 346.31 2619.82 346.2C2619.16 346.01 2618.35 346.08 2617.91 345.27C2617.67 344.82 2617.88 344.36 2617.69 343.99C2617.38 343.39 2617.4 343.03 2618.04 342.65C2618.72 342.24 2619.4 342.35 2619.76 342.85C2620.26 343.55 2620.95 344.34 2620.38 345.33V345.34Z" fill="white"/>
                    <path d="M2661.19 433.61C2661.74 433.82 2662.56 433.76 2662.18 434.81C2662.1 435.05 2662.5 435.44 2662.66 435.78C2662.83 436.16 2662.98 436.56 2663.13 436.95C2662.52 436.83 2661.74 436.89 2661.33 436.53C2660.81 436.07 2660.62 435.25 2660.24 434.62C2660.03 434.27 2659.91 433.91 2660.17 433.6C2660.46 433.27 2660.85 433.51 2661.19 433.6V433.61Z" fill="white"/>
                    <path d="M2696.17 288.12C2696.34 288.17 2696.53 288.21 2696.69 288.29C2697.06 288.46 2697.7 288.34 2697.65 288.99C2697.6 289.66 2697.25 290.14 2696.57 290.39C2696 290.61 2695.78 290.32 2695.51 289.9C2695.34 289.63 2694.94 289.51 2694.76 289.24C2694.52 288.89 2694.15 288.5 2694.44 288.01C2694.79 287.44 2695.23 287.89 2695.63 287.96C2695.81 287.99 2695.98 288.06 2696.15 288.12H2696.17Z" fill="white"/>
                    <path d="M2792.25 365.26C2792.87 364.67 2793.34 363.87 2794.23 363.6C2794.43 363.54 2794.77 363.63 2794.9 363.96C2795.06 364.37 2795.54 364.78 2795.18 365.2C2794.74 365.72 2794.18 366.16 2793.63 366.58C2792.97 367.07 2793.12 366.1 2792.73 366.04C2792.33 365.97 2792.07 365.75 2792.25 365.27V365.26Z" fill="white"/>
                    <path d="M2616.83 422.88C2617.34 422.95 2617.58 423.2 2617.37 423.79C2617.04 424.7 2616.39 425.06 2615.56 424.85C2614.82 424.66 2614.56 424.08 2614.55 423.47C2614.55 422.76 2615.66 422.49 2616.83 422.89V422.88Z" fill="white"/>
                    <path d="M2551.43 475.57C2551.7 475.65 2551.99 475.69 2552.22 475.83C2552.78 476.14 2552.75 476.93 2552.19 477.22C2551.86 477.39 2551.55 477.64 2551.2 477.72C2550.75 477.83 2550.21 477.7 2549.97 477.35C2549.7 476.95 2550.02 476.31 2549.64 475.87C2550.13 475.69 2550.53 475.14 2551.16 475.49C2551.24 475.53 2551.34 475.55 2551.43 475.57Z" fill="white"/>
                    <path d="M2741 357.15C2740.85 356.86 2740.73 356.61 2740.59 356.37C2740.22 355.78 2741.08 355.19 2740.56 354.57C2740.28 354.24 2740.41 353.84 2740.8 353.68C2741.17 353.53 2741.47 353.74 2741.65 354.11C2741.89 354.61 2742.19 355.08 2742.41 355.58C2742.51 355.82 2743.02 356.11 2742.43 356.4C2741.95 356.63 2741.48 356.9 2741.01 357.15H2741Z" fill="white"/>
                    <path d="M2810.29 449.72C2810.03 450.26 2808.99 450.51 2808.4 450.19C2808.16 450.06 2807.68 448.4 2807.86 448.13C2808.31 447.43 2808.85 448 2809.28 448.14C2810.09 448.4 2810.54 449.21 2810.29 449.73V449.72Z" fill="white"/>
                    <path d="M2735.23 470.27C2735.32 469.7 2735.53 469.41 2736.14 469.62C2736.88 469.88 2737.46 470.63 2737.29 471.16C2737.13 471.69 2736.21 472 2735.46 471.76C2734.35 471.41 2735.25 470.74 2735.23 470.26V470.27Z" fill="white"/>
                    <path d="M2730.15 474.83C2729.81 474.45 2729.42 474.04 2729.55 473.47C2729.7 472.85 2730.94 472.55 2731.61 472.93C2732.05 473.18 2731.91 473.51 2731.77 473.85C2731.45 474.61 2730.68 475.08 2730.15 474.84V474.83Z" fill="white"/>
                    <path d="M2746.5 390.57C2746.85 390.68 2747.02 390.75 2747.2 390.78C2747.87 390.9 2747.88 391.56 2748.19 391.97C2747.7 392.14 2747.33 392.68 2746.7 392.4C2746.54 392.33 2746.36 392.28 2746.18 392.24C2745.58 392.11 2745.22 391.92 2745.48 391.14C2745.72 390.4 2746.07 390.32 2746.5 390.57Z" fill="white"/>
                    <path d="M2683.04 538.57C2683.19 537.93 2683.65 537.93 2683.91 538.16C2684.43 538.62 2684.87 539.18 2685.3 539.73C2685.7 540.24 2685.11 540.25 2684.88 540.42C2683.88 541.16 2683.78 539.84 2683.17 539.64C2682.47 539.41 2683.09 538.87 2683.04 538.57Z" fill="white"/>
                    <path d="M2747.42 424.35C2746.65 424.26 2746.37 423.68 2746.35 423.06C2746.33 422.6 2747.71 421.91 2747.98 422.19C2748.49 422.72 2748.59 423.35 2748.3 424.07C2748.1 424.57 2747.78 424.46 2747.42 424.36V424.35Z" fill="white"/>
                    <path d="M2633.36 297.51C2633.61 297.02 2634.11 296.85 2634.63 296.77C2634.94 296.72 2635.45 298.17 2635.33 298.74C2635.22 299.25 2634.87 299.19 2634.51 299.07C2633.78 298.82 2633.21 298.06 2633.36 297.51Z" fill="white"/>
                    <path d="M2777.69 399.17C2777.53 399.41 2777.26 399.61 2777.03 399.38C2776.48 398.83 2775.78 398.41 2775.42 397.65C2775.27 397.33 2775.34 397.03 2775.49 396.88C2775.64 396.74 2776.09 396.7 2776.22 396.82C2776.73 397.3 2777.17 397.85 2777.62 398.39C2777.81 398.62 2777.82 398.89 2777.69 399.17Z" fill="white"/>
                    <path d="M2807.86 395.38C2807.57 395.32 2807.2 395.25 2807.25 394.75C2807.29 394.32 2807.68 394.24 2807.95 394.03C2808.51 393.6 2809.02 393.94 2809.46 394.15C2809.72 394.28 2810.04 394.89 2809.95 395.04C2809.76 395.35 2809.32 395.61 2808.95 395.69C2808.64 395.75 2808.26 395.51 2807.85 395.38H2807.86Z" fill="white"/>
                    <path d="M2732.46 528.84C2732.4 529.3 2732.14 529.54 2731.56 529.32C2730.83 529.05 2730.28 528.31 2730.43 527.78C2730.69 526.88 2731.61 527.83 2732.03 527.21C2732.1 527.11 2732.62 528.13 2732.46 528.83V528.84Z" fill="white"/>
                    <path d="M2704.54 357.67C2704.44 357.53 2704.26 357.39 2704.27 357.26C2704.31 356.53 2705.65 355.62 2706.3 355.87C2706.52 355.96 2706.83 356.15 2706.85 356.34C2706.88 356.53 2706.68 356.84 2706.49 356.98C2705.66 357.59 2704.99 357.8 2704.54 357.67Z" fill="white"/>
                    <path d="M2600.54 367.79C2600.46 368.05 2600.42 368.34 2600.29 368.57C2599.95 369.17 2599.5 368.71 2599.11 368.64C2598.59 368.55 2598.65 368.18 2598.77 367.81C2598.88 367.48 2598.92 367.12 2599.32 366.94C2599.73 366.76 2600.14 366.41 2600.56 366.77C2600.89 367.05 2600.63 367.44 2600.54 367.78V367.79Z" fill="white"/>
                    <path d="M2774.63 399.82C2775.14 400.1 2774.98 400.45 2774.84 400.77C2774.51 401.54 2773.75 402 2773.22 401.73C2772.78 401.51 2772.93 401.14 2773.07 400.81C2773.4 400.04 2774.15 399.6 2774.63 399.81V399.82Z" fill="white"/>
                    <path d="M2739.85 531.56C2739.27 531.84 2738.79 532.25 2738.07 531.93C2737.47 531.67 2737.55 531.36 2737.68 530.89C2737.82 530.37 2738 530.18 2738.61 530.28C2739.41 530.42 2739.49 531.08 2739.85 531.56Z" fill="white"/>
                    <path d="M2725.75 531.05C2725.64 530.92 2725.43 530.77 2725.44 530.66C2725.49 530.3 2725.53 529.84 2725.77 529.64C2726.15 529.32 2726.6 528.89 2727.2 529.15C2727.66 529.35 2727.51 529.73 2727.37 530.06C2727.03 530.84 2726.32 531.27 2725.76 531.05H2725.75Z" fill="white"/>
                    <path d="M2600.67 329.78C2600.31 329.66 2599.92 329.52 2600.16 329.05C2600.46 328.44 2601.02 328.08 2601.66 328.36C2601.99 328.51 2602.15 329.05 2602.39 329.42C2601.84 329.68 2601.35 330.18 2600.68 329.78H2600.67Z" fill="white"/>
                    <path d="M2597.8 477.7C2598.04 477.07 2598.98 476.64 2599.4 476.85C2599.84 477.07 2599.65 477.42 2599.56 477.76C2599.39 478.45 2598.89 478.78 2598.28 478.69C2598.1 478.66 2597.69 478.04 2597.8 477.7Z" fill="white"/>
                    <path d="M2749.92 444.5C2749.47 444.36 2749.01 444.19 2749.29 443.78C2749.64 443.27 2750.17 442.88 2750.63 442.44C2750.97 442.98 2751.23 443.49 2750.92 444.2C2750.62 444.86 2750.23 444.55 2749.92 444.5Z" fill="white"/>
                    <path d="M2641.48 533.27C2640.72 533.13 2640.79 532.76 2640.9 532.24C2641.08 531.43 2641.51 531.52 2642.1 531.7C2642.71 531.88 2642.68 532.2 2642.55 532.72C2642.36 533.49 2641.97 533.56 2641.47 533.26L2641.48 533.27Z" fill="white"/>
                    <path d="M2759.04 436.79C2759.27 436.35 2759.15 435.48 2760.01 435.89C2760.48 436.11 2760.78 436.64 2760.67 437.22C2760.49 438.17 2759.58 437.61 2759.11 437.73C2758.52 437.88 2759.14 437.13 2759.05 436.8L2759.04 436.79Z" fill="white"/>
                    <path d="M2823.33 384.88C2823.07 384.8 2822.79 384.75 2822.55 384.63C2822.12 384.4 2822.28 384.04 2822.41 383.71C2822.5 383.47 2822.46 383.13 2822.81 383.06C2823.2 382.98 2823.49 383.13 2823.65 383.51C2823.72 383.68 2823.82 383.83 2823.9 383.99C2824.05 384.3 2824.4 384.75 2824.3 384.91C2824.08 385.27 2823.66 384.95 2823.32 384.88H2823.33Z" fill="white"/>
                    <path d="M2566.46 434.55C2566.08 435.1 2565.65 434.7 2565.26 434.59C2564.54 434.4 2564.93 433.89 2565.02 433.5C2565.11 433.14 2565.26 432.78 2565.75 432.99C2566.26 433.2 2566.66 434.11 2566.45 434.54L2566.46 434.55Z" fill="white"/>
                    <path d="M2705.88 483.49C2706.04 483.03 2706.16 482.67 2706.66 482.87C2707.16 483.08 2707.59 484 2707.36 484.42C2707.13 484.85 2706.77 484.62 2706.43 484.57C2705.59 484.44 2705.7 483.94 2705.89 483.49H2705.88Z" fill="white"/>
                    <path d="M2790.67 416.28C2790.57 416.44 2790.45 416.81 2790.29 416.84C2789.84 416.91 2789.81 416.4 2789.75 416.15C2789.66 415.78 2788.71 415.38 2789.69 414.94C2790.01 414.79 2790.32 414.54 2790.68 414.77C2791.4 415.24 2790.63 415.72 2790.68 416.28H2790.67Z" fill="white"/>
                    <path d="M2695.28 307.81C2695.01 307.46 2694.36 307 2694.82 306.66C2695.21 306.37 2695.18 305.59 2695.83 305.73C2696.22 305.81 2696.35 307.28 2696.01 307.7C2695.83 307.92 2695.57 307.95 2695.28 307.81Z" fill="white"/>
                    <path d="M2644.7 516.15C2644.7 516.15 2644.22 516.29 2643.77 516.34C2643.58 516.36 2643.19 515.14 2643.32 514.91C2643.66 514.31 2644.1 514.76 2644.49 514.83C2644.97 514.91 2644.99 515.24 2644.7 516.15Z" fill="white"/>
                    <path d="M2614.79 502.15C2614.23 501.78 2614.66 501.36 2614.73 500.97C2614.82 500.47 2615.24 500.41 2615.52 500.65C2615.84 500.92 2616.05 501.34 2616.23 501.74C2616.29 501.88 2615 502.28 2614.78 502.16L2614.79 502.15Z" fill="white"/>
                    <path d="M2557.62 341.32C2557.67 340.83 2557.94 340.71 2558.2 340.79C2558.88 341.01 2559.11 341.55 2559.07 342.23C2559.05 342.58 2558.77 342.69 2558.51 342.62C2557.79 342.42 2557.63 341.84 2557.63 341.32H2557.62Z" fill="white"/>
                    <path d="M2720.74 343.8C2720.48 344.34 2719.99 344.65 2719.54 344.51C2719.32 344.44 2718.93 343.87 2719.11 343.35C2719.34 342.65 2719.81 343.03 2720.2 343.11C2720.57 343.19 2720.88 343.35 2720.75 343.8H2720.74Z" fill="white"/>
                    <path d="M2548.14 432.99C2548.18 432.73 2549.22 431.93 2549.31 432.06C2549.53 432.43 2549.76 432.85 2549.79 433.26C2549.82 433.64 2549.5 433.9 2549.06 433.71C2548.7 433.55 2548.09 433.67 2548.14 432.99Z" fill="white"/>
                    <path d="M2726.46 343.83C2726.03 343.53 2725.65 343.07 2725.88 342.37C2725.97 342.11 2726.22 341.95 2726.52 342.12C2727.1 342.47 2727.43 342.96 2727.22 343.65C2727.14 343.91 2726.89 344.06 2726.46 343.83Z" fill="white"/>
                    <path d="M2757.5 289.39C2757 289.27 2756.93 288.87 2757.16 288.59C2757.43 288.27 2757.85 288.06 2758.25 287.88C2758.39 287.82 2758.79 289.11 2758.67 289.33C2758.5 289.64 2758.05 289.67 2757.5 289.4V289.39Z" fill="white"/>
                    <path d="M2754.45 437.09C2754.08 436.97 2753.71 436.85 2753.91 436.36C2754.12 435.83 2754.63 435.53 2755.09 435.68C2755.32 435.75 2755.7 436.32 2755.52 436.84C2755.29 437.54 2754.82 437.14 2754.45 437.09Z" fill="white"/>
                    <path d="M2802.32 362.47C2801.95 362.1 2801.64 361.72 2801.75 361.16C2801.81 360.83 2802.08 360.69 2802.32 360.8C2802.73 360.99 2803.53 360.79 2803.35 361.67C2803.24 362.21 2802.65 362.16 2802.32 362.47Z" fill="white"/>
                    <path d="M2748.2 425.76C2748.1 425.63 2748 425.49 2747.9 425.36C2748.6 424.97 2749.39 424.69 2749.82 423.92C2749.84 423.89 2750.19 423.98 2750.32 424.09C2750.4 424.15 2750.4 424.47 2750.37 424.49C2749.68 424.77 2749.52 425.71 2748.72 425.82C2748.55 425.84 2748.37 425.79 2748.19 425.77L2748.2 425.76Z" fill="white"/>
                    <path d="M2748.53 421.41C2748.72 420.89 2749.32 421.39 2749.71 421.56C2750.5 421.89 2749.85 422.45 2749.79 422.91C2749.77 423.04 2749.34 423.44 2749.33 422.95C2749.33 422.23 2747.99 422.67 2748.53 421.41Z" fill="white"/>
                    <path d="M2579.87 379.59C2579.78 380.26 2579.11 380.25 2578.71 380.56C2578.54 380.26 2578.16 379.89 2578.24 379.67C2578.4 379.19 2578.9 378.99 2579.44 379.05C2579.77 379.09 2579.85 379.32 2579.87 379.6V379.59Z" fill="white"/>
                    <path d="M2650.39 512.95C2650.56 512.59 2650.5 511.99 2651.22 512.19C2651.74 512.34 2651.67 512.68 2651.54 513.04C2651.4 513.42 2651.44 514.01 2650.72 513.81C2650.2 513.67 2650.3 513.32 2650.4 512.95H2650.39Z" fill="white"/>
                    <path d="M2603.16 308.72C2602.57 308.01 2602.58 307.41 2602.89 306.82C2602.96 306.68 2603.14 306.6 2603.27 306.49C2603.35 306.65 2603.41 306.83 2603.53 306.97C2603.98 307.48 2603.72 307.99 2603.53 308.48C2603.47 308.63 2603.21 308.7 2603.17 308.73L2603.16 308.72Z" fill="white"/>
                    <path d="M2637.42 538.52C2637.29 538.49 2637.32 538.13 2637.46 537.77C2637.61 537.39 2637.55 536.79 2638.28 536.99C2638.79 537.13 2638.75 537.47 2638.61 537.83C2638.47 538.21 2638.5 538.81 2637.42 538.52Z" fill="white"/>
                    <path d="M2679.6 496.63C2679.7 496.55 2679.82 496.36 2679.91 496.38C2680.53 496.51 2681.12 496.65 2681.36 497.39C2681.46 497.69 2681.47 497.88 2681.16 497.92C2680.67 497.98 2679.6 497.09 2679.61 496.64L2679.6 496.63Z" fill="white"/>
                    <path d="M2600.89 421.48C2600.13 421.01 2600.87 420.52 2600.92 420.05C2600.94 419.84 2601.39 419.81 2601.63 419.97C2602.33 420.43 2601.71 420.95 2601.61 421.41C2601.56 421.63 2601.16 421.65 2600.89 421.49V421.48Z" fill="white"/>
                    <path d="M2708.21 439.28C2707.45 438.81 2708.19 438.32 2708.24 437.85C2708.26 437.64 2708.71 437.61 2708.95 437.77C2709.65 438.23 2709.03 438.75 2708.93 439.21C2708.88 439.43 2708.48 439.45 2708.21 439.29V439.28Z" fill="white"/>
                    <path d="M2673.42 318.74C2673.29 318.71 2673.32 318.35 2673.46 317.99C2673.61 317.61 2673.55 317.01 2674.28 317.21C2674.79 317.35 2674.75 317.69 2674.61 318.05C2674.47 318.43 2674.5 319.03 2673.42 318.74Z" fill="white"/>
                    <path d="M2774.75 471.35C2775.32 471.23 2775.91 471.11 2776.51 471.01C2776.54 471.01 2776.63 471.16 2776.63 471.24C2776.59 471.63 2776.38 471.86 2776.01 472.03C2775.6 472.22 2775.21 472.48 2774.83 472.05C2774.74 471.95 2774.79 471.73 2774.75 471.36V471.35Z" fill="white"/>
                    <path d="M2771.9 419.85C2771.82 420.22 2771.75 420.61 2771.24 420.52C2770.59 420.41 2770.65 419.99 2770.83 419.5C2770.95 419.16 2770.99 418.75 2771.5 418.84C2772.14 418.96 2772.12 419.37 2771.9 419.84V419.85Z" fill="white"/>
                    <path d="M2755.9 365.34C2755.47 365.05 2755.29 364.86 2755.46 364.56C2755.81 363.98 2756.3 363.67 2756.99 363.86C2757.12 363.9 2757.21 364.11 2757.32 364.24C2756.83 364.62 2756.33 365 2755.9 365.34Z" fill="white"/>
                    <path d="M2713.23 468.41C2712.74 468.33 2712.4 468.12 2712.49 467.9C2712.66 467.46 2712.44 466.69 2713.28 466.7C2713.57 466.7 2713.89 466.93 2713.82 467.18C2713.69 467.64 2713.76 468.3 2713.23 468.41Z" fill="white"/>
                    <path d="M2655 321.26C2654.64 321.13 2654.3 321.02 2654.47 320.51C2654.71 319.8 2655.17 320.16 2655.57 320.26C2655.93 320.35 2656.26 320.48 2656.09 321C2655.85 321.71 2655.39 321.32 2655 321.26Z" fill="white"/>
                    <path d="M2629.83 402.35C2629.36 403.11 2628.87 402.37 2628.4 402.32C2628.19 402.3 2628.16 401.85 2628.32 401.61C2628.78 400.91 2629.3 401.53 2629.76 401.63C2629.98 401.68 2630 402.08 2629.84 402.35H2629.83Z" fill="white"/>
                    <path d="M2766.06 366.35C2765.98 366.72 2765.91 367.11 2765.4 367.02C2764.75 366.91 2764.81 366.49 2764.99 366.01C2765.11 365.67 2765.15 365.26 2765.66 365.35C2766.3 365.47 2766.28 365.88 2766.06 366.35Z" fill="white"/>
                    <path d="M2733.12 518.94C2732.99 519.57 2732.76 519.66 2732.47 519.61C2731.82 519.48 2731.9 519.05 2732.09 518.6C2732.19 518.36 2732.28 517.85 2732.46 517.99C2732.82 518.27 2733.01 518.76 2733.12 518.95V518.94Z" fill="white"/>
                    <path d="M2619.8 324.53C2619.69 324.43 2619.5 324.26 2619.32 324.09C2619.78 323.87 2620.16 323.37 2620.75 323.64C2621.01 323.76 2621.12 323.96 2621.01 324.29C2620.77 324.98 2620.32 324.63 2619.8 324.53Z" fill="white"/>
                    <path d="M2820.78 354.63C2820.81 354.38 2821.05 354.26 2821.33 354.31C2821.69 354.37 2822.07 354.54 2821.84 355.02C2821.68 355.36 2821.84 356.06 2821.21 355.89C2820.99 355.83 2820.61 355.24 2820.78 354.63Z" fill="white"/>
                    <path d="M2774.8 521.5C2774.15 521.38 2774.15 520.73 2773.86 520.32C2774.11 520.3 2774.38 520.22 2774.61 520.28C2774.99 520.39 2775.58 520.36 2775.39 521.08C2775.31 521.41 2775.11 521.49 2774.8 521.5Z" fill="white"/>
                    <path d="M2789.83 348.4C2789.86 348.02 2789.73 348.4 2790.08 348.29C2790.27 348.23 2790.65 348.35 2790.74 348.51C2790.99 348.94 2791.46 349.37 2791.09 349.96C2791.05 350.03 2790.9 350.11 2790.87 350.08C2790.26 349.68 2790.21 348.93 2789.83 348.4Z" fill="white"/>
                    <path d="M2741.18 367.69C2740.97 367.22 2740.47 366.78 2740.88 366.23C2740.98 366.1 2741.41 366.08 2741.6 366.18C2742.2 366.5 2741.73 366.95 2741.67 367.35C2741.63 367.62 2741.5 367.81 2741.18 367.7V367.69Z" fill="white"/>
                    <path d="M2670.24 422.89C2670.06 422.84 2669.86 422.82 2669.72 422.71C2669.63 422.64 2669.56 422.38 2669.61 422.32C2669.8 422.08 2671.46 422.57 2671.55 422.9C2671.57 422.98 2671.36 423.21 2671.29 423.2C2670.93 423.13 2670.58 423 2670.23 422.89H2670.24Z" fill="white"/>
                    <path d="M2719.12 278.74C2718.56 278.6 2718.58 278.25 2718.73 277.9C2718.84 277.64 2719.05 277.53 2719.38 277.64C2719.87 277.8 2719.86 278.12 2719.71 278.47C2719.6 278.72 2719.39 278.92 2719.12 278.74Z" fill="white"/>
                    <path d="M2756.69 524.14C2756.44 523.97 2756.31 523.8 2756.42 523.47C2756.58 522.98 2756.9 522.99 2757.26 523.14C2757.52 523.25 2757.63 523.46 2757.52 523.79C2757.36 524.29 2757.05 524.24 2756.69 524.13V524.14Z" fill="white"/>
                    <path d="M2570.69 435.69C2570.44 435.52 2570.31 435.35 2570.42 435.02C2570.58 434.53 2570.9 434.54 2571.26 434.69C2571.52 434.8 2571.63 435.01 2571.52 435.34C2571.36 435.84 2571.05 435.79 2570.69 435.68V435.69Z" fill="white"/>
                    <path d="M2591.28 331.09C2591.39 330.76 2591.52 330.47 2592.02 330.62C2592.35 330.72 2592.46 330.93 2592.41 331.2C2592.34 331.58 2592.17 331.86 2591.67 331.71C2591.34 331.61 2591.26 331.42 2591.28 331.09Z" fill="white"/>
                    <path d="M2657.81 534.4C2657.47 534.79 2657.08 535.07 2656.54 534.97C2656.4 534.95 2656.16 534.7 2656.17 534.68C2656.46 534.24 2656.86 533.98 2657.41 534.08C2657.55 534.11 2657.67 534.27 2657.82 534.39L2657.81 534.4Z" fill="white"/>
                    <path d="M2579.75 471.38C2579.42 471.35 2579.33 471.09 2579.38 470.81C2579.45 470.43 2579.62 470.16 2580.12 470.3C2580.45 470.4 2580.56 470.61 2580.51 470.88C2580.44 471.26 2580.25 471.56 2579.75 471.37V471.38Z" fill="white"/>
                    <path d="M2690.87 480.36C2690.62 480.19 2690.49 480.02 2690.6 479.69C2690.76 479.2 2691.08 479.21 2691.44 479.36C2691.7 479.47 2691.81 479.68 2691.7 480.01C2691.54 480.51 2691.23 480.46 2690.87 480.35V480.36Z" fill="white"/>
                    <path d="M2774.16 307.47C2773.87 307.28 2773.74 307.11 2773.85 306.77C2774.01 306.28 2774.33 306.29 2774.69 306.44C2774.95 306.55 2775.05 306.76 2774.96 307.09C2774.81 307.6 2774.47 307.51 2774.17 307.46L2774.16 307.47Z" fill="white"/>
                    <path d="M2771.89 330.28C2772.02 329.76 2772.37 329.77 2772.72 329.93C2772.98 330.04 2773.09 330.25 2772.98 330.58C2772.82 331.07 2772.5 331.06 2772.14 330.91C2771.88 330.8 2771.72 330.58 2771.88 330.28H2771.89Z" fill="white"/>
                    <path d="M2621.93 549.58C2622.27 549.7 2622.63 549.82 2622.43 550.3C2622.37 550.45 2622.24 550.57 2622.14 550.7C2621.91 550.37 2621.68 550.05 2621.44 549.72C2621.6 549.67 2621.76 549.62 2621.93 549.57V549.58Z" fill="white"/>
                    <path d="M2618.37 499.14C2618.03 499.02 2617.67 498.9 2617.87 498.42C2617.93 498.27 2618.06 498.15 2618.16 498.02C2618.39 498.35 2618.62 498.67 2618.86 499C2618.7 499.05 2618.54 499.1 2618.37 499.15V499.14Z" fill="white"/>
                    <path d="M2747.73 504.83C2747.55 504.5 2747.37 504.19 2747.2 503.87C2747.45 503.85 2747.73 503.75 2747.94 503.83C2748.4 504.01 2748.25 504.4 2748.1 504.71C2748.06 504.8 2747.84 504.79 2747.74 504.82L2747.73 504.83Z" fill="white"/>
                    <path d="M2632.16 394.76C2632.29 394.59 2632.43 394.33 2632.49 394.35C2632.96 394.48 2632.93 395 2633.18 395.32C2633.02 395.37 2632.84 395.47 2632.69 395.45C2632.31 395.38 2632.03 395.19 2632.16 394.77V394.76Z" fill="white"/>
                    <path d="M2558.27 346.01C2558.18 345.9 2558.01 345.78 2558.02 345.68C2558.07 345.33 2558.17 344.93 2558.65 345.05C2558.87 345.1 2559.04 345.35 2559.23 345.5C2558.91 345.67 2558.59 345.84 2558.26 346.01H2558.27Z" fill="white"/>
                    <path d="M2574.41 368.71C2574.28 369.2 2573.93 369.15 2573.59 369C2573.45 368.94 2573.37 368.75 2573.26 368.62C2573.64 368.48 2574.01 368.34 2574.39 368.2C2574.39 368.37 2574.41 368.53 2574.42 368.71H2574.41Z" fill="white"/>
                    <path d="M2730.76 489.59C2730.71 489.53 2730.53 489.42 2730.54 489.33C2730.59 488.98 2730.68 488.58 2731.16 488.68C2731.38 488.73 2731.55 488.97 2731.75 489.13C2731.43 489.28 2731.11 489.44 2730.76 489.6V489.59Z" fill="white"/>
                    <path d="M2696.57 477.41C2696.08 477.28 2696.13 476.93 2696.28 476.59C2696.34 476.45 2696.53 476.37 2696.66 476.26C2696.8 476.64 2696.94 477.01 2697.08 477.39C2696.91 477.39 2696.75 477.41 2696.57 477.42V477.41Z" fill="white"/>
                    <path d="M2551.43 408.6C2551.31 408.47 2551.2 408.34 2551.09 408.22C2551.46 408.06 2551.82 407.91 2552.19 407.75C2552.21 407.92 2552.26 408.09 2552.23 408.24C2552.14 408.73 2551.8 408.71 2551.43 408.6Z" fill="white"/>
                    <path d="M2753.99 331.22C2753.86 331.71 2753.51 331.66 2753.17 331.51C2753.03 331.45 2752.95 331.26 2752.84 331.13C2753.22 330.99 2753.59 330.85 2753.97 330.71C2753.97 330.88 2753.99 331.04 2754 331.22H2753.99Z" fill="white"/>
                    <path d="M2763.21 293.78C2762.98 293.84 2762.81 293.93 2762.66 293.91C2762.28 293.83 2761.99 293.63 2762.19 293.18C2762.25 293.03 2762.4 292.92 2762.5 292.79C2762.73 293.11 2762.96 293.44 2763.21 293.78Z" fill="white"/>
                    <path d="M2804.71 497.14C2804.45 496.86 2804.66 496.61 2804.74 496.38C2804.77 496.31 2805.06 496.24 2805.13 496.29C2805.39 496.52 2805.18 496.8 2805.09 497.03C2805.06 497.11 2804.83 497.11 2804.71 497.14Z" fill="white"/>
                    <path d="M2780.97 503.18C2780.66 503.18 2780.4 503.22 2780.17 503.15C2780.04 503.11 2779.95 502.9 2779.84 502.77C2780.09 502.75 2780.35 502.68 2780.59 502.73C2780.72 502.75 2780.81 502.97 2780.98 503.18H2780.97Z" fill="white"/>
                    <path d="M2663.67 285.65C2663.41 285.37 2663.62 285.12 2663.7 284.89C2663.73 284.82 2664.02 284.75 2664.09 284.8C2664.36 285.03 2664.14 285.31 2664.05 285.54C2664.02 285.62 2663.79 285.62 2663.67 285.65Z" fill="white"/>
                    <path d="M2575.78 342.16C2575.78 341.85 2575.74 341.59 2575.81 341.36C2575.85 341.23 2576.06 341.14 2576.19 341.03C2576.21 341.28 2576.28 341.54 2576.23 341.78C2576.21 341.91 2575.99 342 2575.78 342.17V342.16Z" fill="white"/>
                    <path d="M2575.25 345.13C2575.3 344.99 2575.36 344.74 2575.5 344.54C2575.66 344.31 2575.85 344.37 2575.84 344.65C2575.84 344.89 2575.75 345.15 2575.62 345.35C2575.47 345.59 2575.31 345.53 2575.25 345.13Z" fill="white"/>
                    <path d="M2651.11 274.24C2651 274.37 2650.88 274.62 2650.79 274.6C2650.46 274.56 2650.46 274.3 2650.58 274.05C2650.65 273.9 2650.8 273.67 2650.89 273.68C2651.22 273.72 2651.21 273.97 2651.1 274.23L2651.11 274.24Z" fill="white"/>
                    <path d="M2753.12 518.92C2752.99 518.81 2752.74 518.69 2752.76 518.6C2752.8 518.27 2753.06 518.27 2753.31 518.39C2753.46 518.46 2753.69 518.61 2753.68 518.7C2753.64 519.03 2753.39 519.02 2753.13 518.91L2753.12 518.92Z" fill="white"/>
                    <path d="M2558.94 415.37C2558.63 415.37 2558.37 415.41 2558.14 415.34C2558.01 415.3 2557.92 415.09 2557.81 414.96C2558.06 414.94 2558.32 414.87 2558.56 414.92C2558.69 414.94 2558.78 415.16 2558.95 415.37H2558.94Z" fill="white"/>
                    <path d="M2801.57 376.38C2801.49 376.29 2801.38 376.15 2801.28 376.02C2801.53 376 2801.79 375.93 2802.03 375.98C2802.16 376 2802.25 376.22 2802.36 376.35C2802.12 376.51 2801.87 376.55 2801.58 376.38H2801.57Z" fill="white"/>
                    <path d="M2548.37 457.02C2548.43 456.75 2548.56 456.54 2548.86 456.69C2548.94 456.73 2548.93 457.01 2548.91 457.17C2548.88 457.44 2548.73 457.65 2548.43 457.5C2548.35 457.46 2548.39 457.19 2548.37 457.02Z" fill="white"/>
                    <path d="M2765.57 364.15C2765.63 363.88 2765.76 363.67 2766.06 363.82C2766.14 363.86 2766.13 364.14 2766.11 364.3C2766.08 364.57 2765.93 364.78 2765.63 364.63C2765.55 364.59 2765.59 364.32 2765.57 364.15Z" fill="white"/>
                    <path d="M2755.04 369.72C2755.1 369.45 2755.23 369.24 2755.53 369.39C2755.61 369.43 2755.6 369.71 2755.58 369.87C2755.55 370.14 2755.4 370.35 2755.1 370.2C2755.02 370.16 2755.06 369.89 2755.04 369.72Z" fill="white"/>
                    <path d="M2719.56 395.54C2719.25 395.54 2718.99 395.58 2718.76 395.51C2718.63 395.47 2718.54 395.26 2718.43 395.13C2718.68 395.11 2718.94 395.04 2719.18 395.09C2719.31 395.11 2719.4 395.33 2719.57 395.54H2719.56Z" fill="white"/>
                    <path d="M2737.92 496.07C2737.77 495.92 2737.59 495.75 2737.41 495.57C2737.57 495.52 2737.77 495.39 2737.89 495.44C2738.11 495.54 2738.29 495.74 2738.48 495.9C2738.32 495.95 2738.15 496 2737.91 496.07H2737.92Z" fill="white"/>
                    <path d="M2662.56 448.1C2662.48 448.01 2662.37 447.87 2662.27 447.74C2662.52 447.72 2662.78 447.65 2663.02 447.7C2663.15 447.72 2663.24 447.94 2663.35 448.07C2663.11 448.23 2662.86 448.27 2662.57 448.1H2662.56Z" fill="white"/>
                    <path d="M2643.15 487.15C2642.84 487.15 2642.58 487.19 2642.35 487.12C2642.22 487.08 2642.13 486.87 2642.02 486.74C2642.27 486.72 2642.53 486.65 2642.77 486.7C2642.9 486.72 2642.99 486.94 2643.16 487.15H2643.15Z" fill="white"/>
                    <path d="M2613.2 548.57C2612.94 548.29 2613.15 548.04 2613.23 547.81C2613.26 547.74 2613.55 547.67 2613.62 547.72C2613.88 547.95 2613.67 548.23 2613.58 548.46C2613.55 548.54 2613.32 548.54 2613.2 548.57Z" fill="white"/>
                    <path d="M2603.42 488.08C2603.48 487.81 2603.61 487.6 2603.91 487.75C2603.99 487.79 2603.98 488.07 2603.96 488.23C2603.93 488.5 2603.78 488.71 2603.48 488.56C2603.4 488.52 2603.44 488.25 2603.42 488.08Z" fill="white"/>
                    <path d="M2669.5 302.23C2669.39 302.36 2669.27 302.61 2669.18 302.59C2668.85 302.55 2668.85 302.29 2668.97 302.04C2669.04 301.89 2669.19 301.66 2669.28 301.67C2669.61 301.71 2669.6 301.96 2669.49 302.22L2669.5 302.23Z" fill="white"/>
                    <path d="M2656.83 392.9C2656.7 392.79 2656.45 392.67 2656.47 392.58C2656.51 392.25 2656.77 392.25 2657.02 392.37C2657.17 392.44 2657.4 392.59 2657.39 392.68C2657.35 393.01 2657.1 393 2656.84 392.89L2656.83 392.9Z" fill="white"/>
                    <path d="M2642.64 389.41C2642.5 389.43 2642.36 389.48 2642.23 389.47C2642.18 389.47 2642.15 389.33 2642.11 389.25C2642.25 389.23 2642.39 389.18 2642.52 389.19C2642.57 389.19 2642.6 389.33 2642.64 389.41Z" fill="white"/>
                    <path d="M2541.5 408.77C2541.36 408.79 2541.22 408.84 2541.09 408.83C2541.04 408.83 2541.01 408.69 2540.97 408.61C2541.11 408.59 2541.25 408.54 2541.38 408.55C2541.43 408.55 2541.46 408.69 2541.5 408.77Z" fill="white"/>
                    <path d="M2748.89 379.48C2748.8 379.38 2748.7 379.28 2748.6 379.18C2748.68 379.14 2748.79 379.04 2748.83 379.07C2748.95 379.14 2749.04 379.25 2749.14 379.35C2749.06 379.39 2748.98 379.43 2748.89 379.48Z" fill="white"/>
                    <path d="M2773.58 343.19C2773.63 343.28 2773.67 343.36 2773.71 343.44C2773.57 343.46 2773.43 343.51 2773.3 343.5C2773.25 343.5 2773.22 343.36 2773.18 343.28C2773.32 343.25 2773.45 343.22 2773.58 343.2V343.19Z" fill="white"/>
                    <path d="M2750.87 421.67C2750.97 421.58 2751.07 421.48 2751.17 421.38C2751.21 421.46 2751.31 421.57 2751.28 421.61C2751.21 421.73 2751.1 421.82 2751 421.92C2750.96 421.84 2750.92 421.76 2750.87 421.67Z" fill="white"/>
                    <path d="M2711.66 440.94C2711.64 440.8 2711.59 440.66 2711.6 440.53C2711.6 440.48 2711.74 440.45 2711.82 440.41C2711.84 440.55 2711.89 440.69 2711.88 440.82C2711.88 440.87 2711.74 440.9 2711.66 440.94Z" fill="white"/>
                    <path d="M2693.72 483.2C2693.81 483.15 2693.89 483.11 2693.97 483.07C2693.99 483.21 2694.04 483.35 2694.03 483.48C2694.03 483.53 2693.89 483.56 2693.81 483.6C2693.78 483.46 2693.75 483.33 2693.73 483.2H2693.72Z" fill="white"/>
                    <path d="M2739.59 516.43C2739.5 516.33 2739.4 516.23 2739.3 516.13C2739.38 516.09 2739.49 515.99 2739.53 516.02C2739.65 516.09 2739.74 516.2 2739.84 516.3C2739.76 516.34 2739.68 516.38 2739.59 516.43Z" fill="white"/>
                    <path d="M2758.01 404.61C2757.96 404.52 2757.92 404.44 2757.88 404.36C2758.02 404.34 2758.16 404.29 2758.29 404.3C2758.34 404.3 2758.37 404.44 2758.41 404.52C2758.27 404.55 2758.14 404.58 2758.01 404.6V404.61Z" fill="white"/>
                    <path d="M2689.59 421.3C2689.64 421.39 2689.68 421.47 2689.72 421.55C2689.58 421.57 2689.44 421.62 2689.31 421.61C2689.26 421.61 2689.23 421.47 2689.19 421.39C2689.33 421.36 2689.46 421.33 2689.59 421.31V421.3Z" fill="white"/>
                    <path d="M2639.09 547.58C2639.01 547.62 2638.89 547.71 2638.87 547.69C2638.77 547.59 2638.65 547.48 2638.63 547.35C2638.59 547.09 2638.73 547.02 2638.93 547.19C2639.02 547.28 2639.04 547.44 2639.09 547.57V547.58Z" fill="white"/>
                    <path d="M2638.45 547.04C2638.4 546.91 2638.27 546.72 2638.32 546.66C2638.45 546.47 2638.62 546.49 2638.67 546.66C2638.71 546.81 2638.69 546.97 2638.7 547.12C2638.62 547.1 2638.54 547.07 2638.46 547.05L2638.45 547.04Z" fill="white"/>
                    <path d="M2636.95 361.43C2636.86 361.33 2636.76 361.23 2636.66 361.13C2636.74 361.09 2636.85 360.99 2636.89 361.02C2637.01 361.09 2637.1 361.2 2637.2 361.3C2637.12 361.34 2637.04 361.38 2636.95 361.43Z" fill="white"/>
                    <path d="M2716.86 571.12C2716.77 571.02 2716.67 570.92 2716.57 570.82C2716.65 570.78 2716.76 570.68 2716.8 570.71C2716.92 570.78 2717.01 570.89 2717.11 570.99C2717.03 571.03 2716.95 571.07 2716.86 571.12Z" fill="white"/>
                    <path d="M2632.8 551.67C2632.75 551.58 2632.71 551.5 2632.67 551.42C2632.81 551.4 2632.95 551.35 2633.08 551.36C2633.13 551.36 2633.16 551.5 2633.2 551.58C2633.06 551.61 2632.93 551.64 2632.8 551.66V551.67Z" fill="white"/>
                    <path d="M2737.15 535.24C2737.24 535.34 2737.34 535.44 2737.44 535.54C2737.36 535.58 2737.25 535.68 2737.21 535.65C2737.09 535.58 2737 535.47 2736.9 535.37C2736.98 535.33 2737.06 535.29 2737.15 535.24Z" fill="white"/>
                    <path d="M2626.27 453.31C2626.18 453.21 2626.08 453.11 2625.98 453.01C2626.06 452.97 2626.17 452.87 2626.21 452.9C2626.33 452.97 2626.42 453.08 2626.52 453.18C2626.44 453.22 2626.36 453.26 2626.27 453.31Z" fill="white"/>
                    <path d="M2756.56 450.87C2756.65 450.82 2756.73 450.78 2756.81 450.74C2756.83 450.88 2756.88 451.02 2756.87 451.15C2756.87 451.2 2756.73 451.23 2756.65 451.27C2756.62 451.13 2756.59 451 2756.57 450.87H2756.56Z" fill="white"/>
                    <path d="M2630.33 518.98C2630.19 519 2630.05 519.05 2629.92 519.04C2629.87 519.04 2629.84 518.9 2629.8 518.82C2629.94 518.8 2630.08 518.75 2630.21 518.76C2630.26 518.76 2630.29 518.9 2630.33 518.98Z" fill="white"/>
                    <path d="M2755.42 463.15C2755.33 463.05 2755.23 462.95 2755.13 462.85C2755.21 462.81 2755.32 462.71 2755.36 462.74C2755.48 462.81 2755.57 462.92 2755.67 463.02L2755.42 463.15Z" fill="white"/>
                    <path d="M2664.17 401.25C2664.07 401.34 2663.97 401.44 2663.87 401.54C2663.83 401.46 2663.73 401.35 2663.76 401.31C2663.83 401.19 2663.94 401.1 2664.04 401C2664.08 401.08 2664.12 401.16 2664.17 401.25Z" fill="white"/>
                    <path d="M2642.98 272.02C2642.84 272.04 2642.7 272.09 2642.57 272.08C2642.52 272.08 2642.49 271.94 2642.45 271.86C2642.59 271.84 2642.73 271.79 2642.86 271.8C2642.91 271.8 2642.94 271.94 2642.98 272.02Z" fill="white"/>
                    <path d="M2903.93 491.71C2903.42 493.4 2902.06 493.61 2900.78 493.77C2900.31 493.83 2899.57 493.82 2899.34 493.94C2897.86 494.71 2897.79 492.74 2896.77 492.77C2896.9 491.78 2896.71 490.55 2897.24 489.86C2897.74 489.21 2898.43 488.44 2899.25 488.04C2900.03 487.65 2900.94 486.76 2901.6 488.3C2901.7 488.53 2902.25 488.55 2902.57 488.7C2904.01 489.38 2904.44 490.42 2903.94 491.71H2903.93Z" fill="white"/>
                    <path d="M2835.29 366.7C2836.26 367.09 2836.64 368.13 2836.5 368.47C2835.96 369.78 2836.96 370.78 2836.93 371.97C2836.91 372.81 2836.77 373.32 2836 373.6C2834.74 374.07 2834.13 373.95 2833.61 373.12C2833.38 372.76 2833.13 372.44 2832.85 372.12C2831.66 370.72 2832.38 367.07 2834.46 366.72C2834.83 366.66 2835.14 366.6 2835.28 366.7H2835.29Z" fill="white"/>
                    <path d="M2818.45 450.15C2818.79 451 2820.1 451.3 2819.73 452.52C2819.68 452.69 2819.54 452.86 2819.55 453.02C2819.61 453.69 2819.92 454.39 2819.28 454.95C2818.78 455.39 2818.13 455.24 2817.54 455.3C2816.38 455.41 2815.13 454.34 2814.87 453.09C2814.67 452.11 2814.99 451.38 2815.76 450.73C2816.57 450.05 2817.53 450.3 2818.44 450.15H2818.45Z" fill="white"/>
                    <path d="M2692.59 464.16C2691.13 463.59 2690.67 462.8 2690.96 461.7C2691.12 461.09 2691.42 460.53 2691.61 459.93C2691.79 459.36 2692.09 459.29 2692.61 459.52C2693.18 459.78 2693.83 459.9 2694.36 460.22C2695.26 460.76 2695.65 461.67 2695.71 462.7C2695.72 462.91 2695.55 463.25 2695.38 463.33C2694.73 463.61 2694.24 464.08 2693.68 464.46C2693.41 464.65 2692.71 464.2 2692.6 464.17L2692.59 464.16Z" fill="white"/>
                    <path d="M2718.54 348.79C2719.22 348.99 2720.2 349.25 2720.08 350.39C2720.02 350.93 2719.92 351.93 2719.24 351.89C2718.51 351.84 2717.65 352.39 2717.01 351.66C2716.57 351.16 2716.27 350.47 2715.75 350.1C2715.27 349.76 2715.22 349.55 2715.4 349.05C2715.66 348.34 2716.22 348.11 2716.84 348.18C2717.36 348.23 2717.85 348.54 2718.54 348.8V348.79Z" fill="white"/>
                    <path d="M2684.13 481.8C2684.8 482.04 2685.17 482.78 2684.96 483.45C2684.89 483.67 2682.91 485.3 2682.7 485.31C2682.22 485.33 2681.24 484 2681.32 483.98C2682.43 483.73 2681.43 482.77 2681.93 482.36C2682.4 481.98 2683.7 481.63 2684.13 481.79V481.8Z" fill="white"/>
                    <path d="M2843.13 503.59C2843.27 503.24 2843.39 502.67 2843.72 502.8C2844.34 503.03 2845.13 503 2845.5 503.81C2845.7 504.26 2845.47 504.69 2845.63 505.06C2845.89 505.66 2845.85 506 2845.21 506.33C2844.53 506.68 2843.88 506.53 2843.57 506.03C2843.13 505.32 2842.52 504.52 2843.13 503.6V503.59Z" fill="white"/>
                    <path d="M2809.45 416C2808.93 415.76 2808.14 415.77 2808.57 414.78C2808.67 414.56 2808.3 414.15 2808.17 413.81C2808.03 413.43 2807.92 413.04 2807.79 412.65C2808.37 412.81 2809.13 412.8 2809.5 413.17C2809.97 413.65 2810.11 414.44 2810.43 415.08C2810.61 415.43 2810.7 415.79 2810.43 416.06C2810.13 416.36 2809.77 416.1 2809.45 415.99V416Z" fill="white"/>
                    <path d="M2766.51 553.87C2766.35 553.81 2766.17 553.76 2766.02 553.68C2765.68 553.49 2765.05 553.56 2765.14 552.94C2765.23 552.3 2765.6 551.86 2766.27 551.66C2766.84 551.49 2767.02 551.78 2767.25 552.2C2767.4 552.47 2767.78 552.61 2767.93 552.88C2768.13 553.23 2768.47 553.64 2768.16 554.08C2767.79 554.61 2767.39 554.14 2767.01 554.06C2766.84 554.02 2766.68 553.94 2766.52 553.88L2766.51 553.87Z" fill="white"/>
                    <path d="M2678.91 473.47C2678.27 474 2677.77 474.74 2676.9 474.94C2676.7 474.99 2676.38 474.87 2676.28 474.55C2676.15 474.15 2675.71 473.72 2676.09 473.33C2676.55 472.85 2677.11 472.47 2677.67 472.1C2678.33 471.67 2678.13 472.59 2678.5 472.68C2678.88 472.77 2679.12 473 2678.91 473.46V473.47Z" fill="white"/>
                    <path d="M2851.49 429.16C2851.01 429.06 2850.79 428.8 2851.03 428.25C2851.41 427.4 2852.05 427.08 2852.84 427.35C2853.54 427.58 2853.76 428.15 2853.72 428.75C2853.68 429.43 2852.59 429.62 2851.49 429.17V429.16Z" fill="white"/>
                    <path d="M2917.82 382.59C2917.57 382.49 2917.29 382.44 2917.07 382.29C2916.55 381.95 2916.63 381.2 2917.19 380.95C2917.52 380.8 2917.83 380.59 2918.18 380.53C2918.62 380.45 2919.14 380.61 2919.34 380.97C2919.57 381.38 2919.23 381.96 2919.56 382.42C2919.07 382.56 2918.66 383.07 2918.07 382.69C2918 382.64 2917.9 382.62 2917.82 382.59Z" fill="white"/>
                    <path d="M2727.74 484.54C2727.87 484.83 2727.96 485.08 2728.09 485.31C2728.41 485.9 2727.54 486.42 2728.01 487.05C2728.25 487.38 2728.11 487.76 2727.72 487.89C2727.35 488.01 2727.08 487.79 2726.93 487.42C2726.73 486.93 2726.47 486.46 2726.29 485.95C2726.21 485.71 2725.73 485.4 2726.33 485.16C2726.81 484.97 2727.27 484.74 2727.74 484.53V484.54Z" fill="white"/>
                    <path d="M2666.92 391C2667.2 390.5 2668.22 390.32 2668.77 390.67C2668.99 390.81 2669.35 392.44 2669.16 392.69C2668.68 393.33 2668.2 392.75 2667.79 392.59C2667.03 392.29 2666.64 391.48 2666.92 391Z" fill="white"/>
                    <path d="M2740.5 375.99C2740.38 376.53 2740.15 376.8 2739.58 376.56C2738.89 376.26 2738.38 375.5 2738.57 375C2738.76 374.5 2739.67 374.26 2740.37 374.54C2741.42 374.95 2740.51 375.53 2740.5 376V375.99Z" fill="white"/>
                    <path d="M2745.68 371.92C2745.98 372.31 2746.34 372.72 2746.17 373.27C2745.99 373.86 2744.77 374.06 2744.15 373.66C2743.74 373.39 2743.9 373.08 2744.05 372.77C2744.4 372.06 2745.17 371.65 2745.67 371.92H2745.68Z" fill="white"/>
                    <path d="M2724.57 452.01C2724.24 451.88 2724.08 451.8 2723.91 451.76C2723.27 451.6 2723.3 450.97 2723.03 450.55C2723.51 450.42 2723.91 449.92 2724.49 450.23C2724.64 450.31 2724.81 450.37 2724.98 450.42C2725.55 450.59 2725.88 450.79 2725.59 451.52C2725.31 452.22 2724.97 452.27 2724.57 452V452.01Z" fill="white"/>
                    <path d="M2795.1 313.55C2794.91 314.16 2794.48 314.12 2794.24 313.89C2793.77 313.42 2793.38 312.85 2793 312.29C2792.65 311.77 2793.22 311.8 2793.45 311.65C2794.46 311.01 2794.47 312.28 2795.05 312.51C2795.71 312.78 2795.08 313.26 2795.11 313.55H2795.1Z" fill="white"/>
                    <path d="M2725.84 419.44C2726.58 419.57 2726.81 420.16 2726.79 420.75C2726.78 421.2 2725.4 421.77 2725.16 421.49C2724.7 420.94 2724.65 420.33 2724.97 419.66C2725.2 419.19 2725.5 419.32 2725.83 419.44H2725.84Z" fill="white"/>
                    <path d="M2827.59 548.82C2827.32 549.28 2826.82 549.4 2826.32 549.45C2826.02 549.48 2825.62 548.05 2825.77 547.51C2825.91 547.03 2826.25 547.1 2826.58 547.25C2827.26 547.53 2827.77 548.31 2827.58 548.82H2827.59Z" fill="white"/>
                    <path d="M2695.09 441.75C2695.26 441.53 2695.53 441.36 2695.74 441.59C2696.24 442.16 2696.88 442.6 2697.18 443.36C2697.3 443.68 2697.22 443.96 2697.06 444.09C2696.91 444.21 2696.47 444.23 2696.35 444.1C2695.89 443.61 2695.5 443.05 2695.1 442.5C2694.93 442.27 2694.94 442.01 2695.09 441.74V441.75Z" fill="white"/>
                    <path d="M2665.8 443.48C2666.07 443.56 2666.42 443.65 2666.34 444.12C2666.27 444.53 2665.89 444.58 2665.62 444.77C2665.06 445.15 2664.59 444.79 2664.17 444.55C2663.92 444.41 2663.66 443.8 2663.76 443.66C2663.97 443.37 2664.4 443.15 2664.76 443.1C2665.07 443.06 2665.41 443.32 2665.8 443.46V443.48Z" fill="white"/>
                    <path d="M2746.9 319.77C2746.99 319.33 2747.25 319.12 2747.8 319.36C2748.48 319.67 2748.97 320.41 2748.79 320.91C2748.49 321.76 2747.65 320.78 2747.21 321.35C2747.14 321.45 2746.7 320.42 2746.9 319.76V319.77Z" fill="white"/>
                    <path d="M2762.88 486.37C2762.96 486.51 2763.13 486.66 2763.12 486.78C2763.03 487.48 2761.69 488.27 2761.08 487.98C2760.87 487.88 2760.59 487.67 2760.58 487.49C2760.56 487.3 2760.78 487.02 2760.97 486.9C2761.81 486.37 2762.47 486.21 2762.89 486.36L2762.88 486.37Z" fill="white"/>
                    <path d="M2863.67 483.24C2863.76 482.99 2863.82 482.72 2863.96 482.5C2864.32 481.94 2864.73 482.41 2865.1 482.5C2865.59 482.62 2865.51 482.97 2865.37 483.32C2865.24 483.64 2865.18 483.98 2864.78 484.12C2864.37 484.27 2863.96 484.58 2863.57 484.21C2863.27 483.92 2863.55 483.56 2863.66 483.24H2863.67Z" fill="white"/>
                    <path d="M2698.07 441.32C2697.6 441.02 2697.78 440.69 2697.93 440.39C2698.3 439.67 2699.06 439.28 2699.55 439.57C2699.96 439.81 2699.79 440.16 2699.63 440.46C2699.26 441.18 2698.51 441.56 2698.07 441.32Z" fill="white"/>
                    <path d="M2739.95 316.68C2740.53 316.45 2741.01 316.09 2741.69 316.43C2742.25 316.72 2742.16 317.01 2742 317.46C2741.83 317.95 2741.65 318.13 2741.07 317.99C2740.31 317.8 2740.27 317.17 2739.96 316.68H2739.95Z" fill="white"/>
                    <path d="M2753.49 318.07C2753.59 318.2 2753.78 318.36 2753.76 318.47C2753.69 318.81 2753.62 319.26 2753.38 319.43C2752.99 319.71 2752.53 320.1 2751.97 319.81C2751.54 319.58 2751.71 319.23 2751.87 318.92C2752.24 318.19 2752.95 317.82 2753.49 318.07Z" fill="white"/>
                    <path d="M2861.13 519.84C2861.47 519.98 2861.83 520.14 2861.58 520.57C2861.25 521.13 2860.69 521.45 2860.09 521.14C2859.78 520.98 2859.66 520.44 2859.46 520.07C2860 519.86 2860.51 519.4 2861.13 519.83V519.84Z" fill="white"/>
                    <path d="M2873.3 377.59C2873.03 378.18 2872.1 378.54 2871.7 378.31C2871.29 378.07 2871.5 377.75 2871.6 377.42C2871.81 376.77 2872.31 376.48 2872.89 376.6C2873.06 376.64 2873.42 377.26 2873.29 377.59H2873.3Z" fill="white"/>
                    <path d="M2724.72 399.87C2725.14 400.03 2725.57 400.22 2725.28 400.61C2724.91 401.08 2724.37 401.42 2723.91 401.81C2723.62 401.26 2723.4 400.76 2723.74 400.1C2724.07 399.48 2724.43 399.81 2724.72 399.87Z" fill="white"/>
                    <path d="M2834.78 321.3C2835.5 321.49 2835.42 321.84 2835.27 322.33C2835.04 323.1 2834.64 322.98 2834.08 322.77C2833.5 322.55 2833.55 322.25 2833.71 321.76C2833.94 321.03 2834.33 320.99 2834.78 321.31V321.3Z" fill="white"/>
                    <path d="M2715.44 406.71C2715.19 407.12 2715.25 407.96 2714.45 407.52C2714.01 407.28 2713.75 406.75 2713.9 406.2C2714.14 405.3 2714.98 405.9 2715.44 405.81C2716.02 405.7 2715.37 406.39 2715.44 406.71Z" fill="white"/>
                    <path d="M2650.23 452.61C2650.48 452.7 2650.74 452.77 2650.96 452.9C2651.36 453.15 2651.18 453.49 2651.03 453.8C2650.93 454.02 2650.94 454.35 2650.61 454.4C2650.23 454.45 2649.96 454.29 2649.83 453.91C2649.77 453.75 2649.68 453.59 2649.62 453.43C2649.49 453.12 2649.18 452.67 2649.29 452.52C2649.52 452.19 2649.91 452.52 2650.23 452.61Z" fill="white"/>
                    <path d="M2900.73 421.13C2901.13 420.62 2901.52 421.04 2901.88 421.17C2902.56 421.4 2902.15 421.86 2902.04 422.23C2901.93 422.58 2901.77 422.91 2901.3 422.67C2900.83 422.43 2900.5 421.54 2900.72 421.13H2900.73Z" fill="white"/>
                    <path d="M2769.6 365.13C2769.41 365.56 2769.28 365.91 2768.81 365.67C2768.34 365.43 2767.99 364.53 2768.24 364.13C2768.49 363.73 2768.82 363.97 2769.15 364.05C2769.95 364.23 2769.81 364.7 2769.61 365.13H2769.6Z" fill="white"/>
                    <path d="M2683.68 424.45C2683.79 424.3 2683.92 423.95 2684.08 423.94C2684.51 423.91 2684.52 424.4 2684.56 424.64C2684.62 425.01 2685.51 425.44 2684.54 425.81C2684.22 425.93 2683.91 426.15 2683.58 425.91C2682.92 425.42 2683.69 425 2683.67 424.46L2683.68 424.45Z" fill="white"/>
                    <path d="M2768.63 534.97C2768.86 535.32 2769.46 535.81 2769 536.1C2768.6 536.35 2768.58 537.1 2767.97 536.93C2767.6 536.82 2767.57 535.41 2767.92 535.03C2768.1 534.83 2768.36 534.81 2768.63 534.97Z" fill="white"/>
                    <path d="M2830.59 337.58C2830.59 337.58 2831.06 337.48 2831.49 337.46C2831.68 337.45 2831.97 338.65 2831.83 338.86C2831.46 339.42 2831.07 338.96 2830.7 338.86C2830.25 338.75 2830.25 338.43 2830.59 337.58Z" fill="white"/>
                    <path d="M2858.5 352.96C2859.01 353.35 2858.57 353.73 2858.48 354.1C2858.36 354.57 2857.95 354.6 2857.7 354.36C2857.41 354.08 2857.23 353.66 2857.08 353.27C2857.03 353.13 2858.3 352.83 2858.5 352.96Z" fill="white"/>
                    <path d="M2903.31 511.46C2903.23 511.93 2902.96 512.03 2902.72 511.93C2902.08 511.68 2901.89 511.14 2901.97 510.49C2902.01 510.16 2902.29 510.06 2902.54 510.15C2903.22 510.39 2903.34 510.96 2903.31 511.46Z" fill="white"/>
                    <path d="M2746.4 498.69C2746.68 498.19 2747.17 497.92 2747.6 498.09C2747.81 498.17 2748.15 498.74 2747.94 499.23C2747.67 499.89 2747.25 499.5 2746.88 499.39C2746.53 499.29 2746.24 499.12 2746.4 498.69Z" fill="white"/>
                    <path d="M2918.28 423.8C2918.22 424.05 2917.17 424.76 2917.1 424.62C2916.91 424.25 2916.72 423.83 2916.71 423.43C2916.71 423.06 2917.03 422.83 2917.44 423.04C2917.78 423.22 2918.37 423.14 2918.28 423.8Z" fill="white"/>
                    <path d="M2740.89 498.3C2741.28 498.61 2741.62 499.08 2741.35 499.74C2741.25 499.98 2741 500.13 2740.72 499.94C2740.18 499.57 2739.9 499.07 2740.14 498.42C2740.23 498.18 2740.48 498.04 2740.89 498.3Z" fill="white"/>
                    <path d="M2707.54 548.74C2708.01 548.89 2708.06 549.28 2707.81 549.54C2707.53 549.83 2707.11 550.01 2706.72 550.16C2706.58 550.21 2706.28 548.94 2706.41 548.74C2706.59 548.45 2707.03 548.46 2707.54 548.74Z" fill="white"/>
                    <path d="M2719.88 406.72C2720.23 406.86 2720.58 407 2720.36 407.46C2720.12 407.96 2719.61 408.21 2719.18 408.04C2718.97 407.96 2718.64 407.39 2718.84 406.89C2719.11 406.23 2719.53 406.65 2719.89 406.72H2719.88Z" fill="white"/>
                    <path d="M2669.04 475.52C2669.37 475.9 2669.64 476.28 2669.51 476.82C2669.43 477.14 2669.16 477.26 2668.93 477.13C2668.55 476.92 2667.76 477.07 2668 476.22C2668.15 475.7 2668.71 475.79 2669.04 475.52Z" fill="white"/>
                    <path d="M2725.17 418.02C2725.26 418.16 2725.34 418.29 2725.43 418.43C2724.73 418.76 2723.95 418.98 2723.49 419.7C2723.47 419.73 2723.14 419.62 2723.02 419.51C2722.95 419.45 2722.96 419.14 2723 419.13C2723.68 418.91 2723.89 418.01 2724.67 417.95C2724.83 417.94 2725 418 2725.17 418.03V418.02Z" fill="white"/>
                    <path d="M2724.58 422.19C2724.36 422.68 2723.82 422.16 2723.45 421.97C2722.71 421.6 2723.37 421.1 2723.45 420.67C2723.48 420.54 2723.92 420.19 2723.9 420.66C2723.86 421.35 2725.17 421.02 2724.57 422.19H2724.58Z" fill="white"/>
                    <path d="M2884.32 473.2C2884.45 472.56 2885.09 472.61 2885.5 472.34C2885.65 472.64 2885.98 473.02 2885.9 473.23C2885.71 473.68 2885.23 473.85 2884.71 473.75C2884.4 473.69 2884.33 473.47 2884.33 473.2H2884.32Z" fill="white"/>
                    <path d="M2824.91 340.29C2824.72 340.62 2824.74 341.21 2824.06 340.97C2823.57 340.8 2823.66 340.47 2823.81 340.14C2823.97 339.78 2823.97 339.21 2824.65 339.45C2825.15 339.62 2825.02 339.95 2824.91 340.3V340.29Z" fill="white"/>
                    <path d="M2857.38 539.95C2857.9 540.67 2857.85 541.24 2857.52 541.8C2857.44 541.93 2857.26 542 2857.13 542.09C2857.06 541.93 2857.01 541.75 2856.91 541.62C2856.51 541.1 2856.8 540.63 2857.01 540.17C2857.08 540.03 2857.33 539.98 2857.38 539.95Z" fill="white"/>
                    <path d="M2839.02 316.5C2839.15 316.54 2839.09 316.88 2838.93 317.22C2838.76 317.57 2838.78 318.16 2838.09 317.92C2837.61 317.75 2837.67 317.43 2837.83 317.09C2837.99 316.73 2838 316.16 2839.02 316.5Z" fill="white"/>
                    <path d="M2795.75 354.15C2795.65 354.22 2795.52 354.4 2795.44 354.37C2794.85 354.21 2794.29 354.03 2794.11 353.31C2794.04 353.01 2794.03 352.83 2794.34 352.82C2794.82 352.79 2795.79 353.72 2795.75 354.15Z" fill="white"/>
                    <path d="M2866.75 431.52C2867.45 432.02 2866.71 432.45 2866.63 432.9C2866.59 433.1 2866.16 433.1 2865.94 432.94C2865.29 432.45 2865.92 432 2866.05 431.56C2866.11 431.36 2866.49 431.36 2866.75 431.53V431.52Z" fill="white"/>
                    <path d="M2764.55 407.55C2765.25 408.05 2764.51 408.48 2764.43 408.93C2764.39 409.13 2763.96 409.13 2763.74 408.97C2763.09 408.48 2763.72 408.03 2763.85 407.59C2763.91 407.39 2764.29 407.39 2764.55 407.56V407.55Z" fill="white"/>
                    <path d="M2790.37 525.84C2790.5 525.88 2790.44 526.22 2790.28 526.56C2790.11 526.91 2790.13 527.5 2789.44 527.26C2788.96 527.09 2789.02 526.77 2789.18 526.43C2789.34 526.07 2789.35 525.5 2790.37 525.84Z" fill="white"/>
                    <path d="M2702.51 372.43C2701.95 372.5 2701.38 372.59 2700.8 372.65C2700.77 372.65 2700.69 372.49 2700.7 372.42C2700.76 372.05 2700.98 371.84 2701.34 371.7C2701.75 371.54 2702.14 371.32 2702.48 371.76C2702.56 371.86 2702.5 372.07 2702.51 372.43Z" fill="white"/>
                    <path d="M2701.98 422.21C2702.08 421.86 2702.17 421.49 2702.65 421.61C2703.27 421.76 2703.19 422.16 2702.98 422.61C2702.84 422.93 2702.78 423.32 2702.3 423.2C2701.69 423.05 2701.73 422.65 2701.97 422.21H2701.98Z" fill="white"/>
                    <path d="M2713.92 475.71C2714.31 476.02 2714.48 476.21 2714.29 476.49C2713.92 477.03 2713.43 477.3 2712.77 477.07C2712.64 477.02 2712.57 476.82 2712.48 476.68C2712.98 476.34 2713.48 476.01 2713.92 475.71Z" fill="white"/>
                    <path d="M2761.56 379.18C2762.03 379.29 2762.34 379.52 2762.24 379.72C2762.04 380.13 2762.21 380.89 2761.4 380.82C2761.13 380.8 2760.83 380.56 2760.91 380.33C2761.07 379.9 2761.04 379.25 2761.56 379.18Z" fill="white"/>
                    <path d="M2808.27 524.58C2808.61 524.73 2808.93 524.85 2808.73 525.34C2808.45 526.01 2808.03 525.63 2807.65 525.51C2807.3 525.4 2807 525.25 2807.2 524.77C2807.48 524.1 2807.9 524.51 2808.27 524.59V524.58Z" fill="white"/>
                    <path d="M2837.66 448.1C2838.16 447.4 2838.59 448.14 2839.04 448.22C2839.24 448.26 2839.24 448.69 2839.08 448.91C2838.59 449.56 2838.14 448.93 2837.7 448.8C2837.5 448.74 2837.5 448.36 2837.67 448.1H2837.66Z" fill="white"/>
                    <path d="M2704.19 474.09C2704.29 473.74 2704.38 473.37 2704.86 473.49C2705.48 473.64 2705.4 474.04 2705.19 474.49C2705.05 474.81 2704.99 475.2 2704.51 475.08C2703.9 474.93 2703.94 474.53 2704.18 474.09H2704.19Z" fill="white"/>
                    <path d="M2745.63 329.26C2745.8 328.66 2746.02 328.59 2746.3 328.66C2746.92 328.83 2746.82 329.23 2746.6 329.66C2746.49 329.88 2746.37 330.37 2746.2 330.22C2745.87 329.93 2745.72 329.44 2745.62 329.26H2745.63Z" fill="white"/>
                    <path d="M2842.37 523.67C2842.47 523.78 2842.64 523.95 2842.8 524.13C2842.34 524.31 2841.95 524.77 2841.39 524.47C2841.15 524.34 2841.05 524.14 2841.18 523.83C2841.45 523.18 2841.87 523.55 2842.36 523.67H2842.37Z" fill="white"/>
                    <path d="M2650.76 481.9C2650.72 482.14 2650.47 482.24 2650.21 482.17C2649.86 482.09 2649.52 481.9 2649.76 481.45C2649.93 481.14 2649.83 480.45 2650.42 480.65C2650.63 480.72 2650.96 481.32 2650.76 481.89V481.9Z" fill="white"/>
                    <path d="M2705.66 324.15C2706.27 324.3 2706.24 324.93 2706.49 325.35C2706.25 325.35 2705.98 325.42 2705.77 325.34C2705.41 325.21 2704.85 325.2 2705.07 324.52C2705.17 324.21 2705.37 324.14 2705.66 324.15Z" fill="white"/>
                    <path d="M2680.17 489.86C2680.12 490.23 2680.27 489.86 2679.93 489.95C2679.75 490 2679.38 489.85 2679.3 489.69C2679.09 489.26 2678.66 488.82 2679.06 488.28C2679.1 488.22 2679.25 488.15 2679.28 488.17C2679.84 488.59 2679.84 489.32 2680.17 489.85V489.86Z" fill="white"/>
                    <path d="M2728.24 474.39C2728.41 474.86 2728.86 475.31 2728.43 475.82C2728.33 475.94 2727.91 475.93 2727.73 475.82C2727.18 475.48 2727.65 475.07 2727.73 474.69C2727.79 474.43 2727.92 474.26 2728.23 474.38L2728.24 474.39Z" fill="white"/>
                    <path d="M2800.06 425.75C2800.23 425.81 2800.42 425.84 2800.55 425.95C2800.63 426.02 2800.68 426.27 2800.63 426.33C2800.43 426.55 2798.87 425.97 2798.8 425.65C2798.78 425.58 2799 425.36 2799.07 425.38C2799.41 425.47 2799.74 425.62 2800.07 425.74L2800.06 425.75Z" fill="white"/>
                    <path d="M2743.82 561.44C2744.35 561.61 2744.31 561.94 2744.14 562.28C2744.01 562.52 2743.81 562.62 2743.5 562.49C2743.03 562.31 2743.07 561.99 2743.24 561.67C2743.36 561.44 2743.58 561.26 2743.82 561.45V561.44Z" fill="white"/>
                    <path d="M2723.27 322.75C2723.5 322.93 2723.61 323.1 2723.49 323.41C2723.31 323.88 2723 323.84 2722.67 323.67C2722.43 323.55 2722.34 323.34 2722.46 323.03C2722.64 322.56 2722.94 322.63 2723.28 322.75H2723.27Z" fill="white"/>
                    <path d="M2896.74 419.77C2896.97 419.95 2897.08 420.12 2896.96 420.43C2896.78 420.9 2896.47 420.86 2896.14 420.69C2895.9 420.57 2895.81 420.36 2895.93 420.05C2896.11 419.58 2896.41 419.65 2896.75 419.77H2896.74Z" fill="white"/>
                    <path d="M2870.24 519.17C2870.11 519.48 2869.97 519.75 2869.49 519.58C2869.18 519.46 2869.08 519.26 2869.15 518.99C2869.24 518.63 2869.43 518.37 2869.89 518.55C2870.2 518.67 2870.27 518.86 2870.23 519.17H2870.24Z" fill="white"/>
                    <path d="M2819.13 319.17C2819.48 318.82 2819.88 318.58 2820.39 318.7C2820.53 318.73 2820.74 318.99 2820.73 319C2820.42 319.4 2820.02 319.63 2819.5 319.5C2819.36 319.46 2819.26 319.3 2819.13 319.17Z" fill="white"/>
                    <path d="M2890.28 384.82C2890.6 384.87 2890.67 385.13 2890.6 385.39C2890.51 385.75 2890.32 386 2889.86 385.83C2889.55 385.71 2889.45 385.51 2889.52 385.24C2889.61 384.88 2889.81 384.6 2890.28 384.82Z" fill="white"/>
                    <path d="M2783.86 369.1C2784.09 369.28 2784.2 369.45 2784.08 369.76C2783.9 370.23 2783.59 370.19 2783.25 370.02C2783.01 369.9 2782.92 369.69 2783.04 369.38C2783.22 368.91 2783.52 368.98 2783.86 369.1Z" fill="white"/>
                    <path d="M2692.65 530.28C2692.92 530.49 2693.03 530.66 2692.91 530.97C2692.73 531.44 2692.42 531.4 2692.09 531.23C2691.85 531.11 2691.76 530.9 2691.88 530.59C2692.06 530.11 2692.37 530.22 2692.66 530.28H2692.65Z" fill="white"/>
                    <path d="M2696.28 508.45C2696.13 508.94 2695.79 508.91 2695.46 508.73C2695.22 508.6 2695.12 508.4 2695.25 508.09C2695.43 507.62 2695.74 507.66 2696.07 507.83C2696.31 507.95 2696.45 508.18 2696.28 508.45Z" fill="white"/>
                    <path d="M2854.64 306.84C2854.32 306.7 2853.98 306.56 2854.21 306.11C2854.28 305.97 2854.41 305.86 2854.52 305.74C2854.72 306.07 2854.92 306.4 2855.13 306.73C2854.97 306.77 2854.81 306.8 2854.65 306.84H2854.64Z" fill="white"/>
                    <path d="M2854.86 355.63C2855.18 355.77 2855.52 355.91 2855.29 356.36C2855.22 356.5 2855.09 356.61 2854.98 356.73C2854.78 356.4 2854.58 356.07 2854.37 355.74C2854.53 355.7 2854.69 355.67 2854.85 355.63H2854.86Z" fill="white"/>
                    <path d="M2730.66 341.92C2730.82 342.25 2730.96 342.56 2731.11 342.87C2730.87 342.87 2730.59 342.95 2730.4 342.87C2729.97 342.67 2730.13 342.3 2730.31 342.01C2730.36 341.93 2730.56 341.95 2730.67 341.92H2730.66Z" fill="white"/>
                    <path d="M2834.94 455.26C2834.8 455.42 2834.65 455.66 2834.59 455.64C2834.14 455.49 2834.21 454.98 2833.99 454.67C2834.15 454.64 2834.32 454.54 2834.47 454.58C2834.83 454.67 2835.09 454.87 2834.94 455.27V455.26Z" fill="white"/>
                    <path d="M2902.98 506.91C2903.06 507.02 2903.22 507.15 2903.2 507.24C2903.13 507.58 2903.01 507.95 2902.55 507.81C2902.34 507.74 2902.19 507.5 2902.02 507.34C2902.34 507.2 2902.65 507.06 2902.98 506.91Z" fill="white"/>
                    <path d="M2888.88 484.02C2889.03 483.56 2889.37 483.63 2889.69 483.79C2889.82 483.86 2889.89 484.04 2889.98 484.18C2889.61 484.29 2889.24 484.4 2888.87 484.51C2888.87 484.35 2888.87 484.19 2888.87 484.02H2888.88Z" fill="white"/>
                    <path d="M2746.03 357.67C2746.07 357.73 2746.24 357.85 2746.23 357.94C2746.16 358.27 2746.04 358.66 2745.59 358.53C2745.38 358.47 2745.23 358.23 2745.05 358.06C2745.37 357.93 2745.69 357.81 2746.03 357.67Z" fill="white"/>
                    <path d="M2778.18 371.57C2778.64 371.72 2778.57 372.06 2778.41 372.38C2778.34 372.51 2778.16 372.58 2778.02 372.67C2777.91 372.3 2777.8 371.93 2777.69 371.56C2777.85 371.56 2778.01 371.56 2778.18 371.56V371.57Z" fill="white"/>
                    <path d="M2913.55 447.07C2913.65 447.2 2913.75 447.33 2913.85 447.46C2913.49 447.59 2913.12 447.72 2912.76 447.84C2912.76 447.68 2912.72 447.51 2912.76 447.36C2912.88 446.89 2913.21 446.94 2913.55 447.07Z" fill="white"/>
                    <path d="M2713.59 508.69C2713.74 508.23 2714.08 508.3 2714.4 508.46C2714.53 508.53 2714.6 508.71 2714.69 508.85C2714.32 508.96 2713.95 509.07 2713.58 509.18C2713.58 509.02 2713.58 508.86 2713.58 508.69H2713.59Z" fill="white"/>
                    <path d="M2702.32 544.15C2702.54 544.11 2702.72 544.03 2702.86 544.06C2703.22 544.16 2703.48 544.37 2703.26 544.79C2703.19 544.93 2703.05 545.03 2702.94 545.14C2702.74 544.81 2702.54 544.49 2702.32 544.14V544.15Z" fill="white"/>
                    <path d="M2675.31 345.7C2675.54 345.98 2675.33 346.21 2675.23 346.43C2675.2 346.5 2674.91 346.55 2674.85 346.49C2674.61 346.25 2674.83 346 2674.93 345.78C2674.96 345.71 2675.18 345.72 2675.31 345.7Z" fill="white"/>
                    <path d="M2698.55 341.39C2698.85 341.41 2699.1 341.39 2699.32 341.47C2699.45 341.51 2699.52 341.72 2699.61 341.85C2699.37 341.85 2699.11 341.9 2698.89 341.85C2698.77 341.82 2698.69 341.6 2698.55 341.4V341.39Z" fill="white"/>
                    <path d="M2797.65 558.31C2797.88 558.59 2797.67 558.82 2797.57 559.04C2797.54 559.11 2797.25 559.16 2797.19 559.1C2796.95 558.86 2797.17 558.61 2797.27 558.39C2797.3 558.32 2797.52 558.33 2797.65 558.31Z" fill="white"/>
                    <path d="M2885.88 509.5C2885.86 509.8 2885.88 510.05 2885.8 510.27C2885.76 510.4 2885.55 510.47 2885.42 510.56C2885.42 510.32 2885.37 510.06 2885.42 509.84C2885.45 509.72 2885.67 509.64 2885.87 509.5H2885.88Z" fill="white"/>
                    <path d="M2886.58 506.67C2886.52 506.8 2886.45 507.04 2886.31 507.22C2886.14 507.43 2885.96 507.36 2885.99 507.1C2886.01 506.87 2886.11 506.63 2886.24 506.44C2886.4 506.22 2886.55 506.29 2886.59 506.67H2886.58Z" fill="white"/>
                    <path d="M2809.02 570.1C2809.13 569.98 2809.26 569.75 2809.35 569.77C2809.67 569.83 2809.65 570.08 2809.52 570.31C2809.44 570.45 2809.28 570.66 2809.2 570.64C2808.88 570.58 2808.91 570.34 2809.03 570.1H2809.02Z" fill="white"/>
                    <path d="M2726.37 328C2726.49 328.11 2726.72 328.24 2726.7 328.33C2726.64 328.65 2726.39 328.63 2726.16 328.5C2726.02 328.42 2725.81 328.26 2725.83 328.18C2725.89 327.86 2726.13 327.89 2726.37 328.01V328Z" fill="white"/>
                    <path d="M2906.76 440.08C2907.06 440.1 2907.31 440.08 2907.53 440.16C2907.66 440.2 2907.73 440.41 2907.82 440.54C2907.58 440.54 2907.32 440.59 2907.1 440.54C2906.98 440.51 2906.9 440.29 2906.76 440.09V440.08Z" fill="white"/>
                    <path d="M2670.64 462.17C2670.71 462.27 2670.8 462.4 2670.9 462.53C2670.66 462.53 2670.4 462.58 2670.18 462.53C2670.06 462.5 2669.98 462.28 2669.89 462.15C2670.13 462.01 2670.37 461.99 2670.64 462.17Z" fill="white"/>
                    <path d="M2919.58 400.65C2919.51 400.9 2919.37 401.1 2919.09 400.94C2919.02 400.9 2919.04 400.63 2919.07 400.48C2919.12 400.22 2919.28 400.02 2919.55 400.19C2919.62 400.23 2919.57 400.49 2919.57 400.66L2919.58 400.65Z" fill="white"/>
                    <path d="M2704.53 476.24C2704.46 476.49 2704.32 476.69 2704.04 476.53C2703.97 476.49 2703.99 476.22 2704.02 476.07C2704.07 475.81 2704.23 475.61 2704.5 475.78C2704.57 475.82 2704.52 476.08 2704.52 476.25L2704.53 476.24Z" fill="white"/>
                    <path d="M2715.02 471.55C2714.95 471.8 2714.81 472 2714.53 471.84C2714.46 471.8 2714.48 471.53 2714.51 471.38C2714.56 471.12 2714.72 470.92 2714.99 471.09C2715.06 471.13 2715.01 471.39 2715.01 471.56L2715.02 471.55Z" fill="white"/>
                    <path d="M2750.83 448.94C2751.13 448.96 2751.38 448.94 2751.6 449.02C2751.73 449.06 2751.8 449.27 2751.89 449.4C2751.65 449.4 2751.39 449.45 2751.17 449.4C2751.05 449.37 2750.97 449.15 2750.83 448.95V448.94Z" fill="white"/>
                    <path d="M2739.55 350.97C2739.69 351.12 2739.85 351.3 2740.01 351.48C2739.85 351.52 2739.65 351.63 2739.54 351.57C2739.33 351.46 2739.18 351.26 2739 351.09C2739.16 351.05 2739.32 351.02 2739.56 350.97H2739.55Z" fill="white"/>
                    <path d="M2809.06 401.96C2809.13 402.06 2809.22 402.19 2809.32 402.32C2809.08 402.32 2808.82 402.37 2808.6 402.32C2808.48 402.29 2808.4 402.07 2808.31 401.94C2808.55 401.8 2808.79 401.78 2809.06 401.96Z" fill="white"/>
                    <path d="M2830.23 365.6C2830.53 365.62 2830.78 365.6 2831 365.68C2831.13 365.72 2831.2 365.93 2831.29 366.06C2831.05 366.06 2830.79 366.11 2830.57 366.06C2830.45 366.03 2830.37 365.81 2830.23 365.61V365.6Z" fill="white"/>
                    <path d="M2862.98 308.36C2863.21 308.64 2863 308.87 2862.9 309.09C2862.87 309.16 2862.58 309.21 2862.52 309.15C2862.28 308.91 2862.5 308.66 2862.6 308.44C2862.63 308.37 2862.85 308.38 2862.98 308.36Z" fill="white"/>
                    <path d="M2868.55 367.23C2868.48 367.48 2868.34 367.68 2868.06 367.52C2867.99 367.48 2868.01 367.21 2868.04 367.06C2868.09 366.8 2868.25 366.6 2868.52 366.77C2868.59 366.81 2868.54 367.07 2868.54 367.24L2868.55 367.23Z" fill="white"/>
                    <path d="M2793.09 541.98C2793.2 541.86 2793.33 541.63 2793.42 541.65C2793.74 541.71 2793.72 541.96 2793.59 542.19C2793.51 542.33 2793.35 542.54 2793.27 542.52C2792.95 542.46 2792.98 542.22 2793.1 541.98H2793.09Z" fill="white"/>
                    <path d="M2811.07 455.48C2811.19 455.59 2811.42 455.72 2811.4 455.81C2811.34 456.13 2811.09 456.11 2810.86 455.98C2810.72 455.9 2810.51 455.74 2810.53 455.66C2810.59 455.34 2810.83 455.37 2811.07 455.49V455.48Z" fill="white"/>
                    <path d="M2824.51 459.74C2824.64 459.73 2824.78 459.69 2824.91 459.71C2824.96 459.71 2824.98 459.85 2825.02 459.93C2824.89 459.94 2824.75 459.98 2824.62 459.96C2824.57 459.96 2824.55 459.82 2824.51 459.74Z" fill="white"/>
                    <path d="M2923.12 447.54C2923.25 447.53 2923.39 447.49 2923.52 447.51C2923.57 447.51 2923.59 447.65 2923.63 447.73C2923.5 447.74 2923.36 447.78 2923.23 447.76C2923.18 447.76 2923.16 447.62 2923.12 447.54Z" fill="white"/>
                    <path d="M2721.57 462.55C2721.65 462.65 2721.74 462.75 2721.83 462.85C2721.75 462.88 2721.64 462.97 2721.6 462.94C2721.49 462.87 2721.41 462.75 2721.32 462.65C2721.4 462.62 2721.48 462.58 2721.57 462.54V462.55Z" fill="white"/>
                    <path d="M2695.48 495.91C2695.44 495.82 2695.4 495.74 2695.37 495.66C2695.5 495.65 2695.64 495.61 2695.77 495.63C2695.82 495.63 2695.84 495.77 2695.88 495.85C2695.75 495.87 2695.61 495.89 2695.49 495.91H2695.48Z" fill="white"/>
                    <path d="M2722.35 421.79C2722.25 421.87 2722.15 421.96 2722.05 422.05C2722.02 421.97 2721.93 421.86 2721.96 421.82C2722.03 421.71 2722.15 421.63 2722.25 421.54C2722.28 421.62 2722.32 421.7 2722.36 421.79H2722.35Z" fill="white"/>
                    <path d="M2761.33 405.74C2761.34 405.87 2761.38 406.01 2761.36 406.14C2761.36 406.19 2761.22 406.21 2761.14 406.25C2761.13 406.12 2761.09 405.98 2761.11 405.85C2761.11 405.8 2761.25 405.78 2761.33 405.74Z" fill="white"/>
                    <path d="M2781.29 366.18C2781.2 366.22 2781.12 366.26 2781.04 366.29C2781.03 366.16 2780.99 366.02 2781.01 365.89C2781.01 365.84 2781.15 365.82 2781.23 365.78C2781.25 365.91 2781.27 366.05 2781.29 366.17V366.18Z" fill="white"/>
                    <path d="M2739.24 331.26C2739.32 331.36 2739.41 331.46 2739.5 331.56C2739.42 331.59 2739.31 331.68 2739.27 331.65C2739.16 331.58 2739.08 331.46 2738.99 331.36C2739.07 331.33 2739.15 331.29 2739.24 331.25V331.26Z" fill="white"/>
                    <path d="M2714.38 437.76C2714.42 437.85 2714.46 437.93 2714.49 438.01C2714.36 438.02 2714.22 438.06 2714.09 438.04C2714.04 438.04 2714.02 437.9 2713.98 437.82C2714.11 437.8 2714.25 437.78 2714.37 437.76H2714.38Z" fill="white"/>
                    <path d="M2781.33 426.05C2781.29 425.96 2781.25 425.88 2781.22 425.8C2781.35 425.79 2781.49 425.75 2781.62 425.77C2781.67 425.77 2781.69 425.91 2781.73 425.99C2781.6 426.01 2781.46 426.03 2781.34 426.05H2781.33Z" fill="white"/>
                    <path d="M2837.99 307.67C2838.07 307.63 2838.19 307.56 2838.21 307.58C2838.3 307.68 2838.41 307.8 2838.42 307.92C2838.44 308.17 2838.3 308.24 2838.13 308.05C2838.05 307.96 2838.04 307.8 2838 307.67H2837.99Z" fill="white"/>
                    <path d="M2838.57 308.23C2838.61 308.36 2838.72 308.55 2838.68 308.61C2838.54 308.78 2838.38 308.76 2838.35 308.59C2838.32 308.45 2838.35 308.29 2838.35 308.14C2838.43 308.17 2838.5 308.2 2838.58 308.23H2838.57Z" fill="white"/>
                    <path d="M2828.2 487.05C2828.28 487.15 2828.37 487.25 2828.46 487.35C2828.38 487.38 2828.27 487.47 2828.23 487.44C2828.12 487.37 2828.04 487.25 2827.95 487.15C2828.03 487.12 2828.11 487.08 2828.2 487.04V487.05Z" fill="white"/>
                    <path d="M2764.61 280.06C2764.69 280.16 2764.78 280.26 2764.87 280.36C2764.79 280.39 2764.68 280.48 2764.64 280.45C2764.53 280.38 2764.45 280.26 2764.36 280.16C2764.44 280.13 2764.52 280.09 2764.61 280.05V280.06Z" fill="white"/>
                    <path d="M2844.31 304.13C2844.35 304.22 2844.39 304.3 2844.42 304.38C2844.29 304.39 2844.15 304.43 2844.02 304.41C2843.97 304.41 2843.95 304.27 2843.91 304.19C2844.04 304.17 2844.18 304.15 2844.3 304.13H2844.31Z" fill="white"/>
                    <path d="M2742.79 313.31C2742.71 313.21 2742.62 313.11 2742.53 313.01C2742.61 312.98 2742.72 312.89 2742.76 312.92C2742.87 312.99 2742.95 313.11 2743.04 313.21C2742.96 313.24 2742.88 313.28 2742.79 313.32V313.31Z" fill="white"/>
                    <path d="M2844.34 399.26C2844.42 399.36 2844.51 399.46 2844.6 399.56C2844.52 399.59 2844.41 399.68 2844.37 399.65C2844.26 399.58 2844.18 399.46 2844.09 399.36C2844.17 399.33 2844.25 399.29 2844.34 399.25V399.26Z" fill="white"/>
                    <path d="M2718.73 393.31C2718.64 393.35 2718.56 393.39 2718.48 393.42C2718.47 393.29 2718.43 393.15 2718.45 393.02C2718.45 392.97 2718.59 392.95 2718.67 392.91C2718.69 393.04 2718.71 393.18 2718.73 393.3V393.31Z" fill="white"/>
                    <path d="M2844.61 335.77C2844.74 335.76 2844.88 335.72 2845.01 335.74C2845.06 335.74 2845.08 335.88 2845.12 335.96C2844.99 335.97 2844.85 336.01 2844.72 335.99C2844.67 335.99 2844.65 335.85 2844.61 335.77Z" fill="white"/>
                    <path d="M2720.61 381.56C2720.69 381.66 2720.78 381.76 2720.87 381.86C2720.79 381.89 2720.68 381.98 2720.64 381.95C2720.53 381.88 2720.45 381.76 2720.36 381.66L2720.61 381.55V381.56Z" fill="white"/>
                    <path d="M2804.53 446.97C2804.63 446.89 2804.73 446.8 2804.83 446.71C2804.86 446.79 2804.95 446.9 2804.92 446.94C2804.85 447.05 2804.73 447.13 2804.63 447.22C2804.6 447.14 2804.56 447.06 2804.52 446.97H2804.53Z" fill="white"/>
                    <path d="M2816.71 572.76C2816.84 572.75 2816.98 572.71 2817.11 572.73C2817.16 572.73 2817.18 572.87 2817.22 572.95C2817.09 572.96 2816.95 573 2816.82 572.98C2816.77 572.98 2816.75 572.84 2816.71 572.76Z" fill="white"/>
                    <path d="M2939.01 313.66C2940.91 313.6 2941.59 314.92 2942.2 316.18C2942.42 316.64 2942.66 317.41 2942.87 317.6C2944.17 318.85 2942.17 319.6 2942.55 320.63C2941.49 320.84 2940.3 321.45 2939.42 321.15C2938.58 320.86 2937.56 320.41 2936.86 319.71C2936.2 319.05 2934.97 318.41 2936.32 317.22C2936.52 317.04 2936.35 316.47 2936.4 316.09C2936.6 314.39 2937.52 313.59 2939.02 313.66H2939.01Z" fill="white"/>
                    <path d="M2834.51 426.67C2834.58 425.55 2835.51 424.79 2835.91 424.82C2837.44 424.93 2838.12 423.56 2839.35 423.18C2840.22 422.91 2840.79 422.88 2841.34 423.57C2842.25 424.7 2842.33 425.36 2841.66 426.18C2841.37 426.53 2841.13 426.91 2840.9 427.3C2839.88 429 2835.89 429.51 2834.82 427.5C2834.63 427.14 2834.46 426.85 2834.52 426.67H2834.51Z" fill="white"/>
                    <path d="M2925.69 415.38C2926.45 414.74 2926.3 413.3 2927.67 413.26C2927.86 413.26 2928.09 413.34 2928.25 413.27C2928.91 412.98 2929.53 412.42 2930.32 412.89C2930.94 413.26 2931.01 413.97 2931.27 414.55C2931.78 415.7 2931.12 417.35 2929.92 418.04C2928.99 418.58 2928.13 418.51 2927.2 417.93C2926.23 417.34 2926.16 416.26 2925.69 415.38Z" fill="white"/>
                    <path d="M2983.08 539.41C2983 541.1 2982.34 541.84 2981.12 541.92C2980.45 541.97 2979.76 541.85 2979.08 541.86C2978.44 541.86 2978.25 541.58 2978.32 540.98C2978.39 540.3 2978.29 539.6 2978.44 538.95C2978.69 537.84 2979.49 537.14 2980.51 536.72C2980.72 536.63 2981.13 536.69 2981.27 536.85C2981.78 537.42 2982.43 537.76 2983.01 538.21C2983.29 538.43 2983.08 539.29 2983.08 539.42V539.41Z" fill="white"/>
                    <path d="M2856.11 552.3C2856.08 551.53 2856.01 550.44 2857.22 550.18C2857.79 550.05 2858.85 549.82 2859.04 550.52C2859.24 551.29 2860.09 551.98 2859.57 552.88C2859.21 553.51 2858.6 554.04 2858.4 554.7C2858.22 555.31 2858.01 555.43 2857.44 555.41C2856.62 555.39 2856.2 554.89 2856.06 554.24C2855.94 553.69 2856.08 553.08 2856.11 552.29V552.3Z" fill="white"/>
                    <path d="M3004.03 542.04C3004.05 541.27 3004.68 540.64 3005.44 540.63C3005.69 540.63 3008.04 542.09 3008.12 542.31C3008.31 542.8 3007.28 544.25 3007.23 544.17C3006.6 543.12 3005.95 544.47 3005.37 544.1C3004.81 543.75 3004.02 542.53 3004.03 542.04Z" fill="white"/>
                    <path d="M2971.96 371.83C2971.56 371.8 2970.93 371.88 2970.94 371.49C2970.96 370.78 2970.67 369.98 2971.37 369.33C2971.76 368.97 2972.28 369.06 2972.6 368.77C2973.12 368.3 2973.49 368.23 2974.04 368.77C2974.63 369.35 2974.7 370.06 2974.29 370.55C2973.72 371.24 2973.1 372.14 2971.96 371.83Z" fill="white"/>
                    <path d="M2893.82 436.26C2893.76 436.87 2894.03 437.68 2892.87 437.58C2892.61 437.56 2892.32 438.07 2892.02 438.32C2891.68 438.6 2891.32 438.84 2890.97 439.1C2890.93 438.45 2890.66 437.68 2890.92 437.18C2891.25 436.54 2892.02 436.12 2892.56 435.58C2892.86 435.28 2893.19 435.06 2893.56 435.24C2893.97 435.44 2893.83 435.9 2893.82 436.27V436.26Z" fill="white"/>
                    <path d="M3049.63 433.06C3049.63 433.25 3049.63 433.44 3049.6 433.63C3049.52 434.05 3049.81 434.66 3049.15 434.79C3048.47 434.92 3047.89 434.69 3047.45 434.07C3047.08 433.55 3047.32 433.26 3047.67 432.88C3047.9 432.63 3047.91 432.2 3048.14 431.95C3048.43 431.62 3048.73 431.14 3049.29 431.31C3049.96 431.51 3049.62 432.08 3049.66 432.5C3049.68 432.69 3049.65 432.88 3049.64 433.07L3049.63 433.06Z" fill="white"/>
                    <path d="M2997.29 550.23C2998.05 550.7 2998.98 550.96 2999.48 551.79C2999.59 551.98 2999.59 552.35 2999.3 552.56C2998.93 552.83 2998.65 553.42 2998.12 553.17C2997.48 552.87 2996.89 552.42 2996.32 551.97C2995.65 551.44 2996.67 551.33 2996.63 550.92C2996.59 550.5 2996.75 550.18 2997.28 550.23H2997.29Z" fill="white"/>
                    <path d="M2892.92 388.73C2892.98 389.26 2892.8 389.57 2892.15 389.51C2891.15 389.42 2890.61 388.86 2890.61 387.97C2890.61 387.17 2891.12 386.76 2891.74 386.59C2892.45 386.4 2893.02 387.45 2892.93 388.73H2892.92Z" fill="white"/>
                    <path d="M2822.56 336.76C2822.55 337.05 2822.59 337.35 2822.51 337.63C2822.34 338.27 2821.54 338.45 2821.1 337.96C2820.84 337.68 2820.51 337.43 2820.33 337.1C2820.1 336.68 2820.09 336.09 2820.38 335.77C2820.72 335.39 2821.44 335.55 2821.79 335.05C2822.1 335.5 2822.76 335.75 2822.58 336.48C2822.56 336.57 2822.57 336.67 2822.57 336.77L2822.56 336.76Z" fill="white"/>
                    <path d="M2991.92 496.46C2992.17 496.23 2992.4 496.05 2992.59 495.84C2993.09 495.31 2993.91 496.02 2994.4 495.33C2994.66 494.97 2995.1 494.99 2995.36 495.34C2995.61 495.68 2995.48 496.03 2995.15 496.31C2994.71 496.68 2994.32 497.11 2993.87 497.46C2993.66 497.63 2993.5 498.22 2993.05 497.69C2992.69 497.27 2992.3 496.87 2991.92 496.46Z" fill="white"/>
                    <path d="M2916.98 590.71C2916.37 590.59 2915.84 589.61 2916.01 588.92C2916.08 588.65 2917.62 587.73 2917.95 587.83C2918.77 588.1 2918.34 588.79 2918.31 589.26C2918.26 590.15 2917.56 590.81 2916.98 590.7V590.71Z" fill="white"/>
                    <path d="M2876.44 520.53C2877.04 520.47 2877.39 520.61 2877.33 521.27C2877.26 522.08 2876.66 522.86 2876.08 522.84C2875.5 522.81 2874.95 521.97 2874.99 521.16C2875.05 519.95 2875.96 520.68 2876.44 520.53Z" fill="white"/>
                    <path d="M2870.51 516.61C2870.81 516.17 2871.11 515.66 2871.72 515.65C2872.39 515.63 2873.01 516.81 2872.81 517.58C2872.68 518.09 2872.3 518.04 2871.93 517.98C2871.08 517.86 2870.4 517.21 2870.51 516.61Z" fill="white"/>
                    <path d="M2959.71 510.83C2959.69 511.21 2959.67 511.4 2959.68 511.59C2959.74 512.3 2959.08 512.48 2958.74 512.9C2958.44 512.45 2957.8 512.22 2957.92 511.52C2957.95 511.33 2957.95 511.14 2957.95 510.95C2957.92 510.31 2958.02 509.9 2958.87 509.95C2959.68 510 2959.85 510.33 2959.71 510.83Z" fill="white"/>
                    <path d="M2793.86 485.99C2794.54 485.98 2794.66 486.43 2794.5 486.76C2794.18 487.41 2793.73 488 2793.29 488.58C2792.88 489.12 2792.71 488.53 2792.48 488.34C2791.48 487.53 2792.77 487.08 2792.81 486.41C2792.86 485.64 2793.57 486.12 2793.86 485.99Z" fill="white"/>
                    <path d="M2925.93 520.68C2925.81 519.88 2926.33 519.44 2926.95 519.26C2927.41 519.12 2928.47 520.33 2928.26 520.67C2927.86 521.33 2927.25 521.59 2926.45 521.49C2925.89 521.42 2925.92 521.07 2925.93 520.68Z" fill="white"/>
                    <path d="M3023.57 372.27C3024.13 372.39 3024.43 372.86 3024.65 373.36C3024.78 373.66 3023.45 374.55 3022.85 374.58C3022.31 374.61 3022.27 374.23 3022.3 373.84C3022.36 373.04 3022.97 372.26 3023.57 372.27Z" fill="white"/>
                    <path d="M2959.29 544.52C2959.01 544.42 2958.74 544.2 2958.91 543.91C2959.32 543.21 2959.56 542.39 2960.23 541.83C2960.51 541.6 2960.83 541.59 2961.02 541.7C2961.2 541.81 2961.36 542.26 2961.27 542.42C2960.92 543.06 2960.48 543.65 2960.06 544.24C2959.88 544.49 2959.61 544.57 2959.29 544.51V544.52Z" fill="white"/>
                    <path d="M2971.07 573.91C2971.06 573.6 2971.03 573.21 2971.54 573.13C2971.98 573.06 2972.17 573.43 2972.46 573.65C2973.04 574.1 2972.83 574.7 2972.73 575.21C2972.67 575.51 2972.14 575.99 2971.97 575.94C2971.6 575.83 2971.23 575.46 2971.06 575.1C2970.92 574.8 2971.06 574.36 2971.08 573.92L2971.07 573.91Z" fill="white"/>
                    <path d="M2816.71 533.2C2816.23 533.26 2815.92 533.06 2815.99 532.42C2816.07 531.61 2816.66 530.86 2817.24 530.87C2818.21 530.89 2817.5 532.08 2818.23 532.34C2818.35 532.38 2817.46 533.18 2816.71 533.2Z" fill="white"/>
                    <path d="M2981.77 459.86C2981.88 459.73 2981.98 459.5 2982.11 459.47C2982.86 459.32 2984.13 460.43 2984.04 461.15C2984.01 461.4 2983.89 461.75 2983.71 461.83C2983.52 461.91 2983.16 461.79 2982.97 461.63C2982.14 460.95 2981.75 460.34 2981.76 459.85L2981.77 459.86Z" fill="white"/>
                    <path d="M2944.11 357.77C2943.82 357.76 2943.53 357.79 2943.25 357.72C2942.55 357.54 2942.9 356.97 2942.86 356.55C2942.82 356 2943.2 355.97 2943.6 355.99C2943.97 356.01 2944.34 355.96 2944.62 356.31C2944.91 356.68 2945.37 356.99 2945.12 357.52C2944.92 357.93 2944.46 357.76 2944.1 357.76L2944.11 357.77Z" fill="white"/>
                    <path d="M2957.82 541.61C2957.68 542.2 2957.28 542.13 2956.91 542.07C2956.04 541.94 2955.39 541.3 2955.51 540.69C2955.61 540.19 2956.03 540.25 2956.4 540.3C2957.26 540.43 2957.9 541.07 2957.82 541.61Z" fill="white"/>
                    <path d="M2815.92 541.37C2815.48 540.86 2814.95 540.48 2815.07 539.67C2815.17 539 2815.5 538.99 2816.02 539C2816.58 539 2816.82 539.14 2816.88 539.78C2816.95 540.62 2816.31 540.88 2815.92 541.37Z" fill="white"/>
                    <path d="M2812.71 527.03C2812.81 526.89 2812.91 526.63 2813.02 526.62C2813.39 526.58 2813.87 526.49 2814.13 526.68C2814.55 526.98 2815.11 527.32 2815 527.99C2814.92 528.51 2814.5 528.45 2814.13 528.4C2813.25 528.27 2812.64 527.67 2812.71 527.03Z" fill="white"/>
                    <path d="M2982.44 347.86C2982.47 347.46 2982.51 347.04 2983.04 347.15C2983.73 347.3 2984.25 347.76 2984.13 348.48C2984.07 348.86 2983.56 349.16 2983.25 349.49C2982.85 349.01 2982.21 348.64 2982.44 347.86Z" fill="white"/>
                    <path d="M2832.66 384.04C2833.35 384.12 2834.04 384.95 2833.94 385.43C2833.83 385.93 2833.43 385.83 2833.06 385.83C2832.32 385.84 2831.86 385.42 2831.78 384.79C2831.76 384.6 2832.28 384.02 2832.65 384.04H2832.66Z" fill="white"/>
                    <path d="M2906.28 528.52C2906.3 528.03 2906.35 527.52 2906.84 527.7C2907.45 527.91 2907.98 528.35 2908.54 528.69C2908.08 529.17 2907.64 529.58 2906.85 529.45C2906.11 529.33 2906.31 528.85 2906.28 528.52Z" fill="white"/>
                    <path d="M2788.22 442.72C2788.17 441.92 2788.55 441.89 2789.11 441.87C2789.97 441.84 2789.99 442.29 2789.97 442.94C2789.95 443.61 2789.62 443.66 2789.06 443.66C2788.23 443.66 2788.06 443.29 2788.23 442.72H2788.22Z" fill="white"/>
                    <path d="M2916.46 535.67C2916.96 535.78 2917.8 535.43 2917.62 536.4C2917.52 536.93 2917.07 537.38 2916.46 537.42C2915.45 537.49 2915.78 536.42 2915.54 535.98C2915.23 535.42 2916.16 535.86 2916.46 535.67Z" fill="white"/>
                    <path d="M2985.74 586.72C2985.75 586.44 2985.72 586.14 2985.79 585.87C2985.9 585.37 2986.32 585.45 2986.68 585.49C2986.94 585.52 2987.28 585.39 2987.44 585.72C2987.62 586.09 2987.55 586.42 2987.21 586.69C2987.06 586.81 2986.93 586.95 2986.79 587.07C2986.51 587.31 2986.16 587.78 2985.97 587.72C2985.55 587.59 2985.76 587.09 2985.74 586.73V586.72Z" fill="white"/>
                    <path d="M2867.86 341.07C2867.2 340.84 2867.5 340.29 2867.5 339.88C2867.5 339.1 2868.11 339.36 2868.53 339.35C2868.92 339.35 2869.31 339.4 2869.24 339.95C2869.16 340.51 2868.35 341.16 2867.86 341.07Z" fill="white"/>
                    <path d="M2855.38 494.45C2855.88 494.49 2856.28 494.52 2856.21 495.07C2856.13 495.63 2855.32 496.3 2854.83 496.18C2854.33 496.06 2854.47 495.64 2854.44 495.28C2854.35 494.4 2854.88 494.38 2855.39 494.45H2855.38Z" fill="white"/>
                    <path d="M2945.48 562.11C2945.29 562.05 2944.89 562.03 2944.82 561.87C2944.64 561.44 2945.14 561.26 2945.38 561.14C2945.73 560.95 2945.88 559.89 2946.58 560.76C2946.81 561.05 2947.15 561.29 2947.01 561.71C2946.73 562.56 2946.04 561.91 2945.49 562.11H2945.48Z" fill="white"/>
                    <path d="M3029.56 437.37C3029.84 437.01 3030.14 436.23 3030.59 436.6C3030.98 436.92 3031.76 436.68 3031.79 437.37C3031.81 437.78 3030.37 438.3 3029.86 438.07C3029.59 437.95 3029.49 437.69 3029.56 437.36V437.37Z" fill="white"/>
                    <path d="M2806.32 441.45C2806.32 441.45 2806.05 441 2805.88 440.57C2805.81 440.38 2806.94 439.67 2807.2 439.74C2807.9 439.92 2807.56 440.48 2807.59 440.89C2807.63 441.39 2807.3 441.5 2806.32 441.45Z" fill="white"/>
                    <path d="M2812.52 407.61C2812.74 406.95 2813.28 407.27 2813.7 407.24C2814.22 407.2 2814.4 407.61 2814.24 407.95C2814.06 408.34 2813.68 408.67 2813.33 408.96C2813.2 409.06 2812.46 407.86 2812.52 407.61Z" fill="white"/>
                    <path d="M2959.44 307.54C2959.95 307.46 2960.14 307.7 2960.13 307.98C2960.09 308.73 2959.6 309.1 2958.91 309.24C2958.55 309.31 2958.36 309.06 2958.37 308.78C2958.38 308.01 2958.92 307.69 2959.44 307.55V307.54Z" fill="white"/>
                    <path d="M3000.02 472.53C2999.41 472.41 2998.97 472 2998.99 471.5C2999 471.26 2999.47 470.72 3000.04 470.76C3000.81 470.81 3000.55 471.38 3000.57 471.79C3000.58 472.18 3000.51 472.54 3000.02 472.52V472.53Z" fill="white"/>
                    <path d="M2864.59 322.2C2864.86 322.17 2865.95 323.01 2865.83 323.13C2865.52 323.45 2865.16 323.79 2864.75 323.93C2864.37 324.06 2864.03 323.81 2864.11 323.32C2864.18 322.91 2863.89 322.33 2864.6 322.2H2864.59Z" fill="white"/>
                    <path d="M3001.5 478.29C3001.69 477.78 3002.05 477.27 3002.82 477.32C3003.1 477.34 3003.34 477.55 3003.24 477.9C3003.05 478.58 3002.63 479.03 3001.88 479.01C3001.6 479.01 3001.38 478.79 3001.5 478.29Z" fill="white"/>
                    <path d="M3064.54 495.18C3064.54 494.64 3064.92 494.47 3065.26 494.63C3065.65 494.81 3065.98 495.19 3066.27 495.54C3066.37 495.67 3065.17 496.41 3064.92 496.35C3064.56 496.26 3064.42 495.81 3064.54 495.19V495.18Z" fill="white"/>
                    <path d="M2914.95 531.12C2914.98 530.71 2915 530.31 2915.54 530.38C2916.13 530.45 2916.56 530.89 2916.54 531.39C2916.53 531.64 2916.06 532.17 2915.49 532.13C2914.72 532.08 2915 531.51 2914.95 531.12Z" fill="white"/>
                    <path d="M3002.77 559.63C3003.04 559.16 3003.35 558.75 3003.94 558.71C3004.29 558.68 3004.5 558.91 3004.46 559.19C3004.38 559.65 3004.8 560.41 3003.85 560.46C3003.27 560.49 3003.17 559.88 3002.77 559.63Z" fill="white"/>
                    <path d="M2924.71 521.84C2924.82 521.7 2924.93 521.57 2925.04 521.43C2925.62 522.04 2926.11 522.75 2927 522.98C2927.04 522.99 2927.04 523.37 2926.96 523.53C2926.92 523.62 2926.6 523.71 2926.57 523.68C2926.11 523.06 2925.12 523.15 2924.79 522.37C2924.72 522.21 2924.73 522.01 2924.7 521.83L2924.71 521.84Z" fill="white"/>
                    <path d="M2929.19 521.02C2929.76 521.08 2929.42 521.81 2929.35 522.25C2929.23 523.13 2928.49 522.63 2928.01 522.68C2927.87 522.7 2927.36 522.36 2927.85 522.23C2928.57 522.03 2927.78 520.81 2929.19 521.02Z" fill="white"/>
                    <path d="M2926.76 340.06C2926.06 340.14 2925.89 339.47 2925.48 339.15C2925.74 338.9 2926.01 338.42 2926.25 338.44C2926.78 338.48 2927.11 338.92 2927.19 339.48C2927.24 339.82 2927.03 339.96 2926.75 340.06H2926.76Z" fill="white"/>
                    <path d="M2811.04 446.33C2811.44 446.41 2812.04 446.19 2812.02 446.97C2812.02 447.53 2811.64 447.55 2811.25 447.51C2810.83 447.47 2810.24 447.66 2810.25 446.88C2810.25 446.31 2810.63 446.33 2811.03 446.33H2811.04Z" fill="white"/>
                    <path d="M3004.31 344.81C3004.87 344.03 3005.47 343.88 3006.16 344.03C3006.32 344.06 3006.45 344.23 3006.59 344.33C3006.45 344.46 3006.28 344.56 3006.18 344.72C3005.79 345.3 3005.2 345.18 3004.66 345.11C3004.49 345.09 3004.35 344.85 3004.31 344.81Z" fill="white"/>
                    <path d="M2781.85 440.02C2781.85 439.88 2782.21 439.82 2782.61 439.87C2783.03 439.92 2783.62 439.7 2783.62 440.49C2783.62 441.04 2783.26 441.08 2782.86 441.04C2782.44 441 2781.85 441.19 2781.85 440.02Z" fill="white"/>
                    <path d="M2835.19 471.44C2835.3 471.51 2835.52 471.59 2835.53 471.68C2835.57 472.33 2835.58 472.97 2834.9 473.4C2834.62 473.58 2834.44 473.64 2834.32 473.33C2834.13 472.85 2834.74 471.54 2835.2 471.43L2835.19 471.44Z" fill="white"/>
                    <path d="M2890.11 372.31C2890.38 371.42 2891.08 372.03 2891.56 371.96C2891.78 371.93 2891.93 372.37 2891.83 372.65C2891.55 373.48 2890.87 372.99 2890.38 373.01C2890.15 373.01 2890.02 372.62 2890.11 372.31Z" fill="white"/>
                    <path d="M2900.53 485.12C2900.8 484.23 2901.5 484.84 2901.98 484.77C2902.2 484.74 2902.35 485.18 2902.25 485.46C2901.97 486.29 2901.29 485.8 2900.8 485.82C2900.57 485.82 2900.44 485.43 2900.53 485.12Z" fill="white"/>
                    <path d="M3012.78 418.24C3012.78 418.1 3013.14 418.04 3013.54 418.09C3013.96 418.14 3014.55 417.92 3014.55 418.71C3014.55 419.26 3014.19 419.3 3013.79 419.26C3013.37 419.22 3012.78 419.41 3012.78 418.24Z" fill="white"/>
                    <path d="M2885.79 560.63C2886.06 561.18 2886.34 561.74 2886.6 562.31C2886.61 562.34 2886.48 562.48 2886.4 562.49C2886 562.56 2885.7 562.4 2885.44 562.08C2885.14 561.72 2884.78 561.39 2885.11 560.9C2885.19 560.78 2885.42 560.77 2885.79 560.64V560.63Z" fill="white"/>
                    <path d="M2936.93 544.15C2936.54 544.16 2936.12 544.2 2936.08 543.67C2936.02 542.99 2936.46 542.93 2936.99 542.98C2937.36 543.02 2937.79 542.94 2937.83 543.48C2937.88 544.16 2937.46 544.25 2936.93 544.15Z" fill="white"/>
                    <path d="M2987.61 513.63C2987.79 513.12 2987.93 512.89 2988.28 512.98C2988.96 513.17 2989.4 513.59 2989.39 514.34C2989.39 514.49 2989.2 514.63 2989.09 514.77C2988.57 514.37 2988.06 513.98 2987.6 513.63H2987.61Z" fill="white"/>
                    <path d="M2872.51 497.87C2872.46 497.35 2872.59 496.96 2872.83 496.98C2873.32 497.04 2874.04 496.61 2874.25 497.46C2874.32 497.75 2874.18 498.14 2873.91 498.13C2873.41 498.12 2872.76 498.37 2872.51 497.86V497.87Z" fill="white"/>
                    <path d="M3005.37 400.34C3005.4 399.94 3005.42 399.57 3005.99 399.61C3006.77 399.67 3006.53 400.23 3006.53 400.65C3006.53 401.04 3006.49 401.41 3005.93 401.37C3005.15 401.32 3005.42 400.75 3005.38 400.34H3005.37Z" fill="white"/>
                    <path d="M2917.03 396.41C2916.14 396.14 2916.75 395.44 2916.68 394.96C2916.65 394.74 2917.09 394.59 2917.37 394.69C2918.2 394.97 2917.71 395.65 2917.73 396.14C2917.73 396.37 2917.34 396.5 2917.03 396.41Z" fill="white"/>
                    <path d="M2989.28 524.14C2988.89 524.15 2988.47 524.19 2988.43 523.66C2988.37 522.98 2988.81 522.92 2989.34 522.97C2989.71 523.01 2990.14 522.93 2990.18 523.47C2990.23 524.15 2989.81 524.24 2989.28 524.14Z" fill="white"/>
                    <path d="M2826.86 531.25C2826.19 531.29 2826.04 531.08 2826.02 530.77C2825.98 530.08 2826.43 530.04 2826.94 530.12C2827.2 530.16 2827.74 530.12 2827.65 530.33C2827.47 530.76 2827.02 531.08 2826.86 531.25Z" fill="white"/>
                    <path d="M2992.78 365.74C2992.85 365.6 2992.98 365.37 2993.1 365.14C2993.44 365.55 2994.05 365.79 2993.93 366.46C2993.88 366.75 2993.7 366.92 2993.34 366.89C2992.58 366.83 2992.82 366.28 2992.77 365.74H2992.78Z" fill="white"/>
                    <path d="M3015.54 576.16C3015.8 576.12 3015.98 576.34 3016.01 576.63C3016.04 577.01 3015.97 577.43 3015.43 577.33C3015.05 577.26 3014.38 577.6 3014.39 576.93C3014.39 576.69 3014.89 576.15 3015.54 576.16Z" fill="white"/>
                    <path d="M2835.29 573.92C2835.24 573.24 2835.89 573.06 2836.24 572.66C2836.32 572.91 2836.48 573.16 2836.48 573.4C2836.47 573.81 2836.65 574.39 2835.88 574.4C2835.53 574.4 2835.39 574.22 2835.3 573.92H2835.29Z" fill="white"/>
                    <path d="M3013.63 543.34C3014.02 543.27 3013.61 543.24 3013.81 543.56C3013.92 543.73 3013.9 544.15 3013.76 544.29C3013.39 544.66 3013.09 545.24 3012.4 545.02C3012.32 545 3012.2 544.87 3012.22 544.83C3012.46 544.11 3013.2 543.87 3013.64 543.34H3013.63Z" fill="white"/>
                    <path d="M2981.36 499.42C2981.78 499.09 2982.09 498.47 2982.76 498.73C2982.92 498.79 2983.05 499.23 2983 499.44C2982.84 500.12 2982.26 499.78 2981.84 499.82C2981.56 499.85 2981.33 499.77 2981.36 499.42Z" fill="white"/>
                    <path d="M2907.02 442.54C2907.02 442.35 2906.99 442.14 2907.06 441.97C2907.11 441.86 2907.35 441.72 2907.43 441.76C2907.72 441.89 2907.67 443.69 2907.36 443.86C2907.29 443.9 2906.99 443.75 2906.99 443.68C2906.97 443.3 2907 442.92 2907.02 442.54Z" fill="white"/>
                    <path d="M3065.14 453.7C3065.13 453.1 3065.49 453.02 3065.89 453.09C3066.18 453.14 3066.35 453.32 3066.33 453.68C3066.3 454.22 3065.97 454.29 3065.58 454.23C3065.3 454.18 3065.04 454.03 3065.15 453.71L3065.14 453.7Z" fill="white"/>
                    <path d="M2827.85 556.37C2827.95 556.07 2828.09 555.9 2828.45 555.92C2828.99 555.95 2829.06 556.28 2829 556.68C2828.96 556.97 2828.77 557.14 2828.41 557.12C2827.87 557.09 2827.84 556.76 2827.85 556.38V556.37Z" fill="white"/>
                    <path d="M2867.82 345.63C2867.92 345.33 2868.06 345.16 2868.42 345.18C2868.96 345.21 2869.03 345.54 2868.97 345.94C2868.93 346.23 2868.74 346.4 2868.38 346.38C2867.84 346.35 2867.81 346.02 2867.82 345.64V345.63Z" fill="white"/>
                    <path d="M2978.63 338.75C2978.99 338.77 2979.32 338.83 2979.3 339.37C2979.29 339.73 2979.11 339.9 2978.81 339.92C2978.41 339.95 2978.08 339.85 2978.1 339.31C2978.11 338.95 2978.29 338.82 2978.62 338.75H2978.63Z" fill="white"/>
                    <path d="M2791.39 459.47C2790.91 459.23 2790.53 458.91 2790.48 458.34C2790.47 458.19 2790.66 457.88 2790.67 457.89C2791.19 458.07 2791.56 458.4 2791.6 458.98C2791.61 459.13 2791.47 459.29 2791.39 459.48V459.47Z" fill="white"/>
                    <path d="M2834.26 364.19C2834.21 363.85 2834.44 363.68 2834.74 363.66C2835.14 363.63 2835.46 363.73 2835.45 364.27C2835.44 364.63 2835.26 364.8 2834.96 364.82C2834.56 364.85 2834.21 364.74 2834.27 364.19H2834.26Z" fill="white"/>
                    <path d="M2854.57 478.5C2854.67 478.2 2854.81 478.03 2855.17 478.05C2855.71 478.08 2855.78 478.41 2855.72 478.81C2855.68 479.1 2855.49 479.27 2855.13 479.25C2854.59 479.22 2854.56 478.89 2854.57 478.51V478.5Z" fill="white"/>
                    <path d="M3050.74 516.74C3050.86 516.39 3051 516.22 3051.36 516.24C3051.9 516.27 3051.97 516.6 3051.91 517C3051.87 517.29 3051.68 517.45 3051.32 517.44C3050.76 517.42 3050.77 517.06 3050.74 516.75V516.74Z" fill="white"/>
                    <path d="M3027.16 520.48C3027.72 520.47 3027.8 520.83 3027.73 521.23C3027.68 521.52 3027.5 521.69 3027.14 521.67C3026.6 521.64 3026.53 521.31 3026.59 520.91C3026.63 520.62 3026.82 520.4 3027.15 520.48H3027.16Z" fill="white"/>
                    <path d="M2766.62 427.34C2766.59 427.72 2766.56 428.11 2766.02 428.03C2765.85 428.01 2765.7 427.91 2765.54 427.84C2765.81 427.52 2766.08 427.2 2766.34 426.88C2766.43 427.03 2766.52 427.18 2766.62 427.34Z" fill="white"/>
                    <path d="M2816.49 410.42C2816.52 410.04 2816.55 409.65 2817.09 409.73C2817.26 409.75 2817.41 409.85 2817.57 409.92C2817.3 410.24 2817.03 410.56 2816.77 410.88C2816.68 410.73 2816.59 410.58 2816.49 410.42Z" fill="white"/>
                    <path d="M2844.93 542.24C2845.22 541.97 2845.48 541.71 2845.75 541.45C2845.84 541.7 2846.01 541.95 2845.99 542.18C2845.93 542.69 2845.5 542.65 2845.14 542.57C2845.04 542.55 2844.99 542.33 2844.93 542.24Z" fill="white"/>
                    <path d="M2925.29 396.75C2925.5 396.84 2925.8 396.91 2925.8 396.97C2925.8 397.48 2925.26 397.58 2925.01 397.92C2924.92 397.77 2924.77 397.62 2924.75 397.46C2924.72 397.06 2924.83 396.73 2925.29 396.74V396.75Z" fill="white"/>
                    <path d="M2954.88 309.43C2954.97 309.31 2955.05 309.11 2955.15 309.09C2955.52 309.05 2955.95 309.04 2955.96 309.56C2955.96 309.79 2955.76 310.03 2955.66 310.27C2955.41 309.99 2955.15 309.72 2954.89 309.43H2954.88Z" fill="white"/>
                    <path d="M2936.28 331.69C2935.75 331.69 2935.71 331.32 2935.77 330.94C2935.79 330.79 2935.96 330.65 2936.07 330.51C2936.31 330.85 2936.55 331.19 2936.79 331.53C2936.62 331.58 2936.46 331.63 2936.29 331.69H2936.28Z" fill="white"/>
                    <path d="M2855.8 521.13C2855.84 521.07 2855.91 520.85 2856.01 520.84C2856.37 520.8 2856.81 520.79 2856.83 521.29C2856.84 521.52 2856.64 521.76 2856.54 522C2856.3 521.72 2856.06 521.44 2855.8 521.13Z" fill="white"/>
                    <path d="M2859.04 483.46C2859.04 482.93 2859.41 482.89 2859.79 482.95C2859.94 482.97 2860.08 483.14 2860.22 483.25C2859.88 483.49 2859.54 483.73 2859.2 483.97C2859.15 483.8 2859.1 483.64 2859.04 483.47V483.46Z" fill="white"/>
                    <path d="M2890.02 319.08C2890.12 318.93 2890.22 318.78 2890.31 318.64C2890.56 318.97 2890.82 319.29 2891.07 319.62C2890.91 319.68 2890.75 319.78 2890.58 319.79C2890.06 319.83 2890 319.48 2890.01 319.08H2890.02Z" fill="white"/>
                    <path d="M3021.48 502.69C3020.95 502.69 3020.91 502.32 3020.97 501.94C3020.99 501.79 3021.16 501.65 3021.27 501.51C3021.51 501.85 3021.75 502.19 3021.99 502.53C3021.82 502.58 3021.66 502.63 3021.49 502.69H3021.48Z" fill="white"/>
                    <path d="M3061.63 502.09C3061.51 501.88 3061.37 501.73 3061.36 501.57C3061.33 501.16 3061.46 500.83 3061.97 500.91C3062.13 500.94 3062.28 501.05 3062.44 501.12C3062.17 501.44 3061.91 501.76 3061.63 502.09Z" fill="white"/>
                    <path d="M2867.73 597.62C2867.94 597.29 2868.25 597.43 2868.51 597.45C2868.59 597.45 2868.74 597.74 2868.7 597.82C2868.53 598.15 2868.2 598.01 2867.95 597.98C2867.86 597.98 2867.8 597.74 2867.74 597.62H2867.73Z" fill="white"/>
                    <path d="M2855.38 575.3C2855.3 574.99 2855.19 574.73 2855.19 574.48C2855.19 574.34 2855.38 574.19 2855.48 574.05C2855.57 574.3 2855.7 574.54 2855.72 574.79C2855.72 574.93 2855.53 575.08 2855.37 575.3H2855.38Z" fill="white"/>
                    <path d="M3043.53 399.67C3043.74 399.34 3044.05 399.48 3044.31 399.5C3044.39 399.5 3044.54 399.79 3044.5 399.87C3044.33 400.2 3044 400.06 3043.75 400.03C3043.66 400.03 3043.6 399.79 3043.54 399.67H3043.53Z" fill="white"/>
                    <path d="M2963.39 326.06C2963.7 325.98 2963.96 325.87 2964.21 325.87C2964.35 325.87 2964.5 326.06 2964.64 326.16C2964.39 326.25 2964.15 326.38 2963.9 326.4C2963.76 326.4 2963.61 326.21 2963.39 326.05V326.06Z" fill="white"/>
                    <path d="M2960.25 326.31C2960.4 326.33 2960.67 326.31 2960.91 326.4C2961.19 326.5 2961.18 326.71 2960.89 326.77C2960.65 326.82 2960.36 326.81 2960.13 326.74C2959.85 326.65 2959.87 326.48 2960.25 326.31Z" fill="white"/>
                    <path d="M3051.71 384C3051.55 383.93 3051.27 383.87 3051.26 383.78C3051.22 383.44 3051.48 383.37 3051.75 383.43C3051.92 383.47 3052.19 383.55 3052.2 383.65C3052.24 383.99 3051.99 384.05 3051.7 384.01L3051.71 384Z" fill="white"/>
                    <path d="M2832.16 551.4C2832.23 551.24 2832.29 550.96 2832.38 550.95C2832.72 550.91 2832.79 551.17 2832.73 551.44C2832.69 551.61 2832.61 551.88 2832.51 551.89C2832.17 551.93 2832.11 551.68 2832.15 551.39L2832.16 551.4Z" fill="white"/>
                    <path d="M2885.19 328.43C2885.11 328.12 2885 327.86 2885 327.61C2885 327.47 2885.19 327.32 2885.29 327.18C2885.38 327.43 2885.51 327.67 2885.53 327.92C2885.53 328.06 2885.34 328.21 2885.18 328.43H2885.19Z" fill="white"/>
                    <path d="M2988.55 562.56C2988.62 562.46 2988.73 562.31 2988.83 562.17C2988.92 562.42 2989.05 562.66 2989.07 562.91C2989.07 563.05 2988.88 563.2 2988.78 563.34C2988.55 563.14 2988.45 562.9 2988.55 562.56Z" fill="white"/>
                    <path d="M2840.44 328.78C2840.73 328.77 2840.98 328.84 2840.9 329.18C2840.88 329.27 2840.6 329.33 2840.43 329.36C2840.15 329.4 2839.89 329.31 2839.97 328.97C2839.99 328.88 2840.27 328.85 2840.44 328.79V328.78Z" fill="white"/>
                    <path d="M2991.37 523.06C2991.66 523.05 2991.91 523.12 2991.83 523.46C2991.81 523.55 2991.53 523.61 2991.36 523.64C2991.08 523.68 2990.82 523.59 2990.9 523.25C2990.92 523.16 2991.2 523.13 2991.37 523.07V523.06Z" fill="white"/>
                    <path d="M2982.98 513.93C2983.27 513.92 2983.52 513.99 2983.44 514.33C2983.42 514.42 2983.14 514.48 2982.97 514.51C2982.69 514.55 2982.43 514.46 2982.51 514.12C2982.53 514.03 2982.81 514 2982.98 513.94V513.93Z" fill="white"/>
                    <path d="M2947.59 485C2947.51 484.69 2947.4 484.43 2947.4 484.18C2947.4 484.04 2947.59 483.89 2947.69 483.75C2947.78 484 2947.91 484.24 2947.93 484.49C2947.93 484.63 2947.74 484.78 2947.58 485H2947.59Z" fill="white"/>
                    <path d="M2851.16 530.05C2851.27 529.86 2851.4 529.63 2851.53 529.41C2851.62 529.56 2851.8 529.73 2851.78 529.86C2851.74 530.11 2851.58 530.34 2851.48 530.58C2851.39 530.43 2851.3 530.28 2851.16 530.05Z" fill="white"/>
                    <path d="M2879.58 441.46C2879.65 441.36 2879.76 441.21 2879.86 441.07C2879.95 441.32 2880.08 441.56 2880.1 441.81C2880.1 441.95 2879.91 442.1 2879.81 442.24C2879.58 442.04 2879.48 441.8 2879.58 441.46Z" fill="white"/>
                    <path d="M2835.12 432.23C2835.04 431.92 2834.93 431.66 2834.93 431.41C2834.93 431.27 2835.12 431.12 2835.22 430.98C2835.31 431.23 2835.44 431.47 2835.46 431.72C2835.46 431.86 2835.27 432.01 2835.11 432.23H2835.12Z" fill="white"/>
                    <path d="M2765.33 418.28C2765.54 417.95 2765.85 418.09 2766.11 418.11C2766.19 418.11 2766.34 418.4 2766.3 418.48C2766.13 418.81 2765.8 418.67 2765.55 418.64C2765.46 418.64 2765.4 418.4 2765.34 418.28H2765.33Z" fill="white"/>
                    <path d="M2823.69 392.45C2823.98 392.44 2824.23 392.51 2824.15 392.85C2824.13 392.94 2823.85 393 2823.68 393.03C2823.4 393.07 2823.14 392.98 2823.22 392.64C2823.24 392.55 2823.52 392.52 2823.69 392.46V392.45Z" fill="white"/>
                    <path d="M3028.36 409.93C3028.2 409.86 3027.92 409.8 3027.91 409.71C3027.87 409.37 3028.13 409.3 3028.4 409.36C3028.57 409.4 3028.84 409.48 3028.85 409.58C3028.89 409.92 3028.64 409.98 3028.35 409.94L3028.36 409.93Z" fill="white"/>
                    <path d="M2933.68 421.11C2933.75 420.95 2933.81 420.67 2933.9 420.66C2934.24 420.62 2934.31 420.88 2934.25 421.15C2934.21 421.32 2934.13 421.59 2934.03 421.6C2933.69 421.64 2933.63 421.39 2933.67 421.1L2933.68 421.11Z" fill="white"/>
                    <path d="M2933.45 405.89C2933.39 405.76 2933.31 405.63 2933.28 405.49C2933.28 405.44 2933.4 405.37 2933.47 405.31C2933.53 405.44 2933.61 405.57 2933.64 405.71C2933.64 405.76 2933.52 405.83 2933.45 405.89Z" fill="white"/>
                    <path d="M2887.23 309.12C2887.17 308.99 2887.09 308.86 2887.06 308.72C2887.06 308.67 2887.18 308.6 2887.25 308.54C2887.31 308.67 2887.39 308.8 2887.42 308.94C2887.42 308.99 2887.3 309.06 2887.23 309.12Z" fill="white"/>
                    <path d="M2971.52 510.3C2971.59 510.18 2971.67 510.06 2971.74 509.93C2971.8 510 2971.93 510.08 2971.91 510.13C2971.87 510.27 2971.78 510.39 2971.71 510.51C2971.65 510.44 2971.59 510.37 2971.51 510.29L2971.52 510.3Z" fill="white"/>
                    <path d="M3014.59 525.6C3014.51 525.67 3014.44 525.74 3014.37 525.8C3014.31 525.67 3014.23 525.54 3014.2 525.4C3014.2 525.35 3014.32 525.28 3014.39 525.22C3014.45 525.35 3014.52 525.48 3014.58 525.61L3014.59 525.6Z" fill="white"/>
                    <path d="M2929.53 523.44C2929.65 523.51 2929.77 523.59 2929.9 523.66C2929.83 523.72 2929.75 523.85 2929.7 523.83C2929.56 523.79 2929.44 523.7 2929.32 523.63C2929.39 523.57 2929.46 523.51 2929.54 523.43L2929.53 523.44Z" fill="white"/>
                    <path d="M2899.77 489.03C2899.9 488.97 2900.03 488.89 2900.17 488.86C2900.22 488.86 2900.29 488.98 2900.35 489.05C2900.22 489.11 2900.09 489.19 2899.95 489.22C2899.9 489.22 2899.83 489.1 2899.77 489.03Z" fill="white"/>
                    <path d="M2852.45 482.13C2852.52 482.21 2852.59 482.28 2852.65 482.35C2852.52 482.41 2852.39 482.49 2852.25 482.52C2852.2 482.52 2852.13 482.4 2852.07 482.33C2852.2 482.27 2852.33 482.2 2852.46 482.14L2852.45 482.13Z" fill="white"/>
                    <path d="M2831.09 537.11C2831.16 536.99 2831.24 536.87 2831.31 536.74C2831.37 536.81 2831.5 536.89 2831.48 536.94C2831.44 537.08 2831.35 537.2 2831.28 537.32C2831.22 537.25 2831.16 537.18 2831.08 537.1L2831.09 537.11Z" fill="white"/>
                    <path d="M2948.61 526.13C2948.69 526.06 2948.76 525.99 2948.83 525.93C2948.89 526.06 2948.97 526.19 2949 526.33C2949 526.38 2948.88 526.45 2948.81 526.51C2948.75 526.38 2948.68 526.25 2948.62 526.12L2948.61 526.13Z" fill="white"/>
                    <path d="M2913.73 461.61C2913.65 461.68 2913.58 461.75 2913.51 461.81C2913.45 461.68 2913.37 461.55 2913.34 461.41C2913.34 461.36 2913.46 461.29 2913.53 461.23C2913.59 461.36 2913.66 461.49 2913.72 461.62L2913.73 461.61Z" fill="white"/>
                    <path d="M2773.17 444.09C2773.11 444.02 2772.99 443.92 2773 443.9C2773.07 443.78 2773.16 443.62 2773.28 443.57C2773.53 443.46 2773.64 443.59 2773.52 443.83C2773.46 443.95 2773.29 444.01 2773.18 444.1L2773.17 444.09Z" fill="white"/>
                    <path d="M2773.55 443.31C2773.67 443.22 2773.82 443.04 2773.9 443.07C2774.12 443.15 2774.15 443.33 2774 443.42C2773.86 443.5 2773.7 443.52 2773.54 443.57C2773.54 443.48 2773.54 443.4 2773.55 443.31Z" fill="white"/>
                    <path d="M2960.13 392.77C2960.2 392.65 2960.28 392.53 2960.35 392.4C2960.41 392.47 2960.54 392.55 2960.52 392.6C2960.48 392.74 2960.39 392.86 2960.32 392.98C2960.26 392.91 2960.2 392.84 2960.12 392.76L2960.13 392.77Z" fill="white"/>
                    <path d="M2770 528.66C2770.07 528.54 2770.15 528.42 2770.22 528.29C2770.28 528.36 2770.41 528.44 2770.39 528.49C2770.35 528.63 2770.26 528.75 2770.19 528.87C2770.13 528.8 2770.07 528.73 2769.99 528.65L2770 528.66Z" fill="white"/>
                    <path d="M2767.39 438.84C2767.47 438.77 2767.54 438.7 2767.61 438.64C2767.67 438.77 2767.75 438.9 2767.78 439.04C2767.78 439.09 2767.66 439.16 2767.59 439.22C2767.53 439.09 2767.46 438.96 2767.4 438.83L2767.39 438.84Z" fill="white"/>
                    <path d="M2811.51 539.62C2811.44 539.74 2811.36 539.86 2811.29 539.99C2811.23 539.92 2811.1 539.84 2811.12 539.79C2811.16 539.65 2811.25 539.53 2811.32 539.41C2811.38 539.48 2811.44 539.55 2811.52 539.63L2811.51 539.62Z" fill="white"/>
                    <path d="M2864.75 406.27C2864.82 406.15 2864.9 406.03 2864.97 405.9C2865.03 405.97 2865.16 406.05 2865.14 406.1C2865.1 406.24 2865.01 406.36 2864.94 406.48C2864.88 406.41 2864.82 406.34 2864.74 406.26L2864.75 406.27Z" fill="white"/>
                    <path d="M2901.62 536.89C2901.69 536.97 2901.76 537.04 2901.82 537.11C2901.69 537.17 2901.56 537.25 2901.42 537.28C2901.37 537.28 2901.3 537.16 2901.24 537.09C2901.37 537.03 2901.5 536.96 2901.63 536.9L2901.62 536.89Z" fill="white"/>
                    <path d="M2799.67 427.71C2799.61 427.58 2799.53 427.45 2799.5 427.31C2799.5 427.26 2799.62 427.19 2799.69 427.13C2799.75 427.26 2799.83 427.39 2799.86 427.53C2799.86 427.58 2799.74 427.65 2799.67 427.71Z" fill="white"/>
                    <path d="M2888.95 538.98C2889.02 538.86 2889.1 538.74 2889.17 538.61C2889.23 538.68 2889.36 538.76 2889.34 538.81C2889.3 538.95 2889.21 539.07 2889.14 539.19L2888.94 538.97L2888.95 538.98Z" fill="white"/>
                    <path d="M2927.21 430.71C2927.09 430.64 2926.97 430.56 2926.84 430.49C2926.91 430.43 2926.99 430.3 2927.04 430.32C2927.18 430.36 2927.3 430.45 2927.42 430.52C2927.35 430.58 2927.28 430.64 2927.2 430.72L2927.21 430.71Z" fill="white"/>
                    <path d="M3051.8 375.23C3051.74 375.1 3051.66 374.97 3051.63 374.83C3051.63 374.78 3051.75 374.71 3051.82 374.65C3051.88 374.78 3051.96 374.91 3051.99 375.05C3051.99 375.1 3051.87 375.17 3051.8 375.23Z" fill="white"/>
                    <path d="M2947.34 603C2945.44 602.92 2944.86 601.55 2944.35 600.24C2944.16 599.76 2943.98 598.98 2943.79 598.77C2942.59 597.43 2944.64 596.83 2944.34 595.77C2945.41 595.64 2946.65 595.12 2947.51 595.49C2948.33 595.84 2949.31 596.36 2949.95 597.12C2950.56 597.83 2951.74 598.56 2950.3 599.65C2950.09 599.81 2950.21 600.39 2950.14 600.77C2949.81 602.45 2948.83 603.18 2947.35 603H2947.34Z" fill="white"/>
                    <path d="M3060.13 498.17C3059.98 499.29 3058.99 499.96 3058.6 499.91C3057.09 499.69 3056.3 501 3055.04 501.28C3054.16 501.48 3053.58 501.47 3053.09 500.74C3052.27 499.54 3052.24 498.88 3052.97 498.11C3053.29 497.78 3053.55 497.43 3053.82 497.05C3054.97 495.43 3058.98 495.22 3059.9 497.31C3060.06 497.68 3060.21 497.98 3060.14 498.16L3060.13 498.17Z" fill="white"/>
                    <path d="M2968.31 502.54C2967.51 503.12 2967.54 504.57 2966.17 504.51C2965.98 504.51 2965.76 504.4 2965.6 504.46C2964.91 504.7 2964.26 505.21 2963.51 504.68C2962.92 504.27 2962.9 503.55 2962.69 502.96C2962.27 501.78 2963.05 500.18 2964.3 499.58C2965.27 499.11 2966.12 499.25 2967.01 499.89C2967.94 500.56 2967.92 501.63 2968.32 502.55L2968.31 502.54Z" fill="white"/>
                    <path d="M2920.45 374.47C2920.66 372.79 2921.37 372.1 2922.6 372.11C2923.28 372.11 2923.95 372.29 2924.63 372.33C2925.27 372.37 2925.43 372.67 2925.33 373.27C2925.21 373.94 2925.26 374.65 2925.06 375.29C2924.73 376.38 2923.88 377.02 2922.82 377.36C2922.6 377.43 2922.2 377.34 2922.07 377.18C2921.61 376.57 2920.99 376.18 2920.44 375.7C2920.17 375.46 2920.45 374.61 2920.46 374.49L2920.45 374.47Z" fill="white"/>
                    <path d="M3048.08 371.21C3048.05 371.98 3048.03 373.07 3046.82 373.25C3046.24 373.33 3045.16 373.49 3045.03 372.77C3044.89 371.99 3044.09 371.23 3044.68 370.37C3045.09 369.77 3045.73 369.29 3045.98 368.64C3046.21 368.04 3046.42 367.94 3046.99 368C3047.8 368.08 3048.19 368.61 3048.28 369.28C3048.36 369.84 3048.17 370.43 3048.08 371.22V371.21Z" fill="white"/>
                    <path d="M2899.75 370.25C2899.67 371.02 2899 371.6 2898.24 371.55C2897.99 371.53 2895.76 369.9 2895.69 369.68C2895.54 369.18 2896.67 367.8 2896.72 367.89C2897.27 368.98 2898.02 367.68 2898.57 368.1C2899.1 368.5 2899.8 369.76 2899.75 370.26V370.25Z" fill="white"/>
                    <path d="M2918.86 542.48C2919.26 542.54 2919.89 542.51 2919.85 542.89C2919.77 543.6 2920.01 544.42 2919.26 545.02C2918.85 545.35 2918.33 545.22 2917.99 545.48C2917.43 545.91 2917.06 545.96 2916.55 545.37C2916 544.75 2915.99 544.03 2916.43 543.57C2917.05 542.92 2917.73 542.07 2918.86 542.47V542.48Z" fill="white"/>
                    <path d="M3001.69 484.12C3001.8 483.52 3001.58 482.69 3002.73 482.88C3002.99 482.92 3003.32 482.43 3003.64 482.21C3004 481.96 3004.38 481.74 3004.75 481.51C3004.74 482.16 3004.95 482.95 3004.66 483.43C3004.28 484.05 3003.48 484.4 3002.9 484.91C3002.58 485.19 3002.23 485.38 3001.87 485.17C3001.47 484.94 3001.65 484.49 3001.69 484.13V484.12Z" fill="white"/>
                    <path d="M2846.01 475.52C2846.03 475.33 2846.03 475.14 2846.08 474.95C2846.19 474.54 2845.95 473.9 2846.62 473.83C2847.31 473.75 2847.87 474.03 2848.26 474.67C2848.59 475.22 2848.33 475.49 2847.95 475.84C2847.71 476.07 2847.66 476.5 2847.42 476.74C2847.1 477.05 2846.77 477.5 2846.22 477.29C2845.57 477.04 2845.95 476.5 2845.94 476.08C2845.94 475.89 2845.98 475.7 2846 475.51L2846.01 475.52Z" fill="white"/>
                    <path d="M2907.09 362.59C2906.37 362.06 2905.46 361.73 2905.02 360.87C2904.92 360.67 2904.95 360.31 2905.26 360.12C2905.65 359.88 2905.98 359.31 2906.48 359.6C2907.1 359.95 2907.65 360.45 2908.18 360.94C2908.81 361.52 2907.79 361.55 2907.79 361.96C2907.79 362.39 2907.62 362.69 2907.09 362.59Z" fill="white"/>
                    <path d="M2999 531.6C2998.98 531.07 2999.19 530.77 2999.83 530.88C3000.82 531.05 3001.32 531.64 3001.25 532.54C3001.19 533.33 3000.65 533.71 3000.02 533.83C2999.3 533.97 2998.81 532.88 2999 531.61V531.6Z" fill="white"/>
                    <path d="M3065.26 588.76C3065.29 588.47 3065.28 588.17 3065.37 587.9C3065.59 587.27 3066.39 587.16 3066.8 587.67C3067.04 587.97 3067.35 588.25 3067.5 588.59C3067.7 589.03 3067.66 589.61 3067.35 589.92C3066.99 590.27 3066.28 590.06 3065.89 590.53C3065.61 590.06 3064.97 589.76 3065.21 589.05C3065.24 588.96 3065.24 588.86 3065.25 588.76H3065.26Z" fill="white"/>
                    <path d="M2908.38 416.65C2908.11 416.86 2907.87 417.03 2907.66 417.22C2907.12 417.71 2906.35 416.94 2905.82 417.59C2905.54 417.93 2905.1 417.88 2904.87 417.51C2904.65 417.15 2904.8 416.81 2905.15 416.56C2905.61 416.22 2906.04 415.82 2906.52 415.51C2906.75 415.36 2906.95 414.78 2907.35 415.34C2907.68 415.79 2908.04 416.22 2908.38 416.66V416.65Z" fill="white"/>
                    <path d="M2990.27 328.29C2990.87 328.45 2991.32 329.47 2991.1 330.14C2991.01 330.41 2989.4 331.21 2989.09 331.08C2988.29 330.75 2988.78 330.09 2988.84 329.62C2988.95 328.74 2989.7 328.13 2990.27 328.28V328.29Z" fill="white"/>
                    <path d="M3025.4 401.36C3024.8 401.38 3024.46 401.21 3024.56 400.55C3024.69 399.74 3025.35 399.01 3025.93 399.08C3026.51 399.15 3026.99 400.03 3026.89 400.84C3026.74 402.04 3025.89 401.25 3025.4 401.36Z" fill="white"/>
                    <path d="M3031.02 405.73C3030.69 406.15 3030.35 406.63 3029.74 406.6C3029.07 406.57 3028.54 405.35 3028.8 404.59C3028.97 404.09 3029.34 404.17 3029.71 404.25C3030.55 404.43 3031.17 405.13 3031.02 405.73Z" fill="white"/>
                    <path d="M2941.6 404.75C2941.64 404.37 2941.69 404.18 2941.69 404C2941.69 403.29 2942.36 403.16 2942.72 402.76C2942.98 403.23 2943.61 403.51 2943.44 404.2C2943.39 404.38 2943.38 404.58 2943.37 404.77C2943.35 405.41 2943.22 405.81 2942.37 405.69C2941.56 405.58 2941.42 405.24 2941.6 404.75Z" fill="white"/>
                    <path d="M3105.17 442.07C3104.49 442.03 3104.4 441.57 3104.59 441.26C3104.96 440.64 3105.45 440.08 3105.94 439.54C3106.39 439.03 3106.51 439.64 3106.73 439.84C3107.67 440.73 3106.34 441.08 3106.25 441.74C3106.14 442.5 3105.47 441.97 3105.18 442.08L3105.17 442.07Z" fill="white"/>
                    <path d="M2976.04 397.48C2976.1 398.29 2975.55 398.68 2974.92 398.82C2974.45 398.92 2973.49 397.64 2973.72 397.31C2974.17 396.68 2974.8 396.47 2975.59 396.63C2976.14 396.74 2976.09 397.09 2976.05 397.48H2976.04Z" fill="white"/>
                    <path d="M2867.41 538.14C2866.86 537.97 2866.6 537.49 2866.42 536.97C2866.31 536.66 2867.71 535.87 2868.31 535.89C2868.85 535.91 2868.86 536.28 2868.8 536.67C2868.68 537.46 2868.01 538.19 2867.42 538.14H2867.41Z" fill="white"/>
                    <path d="M2944.57 371.17C2944.84 371.29 2945.1 371.53 2944.91 371.81C2944.45 372.48 2944.15 373.27 2943.44 373.78C2943.14 373.99 2942.82 373.98 2942.64 373.85C2942.47 373.73 2942.34 373.27 2942.44 373.11C2942.83 372.5 2943.32 371.95 2943.78 371.39C2943.98 371.15 2944.25 371.09 2944.57 371.18V371.17Z" fill="white"/>
                    <path d="M2935.03 340.96C2935.02 341.27 2935.02 341.66 2934.5 341.7C2934.05 341.74 2933.9 341.36 2933.63 341.11C2933.08 340.62 2933.34 340.03 2933.47 339.54C2933.55 339.25 2934.11 338.81 2934.29 338.87C2934.65 339.01 2935 339.4 2935.14 339.77C2935.26 340.08 2935.08 340.51 2935.03 340.95V340.96Z" fill="white"/>
                    <path d="M3085.94 393.24C3086.42 393.22 3086.72 393.44 3086.6 394.07C3086.46 394.87 3085.81 395.58 3085.24 395.52C3084.27 395.43 3085.07 394.3 3084.36 393.98C3084.24 393.93 3085.2 393.2 3085.94 393.24Z" fill="white"/>
                    <path d="M2915.73 453.92C2915.61 454.04 2915.5 454.26 2915.36 454.28C2914.6 454.37 2913.42 453.17 2913.56 452.46C2913.61 452.22 2913.75 451.87 2913.94 451.8C2914.13 451.73 2914.49 451.88 2914.66 452.05C2915.44 452.79 2915.78 453.43 2915.73 453.91V453.92Z" fill="white"/>
                    <path d="M2945.59 558.61C2945.87 558.64 2946.17 558.63 2946.44 558.72C2947.12 558.95 2946.74 559.5 2946.74 559.92C2946.74 560.47 2946.35 560.47 2945.96 560.42C2945.6 560.37 2945.22 560.4 2944.97 560.02C2944.71 559.63 2944.27 559.28 2944.56 558.78C2944.79 558.39 2945.24 558.59 2945.6 558.61H2945.59Z" fill="white"/>
                    <path d="M2945.81 374.18C2946 373.61 2946.39 373.71 2946.75 373.79C2947.6 373.98 2948.21 374.67 2948.04 375.27C2947.9 375.77 2947.49 375.67 2947.13 375.59C2946.28 375.4 2945.69 374.71 2945.82 374.18H2945.81Z" fill="white"/>
                    <path d="M3087.35 385.15C3087.75 385.7 3088.25 386.11 3088.07 386.91C3087.92 387.57 3087.59 387.55 3087.07 387.5C3086.51 387.45 3086.28 387.3 3086.27 386.66C3086.27 385.82 3086.92 385.61 3087.35 385.15Z" fill="white"/>
                    <path d="M3089.47 399.7C3089.36 399.84 3089.24 400.08 3089.13 400.09C3088.76 400.1 3088.27 400.15 3088.03 399.95C3087.64 399.62 3087.1 399.24 3087.26 398.57C3087.38 398.06 3087.8 398.15 3088.16 398.23C3089.02 398.43 3089.59 399.08 3089.48 399.7H3089.47Z" fill="white"/>
                    <path d="M2906.6 565.6C2906.54 565.99 2906.47 566.42 2905.95 566.26C2905.27 566.06 2904.79 565.56 2904.96 564.85C2905.05 564.48 2905.58 564.22 2905.91 563.91C2906.28 564.42 2906.89 564.83 2906.6 565.6Z" fill="white"/>
                    <path d="M3058.75 540.84C3058.06 540.71 3057.44 539.83 3057.58 539.36C3057.72 538.87 3058.12 539 3058.48 539.02C3059.22 539.06 3059.65 539.52 3059.68 540.16C3059.68 540.35 3059.13 540.89 3058.75 540.84Z" fill="white"/>
                    <path d="M2996.23 391.14C2996.17 391.62 2996.09 392.13 2995.61 391.92C2995.02 391.66 2994.53 391.18 2993.99 390.8C2994.48 390.35 2994.96 389.98 2995.73 390.17C2996.46 390.35 2996.22 390.81 2996.23 391.14Z" fill="white"/>
                    <path d="M3107.52 485.65C3107.52 486.45 3107.13 486.46 3106.57 486.43C3105.71 486.4 3105.72 485.94 3105.8 485.3C3105.87 484.64 3106.21 484.61 3106.77 484.65C3107.59 484.7 3107.74 485.09 3107.53 485.65H3107.52Z" fill="white"/>
                    <path d="M2986.62 383.23C2986.13 383.08 2985.26 383.36 2985.51 382.41C2985.65 381.89 2986.14 381.48 2986.75 381.48C2987.76 381.49 2987.35 382.53 2987.56 382.99C2987.82 383.57 2986.93 383.07 2986.62 383.23Z" fill="white"/>
                    <path d="M2921.37 327.07C2921.34 327.35 2921.34 327.65 2921.26 327.91C2921.11 328.4 2920.7 328.29 2920.34 328.22C2920.08 328.17 2919.74 328.28 2919.6 327.94C2919.44 327.55 2919.55 327.23 2919.9 326.99C2920.06 326.89 2920.2 326.75 2920.35 326.64C2920.64 326.43 2921.03 325.98 2921.22 326.05C2921.63 326.21 2921.38 326.7 2921.37 327.05V327.07Z" fill="white"/>
                    <path d="M3020.39 581.04C3021.03 581.32 3020.69 581.84 3020.66 582.26C3020.6 583.04 3020.01 582.73 3019.59 582.71C3019.2 582.69 3018.81 582.6 3018.93 582.06C3019.05 581.5 3019.9 580.92 3020.39 581.05V581.04Z" fill="white"/>
                    <path d="M3044.44 428.98C3043.94 428.9 3043.54 428.85 3043.66 428.3C3043.78 427.74 3044.64 427.14 3045.12 427.29C3045.61 427.45 3045.44 427.85 3045.45 428.22C3045.47 429.11 3044.94 429.09 3044.44 428.98Z" fill="white"/>
                    <path d="M2959.67 354.66C2959.85 354.74 2960.26 354.78 2960.31 354.95C2960.46 355.4 2959.95 355.53 2959.7 355.64C2959.33 355.8 2959.11 356.85 2958.48 355.93C2958.27 355.63 2957.96 355.36 2958.12 354.95C2958.46 354.13 2959.1 354.83 2959.67 354.66Z" fill="white"/>
                    <path d="M2866.37 472.75C2866.06 473.09 2865.71 473.84 2865.28 473.44C2864.91 473.09 2864.12 473.27 2864.14 472.58C2864.15 472.17 2865.63 471.76 2866.12 472.02C2866.38 472.16 2866.46 472.43 2866.37 472.75Z" fill="white"/>
                    <path d="M3089.37 485.56C3089.37 485.56 3089.6 486.03 3089.74 486.47C3089.8 486.67 3088.62 487.28 3088.36 487.2C3087.68 486.96 3088.06 486.43 3088.06 486.02C3088.06 485.52 3088.39 485.43 3089.37 485.56Z" fill="white"/>
                    <path d="M3080.62 518.84C3080.35 519.48 3079.84 519.12 3079.42 519.12C3078.89 519.12 3078.75 518.7 3078.94 518.37C3079.15 517.99 3079.55 517.69 3079.92 517.44C3080.05 517.35 3080.7 518.6 3080.62 518.85V518.84Z" fill="white"/>
                    <path d="M2926.49 607.56C2925.98 607.6 2925.8 607.34 2925.84 607.07C2925.93 606.33 2926.45 605.99 2927.15 605.91C2927.51 605.87 2927.68 606.13 2927.66 606.41C2927.59 607.18 2927.03 607.46 2926.49 607.56Z" fill="white"/>
                    <path d="M2898.48 439.91C2899.08 440.07 2899.49 440.52 2899.43 441.01C2899.4 441.25 2898.89 441.75 2898.32 441.67C2897.56 441.56 2897.86 441.02 2897.87 440.6C2897.89 440.21 2897.98 439.86 2898.47 439.91H2898.48Z" fill="white"/>
                    <path d="M3022.23 600.11C3021.96 600.11 3020.94 599.2 3021.06 599.09C3021.4 598.79 3021.78 598.48 3022.2 598.37C3022.59 598.27 3022.91 598.54 3022.8 599.03C3022.7 599.43 3022.95 600.03 3022.23 600.11Z" fill="white"/>
                    <path d="M2897.44 434.04C2897.22 434.53 2896.82 435.02 2896.05 434.9C2895.77 434.86 2895.55 434.63 2895.68 434.29C2895.92 433.63 2896.37 433.2 2897.12 433.29C2897.4 433.32 2897.61 433.55 2897.45 434.04H2897.44Z" fill="white"/>
                    <path d="M2835.84 412.43C2835.81 412.96 2835.41 413.11 2835.08 412.93C2834.7 412.72 2834.4 412.32 2834.15 411.95C2834.06 411.82 2835.31 411.17 2835.56 411.25C2835.91 411.36 2836.02 411.83 2835.85 412.44L2835.84 412.43Z" fill="white"/>
                    <path d="M2987.78 387.89C2987.72 388.29 2987.67 388.69 2987.14 388.58C2986.56 388.46 2986.16 387.99 2986.22 387.5C2986.25 387.26 2986.76 386.76 2987.33 386.84C2988.1 386.95 2987.77 387.5 2987.79 387.89H2987.78Z" fill="white"/>
                    <path d="M2902.34 352.8C2902.03 353.25 2901.7 353.63 2901.1 353.63C2900.75 353.63 2900.55 353.38 2900.62 353.11C2900.74 352.65 2900.38 351.87 2901.32 351.89C2901.9 351.91 2901.95 352.52 2902.33 352.79L2902.34 352.8Z" fill="white"/>
                    <path d="M2977.34 396.41C2977.22 396.54 2977.1 396.66 2976.98 396.79C2976.45 396.14 2976.01 395.39 2975.14 395.09C2975.1 395.08 2975.14 394.7 2975.22 394.54C2975.27 394.45 2975.59 394.38 2975.62 394.42C2976.03 395.07 2977.02 395.06 2977.29 395.86C2977.35 396.03 2977.32 396.22 2977.34 396.41Z" fill="white"/>
                    <path d="M2972.82 396.89C2972.25 396.79 2972.65 396.09 2972.75 395.65C2972.94 394.78 2973.64 395.33 2974.11 395.32C2974.25 395.32 2974.74 395.69 2974.24 395.78C2973.5 395.92 2974.2 397.21 2972.82 396.89Z" fill="white"/>
                    <path d="M2961.55 577.59C2962.26 577.56 2962.37 578.24 2962.76 578.59C2962.48 578.82 2962.17 579.28 2961.93 579.24C2961.4 579.16 2961.11 578.7 2961.07 578.13C2961.05 577.79 2961.27 577.66 2961.55 577.58V577.59Z" fill="white"/>
                    <path d="M3085.03 480.33C3084.63 480.22 3084.03 480.4 3084.1 479.62C3084.15 479.06 3084.52 479.07 3084.91 479.14C3085.33 479.21 3085.92 479.07 3085.86 479.84C3085.81 480.4 3085.44 480.36 3085.04 480.33H3085.03Z" fill="white"/>
                    <path d="M2884.55 566.99C2883.93 567.72 2883.32 567.83 2882.65 567.63C2882.49 567.58 2882.38 567.41 2882.24 567.3C2882.39 567.18 2882.56 567.09 2882.68 566.95C2883.12 566.4 2883.69 566.57 2884.22 566.67C2884.38 566.7 2884.51 566.95 2884.55 566.99Z" fill="white"/>
                    <path d="M3113.67 488.83C3113.67 488.97 3113.29 489 3112.9 488.92C3112.49 488.84 3111.88 489.01 3111.94 488.23C3111.99 487.68 3112.34 487.66 3112.74 487.73C3113.16 487.81 3113.76 487.66 3113.67 488.82V488.83Z" fill="white"/>
                    <path d="M3062.83 453.45C3062.73 453.37 3062.51 453.27 3062.51 453.18C3062.52 452.52 3062.56 451.89 3063.27 451.51C3063.56 451.35 3063.75 451.3 3063.85 451.62C3064.01 452.11 3063.29 453.37 3062.83 453.45Z" fill="white"/>
                    <path d="M3000.55 548.19C3000.21 549.06 2999.57 548.39 2999.07 548.43C2998.85 548.45 2998.74 547.99 2998.85 547.72C2999.19 546.91 2999.83 547.45 3000.32 547.47C3000.55 547.47 3000.65 547.88 3000.54 548.19H3000.55Z" fill="white"/>
                    <path d="M2998.69 434.87C2998.35 435.74 2997.71 435.07 2997.21 435.11C2996.99 435.13 2996.88 434.67 2996.99 434.4C2997.33 433.59 2997.97 434.13 2998.46 434.15C2998.69 434.15 2998.79 434.56 2998.68 434.87H2998.69Z" fill="white"/>
                    <path d="M2881.66 493.1C2881.66 493.24 2881.28 493.27 2880.89 493.19C2880.48 493.11 2879.87 493.28 2879.93 492.5C2879.98 491.95 2880.33 491.93 2880.73 492C2881.15 492.08 2881.75 491.93 2881.66 493.09V493.1Z" fill="white"/>
                    <path d="M3019.1 360.66C3018.87 360.1 3018.64 359.51 3018.42 358.92C3018.41 358.89 3018.55 358.76 3018.63 358.76C3019.03 358.73 3019.32 358.9 3019.56 359.24C3019.83 359.62 3020.17 359.97 3019.8 360.45C3019.71 360.56 3019.48 360.55 3019.1 360.66Z" fill="white"/>
                    <path d="M2966.84 373.23C2967.23 373.25 2967.65 373.24 2967.65 373.78C2967.65 374.47 2967.22 374.49 2966.69 374.39C2966.32 374.33 2965.89 374.37 2965.89 373.83C2965.89 373.15 2966.32 373.09 2966.84 373.23Z" fill="white"/>
                    <path d="M2913.98 399.84C2913.76 400.33 2913.6 400.56 2913.26 400.43C2912.6 400.19 2912.19 399.74 2912.26 398.99C2912.27 398.85 2912.47 398.72 2912.59 398.58C2913.07 399.01 2913.56 399.45 2913.99 399.83L2913.98 399.84Z" fill="white"/>
                    <path d="M3027.61 424.27C3027.62 424.79 3027.46 425.18 3027.23 425.13C3026.75 425.03 3026 425.4 3025.85 424.54C3025.8 424.25 3025.97 423.87 3026.24 423.9C3026.73 423.95 3027.4 423.75 3027.61 424.27Z" fill="white"/>
                    <path d="M2887.69 511.52C2887.63 511.92 2887.58 512.29 2887.02 512.2C2886.25 512.09 2886.53 511.54 2886.56 511.12C2886.58 510.73 2886.66 510.37 2887.22 510.45C2887.99 510.56 2887.68 511.11 2887.69 511.52Z" fill="white"/>
                    <path d="M2975.52 522.12C2976.39 522.46 2975.72 523.1 2975.76 523.6C2975.78 523.82 2975.32 523.93 2975.05 523.82C2974.24 523.48 2974.78 522.84 2974.8 522.35C2974.8 522.12 2975.21 522.02 2975.52 522.13V522.12Z" fill="white"/>
                    <path d="M2913.11 389.23C2913.5 389.25 2913.92 389.24 2913.92 389.78C2913.92 390.47 2913.49 390.49 2912.96 390.39C2912.59 390.33 2912.16 390.37 2912.16 389.83C2912.16 389.15 2912.59 389.09 2913.11 389.23Z" fill="white"/>
                    <path d="M3075.67 394.42C3076.34 394.44 3076.48 394.66 3076.47 394.96C3076.46 395.65 3076.01 395.65 3075.5 395.54C3075.24 395.48 3074.7 395.48 3074.81 395.27C3075.03 394.85 3075.5 394.57 3075.67 394.41V394.42Z" fill="white"/>
                    <path d="M2897.64 546.98C2897.56 547.11 2897.42 547.33 2897.28 547.55C2896.97 547.12 2896.39 546.83 2896.55 546.17C2896.62 545.89 2896.81 545.73 2897.17 545.78C2897.93 545.89 2897.64 546.42 2897.65 546.97L2897.64 546.98Z" fill="white"/>
                    <path d="M2890.84 335.35C2890.58 335.37 2890.41 335.14 2890.41 334.85C2890.41 334.47 2890.51 334.05 2891.04 334.19C2891.41 334.29 2892.11 334 2892.05 334.67C2892.03 334.91 2891.49 335.41 2890.84 335.35Z" fill="white"/>
                    <path d="M3070.49 351.22C3070.49 351.9 3069.83 352.03 3069.45 352.4C3069.38 352.15 3069.25 351.88 3069.27 351.64C3069.31 351.23 3069.18 350.63 3069.95 350.69C3070.3 350.72 3070.42 350.9 3070.49 351.21V351.22Z" fill="white"/>
                    <path d="M2890.26 368.24C2889.86 368.28 2890.28 368.34 2890.1 368.01C2890 367.83 2890.06 367.41 2890.2 367.29C2890.6 366.95 2890.94 366.39 2891.62 366.66C2891.7 366.69 2891.81 366.82 2891.79 366.86C2891.5 367.56 2890.74 367.75 2890.26 368.24Z" fill="white"/>
                    <path d="M2919.14 414.48C2918.69 414.78 2918.34 415.38 2917.69 415.06C2917.54 414.99 2917.43 414.54 2917.5 414.33C2917.71 413.66 2918.26 414.05 2918.68 414.04C2918.97 414.04 2919.18 414.13 2919.13 414.48H2919.14Z" fill="white"/>
                    <path d="M2989 476.86C2988.98 477.05 2989 477.26 2988.92 477.42C2988.87 477.53 2988.61 477.64 2988.54 477.6C2988.26 477.45 2988.45 475.66 2988.77 475.51C2988.84 475.47 2989.13 475.65 2989.13 475.72C2989.13 476.1 2989.06 476.48 2989.01 476.86H2989Z" fill="white"/>
                    <path d="M2832.1 453.76C2832.07 454.36 2831.7 454.41 2831.31 454.32C2831.02 454.25 2830.87 454.06 2830.92 453.7C2830.99 453.16 2831.32 453.11 2831.71 453.21C2831.98 453.28 2832.23 453.45 2832.1 453.76Z" fill="white"/>
                    <path d="M3076.59 369.29C3076.47 369.58 3076.31 369.74 3075.96 369.69C3075.42 369.62 3075.38 369.29 3075.47 368.9C3075.54 368.61 3075.73 368.46 3076.09 368.51C3076.63 368.58 3076.64 368.91 3076.6 369.29H3076.59Z" fill="white"/>
                    <path d="M3020.77 576.49C3020.65 576.78 3020.49 576.94 3020.14 576.89C3019.6 576.82 3019.56 576.49 3019.65 576.1C3019.72 575.81 3019.91 575.66 3020.27 575.71C3020.81 575.78 3020.82 576.11 3020.78 576.49H3020.77Z" fill="white"/>
                    <path d="M2909.71 574.97C2909.35 574.92 2909.03 574.84 2909.09 574.3C2909.13 573.94 2909.32 573.79 2909.62 573.79C2910.02 573.79 2910.34 573.92 2910.28 574.45C2910.24 574.81 2910.06 574.92 2909.72 574.97H2909.71Z" fill="white"/>
                    <path d="M3105.62 468.71C3106.08 468.99 3106.44 469.34 3106.44 469.91C3106.44 470.06 3106.23 470.35 3106.22 470.35C3105.72 470.13 3105.37 469.77 3105.38 469.2C3105.38 469.05 3105.53 468.9 3105.62 468.72V468.71Z" fill="white"/>
                    <path d="M3055.65 560.52C3055.68 560.87 3055.43 561.01 3055.14 561.01C3054.74 561.01 3054.42 560.88 3054.48 560.35C3054.52 559.99 3054.71 559.84 3055.01 559.84C3055.41 559.84 3055.75 559.98 3055.66 560.52H3055.65Z" fill="white"/>
                    <path d="M3044.04 444.95C3043.92 445.24 3043.76 445.4 3043.41 445.35C3042.87 445.28 3042.83 444.95 3042.92 444.56C3042.99 444.27 3043.18 444.12 3043.54 444.17C3044.08 444.24 3044.09 444.57 3044.05 444.95H3044.04Z" fill="white"/>
                    <path d="M2851.24 391.97C2851.1 392.31 2850.94 392.47 2850.59 392.42C2850.05 392.35 2850.01 392.02 2850.1 391.63C2850.17 391.34 2850.36 391.2 2850.72 391.24C2851.27 391.3 2851.24 391.66 2851.25 391.98L2851.24 391.97Z" fill="white"/>
                    <path d="M2875.05 390.01C2874.49 389.98 2874.44 389.62 2874.54 389.22C2874.61 388.93 2874.8 388.78 2875.16 388.83C2875.7 388.9 2875.74 389.23 2875.65 389.62C2875.58 389.91 2875.39 390.11 2875.05 390.01Z" fill="white"/>
                    <path d="M3127.9 502.64C3127.96 502.27 3128.02 501.88 3128.55 501.99C3128.71 502.03 3128.86 502.14 3129.02 502.22C3128.73 502.52 3128.44 502.82 3128.15 503.12C3128.07 502.96 3127.99 502.81 3127.91 502.64H3127.9Z" fill="white"/>
                    <path d="M3076.87 515.74C3076.81 516.11 3076.75 516.5 3076.22 516.39C3076.06 516.35 3075.91 516.24 3075.75 516.16C3076.04 515.86 3076.33 515.56 3076.62 515.26C3076.7 515.42 3076.78 515.57 3076.86 515.74H3076.87Z" fill="white"/>
                    <path d="M3058.47 382.09C3058.16 382.34 3057.88 382.58 3057.59 382.81C3057.52 382.56 3057.36 382.29 3057.41 382.06C3057.51 381.56 3057.93 381.63 3058.29 381.74C3058.39 381.77 3058.42 381.99 3058.47 382.09Z" fill="white"/>
                    <path d="M2967.31 521.15C2967.11 521.05 2966.82 520.95 2966.82 520.89C2966.86 520.38 2967.41 520.32 2967.68 520C2967.76 520.16 2967.9 520.32 2967.9 520.47C2967.9 520.87 2967.76 521.19 2967.31 521.14V521.15Z" fill="white"/>
                    <path d="M2931.18 606.02C2931.08 606.13 2930.99 606.33 2930.89 606.34C2930.52 606.36 2930.09 606.33 2930.12 605.81C2930.13 605.58 2930.35 605.35 2930.48 605.13C2930.71 605.42 2930.94 605.72 2931.18 606.02Z" fill="white"/>
                    <path d="M2951.42 585.22C2951.95 585.26 2951.96 585.63 2951.87 586.01C2951.84 586.16 2951.65 586.28 2951.54 586.42C2951.33 586.06 2951.11 585.7 2950.9 585.34C2951.07 585.3 2951.24 585.26 2951.41 585.22H2951.42Z" fill="white"/>
                    <path d="M3046.04 402.33C3045.99 402.39 3045.91 402.6 3045.81 402.61C3045.44 402.63 3045.01 402.61 3045.03 402.1C3045.03 401.87 3045.25 401.64 3045.38 401.41C3045.6 401.71 3045.81 402.01 3046.05 402.34L3046.04 402.33Z" fill="white"/>
                    <path d="M3039.96 439.66C3039.92 440.19 3039.55 440.2 3039.17 440.11C3039.02 440.08 3038.9 439.89 3038.76 439.78C3039.12 439.57 3039.48 439.35 3039.84 439.14C3039.88 439.31 3039.92 439.48 3039.96 439.65V439.66Z" fill="white"/>
                    <path d="M2996.62 601.3C2996.51 601.44 2996.4 601.58 2996.29 601.72C2996.06 601.37 2995.83 601.03 2995.6 600.68C2995.77 600.63 2995.93 600.55 2996.1 600.55C2996.62 600.55 2996.66 600.91 2996.61 601.3H2996.62Z" fill="white"/>
                    <path d="M2879.37 408.19C2879.9 408.23 2879.91 408.6 2879.82 408.98C2879.79 409.13 2879.6 409.25 2879.49 409.39C2879.28 409.03 2879.06 408.67 2878.85 408.31C2879.02 408.27 2879.19 408.23 2879.36 408.19H2879.37Z" fill="white"/>
                    <path d="M2839.26 405.75C2839.36 405.97 2839.49 406.13 2839.49 406.29C2839.49 406.7 2839.33 407.03 2838.83 406.91C2838.67 406.87 2838.53 406.75 2838.38 406.66C2838.67 406.36 2838.96 406.06 2839.26 405.75Z" fill="white"/>
                    <path d="M3039.92 325.12C3039.68 325.44 3039.39 325.27 3039.13 325.23C3039.05 325.22 3038.92 324.92 3038.97 324.85C3039.16 324.53 3039.48 324.7 3039.73 324.75C3039.82 324.77 3039.86 325 3039.92 325.12Z" fill="white"/>
                    <path d="M3050.56 348.32C3050.61 348.64 3050.71 348.9 3050.68 349.15C3050.67 349.29 3050.47 349.42 3050.36 349.56C3050.29 349.31 3050.17 349.06 3050.18 348.8C3050.18 348.66 3050.39 348.53 3050.56 348.32Z" fill="white"/>
                    <path d="M2849.58 509.29C2849.34 509.61 2849.05 509.44 2848.79 509.4C2848.71 509.39 2848.58 509.09 2848.63 509.02C2848.82 508.7 2849.14 508.87 2849.39 508.92C2849.48 508.94 2849.52 509.17 2849.58 509.29Z" fill="white"/>
                    <path d="M2923.96 588.79C2923.64 588.84 2923.38 588.94 2923.13 588.91C2922.99 588.9 2922.86 588.7 2922.72 588.59C2922.97 588.52 2923.22 588.4 2923.48 588.41C2923.62 588.41 2923.75 588.62 2923.96 588.79Z" fill="white"/>
                    <path d="M2927.1 588.78C2926.95 588.75 2926.68 588.74 2926.45 588.64C2926.18 588.52 2926.21 588.31 2926.49 588.27C2926.74 588.23 2927.02 588.27 2927.25 588.36C2927.52 588.47 2927.49 588.64 2927.1 588.78Z" fill="white"/>
                    <path d="M2840.23 524.31C2840.38 524.39 2840.66 524.47 2840.66 524.57C2840.68 524.92 2840.41 524.97 2840.14 524.89C2839.98 524.84 2839.71 524.73 2839.7 524.64C2839.68 524.29 2839.94 524.26 2840.22 524.32L2840.23 524.31Z" fill="white"/>
                    <path d="M3071.91 373.92C3071.83 374.07 3071.75 374.35 3071.65 374.35C3071.3 374.37 3071.25 374.1 3071.33 373.83C3071.38 373.67 3071.49 373.4 3071.58 373.39C3071.93 373.37 3071.96 373.63 3071.9 373.91L3071.91 373.92Z" fill="white"/>
                    <path d="M3002.15 592.34C3002.2 592.66 3002.3 592.92 3002.27 593.17C3002.26 593.31 3002.06 593.44 3001.95 593.58C3001.88 593.33 3001.76 593.08 3001.77 592.82C3001.77 592.68 3001.98 592.55 3002.15 592.34Z" fill="white"/>
                    <path d="M2916.74 350.96C2916.66 351.06 2916.54 351.19 2916.43 351.33C2916.36 351.08 2916.24 350.83 2916.25 350.57C2916.25 350.43 2916.46 350.3 2916.57 350.16C2916.78 350.37 2916.86 350.62 2916.74 350.95V350.96Z" fill="white"/>
                    <path d="M3046.81 595.37C3046.52 595.37 3046.28 595.27 3046.38 594.93C3046.41 594.84 3046.69 594.8 3046.86 594.79C3047.14 594.77 3047.39 594.89 3047.29 595.22C3047.26 595.31 3046.98 595.32 3046.81 595.37Z" fill="white"/>
                    <path d="M2910.94 390.15C2910.65 390.15 2910.41 390.05 2910.51 389.71C2910.54 389.62 2910.82 389.58 2910.99 389.57C2911.27 389.55 2911.52 389.67 2911.42 390C2911.39 390.09 2911.11 390.1 2910.94 390.15Z" fill="white"/>
                    <path d="M2918.62 399.9C2918.33 399.9 2918.09 399.8 2918.19 399.46C2918.22 399.37 2918.5 399.33 2918.67 399.32C2918.95 399.3 2919.2 399.42 2919.1 399.75C2919.07 399.84 2918.79 399.85 2918.62 399.9Z" fill="white"/>
                    <path d="M2951.74 431.43C2951.79 431.75 2951.89 432.01 2951.86 432.26C2951.85 432.4 2951.65 432.53 2951.54 432.67C2951.47 432.42 2951.35 432.17 2951.36 431.91C2951.36 431.77 2951.57 431.64 2951.74 431.43Z" fill="white"/>
                    <path d="M3051.34 393.78C3051.22 393.96 3051.07 394.18 3050.93 394.39C3050.85 394.23 3050.68 394.05 3050.71 393.92C3050.77 393.68 3050.94 393.46 3051.07 393.23C3051.15 393.39 3051.23 393.55 3051.35 393.78H3051.34Z" fill="white"/>
                    <path d="M3016.29 480.01C3016.21 480.11 3016.09 480.24 3015.98 480.38C3015.91 480.13 3015.79 479.88 3015.8 479.62C3015.8 479.48 3016.01 479.35 3016.12 479.21C3016.33 479.42 3016.41 479.67 3016.29 480V480.01Z" fill="white"/>
                    <path d="M3059.94 492.58C3059.99 492.9 3060.09 493.16 3060.06 493.41C3060.05 493.55 3059.85 493.68 3059.74 493.82C3059.67 493.57 3059.55 493.32 3059.56 493.06C3059.56 492.92 3059.77 492.79 3059.94 492.58Z" fill="white"/>
                    <path d="M3128.5 511.77C3128.26 512.09 3127.97 511.92 3127.71 511.88C3127.63 511.87 3127.5 511.57 3127.55 511.5C3127.74 511.18 3128.06 511.35 3128.31 511.4C3128.4 511.42 3128.44 511.65 3128.5 511.77Z" fill="white"/>
                    <path d="M3068.34 533.13C3068.05 533.13 3067.81 533.03 3067.91 532.69C3067.94 532.6 3068.22 532.56 3068.39 532.55C3068.67 532.53 3068.92 532.65 3068.82 532.98C3068.79 533.07 3068.51 533.08 3068.34 533.13Z" fill="white"/>
                    <path d="M2865.48 500.21C2865.63 500.29 2865.91 500.37 2865.91 500.47C2865.93 500.82 2865.66 500.87 2865.39 500.79C2865.23 500.74 2864.96 500.63 2864.95 500.54C2864.93 500.19 2865.19 500.16 2865.47 500.22L2865.48 500.21Z" fill="white"/>
                    <path d="M2960.78 496.22C2960.7 496.37 2960.62 496.65 2960.52 496.65C2960.17 496.67 2960.12 496.4 2960.2 496.13C2960.25 495.97 2960.36 495.7 2960.45 495.69C2960.8 495.67 2960.83 495.93 2960.77 496.21L2960.78 496.22Z" fill="white"/>
                    <path d="M2959.86 511.41C2959.91 511.55 2959.98 511.68 2960 511.82C2960 511.87 2959.87 511.93 2959.8 511.99C2959.75 511.85 2959.68 511.72 2959.66 511.58C2959.66 511.53 2959.79 511.47 2959.86 511.41Z" fill="white"/>
                    <path d="M2998.65 611.45C2998.7 611.59 2998.77 611.72 2998.79 611.86C2998.79 611.91 2998.66 611.97 2998.59 612.03C2998.54 611.89 2998.47 611.76 2998.45 611.62C2998.45 611.57 2998.58 611.51 2998.65 611.45Z" fill="white"/>
                    <path d="M2929.78 404.37C2929.7 404.48 2929.61 404.6 2929.53 404.72C2929.47 404.65 2929.35 404.55 2929.37 404.51C2929.42 404.38 2929.52 404.26 2929.6 404.14C2929.66 404.21 2929.71 404.28 2929.78 404.37Z" fill="white"/>
                    <path d="M2887.96 385.86C2888.05 385.79 2888.12 385.73 2888.19 385.68C2888.24 385.82 2888.31 385.95 2888.33 386.09C2888.33 386.14 2888.2 386.2 2888.13 386.26C2888.08 386.13 2888.02 385.99 2887.97 385.86H2887.96Z" fill="white"/>
                    <path d="M2972.66 394.44C2972.55 394.36 2972.43 394.27 2972.31 394.19C2972.38 394.13 2972.48 394.01 2972.52 394.03C2972.65 394.08 2972.77 394.18 2972.89 394.26C2972.82 394.32 2972.75 394.37 2972.66 394.44Z" fill="white"/>
                    <path d="M2999.74 431.03C2999.6 431.08 2999.47 431.15 2999.33 431.17C2999.28 431.17 2999.22 431.04 2999.16 430.97C2999.3 430.92 2999.43 430.85 2999.57 430.83C2999.62 430.83 2999.68 430.96 2999.74 431.03Z" fill="white"/>
                    <path d="M3046.43 441.49C3046.36 441.4 3046.3 441.33 3046.25 441.26C3046.39 441.21 3046.52 441.14 3046.66 441.12C3046.71 441.12 3046.77 441.25 3046.83 441.32C3046.7 441.37 3046.56 441.43 3046.43 441.48V441.49Z" fill="white"/>
                    <path d="M3071.89 388.26C3071.81 388.37 3071.72 388.49 3071.64 388.61C3071.58 388.54 3071.46 388.44 3071.48 388.4C3071.53 388.27 3071.63 388.15 3071.71 388.03C3071.77 388.1 3071.82 388.17 3071.89 388.26Z" fill="white"/>
                    <path d="M2953.83 390.32C2953.74 390.39 2953.67 390.45 2953.6 390.5C2953.55 390.36 2953.48 390.23 2953.46 390.09C2953.46 390.04 2953.59 389.98 2953.66 389.92C2953.71 390.05 2953.77 390.19 2953.82 390.32H2953.83Z" fill="white"/>
                    <path d="M2983.75 457.32C2983.84 457.25 2983.91 457.19 2983.98 457.14C2984.03 457.28 2984.1 457.41 2984.12 457.55C2984.12 457.6 2983.99 457.66 2983.92 457.72C2983.87 457.59 2983.81 457.45 2983.76 457.32H2983.75Z" fill="white"/>
                    <path d="M3122.64 485.43C3122.7 485.51 3122.81 485.61 3122.8 485.64C3122.72 485.76 3122.62 485.9 3122.5 485.95C3122.24 486.04 3122.14 485.91 3122.28 485.68C3122.35 485.57 3122.52 485.52 3122.64 485.44V485.43Z" fill="white"/>
                    <path d="M3122.2 486.18C3122.07 486.26 3121.91 486.42 3121.83 486.39C3121.61 486.29 3121.6 486.11 3121.76 486.04C3121.9 485.97 3122.07 485.96 3122.23 485.93C3122.22 486.02 3122.21 486.1 3122.2 486.19V486.18Z" fill="white"/>
                    <path d="M2932.25 522.48C2932.17 522.59 2932.08 522.71 2932 522.83C2931.94 522.76 2931.82 522.66 2931.84 522.62C2931.89 522.49 2931.99 522.37 2932.07 522.25C2932.13 522.32 2932.18 522.39 2932.25 522.48Z" fill="white"/>
                    <path d="M3132.19 401.31C3132.11 401.42 3132.02 401.54 3131.94 401.66C3131.88 401.59 3131.76 401.49 3131.78 401.45C3131.83 401.32 3131.93 401.2 3132.01 401.08C3132.07 401.15 3132.12 401.22 3132.19 401.31Z" fill="white"/>
                    <path d="M3128.01 491.11C3127.92 491.18 3127.85 491.24 3127.78 491.29C3127.73 491.15 3127.66 491.02 3127.64 490.88C3127.64 490.83 3127.77 490.77 3127.84 490.71C3127.89 490.84 3127.95 490.98 3128 491.11H3128.01Z" fill="white"/>
                    <path d="M3091.62 387.24C3091.7 387.13 3091.79 387.01 3091.87 386.89C3091.93 386.96 3092.05 387.06 3092.03 387.1C3091.98 387.23 3091.88 387.35 3091.8 387.47C3091.74 387.4 3091.69 387.33 3091.62 387.24Z" fill="white"/>
                    <path d="M3028.42 516.23C3028.34 516.34 3028.25 516.46 3028.17 516.58C3028.11 516.51 3027.99 516.41 3028.01 516.37C3028.06 516.24 3028.16 516.12 3028.24 516C3028.3 516.07 3028.35 516.14 3028.42 516.23Z" fill="white"/>
                    <path d="M3001.51 383.14C3001.44 383.05 3001.38 382.98 3001.33 382.91C3001.47 382.86 3001.6 382.79 3001.74 382.77C3001.79 382.77 3001.85 382.9 3001.91 382.97C3001.78 383.02 3001.64 383.08 3001.51 383.13V383.14Z" fill="white"/>
                    <path d="M3094.97 499.77C3095.02 499.91 3095.09 500.04 3095.11 500.18C3095.11 500.23 3094.98 500.29 3094.91 500.35C3094.86 500.21 3094.79 500.08 3094.77 499.94C3094.77 499.89 3094.9 499.83 3094.97 499.77Z" fill="white"/>
                    <path d="M3014.31 382.01C3014.23 382.12 3014.14 382.24 3014.06 382.36C3014 382.29 3013.88 382.19 3013.9 382.15C3013.95 382.02 3014.05 381.9 3014.13 381.78L3014.31 382.01Z" fill="white"/>
                    <path d="M2967.96 487.13C2968.07 487.21 2968.19 487.3 2968.31 487.38C2968.24 487.44 2968.14 487.56 2968.1 487.54C2967.97 487.49 2967.85 487.39 2967.73 487.31C2967.8 487.25 2967.87 487.2 2967.96 487.13Z" fill="white"/>
                    <path d="M2839.48 533.06C2839.53 533.2 2839.6 533.33 2839.62 533.47C2839.62 533.52 2839.49 533.58 2839.42 533.64C2839.37 533.5 2839.3 533.37 2839.28 533.23C2839.28 533.18 2839.41 533.12 2839.48 533.06Z" fill="white"/>
                    <path d="M2950.18 567.07C2948.77 566.11 2948.98 564.79 2949.2 563.56C2949.28 563.11 2949.51 562.43 2949.47 562.18C2949.19 560.59 2951.03 561.1 2951.3 560.15C2952.18 560.56 2953.37 560.74 2953.85 561.43C2954.31 562.08 2954.81 562.94 2954.95 563.82C2955.08 564.65 2955.64 565.76 2954.02 565.91C2953.78 565.93 2953.6 566.43 2953.37 566.69C2952.33 567.82 2951.24 567.91 2950.19 567.07H2950.18Z" fill="white"/>
                    <path d="M3085.64 540.26C3085 541.04 3083.92 541.09 3083.65 540.86C3082.6 539.98 3081.38 540.6 3080.29 540.23C3079.52 539.97 3079.09 539.69 3079.05 538.9C3078.99 537.6 3079.28 537.08 3080.19 536.84C3080.59 536.74 3080.96 536.59 3081.33 536.43C3082.97 535.74 3086.13 537.47 3085.85 539.49C3085.8 539.85 3085.77 540.15 3085.63 540.25L3085.64 540.26Z" fill="white"/>
                    <path d="M3013.54 500.32C3012.65 500.39 3012 501.51 3010.98 500.81C3010.84 500.71 3010.72 500.53 3010.57 500.5C3009.93 500.36 3009.2 500.44 3008.87 499.68C3008.61 499.09 3008.94 498.53 3009.06 497.98C3009.3 496.88 3010.65 496.03 3011.88 496.16C3012.84 496.26 3013.42 496.77 3013.8 497.67C3014.19 498.61 3013.68 499.43 3013.55 500.31L3013.54 500.32Z" fill="white"/>
                    <path d="M3037.4 380.05C3038.35 378.87 3039.22 378.68 3040.15 379.26C3040.66 379.58 3041.1 380.03 3041.59 380.38C3042.06 380.71 3042.04 381.02 3041.68 381.42C3041.27 381.87 3040.97 382.44 3040.52 382.83C3039.75 383.5 3038.8 383.59 3037.84 383.35C3037.64 383.3 3037.37 383.04 3037.36 382.86C3037.29 382.18 3037 381.59 3036.82 380.96C3036.73 380.65 3037.34 380.14 3037.4 380.04V380.05Z" fill="white"/>
                    <path d="M3136.3 437.73C3135.92 438.3 3135.39 439.13 3134.38 438.69C3133.9 438.48 3133 438.09 3133.24 437.48C3133.5 436.82 3133.24 435.86 3134.1 435.49C3134.7 435.23 3135.42 435.16 3135.91 434.78C3136.36 434.43 3136.58 434.46 3136.98 434.77C3137.56 435.22 3137.61 435.8 3137.36 436.35C3137.16 436.81 3136.73 437.18 3136.29 437.73H3136.3Z" fill="white"/>
                    <path d="M3023.59 367.08C3023.17 367.63 3022.38 367.76 3021.82 367.36C3021.63 367.23 3020.71 364.93 3020.76 364.73C3020.88 364.28 3022.39 363.76 3022.39 363.85C3022.29 364.94 3023.48 364.31 3023.7 364.88C3023.92 365.43 3023.85 366.73 3023.58 367.08H3023.59Z" fill="white"/>
                    <path d="M2956.98 507.48C2957.26 507.71 2957.75 507.99 2957.54 508.26C2957.15 508.77 2956.94 509.5 2956.09 509.6C2955.62 509.66 2955.29 509.31 2954.9 509.35C2954.27 509.42 2953.97 509.28 2953.85 508.59C2953.73 507.86 2954.05 507.3 2954.61 507.16C2955.39 506.96 2956.31 506.63 2956.98 507.47V507.48Z" fill="white"/>
                    <path d="M3047.68 502C3048.05 501.59 3048.27 500.86 3049.06 501.54C3049.24 501.69 3049.72 501.47 3050.07 501.45C3050.46 501.43 3050.86 501.45 3051.25 501.44C3050.93 501.93 3050.72 502.63 3050.27 502.86C3049.69 503.15 3048.92 503.05 3048.23 503.16C3047.86 503.22 3047.5 503.21 3047.32 502.88C3047.13 502.52 3047.47 502.26 3047.67 502H3047.68Z" fill="white"/>
                    <path d="M2932.98 422.06C2933.09 421.93 2933.18 421.78 2933.3 421.66C2933.58 421.4 2933.69 420.8 2934.24 421.06C2934.8 421.33 2935.1 421.8 2935.09 422.48C2935.09 423.05 2934.76 423.14 2934.3 423.23C2934.01 423.29 2933.76 423.6 2933.47 423.66C2933.08 423.74 2932.62 423.93 2932.3 423.52C2931.92 423.02 2932.47 422.79 2932.66 422.47C2932.74 422.32 2932.87 422.2 2932.97 422.07L2932.98 422.06Z" fill="white"/>
                    <path d="M3032.8 364.7C3032.5 363.96 3031.96 363.28 3032.03 362.41C3032.05 362.21 3032.24 361.95 3032.57 361.95C3032.98 361.95 3033.5 361.67 3033.75 362.13C3034.06 362.69 3034.25 363.33 3034.42 363.95C3034.62 364.69 3033.83 364.23 3033.64 364.55C3033.44 364.88 3033.16 365.03 3032.8 364.7Z" fill="white"/>
                    <path d="M3023.25 536.95C3023.48 536.53 3023.79 536.4 3024.22 536.79C3024.9 537.39 3025 538.08 3024.53 538.73C3024.11 539.31 3023.52 539.34 3022.98 539.13C3022.36 538.89 3022.51 537.84 3023.25 536.95Z" fill="white"/>
                    <path d="M3046.85 611.8C3047.01 611.6 3047.14 611.36 3047.34 611.2C3047.8 610.82 3048.47 611.12 3048.54 611.7C3048.58 612.05 3048.68 612.4 3048.64 612.73C3048.59 613.16 3048.28 613.59 3047.9 613.67C3047.46 613.77 3047.02 613.28 3046.5 613.45C3046.51 612.96 3046.16 612.43 3046.68 612C3046.75 611.95 3046.79 611.87 3046.84 611.8H3046.85Z" fill="white"/>
                    <path d="M3008.3 406.54C3007.99 406.57 3007.74 406.59 3007.48 406.64C3006.84 406.76 3006.62 405.81 3005.9 406.06C3005.52 406.19 3005.21 405.94 3005.21 405.55C3005.21 405.17 3005.49 404.99 3005.87 404.96C3006.38 404.92 3006.9 404.82 3007.41 404.8C3007.65 404.8 3008.08 404.45 3008.13 405.06C3008.17 405.56 3008.24 406.05 3008.3 406.55V406.54Z" fill="white"/>
                    <path d="M3112.43 377.74C3112.81 378.15 3112.68 379.14 3112.19 379.55C3112 379.71 3110.39 379.57 3110.21 379.32C3109.76 378.69 3110.43 378.42 3110.7 378.09C3111.2 377.47 3112.06 377.36 3112.42 377.75L3112.43 377.74Z" fill="white"/>
                    <path d="M3104.78 450.04C3104.31 449.77 3104.13 449.48 3104.53 449.03C3105.01 448.48 3105.86 448.23 3106.26 448.55C3106.67 448.87 3106.62 449.78 3106.16 450.35C3105.48 451.2 3105.2 450.19 3104.78 450.04Z" fill="white"/>
                    <path d="M3107.01 456.02C3106.56 456.18 3106.08 456.39 3105.62 456.08C3105.13 455.74 3105.29 454.56 3105.85 454.11C3106.21 453.81 3106.46 454.05 3106.7 454.28C3107.26 454.82 3107.4 455.64 3107.01 456.03V456.02Z" fill="white"/>
                    <path d="M3039.26 413.12C3039.47 412.85 3039.59 412.73 3039.68 412.58C3040.01 412.03 3040.59 412.25 3041.05 412.12C3041.03 412.6 3041.37 413.11 3040.92 413.56C3040.8 413.68 3040.7 413.82 3040.6 413.96C3040.28 414.44 3039.99 414.68 3039.4 414.2C3038.84 413.73 3038.89 413.4 3039.26 413.12Z" fill="white"/>
                    <path d="M3146.45 518.7C3145.95 518.35 3146.1 517.96 3146.39 517.81C3146.97 517.51 3147.6 517.32 3148.23 517.13C3148.81 516.96 3148.62 517.47 3148.69 517.73C3148.99 518.85 3147.81 518.49 3147.43 518.95C3146.99 519.48 3146.73 518.76 3146.45 518.7Z" fill="white"/>
                    <path d="M3068.96 423.81C3068.62 424.45 3068.02 424.49 3067.47 424.3C3067.06 424.16 3066.93 422.72 3067.27 422.58C3067.91 422.31 3068.49 422.45 3069.01 422.94C3069.38 423.29 3069.17 423.53 3068.96 423.8V423.81Z" fill="white"/>
                    <path d="M2919.78 479.91C2919.44 479.52 2919.47 479.03 2919.57 478.55C2919.63 478.27 2921.07 478.32 2921.52 478.61C2921.93 478.88 2921.76 479.17 2921.52 479.44C2921.06 479.99 2920.2 480.23 2919.77 479.91H2919.78Z" fill="white"/>
                    <path d="M3057.35 388.91C3057.5 389.13 3057.59 389.43 3057.3 389.56C3056.63 389.85 3056.03 390.32 3055.25 390.37C3054.92 390.39 3054.68 390.23 3054.61 390.04C3054.54 389.87 3054.66 389.45 3054.81 389.38C3055.4 389.1 3056.03 388.91 3056.65 388.7C3056.91 388.61 3057.15 388.7 3057.35 388.91Z" fill="white"/>
                    <path d="M3064.32 361.36C3064.17 361.59 3063.98 361.89 3063.57 361.67C3063.21 361.49 3063.27 361.12 3063.18 360.81C3062.99 360.18 3063.47 359.85 3063.8 359.54C3064 359.35 3064.64 359.28 3064.74 359.42C3064.95 359.69 3065.03 360.16 3064.96 360.51C3064.91 360.8 3064.57 361.05 3064.32 361.36Z" fill="white"/>
                    <path d="M3154.8 472.39C3155.18 472.6 3155.3 472.91 3154.91 473.34C3154.43 473.88 3153.6 474.12 3153.19 473.8C3152.49 473.27 3153.64 472.79 3153.24 472.21C3153.17 472.11 3154.24 472.01 3154.8 472.39Z" fill="white"/>
                    <path d="M2996.35 438.44C2996.2 438.48 2996.01 438.59 2995.9 438.54C2995.28 438.25 2994.94 436.78 2995.39 436.3C2995.54 436.14 2995.82 435.94 2995.99 435.98C2996.17 436.02 2996.37 436.3 2996.42 436.51C2996.67 437.44 2996.62 438.09 2996.36 438.44H2996.35Z" fill="white"/>
                    <path d="M2969.77 532.38C2969.97 532.54 2970.21 532.67 2970.37 532.86C2970.78 533.36 2970.23 533.59 2970.03 533.91C2969.77 534.33 2969.48 534.15 2969.2 533.92C2968.95 533.71 2968.65 533.56 2968.63 533.15C2968.61 532.73 2968.44 532.26 2968.9 532.01C2969.26 531.82 2969.51 532.18 2969.77 532.37V532.38Z" fill="white"/>
                    <path d="M3056.88 391.79C3057.29 391.44 3057.55 391.7 3057.78 391.93C3058.34 392.48 3058.48 393.29 3058.07 393.67C3057.73 393.98 3057.46 393.72 3057.22 393.48C3056.66 392.93 3056.54 392.13 3056.88 391.79Z" fill="white"/>
                    <path d="M3159.68 466.88C3159.73 467.48 3159.92 468.04 3159.4 468.56C3158.97 468.99 3158.73 468.82 3158.36 468.54C3157.96 468.24 3157.85 468.02 3158.15 467.52C3158.54 466.87 3159.14 467.02 3159.68 466.87V466.88Z" fill="white"/>
                    <path d="M3154.44 478.98C3154.29 479.03 3154.09 479.17 3153.99 479.11C3153.7 478.94 3153.3 478.75 3153.22 478.48C3153.07 478.04 3152.85 477.5 3153.28 477.07C3153.61 476.74 3153.89 477 3154.13 477.23C3154.7 477.79 3154.82 478.55 3154.44 478.97V478.98Z" fill="white"/>
                    <path d="M2936.73 519.34C2936.5 519.61 2936.25 519.9 2935.92 519.54C2935.5 519.07 2935.36 518.46 2935.83 518C2936.07 517.76 2936.6 517.81 2937 517.73C2937.04 518.29 2937.31 518.9 2936.73 519.34Z" fill="white"/>
                    <path d="M3064.48 572.17C3064.02 571.75 3063.96 570.78 3064.28 570.49C3064.62 570.18 3064.86 570.47 3065.13 570.66C3065.67 571.04 3065.79 571.59 3065.51 572.09C3065.43 572.24 3064.75 572.38 3064.48 572.17Z" fill="white"/>
                    <path d="M3087.35 428.5C3087.08 428.84 3086.77 429.19 3086.51 428.8C3086.18 428.33 3086.03 427.73 3085.8 427.18C3086.39 427.07 3086.92 427.01 3087.43 427.52C3087.91 428 3087.5 428.24 3087.35 428.49V428.5Z" fill="white"/>
                    <path d="M3127.7 553.06C3127.32 553.67 3127.02 553.49 3126.61 553.21C3125.97 552.78 3126.19 552.44 3126.55 551.98C3126.92 551.51 3127.18 551.65 3127.59 551.94C3128.19 552.37 3128.12 552.73 3127.7 553.06Z" fill="white"/>
                    <path d="M3083.74 417.93C3083.43 417.58 3082.64 417.39 3083.28 416.78C3083.63 416.45 3084.2 416.36 3084.66 416.65C3085.43 417.13 3084.63 417.73 3084.57 418.18C3084.5 418.74 3084.06 417.94 3083.74 417.92V417.93Z" fill="white"/>
                    <path d="M3060.44 344.33C3060.28 344.53 3060.15 344.76 3059.96 344.92C3059.61 345.22 3059.35 344.95 3059.11 344.73C3058.93 344.57 3058.62 344.49 3058.68 344.17C3058.74 343.8 3058.97 343.6 3059.36 343.59C3059.53 343.59 3059.7 343.55 3059.87 343.54C3060.19 343.51 3060.7 343.36 3060.81 343.5C3061.05 343.81 3060.63 344.07 3060.45 344.34L3060.44 344.33Z" fill="white"/>
                    <path d="M3016.26 584.76C3016.61 585.27 3016.11 585.51 3015.89 585.82C3015.48 586.38 3015.17 585.87 3014.86 585.66C3014.57 585.46 3014.32 585.21 3014.66 584.85C3015.02 584.48 3015.94 584.44 3016.25 584.77L3016.26 584.76Z" fill="white"/>
                    <path d="M3106.29 480.09C3105.95 479.79 3105.67 479.56 3106.02 479.2C3106.38 478.83 3107.32 478.78 3107.61 479.12C3107.91 479.47 3107.59 479.7 3107.42 479.98C3107.02 480.67 3106.63 480.4 3106.29 480.09Z" fill="white"/>
                    <path d="M3076.65 383.43C3076.75 383.57 3077.04 383.8 3077 383.95C3076.91 384.36 3076.45 384.22 3076.21 384.19C3075.85 384.14 3075.19 384.83 3075.14 383.83C3075.12 383.5 3075.01 383.15 3075.33 382.92C3075.98 382.45 3076.14 383.29 3076.65 383.43Z" fill="white"/>
                    <path d="M2949.81 429.53C2949.42 429.64 2948.79 430.05 2948.66 429.54C2948.55 429.1 2947.85 428.86 2948.2 428.35C2948.4 428.04 2949.72 428.43 2949.97 428.86C2950.1 429.09 2950.04 429.33 2949.82 429.53H2949.81Z" fill="white"/>
                    <path d="M3113.9 544.43C3113.9 544.43 3113.86 544.9 3113.75 545.3C3113.7 545.48 3112.51 545.39 3112.36 545.2C3111.95 544.7 3112.49 544.47 3112.69 544.16C3112.93 543.77 3113.22 543.87 3113.91 544.43H3113.9Z" fill="white"/>
                    <path d="M3091.53 565.7C3091.02 566.06 3090.8 565.54 3090.48 565.35C3090.08 565.1 3090.17 564.71 3090.47 564.55C3090.81 564.36 3091.25 564.32 3091.66 564.3C3091.81 564.3 3091.71 565.55 3091.53 565.7Z" fill="white"/>
                    <path d="M2932.13 560.73C2931.72 560.52 2931.71 560.24 2931.86 560.05C2932.28 559.53 2932.83 559.52 2933.41 559.78C2933.71 559.92 2933.71 560.2 2933.56 560.4C2933.14 560.95 2932.58 560.9 2932.13 560.73Z" fill="white"/>
                    <path d="M2989.79 419.62C2990.17 420.03 2990.27 420.56 2990 420.91C2989.86 421.08 2989.24 421.22 2988.84 420.89C2988.31 420.45 2988.8 420.17 2989 419.86C2989.2 419.57 2989.44 419.35 2989.79 419.62Z" fill="white"/>
                    <path d="M3008.67 600.17C3008.46 600.05 3008.11 598.87 3008.26 598.84C3008.66 598.77 3009.1 598.72 3009.47 598.83C3009.81 598.93 3009.93 599.3 3009.61 599.61C3009.35 599.87 3009.25 600.44 3008.67 600.17Z" fill="white"/>
                    <path d="M2991.76 414.65C2991.36 414.92 2990.82 415.1 2990.29 414.65C2990.1 414.49 2990.04 414.21 2990.29 414.01C2990.79 413.62 2991.33 413.51 2991.86 413.92C2992.06 414.07 2992.11 414.35 2991.76 414.65Z" fill="white"/>
                    <path d="M2954.95 369.12C2954.67 369.51 2954.3 369.44 2954.14 369.14C2953.95 368.8 2953.91 368.35 2953.89 367.95C2953.89 367.8 2955.14 367.9 2955.29 368.08C2955.5 368.33 2955.37 368.74 2954.95 369.12Z" fill="white"/>
                    <path d="M3082.44 422.03C3082.21 422.31 3081.98 422.59 3081.62 422.26C3081.23 421.9 3081.15 421.35 3081.43 421C3081.57 420.83 3082.19 420.69 3082.59 421.02C3083.12 421.46 3082.61 421.73 3082.45 422.04L3082.44 422.03Z" fill="white"/>
                    <path d="M3033.79 354.99C3033.34 355.19 3032.91 355.32 3032.45 355.04C3032.18 354.87 3032.15 354.59 3032.33 354.42C3032.63 354.13 3032.73 353.36 3033.44 353.82C3033.87 354.1 3033.63 354.6 3033.78 354.99H3033.79Z" fill="white"/>
                    <path d="M3070.46 423.61C3070.31 423.65 3070.16 423.69 3070.01 423.73C3069.91 422.98 3069.93 422.2 3069.41 421.57C3069.39 421.54 3069.59 421.27 3069.73 421.19C3069.81 421.14 3070.09 421.25 3070.09 421.29C3070.09 421.98 3070.86 422.44 3070.69 423.18C3070.65 423.34 3070.54 423.47 3070.47 423.62L3070.46 423.61Z" fill="white"/>
                    <path d="M3066.78 421.84C3066.4 421.49 3067.03 421.15 3067.31 420.86C3067.86 420.29 3068.14 421.04 3068.51 421.25C3068.62 421.31 3068.81 421.82 3068.39 421.67C3067.76 421.43 3067.69 422.74 3066.78 421.84Z" fill="white"/>
                    <path d="M2973 554.39C2973.55 554.7 2973.32 555.27 2973.45 555.73C2973.13 555.78 2972.68 555.98 2972.51 555.84C2972.15 555.53 2972.14 555.04 2972.37 554.59C2972.51 554.32 2972.74 554.32 2972.99 554.4L2973 554.39Z" fill="white"/>
                    <path d="M3113.05 538.4C3112.8 538.13 3112.25 537.98 3112.67 537.42C3112.97 537.02 3113.25 537.2 3113.51 537.43C3113.8 537.68 3114.32 537.85 3113.9 538.41C3113.6 538.82 3113.33 538.61 3113.04 538.4H3113.05Z" fill="white"/>
                    <path d="M2919.26 510C2918.44 510.27 2917.93 510.06 2917.51 509.59C2917.41 509.48 2917.4 509.29 2917.35 509.14C2917.52 509.12 2917.7 509.14 2917.85 509.08C2918.44 508.86 2918.8 509.26 2919.16 509.6C2919.27 509.7 2919.25 509.95 2919.26 510Z" fill="white"/>
                    <path d="M3130.89 558.39C3130.82 558.5 3130.52 558.34 3130.26 558.1C3129.98 557.84 3129.44 557.69 3129.86 557.12C3130.15 556.72 3130.43 556.88 3130.7 557.12C3130.98 557.38 3131.51 557.55 3130.9 558.39H3130.89Z" fill="white"/>
                    <path d="M3108.79 507.43C3108.75 507.32 3108.63 507.14 3108.67 507.08C3108.99 506.59 3109.32 506.12 3110.04 506.16C3110.34 506.18 3110.5 506.23 3110.43 506.52C3110.32 506.97 3109.18 507.6 3108.79 507.43Z" fill="white"/>
                    <path d="M3016.61 550.35C3015.94 550.85 3015.76 550.04 3015.37 549.84C3015.19 549.75 3015.32 549.35 3015.54 549.2C3016.18 548.75 3016.42 549.46 3016.78 549.71C3016.95 549.82 3016.83 550.18 3016.61 550.36V550.35Z" fill="white"/>
                    <path d="M3068.61 463.01C3067.94 463.51 3067.76 462.7 3067.37 462.5C3067.19 462.41 3067.32 462.01 3067.54 461.86C3068.18 461.41 3068.42 462.12 3068.78 462.37C3068.95 462.48 3068.83 462.84 3068.61 463.02V463.01Z" fill="white"/>
                    <path d="M2951.88 452.27C2951.81 452.38 2951.51 452.22 2951.25 451.98C2950.97 451.72 2950.43 451.57 2950.85 451C2951.14 450.6 2951.42 450.76 2951.69 451C2951.97 451.26 2952.5 451.43 2951.89 452.27H2951.88Z" fill="white"/>
                    <path d="M3119.17 416.02C3119.26 415.48 3119.36 414.93 3119.47 414.38C3119.47 414.35 3119.65 414.32 3119.71 414.36C3120.04 414.52 3120.17 414.79 3120.19 415.17C3120.22 415.59 3120.31 416.02 3119.8 416.2C3119.68 416.24 3119.5 416.13 3119.17 416.03V416.02Z" fill="white"/>
                    <path d="M3073.38 400.98C3073.67 401.18 3073.99 401.37 3073.74 401.78C3073.42 402.31 3073.08 402.12 3072.72 401.79C3072.47 401.57 3072.12 401.4 3072.37 400.99C3072.69 400.47 3073.05 400.63 3073.38 400.98Z" fill="white"/>
                    <path d="M3020.5 396.36C3020.1 396.63 3019.87 396.73 3019.67 396.47C3019.28 395.97 3019.18 395.44 3019.58 394.9C3019.66 394.8 3019.87 394.79 3020.02 394.74C3020.18 395.3 3020.35 395.86 3020.5 396.35V396.36Z" fill="white"/>
                    <path d="M3095.67 468.56C3095.43 468.96 3095.13 469.18 3094.97 469.03C3094.65 468.73 3093.9 468.66 3094.2 467.93C3094.3 467.68 3094.61 467.48 3094.8 467.62C3095.15 467.89 3095.76 468.05 3095.67 468.55V468.56Z" fill="white"/>
                    <path d="M2947.8 469.16C2947.56 469.43 2947.36 469.69 2946.97 469.37C2946.44 468.92 2946.91 468.64 2947.13 468.33C2947.33 468.04 2947.56 467.8 2947.95 468.13C2948.49 468.58 2947.99 468.85 2947.8 469.17V469.16Z" fill="white"/>
                    <path d="M3009.81 518.65C3010.31 519.32 3009.5 519.5 3009.3 519.89C3009.21 520.07 3008.81 519.94 3008.66 519.72C3008.21 519.08 3008.92 518.84 3009.17 518.48C3009.28 518.31 3009.64 518.43 3009.82 518.65H3009.81Z" fill="white"/>
                    <path d="M3024.83 387.86C3025.12 388.06 3025.44 388.25 3025.19 388.66C3024.87 389.19 3024.53 389 3024.17 388.67C3023.92 388.45 3023.57 388.28 3023.82 387.87C3024.14 387.35 3024.5 387.51 3024.83 387.86Z" fill="white"/>
                    <path d="M3146.41 468.45C3146.91 468.78 3146.92 469.01 3146.77 469.24C3146.44 469.76 3146.09 469.55 3145.76 469.23C3145.59 469.06 3145.18 468.81 3145.36 468.7C3145.72 468.48 3146.22 468.49 3146.42 468.45H3146.41Z" fill="white"/>
                    <path d="M2938.67 500.9C2938.54 500.96 2938.33 501.06 2938.12 501.17C2938.09 500.7 2937.78 500.2 2938.21 499.77C2938.4 499.59 2938.62 499.56 2938.86 499.77C2939.38 500.21 2938.92 500.48 2938.66 500.91L2938.67 500.9Z" fill="white"/>
                    <path d="M3033.25 336.25C3033.04 336.14 3033.02 335.89 3033.16 335.67C3033.34 335.38 3033.61 335.11 3033.95 335.47C3034.19 335.72 3034.86 335.83 3034.49 336.31C3034.36 336.48 3033.72 336.61 3033.25 336.26V336.25Z" fill="white"/>
                    <path d="M3162.82 433.05C3162.5 433.57 3161.93 433.35 3161.47 433.46C3161.54 433.24 3161.56 432.97 3161.69 432.8C3161.92 432.51 3162.09 431.99 3162.65 432.39C3162.9 432.57 3162.91 432.78 3162.82 433.04V433.05Z" fill="white"/>
                    <path d="M3017.31 361.07C3016.99 360.91 3017.27 361.16 3017.3 360.82C3017.31 360.64 3017.55 360.34 3017.72 360.32C3018.18 360.25 3018.71 359.98 3019.1 360.51C3019.14 360.57 3019.16 360.72 3019.13 360.74C3018.58 361.14 3017.91 360.92 3017.31 361.07Z" fill="white"/>
                    <path d="M3017.54 409.96C3017.06 409.98 3016.51 410.26 3016.16 409.72C3016.08 409.59 3016.21 409.21 3016.36 409.08C3016.84 408.67 3017.07 409.23 3017.4 409.42C3017.62 409.55 3017.74 409.72 3017.54 409.97V409.96Z" fill="white"/>
                    <path d="M3041.43 490.48C3041.32 490.61 3041.24 490.79 3041.1 490.87C3041.01 490.92 3040.76 490.9 3040.72 490.83C3040.58 490.58 3041.57 489.31 3041.88 489.34C3041.95 489.34 3042.09 489.61 3042.05 489.67C3041.87 489.96 3041.64 490.22 3041.42 490.48H3041.43Z" fill="white"/>
                    <path d="M2932.62 398.9C2932.31 399.34 2932.01 399.21 2931.75 398.95C2931.56 398.76 2931.54 398.55 2931.75 398.29C2932.05 397.91 2932.33 398.03 2932.59 398.29C2932.77 398.47 2932.87 398.72 2932.63 398.89L2932.62 398.9Z" fill="white"/>
                    <path d="M3158.95 449.7C3158.72 449.86 3158.53 449.91 3158.28 449.7C3157.9 449.4 3158.03 449.12 3158.28 448.86C3158.47 448.67 3158.68 448.65 3158.94 448.86C3159.32 449.17 3159.17 449.42 3158.96 449.7H3158.95Z" fill="white"/>
                    <path d="M3018.69 581.47C3018.46 581.63 3018.27 581.68 3018.02 581.47C3017.64 581.17 3017.77 580.89 3018.02 580.63C3018.21 580.44 3018.42 580.42 3018.68 580.63C3019.06 580.94 3018.91 581.19 3018.7 581.47H3018.69Z" fill="white"/>
                    <path d="M2934.68 527.95C2934.43 527.74 2934.22 527.53 2934.52 527.14C2934.72 526.88 2934.94 526.86 2935.16 527C2935.46 527.19 2935.65 527.44 2935.35 527.82C2935.15 528.07 2934.96 528.08 2934.68 527.95Z" fill="white"/>
                    <path d="M3134.24 539.24C3134.46 539.67 3134.57 540.11 3134.31 540.54C3134.24 540.66 3133.94 540.78 3133.93 540.77C3133.65 540.37 3133.56 539.93 3133.83 539.49C3133.9 539.37 3134.09 539.33 3134.24 539.24Z" fill="white"/>
                    <path d="M3052.84 585.72C3052.7 586 3052.44 585.99 3052.22 585.85C3051.91 585.66 3051.73 585.42 3052.03 585.03C3052.23 584.77 3052.45 584.75 3052.67 584.89C3052.98 585.08 3053.17 585.35 3052.84 585.72Z" fill="white"/>
                    <path d="M3098.46 492.08C3098.23 492.24 3098.04 492.29 3097.79 492.08C3097.41 491.78 3097.54 491.5 3097.79 491.24C3097.98 491.05 3098.19 491.03 3098.45 491.24C3098.83 491.55 3098.68 491.8 3098.47 492.08H3098.46Z" fill="white"/>
                    <path d="M2976.35 360.78C2976.08 360.97 2975.89 361.02 2975.64 360.82C2975.26 360.52 2975.39 360.24 2975.64 359.98C2975.83 359.79 2976.04 359.77 2976.3 359.98C2976.69 360.29 2976.5 360.54 2976.36 360.79L2976.35 360.78Z" fill="white"/>
                    <path d="M2995.43 370.51C2995.02 370.22 2995.15 369.92 2995.41 369.67C2995.6 369.48 2995.81 369.46 2996.07 369.67C2996.45 369.97 2996.32 370.25 2996.07 370.51C2995.88 370.7 2995.64 370.76 2995.43 370.52V370.51Z" fill="white"/>
                    <path d="M3135.24 575.63C3135.46 575.37 3135.69 575.1 3136.04 575.44C3136.15 575.54 3136.21 575.7 3136.29 575.83C3135.93 575.92 3135.56 576.01 3135.2 576.1C3135.21 575.94 3135.23 575.78 3135.24 575.62V575.63Z" fill="white"/>
                    <path d="M3090.13 561.56C3089.91 561.82 3089.68 562.09 3089.33 561.75C3089.22 561.65 3089.16 561.49 3089.08 561.36C3089.44 561.27 3089.81 561.18 3090.17 561.09C3090.16 561.25 3090.14 561.41 3090.13 561.57V561.56Z" fill="white"/>
                    <path d="M3139.1 450.93C3138.75 450.98 3138.42 451.02 3138.09 451.07C3138.16 450.85 3138.16 450.57 3138.31 450.41C3138.62 450.07 3138.91 450.33 3139.13 450.58C3139.19 450.65 3139.12 450.83 3139.11 450.93H3139.1Z" fill="white"/>
                    <path d="M3004 514.05C3003.9 513.88 3003.72 513.67 3003.75 513.62C3004.02 513.25 3004.47 513.46 3004.82 513.35C3004.8 513.51 3004.84 513.69 3004.76 513.82C3004.57 514.13 3004.31 514.3 3003.99 514.05H3004Z" fill="white"/>
                    <path d="M2936.43 561.76C2936.3 561.8 2936.14 561.91 2936.06 561.86C2935.77 561.7 2935.46 561.47 2935.72 561.1C2935.84 560.93 2936.11 560.86 2936.31 560.75C2936.35 561.08 2936.39 561.42 2936.43 561.76Z" fill="white"/>
                    <path d="M2961.68 555.43C2962.06 555.71 2961.9 556 2961.65 556.25C2961.55 556.35 2961.36 556.36 2961.21 556.4C2961.21 556.03 2961.22 555.65 2961.23 555.28C2961.38 555.33 2961.52 555.38 2961.68 555.43Z" fill="white"/>
                    <path d="M3120.07 460.51C3120 460.53 3119.84 460.65 3119.76 460.61C3119.47 460.45 3119.15 460.23 3119.4 459.85C3119.51 459.68 3119.79 459.61 3119.99 459.49C3120.01 459.82 3120.04 460.15 3120.06 460.51H3120.07Z" fill="white"/>
                    <path d="M3097.84 486.12C3097.56 486.5 3097.27 486.34 3097.02 486.09C3096.92 485.99 3096.91 485.8 3096.87 485.65C3097.24 485.65 3097.62 485.66 3097.99 485.67C3097.94 485.82 3097.89 485.96 3097.84 486.12Z" fill="white"/>
                    <path d="M2988.57 589.01C2988.42 589.07 2988.27 589.12 2988.12 589.18C2988.11 588.81 2988.1 588.44 2988.08 588.06C2988.23 588.1 2988.4 588.12 2988.52 588.19C2988.92 588.44 2988.78 588.72 2988.56 589.01H2988.57Z" fill="white"/>
                    <path d="M2990.16 386.41C2990.54 386.69 2990.38 386.98 2990.13 387.23C2990.03 387.33 2989.84 387.34 2989.69 387.38C2989.69 387.01 2989.7 386.63 2989.71 386.26C2989.86 386.31 2990 386.36 2990.16 386.41Z" fill="white"/>
                    <path d="M2960.71 365.65C2960.68 365.87 2960.71 366.05 2960.63 366.17C2960.43 366.48 2960.16 366.66 2959.84 366.33C2959.73 366.22 2959.68 366.06 2959.61 365.93C2959.97 365.84 2960.33 365.75 2960.71 365.65Z" fill="white"/>
                    <path d="M3151.8 398.73C3151.47 398.86 3151.32 398.6 3151.15 398.44C3151.1 398.39 3151.14 398.11 3151.21 398.07C3151.5 397.92 3151.67 398.2 3151.84 398.35C3151.9 398.4 3151.82 398.6 3151.81 398.72L3151.8 398.73Z" fill="white"/>
                    <path d="M3148.98 421.44C3148.87 421.71 3148.82 421.95 3148.68 422.13C3148.6 422.23 3148.39 422.24 3148.24 422.29C3148.31 422.07 3148.34 421.82 3148.46 421.63C3148.53 421.53 3148.75 421.52 3148.98 421.44Z" fill="white"/>
                    <path d="M2919.77 449.5C2919.44 449.63 2919.29 449.37 2919.12 449.21C2919.07 449.16 2919.11 448.88 2919.18 448.84C2919.47 448.69 2919.64 448.97 2919.81 449.12C2919.87 449.17 2919.79 449.37 2919.78 449.49L2919.77 449.5Z" fill="white"/>
                    <path d="M2939.04 545.21C2938.77 545.1 2938.53 545.05 2938.35 544.91C2938.25 544.83 2938.24 544.62 2938.19 544.47C2938.41 544.54 2938.66 544.57 2938.85 544.69C2938.95 544.76 2938.96 544.98 2939.04 545.21Z" fill="white"/>
                    <path d="M2941.45 546.68C2941.35 546.59 2941.15 546.45 2941.02 546.27C2940.87 546.05 2940.99 545.9 2941.23 546.01C2941.43 546.1 2941.63 546.26 2941.77 546.44C2941.93 546.65 2941.82 546.77 2941.45 546.69V546.68Z" fill="white"/>
                    <path d="M2905.57 456.55C2905.65 456.69 2905.82 456.88 2905.78 456.95C2905.63 457.22 2905.4 457.14 2905.23 456.95C2905.13 456.84 2904.98 456.63 2905.02 456.55C2905.17 456.28 2905.38 456.37 2905.57 456.55Z" fill="white"/>
                    <path d="M3153.2 451.04C3153.06 451.12 3152.87 451.29 3152.8 451.25C3152.53 451.1 3152.61 450.87 3152.8 450.7C3152.91 450.6 3153.12 450.45 3153.2 450.49C3153.47 450.64 3153.38 450.85 3153.2 451.04Z" fill="white"/>
                    <path d="M2997.02 584.78C2996.91 585.05 2996.86 585.29 2996.72 585.47C2996.64 585.57 2996.43 585.58 2996.28 585.63C2996.35 585.41 2996.38 585.16 2996.5 584.97C2996.57 584.87 2996.79 584.86 2997.02 584.78Z" fill="white"/>
                    <path d="M3045.65 360.37C3045.54 360.41 3045.39 360.45 3045.24 360.5C3045.31 360.28 3045.34 360.03 3045.46 359.84C3045.53 359.74 3045.75 359.73 3045.9 359.68C3045.96 359.94 3045.9 360.17 3045.66 360.37H3045.65Z" fill="white"/>
                    <path d="M3029.66 608.14C3029.45 608 3029.3 607.81 3029.54 607.6C3029.6 607.54 3029.84 607.65 3029.97 607.72C3030.19 607.84 3030.33 608.04 3030.1 608.25C3030.04 608.31 3029.81 608.18 3029.66 608.14Z" fill="white"/>
                    <path d="M3022.75 387.53C3022.54 387.39 3022.39 387.2 3022.63 386.99C3022.69 386.93 3022.93 387.04 3023.06 387.11C3023.28 387.23 3023.42 387.43 3023.19 387.64C3023.13 387.7 3022.9 387.57 3022.75 387.53Z" fill="white"/>
                    <path d="M3024.02 398.59C3023.81 398.45 3023.66 398.26 3023.9 398.05C3023.96 397.99 3024.2 398.1 3024.33 398.17C3024.55 398.29 3024.69 398.49 3024.46 398.7C3024.4 398.76 3024.17 398.63 3024.02 398.59Z" fill="white"/>
                    <path d="M3034.41 438.26C3034.3 438.53 3034.25 438.77 3034.11 438.95C3034.03 439.05 3033.82 439.06 3033.67 439.11C3033.74 438.89 3033.77 438.64 3033.89 438.45C3033.96 438.35 3034.18 438.34 3034.41 438.26Z" fill="white"/>
                    <path d="M3128.14 456.49C3127.96 456.57 3127.75 456.67 3127.54 456.76C3127.55 456.6 3127.51 456.38 3127.6 456.3C3127.76 456.14 3127.99 456.06 3128.2 455.94C3128.19 456.1 3128.17 456.26 3128.15 456.49H3128.14Z" fill="white"/>
                    <path d="M3060.76 505.74C3060.65 505.78 3060.5 505.82 3060.35 505.87C3060.42 505.65 3060.45 505.4 3060.57 505.21C3060.64 505.11 3060.86 505.1 3061.01 505.05C3061.07 505.31 3061.01 505.54 3060.77 505.74H3060.76Z" fill="white"/>
                    <path d="M3088.13 535.91C3088.02 536.18 3087.97 536.42 3087.83 536.6C3087.75 536.7 3087.54 536.71 3087.39 536.76C3087.46 536.54 3087.49 536.29 3087.61 536.1C3087.68 536 3087.9 535.99 3088.13 535.91Z" fill="white"/>
                    <path d="M3131.39 582.88C3131.06 583.01 3130.91 582.75 3130.74 582.59C3130.69 582.54 3130.73 582.26 3130.8 582.22C3131.09 582.07 3131.26 582.35 3131.43 582.5C3131.49 582.55 3131.41 582.75 3131.4 582.87L3131.39 582.88Z" fill="white"/>
                    <path d="M3075.42 570.81C3075.21 570.67 3075.06 570.48 3075.3 570.27C3075.36 570.21 3075.6 570.32 3075.73 570.39C3075.95 570.51 3076.09 570.71 3075.86 570.92C3075.8 570.98 3075.57 570.85 3075.42 570.81Z" fill="white"/>
                    <path d="M2936.19 450.06C2936.27 450.2 2936.44 450.39 2936.4 450.46C2936.25 450.73 2936.02 450.65 2935.85 450.46C2935.75 450.35 2935.6 450.14 2935.64 450.06C2935.79 449.79 2936 449.88 2936.19 450.06Z" fill="white"/>
                    <path d="M3010.77 491.95C3010.63 492.03 3010.44 492.2 3010.37 492.16C3010.1 492.01 3010.18 491.78 3010.37 491.61C3010.48 491.51 3010.69 491.36 3010.77 491.4C3011.04 491.55 3010.95 491.76 3010.77 491.95Z" fill="white"/>
                    <path d="M3002.91 503.11C3002.88 503.24 3002.87 503.37 3002.82 503.49C3002.8 503.53 3002.67 503.51 3002.58 503.52C3002.61 503.39 3002.62 503.26 3002.67 503.14C3002.69 503.1 3002.82 503.12 3002.91 503.11Z" fill="white"/>
                    <path d="M2985.34 597.7C2985.31 597.83 2985.3 597.96 2985.25 598.08C2985.23 598.12 2985.1 598.1 2985.01 598.11C2985.04 597.98 2985.05 597.85 2985.1 597.73C2985.12 597.69 2985.25 597.71 2985.34 597.7Z" fill="white"/>
                    <path d="M3030.41 407.27C3030.29 407.32 3030.17 407.37 3030.05 407.42C3030.05 407.34 3029.99 407.21 3030.03 407.18C3030.13 407.1 3030.26 407.06 3030.38 407.01C3030.38 407.09 3030.4 407.17 3030.41 407.27Z" fill="white"/>
                    <path d="M3007.24 373.43C3007.34 373.42 3007.42 373.41 3007.5 373.4C3007.47 373.53 3007.46 373.66 3007.41 373.78C3007.39 373.82 3007.26 373.8 3007.17 373.81C3007.19 373.68 3007.21 373.55 3007.23 373.43H3007.24Z" fill="white"/>
                    <path d="M3067.81 419.9C3067.76 419.78 3067.71 419.66 3067.66 419.54C3067.74 419.54 3067.87 419.48 3067.9 419.52C3067.98 419.62 3068.02 419.75 3068.07 419.87C3067.99 419.87 3067.91 419.89 3067.81 419.9Z" fill="white"/>
                    <path d="M3071.23 460.58C3071.1 460.55 3070.97 460.54 3070.85 460.49C3070.81 460.47 3070.83 460.34 3070.82 460.25C3070.95 460.28 3071.08 460.29 3071.2 460.34C3071.24 460.36 3071.22 460.49 3071.23 460.58Z" fill="white"/>
                    <path d="M3101.91 490.57C3101.9 490.47 3101.89 490.39 3101.88 490.31C3102.01 490.34 3102.14 490.35 3102.26 490.4C3102.3 490.42 3102.28 490.55 3102.29 490.64C3102.16 490.62 3102.03 490.6 3101.91 490.58V490.57Z" fill="white"/>
                    <path d="M3146.43 461.96C3146.31 462.01 3146.19 462.06 3146.07 462.11C3146.07 462.03 3146.01 461.9 3146.05 461.87C3146.15 461.79 3146.28 461.75 3146.4 461.7C3146.4 461.78 3146.42 461.86 3146.43 461.96Z" fill="white"/>
                    <path d="M3055.39 407.88C3055.29 407.89 3055.21 407.9 3055.13 407.91C3055.16 407.78 3055.17 407.65 3055.22 407.53C3055.24 407.49 3055.37 407.51 3055.46 407.5C3055.44 407.63 3055.42 407.76 3055.4 407.88H3055.39Z" fill="white"/>
                    <path d="M3046.63 473.1C3046.73 473.09 3046.81 473.08 3046.89 473.07C3046.86 473.2 3046.85 473.33 3046.8 473.45C3046.78 473.49 3046.65 473.47 3046.56 473.48C3046.58 473.35 3046.6 473.22 3046.62 473.1H3046.63Z" fill="white"/>
                    <path d="M3139.33 560.01C3139.33 560.1 3139.37 560.23 3139.35 560.24C3139.23 560.29 3139.09 560.36 3138.97 560.33C3138.73 560.28 3138.71 560.13 3138.93 560.02C3139.04 559.97 3139.19 560.01 3139.32 560.01H3139.33Z" fill="white"/>
                    <path d="M3138.65 560.38C3138.52 560.38 3138.31 560.43 3138.27 560.37C3138.15 560.19 3138.22 560.05 3138.39 560.07C3138.53 560.09 3138.66 560.16 3138.8 560.2C3138.75 560.26 3138.7 560.32 3138.65 560.38Z" fill="white"/>
                    <path d="M2976.62 498.53C2976.5 498.58 2976.38 498.63 2976.26 498.68C2976.26 498.6 2976.2 498.47 2976.24 498.44C2976.34 498.36 2976.47 498.32 2976.59 498.27C2976.59 498.35 2976.61 498.43 2976.62 498.53Z" fill="white"/>
                    <path d="M3186.28 500.34C3186.16 500.39 3186.04 500.44 3185.92 500.49C3185.92 500.41 3185.86 500.28 3185.9 500.25C3186 500.17 3186.13 500.13 3186.25 500.08C3186.25 500.16 3186.27 500.24 3186.28 500.34Z" fill="white"/>
                    <path d="M3140.76 566.88C3140.66 566.89 3140.58 566.9 3140.5 566.91C3140.53 566.78 3140.54 566.65 3140.59 566.53C3140.61 566.49 3140.74 566.51 3140.83 566.5C3140.81 566.63 3140.79 566.76 3140.77 566.88H3140.76Z" fill="white"/>
                    <path d="M3161.96 470.48C3162.08 470.43 3162.2 470.38 3162.32 470.33C3162.32 470.41 3162.38 470.54 3162.34 470.57C3162.24 470.65 3162.11 470.69 3161.99 470.74C3161.99 470.66 3161.97 470.58 3161.96 470.48Z" fill="white"/>
                    <path d="M3052.94 539.1C3052.82 539.15 3052.7 539.2 3052.58 539.25C3052.58 539.17 3052.52 539.04 3052.56 539.01C3052.66 538.93 3052.79 538.89 3052.91 538.84C3052.91 538.92 3052.93 539 3052.94 539.1Z" fill="white"/>
                    <path d="M3095.15 424.88C3095.14 424.78 3095.13 424.7 3095.12 424.62C3095.25 424.65 3095.38 424.66 3095.5 424.71C3095.54 424.73 3095.52 424.86 3095.53 424.95C3095.4 424.93 3095.27 424.91 3095.15 424.89V424.88Z" fill="white"/>
                    <path d="M3111.47 557.91C3111.44 558.04 3111.43 558.17 3111.38 558.29C3111.36 558.33 3111.23 558.31 3111.14 558.32C3111.17 558.19 3111.18 558.06 3111.23 557.94C3111.25 557.9 3111.38 557.92 3111.47 557.91Z" fill="white"/>
                    <path d="M3105.45 430.06C3105.33 430.11 3105.21 430.16 3105.09 430.21C3105.09 430.13 3105.03 430 3105.07 429.97C3105.17 429.89 3105.3 429.85 3105.42 429.8L3105.45 430.06Z" fill="white"/>
                    <path d="M3020.53 488.4C3020.58 488.52 3020.63 488.64 3020.68 488.76C3020.6 488.76 3020.47 488.82 3020.44 488.78C3020.36 488.68 3020.32 488.55 3020.27 488.43C3020.35 488.43 3020.43 488.41 3020.53 488.4Z" fill="white"/>
                    <path d="M2900.87 462.87C2900.84 463 2900.83 463.13 2900.78 463.25C2900.76 463.29 2900.63 463.27 2900.54 463.28C2900.57 463.15 2900.58 463.02 2900.63 462.9C2900.65 462.86 2900.78 462.88 2900.87 462.87Z" fill="white"/>
                    <path d="M3221.99 405.14C3223.45 406.11 3223.26 407.46 3223.05 408.74C3222.97 409.21 3222.75 409.91 3222.8 410.16C3223.11 411.79 3221.21 411.29 3220.95 412.27C3220.04 411.87 3218.82 411.69 3218.31 410.99C3217.83 410.33 3217.3 409.45 3217.15 408.55C3217 407.7 3216.41 406.57 3218.07 406.39C3218.32 406.36 3218.49 405.85 3218.73 405.58C3219.79 404.41 3220.9 404.29 3221.99 405.14Z" fill="white"/>
                    <path d="M3083.21 434.63C3083.86 433.82 3084.96 433.75 3085.24 433.98C3086.33 434.87 3087.57 434.21 3088.7 434.58C3089.5 434.84 3089.94 435.12 3089.99 435.93C3090.07 437.26 3089.78 437.81 3088.84 438.07C3088.43 438.18 3088.06 438.33 3087.67 438.5C3086 439.23 3082.72 437.5 3082.98 435.42C3083.03 435.05 3083.06 434.74 3083.2 434.63H3083.21Z" fill="white"/>
                    <path d="M3157.86 474.63C3158.77 474.55 3159.43 473.39 3160.48 474.09C3160.63 474.19 3160.75 474.37 3160.91 474.41C3161.57 474.54 3162.32 474.45 3162.67 475.22C3162.94 475.83 3162.62 476.4 3162.5 476.97C3162.27 478.11 3160.9 478.99 3159.63 478.88C3158.64 478.79 3158.04 478.28 3157.64 477.35C3157.22 476.38 3157.74 475.54 3157.86 474.63Z" fill="white"/>
                    <path d="M3135.08 598.53C3134.12 599.76 3133.23 599.96 3132.27 599.38C3131.74 599.06 3131.29 598.6 3130.77 598.25C3130.28 597.91 3130.3 597.6 3130.66 597.18C3131.07 596.71 3131.37 596.13 3131.83 595.71C3132.61 595.01 3133.58 594.9 3134.58 595.14C3134.78 595.19 3135.06 595.45 3135.08 595.64C3135.16 596.34 3135.46 596.94 3135.67 597.59C3135.77 597.9 3135.15 598.44 3135.08 598.54V598.53Z" fill="white"/>
                    <path d="M3032.64 540.7C3033.02 540.11 3033.56 539.25 3034.6 539.69C3035.1 539.9 3036.02 540.29 3035.79 540.92C3035.53 541.6 3035.81 542.58 3034.93 542.98C3034.32 543.26 3033.58 543.34 3033.08 543.73C3032.62 544.09 3032.4 544.07 3031.98 543.76C3031.38 543.31 3031.32 542.71 3031.56 542.14C3031.76 541.66 3032.19 541.28 3032.64 540.7Z" fill="white"/>
                    <path d="M3149.45 611.66C3149.87 611.09 3150.68 610.95 3151.26 611.34C3151.45 611.47 3152.44 613.82 3152.39 614.03C3152.27 614.5 3150.72 615.05 3150.73 614.96C3150.81 613.83 3149.61 614.51 3149.36 613.92C3149.13 613.36 3149.18 612.02 3149.45 611.66Z" fill="white"/>
                    <path d="M3215.85 466.46C3215.56 466.22 3215.05 465.95 3215.26 465.67C3215.66 465.14 3215.86 464.39 3216.73 464.27C3217.21 464.2 3217.56 464.55 3217.95 464.51C3218.59 464.43 3218.91 464.57 3219.04 465.27C3219.18 466.02 3218.85 466.59 3218.28 466.75C3217.48 466.97 3216.54 467.32 3215.84 466.47L3215.85 466.46Z" fill="white"/>
                    <path d="M3122.75 473.4C3122.38 473.82 3122.16 474.58 3121.34 473.89C3121.16 473.73 3120.66 473.97 3120.31 473.99C3119.91 474.02 3119.5 474.01 3119.1 474.02C3119.42 473.51 3119.62 472.79 3120.08 472.54C3120.67 472.23 3121.47 472.33 3122.17 472.2C3122.55 472.13 3122.92 472.14 3123.11 472.48C3123.31 472.85 3122.96 473.12 3122.76 473.39L3122.75 473.4Z" fill="white"/>
                    <path d="M3241.75 553.88C3241.64 554.02 3241.55 554.17 3241.43 554.29C3241.15 554.56 3241.04 555.18 3240.47 554.92C3239.89 554.65 3239.57 554.17 3239.57 553.48C3239.57 552.89 3239.9 552.8 3240.37 552.7C3240.67 552.64 3240.92 552.32 3241.22 552.25C3241.62 552.16 3242.09 551.96 3242.43 552.38C3242.83 552.88 3242.27 553.13 3242.08 553.47C3241.99 553.62 3241.87 553.75 3241.76 553.89L3241.75 553.88Z" fill="white"/>
                    <path d="M3140.02 614.24C3140.34 615 3140.9 615.69 3140.84 616.58C3140.83 616.78 3140.63 617.06 3140.29 617.06C3139.87 617.06 3139.34 617.36 3139.08 616.9C3138.76 616.33 3138.55 615.68 3138.37 615.04C3138.15 614.28 3138.97 614.74 3139.16 614.41C3139.36 614.07 3139.64 613.91 3140.02 614.24Z" fill="white"/>
                    <path d="M3147.36 437.13C3147.13 437.56 3146.82 437.7 3146.36 437.31C3145.66 436.71 3145.54 436 3146.02 435.33C3146.44 434.73 3147.05 434.69 3147.6 434.89C3148.24 435.12 3148.11 436.21 3147.36 437.13Z" fill="white"/>
                    <path d="M3122.03 360.59C3121.87 360.8 3121.73 361.05 3121.53 361.22C3121.06 361.61 3120.37 361.32 3120.29 360.72C3120.24 360.37 3120.13 360 3120.17 359.66C3120.22 359.22 3120.52 358.77 3120.92 358.68C3121.37 358.57 3121.83 359.07 3122.36 358.88C3122.36 359.38 3122.72 359.93 3122.19 360.37C3122.12 360.43 3122.08 360.51 3122.02 360.58L3122.03 360.59Z" fill="white"/>
                    <path d="M3164.59 570.9C3164.9 570.86 3165.17 570.84 3165.43 570.79C3166.09 570.66 3166.33 571.63 3167.06 571.37C3167.45 571.23 3167.77 571.48 3167.77 571.89C3167.77 572.28 3167.49 572.47 3167.1 572.51C3166.57 572.56 3166.05 572.67 3165.52 572.69C3165.27 572.7 3164.84 573.06 3164.78 572.43C3164.73 571.92 3164.65 571.41 3164.59 570.9Z" fill="white"/>
                    <path d="M3058.02 601.99C3057.63 601.58 3057.75 600.56 3058.24 600.13C3058.44 599.96 3060.09 600.09 3060.28 600.34C3060.76 600.98 3060.06 601.27 3059.79 601.61C3059.28 602.25 3058.4 602.38 3058.02 601.99Z" fill="white"/>
                    <path d="M3064.84 527.6C3065.32 527.87 3065.51 528.17 3065.12 528.64C3064.63 529.21 3063.77 529.48 3063.35 529.15C3062.93 528.82 3062.96 527.89 3063.42 527.3C3064.11 526.42 3064.41 527.45 3064.85 527.59L3064.84 527.6Z" fill="white"/>
                    <path d="M3062.46 521.49C3062.92 521.31 3063.41 521.1 3063.88 521.41C3064.39 521.75 3064.24 522.97 3063.67 523.44C3063.3 523.75 3063.04 523.51 3062.79 523.28C3062.21 522.74 3062.05 521.89 3062.45 521.49H3062.46Z" fill="white"/>
                    <path d="M3132.69 564.58C3132.48 564.86 3132.35 564.99 3132.26 565.14C3131.93 565.7 3131.33 565.49 3130.86 565.63C3130.87 565.14 3130.51 564.61 3130.98 564.15C3131.1 564.03 3131.21 563.88 3131.3 563.74C3131.62 563.24 3131.91 562.99 3132.53 563.48C3133.12 563.95 3133.07 564.29 3132.69 564.59V564.58Z" fill="white"/>
                    <path d="M3021.05 457.66C3021.57 458.01 3021.42 458.42 3021.12 458.58C3020.53 458.9 3019.88 459.1 3019.24 459.3C3018.64 459.49 3018.83 458.95 3018.76 458.69C3018.44 457.54 3019.65 457.9 3020.04 457.42C3020.49 456.87 3020.76 457.61 3021.05 457.66Z" fill="white"/>
                    <path d="M3102.02 554.03C3102.36 553.36 3102.98 553.31 3103.54 553.5C3103.96 553.64 3104.11 555.11 3103.77 555.26C3103.12 555.54 3102.52 555.41 3101.97 554.91C3101.59 554.56 3101.79 554.31 3102.01 554.02L3102.02 554.03Z" fill="white"/>
                    <path d="M3254.47 494.25C3254.83 494.64 3254.81 495.15 3254.7 495.64C3254.64 495.93 3253.16 495.9 3252.7 495.6C3252.28 495.33 3252.45 495.03 3252.68 494.75C3253.15 494.18 3254.03 493.92 3254.47 494.25Z" fill="white"/>
                    <path d="M3114.45 589.72C3114.29 589.5 3114.2 589.19 3114.49 589.06C3115.17 588.75 3115.78 588.26 3116.59 588.2C3116.93 588.17 3117.17 588.34 3117.25 588.53C3117.32 588.71 3117.21 589.13 3117.06 589.21C3116.46 589.51 3115.82 589.71 3115.18 589.94C3114.91 590.04 3114.66 589.95 3114.45 589.74V589.72Z" fill="white"/>
                    <path d="M3107.69 618.12C3107.84 617.88 3108.03 617.58 3108.46 617.79C3108.83 617.97 3108.77 618.35 3108.87 618.67C3109.07 619.32 3108.59 619.66 3108.25 619.99C3108.05 620.18 3107.39 620.26 3107.29 620.13C3107.07 619.85 3106.99 619.37 3107.05 619.01C3107.1 618.71 3107.44 618.45 3107.69 618.13V618.12Z" fill="white"/>
                    <path d="M3013.14 505.36C3012.75 505.15 3012.62 504.84 3013.01 504.39C3013.5 503.83 3014.35 503.57 3014.77 503.89C3015.49 504.42 3014.32 504.93 3014.74 505.52C3014.81 505.62 3013.71 505.74 3013.14 505.36Z" fill="white"/>
                    <path d="M3176.41 537.96C3176.57 537.92 3176.76 537.8 3176.87 537.85C3177.51 538.14 3177.88 539.64 3177.43 540.14C3177.28 540.31 3177 540.52 3176.82 540.48C3176.64 540.44 3176.43 540.16 3176.37 539.94C3176.1 538.99 3176.14 538.32 3176.4 537.96H3176.41Z" fill="white"/>
                    <path d="M3202.36 441.06C3202.15 440.9 3201.91 440.77 3201.74 440.57C3201.31 440.06 3201.87 439.81 3202.07 439.48C3202.33 439.05 3202.64 439.23 3202.93 439.46C3203.19 439.67 3203.5 439.83 3203.53 440.25C3203.55 440.68 3203.74 441.16 3203.27 441.42C3202.9 441.62 3202.64 441.25 3202.37 441.06H3202.36Z" fill="white"/>
                    <path d="M3114.89 586.76C3114.47 587.13 3114.21 586.86 3113.96 586.63C3113.38 586.07 3113.22 585.24 3113.64 584.85C3113.99 584.53 3114.27 584.79 3114.52 585.03C3115.1 585.59 3115.24 586.41 3114.89 586.77V586.76Z" fill="white"/>
                    <path d="M3008.2 511.09C3008.14 510.47 3007.94 509.91 3008.47 509.36C3008.9 508.91 3009.16 509.08 3009.54 509.36C3009.95 509.66 3010.07 509.89 3009.77 510.4C3009.38 511.07 3008.76 510.93 3008.2 511.09Z" fill="white"/>
                    <path d="M3013.41 498.59C3013.56 498.54 3013.77 498.39 3013.87 498.45C3014.17 498.62 3014.58 498.81 3014.67 499.08C3014.83 499.53 3015.07 500.08 3014.63 500.53C3014.29 500.88 3014.01 500.61 3013.76 500.37C3013.17 499.8 3013.03 499.02 3013.42 498.59H3013.41Z" fill="white"/>
                    <path d="M3236.49 453.99C3236.72 453.71 3236.98 453.41 3237.32 453.77C3237.76 454.25 3237.91 454.87 3237.44 455.35C3237.19 455.6 3236.65 455.56 3236.24 455.65C3236.19 455.07 3235.9 454.46 3236.49 453.99Z" fill="white"/>
                    <path d="M3104.49 401.55C3104.97 401.98 3105.05 402.97 3104.71 403.28C3104.36 403.6 3104.12 403.31 3103.84 403.12C3103.28 402.74 3103.15 402.17 3103.43 401.66C3103.51 401.51 3104.21 401.35 3104.49 401.56V401.55Z" fill="white"/>
                    <path d="M3083.06 549.48C3083.33 549.13 3083.64 548.77 3083.92 549.16C3084.26 549.64 3084.43 550.26 3084.67 550.81C3084.07 550.93 3083.52 551 3082.99 550.48C3082.49 549.99 3082.91 549.74 3083.05 549.48H3083.06Z" fill="white"/>
                    <path d="M3039.82 422.09C3040.2 421.46 3040.51 421.64 3040.94 421.92C3041.61 422.35 3041.38 422.71 3041.02 423.18C3040.65 423.67 3040.37 423.53 3039.95 423.24C3039.32 422.81 3039.39 422.43 3039.82 422.09Z" fill="white"/>
                    <path d="M3086.92 560.28C3087.24 560.63 3088.06 560.82 3087.41 561.45C3087.05 561.8 3086.47 561.89 3085.99 561.6C3085.2 561.11 3086.01 560.49 3086.06 560.02C3086.13 559.44 3086.59 560.25 3086.92 560.28Z" fill="white"/>
                    <path d="M3111.92 635.57C3112.08 635.36 3112.21 635.13 3112.41 634.96C3112.76 634.65 3113.03 634.92 3113.29 635.15C3113.47 635.31 3113.79 635.39 3113.74 635.72C3113.68 636.1 3113.45 636.31 3113.05 636.32C3112.88 636.32 3112.7 636.37 3112.53 636.38C3112.2 636.41 3111.68 636.58 3111.57 636.43C3111.32 636.11 3111.75 635.84 3111.92 635.56V635.57Z" fill="white"/>
                    <path d="M3153.85 387.93C3153.48 387.41 3153.99 387.15 3154.21 386.84C3154.63 386.26 3154.95 386.78 3155.27 386.99C3155.57 387.19 3155.84 387.44 3155.48 387.82C3155.12 388.2 3154.17 388.26 3153.85 387.93Z" fill="white"/>
                    <path d="M3062.86 496.75C3063.22 497.05 3063.51 497.28 3063.15 497.66C3062.79 498.04 3061.82 498.11 3061.52 497.76C3061.21 497.4 3061.54 497.16 3061.7 496.87C3062.1 496.16 3062.51 496.43 3062.86 496.75Z" fill="white"/>
                    <path d="M3094.7 595.63C3094.59 595.48 3094.3 595.26 3094.33 595.1C3094.42 594.68 3094.89 594.81 3095.14 594.85C3095.51 594.9 3096.18 594.18 3096.25 595.2C3096.27 595.54 3096.39 595.9 3096.07 596.14C3095.41 596.63 3095.23 595.77 3094.71 595.63H3094.7Z" fill="white"/>
                    <path d="M3224.35 546.44C3224.75 546.32 3225.39 545.89 3225.54 546.41C3225.66 546.86 3226.38 547.09 3226.03 547.63C3225.82 547.95 3224.46 547.57 3224.2 547.13C3224.06 546.9 3224.12 546.64 3224.35 546.44Z" fill="white"/>
                    <path d="M3054.12 430.76C3054.12 430.76 3054.16 430.28 3054.26 429.86C3054.3 429.68 3055.53 429.75 3055.69 429.94C3056.12 430.45 3055.56 430.69 3055.37 431.02C3055.13 431.42 3054.83 431.33 3054.12 430.76Z" fill="white"/>
                    <path d="M3076.79 408.58C3077.31 408.2 3077.54 408.73 3077.87 408.93C3078.29 409.18 3078.2 409.58 3077.89 409.75C3077.54 409.95 3077.09 410 3076.67 410.02C3076.52 410.03 3076.6 408.73 3076.78 408.58H3076.79Z" fill="white"/>
                    <path d="M3240.63 411.4C3241.06 411.61 3241.07 411.9 3240.91 412.1C3240.49 412.64 3239.92 412.66 3239.32 412.4C3239.01 412.26 3239.01 411.97 3239.16 411.76C3239.58 411.18 3240.16 411.23 3240.63 411.4Z" fill="white"/>
                    <path d="M3183.41 557.2C3183.01 556.79 3182.9 556.24 3183.18 555.88C3183.32 555.7 3183.96 555.55 3184.37 555.88C3184.92 556.33 3184.43 556.62 3184.22 556.94C3184.02 557.24 3183.78 557.47 3183.41 557.2Z" fill="white"/>
                    <path d="M3161.42 371.98C3161.64 372.1 3162.01 373.31 3161.86 373.34C3161.45 373.41 3161 373.48 3160.62 373.37C3160.26 373.27 3160.14 372.9 3160.46 372.57C3160.73 372.3 3160.82 371.71 3161.42 371.98Z" fill="white"/>
                    <path d="M3181.46 562.33C3181.87 562.05 3182.42 561.85 3182.97 562.31C3183.17 562.48 3183.24 562.76 3182.97 562.97C3182.46 563.38 3181.91 563.5 3181.36 563.08C3181.16 562.92 3181.1 562.64 3181.46 562.33Z" fill="white"/>
                    <path d="M3219.93 608.57C3220.21 608.16 3220.59 608.23 3220.77 608.54C3220.97 608.89 3221.02 609.34 3221.04 609.76C3221.05 609.91 3219.75 609.83 3219.6 609.65C3219.38 609.39 3219.51 608.98 3219.93 608.58V608.57Z" fill="white"/>
                    <path d="M3088.2 556.05C3088.44 555.76 3088.66 555.47 3089.04 555.81C3089.45 556.18 3089.54 556.74 3089.26 557.1C3089.12 557.28 3088.48 557.43 3088.07 557.1C3087.52 556.65 3088.04 556.37 3088.2 556.05Z" fill="white"/>
                    <path d="M3139.14 624.23C3139.6 624.02 3140.04 623.87 3140.51 624.16C3140.79 624.33 3140.83 624.61 3140.64 624.8C3140.33 625.1 3140.24 625.9 3139.51 625.43C3139.06 625.14 3139.31 624.63 3139.14 624.24V624.23Z" fill="white"/>
                    <path d="M3100.49 554.26C3100.64 554.22 3100.8 554.17 3100.95 554.13C3101.07 554.89 3101.05 555.7 3101.6 556.34C3101.62 556.37 3101.42 556.65 3101.28 556.74C3101.2 556.79 3100.91 556.69 3100.91 556.65C3100.89 555.94 3100.1 555.48 3100.27 554.72C3100.3 554.56 3100.42 554.42 3100.49 554.27V554.26Z" fill="white"/>
                    <path d="M3104.29 556.02C3104.69 556.37 3104.04 556.73 3103.76 557.03C3103.2 557.63 3102.91 556.86 3102.52 556.65C3102.41 556.59 3102.2 556.06 3102.64 556.22C3103.29 556.45 3103.35 555.11 3104.29 556.02Z" fill="white"/>
                    <path d="M3198.73 418.5C3198.16 418.19 3198.39 417.6 3198.25 417.13C3198.58 417.08 3199.04 416.87 3199.21 417.01C3199.59 417.32 3199.6 417.83 3199.37 418.3C3199.23 418.58 3198.99 418.58 3198.73 418.5Z" fill="white"/>
                    <path d="M3055.08 436.95C3055.34 437.23 3055.91 437.37 3055.48 437.95C3055.17 438.37 3054.89 438.18 3054.61 437.95C3054.31 437.69 3053.77 437.53 3054.19 436.95C3054.5 436.53 3054.77 436.74 3055.07 436.95H3055.08Z" fill="white"/>
                    <path d="M3254.58 463.33C3255.42 463.04 3255.95 463.25 3256.38 463.73C3256.48 463.84 3256.49 464.03 3256.55 464.19C3256.38 464.21 3256.19 464.2 3256.04 464.26C3255.43 464.49 3255.06 464.08 3254.69 463.75C3254.58 463.65 3254.6 463.39 3254.59 463.34L3254.58 463.33Z" fill="white"/>
                    <path d="M3036.46 416.67C3036.53 416.56 3036.84 416.71 3037.12 416.96C3037.41 417.22 3037.97 417.37 3037.55 417.96C3037.25 418.37 3036.96 418.22 3036.69 417.97C3036.39 417.71 3035.85 417.54 3036.47 416.67H3036.46Z" fill="white"/>
                    <path d="M3059.9 468.7C3059.94 468.81 3060.07 468.99 3060.02 469.06C3059.7 469.57 3059.37 470.06 3058.63 470.02C3058.33 470 3058.16 469.96 3058.22 469.66C3058.33 469.2 3059.49 468.54 3059.89 468.7H3059.9Z" fill="white"/>
                    <path d="M3153.98 423.28C3154.66 422.75 3154.85 423.58 3155.26 423.79C3155.44 423.88 3155.32 424.29 3155.1 424.45C3154.45 424.93 3154.2 424.2 3153.82 423.95C3153.64 423.83 3153.76 423.47 3153.99 423.28H3153.98Z" fill="white"/>
                    <path d="M3101.82 513.75C3102.5 513.22 3102.69 514.05 3103.1 514.26C3103.28 514.35 3103.16 514.76 3102.94 514.92C3102.29 515.4 3102.04 514.67 3101.66 514.42C3101.48 514.3 3101.6 513.94 3101.83 513.75H3101.82Z" fill="white"/>
                    <path d="M3221.9 523.11C3221.97 523 3222.28 523.15 3222.56 523.4C3222.85 523.66 3223.41 523.81 3222.99 524.4C3222.69 524.81 3222.4 524.66 3222.13 524.41C3221.83 524.15 3221.29 523.98 3221.91 523.11H3221.9Z" fill="white"/>
                    <path d="M3050.55 562.76C3050.46 563.31 3050.37 563.89 3050.27 564.45C3050.27 564.48 3050.09 564.51 3050.03 564.48C3049.69 564.32 3049.55 564.04 3049.53 563.66C3049.5 563.23 3049.4 562.79 3049.91 562.59C3050.03 562.54 3050.21 562.66 3050.56 562.75L3050.55 562.76Z" fill="white"/>
                    <path d="M3097.81 577.55C3097.51 577.35 3097.18 577.16 3097.43 576.73C3097.75 576.19 3098.11 576.38 3098.48 576.7C3098.74 576.93 3099.1 577.1 3098.85 577.52C3098.53 578.06 3098.16 577.9 3097.81 577.55Z" fill="white"/>
                    <path d="M3152.2 581.54C3152.61 581.25 3152.84 581.15 3153.05 581.41C3153.46 581.92 3153.57 582.47 3153.16 583.02C3153.08 583.13 3152.86 583.13 3152.71 583.19C3152.53 582.62 3152.36 582.05 3152.2 581.54Z" fill="white"/>
                    <path d="M3073.93 508.44C3074.17 508.02 3074.47 507.79 3074.64 507.94C3074.97 508.24 3075.74 508.31 3075.45 509.06C3075.35 509.32 3075.03 509.53 3074.84 509.39C3074.47 509.12 3073.85 508.96 3073.93 508.45V508.44Z" fill="white"/>
                    <path d="M3225.84 505.69C3226.08 505.41 3226.29 505.14 3226.69 505.47C3227.24 505.92 3226.76 506.22 3226.54 506.54C3226.34 506.84 3226.1 507.09 3225.7 506.76C3225.14 506.31 3225.65 506.02 3225.83 505.69H3225.84Z" fill="white"/>
                    <path d="M3161.43 455.74C3160.9 455.06 3161.73 454.87 3161.94 454.46C3162.03 454.28 3162.44 454.4 3162.6 454.62C3163.08 455.27 3162.35 455.52 3162.1 455.9C3161.98 456.08 3161.62 455.96 3161.43 455.73V455.74Z" fill="white"/>
                    <path d="M3147.87 590.34C3147.57 590.14 3147.24 589.95 3147.49 589.52C3147.81 588.98 3148.17 589.17 3148.54 589.49C3148.8 589.72 3149.16 589.89 3148.91 590.31C3148.59 590.85 3148.22 590.69 3147.87 590.34Z" fill="white"/>
                    <path d="M3021.81 509.29C3021.29 508.96 3021.28 508.72 3021.43 508.48C3021.77 507.94 3022.12 508.15 3022.47 508.48C3022.65 508.65 3023.08 508.91 3022.89 509.02C3022.52 509.25 3022.01 509.25 3021.81 509.29Z" fill="white"/>
                    <path d="M3234.76 472.95C3234.89 472.88 3235.1 472.78 3235.32 472.67C3235.36 473.15 3235.69 473.66 3235.24 474.11C3235.05 474.3 3234.83 474.33 3234.57 474.12C3234.03 473.67 3234.5 473.39 3234.76 472.95Z" fill="white"/>
                    <path d="M3139.97 643.47C3140.18 643.58 3140.21 643.84 3140.07 644.07C3139.89 644.37 3139.61 644.65 3139.26 644.29C3139.01 644.04 3138.32 643.93 3138.69 643.43C3138.82 643.25 3139.48 643.11 3139.97 643.46V643.47Z" fill="white"/>
                    <path d="M3005.46 545.89C3005.78 545.35 3006.37 545.57 3006.84 545.44C3006.77 545.67 3006.76 545.94 3006.62 546.13C3006.39 546.44 3006.22 546.97 3005.64 546.56C3005.38 546.37 3005.37 546.17 3005.46 545.89Z" fill="white"/>
                    <path d="M3155.99 617.75C3156.33 617.91 3156.03 617.66 3156.01 618.01C3156.01 618.2 3155.76 618.5 3155.59 618.53C3155.12 618.61 3154.58 618.89 3154.17 618.36C3154.12 618.3 3154.1 618.14 3154.13 618.12C3154.69 617.71 3155.38 617.92 3155.99 617.75Z" fill="white"/>
                    <path d="M3155.05 567.52C3155.55 567.49 3156.11 567.19 3156.47 567.75C3156.56 567.88 3156.43 568.28 3156.27 568.41C3155.79 568.84 3155.53 568.27 3155.2 568.08C3154.97 567.95 3154.85 567.77 3155.05 567.52Z" fill="white"/>
                    <path d="M3129.35 485.14C3129.46 485 3129.54 484.82 3129.68 484.74C3129.77 484.68 3130.03 484.71 3130.07 484.78C3130.22 485.03 3129.22 486.36 3128.9 486.33C3128.82 486.33 3128.68 486.05 3128.72 486C3128.9 485.7 3129.14 485.43 3129.35 485.15V485.14Z" fill="white"/>
                    <path d="M3242.45 577.66C3242.76 577.2 3243.07 577.33 3243.34 577.59C3243.53 577.78 3243.56 578 3243.36 578.26C3243.05 578.65 3242.76 578.53 3242.5 578.27C3242.32 578.09 3242.2 577.83 3242.45 577.65V577.66Z" fill="white"/>
                    <path d="M3009.19 528.72C3009.43 528.55 3009.62 528.49 3009.88 528.7C3010.27 529.01 3010.15 529.29 3009.89 529.56C3009.7 529.76 3009.48 529.78 3009.22 529.58C3008.83 529.27 3008.98 529.01 3009.19 528.72Z" fill="white"/>
                    <path d="M3151.4 391.34C3151.64 391.17 3151.83 391.11 3152.09 391.32C3152.48 391.63 3152.36 391.91 3152.1 392.18C3151.91 392.38 3151.69 392.4 3151.43 392.2C3151.04 391.89 3151.19 391.63 3151.4 391.34Z" fill="white"/>
                    <path d="M3238.47 445.11C3238.73 445.32 3238.95 445.54 3238.64 445.94C3238.44 446.21 3238.21 446.23 3237.98 446.09C3237.66 445.9 3237.47 445.65 3237.77 445.25C3237.97 444.99 3238.17 444.98 3238.46 445.11H3238.47Z" fill="white"/>
                    <path d="M3033.3 436.38C3033.07 435.94 3032.95 435.5 3033.21 435.04C3033.28 434.92 3033.59 434.79 3033.59 434.8C3033.88 435.21 3033.99 435.66 3033.71 436.11C3033.64 436.23 3033.45 436.28 3033.29 436.37L3033.3 436.38Z" fill="white"/>
                    <path d="M3116.26 387.46C3116.4 387.17 3116.67 387.18 3116.9 387.32C3117.22 387.51 3117.41 387.76 3117.11 388.16C3116.91 388.43 3116.69 388.45 3116.45 388.31C3116.13 388.12 3115.93 387.85 3116.26 387.46Z" fill="white"/>
                    <path d="M3070.73 484.32C3070.97 484.15 3071.16 484.09 3071.42 484.3C3071.81 484.61 3071.69 484.89 3071.43 485.16C3071.24 485.36 3071.02 485.38 3070.76 485.18C3070.37 484.87 3070.52 484.61 3070.73 484.32Z" fill="white"/>
                    <path d="M3198.07 617.46C3198.34 617.26 3198.54 617.21 3198.8 617.41C3199.19 617.72 3199.07 618 3198.81 618.27C3198.62 618.47 3198.4 618.49 3198.14 618.29C3197.73 617.98 3197.93 617.71 3198.07 617.46Z" fill="white"/>
                    <path d="M3178.33 607.73C3178.75 608.02 3178.62 608.33 3178.36 608.6C3178.17 608.79 3177.95 608.82 3177.69 608.62C3177.3 608.31 3177.42 608.03 3177.68 607.76C3177.87 607.57 3178.12 607.5 3178.33 607.74V607.73Z" fill="white"/>
                    <path d="M3031.74 399.02C3031.52 399.29 3031.29 399.57 3030.92 399.22C3030.81 399.11 3030.74 398.96 3030.66 398.82C3031.03 398.72 3031.4 398.62 3031.77 398.52C3031.76 398.68 3031.75 398.84 3031.74 399.01V399.02Z" fill="white"/>
                    <path d="M3078.28 412.81C3078.5 412.54 3078.73 412.26 3079.1 412.61C3079.21 412.72 3079.28 412.87 3079.36 413.01C3078.99 413.11 3078.62 413.21 3078.25 413.31C3078.26 413.15 3078.27 412.99 3078.28 412.82V412.81Z" fill="white"/>
                    <path d="M3029.57 527.18C3029.93 527.13 3030.27 527.07 3030.61 527.02C3030.54 527.25 3030.54 527.54 3030.4 527.7C3030.08 528.05 3029.78 527.79 3029.55 527.54C3029.49 527.47 3029.56 527.28 3029.57 527.18Z" fill="white"/>
                    <path d="M3167.46 460.39C3167.57 460.57 3167.76 460.78 3167.72 460.83C3167.45 461.21 3166.99 461 3166.62 461.12C3166.63 460.96 3166.6 460.77 3166.67 460.64C3166.86 460.32 3167.12 460.14 3167.46 460.39Z" fill="white"/>
                    <path d="M3236.19 410.4C3236.32 410.36 3236.49 410.24 3236.57 410.29C3236.87 410.45 3237.2 410.68 3236.93 411.07C3236.81 411.25 3236.53 411.32 3236.32 411.44C3236.28 411.1 3236.23 410.76 3236.19 410.4Z" fill="white"/>
                    <path d="M3210.34 417.26C3209.94 416.98 3210.11 416.68 3210.36 416.42C3210.46 416.32 3210.66 416.31 3210.81 416.25C3210.81 416.63 3210.81 417.02 3210.81 417.4C3210.66 417.35 3210.51 417.3 3210.35 417.25L3210.34 417.26Z" fill="white"/>
                    <path d="M3048.98 517.06C3049.05 517.03 3049.21 516.91 3049.29 516.95C3049.59 517.11 3049.92 517.33 3049.67 517.73C3049.55 517.91 3049.28 517.99 3049.07 518.11C3049.04 517.77 3049.01 517.43 3048.98 517.06Z" fill="white"/>
                    <path d="M3071.46 490.43C3071.74 490.03 3072.04 490.2 3072.3 490.45C3072.4 490.55 3072.41 490.75 3072.47 490.9C3072.09 490.9 3071.7 490.9 3071.32 490.9C3071.37 490.75 3071.42 490.6 3071.47 490.44L3071.46 490.43Z" fill="white"/>
                    <path d="M3182.23 383.16C3182.39 383.1 3182.54 383.04 3182.69 382.98C3182.71 383.36 3182.73 383.74 3182.74 384.13C3182.59 384.09 3182.41 384.08 3182.28 384C3181.87 383.75 3182.01 383.45 3182.23 383.16Z" fill="white"/>
                    <path d="M3183.52 591.32C3183.12 591.04 3183.29 590.74 3183.54 590.48C3183.64 590.38 3183.84 590.37 3183.99 590.31C3183.99 590.69 3183.99 591.08 3183.99 591.46C3183.84 591.41 3183.69 591.36 3183.53 591.31L3183.52 591.32Z" fill="white"/>
                    <path d="M3214.07 612.23C3214.1 612.01 3214.07 611.82 3214.14 611.69C3214.34 611.37 3214.61 611.18 3214.95 611.52C3215.06 611.63 3215.11 611.79 3215.19 611.93C3214.82 612.03 3214.45 612.13 3214.06 612.23H3214.07Z" fill="white"/>
                    <path d="M3017.28 580.99C3017.62 580.85 3017.77 581.12 3017.96 581.28C3018.02 581.33 3017.98 581.62 3017.91 581.66C3017.61 581.82 3017.44 581.54 3017.26 581.38C3017.2 581.33 3017.28 581.12 3017.29 581L3017.28 580.99Z" fill="white"/>
                    <path d="M3019.85 557.62C3019.96 557.34 3020.01 557.09 3020.14 556.91C3020.22 556.8 3020.44 556.79 3020.59 556.74C3020.52 556.97 3020.5 557.23 3020.38 557.42C3020.31 557.53 3020.09 557.54 3019.85 557.62Z" fill="white"/>
                    <path d="M3254.92 525.49C3255.26 525.35 3255.41 525.62 3255.6 525.78C3255.66 525.83 3255.62 526.12 3255.55 526.16C3255.25 526.32 3255.08 526.04 3254.9 525.88C3254.84 525.83 3254.92 525.62 3254.93 525.5L3254.92 525.49Z" fill="white"/>
                    <path d="M3233.75 427.44C3234.03 427.55 3234.28 427.6 3234.46 427.73C3234.57 427.81 3234.58 428.03 3234.63 428.18C3234.4 428.11 3234.14 428.09 3233.95 427.97C3233.84 427.9 3233.83 427.68 3233.75 427.44Z" fill="white"/>
                    <path d="M3231.26 425.96C3231.36 426.06 3231.57 426.19 3231.7 426.38C3231.86 426.6 3231.74 426.76 3231.49 426.65C3231.28 426.56 3231.07 426.4 3230.93 426.22C3230.77 426.01 3230.88 425.88 3231.25 425.96H3231.26Z" fill="white"/>
                    <path d="M3269.41 518.04C3269.33 517.9 3269.15 517.71 3269.19 517.63C3269.34 517.35 3269.57 517.43 3269.75 517.63C3269.86 517.75 3270.02 517.96 3269.98 518.03C3269.83 518.31 3269.61 518.22 3269.41 518.03V518.04Z" fill="white"/>
                    <path d="M3015.09 527.27C3015.23 527.19 3015.42 527.01 3015.5 527.05C3015.78 527.2 3015.7 527.43 3015.5 527.61C3015.38 527.72 3015.17 527.88 3015.1 527.84C3014.82 527.69 3014.91 527.47 3015.1 527.27H3015.09Z" fill="white"/>
                    <path d="M3173.62 387.63C3173.73 387.35 3173.78 387.1 3173.91 386.92C3173.99 386.81 3174.21 386.8 3174.36 386.75C3174.29 386.98 3174.27 387.24 3174.15 387.43C3174.08 387.54 3173.86 387.55 3173.62 387.63Z" fill="white"/>
                    <path d="M3126.89 618.87C3127 618.83 3127.16 618.78 3127.31 618.73C3127.24 618.96 3127.22 619.22 3127.1 619.41C3127.03 619.52 3126.81 619.53 3126.65 619.58C3126.59 619.31 3126.64 619.08 3126.89 618.87Z" fill="white"/>
                    <path d="M3139.74 364.09C3139.96 364.23 3140.11 364.42 3139.88 364.64C3139.82 364.7 3139.57 364.6 3139.43 364.52C3139.2 364.4 3139.06 364.19 3139.29 363.98C3139.35 363.92 3139.59 364.05 3139.74 364.09Z" fill="white"/>
                    <path d="M3150.01 590.64C3150.23 590.78 3150.38 590.97 3150.15 591.19C3150.09 591.25 3149.84 591.15 3149.7 591.07C3149.47 590.95 3149.33 590.74 3149.56 590.53C3149.62 590.47 3149.86 590.6 3150.01 590.64Z" fill="white"/>
                    <path d="M3148.56 579.29C3148.78 579.43 3148.93 579.62 3148.7 579.84C3148.64 579.9 3148.39 579.8 3148.25 579.72C3148.02 579.6 3147.88 579.39 3148.11 579.18C3148.17 579.12 3148.41 579.25 3148.56 579.29Z" fill="white"/>
                    <path d="M3137.3 538.69C3137.41 538.41 3137.46 538.16 3137.59 537.98C3137.67 537.87 3137.89 537.86 3138.04 537.81C3137.97 538.04 3137.95 538.3 3137.83 538.49C3137.76 538.6 3137.54 538.61 3137.3 538.69Z" fill="white"/>
                    <path d="M3040.75 521.31C3040.93 521.22 3041.15 521.12 3041.37 521.02C3041.36 521.18 3041.41 521.4 3041.32 521.5C3041.16 521.67 3040.92 521.76 3040.71 521.88C3040.72 521.72 3040.73 521.55 3040.75 521.32V521.31Z" fill="white"/>
                    <path d="M3109.27 469.74C3109.38 469.7 3109.54 469.65 3109.69 469.6C3109.62 469.83 3109.6 470.09 3109.48 470.28C3109.41 470.39 3109.19 470.4 3109.03 470.45C3108.97 470.18 3109.02 469.95 3109.27 469.74Z" fill="white"/>
                    <path d="M3080.71 439.14C3080.82 438.86 3080.87 438.61 3081 438.43C3081.08 438.32 3081.3 438.31 3081.45 438.26C3081.38 438.49 3081.36 438.75 3081.24 438.94C3081.17 439.05 3080.95 439.06 3080.71 439.14Z" fill="white"/>
                    <path d="M3035.59 391.51C3035.93 391.37 3036.08 391.64 3036.27 391.8C3036.33 391.85 3036.29 392.14 3036.22 392.18C3035.92 392.34 3035.75 392.06 3035.57 391.9C3035.51 391.85 3035.59 391.64 3035.6 391.52L3035.59 391.51Z" fill="white"/>
                    <path d="M3093.26 403.11C3093.48 403.25 3093.63 403.44 3093.4 403.66C3093.34 403.72 3093.09 403.62 3092.95 403.54C3092.72 403.42 3092.58 403.21 3092.81 403C3092.87 402.94 3093.11 403.07 3093.26 403.11Z" fill="white"/>
                    <path d="M3238.05 525.15C3237.97 525.01 3237.79 524.82 3237.83 524.74C3237.98 524.46 3238.21 524.54 3238.39 524.74C3238.5 524.86 3238.66 525.07 3238.62 525.14C3238.47 525.42 3238.25 525.33 3238.05 525.14V525.15Z" fill="white"/>
                    <path d="M3160.82 483.19C3160.96 483.11 3161.15 482.93 3161.23 482.97C3161.51 483.12 3161.43 483.35 3161.23 483.53C3161.11 483.64 3160.9 483.8 3160.83 483.76C3160.55 483.61 3160.64 483.39 3160.83 483.19H3160.82Z" fill="white"/>
                    <path d="M3168.74 471.62C3168.77 471.49 3168.77 471.35 3168.83 471.23C3168.85 471.19 3168.99 471.2 3169.07 471.19C3169.04 471.32 3169.04 471.46 3168.98 471.58C3168.96 471.62 3168.82 471.61 3168.74 471.62Z" fill="white"/>
                    <path d="M3185.43 374.18C3185.46 374.05 3185.46 373.91 3185.52 373.79C3185.54 373.75 3185.68 373.76 3185.76 373.75C3185.73 373.88 3185.73 374.02 3185.67 374.14C3185.65 374.18 3185.51 374.17 3185.43 374.18Z" fill="white"/>
                    <path d="M3141.86 570.47C3141.98 570.42 3142.1 570.37 3142.22 570.31C3142.23 570.39 3142.28 570.52 3142.25 570.55C3142.15 570.63 3142.02 570.68 3141.9 570.73C3141.89 570.65 3141.88 570.56 3141.87 570.46L3141.86 570.47Z" fill="white"/>
                    <path d="M3166.15 604.9C3166.05 604.91 3165.97 604.92 3165.88 604.93C3165.91 604.8 3165.91 604.66 3165.97 604.54C3165.99 604.5 3166.13 604.51 3166.21 604.5C3166.19 604.63 3166.17 604.76 3166.15 604.89V604.9Z" fill="white"/>
                    <path d="M3103.26 558.03C3103.31 558.15 3103.36 558.27 3103.42 558.39C3103.34 558.4 3103.21 558.45 3103.18 558.42C3103.1 558.32 3103.05 558.19 3103 558.07C3103.08 558.06 3103.17 558.05 3103.27 558.04L3103.26 558.03Z" fill="white"/>
                    <path d="M3099.16 516.29C3099.29 516.32 3099.43 516.32 3099.55 516.38C3099.59 516.4 3099.58 516.54 3099.59 516.62C3099.46 516.59 3099.32 516.59 3099.2 516.53C3099.16 516.51 3099.17 516.37 3099.16 516.29Z" fill="white"/>
                    <path d="M3067.21 485.92C3067.22 486.02 3067.23 486.1 3067.24 486.19C3067.11 486.16 3066.97 486.16 3066.85 486.1C3066.81 486.08 3066.82 485.94 3066.81 485.86C3066.94 485.88 3067.07 485.9 3067.2 485.92H3067.21Z" fill="white"/>
                    <path d="M3021.89 515.95C3022.01 515.9 3022.13 515.85 3022.25 515.79C3022.26 515.87 3022.31 516 3022.28 516.03C3022.18 516.11 3022.05 516.16 3021.93 516.21C3021.92 516.13 3021.91 516.04 3021.9 515.94L3021.89 515.95Z" fill="white"/>
                    <path d="M3116.19 570.2C3116.29 570.19 3116.37 570.18 3116.46 570.17C3116.43 570.3 3116.43 570.44 3116.37 570.56C3116.35 570.6 3116.21 570.59 3116.13 570.6C3116.15 570.47 3116.17 570.34 3116.19 570.21V570.2Z" fill="white"/>
                    <path d="M3124.25 503.08C3124.15 503.09 3124.07 503.1 3123.98 503.11C3124.01 502.98 3124.01 502.84 3124.07 502.72C3124.09 502.68 3124.23 502.69 3124.31 502.68C3124.29 502.81 3124.27 502.94 3124.25 503.07V503.08Z" fill="white"/>
                    <path d="M3027.76 415.12C3027.75 415.03 3027.71 414.89 3027.73 414.88C3027.85 414.83 3028 414.76 3028.12 414.78C3028.37 414.83 3028.39 414.99 3028.16 415.1C3028.05 415.15 3027.89 415.12 3027.76 415.12Z" fill="white"/>
                    <path d="M3028.46 414.73C3028.59 414.73 3028.81 414.68 3028.85 414.73C3028.98 414.91 3028.9 415.06 3028.74 415.04C3028.6 415.03 3028.46 414.95 3028.32 414.91C3028.37 414.85 3028.42 414.78 3028.47 414.72L3028.46 414.73Z" fill="white"/>
                    <path d="M3195.81 475.94C3195.93 475.89 3196.05 475.84 3196.17 475.78C3196.18 475.86 3196.23 475.99 3196.2 476.02C3196.1 476.1 3195.97 476.15 3195.85 476.2C3195.84 476.12 3195.83 476.03 3195.82 475.93L3195.81 475.94Z" fill="white"/>
                    <path d="M2980.39 477.09C2980.51 477.04 2980.63 476.99 2980.75 476.93C2980.76 477.01 2980.81 477.14 2980.78 477.17C2980.68 477.25 2980.55 477.3 2980.43 477.35C2980.42 477.27 2980.41 477.18 2980.4 477.08L2980.39 477.09Z" fill="white"/>
                    <path d="M3026.2 408.08C3026.3 408.07 3026.39 408.06 3026.47 408.05C3026.44 408.18 3026.44 408.32 3026.38 408.44C3026.36 408.48 3026.22 408.47 3026.14 408.48C3026.16 408.35 3026.18 408.22 3026.2 408.09V408.08Z" fill="white"/>
                    <path d="M3005.8 507.42C3005.68 507.47 3005.56 507.52 3005.44 507.58C3005.43 507.5 3005.38 507.37 3005.41 507.34C3005.51 507.26 3005.64 507.21 3005.76 507.16C3005.77 507.24 3005.78 507.33 3005.79 507.43L3005.8 507.42Z" fill="white"/>
                    <path d="M3116.82 435.36C3116.94 435.31 3117.06 435.26 3117.18 435.2C3117.19 435.28 3117.24 435.41 3117.21 435.44C3117.11 435.52 3116.98 435.57 3116.86 435.62C3116.85 435.54 3116.84 435.45 3116.83 435.35L3116.82 435.36Z" fill="white"/>
                    <path d="M3075.1 553.31C3075.11 553.41 3075.12 553.49 3075.13 553.58C3075 553.55 3074.86 553.55 3074.74 553.49C3074.7 553.47 3074.71 553.33 3074.7 553.25C3074.83 553.27 3074.96 553.29 3075.09 553.31H3075.1Z" fill="white"/>
                    <path d="M3056.42 416.88C3056.45 416.75 3056.45 416.61 3056.51 416.49C3056.53 416.45 3056.67 416.46 3056.75 416.45C3056.72 416.58 3056.72 416.72 3056.66 416.84C3056.64 416.88 3056.5 416.87 3056.42 416.88Z" fill="white"/>
                    <path d="M3064.45 548.14C3064.57 548.09 3064.69 548.04 3064.81 547.98C3064.82 548.06 3064.87 548.19 3064.84 548.22C3064.74 548.3 3064.61 548.35 3064.49 548.4L3064.46 548.13L3064.45 548.14Z" fill="white"/>
                    <path d="M3150.84 486.98C3150.79 486.86 3150.74 486.74 3150.68 486.62C3150.76 486.61 3150.89 486.56 3150.92 486.59C3151 486.69 3151.05 486.82 3151.1 486.94C3151.02 486.95 3150.93 486.96 3150.83 486.97L3150.84 486.98Z" fill="white"/>
                    <path d="M3274.15 511.49C3274.18 511.36 3274.18 511.22 3274.24 511.1C3274.26 511.06 3274.4 511.07 3274.48 511.06C3274.45 511.19 3274.45 511.33 3274.39 511.45C3274.37 511.49 3274.23 511.48 3274.15 511.49Z" fill="white"/>
                    <path d="M3384.79 519.55C3385.18 521.27 3384.11 522.12 3383.07 522.9C3382.69 523.19 3382.04 523.54 3381.9 523.76C3381 525.16 3379.96 523.48 3379.09 524.01C3378.71 523.09 3377.94 522.11 3378.06 521.26C3378.17 520.45 3378.4 519.44 3378.91 518.68C3379.4 517.96 3379.75 516.73 3381.08 517.75C3381.28 517.9 3381.76 517.65 3382.12 517.62C3383.7 517.5 3384.59 518.19 3384.8 519.56L3384.79 519.55Z" fill="white"/>
                    <path d="M3263.47 444.85C3264.5 444.71 3265.35 445.42 3265.4 445.79C3265.57 447.2 3266.94 447.57 3267.5 448.62C3267.9 449.36 3268.03 449.87 3267.5 450.49C3266.63 451.52 3266.05 451.71 3265.19 451.25C3264.82 451.05 3264.44 450.9 3264.04 450.76C3262.31 450.13 3261.14 446.61 3262.77 445.27C3263.06 445.03 3263.3 444.83 3263.47 444.85Z" fill="white"/>
                    <path d="M3290.03 525.64C3290.75 526.21 3292.03 525.82 3292.31 527.06C3292.35 527.23 3292.31 527.45 3292.4 527.59C3292.78 528.14 3293.4 528.6 3293.12 529.4C3292.9 530.03 3292.27 530.22 3291.79 530.56C3290.84 531.23 3289.22 530.92 3288.38 529.96C3287.73 529.21 3287.64 528.42 3287.99 527.47C3288.36 526.48 3289.32 526.23 3290.03 525.65V525.64Z" fill="white"/>
                    <path d="M3187.64 599.93C3186.09 600.16 3185.3 599.69 3185.01 598.6C3184.85 598 3184.83 597.35 3184.7 596.74C3184.58 596.16 3184.8 595.94 3185.36 595.89C3185.99 595.83 3186.61 595.62 3187.23 595.63C3188.28 595.66 3189.06 596.26 3189.63 597.11C3189.74 597.29 3189.77 597.67 3189.65 597.82C3189.22 598.38 3189.03 599.03 3188.73 599.64C3188.59 599.94 3187.76 599.9 3187.64 599.92V599.93Z" fill="white"/>
                    <path d="M3153.23 486.93C3153.92 486.77 3154.9 486.51 3155.36 487.55C3155.58 488.04 3155.98 488.97 3155.37 489.26C3154.71 489.58 3154.23 490.48 3153.32 490.16C3152.69 489.94 3152.09 489.49 3151.45 489.43C3150.86 489.37 3150.72 489.21 3150.63 488.69C3150.5 487.94 3150.88 487.47 3151.45 487.22C3151.93 487.01 3152.5 487.03 3153.23 486.92V486.93Z" fill="white"/>
                    <path d="M3188.99 619.43C3189.69 619.31 3190.38 619.77 3190.52 620.45C3190.57 620.68 3189.66 623.07 3189.48 623.18C3189.07 623.44 3187.56 622.77 3187.63 622.71C3188.47 621.95 3187.13 621.6 3187.36 621.01C3187.58 620.44 3188.54 619.51 3188.99 619.43Z" fill="white"/>
                    <path d="M3337.85 559.88C3337.8 559.51 3337.62 558.96 3337.97 558.9C3338.62 558.79 3339.29 558.38 3340.01 558.9C3340.41 559.19 3340.42 559.68 3340.74 559.92C3341.26 560.31 3341.39 560.63 3341 561.23C3340.58 561.87 3339.94 562.06 3339.43 561.78C3338.7 561.38 3337.77 560.99 3337.85 559.89V559.88Z" fill="white"/>
                    <path d="M3265.36 500.43C3264.79 500.48 3264.11 500.88 3263.99 499.81C3263.96 499.57 3263.44 499.4 3263.17 499.17C3262.86 498.91 3262.57 498.63 3262.27 498.35C3262.85 498.2 3263.51 497.82 3264.01 497.96C3264.65 498.14 3265.16 498.77 3265.76 499.16C3266.09 499.38 3266.35 499.64 3266.25 500.01C3266.14 500.42 3265.7 500.37 3265.36 500.43Z" fill="white"/>
                    <path d="M3296.12 641.37C3295.95 641.4 3295.77 641.44 3295.6 641.45C3295.21 641.45 3294.7 641.83 3294.47 641.24C3294.23 640.64 3294.34 640.07 3294.82 639.57C3295.23 639.14 3295.53 639.3 3295.94 639.55C3296.2 639.71 3296.6 639.65 3296.87 639.81C3297.22 640.02 3297.71 640.2 3297.66 640.74C3297.6 641.38 3297.02 641.17 3296.65 641.29C3296.48 641.34 3296.3 641.35 3296.13 641.37H3296.12Z" fill="white"/>
                    <path d="M3180.35 614.78C3180.06 615.55 3179.99 616.45 3179.33 617.05C3179.18 617.19 3178.85 617.25 3178.6 617.02C3178.29 616.73 3177.7 616.58 3177.83 616.06C3177.99 615.42 3178.29 614.81 3178.6 614.21C3178.96 613.51 3179.24 614.41 3179.61 614.3C3179.99 614.19 3180.31 614.27 3180.35 614.77V614.78Z" fill="white"/>
                    <path d="M3308.37 491.11C3307.9 491.26 3307.58 491.15 3307.52 490.55C3307.43 489.63 3307.83 489.03 3308.65 488.87C3309.37 488.73 3309.84 489.12 3310.1 489.65C3310.4 490.26 3309.55 490.96 3308.37 491.11Z" fill="white"/>
                    <path d="M3342.99 417.92C3342.72 417.96 3342.46 418.05 3342.19 418.03C3341.58 417.99 3341.27 417.3 3341.63 416.81C3341.84 416.52 3342.01 416.18 3342.28 415.96C3342.62 415.67 3343.15 415.56 3343.5 415.77C3343.9 416.01 3343.89 416.69 3344.41 416.92C3344.06 417.28 3343.95 417.93 3343.25 417.89C3343.16 417.89 3343.07 417.91 3342.98 417.93L3342.99 417.92Z" fill="white"/>
                    <path d="M3228.23 600.29C3228.49 600.48 3228.69 600.65 3228.92 600.79C3229.49 601.15 3228.99 602.02 3229.71 602.34C3230.09 602.51 3230.15 602.91 3229.87 603.21C3229.61 603.5 3229.27 603.44 3228.95 603.19C3228.53 602.86 3228.07 602.58 3227.67 602.23C3227.48 602.06 3226.92 602.03 3227.31 601.53C3227.63 601.12 3227.92 600.7 3228.23 600.29Z" fill="white"/>
                    <path d="M3129.23 549.07C3129.23 548.5 3130.02 547.84 3130.68 547.87C3130.94 547.88 3132.05 549.12 3132.01 549.43C3131.91 550.22 3131.21 549.95 3130.77 550.02C3129.96 550.14 3129.23 549.62 3129.23 549.07Z" fill="white"/>
                    <path d="M3185.72 499.71C3185.88 500.24 3185.82 500.59 3185.2 500.65C3184.45 500.73 3183.63 500.33 3183.55 499.79C3183.47 499.26 3184.14 498.61 3184.88 498.5C3185.99 498.34 3185.49 499.3 3185.71 499.7L3185.72 499.71Z" fill="white"/>
                    <path d="M3188.22 493.62C3188.68 493.81 3189.19 493.99 3189.31 494.55C3189.44 495.15 3188.49 495.93 3187.75 495.88C3187.27 495.85 3187.25 495.5 3187.23 495.15C3187.18 494.35 3187.65 493.63 3188.22 493.61V493.62Z" fill="white"/>
                    <path d="M3209.42 573.6C3209.07 573.65 3208.89 573.66 3208.73 573.71C3208.1 573.89 3207.81 573.32 3207.37 573.09C3207.72 572.74 3207.82 572.11 3208.48 572.1C3208.65 572.1 3208.83 572.06 3209 572.02C3209.58 571.88 3209.97 571.9 3210.07 572.68C3210.17 573.43 3209.9 573.64 3209.42 573.6Z" fill="white"/>
                    <path d="M3202.33 418.53C3202.47 419.15 3202.07 419.34 3201.75 419.25C3201.11 419.07 3200.49 418.77 3199.88 418.47C3199.32 418.19 3199.82 417.94 3199.95 417.69C3200.51 416.63 3201.15 417.73 3201.76 417.65C3202.47 417.56 3202.16 418.29 3202.32 418.53H3202.33Z" fill="white"/>
                    <path d="M3194.44 544.68C3195.15 544.43 3195.64 544.82 3195.91 545.35C3196.12 545.75 3195.21 546.92 3194.86 546.79C3194.19 546.54 3193.84 546.03 3193.79 545.29C3193.75 544.77 3194.08 544.73 3194.43 544.67L3194.44 544.68Z" fill="white"/>
                    <path d="M3346.68 606.83C3346.67 607.36 3346.3 607.72 3345.89 608C3345.64 608.17 3344.59 607.12 3344.46 606.59C3344.34 606.1 3344.67 606 3345.04 605.96C3345.78 605.87 3346.59 606.29 3346.69 606.83H3346.68Z" fill="white"/>
                    <path d="M3178.75 579.24C3178.79 578.97 3178.94 578.68 3179.24 578.78C3179.95 579.03 3180.73 579.1 3181.36 579.6C3181.62 579.81 3181.69 580.11 3181.62 580.3C3181.55 580.48 3181.17 580.71 3181.01 580.66C3180.37 580.46 3179.76 580.16 3179.14 579.89C3178.88 579.77 3178.76 579.54 3178.75 579.24Z" fill="white"/>
                    <path d="M3154.16 595.2C3154.44 595.13 3154.78 595.04 3154.95 595.49C3155.09 595.88 3154.79 596.11 3154.64 596.41C3154.34 597.02 3153.75 596.93 3153.28 596.94C3153 596.94 3152.47 596.55 3152.48 596.38C3152.52 596.03 3152.78 595.62 3153.08 595.4C3153.33 595.22 3153.75 595.27 3154.16 595.2Z" fill="white"/>
                    <path d="M3163.54 447.73C3163.4 447.31 3163.52 446.99 3164.12 446.93C3164.87 446.86 3165.66 447.26 3165.75 447.79C3165.91 448.68 3164.7 448.24 3164.6 448.96C3164.58 449.08 3163.7 448.41 3163.55 447.73H3163.54Z" fill="white"/>
                    <path d="M3259.65 584.53C3259.79 584.61 3260.01 584.65 3260.06 584.77C3260.33 585.42 3259.55 586.78 3258.88 586.82C3258.65 586.84 3258.3 586.79 3258.2 586.65C3258.09 586.49 3258.14 586.14 3258.25 585.94C3258.72 585.06 3259.21 584.6 3259.65 584.52V584.53Z" fill="white"/>
                    <path d="M3345.64 532.07C3345.6 531.81 3345.51 531.54 3345.53 531.28C3345.57 530.61 3346.15 530.82 3346.53 530.72C3347.02 530.58 3347.12 530.93 3347.17 531.3C3347.22 531.64 3347.33 531.96 3347.06 532.29C3346.78 532.62 3346.58 533.1 3346.05 532.96C3345.64 532.85 3345.71 532.41 3345.65 532.07H3345.64Z" fill="white"/>
                    <path d="M3181.12 577.39C3180.56 577.36 3180.55 576.99 3180.54 576.65C3180.5 575.84 3180.97 575.13 3181.54 575.13C3182.02 575.13 3182.04 575.52 3182.05 575.87C3182.09 576.68 3181.62 577.37 3181.12 577.39Z" fill="white"/>
                    <path d="M3155.98 448.47C3156.37 447.98 3156.61 447.43 3157.37 447.4C3158 447.37 3158.06 447.67 3158.15 448.14C3158.24 448.65 3158.17 448.89 3157.6 449.06C3156.85 449.27 3156.5 448.74 3155.99 448.47H3155.98Z" fill="white"/>
                    <path d="M3168.42 442.99C3168.57 443.06 3168.82 443.1 3168.85 443.2C3168.95 443.53 3169.12 443.95 3169 444.22C3168.8 444.65 3168.6 445.22 3167.96 445.24C3167.47 445.26 3167.45 444.87 3167.43 444.52C3167.39 443.7 3167.83 443.03 3168.42 442.98V442.99Z" fill="white"/>
                    <path d="M3361.49 565.11C3361.86 565.07 3362.25 565.02 3362.24 565.53C3362.24 566.18 3361.9 566.74 3361.23 566.76C3360.88 566.77 3360.51 566.36 3360.15 566.14C3360.52 565.69 3360.73 565.04 3361.48 565.11H3361.49Z" fill="white"/>
                    <path d="M3301.86 435.55C3301.92 436.19 3301.28 436.97 3300.83 436.96C3300.36 436.96 3300.38 436.57 3300.31 436.24C3300.17 435.57 3300.47 435.07 3301.03 434.89C3301.2 434.84 3301.81 435.2 3301.87 435.55H3301.86Z" fill="white"/>
                    <path d="M3183.8 528.24C3184.25 528.17 3184.72 528.12 3184.65 528.6C3184.57 529.19 3184.26 529.75 3184.05 530.32C3183.53 529.99 3183.08 529.66 3183.06 528.92C3183.04 528.22 3183.51 528.33 3183.8 528.24Z" fill="white"/>
                    <path d="M3240.61 405.68C3241.33 405.49 3241.43 405.83 3241.54 406.33C3241.72 407.11 3241.31 407.21 3240.72 407.3C3240.11 407.4 3240.01 407.11 3239.9 406.6C3239.74 405.85 3240.05 405.63 3240.61 405.68Z" fill="white"/>
                    <path d="M3179.13 538.76C3179.12 539.24 3179.59 539.94 3178.67 539.95C3178.17 539.95 3177.68 539.62 3177.54 539.08C3177.3 538.18 3178.33 538.28 3178.69 537.98C3179.14 537.6 3178.91 538.52 3179.14 538.76H3179.13Z" fill="white"/>
                    <path d="M3145.15 610.81C3145.41 610.77 3145.67 610.69 3145.93 610.7C3146.4 610.72 3146.41 611.1 3146.44 611.44C3146.46 611.69 3146.64 611.96 3146.37 612.17C3146.06 612.4 3145.75 612.39 3145.45 612.13C3145.32 612.02 3145.17 611.92 3145.03 611.82C3144.77 611.61 3144.28 611.38 3144.29 611.19C3144.33 610.79 3144.83 610.89 3145.15 610.81Z" fill="white"/>
                    <path d="M3347.17 459.83C3347.26 459.19 3347.81 459.36 3348.19 459.29C3348.9 459.15 3348.77 459.75 3348.85 460.13C3348.93 460.48 3348.95 460.85 3348.43 460.88C3347.9 460.91 3347.17 460.29 3347.17 459.83Z" fill="white"/>
                    <path d="M3205.65 475.92C3205.7 476.39 3205.75 476.75 3205.23 476.78C3204.7 476.81 3203.95 476.19 3203.97 475.73C3203.99 475.26 3204.4 475.31 3204.72 475.21C3205.51 474.97 3205.62 475.45 3205.65 475.92Z" fill="white"/>
                    <path d="M3160.3 569.85C3160.32 569.67 3160.27 569.3 3160.4 569.21C3160.76 568.97 3161.01 569.39 3161.16 569.58C3161.39 569.87 3162.38 569.81 3161.72 570.6C3161.5 570.86 3161.34 571.21 3160.94 571.16C3160.12 571.06 3160.59 570.31 3160.3 569.85Z" fill="white"/>
                    <path d="M3288.62 623.9C3289 624.09 3289.76 624.22 3289.5 624.7C3289.28 625.11 3289.64 625.78 3289.01 625.93C3288.64 626.02 3287.91 624.8 3288.03 624.3C3288.09 624.04 3288.31 623.89 3288.62 623.9Z" fill="white"/>
                    <path d="M3245.01 421.89C3245.01 421.89 3245.37 421.57 3245.73 421.34C3245.89 421.24 3246.73 422.14 3246.72 422.39C3246.68 423.06 3246.11 422.85 3245.74 422.95C3245.29 423.08 3245.13 422.8 3245.01 421.89Z" fill="white"/>
                    <path d="M3276.85 421.47C3277.49 421.55 3277.29 422.1 3277.39 422.47C3277.52 422.94 3277.18 423.17 3276.84 423.08C3276.45 422.98 3276.09 422.7 3275.76 422.44C3275.64 422.34 3276.6 421.46 3276.84 421.47H3276.85Z" fill="white"/>
                    <path d="M3394 537.01C3394.16 537.46 3393.98 537.68 3393.72 537.71C3393.04 537.81 3392.61 537.43 3392.36 536.83C3392.23 536.52 3392.43 536.3 3392.68 536.26C3393.39 536.13 3393.77 536.57 3393.99 537.02L3394 537.01Z" fill="white"/>
                    <path d="M3251.41 603.37C3251.41 602.8 3251.7 602.32 3252.16 602.25C3252.38 602.22 3252.96 602.55 3253.02 603.07C3253.11 603.78 3252.55 603.64 3252.17 603.73C3251.82 603.81 3251.48 603.81 3251.4 603.36L3251.41 603.37Z" fill="white"/>
                    <path d="M3363.73 453.49C3363.8 453.73 3363.24 454.87 3363.11 454.78C3362.76 454.55 3362.39 454.29 3362.19 453.94C3362 453.62 3362.17 453.26 3362.63 453.25C3363.01 453.24 3363.49 452.87 3363.73 453.49Z" fill="white"/>
                    <path d="M3246.44 605.74C3246.93 605.82 3247.46 606.06 3247.55 606.77C3247.58 607.03 3247.43 607.28 3247.1 607.25C3246.45 607.2 3245.96 606.9 3245.85 606.22C3245.81 605.97 3245.96 605.72 3246.44 605.74Z" fill="white"/>
                    <path d="M3242.37 666.02C3242.85 665.92 3243.09 666.23 3243 666.58C3242.9 666.97 3242.62 667.33 3242.36 667.66C3242.26 667.78 3241.38 666.82 3241.39 666.58C3241.41 666.24 3241.79 666.03 3242.38 666.03L3242.37 666.02Z" fill="white"/>
                    <path d="M3182.99 536.58C3183.36 536.53 3183.73 536.48 3183.77 536.98C3183.81 537.53 3183.49 538 3183.03 538.07C3182.8 538.1 3182.23 537.77 3182.17 537.25C3182.08 536.54 3182.65 536.7 3182.99 536.58Z" fill="white"/>
                    <path d="M3172.79 621.43C3173.27 621.59 3173.69 621.8 3173.84 622.33C3173.93 622.64 3173.76 622.88 3173.49 622.88C3173.06 622.88 3172.44 623.4 3172.23 622.55C3172.1 622.03 3172.64 621.83 3172.79 621.43Z" fill="white"/>
                    <path d="M3193.16 543.78C3193.3 543.85 3193.45 543.93 3193.59 544C3193.14 544.64 3192.58 545.21 3192.53 546.06C3192.53 546.1 3192.19 546.16 3192.02 546.13C3191.93 546.11 3191.79 545.83 3191.81 545.81C3192.29 545.28 3192.03 544.4 3192.68 543.96C3192.82 543.87 3193 543.84 3193.16 543.78Z" fill="white"/>
                    <path d="M3194.71 547.7C3194.76 548.23 3194.03 548.05 3193.62 548.07C3192.8 548.12 3193.12 547.35 3192.99 546.93C3192.95 546.81 3193.16 546.28 3193.37 546.7C3193.68 547.32 3194.65 546.38 3194.71 547.7Z" fill="white"/>
                    <path d="M3358.62 513.15C3358.42 512.53 3359 512.26 3359.22 511.82C3359.5 512.01 3359.98 512.17 3360 512.4C3360.06 512.89 3359.72 513.27 3359.22 513.44C3358.92 513.55 3358.75 513.38 3358.62 513.15Z" fill="white"/>
                    <path d="M3241.42 427.05C3241.42 427.43 3241.73 427.93 3241.02 428.06C3240.51 428.15 3240.43 427.82 3240.39 427.46C3240.35 427.07 3240.07 426.57 3240.78 426.44C3241.3 426.35 3241.35 426.69 3241.42 427.05Z" fill="white"/>
                    <path d="M3368.17 584.43C3368.98 584.8 3369.22 585.32 3369.21 585.97C3369.21 586.12 3369.08 586.27 3369.01 586.42C3368.87 586.32 3368.74 586.18 3368.59 586.12C3367.99 585.87 3368 585.31 3367.96 584.81C3367.95 584.66 3368.14 584.49 3368.17 584.44V584.43Z" fill="white"/>
                    <path d="M3241.93 399.41C3242.06 399.38 3242.18 399.7 3242.21 400.08C3242.24 400.47 3242.54 400.97 3241.83 401.1C3241.33 401.19 3241.23 400.88 3241.19 400.51C3241.15 400.12 3240.88 399.62 3241.93 399.41Z" fill="white"/>
                    <path d="M3222.93 453.47C3222.88 453.58 3222.85 453.8 3222.77 453.82C3222.18 453.97 3221.6 454.09 3221.09 453.55C3220.88 453.33 3220.79 453.17 3221.04 453.01C3221.44 452.75 3222.74 453.08 3222.92 453.47H3222.93Z" fill="white"/>
                    <path d="M3322.78 485.63C3323.64 485.72 3323.2 486.46 3323.36 486.89C3323.43 487.08 3323.05 487.3 3322.78 487.26C3321.98 487.15 3322.3 486.45 3322.19 486.01C3322.14 485.8 3322.48 485.62 3322.78 485.64V485.63Z" fill="white"/>
                    <path d="M3222.19 515.26C3223.05 515.35 3222.61 516.09 3222.77 516.52C3222.84 516.71 3222.46 516.93 3222.19 516.89C3221.39 516.78 3221.71 516.08 3221.6 515.64C3221.55 515.43 3221.89 515.25 3222.19 515.27V515.26Z" fill="white"/>
                    <path d="M3303 605.25C3303.13 605.22 3303.25 605.54 3303.28 605.92C3303.31 606.31 3303.61 606.81 3302.9 606.94C3302.4 607.03 3302.3 606.72 3302.26 606.35C3302.22 605.96 3301.95 605.46 3303 605.25Z" fill="white"/>
                    <path d="M3150.98 515.37C3150.53 515.71 3150.07 516.06 3149.6 516.41C3149.58 516.43 3149.43 516.33 3149.4 516.26C3149.27 515.91 3149.36 515.61 3149.6 515.31C3149.88 514.97 3150.1 514.59 3150.62 514.8C3150.74 514.85 3150.79 515.06 3150.98 515.37Z" fill="white"/>
                    <path d="M3175.08 558.87C3175 558.52 3174.89 558.15 3175.37 558.01C3175.98 557.84 3176.11 558.22 3176.16 558.72C3176.19 559.07 3176.34 559.44 3175.86 559.57C3175.25 559.74 3175.09 559.37 3175.09 558.87H3175.08Z" fill="white"/>
                    <path d="M3211.86 599.44C3212.35 599.51 3212.59 599.6 3212.57 599.94C3212.52 600.59 3212.22 601.07 3211.54 601.19C3211.41 601.21 3211.24 601.07 3211.1 601C3211.37 600.46 3211.63 599.92 3211.87 599.45L3211.86 599.44Z" fill="white"/>
                    <path d="M3205.6 492.09C3206.06 491.95 3206.44 492 3206.46 492.22C3206.49 492.67 3207.01 493.25 3206.28 493.59C3206.03 493.71 3205.65 493.64 3205.61 493.4C3205.53 492.95 3205.19 492.4 3205.61 492.08L3205.6 492.09Z" fill="white"/>
                    <path d="M3317.93 595.32C3318.3 595.28 3318.64 595.23 3318.7 595.75C3318.79 596.46 3318.23 596.35 3317.85 596.43C3317.5 596.5 3317.15 596.53 3317.09 596.01C3317 595.29 3317.57 595.44 3317.93 595.32Z" fill="white"/>
                    <path d="M3305.71 514.38C3305.8 513.52 3306.54 513.96 3306.97 513.8C3307.16 513.73 3307.38 514.11 3307.34 514.38C3307.23 515.18 3306.53 514.86 3306.09 514.97C3305.88 515.02 3305.7 514.68 3305.72 514.38H3305.71Z" fill="white"/>
                    <path d="M3202.62 602.84C3202.54 602.49 3202.43 602.12 3202.91 601.98C3203.52 601.81 3203.65 602.19 3203.7 602.69C3203.73 603.04 3203.88 603.41 3203.4 603.54C3202.79 603.71 3202.63 603.34 3202.63 602.84H3202.62Z" fill="white"/>
                    <path d="M3167.12 456.6C3166.97 456 3167.12 455.82 3167.41 455.75C3168.03 455.59 3168.14 455.99 3168.17 456.47C3168.18 456.72 3168.32 457.2 3168.11 457.15C3167.68 457.06 3167.31 456.71 3167.13 456.6H3167.12Z" fill="white"/>
                    <path d="M3347.1 577.7C3347.24 577.74 3347.47 577.81 3347.7 577.88C3347.39 578.26 3347.28 578.86 3346.65 578.87C3346.38 578.87 3346.19 578.75 3346.15 578.41C3346.07 577.71 3346.61 577.83 3347.1 577.69V577.7Z" fill="white"/>
                    <path d="M3160.07 635.99C3160.15 636.22 3159.99 636.42 3159.73 636.5C3159.39 636.6 3158.99 636.61 3158.99 636.1C3158.99 635.74 3158.56 635.19 3159.17 635.08C3159.39 635.04 3159.97 635.39 3160.07 635.99Z" fill="white"/>
                    <path d="M3129.88 471.88C3130.49 471.71 3130.76 472.27 3131.19 472.51C3130.98 472.63 3130.78 472.82 3130.56 472.86C3130.18 472.92 3129.69 473.19 3129.55 472.49C3129.49 472.17 3129.62 472.01 3129.88 471.88Z" fill="white"/>
                    <path d="M3189.53 628.39C3189.67 628.74 3189.62 628.35 3189.36 628.59C3189.22 628.72 3188.84 628.78 3188.69 628.68C3188.29 628.41 3187.7 628.24 3187.78 627.57C3187.78 627.5 3187.88 627.36 3187.92 627.37C3188.62 627.46 3188.97 628.09 3189.53 628.39Z" fill="white"/>
                    <path d="M3223.64 591.22C3224.02 591.54 3224.64 591.71 3224.51 592.37C3224.48 592.52 3224.11 592.73 3223.91 592.72C3223.26 592.7 3223.47 592.11 3223.36 591.74C3223.28 591.49 3223.32 591.27 3223.64 591.23V591.22Z" fill="white"/>
                    <path d="M3262.02 513.54C3262.19 513.51 3262.38 513.44 3262.54 513.48C3262.65 513.5 3262.82 513.7 3262.8 513.78C3262.73 514.07 3261.09 514.34 3260.88 514.09C3260.83 514.03 3260.91 513.74 3260.98 513.72C3261.32 513.63 3261.68 513.6 3262.03 513.54H3262.02Z" fill="white"/>
                    <path d="M3280.15 659.14C3280.69 659.02 3280.83 659.33 3280.84 659.71C3280.85 659.98 3280.72 660.17 3280.39 660.21C3279.9 660.28 3279.77 659.99 3279.76 659.62C3279.76 659.36 3279.85 659.09 3280.16 659.14H3280.15Z" fill="white"/>
                    <path d="M3144.48 461.98C3144.77 462.02 3144.95 462.11 3145 462.45C3145.07 462.94 3144.78 463.07 3144.41 463.08C3144.14 463.09 3143.96 462.96 3143.91 462.63C3143.84 462.13 3144.13 462.04 3144.48 461.98Z" fill="white"/>
                    <path d="M3343.03 460.62C3343.32 460.66 3343.5 460.75 3343.55 461.09C3343.62 461.58 3343.33 461.71 3342.96 461.72C3342.69 461.73 3342.51 461.6 3342.46 461.27C3342.39 460.77 3342.68 460.68 3343.03 460.62Z" fill="white"/>
                    <path d="M3369.08 560.02C3369.12 560.35 3369.13 560.66 3368.63 560.74C3368.3 560.79 3368.12 560.66 3368.05 560.4C3367.95 560.04 3367.99 559.73 3368.48 559.65C3368.81 559.6 3368.96 559.73 3369.08 560.02Z" fill="white"/>
                    <path d="M3225.98 411.55C3226.11 411.07 3226.34 410.66 3226.84 410.52C3226.97 410.48 3227.29 410.6 3227.28 410.61C3227.21 411.11 3226.97 411.51 3226.46 411.65C3226.32 411.69 3226.15 411.59 3225.97 411.55H3225.98Z" fill="white"/>
                    <path d="M3320.17 433.46C3320.47 433.35 3320.66 433.54 3320.73 433.8C3320.83 434.16 3320.79 434.47 3320.3 434.55C3319.97 434.6 3319.79 434.47 3319.72 434.21C3319.62 433.85 3319.66 433.51 3320.17 433.47V433.46Z" fill="white"/>
                    <path d="M3219.98 472.33C3220.27 472.37 3220.45 472.46 3220.5 472.8C3220.57 473.29 3220.28 473.42 3219.91 473.43C3219.64 473.44 3219.46 473.31 3219.41 472.98C3219.34 472.48 3219.63 472.39 3219.98 472.33Z" fill="white"/>
                    <path d="M3220.32 657.33C3220.66 657.38 3220.84 657.47 3220.88 657.8C3220.95 658.29 3220.66 658.42 3220.29 658.43C3220.02 658.44 3219.84 658.31 3219.79 657.98C3219.71 657.47 3220.04 657.42 3220.32 657.33Z" fill="white"/>
                    <path d="M3212.71 636.58C3212.82 637.08 3212.51 637.22 3212.13 637.23C3211.86 637.24 3211.67 637.11 3211.63 636.78C3211.56 636.29 3211.85 636.16 3212.22 636.15C3212.49 636.14 3212.72 636.26 3212.71 636.59V636.58Z" fill="white"/>
                    <path d="M3250.73 383.31C3250.38 383.35 3250.02 383.39 3249.99 382.89C3249.99 382.74 3250.05 382.58 3250.08 382.42C3250.42 382.61 3250.76 382.79 3251.09 382.98C3250.97 383.09 3250.85 383.2 3250.72 383.31H3250.73Z" fill="white"/>
                    <path d="M3275 425.58C3275.35 425.54 3275.71 425.5 3275.74 426C3275.74 426.15 3275.68 426.31 3275.65 426.47C3275.31 426.28 3274.97 426.1 3274.64 425.91C3274.76 425.8 3274.88 425.69 3275.01 425.58H3275Z" fill="white"/>
                    <path d="M3160.37 474.97C3160.67 475.18 3160.95 475.38 3161.23 475.58C3161.02 475.7 3160.82 475.91 3160.61 475.93C3160.14 475.97 3160.1 475.57 3160.1 475.23C3160.1 475.14 3160.29 475.05 3160.37 474.98V474.97Z" fill="white"/>
                    <path d="M3306.87 521.94C3306.83 522.15 3306.82 522.43 3306.76 522.44C3306.29 522.53 3306.11 522.06 3305.76 521.89C3305.88 521.78 3305.99 521.62 3306.13 521.58C3306.49 521.48 3306.81 521.53 3306.88 521.94H3306.87Z" fill="white"/>
                    <path d="M3391.46 533.22C3391.58 533.28 3391.78 533.31 3391.81 533.4C3391.92 533.73 3392 534.12 3391.53 534.22C3391.32 534.27 3391.07 534.13 3390.83 534.07C3391.04 533.79 3391.24 533.51 3391.45 533.22H3391.46Z" fill="white"/>
                    <path d="M3367.92 520.3C3367.83 519.82 3368.16 519.72 3368.51 519.7C3368.65 519.7 3368.81 519.82 3368.95 519.89C3368.68 520.17 3368.42 520.45 3368.15 520.73C3368.07 520.59 3368 520.45 3367.92 520.3Z" fill="white"/>
                    <path d="M3181.49 481.07C3181.56 481.1 3181.77 481.12 3181.79 481.21C3181.9 481.53 3181.98 481.92 3181.53 482.04C3181.32 482.09 3181.07 481.95 3180.83 481.9C3181.04 481.63 3181.26 481.37 3181.49 481.07Z" fill="white"/>
                    <path d="M3216.27 477.28C3216.75 477.19 3216.85 477.52 3216.87 477.87C3216.87 478.01 3216.75 478.17 3216.68 478.31C3216.4 478.04 3216.12 477.78 3215.84 477.51C3215.98 477.43 3216.12 477.36 3216.27 477.28Z" fill="white"/>
                    <path d="M3371.11 476.03C3371.27 476.09 3371.41 476.16 3371.56 476.22C3371.31 476.51 3371.06 476.8 3370.8 477.09C3370.72 476.95 3370.6 476.83 3370.56 476.68C3370.43 476.22 3370.74 476.09 3371.1 476.04L3371.11 476.03Z" fill="white"/>
                    <path d="M3227.85 628.24C3227.76 627.76 3228.09 627.66 3228.44 627.64C3228.58 627.64 3228.74 627.76 3228.88 627.83C3228.61 628.11 3228.35 628.39 3228.08 628.67C3228 628.53 3227.93 628.39 3227.85 628.24Z" fill="white"/>
                    <path d="M3235.57 664.61C3235.74 664.47 3235.85 664.31 3236 664.27C3236.36 664.17 3236.69 664.23 3236.71 664.71C3236.71 664.86 3236.64 665.02 3236.6 665.18C3236.26 665 3235.93 664.81 3235.57 664.62V664.61Z" fill="white"/>
                    <path d="M3114.15 505.58C3114.49 505.71 3114.42 506.02 3114.44 506.26C3114.44 506.33 3114.22 506.52 3114.14 506.5C3113.81 506.41 3113.88 506.08 3113.86 505.84C3113.86 505.76 3114.05 505.66 3114.15 505.58Z" fill="white"/>
                    <path d="M3132.21 490.37C3132.48 490.24 3132.69 490.1 3132.92 490.05C3133.05 490.03 3133.21 490.17 3133.36 490.24C3133.15 490.36 3132.96 490.53 3132.73 490.59C3132.61 490.62 3132.44 490.47 3132.21 490.37Z" fill="white"/>
                    <path d="M3325.35 629.85C3325.69 629.98 3325.62 630.29 3325.64 630.53C3325.64 630.6 3325.42 630.79 3325.34 630.77C3325.01 630.68 3325.08 630.35 3325.06 630.11C3325.06 630.03 3325.25 629.93 3325.35 629.85Z" fill="white"/>
                    <path d="M3377.89 543.91C3378.02 544.18 3378.16 544.39 3378.21 544.62C3378.23 544.75 3378.09 544.91 3378.02 545.06C3377.9 544.85 3377.73 544.66 3377.67 544.43C3377.64 544.31 3377.79 544.14 3377.89 543.91Z" fill="white"/>
                    <path d="M3377.1 541.11C3377.11 541.25 3377.17 541.49 3377.13 541.72C3377.09 541.99 3376.9 542.02 3376.79 541.77C3376.7 541.56 3376.66 541.3 3376.68 541.07C3376.71 540.8 3376.87 540.79 3377.1 541.1V541.11Z" fill="white"/>
                    <path d="M3341.05 634.48C3341.09 634.32 3341.09 634.06 3341.17 634.03C3341.48 633.93 3341.59 634.15 3341.58 634.42C3341.58 634.58 3341.55 634.84 3341.46 634.87C3341.16 634.97 3341.06 634.75 3341.04 634.48H3341.05Z" fill="white"/>
                    <path d="M3149.77 465.01C3149.93 465.05 3150.19 465.05 3150.22 465.13C3150.32 465.44 3150.1 465.54 3149.83 465.54C3149.67 465.54 3149.41 465.51 3149.38 465.42C3149.28 465.12 3149.5 465.02 3149.77 465V465.01Z" fill="white"/>
                    <path d="M3361.75 473.31C3362.02 473.18 3362.23 473.04 3362.46 472.99C3362.59 472.97 3362.75 473.11 3362.9 473.18C3362.69 473.3 3362.5 473.47 3362.27 473.53C3362.15 473.56 3361.98 473.41 3361.75 473.31Z" fill="white"/>
                    <path d="M3167.6 609.04C3167.71 609.09 3167.86 609.16 3168.01 609.23C3167.8 609.35 3167.61 609.52 3167.38 609.58C3167.26 609.61 3167.09 609.46 3166.94 609.39C3167.08 609.15 3167.28 609.01 3167.6 609.04Z" fill="white"/>
                    <path d="M3353.43 432.74C3353.49 433 3353.47 433.24 3353.15 433.23C3353.06 433.23 3352.96 432.98 3352.91 432.84C3352.83 432.59 3352.86 432.34 3353.19 432.35C3353.28 432.35 3353.36 432.6 3353.44 432.74H3353.43Z" fill="white"/>
                    <path d="M3203.97 604.54C3204.03 604.8 3204.01 605.04 3203.69 605.03C3203.6 605.03 3203.5 604.78 3203.45 604.64C3203.37 604.39 3203.4 604.14 3203.73 604.15C3203.82 604.15 3203.9 604.4 3203.98 604.54H3203.97Z" fill="white"/>
                    <path d="M3210.76 595.29C3210.82 595.55 3210.8 595.79 3210.48 595.78C3210.39 595.78 3210.29 595.53 3210.24 595.39C3210.16 595.14 3210.19 594.89 3210.52 594.9C3210.61 594.9 3210.69 595.15 3210.77 595.29H3210.76Z" fill="white"/>
                    <path d="M3230.71 557.97C3230.98 557.84 3231.19 557.7 3231.42 557.65C3231.55 557.63 3231.71 557.77 3231.86 557.84C3231.65 557.96 3231.46 558.13 3231.23 558.19C3231.11 558.22 3230.94 558.07 3230.71 557.97Z" fill="white"/>
                    <path d="M3172.56 478.45C3172.75 478.51 3172.98 478.59 3173.21 478.67C3173.09 478.78 3172.97 478.98 3172.84 478.98C3172.61 478.98 3172.37 478.89 3172.14 478.83C3172.26 478.72 3172.38 478.61 3172.56 478.45Z" fill="white"/>
                    <path d="M3258.09 488.43C3258.2 488.48 3258.35 488.55 3258.5 488.62C3258.29 488.74 3258.1 488.91 3257.87 488.97C3257.75 489 3257.58 488.85 3257.43 488.78C3257.57 488.54 3257.77 488.4 3258.09 488.43Z" fill="white"/>
                    <path d="M3258.53 446.4C3258.8 446.27 3259.01 446.13 3259.24 446.08C3259.37 446.06 3259.53 446.2 3259.68 446.27C3259.47 446.39 3259.28 446.56 3259.05 446.62C3258.93 446.65 3258.76 446.5 3258.53 446.4Z" fill="white"/>
                    <path d="M3258.73 380.52C3259.07 380.65 3259 380.96 3259.02 381.2C3259.02 381.27 3258.8 381.46 3258.72 381.44C3258.39 381.35 3258.46 381.02 3258.44 380.78C3258.44 380.7 3258.63 380.6 3258.73 380.52Z" fill="white"/>
                    <path d="M3292.62 428.9C3292.68 429.16 3292.66 429.4 3292.34 429.39C3292.25 429.39 3292.15 429.14 3292.1 429C3292.02 428.75 3292.05 428.5 3292.38 428.51C3292.47 428.51 3292.55 428.76 3292.63 428.9H3292.62Z" fill="white"/>
                    <path d="M3313.33 617.92C3313.37 617.76 3313.37 617.5 3313.45 617.47C3313.76 617.37 3313.86 617.59 3313.86 617.86C3313.86 618.02 3313.83 618.28 3313.74 618.31C3313.44 618.41 3313.34 618.19 3313.32 617.92H3313.33Z" fill="white"/>
                    <path d="M3286.25 533.92C3286.41 533.96 3286.67 533.96 3286.7 534.04C3286.8 534.35 3286.58 534.45 3286.31 534.45C3286.15 534.45 3285.89 534.42 3285.86 534.33C3285.76 534.03 3285.98 533.93 3286.25 533.91V533.92Z" fill="white"/>
                    <path d="M3300.03 530.99C3300.14 530.91 3300.24 530.82 3300.36 530.77C3300.4 530.75 3300.49 530.86 3300.56 530.91C3300.45 530.99 3300.35 531.08 3300.23 531.13C3300.19 531.15 3300.1 531.04 3300.03 530.99Z" fill="white"/>
                    <path d="M3379.66 471.72C3379.77 471.64 3379.87 471.55 3379.99 471.5C3380.03 471.48 3380.12 471.59 3380.19 471.64C3380.08 471.72 3379.98 471.81 3379.86 471.86C3379.82 471.88 3379.73 471.77 3379.66 471.72Z" fill="white"/>
                    <path d="M3212 584.23C3212.12 584.27 3212.25 584.32 3212.37 584.37C3212.32 584.44 3212.27 584.57 3212.22 584.56C3212.09 584.56 3211.96 584.49 3211.84 584.45C3211.89 584.38 3211.94 584.31 3212 584.23Z" fill="white"/>
                    <path d="M3205.82 626.09C3205.74 626.03 3205.67 625.98 3205.6 625.93C3205.71 625.85 3205.81 625.76 3205.93 625.71C3205.97 625.69 3206.06 625.8 3206.13 625.85C3206.02 625.93 3205.92 626.01 3205.81 626.09H3205.82Z" fill="white"/>
                    <path d="M3192.57 548.45C3192.53 548.57 3192.48 548.7 3192.43 548.82C3192.36 548.77 3192.23 548.72 3192.24 548.67C3192.24 548.54 3192.31 548.41 3192.35 548.29C3192.42 548.34 3192.49 548.39 3192.57 548.45Z" fill="white"/>
                    <path d="M3218.5 515.27C3218.58 515.38 3218.67 515.48 3218.72 515.6C3218.74 515.64 3218.63 515.73 3218.58 515.8C3218.5 515.69 3218.41 515.59 3218.36 515.47C3218.34 515.43 3218.45 515.34 3218.5 515.27Z" fill="white"/>
                    <path d="M3216.31 471.06C3216.25 471.14 3216.2 471.21 3216.15 471.28C3216.07 471.17 3215.98 471.07 3215.93 470.95C3215.91 470.91 3216.02 470.82 3216.07 470.75C3216.15 470.86 3216.23 470.96 3216.31 471.07V471.06Z" fill="white"/>
                    <path d="M3162.56 461.49C3162.68 461.53 3162.81 461.58 3162.93 461.63C3162.88 461.7 3162.83 461.83 3162.78 461.82C3162.65 461.82 3162.52 461.75 3162.4 461.71C3162.45 461.64 3162.5 461.57 3162.56 461.49Z" fill="white"/>
                    <path d="M3193.53 566.25C3193.61 566.31 3193.68 566.36 3193.75 566.41C3193.64 566.49 3193.54 566.58 3193.42 566.63C3193.38 566.65 3193.29 566.54 3193.22 566.49C3193.33 566.41 3193.43 566.33 3193.54 566.25H3193.53Z" fill="white"/>
                    <path d="M3245.9 523.04C3245.82 522.98 3245.75 522.93 3245.68 522.88C3245.79 522.8 3245.89 522.71 3246.01 522.66C3246.05 522.64 3246.14 522.75 3246.21 522.8C3246.1 522.88 3246 522.96 3245.89 523.04H3245.9Z" fill="white"/>
                    <path d="M3236.68 392.26C3236.73 392.19 3236.8 392.06 3236.82 392.07C3236.95 392.12 3237.1 392.16 3237.17 392.26C3237.31 392.47 3237.22 392.59 3236.98 392.52C3236.86 392.48 3236.78 392.35 3236.68 392.26Z" fill="white"/>
                    <path d="M3237.46 392.46C3237.56 392.55 3237.75 392.66 3237.74 392.74C3237.71 392.96 3237.55 393.02 3237.44 392.89C3237.35 392.78 3237.29 392.63 3237.22 392.5C3237.3 392.49 3237.38 392.48 3237.46 392.46Z" fill="white"/>
                    <path d="M3316.71 552.88C3316.83 552.92 3316.96 552.97 3317.08 553.02C3317.03 553.09 3316.98 553.22 3316.93 553.21C3316.8 553.21 3316.67 553.14 3316.55 553.1C3316.6 553.03 3316.65 552.96 3316.71 552.88Z" fill="white"/>
                    <path d="M3159.31 404.5C3159.43 404.54 3159.56 404.59 3159.68 404.64C3159.63 404.71 3159.58 404.84 3159.53 404.83C3159.4 404.83 3159.27 404.76 3159.15 404.72C3159.2 404.65 3159.25 404.58 3159.31 404.5Z" fill="white"/>
                    <path d="M3240.42 386.06C3240.5 386.12 3240.57 386.17 3240.64 386.22C3240.53 386.3 3240.43 386.39 3240.31 386.44C3240.27 386.46 3240.18 386.35 3240.11 386.3C3240.22 386.22 3240.32 386.14 3240.43 386.06H3240.42Z" fill="white"/>
                    <path d="M3156.78 444.15C3156.66 444.11 3156.53 444.06 3156.41 444.01C3156.46 443.94 3156.51 443.81 3156.56 443.82C3156.69 443.82 3156.82 443.89 3156.94 443.93C3156.89 444 3156.84 444.07 3156.78 444.15Z" fill="white"/>
                    <path d="M3287.4 468.67C3287.52 468.71 3287.65 468.76 3287.77 468.81C3287.72 468.88 3287.67 469.01 3287.62 469C3287.49 469 3287.36 468.93 3287.24 468.89C3287.29 468.82 3287.34 468.75 3287.4 468.67Z" fill="white"/>
                    <path d="M3175.37 525.51C3175.31 525.59 3175.26 525.66 3175.21 525.73C3175.13 525.62 3175.04 525.52 3174.99 525.4C3174.97 525.36 3175.08 525.27 3175.13 525.2C3175.21 525.31 3175.29 525.41 3175.37 525.52V525.51Z" fill="white"/>
                    <path d="M3256.3 413.39C3256.41 413.31 3256.51 413.22 3256.63 413.17C3256.67 413.15 3256.76 413.26 3256.83 413.31C3256.72 413.39 3256.62 413.48 3256.5 413.53C3256.46 413.55 3256.37 413.44 3256.3 413.39Z" fill="white"/>
                    <path d="M3171.2 514.37C3171.32 514.41 3171.45 514.46 3171.57 514.51C3171.52 514.58 3171.47 514.71 3171.42 514.7C3171.29 514.7 3171.16 514.63 3171.04 514.59L3171.2 514.37Z" fill="white"/>
                    <path d="M3276.37 529.76C3276.41 529.64 3276.46 529.51 3276.51 529.39C3276.58 529.44 3276.71 529.49 3276.7 529.54C3276.7 529.67 3276.63 529.8 3276.59 529.92C3276.52 529.87 3276.45 529.82 3276.37 529.76Z" fill="white"/>
                    <path d="M3349.04 632.99C3349.15 632.91 3349.25 632.82 3349.37 632.77C3349.41 632.75 3349.5 632.86 3349.57 632.91C3349.46 632.99 3349.36 633.08 3349.24 633.13C3349.2 633.15 3349.11 633.04 3349.04 632.99Z" fill="white"/>
                    <path d="M3464.72 468.19C3465.99 469.47 3465.51 470.78 3465.03 472.02C3464.85 472.47 3464.48 473.13 3464.48 473.39C3464.45 475.09 3462.65 474.19 3462.18 475.12C3461.35 474.52 3460.16 474.09 3459.81 473.29C3459.47 472.52 3459.12 471.53 3459.16 470.6C3459.19 469.71 3458.84 468.46 3460.54 468.63C3460.79 468.65 3461.08 468.17 3461.37 467.96C3462.68 467 3463.82 467.13 3464.73 468.2L3464.72 468.19Z" fill="white"/>
                    <path d="M3319.37 468.66C3320.19 467.98 3321.31 468.15 3321.55 468.44C3322.46 469.56 3323.84 469.16 3324.9 469.76C3325.64 470.19 3326.03 470.56 3325.91 471.39C3325.71 472.74 3325.31 473.23 3324.31 473.29C3323.88 473.32 3323.47 473.39 3323.05 473.48C3321.22 473.86 3318.3 471.44 3319 469.41C3319.12 469.05 3319.22 468.74 3319.38 468.67L3319.37 468.66Z" fill="white"/>
                    <path d="M3385.84 524.43C3386.77 524.54 3387.67 523.51 3388.58 524.43C3388.71 524.56 3388.79 524.77 3388.94 524.84C3389.57 525.11 3390.35 525.18 3390.53 526.02C3390.68 526.69 3390.23 527.19 3390 527.74C3389.53 528.83 3387.97 529.43 3386.72 529.05C3385.75 528.75 3385.25 528.11 3385.04 527.1C3384.83 526.05 3385.52 525.31 3385.83 524.42L3385.84 524.43Z" fill="white"/>
                    <path d="M3337.02 643.89C3335.8 644.92 3334.87 644.94 3334.02 644.15C3333.55 643.72 3333.2 643.16 3332.75 642.7C3332.33 642.26 3332.41 641.95 3332.87 641.61C3333.38 641.22 3333.8 640.7 3334.35 640.38C3335.28 639.84 3336.28 639.94 3337.23 640.38C3337.42 640.47 3337.65 640.79 3337.63 640.98C3337.56 641.7 3337.74 642.37 3337.81 643.05C3337.84 643.38 3337.11 643.8 3337.02 643.88V643.89Z" fill="white"/>
                    <path d="M3246.43 564.42C3246.94 563.91 3247.65 563.16 3248.61 563.82C3249.06 564.13 3249.91 564.72 3249.54 565.3C3249.14 565.93 3249.21 566.97 3248.25 567.19C3247.58 567.34 3246.82 567.27 3246.24 567.56C3245.7 567.83 3245.49 567.76 3245.13 567.36C3244.62 566.78 3244.69 566.17 3245.05 565.65C3245.35 565.21 3245.86 564.92 3246.43 564.43V564.42Z" fill="white"/>
                    <path d="M3348.68 660.07C3349.22 659.59 3350.06 659.62 3350.56 660.13C3350.73 660.3 3351.22 662.87 3351.13 663.06C3350.92 663.5 3349.25 663.73 3349.27 663.65C3349.59 662.54 3348.24 662.96 3348.12 662.32C3348.01 661.71 3348.33 660.38 3348.68 660.07Z" fill="white"/>
                    <path d="M3445.71 528.4C3445.47 528.1 3445.01 527.72 3445.29 527.48C3445.8 527.04 3446.16 526.32 3447.06 526.38C3447.56 526.42 3447.83 526.84 3448.24 526.87C3448.9 526.93 3449.19 527.13 3449.17 527.86C3449.15 528.64 3448.7 529.15 3448.1 529.18C3447.25 529.23 3446.23 529.39 3445.71 528.39V528.4Z" fill="white"/>
                    <path d="M3350.9 515.83C3350.44 516.18 3350.06 516.89 3349.38 516.02C3349.23 515.83 3348.68 515.96 3348.32 515.91C3347.91 515.85 3347.51 515.76 3347.1 515.69C3347.53 515.25 3347.88 514.56 3348.4 514.42C3349.06 514.23 3349.84 514.5 3350.56 514.52C3350.96 514.53 3351.33 514.62 3351.44 515C3351.57 515.41 3351.16 515.61 3350.9 515.84V515.83Z" fill="white"/>
                    <path d="M3453.35 621.48C3453.21 621.6 3453.09 621.73 3452.94 621.83C3452.6 622.05 3452.36 622.64 3451.85 622.26C3451.32 621.87 3451.11 621.32 3451.25 620.63C3451.37 620.04 3451.73 620.01 3452.21 620.01C3452.52 620.01 3452.84 619.74 3453.15 619.73C3453.57 619.73 3454.09 619.62 3454.34 620.11C3454.63 620.7 3454.02 620.83 3453.76 621.13C3453.64 621.26 3453.49 621.37 3453.35 621.48Z" fill="white"/>
                    <path d="M3338.68 660.68C3338.84 661.51 3339.26 662.32 3339.01 663.2C3338.95 663.4 3338.7 663.63 3338.36 663.57C3337.94 663.49 3337.34 663.67 3337.18 663.15C3336.98 662.51 3336.91 661.81 3336.86 661.14C3336.8 660.34 3337.53 660.97 3337.79 660.68C3338.06 660.38 3338.38 660.28 3338.68 660.69V660.68Z" fill="white"/>
                    <path d="M3383.18 484.62C3382.86 485.01 3382.52 485.08 3382.14 484.59C3381.56 483.84 3381.6 483.11 3382.21 482.53C3382.76 482.02 3383.38 482.1 3383.89 482.42C3384.48 482.79 3384.12 483.85 3383.18 484.62Z" fill="white"/>
                    <path d="M3373.83 402.56C3373.62 402.74 3373.44 402.96 3373.2 403.09C3372.65 403.39 3372.01 402.95 3372.06 402.33C3372.09 401.97 3372.06 401.58 3372.16 401.24C3372.3 400.81 3372.7 400.43 3373.11 400.42C3373.59 400.41 3373.94 401.01 3374.51 400.93C3374.4 401.43 3374.65 402.05 3374.03 402.39C3373.95 402.43 3373.89 402.51 3373.82 402.57L3373.83 402.56Z" fill="white"/>
                    <path d="M3372.4 622.37C3372.72 622.4 3372.99 622.43 3373.26 622.43C3373.94 622.43 3373.99 623.46 3374.77 623.35C3375.19 623.29 3375.45 623.61 3375.38 624.02C3375.31 624.41 3374.98 624.55 3374.58 624.5C3374.04 624.44 3373.49 624.44 3372.96 624.35C3372.71 624.31 3372.2 624.58 3372.27 623.94C3372.33 623.42 3372.36 622.89 3372.4 622.37Z" fill="white"/>
                    <path d="M3259.03 631.2C3258.72 630.7 3259.06 629.7 3259.64 629.38C3259.87 629.25 3261.5 629.73 3261.64 630.02C3261.98 630.76 3261.23 630.91 3260.88 631.19C3260.24 631.73 3259.33 631.67 3259.03 631.19V631.2Z" fill="white"/>
                    <path d="M3281.47 558.03C3281.9 558.4 3282.03 558.74 3281.53 559.13C3280.92 559.6 3280 559.69 3279.64 559.27C3279.28 558.85 3279.51 557.93 3280.1 557.43C3280.98 556.69 3281.06 557.79 3281.47 558.02V558.03Z" fill="white"/>
                    <path d="M3280.36 551.41C3280.86 551.33 3281.4 551.22 3281.8 551.63C3282.24 552.08 3281.83 553.27 3281.17 553.62C3280.73 553.85 3280.53 553.56 3280.32 553.27C3279.85 552.6 3279.87 551.72 3280.35 551.4L3280.36 551.41Z" fill="white"/>
                    <path d="M3341.74 609.35C3341.47 609.58 3341.32 609.69 3341.2 609.82C3340.75 610.32 3340.2 609.98 3339.69 610.01C3339.81 609.52 3339.56 608.92 3340.12 608.55C3340.27 608.45 3340.4 608.33 3340.53 608.2C3340.96 607.77 3341.3 607.58 3341.81 608.2C3342.3 608.79 3342.18 609.12 3341.74 609.35Z" fill="white"/>
                    <path d="M3252.21 478.72C3252.66 479.18 3252.42 479.56 3252.09 479.65C3251.44 479.84 3250.74 479.92 3250.06 479.98C3249.42 480.04 3249.73 479.55 3249.71 479.27C3249.63 478.05 3250.77 478.66 3251.26 478.26C3251.82 477.8 3251.95 478.6 3252.22 478.72H3252.21Z" fill="white"/>
                    <path d="M3313.2 592.33C3313.68 591.73 3314.31 591.81 3314.84 592.12C3315.23 592.35 3315.08 593.86 3314.71 593.93C3314 594.08 3313.42 593.82 3312.98 593.21C3312.67 592.78 3312.93 592.57 3313.2 592.33Z" fill="white"/>
                    <path d="M3478.61 564.35C3478.89 564.82 3478.76 565.32 3478.55 565.8C3478.43 566.08 3476.95 565.74 3476.55 565.34C3476.18 564.98 3476.42 564.72 3476.71 564.48C3477.3 564.01 3478.24 563.93 3478.61 564.35Z" fill="white"/>
                    <path d="M3318.18 630.73C3318.07 630.47 3318.04 630.14 3318.36 630.07C3319.11 629.9 3319.82 629.54 3320.64 629.64C3320.98 629.68 3321.2 629.9 3321.24 630.11C3321.28 630.3 3321.07 630.71 3320.9 630.75C3320.24 630.92 3319.55 630.99 3318.86 631.09C3318.57 631.13 3318.34 630.99 3318.17 630.73H3318.18Z" fill="white"/>
                    <path d="M3305.44 657.79C3305.64 657.58 3305.9 657.32 3306.28 657.62C3306.61 657.88 3306.48 658.25 3306.51 658.59C3306.58 659.28 3306.02 659.52 3305.61 659.78C3305.37 659.93 3304.69 659.88 3304.62 659.72C3304.46 659.39 3304.47 658.9 3304.62 658.55C3304.74 658.26 3305.13 658.07 3305.45 657.8L3305.44 657.79Z" fill="white"/>
                    <path d="M3234.28 524.89C3233.93 524.6 3233.87 524.26 3234.35 523.89C3234.96 523.43 3235.86 523.35 3236.22 523.76C3236.83 524.45 3235.55 524.71 3235.85 525.39C3235.9 525.5 3234.77 525.39 3234.28 524.89Z" fill="white"/>
                    <path d="M3391.17 591.81C3391.34 591.81 3391.55 591.72 3391.66 591.8C3392.24 592.22 3392.3 593.81 3391.74 594.21C3391.55 594.35 3391.23 594.5 3391.06 594.42C3390.88 594.34 3390.74 594.01 3390.72 593.78C3390.65 592.77 3390.83 592.1 3391.17 591.8V591.81Z" fill="white"/>
                    <path d="M3437.51 500.1C3437.33 499.89 3437.12 499.71 3436.99 499.48C3436.67 498.88 3437.28 498.75 3437.55 498.46C3437.9 498.08 3438.17 498.32 3438.41 498.62C3438.63 498.89 3438.91 499.11 3438.84 499.53C3438.77 499.97 3438.85 500.49 3438.33 500.65C3437.92 500.78 3437.74 500.35 3437.5 500.1H3437.51Z" fill="white"/>
                    <path d="M3319.25 627.85C3318.75 628.13 3318.54 627.81 3318.35 627.52C3317.88 626.84 3317.9 625.97 3318.4 625.67C3318.82 625.42 3319.04 625.74 3319.24 626.03C3319.71 626.71 3319.68 627.56 3319.24 627.85H3319.25Z" fill="white"/>
                    <path d="M3228.13 529.6C3228.2 528.97 3228.12 528.36 3228.76 527.92C3229.29 527.56 3229.51 527.79 3229.83 528.15C3230.18 528.54 3230.25 528.79 3229.84 529.24C3229.3 529.83 3228.71 529.55 3228.12 529.6H3228.13Z" fill="white"/>
                    <path d="M3235.97 518.16C3236.14 518.14 3236.37 518.04 3236.46 518.11C3236.73 518.34 3237.1 518.62 3237.13 518.92C3237.19 519.4 3237.32 520.01 3236.78 520.37C3236.37 520.65 3236.14 520.32 3235.94 520.03C3235.47 519.34 3235.49 518.53 3235.97 518.17V518.16Z" fill="white"/>
                    <path d="M3469.02 520.21C3469.31 519.98 3469.63 519.73 3469.9 520.17C3470.24 520.74 3470.26 521.4 3469.69 521.78C3469.39 521.98 3468.85 521.82 3468.43 521.83C3468.5 521.24 3468.34 520.56 3469.03 520.22L3469.02 520.21Z" fill="white"/>
                    <path d="M3347.66 439.96C3348.05 440.49 3347.92 441.5 3347.52 441.74C3347.1 441.99 3346.92 441.64 3346.68 441.39C3346.2 440.89 3346.19 440.3 3346.58 439.84C3346.7 439.7 3347.43 439.69 3347.66 439.96Z" fill="white"/>
                    <path d="M3295.15 583.8C3295.5 583.5 3295.88 583.21 3296.08 583.66C3296.32 584.22 3296.36 584.86 3296.49 585.47C3295.86 585.47 3295.3 585.42 3294.88 584.79C3294.49 584.2 3294.95 584.03 3295.15 583.8Z" fill="white"/>
                    <path d="M3278.5 446.99C3279.02 446.44 3279.29 446.68 3279.66 447.05C3280.24 447.63 3279.94 447.93 3279.47 448.33C3278.99 448.74 3278.75 448.55 3278.38 448.16C3277.84 447.6 3277.99 447.23 3278.5 446.98V446.99Z" fill="white"/>
                    <path d="M3296.75 595.44C3297 595.86 3297.78 596.22 3296.99 596.72C3296.56 596.99 3295.96 596.97 3295.54 596.57C3294.85 595.92 3295.79 595.46 3295.94 595C3296.13 594.43 3296.42 595.35 3296.75 595.44Z" fill="white"/>
                    <path d="M3306.03 676.17C3306.23 676 3306.42 675.79 3306.65 675.66C3307.07 675.42 3307.28 675.75 3307.49 676.03C3307.64 676.23 3307.94 676.38 3307.82 676.7C3307.68 677.07 3307.4 677.23 3307 677.16C3306.82 677.13 3306.64 677.13 3306.47 677.11C3306.13 677.07 3305.58 677.13 3305.5 676.96C3305.32 676.59 3305.81 676.41 3306.04 676.16L3306.03 676.17Z" fill="white"/>
                    <path d="M3400 436.64C3399.74 436.04 3400.3 435.89 3400.59 435.62C3401.13 435.12 3401.34 435.71 3401.62 436C3401.88 436.26 3402.09 436.57 3401.66 436.88C3401.22 437.19 3400.26 437.05 3400 436.65V436.64Z" fill="white"/>
                    <path d="M3285.95 526.69C3286.25 527.07 3286.49 527.36 3286.05 527.66C3285.61 527.97 3284.63 527.84 3284.39 527.42C3284.15 527 3284.53 526.83 3284.76 526.57C3285.32 525.94 3285.67 526.29 3285.95 526.69Z" fill="white"/>
                    <path d="M3297.14 632.51C3297.06 632.34 3296.81 632.05 3296.88 631.9C3297.06 631.49 3297.5 631.73 3297.74 631.81C3298.1 631.94 3298.92 631.35 3298.77 632.39C3298.72 632.73 3298.77 633.12 3298.39 633.3C3297.62 633.65 3297.63 632.75 3297.13 632.51H3297.14Z" fill="white"/>
                    <path d="M3437.46 610.37C3437.89 610.33 3438.62 610.03 3438.65 610.59C3438.68 611.07 3439.35 611.45 3438.89 611.92C3438.62 612.2 3437.33 611.54 3437.16 611.04C3437.07 610.78 3437.18 610.54 3437.45 610.37H3437.46Z" fill="white"/>
                    <path d="M3291.02 458.68C3291.02 458.68 3291.16 458.21 3291.35 457.81C3291.43 457.64 3292.65 457.96 3292.77 458.19C3293.09 458.79 3292.49 458.91 3292.22 459.2C3291.9 459.55 3291.61 459.4 3291.02 458.68Z" fill="white"/>
                    <path d="M3318.4 441.2C3319 440.93 3319.12 441.51 3319.41 441.78C3319.77 442.12 3319.6 442.5 3319.26 442.61C3318.87 442.74 3318.4 442.69 3317.98 442.63C3317.83 442.61 3318.18 441.32 3318.39 441.21L3318.4 441.2Z" fill="white"/>
                    <path d="M3482.1 478.38C3482.48 478.68 3482.44 478.97 3482.24 479.14C3481.7 479.59 3481.13 479.5 3480.59 479.11C3480.31 478.91 3480.36 478.62 3480.56 478.44C3481.1 477.95 3481.67 478.12 3482.1 478.39V478.38Z" fill="white"/>
                    <path d="M3394.16 612.58C3393.85 612.08 3393.85 611.51 3394.21 611.21C3394.39 611.06 3395.06 611.04 3395.4 611.46C3395.86 612.02 3395.3 612.21 3395.02 612.49C3394.76 612.75 3394.47 612.93 3394.16 612.58Z" fill="white"/>
                    <path d="M3410.94 422.24C3411.13 422.41 3411.25 423.7 3411.1 423.7C3410.68 423.69 3410.21 423.66 3409.85 423.47C3409.51 423.29 3409.47 422.89 3409.86 422.63C3410.19 422.41 3410.4 421.84 3410.95 422.24H3410.94Z" fill="white"/>
                    <path d="M3391.12 617.31C3391.59 617.11 3392.18 617.03 3392.64 617.6C3392.81 617.81 3392.81 618.1 3392.51 618.26C3391.92 618.56 3391.33 618.57 3390.87 618.04C3390.7 617.84 3390.7 617.55 3391.12 617.31Z" fill="white"/>
                    <path d="M3420 671.75C3420.36 671.4 3420.73 671.55 3420.85 671.89C3420.98 672.28 3420.93 672.75 3420.87 673.17C3420.85 673.32 3419.56 672.97 3419.45 672.76C3419.28 672.46 3419.5 672.07 3420.01 671.75H3420Z" fill="white"/>
                    <path d="M3298.92 591.46C3299.22 591.22 3299.51 590.97 3299.81 591.39C3300.14 591.85 3300.12 592.43 3299.76 592.73C3299.58 592.88 3298.91 592.9 3298.57 592.48C3298.11 591.91 3298.69 591.74 3298.92 591.46Z" fill="white"/>
                    <path d="M3335.7 670.51C3336.2 670.4 3336.68 670.34 3337.09 670.72C3337.33 670.95 3337.31 671.24 3337.09 671.39C3336.72 671.63 3336.46 672.41 3335.82 671.78C3335.43 671.4 3335.78 670.94 3335.7 670.51Z" fill="white"/>
                    <path d="M3311.62 592.24C3311.78 592.23 3311.95 592.22 3312.11 592.2C3312.07 592.99 3311.88 593.79 3312.3 594.55C3312.32 594.58 3312.06 594.82 3311.89 594.88C3311.8 594.91 3311.53 594.75 3311.54 594.71C3311.67 593.99 3310.98 593.37 3311.3 592.64C3311.37 592.49 3311.51 592.37 3311.62 592.23V592.24Z" fill="white"/>
                    <path d="M3315.06 594.81C3315.39 595.24 3314.66 595.47 3314.31 595.71C3313.62 596.19 3313.49 595.36 3313.15 595.07C3313.05 594.98 3312.95 594.42 3313.36 594.67C3313.96 595.04 3314.3 593.71 3315.06 594.81Z" fill="white"/>
                    <path d="M3438.59 476.71C3438.08 476.28 3438.44 475.73 3438.39 475.24C3438.73 475.26 3439.24 475.14 3439.38 475.32C3439.69 475.71 3439.6 476.22 3439.27 476.64C3439.07 476.89 3438.83 476.84 3438.59 476.71Z" fill="white"/>
                    <path d="M3290.68 465.09C3290.89 465.42 3291.42 465.69 3290.87 466.18C3290.47 466.54 3290.23 466.29 3290 466C3289.76 465.68 3289.25 465.4 3289.79 464.91C3290.19 464.55 3290.42 464.82 3290.67 465.09H3290.68Z" fill="white"/>
                    <path d="M3485.2 533.37C3486.1 533.26 3486.59 533.58 3486.93 534.14C3487.01 534.27 3486.98 534.47 3487 534.63C3486.82 534.62 3486.64 534.57 3486.47 534.59C3485.81 534.69 3485.53 534.21 3485.22 533.79C3485.13 533.66 3485.2 533.41 3485.2 533.36V533.37Z" fill="white"/>
                    <path d="M3276.27 440.85C3276.37 440.76 3276.64 440.97 3276.87 441.28C3277.1 441.6 3277.64 441.87 3277.09 442.37C3276.71 442.72 3276.45 442.51 3276.22 442.2C3275.98 441.88 3275.47 441.59 3276.27 440.85Z" fill="white"/>
                    <path d="M3288.86 497.94C3288.88 498.06 3288.97 498.27 3288.91 498.33C3288.48 498.78 3288.05 499.19 3287.31 499C3287.01 498.92 3286.85 498.84 3286.98 498.55C3287.19 498.11 3288.49 497.69 3288.86 497.94Z" fill="white"/>
                    <path d="M3392.72 472.12C3393.51 471.73 3393.53 472.61 3393.9 472.9C3394.06 473.03 3393.85 473.42 3393.6 473.53C3392.85 473.87 3392.75 473.08 3392.42 472.76C3392.27 472.61 3392.46 472.26 3392.73 472.12H3392.72Z" fill="white"/>
                    <path d="M3321.45 551.9C3322.24 551.51 3322.26 552.39 3322.63 552.68C3322.79 552.81 3322.58 553.2 3322.33 553.31C3321.58 553.65 3321.48 552.86 3321.15 552.54C3321 552.39 3321.19 552.04 3321.46 551.9H3321.45Z" fill="white"/>
                    <path d="M3439.89 586.47C3439.99 586.38 3440.26 586.59 3440.49 586.9C3440.72 587.22 3441.26 587.49 3440.71 587.99C3440.33 588.34 3440.07 588.13 3439.84 587.82C3439.6 587.5 3439.09 587.21 3439.89 586.47Z" fill="white"/>
                    <path d="M3259.77 590.29C3259.56 590.83 3259.36 591.38 3259.13 591.93C3259.12 591.96 3258.94 591.95 3258.88 591.91C3258.58 591.68 3258.49 591.37 3258.55 590.98C3258.61 590.54 3258.6 590.08 3259.16 589.99C3259.29 589.97 3259.45 590.12 3259.78 590.29H3259.77Z" fill="white"/>
                    <path d="M3304.05 615.04C3303.79 614.78 3303.5 614.51 3303.84 614.14C3304.28 613.66 3304.59 613.93 3304.9 614.33C3305.11 614.61 3305.44 614.86 3305.1 615.23C3304.67 615.7 3304.33 615.47 3304.05 615.04Z" fill="white"/>
                    <path d="M3357.76 630.44C3358.23 630.24 3358.48 630.19 3358.64 630.49C3358.94 631.08 3358.94 631.66 3358.42 632.13C3358.32 632.22 3358.1 632.18 3357.93 632.2C3357.87 631.59 3357.81 630.98 3357.76 630.44Z" fill="white"/>
                    <path d="M3294.6 540.73C3294.93 540.36 3295.28 540.19 3295.42 540.38C3295.69 540.75 3296.45 540.98 3296 541.67C3295.85 541.9 3295.48 542.05 3295.32 541.87C3295.01 541.52 3294.42 541.23 3294.61 540.73H3294.6Z" fill="white"/>
                    <path d="M3447.5 569.83C3447.8 569.6 3448.07 569.37 3448.4 569.79C3448.86 570.36 3448.32 570.56 3448.03 570.84C3447.76 571.1 3447.48 571.3 3447.14 570.88C3446.67 570.31 3447.24 570.13 3447.5 569.83Z" fill="white"/>
                    <path d="M3393.38 506.23C3392.99 505.44 3393.87 505.42 3394.16 505.05C3394.29 504.89 3394.68 505.1 3394.79 505.35C3395.13 506.1 3394.34 506.2 3394.02 506.53C3393.87 506.68 3393.52 506.49 3393.38 506.22V506.23Z" fill="white"/>
                    <path d="M3351.57 638.35C3351.31 638.09 3351.02 637.82 3351.36 637.45C3351.8 636.97 3352.11 637.24 3352.42 637.64C3352.63 637.92 3352.96 638.17 3352.62 638.54C3352.18 639.01 3351.85 638.78 3351.57 638.35Z" fill="white"/>
                    <path d="M3242.16 530.65C3241.7 530.21 3241.75 529.97 3241.95 529.76C3242.4 529.29 3242.71 529.58 3242.99 529.97C3243.13 530.18 3243.51 530.52 3243.3 530.6C3242.88 530.75 3242.37 530.65 3242.16 530.65Z" fill="white"/>
                    <path d="M3463.31 538.87C3463.45 538.83 3463.69 538.77 3463.93 538.7C3463.87 539.19 3464.09 539.77 3463.55 540.13C3463.32 540.28 3463.09 540.27 3462.87 540C3462.42 539.44 3462.95 539.25 3463.3 538.87H3463.31Z" fill="white"/>
                    <path d="M3332.5 689.97C3332.69 690.12 3332.66 690.39 3332.48 690.59C3332.24 690.86 3331.9 691.08 3331.62 690.64C3331.42 690.33 3330.76 690.09 3331.23 689.66C3331.4 689.51 3332.09 689.5 3332.5 689.96V689.97Z" fill="white"/>
                    <path d="M3218.09 563.93C3218.53 563.46 3219.07 563.8 3219.57 563.77C3219.45 563.99 3219.38 564.26 3219.21 564.41C3218.92 564.67 3218.63 565.17 3218.13 564.64C3217.9 564.4 3217.94 564.19 3218.09 563.93Z" fill="white"/>
                    <path d="M3353.96 667.54C3354.26 667.77 3354.01 667.46 3353.92 667.8C3353.87 667.99 3353.57 668.24 3353.39 668.24C3352.9 668.22 3352.3 668.39 3352.01 667.77C3351.98 667.7 3351.99 667.54 3352.02 667.52C3352.67 667.22 3353.32 667.58 3353.96 667.54Z" fill="white"/>
                    <path d="M3363.55 616.98C3364.05 617.06 3364.68 616.87 3364.92 617.5C3364.98 617.65 3364.77 618.02 3364.58 618.13C3364 618.46 3363.87 617.83 3363.57 617.57C3363.37 617.39 3363.28 617.19 3363.54 616.98H3363.55Z" fill="white"/>
                    <path d="M3355.05 528.99C3355.19 528.87 3355.31 528.71 3355.47 528.65C3355.57 528.61 3355.83 528.69 3355.85 528.77C3355.95 529.06 3354.67 530.17 3354.35 530.08C3354.28 530.06 3354.19 529.76 3354.24 529.71C3354.49 529.45 3354.78 529.23 3355.05 528.99Z" fill="white"/>
                    <path d="M3449.06 645.48C3449.47 645.09 3449.75 645.28 3449.96 645.6C3450.12 645.83 3450.1 646.06 3449.84 646.28C3449.45 646.61 3449.18 646.43 3448.98 646.11C3448.83 645.89 3448.77 645.61 3449.06 645.48Z" fill="white"/>
                    <path d="M3225.43 547.49C3225.7 547.37 3225.91 547.35 3226.13 547.62C3226.46 548.01 3226.28 548.27 3225.96 548.49C3225.73 548.65 3225.5 548.63 3225.28 548.37C3224.95 547.98 3225.16 547.74 3225.43 547.5V547.49Z" fill="white"/>
                    <path d="M3396.83 439.55C3397.1 439.43 3397.31 439.41 3397.53 439.68C3397.86 440.07 3397.68 440.33 3397.36 440.55C3397.13 440.71 3396.9 440.69 3396.68 440.43C3396.35 440.04 3396.56 439.8 3396.83 439.56V439.55Z" fill="white"/>
                    <path d="M3472.87 511.73C3473.09 511.99 3473.26 512.26 3472.87 512.59C3472.61 512.81 3472.38 512.8 3472.18 512.61C3471.9 512.35 3471.76 512.06 3472.15 511.73C3472.41 511.51 3472.61 511.55 3472.87 511.73Z" fill="white"/>
                    <path d="M3268.96 459.95C3268.82 459.46 3268.79 458.99 3269.15 458.59C3269.25 458.48 3269.58 458.42 3269.58 458.43C3269.79 458.9 3269.8 459.37 3269.43 459.77C3269.33 459.88 3269.13 459.88 3268.96 459.95Z" fill="white"/>
                    <path d="M3362.4 428.29C3362.6 428.03 3362.87 428.09 3363.07 428.29C3363.35 428.55 3363.49 428.84 3363.1 429.17C3362.84 429.39 3362.61 429.38 3362.41 429.19C3362.13 428.93 3361.98 428.62 3362.41 428.3L3362.4 428.29Z" fill="white"/>
                    <path d="M3296.45 515.87C3296.72 515.75 3296.93 515.73 3297.15 516C3297.48 516.39 3297.3 516.65 3296.98 516.87C3296.75 517.03 3296.52 517.01 3296.3 516.75C3295.97 516.36 3296.18 516.12 3296.45 515.88V515.87Z" fill="white"/>
                    <path d="M3396.22 676.07C3396.54 675.93 3396.74 675.91 3396.96 676.18C3397.29 676.57 3397.11 676.83 3396.79 677.05C3396.56 677.21 3396.33 677.18 3396.11 676.93C3395.77 676.53 3396.02 676.31 3396.22 676.08V676.07Z" fill="white"/>
                    <path d="M3378.46 662.19C3378.83 662.57 3378.63 662.85 3378.31 663.07C3378.08 663.23 3377.85 663.21 3377.63 662.95C3377.3 662.56 3377.48 662.3 3377.8 662.08C3378.03 661.92 3378.3 661.91 3378.46 662.19Z" fill="white"/>
                    <path d="M3275.24 422.16C3274.96 422.38 3274.67 422.61 3274.38 422.19C3274.29 422.06 3274.26 421.89 3274.2 421.73C3274.59 421.71 3274.99 421.69 3275.38 421.67C3275.33 421.83 3275.29 421.99 3275.24 422.16Z" fill="white"/>
                    <path d="M3319.01 445.75C3319.29 445.53 3319.58 445.3 3319.87 445.72C3319.96 445.85 3319.99 446.02 3320.05 446.18C3319.66 446.2 3319.26 446.22 3318.87 446.24C3318.92 446.08 3318.96 445.92 3319.01 445.75Z" fill="white"/>
                    <path d="M3246.18 550.22C3246.55 550.24 3246.9 550.26 3247.25 550.28C3247.14 550.5 3247.07 550.78 3246.9 550.92C3246.51 551.21 3246.26 550.88 3246.08 550.58C3246.03 550.5 3246.15 550.32 3246.18 550.22Z" fill="white"/>
                    <path d="M3398.46 512.16C3398.53 512.36 3398.68 512.61 3398.63 512.65C3398.27 512.98 3397.86 512.67 3397.47 512.71C3397.52 512.55 3397.52 512.35 3397.62 512.24C3397.87 511.96 3398.18 511.83 3398.46 512.15V512.16Z" fill="white"/>
                    <path d="M3477.86 476.45C3478 476.44 3478.19 476.36 3478.27 476.42C3478.54 476.64 3478.82 476.94 3478.46 477.28C3478.3 477.43 3478.01 477.45 3477.78 477.53C3477.81 477.18 3477.84 476.83 3477.86 476.46V476.45Z" fill="white"/>
                    <path d="M3450.5 477.91C3450.16 477.55 3450.39 477.28 3450.69 477.07C3450.81 476.99 3451.01 477.02 3451.18 477C3451.1 477.39 3451.02 477.77 3450.93 478.16C3450.79 478.08 3450.65 478 3450.5 477.91Z" fill="white"/>
                    <path d="M3267.77 544.14C3267.84 544.13 3268.04 544.04 3268.11 544.1C3268.38 544.32 3268.66 544.62 3268.32 544.96C3268.17 545.12 3267.87 545.14 3267.64 545.22C3267.68 544.87 3267.72 544.53 3267.77 544.15V544.14Z" fill="white"/>
                    <path d="M3295.89 522.15C3296.26 521.81 3296.52 522.04 3296.73 522.34C3296.81 522.46 3296.78 522.66 3296.8 522.83C3296.41 522.75 3296.03 522.67 3295.64 522.58C3295.72 522.44 3295.8 522.3 3295.89 522.15Z" fill="white"/>
                    <path d="M3429.46 437.82C3429.63 437.79 3429.79 437.77 3429.95 437.74C3429.89 438.13 3429.82 438.51 3429.76 438.9C3429.61 438.83 3429.44 438.78 3429.33 438.67C3428.97 438.34 3429.17 438.07 3429.45 437.82H3429.46Z" fill="white"/>
                    <path d="M3387.1 646.81C3386.76 646.45 3386.99 646.18 3387.29 645.97C3387.41 645.89 3387.61 645.92 3387.78 645.9C3387.7 646.29 3387.62 646.67 3387.53 647.06C3387.39 646.98 3387.25 646.9 3387.1 646.81Z" fill="white"/>
                    <path d="M3413.35 674.19C3413.42 673.97 3413.43 673.78 3413.54 673.67C3413.8 673.39 3414.12 673.26 3414.39 673.67C3414.48 673.8 3414.5 673.98 3414.55 674.13C3414.16 674.15 3413.77 674.17 3413.35 674.2V674.19Z" fill="white"/>
                    <path d="M3222.58 601.6C3222.95 601.53 3223.05 601.84 3223.2 602.03C3223.25 602.09 3223.15 602.37 3223.07 602.4C3222.74 602.5 3222.62 602.18 3222.48 601.98C3222.43 601.91 3222.55 601.72 3222.59 601.6H3222.58Z" fill="white"/>
                    <path d="M3230.05 578.7C3230.22 578.44 3230.32 578.21 3230.49 578.05C3230.59 577.96 3230.81 578 3230.98 577.97C3230.87 578.19 3230.79 578.44 3230.62 578.61C3230.53 578.7 3230.3 578.66 3230.05 578.7Z" fill="white"/>
                    <path d="M3472.5 595.78C3472.87 595.71 3472.97 596.02 3473.12 596.21C3473.17 596.27 3473.07 596.55 3472.99 596.58C3472.66 596.68 3472.54 596.36 3472.4 596.16C3472.35 596.09 3472.47 595.9 3472.51 595.78H3472.5Z" fill="white"/>
                    <path d="M3471.84 493.02C3472.1 493.19 3472.33 493.29 3472.49 493.46C3472.58 493.56 3472.54 493.78 3472.57 493.95C3472.35 493.84 3472.1 493.76 3471.93 493.59C3471.84 493.5 3471.88 493.27 3471.84 493.02Z" fill="white"/>
                    <path d="M3469.65 491.02C3469.73 491.14 3469.91 491.32 3470.01 491.53C3470.12 491.78 3469.97 491.92 3469.74 491.76C3469.55 491.63 3469.37 491.42 3469.27 491.21C3469.15 490.96 3469.29 490.86 3469.65 491.01V491.02Z" fill="white"/>
                    <path d="M3488.6 591.35C3488.55 591.19 3488.41 590.96 3488.46 590.9C3488.67 590.65 3488.89 590.78 3489.02 591.01C3489.1 591.15 3489.22 591.39 3489.16 591.46C3488.95 591.71 3488.75 591.57 3488.59 591.35H3488.6Z" fill="white"/>
                    <path d="M3231.64 547.27C3231.8 547.22 3232.03 547.08 3232.09 547.13C3232.34 547.34 3232.21 547.56 3231.98 547.69C3231.84 547.77 3231.6 547.89 3231.53 547.83C3231.28 547.62 3231.42 547.42 3231.64 547.26V547.27Z" fill="white"/>
                    <path d="M3419.89 440.49C3420.06 440.23 3420.16 440 3420.33 439.84C3420.43 439.75 3420.65 439.79 3420.82 439.76C3420.71 439.98 3420.63 440.23 3420.46 440.4C3420.37 440.49 3420.14 440.45 3419.89 440.49Z" fill="white"/>
                    <path d="M3324.54 662.57C3324.66 662.56 3324.83 662.53 3324.99 662.51C3324.88 662.73 3324.8 662.98 3324.63 663.15C3324.54 663.24 3324.31 663.2 3324.15 663.23C3324.15 662.95 3324.24 662.72 3324.54 662.57Z" fill="white"/>
                    <path d="M3390.86 409.79C3391.05 409.98 3391.16 410.2 3390.88 410.37C3390.8 410.41 3390.58 410.26 3390.46 410.16C3390.25 409.99 3390.15 409.75 3390.44 409.59C3390.52 409.55 3390.72 409.72 3390.87 409.8L3390.86 409.79Z" fill="white"/>
                    <path d="M3353.65 639.1C3353.84 639.29 3353.95 639.51 3353.67 639.68C3353.59 639.72 3353.37 639.57 3353.25 639.47C3353.04 639.3 3352.94 639.06 3353.23 638.9C3353.31 638.86 3353.51 639.03 3353.66 639.11L3353.65 639.1Z" fill="white"/>
                    <path d="M3354.57 627.43C3354.76 627.62 3354.87 627.84 3354.59 628.01C3354.51 628.05 3354.29 627.9 3354.17 627.8C3353.96 627.63 3353.86 627.39 3354.15 627.23C3354.23 627.19 3354.43 627.36 3354.58 627.44L3354.57 627.43Z" fill="white"/>
                    <path d="M3351.8 584.35C3351.97 584.09 3352.07 583.86 3352.24 583.7C3352.34 583.61 3352.56 583.65 3352.73 583.62C3352.62 583.84 3352.54 584.09 3352.37 584.26C3352.28 584.35 3352.05 584.31 3351.8 584.35Z" fill="white"/>
                    <path d="M3258.62 546.68C3258.82 546.63 3259.06 546.58 3259.3 546.52C3259.25 546.68 3259.26 546.91 3259.15 546.99C3258.95 547.12 3258.69 547.16 3258.46 547.24C3258.51 547.08 3258.55 546.92 3258.62 546.68Z" fill="white"/>
                    <path d="M3338.15 509.33C3338.27 509.32 3338.44 509.29 3338.6 509.27C3338.49 509.49 3338.41 509.74 3338.24 509.91C3338.15 510 3337.92 509.96 3337.76 509.99C3337.76 509.71 3337.85 509.48 3338.15 509.33Z" fill="white"/>
                    <path d="M3315.93 472.66C3316.1 472.4 3316.2 472.17 3316.37 472.01C3316.47 471.92 3316.69 471.96 3316.86 471.93C3316.75 472.15 3316.67 472.4 3316.5 472.57C3316.41 472.66 3316.18 472.62 3315.93 472.66Z" fill="white"/>
                    <path d="M3280.67 415.44C3281.04 415.37 3281.14 415.68 3281.29 415.87C3281.34 415.93 3281.24 416.21 3281.16 416.24C3280.83 416.34 3280.71 416.02 3280.57 415.82C3280.52 415.75 3280.64 415.56 3280.68 415.44H3280.67Z" fill="white"/>
                    <path d="M3336.07 439.16C3336.26 439.35 3336.37 439.57 3336.09 439.74C3336.01 439.78 3335.79 439.63 3335.67 439.53C3335.46 439.36 3335.36 439.12 3335.65 438.96C3335.73 438.92 3335.93 439.09 3336.08 439.17L3336.07 439.16Z" fill="white"/>
                    <path d="M3455.66 591.9C3455.61 591.74 3455.47 591.51 3455.52 591.45C3455.73 591.2 3455.95 591.33 3456.08 591.56C3456.16 591.7 3456.28 591.94 3456.22 592.01C3456.01 592.26 3455.81 592.12 3455.65 591.9H3455.66Z" fill="white"/>
                    <path d="M3387.02 533.63C3387.18 533.58 3387.41 533.44 3387.47 533.49C3387.72 533.7 3387.59 533.92 3387.36 534.05C3387.22 534.13 3386.98 534.25 3386.91 534.19C3386.66 533.98 3386.8 533.78 3387.02 533.62V533.63Z" fill="white"/>
                    <path d="M3397.39 523.68C3397.44 523.55 3397.48 523.41 3397.56 523.31C3397.59 523.27 3397.72 523.31 3397.81 523.32C3397.76 523.45 3397.72 523.59 3397.64 523.69C3397.61 523.73 3397.48 523.69 3397.39 523.68Z" fill="white"/>
                    <path d="M3434.55 429.48C3434.6 429.35 3434.64 429.21 3434.72 429.11C3434.75 429.07 3434.88 429.11 3434.97 429.12C3434.92 429.25 3434.88 429.39 3434.8 429.49C3434.77 429.53 3434.64 429.49 3434.55 429.48Z" fill="white"/>
                    <path d="M3349.7 617.18C3349.83 617.15 3349.96 617.13 3350.1 617.1C3350.1 617.19 3350.12 617.33 3350.07 617.35C3349.95 617.41 3349.81 617.43 3349.68 617.46C3349.68 617.37 3349.69 617.29 3349.7 617.18Z" fill="white"/>
                    <path d="M3366.84 656.79C3366.74 656.79 3366.65 656.78 3366.56 656.77C3366.61 656.64 3366.65 656.5 3366.73 656.4C3366.76 656.36 3366.89 656.4 3366.98 656.41C3366.93 656.54 3366.88 656.67 3366.84 656.79Z" fill="white"/>
                    <path d="M3313.61 596.61C3313.64 596.74 3313.66 596.87 3313.69 597.01C3313.6 597.01 3313.46 597.03 3313.44 596.98C3313.38 596.86 3313.36 596.72 3313.33 596.59C3313.42 596.59 3313.5 596.6 3313.61 596.61Z" fill="white"/>
                    <path d="M3318.25 553.89C3318.38 553.94 3318.52 553.98 3318.62 554.06C3318.66 554.09 3318.62 554.22 3318.61 554.31C3318.48 554.26 3318.34 554.22 3318.24 554.14C3318.2 554.11 3318.24 553.98 3318.25 553.89Z" fill="white"/>
                    <path d="M3292.58 516.74C3292.58 516.84 3292.57 516.93 3292.56 517.02C3292.43 516.97 3292.29 516.93 3292.19 516.85C3292.15 516.82 3292.19 516.69 3292.2 516.6C3292.33 516.65 3292.46 516.7 3292.58 516.74Z" fill="white"/>
                    <path d="M3240.83 537.35C3240.96 537.32 3241.09 537.3 3241.23 537.27C3241.23 537.36 3241.25 537.5 3241.2 537.52C3241.08 537.58 3240.94 537.6 3240.81 537.63C3240.81 537.54 3240.82 537.46 3240.83 537.35Z" fill="white"/>
                    <path d="M3324.02 611.52C3324.12 611.52 3324.21 611.53 3324.3 611.54C3324.25 611.67 3324.21 611.81 3324.13 611.91C3324.1 611.95 3323.97 611.91 3323.88 611.9C3323.93 611.77 3323.98 611.64 3324.02 611.52Z" fill="white"/>
                    <path d="M3346.18 545.9C3346.08 545.9 3345.99 545.89 3345.9 545.88C3345.95 545.75 3345.99 545.61 3346.07 545.51C3346.1 545.47 3346.23 545.51 3346.32 545.52C3346.27 545.65 3346.22 545.78 3346.18 545.9Z" fill="white"/>
                    <path d="M3267.87 437.47C3267.87 437.38 3267.87 437.23 3267.89 437.23C3268.02 437.2 3268.18 437.16 3268.3 437.21C3268.54 437.31 3268.52 437.47 3268.27 437.54C3268.15 437.57 3268 437.5 3267.87 437.48V437.47Z" fill="white"/>
                    <path d="M3268.65 437.22C3268.79 437.25 3269.01 437.24 3269.04 437.31C3269.13 437.52 3269.02 437.65 3268.86 437.6C3268.72 437.56 3268.59 437.46 3268.46 437.38C3268.52 437.33 3268.58 437.27 3268.65 437.22Z" fill="white"/>
                    <path d="M3423.62 533.69C3423.75 533.66 3423.88 533.64 3424.02 533.61C3424.02 533.7 3424.04 533.84 3423.99 533.86C3423.87 533.92 3423.73 533.94 3423.6 533.97C3423.6 533.88 3423.61 533.8 3423.62 533.69Z" fill="white"/>
                    <path d="M3207.37 489.68C3207.5 489.65 3207.63 489.63 3207.77 489.6C3207.77 489.69 3207.79 489.83 3207.74 489.85C3207.62 489.91 3207.48 489.93 3207.35 489.96C3207.35 489.87 3207.36 489.79 3207.37 489.68Z" fill="white"/>
                    <path d="M3267.78 430.08C3267.88 430.08 3267.97 430.1 3268.06 430.1C3268.01 430.23 3267.97 430.37 3267.89 430.47C3267.86 430.51 3267.73 430.47 3267.64 430.46C3267.69 430.33 3267.74 430.2 3267.78 430.08Z" fill="white"/>
                    <path d="M3226.5 525.42C3226.37 525.45 3226.24 525.47 3226.1 525.5C3226.1 525.41 3226.08 525.27 3226.13 525.25C3226.25 525.19 3226.39 525.17 3226.52 525.14C3226.52 525.23 3226.51 525.31 3226.5 525.42Z" fill="white"/>
                    <path d="M3352.93 476.44C3353.06 476.41 3353.19 476.39 3353.33 476.36C3353.33 476.45 3353.35 476.59 3353.3 476.61C3353.18 476.67 3353.04 476.69 3352.91 476.72C3352.91 476.63 3352.92 476.55 3352.93 476.44Z" fill="white"/>
                    <path d="M3286.36 585.96C3286.36 586.06 3286.35 586.15 3286.34 586.24C3286.21 586.19 3286.07 586.15 3285.97 586.07C3285.93 586.04 3285.97 585.91 3285.98 585.82C3286.11 585.87 3286.24 585.92 3286.36 585.96Z" fill="white"/>
                    <path d="M3296.24 445.24C3296.29 445.11 3296.33 444.97 3296.41 444.87C3296.44 444.83 3296.57 444.87 3296.66 444.88C3296.61 445.01 3296.57 445.15 3296.49 445.25C3296.46 445.29 3296.33 445.25 3296.24 445.24Z" fill="white"/>
                    <path d="M3276.76 578.55C3276.89 578.52 3277.02 578.5 3277.16 578.47C3277.16 578.56 3277.18 578.7 3277.13 578.72C3277.01 578.78 3276.87 578.8 3276.74 578.83L3276.76 578.55Z" fill="white"/>
                    <path d="M3376.22 535.34C3376.19 535.21 3376.17 535.08 3376.14 534.94C3376.23 534.94 3376.37 534.92 3376.39 534.97C3376.45 535.09 3376.47 535.23 3376.5 535.36C3376.41 535.36 3376.33 535.35 3376.22 535.34Z" fill="white"/>
                    <path d="M3494.73 585.77C3494.78 585.64 3494.82 585.5 3494.9 585.4C3494.93 585.36 3495.06 585.4 3495.15 585.41C3495.1 585.54 3495.06 585.68 3494.98 585.78C3494.95 585.82 3494.82 585.78 3494.73 585.77Z" fill="white"/>
                    <path d="M3566.33 432.74C3568.24 433.51 3568.34 435.11 3568.4 436.63C3568.42 437.18 3568.33 438.05 3568.44 438.33C3569.18 440.13 3566.88 440.01 3566.81 441.2C3565.67 440.95 3564.22 441.04 3563.48 440.35C3562.77 439.7 3561.96 438.81 3561.57 437.81C3561.2 436.86 3560.25 435.7 3562.12 435.11C3562.4 435.02 3562.48 434.38 3562.69 434.02C3563.63 432.42 3564.89 432.02 3566.34 432.74H3566.33Z" fill="white"/>
                    <path d="M3413.63 499.53C3414.18 498.44 3415.44 498.1 3415.82 498.3C3417.29 499.07 3418.56 498.01 3419.94 498.17C3420.92 498.28 3421.49 498.5 3421.74 499.42C3422.15 500.94 3421.95 501.63 3420.93 502.15C3420.49 502.38 3420.09 502.64 3419.69 502.93C3417.94 504.17 3413.76 502.95 3413.57 500.49C3413.54 500.05 3413.5 499.69 3413.63 499.53Z" fill="white"/>
                    <path d="M3508.99 527.88C3510.02 527.57 3510.5 526.08 3511.88 526.64C3512.07 526.72 3512.26 526.9 3512.45 526.9C3513.24 526.9 3514.08 526.61 3514.67 527.42C3515.13 528.05 3514.89 528.79 3514.89 529.48C3514.89 530.84 3513.53 532.19 3512.04 532.36C3510.88 532.49 3510.06 532.05 3509.38 531.07C3508.67 530.06 3509.07 528.96 3508.99 527.89V527.88Z" fill="white"/>
                    <path d="M3512.12 675.84C3511.31 677.48 3510.34 677.93 3509.08 677.48C3508.39 677.24 3507.76 676.82 3507.09 676.53C3506.45 676.26 3506.39 675.9 3506.71 675.33C3507.07 674.69 3507.28 673.95 3507.71 673.36C3508.44 672.37 3509.54 672.02 3510.73 672.05C3510.98 672.05 3511.36 672.29 3511.43 672.5C3511.69 673.28 3512.18 673.91 3512.57 674.6C3512.76 674.94 3512.17 675.71 3512.12 675.83V675.84Z" fill="white"/>
                    <path d="M3380.56 633.55C3380.86 632.78 3381.27 631.67 3382.58 631.92C3383.2 632.04 3384.36 632.27 3384.24 633.05C3384.11 633.9 3384.65 634.96 3383.74 635.63C3383.11 636.09 3382.28 636.36 3381.79 636.93C3381.34 637.46 3381.09 637.49 3380.53 637.22C3379.73 636.84 3379.53 636.17 3379.67 635.45C3379.79 634.85 3380.19 634.31 3380.57 633.54L3380.56 633.55Z" fill="white"/>
                    <path d="M3531.77 687.54C3532.12 686.78 3533.02 686.43 3533.78 686.75C3534.03 686.86 3535.72 689.33 3535.71 689.57C3535.69 690.13 3534.03 691.14 3534.02 691.03C3533.85 689.71 3532.62 690.78 3532.2 690.16C3531.8 689.57 3531.54 688.02 3531.77 687.54Z" fill="white"/>
                    <path d="M3573.79 504.75C3573.4 504.55 3572.75 504.35 3572.93 503.98C3573.26 503.28 3573.32 502.36 3574.29 502.02C3574.83 501.83 3575.31 502.15 3575.75 502C3576.47 501.76 3576.87 501.85 3577.18 502.62C3577.51 503.45 3577.27 504.19 3576.66 504.5C3575.79 504.94 3574.79 505.57 3573.79 504.76V504.75Z" fill="white"/>
                    <path d="M3468.31 534.77C3467.98 535.35 3467.91 536.27 3466.8 535.67C3466.55 535.54 3466.04 535.92 3465.63 536.03C3465.17 536.16 3464.71 536.25 3464.24 536.35C3464.49 535.69 3464.55 534.81 3465.02 534.42C3465.63 533.92 3466.57 533.85 3467.34 533.54C3467.77 533.37 3468.19 533.3 3468.48 533.64C3468.8 534.02 3468.46 534.41 3468.29 534.77H3468.31Z" fill="white"/>
                    <path d="M3624.29 599.2C3624.2 599.38 3624.13 599.58 3624.01 599.75C3623.75 600.13 3623.77 600.87 3623.06 600.7C3622.33 600.53 3621.85 600.05 3621.68 599.25C3621.54 598.57 3621.9 598.39 3622.41 598.16C3622.74 598.01 3622.95 597.59 3623.28 597.44C3623.71 597.24 3624.22 596.9 3624.7 597.3C3625.28 597.79 3624.7 598.2 3624.55 598.64C3624.49 598.83 3624.37 599.01 3624.28 599.2H3624.29Z" fill="white"/>
                    <path d="M3521.53 692.74C3522.08 693.54 3522.89 694.2 3523.03 695.24C3523.06 695.48 3522.9 695.84 3522.52 695.92C3522.04 696.03 3521.5 696.49 3521.08 696.02C3520.57 695.44 3520.18 694.74 3519.82 694.05C3519.39 693.23 3520.44 693.56 3520.58 693.14C3520.73 692.7 3521.02 692.45 3521.53 692.74Z" fill="white"/>
                    <path d="M3488.03 487.22C3487.86 487.77 3487.54 488 3486.93 487.66C3485.98 487.13 3485.68 486.35 3486.07 485.46C3486.41 484.67 3487.1 484.48 3487.79 484.58C3488.58 484.7 3488.68 485.98 3488.04 487.22H3488.03Z" fill="white"/>
                    <path d="M3440.77 405.14C3440.63 405.42 3440.54 405.74 3440.35 405.98C3439.9 406.54 3439.04 406.37 3438.8 405.7C3438.66 405.3 3438.45 404.91 3438.41 404.51C3438.36 403.99 3438.61 403.41 3439.04 403.21C3439.54 402.98 3440.18 403.45 3440.75 403.1C3440.86 403.68 3441.41 404.22 3440.91 404.86C3440.85 404.94 3440.82 405.05 3440.77 405.14Z" fill="white"/>
                    <path d="M3539.53 637.06C3539.88 636.94 3540.18 636.85 3540.47 636.73C3541.19 636.42 3541.7 637.48 3542.48 637.01C3542.89 636.76 3543.32 636.97 3543.43 637.44C3543.53 637.88 3543.25 638.17 3542.8 638.31C3542.2 638.49 3541.63 638.75 3541.03 638.9C3540.75 638.97 3540.34 639.49 3540.12 638.78C3539.94 638.2 3539.73 637.64 3539.54 637.07L3539.53 637.06Z" fill="white"/>
                    <path d="M3424.28 698.06C3423.73 697.68 3423.63 696.47 3424.09 695.87C3424.28 695.63 3426.21 695.38 3426.48 695.63C3427.18 696.25 3426.45 696.75 3426.22 697.21C3425.79 698.07 3424.81 698.43 3424.28 698.06Z" fill="white"/>
                    <path d="M3414.51 610.85C3415.13 611.05 3415.42 611.34 3415.07 611.98C3414.65 612.76 3413.72 613.27 3413.15 612.99C3412.59 612.71 3412.4 611.64 3412.8 610.85C3413.39 609.68 3413.97 610.79 3414.51 610.85Z" fill="white"/>
                    <path d="M3410.33 604.38C3410.82 604.07 3411.34 603.7 3411.95 603.95C3412.62 604.22 3412.73 605.66 3412.19 606.34C3411.84 606.79 3411.49 606.57 3411.14 606.36C3410.35 605.87 3409.96 604.94 3410.33 604.38Z" fill="white"/>
                    <path d="M3501.33 637.34C3501.15 637.71 3501.04 637.89 3500.97 638.08C3500.72 638.81 3499.99 638.7 3499.47 638.97C3499.37 638.4 3498.83 637.88 3499.25 637.24C3499.36 637.07 3499.45 636.88 3499.52 636.69C3499.77 636.04 3500.05 635.68 3500.87 636.1C3501.66 636.5 3501.68 636.9 3501.32 637.34H3501.33Z" fill="white"/>
                    <path d="M3347.56 540.75C3348.24 541.03 3348.16 541.54 3347.86 541.79C3347.26 542.29 3346.56 542.69 3345.87 543.07C3345.23 543.43 3345.32 542.77 3345.17 542.48C3344.53 541.24 3346.01 541.36 3346.34 540.71C3346.72 539.97 3347.22 540.75 3347.56 540.75Z" fill="white"/>
                    <path d="M3463.55 632.46C3463.78 631.61 3464.49 631.41 3465.18 631.49C3465.7 631.55 3466.22 633.21 3465.87 633.46C3465.19 633.94 3464.47 633.93 3463.72 633.49C3463.2 633.18 3463.38 632.84 3463.55 632.46Z" fill="white"/>
                    <path d="M3624.81 527.57C3625.31 527.94 3625.41 528.53 3625.41 529.12C3625.41 529.47 3623.7 529.78 3623.1 529.55C3622.55 529.34 3622.68 528.95 3622.88 528.57C3623.28 527.8 3624.23 527.3 3624.82 527.57H3624.81Z" fill="white"/>
                    <path d="M3486.3 670.58C3486.07 670.36 3485.89 670.03 3486.19 669.81C3486.9 669.29 3487.49 668.58 3488.4 668.32C3488.78 668.21 3489.11 668.34 3489.24 668.54C3489.37 668.73 3489.34 669.24 3489.18 669.37C3488.56 669.85 3487.87 670.24 3487.19 670.65C3486.9 670.82 3486.6 670.79 3486.31 670.59L3486.3 670.58Z" fill="white"/>
                    <path d="M3485.24 704.86C3485.36 704.55 3485.5 704.15 3486.04 704.29C3486.51 704.41 3486.53 704.86 3486.72 705.2C3487.1 705.9 3486.63 706.41 3486.32 706.86C3486.13 707.13 3485.4 707.38 3485.25 707.25C3484.94 706.98 3484.72 706.45 3484.71 706.02C3484.7 705.66 3485.03 705.29 3485.24 704.85V704.86Z" fill="white"/>
                    <path d="M3349.75 597.5C3349.25 597.35 3349.03 597.02 3349.37 596.41C3349.8 595.65 3350.72 595.15 3351.28 595.42C3352.24 595.86 3351.01 596.73 3351.63 597.31C3351.73 597.41 3350.5 597.8 3349.75 597.5Z" fill="white"/>
                    <path d="M3545.34 596.35C3545.51 596.27 3545.7 596.08 3545.85 596.12C3546.66 596.3 3547.44 597.95 3547.04 598.62C3546.9 598.85 3546.63 599.16 3546.42 599.16C3546.2 599.16 3545.89 598.88 3545.77 598.64C3545.24 597.61 3545.12 596.83 3545.34 596.35Z" fill="white"/>
                    <path d="M3552.25 478.71C3551.97 478.57 3551.66 478.48 3551.42 478.29C3550.81 477.81 3551.4 477.39 3551.55 476.96C3551.74 476.4 3552.14 476.53 3552.53 476.73C3552.88 476.91 3553.27 477.02 3553.4 477.5C3553.53 477.99 3553.85 478.5 3553.37 478.91C3553 479.23 3552.61 478.87 3552.25 478.71Z" fill="white"/>
                    <path d="M3486.11 667.07C3485.71 667.59 3485.35 667.35 3485.01 667.14C3484.21 666.64 3483.83 665.71 3484.22 665.17C3484.54 664.71 3484.93 664.95 3485.27 665.17C3486.07 665.67 3486.43 666.58 3486.11 667.08V667.07Z" fill="white"/>
                    <path d="M3345.42 605.26C3345.21 604.56 3344.84 603.96 3345.32 603.21C3345.71 602.59 3346.04 602.73 3346.55 602.96C3347.1 603.21 3347.29 603.44 3347.06 604.1C3346.76 604.96 3346.02 604.95 3345.42 605.26Z" fill="white"/>
                    <path d="M3348.46 589.65C3348.63 589.55 3348.83 589.34 3348.95 589.38C3349.34 589.5 3349.85 589.63 3350.03 589.92C3350.32 590.4 3350.72 590.98 3350.32 591.6C3350.01 592.08 3349.62 591.84 3349.28 591.63C3348.47 591.12 3348.12 590.25 3348.46 589.66V589.65Z" fill="white"/>
                    <path d="M3594.58 485.51C3594.78 485.13 3595 484.72 3595.48 485.06C3596.1 485.5 3596.42 486.19 3595.99 486.85C3595.77 487.2 3595.13 487.27 3594.68 487.47C3594.49 486.82 3594.01 486.18 3594.58 485.5V485.51Z" fill="white"/>
                    <path d="M3430.29 456.43C3430.95 456.81 3431.27 457.93 3430.96 458.36C3430.64 458.81 3430.28 458.53 3429.92 458.38C3429.18 458.07 3428.9 457.45 3429.1 456.79C3429.16 456.59 3429.92 456.25 3430.29 456.43Z" fill="white"/>
                    <path d="M3440.65 631.71C3440.88 631.24 3441.15 630.75 3441.56 631.14C3442.07 631.61 3442.4 632.28 3442.82 632.86C3442.16 633.14 3441.54 633.35 3440.81 632.88C3440.12 632.44 3440.54 632.05 3440.65 631.72V631.71Z" fill="white"/>
                    <path d="M3360.73 495.38C3361.02 494.56 3361.42 494.7 3361.98 494.92C3362.85 495.26 3362.67 495.72 3362.37 496.35C3362.06 497 3361.71 496.91 3361.15 496.67C3360.33 496.32 3360.32 495.87 3360.73 495.37V495.38Z" fill="white"/>
                    <path d="M3447.65 643.23C3448.1 643.56 3449.09 643.58 3448.49 644.46C3448.16 644.94 3447.52 645.19 3446.9 644.97C3445.87 644.6 3446.66 643.68 3446.61 643.14C3446.55 642.46 3447.28 643.28 3447.66 643.23H3447.65Z" fill="white"/>
                    <path d="M3494.24 723.93C3494.38 723.65 3494.47 723.35 3494.65 723.11C3494.98 722.67 3495.36 722.92 3495.7 723.11C3495.95 723.25 3496.34 723.27 3496.35 723.66C3496.37 724.11 3496.15 724.4 3495.7 724.52C3495.5 724.57 3495.31 724.66 3495.12 724.72C3494.74 724.83 3494.19 725.15 3494.03 725.01C3493.67 724.7 3494.1 724.29 3494.23 723.93H3494.24Z" fill="white"/>
                    <path d="M3483.85 429.06C3483.3 428.54 3483.83 428.13 3484.01 427.72C3484.35 426.95 3484.84 427.47 3485.26 427.65C3485.65 427.81 3486.02 428.04 3485.7 428.55C3485.38 429.08 3484.3 429.36 3483.84 429.06H3483.85Z" fill="white"/>
                    <path d="M3404.93 575.83C3405.41 576.09 3405.8 576.29 3405.48 576.81C3405.15 577.34 3404.06 577.64 3403.63 577.32C3403.19 576.98 3403.51 576.63 3403.63 576.25C3403.93 575.33 3404.46 575.55 3404.93 575.83Z" fill="white"/>
                    <path d="M3464.97 682.05C3464.81 681.91 3464.42 681.71 3464.42 681.53C3464.42 681.02 3465 681.07 3465.29 681.05C3465.72 681.02 3466.32 680.03 3466.64 681.19C3466.75 681.57 3466.97 681.96 3466.65 682.32C3466.01 683.04 3465.6 682.09 3464.97 682.06V682.05Z" fill="white"/>
                    <path d="M3602.5 594.76C3602.93 594.52 3603.57 593.88 3603.86 594.45C3604.11 594.94 3604.99 595.04 3604.72 595.74C3604.56 596.16 3602.9 596.05 3602.5 595.6C3602.29 595.37 3602.29 595.06 3602.5 594.77V594.76Z" fill="white"/>
                    <path d="M3379.24 501.96C3379.24 501.91 3379.17 501.4 3379.19 500.89C3379.19 500.67 3380.63 500.46 3380.86 500.64C3381.47 501.13 3380.89 501.53 3380.75 501.95C3380.57 502.47 3380.2 502.43 3379.25 501.95L3379.24 501.96Z" fill="white"/>
                    <path d="M3400.08 471.08C3400.59 470.52 3400.98 471.08 3401.41 471.23C3401.95 471.42 3401.94 471.9 3401.63 472.17C3401.28 472.48 3400.76 472.64 3400.29 472.77C3400.12 472.82 3399.91 471.3 3400.08 471.08Z" fill="white"/>
                    <path d="M3589.26 435.53C3589.8 435.67 3589.88 436 3589.75 436.27C3589.39 436.99 3588.74 437.15 3587.99 436.99C3587.61 436.9 3587.53 436.57 3587.65 436.29C3588 435.53 3588.67 435.45 3589.25 435.53H3589.26Z" fill="white"/>
                    <path d="M3557.95 616.83C3557.4 616.45 3557.14 615.85 3557.37 615.37C3557.49 615.13 3558.19 614.8 3558.74 615.09C3559.48 615.47 3558.98 615.92 3558.81 616.35C3558.65 616.75 3558.43 617.07 3557.95 616.84V616.83Z" fill="white"/>
                    <path d="M3488.78 408.92C3489.06 409.01 3489.78 410.31 3489.61 410.38C3489.16 410.56 3488.65 410.74 3488.19 410.71C3487.76 410.68 3487.52 410.28 3487.82 409.82C3488.06 409.44 3488.03 408.75 3488.79 408.92H3488.78Z" fill="white"/>
                    <path d="M3556.92 623.19C3557.33 622.77 3557.91 622.42 3558.65 622.8C3558.92 622.94 3559.06 623.25 3558.81 623.56C3558.32 624.15 3557.71 624.42 3556.98 624.07C3556.71 623.94 3556.58 623.63 3556.91 623.19H3556.92Z" fill="white"/>
                    <path d="M3612.14 667.3C3612.36 666.76 3612.82 666.75 3613.09 667.06C3613.4 667.41 3613.56 667.93 3613.69 668.4C3613.74 668.57 3612.22 668.78 3612 668.61C3611.68 668.37 3611.74 667.86 3612.13 667.3H3612.14Z" fill="white"/>
                    <path d="M3448.12 638.06C3448.32 637.67 3448.52 637.28 3449.03 637.58C3449.59 637.91 3449.82 638.53 3449.59 639.01C3449.47 639.25 3448.77 639.58 3448.22 639.29C3447.48 638.91 3448.01 638.46 3448.13 638.06H3448.12Z" fill="white"/>
                    <path d="M3522.88 704.45C3523.36 704.1 3523.83 703.83 3524.44 704.04C3524.8 704.17 3524.91 704.49 3524.74 704.74C3524.46 705.16 3524.54 706.1 3523.59 705.73C3523.01 705.51 3523.17 704.86 3522.88 704.44V704.45Z" fill="white"/>
                    <path d="M3461.84 633.08C3462.01 632.99 3462.17 632.91 3462.34 632.82C3462.65 633.67 3462.83 634.6 3463.61 635.21C3463.64 635.24 3463.48 635.61 3463.33 635.74C3463.25 635.82 3462.89 635.77 3462.88 635.72C3462.69 634.9 3461.67 634.56 3461.68 633.65C3461.68 633.46 3461.78 633.27 3461.83 633.07L3461.84 633.08Z" fill="white"/>
                    <path d="M3466.63 634.21C3467.17 634.52 3466.51 635.09 3466.26 635.5C3465.76 636.32 3465.24 635.5 3464.75 635.35C3464.6 635.31 3464.24 634.75 3464.78 634.83C3465.58 634.95 3465.33 633.39 3466.63 634.21Z" fill="white"/>
                    <path d="M3542.73 453.62C3542 453.4 3542.12 452.66 3541.85 452.16C3542.22 452.02 3542.7 451.67 3542.93 451.79C3543.44 452.06 3543.58 452.64 3543.42 453.23C3543.32 453.59 3543.05 453.64 3542.74 453.62H3542.73Z" fill="white"/>
                    <path d="M3381.81 508.85C3382.18 509.1 3382.86 509.14 3382.51 509.91C3382.26 510.46 3381.88 510.32 3381.51 510.11C3381.11 509.88 3380.45 509.82 3380.79 509.06C3381.04 508.5 3381.41 508.68 3381.8 508.85H3381.81Z" fill="white"/>
                    <path d="M3617.6 491.97C3618.49 491.44 3619.16 491.55 3619.77 492C3619.91 492.1 3619.97 492.32 3620.07 492.49C3619.88 492.56 3619.66 492.59 3619.5 492.69C3618.86 493.1 3618.33 492.72 3617.82 492.42C3617.66 492.33 3617.63 492.03 3617.6 491.97Z" fill="white"/>
                    <path d="M3355.59 489.93C3355.65 489.79 3356.04 489.89 3356.41 490.11C3356.8 490.34 3357.49 490.38 3357.14 491.16C3356.9 491.7 3356.53 491.59 3356.15 491.38C3355.75 491.15 3355.08 491.08 3355.59 489.93Z" fill="white"/>
                    <path d="M3394.88 544.25C3394.96 544.37 3395.14 544.55 3395.11 544.64C3394.86 545.3 3394.6 545.94 3393.74 546.08C3393.39 546.13 3393.18 546.12 3393.19 545.76C3393.2 545.2 3394.39 544.17 3394.89 544.26L3394.88 544.25Z" fill="white"/>
                    <path d="M3492.38 469.71C3493.03 468.94 3493.46 469.85 3493.97 469.99C3494.2 470.05 3494.16 470.55 3493.94 470.79C3493.3 471.49 3492.84 470.71 3492.34 470.52C3492.11 470.43 3492.15 469.98 3492.37 469.7L3492.38 469.71Z" fill="white"/>
                    <path d="M3453.77 586.16C3454.42 585.39 3454.85 586.3 3455.36 586.44C3455.59 586.5 3455.55 587 3455.33 587.24C3454.69 587.94 3454.23 587.16 3453.73 586.97C3453.5 586.88 3453.54 586.43 3453.76 586.15L3453.77 586.16Z" fill="white"/>
                    <path d="M3594.16 568.5C3594.22 568.36 3594.61 568.46 3594.98 568.68C3595.37 568.91 3596.06 568.95 3595.71 569.73C3595.47 570.27 3595.1 570.16 3594.72 569.95C3594.32 569.72 3593.65 569.65 3594.16 568.5Z" fill="white"/>
                    <path d="M3406.39 654.69C3406.42 655.35 3406.45 656.03 3406.46 656.71C3406.46 656.74 3406.27 656.82 3406.19 656.8C3405.76 656.69 3405.54 656.41 3405.42 655.98C3405.28 655.49 3405.06 655.01 3405.61 654.66C3405.74 654.58 3405.98 654.66 3406.4 654.69H3406.39Z" fill="white"/>
                    <path d="M3464.28 660.52C3463.89 660.36 3463.46 660.22 3463.65 659.67C3463.89 658.97 3464.34 659.1 3464.85 659.39C3465.2 659.59 3465.66 659.7 3465.47 660.25C3465.23 660.95 3464.77 660.85 3464.28 660.52Z" fill="white"/>
                    <path d="M3527.8 652.23C3528.2 651.81 3528.45 651.63 3528.75 651.88C3529.34 652.37 3529.6 652.97 3529.26 653.71C3529.19 653.85 3528.94 653.91 3528.78 654.01C3528.44 653.39 3528.1 652.78 3527.8 652.23Z" fill="white"/>
                    <path d="M3420.44 586.66C3420.62 586.12 3420.91 585.79 3421.14 585.92C3421.6 586.19 3422.5 586.08 3422.33 587.01C3422.27 587.33 3421.96 587.65 3421.7 587.53C3421.21 587.3 3420.46 587.27 3420.43 586.66H3420.44Z" fill="white"/>
                    <path d="M3594.57 547.52C3594.78 547.14 3594.96 546.78 3595.5 547.06C3596.25 547.45 3595.76 547.91 3595.58 548.33C3595.41 548.72 3595.21 549.06 3594.67 548.78C3593.92 548.39 3594.44 547.94 3594.57 547.52Z" fill="white"/>
                    <path d="M3508.63 505.3C3507.86 504.65 3508.77 504.22 3508.91 503.71C3508.97 503.48 3509.47 503.52 3509.71 503.74C3510.41 504.38 3509.63 504.84 3509.44 505.34C3509.35 505.57 3508.9 505.53 3508.62 505.31L3508.63 505.3Z" fill="white"/>
                    <path d="M3524.9 663.38C3524.51 663.22 3524.08 663.08 3524.27 662.53C3524.51 661.83 3524.96 661.96 3525.47 662.25C3525.82 662.45 3526.28 662.56 3526.09 663.11C3525.85 663.81 3525.39 663.71 3524.9 663.38Z" fill="white"/>
                    <path d="M3360.66 599.97C3359.98 599.71 3359.92 599.44 3360.03 599.13C3360.29 598.43 3360.75 598.59 3361.23 598.88C3361.48 599.03 3362.03 599.23 3361.84 599.4C3361.47 599.75 3360.88 599.87 3360.66 599.97Z" fill="white"/>
                    <path d="M3597.09 507.74C3597.22 507.63 3597.44 507.46 3597.67 507.28C3597.83 507.83 3598.33 508.34 3597.92 508.95C3597.75 509.21 3597.5 509.31 3597.15 509.12C3596.42 508.73 3596.89 508.29 3597.09 507.73V507.74Z" fill="white"/>
                    <path d="M3528.39 726.38C3528.66 726.45 3528.75 726.75 3528.65 727.05C3528.52 727.44 3528.26 727.83 3527.77 727.5C3527.42 727.27 3526.61 727.31 3526.91 726.65C3527.02 726.41 3527.74 726.09 3528.39 726.39V726.38Z" fill="white"/>
                    <path d="M3350.52 645.96C3350.76 645.26 3351.49 645.37 3352 645.12C3351.98 645.4 3352.02 645.72 3351.91 645.96C3351.72 646.37 3351.65 647.02 3350.88 646.69C3350.53 646.54 3350.47 646.3 3350.51 645.96H3350.52Z" fill="white"/>
                    <path d="M3540.74 692.99C3541.16 693.09 3540.76 692.88 3540.82 693.28C3540.85 693.5 3540.65 693.91 3540.46 693.98C3539.93 694.18 3539.38 694.64 3538.79 694.12C3538.72 694.06 3538.66 693.89 3538.69 693.85C3539.24 693.24 3540.08 693.32 3540.74 692.99Z" fill="white"/>
                    <path d="M3527.76 635.42C3528.32 635.27 3528.9 634.79 3529.44 635.34C3529.57 635.47 3529.52 635.96 3529.37 636.15C3528.91 636.76 3528.49 636.16 3528.06 636.02C3527.77 635.93 3527.58 635.75 3527.76 635.41V635.42Z" fill="white"/>
                    <path d="M3478.68 546.73C3478.77 546.54 3478.83 546.32 3478.97 546.19C3479.06 546.1 3479.36 546.07 3479.43 546.14C3479.66 546.4 3478.83 548.15 3478.45 548.2C3478.36 548.2 3478.13 547.93 3478.16 547.86C3478.3 547.47 3478.5 547.11 3478.69 546.74L3478.68 546.73Z" fill="white"/>
                    <path d="M3630.72 626.4C3630.97 625.8 3631.36 625.88 3631.73 626.11C3632 626.28 3632.09 626.53 3631.91 626.88C3631.65 627.4 3631.29 627.33 3630.92 627.1C3630.66 626.93 3630.47 626.66 3630.72 626.4Z" fill="white"/>
                    <path d="M3350.75 625.32C3350.98 625.07 3351.19 624.96 3351.54 625.13C3352.06 625.39 3351.99 625.75 3351.76 626.12C3351.59 626.39 3351.34 626.47 3350.99 626.3C3350.46 626.04 3350.58 625.7 3350.75 625.32Z" fill="white"/>
                    <path d="M3481.84 433.57C3482.07 433.32 3482.28 433.21 3482.63 433.38C3483.15 433.64 3483.08 434 3482.85 434.37C3482.68 434.64 3482.43 434.72 3482.08 434.55C3481.55 434.29 3481.67 433.95 3481.84 433.57Z" fill="white"/>
                    <path d="M3594.76 474.82C3595.11 475 3595.41 475.2 3595.15 475.73C3594.98 476.08 3594.73 476.17 3594.43 476.06C3594.02 475.91 3593.74 475.67 3594 475.15C3594.17 474.8 3594.4 474.75 3594.76 474.82Z" fill="white"/>
                    <path d="M3356.62 513.36C3356.25 512.91 3356.01 512.43 3356.21 511.84C3356.26 511.69 3356.58 511.46 3356.59 511.47C3357.02 511.87 3357.25 512.36 3357.04 512.95C3356.98 513.11 3356.78 513.21 3356.62 513.35V513.36Z" fill="white"/>
                    <path d="M3440.48 437.43C3440.58 437.07 3440.88 437.01 3441.18 437.11C3441.59 437.26 3441.87 437.5 3441.61 438.02C3441.44 438.37 3441.19 438.46 3440.89 438.35C3440.48 438.2 3440.18 437.94 3440.48 437.42V437.43Z" fill="white"/>
                    <path d="M3411.04 559.65C3411.27 559.4 3411.48 559.29 3411.83 559.46C3412.35 559.72 3412.28 560.08 3412.05 560.45C3411.88 560.72 3411.63 560.8 3411.28 560.63C3410.75 560.37 3410.87 560.03 3411.04 559.65Z" fill="white"/>
                    <path d="M3589.09 682.69C3589.36 682.4 3589.57 682.29 3589.92 682.46C3590.44 682.72 3590.37 683.08 3590.14 683.45C3589.97 683.72 3589.72 683.8 3589.37 683.63C3588.83 683.37 3588.99 683.02 3589.09 682.69Z" fill="white"/>
                    <path d="M3564.07 676.18C3564.63 676.41 3564.55 676.8 3564.31 677.17C3564.14 677.44 3563.89 677.53 3563.54 677.35C3563.02 677.09 3563.09 676.73 3563.32 676.36C3563.49 676.09 3563.77 675.95 3564.07 676.18Z" fill="white"/>
                    <path d="M3345.98 470.74C3345.79 471.1 3345.59 471.48 3345.09 471.17C3344.94 471.08 3344.83 470.91 3344.7 470.77C3345.1 470.57 3345.51 470.37 3345.91 470.17C3345.94 470.36 3345.96 470.55 3345.99 470.74H3345.98Z" fill="white"/>
                    <path d="M3402.8 475.59C3402.99 475.23 3403.19 474.85 3403.69 475.16C3403.84 475.25 3403.95 475.42 3404.08 475.56C3403.68 475.76 3403.27 475.96 3402.87 476.16C3402.84 475.97 3402.82 475.78 3402.79 475.59H3402.8Z" fill="white"/>
                    <path d="M3373.83 618.72C3374.23 618.57 3374.61 618.43 3374.99 618.29C3374.97 618.57 3375.03 618.9 3374.91 619.12C3374.63 619.6 3374.22 619.37 3373.9 619.14C3373.81 619.08 3373.85 618.84 3373.84 618.72H3373.83Z" fill="white"/>
                    <path d="M3516.67 509.22C3516.84 509.4 3517.1 509.6 3517.07 509.66C3516.84 510.17 3516.27 510.03 3515.87 510.26C3515.85 510.07 3515.76 509.86 3515.81 509.7C3515.95 509.29 3516.21 509.01 3516.66 509.22H3516.67Z" fill="white"/>
                    <path d="M3583.92 435.43C3584.06 435.35 3584.23 435.18 3584.33 435.21C3584.71 435.33 3585.14 435.51 3584.93 436.02C3584.83 436.25 3584.53 436.4 3584.32 436.59C3584.19 436.21 3584.06 435.82 3583.92 435.42V435.43Z" fill="white"/>
                    <path d="M3555.8 449.44C3555.28 449.21 3555.4 448.82 3555.62 448.47C3555.71 448.33 3555.94 448.27 3556.1 448.17C3556.19 448.61 3556.28 449.06 3556.37 449.5C3556.18 449.48 3556 449.46 3555.8 449.44Z" fill="white"/>
                    <path d="M3393.77 602.48C3393.84 602.43 3394 602.25 3394.1 602.28C3394.48 602.39 3394.91 602.57 3394.72 603.09C3394.63 603.33 3394.33 603.48 3394.12 603.67C3394.01 603.29 3393.89 602.9 3393.77 602.48Z" fill="white"/>
                    <path d="M3413.32 566.52C3413.55 566 3413.94 566.12 3414.29 566.34C3414.43 566.43 3414.49 566.66 3414.59 566.82C3414.15 566.91 3413.7 567 3413.26 567.09C3413.28 566.9 3413.3 566.72 3413.32 566.52Z" fill="white"/>
                    <path d="M3515.38 416.86C3515.55 416.75 3515.7 416.65 3515.86 416.55C3515.97 416.99 3516.08 417.42 3516.19 417.86C3516 417.85 3515.8 417.88 3515.63 417.82C3515.1 417.63 3515.19 417.26 3515.37 416.87L3515.38 416.86Z" fill="white"/>
                    <path d="M3566.15 656.07C3565.63 655.84 3565.75 655.45 3565.97 655.1C3566.06 654.96 3566.29 654.9 3566.45 654.8C3566.54 655.24 3566.63 655.69 3566.72 656.13C3566.53 656.11 3566.35 656.09 3566.15 656.07Z" fill="white"/>
                    <path d="M3606.25 672.89C3606.23 672.63 3606.15 672.42 3606.21 672.25C3606.36 671.84 3606.63 671.56 3607.11 671.86C3607.26 671.96 3607.36 672.13 3607.49 672.27C3607.09 672.47 3606.69 672.67 3606.26 672.88L3606.25 672.89Z" fill="white"/>
                    <path d="M3372.43 683.55C3372.78 683.31 3373.03 683.58 3373.28 683.72C3373.36 683.76 3373.38 684.11 3373.31 684.17C3373 684.42 3372.74 684.14 3372.49 684C3372.41 683.95 3372.45 683.7 3372.43 683.55Z" fill="white"/>
                    <path d="M3369.85 656.05C3369.91 655.71 3369.91 655.41 3370.02 655.16C3370.08 655.02 3370.33 654.96 3370.5 654.86C3370.48 655.14 3370.51 655.44 3370.42 655.7C3370.37 655.84 3370.11 655.9 3369.85 656.05Z" fill="white"/>
                    <path d="M3632.72 563.42C3633.07 563.18 3633.32 563.45 3633.57 563.59C3633.65 563.63 3633.67 563.98 3633.6 564.04C3633.29 564.29 3633.03 564.01 3632.78 563.87C3632.7 563.82 3632.74 563.57 3632.72 563.42Z" fill="white"/>
                    <path d="M3585.14 455.61C3585.48 455.67 3585.78 455.67 3586.03 455.78C3586.17 455.84 3586.23 456.09 3586.33 456.26C3586.05 456.24 3585.75 456.27 3585.49 456.18C3585.35 456.13 3585.29 455.87 3585.14 455.61Z" fill="white"/>
                    <path d="M3581.92 454.5C3582.06 454.58 3582.33 454.69 3582.53 454.88C3582.76 455.1 3582.66 455.3 3582.35 455.24C3582.09 455.19 3581.81 455.05 3581.61 454.88C3581.37 454.67 3581.47 454.51 3581.92 454.5Z" fill="white"/>
                    <path d="M3647.63 551.42C3647.51 551.28 3647.25 551.1 3647.28 551C3647.39 550.64 3647.68 550.68 3647.92 550.86C3648.07 550.97 3648.3 551.17 3648.28 551.27C3648.17 551.63 3647.9 551.57 3647.63 551.41V551.42Z" fill="white"/>
                    <path d="M3357.18 622.26C3357.32 622.14 3357.5 621.88 3357.6 621.91C3357.96 622.02 3357.92 622.31 3357.74 622.55C3357.63 622.7 3357.43 622.93 3357.33 622.91C3356.97 622.8 3357.03 622.53 3357.19 622.26H3357.18Z" fill="white"/>
                    <path d="M3506.52 424.04C3506.58 423.7 3506.58 423.4 3506.69 423.15C3506.75 423.01 3507 422.95 3507.17 422.85C3507.15 423.13 3507.18 423.43 3507.09 423.69C3507.04 423.83 3506.78 423.89 3506.52 424.04Z" fill="white"/>
                    <path d="M3507.51 701.18C3507.63 701.11 3507.79 701.01 3507.96 700.92C3507.94 701.2 3507.97 701.5 3507.88 701.76C3507.83 701.9 3507.57 701.96 3507.41 702.06C3507.27 701.77 3507.27 701.48 3507.52 701.19L3507.51 701.18Z" fill="white"/>
                    <path d="M3461.98 404.98C3462.27 405.09 3462.49 405.27 3462.27 405.58C3462.21 405.66 3461.9 405.6 3461.73 405.55C3461.44 405.46 3461.22 405.26 3461.44 404.96C3461.5 404.88 3461.8 404.96 3461.98 404.98Z" fill="white"/>
                    <path d="M3527.44 663.21C3527.73 663.32 3527.95 663.5 3527.73 663.81C3527.67 663.89 3527.36 663.83 3527.19 663.78C3526.9 663.69 3526.68 663.49 3526.9 663.19C3526.96 663.11 3527.26 663.19 3527.44 663.21Z" fill="white"/>
                    <path d="M3523.08 650.51C3523.37 650.62 3523.59 650.8 3523.37 651.11C3523.31 651.19 3523 651.13 3522.83 651.08C3522.54 650.99 3522.32 650.79 3522.54 650.49C3522.6 650.41 3522.9 650.49 3523.08 650.51Z" fill="white"/>
                    <path d="M3500.51 606.45C3500.57 606.11 3500.57 605.81 3500.68 605.56C3500.74 605.42 3500.99 605.36 3501.16 605.26C3501.14 605.54 3501.17 605.84 3501.08 606.1C3501.03 606.24 3500.77 606.3 3500.51 606.45Z" fill="white"/>
                    <path d="M3385.3 609.32C3385.49 609.18 3385.72 609.01 3385.94 608.84C3385.96 609.03 3386.07 609.27 3386 609.4C3385.85 609.63 3385.6 609.79 3385.39 609.98C3385.37 609.79 3385.34 609.6 3385.3 609.32Z" fill="white"/>
                    <path d="M3451.93 533.76C3452.05 533.69 3452.21 533.59 3452.38 533.5C3452.36 533.78 3452.39 534.08 3452.3 534.34C3452.25 534.48 3451.99 534.54 3451.83 534.64C3451.69 534.35 3451.69 534.06 3451.94 533.77L3451.93 533.76Z" fill="white"/>
                    <path d="M3411.82 505.31C3411.88 504.97 3411.88 504.67 3411.99 504.42C3412.05 504.28 3412.3 504.22 3412.47 504.12C3412.45 504.4 3412.48 504.7 3412.39 504.96C3412.34 505.1 3412.08 505.16 3411.82 505.31Z" fill="white"/>
                    <path d="M3348.63 461.19C3348.98 460.95 3349.23 461.22 3349.48 461.36C3349.56 461.4 3349.58 461.75 3349.51 461.81C3349.2 462.06 3348.94 461.78 3348.69 461.64C3348.61 461.59 3348.65 461.34 3348.63 461.19Z" fill="white"/>
                    <path d="M3417.74 460.88C3418.03 460.99 3418.25 461.17 3418.03 461.48C3417.97 461.56 3417.66 461.5 3417.49 461.45C3417.2 461.36 3416.98 461.16 3417.2 460.86C3417.26 460.78 3417.56 460.86 3417.74 460.88Z" fill="white"/>
                    <path d="M3613.23 567.02C3613.11 566.88 3612.85 566.7 3612.88 566.6C3612.99 566.24 3613.28 566.28 3613.52 566.46C3613.67 566.57 3613.9 566.77 3613.88 566.87C3613.77 567.23 3613.5 567.17 3613.23 567.01V567.02Z" fill="white"/>
                    <path d="M3514.43 537.03C3514.57 536.91 3514.75 536.65 3514.85 536.68C3515.21 536.79 3515.17 537.08 3514.99 537.32C3514.88 537.47 3514.68 537.7 3514.58 537.68C3514.22 537.57 3514.28 537.3 3514.44 537.03H3514.43Z" fill="white"/>
                    <path d="M3520.8 521.83C3520.8 521.67 3520.77 521.51 3520.8 521.36C3520.81 521.31 3520.98 521.29 3521.07 521.26C3521.07 521.42 3521.1 521.58 3521.07 521.73C3521.06 521.78 3520.89 521.8 3520.8 521.83Z" fill="white"/>
                    <path d="M3516.93 405.77C3516.93 405.61 3516.9 405.45 3516.93 405.3C3516.94 405.25 3517.11 405.23 3517.2 405.2C3517.2 405.36 3517.23 405.52 3517.2 405.67C3517.19 405.72 3517.02 405.74 3516.93 405.77Z" fill="white"/>
                    <path d="M3513.28 641.95C3513.4 641.86 3513.53 641.77 3513.66 641.68C3513.69 641.77 3513.78 641.91 3513.75 641.95C3513.65 642.07 3513.51 642.15 3513.39 642.24C3513.36 642.15 3513.33 642.05 3513.29 641.94L3513.28 641.95Z" fill="white"/>
                    <path d="M3549.38 675.81C3549.27 675.85 3549.17 675.88 3549.08 675.91C3549.08 675.75 3549.05 675.59 3549.08 675.44C3549.09 675.39 3549.26 675.37 3549.35 675.34C3549.35 675.5 3549.37 675.65 3549.37 675.81H3549.38Z" fill="white"/>
                    <path d="M3465.92 636.77C3466.01 636.89 3466.1 637.02 3466.19 637.15C3466.1 637.18 3465.96 637.27 3465.92 637.24C3465.8 637.14 3465.72 637 3465.63 636.88C3465.72 636.85 3465.82 636.82 3465.93 636.78L3465.92 636.77Z" fill="white"/>
                    <path d="M3451.32 589.71C3451.48 589.71 3451.64 589.68 3451.79 589.71C3451.84 589.72 3451.86 589.89 3451.89 589.98C3451.73 589.98 3451.57 590.01 3451.42 589.98C3451.37 589.97 3451.35 589.8 3451.32 589.71Z" fill="white"/>
                    <path d="M3407.37 562.33C3407.41 562.44 3407.44 562.54 3407.47 562.63C3407.31 562.63 3407.15 562.66 3407 562.63C3406.95 562.62 3406.93 562.45 3406.9 562.36C3407.06 562.36 3407.21 562.34 3407.37 562.34V562.33Z" fill="white"/>
                    <path d="M3362.33 607.62C3362.45 607.53 3362.58 607.44 3362.71 607.35C3362.74 607.44 3362.83 607.58 3362.8 607.62C3362.7 607.74 3362.56 607.82 3362.44 607.91C3362.41 607.82 3362.38 607.72 3362.34 607.61L3362.33 607.62Z" fill="white"/>
                    <path d="M3483.68 647.71C3483.79 647.67 3483.89 647.64 3483.98 647.61C3483.98 647.77 3484.01 647.93 3483.98 648.08C3483.97 648.13 3483.8 648.15 3483.71 648.18C3483.71 648.02 3483.69 647.87 3483.69 647.71H3483.68Z" fill="white"/>
                    <path d="M3477.06 568.57C3476.95 568.61 3476.85 568.64 3476.76 568.67C3476.76 568.51 3476.73 568.35 3476.76 568.2C3476.77 568.15 3476.94 568.13 3477.03 568.1C3477.03 568.26 3477.05 568.41 3477.05 568.57H3477.06Z" fill="white"/>
                    <path d="M3345.21 490.21C3345.18 490.11 3345.1 489.96 3345.12 489.94C3345.25 489.85 3345.4 489.73 3345.54 489.73C3345.83 489.73 3345.89 489.9 3345.66 490.09C3345.55 490.18 3345.36 490.17 3345.2 490.21H3345.21Z" fill="white"/>
                    <path d="M3345.92 489.59C3346.08 489.56 3346.31 489.45 3346.37 489.51C3346.56 489.69 3346.51 489.88 3346.31 489.9C3346.14 489.92 3345.96 489.87 3345.79 489.85C3345.83 489.77 3345.87 489.68 3345.91 489.6L3345.92 489.59Z" fill="white"/>
                    <path d="M3552.97 520.39C3553.09 520.3 3553.22 520.21 3553.35 520.12C3553.38 520.21 3553.47 520.35 3553.44 520.39C3553.34 520.51 3553.2 520.59 3553.08 520.68C3553.05 520.59 3553.02 520.49 3552.98 520.38L3552.97 520.39Z" fill="white"/>
                    <path d="M3305.38 572.73C3305.5 572.64 3305.63 572.55 3305.76 572.46C3305.79 572.55 3305.88 572.69 3305.85 572.73C3305.75 572.85 3305.61 572.93 3305.49 573.02C3305.46 572.93 3305.43 572.83 3305.39 572.72L3305.38 572.73Z" fill="white"/>
                    <path d="M3341.75 482.48C3341.86 482.44 3341.96 482.41 3342.05 482.38C3342.05 482.54 3342.08 482.7 3342.05 482.85C3342.04 482.9 3341.87 482.92 3341.78 482.95C3341.78 482.79 3341.76 482.64 3341.76 482.48H3341.75Z" fill="white"/>
                    <path d="M3341.8 601.61C3341.68 601.7 3341.55 601.79 3341.42 601.88C3341.39 601.79 3341.3 601.65 3341.33 601.61C3341.43 601.49 3341.57 601.41 3341.69 601.32C3341.72 601.41 3341.75 601.51 3341.79 601.62L3341.8 601.61Z" fill="white"/>
                    <path d="M3452.47 492.41C3452.59 492.32 3452.72 492.23 3452.85 492.14C3452.88 492.23 3452.97 492.37 3452.94 492.41C3452.84 492.53 3452.7 492.61 3452.58 492.7C3452.55 492.61 3452.52 492.51 3452.48 492.4L3452.47 492.41Z" fill="white"/>
                    <path d="M3432.4 638C3432.44 638.11 3432.47 638.21 3432.5 638.3C3432.34 638.3 3432.18 638.33 3432.03 638.3C3431.98 638.29 3431.96 638.12 3431.93 638.03C3432.09 638.03 3432.24 638.01 3432.4 638.01V638Z" fill="white"/>
                    <path d="M3378.6 485.45C3378.6 485.29 3378.57 485.13 3378.6 484.98C3378.61 484.93 3378.78 484.91 3378.87 484.88C3378.87 485.04 3378.9 485.2 3378.87 485.35C3378.86 485.4 3378.69 485.42 3378.6 485.45Z" fill="white"/>
                    <path d="M3418.92 634.58C3419.04 634.49 3419.17 634.4 3419.3 634.31C3419.33 634.4 3419.42 634.54 3419.39 634.58C3419.29 634.7 3419.15 634.78 3419.03 634.87L3418.93 634.57L3418.92 634.58Z" fill="white"/>
                    <path d="M3503.85 543.75C3503.76 543.63 3503.67 543.5 3503.58 543.37C3503.67 543.34 3503.81 543.25 3503.85 543.28C3503.97 543.38 3504.05 543.52 3504.14 543.64C3504.05 543.67 3503.95 543.7 3503.84 543.74L3503.85 543.75Z" fill="white"/>
                    <path d="M3651.53 542.75C3651.53 542.59 3651.5 542.43 3651.53 542.28C3651.54 542.23 3651.71 542.21 3651.8 542.18C3651.8 542.34 3651.83 542.5 3651.8 542.65C3651.79 542.7 3651.62 542.72 3651.53 542.75Z" fill="white"/>
                    <path d="M3727.82 585.37C3727.99 587.3 3726.69 588.06 3725.45 588.75C3725 589 3724.23 589.29 3724.05 589.51C3722.86 590.89 3721.98 588.91 3720.96 589.36C3720.69 588.3 3720 587.12 3720.26 586.21C3720.5 585.34 3720.9 584.29 3721.57 583.54C3722.21 582.83 3722.78 581.55 3724.06 582.85C3724.25 583.04 3724.82 582.85 3725.21 582.87C3726.95 582.98 3727.81 583.86 3727.82 585.38V585.37Z" fill="white"/>
                    <path d="M3607.33 485.92C3608.47 485.92 3609.29 486.83 3609.28 487.23C3609.26 488.78 3610.68 489.4 3611.14 490.62C3611.46 491.48 3611.52 492.06 3610.86 492.66C3609.77 493.64 3609.1 493.77 3608.23 493.13C3607.85 492.85 3607.47 492.64 3607.05 492.42C3605.27 491.48 3604.52 487.47 3606.5 486.27C3606.85 486.06 3607.14 485.87 3607.33 485.92Z" fill="white"/>
                    <path d="M3623.97 577.68C3624.66 578.41 3626.11 578.18 3626.23 579.57C3626.25 579.77 3626.17 580 3626.25 580.16C3626.58 580.82 3627.18 581.4 3626.75 582.23C3626.41 582.88 3625.7 582.99 3625.13 583.29C3624 583.87 3622.29 583.29 3621.52 582.12C3620.92 581.21 3620.95 580.33 3621.47 579.35C3622.02 578.33 3623.1 578.2 3623.97 577.68Z" fill="white"/>
                    <path d="M3501.54 642.92C3499.83 642.93 3499.04 642.31 3498.88 641.08C3498.79 640.4 3498.88 639.7 3498.83 639.01C3498.78 638.36 3499.06 638.16 3499.68 638.19C3500.37 638.22 3501.08 638.08 3501.75 638.19C3502.89 638.38 3503.65 639.15 3504.13 640.16C3504.23 640.37 3504.19 640.79 3504.05 640.94C3503.5 641.49 3503.19 642.16 3502.77 642.78C3502.57 643.08 3501.68 642.91 3501.55 642.92H3501.54Z" fill="white"/>
                    <path d="M3481.23 514.98C3482 514.91 3483.11 514.78 3483.45 515.98C3483.61 516.55 3483.91 517.62 3483.21 517.84C3482.45 518.09 3481.79 518.99 3480.84 518.51C3480.18 518.18 3479.61 517.59 3478.92 517.43C3478.29 517.28 3478.16 517.08 3478.14 516.5C3478.12 515.67 3478.59 515.22 3479.25 515.03C3479.8 514.88 3480.42 514.98 3481.23 514.97V514.98Z" fill="white"/>
                    <path d="M3500.07 664.3C3500.85 664.27 3501.53 664.87 3501.58 665.64C3501.6 665.9 3500.25 668.36 3500.03 668.45C3499.55 668.67 3498.01 667.71 3498.09 667.66C3499.12 666.96 3497.71 666.38 3498.05 665.77C3498.38 665.19 3499.56 664.31 3500.06 664.3H3500.07Z" fill="white"/>
                    <path d="M3670.75 622.09C3670.75 621.68 3670.64 621.05 3671.03 621.04C3671.75 621.02 3672.55 620.68 3673.25 621.35C3673.64 621.72 3673.57 622.26 3673.88 622.57C3674.39 623.07 3674.48 623.44 3673.97 624.03C3673.42 624.66 3672.7 624.77 3672.18 624.39C3671.45 623.85 3670.5 623.28 3670.75 622.1V622.09Z" fill="white"/>
                    <path d="M3600.99 546.57C3600.37 546.54 3599.57 546.87 3599.6 545.69C3599.6 545.42 3599.07 545.16 3598.8 544.87C3598.5 544.54 3598.23 544.19 3597.94 543.85C3598.6 543.78 3599.36 543.46 3599.89 543.69C3600.56 543.99 3601.02 544.74 3601.61 545.26C3601.93 545.54 3602.17 545.87 3602.01 546.26C3601.83 546.69 3601.36 546.57 3600.98 546.58L3600.99 546.57Z" fill="white"/>
                    <path d="M3613.12 704.3C3612.93 704.3 3612.73 704.33 3612.54 704.3C3612.11 704.25 3611.51 704.57 3611.34 703.9C3611.17 703.21 3611.37 702.61 3611.97 702.14C3612.48 701.74 3612.79 701.96 3613.19 702.29C3613.45 702.51 3613.89 702.5 3614.16 702.71C3614.51 702.99 3615.01 703.26 3614.88 703.84C3614.72 704.53 3614.12 704.21 3613.7 704.28C3613.51 704.31 3613.31 704.28 3613.12 704.29V704.3Z" fill="white"/>
                    <path d="M3491.39 657.94C3490.96 658.73 3490.75 659.7 3489.94 660.25C3489.76 660.38 3489.38 660.39 3489.15 660.11C3488.86 659.75 3488.24 659.5 3488.46 658.95C3488.73 658.28 3489.15 657.66 3489.58 657.06C3490.08 656.35 3490.25 657.38 3490.66 657.32C3491.09 657.26 3491.42 657.39 3491.39 657.94Z" fill="white"/>
                    <path d="M3649.11 542.94C3648.58 543.04 3648.25 542.86 3648.27 542.2C3648.31 541.18 3648.84 540.6 3649.75 540.55C3650.56 540.5 3651.01 541 3651.21 541.62C3651.44 542.33 3650.42 542.96 3649.11 542.95V542.94Z" fill="white"/>
                    <path d="M3697.77 468.67C3697.48 468.67 3697.17 468.73 3696.89 468.67C3696.23 468.53 3696.01 467.74 3696.47 467.26C3696.74 466.98 3696.98 466.63 3697.3 466.43C3697.72 466.17 3698.31 466.13 3698.66 466.4C3699.06 466.72 3698.95 467.46 3699.47 467.78C3699.03 468.12 3698.81 468.81 3698.07 468.66C3697.98 468.64 3697.87 468.66 3697.78 468.66L3697.77 468.67Z" fill="white"/>
                    <path d="M3545.58 649.43C3545.83 649.67 3546.03 649.89 3546.25 650.08C3546.81 650.56 3546.14 651.43 3546.87 651.88C3547.25 652.12 3547.26 652.57 3546.91 652.85C3546.58 653.12 3546.22 653.01 3545.92 652.69C3545.52 652.27 3545.06 651.89 3544.68 651.45C3544.5 651.24 3543.89 651.12 3544.4 650.63C3544.81 650.24 3545.19 649.82 3545.59 649.42L3545.58 649.43Z" fill="white"/>
                    <path d="M3445.78 578.85C3445.86 578.23 3446.83 577.63 3447.53 577.76C3447.81 577.81 3448.83 579.32 3448.74 579.66C3448.52 580.51 3447.79 580.11 3447.31 580.11C3446.41 580.11 3445.69 579.44 3445.78 578.85Z" fill="white"/>
                    <path d="M3514.59 533.77C3514.69 534.37 3514.56 534.74 3513.89 534.72C3513.06 534.69 3512.24 534.13 3512.23 533.54C3512.23 532.95 3513.05 532.34 3513.87 532.34C3515.1 532.34 3514.41 533.3 3514.59 533.77Z" fill="white"/>
                    <path d="M3518.23 527.53C3518.7 527.81 3519.22 528.08 3519.27 528.7C3519.32 529.37 3518.17 530.07 3517.38 529.91C3516.86 529.8 3516.89 529.42 3516.92 529.04C3516.99 528.17 3517.61 527.45 3518.23 527.52V527.53Z" fill="white"/>
                    <path d="M3529.18 617.6C3528.79 617.6 3528.6 617.59 3528.41 617.61C3527.7 617.71 3527.47 617.05 3527.03 616.73C3527.47 616.4 3527.67 615.74 3528.39 615.82C3528.58 615.84 3528.78 615.83 3528.97 615.82C3529.62 615.76 3530.04 615.84 3530.03 616.7C3530.03 617.53 3529.7 617.72 3529.18 617.6Z" fill="white"/>
                    <path d="M3544.89 448.1C3544.94 448.79 3544.49 448.94 3544.15 448.79C3543.48 448.5 3542.85 448.08 3542.24 447.67C3541.67 447.28 3542.26 447.08 3542.44 446.84C3543.21 445.78 3543.73 447.06 3544.41 447.07C3545.19 447.07 3544.75 447.82 3544.89 448.11V448.1Z" fill="white"/>
                    <path d="M3517.27 583.93C3518.08 583.77 3518.55 584.27 3518.77 584.88C3518.94 585.34 3517.77 586.48 3517.41 586.29C3516.72 585.92 3516.42 585.32 3516.48 584.5C3516.52 583.93 3516.88 583.94 3517.27 583.93Z" fill="white"/>
                    <path d="M3673.25 674.42C3673.16 675 3672.7 675.33 3672.21 675.58C3671.92 675.73 3670.93 674.43 3670.87 673.83C3670.81 673.28 3671.19 673.22 3671.59 673.23C3672.4 673.24 3673.23 673.82 3673.25 674.43V674.42Z" fill="white"/>
                    <path d="M3495.01 619.1C3495.1 618.81 3495.3 618.52 3495.61 618.68C3496.35 619.06 3497.18 619.25 3497.79 619.9C3498.04 620.17 3498.07 620.5 3497.96 620.69C3497.86 620.88 3497.41 621.07 3497.24 620.99C3496.57 620.68 3495.95 620.26 3495.33 619.87C3495.06 619.7 3494.97 619.43 3495.01 619.1Z" fill="white"/>
                    <path d="M3465.89 632.72C3466.2 632.69 3466.59 632.64 3466.7 633.15C3466.8 633.59 3466.43 633.8 3466.23 634.11C3465.81 634.73 3465.18 634.54 3464.67 634.48C3464.36 634.44 3463.85 633.93 3463.89 633.75C3463.98 633.37 3464.33 632.97 3464.69 632.78C3464.99 632.62 3465.44 632.74 3465.89 632.73V632.72Z" fill="white"/>
                    <path d="M3498.35 473.95C3498.26 473.47 3498.45 473.14 3499.1 473.17C3499.92 473.21 3500.72 473.76 3500.74 474.35C3500.78 475.34 3499.53 474.68 3499.31 475.44C3499.27 475.57 3498.42 474.7 3498.35 473.95Z" fill="white"/>
                    <path d="M3582.09 637.06C3582.23 637.17 3582.47 637.25 3582.5 637.39C3582.69 638.14 3581.65 639.49 3580.91 639.44C3580.66 639.42 3580.29 639.32 3580.2 639.15C3580.11 638.97 3580.21 638.59 3580.36 638.39C3581 637.51 3581.6 637.08 3582.09 637.06Z" fill="white"/>
                    <path d="M3683.41 593.06C3683.41 592.77 3683.35 592.47 3683.41 592.19C3683.55 591.47 3684.15 591.79 3684.58 591.73C3685.13 591.66 3685.19 592.04 3685.19 592.45C3685.19 592.82 3685.27 593.19 3684.92 593.5C3684.57 593.82 3684.27 594.3 3683.73 594.08C3683.3 593.9 3683.44 593.43 3683.42 593.06H3683.41Z" fill="white"/>
                    <path d="M3497.87 617.45C3497.27 617.34 3497.32 616.93 3497.35 616.56C3497.43 615.68 3498.04 614.97 3498.67 615.06C3499.19 615.14 3499.15 615.56 3499.12 615.94C3499.04 616.82 3498.43 617.51 3497.88 617.45H3497.87Z" fill="white"/>
                    <path d="M3490.02 473.61C3490.52 473.14 3490.86 472.58 3491.69 472.66C3492.37 472.73 3492.4 473.06 3492.42 473.58C3492.44 474.15 3492.33 474.4 3491.68 474.49C3490.83 474.61 3490.53 473.98 3490.02 473.61Z" fill="white"/>
                    <path d="M3504.37 469.54C3504.52 469.64 3504.79 469.72 3504.81 469.83C3504.87 470.21 3504.99 470.69 3504.81 470.96C3504.53 471.4 3504.22 471.99 3503.53 471.92C3503 471.87 3503.03 471.44 3503.07 471.06C3503.15 470.16 3503.73 469.51 3504.37 469.54Z" fill="white"/>
                    <path d="M3695.64 631.34C3696.04 631.34 3696.48 631.36 3696.39 631.91C3696.28 632.62 3695.84 633.17 3695.11 633.09C3694.73 633.05 3694.39 632.55 3694.03 632.26C3694.5 631.82 3694.83 631.15 3695.63 631.34H3695.64Z" fill="white"/>
                    <path d="M3650.43 481.61C3650.39 482.32 3649.59 483.06 3649.1 482.99C3648.59 482.91 3648.67 482.5 3648.64 482.12C3648.59 481.37 3648.98 480.88 3649.62 480.77C3649.81 480.74 3650.42 481.23 3650.43 481.61Z" fill="white"/>
                    <path d="M3508.2 564.47C3508.69 564.47 3509.21 564.48 3509.07 564.99C3508.89 565.62 3508.47 566.18 3508.16 566.77C3507.64 566.33 3507.21 565.9 3507.3 565.1C3507.38 564.34 3507.88 564.52 3508.21 564.47H3508.2Z" fill="white"/>
                    <path d="M3588.42 439.91C3589.23 439.81 3589.28 440.2 3589.34 440.76C3589.42 441.63 3588.96 441.68 3588.3 441.69C3587.62 441.7 3587.55 441.37 3587.51 440.81C3587.45 439.97 3587.82 439.77 3588.42 439.91Z" fill="white"/>
                    <path d="M3501.53 575.19C3501.44 575.71 3501.85 576.54 3500.85 576.41C3500.31 576.34 3499.83 575.91 3499.75 575.29C3499.63 574.27 3500.72 574.54 3501.16 574.27C3501.71 573.93 3501.32 574.89 3501.53 575.19Z" fill="white"/>
                    <path d="M3453.75 648.32C3454.04 648.32 3454.33 648.27 3454.61 648.32C3455.12 648.41 3455.07 648.83 3455.05 649.2C3455.03 649.47 3455.19 649.8 3454.86 649.98C3454.49 650.19 3454.15 650.13 3453.87 649.8C3453.74 649.66 3453.59 649.53 3453.46 649.4C3453.21 649.13 3452.71 648.8 3452.76 648.61C3452.86 648.18 3453.39 648.36 3453.75 648.32Z" fill="white"/>
                    <path d="M3695.99 514.83C3696.19 514.15 3696.76 514.42 3697.18 514.4C3697.97 514.36 3697.74 514.99 3697.77 515.42C3697.8 515.81 3697.77 516.22 3697.2 516.17C3696.62 516.12 3695.92 515.34 3695.99 514.83Z" fill="white"/>
                    <path d="M3539.83 510.93C3539.82 511.44 3539.81 511.85 3539.25 511.8C3538.67 511.75 3537.95 510.97 3538.04 510.47C3538.13 509.96 3538.57 510.08 3538.93 510.02C3539.82 509.88 3539.87 510.41 3539.83 510.93Z" fill="white"/>
                    <path d="M3476.39 606.11C3476.44 605.92 3476.44 605.51 3476.6 605.43C3477.03 605.22 3477.23 605.72 3477.37 605.95C3477.58 606.3 3478.66 606.38 3477.83 607.14C3477.55 607.39 3477.32 607.74 3476.89 607.63C3476.01 607.4 3476.64 606.66 3476.4 606.11H3476.39Z" fill="white"/>
                    <path d="M3607.62 684.2C3608 684.46 3608.81 684.72 3608.46 685.2C3608.16 685.61 3608.44 686.39 3607.75 686.46C3607.33 686.5 3606.73 685.07 3606.93 684.54C3607.04 684.26 3607.29 684.14 3607.63 684.19L3607.62 684.2Z" fill="white"/>
                    <path d="M3590.74 458.18C3590.74 458.18 3591.18 457.89 3591.61 457.69C3591.8 457.6 3592.58 458.71 3592.52 458.98C3592.37 459.7 3591.79 459.38 3591.37 459.44C3590.86 459.51 3590.73 459.19 3590.73 458.18H3590.74Z" fill="white"/>
                    <path d="M3625.39 462.54C3626.07 462.73 3625.78 463.29 3625.83 463.71C3625.9 464.24 3625.49 464.44 3625.14 464.29C3624.73 464.13 3624.38 463.77 3624.07 463.43C3623.96 463.31 3625.13 462.49 3625.39 462.54Z" fill="white"/>
                    <path d="M3735.19 605.73C3735.3 606.24 3735.06 606.45 3734.78 606.45C3734.02 606.45 3733.61 605.98 3733.44 605.29C3733.35 604.93 3733.59 604.73 3733.88 604.71C3734.67 604.68 3735.02 605.21 3735.19 605.73Z" fill="white"/>
                    <path d="M3570.3 656.28C3570.38 655.66 3570.78 655.18 3571.28 655.18C3571.53 655.18 3572.1 655.62 3572.09 656.21C3572.09 656.99 3571.49 656.76 3571.07 656.8C3570.67 656.84 3570.31 656.78 3570.29 656.28H3570.3Z" fill="white"/>
                    <path d="M3714.92 510.43C3714.96 510.7 3714.18 511.85 3714.05 511.74C3713.71 511.44 3713.34 511.09 3713.17 510.69C3713.01 510.31 3713.25 509.95 3713.76 510C3714.18 510.04 3714.75 509.72 3714.92 510.43Z" fill="white"/>
                    <path d="M3564.54 658.11C3565.07 658.27 3565.6 658.61 3565.59 659.39C3565.59 659.68 3565.39 659.93 3565.03 659.85C3564.33 659.69 3563.85 659.3 3563.83 658.54C3563.83 658.26 3564.03 658.02 3564.54 658.11Z" fill="white"/>
                    <path d="M3551.02 722.96C3551.56 722.92 3551.76 723.3 3551.62 723.66C3551.46 724.07 3551.1 724.42 3550.76 724.73C3550.64 724.84 3549.82 723.67 3549.87 723.41C3549.94 723.04 3550.39 722.87 3551.02 722.96Z" fill="white"/>
                    <path d="M3506.06 573.4C3506.47 573.4 3506.88 573.4 3506.85 573.96C3506.81 574.56 3506.39 575.03 3505.89 575.03C3505.64 575.03 3505.07 574.58 3505.08 574C3505.08 573.22 3505.68 573.47 3506.07 573.4H3506.06Z" fill="white"/>
                    <path d="M3482.17 664.02C3482.66 664.27 3483.09 664.55 3483.17 665.15C3483.22 665.5 3483 665.73 3482.71 665.7C3482.24 665.64 3481.49 666.11 3481.39 665.15C3481.33 664.57 3481.94 664.43 3482.16 664.01L3482.17 664.02Z" fill="white"/>
                    <path d="M3516.02 582.76C3516.16 582.86 3516.31 582.97 3516.45 583.07C3515.87 583.69 3515.17 584.23 3514.99 585.14C3514.99 585.18 3514.6 585.2 3514.43 585.14C3514.33 585.1 3514.22 584.78 3514.26 584.76C3514.86 584.26 3514.72 583.26 3515.48 582.88C3515.64 582.8 3515.84 582.8 3516.03 582.76H3516.02Z" fill="white"/>
                    <path d="M3517.11 587.25C3517.08 587.83 3516.32 587.52 3515.87 587.49C3514.97 587.42 3515.43 586.64 3515.35 586.16C3515.33 586.02 3515.64 585.48 3515.8 585.97C3516.04 586.69 3517.24 585.82 3517.11 587.26V587.25Z" fill="white"/>
                    <path d="M3700.37 574.47C3700.24 573.76 3700.92 573.56 3701.22 573.12C3701.49 573.37 3701.99 573.62 3701.99 573.86C3701.99 574.4 3701.55 574.76 3700.99 574.88C3700.65 574.95 3700.49 574.74 3700.38 574.47H3700.37Z" fill="white"/>
                    <path d="M3586.06 463.25C3586 463.66 3586.26 464.25 3585.47 464.28C3584.9 464.3 3584.86 463.93 3584.88 463.53C3584.9 463.1 3584.67 462.52 3585.46 462.49C3586.03 462.47 3586.04 462.84 3586.06 463.25Z" fill="white"/>
                    <path d="M3699.98 653.33C3700.8 653.85 3700.99 654.45 3700.88 655.16C3700.85 655.32 3700.7 655.46 3700.6 655.62C3700.46 655.49 3700.35 655.32 3700.19 655.23C3699.58 654.86 3699.67 654.27 3699.71 653.71C3699.72 653.54 3699.96 653.38 3699.99 653.34L3699.98 653.33Z" fill="white"/>
                    <path d="M3590.79 433.31C3590.94 433.3 3591.01 433.67 3590.99 434.07C3590.96 434.5 3591.22 435.09 3590.42 435.12C3589.86 435.15 3589.8 434.79 3589.82 434.38C3589.84 433.95 3589.62 433.36 3590.79 433.3V433.31Z" fill="white"/>
                    <path d="M3561.99 489.16C3561.92 489.27 3561.85 489.51 3561.76 489.52C3561.1 489.59 3560.45 489.64 3559.98 488.98C3559.78 488.71 3559.71 488.53 3560.01 488.38C3560.49 488.16 3561.85 488.71 3561.99 489.16Z" fill="white"/>
                    <path d="M3665.6 539.16C3666.52 539.38 3665.94 540.12 3666.04 540.61C3666.09 540.83 3665.65 541 3665.36 540.92C3664.5 540.68 3664.96 539.97 3664.91 539.47C3664.89 539.24 3665.28 539.09 3665.61 539.15L3665.6 539.16Z" fill="white"/>
                    <path d="M3551.86 556.16C3552.78 556.38 3552.2 557.12 3552.3 557.61C3552.35 557.83 3551.91 558 3551.62 557.92C3550.76 557.68 3551.22 556.97 3551.17 556.47C3551.15 556.24 3551.54 556.09 3551.87 556.15L3551.86 556.16Z" fill="white"/>
                    <path d="M3626.05 666.1C3626.2 666.09 3626.27 666.46 3626.25 666.86C3626.22 667.29 3626.48 667.88 3625.68 667.91C3625.12 667.94 3625.06 667.58 3625.08 667.17C3625.1 666.74 3624.88 666.15 3626.05 666.09V666.1Z" fill="white"/>
                    <path d="M3474.49 545.53C3473.95 545.83 3473.4 546.15 3472.83 546.45C3472.8 546.46 3472.65 546.34 3472.64 546.26C3472.55 545.86 3472.69 545.55 3473 545.26C3473.35 544.94 3473.66 544.55 3474.18 544.86C3474.3 544.93 3474.33 545.17 3474.48 545.53H3474.49Z" fill="white"/>
                    <path d="M3494.11 596.41C3494.07 596.02 3494.02 595.6 3494.55 595.52C3495.24 595.42 3495.32 595.86 3495.3 596.41C3495.28 596.79 3495.38 597.22 3494.84 597.29C3494.16 597.38 3494.04 596.96 3494.11 596.41Z" fill="white"/>
                    <path d="M3527.93 646.04C3528.45 646.2 3528.7 646.33 3528.62 646.69C3528.46 647.39 3528.07 647.86 3527.31 647.89C3527.16 647.89 3527.01 647.71 3526.86 647.61C3527.23 647.07 3527.6 646.52 3527.93 646.04Z" fill="white"/>
                    <path d="M3537.34 528.48C3537.86 528.4 3538.27 528.5 3538.26 528.75C3538.23 529.25 3538.7 529.95 3537.85 530.21C3537.56 530.3 3537.16 530.17 3537.15 529.91C3537.13 529.41 3536.84 528.76 3537.34 528.48Z" fill="white"/>
                    <path d="M3643.76 657.57C3644.17 657.58 3644.54 657.57 3644.54 658.15C3644.53 658.94 3643.94 658.73 3643.51 658.76C3643.11 658.79 3642.74 658.76 3642.75 658.19C3642.75 657.4 3643.35 657.64 3643.77 657.57H3643.76Z" fill="white"/>
                    <path d="M3642.71 567.82C3642.93 566.9 3643.67 567.48 3644.16 567.38C3644.38 567.33 3644.55 567.77 3644.47 568.06C3644.23 568.92 3643.52 568.46 3643.02 568.51C3642.79 568.53 3642.64 568.14 3642.7 567.81L3642.71 567.82Z" fill="white"/>
                    <path d="M3517.38 648.33C3517.34 647.94 3517.29 647.52 3517.82 647.44C3518.51 647.34 3518.59 647.78 3518.57 648.33C3518.55 648.71 3518.65 649.14 3518.11 649.21C3517.43 649.3 3517.31 648.88 3517.38 648.33Z" fill="white"/>
                    <path d="M3500.9 484.12C3500.83 483.44 3501.03 483.27 3501.34 483.24C3502.03 483.16 3502.1 483.61 3502.05 484.13C3502.03 484.4 3502.1 484.94 3501.88 484.86C3501.43 484.7 3501.08 484.26 3500.9 484.11V484.12Z" fill="white"/>
                    <path d="M3678.11 642.84C3678.26 642.91 3678.5 643.02 3678.74 643.13C3678.35 643.5 3678.13 644.13 3677.45 644.05C3677.16 644.02 3676.97 643.85 3676.98 643.48C3676.99 642.7 3677.56 642.92 3678.12 642.84H3678.11Z" fill="white"/>
                    <path d="M3466.15 677.91C3466.21 678.17 3465.99 678.37 3465.7 678.41C3465.31 678.46 3464.88 678.41 3464.95 677.86C3465 677.47 3464.62 676.81 3465.3 676.78C3465.55 676.78 3466.12 677.24 3466.15 677.9V677.91Z" fill="white"/>
                    <path d="M3458.14 495.1C3458.83 495.01 3459.04 495.66 3459.47 495.99C3459.22 496.09 3458.98 496.26 3458.73 496.27C3458.31 496.28 3457.73 496.5 3457.69 495.72C3457.67 495.37 3457.84 495.21 3458.14 495.1Z" fill="white"/>
                    <path d="M3499.31 674.11C3499.41 674.51 3499.41 674.08 3499.1 674.3C3498.93 674.42 3498.5 674.42 3498.36 674.3C3497.97 673.95 3497.36 673.67 3497.54 672.96C3497.56 672.88 3497.68 672.75 3497.72 672.76C3498.46 672.96 3498.75 673.7 3499.31 674.11Z" fill="white"/>
                    <path d="M3541.97 638.89C3542.33 639.3 3542.98 639.58 3542.75 640.27C3542.7 640.43 3542.26 640.6 3542.04 640.56C3541.34 640.44 3541.66 639.83 3541.59 639.41C3541.54 639.12 3541.61 638.89 3541.97 638.9V638.89Z" fill="white"/>
                    <path d="M3595.38 560.3C3595.57 560.3 3595.78 560.25 3595.96 560.3C3596.07 560.34 3596.23 560.58 3596.19 560.66C3596.07 560.96 3594.25 561.01 3594.06 560.71C3594.01 560.64 3594.15 560.33 3594.22 560.32C3594.6 560.27 3594.99 560.29 3595.38 560.29V560.3Z" fill="white"/>
                    <path d="M3593.09 721.2C3593.7 721.15 3593.8 721.51 3593.76 721.92C3593.73 722.22 3593.56 722.4 3593.19 722.4C3592.64 722.4 3592.55 722.07 3592.59 721.67C3592.62 721.39 3592.77 721.11 3593.09 721.21V721.2Z" fill="white"/>
                    <path d="M3475.5 486.56C3475.81 486.65 3475.99 486.78 3475.99 487.14C3475.99 487.69 3475.66 487.78 3475.26 487.74C3474.96 487.71 3474.79 487.54 3474.78 487.17C3474.78 486.62 3475.11 486.57 3475.5 486.56Z" fill="white"/>
                    <path d="M3691.36 515.05C3691.67 515.14 3691.85 515.27 3691.85 515.63C3691.85 516.18 3691.52 516.27 3691.12 516.23C3690.82 516.2 3690.65 516.03 3690.64 515.66C3690.64 515.11 3690.97 515.06 3691.36 515.05Z" fill="white"/>
                    <path d="M3704.65 626.96C3704.65 627.33 3704.61 627.66 3704.06 627.67C3703.69 627.67 3703.51 627.51 3703.48 627.21C3703.43 626.81 3703.51 626.47 3704.06 626.46C3704.42 626.46 3704.57 626.62 3704.66 626.96H3704.65Z" fill="white"/>
                    <path d="M3571.63 444.08C3571.85 443.58 3572.15 443.17 3572.72 443.09C3572.87 443.07 3573.19 443.24 3573.19 443.26C3573.04 443.79 3572.72 444.19 3572.14 444.26C3571.99 444.28 3571.81 444.15 3571.63 444.08Z" fill="white"/>
                    <path d="M3670.64 482.1C3670.99 482.03 3671.16 482.26 3671.2 482.55C3671.25 482.95 3671.17 483.29 3670.62 483.3C3670.25 483.3 3670.08 483.14 3670.04 482.84C3669.99 482.43 3670.08 482.07 3670.64 482.1Z" fill="white"/>
                    <path d="M3555.94 509.2C3556.25 509.29 3556.43 509.42 3556.43 509.78C3556.43 510.33 3556.1 510.42 3555.7 510.38C3555.4 510.35 3555.23 510.18 3555.22 509.81C3555.22 509.26 3555.55 509.21 3555.94 509.2Z" fill="white"/>
                    <path d="M3528.38 710.19C3528.74 710.29 3528.92 710.42 3528.92 710.79C3528.92 711.34 3528.59 711.43 3528.19 711.39C3527.89 711.36 3527.72 711.19 3527.71 710.82C3527.7 710.26 3528.06 710.24 3528.38 710.19Z" fill="white"/>
                    <path d="M3523.24 686.51C3523.28 687.07 3522.92 687.17 3522.52 687.13C3522.22 687.1 3522.04 686.93 3522.04 686.56C3522.04 686.01 3522.37 685.92 3522.77 685.96C3523.07 685.99 3523.3 686.16 3523.24 686.51Z" fill="white"/>
                    <path d="M3602.78 417.15C3602.4 417.15 3602 417.13 3602.04 416.58C3602.05 416.41 3602.15 416.25 3602.21 416.08C3602.55 416.33 3602.89 416.59 3603.23 416.84C3603.08 416.94 3602.93 417.04 3602.78 417.15Z" fill="white"/>
                    <path d="M3622.77 466.73C3623.15 466.73 3623.55 466.75 3623.51 467.3C3623.5 467.47 3623.4 467.63 3623.34 467.8C3623 467.55 3622.66 467.29 3622.32 467.04C3622.47 466.94 3622.62 466.84 3622.77 466.73Z" fill="white"/>
                    <path d="M3490.79 503.07C3491.08 503.34 3491.36 503.6 3491.64 503.86C3491.4 503.96 3491.15 504.16 3490.91 504.14C3490.39 504.11 3490.41 503.67 3490.47 503.3C3490.49 503.2 3490.7 503.13 3490.8 503.07H3490.79Z" fill="white"/>
                    <path d="M3642.83 576.21C3642.75 576.43 3642.7 576.73 3642.63 576.73C3642.11 576.76 3641.98 576.22 3641.62 575.98C3641.77 575.88 3641.91 575.72 3642.07 575.7C3642.47 575.64 3642.81 575.74 3642.83 576.21Z" fill="white"/>
                    <path d="M3733.01 601.22C3733.13 601.3 3733.35 601.37 3733.37 601.47C3733.44 601.84 3733.46 602.28 3732.94 602.31C3732.7 602.33 3732.45 602.14 3732.21 602.04C3732.48 601.77 3732.74 601.5 3733.02 601.21L3733.01 601.22Z" fill="white"/>
                    <path d="M3709.39 583.64C3709.36 583.1 3709.73 583.04 3710.12 583.08C3710.28 583.09 3710.42 583.26 3710.57 583.36C3710.24 583.62 3709.91 583.88 3709.57 584.15C3709.51 583.99 3709.45 583.82 3709.38 583.65L3709.39 583.64Z" fill="white"/>
                    <path d="M3512.81 512.88C3512.88 512.92 3513.1 512.98 3513.12 513.07C3513.19 513.44 3513.22 513.87 3512.71 513.93C3512.48 513.95 3512.22 513.77 3511.97 513.67C3512.24 513.41 3512.52 513.15 3512.81 512.87V512.88Z" fill="white"/>
                    <path d="M3551.17 514.01C3551.71 513.98 3551.77 514.35 3551.73 514.74C3551.72 514.9 3551.55 515.04 3551.45 515.19C3551.19 514.86 3550.93 514.53 3550.66 514.19C3550.82 514.13 3550.99 514.07 3551.16 514L3551.17 514.01Z" fill="white"/>
                    <path d="M3719.54 536.03C3719.7 536.12 3719.85 536.21 3720.01 536.3C3719.69 536.58 3719.38 536.85 3719.06 537.13C3718.99 536.97 3718.88 536.81 3718.86 536.65C3718.79 536.13 3719.14 536.04 3719.55 536.03H3719.54Z" fill="white"/>
                    <path d="M3540.95 679.74C3540.92 679.2 3541.29 679.14 3541.68 679.18C3541.84 679.19 3541.98 679.36 3542.13 679.46C3541.8 679.72 3541.47 679.98 3541.13 680.25C3541.07 680.09 3541.01 679.92 3540.94 679.75L3540.95 679.74Z" fill="white"/>
                    <path d="M3543.85 720.4C3544.06 720.27 3544.2 720.11 3544.36 720.09C3544.77 720.04 3545.12 720.15 3545.07 720.67C3545.05 720.84 3544.95 721 3544.88 721.16C3544.54 720.91 3544.2 720.66 3543.85 720.39V720.4Z" fill="white"/>
                    <path d="M3435.97 529.34C3436.32 529.53 3436.2 529.86 3436.18 530.12C3436.18 530.2 3435.9 530.37 3435.82 530.33C3435.48 530.18 3435.6 529.84 3435.62 529.57C3435.62 529.48 3435.85 529.41 3435.97 529.33V529.34Z" fill="white"/>
                    <path d="M3457.89 515.54C3458.2 515.44 3458.45 515.32 3458.71 515.3C3458.86 515.3 3459.01 515.47 3459.16 515.57C3458.92 515.67 3458.68 515.83 3458.42 515.86C3458.28 515.88 3458.12 515.69 3457.88 515.54H3457.89Z" fill="white"/>
                    <path d="M3646.61 696.21C3646.96 696.4 3646.84 696.73 3646.82 696.99C3646.82 697.07 3646.54 697.24 3646.46 697.2C3646.12 697.05 3646.24 696.71 3646.26 696.44C3646.26 696.35 3646.49 696.28 3646.61 696.2V696.21Z" fill="white"/>
                    <path d="M3716.65 610.79C3716.75 611.1 3716.87 611.35 3716.89 611.61C3716.89 611.76 3716.72 611.91 3716.62 612.06C3716.52 611.82 3716.36 611.58 3716.33 611.32C3716.31 611.18 3716.5 611.02 3716.65 610.78V610.79Z" fill="white"/>
                    <path d="M3716.22 607.63C3716.21 607.79 3716.24 608.06 3716.16 608.3C3716.08 608.59 3715.86 608.59 3715.78 608.3C3715.71 608.06 3715.71 607.77 3715.77 607.53C3715.84 607.24 3716.02 607.25 3716.22 607.63Z" fill="white"/>
                    <path d="M3662.97 703.6C3663.03 703.44 3663.08 703.15 3663.17 703.13C3663.52 703.07 3663.6 703.33 3663.56 703.61C3663.53 703.78 3663.46 704.06 3663.37 704.08C3663.02 704.14 3662.96 703.89 3662.98 703.6H3662.97Z" fill="white"/>
                    <path d="M3480.79 490.64C3480.95 490.7 3481.24 490.75 3481.26 490.84C3481.32 491.19 3481.06 491.27 3480.78 491.23C3480.61 491.2 3480.33 491.13 3480.31 491.04C3480.25 490.69 3480.5 490.63 3480.79 490.65V490.64Z" fill="white"/>
                    <path d="M3709.79 531.67C3710.1 531.57 3710.35 531.45 3710.61 531.43C3710.76 531.43 3710.91 531.6 3711.06 531.7C3710.82 531.8 3710.58 531.96 3710.32 531.99C3710.18 532.01 3710.02 531.82 3709.78 531.67H3709.79Z" fill="white"/>
                    <path d="M3478.4 649.79C3478.51 649.86 3478.66 649.96 3478.81 650.05C3478.57 650.15 3478.33 650.31 3478.07 650.34C3477.93 650.36 3477.77 650.17 3477.62 650.07C3477.81 649.83 3478.04 649.71 3478.4 649.79Z" fill="white"/>
                    <path d="M3706.87 486.34C3706.9 486.63 3706.84 486.89 3706.49 486.83C3706.4 486.82 3706.32 486.53 3706.28 486.36C3706.23 486.08 3706.3 485.81 3706.65 485.87C3706.74 485.88 3706.79 486.17 3706.86 486.33L3706.87 486.34Z" fill="white"/>
                    <path d="M3518.59 650.38C3518.62 650.67 3518.56 650.93 3518.21 650.87C3518.12 650.86 3518.04 650.57 3518 650.4C3517.95 650.12 3518.02 649.85 3518.37 649.91C3518.46 649.92 3518.51 650.21 3518.58 650.37L3518.59 650.38Z" fill="white"/>
                    <path d="M3527.37 641.36C3527.4 641.65 3527.34 641.91 3526.99 641.85C3526.9 641.84 3526.82 641.55 3526.78 641.38C3526.73 641.1 3526.8 640.83 3527.15 640.89C3527.24 640.9 3527.29 641.19 3527.36 641.35L3527.37 641.36Z" fill="white"/>
                    <path d="M3554.67 603.84C3554.98 603.74 3555.23 603.62 3555.49 603.6C3555.64 603.6 3555.79 603.77 3555.94 603.87C3555.7 603.97 3555.46 604.13 3555.2 604.16C3555.06 604.18 3554.9 603.99 3554.66 603.84H3554.67Z" fill="white"/>
                    <path d="M3503.51 508.69C3503.71 508.79 3503.95 508.91 3504.18 509.02C3504.03 509.12 3503.87 509.32 3503.74 509.3C3503.49 509.28 3503.24 509.13 3503 509.03C3503.15 508.93 3503.3 508.83 3503.52 508.68L3503.51 508.69Z" fill="white"/>
                    <path d="M3594.91 532.44C3595.02 532.51 3595.17 532.61 3595.32 532.7C3595.08 532.8 3594.84 532.96 3594.58 532.99C3594.44 533.01 3594.28 532.82 3594.13 532.72C3594.32 532.48 3594.55 532.36 3594.91 532.44Z" fill="white"/>
                    <path d="M3601.73 486.85C3602.04 486.75 3602.29 486.63 3602.55 486.61C3602.7 486.61 3602.85 486.78 3603 486.88C3602.76 486.98 3602.52 487.14 3602.26 487.17C3602.12 487.19 3601.96 487 3601.72 486.85H3601.73Z" fill="white"/>
                    <path d="M3611.89 415.32C3612.24 415.51 3612.12 415.84 3612.1 416.1C3612.1 416.18 3611.82 416.35 3611.74 416.31C3611.4 416.16 3611.52 415.82 3611.54 415.55C3611.54 415.46 3611.77 415.39 3611.89 415.31V415.32Z" fill="white"/>
                    <path d="M3641.4 472.99C3641.43 473.28 3641.37 473.54 3641.02 473.48C3640.93 473.47 3640.85 473.18 3640.81 473.01C3640.76 472.73 3640.83 472.46 3641.18 472.52C3641.27 472.53 3641.32 472.82 3641.39 472.98L3641.4 472.99Z" fill="white"/>
                    <path d="M3635.36 681.43C3635.42 681.27 3635.47 680.98 3635.56 680.96C3635.91 680.9 3635.99 681.16 3635.95 681.44C3635.92 681.61 3635.85 681.89 3635.76 681.91C3635.41 681.97 3635.35 681.72 3635.37 681.43H3635.36Z" fill="white"/>
                    <path d="M3618.62 586.1C3618.78 586.16 3619.07 586.21 3619.09 586.3C3619.15 586.65 3618.89 586.73 3618.61 586.69C3618.44 586.66 3618.16 586.59 3618.14 586.5C3618.08 586.15 3618.33 586.09 3618.62 586.11V586.1Z" fill="white"/>
                    <path d="M3634.03 585C3634.16 584.93 3634.29 584.84 3634.43 584.81C3634.48 584.8 3634.56 584.93 3634.63 584.99C3634.5 585.06 3634.37 585.15 3634.23 585.18C3634.18 585.19 3634.1 585.06 3634.03 585Z" fill="white"/>
                    <path d="M3729.47 532.64C3729.6 532.57 3729.73 532.48 3729.87 532.45C3729.92 532.44 3730 532.57 3730.07 532.63C3729.94 532.7 3729.81 532.79 3729.67 532.82C3729.62 532.83 3729.54 532.7 3729.47 532.64Z" fill="white"/>
                    <path d="M3530.38 629.54C3530.51 629.61 3530.64 629.68 3530.77 629.75C3530.7 629.82 3530.63 629.95 3530.58 629.94C3530.44 629.91 3530.31 629.82 3530.18 629.76C3530.25 629.69 3530.31 629.63 3530.39 629.55L3530.38 629.54Z" fill="white"/>
                    <path d="M3517.34 674.07C3517.26 673.99 3517.19 673.93 3517.13 673.86C3517.26 673.79 3517.39 673.7 3517.53 673.67C3517.58 673.66 3517.66 673.79 3517.73 673.85C3517.6 673.92 3517.47 674 3517.35 674.07H3517.34Z" fill="white"/>
                    <path d="M3514.67 587.74C3514.6 587.87 3514.53 588 3514.46 588.13C3514.39 588.06 3514.26 587.99 3514.27 587.94C3514.3 587.8 3514.39 587.67 3514.45 587.54C3514.52 587.61 3514.58 587.67 3514.66 587.75L3514.67 587.74Z" fill="white"/>
                    <path d="M3547.85 555.61C3547.92 555.74 3548.01 555.87 3548.04 556.01C3548.05 556.06 3547.92 556.14 3547.86 556.21C3547.79 556.08 3547.7 555.95 3547.67 555.81C3547.66 555.76 3547.79 555.68 3547.85 555.61Z" fill="white"/>
                    <path d="M3552.15 507.26C3552.07 507.34 3552.01 507.41 3551.94 507.47C3551.87 507.34 3551.78 507.21 3551.75 507.07C3551.74 507.02 3551.87 506.94 3551.93 506.87C3552 507 3552.08 507.13 3552.15 507.25V507.26Z" fill="white"/>
                    <path d="M3495.21 488.75C3495.34 488.82 3495.47 488.89 3495.6 488.96C3495.53 489.03 3495.46 489.16 3495.41 489.15C3495.27 489.12 3495.14 489.03 3495.01 488.97C3495.08 488.9 3495.14 488.84 3495.22 488.76L3495.21 488.75Z" fill="white"/>
                    <path d="M3513.03 607.22C3513.11 607.3 3513.18 607.36 3513.24 607.43C3513.11 607.5 3512.98 607.59 3512.84 607.62C3512.79 607.63 3512.71 607.5 3512.64 607.44C3512.77 607.37 3512.9 607.29 3513.02 607.22H3513.03Z" fill="white"/>
                    <path d="M3576.43 568.19C3576.35 568.11 3576.28 568.05 3576.22 567.98C3576.35 567.91 3576.48 567.82 3576.62 567.79C3576.67 567.78 3576.75 567.91 3576.82 567.97C3576.69 568.04 3576.56 568.12 3576.44 568.19H3576.43Z" fill="white"/>
                    <path d="M3586.17 424.74C3586.24 424.67 3586.33 424.54 3586.36 424.55C3586.49 424.62 3586.65 424.69 3586.71 424.81C3586.84 425.06 3586.71 425.18 3586.46 425.06C3586.34 425 3586.26 424.84 3586.17 424.73V424.74Z" fill="white"/>
                    <path d="M3586.99 425.08C3587.08 425.2 3587.27 425.34 3587.25 425.42C3587.18 425.65 3587 425.69 3586.9 425.54C3586.81 425.41 3586.78 425.24 3586.72 425.09C3586.81 425.09 3586.9 425.09 3586.98 425.09L3586.99 425.08Z" fill="white"/>
                    <path d="M3648.85 611.29C3648.98 611.36 3649.11 611.43 3649.24 611.5C3649.17 611.57 3649.1 611.7 3649.05 611.69C3648.91 611.66 3648.78 611.57 3648.65 611.51C3648.72 611.44 3648.78 611.38 3648.86 611.3L3648.85 611.29Z" fill="white"/>
                    <path d="M3500.29 426.35C3500.42 426.42 3500.55 426.49 3500.68 426.56C3500.61 426.63 3500.54 426.76 3500.49 426.75C3500.35 426.72 3500.22 426.63 3500.09 426.57C3500.16 426.5 3500.22 426.44 3500.3 426.36L3500.29 426.35Z" fill="white"/>
                    <path d="M3591.17 418.58C3591.25 418.66 3591.32 418.72 3591.38 418.79C3591.25 418.86 3591.12 418.95 3590.98 418.98C3590.93 418.99 3590.85 418.86 3590.78 418.8C3590.91 418.73 3591.04 418.65 3591.16 418.58H3591.17Z" fill="white"/>
                    <path d="M3491.55 469.04C3491.42 468.97 3491.29 468.9 3491.16 468.83C3491.23 468.76 3491.3 468.63 3491.35 468.64C3491.49 468.67 3491.62 468.76 3491.75 468.82C3491.68 468.89 3491.62 468.95 3491.54 469.03L3491.55 469.04Z" fill="white"/>
                    <path d="M3629.72 515.4C3629.85 515.47 3629.98 515.54 3630.11 515.61C3630.04 515.68 3629.97 515.81 3629.92 515.8C3629.78 515.77 3629.65 515.68 3629.52 515.62C3629.59 515.55 3629.65 515.49 3629.73 515.41L3629.72 515.4Z" fill="white"/>
                    <path d="M3499.45 560.22C3499.37 560.3 3499.31 560.37 3499.24 560.43C3499.17 560.3 3499.08 560.17 3499.05 560.03C3499.04 559.98 3499.17 559.9 3499.23 559.83C3499.3 559.96 3499.38 560.09 3499.45 560.21V560.22Z" fill="white"/>
                    <path d="M3604.29 450.66C3604.42 450.59 3604.55 450.5 3604.69 450.47C3604.74 450.46 3604.82 450.59 3604.89 450.65C3604.76 450.72 3604.63 450.81 3604.49 450.84C3604.44 450.85 3604.36 450.72 3604.29 450.66Z" fill="white"/>
                    <path d="M3496.61 547.5C3496.74 547.57 3496.87 547.64 3497 547.71C3496.93 547.78 3496.86 547.91 3496.81 547.9C3496.67 547.87 3496.54 547.78 3496.41 547.72L3496.62 547.51L3496.61 547.5Z" fill="white"/>
                    <path d="M3608.52 580.09C3608.59 579.96 3608.66 579.83 3608.73 579.7C3608.8 579.77 3608.93 579.84 3608.92 579.89C3608.89 580.03 3608.8 580.16 3608.74 580.29C3608.67 580.22 3608.61 580.16 3608.53 580.08L3608.52 580.09Z" fill="white"/>
                    <path d="M3671.86 703.2C3671.99 703.13 3672.12 703.04 3672.26 703.01C3672.31 703 3672.39 703.13 3672.46 703.19C3672.33 703.26 3672.2 703.35 3672.06 703.38C3672.01 703.39 3671.93 703.26 3671.86 703.2Z" fill="white"/>
                    <path d="M3673.96 431.97C3675.7 431.01 3676.95 431.92 3678.12 432.8C3678.55 433.12 3679.13 433.72 3679.41 433.8C3681.21 434.34 3679.71 435.99 3680.55 436.77C3679.67 437.47 3678.85 438.6 3677.89 438.74C3676.97 438.87 3675.82 438.94 3674.83 438.62C3673.9 438.32 3672.46 438.32 3673.15 436.56C3673.25 436.3 3672.83 435.85 3672.69 435.47C3672.07 433.79 3672.54 432.62 3673.96 431.97Z" fill="white"/>
                    <path d="M3630.62 586.53C3630.15 585.45 3630.66 584.31 3631.04 584.15C3632.51 583.53 3632.5 581.93 3633.46 580.99C3634.14 580.33 3634.65 580.03 3635.49 580.41C3636.87 581.03 3637.26 581.6 3637.03 582.68C3636.93 583.15 3636.88 583.6 3636.85 584.08C3636.71 586.14 3633.25 588.51 3631.3 587.15C3630.95 586.91 3630.66 586.72 3630.63 586.52L3630.62 586.53Z" fill="white"/>
                    <path d="M3709.91 532.74C3710.31 531.79 3709.49 530.52 3710.74 529.83C3710.92 529.73 3711.17 529.71 3711.28 529.57C3711.76 528.98 3712.06 528.18 3713.02 528.23C3713.77 528.28 3714.17 528.9 3714.69 529.32C3715.71 530.14 3715.88 531.99 3715.09 533.2C3714.48 534.14 3713.65 534.48 3712.51 534.39C3711.32 534.3 3710.75 533.34 3709.9 532.74H3709.91Z" fill="white"/>
                    <path d="M3822.1 620.64C3822.82 622.25 3822.57 623.24 3821.47 623.91C3820.87 624.27 3820.17 624.49 3819.55 624.82C3818.96 625.13 3818.65 624.96 3818.42 624.37C3818.16 623.71 3817.74 623.1 3817.56 622.43C3817.27 621.28 3817.67 620.25 3818.43 619.38C3818.58 619.2 3818.99 619.06 3819.19 619.14C3819.93 619.43 3820.7 619.44 3821.45 619.57C3821.82 619.64 3822.03 620.54 3822.09 620.66L3822.1 620.64Z" fill="white"/>
                    <path d="M3710.34 692.91C3709.95 692.21 3709.37 691.23 3710.36 690.41C3710.83 690.02 3711.71 689.3 3712.21 689.86C3712.76 690.48 3713.88 690.72 3713.82 691.8C3713.78 692.56 3713.47 693.34 3713.6 694.05C3713.72 694.7 3713.59 694.91 3713.05 695.17C3712.28 695.54 3711.65 695.28 3711.21 694.74C3710.84 694.29 3710.68 693.66 3710.33 692.91H3710.34Z" fill="white"/>
                    <path d="M3842.8 613.14C3842.45 612.42 3842.73 611.53 3843.43 611.16C3843.66 611.04 3846.54 611.28 3846.71 611.44C3847.12 611.8 3846.85 613.64 3846.77 613.59C3845.68 612.92 3845.73 614.48 3845.01 614.41C3844.33 614.34 3843.01 613.6 3842.79 613.13L3842.8 613.14Z" fill="white"/>
                    <path d="M3732.19 470.33C3731.8 470.5 3731.26 470.87 3731.09 470.5C3730.77 469.83 3730.12 469.23 3730.46 468.29C3730.65 467.77 3731.18 467.61 3731.34 467.19C3731.6 466.5 3731.91 466.26 3732.67 466.5C3733.49 466.76 3733.9 467.39 3733.75 468.04C3733.55 468.95 3733.41 470.08 3732.19 470.34V470.33Z" fill="white"/>
                    <path d="M3690.23 567.26C3690.46 567.86 3691.1 568.47 3689.98 568.93C3689.73 569.03 3689.7 569.65 3689.54 570.02C3689.36 570.44 3689.14 570.84 3688.93 571.25C3688.59 570.66 3687.97 570.08 3687.97 569.49C3687.97 568.74 3688.49 567.99 3688.73 567.22C3688.86 566.8 3689.07 566.44 3689.5 566.43C3689.98 566.42 3690.06 566.91 3690.23 567.26Z" fill="white"/>
                    <path d="M3833.38 490.3C3833.46 490.48 3833.57 490.65 3833.62 490.84C3833.75 491.26 3834.31 491.7 3833.75 492.13C3833.18 492.57 3832.53 492.63 3831.83 492.27C3831.24 491.96 3831.32 491.58 3831.46 491.06C3831.55 490.72 3831.36 490.31 3831.45 489.97C3831.57 489.52 3831.61 488.94 3832.21 488.83C3832.92 488.7 3832.88 489.39 3833.11 489.76C3833.22 489.92 3833.28 490.12 3833.36 490.3H3833.38Z" fill="white"/>
                    <path d="M3840.44 623.94C3841.37 624.02 3842.36 623.82 3843.22 624.34C3843.42 624.46 3843.59 624.8 3843.42 625.14C3843.2 625.56 3843.22 626.25 3842.62 626.27C3841.88 626.29 3841.12 626.16 3840.38 626.01C3839.51 625.83 3840.4 625.25 3840.17 624.89C3839.93 624.51 3839.92 624.14 3840.45 623.94H3840.44Z" fill="white"/>
                    <path d="M3666.82 523.56C3667.13 524.02 3667.1 524.4 3666.48 524.65C3665.51 525.04 3664.74 524.78 3664.31 523.95C3663.93 523.21 3664.21 522.58 3664.7 522.13C3665.27 521.61 3666.29 522.32 3666.82 523.55V523.56Z" fill="white"/>
                    <path d="M3576.81 508.73C3576.94 509.01 3577.12 509.27 3577.18 509.56C3577.33 510.24 3576.67 510.78 3576.03 510.54C3575.65 510.4 3575.23 510.32 3574.9 510.1C3574.48 509.82 3574.2 509.28 3574.31 508.84C3574.44 508.33 3575.18 508.13 3575.27 507.5C3575.77 507.77 3576.51 507.69 3576.68 508.45C3576.7 508.55 3576.76 508.63 3576.8 508.73H3576.81Z" fill="white"/>
                    <path d="M3809.91 576.56C3810.03 576.23 3810.15 575.95 3810.24 575.66C3810.45 574.93 3811.55 575.2 3811.67 574.33C3811.73 573.87 3812.15 573.68 3812.56 573.89C3812.95 574.08 3813 574.47 3812.83 574.89C3812.6 575.44 3812.44 576.03 3812.19 576.57C3812.07 576.83 3812.21 577.45 3811.54 577.17C3811 576.95 3810.45 576.76 3809.91 576.56Z" fill="white"/>
                    <path d="M3785.09 699.67C3784.47 699.85 3783.51 699.19 3783.34 698.47C3783.27 698.18 3784.27 696.6 3784.62 696.54C3785.51 696.4 3785.44 697.25 3785.64 697.7C3786.02 698.54 3785.68 699.5 3785.09 699.67Z" fill="white"/>
                    <path d="M3714.12 653.76C3714.64 653.42 3715.04 653.38 3715.3 654.03C3715.62 654.82 3715.43 655.83 3714.88 656.08C3714.33 656.33 3713.42 655.81 3713.07 655.04C3712.55 653.89 3713.74 654.13 3714.11 653.77L3714.12 653.76Z" fill="white"/>
                    <path d="M3706.75 652.94C3706.82 652.39 3706.86 651.78 3707.42 651.47C3708.03 651.14 3709.17 651.93 3709.35 652.74C3709.47 653.27 3709.09 653.41 3708.73 653.53C3707.88 653.83 3706.95 653.54 3706.76 652.93L3706.75 652.94Z" fill="white"/>
                    <path d="M3786.83 605.2C3787 605.56 3787.06 605.75 3787.16 605.92C3787.55 606.55 3787.02 607.03 3786.91 607.58C3786.42 607.31 3785.71 607.39 3785.49 606.69C3785.43 606.5 3785.34 606.32 3785.24 606.15C3784.91 605.56 3784.81 605.14 3785.62 604.79C3786.4 604.45 3786.71 604.68 3786.82 605.21L3786.83 605.2Z" fill="white"/>
                    <path d="M3621.04 660.91C3621.67 660.57 3621.99 660.94 3622 661.32C3622 662.07 3621.87 662.83 3621.74 663.58C3621.61 664.28 3621.18 663.8 3620.88 663.74C3619.56 663.46 3620.55 662.43 3620.27 661.79C3619.95 661.05 3620.84 661.16 3621.04 660.91Z" fill="white"/>
                    <path d="M3760.14 630.39C3759.65 629.7 3759.93 629.05 3760.41 628.58C3760.77 628.23 3762.33 628.85 3762.3 629.27C3762.24 630.07 3761.8 630.6 3761.01 630.89C3760.46 631.09 3760.32 630.75 3760.14 630.39Z" fill="white"/>
                    <path d="M3780.31 446.22C3780.89 446.07 3781.39 446.36 3781.83 446.72C3782.09 446.93 3781.28 448.4 3780.74 448.71C3780.25 448.99 3780.04 448.66 3779.88 448.28C3779.55 447.51 3779.76 446.49 3780.31 446.22Z" fill="white"/>
                    <path d="M3802.43 636.68C3802.12 636.72 3801.76 636.65 3801.78 636.29C3801.83 635.44 3801.66 634.57 3802.02 633.74C3802.17 633.39 3802.47 633.23 3802.69 633.25C3802.91 633.27 3803.27 633.61 3803.27 633.8C3803.25 634.56 3803.12 635.31 3803.01 636.06C3802.96 636.38 3802.75 636.58 3802.42 636.68H3802.43Z" fill="white"/>
                    <path d="M3827.34 658.38C3827.18 658.1 3826.97 657.75 3827.41 657.44C3827.79 657.17 3828.13 657.42 3828.51 657.49C3829.26 657.63 3829.35 658.29 3829.5 658.8C3829.59 659.1 3829.33 659.8 3829.13 659.84C3828.74 659.91 3828.21 659.75 3827.88 659.5C3827.6 659.29 3827.53 658.81 3827.33 658.39L3827.34 658.38Z" fill="white"/>
                    <path d="M3664.68 693.89C3664.26 694.17 3663.88 694.14 3663.64 693.51C3663.33 692.72 3663.52 691.74 3664.07 691.48C3664.98 691.04 3664.88 692.48 3665.69 692.37C3665.83 692.35 3665.37 693.51 3664.69 693.89H3664.68Z" fill="white"/>
                    <path d="M3783.11 547.41C3783.15 547.23 3783.13 546.98 3783.24 546.89C3783.86 546.4 3785.57 546.82 3785.83 547.53C3785.92 547.77 3785.98 548.16 3785.85 548.32C3785.72 548.48 3785.32 548.54 3785.07 548.49C3783.97 548.26 3783.32 547.87 3783.1 547.41H3783.11Z" fill="white"/>
                    <path d="M3699.65 470.5C3699.38 470.63 3699.12 470.8 3698.83 470.86C3698.1 471.02 3698.14 470.33 3697.91 469.96C3697.61 469.47 3697.95 469.26 3698.34 469.09C3698.69 468.94 3699.01 468.71 3699.44 468.91C3699.88 469.11 3700.46 469.18 3700.48 469.79C3700.49 470.26 3699.98 470.33 3699.65 470.5Z" fill="white"/>
                    <path d="M3799.7 634.68C3799.85 635.29 3799.44 635.42 3799.08 635.54C3798.22 635.83 3797.3 635.54 3797.13 634.92C3796.99 634.4 3797.4 634.26 3797.77 634.14C3798.63 633.85 3799.53 634.14 3799.71 634.68H3799.7Z" fill="white"/>
                    <path d="M3667.83 701.85C3667.18 701.58 3666.51 701.49 3666.24 700.68C3666.02 700.01 3666.32 699.85 3666.8 699.61C3667.32 699.35 3667.61 699.35 3667.97 699.92C3668.43 700.67 3667.97 701.21 3667.83 701.85Z" fill="white"/>
                    <path d="M3658.04 690.06C3658.07 689.88 3658.04 689.6 3658.13 689.53C3658.46 689.32 3658.86 689.01 3659.19 689.06C3659.72 689.14 3660.4 689.19 3660.62 689.87C3660.79 690.39 3660.37 690.54 3660 690.66C3659.12 690.95 3658.27 690.69 3658.03 690.07L3658.04 690.06Z" fill="white"/>
                    <path d="M3730.53 443.1C3730.37 442.72 3730.2 442.3 3730.75 442.16C3731.46 441.97 3732.16 442.16 3732.39 442.87C3732.51 443.25 3732.18 443.77 3732.06 444.23C3731.46 443.97 3730.69 443.94 3730.53 443.1Z" fill="white"/>
                    <path d="M3608.65 547.82C3609.33 547.56 3610.36 548.01 3610.5 548.5C3610.64 549.02 3610.22 549.11 3609.88 549.29C3609.2 549.65 3608.57 549.48 3608.2 548.93C3608.09 548.77 3608.3 547.98 3608.66 547.82H3608.65Z" fill="white"/>
                    <path d="M3745.62 647C3745.41 646.54 3745.21 646.04 3745.75 645.97C3746.42 645.88 3747.11 646.04 3747.8 646.08C3747.6 646.75 3747.38 647.33 3746.59 647.59C3745.84 647.83 3745.81 647.28 3745.62 647Z" fill="white"/>
                    <path d="M3595.26 623.42C3594.83 622.7 3595.17 622.49 3595.68 622.21C3596.47 621.77 3596.7 622.18 3596.98 622.8C3597.27 623.43 3596.99 623.63 3596.48 623.91C3595.72 624.31 3595.38 624.05 3595.26 623.43V623.42Z" fill="white"/>
                    <path d="M3758.47 648.81C3758.99 648.68 3759.61 647.95 3759.9 648.94C3760.06 649.48 3759.85 650.11 3759.31 650.44C3758.41 650.98 3758.21 649.83 3757.77 649.54C3757.22 649.17 3758.28 649.13 3758.48 648.82L3758.47 648.81Z" fill="white"/>
                    <path d="M3847.04 663.3C3846.92 663.03 3846.75 662.77 3846.68 662.49C3846.55 661.97 3846.97 661.85 3847.33 661.71C3847.59 661.61 3847.84 661.33 3848.14 661.56C3848.49 661.82 3848.57 662.16 3848.38 662.57C3848.3 662.75 3848.25 662.94 3848.17 663.12C3848.03 663.47 3847.92 664.07 3847.72 664.11C3847.27 664.19 3847.23 663.62 3847.04 663.3Z" fill="white"/>
                    <path d="M3620.92 491.21C3620.2 491.31 3620.22 490.66 3620.02 490.27C3619.65 489.55 3620.34 489.5 3620.73 489.29C3621.09 489.1 3621.48 488.96 3621.67 489.51C3621.86 490.07 3621.42 491.05 3620.92 491.21Z" fill="white"/>
                    <path d="M3682.18 639.54C3682.67 639.34 3683.05 639.17 3683.24 639.73C3683.43 640.29 3683 641.3 3682.49 641.42C3681.97 641.54 3681.9 641.09 3681.7 640.77C3681.2 639.99 3681.68 639.72 3682.18 639.55V639.54Z" fill="white"/>
                    <path d="M3797.97 659.58C3797.77 659.61 3797.38 659.79 3797.24 659.67C3796.86 659.36 3797.24 658.96 3797.41 658.73C3797.65 658.39 3797.28 657.34 3798.34 657.81C3798.69 657.97 3799.12 658.03 3799.19 658.49C3799.33 659.41 3798.38 659.13 3797.97 659.59V659.58Z" fill="white"/>
                    <path d="M3816.79 503.82C3816.88 503.35 3816.79 502.49 3817.39 502.62C3817.91 502.73 3818.52 502.14 3818.87 502.76C3819.08 503.14 3817.99 504.3 3817.41 504.33C3817.11 504.35 3816.88 504.15 3816.79 503.82Z" fill="white"/>
                    <path d="M3611.45 613.64C3611.45 613.64 3610.99 613.35 3610.63 613.03C3610.47 612.89 3611.18 611.7 3611.46 611.64C3612.19 611.48 3612.14 612.16 3612.37 612.53C3612.65 612.98 3612.4 613.23 3611.45 613.65V613.64Z" fill="white"/>
                    <path d="M3601.14 579.28C3601.03 578.56 3601.69 578.61 3602.06 578.38C3602.53 578.1 3602.88 578.39 3602.9 578.79C3602.92 579.24 3602.72 579.72 3602.54 580.15C3602.47 580.3 3601.21 579.54 3601.15 579.28H3601.14Z" fill="white"/>
                    <path d="M3690.03 416.58C3690.46 416.26 3690.76 416.4 3690.88 416.66C3691.2 417.37 3690.92 417.95 3690.34 418.41C3690.04 418.64 3689.75 418.5 3689.62 418.24C3689.26 417.52 3689.62 416.96 3690.03 416.58Z" fill="white"/>
                    <path d="M3806.07 550.49C3805.45 550.67 3804.84 550.49 3804.63 550.03C3804.52 549.8 3804.71 549.07 3805.26 548.84C3806 548.52 3806.03 549.17 3806.24 549.55C3806.44 549.91 3806.54 550.27 3806.08 550.49H3806.07Z" fill="white"/>
                    <path d="M3608.92 475.25C3609.16 475.1 3610.57 475.36 3610.51 475.52C3610.37 475.97 3610.2 476.45 3609.89 476.78C3609.6 477.08 3609.16 477.01 3609 476.52C3608.87 476.11 3608.33 475.71 3608.92 475.25Z" fill="white"/>
                    <path d="M3810.18 555.15C3810.11 554.59 3810.21 553.94 3810.95 553.63C3811.22 553.51 3811.54 553.6 3811.61 553.97C3811.75 554.69 3811.58 555.31 3810.88 555.64C3810.62 555.76 3810.31 555.67 3810.18 555.15Z" fill="white"/>
                    <path d="M3876.73 540.89C3876.47 540.4 3876.73 540.05 3877.14 540.04C3877.59 540.02 3878.07 540.22 3878.5 540.4C3878.65 540.47 3877.89 541.73 3877.63 541.79C3877.26 541.88 3876.91 541.53 3876.73 540.89Z" fill="white"/>
                    <path d="M3754.9 645.3C3754.73 644.91 3754.56 644.53 3755.1 644.33C3755.68 644.12 3756.29 644.32 3756.51 644.79C3756.62 645.02 3756.43 645.74 3755.88 645.98C3755.14 646.3 3755.13 645.63 3754.9 645.3Z" fill="white"/>
                    <path d="M3849.98 630.07C3850.01 629.5 3850.1 628.98 3850.63 628.66C3850.94 628.47 3851.25 628.58 3851.34 628.86C3851.48 629.33 3852.23 629.83 3851.37 630.32C3850.85 630.62 3850.47 630.1 3849.98 630.06V630.07Z" fill="white"/>
                    <path d="M3759.56 632.05C3759.6 631.87 3759.63 631.69 3759.67 631.52C3760.5 631.81 3761.29 632.24 3762.23 632.03C3762.27 632.03 3762.45 632.37 3762.46 632.56C3762.46 632.67 3762.21 632.9 3762.17 632.88C3761.45 632.52 3760.57 633.07 3759.9 632.51C3759.76 632.39 3759.67 632.2 3759.56 632.05Z" fill="white"/>
                    <path d="M3763.33 629.16C3763.89 628.94 3763.91 629.79 3764.07 630.22C3764.37 631.09 3763.45 630.98 3763.04 631.26C3762.92 631.34 3762.28 631.27 3762.67 630.92C3763.25 630.39 3761.93 629.63 3763.33 629.16Z" fill="white"/>
                    <path d="M3675.13 462.3C3674.52 462.71 3674.04 462.17 3673.51 462.06C3673.63 461.7 3673.66 461.13 3673.89 461.03C3674.4 460.81 3674.92 461.07 3675.26 461.55C3675.47 461.84 3675.34 462.08 3675.13 462.3Z" fill="white"/>
                    <path d="M3618.16 615.93C3618.57 615.81 3619.02 615.32 3619.38 616.06C3619.64 616.59 3619.3 616.78 3618.92 616.93C3618.51 617.09 3618.06 617.55 3617.7 616.82C3617.44 616.29 3617.79 616.13 3618.16 615.94V615.93Z" fill="white"/>
                    <path d="M3749.39 429.88C3749.54 428.89 3750.03 428.47 3750.73 428.28C3750.89 428.24 3751.09 428.33 3751.27 428.35C3751.2 428.53 3751.1 428.71 3751.07 428.9C3750.98 429.63 3750.38 429.79 3749.85 429.98C3749.69 430.04 3749.44 429.88 3749.38 429.87L3749.39 429.88Z" fill="white"/>
                    <path d="M3588.06 623.93C3587.99 623.8 3588.3 623.57 3588.7 623.43C3589.11 623.28 3589.56 622.79 3589.93 623.52C3590.19 624.03 3589.88 624.24 3589.49 624.39C3589.08 624.55 3588.62 625.01 3588.07 623.93H3588.06Z" fill="white"/>
                    <path d="M3652.51 627.77C3652.65 627.79 3652.89 627.75 3652.94 627.83C3653.28 628.42 3653.6 629.01 3653.17 629.73C3652.99 630.03 3652.86 630.17 3652.6 629.95C3652.19 629.6 3652.14 628.09 3652.51 627.77Z" fill="white"/>
                    <path d="M3656.42 509.65C3656.25 508.7 3657.18 508.93 3657.6 508.64C3657.79 508.51 3658.13 508.85 3658.18 509.15C3658.31 510.05 3657.45 509.92 3657 510.17C3656.79 510.29 3656.49 509.98 3656.41 509.65H3656.42Z" fill="white"/>
                    <path d="M3719.67 609.44C3719.5 608.49 3720.43 608.72 3720.85 608.43C3721.04 608.3 3721.38 608.64 3721.43 608.94C3721.56 609.84 3720.7 609.71 3720.25 609.96C3720.04 610.08 3719.74 609.77 3719.66 609.44H3719.67Z" fill="white"/>
                    <path d="M3792.12 494.03C3792.05 493.9 3792.36 493.67 3792.76 493.53C3793.17 493.38 3793.62 492.89 3793.99 493.62C3794.25 494.13 3793.94 494.34 3793.55 494.49C3793.14 494.65 3792.68 495.11 3792.13 494.03H3792.12Z" fill="white"/>
                    <path d="M3741.85 686.55C3742.36 686.93 3742.88 687.32 3743.4 687.72C3743.42 687.74 3743.37 687.93 3743.3 687.98C3742.96 688.23 3742.61 688.23 3742.21 688.05C3741.76 687.86 3741.27 687.73 3741.34 687.11C3741.36 686.96 3741.57 686.84 3741.84 686.55H3741.85Z" fill="white"/>
                    <path d="M3781.5 646.96C3781.14 647.16 3780.77 647.39 3780.48 646.91C3780.1 646.3 3780.48 646.05 3781 645.84C3781.36 645.7 3781.72 645.43 3782.02 645.9C3782.39 646.5 3782.04 646.79 3781.5 646.95V646.96Z" fill="white"/>
                    <path d="M3814.06 594.55C3813.99 593.99 3814.01 593.7 3814.38 593.63C3815.1 593.49 3815.71 593.66 3816.05 594.36C3816.12 594.5 3816.01 594.72 3815.98 594.9C3815.31 594.78 3814.65 594.65 3814.06 594.55Z" fill="white"/>
                    <path d="M3699.71 634.59C3699.42 634.13 3699.35 633.7 3699.58 633.62C3700.06 633.44 3700.53 632.71 3701.12 633.4C3701.32 633.63 3701.37 634.06 3701.12 634.18C3700.65 634.41 3700.17 634.95 3699.7 634.59H3699.71Z" fill="white"/>
                    <path d="M3776.74 480.93C3776.58 480.54 3776.42 480.19 3776.96 479.96C3777.71 479.64 3777.75 480.28 3777.96 480.67C3778.15 481.03 3778.28 481.39 3777.74 481.63C3776.99 481.95 3776.97 481.29 3776.74 480.93Z" fill="white"/>
                    <path d="M3692.86 519.24C3691.91 519.41 3692.14 518.48 3691.85 518.06C3691.72 517.87 3692.06 517.53 3692.36 517.48C3693.26 517.35 3693.13 518.21 3693.38 518.66C3693.5 518.87 3693.19 519.17 3692.86 519.25V519.24Z" fill="white"/>
                    <path d="M3820.6 603.52C3820.24 603.72 3819.87 603.95 3819.58 603.47C3819.2 602.86 3819.58 602.61 3820.1 602.4C3820.46 602.26 3820.82 601.99 3821.12 602.46C3821.49 603.06 3821.14 603.35 3820.6 603.51V603.52Z" fill="white"/>
                    <path d="M3673.18 687.26C3672.58 687.61 3672.33 687.49 3672.17 687.21C3671.81 686.59 3672.21 686.34 3672.71 686.17C3672.97 686.08 3673.45 685.79 3673.47 686.03C3673.5 686.52 3673.24 687.03 3673.18 687.26Z" fill="white"/>
                    <path d="M3748.62 454.79C3748.62 454.63 3748.62 454.35 3748.63 454.08C3749.14 454.3 3749.82 454.24 3750.03 454.91C3750.12 455.2 3750.03 455.44 3749.69 455.59C3748.96 455.9 3748.92 455.28 3748.62 454.79Z" fill="white"/>
                    <path d="M3869.69 639.35C3869.91 639.19 3870.19 639.31 3870.34 639.56C3870.55 639.9 3870.68 640.33 3870.14 640.49C3869.75 640.6 3869.29 641.24 3868.98 640.61C3868.87 640.38 3869.07 639.65 3869.69 639.35Z" fill="white"/>
                    <path d="M3701.27 722.87C3700.9 722.26 3701.42 721.79 3701.55 721.25C3701.75 721.44 3702.01 721.6 3702.12 721.83C3702.31 722.22 3702.75 722.67 3702.03 723.04C3701.71 723.21 3701.49 723.11 3701.26 722.87H3701.27Z" fill="white"/>
                    <path d="M3852.34 609.78C3852.67 609.52 3852.27 609.7 3852.61 609.9C3852.79 610.01 3852.97 610.41 3852.91 610.6C3852.74 611.12 3852.74 611.8 3851.99 611.93C3851.91 611.94 3851.74 611.89 3851.73 611.84C3851.61 611.06 3852.18 610.48 3852.34 609.78Z" fill="white"/>
                    <path d="M3801.51 584.34C3801.74 583.83 3801.74 583.11 3802.48 583.04C3802.66 583.02 3802.99 583.36 3803.04 583.59C3803.22 584.3 3802.51 584.25 3802.15 584.49C3801.9 584.65 3801.66 584.68 3801.51 584.34Z" fill="white"/>
                    <path d="M3705.47 566.83C3705.39 566.65 3705.25 566.47 3705.24 566.29C3705.24 566.17 3705.39 565.92 3705.48 565.92C3705.81 565.9 3706.61 567.6 3706.41 567.9C3706.36 567.97 3706.02 567.97 3705.98 567.9C3705.78 567.56 3705.63 567.18 3705.46 566.82L3705.47 566.83Z" fill="white"/>
                    <path d="M3857.58 502.09C3857.29 501.54 3857.58 501.3 3857.98 501.17C3858.27 501.07 3858.51 501.17 3858.67 501.51C3858.9 502.02 3858.63 502.25 3858.23 502.38C3857.95 502.47 3857.63 502.45 3857.58 502.1V502.09Z" fill="white"/>
                    <path d="M3686.02 710.12C3685.97 709.79 3686.02 709.57 3686.36 709.42C3686.87 709.19 3687.1 709.46 3687.23 709.86C3687.33 710.15 3687.23 710.39 3686.89 710.55C3686.37 710.78 3686.19 710.49 3686.01 710.13L3686.02 710.12Z" fill="white"/>
                    <path d="M3623.05 495.46C3623 495.13 3623.05 494.91 3623.39 494.76C3623.9 494.53 3624.13 494.8 3624.26 495.2C3624.36 495.49 3624.26 495.73 3623.92 495.89C3623.4 496.12 3623.22 495.83 3623.04 495.47L3623.05 495.46Z" fill="white"/>
                    <path d="M3722.67 436.45C3723.02 436.3 3723.35 436.2 3723.59 436.71C3723.75 437.05 3723.66 437.29 3723.4 437.45C3723.04 437.67 3722.69 437.73 3722.45 437.22C3722.29 436.88 3722.39 436.68 3722.67 436.45Z" fill="white"/>
                    <path d="M3606.15 637.46C3605.59 637.46 3605.08 637.35 3604.77 636.84C3604.69 636.71 3604.72 636.33 3604.73 636.33C3605.29 636.25 3605.8 636.38 3606.11 636.9C3606.19 637.04 3606.14 637.25 3606.15 637.46Z" fill="white"/>
                    <path d="M3600.71 528.63C3600.5 528.34 3600.64 528.07 3600.9 527.92C3601.26 527.7 3601.61 527.64 3601.85 528.15C3602.01 528.49 3601.92 528.73 3601.66 528.89C3601.3 529.11 3600.92 529.17 3600.71 528.63Z" fill="white"/>
                    <path d="M3673.85 625.12C3673.8 624.79 3673.85 624.57 3674.19 624.42C3674.7 624.19 3674.93 624.46 3675.06 624.86C3675.16 625.15 3675.06 625.39 3674.72 625.55C3674.2 625.78 3674.02 625.49 3673.84 625.13L3673.85 625.12Z" fill="white"/>
                    <path d="M3874.15 567.46C3874.09 567.08 3874.15 566.86 3874.49 566.7C3875 566.47 3875.23 566.74 3875.36 567.14C3875.46 567.43 3875.36 567.67 3875.02 567.83C3874.5 568.08 3874.33 567.74 3874.15 567.46Z" fill="white"/>
                    <path d="M3854.04 582.14C3854.55 581.87 3854.79 582.16 3854.93 582.56C3855.03 582.85 3854.93 583.09 3854.59 583.25C3854.08 583.48 3853.85 583.21 3853.72 582.81C3853.62 582.52 3853.69 582.23 3854.04 582.14Z" fill="white"/>
                    <path d="M3567.9 619.39C3568.05 619.75 3568.21 620.13 3567.67 620.32C3567.5 620.38 3567.31 620.36 3567.13 620.37C3567.23 619.95 3567.32 619.52 3567.42 619.1C3567.58 619.2 3567.73 619.29 3567.89 619.39H3567.9Z" fill="white"/>
                    <path d="M3606.17 580C3606.02 579.64 3605.86 579.26 3606.4 579.07C3606.57 579.01 3606.76 579.03 3606.94 579.02C3606.84 579.44 3606.75 579.87 3606.65 580.29C3606.49 580.19 3606.34 580.1 3606.18 580H3606.17Z" fill="white"/>
                    <path d="M3695.18 688.89C3695.32 688.5 3695.44 688.13 3695.57 687.77C3695.77 687.96 3696.05 688.11 3696.14 688.34C3696.33 688.84 3695.91 689 3695.54 689.11C3695.44 689.14 3695.28 688.96 3695.19 688.9L3695.18 688.89Z" fill="white"/>
                    <path d="M3700.68 515.63C3700.92 515.61 3701.22 515.54 3701.26 515.6C3701.5 516.08 3701.05 516.43 3700.98 516.86C3700.83 516.76 3700.61 516.7 3700.53 516.56C3700.31 516.21 3700.26 515.84 3700.69 515.64L3700.68 515.63Z" fill="white"/>
                    <path d="M3686.7 420.51C3686.73 420.36 3686.7 420.13 3686.79 420.07C3687.11 419.85 3687.51 419.65 3687.76 420.12C3687.87 420.34 3687.8 420.65 3687.81 420.92C3687.44 420.78 3687.08 420.65 3686.7 420.51Z" fill="white"/>
                    <path d="M3679.99 450.01C3679.5 450.26 3679.29 449.94 3679.16 449.55C3679.11 449.4 3679.2 449.19 3679.23 449.01C3679.61 449.21 3680 449.42 3680.38 449.62C3680.25 449.75 3680.12 449.87 3679.99 450.01Z" fill="white"/>
                    <path d="M3695.24 664.12C3695.24 664.04 3695.21 663.81 3695.29 663.75C3695.61 663.54 3696 663.32 3696.27 663.78C3696.39 663.99 3696.32 664.31 3696.34 664.58C3695.98 664.43 3695.63 664.28 3695.24 664.12Z" fill="white"/>
                    <path d="M3680.36 627.61C3680.11 627.12 3680.43 626.91 3680.82 626.78C3680.97 626.73 3681.18 626.82 3681.36 626.85C3681.16 627.23 3680.95 627.62 3680.75 628C3680.62 627.87 3680.5 627.74 3680.36 627.61Z" fill="white"/>
                    <path d="M3631.05 460.27C3631.07 460.08 3631.09 459.9 3631.11 459.72C3631.5 459.9 3631.89 460.09 3632.29 460.27C3632.17 460.4 3632.07 460.57 3631.92 460.66C3631.46 460.94 3631.23 460.65 3631.05 460.27Z" fill="white"/>
                    <path d="M3840.31 568.31C3839.82 568.56 3839.61 568.24 3839.48 567.85C3839.43 567.7 3839.52 567.49 3839.55 567.31C3839.93 567.51 3840.32 567.72 3840.7 567.92C3840.57 568.05 3840.44 568.17 3840.31 568.31Z" fill="white"/>
                    <path d="M3877.31 548.69C3877.1 548.55 3876.89 548.48 3876.81 548.33C3876.59 547.97 3876.55 547.59 3877.06 547.42C3877.22 547.37 3877.42 547.4 3877.6 547.39C3877.5 547.81 3877.41 548.23 3877.31 548.68V548.69Z" fill="white"/>
                    <path d="M3742.65 729.47C3742.69 729.06 3743.04 729.04 3743.29 728.94C3743.37 728.91 3743.64 729.1 3743.64 729.19C3743.64 729.57 3743.27 729.6 3743.01 729.69C3742.93 729.72 3742.76 729.54 3742.64 729.46L3742.65 729.47Z" fill="white"/>
                    <path d="M3720.57 714.62C3720.35 714.37 3720.13 714.19 3720.01 713.95C3719.94 713.81 3720.05 713.59 3720.08 713.41C3720.28 713.6 3720.52 713.76 3720.65 713.98C3720.72 714.1 3720.61 714.33 3720.57 714.62Z" fill="white"/>
                    <path d="M3811.86 462.19C3811.9 461.78 3812.25 461.76 3812.5 461.66C3812.58 461.63 3812.85 461.82 3812.85 461.91C3812.85 462.29 3812.48 462.32 3812.22 462.41C3812.14 462.44 3811.97 462.26 3811.85 462.18L3811.86 462.19Z" fill="white"/>
                    <path d="M3702.49 431.9C3702.74 431.68 3702.92 431.46 3703.16 431.34C3703.3 431.27 3703.52 431.38 3703.7 431.41C3703.51 431.61 3703.35 431.85 3703.13 431.98C3703.01 432.05 3702.78 431.94 3702.49 431.9Z" fill="white"/>
                    <path d="M3699.7 433.62C3699.85 433.57 3700.1 433.43 3700.35 433.39C3700.65 433.35 3700.75 433.55 3700.51 433.75C3700.31 433.92 3700.04 434.04 3699.79 434.08C3699.49 434.13 3699.42 433.96 3699.7 433.62Z" fill="white"/>
                    <path d="M3812.01 443.75C3811.83 443.75 3811.54 443.84 3811.49 443.75C3811.28 443.45 3811.49 443.26 3811.78 443.19C3811.95 443.14 3812.25 443.1 3812.3 443.18C3812.5 443.48 3812.3 443.65 3812.01 443.75Z" fill="white"/>
                    <path d="M3687.66 703.45C3687.66 703.27 3687.57 702.98 3687.66 702.93C3687.96 702.72 3688.15 702.93 3688.22 703.22C3688.27 703.39 3688.31 703.69 3688.23 703.74C3687.93 703.94 3687.76 703.74 3687.66 703.45Z" fill="white"/>
                    <path d="M3631 471.24C3630.78 470.99 3630.56 470.8 3630.44 470.57C3630.37 470.43 3630.48 470.21 3630.51 470.03C3630.71 470.22 3630.95 470.38 3631.08 470.6C3631.15 470.72 3631.04 470.95 3631 471.24Z" fill="white"/>
                    <path d="M3838.17 639.53C3838.19 639.4 3838.22 639.22 3838.25 639.03C3838.45 639.22 3838.69 639.38 3838.82 639.6C3838.89 639.72 3838.78 639.95 3838.75 640.14C3838.45 640.06 3838.24 639.89 3838.16 639.53H3838.17Z" fill="white"/>
                    <path d="M3589.63 492.82C3589.89 492.67 3590.16 492.62 3590.25 492.97C3590.27 493.06 3590.04 493.26 3589.9 493.36C3589.66 493.53 3589.38 493.56 3589.28 493.21C3589.26 493.12 3589.5 492.95 3589.63 492.82Z" fill="white"/>
                    <path d="M3822.03 601.53C3822.29 601.38 3822.56 601.33 3822.65 601.68C3822.67 601.77 3822.44 601.97 3822.3 602.07C3822.06 602.24 3821.78 602.27 3821.68 601.92C3821.66 601.83 3821.9 601.66 3822.03 601.53Z" fill="white"/>
                    <path d="M3809.9 597.03C3810.16 596.88 3810.43 596.83 3810.52 597.18C3810.54 597.27 3810.31 597.47 3810.17 597.57C3809.93 597.74 3809.65 597.77 3809.55 597.42C3809.53 597.33 3809.77 597.16 3809.9 597.03Z" fill="white"/>
                    <path d="M3763.3 586.98C3763.08 586.73 3762.86 586.55 3762.74 586.31C3762.67 586.17 3762.78 585.95 3762.81 585.77C3763.01 585.96 3763.25 586.12 3763.38 586.34C3763.45 586.46 3763.34 586.69 3763.3 586.98Z" fill="white"/>
                    <path d="M3695.17 674.6C3695.18 674.37 3695.19 674.1 3695.21 673.83C3695.37 673.93 3695.62 673.99 3695.66 674.13C3695.74 674.38 3695.71 674.67 3695.72 674.94C3695.56 674.84 3695.41 674.74 3695.18 674.6H3695.17Z" fill="white"/>
                    <path d="M3679.48 578.85C3679.5 578.72 3679.53 578.54 3679.56 578.35C3679.76 578.54 3680 578.7 3680.13 578.92C3680.2 579.04 3680.09 579.27 3680.06 579.46C3679.76 579.38 3679.55 579.21 3679.47 578.85H3679.48Z" fill="white"/>
                    <path d="M3633.82 591.39C3633.6 591.14 3633.38 590.96 3633.26 590.72C3633.19 590.58 3633.3 590.36 3633.33 590.18C3633.53 590.37 3633.77 590.53 3633.9 590.75C3633.97 590.87 3633.86 591.1 3633.82 591.39Z" fill="white"/>
                    <path d="M3562.4 611.59C3562.44 611.18 3562.79 611.16 3563.04 611.06C3563.12 611.03 3563.39 611.22 3563.39 611.31C3563.39 611.69 3563.02 611.72 3562.76 611.81C3562.68 611.84 3562.51 611.66 3562.39 611.58L3562.4 611.59Z" fill="white"/>
                    <path d="M3604.31 559.89C3604.57 559.74 3604.84 559.69 3604.93 560.04C3604.95 560.13 3604.72 560.33 3604.58 560.43C3604.34 560.6 3604.06 560.63 3603.96 560.28C3603.94 560.19 3604.18 560.02 3604.31 559.89Z" fill="white"/>
                    <path d="M3802.65 478.91C3802.47 478.91 3802.18 479 3802.13 478.91C3801.92 478.61 3802.13 478.42 3802.42 478.35C3802.59 478.3 3802.89 478.26 3802.94 478.34C3803.14 478.64 3802.94 478.81 3802.65 478.91Z" fill="white"/>
                    <path d="M3720.04 534.26C3720.04 534.08 3719.95 533.79 3720.04 533.74C3720.34 533.53 3720.53 533.74 3720.6 534.03C3720.65 534.2 3720.69 534.5 3720.61 534.55C3720.31 534.75 3720.14 534.55 3720.04 534.26Z" fill="white"/>
                    <path d="M3712.6 520.25C3712.48 520.16 3712.34 520.07 3712.26 519.96C3712.23 519.92 3712.32 519.79 3712.35 519.7C3712.47 519.79 3712.61 519.88 3712.69 519.99C3712.72 520.03 3712.63 520.16 3712.6 520.25Z" fill="white"/>
                    <path d="M3623.73 452.35C3623.61 452.26 3623.47 452.17 3623.39 452.06C3623.36 452.02 3623.45 451.89 3623.48 451.8C3623.6 451.89 3623.74 451.98 3623.82 452.09C3623.85 452.13 3623.76 452.26 3623.73 452.35Z" fill="white"/>
                    <path d="M3797.54 599.11C3797.55 598.96 3797.56 598.81 3797.57 598.66C3797.66 598.69 3797.82 598.71 3797.83 598.76C3797.86 598.9 3797.83 599.06 3797.83 599.21C3797.74 599.18 3797.65 599.14 3797.54 599.1V599.11Z" fill="white"/>
                    <path d="M3844.8 592.85C3844.76 592.96 3844.73 593.05 3844.69 593.14C3844.57 593.05 3844.43 592.96 3844.35 592.85C3844.32 592.81 3844.41 592.68 3844.44 592.59C3844.56 592.68 3844.68 592.77 3844.8 592.86V592.85Z" fill="white"/>
                    <path d="M3764.8 631.25C3764.95 631.26 3765.1 631.27 3765.25 631.28C3765.22 631.37 3765.2 631.53 3765.15 631.54C3765.01 631.57 3764.85 631.54 3764.7 631.54C3764.73 631.45 3764.77 631.36 3764.81 631.25H3764.8Z" fill="white"/>
                    <path d="M3720.82 613.43C3720.91 613.31 3721 613.17 3721.11 613.09C3721.15 613.06 3721.28 613.15 3721.37 613.18C3721.28 613.3 3721.19 613.44 3721.08 613.52C3721.04 613.55 3720.91 613.46 3720.82 613.43Z" fill="white"/>
                    <path d="M3673.61 629.49C3673.72 629.53 3673.81 629.56 3673.9 629.6C3673.81 629.72 3673.72 629.86 3673.61 629.94C3673.57 629.97 3673.44 629.88 3673.35 629.85C3673.44 629.73 3673.53 629.61 3673.62 629.49H3673.61Z" fill="white"/>
                    <path d="M3679.89 690.69C3679.9 690.54 3679.91 690.39 3679.92 690.24C3680.01 690.27 3680.17 690.29 3680.18 690.34C3680.21 690.48 3680.18 690.64 3680.18 690.79C3680.09 690.76 3680 690.72 3679.89 690.68V690.69Z" fill="white"/>
                    <path d="M3783.79 624.69C3783.83 624.58 3783.86 624.49 3783.9 624.4C3784.02 624.49 3784.16 624.58 3784.24 624.69C3784.27 624.73 3784.18 624.86 3784.15 624.95C3784.03 624.86 3783.91 624.77 3783.79 624.68V624.69Z" fill="white"/>
                    <path d="M3720.76 581.35C3720.72 581.46 3720.69 581.55 3720.65 581.64C3720.53 581.55 3720.39 581.46 3720.31 581.35C3720.28 581.31 3720.37 581.18 3720.4 581.09C3720.52 581.18 3720.64 581.27 3720.76 581.36V581.35Z" fill="white"/>
                    <path d="M3581.94 631.83C3581.85 631.8 3581.69 631.76 3581.69 631.73C3581.7 631.58 3581.71 631.4 3581.79 631.29C3581.97 631.07 3582.14 631.13 3582.13 631.42C3582.13 631.56 3582.01 631.69 3581.94 631.83Z" fill="white"/>
                    <path d="M3581.91 630.93C3581.98 630.79 3582.04 630.55 3582.12 630.54C3582.37 630.51 3582.48 630.66 3582.37 630.82C3582.28 630.96 3582.14 631.06 3582.02 631.18C3581.98 631.1 3581.94 631.02 3581.91 630.93Z" fill="white"/>
                    <path d="M3731.15 495.39C3731.16 495.24 3731.17 495.09 3731.18 494.94C3731.27 494.97 3731.43 494.99 3731.44 495.04C3731.47 495.18 3731.44 495.34 3731.44 495.49C3731.35 495.46 3731.26 495.42 3731.15 495.38V495.39Z" fill="white"/>
                    <path d="M3619.16 711.86C3619.17 711.71 3619.18 711.56 3619.19 711.41C3619.28 711.44 3619.44 711.46 3619.45 711.51C3619.48 711.65 3619.45 711.81 3619.45 711.96C3619.36 711.93 3619.27 711.89 3619.16 711.85V711.86Z" fill="white"/>
                    <path d="M3574.07 629.7C3574.11 629.59 3574.14 629.5 3574.18 629.41C3574.3 629.5 3574.44 629.59 3574.52 629.7C3574.55 629.74 3574.46 629.87 3574.43 629.96C3574.31 629.87 3574.19 629.78 3574.07 629.69V629.7Z" fill="white"/>
                    <path d="M3662.9 702.32C3662.89 702.47 3662.88 702.62 3662.87 702.77C3662.78 702.74 3662.62 702.72 3662.61 702.67C3662.58 702.53 3662.61 702.37 3662.61 702.22C3662.7 702.25 3662.79 702.29 3662.9 702.33V702.32Z" fill="white"/>
                    <path d="M3649 553.23C3649.01 553.08 3649.02 552.93 3649.03 552.78C3649.12 552.81 3649.28 552.83 3649.29 552.88C3649.32 553.02 3649.29 553.18 3649.29 553.33C3649.2 553.3 3649.11 553.26 3649 553.22V553.23Z" fill="white"/>
                    <path d="M3745.27 656.99C3745.38 657.03 3745.47 657.06 3745.56 657.1C3745.47 657.22 3745.38 657.36 3745.27 657.44C3745.23 657.47 3745.1 657.38 3745.01 657.35C3745.1 657.23 3745.19 657.11 3745.28 656.99H3745.27Z" fill="white"/>
                    <path d="M3598.76 604.04C3598.64 603.95 3598.5 603.86 3598.42 603.75C3598.39 603.71 3598.48 603.58 3598.51 603.49C3598.63 603.58 3598.77 603.67 3598.85 603.78C3598.88 603.82 3598.79 603.95 3598.76 604.04Z" fill="white"/>
                    <path d="M3734.5 664.95C3734.51 664.8 3734.52 664.65 3734.53 664.5C3734.62 664.53 3734.78 664.55 3734.79 664.6C3734.82 664.74 3734.79 664.9 3734.79 665.05L3734.5 664.94V664.95Z" fill="white"/>
                    <path d="M3718.6 546.25C3718.45 546.24 3718.3 546.23 3718.15 546.22C3718.18 546.13 3718.2 545.97 3718.25 545.96C3718.39 545.93 3718.55 545.96 3718.7 545.96C3718.67 546.05 3718.63 546.14 3718.59 546.25H3718.6Z" fill="white"/>
                    <path d="M3807.92 435.56C3807.8 435.47 3807.66 435.38 3807.58 435.27C3807.55 435.23 3807.64 435.1 3807.67 435.01C3807.79 435.1 3807.93 435.19 3808.01 435.3C3808.04 435.34 3807.95 435.47 3807.92 435.56Z" fill="white"/>
                    <path d="M3733.86 698.29C3732.04 697.98 3731.65 696.59 3731.31 695.27C3731.19 694.79 3731.11 694.01 3730.95 693.79C3729.96 692.35 3732.01 692.02 3731.85 690.97C3732.9 690.97 3734.15 690.63 3734.94 691.09C3735.68 691.53 3736.56 692.15 3737.09 692.96C3737.59 693.72 3738.64 694.56 3737.12 695.44C3736.89 695.57 3736.94 696.14 3736.82 696.5C3736.3 698.08 3735.27 698.66 3733.85 698.31L3733.86 698.29Z" fill="white"/>
                    <path d="M3855.38 611.05C3855.1 612.11 3854.06 612.64 3853.69 612.54C3852.26 612.14 3851.34 613.31 3850.09 613.43C3849.21 613.52 3848.66 613.43 3848.27 612.67C3847.63 611.42 3847.67 610.77 3848.47 610.12C3848.82 609.84 3849.11 609.53 3849.42 609.21C3850.73 607.79 3854.62 608.08 3855.25 610.2C3855.36 610.58 3855.46 610.89 3855.37 611.05H3855.38Z" fill="white"/>
                    <path d="M3766.36 604.04C3765.52 604.5 3765.37 605.9 3764.06 605.67C3763.87 605.64 3763.68 605.51 3763.51 605.55C3762.82 605.7 3762.13 606.11 3761.47 605.51C3760.95 605.04 3761.02 604.35 3760.89 603.75C3760.63 602.56 3761.58 601.12 3762.85 600.69C3763.84 600.36 3764.64 600.59 3765.42 601.32C3766.23 602.08 3766.09 603.11 3766.36 604.04Z" fill="white"/>
                    <path d="M3735.89 474.75C3736.3 473.16 3737.07 472.58 3738.25 472.74C3738.9 472.83 3739.53 473.07 3740.18 473.2C3740.79 473.32 3740.91 473.62 3740.74 474.19C3740.54 474.82 3740.5 475.51 3740.23 476.1C3739.78 477.11 3738.88 477.62 3737.82 477.82C3737.6 477.86 3737.22 477.73 3737.12 477.55C3736.75 476.91 3736.2 476.45 3735.73 475.92C3735.5 475.66 3735.87 474.87 3735.9 474.75H3735.89Z" fill="white"/>
                    <path d="M3859.29 487.22C3859.17 487.96 3859.02 489.01 3857.82 489.03C3857.25 489.03 3856.19 489.06 3856.16 488.35C3856.12 487.58 3855.44 486.75 3856.12 486C3856.59 485.47 3857.27 485.08 3857.59 484.49C3857.88 483.94 3858.1 483.87 3858.64 484C3859.41 484.18 3859.72 484.73 3859.73 485.39C3859.73 485.94 3859.49 486.49 3859.3 487.23L3859.29 487.22Z" fill="white"/>
                    <path d="M3716.45 468.16C3716.28 468.89 3715.56 469.37 3714.84 469.23C3714.6 469.18 3712.65 467.33 3712.61 467.11C3712.52 466.61 3713.79 465.42 3713.82 465.51C3714.22 466.63 3715.1 465.47 3715.58 465.94C3716.04 466.39 3716.56 467.69 3716.45 468.16Z" fill="white"/>
                    <path d="M3713.81 636.48C3714.19 636.59 3714.8 636.63 3714.71 637C3714.55 637.68 3714.67 638.49 3713.88 638.98C3713.44 639.25 3712.96 639.06 3712.6 639.27C3712.01 639.62 3711.65 639.61 3711.23 638.99C3710.78 638.32 3710.85 637.63 3711.34 637.24C3712.02 636.69 3712.78 635.95 3713.81 636.48Z" fill="white"/>
                    <path d="M3800.77 590.37C3800.95 589.8 3800.84 588.98 3801.93 589.3C3802.17 589.37 3802.55 588.94 3802.89 588.76C3803.27 588.56 3803.66 588.4 3804.05 588.22C3803.96 588.85 3804.07 589.63 3803.72 590.06C3803.28 590.61 3802.47 590.85 3801.85 591.27C3801.51 591.5 3801.15 591.64 3800.83 591.4C3800.48 591.13 3800.7 590.72 3800.78 590.37H3800.77Z" fill="white"/>
                    <path d="M3651.8 563.04C3651.84 562.86 3651.87 562.67 3651.94 562.5C3652.09 562.12 3651.94 561.48 3652.6 561.49C3653.28 561.5 3653.78 561.83 3654.08 562.5C3654.33 563.07 3654.05 563.3 3653.64 563.59C3653.38 563.78 3653.28 564.19 3653.02 564.39C3652.68 564.65 3652.3 565.04 3651.8 564.78C3651.2 564.46 3651.64 563.99 3651.68 563.58C3651.7 563.4 3651.76 563.22 3651.81 563.04H3651.8Z" fill="white"/>
                    <path d="M3724.46 461.68C3723.83 461.08 3722.99 460.65 3722.68 459.77C3722.61 459.57 3722.68 459.22 3723 459.07C3723.4 458.89 3723.79 458.38 3724.24 458.71C3724.79 459.13 3725.27 459.67 3725.72 460.2C3726.25 460.84 3725.26 460.74 3725.22 461.14C3725.17 461.55 3724.96 461.83 3724.46 461.67V461.68Z" fill="white"/>
                    <path d="M3792.37 635.8C3792.41 635.28 3792.65 635.02 3793.26 635.2C3794.19 635.48 3794.6 636.12 3794.43 636.97C3794.28 637.73 3793.71 638.02 3793.09 638.06C3792.38 638.1 3792.04 637 3792.38 635.79L3792.37 635.8Z" fill="white"/>
                    <path d="M3849.24 698.99C3849.31 698.72 3849.33 698.42 3849.45 698.17C3849.74 697.59 3850.53 697.58 3850.86 698.13C3851.05 698.45 3851.32 698.75 3851.42 699.1C3851.56 699.55 3851.45 700.11 3851.11 700.36C3850.72 700.65 3850.06 700.36 3849.63 700.77C3849.42 700.28 3848.84 699.91 3849.16 699.26C3849.2 699.18 3849.21 699.08 3849.23 698.99H3849.24Z" fill="white"/>
                    <path d="M3719.1 513.93C3718.81 514.1 3718.57 514.23 3718.34 514.39C3717.76 514.79 3717.12 513.96 3716.52 514.52C3716.21 514.82 3715.79 514.71 3715.61 514.32C3715.44 513.95 3715.63 513.64 3716 513.44C3716.49 513.17 3716.95 512.84 3717.45 512.59C3717.69 512.47 3717.95 511.94 3718.27 512.53C3718.53 513 3718.82 513.46 3719.1 513.92V513.93Z" fill="white"/>
                    <path d="M3808.83 438.79C3809.39 439.02 3809.7 440.06 3809.41 440.68C3809.29 440.93 3807.64 441.5 3807.35 441.34C3806.62 440.93 3807.17 440.35 3807.28 439.9C3807.5 439.07 3808.29 438.57 3808.83 438.79Z" fill="white"/>
                    <path d="M3833.74 513.51C3833.16 513.45 3832.85 513.25 3833.03 512.63C3833.26 511.87 3833.98 511.24 3834.53 511.38C3835.08 511.52 3835.44 512.43 3835.24 513.19C3834.94 514.33 3834.22 513.46 3833.74 513.51Z" fill="white"/>
                    <path d="M3838.63 518.4C3838.26 518.76 3837.88 519.18 3837.29 519.08C3836.65 518.97 3836.29 517.73 3836.63 517.03C3836.86 516.57 3837.2 516.7 3837.55 516.82C3838.34 517.1 3838.85 517.85 3838.64 518.4H3838.63Z" fill="white"/>
                    <path d="M3752.57 506.52C3752.66 506.16 3752.72 505.98 3752.75 505.8C3752.83 505.11 3753.5 505.07 3753.9 504.74C3754.1 505.22 3754.66 505.57 3754.41 506.22C3754.34 506.39 3754.3 506.58 3754.27 506.76C3754.17 507.38 3753.99 507.74 3753.2 507.53C3752.44 507.32 3752.34 506.98 3752.57 506.53V506.52Z" fill="white"/>
                    <path d="M3905.65 562.49C3905 562.37 3904.97 561.91 3905.19 561.64C3905.62 561.09 3906.16 560.61 3906.7 560.15C3907.2 559.72 3907.24 560.31 3907.42 560.53C3908.22 561.5 3906.9 561.67 3906.73 562.3C3906.53 563.02 3905.95 562.43 3905.65 562.49Z" fill="white"/>
                    <path d="M3786.65 503.73C3786.61 504.52 3786.03 504.83 3785.4 504.89C3784.93 504.93 3784.16 503.57 3784.43 503.29C3784.94 502.74 3785.57 502.61 3786.31 502.86C3786.83 503.04 3786.73 503.37 3786.65 503.73Z" fill="white"/>
                    <path d="M3664.76 626.01C3664.25 625.78 3664.06 625.28 3663.94 624.76C3663.87 624.45 3665.31 623.86 3665.89 623.95C3666.41 624.03 3666.37 624.39 3666.27 624.76C3666.06 625.51 3665.32 626.13 3664.76 626V626.01Z" fill="white"/>
                    <path d="M3759.54 474.53C3759.79 474.68 3760.01 474.94 3759.78 475.19C3759.25 475.78 3758.87 476.51 3758.12 476.91C3757.81 477.08 3757.5 477.02 3757.34 476.88C3757.19 476.74 3757.12 476.28 3757.24 476.14C3757.69 475.6 3758.23 475.13 3758.74 474.64C3758.96 474.43 3759.23 474.41 3759.53 474.53H3759.54Z" fill="white"/>
                    <path d="M3754.04 444.24C3753.99 444.53 3753.94 444.91 3753.44 444.89C3753.01 444.87 3752.9 444.48 3752.67 444.22C3752.2 443.68 3752.52 443.14 3752.71 442.69C3752.82 442.42 3753.42 442.06 3753.58 442.15C3753.91 442.33 3754.19 442.75 3754.29 443.13C3754.37 443.44 3754.15 443.83 3754.04 444.25V444.24Z" fill="white"/>
                    <path d="M3893.09 513.09C3893.56 513.13 3893.81 513.38 3893.63 513.97C3893.4 514.72 3892.68 515.32 3892.14 515.2C3891.22 514.99 3892.14 514 3891.48 513.61C3891.37 513.54 3892.38 512.96 3893.1 513.09H3893.09Z" fill="white"/>
                    <path d="M3721.63 550.75C3721.5 550.86 3721.36 551.05 3721.23 551.05C3720.49 551.05 3719.49 549.75 3719.72 549.08C3719.8 548.85 3719.98 548.53 3720.16 548.49C3720.35 548.45 3720.67 548.64 3720.82 548.82C3721.48 549.63 3721.73 550.29 3721.63 550.75Z" fill="white"/>
                    <path d="M3737.6 655.3C3737.87 655.37 3738.16 655.39 3738.41 655.51C3739.04 655.82 3738.6 656.3 3738.55 656.7C3738.48 657.23 3738.11 657.19 3737.73 657.09C3737.39 657 3737.02 656.98 3736.82 656.58C3736.61 656.18 3736.24 655.79 3736.58 655.33C3736.85 654.98 3737.25 655.23 3737.6 655.3Z" fill="white"/>
                    <path d="M3760.37 477.58C3760.62 477.05 3760.99 477.19 3761.32 477.32C3762.12 477.61 3762.62 478.35 3762.38 478.91C3762.18 479.37 3761.8 479.23 3761.46 479.11C3760.66 478.82 3760.18 478.09 3760.37 477.59V477.58Z" fill="white"/>
                    <path d="M3895.43 505.47C3895.75 506.04 3896.18 506.5 3895.91 507.25C3895.68 507.87 3895.36 507.81 3894.88 507.7C3894.35 507.58 3894.14 507.41 3894.22 506.79C3894.32 505.98 3894.98 505.85 3895.44 505.46L3895.43 505.47Z" fill="white"/>
                    <path d="M3895.7 519.74C3895.57 519.86 3895.44 520.08 3895.32 520.07C3894.96 520.04 3894.48 520.02 3894.27 519.8C3893.93 519.43 3893.46 519 3893.7 518.38C3893.88 517.9 3894.27 518.04 3894.61 518.16C3895.42 518.46 3895.89 519.15 3895.7 519.74Z" fill="white"/>
                    <path d="M3699.17 657.27C3699.06 657.64 3698.95 658.04 3698.46 657.83C3697.83 657.56 3697.43 657.01 3697.68 656.35C3697.81 656 3698.36 655.82 3698.71 655.56C3699 656.1 3699.54 656.57 3699.17 657.27Z" fill="white"/>
                    <path d="M3848.83 652.01C3848.18 651.8 3847.69 650.88 3847.88 650.44C3848.08 649.98 3848.44 650.16 3848.79 650.23C3849.49 650.36 3849.86 650.85 3849.8 651.47C3849.78 651.65 3849.18 652.1 3848.82 652.01H3848.83Z" fill="white"/>
                    <path d="M3806.89 500.09C3806.78 500.55 3806.63 501.02 3806.19 500.76C3805.65 500.44 3805.23 499.92 3804.77 499.48C3805.3 499.11 3805.8 498.81 3806.53 499.09C3807.22 499.35 3806.92 499.77 3806.89 500.08V500.09Z" fill="white"/>
                    <path d="M3902.58 604.79C3902.48 605.56 3902.1 605.52 3901.57 605.43C3900.74 605.29 3900.81 604.86 3900.96 604.24C3901.11 603.61 3901.44 603.63 3901.97 603.73C3902.76 603.88 3902.85 604.27 3902.58 604.79Z" fill="white"/>
                    <path d="M3798.59 491.3C3798.13 491.09 3797.26 491.26 3797.63 490.37C3797.83 489.88 3798.35 489.55 3798.93 489.63C3799.9 489.76 3799.38 490.71 3799.53 491.18C3799.71 491.77 3798.92 491.18 3798.59 491.3Z" fill="white"/>
                    <path d="M3742.57 429.19C3742.5 429.46 3742.47 429.74 3742.36 429.99C3742.15 430.44 3741.78 430.29 3741.43 430.18C3741.18 430.1 3740.84 430.16 3740.75 429.82C3740.65 429.43 3740.78 429.13 3741.16 428.95C3741.32 428.87 3741.47 428.76 3741.63 428.67C3741.94 428.5 3742.37 428.12 3742.54 428.21C3742.91 428.41 3742.61 428.85 3742.57 429.2V429.19Z" fill="white"/>
                    <path d="M3806.95 686.06C3807.53 686.41 3807.14 686.87 3807.06 687.27C3806.9 688.01 3806.37 687.64 3805.97 687.57C3805.6 687.5 3805.23 687.37 3805.41 686.86C3805.6 686.34 3806.49 685.88 3806.94 686.06H3806.95Z" fill="white"/>
                    <path d="M3848.72 542.45C3848.25 542.31 3847.87 542.21 3848.06 541.7C3848.25 541.18 3849.15 540.7 3849.59 540.91C3850.04 541.12 3849.83 541.49 3849.79 541.84C3849.7 542.7 3849.2 542.61 3848.73 542.45H3848.72Z" fill="white"/>
                    <path d="M3776.11 460.46C3776.28 460.56 3776.66 460.65 3776.69 460.82C3776.78 461.27 3776.27 461.33 3776.02 461.41C3775.65 461.52 3775.3 462.5 3774.8 461.54C3774.64 461.22 3774.36 460.92 3774.58 460.55C3775.01 459.8 3775.54 460.55 3776.11 460.46Z" fill="white"/>
                    <path d="M3671.75 562.86C3671.41 563.15 3670.98 563.83 3670.62 563.39C3670.31 563.01 3669.52 563.08 3669.63 562.42C3669.69 562.02 3671.17 561.81 3671.61 562.13C3671.84 562.3 3671.89 562.57 3671.76 562.86H3671.75Z" fill="white"/>
                    <path d="M3885.1 602.48C3885.1 602.48 3885.27 602.96 3885.34 603.41C3885.37 603.61 3884.16 604.06 3883.92 603.94C3883.29 603.63 3883.72 603.16 3883.77 602.76C3883.83 602.27 3884.16 602.23 3885.09 602.48H3885.1Z" fill="white"/>
                    <path d="M3872.6 633.49C3872.26 634.07 3871.81 633.66 3871.41 633.61C3870.9 633.54 3870.82 633.12 3871.04 632.83C3871.29 632.49 3871.71 632.25 3872.1 632.05C3872.24 631.98 3872.71 633.26 3872.6 633.49Z" fill="white"/>
                    <path d="M3713.21 700.14C3712.71 700.12 3712.57 699.85 3712.64 699.59C3712.82 698.89 3713.36 698.63 3714.05 698.63C3714.4 698.63 3714.53 698.91 3714.48 699.18C3714.32 699.91 3713.74 700.11 3713.22 700.15L3713.21 700.14Z" fill="white"/>
                    <path d="M3706.72 535.14C3707.28 535.37 3707.62 535.85 3707.5 536.32C3707.44 536.55 3706.89 536.97 3706.35 536.82C3705.63 536.62 3705.98 536.13 3706.05 535.73C3706.11 535.35 3706.25 535.03 3706.72 535.14Z" fill="white"/>
                    <path d="M3806.38 704.66C3806.12 704.63 3805.24 703.62 3805.38 703.53C3805.74 703.29 3806.15 703.03 3806.57 702.97C3806.96 702.92 3807.24 703.22 3807.06 703.68C3806.92 704.06 3807.08 704.66 3806.38 704.65V704.66Z" fill="white"/>
                    <path d="M3706.43 529.36C3706.15 529.81 3705.71 530.22 3704.98 530.02C3704.71 529.95 3704.53 529.7 3704.7 529.39C3705.02 528.78 3705.5 528.43 3706.21 528.6C3706.47 528.66 3706.65 528.91 3706.43 529.36Z" fill="white"/>
                    <path d="M3649.7 500.99C3649.6 501.5 3649.2 501.6 3648.91 501.38C3648.57 501.13 3648.33 500.71 3648.13 500.32C3648.06 500.18 3649.34 499.71 3649.57 499.82C3649.89 499.97 3649.94 500.43 3649.7 501V500.99Z" fill="white"/>
                    <path d="M3799.14 495.93C3799.04 496.31 3798.94 496.69 3798.43 496.52C3797.88 496.34 3797.55 495.83 3797.67 495.37C3797.73 495.14 3798.28 494.72 3798.82 494.87C3799.54 495.07 3799.16 495.56 3799.13 495.94L3799.14 495.93Z" fill="white"/>
                    <path d="M3721.08 451.66C3720.73 452.06 3720.36 452.39 3719.79 452.31C3719.45 452.27 3719.29 452.01 3719.39 451.75C3719.56 451.33 3719.31 450.52 3720.22 450.66C3720.77 450.75 3720.75 451.34 3721.08 451.66Z" fill="white"/>
                    <path d="M3788.04 502.86C3787.91 502.97 3787.78 503.08 3787.65 503.18C3787.21 502.49 3786.89 501.71 3786.09 501.32C3786.05 501.3 3786.13 500.95 3786.23 500.8C3786.29 500.72 3786.61 500.69 3786.63 500.73C3786.95 501.41 3787.91 501.52 3788.07 502.32C3788.1 502.49 3788.06 502.68 3788.05 502.85L3788.04 502.86Z" fill="white"/>
                    <path d="M3783.62 502.77C3783.09 502.6 3783.56 501.97 3783.7 501.57C3783.99 500.76 3784.6 501.38 3785.06 501.42C3785.2 501.43 3785.62 501.85 3785.13 501.88C3784.4 501.93 3784.92 503.25 3783.62 502.77Z" fill="white"/>
                    <path d="M3750.66 675.54C3751.34 675.6 3751.37 676.27 3751.7 676.66C3751.4 676.85 3751.05 677.25 3750.82 677.19C3750.32 677.05 3750.09 676.57 3750.12 676.01C3750.14 675.68 3750.37 675.58 3750.65 675.54H3750.66Z" fill="white"/>
                    <path d="M3881.56 596.91C3881.19 596.76 3880.58 596.85 3880.75 596.11C3880.87 595.58 3881.22 595.63 3881.59 595.74C3881.99 595.86 3882.58 595.79 3882.42 596.53C3882.3 597.07 3881.95 596.98 3881.57 596.9L3881.56 596.91Z" fill="white"/>
                    <path d="M3677.75 655.91C3677.06 656.54 3676.46 656.57 3675.84 656.29C3675.7 656.23 3675.6 656.05 3675.49 655.92C3675.65 655.83 3675.83 655.76 3675.96 655.63C3676.45 655.15 3676.98 655.39 3677.48 655.55C3677.63 655.6 3677.72 655.86 3677.75 655.9V655.91Z" fill="white"/>
                    <path d="M3908.12 608.61C3908.09 608.75 3907.74 608.73 3907.36 608.61C3906.97 608.48 3906.36 608.57 3906.52 607.83C3906.63 607.31 3906.98 607.33 3907.35 607.45C3907.74 607.57 3908.34 607.51 3908.12 608.62V608.61Z" fill="white"/>
                    <path d="M3863.45 568.29C3863.36 568.2 3863.16 568.08 3863.18 567.99C3863.27 567.36 3863.39 566.75 3864.12 566.47C3864.42 566.36 3864.61 566.33 3864.66 566.65C3864.75 567.14 3863.91 568.27 3863.45 568.29Z" fill="white"/>
                    <path d="M3791.84 651.98C3791.41 652.77 3790.87 652.06 3790.39 652.03C3790.17 652.02 3790.12 651.57 3790.27 651.32C3790.7 650.59 3791.25 651.18 3791.72 651.26C3791.94 651.3 3791.98 651.7 3791.84 651.98Z" fill="white"/>
                    <path d="M3803.91 542.54C3803.48 543.33 3802.94 542.62 3802.46 542.59C3802.24 542.58 3802.19 542.13 3802.34 541.88C3802.77 541.15 3803.32 541.74 3803.79 541.82C3804.01 541.86 3804.05 542.26 3803.91 542.54Z" fill="white"/>
                    <path d="M3684 584.34C3683.97 584.48 3683.62 584.46 3683.24 584.34C3682.85 584.21 3682.24 584.3 3682.4 583.56C3682.51 583.04 3682.86 583.06 3683.23 583.18C3683.62 583.3 3684.22 583.24 3684 584.35V584.34Z" fill="white"/>
                    <path d="M3832.65 473.51C3832.5 472.94 3832.35 472.35 3832.21 471.75C3832.21 471.72 3832.36 471.62 3832.43 471.62C3832.82 471.64 3833.07 471.84 3833.26 472.2C3833.47 472.6 3833.75 472.98 3833.34 473.39C3833.24 473.49 3833.02 473.45 3832.64 473.51H3832.65Z" fill="white"/>
                    <path d="M3780.76 479.23C3781.13 479.29 3781.54 479.34 3781.47 479.86C3781.39 480.52 3780.97 480.49 3780.47 480.33C3780.12 480.22 3779.7 480.21 3779.77 479.69C3779.85 479.03 3780.27 479.03 3780.76 479.23Z" fill="white"/>
                    <path d="M3726.55 498.42C3726.28 498.87 3726.1 499.07 3725.78 498.9C3725.17 498.58 3724.83 498.1 3724.99 497.39C3725.02 497.25 3725.23 497.15 3725.36 497.04C3725.77 497.52 3726.19 497.99 3726.55 498.42Z" fill="white"/>
                    <path d="M3833.08 535.85C3833.03 536.35 3832.83 536.71 3832.61 536.63C3832.16 536.48 3831.39 536.74 3831.36 535.9C3831.35 535.61 3831.56 535.27 3831.81 535.33C3832.28 535.44 3832.95 535.33 3833.09 535.86L3833.08 535.85Z" fill="white"/>
                    <path d="M3687.56 602.83C3687.45 603.2 3687.36 603.55 3686.83 603.41C3686.1 603.21 3686.44 602.72 3686.52 602.31C3686.59 601.94 3686.71 601.6 3687.24 601.74C3687.97 601.94 3687.6 602.43 3687.56 602.83Z" fill="white"/>
                    <path d="M3770.91 623.79C3771.7 624.22 3770.99 624.76 3770.96 625.24C3770.95 625.46 3770.5 625.51 3770.25 625.36C3769.52 624.93 3770.11 624.38 3770.19 623.91C3770.23 623.69 3770.63 623.65 3770.91 623.79Z" fill="white"/>
                    <path d="M3727.01 488.09C3727.38 488.15 3727.79 488.2 3727.72 488.72C3727.64 489.38 3727.22 489.35 3726.72 489.19C3726.37 489.08 3725.95 489.07 3726.02 488.55C3726.1 487.89 3726.52 487.89 3727.01 488.09Z" fill="white"/>
                    <path d="M3883.05 512.97C3883.69 513.07 3883.8 513.3 3883.76 513.59C3883.66 514.25 3883.23 514.2 3882.76 514.03C3882.52 513.94 3881.99 513.88 3882.12 513.69C3882.38 513.31 3882.87 513.1 3883.05 512.97Z" fill="white"/>
                    <path d="M3692.81 638.22C3692.71 638.34 3692.55 638.53 3692.39 638.73C3692.15 638.28 3691.62 637.92 3691.85 637.31C3691.95 637.04 3692.16 636.92 3692.49 637.01C3693.2 637.21 3692.87 637.69 3692.8 638.22H3692.81Z" fill="white"/>
                    <path d="M3712.14 433.44C3711.89 433.43 3711.75 433.18 3711.79 432.9C3711.83 432.53 3711.98 432.14 3712.48 432.34C3712.83 432.48 3713.53 432.29 3713.39 432.92C3713.34 433.15 3712.76 433.57 3712.14 433.43V433.44Z" fill="white"/>
                    <path d="M3883.33 470.7C3883.25 471.36 3882.59 471.4 3882.18 471.71C3882.15 471.46 3882.05 471.19 3882.1 470.96C3882.19 470.57 3882.13 469.98 3882.87 470.13C3883.2 470.2 3883.3 470.39 3883.33 470.7Z" fill="white"/>
                    <path d="M3707.56 465.06C3707.17 465.06 3707.56 465.16 3707.43 464.82C3707.36 464.63 3707.46 464.24 3707.62 464.14C3708.04 463.86 3708.44 463.36 3709.06 463.71C3709.13 463.75 3709.22 463.89 3709.2 463.93C3708.83 464.57 3708.08 464.66 3707.56 465.07V465.06Z" fill="white"/>
                    <path d="M3729.74 513.16C3729.27 513.39 3728.86 513.92 3728.28 513.54C3728.14 513.45 3728.1 513.01 3728.19 512.81C3728.48 512.19 3728.96 512.63 3729.37 512.67C3729.65 512.7 3729.84 512.82 3729.75 513.15L3729.74 513.16Z" fill="white"/>
                    <path d="M3789.44 581.81C3789.4 581.99 3789.39 582.2 3789.29 582.34C3789.23 582.43 3788.97 582.52 3788.9 582.47C3788.65 582.29 3789.05 580.59 3789.38 580.48C3789.46 580.45 3789.71 580.65 3789.7 580.73C3789.65 581.1 3789.54 581.45 3789.45 581.81H3789.44Z" fill="white"/>
                    <path d="M3641.05 540.37C3640.94 540.94 3640.59 540.95 3640.22 540.81C3639.95 540.71 3639.83 540.51 3639.92 540.17C3640.05 539.66 3640.38 539.66 3640.75 539.8C3641.01 539.9 3641.22 540.1 3641.06 540.38L3641.05 540.37Z" fill="white"/>
                    <path d="M3887 488.86C3886.84 489.12 3886.68 489.26 3886.34 489.17C3885.83 489.04 3885.83 488.71 3885.96 488.34C3886.06 488.07 3886.26 487.95 3886.6 488.04C3887.11 488.17 3887.08 488.49 3886.99 488.86H3887Z" fill="white"/>
                    <path d="M3807.87 681.73C3807.71 681.99 3807.55 682.13 3807.21 682.04C3806.7 681.91 3806.7 681.58 3806.83 681.21C3806.93 680.94 3807.13 680.82 3807.47 680.91C3807.98 681.04 3807.95 681.36 3807.86 681.73H3807.87Z" fill="white"/>
                    <path d="M3701.02 666.68C3700.68 666.59 3700.38 666.47 3700.5 665.96C3700.58 665.62 3700.79 665.5 3701.07 665.53C3701.46 665.58 3701.75 665.74 3701.62 666.25C3701.54 666.59 3701.35 666.68 3701.01 666.68H3701.02Z" fill="white"/>
                    <path d="M3902.83 588.23C3903.24 588.55 3903.54 588.94 3903.48 589.48C3903.46 589.63 3903.22 589.88 3903.21 589.87C3902.75 589.6 3902.46 589.21 3902.54 588.65C3902.56 588.5 3902.72 588.38 3902.83 588.22V588.23Z" fill="white"/>
                    <path d="M3843.44 670.59C3843.42 670.93 3843.17 671.04 3842.88 671C3842.49 670.95 3842.2 670.79 3842.33 670.28C3842.41 669.94 3842.62 669.82 3842.9 669.85C3843.29 669.9 3843.6 670.07 3843.44 670.59Z" fill="white"/>
                    <path d="M3846.38 557.8C3846.22 558.06 3846.06 558.2 3845.72 558.11C3845.21 557.98 3845.21 557.65 3845.34 557.28C3845.44 557.01 3845.64 556.89 3845.98 556.98C3846.49 557.11 3846.46 557.43 3846.37 557.8H3846.38Z" fill="white"/>
                    <path d="M3667.05 483.16C3666.87 483.47 3666.7 483.6 3666.36 483.52C3665.85 483.39 3665.85 483.06 3665.98 482.69C3666.08 482.42 3666.28 482.3 3666.62 482.39C3667.14 482.52 3667.07 482.86 3667.04 483.16H3667.05Z" fill="white"/>
                    <path d="M3690.23 484.18C3689.7 484.08 3689.69 483.73 3689.83 483.36C3689.93 483.09 3690.13 482.97 3690.47 483.06C3690.98 483.19 3690.98 483.52 3690.85 483.89C3690.75 484.16 3690.54 484.33 3690.23 484.19V484.18Z" fill="white"/>
                    <path d="M3920.15 623.65C3920.25 623.3 3920.35 622.93 3920.86 623.11C3921.01 623.16 3921.14 623.29 3921.28 623.39C3920.96 623.64 3920.65 623.89 3920.33 624.15C3920.27 623.99 3920.21 623.83 3920.16 623.66L3920.15 623.65Z" fill="white"/>
                    <path d="M3869.37 630.04C3869.27 630.39 3869.17 630.76 3868.66 630.58C3868.51 630.53 3868.38 630.4 3868.24 630.3C3868.56 630.05 3868.87 629.8 3869.19 629.54C3869.25 629.7 3869.31 629.86 3869.36 630.03L3869.37 630.04Z" fill="white"/>
                    <path d="M3867.98 498.98C3867.65 499.18 3867.35 499.38 3867.04 499.57C3867.01 499.32 3866.89 499.04 3866.96 498.83C3867.12 498.36 3867.52 498.48 3867.84 498.62C3867.93 498.66 3867.94 498.88 3867.97 498.98H3867.98Z" fill="white"/>
                    <path d="M3763.11 621.85C3762.93 621.72 3762.66 621.6 3762.67 621.54C3762.77 621.05 3763.31 621.06 3763.61 620.79C3763.67 620.95 3763.78 621.12 3763.76 621.27C3763.71 621.66 3763.54 621.95 3763.1 621.85H3763.11Z" fill="white"/>
                    <path d="M3717.92 699.22C3717.81 699.31 3717.7 699.5 3717.6 699.49C3717.24 699.46 3716.83 699.38 3716.92 698.89C3716.96 698.67 3717.2 698.48 3717.35 698.28C3717.54 698.59 3717.72 698.9 3717.92 699.23V699.22Z" fill="white"/>
                    <path d="M3739.97 681.66C3740.47 681.76 3740.44 682.12 3740.31 682.48C3740.26 682.62 3740.07 682.72 3739.94 682.83C3739.78 682.46 3739.62 682.09 3739.46 681.71C3739.63 681.69 3739.79 681.67 3739.97 681.66Z" fill="white"/>
                    <path d="M3853.52 516.97C3853.47 517.02 3853.36 517.22 3853.26 517.21C3852.9 517.18 3852.49 517.11 3852.57 516.62C3852.6 516.4 3852.84 516.2 3852.99 516C3853.16 516.32 3853.33 516.63 3853.52 516.97Z" fill="white"/>
                    <path d="M3843.09 552.2C3842.99 552.7 3842.63 552.67 3842.27 552.54C3842.13 552.49 3842.03 552.3 3841.92 552.17C3842.29 552.01 3842.66 551.85 3843.04 551.69C3843.06 551.86 3843.08 552.02 3843.09 552.2Z" fill="white"/>
                    <path d="M3781.56 702.68C3781.44 702.8 3781.31 702.92 3781.19 703.04C3781.01 702.68 3780.83 702.32 3780.65 701.96C3780.82 701.94 3780.99 701.87 3781.15 701.89C3781.65 701.95 3781.65 702.3 3781.55 702.68H3781.56Z" fill="white"/>
                    <path d="M3692.17 502.23C3692.67 502.33 3692.64 502.69 3692.51 503.05C3692.46 503.19 3692.27 503.29 3692.14 503.4C3691.98 503.03 3691.82 502.66 3691.66 502.28C3691.83 502.26 3691.99 502.24 3692.17 502.23Z" fill="white"/>
                    <path d="M3653.82 494.98C3653.89 495.21 3654 495.38 3653.98 495.53C3653.93 495.92 3653.74 496.22 3653.27 496.04C3653.12 495.98 3653 495.85 3652.86 495.75C3653.17 495.5 3653.49 495.25 3653.82 494.98Z" fill="white"/>
                    <path d="M3857.06 441.81C3856.79 442.09 3856.53 441.89 3856.29 441.81C3856.22 441.79 3856.13 441.49 3856.18 441.42C3856.4 441.14 3856.69 441.34 3856.93 441.42C3857.01 441.45 3857.02 441.68 3857.06 441.8V441.81Z" fill="white"/>
                    <path d="M3864.48 465.47C3864.49 465.78 3864.55 466.05 3864.5 466.28C3864.47 466.42 3864.26 466.52 3864.14 466.63C3864.1 466.38 3864.02 466.12 3864.05 465.88C3864.07 465.75 3864.28 465.64 3864.48 465.46V465.47Z" fill="white"/>
                    <path d="M3651.1 596.03C3650.83 596.31 3650.57 596.11 3650.33 596.03C3650.26 596.01 3650.17 595.71 3650.22 595.64C3650.44 595.36 3650.73 595.56 3650.97 595.64C3651.05 595.67 3651.06 595.9 3651.1 596.02V596.03Z" fill="white"/>
                    <path d="M3713.06 681.74C3712.75 681.75 3712.48 681.81 3712.25 681.76C3712.11 681.73 3712.01 681.52 3711.9 681.4C3712.15 681.37 3712.41 681.28 3712.65 681.31C3712.78 681.33 3712.89 681.54 3713.07 681.74H3713.06Z" fill="white"/>
                    <path d="M3716.09 682.11C3715.95 682.06 3715.69 682.02 3715.48 681.89C3715.24 681.74 3715.28 681.54 3715.57 681.54C3715.81 681.54 3716.08 681.6 3716.29 681.72C3716.54 681.86 3716.48 682.02 3716.09 682.11Z" fill="white"/>
                    <path d="M3640.26 609.36C3640.4 609.46 3640.65 609.57 3640.65 609.66C3640.63 610 3640.36 610.01 3640.11 609.9C3639.96 609.83 3639.71 609.7 3639.72 609.61C3639.75 609.27 3640 609.27 3640.26 609.36Z" fill="white"/>
                    <path d="M3881.93 492.75C3881.83 492.89 3881.72 493.14 3881.63 493.14C3881.29 493.12 3881.28 492.85 3881.39 492.6C3881.46 492.45 3881.59 492.2 3881.68 492.21C3882.02 492.24 3882.02 492.49 3881.93 492.75Z" fill="white"/>
                    <path d="M3787.98 694.72C3787.99 695.03 3788.05 695.3 3788 695.53C3787.97 695.67 3787.76 695.77 3787.64 695.88C3787.6 695.63 3787.52 695.37 3787.55 695.13C3787.57 695 3787.78 694.89 3787.98 694.71V694.72Z" fill="white"/>
                    <path d="M3735.19 451.65C3735.1 451.73 3734.97 451.85 3734.84 451.97C3734.8 451.72 3734.72 451.46 3734.75 451.22C3734.77 451.09 3734.98 450.98 3735.11 450.87C3735.29 451.1 3735.34 451.35 3735.18 451.66L3735.19 451.65Z" fill="white"/>
                    <path d="M3830.65 703.1C3830.38 703.06 3830.15 702.94 3830.29 702.63C3830.33 702.55 3830.61 702.54 3830.77 702.55C3831.04 702.57 3831.27 702.71 3831.13 703.02C3831.09 703.1 3830.82 703.08 3830.65 703.1Z" fill="white"/>
                    <path d="M3724.81 488.71C3724.54 488.67 3724.31 488.55 3724.45 488.24C3724.49 488.16 3724.77 488.15 3724.93 488.16C3725.2 488.18 3725.43 488.32 3725.29 488.63C3725.25 488.71 3724.98 488.69 3724.81 488.71Z" fill="white"/>
                    <path d="M3731.02 499.04C3730.75 499 3730.52 498.88 3730.66 498.57C3730.7 498.49 3730.98 498.48 3731.14 498.49C3731.41 498.51 3731.64 498.65 3731.5 498.96C3731.46 499.04 3731.19 499.02 3731.02 499.04Z" fill="white"/>
                    <path d="M3759.08 533.48C3759.09 533.79 3759.15 534.06 3759.1 534.29C3759.07 534.43 3758.86 534.53 3758.74 534.64C3758.7 534.39 3758.62 534.13 3758.65 533.89C3758.67 533.76 3758.88 533.65 3759.08 533.47V533.48Z" fill="white"/>
                    <path d="M3859.67 509.38C3859.53 509.54 3859.36 509.73 3859.2 509.92C3859.14 509.76 3859 509.56 3859.05 509.44C3859.13 509.21 3859.33 509.02 3859.48 508.82C3859.54 508.98 3859.59 509.14 3859.68 509.38H3859.67Z" fill="white"/>
                    <path d="M3815.35 588.19C3815.26 588.27 3815.13 588.39 3815 588.51C3814.97 588.26 3814.88 588 3814.91 587.76C3814.93 587.63 3815.14 587.52 3815.27 587.41C3815.45 587.64 3815.5 587.89 3815.34 588.2L3815.35 588.19Z" fill="white"/>
                    <path d="M3855.88 605.64C3855.89 605.95 3855.95 606.22 3855.9 606.45C3855.87 606.59 3855.66 606.69 3855.54 606.8C3855.51 606.55 3855.42 606.29 3855.45 606.05C3855.47 605.92 3855.68 605.81 3855.88 605.63V605.64Z" fill="white"/>
                    <path d="M3919.61 632.53C3919.34 632.81 3919.08 632.61 3918.84 632.53C3918.77 632.51 3918.68 632.21 3918.73 632.14C3918.95 631.86 3919.24 632.06 3919.48 632.14C3919.56 632.17 3919.57 632.4 3919.61 632.52V632.53Z" fill="white"/>
                    <path d="M3859.01 645.75C3858.74 645.71 3858.51 645.59 3858.65 645.28C3858.69 645.2 3858.97 645.19 3859.13 645.2C3859.4 645.22 3859.63 645.36 3859.49 645.67C3859.45 645.75 3859.18 645.73 3859.01 645.75Z" fill="white"/>
                    <path d="M3667.54 589.22C3667.68 589.32 3667.93 589.43 3667.93 589.52C3667.91 589.86 3667.64 589.87 3667.39 589.76C3667.24 589.69 3666.99 589.56 3667 589.47C3667.03 589.13 3667.28 589.13 3667.54 589.22Z" fill="white"/>
                    <path d="M3759.87 597.02C3759.77 597.16 3759.66 597.41 3759.57 597.41C3759.23 597.39 3759.22 597.12 3759.33 596.87C3759.4 596.72 3759.53 596.47 3759.62 596.48C3759.96 596.51 3759.96 596.76 3759.87 597.02Z" fill="white"/>
                    <path d="M3757.13 611.56C3757.16 611.7 3757.21 611.84 3757.21 611.97C3757.21 612.02 3757.07 612.06 3756.99 612.11C3756.96 611.97 3756.91 611.83 3756.91 611.7C3756.91 611.65 3757.05 611.61 3757.13 611.56Z" fill="white"/>
                    <path d="M3782.27 712.71C3782.3 712.85 3782.35 712.99 3782.35 713.12C3782.35 713.17 3782.21 713.21 3782.13 713.26C3782.1 713.12 3782.05 712.98 3782.05 712.85C3782.05 712.8 3782.19 712.76 3782.27 712.71Z" fill="white"/>
                    <path d="M3741.22 504.72C3741.13 504.82 3741.03 504.92 3740.94 505.03C3740.89 504.95 3740.79 504.85 3740.81 504.81C3740.87 504.69 3740.98 504.59 3741.07 504.48C3741.12 504.56 3741.16 504.63 3741.22 504.73V504.72Z" fill="white"/>
                    <path d="M3703.19 481.76C3703.28 481.7 3703.36 481.66 3703.44 481.61C3703.47 481.75 3703.52 481.89 3703.52 482.02C3703.52 482.07 3703.38 482.11 3703.3 482.16C3703.26 482.02 3703.23 481.89 3703.19 481.76Z" fill="white"/>
                    <path d="M3783.76 500.39C3783.66 500.3 3783.56 500.2 3783.45 500.11C3783.53 500.06 3783.63 499.96 3783.67 499.98C3783.79 500.04 3783.89 500.15 3784 500.24C3783.92 500.29 3783.85 500.33 3783.75 500.39H3783.76Z" fill="white"/>
                    <path d="M3805.39 538.96C3805.25 538.99 3805.11 539.04 3804.98 539.04C3804.93 539.04 3804.89 538.9 3804.84 538.82C3804.98 538.79 3805.12 538.74 3805.25 538.74C3805.3 538.74 3805.34 538.88 3805.39 538.96Z" fill="white"/>
                    <path d="M3849.1 554.76C3849.04 554.67 3849 554.59 3848.95 554.51C3849.09 554.48 3849.23 554.43 3849.36 554.43C3849.41 554.43 3849.45 554.57 3849.5 554.65C3849.36 554.69 3849.23 554.72 3849.1 554.76Z" fill="white"/>
                    <path d="M3880.15 506.57C3880.06 506.67 3879.96 506.77 3879.87 506.88C3879.82 506.8 3879.72 506.7 3879.74 506.66C3879.8 506.54 3879.91 506.44 3880 506.33C3880.05 506.41 3880.09 506.48 3880.15 506.58V506.57Z" fill="white"/>
                    <path d="M3766.12 494.11C3766.03 494.17 3765.95 494.21 3765.87 494.26C3765.84 494.12 3765.79 493.98 3765.79 493.85C3765.79 493.8 3765.93 493.76 3766.01 493.71C3766.05 493.85 3766.08 493.98 3766.12 494.11Z" fill="white"/>
                    <path d="M3786.76 562.34C3786.85 562.28 3786.93 562.24 3787.01 562.19C3787.04 562.33 3787.09 562.47 3787.09 562.6C3787.09 562.65 3786.95 562.69 3786.87 562.74C3786.83 562.6 3786.8 562.47 3786.76 562.34Z" fill="white"/>
                    <path d="M3917.18 606.42C3917.23 606.5 3917.32 606.62 3917.31 606.64C3917.22 606.74 3917.11 606.87 3916.98 606.9C3916.72 606.96 3916.64 606.81 3916.81 606.61C3916.89 606.51 3917.06 606.48 3917.19 606.42H3917.18Z" fill="white"/>
                    <path d="M3916.67 607.09C3916.54 607.15 3916.36 607.29 3916.29 607.25C3916.09 607.13 3916.1 606.95 3916.27 606.9C3916.42 606.85 3916.58 606.86 3916.73 606.85C3916.71 606.93 3916.69 607.01 3916.67 607.1V607.09Z" fill="white"/>
                    <path d="M3729.16 618.85C3729.07 618.95 3728.97 619.05 3728.88 619.16C3728.83 619.08 3728.73 618.98 3728.75 618.94C3728.81 618.82 3728.92 618.72 3729.01 618.61C3729.06 618.69 3729.1 618.76 3729.16 618.86V618.85Z" fill="white"/>
                    <path d="M3936.68 526.52C3936.59 526.62 3936.49 526.72 3936.4 526.83C3936.35 526.75 3936.25 526.65 3936.27 526.61C3936.33 526.49 3936.44 526.39 3936.53 526.28C3936.58 526.36 3936.62 526.43 3936.68 526.53V526.52Z" fill="white"/>
                    <path d="M3921.66 612.55C3921.57 612.61 3921.49 612.65 3921.41 612.7C3921.38 612.56 3921.33 612.42 3921.33 612.29C3921.33 612.24 3921.47 612.2 3921.55 612.15C3921.59 612.29 3921.62 612.42 3921.66 612.55Z" fill="white"/>
                    <path d="M3899.29 507.99C3899.38 507.89 3899.48 507.79 3899.57 507.68C3899.62 507.76 3899.72 507.86 3899.7 507.9C3899.64 508.02 3899.53 508.12 3899.44 508.23C3899.39 508.15 3899.35 508.08 3899.29 507.98V507.99Z" fill="white"/>
                    <path d="M3822.61 624.58C3822.52 624.68 3822.42 624.78 3822.33 624.89C3822.28 624.81 3822.18 624.71 3822.2 624.67C3822.26 624.55 3822.37 624.45 3822.46 624.34C3822.51 624.42 3822.55 624.49 3822.61 624.59V624.58Z" fill="white"/>
                    <path d="M3812.95 493.03C3812.89 492.94 3812.85 492.86 3812.8 492.78C3812.94 492.75 3813.08 492.7 3813.21 492.7C3813.26 492.7 3813.3 492.84 3813.35 492.92C3813.21 492.96 3813.08 492.99 3812.95 493.03Z" fill="white"/>
                    <path d="M3888.76 616.85C3888.79 616.99 3888.84 617.13 3888.84 617.26C3888.84 617.31 3888.7 617.35 3888.62 617.4C3888.59 617.26 3888.54 617.12 3888.54 616.99C3888.54 616.94 3888.68 616.9 3888.76 616.85Z" fill="white"/>
                    <path d="M3825.43 493.5C3825.34 493.6 3825.24 493.7 3825.15 493.81C3825.1 493.73 3825 493.63 3825.02 493.59C3825.08 493.47 3825.19 493.37 3825.28 493.26L3825.43 493.51V493.5Z" fill="white"/>
                    <path d="M3767.9 589.14C3768 589.23 3768.1 589.33 3768.21 589.42C3768.13 589.47 3768.03 589.57 3767.99 589.55C3767.87 589.49 3767.77 589.38 3767.66 589.29C3767.74 589.24 3767.81 589.2 3767.91 589.14H3767.9Z" fill="white"/>
                    <path d="M3638.46 617.69C3638.49 617.83 3638.54 617.97 3638.54 618.1C3638.54 618.15 3638.4 618.19 3638.32 618.24C3638.29 618.1 3638.24 617.96 3638.24 617.83C3638.24 617.78 3638.38 617.74 3638.46 617.69Z" fill="white"/>
                </g>
                <path d="M3943.65 665.7H3187.65C3162.73 665.7 3137.98 669.82 3114.49 678.15C3096.26 684.61 3075.54 693.61 3059.43 705.18V779.71C3075.54 791.28 3096.26 800.28 3114.49 806.74C3137.97 815.06 3162.73 819.19 3187.65 819.19H3943.65C4062.07 819.19 4078.69 742.45 4078.69 742.45C4078.69 742.45 4062.07 665.71 3943.65 665.71V665.7Z" fill="#29192F"/>
                <path d="M3348.83 691.34H3265.88V793.54H3348.83V691.34Z" fill="#FE2D55"/>
                <path d="M3477.9 691.34H3394.95V793.54H3477.9V691.34Z" fill="#FE2D55"/>
                <path d="M3606.97 691.34H3524.02V793.54H3606.97V691.34Z" fill="#FE2D55"/>
                <path d="M3736.03 691.34H3653.08V793.54H3736.03V691.34Z" fill="#FE2D55"/>
                <path d="M3865.1 691.34H3782.15V793.54H3865.1V691.34Z" fill="#FE2D55"/>
                <path d="M3381.06 722.23H3361.79V762.64H3381.06V722.23Z" fill="white"/>
                <path d="M3639.14 722.23H3619.87V762.64H3639.14V722.23Z" fill="white"/>
                <path d="M3897.21 722.23H3877.94V762.64H3897.21V722.23Z" fill="white"/>
                <path d="M3961.95 697.86H3911.22V787.02H3961.95V697.86Z" fill="#FE2D55"/>
                <path d="M3985.6 750.79C3990.21 750.79 3993.95 747.052 3993.95 742.44C3993.95 737.828 3990.21 734.09 3985.6 734.09C3980.99 734.09 3977.25 737.828 3977.25 742.44C3977.25 747.052 3980.99 750.79 3985.6 750.79Z" fill="white"/>
                <path d="M3248.97 691.34H3146.77V793.54H3248.97V691.34Z" fill="white"/>
                <path d="M3146.58 723.09H3107.88V761.79H3146.58V723.09Z" fill="#FFCE1F"/>
                <path d="M3500.47 734.09H3371.43V750.78H3500.47V734.09Z" fill="white"/>
                <path d="M3759.08 734.09H3630.04V750.78H3759.08V734.09Z" fill="white"/>
                <path d="M3888.14 734.09H3759.1V750.78H3888.14V734.09Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_1_9114">
                    <rect width="4078.69" height="1480.89" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Boat;
