import { Grid, Box, Link } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled/macro';
import { DefaultLayout } from '../components';
import AboutIllustration from '../components/svgs/About';
import { SocialIcon } from 'react-social-icons';
import { FeaturedTitle } from '../components/home/FeaturedBlogPostList';

const AboutWrapper = styled.div`
    background-color: #79E8DE;
    min-height: calc(100vh - 110px);
`;

const SocialLinksWrapper = styled.ul`
    display: flex;
`;

const SocialLink = styled(Link)`
    display: block;
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

const EtsyIcon = () => {
    return (
        <Box sx={{ alignItems: 'center', display: 'flex', height: 50, justifyContent: 'center', width: 50 }}>
            <Box sx={{ alignItems: 'center', backgroundColor: '#ff2c54', borderRadius: '50%', display: 'flex', height: 28, justifyContent: 'center', width: 28 }}>
                <svg width="13" height="15" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.54681 2.125V8.133C5.54681 8.133 7.66381 8.133 8.79681 8.047C9.68781 7.891 9.85181 7.805 10.0158 6.914L10.3438 5.609H11.3128L11.1488 8.461L11.2348 11.383H10.2578L10.0158 10.242C9.77381 9.43 9.44581 9.265 8.79681 9.187C7.98481 9.101 5.54681 9.101 5.54681 9.101V14.14C5.54681 15.109 6.03881 15.523 7.17181 15.523H10.5858C11.6408 15.523 12.6948 15.437 13.3518 13.898L14.2348 11.945H15.0548C14.9688 12.351 14.5628 15.929 14.4848 16.734C14.4848 16.734 11.3988 16.656 10.1018 16.656H4.24981L0.757812 16.734V15.851L1.89081 15.601C2.71081 15.437 2.95281 15.195 2.95281 14.546C2.95281 14.546 3.03881 12.351 3.03881 8.694C3.03881 5.046 2.95281 2.85 2.95281 2.85C2.95281 2.123 2.71081 1.959 1.89081 1.795L0.757812 1.555V0.664L4.17181 0.734H10.6718C11.9688 0.734 14.1558 0.5 14.1558 0.5C14.1558 0.5 14.0778 1.875 13.9918 5.125H13.1008L12.7728 3.984C12.4528 2.523 11.9678 1.796 11.0698 1.796H5.96081C5.54681 1.797 5.54681 1.875 5.54681 2.125Z" fill="#79E8DE" />
                </svg>
            </Box>
        </Box>
    );
};

const SocialLinks = () => {
    const SocialIcons = [
        {
            icon: <SocialIcon network="instagram" key="instagram" bgColor="transparent" fgColor="#ff2c54" url="https://instagram.com/jenmharley" />,
            key: 'instagram'
        },
        {
            icon: <SocialIcon network="dribbble" key="dribbble" bgColor="transparent" fgColor="#ff2c54" url="https://dribbble.com/jenmharley" />,
            url: 'http://dribbble.com/jenmharley',
            key: 'dribbble'
        },
        {
            icon: <EtsyIcon key="etsy" url="https://etsy.com/jenmharley" />,
            key: 'etsy'
        },
        {
            icon: <SocialIcon network="vimeo" key="vimeo" bgColor="transparent" fgColor="#ff2c54" url="http://vimeo.com/jenmharley" />,
            key: 'vimeo'
        }
    ];

    return (
        <SocialLinksWrapper>
            {SocialIcons.map((link, index) => (
                <li key={index}>
                    <SocialLink href={link.url}>
                        {link.icon}
                    </SocialLink>
                </li>
            ))}
        </SocialLinksWrapper>
    );
};

const StyledFeatureTitle = styled(FeaturedTitle)`
    font-size: 90px;
`;

const Description = styled.p`
    font-size: 20px;
    line-height: 1.5;
    margin-top: 80px;

    span {
        display: block;
        font-weight: 100;
        margin-top: 16px;
    }
`;

const About = () => (
    <DefaultLayout seoTitle={'About'} theme="blue" hideFooter>
        <AboutWrapper>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box p={6}>
                        <StyledFeatureTitle>
                            Jenny Harley
                        </StyledFeatureTitle>
                        <Description>
                            Jen has been drawing cartoon animals since preschool
                            but started her adventures in graphic design in 2005 in
                            order to create her own unique goalie pads. Her work
                            can now be found on gig posters throughout southeast
                            Michigan, and occasionally other interesting places such
                            as book covers, cocktail menus, and medical journals.
                            She specializes in imaginative illustration projects
                            related to science, animals, food, and the Great Lakes.
                            <span>
                                Jen currently lives in Ypsilanti, MI and prefers to spend her free
                                time hugging lighthouses, playing hockey, and looking for cool
                                birds at parks near landfills and wastewater treatment plants.
                            </span>
                        </Description>
                    </Box>
                    <Box px={5} pb={10}>
                        <SocialLinks />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ p: 6 }}>
                        <AboutIllustration style={{ aspectRatio: '1619 / 1584', maxHeight: '75vh', width: '100%' }} />
                    </Box>
                </Grid>
            </Grid>
        </AboutWrapper>
    </DefaultLayout>
);

export default About;
