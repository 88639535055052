import { Grid } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled/macro';
import { PosterItem } from '../../pages/Posters';
import { respondTo } from '../../utils/StyleUtil';
import { MaxWidthContainer } from '../MaxWidthContainer';

const FeaturedPosterGrid = styled.div`
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr;

    ${respondTo('tablet')`
        grid-template-columns: 1fr 2.1fr 1fr;
        grid-template-rows: repeat(2, 1fr);
    `}
`;

const Container = styled.div`
    background-color: #fff;
    position: relative;
    z-index: 1;
`;

const Title = styled.h2`
    color: #41294A;
    font-size: 31px;
    font-weight: 500;
    line-height: 1.5;
    margin: 80px 0 40px;
    text-align: center;

    ${respondTo('tablet')`
        font-size: 62px;
        line-height: 1.855;
        margin: 40px 0 80px;
    `}
`;

const FeaturedPosters = (props) => {
    const { posters } = props;

    return (
        <MaxWidthContainer>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Title>
                            Check Out the Poster Gallery
                        </Title>
                    </Grid>
                    <FeaturedPosterGrid>
                        {posters.map((poster) => (
                            <PosterItem poster={poster} key={poster.id} featured={true} />
                        ))}
                    </FeaturedPosterGrid>
                </Grid>
            </Container>
        </MaxWidthContainer>
    );
};

export default FeaturedPosters;
