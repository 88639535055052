import React from 'react';
import { DefaultLayout } from '../components';
import { Title } from './BlogHome';
import ContactForm from '../components/contact/ContactForm';
import { Box, Grid } from '@mui/material';
import HomeFooter from '../components/home/Footer';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import Boat from '../components/svgs/Boat';

const Contact = props => {
    return (
        <DefaultLayout footerTheme="dark">
            <Box style={{ backgroundColor: 'rgba(121, 232, 222, 0.9)', marginTop: -200, marginBottom: 200 }} sx={{ display: { xs: 'none', md: 'block' } }}>
                <ParallaxBanner style={{ aspectRatio: '16 / 9', minHeight: '80vh' }}>
                    <ParallaxBannerLayer speed={-20} translateX={[-40, 0]} translateY={[-50, -50]} style={{ inset: 'unset', height: 525, width: '100%' }}>
                        <Boat />
                    </ParallaxBannerLayer>
                    <ParallaxBannerLayer speed={-20} translateX={[15, -15]} translateY={[0, 0]} style={{ inset: '400px 0 0 -50vw' }}>
                        <Title style={{ height: 525, padding: 0 }}>Contact</Title>
                    </ParallaxBannerLayer>
                </ParallaxBanner>
            </Box>
            <Box style={{ backgroundColor: 'rgba(121, 232, 222, 0.9)', marginTop: -110, marginBottom: 200 }} sx={{ display: { md: 'none' } }}>
                <Title style={{ paddingBottom: 60, paddingTop: 110 }}>Contact</Title>
                <Boat style={{ height: 'auto', marginBottom: 80 }} />
            </Box>
            <Grid container spacing={8} padding={4}>
                <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Box px={10} sx={{ width: '100%' }}>
                        <Box sx={{ backgroundImage: "url('https://images.prismic.io/jenhar/a91900f0-4774-47cf-879e-cd28037d1ea6_Asset+2%404x.png')", backgroundSize: 'cover', aspectRatio: '3712 / 2756', width: '100%' }} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box pt={5}>
                        <ContactForm />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Box sx={{ backgroundImage: "url('https://images.prismic.io/jenhar/a91900f0-4774-47cf-879e-cd28037d1ea6_Asset+2%404x.png')", backgroundSize: 'cover', aspectRatio: '3712 / 2756', width: '100%' }} />
                </Grid>
            </Grid>
            <Box mt={20} sx={{ marginBottom: '-8rem' }}>
                <HomeFooter style={{ marginBottom: -10 }} />
            </Box>
        </DefaultLayout>
    );
};

export default Contact;
