import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styled from '@emotion/styled/macro';
import { mapboxToken } from '../../pages/Lighthouses';
import Map, { Marker } from 'react-map-gl';
import { ICON, SIZE } from './Pins';

const Close = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px 0 0;
    position: absolute;
    right: 0;
    z-index: 1;
`;

const Lighthouse = styled.img`
    aspect-ratio: 1 / 1;
    color: transparent;
    display: block;
    max-width: 100%;
    width: 100vw;
`;

const ModalInner = styled.div`
    align-self: center;
    display: grid;
    opacity: 0;
    position: relative;
    transition: opacity 0.2s ease-in 0.15s;
    width: 100%;
`;

const ModalMap = styled.div`
    height: 150px;
    position: relative;
    width: 100%;
`;

const ModalName = styled.h3`
    color: #41294a;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 1.25em;
    padding: 1rem 2rem 2rem;
    text-align: center;
`;

const LighthousePopup = (props) => {
    const { lighthouse, onClick } = props;
    const [viewport, setViewport] = React.useState({
        longitude: lighthouse.geometry.coordinates[0],
        latitude: lighthouse.geometry.coordinates[1],
        zoom: 8
    });
    const [opacity, setOpacity] = React.useState(0);
    const url = `${lighthouse.properties.image.url} 2x`;
    const targetRef = React.useRef(null);

    React.useEffect(() => {
        setOpacity(1);
    }, []);

    return (
        <>
            <ModalInner style={{ opacity: opacity }}>
                <Close>
                    <IconButton aria-label="Close" onClick={onClick} sx={{ color: '#fff' }}>
                        <CloseIcon />
                    </IconButton>
                </Close>
                <ModalMap ref={targetRef}>
                    <Map
                        initialViewState={viewport}
                        mapLib={import('mapbox-gl')}
                        mapStyle="mapbox://styles/mapbox/dark-v11"
                        mapboxAccessToken={mapboxToken}
                        onResize={viewport => setViewport(viewport)}
                        onRender={(event) => event.target.resize()}
                        scrollZoom={false}
                    >
                        <Marker longitude={lighthouse.geometry.coordinates[0]} latitude={lighthouse.geometry.coordinates[1]}>
                            <svg height={SIZE}
                                viewBox="0 0 24 24"
                                style={{
                                    cursor: 'pointer',
                                    fill: 'green',
                                    stroke: 'none',
                                    transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
                                }}
                            >
                                <path d={ICON} stroke="#000" strokeOpacity="0.7" strokeWidth="2" />
                            </svg>
                        </Marker>
                    </Map>
                </ModalMap>
                <Lighthouse src={url} alt={lighthouse.properties.name} />
                <ModalName>{lighthouse.properties.name}</ModalName>
            </ModalInner>
        </>
    );
};
export default LighthousePopup;
