import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { isEmpty } from 'lodash';
import { DefaultLayout } from '../components';
import { Box, Grid, Link } from '@mui/material';
import { client } from '../utils/prismicHelpers';
import { toFeatureJson } from '../utils/LighthouseUtil';
import NotFound from './NotFound';
import FeaturedStore from '../components/home/FeaturedStore';

const Name = styled.h3`
    align-self: center;
    background-color: rgba(65,41,74,.8);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding-top: calc(33% - 13px);
    padding-left: 16px;
    padding-right: 16px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transition: all .5s cubic-bezier(.15,.7,.54,.99);

    h3 {
        color: #fff;
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 6px;
    }
`;

const Description = styled.p`
    color: #ddd;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 1.25em;
`;

const ThumbnailImage = styled.img`
    aspect-ratio: 1 / 1;
    transform: scale(1.1);
    transition: all .5s cubic-bezier(.15,.7,.54,.99);
`;

const Thumbnail = styled.div`
    overflow: hidden;
    position: relative;

    &:hover {
        ${Name} {
            opacity: 1;
        }

        ${ThumbnailImage} {
            transform: scale(1.003);
        }
    }
`;

const Shop = () => {
    const [prismicData, setPrismicData] = useState({ lighthouses: null });
    const [notFound, toggleNotFound] = useState(false);

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const homeDoc = await client.getSingle('page');
                const lighthouses = await client.getAllByType('lighthouse', {
                    orderings: {
                        field: 'my.lighthouse.name',
                        direction: 'asc'
                    }
                });

                if (lighthouses) {
                    const features = toFeatureJson(lighthouses);
                    setPrismicData({ homeDoc, lighthouses: features });
                } else {
                    console.warn('Blog Home document was not found. Make sure it exists in your Prismic repository');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        };

        fetchPrismicData();
    }, []);

    if (prismicData.lighthouses) {
        const homeDoc = prismicData.homeDoc;
        const snackLogo = homeDoc.data.body[4].items[0].image.url;

        return (
            <>
                <DefaultLayout seoTitle={'Shop'}>
                    <Box>
                        <Box sx={{ marginBottom: 5 }}>
                            <FeaturedStore image={snackLogo} />
                        </Box>
                    </Box>
                    <Grid container spacing={2} padding={5}>
                        {prismicData.lighthouses.map(lighthouse => {
                            return (!isEmpty(lighthouse.properties.etsy_link.url) && (
                                <Grid item xs={12} md={6} lg={4} xl={3}>
                                    <Link
                                        href={lighthouse.properties.etsy_link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline="none"
                                        sx={{ display: 'block' }}
                                    >
                                        <Thumbnail>
                                            <img src={lighthouse.properties.etsy_image?.url} alt={name} />
                                            <Name>
                                                <h3>
                                                    {lighthouse.properties.name}
                                                </h3>
                                                <Description>
                                                    {'9" x 12" Print'}
                                                </Description>
                                            </Name>
                                        </Thumbnail>
                                    </Link>
                                </Grid>
                            ));
                        })}
                    </Grid>
                </DefaultLayout>
            </>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
};

export default Shop;
