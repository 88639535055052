import * as prismicR from '@prismicio/richtext';

export const toFeatureJson = (lighthouses) => {
    const features = [];
    lighthouses.map(lighthouse => {
        const latitude = lighthouse.data.location.latitude;
        const longitude = lighthouse.data.location.longitude;
        const name = prismicR.asText(lighthouse.data.name);
        const description = prismicR.asText(lighthouse.data.description);
        const colors = ['#f0b8b8', '#aecdc2', '#665191', '#a05195', '#88c9d4', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'];
        const fill = colors[Math.floor(Math.random() * colors.length)];

        features.push(
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [longitude, latitude]
                },
                properties: {
                    name: name,
                    image: lighthouse.data.image,
                    description: description,
                    etsy_link: lighthouse.data.etsy_link,
                    etsy_image: lighthouse.data.etsy_image,
                    fill: fill
                }
            }
        );

        return features;
    });
    return features;
};
