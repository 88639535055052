import * as prismic from '@prismicio/client';

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = 'https://jenhar.cdn.prismic.io/api/v2';
export const endpoint = prismic.getRepositoryEndpoint('jenhar');

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = '';

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
    if (doc.type === 'poster') return `/posters/${doc.uid}`;
    if (doc.type === 'blog_post') return `/blog/${doc.uid}`;
    return '/';
};
