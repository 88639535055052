import Button from '@mui/material/Button';
import React from 'react';
import { TextField, Grid } from '@mui/material';
import styled from '@emotion/styled/macro';
import { Honeypot, NetlifyForm, Recaptcha } from 'react-netlify-forms';

const Form = styled(NetlifyForm)`
    display: grid;
    margin: 0 auto;
    max-width: 480px;
    row-gap: 2rem;
`;

const StyledTextField = styled(TextField)`
    .MuiFormLabel-root.MuiInputLabel-root {
        font-family: Montserrat, 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
`;

const StyledButton = styled(Button)`
    &.MuiButton-root {
        font-family: Montserrat, 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
`;

const ContactForm = (props) => {
    return (
        <Form
            name='contact'
            action='/thanks'
            honeypotName='bot-field'
            enableRecaptcha
            onSuccess={(response, context) => {
                context.formRef.current.reset();
            }}
        >
            {({ handleChange, success, error }) => (
                <Grid container spacing={4}>
                    <Honeypot />
                    <Recaptcha siteKey={'6LegXXcdAAAAACtakT-rVDrxVVTHKRhs9mE4jHKE'} invisible />
                    {success && (
                        <Grid item xs={12}>
                            <p sx={{ variant: 'alerts.success', p: 3 }}>
                                Thanks for contacting us!
                            </p>
                        </Grid>
                    )}
                    {error && (
                        <Grid item xs={12}>
                            <p sx={{ variant: 'alerts.muted', p: 3 }}>
                                Sorry, we could not reach servers. Because it only works on Netlify,
                                our GitHub demo does not provide a response.
                            </p>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <StyledTextField
                            InputLabelProps={{ required: false, fontFamily: 'Montserrat, \'Montserrat\', -apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif;' }}
                            id="name"
                            label="Name"
                            name="name"
                            onChange={handleChange}
                            required
                            sx={{ width: '100%' }}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTextField
                            InputLabelProps={{ required: false }}
                            id="email"
                            label="Email"
                            name="email"
                            onChange={handleChange}
                            required
                            sx={{ width: '100%' }}
                            type="email"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTextField
                            InputLabelProps={{ required: false }}
                            id="message"
                            label="Message"
                            multiline
                            name="message"
                            onChange={handleChange}
                            required
                            rows={4}
                            sx={{ width: '100%' }}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <StyledButton
                            style={{ backgroundColor: '#29192F' }}
                            disableElevation
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            Send
                        </StyledButton>
                    </Grid>
                </Grid>
            )}
        </Form>
    );
};

export default ContactForm;
