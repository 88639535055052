import React from 'react';
import TextTruncate from 'react-text-truncate';
import { Typography } from '../Typography';
import { respondTo } from '../../utils/StyleUtil';
import Link from '@mui/material/Link';
import styled from '@emotion/styled/macro';
import ShopCard from './ShopCard';
import { isEmpty } from 'lodash';

const LighthouseImage = styled.img`
    background: #eee;
    height: 260px;
    width: 260px;

    ${respondTo('mobile')`
        height: 425px;
        width: 425px;
    `}
`;

const Name = styled(Typography)`
    font-size: 25px;
    
    ${respondTo('tablet')`
        font-size: 35px;
    `}
`;

const StyledInfo = styled.div`
    display: grid;
    padding: 10px;
    padding-bottom: 100px;
    row-gap: 1.5rem;
    width: calc(100% - 20px);

    ${respondTo('mobile')`
        // width: calc(100% - 80px);
    `}
`;

const Info = (props) => {
    const { info } = props;
    const { description, etsy_image, etsy_link, image, name } = info.properties;

    const etsyImageUrl = etsy_image?.url;
    const etsyLinkUrl = etsy_link?.url;
    const hasEtsy = !isEmpty(etsyImageUrl) && !isEmpty(etsyLinkUrl);

    return (
        <StyledInfo>
            <Name variant="h1" component="h1">{name}</Name>
            <LighthouseImage src={image.url} alt="" />
            <div>
                <Typography variant="bodyMedium">
                    <TextTruncate
                        line={4}
                        element="span"
                        truncateText="…"
                        text={description}
                    />
                </Typography>
                <Link
                    href={`http://en.wikipedia.org/w/index.php?title=Special:Search&search=${name}`}
                    target="_new"
                    underline="hover"
                    sx={{
                        display: 'block',
                        marginTop: '0.5rem'
                    }}
                >
                    <Typography variant="bodyMedium" component="span" color="#1976d2">
                        Read more on Wikipedia
                    </Typography>
                </Link>
            </div>
            {hasEtsy && (
                <ShopCard image_url={etsyImageUrl} name={name} link_url={etsyLinkUrl} />
            )}
        </StyledInfo>
    );
};

export default Info;
