import React from 'react';
import styled from '@emotion/styled/macro';
import { respondTo } from '../utils/StyleUtil';

const Container = styled.div`
    display: grid;
    padding: 0 2rem;
    width: 100%;

    ${props => props.max && `
        margin-left: auto;
        margin-right: auto;
        max-width: ${props.max}px;
    `}

    ${respondTo('tablet')`
        margin-left: auto;
        margin-right: auto;
        max-width: 1300px;
    `}
`;

export const MaxWidthContainer = (props) => {
    const { max, style } = props;

    return (
        <Container max={max} style={{ ...style }}>
            {props.children}
        </Container>
    );
};
