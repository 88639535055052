import React from 'react';
import * as prismicR from '@prismicio/richtext';
import styled from '@emotion/styled/macro';
import { Button } from '../../components/button';
import { Box, Link } from '@mui/material';
import { FeaturedTitle } from './FeaturedBlogPostList';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { MaxWidthContainer } from '../MaxWidthContainer';
import { respondTo } from '../../utils/StyleUtil';

const Desktop = styled.div`
    display: none;

    ${respondTo('tablet')`
        position: relative;
        display: block;
        transform: translateY(-100px);
    `}
`;

const Mobile = styled.div`
    position: relative;

    ${respondTo('tablet')`
        display: none;
    `}
`;

const ProjectDescription = styled.p`
    color: #fe2d55;
    font-size: 15px;
    font-weight: 300;
    margin: 240px 0 40px;
    position: relative;

    ${respondTo('tablet')`
        font-size: 25px;
    `}

    &::before {
        background-color: #111;
        content: '';
        height: 140px;
        left: 6vw;
        position: absolute;
        top: -180px;
        width: 1px;
    }
`;

const Image = styled.div`
    padding-left: 30px;
    width: 40vw;

    ${respondTo('tablet', 'max')`
        position: absolute;
        top: 132px;

        img {
            width: 134px;
        }
    `}
`;

const Container = styled.div`
    display: grid;
    grid-template-areas: 'image'
                         'heading'
                         'text';
    padding-top: 160px;

    ${respondTo('tablet')`
        grid-template-areas: 'heading heading'
                             'text image';
        grid-template-columns: 1fr 1fr;
    `}
`;

const FeaturedProject = (props) => {
    const { data } = props;
    const { description, feature, title1 } = data;
    const title = title1;
    const image = feature;

    const projectLink = '/lighthouses';

    return (
        <MaxWidthContainer>
            <Container>
                <Box style={{ gridArea: 'heading', maxWidth: 1050, position: 'relative', zIndex: 2 }}>
                    <FeaturedTitle>
                        {prismicR.asText(title)}
                    </FeaturedTitle>
                </Box>
                <Box style={{ gridArea: 'text' }}>
                    <ProjectDescription>
                        {prismicR.asText(description)}
                    </ProjectDescription>
                    <Button to={projectLink} text={'View more'} />
                </Box>
                <Box style={{ gridArea: 'image' }}>
                    <Desktop>
                        <Link href={projectLink} style={{ display: 'block' }}>
                            <ParallaxBanner style={{ aspectRatio: '1 / 1.5' }}>
                                <ParallaxBannerLayer speed={-20} style={{ inset: '200px 0' }}>
                                    <Image>
                                        <img src={image?.url} alt={image?.alt} />
                                    </Image>
                                </ParallaxBannerLayer>
                                <ParallaxBannerLayer speed={-10} translateY={[0, 25]} style={{ inset: '50px 0' }}>
                                    <Sparkles />
                                </ParallaxBannerLayer>
                            </ParallaxBanner>
                        </Link>
                    </Desktop>
                    <Mobile>
                        <Link href={projectLink} style={{ display: 'block' }}>
                            <Image>
                                <img src={image?.url} alt={image?.alt} />
                            </Image>
                        </Link>
                    </Mobile>
                </Box>
            </Container>
        </MaxWidthContainer>
    );
};

const Sparkles = () => (
    <svg
        id="Layer_2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 78.85 398.31"
        style={{ width: 100 }}
    >
        <g id="Middle">
            <path
                stroke="#14d0e1"
                fill="none"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M32.73 130.61v17.56M41.51 139.46H23.95M38.94 133.25l-12.42 12.42M38.89 145.71l-12.42-12.42"
            />
            <path
                stroke="#40ebdf"
                fill="none"
                d="M55.97 202.94v17.56M64.74 211.78H47.18M62.18 205.58 49.76 218M62.12 218.04l-12.41-12.42"
            />
            <path
                stroke="#14d0e1"
                fill="none"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M66.92 292.93v23.88M78.85 304.96H54.97M75.36 296.52l-16.88 16.89M75.29 313.47l-16.88-16.89"
            />
            <path
                stroke="#40ebdf"
                fill="none"
                d="M8.79 281.47v17.56M17.56 290.32H0M14.99 284.12 2.58 296.53M14.94 296.57 2.52 284.16M70.36 133.97v12.21M76.46 140.12H64.25"
            />
            <path stroke="#14d0e1"
                fill="none"
                strokeMiterlimit={10}
                strokeWidth={2} d="M40.87 354.31v12.21M46.97 360.46H34.76" />
            <path
                stroke="#40ebdf"
                fill="none"
                d="M43.77 36.93v12.21M49.87 43.08H37.66M30.78 229.64l-8.63 8.64M30.75 238.31l-8.64-8.64M15.89 98.87l-8.64 8.64M15.85 107.54 7.22 98.9"
            />
            <circle stroke="#14d0e1"
                fill="none"
                strokeMiterlimit={10}
                strokeWidth={2} cx={55.67} cy={110.37} r={2.67} />
            <circle stroke="#14d0e1"
                fill="none"
                strokeMiterlimit={10}
                strokeWidth={2} cx={8.89} cy={212.11} r={2.67} />
            <circle stroke="#14d0e1"
                fill="none"
                strokeMiterlimit={10}
                strokeWidth={2} cx={64.25} cy={173.98} r={2.67} />
            <circle fill="none" stroke="#40ebdf" cx={29.16} cy={311.76} r={2.67} />
            <circle fill="none" stroke="#40ebdf" cx={72.73} cy={344.58} r={2.67} />
            <path
                stroke="#14d0e1"
                fill="none"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M52.67 267.61c0-6.61-2.3-8.91-8.91-8.91 6.61 0 8.91-2.3 8.91-8.91 0 6.61 2.3 8.91 8.91 8.91-6.61 0-8.91 2.3-8.91 8.91Z"
            />
            <path
                stroke="#40ebdf"
                fill="none"
                d="M54.97 398.31c0-6.61-2.3-8.91-8.91-8.91 6.61 0 8.91-2.3 8.91-8.91 0 6.61 2.3 8.91 8.91 8.91-6.61 0-8.91 2.3-8.91 8.91Z"
            />
            <path
                stroke="#14d0e1"
                fill="none"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M66.92 17.81c0-6.61-2.3-8.91-8.91-8.91 6.61 0 8.91-2.3 8.91-8.91 0 6.61 2.3 8.91 8.91 8.91-6.61 0-8.91 2.3-8.91 8.91Z"
            />
            <path
                stroke="#40ebdf"
                fill="none"
                d="M20.13 180.27c4.67-4.67 4.67-7.92 0-12.6 4.67 4.67 7.92 4.67 12.6 0-4.67 4.67-4.67 7.92 0 12.6-4.67-4.67-7.92-4.67-12.6 0ZM61.59 63.95v23.88M73.52 75.98H49.64M70.03 67.55 53.14 84.43M69.96 84.49 53.07 67.6"
            />
            <circle stroke="#14d0e1"
                fill="none"
                strokeMiterlimit={10}
                strokeWidth={2} cx={14.2} cy={67.7} r={2.67} />
        </g>
    </svg>
);

export default FeaturedProject;
