import 'mapbox-gl/dist/mapbox-gl.css';
import LighthouseGrid from '../components/lighthouses/LighthouseGrid';
import LighthouseMap from '../components/lighthouses/LighthouseMap';
import LighthouseViewToggle from '../components/lighthouses/LighthouseViewToggle';
import NotFound from './NotFound';
import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { DefaultLayout } from '../components';
import { client } from '../utils/prismicHelpers';
import { toFeatureJson } from '../utils/LighthouseUtil';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
export const mapboxToken = 'pk.eyJ1Ijoic21oYXJsZXkiLCJhIjoiZGJmNDNlODM0MDEyZjRhZDUzNDY0MWI5ZDM3ZGYwMGMifQ.83BsDK5EYkcPnU8SogikqA';

const Lighthouses = () => {
    const [prismicData, setPrismicData] = useState({ lighthouses: null });
    const [notFound, toggleNotFound] = useState(false);
    const [showGrid, setShowGrid] = useState(false);

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const lighthouses = await client.getAllByType('lighthouse', {
                    orderings: {
                        field: 'my.lighthouse.name',
                        direction: 'asc'
                    }
                });

                if (lighthouses) {
                    const features = toFeatureJson(lighthouses);
                    setPrismicData({ lighthouses: features });
                } else {
                    console.warn('Blog Home document was not found. Make sure it exists in your Prismic repository');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        };

        fetchPrismicData();
    }, []);

    if (prismicData.lighthouses) {
        return (
            <>
                <DefaultLayout seoTitle="Lighthouse Project | Jen Harley" hideFooter={!showGrid}>
                    <LighthouseViewToggle onClick={setShowGrid} showGrid={showGrid} />
                    {!showGrid &&
                        <LighthouseMap lighthouses={prismicData.lighthouses} />
                    }
                    {showGrid &&
                        <LighthouseGrid lighthouses={prismicData.lighthouses} />
                    }
                </DefaultLayout>
            </>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
};

export default Lighthouses;
