import { Button, Grid } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled/macro';
import TextTruncate from 'react-text-truncate';

const StyledCard = styled.a`
    color: #222;
    display: block;
    text-decoration: none;
`;

const CardContent = styled.div`
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    column-gap: 8px;
    display: grid;
    grid-template-columns: 100px 1fr;
    padding: 5px;

    * {
        text-decoration: none !important;
    }
`;

const CardImageContainer = styled.div`
    background-image: url(${props => props.background_image_url});
    background-position: center;
    background-size: cover;
    border-radius: 3px;
    height: 100px;
    overflow: hidden;
    width: 100px;
`;

const Description = styled.p`
    color: #666;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 1.25em;
`;

const Name = styled.h3`
    color: #41294a;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 1.25em;
`;

const BuyButton = styled(Button)({
    '&&': {
        textTransform: 'none'
    }
});

const ShopCard = (props) => {
    const { image_url, link_url, name } = props;

    return (
        <StyledCard href={link_url} target="_blank" rel="noreferrer">
            <CardContent>
                <CardImageContainer background_image_url={image_url} />
                <Grid container rowGap={2}>
                    <Grid item xs={12}>
                        <Name>
                            <TextTruncate
                                line={1}
                                truncateText="…"
                                text={name}
                            />
                        </Name>
                        <Description>
                            {'9" x 12" Print'}
                        </Description>
                    </Grid>
                    <Grid item xs={12}>
                        <BuyButton size="small" variant="outlined" disableElevation>Buy on Etsy</BuyButton>
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>
    );
};

export default ShopCard;
