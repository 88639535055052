import React, { } from 'react';
import styled from '@emotion/styled/macro';
import { respondTo } from '../../utils/StyleUtil';
import BlogPostItem from './BlogPostItem';

const FeaturedBlog = styled.div`
    align-items: flex-end;
    display: grid;
    gap: 3rem;
    margin-top: 8rem;

    ${respondTo('tablet')`
        grid-template-columns: repeat(3, 1fr);
    `}
`;

export const FeaturedTitle = styled.h2`
    align-self: center;
    color: #ff2c54;
    font-family: Montserrat,sans-serif;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -.04em;
    line-height: .833em;
    margin-bottom: 20px;

    ${respondTo('tablet')`
        font-size: 120px;
    `}
`;

const FeaturedBlogPostList = (props) => {
    const { posts } = props;

    return (
        <FeaturedBlog>
            <FeaturedTitle>
                Blog
            </FeaturedTitle>
            {posts.slice(0, 2).map((post) => (
                <BlogPostItem post={post} key={post.id} />
            ))}
        </FeaturedBlog>
    );
};

export default FeaturedBlogPostList;
