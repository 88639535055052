import React from 'react';
import styled from '@emotion/styled/macro';
import { respondTo } from '../../utils/StyleUtil';
import { MaxWidthContainer } from '../MaxWidthContainer';

const FooterContainer = styled.footer`
    display: none;

    ${respondTo('tablet')`
        display: block;
    `}
`;

const Footer = (props) => {
    const { theme } = props;

    return (
        <MaxWidthContainer>
            <FooterContainer style={{ margin: '3rem 0' }}>
                <p style={{ color: theme === 'dark' ? '#fff' : '#111' }}>&copy; 2024 Jen Harley</p>
            </FooterContainer>
        </MaxWidthContainer>
    );
};

export default Footer;
