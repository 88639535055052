import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Info from './Info';
import Pins from './Pins';
import React, { useState } from 'react';
import ReactMapGl from 'react-map-gl';
import styled from '@emotion/styled/macro';
import { mapboxToken } from '../../pages/Lighthouses';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { respondTo } from '../../utils/StyleUtil';

const Container = styled.div`
    height: calc(100vh - 88px);
    overflow: hidden;
    position: absolute;
    width: 100%;

    ${respondTo('tablet')`
        height: calc(100vh - 110px);
    `}
`;

const LighthouseMap = (props) => {
    const { lighthouses } = props;

    const [popupInfo, setPopupInfo] = useState(null);
    const initialViewState = {
        latitude: 44.912879,
        longitude: -84.7586996,
        width: 'fit',
        zoom: 6
    };
    const [state, setState] = React.useState({
        right: false
    });

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 480,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536
            }
        }
    });
    const isTablet = useMediaQuery(theme.breakpoints.up('xs'));
    const padding = isTablet ? 4 : 1;

    const isLaptop = useMediaQuery(theme.breakpoints.up('md'));
    const drawerContent = isLaptop ? 445 : 300;
    const drawerHeight = isLaptop ? 'calc(100vh - 110px)' : 'calc(100vh - 88px)';
    const drawerTop = isLaptop ? 110 : 88;
    const drawerWidth = isTablet ? 525 : 300;

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && ((event).key === 'Tab' || (event).key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <Container>
            <ReactMapGl mapLib={import('mapbox-gl')} scrollZoom={false} doubleClickZoom={true} mapboxAccessToken={mapboxToken} mapStyle="mapbox://styles/mapbox/dark-v11" initialViewState={initialViewState}>
                <Pins data={lighthouses} onClick={setPopupInfo} />
            </ReactMapGl>
            <Drawer
                anchor="right"
                open={!!popupInfo}
                onClose={() => { toggleDrawer('right', false); setPopupInfo(); }}
                sx={{
                    boxSizing: 'border-box',
                    flexShrink: 0,
                    width: drawerWidth,
                    '& .MuiDrawer-paper': { top: drawerTop, height: drawerHeight, width: drawerWidth, boxSizing: 'border-box', overflowX: 'hidden', padding: padding }
                }}
                variant='persistent'
            >
                <Box sx={{ backgroundColor: '#fff', width: drawerContent }}>
                    <IconButton aria-label="Close" onClick={() => setPopupInfo(null)}>
                        <CloseIcon />
                    </IconButton>
                    {popupInfo && <Info info={popupInfo} />}
                </Box>
            </Drawer>
        </Container>
    );
};

export default LighthouseMap;
