import { Grid, Link } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import styled from '@emotion/styled/macro';
import { respondTo } from '../../utils/StyleUtil';
import { Button } from '../button';
import { MaxWidthContainer } from '../MaxWidthContainer';

const Container = styled.div`
    background-color: #fff;
    padding-top: 100px;
    position: relative;
    z-index: 1;
`;

const Description = styled.p`
    font-size: 18px;
    line-height: 1.53;
    margin-bottom: 40px;
    max-width: 800px;
`;

const Title = styled.h2`
    color: #41294A;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    margin: 40px 0 30px;
    text-align: center;

    ${respondTo('tablet')`
        font-size: 62px;
        line-height: 1.855;
        margin: 10px 0 0;
    `}
`;

const FeaturedStore = (props) => {
    const { image } = props;

    return (
        <MaxWidthContainer>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'grid', justifyItems: 'center', textAlign: 'center' }}>
                            <Box sx={{ maxWidth: 300, width: '100%' }}>
                                <img src={image} />
                            </Box>
                            <Title>
                                Visit the Snack Isle Print Shop
                            </Title>
                            <Description>
                                Snack Isle is an imaginary island full of all of my favorite things: lighthouses, food, and cute animals.
                                The <Link href="https://www.etsy.com/shop/snackisle">Snack Isle Print Shop</Link> is where you will find the Michigan lighthouse poster and all of my other printed goods.
                            </Description>
                            <Button to="https://www.etsy.com/shop/snackisle" text="Shop" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </MaxWidthContainer>
    );
};

export default FeaturedStore;
