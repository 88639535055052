import React, { useEffect, useState } from 'react';
import * as prismicR from '@prismicio/richtext';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import { DefaultLayout } from '../components';
import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import styled from '@emotion/styled/macro';
import { MaxWidthContainer } from '../components/MaxWidthContainer';
import moment from 'moment';
import { respondTo } from '../utils/StyleUtil';

const HoverContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
`;

const PosterContainer = styled.div`
    aspect-ratio: 0.65 / 1;

    ${props => props.backgroundImageUrl && `
        background-image: url('${props.backgroundImageUrl}');
        background-position: right top;
        background-repeat: no-repeat;
        background-size: contain;
    `}

    img {
        display: none;
    }

    &:hover {
        ${HoverContainer} {
            opacity: 1;
        }
    }

    ${respondTo('tablet')`
        height: 75vh;
    `}
`;

const Title = styled.div`
    color: #41294a;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -.06em;
`;

const Date = styled.div`
    color: #ff2c54;
    font-size: 1rem;
    font-weight: 700;
`;

const Poster = ({ match }) => {
    const [prismicDoc, setPrismicDoc] = useState(null);
    const [notFound, toggleNotFound] = useState(false);

    const { uid } = useParams();

    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const doc = await client.getByUID('poster', uid);

                if (doc) {
                    setPrismicDoc(doc);
                } else {
                    console.warn('Poster document was not found. Make sure it exists in your Prismic repository');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        };

        fetchPrismicData();
    }, [uid]);

    // Return the page if a document was retrieved from Prismic
    if (prismicDoc) {
        const date = moment(prismicDoc.data.date).format('MMMM D, YYYY');
        const title = prismicDoc.data.title.length !== 0 ? prismicR.asText(prismicDoc.data.title) : 'Untitled';
        const imageUrl = prismicDoc.data.full.url;

        const handleClick = () => {
            window.open(imageUrl, '_blank');
        };

        const TitleBlock = () => (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Title>{title}</Title>
                </Grid>
                <Grid item xs={12}>
                    <Date>{date}</Date>
                </Grid>
            </Grid>
        );

        return (
            <DefaultLayout wrapperClass="main" seoTitle={title}>
                <MaxWidthContainer>
                    <Box mt={{ xs: 4, md: 8 }}>
                        <Grid container spacing={{ xs: 3, md: 6 }} sx={{ justifyContent: 'center' }}>
                            <Grid item xs={12} sx={{ display: { md: 'none' } }}>
                                <TitleBlock />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: { md: 'flex' }, justifyContent: { md: 'flex-end' } }}>
                                    <PosterContainer backgroundImageUrl={imageUrl}>
                                        <HoverContainer>
                                            <Box sx={{ p: 3, display: 'flex' }} justifyContent="flex-end">
                                                <Button onClick={handleClick} variant="contained" startIcon={<FullscreenIcon />}>
                                                View Fullsize
                                                </Button>
                                            </Box>
                                        </HoverContainer>
                                        <img src={imageUrl} alt={prismicDoc.data.full.alt} />
                                    </PosterContainer>
                                </Box>
                            </Grid>
                            <Grid container item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <TitleBlock />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </MaxWidthContainer>
            </DefaultLayout>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
};

export default Poster;
