import React from 'react';
import * as prismicR from '@prismicio/richtext';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { linkResolver } from '../../prismic-configuration';
import { Grid } from '@mui/material';
import moment from 'moment';
import { Date, Dateline, ReadMore, TagList, truncate } from '../../pages/BlogHome';

const Post = styled(Link)`
    text-decoration: none;
`;

const BlogDescription = styled.p`
    color: #41294a;
    font-size: 18px;
    line-height: 1.55;
`;

const BlogTitle = styled.h2`
    color: #41294a;
    font-size: 32px;
    font-weight: 500;
`;

const BlogPostItem = (props) => {
    const { post } = props;
    const { data } = post;
    const { body, publish_date, tags, title } = data;
    const blog_title = prismicR.asText(title);
    const description = prismicR.asText(body[0].primary.blog_post_body);
    const date = moment(publish_date).format('MMM D, YYYY');

    return (
        <Post to={linkResolver(post)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BlogTitle>{blog_title}</BlogTitle>
                </Grid>
                <Grid item xs={12}>
                    <BlogDescription>{truncate(description, 120)}</BlogDescription>
                </Grid>
                <Grid item xs={12}>
                    <Dateline>
                        <Date>{date}</Date>
                        {tags && <TagList tags={tags} />}
                        <ReadMore to={linkResolver(post)}>Read More</ReadMore>
                    </Dateline>
                </Grid>
            </Grid>
        </Post>
    );
};

export default BlogPostItem;
