import React, { useEffect, useState } from 'react';
import * as prismicR from '@prismicio/richtext';

import { DefaultLayout, SliceZone } from '../components';
import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';
import { useParams } from 'react-router-dom';
import { MaxWidthContainer } from '../components/MaxWidthContainer';
import { Typography } from '../components/Typography';
import styled from '@emotion/styled/macro';

const SIZES = {
    size300: '0.75rem',
    size400: '1rem',
    size500: '1.33rem',
    size600: '1.77rem',
    size700: '2.36rem',
    size800: '3.15rem',
    size900: '4.2rem'
};

const Article = styled.article`
    :focus {
        outline: 2px dashed;
        outline-offset: 0.25rem;
    }

    main:focus {
        outline: none;
    }

    a:not([class]) {
        color: currentColor;
    } 

    h1,
    h2,
    h3,
    h4 {
        line-height: 1.3;
    }

    h1,
    h2 {
        font-weight: 900;
    }

    h1 {
        font-size: ${SIZES.size700};
    }

    h2 {
        font-size: ${SIZES.size600};
    }

    h3 {
        font-size: ${SIZES.size600};
    }

    img {
        max-width: 50rem;
    }

    figcaption {
        font-size: ${SIZES.size300};
        font-style: italic;
        margin-top: 1rem;
      }
      
      blockquote {
        padding-left: ${SIZES.size600};
        border-left: 5px solid;
        font-style: italic;
      }
      
      ::selection {
        color: #fff;
        background: rgba(0, 0, 0, 0.99);
      }
      
      > div {
        margin-top: 2em;

        * + * {
            margin-top: 1em;
        }
      }
      
      p,
      li {
        max-width: 60ch;
      }
      
      h1 {
        max-width: 15ch;
      }
      
      h2,
      h3 {
        max-width: 25ch;
      }
      
      .post {
        flow-space: ${SIZES.size700};
      
        width: 90vw;
        width: clamp(16rem, 90vw, 70rem);
        margin-left: auto;
        margin-right: auto;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      
      .post time {
        flow-space: ${SIZES.size400};
        display: block;
      }
      
      .post :is(h2, h3) {
        flow-space: ${SIZES.size900};
      }
      
      .post :is(h2, h3) + * {
        flow-space: ${SIZES.size400};
      }
      
      .post figure,
      .post figure + * {
        flow-space: ${SIZES.size900};
      }
      
      @media (min-width: 45em) {
        h1 {
          font-size: ${SIZES.size900};
        }
      
        h2 {
          font-size: ${SIZES.size700};
        }
      
        p {
          font-size: ${SIZES.size500};
        }
      
        figcaption {
          font-size: ${SIZES.size400};
        }
      
        blockquote p {
          font-size: ${SIZES.size600};
          max-width: 40ch;
        }
      
        .post time {
          font-size: ${SIZES.size600};
        }
      }
`;

/**
 * Blog post page component
 */
const Post = () => {
    const [prismicDoc, setPrismicDoc] = useState(null);
    const [notFound, toggleNotFound] = useState(false);

    const { uid } = useParams();

    // Get the blog post document from Prismic
    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const doc = await client.getByUID('blog_post', uid);

                if (doc) {
                    setPrismicDoc(doc);
                } else {
                    console.warn('Blog post document was not found. Make sure it exists in your Prismic repository');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        };

        fetchPrismicData();
    }, [uid]);

    // Return the page if a document was retrieved from Prismic
    if (prismicDoc) {
        const title =
        prismicDoc.data.title.length !== 0
            ? prismicR.asText(prismicDoc.data.title)
            : 'Untitled';

        return (
            <DefaultLayout seoTitle={title}>
                <MaxWidthContainer max={800}>
                    <Article>
                        <Typography variant="h1" as="h1">{title}</Typography>
                        <SliceZone sliceZone={prismicDoc.data.body} />
                    </Article>
                </MaxWidthContainer>
            </DefaultLayout>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
};

export default Post;
