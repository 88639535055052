import React, { useEffect, useState } from 'react';
import { ParallaxBannerLayer, ParallaxBanner } from 'react-scroll-parallax';
import * as prismicR from '@prismicio/richtext';
import { DefaultLayout } from '../components';
import NotFound from './NotFound';
import { client } from '../utils/prismicHelpers';
import { MaxWidthContainer } from '../components/MaxWidthContainer';
import { Button } from '../components/button';
import { Box, Grid } from '@mui/material';
import FeaturedBlogPostList from '../components/home/FeaturedBlogPostList';
import FeaturedPosters from '../components/home/FeaturedPosters';
import styled from '@emotion/styled/macro';
import { respondTo } from '../utils/StyleUtil';
import FeaturedProject from '../components/home/FeaturedProject';
import FeaturedStore from '../components/home/FeaturedStore';
import HomeFooter from '../components/home/Footer';

const Desktop = styled(Box)`
    display: none;

    ${respondTo('laptop')`
        display: grid;
        padding: 0;
    `}
`;

const Mobile = styled(Box)`
    background-position: center center;
    background-size: cover;
    display: block;
    height: 500px;
    position: relative;
    z-index: 1;

    ${respondTo('laptop')`
        display: none;
    `}
`;

const Home = () => {
    const [prismicData, setPrismicData] = useState({ homeDoc: null, posters: null, blog_posts: null });
    const [notFound, toggleNotFound] = useState(false);

    // Get the homepage and blog post documents from Prismic
    useEffect(() => {
        const fetchPrismicData = async () => {
            try {
                const homeDoc = await client.getSingle('page');

                const featuredBlogPosts = await client.getByType('blog_post', {
                    orderings: {
                        field: 'my.blog_post.publish_date',
                        direction: 'desc'
                    }
                });

                const featuredPosters = await client.getByTag('featured', {
                    orderings: {
                        field: 'my.poster.date',
                        direction: 'desc'
                    }
                });

                if (homeDoc) {
                    setPrismicData({ homeDoc, posters: featuredPosters.results, blog_posts: featuredBlogPosts.results });
                } else {
                    console.warn('Blog Home document was not found. Make sure it exists in your Prismic repository');
                    toggleNotFound(true);
                }
            } catch (error) {
                console.error(error);
                toggleNotFound(true);
            }
        };

        fetchPrismicData();
    }, []);

    // Return the page if a document was retrieved from Prismic
    if (prismicData.homeDoc) {
        const homeDoc = prismicData.homeDoc;
        const blogPosts = prismicData.blog_posts;
        const posters = prismicData.posters;
        const title = prismicR.asText(homeDoc.data.title);
        const speed = [0, 20, 25, 40, 50, 55];
        const zIndex = [1, 2, 2, 2, 3, 3];
        const heroImages = homeDoc.data.body[0].items.map((item, index) => {
            const { image, margin_top } = item;
            return (
                <ParallaxBannerLayer speed={speed[index]} key={index} style={{ inset: 'unset', width: '100%', zIndex: zIndex[index] }}>
                    <img src={image.url} alt={image.alt} style={{ paddingTop: margin_top, width: '100%' }} />
                </ParallaxBannerLayer>
            );
        });
        const mobileHeroImage = homeDoc.data.body[1].items[0].image.url;
        // const featured = homeDoc.data.body[2].items[0].image.url;
        const snackLogo = homeDoc.data.body[4].items[0].image.url;

        const separator = {
            translateX: [-100, 0],
            children: (
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', width: 1920 * 3 }}>
                    <img src="/separator-img.png" alt="decoration" />
                    <img src="/separator-img.png" alt="decoration" />
                    <img src="/separator-img.png" alt="decoration" />
                    <img src="/separator-img.png" alt="decoration" />
                    <img src="/separator-img.png" alt="decoration" />
                    <img src="/separator-img.png" alt="decoration" />
                </Box>
            )
        };

        return (
            <DefaultLayout seoTitle={title} theme="dark">
                <Desktop>
                    <ParallaxBanner style={{ aspectRatio: '2 / 1' }}>
                        {heroImages}
                    </ParallaxBanner>
                </Desktop>
                <Mobile style={{ backgroundImage: `url(${mobileHeroImage})` }} />
                <FeaturedProject
                    data={homeDoc.data.body[2].primary}
                />
                <FeaturedPosters posters={posters} />
                <MaxWidthContainer>
                    <Grid container spacing={5} justifyContent={'center'}>
                        <Grid item sx={{ marginY: '3rem' }}>
                            <Button to={'/posters'} text={'View more'} />
                        </Grid>
                    </Grid>
                    <FeaturedBlogPostList posts={blogPosts} />
                </MaxWidthContainer>
                <ParallaxBanner layers={[separator]} style={{ height: 30, marginTop: '10rem' }} />
                <FeaturedStore image={snackLogo} />
                <Box mt={20} sx={{ marginBottom: '-8rem' }}>
                    <HomeFooter style={{ marginBottom: -10 }} />
                </Box>
            </DefaultLayout>
        );
    } else if (notFound) {
        return <NotFound />;
    }
    return null;
};

export default Home;
