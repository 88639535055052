import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const DefaultLayout = (props) => {
    const { children, className, footerTheme, hideFooter, seoTitle, theme } = props;
    const footerColorTheme = footerTheme ?? theme;

    return (
        <HelmetProvider>
            <Helmet>
                <title data-rh="true">{seoTitle}</title>
            </Helmet>
            <div className={className}>
                <Header theme={theme} />
                {children}
                {!hideFooter && <Footer theme={footerColorTheme} />}
            </div>
        </HelmetProvider>
    );
};

export default DefaultLayout;
